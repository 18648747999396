<template>
  <!-- 三防应急检查表-填报 -->
  <div>
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px"
        border
        v-loading="loading"
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="线路-工点"
        ></el-table-column>
        <el-table-column align="center" prop="deadline" label="截止时间">
        </el-table-column>
        <el-table-column align="center" prop="fillTime" label="填报时间">
        </el-table-column>
        <el-table-column align="center" prop="fillUser" label="填报人员">
          <template slot-scope="scope">{{
            scope.row.fillUser ? scope.row.fillUser.name : ""
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="approvalState"
          label="状态"
          :formatter="formatState"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              @click="filling(scope.row)"
              v-if="scope.row.status === 0"
              type="text"
              size="small"
              v-has="'atPresent:fillIn'"
              >填报</el-button
            >
            <el-button
              @click="lookDetail(scope.row)"
              type="text"
              size="small"
              v-has="'atPresent:fillIn'"
              >查看</el-button
            >
            <el-button
              @click="getHisrotyData(scope.row)"
              type="text"
              size="small"
              v-has="'atPresent:fillIn'"
              >历史操作记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--    施工填报应急检查弹窗-->
    <el-dialog
      title="三防应急检查表"
      v-if="emerDialog"
      :visible.sync="emerDialog"
      width="60%"
      append-to-body
    >
      <emergencyInspection
        v-if="emerDialog"
        :detail-data="detailInfos"
        :title="title"
        @cancelInspection="cancelInspection"
      >
      </emergencyInspection>
    </el-dialog>

    <el-dialog
      title="历史操作记录"
      :visible.sync="historyDialog"
      append-to-body
      width="30%"
    >
      <historyPage
        v-if="historyDialog"
        :log-list="historyData"
        @historyClose="historyClose"
      ></historyPage>
    </el-dialog>
  </div>
</template>

<script>
import {
  getApproveList,
  getDetail,
  operatinghistoryList,
} from "@/api/response";
import emergencyInspection from "./emergencyInspection";
import historyPage from "../resources/historyPage";

export default {
  name: "fillingModal",
  props: {
    detailInfo: {},
  },
  components: {
    emergencyInspection,
    historyPage,
  },
  data() {
    return {
      tableData: [],
      loading: false,
      emerDialog: false,
      historyDialog: false,
      fillingInfo: {},
      title: "",
      historyData: {},
      detailInfos: {},
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      console.log(this.detailInfo);
      let params = {
        responseRecordId: this.detailInfo.responseId,
      };
      getApproveList(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    formatState: function(row, column, cellValue) {
      console.log(cellValue);
      switch (cellValue) {
        case -2:
          return "超时";
        case -1:
          return "未填报";
        case 0:
          return "待审批";
        case 1:
          return "通过";
        case 2:
          return "驳回";
      }
    },
    filling(row) {
      this.title = "填报";
      this.getDetailData(row.id);
    },
    lookDetail(row) {
      this.title = "查看";
      this.getDetailData(row.id);
    },
    getDetailData(id) {
      getDetail(id).then((res) => {
        this.detailInfos = res.data;
        this.emerDialog = true;
      });
    },
    cancelInspection() {
      this.emerDialog = false;
      this.getTableData();
    },
    getHisrotyData(row) {
      let params = {
        incidenceId: row.id,
        operationType: row.reportNameId,
      };
      operatinghistoryList(params).then((res) => {
        if (res.code == 0) {
          this.historyData = res.data;
          this.historyDialog = true;
        }
      });
    },
    historyClose() {
      this.historyDialog = false;
    },
  },
};
</script>

<style scoped></style>
