<template>
  <div>
    <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="120px"
    >

      <el-form-item label="演练内容" prop="label">
        <el-input v-model="ruleForm.planContent" disabled></el-input>
      </el-form-item>

      <el-form-item label="实际演练时间" prop="label">
        <el-input v-model="ruleForm.executeTime" disabled></el-input>
      </el-form-item>
      <el-form-item label="实际参与人数" prop="label">
        <el-input v-model="ruleForm.joinCount" disabled></el-input>
      </el-form-item>
      <el-form-item label="演练方案" prop="label">
        <div
            class="file-item"
            v-for="(item, index) in programmeList"
            :key="index"
            @click="downFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="脚本" prop="label">
        <div class="file-item" v-for="(item, index) in scriptList" :key="index"  @click="downFile(item.link)">
          {{ item.attrName }}
        </div>
      </el-form-item>

      <el-form-item label="影像资料" prop="label">
        <div class="file-item" v-for="(item, index) in videoList" :key="index"  @click="downFile(item.link)">
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="签到表" prop="label">
        <div class="file-item" v-for="(item, index) in signList" :key="index"  @click="downFile(item.link)">
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="总结" prop="label">
        <div
            class="file-item"
            v-for="(item, index) in summaryList"
            :key="index"
            @click="downFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="评估报告" prop="label">
        <div
            class="file-item"
            v-for="(item, index) in assessmentList"
            :key="index"
            @click="downFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="其他附件" prop="label">
        <div class="file-item" v-for="(item, index) in otherList" :key="index"  @click="downFile(item.link)">
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="撤回人" prop="label">
        <el-input v-model="ruleForm.relationPerson" disabled></el-input>
      </el-form-item>
      <el-form-item label="撤回时间" prop="label">
        <el-input v-model="ruleForm.relationTime" disabled></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {getByLogId} from "@/api/stand";

export default {
  name: "planWithdraw",
  props : {
    rowData : {
      default : () => {}
    }
  },
  data(){
    return {
      ruleForm : {},
      programmeList: [],
      scriptList: [],
      videoList: [],
      signList: [],
      summaryList: [],
      assessmentList: [],
      otherList: [],
    }
  },
  created() {
    this.getDetailData();
  },
  methods : {
    getDetailData(){
      getByLogId(this.rowData.eventId).then(res => {
        if(res.code == 0){
          this.ruleForm = res.data;
          if (res.data.attachs && res.data.attachs.length > 0) {
            this.programmeList = res.data.attachs.filter((item) => {
              return item.attrType == 1;
            });
            this.scriptList = res.data.attachs.filter((item) => {
              return item.attrType == 2;
            });
            this.videoList = res.data.attachs.filter((item) => {
              return item.attrType == 3;
            });
            this.signList = res.data.attachs.filter((item) => {
              return item.attrType == 4;
            });
            this.summaryList = res.data.attachs.filter((item) => {
              return item.attrType == 5;
            });
            this.assessmentList = res.data.attachs.filter((item) => {
              return item.attrType == 6;
            });
            this.otherList = res.data.attachs.filter((item) => {
              return item.attrType == 7;
            });
          }
        }
      })
    },
    downFile(url){
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.file-item {
  color: #3090ff;
  cursor: pointer;
}
</style>
