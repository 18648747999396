<template>
  <!-- 措施 -->
  <div class="page-container bg-white p-4 h-full relative">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item prop="riskLevelCode">
            <el-cascader v-model="lineSite"
                         clearable :options="treeData" :props="props"
                         :show-all-levels="false" collapse-tags @change="handleLineSiteChange"></el-cascader>
          </el-form-item>
          <el-form-item prop="riskLevelCode">
            <el-select
              v-model="queryModal.riskTypeCode"
              clearable
              @change="riskTypeChange"
              placeholder="风险类别"
            >
              <el-option
                v-for="item of riskTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="warnGradeCode">
            <el-select
              v-model="queryModal.riskSubTypeCode"
              clearable
              placeholder="风险子类"
            >
              <el-option
                v-for="item of riskTypeSonList"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="warnGradeCode">
            <el-input
              v-model="queryModal.riskName"
              clearable
              placeholder="风险名称"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryModal.riskLevelCode"
              clearable
              placeholder="风险控制等级"
            >
              <el-option
                v-for="item of riskControlLevelList"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div style="margin-bottom: 10px">
      <el-radio-group v-model="radio" @change="radioChange">
        <el-radio-button :label="1">开始巡查</el-radio-button>
        <el-radio-button :label="2">巡查记录</el-radio-button>
      </el-radio-group>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-show="radio == 1">
        <el-table-column type="index" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column
          prop="riskLevelName"
          align="center"
          label="线路/标段/工点"
          min-width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.lineName }} - {{ scope.row.branchName }} - {{ scope.row.siteName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="riskTypeName"
          align="center"
          label="风险类别"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="riskSubTypeName"
          align="center"
          label="风险子类"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="riskName"
          align="center"
          label="风险名称"
          width="240"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="patrolsPerson"
          align="center"
          label="巡查人"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="frequency"
          align="center"
          label="次数"
          width="80"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="cycleName"
          align="center"
          label="周期"
          width="80"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="numberOfPatrols"
          align="center"
          label="已排查次数"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="timeLimit"
          align="center"
          label="剩余时限"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="riskLevelName"
          align="center"
          label="风险控制等级"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="yearQuarter"
          align="center"
          label="年度/季度"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="160">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="view(scope.row)">查看</el-button> -->
            <el-button type="text" @click="edit(scope.row)"
              >风险巡查</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-table :data="tableData" style="width: 100%" v-show="radio == 2">
        <el-table-column type="index" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column
            prop="lineName"
            align="center"
            label="线路"
            min-width="150"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="branchName"
            align="center"
            label="分部/标段"
            width="120"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="siteName"
            align="center"
            label="工点"
            width="120"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="riskTypeName"
            align="center"
            label="风险类别"
            width="120"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="riskSubTypeName"
            align="center"
            label="风险子类"
            width="100"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="riskName"
            align="center"
            label="风险名称"
            width="240"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="mileageRange"
            align="center"
            label="风险里程"
            width="120"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="riskDescribe"
            align="center"
            label="风险描述"
            width="120"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="riskLevelName"
            align="center"
            label="风险控制等级"
            width="120"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="yearQuarter"
            align="center"
            label="年度/季度"
            width="150"
            show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column label="操作" align="center" width="160">
          <template slot-scope="scope">
            <el-button type="text" @click="view(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>

    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        :total="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        layout=" prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      width="70%"
      title="风险巡查"
      class="editDialog details-dialog"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      center
      top="10vh"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <BaseInfo :risk-deaf-uuid="riskDeafUuid"></BaseInfo>
      <PatrolRiskForm
        @showProblemDialog="showProblemDialog"
        @closeProblemDialog="handleClose"
        :xcType="xcType"
        :rowInfo="problemRowInfo"
        :is-change="isChange"
        :front-uuid="frontUuid"
        :risk-deaf-uuid="riskDeafUuid"
      ></PatrolRiskForm>
    </el-dialog>
    <!-- 风险巡查问题填报 -->
    <el-dialog
      width="50%"
      title="风险巡查问题填报"
      class="editDialog details-dialog"
      top="10vh"
      :visible.sync="dialogProblem"
      v-if="dialogProblem"
      center
      :close-on-click-modal="false"
      :before-close="cancelProblem"
    >
      <ProblemFillForm v-if="ViewRiskType === 'prode' && dialogProblem " :type="ViewRiskType"
                       :row-fx-info="rowFxInfo"></ProblemFillForm>
      <ProblemFillForm
        @closeProblem="handleProblemClose"
        @cancelProblem="cancelProblem"
        v-if="ViewRiskType !== 'prode' && dialogProblem "
        :risk-deaf-uuid="questionData.row.id"
        :row-man-info="questionData.rowMainInfo"
        :frontUuid="frontUuid"
        :type="questionData.type"
        :father-form="questionData.ruleFrom"
      ></ProblemFillForm>
<!--      ViewRiskType-->
      <!-- :rowFxInfo="rowFxInfo" -->
    </el-dialog>
    <RiskHistory
      :dialogHistory="dialogHistory"
      v-if="dialogHistory"
      :risk-deaf-uuid="riskDeafUuid"
      @hanlderPro="hanlderPro"
      @closedialogHistory="closedialogHistory"
    ></RiskHistory>
    <el-dialog
        width="50%"
        title="风险进展记录"
        class="editDialog details-dialog"
        top="10vh"
        :visible.sync="progressRecord"
        v-if="progressRecord"
        center
        :close-on-click-modal="false"
    >
      <PatrolRiskForm
          :ViewRiskType="ViewRiskType"
          :row-info="problemRowInfo"
          @showProblemDialog="showProblemDialog"
          @closeProblemDialog="handleClose"
      ></PatrolRiskForm>
      <!--      :before-close="handleProblemClose"-->
    </el-dialog>

    <!-- :ViewRiskType="ViewRiskType" -->
  </div>
</template>
<script>
import BaseInfo from "./components/BaseInfo";
import PatrolRiskForm from "./components/PatrolRiskForm";
import ProblemFillForm from "./components/ProblemFillForm";
import RiskHistory from "./components/RiskHistory";
import {historyPatrolPage, toPatrolPage} from "@/api/riskManage";
import {getDictData} from "@/api/dict";
import {queryDeptDetail} from "@/api/stand";
export default {
  components: {
    PatrolRiskForm,
    BaseInfo,
    ProblemFillForm,
    RiskHistory
  },
  data() {
    return {
      dialogVisible: false,
      progressRecord: false,
      queryModal: {
        companyTypeCode: "",
        roleId: "",
        riskLevelCode: "",
        siteUuidList : []
      },
      page: {
        pageSize: 10,
        pageNo: 1,
        total: 0
      },
      radio: 1,
      tableData: [
      /*  { riskLevelName: "广州市城市轨道交通十四号线二期" },
        { riskLevelName: "广州市城市轨道交通十四号线二期" },
        { riskLevelName: "广州市城市轨道交通十四号线二期" },
        { riskLevelName: "广州市城市轨道交通十四号线二期" }*/
      ],
      problemRowInfo: {},
      dialogProblem: false,
      rolesData: [],
      gradeList: [],
      unitTypeData: [],
      xcType: false,
      isChange: false,
      dialogHistory: false,
      ViewRiskType: "",
      rowFxInfo: {},
      lineSite : [],
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      riskTypeList : [],
      riskTypeSonList : [],
      riskControlLevelList : [],
      riskDeafUuid : '',
      frontUuid : '',
      questionData : {}
    };
  },
  mounted() {
    this.getSelectList();
    this.queryLineSiteTreeData();
    this.getTableData();
  },
  methods: {
    getSelectList() {
      let dictArray = ["riskType", "riskControlLevel"];
      dictArray.forEach((item) => {
        this.getDictList(item);
      });
    },
    getDictList(label) {
      getDictData(label).then((res) => {
        switch (label) {
          case "riskType":
            this.riskTypeList = res.data;
            break;
          case "riskControlLevel":
            this.riskControlLevelList = res.data;
            break;
        }
      });
    },
    queryLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    getUuid(len, radix) {
      var chars = '0123456789abcdefghijklmnopqrstuvwxyz'.split('');
      var uuid = [], i;
      radix = radix || chars.length;

      if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
      } else {
        // rfc4122, version 4 form
        var r;

        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';

        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
          if (!uuid[i]) {
            r = 0 | Math.random() * 16;
            uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
          }
        }
      }

      return uuid.join('');
    },

    riskTypeChange(){
      getDictData(this.queryModal.riskTypeCode).then((res) => {
        this.riskTypeSonList = res.data;
      });
    },
    getTableData(){
      let params = {
        pageNum : this.page.pageNo,
        pageSize : this.page.pageSize,
        ...this.queryModal
      }
      let pageInterface = this.radio == 1 ? toPatrolPage : historyPatrolPage;
      pageInterface(params).then(res => {
        this.tableData = res.data.records;
        this.page.total = res.data.total;
        this.loading = false;
      })
    },
    // 级联选择器事件
    handleLineSiteChange(record) {
      console.log(record)
      this.queryModal.siteUuidList = [];
      for (let item of record) {
        this.queryModal.siteUuidList.push(item[item.length-1]);
      }
    },
    // change切换
    radioChange() {
      this.getTableData();
    },
    // 新增
    add() {
      this.title = "新增";
      this.submitType = "add";
      this.dialogVisible = true;
      this.pageData = {};
    },
    // 编辑
    edit(row) {
      console.log(row);
      this.title = "编辑";
      this.problemRowInfo = {...row}
      this.riskDeafUuid = row.riskDeafUuid;
      this.dialogVisible = true;
      // this.loadform("edit", row);
    },
    // 查看
    view(row) {
      console.log(row);
      this.riskDeafUuid = row.riskDeafUuid;
      this.dialogHistory = true;
      // this.loadform("view", row);
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getTableData();
    },
    // 显示风险巡查问题填报弹窗
    showProblemDialog(val) {
      console.log(val, "sssssddddddddd");
      this.frontUuid = this.getUuid(24,16);
      this.ViewRiskType = val.type;
      this.questionData = val;
      this.dialogProblem = true;
    },
    // 显示风险巡查问题填报弹窗
    closeProblemDialog(val) {
      console.log(val, "sssssddddddddd");
      this.problemRowInfo = val;
      this.dialogVisible = false;
    },
    search() {
      // d
      this.page.pageNo = 1;
      this.getTableData();
    },
    handleClose() {
      this.dialogVisible = false;
      this.activeName = "风险交底";
    },
    handleProblemClose(type) {
      console.log(type);
      this.xcType = type;
      this.isChange = !this.isChange;
      this.dialogProblem = false;
    },
    cancelProblem(type) {
      console.log(type);
      this.xcType = false;
      this.isChange = !this.isChange;
      this.dialogProblem = false;
    },
    hanlderPro(row, type) {
      console.log(row)
      this.ViewRiskType = type;
      if(type === 'process'){
        this.problemRowInfo = { ...row };
        this.progressRecord = true;
      }else{
        this.rowFxInfo = { ...row };
        this.dialogProblem = true;
      }
    },
    closedialogHistory() {
      this.dialogHistory = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 11;
}
::v-deep .el-table__body-wrapper {
  overflow: auto;
  max-height: 500px;
}

.page-container {
  .handle-wrapper {
    min-width: 240px;
    max-width: 30vw;
    height: 60vh;
    position: absolute;
    top: 10%;
    left: 20px;
    background: rgba(255, 255, 255, 1);
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
    border-radius: 0px 20px 20px 0px;
    padding: 20px;
    z-index: 6;

    .tree-wrapper {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }

    .toggle-btn {
      width: 28px;
      height: 240px;
      position: absolute;
      right: 0px;
      top: 50%;
      margin-top: -120px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }

    .toggle-btn-close {
      transform: rotate(180deg);
    }

    .toggle-btn-open {
      transform: rotate(0deg);
    }
  }

  .handle-wrapper-hide {
    left: -212px;
    background: transparent;
    box-shadow: none;
  }

  .handle-wrapper-show {
    left: 0;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #43a5e9;
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
  }
}
.btnStyle {
  margin-bottom: 22px;
}
.hasLeft {
  margin-left: 10px;
}
::v-deep .el-dialog__body {
  max-height: 700px;
  overflow: auto;
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #e6e7ec;
  text-align: center;
}

::v-deep .el-dialog__title {
  font-weight: bold;
}
::v-deep .details-dialog {
  .el-dialog__header {
    .el-dialog__title {
      position: static;
    }
  }
  .el-dialog__body {
    height: 680px;
    // max-height: 680px;
    overflow: auto;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
::v-deep .el-table__fixed-right{
  height: 100% !important;
}
</style>
