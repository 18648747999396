<template>
  <systemModal
    ref="formModal"
    @modalConfirm="handleSubmit"
    :title="title"
    width="40%"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="120px"
      class="demo-model"
      style="width: 80%"
    >
      <el-form-item
        label="工点名称"
        prop="lineSite"
        v-if="!isEdit && MultipleSite"
      >
        <el-cascader
          v-model="model.lineSite"
          ref="cascaderTree"
          :options="lineSiteData"
          :props="props"
          :show-all-levels="false"
          @change="handleLineSiteChange"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="工点名称" v-else>
        <el-input
          v-model="siteName"
          placeholder="请输入工点名称"
          disabled
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="所属建管部">
        <el-input v-model="model.superviseName" placeholder="请输入所属建管部称" disabled></el-input>
      </el-form-item>
      <el-form-item label="线路名称" prop="lineName">
        <el-input v-model="model.lineName" placeholder="请输入线路名称" disabled></el-input>
      </el-form-item>
      <el-form-item label="分部名称">
        <el-input v-model="model.branchName" placeholder="请输入分部名称" disabled></el-input>
      </el-form-item> -->
      <el-form-item
        :label="type == '125' ? '物资名称' : '设备名称'"
        prop="label"
      >
        <el-input
          v-model="model.label"
          :placeholder="type == '125' ? '请输入物资名称' : '请输入设备名称'"
        ></el-input>
      </el-form-item>
      <el-form-item label="规格型号" prop="model">
        <el-input v-model="model.model" placeholder="请输入规格型号"></el-input>
      </el-form-item>
      <el-form-item label="标准数量" prop="standardCount">
        <el-input-number
          v-model="model.standardCount"
          controls-position="right"
          :precision="0"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="现存数量" prop="count">
        <el-input-number
          v-model="model.count"
          controls-position="right"
          :precision="0"
          :min="0"
          :disabled="isEdit"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="计量单位" prop="unit">
        <el-input v-model="model.unit" placeholder="请输入单位"></el-input>
      </el-form-item>
      <el-form-item label="选择用途" prop="usages">
        <el-select v-model="model.usages" multiple placeholder="请选择用途">
          <el-option
            v-for="item in dict.usages"
            :key="item.label"
            :label="item.intro"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="model.remark"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入备注"
        >
        </el-input>
      </el-form-item>
    </el-form>
  </systemModal>
</template>
<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { addMaterials, editMaterials } from "@/api/eres";
import { getSiteDataByUuId } from "@/api/resource";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "新增",
      isEdit: false,
      MultipleSite: true,
      siteIds: [],
      siteName: "",
      model: {
        lineSite: [],
        materialsSitedVoList: [],
        label: "",
        model: "",
        standardCount: "",
        count: "",
        unit: "",
        usages: [],
        remark: "",
        type: "",
        materialType: 1,
      },
      flatTreeData: [],
      props: {
        children: "children",
        label: "name",
        value: "id",
        multiple: true,
      },
      dict: {
        usages: [],
      },
      loading: false,
      rules: {
        lineSite: [
          { required: true, message: "请选择线路工点", trigger: "change" },
        ],
        label: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        model: [{ required: true, message: "请输入规格型号", trigger: "blur" }],
        unit: [{ required: true, message: "请输入计量单位", trigger: "blur" }],
        usages: [{ required: true, message: "请选择用途", trigger: "change" }],
        standardCount: [
          { required: true, message: "请输入标准数量", trigger: "blur" },
          { pattern: /^[+]{0,1}(\d+)$/, message: "请输入合法数量" },
        ],
        count: [
          { required: true, message: "请输入标准数量", trigger: "blur" },
          { pattern: /^[+]{0,1}(\d+)$/, message: "请输入合法数量" },
        ],
      },
    };
  },
  props: {
    lineSiteData: {
      type: Array,
      default: function() {
        return [];
      },
    },
    type: {
      type: Number,
    },
  },
  mounted() {
    this.getDictData("物资用途", this.dict.usages);
    this.getSiteDataByUserInfo();
  },
  methods: {
    async getSiteDataByUserInfo() {
      const userInfo = JSON.parse(localStorage.getItem("user"));
      const params = {
        userId: userInfo.uuid,
      };
      let res = await getSiteDataByUuId(params);
      if (res.code == 0) {
        this.siteIds = res.data;
        if (res.data.length > 1) {
          this.MultipleSite = true;
        } else {
          this.MultipleSite = false;
        }
      }
    },
    handleAdd(record) {
      this.model = {
        lineSite: [],
        materialsSitedVoList: [],
        label: "",
        model: "",
        standardCount: "",
        count: "",
        unit: "",
        usages: [],
        remark: "",
        type: this.type,
        materialType: 1,
      };
      this.flatArr(this.lineSiteData);
      if (record) {
        this.title = "编辑";
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
        this.model.usages = [];
        const tempArr = JSON.parse(record.usages);
        for (let item of tempArr) {
          this.model.usages.push(String(item));
        }
        this.siteName = record.siteName;
        this.isEdit = true;
      } else {
        this.title = "新增";
        this.isEdit = false;
        // 如果只有一个工点权限
        if (!this.MultipleSite) {
          const tempArr = [];
          this.findParent(this.siteIds[0], tempArr);
          const line = tempArr[0];
          const branch = tempArr[1];
          const site = tempArr[tempArr.length - 1];
          this.siteName = site.name;
          this.model.materialsSitedVoList.push({
            branchId: branch.id,
            branchName: branch.name,
            lineId: line.id,
            lineName: line.name,
            siteId: site.id,
            siteName: site.name,
            superviseId: site.constructionManagementUnitId,
            superviseName: site.constructionManagementUnitName,
          });
        }
      }
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    // 线路工点树选择
    handleLineSiteChange(val) {
      console.log(val, "线路工点选中的变化");
    },
    // 数组扁平化
    flatArr(data) {
      for (let item of data) {
        this.flatTreeData.push(item);
        if (item.children) {
          this.flatArr(item.children);
        }
      }
    },
    // 查找祖先
    findParent(uuid, result) {
      const tempObj = this.flatTreeData.find((item) => item.uuid == uuid);
      if (tempObj) {
        result.unshift(tempObj);
        if (tempObj.puuid != "0") {
          this.findParent(tempObj.puuid, result);
        }
      }
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          //编辑
          if (this.isEdit) {
            const params = JSON.parse(JSON.stringify(this.model));
            params.usages = params.usages.join(",");
            editMaterials(params).then((res) => {
              if (res.code == 0) {
                this.$message.success("操作成功");
                this.formReset();
                this.$refs.formModal.handleClose();
                this.$emit("formSubmit");
                this.loading = false;
              }
            });
          } else {
            //新增
            // 如果为多工点
            if (this.MultipleSite) {
              const siteNodes = this.$refs.cascaderTree.getCheckedNodes();
              console.log(siteNodes, "选中的线路工点");
              for (let node of siteNodes) {
                if (node.level == 4) {
                  this.model.materialsSitedVoList.push({
                    branchId: node.path[1],
                    branchName: node.pathLabels[1],
                    lineId: node.path[0],
                    lineName: node.pathLabels[0],
                    siteId: node.value,
                    siteName: node.label,
                    superviseId: node.data.constructionManagementUnitId,
                    superviseName: node.data.constructionManagementUnitName,
                  });
                }
              }
            }
            console.log(this.model);
            const params = JSON.parse(JSON.stringify(this.model));
            params.usages = params.usages.join(",");
            addMaterials(params).then((res) => {
              if (res.code == 0) {
                this.$message.success("操作成功");
                this.formReset();
                this.$refs.formModal.handleClose();
                this.$emit("formSubmit");
                this.loading = false;
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    formReset() {
      this.$refs.modelForm.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped></style>
