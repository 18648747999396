<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item>
            <el-input
              v-model="queryModal.resourceName"
              clearable
              placeholder="输入需要查询的内容"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button
            type="primary"
            @click="handleDownload"
            v-has="'Public:download'"
            >下载导入模板</el-button
          >
          <el-button
            type="primary"
            @click="handleImport"
            v-has="'Public:therimport'"
            >导入</el-button
          >
          <el-button
            type="primary"
            @click="handleExport"
            v-has="'Public:export'"
            >导出</el-button
          >
          <el-button type="primary" @click="handleAdd" v-has="'Public:add'"
            >新增</el-button
          >
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="loading"
        border
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="70"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="resourceName"
          label="名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="resourceAddress"
          label="地址"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="telephone"
          label="电话"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="synopsis"
          label="简介"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="resourceType"
          label="类型"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="remarks"
          label="备注"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column align="center" label="操作" width="150px">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-has="'Public:edit'"
              >编辑</el-button
            >

            <el-button
              v-has="'Public:del'"
              @click="handleDelete(scope.row)"
              type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <PublicForm ref="modelForm" @formSubmit="handleFormSubmit"></PublicForm>
  </div>
</template>

<script>
import PublicForm from "./modules/PublicForm.vue";
import { GetPublic, DelPublic } from "@/api/eres";
// import { queryDictByLabel, queryDictNodesById } from '@/api/dict'
// import { queryOrgPageList } from '@/api/org'
export default {
  components: {
    PublicForm,
  },
  data() {
    return {
      type: "",
      queryModal: {
        resourceName: "",
      },
      tableData: [],
      loading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    handleSearch() {
      this.page.pageNo = 1;
      this.loadData();
    },
    handleDownload() {
      console.log("下载导入模板");
    },
    handleImport() {
      console.log("导入");
    },
    handleExport() {
      console.log("导出");
    },
    handleAdd() {
      this.$refs.modelForm.handleAdd(this.type);
    },
    handleEdit(record) {
      this.$refs.modelForm.handleAdd(this.type, record);
    },
    handleDelete(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        DelPublic(record).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadData();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    handleFormSubmit() {
      this.loadData();
    },
    loadData() {
      this.loading = true;
      const params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        resourceName: this.queryModal.resourceName
          ? this.queryModal.resourceName
          : null,
      };
      GetPublic(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          this.page.pageNo = res.data.current;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.total;
          this.page.count = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 1;
}
.p-4 {
  overflow-y: scroll;
}
</style>
