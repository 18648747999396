<template>
  <!-- 三防检查表二级弹框-填报 -->
  <div>
    <el-form ref="form" :model="form" label-width="120px" v-loading="loading">
      <el-row>
        <el-col :span="6">
          <el-form-item label="线路-工点">
            <el-input v-model="form.lineSiteDTO.name" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="应急响应类型">
            <el-input
              v-model="form.responseRecordDto.type.label"
              disabled
            ></el-input>
            <!--            <el-input v-model="form.name"></el-input>-->
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="应急级别">
            <el-input
              v-model="form.responseRecordDto.currentLevel.label"
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="检查时间"
            prop="checkTime"
            :rules="{
              required: true,
              message: '请选择检查时间',
              trigger: 'bulr',
            }"
          >
            <el-date-picker
              v-model="form.checkTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="请选择检查时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item
            label="参与检查人员"
            prop="checkPeople"
            :rules="{
              required: true,
              message: '请输入参与检查人员',
              trigger: 'bulr',
            }"
          >
            <el-input v-model="form.checkPeople"></el-input>
          </el-form-item>
        </el-col>

        <div class="table-wrapper">
          <el-table
            :data="tableData"
            style="width: 100%;margin-bottom: 20px"
            border
          >
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="100"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="checkItemContent"
              label="检查项"
              width="500"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="hiddenDangerCount"
              label="存在隐患数量"
            >
              <!--              <template slot-scope="scope">
                {{   scope.row.questionList ? scope.row.questionList.length : 0 }}
              </template>-->
            </el-table-column>
            <el-table-column align="center" prop="planCount" label="检查详情">
              <template slot-scope="scope">
                <div
                  class="content"
                  v-for="(item, index) in scope.row.questionArray"
                  :key="index"
                >
                  {{ item.hiddenQuestion }}
                  <el-image
                    v-if="item.uri"
                    style="width: 100px; height: 100px"
                    :src="item.uri"
                    fit="cover"
                  ></el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="onReport(scope.row)"
                  type="text"
                  size="small"
                  v-has="'atPresent:fillIn'"
                  >填报</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!--        <div class="bottom">
          发起隐患填报: <el-button size="small" type="primary">填报</el-button>
        </div>-->
      </el-row>
    </el-form>

    <div slot="footer" class="dialog-footer" v-if="title === '填报'">
      <el-button type="primary" :loading="loading" @click="submitInspection(0)"
        >保存</el-button
      >
      <el-button type="primary" :loading="loading" @click="submitInspection(1)"
        >提交</el-button
      >
    </div>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      append-to-body
      @close="fillClose"
      width="30%"
    >
      <emergencyFill
        v-if="dialogVisible"
        :fill-data="fillData"
        @cancelFill="cancelFill"
        @fillSubmit="fillSubmit"
      ></emergencyFill>
    </el-dialog>
  </div>
</template>

<script>
import emergencyFill from "./emergencyFill";
import { threeCheckListEdit } from "@/api/response";
import dayjs from "dayjs";

export default {
  name: "emergencyInspection",
  props: {
    detailData: {},
    title: {
      default: "",
    },
  },
  components: {
    emergencyFill,
  },
  data() {
    return {
      form: {
        lineSiteDTO: {
          name: "",
        },
        checkTime: null,
      },
      tableData: [],
      loading: false,
      dialogVisible: false,
      fillData: {},
      flag: false,
    };
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.detailData));
    this.$set(
      this.form,
      "checkTime",
      dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss")
    );
    // this.form.checkTime = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
    console.log(this.form, "oooooooooooo");
    this.tableData = this.form.threeCheckListItemList.map((item) => {
      item.dangerNum = item.questionList ? item.questionList.length : 0;
      // item.questionArray = item.questionList;
      return item;
    });
    this.tableData.forEach((item) => {
      this.$set(item, "questionArray", item.questionList);
    });
  },
  methods: {
    // pickInput() {
    //   this.$forceUpdate();
    // },
    onReport(row) {
      // this.fillData = JSON.parse(JSON.stringify(row));
      this.fillData = { ...row };
      console.log(this.fillData);
      this.dialogVisible = true;
    },
    cancelInspection() {
      this.$emit("cancelInspection");
    },
    fillSubmit(event, num) {
      if (num == 0) {
        // this.fillData.questionList = event;
        let i = this.tableData.findIndex(
          (item) => this.fillData.id === item.id
        );
        this.tableData[i].questionList = event;
        this.tableData[i].questionList[0].checkItemId = this.fillData.id;
        this.tableData[i].hiddenDangerCount = 0;
        this.tableData[i].questionArray = this.tableData[i].questionList.map(
          (item) => {
            item.hiddenQuestion =
              item.hiddenQuestion == "无" ? "" : item.hiddenQuestion;
            return item;
          }
        );
        this.tableData[i].dangerNum = 0;
        this.tableData[i].existHiddenQuestionFlag = 0;
      } else {
        event.forEach((item) => {
          if (!item.hiddenQuestion && !item.uri) {
            this.flag = true;
          } else {
            this.flag = false;
          }
        });
        console.log(this.flag);
        if (this.flag) {
          this.fillData.questionList = [];
        } else {
          this.fillData.questionList = event.map((item) => {
            item.checkItemId = this.fillData.id;
            return item;
          });
          // this.$nextTick(()=> {

          let i = this.tableData.findIndex(
            (item) => this.fillData.id === item.id
          );
          this.tableData[i].questionList = this.fillData.questionList;
          this.tableData[i].questionArray = this.tableData[i].questionList;
          this.tableData[i].existHiddenQuestionFlag = 1;
          this.tableData[i].dangerNum = this.fillData.questionList.length;
          this.tableData[
            i
          ].hiddenDangerCount = this.fillData.questionList.length;
          console.log(this.fillData);
          // })
        }
      }
      console.log(this.fillData);
    },
    fillClose() {
      console.log(111);
      // this.fillData.questionList = [];
    },
    /*  dialogClose(){
      this.fillData.questionList = [];
    },*/
    getNowTime() {
      var date = new Date();
      //年 getFullYear()：四位数字返回年份
      var year = date.getFullYear(); //getFullYear()代替getYear()
      //月 getMonth()：0 ~ 11
      var month = date.getMonth() + 1;
      //日 getDate()：(1 ~ 31)
      var day = date.getDate();
      //时 getHours()：(0 ~ 23)
      var hour = date.getHours();
      //分 getMinutes()： (0 ~ 59)
      var minute = date.getMinutes();
      //秒 getSeconds()：(0 ~ 59)
      var second = date.getSeconds();
      var time =
        year +
        "-" +
        this.addZero(month) +
        "-" +
        this.addZero(day) +
        " " +
        this.addZero(hour) +
        ":" +
        this.addZero(minute) +
        ":" +
        this.addZero(second);
      // var today = year + '-' + this.addZero(month) + '-' + this.addZero(day);
      return time;
    },
    //小于10的拼接上0字符串
    addZero(s) {
      return s < 10 ? "0" + s : s;
    },
    submitInspection(num) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          /*let params = {

          }*/
          // this.form.checkTime = this.getNowTime();
          this.form.currentId = this.form.currentResponseReport.id;
          this.form.saveStatus = num;
          threeCheckListEdit(this.form)
            .then((res) => {
              this.loading = false;
              console.log(res);
              if (res.code == 0) {
                this.$message.success("操作成功");
                this.$emit("cancelInspection");
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      });
    },
    cancelFill() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
</style>
