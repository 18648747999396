<template>
    <div class="sign-canvas">
      <canvas
        v-show="boxType"
        id="canvas"
        width="400"
        height="200"
        @mousedown="canvasDown($event)"
        @mousemove="canvasMove($event)"
        @mouseup="canvasUp()"
        @mouseleave="canvasLeave()"
        ref="canvas"
      >
        抱歉，您的浏览器暂不支持canvas元素
      </canvas>
      <div class="imgBox" v-if="!boxType"><img :src="signatureImgUrl" alt="" /></div>
      <div class="sign-btn">
        <div class="clear" @click="clear" v-if="boxType">清空</div>
        <div class="clear" @click="resign" v-else>重签</div>
        <div class="save" @click="save" v-if="boxType">保存</div>
        <div class="save" @click="down" v-else>关闭</div>
      </div>
    </div>
  </template>
  
    <script>
  export default {
    props: {
      signatureImgUrl: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        canvas: null,
        //全局状态位  true展示canvas画布，false展示img标签
        boxType: true
      }
    },
    mounted() {
      if (this.signatureImgUrl) {
        this.boxType = false
      }
      // 初始化如果 传过来的有图片  那就不用初始化
      if (this.boxType) {
        this.show()
      }
    },
    methods: {
      show() {
        this.canvas = this.$refs.canvas // 指定canvas
        this.ctx = this.canvas.getContext("2d") // 设置2D渲染区域
        this.ctx.lineWidth = 3 // 设置线的宽度
      },
      canvasDown(e) {
        this.canvasMoveUse = true
        const canvasX = e.offsetX
        const canvasY = e.offsetY
        this.ctx.beginPath() // 移动的起点
        this.ctx.moveTo(canvasX, canvasY)
      },
      canvasMove(e) {
        // 只在移动是进行绘制图线
        if (this.canvasMoveUse) {
          const t = e.target
          let canvasX
          let canvasY
          canvasX = e.offsetX
          canvasY = e.offsetY
          this.ctx.lineTo(canvasX, canvasY)
          this.ctx.stroke()
        }
      },
      canvasUp() {
        this.canvasMoveUse = false
      },
      canvasLeave() {
        this.canvasMoveUse = false
      },
      resign() {
        this.boxType = true
        this.show()
      },
      clear() {
        this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)
      },
      down() {
        this.$emit("setImgUrl")
      },
      save() {
        if (this.boxType) {
          const imgBase64 = this.$refs.canvas.toDataURL()
          // const rawBase64 = imgBase64.substring(imgBase64.indexOf(",") + 1);
          // this.$emit("setImgUrl", rawBase64)
          this.$emit("setImgUrl", imgBase64)
        }
      }
    }
  }
  </script>
  
    <style lang="scss" scoped>
  .sign-canvas {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    /* padding: 20px 30px; */
  }
  .sign-canvas canvas {
    background-color: #e0e3e5;
  }
  .sign-btn {
    width: 20%;
    display: flex;
    margin: 20px 0;
  }
  .sign-btn div {
    width: 80px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    color: #ffffff;
  }
  .sign-btn div:active {
    background-color: #cccccc;
    color: #333333;
    cursor: pointer;
  }
  .sign-btn .clear {
    background-color: #f18c2e;
    cursor: pointer;
    border-radius: 5px;
  }
  .sign-btn .save {
    background-color: #7073FF;
    cursor: pointer;
    border-radius: 5px;
  }
  .imgBox {
    width: 400px;
    height: 200px;
    background: #e0e3e5;
    img {
      width: 100%;
      height: 100%;
    }
  }
  </style>
  