<template>
  <div>
    <el-form :model="model" :rules="rules" ref="modelForm"
             label-width="120px" class="demo-model">
      <el-checkbox-group v-model="checkList">
        <el-checkbox label="复选框 A"></el-checkbox>
        <el-checkbox label="复选框 B"></el-checkbox>
        <el-checkbox label="复选框 C"></el-checkbox>
        <el-checkbox label="禁用"></el-checkbox>
        <el-checkbox label="选中且禁用" disabled></el-checkbox>
      </el-checkbox-group>
    </el-form>


    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
    </div>
  </div>
</template>

<script>
import {dataList} from "@/api/resource";

export default {
  name: "addWork",
  data(){
    return{
      model : {},
      rules : {},
      checkList : []
    }
  },
  created() {
    this.getWorkList();
  },
  methods : {

    getWorkList(){
      let params = {
        userId : JSON.parse(localStorage.getItem('user')).uuid
      }
      dataList(params).then(res => {
        console.log(res);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-checkbox-group{
  display: flex;
  flex-direction: column;
  .el-checkbox{
    margin-bottom: 10px;
  }
}
</style>
