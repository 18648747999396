<template>
  <div class="video-container">
    <el-button type="primary" size="small" @click="delRoom"
      >解散{{ called }}</el-button
    >
    <div class="scroll-wrapper overflow-y-scroll" v-if="callList.length == 0">
      <div
        class="flex justify-between mb-4 video-list"
        v-for="(i, index) in userList"
        :key="index"
      >
        <div class="video-center">
          <span> 【空闲】 </span>
          <span> {{ i.realName }} </span>
          <span> {{ i.job }} </span>
        </div>
        <div>
          <el-button type="primary" size="small" @click="call(i)"
            >语音</el-button
          >
          <el-button type="primary" size="small" @click="call(i)"
            >视频</el-button
          >
        </div>
      </div>
    </div>

    <div class="block" style="height: 100%;" v-else>
      <el-carousel
        :autoplay="false"
        trigger="click"
        style="height: 100%;"
        indicator-position="click"
      >
        <el-carousel-item v-for="(i, index) in callList" :key="index">
          <ul>
            <li
              v-for="(item, inde) in i"
              :key="inde"
              class="float-left work-canten"
              :class="inde % 2 == 0 ? 'mr-1.5' : ''"
            >
              <span class="mt-2"> {{ item.lable }}</span
              ><br />
              <span v-if="item.state == 2" class="text-xs font-light mb-1"
                >正在呼叫...</span
              >
              <img class="cursor-pointer w-7" src="@assets/im/gd.png" /><br />
            </li>
          </ul>
        </el-carousel-item>
      </el-carousel>
    </div>
    <calledPage
      v-show="called"
      :name="name"
      :callData="callData"
      @callClose="callClose"
      @voipAcceptCall="voipAcceptCall"
    ></calledPage>
  </div>
</template>

<script>
import calledPage from "./calledPage.vue";
import { addRoom, delAllRoom, userIdGetRoom } from "@/api/rtc.js";
export default {
  props: {
    callList: {
      type: Array,
      default: () => [],
    },
  },
  name: "video",
  components: {
    calledPage,
    // IndexBox,
    // RtcDia,
  },
  data() {
    return {
      rtcShow: false,
      dataList: {
        userId: JSON.parse(localStorage.user).id,
        toId: "",
        toName: "",
      },
      userList: [
        {
          realName: "熊常亮",
          job: "总监代表",
          id: 1714,
        },
        {
          realName: "周科汕",
          job: "项目经理",
          id: 1715,
        },
        {
          realName: "李川",
          job: "安全总监",
          id: 1716,
        },
        {
          realName: "谢重卫",
          job: "安质部长",
          id: 1717,
        },
        {
          realName: "梁红兵",
          job: "总监理工程师",
          id: 1718,
        },

        {
          realName: "管理员",
          job: "管理员",
          id: 1,
        },
      ],
      joiners: [], //	会话成员ID集合，不包括创建人。至少需要1个成员
      owner: {}, //	创建人信息
      vendorId: 1, //归属租户企业ID ,示例值(1)
      user: {},
      called: false,
      name: "",
      callData: {},
      // callList会议里的state  1是房主  2是待接通  3是已接通
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.pathWs = "ws://47.95.238.93:9901/rtc?userId=" + this.user.id;
  },
  mounted() {
    this.getUsers();
    this.init();
  },
  methods: {
    voipAcceptCall() {},
    callClose() {},
    getUsers() {
      /*  console.log(this.$route.query.uuid, "陆游的参数uuid");
      if (this.$route.query.id != 672) {
        getSiteUserInformation(this.$route.query.uuid).then((res) => {
          console.log(res);
          this.userList = res.data;
        });
      } else { */
      this.userList = [
        {
          realName: "吕明豪",
          job: "项目经理",
          id: 1747,
        },
        {
          realName: "李绪畅",
          job: "安全总监",
          id: 1748,
        },
        {
          realName: "尹富",
          job: "安质部长",
          id: 1749,
        },
        {
          realName: "郑勇",
          job: "总监理工程师",
          id: 1765,
        },
        {
          realName: "陈振海",
          job: "监理代表",
          id: 1767,
        },
        {
          realName: "管理员",
          job: "管理员",
          id: 1,
        },
      ];
    },
    call(data) {
      console.log(data);
      let parms = {
        // 会话成员ID集合，不包括创建人。至少需要1个成员
        joiners: [
          {
            showName: "管理员",
            id: 1,
          },
          {
            showName: "古伟强",
            id: 1862,
          },
        ],
        // 创建人信息
        owner: {
          showName: "测试",
          id: 1842,
        },
        vendorId: 1, //	归属租户企业ID
      };
      // 创建聊天室
      addRoom(parms).then((res) => {
        console.log(res);
        if (res.code == 0) {
          //聊天室创建成功  ws发送给被拨打人的消息
          this.sendWs(res.data);
        } else {
          this.$message.warning("您已在会议里，不可重复创建会议!");
        }
      });
    },
    init() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        this.socketWs = new WebSocket(this.pathWs);
        // 监听socket连接
        this.socketWs.onopen = this.openWs;
        // 监听socket错误信息
        this.socketWs.onerror = this.errorWs;
        // 关闭socket
        this.socketWs.onclose = this.closeWs;
        // 监听socket消息
        this.socketWs.onmessage = this.getMessageWs;
      }
    },
    openWs() {
      console.log("socket连接成功");
    },
    errorWs() {
      console.log("连接错误");
      //重连
      this.reconnect();
    },
    sendWs(data) {
      let message = {
        type: "im",
        to: data.id, //聊天室的id
        fromName: this.user.realName, //拨打的聊天室人员的姓名
        from: this.user.id, //拨打的聊天室人员的id
        message: {
          content: "这是⼀条IM的图⽂消息",
          type: 1,
          attribute: {
            id: 1,
            roomId: data.id,
            attrName: "这是⼀张图⽚的名称.jpg",
            attrLink:
              "http://47.95.238.93:9901/attrs/202209/dkn891278d0-afj812093jl0d9a.jpg",
            attrType: "jpg",
            created: "2022-09-04 10:01:43",
          },
        },
      };
      this.socketWs.send(JSON.stringify(message));
      console.log(JSON.stringify(message), "WebSocket发送成功");
      // this.called = true;
      // 消息发送成功 等待接收人的同意  进入语音的界面
      this.callList = [
        [
          {
            showName: "管理员",
            id: 1,
            lable: "管理员",
            value: 1,
            state: 1,
          },
          {
            showName: "测试",
            id: 1842,
            lable: "测试",
            value: 1842,
            state: 2,
          },
          {
            showName: "古伟强",
            id: 1862,
            lable: "古伟强",
            value: 1862,
            state: 2,
          },
        ],
      ];
    },
    closeWs() {
      console.log("socket已经关闭");
    },
    getMessageWs: function(msg) {
      console.log(msg, "服务端发来消息啦 视频监控");
      console.log(JSON.parse(msg.data), "服务端发来消息");
      this.callData = JSON.parse(msg.data); //创建的房间的信息
      this.$emit("call", this.callData);
    },
    reconnect() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function() {
        that.init(); //新连接
        that.lockReconnect = false;
        console.log("重新连接中……");
        // that.path = "ws://219.153.100.85:8001/webSocket/" + that.siteId + "-" + that.uuid //模拟测试webscoket断线重连
      }, 1000 * 2);
    },

    delRoom() {
      userIdGetRoom(this.user.id).then((result) => {
        console.log(result);
        if (result.data && result.data.length > 0) {
          result.data.forEach((item) => {
            let params = {
              id: item.id, //	会话ID
              memberId: this.user.id, //操作人账户ID
            };
            delAllRoom(params).then((res) => {
              console.log(res);
            });
          });
        }
      });
    },

    openVoip() {
      this.rtcShow = true;
    },
    endVoip() {
      this.rtcShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.video-container {
  height: 100%;
  .scroll-wrapper {
    color: #000;
    height: 42vh;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    .video-list {
      height: 28px;
      line-height: 28px;
    }
    .video-center {
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.work-canten {
  // width: 45%;
  width: 49%;
  // height: 120px;
  height: 105px;
  // height: 110px;
  background: #081742;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0px 1px 2px #000000;
  margin-bottom: 10px;
  display: flex;
  /* place-items: center; */
  flex-direction: column;
  align-items: center;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

::v-deep .el-carousel__container {
  // height: 100% !important;
  height: 92% !important;
}
::v-deep .el-carousel__arrow {
  display: none !important;
}
</style>
