<template>
  <el-tabs
    v-model="activeName"
    type="card"
    @tab-click="handleClick"
    v-loading="loading"
  >
    <el-tab-pane label="建设工地抢险和灾害损失情况" name="first">
      <el-table :data="rescueData" border style="width: 100%; margin-top: 20px">
        <el-table-column
          prop="inspectNum"
          label="出动检查人员(人次)"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="inspectPlaceNum"
          label="检查场所(处)"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="hiddenNum" label="发现三防问题隐患(处)">
        </el-table-column>
        <el-table-column prop="hiddenSuccessNum" label="已整改三防问题隐患(处)">
        </el-table-column>
        <el-table-column prop="ranks" label="出动(集结)抢险队伍(支)">
        </el-table-column>
        <el-table-column prop="ranksNum" label="出动(集结)抢险队伍人员(人次)">
        </el-table-column>
      </el-table>
      <p style="margin-top: 20px">本单位实际受灾情况报告:</p>
      <el-form :model="restoreTableForm" ref="restoreTableForm" size="small">
        <el-table
          :data="restoreTableForm.restoreTableData"
          style="width: 100%; margin-top: 20px"
          border
        >
          <el-table-column
            align="center"
            prop="lossSituation"
            label="损失情况"
          ></el-table-column>
          <el-table-column align="center" prop="num" label="数量">
          </el-table-column>
          <el-table-column
            align="center"
            prop="rescueSituation"
            label="抢险应对措施"
          >
          </el-table-column>
          <el-table-column align="center" prop="personCharge" label="结果">
          </el-table-column>
        </el-table>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="建设工地灾害损失及恢复情况表" name="second">
      <el-form :model="recoveryTableForm" ref="restoreTableForm" size="small">
        <el-table
          :data="recoveryTableForm.restoreTableData"
          style="width: 100%; margin-top: 20px"
          border
        >
          <el-table-column
            align="center"
            prop="lossSituation"
            label="损失情况"
          ></el-table-column>
          <el-table-column align="center" prop="num" label="数量">
          </el-table-column>
          <el-table-column
            align="center"
            prop="rescueSituation"
            label="抢险及恢复情况(线路-工点+情况描述，多个工点用逗号隔开)"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="personCharge"
            label="负责单位，联系人及电话"
          >
          </el-table-column>
        </el-table>
      </el-form>
    </el-tab-pane>
    <div style="margin-top: 20px" v-if="terwe == '审批'">
      <p>审批意见:</p>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入内容"
        v-model="textarea"
      >
      </el-input>
    </div>
    <!-- && activeName == 'first' -->
    <el-timeline v-show="terwe == '查看'" style="margin:10px 4px">
      <el-timeline-item
        v-for="(activity, index) in xyActivities"
        :key="index"
        type="primary"
        color="#3fa5e9"
        :size="'large'"
      >
        <div>
          <i style="font-style:normal;margin-right: 5px;">{{
            activity.operatorTime
          }}</i
          ><span>{{ activity.actionType }}</span>
          <span style="margin-left:10px"
            >操作人: {{ activity.operatorName }}
          </span>
          <span style="margin-left:10px" v-show="activity.operatorInfo"
            >操作意见: {{ activity.operatorInfo }}
          </span>
        </div>
      </el-timeline-item>
    </el-timeline>
    <div class="dialog-footer" v-if="terwe == '审批'">
      <el-button type="primary" @click="throughClck()">通过</el-button>
      <el-button type="danger" @click="rejectedClick()">驳回</el-button>
    </div>
  </el-tabs>
</template>

<script>
import {
  getlrosses, //current
  situationlist,
  agreelist,
  Department,
} from "@/api/response.js";

export default {
  props: {
    reportCurrentId: {
      type: Number,
    },
    terwe: {
      type: String,
    },
  },
  data() {
    return {
      rescueData: [],
      restoreTableForm: {
        restoreTableData: [],
      },
      restoreRules: {},
      activeName: "first",
      textarea: "",
      recoveryTableForm: {},
      recoveryRules: "",
      reject: true,
      statu: false,
      erteru: {},
      loading: false,
      xyActivities: [],
    };
  },
  watch: {
    terwe: {
      handler(val) {
        if (val) {
          this.loading = true;
          if (val == "审批") {
            this.rescueData = [];
            this.reject = true;
            this.statu = false;
          } else if (val == "查看") {
            this.rescueData = [];
            this.reject = false;
            this.statu = true;
          }
          this.seweer(this.reportCurrentId);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  methods: {
    seweer(val) {
      getlrosses(val).then((res) => {
        if (res.code == 0) {
          this.rescueData.push(res.data.situationInspectDto);
          this.restoreTableForm.restoreTableData = res.data.lossList;
          this.recoveryTableForm.restoreTableData = res.data.recoveryList;
          this.loading = false;
          this.xyActivities = res.data.operatingHistoryList;
        }
      });
    },
    handleClick() {},
    //通过
    throughClck() {
      this.loading = true;
      agreelist({
        reportId: this.reportCurrentId,
        approvalInfo: this.textarea,
        rejectInfo: this.textarea,
      })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "审批成功",
              type: "success",
            });
            this.textarea = "";
            this.$emit("examieClose");
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //驳回
    rejectedClick() {
      if (this.textarea) {
        this.loading = true;
        situationlist({
          reportId: this.reportCurrentId,
          approvalInfo: this.textarea,
          rejectInfo: this.textarea,
        })
          .then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "驳回成功",
                type: "success",
              });
              this.textarea = "";
              this.$emit("examieClose");
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.$message.warning("驳回意见必填");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.terw {
  display: flex;
  margin-top: 20px;
  span {
    margin-left: 20px;
  }
}
</style>
