<template>
  <div class="eq-ledger">
    <el-dialog
      v-if="type == '查看'"
      :title="title"
      :visible.sync="dialogVisible"
      width="80%"
      top="10vh"
      center
      :before-close="cancle"
    >
      <div class="fixed-header">
        <li
          :class="i == menuIndex ? 'selectMenu' : ''"
          v-for="(item, i) in menuList"
          :key="i"
          @click="getMenu(i)"
        >
          {{ item }}
        </li>
      </div>
      <div v-if="menuIndex == 0" class="register">
        <registerDialog :title="type" :ruleForm="ruleForm"></registerDialog>
      </div>
      <processInfo v-if="menuIndex == 1"></processInfo>
      <dataRecord v-if="menuIndex == 2"></dataRecord>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="cancle">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span> -->
    </el-dialog>
    <el-dialog
      v-if="type == '编辑'"
      :title="title"
      :visible.sync="dialogVisible"
      width="80%"
      top="10vh"
      center
      :before-close="cancle"
    >
      <div class="register">
        <registerDialog :ruleForm="ruleForm"></registerDialog>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import registerDialog from "./registerDialog";
import processInfo from "./processInfo";
import dataRecord from "./dataRecord";
export default {
  components: {
    processInfo,
    dataRecord,
    registerDialog
  },
  // ss
  props: {
    type: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    ruleForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      menuList: ["设备进场信息", "使用过程信息", "监测数据记录"],
      menuIndex: 0
    };
  },
  methods: {
    cancle() {
      // ss
      this.$emit("closeMainDialog");
    },
    submit() {
      // ss
      this.$emit("closeMainDialog");
    },
    getMenu(i) {
      this.menuIndex = i;
    }
  }
};
</script>

<style lang="scss" scoped>
// ss
.eq-ledger {
  // dd
  ::v-deep .el-dialog {
    height: 85%;
    .el-dialog__body {
      padding-top: 0;
      height: calc(100% - 54px);
    }
  }
}
.fixed-header {
  display: flex;
  justify-content: center;
  li {
    list-style: none;
    padding: 5px 8px;
    margin: 10px;
    color: #fff;
    background: #a7afb2;
    border-radius: 2px;
    cursor: pointer;
  }
  .selectMenu {
    background: #66b1ff;
  }
}

.register {
  height: calc(100% - 71px);
  overflow: auto;
  margin: 20px 0 0;
}
</style>
