<template>
  <div>
    <div style="margin-bottom: 10px">
      <el-radio-group v-model="radio" @change="radioChange">
        <el-radio-button :label="1">{{
          `全部(${pageData.totalCount})`
        }}</el-radio-button>
        <el-radio-button :label="2">{{
          `待处置(${pageData.unDealCount})`
        }}</el-radio-button>
        <el-radio-button :label="3">{{
          `已验收(${pageData.overCount})`
        }}</el-radio-button>
      </el-radio-group>
    </div>
    <div class="table-wrapper">
      <el-table v-loading="tableLoading" :data="tableData" style="width: 100%">
        <el-table-column type="index" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column
          prop="measureSmg"
          align="center"
          label="问题描述"
          min-width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="inspectUnitName"
          align="center"
          label="提出单位"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="inspectPersonName"
          align="center"
          label="提出人"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="rectificationDeadline"
          align="center"
          label="截止时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="updated"
          align="center"
          label="完成时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="problemStatus"
          align="center"
          label="状态"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ state[scope.row.problemStatus] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="160" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.viewButton"
              @click="view(scope.row)"
              >查看</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.dealButton"
              @click="edit(scope.row)"
              >处置</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.checkButton"
              @click="check(scope.row)"
              >验收</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      width="1200px"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div slot="title">
        <el-tabs v-model="activeName">
          <el-tab-pane label="问题描述" name="one"></el-tab-pane>
          <el-tab-pane label="问题处置" name="two"></el-tab-pane>
          <el-tab-pane
            v-if="proState"
            label="处置验收"
            name="three"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <SetCuoshiDialog
        ref="setCuoshiDialog"
        @CloseData="handleClose"
        :titleName="activeName"
        :rowFxInfo="rowFxInfo"
        :setData="setData"
        :checkData="checkData"
        :isEdit="isEdit"
      ></SetCuoshiDialog>
    </el-dialog>
  </div>
</template>

<script>
import SetCuoshiDialog from "./setCuoshiDialog";
import { getProblem, getCheck } from "@/api/risk";
export default {
  components: {
    SetCuoshiDialog,
  },
  props: {
    pageData: {
      type: Object,
      default: () => {},
    },
    submitType: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      radio: 1,
      tableLoading: false,
      tableData: [],
      dialogVisible: false,
      activeName: "one",
      state: ["待处置", "待验收", "驳回", "通过"],
      rowFxInfo: {},
      isEdit: "",
      setData: {},
      checkData: {},
      proState: true,
    };
  },
  mounted() {
    this.tableData = this.pageData.data;
  },
  methods: {
    // change切换
    radioChange(val) {
      if (val == 1) {
        console.log("全部");
        this.tableData = this.pageData.data;
      } else if (val == 2) {
        console.log("待处置");
        this.tableData = this.pageData.data.filter((i) => {
          return i.problemStatus != 3;
        });
      } else if (val == 3) {
        console.log("已验收");
        this.tableData = this.pageData.data.filter((i) => {
          return i.problemStatus == 3;
        });
      }
      // this.getData();
      console.log("val", val);
    },
    edit(val) {
      if (val.problemStatus == 0) {
        this.proState = false;
      }
      console.log(val);
      this.isEdit = "edit";
      this.activeName = "two";
      this.rowFxInfo = val;
      this.getDetailMsg(val.id);
    },
    view(val) {
      if (val.problemStatus == 0) {
        this.proState = false;
      }
      console.log(val);
      this.isEdit = "view";
      this.activeName = "one";
      this.rowFxInfo = val;
      this.getDetailMsg(val.id);
    },
    check(val) {
      if (val.problemStatus == 0) {
        this.proState = false;
      }
      console.log(val);
      this.isEdit = "check";
      this.activeName = "three";
      this.rowFxInfo = val;
      this.getDetailMsg(val.id);
    },
    getDetailMsg(id) {
      let promiseOne = getProblem(id);
      let promiseTwo = getCheck(id);
      Promise.all([promiseOne, promiseTwo]).then((res) => {
        console.log(res);
        this.setData = res[0].data ? res[0].data : {};
        this.checkData = res[1].data ? res[1].data : {};
        this.dialogVisible = true;
      });
      // getProblem(id).then((res) => {
      //   console.log(res);
      // });
      // getCheck(id).then(res=>{
      //   console.log(res);
      // })
    },
    handleClose(isClose) {
      this.dialogVisible = false;
      if (isClose) {
        this.$emit("CloseData");
      }
    },
  },
};
</script>

<style>
</style>