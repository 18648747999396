import { httpInit } from "./httpWs"
const http = httpInit("/")


// 新建聊天室
// export const addRoom = params => http.post(`/web/im/room/info`, params)
export const addRoom = params => http.post(`/web/im/room/info`, params)

// 退出聊天室
export const delRoom = params => http.delete(`/web/im/room/member/leave/${params.roomId}/${params.requestId}`)

//解散指定的聊天会话
export const delAllRoom = params => http.delete(`/web/im/room/info/${params.id}/${params.memberId}`)

//根据用户id查找他所在的所有聊天回话
export const userIdGetRoom = params => http.get(`/web/im/room/list/${params}`)

