// 上传图片用
<template>
  <div>
    <el-upload
      action="action"
      :http-request="handleUpload"
      list-type="picture-card"
      :on-remove="handleRemoveCover"
      :file-list="list"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <!-- <el-dialog
      class="system-modal editDialog"
      title="正在进行上传"
      :visible.sync="isFinally"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <el-progress
        :text-inside="true"
        :stroke-width="20"
        :percentage="percent"
      ></el-progress>
      <p>正在上传...</p>
    </el-dialog> -->
  </div>
</template>

<script>
import { uploadThumb } from "@/api/upload";
export default {
  name: "CoverUploader",
  props: {
    value: {
      type: Array,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    value(e) {
      let temp = [];
      if (e && e.length) {
        e.forEach((url) => {
          let res = this.list.find((item) => item.url === url);
          if (res) {
            temp.push(res);
          } else {
            temp.push({
              url: url,
              name: url,
            });
          }
        });
      }
      this.list = [...temp];
    },
  },
  data() {
    return {
      list: [],
      percent: 0,
      // isFinally: false,
    };
  },
  methods: {
    handleUploadImg() {},
    async handleUpload(args) {
      // this.isFinally = true;
      this.percent = 0;
      let res = await uploadThumb(args.file, (e) => {
        this.percent = ((e.loaded / e.total) * 100) | 0;
        return args.onProgress({ percent: this.percent });
      });
      if (res.code === 0) {
        this.list.push({
          uid: args.file.uid,
          url: res.data.uri,
          name: res.data.originalName,
        });
        args.onSuccess(res, args.file, this.list);
        this.emitEvent();
        this.emitEvent2();
        // this.isFinally = false;
        this.$message.success("上传成功");
      } else {
        this.message.error("上传失败");
        // this.isFinally = false;
      }
    },
    handleRemoveCover(file) {
      let index = this.list.findIndex((v) => v.url === file.url);
      this.list.splice(index, 1);
      this.emitEvent();
      this.emitEvent2();
    },
    emitEvent() {
      this.$emit(
        "change",
        this.list.map((v) => v.url)
      );
    },
    emitEvent2() {
      this.$emit("uploadChange", this.list);
    },
  },
};
</script>

<style></style>
