import { httpInit } from "./http"

const http = httpInit("/gzdt-role")

//分页查询角色列表
export const getRole = params =>
  http.get(
    `/role/page/${params.name}/${params.companyId}/${params.departmentId}/${params.parentId}/${params.unitType}/${params.pageNo}/${params.pageSize}`
  );
// 查询全部角色）
export const getRoleApi = params => http.post(`/api/listNew`, params)
//新增角色
export const addRole = params => http.post(`/role/info`, params)

//修改角色
export const updateRole = params => http.put(`/role/info/${params.id}`, params)

//删除角色
export const deleteRole = id => http.delete(`/role/info/${id}`)

//查询角色详情
export const getRoleInfo = id => http.get(`/role/info/${id}`)

//查询所有角色
export const getRoleList = params =>
  http.get(
    `/role/list/${params.name}/${params.companyId}/${params.departmentId}/${params.parentId}`,
    params
  )

//分页查询角色列表
export const getRoles = params =>
  http.get(
    `/role/page/${params.name}/${params.companyId}/${params.departmentId}/${params.parentId}/${params.unitType}/${params.pageNo}/${params.pageSize}`
  )
  export const queryRoleById = params => http.get(`/api/info/${params.id}`);
  
