<template>
  <div class="content">
    <el-form
      ref="form"
      :model="form"
      label-width="130px"
      size="mini"
      width="100%"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="事件类型：">
            <el-input
              v-model="form.typeName"
              readonly
              placeholder="事件类型"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发生时间：">
            <el-input
              v-model="form.created"
              readonly
              placeholder="发生时间"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="上报人：">
            <el-input
              v-model="form.publisherName"
              readonly
              placeholder="上报人"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="负责人：">
            <el-input
              v-model="form.chargeName"
              readonly
              placeholder="负责人"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="地理位置：">
            <el-input
              v-model="form.xycoors"
              readonly
              placeholder="地理位置"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工程项目名称：">
            <el-input
              v-model="form.siteName"
              readonly
              placeholder="工程项目名称"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="施工单位：">
            <el-input
              v-model="form.constructionUnitName"
              readonly
              placeholder="施工单位"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="监理单位：">
            <el-input
              v-model="form.supervisorUnitName"
              readonly
              placeholder="监理单位"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="事件经过：">
        <el-input
          readonly
          type="textarea"
          resize="none"
          placeholder="请输入作业内容、事发情形等"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="form.historyRecord"
        ></el-input>
      </el-form-item>
      <el-form-item label="伤亡情况：">
        <el-input
          readonly
          type="textarea"
          resize="none"
          placeholder="请输入人员伤亡、直接经济损失和影响范围"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="form.casualties"
        ></el-input>
      </el-form-item>
      <el-form-item label="处置情况：">
        <el-input
          readonly
          type="textarea"
          resize="none"
          placeholder="请输入应急救援、已采取和拟采取的措施等"
          v-model="form.disposal"
        ></el-input>
      </el-form-item>
      <el-form-item label="照片或附件:" style="display: flex; justify-content: left">
        <div class="files" style="display: flex; flex-direction: column">
          <a
            v-for="(item, index) in imgs"
            :key="index"
            :href="item.url"
            target="_blank"
            style="color: #409eff"
            >{{ item.originalName }}</a
          >
        </div>
      </el-form-item>
      <el-form-item>
        <!-- this.unitType -->
        <!-- 建管部总监、副总监、总工程师室主任 -->
        <el-button
          type="primary"
          v-show="form.isConfirm == 0"
          @click="onSubmit"
          v-has="'index:eventConfig'"
          >事件确认</el-button
        >
        <el-button
          type="primary"
          v-if="form.isConfirm == 1"
          v-has="'index:checkDetail'"
          @click="onDetail"
          >查看详情</el-button
        >
        <el-button
          @click="clickKonw()"
          v-show="form.isConfirm == 0"
          v-has="'index:iKonw'"
          >暂不上报</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { detailsList, detailsConfig } from "@/api/response";

export default {
  components: {},
  props: {
    newsData: Object,
    unitType: String,
  },
  data() {
    return {
      form: {},
      imgs: [],
    };
  },
  watch: {
    newsData: {
      handler(newVal) {
        console.log(newVal);
        this.getInfo();
      },
    },
    deep: true,
    immediate: false,
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      let params = {
        uuid: this.newsData.uuid,
      };
      detailsList(params).then((res) => {
        if (res.code == 0) {
          this.form = res.data;
          this.imgs = [];
          this.form.chargeName = res.data.chargeName ? res.data.chargeName : "";
          if (res.data.processList && res.data.processList.length) {
            res.data.processList.forEach((element) => {
              if (element.enclosure) {
                JSON.parse(element.enclosure).forEach((list) => {
                  this.imgs.push(list);
                });
              }
            });
          }
        }
      });
    },
    onDetail() {
      this.$emit("colsenewsLetters");
      setTimeout(() => {
        localStorage.setItem("detirler", JSON.stringify(this.form));
        this.$router.push({
          path: "/Screen/Contingency/Outburst",
          query: {
            data: JSON.stringify(this.form),
          },
        });
      }, 500);
    },
    onSubmit() {
      let params = {
        uuid: this.newsData.uuid,
      };
      detailsConfig(params).then((res) => {
        if (res.code == 0) {
          this.$emit("colsenewsLetters");
          setTimeout(() => {
            localStorage.setItem("detirler", JSON.stringify(this.form));
            this.$router.push({
              path: "/Screen/Contingency/Outburst",
              query: {
                data: JSON.stringify(this.form),
              },
            });
          }, 500);
        }
      });
    },
    clickKonw() {
      this.$emit("colsenewsLetters");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner,
::v-deep .el-textarea__inner {
  background: #081742;
  border-color: #25458f;
  color: #fff;
  font-size: 16px;
}
.el-select .el-input.is-focus .el-input__inner {
  border-color: #173f58;
}
::v-deep .el-input__inner:hover,
::v-deep .el-textarea__inner:hover {
  border-color: #25458f;
}
::v-deep .el-form-item__label {
  font-size: 16px;
  color: #fff;
}
::v-deep .el-form-item__content {
  display: flex;
  justify-content: center;
  margin-left: 0 !important;
}
</style>
