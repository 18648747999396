<template>
  <div class="min-h-full bg-white">
    <div class="flex justify-between p-4">
      <el-row class="flex">
        <popup-tree-input
          v-if="deviceType[0].children"
          :data="deviceType"
          :props="deptTreeProps"
          placeholder="设备类型"
          :prop="queryParams.deviceName"
          :nodeKey="queryParams.equipmentType"
          :currentChangeHandle="lineTreeCurrentChangeHandle"
        ></popup-tree-input>

        <el-button style="margin-left:20px" type="primary" @click="getList"
          >搜索</el-button
        >
      </el-row>

      <el-row>
        <el-button type="primary" @click="handleAdd">新增</el-button>
      </el-row>
    </div>

    <div class="px-4">
      <el-table
        :data="tableList"
        :row-style="{ height: '61px' }"
        border
        size="mini"
        v-loading="loading"
        style="overflow: auto"
      >
        <el-table-column
          type="index"
          label="序号"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="equipmentTypeName"
          show-overflow-tooltip
          label="设备类型"
          width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="checkItem"
          show-overflow-tooltip
          label="检查项目"
          width="300"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="checkContent"
          show-overflow-tooltip
          label="检查内容"
          align="center"
        ></el-table-column>

        <el-table-column width="200" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              icon="el-icon-edit"
              size="mini"
              style="margin-left: 0"
              @click="handleUpdate(scope.row)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              style="margin-left: 5px"
              @click="handleDelete(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="toolbar" style="padding: 10px">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="refreshPageRequest"
          :current-page.sync="queryParams.pageNum"
          :page-size="queryParams.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="totalSize"
          style="text-align: right"
        ></el-pagination>
      </div>
    </div>

    <!-- Dialog -->
    <el-dialog
      :title="title"
      v-if="dialogFormVisible"
      :visible.sync="dialogFormVisible"
      width="40%"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :before-close="handleDialogClose"
    >
      <el-form
        ref="dataForm"
        :rules="rules"
        :model="form"
        size="small"
        label-width="100px"
      >
        <el-form-item label="设备类型" prop="equipmentType">
          <popup-tree-input
            :data="deviceType"
            :props="deptTreeProps"
            placeholder="设备类型"
            :prop="deviceNameAdd"
            :nodeKey="form.equipmentType"
            :currentChangeHandle="lineTreeCurrentChangeHandleEqu"
            :disabled="title == '编辑'"
          ></popup-tree-input>
        </el-form-item>
        <el-form-item label="检查项目" prop="checkItemValue">
          <el-select
            style="width: 100%"
            v-model="form.checkItemValue"
            clearable
          >
            <el-option
              v-for="item in checkList"
              :key="item.id"
              :label="item.label"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- checkItemValue -->
        <el-form-item label="检查内容" prop="checkContent">
          <el-input v-model="form.checkContent" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          v-if="title != '详情'"
          @click="submit"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import popupTreeInput from "./components/popupTreeInput";
import {
  deviceMSaveOrUpdate,
  deleteDecive,
  findDevicePage
} from "@/api/deviceManagement";
import { getDictData } from "@/api/dict";

export default {
  components: {
    popupTreeInput
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        siteIdList: [],
        deviceName: ""
      },
      deviceType: [
        {
          children: []
        }
      ],
      deptTreeProps: {
        label: "label",
        children: "children"
      },
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      tableList: [],
      loading: false,
      totalSize: 0,
      title: "",
      dialogFormVisible: false,
      lookNamePoptPPE: true,
      editLoading: true,
      flatTreeData: [],
      form: {
        equipmentType: "",
        equipmentTypeName: "",
        checkItemValue: "",
        checkItem: "",
        checkContent: ""
      },
      rules: {
        equipmentType: [
          { required: true, message: "设备类型不能为空", trigger: "change" }
        ],
        checkItemValue: [
          { required: true, message: "检查项目不能为空", trigger: "change" }
        ],
        checkContent: [
          { required: true, message: "检查内容不能为空", trigger: "blur" }
        ]
      },
      size: "small",
      deviceNameAdd: "",
      checkList: []
    };
  },
  created() {
    this.getEquSelect();
    this.getLineSiteTreeData();
    this.getList();
  },
  methods: {
    getEquSelect() {
      getDictData("equipment_type").then(res => {
        if (res.code == 0) {
          this.deviceType = res.data.map(item => {
            item.id = item.code;
            item.children = [];
            return item;
          });
          let largeEquipment = [];
          let mechanicalEquipment = [];
          getDictData("mechanicalEquipment").then(res => {
            mechanicalEquipment = res.data;
            this.deviceType.forEach(item => {
              if (item.intro === "mechanicalEquipment") {
                item.children = mechanicalEquipment.map(item => {
                  item.id = item.code;
                  return item;
                });
              }
            });
          });
          getDictData("largeEquipment").then(res => {
            largeEquipment = res.data;
            this.deviceType.forEach(item => {
              if (item.intro === "largeEquipment") {
                item.children = largeEquipment.map(item => {
                  item.id = item.code;
                  return item;
                });
              }
            });
          });
        }
      });
    },
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
      this.flatArr(lineSiteData);
    },
    flatArr(data) {
      for (let item of data) {
        this.flatTreeData.push(item);
        if (item.children) {
          this.flatArr(item.children);
        }
      }
    },
    lineTreeCurrentChangeHandle(data) {
      if (!data.children) {
        this.queryParams.deviceName = data.intro;
        this.queryParams.equipmentType = data.id;
        this.getList();
      }
    },
    lineTreeCurrentChangeHandleEqu(data) {
      console.log(data);
      if (!data.children) {
        this.deviceNameAdd = data.label;
        this.form.equipmentType = data.id;
        this.form.equipmentTypeName = data.label;
        this.form.checkItemValue = "";
        let name = data.code + "_checkItem";
        this.getCheckList(name, data.label);
      }
    },
    getCheckList(value, name) {
      this.checkList = [];
      getDictData(value).then(res => {
        if (res.code == 0) {
          this.checkList = res.data;
        } else {
          this.$message({
            type: "warning",
            message:
              "暂未匹配到相关检查项，请前往字典表新增" +
              name +
              "检查项,值为" +
              value
          });
        }
        console.log(this.checkList);
      });
    },
    getList() {
      findDevicePage(this.queryParams).then(res => {
        if (res.code == 200) {
          this.tableList = res.data.list;
          this.totalSize = res.data.total;
          this.loading = false;
        }
      });
    },

    // 显示编辑界面
    handleUpdate(row) {
      this.title = "编辑";
      this.dialogFormVisible = true;
      this.form = { ...row };
      this.deviceNameAdd = row.equipmentTypeName;
      let name = row.equipmentType + "_checkItem";
      this.getCheckList(name, row.equipmentTypeName);
    },
    // 删除
    handleDelete(row) {
      let id = (row && row.id) || this.multipleSelection;
      this.$confirm("确认删除选中记录吗？", "提示", {
        type: "warning"
      }).then(() => {
        deleteDecive(id).then(res => {
          if (res.code == "200") {
            this.getList();
            this.$message({ message: "删除成功", type: "success" });
          }
        });
      });
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    //翻页
    refreshPageRequest(pageNum) {
      this.queryParams.pageNum = pageNum;
      this.getList();
    },
    handleAdd() {
      this.title = "新增";
      this.resetForm();
      this.dialogFormVisible = true;
      this.lookNamePoptPPE = true;
      if (this.$refs["dataForm"]) {
        this.$refs["dataForm"].clearValidate();
      }
    },
    handleDialogClose() {
      this.dialogFormVisible = false;
      this.resetForm();
    },
    resetForm() {
      this.form = {
        equipmentType: "",
        equipmentTypeName: "",
        checkItemValue: "",
        checkItem: "",
        checkContent: ""
      };
      this.deviceNameAdd = "";
      this.checkList = [];
    },
    // 表单提交
    submit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.form.checkItem = this.checkList.find(
              i => i.code == this.form.checkItemValue
            ).label;
            this.editLoading = true;
            const that = this;
            let params = Object.assign({}, that.form);
            deviceMSaveOrUpdate(params).then(res => {
              this.editLoading = false;
              if (res.code == 200) {
                this.$message({ message: "操作成功", type: "success" });
                this.dialogFormVisible = false;
                this.$refs["dataForm"].resetFields();
              } else {
                this.$message({
                  message: "操作失败, " + res.msg,
                  type: "error"
                });
              }
              this.getList();
            });
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-cascader .el-input {
  width: 202px;
}
</style>
