<template>
  <div class="content">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="120px"
      size="mini"
    >
      <el-form-item label="工点名称:">
        <el-cascader
          style="width: 100%"
          ref="treeSree"
          filterable
          clearable
          v-model="form.lineSite"
          placeholder="选择工点"
          :props="props"
          :options="treeData"
          :show-all-levels="false"
          @change="handleLineSiteChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="事件类型:">
        <el-select
          v-model="form.type"
          placeholder="请选择事件类型"
          style="width: 100%"
        >
          <el-option
            v-for="item in dictType"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发生时间:">
        <el-date-picker
          style="width: 100%"
          v-model="form.startTime"
          type="datetime"
          placeholder="选择发生时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="地理位置:" prop="xycoors">
        <el-input
          v-model="form.xycoors"
          readonly
          style="width: 80%; float: left"
          placeholder="地理位置"
        ></el-input
        ><el-button style="float: right" @click.prevent="selectLocation()"
          >选择位置</el-button
        >
      </el-form-item>
      <el-form-item label="经度:" prop="xcoor">
        <el-input v-model="form.xcoor" readonly placeholder="经度"></el-input>
      </el-form-item>
      <el-form-item label="纬度:" prop="ycoor">
        <el-input v-model="form.ycoor" readonly placeholder="纬度"></el-input>
      </el-form-item>
      <el-form-item label="工程项目名称:">
        <el-input
          v-model="form.siteName"
          readonly
          placeholder="工程项目名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="施工单位:">
        <el-input
          v-model="form.constructionUnitName"
          readonly
          placeholder="施工单位"
        ></el-input>
      </el-form-item>
      <el-form-item label="监理单位:">
        <el-input
          v-model="form.supervisorUnitName"
          readonly
          placeholder="监理单位"
        ></el-input>
      </el-form-item>
      <el-form-item label="事件经过:">
        <el-input
          type="textarea"
          resize="none"
          placeholder="请输入作业内容、事发情形等"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="form.historyRecord"
        ></el-input>
      </el-form-item>
      <el-form-item label="伤亡情况:">
        <el-input
          type="textarea"
          resize="none"
          placeholder="请输入人员伤亡、直接经济损失和影响范围"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="form.casualties"
        ></el-input>
      </el-form-item>
      <el-form-item label="处置情况:">
        <el-input
          type="textarea"
          resize="none"
          placeholder="请输入应急救援、已采取和拟采取的措施等"
          v-model="form.disposal"
        ></el-input>
      </el-form-item>
      <el-form-item label="上报人:">
        <el-input v-model="form.publisherName" readonly></el-input>
      </el-form-item>
      <el-form-item label="附件" prop="file">
        <FileUploader
          @uploadChange="handleFileUpload"
          @delChange="handleFileDel"
          :value="[]"
        ></FileUploader>
      </el-form-item>
      <el-form-item
        style="display: flex; justify-content: center; margin-left: 0"
      >
        <el-button type="primary" @click="onSubmit">确定</el-button>
        <el-button @click="colseDialog">取消</el-button>
      </el-form-item>
    </el-form>
    <!-- <Modal
      class="modalDetail"
      ref="chioceDiallog"
      :modal="false"
      :width="'800px'"
      title="选择位置"
      @colseDialog="colseDialog"
    >
      <div
        class="table-wrapper p-4 h-full"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      ></div>
    </Modal> -->

    <MapForm ref="mapForm" :getCoo="getCoo"></MapForm>
  </div>
</template>

<script>
import FileUploader from "@/components/Uploader/FileUploader.vue";
// import Modal from "@/components/Modal/modal.vue";
import MapForm from "./map.vue";
import { getCodeDictDetails, queryDictNodesById } from "@/api/dict";
import { emergencyResponse } from "@/api/response";

export default {
  props: ["infoNewsData"],
  components: {
    FileUploader,
    // Modal,
    MapForm,
  },
  data() {
    return {
      form: {
        lineSite: [],
        lineId: "",
        lineUuid: "",
        siteId: "",
        siteName: "",
        siteUuid: "",
        type: "",
        startTime: "",
        xcoor: null,
        ycoor: null,
        xycoors: "",
        constructionUnitId: "",
        constructionUnitName: "",
        supervisorUnitId: "",
        supervisorUnitName: "",
        historyRecord: "",
        casualties: "",
        disposal: "",
        enclosure: "",
        publisherName: JSON.parse(localStorage.user).realName,
        publisherId: JSON.parse(localStorage.user).uuid,
        // siteAlarmUuid: ""
      },
      rules: {
        xycoors: [
          { required: true, message: "请输入地理位置", trigger: "change" },
        ],
      },
      props: {
        children: "children",
        label: "name",
        value: "uuid",
      },
      treeData: [],
      //扁平的树
      faltArr: [],
      dictType: [],
      fileList: [],
    };
  },
  mounted() {
    this.getCoo();
    this.getLineSiteTreeData();
    this.getDictType();
    this.modelBlank();
  },
  methods: {
    modelBlank() {
      this.form = {
        lineSite: [],
        lineId: "",
        lineUuid: "",
        siteId: "",
        siteName: "",
        siteUuid: "",
        type: "",
        startTime: "",
        xcoor: null,
        ycoor: null,
        xycoors: "",
        constructionUnitId: "",
        constructionUnitName: "",
        supervisorUnitId: "",
        supervisorUnitName: "",
        historyRecord: "",
        casualties: "",
        disposal: "",
        enclosure: "",
        publisherName: JSON.parse(localStorage.user).realName,
        publisherId: JSON.parse(localStorage.user).uuid,
        // siteAlarmUuid: this.infoNewsData ? this.infoNewsData.uuid : ""
      };
      this.fileList = [];
    },
    getDictType() {
      getCodeDictDetails("eventType").then((res) => {
        queryDictNodesById(res.data).then((r) => {
          this.dictType = r.data;
        });
      });
    },
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
        this.treeData.forEach((item) => {
          this.flatArray(item);
        });
      }
    },
    flatArray(data) {
      this.faltArr.push(data);
      if (data.children && data.children.length > 0) {
        data.children.forEach((item) => {
          this.flatArray(item);
        });
      }
    },
    handleLineSiteChange(val) {
      if (val && val.length > 0) {
        this.form.lineUuid = val[0];
        this.form.siteUuid = val[val.length - 1];
        let site = this.faltArr.find((item) => item.uuid == this.form.siteUuid);
        let line = this.faltArr.find((item) => item.uuid == this.form.lineUuid);
        this.form.siteName = site.name;
        this.form.constructionUnitId = site.constructionUnitId;
        this.form.constructionUnitName = site.constructionUnitName;
        this.form.supervisorUnitId = site.supervisorUnitId;
        this.form.supervisorUnitName = site.supervisorUnitName;
        this.form.siteId = site.id;
        this.form.lineId = line.id;
      }
    },
    onSubmit() {
      this.form.enclosure = this.fileList.join(",");
      // this.form.enclosure = this.fileList;
      this.dictType.forEach((t) => {
        if (this.form.type == t.code) {
          this.form.typeName = t.label;
        }
      });
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          emergencyResponse(this.form).then((res) => {
            this.loading = this.false;
            if (res.code == 0) {
              this.$message.success("发布成功");
              this.$emit("infoColseDialog");
              console.log(this.form, "5555555");
              setTimeout(() => {
                 localStorage.setItem("detirler", JSON.stringify(this.form));
                this.$router.push({
                  path: "/Screen/Contingency/Outburst",
                  query: {
                    data: JSON.stringify(this.form),
                  },
                });
              }, 500);
            }
          });
        }
      });
    },

    handleFileUpload(e) {
      this.fileList.push(e.url);
    },
    handleFileDel(e) {
      console.log(e);
    },

    getCoo(lng, lat, address) {
      //lng经度  lat纬度
      (this.form.xycoors = address),
        (this.form.xcoor = lng),
        (this.form.ycoor = lat),
        this.$forceUpdate();
    },
    // 选择位置
    selectLocation() {
      // this.$refs.chioceDiallog.handleOpen();
      this.$refs.mapForm.handleAdd(
        this.form.longitude,
        this.form.latitude,
        this.form.address
      );
    },
    colseDialog() {
      this.$emit("infoColseDialog");
    },
    resetFieldClose() {
      // this.$refs['form'].resetFields()
      // 比上方获取demo的清理方式更彻底
      const th = this;
      Object.keys(this.form).forEach(function (key) {
        th.form[key] = "";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner,
::v-deep .el-textarea__inner {
  background: #081742;
  border-color: #25458f;
  color: #fff;
  font-size: 16px;
}
.el-select .el-input.is-focus .el-input__inner {
  border-color: #173f58;
}
::v-deep .el-input__inner:hover,
::v-deep .el-textarea__inner:hover {
  border-color: #25458f;
}
::v-deep .el-form-item__label {
  font-size: 16px;
  color: #fff;
}
// .el-select-dropdown__item {
//   color: #fff;
// }
.el-select-dropdown__item.selected,
.el-select-dropdown__item:hover {
  // background: #081742;
  // background: #002e75;
  color: #409eff !important;
}
</style>
