import { httpInit } from "./http";
const http = httpInit("/gzdt-linesite");

// 查询地图线路工点树
export const queryLineSiteTree = params =>
  http.get("/api/linesite/tree", params);

//查询工点详情
export const getPointInfo = id => http.get(`/api/linesite/info_pub/${id}`);


//查询大型设备统计接口
export const getStatis = params =>
  http.get(
    `/api/machine/statis/${params.type}/${params.lineId}/${params.siteId}/${params.state}/${params.is_special}/${params.is_using}`
  );

//查询大型设备列表
// export const getList = params =>
//   http.get(
//     `/api/machine/list/${params.type}/${params.lineId}/${params.siteId}/${params.state}/${params.is_special}/${params.is_using}`
//   );

// 根据线路id、工点id查询大型设备
export const getMachineById = params =>
  http.get(`/api/machine/statis/-/${params.lineId}/${params.siteId}/-/-/-`);

// 雨量实时监控
export const getRain = params =>
  http.get(
    `/linesite/monitor/rain/${params.name}/${params.page}/${params.size}`
  );

// 查询工点气象
export const getLineSiteWeather = params =>
  http.get(`/linesite/monitor/${params.lineId}/${params.siteId}`);

//风力实时监控
export const getWind = params =>
  http.get(
    `/linesite/monitor/wind/${params.name}/${params.page}/${params.size}`
  );

// 24小时监控统计
export const getMonitorLog = params =>
  http.get(`/linesite/monitor/log/${params.type}`);

// 监控设备统计
export const getEquipmentStatis = params =>
  http.get(`/linesite/monitor/${params.type}`);

//分页查询操作记录
export const drainagerList = params =>
  http.get(
    `/linesite/drainager/page/${params.siteId}/${params.state}/${params.date}/${params.keyword}/${params.page}/${params.size}/${params.type}`
  );

//新增操作记录
export const drainagerAdd = params =>
  http.post(`/linesite/drainager/add`, params);

//编辑排水记录(增加审核意见以及状态修改)
export const drainagerModify = params =>
  http.put(`/linesite/drainager/modify/${params.id}`, params);

//删除排水记录
export const drainagerDelete = params =>
  http.delete(`/linesite/drainager/info/${params.id}`, params);

//查询详情
export const drainagerInfo = params =>
  http.get(`/linesite/drainager/info/${params.id}`);

// 根据id查询操作的历史记录
export const drainagerLog = params =>
  http.get(`/linesite/drainager/record/list/${params.id}/${params.type}`);

//根据工点id查询所有图表
export const getImageInfo = params =>
  http.get(`/linesite/drainager/imageInfo/${params.id}`);

//工作台更新工点航拍图属性，系统将自动将前航拍图及关注重点、视频监控设备和物资库的平面坐标复制到航拍图历史记录中
export const airphotoModify = params => http.put(`/linesite/airphoto`, params);

//查询最新的航拍图信息，包括关注重点、视频监控设备、物资库。须验证数据权限
export const airphotoInfo = params => http.get(`/linesite/airphoto/${params}`);

// 查询工点的视频监控设备列表
export const siteVideos = params =>
  http.get(`/linesite/videos/${params.siteId}/${params.type}`);

//更新视频监控设备在所属工点航拍图上的平面坐标
export const siteVideosModify = params =>
  http.put(`/linesite/video/${params.Id}`, params);

//查询指定工点的物资库在航拍图上的平面坐标位置
export const siteMaterials = params =>
  http.get(`/linesite/materials/${params.siteId}`);

//更新指定工点的物资库在航拍图上的平面坐标位置
export const siteMaterialsModify = params =>
  http.put(`/linesite/materials/${params.siteId}`, params.list);

//查询指定工点航拍图历史变更时间线
export const airphotoHistory = params =>
  http.get(`/linesite/airphotoHistory/${params.siteId}`);

//清空工点航拍图历史记录
export const airphotoDelete = params =>
  http.delete(`/linesite/airphotoHistory/${params.siteId}`);

//数据中心首页统计加载
export const dataCenter = params =>
  http.get(`/linesite/dataCenter/info/${params.startDate}/${params.endDate}`);

//查询三级树-部门权限
export const tripleTree = data => http.post(`/linesiteNew/tree`, data);

//根据工点查询线路工点树
export const siteUuidP = params =>
  http.get(`/linesiteNew/tree/site/${params.siteId}`);

// 摄像头管理新增
export const addVideo = data => http.post(`/video/save`, data);

// 摄像头管理删除
export const deleteVideo = id => http.delete(`/video/delete/${id}`);

// 摄像头管理修改
export const updateVideo = data => http.post(`/video/update`, data);

// 摄像头管理列表查询
export const getVideoPage = data => http.post(`/video/page`, data);
//包含工点信息
export const worksiteTree = data => http.post(`/linesiteNew/tree_info`, data);



// 开始合并 ----------------------------------------------

//查询大型设备列表
export const getList = params =>
  http.get(
    `/api/machine/list/${params.type}/${params.lineId}/${params.siteId}/${params.state}/${params.is_special}/${params.is_using}`
  );

//条件查询线路工点列表
export const linesite = params =>
  http.get(
    `/linesite/list/map/${params.uuid}/${params.name}/${params.parent}/${params.category}/${params.state}/${params.workLevel}/${params.page}/${params.size}`
  );

//分页查询排水记录

export const drainager = params =>
  http.get(
    `/linesite/drainager/page/${params.lineId}/${params.state}/${params.date}/${params.keyword}/${params.page}/${params.size}/${params.type}`
  );

// 分页查询三防安全检查
export const querySafeCheckPage = params =>
  http.get(
    `/linesite/safeCheck/page/${params.lineId}/${params.checkDate}/${params.page}/${params.size}`
  );

// 新增三防安全检查
export const addSafeCheck = data => http.post(`/linesite/safeCheck/add`, data);

// 修改三防安全检查
export const editSafeCheck = data =>
  http.put(`/linesite/safeCheck/update`, data);

// 删除三防安全检查
export const delSafeCheck = id =>
  http.delete(`/linesite/safeCheck/delete/${id}`);

// 根据id查询三防安全检查详情
export const querySafeCheckDetail = id =>
  http.get(`/linesite/safeCheck/get/${id}`);

// 分页查询三防工作报告
// export const querysanWorkReportPage = (params) =>
//   http.get(
//     `/linesite/defenceMonthWorkReport/page/${params.lineId}/${params.updated}/${params.status}/${params.reportName}/${params.page}/${params.size}`
//   );
// 查询线路工点三级数
export const queryTripleTree = () => http.get(`/linesite/tripleTree`);

// 分页查询三防工作报告
export const querysanWorkReportPage = params =>
  http.get(
    `/linesite/defenceMonthWorkReport/page/${params.lineId}/${params.updated}/${params.status}/${params.reportName}/${params.page}/${params.size}`
  );

//获取本单位本月三防安全检查情况
export const getMonthSecurityCheck = params =>
  http.get(`/linesite/safeCheck/get/monthResult`, params);

//新增三防工作报告
export const WorkReportAdd = params =>
  http.post(`/linesite/defenceMonthWorkReport/add`, params);

//导出三防工作报告
export const WorkReportExport = id =>
  http.get(`/linesite/defenceMonthWorkReport/export/${id}`, {
    //重要  没有她导出的文件会打不开
    responseType: "blob"
  });

//根据ID查三防工作报告
export const WorkReportDetail = id =>
  http.get(`/linesite/defenceMonthWorkReport/get/${id}`);

//查询原来是否保存过三防月报
export const WorkReportisHave = params =>
  http.get(`/linesite/defenceMonthWorkReport/getSaveDate`, params);

//再有保存数据的情况下去更新保存
export const WorkReportUpdate = params =>
  http.put(`/linesite/defenceMonthWorkReport/update`, params);

//查询工点树-根据防汛重点关注
export const getfangxun = id => http.get(`/linesite/videos/type/${id}`);

//选择报告名称 查询是否有过该报告
export const getReportNameHave = params =>
  http.get(
    `/linesite/defenceMonthWorkReport/getDateBySite/${params.lineId}/${params.siteId}/${params.reportName}`
  );
// export const getfangxun = (id) => http.get(`/linesite/videos/type/${id}`);

//线路工点分页
export const triplePage = data => http.post(`/linesiteNew/page`, data);

//根据工点uuid-查询工点的视频监控设备列表
export const getTypeVideos = params =>
  http.get(
    `/linesite/videos/getSiteVideosByUuid/${params.siteUUID}/${params.type}`
  );

//线路工点新增
export const addProje = data => http.post(`/linesiteNew/save`, data);

//线路工点修改
export const aertProje = data => http.post(`/linesiteNew/update`, data);

//线路工点删除
export const deleProje = params =>
  http.delete(`/linesiteNew/delete/${params.uuids}`);


//监测设备查询
export const monitorStatistics = data => http.post(`/linesite/monitor/page`, data);

//摄像头列表
export const cameraList = data => http.post(`/video/use/page`, data);

//绑定
export const bindingList = data => http.post(`/video/use/binding`, data);

//更新弹窗列表
export const bindiedtrList = params => http.get(`/video/use/updateList?siteId=${params.siteId}&useCode=${params.useCode}`);

//摄像头单条解绑
export const delebinding = params =>
  http.put(`/video/use/unbinding/${params.useId}`, { headers: { 'Content-Type': 'application/json;charset=utf8' } });


//数据中心在建工点列表
export const buildingSite = params => http.get(`/linesite/dataCenter/info/buildingSite`, params);

//数据中心线路列表
export const infoLine = params => http.get(`/linesite/dataCenter/info/line`, params);


//  数据中心工点列表
export const infoSite = params => http.get(`/linesite/dataCenter/info/site`, params);

// 导入
export const importLineSite = data => http.post(`/linesiteNew/importLineSite`, data);

//下载
export const lineDownload = (data) => http({ url: `/linesiteNew/download`, data, method: "post", responseType: "blob", headers: { 'Content-Type': 'application/json;charset=utf8' } })
