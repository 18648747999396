<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" :title="title">
    <el-form v-loading="loading" :model="model" :rules="rules" ref="modelForm" label-width="120px" class="demo-model">
      <el-form-item label="线路工点" prop="lineSite">
        <el-cascader
          v-model="model.lineSite"
          :options="lineSiteData"
          @change="handleLineSiteChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="演练主题" prop="label">
        <el-input v-model="model.label" placeholder="请输入演练主题"></el-input>
      </el-form-item>
      <el-form-item label="演练类型" prop="category">
        <el-select v-model="model.category" placeholder="请选择演练类型">
          <el-option v-for="item of dictOption.category" :key="item.id" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="拟参与人数" prop="joinCount">
        <el-input v-model="model.joinCount" placeholder="请输入拟参与人数"></el-input>
      </el-form-item>
      <el-form-item label="是否汛期演练" prop="floodSeason">
        <el-radio-group v-model="model.floodSeason">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="演练地点" prop="address">
        <el-input v-model="model.address" placeholder="请输入演练地点"></el-input>
      </el-form-item>
      <el-form-item label="计划演练时间" prop="planDate">
        <el-date-picker
          v-model="model.planDate"
          type="month"
          placeholder="请选择计划演练时间"
          value-format="yyyy-MM"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="运营接口车站" prop="station" v-if="model.floodSeason">
        <el-row class="flex items-center" style="height: 40px;">
          <el-radio-group v-model="model.hasStation">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
          <el-input v-if="model.hasStation" class="flex-1 ml-4" v-model="model.station" placeholder="请输入运营接口车站"></el-input>
        </el-row>
      </el-form-item>
      <el-form-item label="周边街道名称" prop="street" v-if="model.floodSeason">
        <el-input v-model="model.street" placeholder="请输入周边街道名称"></el-input>
      </el-form-item>
      <el-form-item label="更改计划原因" prop="reason" v-if="model.id">
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 10}" v-model="model.reason" placeholder="请输入更改计划原因"></el-input>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { addRehearsal, editRehearsal } from "@/api/stand";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "新增",
      model: {
        lineSite: "",
        lineId: "",
        siteId: "",
        category: "",
        label: "",
        joinCount: "",
        floodSeason: false,
        address: "",
        planDate: "",
        hasStation: "false",
        station: "",
        street: "",
        reason: ""
      },
      photoList: [],
      dictOption: {
        state: [],
        category: []
      },
      loading: false,
      rules: {
        lineSite: [
          { required: true, message: '请选择线路工点', trigger: 'change' },
        ],
        label: [
          { required: true, message: '请输入演练主题', trigger: 'blur' },
        ],
        category: [
          { required: true, message: '请选择演练类型', trigger: 'change' },
        ],
        joinCount: [
          { required: true, message: '请输入拟参与人数', trigger: 'blur' },
        ],
        floodSeason: [
          { required: true, message: '请选择是否汛期', trigger: 'change' },
        ],
        address: [
          { required: true, message: '请输入演练地址', trigger: 'blur' },
        ],
        planDate: [
          { required: true, message: '请选择计划时间', trigger: 'change' },
        ],
      }
    };
  },
  props: {
    lineSiteData: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  mounted() {
    // 应急培训类型
    // const params = {
    //   label: "-",
    //   parent: "-",
    //   canModify: "-",
    //   page: 1,
    //   size: 1000,
    // }
    // queryDictList(params);
    this.getDictData("应急培训类型", this.dictOption.category);
  },
  methods: {
    getDictData(label, target) {
      queryDictByLabel({ label }).then(res => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then(res => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          })
        }
      });
    },
    // 线路工点树选择
    handleLineSiteChange(val) {
      this.model.lineId = val[0];
      this.model.siteId = val[1];
    },
    handleAdd(record) {
      if (record) {
        this.title = "更改演练计划";
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
        this.$set(this.model, "lineSite", [record.lineId, record.siteId]);
        if (record.station) {
          this.model.hasStation = true;
        }
      } else {
        this.title = "新增";
      }
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const params = JSON.parse(JSON.stringify(this.model));
          params.floodSeason = params.floodSeason.toString();
          if (this.model.id) {
            params.state = 0;
            editRehearsal(params).then(res => {
              if (res.code == 0) {
                this.loading = false;
                this.$message.success("操作成功");
                this.$emit("formSubmit");
                this.$refs.formModal.handleClose();
                this.resetModel();
              }
            });
          } else {
            addRehearsal(params).then(res => {
              if (res.code == 0) {
                this.loading = false;
                this.$message.success("操作成功");
                this.$emit("formSubmit");
                this.$refs.formModal.handleClose();
                this.resetModel();
              } else {
                this.loading = false;
              }
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
    urlChange(urlList) {
      this.photoList = [];
      console.log(urlList)
      for (let item of urlList) {
        this.photoList.push({
          file_name: item.name,
          link: item.url
        });
      }
    },
    resetModel() {
      this.model = {
        lineSite: "",
        lineId: "",
        siteId: "",
        label: "",
        teacher: "",
        planCount: "",
        localePhoto: []
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
