<template>
  <systemModal
    ref="formModal"
    @modalConfirm="handleSubmit"
    :title="title"
    width="70%"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="150px"
      class="demo-model"
      style="width: 90%"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item
            label="姓名"
            prop="workerName"
            v-if="typesr == 'stance'"
          >
            <el-input
              v-model="model.workerName"
              placeholder="请输入姓名"
            ></el-input
          ></el-form-item>
          <el-form-item
            label="姓名"
            prop="workerName"
            v-if="typesr !== 'stance'"
          >
            <el-popover
              ref="popover"
              placement="bottom"
              :disabled="disabled"
              trigger="click"
            >
              <el-tree
                ref="tree"
                :props="props"
                :data="tree"
                node-key="uuid"
                :load="loadNode"
                lazy
                accordion
                @current-change="deptTreeCurrentChangeHandle"
                @node-click="handleTreeCheck"
              >
              </el-tree>
            </el-popover>
            <el-input
              v-model="model.workerName"
              v-popover:popover
              :readonly="true"
              placeholder="请选择姓名"
              style="cursor: pointer"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="职务" prop="workRoleName">
            <el-select
              v-model="model.workRoleName"
              placeholder="请选择职务"
              @change="changeRole"
            >
              <el-option
                v-for="item of dict.workRole"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系电话" prop="workerPhone">
            <el-input
              v-model="model.workerPhone"
              readonly
              placeholder="请输入联系电话"
            ></el-input> </el-form-item
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="学历" prop="workerEducation">
            <el-select v-model="model.workerEducation" placeholder="请选择学历">
              <el-option
                v-for="item of dict.education"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select> </el-form-item
        ></el-col>
        <el-col :span="8">
          <el-form-item label="专业" prop="workerSpecialty">
            <el-input
              v-model="model.workerSpecialty"
              placeholder="请输入专业"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="8"></el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="特种作业证" prop="permit" label-width="150px">
            <div class="url-list" v-if="model.permit">
              <el-image :src="model.permit"> </el-image>
              <i
                class="el-icon-circle-close i"
                @click="imgDelete('permit')"
              ></i>
            </div>
            <image-uploader v-else v-model="model.permit"></image-uploader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="照片" prop="workerImg" label-width="150px">
            <div class="url-list" v-if="model.workerImg">
              <el-image :src="model.workerImg"> </el-image>
              <i
                class="el-icon-circle-close i"
                @click="imgDelete('workerImg')"
              ></i>
            </div>
            <image-uploader
              v-else
              v-model="model.workerImg"
            ></image-uploader> </el-form-item
        ></el-col>
        <el-col :span="8">
          <el-form-item
            label="三级安全教育"
            prop="threeLevelSafetyEduImg"
            label-width="150px"
          >
            <div class="url-list" v-if="model.threeLevelSafetyEduImg">
              <el-image :src="model.threeLevelSafetyEduImg"> </el-image>
              <i
                class="el-icon-circle-close i"
                @click="imgDelete('threeLevelSafetyEduImg')"
              ></i>
            </div>
            <image-uploader
              v-else
              v-model="model.threeLevelSafetyEduImg"
            ></image-uploader> </el-form-item
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item
            label="上岗前培训"
            prop="preJobTrainingImg"
            label-width="150px"
          >
            <div class="url-list" v-if="model.preJobTrainingImg">
              <el-image :src="model.preJobTrainingImg"> </el-image>
              <i
                class="el-icon-circle-close i"
                @click="imgDelete('preJobTrainingImg')"
              ></i>
            </div>
            <image-uploader
              v-else
              v-model="model.preJobTrainingImg"
            ></image-uploader> </el-form-item
        ></el-col>
        <el-col :span="8">
          <el-form-item
            label="技能培训"
            prop="skillsTrainingImg"
            label-width="150px"
          >
            <div class="url-list" v-if="model.skillsTrainingImg">
              <el-image :src="model.skillsTrainingImg"> </el-image>
              <i
                class="el-icon-circle-close i"
                @click="imgDelete('skillsTrainingImg')"
              ></i>
            </div>
            <image-uploader
              v-else
              v-model="model.skillsTrainingImg"
            ></image-uploader> </el-form-item
        ></el-col>
        <el-col :span="8">
          <el-form-item
            label="疫情防控资料"
            prop="epidemicPreventionDataImg"
            label-width="150px"
          >
            <div class="url-list" v-if="model.epidemicPreventionDataImg">
              <el-image :src="model.epidemicPreventionDataImg"> </el-image>
              <i
                class="el-icon-circle-close i"
                @click="imgDelete('epidemicPreventionDataImg')"
              ></i>
            </div>
            <image-uploader
              v-else
              v-model="model.epidemicPreventionDataImg"
            ></image-uploader> </el-form-item
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item
            label="个人年度体检报告"
            prop="medicalExaminationReportImg"
            label-width="150px"
          >
            <div class="url-list" v-if="model.medicalExaminationReportImg">
              <el-image :src="model.medicalExaminationReportImg"> </el-image>
              <i
                class="el-icon-circle-close i"
                @click="imgDelete('medicalExaminationReportImg')"
              ></i>
            </div>
            <image-uploader
              v-else
              v-model="model.medicalExaminationReportImg"
            ></image-uploader> </el-form-item
        ></el-col>
        <el-col :span="16"></el-col>
      </el-row>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import {
  addRescueTeamMember,
  editRescueTeamMember,
  getDeptAccountListV2,
} from "@/api/eres";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import ImageUploader from "@/components/Uploader/imgUp_copy.vue";
export default {
  components: {
    systemModal,
    ImageUploader,
  },

  data() {
    return {
      title: "新增",
      model: {
        workerName: "",
        workRoleName: "",
        workerPhone: "",
        workerEducation: "",
        workerSpecialty: "",
        workerImg: "",
        epidemicPreventionDataImg: "",
        medicalExaminationReportImg: "",
        permit: "",
        preJobTrainingImg: "",
        threeLevelSafetyEduImg: "",
        skillsTrainingImg: "",
      },
      tree: [],
      props: {
        children: "children",
        label: "name",
        isLeaf: "leaf",
      },
      disabled: false,
      loading: false,
      teamList: [],
      rules: {
        workerName: [
          { required: true, message: "请输入姓名", trigger: "change" },
        ],
        workRoleName: [
          { required: true, message: "请选择职务", trigger: "blur" },
        ],
        // workerPhone: [
        //   { required: true, message: '请输入手机号', trigger: 'change' },
        //   {
        //     pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
        //     message: '请输入合法手机号',
        //     trigger: 'blur',
        //   },
        // ],
        workerEducation: [
          { required: true, message: "请选择学历", trigger: "blur" },
        ],
        workerImg: [
          { required: true, message: "请上传照片", trigger: "change" },
        ],
        threeLevelSafetyEduImg: [
          {
            required: true,
            message: "请上传三级安全教育图片",
            trigger: "change",
          },
        ],
        preJobTrainingImg: [
          {
            required: true,
            message: "请上传上岗前培训图片",
            trigger: "change",
          },
        ],
        skillsTrainingImg: [
          { required: true, message: "请上传技能培训图片", trigger: "change" },
        ],
        epidemicPreventionDataImg: [
          {
            required: true,
            message: "请上传疫情防控资料图片",
            trigger: "change",
          },
        ],
        medicalExaminationReportImg: [
          {
            required: true,
            message: "请上传个人年度体检报告图片",
            trigger: "change",
          },
        ],
      },
      dict: {
        workRole: [],
        education: [
          {
            label: "小学",
            value: 0,
          },
          {
            label: "初中",
            value: 1,
          },
          {
            label: "高中",
            value: 2,
          },
          {
            label: "专科",
            value: 3,
          },
          {
            label: "本科",
            value: 4,
          },
          {
            label: "硕士",
            value: 5,
          },
          {
            label: "博士",
            value: 6,
          },
        ],
      },
      uuid: "",
    };
  },
  props: {
    orgTree: {
      type: Array,
      default: () => [],
    },
    typesr: {
      type: String,
    },
  },
  mounted() {
    this.getDictData("救援队职务", this.dict.workRole);
  },
  methods: {
    //图片删除
    imgDelete(name) {
      this.$confirm("删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (name == "permit") {
            this.model.permit = "";
          } else if (name == "workerImg") {
            this.model.workerImg = "";
          } else if (name == "threeLevelSafetyEduImg") {
            this.model.threeLevelSafetyEduImg = "";
          } else if (name == "preJobTrainingImg") {
            this.model.preJobTrainingImg = "";
          } else if (name == "skillsTrainingImg") {
            this.model.skillsTrainingImg = "";
          } else if (name == "epidemicPreventionDataImg") {
            this.model.epidemicPreventionDataImg = "";
          } else if (name == "medicalExaminationReportImg") {
            this.model.medicalExaminationReportImg = "";
          }
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // })
        });
    },
    changeRole(val) {
      let workRoleId = this.dict.workRole.filter((item) => {
        return item.label == val;
      })[0].id;
      this.model.workRoleId = workRoleId;
    },
    deptTreeCurrentChangeHandle(data, node) {
      console.log("data",data);
      console.log("node",node);
      this.model.workerName = data.name;
      this.model.workerUuid = data.uuid;
      this.model.workerPhone = data.contactPhone;
      this.$set(this.model, this.model.workerName, data.name);
    },
    handleTreeCheck(node) {
      if (node.children == undefined) {
        // 判断当前选中的数据是站点层的话 关闭当前选择框
        this.$refs.popover.showPopper = false;
      }
    },
    // 加载叶子节点
    async loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve(this.orgTree);
      }
      if (node.level != 0 && node.data.vendorOrDept == 0) {
        resolve(node.data.children);
      } else if (node.data.vendorOrDept == 1) {
        // 查询部门下的人员
        const params = {
          deptUuId: node.data.uuid,
        };
        let res = await getDeptAccountListV2(params);
        if (res) {
          const data = [];
          for (let item of res) {
            data.push({
              name: item.realName,
              uuid: item.accountUuid,
              contactPhone: item.contactPhone,
              leaf: true,
            });
          }
          resolve(data);
        } else {
          resolve([]);
        }
      } else {
        return false;
      }
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    handleAdd(teamInfo, record) {
      if (record) {
        this.title = "编辑";
        this.disabled = true;
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
      } else {
        this.title = "新增";
        this.disabled = false;
        this.model = {};
        this.model.teamId = teamInfo.teamId;
        this.model.teamName = teamInfo.teamName;
      }
      this.tree = JSON.parse(JSON.stringify(this.orgTree));
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.model.id) {
            const params = {
              id: this.model.id,
              data: this.model,
            };
            editRescueTeamMember(params)
              .then((res) => {
                if (res.code == 0) {
                  this.loading = false;
                  this.$message.success("操作成功");
                  this.$refs.formModal.handleClose();
                  this.$emit("formSubmit");
                }
              })
              .catch((err) => {
                this.$message.error(err.message);
                this.loading = false;
              });
          } else {
            addRescueTeamMember(this.model)
              .then((res) => {
                if (res.code == 0) {
                  this.loading = false;
                  this.$message.success("操作成功");
                  this.$refs.formModal.handleClose();
                  this.$emit("formSubmit");
                }
              })
              .catch((err) => {
                this.$message.error(err.message);
                this.loading = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-image {
  height: 150px;
}
.i {
  position: absolute;
  top: -5px;
  right: -5px;
  color: red;
  font-weight: 600;
}
.url-list {
  position: relative;
  display: inline-block;
}
</style>
