<template>
  <div class="content">
    <el-form
      ref="form"
      :model="form"
      label-width="100px"
      size="small"
      :rules="rules"
    >
      <el-form-item label="结束原因:" prop="endReason">
        <el-input
          type="textarea"
          placeholder="请输入结束原因"
          :autosize="{ minRows: 2, maxRows: 4 }"
          resize="none"
          v-model="form.endReason"
        ></el-input>
      </el-form-item>

      <el-form-item label="上报人:">
        <!--  <el-select
          v-model="form.uploadId"
          placeholder="请选择"
          style="width:100%"
        >
          <el-option label="区域一" value="1"></el-option>
          <el-option label="区域二" value="2"></el-option>
        </el-select> -->
        <el-input
          placeholder="请输入上报人"
          v-model="form.uploadName"
          :readonly="isreadonly"
        ></el-input>
      </el-form-item>
      <el-form-item label="操作人:">
        <el-input v-model="form.managerName" readonly></el-input>
      </el-form-item>
      <el-form-item style="margin-top:50px">
        <el-button type="primary" @click="onSubmit">确定</el-button>
        <el-button style="margin-left:50px" @click="colse">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { EndEvent } from "@/api/response";
export default {
  props: ["eventData"],
  data() {
    return {
      loading: false,
      form: {
        endReason: "",
        uploadName: "",
        managerName: JSON.parse(localStorage.user).realName,
        managerUuid: JSON.parse(localStorage.user).uuid,
      },
      rules: {
        endReason: [
          { required: true, message: "请输入结束原因", trigger: "blur" },
        ],
      },
      isreadonly: false, //当前上报人是否只读
    };
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.eventData, "eventData");
      if (this.eventData.alarmTypeName == "手机报警") {
        // this.form.uploadName=app一键上报的账号登录人
        this.form.uploadName = this.eventData.uploadName;
        this.isreadonly = true;
      } else {
        this.isreadonly = false;
      }
    });
  },
  methods: {
    onSubmit() {
      console.log("submit!", this.eventData);
      this.$refs.form.validate((valid) => {
        if (valid) {
          EndEvent(this.form).then((res) => {
            if (res.code == 0) {
              this.$emit("eventEndClose");
            }
          });
        }
      });
    },
    colse() {
      this.$emit("eventEndClose");
    },
  },
};
</script>

<style lang="scss" scoped>
// .el-select-dropdown__item {
//   color: #fff;
// }
.el-select-dropdown__item.selected,
.el-select-dropdown__item:hover {
  // background: #081742;
  color: #409eff !important;
}
.p-4 {
  padding-left: 0;
}
::v-deep .el-input__inner,
::v-deep .el-textarea__inner {
  background: #081742;
  border-color: #25458f;
  color: #fff;
}
.el-table--border::after {
  width: 0;
}
::v-deep .el-input__inner:hover,
::v-deep .el-textarea__inner:hover {
  border-color: #25458f;
}
</style>
