<template>
  <systemModal ref="formModal" :title="title" :showFooter="false" width="60%">
    <div class="search-modal">
      <el-form :inline="true" :model="queryModel">
        <el-form-item label="">
          <el-select v-model="queryModel.optionType" clearable clearableplaceholder="请选择操作类型">
            <el-option v-for="item of optionType" :label="item.label" :value="item.value" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" class="date">
          <el-date-picker v-model="date" type="datetimerange" clearable range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" @change="handleDateChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" style="width: 100%" v-loading="loading" border>
        <el-table-column type="index" align="center" label="序号" width="100"></el-table-column>
        <el-table-column prop="optionType" align="center" label="操作类型" :formatter="optionTypeLabel"></el-table-column>
        <el-table-column prop="num" align="center" label="数量"></el-table-column>
        <el-table-column prop="reason" align="center" label="原因"></el-table-column>
        <el-table-column prop="optionName" align="center" label="操作人"></el-table-column>
        <el-table-column prop="created" align="center" label="操作时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="remark" align="center" label="备注"></el-table-column>
      </el-table>
      <div class="pagination-wrapper flex justify-center mt-4">
        <el-pagination background layout="prev, pager, next" :total="page.count" :page-count="page.total"
          :page-size="page.pageSize" :current-page="page.pageNo" @current-change="handleCurrentChange"
          @size-change="handleSizeChange">
        </el-pagination>
      </div>
    </div>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { materialsHistory } from "@/api/eres";
export default {
  components: {
    systemModal
  },
  data() {
    return {
      title: "历史记录",
      queryModel: {
        endDate: "",
        id: "",
        optionType: "",
        startDate: ""
      },
      date: [],
      loading: false,
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      optionType: [
        {
          label: "入库/进场",
          value: 1
        },
        {
          label: "出库/出场",
          value: 2
        },
      ]
    };
  },
  props: {
    type: {
      type: Number
    }
  },
  watch: {
    type: {
      handler(newVal) {
        if (newVal == 125) {
          this.optionType = [
            {
              label: "入库",
              value: 1
            },
            {
              label: "出库",
              value: 2
            },
          ]
        } else {
          this.optionType = [
            {
              label: "进场",
              value: 1
            },
            {
              label: "出场",
              value: 2
            },
          ]
        }
      },
      deep: true,
      immediate: true
    }
  },
  filters: {},
  mounted() { },
  methods: {
    // 时间选择
    handleDateChange(val) {
      if (val) {
        this.queryModel.startDate = val[0];
        this.queryModel.endDate = val[1];
      } else {
        this.queryModel.startDate = "";
        this.queryModel.endDate = "";
      }
    },
    handleHistory(record) {
      this.queryModel.id = record.id;
      this.loadData();
      this.$refs.formModal.handleOpen();
    },
    // 搜索栏查询
    onSubmit() {
      this.loadData();
    },
    // 加载表格数据
    async loadData() {
      this.loading = true;
      const params = {
        endDate: this.queryModel.endDate,
        id: this.queryModel.id,
        materialsId: this.queryModel.id,
        optionType: this.queryModel.optionType,
        page: this.page.pageNo,
        size: this.page.pageSize,
        startDate: this.queryModel.startDate
      };
      let res = await materialsHistory(params);
      if (res.code == 0) {
        this.tableData = res.data.records;
        this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.page.total = res.data.total;
        this.page.count = res.data.count;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    optionTypeLabel(row) {
      const tempObj = this.optionType.find(item => item.value == row.optionType);
      if (tempObj) {
        return tempObj.label;
      }
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then(res => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then(res => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          })
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.search-modal {
  .date {
    width: 420px;
  }

  ::v-deep .el-input {
    width: 90%;
  }
}
</style>
