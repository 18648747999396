<template>
  <div class="home">
    <div :class="this.$route.query.rte==222?'contenert':'content' ">
      <div class="content-wrapper">
        <LeftIndex class="left-panel" ref="LeftIndex"></LeftIndex>
        <MapIndex class="center-panel" ref="MapIndex"></MapIndex>
        <BelowIndex
          class="center-below"
          ref="MapIndex"
          style="height:30%"
        ></BelowIndex>
        <RightIndex class="right-panel" ref="RightIndex"></RightIndex>
      </div>
    </div>
  </div>
</template>
<script>
import MapIndex from "./Map/index";
import LeftIndex from "./leftPage/index";
import RightIndex from "./rightPage/index";
import BelowIndex from "./belowPage/index";

export default {
  name: "HomePage",
  components: { MapIndex, LeftIndex, RightIndex, BelowIndex },
  data() {
    return {
      path: "",
      socket: "",
    };
  },
  created() {
    console.log(this.$route.query.rte)
    if(this.$route.query.rte==222){
     this.$EventBus.$emit('secrty',1)
    }
   },
  computed: {},
  methods: {},
  destroyed() {
    // 销毁ws
    // this.socket.close();
  },
};
</script>
<style lang="scss" scoped>
.home {
  ::v-deep {
    // 首页布局
    .content {
      width: 100%;
      height: calc(100vh - 88px - 0.25rem);
      .el-dialog {
        background: url("../../../assets/card-bg1.png") repeat 99%;
        background-size: 100% 100%;
        color: #fff;
      }
      .el-dialog,
      .el-pager li {
        border-radius: 10px;
        color: #fff;
      }
      .el-dialog__header {
        text-align: center;
      }
      .el-dialog__wrapper {
        background: rgba(0, 0, 0, 0.8);
      }
      .el-dialog__title {
        color: #fff;
      }
      .el-form-item__label {
        color: #fff;
      }
      .el-input__inner {
        background-color: rgba(2, 2, 6, 1);
        color: #fff;
        border-radius: 4px;
        border: 1px solid #4d4d4d;
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #f9aa33;
      }
      .content-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
     .contenert{
       width: 100%;
      height: 100vh;
       .el-dialog {
        background: url("../../../assets/card-bg1.png") repeat 99%;
        background-size: 100% 100%;
        color: #fff;
      }
      .el-dialog,
      .el-pager li {
        border-radius: 10px;
        color: #fff;
      }
      .el-dialog__header {
        text-align: center;
      }
      .el-dialog__wrapper {
        background: rgba(0, 0, 0, 0.8);
      }
      .el-dialog__title {
        color: #fff;
      }
      .el-form-item__label {
        color: #fff;
      }
      // .el-input__inner {
      //   background-color: rgba(2, 2, 6, 1);
      //   color: #fff;
      //   border-radius: 4px;
      //   border: 1px solid #4d4d4d;
      // }
      .el-dialog__headerbtn .el-dialog__close {
        color: #f9aa33;
      }
      .content-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
  }
  .left-panel {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    width: 20.8%;
    height: 100%;
  }
  .center-below {
    position: absolute;
    bottom: 0;
    left: 20.8%;
    z-index: 9;
    width: calc(100% - 41.6%);
    height: calc(100% - 80%);
  }
  .right-panel {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    width: 20.8%;
    height: 100%;
  }
  .center-panel {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
