<template>
  <div>
    <el-popover ref="popover" :placement="placement" :disabled="disabled" trigger="click">
      <el-tree
        :data="data"
        :props="props"
        :node-key="nodeKey"
        ref="popupTree"
        @current-change="currentChangeHandle"
        :highlight-current="true"
        :expand-on-click-node="true"
        @node-click="handleTreeCheck"
        :default-expanded-keys="defaultArrays"
        :current-node-key="checkId"
      >
      </el-tree>
    </el-popover>
    <el-input
      v-model="prop"
      v-popover:popover
      :readonly="true"
      :placeholder="placeholder"
      :disabled="disabled"
      style="cursor: pointer"
      suffix-icon="el-icon-arrow-down"
    ></el-input>
  </div>
</template>

<script>
export default {
  name: "PopupTreeInput",
  props: {
    data: {
      type: Array,
      default: []
    },
    props: {
      type: Object,
      default: {}
    },
    prop: {
      type: String,
      default: ""
    },
    nodeKey: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "点击选择内容"
    },
    placement: {
      type: String,
      default: "right-start"
    },
    defaultExpandAll: {
      type: Boolean,
      default: false
    },
    currentChangeHandle: {
      type: Function,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      /* type主要是区分当前选择框选择的是标段层，还是站点层；若是标段层则type为ture */ type: Boolean,
      default: false
    },
    defaultArray: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      defaultArrays: [],
      checkId: ""
    }
  },
  created() {
    if (this.defaultArray.length) {
      this.checkId = this.defaultArray.at(-1)
      this.defaultArrays = this.defaultArray.slice(0, this.defaultArray.length - 1)
    }
  },
  methods: {
    handleTreeCheck(node) {
      // 这里的type类型只用来区分标段和站点层；
      // 目前所使用的组件需求，并不是所有的选择框都适用选中某个值后关闭选择框；所以以下判断只针对部门页面
      if (node.type == "siteId" || !node.children) {
        // 判断当前选中的数据是站点层的话 关闭当前选择框
        this.$refs.popover.showPopper = false
      } else if (node.type == "workAreaId" && this.type == true) {
        // 判断当前选中的数据是标段层并且type类型为true(说明当前选择框需求是只能选择标段层)，关闭当前选择框
        this.$refs.popover.showPopper = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-tree {
  color: #606266;
  overflow: auto;
  max-height: 350px !important;
}
</style>
