<template>
  <systemModal
    ref="formModal"
    @modalConfirm="handleSubmit"
    :title="title"
    width="40%"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="120px"
      class="demo-model"
      style="width: 80%"
    >
      <el-form-item label="救援队名称">
        <el-input v-model="rescueTeamName" disabled></el-input>
      </el-form-item>
      <el-form-item :label="label" prop="importFile">
        <el-upload
          action="action"
          :http-request="uploadFile"
          :file-list="fileList"
          :before-upload="beforeUpload"
          :limit="1"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传xls/xlsx文件
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
    <!--  <el-dialog
      title="正在进行上传"
      :visible.sync="isFinally"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <el-progress
        :text-inside="true"
        :stroke-width="20"
        :percentage="percent"
      ></el-progress>
      <p>正在上传...</p>
    </el-dialog> -->
  </systemModal>
</template>
<script>
import systemModal from "@/components/Modal/systemModal.vue"
import { materialImport } from "@/api/eres"
import { upload } from "@/api/upload"

export default {
  components: {
    systemModal,
  },
  data () {
    return {
      title: "导入",
      model: {
        rescueTeamId: "",
        importFile: null,
      },
      rescueTeamName: "",
      flatTreeData: [],
      props: {
        children: "children",
        label: "name",
        value: "id",
      },
      action: "",
      fileList: [],
      loading: false,
      rules: {
        importFile: [
          { required: true, message: "请上传文件", trigger: "blur" },
        ],
      },
      // isFinally: false,
      percent: 0,
    }
  },
  props: {
    type: {
      type: Number,
    },
  },
  computed: {
    label () {
      if (this.type == 125) {
        return "物资导入"
      } else {
        return "设备导入"
      }
    },
  },
  mounted () { },
  methods: {
    handleImport (id, name) {
      this.model.rescueTeamId = id
      this.rescueTeamName = name
      this.$refs.formModal.handleOpen()
    },
    // 上传前调用
    beforeUpload (file) {
      console.log(file)
      const tempArr = file.name.split(".")
      const isValid = tempArr.includes("xls") || tempArr.includes("xlsx")
      if (!isValid) {
        this.$message.error("只支持xls、xlsx格式文件！")
        return false
      }
    },
    // 自定义上传方法
    async uploadFile (params) {
      this.model.importFile = params.file
      // this.isFinally = true;
      this.percent = 0
      const { file } = params
      let res = {}
      let fn = upload
      res = await fn(file, (e) => {
        console.log(e)
        this.percent = ((e.loaded / e.total) * 100) | 0
        return params.onProgress({ percent: this.percent })
        // return this.percent;
      }).catch((err) => {
        res.message = err
        console.error(err)
      })
      if (res.code === 0) {
        // this.isFinally = false;
        this.$message.success("上传成功")
      } else {
        this.message.error("上传失败")
        this.$emit("error", res.message)
        // this.isFinally = false;
      }
    },
    handleSubmit () {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true
          const data = new FormData()
          Object.keys(this.model).forEach((key) => {
            if (this.model[key]) {
              data.append(key, this.model[key])
            }
          })
          data.append("materialType", 2)
          data.append("type", this.type)
          materialImport(data).then((res) => {
            if (res.code == 0) {
              this.$message.success("操作成功！")
              this.loading = false
              this.formReset()
              this.$emit("importSub")
              this.$refs.formModal.handleClose()
            } else {
              this.$message.error(res.messsage)
              this.loading = false
            }
          }).catch(err => {
            this.loading = false
            this.$message.error('操作失败' + err.message)
          })
        } else {
          console.log("error submit!!")
          return false
        }
      })
      // this.$refs.formModal.handleClose();
    },
    formReset () {
      // this.$refs.modelForm.resetFields();
      this.model = {
        rescueTeamId: "",
        importFile: null,
      }
      this.fileList = []
    },
  },
};
</script>
<style lang="scss" scoped></style>
