<template>
  <div class="outbures">
    <div class="dialogBox" v-if="dialogVisible">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :modal="false"
        :before-close="handleClose"
      >
        <p style="margin-top: 5px">{{ contontes.noticeDescription }}</p>
        <p style="margin-top: 5px">{{ contontes.createTime }}</p>
        <div class="demo-input-suffix" v-if="inerty" style="margin-top: 5px">
          <span style="margin-top: 9px">驳回原因：</span>
          <el-input v-model="input" style="100px" placeholder="请输入内容">
          </el-input>
        </div>

        <span slot="footer" class="dialog-footer" v-if="ewert">
          <el-button
            @click="rejected"
            style="
              background: none;
              border-color: #3e9eff;
              color: #3e9eff;
              margin-top: 30px;
              margin-left: 35%;
            "
            >驳回</el-button
          >
          <el-button type="primary" @click="Startthe">启动</el-button>
        </span>
        <span slot="footer" class="dialog-footer" v-if="inerty">
          <el-button
            @click="rejectedet"
            style="
              background: none;
              border-color: #3e9eff;
              color: #3e9eff;
              margin-top: 30px;
              margin-left: 35%;
            "
            >确定驳回</el-button
          >
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button
            v-if="knowser"
            @click="know"
            style="
              background: none;
              border-color: #3e9eff;
              color: #3e9eff;
              margin-top: 30px;
              margin-left: 35%;
            "
            >确认</el-button
          >
          <el-button
            v-if="view"
            @click="viewers"
            style="
              background: none;
              border-color: #3e9eff;
              color: #3e9eff;
              margin-top: 30px;
              margin-left: 35%;
            "
            >我知道了</el-button
          >
          <el-button
            v-if="toviewers"
            @click="toview"
            style="
              background: none;
              border-color: #3e9eff;
              color: #3e9eff;
              margin-top: 30px;
              margin-left: 35%;
            "
            >请查看</el-button
          >
        </span>
      </el-dialog>
    </div>
    <BIM v-if="BimBool"></BIM>
    <mapindex
      v-else
      class="center-panel"
      ref="trecenr"
      :detalieltes="detalieltes"
      :filews="filew"
      @wisherty="withinLists"
    ></mapindex>

    <outLeft class="left-panel" v-if="filew" @detaliel="detaliel"></outLeft>

    <sidebares
      :class="filew == false ? 'left_ster' : 'left-side'"
      @pack="packs"
      :detaltes="detalieltes"
      :eventId="eventId"
      :messageId="messageId"
    ></sidebares>

    <responseStatisti
      class="right_top"
      v-if="filew"
      :detalieltes="detalieltes"
    ></responseStatisti>
    <resour
      class="right_bottom"
      v-if="filew"
      :detsiel="detalieltes"
      :within="within"
    ></resour>
    <div :class="!unfoldAndFold ? 'ump_top' : 'ump_button'">
      <div :class="!BimBool ? 'teo' : 'teos'" @click="gisClick">
        <img src="@/assets/zhanshi.png" alt="" />
      </div>
      <div class="teos">
        <img src="@/assets/erwei.png" alt="" />
      </div>
      <div :class="BimBool ? 'teo' : 'teos'" @click="teero">
        <img src="@/assets/bm.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import mapindex from "./components/mapindex.vue";
import outLeft from "./components/outburstLeft.vue";
import sidebares from "./components/sidebar.vue";
import resour from "./components/resource.vue";
import responseStatisti from "@/views/Contingency/HomePage/rightPage/responseStatistics";
import { emergencyEarly, confirmResponseUpgrade } from "@/api/response.js";
import BIM from "./BIM/index.vue";
export default {
  components: { mapindex, outLeft, sidebares, resour, responseStatisti, BIM },
  data() {
    return {
      filew: true,
      select: "",
      detalieltes: {},
      dialogVisible: false,
      path: "", //socket连接地址
      socket: null, //建立的连接
      lockReconnect: false, //是否真正建立连接
      timeout: 2 * 1000, //30秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时,
      user: {},
      title: "",
      contontes: {},
      gettime: "",
      inerty: false,
      ewert: false,
      knowser: false,
      view: false,
      toviewers: false,
      input: "",
      eventId: 0,
      messageId: "",
      BimBool: false,
      within: [],
      unfoldAndFold: true,
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
    var userId = this.user.uuid;

    this.path = "ws://47.95.238.93:9310/backlogMessage?userId=" + userId;
  },
  mounted() {
    // 初始化
    this.init();
    this.$nextTick(() => {
      let contentDom = document.querySelectorAll(".conent");
      contentDom = [...contentDom];
      console.log(contentDom);
      contentDom.forEach((item) => {
        let height = item.offsetHeight;
        console.log(height);
        if (height > 100) {
          // 超过高度，给该文本的父元素添加class
          this.anert = true;
          this.pack = false;
          this.showTotal = true;
        }
      });
    });
  },
  methods: {
    init() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        this.socket = new WebSocket(this.path);
        // 监听socket连接
        this.socket.onopen = this.open;
        // 监听socket错误信息
        this.socket.onerror = this.error;
        // 关闭socket
        this.socket.onclose = this.close;
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
      }
    },
    reconnect() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function() {
        that.init(); //新连接
        that.lockReconnect = false;
        console.log("重新连接中……");
        // that.path = "ws://219.153.100.85:8001/webSocket/" + that.siteId + "-" + that.uuid //模拟测试webscoket断线重连
      }, 1000 * 2);
    },
    reset() {
      //重置心跳
      var that = this;
      clearTimeout(that.timeoutObj); //清除时间
      clearTimeout(that.serverTimeoutObj); //清除时间
      // that.start(); //重启心跳
    },
    open() {
      console.log("socket连接成功");
      // this.start(); //开启心跳
    },
    error() {
      console.log("连接错误");
      //重连
      this.reconnect();
    },
    getMessage: function(msg) {
      console.log(JSON.parse(msg.data), "服务端发来消息");
      var serwrt = {};
      if (JSON.parse(msg.data).length > 1) {
        serwrt = JSON.parse(msg.data[0]);
      } else {
        serwrt = JSON.parse(msg.data);
      }
      if (serwrt.noticeType == "应急预警" && serwrt.noticeCategory == 1) {
        this.dialogVisible = true;
        this.knowser = false;
        this.inerty = false;
        this.ewert = true;
        this.title = serwrt.noticeType;
        this.contontes = serwrt;
        this.view = false;
        this.toviewers = false;
      } else if (
        serwrt.noticeType == "响应升级" &&
        serwrt.noticeCategory == 1
      ) {
        this.title = serwrt.noticeType;
        this.contontes = serwrt;
        this.dialogVisible = true;
        this.knowser = true;
        this.ewert = false;
        this.inerty = false;
        this.view = false;
        this.toviewers = false;
      } else if (serwrt.noticeType == "启动预案" && serwrt.noticeCode == 1000) {
        this.title = serwrt.noticeType;
        this.contontes = serwrt;
        this.dialogVisible = true;
        this.knowser = false;
        this.ewert = false;
        this.inerty = false;
        this.view = false;
        this.toviewers = true;
      } else {
        this.dialogVisible = true;
        this.title = serwrt.noticeType;
        this.contontes = serwrt;
        this.inerty = false;
        this.ewert = false;
        this.knowser = false;
        this.view = true;
        this.toviewers = false;
      }
    },
    destroyed() {
      // 销毁监听
      this.socket.onclose = this.close;
    },
    packs(val) {
      this.unfoldAndFold = val;
      console.log(this.unfoldAndFold, val, "llllmmmmmmm");
      this.filew = !this.filew;
      console.log(this.filew);
    },
    withinLists(val) {
      console.log(val, "888888888888888888888888");
      this.within = val;
    },
    detaliel(val) {
      console.log(val);

      this.detalieltes = val;
    },
    //驳回
    rejected() {
      this.inerty = true;
      this.ewert = false;
    },
    viewers() {
      this.dialogVisible = false;
    },
    rejectedet() {
      this.getCurrentTime();
      console.log(this.gettime);
      emergencyEarly({
        startUserId: this.user.uuid,
        startTime: this.gettime,
        startStatus: 0,
        id: this.contontes.eventId,
        rejectReason: this.input,
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "驳回成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.input = "";
        }
      });
    },
    getCurrentTime() {
      //获取当前时间并打印
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      _this.gettime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      console.log(_this.gettime);
    },
    //启动
    Startthe() {
      this.getCurrentTime();
      console.log(this.gettime);
      emergencyEarly({
        startUserId: this.user.uuid,
        startTime: this.gettime,
        startStatus: 1,
        id: this.contontes.eventId,
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "启动成功",
            type: "success",
          });
          this.dialogVisible = false;
        }
      });
    },
    //响应升级确定
    know() {
      confirmResponseUpgrade({
        noticeEventId: this.contontes.eventId,
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "确认成功",
            type: "success",
          });
          this.dialogVisible = false;
        }
      });
    },
    teero() {
      // this.$router.push({
      //   path: "/Screen/Contingency/Modelres",
      //   // query: {
      //   //   data: JSON.stringify(data),
      //   // },
      // });
      this.BimBool = true;
    },
    gisClick() {
      this.BimBool = false;
    },
    //查看处置卡
    toview() {
      this.dialogVisible = false;
      this.eventId = this.contontes.eventId;
      this.messageId = this.contontes.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.mybox-leave-active,
.mybox-enter-active {
  transition: all 1s ease;
}
.mybox-leave-active,
.mybox-enter {
  margin-left: 0px !important;
}
.mybox-leave,
.mybox-enter-active {
  margin-left: -200px;
}
.outbures {
  width: 100%;
  height: 100%;
  position: absolute;

  .center-panel {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
  .left-panel {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    width: 20.8%;
    height: 100%;
  }
  .left-side {
    position: absolute;
    left: 22%;
    top: 0;
    z-index: 9;
  }
  .left_ster {
    position: absolute;
    left: 2%;
    top: 0;
    z-index: 9;
  }
  .right_top {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    width: 20.8%;
    height: 42%;
  }
  .right_bottom {
    position: absolute;
    right: 0;
    top: 43%;
    z-index: 9;
    width: 20.8%;
    height: 56%;
  }
  .ump_top {
    width: 32px;
    height: 96px;
    background: #3a6892;
    position: absolute;
    right: 2%;
    top: 12%;
    z-index: 9;
    border-radius: 5px;
    .teo {
      width: 32px;
      height: 32px;
      background: #5aa3e3;
      border-radius: 5px;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        margin: auto;
      }
    }
    .teos {
      width: 32px;
      height: 32px;
      background: #3a6892;
      border-radius: 5px;
      position: relative;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 6px;
        left: 6px;
      }
    }
  }
  .ump_button {
    width: 32px;
    height: 96px;
    background: #3a6892;
    position: absolute;
    right: 24%;
    top: 12%;
    z-index: 9;
    border-radius: 5px;
    .teo {
      width: 32px;
      height: 32px;
      background: #5aa3e3;
      border-radius: 5px;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        margin: auto;
      }
    }
    .teos {
      width: 32px;
      height: 32px;
      background: #3a6892;
      border-radius: 5px;
      position: relative;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 6px;
        left: 6px;
      }
    }
  }
  .seach {
    position: absolute;
    left: 83%;
    top: 2%;
    z-index: 99;
    display: flex;
    ::v-deep .el-input__inner {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      background: rgba(24, 70, 127, 0.8) !important;
      border: 1px solid #173f58 !important;
      border-right: none !important;
    }

    ::v-deep .el-input-group__append {
      background: #43a5e9;
      border: 1px solid #43a5e9;
      border-left: none;
      color: #fff;
    }
  }

  .conter {
    position: absolute;
    left: 25%;
    top: 90%;
    display: flex;
    .steam {
      width: 150px;
      height: 50px;
      background: rgba(8, 23, 66, 0.9);
      border: 1px solid #002e75;
      box-shadow: 0px 0px 20px 6px #002e75 inset;
      margin-left: 20px;
      cursor: pointer;
      .corner-wrap {
        width: 100%;
        height: 100%;
        // position: absolute;
        .corner {
          width: 16px;
          height: 16px;
        }
        .centers {
          display: flex;
          align-items: center;
          justify-content: space-around;
          height: 50px;

          img {
            width: 20px;
            height: 20px;
          }
          span {
            color: #ffffff;
            margin-left: -25px;
          }
        }
        .corner-left-top {
          border-left: 2px solid #43a5e9;
          border-top: 2px solid #43a5e9;
          position: relative;
          left: 0;
          top: -52px;
        }
        .corner-right-top {
          border-right: 2px solid #43a5e9;
          border-top: 2px solid #43a5e9;
          position: relative;
          left: 134px;
          top: -67px;
        }
        .corner-right-bottom {
          border-right: 2px solid #43a5e9;
          border-bottom: 2px solid #43a5e9;
          position: relative;
          left: 134px;
          bottom: 47px;
        }
        .corner-left-bottom {
          border-left: 2px solid #43a5e9;
          border-bottom: 2px solid #43a5e9;
          position: relative;
          left: 0;
          bottom: 63px;
        }
      }
    }
  }
}
.ops {
  opacity: 0.6;
}
::v-deep .el-dialog__body {
  height: 115px;
  overflow-x: hidden;

  overflow-y: scroll;
  width: calc(100% + 17px);
}
::v-deep .el-dialog {
  width: 100%;
  overflow: hidden;
}
.el-input {
  width: 73%;
}
::v-deep.el-select-dropdown__item.hover {
  background: rgba(24, 70, 127, 0.8);
}

::v-deep .el-select-dropdown.el-popper {
  background: #062052 !important;
  color: #ffffff;
  border-color: #062052;
}
::v-deep .el-input-group__prepend {
  background: #062052 !important;
  border: none;
}

::v-deep .el-select-dropdown__item {
  color: #ffffff;
}
.selseClassName .el-popover {
  background: #002e75 !important;
  color: #ffffff;
  border-color: #002e75;
}
.selseClassName .el-popover .popper__arrow::after {
  border-bottom-color: #002e75 !important;
}
.dialogBox {
  width: 396px;
  height: 320px;
  position: fixed;
  right: 0;
  bottom: -74px;
  z-index: 20;
  .demo-input-suffix {
    display: flex;
  }
  .el-dialog__wrapper {
    width: 100% !important;
    height: 100% !important;
    position: static !important;
    z-index: 20 !important;
    ::v-deep .el-dialog {
      margin-top: 0 !important;
    }
  }
}
</style>
<style>
.selseClassName .el-popper .popper__arrow::after {
  border-bottom-color: #002e75 !important;
}
</style>
