<template>
  <div style="wieth: 100%; height: 100%" class="flex flex-col conLeft">
    <Grade></Grade>
    <Risk ref="Risk"></Risk>
    <RiskManage ref="RiskManage"></RiskManage>
  </div>
</template>

<script>
import Grade from "./grade";
import RiskManage from "./riskManage";
import Risk from "./risk";
export default {
  name: "routeList",
  components: { Grade, RiskManage, Risk },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    fluData(res) {
      let that = this;
      that.$refs.RealTime.getwsData(res);
      that.$refs.Cumulative.getwsData(res);
    },
  },
};
</script>

<style lang="scss" scoped>
.conLeft {
  height: 100%;
  display: grid;
  grid-template-rows: 40% calc(33% - 20px) 27% ;
  grid-row-gap: 10px;
}
</style>
