<template>
  <div>
    <div class="riskDialogTitle">
      <span>{{ label + "交底" }}</span>
      <el-button
        v-if="type != 'view' && isAdd == true"
        type="primary"
        size="mini"
        icon="el-icon-plus"
        @click="handleAdd('add')"
        >风险交底</el-button
      >
    </div>
    <ul class="xinxiCon">
      <li v-for="(item, i) in fileList" :key="i">
        <div class="form-title">
          {{ label + "单位" }}：{{ item.bottomPerson }}
          <span>{{ item.created }}</span>
          <!-- <el-button
            type="text"
            size="mini"
            v-if="type != 'view' && isAdd == true"
            style="font-size:14px;margin-left: 10px;"
            @click="edit(item, i, 'edit')"
            >编辑</el-button
          > -->
          <el-button
            type="text"
            size="mini"
            v-if="type != 'view' && isAdd == true"
            style="font-size:14px;margin-left: 10px;"
            @click="handleDelete(item, i)"
            ><span v-if="item.disabled == false">删除</span></el-button
          >
        </div>
        <div>交底内容：{{ item.bottomDescribe }}</div>
        <div
          class="form-title preview"
          v-for="(t, inx) in item.fileList"
          :key="inx"
        >
          <i class="fileNameLabel" :class="inx > 0 ? 'hiddenLabel' : ''"
            >附件内容：</i
          >{{ t.fileName }}
          <span @click="handleFileClick(t)">预览</span>
          <span @click="handleExport(t, t.fileName)">下载</span>
        </div>
      </li>
    </ul>
    <p @click="handleEx" v-if="storageList.length >= 1" class="zhankaiBtn">
      {{ isShow == true ? "点击收起" : "点击展开"
      }}<i
        class="el-icon-d-arrow-left"
        :class="isShow == true ? 'upArrow' : ''"
      ></i>
    </p>
    <el-empty v-if="fileList.length == 0" description="暂无交底数据"></el-empty>
    <el-dialog
      width="50%"
      title="交底操作"
      class="editDialog disclosure-dialog"
      :visible.sync="ContentdialogVisible"
      v-if="ContentdialogVisible"
      :close-on-click-modal="false"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="单位类型" prop="companyTypeName">
              <el-input v-model="ruleForm.companyTypeName" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="交底人" prop="bottomPerson">
              <el-input v-model="ruleForm.bottomPerson" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="交底内容" prop="bottomDescribe">
              <el-input
                type="textarea"
                resize="none"
                :autosize="{ minRows: 2, maxRows: 6 }"
                v-model="ruleForm.bottomDescribe"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="附件" prop="fileList">
              <FileUpload
                @uploadChange="handleFileUpload"
                @delChange="handleFileDel"
                :value="dialogFileList"
              ></FileUpload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialogFooter">
        <el-button size="medium" type="primary" @click="submit"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FileUpload from "./FileUpload";
import {
  download,
  riskBottomAdd,
  riskBottomEdit,
  riskBottomDelete
} from "@/api/riskManage";
export default {
  components: {
    FileUpload
  },
  props: {
    label: {
      type: String,
      default: () => ""
    },
    type: {
      type: String,
      default: ""
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {}
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // dd
      fileList: [],
      storageList: [],
      isShow: false,
      ContentdialogVisible: false,
      ruleForm: {
        riskName: "",
        bottomPerson: "",
        bottomPersonUuid: "",
        companyUuid: "",
        companyTypeCode: "",
        companyTypeName: "",
        departmentId: "",
        bottomDescribe: "",
        fileList: [],
        riskMasterUuid: "",
        riskDeafUuid: ""
      },
      dialogFileList: [],
      index: null,
      dialogType: "",
      bottomId: "",
      editId: "",
      rules: {
        bottomDescribe: [
          { required: true, message: "请输入交底内容：", trigger: "blur" }
        ],
        fileList: [
          { required: true, message: "请上传附件：", trigger: "change" }
        ]
      }
    };
  },
  watch: {
    list: {
      deep: true,
      handler() {
        this.storageList = JSON.parse(JSON.stringify(this.list));
        console.log(this.storageList, "dddddddddddddddddddd");
        if (this.storageList.length >= 1) {
          this.fileList = [this.storageList[0]];
          this.storageList.shift();
          console.log(this.storageList);
        }
      }
    }
  },
  methods: {
    // 附件上传
    handleFileUpload(e) {
      console.log(e, "eeeee");
      this.dialogFileList.push({
        name: e.name,
        url: e.url,
        // fileName: e.name,
        // fileUrl: e.url + "," + e.realUrl
      });
      this.ruleForm.fileList.push({
        fileName: e.name,
        fileUrl: e.url + "," + e.realUrl,
        id: this.editId,
        bottomId: this.bottomId
      });
    },
    handleFileDel(e) {
      console.log(this.dialogList, "dialogList");
      let index = this.dialogFileList.findIndex(v => v.url === e.url);
      console.log(index, "index");
      this.dialogFileList.splice(index, 1);
      this.ruleForm.fileList.splice(index, 1);
      console.log(this.ruleForm, "this.ruleForm");
      // console.log(this.ruleForm.fileList, "obj.fileList");
    },
    handleEx() {
      this.isShow = !this.isShow;
      if (this.isShow == true) {
        this.fileList.push(...this.storageList);
      } else if (this.isShow == false) {
        this.fileList = [this.fileList[0]];
      }
    },
    handleDelete(obj, i) {
      console.log(obj, "deleteobj");
      this.$confirm("此操作将永久删除该交底内容, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          riskBottomDelete({
            id: obj.id
          }).then(res => {
            if (res.code == 0) {
              this.fileList.splice(i, 1);
              this.storageList.splice(i, 1);
              this.$message({
                type: "success",
                message: "删除成功!"
              });
              this.$emit("SubmitForm", this.fileList);
              this.handleEx();
              // this.isShow == false;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    edit(obj, i, type) {
      this.ContentdialogVisible = true;
      this.dialogType = type;
      this.index = i;
      obj.fileList.forEach(el => {
        el["name"] = el.fileName;
      });
      this.dialogFileList = [].concat(obj.fileList);
      this.ruleForm = JSON.parse(JSON.stringify(obj));
      this.ruleForm.fileList = [].concat(obj.fileList);
      if (this.dialogFileList.length > 0) {
        this.bottomId = obj.fileList[0].bottomId;
        this.editId = obj.fileList[0].id;
      }
    },
    handleAdd(type) {
      this.ruleForm.riskMasterUuid = this.rowData.riskMasterUuid;
      this.ruleForm.riskDeafUuid = this.rowData.riskDeafUuid;
      this.ruleForm.riskName = this.rowData.riskName;
      if (localStorage.getItem("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        this.ruleForm.companyTypeCode = user.companyCategory; //单位类型code
        this.ruleForm.companyTypeName = user.companyCategoryName; //单位类型name
        this.ruleForm.companyUuid = user.companyId; //单位类型uuid
        this.ruleForm.departmentId = user.departmentId; //部门uuid
        this.ruleForm.bottomPerson = user.realName; //交底人name
        this.ruleForm.bottomPersonUuid = user.uuid; //交底人uuid
      }
      this.dialogType = type;
      this.ContentdialogVisible = true;
    },
    handleFileClick(record) {
      console.log(record);
      let obj = Object.assign({}, record);
      let url = obj.fileUrl.split(",")[0];
      const link = document.createElement("a");
      link.href = url;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
    handleExport(record, name) {
      console.log(record, "sssssssssss");
      let obj = Object.assign({}, record);
      let url = obj.fileUrl.split(",")[1];
      // console.log(url);
      let list = [
        { name: "doc", value: "application/msword" },
        {
          name: "docx",
          value:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        },
        { name: "xls", value: "application/vnd.ms-excel" },
        {
          name: "xlsx",
          value:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        { name: "ppt", value: "application/vnd.ms-powerpoint" },
        {
          name: "pptx",
          value:
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        },
        { name: "pdf", value: "application/pdf;chartset=UTF-8" }
      ];
      let fileType = url.split(".")[1];
      let type = null;
      list.forEach(el => {
        if (el.name == fileType) {
          type = el.value;
        }
      });
      download(url).then(res => {
        const aLink = document.createElement("a");
        let blob = null;
        if (fileType == "txt") {
          blob = new Blob([res]);
        } else if (
          fileType == "png" ||
          fileType == "jpg" ||
          fileType == "jpeg"
        ) {
          blob = new Blob([res]);
        } else {
          blob = new Blob([res], { type: type });
        }
        aLink.href = URL.createObjectURL(blob);
        aLink.download = name + "." + fileType;
        aLink.click();
        document.body.appendChild(aLink);
      });
    },
    handleClose() {
      this.ContentdialogVisible = false;
      this.reset();
    },
    reset() {
      const th = this;
      Object.keys(this.ruleForm).forEach(function(key) {
        th.ruleForm[key] = "";
        if (key == "fileList") {
          th.ruleForm[key] = [];
        }
      });
      this.dialogFileList = [];
    },
    submit() {
      const that = this;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // 55
          if (that.dialogType == "add") {
            // this.ruleForm.fileList = this.dialogFileList
            // this.fileList.unshift({ ...this.ruleForm });
            riskBottomAdd(that.ruleForm).then(res => {
              if (res.code == 0) {
                that.$emit("SubmitForm", that.fileList);
                that.reset();
                that.$refs.ruleForm.resetFields();
                that.ContentdialogVisible = false;
                // that.isShow == false;
                that.handleEx();
              }
            });
          } else if (that.dialogType == "edit") {
            that.fileList[that.index] = { ...that.ruleForm };
            riskBottomEdit(that.ruleForm).then(res => {
              if (res.code == 0) {
                that.$emit("SubmitForm", that.fileList);
                that.reset();
                that.$refs.ruleForm.resetFields();
                that.ContentdialogVisible = false;
              }
            });
          }
        }
      });
      // this.$emit("SubmitForm", { ...this.ruleForm });
    }
  }
};
</script>

<style lang="scss" scoped>
.riskDialogTitle {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
  }
  .el-button--mini,
  .el-button--mini.is-round {
    padding: 5px 8px;
  }
}
.form-title {
  // margin: 3px 0;
  span {
    margin-left: 10px;
  }
}
.preview span {
  color: #3fa5e9;
  cursor: pointer;
}
.xinxiCon li {
  margin-bottom: 15px;
}
.zhankaiBtn {
  width: 100%;
  padding: 3px 0;
  border: 1px solid #bdbdbd;
  margin: 8px 0 15px;
  text-align: center;
  cursor: pointer;
  .el-icon-d-arrow-left {
    transform: rotate(-90deg);
    margin-left: 5px;
  }
  .upArrow {
    transform: rotate(90deg);
  }
}
.dialogFooter {
  display: flex;
  justify-content: center;
}
::v-deep .el-empty {
  padding: 20px 0px;
  .el-empty__image {
    width: 46px;
  }
}
.fileNameLabel {
  font-style: normal;
}
.hiddenLabel {
  opacity: 0;
}
</style>
