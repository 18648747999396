<template>
  <div class="person-statistics-container">
    <index-box :title="title">
      <div class="person-statistics-wrapper flex flex-col justify-between">
        <div class="tab-wrapper">
          <div class="tab-content flex justify-center">
            <div
              class="tab-item text-center"
              :class="tabsKey == 0 ? 'tab-item-active' : ''"
              @click="onTabsChange(0)"
            >
              日常值班
            </div>
            <div
              class="tab-item text-center"
              :class="tabsKey == 1 ? 'tab-item-active' : ''"
              @click="onTabsChange(1)"
            >
              应急值班
            </div>
          </div>
          <div class="line"></div>
        </div>
        <div
          class="item-group flex-auto flex md:mt-2 xl:mt-1"
          style="cursor: pointer;"
          @click="shouldBeTo"
        >
          <div class="item flex-1 flex flex-col justify-around items-center">
            <p class="item-num md:text-3xl xl:text-xl font-bold text-white">
              {{ tabsData.shouldNum ? tabsData.shouldNum : 0 }}
            </p>
            <p
              class="
                item-title
                text-white text-opacity-40
                2xl:text-base
                xl:text-xs
                md:text-xs
              "
            >
              今日应到人数
            </p>
          </div>
          <div class="item flex-1 flex flex-col justify-around items-center">
            <p class="item-num md:text-3xl xl:text-xl font-bold text-white">
              {{ tabsData.reallyNum ? tabsData.reallyNum : 0 }}
            </p>
            <p
              class="
                item-title
                text-white text-opacity-40
                2xl:text-base
                xl:text-xs
                md:text-xs
              "
            >
              实到人数
            </p>
          </div>
          <div class="item flex-1 flex flex-col justify-around items-center">
            <p class="item-num md:text-3xl xl:text-xl font-bold text-white">
              {{ tabsData.notHereNum ? tabsData.notHereNum : 0 }}
            </p>
            <p
              class="
                item-title
                text-white text-opacity-40
                2xl:text-base
                xl:text-xs
                md:text-xs
              "
            >
              未到人数
            </p>
          </div>
        </div>
      </div>
    </index-box>
    <Modal
      ref="modalDetail"
      :modal="false"
      :width="'1200px'"
      :title="modelTitle"
    >
      <dutyTable></dutyTable>
    </Modal>
  </div>
</template>

<script>
// import { queryMaterialsList } from "@/api/eres";
import IndexBox from "@/components/IndexBox/indexBox"
import { screenUser } from "@/api/eres"
import Modal from "@/components/Modal/modal.vue"
import dutyTable from "./dutyTable.vue"

export default {
  components: {
    IndexBox,
    Modal,
    dutyTable,
  },
  data () {
    return {
      title: "值班人员到岗统计",
      modelTitle: "值班人员到岗统计",
      tabsKey: 0,
      tabsData: {
        shouldNum: 0,
        reallyNum: 0,
        notHereNum: 0,
      },
      page: {
        total: 11,
      },
    }
  },
  mounted () {

    console.log(this.tabsKey)
    this.getData(this.tabsKey)
  },
  methods: {
    getData (data) {
      screenUser(data).then((res) => {
        if (res.code == 0) {
          console.log(res)
          this.tabsData.shouldNum = res.data.shouldNum ? res.data.shouldNum : 0
          this.tabsData.notHereNum = res.data.notHereNum
            ? res.data.notHereNum
            : 0
          this.tabsData.reallyNum = res.data.reallyNum
            ? res.data.reallyNum
            : 0
        }
      })
    },
    onTabsChange (key) {
      // debugger
      this.tabsKey = key
      this.getData(key)
    },
    shouldBeTo () {
      // 做一次提交 暂时不能展示
      this.$refs.modalDetail.handleOpen()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../style/elTable.scss";
.person-statistics-wrapper {
  height: 100%;
  .tab-wrapper {
    position: relative;
    height: 28px;
    .tab-content {
      overflow: hidden;
      &::after {
        z-index: -1;
        display: block;
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        margin-left: -90px;
        width: 180px;
        height: 28px;
        background: #081842;
        border: 1px solid #43a5e9;
        border-radius: 4px;
      }
    }
    .tab-item {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      width: 90px;
      height: 28px;
      line-height: 28px;
      cursor: pointer;
      &:nth-child(1) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:nth-child(2) {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    .tab-item-active {
      background: #43a5e9;
    }
    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      z-index: -20;
      width: 100%;
      height: 2px;
      background-image: linear-gradient(
        45deg,
        rgba(67, 165, 233, 0),
        rgba(67, 165, 233, 1) 50%,
        rgba(67, 165, 233, 0) 100%
      );
    }
  }
  .tab-active-left {
    background: red;
  }
  .tab-active-right {
    background: blue;
  }
  .item-group {
    .item {
      position: relative;
      height: 100%;
      &::after {
        position: absolute;
        right: 0;
        display: inline-block;
        content: "";
        height: 100%;
        width: 1px;
        background: #ffffff;
        opacity: 0.1;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
  .item-num {
    font-family: Arial;
  }
}
::v-deep .el-pagination__total {
  margin-left: 20px;
}
</style>
