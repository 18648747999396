<template>
  <div>
    <el-dialog
      title="调配申请"
      center
      :visible.sync="deietVisible"
      width="50%"
      :before-close="handleClose"
    >
      <span>
        <el-form ref="form" :model="form" label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="所需物资">
                <el-input
                  v-model="maerty"
                  :disabled="true"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申请原因">
                <el-input
                  v-model="form.applyReason"
                  :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="申请人">
                <el-input v-model="form.applyName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申请时间">
                <el-input v-model="form.applyDate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="调配人">
                <el-input v-model="form.optionName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="调配时间">
                <el-input v-model="form.optionDate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
           <el-row>
              <el-col :span="12">
           <el-form-item label="调配工点">
            <el-input v-model="form.siteName" :disabled="true"></el-input>
          </el-form-item>
           </el-col>
          </el-row>
        </el-form>
      </span>
      <p
        style="
          font-weight: bold;
          font-size: 16px;
          margin-left: 28px;
          margin-top: 10px;
        "
      >
        调配内容
      </p>
      <el-table
        style="margin-top: 20px"
        ref="suppliesTable"
        :data="materialsApplyDetailsList"
        border
        height="300"
        @selection-change="handlenChange"
      >
        <el-table-column
          property="libName"
          label="物资库/救援队名称"
        ></el-table-column>
        <el-table-column
          property="materialsName"
          label="物资设备名称"
        ></el-table-column>

        <el-table-column property="chargeName" label="负责人"></el-table-column>
        <el-table-column
          property="chargeMobile"
          label="联系方式"
        ></el-table-column>
        <el-table-column label="调配数量" property="num" width="180">
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      title="调配申请"
      center
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>
        <el-form ref="form" :model="formse" label-width="100px">
           <el-form-item label="调配工点">
            <el-input v-model="formse.siteName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="所需物资">
            <el-input
              v-model="maerty"
              :disabled="true"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
            ></el-input>
          </el-form-item>

          <el-form-item label="申请原因">
            <el-input v-model="formse.applyReason" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="申请人">
            <el-input v-model="formse.applyName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="申请时间">
            <el-input v-model="formse.applyDate" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="驳回原因">
            <el-input v-model="formse.optionReason" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="操作人">
            <el-input v-model="formse.optionName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="操作时间">
            <el-input v-model="formse.optionDate" :disabled="true"></el-input>
          </el-form-item>
        </el-form>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getdetails } from "@/api/eres.js";
export default {
  data() {
    return {
      deietVisible: false,
      dialogVisible: false,
      form: {},
      maerty: [],
      materialsApplyDetailsList: [],
      formse: {},
    };
  },
  methods: {
    async diserts(record) {
      this.maerty = [];
      const data = await getdetails({ eventId: record.eventId });
      if (data.data.state == 1) {
        this.form = data.data;

        data.data.materialListDtoList.forEach((item) => {
          this.maerty.push(item.materialName + "*" + item.num);
        });
        this.materialsApplyDetailsList = data.data.materialsApplyDetailsList;

        this.deietVisible = true;
      } else {
        this.formse = data.data;
        data.data.materialListDtoList.forEach((item) => {
          this.maerty.push(item.materialName + "*" + item.num);
        });

        this.dialogVisible = true;
      }
    },
    async disert(record) {
      this.maerty = [];
      const data = await getdetails({ eventId: record.eventId });
      console.log(data);

      this.form = data.data;

      data.data.materialListDtoList.forEach((item) => {
        this.maerty.push(item.materialName + "*" + item.num);
      });
      this.materialsApplyDetailsList = data.data.materialsApplyDetailsList;

      this.deietVisible = true;
    },
    async tertst(record) {
      this.maerty = [];
      const data = await getdetails({ eventId: record.eventId });
      this.formse = data.data;
      data.data.materialListDtoList.forEach((item) => {
        this.maerty.push(item.materialName + "*" + item.num);
      });

      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table th > .cell {
  color: #313030 !important;
}
</style>
