<template>
  <div>
    <div class="callVideo">
      <div class="callusername" v-show="callload">
        {{ toname }}<br />正在呼叫...
      </div>
      <video ref="callVideotabus" autoplay style="max-width: 100%"></video>
      <video ref="callVideotab" autoplay style="max-width: 100%"></video>
      <div class="flex justify-around callfooter" style="width: 100%">
        <img
          class="cursor-pointer"
          v-show="usercall"
          src="@assets/im/jt.png"
          @click="voipAcceptCall"
        />
        <img
          class="cursor-pointer"
          v-show="usercall"
          src="@assets/im/gd.png"
          @click="exitVoipFunc"
        />
        <img
          class="cursor-pointer"
          v-show="!usercall"
          src="@assets/im/gd.png"
          @click="stop(false)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import kurentoUtils from "kurento-utils";
const NO_CALL = 0;
const PROCESSING_CALL = 1;
const IN_CALL = 2;
var from;
export default {
  name: "rtc",
  props: {
    dataList: {},
  },
  data() {
    return {
      CallDialog: false,
      ws: null,
      setMsgData: "",
      userId: null,
      callUserName: "一个设备",
      callload: true,
      usercall: true,
      callId: "",
      fromId: 1,
      toname: "用户",
      toId: "",
      registerState: null,
      callState: 0,
      videoInput: null,
      videoOutput: null,
      webRtcPeer: null,
      incalldata: null,
    };
  },
  computed: {
    list() {
      return this.dataList;
    },
  },
  mounted() {
    this.userId = this.dataList.userId;
    // this.userId = this.$route.query.userId; // 测试使用ddd
    this.initStc();
  },
  methods: {
    //初始化
    initStc() {
      let that = this;
      that.ws = new WebSocket(
        "ws://192.168.2.40:9901/rtc?userId=" + this.userId
      );
      // 监听socket连接
      that.ws.onopen = that.open;
      // 监听socket错误信息
      that.ws.onerror = that.error;
      // 监听socket消息
      that.ws.onmessage = that.getMessage;
      // 关闭连接
      that.ws.onclose = that.close;
    },
    open() {
      console.log("socket连接成功");
      this.register();
    },
    error() {
      console.log("连接错误");
    },

    close() {
      console.log("socket已经关闭");
    },
    getMessage(message) {
      let that = this;
      var parsedMessage = JSON.parse(message.data);
      console.info("收到消息: " + message.data);

      switch (parsedMessage.id) {
        case "registerResponse":
          that.registerResponse(parsedMessage);
          break;
        case "callResponse":
          that.callResponse(parsedMessage);
          break;
        case "incomingCall":
          that.incomingCall(parsedMessage);
          break;
        case "startCommunication":
          that.startCommunication(parsedMessage);
          break;
        case "stopCommunication":
          console.info("通讯结束");
          that.stop(true);
          break;
        case "iceCandidate":
          that.callload = false;
          that.webRtcPeer.addIceCandidate(parsedMessage.candidate, function(
            error
          ) {
            if (error) return console.error("Error adding candidate: " + error);
          });
          break;
        default:
          console.error("Unrecognized message", parsedMessage);
      }
    },
    //登录后注册方法
    register() {
      var message = {
        id: "register",
        name: this.userId,
        type: "voip",
      };
      this.sendMessage(message);
    },
    registerResponse(message) {
      if (message.response == "accepted") {
        console.log("");
      } else {
        var errorMessage = message.message
          ? message.message
          : "Unknown reason for register rejection.";
        console.log(errorMessage);
      }
    },
    callResponse(message) {
      console.log("=================");
      console.log(message);
      if (message.response != "accepted") {
        this.$message(message.message);
        this.stop();
      } else {
        this.$message.warning("对方网络不好或占线中！");
        this.setCallState(IN_CALL);
        this.webRtcPeer.processAnswer(message.sdpAnswer, function(error) {
          if (error) return console.error(error);
        });
      }
    },
    //收到呼叫
    incomingCall(message) {
      if (this.callState != NO_CALL) {
        var response = {
          id: "incomingCallResponse",
          from: message.from,
          callResponse: "reject",
          message: "bussy",
          type: "voip",
        };
        return this.sendMessage(response);
      }

      this.setCallState(PROCESSING_CALL);
      this.$emit("openVoip");
      this.CallDialog = true;

      this.usercall = true; 
      this.toname = message.from;
      this.incalldata = message;
      this.videoInput = this.$refs.callVideotabus;
      this.videoOutput = this.$refs.callVideotab;
      console.log(this.incalldata);
    },
    startCommunication(message) {
      this.setCallState(IN_CALL);
      this.webRtcPeer.processAnswer(message.sdpAnswer, function(error) {
        if (error) return console.error(error);
      });
    },
    setCallState(nextState) {
      switch (nextState) {
        case NO_CALL:
          console.log("空闲中");
          break;
        case PROCESSING_CALL:
          console.log("呼叫中");
          break;
        case IN_CALL:
          console.log("通话中");
          break;
        default:
          return;
      }
      this.callState = nextState;
    },
    stop(message) {
      this.setCallState(NO_CALL);
      if (this.webRtcPeer) {
        this.webRtcPeer.dispose();
        this.webRtcPeer = null;

        if (!message) {
          message = {
            id: "stop",
            type: "voip",
          };
          this.sendMessage(message);
        }
      }

      this.hideSpinner(this.videoInput, this.videoOutput);
      this.callload = true;
      this.$emit("endVoip");
    },
    sendMessage(message) {
      var jsonMessage = JSON.stringify(message);
      console.log("发送消息: " + jsonMessage);
      this.ws.send(jsonMessage);
    },
    //发起视频通话
    callVideo() {
      let that = this;
      that.toId = that.dataList.toId;
      that.toname = that.dataList.toName;
      that.callUserName = that.toname;
      that.usercall = false;
      that.CallDialog = true;
      that.callload = true;
      that.videoInput = that.$refs.callVideotabus;
      that.videoOutput = that.$refs.callVideotab;
      this.setCallState(PROCESSING_CALL);
      this.register();
      setTimeout(() => {
        var iceservers = {
          iceServers: [
            {
              urls: "stun:47.95.238.93:3478",
            },
            {
              urls: ["turn:47.95.238.93:3478"],
              username: "kurento",
              credential: "kurento",
            },
          ],
        };
        var options = {
          localVideo: that.videoInput,
          remoteVideo: that.videoOutput,
          onicecandidate: that.onIceCandidate,
          onerror: that.onError,
          configuration: iceservers,
        };
        that.webRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendrecv(
          options,
          function(error) {
            if (error) {
              return console.error(error);
            }
            this.generateOffer(that.onOfferCall);
          }
        );
      }, 500);
    },
    onOfferCall(error, offerSdp) {
      if (error) return console.error("Error generating the offer");
      console.log("Invoking SDP offer callback function");
      var message = {
        id: "call",
        from: this.userId,
        to: this.toId,
        sdpOffer: offerSdp,
        type: "voip",
      };
      this.sendMessage(message);
    },
    onIceCandidate(candidate) {
      console.log("Local candidate" + JSON.stringify(candidate));

      var message = {
        id: "onIceCandidate",
        candidate: candidate,
        type: "voip",
      };
      this.sendMessage(message);
    },
    onError() {
      this.setCallState(NO_CALL);
    },
    hideSpinner() {
      this.videoInput.src = "";
      this.videoOutput.src = "";
    },
    //voip 同意接受对方呼叫
    voipAcceptCall() {
      let that = this;
      that.callload = false;
      let message = that.incalldata;
      from = message.from;
      var iceservers = {
        iceServers: [
          {
            urls: "stun:47.95.238.93:3478",
          },
          {
            urls: ["turn:47.95.238.93:3478"],
            username: "kurento",
            credential: "kurento",
          },
        ],
      };
      var options = {
        localVideo: that.videoInput,
        remoteVideo: that.videoOutput,
        onicecandidate: that.onIceCandidate,
        onerror: that.onError,
        configuration: iceservers,
      };
      that.webRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendrecv(
        options,
        function(error) {
          if (error) {
            return console.error(error);
          }
          this.generateOffer(that.onOfferIncomingCall);
        }
      );
    },
    onOfferIncomingCall(error, offerSdp) {
      if (error) return console.error("Error generating the offer");
      var response = {
        id: "incomingCallResponse",
        from: from,
        callResponse: "accept",
        sdpOffer: offerSdp,
        type: "voip",
      };
      this.sendMessage(response);
    },
    //退出或者挂断
    exitVoipFunc() {
      let message = this.incalldata;
      var response = {
        id: "incomingCallResponse",
        from: message.from,
        callResponse: "reject",
        message: "user declined",
        type: "voip",
      };
      this.sendMessage(response);
      this.stop();
    },
  },
};
</script>

<style lang="scss" scoped>
.callVideo {
  width: 400px;
  height: 600px;
  position: fixed;
  left: 500px;
  top: 100px;
  background: rgba(0, 0, 0, 1);
  z-index: 100;
}
.callusername {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 200px;
  left: 0px;
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
}
.callfooter {
  position: absolute;
  bottom: 20px;
  left: 0px;
}
</style>