<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    //百度地图的缩放图层错乱  设置浏览器的比例为1
    window.devicePixelRatio = 1;
  },
};
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

/* .el-popper[x-placement^=bottom] .popper__arrow::after {
border-bottom-color:#002e75 !important;
} */
.page-container .toolbarLeft {
  float: left;
  text-align: left;
}

.page-container .toolbarRight,
.page-container .toolbarLeft {
  padding: 10px 0 0 15px;
}
</style>
