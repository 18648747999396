<template>
  <div class="material-container h-full">
    <el-form
      ref="formData"
      :rules="rules"
      :model="formData"
      v-loading="loading"
      label-width="110px"
      :disabled="submitType == 'view'"
      element-loading-text="正在保存，请稍等"
    >
      <el-form-item label="事件类型:" prop="name">
        <!-- <el-select
          v-model="formData.riskEventCode"
          style="width: 100%"
          placeholder="请选择事件类型"
        >
          <el-option
            v-for="item of unitTypeData"
            :key="item.code"
            :label="item.intro"
            :value="item.code"
          >
          </el-option>
        </el-select> -->
        <el-input
          v-model="formData.name"
          placeholder="事件类型"
          clearable=""
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="category">
        <el-select
          v-model="formData.category"
          style="width: 100%"
          placeholder="请选择类型"
          disabled
        >
          <el-option
            v-for="item of categoryList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="编码" prop="code">
        <el-input
          v-model="formData.code"
          disabled
          clearable
          placeholder="请输入3001-3099之间的数字"
          type="number"
        ></el-input>
      </el-form-item>
      <el-form-item label="待办通知脚本" prop="template">
        <el-input
          type="textarea"
          v-model="formData.template"
          placeholder="请输入脚本内容"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="权限设置:" prop="roleInfoList">
        <el-button size="medium" type="primary" @click="allocate"
          >分配</el-button
        >
      </el-form-item> -->
      <el-form-item label="权限设置">
        <el-table :data="tableData">
          <el-table-column
            prop="stepType"
            align="center"
            label="分组编号"
            width="90"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="stepName"
            align="center"
            label="分组名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="noticeTypeRoleLinkList"
            align="center"
            label="角色"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <p class="roles" @click="rolesClick(scope.row)">
                <span
                  v-for="item in scope.row.noticeTypeRoleLinkList"
                  :key="item.roleId"
                  >{{ item.roleName + "," }}
                </span>
                <span v-if="scope.row.noticeTypeRoleLinkList.length == 0"
                  >暂无角色</span
                >
              </p>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <div class="dialogFooter">
      <el-button size="medium" @click="cancel" v-show="submitType !== 'view'"
        >取 消</el-button
      >
      <el-button
        size="medium"
        type="primary"
        @click="submit"
        v-show="submitType !== 'view'"
        >确 定</el-button
      >
    </div>
    <div class="form-wrapper">
      <el-dialog
        v-if="dialogVisible"
        width="30%"
        title="分配权限"
        :visible.sync="dialogVisible"
        append-to-body
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div class="tree-wrapper h-full overflow-y-scroll mr-4">
          <el-tree
            v-loading="treeLoading"
            :data="treeData"
            show-checkbox
            node-key="id"
            :props="defaultProps"
            ref="treeRef"
            accordion
            :default-checked-keys="defaultCheckedKeys"
            element-loading-text="拼命加载中"
          >
          </el-tree>
        </div>
        <span slot="footer" class="dialogFooter">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="allotSubmit">提交</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// 获取权限单位
import { getUnitRoles } from "@/api/hdanger";
// 字典
import { getDictData } from "@/api/dict";
// 查询角色列表
// import { getRoleApi } from "@/api/role";
// 新增,编辑
import { todoAdd, todoEdit, todoDetail, getRoles, setRoles } from "@/api/notic";

export default {
  props: {
    pageData: {
      type: Object,
      default: () => {},
    },
    submitType: {
      type: String,
    },
  },
  data() {
    return {
      formData: {
        name: "",
        category: "",
        code: "",
        template: "",
        codeRightLike: 30,
        roleInfoList: [],
      },
      categoryList: [
        { value: 0, label: "通知" },
        { value: 1, label: "待办" },
      ],
      // 单位类型
      unitTypeData: [],
      // 风险控制等级
      gradeList: [],
      cycleList: [
        { label: "季" },
        { label: "月" },
        { label: "周" },
        { label: "日" },
      ],
      // 角色
      // rolesData: [],
      loading: false,
      rules: {
        name: [
          { required: true, message: "请输入事件类型", trigger: "change" },
        ],
        code: [{ required: true, message: "请输入编码", trigger: "change" }],
        category: [
          {
            required: true,
            message: "请选择类型",
            trigger: "change",
          },
        ],
        template: [
          {
            required: true,
            message: "请输入脚本内容",
            trigger: "change",
          },
        ],
      },
      // 权限
      dialogVisible: false,
      treeLoading: false,
      treeData: [],
      defaultProps: {
        label: "roleName",
        children: "children",
      },
      defaultCheckedKeys: [],
      rolesList: [],
      stepType: "",

      // 锁
      lock: false,
      tableData: [],
    };
  },
  watch: {
    "pageData.id": {
      handler(val) {
        if (val) {
          this.getData();
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
  },
  created() {
    this.getUnitData();
  },
  mounted() {
    this.getDict();
    this.getTableData();
  },
  methods: {
    // 点击角色打开权限
    rolesClick(row) {
      if (this.lock) {
        this.dialogVisible = true;
        console.log(row);
        this.stepType = row.stepType;
        if (row.noticeTypeRoleLinkList.length) {
          row.noticeTypeRoleLinkList.forEach((i) => {
            this.defaultCheckedKeys.push(i.roleId);
          });
        }
      } else {
        this.$message.error("请稍等，正在查询...");
      }
    },
    // 分配
    allocate() {
      console.log(this.lock);
      if (this.lock) {
        console.log(this.rolesList);
        this.dialogVisible = true;
        if (this.rolesList.length) {
          this.rolesList.forEach((i) => {
            this.defaultCheckedKeys.push(i.roleId);
          });
        }
      } else {
        this.$message.error("请稍等，正在查询...");
      }
    },
    allotSubmit() {
      const selectNodeData = this.$refs.treeRef.getCheckedNodes(true);
      if (selectNodeData.length) {
        let index = this.tableData.findIndex((i) => {
          return i.stepType == this.stepType;
        });
        this.tableData[index].noticeTypeRoleLinkList = [];
        for (let item of selectNodeData) {
          this.tableData[index].noticeTypeRoleLinkList.push({
            roleId: item.id,
            roleName: item.roleName,
            stepName: this.tableData[index].stepName || "默认分组",
            stepType: this.tableData[index].stepType || 0,
            noticeCode: this.tableData[index].noticeCode,
          });
        }
      }
      // this.formData.roleInfoList = this.rolesList;
      this.dialogVisible = false;
    },
    // 获取表格数据
    getTableData() {
      console.log(this.pageData.code);
      getRoles(this.pageData.code).then((res) => {
        this.tableData = res.data;
        this.tableData.forEach((i) => {
          if (!i.stepType) {
            i.stepType = 0;
          }
          if (!i.stepName) {
            i.stepName = "默认分组";
          }
        });
      });
    },
    // 分配权限关闭
    handleClose() {
      this.defaultCheckedKeys = [];
      this.dialogVisible = false;
    },
    getData() {
      // this.formData.roleId = this.pageData.roleName;
      this.formData = { ...this.pageData };
      this.formData.roleId = Number(this.pageData.roleId);
      // this.rolesData = [];
      // getRoleApi({ unitType: this.pageData.responseUnitType })
      //   .then((res) => {
      //     if (res.code == 0) {
      //       this.rolesData = res.data;
      //     }
      //   })
      //   .catch((res) => {
      //     console.log(res.messafe);
      //   });
      if (this.formData.id) {
        todoDetail(this.formData.id).then((res) => {
          console.log(res);
          if (res.data.roleInfoList) {
            let arr = [];
            res.data.roleInfoList.forEach((element) => {
              arr.push(element);
            });
            this.formData.roleInfoList = arr;
            this.rolesList = arr;
          }
        });
      }
    },
    getUnitData() {
      this.treeLoading = true;
      getUnitRoles().then((res) => {
        if (res.code == 0) {
          let unitRoles = [];
          unitRoles = res.data;
          unitRoles.forEach((element) => {
            element.dict.roleName = element.dict.intro;
            element.dict.children = element.roles;
            delete element.roles;
            this.treeData.push(element.dict);
          });
          this.treeLoading = false;
          this.lock = true;
        }
      });
    },
    getDict() {
      //发布单位/响应单位,整改单位,等级
      const demo = ["riskEventType", "riskControlLevel"];
      demo.forEach((item) => {
        this.getDictList(item);
      });
    },
    // 字典  unitType 单位类型
    getDictList(val) {
      getDictData(val).then((res) => {
        if (res.code == 0) {
          switch (val) {
            case "riskEventType":
              this.unitTypeData = res.data;
              break;
            case "riskControlLevel":
              this.gradeList = res.data;
              break;
          }
        }
      });
    },
    // 设置角色绑定
    setRoles() {
      let arr = [];
      this.tableData.forEach((i) => {
        if (i.noticeTypeRoleLinkList.length) {
          i.noticeTypeRoleLinkList.forEach((j) => {
            arr.push(j);
          });
        }
      });
      setRoles(arr).then((res) => {
        console.log(res);
      });
    },
    // unitTypeChange(val) {
    //   this.formData.roleId = "";
    //   this.rolesData = [];
    //   getRoleApi({ unitType: val })
    //     .then((res) => {
    //       if (res.code == 0) {
    //         this.rolesData = res.data;
    //       }
    //     })
    //     .catch((res) => {
    //       console.log(res.messafe);
    //     });
    // },
    submit() {
      this.$refs.formData.validate((valid) => {
        console.log(this.submitType);
        if (valid) {
          if (
            Number(this.formData.code) > 3100 ||
            Number(this.formData.code) < 3001
          ) {
            this.$message.error("编码请输入3001-3099之间的数字");
            return;
          }
          this.loading = true;
          let copyForm = Object.assign({}, this.formData);
          copyForm.code = Number(copyForm.code);
          // copyForm.riskEventName = this.unitTypeData.find((i) => {
          //   return i.code == copyForm.riskEventCode;
          // }).label;
          if (this.submitType == "add") {
            console.log(copyForm);
            todoAdd(copyForm)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.loading = false;
                  this.cancel();
                } else {
                  this.$message.error(res.message);
                }
              })
              .catch((res) => {
                this.$message.error(res.message);
              });
          } else if (this.submitType == "edit") {
            console.log(2);
            this.setRoles();
            todoEdit(copyForm)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.loading = false;
                  this.cancel();
                } else {
                  this.$message.error(res.message);
                }
              })
              .catch((res) => {
                // this.cancel();
                this.$message.error(res.message);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancel() {
      this.reset();
      this.$emit("CloseData");
    },
    reset() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = "";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
::v-deep .el-dialog__body {
  max-height: 500px;
  overflow: auto;
}
.roles {
  cursor: pointer;
  color: #409eff;
}
</style>
