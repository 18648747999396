<template>
  <div class="person-statistics-container">
    <div class="box-container">
      <div class="corner-wrap">
        <div class="corner corner-left-top"></div>
        <div class="corner corner-right-top"></div>
        <div class="corner corner-right-bottom"></div>
        <div class="corner corner-left-bottom"></div>
      </div>
      <div class="main-wrapper 2xl:p-4 md:p-2 xl:p-3">
        <div class="flex h-full w-full flex-col">
          <div
            class="header flex items-center bg-gradient-to-r from-index-title-bg 2xl:my-4 xl:my-2 md:my-2"
          >
            <h3 class="header-title 2xl:text-lg xl:text-base md:text-xs">
              视频通信
            </h3>
            <div
              class="header-title-more"
              :class="tabsKey !== 0 ? '2xl:ml-32' : '2xl:ml-36'"
            >
              <div
                class="text-xs inline-block ml-1 text-center"
                @click="expandMore(1)"
                v-if="tabsKey !== 0"
              >
                <span>
                  <!-- <img src="@/assets/invite.png" alt="" /> -->
                  <i class="el-icon-user font-semibold"></i> </span
                ><br />
                <span>邀请</span>
              </div>
              <div
                class="text-xs inline-block ml-1 text-center"
                @click="expandMore(2)"
              >
                <span>
                  <!-- <img src="@/assets/screen.png" alt="" /> -->
                  <i class="el-icon-camera font-semibold"></i> </span
                ><br />
                <span>录屏</span>
              </div>
              <div
                class="text-xs inline-block ml-1 text-center"
                @click="expandMore(3)"
              >
                <span>
                  <svg
                    t="1663234003757"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="2515"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M358.4 768H426.666667v85.333333H213.333333v-213.333333h85.333334v68.266667l128-128 59.733333 59.733333-128 128z m345.6 0l-128-128 59.733333-59.733333 132.266667 132.266666V640h85.333333v213.333333h-213.333333v-85.333333h64zM358.4 298.666667l128 128-59.733333 59.733333-128-128V426.666667H213.333333V213.333333h213.333334v85.333334H358.4z m345.6 0H640V213.333333h213.333333v213.333334h-85.333333V354.133333l-132.266667 132.266667-59.733333-59.733333 128-128z"
                      fill="#3d97d8"
                      p-id="2516"
                    ></path>
                  </svg>
                  <!-- <img src="@/assets/full.png" alt="" /> -->
                </span>
                <br />
                <span>全屏</span>
              </div>
            </div>
          </div>
          <div class="main-content flex-1 overflow-y-scroll">
            <div
              class="person-statistics-wrapper flex flex-col justify-between"
            >
              <div class="tab-wrapper">
                <div class="tab-content flex justify-center">
                  <div
                    class="tab-item text-center"
                    :class="tabsKey == 0 ? 'tab-item-active' : ''"
                    @click="onTabsChange(0)"
                  >
                    现场监控
                  </div>
                  <div
                    class="tab-item text-center"
                    :class="tabsKey == 1 ? 'tab-item-active' : ''"
                    @click="onTabsChange(1)"
                  >
                    单兵通信
                  </div>
                  <div
                    class="tab-item text-center"
                    :class="tabsKey == 2 ? 'tab-item-active' : ''"
                    @click="onTabsChange(2)"
                  >
                    多人会议
                  </div>
                </div>
                <div class="line"></div>
              </div>
              <div class="scroll-wrapper overflow-y-scroll">
                <!-- 监控摄像头 -->
                <surveillanceCamera
                  v-if="tabsKey == 0"
                  :eventsSwitching="eventsSwitching"
                ></surveillanceCamera>
                <!-- 单兵通信 -->
                <SoldierCommunication
                  v-show="tabsKey == 1"
                  :callList="callList"
                  @call="call"
                ></SoldierCommunication>
                <!-- 多人会议 -->
                <ManyPeopleMeeting v-show="tabsKey == 2"></ManyPeopleMeeting>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <inviteDialog ref="invite"></inviteDialog>
    <fullScreenDialog ref="full"></fullScreenDialog>
    <calledPage
      v-show="called"
      :name="name"
      :callData="callDataInfo"
      @callClose="callClose"
      @voipAcceptCall="voipAcceptCall"
    ></calledPage>
  </div>
</template>

<script>
import surveillanceCamera from "@/views/Contingency/HomePage/rightPage/surveillanceCamera.vue" //监控摄像头
import SoldierCommunication from "./SoldierCommunication.vue"; //单兵通信
import ManyPeopleMeeting from "./ManyPeopleMeeting.vue"; //多人会议
import inviteDialog from "./inviteDialog.vue"; // 会议邀请
import fullScreenDialog from "./fullScreenDialog.vue"; //全屏
import calledPage from "./calledPage.vue"; //被呼叫的页面

export default {
  props: {
    detalieltes: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    surveillanceCamera,
    SoldierCommunication,
    ManyPeopleMeeting,
    inviteDialog,
    fullScreenDialog,
    calledPage,
  },
  data() {
    return {
      tabsKey: 0,
      tabsData: {},
      dialogVisible: false,
      // 邀请
      inviteDialogVisible: false,
      pathWs: "",
      socketWs: null,
      lockReconnect: false, //是否真正建立连接
      user: {},
      called: false,
      name: "",
      eventsSwitching: {}, //事件切换当前事件的信息
      // callDataInfo: {},
      callList: [],
      callDataInfo: {}, //创建的房间的信息
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {},
  watch: {
    detalieltes: {
      handler(val) {
        console.log(val, this.detalieltes);
        if (val.siteId && val.siteId !== null && val.siteId !== undefined) {
          this.eventsSwitching = val;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      console.log(this.dialogVisible);
    },
    //邀请1   录屏2   全屏3
    expandMore(val) {
      if (val == 1) {
        this.inviteDialogVisible = true;
        this.$refs.invite.handelOpen(this.tabsKey);
      } else if (val == 2) {
        console.log(val, "111");
      } else if (val == 3) {
        this.$refs.full.handelOpen(this.tabsKey, this.eventsSwitching);
      }
    },
    // 视频监控0  单兵通信1   多人会议2 小弹窗的切换
    onTabsChange(key) {
      this.tabsKey = key;
    },
    //ws发来消息 显示谁在像你拨打视频会议
    call(msg) {
      this.callDataInfo = msg;
      this.name = msg.fromName;
      this.called = true;
    },
    //被呼叫时挂断
    callClose() {
      this.called = false;
    },
    // 被呼叫时接通
    voipAcceptCall() {
      this.called = false;
      console.log("邀请成功");
      //接通后建立连接 创建一个新的ws链接进到会议里
      this.meetingRoom(this.callDataInfo);
    },
    meetingRoom(msg) {
      let meetingRoomPath =
        "ws://47.95.238.93:9901/rtc/meeting?userId=" +
        this.user.id +
        "&roomId=" +
        msg.message.attribute.roomId;
      // 实例化socket
      this.socketWs = new WebSocket(meetingRoomPath);
      // 监听socket连接
      this.socketWs.onopen = this.meetingopenWs;
      // 监听socket错误信息
      this.socketWs.onerror = this.meetingerrorWs;
      // 关闭socket
      this.socketWs.onclose = this.meetingcloseWs;
      // 监听socket消息
      this.socketWs.onmessage = this.meetinggetMessageWs;
    },
    meetingopenWs() {
      console.log("socket连接成功");
      this.callList = [
        [
          {
            showName: "管理员",
            id: 1,
            lable: "管理员",
            value: 1,
            state: 1,
          },
          {
            showName: "测试",
            id: 1842,
            lable: "测试",
            value: 1842,
            state: 3,
          },
          {
            showName: "古伟强",
            id: 1862,
            lable: "古伟强",
            value: 1862,
            state: 3,
          },
        ],
      ];
    },
    meetingerrorWs() {
      console.log("连接错误");
      //重连
      this.meetingreconnect();
    },
    meetingcloseWs() {
      console.log("socket已经关闭");
    },
    meetinggetMessageWs(msg) {
      console.log(JSON.parse(msg), "服务端发来消息啦  进入会议室");
      this.callList = [
        [
          {
            showName: "管理员",
            id: 1,
            lable: "管理员",
            value: 1,
            state: 1,
          },
          {
            showName: "测试",
            id: 1842,
            lable: "测试",
            value: 1842,
            state: 3,
          },
          {
            showName: "古伟强",
            id: 1862,
            lable: "古伟强",
            value: 1862,
            state: 3,
          },
        ],
      ];
    },
    meetingreconnect() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function() {
        that.meetingRoom(); //新连接
        that.lockReconnect = false;
        console.log("重新连接中……");
        // that.path = "ws://219.153.100.85:8001/webSocket/" + that.siteId + "-" + that.uuid //模拟测试webscoket断线重连
      }, 1000 * 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.person-statistics-wrapper {
  height: 100%;
  .tab-wrapper {
    position: relative;
    height: 28px;
    margin-bottom: 10px;
    .tab-content {
      overflow: hidden;
      &::after {
        z-index: -1;
        display: block;
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        margin-left: -135px;
        width: 270px;
        height: 28px;
        background: #081842;
        border: 1px solid #43a5e9;
        border-radius: 4px;
        // margin-bottom: 10px;
      }
    }
    .tab-item {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      width: 90px;
      height: 28px;
      line-height: 28px;
      cursor: pointer;
      &:nth-child(1) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:nth-child(2) {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    .tab-item-active {
      background: #43a5e9;
    }
    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      z-index: -20;
      width: 100%;
      height: 2px;
      background-image: linear-gradient(
        45deg,
        rgba(67, 165, 233, 0),
        rgba(67, 165, 233, 1) 50%,
        rgba(67, 165, 233, 0) 100%
      );
    }
  }
}
.scroll-wrapper {
  height: 100%;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

.work-tit {
  line-height: 85px;
}

.box-container {
  width: 100%;
  height: 100%;
  background: rgba(8, 23, 66, 0.9);
  border: 1px solid #002e75;
  box-shadow: 0px 0px 20px 6px #002e75 inset;
  position: relative;
  .corner-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    .corner {
      width: 16px;
      height: 16px;
    }
    .corner-left-top {
      border-left: 2px solid #43a5e9;
      border-top: 2px solid #43a5e9;
      position: absolute;
      left: 0;
      top: 0;
    }
    .corner-right-top {
      border-right: 2px solid #43a5e9;
      border-top: 2px solid #43a5e9;
      position: absolute;
      right: 0;
      top: 0;
    }
    .corner-right-bottom {
      border-right: 2px solid #43a5e9;
      border-bottom: 2px solid #43a5e9;
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .corner-left-bottom {
      border-left: 2px solid #43a5e9;
      border-bottom: 2px solid #43a5e9;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .main-wrapper {
    position: absolute;
    z-index: 10;
    padding-top: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 0 !important;
    & > div {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }
    .header {
      width: 100%;
      height: 32px;
      justify-content: space-between;

      .header-title {
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        // margin-left: -4rem;
        &::before {
          display: inline-block;
          content: "";
          width: 12px;
          height: 14px;
          margin-left: 11px;
          margin-right: 3px;
          // background-color: rgb(243, 11, 11);
          background-image: url(../../../../assets/box-icon.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .header-title-more {
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #43a5ea;
      }
    }
  }
  .main-content {
    height: calc(100% - 67px);
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}
// ::v-deep .el-carousel__indicators--horizontal {
// bottom: 10px !important;
// }
::v-deep .el-carousel__button {
  width: 7px;
  height: 7px;
  border-radius: 4px;
}
</style>
