<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>

          <el-form-item label="">
            <el-select v-model="queryModal.screeningType" clearable placeholder="排查类型" style="width: 150px">
              <el-option
                  v-for="item in troubleshooting"
                  :key="item.value"
                  :label="item.intro"
                  :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="queryModal.categoryPid" clearable @change="dangerChange" placeholder="隐患类别">
              <el-option
                  v-for="item in dangerType"
                  :key="item.value"
                  :label="item.categoryName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="queryModal.categoryId" clearable placeholder="隐患子类">
              <el-option
                  v-for="item in dangerSon"
                  :key="item.value"
                  :label="item.categoryName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="queryModal.itemName" placeholder="隐患条目名称"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="queryModal.reformUnitType" clearable placeholder="整改单位">
              <el-option
                  v-for="item in rectification"
                  :key="item.value"
                  :label="item.label"
                  :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="queryModal.hiddenLevel" clearable placeholder="隐患等级" style="width: 150px">
              <el-option
                  v-for="item in dangerLevel"
                  :key="item.value"
                  :label="item.label"
                  :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item>
            <el-button type="primary" @click="handleAdd">新建</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleExport">导出</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="openDialog">导入</el-button>
          </el-form-item>
        </el-row>

<!--          <el-form-item>
            <el-button type="primary" v-has="'drill:add'" @click="downTemplate">下载模板</el-button>
          </el-form-item>
          <el-form-item>
            <el-upload
                action="action"
                :http-request="uploadFile"
                :file-list="fileList"
                :before-upload="beforeUpload"
                :limit="1"
            >
              <el-button type="primary">导入</el-button>
            </el-upload>
          </el-form-item>-->

      </el-form>
    </div>

    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" type="index" label="序号" width="100"></el-table-column>
        <el-table-column align="center" prop="itemName" label="隐患条目名称"
                         show-overflow-tooltip minWidth="200"></el-table-column>
        <el-table-column align="center" prop="itemState" label="状态" width="100">
          <template slot-scope="scope">
            {{ scope.row.itemState == 0 ? '有效' : scope.row.itemState == 1 ? '无效' : '' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="itemCode" label="条目编码" width="160"></el-table-column>
        <el-table-column align="center" prop="reformUnitName" label="整改单位" width="120"></el-table-column>
        <el-table-column align="center" prop="hiddenLevelName" label="隐患等级" width="120"></el-table-column>
        <el-table-column align="center" prop="reformDay" label="整改天数" width="100"></el-table-column>
        <el-table-column align="center" label="操作" width="200" fixed="right" v-if="roleType == 4">
          <template slot-scope="scope">
            <!-- 计划审核 -->
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination background layout="prev, pager, next"
                     :total="page.total" :page-count="page.count"
                     :page-size="page.pageSize"
                     :current-page="page.pageNo"
                     @current-change="handleCurrentChange"
                     @size-change="handleSizeChange">
      </el-pagination>
    </div>


    <el-dialog
        :title="titleAdd"
        :visible.sync="dialogVisible"
        class="editDialog"
        append-to-body>
      <addLibrary v-if="dialogVisible" :operation="operation" :edit-data="editData"
                  @successModal="successModal" @cancelModal="cancelModal"></addLibrary>
    </el-dialog>

    <el-dialog
        width="20%"
        :visible.sync="downVisible"
        class="editDialog"
        append-to-body>
      <downUpload v-if="downVisible" @successUpload="successUpload"></downUpload>
    </el-dialog>

  </div>
</template>

<script>
import {libraryDelete, libraryExport, libraryList, libraryUpload, subCategoryList} from "@/api/hdanger";
import addLibrary from "@/views/HiddenDanger/components/addLibrary";
import downUpload from "@/views/HiddenDanger/components/downUpload";
import {getDictData} from "@/api/dict";
import {getDeptInfoById} from "@/api/org";
import {ImportCard} from "@/api/eres";

export default {
  name: "ItemLibrary",
  components : {
    addLibrary,
    downUpload
  },
  data(){
    return {
      queryModal : {
        categoryId : '',
        categoryPid : '',
        hiddenLevel : '',
        itemName : '',
        reformUnitType : '',
        screeningType : '',
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      troubleshooting : [],
      dangerType : [],
      dangerSon : [],
      rectification : [],
      dangerLevel : [],
      tableData : [],
      loading : false,
      titleAdd : '',
      dialogVisible : false,
      downVisible : false,
      editData : {},
      selectionList : [],
      operation : 'add',
      roleType : null,
      fileList : []
    }
  },
  created() {
    this.getSelectList();
    this.getHiddenSelect(0)
    this.queryDeptInfo();
    this.getTableData();
  },
  methods : {

    // 查询当前登陆人的部门详情（可以获取部门类型）
    queryDeptInfo() {
      const user = JSON.parse(localStorage.getItem("user"));
      // this.supervisionUuid = user.departmentId; //监管部的UUID
      getDeptInfoById(user.departmentId).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            console.log(res.data.roleType, "wqe");
            this.roleType = res.data.roleType;
          }
        }
      });
    },
    companyFormatter(row){
      let arr = this.rectification.filter(item => item.id == row.reformUnitType);
      let name = arr.length > 0 ? arr[0].intro : ''
      return name;
    },
    dangerChange(){
      this.queryModal.categoryId = '';
      this.getHiddenSelect(this.queryModal.categoryPid)
    },
    getTableData(){
      this.loading = true;
      let params = {
        pageNum : this.page.pageNo,
        pageSize : this.page.pageSize,
        ...this.queryModal
      }
      libraryList(params).then(res => {
        this.tableData = res.data.records;
        this.page.total = res.data.count;
        this.loading = false;
      })
    },
    openDialog(){
      this.downVisible = true;
    },
    getSelectList(){
      let dictArray = ['screeningType','unitType','hdLevel'];
      dictArray.forEach(item => {
        this.getDictList(item);
      })
    },
    getDictList(label){
      getDictData(label).then(res => {
        switch (label){
          case 'screeningType' :
            this.troubleshooting = res.data;
            break;
          case 'unitType' :
            this.rectification = res.data;
            break;
          case 'hdLevel' :
            this.dangerLevel = res.data;
            break;
        }
      })
    },
    getHiddenSelect(id){
      subCategoryList(id).then(res => {
        if(res.code == 0){
          if(id == 0){
            this.dangerType = res.data;
          }else{
            this.dangerSon = res.data;
          }
        }
      })
    },
    handleAdd(){
      this.operation = 'add';
      this.titleAdd = '新增';
      this.dialogVisible = true;
    },
    handleEdit(row){
      this.operation = 'edit';
      this.titleAdd = '编辑';
      this.editData = {...row};
      this.dialogVisible = true;
    },
    cancelModal(){
      this.dialogVisible = false;
    },
    successUpload(){
      this.downVisible = false;
      this.getTableData();
    },
    successModal(){
      this.dialogVisible = false;
      this.getTableData();
    },
    handleSearch(){
      this.page.pageNo = 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getTableData();
    },
    handleSelectionChange(selection){
      this.selectionList = selection;
    },
    // 自定义上传方法
    async uploadFile(params) {
      // this.model.importFile = params.file;
      // this.isFinally = true;
      this.percent = 0;
      const { file } = params;
      let res = {};
      res = await libraryUpload(file);
      if (res.code === 0) {
        // this.isFinally = false;
        this.$message.success("上传成功");
      } else {
        this.message.error("上传失败");
        this.$emit("error", res.message);
        // this.isFinally = false;
      }
    },
    beforeUpload(file) {
      console.log(file);
      const tempArr = file.name.split(".");
      const isValid = tempArr.includes("xls") || tempArr.includes("xlsx");
      if (!isValid) {
        this.$message.error("只支持xls、xlsx格式文件！");
        return false;
      }
    },
    handleExport(){
      let message = this.selectionList.length === 0 ? '确定导出全部的隐患条目库吗' : '确定导出选中的隐患条目吗';
      this.$confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const params = this.selectionList.map(item => item.id);
        libraryExport(params).then((res) => {
          const aLink = document.createElement("a");
          let blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          aLink.href = URL.createObjectURL(blob);
          aLink.download = "隐患条目库.xlsx";
          aLink.click();
          document.body.appendChild(aLink);
        });
      })
    },
    downTemplate() {
      ImportCard().then((res) => {
        const aLink = document.createElement("a");
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel",
        });
        aLink.href = URL.createObjectURL(blob);
        aLink.download = "应急处置卡";
        aLink.click();
        document.body.appendChild(aLink);
        URL.revokeObjectURL(aLink.href); //销毁下载地址
        document.body.removeChild(aLink); //将a标签从dom中移除
      });
    },
    handleDelete(row){
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        libraryDelete(row.id).then(res => {
          if(res.code == 0){
            this.$message.success('操作成功');
            this.getTableData();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
