<template>
  <div class="page-container bg-white p-4 h-full relative">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="线路">
            <el-select
              v-model="queryModal.lineId"
              placeholder="请选择线路"
              clearable
              filterable
            >
              <el-option
                v-for="item of dict.lineSiteData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="queryModal.status" placeholder="请选择状态">
              <el-option
                v-for="item of dict.state"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="更新日期">
            <el-date-picker
              v-model="queryModal.updated"
              type="date"
              placeholder="选择更新日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="关键字">
            <el-input
              v-model="queryModal.label"
              placeholder="输入关键字"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="handleAdd" v-has="'workReport:monthlyReport'">填写月报</el-button>
          <el-button type="primary" @click="handleExport">批量导出</el-button>
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="loading"
        @selection-change="handleSelectionChange"
        >>
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路-工点"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="reportName"
          label="报告名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="reportStaffName"
          label="报告人员"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="reportTime"
          label="报告日期"
        ></el-table-column>
        <el-table-column align="center" prop="status" label="状态" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0" style="color:red">审核中</span>
            <span v-if="scope.row.status == 1">已审核</span>
            <span v-if="scope.row.status == 2">未通过</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleDetail(scope.row)"
              >查看详情</el-button
            >
            <el-button type="text" @click="handleExport(scope.row)"
              >导出</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>

    <prevention-work-report-form
      ref="modalForms"
      @submitFrom="handleSearch"
    ></prevention-work-report-form>
    <prevention-work-report-detail-form
      ref="modalDetailForms"
    ></prevention-work-report-detail-form>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  querysanWorkReportPage,
  WorkReportExport,
  WorkReportDetail,
} from "@/api/map";
import PreventionWorkReportForm from "./modules/PreventionWorkReportForm.vue";
import PreventionWorkReportDetailForm from "./modules/PreventionWorkReportDetailForm.vue";
export default {
  components: { PreventionWorkReportForm, PreventionWorkReportDetailForm },
  data() {
    return {
      queryModal: {
        lineId: "",
        updated: "",
        status: "",
        reportName: "",
      },
      tableData: [],
      loading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      dict: {
        lineSiteData: [],
        state: [],
      },
      ids: [],
      historyMonth: 12,
    };
  },
  mounted() {
    this.queryLineSiteTreeData();
    this.loadData();
  },
  methods: {
    //搜索
    handleSearch() {
      this.loadData();
    },
    //填写月报
    handleAdd() {
      this.historyMonth = dayjs().format("M");
      this.$refs.modalForms.handleAdd(this.historyMonth);
    },
    //导出
    handleExport(record) {
      let id;
      if (record && record.id) {
        id = [record.id];
      } else {
        id = this.ids;
      }
      WorkReportExport(id).then((res) => {
        console.log(res);
        const aLink = document.createElement("a");
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        aLink.href = URL.createObjectURL(blob);
        aLink.download = "三防工作报告.xlsx";
        aLink.click();
        document.body.appendChild(aLink);
      });
    },
    //月报详情
    handleDetail(record) {
      this.$nextTick(() => {
        WorkReportDetail(record.id).then((res) => {
          if (res.code == 0) {
            this.$refs.modalDetailForms.handleDetail(res.data);
          }
        });
      });
    },
    handleSelectionChange(data) {
      console.log(data);
      this.ids = data.map((v) => v.id);
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    // 线路工点树数据查询
    queryLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.dict.lineSiteData = lineSiteData;
      }
    },
    // 加载表格数据
    loadData() {
      this.loading = true;
      const params = {
        lineId: this.queryModal.lineId ? this.queryModal.lineId : "-",
        updated: this.queryModal.updated ? this.queryModal.updated : "-",
        status: this.queryModal.status ? this.queryModal.status : "-",
        reportName: this.queryModal.reportName
          ? this.queryModal.reportName
          : "-",
        page: this.page.pageNo,
        size: this.page.pageSize,
      };
      querysanWorkReportPage(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          this.page.pageNo = res.data.current;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.total;
          this.page.count = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 11;
}
</style>
