<template>
  <div class="quoted">
    <el-form
      :model="formInline"
      class="demo-form-inline"
      :label-position="labelPosition"
      label-width="100px"
      :rules="rules"
       ref="ruleForm"
    >
      <el-form-item label="事件名称:" v-if="finalquote" prop="responseRecordName">
        <el-input
          type="textarea"
          v-model="formInline.responseRecordName"
        ></el-input>
      </el-form-item>
      <el-form-item label="事件等级:" v-if="finalquote" prop="responseLevle">
        <el-select
          v-model="formInline.responseLevle"
          placeholder="请选择"
          :popper-append-to-body="false"
          class="respon"
          @change="reserns"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.label"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="事发经过:" prop="historyRecord">
        <el-input
          type="textarea"
          v-model="formInline.historyRecord"
          :autosize="{ minRows: 4, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="伤亡情况:" prop="casualties">
        <el-input
          type="textarea"
          v-model="formInline.casualties"
          :autosize="{ minRows: 4, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="处置情况:" prop="disposal">
        <el-input
          type="textarea"
          v-model="formInline.disposal"
          :autosize="{ minRows: 4, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="照片或附件:">
        <Uploders @uploader="uploader"></Uploders>
        <!-- <el-upload
          action="https://jsonplaceholder.typicode.com/posts/"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVble">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog> -->
      </el-form-item>
      <el-form-item v-show="continues">
        <el-button
          type="primary"
          @click="contireminkss('ruleForm')"
          style="margin-left: 35%; margin-top: 20px"
          >确 定</el-button
        >
        <el-button
          @click="contiterwr"
          style="
            background: none;
            color: #43a5e9;
            border-color: #43a5e9;
            margin-left: 5%;
          "
          >取 消</el-button
        >
      </el-form-item>
      <el-form-item v-show="finalquote">
        <el-button
          type="primary"
          @click="finalreminkss('ruleForm')"
          style="margin-left: 35%; margin-top: 20px"
          >确 定</el-button
        >
        <el-button
          @click="finalterwr"
          style="
            background: none;
            color: #43a5e9;
            border-color: #43a5e9;
            margin-left: 5%;
          "
          >取 消</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { finalQuote } from "@/api/response.js";
import { queryDictByCode } from "@/api/dict.js";
import Uploders from "@/components/Uploader/Uploader.vue";
export default {
  components: { Uploders },
  props: {
    statre: {
      type: Number,
      default: 0
    },
    uuid: {
      type: String
    }
  },
  watch: {
    statre: {
      immediate: true,
      handler(val) {
        console.log(val);
        if (val == 1) {
          this.continues = true;
          this.finalquote = false;
        } else if (val == 2) {
          this.continues = false;
          this.finalquote = true;
        }
      },
      deep: true
    }
  },
  data() {
    return {
      formInline: {
        // enclosure:[]
      },
      labelPosition: "right",
      dialogImageUrl: "",
      dialogVble: false,
      continues: false,
      finalquote: false,
      options: [],
      value: "",
      arres: {},
      user:{},
      rules:{
       responseRecordName:[
          { required: true, message: '请输入', trigger: 'blur' },
          
       ] ,

       responseLevle:[
          { required: true, message: '请选择等级', trigger: 'change' }
       ],
       historyRecord:[
         { required: true, message: '请输入事发经过', trigger: 'blur' },
       ],
       casualties:[
         { required: true, message: '请输入伤亡情况', trigger: 'blur' },
       ],
       disposal:[
         { required: true, message: '请输入处置情况', trigger: 'blur' },
       ]
      }
    };
  },
  created() {
    queryDictByCode({ code: "currentLevel" }).then(res => {
      console.log(res);
      this.options = res.data;
    });
        this.user = JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
  },
  methods: {
    //事件续报确定和取消
    contireminkss(formName) {
      
        this.$refs[formName].validate((valid) =>{
          if (valid) {
            console.log(222222)
                  this.formInline.responseType = "continue";
      this.formInline.responseRecordUuid = this.uuid;
      this.formInline.responseProcessName = "事件续报";
      this.formInline.responseUserName=this.user.realName
      this.formInline.responseUserUuid=this.user.uuid
      finalQuote(this.formInline).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "事件续报成功",
            type: "success"
          });
          this.formInline = {};
          this.arres = {};
          this.value = "";
          this.$emit("closeter");
            this.$refs[formName].resetFields();
        }
      });
          }
        })
          
        
      console.log(this.uuid);

    },
    contiterwr() {
      this.formInline = {};
      this.arres ={};
      this.value = "";
      this.$emit("closeter");
    },
    //点击事件等级
    reserns(val) {
      console.log(val);
      this.value = val.label;
      this.formInline.levelName = val.label;
      this.formInline.responseLevle = val.code;
    },
    //事件终报确定和取消
    finalreminkss(formName) {
       this.$refs[formName].validate((valid) =>{
        if(valid){
           this.formInline.responseType = "deadline";
      this.formInline.responseRecordUuid = this.uuid;
      this.formInline.responseProcessName = "事件终报";
        this.formInline.responseUserName=this.user.realName
      this.formInline.responseUserUuid=this.user.uuid
      finalQuote(this.formInline).then(res => {
        if (res.code == 0) {
          this.$message({
            message: "事件终报成功",
            type: "success"
          });
          this.formInline = {};
          this.arres = [];
          this.value = "";
          this.$emit("closeter");
            this.$refs[formName].resetFields();
        }
      });
        }
        })
     
    },
    finalterwr() {
      this.formInline = {};
      this.arres = [];
      this.value = "";
      this.$emit("closeter");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVble = true;
    },
    uploader(val) {
      this.arres.originalName=val.originalName
      this.arres.uri=val.uri
     let arr=[]
     arr.push(this.arres)
      this.formInline.enclosure=JSON.stringify(arr)
      console.log(JSON.stringify(this.formInline.enclosure))
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-form--inline .el-form-item {
  width: 400px;
}
::v-deep .el-form--inline .el-form-item__content {
  width: 320px;
}
::v-deep .el-upload-dragger {
  width: 182px;
  height: 159px;
  margin-left: 40px;
}
.dialog-footer {
  .el-button {
    z-index: 99;
  }
}
::v-deep .el-form-item__label {
  color: #ffffff;
}
::v-deep .el-textarea__inner {
  background: #081742;
  border-color: #143363;
  color: #ffffff;
}
::v-deep .el-upload--picture-card {
  background: #081742;
  border-color: #143363;
}
::v-deep .el-upload-list__item-name {
  color: #ffffff;
}
::v-deep .el-input__inner {
  background: #081742;
  color: #ffffff;
  border-color: #002e75;
}
::v-deep .respon .el-select-dropdown {
  background: #081742 !important;
  color: #ffffff;
  border-color: #081742;
}
::v-deep .respon .el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #081742 !important;
}
::v-deep .respon .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: #002e75;
}
</style>
