<template>
  <div>
    <el-form
      ref="disposeForm"
      :rules="rules"
      :model="disposeForm"
      label-width="110px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item :label="ctroType + '人:'" prop="userName">
            <el-input readonly v-model="disposeForm.userName"></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="响应时间:" prop="time">
            <el-input readonly v-model="disposeForm.time"></el-input>
          </el-form-item>
        </el-col> -->
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item :label="ctroType + '描述:'" prop="remark">
            <el-input
              type="textarea"
              resize="none"
              :placeholder="'请输入' + ctroType + '描述'"
              :autosize="{ minRows: 2, maxRows: 6 }"
              v-model="disposeForm.remark"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item :label="ctroType + '图片:'" prop="riskWarnPicList">
            <image-uploader
              v-model="disposeForm.riskWarnPicList"
              @uploadChange="urlChange"
            ></image-uploader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="附件:" prop="fileUrl">
            <FileUploader
              @uploadChange="handleFileUpload"
              @delChange="handleFileDel"
              :value="fileList"
            ></FileUploader>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="dialogFooter" v-if="ctroType != '审核'">
      <el-button size="medium" @click="cancel">取 消</el-button>
      <el-button size="medium" type="primary" @click="submit">确 定</el-button>
    </div>
    <div class="dialogFooter" v-if="ctroType == '审核'">
      <el-button size="medium" type="primary" @click="getClick(3)"
        >通 过</el-button
      >
      <el-button size="medium" type="primary" @click="getClick(2)"
        >驳 回</el-button
      >
    </div>
  </div>
</template>

<script>
// 图片
import imageUploader from "../../../components/imageUploader.vue";
// 文件
import FileUploader from "@/components/Uploader/FileUploader.vue";
import { warnRecordAdd } from "@/api/riskManage";
export default {
  components: {
    FileUploader,
    imageUploader
  },
  props: {
    warnUuid: {
      type: String,
      default: () => ""
    },
    eventId: {
      type: Number
    },
    ctroType: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      fileList: [],
      disposeForm: {
        userName: "",
        userUuid: "",
        remark: "",
        riskWarnPicList: [],
        riskWarnFileList: [],
        warnUuid: "",
        type: null,
        eventId: null
      },
      photoList: [],
      reformUnitTypeData: [],
      rules: {
        remark: [
          { required: true, message: "请输入描述内容", trigger: "blur" }
        ],
        riskWarnPicList: [
          { required: true, message: "请上传图片", trigger: "change" }
        ]
      },
      AuditType: null
    };
  },
  created() {
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      this.disposeForm.userName = user.realName;
      this.disposeForm.userUuid = user.uuid;
      this.disposeForm.companyName = user.companyName;
      this.disposeForm.companyUuid = user.companyId;
      this.disposeForm.departmentName = user.departmentName;
      this.disposeForm.departmentUuid = user.departmentId;
    }
  },
  methods: {
    urlChange(urlList) {
      this.photoList = [];
      for (let item of urlList) {
        this.photoList.push(item.url);
      }
    },
    // 附件上传
    handleFileUpload({ name, url, realUrl }) {
      this.fileList.push({ name: name, url: url, fileRealUrl: realUrl });
      this.disposeForm.riskWarnFileList.push({
        fileName: name,
        fileUrl: url,
        fileRealUrl: realUrl
      });
    },
    handleFileDel({ name, url }) {
      console.log(name, url, "Dddddddddddddd");
      let index = this.fileList.findIndex(v => v.url === url);
      this.fileList.splice(index, 1);
      this.disposeForm.riskWarnFileList.splice(index, 1);
    },
    cancel() {
      this.reset();
      this.$emit("CloseData");
    },
    reset() {
      const th = this;
      console.log(th);
      this.$refs.disposeForm.resetFields();
    },
    getClick(num) {
      this.AuditType = num;
      this.submit();
    },
    submit() {
      const _this = this;
      this.disposeForm.riskWarnPicList = this.photoList;
      this.disposeForm.warnUuid = this.warnUuid;
      this.disposeForm.eventId = this.eventId;
      if (_this.ctroType == "响应") {
        _this.disposeForm.type = 0;
      } else if (_this.ctroType == "整改") {
        _this.disposeForm.type = 1;
      } else if (_this.ctroType == "审核") {
        _this.disposeForm.type = _this.AuditType;
      } else if (_this.ctroType == "消除") {
        _this.disposeForm.type = 4;
      }
      this.$refs.disposeForm.validate(valid => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, _this.disposeForm);
          warnRecordAdd(copyForm)
            .then(res => {
              if (res.code == 0) {
                this.$message.success("操作成功");
                this.loading = true;
                this.cancel();
              } else {
                this.$message.error(res.message);
              }
            })
            .catch(res => {
              this.$message.error(res.message);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
  margin: 0 20px;
}
</style>
