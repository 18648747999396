<template>
  <!-- 大屏响应查看详情 -->
  <div
    style="font-size:16px"
    class="checkDetails"
    v-loading="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-form
      class="checkForm"
      ref="checkForm"
      :model="temList"
      label-width="130px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="事件类型:" prop="typeLabel">
            <el-input
              readonly
              placeholder="事件类型"
              v-model="temList.type.label"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="事件级别:" prop="currentLevelLabel">
            <el-input
              placeholder="事件级别"
              v-model="temList.currentLevel.label"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="启动时间:" prop="startTime">
            <el-input
              placeholder="启动时间"
              v-model="temList.startTime"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发布人:" prop="publisherName">
            <el-input
              placeholder="发布人"
              v-model="temList.publisherName"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="margin-bottom:10px" class="flex justify-between">
      <div>
        <el-radio-group v-model="radio" @change="radioChange">
          <el-radio-button :label="1">三防检查情况</el-radio-button>
          <el-radio-button :label="2">市交通统计信息填报情况</el-radio-button>
          <el-radio-button :label="3">工作简报情况</el-radio-button>
          <el-radio-button v-show="disaster == 1 && disasters != 2" :label="5"
            >灾害情况损失</el-radio-button
          >
          <el-radio-button :label="4">响应事件进展</el-radio-button>
        </el-radio-group>
      </div>
      <div style="position:relative;">
        <el-button
          type="primary"
          @click="briSummary"
          v-if="radio == 3 && deptFlag"
          >汇总数据</el-button
        >
        <el-button
          type="primary"
          @click="Summary"
          v-show="radio == '2' && operated"
          >汇总数据</el-button
        >
      </div>
    </div>
    <div
      class="search-wrapper"
      style="margin-bottom: 10px;
    "
    >
      <el-radio-group
        v-show="radio == '2'"
        v-model="tabRadioThree"
        size="small"
        style="margin-right: 20px"
        @change="getChangeThree"
      >
        <el-radio-button label="工点" v-show="lineSiteBtn"
          >工点</el-radio-button
        >
        <el-radio-button label="专职救援队" v-show="rescueBtn"
          >专职救援队</el-radio-button
        >
      </el-radio-group>
      <el-radio-group
        v-show="radio == '3'"
        v-model="tabRadioTwo"
        size="small"
        style="margin-right: 20px"
        @change="getChange"
      >
        <el-radio-button label="工点" v-if="BriefLineSiteBtn"></el-radio-button>
        <el-radio-button label="总包部" v-if="BriefRescueBtn"></el-radio-button>
      </el-radio-group>
    </div>

    <div v-show="radio != '4'" class="timeRadio">
      <el-radio-group
        size="mini"
        v-model="degree"
        v-for="item in times"
        :key="item.time"
        @change="degreeChange"
      >
        <el-radio-button
          style="margin:0 10px 5px 0"
          :label="item.label"
        ></el-radio-button>
      </el-radio-group>
    </div>
    <div class="details" style="color:#ffffff" v-show="radio != '4'">
      {{ description }}
    </div>
    <div class="info-wrapper detailHeight mt-4" v-if="radio != '4'">
      <el-table
        :data="tableData"
        style="width: 100%;"
        row-key="code"
        border
        default-expand-all
        height="400"
        ref="table"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          type="index"
          label="序号"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="lineName"
          :label="getLabel()"
          header-align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.children == null"
              style="color: #7073ff; cursor: pointer"
              @click="lookLineName(scope.row)"
            >
              {{ scope.row.lineName }}
            </span>
            <span v-if="scope.row.children">
              {{ scope.row.lineName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="constructionManagementName"
          label="所属建管部"
          header-align="center"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          prop="performance"
          label="完成情况"
          align="center"
          show-overflow-tooltip
          :formatter="manceFormatter"
        >
        </el-table-column>
        <el-table-column
          prop="issueCount"
          label="问题数量"
          align="center"
          show-overflow-tooltip
          v-if="radio == '1'"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.issueList"
              style="color: #7073ff; cursor: pointer"
              @click="lookNamePop(scope.row)"
            >
              {{ scope.row.issueCount }}
            </span>
            <span v-if="scope.row.issueList == null">
              {{ scope.row.issueCount }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 响应事件进展 -->
    <el-timeline
      :reverse="false"
      style="margin-top:20px;  height: 348px; overflow: auto;"
      v-if="radio == '4'"
    >
      <el-timeline-item
        style="margin-left:5px; "
        v-for="(activity, index) in historyData"
        :key="index"
        type="primary"
        color="#3fa5e9"
        :size="'large'"
      >
        <i> </i>
        <i style="font-style:normal;margin-right: 5px;color:#ffffff">
          {{ activity.operatorTime }}
        </i>
        <span style="color:#ffffff">
          {{ activity.actionType }}{{ activity.operationValue }}
        </span>
        <span style="color:#ffffff">
          {{
            activity.actionType == "发布"
              ? activity.record.originalAlertMessage
              : activity.actionType == "结束"
              ? activity.record.endRemark
              : activity.record.relegationRemark
          }}
        </span>
      </el-timeline-item>
    </el-timeline>
    <!-- 三防应急检查表 -->
    <Modal ref="emergencyCheck" :width="'1200px'" title="三防应急检查表">
      <emergencyCheck
        v-if="dialogVisibleOne"
        :operation="oneCheckTitle"
        :oneCheckData="oneCheckData"
        @cancelApproval="cancelApproval"
        @successApproval="successApproval"
      ></emergencyCheck>
    </Modal>
    <!-- 灾害 -->
    <Modal ref="examineCheck" width="80%" title="灾害损失及恢复情况上报详情">
      <examine
        v-if="examineDialog"
        :reportCurrentId="reportCurrentId"
        @examieClose="examieClose"
        :terwe="terwe"
      ></examine>
    </Modal>
    <!-- 统计表工点 -->
    <Modal
      ref="checker"
      :width="'1200px'"
      title="广州市交通运输系统三防工作统计表"
    >
      <checker
        v-if="checkerDialog"
        :strery="strery"
        :approveid="approveid"
        @chexkpageClose="chexkpageClose"
      ></checker>
    </Modal>
    <Modal
      ref="rescueCheck"
      :width="'1200px'"
      title="广州市交通运输系统三防工作统计表"
    >
      <rescueCheck
        v-if="rescueCheckDialog"
        :approveid="approveid"
        :options="reseuceOptions"
        @rescueCheckClose="rescueCheckClose"
      ></rescueCheck>
    </Modal>
    <Modal ref="briefingsViewModal" :width="'1200px'" title="工作简报">
      <briefingsView :briefings="briefings"></briefingsView>
    </Modal>
    <Modal ref="briefingsJGViewModal" :width="'1200px'" title="工作简报">
      <briefingsJGView
        :briefings="briefingsJG"
        @fingrest="onSearch"
        @btnClose="btnClose"
      ></briefingsJGView>
    </Modal>
    <!-- 问题清单 -->
    <Modal
      ref="checkQuestion"
      :width="'1200px'"
      title="问题清单"
      class="modalDetail"
    >
      <CheckQuestion
        v-if="dialogVisible"
        :parmsData="parmsData"
      ></CheckQuestion>
    </Modal>
    <!-- 三防工作简报汇总弹窗 -->
    <Modal ref="briQuality" :modal="false" :width="'1300px'" title="汇总数据">
      <briQualitys
        v-if="briQualitysDialog"
        ref="briQualitysMell"
        :userDept="userDept"
        :qualityResponseId="responseRecordId"
      ></briQualitys>
    </Modal>
    <Modal
      ref="Qualitys"
      :modal="false"
      :width="'1300px'"
      title="广州市交通运输系统三防工作统计表"
    >
      <qualitys :ids="aponerids"></qualitys>
    </Modal>
    <!-- <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="qualitysDialog"
      append-to-body
      width="80%"
      center
    >
      <qualitys :ids="aponerids"></qualitys>
    </el-dialog> -->
  </div>
</template>

<script>
import {
  getCurrentResponseTreeById,
  getCurrentResponseTeamTreeById,
  getCurrentResponseZongBaoTreeById,
  buttondata,
  currentGetOps,
} from "@/api/response";
import CheckQuestion from "./checkQuestions.vue";
import Modal from "@/components/Modal/modal.vue";
import { getDepartmentTypeByUser } from "@/api/org";
import { isRotaLeaderOrSafeDepartment } from "@/api/responseTem";
import briQualitys from "./prevention.vue";
import qualitys from "./qualitys.vue";
import emergencyCheck from "./emergencyCheck.vue";
import checker from "./checker.vue";
import rescueCheck from "./rescueCheck.vue";
import briefingsView from "./briefingView.vue";
import briefingsJGView from "./briefingJGView.vue";
import examine from "./examine.vue";

export default {
  props: {
    temList: {},
    historyData: {
      type: Array,
      default: () => [],
    },
    disasters: {
      type: Number,
      default: 1,
    },
  },
  components: {
    CheckQuestion,
    Modal,
    briQualitys,
    qualitys,
    emergencyCheck,
    checker,
    rescueCheck,
    briefingsView,
    briefingsJGView,
    examine,
  },
  data() {
    return {
      radio: "1",
      degree: "第1次",
      times: [],
      description: "",
      tableData: [],
      //问题清单
      dialogVisible: false,
      parmsData: [],
      loading: false,
      // 统计表汇总数据
      deptFlag: false,
      // 工作简报汇总数据
      operated: false,
      aponerids: {},
      briQualitysDialog: false,
      responseRecordId: null,
      userDept: "3",
      qualitysDialog: false,
      disaster: 1,
      tabRadioThree: "工点",
      lineSiteBtn: false,
      rescueBtn: false,
      tabRadioTwo: "工点",
      BriefLineSiteBtn: false,
      BriefRescueBtn: false,
      // 三防检查表
      dialogVisibleOne: false,
      oneCheckTitle: "",
      oneCheckData: {},
      // 交通统计表
      checkerDialog: false,
      rescueCheckDialog: false,
      approveid: {},
      reseuceOptions: "",
      strery: {},
      // 工作简报
      briefings: {},
      briefingsJG: {},
      terwe: "",
      reportCurrentId: null,
      examineDialog: false,
    };
  },
  created() {
    this.getTable(139, 1);
    this.getrot();
    this.buttoner();
    this.getUserDept();
    this.getOptions();
  },
  methods: {
    getTable(val, num) {
      this.loading = true;
      this.description = "";
      this.times = [];
      this.responseRecordId = this.temList.id;
      let paramsData = {
        responseRecordId: this.temList.id,
        type: val,
        number: num,
      };
      getCurrentResponseTreeById(paramsData)
        .then((res) => {
          if (res.code == 0) {
            this.description = res.data.description;
            this.tableData = res.data.treeList ? res.data.treeList : [];
            this.getLabel();
            if (res.data.maxNumber) {
              for (let i = 1; i <= res.data.maxNumber; i++) {
                this.times.push({
                  label: `第${i}次`,
                  value: i,
                });
              }
            }
            this.loading = false;
          }
        })
        .catch((err) => {
          this.tableData = [];
          console.log(err);
          this.loading = false;
        });
    },
    getLabel() {
      if (this.radio == "2" && this.tabRadioThree == "专职救援队") {
        return "专职救援队";
      } else if (this.radio == "3" && this.tabRadioTwo == "总包部") {
        return "总包部";
      } else {
        return "线路/工点";
      }
    },
    // 救援队
    getRescue(val, num) {
      this.loading = true;
      this.description = "";
      this.times = [];
      this.responseRecordId = this.temList.id;
      let paramsData = {
        responseRecordId: this.temList.id,
        type: val,
        number: num,
      };
      getCurrentResponseTeamTreeById(paramsData)
        .then((res) => {
          if (res.code == 0) {
            this.description = res.data.description;
            this.tableData = res.data.treeList ? res.data.treeList : [];
            if (res.data.maxNumber) {
              for (let i = 1; i <= res.data.maxNumber; i++) {
                this.times.push({
                  label: `第${i}次`,
                  value: i,
                });
              }
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.tableData = [];
          }
        })
        .catch((err) => {
          this.tableData = [];
          console.log(err);
          this.loading = false;
        });
    },
    // 总包
    getGeneral(val, num) {
      this.loading = true;
      this.description = "";
      this.times = [];
      this.responseRecordId = this.temList.id;
      let paramsData = {
        responseRecordId: this.temList.id,
        type: val,
        number: num,
      };
      getCurrentResponseZongBaoTreeById(paramsData)
        .then((res) => {
          if (res.code == 0) {
            this.description = res.data.description;
            this.tableData = res.data.treeList ? res.data.treeList : [];
            if (res.data.maxNumber) {
              for (let i = 1; i <= res.data.maxNumber; i++) {
                this.times.push({
                  label: `第${i}次`,
                  value: i,
                });
              }
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.tableData = [];
          }
        })
        .catch((err) => {
          this.tableData = [];
          console.log(err);
          this.loading = false;
        });
    },
    getOptions() {
      currentGetOps({ type: 140 }).then((res) => {
        if (res.code == 0) {
          // this.statisticsData = res.data;
          res.data.forEach((item) => {
            if (item.operationText == "工点") {
              this.BriefLineSiteBtn = item.enabled;
            } else if (item.operationText == "总包部") {
              this.BriefRescueBtn = item.enabled;
            }
          });
        }
      });
      currentGetOps({ type: 141 }).then((res) => {
        if (res.code == 0) {
          // this.tabRadioThreeData = res.data;
          res.data.forEach((item) => {
            if (item.operationText == "工点") {
              this.lineSiteBtn = item.enabled;
            } else if (item.operationText == "专职救援队") {
              this.rescueBtn = item.enabled;
            }
          });
          console.log("数据", res);
        }
      });
    },
    // 0-待填写、1-待监理审核、2-待建管审核、3-待质安部审核、4-已完成
    manceFormatter(row) {
      const condition = ["0", "1", "2", "3", "4", "5"];
      if (condition.includes(row.performance)) {
        return row.performance == 0
          ? "待填报"
          : row.performance == 1
          ? "总包已驳回"
          : row.performance == 2
          ? "建管部已驳回"
          : row.performance == 3
          ? "待总包审批"
          : row.performance == 4
          ? "待建管部确认"
          : row.performance == 5
          ? "已完成"
          : "";
      } else {
        return row.performance;
      }
    },
    // change切换
    radioChange(val) {
      if (val == 1) {
        this.getTable(139, 1);
      } else if (val == 2) {
        if (this.lineSiteBtn && !this.rescueBtn) {
          this.tabRadioThree = "工点";
          this.getTable(141, 1);
        } else if (this.rescueBtn && !this.lineSiteBtn) {
          this.tabRadioThree = "专职救援队";
          this.getRescue(141, 1);
        } else if (this.rescueBtn && this.lineSiteBtn) {
          this.tabRadioThree = "工点";
          this.getTable(141, 1);
        }
      } else if (val == 3) {
        if (this.BriefLineSiteBtn && !this.BriefRescueBtn) {
          this.tabRadioTwo = "工点";
          this.getTable(140, 1);
        } else if (this.BriefRescueBtn && !this.BriefLineSiteBtn) {
          this.tabRadioTwo = "总包部";
          this.getGeneral(140, 1);
        } else if (this.BriefRescueBtn && this.BriefLineSiteBtn) {
          this.tabRadioTwo = "工点";
          this.getTable(140, 1);
        }
      } else if (val == 5) {
        this.getTable(347, 1);
      }
      this.degree = "第1次";
      if (val != 4) {
        this.$nextTick(() => {
          this.$refs.table.doLayout(); // el-table添加ref="tableName"
        });
      }
    },
    // 工点/救援队切换
    getChangeThree(val) {
      if (val == "工点") {
        this.getTable(141, 1);
      } else {
        this.getRescue(141, 1);
      }
      this.degree = "第1次";
    },
    // 工点/总包
    getChange(val) {
      if (val == "工点") {
        this.getTable(140, 1);
      } else {
        this.getGeneral(140, 1);
      }
      this.degree = "第1次";
    },
    // 次数
    degreeChange(val) {
      var num = val.replace(/[^\d]/g, " ");
      if (this.radio == 2) {
        if (this.tabRadioThree == "工点") {
          this.getTable(141, num);
        } else {
          this.getRescue(141, num);
        }
      } else if (this.radio == 3) {
        if (this.tabRadioTwo == "工点") {
          this.getTable(140, num);
        } else {
          this.getGeneral(140, num);
        }
      } else if (this.radio == 1) {
        this.getTable(139, num);
      }
    },
    // 线路/工点
    lookLineName(val) {
      if (val.performance == 0) {
        this.$message.warning("待填写单位填写后方可查看");
      } else {
        if (this.radio == 1) {
          this.$refs.emergencyCheck.handleOpen();
          this.dialogVisibleOne = true;
          this.oneCheckTitle = "查看";
          this.oneCheckData = { ...val };
        } else if (this.radio == 2) {
          var ser = "1";
          this.approveid = Object.assign({}, val);
          this.btnLoading = false;
          if (this.tabRadioThree == "工点") {
            this.strery = Object.assign({}, ser);
            this.checkerDialog = true;
            this.$refs.checker.handleOpen();
          } else {
            this.reseuceOptions = "查看";
            this.rescueCheckDialog = true;
            this.$refs.rescueCheck.handleOpen();
          }
        } else if (this.radio == 3) {
          if (this.tabRadioTwo == "工点") {
            this.briefings = val;
            this.$refs.briefingsViewModal.handleOpen();
          } else if (this.tabRadioTwo == "总包部") {
            this.briefingsJG = val;
            this.$refs.briefingsJGViewModal.handleOpen();
          }
        } else if (this.radio == 5) {
          this.terwe = "查看";
          this.examineDialog = true;
          this.reportCurrentId = val.id;
          this.$refs.examineCheck.handleOpen();
        }
      }
    },
    lookNamePop(val) {
      this.dialogVisible = true;
      this.$refs.checkQuestion.handleOpen();
      this.parmsData = val.issueList;
    },
    // 检查表
    successApproval() {
      this.dialogVisibleOne = false;
      this.$refs.emergencyCheck.handleClose();
    },
    cancelApproval() {
      this.dialogVisibleOne = false;
      this.$refs.emergencyCheck.handleClose();
    },
    chexkpageClose() {
      this.checkerDialog = false;
      this.$refs.checker.handleClose();
    },
    rescueCheckClose() {
      this.rescueCheckDialog = false;
      this.$refs.rescueCheck.handleClose();
    },
    // 灾害
    examieClose() {
      this.examineDialog = false;
      this.$refs.examineCheck.handleClose();
    },
    btnClose() {},
    onSearch() {},
    sureSub() {
      this.$emit("detailClose");
    },
    //判断当前响应页面列表是否有建管部按钮权限
    getrot() {
      isRotaLeaderOrSafeDepartment({}).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            this.deptFlag = res.data;
          }
        }
      });
    },
    briSummary() {
      this.$refs.briQuality.handleOpen();
      this.briQualitysDialog = true;
      this.$refs.briQualitysMell.getTableData();
    },
    //权限按钮
    buttoner() {
      buttondata({ id: this.temList.id }).then((res) => {
        if (res.code == 0) {
          res.data.forEach((item) => {
            if (item.operationText == "汇总数据") {
              this.operated = item.enabled;
            }
          });
        }
      });
    },
    Summary() {
      this.aponerids = Object.assign({}, this.temList);
      this.$refs.Qualitys.handleOpen();
      this.qualitysDialog = true;
    },
    //获取当前用户是什么部门
    getUserDept() {
      getDepartmentTypeByUser()
        .then((res) => {
          let text = res.data;
          this.userDept = text.toString();
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-wrapper {
  ::v-deep .el-table--border,
  .el-table::before {
    border: none;
    border-bottom: 0 !important;
    height: 0px;
  }
  .el-table--border::after {
    width: 0;
  }
  ::v-deep .el-table th.is-leaf,
  ::v-deep .el-table--border td {
    border: 1px solid #143363 !important;
  }
}
// ::v-deep .el-dialog__body {
//   height: 700px;
//   overflow: auto;
// }
::v-deep .el-dialog--center .el-dialog__body {
  padding: 0 !important;
}
::v-deep.el-table::before {
  display: none;
}
::v-deep .el-table__header-wrapper {
  position: sticky;
  top: 0;
  z-index: 1;
}
.detailHeight ::v-deep .el-table__body-wrapper {
  height: 348px !important;
}
// ::v-deep .modal-wrapper {
//   height: 348px !important;
// }
.checkForm ::v-deep .el-form-item__label {
  color: #ffffff !important;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
.timeRadio ::v-deep .el-radio-button {
  width: 75px;
}
.timeRadio ::v-deep .el-radio-button__inner {
  width: 65px;
}
::v-deep .modal-container {
  height: 700px;
  overflow: auto;
  position: relative;
  z-index: 11111;
}
::v-deep .corner-wrap {
  .corner {
    display: none;
  }
}
</style>
