<template>
  <div class="material-container h-full">
    <el-steps
      :active="active"
      finish-status="success"
      process-status="wait"
      align-center
    >
      <el-step title="" v-for="item in years" :key="item.id"></el-step>
    </el-steps>
    <div class="yearName">
      <div
        :class="
          active > item.id
            ? 'prevYear'
            : active < item.id
            ? 'nextYear'
            : 'activeYear'
        "
        v-for="item in years"
        :key="item.id"
      >
        <span>{{ item.name }}</span>
      </div>
    </div>
    <el-form :model="formData" label-width="80px" style="padding: 50px 40px 0">
      <el-form-item label="审核人">
        <el-input v-model="formData.approvalName" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="审核描述">
        <el-input v-model="formData.approverOpinion" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="补充附件">
        <FileUploader
          @uploadChange="handleFileUpload"
          @delChange="handleFileDel"
          :value="fileList"
        ></FileUploader>
      </el-form-item>
    </el-form>
    <div class="dialogFooter">
      <el-button size="medium" type="danger" @click="reject">驳 回</el-button>
      <el-button size="medium" type="primary" @click="submit">通 过</el-button>
    </div>
  </div>
</template>

<script>
import { riskLibraryapply, checkSuccess, getMsg } from "@/api/risk";
import FileUploader from "@/components/Uploader/FileUploader.vue";
// 字典
export default {
  components: {
    FileUploader,
  },
  props: {
    pageData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      active: 1,
      years: [],
      colActive: 5,
      riskName: "风险名称",
      stateList: [
        "施工单位申请",
        "监理单位审核",
        "总承包单位审核",
        "建管部审核",
        "建设公司审核",
        "集团审批",
      ],
      formData: {
        approverOpinion: "",
      },
      fileList: [],
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      for (let i = 0; i < 6; i++) {
        let obj = {};
        obj.id = i;
        obj.name = this.stateList[i];
        this.years.push(obj);
      }
      this.active = this.pageData.state - 1;
      Object.assign(this.formData, this.pageData);
      console.log(this.pageData);
    },
    submit() {
      this.formData.approverStatus = 1;
      this.formData.approverFile = this.fileList.length
        ? this.fileList[0].url + "," + this.fileList[0].filePath
        : "";
      if (this.pageData.isAll) {
        let reqArr = [];
        let sendMsg = [];
        let arr = [...this.pageData.riskDeafUuid];
        arr.forEach((i) => {
          this.formData.riskDeafUuid = i;
          let req = riskLibraryapply(this.formData);
          reqArr.push(req);
          sendMsg.push(this.formData)
        });
        Promise.all(reqArr).then((res) => {
          let isTrue = res.every((i) => {
            return i.code == 0;
          });
          console.log(isTrue);
          if (isTrue) {
            this.$message.success("审核成功");
            // 如果是集团审核通过，调用待办通知，state==6为集团审核
            if (this.formData.state == 6) {
              // 重复调用待办通知接口
              sendMsg.forEach((i) => {
                getMsg(this.formData.riskDeafUuid).then((res)=>{
                  checkSuccess(res.data).then((res) => {
                    console.log(res, "调用成功");
                  });
                });
              });
            }
          } else {
            this.$message.error("审核失败");
          }
          this.cancel();
        });
      } else {
        riskLibraryapply(this.formData).then((res) => {
          if (res.code == 0) {
            this.$message.success("审批成功");
            // 如果是集团审核通过，调用待办通知，state==6为集团审核
            if (this.formData.state == 6) {
              getMsg(this.formData.riskDeafUuid).then((res)=>{
                checkSuccess(res.data).then((res) => {
                  console.log(res, "调用成功");
                });
              });
            }
          } else {
            this.$message.error("审批失败" + res.$message);
          }
          this.cancel();
        });
      }
    },
    reject() {
      this.formData.approverStatus = 0;
      this.formData.approverFile = this.fileList.length
        ? this.fileList[0].url + "," + this.fileList[0].filePath
        : "";
      if (this.pageData.isAll) {
        let reqArr = [];
        let arr = [...this.pageData.riskDeafUuid];
        arr.forEach((i) => {
          this.formData.riskDeafUuid = i;
          let req = riskLibraryapply(this.formData);
          reqArr.push(req);
        });
        Promise.all(reqArr).then((res) => {
          let isTrue = res.every((i) => {
            return i.code == 0;
          });
          console.log(isTrue);
          if (isTrue) {
            this.$message.success("审核成功");
          } else {
            this.$message.error("审核失败");
          }
          this.cancel();
        });
      } else {
        riskLibraryapply(this.formData).then((res) => {
          if (res.code == 0) {
            this.$message.success("审批成功");
          } else {
            this.$message.error("审批失败" + res.$message);
          }
          this.cancel();
        });
      }
    },
    handleFileUpload(e) {
      if (this.fileList.length) {
        this.fileList.shift();
      }
      this.fileList.push({
        originalName: e.name,
        url: e.url,
        filePath: e.realUrl,
      });
      console.log(e);
    },
    handleFileDel(e) {
      console.log(e);
      let index = this.fileList.findIndex((v) => v.url === e.url);
      this.fileList.splice(index, 1);
    },
    cancel() {
      this.$emit("CloseData");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
::v-deep .el-step__title.is-success {
  color: #409eff;
}
::v-deep .el-step__head.is-success {
  color: #409eff;
  border-color: #409eff;
}
.yearName {
  margin-top: 14px;
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  span {
    cursor: pointer;
    color: #999;
    display: inline-block;
    width: 130px;
    text-align: center;
  }
  .activeYear {
    text-align: center;
    span {
      color: #999;
    }
  }
  .nextYear {
    text-align: center;
    span {
      color: #999;
    }
  }
  .prevYear {
    text-align: center;
    span {
      color: #409eff;
    }
    .rejectStyle {
      color: #ff0000;
    }
  }
}
.dialogWrap {
  padding: 0 46px;
  .riskName {
    font-size: 16px;
    margin: 30px 0 20px;
    span {
      margin-left: 20px;
    }
  }
  .process {
    display: flex;
    .process_left {
      width: 50px;
    }
    .process_content {
      flex: 1;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      position: relative;
      top: -18px;
      span {
        color: #333;
      }
      .process_content_time {
        margin-right: 20px;
      }
    }
    .process_right {
      width: 100px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      position: relative;
      top: -18px;
      span {
        cursor: pointer;
        color: #409eef;
      }
    }
  }
}
</style>
