import {
  httpInit
} from "./http"
const http = httpInit("/gzdt-eres")

// 应急物资分页查询
export const queryMaterialsList = params =>
  http.get(
    `/eres/materials/list/${params.lineId}/${params.siteId}/${params.type}/${params.label}/${params.modelNo}/${params.pageNo}/${params.pageSize}`
  )
// 救援队分页查询
export const queryTeamList = params =>
  http.get(
    `/eres/team/list/${params.teamName}/${params.pageNo}/${params.pageSize}`
  )
  // 救援队下拉列表
export const getTeamList = params => http.get(`/api/eres/teamList/${params.teamType}`)

//值班人员到岗统计
export const queryStatistic = params =>
  http.get(`/eres/rota/statistic/${params}`)

//查询救援队列表，根据筛选条件精确匹配
export const queryTeamLists = params =>
  http.get(`/eres/team/list/${params.teamType}/${params.teamName}/${params.personName}/${params.materialsName}`)

//查询安置信息列表，根据筛选条件精确匹配
export const queryMentList = params =>
  http.get(
    `/eres/resettlement/list/${params.lineId}/${params.siteId}/${params.departmentId}/${params.resettlementSite}`
  )

//全量查询值班人员列表，根据筛选条件精确匹配
export const queryDutyList = params =>
  http.get(
    `/eres/duty/list/${params.lineId}/${params.siteId}/${params.grouping}/${params.realName}`
  )

// 根据id查询救援队列表
export const queryTeamDetailById = teamId =>
  http.get(`/eres/worker/list/${teamId}`)

// ------------------------ 排班 start -----------------------------
// 查询当月值班表（站点人员、领导）
export const queryDutyMonth = data => http.post(`/eres/rotaNew/list/month`, data)

// 查询当月值班表（专职救援队）
export const queryRescueDutyMonth = data => http.post(`/eres/rotaNew/rescue/list/month`, data)

// 值班人员列表
export const queryDutyPersonList = () => http.get(`/eres/rotaNew/rota/staff/list`)

// 救援队值班人员列表
export const queryRescueDutyPersonList = () => http.get(`/eres/rotaNew/rota/staff/rescue/list`)

// 站点人员、建管领导添加排班接口
export const addDuty = data => http.post(`/eres/rotaNew/add`, data)

// 添加救援队排班接口
export const addRescueDuty = data => http.post(`/eres/rotaNew/add/rescue/team`, data)
// ------------------------ 排班 end -----------------------------

// 全量查询人员列表
export const queryWorkerList = params =>
  http.get(
    `/eres/worker_info/list/${params.lineId}/${params.siteId}/${params.grouping}/${params.type}/${params.realName}`
  )

// 分页查询人员列表
export const queryWorkerListByPage = params =>
  http.get(
    `/eres/worker_info/list/${params.lineId}/${params.siteId}/${params.vendorId}/${params.departmentId}/${params.grouping}/${params.type}/${params.realName}/${params.page}/${params.size}`
  )

// 人员新增
export const addWorker = data => http.post(`/eres/worker_info/info`, data)
// 人员修改
export const editWorker = params =>
  http.put(`/eres/worker_info/info/${params.id}`, params.data)
// 人员删除
export const delWorker = id =>
  http.delete(`/eres/worker_info/info/${id}`)

//人员详情
export const userInfo = params =>
  http.get(`/eres/worker_info/info/${params}`)


// 查询人员分组列表
export const queryGroup = params =>
  http.get(`/eres/worker_info/list/group`, params)

// 分页查询救援队人员信息资源列表
export const queryRescueTeamMemberList = params =>
  http.get(
    `/eres/worker/list/${params.teamId}/${params.postId}/${params.name}/${params.page}/${params.size}`
  )

// 新增专职救援队人员数据
export const addRescueTeamMember = data => http.post(`/eres/worker/info`, data)

// 更新专职救援队成员数据
export const editRescueTeamMember = params =>
  http.put(`/eres/worker/info/${params.id}`, params.data)
// 删除专职救援队成员数据
export const deleteRescueTeamMember = id =>
  http.delete(`/eres/worker/info/${id}`)

// 更新专职救援队信息
export const updateTeamInfo = params =>
  http.put(`/eres/team/info/${params.id}`, params.data)

// 更新社会救援队信息
export const updateStance = params =>
  http.put(`/eres/team/social/info/${params.id}`, params.data)
// 更新兼职救援队信息
export const updatePatrTeamInfo = params =>
  http.put(`/eres/team/modifyPartTimeRescueTeam/${params.id}`, params.data)

// 新增专职救援队
export const addTeamInfo = data => http.post(`/eres/team/info`, data)
// 新增兼职救援队
export const addPartTeamInfo = data => http.post(`/eres/team/addPartTimeRescueTeam`, data)
// 新增社会救援队信息
export const addStance = data => http.post(`/eres/team/social/info `, data)

// 删除排班数据
export const delDuty = params => http.delete(`/eres/rota/info/${params.ids}`)

// 应急物资出库
export const materialOut = data =>
  http.put(`/eres/materials/ex_warehouse/${data.id}`, data.data)

// 应急物资入库
export const materialIn = data =>
  http.put(`/eres/materials/warehousing/${data.id}`, data.data)

//全量查询人员列表
export const personnelIn = params =>
  http.get(
    `/eres/worker_info/list/${params.lineId}/${params.siteId}/${params.grouping}/${params.type}/${params.realName}`
  )
//查询当日值班人员
export const getduty = params =>
  http.get(`/eres/rota/list/curday/worker/${params.siteId}`)


// 应急资源/设备/专职救援队物资新增（新）
export const addMaterials = data => http.post(`/eres/materials/new/add`, data)

// 修改应急资源(新)
export const editMaterials = data => http.post(`/eres/materials/new/update/${data.id}`, data)

// 分页查询应急物资列表（新）
export const getMaterialsPage = data => http.post(`/eres/materials/new/page`, data)

// 物资出库
export const materialsOut = data => http.post(`/eres/materials/new/warehouse/${data.id}`, data)

// 物资入库
export const materialsIn = data => http.post(`/eres/materials/new/warehousing/${data.id}`, data)

// 删除应急资源 (新)
export const delMaterials = id => http.get(`/eres/materials/new/delete/${id}`)

// 物资历史记录
export const materialsHistory = data => http.post(`/eres/materialsRecord/page`, data)

// 查询公共资源列表
export const GetPublic = params =>
  http.post(`/eres/resourceLibrary/page`, params)
// 战时查询公共资源列表
export const GetPublices = params =>
  http.post(`/eres/resourceLibrary/getPageByDistrict`, params)
// 新增公共资源
export const AddPublic = params =>
  http.post(`/eres/resourceLibrary/save`, params)
// 更新公共资源
export const EditPublic = params =>
  http.put(`/eres/resourceLibrary/update`, params)
// 删除公共资源
export const DelPublic = params =>
  http.delete(`/eres/resourceLibrary/delete/${params.resourceId}`, params)

// 专家库列表查询
export const getExpertPage = data => http.post(`/eres/specialistInfo/page`, data)

// 新增专家
export const addExpert = data => http.post(`/eres/specialistInfo/save`, data)

// 编辑专家
export const updateExpert = data => http.put(`/eres/specialistInfo/update`, data)

// 删除专家
export const delExpert = specialistId => http.delete(`/eres/specialistInfo/delete/${specialistId}`)
// 分页查询专职救援队数据
export const getSoleDuty = params =>
  http.post(
    `/eres/team/list`, params
  )
// 删除专职救援队
export const delSoleDuty = id => http.delete(`/eres/team/info/${id}`)

// 单兵分页查询
export const getIndividualPage = data => http.post(`/eres/individual/page`, data)

// 单兵修改
export const updateIndividual = data => http.post(`/eres/individual/update`, data)

// 单兵删除
export const delIndividual = id => http.delete(`/eres/individual/delete/${id}`)

// 单兵新增
export const addIndividual = data => http.post(`/eres/individual/save`, data)

// 部门单兵备选列表
export const getDeptAccountListByDeptId = params => http.post(`/eres/individual/getDeptAccountListByDeptId/${params.deptUuId}`, {
  data: params.data
})
// 部门下所有人员列表
export const getDeptAccountList = params => http.post(`/eres/individual/getDeptAllAccountListByDeptId/${params.deptUuId}`, params)

// 
export const getDeptAccountListV2 = params => http.post(`/eres/individual/getDeptAllAccountListByDeptIdV2/${params.deptUuId}`, params)

// 大屏值班列表
export const getEresList = data => http.post(`/eres/rotaNew/screen/rota/page`, data)
// 查询公共资源列表
export const getResource = data => http.post(`/eres/resourceLibrary/list`, data)

//分页查询救援队人员信息列表
export const getWorkers = params =>
  http.get(
    `/eres/worker/list/${params.teamId}/${params.postId}/${params.name}/${params.page}/${params.size}`
  )

// 物资库负责人列表
export const getWareHouseManList = data => http.post(`/eres/materials/leader/page`, data)

// 物资库负责人新增
export const addWareHouseMan = data => http.post(`/eres/materials/leader/add`, data)

// 物资库负责人修改
export const editWareHouseMan = data => http.post(`/eres/materials/leader/update/${data.id}`, data)

// 物资库负责人删除
export const delWareHouseMan = id => http.get(`/eres/materials/leader/delete/${id}`)

// 查询负责人列表
export const getChargePerson = () => http.get(`/eres/materials/leader/getUserList`)
// 大屏物资仓库列表
export const getMaterials = data => http.post(`/eres/materials/new/screen/warehouse/list`, data)

//查询范围内的公共资源-地图图标展示

export const publicresources = (query) => {
  return http({
    method: "get",
    url: "/eres/resourceLibrary/listInRange",
    headers: {
      "Content-Type": "application/json"
    },
    params: query
  })
}


//查询范围内的救援队-地图图标展示


export const rescueteam = (query) => {
  return http({
    method: "get",
    url: "/eres/team/listInRange",
    headers: {
      "Content-Type": "application/json"
    },
    params: query
  })
}
//查询范围内的物资设备-地图图标展示


export const equipmentlist = (query) => {
  return http({
    method: "get",
    url: "/eres/materials/new/listInRange",
    headers: {
      "Content-Type": "application/json"
    },
    params: query
  })
}




// 合并---------------------11111111111111111111111------------------------------

// 查询当天值班表
export const queryDutyDay = params =>
  http.get(
    `/eres/rota/list/curday/${params.lineId}/${params.siteId}/${params.emergency}`
  )

// // 应急物资新增
// export const addMaterial = data => http.post(`/eres/materials/info`, data)

//查询当日值班
export const getonduty = params =>
  http.get(
    `/eres/rota/list/curday/worker/map/${params.siteId}/${params.lineId}/${params.page}/${params.size}`
  )



// 大屏调配物资记录列表
export const materialsRecord = params => http.get(`/eres/materials/new/screen/deploy/materials?id=${params.id}`)

// 大屏调配人员记录列表
export const userRecord = params => http.get(`/eres/materials/new/screen/deploy/staff?id=${params.id}`)

// 物资/设备excel导入
export const materialImport = data => http.post(`/eres/materials/new/import`, data)

//资源调配

export const allocation = data => http.post(`/eres/materials/new/allocate`, data)
//资源调配

export const addMaterial = data => http.post(`/eres/materials/info`, data)

//应急演练
// 查询登录人权限内的专职救援队
export const getQxlist = () => http.get(`/eres/team/getQxlist`)

// 物资模板导出
export const exportMaterialFile = () => {
  return http({
    method: "get",
    url: "/eres/excle/material/export",
    headers: {
      "Content-Type": "application/json"
    },
    responseType: 'blob',
  })
}
// 设备模板导出
export const exportEquipmentFile = () => {
  return http({
    method: "get",
    url: "/eres/excle/equipment/export",
    headers: {
      "Content-Type": "application/json"
    },
    responseType: 'blob',
  })
}

//应急处置卡的模板下载
export const ImportCard = () => http.get(`/eres/excle/dispositionCard/export`, {
  //重要  没有她导出的文件会打不开 
  responseType: 'blob'
})


// 消息代办物资库立即处理分配
export const materialHandle = data => http.post(`/eres/materials/new/material/handle/deploy`, data)

// 查询物资处理信息
export const queryMaterialHandleInfo = params => http.get(`/eres/materials/new/material/handle/info`, { params })

// 物资调配驳回
export const rejectMaterialHandle = data => http.post(`/eres/materials/new/material/handle/reason`, data)

// 查询站点和救援队调配人员列表
export const queryMaterialStaffPerson = params => http.get(`/eres/materials/new/material/staff/list`, { params })
//物资配送详情通知消息弹窗
export const allocatesrt = params => http.get(`/eres/materials/new/material/alter/delivery?eventId=${params.eventId}`)

//物资配送人通知消息弹窗
export const distributorNotice = params => http.get(`/eres/materials/new/material/alter/deliveryNotice?eventId=${params.eventId}`)

//物资通知消息详情弹窗
export const suppliesInform = params => http.get(`/eres/materials/new/material/alter/notice?eventId=${params.eventId}`)

//物资驳回消息弹窗
export const rejectedGoods = params => http.get(`/eres/materials/new/material/alter/reject?eventId=${params.eventId}`)

//查询工点下人员信息
export const getSiteUserInformation = id =>
  http.get(
    `/eres/rotaNew/site/rota/info/${id}`
  )

// 大屏物资库列表接口
export const getScreenMaterialsNew = data => http.post(`/eres/materials/new/screen/page`, data)

//大屏值班人员统计
export const screenUser = params => http.get(`/eres/rotaNew/screen/rota/count?type=${params}`)

//调配所需物资列表

export const suppliesList = () => http.get(`/eres/materials/new/material/menu/list`)

//调配申请
export const getsupplieialsNew = data => http.post(`/eres/materials/new/material/apply`, data)

//物资申请代办消息立即处理弹窗
export const getimmediate = params => http.get(`/eres/materials/new/material/applyHandle/alter/notice?eventId=${params.eventId}`)

//申请物资调配
export const geteployment = data => http.post(`/eres/materials/new/apply/allocate`, data)

//物资调配列表
export const getployialsNew = data => http.post(`/eres/materials/new/material/deployApply/list`, data)

//物资申请驳回
export const getapplication = data => http.post(`/eres/materials/new/material/applyHandle/reject`, data)

//物资申请告知、代办消息详情弹窗(state: 1通过弹窗、2驳回驳回弹窗)
export const getdetails = params => http.get(`/eres/materials/new/material/applyInfo/alter/notice?eventId=${params.eventId}`)

//考勤列表
// export const attendanceList = data => http.post(`/checkAttendance/page/info`, data,{headers: {'Content-Type': 'application/json;charset=utf8'}})
export const attendanceList = data => http({ url: `/checkAttendance/page/info`, data, method: "post", headers: { 'Content-Type': 'application/json;charset=utf8' } })
//考勤导出
export const deriveList = data => http({ url: `/checkAttendance/export/list/info`, data, method: "post", responseType: "blob", headers: { 'Content-Type': 'application/json;charset=utf8' } })

//考勤删除
export const deletionList = data => http.delete(`/checkAttendance/delete/id/${data.id}`)

//物资盘点列表
export const InventorysseList = params => http.get(`/eres/materials/inventory/app/page?page=${params.page}&size=${params.size}&endDate=${params.endDate}&startDate=${params.startDate}&siteId=${params.siteId}&state=${params.state}&type=${params.type}`)

//导出盘点
export const exinventory = data => http({ url: `/eres/materials/inventory/app/export/list/info`, data, method: "post", responseType: "blob", headers: { 'Content-Type': 'application/json;charset=utf8' } })
//物资盘点详情

export const inventoryList = params => http.get(`/eres/materials/inventory/app/info?id=${params.id}`)

//物资库盘点流程
export const process = params => http.get(`/eres/materials/inventory/app/process?id=${params.id}`)

//异常物资列表详情

export const material = params => http.get(`/eres/materials/inventory/app/abnormal/list?id=${params.id}`)

//正常物资列表详情

export const supplies = params => http.get(`/eres/materials/inventory/app/normal/list?id=${params.id}&siteId=${params.siteId}`)

//物资库盘点审批身份

export const Approval = () => http.get(`/eres/materials/inventory/app/isApprove/identity`)

//物资审批
export const inventory = data => http.post(`/eres/materials/inventory/app/approval`, data)



//查询当天值班列表
export const dayRota = data => http.post(`/eres/rotaNew/dayRota`, data)


//删除排班数据
export const destoryRota = (id) => http.get(`/eres/rotaNew/destoryRota?zid=` + id)

//查询救援队当天值班列表
export const teamDayRota = data => http.post(`/eres/rotaNew/team/dayRota`, data)



//数据中心-当天值班人员列表
export const curdayWorker = data => http.post(`/eres/rotaNew/list/curday/worker`, data)

//获取专职救援队下的用户列表
export const getUserList = data => http.post(`/eres/team/getUserList`, data)

