<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
             label-width="120px" class="demo-model">

      <el-row>
        <el-col :span="16">
          <el-form-item label="标题" prop="itemName">
            <el-input v-model="title" disabled placeholder="标题"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查类型" prop="itemName">
            <el-input v-model="detailData.taskType" disabled placeholder="排查类型"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查单位" prop="itemName">
            <el-input v-model="detailData.unitType" disabled placeholder="排查单位"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查人员" prop="itemName">
            <el-input v-model="detailData.personLiable" disabled placeholder="排查人员"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查时间" prop="itemName">
            <el-input v-model="detailData.created" disabled placeholder="排查时间"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="线路" prop="itemName">
            <el-input v-model="detailData.lineName" disabled placeholder="线路"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="分部/标段" prop="itemName">
            <el-input v-model="detailData.branchName" disabled placeholder="分部/标段"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工点" prop="itemName">
            <el-input v-model="detailData.workAreaName" disabled placeholder="工点"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>


    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column align="center" type="index" label="序号" width="100"></el-table-column>
        <el-table-column align="center" prop="itemName" label="隐患条目" width="">
          <template slot-scope="scope">
            <span class="file-item" @click="handleDetailData(scope.row)"> {{ scope.row.itemName }} </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="itemName" label="上报时间" width="" v-if="title === '专项排查'"></el-table-column><!--   专项排查时显示    -->
        <el-table-column align="center" prop="status" label="隐患状态" width="" :formatter="formatter"></el-table-column>
      </el-table>
    </div>

    <el-dialog
        width="70%"
        :visible.sync="dialogVisible"
        class="editDialog"
        append-to-body>
      <detailRecord v-if="dialogVisible" :detail-data="detailInfo" :fatherId="detailData.id"
                    :type="type"></detailRecord>
    </el-dialog>


  </div>
</template>

<script>
import {getRecordDetailsByTaskId, recordDetails} from "@/api/hdanger";
import detailRecord from "@/views/HiddenDanger/System/DangerTrouble/DayTrouble/components/detailRecord";

export default {
  name: "dayRecord",
  components : {
    detailRecord
  },
  props : {
    title : {
      default : ''
    },
    detailData : {
      default : () => {}
    },
    type : {
      default : ''
    },
    where:{
      default : 'operation'
    }
  },
  data(){
    return{
      ruleForm : {},
      rules : {},
      tableData : [],
      loading : false,
      dialogVisible : false,
      detailInfo : {}
    }
  },
  created() {
    if(this.where === 'todo'){
      this.getToDoList();
    }else{
      this.getTableData();
    }
  },
  methods : {
    getTableData(){
      this.loading = true;
      let params = {
        id : this.detailData.id,
        screeningType : this.type,
      }
      recordDetails(params).then(res => {
        this.tableData = res.data.itemList;
        this.detailData = res.data;
        this.loading = false;
      })
    },
    getToDoList(){
      getRecordDetailsByTaskId(this.detailData.id).then(res => {
        this.tableData = res.data.itemList;
      })
    },
    formatter(row, column){
      console.log(column);
      // 0 待审核 1待整改 2 待消除 3已消除
      return row.status == 0 ? '待审核' : row.status == 1 ? '待整改' : row.status == 2 ? '待消除' :
          row.status == 3 ? '已消除' : '';
    },
    handleDetailData(row){
      this.detailInfo = {...row};
      this.dialogVisible = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.file-item {
  color: #3090ff;
  cursor: pointer;
}
</style>
