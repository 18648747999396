<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
            <el-select
              v-model="queryModal.eventTypeId"
              placeholder="选择事件类型"
              clearable
            >
              <el-option
                :label="data.label"
                :value="data.id"
                v-for="(data, index) in typeList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select
              v-model="queryModal.eventLevelId"
              placeholder="选择事件级别"
              clearable
            >
              <el-option
                :label="data.label"
                :value="data.id"
                v-for="(data, index) in levelList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="">
            <el-date-picker
              v-model="queryModal.times"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="">
            <el-input
              v-model="queryModal.caseName"
              placeholder="输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="setsmit">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <!-- <el-button
            type="primary"
            @click="handleAdd"
            v-has="'floodControl:add'"
            >下载模板</el-button
          >
          <el-button
            type="primary"
            @click="handleAdd"
            v-has="'floodControl:add'"
            >导入</el-button
          > -->
          <el-button
            type="primary"
            @click="handleAdd"
            v-has="'CaseLibrary:newly'"
            >新增</el-button
          >
          <!-- <el-button
            type="primary"
            @click="handleAdd"
            v-has="'floodControl:add'"
            >导出</el-button
          > -->
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" v-loading="tableLoading" style="width: 100%">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="80"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="caseName"
          label="事件名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="caseLocation"
          label="事发地点"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="eventType"
          label="事件类型"
        ></el-table-column>
        <el-table-column align="center" prop="caseType" label="事故/件类别">
        </el-table-column>
        <el-table-column
          align="center"
          prop="occurrenceTime"
          label="发生时间"
        ></el-table-column>

        <el-table-column
          align="center"
          prop="enclosure"
          label="附件"
          width="150"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="handleFileClick(scope.row)"
              >附件</el-button
            >
            <!-- <el-image
              style="width: 150px; height: 80px"
              :src="scope.row.enclosure"
              :preview-src-list="[scope.row.enclosure]"
            >
            </el-image> -->
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="uploaderName"
          label="上传人"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="uploadTime"
          label="上传时间"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-has="'CaseLibrary:editor'"
              >编辑</el-button
            >

            <el-button
              @click="handleDelete(scope.row)"
              type="text"
              v-has="'CaseLibrary:delete'"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="queryModal.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <CaseForm
      ref="modelForm"
      :typeList="typeList"
      :levelList="levelList"
      :caseList="caseList"
      @getdata="setsmit"
    ></CaseForm>
  </div>
</template>

<script>
import CaseForm from "./modules/caseForm";
import { caseLibraryList, caseLibraryDelete } from "@/api/response";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
export default {
  components: {
    CaseForm,
  },
  data() {
    return {
      queryModal: {
        eventTypeId: "",
        eventLevelId: "",
        times: [],
        caseName: "",
        page: 1,
        size: 10,
        total: 0,
        type: 1,
      },
      tableData: [],
      options: [],
      lineSiteData: [],
      typeList: [],
      levelList: [],
      tableLoading: false,
      caseList: [],
    };
  },
  mounted() {
    this.getdata();
    queryDictByLabel({ label: "事件类型" }).then((res) => {
      queryDictNodesById({ id: res.data.id }).then((el) => {
        this.typeList = el.data;
      });
    });
    queryDictByLabel({ label: "事故类别" }).then((res) => {
      queryDictNodesById({ id: res.data.id }).then((el) => {
        this.caseList = el.data;
      });
    });

    queryDictByLabel({ label: "当前等级" }).then((res) => {
      queryDictNodesById({ id: res.data.id }).then((el) => {
        this.levelList = el.data;
        this.levelList.pop();
      });
    });
  },
  methods: {
    setsmit() {
      this.queryModal.page = 1;
      this.getdata();
    },
    //条数改变
    handleSizeChange(val) {
      this.queryData.size = val;
      this.queryData.page = 1;
      this.getdata();
    },
    // 翻页
    handleCurrentChange(val) {
      this.queryData.page = val;
      this.getdata();
    },
    async getdata() {
      this.tableLoading = true;
      let params = {
        eventTypeId: this.queryModal.eventTypeId,
        eventLevelId: this.queryModal.eventLevelId,
        startTime: this.queryModal.times[0] ? this.queryModal.times[0] : "",
        endTime: this.queryModal.times[1] ? this.queryModal.times[0] : "",
        caseName: this.queryModal.caseName,
        pageNum: this.queryModal.page,
        pageSize: this.queryModal.size,
      };
      for (const key in params) {
        if (params[key] == "") {
          delete params[key];
        }
      }
      let res = await caseLibraryList(params);
      if (res.code == 0) {
        console.log(res);
        this.tableData = res.data.records;
        this.tableLoading = false;
      }
    },
    handleAdd() {
      this.$refs.modelForm.handleAdd();
    },
    handleEdit(record) {
      this.$refs.modelForm.handleAdd(record);
    },
    handleAudit(record) {
      this.$refs.AuditForm.handleAdd(record);
    },
    handleDelete(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        caseLibraryDelete(record).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.getdata();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    handleLog() {
      this.tableLoading = true;
    },
    handleFileClick(record) {
      const link = document.createElement("a");
      link.href = record.enclosure;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped></style>
