import { httpInit } from "./http";
const http = httpInit("/gzdt-linesite");

// 更新bim
export const updateBimList = () => http.get('/bimSite/updateBimList')

//已绑定工点列表
export const getCodeDictDetails = id => http.get('/bimSite/binding/list?siteUuId=' + id)

//绑定bim
export const bindingBySite = params => http.post(`/bimSite/bindingBySite`, params);


// 删除bim
// export const bimDelete = id => http.get(`/bimSite/delete/${id}`);
export const bimDelete = id => http.get(`/bimSite/delete?id=` + id);

//未绑定bim列表
export const unBindingList = name => http.get('/bimSite/unBinding/list?modelName=' + name)

// 解绑bim
export const unBindingBySite = id => http.get(`/bimSite/unBindingBySite?id=` + id);