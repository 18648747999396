import { render, staticRenderFns } from "./dangerousBig.vue?vue&type=template&id=0e032ecf&scoped=true&"
import script from "./dangerousBig.vue?vue&type=script&lang=js&"
export * from "./dangerousBig.vue?vue&type=script&lang=js&"
import style0 from "./dangerousBig.vue?vue&type=style&index=0&id=0e032ecf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e032ecf",
  null
  
)

export default component.exports