<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" :title="title">
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="100px"
      class="demo-model"
    >
      <el-form-item label="预案名称" prop="planId">
        <el-select v-model="model.planId" placeholder="选择现在已有的预案名称">
          <el-option
            v-for="item of EmergencyData"
            :key="item.id"
            :label="item.planName"
            :value="item.planId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="应急处置卡" prop="importFile">
        <el-upload
          action="action"
          :http-request="uploadFile"
          :file-list="fileList"
          :before-upload="beforeUpload"
          :limit="1"
          accept=".xls,.xlsx"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传xls/xlsx文件
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
    <!--  <el-dialog
      title="正在进行上传"
      :visible.sync="isFinally"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <el-progress
        :text-inside="true"
        :stroke-width="20"
        :percentage="percent"
      ></el-progress>
      <p>正在上传...</p>
    </el-dialog> -->
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { Emergencyimport, Emergencyall } from "@/api/emergency";
// eslint-disable-next-line no-unused-vars
import { upload, uploadThumb } from "@/api/upload";
export default {
  components: {
    systemModal,
  },
  props: {
    /*   EmergencyData: {
      type: Array,
      default: () => [],
    }, */
  },
  data() {
    return {
      title: "导入",
      model: {
        planId: "",
        importFile: null,
      },
      loading: false,
      rules: {
        planId: [
          {
            required: true,
            message: "请选择预案",
            trigger: ["blur", "change"],
          },
        ],
        importFile: [
          {
            required: true,
            message: "请上传文件",
            trigger: ["blur", "change"],
          },
        ],
      },
      fileList: [],
      EmergencyData: [],
      // isFinally: false,
      percent: 0,
    };
  },

  mounted() {},
  methods: {
    handleImport() {
      this.resetModel();
      this.title = "导入";
      this.handleEdit();
      Emergencyall().then((res) => {
        console.log(res);
        this.EmergencyData = res.data;
      });
    },
    // 上传前调用
    beforeUpload(file) {
      console.log(file);
      const tempArr = file.name.split(".");
      const isValid = tempArr.includes("xls") || tempArr.includes("xlsx");
      if (!isValid) {
        this.$message.error("只支持xls、xlsx格式文件！");
        return false;
      } else {
        this.isFinally = true;
      }
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    // 自定义上传方法
    async uploadFile(args) {
      console.log(args);
      this.model.importFile = args.file;
      // this.isFinally = true;
      this.percent = 0;
      const { file } = args;
      let res = {};
      let fn = upload;
      res = await fn(file, (e) => {
        console.log(e);
        this.percent = ((e.loaded / e.total) * 100) | 0;
        return args.onProgress({ percent: this.percent });
        // return this.percent;
      }).catch((err) => {
        res.message = err;
        console.error(err);
      });
      if (res.code === 0) {
        // this.isFinally = false;
        this.$message.success("上传成功");
      } else {
        this.message.error("上传失败");
        this.$emit("error", res.message);
        // this.isFinally = false;
      }
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let plan = this.EmergencyData.find(
            (item) => item.planId == this.model.planId
          );
          this.model.planName = plan.planName;
          const data = new FormData();
          Object.keys(this.model).forEach((key) => {
            if (this.model[key]) {
              data.append(key, this.model[key]);
            }
          });
          Emergencyimport(data)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("操作成功！");
                this.loading = false;
                this.resetModel();
                this.$emit("importSub");
                this.$refs.formModal.handleClose();
              } else {
                this.$message.error(res.messsage);
                this.loading = false;
              }
            })
            .catch((err) => {
              this.loading = false;
              this.$message.error(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
    resetModel() {
      this.model = {
        planName: "",
        planId: "",
        importFile: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
