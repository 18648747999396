<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
            <el-cascader
              v-model="queryModal.linsiteIds"
              clearable
              :options="lineSiteData"
              :props="props"
              :show-all-levels="false"
              placeholder="线路工点"
              collapse-tags
              @change="lineChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="">
            <el-select
              clearable
              v-model="queryModal.riskTypeCode"
              placeholder="风险类别"
              @change="handleSonCate"
            >
              <el-option
                v-for="item in riskCategory"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="queryModal.riskSubTypeCode"
              placeholder="风险子类"
            >
              <el-option
                v-for="item in riskSubTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              clearable
              v-model="queryModal.riskName"
              placeholder="风险名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="queryModal.riskLevelCode"
              placeholder="风险控制等级"
            >
              <el-option
                v-for="item in riskControl"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData">查询</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div style="margin-bottom:10px">
      <el-radio-group v-model="radio" @change="radioChange">
        <el-radio-button :label="1">待启动</el-radio-button>
        <el-radio-button :label="2">已启动</el-radio-button>
      </el-radio-group>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="branchName"
          label="分部/标段"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="工点"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskTypeName"
          label="风险类别"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskSubTypeName"
          label="风险子类"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskName"
          label="风险名称"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="mileageRange"
          label="风险里程"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskDescribe"
          show-overflow-tooltip
          label="风险描述"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskLevelName"
          show-overflow-tooltip
          label="风险控制等级"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskYear"
          label="年度/季度"
          show-overflow-tooltip
          width=""
        >
          <template slot-scope="scope">
            {{ scope.row.riskYear + "年度第" + scope.row.riskQuarter + "季度" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="processStatusName"
          label="状态"
          show-overflow-tooltip
          width=""
        >
          <template slot-scope="scope">
            <el-button
              v-if="
                scope.row.processStatus == 2 ||
                  scope.row.processStatus == 3 ||
                  scope.row.processStatus == 5||
                  scope.row.processStatus == 9
              "
              type="text"
              @click="auditclick(scope.row)"
              >{{ scope.row.processStatusName }}</el-button
            >
            <el-button
              v-else-if="
                scope.row.processStatus == 4 ||
                  scope.row.processStatus == 6 ||
                  scope.row.processStatus == 8
              "
              class="redClass"
              type="text"
              @click="auditclick(scope.row)"
              >{{ scope.row.processStatusName }}</el-button
            >
            <span v-else>{{ scope.row.processStatusName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150" fixed="right">
          <template class="operateClass" slot-scope="scope">
            <el-button
              type="text"
              v-if="radio == 1"
              :disabled="getBtnState(scope.row)"
              style="margin-right: 5px;"
              @click="handleView(scope.row, 'edit')"
              >申请</el-button
            >
            <!-- <span
              v-if="
                (radio == 1 && scope.row.processStatus == 2) ||
                  (radio == 1 && scope.row.processStatus == 3) ||
                  (radio == 1 && scope.row.processStatus == 5) ||
                  (radio == 1 && scope.row.processStatus == 7) ||
                  radio == 2
              "
            >
            </span> -->
            <span v-if="scope.row.processStatus != 1">
              <el-button
                type="text"
                v-if="
                  (radio == 1 && userUnitType == 'monitor') ||
                    (radio == 1 && userUnitType == 'build') ||
                    (radio == 1 && userUnitType == 'constManagDept')
                "
                style="margin-right: 5px;"
                @click="handleView(scope.row, 'view')"
                >查看</el-button
              >
            </span>
            <span v-if="radio == 1 && scope.row.processStatus != 1">
              <el-button
                v-if="
                  userUnitType == 'monitor' ||
                    userUnitType == 'build' ||
                    userUnitType == 'constManagDept'
                "
                type="text"
                :disabled="getAuditState(scope.row)"
                style="margin-right: 5px;"
                @click="openAuditDialog(scope.row, 'edit')"
                >审核</el-button
              >
            </span>
            <span v-if="radio == 2">
              <el-button
                type="text"
                style="margin-right: 5px;"
                @click="handleView(scope.row, 'view')"
                >查看</el-button
              >
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      width="70%"
      title="风险启动"
      class="editDialog details-dialog"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      center
      top="10vh"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <BaseInfo :risk-deaf-uuid="riskDeafUuid"></BaseInfo>
      <StartRiskForm
        @closeDialog="handlerCloseDia"
        :type="disclosureType"
        @showStartRecord="showStartRecord"
        :rowData="currentRowInfo"
        :isShowTable="isShowTable"
        :riskControlLevel="currentRowInfo.riskLevelCode"
      ></StartRiskForm>
    </el-dialog>
    <!-- 风险启动审核记录弹窗 -->
    <Audit
      :dialogRecord="dialogRecord"
      @closeStartRecord="closeStartRecord"
      @showFileList="showFileList"
    ></Audit>
    <Accessory
      :fileDialog="fileDialog"
      :fileData="fileData"
      @closeAccessory="closeAccessory"
    ></Accessory>
    <el-dialog
      width="50%"
      class="editDialog details-dialog"
      :visible.sync="auditVisible"
      v-if="auditVisible"
      :close-on-click-modal="false"
      :before-close="handleAuditClose"
    >
      <AuditDialog
        :active="AuditActive"
        :stepSx="stepSx"
        :controlLevel="currentCtolLevel"
        :rowData="currentInfo"
        @showFileList="ShowFileDialog"
        @submitClose="CloseAuditDialog"
      ></AuditDialog>
    </el-dialog>
  </div>
</template>

<script>
import { riskStartpage } from "@/api/riskManage";
import { getDictData } from "@/api/dict";
import BaseInfo from "./components/BaseInfo";
import StartRiskForm from "./components/StartRiskForm";
import Audit from "./components/AuditRecordDialog";
import Accessory from "./components/AccessoryDialog";
import AuditDialog from "./components/AuditDialog";
export default {
  components: {
    BaseInfo,
    StartRiskForm,
    Audit,
    Accessory,
    AuditDialog
  },
  data() {
    return {
      queryModal: {
        linsiteIds: [],
        riskTypeCode: "",
        riskSubTypeCode: "",
        riskName: "",
        riskLevelCode: "",
        processStatus: -9 //过程状态 9已启动  -9未启动
      },
      lineSiteData: [],
      loading: false,
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      riskCategory: [], //风险类别
      riskControl: [], //风险控制等级
      riskSubTypeList: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      radio: 1,
      dialogVisible: false,
      activeName: "风险交底",
      disclosureType: "", //风险交底类型
      tableData: [],
      currentRowInfo: {},
      dialogRecord: false,
      fileDialog: false,
      fileData: {},
      riskDeafUuid: "",
      userUnitType: "", //当前用户单位类型
      userName: "", //当前用户名称
      auditVisible: "", //审核弹窗
      AuditActive: 0, //步骤条进度
      currentInfo: {}, //当前行数据
      currentCtolLevel: "", //当前行风险控制等级
      currentRiskName: "", //当前行风险名称
      // 启动过程状态 0未初次交底 1已初次交底/未启动(未启动可申请) 2提交申请 3监理审核通过 4监理审核驳回 5建管部审核通过 6建管部审核驳回 7建设单位审核通过 8建管部审核驳回 9启动成功 10未启动不可申请(是否可申请操作，依据预警开工时间进行判断，在预计开工的前十天内才可以进行启动操作)
      stateList: [
        { label: "未启动", value: "1" },
        { label: "未启动", value: "10" },
        { label: "待监理审核", value: "2" },
        { label: "待建管部审核", value: "3" },
        { label: "监理驳回", value: "4" },
        { label: "待建设公司审核", value: "5" },
        { label: "建管部驳回", value: "6" },
        { label: "建设单位审核通过", value: "7" },
        { label: "建设公司驳回", value: "8" },
        { label: "启动成功", value: "9" }
      ],
      isShowTable: true,
      departmentName: "",
      stepSx: 1
    };
  },
  created() {
    this.getLineTree();
    this.getDict();
    this.getData();
  },
  mounted() {
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      this.userUnitType = user.companyCategory;
      console.log(this.userUnitType, "userUnitType");
      this.userName = user.userName;
      this.departmentName = user.departmentName;
    }
  },
  methods: {
    getBtnState(row) {
      if (
        row.processStatus == 4 ||
        row.processStatus == 6 ||
        row.processStatus == 8
      ) {
        if (this.userUnitType == "construct") {
          return false;
        } else {
          return true;
        }
      }
      if (
        row.processStatus == 2 ||
        row.processStatus == 3 ||
        row.processStatus == 5 ||
        row.processStatus == 10
      ) {
        return true;
      }
      if (
        (row.processStatus == 1 &&
          row.riskLevelCode == "const_manage_dept_level") ||
        (row.processStatus == 1 && row.riskLevelCode == "project_depart_level")
      ) {
        if (this.userUnitType == "construct") {
          return false;
        } else {
          return true;
        }
      }
    },
    getAuditState(row) {
      const _this = this;
      if (row.processStatus == 2 && this.userUnitType == "monitor") {
        return false;
      } else if (row.processStatus == 3 && this.userUnitType == "build") {
        if (_this.departmentName.includes("建设管理部")) {
          return false;
        } else if (_this.departmentName.includes("建设公司")) {
          return false;
        } else {
          return true;
        }
      } else if (row.processStatus == 5 && this.userUnitType == "build") {
        return false;
      } else {
        return true;
      }
    },
    async getLineTree() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.lineSiteData = JSON.parse(JSON.stringify(lineSiteData));
      }
    },
    // riskType
    getDict() {
      getDictData("riskType").then(res => {
        if (res.code == 0) {
          this.riskCategory = res.data;
        }
      });
      getDictData("riskControlLevel").then(res => {
        if (res.code == 0) {
          this.riskControl = res.data;
        }
      });
    },
    handleSonCate(val) {
      this.queryModal.riskSubTypeCode = "";
      this.riskSubTypeList = [];
      if (val.length > 0) {
        getDictData(val).then(res => {
          if (res.code == 0) {
            this.riskSubTypeList = res.data;
          }
        });
      }
    },
    // change切换
    radioChange(val) {
      if (val == 1) {
        //未响应
        this.queryModal.processStatus = -9;
      } else if (val == 2) {
        //已响应
        this.queryModal.processStatus = 9;
      }
      this.getData();
      console.log("val", val);
    },
    getData() {
      // dd
      const that = this;
      this.loading = true;
      let params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.queryModal
      };
      riskStartpage(params).then(res => {
        if (res.code == 0) {
          this.loading = false;
          that.stateList.forEach(el => {
            res.data.list.forEach(v => {
              if (el.value == v.processStatus) {
                v.processStatusName = el.label;
              }
            });
          });
          that.tableData = res.data.list;
          this.page.total = res.data.total;
        }
      });
    },
    auditclick(row) {
      this.getActive(row.processStatus);
      this.AuditActive = 2;
      this.currentInfo = { ...row };
      this.currentCtolLevel = row.riskLevelCode;
      this.currentRiskName = row.riskName;
      this.auditVisible = true;
    },
    lineChange(record) {
      console.log(record);
      this.queryModal.siteUuidList = [];
      for (let item of record) {
        this.queryModal.siteUuidList.push(item[item.length - 1]);
      }
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getData();
    },
    handleView(row, type) {
      console.log(row, "row");
      const that = this;
      this.currentRowInfo = Object.assign({}, row);
      if ((type == "edit" && row.processStatus == 1) || type == "view") {
        that.isShowTable = true;
      } else {
        that.isShowTable = false;
      }
      // if (type == "view") {
      //   if (
      //     row.riskControlLevel == "headquarters_level" ||
      //     row.riskControlLevel == "group_level"
      //   ) {
      //     that.isShowTable = true;
      //   } else {
      //     that.isShowTable = false;
      //   }
      // }
      this.disclosureType = type;
      this.riskDeafUuid = row.riskDeafUuid;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.activeName = "风险交底";
    },
    handleClick() {
      // d
    },
    handlerCloseDia() {
      this.dialogVisible = false;
      this.getData();
    },
    showStartRecord(obj) {
      // this.dialogRecord = true;
      this.auditclick(obj);
    },
    closeStartRecord() {
      this.dialogRecord = false;
    },
    // 附件列表弹窗
    showFileList(obj) {
      this.fileDialog = true;
      this.fileData = obj.riskWarnFileList;
    },
    // 附件列表弹窗
    ShowFileDialog(obj) {
      this.fileDialog = true;
      this.fileData = obj;
    },
    closeAccessory() {
      this.fileDialog = false;
    },
    // 审核弹窗
    openAuditDialog(row, type) {
      this.getActive(row.processStatus);
      console.log(row, "row");
      this.auditType = type;
      this.AuditActive = 1;
      // if (row.riskLevelCode == "project_depart_level") {
      //   this.stepSx = 1;
      // } else {
      // }
      this.currentInfo = { ...row };
      this.currentCtolLevel = row.riskLevelCode;
      this.currentRiskName = row.riskName;
      this.auditVisible = true;
    },
    getActive(status) {
      switch (status) {
        case 2:
          this.stepSx = 1;
          break;
        case 3:
          this.stepSx = 2;
          break;
        case 4:
          this.stepSx = 2;
          break;
        case 5:
          this.stepSx = 3;
          break;
        case 6:
          this.stepSx = 3;
          break;
        case 7:
          this.stepSx = 4;
          break;
        case 8:
          this.stepSx = 4;
          break;
        case 9:
          this.stepSx = 4;
          break;
        default:
          break;
      }
    },
    handleAuditClose() {
      // SSSSSSSSS
      this.auditVisible = false;
    },
    CloseAuditDialog() {
      this.auditVisible = false;
      this.getData();
    }
  }
};
</script>

<style lang="scss" scoped>
.riskDialogTitle {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
}
::v-deep .details-dialog {
  .el-dialog__header {
    .el-dialog__title {
      position: static;
    }
  }
  .el-dialog__body {
    max-height: 680px;
    overflow: auto;
  }
}
.redClass {
  color: rgb(245, 108, 108);
}
</style>
