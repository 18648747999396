<template>
  <!--三防工作统计表建设公司质安部  -->
  <div>
    <div style="margin-bottom:20px">
      <el-radio-group
        size="mini"
        v-model="degree"
        v-for="item in times"
        :key="item.time"
        @change="degreeChange"
      >
        <el-radio-button :label="item.label"></el-radio-button>
      </el-radio-group>
    </div>
    <div class="content">
      <div class="cpntent_left">
        <el-form :model="formData" class="demo-form-inline">
          <el-row>
            <el-form-item>
              <el-select
                v-model="formData.emergency"
                placeholder="数据汇总"
                clearable
                @change="emergencyChange"
              >
                <el-option
                  v-for="item of leveloptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <div class="content-right">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="三防应急响应类型:">
            <el-input
              v-model="formInline.responseType"
              readonly
            ></el-input> </el-form-item
          ><el-form-item label="三防应急响应级别:">
            <el-input v-model="formInline.responseLevel" readonly></el-input>
          </el-form-item>
          <span>
            <el-button @click="derive()" type="primary" size="medium">
              导出
            </el-button></span
          >
        </el-form>
      </div>
    </div>

    <el-table
      ref="tableData"
      :data="tableData"
      id="tableData"
      border
      v-loading="tableLoading"
      :row-style="{ height: '30px' }"
      style="width: 100%"
      height="430"
    >
      <el-table-column
        label="单位"
        prop="supervisionName"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        label="派出工作组(个)从应急响应启动到本次报送时间节点,派出工作组的累计数量"
        align="center"
        prop="workGroup"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="position"
        label="检查场所部位(处)从应急响应启动到本次报送时间节点,检查场所部位的累计数量"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="hiddenDanger"
        label="新排查出三防风险隐患(处)上—次报送时间节点到本次报送时间节点，新排查出的三防风险隐患数量"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="info"
        label="发送预警或防御信息(条)地铁集团不需要填该项"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="worker"
        label="预制(出动)抢险人员(人)在本次报送时间节点，能立即调动的抢险人员"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="team"
        label="预制(出动)抢险队伍(支)在本次报送时间节点，能立即调动的抢险队伍数量"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        label="预置(出动)抢险设备(台)指在本次报送时间节点，能立即调动设备数量"
        align="center"
      >
        <el-table-column
          prop="deviceTotal"
          label="合计"
          align="center"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="vehicle"
          label="车辆能立即调动的抢险汽车、吊车、铲车、履带车等"
          align="center"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="boat"
          label="舟艇能立即调动的舟船、划艇等水上运输工具数量"
          align="center"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="waterPump"
          label="抽水机能立即调动的龙吸水、自吸泵、排污泵、潜水泵、动力站水泵等抽排水设备数量"
          align="center"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="other"
          label="其他能立即调动的、未统计在车辆、舟艇、抽水机范围内的三防抢险设备数量"
          align="center"
          width="120"
        >
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="其他应急措施落实及受灾害影响情况指从应急响应启动到本次报送时间节点，集团公司各单位应急措施落实及受灾受影响累计情况"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="deadline"
        label="截至时间"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="fillTime"
        label="填报时间"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="fillUserName"
        label="填报人员"
        align="center"
        width="120"
      >
      </el-table-column>

      <el-table-column label="操作" align="center" width="160">
        <!-- v-show="sureSubData == '1' ? true : false" -->
        <template slot-scope="scope">
          <el-button
            @click="check(scope.row, tableData)"
            type="text"
            size="small"
          >
            查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="checkDialog"
      width="80%"
      :before-close="checkClose"
      center
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <checkPage
        @checkClose="checkClose"
        :checkId="checkId"
        :responseId="responseId"
        :linetes="linetes"
        :qualityResponseId="qualityResponseId"
      ></checkPage>
    </el-dialog>
  </div>
</template>

<script>
import { getSelection, getRes } from "@/api/response";
import { QualityAllData, summaryDataList } from "@/api/currentResponse";
import checkPage from "./checkPage.vue";

export default {
  props: {
    qualityResponseId: {
      type: Number,
      default: () => null,
    },
    sureSubData: {
      type: String,
      default: "1",
    },
    linetes: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    checkPage,
  },
  data() {
    return {
      times: [],
      degree: "第1次",
      tableData: [
        {
          reportcishu: "三号线",
        },
      ],
      tableTotalData: [],
      tableLoading: false,
      checkDialog: false,
      formData: {
        emergency: "",
      },
      formInline: {
        responseType: "",
        responseLevel: "",
      },
      leveloptions: [],
      checkId: "", //当前行的id
      responseId: "", //事件id
      // buildDepartmentResponseId: "",
    };
  },
  created() {},
  watch: {
    qualityResponseId: {
      handler(val) {
        if (val) {
          this.getGenre(val);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    console.log(this.linetes, "质安部查看的线路工点");
    this.getData();
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.tableLoading = true;
      let params = {
        responseId: this.qualityResponseId,
      };
      QualityAllData(params).then((res) => {
        this.times = [];
        if (res.code == 0) {
          this.tableLoading = false;
          // this.tableForm.tableData = res.data;
          res.data.forEach((element) => {
            this.times.push({
              time: element.times,
              label: "第" + element.times + "次",
            });
          });
          this.tableData = res.data[0].statisticFormDtoList;
          this.tableTotalData = res.data;
        }
      });
    },
    getData() {
      let params = {};
      getSelection(params).then((res) => {
        if (res.code == 0 && res.data && res.data.length != 0) {
          this.leveloptions = [];
          let id1 = res.data.findIndex((item) => {
            if (item.label == "汇总") {
              return true;
            }
          });
          res.data.splice(id1, 1);
          res.data.forEach((element) => {
            this.leveloptions.push({
              label: element.label,
              value: element.value,
            });
          });
        }
      });
    },
    degreeChange(val) {
      var arr = val.replace(/\D/g, "");
      this.tableData = [];
      this.tableTotalData.forEach((item) => {
        if (arr == item.times) {
          this.tableData = item.statisticFormDtoList;
        }
      });
    },
    getGenre(val) {
      let id = val;
      getRes(id).then((res) => {
        if (res.code == 0) {
          this.formInline.responseLevel = res.data.currentLevel.label;
          this.formInline.responseType = res.data.type.label;
        }
      });
    },
    emergencyChange(val) {
      this.tableLoading = true;
      let params = {
        manageCurrentId: val,
      };
      QualityAllData(params).then((res) => {
        if (res.code == 0) {
          this.tableLoading = false;
          this.tableData = res.data[0].statisticFormDtoList;
        }
      });
    },
    derive() {
      // this.$message.warning("导出");
      let parms = {
        times: this.degree.replace(/\D/g, ""),
        responseTypeAndLevel:
          this.formInline.responseType + this.formInline.responseLevel,
        responseId: this.qualityResponseId,
      };

      summaryDataList(parms).then((res) => {
        console.log(res);
        const aLink = document.createElement("a");
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        aLink.href = URL.createObjectURL(blob);
        aLink.download = "广州市交通运输系统三防工作统计表.xlsx";
        aLink.click();
        document.body.appendChild(aLink);
      });
    },
    check(row, tabdata) {
      console.log(row, tabdata, "查看详情");
      this.checkId = row.id;
      this.responseId = row.responseId;
      this.checkDialog = true;
    },
    checkClose() {
      this.checkDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-table::before {
  height: 0;
}
::v-deep .el-table {
  height: 400px;
  overflow-y: auto;
}
.content {
  display: flex;
  justify-content: space-between;
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: #fff !important;
  border-radius: 7px;
}
/*定义滑块颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}

//去掉固定列下方的横线
::v-deep .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: transparent;
}
//去掉固定列阴影
::v-deep .el-table__fixed-right {
  // box-shadow: none;
  height: 100% !important;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}
</style>
