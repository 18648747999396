<template>
  <systemModal ref="formModal" :title="title" :showFooter="false" width="40%">
    <div class="timeline-wrapper" v-loading="loading">
      <el-timeline>
        <el-timeline-item v-for="item of historyData" :key="item.id" :timestamp="item.created" placement="top">
          <el-card>
            <h4>{{ item.eventTag | HanldeName }}</h4>
            <p>{{ item.owner.name }} 提交于 {{ item.created }}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { getHistoryById } from "@/api/stand";
let handleType = [
  {label: "提交计划", value: 0},
  {label: "计划审批通过", value: 1},
  {label: "计划审批驳回", value: 2},
  {label: "开始前提醒", value: 3},
  {label: "开始演练", value: 4},
  {label: "提交报告", value: 5},
  {label: "报告审核通过，完成演练", value: 6},
  {label: "报告审核驳回", value: 7},
  {label: "上传附件", value: 8},
]
export default {
  components: {
    systemModal
  },
  data() {
    return {
      title: "历史操作",
      historyData: [],
      loading: false
    };
  },
  filters: {
    HanldeName(val) {
      const temp = handleType.find(item => item.value == val);
      if (temp) {
        return temp.label;
      }
    }
  },
  mounted() {},
  methods: {
    handleHistory(record) {
      this.historyData = [];
      this.getHistoryById(record.id);
      this.$refs.formModal.handleOpen();
    },
    getHistoryById(id) {
      this.loading = true;
      getHistoryById({ planId: id }).then(res => {
        if (res.code == 0) {
          this.historyData = Object.assign([], res.data);
          this.loading = false;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}
::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
.timeline-wrapper {
  min-height: 300px;
  padding: 20px;
}
</style>
