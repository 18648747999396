<template>
  <systemModal ref="formModal" :title="title" :IsAudit="true" @modalAgree="handleExamint(1)"
    @modalDisagree="handleExamint(2)">
    <el-form v-loading="loading" :model="model" ref="modelForm" label-width="120px" class="demo-model">
      <el-form-item label="线路工点" prop="lineSite">
        {{ model.lineName }}-{{ model.siteName }}
      </el-form-item>
      <el-form-item label="演练内容" prop="label">
        {{ model.label }}
      </el-form-item>
      <el-form-item label="演练类型" prop="category">
        {{ model.category | categoryName }}
      </el-form-item>
      <el-form-item label="演练时间" prop="executeTime">
        {{ model.executeTime }}
      </el-form-item>
      <el-form-item label="演练方案">
        <template v-if="planList.length > 0">
          <el-button type="text" v-for="item of planList" :key="item.id" @click="handleDownloadFile(item.link)">{{ item.attrName }}</el-button>
        </template>
        <template v-else>无</template>
      </el-form-item>
      <el-form-item label="演练脚本">
        <template v-if="scriptList.length > 0">
          <el-button type="text" v-for="item of scriptList" :key="item.id" @click="handleDownloadFile(item.link)">{{ item.attrName }}</el-button>
        </template>
        <template v-else>无</template>
      </el-form-item>
      <el-form-item label="签到表">
        <template v-if="signFormList.length > 0">
          <el-image v-for="item of signFormList" :key="item.id" style="width: 100px; height: 100px" :src="item.link"
            :preview-src-list="[item.link]">
          </el-image>
        </template>
        <template v-else>无</template>
      </el-form-item>
      <el-form-item label="过程图片/视频">
        <template v-if="imgVideoList.length > 0">
          <ul>
            <li v-for="item of imgVideoList" :key="item.id">
              <el-image v-if="!isVideo()" style="width: 100px; height: 100px" :src="item.link"
                :preview-src-list="[item.link]">
              </el-image>
              <video
                v-else
                class="video-uploader-preview"
                :src="item.link"
                controls
              ></video>
            </li>
          </ul>
        </template>
        <template v-else>无</template>
      </el-form-item>
      <el-form-item label="演练总结">
        <template v-if="summaryList.length > 0">
          <el-button type="text" v-for="item of summaryList" :key="item.id" @click="handleDownloadFile(item.link)">{{ item.attrName }}</el-button>
        </template>
        <template v-else>无</template>
      </el-form-item>
      <el-form-item label="评估表">
        <template v-if="evaluateList.length > 0">
          <el-button type="text" v-for="item of evaluateList" :key="item.id" @click="handleDownloadFile(item.link)">{{ item.attrName }}</el-button>
        </template>
        <template v-else>无</template>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          placeholder="请输入内容"
          v-model="remark"
        >
        </el-input>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { approveReport, noApproveReport } from "@/api/stand";
let that;
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "应急演练报告审核",
      model: {},
      planList: [],
      scriptList: [],
      signFormList: [],
      imgVideoList: [],
      summaryList: [],
      evaluateList: [],
      dict: {
        category: []
      },
      loading: false,
      remark: ""
    };
  },
  props: {
    lineSiteData: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {},
  beforeCreate: function () {
    that = this;
  },
  mounted() {
    this.getDictData("应急培训类型", this.dict.category);
  },
  filters: {
    categoryName(val) {
      if (that.dict.category.length > 0) {
        let temp = that.dict.category.find(item => item.id == val);
        if (temp) {
          return temp.label;
        }
      } else {
        return "";
      }
    }
  },
  methods: {
    getDictData(label, target) {
      queryDictByLabel({ label }).then(res => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then(res => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          })
        }
      });
    },
    handleReportExamine(record) {
      this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
      // this.$set(this.model, "lineSite", [record.lineId, record.siteId]);
      if (record.attachs) {
        for (let item of record.attachs) {
          switch (item.attrType) {
            case 0:
              this.planList.push(item);
              break;
            case 1:
              this.scriptList.push(item);
              break;
            case 2:
              this.signFormList.push(item);
              break;
            case 3:
              this.imgVideoList.push(item);
              break;
            case 4:
              this.summaryList.push(item);
              break;
            case 5:
              this.evaluateList.push(item);
              break;
          }
        }
      }
      this.$refs.formModal.handleOpen();
    },
    handleDownloadFile(url) {
      const link = document.createElement("a");
      link.href = url;
      link.click();
    },
    handleExamint(type) {
      if (type == 1) {
        // 通过
        this.loading = true;
        approveReport({ id: this.model.id }).then(res => {
          if (res.code == 0) {
            this.loading = false;
            this.$message.success("操作成功！");
            this.$emit("examineOver");
            this.$refs.formModal.handleClose();
          }
        })
      } else {
        // 驳回
        if (!this.remark) {
          this.$message.error("请输入备注");
          return false;
        }
        this.loading = true;
        const params = {
          id: this.model.id,
          data: {
            opinion: this.remark
          }
        };
        noApproveReport(params).then(res => {
          if (res.code == 0) {
            this.loading = false;
            this.$message.success("操作成功！");
            this.$emit("examineOver");
            this.$refs.formModal.handleClose();
          }
        })
      }
    },
    isVideo(url) {
      const reg = /.(avi|wmv|mpg|mpeg|mov|rm|swf|mp4)$/i;
      return reg.test(url);
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
