<template>
  <div class="material-container h-full">
    <el-form
      ref="queryModal"
      :inline="true"
      :model="queryModal"
      class="demo-form-inline"
    >
      <el-form-item prop="">
        <el-select
          v-model="queryModal.type"
          clearable
          placeholder="请选择线路-工点"
        >
          <el-option
            v-for="item of monitorCategorylist"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="">
        <el-select
          v-model="queryModal.monitorCategory"
          clearable
          placeholder="请选择设备类型"
        >
          <el-option
            v-for="item of monitorCategorylist"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="">
        <el-date-picker
          v-model="queryModal.times"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </el-form-item>
    </el-form>

    <div class="tablese">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        max-height="500"
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="60"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="id"
          show-overflow-tooltip
          label="线路-工点"
        >
          <template slot-scope="scope">
            {{ scope.row.lineName }} - {{ scope.row.siteName }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="id"
          show-overflow-tooltip
          label="设备类型"
        >
          <template slot-scope="scope">
            {{ scope.row.rainfallFlag ? "雨量监测仪" : "" }}
            {{ scope.row.temperatureFlag ? "温度计" : "" }}
            {{ scope.row.windSpeedFlag ? "风力监测仪" : "" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="responseName"
          show-overflow-tooltip
          label="接入时间"
        >
          <template slot-scope="scope">
            {{ scope.row.created }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="monitorState"
          show-overflow-tooltip
          label="设备状态"
        >
        </el-table-column>

      </el-table>
    </div>

    <div class="relative text-center mt-4">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :total="page.count"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { monitorStatistics } from "@/api/map";
export default {
  props: {
    type: Number,
  },
  components: {},
  data() {
    return {
      loading: false,
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      queryModal: {
        monitorState: 10086,
        monitorCategory: "",
        times: [],
      },
      // 应急响应升/降级
      upgradeTitle: "升/降级",
      monitorCategorylist: [
        {
          id: 0,
          label: "风力监测仪",
        },
        {
          id: 1,
          label: "温度计",
        },
        {
          id: 2,
          label: "雨量监测仪",
        },
      ],
      monitorStatelist: [
        {
          id: 0,
          label: "在线",
        },
        {
          id: 1,
          label: "离线",
        },
        {
          id: 2,
          label: "故障",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    //参数筛选
    removeProperty(obj) {
      Object.keys(obj).forEach((e) => {
        if (
          obj[e] === "" ||
          obj[e] === undefined ||
          obj[e] === null ||
          obj[e] === "null"
        ) {
          delete obj[e];
        }
      });
      return obj;
    },
    //初始化查询
    initdata() {
      if (this.type == 10086) {
        this.queryModal.monitorState = "";
      } else {
        this.queryModal.monitorState = this.type;
      }

      this.handleSearch();
    },
    //查询
    handleSearch() {
      this.page.pageNo = 1;
      this.loadData();
    },
    //分页查询列表
    async loadData() {
      this.loading = true;
      let params = {
        page: this.page.pageNo,
        size: this.page.pageSize,
        startTime: this.queryModal.times[0] ? this.queryModal.times[0] : "",
        endTime: this.queryModal.times[1] ? this.queryModal.times[1] : "",
        monitorCategory: this.queryModal.monitorCategory,
        monitorState: this.queryModal.monitorState,
      };
      params = this.removeProperty(params);
      let res = await monitorStatistics(params);
      if (res.code == 0) {
        console.log(res);
        this.loading = false;
        this.tableData = res.data.records;
        this.tableData.forEach((e) => {
          console.log(e.status);
          if (e.relegationLevel) {
            this.leveloptions.forEach((el) => {
              let dd = parseInt(el.id);
              let nu = parseInt(e.relegationLevel);
              if (dd == nu) {
                e.relegationLevelLabel = el.label;
              }
            });
          }
        });
        this.page.count = res.data.count;
      } else {
        this.loading = false;
      }
    },
    //发布三防响应
    issue() {
      this.issueDialog = true;
    },
    issueClose(msg) {
      this.issueDialog = false;
      if (msg == true) {
        this.handleSearch();
      }
    },
    // 升级/降级
    upgrade(data) {
      this.temList = data;
      this.upgradeDialog = true;
    },
    upgradeClose(msg) {
      console.log(msg);
      this.upgradeDialog = false;
      if (msg == true) {
        this.handleSearch();
      }
    },
    // 结束
    end(data) {
      this.temList = data;
      this.endDialog = true;
    },
    endClose(msg) {
      this.endDialog = false;
      if (msg == true) {
        this.handleSearch();
      }
    },
    // 查看详情
    detailHandle(data) {
      this.temList = data;
      this.$refs.modalDetail.handleOpen();
      this.detailDialog = true;
    },
    detailClose(msg) {
      this.detailDialog = false;
      if (msg == true) {
        this.handleSearch();
      }
    },

    historyClose() {
      this.historyDialog = false;
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../style/elTable.scss";
::v-deep .el-tabs__item {
  color: #fff !important;
}
::v-deep .el-tabs__item.is-active {
  color: #409eff !important;
}

::v-deep .el-input--prefix .el-input__inner {
  color: #fff;
  background-color: #18467f;
  border: 0px solid #dcdfe6;
}
::v-deep .el-input__inner {
  color: #fff;
  background-color: #18467f;
  border: 0px solid #dcdfe6;
}
::v-deep .el-range-input {
  color: #fff;
  background-color: #18467f;
  border: 0px solid #dcdfe6;
}
</style>
