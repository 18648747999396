<template>
  <el-dialog
    width="40%"
    title="附件列表"
    class="fileDialogCs"
    append-to-body
    :visible.sync="fileDialog"
    v-if="fileDialog"
    center
    style="z-index:99999"
    :close-on-click-modal="true"
    :before-close="handlefileClose"
  >
    <el-table :data="list" style="width: 100%">
      <el-table-column
        align="center"
        prop="fileName"
        show-overflow-tooltip
        label="附件名称"
      ></el-table-column>
      <el-table-column
        align="center"
        show-overflow-tooltip
        label="操作"
        width="90"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="handleFileView(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  props: {
    // ss
    fileDialog: {
      type: Boolean,
      default: false
    },
    fileData: {
      default: () => {}
    }
  },
  data() {
    return {
      list: [
        {
          fileName: "07f123d1a23d0bb1ce3ac7944b3665c91412653368402789991.txt",
          fileUrl:
            "http://47.95.238.93:9300/gzdt-upload/attachments/2022/12/07f123d1a23d0bb1ce3ac7944b3665c91412653368402789991.txt"
        }
      ]
    };
  },
  watch: {
    fileDialog() {
      this.list = this.fileData.fileList;
    }
  },
  mounted() {
    this.list = this.fileData.fileList;
  },
  methods: {
    // dd
    handlefileClose() {
      this.$emit("closeAccessory");
    },
    handleFileView(row) {
      let obj = Object.assign({}, row);
      let url = obj.fileUrl.split(",")[0];
      const link = document.createElement("a");
      link.href = url;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    }
  }
};
</script>

<style></style>
