<template>
  <!--三防工作统计表监理审  -->
  <div
    v-loading="tableLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-descriptions
      title=""
      style="width: 100%;"
      v-if="tableForm.tableData[0]"
    >
      <el-descriptions-item label="填报工点">{{
        tableForm.tableData[0].lineSite ? tableForm.tableData[0].lineSite : ""
      }}</el-descriptions-item>
      <el-descriptions-item label="三防应急响应类型">{{
        tableForm.tableData[0].responseInfo
          ? tableForm.tableData[0].responseInfo
          : ""
      }}</el-descriptions-item>
      <el-descriptions-item label="三防应急响应级别">{{
        tableForm.tableData[0].responseLevel
          ? tableForm.tableData[0].responseLevel
          : ""
      }}</el-descriptions-item>
    </el-descriptions>
    <div class="huizong">
      <el-table
        ref="tableData"
        :data="tableForm.tableData"
        id="tableData"
        border
        style="width: 100%; margin-top: 20px"
      >
        <el-table-column
          label="派出工作组(个)从应急响应启动到本次报送时间节点,派出工作组的累计数量"
          align="center"
          prop="workGroup"
        >
        </el-table-column>
        <el-table-column
          prop="position"
          label="检查场所部位(处)从应急响应启动到本次报送时间节点,检查场所部位的累计数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="hiddenDanger"
          label="新排查出三防风险隐患(处)上—次报送时间节点到本次报送时间节点，新排查出的三防风险隐患数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="info"
          label="发送预警或防御信息(条)地铁集团不需要填该项"
          align="center"
        >
          <template>
            {{ "/" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="worker"
          label="预置(出动)抢险人员(人)在本次报送时间节点，能立即调动的抢险人员"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="team"
          label="预置(出动)抢险队伍(支)在本次报送时间节点，能立即调动的抢险队伍数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="预置(出动)抢险设备(台)指在本次报送时间节点，能立即调动设备数量"
          align="center"
        >
          <el-table-column prop="deviceTotal" label="合计" align="center">
          </el-table-column>
          <el-table-column
            prop="vehicle"
            label="车辆能立即调动的抢险汽车、吊车、铲车、履带车等"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="boat"
            label="舟艇能立即调动的舟船、划艇等水上运输工具数量"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="waterPump"
            label="抽水机能立即调动的龙吸水、自吸泵、排污泵、潜水泵、动力站水泵等抽排水设备数量"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="other"
            label="其他能立即调动的、未统计在车辆、舟艇、抽水机范围内的三防抢险设备数量"
            align="center"
          >
          </el-table-column>
        </el-table-column>

        <el-table-column
          prop="remark"
          label="其他应急措施落实及受灾害影响情况指从应急响应启动到本次报送时间节点，集团公司各单位应急措施落实及受灾受影响累计情况"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>

    <div v-show="this.strery[0] == '1'">
      <el-timeline>
        <el-timeline-item
          v-for="(activity, index) in xyActivities"
          :key="index"
          type="primary"
          color="#3fa5e9"
          :size="'large'"
        >
          <div>
            <i style="font-style:normal;margin-right: 5px;">{{
              activity.operatorTime
            }}</i
            ><span>{{ activity.actionType }}</span>
            <span style="margin-left:10px"
              >操作人: {{ activity.operatorName }}
            </span>
            <span style="margin-left:10px" v-show="activity.operatorInfo"
              >操作意见: {{ activity.operatorInfo }}
            </span>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>

    <div v-show="approver">
      <span>审批意见：</span>
      <!-- <span v-show="deter">{{ opinion }}</span> -->
      <el-input
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4 }"
        placeholder="请输入内容"
        v-model="textarea2"
      >
      </el-input>
    </div>

    <div
      style="text-align: center; margin-top: 10px;position: relative;z-index: 111;"
    >
      <el-button type="danger" @click="BatchApproval" v-show="approv"
        >驳回</el-button
      >
      <el-button type="primary" @click="subtable" v-show="approvers"
        >通过</el-button
      >
      <el-button @click="Shutdown" v-show="deter">关闭</el-button>
      <!-- <el-button type="primary" @click="BatchApproval()">批量审批</el-button>
        <el-button type="danger" @click="BatchApproval()">驳回</el-button> -->
      <!-- <el-button type="primary" @click="subtable">提交</el-button> -->
    </div>
  </div>
</template>

<script>
import { statistics, approveent } from "@/api/response.js";
export default {
  props: {
    strery: {},
    approveid: {},
  },
  watch: {
    strery: {
      handler(val) {
        this.ster = val[0];
        if (val[0] == "1") {
          this.tableLoading = true;
          this.opinion = "";
          this.tableForm.tableData = [];
          this.approver = false;
          // this.approvers = false;
          // this.approv = false;
          this.deter = true;
        } else {
          this.tableLoading = true;
          this.opinion = "";
          this.tableForm.tableData = [];
          // this.approvers = false;
          // this.approv = false;
          this.approver = true;
          this.deter = false;
        }
      },
      immediate: true,
      deep: true,
    },
    approveid: {
      handler(val) {
        if (val) {
          this.statisticslist(val.id);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      ster: "",
      tableForm: {
        tableData: [],
      },
      textarea2: "",
      approver: false,
      approv: false,
      approvers: false,
      opera: "",
      operaesr: "",
      tableLoading: false,
      opinion: "",
      xyActivities: [],
    };
  },
  mounted() {},

  methods: {
    //填报查看详情
    statisticslist(val) {
      this.tableLoading = true;
      this.tableForm = {
        tableData: [],
      };
      statistics({ id: val }).then((res) => {
        this.tableForm.tableData.push(res.data.item);
        this.xyActivities = res.data.operatingHistoryList;

        this.tableLoading = false;
        if (
          res.data.item.approveInfoList != null &&
          res.data.item.approveInfoList.length > 1
        ) {
          this.opinion =
            res.data.item.approveInfoList[
              res.data.item.approveInfoList.length - 1
            ].approveRemark;
        } else {
          this.opinion = "";
        }
        if (this.ster != "1" && res.data.ops.length !== 0) {
          res.data.ops.forEach((it) => {
            if (it.operationText == "通过") {
              this.approvers = it.enabled;
              this.opera = it.operation;
            } else if (it.operationText == "驳回") {
              this.approv = it.enabled;
              this.operaesr = it.operation;
            } else {
              this.approvers = false;
            }
          });
        }
      });
    },
    //同意
    subtable() {
      this.tableLoading = true;
      approveent({
        data: this.textarea2,
        id: this.tableForm.tableData[0].id,
        operation: this.opera,
      })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.tableLoading = false;
            this.$emit("chexkpageClose");
            this.textarea2 = "";
          } else {
            this.$message.error("操作失败");
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    //驳回
    BatchApproval() {
      if (!this.textarea2) {
        this.$message({
          message: "请填写审批意见",
          type: "warning",
        });
      } else {
        this.tableLoading = true;
        approveent({
          data: this.textarea2,
          id: this.tableForm.tableData[0].id,
          operation: this.operaesr,
        })
          .then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.tableLoading = false;
              this.$emit("chexkpageClose");
              this.textarea2 = "";
            } else {
              this.$message.error("操作失败");
            }
          })
          .catch((err) => {
            this.tableLoading = false;
          });
      }
    },
    //关闭
    Shutdown() {
      this.textarea2 = "";
      this.$emit("chexkpageClose");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table th > .cell {
  font-size: 12px !important;
}
::v-deep .el-table--border {
  border: none;
}
.huizong {
  ::v-deep .el-table {
    height: 320px !important;
    overflow: auto;
  }
  ::v-deep .el-table--border,
  .el-table::before {
    border-bottom: 0 !important;
    height: 0px;
  }
  .el-table--border::after {
    width: 0;
  }
}
::v-deep .corner-wrap {
  display: none !important;
}
::v-deep .el-table:before {
  height: 0px;
}

::v-deep .el-table {
  background-color: #081742 !important;
}
::v-deep .el-table--border {
  border: none;
}
::v-deep .el-table tr {
  background: #081742 !important;
}
::v-deep .el-table__cell.is-leaf {
  background: #081742 !important;
}
::v-deep .el-table thead.is-group th.el-table__cell {
  background: #081742 !important;
}
::v-deep .el-table--border th.el-table__cell {
  border: 1px solid #081742 !important;
}
::v-deep .el-table::before,
.el-table--border::after {
  background-color: #143363 !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: rgba(0, 46, 117, 0.4);
}
::v-deep .el-table th.is-leaf,
::v-deep .el-table--border td {
  border: 1px solid #143363 !important;
}
::v-deep.el-timeline {
  margin-left: 5px;
  margin-top: 20px;
  background: #081742 !important;
  .el-timeline-item__content {
    color: #ffffff;
  }
}
.info-item {
  color: #ffffff;
}
::v-deep .el-descriptions-row {
  background-color: #081742 !important;
  color: #fff;
}
</style>
