<template>
  <div class="duty-container flex flex-col relative">
    <div class="bottom flex-1 p-4 bg-white mt-4">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="应急值班" name="2"></el-tab-pane>
      </el-tabs>
      <div class="first-wrapper flex">
        <div class="right ml-4 flex-1">
          <div class="search-wrapper flex justify-between items-center">
            <el-form
              :inline="true"
              :model="queryModel"
              class="demo-form-inline"
            >
              <el-form-item label="">
                <el-date-picker
                  v-model="queryModel.monthDate"
                  type="month"
                  placeholder="选择月"
                  value-format="yyyy-MM"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleSearch">查询</el-button>
              </el-form-item>
            </el-form>
            <el-row>
              <!-- <el-button type="primary">批量导入</el-button>
                  <el-button type="primary">导出</el-button> -->
            </el-row>
          </div>
          <div class="calendar-wrapper">
            <el-calendar v-model="calendarDate" v-loading="loading">
              <template slot="dateCell" slot-scope="{ data }">
                <div
                  class="w-full h-full relative p-2 calendar-item overflow-y-scroll"
                  @click="handleCalendarClick($event, data)"
                >
                  <div v-for="item in calendarData" :key="item.id">
                    <div v-if="data.day == item.dutyTime">
                      <ul>
                        <li v-for="i of item.dayShiftList" :key="i.id">
                          <span class="label">白班：</span>
                          <span>{{ i.realName }}</span>
                        </li>
                        <li v-for="i of item.nightShiftList" :key="i.id">
                          <span class="label">夜班：</span>
                          <span>{{ i.realName }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p class="date absolute right-0 top-0">
                    {{ data.day.slice(-2) }}
                  </p>
                </div>
              </template>
            </el-calendar>
          </div>
        </div>
      </div>
    </div>
    <!-- 值班人员选择弹窗 -->
    <system-modal
      ref="setDutyModal"
      :title="title"
      @modalConfirm="handleSubmit"
      @modalClose="handleClose"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="100px"
        class="demo-dynamic"
        v-loading="formLoading"
      >
        <el-form-item
          v-for="(day, index) in dynamicValidateForm.dayShiftList"
          :label="index == 0 ? '白班人员' : ''"
          :key="index"
          :prop="'dayShiftList.' + index + '.id'"
          :rules="{
            required: true,
            message: '人员不能为空',
            trigger: 'change',
          }"
        >
          <el-select
            v-model="day.id"
            placeholder="请选择"
            style="width: 50%"
            @change="personChange($event, index, 1)"
          >
            <el-option
              v-for="item in personList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-button
            class="ml-4"
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="addPersonnel(1)"
          ></el-button>
          <el-button
            class="ml-4"
            type="danger"
            size="mini"
            @click.prevent="removePersonnel(1, index, day)"
            v-if="dynamicValidateForm.dayShiftList.length > 1"
            icon="el-icon-minus"
          ></el-button>
        </el-form-item>
        <el-form-item
          v-for="(night, index) in dynamicValidateForm.nightShiftList"
          :label="index == 0 ? '夜班人员' : ''"
          :key="night.key"
          :prop="'nightShiftList.' + index + '.id'"
          :rules="{
            required: true,
            message: '人员不能为空',
            trigger: 'change',
          }"
        >
          <el-select
            v-model="night.id"
            placeholder="请选择"
            style="width: 50%"
            @change="personChange($event, index, 2)"
          >
            <el-option
              v-for="item in personList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-button
            class="ml-4"
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="addPersonnel(2)"
          ></el-button>
          <el-button
            class="ml-4"
            type="danger"
            size="mini"
            @click.prevent="removePersonnel(2, index, night)"
            v-if="dynamicValidateForm.nightShiftList.length > 1"
            icon="el-icon-minus"
          ></el-button>
        </el-form-item>
      </el-form>
    </system-modal>
  </div>
</template>

<script>
import SystemModal from "@/components/Modal/systemModal.vue";
import {
  queryDutyMonth,
  addDuty,
  queryDutyPersonList,
  dayRota,
  destoryRota,
} from "@/api/eres";
import dayjs from "dayjs";
export default {
  components: {
    SystemModal,
  },
  data() {
    return {
      queryModel: {
        emergency: "true",
        monthDate: dayjs().format("YYYY-MM"),
      },
      type: "96",
      lineSiteData: [], // 树型线路工点树
      flatLineSiteData: [], // 扁平化线路工点数据
      props: {
        children: "children",
        label: "name",
        value: "id",
      },
      defaultExpandedKeys: [],
      defaultCheckedKeys: [],
      dutyData: [], // 月值班数据
      activeName: "2",
      calendarDate: "",
      calendarData: [],
      loading: false,
      formLoading: false,
      // 弹窗相关
      title: "选择值班人员",
      personList: [], // 动态人员列表
      dynamicValidateForm: {
        dayShiftList: [],
        nightShiftList: [],
        dutyTime: dayjs().format("YYYY-MM"),
      },
    };
  },
  watch: {},
  mounted() {
    this.queryDutyPersonList();
    this.queryDutyDataByMonth();
  },
  methods: {
    // 选项卡切换
    handleClick() {
      if (this.activeName == 1) {
        // 日常值班
        this.queryModel.emergency = "false";
      } else {
        this.queryModel.emergency = "true";
      }
      this.queryDutyDataByMonth();
    },

    // 日历组件点击
    handleCalendarClick(e, record) {
      console.log(e, record);

      this.dynamicValidateForm.dayShiftList = [];
      this.dynamicValidateForm.nightShiftList = [];

      let parms = {
        emergency: this.activeName == "1" ? "false" : "true",
        searchDate: record.day + " 00:00:00",
      };

      dayRota(parms)
        .then((res) => {
          if (res.code == 0) {
            this.dynamicValidateForm.dutyTime = record.day;
            if (res.data.dayShiftList && res.data.dayShiftList.length > 0) {
              res.data.dayShiftList.forEach((element) => {
                this.dynamicValidateForm.dayShiftList.push({
                  name: element.realName,
                  id: element.id,
                  zid: element.zid,
                });
              });
            } else {
              this.dynamicValidateForm.dayShiftList.push({
                zid: "",
                id: "",
              });
            }
            if (res.data.nightShiftList && res.data.nightShiftList.length > 0) {
              res.data.nightShiftList.forEach((element) => {
                this.dynamicValidateForm.nightShiftList.push({
                  name: element.realName,
                  id: element.id,
                  zid: element.zid,
                });
              });
            } else {
              this.dynamicValidateForm.nightShiftList.push({
                zid: "",
                id: "",
              });
            }
            console.log(this.dynamicValidateForm);
          } else {
            this.dynamicValidateForm = {
              dayShiftList: [
                {
                  zid: "",
                  id: "",
                },
              ],
              nightShiftList: [
                {
                  zid: "",
                  id: "",
                },
              ],
              dutyTime: record.day,
            };
          }
        })
        .catch((err) => {
          this.$message.error("操作失败！" + err.message);
        });

      console.log(this.dynamicValidateForm.dutyTime, record.day);
      this.$refs.setDutyModal.handleOpen();
    },
    // 日历数据查询
    handleSearch() {
      this.calendarDate = this.queryModel.monthDate;
      this.queryDutyDataByMonth();
    },
    // 弹窗人员更换 留下原来人的zid  将现在选择的人的信息替换掉
    personChange(person, index, type) {
      console.log(person, index, type);
      let personObj = this.personList.find((i) => i.id == person);
      console.log(personObj, this.dynamicValidateForm.dayShiftList);

      if (type == 1) {
        this.dynamicValidateForm.dayShiftList.slice(index, 1, { ...personObj });
        if (
          this.dynamicValidateForm.dayShiftList[index] &&
          this.dynamicValidateForm.dayShiftList[index].zid
        ) {
          let zid1 = this.dynamicValidateForm.dayShiftList[index].zid;
          this.dynamicValidateForm.dayShiftList[index].zid = zid1;
        } else {
          this.dynamicValidateForm.dayShiftList[index].zid = null;
        }
      } else {
        this.dynamicValidateForm.nightShiftList.slice(index, 1, {
          ...personObj,
        });
        if (
          this.dynamicValidateForm.nightShiftList[index] &&
          this.dynamicValidateForm.nightShiftList[index].zid
        ) {
          let zid2 = this.dynamicValidateForm.nightShiftList[index].zid;
          this.dynamicValidateForm.nightShiftList[index].zid = zid2;
        } else {
          this.dynamicValidateForm.nightShiftList[index].zid = null;
        }
      }
    },
    // 增加值班人员选项
    addPersonnel(type) {
      if (type == 1) {
        this.dynamicValidateForm.dayShiftList.push({
          zid: "",
          id: "",
        });
      } else {
        this.dynamicValidateForm.nightShiftList.push({
          zid: "",
          id: "",
        });
      }
    },
    // 移除值班人员选项
    removePersonnel(type, index, data) {
      console.log(type, index, data);
      this.$confirm("确定删除该人员?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 白班
          if (type == 1) {
            if (data.zid) {
              destoryRota(data.zid).then((res) => {
                if (res.code == 0) {
                  this.dynamicValidateForm.dayShiftList.splice(index, 1);
                  this.$message.success("操作成功！");
                } else {
                  this.$message.error("操作失败！");
                }
              });
            } else {
              this.dynamicValidateForm.dayShiftList.splice(index, 1);
              this.$message.success("操作成功！");
            }
          } else {
            // 夜班
            if (data.zid) {
              destoryRota(data.zid).then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功！");
                  this.dynamicValidateForm.nightShiftList.splice(index, 1);
                } else {
                  this.$message.error("操作失败！");
                }
              });
            } else {
              this.$message.success("操作成功！");
              this.dynamicValidateForm.nightShiftList.splice(index, 1);
            }
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 查询月份值班表
    queryDutyDataByMonth() {
      this.loading = true;
      const params = JSON.parse(JSON.stringify(this.queryModel));
      queryDutyMonth(params)
        .then((res) => {
          if (res.code == 0) {
            this.calendarData = res.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
    },
    // 查询值班人员
    async queryDutyPersonList() {
      let res = await queryDutyPersonList();
      if (res.code == 0) {
        console.log(res.data);
        this.personList = res.data;
      }
    },
    handleClose() {
      this.queryDutyDataByMonth();
    },
    handleSubmit() {
      this.$refs.dynamicValidateForm.validate((valid) => {
        if (valid) {
          this.formLoading = true;
          const params = {
            dayShiftList: [],
            nightShiftList: [],
            emergency: this.activeName == "1" ? "false" : "true",
            dutyTime: this.dynamicValidateForm.dutyTime,
          };
          for (let item of this.dynamicValidateForm.dayShiftList) {
            const tempObj = this.personList.find(
              (person) => person.id == item.id
            );
            console.log(item, item.zid);
            params.dayShiftList.push({
              ...tempObj,
              zid: item.zid ? item.zid : null,
            });
          }

          for (let item of this.dynamicValidateForm.nightShiftList) {
            const tempObj = this.personList.find(
              (person) => person.id == item.id
            );
            params.nightShiftList.push({
              ...tempObj,
              zid: item.zid ? item.zid : null,
            });
          }
          addDuty(params)
            .then((res) => {
              if (res.code == 0) {
                this.formLoading = false;
                this.$message.success("操作成功！");
                this.$refs.setDutyModal.handleClose();
                this.queryDutyDataByMonth();
              } else {
                this.formLoading = false;
                this.$message.error("操作失败！" + res.message);
              }
            })
            .catch((err) => {
              this.$message.error("操作失败！" + err.message);
              this.formLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 19;
}

::v-deep .el-calendar {
  .el-calendar__header {
    display: none;
  }
}

::v-deep .el-input {
  width: 100% !important;
}

.duty-container {
  .top {
    .title {
      margin-bottom: 28px;
    }

    .item-wrapper {
      .item {
        width: 200px;
        border: 1px solid #43a5e9;

        .item-header {
          padding-left: 10px;
          line-height: 40px;
          background: #43a5e9;
          color: #fff;
          clip-path: polygon(0px 0px, 180px 0px, 165px 40px, 0px 40px);
        }

        .item-content {
          padding: 20px 10px;
        }
      }
    }
  }

  .bottom {
    .first-wrapper {
      .left {
        width: 300px;
        border: 1px solid #e6e7ec;

        .header {
          .title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #081742;
          }
        }
      }

      .right {
        .calendar-wrapper {
          span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }

          .calendar-item {
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;

            &::-webkit-scrollbar {
              width: 0 !important;
            }
          }
        }
      }
    }
  }

  .handle-wrapper {
    min-width: 240px;
    max-width: 30vw;
    height: 60vh;
    position: absolute;
    top: 10%;
    left: 20px;
    background: rgba(255, 255, 255, 1);
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
    border-radius: 0px 20px 20px 0px;
    padding: 20px;
    z-index: 10;

    .tree-wrapper {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }

    .toggle-btn {
      width: 28px;
      height: 240px;
      position: absolute;
      right: 0px;
      top: 50%;
      margin-top: -120px;
      cursor: pointer;

      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }

    .toggle-btn-close {
      transform: rotate(180deg);
    }

    .toggle-btn-open {
      transform: rotate(0deg);
    }
  }

  .handle-wrapper-hide {
    left: -212px;
    background: transparent;
    box-shadow: none;
  }

  .handle-wrapper-show {
    left: 0;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #43a5e9;
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
  }
}
</style>
