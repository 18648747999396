<template>
  <div>
    <el-table :data="tabledata" border style="width: 100%; margin-top: 10px">
      <el-table-column
        align="center"
        type="index"
        label="序号"
        width="60"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="id"
        show-overflow-tooltip
        label="三防应急响应"
      >
        <template slot-scope="scope">
          {{ scope.row.type ? scope.row.type.label : ""
          }}{{
            scope.row.currentLevel ? scope.row.currentLevel.label : ""
          }}级响应
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="id"
        show-overflow-tooltip
        label="响应类型"
      >
        <template slot-scope="scope">
          {{ scope.row.type ? scope.row.type.label : "" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="responseName"
        show-overflow-tooltip
        label="响应级别"
      >
        <template slot-scope="scope">
          {{ scope.row.currentLevel ? scope.row.currentLevel.label : "" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="startTime"
        show-overflow-tooltip
        label="开始时间"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="isRelegation"
        show-overflow-tooltip
        label="是否升/降级"
      >
        <template slot-scope="scope">
          {{
            scope.row.isRelegation
              ? scope.row.isRelegation == 0
                ? "/"
                : scope.row.isRelegation == 1
                ? "升级"
                : "降级"
              : "/"
          }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="relegationTime"
        show-overflow-tooltip
        label="升/降级时间"
      >
        <template slot-scope="scope">
          {{ scope.row.relegationTime ? scope.row.relegationTime : "/" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="relegationLevel"
        show-overflow-tooltip
        label="升/降级等级"
      >
        <template slot-scope="scope">
          {{
            scope.row.relegationLevelDto
              ? scope.row.relegationLevelDto.label
              : "/"
          }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="endTime"
        show-overflow-tooltip
        label="结束时间"
      >
        <template slot-scope="scope">
          {{ scope.row.endTime ? scope.row.endTime : "/" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="status"
        show-overflow-tooltip
        label="状态"
        :formatter="statusFormatter"
      >
      </el-table-column>
    </el-table>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        :total="page.count"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        layout=" prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { responseList } from "@/api/responseTem";
export default {
  components: {},
  props: {
    paramsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      page: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
      },
      tabledata: [],
    };
  },
  watch: {},
  mounted() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      let params = {
        dateType: Number(this.paramsData.dateType),
        page: this.page.pageNo,
        size: this.page.pageSize,
        currentLevel: this.paramsData.currentLevel,
        type: this.paramsData.type,
      };
      if (this.paramsData.YJTJ.name != false) {
        params.data = this.paramsData.YJTJ.name;
      }
      responseList(params).then((res) => {
        if (res.code == 0) {
          this.tabledata = res.data.records;
          this.page.count = res.data.count;
        }
      });
    },
    statusFormatter(val) {
      switch (val.status) {
        case 0:
          return "进行中";
        case 1:
          return "响应结束,事件闭合中";
        case 2:
          return "已结束";
      }
    },
    //页数
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.onSearch();
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.onSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 700px;
  overflow: auto;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
::v-deep .el-table:before {
  height: 0px;
}
.stCard {
  margin: 0 20px 10px 0;
  padding: 20px 20px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.cenCard {
  padding: 20px 20px;
  width: 24%;
  height: 100px;
}
.cenNum {
  font-size: 28px;
  font-family: Arial;
  font-weight: bold;
  color: #081742;
}
</style>
