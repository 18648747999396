<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="type == 'view' ? viewRule : rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-model"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="预警名称" prop="warnName">
            <el-input
              :readonly="type == 'view' ? true : false"
              v-model="ruleForm.warnName"
              placeholder="预警名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工点" prop="siteUuid">
            <el-cascader
              v-if="type != 'view'"
              style="width:100%"
              clearable
              v-model="siteList"
              :options="lineSiteData"
              :props="props"
              :show-all-levels="false"
              @change="handleLineSiteChange"
            ></el-cascader>
            <!-- collapse-tags -->
            <el-input
              v-if="type == 'view'"
              v-model="ruleForm.siteName"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发布单位" prop="issuedName">
            <el-input
              v-model="ruleForm.issuedName"
              readonly
              placeholder="发布单位"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发布人" prop="publisherName">
            <el-input
              v-model="ruleForm.publisherName"
              readonly
              placeholder="发布人"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发布时间" prop="releaseTime">
            <el-date-picker
              v-if="type != 'view'"
              style="width:100%"
              :readonly="type == 'view' ? true : false"
              :disabled="type == 'view' ? true : false"
              v-model="ruleForm.releaseTime"
              type="datetime"
              placeholder="选择时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
            <el-input
              v-if="type == 'view'"
              v-model="ruleForm.releaseTime"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="预警类型" prop="warnTypeCode">
            <el-select
              style="width:100%"
              v-if="type != 'view'"
              :disabled="isReadonly"
              v-model="ruleForm.warnTypeCode"
              @change="handleChangeWarnType"
              placeholder="预警类型"
            >
              <el-option
                v-for="item in warnType"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <el-input
              v-if="type == 'view'"
              v-model="ruleForm.warnTypeName"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="预警等级" prop="warnGradeCode">
            <el-select
              v-if="type != 'view'"
              style="width:100%"
              :readonly="type == 'view' ? true : false"
              :disabled="type == 'view' ? true : false"
              v-model="ruleForm.warnGradeCode"
              @change="handleChangeWarnLevel"
              placeholder="预警等级"
            >
              <el-option
                v-for="item in warnLevel"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <el-input
              v-if="type == 'view'"
              v-model="ruleForm.warnGradeName"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="type == ''">
          <el-form-item
            label="风险名称"
            prop="riskUuid"
            :rules="{
              required: isShowRiskName,
              message: '请选择预警名称',
              trigger: 'change'
            }"
          >
            <el-select
              v-if="isShowRiskName == true"
              style="width:100%"
              v-model="ruleForm.riskUuid"
              @change="dangerChange"
              remote
              placeholder="风险名称"
              :remote-method="remoteMethod"
              :loading="selloading"
              filterable
            >
              <el-option
                v-for="item in options"
                :key="item.riskDeafUuid"
                :label="item.riskName"
                :value="item.riskDeafUuid"
              >
              </el-option>
            </el-select>
            <el-input
              v-if="isShowRiskName == false"
              v-model="ruleForm.riskName"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="type == 'view'">
          <el-form-item label="风险名称">
            <el-input v-model="ruleForm.riskName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="整改单位" prop="rectificationName">
            <el-input
              v-model="ruleForm.rectificationName"
              readonly
              placeholder="整改单位"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            v-if="type == 'view'"
            label="整改人"
            prop="rectifierUserName"
          >
            <el-input
              v-model="ruleForm.rectifierUserName"
              readonly
              placeholder="整改人"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="type != 'view'"
            label="整改人"
            prop="rectifierUserName"
          >
            <el-input
              v-model="ruleForm.rectifierUserName"
              readonly
              placeholder="整改人"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="预警情况描述" prop="warnDescribe">
            <el-input
              type="textarea"
              v-model="ruleForm.warnDescribe"
              :readonly="type == 'view' ? true : false"
              :autosize="{ minRows: 2, maxRows: 6 }"
              resize="none"
              placeholder="预警情况描述"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="现场图片" prop="riskWarnPicList">
            <image-uploader
              v-if="type != 'view'"
              :disabled="type == 'view' ? true : false"
              v-model="ruleForm.riskWarnPicList"
              @uploadChange="urlChange"
            ></image-uploader>
            <div v-if="type == 'view'" class="imgGroupBox">
              <el-image
                style="width: 100px; height: 100px"
                :preview-src-list="ruleForm.riskWarnPicList"
                v-for="(item, i) in ruleForm.riskWarnPicList"
                :key="i"
                :src="item"
              ></el-image>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="补充附件" prop="warnFile">
            <file-uploader
              v-if="type != 'view'"
              @uploadChange="handleFileUpload"
              @delChange="handleFileDel"
              :value="fileList"
            ></file-uploader>
            <ul v-if="type == 'view'" class="fileGroupBox">
              <li v-for="(item, i) in ruleForm.riskWarnFileList" :key="i">
                {{ item.fileName }}
                <span @click="handleFileClick(item)">预览</span>
                <span @click="handleExport(item.fileRealUrl, item.fileName)"
                  >导出</span
                >
              </li>
            </ul>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="dialogFooter" v-if="type != 'view'">
      <el-button size="medium" @click="cancel">取 消</el-button>
      <el-button size="medium" type="primary" @click="submit">确 定</el-button>
    </div>
  </div>
</template>

<script>
import FileUploader from "@/components/Uploader/FileUploader.vue";
import imageUploader from "../../../components/imageUploader.vue";
import {
  warnAdd,
  riskItemMasterList,
  getConstructionUnitlnfo,
  download
} from "@/api/riskManage";
export default {
  components: {
    FileUploader,
    imageUploader
  },
  props: {
    warnType: {
      type: Array,
      default: () => []
    },
    warnLevel: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: () => ""
    },
    detailsForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ruleForm: {
        warnName: "",
        siteUuid: "",
        issuedName: "",
        issuedUuid: "",
        publisherName: "", // 发布人
        publisherUuid: "", //发布人uuid
        releaseTime: "", // 发布时间
        warnGradeCode: "", //预警等级code
        warnGradeName: "", //预警等级名称
        warnTypeCode: "", //预警类型code
        warnTypeName: "", //预警类型名称
        rectificationName: "", //整改单位名称
        rectificationUuid: "", //整改单位uuid
        rectifierName: "", //整改人
        rectifierUuid: "", //整改人uuid
        riskUuid: "", //风险code
        riskName: "", //风险名称
        riskWarnPicList: [], //现场图片集合
        riskWarnFileList: [], //补充附件集合
        rectifierUserName: "", //整改人名称

        lineName: "", //线路名称
        lineUuid: "", //线路uuid
        branchName: "", //分部名称
        branchUuid: "", //分部uuid
        siteName: "", //站点名称
        // eslint-disable-next-line no-dupe-keys
        siteUuid: "", //站点uuid
        warnDescribe: "" //预警情况描述
      },
      rules: {
        warnName: [
          { required: true, message: "请输入预警名称", trigger: "blur" }
        ],
        siteUuid: [
          { required: true, message: "请输入预警名称", trigger: "blur" }
        ],
        releaseTime: [
          { required: true, message: "请选择发布时间", trigger: "change" }
        ],
        warnType: [
          { required: true, message: "请选择预警类型", trigger: "change" }
        ],
        warnGradeCode: [
          { required: true, message: "请选择预警等级", trigger: "change" }
        ],
        warnDescribe: [
          { required: true, message: "请输入预警描述", trigger: "blur" }
        ],
        riskWarnPicList: [
          { required: true, message: "请上传图片", trigger: "change" }
        ],
        riskName: [{ message: "请选择预警名称", trigger: "change" }]
      },
      viewRule: {},
      isRequire: false,
      lineSiteData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid"
      },
      selloading: false,
      options: [],
      fileList: [], // 附件列表
      siteList: [], // 层级站点树
      faltArr: [], //站点数平行数据
      photoList: [], //数组图片列表
      isShowRiskName: true,
      riskSelList: [],
      currentUnitType: "",
      isReadonly: ""
    };
  },
  mounted() {
    const that = this;
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      this.ruleForm.issuedName = user.companyName;
      this.ruleForm.issuedUuid = user.companyId;
      this.ruleForm.publisherName = user.realName;
      this.ruleForm.publisherUuid = user.uuid;
      this.currentUnitType = user.companyCategory;
      if (
        user.companyCategory == "construct" ||
        user.companyCategory == "contracting" ||
        user.companyCategory == "design"
      ) {
        // ss
        that.isReadonly = true;
        that.ruleForm.warnTypeCode = "patrolWarning";
        that.ruleForm.warnTypeName = "巡视预警";
      } else {
        that.isReadonly = false;
        that.ruleForm.warnTypeCode = "";
        that.ruleForm.warnTypeName = "";
      }
      console.log(that.isReadonly, "sssssss");
      console.log(that.ruleForm.warnTypeName, "this.ruleForm.warnTypeName");
    }
    this.getLineTree();
  },
  watch: {
    detailsForm() {
      if (this.type == "view") {
        this.ruleForm = this.detailsForm;
      }
    },
    "ruleForm.warnTypeName": {
      deep: true,
      handler() {
        if (this.ruleForm.warnTypeName == "巡视预警") {
          this.isRequire = true;
        } else {
          this.isRequire = false;
        }
      }
    }
  },
  methods: {
    async getLineTree() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.lineSiteData = JSON.parse(JSON.stringify(lineSiteData));
        this.lineSiteData.forEach(item => {
          this.flatArray(item);
        });
      }
    },
    flatArray(data) {
      this.faltArr.push(data);
      if (data.children && data.children.length > 0) {
        data.children.forEach(item => {
          this.flatArray(item);
        });
      }
    },
    handleLineSiteChange(val) {
      console.log(val, "val");
      if (val && val.length > 0) {
        this.ruleForm.lineUuid = val[0];
        this.ruleForm.branchUuid = val[1];
        this.ruleForm.siteUuid = val[val.length - 1];
        this.getSiteConsUnit(this.ruleForm.siteUuid);
        let site = this.faltArr.find(
          item => item.uuid == this.ruleForm.siteUuid
        );
        let line = this.faltArr.find(
          item => item.uuid == this.ruleForm.lineUuid
        );
        let branch = this.faltArr.find(
          item => item.uuid == this.ruleForm.branchUuid
        );
        this.ruleForm.siteName = site.name;
        this.ruleForm.lineName = line.name;
        this.ruleForm.branchName = branch.name;
      }
      riskItemMasterList({ siteUuid: this.ruleForm.siteUuid }).then(res => {
        this.riskSelList = res.data;
      });
    },
    getSiteConsUnit(uuid) {
      getConstructionUnitlnfo(uuid).then(res => {
        this.ruleForm.rectificationUuid = res.ConstructionUnitId;
        this.ruleForm.rectificationName = res.ConstructionUnitName;
        this.ruleForm.rectifierUuid = res.roleId;
        this.ruleForm.rectifierName = res.roleName;
        this.ruleForm.rectifierUserName = res.userNames;
      });
    },
    urlChange(urlList) {
      this.photoList = [];
      for (let item of urlList) {
        this.photoList.push(item.url);
      }
    },
    remoteMethod(query) {
      console.log(query, "query");
      console.log(this.riskSelList, "riskSelList");
      if (query !== "") {
        this.selloading = true;
        setTimeout(() => {
          this.selloading = false;
          this.options = this.riskSelList.filter(item => {
            console.log(item, "item");
            return item.riskName.indexOf(query) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    handleChangeWarnType(val) {
      // 巡视预警
      this.ruleForm.riskUuid = "";
      this.ruleForm.riskName = "";
      // this.options = [];
      // this.riskSelList = [];
      if (val == "patrolWarning") {
        console.log("ddddddddddddddd");
        this.isShowRiskName = true;
      } else if (val == "comprehensiveWarning") {
        //综合预警
        this.isShowRiskName = false;
      }
      console.log(val, "val");
      const _this = this;
      this.warnType.forEach(el => {
        if (el.code == val) {
          _this.ruleForm.warnTypeName = el.label;
        }
      });
    },
    handleChangeWarnLevel(val) {
      const _this = this;
      this.warnLevel.forEach(el => {
        if (el.code == val) {
          _this.ruleForm.warnGradeName = el.label;
        }
      });
    },
    handleFileClick(record) {
      const link = document.createElement("a");
      link.href = record.fileUrl;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
    handleExport(url, name) {
      let list = [
        { name: "doc", value: "application/msword" },
        {
          name: "docx",
          value:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        },
        { name: "xls", value: "application/vnd.ms-excel" },
        {
          name: "xlsx",
          value:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        { name: "ppt", value: "application/vnd.ms-powerpoint" },
        {
          name: "pptx",
          value:
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        },
        { name: "pdf", value: "application/pdf;chartset=UTF-8" }
      ];
      let fileType = url.split(".")[1];
      let type = null;
      list.forEach(el => {
        if (el.name == fileType) {
          type = el.value;
        }
      });
      // if (fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
      // s

      // } else {
      download(url).then(res => {
        const aLink = document.createElement("a");
        let blob = null;
        if (fileType == "txt") {
          blob = new Blob([res]);
        } else if (
          fileType == "png" ||
          fileType == "jpg" ||
          fileType == "jpeg"
        ) {
          blob = new Blob([res]);
        } else {
          blob = new Blob([res], { type: type });
        }
        aLink.href = URL.createObjectURL(blob);
        aLink.download = name + "." + fileType;
        aLink.click();
        document.body.appendChild(aLink);
      });
      // }
    },
    dangerChange(val) {
      this.options.forEach(el => {
        if (val == el.riskDeafUuid) {
          this.ruleForm.riskName = el.riskName;
          this.ruleForm.riskLevelCode = el.riskLevelCode;
          this.ruleForm.riskLevelName = el.riskLevelName;
        }
      });
    },
    handleFileUpload({ name, url, realUrl }) {
      this.fileList.push({ name: name, url: url, fileRealUrl: realUrl });
      this.ruleForm.riskWarnFileList.push({
        fileName: name,
        fileUrl: url,
        fileRealUrl: realUrl
      });
    },
    handleFileDel({ name, url }) {
      console.log(name);
      let index = this.fileList.findIndex(v => v.url === url);
      this.fileList.splice(index, 1);
      this.ruleForm.riskWarnFileList.splice(index, 1);
    },
    cancel() {
      this.rolesData = [];
      this.defaultCheckedKeys = [];
      this.reset();
      this.$emit("CloseData");
    },
    reset() {
      const th = this;
      Object.keys(this.ruleForm).forEach(function(key) {
        th.ruleForm[key] = "";
      });
      this.$refs.ruleForm.resetFields();
    },
    submit() {
      const _this = this;
      this.ruleForm.riskWarnPicList = this.photoList;
      console.log(this.ruleForm, "this.ruleForm");
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, _this.ruleForm);
          warnAdd(copyForm)
            .then(res => {
              if (res.code == 0) {
                this.$message.success("操作成功");
                this.loading = true;
                this.cancel();
              } else {
                this.$message.error(res.message);
              }
            })
            .catch(res => {
              this.$message.error(res.message);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background-color: none !important;
}
.imgGroupBox {
  display: flex;
  flex-wrap: wrap;
}
.huixianImg {
  width: 148px;
  height: 148px;
  margin-left: 15px;
}
.huixianImg:first-child {
  margin-left: 0;
}
.fileGroupBox {
  span {
    color: #3fa5e9;
    cursor: pointer;
    margin-left: 10px;
  }
}
</style>
