<template>
  <ul class="zz-menu zz-menu--horizontal flex">
    <!--菜单项-->
    <template v-for="(item, index) in menu">
      <zz-menu-item :item="item" :key="index" :firstLevel="true"></zz-menu-item>
    </template>
    <li v-if="rightMenu" class="workbench-btn flex justify-center items-center" @click="handleJumpWorkbench">
      <a href="javascript:void(0)">工作台</a>
    </li>
  </ul>
</template>

<script>
import zzMenuItem from "./Item.vue";

export default {
  name: "zz-menu-horizontal",
  provide() {
    return {
      rootMenu: this,
    };
  },
  components: {
    zzMenuItem,
  },
  data() {
    return {
      openedMenus: [],
      activeIds: []
    };
  },
  props: {
    menu: {
      type: Array,
      default: () => {
        return [];
      },
    },
    rightMenu: {
      type: Boolean,
      default: false
    },
    systemPath: {
      type: String,
      default: ""
    }
  },
  mounted() {
    console.log(this.menu)
    const resourceAll = JSON.parse(localStorage.getItem("SCREEN_AUTH") || '""');
    const currentPath = this.$route.path;
    const currentMenu = resourceAll.find(item => item.resourcePath == currentPath);
    this.activeIds = [];
    this.treeFindUp(currentMenu.id, resourceAll);
  },
  methods: {
    openMenu(id) {
      let openedMenus = this.openedMenus;
      if (openedMenus.indexOf(id) !== -1) return;
      this.openedMenus.push(id);
    },
    closeMenu(id) {
      const i = this.openedMenus.indexOf(id);
      if (i !== -1) {
        this.openedMenus.splice(i, 1);
      }
    },
    handleItemClick(record) {
      console.log("handleItemClick", record.id);
      const treeData = JSON.parse(localStorage.getItem("SCREEN_AUTH") || '""');
      this.activeIds = [];
      this.treeFindUp(record.id, treeData);
    },
    treeFindUp(id, origin) {
      this.activeIds.unshift(id);
      const tempObj = origin.find(item => item.id == id);
      if (tempObj) {
        this.treeFindUp(tempObj.parentId, origin);
      } else {
        return;
      }
    },
    handleJumpWorkbench() {
      const path = this.systemPath;
      // window.open(path, "_blank");
      // 本页面跳转
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.zz-menu {
  width: 100%;
}
.workbench-btn {
  width: 160px;
  height: 40px;
  background: #0b1b4f;
  border: 1px solid #173f58;
  margin-top: 10px;
  a {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>
