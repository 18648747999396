<template>
  <systemModal
    ref="formModal"
    :title="title"
    :showFooter="false"
    width="40%"
    @modalClose="handleClose"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-model"
    >
      <el-form-item label="所属建管部" prop="buildDeptName">
        <el-input
          v-model="ruleForm.buildDeptName"
          readonly
          placeholder="所属建管部"
        ></el-input>
      </el-form-item>
      <el-form-item label="线路名称" prop="lineName">
        <el-input
          v-model="ruleForm.lineName"
          readonly
          placeholder="线路名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="分部名称" prop="branchName">
        <el-input
          v-model="ruleForm.branchName"
          readonly
          placeholder="分部名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="工点名称" prop="siteName">
        <el-input
          v-model="ruleForm.siteName"
          readonly
          placeholder="工点名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="培训名称" prop="label">
        <el-input
          v-model="ruleForm.label"
          readonly
          placeholder="培训名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="主讲人" prop="teacher">
        <el-input
          v-model="ruleForm.teacher"
          readonly
          placeholder="主讲人"
        ></el-input>
      </el-form-item>
      <el-form-item label="培训地点" prop="trainAddress">
        <el-input
          v-model="ruleForm.trainAddress"
          readonly
          placeholder="培训地点"
        ></el-input>
      </el-form-item>
      <el-form-item label="计划培训时间" prop="planDate">
        <el-input
          v-model="ruleForm.planDate"
          readonly
          placeholder="计划培训时间"
        ></el-input>
      </el-form-item>
      <el-form-item label="实际培训时间" prop="trainDate" v-if="type == 1024">
        <el-input
          v-model="ruleForm.trainDate"
          readonly
          placeholder="实际培训时间"
        ></el-input>
      </el-form-item>
      <el-form-item label="参与人数" prop="joinCount" v-if="type == 1024">
        <el-input
          v-model="ruleForm.joinCount"
          readonly
          placeholder="参与人数"
        ></el-input>
      </el-form-item>
      <el-form-item label="上传人" prop="operatorName">
        <el-input
          v-model="ruleForm.operatorName"
          readonly
          placeholder="上传人"
        ></el-input>
      </el-form-item>
      <el-form-item label="上报时间" prop="created">
        <el-input
          v-model="ruleForm.created"
          readonly
          placeholder="上报时间"
        ></el-input>
      </el-form-item>

      <el-form-item label="课件" prop="label">
        <div class="file-item" @click="openFile(ruleForm.coursewares[0].link)">
          {{ ruleForm.coursewares ? ruleForm.coursewares[0].attrName : "" }}
        </div>
      </el-form-item>
      <el-form-item label="附件" prop="label" v-if="type == 1024">
        <div class="file-item" @click="openFile(ruleForm.accessories[0].link)">
          {{ ruleForm.accessories ? ruleForm.accessories[0].attrName : "" }}
        </div>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue"
import { queryDictByLabel, queryDictNodesById } from "@/api/dict"
import { trainInfoUpdate, backlogInfo } from "@/api/stand"
export default {
  components: {
    systemModal,
  },
  data () {
    return {
      title: "详情",
      examintStatus: null,
      detailId: "",
      ruleForm: {
        courseware: [],
        accessory: [],
      },
      type: "",
      rules: {
        reason: [{ required: true, message: "请输入原因", trigger: "blur" }],
      },
      radio: 3,
      dict: {},
      loading: false,
    }
  },
  filters: {},
  mounted () { },
  methods: {
    handleOpen (record) {
      console.log(record)
      this.title = record.noticeCode == 1023 ? "计划审核" : "报告审核"
      this.type = record.noticeCode
      this.getDetailData(record)
    },
    onSubmit () {

    },
    onClose () { },
    getDetailData (record) {
      backlogInfo(record.eventId).then((res) => {
        console.log(res)
        if (res.code == 0) {
          this.ruleForm = res.data
          this.ruleForm.reason = ""
          this.detailId = res.data.id
          this.examintStatus = res.data.state
          this.$refs.formModal.handleOpen()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    openFile (url) {
      window.open(url)
    },
    cancelTrainClose () {
      this.$emit("cancelTrainClose")
    },
    submitTrain () {
      if (this.radio == 6 && !this.ruleForm.reason) {
        this.$message.error("请输入原因！")
        return false
      }
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let params = {
            id: this.detailId,
            reason: this.ruleForm.reason,
            // state : this.radio == 3 ? 3 : 2
          }
          if (this.examintStatus == 1 && this.radio == 3) {
            params.state = 4
          } else if (this.examintStatus == 1 && this.radio != 3) {
            params.state = 3
          } else if (this.examintStatus == 5 && this.radio == 3) {
            params.state = 8
          } else if (this.examintStatus == 5 && this.radio != 3) {
            params.state = 7
          }
          trainInfoUpdate(params).then((res) => {
            console.log(res)
            if (res.code == 0) {
              this.$message.success("操作成功")
              this.$emit("close")
              this.$refs.formModal.handleClose()
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          this.$message.error("请正确填写")
        }
      })
    },
    getDictData (label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data)
              this.$forceUpdate()
            }
          })
        }
      })
    },
    handleClose () {
      this.$emit("close")
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 30px !important;
}

::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}

::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
</style>
