<template>
  <div style="width:100%;height:100%">
    
     <iframe
            :src="iframeSrc"
	    width="100%"
	    height="100%"
	    frameborder="0"
	    scrolling="no">
        </iframe>
  </div>
</template>

<script>
export default {
    data(){
        return{
            iframeSrc:"https://ts.gzdtjl.com:91/login/loginToMain?u=c2VjdXJpdHlDb250cm9s&p=U2VjdXJpdHkyMDIy"
        }
    }
}
</script>

<style>

</style>