<template>
  <div class="flex justify-start">
    <div class="flex flex-col" style="width: 50%">
      <div class="stCard" style="width: 100%; height: 176px">
        <div class="flex justify-between">
          <div style="font-size: 16px; font-weight: bold; color: #081742">
            三防待办统计
          </div>
          <div class="flex justify-start">
            <!-- <div class="mr-2">
              <el-cascader
                size="small"
                ref="linecas"
                placeholder="选择线路工点"
                v-model="model.lineSite"
                :options="treeData"
                :props="propsForm"
                filterable
                clearable
                @change="handleLineSiteChangeFrom"
              ></el-cascader>
            </div> -->
            <div>
              <el-radio-group
                v-model="model.times"
                size="small"
                @change="getSFDB"
              >
                <el-radio-button label="1">年</el-radio-button>
                <el-radio-button label="2">季</el-radio-button>
                <el-radio-button label="3">月</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="flex justify-between mt-2">
          <div
            class="cenCard flex justify-between cursor-pointer"
            style="background: rgba(23, 145, 255, 0.1)"
            @click="jumps('三防待办统计', '', '待办总数')"
          >
            <div class="flex flex-col">
              <div>待办总数</div>
              <div class="cenNum">{{ selectMessageCountList.total }}</div>
            </div>

            <div>
              <img src="@/assets/system/db-1.png" alt="" />
            </div>
          </div>
          <div
            class="cenCard flex justify-between cursor-pointer"
            style="background: rgba(114, 192, 64, 0.1)"
            @click="jumps('三防待办统计', 1, '已处理')"
          >
            <div class="flex flex-col">
              <div>已处理</div>
              <div class="cenNum">{{ selectMessageCountList.finishCount }}</div>
            </div>

            <div>
              <img src="@/assets/system/db-2.png" alt="" />
            </div>
          </div>
          <div
            class="cenCard flex justify-between cursor-pointer"
            style="background: rgba(122, 99, 255, 0.1)"
            @click="jumps('三防待办统计', 0, '待处理')"
          >
            <div class="flex flex-col">
              <div>待处理</div>
              <div class="cenNum">
                {{ selectMessageCountList.notFinishCount }}
              </div>
            </div>

            <div>
              <img src="@/assets/system/db-3.png" alt="" />
            </div>
          </div>
          <div
            class="cenCard flex justify-between cursor-pointer"
            style="background: rgba(250, 173, 19, 0.1)"
          >
            <div class="flex flex-col">
              <div>完成率</div>
              <div class="cenNum">{{ selectMessageCountList.finishRatio }}</div>
            </div>

            <div>
              <img src="@/assets/system/db-4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="stCard" style="width: 100%; height: 500px">
        <div class="flex justify-between">
          <div style="font-size: 16px; font-weight: bold; color: #081742">
            三防应急发布统计
          </div>
          <div class="flex justify-start">
            <div>
              <el-radio-group
                v-model="model.dateType"
                size="small"
                @change="getSFYJXY"
              >
                <el-radio-button label="1">年</el-radio-button>
                <el-radio-button label="2">季</el-radio-button>
                <el-radio-button label="3">月</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div
          class="flex justify-around mt-3 pt-2"
          style="background: rgba(67, 165, 233, 0.05); height: 100px"
        >
          <div
            class="flex flex-col text-center"
            @click="jumpXY('三防应急发布统计', '', '总数', '', false)"
          >
            <div class="cenNum">{{ totals }}</div>
            <div>总数</div>
          </div>
          <div
            class="flex flex-col text-center"
            v-for="(val, index) in responseCountDepartmentList.resultList"
            :key="index"
            @click="
              jumpXY(
                '三防应急发布统计',
                val.currentLevel,
                val.cateName + '级响应',
                '',
                false
              )
            "
          >
            <div class="cenNum">{{ val.num }}</div>
            <div>{{ val.cateName }}级响应</div>
          </div>
        </div>
        <div>
          <div id="main" class="mt-3" style="width: 100%; height: 300px"></div>
        </div>
      </div>

      <div class="stCard" style="width: 100%; height: 670px">
        <div class="flex justify-between">
          <div style="font-size: 16px; font-weight: bold; color: #081742">
            三防隐患问题排查统计
          </div>
          <div class="flex justify-start">
            <div>
              <el-radio-group
                v-model="model.YHdateType"
                size="small"
                @change="getSFYHPC"
              >
                <el-radio-button label="1">年</el-radio-button>
                <el-radio-button label="2">季</el-radio-button>
                <el-radio-button label="3">月</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div
          class="flex justify-around mt-3 pt-2"
          style="background: rgba(67, 165, 233, 0.05); height: 100px"
        >
          <div
            class="flex flex-col text-center"
            @click="jumpYH('三防隐患问题排查统计', '', '排查隐患数量', '')"
          >
            <div class="cenNum">
              {{ responseCountVo.total }}
            </div>
            <div>排查隐患数量</div>
          </div>
          <div
            class="flex flex-col text-center"
            @click="jumpYH('三防隐患问题排查统计', 1, '已整改隐患', '')"
          >
            <div class="cenNum">
              {{ responseCountVo.finishCount }}
            </div>
            <div>已整改隐患</div>
          </div>
          <div
            class="flex flex-col text-center"
            @click="jumpYH('三防隐患问题排查统计', 0, '未整改隐患', '')"
          >
            <div class="cenNum">
              {{ responseCountVo.notFinishCount }}
            </div>
            <div>未整改隐患</div>
          </div>
          <div class="flex flex-col text-center">
            <div class="cenNum">
              {{ responseCountVo.finishRatio }}
            </div>
            <div>整改完成率</div>
          </div>
        </div>
        <div>
          <div id="main1" class="mt-3" style="width: 100%; height: 300px"></div>
        </div>
        <div style="background: rgba(67, 165, 233, 0.05); height: 150px">
          <div class="pt-2">
            <el-divider>本次三防应急响应隐患整改情况</el-divider>
          </div>
          <div class="flex justify-around mt-2 pt-2">
            <div
              class="flex flex-col text-center"
              @click="
                jumpYH(
                  '三防隐患问题排查统计',
                  '',
                  '排查隐患数量',
                  thisResponseCountVo.responseId
                )
              "
            >
              <div class="cenNum">
                {{ thisResponseCountVo.total }}
              </div>
              <div>排查隐患数量</div>
            </div>
            <div
              class="flex flex-col text-center"
              @click="
                jumpYH(
                  '三防隐患问题排查统计',
                  1,
                  '已整改隐患',
                  thisResponseCountVo.responseId
                )
              "
            >
              <div class="cenNum">
                {{ thisResponseCountVo.finishCount }}
              </div>
              <div>已整改隐患</div>
            </div>
            <div
              class="flex flex-col text-center"
              @click="
                jumpYH(
                  '三防隐患问题排查统计',
                  0,
                  '未整改隐患',
                  thisResponseCountVo.responseId
                )
              "
            >
              <div class="cenNum">
                {{ thisResponseCountVo.notFinishCount }}
              </div>
              <div>未整改隐患</div>
            </div>
            <div class="flex flex-col text-center">
              <div class="cenNum">
                {{ thisResponseCountVo.finishRatio }}
              </div>
              <div>整改完成率</div>
            </div>
          </div>
        </div>
      </div>
      <div class="stCard" style="width: 100%; height: 350px">
        <div class="flex justify-between">
          <div style="font-size: 16px; font-weight: bold; color: #081742">
            三防图表录入统计
          </div>
          <div class="flex justify-start"></div>
        </div>
        <div
          class="flex justify-around mt-3 pt-2"
          style="background: rgba(67, 165, 233, 0.05); height: 100px"
        >
          <div class="flex flex-col text-center">
            <div class="cenNum">
              {{ HPchartCountVo.siteNum }}
            </div>
            <div>工点总数</div>
          </div>
          <div
            class="flex flex-col text-center"
            @click="jumpTB('三防图表录入统计', 0, '工点航拍图', '')"
          >
            <div class="cenNum">
              {{ HPchartCountVo.airPhotoNum }}
            </div>
            <div>工点航拍图</div>
          </div>
          <div
            class="flex flex-col text-center"
            @click="jumpTB('三防图表录入统计', 1, '周边排水图', '')"
          >
            <div class="cenNum">
              {{ HPchartCountVo.drainPhotoNum }}
            </div>
            <div>周边排水图</div>
          </div>
          <div
            class="flex flex-col text-center"
            @click="jumpTB('三防图表录入统计', 2, '防汛作战图', '')"
          >
            <div class="cenNum">
              {{ HPchartCountVo.floodPreventionPhotoNum }}
            </div>
            <div>防汛作战图</div>
          </div>
          <div
            class="flex flex-col text-center"
            @click="jumpTB('三防图表录入统计', 3, '三防明白卡', '')"
          >
            <div class="cenNum">
              {{ HPchartCountVo.clearPhotoNum }}
            </div>
            <div>三防明白卡</div>
          </div>
          <!-- <div class="flex flex-col text-center">
            <div class="cenNum">{{responseChartCountList.chartCountVo.siteNum}}</div>
            <div>完善度</div>
          </div> -->
        </div>
        <div style="background: rgba(67, 165, 233, 0.05); height: 150px">
          <div class="pt-2">
            <el-divider>本月更新情况</el-divider>
          </div>
          <div class="flex justify-around mt-2 pt-2">
            <div
              class="flex flex-col text-center"
              @click="jumpTB('三防图表录入统计', 0, '本月工点航拍图', 3)"
            >
              <div class="cenNum">
                {{ HPchartMonthCountVo.airPhotoNum }}
              </div>
              <div>工点航拍图</div>
            </div>
            <div
              class="flex flex-col text-center"
              @click="jumpTB('三防图表录入统计', 1, '本月周边排水图', 3)"
            >
              <div class="cenNum">
                {{ HPchartMonthCountVo.drainPhotoNum }}
              </div>
              <div>周边排水图</div>
            </div>
            <div
              class="flex flex-col text-center"
              @click="jumpTB('三防图表录入统计', 2, '本月防汛作战图', 3)"
            >
              <div class="cenNum">
                {{ HPchartMonthCountVo.floodPreventionPhotoNum }}
              </div>
              <div>防汛作战图</div>
            </div>
            <div
              class="flex flex-col text-center"
              @click="jumpTB('三防图表录入统计', 3, '本月三防明白卡', 3)"
            >
              <div class="cenNum">
                {{ HPchartMonthCountVo.clearPhotoNum }}
              </div>
              <div>三防明白卡</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右边 -->
    <div class="flex flex-col ml-2" style="width: 50%">
      <div class="stCard" style="width: 100%; height: 500px">
        <div class="flex justify-between">
          <div style="font-size: 16px; font-weight: bold; color: #081742">
            三防应急响应工作情况统计
          </div>
          <div class="flex justify-start">
            <div class="mr-2">
              <el-cascader
                size="small"
                ref="linecas"
                placeholder="选择线路工点"
                v-model="model.gzSite"
                :options="treeData"
                :props="props"
                filterable
                clearable
                collapse-tags
                @change="handleLineSiteChangeFrom"
              ></el-cascader>
            </div>
            <div>
              <el-radio-group
                v-model="model.gztimes"
                size="small"
                @change="getSFgzqk"
              >
                <el-radio-button label="1">年</el-radio-button>
                <el-radio-button label="2">季</el-radio-button>
                <el-radio-button label="3">月</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div
          class="flex justify-around mt-3 pt-2"
          style="background: rgba(67, 165, 233, 0.05); height: 100px"
        >
          <div class="flex flex-col text-center">
            <div class="cenNum">
              {{ YJresponseCountVo.total }}
            </div>
            <div>应完成次数</div>
          </div>
          <div class="flex flex-col text-center">
            <div class="cenNum">
              {{ YJresponseCountVo.finishCount }}
            </div>
            <div>已完成次数</div>
          </div>
          <div class="flex flex-col text-center">
            <div class="cenNum">
              {{ YJresponseCountVo.notFinishCount }}
            </div>
            <div>未完成次数</div>
          </div>
          <div class="flex flex-col text-center">
            <div class="cenNum">
              {{ YJresponseCountVo.finishRatio }}
            </div>
            <div>完成率</div>
          </div>
        </div>
        <div>
          <div class="pt-2">
            <el-divider>最新三防应急响应完成情况</el-divider>
          </div>
          <div class="flex justify-around">
            <div
              id="main2"
              class="mt-3"
              style="width: 30%; height: 200px"
            ></div>

            <div
              id="main3"
              class="mt-3"
              style="width: 30%; height: 200px"
            ></div>

            <div
              id="main4"
              class="mt-3"
              style="width: 30%; height: 200px"
            ></div>
          </div>
          <div class="flex justify-around">
            <div class="mt-3 text-center" style="width: 30%; height: 50px">
              《三防应急检查表》
            </div>

            <div class="mt-3 text-center" style="width: 30%; height: 50px">
              《广州市交通运输系统三防工作统计表》
            </div>

            <div class="mt-3 text-center" style="width: 30%; height: 50px">
              《三防工作简报》(施工单位)
            </div>
          </div>
        </div>
      </div>
      <div class="stCard" style="width: 100%; height: 360px">
        <div class="flex justify-between">
          <div style="font-size: 16px; font-weight: bold; color: #081742">
            防汛关注重点统计
          </div>
          <div class="flex justify-start"></div>
        </div>

        <div>
          <div id="main5" class="mt-3" style="width: 100%; height: 300px"></div>
        </div>
      </div>
      <!-- <div class="stCard" style="width: 100%; height: 470px">
        <div class="flex justify-between">
          <div style="font-size: 16px; font-weight: bold; color: #081742">
            重要抢险设备统计
          </div>
          <div class="flex justify-start"></div>
        </div>
        <div>
          <div id="main6" class="mt-3" style="width: 100%; height: 400px"></div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  selectMessageCount,
  responseCountDepartment,
  responseQuestionCount,
  responseChartCount,
  responseCurrentCount,
  proofCount,
} from "@/api/responseTem";
export default {
  components: {},
  props: {},
  data() {
    return {
      props: {
        multiple: true,
        children: "children",
        label: "name",
        value: "uuid",
      },
      model: {
        lineSite: [],
        times: "1",
        dateType: "1",
        YHdateType: "1",
        gztimes: "1",
        gzSite: [],
      },
      treeData: [],
      lineId: "",
      siteId: "",
      selectMessageCountList: {},
      responseCountDepartmentList: {},
      responseQuestionCountList: {},
      thisResponseCountVo: {},
      responseCountVo: {},
      gzrResponseCountVo: {},
      totals: 0,
      responseChartCountList: {},
      HPchartCountVo: {},
      HPchartMonthCountVo: {},
      gzSite: [],
      responseCurrentCountList: {},
      YJresponseCountVo: {},
    };
  },
  watch: {},
  methods: {
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    handleLineSiteChangeFrom(val) {
      console.log(val);

      this.getSFgzqk();
    },
    //三防代办统计
    getSFDB() {
      let params = {
        dateType: this.model.times,
        messageSource: 2,
        noticeCategory: 1,
        siteUuidList: [],
      };
      selectMessageCount(params).then((res) => {
        if (res.code == 0) {
          this.selectMessageCountList = res.data;
        }
      });
    },
    //三防应急响应发布统计
    getSFYJXY() {
      let params = {
        dateType: this.model.dateType,
      };
      responseCountDepartment(params).then((res) => {
        if (res.code == 0) {
          this.responseCountDepartmentList = res.data;
          let series = [];
          var dataParamsOne = [];
          this.responseCountDepartmentList.responseRecordCountVoList.forEach(
            (el) => {
              dataParamsOne = el.numList;
              series.push({
                name: el.label,
                type: "bar",
                stack: "Ad",
                emphasis: {
                  focus: "series",
                },
                label: {
                  show: true,
                  color: "#ffffff",
                  formatter: function(params) {
                    const a = params.value;
                    if (a > 0) {
                      return a;
                    } else {
                      return "";
                    }
                  },
                },
                data: el.numList,
                comsssss: el,
              });
            }
          );
          var listDataOne = [];
          dataParamsOne.forEach((item) => {
            listDataOne.push(0);
          });
          series.push({
            name: "",
            type: "bar",
            stack: "Ad",
            emphasis: {
              focus: "series",
            },
            label: {
              show: true,
              color: "black",
              position: "top",
              fontWeight: "bold",
              fontSize: 18,
            },
            data: listDataOne,
          });
          this.totals = 0;
          this.responseCountDepartmentList.resultList.map((item) => {
            this.totals += item.num;
          });
          function getSum(params) {
            let dataValue = 0;
            for (let i = 0; i < series.length; i++) {
              dataValue += series[i].data[params.dataIndex];
            }
            return dataValue;
          }
          series[series.length - 1].label.formatter = getSum;

          setTimeout(() => {
            this.getCharts(
              document.getElementById("main"),
              this.responseCountDepartmentList.dataList,
              series,
              "应急",
              this.responseCountDepartmentList.dataList,
              this.responseCountDepartmentList.responseRecordCountVoList
            );
          }, 1000);
        }
      });
    },
    //三防隐患问题排查统计
    getSFYHPC() {
      let params = {
        dateType: this.model.YHdateType,
      };
      responseQuestionCount(params).then((res) => {
        if (res.code == 0) {
          this.responseQuestionCountList = res.data;
          this.responseCountVo = this.responseQuestionCountList.responseCountVo;
          this.thisResponseCountVo = this.responseQuestionCountList.thisResponseCountVo;
          let xData = [];
          this.responseQuestionCountList.dataList.forEach((el) => {
            xData.push(el.lineName);
          });
          let series = [];
          var dataParamsTwo = [];
          this.responseQuestionCountList.responseRecordCountVoList.forEach(
            (el) => {
              dataParamsTwo = el.numList;
              series.push({
                name: el.label,
                type: "bar",
                stack: "Ad",
                emphasis: {
                  focus: "series",
                },
                label: {
                  show: true,
                  color: "#ffffff",
                  formatter: function(params) {
                    const a = params.value;
                    if (a > 0) {
                      return a;
                    } else {
                      return "";
                    }
                  },
                },
                data: el.numList,
              });
            }
          );
          var listDataTwo = [];
          dataParamsTwo.forEach((item) => {
            listDataTwo.push(0);
          });
          series.push({
            name: "",
            type: "bar",
            stack: "Ad",
            emphasis: {
              focus: "series",
            },
            label: {
              show: true,
              color: "black",
              position: "top",
              fontWeight: "bold",
              fontSize: 18,
            },
            data: listDataTwo,
          });
          function getSum(params) {
            let dataValue = 0;
            for (let i = 0; i < series.length; i++) {
              dataValue += series[i].data[params.dataIndex];
            }
            return dataValue;
          }
          series[series.length - 1].label.formatter = getSum;
          setTimeout(() => {
            this.getCharts(
              document.getElementById("main1"),
              xData,
              series,
              "隐患",
              this.responseQuestionCountList.dataList,
              this.responseQuestionCountList.responseRecordCountVoList
            );
          }, 1000);
        }
      });
    },

    //三防图标录入统计
    getSFTB() {
      responseChartCount().then((res) => {
        if (res.code == 0) {
          this.responseChartCountList = res.data;
          this.HPchartCountVo = this.responseChartCountList.chartCountVo;
          this.HPchartMonthCountVo = this.responseChartCountList.chartMonthCountVo;
        }
      });
    },
    //三防应急响应工作情况统计
    getSFgzqk() {
      let arr = [];
      if (this.model.gzSite.length > 0) {
        this.model.gzSite.forEach((el) => {
          console.log(el);
          arr.push(el[el.length - 1]);
        });
      } else {
        arr = [];
      }

      let params = {
        dateType: this.model.gztimes,
        siteUuidList: arr,
      };
      responseCurrentCount(params).then((res) => {
        if (res.code == 0) {
          this.responseCurrentCountList = res.data;
          this.YJresponseCountVo = this.responseCurrentCountList.responseCountVo;

          setTimeout(() => {
            this.getCharts2(
              document.getElementById("main2"),
              this.responseCurrentCountList.threeCheckListCountVo.total,
              "《三防应急检查表》",
              this.responseCurrentCountList.threeCheckListCountVo.finishCount,
              this.responseCurrentCountList.threeCheckListCountVo
                .notFinishCount,
              this.responseCurrentCountList.threeCheckListCountVo
            );
            this.getCharts2(
              document.getElementById("main3"),
              this.responseCurrentCountList.statisticFormCountVo.total,
              "《广州市交通运输系统三防工作统计表》",
              this.responseCurrentCountList.statisticFormCountVo.finishCount,
              this.responseCurrentCountList.statisticFormCountVo.notFinishCount,
              this.responseCurrentCountList.statisticFormCountVo
            );
            this.getCharts2(
              document.getElementById("main4"),
              this.responseCurrentCountList.threePreventionWorkCountVo.total,
              "《三防工作简报》(施工单位)",
              this.responseCurrentCountList.threePreventionWorkCountVo
                .finishCount,
              this.responseCurrentCountList.threePreventionWorkCountVo
                .notFinishCount,
              this.responseCurrentCountList.threePreventionWorkCountVo
            );
          }, 1000);
        }
      });
    },
    //防汛关注重点统计
    getFXGZ() {
      proofCount().then((res) => {
        let series = [];
        if (res.code == 0) {
          this.proofCountList = res.data;
          var dataParamsThree = [];

          this.proofCountList.proofCountVos.forEach((el) => {
            dataParamsThree = el.numList;

            series.push({
              name: el.label,
              type: "bar",
              stack: "Ad",
              emphasis: {
                focus: "series",
              },
              label: {
                show: true,
                color: "#ffffff",
                formatter: function(params) {
                  const a = params.value;
                  if (a > 0) {
                    return a;
                  } else {
                    return "";
                  }
                },
              },
              data: el.numList,
            });
          });
          var listDataThree = [];
          dataParamsThree.forEach((item) => {
            listDataThree.push(0);
          });
          series.push({
            name: "",
            type: "bar",
            stack: "Ad",
            emphasis: {
              focus: "series",
            },
            label: {
              show: true,
              color: "black",
              position: "top",
              fontWeight: "bold",
              fontSize: 18,
            },
            data: listDataThree,
          });
          function getSum(params) {
            let dataValue = 0;
            for (let i = 0; i < series.length; i++) {
              dataValue += series[i].data[params.dataIndex];
            }
            return dataValue;
          }
          series[series.length - 1].label.formatter = getSum;
          let xdata = [];
          this.proofCountList.dataSet.forEach((el) => {
            xdata.push(el.lineName);
          });
          setTimeout(() => {
            this.getCharts(
              document.getElementById("main5"),
              xdata,
              series,
              "防汛",
              this.proofCountList.dataSet,
              this.proofCountList.proofCountVos
            );
          }, 1000);
        }
      });
    },
    //获取统计数据
    getdata() {
      this.getSFDB();
      this.getSFYJXY();
      this.getSFYHPC();
      this.getSFTB();
      this.getSFgzqk();
      this.getFXGZ();
    },
    getCharts(val, data, series, type, xlist, ylist) {
      console.log("data", data);
      echarts.dispose(val);
      var Title = {};
      if (ylist.length > 0) {
        Title = {
          text: "",
          x: "center",
          top: 35,
          textStyle: {
            fontSize: "15",
            color: "#333",
          },
        };
      } else {
        Title = {
          text: "暂无数据",
          left: "center",
          top: "center",
          textStyle: {
            fontSize: "35",
            color: "grey",
          },
        };
      }
      var myChart = echarts.init(val);
      var option;

      option = {
        title: Title,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: data,
            axisLabel: {
              interval: 0,
              formatter: function(value) {
                var str = "";
                var num = 5; //每行显示字数
                var valLength = value.length; //该项x轴字数
                var rowNum = Math.ceil(valLength / num); // 行数

                if (rowNum > 1) {
                  for (var i = 0; i < rowNum; i++) {
                    var temp = "";
                    var start = i * num;
                    var end = start + num;

                    temp = value.substring(start, end) + "\n";
                    str += temp;
                  }
                  return str;
                } else {
                  return value;
                }
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: series,
      };

      option && myChart.setOption(option);
      let that = this;
      myChart.on("click", function(params) {
        if (type == "应急") {
          console.log(params);
          let ysd = {};
          ylist.forEach((el) => {
            if (el.label == params.seriesName) {
              ysd = el;
            }
          });
          that.jumpXY(
            "三防应急发布统计",
            "",
            params.seriesName,
            ysd.type,
            params
          );
        } else if (type == "隐患") {
          let isd = {};
          let ysd = {};
          xlist.forEach((el) => {
            if (el.lineName == params.name) {
              isd = el;
            }
          });
          ylist.forEach((el) => {
            if (el.label == params.seriesName) {
              ysd = el;
            }
          });
          that.jumpYH(
            "三防隐患问题排查统计",
            "",
            params.seriesName,
            "",
            isd.lineUuid,
            ysd.type
          );
        } else if (type == "防汛") {
          console.log(data);
          let isd = {};
          let ysd = {};
          xlist.forEach((el) => {
            if (el.lineName == params.name) {
              isd = el;
            }
          });
          ylist.forEach((el) => {
            if (el.label == params.seriesName) {
              ysd = el;
            }
          });
          that.jumpGZ(
            "防汛关注重点统计",
            isd.lineId,
            params.seriesName,
            ysd.type
          );
          console.log(123123, isd.lineId);
        }
      });
    },
    getCharts2(val, texts, names, overdata, undata, lists) {
      var myChart = echarts.init(val);
      var option;

      option = {
        title: [
          {
            // 第一个圆环标题
            text: texts, // 主标题
            textStyle: {
              // 主标题样式
              color: "rgba(8, 23, 66, 1)",
              fontWeight: "bold",
              fontSize: 24,
            },
            left: "48%", // 定位到适合的位置
            top: "35%", // 定位到适合的位置
            subtext: "应完成", // 副标题
            subtextStyle: {
              // 副标题样式
              color: "rgba(8, 23, 66, 0.6)",
              fontSize: 16,
              fontWeight: "bold",
            },
            textAlign: "center",
          },
        ],
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: names,
            type: "pie",
            radius: ["50%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: 40,
            //     fontWeight: "bold",
            //   },
            // },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: overdata,
                name: "已完成",
              },
              {
                value: undata,
                name: "未完成",
              },
            ],
            itemStyle: {
              normal: {
                color: function(colors) {
                  var colorList = [
                    "rgba(23, 145, 255, 1)",
                    "rgba(8, 23, 66, 0.1)",
                  ];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
      let that = this;
      myChart.on("click", function(params) {
        console.log(params);
        let arr = [];
        if (that.model.gzSite.length > 0) {
          that.model.gzSite.forEach((el) => {
            console.log(el);
            arr.push(el[el.length - 1]);
          });
        } else {
          arr = [];
        }
        that.jumpYJ(
          "三防应急响应工作情况统计",
          params.name,
          params.seriesName,
          arr,
          lists.responseId,
          lists.reportNameId
        );
      });
    },
    getCharts3(val) {
      var myChart = echarts.init(val);
      var option;

      option = {
        title: [
          {
            // 第一个圆环标题
            text: "100", // 主标题
            textStyle: {
              // 主标题样式
              color: "rgba(8, 23, 66, 1)",
              fontWeight: "bold",
              fontSize: 24,
            },
            left: "29%", // 定位到适合的位置
            top: "42%", // 定位到适合的位置
            subtext: "抢险设备总数", // 副标题
            subtextStyle: {
              // 副标题样式
              color: "rgba(8, 23, 66, 0.6)",
              fontSize: 16,
              fontWeight: "bold",
            },
            textAlign: "center",
          },
        ],
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          x: "right", //可设定图例在左、右、居中
          y: "center", //可设定图例在上、下、居中
          padding: [0, 250, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          align: "left",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            center: ["30%", "50%"],
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 1048, name: "Search Engine" },
              { value: 735, name: "Direct" },
              { value: 580, name: "Email" },
              { value: 484, name: "Union Ads" },
              { value: 300, name: "Video Ads" },
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    },
    jumps(name, executorStatus, tabName) {
      let params = {
        name: name,
        dateType: this.model.times,
        executorStatus: executorStatus,
        tabName: tabName,
      };
      this.$router.push({
        name: "PreventionstatisticsTables",
        params: params,
      });
    },
    jumpYH(name, status, tabName, responseId, lineUuid, type) {
      let params = {
        name: name,
        dateType: this.model.YHdateType,
        status: status,
        tabName: tabName,
        responseId: responseId,
        lineUuid: lineUuid,
        type: type,
      };
      this.$router.push({
        name: "PreventionstatisticsTables",
        params: params,
      });
    },
    jumpTB(name, status, tabName, dateType) {
      let params = {
        name: name,
        dateType: dateType,
        status: status,
        tabName: tabName,
      };
      this.$router.push({
        name: "PreventionstatisticsTables",
        params: params,
      });
    },
    jumpXY(name, currentLevel, tabName, type, YJTJ) {
      let params = {
        name: name,
        dateType: this.model.dateType,
        tabName: tabName,
        type: type,
        currentLevel: currentLevel,
        YJTJ,
      };
      this.$router.push({
        name: "PreventionstatisticsTables",
        params: params,
      });
    },
    jumpGZ(name, lineId, tabName, type) {
      let params = {
        name: name,
        dateType: this.model.dateType,
        tabName: tabName,
        type: type,
        lineId: lineId,
      };
      this.$router.push({
        name: "PreventionstatisticsTables",
        params: params,
      });
    },
    jumpYJ(
      name,
      finishStatus,
      tabName,
      siteUuidList,
      responseRecordId,
      reportType
    ) {
      let params = {
        name: name,
        tabName: tabName,
        finishStatus: finishStatus == "已完成" ? 1 : 0,
        siteUuidList: siteUuidList,
        responseRecordId: responseRecordId,
        reportType: reportType,
      };
      this.$router.push({
        name: "PreventionstatisticsTables",
        params: params,
      });
    },
  },
  mounted() {
    this.getdata();
    this.getLineSiteTreeData();

    // this.getCharts3(document.getElementById("main6"));
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 700px;
  overflow: auto;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
::v-deep .el-table:before {
  height: 0px;
}
.stCard {
  margin: 0 20px 10px 0;
  padding: 20px 20px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.cenCard {
  padding: 20px 20px;
  width: 24%;
  height: 100px;
}
.cenNum {
  font-size: 28px;
  font-family: Arial;
  font-weight: bold;
  color: #081742;
}
</style>
