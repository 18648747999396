<template>
  <div class="min-h-full bg-white">
    <div class="flex justify-between p-4">
      <el-row class="flex ">
        <propver
          class="mr-2"
          :data="comList"
          :defaultProps="props"
          :chooseName="chooses"
          :pid="statistics.puuid"
          :placeholder="'请选择单位/部门'"
          @deptTreeCurr="deptTreeCurrentChangeHandle"
        ></propver>
        <el-input
          class="mr-2"
          v-model="page.name"
          placeholder="用户账号或用户姓名"
          style="width: 180px"
        ></el-input>
        <el-button type="primary" @click="findData">搜索</el-button>
        <el-button type="primary" @click="lead" v-has="'user:lead'"
          >导入</el-button
        >
        <el-button
          type="primary"
          @click="leaddownload"
          v-has="'user:leadDownload'"
          >下载导入模板</el-button
        >
      </el-row>
      <span>
        <el-button type="primary" @click="opAdd" v-has="'user:add'"
          >新增用户</el-button
        >
      </span>
    </div>
    <div class="px-4">
      <el-table v-loading="tableloading" :data="list" style="width: 100%">
        <el-table-column prop="userName" align="center" label="用户账号">
        </el-table-column>
        <el-table-column prop="realName" align="center" label="用户姓名">
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="手机号">
        </el-table-column>
        <el-table-column prop="companyName" align="center" label="所属单位">
        </el-table-column>
        <el-table-column prop="departmentName" align="center" label="所属部门">
        </el-table-column>
        <el-table-column prop="roleId" label="所属角色" align="center">
          <template #default="scope">
            <ul>
              <li v-for="item of scope.row.roles" :key="item.id">
                {{ item.roleName }}
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="状态">
          <template #default="scope">
            {{ scope.row.state == 0 ? "正常" : "限制登录" }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <div class="flex justify-around cursor-pointer">
              <el-button type="text" size="small" @click="userInfo(scope.row)"
                >详情
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="opUpdata(scope.row)"
                v-has="'user:edit'"
                >修改
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="userDelete(scope.row)"
                v-has="'user:del'"
                >删除
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="userLock(scope.row)"
                v-show="scope.row.state == 0"
                v-has="'user:lock'"
                >锁定
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="userUnlock(scope.row)"
                v-show="scope.row.state != 0"
                v-has="'user:unlock'"
                >解锁
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex justify-center mt-4">
        <el-pagination
          background
          @current-change="pageChange"
          :current-page.sync="page.pageNo"
          :page-size="page.pageSize"
          layout="prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </div>
    <importForm ref="importForms"></importForm>
    <AddUser
      v-if="addDialog.show"
      :parentData="addDialog"
      @offIt="offDia"
    ></AddUser>
    <InfoUser
      ref="infoModal"
      :resourceAll="resourceAll"
      @offIt="offDia"
    ></InfoUser>
  </div>
</template>

<script>
import {
  // getComs,
  // getDepts,
  getAccounts,
  getAccountInfo,
  deleteAccount,
  lockUser,
  unlockUser,
} from "@/api/ucenter";
import { apermission, resourceAll } from "@/api/resource";
import { getUserDeptList } from "@/api/org";
import propver from "@/components/propver/index.vue";

import AddUser from "../Users/userForm";
import InfoUser from "../Users/infoUser";
import importForm from "./modules/importForm.vue";
import { userDownload } from "@/api/ucenter";

export default {
  name: "userList",
  data() {
    return {
      list: [],
      user: JSON.parse(localStorage.user),
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 1,
        name: "",
        companyId: "",
        deptId: "",
      },
      comList: [],
      deptList: [],
      addDialog: {
        show: false,
        data: {},
        tree: [],
        title: "",
      },
      infoDialog: {
        show: false,
        data: {},
        tree: [],
      },
      tableloading: true,
      findparams: {},
      resourceAll: [],
      props: {
        value: "uuid",
        label: "name",
        // label: "companyName",
        children: "children",
        disabled: "disabledS",
      },
      chooses: "",
      statistics: {},
    };
  },
  mounted() {
    this.findparams = {
      companyId: this.page.companyId == "" ? "-" : this.page.companyId,
      deptId: this.page.deptId == "" ? "-" : this.page.deptId,
      roleId: "-",
      username: this.page.name == "" ? "-" : this.page.name,
      state: "-",
      type: "-",
      pageNo: this.page.pageNo,
      pageSize: this.page.pageSize,
    };
    this.getTableList();
    // this.getAllComs();
    this.getdeptlist();
    this.getResourceAll();
  },
  methods: {
    getdeptlist() {
      let params = {
        name: "",
        category: "",
      };
      getUserDeptList(params)
        .then((res) => {
          if (res.code === 0) {
            res.data.forEach((item) => {
              item.disabledS = true;
            });
            this.comList = res.data || [];
          }
        })
        .catch((res) => {
          this.$message({
            message: res.message,
            type: "error",
          });
        });
    },
    //上级树选择
    deptTreeCurrentChangeHandle(data) {
      console.log(data);
      if (data.uuid) {
        this.chooses = data.name;
        this.statistics.puuid = data.uuid;
        if (data.vendorOrDept == 0) {
          this.page.companyId = data.uuid ? data.uuid : "-";
          this.page.deptId = "-";
        } else if (data.vendorOrDept == 1) {
          this.page.deptId = data.uuid ? data.uuid : "-";
          this.page.companyId = "-";
        }
      } else {
        this.page.companyId = "-";
        this.page.deptId = "-";
        //  this.chooses =''
        //   this.statistics.puuid=""
      }
    },
    //获取用户列表
    async getTableList() {
      this.tableloading = true;
      let res = await getAccounts(this.findparams);
      if (res.code === 0) {
        this.tableloading = false;
        this.list = res.data.records || [];
        this.page.total = res.data.count;
      }
    },
    //搜索
    findData() {
      this.page.pageNo = 1;
      this.findparams = {
        companyId: this.page.companyId == "" ? "-" : this.page.companyId,
        deptId: this.page.deptId == "" ? "-" : this.page.deptId,
        roleId: "-",
        username: this.page.name == "" ? "-" : this.page.name,
        state: "-",
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      };
      this.getTableList();
    },
    //翻页方法
    async pageChange() {
      this.findparams.pageNo = this.page.pageNo;
      this.getTableList();
    },
    //获取所有单位
    // async getAllComs() {
    //   let res = await getComs();
    //   if (res.code === 0) {
    //     console.log(res.data);
    //     this.comList = res.data || [];
    //     //默认加载第一个单位下的部门 后期不需要可以删除
    //     // this.comChange(this.comList[0].id);
    //   }
    // },
    //根据单位查询部门
    // async comChange(val) {
    //   if (val != "") {
    //     let res = await getDepts(val);
    //     if (res.code === 0) {
    //       this.deptList = res.data || [];
    //     }
    //   } else {
    //     this.page.deptId = "";
    //     this.deptList = [];
    //   }
    // },
    //根据用户id获取详情
    async userInfo(data) {
      this.$refs.infoModal.handleOpen(data);
    },
    async getResourceAll() {
      let res = await resourceAll();
      this.resourceAll = res.data;
    },
    //打开修改弹窗
    async opUpdata(data) {
      let res = await getAccountInfo(data.id);
      if (res.code === 0) {
        console.log(res);
        this.addDialog.data = res.data;
      }
      console.log(res);
      let redata = await apermission(data.id);
      if (redata.code === 0) {
        this.addDialog.show = true;
        this.addDialog.title = "修改用户";
        this.addDialog.tree = redata.data;
      }
    },
    //解锁
    async unlockfun(data) {
      let delres = await unlockUser(data);
      if (delres.code === 0) {
        this.$message({
          type: "success",
          message: "解锁成功!",
        });
        this.getTableList();
      } else {
        this.$message.error(delres.message);
      }
    },
    //解锁确认
    async userUnlock(data) {
      this.$confirm("确认解锁该用户？解锁后用户可正常登录", "解锁", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.unlockfun(data);
      });
    },
    //锁定用户
    async lockfun(data) {
      let delres = await lockUser(data);
      if (delres.code === 0) {
        this.$message({
          type: "success",
          message: "锁定成功!",
        });
        this.getTableList();
      } else {
        this.$message.error(delres.message);
      }
    },
    //锁定用户确认
    async userLock(data) {
      this.$confirm("确认锁定该用户？锁定后用户不能登录", "锁定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.lockfun(data);
      });
    },
    //删除用户
    async deletefun(data) {
      let delres = await deleteAccount(data.id);
      if (delres.code === 0) {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getTableList();
      } else {
        this.$message.error(delres.message);
      }
    },
    //删除用户确认
    async userDelete(data) {
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.deletefun(data);
      });
    },
    //打开新增弹窗
    async opAdd() {
      this.addDialog = {
        show: true,
        data: {},
        tree: [],
        title: "新增用户",
      };
    },
    // 导入
    lead() {
      this.$nextTick(() => {
        this.$refs.importForms.reset();
        this.$refs.importForms.handleImport();
      });
    },
    // 导入模板下载
    leaddownload() {
      let parms = {};
      userDownload(parms).then((res) => {
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel",
        });
        // 3.创建一个临时的url指向blob对象
        let url = window.URL.createObjectURL(blob);
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "导入模板下载_.xlsx"; //自定义导出文件名
        a.click();
        this.checkList = [];
        this.timesList = [];
        this.dialogVisible = false;
      });
    },
    //关闭窗口
    async offDia(data) {
      this.addDialog.show = false;
      if (data) {
        this.getTableList();
      }
    },
  },

  components: { AddUser, InfoUser, propver, importForm },
};
</script>

<style scoped>
.el-pagination__jump {
  margin-top: -6px;
}
</style>
