<template>
  <div class="box-container">
    <div class="corner-wrap">
      <div class="corner corner-left-top"></div>
      <div class="corner corner-right-top"></div>
      <div class="corner corner-right-bottom"></div>
      <div class="corner corner-left-bottom"></div>
    </div>
    <div class="main-wrapper 2xl:p-4 md:p-2 xl:p-3">
      <div class="flex h-full w-full flex-col">
        <div
          class="header flex items-center bg-gradient-to-r from-index-title-bg 2xl:my-4 xl:my-2 md:my-2"
          :class="{ 'justify-between': titleRight !== '' }"
          v-if="!hideTitle"
        >
          <h3
          
            class="header-title flex items-center 2xl:text-lg xl:text-base md:text-xs"
            :title="title"
          >
          <div class="conteq"></div>
            <span style="width:200px; overflow: hidden;text-overflow:ellipsis;white-space: nowrap;display: block;">{{ title }}</span>
            <span  @click="expandMore"
            v-if="titleRight !== ''" class="header-title-more"> {{ titleRight }}</span>
          </h3>
          <h4
            class="header-title-more 2xl:ml-32"
           
          >
           
          </h4>
        </div>
        <div class="main-content flex-1 overflow-y-scroll">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "默认标题",
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    titleRight: {
      type: String,
      default: "",
    },
  },
  methods: {
    expandMore() {
      this.$emit("expand-more", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.box-container {
  width: 100%;
  height: 100%;
  background: rgba(8, 23, 66, 0.9);
  border: 1px solid #002e75;
  box-shadow: 0px 0px 20px 6px #002e75 inset;
  position: relative;
  .corner-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    .corner {
      width: 16px;
      height: 16px;
    }
    .corner-left-top {
      border-left: 2px solid #43a5e9;
      border-top: 2px solid #43a5e9;
      position: absolute;
      left: 0;
      top: 0;
    }
    .corner-right-top {
      border-right: 2px solid #43a5e9;
      border-top: 2px solid #43a5e9;
      position: absolute;
      right: 0;
      top: 0;
    }
    .corner-right-bottom {
      border-right: 2px solid #43a5e9;
      border-bottom: 2px solid #43a5e9;
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .corner-left-bottom {
      border-left: 2px solid #43a5e9;
      border-bottom: 2px solid #43a5e9;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .main-wrapper {
    position: absolute;
    z-index: 10;
    padding-top: 0;
    width: 100%;
    height: 100%;
    & > div {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }
    .header {
      width:100%;
      height: 32px;
      .header-title {
      
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        .conteq {
          display: block;
          content: "";
          width: 12px;
          height: 14px;
          margin-left: 11px;
          margin-right: 7px;
          // background-color: rgb(243, 11, 11);
          background-image: url(../../assets/box-icon.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center;
        }
         .header-title-more {
          width: 90px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #43a5ea;
      }
      }
     
      .title-right {
        // text-align: right;
      }
    }
  }
  .main-content {
    height: calc(100% - 67px);
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}
.flex {
  // overflow: hidden !important;
}
</style>
