<template>
  <div>
    <module
      :type="disclosureType"
      label="设计"
      :rowData="rowData"
      :isAdd="unitType == 'design' ? true : false"
      :list="designData"
      @SubmitForm="SubmitForm"
    ></module>
    <module
      :type="disclosureType"
      label="施工"
      :rowData="rowData"
      :isAdd="unitType == 'construct' ? true : false"
      :list="constructData"
      @SubmitForm="SubmitForm"
    ></module>
    <module
      :type="disclosureType"
      label="勘察"
      :rowData="rowData"
      :isAdd="unitType == 'survey' ? true : false"
      :list="surveyData"
      @SubmitForm="SubmitForm"
    ></module>
    <div class="dialogFooter" v-if="disclosureType != 'view'">
      <el-button size="medium" @click="submit">取消</el-button>
    </div>
  </div>
</template>

<script>
import { riskBottomList } from "@/api/riskManage";
import module from "./module";
export default {
  components: {
    // FileUpload
    module
  },
  props: {
    disclosureType: {
      type: String,
      default: ""
    },
    rowData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ruleForm: {},
      designUnitList: [],
      fileList: [],
      unitType: "",
      isShow: false,
      designData: [],
      constructData: [],
      surveyData: [],
      submitList: [],
    };
  },
  mounted() {
    this.getData();
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      this.unitType = user.companyCategory;
    }
  },
  methods: {
    getData() {
      const _this = this;
      riskBottomList({
        riskMasterUuid: _this.rowData.riskMasterUuid,
        riskDeafUuid: _this.rowData.riskDeafUuid
      }).then(res => {
        if (res.code == 0 && res.data.length > 0) {
          res.data.sort(function(a, b) {
            return b.created < a.created ? -1 : 1;
          });
          res.data.forEach(el => {
            el["disabled"] = false;
            if (el.companyTypeCode == "design") {
              _this.designData.push(el);
            } else if (el.companyTypeCode == "construct") {
              _this.constructData.push(el);
            } else if (el.companyTypeCode == "survey") {
              _this.surveyData.push(el);
            }
          });
          if (this.designData.length > 0) {
            this.designData[this.designData.length - 1].disabled = true;
          }
          if (this.constructData.length > 0) {
            this.constructData[this.constructData.length - 1].disabled = true;
          }
          if (this.surveyData.length > 0) {
            this.surveyData[this.surveyData.length - 1].disabled = true;
          }
          // this.surveyData[this.surveyData.length - 1].disabled = true;
        }
        console.log(this.constructData, "施工单位交底数据");
      });
    },
    // 附件上传
    handleFileUpload(e) {
      this.fileList.push({
        originalName: e.name,
        url: e.url
      });
    },
    handleFileDel(e) {
      let index = this.fileList.findIndex(v => v.url === e.url);
      this.fileList.splice(index, 1);
    },
    handleEx() {
      this.isShow = !this.isShow;
    },
    submit() {
      // console.log(this.submitList, "this.submitList");
      this.$emit("handlerCloseDia");
    },
    SubmitForm() {
      this.designData = [];
      this.constructData = [];
      this.surveyData = [];
      this.getData();
    }
    // designSubmit(obj) {
    //   this.submitList.push({ ...obj });
    //   console.log(obj, "designSubmit");
    // },
    // constructSubmit(obj) {
    //   this.submitList.push({ ...obj });
    //   console.log(obj, "constructSubmit");
    // },
    // surveySubmit(obj) {
    //   this.submitList.push({ ...obj });
    //   console.log(obj, "surveySubmit");
    // }
  }
};
</script>

<style lang="scss" scoped>
.riskDialogTitle {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
  }
  .el-button--mini,
  .el-button--mini.is-round {
    padding: 5px 8px;
  }
}
.form-title {
  span {
    margin-left: 10px;
  }
}
.preview span {
  color: #3fa5e9;
  cursor: pointer;
}
.xinxiCon li {
  margin-bottom: 15px;
}
.zhankaiBtn {
  width: 100%;
  padding: 3px 0;
  border: 1px solid #bdbdbd;
  margin: 8px 0 15px;
  text-align: center;
  cursor: pointer;
  .el-icon-d-arrow-left {
    transform: rotate(-90deg);
    margin-left: 5px;
  }
  .upArrow {
    transform: rotate(90deg);
  }
}
.dialogFooter {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
</style>
