<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" :title="title">
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="100px"
      class="demo-model"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="model.title" placeholder="请输入标题"></el-input>
      </el-form-item>

      <el-form-item label="类型" prop="ruleType">
        <el-select v-model="model.ruleType" placeholder="选择类型" clearable>
          <el-option
            :label="data.label"
            :value="data.id"
            v-for="(data, index) in typeList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="简要" prop="summary">
        <el-input v-model="model.summary" placeholder="请输入简要"></el-input>
      </el-form-item>
      <el-form-item label="附件" >
        <FileUploader
          @uploadChange="handleFileUpload"
          @delChange="handleFileDel"
          :value="fileList"
        ></FileUploader>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import FileUploader from "@/components/Uploader/FileUploader.vue";
import { ruleSystemAdd, ruleSystemUpdate } from "@/api/response";
export default {
  components: {
    systemModal,
    FileUploader,
  },
  props: { typeList: {} },
  data() {
    return {
      title: "新增",
      model: {
        name: "",
        address: "",
        type: "",
        level: "",
        time: "",
        imageUrl: "",
      },
      fileList: [],
      loading: false,
      rules: {
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        ruleType: [{ required: true, message: "请选择", trigger: "change" }],
        summary: [{ required: true, message: "请输入", trigger: "blur" }],
        fullPath: [{ required: true, message: "请上传", trigger: "change" }],
      },
      lineName: [],
    };
  },
  mounted() {},
  methods: {
    handleAdd(record) {
      if (record) {
        this.title = "编辑";
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
        this.fileList = [
          {
            name: this.model.realName,
            url: this.model.fullPath,
          },
        ];
      } else {
        this.title = "新增";
        this.fileList = [];
        this.model = {
          title: "",
          summary: "",
          ruleType: "",
        };
      }
      this.handleEdit();
    },
    handleName(e) {
      console.log(e);
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    getlabel() {
      if (this.model.lineSite.length != 0) {
        this.lineName = this.$refs["linecas"].getCheckedNodes()[0].pathLabels;
      }
      console.log(this.lineName);
    },
    urlchange(url) {
      this.model.imageUrl = url;
    },
    handleFileUpload({ name, url }) {
      console.log(name);
      this.model.realName = name;
      this.model.fullPath = url;
    },
    handleFileDel({ name, url }) {
      console.log(name);
      this.model.realName = name;
      this.model.fullPath = url;
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;

          if (this.title == "新增") {
            let params = {
              title: this.model.title,
              ruleType: this.model.ruleType,
              summary: this.model.summary,
              fullPath: this.model.fullPath,
              realName: this.model.realName,
            };
            ruleSystemAdd(params).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "新增成功！",
                  type: "success",
                });
                this.$emit("getdata");
                this.$refs.formModal.handleClose();
              } else {
                this.$message.error("新增失败！");
              }
              this.loading = false;
            });
          } else {
            let params = {
              id: this.model.id,
              title: this.model.title,
              ruleType: this.model.ruleType,
              summary: this.model.summary,
              fullPath: this.model.fullPath,
              realName: this.model.realName,
            };
            ruleSystemUpdate(params).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "修改成功！",
                  type: "success",
                });
                this.$emit("getdata");
                this.$refs.formModal.handleClose();
              } else {
                this.$message.error("修改失败！");
              }
              this.loading = false;
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
