<template>
  <div class="processInfo">
    <div v-for="(item, i) in tableGroup" :key="i" class="group">
      <div class="name btnTitle">
        <span style="display: flex">
          <img src="@/assets/system/xinxi.png" style="width: 16px;height: 16px" alt />
          {{ item.name }}
        </span>
        <span class="buttomName">
          <el-button
            v-if="title == '编辑'"
            class="customButton"
            type="primary"
            @click.prevent="handleAddDialog(item.name, i)"
            size="mini"
          >
            <i class="fa fa-plus"></i>新增
          </el-button>
        </span>
      </div>
      <el-divider></el-divider>
      <el-table
        size="mini"
        :data="item.list"
        element-loading-text="拼命加载中"
        element-loading-background="rgba(255, 255, 255, 0.6)"
        height="180px"
        border
        class="helpTable"
      >
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column
          v-for="item in item.headerList"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.minWidth"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">{{ scope.row[item.prop] }}</template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-if="title == '编辑'">
          <template slot-scope="scope">
            <div>
              <el-button
                type="text"
                size="small"
                @click="handleEdit(scope.row)"
                v-has="'role:assign'"
                class="mr-4"
              >编辑</el-button>
              <el-button
                type="text"
                size="small"
                @click="handleDelete(scope.row)"
                v-has="'role:del'"
              >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="group" v-if="!isYj">
      <div class="name btnTitle">
        <span style="display: flex">
          <img src="@/assets/system/xinxi.png" style="width: 16px;height: 16px" alt />
          退场信息
        </span>
      </div>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="4">退场时间</el-col>
        <el-col :span="20">2022</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">执卸告知回执文件</el-col>
        <el-col :span="20">s</el-col>
      </el-row>
    </div>
    <!-- 二级弹窗 -->
    <el-dialog
      class="editDialog"
      :title="installTitle"
      width="70%"
      v-if="dialogFormVisible"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      @close="formClose"
    >
      <div class="page-container" style="margin-top: 0px">
        <!-- 检测记录 -->
        <el-form
          :model="check"
          label-width="180px"
          ref="form2"
          label-position="right"
          :validate-on-rule-change="false"
          v-if="this.tableName == '检查信息'"
          :rules="rules2"
        >
          <el-row class="PostInfCs-input">
            <el-col :span="12">
              <el-form-item
                label="检测人"
                prop="detectionPerson"
                style="text-align: left"
              >
                <el-input v-model="check.detectionPerson"></el-input>
              </el-form-item>
              <el-form-item label="设备名称" prop="deviceName" style="text-align: left">
                <el-input disabled v-model="check.deviceName"></el-input>
              </el-form-item>
              <el-form-item label="检测时间" prop="detectionTime" style="text-align: left">
                <el-date-picker
                  v-model="check.detectionTime"
                  type="datetime"
                  placeholder="开始时间"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="检测结果" prop="detectionResult" style="text-align: left">
                <el-input v-model="check.detectionResult"></el-input>
              </el-form-item>
              <el-form-item label="上传附件" prop="fileList">
                <Uploader @uploader="expertUploader"></Uploader>
              </el-form-item>
              <el-form-item v-if="installFileList.length" label="文件列表" style="text-align: left">
                <div class="fileList">
                  <p v-for="(item, index) in installFileList" :key="index">
                    {{ item.name || item.fileName }}
                    <span
                      @click="delFile(index)"
                      style="color: #7073FF;cursor: pointer;"
                    >删除</span>
                  </p>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备类型" prop="equipmentType" style="text-align: left">
                <el-input v-model="check.equipmentType"></el-input>
              </el-form-item>
              <el-form-item label="检测单位" prop="detectionUnit" style="text-align: left">
                <el-input v-model="check.detectionUnit"></el-input>
              </el-form-item>
              <el-form-item
                label="检测证书编号"
                prop="detectionCredentialNumber"
                style="text-align: left"
              >
                <el-input v-model="check.detectionCredentialNumber"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 维保记录 -->
        <el-form
          :model="maintain"
          label-width="180px"
          ref="form3"
          v-if="tableName == '维保记录'"
          label-position="right"
          :validate-on-rule-change="false"
          :rules="rules3"
        >
          <el-row class="PostInfCs-input">
            <el-col :span="12">
              <el-form-item
                label="设备名称"
                prop="deviceName"
                style="text-align: left"
              >
                <el-input disabled v-model="maintain.deviceName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="维保方式"
                prop="maintenanceUser"
                style="text-align: left"
              >
                <el-input v-model="maintain.maintenanceUser"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="维保时间"
                prop="maintenanceTime"
                style="text-align: left"
              >
                <el-date-picker
                  v-model="maintain.maintenanceTime"
                  type="datetime"
                  placeholder="开始时间"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="维保人"
                prop="maintenanceUser"
                style="text-align: left"
              >
                <el-input v-model="maintain.maintenanceUser"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保记录" prop="fileList">
                <Uploader @uploader="expertUploader"></Uploader>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item
                label="维保证书编号"
                prop="maintenanceCredentialNumber"
                style="text-align: left"
              >
                <el-input v-model="maintain.maintenanceCredentialNumber"></el-input>
              </el-form-item>
            </el-col>-->
            <el-col :span="12">
              <el-form-item
                v-if="
                  installFileList.length &&
                    maintain.equipmentType != 'tower' &&
                    maintain.equipmentType != 'gantryCrane'
                "
                label="文件列表"
                style="text-align: left"
              >
                <div class="fileList">
                  <p v-for="(item, index) in installFileList" :key="index">
                    {{ item.name || item.fileName }}
                    <span
                      @click="delFile(index)"
                      style="color: #7073FF;cursor: pointer;"
                      >删除</span
                    >
                  </p>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 使用记录 -->
        <el-form
          :model="maintain"
          label-width="180px"
          ref="form3"
          v-if="tableName == '使用记录'"
          label-position="right"
          :validate-on-rule-change="false"
          :rules="rules3"
        >
          <el-row class="PostInfCs-input">
            <el-col :span="12">
              <el-form-item
                label="使用人"
                prop="deviceName"
                style="text-align: left"
              >
                <el-input v-model="maintain.deviceName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="使用时间"
                prop="maintenanceTime"
                style="text-align: left"
              >
                <el-date-picker
                  v-model="maintain.maintenanceTime"
                  type="datetime"
                  placeholder="开始时间"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click.native="formClose">取消</el-button>
        <el-button type="primary" @click.native="sure">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Uploader from "./Uploader";
export default {
  components: {
    Uploader
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    isYj: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // dd
      sgTableGroup: [
        {
          name: "维保记录",
          value: "maintenanceList",
          list: [],
          headerList: [
            { prop: "deviceId", label: "维保方式", minWidth: 130 },
            { prop: "userName", label: "维保时间", minWidth: 80 },
            { prop: "mobile", label: "维保人", minWidth: 100 },
            { prop: "time", label: "维保记录(证明文件)", minWidth: 130 }
          ]
        },
        {
          name: "故障记录",
          value: "failureList",
          list: [],
          headerList: [
            { prop: "deviceId", label: "故障部位", minWidth: 130 },
            { prop: "userName", label: "故障时间", minWidth: 80 },
            { prop: "mobile", label: "排除人", minWidth: 100 },
            { prop: "time", label: "维修记录(证明文件)", minWidth: 130 }
          ]
        },
        {
          name: "检定记录",
          value: "failureList",
          list: [],
          headerList: [
            { prop: "deviceId", label: "检定方式", minWidth: 130 },
            { prop: "userName", label: "检定时间", minWidth: 80 },
            { prop: "mobile", label: "检定人", minWidth: 100 },
            { prop: "time", label: "检定报告(证明文件)", minWidth: 130 }
          ]
        },
        {
          name: "三防检查记录",
          value: "failureList",
          list: [],
          headerList: [
            { prop: "deviceId", label: "检查问题描述", minWidth: 100 },
            { prop: "userName", label: "检查时间", minWidth: 80 },
            { prop: "mobile", label: "检定人", minWidth: 100 }
          ]
        },
        {
          name: "隐患问题描述",
          value: "failureList",
          list: [],
          headerList: [
            { prop: "deviceId", label: "隐患问题描述", minWidth: 100 },
            { prop: "userName", label: "检查时间", minWidth: 80 },
            { prop: "mobile", label: "检定人", minWidth: 100 }
          ]
        }
      ],
      yjTableGroup: [
        {
          name: "维保记录",
          value: "maintenanceList",
          list: [],
          headerList: [
            { prop: "deviceId", label: "维保方式", minWidth: 130 },
            { prop: "userName", label: "维保时间", minWidth: 80 },
            { prop: "mobile", label: "维保人", minWidth: 100 },
            { prop: "time", label: "维保记录(证明文件)", minWidth: 130 }
          ]
        },
        {
          name: "检查信息",
          value: "failureList",
          list: [],
          headerList: [
            { prop: "deviceId", label: "检查结果", minWidth: 130 },
            { prop: "userName", label: "检查时间", minWidth: 130 },
            { prop: "mobile", label: "检查人员", minWidth: 80 }
          ]
        },
        {
          name: "使用记录",
          value: "failureList",
          list: [],
          headerList: [
            { prop: "deviceId", label: "使用人", minWidth: 80 },
            { prop: "userName", label: "使用时间", minWidth: 80 }
          ]
        }
      ],
      tableGroup: [],
      dialogFormVisible: false,
      installFileList: [],
      // failureList: []
      maintain: {
        deviceName: "",
        equipmentType: "",
        maintenanceTime: "",
        maintenanceUnit: "",
        maintenanceResult: "",
        maintenanceCredentialNumber: "",
        maintenanceStatus: "",
        siteId: "",
        siteName: "",
        maintenanceUser: sessionStorage.getItem("userName"),
        maintenanceUserId: sessionStorage.getItem("userId")
      },
      check: {
        deviceName: "",
        detectionStatus: "",
        detectionTime: "",
        detectionResult: "",
        equipmentType: "",
        detectionUnit: "",
        detectionCredentialNumber: "",
        siteId: "",
        siteName: "",
        detectionPerson: ""
      },
      useRecord: {
        detectionStatus: "",
        detectionTime: "",
        detectionResult: "",
        equipmentType: "",
        detectionUnit: "",
        detectionCredentialNumber: "",
        siteId: "",
        siteName: "",
        detectionPerson: ""
      },
      installTitle: "",
      rules3: {},
      rules2: {},
      tableName: ""
    };
  },
  mounted() {
    this.tableGroup = this.isYj == true ? this.yjTableGroup : this.sgTableGroup;
  },
  methods: {
    expertUploader(event) {
      console.log(event);
      if (event.length !== 0) {
        let params = {
          fileName: event.originalName,
          fileRealUrl: event.fileRealUrl,
          fileUrl: event.fileUrl
        };
        this.installFileList.push(params);
      }
    },
    delFile(index) {
      this.installFileList.splice(index, 1);
      this.$message.success("删除成功");
    },
    // 关闭弹窗
    formClose() {
      this.dialogFormVisible = false;
      this.person = {
        personName: null,
        mobile: null,
        certificateName: null
      };
    },
    handleAddDialog(name, i) {
      this.installFileList = [];
      this.installTitle = "新增";
      this.dialogFormVisible = true;
      this.tableName = name;
      if (name == "维保记录") {
        // ss
        this.maintain = {};
      } else if (name == "检查记录") {
        // ss
        this.check = {};
      } else if (name == "使用记录") {
        // ss
        this.useRecord = {};
      }
      console.log(name, i, "新增");
    },
    handleEdit() {
      // s
    },
    handleDelete() {
      // s
    }
  }
};
</script>

<style lang="scss" scoped>
// dd
.processInfo {
  margin: 20px 0;
  height: calc(100% - 91px);
  overflow: auto;
  .group {
    margin-top: 10px;
  }
  .name {
    display: flex;
    img {
      margin-right: 10px;
    }
  }
  .btnTitle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0 0;
  }
  .el-divider--horizontal {
    margin: 10px 0;
  }
  .el-col {
    border: 1px solid #ebeef5;
    padding: 8px;
  }
  .el-col:last-child {
    border-left: 0;
  }
  .el-row:last-child .el-col {
    border-top: 0;
  }
}
</style>
