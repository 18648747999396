<template>
  <div style="border-radius: 10px; background: rgba(8, 23, 66, 0.9)">
    <div ref="main" class="draw-main" id="draw">
      <div
        v-show="loading"
        class="text-center align-middle"
        style="color: #fff"
      >
        底图加载中。。。
      </div>
    </div>
    <div
      class="layer"
      :style="{
        top: layers.top + 'px',
        left: layers.left + 'px',
        display: layers.display,
      }"
    >
      <span>{{ layers.name }}</span>
    </div>
    <!-- 视频弹窗 -->
    <el-dialog
      :title="modelTitle"
      :visible.sync="comsee"
      width="800px"
      :before-close="handleClose"
      :modal="true"
    >
      <div
        id="videoid"
        style="width: 99%; height: 400px"
        v-for="(item, index) of flowLinkid"
        :key="index"
      >
        <video
          v-if="flowLinksourceId == 0"
          :id="`my-video${item}`"
          class="video-js vjs-default-skin"
          controls
          preload="auto"
          style="width: 100%; height: 100%"
          muted
        >
          <source :src="flowLink" type="application/x-mpegURL" />
        </video>
        <video
          v-else-if="flowLinksourceId == 2"
          class="video-js vjs-default-skin"
          :id="`test${flowLinkid[0]}`"
        ></video>
        <iframe
          class="h-full w-full"
          :src="flowLink"
          frameborder="0"
          v-else
        ></iframe>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Konva from "konva";
import { airphotoInfo } from "@/api/map";
import videojs from "video.js";
import "videojs-contrib-hls";
import { lfsoftPlayerWS } from "@/utils/video";

export default {
  name: "routeList",
  components: {},
  data() {
    return {
      Image: "",
      props: {
        value: "id",
        label: "name",
        children: "nodes",
      },
      queryModal: {},
      imageObj: null,
      layer: null,
      layer1: null,
      stage: null,
      containers: null,
      r: 0, // 底图缩放比例
      arc: 0,
      targetLayer: null,
      rect: null,
      circle: null,
      phiont: {
        x: 0,
        y: 0,
        minColumn: 50,
        maxColumn: 50,
      },
      loading: true,
      cameraList: [],
      layers: {
        top: 0,
        left: 0,
        display: "none",
        name: "",
      },
      modelTitle: "视频播放",
      flowLink: "",
      flowLinks: "",
      flowLinksourceId: -1,
      flowLinkid: [],
      comsee: false,
      materials: [],
      floodList: [
        {
          id: 9,
          name: "地势低洼工点",
          url: require("../../../../assets/map/diwa.png"),
          coordX: 0,
          coordY: 0,
        },
        {
          id: 10,
          name: "高边坡",
          url: require("../../../../assets/map/bianpo.png"),
          coordX: 0,
          coordY: 0,
        },
        {
          id: 11,
          name: "河涌",
          url: require("../../../../assets/map/hupo.png"),
          coordX: 0,
          coordY: 0,
        },
        {
          id: 8,
          name: "临近既有线路",
          url: require("../../../../assets/map/jiyouxian.png"),
          coordX: 0,
          coordY: 0,
        },
      ],
      floodData: [],
      scaleNum: 1,
      draggeX: 0,
      draggey: 0,
      playVideo: [],
    };
  },
  mounted() {
    this.getImage();
  },
  methods: {
    async getImage() {
      let res = await airphotoInfo(this.$route.query.id);
      if (res.code == 0) {
        this.image = res.data.photoPath;
        if (
          res.data.materialCoordinates &&
          res.data.materialCoordinates.length > 0
        ) {
          this.materials = res.data.materialCoordinates;
        }
        if (res.data.monitors && res.data.monitors.length > 0) {
          this.cameraList = res.data.monitors;
        }
        if (res.data.proofs && res.data.proofs.length > 0) {
          this.floodData = res.data.proofs;
        }
        this.init(this.image);
      }
    },
    //获取初始化摄像头和位置
    camerasCoords() {
      this.cameraList.forEach((e) => {
        if (e.coordX > 0 && e.coordY > 0) {
          this.getCamera(e.id);
        }
      });
    },
    //获取初始化三防关注和位置
    floodCoords() {
      this.floodData.forEach((e) => {
        if (e.coordinate2D.lat > 0 && e.coordinate2D.lng > 0) {
          let com = this.floodList.find((el) => el.id === e.type.id);
          com.coordX = e.coordinate2D.lat;
          com.coordY = e.coordinate2D.lng;
          this.getFlood(e.type.id);
        }
      });
    },
    //获取初始化物资库
    materialsCoords() {
      if (this.materials && this.materials.length > 0) {
        let c = this.materials;
        c.forEach((e) => {
          if (e.lat > 0 && e.lng > 0) {
            let data = {
              id: 10086,
              name: "物资库",
              coordX: e.lat,
              coordY: e.lng,
              url: require("../../../../assets/map/wuziku.png"),
            };
            this.getMaterial(data);
          }
        });
      }
    },
    init(data) {
      this.loading = true;
      let imageObj = new Image();
      imageObj.src = data;
      imageObj.onload = () => {
        this.imageObj = imageObj;
        this.drawBg(imageObj);
      };
    },
    drawBg(imageObj) {
      let container;

      document.getElementById("draw").innerHTML = "";
      container = this.$refs.main;
      this.containers = container;
      let width = container.clientWidth;
      let r = imageObj.width / width;

      this.arc = 1068 / width;

      this.r = r;
      this.stage = new Konva.Stage({
        container,
        height: imageObj.height / r,
        width: width,
        draggable: true,
      });

      let bg = new Konva.Image({
        x: 0,
        y: 0,
        image: imageObj,
        width: width,
        height: imageObj.height / r,
      });
      this.stage.position({ x: this.draggeX, y: this.draggey });
      // 缩放
      this.stage.on("wheel", (e) => {
        e.evt.preventDefault();
        var max = 4; // 放大最大的比例
        var min = 1; // 缩小最小的比例
        var step = 0.05; // 每次缩放的比例

        const x = e.evt.offsetX;
        const y = e.evt.offsetY;

        var offsetX =
          ((x - this.stage.offsetX()) * this.stage.scaleX()) /
            (this.stage.scaleX() - step) -
          (x - this.stage.offsetX());
        var offsetY =
          ((y - this.stage.offsetY()) * this.stage.scaleY()) /
            (this.stage.scaleY() - step) -
          (y - this.stage.offsetY());

        if (e.evt.wheelDelta) {
          if (e.evt.wheelDelta > 0) {
            // 放大
            if (this.stage.scaleX() < max && this.stage.scaleY() < max) {
              if (this.scaleNum < max) {
                this.scaleNum = this.scaleNum + step;
                //缩放之前先清除一下图标的图层
                if (this.layer1) {
                  this.layer1.destroyChildren();
                  this.layer1.remove(true);
                }
                //加载配置了的摄像头
                this.camerasCoords();
                //加载配置了的三防关注；
                this.floodCoords();
                //加载配置了的物资库；
                this.materialsCoords();
              } else {
                this.scaleNum = max;
              }
              this.stage.scaleX(this.stage.scaleX() + step);
              this.stage.scaleY(this.stage.scaleY() + step);
              this.stage.move({ x: -offsetX, y: -offsetY }); // 跟随鼠标偏移位置
            }
          } else {
            // 缩小
            if (this.stage.scaleX() > min && this.stage.scaleY() > min) {
              if (this.scaleNum > min) {
                this.scaleNum = this.scaleNum - step;
                //缩放之前先清除一下图标的图层
                if (this.layer1) {
                  this.layer1.destroyChildren();
                  this.layer1.remove(true);
                }
                //加载配置了的摄像头
                this.camerasCoords();
                //加载配置了的三防关注；
                this.floodCoords();
                //加载配置了的物资库；
                this.materialsCoords();
              } else {
                this.scaleNum = min;
              }
              this.stage.scaleX(this.stage.scaleX() - step);
              this.stage.scaleY(this.stage.scaleY() - step);
              this.stage.move({ x: offsetX, y: offsetY }); // 跟随鼠标偏移位置
            }
          }
        }
      });

      this.stage.on("dragmove", () => {
        if (this.stage.position().x > 0) {
          this.stage.position({ x: 0, y: this.stage.position().y });
        }
        if (this.stage.position().y > 0) {
          this.stage.position({ x: this.stage.position().x, y: 0 });
        }
        if (this.stage.position().x + width * this.scaleNum < width) {
          this.stage.position({
            x: width - width * this.scaleNum,
            y: this.stage.position().y,
          });
        }
        if (
          this.stage.position().y + (imageObj.height / r) * this.scaleNum <
          imageObj.height / r
        ) {
          this.stage.position({
            x: this.stage.position().x,
            y: imageObj.height / r - (imageObj.height / r) * this.scaleNum,
          });
        }

        this.draggeX = this.stage.position().x;
        this.draggey = this.stage.position().y;
      });

      // bg layer
      let layer = new Konva.Layer();
      this.layer = layer;
      this.layer.add(bg);
      this.layer.batchDraw();
      this.stage.add(layer);

      let layer1 = new Konva.Layer();
      this.layer1 = layer1;
      this.layer1.batchDraw();
      this.stage.add(this.layer1);

      //加载配置了的摄像头
      this.camerasCoords();
      //加载配置了的三防关注；
      this.floodCoords();
      //加载配置了的物资库；
      this.materialsCoords();
      this.loading = false;
    },
    //获取物资库
    getMaterial(data) {
      let imageObj = new Image();
      imageObj.id = data.id;
      imageObj.name = data.name;
      imageObj.coordX = data.coordX;
      imageObj.coordY = data.coordY;
      imageObj.src = data.url;
      imageObj.ctype = data.id;
      imageObj.width = 29 / this.scaleNum;
      imageObj.height = 30 / this.scaleNum;
      imageObj.onload = () => {
        this.addCamera(imageObj, "materia");
      };
    },
    //获取防汛关注重点
    getFlood(id) {
      let com = this.floodList.find((e) => e.id === id);
      let imageObj = new Image();
      imageObj.id = com.id;
      imageObj.name = com.name;
      imageObj.coordX = com.coordX;
      imageObj.coordY = com.coordY;
      imageObj.src = com.url;
      imageObj.ctype = com.id;
      imageObj.width = 30 / this.scaleNum;
      imageObj.height = 31 / this.scaleNum;
      imageObj.onload = () => {
        this.addCamera(imageObj, "flood");
      };
    },
    //获取监控摄像头
    getCamera(id) {
      let com = this.cameraList.find((e) => e.id === id);
      if (com.coordX > 0 && com.coordY > 0) {
        console.log("正常获取");
      } else {
        com.coordX = 1;
        com.coordY = 1;
      }
      let imageObj = new Image();
      imageObj.id = com.id;
      imageObj.name = com.name;
      imageObj.flowLink = com.hls;
      imageObj.flowLinks = com.ipAddress;
      // imageObj.flowLink = com.flowLink;
      imageObj.flowLinksourceId = com.sourceId;
      imageObj.flowLinkid = com.id;
      imageObj.coordX = com.coordX;
      imageObj.coordY = com.coordY;
      imageObj.src = require("../../../../assets/map/qiangji.png");
      imageObj.width = 25 / this.scaleNum;
      imageObj.height = 33 / this.scaleNum;
      imageObj.onload = () => {
        this.addCamera(imageObj, "camera");
      };
      // imageObj.width = 30;
      // imageObj.height = 50;
    },
    //将摄像头添加到绘图画布
    addCamera(imageObj, tname) {
      // darth vader
      var darthVaderImg = new Konva.Image({
        id: imageObj.id,
        name: tname,
        ctype: imageObj.ctype ? imageObj.ctype : 0,
        image: imageObj,
        x: imageObj.coordX / this.arc,
        y: imageObj.coordY / this.arc,
        width: imageObj.width,
        height: imageObj.height,
        scaleX: 1,
        scaleY: 1,
      });

      let _that = this;
      // add cursor styling
      darthVaderImg.on("mouseover", function (evt) {
        document.body.style.cursor = "pointer";
        _that.layers = {
          top: evt.evt.clientY - 50,
          left: evt.evt.clientX + 10,
          index: imageObj.id,
          display: "block",
          name: imageObj.name,
        };
      });
      darthVaderImg.on("mouseout", function (evt) {
        document.body.style.cursor = "default";
        _that.layers = {
          top: evt.evt.clientY - 50,
          left: evt.evt.clientX + 10,
          index: imageObj.id,
          display: "none",
          name: imageObj.name,
        };
      });
      darthVaderImg.on("click", function () {
        if (tname == "camera") {
          _that.flowLinkid = [];
          _that.modelTitle = imageObj.name;
          _that.flowLink = imageObj.flowLink;
          _that.flowLinks = imageObj.flowLinks;
          _that.flowLinksourceId = imageObj.flowLinksourceId;
          _that.flowLinkid.push(imageObj.flowLinkid);
          if (_that.flowLinksourceId == 0) {
            _that.$nextTick(() => {
              videojs(
                "my-video" + _that.flowLinkid[0],
                {
                  bigPlayButton: false,
                  textTrackDisplay: false,
                  posterImage: true,
                  errorDisplay: false,
                  controlBar: true,
                },
                function () {
                  this.play();
                }
              );
            });
          }
          //广联达的视频
          if (_that.flowLinksourceId == 2) {
            _that.$nextTick(() => {
              console.log(_that.flowLinks.slice(0, 3));
              let IPhost = "zhjk.geobim.cn:3226";
              if (_that.flowLinks.slice(0, 3) == 172) {
                IPhost = "zhjk.geobim.cn:3226";
              } else {
                IPhost = "spjk.geobim.cn:3234";
              }
              var conf1 = {
                videoid: "test" + _that.flowLinkid[0],
                protocol: "https:",
                host: IPhost,
                rootpath: "/",
                token: _that.flowLinks,
                hlsver: "v1",
                session: "c1782caf-b670-42d8-ba90-2244d0b0ee83", //session got from login
              };
              console.log(conf1);
              var v1 = new lfsoftPlayerWS(conf1);
              _that.playVideo.push(v1);
              v1.connect();
            });
          }
          _that.comsee = true;
        }
      });

      this.layer1.add(darthVaderImg);
      this.layer1.batchDraw();
      this.stage.add(this.layer1);
    },
    handleClose() {
      if (this.flowLinksourceId == 0) {
        videojs("my-video" + this.flowLinkid[0]).dispose();
      }
      this.playVideo.forEach((item) => {
        if (item.W == this.flowLinks) {
          item.disconnect();
        }
      });
      (this.flowLink = ""), (this.flowLinks = ""), (this.flowLinksourceId = -1);
      this.flowLinkid = [];
      this.comsee = false;
    },
  },
  beforeDestroy() {
    if (this.stage) {
      this.stage.destroyChildren();
      this.stage.remove(true);
    }
    this.stage = null;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .video-js {
  width: 100%;
  height: 100%;
}
.draw-main {
  max-width: 1070px;
  max-height: 880px;
  // border: 1px solid #8df;
}
.response-list {
  max-height: 480px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  li {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .response-date {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 28px;
  }
  .response-content {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.6;
  }
}
.layer {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 5px;
  text-align: left;
  font-size: 14px;
  color: #fff;
  z-index: 100000000;
}
//弹窗
.el-dialog,
.el-pager li {
  background: rgba(52, 73, 85, 0.3);
  border: 1px solid #47a2d3;
  border-radius: 10px;
  color: #fff;
}
.el-dialog__wrapper {
  background: rgba(0, 0, 0, 0.8);
}
.el-dialog__title {
  color: #fff;
}
.el-form-item__label {
  color: #fff;
}
.el-input__inner {
  background-color: rgba(2, 2, 6, 1);
  color: #fff;
  border-radius: 4px;
  border: 1px solid #4d4d4d;
}
.el-dialog__headerbtn .el-dialog__close {
  color: #f9aa33;
}
// 信息弹窗样式
::v-deep .BMap_pop {
  > div,
  > img:nth-child(10) {
    display: none;
    overflow: unset;
  }
  > div:nth-child(9) {
    display: block;
    overflow: unset;
    min-width: 320px !important;
  }
  // > div:nth-child(8) {
  //   /*display: block;*/
  // }
  .BMap_top {
    display: none;
  }
  .BMap_center {
    background: transparent;
    border: none;
    position: sticky !important;
    height: 100%;
  }
}

::v-deep .BMap_bubble_content {
  background: #081742;
  box-shadow: 0px 0px 8px 0px rgba(0, 14, 38, 0.2);
  border: 1px solid #173f58;
  padding: 20px;
  position: relative;
  .info-wrapper {
    width: 100%;
    p {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
    }
  }
  .info-header {
    height: 18px;
    margin-bottom: 20px;
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      &::before {
        display: inline-block;
        height: 18px;
        width: 18px;
        content: "";
        background: red;
        margin-right: 5px;
      }
    }
    .close-btn {
      font-size: 12px;
      color: #fff;
      cursor: pointer;
    }
  }
  .triangle {
    width: 40px;
    height: 40px;
    background: #081742;
    border: 1px solid #173f58;
    transform: rotate(-45deg);
    position: absolute;
    z-index: -99;
    left: 40%;
  }
}
</style>
<style>
.anchorBL {
  display: none !important;
}
</style>
