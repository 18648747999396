<template>
  <div class="container">
    <index-box :title="title">
      <div class="weather-container flex flex-col justify-between">
        <div class="weather-info">
          <div
            class="left text-center flex flex-col items-center justify-center"
          >
            <p class="temperature xl:text-2xl md:text-base">
              {{ weatherData.temperater }}℃
            </p>
            <p class="weather">{{ weatherData.weather }}</p>
          </div>
          <div
            class="middle flex flex-col justify-around xl:text-sm md:text-xs"
          >
            <p>相对湿度：{{ weatherData.humRatio }}%</p>
            <p>风速风向：{{ weatherData.windDes }}</p>
          </div>
          <div class="right flex flex-col justify-around xl:text-sm md:text-xs">
            <p>PM10：{{ weatherData.pm }}μg/m³</p>
            <p>PM2.5：{{ weatherData.pm }} μg/m³</p>
          </div>
        </div>
        <div class="weather-release">
          <div class="release-content">
            广州市气象台发布 {{ timeData }} 更新
          </div>
        </div>
        <div class="weather-image flex gap-x-2">
          <div class="image-content" v-for="(s, index) in newData" :key="index">
            <img :src="s.icon" />
          </div>
        </div>
      </div>
    </index-box>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import { getActiveWarn, getCityLive } from "@/api/proof";
import dayjs from "dayjs";
export default {
  name: "weather",
  components: {
    IndexBox,
  },
  data() {
    return {
      title: "气象信息",
      weatherData: {},
      newData: [],
      timeData: dayjs().format("YYYY年MM月DD日 HH时mm分"),
    };
  },
  mounted() {
    // this.getData();
    this.getwarn();
    this.getct();
    const timer = setInterval(() => {
      this.getwarn();
      this.getct();
    }, 300000);
    this.$once("hook:beforeDestroy", () => {
      clearInterval(timer);
    });
  },
  methods: {
    // async getData() {
    //   let res = await getHourTemp();
    //   console.log(res);
    //   if (res.code == 0) {
    //     let list = res.data.list;
    //     list.forEach((e) => {
    //       if (e.cityName == "广州") {
    //         // this.weatherData = e;
    //       }
    //     });
    //   }
    // },
    async getct() {
      let res = await getCityLive();
      console.log(res);
      if (res.code == 0) {
        console.log(res.data[0].cityName);
        this.weatherData = res.data.find((e) => e.cityName == '"广州"');
        console.log(this.weatherData);
      }
    },
    async getwarn() {
      let res = await getActiveWarn();
      console.log(res);
      if (res.code == 0) {
        this.newData = this.changData(res.data.data);
      }
    },
    changData(all) {
      for (let i = 0; i < all.length; i++) {
        if (all[i].districts_NAME != "广州市") {
          all.splice(i, 1);
          i--;
        }
      }
      let conData = all;

      //把有解除的气象预警信息从allWarningData去除
      for (let i = 0; i < conData.length; i++) {
        if (conData[i].title.includes("解除")) {
          conData.splice(i, 1);
          i--;
        }
      }
      console.log(conData);
      return conData;
    },
  },
};
</script>

<style lang="scss" scoped>
.weather-container {
  height: 16vh;
  .weather-info {
    display: flex;
    .left {
      width: 25%;
      p {
        line-height: 1;
      }
      .temperature {
        // font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 10px;
      }
      .weather {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .middle {
      flex: 1;
      p {
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .right {
      flex: 1;
      p {
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .weather-release {
    padding: 10px 0;
    .release-content {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &::before {
        display: block;
        content: "";
        width: 36px;
        height: 1px;
        background: #fff;
      }
      &::after {
        display: block;
        content: "";
        width: 36px;
        height: 1px;
        background: #fff;
      }
    }
  }
  .weather-image {
    .image-content {
      width: 6.5vh;
      // height: 64px;
      img {
        width: 100%;
      }
    }
  }
}
</style>