<template>
  <div class="floodPrevention-container">
    <index-box :title="title">
      <div class="floodPrevention-wrapper">
        <div class="floodPrevention-header">
          <div class="header-wrapper flex justify-center items-center">
            <div class="header-title 2xl:text-xl xl:text-base md:text-sm">
              防汛关注总数：{{ sumb }}
            </div>
          </div>
        </div>
        <div class="table-wrapper flex flex-col">
          <div class="flex flex-1 items-center item">
            <div
              class="
                flex
                items-center
                justify-center
                header-title
                2xl:text-base
                xl:text-xs
                md:text-xs
              "
            >
              防汛关注类型
            </div>
            <div
              class="
                flex
                items-center
                justify-center
                header-title
                2xl:text-base
                xl:text-xs
                md:text-xs
              "
            >
              防汛关注总数
            </div>
            <div
              class="
                flex
                items-center
                justify-center
                header-title
                2xl:text-base
                xl:text-xs
                md:text-xs
              "
            >
              预警数量
            </div>
          </div>
          <div
            class="flex flex-1 items-center item"
            v-for="(data, index) in fxData"
            :key="index"
          >
            <div
              class="
                flex
                items-center
                justify-center
                2xl:text-base
                xl:text-xs
                md:text-xs
              "
            >
              {{ data.type.label }}
            </div>
            <div
              class="
                flex
                items-center
                justify-center
                2xl:text-base
                xl:text-xs
                md:text-xs
              "
              style="cursor: pointer"
              @click="totalnum(data)"
            >
              {{ data.count }}
            </div>
            <div
              class="
                flex
                items-center
                justify-center
                2xl:text-base
                xl:text-xs
                md:text-xs
              "
              style="color: #e23c39"
            >
              {{ data.warnCount }}
            </div>
          </div>
        </div>
      </div>
    </index-box>
    <Modal
      class="modalDetail"
      ref="rescueDetail"
      :modal="false"
      @closeDialog="closeDialog"
      :width="'1200px'"
      title="防汛关注总数详情"
    >
      <div
        class="table-wrapper p-4 h-full"
        v-loading="pageLoading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        <el-table
          :data="rescueData"
          border
          style="width: 100%"
          max-height="500"
        >
          <el-table-column align="center" label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column prop="label" label="关注点类型"> </el-table-column>
          <el-table-column prop="site" label="归属工点名称"> </el-table-column>
          <el-table-column prop="alterFlag" label="当前有无报警">
          </el-table-column>
          <el-table-column prop="created" label="记录时间"> </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-center pt-4" style="position: relative">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.count"
          :page-count="page.total"
          :page-size="page.pageSize"
          :current-page="page.pageNo"
          @size-change="handlerescueChange"
          @current-change="hanrescueChange"
        >
        </el-pagination>
      </div>
    </Modal>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import { getStatistics, PagingCoreList } from "@/api/proof";
import Modal from "@/components/Modal/modal.vue";
export default {
  name: "floodPrevention",
  components: {
    IndexBox,
    Modal,
  },
  data() {
    return {
      title: "防汛关注重点",
      sumb: "0",
      fxData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      datarry: {},
      rescueData: [],
      pageLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      getStatistics().then((res) => {
        if (res.code == 0) {
          this.fxData = res.data;
          console.log(res);
          this.sumb = 0;
          this.fxData.forEach((e) => {
            this.sumb = this.sumb + e.count;
            if (e.type.id == 8) {
              e.type.label = "临近既有线接口";
            } else if (e.type.id == 9) {
              e.type.label = "地势低洼点";
            } else if (e.type.id == 10) {
              e.type.label = "高边坡";
            } else if (e.type.id == 11) {
              e.type.label = "临近湖泊（河涌）";
            }
          });
        }
      });
    },
    //查看详情
    totalnum(data) {
      console.log(data);
      this.datarry = data;
      this.$refs.rescueDetail.handleOpen();
      this.pageLoading = true;
      PagingCoreList({
        uuid: "-",
        type: data.type.id,
        siteId: "-",
        alterFlag: "-",
        page: this.page.pageNo,
        size: this.page.pageSize,
      }).then((res) => {
        console.log(res);

        res.data.records.forEach((item) => {
          let obj = {
            label: "",
            site: "",
            alterFlag: "",
            created: "",
          };
          obj.label = item.type.label;
          if (item.site) {
            obj.site = item.site.name;
          } else {
            obj.site = "暂无工点";
          }

          obj.created = item.created;
          if (item.alterFlag == 0) {
            obj.alterFlag = "无报警";
          } else if (item.alterFlag == 1) {
            obj.alterFlag = "有报警";
          }

          this.rescueData.push(obj);
        });
        this.page.count = res.data.count;
        this.page.pageSize = res.data.size;
        this.page.total = res.data.total;
        this.pageLoading = false;
      });
    },
    handlerescueChange(val) {
      console.log(val);
      this.page.pageSize = val;
      this.rescueData = [];
      this.totalnum(this.datarry);
    },
    hanrescueChange(val) {
      console.log(val);
      this.rescueData = [];
      this.page.pageNo = val;
      this.totalnum(this.datarry);
    },
    closeDialog() {
      (this.rescueData = []),
        (this.page = {
          pageNo: 1,
          pageSize: 10,
          total: 0,
          count: 0,
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.floodPrevention-container {
  .floodPrevention-wrapper {
    height: 100%;
  }
  .floodPrevention-header {
    width: 100%;
    height: 3.7vh;
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 3.7vh;
    margin-bottom: 20px;
    position: relative;
    .header-wrapper {
      .header-title {
        width: 11.98vw;
        border-radius: 4px;
        background: #faad13;
        color: #fff;
        line-height: 3.7vh;
      }
      &:after {
        display: block;
        width: 100%;
        height: 2px;
        content: "";
        position: absolute;
        z-index: -99;
        top: 50%;
        left: 0;
        background-image: linear-gradient(
          45deg,
          rgba(240, 175, 65, 0),
          rgba(240, 175, 65, 1) 50%,
          rgba(240, 175, 65, 0) 100%
        );
      }
    }
  }
  .table-wrapper {
    height: calc(100% - 60px);
    margin-bottom: 20px;
    border-top: 1px solid #173f58;
    border-left: 1px solid #173f58;
    .item {
      div {
        height: 100%;
        border-right: 1px solid #173f58;
        border-bottom: 1px solid #173f58;
        color: #fff;
        font-weight: bold;
        &:nth-child(1) {
          width: 40%;
        }
        &:nth-child(2) {
          width: 30%;
        }
        &:nth-child(3) {
          width: 30%;
        }
      }
      .header-title {
        color: #43a5e9;
      }
    }
  }
}
::v-deep .el-table {
  width: 100%;

  background: transparent;

  .el-table__body-wrapper {
    height: 300px;
  }

  &::before {
    background-color: #143363;
  }

  thead {
    color: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: rgba(20, 51, 99, 0.5);
  }

  tr,
  th {
    background-color: transparent;
  }

  td {
    border-bottom: 1px solid #143363;
  }

  .cell {
    color: #fff;
  }

  th.is-leaf {
    border: 1px solid #143363;
  }
}

::v-deep .el-table--border {
  border: 1px solid #143363;

  .el-table--enable-row-hover {
    background-color: #143363;
  }

  td {
    border-right: 1px solid #143363;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: rgba(0, 46, 117, 0.4);
}
::v-deep .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  width: 0;
}
</style>
