<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="线路工点" prop="lineSite">
            <el-cascader
              v-model="queryModal.lineSite"
              clearable
              :options="treeData"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="handleLineSiteChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="queryModal.teamName"
              clearable
              placeholder="输入队伍名称查询"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="handleImport"  v-has="'Pluralism:therimport'">导入</el-button>
          <el-button type="primary" @click="handleExport" v-has="'Pluralism:export'">导出</el-button>
          <el-button type="primary" @click="handleAdd" v-has="'Pluralism:add'"
            >新增</el-button
          >
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="loading"
        border
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="70"
        ></el-table-column>
        <el-table-column align="center" prop="teamName" label="名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          align="center"
          prop="superviseName"
          label="所属建管部"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="branchName"
          label="分部名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="工点名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="numOfPeople"
          label="人数"
          width="70"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="captainName"
          label="负责人"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="captainPhone"
          label="联系方式"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column align="center" label="操作" width="200px">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-has="'Pluralism:edit'"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="MemberList(scope.row)"
              v-has="'Pluralism:member'"
              >人员名单</el-button
            >
            <el-button
              v-has="'Pluralism:del'"
              @click="handleDelete(scope.row)"
              type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <PluralismForm
      ref="modelForm"
      :lineSiteData="treeData"
      :orgTree="orgTree"
      @formSubmit="handleFormSubmit"
    ></PluralismForm>
  </div>
</template>

<script>
import PluralismForm from "./modules/PluralismForm.vue";
import { getSoleDuty, delSoleDuty } from "@/api/eres";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { getLoginUserDeptList } from "@/api/organization";
import { getSiteDataByUuId } from "@/api/resource";
export default {
  components: {
    PluralismForm,
  },
  data() {
    return {
      isShowPanl: false,
      treeData: [],
      queryModal: {
        teamName: "",
        lineID: "",
        siteId: "",
        lineSite: [],
        siteIds: [],
      },
      filterText: "",
      tableData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true,
      },
      loading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      orgTree: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  mounted() {
    this.getSiteDataByUserInfo();
    this.getLineSiteTreeData();
    this.getLoginUserDeptList();
  },
  methods: {
    // 根据当前登录人单位获取组织结构权限树
    async getLoginUserDeptList() {
      let res = await getLoginUserDeptList({});
      if (res.code == 0) {
        this.orgTree = res.data;
      }
    },
    async getSiteDataByUserInfo() {
      const userInfo = JSON.parse(localStorage.getItem("user"));
      const params = {
        userId: userInfo.uuid,
      };
      let res = await getSiteDataByUuId(params);
      if (res.code == 0) {
        this.queryModal.siteIds = res.data;
        this.loadData();
      }
    },
    handleSearch() {
      this.loadData();
    },
    handleImport() {
      console.log("导入");
    },
    handleExport() {
      console.log("导出");
    },
    MemberList(row) {
      this.$router.push({
        path: "MemberList",
        query: {
          id: row.id,
          name: row.teamName,
          type: "Pluralism",
        },
      });
    },
    handleAdd() {
      this.$refs.modelForm.handleAdd();
    },
    handleEdit(record) {
      this.$refs.modelForm.handleAdd(record);
    },
    handleDelete(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delSoleDuty(record.id).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadData();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    handleFormSubmit() {
      this.loadData();
    },
    handleLineSiteChange(val) {
      this.queryModal.siteIds = [];
      for (let item of val) {
        this.queryModal.siteIds.push(item[item.length - 1]);
      }
    },
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    // 线路工点树过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 线路工点树切换
    getCurrentTreeNode() {
      this.queryModal.siteIds = this.$refs.treePanl.getCheckedKeys();
      this.loadData();
    },
    // 线路工点面板显示隐藏
    handleTogglePanl() {
      this.isShowPanl = !this.isShowPanl;
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    async loadData() {
      this.loading = true;
      const params = {
        page: this.page.pageNo,
        size: this.page.pageSize,
        teamName: this.queryModal.teamName,
        teamType: 2,
        siteUuids: this.queryModal.siteIds,
      };
      getSoleDuty(params).then((res) => {
        if (res.code == 0) {
          const { current, size, total, count } = res.data;
          this.tableData = res.data.records ? res.data.records : [];
          this.page.pageNo = current;
          this.page.pageSize = size;
          this.page.total = total;
          this.page.count = count;
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 1;
}
.p-4 {
  overflow-y: scroll;
}
.page-container {
  .handle-wrapper {
    min-width: 240px;
    max-width: 30vw;
    height: 60vh;
    position: absolute;
    top: 10%;
    left: 20px;
    background: rgba(255, 255, 255, 1);
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
    border-radius: 0px 20px 20px 0px;
    padding: 20px;
    z-index: 2;

    .tree-wrapper {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }

    .toggle-btn {
      width: 28px;
      height: 240px;
      position: absolute;
      right: 0px;
      top: 50%;
      margin-top: -120px;
      cursor: pointer;
      background-image: url();
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }

    .toggle-btn-close {
      transform: rotate(180deg);
    }

    .toggle-btn-open {
      transform: rotate(0deg);
    }
  }

  .handle-wrapper-hide {
    left: -212px;
    background: transparent;
    box-shadow: none;
  }

  .handle-wrapper-show {
    left: 0;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #43a5e9;
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
  }
}
</style>
