<template>
  <div>
    <el-form
      :model="formData"
      class="demo-form-inline"
      :rules="rules"
      ref="ruleForm"
      v-loading="fromLoading"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item label="启动时间" prop="startTime">
            <!-- readonly -->
            <el-date-picker
              v-model="formData.startTime"
              type="datetime"
              placeholder="请选择"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="类型" prop="type">
            <el-select
              v-model="formData.type"
              clearable
              placeholder="请选择类型"
            >
              <el-option
                v-for="item of typeList"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="级别" prop="currentLevel">
            <el-select
              v-model="formData.currentLevel"
              clearable
              placeholder="请选择级别"
            >
              <el-option
                v-for="item of leveloptions"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="应急响应详细内容" prop="originalAlertMessage">
            <el-input
              type="textarea"
              :autosize="{ minRows: 8, maxRows: 16 }"
              v-model="formData.originalAlertMessage"
              placeholder="多行输入"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item class="dialog-footer">
        <el-button type="primary" @click="sureSub('ruleForm')"
          >确认发布</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ResSaveOrUpdateNew } from "@/api/responseTem";
export default {
  props: {
    rows: {
      type: Object,
      default: () => {},
    },
    leveloptions: {},
    typeList: {},
  },
  data() {
    return {
      dialogVisible: true,
      fromLoading: false,
      formData: {
        startTime: "",
        currentLevel: "",
        type: "",
        originalAlertMessage: "",
      },
      rules: {
        startTime: [
          {
            type: "string",
            required: true,
            message: "请选择启动时间",
            trigger: "change",
          },
        ],
        currentLevel: [
          {
            type: "number",
            required: true,
            message: "请选择应急等级",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "number",
            required: true,
            message: "请选择应急类型",
            trigger: "change",
          },
        ],

        originalAlertMessage: [
          {
            type: "string",
            required: true,
            message: "请填写应急响应详细内容",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.formData = {
      startTime: "",
      currentLevel: "",
      type: "",
      originalAlertMessage: "",
    };
  },
  methods: {
    sureSub(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fromLoading = true;
          let params = this.formData;
          ResSaveOrUpdateNew(params).then((res) => {
            if (res.code == 0) {
              this.fromLoading = false;
              this.$message.success("新增成功");
              this.$emit("issueClose", true);
              this.resetForm();
            } else {
              this.fromLoading = false;
              this.$message.error(res.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      const th = this;
      Object.keys(this.formData).forEach(function(key) {
        th.formData[key] = "";
      });
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.dialog-footer ::v-deep .el-form-item__content {
  margin-left: 0 !important;
}
</style>
