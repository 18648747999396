<template>
  <systemModal ref="formModal" :title="title" width="60%" :showFooter="false">
    <el-form v-loading="loading" :model="model" ref="modelForm" label-width="120px" class="demo-model"
      style="width: 90%"
    >
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="摄像头名称：" prop="name">
            {{ model.name }}
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="所属工点：" prop="lineSite">
            {{ model.siteName }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="类型：" prop="type">
            {{ model.typeName }}
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="状态：" prop="status">
            {{ model.statusName }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="IP：">
            {{ model.ipAddress }}
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="通道：">
            {{ model.channel }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="端口：">
            {{ model.port }}
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="用户名：">
            {{ model.userName }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="密码：">
            {{ model.userPwd }}
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="公网流地址：">
            {{ model.flowLink }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="flv流地址：">
            {{ model.flv }}
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="hls流地址：">
            {{ model.hls }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="中铁获取直播请求地址：">
            {{ model.videoAddress }}
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="中铁登录请求地址：">
            {{ model.loginAddress }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="数据来源：">
            {{ model.sourceId == 0 ? "中铁" : "广联达" }}
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="是否可控制：">
            {{ model.canContr == 0 ? "不可控制" : "可控制" }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="X坐标：">
            {{ model.coordX }}
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="Y坐标：">
            {{ model.coordY }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="记录时间：">
            {{ model.created }}
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="备注：" prop="remark">
            {{ model.remark }}
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
export default {
  components: {
    systemModal
  },
  data() {
    return {
      title: "详情",
      colSpan: 12,
      model: {
        lineSite: [],
        label: "",
        monitorType: "",
        state: "",
      },
      dict: {
        type: [],
        status: []
      },
      loading: false,
    };
  },
  props: {},
  created() {},
  mounted() {
    // this.getDictData("摄像头类型", this.dict.type);
    // this.getDictData("摄像头状态", this.dict.status);
  },
  methods: {
    handleDetail(record) {
      this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
      this.$refs.formModal.handleOpen();
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then(res => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then(res => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 清空el表单
    handleModalClose() {
      this.model = {
        lineSite: [],
        label: "",
        monitorType: "",
        state: "",
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
