<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
             label-width="120px" class="demo-model">

      <el-row>
        <el-col :span="24">
          <el-form-item label="隐患条目名称" prop="itemName">
            <el-input v-model="ruleForm.itemName" type="textarea" placeholder="隐患条目名称"></el-input>
          </el-form-item>
        </el-col>


        <el-col :span="12">
          <el-form-item label="隐患类型" prop="screeningType">
            <el-select v-model="ruleForm.screeningType" placeholder="隐患类型"
                       style="width: 100%">
              <el-option
                  v-for="item in troubleshooting"
                  :key="item.value"
                  :label="item.intro"
                  :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="隐患类别" prop="categoryPid">
            <el-select v-model="ruleForm.categoryPid" @change="dangerChange" placeholder="隐患类别"
                       style="width: 100%">
              <el-option
                  v-for="item in dangerType"
                  :key="item.value"
                  :label="item.categoryName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="隐患子类" prop="categoryId">
            <el-select v-model="ruleForm.categoryId" placeholder="隐患子类"
                       style="width: 100%">
              <el-option
                  v-for="item in dangerSon"
                  :key="item.value"
                  :label="item.categoryName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="条目编码" prop="itemCode">
            <el-input v-model="ruleForm.itemCode" placeholder="条目编码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="整改单位" prop="reformUnitType">
              <el-select v-model="ruleForm.reformUnitType" placeholder="整改单位"
                         style="width: 100%">
                <el-option
                    v-for="item in rectification"
                    :key="item.value"
                    :label="item.label"
                    :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="整改天数" prop="reformDay">
            <el-input v-model="ruleForm.reformDay" placeholder="整改天数"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="隐患等级" prop="hiddenLevel">
            <el-select v-model="ruleForm.hiddenLevel" placeholder="隐患等级"
                       style="width: 100%">
              <el-option
                  v-for="item in dangerLevel"
                  :key="item.value"
                  :label="item.label"
                  :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="状态" prop="itemState">
              <el-select v-model="ruleForm.itemState" placeholder="状态"
                         style="width: 100%">
                <el-option
                    v-for="item in statusList"
                    :key="item.value"
                    :label="item.label"
                    :value="Number(item.value)">
                </el-option>
              </el-select>
            </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelAddPlan">取 消</el-button>
      <el-button type="primary" @click="submitAdd('0')" v-if="roleType == 4">确 定</el-button>
      <el-button type="primary" @click="submitAdd('1')" v-if="roleType != 4">提交建议</el-button>
    </div>
  </div>
</template>

<script>
import {libraryAdd, libraryDetail, libraryEdit, subCategoryList} from "@/api/hdanger";
import {getDictData} from "@/api/dict";

export default {
  name: "addLibrary",
  props : {
    operation : {
      default : 'add'
    },
    editData : {
      default : () => {}
    },
    roleType : {
      default : null
    }
  },
  data(){
    return {
      ruleForm : {
        itemState : '',
        reformDay : '',
        reformUnitType : '',
        itemCode : '',
        categoryId : '',
        categoryPid : '',
        screeningType : '',
        itemName : '',
        dangerLevel : '',
      },
      rules : {
        itemName: [
          { required: true, message: '隐患条目不能为空', trigger: 'blur' }
        ],
        screeningType: [
          { required: true, message: '隐患类型不能为空', trigger: 'change' }
        ],
        itemCode: [
          { required: true, message: '条目编码不能为空', trigger: 'change' }
        ],
        reformUnitType: [
          { required: true, message: '整改单位不能为空', trigger: 'change' }
        ],
        reformDay: [
          { required: true, message: '整改天数不能为空', trigger: 'blur' }
        ],
        itemState: [
          { required: true, message: '状态不能为空', trigger: 'change' }
        ],
        dangerLevel: [
          { required: true, message: '隐患等级不能为空', trigger: 'change' }
        ],
      },
      statusList : [
        {
          label : '有效',
          value : '0'
        },
        {
          label : '无效',
          value : '1'
        },
      ],
      rectification : [],
      troubleshooting : [],
      dangerType : [],
      dangerSon : [],
      dangerLevel : []
    }
  },
  created() {
    this.getSelectList();
    this.reset();
    this.getHiddenSelect(0)
    if(this.operation === 'edit'){
      this.getDetailData();
    }
  },
  methods : {
    reset(){
      this.ruleForm = {
        itemState : '',
        reformDay : '',
        reformUnitType : '',
        itemCode : '',
        categoryId : '',
        categoryPid : '',
        screeningType : '',
        itemName : '',
      }
    },
    dangerChange(){
      this.ruleForm.categoryId = null;
      this.getHiddenSelect(this.ruleForm.categoryPid)
    },
    getHiddenSelect(id){
      subCategoryList(id).then(res => {
        if(res.code == 0){
          if(id == 0){
            this.dangerType = res.data;
          }else{
            this.dangerSon = res.data;
          }
        }
      })
    },
    getSelectList(){
      let dictArray = ['screeningType','unitType','hdLevel'];
      dictArray.forEach(item => {
        this.getDictList(item);
      })
    },
    getDictList(label){
      getDictData(label).then(res => {
        switch (label){
          case 'screeningType' :
            this.troubleshooting = res.data;
            break;
          case 'unitType' :
            this.rectification = res.data;
            break;
          case 'hdLevel' :
            this.dangerLevel = res.data;
            break;
        }
      })
    },
    getDetailData(){
      libraryDetail(this.editData.id).then(res => {
        if(res.code == 0){
          this.ruleForm = res.data;
          this.getHiddenSelect(this.ruleForm.categoryPid);
        }
      })
    },
    cancelAddPlan(){
      this.$emit('cancelModal')
    },
    submitAdd(number){
      this.$refs['ruleForm'].validate((vaild) => {
        if(vaild){
          if(this.operation == 'add'){
            this.ruleForm.itemType = number;
            libraryAdd(this.ruleForm).then(res => {
              if(res.code == 0){
                this.$message.success('操作成功');
                this.$emit('successModal');
              }else{
                this.$message.error(res.msg);
              }
            })
          }else{
            libraryEdit(this.ruleForm).then(res => {
              if(res.code == 0){
                this.$message.success('操作成功');
                this.$emit('successModal');
              }else{
                this.$message.error(res.msg);
              }
            })
          }
        }else{
          this.$message.error('请正确填写');
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
