<template>
  <systemModal ref="recordModal" :title="title" :showFooter="false">
    <div class="record-list">
      <ul>
        <li>
          <h3></h3>
          <p>
            <span>参与盘点人员：</span>
            <span>张振宇、理想</span>
          </p>
          <p>
            <span>操作人员：</span>
            <span>理想</span>
          </p>
          <p>
            <span>盘点情况：</span>
            <span>沙袋-2；手套-2</span>
          </p>
        </li>
      </ul>
    </div>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
export default {
  components: {
    systemModal
  },
  data() {
    return {
      title: "物资盘点记录"
    };
  },
  mounted() {
    this.queryRecordData();
  },
  methods: {
    queryRecordData() {},
    handleModalShow() {
      this.$refs.recordModal.handleOpen();
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
