import { httpInit } from "./http";
const http = httpInit("/gzdt-stand");
const httpNew = httpInit("/gzdt-org");

// 分页查询应急培训列表
export const getTrainPageList = data =>
    /*http.get(`/stand/train_info/list/${params.lineId}/${params.siteId}
    /${params.label}/${params.state}/${params.startTime}/${params.endTime}
    /${params.page}/${params.size}`);*/
    http.post(`/stand/train_info/page`, data);

// 新增应急培训数据
export const addTrain = data => http.post(`/stand/train_info/info`, data);
// 查询操作历史
export const operation_historyList = data => http.post(`/stand/operation_history/list`, data);
// 更新应急培训
export const trainInfoUpdate = data => http.post(`/stand/train_info/update`, data);

// 编辑应急培训
export const editTrain = data => http.put(`/stand/train_info/info/${data.id}`, data.data);

// 查询应急培训详情
export const getTrainDetail = params => http.get(`/stand/train_info/info/${params.id}`);

// 查询应急培训详情
export const getPlanDetail = planId => http.get(`/stand/rehearsal/log/info/plan/${planId}`);

// 列表查询应急演练
// export const getRehearsalPageList = params => http.get(`/stand/rehearsal/list/${params.lineId}/${params.state}/${params.date}/${params.label}/${params.page}/${params.size}`);
export const getRehearsalPageList = data => http.post(`/stand/rehearsal/list`, data);

// 应急演练新增
export const addRehearsal = data => http.post(`/stand/rehearsal/info`, data);

// 新增施工单位演练计划
export const addRehearsalBuild = data => http.post(`/stand/rehearsal/add_site_plan`, data);

// 新增救援队演练计划
export const addRehearsalTeam = data => http.post(`/stand/rehearsal/add_rescue_team_plan`, data);
// 应急演练修改
export const editRehearsal = data => http({ url: `/stand/rehearsal/modify/${data.id}`, data, method: "put", headers: { "Content-Type": "application/json" } })

// 应急演练详情
export const getRehearsalDetail = params => http.get(`/stand/rehearsal/info/${params.id}`);

// 上传应急演练附件
export const uploadRehearsalFile = data => http.post(`stand/rehearsal/attach/saveOrUpdateRehearsal`, data);

// 应急演练附件删除
export const delRehearsalFile = params => http.delete(`/stand/rehearsal/attach/info/${params.id}`);


// export const editResource = data => http.put(`/resource/info/${data.id}`, data);
// 应急演练状态修改
export const updateRehearsalState = params => http.put(`/stand/rehearsal/modify/${params.id}`, params);

// 应急演练状态修改
export const submitReport = params => http.put(`/stand/rehearsal/submit_report`, params);

// 演练计划审核通过
// export const approvePlan = params => http({ url: `/stand/rehearsal/modify/approve/${params.id}`,
//     method: "put", data: params, headers: { "Content-Type": "application/json" } });
export const approvePlan = params => http.put(`/stand/rehearsal/modify/approve/${params.id}`, params.opinion);

// 演练计划审核驳回
export const noApprovePlan = params => http.put(`/stand/rehearsal/modify/non_approve/${params.id}`, params.opinion);

// 演练报告审核通过
// export const approveReport = params => http({ url: `/stand/rehearsal/modify/pass/${params.id}`,
//     method: "put", data: params, headers: { "Content-Type": "application/json" } });
export const approveReport = params => http.put(`/stand/rehearsal/modify/pass/${params.id}`, params.opinion);

// 演练报告审核驳回
export const noApproveReport = params => http.put(`/stand/rehearsal/modify/non_pass/${params.id}`, params.opinion);

// 应急演练操作记录列表
export const getHistoryById = params => http.get(`/stand/rehearsal/log/info/plan/${params.planId}`);

// 撤回提交计划
export const withdrawalPlan = id => http.get(`/stand/rehearsal/withdrawal_plan/${id}`);

// 撤回提交报告
export const withdrawalReport = id => http.get(`/stand/rehearsal/withdrawal_report/${id}`);

// 根据ID查询单位详情
export const queryDeptDetail = (id) =>
    httpNew.get(`/api/com/${id}`);



// 合并
//数据中心-应急演练统计
export const getryById = params => http.get(`/stand/rehearsal/list/map/${params.lineId}/${params.state}/${params.date}/${params.label}/${params.page}/${params.size}`);

//三防演练开展情况
export const getdevelopmentDrill = params => http.get(`/stand/rehearsal/list/${params.lineId}/${params.siteId}`);


// 推送施工单位演练计划(建设单位下发演练任务)
export const pushSitePlan = data => http.post(`/stand/rehearsal/push_site_plan`, data);

// 推送救援队演练计划(建设单位下发演练任务)
export const pushRescueTeamPlan = data => http.post(`/stand/rehearsal/push_rescue_team_plan`, data);

// 应急培训详情
// export const getTrainDetailByNotic = params => http.get(`/stand/train_info/backlog/info/${params.historyId}`);


// 应急培训详情（待办页面）
export const backlogInfo = historyId => http.get(`/stand/train_info/backlog/info/${historyId}`);

// 通过记录id查询应急演练详情
export const getByLogId = id => http.get(`/stand/rehearsal/getByLogId/${id}`);
