<template>
  <!--三防工作统计表监理审  -->
  <div>
    <el-descriptions title="" style="width: 100%;color:#ffffff">
      <el-descriptions-item label="汇总单位">{{
        checkName
      }}</el-descriptions-item>

      <el-descriptions-item label="汇总次数"
        >第{{ cuns }}次</el-descriptions-item
      >
    </el-descriptions>
    <el-table
      ref="tableDatas"
      :data="tableDatas"
      id="tableDatas"
      border
      :row-style="{ height: '30px' }"
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column label="线路-工点" align="center" prop="name" width="420">
      </el-table-column>
      <el-table-column
        prop="workGroupCount"
        label="派出工作组数"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="workGroupCount"
        label="出动检查人数"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="siteCount"
        label="检查在建工点数"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="hiddenDangerCount"
        label="发现隐患数量"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="hiddenDangerCount"
        label="整改隐患数量"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column prop="evacueesCount" label="撤离人员" align="center">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// import { listSiteDetailSummaryInfo } from "@/api/responseTem";
export default {
  props: {
    strery: {
      type: String,
    },
    checkId: {
      type: String,
    },
    checkName: {
      type: String,
    },
    cuns: {
      type: Number,
    },
    qualityResponseId: {
      type: Number,
    },
    tableDatas: {
      type: Array,
    },
  },
  watch: {
    strery: {
      handler(val) {
        if (val == "view") {
          this.approver = false;
          this.deter = true;
        } else {
          this.approver = true;
          this.deter = false;
        }
      },
      immediate: true,
      deep: true,
    },
    data() {
      return {
        times: [],

        tableTotalData: [],
        checkPageLoading: false,
        //审批驳回
        turnDownDialog: false,
        checkDialog: false,
        formInline: {
          lineId: "",
          branchUuid: "",
          siteUuid: "",
        },
        // 驳回参数
        id: "",
        constructionCurrentId: "",
        // 查看返回的列表
        checkData: [],
        currentId: "",
        //线路工点的下拉选
        lineList: [],
        branchList: [],
        siteList: [],
        responseType: "",
        responseLevel: "",
        textarea2: "",
        approver: true,
        deter: false,
      };
    },
    mounted() {},
  },
  methods: {
    getdata() {},
    //同意
    subtable() {},
    //驳回
    BatchApproval() {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-descriptions__body {
  color: #ffffff;
}
::v-deep .el-table {
  background-color: #081742 !important;
  height: 490px !important;
  overflow: auto;
}
::v-deep .el-table__cell {
  border: 1px solid #143363 !important;
}
::v-deep .el-radio-button {
  width: 75px;
}
::v-deep .el-radio-button__inner {
  width: 65px;
}
::v-deep .el-descriptions-item__cell {
  background: #081742;
}
::v-deep .el-table--border::after {
  width: 0;
}
</style>
