<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-model"
    >
      <el-form-item label="实际演练时间" prop="executeTime">
        <el-date-picker
          v-model="ruleForm.executeTime"
          type="date"
          placeholder="实际演练时间"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="实际参演人数" prop="joinCount">
        <el-input
          v-model="ruleForm.joinCount"
          placeholder="实际参演人数"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练方案" prop="label">
        <Uploader
          @uploader="uploaderProgramme"
          :file-array="programmeList"
          :accept="'.pdf,image/*'"
        ></Uploader>
      </el-form-item>

      <el-form-item label="脚本" prop="label">
        <Uploader
          @uploader="uploadScript"
          :file-array="scriptList"
          :accept="'.pdf,image/*'"
        ></Uploader>
      </el-form-item>

      <el-form-item label="影像资料" prop="label">
        <Uploader
          @uploader="uploadImage"
          :file-array="videoList"
          :accept="'.pdf,image/*,.mp4'"
        ></Uploader>
      </el-form-item>

      <el-form-item label="签到表" prop="label">
        <Uploader
          @uploader="uploadSign"
          :file-array="signList"
          :accept="'.pdf,image/*'"
        ></Uploader>
      </el-form-item>
      <el-form-item label="总结" prop="label">
        <Uploader
          @uploader="uploadSummart"
          :file-array="summaryList"
          :accept="'.pdf,image/*'"
        ></Uploader>
      </el-form-item>
      <el-form-item label="评估报告" prop="label">
        <Uploader
          @uploader="uploadAssessment"
          :file-array="assessmentList"
          :accept="'.pdf,image/*'"
        ></Uploader>
      </el-form-item>
      <el-form-item label="其他附件" prop="label">
        <Uploader
          @uploader="uploadOther"
          :file-array="otherList"
          :accept="'.pdf,image/*'"
        ></Uploader>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="outerVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitUpload">确 定</el-button>
    </div>
  </div>
</template>

<script>
import Uploader from "@/views/Contingency/System/TrainingDrill/components/Uploader";
import {
  getRehearsalDetail,
  submitReport,
  uploadRehearsalFile,
} from "@/api/stand";
export default {
  name: "addPlan",  
  components: {
    Uploader,
  },
  props: {
    planId: {
      default: "",
    },
    operation: {
      default: "",
    },
    uploadData: {
      default: () => {},
    },
  },
  data() {
    return {
      ruleForm: {
        executeTime: "",
        joinCount: "",
      },
      rules: {
        executeTime: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
        joinCount: [
          {
            required: true,
            message: "实际参与人数不能为空",
            trigger: "change",
          },
        ],
      },
      tags: [],
      dialogVisible: false,
      programmeList: [],
      scriptList: [],
      videoList: [],
      signList: [],
      summaryList: [],
      assessmentList: [],
      otherList: [],
    };
  },
  created() {
    if (this.operation === "edit") {
      this.getDetailData();
    }
  },
  methods: {
    getDetailData() {
      let params = {
        id: this.uploadData.id,
      };
      getRehearsalDetail(params).then((res) => {
        if (res.code == 0) {
          this.ruleForm = res.data;
          if (this.ruleForm.attachs && this.ruleForm.attachs.length > 0) {
            this.programmeList = this.ruleForm.attachs.filter((item) => {
              return item.attrType == 1;
            });
            this.scriptList = this.ruleForm.attachs.filter((item) => {
              return item.attrType == 2;
            });
            this.videoList = this.ruleForm.attachs.filter((item) => {
              return item.attrType == 3;
            });
            this.signList = this.ruleForm.attachs.filter((item) => {
              return item.attrType == 4;
            });
            this.summaryList = this.ruleForm.attachs.filter((item) => {
              return item.attrType == 5;
            });
            this.assessmentList = this.ruleForm.attachs.filter((item) => {
              return item.attrType == 6;
            });
            this.otherList = this.ruleForm.attachs.filter((item) => {
              return item.attrType == 7;
            });
          }
          // var arr = this.ruleForm.fileUrl.split("/");
          // this.ruleForm.fileName = arr[arr.length - 1];
        }
      });
    },
    uploaderProgramme(event) {
      let params = {
        planId: this.uploadData.id,
        attrName: event.originalName,
        attrType: 1,
        link: event.uri,
        localPath: event.fullPath,
      };
      uploadRehearsalFile(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message.success("上传成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    uploadScript(event) {
      let params = {
        planId: this.uploadData.id,
        attrName: event.originalName,
        attrType: 2,
        link: event.uri,
        localPath: event.fullPath,
      };
      uploadRehearsalFile(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message.success("上传成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    uploadImage(event) {
      let params = {
        planId: this.uploadData.id,
        attrName: event.originalName,
        attrType: 3,
        link: event.uri,
        localPath: event.fullPath,
      };
      uploadRehearsalFile(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message.success("上传成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    uploadSign(event) {
      let params = {
        planId: this.uploadData.id,
        attrName: event.originalName,
        attrType: 4,
        link: event.uri,
        localPath: event.fullPath,
      };
      uploadRehearsalFile(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message.success("上传成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    uploadSummart(event) {
      let params = {
        planId: this.uploadData.id,
        attrName: event.originalName,
        attrType: 5,
        link: event.uri,
        localPath: event.fullPath,
      };
      uploadRehearsalFile(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message.success("上传成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    uploadAssessment(event) {
      let params = {
        planId: this.uploadData.id,
        attrName: event.originalName,
        attrType: 6,
        link: event.uri,
        localPath: event.fullPath,
      };
      uploadRehearsalFile(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message.success("上传成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    uploadOther(event) {
      let params = {
        planId: this.uploadData.id,
        attrName: event.originalName,
        attrType: 7,
        link: event.uri,
        localPath: event.fullPath,
      };
      uploadRehearsalFile(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message.success("上传成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    submitUpload() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.ruleForm.id = this.uploadData.id;
          submitReport(this.ruleForm).then((res) => {
            if (res.code == 0) {
              this.$message.success("操作成功");
              this.$emit("successUpload");
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          this.$message.error("请正确填写");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-class {
  display: flex;
  flex-direction: column;
}
</style>
