<template>
  <!-- 首页弹框 -->
  <div class="material-container h-full">
    <el-form
      ref="queryModal"
      :inline="true"
      :model="queryModal"
      class="demo-form-inline"
    >
      <el-form-item prop="">
        <el-select v-model="queryModal.type" clearable placeholder="请选择类型">
          <el-option
            v-for="item of typeList"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="">
        <el-select
          v-model="queryModal.currentLevel"
          clearable
          placeholder="请选择级别"
        >
          <el-option
            v-for="item of leveloptions"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="">
        <el-date-picker
          v-model="queryModal.times"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </el-form-item>
    </el-form>

    <div class="tablese">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        max-height="500"
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="60"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="id"
          show-overflow-tooltip
          label="三防应急响应"
        >
          <template slot-scope="scope">
            {{ scope.row.type ? scope.row.type.label : ""
            }}{{
              scope.row.currentLevel ? scope.row.currentLevel.label : ""
            }}级响应
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="id"
          show-overflow-tooltip
          label="响应类型"
        >
          <template slot-scope="scope">
            {{ scope.row.type ? scope.row.type.label : "" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="responseName"
          show-overflow-tooltip
          label="响应级别"
        >
          <template slot-scope="scope">
            {{ scope.row.currentLevel ? scope.row.currentLevel.label : "" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="created"
          show-overflow-tooltip
          label="启动时间"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="isRelegation"
          show-overflow-tooltip
          label="是否升/降级"
        >
          <template slot-scope="scope">
            {{
              scope.row.isRelegation
                ? scope.row.isRelegation == 0
                  ? "/"
                  : scope.row.isRelegation == 1
                  ? "升级"
                  : "降级"
                : "/"
            }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="relegationTime"
          show-overflow-tooltip
          label="升/降级时间"
        >
          <template slot-scope="scope">
            {{ scope.row.relegationTime ? scope.row.relegationTime : "/" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="relegationLevel"
          show-overflow-tooltip
          label="升/降级等级"
        >
          <template slot-scope="scope">
            {{
              scope.row.relegationLevelLabel
                ? scope.row.relegationLevelLabel
                : "/"
            }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="endTime"
          show-overflow-tooltip
          label="结束时间"
        >
          <template slot-scope="scope">
            {{ scope.row.endTime ? scope.row.endTime : "/" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="status"
          show-overflow-tooltip
          label="状态"
          :formatter="statusFormatter"
        >
        </el-table-column>
        <el-table-column align="center" label="操作" width="120px">
          <template slot-scope="scope">
            <el-button type="text" @click="detailHandle(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="relative text-center mt-4">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :total="page.count"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <Modal
      ref="modalDetail"
      :width="'1300px'"
      :title="title"
      @closeDialog="detailClose"
    >
      <DetailPage
        @detailClose="detailClose"
        v-if="detailDialog"
        :temList="temList"
        :historyData="historyData"
      ></DetailPage>
    </Modal>
  </div>
</template>

<script>
import { getResList, ResLog } from "@/api/responseTem";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import DetailPage from "./checkDetails.vue";
import Modal from "@/components/Modal/modal.vue";
import { operatinghistoryList } from "@/api/response";
export default {
  props: {
    level: Number,
    type: String,
  },
  components: {
    DetailPage,
    Modal,
  },
  data() {
    return {
      loading: false,
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      queryModal: {
        type: "",
        currentLevel: "",
        times: [],
      },
      //发布三防响应
      issueDialog: false,
      // 应急响应升/降级
      upgradeTitle: "升/降级",
      upgradeDialog: false,
      // 应急响应结束
      endDialog: false,
      // 历史操作记录
      historyDialog: false,
      detailDialog: false,

      typeList: [],
      leveloptions: [],
      temList: {},
      title: "",
      historyData: [],
      logList: [],
    };
  },
  created() {
    queryDictByLabel({ label: "应急类别" }).then((res) => {
      queryDictNodesById({ id: res.data.id }).then((el) => {
        this.typeList = el.data;
      });
    });
    queryDictByLabel({ label: "当前等级" }).then((res) => {
      queryDictNodesById({ id: res.data.id }).then((el) => {
        this.leveloptions = el.data;
      });
    });
  },
  mounted() {},
  methods: {
    //参数筛选
    removeProperty(obj) {
      Object.keys(obj).forEach((e) => {
        if (
          obj[e] === "" ||
          obj[e] === undefined ||
          obj[e] === null ||
          obj[e] === "null"
        ) {
          delete obj[e];
        }
      });
      return obj;
    },
    //初始化查询
    initdata() {
      if (this.type == "-") {
        this.queryModal.type = "";
      } else {
        this.queryModal.type = this.typeList.find(
          (e) => e.label == this.type
        ).id;
      }
      console.log(this.level);
      console.log(this.leveloptions);
      if (this.level == 4) {
        this.queryModal.currentLevel = "";
      } else {
        this.queryModal.currentLevel = this.leveloptions[this.level].id;
      }
      this.handleSearch();
    },
    //查询
    handleSearch() {
      this.page.pageNo = 1;
      this.loadData();
    },
    //分页查询列表
    async loadData() {
      this.loading = true;
      let params = {
        page: this.page.pageNo,
        size: this.page.pageSize,
        startDate: this.queryModal.times[0] ? this.queryModal.times[0] : "",
        endDate: this.queryModal.times[1] ? this.queryModal.times[1] : "",
        currentLevel: this.queryModal.currentLevel,
        type: this.queryModal.type,
      };
      params = this.removeProperty(params);
      let res = await getResList(params);
      if (res.code == 0) {
        console.log(res);
        this.loading = false;
        this.tableData = res.data.records;
        this.tableData.forEach((e) => {
          console.log(e.status);
          if (e.relegationLevel) {
            this.leveloptions.forEach((el) => {
              let dd = parseInt(el.id);
              let nu = parseInt(e.relegationLevel);
              if (dd == nu) {
                e.relegationLevelLabel = el.label;
              }
            });
          }
        });
        this.page.count = res.data.count;
      } else {
        this.loading = false;
      }
    },
    statusFormatter(val) {
      switch (val.status) {
        case 0:
          return "进行中";
        case 1:
          return "响应结束,事件闭合中";
        case 2:
          return "已结束";
      }
    },
    //发布三防响应
    issue() {
      this.issueDialog = true;
    },
    issueClose(msg) {
      this.issueDialog = false;
      if (msg == true) {
        this.handleSearch();
      }
    },
    // 升级/降级
    upgrade(data) {
      this.temList = data;
      this.upgradeDialog = true;
    },
    upgradeClose(msg) {
      console.log(msg);
      this.upgradeDialog = false;
      if (msg == true) {
        this.handleSearch();
      }
    },
    // 结束
    end(data) {
      this.temList = data;
      this.endDialog = true;
    },
    endClose(msg) {
      this.endDialog = false;
      if (msg == true) {
        this.handleSearch();
      }
    },
    // 查看详情
    detailHandle(data) {
      this.title = data.type.label + data.currentLevel.label + "级响应详情";

      this.temList = data;
      this.$refs.modalDetail.handleOpen();
      this.detailDialog = true;
      let params = {
        incidenceId: data.id,
        operationType: 384,
      };
      operatinghistoryList(params)
        .then((res) => {
          if (res.code == 0) {
            this.historyData = res.data;
          }
        })
        .catch((err) => {
          this.historyData = [];
          console.log(err);
        });
    },
    detailClose(msg) {
      this.detailDialog = false;
      if (msg == true) {
        this.handleSearch();
      }
    },
    // 历史操作
    async historyHandle(data) {
      let params = {
        incidenceId: data.id,
        operationType: 384,
      };
      let res = await ResLog(params);
      if (res.code == 0) {
        console.log(res);
        this.logList = res.data;
        this.historyDialog = true;
      } else {
        this.$message.error("数据出错");
      }
    },
    historyClose() {
      this.historyDialog = false;
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../style/elTable.scss";
::v-deep .el-tabs__item {
  color: #fff !important;
}
::v-deep .el-tabs__item.is-active {
  color: #409eff !important;
}

::v-deep .el-input--prefix .el-input__inner {
  color: #fff;
  background-color: #18467f;
  border: 0px solid #dcdfe6;
}
::v-deep .el-input__inner {
  color: #fff;
  background-color: #18467f;
  border: 0px solid #dcdfe6;
}
::v-deep .el-range-input {
  color: #fff;
  background-color: #18467f;
  border: 0px solid #dcdfe6;
}
.checkDialog ::v-deep .el-dialog__body {
  height: 680px;
  overflow: auto;
}
.tablese ::v-deep .el-table__body-wrapper {
  height: 340px !important;
  .el-table__cell .gutter {
    border: none;
  }
}
.el-table--border::after {
  width: 0;
}

::v-deep .modal-container {
  height: 700px;
  overflow: auto;
  position: relative;
  z-index: 11111;
}
::v-deep .corner-wrap {
  .corner {
    display: none;
  }
}
</style>
