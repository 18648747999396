<template>
  <el-dialog
    :title="title"
    width="60%"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :before-close="turnOff"
  >
    <el-tabs style="height: 200px;">
      <el-tab-pane label="预警数据">
        <el-form class="" :model="dataForm" label-width="100px" ref="dataForm" :size="size" :disabled="title == '详情'">
          <el-row>
            <el-col span="8">
              <el-form-item label="线路标段工点" prop="site">
                <el-input v-model="dataForm.site" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="设备名称" prop="craneTime">
                <el-input v-model="dataForm.site" disabled></el-input>
                <!-- <el-input v-model="dataForm.warnTime"></el-input> -->
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="起吊时间" prop="alarmReason">
                <el-select v-model="dataForm.alarmKey" style="width: 100%;" placeholder="请选择报警原因" clearable>
                  <el-option v-for="item in warnReason" :key="item.id" :label="item.label" :value="item.code"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="落吊时间" prop="craneHeight">
                <el-input v-model="dataForm.height"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="预警规则名称" prop="craneRange">
                <el-input v-model="dataForm.rrange"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="预警内容" prop="craneTiltAngle">
                <el-input v-model="dataForm.obliguity"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="规则值" prop="craneWindSpeed">
                <el-input v-model="dataForm.windSpeed"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="实际值" prop="craneTorquePercentage">
                <el-input v-model="dataForm.momentPercent"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
      </el-tab-pane>
      <el-tab-pane label="预警处置记录">
        <el-form ref="form" :model="form" label-width="80px">
          <el-row>
            <el-col span="8">
              <el-form-item label="排查单位">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="排查人">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="排查时间">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="排查结论">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="现场照片">
                <el-image
                    style="width: 100px; height: 100px"
                    :src="form.url"
                    :preview-src-list="[form.url]">
                </el-image>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="整改后照片">
                <el-image
                    style="width: 100px; height: 100px"
                    :src="form.url"
                    :preview-src-list="[form.url]">
                </el-image>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
      </el-tab-pane>
    </el-tabs>
    <div slot="footer" class="dialog-footer">
      <el-button :size="size" v-if="title == '修改'" @click="cancel"
        >取 消</el-button
      >
      <el-button
        :size="size"
        v-if="title == '修改'"
        type="primary"
        @click="submit"
        >确 定</el-button
      >
    </div>
    <el-dialog
      title="预览"
      :visible.sync="viewVisible"
      center
      width="60%"
      @close="closePreview"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <iframe
        class="iframe"
        :src="src"
        frameborder="0"
        width="100%"
        height="700px"
      ></iframe>
    </el-dialog>
  </el-dialog>
</template>
<script>
import PopupTreeInput from "@/components/PopupTreeInput";
import { crawlerDataSaveOrUpdate } from "@/api/deviceManagement";

export default {
  props: [
    "dialogVisible",
    "detail",
    "title",
    "warnTypeList",
    "sensorStatusList",
    "operateList"
  ],
  components: { PopupTreeInput },
  data() {
    return {
      warnItemList: [],
      dataForm: {},
      size: "small",
      lineData: [],
      deptTreeProps: {
        label: "name",
        children: "children"
      },
      isShowWarnImg: false,
      isShowWarnFile: false,
      src: "",
      viewVisible: false,
      rulesForm: {
        site: [{ required: true, message: "请输入预警工地", trigger: "blue" }],
        warnTime: [
          { required: true, message: "请输入预警时间", trigger: "blue" }
        ],
        camera: [
          { required: true, message: "请输入预警摄像头", trigger: "blue" }
        ],
        warnItem: [
          { required: true, message: "请输入预警项", trigger: "blue" }
        ],
        warnText: [
          { required: true, message: "请输入预警内容", trigger: "blue" }
        ],
        images: [{ required: true, message: "请输入图片", trigger: "blue" }]
      },
      form : {}
    };
  },
  created() {
    this.dataForm = JSON.parse(this.detail);
  },
  mounted() {
    this.getTree();
    this.dataForm.dealAlarmSwitch += "";
    if (
      this.dataForm.imgList &&
      this.dataForm.imgList.length &&
      this.title == "详情"
    ) {
      this.isShowWarnImg = true;
    } else {
      this.isShowWarnImg = false;
    }
    if (
      this.dataForm.fileList &&
      this.dataForm.fileList.length &&
      this.title == "详情"
    ) {
      this.isShowWarnFile = true;
    } else {
      this.isShowWarnFile = false;
    }
  },
  methods: {
    getOperateMsg(data) {
      this.dataForm.workType = data.code;
      this.dataForm.workTypeName = data.label;
    },
    getWarnMsg(data) {
      this.dataForm.warnStatus = data.code;
      this.dataForm.warnStatusName = data.label;
    },
    getsensorMsg(data) {
      this.dataForm.sensorStatus = data.code;
      this.dataForm.sensorStatusName = data.label;
    },
    preview(index, images) {
      this.$emit("imagesPreview", index, images);
    },
    turnOff() {
      this.$emit("close");
    },
    cancel() {
      this.$emit("cancel", false);
    },
    // 文件下载
    downloadImg(url, name) {
      var x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function(e) {
        console.log(e, "ee");
        var url = window.URL.createObjectURL(x.response);
        var a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      };
      x.send();
    },
    closePreview() {
      this.viewVisible = false;
    },
    // 预览
    view(url) {
      this.viewVisible = true;
      this.src = url;
    },
    // 表单提交
    submit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            const that = this;
            let params = Object.assign({}, that.dataForm);
            crawlerDataSaveOrUpdate(params).then(res => {
              this.editLoading = false;
              if (res.code == 200) {
                this.$message({ message: "操作成功", type: "success" });
                this.dialogVisible = false;
                this.$refs["dataForm"].resetFields();
              } else {
                this.$message({
                  message: "操作失败, " + res.msg,
                  type: "error"
                });
              }
              this.$emit("submit");
            });
          });
        }
      });
    },
    getTree() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    lineTreeCurrentChangeHandle(data) {
      if (data.children == null) {
        this.dataForm.siteId = data.id;
        this.dataForm.site = data.name;
      }
    },
    handleIsopenSelect() {
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss" scoped>
.warnImgBox {
  text-align: left;
  .warnImg {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
.warnFileBox {
  ul {
    margin: 0;
    padding-left: 0px;
    li {
      list-style: none;
      line-height: 32px;
      position: relative;
      text-indent: 20px;
      text-align: left;
    }
    li::before {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #333;
      position: absolute;
      top: 40%;
    }
    span {
      color: #337ab7;
      cursor: pointer;
      margin-left: 10px;
    }
    .del {
      color: #ff5454;
    }
    a {
      text-decoration: none;
      color: #337ab7;
      margin-left: 10px;
    }
    a:hover,
    span:hover {
      text-decoration: underline;
    }
  }
}
.el-select {
  width: 100%;
}
</style>
