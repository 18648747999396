<template>
  <el-dialog
    width="50%"
    :visible.sync="parentData.show"
    :title="parentData.title"
    :before-close="handleClickClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-steps
      :active="activeName"
      finish-status="success"
      simple
      style="margin-bottom: 20px"
    >
      <el-step title="用户信息"></el-step>
      <!-- <el-step title="选择权限"></el-step> -->
    </el-steps>
    <!-- v-if="activeName == 0" -->
    <el-form ref="form" :model="form" :rules="rules" label-width="140px">
      <el-form-item label="登录名称" prop="userName">
        <el-input
          v-model="form.userName"
          :disabled="Boolean(parentData.data.id)"
        ></el-input>
      </el-form-item>


      <el-form-item label="密码" prop="password" v-if="!parentData.data.id">
        <el-input
          show-password
          v-model="form.password"
          placeholder="密码必须由8-16位字母、数字及下划线组成"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="form.mobile"></el-input>
      </el-form-item>
      <el-form-item label="修改密码" v-if="parentData.data.id">
        <el-button
          type="primary"
          @click="changePass"
          v-if="!passShow"
          :loading="resetPwdLoading"
          >点击重置密码</el-button
        >
        <!-- <el-input
          show-password
          v-model="form.password"
          v-if="passShow"
          placeholder="密码必须由8-16位字母、数字及下划线组成"
        ></el-input> -->
      </el-form-item>
      <el-form-item label="用户姓名" prop="realName">
        <el-input v-model="form.realName"></el-input>
      </el-form-item>
      <el-form-item label="所属单位" prop="companyId">
        <el-select
          v-model="form.companyId"
          placeholder="请选择"
          @change="comChange"
          style="width: 100%"
        >
          <el-option
            v-for="item in comList"
            :key="item.uuid"
            :label="item.companyName"
            :value="item.uuid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属部门" prop="departmentId">
        <el-select
          v-model="form.departmentId"
          placeholder="请选择"
          filterable
          style="width: 100%"
          @change="deptChange"
        >
          <el-option
            v-for="item in deptList"
            :key="item.uuid"
            :label="item.departmentName"
            :value="item.uuid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联角色" prop="roleIds">
        <el-select
          v-model="form.roleIds"
          multiple
          filterable
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in roleList"
            :key="item.id"
            :label="item.roleName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <!-- <el-button type="primary" @click="onSubmit('form')">下一步</el-button> -->
        <el-button type="primary" @click="onSubmit('form')">确定</el-button>
        <el-button @click="handleClickClose">关闭</el-button>
      </el-form-item>
    </el-form>
    <!-- <div class="permission form" v-loading="loading" v-else>
      <el-tree
        :data="tree"
        show-checkbox
        node-key="id"
        ref="trees"
        highlight-current
        :indent="40"
        :default-checked-keys="targetRules"
        :props="{
          children: 'sub',
          label: 'resourceName',
        }"
      ></el-tree>
      <div class="form-btns text-center">
        <el-button type="primary" @click="onUpdata">确认</el-button>
        <el-button @click="handleClickClose">关闭</el-button>
      </div>
    </div> -->
  </el-dialog>
</template>
<script>
import { getRole } from "@/api/role";
import {
  getQxComs,
  getDepts,
  addAccounts,
  modifyAccount,
  resetPwd,
} from "@/api/ucenter";
/* import {
  updateBatchUser,
  queryResourceByRole,
  resourceAll,
} from "@/api/resource"; */
// import { log } from 'video.js'
import { queryDeptDetail } from "@/api/org";

export default {
  emits: ["offIt"],
  props: ["parentData"],
  data() {
    let issMobile = /^1[3456789]\d{9}$/
    let ismobile = (rule, value, callback) => {
      if (!issMobile.test(value)) {
        return callback(new Error("手机号码格式错误！"))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      resetPwdLoading: false,
      tree: [],
      targetRules: [],
      list: {},
      userData: {},
      activeName: 0,
      user: JSON.parse(localStorage.user),
      form: {
        userName: "",
        password: "",
        mobile:'',
        companyId: "",
        departmentId: "",
        realName: "",
        roleIds: [],
      },
      comList: [],
      deptList: [],
      roleList: [],
      passShow: false,
      userId: "",
      rules: {
        userName: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { required: true, validator: ismobile, trigger: "blur" }
        ],
        password: [
          {
            required: true,
            message: "请输入密码，密码必须由8-16位字母、数字及下划线组成",
            trigger: "blur",
          },
        ],
        companyId: [
          {
            required: true,
            message: "请选择单位",
            trigger: "change",
          },
        ],
        departmentId: [
          {
            required: true,
            message: "请选择部门",
            trigger: "change",
          },
        ],
        roleIds: [
          {
            required: true,
            message: "请选择角色",
            trigger: "change",
          },
        ],
        realName: [
          { required: true, message: "请填写用户姓名", trigger: "blur" },
        ],
        isbuild: true, //单位选择的要是建设单位就把角色清空
      },
    };
  },
  mounted() {
    this.form = {
      userName: "",
      password: "",
      companyId: "",
      departmentId: "",
      realName: "",
      roleIds: [],
      mobile: ""
    };
    if (this.parentData.data.id) {
      this.form.id = this.parentData.data.id;
      this.form.userName = this.parentData.data.userName;
      this.form.companyId = this.parentData.data.companyId;
      this.form.departmentId = this.parentData.data.departmentId;
      this.form.realName = this.parentData.data.realName;
      this.form.roleIds = this.parentData.data.roleIdList;
      this.form.mobile = this.parentData.data.mobile || "";
      // this.parentData.data.roles.forEach((e) => {
      //   this.form.roleIds.push(e.id);
      // });
    }
    // this.getResourceTree();
    if (this.parentData.data.id) {
      // this.comChange(this.parentData.data.companyId, false);
      queryDeptDetail(this.parentData.data.companyId).then((res) => {
        getDepts(this.parentData.data.companyId).then((ress) => {
          if (ress.code == 0) {
            this.deptList = ress.data || [];

            if (res.data.category == "build") {
              let params = {
                pageNo: 1,
                pageSize: 10000,
                name: "-",
                departmentId: "-",
                parentId: "-",
                unitType: "-",
                companyId: "-",
              };
              let type = this.deptList.find(
                (item) => item.uuid == this.parentData.data.departmentId
              );
              if (type.roleType == 3) {
                params.unitType = "constManagDept";
              } else if (type.roleType == 4) {
                params.unitType = "qualitySafetyDep";
              } else {
                params.unitType = type.roleType;
              }
              this.queryAllRole(params);
            } else {
              let params = {
                pageNo: 1,
                pageSize: 10000,
                name: "-",
                departmentId: "-",
                parentId: "-",
                unitType: res.data.category,
                companyId: "-",
              };
              this.queryAllRole(params);
            }
          }
        });
      });
    }
    this.getAllComs();
    // this.queryAllRole()
  },
  methods: {
    //关闭窗口
    handleClickClose() {
      this.$emit("offIt");
    },
    //打开修改密码确认框
    changePass() {
      this.$confirm("确认重置用户密码为：GZ123456 么？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.pwdreset(this.parentData.data.id);
      });
    },
    //修改密码
    async pwdreset(id) {
      this.resetPwdLoading = true;
      let params = {
        id: id,
      };
      let res = await resetPwd(params);
      if (res.code === 0) {
        this.$message({
          type: "success",
          message: "重置成功!",
        });
        this.resetPwdLoading = false;
      } else {
        this.resetPwdLoading = false;
        this.$message.error(res.message);
      }
    },
    //获取所有单位
    async getAllComs() {
      let res = await getQxComs();
      if (res.code === 0) {
        this.comList = res.data || [];
      }
    },
    deptChange(val) {
      if (this.isbuild == false) {
        this.form.roleIds = [];
        this.roleList = [];
        let params = {
          pageNo: 1,
          pageSize: 10000,
          name: "-",
          departmentId: "-",
          parentId: "-",
          unitType: "-",
          companyId: "-",
        };
        let type = this.deptList.find((item) => item.uuid == val);
        if (type.roleUnitType && type.roleUnitType !== "") {
          params.unitType = type.roleUnitType;
          this.queryAllRole(params);
        } else {
          params.unitType = "-";
          this.queryAllRole(params);
        }
      }
    },
    //根据单位查询部门 和角色
    async comChange(val, data) {
      // data==undefined就是新增
      if (data == undefined) {
        this.form.departmentId = "";
        this.form.roleIds = [];
      }
      queryDeptDetail(val).then((res) => {
        getDepts(val).then((ress) => {
          if (ress.code === 0) {
            this.deptList = ress.data || [];
          }
        });
        if (res.data.category == "build") {
          this.isbuild = false;
          this.roleList = []; //要是选择的是建设单位就把角色清空 选择完部门再掉角色
        } else {
          this.isbuild = true;
          let params = {
            pageNo: 1,
            pageSize: 10000,
            name: "-",
            departmentId: "-",
            parentId: "-",
            unitType: res.data.category,
            companyId: "-",
          };
          this.queryAllRole(params);
        }
      });
    },
    async queryAllRole(params) {
      getRole(params).then((ress) => {
        if (ress.code === 0) {
          this.roleList = ress.data.records || [];
        }
      });
      //   let params = {
      //     name: "-",
      //     companyId: "-",
      //     departmentId: "-",
      //     parentId: "-",
      //   }
      //   let rres = await getRoleList(params)
      //   if (rres.code === 0) {
      //     this.roleList = rres.data || []
      //   }
    },
    //确认新增用户信息
    async onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            let params = {
              id: this.form.id,
              userName: this.form.userName,
              companyId: this.form.companyId,
              departmentId: this.form.departmentId,
              realName: this.form.realName,
              roleIds: this.form.roleIds,
              mobile: this.form.mobile,
            };
            this.updatauc(params);
          } else {
            this.form.roleId = this.form.roleIds;
            this.adduc(this.form);
          }
        } else {
          return false;
        }
      });
    },
    //添加
    async adduc(data) {
      try {
        let res = await addAccounts(data);
        if (res.code === 0) {
          this.userData = res.data;
          this.userId = this.userData.id;
          console.log(this.userId);
          // this.getResourceTree();
          // this.activeName = 1;
          this.loading = true;
          this.$emit("offIt", true);
        } else {
          this.$message.error(res.message);
        }
      } catch (err) {
        this.$message.error(err);
        this.$message.error();
      }
    },
    //修改
    async updatauc(data) {
      data.roleId = this.form.roleIds;
      let res = await modifyAccount(data);
      if (res.code === 0) {
        this.userData = res.data;
        this.userId = this.userData.id;
        // this.getResourceTree();
        // this.activeName = 1;
        this.loading = true;
        this.$emit("offIt", true);
      } else {
        this.$message.error(res.message);
      }
    },

    /*   //查询资源树
    async getResourceTree() {
      let resrole = await queryResourceByRole({
        roleIds: this.form.roleIds.join(","),
      });
      let roleL = resrole.data;
      let res = await resourceAll();
      if (res.code === 0) {
        let treeList = res.data;
        treeList.forEach((e) => {
          roleL.forEach((el) => {
            if (e.id == el.resourceId) {
              this.targetRules.push(el.resourceId);
            }
          });
        });
        let handleResource = (resource) => {
          let parent = treeList.find((p) => p.id == resource.parentId);
          if (parent) {
            if (resource.parentId !== 0 && parent.sub) {
              parent.sub.push(resource);
            } else {
              parent.sub = [resource];
            }
          } else {
            console.log(parent);
          }
        };
        treeList.forEach((item) => handleResource(item));
        this.tree = treeList.filter((item) => item.parentId === 0);
        for (let item of this.parentData.tree) {
          this.targetRules.push(item.resourceId);
        }
        this.loading = false;
      } else {
        this.$message.error(res.message);
      }
    }, */
    /*   //用户授权
    async onUpdata() {
      let ids = [
        ...this.$refs.trees.getCheckedKeys(),
        ...this.$refs.trees.getHalfCheckedKeys(),
      ];
      let pardata = {
        accountId: this.userId,
        data: [],
      };
      ids.forEach((e) => {
        pardata.accountId = this.userId;
        pardata.data.push({
          accountId: this.userId,
          resourceId: e,
          permissionLevel: 0,
        });
      });
      let res = await updateBatchUser(pardata);
      if (res.code === 0) {
        this.$message({
          type: "success",
          message: "添加成功!",
        });
        this.$emit("offIt", true);
      } else {
        this.$message.error(res.message);
      }
    }, */
  },
};
</script>
