import EquAccount from "@/views/DeviceManagent/EquAccount";
import EquRealtime from "@/views/DeviceManagent/EquRealtime";
import Layout from "@/components/Layout/SystemLayout";
import TowerCrane from "@/views/DeviceManagent/TowerCrane";
import GantryCrane from "@/views/DeviceManagent/GantryCrane";
import towerWarn from "@/views/DeviceManagent/towerWarn";
import GantryWarn from "@/views/DeviceManagent/GantryWarn";
import EquipmentManagement from "@/views/DeviceManagent/EquipmentManagement";
import CrawlerRealtime from "@/views/DeviceManagent/CrawlerRealtime";
import CrawlerWarn from "@/views/DeviceManagent/CrawlerWarn";
import CrawlerRule from "@/views/DeviceManagent/CrawlerRule";
import GantryCraneDevice from "@/views/DeviceManagent/GantryCraneDevice";
import DiviceCheckDict from "@/views/DeviceManagent/DiviceCheckDict";
import DeviceRegister from "@/views/DeviceManagent/DeviceRegister";
import EmergencyDevice from "@/views/DeviceManagent/EmergencyDevice";
const Routes = [
    {
        path: "DeviceManagent",
        component: Layout,
        name: "ThreeProofings",
        children: [
            {
                path: "monitorManagent",
                name: "monitorManagent",
                component: Layout,
                meta: {
                    title: "监测数据管理"
                },
                children: [
                    {
                        path: "EquRealtime",
                        name: "EquRealtime",
                        component: EquRealtime,
                        meta: {
                            title: "设备运行监测",
                        }
                    },
                    {
                        path: "EquipmentManagement",
                        name: "EquipmentManagement",
                        component: EquipmentManagement,
                        meta: {
                            title: "塔吊预警数据",
                        }
                    },
                    {
                        path: "GantryCraneDevice",
                        name: "GantryCraneDevice",
                        component: GantryCraneDevice,
                        meta: {
                            title: "龙门吊预警数据",
                        }
                    },
                    {
                        path: "CrawlerRealtime",
                        name: "CrawlerRealtime",
                        component: CrawlerRealtime,
                        meta: {
                            title: "履带吊监测预警",
                        }
                    },
                ]
            },
            {
                path: "warnManagent",
                name: "warnManagent",
                component: Layout,
                meta: {
                    title: "预警数据管理"
                },
                children: [
                    {
                        path: "towerWarn",
                        name: "towerWarn",
                        component: towerWarn,
                        meta: {
                            title: "塔吊监测预警",
                        }
                    },
                    {
                        path: "GantryWarn",
                        name: "GantryWarn",
                        component: GantryWarn,
                        meta: {
                            title: "龙门吊监测预警",
                        }
                    },
                    {
                        path: "CrawlerWarn",
                        name: "CrawlerWarn",
                        component: CrawlerWarn,
                        meta: {
                            title: "履带吊监测预警",
                        }
                    },
                ]
            },
            {
                path: "ruleManagement",
                name: "ruleManagement",
                component: Layout,
                meta: {
                    title: "预警规则管理"
                },
                children: [
                    {
                        path: "TowerCrane",
                        name: "TowerCrane",
                        component: TowerCrane,
                        meta: {
                            title: "塔吊规则",
                        }
                    },
                    {
                        path: "GantryCrane",
                        name: "GantryCrane",
                        component: GantryCrane,
                        meta: {
                            title: "龙门吊规则",
                        }
                    },
                    {
                        path: "CrawlerRule",
                        name: "CrawlerRule",
                        component: CrawlerRule,
                        meta: {
                            title: "履带吊规则",
                        }
                    },
                ]
            },
            {
                path: "EquAccount",
                name: "EquAccount",
                component: EquAccount,
                meta: {
                    title: "施工设备台账",
                }
            },
            {
                path: "EmergencyDevice",
                name: "EmergencyDevice",
                component: EmergencyDevice,
                meta: {
                    title: "应急设备台账",
                }
            },
            {
                path: "DiviceCheckDict",
                name: "DiviceCheckDict",
                component: DiviceCheckDict,
                meta: {
                    title: "设备安全表",
                }
            },
            {
                path: "DeviceRegister",
                name: "DeviceRegister",
                component: DeviceRegister,
                meta: {
                    title: "设备注册",
                }
            },
        ]
    }
];

export default Routes;
