<template>
  <div class="content">
    <div class="info-wrapper">
      <el-table
        :data="tableHeaderData"
        height="105"
        border
        style="width:100%;margin-bottom:20px"
      >
        <el-table-column
          align="center"
          prop="lineName"
          show-overflow-tooltip
          label="所属建管部"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          show-overflow-tooltip
          label="线路名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="vownerName"
          show-overflow-tooltip
          label="分部名称"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="tell"
          show-overflow-tooltip
          label="工点名称"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="tell"
          show-overflow-tooltip
          label="负责人"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="tell"
          show-overflow-tooltip
          label="联系方式"
        >
        </el-table-column>
      </el-table>
      <div class="choice">
        <span
          style="margin-right:10px"
          v-for="item in radioData"
          :key="item.value"
        >
          <el-radio v-model="radio" @change="radioChange" :label="item.value">{{
            item.label
          }}</el-radio>
        </span>
      </div>
      <div class="line"></div>
      <el-form :inline="true" :model="libraryForm" class="demo-form-inline">
        <el-form-item>
          <el-input
            v-model="libraryForm.label"
            placeholder="请输入物资名称搜索"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="libraryQuery">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table
        :data="tableData"
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        height="380"
        border
        style="width: 100%"
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="60"
        ></el-table-column>

        <el-table-column
          align="center"
          prop="label"
          show-overflow-tooltip
          label="物资名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="model"
          show-overflow-tooltip
          label="规格/型号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="unit"
          show-overflow-tooltip
          width="100"
          label="计量单位"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="count"
          show-overflow-tooltip
          width="100"
          label="库存数量"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="usages"
          show-overflow-tooltip
          label="用途"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="remark"
          show-overflow-tooltip
          label="备注"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="libraryForm.pageNo"
        :page-size="libraryForm.pageSize"
        layout="prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getMaterialsPage, getWareHouseManList } from "@/api/eres";

export default {
  components: {},
  props: {
    libraryData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      libraryForm: {
        label: "",
        modelNo: "",
        state: "",
        siteIds: [],
        type: "126",
        currentPage: 1,
        size: 5,
      },
      tableData: [
        {
          lineName: "姓名",
          siteName: "xxx职位",
          vownerName: "部门",
          tell: "12",
        },
      ],
      tableHeaderData: [],
      radio: 125,
      radioData: [
        {
          label: "应急物资",
          value: 125,
        },
        {
          label: "应急设备",
          value: 126,
        },
      ],
      total: 0,
      loading: false,
    };
  },

  mounted() {
    this.libraryForm.siteIds = [];
    this.libraryForm.siteIds.push(this.libraryData.siteId);
    this.getDataList(this.radio);
    this.getHeadDatas();
  },
  methods: {
    getHeadDatas() {
      this.loading = true;
      const params = {
        siteIds: this.libraryForm.siteIds,
        name: "",
        page: "",
        size: "",
      };
      getWareHouseManList(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records ? res.data.records : [];
          this.total = res.data.total;
        } else {
          this.loading = false;
        }
      });
    },
    getDataList(val) {
      this.loading = true;
      const params = {
        materialType: 1,
        siteIds: this.libraryForm.siteIds,
        type: val,
        state: this.libraryForm.state,
        modelNo: this.libraryForm.modelNo,
        label: this.libraryForm.label,
        page: this.libraryForm.currentPage,
        size: this.libraryForm.size,
      };
      getMaterialsPage(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records ? res.data.records : [];
          this.total = res.data.total;
        } else {
          this.loading = false;
        }
      });
    },
    radioChange(val) {
      this.radio = val;
      this.getDataList(val);
    },
    libraryCls() {
      this.$emit("libraryCls");
    },
    // 查询
    libraryQuery() {
      this.getDataList(this.radio);
    },

    handleCurrentChange(val) {
      this.libraryForm.currentPage = val;
    },
    handleSizeChange(size) {
      this.libraryForm.size = size;
    },
    yuyin(val) {
      console.log(val);
    },
    shipin(val) {
      console.log(val);
    },
    duanxian(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.info-wrapper {
  ::v-deep .el-table--border,
  .el-table::before {
    border-bottom: 0 !important;
    height: 0px;
  }
}

.peopleNum {
  color: #000;
  font-weight: bold;
}
::v-deep .el-radio-button__inner {
  width: 200px;
}
.choice {
  display: flex;
  flex-direction: row;
}
.line {
  height: 1px;
  width: 100%;
  background: #143363;
  margin-bottom: 20px;
}
::v-deep .el-input__inner {
  background: #081742;
  border-color: #25458f;
  color: #fff;
}
.el-table--border::after {
  width: 0;
}
::v-deep .el-input__inner:hover {
  border-color: #25458f;
}
</style>
