<template>
  <systemModal
    ref="formModal"
    :title="title"
    :showFooter="false"
    width="40%"
    @modalClose="handleClose"
  >
    <div>
      <section>
        <h3>事件快报</h3>
        <el-form
          ref="form"
          :model="eventForm"
          label-width="110px"
          class="px-4"
          label-position="left"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="事件类型">
                <el-input
                  v-model="eventForm.typeName"
                  readonly
                  placeholder="事件类型"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发生时间">
                <el-input
                  v-model="eventForm.created"
                  readonly
                  placeholder="发生时间"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="上报人">
                <el-input
                  v-model="eventForm.publisherName"
                  readonly
                  placeholder="上报人"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="负责人">
                <el-input
                  v-model="eventForm.chargeName"
                  readonly
                  placeholder="负责人"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="地理位置">
                <el-input
                  v-model="eventForm.xycoors"
                  readonly
                  placeholder="地理位置"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工程项目名称">
                <el-input
                  v-model="eventForm.siteName"
                  readonly
                  placeholder="工程项目名称"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="施工单位">
                <el-input
                  v-model="eventForm.constructionUnitName"
                  readonly
                  placeholder="施工单位"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="监理单位">
                <el-input
                  v-model="eventForm.supervisorUnitName"
                  readonly
                  placeholder="监理单位"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="事件经过">
            <el-input
              readonly
              type="textarea"
              resize="none"
              placeholder="请输入作业内容、事发情形等"
              :autosize="{ minRows: 2, maxRows: 4 }"
              v-model="eventForm.historyRecord"
            ></el-input>
          </el-form-item>
          <el-form-item label="伤亡情况">
            <el-input
              readonly
              type="textarea"
              resize="none"
              placeholder="请输入人员伤亡、直接经济损失和影响范围"
              :autosize="{ minRows: 2, maxRows: 4 }"
              v-model="eventForm.casualties"
            ></el-input>
          </el-form-item>
          <el-form-item label="处置情况">
            <el-input
              readonly
              type="textarea"
              resize="none"
              placeholder="请输入应急救援、已采取和拟采取的措施等"
              v-model="eventForm.disposal"
            ></el-input>
          </el-form-item>
          <el-form-item label="照片或附件" v-if="imgs.length">
            <a
              v-for="(item, index) in imgs"
              :key="index"
              :href="item.url"
              target="_blank"
              style="color:#409EFF;display:block;"
              >{{ item.originalName }}</a
            >
          </el-form-item>
        </el-form>
      </section>
      <section>
        <h3>预警信息</h3>
        <el-form
          v-loading="loading"
          :model="model"
          ref="modelForm"
          label-width="200px"
          class="demo-model"
          style="width: 80%"
        >
          <el-form-item label="工点名称" prop="siteName">
            <el-input
              v-model="eventForm.siteName"
              readonly
              placeholder="工点名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="预警级别" prop="warnLevel">
            <el-input
              v-model="model.warnLevel"
              readonly
              placeholder="预警级别"
            ></el-input>
          </el-form-item>
          <el-form-item label="预警原因" prop="warnReason">
            <el-input
              v-model="model.warnReason"
              readonly
              placeholder="预警级别"
            ></el-input>
          </el-form-item>
          <el-form-item label="预警时间" prop="createdTime">
            <el-input
              v-model="model.createdTime"
              readonly
              placeholder="预警时间"
            ></el-input>
          </el-form-item>
          <el-form-item label="操作人" prop="createdName">
            <el-input
              v-model="model.createdName"
              readonly
              placeholder="操作人"
            ></el-input>
          </el-form-item>
        </el-form>
      </section>
    </div>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { getWarningInfo, detailsList } from "@/api/response";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "应急预警详情",
      model: {},
      eventForm: {},
      loading: false,
      rejectLoading: false,
      rejectModalShow: false,
      form: {
        rejectReason: "",
      },
      imgs: [],
      rules: {
        rejectReason: [
          { required: true, message: "请输入驳回原因", trigger: "blur" },
        ],
      },
    };
  },
  filters: {},
  mounted() {},
  methods: {
    handleDetail(record) {
      this.getWarningInfo(record.eventId);
      this.$refs.formModal.handleOpen();
    },
    // 请求应急预警详情
    async getWarningInfo(id) {
      const res = await getWarningInfo(id);
      if (res.code == 0) {
        this.model = res.data;
        this.getEventInfo(res.data.responseEventId);
      }
    },
    // 请求事件快报详情
    async getEventInfo(eventId) {
      let params = {
        id: eventId,
      };
      const res = await detailsList(params);
      if (res.code == 0) {
        this.eventForm = res.data;
        if (res.data.processList[0].enclosure) {
          try {
            this.imgs = JSON.parse(res.data.processList[0].enclosure);
          } catch (error) {
            this.imgs = [];
          }
        }
      }
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 30px !important;
}

::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}

::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}

section {
  h3 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
    color: rgb(16, 16, 16);
  }
  &:nth-child(2) {
    margin-top: 20px;
  }
}
</style>
