<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :modal="true"
    :fullscreen="true"
    center
  >
    <el-form :model="model1" :rules="rules" ref="modelForm" class="demo-model">
      <el-row>
        <el-col :span="12">
          <el-form-item label="线路工点" prop="">
            <el-cascader
              ref="linecas"
              placeholder="选择线路工点"
              v-model="model1.lineSite"
              :options="options"
              :props="props"
              filterable
              clearable
              @change="handleLineSiteChange"
              style="width:90%"
              :disabled="false"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="报告名称" prop="">
            <el-select
              v-model="model1.reportName"
              placeholder="请选择"
              style="width:90%"
              @change="reportNameChange"
              :disabled="model1.lineSite.length < 2"
            >
              <el-option
                v-for="(item, index) in historyMonth"
                :key="index"
                :label="item + '月三防工作报告'"
                :value="item + '月三防工作报告'"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span style="color:red" v-if="model1.lineSite.length < 2"
      >请先选择线路工点！</span
    >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      class="demo-model"
      :disabled="model1.lineSite.length < 2"
    >
      <el-form-item label="" prop="" class="first">
        按照建设公司统一部署要求，第二建管部组织积极开展三防工作，将现工作落实情况和下一步工作计划报告<br />如下：
      </el-form-item>
      <el-form-item label="" prop="">
        一、 工程概况
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input type="textarea" v-model="model.projectBaseFact"></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        二、 三防通知要求制度宣贯传达落实情况
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input
          type="textarea"
          v-model="model.defencePropagationImple"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-upload
          class="upload-demo"
          action="upload"
          :http-request="uploadImg"
          :file-list="fileList"
          :show-file-list="false"
        >
          <el-button size="small" type="info" class="el-icon-plus"></el-button>
        </el-upload>
        <div v-for="(item, index) in fileList" :key="index" class="url-list">
          <el-image :src="item.uri"> </el-image>
          <i class="el-icon-circle-close i" @click="imgDelete(item)"></i>
        </div>
      </el-form-item>
      <el-form-item label="" prop="">
        三、 三防风险隐患整改落实情况
      </el-form-item>
      <el-form-item
        label=""
        prop=""
        style="font-weight: 400; text-align: center;"
      >
        <potential-risks-table
          :tableData="model.addDefenceProblemRectifySituations"
          :RiskData="RiskData"
          :supervisingData="supervisingData"
          :builderData="builderData"
        ></potential-risks-table>
      </el-form-item>
      <el-form-item label="" prop="">
        四、 本月上级单位/领导检查情况
      </el-form-item>
      <el-form-item label="" prop="">
        <el-table :data="monthData" style="width: 100%" border>
          <el-table-column
            prop="leaderInspectTimes"
            label="检查次数"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="leaderInspectPeopleNum"
            label="检查人次"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :prop="model.leaderExposedProblemNum + ''"
            label="发现问题数量"
            align="center"
          >
            <template slot-scope="">
              <el-input v-model="model.leaderExposedProblemNum"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            :prop="model.leaderRectifyProblemNum + ''"
            label="整改问题数量"
            align="center"
          >
            <template slot-scope="">
              <el-input v-model="model.leaderRectifyProblemNum"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="" prop="">
        五、 本月值班情况
      </el-form-item>
      <el-form-item label="" prop="">
        <duty-status :calendarData="calendarData"></duty-status>
      </el-form-item>
      <el-form-item label="" prop="">
        六、 三防检查工作开展情况
      </el-form-item>
      <el-form-item label="" prop="">
        <el-table :data="workData" style="width: 100%" border>
          <el-table-column
            prop="defenceInspectTimes"
            label="三防检查次数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="defenceInspectPeopleNum"
            label="参与检查人数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :prop="model.defenceExposedProblemNum + ''"
            label="发现问题数量"
            align="center"
          >
            <template slot-scope="">
              <el-input v-model="model.defenceExposedProblemNum"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            :prop="model.defenceRectifyProblemNum + ''"
            label="整改问题数量"
            align="center"
          >
            <template slot-scope="">
              <el-input v-model="model.defenceRectifyProblemNum"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="" prop="">
        七、 三防演练开展情况
      </el-form-item>
      <el-form-item label="" prop="" style="text-align: center;">
        <el-table
          :data="model.addDefencePlanReports"
          style="width: 100%"
          border
        >
          <el-table-column prop="exerciseTime" label="演练时间" align="center">
            <template slot-scope="scope">
              <el-date-picker
                v-model="scope.row.exerciseTime"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column prop="exerciseTheme" label="演练主题" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.exerciseTheme"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="exerciseLocation"
            label="演练地点"
            align="center"
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.exerciseLocation"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="exercisePeopleNum"
            label="参与人数"
            align="center"
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.exercisePeopleNum"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <el-button
          size="small"
          type="primary"
          class="el-icon-plus"
          @click="tableDataAdd"
        ></el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input
          type="textarea"
          v-model="model.exerciseDeri"
          placeholder="具体演练情况"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        八、 本月三防突发事件情况
      </el-form-item>
      <el-form-item label="" prop="">
        <el-radio-group v-model="model.emergencySymbol">
          <el-radio :label="0">本月未发生三防突发事件</el-radio>
          <el-radio :label="1">填写三防突发事件情况</el-radio>
        </el-radio-group>

        <el-input
          type="textarea"
          v-model="model.defenceEmergencySituation"
          v-if="model.emergencySymbol == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        九、 下一步工作计划
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input type="textarea" v-model="model.nextWorkPlan"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSave">保存</el-button>
      <el-button type="primary" @click="handleSubmit">提交</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { upload } from "@/api/upload";
import { queryDutyMonth } from "@/api/eres";
import { queryDictNodesById } from "@/api/dict";
import {
  getMonthSecurityCheck,
  WorkReportAdd,
  WorkReportisHave,
  WorkReportUpdate,
  getReportNameHave,
} from "@/api/map";
import { getdevelopmentDrill } from "@/api/stand";
import { queryOrgPageList } from "@/api/org";
import dayjs from "dayjs";
// 三防风险隐患整改落实情况
import PotentialRisksTable from "./PotentialRisksTable.vue";
// 本月值班情况
import DutyStatus from "./DutyStatus.vue";

export default {
  // props: {
  //   historyMonth: {
  //     // type: Number,
  //     default: 12,
  //   },
  // },
  components: {
    PotentialRisksTable,
    DutyStatus,
  },
  data() {
    return {
      title: "新增",
      model: {
        projectBaseFact: "",
        defencePropagationImple: "",
        // 三防风险隐患整改落实情况
        addDefenceProblemRectifySituations: [],
        addDefencePlanReports: [],
        leaderExposedProblemNum: "",
        leaderRectifyProblemNum: "",
        defenceExposedProblemNum: "",
        defenceRectifyProblemNum: "",
        defenceInspectTimes: "",
        defenceInspectPeopleNum: "",
        leaderInspectTimes: "",
        leaderInspectPeopleNum: "",
        exerciseDeri: "",
        emergencySymbol: 0,
        defenceEmergencySituation: "",
        nextWorkPlan: "",
        pics: [],
        isSave: 0,
        lineId: "",
        siteId: "",
        lineSite: [],
        reportName: "",
      },
      model1: {
        lineSite: [],
        reportName: "",
      },
      loading: false,
      rules: {},
      fileList: [],
      percent: 0,
      monthData: [
        {
          leaderInspectTimes: 0,
          leaderInspectPeopleNum: 0,
          leaderExposedProblemNum: 0,
          leaderRectifyProblemNum: 0,
        },
      ],
      workData: [
        {
          defenceInspectTimes: 0,
          defenceInspectPeopleNum: 0,
          defenceExposedProblemNum: 0,
          defenceRectifyProblemNum: 0,
        },
      ],
      calendarData: [],
      dialogVisible: false,
      ishave: false, //是否有保存信息
      options: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
      },
      option: [],
      RiskData: [],
      supervisingData: [],
      builderData: [],
      historyMonth: [],
    };
  },
  mounted() {
    this.getLineTree();
  },
  methods: {
    //查询该报告名称是否有写过报告了
    reportNameChange(val) {
      console.log(val);
      let params = {
        lineId: this.model.lineId,
        siteId: this.model.siteId,
        reportName: val,
      };
      getReportNameHave(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          if (res.data == true) {
            this.model1.reportName = "";
            this.$message.warning("当前报告名称已有报告");
          }
        }
      });
    },
    //线路工点的选择
    handleLineSiteChange(val) {
      if (val.length > 0) {
        this.model.lineId = val[0];
        this.model.siteId = val[val.length-1];
        this.model1.reportName = "";
        this.queryDutyDataByMonth();
        this.developmentDrill();
      }
    },
    //获取线路工点
    async getLineTree() {const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.options = lineSiteData;
      }
    },
    tableDataAdd() {
      let obj = {
        exerciseTime: "",
        exerciseTheme: "",
        exerciseLocation: "",
        exercisePeopleNum: "",
      };
      this.model.addDefencePlanReports.push(obj);
    },
    // 查询月份值班表
    queryDutyDataByMonth() {
      this.loading = true;
      const params = {
        lineId: this.model.lineId,
        siteId: this.model.siteId,
        emergency: false,
        dutyMonth: dayjs().format("YYYY-MM"),
      };
      queryDutyMonth(params).then((res) => {
        if (res.code == 0) {
          res.data.map((item) => {
            item.dutyTime = item.dutyTime.split(" ")[0];
          });
          this.calendarData = res.data;
          this.loading = false;
        }
      });
    },
    //三防演练开展情况
    developmentDrill() {
      let params = {
        lineId: this.model.lineId,
        siteId: this.model.siteId,
      };
      getdevelopmentDrill(params).then((res) => {
        this.model.addDefencePlanReports = [
          ...this.model.addDefencePlanReports.filter((item) => !item.id),
          ...res.data,
        ];
      });
    },
    //隐患分类 字典
    getRiskclassified() {
      let params = {
        id: 303,
      };
      queryDictNodesById(params).then((res) => {
        if (res.code == 0) {
          this.RiskData = res.data;
        }
      });
    },
    //监理单位  施工单位
    getSupervisingUnits() {
      let params = {
        name: "-",
        category: "monitor",
        pageNo: "1",
        pageSize: "10",
      };
      queryOrgPageList(params).then((res) => {
        if (res.code == 0) {
          this.supervisingData = res.data.records;
        }
      });
      let param = {
        name: "-",
        category: "construct",
        pageNo: "1",
        pageSize: "10",
      };
      queryOrgPageList(param).then((res) => {
        if (res.code == 0) {
          this.builderData = res.data.records;
        }
      });
    },

    //图片删除
    imgDelete(url) {
      this.$confirm("删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.fileList.splice(this.fileList.indexOf(url), 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {});
    },
    //填写月报
    handleAdd(month) {
      this.historyMonth = [];
      for (let i = 0; i < month; i++) {
        // pass
        this.historyMonth.push(i + 1);
      }

      console.log(this.historyMonth);
      this.title = "月报";
      this.handleEdit();
      this.resetModel();
      this.fileList = [];
      this.getRiskclassified();
      this.getSupervisingUnits();
      this.loading = true;
      WorkReportisHave()
        .then((res) => {
          // 上次有保存的情况  回显
          if (res.code == 0 && res.data && Object.keys(res.data).length !== 0) {
            this.loading = false;
            this.ishave = true;
            this.model = Object.assign({}, res.data);
            this.model1.lineSite = [this.model.lineId, this.model.siteId];
            this.model1.reportName = this.model.reportName
              ? this.model.reportName
              : month + "月三防工作报告";
            //这个是因为新增传过去的字段个原来保存过返回的字段不一样
            this.model.addDefencePlanReports = this.model.defencePlanReports;
            this.model.addDefenceProblemRectifySituations = this.model.defenceProblemRectifySituationDtos;
            //  三防风险隐患整改落实情况
            this.model.addDefenceProblemRectifySituations.forEach((item) => {
              item.lineSite = [item.lineId, item.siteId];
            });
            this.model.pics.forEach((item, index) => {
              this.fileList.push({
                id: index,
                uri: item,
              });
            });
            this.monthData[0].leaderInspectTimes = this.model.leaderInspectTimes;
            this.monthData[0].leaderInspectPeopleNum = this.model.leaderInspectPeopleNum;
            this.monthData[0].leaderExposedProblemNum = this.model.leaderExposedProblemNum;
            this.monthData[0].leaderRectifyProblemNum = this.model.leaderRectifyProblemNum;
            this.workData[0].defenceInspectTimes = this.model.defenceInspectTimes;
            this.workData[0].defenceInspectPeopleNum = this.model.defenceInspectPeopleNum;
            this.workData[0].defenceExposedProblemNum = this.model.defenceExposedProblemNum;
            this.workData[0].defenceRectifyProblemNum = this.model.defenceRectifyProblemNum;
          } else {
            this.ishave = false;
            this.model1.reportName = month + "月三防工作报告";
            // 没有保存的情况
            // 获取本单位本月三防安全检查情况;
            getMonthSecurityCheck().then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.monthData[0].leaderInspectTimes = res.data.num;
                this.monthData[0].leaderInspectPeopleNum = res.data.staffNum;
                this.workData[0].defenceInspectTimes = res.data.num;
                this.workData[0].defenceInspectPeopleNum = res.data.staffNum;
              }
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleEdit() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    //提交
    handleSubmit() {
      this.model.isSave = 0;
      //点击提交或者保存的时候把线路工点循环分出来线路工点
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.model.addDefenceProblemRectifySituations.length > 0) {
            this.model.addDefenceProblemRectifySituations.forEach((item) => {
              item.lineId = item.lineSite[0];
              item.siteId = item.lineSite[1];
              this.supervisingData.forEach((i) => {
                if (i.companyName == item.supervisionUnit) {
                  item.supervisionUnitUuid = i.uuid;
                }
              });
              this.builderData.forEach((i) => {
                if (i.companyName == item.constructUnit) {
                  item.constructUnitUuid = i.uuid;
                }
              });
            });
          }
          this.model.defenceInspectTimes = this.workData[0].defenceInspectTimes;
          this.model.defenceInspectPeopleNum = this.workData[0].defenceInspectPeopleNum;
          this.model.leaderInspectTimes = this.monthData[0].leaderInspectTimes;
          this.model.leaderInspectPeopleNum = this.monthData[0].leaderInspectPeopleNum;
          this.model.pics = [];
          this.fileList.forEach((item) => {
            this.model.pics.push(item.uri);
          });
          this.model.reportName = this.model1.reportName;

          const params = JSON.parse(JSON.stringify(this.model));

          console.log(params, "提交的参数");
          WorkReportAdd(params)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.$message.success("操作成功");
                this.dialogVisible = false;
                this.$emit("submitFrom");
                this.resetModel();
              } else {
                this.$message.error("操作失败" + res.message);
              }
            })
            .catch(() => {
              this.loading = false;
              this.$message.error("操作失败");
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //保存
    handleSave() {
      this.model.isSave = 1;
      //点击提交或者保存的时候把线路工点循环分出来线路工点
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.model.addDefenceProblemRectifySituations.length > 0) {
            this.model.addDefenceProblemRectifySituations.forEach((item) => {
              item.lineId = item.lineSite[0];
              item.siteId = item.lineSite[1];
              this.supervisingData.forEach((i) => {
                if (i.companyName == item.supervisionUnit) {
                  item.supervisionUnitUuid = i.uuid;
                }
              });
              this.builderData.forEach((i) => {
                if (i.companyName == item.constructUnit) {
                  item.constructUnitUuid = i.uuid;
                }
              });
            });
          }
          this.model.defenceInspectTimes = this.workData[0].defenceInspectTimes;
          this.model.defenceInspectPeopleNum = this.workData[0].defenceInspectPeopleNum;
          this.model.leaderInspectTimes = this.monthData[0].leaderInspectTimes;
          this.model.leaderInspectPeopleNum = this.monthData[0].leaderInspectPeopleNum;

          this.model.pics = [];
          this.fileList.forEach((item) => {
            this.model.pics.push(item.uri);
          });
          this.model.reportName = this.model1.reportName;
          const params = JSON.parse(JSON.stringify(this.model));

          //原来没有保存去保存
          if (!this.ishave) {
            WorkReportAdd(params)
              .then((res) => {
                this.loading = false;
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  this.$emit("submitFrom");
                  this.resetModel();
                } else {
                  this.$message.error("操作失败" + res.message);
                }
              })
              .catch(() => {
                this.$message.error("操作失败");
                this.loading = false;
              });
          } else {
            //有保存的数据情况下再保存 去更新
            WorkReportUpdate(params)
              .then((res) => {
                this.loading = false;
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.$emit("submitFrom");
                  this.dialogVisible = false;
                  this.resetModel();
                } else {
                  this.$message.error("操作失败" + res.message);
                }
              })
              .catch(() => {
                this.loading = false;
                this.$message.error("操作失败");
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //置空
    resetModel() {
      this.model = {
        projectBaseFact: "",
        defencePropagationImple: "",
        // 三防风险隐患整改落实情况
        addDefenceProblemRectifySituations: [],
        addDefencePlanReports: [],
        leaderExposedProblemNum: "",
        leaderRectifyProblemNum: "",
        defenceExposedProblemNum: "",
        defenceRectifyProblemNum: "",
        defenceInspectTimes: "",
        defenceInspectPeopleNum: "",
        leaderInspectTimes: "",
        leaderInspectPeopleNum: "",
        exerciseDeri: "",
        emergencySymbol: 0,
        defenceEmergencySituation: "",
        nextWorkPlan: "",
        pics: [],
        isSave: 0,
        lineId: "",
        siteId: "",
        lineSite: [],
        reportName: "",
      };
      (this.monthData = [
        {
          leaderInspectTimes: 0,
          leaderInspectPeopleNum: 0,
          leaderExposedProblemNum: 0,
          leaderRectifyProblemNum: 0,
        },
      ]),
        (this.workData = [
          {
            defenceInspectTimes: 0,
            defenceInspectPeopleNum: 0,
            defenceExposedProblemNum: 0,
            defenceRectifyProblemNum: 0,
          },
        ]),
        (this.model1 = {
          lineSite: [],
          reportName: "",
        }),
        (this.fileList = []);
    },
    // 图片上传的loading
    async uploadImg(args) {
      const loading = this.$loading({
        lock: true,
        text: "上传中...",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.percent = 0;
      const { file } = args;
      let res = {};
      let fn = upload;
      res = await fn(file, (e) => {
        this.percent = ((e.loaded / e.total) * 100) | 0;
        return args.onProgress({ percent: this.percent });
      }).catch((err) => {
        res.message = err;
      });
      if (res.code === 0) {
        loading.spinner = "el-icon-success";
        loading.text = "上传成功！";
        setTimeout(() => {
          loading.close();
        }, 800);
        this.fileList.push(res.data);
      } else {
        this.message.error("上传失败");
        this.$emit("error", res.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.first {
  margin-bottom: 0 !important;
  ::v-deep .el-form-item__content {
    font-weight: 550;
  }
}
::v-deep .el-form-item__content {
  font-weight: 700;
}
::v-deep .el-image {
  height: 80px;
  margin-right: 10px;
}
.i {
  position: absolute;
  top: 1px;
  right: 10px;
  color: red;
  font-weight: 500;
}
.url-list {
  position: relative;
  display: inline-block;
}
::v-deep .el-table {
  font-size: 14px !important;
}

::v-deep .el-input {
  width: 100% !important;
}
</style>
