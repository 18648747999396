<template>
  <div>
    <el-upload
      action="action"
      :http-request="handleUpload"
      list-type="text"
      :on-remove="handleRemoveCover"
      :file-list="list"
    >
      <!-- :limit="1" -->
      <span v-if="type == '1'">《风险管控关键节点施工前条件核查表》</span
      ><i v-if="type == '1'" style="color:#409eff;" class="el-icon-plus"></i>
      <span v-if="type == '2'">《风险管控关键节点实施前条件验收记录表》</span
      ><i v-if="type == '2'" style="color:#409eff;" class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
import { uploadThumb } from "@/api/upload";
export default {
  name: "FileUploader",
  props: {
    value: {
      type: Array
    },
    type: {
      type: String
    }
  },
  model: {
    prop: "value",
    event: "change"
  },
  watch: {
    value: {
      handler(e) {
        let temp = [];
        if (e && e.length) {
          e.forEach(file => {
            let res = this.list.find(item => item.url === file.url);
            if (res) {
              temp.push(res);
            } else {
              temp.push({
                url: file.url,
                name: file.name
              });
            }
          });
        }
        this.list = [...temp];
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      list: []
    };
  },
  methods: {
    handleUploadImg() {},
    async handleUpload(args) {
      let res = await uploadThumb(args.file, e => {
        this.percent = ((e.loaded / e.total) * 100) | 0;
        return args.onProgress({ percent: this.percent });
      });
      if (res.code === 0) {
        this.list.push({
          uid: args.file.uid,
          url: res.data.uri,
          name: res.data.originalName
        });
        args.onSuccess(res, args.file, this.list);
        this.emitEvent();
        this.emitEvent2({
          url: res.data.uri,
          name: res.data.originalName,
          realUrl: res.data.fullPath
        });
      }
    },
    handleRemoveCover(file) {
      let index = this.list.findIndex(v => v.url === file.url);
      this.list.splice(index, 1);
      this.emitEvent();
      this.emitDelEvent(file);
    },
    emitEvent() {
      this.$emit(
        "change",
        this.list.map(v => v.url)
      );
    },
    emitEvent2(record) {
      this.$emit("uploadChange", record);
    },
    emitDelEvent(record) {
      this.$emit("delChange", record);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-icon-upload {
  font-size: 30px;
  line-height: 40px;
}
</style>
