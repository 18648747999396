<template>
  <systemModal
    ref="planModal"
    :title="title"
    width="50%"
    @modalClose="handleClose"
    :showFooter="false"
  >
    <el-tabs v-model="activeName" @tab-click="handleTabsClick">
      <el-tab-pane label="匹配预案" name="first">
        <div class="search mb-4">
          <el-input
            v-model="matchinputs"
            placeholder="输入关键字查询"
            style="width: 40%;"
          ></el-input>
          <el-button
            type="primary"
            @click="matchplan"
            style="margin-left: 10px; background: #43a5e9"
            >查询</el-button
          >
        </div>
        <el-table
          :data="gridlist"
          height="500px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            property="planName"
            align="center"
            label="预案名称"
          ></el-table-column>
          <el-table-column property="planFile" label="附件" align="center">
            <template slot-scope="scope"
              ><el-button type="text" @click="handleFileClick(scope.row)"
                >附件</el-button
              ></template
            ></el-table-column
          >
        </el-table>
        <div class="pagination-wrapper flex justify-center mt-4">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="page.count"
            :page-count="page.total"
            :page-size="page.pageSize"
            :current-page="page.pageNo"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </el-tab-pane>

      <el-tab-pane label="全部预案" name="second">
        <div class="search mb-4">
          <el-input
            v-model="allinputs"
            placeholder="输入关键字查询"
            style="width: 40%;"
            class="partime"
          ></el-input>
          <el-button
            type="primary"
            @click="allplan"
            style="margin-left: 10px; background: #43a5e9"
            >查询</el-button
          >
        </div>
        <el-table
          :data="gridlists"
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            property="planName"
            align="center"
            label="预案名称"
          ></el-table-column>
          <el-table-column property="planFile" label="附件" align="center">
            <template slot-scope="scope"
              ><el-button type="text" @click="handlesFileClick(scope.row)"
                >附件</el-button
              ></template
            ></el-table-column
          >
        </el-table>
        <div class="pagination-wrapper flex justify-center mt-4">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pages.count"
            :page-count="pages.total"
            :page-size="pages.pageSize"
            :current-page="pages.pageNo"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          >
          </el-pagination></div
      ></el-tab-pane>
    </el-tabs>
    <el-row>
      <el-button
        type="primary"
        @click="launch"
        style="margin-top: 30px; margin-left: 40%"
        >启动预案</el-button
      >
      <el-button
        @click="launchset"
        style="
            background: none;
            border-color: #3e9eff;
            color: #3e9eff;
            margin-top: 30px;
            margin-left: 5%;
          "
        >取 消</el-button
      >
    </el-row>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { EmergencyPageList, startPlan } from "@/api/emergency.js";
import { queryRoleById } from "@/api/role";
import { detailsList, finalQuote, } from "@/api/response";

export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "启动预案",
      activeName: "first",
      loading: false,
      matchinputs: "",
      allinputs: "",
      gridlist: [],
      gridlists: [],
      final: {},
      detaltes: {},
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      pages: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      userData: {},
      unitType: "",
      belonging: 0,
      finalid: "",
    };
  },
  filters: {},
  mounted() {},
  methods: {
    handleOpen(record) {
      this.userData = JSON.parse(localStorage.getItem("user"));
      var roleId = this.userData.roles[0].id;
      queryRoleById({ id: roleId }).then((res) => {
        this.unitType = res.data ? res.data.unitType : "0";
      });
      if (this.unitType == "construct") {
        this.belonging = 1;
      } else if (this.unitType == "build") {
        this.belonging = 0;
      } else if (this.unitType == "metroGroupUnit") {
        this.belonging = 3;
      }
      this.queryDetailInfo(record.eventId);
      this.getList(record);
    },
    // 启动预案
    getList(record) {
      EmergencyPageList({
        belonging: this.belonging,
        siteId: record.siteUuid,
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
      }).then((res) => {
        this.gridlist = res.data.records;
        this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.page.total = res.data.count;
        this.page.count = res.data.total;
      });
      this.$refs.planModal.handleOpen();
    },
    async queryDetailInfo(id) {
      let params = {
        id: id,
      };
      detailsList(params).then((res) => {
        if (res.code == 0) {
          this.detaltes = res.data;
        }
      });
    },
    // tab栏事件
    handleTabsClick(tab, event) {
      console.log(12312321321321, tab, event);
      if (tab.label == "全部预案") {
        EmergencyPageList({
          // siteId: record.siteUuid,
          pageNum: this.pages.pageNo,
          pageSize: this.pages.pageSize,
        }).then((res) => {
          this.gridlists = res.data.records;
          this.pages.pageNo = res.data.current;
          this.pages.pageSize = res.data.size;
          this.pages.total = res.data.count;
          this.pages.count = res.data.total;
        });
      } else {
        this.emerg({
          belonging: this.belonging,
          siteId: this.detaltes.siteUuid,
        });
      }
    },
    //匹配预案查询
    matchplan() {
      if (this.matchinputs == "") {
        this.emerg({
          belonging: this.belonging,
          siteId: this.detaltes.siteUuid,
        });
      } else {
        EmergencyPageList({
          belonging: this.belonging,
          siteId: this.detaltes.siteUuid,
          pageNum: this.page.pageNo,
          pageSize: this.page.pageSize,
          planName: this.matchinputs,
        }).then((res) => {
          this.gridlist = res.data.records;
          this.page.pageNo = res.data.current;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.count;
          this.page.count = res.data.total;
        });
      }
    },
    //全部预案查询
    allplan() {
      if (this.allinputs == "") {
        EmergencyPageList({
          pageNum: this.pages.pageNo,
          pageSize: this.pages.pageSize,
        }).then((res) => {
          this.gridlists = res.data.records;
          this.pages.pageNo = res.data.current;
          this.pages.pageSize = res.data.size;
          this.pages.total = res.data.count;
          this.pages.count = res.data.total;
        });
      } else {
        EmergencyPageList({
          pageNum: this.pages.pageNo,
          pageSize: this.pages.pageSize,
          planName: this.allinputs,
        }).then((res) => {
          this.gridlists = res.data.records;
          this.pages.pageNo = res.data.current;
          this.pages.pageSize = res.data.size;
          this.pages.total = res.data.count;
          this.pages.count = res.data.total;
        });
      }
    },
    //查询预案列表
    emerg(val) {
      EmergencyPageList({
        belonging: val.belonging,
        siteId: val.siteId,
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
      }).then((res) => {
        this.gridlist = res.data.records;
        this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.page.total = res.data.count;
        this.page.count = res.data.total;
      });
    },
    // 表格复选事件
    handleSelectionChange(val) {
      console.log(12312312, val);
      this.finalid = val[0].planId;
      this.final.disposal = val[0].fileName;
    },
    // 附件下载
    handleFileClick(record) {
      const link = document.createElement("a");
      link.href = record.enclosure;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
    // 分页事件
    handleCurrentChange() {},
    handleSizeChange() {},
    // 启动预案
    launch() {
      if (this.finalid) {
        this.final.responseUserName = this.userData.realName;
        this.final.responseUserUuid = this.userData.uuid;
        this.final.responseRecordUuid = this.detaltes.uuid;
        this.final.responseType = "start_plan";
        this.final.responseProcessName = "启动预案";
        startPlan({
          eventId: this.detaltes.id,
          planId: this.finalid,
        }).then((res) => {
          console.log(res);
          this.launchset();
        });
         finalQuote(this.final).then((res) => {
        console.log(res);
      });
      } else {
        this.$message.warning("至少勾选一条");
      }
      
    },
    // 取消
    launchset() {
      this.allinputs = "";
      this.final = {};
      this.$refs.planModal.handleClose();
      this.$emit("close");
    },
    handleClose() {
      this.allinputs = "";
      this.final = {};
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 30px !important;
}
::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}
::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
</style>
