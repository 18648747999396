<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" :title="title">
    <el-form v-loading="loading" :model="model" :rules="rules" ref="modelForm" label-width="100px" class="demo-model">
      <el-form-item label="队伍名称" prop="teamName">
        <el-input v-model="model.teamName" placeholder="请输入队伍名称"></el-input>
      </el-form-item>
      <el-form-item label="队伍驻地" prop="teamStation">
        <el-input v-model="model.teamStation" placeholder="请输入队伍驻地"></el-input>
      </el-form-item>
      <el-form-item label="救援范围" prop="rescueScope">
        <el-input v-model="model.rescueScope" type="textarea" autosize placeholder="请输入救援范围">
        </el-input>
      </el-form-item>
      <el-form-item label="队长姓名" prop="captainName">
        <el-input v-model="model.captainName" placeholder="请输入队长姓名">
        </el-input>
      </el-form-item>
      <el-form-item label="队长电话" prop="captainPhone">
        <el-input v-model="model.captainPhone" placeholder="请输入队长电话">
        </el-input>
      </el-form-item>
      <el-form-item label="队长专业" prop="captainPhone">
        <el-input v-model="model.captainSpecialty" placeholder="请输入队长专业">
        </el-input>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { updateTeamInfo, addTeamInfo } from "@/api/eres";
export default {
  components: {
    systemModal
  },
  data() {
    return {
      title: "救援队设置",
      model: {
        teamName: "",
        teamStation: "",
        rescueScope: "",
        captainName: "",
        captainPhone: "",
        captainSpecialty: ""
      },
      loading: false,
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      }
    };
  },
  mounted() { },
  methods: {
    handleAdd(record) {
      if (record.id) {
        this.model = Object.assign({}, record);
      }
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.model.id) {
            // 编辑修改
            const params = {
              id: this.model.id,
              data: this.model
            };
            updateTeamInfo(params).then(res => {
              if (res.code == 0) {
                this.loading = false;
                this.$refs.formModal.handleClose();
                this.$message.success("操作成功");
                this.$emit("teamFormSubmit");
              }
            });
          } else {
            // 新增
            addTeamInfo(this.model).then(res => {
              if (res.code == 0) {
                this.loading = false;
                this.$refs.formModal.handleClose();
                this.$message.success("操作成功");
                this.$emit("teamFormSubmit");
              }
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 图片上传成功
    handleAvatarSuccess() { },
    beforeAvatarUpload() { }
  }
}
</script>

<style lang="scss" scoped>
</style>
