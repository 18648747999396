import { httpInit } from "./http";
const http = httpInit("/gzdt-dict");
// 根据label查询字典详情
export const queryDictByLabel = data => http.get(`/api/bylabel?label=${data.label}`);
// 根据id查询字典详情
export const queryDictById = params => http.get(`/api/info/${params.id}`);
// 查询子字典节点列表
export const queryDictNodesById = params => http.get(`/api/son/${params.id}`);

// 分页查询字典列表
export const queryDictList = params => http.get(`/master/page/${params.label}/${params.parent}/${params.canModify}/${params.page}/${params.size}`);
// 新增字典项
export const addDictInfo = data => http.post(`/master/info`, data)

//删除字典项
export const deleteDict = id => http.delete(`/master/info/${id}`);


export const getDetails = id => http.get(`/master/info/${id}`);
// 新增字典项
export const editDictInfo = (params) =>
  http.put(`/master/info/${params.id}`, params.form);
//根据code查询子字典
export const getDictData = code => http.get(`/api/son/code/${code}`);

//根据code查询字典详情
export const getCodeDictDetails = params => http.get('/api/bycode?code=' + params)

//根据code查询子字典节点列表
export const queryDictByCode = params => http.get(`/api/son/code/${params.code}`);
// 分页查询字典列表
export const getDictList = data => http.post(`/api/tree/cond`,data);


// 合并开始
const https = httpInit("/gzdt-response");
// 灾害情况及恢复上报
export const situationReport = data => https.post(`/response/situation/report`, data)
// 建设工地抢险表单查询
export const theEcho = params => https.get(`/response/situation/query/${params.reportId}`);
// 通过
export const throughapi = data => https.post(`/response/situation/adopt`, data)
// 驳回
export const rejectedhapi = data => https.post(`/response/situation/reject`, data)
// 查询当前响应id
export const queryCurrentResponseId = () => https.get(`/response/queryCurrentResponseId`);
// 查询当前响应时间记录
export const queryCurrentResponse = () => https.get(`/response/queryCurrentResponse`);
// 获取头部表格数据
export const theEchoQueryStatic = params => https.get(`/response/situation/queryStatic/${params.reportId}`);
