<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="单位">
            <el-select v-model="queryModal.vendorId" clearable placeholder="请选择单位">
              <el-option
                v-for="item of dict.vendor"
                :key="item.uuid"
                :label="item.companyName"
                :value="item.uuid"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input
              v-model="queryModal.realName"
              placeholder="输入姓名查询"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="handleAdd" v-has="'guarantee:add'"
            >新增</el-button
          >
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column align="center" prop="line" label="线路工点" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.line }}-{{ scope.row.site }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="realName"
          label="姓名"
          width="180"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="vendor"
          label="包保单位"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="roleName"
          label="角色"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="phone"
          label="联系电话"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="district"
          label="地址"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-has="'guarantee:edit'"
              >编辑</el-button
            >
            <el-divider direction="vertical"></el-divider>

            <el-button
              @click="handleDelete(scope.row)"
              type="text"
              v-has="'guarantee:del'"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <PersonForm ref="modelForm" @formSubmit="handleFormSubmit"></PersonForm>
  </div>
</template>

<script>
import PersonForm from "./modules/personForm.vue";
import { queryWorkerListByPage, delWorker } from "@/api/eres";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { queryOrgPageList } from "@/api/org";
export default {
  components: {
    PersonForm,
  },
  data() {
    return {
      type: "",
      queryModal: {
        vendorId: "",
        realName: "",
      },
      tableData: [],
      loading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      dict: {
        vendor: [],
      },
    };
  },
  mounted() {
    this.queryDictByLabel();
    this.queryOrgData();
  },
  methods: {
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 查询组织机构
    queryOrgData() {
      const params = {
        category: "-",
        name: "-",
        pageNo: 1,
        pageSize: 1000,
      };
      queryOrgPageList(params).then((res) => {
        if (res.code == 0) {
          this.dict.vendor = res.data.records;
        }
      });
    },
    queryDictByLabel() {
      this.loading = true;
      queryDictByLabel({ label: "包保人员" }).then((res) => {
        if (res.code == 0) {
          this.type = res.data.id;
          this.loadData();
        }
      });
    },
    handleSearch() {
      this.page.pageNo = 1;
      this.loadData();
    },
    handleAdd() {
      this.$refs.modelForm.handleAdd(this.type);
    },
    handleEdit(record) {
      this.$refs.modelForm.handleAdd(this.type, record);
    },
    handleDelete(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delWorker(record.id).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadData();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    handleFormSubmit() {
      this.loadData();
    },
    loadData() {
      this.loading = true;
      const params = {
        departmentId: "-",
        grouping: "-",
        lineId: "-",
        page: this.page.pageNo,
        realName: this.queryModal.realName ? this.queryModal.realName : "-",
        siteId: "-",
        size: this.page.pageSize,
        type: this.type,
        vendorId: this.queryModal.vendorId ? this.queryModal.vendorId : "-",
      };
      queryWorkerListByPage(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          this.page.pageNo = res.data.current;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.total;
          this.page.count = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
