<template>
  <div class="ertt">
    <el-tabs v-model="activeName" type="card" @tab-click="handClick">
      <el-tab-pane label="事件快报" name="1">
        <el-form
          :model="formData"
          ref="ruleForm "
          label-width="130px"
          v-loading="fromLoading"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="事件名称"
                prop="startTime"
                style="margin-top: 10px"
              >
                <!-- readonly -->
                <el-input
                  v-model="formData.name"
                  readonly
                  placeholder="事件名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="上报人" prop="type">
                <el-input
                  v-model="formData.publisherName"
                  readonly
                  placeholder="上报人"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            
            <el-col :span="12">
              <el-form-item label="事件类型" prop="currentLevel">
                <el-input
                  v-model="formData.typeName"
                  readonly
                  placeholder="事件类型"
                ></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="12">
              <el-form-item label="发生时间" prop="currentLevel">
                <el-input
                  v-model="formData.created"
                  readonly
                  placeholder="发生时间"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
           

            <el-col :span="12">
              <el-form-item label="事件等级" prop="currentLevel">
                <el-input
                  v-model="formData.levelName"
                  readonly
                  placeholder="事件等级"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地理位置" prop="currentLevel">
                <el-input
                  v-model="formData.xycoors"
                  readonly
                  placeholder="地理位置"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            
            <el-col :span="12">
              <el-form-item label="工程项目名称" prop="currentLevel">
                <el-input
                  v-model="formData.siteName"
                  readonly
                  placeholder="工程项目名称"
                ></el-input>
              </el-form-item>
            </el-col>
              <el-col :span="12">
              <el-form-item label="施工单位" prop="currentLevel">
                <el-input
                  v-model="formData.constructionUnitName"
                  readonly
                  placeholder="施工单位"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
          
            <el-col :span="12">
              <el-form-item label="监理单位" prop="currentLevel">
                <el-input
                  v-model="formData.supervisorUnitName"
                  readonly
                  placeholder="监理单位"
                ></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="12">
              <el-form-item label="负责人" prop="currentLevel">
                <el-input
                  v-model="formData.chargeName"
                  readonly
                  placeholder="负责人"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        
          <el-row>
            <el-col :span="24">
              <el-form-item label="事件经过" prop="originalAlertMessage">
                <el-input
                  readonly
                  type="textarea"
                  resize="none"
                  placeholder="请输入作业内容、事发情形等"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  v-model="formData.historyRecord"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="伤亡情况" prop="originalAlertMessage">
                <el-input
                  readonly
                  type="textarea"
                  resize="none"
                  placeholder="请输入人员伤亡、直接经济损失和影响范围"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  v-model="formData.casualties"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="处置情况" prop="originalAlertMessage">
                <el-input
                  readonly
                  type="textarea"
                  resize="none"
                  placeholder="请输入应急救援、已采取和拟采取的措施等"
                  v-model="formData.disposal"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="附件" prop="currentLevel">
                <a
                  v-for="(item, index) in imgs"
                  :key="index"
                  :href="item.url"
                  target="_blank"
                  style="color: #409eff; display: block"
                  >{{ item.originalName }}</a
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="事件终报" name="2" v-if="terf">
        <el-form
          :model="formInline"
          class="demo-form-inline"
          label-width="100px"
          ref="ruleForm"
        >
          <el-form-item
            label="事件名称:"
            prop="responseRecordName"
            style="margin-top: 10px"
          >
            <el-input
              readonly
              v-model="formInline.responseRecordName"
            ></el-input>
          </el-form-item>
          <el-form-item label="事件等级:" prop="responseLevle">
            <el-input readonly v-model="formInline.levelName"></el-input>
          </el-form-item>
          <el-form-item label="事发经过:" prop="historyRecord">
            <el-input
              readonly
              type="textarea"
              v-model="formInline.historyRecord"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="伤亡情况:" prop="casualties">
            <el-input
              readonly
              type="textarea"
              v-model="formInline.casualties"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="处置情况:" prop="disposal">
            <el-input
              readonly
              type="textarea"
              v-model="formInline.disposal"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="照片或附件:">
            <el-link
              type="primary"
              v-for="(item, index) in enclosure"
              :key="index"
              :href="item.uri"
              target="_blank"
              >{{ item.originalName }}</el-link
            >
          </el-form-item>
        </el-form></el-tab-pane
      >
      <el-tab-pane label="事件进展" name="3"
        ><div>
          <div v-for="(activity, index) in formData.processList" :key="index">
          
                <div class="actitle">
                <span class="incid">{{ activity.responseProcessName }}</span>
                <span class="times" style="margin-left:20px">{{ activity.created }}</span>
              </div>
              <div
                class="content"
                v-show="
                  activity.responseProcessName == '事件发布' ||
                  activity.responseProcessName == '事件续报' ||
                  activity.responseProcessName == '事件终报'
                "
              >
                <span class="incid"> 上报人：</span>
                <span>{{ activity.responseUserName }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '应急预警'"
              >
                <span class="incid"> 启动人员：</span>
                <span>{{ activity.responseUserName }}</span>
              </div>
               
                <div
                class="content"
                v-show="activity.responseProcessName == '资源调配'"
              >
                <span class="incid"> 调配人：</span>
                <span></span>
              </div>
               <div
                class="content"
                v-show="activity.responseProcessName == '资源调配'"
              >
                <span class="incid"> 调配时间：</span>
                <span></span>
              </div>
               <div
                class="content"
                v-show="activity.responseProcessName == '资源调配'"
              >
                <span class="incid"> 调配内容：</span>
                <span></span>
              </div>
              <div
                class="content"
                v-show="
                 
                  activity.responseProcessName == '事件终报'
                "
              >
                <span class="incid">事件等级:</span
                ><span>{{
                  activity.levelName ? activity.levelName : "Ⅲ"
                }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '应急预警'"
              >
                <span class="incid">预警等级:</span
                ><span>{{
                  activity.levelName ? activity.levelName : "Ⅲ"
                }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '响应升级'"
              >
                <span class="incid">升级级别:</span
                ><span v-if="activity.levelName == 'Ⅱ'"
                  >Ⅲ->{{ activity.levelName }}</span
                >
                <span v-if="activity.levelName == 'Ⅰ'"
                  >Ⅱ->{{ activity.levelName }}</span
                >
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '事件终报'"
              >
                <span class="incid"> 事件经过:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '事件确认'"
              >
                <span class="incid"> 确认人:</span
                ><span>{{ activity.createName }}</span>
              </div>
              <div
                class="content"
                v-show="
                  activity.responseProcessName == '事件续报' ||
                  activity.responseProcessName == '事件终报'
                "
              >
                <span class="incid"> 伤亡情况:</span
                ><span>{{ activity.casualties }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '响应升级'"
              >
                <span class="incid">升级原因:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '应急预警'"
              >
                <span class="incid">预警原因:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
              <div
                class="content"
                v-show="
                 
                  activity.responseProcessName == '响应升级'
                "
              >
                <span class="incid">升级人员:</span
                ><span>{{ activity.createName }}</span>
              </div>
               <div
                class="content"
                v-show="
                  activity.responseProcessName == '启动预案' 
                "
              >
                <span class="incid">启动人员:</span
                ><span>{{ activity.createName }}</span>
              </div>
                <div
                class="content"
                 v-show="
                  activity.responseProcessName == '事件续报'
                "
              >
                <span class="incid">事件经过:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
                <div
                class="content"
                 v-show="
                  activity.responseProcessName == '响应结束'
                "
              >
                <span class="incid">结束人员:</span
                ><span></span>
              </div>
               <div
                class="content"
                v-show="
                  activity.responseProcessName == '事件续报'
                "
              >
                <span class="incid">附件:</span
                ><el-link v-for="(item,index) in enclosure" :key="index"  :href="item.uri"  style="margin-left: 4px; font-size: 12px;"  type="primary">{{item.originalName}}</el-link>
              </div>
              <div class="content">
                <span
                  class="incid"
                  v-if="activity.responseProcessName == '启动预案' ||  activity.responseProcessName == '事件续报' ||
                    activity.responseProcessName == '事件终报'"
                  >{{
                    activity.responseProcessName == "启动预案"
                      ? "启动预案:"
                      : "处置情况:"
                  }}</span
                ><span v-if="activity.responseProcessName == '启动预案'"
                  ><el-link
                    type="primary"
                    style="margin-left: 4px; font-size: 12px"
                    target="_blank"
                    :href="activity.enclosure"
                    >《{{ activity.disposal }}》</el-link
                  >预案</span
                ><span
                  v-if="
                    activity.responseProcessName == '事件续报' ||
                    activity.responseProcessName == '事件终报'
                  "
                  >{{ activity.disposal }}</span
                >
              </div>
            <el-divider></el-divider>
          </div></div
      ></el-tab-pane>
      <el-tab-pane label="舆情信息" name="4">舆情信息</el-tab-pane>
      <el-tab-pane label="物资调配" name="5">
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          element-loading-spinner="el-icon-loading"
          :header-cell-style="{color:'#000000'}"
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
          ></el-table-column>
           <el-table-column
            align="center"
            prop="sourceName"
            label="被调配方"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="chargeName"
            label="被调配方负责人"
          ></el-table-column>
            <el-table-column
            align="center"
            prop="chargeMobile"
            label="被调配方联系方式"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="materialsNameList"
            label="调配内容"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="deployName"
            label="调配人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="deployDate"
            label="调配时间"
          ></el-table-column>
          
        
          <el-table-column align="center" prop="state" label="状态">
           
          </el-table-column>
          <el-table-column
            align="center"
            prop="deliveryName"
            label="配送人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="deliveryMobile"
            label="配送人联系方式"
          ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="人员调配" name="6"
        ><el-table
          :data="tableDatas"
          style="width: 100%"
          border
          element-loading-spinner="el-icon-loading"
          :header-cell-style="{color:'#000000'}"
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="rescueTeamName"
            label="调配队伍"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="rescueName"
            label="调配人员"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="rescueMobile"
            label="联系方式"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="deployDate"
            label="调配时间"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="chargeName"
            label="被调配方负责人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="chargeMobile"
            label="负责人联系方式"
          ></el-table-column>
          <el-table-column align="center" prop="state" label="状态">
          </el-table-column
          >
        </el-table></el-tab-pane
      >
    </el-tabs>

    <div style="display: flex; justify-content: center">
      <!-- <el-button type="primary" @click="sureSub('ruleForm')">确认</el-button> -->
    </div>
  </div>
</template>

<script>
import { materialsRecord, userRecord } from "@/api/eres";

export default {
  props: {
    caseList: {},
    detailData: {},
    endtag: {
      type: Number,
    },
   
  
  },
  watch: {
    endtag: {
      handler(val) {
        if (val == 1) {
             
          this.terf = true;
        } else {
           
          this.terf = false;
        }
      },
      immediate: true,
    },
   
    detailData:{
       handler(val) {
        console.log(val)
        if (val) {
              this.activeName="1"
            if (this.detailData.processList) {
      this.detailData.processList.forEach((e) => {
      
        if (e.enclosure != null&&e.responseProcessName == "事件发布") {
          // this.imgs.push.apply(this.imgs, [JSON.parse(JSON.stringify(e.enclosure))]);
           this.imgs= JSON.parse(e.enclosure);
          
        }
      });
    }
    if (this.detailData.enclosure!=null) {
       
      this.imgs.push(JSON.parse(this.detailData.enclosure));
    }
    console.log(this.detailData);
    this.formData = this.detailData;
    if (this.detailData.processList) {
      this.detailData.processList.forEach((el) => {
        if (el.responseProcessName == "事件终报"||el.responseProcessName == "事件续报") {
        
       
          this.enclosures = JSON.parse(el.enclosure);
          
        }
        
         
      });
    }
    if (this.detailData.processList) {
      this.detailData.processList.forEach((el) => {
        if (el.responseProcessName == "事件终报") {
          
          this.formInline = el;
          this.enclosure = JSON.parse(this.formInline.enclosure);
          this.terf = true;
        }
         else if (!el.responseProcessName == "事件终报") {
          this.terf = false;
        }
      });
    }
        } 
      },
      immediate: true,
    }
   
  },
  data() {
    return {
      dialogVisible: true,
      fromLoading: false,
      activeName: "1",
      formData: {},
      imgs: [],
      formInline: {},
      tableData: [],
      tableDatas: [],
      page: 1,
      size: 1000000,
      total: 0,
      terf: false,
      enclosure: [],
      enclosures:[],
      
    };
  },
created(){

},
  mounted() {
   
 
  },
  methods: {
    //物资表格数据
    getmaterialsListData() {          
      let parms = {
        id: this.detailData.uuid,
        // id: 2,
        page: this.page,
        size: this.size,
      };

      materialsRecord(parms).then((res) => {
        console.log(res.data)
        this.tableData = res.data; 
      });
    },
    getmaertye() {
      let parms = {
        id: this.detailData.uuid,
        // id: 2,
        page: this.page,
        size: this.size,
      };
      userRecord(parms).then((res) => {
        
        this.tableDatas = res.data;
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handClick(tab, event) {
      console.log(tab, event);
      if (tab.name == "5") {
        this.getmaterialsListData();
      } else if (tab.name == "6") {
        this.getmaertye();
      }
    },
    sureSub(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fromLoading = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ertt {
  height: 500px;
  overflow-y: scroll;
}
.content{
 margin-top: 10px;
}
::v-deep .el-table th.el-table__cell{
  color:#000000 !important;
}
</style>
