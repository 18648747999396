<template>
  <div class="work-container">
    <index-box :title="title">
      <div class="scroll-wrapper overflow-y-scroll">
        <div class="float-left work-canten mr-4">
          <!-- <span class="work-tit po">防汛作战图</span> -->
          <el-image
            style="width: 100%; height: 100%"
            :src="fxurl"
            :preview-src-list="[fxurl]"
          >
          </el-image>
        </div>
        <div class="float-left work-canten">
          <!-- <span style="line-height: 35px"
            ><div>施工场地<br />周边排水图</div></span
          > -->
          <el-image
            style="width: 100%; height: 100%"
            :src="psurl"
            :preview-src-list="[psurl]"
          >
          </el-image>
        </div>
        <div class="float-left work-canten">
          <!-- <span class="work-tit">明白卡</span> -->
          <el-image
            style="width: 100%; height: 100%"
            :src="mburl"
            :preview-src-list="[mburl]"
          >
          </el-image>
        </div>
      </div>
    </index-box>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import { getImageInfo } from "@/api/map";
export default {
  name: "work",
  components: {
    IndexBox,
  },
  data() {
    return {
      title: "工作图表",
      fxurl: "",
      mburl: "",
      psurl: "",
    };
  },
  mounted() {
    this.getCharts();
  },
  methods: {
    async getCharts() {
      let params = {
        id: this.$route.query.id,
      };
      let res = await getImageInfo(params);
      if (res.code == 0) {
        console.log(res);
        res.data.forEach((e) => {
          switch (e.type) {
            case 1:
              this.psurl = e.imageUrl;
              break;
            case 2:
              this.fxurl = e.imageUrl;
              break;
            case 3:
              this.mburl = e.imageUrl;
              break;
            default:
              break;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-wrapper {
  height: 100%;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}
.work-canten {
  width: 45%;
  height: 85px;
  background: #081742;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0px 1px 2px #000000;
  margin-bottom: 10px;
}
.work-tit {
  line-height: 85px;
}
</style>
