<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="type == 'view' ? viewRule : rules"
      ref="ruleForm"
      label-width="110px"
      class="demo-model"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="节点名称" prop="nodeName">
            <el-input
              :readonly="type == 'view' ? true : false"
              v-model="ruleForm.nodeName"
              placeholder="节点名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="线路工点" prop="siteUuid">
            <el-cascader
              v-if="type != 'view'"
              style="width: 100%"
              clearable
              v-model="siteList"
              :options="lineSiteData"
              :props="props"
              :show-all-levels="false"
              @change="handleLineSiteChange"
            ></el-cascader>
            <!-- collapse-tags -->
            <el-input
              v-if="type == 'view'"
              v-model="ruleForm.siteName"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="类别" prop="nodeTypeCode">
            <el-select
              style="width:100%"
              v-if="type != 'view'"
              v-model="ruleForm.nodeTypeCode"
              @change="handleChangeNodeType"
              placeholder="选择类别"
            >
              <el-option
                v-for="item in keyNodeTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <el-input
              v-if="type == 'view'"
              v-model="ruleForm.nodeTypeName"
              readonly
              placeholder="级别"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="级别" prop="nodeLevelCode">
            <el-select
              style="width:100%"
              v-if="type != 'view'"
              v-model="ruleForm.nodeLevelCode"
              @change="handleChangeNodeLevel"
              placeholder="选择级别"
            >
              <el-option
                v-for="item in keyNodesLevelList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <el-input
              v-if="type == 'view'"
              v-model="ruleForm.nodeLevelName"
              readonly
              placeholder="级别"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="预计开始时间" prop="estimateStartDay">
            <el-date-picker
              v-if="type != 'view'"
              style="width: 100%"
              v-model="ruleForm.estimateStartDay"
              type="datetime"
              placeholder="选择时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
            <el-input
              v-if="type == 'view'"
              v-model="ruleForm.estimateStartDay"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="预计结束时间" prop="estimateEndDay">
            <el-date-picker
              v-if="type != 'view'"
              style="width: 100%"
              v-model="ruleForm.estimateEndDay"
              type="datetime"
              placeholder="选择时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
            <el-input
              v-if="type == 'view'"
              v-model="ruleForm.estimateEndDay"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8" v-if="type != 'view'">
          <el-form-item label="位置">
            <!-- :disabled="submitType === 'detail' "  -->
            <el-button size="medium" type="primary" @click="choseMap"
              >选择坐标</el-button
            >
          </el-form-item>
        </el-col>
        <el-col :span="type == 'view' ? 12 : 8">
          <el-form-item label="经度" prop="lon">
            <el-input
              v-model="ruleForm.lon"
              clearable
              readonly
              style="width: 100%"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="type == 'view' ? 12 : 8">
          <el-form-item label="纬度" prop="lat">
            <el-input
              v-model="ruleForm.lat"
              clearable
              readonly
              style="width: 100%"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="dialogFooter" v-if="type != 'view'">
      <el-button size="medium" @click="cancel">取 消</el-button>
      <el-button size="medium" type="primary" @click="submit">确 定</el-button>
    </div>
    <MapForm ref="mapForm" :getCoo="getCoo"></MapForm>
  </div>
</template>

<script>
import { nodeAdd, nodeEdit } from "@/api/riskManage";
import MapForm from "@/views/Contingency/System/Resource/modules/map";
export default {
  components: {
    MapForm
  },
  props: {
    keyNodeTypeList: {
      type: Array,
      default: () => []
    },
    keyNodesLevelList: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: () => ""
    },
    nodeId: {
      type: Number,
      default: () => 0
    },
    nodeForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ruleForm: {
        warnName: "",
        lineName: "", //线路名称
        lineUuid: "", //线路uuid
        branchName: "", //分部名称
        branchUuid: "", //分部uuid
        siteName: "", //站点名称
        siteUuid: "", //站点uuid
        lon: "",
        lat: ""
      },
      rules: {
        nodeName: [
          { required: true, message: "请输入节点名称", trigger: "blur" }
        ],
        siteUuid: [
          { required: true, message: "请选择线路工点", trigger: "change" }
        ],
        nodeTypeCode: [
          { required: true, message: "请选择类别", trigger: "change" }
        ],
        nodeLevelCode: [
          { required: true, message: "请选择级别", trigger: "change" }
        ],
        estimateStartDay: [
          { required: true, message: "请选择预计开始时间", trigger: "change" }
        ],
        estimateEndDay: [
          { required: true, message: "请选择预计结束时间", trigger: "change" }
        ]
      },
      viewRule: {},
      isRequire: false,
      lineSiteData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid"
      },
      selloading: false,
      options: [],
      fileList: [], // 附件列表
      siteList: [], // 层级站点树
      faltArr: [], //站点数平行数据
      photoList: [], //数组图片列表
      faltSiteList: []
    };
  },
  created() {
    this.getLineTree();
  },
  mounted() {
    if (this.type == "view" || this.type == "edit") {
      this.ruleForm = Object.assign({}, this.nodeForm);
      let site = this.faltArr.find(i => i.uuid == this.ruleForm.siteUuid);
      if (site.puuid == this.ruleForm.branchUuid) {
        this.siteList = [
          this.ruleForm.lineUuid,
          this.ruleForm.branchUuid,
          this.ruleForm.siteUuid
        ];
      } else {
        this.siteList = [
          this.ruleForm.lineUuid,
          this.ruleForm.branchUuid,
          site.puuid,
          this.ruleForm.siteUuid
        ];
      }
    } else {
      this.getCoo();
    }
  },
  methods: {
    getCoo(lon, lat) {
      this.ruleForm.lon = lon;
      this.ruleForm.lat = lat;
      this.$forceUpdate();
    },
    async getLineTree() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.lineSiteData = JSON.parse(JSON.stringify(lineSiteData));
        this.lineSiteData.forEach(item => {
          this.flatArray(item);
        });
        console.log(this.faltArr);
      }
    },
    flatArray(data) {
      this.faltArr.push(data);
      if (data.children && data.children.length > 0) {
        data.children.forEach(item => {
          this.flatArray(item);
        });
      }
    },
    handleLineSiteChange(val) {
      console.log(val, "val");
      if (val && val.length > 0) {
        this.ruleForm.lineUuid = val[0];
        this.ruleForm.branchUuid = val[1];
        this.ruleForm.siteUuid = val[val.length - 1];
        let site = this.faltArr.find(
          item => item.uuid == this.ruleForm.siteUuid
        );
        let line = this.faltArr.find(
          item => item.uuid == this.ruleForm.lineUuid
        );
        let branch = this.faltArr.find(
          item => item.uuid == this.ruleForm.branchUuid
        );
        this.ruleForm.siteName = site.name;
        this.ruleForm.lineName = line.name;
        this.ruleForm.branchName = branch.name;
      }
    },
    choseMap() {
      this.$refs.mapForm.handleAdd(
        this.ruleForm.lon,
        this.ruleForm.lat,
        this.ruleForm.teamStation
      );
    },
    urlChange(urlList) {
      this.photoList = [];
      for (let item of urlList) {
        this.photoList.push(item.url);
      }
    },
    handleChangeNodeType(val) {
      console.log(val, "val");
      const _this = this;
      this.keyNodeTypeList.forEach(el => {
        if (el.code == val) {
          _this.ruleForm.nodeTypeName = el.label;
        }
      });
    },
    handleChangeNodeLevel(val) {
      console.log(val, "val");
      const _this = this;
      this.keyNodesLevelList.forEach(el => {
        if (el.code == val) {
          _this.ruleForm.nodeLevelName = el.label;
        }
      });
    },
    cancel() {
      this.reset();
      this.$emit("CloseData");
    },
    reset() {
      const th = this;
      Object.keys(this.ruleForm).forEach(function(key) {
        th.ruleForm[key] = "";
      });
      Object.keys(this.nodeForm).forEach(function(key) {
        th.nodeForm[key] = "";
      });
      this.$refs.ruleForm.resetFields();
    },
    submit() {
      const _this = this;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          var api = null;
          if (_this.type == "add") {
            api = nodeAdd(this.ruleForm);
          } else if (_this.type == "edit") {
            api = nodeEdit(this.ruleForm);
          }
          api.then(res => {
            if (res.code == 0) {
              this.$message.success("操作成功");
              this.loading = true;
              this.reset();
              this.$emit("submitClose");
            } else {
              this.$message.error(res.message);
            }
          });
          this.loading = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background-color: none !important;
}
.imgGroupBox {
  display: flex;
  flex-wrap: wrap;
}
.huixianImg {
  width: 148px;
  height: 148px;
  margin-left: 15px;
}
.huixianImg:first-child {
  margin-left: 0;
}
.fileGroupBox {
  span {
    color: #3fa5e9;
    cursor: pointer;
    margin-left: 10px;
  }
}
</style>
