<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>

          <el-form-item label="">
            <el-select v-model="queryModal.unitType" clearable placeholder="单位类型" @change="companyChange"
                       style="width: 100%">
              <el-option
                  v-for="item in examCompany"
                  :key="item.value"
                  :label="item.label"
                  :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="queryModal.roleId" clearable placeholder="用户角色"
                       style="width: 100%">
              <el-option
                  v-for="item in roleList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>

        </el-row>

        <el-row>
          <el-form-item>
            <el-button type="primary" v-has="'drill:add'" @click="handleAdd">新建</el-button>
          </el-form-item>
        </el-row>

      </el-form>
    </div>

    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column align="center" type="index" label="序号" width="100"></el-table-column>
        <el-table-column align="center" prop="unitName" label="单位类型" width="160"></el-table-column>
        <el-table-column align="center" prop="roleName" label="用户角色" width="160"></el-table-column>
        <el-table-column align="center" prop="frequency" label="次数" width="100"></el-table-column>
        <el-table-column align="center" prop="frequency" label="周期" width="100" :formatter="formatter"></el-table-column>
        <el-table-column align="center" prop="hiddenLevel" label="隐患等级" width=""></el-table-column>
        <el-table-column align="center" prop="created" label="创建时间" width=""></el-table-column>
        <el-table-column align="center" label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <!-- 计划审核 -->
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination background layout="prev, pager, next"
                     :total="page.total"
                     :page-size="page.pageSize"
                     :current-page="page.pageNo"
                     @current-change="handleCurrentChange"
                     @size-change="handleSizeChange">
      </el-pagination>
    </div>

    <el-dialog
        :title="titleAdd"
        :visible.sync="dialogVisible"
        class="editDialog"
        append-to-body
    >
      <troubleModal
          v-if="dialogVisible"
          :operation="operation"
          :edit-data="editData"
          @successModal="successModal"
          @cancelModal="cancelModal"
      ></troubleModal>
    </el-dialog>

  </div>
</template>

<script>
import {getDictData} from "@/api/dict";
import {getRoleApi} from "@/api/role";
import {screeningDelete, screeningList} from "@/api/hdanger";
import troubleModal from "@/views/HiddenDanger/System/Wooden/troubleFrequency/troubleModal";

export default {
  name: "troubleFrequency",
  components : {
    troubleModal
  },
  data(){
    return {
      queryModal : {
        unitType : '',
        roleId	 : '',
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      tableData : [],
      examCompany : [],
      roleList : [],
      loading : false,
      titleAdd : '',
      dialogVisible : false,
      editData : {},
      operation : ''
    }
  },
  created() {
    this.getDictList();
    this.getTableData();
  },
  methods : {
    reset(){
      this.queryModal = {
        unitType : '',
        roleId	 : '',
      }
    },
    getDictList() {
      getDictData('unitType').then((res) => {
        this.examCompany = res.data;
      });
    },
    formatter(row){
      switch (row.cycle){
        case 1:
          return '日';
          case 2:
          return '周';
          case 3:
          return '月';
          case 4:
          return '季';
      }
    },
    companyChange(){
      this.getRoleList();
    },
    getRoleList(){
      getRoleApi({ unitType: this.queryModal.unitType })
          .then((res) => {
            if (res.code == 0) {
              res.data.forEach((element) => {
                this.roleList.push({
                  label: element.roleName,
                  value: element.id,
                });
              });
            }
          })
          .catch((res) => {
            console.log(res.messafe);
          });
    },
    getTableData(){
      let params = {
        pageNum : this.page.pageNo,
        pageSize : this.page.pageSize,
        ...this.queryModal
      }
      screeningList(params).then(res => {
        this.tableData = res.data.records;
        this.page.total = res.data.count;
        this.loading = false;
      })
    },
    handleSearch() {
      this.page.pageNo = 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getTableData();
    },
    handleAdd() {
      this.titleAdd = "新增";
      this.operation = "add";
      this.dialogVisible = true;
    },
    cancelModal() {
      this.dialogVisible = false;
    },
    successModal() {
      this.dialogVisible = false;
      this.reset();
      this.getTableData();
    },
    handleEdit(row) {
      this.titleAdd = "编辑";
      this.operation = "edit";
      this.editData = { ...row };
      this.dialogVisible = true;
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        screeningDelete(row.id).then((res) => {
          if (res.code == 0) {
            this.$message.success("操作成功");
            this.getTableData();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
  }
}
</script>

<style scoped>

</style>
