<template>
  <div>
      <!-- 待盘点详情 -->
    <el-dialog
      title="待盘点详情"
      center
      :visible.sync="countedVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="countedform" label-width="100px">
        <el-form-item label="物资库名称">
          <el-input
            v-model="countedform.materialWareHouse"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="负责人">
          <el-input
            v-model="countedform.chargeName"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="countedform.mobile" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="截至时间">
          <el-input v-model="countedform.endDate" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
      <div class="rever" style="margin-top: 10px; margin-left: 10px">
        <div class="radio">处理过程（待盘点）</div>
        <el-timeline :reverse="reverse" style="margin-top: 10px; margin: 2px">
          <el-timeline-item
            v-for="(activity, index) in acticonunt"
            :key="index"
          >
            <div class="actitle">
              <span class="incid">{{ activity.title }}</span>
              <span class="times">{{ activity.created }}</span>
            </div>
            <div
              class="reported"
              v-show="
                activity.title == '物资盘点' || activity.title == '重新提交'
              "
            >
              <span>提交人:</span>
              <span>{{ activity.optionName }}</span>
            </div>
            <div class="reported" v-show="activity.title == '审核'">
              <span>审核人:</span>
              <span>{{ activity.optionName }}</span>
            </div>
            <div class="reported" v-show="activity.title == '审核'">
              <span>审核结果:</span>
              <span>{{ activity.examineOpinion }}</span>
            </div>
            <div class="reported" v-show="activity.title == '审核'">
              <span>审核意见:</span>
              <span>{{ activity.remark }}</span>
            </div>
            <div class="reported" v-show="activity.title == '重新提交'">
              <span>备注:</span>
              <span>{{ activity.remark }}</span>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
        <p style="color:red">“电脑端无法进行物资盘点操作，请前往手机端进行处理”</p>
    </el-dialog>
      <!-- 审核 -->
    <el-dialog
      title="审核"
      center
      :visible.sync="auditsserVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="详情" name="first">
          <el-form ref="form" :model="auditform" label-width="100px">
            <el-form-item label="物资库名称">
              <el-input
                v-model="auditform.materialWareHouse"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="负责人">
              <el-input
                v-model="auditform.chargeName"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input v-model="auditform.mobile" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="盘点时间">
              <el-input
                v-model="auditform.inventoryDate"
                :disabled="true"
              ></el-input>
            </el-form-item>

            <el-form-item label="盘点人员">
              <el-input
                v-model="auditform.inventoryUserName"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="auditform.remark" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="照片">
              <el-image
                v-for="(item, index) in imgs"
                :key="index"
                style="width: 100px; height: 100px"
                :src="item"
              ></el-image>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="异常物资" name="second">
          <el-table :data="materialList" style="width: 100%" height="300">
            <el-table-column prop="materialsName" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="num" label="现存数量" width="180">
            </el-table-column>
            <el-table-column prop="inventoryNum" label="盘点数量">
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="正常物资" name="third">
          <el-table :data="suppliesList" style="width: 100%"  height="300">
            <el-table-column prop="label" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="count" label="现存数量" width="180">
            </el-table-column>
            <el-table-column prop="address" label="盘点数量"> </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="处理过程" name="fourth" class="rever">
          <div class="block">
            <div class="radio">处理过程（审核中）</div>
            <el-timeline
              :reverse="reverse"
              style="margin-top: 20px; margin: 2px"
            >
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
              >
                <div class="actitle">
                  <span class="incid">{{ activity.title }}</span>
                  <span class="times">{{ activity.created }}</span>
                </div>
                <div
                  class="reported"
                  v-show="
                    activity.title == '物资盘点' || activity.title == '重新提交'
                  "
                >
                  <span>提交人:</span>
                  <span>{{ activity.optionName }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核人:</span>
                  <span>{{ activity.optionName }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核结果:</span>
                  <span>{{ activity.examineOpinion }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核意见:</span>
                  <span>{{ activity.remark }}</span>
                </div>
                <div class="reported" v-show="activity.title == '重新提交'">
                  <span>备注:</span>
                  <span>{{ activity.remark }}</span>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div></el-tab-pane
        >
      </el-tabs>
      <el-input
       
        type="textarea"
        :rows="2"
        placeholder="请填写审核意见"
        v-model="textarea"
      >
      </el-input>
      <el-row style="margin-top: 10px; text-align: center" >
        <el-button @click="reject">驳回</el-button>
        <el-button type="primary" @click="onSubmit">通过</el-button>
      </el-row>
    </el-dialog>
      <!-- 整改中详情 -->
    <el-dialog
      title="整改中详情"
      center
      :visible.sync="recticationdVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="详情" name="first">
          <el-form ref="form" :model="rectificaform" label-width="100px">
            <el-form-item label="物资库名称">
              <el-input
                v-model="rectificaform.materialWareHouse"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="负责人">
              <el-input
                v-model="rectificaform.chargeName"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input
                v-model="rectificaform.mobile"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="盘点时间">
              <el-input
                v-model="rectificaform.inventoryDate"
                :disabled="true"
              ></el-input>
            </el-form-item>

            <el-form-item label="盘点人员">
              <el-input
                v-model="rectificaform.inventoryUserName"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model="rectificaform.remark"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="照片">
              <el-image
                v-for="(item, index) in imgs"
                :key="index"
                style="width: 100px; height: 100px"
                :src="item"
              ></el-image>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="异常物资" name="second">
          <el-table :data="materialList" style="width: 100%">
            <el-table-column prop="materialsName" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="num" label="现存数量" width="180">
            </el-table-column>
            <el-table-column prop="inventoryNum" label="盘点数量">
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="正常物资" name="third">
          <el-table :data="suppliesList" style="width: 100%">
            <el-table-column prop="label" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="count" label="现存数量" width="180">
            </el-table-column>
            <el-table-column prop="address" label="盘点数量"> </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="处理过程" name="fourth" class="rever">
          <div class="block">
            <div class="radio">处理过程（整改中）</div>
            <el-timeline
              :reverse="reverse"
              style="margin-top: 20px; margin: 2px"
            >
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
              >
                <div class="actitle">
                  <span class="incid">{{ activity.title }}</span>
                  <span class="times">{{ activity.created }}</span>
                </div>
                <div
                  class="reported"
                  v-show="
                    activity.title == '物资盘点' || activity.title == '重新提交'
                  "
                >
                  <span>提交人:</span>
                  <span>{{ activity.optionName }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核人:</span>
                  <span>{{ activity.optionName }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核结果:</span>
                  <span>{{ activity.examineOpinion }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核意见:</span>
                  <span>{{ activity.remark }}</span>
                </div>
                <div class="reported" v-show="activity.title == '重新提交'">
                  <span>备注:</span>
                  <span>{{ activity.remark }}</span>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div></el-tab-pane
        >
      </el-tabs>
        <p style="color:red;margin-top:10px">“电脑端无法进行物资盘点操作，请前往手机端进行处理”</p>
    </el-dialog>
    <el-dialog  center
      :visible.sync="auertrVisible"
      width="30%"
      :before-close="handleClose">
      <p style="color:red;margin-top:10px;text-align: center;">“电脑端无法进行打卡，请前往手机端进行处理”</p>
    </el-dialog>
  </div>
</template>

<script>
import {
  inventoryList,
  process,
  material,
  supplies,
  inventory,
} from "@/api/eres.js";
export default {
data(){
    return{
        countedVisible:false,
        auditsserVisible:false,
          recticationdVisible: false,
          auertrVisible:false,
        countedform:{},
        auditform:{},
        acticonunt:[],
        rectificaform:{},
        activeName:"first",
        textarea:""
    }
},
methods:{
  complerete(){
this.auertrVisible=true
  },
     //待盘点
    besconunt(val) {
      inventoryList({ id: val.eventId }).then((res) => {
        console.log(res);
        this.countedform = res.data;
      });
      process({ id: val.eventId }).then((data) => {
        console.log(data);
        this.acticonunt = data.data;
      });
      this.countedVisible = true;
    },
     //审核
    auditerts(val) {
      this.inventoryId = val.eventId;
      inventoryList({ id: val.eventId }).then((res) => {
        this.auditform = res.data;
        this.imgs = res.data.imgs;
          supplies({
        id: val.eventId,
        siteId:  this.auditform.siteId,
      }).then((res) => {
        this.suppliesList = res.data;
      });
      });
      material({ id: val.eventId }).then((res) => {
        this.materialList = res.data;
      });
    
      process({ id: val.eventId }).then((data) => {
        this.activities = data.data;
      });
      this.auditsserVisible = true;
    },
     //整改中
    rectification(val) {
      inventoryList({ id: val.eventId }).then((res) => {
        this.rectificaform = res.data;
        this.imgs = res.data.imgs;
         supplies({
        id: val.eventId,
        siteId: this.rectificaform.siteId,
      }).then((res) => {
        this.suppliesList = res.data;
      });
      });
      material({ id: val.eventId }).then((res) => {
        this.materialList = res.data;
      });
     
      process({ id: val.eventId }).then((data) => {
        this.activities = data.data;
      });
      this.recticationdVisible = true;
    },
    //通过
    onSubmit() {
      inventory({
        examineOpinion: this.textarea,
        state: 2,
        inventoryId: this.inventoryId,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "已审核",
            type: "success",
          });
        }
        this.auditsserVisible = true;
        this.textarea=""
      });
    },
    //驳回
    reject() {
        if(this.textarea==""){
              this.$message({
          message: '请填写审核意见',
          type: 'warning'
        });
        }else{
               inventory({
        examineOpinion: this.textarea,
        state: 1,
        inventoryId: this.inventoryId,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "已审核",
            type: "success",
          });
           this.auditsserVisible = true;
        this.textarea=""
        }
      });
        }
   
    },
    handleClose(){
        this.textarea=""
        this.countedVisible = false;
        this.auditsserVisible =false
        this.recticationdVisible=false
        this.auertrVisible=false
    }

}
}
</script>

<style>

</style>