import Layout from "@/components/Layout/SystemLayout.vue";
import DutyPage from "@/views/Contingency/System/Personnel/dutyPage.vue";
import DutyLeaderPage from "@/views/Contingency/System/Personnel/dutyLeaderPage.vue";
import DutyRescuePage from "@/views/Contingency/System/Personnel/dutyRescuePage.vue";
import GuaranteePage from "@/views/Contingency/System/Personnel/guaranteePage.vue";
import StandingBookPage from "@/views/Contingency/System/Personnel/standingBookPage.vue";
import RescueTeamPage from "@/views/Contingency/System/Personnel/rescueTeamPage.vue";
import TrainPage from "@/views/Contingency/System/TrainingDrill/Train.vue";
import DrillPage from "@/views/Contingency/System/TrainingDrill/Drill.vue";
import Materials from "@/views/Contingency/System/Resource/Materials";
import Equipment from "@/views/Contingency/System/Resource/Equipment";
import Warehouseman from "@/views/Contingency/System/Resource/Warehouseman";
import Expert from "@/views/Contingency/System/Resource/Expert";
import Soldier from "@/views/Contingency/System/Resource/Soldier";
import SoleDuty from "@/views/Contingency/System/Resource/SoleDuty";
import Pluralism from "@/views/Contingency/System/Resource/Pluralism";
import Public from "@/views/Contingency/System/Resource/Public";
import MemberList from "@/views/Contingency/System/Resource/MemberList";
import MaterialList from "@/views/Contingency/System/Resource/MaterialList";
import EquipmentList from "@/views/Contingency/System/Resource/EquipmentList";
import EmergencyResponseCard from "@/views/Contingency/System/Emergency/EmergencyResponseCard.vue";
import contingencyplan from "@/views/Contingency/System/Emergency/contingencyplan.vue";
import Historical from "@/views/Contingency/System/Response/Historical.vue";
import Bimerses from "@/views/Contingency/System/Management/bimers.vue";
import Videupings from "@/views/Contingency/System/Management/index.vue"
import EmeStatics from "@/views/Contingency/System/EmeStatics/index.vue"
import EmeStaticsTable from "@/views/Contingency/System/EmeStatics/table.vue"

import CaseLibrary from "@/views/Contingency/System/Response/CaseLibrary.vue";
import Monitor from "@/views/System/Device/Monitor.vue";
import Assistance from "@/views/Contingency/System/Resource/Assistance"
const Routes = [
  {
    path: "Contingency",
    component: Layout,
    name: "Contingency",
    children: [
      {
        path: "Personnel",
        name: "Personnel",
        component: Layout,
        meta: {
          title: "人员管理",
          path: [
            {
              name: "人员管理",
            },
            {
              name: "首页",
            },
          ],
        },
        children: [
          {
            path: "duty",
            name: "duty",
            component: DutyPage,
          },
          {
            path: "dutyLeader",
            name: "dutyLeader",
            component: DutyLeaderPage,
          },
          {
            path: "dutyRescue",
            name: "dutyRescue",
            component: DutyRescuePage,
          },
          {
            path: "guarantee",
            name: "guarantee",
            component: GuaranteePage,
          },
          {
            path: "standingBook",
            name: "standingBook",
            component: StandingBookPage,
          },
          {
            path: "rescueTeam",
            name: "rescueTeam",
            component: RescueTeamPage,
          },
        ],
      },
      {
        path: "Resource",
        name: "Resource",
        component: Layout,
        meta: {
          title: "应急资源"
        },
        children: [
          {
            path: "Monitor",
            name: "Monitor",
            component: Monitor,
          },
          {
            path: "CaseLibrary",
            name: "CaseLibrary",
            component: CaseLibrary,
            meta: {
              title: "案例库",
            },
          },
          {
            path: "Materials",
            name: "Materials",
            component: Materials,
          },
          {
            path: "Equipment",
            name: "Equipment",
            component: Equipment,
          },
          {
            path: "Warehouseman",
            name: "Warehouseman",
            component: Warehouseman,
          },
          {
            path: "Expert",
            name: "Expert",
            component: Expert,
          },
          {
            path: "Soldier",
            name: "Soldier",
            component: Soldier,
          },
          {
            path: "SoleDuty",
            name: "SoleDuty",
            component: SoleDuty,
          },
          {
            path: "Pluralism",
            name: "Pluralism",
            component: Pluralism,
          },
          {
            path: "Public",
            name: "Public",
            component: Public,
          },
          {
            path: "MaterialList",
            name: "MaterialList",
            component: MaterialList,
          },
          {
            path: "EquipmentList",
            name: "EquipmentList",
            component: EquipmentList,
          },
          {
            path: "MemberList",
            name: "MemberList",
            component: MemberList,
          },
          {
            path:"Assistance",
            name:"Assistance",
            component:Assistance
          }
        ],
      },
      {
        path: "Management",
        name: "Management",
        component: Layout,
        meta: {
          title: "设备管理"
        },
        children: [
          {
            path: "Bimers",
            name: "Bimers",
            component: Bimerses,
          },
          {
            path:"index",
            name:"index",
            component:Videupings
          }
        ],
        
      },
      {
        path:"EmeStatics",
        name: "EmeStatics",
        component: EmeStatics,
        meta: {
          title: "数据统计"
        },
      },
      {
        path:"EmeStaticsTable",
        name: "EmeStaticsTable",
        component: EmeStaticsTable,
        meta: {
          title: "应急统计表格"
        },
      },
      {
        path: "TrainingDrill",
        name: "TrainingDrill",
        component: Layout,
        meta: {
          title: "培训演练",
        },
        children: [
          {
            path: "Train",
            name: "Train",
            component: TrainPage,
          },
          {
            path: "Drill",
            name: "Drill",
            component: DrillPage,
          },
        ],
      },
      {
        path: "Emergency",
        name: "Emergency",
        component: Layout,
        meta: {
          title: "应急预案"
        },
        children: [
          {
            path: "EmergencyResponseCard",
            name: "EmergencyResponseCard",
            component: EmergencyResponseCard,
            meta: {
              title: "应急预案",
            },
          },
          {
            path: "contingencyplan",
            name: "contingencyplan",
            component: contingencyplan,
            meta: {
              title: "应急处置卡",
            },
          },
        ],
      },
      {
        path: "Event",
        name: "Event",
        component: Layout,
        children: [
          {
            path: "history",
            name: "history",
            component: Historical,
            meta: {
              title: "历史事件"
            },
          }
        ],
      },
    ],
  },
]

export default Routes
