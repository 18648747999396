<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
            <el-select
              v-model="queryModal.ruleType"
              placeholder="选择类型"
              clearable
            >
              <el-option
                :label="data.label"
                :value="data.id"
                v-for="(data, index) in typeList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="">
            <el-date-picker
              v-model="queryModal.times"
              type="date"
              placeholder="选择时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="">
            <el-input
              v-model="queryModal.title"
              placeholder="输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="setsmit">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <!-- <el-button
            type="primary"
            @click="handleAdd"
            v-has="'floodControl:add'"
            >下载模板</el-button
          >
          <el-button
            type="primary"
            @click="handleAdd"
            v-has="'floodControl:add'"
            >导入</el-button
          > -->
          <el-button
            type="primary"
            @click="handleAdd"
            v-has="'floodControl:add'"
            >新增</el-button
          >
          <!-- <el-button
            type="primary"
            @click="handleAdd"
            v-has="'floodControl:add'"
            >导出</el-button
          > -->
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" v-loading="tableLoading" style="width: 100%">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="80"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="title"
          label="标题"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column align="center" prop="ruleType" label="类型">
          <template slot-scope="scope">
            <span>{{ scope.row.ruleTypeName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="summary"
          label="正文"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="realName"
          label="附件"
          width="150"
        >
          <template slot-scope="scope">
            <!-- <span class="cursor-pointer" style="color: #43a5e9">{{
              scope.row.imageName
            }}</span> -->
            <el-button type="text" @click="handleFileClick(scope.row)">{{
              scope.row.realName
            }}</el-button>
            <!-- <el-image
              style="width: 150px; height: 80px"
              :src="scope.row.imageUrl"
              :preview-src-list="[scope.row.imageUrl]"
            >
            </el-image> -->
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="uploadTime"
          label="发布日期"
        ></el-table-column>

        <el-table-column
          align="center"
          prop="uploaderName"
          label="发布人"
        ></el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >

            <el-button
              type="text"
              @click="handleDelete(scope.row)"
              v-has="'floodControl:del'"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="queryModal.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <RegulationsForm
      ref="modelForm"
      :typeList="typeList"
      @getdata="setsmit"
    ></RegulationsForm>
  </div>
</template>

<script>
import RegulationsForm from "./modules/regulationsForm.vue";
import { ruleSystemList, ruleSystemDelete } from "@/api/response";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
export default {
  components: {
    RegulationsForm,
  },
  data() {
    return {
      queryModal: {
        ruleType: "",
        times: "",
        title: "",
        page: 1,
        size: 10,
        total: 0,
        type: 1,
      },
      tableData: [],
      options: [],
      lineSiteData: [],
      typeList: [],
      props: {
        value: "id",
        label: "name",
        children: "nodes",
        multiple: true,
      },
      tableLoading: false,
    };
  },
  mounted() {
    queryDictByLabel({ label: "规章制度类型" }).then((res) => {
      queryDictNodesById({ id: res.data.id }).then((el) => {
        this.typeList = el.data;
        this.getdata();
      });
    });
  },
  methods: {
    setsmit() {
      this.queryModal.page = 1;
      this.getdata();
    },
    //条数改变
    handleSizeChange(val) {
      this.queryModal.size = val;
      this.queryModal.page = 1;
      this.getdata();
    },
    // 翻页
    handleCurrentChange(val) {
      this.queryModal.page = val;
      this.getdata();
    },
    async getdata() {
      this.tableLoading = true;
      this.tableData = [];
      let params = {
        ruleType: this.queryModal.ruleType,
        startTime: this.queryModal.times[0] ? this.queryModal.times[0] : "",
        endTime: this.queryModal.times[1] ? this.queryModal.times[0] : "",
        title: this.queryModal.title,
        pageNum: this.queryModal.page,
        pageSize: this.queryModal.size,
      };
      for (const key in params) {
        if (params[key] == "") {
          delete params[key];
        }
      }
      let res = await ruleSystemList(params);
      if (res.code == 0) {
        console.log(res);
        if (res.data.records) {
          this.tableData = res.data.records;
          this.tableData.forEach((e) => {
            this.typeList.forEach((el) => {
              if (el.id == e.ruleType) {
                e.ruleTypeName = el.label;
              }
            });
          });
          this.queryModal.total = res.data.count;
        }

        this.tableLoading = false;
      }
    },
    handleAdd() {
      this.$refs.modelForm.handleAdd();
    },
    handleEdit(record) {
      this.$refs.modelForm.handleAdd(record);
    },
    handleAudit(record) {
      this.$refs.AuditForm.handleAdd(record);
    },
    handleDelete(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        ruleSystemDelete(record).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.getdata();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    handleLog() {
      this.tableLoading = true;
    },
    handleFileClick(record) {
      const link = document.createElement("a");
      link.href = record.fullPath;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped></style>
