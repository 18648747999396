<template>
  <div>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="隐患详情" name="隐患详情"></el-tab-pane>
      <el-tab-pane label="隐患响应" name="隐患响应"></el-tab-pane>
      <el-tab-pane label="隐患整改" name="隐患整改"></el-tab-pane>
      <el-tab-pane label="隐患消除" name="隐患消除"></el-tab-pane>
    </el-tabs>

    <div v-if="activeName === '隐患详情' ">
      <troubleDetailDay :rule-form="ruleForm" v-if="type === 'everyday_check' "></troubleDetailDay>
      <troubleDetailSpec :rule-form="ruleForm" v-else></troubleDetailSpec>
    </div>

    <div v-else-if="activeName === '隐患响应' ">
      <el-timeline>
        <el-timeline-item
            v-for="(activity, index) in activities"
            placement="top"
            :key="index"
            :timestamp="activity.created">
<!--          {{activity.content}}-->
          <el-card>
            <span> {{ activity.reformUnitName }} - {{ activity.responsePerson }} ({{ activity.roleName }}) -
            {{ activity.status == 1 ? '未响应' : '已响应' }} ({{ activity.responseInfo }}) </span>
            <el-button type="text" style="font-size: 14px;margin-left: 20px" @click="filePreview(activity)">附件预览</el-button>
          </el-card>
<!--          <el-button type="text" style="font-size: 14px;margin-left: 20px">附件预览</el-button>-->
        </el-timeline-item>
      </el-timeline>
    </div>


    <div v-else>
      <el-timeline>
        <el-timeline-item
            v-for="(activity, index) in activities"
            placement="top"
            :key="index"
            :timestamp="activity.created">
          <!--          {{activity.content}}-->
          <el-card>
            <span> {{ activity.companyName }} - {{ activity.operator }} ({{ activity.roleName }}) -
            {{ activity.operateType == 0 ? '审核' : activity.operateType == 1 ? '整改' :
                  activity.operateType == 2 ? '隐患消除' : activity.operateType == 3 ? '审核驳回' :
                      activity.operateType == 4 ? '消除驳回' : ''}}
              ({{ activity.operateDescribe }}) </span>
            <el-button type="text" style="font-size: 14px;margin-left: 20px" @click="filePreview(activity)">附件预览</el-button>
          </el-card>
          <!--          <el-button type="text" style="font-size: 14px;margin-left: 20px">附件预览</el-button>-->
        </el-timeline-item>
      </el-timeline>
    </div>

    <AccessoryDialog :fileDialog="dialogVisible" :file-data="fileData" v-if="dialogVisible"
                     @closeAccessory="closeAccessory"></AccessoryDialog>

  </div>
</template>

<script>
import {detailsList, getDetails, getOperateRecords} from "@/api/hdanger";
import troubleDetailDay from "@/views/HiddenDanger/System/DangerTrouble/DayTrouble/components/troubleDetailDay";
import troubleDetailSpec from "@/views/HiddenDanger/System/DangerTrouble/DayTrouble/components/troubleDetailSpec";
import AccessoryDialog from "@/views/RiskManagement/System/RiskProcess/components/AccessoryDialog";

export default {
  name: "dayRecord",
  components : {
    troubleDetailDay,
    troubleDetailSpec,
    AccessoryDialog
  },
  props : {
    detailData : {
      default : () => {}
    },
    type : {
      default : ''
    },
    fatherId : {
      default : ''
    }
  },
  data(){
    return{
      ruleForm : {},
      rules : {},
      tableData : [],
      loading : false,
      dialogVisible : false,
      activeName : '隐患详情',
      activities: [{
        content: '活动按期开始',
        timestamp: '2018-04-15'
      }, {
        content: '通过审核',
        timestamp: '2018-04-13'
      }, {
        content: '创建成功',
        timestamp: '2018-04-11'
      }],
      fileData : {}
    }
  },
  created() {
    this.getDetailData();
  },
  methods : {
    getDetailData(){
      let params = {
        id : this.detailData.id,
        screeningType : this.type
      }
      getDetails(params).then(res => {
        if(res.code == 0){
          this.ruleForm = res.data;
        }
      })
    },
    getRectificationData(){
      let params = {
        taskItemId : this.fatherId,
        operateTypes : [1]
      }
      getOperateRecords(params).then(res => {
        console.log(res);
        this.activities = res.data;
      })
    },
    getEliminateData(){
      let params = {
        taskItemId : this.fatherId,
        operateTypes : [2,4]
      }
      getOperateRecords(params).then(res => {
        console.log(res);
        this.activities = res.data;
      })
    },
    getResponseLine(){
      detailsList(this.detailData.id).then(res => {
        this.activities = res.data;
      })
    },
    handleClick(){
      switch (this.activeName) {
        case '隐患详情' :
          this.getDetailData();
          break;
        case '隐患响应' :
          this.getResponseLine();
          break;
        case '隐患整改' :
          this.getRectificationData();
          break;
        case '隐患消除' :
          this.getEliminateData();
          break;
      }
    },
    filePreview(file){
      this.fileData = {...file}
      if(this.type === 'special_check'){
        this.fileData.fileList = this.fileData.operateRecordFiles;
      }
      console.log(this.fileData)
      this.dialogVisible = true;
    },
    closeAccessory(){
      this.dialogVisible = false;
    }
  }
}
</script>

<style scoped>

</style>
