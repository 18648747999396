<template>
  <!-- 响应 -->
  <div class="material-container h-full">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="预警详情" name="预警详情">
        <IssueWarnDialog
          type="view"
          :detailsForm="detailsForm"
          :warnType="warnType"
          :warnLevel="warnLevel"
        ></IssueWarnDialog>
      </el-tab-pane>

      <!-- 响应 -->
      <el-tab-pane label="预警响应" name="预警响应">
        <WarnResponse
          :eventId="eventId"
          v-if="response == 1"
          @CloseData="CloseData"
          ctroType="响应"
          :warnUuid="warnUuid"
        ></WarnResponse>
        <el-timeline
          v-if="response == 2 && activities.length > 0"
          style="margin:10px 0 0 10px"
        >
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            type="primary"
            color="#3fa5e9"
            :size="'large'"
          >
            <div>
              <i style="font-style:normal;margin-right: 5px;">{{
                activity.created
              }}</i
              ><span>{{
                activity.companyName +
                  "-" +
                  activity.userName +
                  "（" +
                  activity.roleName +
                  "）" +
                  "-已响应 " +
                  activity.remark
              }}</span>
              <span
                @click="hanlderViewList(activity)"
                v-if="activity.riskWarnFileList.length > 0"
                class="fileDialogBox"
                >附件列表
              </span>
            </div>
          </el-timeline-item>
        </el-timeline>
        <el-empty
          v-if="response == 2 && activities.length == 0"
          description="暂无响应数据"
        ></el-empty>
      </el-tab-pane>

      <!-- 整改 -->
      <el-tab-pane
        v-if="selected != '预警响应'"
        label="预警整改"
        name="预警整改"
      >
        <!-- <correctionForm  v-if="correction == 1" @CloseData="CloseData"></correctionForm> -->
        <WarnResponse
          v-if="correction == 1"
          :eventId="eventId"
          @CloseData="CloseData"
          ctroType="整改"
          :warnUuid="warnUuid"
        ></WarnResponse>
        <el-timeline
          v-if="correction == 2 && activities.length > 0"
          style="margin:10px 0 0 10px"
        >
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            type="primary"
            color="#3fa5e9"
            :size="'large'"
          >
            <div>
              <i style="font-style:normal;margin-right: 5px;">{{
                activity.created
              }}</i
              ><span>{{
                activity.companyName +
                  "-" +
                  activity.userName +
                  "（" +
                  activity.roleName +
                  "）" +
                  "-已整改 " +
                  activity.remark
              }}</span>
              <span
                @click="hanlderViewList(activity)"
                v-if="activity.riskWarnFileList.length > 0"
                class="fileDialogBox"
                >附件列表
              </span>
            </div>
          </el-timeline-item>
        </el-timeline>
        <el-empty
          v-if="correction == 2 && activities.length == 0"
          description="暂无整改数据"
        ></el-empty>
      </el-tab-pane>

      <!-- 审核 -->
      <el-tab-pane
        v-if="selected != '预警响应' && selected != '预警整改'"
        label="预警审核"
        name="预警审核"
      >
        <!-- <AuditForm v-if="audit == 1" @CloseData="CloseData"></AuditForm> -->
        <WarnResponse
          v-if="audit == 1"
          :eventId="eventId"
          @CloseData="CloseData"
          ctroType="审核"
          :warnUuid="warnUuid"
        ></WarnResponse>
        <el-timeline
          v-if="audit == 2 && activities.length > 0"
          style="margin:10px 0 0 10px"
        >
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            type="primary"
            color="#3fa5e9"
            :size="'large'"
          >
            <div>
              <i style="font-style:normal;margin-right: 5px;">{{
                activity.created
              }}</i
              ><span
                >{{
                  activity.companyName +
                    "-" +
                    activity.userName +
                    "（" +
                    activity.roleName +
                    "）"}}{{ activity.type == 2 ? "已驳回" : "已通过" }}
                {{ activity.remark }}
              </span>
              <span
                @click="hanlderViewList(activity)"
                v-if="activity.riskWarnFileList.length > 0"
                class="fileDialogBox"
                >附件列表
              </span>
            </div>
          </el-timeline-item>
        </el-timeline>
        <el-empty
          v-if="audit == 2 && activities.length == 0"
          description="暂无审核数据"
        ></el-empty>
      </el-tab-pane>

      <!-- 消除 -->
      <el-tab-pane
        v-if="
          selected != '预警响应' &&
            selected != '预警整改' &&
            selected != '预警审核'
        "
        label="预警消除"
        name="预警消除"
      >
        <!-- <RemoveForm v-if="remove == 1"></RemoveForm> -->
        <WarnResponse
          v-if="remove == 1"
          :eventId="eventId"
          @CloseData="CloseData"
          ctroType="消除"
          :warnUuid="warnUuid"
        ></WarnResponse>
        <el-timeline
          v-if="remove == 2 && activities.length > 0"
          style="margin:10px 0 0 10px"
        >
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            type="primary"
            color="#3fa5e9"
            :size="'large'"
          >
            <div>
              <i style="font-style:normal;margin-right: 5px;">{{
                activity.created
              }}</i
              ><span>{{
                activity.companyName +
                  "-" +
                  activity.userName +
                  "（" +
                  activity.roleName +
                  "）" +
                  "-已消除 " +
                  activity.remark
              }}</span>
              <span
                @click="hanlderViewList(activity)"
                v-if="activity.riskWarnFileList.length > 0"
                class="fileDialogBox"
                >附件列表
              </span>
            </div>
          </el-timeline-item>
        </el-timeline>
        <el-empty
          v-if="remove == 2 && activities.length == 0"
          description="当前预警暂未消除"
        ></el-empty>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import IssueWarnDialog from "./IssueWarnDialog.vue";
import WarnResponse from "../WarnResponse/responseFrom.vue";
import { warnDetails, warnRecord, warnRecord1 } from "@/api/riskManage";
export default {
  components: { IssueWarnDialog, WarnResponse },
  props: {
    selected: {
      type: String,
      default: () => ""
    },
    warnType: {
      type: Array,
      default: () => []
    },
    warnLevel: {
      type: Array,
      default: () => []
    },
    response: {
      type: Number,
      default: () => 2
    },
    correction: {
      type: Number,
      default: () => 2
    },
    audit: {
      type: Number,
      default: () => 2
    },
    remove: {
      type: Number,
      default: () => 2
    },
    warnId: {
      type: Number,
      default: () => null
    },
    warnUuid: {
      type: String,
      default: () => ""
    },
    eventId: {
      type: Number
    }
  },
  data() {
    return {
      activeName: "预警详情",
      // 时间线
      activities: [],
      detailsForm: {},
      params: {
        pageNum: 1,
        pageSize: 10,
        warnUuid: "",
        type: null
      },
      fileDialog: false
    };
  },
  mounted() {
    console.log(this.warnId, "warnid");
    this.activeName = this.selected;
    let searchId = this.warnId || this.eventId;
    console.log(this.warnId, this.eventId, searchId, "warnId,eventId");
    this.getWarnDetails(searchId);
  },
  methods: {
    getWarnDetails(id) {
      warnDetails(id).then(res => {
        this.detailsForm = res.data;
        this.warnUuid = res.data.warnUuid;
        console.log(res.data, "res");
        if (this.response == 2 && this.selected == "预警响应") {
          this.getRecord(this.warnUuid, 0, this.eventId);
        }
        if (this.correction == 2 && this.selected == "预警整改") {
          this.getRecord(this.warnUuid, 1, this.eventId);
        }
        if (this.audit == 2 && this.selected == "预警审核") {
          this.getRecord(this.warnUuid, 2, this.eventId);
        }
        if (this.remove == 2 && this.selected == "预警消除") {
          this.getRecord(this.warnUuid, 4, this.eventId);
        }
      });
    },
    getRecord(uuid, type, eventId) {
      console.log(eventId, "eventId");
      this.params.warnUuid = uuid;
      this.params.type = type;
      // if (eventId) {
      //   console.log("测试测试测试测试测试测试");
      //   this.params["eventId"] = eventId;
      //   warnRecord1(this.params).then(res => {
      //     this.activities = res.data.records;
      //   });
      // } else {
      warnRecord(this.params).then(res => {
        this.activities = res.data.records;
      });
      // }
    },
    handleClick(val) {
      console.log(val);
      const _this = this;
      // console.log("表头", val);
      this.params.pageNum = 1;
      this.activities = [];
      if (this.activeName == "预警响应") {
        if (this.response == 2) {
          _this.getRecord(_this.warnUuid, 0, this.eventId);
        }
      } else if (this.activeName == "预警整改") {
        if (this.correction == 2) {
          _this.getRecord(_this.warnUuid, 1, this.eventId);
        }
      } else if (this.activeName == "预警审核") {
        if (this.audit == 2) {
          _this.getRecord(_this.warnUuid, 2, this.eventId);
        }
      } else if (this.activeName == "预警消除") {
        if (this.remove == 2) {
          _this.getRecord(_this.warnUuid, 4, this.eventId);
        }
      }
    },
    CloseData() {
      this.$emit("CloseData");
    },
    hanlderViewList(obj) {
      this.$emit("showFileList", obj);
      // console.log(obj);
      // this.fileDialog = true;
    },
    handlefileClose() {
      this.fileDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
// ::v-deep .el-dialog__body {
//   height: 460px;
// }
::v-deep .el-image {
  height: 150px;
}
.i {
  position: absolute;
  top: -5px;
  right: -5px;
  color: red;
  font-weight: 600;
}
.url-list {
  position: relative;
  display: inline-block;
}
.el-dialog__header {
  padding: 0 !important;
}
.fileDialogBox {
  color: #3fa5e9;
  margin-left: 10px;
  cursor: pointer;
}
.material-container {
  min-height: 400px;
}
</style>
