import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ScreenHome from "@/views/ScreenHome.vue";
import Login from "../views/Login.vue";
import Index from "../views/HomePage/Index.vue";
import ErrorPage from "@/views/ErrorPage";
import callback from "@/views/callback/index";
import emerger from "../views/emerger/index.vue"
import proofing from "../views/proofing/index.vue"
import dangers from "../views/dangers/index.vue"



import Contingency from "./contingency.js";
import ThreeProofings from "./threeProofings.js";
import HiddenDanger from "./hiddenDanger.js";
import RiskManagement from "./riskManagement.js";
import DeviceManagent from  "./deviceManagent.js";
import SystemManagement from "./systemManagement";
import DataManagement from "./dataManagement";
import Screen from "./screen";
import Emergency  from "./Emergency.js"


// 工作台待办事项
import Agency from "@/views/SystemHome/Agency/Index.vue";
import Layout from "@/components/Layout/SystemLayout.vue";

// import { Message } from "element-ui";

Vue.use(VueRouter);
export const routes = [{
  path: "/login",
  component: Login,
  name: "login",
},
{
  path: "/callback",
  component: callback,
  name: "callback",
},
{
  path: "/emerger",
  component: emerger,
  name: "emerger",
},

{
  path: "/Screen",
  component: ScreenHome,
  children: [
    ...Screen
  ],
},
{
  path: "/proofing",
  component: proofing,
  name: "proofing"
},

{
  path: "/dangers",
  component: dangers,
  name: "dangers"
},
{
  path: "/",
  component: Home,
  children: [{
    path: "/",
    component: Index,
    name: "Index",
  },
  {
    path: "SystemHome",
    component: Layout,
    name: "SystemHome",
    children: [
      {
        path: "Agency",
        component: Agency,
        name: "Agency"
      }
    ]
  },
  ...Contingency,
  ...ThreeProofings,
  ...HiddenDanger,
  ...RiskManagement,
  ...DeviceManagent,
  ...SystemManagement,
  ...DataManagement,
  ...Emergency,
  {
    path: "403",
    component: ErrorPage,
    name: "403",
  },
  {
    path: "*",
    component: ErrorPage,
    name: "404",
  },
  ],
},

];
const router = new VueRouter({
  mode: "history",
  routes,
});
// 登录验证
router.beforeEach((to, from, next) => {
  let jwtToken = localStorage.getItem("jwtToken");
  // let yjxToken = localStorage.getItem("yjxToken");
  // let expire = localStorage.getItem("jwtTokenExpire");
  // let e = new Date().getTime() - expire;
  const notTimeOut = jwtToken;
  if (!notTimeOut) {
    console.log(to.path, to, from, next)
    if (to.path === "/Login" || to.path == "/callback" || to.path == "/emerger" || to.path == "/proofing" || to.path == "/dangers") {
      next();
    } else {
      next("/Login");
    }
    // if (from.fullPath === "/") {
    //   console.log("is_not_login");
    // } else if (e < 0) {
    //   Message.info("用户信息超时，请重新登录");
    // } else {
    //   Message.error("未登录");
    // }
  } else {
    if (to.path === "/Login" || to.path === "/") {
      next('/Screen/ThreeProofings/Index');
    } else {
      next();
    }
  }
});
export default router;
