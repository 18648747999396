<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" :title="title">
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="100px"
      class="demo-model"
    >
      <el-form-item label="线路工点" prop="lineSite">
        <el-cascader
          ref="linecas"
          placeholder="选择线路工点"
          v-model="model.lineSite"
          :options="linetree"
          :props="props"
          filterable
          clearable
          @change="getlabel"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="备注" prop="note">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 6 }"
          v-model="model.note"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
      <el-form-item label="上传图片" prop="imageUrl">
        <image-uploader
          v-model="model.imageUrl"
          :modelSrc="model.imageUrl"
          @change="urlchange($event)"
        ></image-uploader>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import ImageUploader from "@/components/Uploader/ImageUploader.vue";
import { drainagerAdd, drainagerModify } from "@/api/map";
import { lineSiteTreeCascadeEcho } from "@/utils/common";
export default {
  components: {
    systemModal,
    ImageUploader,
  },
  props: { linetree: {}, type: {} },
  data() {
    return {
      title: "新增",
      model: {
        lineSite: [],
        imageUrl: "",
        note: "",
      },
      loading: false,
      rules: {
        note: [{ required: true, message: "请输入备住", trigger: "blur" }],
        lineSite: [
          { required: true, message: "请选择线路工点", trigger: "change" },
        ],
        imageUrl: [
          { required: true, message: "请上传图片", trigger: "change" },
        ],
      },
      props: {
        value: "id",
        label: "name",
        children: "children",
      },
      lineName: [],
    };
  },
  mounted() {},
  methods: {
    handleAdd(record) {
      if (record) {
        this.title = "编辑";
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
        const treeEcho = lineSiteTreeCascadeEcho(record.siteId);
        this.model = {
          id: record.id,
          lineSite: treeEcho,
          note: record.note,
          imageUrl: record.imageUrl,
        };
        this.lineName = [record.lineName, record.siteName];
      } else {
        this.title = "新增";
        this.model = {
          lineSite: [],
          note: "",
          imageUrl: "",
        };
      }
      this.handleEdit();
    },
    handleName(e) {
      console.log(e);
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    getlabel() {
      if (this.model.lineSite.length != 0) {
        this.lineName = this.$refs["linecas"].getCheckedNodes()[0].pathLabels;
      }
      console.log(this.lineName);
    },
    urlchange(url) {
      this.model.imageUrl = url;
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let name =
            this.type == "1"
              ? "场地周边排水图"
              : this.type == "2"
              ? "防汛作战图"
              : "三防明白卡";
          if (this.title == "新增") {
            let params = {
              imageName: name,
              imageUrl: this.model.imageUrl,
              lineId: this.model.lineSite[0],
              lineName: this.lineName[0],
              note: this.model.note,
              siteId: this.model.lineSite[this.model.lineSite.length-1],
              siteName: this.lineName[this.lineName.length-1],
              type: this.type,
            };
            drainagerAdd(params).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "新增成功！",
                  type: "success",
                });
                this.$emit("getdata");
                this.$refs.formModal.handleClose();
              } else {
                this.$message.error("新增失败！");
              }
              this.loading = false;
            });
          } else {
            console.log(this.model.imageUrl);
            let params = {
              id: this.model.id,
              imageName: name,
              imageUrl: this.model.imageUrl,
              lineId: this.model.lineSite[0],
              lineName: this.lineName[0],
              note: this.model.note,
              siteId: this.model.lineSite[1],
              siteName: this.lineName[1],
              type: this.type,
              state: 2,
            };
            drainagerModify(params).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "修改成功！",
                  type: "success",
                });
                this.$emit("getdata");
                this.$refs.formModal.handleClose();
              } else {
                this.$message.error("修改失败！");
              }
              this.loading = false;
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
