<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
    >
      <el-form-item label="实际培训时间" prop="trainDate">
        <el-date-picker
          v-model="ruleForm.trainDate"
          format="yyyy-MM-dd"
          style="width: 100%"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="实际培训时间"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="参与人数" prop="joinCount">
        <el-input
          v-model.number="ruleForm.joinCount"
          placeholder="请输入参与人数"
        ></el-input>
      </el-form-item>

      <el-form-item label="总结" prop="summarisingDocuments">
        <Uploader @uploader="uploadSummart" :accept="'.pdf,image/*'"></Uploader>
      </el-form-item>

      <el-form-item label="照片" prop="pictures">
        <Uploader @uploader="pictureSummart" :accept="'image/*'"></Uploader>
      </el-form-item>

      <el-form-item label="签到表" prop="signInForms">
        <Uploader @uploader="uploadSign" :accept="'.pdf,image/*'"></Uploader>
      </el-form-item>

      <el-form-item label="其他附件" prop="label">
        <Uploader @uploader="otherSummart" :accept="'.pdf,image/*'"></Uploader>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitUpload">确 定</el-button>
      <el-button @click="cancelUpload">取 消</el-button>
    </div>
  </div>
</template>

<script>
// import Uploader from "@/views/System/TrainingDrill/components/Uploader"
import Uploader from "./Uploader"
import { trainInfoUpdate } from "@/api/stand"
export default {
  name: "trainUpload",
  components: {
    Uploader
  },
  props: {
    uploadData: {
      default: () => { }
    }
  },
  data () {
    return {
      ruleForm: {
        trainDate: '',
        joinCount: '',
        accessories: [],
        pictures: [],
        signInForms: [],
        summarisingDocuments: [],
      },
      rules: {
        joinCount: [
          { required: true, message: '参与人数不能为空', trigger: 'blur' },
          { type: 'number', message: '参与人数必须为数字值' }
        ],
        trainDate: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
        summarisingDocuments: [
          { required: true, message: '请上传总结附件', trigger: 'change' }
        ],
        pictures: [
          { required: true, message: '请上传总结图片', trigger: 'change' }
        ],
        signInForms: [
          { required: true, message: '请上传签到表', trigger: 'change' }
        ],
      }
    }
  },
  created () {

  },
  methods: {
    uploadSummart (event) {
      let params = {
        planId: this.planId,
        attrName: event.originalName,
        attrType: 1,
        link: event.uri,
        localPath: event.fullPath
      }
      this.ruleForm.summarisingDocuments.push(params)
      console.log(this.ruleForm.summarisingDocuments)
    },
    pictureSummart (event) {
      let params = {
        planId: this.planId,
        attrName: event.originalName,
        attrType: 2,
        link: event.uri,
        localPath: event.fullPath
      }
      this.ruleForm.pictures.push(params)
    },
    uploadSign (event) {
      let params = {
        planId: this.planId,
        attrName: event.originalName,
        attrType: 3,
        link: event.uri,
        localPath: event.fullPath
      }
      this.ruleForm.signInForms.push(params)
    },
    otherSummart (event) {
      let params = {
        planId: this.planId,
        attrName: event.originalName,
        attrType: 4,
        link: event.uri,
        localPath: event.fullPath
      }
      this.ruleForm.accessories.push(params)
    },
    cancelUpload () {
      this.$emit('cancelUpload')
    },
    submitUpload () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.ruleForm.joinCount < 0) {
            this.$message.error('参与人数不能小于0')
            return
          }
          if (this.ruleForm.trainDate && !this.ruleForm.trainDate.includes(' 00:00:00')) {
            this.ruleForm.trainDate = this.ruleForm.trainDate + ' 00:00:00'
          }
          let params = {
            accessories: this.ruleForm.accessories,
            pictures: this.ruleForm.pictures,
            signInForms: this.ruleForm.signInForms,
            summarisingDocuments: this.ruleForm.summarisingDocuments,
            id: this.uploadData.id,
            state: 5,
            joinCount: this.ruleForm.joinCount,
            trainDate: this.ruleForm.trainDate
          }
          trainInfoUpdate(params).then(res => {
            if (res.code == 0) {
              this.$message.success('操作成功')
              this.$emit('successUpload')
            }
          })
        } else {
          this.$message.error('请正确填写')
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
