import { render, staticRenderFns } from "./CrawlerRealtime.vue?vue&type=template&id=54ca999e&scoped=true&"
import script from "./CrawlerRealtime.vue?vue&type=script&lang=js&"
export * from "./CrawlerRealtime.vue?vue&type=script&lang=js&"
import style0 from "./CrawlerRealtime.vue?vue&type=style&index=0&id=54ca999e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54ca999e",
  null
  
)

export default component.exports