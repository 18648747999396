<template>
  <!-- 应急检查表的建管部 汇总数据   -->
  <div>
    <div style="margin-bottom: 20px">
      <el-radio-group
        size="mini"
        v-model="degree"
        v-for="item in times"
        :key="item.time"
        @change="degreeChange"
      >
        <el-radio-button :label="item.label"></el-radio-button>
      </el-radio-group>
    </div>
    <el-descriptions title="" style="width: 70%">
      <el-descriptions-item label="汇总单位">{{
        checkName
      }}</el-descriptions-item>

      <el-descriptions-item label="汇总次数"
        >第{{ cuns }}次</el-descriptions-item
      >
    </el-descriptions>

    <el-table
      ref="tableData"
      :data="tableData"
      id="tableData"
      border
      v-loading="tableLoading"
      :row-style="{ height: '30px' }"
      style="width: 100%"
      height="430"
    >
      <el-table-column
        align="center"
        type="index"
        label="序号"
        width="100"
      ></el-table-column>
      <el-table-column label="总包部" prop="name" align="center" width="380">
      </el-table-column>
      <el-table-column
        label="填报次数"
        align="center"
        prop="workGroupCount"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="checkerCount"
        label="工点完成情况"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="siteCount"
        label="截止时间"
        width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="hiddenDangerCount"
        label="填报时间"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="rectificationCount"
        label="填报人员"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="evacueesCount"
        label="状态"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="evacueesCount"
        label="审核人员"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        v-if="userDept == '4'"
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.name != '汇总'">
            <el-button
              @click="check(scope.row, tableData)"
              type="text"
              size="small"
            >
              查看
            </el-button>
            <el-button
              @click="check(scope.row, tableData)"
              type="text"
              size="small"
            >
              驳回
            </el-button>
          </span>
          <span v-else>/</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center mt-8">
      <el-button type="primary" @click="exportsubmit">导出</el-button>
    </div>

    <el-dialog
      title="查看详情"
      :visible.sync="checkDialog"
      width="1200px"
      :before-close="checkClose"
      center
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <checkPage
        ref="checkmell"
        @checkClose="checkClose"
        :checkId="checkId"
        :checkName="checkName"
        :cuns="cuns"
        :qualityResponseId="qualityResponseId"
        :tableDatas="tableDatas"
      ></checkPage>
    </el-dialog>

    <el-dialog
      title="导出"
      :visible.sync="exportDialog"
      width="400px"
      :before-close="diaLogClose"
      center
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
      v-loading="exloading"
    >
      <div>
        <el-checkbox-group v-model="checkboxTimes" size="small">
          <el-checkbox
            :label="item.label"
            v-for="item in times"
            :key="item.time"
            :disabled="exloading"
          ></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="text-center mt-8">
        <el-button type="primary" :disabled="exloading" @click="exportGo"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSelection, getRes } from "@/api/response";
import {
  listSupervisionSummaryInfo,
  listSiteSummaryInfo,
  exportSummaryInfo,
  listSiteDetailSummaryInfo,
} from "@/api/responseTem";
import checkPage from "./checkPage.vue";

export default {
  props: {
    qualityResponseId: {
      type: Number,
      default: () => null,
    },
    userDept: {
      type: String,
      default: "1",
    },
  },
  components: {
    checkPage,
  },
  data() {
    return {
      times: [],
      degree: "第1次",
      tableData: [
        {
          reportcishu: "三号线",
        },
      ],
      tableTotalData: [],
      tableLoading: false,
      checkDialog: false,
      formData: {
        emergency: "",
      },
      formInline: {
        responseType: "",
        responseLevel: "",
      },
      leveloptions: [],
      checkId: "", //当前行的id
      checkName: "", //事件id
      // buildDepartmentResponseId: "",
      supervisionName: "",
      cuns: 1,
      exportDialog: false,
      checkboxTimes: [],
      exloading: false,
      tableDatas: [],
    };
  },
  created() {},
  watch: {
    qualityResponseId: {
      handler(val) {
        if (val) {
          this.getGenre(val);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    console.log(this.linetes, "质安部查看的线路工点");
    this.getData();
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.tableLoading = true;
      let params = {
        responseId: this.qualityResponseId,
      };
      if (this.userDept == "3") {
        listSiteSummaryInfo(params).then((res) => {
          this.times = [];
          if (res.code == 0) {
            this.tableLoading = false;
            // this.tableForm.tableData = res.data;
            res.data.forEach((element) => {
              this.times.push({
                time: element.times,
                label: "第" + element.times + "次",
              });
            });
            this.tableData = res.data[0].summaryInfoList;
            this.tableTotalData = res.data;
          }
        });
      } else {
        listSupervisionSummaryInfo(params).then((res) => {
          this.times = [];
          if (res.code == 0) {
            this.tableLoading = false;
            // this.tableForm.tableData = res.data;
            res.data.forEach((element) => {
              this.times.push({
                time: element.times,
                label: "第" + element.times + "次",
              });
            });
            this.tableData = res.data[0].summaryInfoList;
            this.tableTotalData = res.data;
          }
        });
      }
    },
    getData() {
      let params = {};
      getSelection(params).then((res) => {
        if (res.code == 0 && res.data && res.data.length != 0) {
          this.leveloptions = [];
          let id1 = res.data.findIndex((item) => {
            if (item.label == "汇总") {
              return true;
            }
          });
          res.data.splice(id1, 1);
          res.data.forEach((element) => {
            this.leveloptions.push({
              label: element.label,
              value: element.value,
            });
          });
        }
      });
    },
    degreeChange(val) {
      var arr = val.replace(/\D/g, "");
      this.cuns = arr;
      this.tableData = [];
      this.tableTotalData.forEach((item) => {
        if (arr == item.times) {
          this.tableData = item.summaryInfoList;
        }
      });
    },
    getGenre(val) {
      let id = val;
      getRes(id).then((res) => {
        if (res.code == 0) {
          this.formInline.responseLevel = res.data.currentLevel.label;
          this.formInline.responseType = res.data.type.label;
        }
      });
    },
    emergencyChange(val) {
      this.tableLoading = true;
      let params = {
        manageCurrentId: val,
      };
      listSupervisionSummaryInfo(params).then((res) => {
        if (res.code == 0) {
          this.tableLoading = false;
          this.tableData = res.data[0].statisticFormDtoList;
        }
      });
    },
    exportGo() {
      console.log(this.checkboxTimes);
      if (this.checkboxTimes.length > 0) {
        this.exloading = true;
        this.checkboxTimes.forEach((el) => {
          this.derive(el);
        });
      } else {
        this.$message.warning("请至少选择一个提交次数");
      }
    },
    derive(data) {
      let parms = {
        times: data.replace(/\D/g, ""),
        responseId: this.qualityResponseId,
      };
      exportSummaryInfo(parms).then((res) => {
        console.log(res);
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel",
        });
        // 3.创建一个临时的url指向blob对象
        let url = window.URL.createObjectURL(blob);
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "三防工作简报" + data + ".xlsx"; //自定义导出文件名
        a.click();
        this.exloading = false;
      });
    },
    check(row, tabdata) {
      console.log(row, tabdata, "查看详情");
      this.checkId = row.supervisionUuid;
      this.checkName = row.name;
      let params = {
        supervisionName: this.checkName,
        supervisionUuid: this.checkId,
        times: this.cuns,
        responseId: this.qualityResponseId,
      };
      listSiteDetailSummaryInfo(params).then((res) => {
        if (res.code == 0) {
          this.tableLoading = false;
          this.tableDatas = res.data.summaryInfoList;
          console.log(this.tableDatas);
          this.checkDialog = true;
        }
      });

      // this.$refs.checkmell.getdata();
    },
    checkClose() {
      this.checkDialog = false;
    },
    exportsubmit() {
      this.exportDialog = true;
    },
    diaLogClose() {
      this.exportDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-table::before {
  height: 0;
}
::v-deep .el-table {
  height: 400px;
  // overflow-y: auto;
}
.content {
  display: flex;
  justify-content: space-between;
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: #fff !important;
  border-radius: 7px;
}
/*定义滑块颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}

//去掉固定列下方的横线
::v-deep .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: transparent;
}
//去掉固定列阴影
::v-deep .el-table__fixed-right {
  // box-shadow: none;
  height: 100% !important;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}
</style>
