var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"material-container h-full"},[_c('el-form',{ref:"formData",attrs:{"size":"medium","rules":_vm.rules,"model":_vm.formData,"loading":_vm.loading,"label-width":"90px"}},[_c('el-form-item',{attrs:{"label":"隐患类别:","prop":"parentCategoryName"}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入隐患类别","disabled":_vm.submitType == 'addSon' ||
            _vm.submitType == 'view' ||
            (_vm.submitType == 'edit' && _vm.pageData.categoryType == '1')},model:{value:(_vm.formData.parentCategoryName),callback:function ($$v) {_vm.$set(_vm.formData, "parentCategoryName", $$v)},expression:"formData.parentCategoryName"}})],1),_c('el-form-item',{attrs:{"label":"排查类型:","prop":"screeningType"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"clearable":"","placeholder":"请选择排查类型","disabled":_vm.submitType == 'addSon' ||
            _vm.submitType == 'view' ||
            (_vm.submitType == 'edit' && _vm.pageData.categoryType == '1')},model:{value:(_vm.formData.screeningType),callback:function ($$v) {_vm.$set(_vm.formData, "screeningType", $$v)},expression:"formData.screeningType"}},_vm._l((_vm.checkType),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),(
        (_vm.submitType == 'addSon' && _vm.pageData.categoryType == '0') ||
          (_vm.submitType == 'view' && _vm.pageData.categoryType == '1') ||
          (_vm.submitType == 'edit' && _vm.pageData.categoryType == '1')
      )?_c('el-form-item',{attrs:{"label":"隐患子类:","prop":"categoryName"}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入隐患子类","disabled":_vm.submitType == 'view'},model:{value:(_vm.formData.categoryName),callback:function ($$v) {_vm.$set(_vm.formData, "categoryName", $$v)},expression:"formData.categoryName"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"关联工法:"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择关联工法","disabled":_vm.submitType == 'view'},model:{value:(_vm.formData.region),callback:function ($$v) {_vm.$set(_vm.formData, "region", $$v)},expression:"formData.region"}})],1),_c('el-form-item',{attrs:{"label":"分部工程:"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择分部工程","disabled":_vm.submitType == 'view'},model:{value:(_vm.formData.region),callback:function ($$v) {_vm.$set(_vm.formData, "region", $$v)},expression:"formData.region"}})],1),_c('el-form-item',{attrs:{"label":"排序:"}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入","disabled":_vm.submitType == 'view'},model:{value:(_vm.formData.sort),callback:function ($$v) {_vm.$set(_vm.formData, "sort", _vm._n($$v))},expression:"formData.sort"}})],1)],1),_c('div',{staticClass:"dialogFooter"},[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.submitType !== 'view'),expression:"submitType !== 'view'"}],attrs:{"size":"medium"},on:{"click":_vm.cancel}},[_vm._v("取 消")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.submitType !== 'view'),expression:"submitType !== 'view'"}],attrs:{"size":"medium","type":"primary"},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }