<template>
  <systemModal ref="systemModal" @modalConfirm="handleSubmit" :title="title" :showFooter="false">
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="100px"
      class="demo-model"
    >
      <el-form-item label="线路-工点" prop="lineSite">
        {{ model.lineName }}-{{ model.siteName }}
      </el-form-item>
      <el-form-item label="检查单位" prop="unitId">
        {{ model.unitName }}
      </el-form-item>
      <el-form-item label="检查人员" prop="video">
        <ul>
          <li v-for="item of model.safeCheckStaff" :key="item.staffId">{{ item.staffName }}</li>
        </ul>
      </el-form-item>
      <el-form-item label="检查日期" prop="video">
        {{ model.checkDate }}
      </el-form-item>
      <el-form-item label="状态" prop="state">
        {{ model.state | stateLabel }}
      </el-form-item>
      <el-form-item label="附件" prop="video">
        <el-button type="text" @click="handleFilePreview(model.imageUrl)">{{ model.imageName }}</el-button>
      </el-form-item>
    </el-form>
    <file-preview ref="filePreview" :appendToBody="true" :src="fileUrl"></file-preview>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { querySafeCheckDetail } from "@/api/map";
import { queryOrg } from "@/api/org";
import filePreview from "@/components/Uploader/filePreview.vue";
export default {
  components: {
    systemModal,
    filePreview
  },
  props: {
    lineSiteData: {
      typeof: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      title: "详情",
      model: {},
      unitOptions: [],
      loading: false,
      rules: {},
      fileUrl: ""
    };
  },
  filters: {
    stateLabel(val) {
      switch (val) {
        case 1:
          return "审核中";
        case 2:
          return "审核未通过";
        case 3:
          return "审核已通过";
      }
    }
  },
  mounted() {
    this.queryOrg();
  },
  methods: {
    handleDetail(record) {
      this.$refs.systemModal.handleOpen();
      this.getInfo(record.id);
    },
    getInfo(id) {
      // 请求详情接口
      this.loading = true;
      querySafeCheckDetail(id).then(res => {
        if (res.code == 0) {
          this.model = Object.assign({}, res.data);
          if (this.unitOptions.length > 0) {
            const tempObj = this.unitOptions.find(item => item.id == this.model.unitId);
            this.$set(this.model, "unitName", tempObj.companyName)
          }
          this.loading = false;
        }
      });
    },
    queryOrg() {
      queryOrg().then(res => {
        if (res.code == 0) {
          this.unitOptions = res.data;
        }
      })
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          // 验证通过
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
    handleFilePreview(url) {
      this.fileUrl = url;
      this.$refs.filePreview.handleOpen();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
