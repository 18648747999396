<template>
  <div class="min-h-full bg-white">
    <div class="flex justify-between p-4">
      <el-row class="flex">
        <el-select
          class="mr-2"
          v-model="queryParams.equipmentType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in equType"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          class="mr-2"
          v-model="queryParams.name"
          clearable
          placeholder="规则名称"
          style="width: 120px"
        ></el-input>
        <el-button type="primary" @click="getTableData">搜索</el-button>
      </el-row>

      <el-row>
        <el-button type="primary" @click="handleAdd">新增</el-button>
      </el-row>
    </div>

    <div class="px-4">
      <el-table
        id="el-table"
        ref="qtable"
        :data="tableData"
        size="mini"
        border
        v-loading="loading"
      >
        <el-table-column
          type="index"
          width="55"
          header-align="center"
          align="center"
          label="序号"
        ></el-table-column>
        <el-table-column
          prop="unitType"
          align="center"
          label="设备型号"
        ></el-table-column>
        <el-table-column
          prop="ruleName"
          align="center"
          label="规则名称"
        ></el-table-column>
        <el-table-column
          prop="ruleNum"
          header-align="center"
          align="center"
          label="预警类型数量"
        ></el-table-column>
        <el-table-column
          prop="ruleDesc"
          header-align="center"
          align="center"
          label="规则说明"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          header-align="center"
          align="center"
          label="创建时间"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-button
                type="text"
                size="small"
                @click="handleEdit(scope.row)"
                v-has="'role:assign'"
                class="mr-4"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="handleDelete(scope.row)"
                v-has="'role:del'"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <GantryCraneRuleDialog
      :title="dialogTitle"
      :dialogVisible="dialogVisible"
      @closeMainDialog="closeMainDialog"
      :tableData="formLabelList"
      :ruleForm="ruleForm"
    ></GantryCraneRuleDialog>
  </div>
</template>

<script>
import { towerWarnRulesList } from "@/api/deviceManagement";
import GantryCraneRuleDialog from "./components/GantryCraneRuleDialog";
export default {
  components: {
    GantryCraneRuleDialog
  },
  watch: {
    tableTreeData() {
      this.doLayout();
    }
  },
  data() {
    return {
      equType: [],
      queryParams: {
        id: "0",
        name: "",
        equipmentType: ""
      },
      tableData: [
        {
          unitType: "TDJC-01",
          ruleName: "塔吊规则1",
          ruleNum: 11,
          ruleDesc: "根据国标预制的规则",
          createTime: "2023-08-12"
        },
        {
          unitType: "TDJC-02",
          ruleName: "塔吊规则2",
          ruleNum: 12,
          ruleDesc: "根据国标预制的规则",
          createTime: "2023-08-12"
        },
        {
          unitType: "TDJC-03",
          ruleName: "塔吊规则3",
          ruleNum: 13,
          ruleDesc: "根据国标预制的规则",
          createTime: "2023-08-12"
        }
      ],
      loading: false,
      title: "",
      dialogVisible: false,
      dialogTitle: "",
      formLabelList: []
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      towerWarnRulesList(this.queryParams).then(res => {
        this.formLabelList = res.data[0].nodes;
      });
    },
    handleAdd() {
      this.reset();
      this.dialogTitle = "新增预警规则";
      this.dialogVisible = true;
    },
    handleEdit(row) {
      this.reset();
      this.ruleForm = { ...row };
      this.dialogTitle = "编辑预警规则";
      this.dialogVisible = true;
    },
    // 删除
    handleDelete(data) {
      console.log(data);
    },
    closeMainDialog() {
      this.dialogVisible = false;
    },
    reset() {
      // mm
    }
  }
};
</script>

<style lang="scss" scoped>
// s
</style>
