<template>
  <div>
    <div class="callVideo">
      <div class="callusername" v-show="callload">
        {{ toname }}<br />正在呼叫...
      </div>
      <video ref="callVideotabus" autoplay style="max-width: 100%"></video>
      <video ref="callVideotab" autoplay style="max-width: 100%"></video>
      <video ref="callVideotab1" autoplay style="max-width: 100%"></video>
      <video ref="callVideotab2" autoplay style="max-width: 100%"></video>
      <div class="flex justify-around callfooter" style="width: 100%">
        <img
          class="cursor-pointer"
          v-show="usercall"
          src="@assets/im/jt.png"
          @click="voipAcceptCall"
        />
        <img
          class="cursor-pointer"
          v-show="usercall"
          src="@assets/im/gd.png"
          @click="exitVoipFunc"
        />
        <img
          class="cursor-pointer"
          v-show="!usercall"
          src="@assets/im/gd.png"
          @click="stop(false)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import kurentoUtils from "kurento-utils";
const NO_CALL = 0;
const PROCESSING_CALL = 1;
const IN_CALL = 2;
var from;
import { addRoom } from "@/api/rtc.js";
export default {
  name: "rtc",
  props: {
    dataList: {},
    owner: {},
  },
  data() {
    return {
      CallDialog: false,
      ws: null,
      setMsgData: "",
      userId: JSON.parse(localStorage.user).id,
      userName: JSON.parse(localStorage.user).realName,
      // callUserName: "一个设备",
      callload: true,
      usercall: true,
      callId: "",
      fromId: 1,
      toname: "用户",
      toId: "",
      registerState: null,
      callState: 0,
      videoInput: null,
      videoOutput: null,
      webRtcPeer: null,
      remoteRtcPeer: [],
      incalldata: null,
      inVideo: [],
      offerSdp: null,
      roomOwner: null,
    };
  },
  computed: {},
  mounted() {
    // this.userId = this.$route.query.userId; // 测试使用ddd
    console.log("链接", this.userId);
    this.initStc();
  },
  methods: {
    //初始化
    initStc() {
      let that = this;
      that.ws = new WebSocket(
        "ws://47.95.238.93:9901/rtc?userId=" + this.userId
      );
      // 监听socket连接
      that.ws.onopen = that.open;
      // 监听socket错误信息
      that.ws.onerror = that.error;
      // 监听socket消息
      that.ws.onmessage = that.getMessage;
      // 关闭连接
      that.ws.onclose = that.close;

      // this.userId = this.owner.id;
    },
    open() {
      console.log("socket初始化成功");
      this.register();
    },
    error() {
      console.log("连接错误");
    },

    close() {
      console.log("socket已经关闭");
    },
    // 单sendWs人
    getMessage(message) {
      let that = this;
      var parsedMessage = JSON.parse(message.data);

      switch (parsedMessage.id) {
        case "registerResponse":
          that.registerResponse(parsedMessage);
          break;
        case "callResponse":
          that.callResponse(parsedMessage);
          break;
        case "incomingCall":
          that.incomingCall(parsedMessage);
          break;
        case "startCommunication":
          that.startCommunication(parsedMessage);
          break;
        case "stopCommunication":
          console.info("通讯结束");
          that.stop(true);
          break;
        case "userStartVideo":
          that.userStartVideo(parsedMessage.name);
          break; //用户开始视频
        case "incomingMeetingCall":
          that.incomingMeetingCall(parsedMessage);
          break;
        case "iceCandidate":
          that.callload = false;
          that.webRtcPeer.addIceCandidate(
            parsedMessage.candidate,
            function (error) {
              if (error)
                return console.error("Error adding candidate: " + error);
            }
          );
          break;
        default:
          console.error("Unrecognized message", parsedMessage);
      }
    },

    //收到呼叫
    incomingMeetingCall(message) {
      console.log("状态", this.callState);
      console.log("收到呼叫", message);

      if (this.callState != NO_CALL) {
        var response = {
          id: "incomingCallResponse",
          from: message.from,
          callResponse: "reject",
          message: "bussy",
          type: "voip",
        };
        return this.sendSoloMessage(response);
      }

      this.setCallState(PROCESSING_CALL);
      this.$emit("openVoip");
      this.CallDialog = true;

      this.usercall = true;
      this.toname = message.from;
      this.roomOwner = message.form;
      console.log("toname是form吗", message.from);
      this.incalldata = message;
      this.videoInput = this.$refs.callVideotabus;
      this.videoOutput = this.$refs.callVideotab;
      console.log(this.incalldata);
    },
    call() {
      let parms = {
        // 会话成员ID集合，不包括创建人。至少需要1个成员
        joiners: this.dataList,
        // 创建人信息
        owner: this.owner,
        vendorId: 1, //	归属租户企业ID
      };
      // 创建聊天室
      addRoom(parms).then((res) => {
        if (res.code == 0) {
          //聊天室创建成功  ws发送给被拨打人的消息
          this.roomID = res.data.id;
          this.roomOwner = this.userId;
          this.sendWs(this.roomID, this.userId);
        } else {
          this.$message.warning("您已在会议里，不可重复创建会议!");
        }
      });
    },
    sendWs(roomID, sendId) {
      this.roomID = roomID;
      let meetingRoomPath =
        "ws://47.95.238.93:9901/rtc/meeting?userId=" +
        sendId +
        "&roomId=" +
        roomID;

      // 实例化socket
      this.socketWs = new WebSocket(meetingRoomPath);
      console.log("sockets", this.socketWs);
      // 监听socket连接
      this.socketWs.onopen = this.meetingopenWs;
      // 监听socket错误信息
      this.socketWs.onerror = this.meetingerrorWs;
      // 关闭socket
      this.socketWs.onclose = this.meetingcloseWs;
      // 监听socket消息
      this.socketWs.onmessage = this.meetinggetMessageWs;
    },
    meetingopenWs() {
      this.callVideo();
    },
    meetingerrorWs(message) {
      console.log("连接错误", message);
    },
    // 多人
    meetinggetMessageWs(message) {
      let that = this;
      var parsedMessage = JSON.parse(message.data);
      let name = parsedMessage.name;
      switch (parsedMessage.id) {
        case "registerResponse":
          that.registerResponse(parsedMessage);
          break;
        // case "receiveVideoAnswer":
        //   that.callResponse(parsedMessage);
        //   break;
        case "newParticipantArrived":
          that.newParticipantArrived(parsedMessage);
          break;
        case "incomingCall":
          that.incomingCall(parsedMessage);
          break;
        case "startCommunication":
          that.startCommunication(parsedMessage);
          break;
        case "stopCommunication":
          that.stop(true);
          break;
        case "receiveVideoAnswer":
          that.receiveVideoAnswer(parsedMessage);
          break;
        case "userStartVideo":
          that.userStartVideo(parsedMessage.name);
          break; //用户开始视频
        case "iceCandidate":
          console.log("收到:iceCandidate:", parsedMessage.name);
          that.callload = false;
          // console.log("收到:iceCandidate:", parsedMessage.candidate);

          if (name == this.userId) {
            this.webRtcPeer.addIceCandidate(parsedMessage.candidate, error => {
              if (error)
                return console.error("Error adding candidate: " + error);
              console.log("添加成功:iceCandidate:", name);
            });
          } else {
            console.log("收到:iceCandidate:", name);
            
            if (that.remoteRtcPeer[name]) {
              that.remoteRtcPeer[name].addIceCandidate(
                parsedMessage.candidate,
                error => {
                  if (error)
                    return console.error("Error adding candidate: " + error);
                  console.log("添加成功:iceCandidate:", name,parsedMessage.candidate);
                }
              );
            } else {
              console.log("没有找到相应的端点", name);
            }
          }
          break;
        default:
          console.error("Unrecognized message", parsedMessage);
      }
    },
    meetingcloseWs() {
      console.log("多人会议已结束");
    },
    joinRoom() {
      var messageJoinRoom = {
        id: "joinRoom",
        sender: this.owner.id,
        name: this.owner.id,
        room: this.roomID,
      };
      this.sendMessage(messageJoinRoom);
    },
    //登录后注册方法
    register() {
      var message = {
        id: "register",
        name: this.userId,
        type: "voip",
      };
      this.sendSoloMessage(message);
    },
    //发起视频通话
    callVideo() {
      let that = this;
      that.toId = that.userId;
      that.toname = that.userName;
      // that.callUserName = that.toname;
      that.usercall = false;
      that.CallDialog = true;
      that.callload = true;
      that.videoInput = that.$refs.callVideotabus;
      that.videoOutput = that.$refs.callVideotab;
      this.setCallState(PROCESSING_CALL);
      this.joinRoom();
      setTimeout(() => {
        var iceservers = {
          iceServers: [
            {
              urls: "stun:47.95.238.93:3478"
            },
            {
              urls: ["turn:47.95.238.93:3478"],
              username: "kurento",
              credential: "kurento",
            },
          ],
        };
        var options = {
          localVideo: that.videoInput,
          // remoteVideo: that.videoOutput,
          onicecandidate: that.onIceCandidate,
          onerror: that.onError,
          configuration: iceservers,
        };
        that.webRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(
          options,
          function (error) {
            if (error) {
              return console.error(error);
            }
            this.generateOffer(that.startVideo);
          }
        );
      }, 500);
      let userId = that.roomOwner;
      if (userId != that.userId && !that.remoteRtcPeer[userId]) {
        console.log("创建远程端点显示主持人");
        var iceservers = {
          iceServers: [
            {
              urls: "stun:47.95.238.93:3478"
            },
            {
              urls: ["turn:47.95.238.93:3478"],
              username: "kurento",
              credential: "kurento",
            },
          ],
        };
        var options = {
          remoteVideo: that.videoOutput,
          onicecandidate: function(candidate) {
            console.log("onIceCandidate for " + userId, candidate);
            console.log(candidate);
            var message = {
              id: "onIceCandidate",
              candidate: candidate,
              type: "voip",
              name: userId,
            };
            that.sendMessage(message);
          },
          onerror: that.onError,
          configuration: iceservers,
        };
        that.remoteRtcPeer[
          userId
        ] = new kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, function(
          error
        ) {
          if (error) {
            return console.error(error);
          }
          this.generateOffer((error, offerSdp) => {
            if (error) return console.error("Error generating the offer");
            var message = {
              id: "startReceiveVideo",
              sender: that.userId,
              name: userId,
              room: that.roomID,
              sdpOffer: offerSdp
            };
            that.sendMessage(message);
          });
        });
      }
    },
    newParticipantArrived(message) {
      console.log("newParticipantArrived", message);
      let userId = message.name;
      let that = this;
      var iceservers = {
        iceServers: [
          {
            urls: "stun:47.95.238.93:3478",
          },
          {
            urls: ["turn:47.95.238.93:3478"],
            username: "kurento",
            credential: "kurento",
          },
        ],
      };
      if (userId != that.userId && !that.remoteRtcPeer[userId]) {
        console.log("newParticipantArrived", message);
        var options = {
          remoteVideo: that.videoOutput,
          onicecandidate: function(candidate) {
            console.log("onIceCandidate for " + userId, candidate);
            console.log(candidate);
            var message = {
              id: "onIceCandidate",
              candidate: candidate,
              type: "voip",
              name: userId,
            };
            that.sendMessage(message);
          },
          onerror: that.onError,
          configuration: iceservers,
        };
        that.remoteRtcPeer[
          userId
        ] = new kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, function(
          error
        ) {
          if (error) {
            return console.error(error);
          }
          this.generateOffer((error, offerSdp) => {
            if (error) return console.error("Error generating the offer");
            var message = {
              id: "startReceiveVideo",
              sender: that.userId,
              name: userId,
              room: that.roomID,
              sdpOffer: offerSdp
            };
            that.sendMessage(message);
          });
        });
      }
    },
    // 开始发送本地视频
    startVideo(error, offerSdp) {
      if (error) return console.error("Error generating the offer");
      this.offerSdp = offerSdp;
      var message = {
        id: "startVideo",
        sender: this.owner.id,
        name: this.owner.id,
        room: this.roomID,
        sdpOffer: offerSdp,
      };
      this.sendMessage(message);
    },
    // 接收其他用户视频
    userStartVideo(name) {
      // console.log("接收其他用户视频", name);
      // let that = this;
      // that.callload = false;
      // let msg = {
      //   id: "receiveVideoFrom",
      //   sender: this.owner.id,
      //   require: name,
      // };
      // this.sendMessage(msg);
    },
    registerResponse(message) {
      if (message.response == "accepted") {
        console.log("");
      } else {
        var errorMessage = message.message
          ? message.message
          : "Unknown reason for register rejection.";
        console.log(errorMessage);
      }
    },
    callResponse(message) {
      console.log("信息", message);
      if (message.response != "accepted") {
        this.$message(message.message);
        this.stop();
      } else {
        this.$message("对方网络不好或占线中！");
        this.setCallState(IN_CALL);
        this.webRtcPeer.processAnswer(message.sdpAnswer, function (error) {
          if (error) return console.error(error);
        });
      }
    },

    receiveVideoAnswer(message) {
      console.log("receiveVideoAnswer");
      console.log("接收到视频", message);
      this.setCallState(IN_CALL);

      let user = message.name;
      let that = this;

      if (this.inVideo[user]) {
        console.log("已经存在");
        return;
      }
      this.inVideo[user] = true;
      if (this.remoteRtcPeer[user]) {
        this.remoteRtcPeer[user].processAnswer(message.sdpAnswer, error => {
          if (error) return console.error(error);
          console.log("接收到视频 处理成功,用户", user);
          that.arkAnswerSuccess(user);
        });
      } else {
        this.webRtcPeer.processAnswer(message.sdpAnswer, function(error) {
          if (error) return console.error(error);
          console.log("接收到视频 处理成功");
          // that.sendVideo();
          that.arkAnswerSuccess(user);
        });
      }
    },
    arkAnswerSuccess(user) {
      let msg = {
        id: "arkAnswerSuccess",
        room: this.roomID,
        sender: this.owner.id,
        require: user,
      };
      this.sendMessage(msg);
    },
    startCommunication(message) {
      this.setCallState(IN_CALL);
      this.webRtcPeer.processAnswer(message.sdpAnswer, function(error) {
        if (error) return console.error(error);
      });
    },
    setCallState(nextState) {
      switch (nextState) {
        case NO_CALL:
          console.log("空闲中");
          break;
        case PROCESSING_CALL:
          console.log("呼叫中");
          break;
        case IN_CALL:
          console.log("通话中");
          break;
        default:
          return;
      }
      this.callState = nextState;
    },

    stop(message) {
      this.setCallState(NO_CALL);
      if (this.webRtcPeer) {
        // 关闭摄像头
        this.webRtcPeer.dispose();
        this.webRtcPeer = null;

        if (!message) {
          message = {
            id: "stop",
            type: "voip",
          };
          this.sendMessage(message);
          this.sendSoloMessage(message);
        }
      }

      this.hideSpinner(this.videoInput, this.videoOutput);
      this.callload = true;
      this.$emit("endVoip");
    },
    // 多人
    sendMessage(message) {
      console.log("发送多人消息", message);
      var jsonMessages = JSON.stringify(message);
      if (this.socketWs) {
        this.socketWs.send(jsonMessages);
      } else {
        this.$message("网络异常，请稍后再试");
      }
    },
    // 单人
    sendSoloMessage(message) {
      var jsonMessage = JSON.stringify(message);
      this.ws.send(jsonMessage);
    },
    onIceCandidate(candidate) {
      console.log("onIceCandidate", candidate);
      console.log(candidate);
      var message = {
        id: "onIceCandidate",
        candidate: candidate,
        type: "voip",
        name: this.userId,
      };
      this.sendMessage(message);
    },
    onError() {
      this.setCallState(NO_CALL);
    },
    hideSpinner() {
      this.videoInput.src = "";
      this.videoOutput.src = "";
    },
    //voip 同意接受对方呼叫
    voipAcceptCall() {
      console.log("同意");
      let that = this;
      that.callload = false;
      let message = that.incalldata;
      console.log("message", message);
      let from = message.form;
      let roomId = message.room;
      console.log("roomId", roomId);

      // this.inVideo[from] = true;
      this.sendWs(roomId, this.owner.id);
      that.videoInput = that.$refs.callVideotabus;
      that.videoOutput = that.$refs.callVideotab;
      var iceservers = {
        iceServers: [
          {
            urls: "stun:47.95.238.93:3478",
          },
          {
            urls: ["turn:47.95.238.93:3478"],
            username: "kurento",
            credential: "kurento",
          },
        ],
      };
      var options = {
        localVideo: that.videoInput,
        // remoteVideo: that.videoOutput,
        onicecandidate: that.onIceCandidate,
        onerror: that.onError,
        configuration: iceservers,
      };
      that.webRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(
        options,
        async function(error) {
          if (error) {
            return console.error(error);
          }
          await this.generateOffer(that.startVideo);
        }
      );
    },
    onOfferIncomingCall(error, offerSdp) {
      if (error) return console.error("Error generating the offer");
      var response = {
        id: "incomingCallResponse",
        from: from,
        callResponse: "accept",
        sdpOffer: offerSdp,
        type: "voip",
      };
      // this.sendWs(roomID,userId)
      // let meetingRoomPath =
      //   "ws://47.95.238.93:9901/rtc/meeting?userId=" +
      //   this.owner.id +
      //   "&roomId=" +
      //   data.id;

      // // 实例化socket
      // this.socketWs = new WebSocket(meetingRoomPath);
      // // 监听socket连接
      // this.socketWs.onopen = this.meetingopenWs;
      // // 监听socket错误信息
      // this.socketWs.onerror = this.meetingerrorWs;
      // // 关闭socket
      // this.socketWs.onclose = this.meetingcloseWs;
      // // 监听socket消息
      // this.socketWs.onmessage = this.meetinggetMessageWs;
      this.sendMessage(response);
    },
    //退出或者挂断
    exitVoipFunc() {
      let message = this.incalldata;
      var response = {
        id: "incomingCallResponse",
        from: message.from,
        callResponse: "reject",
        message: "user declined",
        type: "voip",
      };
      this.sendMessage(response);
      this.sendSoloMessage(response);
      this.stop();
    },
  },
};
</script>

<style lang="scss" scoped>
.callVideo {
  width: 400px;
  height: 600px;
  position: fixed;
  left: 500px;
  top: 100px;
  background: rgba(0, 0, 0, 1);
  z-index: 100111111111111;
}
.callusername {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 200px;
  left: 0px;
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
}
.callfooter {
  position: absolute;
  bottom: 20px;
  left: 0px;
}
</style>
