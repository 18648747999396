<template>
  <systemModal ref="formModal" :title="title" :showFooter="false" width="40%" @modalClose="handleClose">
    <el-form v-loading="loading" :model="model" ref="modelForm" label-width="200px" class="demo-model" style="width: 80%">
      <el-form-item label="报警来源" prop="lineSite">
        <el-input
          v-model="model.alarmTypeName"
          readonly
          placeholder="报警来源"
        ></el-input>
      </el-form-item>
      <el-form-item label="地理位置" prop="xycoors">
        <el-input
          v-model="model.xycoors"
          readonly
          placeholder="地理位置"
        ></el-input>
      </el-form-item>
      <el-form-item label="报警时间" prop="created">
        <el-input
          v-model="model.created"
          readonly
          placeholder="报警时间"
        ></el-input>
      </el-form-item>
      <el-form-item label="上报人" prop="uploadName" v-if="type == 1017">
        <el-input
          v-model="model.uploadName"
          readonly
          placeholder="上报人"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="phoneNumber" v-if="type == 1017">
        <el-input
          v-model="model.phoneNumber"
          readonly
          placeholder="联系方式"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="footer w-full flex justify-center">
      <el-button type="primary" @click="onPublishMsg">信息发布</el-button>
      <el-button @click="onEventEnd">事件结束</el-button>
    </div>
    <PublishMsg ref="publishMsgModal" @close="handlePublishMsgModalClose"></PublishMsg>
    <EventEnd ref="eventEndModal"></EventEnd>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import PublishMsg from "./publishMsg.vue";
import EventEnd from "./eventEnd.vue";
import { getSiteAlarmInfoById } from "@/api/response";
export default {
  components: {
    systemModal,
    PublishMsg,
    EventEnd
  },
  data() {
    return {
      title: "一键报警",
      model: {
        siteName: "",
        level: "",
        reason: "",
        date: "",
        handlar: "",
      },
      type: "",
      dict: {},
      loading: false
    };
  },
  filters: {},
  mounted() {},
  methods: {
    handleOpen(record) {
      this.type = record.noticeCode;
      this.getInfo(record.eventId);
      this.$refs.formModal.handleOpen();
    },
    async getInfo(id) {
      const params = {
        id
      };
      const res = await getSiteAlarmInfoById(params);
      if (res.code == 0) {
        this.model = res.data;
      }
    },
    // 消息发布
    onPublishMsg() {
      this.$refs.publishMsgModal.handlePublishMsg(this.model);
    },
    // 结束事件
    onEventEnd() {
      this.$refs.eventEndModal.handleEventEnd(this.model);
    },
    handlePublishMsgModalClose() {},
    getDictData(label, target) {
      queryDictByLabel({ label }).then(res => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then(res => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          })
        }
      });
    },
    handleClose() {
      this.$emit("close");
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 30px !important;
}
::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}
::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
</style>
