<template>
  <systemModal
    ref="LogForm"
    :width="width"
    @modalConfirm="handleSubmit"
    :title="title"
  >
    <div>
      <div class="leading-6 mb-6" v-for="(data, index) in model" :key="index">
        <span>{{ data.created }} </span><br />
        <span>【{{ data.userName }}】{{ stateList[data.state] }} </span><br />
        <span> 备注：{{ data.note }} </span><br />
      </div>
    </div>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "历史操作",
      width: "400px",
      model: [],
      remet: "",
      loading: false,
      stateList: ["更新", "上传", "审核通过", "审核不通过", "删除"],
    };
  },
  mounted() {},
  methods: {
    handleAdd(data) {
      this.model = data;
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.LogForm.handleOpen();
    },
    handleSubmit() {
      this.$refs.LogForm.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
