<template>
  <div class="material-container h-full">
    <el-form
      ref="formData"
      :rules="rules"
      :model="formData"
      :loading="loading"
      label-width="120px"
      :disabled="submitType == 'view'"
    >
      <el-form-item label="风险控制等级" prop="riskLevelCode">
        <el-select
          v-model="formData.riskLevelCode"
          clearable
          placeholder="请选择风险控制等级"
          style="width: 100%"
        >
          <el-option
            v-for="item of gradeList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="预警级别" prop="warnGradeCode">
        <el-select
          v-model="formData.warnGradeCode"
          clearable
          placeholder="请选择预警级别"
          style="width: 100%"
        >
          <el-option
            v-for="item in cycleList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="预警响应单位:" prop="companyTypeCode">
        <el-select
          v-model="formData.companyTypeCode"
          style="width: 100%"
          placeholder="请选择预警响应单位"
          @change="unitTypeChange"
        >
          <el-option
            v-for="item of unitTypeData"
            :key="item.code"
            :label="item.intro"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="预警响应角色:" prop="responseRoleId">
        <el-select
          v-model="formData.responseRoleId"
          clearable
          style="width: 100%"
          placeholder="请选择预警响应角色"
        >
          <el-option
            v-for="item of rolesData"
            :key="item.id"
            :label="item.roleName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="响应时限" prop="responseDeadline">
        <el-input
          v-model="formData.responseDeadline"
          clearable
          placeholder="请输入数字"
          type="number"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialogFooter">
      <el-button size="medium" @click="cancel" v-show="submitType !== 'view'"
        >取 消</el-button
      >
      <el-button
        size="medium"
        type="primary"
        @click="submit"
        v-show="submitType !== 'view'"
        >确 定</el-button
      >
    </div>
  </div>
</template>

<script>
// 字典
import { getDictData } from "@/api/dict";
// 查询角色列表
import { getRoleApi } from "@/api/role";
// 新增,编辑
import { warnAdd, warnEdit } from "@/api/risk";

export default {
  props: {
    pageData: {
      type: Object,
      default: () => {},
    },
    submitType: {
      type: String,
    },
  },
  data() {
    return {
      formData: {
        companyTypeCode: "",
        responseRoleId: "",
        riskLevelCode: "",
        responseDeadline: "",
        warnGradeCode: "",
      },
      // 单位类型
      unitTypeData: [],
      // 风险控制等级
      gradeList: [],
      cycleList: [],
      // 角色
      rolesData: [],
      loading: false,
      rules: {
        companyTypeCode: [
          { required: true, message: "请选择单位", trigger: "change" },
        ],
        responseRoleId: [{ required: true, message: "请选择角色", trigger: "change" }],
        riskLevelCode: [
          {
            required: true,
            message: "请选择隐风险控制等级",
            trigger: "change",
          },
        ],
        warnGradeCode: [
          {
            required: true,
            message: "请选择预警级别",
            trigger: "change",
          },
        ],
        responseDeadline: [
          {
            required: true,
            message: "请输入时限",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    "pageData.id": {
      handler(val) {
        if (val) {
          this.getData();
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
  },
  created() {},
  mounted() {
    this.getDict();
  },
  methods: {
    getData() {
      // this.formData.responseRoleId = this.pageData.roleName;
      this.formData = { ...this.pageData };
      this.formData.responseRoleId = Number(this.pageData.responseRoleId);
      this.rolesData = [];
      getRoleApi({ unitType: this.pageData.companyTypeCode })
        .then((res) => {
          if (res.code == 0) {
            this.rolesData = res.data;
          }
        })
        .catch((res) => {
          console.log(res.messafe);
        });
    },
    getDict() {
      //发布单位/响应单位,整改单位,等级
      const demo = ["unitType", "riskControlLevel", "warnLevel"];
      demo.forEach((item) => {
        this.getDictList(item);
      });
    },
    // 字典  unitType 单位类型
    getDictList(val) {
      getDictData(val).then((res) => {
        if (res.code == 0) {
          switch (val) {
            case "unitType":
              this.unitTypeData = res.data;
              break;
            case "riskControlLevel":
              this.gradeList = res.data;
              break;
            case "warnLevel":
              this.cycleList = res.data;
              console.log(res.data);
              break;
          }
        }
      });
    },
    unitTypeChange(val) {
      this.formData.responseRoleId = "";
      this.rolesData = [];
      getRoleApi({ unitType: val })
        .then((res) => {
          if (res.code == 0) {
            this.rolesData = res.data;
          }
        })
        .catch((res) => {
          console.log(res.messafe);
        });
    },
    submit() {
      this.$refs.formData.validate((valid) => {
        console.log(this.submitType);
        if (valid) {
          this.loading = true;
          let copyForm = Object.assign({}, this.formData);
          copyForm.companyTypeName = this.unitTypeData.find((i) => {
            return i.code == copyForm.companyTypeCode;
          }).label;
          copyForm.responseRoleName = this.rolesData.find((i) => {
            return i.id == copyForm.responseRoleId;
          }).roleName;
          copyForm.riskLevelName = this.gradeList.find((i) => {
            return i.code == copyForm.riskLevelCode;
          }).label;
          copyForm.warnGradeName = this.cycleList.find((i) => {
            return i.code == copyForm.warnGradeCode;
          }).label;
          if (this.submitType == "add") {
            console.log(1);
            copyForm.id = "";
            warnAdd(copyForm)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.loading = true;
                  this.cancel();
                  this.$emit("CloseData");
                } else {
                  this.$message.error(res.message);
                }
              })
              .catch((res) => {
                this.$message.error(res.message);
              });
          } else if (this.submitType == "edit") {
            console.log(2);
            warnEdit(copyForm)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.loading = true;
                  this.cancel();
                  this.$emit("CloseData");
                } else {
                  this.$message.error(res.message);
                }
              })
              .catch((res) => {
                // this.cancel();
                this.$message.error(res.message);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancel() {
      this.reset();
      this.$emit('CloseData')
    },
    reset() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = "";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
</style>
