<template>
  <div class="min-h-full bg-white">
    <!--工具栏-->
    <div class="flex justify-between p-4">
      <el-row class="flex " style="margin-right: 10px">
        <el-cascader
            v-model="filters.lineSite"
            clearable
            :options="treeData"
            :props="props"
            :show-all-levels="false"
            collapse-tags
            style="margin-right: 10px;"
            @change="handleLineSiteChange"
        ></el-cascader>

        <el-select
            v-model="filters.unitType"
            placeholder="单位类型"
            class="form-item"
            style="width: 90%;margin-right: 10px"
            filterable
            clearable
        >
          <el-option
              v-for="item in unitTypeList"
              :key="item.id"
              :label="item.label"
              :value="item.code"
          >
          </el-option>
        </el-select>

        <el-input
            v-model="filters.name"
            style="width: 90%;margin-right: 10px"
            @keyup.native.enter="findPage(null)"
            clearable
            placeholder="用户名"
        ></el-input>

        <el-input
            v-model="filters.mobile"
            style="width: 90%;margin-right: 10px"
            @keyup.native.enter="findPage(null)"
            clearable
            placeholder="手机号"
        ></el-input>

        <el-select
            v-model="filters.isBlacklist"
            placeholder="黑白名单"
            style="width: 90%;margin-right: 10px"
            class="form-item"
            clearable
        >
          <el-option
              v-for="item in filtersBlackList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>

        <el-select
            clearable
            v-model="filters.ccPush"
            style="width: 90%;margin-right: 10px"
            placeholder="市政考勤"
            class="form-item"
        >
          <el-option
              v-for="item in filtersCcPushList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>

        <el-select
            clearable
            v-model="filters.jobGroup"
            style="width: 90%;margin-right: 10px"
            placeholder="工种分组"
            class="form-item"
        >
          <el-option
              v-for="item in jobList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>

        <el-button
            type="primary"
            style="height: 40px"
            @click="findPage(null)"
        >
          <i class="fa fa-search"></i> 查询
        </el-button>
      </el-row>

      <el-row class="toolbarRight">
        <el-button
            :disabled="isDisabled"
            v-if="showHideen"
            type="primary"
            @click="handleInOut"
        ><i class="el-icon-delete"></i> 批量{{
            inOut === "in" ? "进场" : "退场"
          }}
        </el-button
        >

        <el-button
            type="primary"
            @click="handleAdd()"
        >
          <i class="el-icon-circle-plus-outline"></i> 新增
        </el-button>


<!--        <el-button
            type="primary"
            @click.prevent="importExcel()"
        >
          <i class="el-icon-download"></i>导入
        </el-button>-->

        <el-button
            type="primary"
            @click.prevent="exportExcel()"
        >
          <i class="el-icon-download"></i>导出
        </el-button>
      </el-row>
      </div>

    <div class="mainPart">
      <div class="mainPartCon">
        <!--表格内容栏-->
        <!-- @handleEdit="handleEdit" -->
        <kt-table
          permsEdit="sys:user:edit"
          permsDelete="sys:user:delete"
          :data="pageResult"
          :columns="filterColumns"
          @findPage="findPage"
          @handleDelete="handleDelete"
          @associationUser="associationUser"
          :showOperation="true"
          :SGtype="SGtype"
          :loading="loading"
          :colwidth="200"
          :align="'center'"
          :emptyText="emptyText"
          @getRow="getRow"
          @selectionChange="getDElID(arguments)"
          @switchChange="switchChange"
          @switcStaChange="switcStaChange"
          @switcCcpushChange="switcCcpushChange"
          @loadings="loadings"
        >
        </kt-table>
      </div>
    </div>
    <uploadexcelcomponent
      :dialogExcelVisible="dialogExcelVisible"
      :on-excel-success="handleExcelSuccess"
      :on-zip-success="handleZipSuccess"
      @turnOff="turnOff"
    />
    <!-- 用户详情编辑 -->
    <div class="tistBox" v-if="dialogVisibleUser">
      <el-dialog
        :title="tiele"
        width="70%"
        :visible.sync="dialogVisibleUser"
        :close-on-click-modal="false"
      >
        <userList
          :idUsetList="idUsetList"
          @downLog="downLog"
          :roleList="roles"
          :editorType="editorType"
          :title="tiele"
          :userSiteIdList="userSiteIdList"
          :BrakeList="BrakeList"
          :isHaveCertificate="isHaveCertificate"
          :isSubcontractUnit="isSubcontractUnit"
          :userDeptUnitType="userDeptUnitType"
          :subUnitType="subUnitType"
        ></userList>
      </el-dialog>
    </div>
    <!-- 黑白白名单转换 -->

    <userForm
        v-if="addDialog"
        :parentData="parentData"
        :dialog-visble="addDialog"
        :mainData="mainData"
        :title="title"
        @offIt="offDia"
    ></userForm>

  </div>
</template>

<script>
import PopupTreeInput from "@/components/PopupTreeInput";
import KtTable from "@/views/Core/KtTable";
import TableColumnFilterDialog from "@/views/Core/TableColumnFilterDialog";
// import { format } from "@/utils/datetime";
import uploadexcelcomponent from "@/components/Excel";
import userList from "./UserList.vue";
import inputSelectTree from "@/components/inputSelectTree";
// import { changeStatusByIds } from "../../http/moudules/sys/user";
// import batchSync from "./components/batchSync";
import batchSync from "../../Sys/components/batchSync.vue";
import userForm from "@/views/SystemManagement/personInfo/components/userForm";
// import synchronization from "@/views/Sys/synchronization.vue"
import { getDictData } from "@/api/dict";
import {changeStatusByIds, getUserGateBrakePage, getUserSiteListByUserId, userDetail, batchDelete,userPage, userExportExcel} from "@/api/user";
import { baseURL } from "../../../../src/api/http"
import {account, getAccountInfo} from "@/api/ucenter";

export default {
  components: {
    PopupTreeInput,
    KtTable,
    TableColumnFilterDialog,
    uploadexcelcomponent,
    userList,
    inputSelectTree /* synchronization */,
    batchSync,
    userForm
  },
  data() {
    return {
      loading: false,
      addDialog: false,
      title: "",
      parentData: {},
      mainData: {},
      tiele: "",
      size: "small",
      treeData : [],
      props: {
        children: "children",
        label: "name",
        value: "id",
        multiple: true,
      },
      filters: {
        pageNum: 1,
        pageSize: 10,
        name: "",
        isBlacklist: "",
        deptId: "",
        isSynchro: null,
        ccPush: null,
        deptIdList: [],
        unitType: null,
        mobile: null,

        // deptIdList: ""
      },
      unitTypeList: [],
      showHideen: false,
      filtersSynchroList: [
        {
          label: "已同步",
          value: 1,
        },
        {
          label: "未同步",
          value: 0,
        },
      ],
      filtersBlackList: [
        {
          label: "白名单",
          value: 0,
        },
        {
          label: "黑名单",
          value: 1,
        },
      ],
      filtersCcPushList: [
        {
          label: "是",
          value: 1,
        },
        {
          label: "否",
          value: 0,
        },
      ],
      columns: [],
      filterColumns: [],
      jobList: [],
      jobGroup: [],
      pageRequest: { pageNum: 1, pageSize: 10, deptId: "" },
      pageResult: {},
      emptyText: "",
      blackForm: {}, //黑名单说明
      isBlack: false,
      operation: false, // true:新增, false:编辑
      dialogVisible: false, // 新增编辑界面是否显示
      editLoading: false,
      dataFormRules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        personName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        jobTypeValue: [
          { required: true, message: "请选择工种", trigger: "blur" },
        ],
        deptName: [{ required: true, message: "请选择角色", trigger: "blur" }],
      },
      // 新增编辑界面数据
      dataForm: {
        name: "",
        password: "",
        deptId: null,
        deptName: "",
        email: "",
        mobile: "",
        isSynchro: null,
        status: null,
        userRoles: [],
        personName: "",
        sex: null,
        idCard: "",
        isBlacklist: null,
        age: "",
        jobTypeValue: "",
        safeTrain: null,
        signEntryProcedure: null,
        signLaborContract: null,
        specialEquipmentOperators: null,
        socialInsurance: null,
        major: null,
        school: null,
        marriage: null,
        politicalIdentity: null,
        education: null,
        address: null,
        unitId: null,
        postId: null,
        disease: null,
        vaccination: null,
      },
      deptData: [],
      deptTreeProps: {
        label: "name",
        children: "children",
      },
      roles: [],
      defaultProps: {
        value: "id",
        label: "name",
        children: "children",
        expandTrigger: "hover",
      },
      imgUrl: "",
      imgData: { id: "" },
      imgHeaders: {
        Authorization: sessionStorage.getItem("token"),
      },
      fileList: [],
      jobTypes: [], // 工种类型字典
      expandedArr: [],
      dialogExcelVisible: false,
      SGtype: true,
      dialogVisibleUser: false,
      idUsetList: {
        status: null,
      },
      // 控制是否可提交
      editorType: "",
      selections: [],
      // 提交按钮的显示隐藏
      isHaveCertificate: false,
      certificateTypeList: [], //证书的下拉
      // 同步的
      issync: false,
      syncForm: {},
      syncFormRules: {
        blackDescribe: [
          { required: true, message: "请填写说明", trigger: "blur" },
        ],
      },
      // 同步的设备
      syncList: [],
      isShow: false,
      parmsSync: {},
      tableRow: {},
      // 左侧树换成上边下拉树
      nameArr: [],
      idArr: [],
      valueTitle: "",
      defaultId: "",
      //默认勾选的树
      defaultCheckedkeys: [],
      //这个用户查看考勤记录可选的站点列表下拉框
      userSiteIdList: [],
      // 打卡记录的分页
      BrakeList: {},
      /******************************同步******************/
      isSyncc: false,
      peopleList: [], //一
      awaitSynchronList: [], //二
      synchronList: [], //三
      syncData: {},
      peopleLoading: false,
      // multiple: [],
      multipleId: "",
      multiple: {},
      selectionsList: [],
      // 二
      awaitLoading: false,
      // awaitSynchronList: [],
      SynDisabled: true,
      idssync: [],
      multipleSelection: [],
      // 三
      synchronLoading: false,
      // synchronList: [],
      whetDisabled: true,
      synchronSelection: [],
      arrayLll: [],
      userFilter: {},
      awaitParams: {},
      isSubcontractUnit: false,
      isChongqing: null,
      userDeptUnitType: "",
      subUnitType: ["001", "002", "003", "006"],
      subValueStroge: {
        unitName: "",
        unitId: null,
        deptName: "",
        deptId: null,
      },
      inOut: "in",
      isDisabled: false,
      isBatchSync: false,
      batchName: [],
      batchSyncModal: false,
      deptId: null,
      isAdmin: sessionStorage.getItem("user") === "admin",
    };
  },
  watch: {},
  created() {
    this.getCertificateTypeList();
    this.findPage();
    //上传图片附件接口
    this.imgUrl = baseURL + "/user/uploadPic";
  },
  mounted() {
    // this.$api.user.findAll("jobGroup").then((res) => {
    //   this.jobList = res.data;
    // });
    let label = "jobGroup";
    this.jobList = [];
    getDictData(label).then((res) => {
      if (res.code == 0) {
        res.data.forEach((element) => {
          this.jobList.push({
            label: element.label,
            value: element.code,
          });
        });
      }
    });
    if (sessionStorage.userDeptUnitType) {
      this.userDeptUnitType = sessionStorage.userDeptUnitType;
    }
    // 判断当前用户的所属部门是否属于分包单位(相当于所属部门是子单位)，如果是的话 获取当前用户的所属部门信息，因为当前用户权限不能查看到他所属单位的上级，所以需要判断后手动查询
    if (this.subUnitType.includes(this.userDeptUnitType)) {
      this.subUnit();
    }
    this.initColumns();
    this.getJobTypes();
    this.unitTSelect();
    this.getLineSiteTreeData();
  },
  methods: {
    handleLineSiteChange(record){
      console.log(record);
      this.filters.siteIdList = []
      for (let item of record) {
        this.filters.siteIdList.push(item[item.length - 1])
      }
    },
    // 线路工点树数据查询
    getLineSiteTreeData () {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"))
      if (lineSiteData) {
        this.treeData = lineSiteData
      }
      // this.flatArr(lineSiteData);
    },

    // 同步
    bclose() {
      this.userFilter = {
        pageSize: 10000,
        pageNum: 1,
        name: "",
        type: 1,
      };
      this.currentHelmet = -1;
      this.isSyncc = false;
    },
    // loading效果
    loadings(data) {
      this.loading = data;
    },
    downLog() {
      this.dialogVisibleUser = false;
      this.findPage();
    },
    getRow(row, type, bool) {
      this.tiele = "用户信息";
      this.editorType = type;
      userDetail(row.id).then((res) => {
        if (res.code == 200) {
          this.idUsetList = res.data
          let userRoles = []
          // 角色
          if(this.idUsetList.userRoles){
            for (let i = 0, len = this.idUsetList.userRoles.length; i < len; i++) {
              userRoles.push(this.idUsetList.userRoles[i].roleId)
            }
            this.idUsetList.userRoles = userRoles
          }
          // 证书类型
          if (this.idUsetList.aiUserCertificateList.length > 0) {
            this.idUsetList.aiUserCertificateList.forEach((item) => {
              this.certificateTypeList.forEach((element) => {
                if (item.certificateType == element.value) {
                  item.certificateTypeName = element.label
                }
              })
            })
          }
          let that = this
          // 所属单位部门的名字
          if (!this.subUnitType.includes(this.userDeptUnitType)) {
            that.deptData.forEach((item) => {
              getdata(item)
            })
            function getdata(item) {
              if (item.id == that.idUsetList.unitId) {
                that.idUsetList.unitName = item.name
              }
              if (item.id == that.idUsetList.deptId) {
                that.idUsetList.deptName = item.name
              }
              if (item.children !== null) {
                item.children.forEach((data) => {
                  getdata(data)
                })
              }
            }
          } else {
            this.isSubcontractUnit = true
            this.idUsetList.unitName = this.subValueStroge.unitName
            this.idUsetList.unitId = this.subValueStroge.unitId
            this.idUsetList.deptName = this.subValueStroge.deptName
            this.idUsetList.deptId = this.subValueStroge.deptId
          }
          getUserSiteListByUserId(row.id).then((result) => {
            if (res.code == 200) {
              const islist = []
              this.userSiteIdList = result.data
              result.data.forEach((item) => {
                islist.push(item.id)
              })
              this.userSiteIdList.unshift({ name: "全部", id: islist })
            }
          })
          let parms = {
            pageNum: 1,
            pageSize: 5,
            siteIdList: [],
            userId: row.id
          }
          getUserGateBrakePage(parms).then((udata) => {
            if (udata.code == 200) {
              this.BrakeList = udata.data
              if (udata.data.list.length > 6) {
                const listd = []
                for (let i = 0; i < 5; i++) {
                  listd.push(udata.data.list[i])
                }
                this.BrakeList.list = listd
              }
            }
          })

          this.dialogVisibleUser = true
          this.isHaveCertificate = bool
        }
      })
    },

    handleNodeClick(checked) {
      this.filters.deptId = checked.id;
      this.findPage(null);
    },
    // 获取分页数据
    findPage(data) {
        this.loading = true;
      if (data) {
        this.filters.pageNum = 1;
        this.filters.pageSize = 10;
        Object.assign(this.filters, data.pageRequest);
      }
      userPage(this.filters)
          .then((res) => {
            this.loading = false;
            if (res.data.content && res.data.content.length > 0) {
              res.data.content.forEach((item, index) => {
                item.sta = item.status;
              });
            }
            this.pageResult = res.data;
            if (data in res) {
              this.emptyText = "";
            } else {
              this.emptyText = "暂无数据";
            }
            this.findUserRoles();
          })
          .then(data != null ? data.callback : "");
    },
    // 加载用户角色信息
    findUserRoles() {
      this.$api.role.findAll().then((res) => {
        // 加载角色集合
        this.roles = res.data;
      });
    },
    // 批量删除
    handleDelete(data) {
        batchDelete(data.params)
          .then(data != null ? data.callback : "");
    },
    async associationUser(data){
      console.log(data);
      this.mainData = {...data};
      let res = await account(data);
      this.parentData = res.data;
      if(Object.keys(this.parentData).length === 0){
        this.title = "新增";
      }else{
        this.title = "编辑";
      }
      this.addDialog = true;
      console.log(res)
    },
    //关闭窗口
    async offDia(data) {
      this.addDialog = false;
      if (data) {
        this.getTableList();
      }
    },
    // 同步的设备
    getSncList(data) {
      //   this.$api.user.syncAiGateUser({ userId: data.id }).then((res) => {
      //     this.syncList = res.data;
      //   });
    },

    getDElID(val) {
      console.log(val);
      this.selections = val[0].selections;
      if (val[0].selections.length > 0) {
        this.showHideen = true;
        let i = this.selections.findIndex((item) => item.status == 1);
        let j = this.selections.findIndex((item) => item.status == 0);
        if (i !== -1 && j === -1) {
          this.inOut = "out";
          this.isDisabled = false;
        } else if (i === -1 && j !== -1) {
          this.inOut = "in";
          this.isDisabled = false;
        } else {
          this.inOut = "in";
          this.isDisabled = true;
        }
      } else {
        this.showHideen = false;
      }
    },
    handleInOut() {
      this.$confirm(
        `确认批量${this.inOut === "in" ? "进场" : "退场"}吗？`,
        "提示",
        {
          type: "warning",
        }
      ).then(() => {
        let params = {
          userIds: this.selections.map((item) => item.id),
          status: this.inOut === "in" ? 1 : 0,
        };
        changeStatusByIds(params).then((res) => {
          if (res.code == 200) {
            this.$message.success("操作成功");
            this.findPage();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 显示新增界面
    handleAdd() {
      this.tiele = "添加";
      this.editorType = "Beditor";
      this.idUsetList = {};
      this.idUsetList.status = 1;
      // 判断当前用户是是否属于分包单位，如果是的话将单位名称单位id及部门名称部门id写死
      if (this.subUnitType.includes(this.userDeptUnitType)) {
        this.isSubcontractUnit = true;
        this.idUsetList.unitName = this.subValueStroge.unitName;
        this.idUsetList.unitId = this.subValueStroge.unitId;
        this.idUsetList.deptName = this.subValueStroge.deptName;
        this.idUsetList.deptId = this.subValueStroge.deptId;
        this.idUsetList.unitType = this.subValueStroge.unitType;
      }
      this.dialogVisibleUser = true;
      this.isHaveCertificate = true;
    },
    subUnit() {
      var obj = {};
      //   this.$api.user.findFBParent().then((res) => {
      //     if (res.code == 200) {
      //       obj = Object.assign({}, res.data);
      //       // 将获取到的信息存到本地
      //       this.subValueStroge.unitName = obj.name;
      //       this.subValueStroge.unitId = obj.id;
      //       this.subValueStroge.unitType = obj.unitType;
      //     }
      //   });
      let deptInfo = JSON.parse(sessionStorage.currentDeptInfo);
      this.subValueStroge.deptName = deptInfo.deptName;
      this.subValueStroge.deptId = deptInfo.deptId;
    },
    // 证书类型
    getCertificateTypeList() {
      //   this.$api.user.findAll("certificateType").then((res) => {
      //     if (res.code == 200) {
      //       this.certificateTypeList = res.data;
      //     }
      //   });
    },
    // 单位
    unitTSelect() {
      getDictData("typeUnit").then((res) => {
          this.unitTypeList = res.data;
        });
    },
    // 显示编辑界面
    handleEdit(params) {
      this.fileList = [];
      if (params.row.pic) {
        this.fileList.push({
          name: "头像",
          url: params.row.pic,
        });
      }

      this.dialogVisible = true;
      this.operation = false;
      this.dataForm = Object.assign({}, params.row);
      this.imgData.id = this.dataForm.id;
      let userRoles = [];
      for (let i = 0, len = params.row.userRoles.length; i < len; i++) {
        userRoles.push(params.row.userRoles[i].roleId);
      }
      this.dataForm.userRoles = userRoles;
    },
    // 编辑
    submitForm() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            const jobTypeObj = this.findJobTypeObj(this.dataForm.jobTypeValue);
            this.dataForm.jobTypeLabel = jobTypeObj.label;
            this.dataForm.jobTypeId = jobTypeObj.id;
            let params = Object.assign({}, this.dataForm);
            let userRoles = [];
            for (let i = 0, len = params.userRoles.length; i < len; i++) {
              let userRole = {
                userId: params.id,
                roleId: params.userRoles[i],
              };
              userRoles.push(userRole);
            }
            params.userRoles = userRoles;
            // this.$api.user.save(params).then((res) => {
            //   this.editLoading = false;
            //   if (res.code == 200) {
            //     this.$message({ message: "操作成功", type: "success" });
            //     this.dialogVisible = false;
            //     this.$refs["dataForm"].resetFields();
            //     this.imgData.id = res.data.id;
            //     // LABEL 1
            //     this.$refs.userImg.submit();
            //   } else {
            //     this.$message({
            //       message: "操作失败, " + res.msg,
            //       type: "error",
            //     });
            //   }
            //   // LABEL 1 中图片上传为后置，无法控制
            //   setTimeout(() => {
            //     this.findPage(null);
            //   }, 1500);
            // });
          });
        }
      });
    },
    // 黑名单提交
    submitSync() {
      this.parmsSync.blackDescribe = this.syncForm.blackDescribe;
      let date = new Date();
      let time = this.moment(date).format("YYYY-MM-DD HH:mm:ss");
      this.parmsSync.time = time;

      this.$refs.syncForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            // this.$api.user.BlacklistSave(this.parmsSync).then((res) => {
            //   this.editLoading = false;
            //   this.issync = false;
            //   if (res.code == 200) {
            //     if (this.syncForm.blacklist == 1) {
            //       this.$api.user
            //         .syncAiGateUser({ userId: this.parmsSync.userId })
            //         .then((res) => {
            //           if (res.data.length > 0) {
            //             res.data.forEach((item, index) => {
            //               this.$api.user
            //                 .saveOrUpdateUser({ userId: item.userId })
            //                 .then((res) => {
            //                   if (res.code == 200) {
            //                     this.$message.success(
            //                       "设备" + item.gateCode + "同步成功"
            //                     );
            //                     this.findPage();
            //                   } else {
            //                     this.$message.error(
            //                       "设备" + item.gateCode + "同步失败"
            //                     );
            //                   }
            //                 });
            //             });
            //           } else {
            //             this.$message.warning("没有设备可同步");
            //           }
            //         });
            //     }
            //     this.$message({ message: "操作成功", type: "success" });
            //     this.dialogVisible = false;
            //     this.findPage();
            //   } else {
            //     this.$message({ message: "操作失败", type: "error" });
            //     this.dialogVisible = false;
            //     this.findPage();
            //   }
            // });
          });
        }
      });
    },
    // 表格开关
    switchChange(num, val) {
      this.syncForm = {};
      this.issync = true;
      this.isBlack = true;
      this.getSncList(val);
      this.tableRow = val;
      this.parmsSync = {
        userId: val.id,
        blacklist: num,
      };
    },
    // 状态开关
    switcStaChange(val) {
      let that = this;
      let params = {
        userIds: [val.id],
        status: val.status == 0 ? 1 : 0,
      };
      changeStatusByIds(params).then((res) => {
        that.editLoading = false;
        if (res.code == 200) {
          that.$message({ message: "操作成功", type: "success" });
          that.dialogVisible = false;
          that.findPage();
        } else {
          that.findPage();
          that.$message({
            message: "操作失败, " + res.msg,
            type: "error",
          });
        }
      });
    },
    switcCcpushChange(val) {
      let that = this;
      //   that.$api.user.changeCcPush(val).then((res) => {
      //     if (res.code == 200) {
      //       that.$message({ message: "操作成功", type: "success" });
      //       that.findPage();
      //     } else {
      //       that.findPage();
      //       that.$message({
      //         message: "操作失败, " + res.msg,
      //         type: "error",
      //       });
      //     }
      //   });
    },
    // 选中
    handleCheckChange(data, name) {
      this.filters.deptIdList = data;
      this.nameArr = name;
    },
    // 清除选中
    clearHandle() {
      this.nameArr = [];
      this.idArr = [];
      this.filters.deptIdList = [];
    },
    // 获取工种字典
    getJobTypes() {
      let label = "jobType";
      this.jobTypes = [];
      getDictData(label).then((res) => {
        if (res.code == 0) {
          res.data.forEach((element) => {
            this.jobTypes.push({
              label: element.label,
              value: element.label,
            });
          });
        }
      });
      //   this.$api.user.getJobTypes({ lable: "jobType" }).then((res) => {
      //     this.jobTypes = res.data;
      //   });
    },
    // 处理表格列过滤显示
    initColumns() {
      if (this.roleType === "role_dalian") {
        this.columns = [
          // { prop: "index", label: "序号", width: 55, type: "index" },
          { prop: "personName", label: "姓名", minWidth: 80 },
          { prop: "name", label: "用户名", },
          { prop: "unitTypeName", label: "单位类型", minWidth: 80 },
          { prop: "unitName", label: "单位名称", minWidth: 145 },
          { prop: "personTypeName", label: "人员类别", minWidth: 80 },
          { prop: "personDetailTypeName", label: "人员细分", minWidth: 80 },
          // { prop: "postName", label: "岗位职称", minWidth: 80 },
          { prop: "jobTypeLabel", label: "人员工种", minWidth: 80 },
          { prop: "sta", label: "用户状态", minWidth: 75 },
          { prop: "isBlacklist", label: "黑名单", minWidth: 75 },
          { prop: "pic", label: "头像", minWidth: 80 },
          { prop: "codeImage", label: "二维码", minWidth: 80 },
          // { prop: "signImage", label: "手签名", minWidth: 80 }
        ];
      } else {
        this.columns = [
          // { prop: "index", label: "序号", width: 55, type: "index" },
          { prop: "personName", label: "姓名", minWidth: 80 },
          { prop: "name", label: "用户名" },
          { prop: "unitName", label: "所属单位", minWidth: 145 },
          { prop: "deptName", label: "所属部门", minWidth: 80 },
          { prop: "postName", label: "岗位职称", minWidth: 80 },
          { prop: "jobTypeLabel", label: "人员工种", minWidth: 80 },
          { prop: "sta", label: "进/退场", minWidth: 75 },
          { prop: "isBlacklist", label: "黑名单", minWidth: 75 },
          { prop: "pic", label: "头像", minWidth: 80 },
          { prop: "codeImage", label: "二维码", minWidth: 80 },
          // { prop: "signImage", label: "手签名", minWidth: 80 }
        ];
      }

      if (this.isChongqing) {
        this.columns.push({ prop: "ccPush", label: "市政考勤", minWidth: 80 });
      }

      this.filterColumns = [].concat(this.columns);

      console.log('show-overflow-tooltip',this.filterColumns);
    },
    // 根据工种名称找工种对象
    findJobTypeObj(value) {
      return this.jobTypes.filter((item) => {
        return item.value === value;
      })[0];
    },
    // 上传成功
    handleExcelSuccess({ msg, code }) {
      if (code == 200) {
        this.findPage(null);
        this.$message({ message: "导入成功", type: "success" });
      } else {
        this.$message({ message: msg, type: "error" });
      }
    },
    handleZipSuccess({ msg, result }) {
      if (msg == "success") {
        this.findPage(null);
        this.dialogExcelVisible = false;
        this.$message({ message: "导入成功", type: "success" });
      } else {
        this.$message({ message: result, type: "error" });
      }
    },
    turnOff() {
      this.dialogExcelVisible = false;
    },
    importExcel() {
      this.dialogExcelVisible = true;
    },
    // 导出
    exportExcel() {
      this.exportLoading = true;
      userExportExcel(this.filters).then(res => {
        const aLink = document.createElement("a");
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel;charset=utf-8"
        });
        aLink.href = URL.createObjectURL(blob);
        aLink.download = "用户列表.xlsx";
        aLink.click();
        document.body.appendChild(aLink);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-cascader .el-input{
  width: 202px;
}
.toolbarRight{
  width: 20%;
  white-space: nowrap;
  overflow-x: auto;
}
.toolbarRight::-webkit-scrollbar
{
  display: block !important;
  width: 5px !important;
  height: 5px !important;
}
.form-item {
  width: -webkit-fill-available;
  text-align: left;
}
.filter-item {
  width: 150px;
}
.kt-table {
  // height: calc(100% - 50px) !important;
  overflow-y: auto;
}
.syncItme {
  // border: 1px solid #ccc;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 10px;
  width: 85%;
  float: left;
  list-style: none;
  ::v-deep.el-botton i {
    font-size: 20px;
  }
}
.gateCode {
  width: 50%;
  display: inline-block;
  text-align: center;
}

/* 样式文件global.scss*/
/* switch按钮样式 */
.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}
/*打开时文字位置设置*/
.switch .el-switch__label--right {
  z-index: 1;
}
/* 调整打开时文字的显示位子 */
.switch .el-switch__label--right span {
  margin-right: 20px;
}
/*关闭时文字位置设置*/
.switch .el-switch__label--left {
  z-index: 1;
}
/* 调整关闭时文字的显示位子 */
.switch .el-switch__label--left span {
  margin-right: -10px;
}
/*显示文字*/
.switch .el-switch__label.is-active {
  display: block;
}
/* 调整按钮的宽度 */
.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 45px !important;
  margin: 0;
}

.syncSmg {
  display: inline-block;
  margin-left: 10px !important;
}

.itemstyle .el-form-item__label {
  margin-top: 15px;
}

.filter-item1 {
  min-width: 180px;
  .el-select {
    min-width: 180px;
  }
}
.el-form-item--small .el-form-item__content,
.el-form-item--small .el-form-item__label {
  width: 100%;
}

.editLog {
  // ::v-deep .el-dialog .el-dialog__header{
  //   border: none !important;
  // }
  ::v-deep .el-dialog__body {
    padding-top: 0px !important;
    padding-bottom: 20px !important;
    height: 70% !important;
  }
}
::v-deep .tistBox > .el-dialog {
  height: 80% !important;
}

::v-deep .nameButton {
  margin-top: 20px;
}

/*单位类型显示滚动条*/
::v-deep .el-scrollbar .is-vertical {
  width: 6px !important;
}

// 同步
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
.form-item {
  width: -webkit-fill-available;
  text-align: left;
}
::v-deep .el-dialog__body {
  height: 87%;
}
::v-deep.configPeopleMain {
  display: flex;
  justify-content: space-between;
  height: 100%;
  .config-box {
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 28%;
    height: 100%;
    border: 1px solid #7073ff;
    border-radius: 20px;
    .config-box-title {
      font-size: 16px;
      font-weight: bolder;
      margin: 0 0 8px 0;
    }
    .config-box-button {
      text-align: right;
      padding-top: 10px;
      .el-button [class*="el-icon-"] + span {
        margin-left: 0;
      }
    }
    > .mainPart {
      height: calc(100% - 40px) !important;
      .el-tree {
        width: 200px;
        box-sizing: border-box;
        margin-right: 0 !important;
      }
    }
  }
  .configPeopleCon {
    margin-left: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    > .el-table {
      height: calc(100% - 60px) !important;
      border-radius: 20px;
      overflow: auto;
    }
  }
  .mainPart {
    .el-table {
      height: calc(100% - 10px) !important;
    }
  }
}
::v-deep .toolbarRight {
  width: 20%;
  white-space: nowrap;
  padding-bottom: 5px;
  box-sizing: border-box;
}
::v-deep .el-form--inline {
  width: 100%;
  display: flex;
  overflow-x: scroll;
}
::v-deep .scroll-form::-webkit-scrollbar {
  display: block !important;
  width: 5px !important;
  height: 5px !important;
}
::v-deep .page-container .toolbarRight,
.page-container .toolbarLeft {
  padding-left: 0;
}
.el-button.is-disabled {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(112, 115, 255, 0.85);
}
.DL-btn.is-disabled {
  background-color: #4285d0;
}

.page-container .toolbarRight {
  float: right;
}

.page-container .toolbarLeft {
  float: left;
  text-align: left;
}

.page-container .toolbarRight,
.page-container .toolbarLeft {
  padding: 10px 0 0 15px;
}

/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}
/* 滚动条样式 */
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px !important;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 8px !important;
  border-radius: 5px !important;
  background: #ffffff;
  cursor: pointer !important;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px !important;
  -webkit-box-shadow: inset 0 0 5px rgba(240, 240, 240, 0.5) !important;
  background: #c1c1c1 !important;
  cursor: pointer !important;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(240, 240, 240, 0.5) !important;
  border-radius: 5px !important;
  background: rgba(240, 240, 240, 0.5) !important;
  cursor: pointer !important;
}
</style>
