<template>
  <systemModal
    ref="formModal"
    @modalConfirm="handleSubmit"
    :title="title"
    width="40%"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="120px"
      class="demo-model"
      style="width: 80%"
    >
      <el-form-item
        label="工点名称"
        prop="lineSite"
        v-if="!isEdit && MultipleSite"
      >
        <el-cascader
          v-model="model.lineSite"
          ref="cascaderTree"
          :options="lineSiteData"
          :props="props"
          :show-all-levels="false"
          @change="handleLineSiteChange"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="工点名称" v-else>
        <el-input
          v-model="siteName"
          placeholder="请输入工点名称"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="负责人" prop="leaderId">
        <el-select
          v-model="model.leaderId"
          placeholder="请选择负责人"
          @change="handlePersonChange"
        >
          <el-option
            v-for="item in dict.person"
            :key="item.leaderId"
            :label="item.leaderName"
            :value="item.leaderId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系方式" prop="mobile">
        <el-input
          v-model="model.mobile"
          placeholder="请输入联系方式"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="model.remark"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入备注"
        >
        </el-input>
      </el-form-item>
    </el-form>
  </systemModal>
</template>
<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { addWareHouseMan, editWareHouseMan, getChargePerson } from "@/api/eres";
import { getSiteDataByUuId } from "@/api/resource";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "新增",
      isEdit: false,
      MultipleSite: true,
      siteIds: [],
      siteName: "",
      model: {
        lineSite: [],
        materialsSitedVoList: [],
        leaderName: "",
        leaderId: "",
        mobile: "",
        remark: "",
      },
      flatTreeData: [],
      props: {
        children: "children",
        label: "name",
        value: "id",
        multiple: true,
      },
      dict: {
        person: [],
      },
      loading: false,
      rules: {
        lineSite: [
          { required: true, message: "请选择线路工点", trigger: "change" },
        ],
        leaderId: [
          { required: true, message: "请选择负责人", trigger: "change" },
        ],
        mobile: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
          {
            pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: "请输入合法手机号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    lineSiteData: {
      type: Array,
      default: function() {
        return [];
      },
    },
    type: {
      type: Number,
    },
  },
  mounted() {
    this.getSiteDataByUserInfo();
    this.getChargePerson();
  },
  methods: {
    async getChargePerson() {
      let res = await getChargePerson({});
      if (res.code == 0) {
        this.dict.person = res.data;
      }
    },
    async getSiteDataByUserInfo() {
      const userInfo = JSON.parse(localStorage.getItem("user"));
      const params = {
        userId: userInfo.uuid,
      };
      let res = await getSiteDataByUuId(params);
      if (res.code == 0) {
        this.siteIds = res.data;
        if (res.data.length > 1) {
          this.MultipleSite = true;
        } else {
          this.MultipleSite = false;
        }
      }
    },
    // 负责人选中事件
    handlePersonChange(val) {
      const obj = this.dict.person.find((item) => item.leaderId == val);
      this.model.leaderName = obj.leaderName;
    },
    handleAdd(record) {
      this.model = {
        lineSite: [],
        materialsSitedVoList: [],
        leaderId: "",
        leaderName: "",
        mobile: "",
        remark: "",
      };
      this.flatArr(this.lineSiteData);
      if (record) {
        this.title = "编辑";
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
        this.siteName = record.siteName;
        this.isEdit = true;
      } else {
        this.title = "新增";
        this.isEdit = false;
        // 如果只有一个工点权限
        if (!this.MultipleSite) {
          const tempArr = [];
          this.findParent(this.siteIds[0], tempArr);
          const line = tempArr[0];
          const branch = tempArr[1];
          const site = tempArr[tempArr.length - 1];
          this.siteName = site.name;
          this.model.materialsSitedVoList.push({
            branchId: branch.id,
            branchName: branch.name,
            lineId: line.id,
            lineName: line.name,
            siteId: site.id,
            siteName: site.name,
            superviseId: site.constructionManagementUnitId,
            superviseName: site.constructionManagementUnitName,
          });
        }
      }
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    // 线路工点树选择
    handleLineSiteChange(val) {
      console.log(val);
    },
    // 数组扁平化
    flatArr(data) {
      for (let item of data) {
        this.flatTreeData.push(item);
        if (item.children) {
          this.flatArr(item.children);
        }
      }
    },
    // 查找祖先
    findParent(uuid, result) {
      const tempObj = this.flatTreeData.find((item) => item.uuid == uuid);
      if (tempObj) {
        result.unshift(tempObj);
        if (tempObj.puuid != "0") {
          this.findParent(tempObj.puuid, result);
        }
      }
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.isEdit) {
            const params = JSON.parse(JSON.stringify(this.model));
            editWareHouseMan(params).then((res) => {
              if (res.code == 0) {
                this.$message.success("操作成功");
                this.formReset();
                this.$refs.formModal.handleClose();
                this.$emit("formSubmit");
                this.loading = false;
              }
            });
          } else {
            // 如果为多工点
            if (this.MultipleSite) {
              const siteNodes = this.$refs.cascaderTree.getCheckedNodes(true);
              for (let node of siteNodes) {
                this.model.materialsSitedVoList.push({
                  branchId: node.path[1],
                  branchName: node.pathLabels[1],
                  lineId: node.path[0],
                  lineName: node.pathLabels[0],
                  siteId: node.value,
                  siteName: node.label,
                  superviseId: node.data.constructionManagementUnitId,
                  superviseName: node.data.constructionManagementUnitName,
                });
              }
            }
            const params = JSON.parse(JSON.stringify(this.model));
            addWareHouseMan(params)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.formReset();
                  this.$refs.formModal.handleClose();
                  this.$emit("formSubmit");
                  this.loading = false;
                }
              })
              .catch((err) => {
                console.log(err);
                this.loading = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    formReset() {
      this.$refs.modelForm.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped></style>
