<template>
  <div class="page-container bg-white p-4 h-full relative">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModel" class="flex justify-between">
        <el-row>
          <el-form-item label="专业类型">
            <el-select
              v-model="queryModel.majorType"
              clearable
              placeholder="请选择专业类型"
            >
              <el-option
                v-for="item of dict.majorType"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="擅长领域">
            <el-input
              v-model="queryModel.expertiseAreas"
              clearable
              placeholder="请输入擅长领域"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="专家名称">
            <el-input
              v-model="queryModel.specialistName"
              clearable
              placeholder="输入专家名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row> 
          <!-- <el-button type="primary" @click="handleTemplateDownLoad" v-has="'expert:download'"
            >下载导入模板</el-button
          > -->
          <!-- <el-button type="primary" @click="handleImport" v-has="'expert:therimport'">导入</el-button> -->
          <el-button type="primary" @click="handleExport" v-has="'expert:export'">导出</el-button>
          <el-button type="primary" @click="handleAdd" v-has="'expert:add'">新增</el-button>
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="specialistName"
          label="姓名"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="deptName"
          label="单位"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="postName"
          label="职务"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="majorType"
          label="专业类型"
          :show-overflow-tooltip="true"
          :formatter="majorName"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="expertiseAreas"
          label="擅长领域"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="contactInfo"
          label="联系方式"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="synopsis"
          label="简介"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-has="'expert:edit'"
              class="mr-4"
              >编辑</el-button
            >

            <el-button @click="handleDel(scope.row)" type="text"
             v-has="'expert:delete'"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <ExpertForm ref="modalForm" @formSubmit="handleFormSubmit"></ExpertForm>
  </div>
</template>

<script>
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import ExpertForm from "./modules/expertForm.vue";
import { getExpertPage, delExpert } from "@/api/eres";
export default {
  components: {
    ExpertForm,
  },
  data() {
    return {
      queryModel: {
        // expertiseAreas: "",
        majorType: "",
        specialistName: "",
      },
      tableData: [],
      loading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      dict: {
        majorType: [],
        expertiseAreas: [],
      },
    };
  },
  mounted() {
    this.getDictData("专业类型", this.dict.majorType);
    // this.getDictData("擅长领域", this.dict.expertiseAreas);
  },
  methods: {
    // 顶部查询
    handleSearch() {
      this.loadData();
    },
    // 导入模板下载
    handleTemplateDownLoad() {},
    // 导入
    handleImport() {},
    // 导出
    handleExport() {},
    // 新增
    handleAdd() {
      this.$refs.modalForm.handleAdd();
    },
    // 修改
    handleEdit(record) {
      this.$refs.modalForm.handleAdd(record);
    },
    // 删除
    handleDel(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delExpert(record.specialistId).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadData();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    // 表单提交
    handleFormSubmit() {
      this.loadData();
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
              this.loadData();
            }
          });
        }
      });
    },
    // 加载表格数据
    loadData() {
      this.loading = true;
      const params = {
        majorType: this.queryModel.majorType || null,
        // expertiseAreas: this.queryModel.expertiseAreas || null,
        specialistName: this.queryModel.specialistName || null,
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
      };
      getExpertPage(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          this.page.pageNo = res.data.current;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.total;
          this.page.count = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },
    majorName(row) {
      const obj = this.dict.majorType.find((item) => item.id == row.majorType);
      if (obj) {
        return obj.label;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
