<template>
  <div
    style="wieth: 100%; height: 100%; border-radius: 10px"
    class="flex flex-col conLeft"
  >
    <Video ref="Video"></Video>
    <Rectification></Rectification>
    <Camera></Camera>
  </div>
</template>

<script>
import Video from "@/views/ThreeProofings/WorkPointPage/rightPage/video";
import Camera from "@/views/ThreeProofings/WorkPointPage/rightPage/camera";
import Rectification from "@/views/ThreeProofings/WorkPointPage/rightPage/rectification";

export default {
  name: "routeList",
  components: { Video, Camera, Rectification },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.conLeft {
  height: 100%;
  display: grid;
  grid-template-rows: 44% calc(25% - 20px) 31%;
  grid-row-gap: 10px;
}
</style>
