<template>
  <!-- 风险巡查历史记录 -->
  <el-dialog
    width="50%"
    title="风险巡查历史记录"
    class="editDialog details-dialog"
    top="10vh"
    :visible.sync="dialogHistory"
    v-if="dialogHistory"
    center
    :close-on-click-modal="false"
    :before-close="handleHistoryClose"
  >
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" align="center" label="序号" width="60">
      </el-table-column>
      <el-table-column
        prop="progressTitle"
        align="center"
        label="巡查名称"
        width="150"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="companyTypeName"
        align="center"
        label="检查单位"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="submitPersonName"
        align="center"
        label="检查人"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <!-- <el-button type="text" @click="view(scope.row)">查看</el-button> -->
          <el-button type="text" @click="handleView(scope.row, 'prode')">查看问题</el-button>
          <el-button type="text" @click="handleView(scope.row, 'process')"
                      v-if="scope.row.companyTypeCode === 'monitor' ">风险进展</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import {historyList} from "@/api/riskManage";
import {queryDeptDetail} from "@/api/stand";

export default {
  props: {
    dialogHistory: {
      type: Boolean,
      default: false
    },
    riskDeafUuid : {
      default : ''
    },
  },
  data() {
    return {
      // dialogHistory: false
      tableData: [
        { patrolName: "2022年11月19日共处0出问题" },
        { patrolName: "2022年11月19日共处0出问题" },
        { patrolName: "2022年11月19日共处0出问题" },
        { patrolName: "2022年11月19日共处0出问题" },
        { patrolName: "2022年11月19日共处0出问题" }
      ],
      deptType : ''
    };
  },
  created() {
    this.getDeptDetail();
    this.getTableData();
  },
  methods: {
    handleHistoryClose() {
      this.$emit("closedialogHistory");
    },
    handleView(row, type) {
      this.$emit("hanlderPro", row, type);
    },
    getTableData(){
      historyList(this.riskDeafUuid).then(res => {
        this.tableData = res.data;
      })
    },
    //查询属于哪个角色
    getDeptDetail() {
      console.log(JSON.parse(localStorage.getItem("user")));
      var userInfo = JSON.parse(localStorage.getItem("user"));
      queryDeptDetail(userInfo.companyId).then((res) => {
        console.log(res);
        this.deptType = res.data ? res.data.category : "0";
      });
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .details-dialog {
  .el-dialog__header {
    .el-dialog__title {
      position: static;
    }
  }
  .el-dialog__body {
    height: 680px;
    // max-height: 680px;
    overflow: auto;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
/*::v-deep .el-table__fixed-right{
  height: 100% !important;
}*/
</style>
