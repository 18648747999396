<template>
  <div style="flex: 1">
    <!--表格栏-->
    <el-table
      ref="qtable"
      :data="data.content"
      :highlight-current-row="highlightCurrentRow"
      @selection-change="selectionChange"
      @current-change="handleCurrentChange"
      v-loading="loading"
      :row-style="{ height: '60px' }"
      :border="border"
      :show-overflow-tooltip="showOverflowTooltip"
      :size="size"
      :align="align"
      style="width: 100%; overflow: scorll"
    >
      <!-- :height="height" -->
      <!-- :max-height="maxHeight" -->
      <!-- :element-loading-text="$t('action.loading')" -->
      <template slot="empty">{{ emptyText }}</template>
      <!-- :stripe="stripe" -->
      <el-table-column
        type="selection"
        width="60"
        align="center"
        v-if="showBatchDelete & showOperation"
      ></el-table-column>
      <el-table-column
        type="index"
        width="60"
        label="序号"
        align="center"
      ></el-table-column>
      <!--      转换-->
      <el-table-column
        v-for="column in columns"
        header-align="center"
        align="center"
        :prop="column.prop"
        :label="column.label"
        :width="column.width"
        :min-width="column.minWidth"
        :fixed="column.fixed"
        :key="column.prop"
        :type="column.type"
        :formatter="column.formatter"
        :sortable="column.sortable == null ? true : column.sortable"
      >
        <template slot-scope="scope">
          <div
            v-if="
              column.prop == 'personName' ||
                column.prop == 'pic' ||
                column.prop == 'codeImage' ||
                column.prop == 'status' ||
                column.prop == 'isBlacklist' ||
                column.prop == 'sta' ||
                column.prop == 'ccPush' ||
                column.prop == 'signImage'
            "
          >
            <div class="nemclassknem" v-if="column.prop == 'pic'">
              <!-- <el-image v-if="scope.row.pic != null" :src="scope.row.pic" :preview-src-list="[scope.row.pic]">
                </el-image> -->
              <ImgUpload
                class="erweima"
                v-if="scope.row.pic != null"
                :src="scope.row.pic"
                :preview-src-list="[scope.row.pic]"
              ></ImgUpload>
            </div>
            <div v-else-if="column.prop == 'codeImage'">
              <ImgUpload
                class="erweima"
                v-if="scope.row.codeImage != null"
                :src="scope.row.codeImage"
                :preview-src-list="[scope.row.codeImage]"
              ></ImgUpload>
            </div>
            <div v-else-if="column.prop == 'signImage'">
              <ImgUpload
                class="erweima"
                v-if="scope.row.signImage != null"
                :src="scope.row.signImage"
                :preview-src-list="[scope.row.signImage]"
              ></ImgUpload>
            </div>
            <div
              class="clickable"
              @click="handleDeleteModify(scope.row, 'Leditor', false)"
              v-else-if="column.prop == 'personName'"
            >
              {{ scope.row.personName }}
            </div>
            <div v-else-if="column.prop == 'status'">
              {{ returCLname(scope.row.status) }}
            </div>
            <!-- 状态 -->
            <div v-else-if="column.prop == 'sta'">
              <el-switch
                class="main-switch"
                v-model="scope.row.sta"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                active-text="进 场"
                inactive-text="退 场"
                @change="switcStaChange($event, scope.row)"
              >
              </el-switch>
            </div>
            <!-- 市政考勤 -->
            <div v-else-if="column.prop == 'ccPush'">
              <el-switch
                class="switch"
                v-model="scope.row.ccPush"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                @change="switcCcpushChange($event, scope.row)"
              >
              </el-switch>
              <!-- {{ scope.row.ccPush === 1 ? "是" : "否" }} -->
            </div>
            <!-- 黑白名单 -->
            <div v-else-if="column.prop == 'isBlacklist'">
              <el-tooltip
                class="item"
                effect="dark"
                :content="
                  scope.row.isBlacklist == 1
                    ? '点击转换白名单'
                    : '点击转换黑名单'
                "
                placement="top"
              >
                <el-tag
                  type="success"
                  v-if="scope.row.isBlacklist == 1"
                  @click="isBlacklist(0, scope.row)"
                  >黑名单</el-tag
                >
                <el-tag
                  v-if="scope.row.isBlacklist == 0"
                  @click="isBlacklist(1, scope.row)"
                  >白名单</el-tag
                >
              </el-tooltip>
            </div>
          </div>

          <div v-else>
            {{ scope.row[column.prop] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        :width="colwidth"
        fixed="right"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            v-if="SGtype"
            :perms="permsDelete"
            type="text"
            :size="size"
            @click="handleDeleteModify(scope.row, 'Leditor', true)"
            class="mr-4"
            >完善信息
          </el-button>

          <el-button
            v-else
            :perms="permsEdit"
            type="text"
            :size="size"
            @click="handleEdit(scope.$index, scope.row)"
            class="mr-4"
            >编辑
          </el-button>

          <el-button
            :perms="permsDelete"
            type="text"
            :size="size"
            @click="handleDelete(scope.$index, scope.row)"
            class="mr-4"
            >删除
          </el-button>

          <el-button
              :perms="permsDelete"
              type="text"
              :size="size"
              @click="associationUser(scope.$index, scope.row)"
              class="mr-4"
          >关联用户
          </el-button>
          <!-- <el-tooltip class="item" effect="dark" content="同步" placement="top">
            <el-button
              icon="el-icon-refresh"
              label=""
              :disabled="scope.row.status == 0"
              :perms="permsDelete"
              :size="size"
              type="primary"
              @click="handleSync(scope.$index, scope.row)"
            />
          </el-tooltip> -->
        </template>
      </el-table-column>
    </el-table>
    <!--分页栏-->
    <div class="toolbar" style="margin-top: 20px">
      <!-- <kt-button
          :label="$t('action.batchDelete')"
          :perms="permsDelete"
          :size="size"
          type="danger"
          @click="handleBatchDelete()"
          :disabled="this.selections.length === 0"
          style="float: left"
          v-if="showBatchDelete & showOperation"
        /> -->
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="refreshPageRequest"
        @size-change="refreshSizeChange"
        :current-page="pageRequest.pageNum"
        :page-size="pageRequest.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="data.totalSize"
        style="float: right"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import KtButton from "@/views/Core/KtButton";
import ImgUpload from "@/components/ImgUpload";
// import BloodPressureVue from "../Sys/BloodPressure.vue";
export default {
  name: "KtTable",
  components: {
    // KtButton,
    ImgUpload,
  },
  props: {
    SGtype: {
      type: Boolean,
      default: false,
    },
    columns: Array, // 表格列配置
    data: Object, // 表格分页数据
    permsEdit: String, // 编辑权限标识
    permsDelete: String, // 删除权限标识
    size: {
      // 尺寸样式
      type: String,
      default: "mini",
    },
    align: {
      // 文本对齐方式
      type: String,
      default: "left",
    },
    // maxHeight: {
    //   // 表格最大高度
    //   type: Number,
    //   default: 635,
    // },
    height: {
      // 表格最大高度
      type: Number,
      default: 700,
    },
    showOperation: {
      // 是否显示操作组件
      type: Boolean,
      default: true,
    },
    border: {
      // 是否显示边框
      type: Boolean,
      default: true,
    },
    // stripe: {
    //   // 是否显示斑马线
    //   type: Boolean,
    //   default: true
    // },
    highlightCurrentRow: {
      // // 是否高亮当前行
      type: Boolean,
      default: true,
    },
    showOverflowTooltip: {
      // 是否单行显示
      type: Boolean,
      default: true,
    },
    showBatchDelete: {
      // 是否显示操作组件
      type: Boolean,
      default: true,
    },
    // loading效果
    loading: {
      type: Boolean,
      default: false,
    },

    //暂无数据
    emptyText: {
      type: String,
      default: "",
    },
    //  操作的宽
    colwidth: {
      type: Number,
      default: 350,
    },
  },
  data() {
    return {
      // 分页信息
      pageRequest: {
        pageNum: 1,
        pageSize: 10,
      },
      // loading: false, // 加载标识
      selections: [], // 列表选中列
    };
  },
  watch: {
    data(val) {
      this.doLayout();
    },
  },
  methods: {
    returCLname(val) {
      if (val == "1") {
        return "正常";
      } else if (val == "0") {
        return "禁用";
      }
      return val;
    },
    goDetail(row) {
      this.$emit("rowIdLook", row);
    },
    handleDeleteModify(row, type, bool) {
      this.$emit("getRow", row, type, bool);
    },
    doLayout() {
      this.$nextTick(() => {
        this.$refs.qtable.doLayout();
      });
    },

    isBlacklist(val, row) {
      this.$emit("switchChange", val, row);
    },
    switcStaChange(val, row) {
      let param = {
        id: row.id,
        status: row.status,
      };
      this.$emit("switcStaChange", param);
    },
    switcCcpushChange(val, row) {
      let param = {
        id: row.id,
        ccPush: row.ccPush,
      };
      this.$emit("switcCcpushChange", param);
    },
    // 分页查询
    findPage() {
      // this.loading = true
      this.$emit("loadings", true);
      let callback = (res) => {
        // this.loading = false
        this.$emit("loadings", false);
      };
      this.$emit("findPage", {
        pageRequest: this.pageRequest,
        callback: callback,
      });
    },
    // 选择切换
    selectionChange(selections) {
      this.selections = selections;
      this.$emit("selectionChange", { selections: selections });
    },
    // 选择切换
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", { val: val });
    },
    // 换页刷新
    refreshPageRequest(pageNum) {
      this.pageRequest.pageNum = pageNum;
      this.findPage();
    },
    refreshSizeChange(val) {
      this.pageRequest.pageSize = val;
      this.findPage();
    },
    // 编辑
    handleEdit(index, row) {
      this.$emit("handleEdit", { index: index, row: row });
    },
    // 删除
    handleDelete(index, row) {
      if (row.name && row.name == "admin") {
        this.$message({
          message: "超级管理员不允许删除！",
          type: "error",
        });
      } else {
        this.delete(row.id);
      }
    },
    associationUser(index, row) {
      this.$emit('associationUser',row)
    },
    handleSync(index, row) {
      this.$emit("handleSync", row);
    },
    // 批量删除
    handleBatchDelete() {
      let ids = this.selections.map((item) => item.id).toString();
      this.delete(ids);
    },
    // 删除操作
    delete(ids) {
      this.$confirm("确认删除选中记录吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          let params = [];
          let idArray = (ids + "").split(",");
          for (var i = 0; i < idArray.length; i++) {
            params.push({ id: idArray[i] });
          }
          // this.loading = true
          this.$emit("loadings", true);
          let callback = (res) => {
            if (res.code == 200) {
              this.$message({ message: "删除成功", type: "success" });
              this.findPage();
            } else {
              this.$message({ message: "操作失败, " + res.msg, type: "error" });
            }
            // this.loading = false
            this.$emit("loadings", false);
          };
          this.$emit("handleDelete", { params: params, callback: callback });
        })
        .catch(() => {});
    },
  },
  mounted() {
    // this.refreshPageRequest(2)
  },
};
</script>

<style lang="scss" scoped>
::v-deep .clickable {
  font-weight: normal;
}
.nemclassknem {
  height: 80px !important;
}
.erweima {
  height: 80px;
}
/* switch按钮样式 */
::v-deep .main-switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}
/*打开时文字位置设置*/
::v-deep .main-switch .el-switch__label--right {
  z-index: 1;
}
::v-deep .main-switch .el-switch__core {
  width: 70px !important;
  height: 27px !important;
  border-radius: 13px !important;
}
/* 调整打开时文字的显示位子 */
::v-deep .main-switch .el-switch__label--right span {
  margin-right: 20px;
}
/*关闭时文字位置设置*/
::v-deep .main-switch .el-switch__label--left {
  z-index: 1;
  left: 25px;
  top: 1px;
}
// 圆球的位置
::v-deep .main-switch .el-switch__core:after {
  top: 5px !important;
  //left: 3px !important;
}
/* 调整关闭时文字的显示位子 */
::v-deep .main-switch .el-switch__label--left span {
  margin-right: -10px;
}
::v-deep .main-switch .el-switch__label--leftel-switch__label--left {
  left: 20px;
}
/*显示文字*/
::v-deep .main-switch .el-switch__label.is-active {
  display: block;
}
/* 调整按钮的宽度 */
::v-deep .main-switch .el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
</style>
