import Layout from "@/components/Layout/SystemLayout.vue";
import Categorys from "@/views/HiddenDanger/System/Wooden/Categorys/index.vue";
import Respond from "@/views/HiddenDanger/System/Wooden/Response/respond.vue";
import ItemLibrary from "@/views/HiddenDanger/ItemLibrary";
import AssessCriteria from "@/views/HiddenDanger/System/Wooden/AssessCriteria/AssessCriteria";
import HdangerToDo from "@/views/HiddenDanger/System/Wooden/HdangerToDo/index";
import GovernFlow from "@/views/HiddenDanger/System/Wooden/govern/governFlow.vue";
import troubleFrequency from "@/views/HiddenDanger/System/Wooden/troubleFrequency/troubleFrequency";
import Settle from "@/views/HiddenDanger/System/WoodenGovern/Settle/tidy.vue";
import Release from "@/views/HiddenDanger/System/WoodenGovern/Settle/release.vue";
import Rectify from "@/views/HiddenDanger/System/WoodenGovern/Settle/rectify.vue";
import Eliminate from "@/views/HiddenDanger/System/WoodenGovern/Settle/eliminate.vue";
import History from "@/views/HiddenDanger/System/WoodenGovern/Settle/history.vue";

import DayTrouble from "@/views/HiddenDanger/System/DangerTrouble/DayTrouble/DayTrouble";
import SpecialTrouble from "@/views/HiddenDanger/System/DangerTrouble/SpecialTrouble/SpecialTrouble";

const Routes = [
  {
    path: "HiddenDanger",
    component: Layout,
    name: "HiddenDanger",
    children: [
      {
        path: "Wooden",
        name: "Wooden",
        component: Layout,
        meta: {
          title: "隐患",
          path: [
            {
              name: "隐患",
            },
          ],
        },
        children: [
          {
            path: "categorys",
            name: "categorys",
            component: Categorys,
          },
          {
            path: "Respond",
            name: "Respond",
            component: Respond,
          },
          {
            path: "AssessCriteria",
            name: "AssessCriteria",
            component: AssessCriteria,
            meta: {
              title: "考核标准",
            },
          },
          {
            path: "GovernFlow",
            name: "GovernFlow",
            component: GovernFlow,
            meta: {
              title: "治理流程",
            },
          },
          {
            path: "troubleFrequency",
            name: "troubleFrequency",
            component: troubleFrequency,
            meta: {
              title: "排查频次",
            },
          },
          {
            path: "HdangerToDo",
            name: "HdangerToDo",
            component: HdangerToDo,
            meta: {
              title: "隐患待办通知",
            },
          },
        ],
      },
      {
        path: "ItemLibrary",
        name: "ItemLibrary",
        component: ItemLibrary,
        meta: {
          title: "隐患条目库",
        },
      },
      {
        path: "WoodenGovern",
        name: "WoodenGovern",
        component: Layout,
        meta: {
          title: "隐患治理",
        },
        children: [
          {
            path: "Release",
            name: "Release",
            component: Release,
            meta: {
              title: "隐患发布",
            },
          },
          {
            path: "Settle",
            name: "Settle",
            component: Settle,
            meta: {
              title: "隐患响应",
            },
          },
          {
            path: "Rectify",
            name: "Rectify",
            component: Rectify,
            meta: {
              title: "隐患整改",
            },
          },
          {
            path: "Eliminate",
            name: "Eliminate",
            component: Eliminate,
            meta: {
              title: "隐患消除",
            },
          },
          {
            path: "History",
            name: "History",
            component: History,
            meta: {
              title: "历史隐患",
            },
          },
        ],
      },
      {
        path: "DangerTrouble",
        name: "DangerTrouble",
        component: Layout,
        meta: {
          title: "隐患排查",
        },
        children: [
          {
            path: "DayTrouble",
            name: "DayTrouble",
            component: DayTrouble,
            meta: {
              title: "日常排查",
            },
          },
          {
            path: "SpecialTrouble",
            name: "SpecialTrouble",
            component: SpecialTrouble,
            meta: {
              title: "日常排查",
            },
          },
        ],
      },
    ],
  },
];

export default Routes;
