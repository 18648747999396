<template>
  <!--质安部汇总数据-查看详情页面  -->
  <div v-loading="tableLoading">
    <!-- <el-page-header @back="goBack"> </el-page-header> -->
    <div>
      <span>汇总单位：{{ summaryDepartment }}</span
      ><span style="margin-left:100px">汇总次数:{{ times }}</span>
    </div>
    <el-table
      ref="tableData"
      :data="tableData"
      id="tableData"
      border
      :row-style="{ height: '30px' }"
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column
        label="线路工点"
        prop="lineSite"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        label="派出工作组(个)从应急响应启动到本次报送时间节点,派出工作组的累计数量"
        align="center"
        prop="workGroup"
      >
      </el-table-column>
      <el-table-column
        prop="position"
        label="检查场所部位(处)从应急响应启动到本次报送时间节点,检查场所部位的累计数量"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="hiddenDanger"
        label="新排查出三防风险隐患(处)上—次报送时间节点到本次报送时间节点，新排查出的三防风险隐患数量"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="info"
        label="发送预警或防御信息(条)地铁集团不需要填该项"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.info ? scope.row.info : "/" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="worker"
        label="预置(出动)抢险人员(人)在本次报送时间节点，能立即调动的抢险人员"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="team"
        label="预置(出动)抢险队伍(支)在本次报送时间节点，能立即调动的抢险队伍数量"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="预置(出动)抢险设备(台)指在本次报送时间节点，能立即调动设备数量"
        align="center"
      >
        <el-table-column prop="deviceTotal" label="合计" align="center">
        </el-table-column>
        <el-table-column
          prop="vehicle"
          label="车辆能立即调动的抢险汽车、吊车、铲车、履带车等"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="boat"
          label="舟艇能立即调动的舟船、划艇等水上运输工具数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="waterPump"
          label="抽水机能立即调动的龙吸水、自吸泵、排污泵、潜水泵、动力站水泵等抽排水设备数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="other"
          label="其他能立即调动的、未统计在车辆、舟艇、抽水机范围内的三防抢险设备数量"
          align="center"
        >
        </el-table-column>
      </el-table-column>

      <el-table-column
        prop="remark"
        label="其他应急措施落实及受灾害影响情况指从应急响应启动到本次报送时间节点，集团公司各单位应急措施落实及受灾受影响累计情况"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.remark ? scope.row.remark : "/" }}
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: center; margin-top: 20px">
      <el-button @click="sureSub" type="primary" :disabled="derty"
        >导出</el-button
      >
    </div>
    <!-- <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :modal-append-to-body="false"
      :append-to-body="true"
      center
    >
      <el-checkbox-group v-model="checkList">
        <el-checkbox
          :label="item[0]"
          v-for="(item, index) in timeList"
          :key="index"
        ></el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="derive">导出</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
// 接口
import {
  // 检查表填报-获取检查项
  summarydata,
  qualitydata,
  derivedata,
} from "@/api/response";

export default {
  props: {
    stasher: {
      type: String,
    },
    resetid: {
      type: Number,
    },
    responseId: {
      type: String,
    },
    supervisionUuid: {
      type: String,
    },
  },
  components: {
    // checkPage,
  },
  data() {
    return {
      tableLoading: false,
      tableData: [],
      derty: false,
      dialogVisible: false,
      summaryDepartment: "",
      times: "",
      timeList: [],
    };
  },
  created() {
    this.getResponseList();
  },
  mounted() {
    // this.getTableList();
    //线路
    // this.linetes.forEach((element) => {
    //   this.lineList.push(element);
    // });
  },
  watch: {
    resetid: {
      handler(val) {
        if (val) {
          this.serte(val);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    serte(val) {
      this.tableLoading = true;
      qualitydata({ id: val }).then((res) => {
        this.tableLoading = false;
        this.tableData = res.data.list;
        this.summaryDepartment = res.data.summaryDepartment;
        this.times = res.data.times;
        this.timeList = res.data.timeList;
      });
    },

    // 表格的汇总
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "汇总";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          // sums[index] += " 元";
        } else {
          sums[index] = "/";
        }
      });

      return sums;
    },
    //导出弹框
    sureSub() {
      this.$confirm("确定导出, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableLoading = true;
          derivedata({
            times: this.timeList,
            responseId: this.responseId,
            supervisionUuid: this.supervisionUuid,
          }).then((res) => {
            let blob = new Blob([res], {
              type: "application/vnd.ms-excel",
            });
            // 3.创建一个临时的url指向blob对象
            let url = window.URL.createObjectURL(blob);
            // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
            let a = document.createElement("a");
            a.href = url;
            a.download = "导出数据_" + new Date().getTime() + ".xlsx"; //自定义导出文件名
            a.click();
            this.tableLoading = false;
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //导出文件
    derive() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    cancel() {
      this.dialogVisible = false;
    },
    goBack() {
      this.$emit("summeryClose");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-table::before {
  height: 0;
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: #fff !important;
  border-radius: 7px;
}
/*定义滑块颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
::v-deep ::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 7px;
  border-radius: 0;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}

//去掉固定列下方的横线
::v-deep .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: transparent;
}
//去掉固定列阴影
::v-deep .el-table__fixed-right {
  // box-shadow: none;
  height: 100% !important;
}

// 表格多选框是小计的隐藏掉
::v-deep .myCell .el-checkbox__input {
  display: none !important;
}
::v-deep .el-table th > .cell {
  font-size: 12px !important;
}
::v-deep .el-table--border,
.el-table--group {
  border: none;
}
::v-deep .el-table--border::after,
.el-table--group::after {
  width: 0 !important;
}
::v-deep .el-table--border th.el-table__cell.gutter:last-of-type {
  background-color: #fff !important;
  border-bottom: none;
}
::v-deep .el-radio-button {
  width: 75px;
}
::v-deep .el-radio-button__inner {
  width: 65px;
}
::v-deep .el-table {
  display: flex;
  flex-direction: column;
}
::v-deep .el-table__body-wrapper {
  order: 1;
}

::v-deep .el-table__footer-wrapper tbody td {
  cursor: pointer;
}
::v-deep .el-radio-button:first-child:last-child .el-radio-button__inner {
  width: 88px;
}
::v-deep .el-radio-button {
  width: 95px;
}
</style>
