<template>
  <systemModal
    ref="formModal"
    @modalConfirm="handleSubmit"
    :title="title"
    width="50"
  >
    <baidu-map scroll-wheel-zoom @ready="mapReady">
      <!--地图视图-->
      <bm-view class="map"></bm-view>
      <bm-control>
        <el-form :inline="true" class="flex justify-between">
          <el-form-item>
            <el-input
              v-model="inputValue"
              placeholder="请输入地名关键字"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="confirmAddress(inputValue)"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </bm-control>
      <!--搜索-->
      <bm-local-search
        :keyword="keyword"
        :panel="isShowPanel"
        auto-viewport
        :zoom="10000"
        :location="location"
        @markersset="setAddressList"
      ></bm-local-search>

      <!--点标注-->
      <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" />
      <bm-marker
        :position="location"
        :dragging="true"
        animation="BMAP_ANIMATION_BOUNCE"
      />
    </baidu-map>
    <!-- <div style="width:60%">
      <el-form label-width="80px" class="demo-model">
        <el-form-item label="选择位置">
          <el-input v-model="model.address" readonly></el-input>
        </el-form-item>
      </el-form>
    </div> -->
  </systemModal>
</template>
<script>
import systemModal from '@/components/Modal/systemModal.vue'
let geocoder
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: '坐标选择',
      model: {},
      keyword: '',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      location: {
        lng: 113.2839488029,
        lat: 23.0907270045,
      },
      isShowPanel: false,
      BMap: {},
      map: {},
      inputValue: '',
    }
  },
  props: ['getCoo'],
  methods: {
    mapReady({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      geocoder = new BMap.Geocoder()
      if (this.model.address) {
        this.keyword = this.model.address
        this.map.centerAndZoom(
          new BMap.Point(this.location.lng, this.location.lat),
          10
        )
      } else {
        this.map.centerAndZoom(
          new BMap.Point(113.2839488029, 23.0907270045),
          10
        )
      }
      map.addEventListener('click', ({ point }) => {
        this.location.lng = point.lng
        this.location.lat = point.lat
        geocoder.getLocation(point, (res) => {
          this.model.address = res.address
          console.log('11当前所在位置信息', res)
          console.log('当前所在位置信息', res.address)
          this.model.storeLongitude = point.lng
          this.model.storeLatitude = point.lat
          this.$forceUpdate()
        })
      })
    },
    setAddressList(e) {
      console.log('搜索相关数据', e)
    },
    confirmAddress(e) {
      console.log(e)
      if (this.inputValue != '') {
        console.log('输入框不为空赋值输入框的值')
        this.keyword = this.inputValue
      } else if (this.model.address != undefined) {
        console.log('输入框不为空 地址也不为空 赋值地址的值')
        this.keyword = this.model.address
      }
      setTimeout(() => {
        var local = new this.BMap.LocalSearch(this.map, {
          renderOptions: { map: this.map },
        })
        local.search(this.keyword)
      }, 0)
    },
    handleAdd(lng, lat, address) {
      console.log(lng, lat, address)
      if (!address) {
        this.model.address = ''
      } else {
        if (lng && lat) {
          this.model.address = address
          this.location.lng = lng
          this.location.lat = lat
        } else {
          this.location.lng = 113.2839488029
          this.location.lat = 23.0907270045
        }
      }
      this.$refs.formModal.handleOpen()
      this.$forceUpdate()
    },
    handleSubmit() {
      this.getCoo(
        this.model.storeLongitude,
        this.model.storeLatitude,
        this.model.address
      )
      this.$refs.formModal.handleClose()
    },
  },
}
</script>
<style lang="scss" scoped>
.map {
  width: 100%;
  height: 500px;
}

.more_panel {
  text-align: center;
  font-size: 12px;
  color: #2878ff;
  padding-top: 10px;

  span {
    cursor: pointer;
    i {
      transform: rotate(90deg);
    }
  }

  span.down {
    i {
      transform: rotate(-90deg);
    }
  }
}
::v-deep .el-dialog__body {
  padding: 30px 0 !important;
}
.demo-model {
  margin: 20px 0 0;
}
.el-form-item {
  margin: 20px 0 20px 20px;
  // width: 80%;
}
</style>
