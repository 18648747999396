<template>
  <div class="sidebar">
    <div
      class="sidebar_item"
      v-for="(item, index) in sider"
      :key="index"
      @click="meet(item)"
      v-has="item.haes"
    >
      <p class="items"><img :src="item.icon" alt="" /></p>
      <p>{{ item.sidename }}</p>
    </div>
    <Modal
      class="modalDetail"
      ref="qupes"
      :modal="false"
      :width="'500px'"
      :title="titesle"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="升级级别">
          <el-input type="textarea" v-model="form.currentLevel"></el-input>
        </el-form-item>
        <el-form-item label="升级原因">
          <el-input type="textarea" v-model="form.upgradeReason"></el-input>
        </el-form-item>
        <el-form-item label="操作人">
          <el-input v-model="form.naemsres"></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 15%">
          <el-button
            @click="upgradeset"
            style="background: none; border-color: #3e9eff; color: #3e9eff"
            >取 消</el-button
          >
          <el-button type="primary" @click="upgrade">确 定</el-button>
        </el-form-item>
      </el-form>
    </Modal>
    <Modal
      class="modalDetail"
      ref="espond"
      :modal="false"
      :width="'500px'"
      :title="titesle"
    >
      <div class="top">
        <p
          style="
            color: #ffffff;
            text-align: center;
            margin-top: 20px;
            font-size: 18px;
          "
        >
          是否确认结束当前事件?
        </p>
        <el-row>
          <el-button
            @click="espondset"
            style="
              background: none;
              border-color: #3e9eff;
              color: #3e9eff;
              margin-top: 30px;
              margin-left: 35%;
            "
            >取 消</el-button
          >
          <el-button type="primary" @click="espond">确 定</el-button>
        </el-row>
      </div>
    </Modal>
    <Modal
      class="modalDetail"
      ref="plantes"
      :modal="false"
      :width="'1200px'"
      :title="titesle"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="匹配预案" name="first">
          <el-input
            v-model="matchinputs"
            placeholder="输入关键字查询"
            style="width: 17%; border: 1px solid #143363"
            class="partime"
          ></el-input>
          <el-button
            type="primary"
            @click="matchplan"
            style="margin-left: 10px; background: #43a5e9"
            >查询</el-button
          >

          <el-table
            :data="gridlist"
            border
            :header-cell-style="{
              background: '#0E2553',
              color: '#ffffff',
              border: '1px solid #193F71'
            }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              property="planName"
              label="预案名称"
            ></el-table-column>
            <el-table-column property="planFile" label="附件">
              <template slot-scope="scope"
                ><el-button type="text" @click="handleFileClick(scope.row)"
                  >附件</el-button
                ></template
              ></el-table-column
            >
          </el-table>
          <div class="pagination-wrapper flex justify-center mt-4">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="page.count"
              :page-count="page.total"
              :page-size="page.pageSize"
              :current-page="page.pageNo"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            >
            </el-pagination>
          </div>
        </el-tab-pane>

        <el-tab-pane label="全部预案" name="second"
          ><el-input
            v-model="allinputs"
            placeholder="输入关键字查询"
            style="width: 17%; border: 1px solid #143363"
            class="partime"
          ></el-input>
          <el-button
            type="primary"
            @click="allplan"
            style="margin-left: 10px; background: #43a5e9"
            >查询</el-button
          >
          <el-table
            :data="gridlists"
            border
            :header-cell-style="{
              background: '#0E2553',
              color: '#ffffff',
              border: '1px solid #193F71'
            }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              property="planName"
              label="预案名称"
            ></el-table-column>
            <el-table-column property="planFile" label="附件">
              <template slot-scope="scope"
                ><el-button type="text" @click="handlesFileClick(scope.row)"
                  >附件</el-button
                ></template
              ></el-table-column
            >
          </el-table>
          <div class="pagination-wrapper flex justify-center mt-4">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pages.count"
              :page-count="pages.total"
              :page-size="pages.pageSize"
              :current-page="pages.pageNo"
              @current-change="handleChange"
              @size-change="hanSizeChange"
            >
            </el-pagination></div
        ></el-tab-pane>
      </el-tabs>

      <el-row>
        <el-button
          type="primary"
          @click="launch"
          style="margin-top: 30px; margin-left: 40%"
          >启动预案</el-button
        >
        <el-button
          @click="launchset"
          style="
            background: none;
            border-color: #3e9eff;
            color: #3e9eff;
            margin-top: 30px;
            margin-left: 5%;
          "
          >取 消</el-button
        >
      </el-row>
    </Modal>
    <Modal
      class="modalDetail"
      ref="qutypes"
      :modal="false"
      :width="'1200px'"
      :title="titesle"
    >
      <el-row>
        <el-input
          v-model="unit"
          class="input_cer"
          placeholder="输入关键字查询"
          style="width: 17%"
        ></el-input>

        <el-button
          type="primary"
          @click="unitonSubmit"
          style="margin-left: 10px"
          >查询</el-button
        >
      </el-row>
      <el-table
        :data="gridData"
        :header-cell-style="{
          background: '#0E2553',
          color: '#ffffff',
          border: '1px solid #193F71'
        }"
      >
        <el-table-column
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          property="resourceName"
          label="单位或部门"
        ></el-table-column>
        <el-table-column property="address" label="地址"></el-table-column>
        <el-table-column property="telephone" label="电话"></el-table-column>
        <el-table-column property="remarks" label="备注"></el-table-column>
      </el-table>
      <div class="pagination-wrapper flex justify-center mt-4">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="geides.count"
          :page-count="geides.total"
          :page-size="geides.pageSize"
          :current-page="geides.pageNo"
          @current-change="handlegeidesChange"
          @size-change="hanSizegeidesChange"
        >
        </el-pagination>
      </div>
    </Modal>
    <Modal
      class="modalDetail"
      ref="warning"
      :modal="false"
      :width="'500px'"
      :title="titesle"
    >
      <el-form :model="warning.warnState" label-width="80px">
        <el-form-item label="应急预警:" v-show="waring">
          <el-radio-group v-model="warning.warnState" @change="radioChange()">
            <el-radio label="2">启动预警</el-radio>
            <el-radio label="3">结束预警</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="预警级别:" v-show="Level">
          <el-select
            v-model="warning.warnLevel"
            placeholder="请选择"
            class="warningets"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预警原因:">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 8 }"
            v-model="warning.warnReason"
          ></el-input>
        </el-form-item>

        <el-form-item label="操作人:">
          <el-input v-model="warning.releaseUserName"></el-input>
        </el-form-item>
      </el-form>
      <el-row>
        <el-button type="primary" @click="warningmi" style="margin-left: 38%"
          >确 定</el-button
        >
        <el-button
          @click="warningset"
          style="background: none; border-color: #3e9eff; color: #3e9eff"
          >取 消</el-button
        >
      </el-row>
    </Modal>
    <Modal
      class="modalDetail"
      ref="disposal"
      :modal="false"
      :width="'1200px'"
      :title="titesle"
    >
      <div class="bottwr">
        <span class="time"
          >事件类型:
          <div class="temwer">xxxxx位置</div>
        </span>

        <span class="time"
          >发生时间
          <div class="temwer">xxxxx位置</div></span
        >

        <span class="time"
          >事件等级:
          <div class="temwer">xxxxx位置</div></span
        >

        <span class="time"
          >负责人:
          <div class="temwer">xxxxx位置</div></span
        >

        <span class="time"
          >地理位置:
          <div class="temwer">xxxxx位置</div></span
        >
        <span class="time"
          >工程项目名称:
          <div class="temwer">xxxxx位置</div></span
        >
        <span class="time"
          >施工单位:
          <div class="temwer">xxxxx位置</div></span
        >
        <span class="time"
          >监理单位:
          <div class="temwer">xxxxx位置</div></span
        >
        <span class="time"
          >事件经过:
          <div class="temwer" style="width: 1050px">xxxxx位置</div></span
        >
      </div>
      <p style="color: #ffffff; font-size: 15px">
        启动预案：
        <span style="color: #ffffff; font-size: 15px">xxxxxxxxx</span>
      </p>
      <p style="color: #ffffff; font-size: 15px">
        启动人： <span style="color: #ffffff; font-size: 15px">xxxxxxxxx</span>
      </p>
      <div class="toobte">
        <div class="title">你的职责</div>
        1.xxxxxxxxxxxxxxxxxx
      </div>

      <el-row>
        <el-button
          type="primary"
          @click="partel"
          style="
            margin-left: 48%;
            margin-top: 30px;
            background: none;
            border-color: #3e9eff;
            color: #3e9eff;
          "
          >我知道了</el-button
        >
      </el-row>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal/modal.vue";
import {
  finalQuote,
  warningete,
  Upgrade,
  getprogress,
  responseend
} from "@/api/response.js";
import { EmergencyPageList } from "@/api/emergency.js";
import { GetPublic } from "@/api/eres.js";
import { queryDictByCode } from "@/api/dict.js";
import { queryRoleById } from "@/api/role";
export default {
  components: { Modal },
  props: {
    detaltes: {
      type: Object
    }
  },
  // watch: {
  //   detalieltes: {
  //     handler(val) {
  //       console.log(val);

  //     },
  //     immediate: true
  //   }
  // },
  data() {
    return {
      sider: [
        {
          icon: require("../../../assets/shouqi.png"),
          sidename: "收起两侧",
          haes: "Outburst:bothsides"
        },
        {
          icon: require("../../../assets/qidong.png"),
          sidename: "启动预案",
          haes: "Outburst:startplan"
        },
        {
          icon: require("../../../assets/xaingyi.png"),
          sidename: "响应升级",
          haes: "Outburst:upgrade"
        },
        {
          icon: require("../../../assets/yingji.png"),
          sidename: "应急联络",
          haes: "Outburst:contact"
        },
        {
          icon: require("../../../assets/yujing.png"),
          sidename: "应急预警",
          haes: "Outburst:warning"
        },
        {
          icon: require("../../../assets/jishu.png"),
          sidename: "响应结束",
          haes: "Outburst:responseend"
        }
      ],
      contn: ["匹配预案", "全部预案"],
      dialogresponse: false,
      form: {
        resource: "1"
      },
      input: "",
      dialogStart: false,
      num: 0,
      formzs: {},
      radio: "1",
      gridData: [],
      titesle: "",

      warning: {},

      dialogtreatment: false,
      activeName: "first",
      gridlist: [],
      gridlists: [],

      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      pages: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      geides: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      matchinputs: "",
      allinputs: "",
      inputs: "",
      unit: "", //应急联络查询
      options: [], //预警等级
      final: {},
      finelse: {},
      warninges: {},
      user: {},
      unitType: "",
      waring: false,
      Level: false,
      unitRole: "",
      belonging: 0
    };
  },
  created() {
    queryDictByCode({ code: "currentLevel" }).then(res => {
      console.log(res);
      this.options = res.data;
    });
    this.user = JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
    if (this.unitType == "construct") {
      this.belonging = 1;
    }
    // this.roleName = this.user.roles[0].roleName;
    // console.log(this.roleName);
    queryRoleById({ id: this.user.roles[0].id }).then(res => {
      this.unitType = res.data.unitType;
      this.unitRole = res.data.unitType + this.user.roles[0].roleName;
      console.log("1111111111", this.unitRole);
      console.log(this.unitType);
    });
  },
  methods: {
    meet(val) {
      console.log(val);
      if (val.sidename == "响应升级") {
        this.$refs.qupes.handleOpen();
        this.form.naemsres = this.user.realName;
        this.form.createdId = this.user.uuid;
        this.form.responseEventId = this.detaltes.uuid;
        this.form.upgradeUnitId = this.user.companyId;
        if (this.unitRole == "construct负责人") {
          this.form.beforeLevel = "ⅠⅠⅠ";
          this.form.beforeLevelName = "等级ⅠⅠⅠ";
          this.form.currentLevel = "ⅠⅠ";
          this.form.currentLevelName = "等级ⅠⅠ";
        } else if (this.unitRole == "constructionUnit值班领导") {
          this.form.beforeLevel = "ⅠⅠ";
          this.form.beforeLevelName = "等级ⅠⅠ";
          this.form.currentLevel = "Ⅰ";
          this.form.currentLevelName = "等级Ⅰ";
        } else if (this.unitRole == "metroGroupUnit集团领导") {
          this.form.beforeLevel = "ⅠⅠⅠ";
          this.form.beforeLevelName = "等级ⅠⅠⅠ";
          this.form.currentLevel = "ⅠⅠ";
          this.form.currentLevelName = "等级ⅠⅠ";
        }
        this.titesle = "响应升级";
      } else if (val.sidename == "启动预案") {
        this.$refs.plantes.handleOpen();
        this.titesle = "启动预案";

        this.emerg({
          belonging: this.belonging,
          siteId: this.detaltes.siteUuid
        });
      } else if (val.sidename == "应急联络") {
        this.gried();
        this.$refs.qutypes.handleOpen();
        this.titesle = "应急联络";
      } else if (val.sidename == "应急预警") {
        if (this.unitRole == "construct负责人") {
          this.waring = false;
          this.Level = true;
        } else {
          this.waring = true;
          this.Level = true;
        }
        this.$refs.warning.handleOpen();

        this.warning.releaseUserId = this.user.uuid;
        this.warning.releaseUnitId = this.user.companyId;
        this.warning.releaseUserName = this.user.realName;
        this.titesle = "应急预警";
      } else if (val.sidename == "响应结束") {
        console.log(this.detaltes);
        getprogress({
          responseRecordUUid: this.detaltes.uuid,
          responseType: "deadline",
          size: "0"
        }).then(res => {
          console.log(res);
          if (res.data.length == 0) {
            this.$message({
              message: "请先填写事件终报",
              type: "warning"
            });
          } else {
            responseend({
              uuid: this.detaltes.uuid
            }).then(res => {
              if (res.code == 0) {
                this.$refs.espond.handleOpen();
                this.titesle = "响应结束";
              }
            });
          }
        });

        //     this.$confirm('是否确认结束当前事件?', '响应结束', {
        //   confirmButtonText: '确认',
        //   cancelButtonText: '取消',

        // }).then(() => {
        //   this.dialogwarning=false
        // }).catch(() => {
        //    this.dialogwarning=false
        // });
      } else if (val.sidename == "收起两侧") {
        this.$emit("pack");
      }
    },
    //预案列表
    emerg(val) {
      EmergencyPageList({
        belonging: val.belonging,
        siteId: val.siteId,
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize
      }).then(res => {
        console.log(res);
        this.gridlist = res.data.records;
        this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.page.total = res.data.count;
        this.page.count = res.data.total;
      });
    },
    //应急联络数据列表
    gried() {
      GetPublic({
        pageNum: this.geides.pageNo,
        pageSize: this.geides.pageSize
      }).then(res => {
        this.gridData = res.data.records;
        this.geides.pageNo = res.data.current;
        this.geides.pageSize = res.data.size;
        this.geides.total = res.data.count;
        this.geides.count = res.data.total;
      });
    },
    //应急联络查询
    unitonSubmit() {
      console.log(2222);
      if (this.unit == "") {
        this.gried();
      } else {
        GetPublic({
          pageNum: this.geides.pageNo,
          pageSize: this.geides.pageSize,
          resourceName: this.unit
        }).then(res => {
          this.gridData = res.data.records;
          this.geides.pageNo = res.data.current;
          this.geides.pageSize = res.data.size;
          this.geides.total = res.data.count;
          this.geides.count = res.data.total;
        });
      }
    },
    //匹配预案查询
    matchplan() {
      if (this.matchinputs == "") {
        this.emerg({ belonging: 1, planTypeId: 422 });
      } else {
        EmergencyPageList({
          belonging: this.belonging,
          siteId: this.detaltes.siteUuid,
          pageNum: this.page.pageNo,
          pageSize: this.page.pageSize,
          planName: this.matchinputs
        }).then(res => {
          console.log(res);
          this.gridlist = res.data.records;
          this.page.pageNo = res.data.current;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.count;
          this.page.count = res.data.total;
        });
      }
    },
    //全部预案查询
    allplan() {
      if (this.allinputs == "") {
        EmergencyPageList({
          pageNum: this.pages.pageNo,
          pageSize: this.pages.pageSize
        }).then(res => {
          console.log(res);
          this.gridlists = res.data.records;
          this.pages.pageNo = res.data.current;
          this.pages.pageSize = res.data.size;
          this.pages.total = res.data.count;
          this.pages.count = res.data.total;
        });
      } else {
        EmergencyPageList({
          pageNum: this.pages.pageNo,
          pageSize: this.pages.pageSize,
          planName: this.allinputs
        }).then(res => {
          console.log(res);
          this.gridlists = res.data.records;
          this.pages.pageNo = res.data.current;
          this.pages.pageSize = res.data.size;
          this.pages.total = res.data.count;
          this.pages.count = res.data.total;
        });
      }
    },
    handleFileClick(record) {
      const link = document.createElement("a");
      link.href = record.enclosure;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
    handlesFileClick(record) {
      const link = document.createElement("a");
      link.href = record.enclosure;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
    handleCurrentChange() {},
    handleSizeChange() {},
    handleChange() {},
    hanSizeChange() {},
    //应急联络分页
    handlegeidesChange() {},
    hanSizegeidesChange() {},
    changes(val) {
      this.num = val;
    },
    onSubmit() {},
    //启动
    launch() {
      // this.$refs.disposal.handleOpen()
      this.final.responseUserName = this.user.realName;
      this.final.responseUserUuid = this.user.uuid;
      this.final.responseRecordUuid = this.detaltes.uuid;
      this.final.responseType = "start_plan";
      this.final.responseProcessName = "启动预案";

      this.titesle = "启动预案";
      finalQuote(this.final).then(res => {
        console.log(res);
      });
      this.$refs.plantes.handleClose();
      this.allinputs = "";
      this.final = {};
    },
    partel() {
      this.$refs.disposal.handleClose();
    },
    launchset() {
      this.$refs.plantes.handleClose();
      this.allinputs = "";
      this.final = {};
    },
    //响应升级确定和取消
    upgrade() {
      this.finelse.responseUserName = this.user.realName;
      this.finelse.responseUserUuid = this.user.uuid;
      this.finelse.responseType = "response_upgrade";
      this.finelse.responseRecordUuid = this.detaltes.uuid;
      this.finelse.levelName = this.form.currentLevel;

      this.finelse.responseProcessName = "响应升级";
      Upgrade(this.form).then(res => {
        if (res.code == 0) {
          this.$message({
            message: "响应升级成功",
            type: "success"
          });
          this.form = {};
          this.$refs.qupes.handleClose();
        }
        console.log(res);
      });

      finalQuote(this.finelse).then(res => {
        console.log(res);
      });
    },
    upgradeset() {
      this.form = {};
      this.$refs.qupes.handleClose();
    },
    //结束响应确定和取消
    espond() {
      this.$refs.espond.handleClose();
      this.$router.push("/index");
    },
    espondset() {
      this.$refs.espond.handleClose();
    },
    ///应急预警确定和取消
    warningmi() {
      console.log("poo000000");
      this.options.forEach(el => {
        if (el.id == this.warning.warnLevel) {
          this.warninges.levelName = el.label;
        }
      });
      this.warninges.responseUserName = this.user.realName;
      this.warninges.responseUserUuid = this.user.uuid;
      this.warninges.responseType = "start_warn";
      this.warninges.responseRecordUuid = this.detaltes.uuid;
      this.warninges.responseProcessName = "应急预警";
      warningete(this.warning).then(res => {
        console.log(res.code);

        this.$message({
          message: "应急预警成功",
          type: "success"
        });

        this.$refs.warning.handleClose();
        this.warning = {};
      });
      finalQuote(this.warninges).then(res => {
        console.log(res);
      });
      //  this.$refs.warning.handleClose()
    },
    warningset() {
      this.$refs.warning.handleClose();
      this.warning = {};
    },
    //选择启动预警和结束预警
    radioChange() {
      console.log(this.warning.warnState);
      if (this.warning.warnState == "2") {
        this.Level = true;
      } else {
        this.Level = false;
      }
    },

    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.label == "全部预案") {
        EmergencyPageList({
          pageNum: this.pages.pageNo,
          pageSize: this.pages.pageSize
        }).then(res => {
          console.log(res);
          this.gridlists = res.data.records;
          this.pages.pageNo = res.data.current;
          this.pages.pageSize = res.data.size;
          this.pages.total = res.data.count;
          this.pages.count = res.data.total;
        });
      }
    },
    handleSelectionChange(val) {
      this.final.disposal = val[0].fileName;
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  .sidebar_item {
    width: 72px;
    height: 72px;
    background: #071743;
    border: 1px solid #3e9eff;
    box-shadow: 0px 0px 30px 0px rgba(62, 158, 255, 0.6);
    opacity: 0.7;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    p {
      color: #43a5e9;
      font-size: 14px;
      line-height: 4px;
      text-align: center;
    }
    .items {
      width: 20px;
      height: 20px;
      margin: auto;
      margin-top: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.tabes {
  width: 95%;
  height: 35px;

  display: flex;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
}
.toobte {
  width: 90%;
  height: 200px;
  border: 1px solid #143363;
  margin: auto;
  margin-top: 20px;
  color: #ffffff;

  .title {
    font-size: 15px;
    text-align: center;
  }
}
p {
  margin-top: 20px;
  margin-left: 2%;
  color: #000000;
}
::v-deep .el-input-group__append {
  background: #389ce4;
  color: #ffffff;
}
::v-deep .el-input__inner {
}
::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
  border: 1px solid #002e75;
  background-color: #081742;
  color: white;
  margin-top: 20px;
}
::v-deep .el-table tbody tr:hover > td {
  background-color: #081742 !important;
  border-color: #143363;
}
.el-table::before {
  height: 0;
}
.el-table {
  margin-top: 30px;
}
//  ::v-deep .el-input__inner {
//       background: #ffffff;
//       border:1px solid #9999a0;
//     }
::v-deep.input_cer .el-input__inner {
  height: 36px;
  border: none;
  background: #18467f;
}
::v-deep .el-textarea__inner {
  background: #081742;
  border-color: #143363;
}
::v-deep .el-input__inner {
  background: #081742;
  border-color: #143363;
  color: #ffffff;
}
::v-deep .el-checkbox__inner {
  background-color: #081742 !important;
  border: 1px solid #143363;
}
.el-table--border::after,
.el-table--group::after {
  width: 0;
}
::v-deep .el-tabs__item.is-active {
  color: #409eff;
}
::v-deep .el-tabs__item {
  color: #ffffff;
}
.bottwr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.time {
  color: #ffffff;
  display: flex;
  line-height: 70px;
  margin-top: 20px;
  font-size: 15px;
}
.temwer {
  width: 470px;
  font-size: 15px;
  color: #ffffff;
  height: 70px;
  border: 1px solid #143363;
  margin-left: 10px;
  text-indent: 10px;
}
::v-deep .el-form-item__label {
  color: #ffffff;
}
::v-deep [data-v-3fa6cbb6] .el-textarea__inner {
  color: #ffffff;
}
</style>
<style>
.warningets .el-popper {
  background-color: #081742 !important;
  border: 1px solid #143363;
}
.warningets .el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #081742;
}
</style>
