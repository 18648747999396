<template>
  <div class="page-container bg-white p-4 h-full"   >
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item>
        <el-cascader
          placeholder="工点选择"
          v-model="lineSite"
          :show-all-levels="false"
          :options="treeData"
          :props="props"
          collapse-tags
          clearable
          @change="handleLineSiteChange"
        ></el-cascader>
      </el-form-item>

      <el-form-item>
        <el-date-picker
          v-model="value1"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="handleDateChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select v-model="pserty.nightShift" placeholder="班次选择">
          <el-option label="白班" value="false"></el-option>
          <el-option label="晚班" value="true"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="pserty.onDutyInt" placeholder="状态选择">
          <el-option
            v-for="item in states"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="pserty.realName"
          placeholder="请输入姓名搜索"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="reset">重置</el-button>
      </el-form-item>
      <el-form-item style="margin-right: 100px;float:right;">
        <el-button type="primary" @click="derive"  >导出</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" border style="width: 100%">
      <el-table-column type="index" label="序号" width="80"> </el-table-column>

      <el-table-column prop="dutyWorkerName" label="姓名" width="180">
      </el-table-column>
      <el-table-column prop="lineName" label="线路名称" width="180">
      </el-table-column>
      <el-table-column label="分部-工区名称">
        <template slot-scope="scope">
          {{ scope.row.branchName }}{{ scope.row.partitionName }}
        </template>
      </el-table-column>
      <el-table-column prop="siteName" label="工点名称"> </el-table-column>
      <el-table-column label="班次">
        <template slot-scope="scope">
          {{ scope.row.nightShift == true ? "晚班" : "白班" }}
        </template>
      </el-table-column>
      <el-table-column prop="clockInTime" label="打卡时间"> </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          {{
            scope.row.onDutyInt == 0
              ? "未到岗"
              : scope.row.onDutyInt == 1
              ? "已到岗"
              : "迟到"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="reason" label="原因"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-count="page.count"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { attendanceList,deletionList,deriveList } from "@/api/eres.js";
export default {
  data() {
    return {
      options: [],
      states: [
        {
          value: 0,
          label: "未到岗",
        },
        {
          value: 1,
          label: "已到岗",
        },
        {
          value: 2,
          label: "迟到",
        },
      ],
      formInline: {},
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true,
      },
      pserty: {},
      value1: [],
      lineSite: [],
      
    };
  },
  created() {
    this.getLineSiteTreeData();
    this.attendan();
  },
  methods: {
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
        console.log(this.treeData);
      }
    },
    handleLineSiteChange(val) {
      console.log(val);
      this.lineSite = val;
      this.pserty.siteId = this.lineSite[0];
    },
    // 时间选择
    handleDateChange(val) {
      if (val) {
        this.pserty.startTime = val[0];
        this.pserty.endTime = val[1];
      } else {
        this.pserty.startTime = "";
        this.pserty.endTime = "";
      }
    },
    //考勤列表
    attendan() {
      attendanceList({
        page: this.page.pageNo,
        size: this.page.pageSize,
      }).then((res) => {
        console.log(res);
        this.tableData = res.data.records;
        this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.page.total = res.data.count;
        this.page.count = res.data.total;
      });
    },
    //查询
    onSubmit() {
      this.pserty.page = this.page.pageNo;
      this.pserty.size = this.page.pageSize;
      attendanceList(this.pserty).then((res) => {
        console.log(res);
        this.tableData = res.data.records;
        this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.page.total = res.data.count;
        this.page.count = res.data.total;
      });
    },
    //重置
    reset() {
      this.pserty = {};
      this.attendan();
    },
    //导出
    derive() {
 const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      deriveList({}).then(res=>{
        console.log(res)
         loading.close();
        let blob = new Blob([res], {
				type: "application/vnd.ms-excel"
			});
			// 3.创建一个临时的url指向blob对象
			let url = window.URL.createObjectURL(blob);
			// 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
			let a = document.createElement("a");
			a.href = url;
			a.download = "导出数据_" + new Date().getTime() + ".xlsx"; //自定义导出文件名
			a.click();


      })
    },
    //删除
    handleDelete(val) {
      console.log(val)
       this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           deletionList({id:val.checkAttendanceId}).then(res=>{
        if(res.code==0){
           this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.attendan();
        }
      })
         
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
     
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.attendan()
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.attendan()
    },
  },
};
</script>

<style>
</style>