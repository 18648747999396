<template>
  <div>
    <el-form
      :model="formData"
      class="demo-form-inline"
      :rules="rules"
      ref="ruleForm"
      v-loading="fromLoading"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="当前应急响应：">
           <span
              >{{ temList.type ? temList.type.label : ""
              }}{{
                temList.currentLevel ? temList.currentLevel.label : ""
              }}级响应</span
            >
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="响应结束时间" prop="endTime">
            <el-date-picker
              v-model="formData.endTime"
              type="datetime"
              placeholder="请选择"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="应急响应结束详细内容" prop="endRemark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 8, maxRows: 16 }"
              v-model="formData.endRemark"
              placeholder="多行输入"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="display: flex; justify-content: center">
      <el-button type="primary" @click="sureSub('ruleForm')">确认</el-button>
    </div>
  </div>
</template>

<script>
import { ResFinish } from "@/api/responseTem";
export default {
  props: {
    temList: {
      type: Object,
      default: () => {},
    },
    leveloptions: {},
  },
  data() {
    return {
      dialogVisible: true,
      fromLoading: false,
      formData: {
        id: "",
        endTime: "",
        endRemark: "",
      },
      rules: {
        endTime: [
          {
            type: "string",
            required: true,
            message: "请选择结束时间",
            trigger: "change",
          },
        ],
        endRemark: [
          {
            type: "string",
            required: true,
            message: "请填写详细内容",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
     this.formData = {
      id: this.temList.id,
      endTime: "",
      endRemark: ""
    };
  },
  methods: {
    sureSub(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fromLoading = true;
          let params = this.formData;
          ResFinish(params).then((res) => {
            if (res.code == 0) {
              this.fromLoading = false;
              this.$message.success("操作成功");
              this.$emit("endClose",true);
            } else {
              this.fromLoading = false;
              this.$message.error(res.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
