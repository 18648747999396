<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="type == 'view' ? viewRule : rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-model"
    >
      <el-row>
        <el-col :span="24">
          <h3
            class="font-black mb-4"
            style="font-size:14px"
            v-if="rowData.state == 6 || dbType == '整改'"
          >
            施工单位提交整改
          </h3>
          <h3 class="font-black mb-4" style="font-size:14px" v-else>
            施工单位申请验收
          </h3>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="rowData.state == 6 ? '整改人' : '申请人'"
            prop="userName"
          >
            <el-input readonly v-model="ruleForm.userName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="rowData.state == 6 ? '整改时间' : '申请时间'"
            prop="operationTime"
            v-if="type != 'view'"
          >
            <el-input v-model="ruleForm.operationTime" readonly></el-input>
          </el-form-item>
          <el-form-item
            :label="rowData.state == 6 ? '整改时间' : '申请时间'"
            prop="operationTime"
            v-if="type == 'view'"
          >
            <el-input v-model="ruleForm.operationTime" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            :label="rowData.state == 6 ? '整改描述' : '申请描述'"
            prop="remark"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
              v-model="ruleForm.remark"
              resize="none"
              :readonly="type == 'view' ? true : false"
              :placeholder="rowData.state == 6 ? '整改描述' : '申请描述'"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="附件" prop="fileList">
            <FileUploader
              v-if="type != 'view'"
              @uploadChange="handleFileUpload"
              @delChange="handleFileDel"
              :value="fileList"
            ></FileUploader>
            <ul v-if="type == 'view'" class="fileGroupBox">
              <li v-for="(item, i) in ruleForm.fileList" :key="i">
                <span v-if="rowData.state == 6"
                  >《风险管控关键节点实施前条件验收整改情况确认表》</span
                >
                <span v-else>《风险管控关键节点实施前条件验收申请表》</span>
                <span @click="handleFileClick(item)">预览</span>
                <span @click="handleExport(item.fileRealUrl, item.fileName)"
                  >导出</span
                >
              </li>
            </ul>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="dialogFooter mt-4" v-if="type != 'view'">
      <el-button
        v-if="rowData.state == 6 || dbType == '整改'"
        size="medium"
        type="primary"
        @click="submit('4')"
        >提交整改</el-button
      >
      <el-button v-else size="medium" type="primary" @click="submit('1')"
        >提交申请</el-button
      >
    </div>
  </div>
</template>

<script>
import { nodeCheckAdd, nodeStateRecordDetail, download } from "@/api/riskManage";
import dayjs from "dayjs";
import FileUploader from "@/components/Uploader/FileUploader.vue";
export default {
  components: {
    FileUploader
  },
  props: {
    rowData: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ""
    },
    jlView: {
      type: String,
      default: ""
    },
    dbType: {
      type: String,
      default: "验收"
    }
  },
  data() {
    return {
      ruleForm: {
        companyName: "",
        companyUuid: "",
        userName: "",
        userUuid: "",
        companyTypeName: "",
        companyTypeCode: "",
        departmentName: "",
        departmentUuid: "",
        fileList: [],
        operationTime: "",
        remark: "",
        nodeUuid: "",
        nodeName: "",
        id: "",
        recordType: 1,
        eventId: "",
      },
      rules: {
        remark: [{ required: true, message: "请输入描述", trigger: "blur" }],
        fileList: [{ required: true, message: "请上传附件", trigger: "change" }]
      },
      viewRule: {},
      isRequire: false,
      lineSiteData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid"
      },
      selloading: false,
      options: [],
      fileList: [], // 附件列表
      siteList: [], // 层级站点树
      faltArr: [], //站点数平行数据
      photoList: [] //数组图片列表
    };
  },
  watch: {
    rowData() {
      if (this.type == "view") {
        this.ruleForm = this.rowData;
      }
    }
  },
  mounted() {
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      this.ruleForm.companyName = user.companyName;
      this.ruleForm.companyUuid = user.companyId;
      this.ruleForm.userName = user.realName;
      this.ruleForm.userUuid = user.uuid;
      this.ruleForm.companyTypeName = user.companyCategoryName;
      this.ruleForm.companyTypeCode = user.companyCategory;
      this.ruleForm.departmentName = user.departmentName;
      this.ruleForm.departmentUuid = user.departmentId;
    }
    // console.log(this.rowData.eventId, "ssssssssss");
    if (this.type != "view") {
      this.ruleForm.operationTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.ruleForm.id = this.rowData.id;
      this.ruleForm.nodeUuid = this.rowData.nodeUuid;
      this.ruleForm.nodeName = this.rowData.nodeName;
    } else {
      if (this.jlView != "jl") {
        this.getDetails();
      }
    }
    if (this.rowData.eventId) {
      this.ruleForm.eventId = this.rowData.eventId;
    }
    this.getLineTree();
  },
  methods: {
    getDetails() {
      // this.filter.nodeUuid = this.rowData.nodeUuid;
      console.log(this.rowData.eventId, "ggggggggggg");
      let data = { ...this.filter };
      const _this = this;
      data.keyNodesType = 0;
      nodeStateRecordDetail({ id: this.rowData.eventId }).then(res => {
        if (res.code == 0) {
          // _this.ruleForm = res.data;
          // console.log(res.data, "fata");
          if (res.data.length > 0) {
            // debugger
            // console.log(res.data[res.data.length - 1],"ssssssssssss");
            res.data.forEach(el => {
              if (_this.dbType == "申请" && el.recordType == 1) {
                _this.ruleForm = el;
              } else if (_this.dbType == "整改" && el.recordType == 4) {
                _this.ruleForm = el;
              }
            });
          }
        }
      });
    },
    async getLineTree() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.lineSiteData = JSON.parse(JSON.stringify(lineSiteData));
        this.lineSiteData.forEach(item => {
          this.flatArray(item);
        });
      }
    },
    flatArray(data) {
      this.faltArr.push(data);
      if (data.children && data.children.length > 0) {
        data.children.forEach(item => {
          this.flatArray(item);
        });
      }
    },
    handleFileClick(record) {
      const link = document.createElement("a");
      link.href = record.fileUrl;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
    handleExport(url, name) {
      console.log(url);
      let list = [
        { name: "doc", value: "application/msword" },
        {
          name: "docx",
          value:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        },
        { name: "xls", value: "application/vnd.ms-excel" },
        {
          name: "xlsx",
          value:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        { name: "ppt", value: "application/vnd.ms-powerpoint" },
        {
          name: "pptx",
          value:
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        },
        { name: "pdf", value: "application/pdf;chartset=UTF-8" }
      ];
      let fileType = url.split(".")[1];
      let type = null;
      list.forEach(el => {
        if (el.name == fileType) {
          type = el.value;
        }
      });
      download(url).then(res => {
        const aLink = document.createElement("a");
        let blob = null;
        if (fileType == "txt") {
          blob = new Blob([res]);
        } else if (
          fileType == "png" ||
          fileType == "jpg" ||
          fileType == "jpeg"
        ) {
          blob = new Blob([res]);
        } else {
          blob = new Blob([res], { type: type });
        }
        aLink.href = URL.createObjectURL(blob);
        aLink.download = name + "." + fileType;
        aLink.click();
        document.body.appendChild(aLink);
      });
    },
    handleLineSiteChange(val) {
      // console.log(val, "val");
      if (val && val.length > 0) {
        this.ruleForm.lineUuid = val[0];
        this.ruleForm.branchUuid = val[1];
        this.ruleForm.siteUuid = val[val.length - 1];
        this.getSiteConsUnit(this.ruleForm.siteUuid);
        let site = this.faltArr.find(
          item => item.uuid == this.ruleForm.siteUuid
        );
        let line = this.faltArr.find(
          item => item.uuid == this.ruleForm.lineUuid
        );
        let branch = this.faltArr.find(
          item => item.uuid == this.ruleForm.branchUuid
        );
        this.ruleForm.siteName = site.name;
        this.ruleForm.lineName = line.name;
        this.ruleForm.branchName = branch.name;
      }
    },
    // 附件上传
    handleFileUpload({ name, url, realUrl }) {
      this.fileList.push({ name: name, url: url, fileRealUrl: realUrl });
      this.ruleForm.fileList.push({
        fileName: name,
        fileUrl: url,
        fileRealUrl: realUrl
      });
    },
    handleFileDel({ name, url }) {
      console.log(name);
      let index = this.fileList.findIndex(v => v.url === url);
      this.fileList.splice(index, 1);
      this.ruleForm.fileList.splice(index, 1);
    },

    cancel() {
      this.rolesData = [];
      this.defaultCheckedKeys = [];
      this.reset();
      this.$emit("CloseData");
    },
    reset() {
      const th = this;
      Object.keys(this.ruleForm).forEach(function(key) {
        th.ruleForm[key] = "";
      });
      // this.$refs.ruleForm.resetFields();
    },
    submit(type) {
      console.log(this.nodeId);
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.ruleForm.recordType = type;
          nodeCheckAdd(this.ruleForm).then(res => {
            if (res.code == 0) {
              this.$message.success("操作成功");
              this.reset();
              this.$emit("submitClose");
            } else {
              this.$message.error("操作失败");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background-color: none !important;
}
.imgGroupBox {
  display: flex;
  flex-wrap: wrap;
}
.huixianImg {
  width: 148px;
  height: 148px;
  margin-left: 15px;
}
.huixianImg:first-child {
  margin-left: 0;
}
.fileGroupBox {
  span {
    color: #3fa5e9;
    cursor: pointer;
    margin-left: 10px;
  }
  span:first-child {
    color: #606266;
  }
}
</style>
