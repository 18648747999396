<template>
  <systemModal
    ref="formModal"
    @modalConfirm="handleSubmit"
    :title="title"
    width="900px"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="120px"
      class="demo-model"
    >
      <el-form-item label="线路工点" prop="lineSite">
        <el-cascader
          style="width: 100%"
          v-model="model.lineSite"
          :options="lineSiteData"
          :props="props"
          @change="handleLineSiteChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="姓名" prop="realName">
        <el-select
          style="width: 100%"
          v-model="model.realName"
          placeholder="请选择姓名"
          @change="handleUserChange"
        >
          <el-option
            v-for="item of dictOption.userName"
            :key="item.uuid"
            :label="item.realName"
            :value="item.realName"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单位" prop="vendor">
        <el-input
          v-model="model.vendor"
          placeholder="请输入单位"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="角色" prop="roleName">
        <el-input
          v-model="model.roleName"
          placeholder="请输入角色"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="所在区" prop="districtId">
        <el-select
          style="width: 100%"
          v-model="model.districtId"
          placeholder="请选择所在区"
          @change="districtChange"
        >
          <el-option
            v-for="item of dictOption.district"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input
          v-model="model.phone"
          placeholder="请输入联系电话"
          readonly
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="值班人员分组" prop="grouping">
        <el-select v-model="model.grouping" placeholder="请选择分组">
          <el-option v-for="item of dictOption.grouping" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item> -->
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { addWorker, editWorker, queryGroup, userInfo } from "@/api/eres";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { queryOrgPageList } from "@/api/org";
// import { lineSiteTreeCascadeEcho } from "@/utils/common"
import { getRoleList } from "@/api/role";
import { listAccInfoBySiteUuidAndUnit } from "@/api/resource";
import { accountList, account } from "@/api/ucenter";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "新增",
      model: {
        lineSite: [],
        line: "",
        lineId: "",
        site: "",
        siteId: "",
        phone: "",
        realName: "",
        roleId: "",
        roleName: "",
        type: "",
        userId: "",
        vendor: "",
        vendorCategory: "",
        vendorCategoryName: "",
        vendorId: "",
        districtId: "",
      },
      lineSiteData: [],
      props: {
        children: "children",
        label: "name",
        value: "id",
      },
      dictOption: {
        grouping: [],
        department: [], // 职位
        vendor: [], // 单位
        district: [], // 所在区
        roles: [],
        userName: [],
      },
      loading: false,
      faltSiteList: [], //线路树的平行数组
      rules: {
        lineSite: [
          { required: true, message: "请选择线路工点", trigger: "change" },
        ],
        realName: [
          { required: true, message: "请输入姓名", trigger: "change" },
        ],
        vendor: [{ required: true, message: "请选择单位", trigger: "change" }],
        roleName: [
          { required: true, message: "请选择角色", trigger: "change" },
        ],
        districtId: [
          { required: true, message: "请选择所在区", trigger: "change" },
        ],
        grouping: [
          { required: true, message: "请选择分组", trigger: "change" },
        ],
      },
      lineSite: [],
    };
  },
  mounted() {
    // const params = {
    //   label: "-",
    //   parent: "-",
    //   canModify: "-",
    //   page: 1,
    //   size: 1000,
    // }
    // queryDictList(params)
    // this.dictOption.department = this.getDictData();
    // this.dictOption.vendor = this.getDictData();
    this.getDictData("广州市辖区", this.dictOption.district);
    this.queryOrgData();
    this.queryLineSiteTreeData();
    // this.queryGroup();
  },
  methods: {
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    async getRoleList(val) {
      this.dictOption.roles = [];
      const params = {
        name: "-",
        companyId: val,
        departmentId: "-",
        parentId: "-",
      };
      const res = await getRoleList(params);
      if (res.code == 0) {
        this.dictOption.roles = res.data;
      }
    },
    // 查询单位列表
    queryOrgData() {
      const params = {
        category: "-",
        name: "-",
        pageNo: 1,
        pageSize: 1000,
      };
      queryOrgPageList(params).then((res) => {
        if (res.code == 0) {
          this.dictOption.vendor = res.data.records;
        }
      });
    },
    // 根据指定id查询部门
    handleVendorChange(val) {
      // 清空数据
      this.dictOption.department = [];
      if (this.title == "新增") {
        if (this.model.roleId) {
          this.model.roleId = "";
        }
      }
      const tempObj = this.dictOption.vendor.find((item) => item.id == val);
      this.getRoleList(tempObj.uuid);
    },
    // 查询人员分组
    queryGroup() {
      queryGroup({}).then((res) => {
        if (res.code == 0) {
          this.dictOption.grouping = res.data;
        }
      });
    },
    // 线路工点树数据查询
    queryLineSiteTreeData() {
      this.loading = true;
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.lineSiteData = lineSiteData;
        this.loading = false;

        this.lineSiteData.forEach((element) => {
          this.faltSiteList.push(element);
          if (element.children && element.children.length > 0) {
            this.faltFun(element.children);
          }
        });
      }
    },
    faltFun(data) {
      data.forEach((item) => {
        this.faltSiteList.push(item);
        if (item.children && item.children.length > 0) {
          this.faltFun(item.children);
        }
      });
    },
    // 线路工点树选择
    handleLineSiteChange(val) {
      console.log(val);
      this.model.lineId = val[0];
      this.model.siteId = val[val.length - 1];
      let site = this.faltSiteList.find((item) => item.id == this.model.siteId);
      let line = this.faltSiteList.find((item) => item.id == this.model.lineId);
      console.log(site, line);
      this.model.line = line.name;
      this.model.site = site.name;
      this.getUser(site.uuid);
    },
    getUser(uuid) {
      // 查询工点下边的人员的UUID列表
      listAccInfoBySiteUuidAndUnit({ siteUuid: uuid }).then((res) => {
        if (res.code == 0) {
          let userListUuid = res.data;
          if (userListUuid.length > 0) {
            accountList(userListUuid).then((datas) => {
              //人员的下拉框
              this.dictOption.userName = datas.data;
            });
          }
        }
      });
    },
    //人员选择
    handleUserChange(val) {
      console.log(this.dictOption.userName);
      let user = this.dictOption.userName.find((i) => i.realName == val);
      console.log(user);
      account({ uuid: user.uuid }).then((res) => {
        console.log(res);
        this.model.phone = res.data.mobile;
        this.model.userId = res.data.uuid;
        this.model.vendor = res.data.companyName;
        this.model.vendorId = res.data.companyId;
        this.model.vendorCategory = res.data.companyCategory;
        this.model.vendorCategoryName = res.data.companyCategoryName;
        if (res.data.roles && res.data.roles.length > 0) {
          this.model.roleId = res.data.roles[0].id;
          this.model.roleName = res.data.roles[0].roleName;
        }
        console.log(
          this.model,
          "人员选择参数",
          res.data.companyCategory,
          res.data,
          res.data.companyCategoryName
        );
        /*  let roleId = []
         let roleName = []
         if (res.data.roles && res.data.roles.length > 0) {
           res.data.roles.forEach(i => {
             roleId.push(i.id)
             roleName.push(i.roleName)
           })
         }
 
         this.model.roleId = roleId
         this.model.roleName = roleName.join(',') */
      });
    },
    districtChange(val) {
      let dist = this.dictOption.district.find((i) => i.id == val);
      this.model.district = dist.label;
    },
    lineSiteFun(puuid) {
      let obj = this.faltSiteList.find((i) => i.uuid == puuid);
      console.log(obj);
      this.lineSite.unshift(obj.id);
      if (obj.id != this.model.lineId) {
        this.lineSiteFun(obj.puuid);
      }
    },
    handleAdd(type, record) {
      this.lineSite = [];
      if (record) {
        this.title = "编辑";
        userInfo(record.id).then((res) => {
          if (res.code == 0) {
            this.model = Object.assign(
              {},
              JSON.parse(JSON.stringify(res.data))
            );
            // this.getUser(res.data.uuid)
            let site = this.faltSiteList.find((i) => i.id == res.data.siteId);
            this.lineSite.unshift(site.id);
            this.lineSiteFun(site.puuid);
            this.$set(this.model, "lineSite", this.lineSite);
            console.log(
              this.model.lineSite,
              this.lineSite,
              this.model,
              "this.model.lineSite"
            );
          }
        });
        // this.model = Object.assign({}, JSON.parse(JSON.stringify(record)))
        // this.model.vendorId = parseInt(this.model.vendorId)
        // console.log(this.model.vendorId, typeof this.model.vendorId)
        // const treeEcho = lineSiteTreeCascadeEcho(record.siteId)
        // this.$set(this.model, "lineSite", treeEcho)
        // this.handleVendorChange(this.model.vendorId)
      } else {
        this.title = "新增";
        this.model = {
          departmentId: "",
          districtId: "",
          lineSite: [],
          line: "",
          lineId: "",
          site: "",
          siteId: "",
          phone: "",
          realName: "",
          roleId: "",
          roleName: "",
          type: "",
          userId: "",
          vendor: "",
          vendorCategory: "",
          vendorCategoryName: "",
          vendorId: "",
        };
      }
      this.model.type = type;
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
      if (this.title == "新增") {
        debugger;
        console.log(this.$refs.modelForm, this.$refs["modelForm"]);
        this.$refs["modelForm"].resetFields();
      }
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        console.log(this.model, "提交的参数");
        if (valid) {
          this.loading = true;
          if (this.model.id) {
            const params = {
              id: this.model.id,
              data: this.model,
            };
            editWorker(params)
              .then((res) => {
                if (res.code == 0) {
                  this.loading = false;
                  this.$message.success("操作成功");
                  this.$refs.formModal.handleClose();
                  this.$emit("formSubmit");
                }
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error("操作失败," + err.message);
              });
          } else {
            addWorker(this.model)
              .then((res) => {
                if (res.code == 0) {
                  this.loading = false;
                  this.$message.success("操作成功");
                  this.$refs.formModal.handleClose();
                  this.$emit("formSubmit");
                }
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error("操作失败," + err.message);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped></style>
