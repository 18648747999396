<template>
  <div
    class="dangers"
    style="width: 100vw; height: 100vh"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  ></div>
</template>

<script>
import { tripleTree, worksiteTree } from "@/api/map";
import { queryResourceByRole, queryResourceList } from "@/api/resource";
import { login } from "@/api/user";
export default {
  data() {
    return {
      params: {
        username: "",
        password: "",
      },
      loading: true,
    };
  },
  async created() {
    let eouet = window.self === window.top;
    console.log(eouet);
    if (eouet == false) {
      let message = {
        urlName: "风险隐患",
        url: "http://47.95.238.93:9998/dangers", //子页面接收消息页面地址
      };
      window.parent.postMessage(JSON.stringify(message),'*');
      window.addEventListener("message", function (event) {
        console.log(event);
        var base64Url = event.id_token.split(".")[1];
        var userinfo = JSON.parse(
          decodeURIComponent(
            escape(window.atob(base64Url.replace(/-/g, "+").replace(/_/g, "/")))
          )
        );
        console.log(userinfo);
      });
      this.loading = true;
    }
    let res = {};
    this.params.username = this.getQueryVariable("username");
    this.params.password = this.getQueryVariable("password");

    // this.params.username=this.$router.query.username
    // this.params.password=this.$router.query.password

    if (!this.params.password || !this.params.username) {
      this.$message.error("请正确输入用户名及密码");
      return;
    }
    this.loading = true;
    try {
      res = await login(this.params);
    } catch (error) {
      res.code = 9;
    }
    // res.code = 0
    if (res.code === 0) {
      let now = new Date();
      // save user info & jwtToken
      localStorage.setItem("user", JSON.stringify(res.data));
      localStorage.setItem("jwtToken", res.data.jwtToken);
      localStorage.setItem(
        "jwtTokenExpire",
        now.getTime() + 3 * 60 * 60 * 1000
      );
      // localStorage.removeItem("lineSiteTree");//登录时清理线路local
      // fetch permission
      // let permission = new Permission();
      // await permission.init(true);
      // attach Vue prototype
      // Vue.prototype.$permission = permission;
      // let operation = new Operation();
      // await operation.init();
      // Vue.prototype.$operation = operation;
      // navigate
      Promise.all([this.combinedQuery(res.data), this.queryLineSite()]).then(
        (res) => {
          if (res) {
            this.$router.push({
              path: "/Screen/HiddenDanger/Index",
              query: { rte: 222 },
            });
            // this.$router.push("/Screen/Contingency/Index");
          }
        }
      );
    } else {
      this.$message.error("登录失败");
      this.loading = false;
    }
  },
  methods: {
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },

    /**
     * 查询角色的权限列表
     * @param {String} roleIds 角色集合字符串，逗号分隔
     */
    async queryRoleResource(roleIds) {
      let res = await queryResourceByRole(roleIds);
      if (res.code == 0) {
        return res.data;
      }
    },
    // 查询全部资源列表
    async queryResourceAll() {
      const params = {
        name: "-",
        parentId: "-",
        resourcePath: "-",
        type: "-",
      };
      let res = await queryResourceList(params);
      if (res.code == 0) {
        return res.data;
      }
    },
    // 菜单树初始化
    initMenuTree(resourceData) {
      let handleResource = (resource) => {
        let parent = resourceData.find((p) => p.id == resource.parentId);
        if (parent) {
          if (resource.parentId !== 0 && parent.children) {
            parent.children.push(resource);
          } else {
            parent.children = [resource];
          }
        } else {
          console.log(parent);
        }
      };
      resourceData.forEach((item) => handleResource(item));
      const result = resourceData.filter((item) => item.parentId === 0);
      return result;
    },
    // 查询线路工点树
    async queryLineSite() {
      let result = await tripleTree({});
      if (result.code == 0) {
        localStorage.setItem("lineSiteTree", JSON.stringify(result.data));
        return result;
      }
      worksiteTree({}).then((res) => {
        if (res.code == 0) {
          localStorage.setItem("DetailsTree", JSON.stringify(res.data));
        }
      });
    },
    // 找到第一个有效的菜单
    findValidMenu(resource) {
      if (resource.children) {
        return this.findValidMenu(resource.children[0]);
      } else {
        return resource;
      }
    },
    async combinedQuery(userInfo) {
      // 菜单权限列表
      const systemPermission = []; // 工作台菜单
      const screenPermission = []; // 大屏菜单
      // 按钮权限列表
      const btnPermission = [];
      // 具有的权限列表
      let hasPermission = [];
      const roleIds = userInfo.roleIdList.join(",");
      const [resource, rolePermission] = await Promise.all([
        this.queryResourceAll(),
        this.queryRoleResource({ roleIds }),
      ]);
      // 超级管理员
      if (userInfo.roleIdList.includes(1)) {
        for (let r of resource) {
          if (r.type == 0) {
            if (r.category == 0) {
              systemPermission.push(r);
            } else {
              screenPermission.push(r);
            }
          } else {
            btnPermission.push(r);
          }
        }
      } else {
        // 权限列去重
        const tempArr = [...rolePermission];
        let tempMap = new Map();
        for (let item of tempArr) {
          if (!tempMap.has(item.resourceId)) {
            tempMap.set(item.resourceId, item);
          }
        }
        hasPermission = [...tempMap.values()];
        // 过滤菜单权限和按钮权限
        for (let r of resource) {
          for (let p of hasPermission) {
            if (p.resourceId == r.id) {
              if (r.type == 0) {
                if (r.category == 0) {
                  systemPermission.push(r);
                } else {
                  screenPermission.push(r);
                }
              } else {
                btnPermission.push(r);
              }
            }
          }
        }
      }
      localStorage.setItem("resource", JSON.stringify(resource));
      localStorage.setItem("USER_AUTH", JSON.stringify(systemPermission));
      localStorage.setItem("SCREEN_AUTH", JSON.stringify(screenPermission));
      localStorage.setItem("USER_BTN_AUTH", JSON.stringify(btnPermission));
      const ScreenMenu = this.initMenuTree(screenPermission);
      if (ScreenMenu.length) {
        this.jumpMenu = this.findValidMenu(ScreenMenu[0]);
      } else {
        this.jumpMenu = {
          resourcePath: "/",
        };
      }
      localStorage.setItem("FIRST_MEUN", JSON.stringify(this.jumpMenu));
      return Promise.resolve("success");
    },
  },
};
</script>

<style>
</style>