<template>
  <div>
    <el-dialog
      type="text"
      :title="title"
      :visible.sync="breVisible"
      width="70%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :append-to-body="true"
      center
      class="abow_dialog"
      v-if="breVisible"
    >
      <div class="dildiv overflow-auto">
        <div class="mb-16" v-for="(el, index) in dateList" :key="index">
          <div class="flex justify-start">
            <div class="mr-8">
              检查工点：{{ el.lineName }} - {{ el.siteName }}
            </div>
            <div class="mr-8">检查人员：{{ el.checkPeople }}</div>
            <div>检查时间：{{ el.checkTime }}</div>
          </div>
          <div class="mt-4 mb-4">隐患问题及照片</div>
          <el-table
            :data="[el]"
            border
            canter
            class="mb-2"
            style="width: 99%; margin-left: 4px; margin-top: 8px"
          >
            <el-table-column prop="hiddenQuestion" label="检查问题">
            </el-table-column>
            <el-table-column prop="uri" label="检查照片">
              <template slot-scope="scope">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="scope.row.uri"
                  :preview-src-list="[scope.row.uri]"
                >
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="correctiveApproveStatus" label="整改状态">
              <template slot-scope="scope">
                <span>{{ types[scope.row.correctiveApproveStatus] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    bruefing: {
      type: Boolean,
      default: () => false,
    },
    rows: {
      type: Object,
      default: () => {},
    },
    // briefi: {
    //   type: Object,
    //   default: () => {}
    // }
  },
  watch: {},
  data() {
    return {
      title: "三防工作简报",
      breVisible: false,
      types: ["待整改", "待监理审批", "审批不通过", "完成"],
      //多选
      dateList: [],
    };
  },
  methods: {
    handleFilling(record, state) {
      console.log(state);
      if (state == "read") this.readState = true;
      console.log(record);
      this.breVisible = true;
      this.dateList = record;
    },
    handleClose() {
      this.breVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dildiv {
  height: 600px;
  color: #000;
  font-weight: bold;
}
</style>
