<template>
  <div class="briefing">
    <div class="briefong_auto" v-loading="loading">
      <!-- 一、水雨情的台风情况 -->
      <div class="briefong_one">
        <div class="typhoon">一、水雨情和台风情况</div>
        <el-form
          :model="briefing.weatherOrOnDuty"
          ref="numberValidateForm"
          class="viewForm"
          :disabled="true"
        >
          <el-row>
            <el-col :span="24">
              <el-form-item label="广州市是否发布雨情或台风预警信号：">
                <span>{{
                  briefing.weatherOrOnDuty.warnFlag ? "有" : "无"
                }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="briefing.weatherOrOnDuty.warnFlag">
            <el-col :span="24">
              <el-form-item label="预警信号：">
                <span>{{ briefing.weatherOrOnDuty.warnDescription }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="项目所在行政区是否发布雨情或台风预警信号：">
                <span>{{
                  briefing.weatherOrOnDuty.regionWarnFlag ? "有" : "无"
                }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="briefing.weatherOrOnDuty.regionWarnFlag">
            <el-col :span="24">
              <el-form-item label="预警信号：">
                <span>{{
                  briefing.weatherOrOnDuty.regionWarnDescription
                }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="是否雨情期间：">
                <span>{{
                  briefing.weatherOrOnDuty.rainFlag ? "是" : "否"
                }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="briefing.weatherOrOnDuty.rainFlag">
              <el-form-item label="平均降雨量：">
                <span>{{ briefing.weatherOrOnDuty.avgRainfall }}mm</span>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="briefing.weatherOrOnDuty.rainFlag">
              <el-form-item label="最大降雨量">
                <span>{{ briefing.weatherOrOnDuty.maxRainfall }}mm</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="是否台风期间：">
                <span>{{
                  briefing.weatherOrOnDuty.windFlag ? "是" : "否"
                }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="6" v-if="briefing.weatherOrOnDuty.windFlag">
              <el-form-item label="台风名：">
                <span>{{ briefing.weatherOrOnDuty.windName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="5" v-if="briefing.weatherOrOnDuty.windFlag">
              <el-form-item label="台风等级：">
                <span>{{ briefing.weatherOrOnDuty.windLevel }}级</span>
              </el-form-item>
            </el-col>
            <el-col :span="5" v-if="briefing.weatherOrOnDuty.windFlag">
              <el-form-item label="最大风速：">
                <span>{{ briefing.weatherOrOnDuty.maxWindSpeed }}m/s</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-divider></el-divider>
      <!-- 二、防御情况 -->
      <div class="briefong_one">
        <div class="defense">二、防御情况</div>
        <el-form
          :model="briefing.defense.defense"
          ref="ValidateForm"
          class="viewForm"
          :disabled="true"
        >
          <el-row>
            <el-col :span="10">
              <el-form-item label="应急响应启动时间：">
                <span>{{ briefing.defense.startTime }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="类型：">
                <span>{{ briefing.defense.responseType.label }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="级别：">
                <span>{{ briefing.defense.responseLevel.label }}级</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="是否升级或降级：">
                <span>{{ briefing.defense.relegationFlag ? "是" : "否" }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt-8">
            <el-col :span="24">
              <el-form-item label="1、地铁集团、建设公司领导检查：">
                <div
                  class="titcens"
                  @click="
                    jumpDeail(
                      briefing.defense.leaderCheckInfo
                        .threeCheckItemQuestionList
                    )
                  "
                >
                  查看详情 ＞
                </div>
                <div class="content">
                  <!-- <el-form
                        :inline="true"
                        :model="briefing.defense.leaderCheckInfo"
                        class="demo-form-inline"
                      >
                        <el-form-item
                          label="检查人员"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            v-model="
                              briefing.defense.leaderCheckInfo.checkPeoples
                            "
                            :disabled="true"
                            @input="theOneChange($event)"
                            size="mini"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="检查时间"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-date-picker
                            size="mini"
                            style="width: 180px"
                            v-model="briefing.defense.leaderCheckInfo.checkTime"
                            @input="theOneChange($event)"
                            type="datetime"
                            placeholder="选择时间"
                            value-format="yyyy-MM-DD HH:mm:ss"
                            :disabled="true"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-form> -->
                  <el-table
                    :data="briefing.defense.leaderCheckInfo.listData"
                    border
                    style="width: 99%; margin-left: 4px; margin-top: 8px"
                  >
                    <el-table-column
                      prop="hiddenDangerCount"
                      label="排查出问题数量"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="rectificationCount"
                      label="已整改问题数量"
                    >
                    </el-table-column>
                  </el-table>

                  <!-- <div class="practicable" style="margin-left: 4px">
                        隐患问题及照片
                      </div>
                      <el-table
                        :data="
                          briefing.defense.leaderCheckInfo
                            .threeCheckItemQuestionList
                        "
                        border
                        canter
                        class="mb-2"
                        style="width: 99%; margin-left: 4px; margin-top: 8px"
                      >
                        <el-table-column prop="checkPeople" label="检查人员">
                          <template slot-scope="scope">
                            <el-input
                              v-model="scope.row.checkPeople"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="checkTime" label="检查时间">
                          <template slot-scope="scope">
                            <el-input
                              v-model="scope.row.checkTime"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="correctiveApproveStatus"
                          label="整改状态"
                        >
                          <template slot-scope="scope">
                            <el-input
                              v-model="types[scope.row.correctiveApproveStatus]"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="hiddenQuestion" label="检查问题">
                          <template slot-scope="scope">
                            <el-input
                              v-model="scope.row.hiddenQuestion"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="uri" label="检查照片">
                          <template slot-scope="scope">
                            <el-image
                              style="width: 50px; height: 50px"
                              :src="scope.row.uri"
                              :preview-src-list="[scope.row.uri]"
                            >
                            </el-image>
                          </template>
                        </el-table-column>
                      </el-table> -->
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt-8">
            <el-col :span="24">
              <el-form-item label="2、是否与外单位(街道、运营单位等)联合检查：">
                <div
                  class="titcens"
                  @click="
                    jumpDeail(
                      briefing.defense.externalUnitCheckInfo
                        .threeCheckItemQuestionList
                    )
                  "
                >
                  查看详情 ＞
                </div>
                <div class="content">
                  <!-- <el-form
                        :inline="true"
                        :model="briefing.defense.externalUnitCheckInfo"
                        class="demo-form-inline"
                        :disabled="readState"
                      >
                        <el-form-item
                          label="检查人员"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            v-model="
                              briefing.defense.externalUnitCheckInfo.checkPeoples
                            "
                            :disabled="true"
                            @input="theOneChange($event)"
                            size="mini"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="检查时间"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-date-picker
                            size="mini"
                            style="width: 180px"
                            v-model="
                              briefing.defense.externalUnitCheckInfo.checkTime
                            "
                            @input="theOneChange($event)"
                            type="datetime"
                            placeholder="选择时间"
                            value-format="yyyy-MM-DD HH:mm:ss"
                            :disabled="true"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-form> -->

                  <el-table
                    :data="briefing.defense.externalUnitCheckInfo.listData"
                    border
                    style="width: 99%; margin-left: 4px; margin-top: 8px"
                  >
                    <el-table-column
                      prop="hiddenDangerCount"
                      label="排查出问题数量"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="rectificationCount"
                      label="已整改问题数量"
                    >
                    </el-table-column>
                  </el-table>

                  <!-- <div class="practicable" style="margin-left: 4px">
                        隐患问题及照片
                      </div>
                      <el-table
                        :data="
                          briefing.defense.externalUnitCheckInfo
                            .threeCheckItemQuestionList
                        "
                        border
                        canter
                        class="mb-2"
                        style="width: 99%; margin-left: 4px; margin-top: 8px"
                      >
                        <el-table-column prop="checkPeople" label="检查人员">
                          <template slot-scope="scope">
                            <el-input
                              v-model="scope.row.checkPeople"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="checkTime" label="检查时间">
                          <template slot-scope="scope">
                            <el-input
                              v-model="scope.row.checkTime"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="correctiveApproveStatus"
                          label="整改状态"
                        >
                          <template slot-scope="scope">
                            <el-input
                              v-model="types[scope.row.correctiveApproveStatus]"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="hiddenQuestion" label="检查问题">
                          <template slot-scope="scope">
                            <el-input
                              v-model="scope.row.hiddenQuestion"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="uri" label="检查照片">
                          <template slot-scope="scope">
                            
                            <el-image
                              style="width: 50px; height: 50px"
                              :src="scope.row.uri"
                              :preview-src-list="[scope.row.uri]"
                            >
                            </el-image>
                          </template>
                        </el-table-column>
                      </el-table> -->
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt-8">
            <el-col :span="24">
              <el-form-item label="3、建管部领导检查">
                <div
                  class="titcens"
                  @click="
                    jumpDeail(
                      briefing.defense.constManageLeaderCheckInfo
                        .threeCheckItemQuestionList
                    )
                  "
                >
                  查看详情 ＞
                </div>

                <div class="content">
                  <!-- <el-form
                        :inline="true"
                        :model="briefing.defense.externalUnitCheckInfo"
                        class="demo-form-inline"
                        :disabled="true"
                      >
                        <el-form-item
                          label="检查人员"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            v-model="
                              briefing.defense.externalUnitCheckInfo.checkPeoples
                            "
                            @input="theOneChange($event)"
                            size="mini"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="检查时间"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-date-picker
                            size="mini"
                            style="width: 180px"
                            v-model="
                              briefing.defense.externalUnitCheckInfo.checkTime
                            "
                            @input="theOneChange($event)"
                            type="datetime"
                            placeholder="选择时间"
                            value-format="yyyy-MM-DD HH:mm:ss"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-form> -->

                  <el-table
                    :data="[briefing.defense.constManageLeaderCheckInfo]"
                    border
                    style="width: 99%; margin-left: 4px; margin-top: 8px"
                  >
                    <!-- <el-table-column
                          prop="checkSiteCount"
                          label="检查工点总数"
                        >
                        </el-table-column> -->
                    <el-table-column
                      prop="hiddenDangerCount"
                      label="检查出问题数量"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="rectificationCount"
                      label="已整改问题数量"
                    >
                    </el-table-column>
                  </el-table>

                  <!-- <div class="practicable" style="margin-left: 4px">
                        隐患问题及照片
                      </div>
                      <el-table
                        :data="
                          briefing.defense.externalUnitCheckInfo
                            .threeCheckItemQuestionList
                        "
                        border
                        canter
                        class="mb-2"
                        style="width: 99%; margin-left: 4px; margin-top: 8px"
                      >
                        <el-table-column prop="checkPeople" label="线路-工点">
                          <template slot-scope="scope">
                            <span
                              >{{ scope.row.lineName }} -
                              {{ scope.row.siteName }}</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column prop="checkPeople" label="检查人员">
                          <template slot-scope="scope">
                            <el-input
                              v-model="scope.row.checkPeople"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="checkTime" label="检查时间">
                          <template slot-scope="scope">
                            <el-input
                              v-model="scope.row.checkTime"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="correctiveApproveStatus"
                          label="整改状态"
                        >
                          <template slot-scope="scope">
                            <el-input
                              v-model="types[scope.row.correctiveApproveStatus]"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="hiddenQuestion" label="检查问题">
                          <template slot-scope="scope">
                            <el-input
                              v-model="scope.row.hiddenQuestion"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="uri" label="检查照片">
                          <template slot-scope="scope">
                            <el-image
                              style="width: 50px; height: 50px"
                              :src="scope.row.uri"
                              :preview-src-list="[scope.row.uri]"
                            >
                            </el-image>
                          </template>
                        </el-table-column>
                      </el-table> -->
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt-8">
            <el-col :span="24">
              <el-form-item label="4、本项目开展的检查情况：">
                <div
                  class="titcens"
                  @click="
                    jumpDeail(
                      briefing.defense.threeCheckItemQuestionList[0]
                        .threeCheckItemQuestionList
                    )
                  "
                >
                  查看详情 ＞
                </div>
                <div class="content">
                  <div>
                    <!-- <el-form
                  :inline="true"
                  :model="rew"
                  class="demo-form-inline"
                  :disabled="true"
                >
                  <el-form-item
                    label="检查人员"
                    style="margin-top: 8px; margin-left: 4px"
                  >
                    <el-input
                      v-model="rew.checkPeoples"
                      @input="theOneChange($event)"
                      size="mini"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="检查时间"
                    style="margin-top: 8px; margin-left: 4px"
                  >
                    <el-date-picker
                      size="mini"
                      style="width: 180px"
                      v-model="rew.checkTime"
                      @input="theOneChange($event)"
                      type="datetime"
                      placeholder="选择时间"
                      value-format="yyyy-MM-DD HH:mm:ss"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-form> -->

                    <el-table
                      :data="briefing.defense.threeCheckItemQuestionList"
                      border
                      style="width: 99%; margin-left: 4px; margin-top: 8px"
                    >
                      <el-table-column
                        prop="positionCount"
                        label="检查场所部位"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="hiddenDangerCount"
                        label="排查出问题数量"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="rectificationCount"
                        label="已整改问题数量"
                      >
                      </el-table-column>
                    </el-table>
                    <!-- <div class="practicable" style="margin-left: 4px">
                        隐患问题及照片
                      </div>
                      <el-table
                        :data="rew.threeCheckItemQuestionList"
                        border
                        canter
                        class="mb-2"
                        style="width: 99%; margin-left: 4px; margin-top: 8px"
                      >
                        <el-table-column prop="checkPeople" label="检查人员">
                          <template slot-scope="scope">
                            <el-input
                              v-model="scope.row.checkPeople"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="checkTime" label="检查时间">
                          <template slot-scope="scope">
                            <el-input
                              v-model="scope.row.checkTime"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="correctiveApproveStatus"
                          label="整改状态"
                        >
                          <template slot-scope="scope">
                            <el-input
                              v-model="types[scope.row.correctiveApproveStatus]"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="hiddenQuestion" label="检查问题">
                          <template slot-scope="scope">
                            <el-input
                              v-model="scope.row.hiddenQuestion"
                              :disabled="true"
                            >
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="uri" label="检查照片">
                          <template slot-scope="scope">
                           
                            <el-image
                              style="width: 50px; height: 50px"
                              :src="scope.row.uri"
                              :preview-src-list="[scope.row.uri]"
                            >
                            </el-image>
                          </template>
                        </el-table-column>
                      </el-table> -->
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div class="emergency font-black text-black mt-8" style="color:#fff">
          5、应急人员、设备物资准备情况
        </div>
        <div class="poert" style="margin-top: 20px">
          <div class="topwe">
            <el-form
              :model="briefing.defense"
              ref="dateForm"
              class="ruleForm viewForm"
              :disabled="true"
            >
              <div
                class="emer"
                v-for="(item, index) in briefing.defense.reportOnDutyInfoList"
                :key="index"
              >
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="应急人数：">
                      <el-input
                        style="width: 100px"
                        v-model="item.count"
                        placeholder="请输入内容"
                        @input="theOneChange($event)"
                      ></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="6">
                    <el-form-item>
                      <el-input
                        style="width: 150px"
                        v-model="item.name"
                        placeholder="请输入值守地点"
                        @input="theOneChange($event)"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-form>
          </div>
        </div>
        <div class="poert" style="margin-top: 12px">
          <div class="topwe">
            <el-form
              :model="briefing.defense.reportMaterialInfoList"
              ref="dateForm"
              class="ruleForm viewForm"
              :disabled="true"
            >
              <div
                class="emer"
                v-for="(item, index) in briefing.defense.reportMaterialInfoList"
                :key="index"
              >
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="物资设备：">
                      <el-select
                        v-model="item.materialId"
                        style="width: 100px"
                        clearable
                        filterable
                        @input="theOneChange($event)"
                      >
                        <el-option
                          v-for="it in equipment"
                          :key="it.id"
                          :label="it.label + '(' + it.model + ')'"
                          :value="it.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span="6">
                    <el-form-item>
                      <el-input
                        style="width: 150px"
                        v-model="item.count"
                        placeholder="输入数量"
                        @input="theOneChange($event)"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="物资设备存放地点：">
                      <el-input
                        style="width: 250px"
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        v-model="briefing.defense.materialAddress"
                        placeholder="输入地址"
                        @input="theOneChange($event)"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-form>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <!-- 三、受影响情况 -->
      <div class="briefong_one mt-8">
        <div class="defense">三、受影响情况</div>
        <el-radio-group
          v-model="briefing.affected.affectedFlag"
          :disabled="true"
        >
          <el-radio :label="false" style="margin-top: 12px"
            >本次{{ briefing.defense.responseType.label
            }}{{
              briefing.defense.responseLevel.label
            }}级应急响应期间，本单位所辖项目未受到影响，无突发事件发生，总体安全可控。</el-radio
          ><br />
          <el-radio :label="true" style="margin-top: 12px"
            >填写受影响情况</el-radio
          >
        </el-radio-group>
        <div class="defenserty" v-if="briefing.affected.affectedFlag">
          <div class="water">
            <span class="font-black text-black mr-2">施工项目是否积水:</span
            >{{ briefing.affected.sitePondingFlag ? "有" : "无" }}
          </div>
          <div v-if="briefing.affected.sitePondingFlag">
            <div
              class="conten_wer"
              v-for="(item, index) in briefing.affected.sitePondingList"
              :key="index + 2"
            >
              <div class="content">
                <el-form
                  :model="item"
                  class="demo-form-inline viewForm"
                  :disabled="true"
                >
                  <el-row>
                    <el-col :span="8">
                      <el-form-item
                        label="积水具体地点:"
                        style="margin-top: 8px; margin-left: 4px"
                      >
                        <span>{{ item.address }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item
                        label="最大积水深度:"
                        style="margin-top: 8px; margin-left: 4px"
                      >
                        <span>{{ item.value }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item
                        label="积水情况及影响:"
                        style="margin-top: 8px; margin-left: 4px"
                      >
                        <br />
                        <span>{{ item.description }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item
                        label=" 采取的应急措施及处理结果:"
                        style="margin-top: 8px; margin-left: 4px"
                      >
                        <br />
                        <span>{{ item.result }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
          <div class="water">
            <span class="font-black text-black mr-2"
              >施工项目是否受台风影响:</span
            >{{ briefing.affected.typhoonFlag ? "有" : "无" }}
          </div>
          <div>
            <div
              class="conten_wer"
              v-for="(item, index) in briefing.affected.typhoonList"
              :key="index + 3"
            >
              <div v-if="briefing.affected.typhoonFlag">
                <el-form
                  :model="briefing.affected.typhoonList"
                  class="demo-form-inline viewForm"
                  :disabled="true"
                >
                  <!-- <el-row>
                        <el-col :span="8">
                          <el-form-item
                            label="台风具体地点"
                            style="margin-top: 8px; margin-left: 4px"
                          >
                            <span>{{ item.address }}</span>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="最大风速"
                            style="margin-top: 8px; margin-left: 4px"
                            ><span>{{ item.value }}</span>
                          </el-form-item>
                        </el-col>
                      </el-row> -->
                  <el-row>
                    <el-col :span="24">
                      <el-form-item
                        label=" 台风情况及影响:"
                        style="margin-top: 8px; margin-left: 4px"
                        ><br /><span>{{ item.description }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item
                        label=" 采取的应急措施及处理结果:"
                        style="margin-top: 8px; margin-left: 4px"
                        ><br /><span>{{ item.result }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
          <div class="water">
            <span class="font-black text-black mr-2"
              >是否有其他原因引起的突发事件:</span
            >{{ briefing.affected.otherEffectFlag ? "有" : "无" }}
          </div>
          <div
            class="conten_wer"
            v-for="(item, index) in briefing.affected.otherEffectList"
            :key="index + 4"
          >
            <div v-if="briefing.affected.otherEffectFlag">
              <el-form
                :model="briefing.affected.otherEffectList"
                class="demo-form-inline viewForm"
                :disabled="true"
              >
                <!-- <el-row>
                      <el-col :span="8">
                        <el-form-item
                          label="事件具体地点"
                          style="margin-top: 8px; margin-left: 4px"
                          ><span>{{ item.address }}</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="事件原因"
                          style="margin-top: 8px; margin-left: 4px"
                          ><span>{{ item.value }}</span>
                        </el-form-item>
                      </el-col>
                    </el-row> -->
                <el-row>
                  <el-col :span="24">
                    <el-form-item
                      label=" 受其他原因引起的突发事件影响情况:"
                      style="margin-top: 8px; margin-left: 4px"
                      ><br /><span>{{ item.description }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item
                      label=" 采取的应急措施及处理结果:"
                      style="margin-top: 8px; margin-left: 4px"
                      ><br /><span>{{ item.result }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div>
        </div>
        <div class="water">
          <span class="font-black text-black mr-2" style="color:#fff"
            >是否有当前未能处理完毕事项的工作计划:</span
          >{{ briefing.affected.workPlanInFlag ? "有" : "无" }}
        </div>
        <div
          class="content border border-solid border-gray-200 p-4 mt-2"
          v-if="briefing.affected.workPlanInFlag"
        >
          <el-form
            :model="briefing.affected.workPlanInfo"
            class="demo-form-inline viewForm"
            :disabled="true"
          >
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="建管部责任人"
                  style="margin-top: 8px; margin-left: 4px"
                >
                  <el-select
                    v-model="
                      briefing.affected.workPlanInfo
                        .constManagDeptPersonInChargeUuid
                    "
                    size="mini"
                    style="width: 150px"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in jgList"
                      :key="item.uuid"
                      :label="item.realName"
                      :value="item.uuid"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="10">
                <el-form-item label="联系电话：" style="margin-top: 8px">
                  <el-input
                    size="mini"
                    style="width: 150px"
                    v-model="
                      briefing.affected.workPlanInfo
                        .constManagDeptPersonInChargePhone
                    "
                    oninput="if(value.length>5)value=value.slice(0,11)"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    maxlength="11"
                    placeholder="请输入联系电话"
                    @change="phoneVerifyJG"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="监理单位责任人"
                  style="margin-top: 8px; margin-left: 4px"
                >
                  <el-select
                    v-model="
                      briefing.affected.workPlanInfo.monitorPersonInChargeUuid
                    "
                    size="mini"
                    style="width: 150px"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in jlList"
                      :key="item.uuid"
                      :label="item.realName"
                      :value="item.uuid"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="10">
                <el-form-item label="联系电话：" style="margin-top: 8px">
                  <el-input
                    size="mini"
                    style="width: 150px"
                    v-model="
                      briefing.affected.workPlanInfo.monitorPersonInChargePhone
                    "
                    oninput="if(value.length>5)value=value.slice(0,11)"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    maxlength="11"
                    placeholder="请输入联系电话"
                    @change="phoneVerifyJL"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="施工单位责任人"
                  style="margin-top: 8px; margin-left: 4px"
                >
                  <el-select
                    v-model="
                      briefing.affected.workPlanInfo.constructPersonInChargeUuid
                    "
                    size="mini"
                    style="width: 150px"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in sgList"
                      :key="item.uuid"
                      :label="item.realName"
                      :value="item.uuid"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="10">
                <el-form-item label="联系电话：" style="margin-top: 8px">
                  <el-input
                    size="mini"
                    style="width: 150px"
                    v-model="
                      briefing.affected.workPlanInfo
                        .constructPersonInChargePhone
                    "
                    oninput="if(value.length>5)value=value.slice(0,11)"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    maxlength="11"
                    placeholder="请输入联系电话"
                    @change="phoneVerifySG"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label=" 工作计划:"
                  style="margin-top: 8px; margin-left: 4px"
                >
                  <br /><span>{{
                    briefing.affected.workPlanInfo.workPlan
                  }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <el-divider></el-divider>
      <!-- 四、小结 -->
      <div class="briefong_one">
        <div class="defense">四、小结</div>
        <el-table
          :data="briefing.briefSummaryList"
          border
          style="width: 100%; margin-top: 10px"
        >
          <el-table-column prop="workGroupCount" label="派出工作组数">
          </el-table-column>
          <el-table-column prop="checkerCount" label="出动检查人数">
          </el-table-column>
          <el-table-column prop="hiddenDangerCount" label="发现隐患数量">
          </el-table-column>
          <el-table-column prop="rectificationCount" label="已整改数量">
          </el-table-column>
          <el-table-column prop="evacueesCount" label="撤离人员">
          </el-table-column>
        </el-table>
        <div class="plan mt-8 font-bold text-black" style="color:#fff">
          根据天气预报信息简述下一步防范工作:
          <div class="p-2" style="line-height: 26px">
            <span v-if="briefing.briefSummary.nextWorkPlanList[0]" class="mt-2"
              >关注气象信息，及时学握气象变化情况，落实防御工作。<br
            /></span>
            <span v-if="briefing.briefSummary.nextWorkPlanList[1]" lclass="mt-4"
              >加强应急值班值守，保障人员投入。<br
            /></span>
            <span v-if="briefing.briefSummary.nextWorkPlanList[2]" class="mt-2"
              >做好应急物资设备的储备，确保各项应急物资设备处于完好状态。<br
            /></span>
            <span v-if="briefing.briefSummary.nextWorkPlanList[3]" class="mt-2"
              >加强对各重点部位的安全巡查，及时整改存在的安全隐患。<br
            /></span>
            <span class="mt-2" v-if="briefing.briefSummary.nextWorkPlanList[4]"
              >做好与街道等单位的联防联动。</span
            >
          </div>

          <span class="mt-4" v-if="readState">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in briefing.operatingHistoryList"
                :key="index"
                :color="'#649ef1'"
              >
                <!-- {{ activity.operatorTime }}
                    {{ "    " + activity.actionType + "    " }}操作人：{{
                      activity.operatorPost
                    }}-{{ activity.operatorName }} 确认意见：{{
                      "    " + activity.opinion
                    }} -->
                <div>
                  <i style="font-style:normal;margin-right: 5px;">{{
                    activity.operatorTime
                  }}</i
                  ><span>{{ activity.actionType }}</span>
                  <span style="margin-left:10px"
                    >操作人: {{ activity.operatorPost }}-{{
                      activity.operatorName
                    }}
                  </span>
                  <span style="margin-left:10px" v-show="activity.opinion"
                    >操作意见: {{ activity.opinion }}
                  </span>
                </div>
              </el-timeline-item>
            </el-timeline>
          </span>

          <div v-if="!readState" class="plan" style="margin-top: 10px">
            审批意见:
            <div>
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                placeholder="请输入内容"
                v-model="briefing.opinion"
              >
              </el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer" v-show="!readState">
      <el-button type="primary" @click="submit(1)" :disabled="loading"
        >同意</el-button
      >
      <el-button type="danger" @click="submit(2)" :disabled="loading"
        >驳回</el-button
      >
    </span>
    <Detarl ref="DetarlViewModal"> </Detarl>
  </div>
</template>

<script>
import { getInfo, SavedInfo } from "@/api/responseTem";
import { approvalBriefing } from "@/api/response";
import { orgTypeList } from "@/api/ucenter";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { getDeptInfoById } from "@/api/org";
import { getMaterialsPage } from "@/api/eres";
import Detarl from "./detail.vue";
export default {
  components: {
    Detarl,
  },
  props: {
    bruefing: {
      type: Boolean,
      default: () => false,
    },
    briefings: {
      type: Object,
    },
  },
  watch: {
    bruefing: {
      handler() {
        this.breVisible = this.bruefing;
      },
      deep: true,
    },
    briefi: {
      handler() {
        this.briefing = this.briefi;
      },
      deep: true,
    },
  },
  data() {
    return {
      title: "三防工作简报",
      breVisible: false,
      url: "",
      briefing: {
        weatherOrOnDuty: {}, //水雨情的台风情况
        defense: {
          practicable: [
            {
              date: "0",
              name: "0",
              address: "",
            },
          ],
          threeCheckItemQuestionList: [
            {
              checkPeopleList: [],
              checkTime: "",
            },
          ],
          abarbeitung: "",
          leaderCheckInfo: {
            checkPeopleList: [],
            checkTime: "",
          },
          externalUnitCheckInfo: {
            checkPeopleList: [],
            checkTime: "",
          },
          emergency: {
            list: [{ numbers: "", trew: "" }],
          },
          supplies: {
            list: [{ numbers: "", trew: "", tefcr: "" }],
          },
        }, //防御情况
        affected: {
          affectedFlag: false,
          sitePondingFlag: false,
          typhoonFlag: false,
          otherEffectFlag: false,
          workPlanInFlag: false,
          sitePondingList: [],
          typhoonList: [],
          otherEffectList: [],
          workPlanInfo: {},
        }, //受影响情况
        //重新提交的再次审批
        activities: [
          {
            content: "活动按期开始",
            timestamp: "2018-04-15",
          },
          {
            content: "通过审核",
            timestamp: "2018-04-13",
          },
          {
            content: "创建成功",
            timestamp: "2018-04-11",
          },
        ],
      },

      options: [],

      equipment: [],

      connecting: [],

      against: "",
      typeList: [],
      leveloptions: [],
      jgList: [],
      jlList: [],
      sgList: [],
      readState: false,
      model: {
        supervisionUuid: "",
        supervisionName: "",
      },
      types: ["待整改", "待监理审批", "审批不通过", "完成"],
      loading: false,
      state: "",
    };
  },
  mounted() {
    this.handleFilling(this.briefings, "read");
  },
  methods: {
    handleFilling(record, state) {
      this.state = state;
      this.readState = false;
      if (state == "read") this.readState = true;
      console.log(123, record);

      const userInfo = JSON.parse(localStorage.getItem("user"));
      if (userInfo) {
        this.model.user = {
          id: userInfo.id,
          name: userInfo.realName,
        };
      }
      queryDictByLabel({ label: "应急类别" }).then((res) => {
        queryDictNodesById({ id: res.data.id }).then((el) => {
          this.typeList = el.data;
        });
      });
      queryDictByLabel({ label: "当前等级" }).then((res) => {
        queryDictNodesById({ id: res.data.id }).then((el) => {
          this.leveloptions = el.data;
        });
      });
      orgTypeList({ orgType: 3 }).then((el) => {
        this.jgList = el.data;
      });
      orgTypeList({ orgType: 2 }).then((el) => {
        this.jlList = el.data;
      });
      orgTypeList({ orgType: 1 }).then((el) => {
        this.sgList = el.data;
      });
      this.queryDeptInfo();
      this.getDataList();
      this.gitusData(record);
    },
    //查询物资
    getDataList() {
      this.loading = true;
      const params = {
        materialType: 1,
        page: 1,
        size: 1000000,
      };
      getMaterialsPage(params).then((res) => {
        if (res.code == 0) {
          console.log(res.data);
          this.loading = false;
          this.equipment = res.data.records ? res.data.records : [];
        }
      });
    },
    queryDeptInfo() {
      const user = JSON.parse(localStorage.getItem("user"));
      this.model.supervisionUuid = user.departmentId;
      getDeptInfoById(user.departmentId).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            this.model.supervisionName = res.data.departmentName;
          }
        }
      });
    },
    // 回显信息接口
    gitusData(record) {
      console.log("=============");
      console.log(record);
      let params = {
        id: record.id,
      };
      getInfo(params).then((res) => {
        // this.briefing = Object.assign(this.briefing, res.data);
        if (res.code == 0) {
          this.briefing = res.data;
          this.$emit("btnClose");
          this.breVisible = true;
          if (!this.briefing.affected) {
            this.briefing.affected = {
              affectedFlag: false,
              sitePondingFlag: false,
              typhoonFlag: false,
              otherEffectFlag: false,
              workPlanInFlag: false,
              sitePondingList: [],
              typhoonList: [],
              otherEffectList: [],
              workPlanInfo: {},
            };
          }
          if (!this.briefing.defense.leaderCheckInfo) {
            this.briefing.defense.leaderCheckInfo = {
              checkPeopleList: [],
              checkPeoples: "",
              checkTime: "",
              hiddenDangerCount: 0,
              rectificationCount: 0,
              listData: [
                {
                  hiddenDangerCount: 0,
                  rectificationCount: 0,
                },
              ],
              threeCheckItemQuestionList: [],
            };
          } else {
            this.briefing.defense.leaderCheckInfo.checkPeoples = this.briefing.defense.leaderCheckInfo.checkPeopleList.join();
            this.briefing.defense.leaderCheckInfo.listData = [
              {
                hiddenDangerCount: this.briefing.defense.leaderCheckInfo
                  .hiddenDangerCount,
                rectificationCount: this.briefing.defense.leaderCheckInfo
                  .rectificationCount,
              },
            ];
          }
          if (!this.briefing.defense.threeCheckItemQuestionList) {
            this.briefing.defense.threeCheckItemQuestionList = [
              {
                checkPeopleList: [],
                checkPeoples: "",
                checkTime: "",
                positionCount: 0,
                hiddenDangerCount: 0,
                rectificationCount: 0,
                threeCheckItemQuestionList: [],
              },
            ];
          } else {
            // this.briefing.defense.threeCheckItemQuestionList.forEach((e) => {
            //   e.checkPeoples = e.checkPeopleList.join();
            // });
          }
          if (!this.briefing.defense.externalUnitCheckInfo) {
            this.briefing.defense.externalUnitCheckInfo = {
              checkPeopleList: [],
              checkPeoples: "",
              checkTime: "",
              hiddenDangerCount: 0,
              rectificationCount: 0,
              listData: [
                {
                  hiddenDangerCount: 0,
                  rectificationCount: 0,
                },
              ],
              threeCheckItemQuestionList: [],
            };
          } else {
            this.briefing.defense.externalUnitCheckInfo.checkPeoples = this.briefing.defense.externalUnitCheckInfo.checkPeopleList.join();
            this.briefing.defense.externalUnitCheckInfo.listData = [
              {
                hiddenDangerCount: this.briefing.defense.externalUnitCheckInfo
                  .hiddenDangerCount,
                rectificationCount: this.briefing.defense.externalUnitCheckInfo
                  .rectificationCount,
              },
            ];
          }
          if (!this.briefing.defense.reportOnDutyInfoList) {
            this.briefing.defense.reportOnDutyInfoList = [
              {
                count: 0,
                name: "",
              },
            ];
          }
          if (!this.briefing.defense.reportMaterialInfoList) {
            this.briefing.defense.reportMaterialInfoList = [
              {
                address: "",
                count: "",
                materialId: "",
                materialName: "",
              },
            ];
          }
          //2、建管部领导检查
          if (!this.briefing.defense.constManageLeaderCheckInfo) {
            this.briefing.defense.constManageLeaderCheckInfo = {
              checkPeopleList: [],
              checkPeoples: "",
              checkTime: "",
              hiddenDangerCount: 0,
              rectificationCount: 0,
              checkSiteCount: 0,
              positionCount: 0,
              listData: [
                {
                  hiddenDangerCount: 0,
                  rectificationCount: 0,
                },
              ],
              threeCheckItemQuestionList: [],
            };
          }
          this.briefing.briefSummaryList = [this.briefing.briefSummary];
          console.log(this.briefing);
          this.$forceUpdate();
        } else {
          this.$message.error("数据请求错误，请联系管理员！");
          this.$emit("btnClose");
        }
      });
    },
    uploader(val) {
      console.log(val);
      this.url = val;
    },
    mergencyadd() {
      this.briefing.defense.reportOnDutyInfoList.push({ count: 0, name: "" });
      this.$forceUpdate();
    },
    mergencyremov(val) {
      if (this.briefing.defense.reportOnDutyInfoList.length < 2) {
        return;
      } else {
        this.briefing.defense.reportOnDutyInfoList.splice(
          this.briefing.defense.reportOnDutyInfoList.indexOf(val),
          1
        );
      }
      this.$forceUpdate();
    },
    suppliesadd() {
      this.briefing.defense.reportMaterialInfoList.push({
        materialName: "",
        count: "",
        address: "",
      });
      this.$forceUpdate();
    },
    suppliesremove(val) {
      if (this.briefing.defense.reportMaterialInfoList.length < 2) {
        return;
      } else {
        this.briefing.defense.reportMaterialInfoList.splice(
          this.briefing.defense.reportMaterialInfoList.indexOf(val),
          1
        );
      }
      this.$forceUpdate();
    },
    // radiowe() {
    //   if (this.briefing.affected.affectedFlag == 1) {
    //     this.briefing.affected.defenseew = false;
    //   } else {
    //     this.briefing.affected.defenseew = true;
    //   }
    // },
    // 施工项目是否受积水影响
    watererd() {
      if (this.briefing.affected.sitePondingFlag) {
        this.briefing.affected.sitePondingList.push({
          address: "",
          value: "",
          description: "",
          result: "",
        });
      } else {
        this.briefing.affected.sitePondingList = [];
      }
    },
    wateradd() {
      this.briefing.affected.sitePondingList.push({
        address: "",
        value: "",
        description: "",
        result: "",
      });
    },
    waterremove(val) {
      if (this.briefing.affected.sitePondingList.length < 2) {
        return;
      } else {
        this.briefing.affected.sitePondingList.splice(
          this.briefing.affected.sitePondingList.indexOf(val),
          1
        );
      }
    },
    // 施工项目是否受台风影响
    influenceed() {
      if (this.briefing.affected.typhoonFlag) {
        this.briefing.affected.typhoonList.push({
          address: "",
          value: "",
          description: "",
          result: "",
        });
      } else {
        this.briefing.affected.typhoonList = [];
      }
    },
    influenceadd() {
      this.briefing.affected.typhoonList.push({
        address: "",
        value: "",
        description: "",
        result: "",
      });
    },
    influenceremove(val) {
      if (this.briefing.affected.typhoonList.length < 2) {
        return;
      } else {
        this.briefing.affected.typhoonList.splice(
          this.briefing.affected.typhoonList.indexOf(val),
          1
        );
      }
    },
    // 其他原因突发事件
    sudeed() {
      if (this.briefing.affected.otherEffectFlag) {
        this.briefing.affected.otherEffectList.push({
          address: "",
          value: "",
          description: "",
          result: "",
        });
      } else {
        this.briefing.affected.otherEffectList = [];
      }
    },
    suddenadd() {
      this.briefing.affected.otherEffectList.push({
        address: "",
        value: "",
        description: "",
        result: "",
      });
    },
    suddenremove(val) {
      if (this.briefing.affected.otherEffectList.length < 2) {
        return;
      } else {
        this.briefing.affected.otherEffectList.splice(
          this.briefing.affected.otherEffectList.indexOf(val),
          1
        );
      }
    },
    // 未处理完毕的工作计划
    workerd() {
      if (this.briefing.affected.workPlanInFlag == "true") {
        this.briefing.affected.workPlanInFlag = true;
      } else {
        this.briefing.affected.workPlanInFlag = false;
      }
    },
    abarbeitungChange(row) {
      console.log(row);
      this.briefing.defense.practicable[0].address = row.address;
    },

    // 小结
    onInputChange(row) {
      this.briefing.summary.tableData[0].onInput = row.onInput;
    },
    checkChange(row) {
      this.briefing.summary.tableData[0].check = row.check;
    },
    troubleChange(row) {
      this.briefing.summary.tableData[0].trouble = row.trouble;
    },
    rectificationChange(row) {
      this.briefing.summary.tableData[0].rectification = row.rectification;
    },
    evacuationChange(row) {
      this.briefing.summary.tableData[0].evacuation = row.evacuation;
    },
    //提交
    submit(data) {
      if (data == 2 && !this.briefing.opinion) {
        this.$message.error("请输入审核意见");

        return false;
      }
      this.loading = true;
      this.briefing.status = data;
      this.briefing.defense.leaderCheckInfo.hiddenDangerCount = this.briefing.defense.leaderCheckInfo.listData[0].hiddenDangerCount;
      this.briefing.defense.leaderCheckInfo.rectificationCount = this.briefing.defense.leaderCheckInfo.listData[0].rectificationCount;

      // this.briefing.defense.externalUnitCheckInfo.hiddenDangerCount =
      //   this.briefing.defense.externalUnitCheckInfo.listData[0].hiddenDangerCount;
      // this.briefing.defense.externalUnitCheckInfo.rectificationCount =
      //   this.briefing.defense.externalUnitCheckInfo.listData[0].rectificationCount;

      //人员转换
      // this.briefing.defense.leaderCheckInfo.checkPeopleList =
      //   this.briefing.defense.leaderCheckInfo.checkPeoples.split();
      // this.briefing.defense.externalUnitCheckInfo.checkPeopleList =
      //   this.briefing.defense.externalUnitCheckInfo.checkPeoples.split();

      this.briefing.briefSummary = this.briefing.briefSummaryList[0];

      delete this.briefing.briefSummaryList;

      let params = {
        threePreventionWorks: [{ ...this.briefing, ...this.model }],
      };
      console.log(params);
      if (this.state == "总包部") {
        params.threePreventionWorks[0].preventionType = 2;
      } else if (this.state == "工点") {
        params.threePreventionWorks[0].preventionType = 1;
      } else if (this.state == "2029") {
        params.threePreventionWorks[0].preventionType = 1;
      }
      // console.log(this.row);
      approvalBriefing(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.loading = false;
          this.handleClose();
        } else {
          this.$message.error(res.message);
          this.loading = false;
        }
      });
    },
    //保存
    saves() {
      SavedInfo(this.briefing).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.$emit("fingrest");
          this.briefing = {};
        }
      });
    },
    handleClose() {
      this.breVisible = false;
      this.$emit("fingrest");
    },
    theOneChange() {
      this.$forceUpdate();
    },
    //建管部手机
    phoneVerifyJG(val) {
      let flag = this.phoneVerify(val);
      if (flag) {
        this.briefing.affected.workPlanInfo.constManagDeptPersonInChargePhone =
          "";
      }
    },
    //监理部手机
    phoneVerifyJL(val) {
      let flag = this.phoneVerify(val);
      if (flag) {
        this.briefing.affected.workPlanInfo.monitorPersonInChargePhone = "";
      }
    },
    //施工部手机
    phoneVerifySG(val) {
      let flag = this.phoneVerify(val);
      if (flag) {
        this.briefing.affected.workPlanInfo.constructPersonInChargeUuid = "";
      }
    },
    //手机号码验证
    phoneVerify(val) {
      if (
        !/^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/.test(
          val
        )
      ) {
        this.$message({
          message: "手机号码有误，请重新填写",
          type: "warning",
        });
        return true;
      }
    },
    //查看问题详情
    jumpDeail(val) {
      if (val.length > 0) {
        this.$refs.DetarlViewModal.handleFilling(val);
      } else {
        this.$message("此项无问题");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .briefing {
//   height: 700px;
//   overflow: auto;
// }
.briefong_auto {
  width: 90%;
  margin: auto;
  color: #fff;
  .briefong_one {
    .typhoon {
      font-size: 14px;
      // color: #fff;
      font-weight: bold;
    }
    .defense {
      font-size: 14px;
      // color: #000000;
      font-weight: bold;
    }
  }
}
::v-deep .viewForm .el-row .el-col .el-form-item .el-form-item__label {
  color: #fff !important;
}
.el-form-item {
  margin-bottom: 8px !important;
}
.content {
  width: 100%;
  // height: 300px;

  margin-top: 8px;
  position: relative;

  .water {
    position: absolute;
    top: 37%;
    left: 104%;
  }
  .influen {
    position: absolute;
    top: 37%;
    left: 104%;
  }
  .reovem {
    position: absolute;
    top: 37%;
    left: 104%;
  }
}
.el-table {
  font-size: 12px !important;
}
.toimg {
  display: flex;
  margin-top: 8px;
  margin-left: 4px;
  .imglist {
    width: 60px;
    height: 60px;
    margin-left: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.poert {
  position: relative;
  .el-icon-circle-plus-outline {
    position: absolute;
    top: 33%;
    left: 60%;
  }
  .el-icon-delete {
    position: absolute;
    top: 33%;
    left: 65%;
  }
  .supplie {
    top: 33%;
    left: 85%;
  }
  .suppiet {
    top: 33%;
    left: 90%;
  }
}

.water {
  position: relative;
  margin-top: 40px;
  font-weight: bold;
  .waeeter {
    position: absolute;
    top: 29%;
    left: 45%;
  }
  .suddend {
    position: absolute;
    top: 29%;
    left: 50%;
  }
}
.el-radio {
  margin-right: 150px !important;
}
.titcens {
  color: #62b9e5;
  text-align: right;
  cursor: pointer;
}
.plan {
  font-weight: bold;
}
.conten_wer {
  border: 1px solid rgb(189, 186, 186);
  padding: 10px;
  margin-top: 20px;
}
::v-deep .el-table {
  background-color: #081742 !important;
}
::v-deep .el-table--border {
  border: none;
}
::v-deep .el-table tr {
  background: #081742 !important;
}
::v-deep .el-table__cell.is-leaf {
  background: #081742 !important;
}
::v-deep .el-table thead.is-group th.el-table__cell {
  background: #081742 !important;
}
::v-deep .el-table--border th.el-table__cell {
  border: 1px solid #081742 !important;
}
::v-deep .el-table::before,
.el-table--border::after {
  background-color: #143363 !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: rgba(0, 46, 117, 0.4);
}
::v-deep .el-table th.is-leaf,
::v-deep .el-table--border td {
  border: 1px solid #143363 !important;
}
::v-deep .el-timeline-item__content {
  color: #ffffff;
}
</style>
<style lang="scss">
.viewForm .el-row .el-col .el-form-item .el-form-item__label {
  font-weight: bold !important;
  color: #000;
}
</style>
