import { httpInit } from "./http";
const http = httpInit("/gzdt-ucenter");

//登录
export const login = params => http.post(`/api/login`, params);

//sso登录
export const ssoLogin = params => http.post(`/api/linenet/ssoLogin`, params);

// 例子
// 用户修改密码
export const modifyPwd = params => http.put(`/api/modify_pwd`, params);
//删除账号
export const deleteAccount = id => http.delete(`/account/info/${id}`);
//分页查询账户列表
export const getAccounts = params =>
  http.get(
    `/account/list/${params.companyId}/${params.deptId}/${params.roleId}/${params.username}/${params.state}/${params.type}/${params.pageNo}/${params.pageSize}`
  );

export const getUserInfo = token => {
  return http({
    method: "",
    url: "",
    data: {
      token
    }
  });
};

export const logout = () => {
  return http({
    method: "get",
    url: "/loginout",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
};


export const postMangementPage = params =>
    http.get(`/post/page?unitType=${params.unitType}&pageSize=${params.pageSize}
    &pageNum=${params.pageNum}&postName=${params.postName}`);

export const userNameList = params =>
    http.get(`/user/getListByDeptId/${params}`);

export const getUserName = params =>
    http.get(`/cooper/aiViolationRecord/${params}`);

export const postidlist = params =>
    http.get(`/post/listByDeptIdAmend/${params}`);

export const userDetail = params => http.get(`/user/${params}`);

export const iUserContractPage = params => http.get(`/cooper/aiUserContractFile/page?contractId=${params.contractId}
&pageNum=${params.pageNum}&pageSize=${params.pageSize}`);

export const getUserSiteListByUserId = params => http.get(`/gateBrake/getUserSiteListByUserId/${params}`);

export const roleList = params =>
    http.get(`/role/findAll`);

export const postAdd = params => http.post(`/post/save`, params)

export const postEdit = params => http.post(`/post/updateById`, params)

export const criminalrecordsList = params => http.post(`/cooper/aiViolationRecord/page`, params)

export const getUserGateBrakePage = params => http.post(`/gateBrake/getUserGateBrakePage`, params)

export const changeStatusByIds = params => http.post(`/user/changeStatusByIds`, params)


export const criminalrecordsupdate = params => http.post(`/cooper/aiViolationRecord/updateById`, params)

export const getAttend = params => http.post(`/gateBrake/page`, params)

export const criminalrecordsSave = params => http.post(`/cooper/aiViolationRecord/save`, params)

export const deleteRecord = params => http.post(`/gateBrake/delete`, params)

export const userPage = params => http.post(`/user/list`, params)

export const userSave = params => http.post(`/user/save`, params)

export const uploadIdCardImg = params => http.post(`/user/uploadIdCardImg`, params)

export const uploadPicImg = params => http.post(`/user/uploadPic`, params)

export const contractPage = params => http.post(`/cooper/aiUserContract/userContract`, params)

export const saveContract = params => http.post(`/cooper/aiUserContract/saveAll`, params)

export const changeContract = params => http.post(`/cooper/aiUserContract/updateById`, params)

export const batchDelete = params => http.post(`/user/delete`, params)


export const deleteId = id => http.delete(`/post/${id}`);

export const criminalrecordsDelete = id => http.delete(`/cooper/aiViolationRecord/${id}`);

export const delContract = id => http.delete(`/cooper/aiUserContract/${id}`);

export const uploadBook = (file, onUploadProgress) => {
  return http.post(`/file/upload`, file, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress
  });
};

// 人员档案列表导出
export const userExportExcel = params =>
  http.post(`/user/exportExcel`, params, {
    //重要  没有她导出的文件会打不开
    responseType: "blob"
  });
