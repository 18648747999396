<template>
  <div class="cased">
    <el-form :model="form" :inline="true">
      <el-form-item>
        <el-input v-model="form.name" placeholder="输入关键字查询"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
     v-loading="loading"
      :data="gridData"
      :header-cell-style="{
        background: '#0E2553',
        color: '#ffffff',
        border: '1px solid #193F71',
      }"
    >
      <el-table-column property="caseName" label="事件名称"></el-table-column>
      <el-table-column
        property="caseLocation"
        label="事发地点"
      ></el-table-column>
      <el-table-column label="事故/件类别">
        <template slot-scope="scope">
          <span>{{ scope.row.addres == 0 ? "事故" : "事件" }}</span>
        </template></el-table-column
      >
      <el-table-column property="eventType" label="事件类型"></el-table-column>
      <el-table-column
        property="occurrenceTime"
        label="发生时间"
      ></el-table-column>
      <el-table-column property="address" label="附件">
        <template slot-scope="scope"
          ><el-button type="text" @click="handleFileClick(scope.row)"
            >附件</el-button
          ></template
        ></el-table-column
      >
    </el-table>

    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { puttedForward } from "@/api/response.js";
import { getCodeDictDetails } from "@/api/dict";
export default {
  props:{
typesr:{
      type: String,
    },
  },
   watch: {
    typesr:{
       handler(val) {
        console.log(val);
       getCodeDictDetails(val).then((res) => {
      console.log(res)
      if(res.code==0){
        this.ides=res.data.id
       this.putted()
      }
      
      });
      },
      immediate: true,
    }
   },
  data() {
    return {
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      form: {},
      ides:0,
      gridData: [],
      loading:true
    };
  },
  created() {
  
   
  },
  methods: {
    mivise() {
      this.dialogFormVisible = true;
    },
    putted() {
      puttedForward({
        eventTypeId: this.ides,
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
    
      }).then((res) => {
        console.log(res);
        this.gridData = res.data.records;
        this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.page.total = res.data.count;
        this.page.count = res.data.total;
        this.loading=false
      });
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.putted();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.putted();
    },
    //查询
    onSubmit() {
      if(this.form.name!==""){
         puttedForward({
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        caseName:this.form.name
      }).then((res) => {
        console.log(res);
        this.gridData = res.data.records;
        this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.page.total = res.data.count;
        this.page.count = res.data.total;
          this.loading=false
      });
      }else{
        this.putted()
      }
     
    },
    handleFileClick(record) {
      const link = document.createElement("a");
      link.href = record.enclosure;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 36px !important;
  border: none;
  background: #18467f;
  color:#ffffff;
}
::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
  border: 1px solid #002e75;
  background-color: #081742;
  color: white;
  margin-top: 20px;
}
::v-deep .el-table tbody tr:hover > td {
  background-color: #081742 !important;
  border-color: #143363;
}
.el-table::before {
  height: 0;
}
::v-deep .el-table td div{
   color: #ffffff;
}
::v-deep .el-table th>.cell{
  color:#ffffff;
}
.el-table {
   background-color: #081742 !important;
  height: 400px;
  overflow-x:hidden;overflow-y:scroll;
}
.el-table::-webkit-scrollbar {
    display: none;
}
</style>