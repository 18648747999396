<template>
  <el-dialog
    width="30%"
    :visible.sync="show"
    title="用户详情"
    :before-close="handleClickClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="flex justify-between" v-loading="loading">
      <div class="bg-gray-200 border-8 border-gray-600 p-2" style="width: 100%">
        <!-- <h2 class="text-2xl font-bold">基础信息</h2> -->
        <el-form :model="infoData" label-width="140px">
          <el-form-item label="用户名称:">
            <span>{{ infoData.userName }}</span>
          </el-form-item>
          <el-form-item label="所属单位:">
            <span>{{ infoData.companyName }}</span>
          </el-form-item>
          <el-form-item label="所属部门:">
            <span>{{ infoData.departmentName }}</span>
          </el-form-item>
          <el-form-item label="所属角色:">
            <ul>
              <li v-for="item of infoData.roles" :key="item.id">
                <span>{{ item.roleName }}</span>
              </li>
            </ul>
          </el-form-item>
          <el-form-item label="账户状态:">
            <span>{{ infoData.state == 0 ? "正常" : "限制登录" }}</span>
          </el-form-item>
          <el-form-item label="用户姓名:">
            <span>{{ infoData.realName }}</span>
          </el-form-item>
        </el-form>
      </div>

      <!-- <div class="bg-gray-200 border-8 border-gray-600 p-2" style="width: 47%">
        <h2 class="text-2xl font-bold">权限列表</h2>
        <div class="list">
          <el-tree
            style="background: f0f0f0"
            :data="tree"
            node-key="id"
            ref="trees"
            :highlight-current="false"
            accordion
            :indent="40"
            :props="{
              children: 'sub',
              label: 'resourceName'
            }"
          >
          </el-tree>
        </div>
      </div> -->
    </div>
  </el-dialog>
</template>
<script>
import { apermission } from "@/api/resource";
import { getAccountInfo } from "@/api/ucenter";
export default {
  props: {
    resourceAll: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tree: [],
      infoData: {},
      show: false,
      loading: false,
    };
  },
  mounted() {},
  methods: {
    // 对外父组件暴露的方法
    handleOpen(record) {
      this.loading = true;
      this.getUserInfo(record);
      this.show = true;
    },
    // 查询用户权限和详细信息
    async getUserInfo(record) {
      let res = await getAccountInfo(record.id);
      this.infoData = res.data;
      let redata = await apermission(record.id);
      this.tree = redata.data;
      this.treeDataEcho();
      this.loading = false;
    },
    treeDataEcho() {
      let treeList = [];
      this.tree.forEach((e) => {
        let clist = this.resourceAll.find((f) => f.id == e.resourceId);
        if (clist) {
          treeList.push(clist);
        }
      });
      let handleResource = (resource) => {
        let parent = treeList.find((p) => p.id == resource.parentId);
        if (parent) {
          if (resource.parentId !== 0 && parent.sub) {
            parent.sub.push(resource);
          } else {
            parent.sub = [resource];
          }
        } else {
          console.log(parent);
        }
      };
      treeList.forEach((item) => handleResource(item));
      this.tree = treeList.filter((item) => item.parentId === 0);
    },
    //关闭窗口
    async handleClickClose() {
      this.show = false;
    },
  },
};
</script>
