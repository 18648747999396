import Layout from "@/components/Layout/SystemLayout.vue";
import FloodControl from "@/views/DataManagement/Chart/FloodControl.vue";
import SecurityChecksPage from "@/views/DataManagement/Chart/SecurityChecksPage.vue";
import WorkReportsPage from "@/views/DataManagement/Chart/WorkReportsPage.vue";
import AerialViewPage from "@/views/DataManagement/Chart/AerialViewPage.vue";
import PreventionWorkReport from "@/views/DataManagement/Chart/PreventionWorkReport.vue";
import EvacuationChart from "@/views/DataManagement/Chart/EvacuationChart.vue";
import Regulations from "@/views/DataManagement/RulesRegulations/Regulations.vue";

const Routes = [
  {
    path: "/DataManagement",
    name: "/DataManagement",
    component: Layout,
    meta: {
      title: "数据中心",
    },
    children: [
      {
        path: "Chart",
        name: "Chart",
        component: Layout,
        meta: {
          title: "图表管理"
        },
        children: [
          {
            path: "DrainPage",
            name: "DrainPage",
            component: FloodControl,
          },
          {
            path: "KnowPage",
            name: "KnowPage",
            component: FloodControl,
          },
          {
            path: "FloodControl",
            name: "FloodControl",
            component: FloodControl,
          },
          {
            path: "SecurityChecksPage",
            name: "SecurityChecksPage",
            component: SecurityChecksPage,
          },
          {
            path: "WorkReportsPage",
            name: "WorkReportsPage",
            component: WorkReportsPage,
          },
          {
            path: "AerialViewPage",
            name: "AerialViewPage",
            component: AerialViewPage,
          },
          {
            path: "PreventionWorkReport",
            name: "PreventionWorkReport",
            component: PreventionWorkReport,
          },
          {
            path: "EvacuationChart",
            name: "EvacuationChart",
            component: EvacuationChart,
          },
        ],
      },
      {
        path: "RulesRegulations",
        name: "RulesRegulations",
        component: Regulations,
        meta: {
          title: "规章制度"
        },
      },
    ],
  },
];
export default Routes;
