<template>
  <div>
    <el-form label-width="130">
      <!-- s -->
      <el-form-item
          prop="progressDescribe"
          label="风险进展描述"
          class="textareaItem"
      >
        <el-input
            v-model="rowData.progressDescribe"
            type="textarea"
            resize="none"
            placeholder="请输入"
            auto-complete="off"
            :autosize="{ minRows: 2, maxRows: 4 }"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="photoList"
          label="形象进展图"
          class="textareaItemSpec"
      >
        <el-image
            style="width: 100px; height: 100px"
            v-for="(item,index) in rowData.progressPicUrlList"
            :key="index"
            :preview-src-list="rowData.progressPicUrlList"
            :src="item"></el-image>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
export default {
  name: "historyDetail",
  props : {
    rowData : {
      default : () => {}
    }
  }
}
</script>

<style scoped>

</style>
