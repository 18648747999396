<template>
  <!--汇总提交-->
  <div v-loading="tableLoading">
    <span v-show="gather" style="color:red"
      >请尽快前往电脑端事件列表进行汇总提交操作</span
    >
    <div style="margin-bottom: 5px" v-show="!gather">
      <el-radio-group
        size="mini"
        v-model="degree"
        v-for="(item, index) in times"
        :key="index"
        @change="degreeChange(item)"
      >
        <el-radio-button
          :label="item"
          style="margin: 0 10px 5px 0"
        ></el-radio-button>
      </el-radio-group>
    </div>
    <div style="margin-bottom: 5px">
      <!-- <span>填报次数{{  }}</span> -->
      汇总单位:
      <span style="margin-left:5px">{{ summaryDepartment }}</span>
    </div>
    <div v-if="primarywe">
      完成情况:
      <span style="margin-left:5px">{{ summaryInfo }}</span>
    </div>
    <!-- <el-descriptions class="margin-top" :column="3" border v-if="primarywe">
      <el-descriptions-item>
        <template slot="label"> 完成情况 </template>
        {{ summaryInfo }}
      </el-descriptions-item>
    </el-descriptions> -->
    <el-table
      ref="tableData"
      :data="tableData"
      id="tableData"
      border
      :row-style="{ height: '30px' }"
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column
        label="线路工点/专职救援队"
        prop="lineSite"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        label="派出工作组(个)从应急响应启动到本次报送时间节点,派出工作组的累计数量"
        align="center"
        prop="workGroup"
      >
      </el-table-column>
      <el-table-column
        prop="position"
        label="检查场所部位(处)从应急响应启动到本次报送时间节点,检查场所部位的累计数量"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="hiddenDanger"
        label="新排查出三防风险隐患(处)上—次报送时间节点到本次报送时间节点，新排查出的三防风险隐患数量"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="info"
        label="发送预警或防御信息(条)地铁集团不需要填该项"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.info ? scope.row.info : "/" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="worker"
        label="预置(出动)抢险人员(人)在本次报送时间节点，能立即调动的抢险人员"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="team"
        label="预置(出动)抢险队伍(支)在本次报送时间节点，能立即调动的抢险队伍数量"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="预置(出动)抢险设备(台)指在本次报送时间节点，能立即调动设备数量"
        align="center"
      >
        <el-table-column prop="deviceTotal" label="合计" align="center">
        </el-table-column>
        <el-table-column
          prop="vehicle"
          label="车辆能立即调动的抢险汽车、吊车、铲车、履带车等"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="boat"
          label="舟艇能立即调动的舟船、划艇等水上运输工具数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="waterPump"
          label="抽水机能立即调动的龙吸水、自吸泵、排污泵、潜水泵、动力站水泵等抽排水设备数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="other"
          label="其他能立即调动的、未统计在车辆、舟艇、抽水机范围内的三防抢险设备数量"
          align="center"
        >
        </el-table-column>
      </el-table-column>

      <el-table-column
        prop="remark"
        label="其他应急措施落实及受灾害影响情况指从应急响应启动到本次报送时间节点，集团公司各单位应急措施落实及受灾受影响累计情况"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.remark ? scope.row.remark : "/" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.status ? scope.row.status : "/" }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" v-if="!gather">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="refusal(scope.row)">{{
            scope.row.operation ? scope.row.operation.operationText : "/"
          }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div
      style="display: flex; justify-content: center; margin-top: 20px"
      v-show="!gather"
    >
      <el-row>
        <el-button
          @click="submit"
          type="primary"
          key="autoButton"
          v-if="primarywe"
          :disabled="submiteds"
          >提交</el-button
        >
      </el-row>
      <!-- :disabled="demo ? 1 : 0" -->
    </div>
    <!-- 驳回 -->
    <el-dialog
      title="驳回"
      :visible.sync="rejectDialog"
      :before-close="rejectCloses"
      append-to-body
      width="450px"
      center
    >
      <el-form
        ref="form"
        :model="rejectForm"
        :rules="rejectRules"
        label-width="80px"
        v-loading="rejectLoading"
      >
        <el-form-item label="原因:" prop="reason">
          <el-input v-model="rejectForm.reason"></el-input>
        </el-form-item>
        <el-form-item
          style="display: flex;
          justify-content: center;"
          class="rejectButtton"
        >
          <el-button @click="cancel()">取消</el-button>
          <el-button type="primary" @click="onSubmit()">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  // 检查表填报-获取检查项
  informlist,
  summarydata,
  submitdata,
  approveent,
} from "@/api/response";

export default {
  props: {
    ids: {
      type: Object,
      default() {
        return {};
      },
    },
    stasher: {
      type: String,
    },
    swert: {
      type: Object,
      default() {
        return {};
      },
    },
    gather: {
      type: Boolean,
      default: false,
    },
    gatherStr: {
      tye: String,
    },
  },
  components: {
    // checkPage,
  },
  data() {
    return {
      tableData: [],
      submitData: [],
      times: [],
      timwe: [],
      degree: "第一次",
      id: "",
      tableLoading: false,
      rejectLoading: false,
      summaryDepartment: "",
      summaryInfo: "",
      submiteds: true,
      degrees: null,
      primarywe: true,
      rejectDialog: false,
      rowId: "",
      rejectForm: {
        reason: "",
      },
      rejectRules: {
        reason: [{ required: true, message: "请输入原因", trigger: "blur" }],
      },
    };
  },
  created() {
    // this.getResponseList();
  },
  mounted() {},
  watch: {
    ids: {
      handler(val) {
        if (val) {
          this.tableLoading = true;
          // this.times = [];
          // this.tableData = [];
          this.timwe = [];
          this.id = val.responseRecordId;
        }
      },
      immediate: true,
      deep: true,
    },
    swert: {
      handler(val) {
        if (val) {
          if (val[0] == "汇总提交") {
            this.serte(this.id);
          } else if (val[0] == "查看汇总提交") {
            this.setf(this.id);
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // statusFormatter(row) {
    //   switch (row.status) {
    //     case 1:
    //       return "待总包单位审批";
    //     case 2:
    //       return "待建管部确认";
    //     default:
    //       return "";
    //   }
    // },
    refer() {
      let tableLength = this.tableData.length - 1;
      let interData = [];
      interData = this.tableData.filter(
        (item) => item.status == "待建管部确认"
      );
      if (tableLength == interData.length) {
        this.submiteds = false;
      } else {
        this.submiteds = true;
      }
    },
    serte(val) {
      var newser = [];
      this.times = [];
      var demo = [];
      summarydata({ id: val }).then((res) => {
        this.times = Object.keys(res.data.reportData);
        for (let j in res.data.reportData) {
          newser.push({ [j]: res.data.reportData[j] });
          demo.push(res.data.reportData[j]);
        }

        this.timwe = JSON.parse(JSON.stringify(newser));

        // for (let value in this.timwe) {
        //   this.times.push(Object.keys(this.timwe[value]));
        // }
        if (newser[0][this.times[0]].operation.operationText == "关闭") {
          this.submiteds = true;
        } else if (newser[0][this.times[0]].operation.operationText == "提交") {
          this.submiteds = false;
        }
        this.degree = this.times[0];
        this.degrees = 1;
        if (this.gather) {
          let demoData = demo.filter(
            (item) => item.times == Number(this.gatherStr)
          );
          this.tableData = demoData[0].list;
        } else {
          this.tableData = newser[0][this.times[0]].list;
        }
        this.tableLoading = false;
        this.summaryInfo = newser[0][this.times[0]].summaryInfo;
        this.summaryDepartment = newser[0][this.times[0]].summaryDepartment;
        var ert = [];
        this.tableData.forEach((it) => {
          ert.push(it.status);
        });
        this.submitData = this.tableData.filter(
          (item) => item.lineSite != "合计"
        );
        // 提交按钮
        this.refer();
        ert.shift();
        // const erwe = ert.every((item) => item == "待汇总");
        // if ( == true) {
        //   this.submiteds = false;
        // } else {
        //   this.submiteds = true;
        // }
      });
    },
    setf(val) {
      informlist({ id: val }).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.summaryDepartment = res.data.summaryDepartment;
          this.primarywe = false;
        }
        this.tableLoading = false;
      });
    },

    //点击第几次
    degreeChange(val) {
      for (let value in this.timwe) {
        var newsers = [];
        if (Object.keys(this.timwe[value])[0] == val) {
          this.degrees = Number(value) + Number(1);
          for (let j in this.timwe[value]) {
            newsers.push({ [j]: this.timwe[value][j] });
          }
          this.summaryInfo = newsers[0][val].summaryInfo;
          this.summaryDepartment = newsers[0][val].summaryDepartment;
          this.tableData = newsers[0][val].list;
          this.refer()
        }
      }
    },

    // 表格的汇总
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "汇总";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          // sums[index] += " 元";
        } else {
          sums[index] = "/";
        }
      });

      return sums;
    },
    serder() {
      this.times = [];
      this.tableData = [];
      this.timwe = [];
    },
    //提交
    submit() {
      this.tableLoading = true;
      submitdata({ id: this.id, times: this.degrees })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.serte(this.id);
            this.$emit("handleCloses");
            this.tableLoading = false;
          } else {
            this.$message.warning("提交失败");
            this.tableLoading = false;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    refusal(row) {
      this.operaesr = row.operation.operation;
      this.rowId = row.currentId;
      this.rejectDialog = true;
    },
    // 确认驳回
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.rejectLoading = true;
          approveent({
            data: this.rejectForm.reason,
            id: this.rowId,
            operation: this.operaesr,
          })
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                });
                this.rejectLoading = false;
                this.rejectDialog = false;
                this.rejectForm.reason = "";
                this.serte(this.id);
              } else {
                this.rejectLoading = false;
                this.$message.warning("操作失败");
              }
            })
            .catch((err) => {
              this.rejectLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.rejectDialog = false;
      this.$refs.form.resetFields();
    },
    rejectCloses() {
      this.cancel();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-table::before {
  height: 0;
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: #fff !important;
  border-radius: 7px;
}
/*定义滑块颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
::v-deep ::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 7px;
  border-radius: 0;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}

//去掉固定列下方的横线
::v-deep .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: transparent;
}
//去掉固定列阴影
::v-deep .el-table__fixed-right {
  // box-shadow: none;
  height: 100% !important;
}

// 表格多选框是小计的隐藏掉
::v-deep .myCell .el-checkbox__input {
  display: none !important;
}
::v-deep .el-table th > .cell {
  font-size: 12px !important;
}
::v-deep .el-table--border,
.el-table--group {
  border: none;
}
::v-deep .el-table--border::after,
.el-table--group::after {
  width: 0 !important;
}
::v-deep .el-table--border th.el-table__cell.gutter:last-of-type {
  background-color: #fff !important;
  border-bottom: none;
}
::v-deep .el-radio-button {
  width: 75px;
}
::v-deep .el-radio-button__inner {
  width: 65px;
}
::v-deep .el-table {
  display: flex;
  flex-direction: column;
}
::v-deep .el-table__body-wrapper {
  order: 1;
}

::v-deep .el-table__footer-wrapper tbody td {
  cursor: pointer;
}
::v-deep .el-radio-button:first-child:last-child .el-radio-button__inner {
  width: 88px;
}
::v-deep .el-radio-button {
  width: 95px;
}
.rejectButtton {
  ::v-deep .el-form-item__content {
    margin-left: 0 !important;
  }
}
</style>
