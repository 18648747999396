<template>
  <!--三防工作统计表监理审  -->
  <div>
    <el-descriptions title="" style="width: 70%">
      <el-descriptions-item label="汇总单位">{{
        checkName
      }}</el-descriptions-item>

      <el-descriptions-item label="汇总次数"
        >第{{ cuns }}次</el-descriptions-item
      >
    </el-descriptions>
    <el-table
      ref="tableDatas"
      :data="tableDatas"
      id="tableDatas"
      border
      :row-style="{ height: '30px' }"
      style="width: 100%; margin-top: 20px"
      v-loading="tableLoading"
      height="430"
    >
      <el-table-column label="线路-工点" align="center" prop="name" width="420">
      </el-table-column>
      <el-table-column
        prop="workGroupCount"
        label="派出工作组数"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="workGroupCount"
        label="出动检查人数"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="siteCount"
        label="检查在建工点数"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="hiddenDangerCount"
        label="发现隐患数量"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="hiddenDangerCount"
        label="整改隐患数量"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column prop="evacueesCount" label="撤离人员" align="center">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// import { listSiteDetailSummaryInfo } from "@/api/responseTem";
export default {
  props: {
    strery: {
      type: String,
    },
    checkId: {
      type: String,
    },
    checkName: {
      type: String,
    },
    cuns: {
      type: Number,
    },
    qualityResponseId: {
      type: Number,
    },
    tableDatas: {
      type: Array,
    },
  },
  watch: {
    strery: {
      handler(val) {
        console.log(val);
        if (val == "view") {
          this.approver = false;
          this.deter = true;
        } else {
          this.approver = true;
          this.deter = false;
        }
      },
      immediate: true,
      deep: true,
    },
    data() {
      return {
        times: [],
        
        tableTotalData: [],
        checkPageLoading: false,
        //审批驳回
        turnDownDialog: false,
        checkDialog: false,
        tableLoading: false,
        formInline: {
          lineId: "",
          branchUuid: "",
          siteUuid: "",
        },
        // 驳回参数
        id: "",
        constructionCurrentId: "",
        // 查看返回的列表
        checkData: [],
        currentId: "",
        //线路工点的下拉选
        lineList: [],
        branchList: [],
        siteList: [],
        responseType: "",
        responseLevel: "",
        textarea2: "",
        approver: true,
        deter: false,
      };
    },
    mounted() {},
  },
  methods: {
    getdata() {
      // this.tableLoading = true;
      // let params = {
      //   supervisionName: this.checkName,
      //   supervisionUuid: this.checkId,
      //   times: this.cuns,
      //   responseId: this.qualityResponseId,
      // };
      // listSiteDetailSummaryInfo(params).then((res) => {
      //   if (res.code == 0) {
      //     this.tableLoading = false;
      //     this.tableDatas = res.data.summaryInfoList;
      //     this.$forceUpdate();
      //     console.log(this.tableDatas);
      //   }
      // });
    },
    //同意
    subtable() {},
    //驳回
    BatchApproval() {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-table::before {
  height: 0;
}
::v-deep .el-table {
  height: 400px;
  overflow-y: auto;
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: #fff !important;
  border-radius: 7px;
}
/*定义滑块颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}

//去掉固定列下方的横线
::v-deep .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: transparent;
}
//去掉固定列阴影
::v-deep .el-table__fixed-right {
  // box-shadow: none;
  height: 100% !important;
}

// 表格多选框是小计的隐藏掉
::v-deep .myCell .el-checkbox__input {
  display: none !important;
}
::v-deep .el-radio-button {
  width: 75px;
}
::v-deep .el-radio-button__inner {
  width: 65px;
}
// ::v-deep .el-table {
//   display: flex;
//   flex-direction: column;
// }
// ::v-deep .el-table__body-wrapper {
//   order: 1;
// }
// ::v-deep .el-table__fixed-body-wrapper {
//   top: 96px !important;
// }
// ::v-deep .el-table__fixed-footer-wrapper {
//   z-index: 0;
// }
</style>
