<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
            <el-cascader v-model="lineSite"
                         clearable :options="treeData" :props="props"
                         :show-all-levels="false" collapse-tags @change="handleLineSiteChange"></el-cascader>
          </el-form-item>
          <el-form-item prop="riskLevelCode">
            <el-select
                v-model="queryModal.riskTypeCode"
                clearable
                @change="riskTypeChange"
                placeholder="风险类别"
            >
              <el-option
                  v-for="item of riskTypeList"
                  :key="item.code"
                  :label="item.label"
                  :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="warnGradeCode">
            <el-select
                v-model="queryModal.riskSubTypeCode"
                clearable
                placeholder="风险子类"
            >
              <el-option
                  v-for="item of riskTypeSonList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="queryModal.riskName"
              placeholder="风险名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryModal.riskLevelCode"
              placeholder="风险控制等级"
              clearable
            >
              <el-option
                v-for="item in riskControlLevelList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
<!--          <el-form-item>
            <el-select
              v-model="queryModal.warnGradeCode"
              placeholder="安全状态"
            >
              <el-option
                v-for="item in riskCategory"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>-->
          <el-form-item>
            <el-button type="primary" @click="searchData">查询</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>

    <div style="margin-bottom: 10px">
      <el-radio-group v-model="radio" @change="radioChange">
        <el-radio-button :label="1">未消除</el-radio-button>
        <el-radio-button :label="2">已消除</el-radio-button>
      </el-radio-group>
    </div>

    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="branchName"
          label="分部/标段"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="工点"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskTypeName"
          label="风险类别"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskSubTypeName"
          label="风险子类"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskName"
          label="风险名称"
          show-overflow-tooltip
          width=""
        ></el-table-column>
<!--        <el-table-column
          align="center"
          prop="mileageRange"
          label="风险里程"
          show-overflow-tooltip
          width=""
        ></el-table-column>-->
        <el-table-column
          align="center"
          prop="riskDescribe"
          show-overflow-tooltip
          label="风险描述"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskLevelName"
          show-overflow-tooltip
          label="风险控制等级"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="yearQuarter"
          label="年度/季度"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="problemCount"
          label="待验收问题数"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskState"
          label="状态"
          show-overflow-tooltip
          width=""
        >
          <template slot-scope="scope">
            {{ scope.row.closeState == 0 ? '未申请' : scope.row.closeState == 1 ? '已申请' :
            scope.row.closeState == 2 ? '已通过' : '已驳回'}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleView(scope.row, 'detail')"
              >查看</el-button
            >
            <el-button
              type="text"
              v-if="deptType == 'construct' && (scope.row.closeState == 0 || scope.row.closeState == 3 )"
              :disabled="scope.row.problemCount > 0"
              @click="handleView(scope.row, 'edit')"
              >申请消除</el-button
            >
            <el-button
              type="text"
              v-if="deptType == 'monitor' && radio === 1 && scope.row.closeState == 1 "
              @click="handleView(scope.row, 'edit')"
              >风险消除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      width="70%"
      title="风险消除"
      class="editDialog details-dialog"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      center
      top="10vh"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <BaseInfo :risk-deaf-uuid="riskDeafUuid"></BaseInfo>
      <!-- <div class="riskDialogTitle">风险交底</div> -->
      <RiskMitigationForm :row-info="rowInfo" :type="disclosureType"
                          @CloseDialog="handleClose"></RiskMitigationForm>
    </el-dialog>
  </div>
</template>

<script>
import { getDictData } from "@/api/dict";
import BaseInfo from "./components/BaseInfo";
import RiskMitigationForm from "./components/RiskMitigationForm";
import {removeList} from "@/api/riskManage";
import {queryDeptDetail} from "@/api/stand";
import {riskLibraryRealList} from "@/api/risk";
export default {
  components: {
    BaseInfo,
    RiskMitigationForm
  },
  data() {
    return {
      queryModal: {
        siteUuidList: [],
        riskLevelCode : '',
        riskName : '',
        riskSubTypeCode : '',
        riskTypeCode : '',
        estimateEndDay : ''
      },
      lineSiteData: [],
      loading: false,
      riskCategory: [], //风险类别
      riskControl: [], //风险控制等级
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      radio: 1,
      dialogVisible: false,
      activeName: "风险交底",
      disclosureType: "", //风险交底类型
      tableData: [],
      unitType: "",
      lineSite : [],
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      riskTypeList : [],
      riskTypeSonList : [],
      riskControlLevelList : [],
      riskDeafUuid : '',
      deptType : '',
      rowInfo : {},
    };
  },
  created() {
    let obj = JSON.parse(localStorage.getItem("user"));
    console.log(obj, "sssss");
    // if (obj.companyCategory == "construct") {
    this.unitType = obj.companyCategory;
    // this.queryModal.estimateEndDay = this.getNowTime();
    this.queryModal.estimateEndDay = '2023-10-30';
    this.getSelectList();
    this.queryLineSiteTreeData();
    this.getDeptDetail();
    this.getTableData();
  },
  methods: {
    getNowTime() {
      var date = new Date();
      //年 getFullYear()：四位数字返回年份
      var year = date.getFullYear(); //getFullYear()代替getYear()
      //月 getMonth()：0 ~ 11
      var month = date.getMonth() + 1;
      //日 getDate()：(1 ~ 31)
      var day = date.getDate();
      //时 getHours()：(0 ~ 23)
      var hour = date.getHours();
      //分 getMinutes()： (0 ~ 59)
      var minute = date.getMinutes();
      //秒 getSeconds()：(0 ~ 59)
      var second = date.getSeconds();
      // var time = year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
      var time = year + '-' + this.addZero(month) + '-' + this.addZero(day);
      return time;
    },
    addZero(s) {
      return s < 10 ? ('0' + s) : s;
    },
    getDeptDetail() {
      console.log(JSON.parse(localStorage.getItem("user")));
      var userInfo = JSON.parse(localStorage.getItem("user"));
      queryDeptDetail(userInfo.companyId).then((res) => {
        console.log(res);
        this.deptType = res.data ? res.data.category : "0";
      });
    },
    radioChange(){
      if(this.radio === 1){
        this.getTableData();
      }else{
        this.getFinishData();
      }
    },
    queryLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    getSelectList() {
      let dictArray = ["riskType", "riskControlLevel"];
      dictArray.forEach((item) => {
        this.getDictList(item);
      });
    },
    getDictList(label) {
      getDictData(label).then((res) => {
        switch (label) {
          case "riskType":
            this.riskTypeList = res.data;
            break;
          case "riskControlLevel":
            this.riskControlLevelList = res.data;
            break;
        }
      });
    },
    getTableData(){
      let params = {
        pageNum : this.page.pageNo,
        pageSize : this.page.pageSize,
        ...this.queryModal
      }
      removeList(params).then(res => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
        this.loading = false;
      })
    },
    getFinishData(){
      let params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        isHistoricalRisk : 1,
        ...this.queryModal,
      };
      riskLibraryRealList(params).then(res => {
        this.tableData = res.data.records;
        // 手动添加状态中文
        this.tableData.forEach((i) => {
          // 代表是正常状态，返回待审核状态
          i.stateName = "已通过";
        });
        this.page.total = res.data.total;
        this.loading = false;
      })
    },
    riskTypeChange(){
      getDictData(this.queryModal.riskTypeCode).then((res) => {
        this.riskTypeSonList = res.data;
      });
    },
    searchData(){
      this.page.pageNo = 1;
      this.radioChange();
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getTableData();
    },
    handleLineSiteChange(record) {
      console.log(record)
      this.queryModal.siteUuidList = [];
      for (let item of record) {
        this.queryModal.siteUuidList.push(item[item.length-1]);
      }
    },
    handleView(row, type) {
      console.log(row);
      this.riskDeafUuid = row.riskDeafUuid;
      this.rowInfo = {...row};
      this.disclosureType = type;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.activeName = "风险交底";
      this.radioChange();
    }
  }
};
</script>

<style lang="scss" scoped>
.riskDialogTitle {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
}
::v-deep .details-dialog {
  .el-dialog__header {
    .el-dialog__title {
      position: static;
    }
  }
  .el-dialog__body {
    max-height: 680px;
    overflow: auto;
  }
}
</style>
