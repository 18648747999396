<template>
  <div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="supervisionName" label="建管部"> </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 6" style="color: #18c268"
            >待审批</span
          >
          <span v-if="scope.row.status == 0" style="color: red">待填报</span>
          <span v-if="scope.row.status == 1" style="color: red">已驳回</span>
          <span v-if="scope.row.status == 5">已完成</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            v-if="scope.row.approvalState == 3"
            @click="handleview(scope.row)"
            >查看</el-button
          >
          <el-button
            size="mini"
            type="text"
            v-if="scope.row.approvalState == 2"
            @click="handleapprove(scope.row)"
            >审批</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="title"
      :visible.sync="examineDialog"
      width="80%"
     
      center
      append-to-body
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <examiners :reportId="id" @examieClose="examieClose" :terwe="terwe"></examiners>
    </el-dialog>
  </div>
</template>

<script>
import examiners from "./examine.vue";
import {recoverylist } from "@/api/response.js"
export default {
  components: { examiners },
  props:{
    responseIds:{
      trpe:String
    }
  },
  data() {
    return {
      tableData: [
       
      ],
      //审批
      examineDialog: false,
      ids:"",
      id:"",
      terwe:"",
      title:""
    };
  },
  watch:{
responseIds:{
  handler(val){
    if(val){
      console.log(val)
      this.ids=val
       this.loss()
    }
  },
   immediate: true,
      deep: true,
}
  },
  
  created(){
   
  },
  methods: {
    loss(){
      recoverylist({responseId:this.ids}).then(res=>{
        console.log(res)
        this.tableData=res.data
      })
    },
    //审批
    handleapprove(val) {
        this.id=val.id
         this.terwe="审批"
      this.examineDialog = true;
      this.title="灾害损失及恢复情况审批"
    },
    //审批关闭
    examieClose(){
this.examineDialog = false;
this.loss()
    },
    //查看
    handleview(val) {
       this.id=val.id
      this.examineDialog = true;
      this.terwe="查看2"
      this.title="灾害损失及恢复情况详情"
    },
  
  },
};
</script>

<style>
</style>