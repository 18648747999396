<template>
  <div class="min-h-full bg-white">
    <div class="flex justify-between p-4">
      <el-row class="flex ">

        <propver
            class="mr-2 spec-select"
            :data="comList"
            :defaultProps="props"
            :chooseName="chooses"
            :pid="filters.puuid"
            :placeholder="'请选择单位/部门'"
            @deptTreeCurr="deptTreeCurrentChangeHandle"
        ></propver>

<!--        <input-select-tree
            :nameArr="nameArr"
            :valueTitle="valueTitle"
            :lineTree="lineTree"
            :defaultId="defaultId"
            :placeholder="'请选择站点'"
            @handleCheckChange="handleCheckChange"
            @clearHandle="clearHandle"
        ></input-select-tree>-->
        <el-cascader
            v-model="filters.lineSite"
            clearable
            :options="treeData"
            :props="propsSite"
            :show-all-levels="false"
            collapse-tags
            style="margin-right: 10px"
            @change="handleLineSiteChange"
        ></el-cascader>

        <el-select
            v-model="filters.jobTypeValue"
            placeholder="工种"
            class="mr-2"
            filterable
            clearable
        >
          <el-option
              v-for="item in unitTypeList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>

        <el-input
            v-model="filters.userName"
            class="mr-2"
            placeholder="姓名"
            style="width: 120px;"
            clearable
        ></el-input>

        <el-date-picker
            class="mr-2"
            value-format="yyyy-MM-dd"
            v-model="timeRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
        >
        </el-date-picker>

        <el-button
            type="primary"
            @click="findPage()"
        ><i class="fa fa-search"></i>查询
        </el-button
        >
      </el-row>

      <el-row>
        <el-button  type="primary" @click="handelExport()"
        ><i class="el-icon-download"></i>导出
        </el-button
        >

      </el-row>


    </div>
    <div class="mainPart">
      <div style="flex: 1">
        <!--表格内容栏-->
        <el-table
          :data="pageResult"
          ref="qtable"
          border
          size="mini"
          style="overflow: auto"
          height="650"
          v-loading="loading"
          @selection-change="handleSelectionChange"
        >
          <!-- :element-loading-text="$t('action.loading')" -->
          <el-table-column
            type="selection"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            label="序号"
            type="index"
            width="70"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="userName"
            header-align="center"
            align="center"
            label="人员姓名"
          >
            <template slot-scope="scope">
              <div class="clickable" @click="handleDeleteModify(scope.row)">
                {{ scope.row.userName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="unitName"
            header-align="center"
            align="center"
            label="所属单位"
          >
          </el-table-column>
          <el-table-column
            prop="dept"
            header-align="center"
            align="center"
            label="所属部门"
          >
          </el-table-column>
          <el-table-column
            prop="unitTypeStr"
            header-align="center"
            align="center"
            label="单位类型"
          >
          </el-table-column>

          <el-table-column
            prop="jobTypeLabel"
            header-align="center"
            align="center"
            label="工种"
          >
          </el-table-column>
          <el-table-column prop="attendanceType" label="打卡方式">
            <template slot-scope="scope">
              {{ returCLname(scope.row.attendanceType) }}
            </template>
          </el-table-column>

          <el-table-column
            prop="ioTime"
            header-align="center"
            align="center"
            label="时间"
          >
          </el-table-column>
          <el-table-column header-align="center" align="center" label="进出">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.ioStatus">出</el-tag>
              <el-tag v-else type="success">进</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="gateDeviceName"
            label="进出位置"
          >
          </el-table-column>
          <el-table-column
            prop="temperature"
            header-align="center"
            align="center"
            label="抓拍图"
          >
            <template slot-scope="scope">
              <ImgUpload
                class="erweima"
                v-if="scope.row.snapImage != null"
                :src="scope.row.snapImage"
                :preview-src-list="[scope.row.snapImage]"
              ></ImgUpload>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            header-align="center"
            align="center"
            label="操作"
          >
            <template slot-scope="scope">
              <!--              <kt-button
                    icon="fa fa-edit"
                    :label="$t('action.edit')"
                    perms="attend:record:edit"
                    @click="handleEdit(scope.row)"
                  />-->
              <el-button
                type="text"
                size="small"
                @click="handleDelete(scope.row)"
                v-has="'attend:record:delete'"
                class="mr-4"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页栏-->
        <div class="toolbar" style="padding: 10px">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="refreshPageRequest"
            @size-change="handleSizeChange"
            :current-page.sync="filters.pageNum"
            :page-size="filters.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="totalSize"
            style="text-align: right"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog
      :title="operation"
      v-if="dialogVisible"
      width="40%"
      class="editDialog"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
    >
      <el-form
        :model="dataForm"
        :rules="!xiangqing ? {} : rules"
        label-width="150px"
        ref="dataForm"
        :size="size"
        label-position="right"
        :disabled="!xiangqing"
      >
        <el-form-item label="人员姓名" prop="userName">
          <el-input
            v-model="dataForm.userName"
            auto-complete="off"
            readOnly
          ></el-input>
        </el-form-item>
        <el-form-item label="打卡方式" prop="attendanceType">
          <el-input
            v-model="attendanceType"
            auto-complete="off"
            readOnly
          ></el-input>
        </el-form-item>
        <el-form-item label="打卡设备" prop="gateDeviceName">
          <el-input
            v-model="dataForm.gateDeviceName"
            auto-complete="off"
            readOnly
          ></el-input>
        </el-form-item>
        <el-form-item label="打卡时间" prop="ioTime">
          <el-date-picker
            class="edit-form-item"
            width="200px"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="dataForm.ioTime"
            type="datetime"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="进出" prop="ioStatus">
          <el-radio-group v-model="dataForm.ioStatus" class="edit-form-item">
            <el-radio disabled :label="0" style="color: #59baf2"> 进 </el-radio>
            <el-radio disabled :label="1" style="color: #59baf2"> 出 </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="所属项目" prop="site">
          <el-input
            v-model="dataForm.site"
            auto-complete="off"
            readOnly
          ></el-input>
        </el-form-item>
        <el-form-item label="所属单位" prop="unitTypeStr">
          <el-input
            v-model="dataForm.unitTypeStr"
            auto-complete="off"
            readOnly
          ></el-input>
        </el-form-item>
        <el-form-item label="所属部门" prop="dept">
          <el-input
            v-model="dataForm.dept"
            auto-complete="off"
            readOnly
          ></el-input>
        </el-form-item>
        <el-form-item label="工种" prop="jobTypeValue">
          <el-input
            v-model="dataForm.jobTypeLabel"
            auto-complete="off"
            readOnly
          ></el-input>
        </el-form-item>
        <el-form-item label="体温" prop="temperature">
          <el-input
            v-model="dataForm.temperature"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="健康状态" prop="detail">
                      <el-input v-model="dataForm.detail" auto-complete="off"></el-input>
                  </el-form-item> -->
      </el-form>

      <div slot="footer" class="dialog-footer" v-if="xiangqing">
        <el-button :size="size" @click.native="dialogVisible = false">{{
          $t("action.cancel")
        }}</el-button>
        <el-button
          :size="size"
          :class="{ 'DL-btn': isDalian }"
          type="primary"
          @click.native="submitForm"
          :loading="editLoading"
        >
          {{ $t("action.submit") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SelectTree from "@/components/SelectTree";
import ImgUpload from "@/components/ImgUpload";
import ElTreeCommon from "@/components/ElTreeCommon";
import propver from "@/components/propver/index.vue";
import inputSelectTree from "@/components/inputSelectTree";
import { getDictData } from "@/api/dict";
import {getUserDeptList} from "@/api/org";
import {deleteRecord, getAttend} from "@/api/user";
import dayjs from "dayjs";


export default {
  components: {
    // KtButton,
    SelectTree,
    ImgUpload,
    ElTreeCommon,
    inputSelectTree,
    propver
  },
  data() {
    return {
      size: "small",
      filters: {
        userName: "",
        jobTypeValue: "",
        gateBrakeId: "",
        pageNum: 1,
        pageSize: 10,
        deptIds: [],
        unitTypeList: [],
        siteIds: [],
        isVisitor: null,
      },
      unitTypeList: [],
      treeData: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
        expandTrigger: "hover",
        disabled: "disabled",
      },
      propsSite: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true,
      },
      propsDept: {
        value: "uuid",
        label: "name",
        // label: "companyName",
        children: "children",
        disabled: "disabledS",
      },
      comList: [],
      xiangqing: true,
      chooses : "",
      deptTree: [],
      deptTreeProps: {
        label: "name",
        children: "children",
      },
      isVisitorArr: [
        { label: "否", value: 0 },
        { label: "是", value: 1 },
      ],
      lineTree: [],
      nameArr: [],
      depenamearr: [],
      idArr: [],
      valueTitle: "",
      defaultId: "",
      //默认勾选的树
      defaultCheckedkeys: [],
      //权限树的默认配置
      lineProps: {
        value: "id",
        label: "name",
        children: "children",
        expandTrigger: "hover",
      },
      timeRange: [],
      pageNum: 1,
      pageSize: 10,
      totalSize: 0,
      pageResult: [],
      loading: false,
      selectid: "",
      dataForm: {},
      dialogVisible: false,
      editLoading: false,
      operation: "",

      expandedArr: [],
      rules: {
        userName: [
          { required: true, message: "请输入人员姓名", trigger: "blur" },
        ],
        gateDeviceName: [
          { required: true, message: "请输入打卡设备", trigger: "blur" },
        ],
        site: [{ required: true, message: "请输入所属项目", trigger: "blur" }],
        dept: [{ required: true, message: "请输入所属单位", trigger: "blur" }],
        jobTypeValue: [
          { required: true, message: "请输入工种", trigger: "blur" },
        ],
        temperature: [
          { required: true, message: "请输入体温", trigger: "blur" },
        ],
        detail: [
          { required: true, message: "请输入健康状态", trigger: "blur" },
        ],
        ioTime: [{ required: true, message: "请输入时间", trigger: "blur" }],
        ioStatus: [{ required: true, message: "请选择进出", trigger: "blur" }],
      },

      isDalian: sessionStorage.getItem("roleType") == "role_dalian",
    };
  },
  watch: {
    pageResult(val) {
      this.doLayout();
    },
  },
  computed: {
    attendanceType() {
      return this.dataForm.attendanceType == "1" ? "闸机" : "手机";
    },
  },
  created() {
    this.getCrewLine();
    this.getdeptlist();
    this.getLineSiteTreeData();
    this.getjobTypeValueList();
    this.findPage();
  },
  methods: {
    getdeptlist() {
      let params = {
        name: "",
        category: "",
      };
      getUserDeptList(params)
          .then((res) => {
            if (res.code === 0) {
              res.data.forEach((item) => {
                item.disabledS = true;
              });
              this.comList = res.data || [];
            }
          })
          .catch((res) => {
            this.$message({
              message: res.message,
              type: "error",
            });
          });
    },
    // 线路工点树数据查询
    getLineSiteTreeData () {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"))
      if (lineSiteData) {
        this.treeData = lineSiteData
      }
      // this.flatArr(lineSiteData);
    },
    handleLineSiteChange(record){
      console.log(record);
      this.filters.siteIdList = []
      for (let item of record) {
        this.filters.siteIdList.push(item[item.length - 1])
      }
    },
    deptTreeCurrentChangeHandle(data) {
      console.log(data);
      this.chooses = data.name;
      this.filters.deptIdList = [data.id]
    },
    returCLname(val) {
      if (val == "1") {
        return "闸机";
      } else if (val == "0") {
        return "手机";
      }
      return val;
    },

    handelExport() {
      this.$confirm("是否确认导出所有的考勤记录信息?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.exportLoading = true;
          this.$api.statistics.recordExport(this.filters).then((res) => {
            const aLink = document.createElement("a");
            let blob = new Blob([res], {
              type: "application/vnd.ms-excel;charset=utf-8",
            });
            aLink.href = URL.createObjectURL(blob);
            aLink.download = "考勤记录.xlsx";
            aLink.click();
            document.body.appendChild(aLink);
          });
        })
        .then((response) => {
          this.download(response.msg);
          this.exportLoading = false;
        })
        .catch(() => {});
    },

    doLayout() {
      this.$nextTick(() => {
        this.$refs.qtable.doLayout();
      });
    },


    handleSizeChange(val) {
      this.filters.pageSize = val;
      this.findPage();
    },

    // 工种
    getjobTypeValueList() {
      let label = "jobType";
      this.jobTypes = [];
      getDictData(label).then((res) => {
        if (res.code == 0) {
          res.data.forEach((element) => {
            this.unitTypeList.push({
              label: element.label,
              value: element.code,
            });
          });
        }
      });
      // this.$api.user.findAll("jobType").then((res) => {
      //   if (res.code == 200) {
      //     this.unitTypeList = res.data;
      //   }
      // });
    },
    //获取线路权限树
    getCrewLine() {
      // this.$api.homePage.getCrewLine().then((res) => {
      //   if (res.code == "200") {
      //     this.lineTree = res.data.treeList;
      //   }
      // });
    },
    // 选中
    handleCheckChange(data, name) {
      this.filters.siteIds = data;
      this.nameArr = name;
      // this.findPage()
    },
    // 选中
    handledeptCheckChange(data, name) {
      this.filters.deptIds = data;
      this.depenamearr = name;
      // this.findPage()
    },
    // 清除选中
    clearHandle() {
      // this.$refs.selectTree.setCheckedKeys([])
      this.nameArr = [];
      this.idArr = [];
      this.filters.siteIds = [];
    },
    cleardeptHandle() {
      this.depenamearr = [];
      this.filters.deptIds = [];
    },

    // 获取分页数据
    findPage() {
      if (this.timeRange && !this.timeRange.length) {
        const date = new Date();
        this.timeRange = [
          dayjs(date).format("YYYY-MM-DD"),
          dayjs(date).format("YYYY-MM-DD"),
        ];
      }
      this.filters.starttime = this.timeRange
        ? this.timeRange[0] + " 00:00:00"
        : "";
      this.filters.endtime = this.timeRange
        ? this.timeRange[1] + " 23:59:59"
        : "";
      this.loading = true;
      // let params = 'pageNum=' + this.pageNum + '&pageSize=10&starttime=' + this.filters.starttime + '&endtime=' + this.filters.endtime + '&selectid=' + this.selectid
      getAttend(this.filters).then((res) => {
        if (res.code == "200") {
          this.pageResult = res.data.list;
          this.totalSize = res.data.total;
        }
        this.loading = false;
      });
    },
    // 显示隐藏批量删除按钮
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (this.multipleSelection.length != 0) {
        document.getElementById("btn").style.display = "block";
      } else {
        document.getElementById("btn").style.display = "none";
      }
    },
    // 批量删除
    batchDelete() {
      var ids = this.multipleSelection.map((item) => item.id);
      // console.log(ids)
      this.$confirm("确认删除选中记录吗？", "提示", {}).then(() => {
        this.$api.attendRecord.deleteBatchGateBrakeList(ids).then((res) => {
          if (res.code == 200) {
            this.$message({ message: "操作成功", type: "success" });
            this.findPage();
          } else {
            this.$message({ message: "操作失败, " + res.msg, type: "error" });
          }
        });
      });
    },
    //翻页
    refreshPageRequest(pageNum) {
      this.pageNum = pageNum;
      this.findPage();
    },

    // 显示编辑界面
    handleEdit(params) {
      this.operation = "编辑";
      this.xiangqing = true;
      this.dataForm = Object.assign({}, params);
      console.log(this.dataForm);
      this.dialogVisible = true;
    },
    //详情
    handleDeleteModify(params) {
      console.log(params);
      this.operation = "详情";
      this.xiangqing = false;
      this.dataForm = Object.assign({}, params);
      // this.dataForm.temperature = params.temperature == 0 ? '' : params.temperature;
      // this.dataForm.detail = params.detail == "null" ? '' : params.detail;
      this.dialogVisible = true;
    },
    // 编辑 或新增 operation true为新增
    submitForm() {
      // return
      this.$confirm("确认提交吗？", "提示", {}).then(() => {
        this.editLoading = true;
        let params = Object.assign({}, this.dataForm);

        this.editOperation(params);
      });
    },
    // 编辑
    editOperation(params) {
      this.$api.attendRecord.update(params).then((res) => {
        this.editLoading = false;
        if (res.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.findPage();
          this.$refs["dataForm"].resetFields();
        } else {
          this.$message({
            message: "操作失败, " + res.msg,
            type: "error",
          });
        }
      });
    },
    // 删除
    handleDelete(data) {
      this.$confirm("确认删除吗？", "提示", {}).then(() => {
        deleteRecord({ id: data.id })
          .then((res) => {
            if (res.code == 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.findPage();
            } else {
              this.$message({ message: "操作失败, " + res.msg, type: "error" });
            }
          })
          .then(data != null ? data.callback : "");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-container {
  margin-top: 10px;
}

.menu-header {
  padding-left: 8px;
  padding-bottom: 5px;
  text-align: left;
  font-size: 16px;
  color: rgb(20, 89, 121);
}

::v-deep .data-picker-form-item {
  width: 220px !important;
  padding: 3px 5px !important;
  font-size: 12px !important;
}

.form-item {
  width: 150px;
}

.edit-form-item {
  width: -webkit-fill-available;
  text-align: left;
}

.erweima {
  width: 80px;
  height: 80px;
}

.filter-item1 {
  min-width: 300px;

  .el-select {
    min-width: 300px;
  }
}

.filter-siteId {
  width: 200px;

  .el-select {
    min-width: 200px;
  }
}

.filter-item2 {
  min-width: 150px;

  .el-select {
    min-width: 150px;
  }
}

::v-deep .filterItemCss {
  width: 100px;

  .el-input {
    width: 100px;
  }
}

::v-deep .filterItemCss1 {
  width: 100px;

  .el-input {
    width: 100px;
  }
}
</style>
