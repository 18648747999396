<template>
  <div>
    <el-card>
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        label-position="left"
        :rules="rules"
      >
        <el-form-item label="旧密码:" prop="oldPwd">
          <el-input v-model="form.oldPwd" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新密码:" prop="newPwd">
          <el-input
            v-model="form.newPwd"
            type="password"
            @input="input()"
            placeholder="密码必须由8-16位字母、数字及下划线组成"
          ></el-input
          ><br />
          <el-rate
            v-model="level"
            class="pt-2"
            disabled
            :icon-classes="iconClasses"
            disabled-void-icon-class="el-icon-warning"
            :colors="['#ff0000', '#fdc632', '#04b30b']"
          >
          </el-rate>
        </el-form-item>
        <el-form-item label="重复新密码:" prop="reqPwd">
          <el-input v-model="form.reqPwd" type="password"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
          <el-button @click="resetForm">取消</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { modifyPwd } from "@/api/ucenter";
export default {
  name: "Appointment",
  data() {
    return {
      rules: {
        oldPwd: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newPwd: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        reqPwd: [
          {
            required: true,
            message: "请验证新密码",
            trigger: "blur",
          },
        ],
      },
      form: {
        oldPwd: "",
        newPwd: "",
        reqPwd: "",
      },
      level: 0,
      iconClasses: ["el-icon-warning", "el-icon-success", "el-icon-success"],
    };
  },
  mounted() {},
  methods: {
    // async onSubmit() {
    //   console.log(this.form);
    //   this.$refs["form"].validate((valid) => {
    //     if (valid) {
    //       if (this.form.newPwd != this.form.reqPwd) {
    //         this.$message.info("两次输入的新密码不一致!");
    //       } else if (this.level < 1) {
    //         this.$message.info(
    //           "新密码安全系数较低，密码必须由8-16位字母、数字及下划线组成"
    //         );
    //       } else {
    //         this.changePwb();
    //       }
    //     } else {
    //       return false;
    //     }
    //   });
    // },
    // async changePwb() {
    //   let params = {
    //     oldPwd: this.form.oldPwd,
    //     newPwd: this.form.newPwd,
    //   };
    //   let res = await modifyPwd(params);
    //   if (res.code === 0) {
    //     this.$message.success("修改成功！");
    //     this.$router.push("/Login");
    //   } else {
    //     this.$message.error(res.message);
    //   }
    // },
     onSubmit() {
      console.log(this.form);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.newPwd != this.form.reqPwd) {
            this.$message.info("两次输入的新密码不一致!");
          } else if (this.level < 1) {
            this.$message.info(
              "新密码安全系数较低，密码必须由8-16位字母、数字及下划线组成"
            );
          } else {
            this.changePwb();
          }
        } else {
          return false;
        }
      });
    },
    changePwb(){
       let params = {
        oldPwd: this.form.oldPwd,
        newPwd: this.form.newPwd,
      };
      modifyPwd(params).then((res)=>{
       if(res.code === 0){
          this.$message.success("修改成功！");
          this.$router.push("/Login"); 
       }else {
        this.$message.error(res.message);
      }
      }).catch((res)=>{
      this.$message.error(res.message);
      })
    },
    getLvl(val) {
      var level = 0;
      if (val.length < 9) return 0;
      if (/\d/.test(val)) level = level + 2; //数字
      if (/[a-z]/.test(val)) level++; //小写
      if (/[A-Z]/.test(val)) level++; //大写
      if (/\W/.test(val)) level++; //特殊字符

      return level;
    },
    input() {
      this.level = this.getLvl(this.form.newPwd);
      console.log(this.level);
    },
    resetForm() {
      this.level = 0;
      this.$refs["form"].resetFields();
    },
  },
};
</script>

<style  scoped>
.week {
  text-align: center;
}
</style>
