<template>
  <div class="riskDialog">
    <div class="main">
      <div class="riskDialogTitle">风险消除操作</div>
      <div class="risktowTitle">
        施工单位提交申请
      </div>
      <el-form
        ref="disposeForm"
        :rules="rules"
        :model="disposeForm"
        label-width="110px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="申请人" prop="userName">
              <el-input readonly v-model="disposeForm.submitPerson"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请时间" prop="userName">
              <el-date-picker
                  v-model="disposeForm.submitTime"
                  type="date"
                  readonly
                  format="yyyy-MM-dd"
                  style="width: 100%;"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
<!--           :readonly="type === 'detail' || deptType !== 'construct'  "-->
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="申请描述" prop="submitDescribe">
              <el-input
                type="textarea"
                resize="none"
                :readonly="type === 'detail' || deptType !== 'construct'  "
                :autosize="{ minRows: 2, maxRows: 6 }"
                v-model="disposeForm.submitDescribe"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="附件:" prop="fileUrl">
              <FileUpload
                v-if="type !== 'detail' && deptType !== 'monitor' "
                @uploadChange="handleFileUpload"
                @delChange="handleFileDel"
                :value="fileList"
                type="btn"
              ></FileUpload>
              <ul v-if="type === 'detail' || deptType === 'monitor' || rowInfo.closeState == 3 " class="fileGroupBox">
                <li v-for="(item, i) in constructFileList" :key="i">

                  {{ item.fileName }}
<!--                  <span @click="handleFileClick(item)">预览</span>-->
                  <span @click="deleteFile(item)">x</span>
                  <span @click="handleExport(item.fileUrl, item.fileName)"
                    >导出</span
                  >
                </li>
              </ul>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="risktowTitle" v-if="deptType === 'monitor' || rowInfo.closeState == 3 ">监理单位审核</div>
      <el-form
        v-if="deptType == 'monitor' || rowInfo.closeState == 3 "
        ref="examForm"
        :rules="rules"
        :model="examForm"
        label-width="110px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="审核人" prop="userName">
              <el-input readonly v-model="examForm.examinePerson"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核时间" prop="userName">
              <el-date-picker
                  v-model="examForm.created"
                  type="date"
                  style="width: 100%"
                  readonly
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
<!--            :readonly="type === 'detail' || deptType !== 'monitor' "-->
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="审核描述" prop="remark">
              <el-input
                type="textarea"
                :readonly="type === 'detail' || deptType !== 'monitor' "
                resize="none"
                :autosize="{ minRows: 2, maxRows: 6 }"
                v-model="examForm.examineDescribe"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="附件:" prop="fileUrl">
              <FileUpload
                v-if="type !== 'detail' && deptType == 'monitor' "
                @uploadChange="handleFileUploadExamine"
                @delChange="handleFileDel"
                :value="fileList"
                type="btn"
              ></FileUpload>
              <ul v-if="type === 'detail' || rowInfo.closeState == 3 " class="fileGroupBox">
                <li v-for="(item, i) in examFileList" :key="i">
                  {{ item.fileName }}
<!--                  <span @click="handleFileClick(item)">预览</span>-->
                  <span @click="handleExport(item.fileUrl, item.fileName)"
                    >导出</span
                  >
                </li>
              </ul>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="dialogFooter" v-if="type !== 'detail' ">
      <el-button
        v-if="deptType == 'construct'"
        size="medium"
        type="primary"
        @click="submitApply"
        >提交申请</el-button
      >
      <el-button v-if="deptType == 'monitor'" size="medium" @click="submit(0)"
        >驳回</el-button
      >
      <el-button
        v-if="deptType == 'monitor'"
        size="medium"
        type="primary"
        @click="submit(1)"
        >通过</el-button
      >
    </div>
  </div>
</template>

<script>
import FileUpload from "./FileUpload";
import {queryDeptDetail} from "@/api/stand";
import dayjs from "dayjs";
import {
  applyClear, applyClearEdit,
  applyClearFile, applyClearFileDelete, applyClearFileList,
  applyDetails,
  applyDetailsFile,
  examineClear,
  examineClearFile, examineClearFileList, getRiskCloseExamine, getRiskCloseSubmit
} from "@/api/riskManage";
export default {
  components: {
    FileUpload
  },
  props: {
    type: {
      type: String,
      default: ""
    },
    rowInfo : {
      default : () => {}
    }
  },
  data() {
    return {
      // dd
      disposeForm: {
        userName: "",
        submitDescribe: "",
        submitTime: "",
        submitPerson : '',
        submitPersonUuid : '',
      },
      rules: {},
      fileList: [],
      dialogFileList: [
        {
          fileName: "07f123d1a23d0bb1ce3ac7944b3665c91412653368402789991.txt",
          fileUrl:
            "http://47.95.238.93:9300/gzdt-upload/attachments/2022/12/07f123d1a23d0bb1ce3ac7944b3665c91412653368402789991.txt"
        }
      ],
      unitType: "",
      deptType : '',
      obj : JSON.parse(localStorage.getItem("user")),
      examForm : {
        examinePerson : '',
        examinePersonUuid : '',
        examineDescribe : '',
        created : '',
      },
      constructFileList : [],
      examFileList : [],
      isEdit : false
    };
  },
  created() {
    this.getDeptDetail();
  },
  mounted() {
    // console.log(obj, "sssss");
    // if (obj.companyCategory == "construct") {
    this.unitType = this.obj.companyCategory;

    // }
  },
  methods: {
    reset(){
      this. disposeForm = {
        userName: "",
        submitDescribe: "",
        submitTime: "",
        submitPerson: this.obj.realName,
        submitPersonUuid: this.obj.uuid,
      }
    },
    getDeptDetail() {
      console.log(JSON.parse(localStorage.getItem("user")));
      var userInfo = JSON.parse(localStorage.getItem("user"));
      queryDeptDetail(userInfo.companyId).then((res) => {
        console.log(res);
        this.deptType = res.data ? res.data.category : "0";
        if(this.type === 'detail'){
          if(this.deptType === "construct"){
            console.log(111)
            this.getConstructData();
            this.getConstructFileData();
            this.getExamData();
            this.getExamFileData();
          }else{
            console.log(222)
            this.getConstructData();
            this.getConstructFileData();
            this.getExamData();
            this.getExamFileData();
          }
        }else{
          if(this.deptType === 'construct'){
            this.disposeForm.submitPerson = this.obj.realName;
            this.disposeForm.submitPersonUuid = this.obj.uuid;
            this.getConstructData();
            this.getConstructFileData();
            this.getExamData();
            this.getExamFileData();
          }else{
            console.log(222)
            this.examForm.examinePerson = this.obj.realName;
            this.examForm.examinePersonUuid = this.obj.uuid;
            this.examForm.created = dayjs().format('YYYY-MM-DD HH:mm:ss')
            console.log(this.examForm)
            this.getConstructData();
            this.getConstructFileData();
          }
        }
      });
    },
   /* getDetailData(){
      applyDetails(this.rowInfo.id).then(res => {
        this.examForm = res.data;
      })
      applyDetailsFile(this.rowInfo.id).then(res => {
        console.log(res);
      })
    },*/
    //查看施工单位填报信息
    getConstructData(){
      let params = {
        riskMasterUuid : this.rowInfo.riskMasterUuid
      }
      getRiskCloseSubmit(params).then(res => {
        if(res.code == 0){
          // if(this.type === 'detail'){
          if(res.data){
            this.disposeForm = res.data;
          }else{
            this.reset();
          }

          if(this.type !== 'detail'){
            this.disposeForm.submitTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
          }
          // }
          let isNull = res.data;
          if(Object.keys(isNull).length == 0){
            this.isEdit = false;
          }else{
            this.isEdit = true;
          }
        }
      })
    },
    getConstructFileData(){
      let params = {
        submitUuid : this.rowInfo.id
      }
      applyClearFileList(params).then(res => {
        this.constructFileList = res.data;
      })
    },
    //查看施工单位填报信息
    getExamData(){
      let params = {
        riskMasterUuid : this.rowInfo.riskMasterUuid
      }
      getRiskCloseExamine(params).then(res => {
        if(res.code == 0 && res.data ){
          this.examForm = res.data;

        }
      })

    },
    getExamFileData(){
      let params = {
        examineUuid : this.rowInfo.id
      }
      examineClearFileList(params).then(res => {
        this.examFileList = res.data;
      })
    },
    // 附件上传
    handleFileUpload(e) {
   /*   this.fileList.push({
        fileName: e.name,
        name: e.name,
        fileUrl: e.url,
        filePath : e.realUrl
      });*/
      let params = {
        fileName: e.name,
        name: e.name,
        fileUrl: e.url + ',' + e.realUrl,
        submitUuid : this.rowInfo.id
      }
      applyClearFile(params).then(res => {
        console.log(res);
        if(res.code == 0){
          this.$message.success('上传成功')
        }else{
          this.$message.error(res.message);
        }
      })
    },
    deleteFile(item){
      this.$confirm("确定删除当前文件?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        applyClearFileDelete(item.id).then(res => {
          if(res.code == 0){
            this.$message.success('删除成功');
            this.getConstructFileData();
          }
        })
      })
    },
    // 附件上传
    handleFileUploadExamine(e) {
      let params = {
        fileName: e.name,
        name: e.name,
        fileUrl: e.url + ',' + e.realUrl,
        examineUuid : this.rowInfo.id
      }
      examineClearFile(params).then(res => {
        console.log(res);
        if(res.code == 0){
          this.$message.success('上传成功')
        }else{
          this.$message.error(res.message);
        }
      })
    },
    handleFileDel(e) {
      let index = this.fileList.findIndex(v => v.url === e.url);
      this.fileList.splice(index, 1);
    },
    handleFileClick(record) {
      const link = document.createElement("a");
      link.href = record.url;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
    handleExport(fileUrl,fileName) {
      // handleExport(url, name) {
      //   // 文件下载
      //   var x = new XMLHttpRequest();
      //   x.open("GET", url, true);
      //   x.responseType = "blob";
      //   x.onload = function() {
      //     var url = window.URL.createObjectURL(x.response);
      //     var a = document.createElement("a");
      //     a.href = url;
      //     a.download = name;
      //     a.click();
      //   };
      //   x.send();
      fileUrl = fileUrl.split(',')[1];
      this.downLoadFile(fileUrl,fileName);
    },
    submit(num) {
      this.$refs['examForm'].validate((valid) => {
        if(valid){
          let params = {
            companyTypeCode : this.obj.companyCategoryName,
            companyTypeName : this.obj.companyCategory,
            companyUuid : this.obj.companyId,
            departmentUuid : this.obj.departmentId,
            created : this.examForm.created,
            examineDescribe : this.examForm.examineDescribe,
            examinePerson : this.examForm.examinePerson,
            examinePersonUuid : this.examForm.examinePersonUuid,
            examineStatus : num,
            id : this.rowInfo.id,
            riskDeafUuid : this.rowInfo.riskDeafUuid,
            riskMasterUuid : this.rowInfo.riskMasterUuid,
            riskName : this.rowInfo.riskName,
          }
          examineClear(params).then(res => {
            if(res.code == 0){
              this.$message.success('操作成功');
              this.$emit("CloseDialog");
            }else{
              this.$message.error(res.message);
            }
          })
        }else{
          this.$message.error('请正确填写');
        }
      })
      // this.$emit("CloseDialog");
    },
    submitApply(){
      this.$refs['disposeForm'].validate((valid) => {
        if(valid){
          let params = {
            riskDeafUuid : this.rowInfo.riskDeafUuid,
            riskMasterUuid : this.rowInfo.riskMasterUuid,
            riskName : this.rowInfo.riskName,
            submitDescribe : this.disposeForm.submitDescribe,
            submitPerson : this.disposeForm.submitPerson,
            submitPersonUuid : this.disposeForm.submitPersonUuid,
            submitTime : this.disposeForm.submitTime,
            companyUuid : this.obj.companyId,
            departmentUuid : this.obj.departmentId,
            id : this.rowInfo.id,
          }
          // let applyInterface = this.isEdit === false ? applyClear : applyClearEdit;
          applyClear(params).then(res => {
            if(res.code == 0){
              this.$message.success('操作成功');
              this.$emit("CloseDialog");
            }else{
              this.$message.error(res.message);
            }
          })
        }else{
          this.$message.error('请正确填写');
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  min-height: 253px;
}
.dialogFooter {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .el-button {
    margin: 0 30px;
  }
}
.riskDialogTitle {
  margin-top: 20px;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.risktowTitle {
  text-indent: 12px;
  margin-bottom: 10px;
  font-weight: bold;
}
.riskDialogTitle:first-child {
  margin-top: 0;
}
.fileGroupBox {
  span {
    color: #3fa5e9;
    cursor: pointer;
    margin-left: 10px;
  }
}
</style>
