<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="职位">
            <el-select v-model="queryModal.region" placeholder="请选择职位">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input
              v-model="queryModal.user"
              placeholder="输入姓名查询"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="handleEditTeamInfo"
            >救援队设置</el-button
          >
          <el-button type="primary" @click="handleAdd">新增</el-button>
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column align="center" prop="name" label="姓名" width="180">
        </el-table-column>
        <el-table-column
          align="center"
          prop="address"
          label="所属救援队"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="address"
          label="职务"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="address"
          label="联系电话"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="address"
          label="学历"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="address"
          label="专业"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="address"
          label="特种作业证"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="address"
          label="照片"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="address"
          label="地址"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination background layout="prev, pager, next" :total="1000">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      queryModal: {},
      tableData: [],
    };
  },
  mounted() {},
  methods: {
    handleAdd() {
      this.$refs.modelForm.handleAdd();
    },
    handleEdit(record) {
      this.$refs.modelForm.handleAdd(record);
    },
    handleDelete(record) {
      console.log(record);
    },
    handleEditTeamInfo() {
      this.$refs.infoEditModalForm.handleAdd();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
