<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" :title="title">
    <el-form v-loading="loading" :model="model" :rules="rules" ref="modelForm" label-width="120px" class="demo-model">
      <el-form-item label="线路工点" prop="lineSite">
        <el-cascader
          v-model="model.lineSite"
          :options="lineSiteData"
          @change="handleLineSiteChange"
          :disabled="true"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="演练内容" prop="label">
        <el-input v-model="model.label" placeholder="请输入演练主题" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="演练类型" prop="category">
        <el-select v-model="model.category" placeholder="请选择演练类型" :disabled="true">
          <el-option v-for="item of dictOption.category" :key="item.id" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="演练时间" prop="executeTime">
        <el-date-picker
          v-model="model.executeTime"
          type="date"
          placeholder="请选择演练时间"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="演练方案">
        <FileUploader @uploadChange="handleFileUpload($event, 0)" @delChange="handleFileDel($event, 0)" :value="asyncFile(0)"></FileUploader>
      </el-form-item>
      <el-form-item label="演练脚本">
        <FileUploader @uploadChange="handleFileUpload($event, 1)" @delChange="handleFileDel($event, 1)" :value="asyncFile(1)"></FileUploader>
      </el-form-item>
      <el-form-item label="签到表">
        <FileUploader @uploadChange="handleFileUpload($event, 2)" @delChange="handleFileDel($event, 2)" :value="asyncFile(2)"></FileUploader>
      </el-form-item>
      <el-form-item label="过程图片/视频">
        <FileUploader @uploadChange="handleFileUpload($event, 3)" @delChange="handleFileDel($event, 3)" :value="asyncFile(3)"></FileUploader>
      </el-form-item>
      <el-form-item label="演练总结">
        <FileUploader @uploadChange="handleFileUpload($event, 4)" @delChange="handleFileDel($event, 4)" :value="asyncFile(4)"></FileUploader>
      </el-form-item>
      <el-form-item label="评估表">
        <FileUploader @uploadChange="handleFileUpload($event, 5)" @delChange="handleFileDel($event, 5)" :value="asyncFile(5)"></FileUploader>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { uploadRehearsalFile, editRehearsal, delRehearsalFile } from "@/api/stand";
import FileUploader from '@/components/Uploader/FileUploader.vue';
import dayjs from 'dayjs';
export default {
  components: {
    systemModal,
    FileUploader
  },
  data() {
    return {
      title: "应急演练报告",
      model: {
        lineSite: "",
        lineId: "",
        siteId: "",
        category: "",
        label: "",
        joinCount: "",
        floodSeason: "",
        address: "",
        planDate: "",
        executeTime: ""
      },
      fileList: [],
      dictOption: {
        state: [],
        category: []
      },
      loading: false,
      rules: {
      }
    };
  },
  props: {
    lineSiteData: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  computed: {},
  mounted() {},
  methods: {
    // 线路工点树选择
    handleLineSiteChange(val) {
      this.model.lineId = val[0];
      this.model.siteId = val[1];
    },
    handleReport(record) {
      this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
      this.$set(this.model, "lineSite", [record.lineId, record.siteId]);
      this.model.executeTime = this.model.executeTime ? this.model.executeTime : dayjs().format("YYYY-MM-DD");
      if (record.attachs) {
        this.fileList = record.attachs;
        this.$forceUpdate();
      }
      this.$refs.formModal.handleOpen();
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const params = JSON.parse(JSON.stringify(this.model));
          params.floodSeason = params.floodSeason.toString();
          params.state = 5;
          editRehearsal(params).then(res => {
            if (res.code == 0) {
              this.loading = false;
              this.$message.success("操作成功");
              this.$emit("reportSubmit");
              this.$refs.formModal.handleClose();
              this.resetModel();
              this.fileList = [];
            } else {
              this.loading = false;
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleFileUpload(record, type) {
      const params = {
        planId: this.model.id,
        data: {
          attrName: record.name,
          attrType: type,
          link: record.url,
          localPath: record.url
        }
      };
      uploadRehearsalFile(params).then(res => {
        if (res.code == 0) {
          this.fileList.push(res.data);
          this.$message.success("上传成功");
          this.asyncFile();
        }
      });
    },
    handleFileDel(record) {
      const index = this.fileList.findIndex(item => item.link == record.url);
      delRehearsalFile({ id: this.fileList[index].id }).then(res => {
        if (res.code == 0) {
          this.fileList.splice(index, 1);
          this.$message.success("操作成功");
          this.asyncFile();
        }
      })
    },
    resetModel() {
      this.model = {
        lineSite: "",
        lineId: "",
        siteId: "",
        label: "",
        teacher: "",
        planCount: "",
        localePhoto: []
      }
    },
    asyncFile(type) {
      const data = this.fileList.filter(item => item.attrType == type);
      let fileList = [];
      for (let item of data) {
        fileList.push({
          url: item.link,
          name: item.attrName
        });
      }
      return fileList;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
