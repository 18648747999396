<template>
  <div style="font-size: 16px" v-loading="loading">
    <el-form ref="checkForm" :model="temList" label-width="130px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="事件类型:" prop="typeLabel">
            <el-input
              readonly
              placeholder="事件类型"
              v-model="temList.type.label"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="事件级别:" prop="currentLevelLabel">
            <el-input
              placeholder="事件级别"
              v-model="temList.currentLevel.label"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="启动时间:" prop="startTime">
            <el-input
              placeholder="启动时间"
              v-model="temList.startTime"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发布人:" prop="publisherName">
            <el-input
              placeholder="发布人"
              v-model="temList.publisherName"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="应急响应要求:" prop="respondImage">
            <el-input
              type="textarea"
              resize="none"
              readonly
              placeholder="应急响应要求"
              :autosize="{ minRows: 2, maxRows: 6 }"
              v-model="temList.respondImage"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="margin-bottom: 10px" class="flex justify-between">
      <div>
        <el-radio-group v-model="radio" @change="radioChange">
          <el-radio-button v-show="statisticses == 1" :label="1"
            >三防检查情况</el-radio-button
          >
          <el-radio-button :label="2">市交通统计信息填报情况</el-radio-button>
          <el-radio-button v-show="statisticses == 1" :label="3"
            >工作简报情况</el-radio-button
          >
          <el-radio-button v-show="disaster == 1 && disasters != 2" :label="5"
            >灾害情况损失</el-radio-button
          >
          <el-radio-button :label="4">响应事件进展</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-button
          type="primary"
          @click="briSummary"
          v-if="radio == 3 && deptFlag"
          >汇总数据</el-button
        >
        <el-button
          type="primary"
          v-show="radio == '2' && operated"
          @click="Summary"
          >汇总数据</el-button
        >
      </div>
    </div>
    <div
      class="search-wrapper"
      style="margin-bottom: 10px;
    "
    >
      <el-radio-group
        v-show="radio == '2'"
        v-model="tabRadioThree"
        size="small"
        style="margin-right: 20px"
        @change="getChangeThree"
      >
        <el-radio-button label="工点" v-show="lineSiteBtn"
          >工点</el-radio-button
        >
        <el-radio-button label="专职救援队" v-show="rescueBtn"
          >专职救援队</el-radio-button
        >
        <!-- <el-radio-button
          v-for="item in tabRadioThreeData"
          :key="item.operation"
          :label="item.operationText"
          v-show="item.enabled"
          >{{ item.operationText }}</el-radio-button
        > -->
      </el-radio-group>
      <el-radio-group
        v-show="radio == '3'"
        v-model="tabRadioTwo"
        size="small"
        style="margin-right: 20px"
        @change="getChange"
      >
        <el-radio-button label="工点" v-if="BriefLineSiteBtn"></el-radio-button>
        <el-radio-button label="总包部" v-if="BriefRescueBtn"></el-radio-button>
        <!-- <el-radio-button
          v-for="item in statisticsData"
          :key="item.operation"
          :label="item.operationText"
          v-show="item.enabled"
          >{{ item.operationText }}</el-radio-button
        > -->
      </el-radio-group>
    </div>

    <div v-show="radio != '4'">
      <el-radio-group
        size="mini"
        v-model="degree"
        v-for="item in times"
        :key="item.time"
        @change="degreeChange"
      >
        <el-radio-button
          style="margin: 0 10px 5px 0"
          :label="item.label"
        ></el-radio-button>
      </el-radio-group>
    </div>
    <div class="details" v-show="radio != '4'">
      {{ description }}
    </div>
    <div class="table-wrapper detailHeight mt-4" v-show="radio != '4'">
      <el-table
        ref="tableData"
        :data="tableData"
        style="width: 100%"
        row-key="code"
        border
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          type="index"
          label="序号"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="lineName"
          label="线路/工点"
          header-align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.children == null"
              style="color: #7073ff; cursor: pointer"
              @click="lookLineName(scope.row)"
            >
              {{ scope.row.lineName }}
            </span>
            <span v-if="scope.row.children">
              {{ scope.row.lineName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="constructionManagementName"
          label="所属建管部"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          prop="performance"
          label="完成情况"
          align="center"
          show-overflow-tooltip
          :formatter="manceFormatter"
        >
        </el-table-column>
        <el-table-column
          prop="issueCount"
          label="问题数量"
          align="center"
          show-overflow-tooltip
          v-if="radio == '1'"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.issueList"
              style="color: #7073ff; cursor: pointer"
              @click="lookNamePop(scope.row)"
            >
              {{ scope.row.issueCount }}
            </span>
            <span v-if="scope.row.issueList == null">
              {{ scope.row.issueCount }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 响应事件进展 -->
    <el-timeline
      :reverse="false"
      style="margin-top: 20px"
      v-show="radio == '4'"
    >
      <el-timeline-item
        v-for="(activity, index) in historyData"
        :key="index"
        type="primary"
        color="#3fa5e9"
        :size="'large'"
      >
        <i> </i>
        <i style="font-style: normal; margin-right: 5px">
          {{ activity.operatorTime }}
        </i>
        <span> {{ activity.actionType }}{{ activity.operationValue }} </span>
        <span>
          {{
            activity.actionType == "发布"
              ? activity.record.originalAlertMessage
              : activity.actionType == "结束"
              ? activity.record.endRemark
              : activity.record.relegationRemark
          }}
        </span>
      </el-timeline-item>
    </el-timeline>
    <!-- <div
      class="mb-4"
      style="display: flex;
  justify-content: center;
  margin-top: 20px"
    >
      <el-button type="primary" @click="sureSub">关闭</el-button>
    </div> -->
    <!-- 问题清单 -->
    <el-dialog
      title="问题清单"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      append-to-body
      width="70%"
      center
      class="checkDialog"
    >
      <CheckQuestion
        v-if="dialogVisible"
        :parmsData="parmsData"
      ></CheckQuestion>
    </el-dialog>
    <!-- 三防问题检查表 -->
    <el-dialog
      title="三防应急检查表"
      :visible.sync="dialogVisibleOne"
      append-to-body
      width="50%"
      class="checkDialog"
    >
      <emergencyCheck
        v-if="dialogVisibleOne"
        :operation="oneCheckTitle"
        :oneCheckData="oneCheckData"
        @cancelApproval="cancelApproval"
        @successApproval="successApproval"
      ></emergencyCheck>
    </el-dialog>
    <el-dialog
      title="灾害损失及恢复情况上报详情"
      :visible.sync="examineDialog"
      width="80%"
      :before-close="examieClose"
      center
      :append-to-body="true"
      :modal-append-to-body="false"
      class="checkDialog"
    >
      <examine
        v-if="examineDialog"
        :reportCurrentId="reportCurrentId"
        @examieClose="examieClose"
        :terwe="terwe"
      ></examine>
    </el-dialog>
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="rescueCheckDialog"
      :before-close="rescueCheckClose"
      append-to-body
      width="80%"
      center
    >
      <rescueCheck
        v-if="rescueCheckDialog"
        :approveid="approveid"
        :options="reseuceOptions"
        @rescueCheckClose="rescueCheckClose"
      ></rescueCheck>
    </el-dialog>
    <div v-if="checkerDialog">
      <el-dialog
        title="广州市交通运输系统三防工作统计表"
        :visible.sync="checkerDialog"
        append-to-body
        width="80%"
        center
      >
        <checker
          v-if="checkerDialog"
          :strery="strery"
          :approveid="approveid"
          @chexkpageClose="chexkpageClose"
        ></checker>
      </el-dialog>
    </div>
    <!-- 工作简报 -->
    <briefingsView ref="briefingsViewModal"></briefingsView>
    <briefingsJGView
      ref="briefingsJGViewModal"
      @fingrest="onSearch"
      @btnClose="btnClose"
    ></briefingsJGView>
    <!-- 三防工作简报汇总弹窗 -->
    <el-dialog
      title="汇总数据"
      :visible.sync="briQualitysDialog"
      append-to-body
      width="1350px"
      center
    >
      <briQualitys
        ref="briQualitysMell"
        :userDept="userDept"
        :qualityResponseId="responseRecordId"
      ></briQualitys>
    </el-dialog>
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="qualitysDialog"
      append-to-body
      width="80%"
      center
    >
      <qualitys :ids="aponerids"></qualitys>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCurrentResponseTreeById,
  getCurrentResponseTeamTreeById,
  getCurrentResponseZongBaoTreeById,
  buttondata,
  currentGetOps,
} from "@/api/response";
import CheckQuestion from "./checkQuestion.vue";
import HistoryPage from "./historyPage.vue";
import emergencyCheck from "../emergency/emergencyCheck.vue";
import buildWrite from "../../Proofings/workStatistics/buildWrite.vue";
import { isRotaLeaderOrSafeDepartment } from "@/api/responseTem";
import { getDepartmentTypeByUser } from "@/api/org";
import briQualitys from "../prevention/statistical.vue";
import qualitys from "../statistical/quality.vue";
import checker from "../statistical/checkPage.vue";
import rescueCheck from "../rescueTeam/rescueCheck.vue";
import briefingsView from "../prevention/briefingView.vue";
import briefingsJGView from "../prevention/briefingJGView.vue";
import examine from "../lossste/examine.vue";

export default {
  props: {
    temList: {},
    historyData: {
      type: Array,
      default: () => [],
    },
    disasters: {
      type: Number,
      default: 1,
    },
    statisticses: {
      type: Number,
      default: 1,
    },
  },
  components: {
    HistoryPage,
    CheckQuestion,
    emergencyCheck,
    buildWrite,
    briQualitys,
    qualitys,
    checker,
    briefingsView,
    rescueCheck,
    briefingsJGView,
    examine,
  },
  data() {
    return {
      radio: "1",
      degree: "第1次",
      times: [],
      description: "",
      tableData: [],
      //问题清单
      dialogVisible: false,
      // 三防检查表
      dialogVisibleOne: false,
      // 交通统计表
      checkerDialog: false,
      rescueCheckDialog: false,
      reseuceOptions: "",
      strery: {},
      // 工点/救援队
      tabRadioThree: "工点",
      lineSiteBtn: false,
      rescueBtn: false,
      tabRadioTwo: "工点",
      approveid: {},
      checkId: "", //当前行的id
      responseId: "", //事件id
      parmsData: [],
      loading: false,
      oneCheckTitle: "",
      oneCheckData: {},
      deptFlag: false,
      briQualitysDialog: false,
      responseRecordId: null,
      userDept: "3",
      operated: false,
      aponerids: {},
      qualitysDialog: false,
      disaster: 1,
      // statisticsData: [],
      // tabRadioThreeData: [],
      BriefLineSiteBtn: false,
      BriefRescueBtn: false,
      examineDialog: false,
      terwe: "",
      reportCurrentId: null,
    };
  },
  created() {},
  mounted() {
    this.getrot();
    this.getUserDept();
    // if (this.statisticses == 2) {
    //   this.getTable(141, 1);
    //   this.radio = "2";
    // } else {
    this.getTable(139, 1);
    // }
    this.buttoner();
    currentGetOps({ type: 140 }).then((res) => {
      if (res.code == 0) {
        // this.statisticsData = res.data;
        res.data.forEach((item) => {
          if (item.operationText == "工点") {
            this.BriefLineSiteBtn = item.enabled;
          } else if (item.operationText == "总包部") {
            this.BriefRescueBtn = item.enabled;
          }
        });
      }
    });
    currentGetOps({ type: 141 }).then((res) => {
      if (res.code == 0) {
        // this.tabRadioThreeData = res.data;
        res.data.forEach((item) => {
          if (item.operationText == "工点") {
            this.lineSiteBtn = item.enabled;
          } else if (item.operationText == "专职救援队") {
            this.rescueBtn = item.enabled;
          }
        });
        console.log("数据", res);
      }
    });
  },
  methods: {
    //权限按钮
    buttoner() {
      buttondata({ id: this.temList.id }).then((res) => {
        if (res.code == 0) {
          res.data.forEach((item) => {
            if (item.operationText == "汇总数据") {
              this.operated = item.enabled;
            }
          });
        }
      });
    },
    Summary() {
      this.aponerids = Object.assign({}, this.temList);
      this.qualitysDialog = true;
    },
    getTable(val, num) {
      this.loading = true;
      this.description = "";
      this.times = [];
      this.responseRecordId = this.temList.id;
      let paramsData = {
        responseRecordId: this.temList.id,
        type: val,
        number: num,
      };
      getCurrentResponseTreeById(paramsData)
        .then((res) => {
          if (res.code == 0) {
            this.description = res.data.description;
            this.tableData = res.data.treeList ? res.data.treeList : [];
            if (res.data.maxNumber) {
              for (let i = 1; i <= res.data.maxNumber; i++) {
                this.times.push({
                  label: `第${i}次`,
                  value: i,
                });
              }
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.tableData = [];
          }
        })
        .catch((err) => {
          this.tableData = [];
          console.log(err);
          this.loading = false;
        });
    },
    // 救援队
    getRescue(val, num) {
      this.loading = true;
      this.description = "";
      this.times = [];
      this.responseRecordId = this.temList.id;
      let paramsData = {
        responseRecordId: this.temList.id,
        type: val,
        number: num,
      };
      getCurrentResponseTeamTreeById(paramsData)
        .then((res) => {
          if (res.code == 0) {
            this.description = res.data.description;
            this.tableData = res.data.treeList ? res.data.treeList : [];
            if (res.data.maxNumber) {
              for (let i = 1; i <= res.data.maxNumber; i++) {
                this.times.push({
                  label: `第${i}次`,
                  value: i,
                });
              }
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.tableData = [];
          }
        })
        .catch((err) => {
          this.tableData = [];
          console.log(err);
          this.loading = false;
        });
    },
    // 总包
    getGeneral(val, num) {
      this.loading = true;
      this.description = "";
      this.times = [];
      this.responseRecordId = this.temList.id;
      let paramsData = {
        responseRecordId: this.temList.id,
        type: val,
        number: num,
      };
      getCurrentResponseZongBaoTreeById(paramsData)
        .then((res) => {
          if (res.code == 0) {
            this.description = res.data.description;
            this.tableData = res.data.treeList ? res.data.treeList : [];
            if (res.data.maxNumber) {
              for (let i = 1; i <= res.data.maxNumber; i++) {
                this.times.push({
                  label: `第${i}次`,
                  value: i,
                });
              }
            }
            this.loading = false;
          }
        })
        .catch((err) => {
          this.tableData = [];
          console.log(err);
          this.loading = false;
        });
    },

    // 0-待填写、1-待监理审核、2-待建管审核、3-待质安部审核、4-已完成
    manceFormatter(row) {
      const condition = ["0", "1", "2", "3", "4", "5"];
      if (condition.includes(row.performance)) {
        return row.performance == 0
          ? "待填报"
          : row.performance == 1
          ? "总包已驳回"
          : row.performance == 2
          ? "建管部已驳回"
          : row.performance == 3
          ? "待总包审批"
          : row.performance == 4
          ? "待建管部确认"
          : row.performance == 5
          ? "已完成"
          : "";
      } else {
        return row.performance;
      }
    },
    // change切换
    radioChange(val) {
      if (val == 1) {
        this.getTable(139, 1);
      } else if (val == 2) {
        if (this.lineSiteBtn && !this.rescueBtn) {
          this.tabRadioThree = "工点";
          this.getTable(141, 1);
        } else if (this.rescueBtn && !this.lineSiteBtn) {
          this.tabRadioThree = "专职救援队";
          this.getRescue(141, 1);
        } else if (this.rescueBtn && this.lineSiteBtn) {
          this.tabRadioThree = "工点";
          this.getTable(141, 1);
        }
      } else if (val == 3) {
        if (this.BriefLineSiteBtn && !this.BriefRescueBtn) {
          this.tabRadioTwo = "工点";
          this.getTable(140, 1);
        } else if (this.BriefRescueBtn && !this.BriefLineSiteBtn) {
          this.tabRadioTwo = "总包部";
          this.getGeneral(140, 1);
        } else if (this.BriefRescueBtn && this.BriefLineSiteBtn) {
          this.tabRadioTwo = "工点";
          this.getTable(140, 1);
        }
      } else if (val == 5) {
        this.getTable(347, 1);
      }
      this.degree = "第1次";
      this.$nextTick(() => {
        this.$refs.tableData.doLayout();
        // el-table添加ref="tableName"
      });
    },
    // 工点/救援队切换
    getChangeThree(val) {
      if (val == "工点") {
        this.getTable(141, 1);
      } else {
        this.getRescue(141, 1);
      }
      this.degree = "第1次";
    },
    // 工点/总包
    getChange(val) {
      if (val == "工点") {
        this.getTable(140, 1);
      } else {
        this.getGeneral(140, 1);
      }
      this.degree = "第1次";
    },
    // 次数
    degreeChange(val) {
      var num = val.replace(/[^\d]/g, " ");
      if (this.radio == 2) {
        if (this.tabRadioThree == "工点") {
          this.getTable(141, num);
        } else {
          this.getRescue(141, num);
        }
      } else if (this.radio == 3) {
        if (this.tabRadioTwo == "工点") {
          this.getTable(140, num);
        } else {
          this.getGeneral(140, num);
        }
      } else if (this.radio == 1) {
        this.getTable(139, num);
      }
    },
    // 线路/工点
    lookLineName(val) {
      console.log("lookLineName", val);
      if (val.performance == 0) {
        this.$message.warning("待填写单位填写后方可查看");
      } else {
        if (this.radio == 1) {
          this.dialogVisibleOne = true;
          this.oneCheckTitle = "查看";
          this.oneCheckData = { ...val };
        } else if (this.radio == 2) {
          var ser = "1";
          this.approveid = Object.assign({}, val);
          this.btnLoading = false;
          if (this.tabRadioThree == "工点") {
            this.strery = Object.assign({}, ser);
            this.checkerDialog = true;
          } else {
            this.reseuceOptions = "查看";
            this.rescueCheckDialog = true;
          }
        } else if (this.radio == 3) {
          if (this.tabRadioTwo == "工点") {
            this.$refs.briefingsViewModal.handleFilling(val, "read");
          } else if (this.tabRadioTwo == "总包部") {
            this.$refs.briefingsJGViewModal.handleFilling(val, "read");
          }
        } else if (this.radio == 5) {
          this.terwe = "查看";
          this.examineDialog = true;
          this.reportCurrentId = val.id;
        }
      }
    },
    // 检查表
    successApproval() {
      this.dialogVisibleOne = false;
    },
    cancelApproval() {
      this.dialogVisibleOne = false;
    },
    // 统计表
    rescueCheckClose() {
      this.rescueCheckDialog = false;
    },
    // 灾害
    examieClose() {
      this.examineDialog = false;
    },
    // 问题数量
    lookNamePop(val) {
      this.dialogVisible = true;
      this.parmsData = val.issueList;
    },
    // sureSub() {
    //   this.$emit("detailClose");
    // },
    //获取当前用户是什么部门
    getUserDept() {
      getDepartmentTypeByUser()
        .then((res) => {
          this.userDept = res.data;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    //判断当前响应页面列表是否有建管部按钮权限
    getrot() {
      isRotaLeaderOrSafeDepartment({}).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            this.deptFlag = res.data;
          }
        }
      });
    },
    briSummary() {
      this.briQualitysDialog = true;
      this.$refs.briQualitysMell.getTableData();
    },
    chexkpageClose() {
      this.checkerDialog = false;
    },
    rescueCheckClose() {
      this.rescueCheckDialog = false;
    },
    btnClose() {},
    onSearch() {},
  },
};
</script>

<style lang="scss" scoped>
.checkDialog ::v-deep .el-dialog__body {
  height: 700px;
  overflow: auto;
}
.checkDialog ::v-deep .el-dialog--center .el-dialog__body {
  padding: 0 25px !important;
}
::v-deep.el-table::before {
  display: none;
}
::v-deep .el-table__header-wrapper {
  position: sticky;
  top: 0;
  z-index: 1;
}
.mb-4 {
  position: absolute;
  bottom: 1rem;
  left: 49%;
}

// /*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}
// /*定义滚动条的轨道颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-track {
  display: none;
}
/*定义滑块颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar {
  display: block;
  width: 16px;
  height: 7px;
}
// 滑块设置
::v-deep ::-webkit-scrollbar-thumb {
  background-color: rgb(205, 199, 199);
  border-radius: 0;
}
// .detailHeight ::v-deep .el-table__body-wrapper {
//   height: 348px !important;
// }
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
</style>
