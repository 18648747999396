<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
             label-width="120px" class="demo-model">

      <el-row>
        <el-col :span="16">
          <el-form-item label="标题" prop="itemName">
            <el-input v-model="title" disabled placeholder="标题"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查类型" prop="itemName">
            <el-input v-model="escalation.taskType" disabled placeholder="排查类型"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查单位" prop="itemName">
            <el-input v-model="pcCompany" disabled placeholder="排查单位"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查人员" prop="itemName">
            <el-input v-model="escalation.personLiable" disabled placeholder="排查人员"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查时间" prop="itemName">
<!--            <el-input v-model="escalation.screeningTime" placeholder="排查时间"></el-input>-->
            <el-date-picker
                v-model="escalation.screeningTime"
                style="width: 100%"
                disabled
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="线路" prop="itemName">
            <el-input v-model="escalation.lineName" disabled placeholder="线路"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="分部/标段" prop="itemName">
            <el-input v-model="escalation.branchName" disabled placeholder="分部/标段"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工点" prop="itemName">
            <el-input v-model="escalation.workAreaName" disabled placeholder="工点"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="任务人员" prop="itemName" v-if="type === '专项排查' ">
            <el-input type="textarea" v-model="escalation.checkName" :rows="4"
                      disabled placeholder="工点"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="隐患类别" prop="itemName">
            <el-input v-model="escalation.categoryPname" disabled placeholder="隐患类别"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="隐患子类/子项" prop="itemName">
            <el-input v-model="escalation.categoryName" disabled placeholder="隐患子类/子项"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="隐患等级" prop="itemName">
            <el-input v-model="escalation.hiddenLevelName" disabled  placeholder="隐患等级"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="隐患条目" prop="itemName">
            <el-input v-model="escalation.itemName" placeholder="隐患条目"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="责任人" prop="itemName">
            <el-input v-model="escalation.personLiable" placeholder="责任人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="整改单位" prop="itemName">
            <el-input v-model="escalation.reformUnitName" placeholder="整改单位"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="整改人" prop="itemName">
<!--            <el-tooltip class="item" effect="dark" :content="reformInfo" placement="top">-->
              <el-input v-model="reformInfo" disabled placeholder="整改人"></el-input>
<!--            </el-tooltip>-->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="整改期限" prop="itemName">
            <el-input v-model="escalation.reformDay" disabled placeholder="整改期限"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="隐患描述" prop="itemName">
            <el-input v-model="escalation.hdInfo" type="textarea" placeholder="隐患描述"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="整改要求" prop="itemName">
            <el-input v-model="escalation.reformRequire" type="textarea" placeholder="整改要求"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="现场图片" prop="itemName">
<!--            <el-input v-model="ruleForm.itemName" placeholder="隐患条目名称"></el-input>-->
            <ImageUploader @change="uploader" @remove="remove"></ImageUploader>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelModal">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>

  </div>
</template>

<script>
import ImageUploader from "./ImageUploader";
import {dayTroubleReport, getNodeRole} from "@/api/hdanger";
import {getDictData} from "@/api/dict";
// import {dayTroubleReport} from "@/api/hdanger";
export default {
  name: "ecalation",
  components : {
    ImageUploader
  },
  props : {
    escalation : {
      default : () => {}
    },
    title : {
      default : ''
    },
    taskId : {
      default : ''
    },
    type : {
      default : ''
    },
  },
  data(){
    return {
      ruleForm : {},
      rules : {},
      fileList : [],
      pcCompany : JSON.parse(localStorage.getItem('user')).companyCategoryName,
      releaseUnitType : JSON.parse(localStorage.getItem('user')).companyCategory,
      reformInfo : '',
      troubleshooting : ''
    }
  },
  created() {
    this.escalation.taskType = '日常排查';
    this.getNodeInfo();
    this.escalation.screeningTime = this.getNowTime('time')
  },
  methods : {
    getNowTime(type) {
      var date = new Date();
      //年 getFullYear()：四位数字返回年份
      var year = date.getFullYear(); //getFullYear()代替getYear()
      //月 getMonth()：0 ~ 11
      var month = date.getMonth() + 1;
      //日 getDate()：(1 ~ 31)
      var day = date.getDate();
      //时 getHours()：(0 ~ 23)
      var hour = date.getHours();
      //分 getMinutes()： (0 ~ 59)
      var minute = date.getMinutes();
      //秒 getSeconds()：(0 ~ 59)
      var second = date.getSeconds();
      var time = year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
      var today = year + '-' + this.addZero(month) + '-' + this.addZero(day);
      return type === 'time' ? time : today;
    },
    addZero(s) {
      return s < 10 ? ('0' + s) : s;
    },
    cancelModal(){
      this.$emit('cancelModal')
    },
    uploader(event){
      console.log(event)
      let params = {
        fileName : event.originalName,
        fileUrl : event.uri,
      }
      this.fileList.push(params);
    },
    remove(list){
      console.log(list);
      this.fileList = list.map(item => {
        return {
          fileName : item.originalName,
          fileUrl : item.uri,
        }
      });
    },
    getNodeInfo(){
      let params = {
        hiddenLevel : this.escalation.hiddenLevel,
        thisnode : this.hiddenLevel === 'I' ? 1 : 5,
        unitType : this.escalation.reformUnitType,
      }
      getNodeRole(params).then(res => {
        this.reformInfo = res.data;
      })
    },
    getDictList(){
      getDictData('screeningType').then(res => {
        this.troubleshooting = res.data;
      })
    },
    submit(){
      this.$refs['ruleForm'].validate((valid) => {
        if(valid){
          console.log(this.escalation)
       /*   "created": "",
              "deleted": true,
              "fileList": [],
              "hdInfo": "",
              "hiddenLevel": "",
              "id": 0,
              "itemId": 0,
              "itemName": "",
              "personLiable": "",
              "reformRequire": "",
              "screeningTime": "",
              "status": 0,
              "taskId": 0,
              "taskType": "",
              "updated": "",
              "version": 0*/
          let params = {
            created : this.escalation.created,
            fileList : this.fileList,
            hdInfo : this.escalation.hdInfo,
            hiddenLevel : this.escalation.hiddenLevel,
            itemId : this.escalation.id,
            itemName : this.escalation.itemName,
            personLiable : this.escalation.personLiable,
            reformRequire : this.escalation.reformRequire,
            screeningTime : this.escalation.screeningTime,
            status : this.escalation.hiddenLevelName === 'Ⅰ' ? 0 : 1,
            updated : this.escalation.updated,
            version : this.escalation.version,
            lineUuid : this.escalation.lineUuid,
            lineName : this.escalation.lineName,
            branchUuid : this.escalation.branchUuid,
            branchName : this.escalation.branchName,
            workAreaUuid : this.escalation.workAreaUuid,
            workAreaName : this.escalation.workAreaName,
            reformUnitType : this.escalation.reformUnitType,
            releaseUnitType :this.releaseUnitType,
            taskRecordId : this.taskId,
            screeningType : this.type === '日常排查' ? 'everyday_check' : 'special_check'
          }
          dayTroubleReport(params).then(res => {
            if(res.code == 0){
              let message  = this.escalation.hiddenLevelName === 'Ⅰ'
                  ? '已上报建设公司质安部，等待审核确认' : '已成功发布隐患';
              this.$message.info(message);
              this.$emit('successRelease');
            }else{
              this.$message.error(res.msg);
            }
          })
        }else{
          this.$message.error('请正确填写');
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer{
  margin-top: 20px;
}
</style>
