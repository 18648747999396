<template>
  <!-- 部门权限 -->
  <div class="organization min-h-full bg-white">
    <div class="searchbar">
      <div class="searchbar-left">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="small"
        >
          <el-form-item label="单位/部门名称">
            <el-input
              v-model="formInline.name"
              placeholder="单位/部门名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="单位类型">
            <el-select
              v-model="formInline.category"
              clearable
              filterable
              placeholder="单位类型"
            >
              <el-option
                v-for="item in categoryData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- <div class="searchbar-right">
        <el-button
          @click="dialogVisible = true"
          class="add_button"
          type="primary"
          size="small"
        >
          <span>导出</span>
        </el-button>
      </div> -->
    </div>
    <div>
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="uuid"
        default-expand-all
        height="78vh"
        size="mini"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column type="index" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column prop="name" header-align="center" label="单位名称">
        </el-table-column>
        <el-table-column prop="categoryName" align="center" label="单位类型">
        </el-table-column>
        <el-table-column
          prop="vendorOrDeptName"
          align="center"
          label="单位/部门"
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="allot(scope.row)"
              v-has="'deptPermission:assign'"
              >分配权限</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-dialog
        v-if="dialogVisible"
        class="system-modal"
        :width="'450px'"
        title="分配权限"
        center
        :visible.sync="dialogVisible"
        :before-close="handleClose"
      >
        <div class="tree-wrapper h-full overflow-y-scroll mr-4">
          <el-tree
            v-loading="treeLoading"
            :data="treeData"
            show-checkbox
            node-key="uuid"
            :props="defaultProps"
            ref="treeRef"
            accordion
            :default-checked-keys="defaultCheckedKeys"
          >
          </el-tree>
        </div>
        <span slot="footer" class="dialog-footer">
          <span>
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="handleSubmit">确 定</el-button>
          </span>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { getUserDeptList } from "@/api/org";
import { tripleTree } from "@/api/map";
import { queryDictNodesById } from "@/api/dict";
import { saveTree, getTreeList } from "@/api/resource";
export default {
  name: "Organization",
  components: {},
  data() {
    return {
      dialogVisible: false,
      tableLoading: false,
      treeLoading: false,
      formInline: {
        name: "",
        category: "",
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      treeData: [],
      tableData: [],
      categoryData: [],
      defaultId: "",
      defaultCheckedKeys: [],
      // 分配权限参数
      deptId: "",
      siteUuIdList: [],
    };
  },
  mounted() {
    this.getList(); 
    this.getData();
    this.getTripleTree();
  },
  methods: {
    // 列表
    getList() {
      this.tableLoading = true;
      let params = {
        name: this.formInline.name,
        category: this.formInline.category,
      };
      getUserDeptList(params)
        .then((res) => {
          if (res.code === 0) {
            this.tableData = res.data;
            this.tableLoading = false;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    //分配权限弹出框
    getTripleTree() {
      let params = {};
      tripleTree(params)
        .then((res) => {
          if (res.code === 0) {
            this.treeData = res.data;
          }
        })
        .catch((res) => {
          this.$message({
            message: res.message,
            type: "error",
          });
        });
    },
    // 单位类型字典
    getData() {
      let params = {
        id: 306,
      };
      this.categoryData = [];
      queryDictNodesById(params)
        .then((res) => {
          if (res.code === 0) {
            res.data.forEach((item) => {
              this.categoryData.push({
                label: item.intro,
                value: item.code,
              });
            });
          }
        })
        .catch((res) => {
          this.$message({
            message: res.message,
            type: "error",
          });
        });
    },
    allot(rows) {
      this.treeLoading = true;
      this.defaultCheckedKeys = [];
      this.dialogVisible = true;
      this.deptId = rows.uuid;
      let params = {
        deptId: rows.uuid,
      };
      getTreeList(params)
        .then((res) => {
          this.treeLoading = false;
          this.defaultCheckedKeys = [...res.data];
        })
        .catch((res) => {
          this.$message({
            message: res.message,
            type: "error",
          });
        });
    },
    // 查询
    onSubmit() {
      this.getList();
    },
    handleClose() {
      this.dialogVisible = false;
      this.$refs.treeRef.setCheckedKeys([]);
      this.getList()
    },
    // 分配权限保存
    handleSubmit() {
      let params = {
        deptId: this.deptId,
        siteUuIdList: [],
        siteIdList: [],
      };
      const selectNodeData = this.$refs.treeRef.getCheckedNodes(true);
      for (let item of selectNodeData) {
        params.siteUuIdList.push(item.uuid);
        params.siteIdList.push(item.id);
      }
      if (params.siteUuIdList.length) {
        this.treeLoading = true;
        saveTree(params)
          .then((res) => {
            if (res.code === 0) {
              this.dialogVisible = false;
              this.treeLoading = false;
              this.$message.success("保存成功");
              this.getList();
              this.$refs.treeRef.setCheckedKeys([]);
            }
          })
          .catch((res) => {
            this.$message({
              message: res.message,
              type: "error",
            });
          });
      } else {
        this.$message.warning("请至少勾选一个工点");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.organization {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  .searchbar {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .el-dialog__body {
    height: 500px;
    overflow: auto;
  }
}
</style>
