<template>
  <systemModal ref="formModal" :title="title" :showFooter="false" width="40%" @modalClose="handleClose">
    <el-form v-loading="loading" :model="model" ref="modelForm" label-width="80px" class="demo-model" style="width: 95%">
      <el-form-item label="通知内容" prop="lineSite">
        {{ model.noticeDescription }}
      </el-form-item>
      <el-form-item label="线路工点" prop="modelNo">
        {{ model.lineName + model.siteName }}
      </el-form-item>
      <el-form-item label="发布人员" prop="initiatorName">
        {{ model.initiatorName }}
      </el-form-item>
      <el-form-item label="发布时间" prop="standardCount">
        {{ model.createTime }}
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
export default {
  components: {
    systemModal
  },
  data() {
    return {
      title: "通知详情",
      disabled: false,
      model: {
        siteName: "",
        level: "",
        reason: "",
        date: "",
        handlar: "",
      },
      dict: {},
      loading: false
    };
  },
  filters: {},
  mounted() {},
  methods: {
    handleDetail(record) {
      this.model = Object.assign({}, record);
      this.$refs.formModal.handleOpen();
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then(res => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then(res => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          })
        }
      });
    },
    handleClose() {
      this.$emit("close");
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}
::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
// ::v-deep .el-form-item__content{
//   margin-left: 0 !important;
//   width: 100%;
// }
</style>
