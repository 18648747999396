<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
            <el-cascader
              v-model="queryModal.linsiteIds"
              clearable
              :options="lineSiteData"
              :props="props"
              :show-all-levels="false"
              placeholder="线路工点"
              collapse-tags
              @change="lineChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="">
            <el-select
              v-model="queryModal.riskTypeCode"
              clearable
              placeholder="风险类别"
              @change="handleSonCate"
            >
              <el-option
                v-for="item in riskCategory"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryModal.riskSubTypeCode"
              clearable
              placeholder="风险子类"
            >
              <el-option
                v-for="item in riskSubTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="queryModal.riskName"
              clearable
              placeholder="风险名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryModal.riskLevelCode"
              clearable
              placeholder="风险控制等级"
            >
              <el-option
                v-for="item in riskControl"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData">查询</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div style="margin-bottom:10px">
      <el-radio-group v-model="radio" @change="radioChange">
        <el-radio-button :label="1">待初次交底</el-radio-button>
        <el-radio-button :label="2">已初次交底</el-radio-button>
      </el-radio-group>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="branchName"
          label="分部/标段"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="工点"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskTypeName"
          label="风险类别"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskSubTypeName"
          label="风险子类"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskName"
          label="风险名称"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="mileageRange"
          label="风险里程"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskDescribe"
          show-overflow-tooltip
          label="风险描述"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskLevelName"
          show-overflow-tooltip
          label="风险控制等级"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskYear"
          label="年度/季度"
          show-overflow-tooltip
          width=""
        >
          <template slot-scope="scope">
            {{ scope.row.riskYear + "年度第" + scope.row.riskQuarter + "季度" }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="radio == 1"
              @click="handleView(scope.row, 'edit')"
              >风险交底</el-button
            >
            <el-button
              type="text"
              v-if="radio == 2"
              @click="handleView(scope.row, 'view')"
              >查看</el-button
            >
            <el-button
              type="text"
              v-if="radio == 2"
              @click="handleView(scope.row, 'edit')"
              >再次交底</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      width="70%"
      title="风险交底"
      class="editDialog details-dialog"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      center
      top="10vh"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <BaseInfo :risk-deaf-uuid="riskDeafUuid"></BaseInfo>
      <!-- <AddPage :pageData="pageData"></AddPage> -->
      <!-- <BaseInfo :onlyInput="true" :pageData="pageData"></BaseInfo> -->
      <div class="riskDialogTitle">风险交底</div>
      <DisclosureRisk
        :rowData="rowData"
        :disclosureType="disclosureType"
        @handlerCloseDia="handlerCloseDia"
      ></DisclosureRisk>
    </el-dialog>
  </div>
</template>

<script>
import { riskBottompage } from "@/api/riskManage";
// import { getMsg } from "@/api/risk";
import { getDictData } from "@/api/dict";
import BaseInfo from "./components/BaseInfo";
// import BaseInfo from "../../RiskList/RiskListLibrary/modules/addPage.vue";
// import AddPage from "../../RiskList/RiskMeasures/modules/addPage.vue";
import DisclosureRisk from "./components/DisclosureRisk";
export default {
  components: {
    BaseInfo,
    // AddPage,
    DisclosureRisk
  },
  data() {
    return {
      queryModal: {
        linsiteIds: [],
        siteUuidList: [],
        riskTypeCode: "",
        riskSubTypeCode: "",
        riskName: "",
        riskLevelCode: "",
        processStatus: 0
      },
      lineSiteData: [],
      loading: false,
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      rowData: {}, //当前行数据
      riskCategory: [], //风险类别
      riskSubTypeList: [], //风险子类
      riskControl: [], //风险控制等级
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      radio: 1,
      tableData: [],
      dialogVisible: false,
      activeName: "风险交底",
      riskDeafUuid: "",
      pageData: {},
      disclosureType: "" //风险交底类型
    };
  },
  created() {
    this.getLineTree();
    this.getDict();
    this.getData();
  },
  methods: {
    async getLineTree() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.lineSiteData = JSON.parse(JSON.stringify(lineSiteData));
      }
    },
    getDict() {
      getDictData("riskType").then(res => {
        if (res.code == 0) {
          this.riskCategory = res.data;
        }
      });
      getDictData("riskControlLevel").then(res => {
        if (res.code == 0) {
          this.riskControl = res.data;
        }
      });
    },
    handleSonCate(val) {
      this.queryModal.riskSubTypeCode = "";
      this.riskSubTypeList = [];
      if (val.length > 0) {
        getDictData(val).then(res => {
          if (res.code == 0) {
            this.riskSubTypeList = res.data;
          }
        });
      }
    },
    lineChange(val) {
      this.queryModal.siteUuidList = [];
      if (val.length > 0) {
        for (let item of val) {
          this.queryModal.siteUuidList.push(item[item.length - 1]);
        }
      }
    },
    // change切换
    radioChange(val) {
      if (val == 1) {
        //未响应
        this.queryModal.processStatus = 0;
      } else if (val == 2) {
        //已响应
        this.queryModal.processStatus = 1;
      }
      this.getData();
    },
    getData() {
      this.loading = true;
      let params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.queryModal
      };
      riskBottompage(params).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.page.total = res.data.total;
          this.loading = false;
        }
      });
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getData();
    },
    handleView(row, type) {
      console.log(row);
      this.dialogVisible = true;
      this.disclosureType = type;
      this.riskDeafUuid = row.riskDeafUuid;
      this.rowData = { ...row };
    },
    handleClose() {
      this.dialogVisible = false;
      this.activeName = "风险交底";
      this.getData();
    },
    handleClick() {
      // d
    },
    handlerCloseDia() {
      this.dialogVisible = false;
      this.activeName = "风险交底";
      this.getData();
    }
  }
};
</script>

<style lang="scss" scoped>
.riskDialogTitle {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
}
::v-deep .details-dialog {
  .el-dialog__header {
    .el-dialog__title {
      position: static;
    }
  }
  .el-dialog__body {
    max-height: 680px;
    overflow: auto;
  }
}
</style>
