<template>
  <div class="min-h-full bg-white">
    <div class="flex justify-between p-4">
      <el-row class="flex">
        <el-cascader
          v-model="filters.lineSite"
          clearable
          :options="treeData"
          :props="props"
          :show-all-levels="false"
          collapse-tags
          style="margin-right: 10px"
          @change="handleLineSiteChange"
        ></el-cascader>

        <el-date-picker
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          v-model="timeRange"
          type="daterange"
          style="margin-right: 10px"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
        ></el-date-picker>

        <el-select
          class="mr-2"
          v-model="filters.warnStatus"
          clearable
          placeholder="请选择报警原因"
        >
          <el-option
            v-for="item in warnTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.code"
          ></el-option>
        </el-select>

        <el-select
          class="mr-2"
          v-model="filters.dealAlarmSwitch"
          clearable
          placeholder="是否需要消警"
        >
          <el-option label="不需消警" value="0"></el-option>
          <el-option label="待消警" value="1"></el-option>
          <el-option label="已消警" value="2"></el-option>
        </el-select>

        <el-button type="primary" @click="findPage">搜索</el-button>
      </el-row>

      <el-row>
        <!--        <el-button type="primary" style="display: none" @click="handleDelete">批量删除</el-button>-->
        <!--        <el-button type="primary" @click="exportExcel">导出</el-button>-->
      </el-row>
    </div>

    <div class="px-4">
      <el-table
        :data="warnList"
        ref="qtable"
        size="mini"
        :row-style="{ height: 61 + 'px' }"
        style="overflow: auto"
        v-loading="loading"
        @selection-change="handleSelectionChange"
        :cell-style="cellStyle"
      >
        <el-table-column align="center" type="selection"></el-table-column>
        <el-table-column
          align="center"
          width="80"
          label="序号"
          type="index"
        ></el-table-column>
        <el-table-column
          prop="deviceName"
          header-align="center"
          align="center"
          label="设备名称"
        >
        </el-table-column>
        <el-table-column
          prop="site"
          header-align="center"
          align="center"
          label="工点"
        ></el-table-column>

        <el-table-column label="起吊时间" align="center" prop="craneTime"> </el-table-column>
        <el-table-column label="落吊时间" align="center" prop="alarmReason"> </el-table-column>
        <el-table-column label="预警规则名称" align="center" prop="rrange"> </el-table-column>
        <el-table-column label="预警内容" align="center" prop="obliguity"> </el-table-column>
        <el-table-column label="规则值" align="center" prop="windSpeed"> </el-table-column>
        <el-table-column label="实际值" align="center" prop="momentPercent"> </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-button
                type="text"
                size="small"
                @click="goDetail(scope.row)"
                v-has="'role:assign'"
                class="mr-4"
                >查看</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="handleDelete(scope.row)"
                v-has="'role:del'"
                >删除</el-button
              >
              <el-button
                type="text"
                size="small"
                :disabled="scope.row.dealAlarmSwitch !== 1"
                @click="handleWarn(scope.row)"
                v-has="'role:del'"
                >消警</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!--分页栏-->
      <div class="toolbar" style="padding: 10px">
        <el-pagination
          layout="total,sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="refreshPageRequest"
          :current-page.sync="filters.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="filters.pageSize"
          :total="totalSize"
          style="text-align: right"
        ></el-pagination>
      </div>
    </div>

    <detailDialog
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      :detail="detailData"
      :warnTypeList="warnTypeList"
      :sensorStatusList="sensorStatusList"
      :operateList="operateList"
      :title="title"
      @cancel="cancel"
      @submit="submit"
      @close="dialogVisible = false"
    ></detailDialog>

    <el-dialog
      title="消警"
      v-if="dialogWarn"
      :visible.sync="dialogWarn"
      width="40%"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      @close="cancelWarn"
    >
      <el-form
        :model="warnForm"
        label-width="130px"
        ref="warnForm"
        :size="size"
        :rules="warnRules"
        :validate-on-rule-change="false"
      >
        <el-form-item label="处理后现场图片" prop="isHasImg">
          <Uploader @uploader="expertUploader" accept=".jpg, .png"></Uploader>
          <div
            class="fileList"
            style="margin-top: 10px"
            v-if="warnForm.dealImageList.length > 0"
          >
            <div
              class="file-item"
              v-for="(item, index) in warnForm.dealImageList"
              :key="index"
            >
              <el-image
                style="width: 100px; height: 100px"
                :src="item.fileUrl"
                :preview-src-list="[item.fileUrl]"
              ></el-image>
              <i
                class="el-icon-circle-close"
                @click="handlerDel(item, index)"
              ></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="处理说明" prop="dealRemark">
          <el-input
            type="textarea"
            :rows="3"
            v-model="warnForm.dealRemark"
          ></el-input>
        </el-form-item>
        <el-form-item label="处理证明文件" prop="dealFileList">
          <Uploader @uploader="dealUploader"></Uploader>
        </el-form-item>
        <el-form-item
          v-if="warnForm.dealFileList.length > 0"
          label="文件列表"
          style="text-align: left"
        >
          <div class="fileList">
            <p
              style="width:100%; display: flex;justify-content: space-between"
              v-for="(item, index) in warnForm.dealFileList"
              :key="index"
            >
              {{ item.name || item.fileName }}
              <span
                @click="delFile(index)"
                style="color: #7073FF;cursor: pointer;"
                >删除</span
              >
            </p>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :size="size" @click="cancelWarn">取 消</el-button>
        <el-button :size="size" @click="submitWarn">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  crawlerDataDealAlarmById,
  exportInfo,
  crawlerDataPage,
  crawlerDataDelete
} from "@/api/deviceManagement";
import { getDictData } from "@/api/dict";
import detailDialog from "@/views/DeviceManagent/components/CrawlerDetailDialog";
import Uploader from "@/views/DeviceManagent/components/Uploader";

export default {
  name: "towerWarn",
  components: {
    detailDialog,
    Uploader
  },
  data() {
    return {
      filters: {
        id: "",
        startTime: "",
        endTime: "",
        warnItem: "",
        warnItemId: "",
        isWarn: 0,
        pageNum: 1,
        pageSize: 10,
        siteIdList: [],
        warnStatus: "",
        dealAlarmSwitch: ""
      },
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      timeRange: [],
      warnReason: [],
      isClear: [],
      warnList: [],
      multipleSelection: [],
      loading: false,
      totalSize: 0,
      detailData: {},
      title: "",
      dialogVisible: false,
      warnForm: {
        isHasImg: "",
        dealImageList: [],
        dealFileList: []
      },
      dialogWarn: false,
      warnRules: {
        dealRemark: [
          { required: true, message: "请输入处理说明", trigger: "blur" }
        ],
        isHasImg: [{ required: true, message: "请上传图片", trigger: "blur" }]
      },
      size: "small",
      warnTypeList: [],
      sensorStatusList: [],
      operateList: []
    };
  },
  created() {
    this.getLineSiteTreeData();
    this.findPage();
    this.dictLabel();
  },
  methods: {
    dictLabel() {
      let dictArray = [
        "crawler_crane_warn_type",
        "crawler_crane_sensor_status",
        "crawler_operate_status"
      ];
      dictArray.forEach(item => {
        this.getDictData(item);
      });
    },
    getDictData(label) {
      getDictData(label).then(res => {
        if (res.code == 0) {
          switch (label) {
            case "crawler_crane_warn_type":
              this.warnTypeList = res.data;
              break;
            case "crawler_crane_sensor_status":
              this.sensorStatusList = res.data;
              break;
            case "crawler_operate_status":
              this.operateList = res.data;
              break;
          }
        }
      });
    },
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    handlerDel(item, index) {
      this.warnForm.dealImageList.splice(index, 1);
      this.$message.success("删除成功");
    },
    delFile(index) {
      this.warnForm.dealFileList.splice(index, 1);
      this.$message.success("删除成功");
    },
    expertUploader(event) {
      if (event.length !== 0) {
        let params = {
          fileName: event.originalName,
          fileRealUrl: event.fileRealUrl,
          fileUrl: event.fileUrl
        };
        this.warnForm.dealImageList.push(params);
      }
      this.warnForm.isHasImg = this.warnForm.dealImageList.length > 0 ? 1 : "";
    },
    dealUploader(event) {
      console.log(event);
      if (event.length !== 0) {
        let params = {
          fileName: event.originalName,
          fileRealUrl: event.fileRealUrl,
          fileUrl: event.fileUrl
        };
        this.warnForm.dealFileList.push(params);
      }
    },
    handleLineSiteChange(record) {
      console.log(record);
      this.filters.siteIdList = [];
      for (let item of record) {
        this.filters.siteIdList.push(item[item.length - 1]);
      }
    },
    handleUpdate(row) {
      this.title = "修改";
      this.dialogVisible = true;
      row.craneAlarmReason = row.craneAlarmReason + "";
      this.detailData = JSON.stringify(row);
    },
    handleWarn(data) {
      console.log(data);
      this.dialogWarn = true;
      this.warnForm.warnId = data.id;
    },
    // 消警弹窗取消
    cancelWarn() {
      this.dialogWarn = false;
      this.warnForm = {
        isHasImg: "",
        dealImageList: [],
        dealFileList: []
      };
    },
    cancel() {
      this.dialogVisible = false;
    },
    submit() {
      this.dialogVisible = false;
      this.findPage();
    },
    // 消警确认
    submitWarn() {
      console.log(this.warnForm);
      this.$refs["warnForm"].validate(valid => {
        if (valid) {
          crawlerDataDealAlarmById(this.warnForm).then(res => {
            if (res.code == 200) {
              this.cancelWarn();
              this.findPage();
            } else {
              this.$message({
                type: "error",
                message: res.msg
              });
            }
          });
        }
      });
    },
    goDetail(detail) {
      console.log(detail);
      this.title = "详情";
      this.dialogVisible = true;
      detail.craneAlarmReason = detail.craneAlarmReason + "";

      // 获取消警图片
      let imgList = [];
      if (detail.dealImage) {
        let arr = detail.dealImage.split(",");
        arr.map(i => {
          i = detail.dealFileUrl + i;
          imgList.push(i);
        });
      }

      detail["imgList"] = imgList;
      // 获取文件名

      let fileName = [];
      if (detail.dealFileName) {
        fileName = detail.dealFileName.split(",");
      }

      // 获取消警文件，并和文件名拼接起来
      let file = [];
      if (detail.dealFile) {
        let list = detail.dealFile.split(",");
        list.forEach((i, index) => {
          i = detail.dealFileUrl + i;
          let obj = {};
          obj["fileName"] = fileName[index];
          obj["filePath"] = i;
          file.push(obj);
        });
      }
      console.log(file);
      detail["fileList"] = file;
      this.detailData = JSON.stringify(detail);
    },
    //导出excel
    exportExcel() {
      this.$confirm("是否确认导出当前的塔吊安全监测预警信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        if (this.timeRange && this.timeRange.length != 0) {
          this.filters.startTime = this.timeRange[0] + " 00:00:00";
          this.filters.endTime = this.timeRange[1] + " 23:59:59";
        } else {
          this.filters.startTime = null;
          this.filters.endTime = null;
        }
        // 设置导出非0预警
        if (this.filters.craneAlarmReason) {
          // dd
        } else {
          this.filters.craneAlarmReason = 1;
        }
        exportInfo(this.filters).then(res => {
          this.filters.craneAlarmReason = 0;
          const aLink = document.createElement("a");
          let blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8"
          });
          aLink.href = URL.createObjectURL(blob);
          aLink.download = "塔吊安全检测预警.xlsx";
          aLink.click();
          document.body.appendChild(aLink);
        });
      });
    },
    handleDelete(data) {
      this.$confirm("确认删除吗？", "提示", {}).then(() => {
        crawlerDataDelete(data.id)
          .then(res => {
            if (res.code == 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.findPage();
            } else {
              this.$message({ message: "操作失败, " + res.msg, type: "error" });
            }
          })
          .then(data != null ? data.callback : "");
      });
    },
    // 获取分页数据
    findPage() {
      if (this.timeRange && this.timeRange.length != 0) {
        this.filters.startTime = this.timeRange[0] + " 00:00:00";
        this.filters.endTime = this.timeRange[1] + " 23:59:59";
      } else {
        this.filters.startTime = null;
        this.filters.endTime = null;
      }
      this.loading = true;
      let data = Object.assign({}, this.filters);
      delete data.lineSite;
      crawlerDataPage(data).then(res => {
        console.log(res);
        if (res.code == "200") {
          this.warnList = res.data.list;
          this.warnList.forEach(i => {
            i.dealAlarmSwitchName =
              i.dealAlarmSwitch == 0
                ? "不需消警"
                : i.dealAlarmSwitch == 1
                ? "待消警"
                : "已消警";
          });
          let isSure = this.warnList.find(i => i.dealAlarmSwitch == 1);
          this.hasWarn = isSure ? 1 : 0;
          this.totalSize = res.data.total;
          this.loading = false;
        }
      });
    },
    handleSizeChange(val) {
      this.filters.pageSize = val;
      this.findPage();
    },
    //翻页
    refreshPageRequest(pageNum) {
      this.pageNum = pageNum;
      this.findPage();
    },
    cellStyle({ row, columnIndex }) {
      if (row.dealAlarmSwitch == 1 && columnIndex === 12) {
        return "color: #EA1B29";
      } else if (row.dealAlarmSwitch == 0 && columnIndex === 12) {
        return "color: #000000";
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map(v => v.id);
      if (val.length != 0) {
        document.getElementById("btn").style.display = "block";
      } else {
        document.getElementById("btn").style.display = "none";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fileList {
  display: flex;
  flex-wrap: wrap;
  .file-item {
    margin-right: 20px;
    position: relative;
    .el-icon-circle-close {
      position: absolute;
      top: 30%;
      left: 50px;
      color: red;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
/* 可以点击的区域 */
.clickable {
  color: #7073ff;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* font-weight: bold; */
}
</style>
