import { httpInit } from "./http"
const http = httpInit("/gzdt-plan")
const https = httpInit("/gzdt-response")



// 分页查询预案管理列表
export const getEmergencyPageList = params => http.get(`/plan/page/${params.vendorType}/${params.planName}/${params.page}/${params.size}`)

// 新增预案管理数据
export const addEmergency = data => http.post(`/plan/add`, data)

// 查询预案管理详情
export const getEmergencyDetail = params => http.get(`/plan/info/${params.id}`)

// 预案管理删除
export const delEmergency = params => http.delete(`/plan/info/${params.id}`)

// 导出预案管理
export const getEmergencyExport = params => http.get(`/plan/export/${params.vendorType}/${params.planName}`, {
  //重要  没有她导出的文件会打不开 
  responseType: 'blob'
})


//历史响应记录
export const HistoricalResponseRecordlist = params => https.get(`/response/current/log/page/${params.lineId}/${params.siteId}/${params.type}/${params.level}/${params.start}/${params.end}/${params.page}/${params.size}`)



//应急预案
// 分页查询
// 分页查询预案管理列表
export const EmergencyPageList = params => http.post(`/plan/emergency/page`, params)
// 新增应急预案管理数据
export const addEmergencyData = data => http.post(`/plan/emergency/save`, data)
//修改
export const UpdateEmergencyData = data => http.put(`/plan/emergency/update`, data)
//删除
export const delEmergencyData = params => http.delete(`/plan/emergency/delete/${params.planId}`)



//应急处置卡
// 查询应急预案列表(不分页)
export const EmergencyPage = params => http.post(`/plan/emergency/list`, params)

//预案的预案类型
export const Emergencyall = data => http.get(`/plan/emergencyDisposalCard/all`, data)

// 应急处置卡导入
export const Emergencyimport = data => http.post(`/plan/emergencyDisposalCard/import`, data)

// 分页查询
export const EmergencyPageCardList = params => http.post(`/plan/emergencyDisposalCard/page`, params)
// 新增
export const addEmergencyCardData = data => http.post(`/plan/emergencyDisposalCard/save`, data)
//修改
export const UpdateEmergencyCardData = data => http.put(`/plan/emergencyDisposalCard/update`, data)
//删除
export const delEmergencyCardData = params => http.delete(`/plan/emergencyDisposalCard/delete/${params.cardId}`)
//查询应急处置卡对应的人员信息
export const EmergencyPageCardUserList = params => http.post(`/plan/emergencyDisposalCard/getEmergencyDisposalCardUserInfo`, params)

//开始合并
//分页查询预案管理记录
export const getEmergen = params =>
  http.get(
    `/plan/page/${params.vendorType}/${params.planName}/${params.page}/${params.size}`
  )
//三防应急检查表 分页
export const Emergencyinspection = data => https.post(`/contingencyInspect/mainPage`, data)

//三防应急检查表  新增 
export const EmergencyinspectionSave = data => https.post(`/contingencyInspect/mainSave`, data)

//三防应急检查表  删除
export const delEmergencyinspection = params => https.delete(`/contingencyInspect/${params.id}`)


//三防应急检查表  新增 
export const EmergencyinspectionUpdate = data => https.post(`/contingencyInspect/mainUpdate`, data)

//启动预案
export const startPlan = data => http.post(`/plan/emergency/launchPlan`, data)

//查询应急预案通知详情
export const notification = params => http.get(`/plan/emergencyDisposalCard/getPlanNoticMessageInfo/${params.id}`)

//查询应急处置卡详情
export const getPlan = data => http.post(`/plan/emergencyDisposalCard/getEventInfo`, data)



//删除过期的应急预案并且发送消息
// 删除过期的应急预案并且发送消息，首页进行调用如有需要，则切换前端路由时也可以调用，这个样更加精准
export const emergencyExpireSendMessage = params => http.get(`/plan/emergency/emergencyExpireSendMessage`, params)

// 查询过期了的应急预案列表分页
export const expirePage = data => http.post(`/plan/emergency/expirePage`, data)