<template>
  <div class="min-h-full bg-white">
    <div class="flex justify-between p-4">
      <el-row class="flex ">
        <el-cascader
            v-model="queryParams.lineSite"
            clearable
            :options="treeData"
            :props="props"
            :show-all-levels="false"
            collapse-tags
            style="margin-right: 10px;"
        ></el-cascader>

          <el-date-picker
              v-model="timeRange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              style="margin-right: 10px"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          >
          </el-date-picker>

          <el-select v-model="queryParams.craneAlarmReason" style="margin-right: 10px" clearable placeholder="请选择报警类型">
            <el-option
                v-for="(item, index) in selection"
                :key="index"
                :label="item.label"
                :value="item.code"
            ></el-option>
          </el-select>

        <el-button type="primary" @click="getList">搜索</el-button>
      </el-row>

      <el-row>
        <el-button type="primary" @click="addClick">新增</el-button>
        <el-button type="primary" @click="handleExport">导出</el-button>
      </el-row>
    </div>

    <div class="px-4">
      <el-table
          size="mini"
          height="630"
          :data="warnList"
          border
          v-loading="loading"
          :row-style="{ height: 59 + 'px' }"
          style="overflow-x: auto; overflow-y: auto"
          id="table"
      >
        <el-table-column width="50" type="selection" align="center"></el-table-column>
        <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
        <el-table-column
            prop="deviceId"
            header-align="center"
            align="center"
            label="设备编号"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            prop="deviceName"
            header-align="center"
            align="center"
            label="设备名称"
            show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="clickable" @click="lookNamePop(scope.row, 'Leditor')">
              {{ scope.row.deviceName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="equipmentName" align="center" label="起吊风速"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="起吊载重"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="起吊高度"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="落吊风速"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="落吊高度"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="主钩实重"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="副钩实重"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="主钩高度"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="起吊时间"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="落吊时间"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="设备状态"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="预警规则名称"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="预警内容"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="实际值"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-button
                  type="text"
                  size="small"
                  @click="handleEdit(scope.row)"
                  v-has="'role:assign'"
                  class="mr-4"
              >编辑
              </el-button>
              <el-button
                  type="text"
                  size="small"
                  @click="handleDelete(scope.row)"
                  v-has="'role:del'"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="toolbar" style="padding: 20px 10px">
        <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="refreshPageRequest"
            :current-page.sync="pageNum"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="totalSize"
            style="float: right"
        >
        </el-pagination>
      </div>
    </div>

    <!-- Dialog -->
    <el-dialog
        :title="title"
        width="60%"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        :before-close="handleDialogClose"
    >
      <el-form
          :rules="title == '详情' ? {} : rules"
          :model="dataForm"
          ref="dataForm"
          @keyup.enter.native="submitForm()"
          label-width="100px"
          :size="size"
          style="text-align: left; margin-right: 5px; overflow-y: auto"
          :disabled="title == '详情'"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="所属工点" prop="siteId">
              <el-cascader
                  v-model="dataForm.siteId"
                  clearable
                  :options="treeData"
                  :props="propsAdd"
                  style="width: 100%"
                  :show-all-levels="false"
                  collapse-tags
                  @change="handleLineSiteChangeAdd"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备名称" prop="deviceId">
              <el-select v-model="dataForm.deviceId" clearable style="width: 100%;"
                         placeholder="设备名称" :disabled="title == '编辑'">
                <el-option
                    v-for="item in equipmentList"
                    :key="item.deviceSerialNumber"
                    :label="item.equipmentName"
                    :value="item.deviceSerialNumber"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="起吊风速" prop="deviceId">
              <el-input
                  v-model="dataForm.deviceId"  disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="angle" label="大车运行行程">
              <el-input v-model="dataForm.angle" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="起吊时间" prop="obliguity">
              <el-input v-model="dataForm.obliguity" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="height" label="大车行走方向">
              <el-input v-model="dataForm.height" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="起吊风速" prop="rrange">
              <el-input v-model="dataForm.rrange" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="大车行走速度" prop="weight">
              <el-input v-model="dataForm.weight" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="起吊载重" prop="windLevel">
              <el-input v-model="dataForm.windLevel" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="大车运行偏斜" prop="windSpeed">
              <el-input v-model="dataForm.windSpeed" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="起吊高度" prop="warnType">
              <el-select v-model="dataForm.warnType" style="width: 100%;" clearable placeholder="请选择报警类型">
                <el-option
                    v-for="(item, index) in selection"
                    :key="index"
                    :label="item.label"
                    :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="小车运行行程" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="落吊时间" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="小车行走方向" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="落吊风速" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="小车行走速度" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="落吊载重" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="主钩实重" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="落吊高度" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="副钩实重" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="本次工作时长" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="主钩高度" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预报警状态" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="副钩高度" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="传感器状态" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="工作时间" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer" class="dialog-footer" v-if="title != '详情'">
        <el-button :size="size" @click="handleDialogClose">取消</el-button>
        <el-button :size="size" type="primary" @click="submitForm()">确认</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
import {
  gantryCraneAdds,
  gantryCraneDeleteById, gantryCraneUpdateById,
  getEquInfoList,
  gethistoryGantryCraneData,
  gantryCraneExport
} from "@/api/deviceManagement";
import {getDictData} from "@/api/dict";

export default {
  name: "GantryCraneDevice",
  data(){
    return{
      queryParams : {
        pageNum: 1,
        pageSize: 10,
        siteIdList: [],
        // time: "",
        startTime: "",
        warnType:'',
        endTime: ""
      },
      treeData : [],
      timeRange : [],
      selection: [],
      props: {
        children: "children",
        label: "name",
        value: "id",
        multiple: true,
      },
      totalSize: 0,
      pageNum: 1,
      pageSize: 10,
      warnList : [],
      flatTreeData : [],
      loading : false,
      dialogVisible : false,
      title : "",
      size : "small",
      dataForm: {
        deviceName: "",
        craneHeight: "",
        craneRange: "",
        craneTiltAngle: "",
        craneWindSpeed: "",
        craneTorquePercentage: "",
        craneElevatingCapacity: "",
        craneRotation: "",
        craneTime: "",
        alarmReason: "",
        remark: "",
        winddirection: "",
        windspeed: "",
        label: "",
        site: "",
        siteId: "",
        angle: null,
        obliguity: null,
        height: null,
        rrange: null,
        weight: null,
        windLevel: null,
        windSpeed: null,
        warnType:''
      },
      propsAdd : {
        children: "children",
        label: "name",
        value: "uuid",
      },
      equipmentList : [],
      rules: {
        siteId: [{ required: true, message: "所属工点不能为空", trigger: "change" }],
        deviceId: [{ required: true, message: "设备名称不能为空", trigger: "change" }]
      },
    }
  },
  created() {
    this.getLineSiteTreeData();
    this.dictLabel();
    this.getList();
    this.getEquipment();
  },
  methods : {
    dictLabel(){
      let dictArray = ['install_area','alarm_type'];
      dictArray.forEach(item => {
        this.getDictData(item)
      })
    },
    getDictData(label){
      getDictData(label).then(res => {
        if(res.code == 0){
          switch (label){
            case 'install_area' :
              this.areaTypeList = res.data;
              break;
            case 'alarm_type' :
              this.selection = res.data;
              break;
          }
        }
      })
    },
    getEquipment() {
      let params = {
        pageSize: 10000000,
        pageNum: 1,
        siteIdList : [this.dataForm.siteId],
        equipmentType : "tower"
      }
      getEquInfoList(params).then((res) => {
        this.equipmentList = res.data.list
      })
    },
    handleLineSiteChangeAdd(record){
      let siteUuId = record[record.length - 1]
      this.dataForm.siteId = siteUuId;
      this.dataForm.site = this.flatTreeData.filter(item => item.uuid === siteUuId)[0].name;
      this.getEquipment();
    },
    // 线路工点树数据查询
    getLineSiteTreeData () {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"))
      if (lineSiteData) {
        this.treeData = lineSiteData
      }
      this.flatArr(lineSiteData);
    },
    flatArr(data) {
      for (let item of data) {
        this.flatTreeData.push(item);
        if (item.children) {
          this.flatArr(item.children);
        }
      }
    },
    // 获取分页数据
    getList() {
      if (this.timeRange && this.timeRange.length != 0) {
        this.queryParams.startTime = this.timeRange[0] + " 00:00:00"
        this.queryParams.endTime = this.timeRange[1] + " 23:59:59"
      } else {
        this.queryParams.startTime = null
        this.queryParams.endTime = null
      }
      this.loading = true
      gethistoryGantryCraneData(this.queryParams).then((res) => {
        if (res.code == 200) {
          this.warnList = res.data.list
          this.totalSize = res.data.total
          this.loading = false
        }
      })
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val
      this.getList()
    },
    //翻页
    refreshPageRequest(pageNum) {
      this.queryParams.pageNum = pageNum
      this.getList()
    },
    addClick() {
      this.title = "新增"
      this.dialogVisible = true
      this.dataForm = {
        deviceName: "",
        craneHeight: "",
        craneRange: "",
        craneTiltAngle: "",
        craneWindSpeed: "",
        craneTorquePercentage: "",
        craneElevatingCapacity: "",
        craneRotation: "",
        craneTime: "",
        alarmReason: "",
        remark: "",
        winddirection: "",
        windspeed: "",
        label: "",
        site: "",
        siteId: ""
      }
      // this.resetForm()
      // this.$refs["dataForm"].clearValidate()
    },
    // 显示编辑界面
    handleEdit(row, type) {
      // if (type == "Leditor") {
      //   this.lookNamePoptPPE = false
      // } else {
      //   this.lookNamePoptPPE = true
      // }
      this.title = "编辑"
      this.dialogVisible = true
      row.warnType = row.alarmKey
      this.dataForm = { ...row }
      console.log(row, this.selection)
    },
    // 删除
    handleDelete(row) {
      let id = (row && row.id) || this.multipleSelection
      this.$confirm("确认删除选中记录吗？", "提示", {
        type: "warning"
      }).then(() => {
        gantryCraneDeleteById(id).then((res) => {
          // this.findTreeData()
          if (res.code == "200") {
            this.getList()
            this.$message({ message: "删除成功", type: "success" })
          }
        })
      })
    },
    // 弹框 x 关闭事件 弹框取消按钮(清空表单)
    handleDialogClose() {
      this.dialogVisible = false
      this.resetForm()
    },
    resetForm() {
      this.dataForm = {
        deviceName: null,
        craneHeight: null,
        craneRange: null,
        craneTiltAngle: null,
        craneWindSpeed: null,
        craneTorquePercentage: null,
        craneElevatingCapacity: null,
        craneRotation: null,
        craneTime: null,
        alarmReason: null,
        remark: null,
        winddirection: null,
        windspeed: null,
        label: null,
        site: null,
        siteId: null,
        deviceId: null
      }
    },
    // 表单提交
    submitForm() {
      // console.log(this.dataForm.id)
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true
            const that = this
            that.equipmentList.forEach((el) => {
              if (el.deviceSerialNumber == that.dataForm.deviceId) {
                that.dataForm.deviceName = el.equipmentName
              }
            })
            let params = Object.assign({}, that.dataForm)
            var apInstruct = null
            if (this.title == "新增") {
              apInstruct = gantryCraneAdds(params)
            } else {
              apInstruct = gantryCraneUpdateById(params)
            }
            apInstruct.then((res) => {
              this.editLoading = false
              if (res.code == 200) {
                this.$message({ message: "操作成功", type: "success" })
                this.dialogVisible = false
                this.$refs["dataForm"].resetFields()
              } else {
                this.$message({
                  message: "操作失败, " + res.msg,
                  type: "error"
                })
              }
              this.getList()
            })
          })
        }
      })
    },
    handleExport() {
      this.$confirm("确定导出龙门吊监测数据吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        gantryCraneExport(this.queryParams).then(res => {
          const aLink = document.createElement("a");
          let blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8"
          });
          aLink.href = URL.createObjectURL(blob);
          aLink.download = "龙门吊监测数据.xlsx";
          aLink.click();
          document.body.appendChild(aLink);
        });
      });
    }
  }
}
</script>

<style scoped>

</style>
