<template>
  <systemModal ref="formModal" :title="title" :IsAudit="true" width="40%" @modalAgree="handleExamint(1)" @modalDisagree="handleExamint(2)">
    <el-form v-loading="loading" :model="model" ref="modelForm" label-width="200px" class="demo-model" style="width: 80%">
      <el-form-item label="线路工点" prop="lineSite">
        {{ model.lineName }} - {{ model.siteName }}
      </el-form-item>
      <el-form-item label="演练类型" prop="category">
        {{ model.category | categoryName }}
      </el-form-item>
      <el-form-item label="演练主题" prop="label">
        {{ model.label }}
      </el-form-item>
      <el-form-item label="计划演练时间" prop="planDate">
        {{ model.planDate }}
      </el-form-item>
      <el-form-item label="演练地点" prop="address">
        {{ model.address }}
      </el-form-item>
      <el-form-item label="是否汛期" prop="floodSeason">
        {{ model.floodSeason ? "是" : "否" }}
      </el-form-item>
      <el-form-item label="运营接口车站" prop="station" v-if="model.floodSeason">
        {{ model.station }}
      </el-form-item>
      <el-form-item label="周边街道名称" prop="street" v-if="model.floodSeason">
        {{ model.street }}
      </el-form-item>
      <el-form-item label="拟参与人数" prop="joinCount">
        {{ model.joinCount }}
      </el-form-item>
      <el-form-item label="实际参与人数" prop="joinCount">
        {{ model.joinCount }}
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          placeholder="请输入内容"
          v-model="remark"
        >
        </el-input>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { getRehearsalDetail, approvePlan, noApprovePlan } from "@/api/stand";
let that;
export default {
  components: {
    systemModal
  },
  data() {
    return {
      title: "计划审批",
      model: {},
      loading: false,
      remark: "",
      dict: {
        category: []
      }
    };
  },
  beforeCreate: function () {
    that = this;
  },
  props: {
    lineSiteData: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  filters: {
    categoryName(val) {
      if (that.dict.category.length > 0) {
        let temp = that.dict.category.find(item => item.id == val);
        if (temp) {
          return temp.label;
        }
      } else {
        return "";
      }
    }
  },
  mounted() {
    this.getDictData("应急培训类型", this.dict.category);
  },
  methods: {
    handlePlanExamine(record) {
      this.$refs.formModal.handleOpen();
      this.loading = true;
      getRehearsalDetail({ id: record.id }).then(res => {
        if (res.code == 0) {
          this.model = Object.assign({}, res.data);
          this.loading = false;
        }
      });
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then(res => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then(res => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 审核
    handleExamint(type) {
      if (type == 1) {
        this.loading = true;
        approvePlan({ id: this.model.id }).then(res => {
          if (res.code == 0) {
            this.loading = false;
            this.$message.success("操作成功");
            this.$refs.formModal.handleClose();
            this.$emit("examineSubmit");
          }
        });
      } else {
        if (!this.remark) {
          this.$message.error("请输入备注！");
          return false;
        }
        const params = {
          id: this.model.id,
          data: {
            opinion: this.remark
          }
        };
        this.loading = true;
        noApprovePlan(params).then(res => {
          if (res.code == 0) {
            this.loading = false;
            this.$message.success("操作成功");
            this.$refs.formModal.handleClose();
            this.$emit("examineSubmit");
          }
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}
::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
</style>
