<template>
  <div>
    <!-- 应急检查表三层弹窗-填报 -->
    <el-form :inline="true">
      <el-form-item label="是否存在隐患问题">
        <!--        <el-input v-model="isQuestion"></el-input>-->
        <el-select
          v-model="isQuestion"
          placeholder="请选择"
          @change="isQuestionChange"
        >
          <el-option label="是" value="0"></el-option>
          <el-option label="否" value="1"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!--    存在时-->
    <div class="question" v-for="(item, index) in questionList" :key="index">
      <div v-if="isQuestion == 0">
        <div style="margin-bottom:10px">
          <span>隐患问题{{ index + 1 }}</span>
        </div>

        <p>隐患详情</p>
        <el-input
          type="textarea"
          v-model="item.hiddenQuestion"
          style="margin-bottom: 20px"
        ></el-input>
        <ImageUploader
          :show-cover="item.uri ? true : false"
          :modelValue="item.uri ? item.uri : ''"
          :showClose="true"
          @delPic="delPic(index)"
          @on-success="uploadSuccess($event, index)"
        ></ImageUploader>
      </div>
    </div>
    <!--  不存在时  -->
    <div class="question" v-for="(item, index) in questionList" :key="index">
      <div v-if="isQuestion == 1">
        <h6>检查情况</h6>
        <el-input
          type="textarea"
          v-model="item.hiddenQuestion"
          style="margin-bottom: 20px"
        ></el-input>
        <ImageUploader
          :show-cover="item.uri ? true : false"
          :modelValue="item.uri ? item.uri : ''"
          :showClose="true"
          @delPic="delPic(index)"
          @on-success="uploadSuccess($event, index)"
        ></ImageUploader>
      </div>
    </div>

    <div class="add">
      <el-button
        type="primary"
        size="small"
        @click="addQuestion"
        v-if="isQuestion == 0"
        >+</el-button
      >
      <el-button
        style="margin:10px 0 0;"
        type="danger"
        size="small"
        @click="mergencyremov"
        v-if="questionList.length > 1"
        >-</el-button
      >
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmit">确认</el-button>
    </div>
  </div>
</template>

<script>
import ImageUploader from "@/components/Uploader/ImageUploader1";
export default {
  name: "emergencyFill",
  components: {
    ImageUploader,
  },
  props: {
    /* questionList : {
      type : Array,
      default : () => []
    }*/
    fillData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isQuestion: 0,
      questionList: [],
    };
  },
  created() {
    console.log(this.fillData);
    this.questionList = this.fillData.questionList
      ? JSON.parse(JSON.stringify(this.fillData.questionList))
      : [];
    // this.questionList = this.fillData.questionList ? this.fillData.questionList : [];
    this.isQuestion = this.fillData.existHiddenQuestionFlag == 0 ? "1" : "0";
    if (this.questionList.length === 0) {
      this.questionList.push({
        hiddenQuestion: "",
        uri: "",
      });
    }
  },
  methods: {
    delPic(index) {
      console.log(index);
      this.questionList[index].uri = "";
    },
    uploadSuccess(event, index) {
      console.log(event, index);
      this.questionList[index].uri = event.data.uri;
    },
    addQuestion() {
      this.questionList.push({
        hiddenQuestion: "",
        uri: "",
      });
    },
    onSubmit() {
      let url = this.questionList.some((item) => item.uri == "");
      console.log("ulr", url);
      // if (url == false) {
      if (this.isQuestion == 0) {
        this.$emit("fillSubmit", this.questionList, 1);
        this.$emit("cancelFill");
      } else {
        // if (!this.questionList[0].uri) {
        //   this.$message.error("不存在隐患问题图片必须上传");
        //   return;
        // }
        if (!this.questionList[0].hiddenQuestion) {
          this.questionList[0].hiddenQuestion = "无";
        }
        this.$emit("fillSubmit", this.questionList, 0);
        this.$emit("cancelFill");
        // }
      }
      //  else {
      //   this.$message.warning("请上传图片");
      // }
    },
    // 是否存在隐患问题
    isQuestionChange(val) {
      console.log("是否存在隐患问题", this.questionList);
      // 否
      if (val == 1) {
        this.questionList.length = 1;
      }
    },
    mergencyremov() {
      this.questionList.pop();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.question {
  margin-bottom: 20px;
  h4 {
    font-weight: bold;
    margin-bottom: 20px;
  }
}
.add {
  width: 100%;
  .el-button {
    width: 100%;
    height: 30px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
