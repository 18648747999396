<template>
    <el-dialog
      title="导入"
      :visible.sync="dialogExcelVisible"
      :before-close="turnOff"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属单位" label-width="80px" prop="unitId">
              <!--            <treeselect-->
              <!--              v-model="ruleForm.unitId"-->
              <!--              :clearable="clearable"-->
              <!--              :options="deptData"-->
              <!--              :normalizer="normalizer"-->
              <!--              :flat="true"-->
              <!--              @select="changeUnit"-->
              <!--              @input="input"-->
              <!--              placeholder="请选择"-->
              <!--            ></treeselect> -->
              <popup-tree-input
                :data="deptData"
                :props="lineProps"
                :prop="ruleForm.unit"
                :nodeKey="ruleForm.unitId"
                :placement="'bottom-start'"
                :currentChangeHandle="unitTreeCurrentChangeHandle"
              >
              </popup-tree-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="所属部门" label-width="80px" prop="deptId">
              <!--            <treeselect-->
              <!--              v-model="ruleForm.deptId"-->
              <!--              :clearable="clearable"-->
              <!--              :options="deptDataDepartment"-->
              <!--              :normalizer="normalizer"-->
              <!--              :flat="true"-->
              <!--              @select="changeDept"-->
              <!--              placeholder="请选择"-->
              <!--            ></treeselect> -->
              <popup-tree-input
                :data="deptDataDepartment"
                :props="lineProps"
                :prop="ruleForm.dept"
                :nodeKey="ruleForm.deptId"
                :placement="'bottom-start'"
                :currentChangeHandle="deptTreeCurrentChangeHandle"
              >
              </popup-tree-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
      <!-- <input ref="excel-upload-input" class="excel-upload-input" type="file" accept=".xlsx, .xls" @change="handleClick" /> -->
      <section class="hint-area">
        <p class="hint">注意事项:</p>
        <p class="hint">1、模板中的表头名称不可更改，表头行不能删除，不能删除必填字段列</p>
        <p class="hint">2、为保证导入成功，请勿调整文件内容单元格格式</p>
        <p class="hint">3、图片只支持 .jpg 格式</p>
        <p class="hint">4、压缩包只支持 .zip 格式</p>
      </section>
      <div class="drop" v-loading="upLoading">
        <div>
          <div class="upload-hint">点击预览操作说明</div>
          <el-button
            :loading="loading"
            :class="{ 'DL-btn': isDalian }"
            style="margin-left: 16px"
            size="mini"
            type="primary"
            @click="onPreview"
            >操作说明</el-button
          >
        </div>
        <div class="upload-item">
          <div class="upload-hint">1.请先下载模板</div>
          <el-button
            :loading="loading"
            :class="{ 'DL-btn': isDalian }"
            style="margin-left: 16px"
            size="mini"
            type="primary"
            @click="downMoud"
            >下载Excel模板</el-button
          >
        </div>
        <div class="upload-item">
          <div class="upload-hint">2.请上传按照模板创建的Excel表格</div>
          <el-upload
            :disabled="!ruleForm.deptId"
            class="avatar-uploader"
            :action="uploadExcelUrl"
            :data="CertificateData"
            :show-file-list="false"
            :headers="myHeaders"
            :on-success="handleExcelSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <el-button
              :loading="loading"
              :class="{ 'DL-btn': isDalian }"
              style="margin-left: 16px"
              :disabled="!ruleForm.deptId"
              size="mini"
              type="primary"
            >
              上传excel</el-button
            >
          </el-upload>
        </div>
        <!-- :disabled="disZip && ruleForm.deptId !== ''" -->
        <div class="upload-item">
          <div class="upload-hint">3.请上传图片压缩包</div>
          <el-upload
            class="avatar-uploader"
            :action="uploadZipUrl"
            :headers="myHeaders"
            :show-file-list="false"
            :on-success="handleZipSuccess"
            :before-upload="beforeZipUpload"
          >
            <el-button
              :class="{ 'DL-btn': isDalian }"
              :loading="loading"
              style="margin-left: 16px"
              autofocus
              size="mini"
              type="primary"
              >上传图片压缩包</el-button
            >
          </el-upload>
        </div>
      </div>
      <el-dialog
        title="预览操作说明"
        :visible.sync="viewVisible"
        center
        width="60%"
        @close="closePreview"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        :append-to-body="true"
      >
        <iframe :src="src" frameborder="0" width="100%" height="700px"> </iframe>
      </el-dialog>
    </el-dialog>
  </template>
  
  <script>
//   import XLSX from "xlsx"
//   import Treeselect from "@riophae/vue-treeselect"
//   import "@riophae/vue-treeselect/dist/vue-treeselect.css"
  import PopupTreeInput from "@/components/PopupTreeInput"
  
  export default {
    components: {
    //   Treeselect,
      PopupTreeInput
    },
    props: {
      beforeUpload: Function, // eslint-disable-line
      onExcelSuccess: Function, // eslint-disable-line
      onZipSuccess: Function, // eslint-disable-line
      dialogExcelVisible: Boolean
    },
    data() {
      const token = sessionStorage.getItem("token") // 获取token
      return {
        loading: false,
        uploadExcelUrl: "",
        uploadZipUrl: "",
        upLoading: false,
        myHeaders: { Authorization: token },
        // 下拉框
        ruleForm: {
          unit: null,
          unitId: null,
          deptId: null,
          dept: null
        },
        rules: {},
        clearable: false,
        deptDataDepartment: [],
        deptData: [],
        normalizer(node) {
          //去掉children=[]的children属性
          if (node.children && !node.children.length) {
            delete node.children
          }
          return {
            id: node.id,
            label: node.name,
            children: node.children
            // isDisabled: node.parentId == 0
          }
        },
        CertificateData: { unitId: "", deptId: "" },
        disZip: true,
        src: null,
        viewVisible: false,
        lineProps: {
          value: "id",
          label: "name",
          children: "children",
          expandTrigger: "hover"
        },
        isDalian: sessionStorage.getItem("roleType") == "role_dalian"
      }
    },
    watch: {
      "CertificateData.unitId": {
        deep: true,
        handler(v) {
          if (this.ruleForm.unitId) {
            this.$api.userList.findDeptTreeById(this.CertificateData.unitId).then((res) => {
              if (res.code == 200) {
                this.deptDataDepartment = res.data
              }
            })
          }
        }
      }
      // "CertificateData.deptId": {
      //   deep: true,
      //   handler(v) {
      //     if (this.ruleForm.deptId) {
      //       this.$api.userList.findDeptTreeById(this.CertificateData.deptId).then((res) => {
      //         if (res.code == 200) {
      //           this.deptDataDepartment = res.data
      //         }
      //       })
      //     }
      //   }
      // }
    },
    created() {
      // if (process.env.NODE_ENV == "production") {
      //上传图片附件接口
      this.uploadExcelUrl = this.global.baseUrl + "/user/importExcel"
      this.uploadZipUrl = this.global.baseUrl + "/user/uploadCompressedFiles"
      // } else {
      //   this.uploadExcelUrl = "/emer/user/importExcel"
      //   this.uploadZipUrl = "/emer/user/uploadCompressedFiles"
      // }
      this.findUnitTreeLit()
    },
    mounted() {},
    methods: {
      // 点击预览
      onPreview() {
        this.$api.userList.Preview().then((res) => {
          this.viewVisible = true
          this.src = res
        })
      },
      closePreview() {
        this.pdfPage = 1
      },
      previousPage() {
        let p = this.pdfPage
        p = p > 1 ? p - 1 : this.pageCount
        this.pdfPage = p
      },
      nextPage() {
        let p = this.pdfPage
        p = p < this.pageCount ? p + 1 : 1
        this.pdfPage = p
      },
      // 获取所属单位信息
      findUnitTreeLit() {
        this.$api.dept.findUserDeptTree().then((res) => {
          if (res.code == 200) {
            let nn = []
            res.data.forEach((item) => {
              getdata(item)
            })
            function getdata(item) {
              if (item.unitDept == "unit") {
                nn.push(item)
              }
              if (item.children !== null) {
                item.children.forEach((data) => {
                  getdata(data)
                })
              }
            }
            let mm = [] //1
            let ll = [] //下级
            nn.map((item) => {
              item.children = []
              if (item.parentId == 0) {
                mm.push(item)
              } else {
                ll.push(item)
              }
            })
            mm.map((item) => {
              ll.forEach((i) => {
                if (item.id == i.parentId) {
                  item.children.push(i)
                }
                if (i.children !== null) {
                  pp(i, i.children)
                }
              })
            })
            function pp(i, item) {
              item.forEach((index) => {
                if (i.id == index.parentId) {
                  item.children.push(index)
                }
              })
            }
            this.deptData = mm
          }
        })
      },
      downMoud() {
        this.$api.user.downLoadUserExcel().then((res) => {
          window.open(res)
          // this.downloadExcel(res, '导入模板');
        })
      },
      beforeAvatarUpload(file) {
        if (!this.isExcel(file)) {
          this.$message.error("Only supports upload .xlsx, .xls, .csv suffix files")
          return false
        }
  
        const isLt1M = file.size / 1024 / 1024 < 1
        if (isLt1M) {
          this.upLoading = true
          return true
        }
        this.$message({
          message: "上传文件过大！",
          type: "warning"
        })
        return false
      },
      beforeZipUpload(file) {
        if (!this.isZip(file)) {
          this.$message.error("Only supports upload .zip, suffix files")
          return false
        }
        this.upLoading = true
        return true
      },
      handleExcelSuccess(res, file) {
        console.log(res, file)
        if (res.code == 200) {
          this.disZip = false
        } else {
          // this.$message.error("操作失败" + res.msg)
        }
        this.upLoading = false
        this.onExcelSuccess && this.onExcelSuccess(res)
      },
      handleZipSuccess(res, file) {
        this.upLoading = false
        this.onZipSuccess && this.onZipSuccess(res)
      },
      changeUnit(node, instanceId) {
        console.log(node, instanceId)
        if (node.parentId == 0) {
          //那就不能选中
          // this.ruleForm.unitId=''
        } else {
          this.CertificateData.unitId = node.id
        }
      },
  
      // 菜单树选中
      unitTreeCurrentChangeHandle(data, node) {
        console.log(data, node, data.parentId !== 0, data.name, data.id)
        if (data.parentId !== 0) {
          this.ruleForm.unitId = toString(data.id)
          this.ruleForm.unit = data.name
          this.CertificateData.unitId = data.id
          this.$api.userList.findDeptTreeById(data.id).then((res) => {
            if (res.code == 200) {
              this.deptDataDepartment = res.data
            }
          })
        } else {
        }
      },
      deptTreeCurrentChangeHandle(data, node) {
        this.ruleForm.deptId = toString(data.id)
        this.ruleForm.dept = data.name
        this.CertificateData.deptId = data.id
      },
      changeDept(node, instanceId) {
        console.log(node, instanceId)
        this.CertificateData.deptId = node.id
        console.log(this.CertificateData)
      },
      isExcel(file) {
        return /\.(xlsx|xls|csv)$/.test(file.name)
      },
      isZip(file) {
        return /\.(zip|csv)$/.test(file.name)
      },
      turnOff() {
        this.ruleForm = {
          unit: null,
          unitId: null,
          deptId: null,
          dept: null
        }
        this.$emit("turnOff")
      }
    }
  }
  </script>
  
  <style scoped>
  .el-dialog__body {
    padding: 20px;
  }
  .excel-upload-input {
    display: none;
    z-index: -9999;
  }
  .drop {
    display: flex;
    /* justify-content: center; */
  }
  .upload-item {
    margin-left: 100px;
  }
  .upload-hint {
    margin: 28px 0 8px 0;
    font-size: 14px;
    font-weight: bold;
    color: #59baf2;
  }
  
  .hint-area {
    margin: -60px 0 0 9px;
  }
  
  .hint {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #cbcccd;
    text-align: left;
    line-height: 18px;
  }
  .el-button--primary.is-disabled {
    background-color: #4285d0;
  }
  </style>
  