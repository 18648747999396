<template>
  <!-- 风险类别统计 -->
  <div class="info-container">
    <index-box :title="title">
      <div id="Referer" class="Referer"></div>
    </index-box>
    <HomePageDialog
      ref="modalTable"
      :titleTable="titleTable"
      :columns="columns"
      :tableList="tableList"
      :totalData="totalData"
      :leftTwoParams="leftTwoParams"
    ></HomePageDialog>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import HomePageDialog from "@/views/HiddenDanger/components/HomePageDialog.vue";
// 数据,弹框
import { getRiskTypeCount, getRiskTypePage } from "@/api/hdanger";
export default {
  name: "info-container",
  components: {
    IndexBox,
    HomePageDialog,
  },
  data() {
    return {
      title: "风险类别统计",
      arrData: [],
      titleTable: "",
      columns: [
        { prop: "lineName", label: "线路", minWidth: 80 },
        { prop: "siteName", label: "工点", minWidth: 80 },
        { prop: "riskName", label: "风险名称", minWidth: 80 },
        { prop: "riskQuarter", label: "季度", minWidth: 80 },
      ],
      tableList: [],
      totalData: 0,
      leftTwoParams: {
        riskTypeId: "",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    RefererShow() {
      var myChart = this.$echarts.init(document.getElementById("Referer"));

      var option = {
        title: {
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: "80%",
            data: this.arrData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      myChart.clear();
      option && myChart.setOption(option);
      myChart.off("click");
      myChart.hideLoading();
      myChart.on("click", (param) => {
        this.titleTable = param.name + "列表";
        this.$refs.modalTable.handleOpen("left", "风险类别统计");
        let paramData = {
          riskTypeChildId: "",
          riskTypeId: param.data.riskTypeId,
          workAreaNameList: [],
          pageNum: 1,
          pageSize: 5,
        };
        this.leftTwoParams = {
          riskTypeId: paramData.riskTypeId,
        };
        getRiskTypePage(paramData).then((res) => {
          if (res.code == 0) {
            this.tableList = res.data.records;
            this.totalData = res.data.count;
          }
        });
      });
    },
    getData() {
      getRiskTypeCount().then((res) => {
        if (res.code == 0) {
          this.arrData = [];
          if (res.data) {
            res.data.forEach((element) => {
              this.arrData.push({
                value: Number(element.num),
                name: element.riskType,
                riskTypeId: element.riskTypeId,
              });
            });
            this.RefererShow();
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-container {
  .Referer {
    width: 100%;
    height: 100%;
  }
  .scroll-wrapper {
    height: 100%;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}
</style>
