<template>
  <systemModal
    ref="formModal"
    @modalConfirm="handleSubmit"
    @modalClose="handleModalClose"
    :title="title"
    width="40%"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="100px"
      class="demo-model"
      style="width: 80%"
    >
      <el-form-item
        :label="operationType == 1 ? '出库数量' : '入库数量'"
        prop="num"
      >
        <el-input-number
          v-model="model.num"
          controls-position="right"
          :precision="0"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="operationType == 1 ? '出库原因' : '入库原因'">
        <el-input v-model="model.reason" placeholder="请输入原因"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="mobile">
        <el-input
          v-model="model.remark"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { materialsOut, materialsIn } from "@/api/eres";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      model: {
        id: "",
        num: "",
        reason: "",
        remark: "",
        type: 1,
      },
      dict: {},
      operationType: 1,
      loading: false,
      rules: {
        num: [
          { required: true, message: "请输入标准数量", trigger: "blur" },
          { pattern: /^[+]{0,1}(\d+)$/, message: "请输入合法数量" },
        ],
        reason: [{ required: true, message: "请输入原因", trigger: "blur" }],
      },
    };
  },
  computed: {
    title() {
      if (this.operationType == 1) {
        return "物资出库";
      } else {
        return "物资入库";
      }
    },
  },
  props: {
    lineSiteData: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  mounted() {},
  methods: {
    handleInOut(type, record) {
      this.model = {
        id: "",
        num: "",
        reason: "",
        remark: "",
        type: 1,
      };
      this.operationType = type;
      this.model.id = record.id;
      this.$refs.formModal.handleOpen();
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          if (this.operationType == 1) {
            this.materialsOut();
          } else {
            this.materialsIn();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 出库
    async materialsOut() {
      this.loading = true;
      const params = JSON.parse(JSON.stringify(this.model));
      let res = await materialsOut(params).catch((res) => {
        this.loading = false;
        console.log(res);
        this.$message.warning("现存数量不足,请重新输入");
      });
      if (res.code == 0) {
        this.$message.success("操作成功");
        this.$emit("inOutSubmit");
        this.loading = false;
        this.handleModalClose();
      } else {
        this.loading = false;
        this.$message.warning("库存数量不足,请重新输入");
      }
    },
    // 入库
    async materialsIn() {
      this.loading = true;
      const params = JSON.parse(JSON.stringify(this.model));
      let res = await materialsIn(params);
      if (res.code == 0) {
        this.$message.success("操作成功");
        this.$emit("inOutSubmit");
        this.loading = false;
        this.handleModalClose();
      }
    },
    formResize() {},
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    handleModalClose() {
      this.$refs.formModal.handleClose();
      this.$refs.modelForm.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped></style>
