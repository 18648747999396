<template>
  <el-select
    multiple
    collapse-tags
    :value="nameArr"
    clearable
    :placeholder="placeholder"
    style="width: 100%"
    @clear="clearHandle"
    id="selectN"
  >
    <el-option :value="valueTitle" :label="valueTitle">
      <el-tree
        id="tree-option"
        ref="selectTree"
        show-checkbox
        :default-expanded-keys="expandKey"
        :data="lineTree"
        :props="props"
        node-key="id"
        :current-node-key="defaultId"
        :default-checked-keys="defaultCheckedkeys"
        @check="handleCheckChange"
      >
      </el-tree>
    </el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    nameArr: {
      type: Array,
      default: () => [],
    },
    valueTitle: {
      type: String,
      default: "",
    },
    lineTree: {
      type: Array,
      default: () => [],
    },
    defaultId: {
      type: String,
      default: "",
    },
    //默认勾选的树
    defaultCheckedkeys: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      idArr: [],
      //权限树的默认配置
      props: {
        value: "id",
        label: "name",
        children: "children",
        expandTrigger: "hover",
      },
      expandKey: [],
      siteIdList: [],
    };
  },
  watch: {
    lineTree() {
      if (this.lineTree.length > 0) {
        this.lineTree.forEach((el) => {
          this.expandKey.push(el.id);
        });
      }
    },
  },
  created() {
    this.initHandle();
  },
  mounted() {},
  methods: {
    // 初始化值
    initHandle() {
      this.$nextTick(() => {
        let scrollWrap = document.querySelectorAll(
          ".el-scrollbar .el-select-dropdown__wrap"
        )[0];
        let scrollBar = document.querySelectorAll(
          ".el-scrollbar .el-scrollbar__bar"
        );
        scrollWrap.style.cssText =
          "margin: 0px; max-height: none; overflow: hidden;";
        scrollBar.forEach((ele) => (ele.style.width = 0));
      });
    },
    // 选中
    handleCheckChange(data, val, node, nodes) {
      /* console.log(data, val, node, nodes, "mmmmmmmmmmm") */

      let nn = [];
      val.checkedNodes.forEach((item) => {
        nn.push(item.name);
      });
      this.$emit("handleCheckChange", val.checkedKeys, nn);
    },
    // 清除选中
    clearHandle() {
      this.$refs.selectTree.setCheckedKeys([]);
      this.clearSelected();
      this.$emit("clearHandle");
    } /* 清空选中样式 */,
    clearSelected() {
      let allNode = document.querySelectorAll("#tree-option .el-tree-node");
      allNode.forEach((element) => element.classList.remove("is-current"));
    },
    setTree(keys) {
      // keys是要勾选中的数据
      // 该方法通过父组件传过来要勾选的数组
      this.$refs.selectTree.setCheckedKeys(keys);
    },
  },
};
</script>

<style lang="scss" scoped>
// 站点的下拉树
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 600px;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}

.el-select-dropdown__item.selected {
  font-weight: normal;
}

.el-tree {
  color: #606266;
}

.el-select__tags {
  flex-wrap: nowrap !important;
  overflow: hidden;
}

.el-tag.el-tag--info .el-tag__close {
  display: none;
}

.el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}

.el-tree .is-current .el-tree-node__label {
  color: #409eff;
  font-weight: 700;
}

.el-tree .is-current .el-tree-node__children .el-tree-node__label {
  color: #606266;
  font-weight: normal;
}

::v-deep .el-tag.el-tag--info .el-tag__close {
  display: none !important;
}

::v-deep.el-checkbox__input.is-disabled {
  display: none !important;
}

/* .el-select-dropdown .el-scrollbar > .el-select-dropdown__wrap.el-scrollbar__wrap.el-scrollbar__wrap--hidden-default {
    height: 100% !important;
  } */
</style>
