<template>
  <div>
    <el-row>
      <el-form :inline="true" :model="formData" class="demo-form-inline">
        <el-form-item label="应急相应类型">
          <el-input
            v-model="formData.emergency"
            placeholder="应急相应类型"
            readonly
          ></el-input>
          
        </el-form-item>
        <el-form-item label="应急级别">
          <el-input v-model="formData.level" readonly placeholder="应急级别"></el-input>
          
        </el-form-item>
      </el-form>
    </el-row>
    <el-table :data="tableData" v-loading="fromLoading" style="width: 100%">
      <el-table-column
        prop="departmentName"
        label="单位部分"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        width="150"
        :formatter="ztformatter"
      >
      </el-table-column>
      <el-table-column
        prop="group"
        label="派出工作组"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="position"
        label="检查场所部位"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="hiddenDanger"
        label="新排查出三防风险隐患"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="info"
        label="发送预警或防御信息"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="worker"
        label="预制(出动)抢险人员"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="team"
        label="预制(出动)抢险队伍"
        align="center"
        width="150"
      >
      </el-table-column>

      <el-table-column label="预制(出动)抢险设备" align="center">
        <el-table-column prop="device" label="合计" align="center" width="150">
        </el-table-column>
        <el-table-column prop="vehicle" label="车辆" align="center" width="120">
        </el-table-column>
        <el-table-column prop="boat" label="舟艇" align="center" width="120">
        </el-table-column>
        <el-table-column
          prop="waterPump"
          label="抽水机"
          align="center"
          width="120"
        >
        </el-table-column>
        <el-table-column prop="other" label="其他" align="center" width="300">
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="其他应急措施落实及受灾影响情况"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="fillTime"
        label="填报时间"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="100">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >审核</el-button
          >
          <el-button type="text" size="small">催办</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top:20px" slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
import { getWorkData, getrecord } from "@/api/response";
// import checkTableVue from './checkTable.vue';

export default {
  props: {
    rows: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: true,
      fromLoading: false,
      formData: {
        emergency: "",
        level: "",
      },
      tableData: [],
    };
  },
  created() {
    this.record();
  },
  methods: {
    record() {
      getrecord({ id: 35 }).then((res) => {
        if (res.code == 0) {
          this.formData.emergency = res.data.type.label;
          this.formData.level = res.data.currentLevel.label;
        }
      });
      this.fromLoading = true;
      getWorkData({
        responseRecordId: this.rows.responseId,
        siteId: this.rows.siteId,
        departmentId: "1",
      }).then((res) => {
        if (res.code == 0) {
          this.fromLoading = false;
          this.tableData = res.data;
        } else {
          this.fromLoading = false;
          this.$message.warning(res.message);
        }
      });
    },
    cancel() {
      this.$emit("closeDialog");
    },
    // 审核
    handleClick() {},
    // 状态
    ztformatter() {},
  },
};
</script>

<style></style>
