<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" :title="title">
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="100px"
      class="demo-model"
    >
      <el-form-item label="线路工点" prop="lineSite">
        <el-cascader
          ref="linecas"
          placeholder="选择线路工点"
          v-model="model.lineSite"
          :options="linetree"
          :props="props"
          filterable
          clearable
          @change="getlabel"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="备注" prop="note">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 6 }"
          v-model="model.note"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
      <el-form-item label="上传图片" prop="imageUrl">
        <image-uploader
          v-model="model.imageUrl"
          @change="urlchange($event)"
        ></image-uploader>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import ImageUploader from "@/components/Uploader/ImageUploader.vue";
import { drainagerAdd, drainagerModify, tripleTree } from "@/api/map";
export default {
  components: {
    systemModal,
    ImageUploader,
  },
  props: { type: {} },
  data() {
    return {
      title: "新增",
      model: {
        lineSite: [],
        imageUrl: "",
        note: "",
      },
      loading: false,
      rules: {
        note: [{ required: true, message: "请输入备住", trigger: "blur" }],
        lineSite: [
          { required: true, message: "请选择线路工点", trigger: "change" },
        ],
        imageUrl: [
          { required: true, message: "请上传图片", trigger: "change" },
        ],
      },
      props: {
        value: "id",
        label: "name",
        children: "children",
      },
      lineName: [],
      linetree: [],
      lineUuid: "",
      siteUuid: "",
      lineSite: [],
      FlattenArray: [],
    };
  },
  mounted() {
    this.queryLineSiteTree();
  },
  methods: {
    //获取线路树
    queryLineSiteTree() {
      tripleTree({}).then((res) => {
        if (res.code == 0) {
          let lineSiteData = JSON.parse(JSON.stringify(res.data));
          this.linetree = lineSiteData;
        }
      });
    },
    finduuid(params) {
      let that = this;
      that.FlattenArray.push(params);
      if (params.children && params.children.length > 0) {
        params.children.forEach((i) => {
          that.finduuid(i);
        });
      }
    },
    getnameuuid(uuid) {
      let that = this;
      let puuidObj = that.FlattenArray.find((item) => item.uuid == uuid);
      if (puuidObj) {
        that.lineSite.unshift(puuidObj.id);
        that.lineName.unshift(puuidObj.name);
        that.getnameuuid(puuidObj.puuid);
      }
    },
    //新增或者回显
    handleAdd(record) {
      if (record) {
        let that = this;
        // 变成扁平数组
        that.lineSite = [];
        that.FlattenArray = [];
        this.linetree.forEach((item) => {
          if (item.id == record.lineId) {
            that.finduuid(item);
          }
        });
        console.log(that.FlattenArray, "扁平数组");
        let siteObj = that.FlattenArray.find(
          (item) => item.id == record.siteId
        );
        console.log(siteObj, "当前站点的信息");
        that.lineSite.push(siteObj.id);
        that.lineName.push(siteObj.name);
        that.getnameuuid(siteObj.puuid);
        console.log(that.lineSite, "数据回显的树");
        this.title = "编辑";
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
        this.model = {
          id: record.id,
          lineSite: that.lineSite,
          note: record.note,
          imageUrl: record.imageUrl,
        };
      } else {
        this.title = "新增";
        this.model = {
          lineSite: [],
          note: "",
          imageUrl: "",
        };
      }
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    //线路树的change
    getlabel() {
      if (this.model.lineSite.length != 0) {
        this.lineName = this.$refs["linecas"].getCheckedNodes()[0].pathLabels;
      }
      console.log(this.lineName);
    },
    urlchange(url) {
      this.model.imageUrl = url;
    },
    forfunid(data) {
      let that = this;
      if (data.id == that.model.lineSite[that.model.lineSite.length - 1]) {
        that.siteUuid = data.uuid;
      } else {
        if (data.children && data.children.length > 0) {
          data.children.forEach((item) => {
            that.forfunid(item);
          });
        }
      }
    },
    handleSubmit() {
      let that = this;
      console.log(this.model, "this.model");
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let name =
            this.type == "1"
              ? "场地周边排水图"
              : this.type == "2"
              ? "防汛作战图"
              : "应急疏散图";
          that.linetree.forEach((item) => {
            if (item.id == that.model.lineSite[0]) {
              that.lineUuid = item.uuid;
            }
            that.forfunid(item);
          });

          if (this.title == "新增") {
            let params = {
              imageName: name,
              imageUrl: this.model.imageUrl,
              lineId: this.model.lineSite[0],
              lineName: this.lineName[0],
              lineUuid: that.lineUuid,
              note: this.model.note,
              siteId: this.model.lineSite[this.model.lineSite.length - 1],
              siteName: this.lineName[this.lineName.length - 1],
              siteUuid: that.siteUuid,
              type: this.type,
            };
            drainagerAdd(params).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "新增成功！",
                  type: "success",
                });
                this.$emit("getdata");
                this.$refs.formModal.handleClose();
              } else {
                this.$message.error("新增失败！");
              }
              this.loading = false;
            });
          } else {
            console.log(this.model.imageUrl);
            let params = {
              id: this.model.id,
              imageName: name,
              imageUrl: this.model.imageUrl,
              lineId: this.model.lineSite[0],
              lineName: this.lineName[0],
              lineUuid: that.lineUuid,
              note: this.model.note,
              siteId: this.model.lineSite[this.model.lineSite.length - 1],
              siteName: this.lineName[this.lineName.length - 1],
              siteUuid: that.siteUuid,
              type: this.type,
              state: 2,
            };
            drainagerModify(params).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "修改成功！",
                  type: "success",
                });
                this.$emit("getdata");
                this.$refs.formModal.handleClose();
              } else {
                this.$message.error("修改失败！");
              }
              this.loading = false;
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
