<template>
  <!-- 视频监控 单兵通信  多人会议 全屏的页面 -->
  <el-dialog
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :fullscreen="fullscreen"
    :modal="true"
    :close-on-click-modal="false"
    :append-to-body="true"
    :show-close="false"
  >
    <div class="modal-container">
      <div class="corner-wrap">
        <div class="corner corner-left-top"></div>
        <div class="corner corner-right-top"></div>
        <div class="corner corner-right-bottom"></div>
        <div class="corner corner-left-bottom"></div>
      </div>
      <div class="modal-wrapper p-4">
        <div class="header flex items-center justify-center mb-4">
          <div class="title text-center">{{ modelTitle }}</div>
          <div class="close-btn absolute right-4" @click="handleClose"></div>
        </div>

        <div class="page-container  p-4 h-full">
          <div class="search-wrapper flex justify-between">
            <!-- 左侧的tab -->
            <el-row>
              <el-button
                type="text"
                :class="modelTitle == '视频监控' ? 'el-button-border' : ''"
                @click="tabsClick('视频监控')"
                >视频监控</el-button
              >
              <el-button
                type="text"
                :class="modelTitle == '单兵通信' ? 'el-button-border' : ''"
                @click="tabsClick('单兵通信')"
                >单兵通信</el-button
              ><el-button
                type="text"
                :class="modelTitle == '多人会议' ? 'el-button-border' : ''"
                @click="tabsClick('多人会议')"
                >多人会议</el-button
              >
            </el-row>
            <!-- 右侧图标 -->
            <el-row v-if="modelTitle == '视频监控'">
              <img src="@/assets/screen.png" alt="" class="img-class" />
              <i
                class="el-icon-s-grid img-class"
                :style="{ color: splitScreenType == 9 ? '#fff' : '#6B748E' }"
                @click="handleSplitScreen(9)"
              ></i
              ><i
                class="el-icon-menu img-class"
                :style="{ color: splitScreenType == 4 ? '#fff' : '#6B748E' }"
                @click="handleSplitScreen(4)"
              ></i>
            </el-row>
            <el-row v-if="modelTitle == '单兵通信'">
              <img
                src="@/assets/user.png"
                alt=""
                class="img-class img-class-1"
                @click="useradd"
              />
              <img
                src="@/assets/screen.png"
                alt=""
                class="img-class img-class-1"
              />
              <el-button type="danger" size="mini">全部挂断</el-button>
            </el-row>
            <el-row v-if="modelTitle == '多人会议'">
              <img
                src="@/assets/user.png"
                alt=""
                class="img-class img-class-1"
              />
              <img
                src="@/assets/screen.png"
                alt=""
                class="img-class img-class-1"
              />
              <el-button type="danger" size="mini">结束会议</el-button>
            </el-row>
          </div>
          <!-- 内容  -->
          <div
            class="mt-8 video-wrapper flex-1"
            v-loading="loading"
            element-loading-text="数据加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.5)"
          >
            <!-- 视频监控tab -->
            <div
              class="block"
              style="height: 100%;"
              v-show="modelTitle == '视频监控'"
            >
              <el-carousel
                :autoplay="false"
                trigger="click"
                style="height: 100%;"
                indicator-position="click"
              >
                <el-carousel-item v-for="(i, index) in videoData" :key="index">
                  <div
                    class="float-left work-canten"
                    v-for="(item, ind) in i"
                    :class="
                      (ind % 2 == 0 ? 'mr-1' : '',
                      splitScreenType == 4 ? 'division4' : 'division9')
                    "
                    :key="item.id"
                  >
                    <video
                      :id="`my-video${item.id}`"
                      class="video-js vjs-default-skin"
                      controls
                      preload="auto"
                      width="100%"
                      height="100%"
                      muted
                      v-if="item.sourceId == 0"
                    >
                      <source :src="item.url" type="application/x-mpegURL" />
                    </video>
                    <video
                      v-else-if="item.sourceId == 2"
                      class="video-js vjs-default-skin"
                      :id="`testVideo${item.id}`"
                    ></video>
                    <iframe
                      v-else
                      class="h-full w-full"
                      :src="item.url"
                      frameborder="0"
                    ></iframe>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <!-- 单兵通信TAB -->
            <div
              class="block"
              style="height: 100%;"
              v-show="modelTitle == '单兵通信'"
            >
              <el-carousel
                :autoplay="false"
                trigger="click"
                style="height: 100%;"
                indicator-position="click"
              >
                <el-carousel-item v-for="(i, index) in videoData" :key="index">
                  <div
                    class="float-left work-canten"
                    v-for="item in 1"
                    :key="item.id"
                  >
                    <div
                      class="video-item division12"
                      v-for="(item, ind) of 12"
                      :class="ind % 2 == 0 ? 'mr-1' : ''"
                      :key="item.id"
                    >
                      <img src="../../../../assets/dummy.png" alt="" />
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <!-- 多人会议tab -->
            <div
              class="block"
              style="height: 100%;"
              v-show="modelTitle == '多人会议'"
            >
              <el-carousel
                :autoplay="false"
                trigger="click"
                style="height: 100%;"
                indicator-position="click"
              >
                <el-carousel-item v-for="(i, index) in videoData" :key="index">
                  <div
                    class="float-left work-canten"
                    v-for="(item, ind) in 1"
                    :class="ind % 2 == 0 ? 'mr-1' : ''"
                    :key="item.id"
                  >
                    <div
                      class="video-item division12"
                      v-for="item of 12"
                      :key="item.id"
                    >
                      <img src="../../../../assets/dummy.png" alt="" />
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <!-- 线路工点树选择面板 -->
        <div
          class="handle-wrapper"
          :class="isShowPanl ? 'handle-wrapper-show' : 'handle-wrapper-hide'"
        >
          <div
            class="toggle-btn"
            :class="isShowPanl ? 'toggle-btn-close' : 'toggle-btn-open'"
            @click="handleTogglePanl"
          ></div>
          <div class="tree-wrapper h-full overflow-y-scroll">
            <el-input
              placeholder="输入关键字进行过滤"
              v-model="filterText"
              clearable
              style="margin-bottom: 10px; width: 95%"
            >
            </el-input>
            <el-tree
              ref="tree"
              class="filter-tree"
              v-show="isShowPanl"
              :data="lineSiteOptions"
              show-checkbox
              expand-on-click-node
              node-key="id"
              @check="handleTreeCheck"
              @node-click="handleTreeCheckChange"
              :default-expanded-keys="defaultExpandedKeys"
              :default-checked-keys="defaultCheckedKeys"
              :filter-node-method="filterNode"
              :props="props"
              :accordion="true"
            ></el-tree>
          </div>
        </div>
      </div>
    </div>
    <inviteDialog ref="invite"></inviteDialog>
  </el-dialog>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
// import Modal from "@/components/Modal/modal.vue";
// import videos49 from "@/views/HomePage1/rightPage/videos49.vue"; //监控摄像头
import { siteUuidP } from "@/api/map";
import inviteDialog from "@/views/Contingency/HomePage/rightPage/inviteDialog.vue"; // 邀请
import { siteVideos } from "@/api/map";
import { lfsoftPlayerWS } from "@/utils/video";

export default {
  /*  props: {
    eventsSwitching: {
      type: Object,
      default: () => {},
    },
  }, */
  components: {
    // Modal,
    // videos49 ,
    inviteDialog,
  },
  data() {
    return {
      modelTitle: "视频监控",
      loading: false,
      dialogVisible: false,
      fullscreen: false,
      full: true,
      videoData: [],
      splitScreenType: 4,
      filterText: "",
      props: {
        disabled: "disabled",
        children: "children",
        label: "name",
      },
      isShowPanl: true,
      lineSiteOptions: [],
      defaultExpandedKeys: [],
      defaultCheckedKeys: [],
      dimensional: [], //播放视频的数组  平行
      playVideo: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    /* eventsSwitching: {
      handler(val) {
        console.log(val, this.eventsSwitching, val.siteId);
        this.queryLineSiteTreeData(val.siteUuid);
      },
      immediate: true,
    }, */
  },
  methods: {
    // 线路工点树数据查询
    queryLineSiteTreeData(siteId) {
      // let site = JSON.parse(this.$route.query.data);
      // console.log(site);
      this.lineSiteOptions = [];
      this.defaultExpandedKeys = [];
      siteUuidP({ siteId: siteId }).then((res) => {
        if (res.code == 0) {
          this.lineSiteOptions = [JSON.parse(JSON.stringify(res.data))];
          /*  if (this.lineSiteOptions.length > 0) {
            this.defaultExpandedKeys.push(this.lineSiteOptions[0].id);
          } */
          if (this.modelTitle == "视频监控") {
            console.log(this.lineSiteOptions[0]);
            this.recursion(this.lineSiteOptions[0]);
            this.lineSiteOptions.forEach((item) => {
              recursionfun(item);
            });
            // eslint-disable-next-line no-inner-declarations
            function recursionfun(data) {
              data.disabled = true;
              if (data.children && data.children.length > 0) {
                data.children.forEach((item) => {
                  recursionfun(item);
                });
              }
            }
          }
        }
      });
    },
    // 递归拿到工点  页面初始化的方法
    recursion(data) {
      // lineSiteType==0就是工点
      if (data.lineSiteType == 0) {
        this.defaultExpandedKeys.push(data.id);
        this.defaultCheckedKeys.push(data.id);
        this.querySiteVideos(data.id, 1);
        console.log(data.id);
      } else {
        if (data.children && data.children.length > 0) {
          this.recursion(data.children[0]);
        }
      }
    },
    //邀请用户
    useradd() {
      this.$refs.invite.handelOpen();
    },
    // 站点树的查找工点
    filterNode(value, data) {
      console.log(value, data);
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 复选框被选中时的状态
    handleTreeCheck(record, checkInfo) {
      console.log(record, checkInfo, "复选框选中");
      console.log(record.lineSiteType, "0工点", record.videoOrNot, "1摄像头");
      /**
       * 复选框选中后判断是摄像头还是工点
       * 摄像头选中是就push到videoData里
       * 摄像头取消-------
       * 选中的是工点就 调videos接口 查询摄像头
       * 取消选中的是工点就------
       */
      // 工点  就查询他下边的摄像头
      if (record.lineSiteType == 0) {
        // 工点的选中
        if (checkInfo.checkedNodes.includes(record)) {
          //选中就掉接口拿摄像头
          this.querySiteVideos(record.id, 3);
        }
        /* else {
          // this.dimensional = [];
          this.videoData = [];
          console.log(record.children, "取消的工点下边的摄像头");
          // this.dimensional = checkInfo.checkedNodes;
          console.log(this.dimensional, "工点取消选择视频播放的数组");
          this.dimensional.forEach((item, index) => {
            record.children &&
              record.children.forEach((element) => {
                if (item.id == element.id) {
                  this.dimensional.splice(index, 1);
                }
              });
          });

          this.dimensional.forEach((item) => {
            if (this.videoData.length == 0) {
              this.videoData.push([item]);
            } else {
              if (
                this.videoData[this.videoData.length - 1].length <
                this.splitScreenType
              ) {
                this.videoData[this.videoData.length - 1].push(item);
              } else {
                this.videoData.push([item]);
              }
            }
          });
        } */

        //摄像头
      } else if (record.videoOrNot == 1) {
        //进到这里就是摄像头
        console.log(
          checkInfo.checkedNodes.includes(record),
          "true 就是选中  FALSE就是取消选中"
        );
        // 判断当前选中的在数组里就是选中  不在数组里就是取消选中
        if (checkInfo.checkedNodes.includes(record)) {
          // 判断播放的数组里最后一项是不是小于splitScreenType（几分屏）
          // 是就放进去  不是就自己加个[]push videodata数组里
          if (this.videoData.length > 0) {
            if (
              this.videoData[this.videoData.length - 1].length <
              this.splitScreenType
            ) {
              this.videoData[this.videoData.length - 1].push(record);
              console.log(this.videoData, "摄像头的选中"); //要是当前选中的摄像头是中铁的就播放
              if (record.sourceId == 0) {
                this.$nextTick(() => {
                  this.getVideo(record.id);
                });
              }
              //九碧木的视频
              if (record.sourceId == 2) {
                this.$nextTick(() => {
                  let IPhost = "zhjk.geobim.cn:3226";
                  if (record.ipAddress.slice(0, 3) == 172) {
                    IPhost = "zhjk.geobim.cn:3226";
                  } else {
                    IPhost = "spjk.geobim.cn:3234";
                  }
                  var conf1 = {
                    videoid: "testVideo" + record.id,
                    protocol: "https:",
                    host: IPhost,
                    rootpath: "/",
                    token: record.ipAddress,
                    hlsver: "v1",
                    session: "c1782caf-b670-42d8-ba90-2244d0b0ee83", //session got from login
                  };
                  console.log(conf1);
                  var v1 = new lfsoftPlayerWS(conf1);
                  this.playVideo.push(v1);
                  v1.connect();
                });
              }
            } else {
              this.videoData.push([record]);
              console.log(this.videoData, "摄像头的选中new");
            }
          } else {
            this.videoData.push([record]);
            console.log(this.videoData, "视频播放的数组第一项摄像头的选中new");
          }
          this.dimensional.push(record);
        } else {
          console.log("这里是要取消选中的视频");
          let arr = [];
          this.videoData = [];
          this.playVideo.forEach((item) => {
            if (item.W == record.ipAddress) {
              item.disconnect();
            }
          });
          this.dimensional.forEach((item, index) => {
            //取消的视频
            if (item.id == record.id) {
              //video播放的视频 需要走销毁逻辑
              if (record.sourceId == 0) {
                console.log("my-video" + record.id, "销毁的id");
                let video = videojs("my-video" + record.id);
                video.dispose();
              }
              this.dimensional.splice(index, 1);
            }
          });
          this.dimensional.forEach((item) => {
            if (arr.length == 0) {
              arr.push([item]);
            } else {
              if (arr[arr.length - 1].length < this.splitScreenType) {
                arr[arr.length - 1].push(item);
              } else {
                arr.push([item]);
              }
            }
          });
          this.videoData = arr;
          console.log(
            this.dimensional,
            arr,
            this.videoData,
            "删除当前取消的视频的数组"
          );

          /*this.videoData.forEach((item, index) => {
            item.forEach((i, ind) => {
              if (i.id == record.id) {
                console.log(record, record.id, i.name, "销毁");
                if (record.sourceId == 0) {
                  console.log("my-video" + record.id, "销毁的id");
                  let video = videojs("my-video" + record.id);
                  video.dispose();
                }
                //要是当前删除的视频是该页的最后一个摄像头 就把这一页删掉
                if (this.videoData[index].length == 1) {
                  this.videoData.splice(index, 1);
                } else {
                  this.videoData[index].splice(ind, 1);
                }
              }
            });
          }); */
        }
      }
    },
    //点击工点获取他下边的摄像头列表
    handleTreeCheckChange(data, node) {
      console.log(data, node);
      if (!data.children && !data.videoOrNot) {
        this.querySiteVideos(data.id, 2);
      }
    },
    // 查询工点下的视频  //点击工点获取他下边的摄像头列表
    querySiteVideos(siteId, type) {
      /**type
       * 1  是页面初始化的默认那第一个站点的前四个摄像头
       * 2  是点击工点树获取改工点的摄像头
       * 3  是工点的选中 把他下边的摄像头全部播放
       */
      this.loading = true;
      const params = {
        siteId,
        type: "-",
      };
      siteVideos(params)
        .then((res) => {
          this.loading = false;
          if (res.code == 0) {
            let treeVideo = [];
            if (res.data.length == 0) {
              this.$message.warning("当前工点没有摄像头");
            } else {
              res.data.forEach((element) => {
                treeVideo.push({
                  id: element.id, //视频id
                  uuid: element.uuid, //视频UUID
                  name: element.name, //视频名字
                  url: element.hls, //视频地址
                  videoOrNot: element.videoOrNot, //1就是摄像头
                  sourceId: element.sourceId, //视频是中铁还是广联达
                  ipAddress: element.ipAddress, //九碧木的播放地址
                });
              });
              this.$nextTick(() => {
                //在当前工点下添加摄像头子节点
                this.$refs["tree"].updateKeyChildren(siteId, treeVideo);
                // type用来区分是页面初始化默认 掉第一个站点的摄像头 并默认选中该工点下的摄像头
                //  false是页面初始化的查询摄像头
                if (type == 1) {
                  //默认选中4个 默认播放4个
                  this.videoData = [];
                  this.dimensional = [];
                  let checkTree = treeVideo.slice(0, 4);
                  //因为页面初始化时默认是4分屏 只勾选第一个工点下的前4个摄像头
                  this.$refs["tree"].setCheckedNodes(checkTree);
                  console.log(checkTree, treeVideo);
                  this.videoData = [checkTree];
                  this.dimensional = checkTree; //初始化的视频数组 平级
                  // 第一个工点下的前4个摄像头
                  checkTree.forEach((item) => {
                    if (item.sourceId == 0) {
                      console.log(item.id, "播放视频id");
                      this.$nextTick(() => {
                        this.getVideo(item.id);
                      });
                    }
                    //九碧木的视频
                    if (item.sourceId == 2) {
                      this.$nextTick(() => {
                        let IPhost = "zhjk.geobim.cn:3226";
                        if (item.ipAddress.slice(0, 3) == 172) {
                          IPhost = "zhjk.geobim.cn:3226";
                        } else {
                          IPhost = "spjk.geobim.cn:3234";
                        }
                        var conf1 = {
                          videoid: "testVideo" + item.id,
                          protocol: "https:",
                          host: IPhost,
                          rootpath: "/",
                          token: item.ipAddress,
                          hlsver: "v1",
                          session: "c1782caf-b670-42d8-ba90-2244d0b0ee83", //session got from login
                        };
                        console.log(conf1);
                        var v1 = new lfsoftPlayerWS(conf1);
                        this.playVideo.push(v1);
                        v1.connect();
                      });
                    }
                  });
                }
                /*   if (type == 3) {
                  this.videoData = [];
                  // 这个是防止当前选中的工点下的摄像头多次push到选中的摄像头播放数组
                  // let newVideo = [];
                  console.log(
                    this.dimensional,
                    treeVideo,
                    "选中站点没有重复选中的1"
                  );
                  let array = this.dimensional.concat(treeVideo);
                  let nn = Array.from(new Set(array));
                  console.log(
                    this.dimensional,
                    "原来选中的",
                    treeVideo,
                    "现在选中的",
                    array,
                    "合并后的",
                    nn,
                    "去重后的"
                  );
                  //先把原来选中的销毁播放
                  this.dimensional.forEach((item) => {
                    if (item.sourceId == 0) {
                      videojs("my-video" + item.id).dispose();
                    }
                  });
                  this.$refs["tree"].setCheckedNodes(nn);
                  // nn 是原来选中的加上现在选中的 去重的视频
                  nn.forEach((i) => {
                    if (i.sourceId == 0) {
                      console.log(i.id, "播放视频id");
                      this.$nextTick(() => {
                        this.getVideo(i.id);
                      });
                    }
                    if (this.videoData.length == 0) {
                      this.videoData.push([i]);
                    } else {
                      if (
                        this.videoData[this.videoData.length - 1].length <
                        this.splitScreenType
                      ) {
                        this.videoData[this.videoData.length - 1].push(i);
                      } else {
                        this.videoData.push([i]);
                      }
                    }
                  });
                  console.log(
                    this.videoData,
                    "选中工点 把其下边的摄像头放到播放的视频数组里"
                  );
                } */
              });
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 是否显示站点树
    handleTogglePanl() {
      this.isShowPanl = !this.isShowPanl;
    },
    // 四九分屏
    handleSplitScreen(type) {
      this.splitScreenType = type;
      this.videoData = [];
      this.dimensional.forEach((item) => {
        if (this.videoData.length == 0) {
          this.videoData.push([item]);
        } else {
          if (
            this.videoData[this.videoData.length - 1].length <
            this.splitScreenType
          ) {
            this.videoData[this.videoData.length - 1].push(item);
          } else {
            this.videoData.push([item]);
          }
        }
      });

      /*  if (type == 4) {
        this.videoData = this.videoData4;
      } else {
        this.videoData = this.videoData9;
      } */
    },
    // 页面打开   视频监控0  单兵通信1   多人会议2  eventsSwitching事件切换的事件信息
    handelOpen(val, eventsSwitching) {
      console.log(eventsSwitching);
      if (val == 0) {
        this.modelTitle = "视频监控";
      } else if (val == 1) {
        this.modelTitle = "单兵通信";
      } else if (val == 2) {
        this.modelTitle = "多人会议";
      }
      this.queryLineSiteTreeData(eventsSwitching.siteUuid);
      this.dialogVisible = true;
      this.fullscreen = true;
    },
    // 视频播放
    getVideo(i) {
      console.log(i, "my-video" + i, "当前要创建的节点video");
      videojs(
        "my-video" + i,
        {
          bigPlayButton: false,
          textTrackDisplay: false,
          posterImage: true,
          errorDisplay: false,
          controlBar: true,
        },
        function() {
          this.play();
        }
      );
    },
    // 视频监控多人会议的切换
    tabsClick(tab) {
      this.modelTitle = tab;
      if (this.modelTitle == "视频监控") {
        this.videoData &&
          this.videoData.forEach((item) => {
            item.forEach((i) => {
              if (i.sourceId == 0) {
                console.log(i.id, "播放视频id");
                this.$nextTick(() => {
                  this.getVideo(i.id);
                });
              }
              //九碧木的视频
              if (item.sourceId == 2) {
                this.$nextTick(() => {
                  let IPhost = "zhjk.geobim.cn:3226";
                  if (item.ipAddress.slice(0, 3) == 172) {
                    IPhost = "zhjk.geobim.cn:3226";
                  } else {
                    IPhost = "spjk.geobim.cn:3234";
                  }
                  var conf1 = {
                    videoid: "testVideo" + item.id,
                    protocol: "https:",
                    host: IPhost,
                    rootpath: "/",
                    token: item.ipAddress,
                    hlsver: "v1",
                    session: "c1782caf-b670-42d8-ba90-2244d0b0ee83", //session got from login
                  };
                  console.log(conf1);
                  var v1 = new lfsoftPlayerWS(conf1);
                  v1.connect();
                });
              }
            });
          });
      }
    },
    //页面关闭
    handleClose() {
      this.dialogVisible = false;
      console.log("页面关闭");
      this.videoData.forEach((item) => {
        item.forEach((i) => {
          if (i.sourceId == 0) {
            console.log("my-video" + i.id, "时间监控销毁");
            videojs("my-video" + i.id).dispose();
          }
        });
      });
      this.playVideo.forEach((item) => {
        item.disconnect();
      });
    },
  },
  // 页面销毁
  beforeDestroy() {
    console.log("页面销毁");
    this.videoData.forEach((item) => {
      item.forEach((i) => {
        if (i.sourceId == 0) {
          console.log("my-video" + i.id, "时间监控销毁");
          videojs("my-video" + i.id).dispose();
        }
      });
    });
    this.playVideo.forEach((item) => {
      item.disconnect();
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-button--text {
  color: #fff !important;
}

//树的搜索框
::v-deep .el-input__inner {
  background: #18467f;
  border: none;
  color: #fff;
}
//按钮选中的下划线
.el-button-border {
  border-bottom: 1px solid #43a5e9;
}
// 右侧图标的样式
.img-class {
  width: 20px;
  margin-right: 10px;
  display: inline-block;
  color: #fff;
  font-size: x-large;
}
.img-class-1 {
  margin-bottom: -8px;
}
// 监控的布局
::v-deep .video-js {
  width: 100%;
  height: 100%;
}
.video-wrapper {
  height: calc(100% - 4rem);
  .video-group {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .video-item {
      background: #000;
    }
  }
}

//线路工点树的布局
.handle-wrapper {
  min-width: 240px;
  max-width: 30vw;
  height: 60vh;
  position: absolute;
  top: 20%;
  background: rgba(11, 20, 53, 0.9);
  box-shadow: 10px 0px 25px 0px #002e75 inset;
  border-radius: 0px 20px 20px 0px;
  padding: 20px;
  z-index: 99;

  .tree-wrapper {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  ::v-deep .el-tree {
    background: transparent;
    color: #fff;
  }

  ::v-deep .el-tree-node__content:hover {
    background: transparent;
  }

  ::v-deep .el-tree-node:focus > .el-tree-node__content {
    background: transparent;
  }

  .toggle-btn {
    width: 28px;
    height: 240px;
    position: absolute;
    right: 0px;
    top: 50%;
    margin-top: -120px;
    cursor: pointer;
    background-image: url(~@/assets/sq.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }

  .toggle-btn-close {
    transform: rotate(180deg);
  }

  .toggle-btn-open {
    transform: rotate(0deg);
  }
}

.handle-wrapper-hide {
  left: -212px;
  background: transparent;
  box-shadow: none;
}

.handle-wrapper-show {
  left: 0px;
  background: rgba(11, 20, 53, 0.9);
  box-shadow: 10px 0px 25px 0px #002e75 inset;
}

//弹窗的样式
::v-deep .el-dialog .el-dialog__body {
  height: 100%;
}
.modal-container {
  height: 100%;
}
.modal-wrapper {
  height: calc(100% - 2rem);
}
::v-deep .el-dialog {
  border-radius: 0;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
    position: relative;
    background: #081742;
    border: 1px solid #002e75;
    box-shadow: 0px 0px 23px 6px #002e75 inset;
  }
}
// 弹窗的4个角
.corner-wrap {
  width: 100%;
  height: 100%;
  position: absolute;

  .corner {
    width: 16px;
    height: 16px;
  }

  .corner-left-top {
    border-left: 2px solid #43a5e9;
    border-top: 2px solid #43a5e9;
    position: absolute;
    left: 0;
    top: 0;
  }

  .corner-right-top {
    border-right: 2px solid #43a5e9;
    border-top: 2px solid #43a5e9;
    position: absolute;
    right: 0;
    top: 0;
  }

  .corner-right-bottom {
    border-right: 2px solid #43a5e9;
    border-bottom: 2px solid #43a5e9;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .corner-left-bottom {
    border-left: 2px solid #43a5e9;
    border-bottom: 2px solid #43a5e9;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.modal-wrapper {
  .header {
    .title {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    .close-btn {
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/closeBtn.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
}

//走马灯的左右两个角隐藏掉
::v-deep .el-carousel__container {
  // height: 100% !important;
  height: 92% !important;
}
::v-deep .el-carousel__arrow {
  display: none !important;
}

//一页4/9/12
.work-canten {
  width: 100%;
  height: 100%;
}
.division9 {
  border: 1px solid #eee;
  width: 32.65%;
  // height: 32%;
  height: 32.4%;
  margin-top: 0.5%;
  margin-right: 0.5%;

  &:nth-child(1) {
    margin-top: 0;
  }

  &:nth-child(2) {
    margin-top: 0;
  }

  &:nth-child(3) {
    margin-top: 0;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }
}

.division4 {
  border: 1px solid #eee;
  width: 49.5%;
  height: 49.2%;
  margin-top: 0.5%;
  margin-right: 0.5%;

  &:nth-child(1) {
    margin-top: 0;
  }

  &:nth-child(2) {
    margin-top: 0;
  }

  &:nth-child(2n) {
    margin-right: 0;
  }
}
.division12 {
  display: inline-block;
  width: 16.2%;
  height: 49.2%;
  // margin-top: 0.5%;
  // margin-right: 0.5%;
  // border: 1px solid red;
  &:nth-child(1) {
    margin-top: 0;
  }

  &:nth-child(2) {
    margin-top: 0;
  }
  &:nth-child(3) {
    margin-top: 0;
  }
  &:nth-child(4) {
    margin-top: 0;
  }
  &:nth-child(5) {
    margin-top: 0;
  }
  &:nth-child(6) {
    margin-top: 0;
  }

  &:nth-child(6n) {
    margin-right: 0;
  }
}
</style>
