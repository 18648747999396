<template>
   <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item>
            <el-input
              v-model="queryModal.teamName"
              clearable
              placeholder="输入队伍名称查询"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="handleDownload" v-has="'Assistance:download'"
            >下载导入模板</el-button
          >
          <el-button type="primary" @click="handleImport" v-has="'Assistance:therimport'">导入</el-button>
          <el-button type="primary" @click="handleExport" v-has="'Assistance:export'">导出</el-button>
          <el-button type="primary" @click="handleAdd" v-has="'Assistance:add'"
            >新增</el-button
          >
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="loading"
        border
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="70"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="teamName"
          label="名称"
          show-overflow-tooltip
        ></el-table-column>
     
        <el-table-column
          align="center"
          prop="teamStation"
          label="队伍驻地"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="rescueScope"
          label="救援区域"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="numOfPeople"
          label="人数"
          width="70"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="captainName"
          label="负责人"
           width="100"
        ></el-table-column>
        <!-- <el-table-column align="center" label="坐标" width="250">
          <template slot-scope="scope" v-if="scope.row.lng && scope.row.lat">
            {{ scope.row.lng + '，' + scope.row.lat }}
          </template>
          <template slot-scope="scope" v-else>
            {{ scope.row.lng + '，' + scope.row.lat }}
          </template>
        </el-table-column> -->
        <el-table-column
          align="center"
          prop="captainPhone"
          label="联系方式"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="200px"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-has="'Assistance:edit'"
              >编辑</el-button
            >
            <el-button
              @click="handleDelete(scope.row)"
              v-has="'Assistance:del'"
              type="text"
              >删除</el-button
            >
            <el-popover placement="left" width="100" trigger="click">
              <el-button
                type="text"
                style="margin-left:9px"
                @click="MemberList(scope.row)"
                v-has="'Assistance:member'"
                >人员名单</el-button
              >
              <el-button
                type="text"
                @click="MaterialList(scope.row)"
                v-has="'Assistance:MaterialList'"
                >物资清单</el-button
              >
              <el-button
                type="text"
                @click="EquipmentList(scope.row)"
                v-has="'Assistance:EquipmentList'"
                >设备清单</el-button
              >
              <el-button slot="reference" type="text" style="margin-left:10px"
                >更多</el-button
              >
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <StanceFrom ref="modelForm" @formSubmit="handleFormSubmit"></StanceFrom>
  </div>
</template>

<script>
import StanceFrom from "./modules/stanceFrom.vue";
import { getSoleDuty, delSoleDuty } from "@/api/eres";
// import { queryDictByLabel, queryDictNodesById } from '@/api/dict'
// import { queryOrgPageList } from '@/api/org'

export default {
  components: {
    StanceFrom,
  },
  data() {
    return {
      type: "",
      queryModal: {
        teamName: "",
      },
      tableData: [],
      loading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    handleSearch() {
      this.loadData();
    },
    handleDownload() {
      console.log("下载导入模板");
    },
    handleImport() {
      console.log("导入");
    },
    handleExport() {
      console.log("导出");
    },
    MemberList(row) {
      this.$router.push({
        path: "MemberList",
        query: {
          id: row.id,
          name: row.teamName,
          type: "stance",
        },
      });
    },
    MaterialList(row) {
      this.$router.push({
        path: "MaterialList",
        query: {
          id: row.id,
          name: row.teamName,
          type: "stance",
        },
      });
    },
    EquipmentList(row) {
      this.$router.push({
        path: "EquipmentList",
        query: {
          id: row.id,
          name: row.teamName,
          type: "stance",
        },
      });
    },
    handleAdd() {
      this.$refs.modelForm.handleAdd(this.type);
    },
    handleEdit(record) {
      this.$refs.modelForm.handleAdd(this.type, record);
    },
    handleDelete(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delSoleDuty(record.id).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadData();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    handleFormSubmit() {
      this.loadData();
    },
    loadData() {
      this.loading = true;
      const params = {
        page: this.page.pageNo,
        size: this.page.pageSize,
        teamName: this.queryModal.teamName ? this.queryModal.teamName : "-",
        teamType:3,
      };
      getSoleDuty(params).then((res) => {
        if (res.code == 0) {
          console.log(res.data.records);
          const { current, size, total, count } = res.data;
          this.tableData = res.data.records ? res.data.records : [];
          this.page.pageNo = current;
          this.page.pageSize = size;
          this.page.total = total;
          this.page.count = count;
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 1;
}
.p-4 {
  overflow-y: scroll;
}
::v-deep ::-webkit-scrollbar {
  height: 10px !important; /*对水平流动条有效*/
  display: block !important;
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: #fff !important;
  border-radius: 7px;
}
/*定义滑块颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}

//去掉固定列下方的横线
::v-deep .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: transparent;
}
//去掉固定列阴影
::v-deep .el-table__fixed-right {
  // box-shadow: none;
  height: 100% !important;
}
</style>
