import { httpInit } from "./http";
const http = httpInit("/gzdt-risk");
// 风险排查频次
//分页查询
export const troubleshootingList = (params) => http.post(`/riskTroubleshootingFrequency/page`, params);
//新增
export const troubleshootingAdd = (params) => http.post(`/riskTroubleshootingFrequency/add`, params);
//编辑
export const troubleshootingEdit = (params) => http.post(`/riskTroubleshootingFrequency/edit`, params);
//删除
export const troubleshootingDel = (params) => http.delete(`/riskTroubleshootingFrequency/${params}`);

// 风险考核标准
//分页查询
export const assessmentList = (params) => http.post(`/riskAssessmentCriteria/page`, params);
//新增
export const assessmentAdd = (params) => http.post(`/riskAssessmentCriteria/add`, params);
//编辑
export const assessmentEdit = (params) => http.post(`/riskAssessmentCriteria/edit`, params);
//删除
export const assessmentDel = (params) => http.delete(`/riskAssessmentCriteria/${params}`);

// 预警响应管理
//分页查询
export const warnList = (params) => http.post(`/riskWarnResponse/page`, params);
//新增
export const warnAdd = (params) => http.post(`/riskWarnResponse/add`, params);
//编辑
export const warnEdit = (params) => http.post(`/riskWarnResponse/edit`, params);
//删除
export const warnDel = (params) => http.delete(`/riskWarnResponse/${params}`);

// 风险流程管理
//分页查询
export const processList = (params) => http.get(`/riskProcessManagement/page?pageNum=${params.pageNum}&pageSize=${params.pageSize}${params.modularCode ? "&modularCode=" + params.modularCode : ""}`);
//新增
export const processAdd = (params) => http.post(`/riskProcessManagement/add`, params);
//编辑
export const processEdit = (params) => http.post(`/riskProcessManagement/edit`, params);
//删除
export const processDel = (params) => http.delete(`/riskProcessManagement/${params}`);
//通过id查询
export const getProcessById = (params) => http.get(`/riskProcessManagement/${params}`);

// 风险待办通知
//分页查询
export const todoList = (params) => http.get(`/riskNotification/page?pageNum=${params.pageNum}&pageSize=${params.pageSize}${params.riskEventCode ? "&riskEventCode=" + params.riskEventCode : ""}`);
//新增
export const todoAdd = (params) => http.post(`/riskNotification/add`, params);
//编辑
export const todoEdit = (params) => http.post(`/riskNotification/edit`, params);
//删除
export const todoDel = (params) => http.delete(`/riskNotification/${params}`);

//id查询
export const todoDetail = (params) => http.get(`/riskNotification/${params}`);

// 风险清单草稿接口
//分页查询
export const riskLibraryList = (params) => http.post(`/riskItemDraft/page`, params);
//新增
export const riskLibraryAdd = (params) => http.post(`/riskItemDraft/add`, params);
//编辑
export const riskLibraryEdit = (params) => http.post(`/riskItemDraft/edit`, params);
//删除
export const riskLibraryDel = (params) => http.delete(`/riskItemDraft/${params}`);
//id查询
export const riskLibraryDetail = (params) => http.get(`/riskItemDraft/${params}`);
//审批
export const riskLibraryapply = (params) => http.post(`/riskItemDraft/approvalRiskItemDraftDraft`, params);
//查询详情
export const getLibraryDetail = (params) => http.get(`/riskItem/getListByRiskMasterUuid?riskMasterUuid=${params.riskMasterUuid}&yearQuarter=${params.yearQuarter || ''}`);
// 风险清单接口
//id查询
export const riskDetail = (params) => http.get(`/riskItem/${params}`);

//分页查询
export const riskLibraryRealList = (params) => http.post(`/riskItem/page`, params);
//删除
export const riskLibraryRealDel = (params) => http.delete(`/riskItem/${params}`);
//查询年度季度
export const riskLibraryYear = () => http.get(`/riskItem/getMinYearQuarter`);
//查询审批记录
export const riskApprovalRecord = (params) => http.get(`/risk_approval_record/list?riskDeafUuid=${params}`);
//审批id查询
export const getRiskDeafUuidTwo = (params) => http.get(`/riskItemDraft/getByApprovalRecordId/${params}`);
// 措施处置
//待处置分页查询
export const measureList = (params) => http.post(`/riskPatrolProblemRectification/unRectificationPage`, params);
//已处置分页查询
export const measureFinishList = (params) => http.post(`/riskPatrolProblemRectification/rectifiedPage`, params);
//风险基本信息
export const getMsg = (params) => http.get(`/riskItem/findByUuid?riskDeafUuid=${params}`);
//风险基本信息
export const getMeasures = (params) => http.get(`/riskPatrolProblemRectification/measureProcessedList?riskDeafUuid=${params}`);
//查询处置列表
export const getMeasuresList = (params) => http.get(`/riskPatrolProblemRectification/listProblemsByMeasureId?measureId=${params}`);
//问题处置
export const setProblem = (params) => http.post(`/riskPatrolProblemRectification/deal`, params);
//查询问题处置
export const getProblem = (params) => http.get(`/riskPatrolProblemRectification/findByProblemId?problemId=${params}`);
// 验收
export const setCheck = (params) => http.post(`/riskPatrolProblemCheck/check`, params);
//查询验收
export const getCheck = (params) => http.get(`/riskPatrolProblemCheck/findByProblemId?problemId=${params}`);

//查询总结
export const getSummary = (params) => http.get(`/riskItem/processSummary?riskDeafUuid=${params}`);
// 风险清单库主表导出
export const exportList = (params) => http.post(`/riskItem/export`, params, {
    responseType: "blob",
});
// 风险清单库草稿表导入
export const importList = (params) => http.post(`/riskItemDraft/import`, params);

// export const importList = (file) => {
//     let form = new FormData();
//     for (let k in file) {
//         form.append(k, file[k]);
//     }
//     return http.post(`/riskItemDraft/import`, form, {
//         headers: {
//             "Content-Type": "multipart/form-data",
//         },
//     });
// };


// 查询eventId
export const getRiskDeafUuid = (params) => http.get(`/riskPatrolProblem/findById?id=${params}`);
export const checkSuccess = (params) => http.post(`/riskbottom/sendBottom`,params);