<template>
  <div class="person-statistics-container">
    <div class="box-container">
      <div class="corner-wrap">
        <div class="corner corner-left-top"></div>
        <div class="corner corner-right-top"></div>
        <div class="corner corner-right-bottom"></div>
        <div class="corner corner-left-bottom"></div>
      </div>
      <div class="main-wrapper 2xl:p-4 md:p-2 xl:p-3">
        <div class="flex h-full w-full flex-col">
          <div
            class="
              header
              flex
              items-center
              bg-gradient-to-r
              from-index-title-bg
              2xl:my-4
              xl:my-2
              md:my-2
            "
          >
            <p
              class="header-title 2xl:text-lg xl:text-base md:text-xs"
              style="
                width: 200px;
                overflow: hidden;
                white-space: nowrap;
                display: block;
              "
            >
              三防应急响应统计
            </p>
            <div class="header-title-more 2xl:ml-24">
              <div class="text-xs inline-block ml-1 text-center c">
                <div class="flex cursor-pointer">
                  <span
                    style="
                      width: 24px;
                      height: 24px;
                      color: #ffffff;
                      border-top-left-radius: 4px;
                      border-bottom-left-radius: 4px;
                      line-height: 24px;
                      border: 1px solid #43a5e9;
                    "
                    @click="expandMore(1)"
                    :class="nactive ? 'nactive' : ''"
                    >年</span
                  >
                  <span
                    style="
                      width: 24px;
                      height: 24px;
                      color: #ffffff;
                      border-top-right-radius: 4px;
                      border-bottom-right-radius: 4px;
                      line-height: 24px;
                      border: 1px solid #43a5e9;
                    "
                    @click="expandMore(2)"
                    :class="!nactive ? 'nactive' : ''"
                    >月</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="main-content flex-1 overflow-y-scroll">
            <div class="person-statistics-wrapper text-center" v-if="hackReset">
              <div class="item item-title"></div>
              <div class="item item-title 2xl:text-base xl:text-sm md:text-xs">
                防暴雨内涝
              </div>
              <div class="item item-title 2xl:text-base xl:text-sm md:text-xs">
                防汛
              </div>
              <div class="item item-title 2xl:text-base xl:text-sm md:text-xs">
                防台风
              </div>
              <div class="item item-title 2xl:text-base xl:text-sm md:text-xs">
                合计
              </div>
              <div class="item leave-1 2xl:text-base xl:text-sm md:text-xs">
                I级
              </div>
              <div
                class="item 2xl:text-base xl:text-sm md:text-xs cursor-pointer"
                :class="index < 3 ? 'leave-1' : ''"
                v-for="(s, index) in oneLevel"
                :key="index"
                @click="gotable(0, index)"
              >
                {{ s }}
              </div>
              <div class="item 2xl:text-base xl:text-sm md:text-xs leave-2">
                II级
              </div>
              <div
                class="item 2xl:text-base xl:text-sm md:text-xs cursor-pointer"
                :class="index < 3 ? 'leave-2' : ''"
                v-for="(s, index) in twoLevel"
                :key="index + 10"
                @click="gotable(1, index)"
              >
                {{ s }}
              </div>
              <div class="item 2xl:text-base xl:text-sm md:text-xs leave-3">
                III级
              </div>
              <div
                class="item 2xl:text-base xl:text-sm md:text-xs cursor-pointer"
                :class="index < 3 ? 'leave-3' : ''"
                v-for="(s, index) in thereLevel"
                :key="index + 100"
                @click="gotable(2, index)"
              >
                {{ s }}
              </div>
              <div class="item 2xl:text-base xl:text-sm md:text-xs leave-4">
                Ⅳ级
              </div>
              <div
                class="item 2xl:text-base xl:text-sm md:text-xs cursor-pointer"
                :class="index < 3 ? 'leave-4' : ''"
                v-for="(s, index) in fourLevel"
                :key="index + 1000"
                @click="gotable(3, index)"
              >
                {{ s }}
              </div>
              <div class="item 2xl:text-base xl:text-sm md:text-xs item-title">
                合计
              </div>
              <div
                class="item 2xl:text-base xl:text-sm md:text-xs cursor-pointer"
                v-for="(s, index) in sumdData"
                :key="index + 10000"
                @click="gotable(4, index)"
              >
                {{ s }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      ref="modalDetail"
      :modal="true"
      :width="'1300px'"
      :title="modelTitle"
      @closeDialog="closeDialog"
    >
      <statisticsTable
        v-if="staShow"
        ref="staDetail"
        :level="level"
        :type="type"
      ></statisticsTable>
    </Modal>
  </div>
</template>

<script>
import { getResStatistics } from "@/api/proof";
import Modal from "@/components/Modal/modal.vue";
import statisticsTable from "./statisticsDialog/statisticsTable.vue";
import dayjs from "dayjs";
export default {
  components: {
    Modal,
    statisticsTable,
  },
  data() {
    return {
      title: "年度三防应急响应统计",
      seData: [],
      oneLevel: [0, 0, 0, 0],
      twoLevel: [0, 0, 0, 0],
      thereLevel: [0, 0, 0, 0],
      fourLevel: [0, 0, 0, 0],
      sumdData: [0, 0, 0, 0],
      arrData: [],
      hackReset: true,
      modelTitle: "年度三防应急响应统计",
      levle: "-",
      type: "-",
      staShow: false,
      nactive: true,
    };
  },
  mounted() {
    this.getData(1);
  },
  methods: {
    cheekData(e) {
      if (e.currentLevel == "Ⅰ") {
        this.arrData = this.oneLevel;
        this.conf(e);
      } else if (e.currentLevel == "Ⅱ") {
        this.arrData = this.twoLevel;
        this.conf(e);
      } else if (e.currentLevel == "Ⅲ") {
        this.arrData = this.thereLevel;
        this.conf(e);
      } else if (e.currentLevel == "Ⅳ") {
        this.arrData = this.fourLevel;
        this.conf(e);
      }
    },
    conf(e) {
      if (e.type == "防暴雨内涝") {
        this.arrData[0] = e.count;
        this.arrData[3] = this.arrData[0] + this.arrData[3];
        this.sumdData[0] = this.sumdData[0] + e.count;
      } else if (e.type == "防汛") {
        this.arrData[1] = e.count;
        this.arrData[3] = this.arrData[1] + this.arrData[3];
        this.sumdData[1] = this.sumdData[1] + e.count;
      } else if (e.type == "防台风") {
        this.arrData[2] = e.count;
        this.arrData[3] = this.arrData[2] + this.arrData[3];
        this.sumdData[2] = this.sumdData[2] + e.count;
      }
    },
    expandMore(type) {
      if (type == 2) {
        this.nactive = false;
      } else {
        this.nactive = true;
      }
      this.getData(type);
    },
    async getData(type) {
      let params;
      if (type == 2) {
        params = {
          month: dayjs().format("YYYY-MM"),
        };
      } else {
        params = {};
      }
      this.seData = [];

      this.oneLevel = [0, 0, 0, 0];
      this.twoLevel = [0, 0, 0, 0];
      this.thereLevel = [0, 0, 0, 0];
      this.fourLevel = [0, 0, 0, 0];
      this.sumdData = [0, 0, 0, 0];
      console.log(params);
      let res = await getResStatistics(params);
      console.log(res);
      if (res.code == 0) {
        this.hackReset = false;
        this.seData = res.data;
        this.seData.forEach((e) => {
          this.cheekData(e);
        });
        this.sumdData[3] =
          this.sumdData[0] + this.sumdData[1] + this.sumdData[2];
        this.$nextTick(() => {
          this.hackReset = true;
        });
      }
    },
    async gotable(level, type) {
      this.level = level;
      switch (type) {
        case 0:
          this.type = "防暴雨内涝";
          break;
        case 1:
          this.type = "防汛";
          break;
        case 2:
          this.type = "防台风";
          break;

        default:
          this.type = "-";
          break;
      }
      this.$refs.modalDetail.handleOpen();
      this.staShow = true;
      setTimeout(() => {
        this.$refs.staDetail.initdata();
      }, 1500);
    },
    closeDialog() {
      this.staShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../style/elTable.scss";
.person-statistics-wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1.9fr 1.3fr 1.3fr 1.5fr;
  grid-template-rows: repeat(6, 1fr);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  .item {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
  }
  .item-title {
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #43a5e9;
    line-height: 18px;
    font-weight: normal;
  }
  .leave-1 {
    color: #e23c39;
  }
  .leave-2 {
    color: #f0af41;
  }
  .leave-3 {
    color: #f0ee41;
  }
  .leave-4 {
    color: #1791ff;
  }
}
.box-container {
  width: 100%;
  height: 100%;
  background: rgba(8, 23, 66, 0.9);
  border: 1px solid #002e75;
  box-shadow: 0px 0px 20px 6px #002e75 inset;
  position: relative;
  .corner-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    .corner {
      width: 16px;
      height: 16px;
    }
    .corner-left-top {
      border-left: 2px solid #43a5e9;
      border-top: 2px solid #43a5e9;
      position: absolute;
      left: 0;
      top: 0;
    }
    .corner-right-top {
      border-right: 2px solid #43a5e9;
      border-top: 2px solid #43a5e9;
      position: absolute;
      right: 0;
      top: 0;
    }
    .corner-right-bottom {
      border-right: 2px solid #43a5e9;
      border-bottom: 2px solid #43a5e9;
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .corner-left-bottom {
      border-left: 2px solid #43a5e9;
      border-bottom: 2px solid #43a5e9;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .main-wrapper {
    position: absolute;
    z-index: 10;
    padding-top: 0;
    width: 100%;
    height: 100%;
    & > div {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }
    .header {
      width: 100%;
      height: 32px;
      .header-title {
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        // margin-left: -4rem;
        &::before {
          display: inline-block;
          content: "";
          width: 12px;
          height: 14px;
          margin-left: 11px;
          margin-right: 3px;
          // background-color: rgb(243, 11, 11);
          background-image: url(../../../../assets/box-icon.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .header-title-more {
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #43a5ea;
      }
      .title-right {
        // text-align: right;
      }
    }
  }
  .main-content {
    height: calc(100% - 67px);
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}
.flex {
  // overflow: hidden !important;
}
.nactive {
  background: #43a5e9;
}
</style>
