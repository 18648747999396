<template>
  <div
    style="wieth: 100%; height: 100%; border-radius: 10px; position: relative"
  >
    <div :class="filews == false ? 'search-wrapper_teo' : 'search-wrapper'">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item :inline="true">
          <el-select
            :popper-append-to-body="false"
            class="resources"
            v-model="formInline.resource"
            placeholder="请选择"
            clearable
            @change="resourceChange"
          >
            <el-option
              v-for="item in optionsData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            style="color: #ffffff"
            v-model="formInline.searchName"
            :placeholder="searchNamePlace"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label-width="77px">
          <el-input
            style="width: 77px; margin-left: 10px"
            class="zydpInput"
            v-model="formInline.input"
            placeholder="km"
            @clear="claert"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="querySite" style="margin-left: 10px"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <baidu-map
      :center="page.center"
      :scroll-wheel-zoom="true"
      :zoom="page.zoom"
      class="map"
      @ready="handler"
      :mapClick="false"
    >
      <bm-driving
        v-if="driving"
        :start="{ lng: start[0], lat: start[1] }"
        :end="{ lng: end[0], lat: end[1] }"
        :auto-viewport="true"
      ></bm-driving>
      <!-- 告警marker -->
      <bm-marker
        v-for="(data, index) in markerList"
        :key="'-' + index"
        :position="{ lng: data.coord[0], lat: data.coord[1] }"
        :dragging="false"
        :icon="{
          url: data.url,
          size: { width: 70, height: 96 },
        }"
        :offset="{ width: 0, height: -10 }"
      >
      </bm-marker>
      <bm-circle
        v-for="(data, index) in markerList"
        :key="'-o' + index"
        :center="{ lng: data.coord[0], lat: data.coord[1] }"
        :radius="radiu"
        :stroke-color="styokecolor"
        :fill-color="fillcolor"
        :fillOpacity="fillOpacity"
        :stroke-opacity="opacity"
        :stroke-weight="weight"
        :editing="false"
      ></bm-circle>
      <!--物资库-->
      <bm-marker
        v-for="(data, index) in materLibrary"
        :key="'-ty' + index"
        :position="{ lng: data.lng, lat: data.lat }"
        :dragging="false"
        :title="data.resettlementSite"
        :icon="{
          url: data.url,
          size: { width: 20, height: 20 },
        }"
        :offset="{ width: 5, height: 15 }"
        @click="handleLibrary(data)"
      >
      </bm-marker>
      <!-- 专职救援队 -->
      <bm-marker
        v-for="data in rescueTeamMarkerList"
        :key="data.id"
        :position="{ lng: data.lng, lat: data.lat }"
        :dragging="false"
        :title="data.teamName"
        :icon="{
          url: data.url,
          size: { width: 20, height: 20 },
        }"
        @click="rescue(data)"
      >
      </bm-marker>
      <!-- 社会救援队 -->
      <bm-marker
        v-for="data in socialRescueTeamList"
        :key="data.id"
        :position="{ lng: data.lng, lat: data.lat }"
        :dragging="false"
        :title="data.teamName"
        :icon="{
          url: data.url,
          size: { width: 20, height: 20 },
        }"
        @click="sociaerl(data)"
      >
      </bm-marker>
      <!-- 兼职救援队 -->
      <bm-marker
        v-for="(data, index) in partTimeTeam"
        :key="'-e' + index"
        :position="{ lng: data.lng, lat: data.lat }"
        :dragging="false"
        :title="data.resettlementSite"
        :icon="{
          url: data.url,
          size: { width: 20, height: 20 },
        }"
        :offset="{ width: 5, height: 15 }"
        @click="handlbrary(data)"
      >
      </bm-marker>

      <!--单兵-->
      <bm-marker
        v-show="isTaDiao"
        v-for="data in taDiaoMarkerList"
        :key="data.id"
        :position="{ lng: data.coord[1], lat: data.coord[0] }"
        :dragging="false"
        :icon="{
          url: data.url,
          size: { width: 32, height: 42 },
        }"
        @click="pawn(data)"
      >
      </bm-marker>
      <!-- 医院 -->
      <bm-marker
        v-for="(data, index) in hospitalList"
        :key="'-t' + index"
        :position="{ lng: data.longitude, lat: data.latitude }"
        :dragging="false"
        :title="data.resettlementSite"
        :icon="{
          url: data.url,
          size: { width: 20, height: 20 },
        }"
        :offset="{ width: 5, height: 15 }"
        @click="openHospital(data)"
      >
        <!-- 医院 -->
        <bm-info-window
          :position="{ lng: data.longitude, lat: data.latitude }"
          :show="data.infoWindow.show"
          :closeOnClick="true"
          :width="320"
          :height="280"
          :offset="{ width: -20, height: 125 }"
        >
          <div
            class="bigdiv"
            style="
              z-index: 999;
              height:150px;
              overflow:scroll;
              overflow-x:hidden;
             
            "
          >
            <div class="info-header flex justify-between items-center">
              <p class="text-white flex items-center">
                {{ data.resourceName }}
              </p>
              <span class="close-btn" @click="hospitalCls(data)">X</span>
            </div>
            <div class="info-wrapper">
              <p class="address" style="margin-bottom: 10px">
                医疗特色:{{ data.synopsis }}
              </p>
              <p class="department" style="margin-bottom: 10px">
                医院地址:{{ data.address }}{{ data.resourceAddress }}
              </p>
              <p class="tell">联系电话:{{ data.telephone }}</p>
            </div>
          </div>
        </bm-info-window>
      </bm-marker>
      <!-- 应急联络 -->
      <bm-marker
        v-for="(data, index) in unitsList"
        :key="'-l' + index"
        :position="{ lng: data.longitude, lat: data.latitude }"
        :dragging="false"
        :title="data.resettlementSite"
        :icon="{
          url: data.url,
          size: { width: 20, height: 20 },
        }"
        :offset="{ width: 5, height: 15 }"
        @click="openUnits(data)"
      >
        <!-- 应急联络 -->
        <!-- {{ data.name }} -->
        <!-- 地址：{{ gettypes == "" ? "暂无" : gettypes }} -->
        <bm-info-window
          :position="{ lng: data.longitude, lat: data.latitude }"
          :show="data.infoWindow.show"
          :closeOnClick="true"
          :width="320"
          :height="140"
          :offset="{ width: 5, height: 30 }"
        >
          <div id="bigdiv" style="z-index: 999">
            <div class="info-header flex justify-between items-center">
              <p class="text-white flex items-center">
                {{ data.resourceName }}
              </p>
              <span class="close-btn" @click="unitsCls(data)">X</span>
            </div>
            <div class="info-wrapper">
              <p class="address" style="margin-bottom: 10px">
                地址:{{ data.address }}
              </p>
              <p class="state">联系电话:{{ data.telephone }}</p>
            </div>
          </div>
        </bm-info-window>
      </bm-marker>
    </baidu-map>
    <!-- 底部操作栏 -->
    <div class="handle-wrapper">
      <div class="handle-content flex justify-center">
        <div class="handle-item" style="width: 9.37vw">
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div
            class="
              item-content
              2xl:text-base
              xl:text-sm
              md:text-xs
              cursor-pointer
            "
            :class="isAnZhi ? '' : 'ops'"
            @click="selectAnZhi()"
          >
            <div class="item-content-anzhi">物资库</div>
          </div>
        </div>
        <div
          class="handle-item 2xl:text-base xl:text-sm md:text-xs"
          style="width: 12.5vw"
        >
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div
            class="
              item-content
              2xl:text-base
              xl:text-sm
              md:text-xs
              cursor-pointer
            "
            :class="isJiuYuan ? '' : 'ops'"
            @click="selectJiuYuan()"
          >
            <div class="item-content-wrapper">专职救援队</div>
          </div>
        </div>
        <div
          class="handle-item 2xl:text-base xl:text-sm md:text-xs"
          style="width: 12.5vw"
        >
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div
            class="
              item-content
              2xl:text-base
              xl:text-sm
              md:text-xs
              cursor-pointer
            "
            :class="sociJiuYuan ? '' : 'ops'"
            @click="socialLeyrt()"
          >
            <div class="item-content-wrapper dertgbb">社会救援队</div>
          </div>
        </div>
        <!-- <div
          class="handle-item 2xl:text-base xl:text-sm md:text-xs"
          style="width: 12.5vw"
        >
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div
            class="
              item-content
              2xl:text-base
              xl:text-sm
              md:text-xs
              cursor-pointer
            "
            :class="isPartJiuYuan ? '' : 'ops'"
            @click="partJiuYuan()"
          >
            <div class="item-content-wrapper partJob">兼职救援队</div>
          </div>
        </div> -->
        <!-- <div
          class="handle-item 2xl:text-base xl:text-sm md:text-xs"
          style="width: 9.37vw"
        >
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div
            class="
              item-content
              2xl:text-base
              xl:text-sm
              md:text-xs
              cursor-pointer
            "
            :class="isSingle ? '' : 'ops'"
            @click="single()"
          >
            <div class="item-content-wrapper singlePawn">单兵</div>
          </div>
        </div> -->
        <div
          class="handle-item 2xl:text-base xl:text-sm md:text-xs"
          style="width: 9.37vw"
        >
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div
            class="
              item-content
              2xl:text-base
              xl:text-sm
              md:text-xs
              cursor-pointer
            "
            :class="isHospital ? '' : 'ops'"
            @click="selectHospital()"
          >
            <div class="item-content-wrapper hospital">医院</div>
          </div>
        </div>
        <div
          class="handle-item 2xl:text-base xl:text-sm md:text-xs"
          style="width: 11.5vw"
        >
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div
            class="
              item-content
              2xl:text-base
              xl:text-sm
              md:text-xs
              cursor-pointer
            "
            :class="isMunicipal ? '' : 'ops'"
            @click="selectMunicipal()"
          >
            <div class="item-content-wrapper municipal">应急联络</div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      class="modalDetail"
      ref="rescueDetail"
      :modal="false"
      :width="'1200px'"
      :title="rescueTitle"
      @closeDialog="closeDialog"
    >
      <Resor
        ref="resores"
        @closeter="closeter"
        :libraryData="libraryData"
        @starts="starts"
        @end="ends"
        :evebty="evebty"
      ></Resor>
    </Modal>
    <Modal
      class="modalDetail"
      ref="library"
      :modal="false"
      :width="'1200px'"
      title="物资库"
      @libraryClose="libraryClose"
    >
      <div
        class="table-wrapper p-4 h-full"
        v-loading="pageLoading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        style="height: 450px; overflow-y: auto"
      >
        <libraryPage ref="librarys" :libraryData="libraryData"></libraryPage>
      </div>
    </Modal>
    <Modal
      class="modalDetail"
      ref="rescueDialog"
      :modal="false"
      :width="'1200px'"
      :title="tielrt"
      @closeDialog="rescueClose"
    >
      <div
        class="table-wrapper p-4 h-full"
        v-loading="pageLoading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        <rescuePage
          ref="rescuePageData"
          :fullTimeTeam="fullTimeTeam"
        ></rescuePage>
      </div>
    </Modal>
    <Modal
      class="modalDetail"
      ref="partRescueDialog"
      :modal="false"
      :width="'1200px'"
      title="兼职救援队"
    >
      <div
        class="table-wrapper p-4 h-full"
        v-loading="pageLoading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        <partRescuePage
          ref="partRescuePageData"
          :partRescue="partRescue"
        ></partRescuePage>
      </div>
    </Modal>
  </div>
</template>

<script>
//高德坐标转百度（传入经度、纬度）
function bd_encrypt(gg_lng, gg_lat) {
  var X_PI = (Math.PI * 3000.0) / 180.0;
  var x = gg_lng,
    y = gg_lat;
  var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
  var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
  var bd_lng = z * Math.cos(theta) + 0.0065;
  var bd_lat = z * Math.sin(theta) + 0.006;
  return {
    lat: bd_lng,
    lng: bd_lat,
  };
}
import Resor from "./incident/resor.vue";
import dayjs from "dayjs";
import libraryPage from "../../HomePage/Map/modules/libraryPage.vue";
import rescuePage from "../../HomePage/Map/modules/rescuePage.vue";
import partRescuePage from "../../HomePage/Map/modules/partRescuePage.vue";
import Modal from "@/components/Modal/modal.vue";
// import { tripleTree } from "@/api/map";
// import { queryDictNodesById } from "@/api/dict";
import {
  queryTeamLists,
  // queryMentList,
  // queryDutyList,
  getMaterials,
  queryWorkerListByPage,
  rescueteam,
  equipmentlist,
  publicresources,
} from "@/api/eres";

import { queryMaterialsList, getResource } from "@/api/eres";
import { queryDictByCode } from "@/api/dict.js";
import { queryRoleById } from "@/api/role";
import TrainVue from "../../System/TrainingDrill/Train.vue";
export default {
  name: "routeList",
  components: { Resor, Modal, libraryPage, rescuePage, partRescuePage },
  props: {
    detalieltes: {
      type: Object,
    },
    filews: {
      type: Boolean,
    },
  },
  watch: {
    detalieltes: {
      handler(val) {
        if (val.coord) {
          this.markerList = [];
          val.url = require("@/assets/map/warning.png");
          val.size = { width: 70, height: 96 };
          this.page.center.lng = val.coord[0];
          this.page.center.lat = val.coord[1];
          this.markerList.push(val);
        } else {
          this.markerList = [];
          val.url = require("@/assets/map/warning.png");
          val.size = { width: 70, height: 96 };
          let p = bd_encrypt(val.xcoor, val.ycoor);
          val.coord = [p.lat, p.lng];
          this.page.center.lng = p.lat;
          this.page.center.lat = p.lng;

          this.markerList.push(val);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      evebty: {},
      // 值班人员
      keeperDialog: false,
      rescueTitle: "资源调配",
      formInline: {
        resource: "",
        searchName: "",
        input: "",
      },
      searchNamePlace: "请输入物资或设备名称搜索",
      optionsData: [
        {
          value: "1",
          label: "物资库",
        },
        {
          value: "2",
          label: "专职救援队",
        },
        // {
        //   value: "3",
        //   label: "兼职救援队",
        // },
        // {
        //   value: "4",
        //   label: "单兵",
        // },
        {
          value: "5",
          label: "医院",
        },
        {
          value: "6",
          label: "应急联络",
        },
        {
          value: "7",
          label: "社会救援队",
        },
      ],
      tielrt: "",
      // lineSite: [],
      // options: [],
      // props: {
      //   value: "id",
      //   label: "name",
      //   children: "nodes",
      // },
      siteName: "", // 站点名称
      lineShow: false,
      pointShow: false,
      currentDate: dayjs(),

      tableData: [],
      columns: [],
      pages: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        total: 0,
      },
      pageLoading: false,
      lineId: "",
      siteId: "",
      typeData: null,
      addDialog: {
        show: false,
        data: {},
        tree: [],
        title: "",
      },
      page: {
        start: "",
        end: "",
        center: { lng: 0, lat: 0 },
        zoom: 12,
      },
      modelTitle: "",
      // 告警点
      markerList: [],
      // 防汛关注重点
      floodPreventionMarkerList: [],
      //安置点
      materLibrary: [],
      secZhiMarkerList: [],
      // 救援队
      rescueTeamMarkerList: [],
      sectaDiaoMarkerList: [],
      // 专职救援队
      rescueTeamList: [],
      // 兼职救援队
      partTimeTeamList: [],
      partTimeTeam: [],
      //社会救援队
      socialRescueTeamList: [],

      // 医院
      hospitals: [],
      hospitalList: [],
      // 应急联络
      units: [],
      unitsList: [],
      //塔吊列表
      taDiaoMarkerList: [],

      //龙门吊列表
      longMenMarkerList: [],
      //轮胎式起重机列表
      qiZhongMarkerList: [],
      // 线路坐标集合
      polylineList: [],
      // 工点坐标集合
      workPoints: [],
      // 电子围栏坐标点集合
      enclosureList: [],
      zoomFlag: false,
      dictList: {},
      sheBeiList: [], //大型设备
      isTaDiao: false,
      isQiChe: false,
      isLongMen: false,
      isDiWa: false,
      isGaoPo: false,
      isHeYong: false,
      isYouXian: false,
      isJiuYuan: false,
      sociJiuYuan: true,
      //兼职救援队
      isPartJiuYuan: false,
      // 单兵
      isSingle: false,
      // 医院shi
      isHospital: true,
      // 应急联络
      isMunicipal: TrainVue,
      isAnZhi: true,
      allAttention: [],
      fangxunData: {}, //地图防汛关注弹窗数据
      gettypes: "",
      getterlidt: [],
      treu: "",
      packages: [],
      ages: "",
      peron: [],
      dutyon: "",

      // 物资库
      libraryData: {},
      library: "",
      weight: 0,
      opacity: 0,
      fillOpacity: 0,
      fillcolor: "",
      radiu: 0,
      styokecolor: "",
      hospital: [],
      unit: [],
      supplies: [],
      fulltime: [],
      parttime: [],
      dicst: [],
      start: [],
      end: [],
      unitRole: "",
      user: {},
      driving: false,
      // 专职救援队弹框
      fullTimeTeam: {},
      // 兼职救援队弹框
      partRescue: {},
      withinList: [],
      socialList: [],
      socitime: [],
    };
  },
  created() {
    this.$EventBus.$on("starts", (val) => {
      this.start = val;
      if (this.start.length != 0) {
        this.driving = true;
      } else {
        this.driving = false;
      }
    });
    this.$EventBus.$on("end", (val) => {
      this.end = val;
      if (this.end.length != 0) {
        this.driving = true;
      } else {
        this.driving = false;
      }
    });
    // this.$EventBus.$on("closeter", () => {
    //   this.$refs.rescueDetail.handleClose();
    // });
    this.user = JSON.parse(localStorage.getItem("user"));

    this.roleName = this.user.roles[0].roleName;
    console.log(this.roleName);
    queryRoleById({ id: this.user.roles[0].id }).then((res) => {
      this.unitRole = res.data.unitType + this.user.roles[0].roleName;
    });
    queryDictByCode({
      code: "commonResourceYype",
    }).then((res) => {
      this.dicst = res.data;
    });
  },
  beforeDestroy() {
    // this.$EventBus.$off("hospital");
    // this.$EventBus.$off("unit");
    // this.$EventBus.$off("supplies");
    // this.$EventBus.$off("fulltime");
    // this.$EventBus.$off("parttime");
    // this.$EventBus.$off("ruides");
    // this.$EventBus.$off("starts");
    // this.$EventBus.$off("end");
    // this.$EventBus.$off("closeter");
  },

  mounted() {
    // this.getTableList();

    // this.getSheBei(); //查询大型设备
    // this.queryLineSiteTree(); //查询线路工点
    this.getAnZhi(); //查询物资库
    this.getJiuYuan(); //查询救援队
    this.getsocial(); //社会救援队
    this.getTeamList(); //查询兼职救援队
    this.getHospital(); //查询医院
    this.getNnits(); //查询市政单位

    // this.getGuanZhu(); //查询关注
  },
  methods: {
    starts(val) {
      this.start = val;
    },
    ends(val) {
      this.end = val;
    },
    // 信息发布
    infoIssue() {
      this.$refs.infoDialog.handleOpen();
    },
    // handleClose() {
    //   this.$refs.infoDialog.handleClose();

    // },
    colseDialog() {
      // this.infoDialog = false;
      this.$refs.infoDialog.handleClose();
    },
    // 值班人员
    duty() {
      // 值班人员
      // this.$refs.keeperDialog.handleOpen();
      // 信息快报
      this.$refs.newsLetters.handleOpen();
    },
    keeperClose() {
      this.$refs.keeperDialog.handleClose();
    },
    keeperColseDialog() {
      this.$refs.keeperDialog.handleClose();
    },
    async getLineTree() {
      if (localStorage.linetree) {
        this.options = JSON.parse(localStorage.linetree);
      } else {
        const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
        if (lineSiteData) {
          this.options = lineSiteData;
          this.options.forEach((e) => {
            if (e.nodes.length != 0) {
              e.nodes.forEach((el) => {
                delete el.nodes;
              });
            }
          });
          localStorage.linetree = JSON.stringify(this.options);
        }
      }
    },
    resourceChange(val) {
      if (val == 1) {
        this.searchNamePlace = "请输入物资或设备名称搜索";
      } else if (val == 2 || val == 3) {
        this.searchNamePlace = "请输入队伍名称或人员名称搜索";
      } else if (val == 5) {
        this.searchNamePlace = "请输入医院名称搜索";
      } else if (val == 6) {
        this.searchNamePlace = "请输入市政单位名称搜索";
      }
    },
    //查询
    querySite() {
      // this.aaaaa = false;
      this.withinList = [];
      this.unitsList = [];

      // 物资库
      this.materLibrary = [];

      // 专职救援队
      this.rescueTeamMarkerList = [];

      // 兼职救援队
      this.partTimeTeam = [];

      // 医院
      this.hospitalList = [];
      // this.isHospital = false;
      // 社会救援队
      this.socialRescueTeamList = [];
      console.log(this.formInline.resource, this.formInline.input);
      // this.sociJiuYuan = true;
      if (this.formInline.input != 0 || this.formInline.input != "") {
        console.log(1111);
        this.weight = 2;
        this.opacity = 0.5;
        this.fillOpacity = 0.5;
        this.styokecolor = "blue";
        this.fillcolor = "rgba(226,60,57,0.3)";
        this.radiu = this.formInline.input * 1000;
      } else {
        console.log(22222);
        this.weight = 0;
        this.styokecolor = "";
        this.opacity = 0;
        this.fillOpacity = 0;
        this.fillcolor = "";
      }

      if (this.formInline.resource == "1") {
        this.markerList.forEach((el) => {
          equipmentlist({
            km: this.formInline.input,
            lat: el.coord[1],
            lon: el.coord[0],
            materialsName: this.formInline.searchName,
          }).then((res) => {
            this.wzkList = res.data;
            this.wzkList.forEach((e) => {
              e.url = require("@/assets/map/material.png");
              let p = bd_encrypt(e.lng, e.lat);
              e.coord = [p.lat, p.lng];
              e.infoWindow = {
                show: false,
              };
            });
            res.data.forEach((item) => {
              item.teamName = item.title;
              item.captainName = item.leaderName;
              item.captainPhone = item.mobile;
              item.library = "物资库";
              this.withinList.push(item);
            });

            // 物资库
            this.materLibrary = this.wzkList;
            this.isAnZhi = true;
          });
        });
        // 兼职救援队
        this.partTimeTeam = [];
        this.isPartJiuYuan = false;
        // 医院
        this.hospitalList = [];
        this.isHospital = false;
        // 应急联络
        this.unitsList = [];
        this.isMunicipal = false;
        //专职救援队
        this.rescueTeamMarkerList = [];
        this.isJiuYuan = false;
        // 社会救援队
        this.socialRescueTeamList = [];
        this.sociJiuYuan = true;
      } else if (this.formInline.resource == "2") {
        this.markerList.forEach((el) => {
          rescueteam({
            km: this.formInline.input,
            lat: el.coord[1],
            lon: el.coord[0],
            teamType: 1,
            materialsName: this.formInline.searchName,
          }).then((res) => {
            this.jydList = res.data;
            this.jydList.forEach((e) => {
              e.url = require("@/assets/map/jiuyuan.png");
              let p = bd_encrypt(e.lng, e.lat);
              e.coord = [p.lat, p.lng];
              e.infoWindow = {
                show: false,
              };
              // e.arrange = {
              //   show: false,
              // };
            });
            res.data.forEach((item) => {
              item.teamName = item.title;
              item.captainName = item.leaderName;
              item.captainPhone = item.mobile;
              item.library = "专职救援队";
              this.withinList.push(item);
            });

            this.rescueTeamMarkerList = this.jydList;
            this.isJiuYuan = true;
          });
        });

        // 兼职救援队
        this.partTimeTeam = [];
        this.isPartJiuYuan = false;
        // 物资库
        this.materLibrary = [];
        this.isAnZhi = false;
        // 医院
        this.hospitalList = [];
        this.isHospital = false;
        // 应急联络
        this.unitsList = [];
        this.isMunicipal = false;
        // 社会救援队
        this.socialRescueTeamList = [];
        this.sociJiuYuan = true;
      } else if (this.formInline.resource == "3") {
        this.markerList.forEach((el) => {
          rescueteam({
            km: this.formInline.input,
            lat: el.coord[1],
            lon: el.coord[0],
            teamType: 2,
            materialsName: this.formInline.searchName,
          }).then((res) => {
            let list = res.data;
            list.forEach((e) => {
              e.url = require("@/assets/map/jobTeam.png");
              let p = bd_encrypt(e.lng, e.lat);
              e.coord = [p.lat, p.lng];
              e.infoWindow = {
                show: false,
              };
            });
            // 兼职救援队
            this.partTimeTeam = list;
            this.isPartJiuYuan = true;
          });
        });

        // 物资库
        this.materLibrary = [];
        this.isAnZhi = false;
        // 专职救援队
        this.rescueTeamMarkerList = [];
        this.isJiuYuan = false;
        // 医院
        this.hospitalList = [];
        this.isHospital = false;
        // 应急联络
        this.unitsList = [];
        this.isMunicipal = false;
        // 社会救援队
        this.socialRescueTeamList = [];
        this.sociJiuYuan = true;
      } else if (this.formInline.resource == "5") {
        this.markerList.forEach((el) => {
          //医院
          publicresources({
            km: this.formInline.input,
            lat: el.coord[1],
            lon: el.coord[0],

            resourceName: this.formInline.searchName,

            resourceType: this.dicst[1].label,
          }).then((res) => {
            let list = res.data;
            list.forEach((e) => {
              e.url = require("@/assets/map/hospital.png");
              let p = bd_encrypt(e.lng, e.lat);
              e.coord = [p.lat, p.lng];
              e.infoWindow = {
                show: false,
              };
            });
            res.data.forEach((item) => {
              item.teamName = item.resourceName;

              item.captainPhone = item.telephone;
              item.library = "医院";
              this.withinList.push(item);
            });

            // 医院
            this.hospitalList = list;
            this.isHospital = true;
          });
        });

        // 物资库
        this.materLibrary = [];
        this.isAnZhi = false;
        // 专职救援队
        this.rescueTeamMarkerList = [];
        this.isJiuYuan = false;
        // 兼职救援队
        this.partTimeTeam = [];
        this.isPartJiuYuan = false;
        // 应急联络
        this.unitsList = [];
        this.isMunicipal = false;
        // 社会救援队
        this.socialRescueTeamList = [];
        this.sociJiuYuan = true;
      } else if (this.formInline.resource == "6") {
        this.markerList.forEach((el) => {
          publicresources({
            km: this.formInline.input,
            lat: el.coord[1],
            lon: el.coord[0],

            resourceName: this.formInline.searchName,

            resourceType: this.dicst[0].label,
          }).then((res) => {
            let list = res.data;
            list.forEach((e) => {
              e.url = require("@/assets/map/municipal.png");
              e.coord = [e.lng, e.lat];
              e.infoWindow = {
                show: false,
              };
            });
            res.data.forEach((item) => {
              item.teamName = item.title;
              item.captainName = item.leaderName;
              item.captainPhone = item.mobile;
              item.library = "应急联络";
              this.withinList.push(item);
            });
            // 应急联络
            this.unitsList = list;
            this.isMunicipal = true;
          });
        });

        // 物资库
        this.materLibrary = [];
        this.isAnZhi = false;
        // 专职救援队
        this.rescueTeamMarkerList = [];
        this.isJiuYuan = false;
        // 兼职救援队
        this.partTimeTeam = [];
        this.isPartJiuYuan = false;
        // 医院
        this.hospitalList = [];
        this.isHospital = false;
        // 社会救援队
        this.socialRescueTeamList = [];
        this.sociJiuYuan = true;
      } else if (this.formInline.resource == "7") {
        this.markerList.forEach((el) => {
          rescueteam({
            km: this.formInline.input,
            lat: el.coord[1],
            lon: el.coord[0],
            teamType: 3,
            materialsName: this.formInline.searchName,
          }).then((res) => {
            let lists = res.data;
            lists.forEach((e) => {
              e.url = require("@/assets/map/jiuyuan.png");
              let p = bd_encrypt(e.lng, e.lat);
              e.coord = [p.lat, p.lng];
              e.infoWindow = {
                show: false,
              };
              // e.arrange = {
              //   show: false,
              // };
            });
            res.data.forEach((item) => {
              item.teamName = item.title;
              item.captainName = item.leaderName;
              item.captainPhone = item.mobile;
              item.library = "社会救援队";
              this.withinList.push(item);
            });

            this.socialRescueTeamList = lists;
            this.sociJiuYuan = true;
          });
        });

        // 专职救援队
        this.rescueTeamMarkerList = [];
        this.isJiuYuan = false;

        // 兼职救援队
        this.partTimeTeam = [];
        this.isPartJiuYuan = false;
        // 物资库
        this.materLibrary = [];
        this.isAnZhi = false;
        // 医院
        this.hospitalList = [];
        this.isHospital = false;
        // 应急联络
        this.unitsList = [];
        this.isMunicipal = false;
      } else if (
        this.formInline.resource == "" ||
        this.formInline.input == ""
      ) {
        this.markerList.forEach((el) => {
          equipmentlist({
            km: this.formInline.input,
            lat: el.coord[1],
            lon: el.coord[0],
            materialsName: this.formInline.searchName,
          }).then((res) => {
            console.log(res.data);
            if (res.data.length == 0) {
              // 物资库
              this.materLibrary = [];
              this.isAnZhi = false;
            } else {
              this.wzkList = res.data;
              this.wzkList.forEach((e) => {
                e.url = require("@/assets/map/material.png");
                let p = bd_encrypt(e.lng, e.lat);
                e.coord = [p.lat, p.lng];
                e.infoWindow = {
                  show: false,
                };
              });
              res.data.forEach((item) => {
                console.log(item);
                item.teamName = item.title;
                item.captainName = item.leaderName;
                item.captainPhone = item.mobile;
                item.library = "物资库";
                this.withinList.push(item);
              });

              // 物资库
              this.materLibrary = this.wzkList;
              this.supplies = this.wzkList;
              this.isAnZhi = true;
            }
          });
          rescueteam({
            km: this.formInline.input,
            lat: el.coord[1],
            lon: el.coord[0],
            teamType: 1,
            materialsName: this.formInline.searchName,
          }).then((res) => {
            if (res.data.length == 0) {
              // 专职救援队
              this.rescueTeamMarkerList = [];
              this.isJiuYuan = false;
            } else {
              this.jydList = res.data;
              this.jydList.forEach((e) => {
                e.url = require("@/assets/map/jiuyuan.png");
                let p = bd_encrypt(e.lng, e.lat);
                e.coord = [p.lat, p.lng];
                e.infoWindow = {
                  show: false,
                };
                // e.arrange = {
                //   show: false,
                // };
              });
              res.data.forEach((item) => {
                console.log(item);

                item.library = "专职救援队";
                this.withinList.push(item);
              });

              this.rescueTeamMarkerList = this.jydList;
              this.fulltime = this.jydList;
              this.isJiuYuan = true;
            }
          });
          rescueteam({
            km: this.formInline.input,
            lat: el.coord[1],
            lon: el.coord[0],
            teamType: 3,
            materialsName: this.formInline.searchName,
          }).then((res) => {
            if (res.data.length == 0) {
              // 社会救援队
              this.socialRescueTeamList = [];
              this.sociJiuYuan = false;
            } else {
              let list = res.data;
              list.forEach((e) => {
                e.url = require("@/assets/map/shehui.png");
                let p = bd_encrypt(e.lng, e.lat);
                e.coord = [p.lat, p.lng];
                e.infoWindow = {
                  show: false,
                };
                // e.arrange = {
                //   show: false,
                // };
              });
              res.data.forEach((item) => {
                console.log(item);

                item.library = "社会救援队";
                this.withinList.push(item);
              });

              this.socialRescueTeamList = list;
              this.socitime = list;
              this.sociJiuYuan = true;
            }
          });
          //医院
          publicresources({
            km: this.formInline.input,
            lat: el.coord[1],
            lon: el.coord[0],

            resourceName: this.formInline.searchName,

            resourceType: this.dicst[1].label,
          }).then((res) => {
            if (res.data.length == 0) {
              // 医院
              this.hospitalList = [];
              this.isHospital = false;
            } else {
              let list = res.data;
              list.forEach((e) => {
                e.url = require("@/assets/map/hospital.png");
                let p = bd_encrypt(e.lng, e.lat);
                e.coord = [p.lat, p.lng];
                e.infoWindow = {
                  show: false,
                };
              });
              res.data.forEach((item) => {
                item.teamName = item.resourceName;

                item.captainPhone = item.telephone;
                item.library = "医院";
                this.withinList.push(item);
              });

              // 医院
              this.hospitalList = list;
              this.hospital = list;
              this.isHospital = true;
            }
          });
          //应急联络
          publicresources({
            km: this.formInline.input,
            lat: el.coord[1],
            lon: el.coord[0],

            resourceName: this.formInline.searchName,

            resourceType: this.dicst[0].label,
          }).then((res) => {
            if (res.data.length == 0) {
              this.unitsList = [];
              this.isMunicipal = false;
            } else {
              let list = res.data;
              list.forEach((e) => {
                e.url = require("@/assets/map/municipal.png");
                e.coord = [e.lng, e.lat];
                e.infoWindow = {
                  show: false,
                };
              });
              res.data.forEach((item) => {
                item.teamName = item.resourceName;

                item.captainPhone = item.telephone;
                item.library = "应急联络";
                this.withinList.push(item);
              });
              // 应急联络
              this.unitsList = list;
              this.unit = list;
              this.isMunicipal = true;
            }
          });
        });
      } else {
        // 应急联络
        this.unitsList = [];
        this.isMunicipal = false;
        // 物资库
        this.materLibrary = [];
        this.isAnZhi = false;

        // 专职救援队
        this.rescueTeamMarkerList = [];
        this.isJiuYuan = false;

        // 兼职救援队
        this.partTimeTeam = [];
        this.isPartJiuYuan = false;
        // 医院
        this.hospitalList = [];
        this.isHospital = false;
        // 社会救援队
        this.socialRescueTeamList = [];
        this.sociJiuYuan = true;
      }

      // if (this.lineSite.length != 0) {
      //   this.workPoints.forEach((e) => {
      //     if (this.lineSite[1] == e.id) {
      //       this.page = {
      //         start: "",
      //         end: "",
      //         center: { lng: e.coord[0], lat: e.coord[1] },
      //         zoom: 14,
      //       };
      //       e.markerShow = "BMAP_ANIMATION_BOUNCE";
      //       setTimeout(() => {
      //         e.markerShow = "";
      //       }, 3000);
      //     }
      //   });
      // } else {
      //   this.page = {
      //     start: "",
      //     end: "",
      //     center: { lng: 113.2839488029, lat: 23.0907270045 },
      //     zoom: 14,
      //   };
      // }
      // this.$router.push("/Outburst");
      // this.$EventBus.$emit("withinList", this.withinList);

      this.$emit("wisherty", this.withinList);
    },
    claert() {
      this.formInline.input = 0;
      // this.formInline.searchName=""
      //  this.weight = 0;
      this.opacity = 0;
      //   this.fillOpacity = 0;
      //   this.fillcolor = "";
      this.querySite();
    },

    //获取父组件传递的ws数据
    getwsData(res) {
      let that = this;
      if (
        res.data != "PING" &&
        res.data != "suc|欢迎登录广州地铁安全风险监管工具消息中心"
      ) {
        let usData = JSON.parse(res.data);
        let datas = usData.body;
        if (datas && datas.coord) {
          let p = bd_encrypt(datas.coord[1], datas.coord[0]);
          datas.coord = [p.lat, p.lng];
        }
        if (usData.msgType && usData.msgType === "TRUCK_COORD") {
          //实时位置推送
          if (that.markerList.length > 0) {
            let u = that.markerList.find((e) => e.truckNum === datas.truckNum);
            if (u) {
              u.msgType = usData.msgType;
              u.url = require("@/assets/map/dingw-blue.png");
              u.coord = datas.coord;
            } else {
              datas.msgType = usData.msgType;
              datas.url = require("@/assets/map/dingw-blue.png");
              that.markerList.push(datas);
            }
          } else {
            datas.msgType = usData.msgType;
            datas.url = require("@/assets/map/dingw-blue.png");
            that.markerList.push(datas);
          }
        } else if (
          //超时报警推送和 位置偏移推送
          usData.msgType === "TIMEOUT_ALERT" ||
          usData.msgType === "MOVE_ALERT" ||
          usData.msgType === "ADV_FINISH_TASK"
        ) {
          let u = that.markerList.find((e) => e.truckNum === datas.truckNum);
          if (u) {
            u.msgType = usData.msgType;
            u.url = require("@/assets/map/dingw-red.png");
          } else {
            if (
              usData.msgType === "MOVE_ALERT" ||
              usData.msgType === "ADV_FINISH_TASK"
            ) {
              datas.msgType = usData.msgType;
              datas.url = require("@/assets/map/dingw-red.png");
              that.markerList.push(datas);
            }
          }
        } else if (usData.msgType === "FINISHED_TASK") {
          //任务完成推送
          let u = that.markerList.find((e) => e.truckNum === datas.truckNum);
          if (u) {
            that.markerList = that.markerList.filter(
              (item) => item.truckNum != u.truckNum
            );
          }
        }
      }
    },
    //地图加载后事件
    handler({ BMap, map }) {
      console.log(BMap);
      map.setMapStyleV2({
        styleId: "565ab0773031556035c58e365ee8547a",
      });
      //       var p1 = new BMap.Point(116.301934,39.977552);
      // var p2 = new BMap.Point(116.508328,39.919141);

      // var driving = new BMap.DrivingRoute(map, {renderOptions:{map: map, autoViewport: true}});
      // driving.search(p1, p2);

      // var p3 = new BMap.Point(116.312964,39.970592);
      // var p4 = new BMap.Point(116.364432,39.924208);

      // var driving2 = new BMap.DrivingRoute(map, {renderOptions:{map: map, autoViewport: true}});
      // driving2.search(p3, p4);
    },
    //告警图标点击事件
    infoWindowOpen(data) {
      const index = this.markerList.findIndex((item) => item.id == data.id);
      this.markerList[index].infoWindow.show = true;
    },
    // 告警信息鼠标移入
    infoWindowHover(data) {
      const index = this.markerList.findIndex((item) => item.id == data.id);
      this.markerList[index].infoWindow1.show = true;
    },
    // 告警信息鼠标移出
    infoWindowCls(data) {
      const index = this.markerList.findIndex((item) => item.id == data.id);
      this.markerList[index].infoWindow1.show = false;
    },
    // 告警信息窗口关闭
    onInfoWindowClose(data) {
      const index = this.markerList.findIndex((item) => item.id == data.id);
      this.markerList[index].infoWindow.show = false;
    },

    //地图绘点
    mapTree(data) {
      // let con = list.filter((e) => e.name == "广州市轨道交通十号线");
      let colors = "#01963F";
      let url = require("@/assets/map/gongdian.png");
      data.forEach((e) => {
        switch (e.name) {
          case "广州市轨道交通十八号线":
            colors = "#01963F";
            url = require("@/assets/map/gongdian.png");
            break;
          case "广州市轨道交通13号线二期线路":
            colors = "#026CB6";
            url = require("@/assets/map/gongdian.png");
            break;
          case "广州市城市轨道交通十四号线二期工程":
            colors = "#956339";
            url = require("@/assets/map/gongdian.png");
            break;
          case "广州东至花都天贵城际项目":
            colors = "#901D42";
            url = require("@/assets/map/gongdian.png");
            break;
          case "广州市轨道交通二十二号线":
            colors = "#E6037D";
            url = require("@/assets/map/gongdian.png");
            break;
          case "广州市轨道交通十二号线及同步实施工程总承包项目":
            colors = "#950885";
            url = require("@/assets/map/gongdian.png");
            break;
          case "广州市轨道交通五号线东延段及同步实施工程":
            colors = "#F977A3";
            url = require("@/assets/map/gongdian.png");
            break;
          case "广州市轨道交通十号线":
            colors = "#99D317";
            url = require("@/assets/map/gongdian.png");
            break;
          case "广州市轨道交通7号线二期线路":
            colors = "#B159D4";
            url = require("@/assets/map/gongdian.png");
            break;
          case "广州市轨道交通11号线路":
            colors = "#55CAE2";
            url = require("@/assets/map/gongdian.png");
            break;
          case "广州市轨道交通三号线东延段":
            colors = "#5E5EF7";
            url = require("@/assets/map/gongdian.png");
            break;
          case "芳白城际":
            colors = "#95D30C";
            url = require("@/assets/map/gongdian.png");
            break;
          default:
            break;
        }

        for (let item of e.nodes) {
          // 将返回的围栏坐标集合并到
          if (item.coordinate.lat != 0) {
            let p = bd_encrypt(item.coordinate.lng, item.coordinate.lat);

            // 添加工点
            item.coord = [p.lat, p.lng];

            item.url = url;
            item.infoWindow = {
              show: false,
            };
            item.markerShow = "";
            this.workPoints.push(item);
          }
          if (item.fance.length > 0) {
            let line = {
              id: e.id,
              name: e.name,
              polylinePath: [],
              color: colors,
            };
            item.fance.forEach((el) => {
              let q = bd_encrypt(el.lng, el.lat);
              let d = { lng: q.lat, lat: q.lng };
              line.polylinePath.push(d);
            });
            this.polylineList.push(line);
          }
        }
      });
    },
    //相互之间互相影响
    relieves() {
      this.markerList.forEach((element) => {
        element.infoWindow.show = false;
      });
      // this.earlyWarnList.forEach((element) => {
      //   element.infoWindow.show = false;
      // });
      this.unitsList.forEach((element) => {
        element.infoWindow.show = false;
      });
      this.hospitalList.forEach((element) => {
        element.infoWindow.show = false;
      });
    },
    // 查询物资库
    getAnZhi() {
      let params = {
        materialsName: "",
      };

      getMaterials(params).then((res) => {
        if (res.code == 0) {
          this.wzkList = res.data;
          this.wzkList.forEach((e) => {
            e.url = require("@/assets/map/material.png");
            let p = bd_encrypt(e.lng, e.lat);
            e.coord = [p.lat, p.lng];
            e.infoWindow = {
              show: false,
            };
          });
          // 物资库
          this.secZhiMarkerList = this.wzkList;
          this.selectAnZhi();
        }
      });
    },
    // 查询专职救援队
    getJiuYuan() {
      let params = {
        teamType: 1,
        teamName: this.formInline.searchName ? this.formInline.searchName : "-",
        personName: this.formInline.searchName
          ? this.formInline.searchName
          : "-",
        materialsName: "-",
      };
      queryTeamLists(params).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          list.forEach((e) => {
            e.url = require("@/assets/map/jiuyuan.png");
            let p = bd_encrypt(e.lng, e.lat);
            e.coord = [p.lat, p.lng];
            e.infoWindow = {
              show: false,
            };
            // e.arrange = {
            //   show: false,
            // };
          });
          this.rescueTeamList = list;
          this.selectJiuYuan();
          console.log(this.isJiuYuan);
        }
      });
    },

    // 查询社会救援队
    getsocial() {
      let params = {
        teamType: 3,
        teamName: this.formInline.searchName ? this.formInline.searchName : "-",
        personName: this.formInline.searchName
          ? this.formInline.searchName
          : "-",
        materialsName: "-",
      };
      queryTeamLists(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let list = res.data;
          list.forEach((e) => {
            e.url = require("@/assets/map/shehui.png");
            let p = bd_encrypt(e.lng, e.lat);
            e.coord = [p.lat, p.lng];
            e.infoWindow = {
              show: false,
            };
            // e.arrange = {
            //   show: false,
            // };
          });
          this.socialList = list;
          this.socialLeyrt();
          console.log(this.sociJiuYuan);
        }
      });
    },
    // 兼职救援队
    getTeamList() {
      let param = {
        teamType: 2,
        teamName: this.formInline.searchName ? this.formInline.searchName : "-",
        personName: this.formInline.searchName
          ? this.formInline.searchName
          : "-",
        materialsName: "-",
      };
      queryTeamLists(param).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          list.forEach((e) => {
            e.url = require("@/assets/map/jobTeam.png");
            let p = bd_encrypt(e.lng, e.lat);
            e.coord = [p.lat, p.lng];
            e.infoWindow = {
              show: false,
            };
          });
          this.partTimeTeamList = list;
        }
      });
    },
    //查询医院
    getHospital() {
      let param = {
        resourceType: "医院",
        resourceName: this.formInline.searchName,
      };

      getResource(param).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          list.forEach((e) => {
            e.url = require("@/assets/map/hospital.png");
            let p = bd_encrypt(e.lng, e.lat);
            e.coord = [p.lat, p.lng];
            e.infoWindow = {
              show: false,
            };
          });
          this.hospitals = list;
          this.selectHospital();
        }
      });
    },
    // 获取市政单位
    getNnits() {
      let param = {
        resourceType: "应急联络",
        resourceName: this.formInline.searchName,
      };
      getResource(param).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          list.forEach((e) => {
            e.url = require("@/assets/map/municipal.png");
            e.coord = [e.lng, e.lat];
            e.infoWindow = {
              show: false,
            };
          });
          this.units = list;
          this.selectMunicipal();
        }
      });
    },

    // 单兵
    // pawn(data) {
    //   console.log("单兵", data);
    // },
    voice() {
      this.$message.warning("语音");
    },
    video() {
      this.$message.warning("视频");
    },
    // 物资库
    handleLibrary(data) {
      if (
        this.unitRole == "construct负责人" ||
        this.unitRole == "constructionUnit值班领导" ||
        this.unitRole == "metroGroupUnit集团领导"
      ) {
        this.$refs.rescueDetail.handleOpen();
        this.libraryData = Object.assign({}, data);

        Object.assign(this.libraryData, { library: "物资库" });
        Object.assign(this.libraryData, {
          lablename: this.formInline.searchName,
        });
        this.evebty = Object.assign({}, this.markerList[0]);
        // const newObj = JSON.parse(JSON.stringify(oldObj));
      } else {
        this.$refs.library.handleOpen();

        this.libraryData = Object.assign({}, data);
        Object.assign(this.libraryData, { id: data.siteId });
        Object.assign(this.libraryData, {
          lablename: this.formInline.searchName,
        });

        console.log(this.markerList);
        this.$refs.librarys.paertyu();
        // this.libraryData = data;
        // this.libraryData.id = data.siteId;
      }

      // this.library="物资库"
    },
    libraryClose() {
      this.$refs.librarys.paertyu();
      this.$refs.library.handleClose();
    },
    // 专职救援队
    rescue(data) {
      console.log(data, "8888888");
      if (
        this.unitRole == "construct负责人" ||
        this.unitRole == "constructionUnit值班领导" ||
        this.unitRole == "metroGroupUnit集团领导"
      ) {
        console.log(22222222222);
        this.$refs.rescueDetail.handleOpen();
        this.libraryData = Object.assign({}, data);
        Object.assign(this.libraryData, { library: "专职救援队" });
        Object.assign(this.libraryData, {
          lablename: this.formInline.searchName,
        });
        this.evebty = Object.assign({}, this.markerList[0]);
      } else {
        console.log(111111111111);
        this.$refs.rescueDialog.handleOpen();
        this.tielrt = "专职救援队";
        this.fullTimeTeam = data;
      }
    },
    //社会救援队
    sociaerl(data) {
      this.$refs.rescueDialog.handleOpen();
      this.tielrt = "社会救援队";
      this.fullTimeTeam = data;
    },
    closeDialog() {
      Object.assign(this.libraryData.library, "");
      this.$refs.resores.reset();
    },

    rescueCls(data) {
      // 单兵
      const index = this.rescueTeamMarkerList.findIndex(
        (item) => item.id == data.id
      );
      this.rescueTeamMarkerList[index].infoWindow.show = false;
    },
    //兼职救援队
    handlbrary(data) {
      if (
        this.unitRole == "construct负责人" ||
        this.unitRole == "constructionUnit值班领导" ||
        this.unitRole == "metroGroupUnit集团领导"
      ) {
        this.$refs.rescueDetail.handleOpen();
        this.libraryData = Object.assign({}, data);
        Object.assign(this.libraryData, { library: "兼职救援队" });
      } else {
        this.$refs.partRescueDialog.handleOpen();
        this.partRescue = data;
      }
    },

    //医院点击事件
    openHospital(data) {
      this.relieves();
      const index = this.hospitalList.findIndex(
        (item) => item.resourceId == data.resourceId
      );

      this.hospitalList[index].infoWindow.show = true;
    },
    hospitalCls(data) {
      const index = this.hospitalList.findIndex(
        (item) => item.resourceId == data.resourceId
      );
      this.hospitalList[index].infoWindow.show = false;
    },
    //市政单位点击事件
    openUnits(data) {
      this.relieves();
      const index = this.unitsList.findIndex(
        (item) => item.resourceId == data.resourceId
      );
      this.unitsList[index].infoWindow.show = true;
    },
    unitsCls(data) {
      const index = this.unitsList.findIndex(
        (item) => item.resourceId == data.resourceId
      );
      this.unitsList[index].infoWindow.show = false;
    },

    //物资库
    selectAnZhi() {
      if (this.isAnZhi) {
        this.materLibrary = [];
      } else {
        if (this.formInline.input != 0) {
          this.materLibrary = this.supplies;
        } else {
          this.materLibrary = this.secZhiMarkerList;
        }
      }

      this.isAnZhi = !this.isAnZhi;
    },
    //专职救援队
    selectJiuYuan() {
      if (this.isJiuYuan) {
        this.rescueTeamMarkerList = [];
      } else {
        if (this.formInline.input != 0) {
          this.rescueTeamMarkerList = this.fulltime;
        } else {
          this.rescueTeamMarkerList = this.rescueTeamList;
        }
      }
      this.isJiuYuan = !this.isJiuYuan;
    },
    //社会救援队

    socialLeyrt() {
      if (this.sociJiuYuan) {
        this.socialRescueTeamList = [];
      } else {
        if (this.formInline.input != 0) {
          this.socialRescueTeamList = this.socitime;
        } else {
          this.socialRescueTeamList = this.socialList;
        }
      }
      this.sociJiuYuan = !this.sociJiuYuan;
    },
    // 兼职救援队
    partJiuYuan() {
      if (this.isPartJiuYuan) {
        this.partTimeTeam = [];
      } else {
        if (this.formInline.input != 0) {
          this.partTimeTeam = this.parttime;
        } else {
          this.partTimeTeam = this.partTimeTeamList;
        }
      }
      this.isPartJiuYuan = !this.isPartJiuYuan;
    },

    // 医院
    selectHospital() {
      if (this.isHospital) {
        this.hospitalList = [];
      } else {
        if (this.formInline.input != 0) {
          this.hospitalList = this.hospital;
        } else {
          this.hospitalList = this.hospitals;
        }

        // this.hospitalList.forEach((item) => {
        //   item.arrange.show = false;
        // });
      }
      this.isHospital = !this.isHospital;
    },
    // 应急联络
    selectMunicipal() {
      if (this.isMunicipal) {
        this.unitsList = [];
      } else {
        if (this.formInline.input != 0) {
          this.unitsList = this.unit;
        } else {
          this.unitsList = this.units;
        }

        // this.rescueTeamMarkerList.forEach((item) => {
        //   item.arrange.show = false;
        // });
      }
      this.isMunicipal = !this.isMunicipal;
    },

    //关注弹窗内按钮
    goData() {
      this.$message("数据对接中，敬请期待。");
    },
    // 分页相关
    handleSizeChange(val) {
      this.pages.pageSize = val;
      if (this.modelTitle == "物资") {
        this.typeData = 125;
        this.getTableData();
      } else if (this.modelTitle == "设备") {
        this.typeData = 126;
        this.getTableData();
      } else if (this.modelTitle == "值班人员") {
        this.typeData = 96;
        this.getData();
      }
    },

    handleCurrentChange(val) {
      this.pages.pageNo = val;
      if (this.modelTitle == "物资") {
        this.typeData = 125;
        this.getTableData();
      } else if (this.modelTitle == "设备") {
        this.typeData = 126;
        this.getTableData();
      } else if (this.modelTitle == "值班人员") {
        this.typeData = 96;
        this.getData();
      }
    },
    // 获取列表
    getTableData() {
      queryMaterialsList({
        lineId: this.lineId,
        siteId: this.siteId,
        type: this.typeData,
        label: "-",
        modelNo: "-",
        pageNo: this.pages.pageNo,
        pageSize: this.pages.pageSize,
      }).then((res) => {
        if (res.code == 0) {
          this.pageLoading = false;
          this.tableData = res.data.records;
          this.tableData &&
            this.tableData.forEach((item) => {
              if (item.state == 0) {
                item.state = "待修";
              } else {
                item.state = "完好";
              }
            });
          this.pages.count = res.data.count;
          this.pages.total = res.data.total;
        }
      });
    },
    // closeDialog() {
    //   this.pages.pageNo = 1;
    //   this.pages.pageSize = 10;
    // },
    // 信息发布
    newsIssue(data) {
      console.log(data);
      this.$refs.infoDialog.handleOpen();
    },

    // 视频监控
    monitor(data) {
      console.log("视频监控", data);
    },
    // 物资
    materials(data) {
      this.tableData = [];
      this.pageLoading = true;
      this.modelTitle = "物资";
      this.columns = [
        { prop: "label", label: "名称" },
        { prop: "modelNo", label: "规格型号" },
        { prop: "siteName", label: "所属工点" },
        { prop: "address", label: "详细位置" },
        { prop: "responsiblePersonName", label: "负责人" },
        { prop: "factory", label: "生产厂家" },
        { prop: "unit", label: "单位" },
        { prop: "count", label: "数量" },
        { prop: "inTime", label: "进场时间" },
        { prop: "expire", label: "有效期" },
        { prop: "state", label: "性能状况" },
        { prop: "remark", label: "备注" },
      ];
      this.$refs.modalDetail.handleOpen();
      this.lineId = data.parent;
      this.siteId = data.id;
      this.typeData = 125;
      this.getTableData();
    },
    // 专职救援队关闭
    rescueClose() {
      this.$refs.rescuePageData.clearCloseData();
    },
    // 设备
    facility(data) {
      this.tableData = [];
      this.pageLoading = true;
      this.modelTitle = "设备";
      this.columns = [
        { prop: "label", label: "名称" },
        { prop: "modelNo", label: "规格型号" },
        { prop: "siteName", label: "所属工点" },
        { prop: "address", label: "详细位置" },
        { prop: "responsiblePersonName", label: "负责人" },
        { prop: "factory", label: "生产厂家" },
        { prop: "unit", label: "单位" },
        { prop: "count", label: "数量" },
        { prop: "inTime", label: "进场时间" },
        { prop: "expire", label: "有效期" },
        { prop: "state", label: "性能状况" },
        { prop: "remark", label: "备注" },
      ];
      this.$refs.modalDetail.handleOpen();
      this.lineId = data.parent;
      this.siteId = data.id;
      this.typeData = 126;
      this.getTableData();
    },
    getData() {
      this.pageLoading = true;
      const params = {
        departmentId: "-",
        grouping: "-",
        lineId: this.lineId,
        page: this.pages.pageNo,
        realName: "-",
        siteId: this.siteId,
        size: this.pages.pageSize,
        type: this.typeData,
        vendorId: "-",
      };
      queryWorkerListByPage(params).then((res) => {
        if (res.code == 0) {
          this.pageLoading = false;
          this.tableData = res.data.records;
          this.pages.total = res.data.total;
          this.pages.count = res.data.count;
        } else {
          this.pageLoading = false;
        }
      });
    },
    // 值班人员
    operatorDuty(data) {
      this.tableData = [];
      this.typeData = 96;
      this.lineId = data.parent;
      this.siteId = data.id;
      this.getData();
      this.modelTitle = "值班人员";
      this.columns = [
        { prop: "realName", label: "值班人员名称" },
        { prop: "phone", label: "	值班人员电话" },
      ];
      this.$refs.modalDetail.handleOpen();
    },

    //资源调配关闭
    closeter() {
      this.$refs.resores.reset();
      this.$refs.rescueDetail.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.bigdiv::-webkit-scrollbar  {
    display: none;
}

.modalDetail ::v-deep.el-pager {
  position: relative;
  li {
    border-radius: 0px !important;
  }
}

::v-deep .el-pagination {
  width: 350px;
}

::v-deep .el-table {
  width: 100%;

  background: transparent;

  .el-table__body-wrapper {
    height: 300px;
  }

  &::before {
    background-color: #143363;
  }

  thead {
    color: #000;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: rgba(20, 51, 99, 0.5);
  }

  tr,
  th {
    background-color: transparent;
  }

  td {
    border-bottom: 1px solid #143363;
  }

  .cell {
    color: #fff;
  }

  th.is-leaf {
    border: 1px solid #143363;
  }
}

::v-deep .el-table--border {
  border: 1px solid #143363;

  .el-table--enable-row-hover {
    background-color: #143363;
  }

  td {
    border-right: 1px solid #143363;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: rgba(0, 46, 117, 0.4);
}

.map {
  width: 100%;
  height: 100%;
}

//弹窗
.el-dialog,
.el-pager li {
  background: rgba(52, 73, 85, 0.3);
  border: 1px solid #47a2d3;
  border-radius: 10px;
  color: #fff;
}

.el-dialog__wrapper {
  background: rgba(0, 0, 0, 0.8);
}

.el-dialog__title {
  color: #fff;
}

.el-form-item__label {
  color: #fff;
}

.el-input__inner {
  color: #fff;
}
.el-dialog__headerbtn .el-dialog__close {
  color: #f9aa33;
}

.search-wrapper {
  position: absolute;
  right: 23%;
  top: 2%;
  z-index: 99;
  display: flex;
  flex-direction: column;

  ::v-deep .el-input__inner {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background: rgba(24, 70, 127, 0.8) !important;
    border: 1px solid #173f58 !important;
    border-right: none !important;
    // width: 240px;
  }

  ::v-deep .el-input-group__append {
    background: #43a5e9;
    border: 1px solid #43a5e9;
    border-left: none;
    color: #fff;
  }
}
.search-wrapper_teo {
  position: absolute;
  right: 2%;
  top: 2%;
  z-index: 99;
  display: flex;
  flex-direction: column;

  ::v-deep .el-input__inner {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background: rgba(24, 70, 127, 0.8) !important;
    border: 1px solid #173f58 !important;
    border-right: none !important;
    // width: 240px;
  }

  ::v-deep .el-input-group__append {
    background: #43a5e9;
    border: 1px solid #43a5e9;
    border-left: none;
    color: #fff;
  }
}
.information,
.keeper {
  width: 70px;
  height: 70px;
  background: rgba(24, 70, 127, 0.8);

  color: #409eff;
  border: 1px solid #409eff;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  .supervise {
    .keepers {
      width: 27px;
      height: 27px;
      margin: 4px auto;

      background-image: url("~@/assets/map/keepers.png");
    }
    .issue {
      width: 22px;
      margin: 4px auto;
      height: 24px;
      background-image: url("~@/assets/map/issue.png");
    }
  }
}
// .keeper {
//   width: 70px;
//   height: 70px;
//   background: red;
//   margin-top: 10px;
// }

.handle-wrapper {
  position: fixed;
  bottom: 30px;
  left: 53%;
  margin-left: -400px;
  width: 800px;
  height: 5.56vh;

  .corner-wrap {
    width: 100%;
    height: 100%;
    position: absolute;

    .corner {
      width: 16px;
      height: 16px;
    }

    .corner-left-top {
      border-left: 2px solid #43a5e9;
      border-top: 2px solid #43a5e9;
      position: absolute;
      left: -1px;
      top: -1px;
    }

    .corner-right-top {
      border-right: 2px solid #43a5e9;
      border-top: 2px solid #43a5e9;
      position: absolute;
      right: -1px;
      top: -1px;
    }

    .corner-right-bottom {
      border-right: 2px solid #43a5e9;
      border-bottom: 2px solid #43a5e9;
      position: absolute;
      right: -1px;
      bottom: -1px;
    }

    .corner-left-bottom {
      border-left: 2px solid #43a5e9;
      border-bottom: 2px solid #43a5e9;
      position: absolute;
      left: -1px;
      bottom: -1px;
    }
  }

  .handle-content {
    width: 100%;
    height: 100%;

    .handle-item {
      margin-left: 10px;
      position: relative;
      background: rgba(8, 23, 66, 0.9);
      border: 1px solid #002e75;
      box-shadow: 0px 0px 23px 6px #002e75 inset;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        .item-content {
          .item-content-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;

            &::before {
              display: inline-block;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url("~@/assets/map/jiuyuan.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center;
              margin-right: 10px;
            }
          }
          .partJob {
            &::before {
              background-image: url("~@/assets/map/jobTeam.png");
            }
          }
          .dertgbb {
            &::before {
              background-image: url("~@/assets/map/shehui.png");
            }
          }
          .hospital {
            &::before {
              background-image: url("~@/assets/map/hospital.png");
            }
          }
          .singlePawn {
            &::before {
              background-image: url("~@/assets/map/singlePawn.png");
            }
          }
          .municipal {
            &::before {
              background-image: url("~@/assets/map/municipal.png");
            }
          }

          .item-content-anzhi {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;

            &::before {
              display: inline-block;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url("~@/assets/map/material.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center;
              margin-right: 10px;
            }
          }
        }
      }

      &:first-child {
        margin-left: 0;

        .item-content {
          .item-content-wrapper {
            &::before {
              display: inline-block;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url("~@/assets/map/jiuyuan.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center;
              margin-right: 10px;
            }
            .partJob {
              background-image: url("~@/assets/map/jobTeam.png");
            }
            .dertgbb {
              background-image: url("~@/assets/map/shehui.png");
            }
            .singlePawn {
              background-image: url("~@/assets/map/singlePawn.png");
            }
            .hospital {
              background-image: url("~@/assets/map/hospital.png");
            }
            .municipal {
              background-image: url("~@/assets/map/municipal.png");
            }
          }

          .item-content-anzhi {
            &::before {
              display: inline-block;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url("~@/assets/map/material.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center;
              margin-right: 10px;
            }
          }
        }
      }

      .item-content {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 99;

        .item-content-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          color: #fff;
          padding: 0 10px;

          img {
            width: 1.98vw;
          }
        }

        .item-content-anzhi {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          color: #fff;
          padding: 0 10px;

          img {
            width: 20px;
          }
        }
      }
    }
  }
}

// 信息弹窗样式
::v-deep .BMap_pop {
  > div,
  > img:nth-child(10) {
    display: none;
    overflow: unset;
  }

  > div:nth-child(9) {
    display: block;
    overflow: unset;
    min-width: 320px !important;
    top: 20px !important;
  }

  // > div:nth-child(8) {
  //   /*display: block;*/
  // }
  .BMap_top {
    display: none;
  }

  .BMap_center {
    background: transparent;
    border: none;
    position: sticky !important;
    height: 100%;
  }
}
::v-deep .BMap_bubble_content {
  background: #081742;
  box-shadow: 0px 0px 8px 0px rgba(0, 14, 38, 0.2);
  border: 1px solid #173f58;
  padding: 20px;
  position: relative;
  // background: #fff;

  .info-wrapper {
    width: 100%;

    p {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
    }
  }

  .btn-wrapper {
    .el-button {
      padding: 7px 16px;
    }
  }

  .info-header {
    height: 18px;
    margin-bottom: 20px;

    .title {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      // &::before {
      //   display: inline-block;
      //   height: 18px;
      //   width: 18px;
      //   content: "";
      //   background: red;
      //   margin-right: 5px;
      // }
    }

    .close-btn {
      font-size: 12px;
      color: #fff;
      cursor: pointer;
    }
  }

  .triangle {
    width: 40px;
    height: 40px;
    background: #081742;
    border: 1px solid #173f58;
    transform: rotate(-45deg);
    position: absolute;
    z-index: -99;
    left: 41%;
  }
}
.info-rescue {
  width: 200px;
  height: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  background-color: #fff;
}
.close-btn {
  float: right;
  font-size: 12px;
  color: #000;
  cursor: pointer;
}
// 医院
.info-hospital {
  width: 200px;
  height: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  // background-color: #fff;
}
.close-hospital {
  position: absolute;
  top: 10px;
  left: 180px;
  font-size: 12px;
  color: #000;
  cursor: pointer;
}

::v-deep.el-table--border {
  &::after {
    width: 0px;
  }
}
.resources ::v-deep .el-input__inner {
  width: 120px !important;
  color: #c9cbd0;
  .el-form--inline .el-form-item {
    background: #081742;
  }
}
.resources .el-select-dropdown__item.hover {
  background-color: #002e75;
}

.el-form--inline .el-form-item {
  margin-right: 0px !important;
}
</style>
<style>
.anchorBL {
  display: none !important;
}

.ops {
  opacity: 0.4;
}
.resources .el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #081742;
}
::v-deep .search-wrapper .zydpInput .el-input__inner {
  width: 30px;

  background-color: rgba(8, 23, 66, 0.9);
}
</style>
