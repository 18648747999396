<template>
  <div class="material-container h-full">
    <el-form
      ref="formData"
      :rules="rules"
      :model="formData"
      :loading="loading"
      label-width="110px"
      :disabled="submitType == 'view'"
    >
      <el-form-item label="隐患整改单位:" prop="unitType">
        <el-select
          v-model="formData.unitType"
          clearable
          style="width:100%"
          placeholder="请选择隐患整改单位"
        >
          <el-option
            v-for="item of reformUnitTypeData"
            :key="item.code"
            :label="item.intro"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="隐患等级:" prop="hiddenLevel">
        <el-select
          v-model="formData.hiddenLevel"
          clearable
          style="width:100%"
          placeholder="请选择隐患等级"
          @change="levelChange"
        >
          <el-option
            v-for="item of hdLevelData"
            :key="item.code"
            :label="item.intro"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="流程节点:" prop="thisNode">
        <el-select
          v-model="formData.thisNode"
          style="width:100%"
          placeholder="请选择流程节点"
          @change="nodeChange"
        >
          <el-option
            v-for="item of nodeData"
            :key="item.id"
            :label="item.nodeName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="流转节点:" prop="nextNodeName">
        <el-input
          v-model="formData.nextNodeName"
          placeholder="请输入流转节点"
          style="width:100%"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="驳回节点:" prop="rejectNodeName">
        <el-input
          v-model="formData.rejectNodeName"
          placeholder="请输入驳回节点"
          style="width:100%"
          disabled
        ></el-input>
        <!-- <el-select
          v-model="formData.rejectNode"
          style="width:100%"
          placeholder="请选择驳回节点"
        >
          <el-option
            v-for="item of rejectNodeData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
      </el-form-item>
      <el-form-item label="时限:" prop="deadline">
        <el-input
          v-model.number="formData.deadline"
          placeholder="请输入数字,单位小时"
          style="width:100%"
        ></el-input>
      </el-form-item>
      <el-form-item label="权限设置:">
        <el-button size="medium" type="primary" @click="allocate"
          >分配</el-button
        >
      </el-form-item>
    </el-form>
    <div class="dialogFooter" v-show="submitType != 'view'">
      <el-button size="medium" @click="cancel">取 消</el-button>
      <el-button size="medium" type="primary" @click="submit">确 定</el-button>
    </div>
    <div class="form-wrapper">
      <el-dialog
        v-if="dialogVisible"
        width="30%"
        title="分配权限"
        :visible.sync="dialogVisible"
        append-to-body
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div class="tree-wrapper h-full overflow-y-scroll mr-4">
          <el-tree
            v-loading="treeLoading"
            :data="treeData"
            show-checkbox
            node-key="id"
            :props="defaultProps"
            ref="treeRef"
            accordion
            :default-checked-keys="defaultCheckedKeys"
            element-loading-text="拼命加载中"
          >
          </el-tree>
        </div>
        <span slot="footer" class="dialogFooter">
          <el-button @click="chooseCancel">取 消</el-button>
          <el-button type="primary" @click="allotSubmit">提交</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// 字典
import { getDictData } from "@/api/dict";
// 新增,编辑,获取单位角色列表,获取流程节点,获取流转节点和驳回节点
import {
  manageAdd,
  getUnitRoles,
  manageEdit,
  getManageNode,
  getmanageNodeInfo,
} from "@/api/hdanger";
export default {
  props: {
    pageData: {
      type: Object,
      default: () => {},
    },
    submitType: {
      type: String,
    },
  },
  data() {
    return {
      formData: {
        unitType: "",
        hiddenLevel: "",
        thisNode: "",
        nextNode: "",
        nextNodeName: "",
        rejectNode: "",
        rejectNodeName: "",
        deadline: "",
      },
      // 隐患整改单位
      reformUnitTypeData: [],
      hdLevelData: [],
      loading: false,
      rules: {
        // nextNode: [
        //   { required: true, message: "请选择隐患响应单位", trigger: "change" },
        // ],
        // unitType: [
        //   { required: true, message: "请选择隐患整改单位", trigger: "change" },
        // ],
        // thisNode: [
        //   { required: true, message: "请选择隐患等级", trigger: "change" },
        // ],
        // rejectNode: [
        //   { required: true, message: "请选择隐患响应角色", trigger: "change" },
        // ],
        // deadline: [
        //   { required: true, message: "请输入响应时限", trigger: "blur" },
        // ],
      },
      //分配权限
      treeData: [],
      // 节点
      nodeData: [],
      // 流转节点
      nextNodeData: [],
      // 驳回节点
      rejectNodeData: [],
      // 权限树
      defaultProps: {
        label: "roleName",
        children: "children",
      },
      // 勾选的单位角色
      rolesData: [],
      defaultCheckedKeys: [],
      dialogVisible: false,
      treeLoading: false,
    };
  },
  watch: {
    "pageData.id": {
      handler(val) {
        if (val) {
          this.getData();
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
  },
  created() {
    this.getUnitData();
  },
  mounted() {
    this.getDict();
    this.getData();
  },
  methods: {
    //获取流转节点值
    levelChange(val) {
      this.$set(this.formData, "thisNode", "");
      this.$set(this.formData, "nextNode", "");
      this.$set(this.formData, "rejectNode", "");
      this.$set(this.formData, "nextNodeName", "");
      this.$set(this.formData, "rejectNodeName", "");
      getManageNode({ hiddenLevel: val }).then((res) => {
        if (res.code == 0) {
          this.nodeData = res.data;
        }
      });
    },
    // 获取流转节点和驳回节点
    nodeChange(val) {
      this.$set(this.formData, "nextNode", "");
      this.$set(this.formData, "rejectNode", "");
      this.$set(this.formData, "nextNodeName", "");
      this.$set(this.formData, "rejectNodeName", "");
      var demo = [];
      this.nodeData.forEach((element) => {
        if (element.id == val) {
          demo = element;
        }
      });
      getmanageNodeInfo(demo.nextNodeId).then((res) => {
        if (res.code == 0) {
          // this.nextNodeData.push({
          //   label: res.data.nodeName,
          //   value: res.data.id,
          // });
          // this.nextNodeData = res.data
          this.formData.nextNode = res.data.id;
          this.formData.nextNodeName = res.data.nodeName;
        }
      });

      if (demo.rejectNodeId) {
        getmanageNodeInfo(demo.rejectNodeId).then((res) => {
          if (res.code == 0) {
            this.formData.rejectNode = res.data.id;
            this.formData.rejectNodeName = res.data.nodeName;
            // this.rejectNodeData.push({
            //   label: res.data.nodeName,
            //   value: res.data.id,
            // });
          }
        });
      }
    },
    // 编辑查看回显
    getData() {
      if (this.submitType == "add") {
        this.$set(this.formData, "thisNode", "");
      } else {
        this.formData = { ...this.pageData };
        this.formData.thisNode = Number(this.formData.thisNode);
      }
      let rolesData = [];
      rolesData = this.formData.manageRoles;
      if (rolesData) {
        rolesData.forEach((element) => {
          this.defaultCheckedKeys.push(element.roleId);
        });
      }
      //获取流转节点值
      getManageNode({ hiddenLevel: this.formData.hiddenLevel }).then((res) => {
        if (res.code == 0) {
          this.nodeData = res.data;
        }
      });
    },
    getDict() {
      //等级,整改单位
      const demo = ["reform_unit_type", "hdLevel"];
      demo.forEach((item) => {
        this.getDictList(item);
      });
    },
    // 字典  unitType 单位类型
    getDictList(val) {
      getDictData(val).then((res) => {
        if (res.code == 0) {
          switch (val) {
            case "reform_unit_type":
              this.reformUnitTypeData = res.data;
              break;
            case "hdLevel":
              this.hdLevelData = res.data;
              break;
          }
        }
      });
    },
    getUnitData() {
      this.treeLoading = true;
      getUnitRoles().then((res) => {
        if (res.code == 0) {
          let unitRoles = [];
          unitRoles = res.data;
          unitRoles.forEach((element) => {
            element.dict.roleName = element.dict.intro;
            element.dict.children = element.roles;
            delete element.roles;
            this.treeData.push(element.dict);
          });
          this.treeLoading = false;
        }
      });
    },

    // 分配
    allocate() {
      this.dialogVisible = true;
    },
    allotSubmit() {
      console.log("tijiao");
      // this.siteUuIdList = [...this.$refs.treeRef.getCheckedKeys(true)];
      this.rolesData = [];
      const selectNodeData = this.$refs.treeRef.getCheckedNodes(true);
      console.log("数据", selectNodeData);
      if (selectNodeData.length) {
        for (let item of selectNodeData) {
          if (item.unitType) {
            this.rolesData.push({
              roleId: item.id,
              unitType: item.unitType,
            });
          }
        }
      }
      this.dialogVisible = false;
    },
    // 分配权限关闭
    handleClose() {
      this.dialogVisible = false;
    },
    chooseCancel() {
      this.handleClose();
    },
    cancel() {
      this.rolesData = [];
      this.defaultCheckedKeys = [];
      this.reset();
      this.$emit("CloseData");
    },
    submit() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, this.formData);
          copyForm.manageRoles = this.rolesData;
          if (this.submitType == "add") {
            copyForm.id = "";
            manageAdd(copyForm)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.loading = true;
                  this.cancel();
                  this.rolesData = [];
                } else {
                  this.$message.warning(res.message);
                }
              })
              .catch((res) => {
                this.$message.warning(res.message);
              });
          } else if (this.submitType == "edit") {
            manageEdit(copyForm)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.loading = true;
                  this.cancel();
                  this.rolesData = [];
                } else {
                  this.$message.warning(res.message);
                }
              })
              .catch((res) => {
                // this.cancel();
                this.$message.warning(res.message);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    reset() {
      // this.$refs['formData'].resetFields()
      // 比上方获取demo的清理方式更彻底
      this.rolesData = [];
      const th = this;
      Object.keys(this.formData).forEach(function(key) {
        th.formData[key] = "";
        //  th.$set(this.formData, "nextNode", "");
      });
      // this.$refs.formData.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
::v-deep .el-dialog__body {
  height: 460px;
}
</style>
