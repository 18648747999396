<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
             label-width="120px" class="demo-model">

      <el-form-item label="所属建管部" prop="buildDeptName">
        <el-input v-model="ruleForm.buildDeptName" disabled placeholder="所属建管部"></el-input>
      </el-form-item>
      <el-form-item label="线路名称" prop="lineName">
        <el-input v-model="ruleForm.lineName" disabled placeholder="线路名称"></el-input>
      </el-form-item>
      <el-form-item label="分部名称" prop="branchName">
        <el-input v-model="ruleForm.branchName" disabled placeholder="分部名称"></el-input>
      </el-form-item>
      <el-form-item label="工点名称" prop="siteName">
        <el-input v-model="ruleForm.siteName" disabled placeholder="工点名称"></el-input>
      </el-form-item>
      <el-form-item label="培训名称" prop="label">
        <el-input v-model="ruleForm.label" disabled placeholder="培训名称"></el-input>
      </el-form-item>
      <el-form-item label="主讲人" prop="teacher">
        <el-input v-model="ruleForm.teacher" disabled placeholder="主讲人"></el-input>
      </el-form-item>
      <el-form-item label="培训地点" prop="trainAddress">
        <el-input v-model="ruleForm.trainAddress" disabled placeholder="培训地点"></el-input>
      </el-form-item>
      <el-form-item label="计划培训时间" prop="planDate">
        <el-input v-model="ruleForm.planDate" disabled placeholder="计划培训时间"></el-input>
      </el-form-item>
      <el-form-item label="实际培训时间" prop="trainDate" v-if="examintStatus == 5">
        <el-input v-model="ruleForm.trainDate" disabled placeholder="实际培训时间"></el-input>
      </el-form-item>
      <el-form-item label="参与人数" prop="joinCount" v-if="examintStatus == 5">
        <el-input v-model="ruleForm.joinCount" disabled placeholder="参与人数"></el-input>
      </el-form-item>
      <el-form-item label="上传人" prop="operatorName">
        <el-input v-model="ruleForm.operatorName" disabled placeholder="上传人"></el-input>
      </el-form-item>
      <el-form-item label="上报时间" prop="created">
        <el-input v-model="ruleForm.created" disabled placeholder="上报时间"></el-input>
      </el-form-item>

      <el-form-item label="课件" prop="label">
<!--        <Uploader></Uploader>-->
        <div class="file-item" @click="openFile(ruleForm.coursewares[0].link)">
          {{ ruleForm.coursewares ? ruleForm.coursewares[0].attrName : '' }}
        </div>
       </el-form-item>
      <el-form-item label="附件" prop="label" v-if="examintStatus == 5">
<!--        <Uploader></Uploader>-->
        <div class="file-item" @click="openFile(ruleForm.accessories[0].link)">
          {{ ruleForm.accessories ? ruleForm.accessories[0].attrName : '' }}
        </div>
      </el-form-item>

      <el-form-item label="审核意见" prop="label">
        <el-radio-group v-model="radio">
          <el-radio :label="3">同意</el-radio>
          <el-radio :label="6">驳回</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="原因" prop="reason">
        <el-input type="textarea" v-model="ruleForm.reason" placeholder="请输原因"></el-input>
      </el-form-item>

    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelTrainClose">取 消</el-button>
      <el-button type="primary" @click="submitTrain">确 定</el-button>
    </div>

  </div>
</template>

<script>
// import Uploader from "./Uploader";
import {getTrainDetail, trainInfoUpdate} from "@/api/stand";

export default {
  name: "planExamine",
  props : {
    detailId: {},
    examintStatus : {
      default : null
    },
  },
  components:{
    // Uploader
  },
  data(){
    return{
      ruleForm : {
        courseware : [],
        accessory : [],
      },
      rules : {
        reason: [
          { required: true, message: '请输入原因', trigger: 'blur' },
        ],
      },
      radio : 3
    }
  },
  created() {
    this.getDetailData();
  },
  methods : {
    getDetailData(){
      let params = {
        id : this.detailId
      }
      getTrainDetail(params).then(res => {
        console.log(res);
        if(res.code == 0){
          this.ruleForm = res.data;
        }
      })
    },
    openFile(url){
      window.open(url)
    },
    cancelTrainClose(){
      this.$emit('cancelTrainClose')
    },
    submitTrain(){
      this.$refs['ruleForm'].validate((valid) => {
        if(valid){
          let params = {
            id : this.detailId,
            reason : this.ruleForm.reason,
            // state : this.radio == 3 ? 3 : 2
          }
          if(this.examintStatus == 1 && this.radio == 3){
            params.state = 4;
          }else if (this.examintStatus == 1 && this.radio != 3){
            params.state = 3
          }else if (this.examintStatus == 5 && this.radio == 3){
            params.state = 8
          }else if (this.examintStatus == 5 && this.radio != 3){
            params.state = 7
          }
          trainInfoUpdate(params).then(res => {
            console.log(res);
            if(res.code == 0){
              this.$message.success('操作成功');
              this.$emit('successTrain')
            }else{
              this.$message.error(res.msg);
            }
          })
        }else{
          this.$message.error('请正确填写');
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.file-item{
  color: #1aa3ff;
  cursor: pointer;
}
</style>
