<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
            <el-cascader
              v-model="queryModal.linsiteIds"
              clearable
              :options="lineSiteData"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="handleLineSiteChange"
              placeholder="选择线路工点"
            ></el-cascader>
          </el-form-item>

          <el-form-item label="">
            <el-select
              clearable
              v-model="queryModal.nodeTypeCode"
              placeholder="选择类别"
            >
              <el-option
                v-for="item in keyNodeTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="queryModal.nodeLevelCode"
              placeholder="选择级别"
            >
              <el-option
                v-for="item in keyNodesLevelList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              clearable
              v-model="queryModal.nodeName"
              placeholder="节点名称"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="active == 1">
            <el-select
              clearable
              v-model="queryModal.state"
              placeholder="选择审批状态"
            >
              <el-option
                v-for="item in stateList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item>
            <el-button type="primary" v-has="'drill:add'" @click="handleAdd"
              >新增</el-button
            >
          </el-form-item>
          <!-- <el-form-item>
            <el-button type="primary" v-has="'drill:add'" @click="handleAdd"
              >导入</el-button
            >
          </el-form-item> -->
        </el-row>
      </el-form>
    </div>
    <div style="margin-bottom: 10px">
      <el-radio-group v-model="active" @change="changeTab">
        <el-radio-button :label="1">待确认</el-radio-button>
        <el-radio-button :label="2">已确认</el-radio-button>
      </el-radio-group>
    </div>

    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          v-if="active === 2"
          align="center"
          prop="lineName"
          label="分区"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="工点"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="nodeTypeName"
          label="类别"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="nodeName"
          label="关键节点名称"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="nodeLevelName"
          label="级别"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="stateName"
          label="审批状态"
          show-overflow-tooltip
          width=""
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.state == 1 && queryModal.isApply == false"
              type="text"
              @click="auditclick(scope.row)"
              >{{ scope.row.stateName }}</el-button
            >
            <el-button
              v-if="scope.row.state == 2 && queryModal.isApply == false"
              type="text"
              style="color:#F56C6C;"
              @click="auditclick(scope.row)"
              >{{ scope.row.stateName }}</el-button
            >
            <span v-if="scope.row.state == 0 && queryModal.isApply == false">{{
              scope.row.stateName
            }}</span>
            <el-button
              v-if="queryModal.isApply == true"
              type="text"
              @click="auditclick(scope.row)"
              >已确认</el-button
            >
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="250" fixed="right">
          <template slot-scope="scope">
            <span>
              <el-button type="text" @click="handleView(scope.row, 'view')"
                >查看</el-button
              >
            </span>
            <span v-if="active === 1" class="ml-2">
              <el-button
                :disabled="scope.row.state == 1 ? true : false"
                v-if="userUnitType == 'construct' || userName == 'admin'"
                type="text"
                @click="handleView(scope.row, 'edit')"
                >编辑</el-button
              >
              <el-button
                :disabled="scope.row.state == 1 ? true : false"
                v-if="userUnitType == 'construct' || userName == 'admin'"
                type="text"
                @click="handleApp(scope.row)"
                >申请</el-button
              >
              <el-button
                :disabled="scope.row.state == 1 ? true : false"
                v-if="userUnitType == 'construct' || userName == 'admin'"
                type="text"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
              <span v-if="scope.row.state == 1">
                <el-button
                  v-if="userUnitType == 'monitor' || userName == 'admin'"
                  type="text"
                  @click="handleAudit(scope.row, 'edit')"
                  >审核</el-button
                >
              </span>
              <!-- <el-button type="text" @click="handleAudit(scope.row)" -->
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      class="editDialog details-dialog"
      append-to-body
      :before-close="CloseData"
    >
      <nodeConForm
        :type="type"
        :keyNodeTypeList="keyNodeTypeList"
        :keyNodesLevelList="keyNodesLevelList"
        @CloseData="CloseData"
        @submitClose="submitClose"
        :nodeId="nodeId"
        :nodeForm="nodeForm"
      ></nodeConForm>
    </el-dialog>
    <!-- <el-dialog
      width="50%"
      class="editDialog details-dialog"
      :visible.sync="nodeDetailsDia"
      v-if="nodeDetailsDia"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <NodeDetails></NodeDetails>
    </el-dialog> -->
    <el-dialog
      width="50%"
      class="editDialog details-dialog"
      :visible.sync="auditVisible"
      v-if="auditVisible"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <NodeAudit
        :rowData="rowData"
        :active="AuditActive"
        :currentState="currentState"
        :nodeId="nodeId"
        :nodeUuid="nodeUuid"
        @submitClose="AuditClose"
        @showFileList="showFileList"
      ></NodeAudit>
    </el-dialog>
    <el-dialog
      width="40%"
      title="附件列表"
      class="fileDialogCs"
      :visible.sync="fileDialog"
      v-if="fileDialog"
      style="z-index:99999"
      :close-on-click-modal="false"
      :before-close="handlefileClose"
    >
      <el-table :data="fileData" style="width: 100%">
        <el-table-column
          align="center"
          prop="fileName"
          show-overflow-tooltip
          label="附件名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          show-overflow-tooltip
          label="操作"
          width="90"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="handleFileView(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import nodeConForm from "./KeyNodeDialog/nodeConForm.vue";
// import NodeDetails from "./KeyNodeDialog/nodeDetails.vue";
import NodeAudit from "./KeyNodeDialog/nodeAuditLog.vue";
import { confirmPage, nodeDelete, nodeApply } from "@/api/riskManage";
import { getDictData } from "@/api/dict";
export default {
  components: {
    // NodeDetails,
    nodeConForm,
    NodeAudit
  },
  data() {
    return {
      queryModal: {
        siteUuidList: [],
        nodeTypeCode: "",
        nodeLevelCode: "",
        nodeName: "",
        linsiteIds: [],
        isApply: false,
        keyNodesType: 0,
        state: null
      },
      loading: false,
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      tableData: [],
      warnTypeList: [],
      nodeDetailsDia: false,
      active: 1,
      dialogVisible: false,
      auditVisible: false,
      AuditActive: 2,
      keyNodeTypeList: [],
      keyNodesLevelList: [],
      stateList: [
        // 0 待确认 1待确认审核 2确认驳回 3.待申请验收 4待验收审核 5驳回待整改 6整改待验收 7验收通过
        { name: "待申请", value: 0 },
        { name: "待监理审核", value: 1 },
        { name: "监理驳回", value: 2 }
        // { name: "待申请",value:3 },
        // { name: "待监理验收",value:4 },
        // { name: "监理驳回",value:5 },
        // { name: "整改待验收",value:6 },
        // { name: "验收通过 ",value:7 },
      ],
      type: "",
      nodeId: null,
      nodeForm: {},
      userUnitType: "",
      userName: "",
      fileDialog: false,
      fileData: [],
      currentState: null,
      rowData: {}
    };
  },
  created() {
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      this.userUnitType = user.companyCategory;
      this.userName = user.userName;
    }
    this.getLineTree();
    this.getDict();
    this.getData();
    // keyNodesType
  },
  methods: {
    async getLineTree() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.lineSiteData = JSON.parse(JSON.stringify(lineSiteData));
      }
    },
    // 级联选择器事件
    handleLineSiteChange(val) {
      console.log(val, "val");
      this.queryModal.siteUuidList = [];
      if (val.length > 0) {
        for (let item of val) {
          this.queryModal.siteUuidList.push(item[item.length - 1]);
        }
      }
    },
    getDict() {
      getDictData("keyNodesType").then(res => {
        if (res.code == 0) {
          this.keyNodeTypeList = res.data;
        }
      });
      getDictData("keyNodesLevel").then(res => {
        if (res.code == 0) {
          this.keyNodesLevelList = res.data;
        }
      });
      // this.getData();
    },
    getData() {
      this.loading = true;
      let params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.queryModal
      };
      confirmPage(params).then(res => {
        this.stateList.forEach(el => {
          res.data.records.forEach(v => {
            if (el.value == v.state) {
              v.stateName = el.name;
            }
          });
        });
        this.tableData = res.data.records;
        this.page.total = res.data.total;
        this.loading = false;
      });
    },
    handleAdd() {
      this.title = "新增关键节点";
      this.type = "add";
      this.dialogVisible = true;
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getData();
    },
    handleView(row, type) {
      this.type = type;
      if (type == "view") {
        this.title = "查看关键节点";
      } else if (type == "edit") {
        this.title = "编辑关键节点";
      }
      console.log(row, "row");
      this.nodeId = row.id;
      this.nodeForm = { ...row };
      this.dialogVisible = true;
    },
    auditclick(row) {
      console.log(row, "row");
      this.nodeUuid = row.nodeUuid;
      this.rowData = { ...row };
      this.AuditActive = 2;
      if (row.state == 1) {
        this.currentState = 1;
      } else {
        this.currentState = 2;
      }
      this.auditVisible = true;
    },
    handleAudit(row) {
      console.log(row, "row");
      this.AuditActive = 1;
      this.currentState = 1;
      this.nodeId = row.id;
      this.nodeUuid = row.nodeUuid;
      this.auditVisible = true;
    },
    CloseData() {
      this.dialogVisible = false;
      this.nodeForm = {};
    },
    submitClose() {
      this.dialogVisible = false;
      this.getData();
    },
    AuditClose() {
      console.log("dddddddddddd");
      this.auditVisible = false;
      this.getData();
    },
    handleClose() {
      this.nodeDetailsDia = false;
      this.auditVisible = false;
    },
    showFileList(obj) {
      this.fileDialog = true;
      this.fileData = obj.fileList;
      console.log(this.fileData);
    },
    handleFileView(row) {
      const link = document.createElement("a");
      link.href = row.fileUrl;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
    handlefileClose() {
      this.fileDialog = false;
    },
    changeTab(name) {
      if (name == 1) {
        this.queryModal.isApply = false;
      }
      if (name == 2) {
        this.queryModal.isApply = true;
      }
      this.getData();
      // this.active = name;
    },
    handleApp(row) {
      console.log(row);
      const that = this;
      this.$confirm("确定提交节点确认申请吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.loading = true;
          nodeApply({ ids: row.id }).then(res => {
            if (res.code == 0) {
              that.$message({
                type: "success",
                message: "成功!"
              });
              that.getData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    handleDelete(row) {
      const that = this;
      console.log(row);
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          nodeDelete(row.id).then(res => {
            if (res.code == 0) {
              that.$message({
                type: "success",
                message: "成功!"
              });
              that.getData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #1684fc;
  width: 300px;
  padding: 0;
  .tabs-item {
    width: 150px;
    height: 30px;
    line-height: 30px;
    color: #1684fc;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }
  .tabs-item:first-child {
    border-right: none;
  }
  .active {
    background: #1684fc;
    color: #ffffff;
    width: 150px;
    height: 32px;
    line-height: 32px;
    border-radius: 5px;
    margin: -1px 0 0 -1px;
  }
}
::v-deep .details-dialog > .el-dialog {
  > .el-dialog__header {
    padding: 0 !important;
  }
}
::v-deep .editDialog .el-dialog .el-dialog__body {
  padding-left: 20px;
}
</style>
