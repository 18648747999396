<template>
  <el-dialog
    class="system-modal editDialog"
    :title="title"
    :visible.sync="dialogVisible"
    :width="width"
    :before-close="handleClose"
    :modal="modal"
    :IsAudit="IsAudit"
    :append-to-body="true"
  >
    <slot></slot>
    <span slot="footer" class="dialog-footer" v-if="showFooter">
      <span v-if="IsAudit">
        <el-button type="primary" @click="handleAgree">同意</el-button>
        <el-button type="danger" @click="handleDisagree">不同意</el-button>
      </span>
      <span v-else>
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    }
  },
  props: {
    title: {
      type: String,
      default: '标题',
    },
    width: {
      type: [String, Number],
      default: '30%',
    },
    modal: {
      type: Boolean,
      default: true,
    },
    IsAudit: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleOpen() {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('modalClose')
    },
    handleAgree() {
      this.$emit('modalAgree')
    },
    handleDisagree() {
      this.$emit('modalDisagree')
    },
    handleSubmit() {
      this.$emit('modalConfirm')
    },
  },
}
</script>

<style lang="scss" scoped></style>
