<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="type == 'view' ? viewRule : rules"
      ref="ruleForm"
      label-width="190px"
      class="demo-model"
      :disabled="hiddenBtn"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item label="风险名称" prop="riskName">
            <el-input
              disabled
              v-model="ruleForm.riskName"
              placeholder="风险名称"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="措施名称" prop="riskName">
            <el-input
              disabled
              v-model="ruleForm.measureSmg"
              placeholder="措施名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="检查单位" prop="riskName">
            <el-input
              disabled
              v-model="ruleForm.inspectUnitName"
              placeholder="检查单位"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="检查人员" prop="riskName">
            <el-input
              disabled
              v-model="ruleForm.inspectPersonName"
              placeholder="检查人员"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="风险级别" prop="riskName">
            <el-input
              disabled
              v-model="ruleForm.riskLevelName"
              placeholder="风险级别"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="整改单位" prop="riskName">
            <el-input
              disabled
              v-model="ruleForm.rectificationUnitName"
              placeholder="整改单位"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="整改人" prop="riskName">
            <el-input
              disabled
              v-model="ruleForm.rectificationPersonName"
              placeholder="整改人"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="整改截止时间" prop="riskName">
            <el-date-picker
              v-if="type != 'view'"
              style="width: 100%"
              :readonly="type == 'view' ? true : false"
              :disabled="type == 'view' ? true : false"
              v-model="ruleForm.rectificationDeadline"
              type="date"
              format="yyyy-MM-dd"
              placeholder="选择时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
            <el-input
              v-if="type == 'view'"
              v-model="ruleForm.rectificationDeadline"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="问题发生时间" prop="riskName">
            <el-date-picker
              v-if="type != 'view'"
              style="width: 100%"
              :readonly="type == 'view' ? true : false"
              :disabled="type == 'view' ? true : false"
              v-model="ruleForm.happenTime"
              type="date"
              format="yyyy-MM-dd"
              placeholder="选择时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
            <el-input
              v-if="type == 'view'"
              v-model="ruleForm.happenTime"
              readonly
            ></el-input>
            <!-- <el-input
              :readonly="type == 'view' ? true : false"
              v-model="ruleForm.riskName"
              placeholder="问题发生时间"
            ></el-input> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="是否预警" prop="riskName">
            <!--            <el-input
              :readonly="type == 'view' ? true : false"
              v-model="ruleForm.riskName"
              placeholder="整改截至时间"
            ></el-input>-->
            <el-checkbox
              :disabled="ruleForm.measureTypeName !== '施工措施'"
              v-model="isWarning"
              >是</el-checkbox
            >
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="预警级别" prop="riskName">
            <!--            <el-input-->
            <!--              :readonly="type == 'view' ? true : false"-->
            <!--              v-model="ruleForm.riskName"-->
            <!--              placeholder="问题发生时间"-->
            <!--            ></el-input>-->

            <el-select
              v-model="ruleForm.warnLevelCode"
              clearable
              style="width: 100%"
              :readonly="type == 'view' ? true : false"
              placeholder="预警级别"
            >
              <el-option
                v-for="item of warnLevelList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="验收单位" prop="riskName">
            <el-input
              disabled
              v-if="!isWarning"
              v-model="ruleForm.notWarnCheckUnitName"
              placeholder="验收单位"
            ></el-input>

            <el-input
              disabled
              v-else
              v-model="ruleForm.isWarnCheckUnitName"
              placeholder="验收单位"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="验收人" prop="riskName">
            <el-input
              disabled
              v-model="ruleForm.notWarnCheckPersonName"
              v-if="!isWarning"
              placeholder="验收人"
            ></el-input>
            <el-input
              disabled
              v-else
              v-model="ruleForm.isWarnCheckPersonName"
              placeholder="验收人"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="问题/预警描述及整改要求" prop="problemDescribe">
            <el-input
              v-model="ruleForm.problemDescribe"
              :readonly="type == 'view' ? true : false"
              type="textarea"
              resize="none"
              placeholder="请输入问题/预警描述及整改要求"
              auto-complete="off"
              :autosize="{ minRows: 2, maxRows: 4 }"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="现场图片/视频" prop="photoList">
            <span slot="label">
              <span class="span-box">
                <span style="color: #ff1e2f; margin-right: 3px">*</span>
                <span>现场图片/视频</span>
              </span>
            </span>
            <image-uploader
              @uploadChange="urlChange"
              v-if="type !== 'prode'"
            ></image-uploader>
            <!--
              v-model="ruleForm.riskWarnPicList"-->
            <!--            <div
              class="file-item"
              v-else
              v-for="(item, index) in imgList"
              :key="index"
              @click="handleFileView(item)"
            >
              {{ item.fileName }}
            </div>-->
            <!-- <el-image
              style="width: 100px; height: 100px"
              v-for="(item, index) in imgList"
              :kek="index"
              :src="item.fileUrl"
              :preview-src-list="imgList.map((item) => item.fileUrl)"
            >
            </el-image> -->

            <div
              class="file-item"
              v-else
              v-for="(item, index) in imgList"
              :key="index"
              @click="handleFileView(item)"
            >
              {{ item.fileName }}
            </div>

          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="补充附件" prop="riskName">
            <FileUploader
              @uploadChange="handleFileUpload"
              @delChange="handleFileDel"
              :value="fileList"
              v-if="type !== 'prode'"
            ></FileUploader>
            <div
              class="file-item"
              v-else
              v-for="(item, index) in progressFileList"
              :key="index"
              @click="handleFileView(item)"
            >
              {{ item.fileName }}
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="dialogFooter" v-if="!hiddenBtn && type === 'guanli'">
      <el-button size="medium" @click="cancel">取消上报</el-button>
      <el-button size="medium" type="primary" @click="submit"
        >确认上报</el-button
      >
    </div>
  </div>
</template>

<script>
// 图片
import imageUploader from "../../../components/imageUploader.vue";
// 文件
import FileUploader from "@/components/Uploader/FileUploader.vue";
import {
  getMessageDetail,
  getRiskBaseMsg,
  historyPatrolAdd,
  riskPatrolProblemAdd,
} from "@/api/riskManage";
import { getDictData } from "@/api/dict";
import { queryDeptDetail } from "@/api/stand";
export default {
  components: {
    FileUploader,
    imageUploader,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    frontUuid: {
      type: String,
      default: "",
    },
    rowInfo: {
      type: Object,
      default: () => {},
    },
    rowFxInfo: {
      type: Object,
      default: () => {},
    },
    riskDeafUuid: {
      default: "",
    },
    fatherForm: {
      default: () => {},
    },
    hiddenBtn: {
      type: Boolean,
      default: () => false,
    },
    rowManInfo: {
      default: () => {},
    },
  },
  data() {
    return {
      ruleForm: {
        riskName: "",
        riskWarnPicList: [],
        happenTime: "",
        photoList: [],
      },
      viewRule: {},
      rules: {
        problemDescribe: [
          { required: true, message: "风险进展文字说明必填", trigger: "blur" },
        ],
        /*  photoList : [
          { required: true, message: '形象进展图最少上传一个', trigger: 'blur' }
        ]*/
      },
      fileList: [],
      isWarning: false,
      userInfo: JSON.parse(localStorage.getItem("user")),
      imgList: [],
      progressFileList: [],
      warnLevelList: [],
      photoList: [],
      deptType: "",
    };
  },
  created() {
    this.getDictList();
    this.getDeptDetail();
    if (this.type === "prode") {
      this.getMessageInfo();
    } else {
      this.getDetailData();
    }
  },
  methods: {
    //查询属于哪个角色
    getDeptDetail() {
      console.log(JSON.parse(localStorage.getItem("user")));
      var userInfo = JSON.parse(localStorage.getItem("user"));
      queryDeptDetail(userInfo.companyId).then((res) => {
        console.log(res);
        this.deptType = res.data ? res.data.category : "0";
      });
    },
    //warnLevel
    getDictList() {
      getDictData("warnLevel").then((res) => {
        this.warnLevelList = res.data;
      });
    },
    getDetailData() {
      getRiskBaseMsg(this.riskDeafUuid).then((res) => {
        this.ruleForm = res.data;
        this.ruleForm.measureTypeName = this.fatherForm.measureTypeName;
      });
    },
    handleFileView(row) {
      console.log(row);
      this.downLoadFile(row.filePath, row.fileName);
      /*const link = document.createElement("a");
      link.href = row.fileUrl;
      link.download = "附件";
      link.target = "_blank";
      link.click();*/
    },
    getMessageInfo() {
      console.log(this.rowFxInfo);
      getMessageDetail(this.rowFxInfo.progressUuid).then((res) => {
        console.log(res);
        // 将获取的数据返出去
        this.$emit("getData", res);
        this.ruleForm = res.data[0];
        this.imgList = this.ruleForm.riskPatrolProblemFileList.filter(
          (item) => item.fileType === 0 || item.fileType === 2
        );
        this.progressFileList = this.ruleForm.riskPatrolProblemFileList.filter(
          (item) => item.fileType === 1
        );
        this.isWarning = this.ruleForm.isWarn === 0 ? true : false;
      });
    },
    urlChange(urlList) {
      console.log(urlList);
      this.photoList = urlList.map((item) => {
        return {
          fileName: item.name,
          fileUrl: item.url,
          fileType: item.type === "image" ? 0 : item.type === "video" ? 2 : 1,
          filePath: item.fullPath
        };
      });
      this.ruleForm.photoList = JSON.parse(JSON.stringify(this.photoList));
    },
    // 附件上传
    handleFileUpload(e) {
      console.log(e);
      this.fileList.push({
        fileName: e.name,
        name: e.name,
        fileUrl: e.url,
        fileType: e.type === "image" ? 0 : e.type === "video" ? 2 : 1,
        filePath: e.realUrl,
      });
    },
    handleFileDel(e) {
      let index = this.fileList.findIndex((v) => v.url === e.url);
      this.fileList.splice(index, 1);
    },
    cancel() {
      console.log("关闭");
      this.$emit("cancelProblem", false);
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (this.deptType === "monitor") {
          if (!this.fatherForm.progressDescribe) {
            this.$message.error("风险进展描述不能为空");
            return;
          }
          if (this.fatherForm.progressPicUrlList.length === 0) {
            this.$message.error("形象进展图最少上传一个");
            return;
          }
        }
        if (valid) {
          if (this.photoList.length === 0) {
            this.$message.error("现场图片最少上传一个");
            return;
          }
          if (this.isWarning === true && !this.ruleForm.warnLevelCode) {
            this.$message.error("存在预警，预警级别必填");
            return;
          }
          this.fatherForm.progressUuid = this.frontUuid;
          let params = {
            companyName: this.userInfo.companyName,
            companyTypeCode: this.userInfo.companyCategory,
            companyTypeName: this.userInfo.companyCategoryName,
            companyUuid: this.userInfo.companyId,
            departmentUuid: this.userInfo.departmentId,
            progressDescribe: this.fatherForm.progressDescribe,
            progressPicUrlList: this.fatherForm.progressPicUrlList,
            riskDeafUuid: this.fatherForm.riskDeafUuid,
            riskName: this.fatherForm.riskName,
            submitPersonName: this.fatherForm.patrolsPerson,
            submitPersonUuid: this.userInfo.uuid,
            progressTitle: "",
            progressUuid: this.frontUuid,
            riskMasterUuid: this.rowManInfo.riskMasterUuid,
          };
          console.log(params);
          historyPatrolAdd(params).then((res) => {
            console.log(res);
          });
          this.photoList = this.photoList.concat(this.fileList);
          this.ruleForm.riskPatrolProblemFileList = this.photoList;
          this.ruleForm.isWarn = this.isWarning;
          let paramsPatrol = {
            riskPatrolProblemFileList: this.photoList,
            bottomPersonName: this.ruleForm.bottomPersonName,
            bottomPersonUuid: this.ruleForm.bottomPersonUuid,
            checkButton: this.ruleForm.checkButton,
            checkUnitName:
              this.isWarning === true
                ? this.ruleForm.isWarnCheckUnitName
                : this.ruleForm.notWarnCheckUnitName,
            checkUnitUuid:
              this.isWarning === true
                ? this.ruleForm.isWarnCheckUnitUuid
                : this.ruleForm.notWarnCheckUnitUuid,
            checkPersonName:
              this.isWarning === true
                ? this.ruleForm.isWarnCheckPersonName
                : this.ruleForm.notWarnCheckPersonName,
            checkPersonUuid:
              this.isWarning === true
                ? this.ruleForm.isWarnCheckPersonUuid
                : this.ruleForm.notWarnCheckPersonUuid,
            constructUnitName: this.ruleForm.constructUnitName,
            constructUnitUuid: this.ruleForm.constructUnitUuid,
            created: this.ruleForm.created,
            dealButton: this.ruleForm.dealButton,
            happenTime: this.ruleForm.happenTime,
            id: this.ruleForm.id,
            inspectPersonName: this.ruleForm.inspectPersonName,
            inspectPersonUuid: this.ruleForm.inspectPersonUuid,
            inspectUnitName: this.ruleForm.inspectUnitName,
            inspectUnitUuid: this.ruleForm.inspectUnitUuid,
            isDelete: this.ruleForm.isDelete,
            isWarn: this.isWarning === true ? 0 : 1,
            measureId: this.ruleForm.measureId,
            measureSmg: this.ruleForm.measureSmg,
            monitorUnitName: this.ruleForm.monitorUnitName,
            monitorUnitUuid: this.ruleForm.monitorUnitUuid,
            problemDescribe: this.ruleForm.problemDescribe,
            problemStatus: this.ruleForm.problemStatus,
            progressUuid: this.frontUuid,
            rectificationDeadline: this.ruleForm.rectificationDeadline,
            rectificationPersonName: this.ruleForm.rectificationPersonName,
            rectificationPersonUuid: this.ruleForm.rectificationPersonUuid,
            rectificationUnitName: this.ruleForm.rectificationUnitName,
            rectificationUnitUuid: this.ruleForm.rectificationUnitUuid,
            riskDeafUuid: this.ruleForm.riskDeafUuid,
            riskLevelName: this.ruleForm.riskLevelName,
            riskMasterUuid: this.ruleForm.riskMasterUuid,
            riskName: this.ruleForm.riskName,
            warnLevelCode: this.ruleForm.warnLevelCode,
            riskLevelCode: this.ruleForm.riskLevelCode,
          };

          if (this.ruleForm.warnLevelCode) {
            paramsPatrol.warnLevel = this.warnLevelList.filter(
              (item) => item.code === this.ruleForm.warnLevelCode
            )[0].label;
          }
          console.log(paramsPatrol);
          riskPatrolProblemAdd(paramsPatrol).then((res) => {
            if (res.code == 0) {
              this.$message.success("操作成功");
              this.$emit("closeProblem", true);
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          this.$message.error("请正确填写");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
  .el-button {
    margin-left: 30px;
  }
}
.file-item {
  color: #1aa3ff;
  cursor: pointer;
}
</style>
