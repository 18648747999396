<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
            <el-cascader
              v-model="queryModal.linsiteIds"
              clearable
              :options="lineSiteData"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="handleLineSiteChange"
              placeholder="选择线路工点"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="">
            <el-select
              clearable
              v-model="queryModal.nodeTypeCode"
              placeholder="选择类别"
            >
              <el-option
                v-for="item in keyNodeTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="queryModal.nodeLevelCode"
              placeholder="选择级别"
            >
              <el-option
                v-for="item in keyNodesLevelList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              clearable
              v-model="queryModal.nodeName"
              placeholder="节点名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData">查询</el-button>
          </el-form-item>
        </el-row>
        <!-- <el-row>
          <el-form-item>
            <el-button type="primary" v-has="'drill:add'" @click="handleAdd"
              >导出</el-button
            >
          </el-form-item>
        </el-row> -->
      </el-form>
    </div>

    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="工点"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="nodeTypeName"
          label="类别"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="nodeName"
          label="关键节点名称"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="nodeLevelName"
          label="级别"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column align="center" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="handleView(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      width="50%"
      class="editDialog details-dialog"
      :visible.sync="KeyDetailsDia"
      v-if="KeyDetailsDia"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <KeyDetails :rowData="rowData"></KeyDetails>
    </el-dialog>
  </div>
</template>

<script>
import KeyDetails from "./KeyNodeDialog/keyDetails.vue";
import { keyNodes } from "@/api/riskManage";
import { getDictData } from "@/api/dict";
export default {
  components: {
    KeyDetails
  },
  data() {
    return {
      queryModal: {
        siteUuidList: [],
        warnTypeCode: "",
        warnGradeCode: "",
        warnName: "",
        linsiteIds: [],
        isEliminate: false
      },
      loading: false,
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      tableData: [
        {
          lineName: "三号线东延",
          siteName: "海傍站",
          warnTypeName: "盾构",
          warnName: "盾构开仓",
          riskDradeName: "一类关键节点"
        }
      ],
      warnTypeList: [],
      KeyDetailsDia: false,
      rowData: {},
      keyNodeTypeList: [],
      keyNodesLevelList: []
    };
  },
  created() {
    this.getLineTree();
    this.getDict();
    this.getData();
  },
  methods: {
    async getLineTree() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.lineSiteData = JSON.parse(JSON.stringify(lineSiteData));
      }
    },
    getDict() {
      getDictData("keyNodesType").then(res => {
        if (res.code == 0) {
          this.keyNodeTypeList = res.data;
        }
      });
      getDictData("keyNodesLevel").then(res => {
        if (res.code == 0) {
          this.keyNodesLevelList = res.data;
        }
      });
    },
    // 级联选择器事件
    handleLineSiteChange(record) {
      console.log(record);
      this.queryModal.siteUuidList = [];
      for (let item of record) {
        this.queryModal.siteUuidList.push(item[item.length - 1]);
      }
      console.log(this.queryModal.siteUuidList);
    },
    getData() {
      this.loading = true;
      let params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.queryModal
      };
      keyNodes(params).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
          this.loading = false;
        }
      });
    },
    handleAdd() {
      this.title = "发布预警";
      this.dialogVisible = true;
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getData();
    },
    handleView(row) {
      console.log(row, "row");
      this.rowData = { ...row };
      this.KeyDetailsDia = true;
    },
    CloseData() {
      this.dialogVisible = false;
      this.getData();
    },
    handleClose() {
      this.KeyDetailsDia = false;
    },
    showFileList(obj) {
      this.fileDialog = true;
      this.fileData = obj.riskWarnFileList;
      console.log(this.fileData);
    },
    handleFileView(row) {
      const link = document.createElement("a");
      link.href = row.fileUrl;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
    handlefileClose() {
      this.fileDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .details-dialog > .el-dialog {
  > .el-dialog__header {
    padding: 0 !important;
  }
}
::v-deep .editDialog .el-dialog .el-dialog__body {
  padding-left: 20px;
}
</style>
