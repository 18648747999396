<template>
  <systemModal ref="formModal" :title="title" :showFooter="false" width="50%" @modalClose="handleClose">
    <el-form
      ref="form"
      :model="form"
      label-width="130px"
      size="mini"
      width="100%"
      v-loading="loading"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="事件类型：">
            <el-input
              v-model="form.typeName"
              readonly
              placeholder="事件类型"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发生时间：">
            <el-input
              v-model="form.created"
              readonly
              placeholder="发生时间"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="上报人：">
            <el-input
              v-model="form.publisherName"
              readonly
              placeholder="上报人"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="负责人：">
            <el-input
              v-model="form.chargeName"
              readonly
              placeholder="负责人"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="地理位置：">
            <el-input
              v-model="form.xycoors"
              readonly
              placeholder="地理位置"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工程项目名称：">
            <el-input
              v-model="form.siteName"
              readonly
              placeholder="工程项目名称"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="施工单位：">
            <el-input
              v-model="form.constructionUnitName"
              readonly
              placeholder="施工单位"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="监理单位：">
            <el-input
              v-model="form.supervisorUnitName"
              readonly
              placeholder="监理单位"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="事件经过：">
        <el-input
          readonly
          type="textarea"
          resize="none"
          placeholder="请输入作业内容、事发情形等"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="form.historyRecord"
        ></el-input>
      </el-form-item>
      <el-form-item label="伤亡情况：">
        <el-input
          readonly
          type="textarea"
          resize="none"
          placeholder="请输入人员伤亡、直接经济损失和影响范围"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="form.casualties"
        ></el-input>
      </el-form-item>
      <el-form-item label="处置情况：">
        <el-input
          readonly
          type="textarea"
          resize="none"
          placeholder="请输入应急救援、已采取和拟采取的措施等"
          v-model="form.disposal"
        ></el-input>
      </el-form-item>
      <el-form-item label="照片或附件：">
        <a
          v-for="(item, index) in imgs"
          :key="index"
          :href="item.url"
          target="_blank"
          style="color:#409EFF;display:block;"
          >{{ item.originalName }}</a
        >
      </el-form-item>
    </el-form>
    <div class="w-full flex justify-center">
      <el-button
        type="primary"
        @click="onSubmit"
        >事件确认</el-button
      >
    </div>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { detailsList, detailsConfig } from "@/api/response";
import { updateHandleState } from "@/api/notic";

export default {
  components: {
    systemModal
  },
  data() {
    return {
      title: "事件快报",
      form: {},
      imgs: [],
      eventData: {},
      loading: false,
      id: ""
    };
  },
  mounted() {},
  methods: {
    handleOpen(record) {
      this.id = record.id;
      this.eventData = record;
      this.getInfo(record.eventId);
      this.$refs.formModal.handleOpen();
    },
    handleClose() {},
    getInfo() {
      let params = {
        id: this.eventData.eventId,
      };
      detailsList(params).then((res) => {
        if (res.code == 0) {
          this.form = res.data;
          if (res.data.processList[0].enclosure) {
            try {
              this.imgs = JSON.parse(res.data.processList[0].enclosure);
            } catch (error) {
              this.imgs = [];
            }
          }
        }
      });
    },
    onSubmit() {
      this.loading = true;
      let params = {
        uuid: this.form.uuid,
      };
      detailsConfig(params).then((res) => {
        if (res.code == 0) {
          const data = {
            id: this.id,
            executorId: JSON.parse(localStorage.getItem("user")).uuid,
            executorName: JSON.parse(localStorage.getItem("user")).realName,
            type: 1
          };
          updateHandleState(data).then(res => {
            if (res.code == 0) {
              this.$message.success("操作成功！");
              this.$refs.formModal.handleClose();
              this.loading = false;
              this.$emit("close");
            }
          });
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}
::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
::v-deep .el-dialog__body {
  padding: 30px !important;
}
</style>
