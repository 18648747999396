<template>
  <div>
    <div class="riskDialogTitle">风险基本信息</div>
    <el-form ref="ruleForm" :model="ruleForm" label-width="130px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="线路分部工点名称" prop="lineSite">
            <el-input readonly v-model="ruleForm.siteName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="风险名称" prop="riskName">
            <el-input readonly v-model="ruleForm.riskName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="风险控制等级" prop="riskLevelName">
            <el-input readonly v-model="ruleForm.riskLevelName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="安全情况" prop="safeStatus">
            <el-input readonly v-model="ruleForm.safeStatus"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="isShow == true">
        <el-col :span="12">
          <el-form-item label="风险类别" prop="riskTypeName">
            <el-input readonly v-model="ruleForm.riskTypeName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="风险子类" prop="riskSubTypeName">
            <el-input readonly v-model="ruleForm.riskSubTypeName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="isShow == true">
        <el-col :span="12">
          <el-form-item label="风险预计开始时间" prop="estimateStartDay">
            <el-input readonly v-model="ruleForm.estimateStartDay"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="风险预计结束时间" prop="estimateEndDay">
            <el-input readonly v-model="ruleForm.estimateEndDay"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="isShow == true">
        <el-col :span="12">
          <el-form-item label="实际开始时间" prop="realStartDay">
            <el-input readonly v-model="ruleForm.realStartDay"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="实际结束时间" prop="realEndDay">
            <el-input readonly v-model="ruleForm.realEndDay"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="isShow == true">
        <el-col :span="12">
          <el-form-item label="原始风险等级" prop="originalRiskLevelCode">
            <el-input
              readonly
              v-model="ruleForm.originalRiskLevelCode"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="剩余风险等级" prop="restRiskLevelCode">
            <el-input readonly v-model="ruleForm.restRiskLevelCode"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="isShow == true">
        <el-col :span="12">
          <el-form-item label="经度" prop="lat">
            <el-input readonly v-model="ruleForm.lat"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="纬度" prop="lon">
            <el-input readonly v-model="ruleForm.lon"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="里程范围" prop="mileageRange">
            <el-input
              readonly
              type="input"
              v-model="ruleForm.mileageRange"
              placeholder="请输入里程范围"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="PostInfCs-input" v-if="isShow == true">
        <el-col :span="12">
          <el-form-item label="关联工法" prop="relationConstructMethodName">
            <el-input
              v-model="ruleForm.relationConstructMethodName"
              readonly
              placeholder="请输入关联工法"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="施工单位联系人" prop="constructPerson">
            <el-input
              v-model="ruleForm.constructPerson"
              readonly
              placeholder="请输入施工单位联系人"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="设计单位联系人" prop="designPerson">
            <el-input
              v-model="ruleForm.designPerson"
              readonly
              placeholder="设计单位联系人"
              style="width: 100%"
            >
            </el-input> </el-form-item
          ><el-form-item label="监理单位联系人" prop="monitorPerson">
            <el-input
              v-model="ruleForm.monitorPerson"
              readonly
              placeholder="请输入监理单位联系人"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="建设单位联系人" prop="buildPerson">
            <el-input
              v-model="ruleForm.buildPerson"
              readonly
              placeholder="请输入建设单位联系人"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="勘察单位联系人" prop="surveyPerson">
            <el-input
              v-model="ruleForm.surveyPerson"
              readonly
              placeholder="请输入勘察单位联系人"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="第三方监测单位联系人" prop="thirdPerson">
            <el-input
              v-model="ruleForm.thirdPerson"
              readonly
              placeholder="请输入第三方监测单位联系人"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="分部工程" prop="subprojectName">
            <el-input
              v-model="ruleForm.subprojectName"
              readonly
              placeholder="请输入分部工程"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="constructPhone">
            <el-input
              v-model="ruleForm.constructPhone"
              readonly
              placeholder="请输入联系电话"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="designPhone">
            <el-input
              v-model="ruleForm.designPhone"
              readonly
              placeholder="请输入联系电话"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="monitorPhone">
            <el-input
              v-model="ruleForm.monitorPhone"
              readonly
              placeholder="请输入联系电话"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="buildPhone">
            <el-input
              v-model="ruleForm.buildPhone"
              readonly
              placeholder="请输入联系电话"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="surveyPhone">
            <el-input
              v-model="ruleForm.surveyPhone"
              readonly
              placeholder="请输入联系电话"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="thirdPhone">
            <el-input
              v-model="ruleForm.thirdPhone"
              readonly
              placeholder="请输入联系电话"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="PostInfCs-input" v-if="isShow == true">
        <el-col>
          <el-form-item label="具体风险描述" prop="riskDescribe">
            <el-input
              type="textarea"
              readonly
              resize="none"
              v-model="ruleForm.riskDescribe"
              placeholder="请输入具体风险描述"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <p @click="handleEx" class="zhankaiBtn">
        {{ isShow == true ? "点击收起" : "点击展开"
        }}<i
          class="el-icon-d-arrow-left"
          :class="isShow == true ? 'upArrow' : ''"
        ></i>
      </p>
    </el-form>
  </div>
</template>

<script>
import { findByUuid } from "@/api/riskManage";

// 字典
import { getDictData } from "@/api/dict";
export default {
  props: {
    riskDeafUuid: {
      default: "",
    },
    frontUuid: {
      default: "",
    },
  },
  data() {
    return {
      ruleForm: {
        lineSite: "",
      },
      isShow: false,
    };
  },
  created() {
    this.getDetailData();
  },
  methods: {
    handleEx() {
      this.isShow = !this.isShow;
    },
    getDetailData() {
      findByUuid(this.riskDeafUuid).then((res) => {
        if (res.code == 0) {
          this.ruleForm = res.data;
          getDictData("safeStatusType").then((res) => {
            this.ruleForm.safeStatus = res.data.find(
              (i) => i.code == this.ruleForm.safeStatus
            ).label;
            console.log(this.ruleForm.safeStatus);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.riskDialogTitle {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
}
.zhankaiBtn {
  width: 100%;
  padding: 3px 0;
  border: 1px solid #bdbdbd;
  margin: 8px 0 15px;
  text-align: center;
  cursor: pointer;
  .el-icon-d-arrow-left {
    transform: rotate(-90deg);
    margin-left: 5px;
  }
  .upArrow {
    transform: rotate(90deg);
  }
}
</style>
