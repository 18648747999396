<template>
  <!-- 三防工作简报的  建管部 汇总数据提交   -->
  <div>
    <div style="margin-bottom: 20px">
      <el-radio-group
        size="mini"
        v-model="degree"
        v-for="(item, index) in manyTimes"
        :key="index"
        @change="degreeChange"
      >
        <el-radio-button :label="`第` + item + '次'"></el-radio-button>
      </el-radio-group>
    </div>
    <el-descriptions title="" style="width: 70%">
      <el-descriptions-item label="汇总单位">{{
        user.departmentName
      }}</el-descriptions-item>

      <!-- <el-descriptions-item label="汇总次数"
        >第{{ cuns }}次</el-descriptions-item
      > -->
    </el-descriptions>

    <el-table
      ref="tableData"
      :data="tableData"
      id="tableData"
      border
      v-loading="tableLoading"
      :row-style="{ height: '30px' }"
      style="width: 100%"
      height="430"
    >
      <el-table-column
        align="center"
        type="index"
        label="序号"
        width="100"
      ></el-table-column>
      <el-table-column label="总包部" prop="name" align="center">
      </el-table-column>
      <el-table-column label="填报次数" align="center" prop="times">
      </el-table-column>
      <el-table-column prop="performance" label="工点完成情况" align="center">
      </el-table-column>
      <el-table-column prop="deadlineDate" label="截止时间" align="center">
      </el-table-column>
      <el-table-column prop="fillDate" label="填报时间" align="center">
      </el-table-column>
      <el-table-column prop="fillUserName" label="填报人员" align="center">
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        :formatter="formatState"
      >
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <!-- <span v-if="scope.row.status == 4"> -->
          <el-button @click="handelDtile(scope.row)" type="text" size="small">
            查看
          </el-button>
          <el-button
            @click="handelReject(scope.row)"
            type="text"
            size="small"
            v-if="scope.row.status != 5"
          >
            驳回
          </el-button>
          <!-- </span>
          <span v-else>/</span> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center mt-8">
      <el-button
        type="primary"
        :disabled="buttonState == null || buttonState == false"
        @click="submit"
        >提交</el-button
      >
    </div>

    <!-- 查看详情 -->
    <briefingJGView ref="briefingJGView"></briefingJGView>

    <el-dialog
      title="驳回"
      v-if="rejectVisibe"
      :visible.sync="rejectVisibe"
      width="30%"
      :before-close="checkClose"
      center
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-row>
        <el-col :span="3"
          ><span><span style="color: #F56C6C;">*</span>原因:</span></el-col
        >
        <el-col :span="21">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="驳回原因"
            v-model="rejectText"
          >
          </el-input
        ></el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleRejectSubmit">驳回 </el-button>
      </span>

      <!-- <checkPage
        ref="checkmell"
        @checkClose="checkClose"           
        :checkId="checkId"
        :checkName="checkName"
        :cuns="cuns"
        :tableDatas="tableDatas"
      ></checkPage> -->
    </el-dialog>
  </div>
</template>

<script>
import {
  // getSelection,
  getRes,
  listSZongBaoDetailSummaryInfo,
  getMaxTimes,
  approvalBriefing,
} from "@/api/response";
import checkPage from "./checkPage.vue";
import briefingJGView from "./briefingJGView.vue";

export default {
  props: {
    supervisionUuid: {
      type: String,
    },
    activees: {
      type: Object,
      default: {},
    },
  },
  components: {
    checkPage,
    briefingJGView,
  },
  data() {
    return {
      times: 1,
      manyTimes: 1,
      degree: "第1次",
      tableData: [],
      tableTotalData: [],
      tableLoading: false,
      checkDialog: false,
      formData: {
        emergency: "",
      },
      formInline: {
        responseType: "",
        responseLevel: "",
      },
      // leveloptions: [],
      checkId: "", //当前行的id
      checkName: "", //事件id
      // buildDepartmentResponseId: "",
      supervisionName: "",
      cuns: 1,
      // exportDialog: false,
      checkboxTimes: [],
      exloading: false,
      tableDatas: [],
      departmentName: {},
      rejectVisibe: false,
      rejectText: "",
      rowReject: {},
      buttonState: false,
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {
    // this.getData();
    this.getTimes();
    this.getTableData();
  },
  methods: {
    getTimes() {
      getMaxTimes(this.activees.responseRecordId).then((result) => {
        this.manyTimes = result.data;
      });
    },
    // 获取表格数据
    getTableData() {
      this.tableLoading = true;
      let params = {
        responseId: this.activees.responseRecordId,
        times: this.times,
        supervisionName: "",
        supervisionUuid: this.supervisionUuid,
      };
      listSZongBaoDetailSummaryInfo(params)
        .then((res) => {
          this.tableLoading = false;
          if (res.code == 0) {
            this.buttonState = res.data.buttonState;
            if (
              res.data.summaryInfoList &&
              res.data.summaryInfoList.length > 0
            ) {
              this.tableData = res.data.summaryInfoList;
            }
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    formatState: function(row, column, cellValue) {
      console.log(row, column, cellValue);
      switch (cellValue) {
        case 0:
          return "待填报";
        case 1:
          return "总包已驳回";
        case 2:
          return "建管部已驳回";
        case 3:
          return "待总包审批";
        case 4:
          return "待建管部确认";
        case 5:
          return "已完成";
      }
    },
    degreeChange(val) {
      var arr = val.replace(/\D/g, "");
      this.cuns = arr;
      if (arr != this.times) {
        this.times = arr;
        this.tableData = [];
        this.getTableData();
      }
    },
    // 查看
    handelDtile(val) {
      this.$refs.briefingJGView.handleFilling(val);
    },
    // 驳回
    handelReject(val) {
      this.rowReject = val;
      this.rejectVisibe = true;
    },
    handleRejectSubmit() {
      if (this.rejectText == "") {
        return this.$message.warning("请填写驳回原因");
      } else {
        let parmsArr = [];
        let parms = {
          opinion: this.rejectText,
          currentId: this.rowReject.id,
          status: 2, //status 1为通过 2为驳回
          preventionType: 2, // 1工点 2 总包
          responseId: this.rowReject.responseId,
        };
        parmsArr.push(parms);
        approvalBriefing({ threePreventionWorks: parmsArr }).then((res) => {
          if (res.code == 0) {
            console.log(res.data);
            this.rejectVisibe = false;
            this.$message.success("操作成功");
            this.getTableData();
          } else {
            this.$message.error("操作失败!" + res.message);
          }
        });
      }
    },
    checkClose() {
      this.rejectVisibe = false;
    },

    submit() {
      let parmsArr = [];
      this.tableData.forEach((item) => {
        let parms = {
          currentId: item.id,
          status: 1, //status 1为通过 2为驳回
          preventionType: 2, // 1工点 2 总包
          responseId: this.rowReject.responseId,
        };
        parmsArr.push(parms);
      });
      approvalBriefing({ threePreventionWorks: parmsArr }).then((res) => {
        if (res.code == 0) {
          console.log(res.data);
          this.$emit("submit");
          this.$message.success("操作成功!");
        } else {
          this.$message.error("操作失败!" + res.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-table::before {
  height: 0;
}
::v-deep .el-table {
  height: 400px;
  // overflow-y: auto;
}
.content {
  display: flex;
  justify-content: space-between;
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: #fff !important;
  border-radius: 7px;
}
/*定义滑块颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}

//去掉固定列下方的横线
::v-deep .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: transparent;
}
//去掉固定列阴影
::v-deep .el-table__fixed-right {
  // box-shadow: none;
  height: 100% !important;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}
</style>
