<template>
  <div class="page-container bg-white p-4 h-full relative">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="线路工点" prop="lineSite">
            <el-cascader
              v-model="queryModal.lineSite"
              clearable
              :options="treeData"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="handleLineSiteChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="状态">
            <el-select
              v-model="queryModal.state"
              clearable
              placeholder="请选择状态"
            >
              <el-option
                v-for="item of dict.state"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称">
            <el-input
              v-model="queryModal.label"
              clearable
              placeholder="输入设备名称"
            ></el-input>
          </el-form-item>
           <el-form-item label="规格型号">
            <el-input
              v-model="queryModal.modelName"
              clearable
              placeholder="输入规格型号"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button
            type="primary"
            @click="handleTemplateDownLoad"
            v-has="'equipment:download'"
            >下载导入模板</el-button
          >
          <el-button
            type="primary"
            @click="handleImport"
            v-has="'equipment:therimport'"
            >导入</el-button
          >
          <!-- <el-button
            type="primary"
            @click="handleRecord"
            v-has="'equipment:inventory'"
            >盘点记录</el-button
          > -->
          <el-button
            type="primary"
            @click="handleExport"
            v-has="'equipment:export'"
            >导出</el-button
          >
          <el-button type="primary" @click="handleAdd" v-has="'equipment:add'"
            >新增</el-button
          >
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="loading"
        @cell-mouse-enter="handleCellMouseEnter"
        @cell-mouse-leave="handleCellMouseLeave"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <!-- <el-table-column
          align="center"
          prop="superviseName"
          label="所属建管部"
          :show-overflow-tooltip="true"
        ></el-table-column> -->
        <el-table-column
          align="center"
          prop="lineName"
          label="线路名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="branchName"
          label="分部名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="工点名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="label"
          label="设备名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="model"
          label="规格/型号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="unit"
          label="计量单位"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="count"
          label="现存数量"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="standardCount"
          label="标准数量"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="usages"
          label="用途"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ JSON.parse(scope.row.usages).join(",") }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="stateLabel"
          label="状态"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="remark"
          label="备注"
        ></el-table-column>
        <el-table-column align="center" label="操作" width="300px">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleOut(scope.row)"
              v-has="'equipment:out'"
              class="mr-4"
              >出场</el-button
            >
            <el-button
              type="text"
              @click="handleIn(scope.row)"
              v-has="'equipment:in'"
              class="mr-4"
              >进场</el-button
            >
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-has="'equipment:edit'"
              class="mr-4"
              >编辑</el-button
            >
            <el-popover placement="left" width="100" trigger="click">
              <el-button
                type="text"
                @click="handleHistory(scope.row)"
                v-has="'equipment:historical'"
                class="mr-4"
                >历史记录</el-button
              >

              <el-button
                @click="handleDel(scope.row)"
                type="text"
                v-has="'equipment:delete'"
                >删除</el-button
              >
              <el-button slot="reference" type="text" style="margin-left:10px"
                >更多</el-button
              >
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <RecordModal ref="recordModal"></RecordModal>
    <MaterialForm
      ref="modalForm"
      :lineSiteData="treeData"
      :type="126"
      @formSubmit="handleFormSubmit"
    ></MaterialForm>
    <HistoryRecord
      ref="modalHistory"
      :lineSiteData="treeData"
      :type="126"
    ></HistoryRecord>
    <EquipmentInOut
      ref="modalInOutForm"
      :lineSiteData="treeData"
      @inOutSubmit="handleInOutSubmit"
    ></EquipmentInOut>
    <FileImport
      ref="fileImport"
      @importSub="handleImportSub"
      :type="126"
      :lineSiteData="treeData"
    ></FileImport>
  </div>
</template>

<script>
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import {
  getMaterialsPage,
  delMaterials,
  exportEquipmentFile,
} from "@/api/eres";
import RecordModal from "./modules/recordModal";
import MaterialForm from "./modules/materialForm.vue";
import HistoryRecord from "./modules/historyRecord.vue";
import EquipmentInOut from "./modules/equipmentInOut.vue";
import FileImport from "./modules/fileImport.vue";
export default {
  components: {
    RecordModal,
    MaterialForm,
    HistoryRecord,
    EquipmentInOut,
    FileImport,
  },
  data() {
    return {
      queryModal: {
        lineSite: [],
        label: "",
        state: "",
        modelNo: "",
        siteIds: [],
        type: "126",
      },
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "id",
        multiple: true,
      },
      tableData: [],
      loading: false,
      usageCache: "",
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      dict: {
        model: [],
        usages: [],
        state: [
          {
            label: "正常",
            value: 1,
          },
          {
            label: "异常",
            value: 2,
          },
        ],
      },
    };
  },
  mounted() {
    this.getLineSiteTreeData();
    this.loadData();
    this.getDictData("物资用途", this.dict.usages);
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      console.log(row, rowIndex, "rowIndex");
      if (row.stateLabel == "异常") {
        return "warning-row";
      }
      // return "";
    },
    handleLineSiteChange(val) {
      this.queryModal.siteIds = [];
      for (let item of val) {
        this.queryModal.siteIds.push(item[item.length - 1]);
      }
    },
    // 顶部查询
    handleSearch() {
      this.page.pageNo = 1;
      this.loadData();
    },
    // 导入模板下载
    async handleTemplateDownLoad() {
      let res = await exportEquipmentFile();
      if (res) {
        let fileName = "设备导入模板"; //这个是需要导出的表格的名字
        let blob = new Blob([res], { type: "application/vnd.ms-excel" }); //实例一个blob对象,res.data是后台反馈的二进制数据,type是需要下载的文件的类型此处为表格
        let link = document.createElement("a"); //创建一个a标签dom节点
        link.download = fileName; //下载文件的名字
        link.href = URL.createObjectURL(blob); //下载文件的地址
        link.style.display = "none"; //a标签默默无闻
        document.body.appendChild(link); //将a标签加入到dom中
        link.click(); //添加点击事件进行文件下载
        // 下载完成可以退场
        URL.revokeObjectURL(link.href); //销毁下载地址
        document.body.removeChild(link); //将a标签从dom中移除
      }
    },
    // 导入
    handleImport() {
      this.$refs.fileImport.handleImport();
    },
    // 导出
    handleExport() {},
    // 新增
    handleAdd() {
      this.$refs.modalForm.handleAdd();
    },
    // 出库
    handleOut(record) {
      this.$refs.modalInOutForm.handleInOut(1, record);
    },
    // 入库
    handleIn(record) {
      this.$refs.modalInOutForm.handleInOut(2, record);
    },
    // 修改
    handleEdit(record) {
      this.$refs.modalForm.handleAdd(record);
    },
    // 删除
    handleDel(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delMaterials(record.id).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadData();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    // 历史记录
    handleHistory(record) {
      this.$refs.modalHistory.handleHistory(record);
    },
    // 盘点记录
    handleRecord() {
      this.$refs.recordModal.handleModalShow();
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    // 表单提交
    handleFormSubmit() {
      this.loadData();
    },
    handleInOutSubmit() {
      this.loadData();
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    // 加载表格数据
    loadData() {
      this.tableData = [];
      this.loading = true;
      const params = {
        materialType: 1,
        siteIds: this.queryModal.siteIds,
        type: this.queryModal.type,
        state: this.queryModal.state,
        modelNo: this.queryModal.modelNo,
        modelName:this.queryModal.modelName,
        label: this.queryModal.label,
        page: this.page.pageNo,
        size: this.page.pageSize,
      };
      getMaterialsPage(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          this.page.pageNo = res.data.current;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.total;
          this.page.count = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },
    // 鼠标滑入单元格
    handleCellMouseEnter(row, column, cell, event) {
      if (column.property == "usages") {
        cell.querySelector(".cell").style.cursor = "pointer";
        console.log(row, column, cell, event);
        const labelArr = [];
        const usageStr = cell.querySelector(".cell").innerHTML;
        this.usageCache = usageStr;
        let usageArr = [];
        if (usageStr.length) {
          usageArr = usageStr.split("");
        }
        for (let usage of usageArr) {
          const tempObj = this.dict.usages.find((item) => item.label == usage);
          if (tempObj) {
            labelArr.push(tempObj);
          }
        }
        let str = "";
        for (let item of labelArr) {
          str += `<li>${item.label}.${item.intro}</li>`;
        }
        cell.querySelector(".cell").innerHTML = `<ul>${str}</ul>`;
      }
    },
    // 鼠标移出单元格
    handleCellMouseLeave(row, column, cell, event) {
      if (column.property == "usages") {
        console.log(row, column, cell, event);
        cell.querySelector(".cell").innerHTML = this.usageCache;
      }
    },
    // 导入成功回调
    handleImportSub() {
      this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 11;
}

.page-container {
  .handle-wrapper {
    min-width: 240px;
    max-width: 30vw;
    height: 60vh;
    position: absolute;
    top: 10%;
    left: 20px;
    background: rgba(255, 255, 255, 1);
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
    border-radius: 0px 20px 20px 0px;
    padding: 20px;
    z-index: 6;

    .tree-wrapper {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }

    .toggle-btn {
      width: 28px;
      height: 240px;
      position: absolute;
      right: 0px;
      top: 50%;
      margin-top: -120px;
      cursor: pointer;
      background-image: url();
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }

    .toggle-btn-close {
      transform: rotate(180deg);
    }

    .toggle-btn-open {
      transform: rotate(0deg);
    }
  }

  .handle-wrapper-hide {
    left: -212px;
    background: transparent;
    box-shadow: none;
  }

  .handle-wrapper-show {
    left: 0;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #43a5e9;
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
  }
}

::v-deep .el-table .warning-row {
  background-color: oldlace !important;
  // background-color: red !important;
}
</style>
