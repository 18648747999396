<template>
  <div>
    <el-table :data="tabledata" border style="width: 100%; margin-top: 10px">
      <el-table-column
        prop="lineName,siteName"
        label="线路-工点"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.lineName }}-{{ scope.row.siteName }}
        </template>
      </el-table-column>
      <!-- prop="checkItemContent" -->
      <el-table-column
        prop="hiddenQuestion"
        label="问题详情"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="typeName"
        label="检查类型"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="checkTime"
        label="问题上报时间"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="correctiveTime"
        label="问题整改时间"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="correctiveUser.name"
        label="整改人员"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="correctiveApproveStatus"
        label="状态"
        show-overflow-tooltip
        align="center"
        :formatter="correctiveApproveStatusFormatter"
      >
      </el-table-column>
      <!-- <el-table-column
          prop="correctiveApproveStatus"
          label="备注"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column> -->
      <!-- <el-table-column label="操作" align="center" width="200px">
        <template #default="scope">
          <div class="flex justify-around cursor-pointer">
            <el-button
              v-has="'corrective:add'"
              type="text"
              size="small"
              @click="opUpdata(scope.row)"
              v-if="scope.row.buttonStatus != 3"
              >整改
            </el-button>
            <el-button
              v-has="'corrective:approve'"
              type="text"
              size="small"
              @click="approve(scope.row)"
              v-if="scope.row.buttonStatus == 1"
              >审批
            </el-button>

            <el-button type="text" size="small" @click="userInfo(scope.row)"
              >查看
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="getHistoryData(scope.row)"
              >历史操作
            </el-button>
          </div>
        </template>
      </el-table-column> -->
    </el-table>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        :total="page.count"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        layout=" prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { questionCountPage } from "@/api/responseTem";
export default {
  components: {},
  props: {
    paramsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      page: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
      },
      tabledata: [],
    };
  },
  watch: {},
  mounted() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      //       dateType	时间类型 1年 2季 3 月		true
      // integer
      // endDate	结束时间		false
      // string
      // lineUuid	綫路id		false
      // string
      // pageNum	页码 ,示例值(1)		false
      // integer
      // pageSize	分页长度 ,示例值(10)		false
      // integer
      // responseId	响应id		false
      // integer
      // startDate	开始时间		false
      // string
      // status	0未完成 1已完成		false
      // integer
      // type	类型字典id
      console.log(this.paramsData.responseId);
      let params = {
        endDate: "",
        lineUuid: this.paramsData.lineUuid,
        startDate: "",
        type: this.paramsData.type,
        dateType: this.paramsData.dateType,
        status: this.paramsData.status,
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        responseId: this.paramsData.responseId,
      };
      questionCountPage(params).then((res) => {
        if (res.code == 0) {
          this.tabledata = res.data.list;
          this.page.count = res.data.total;
        }
      });
    },
    //页数
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.onSearch();
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.onSearch();
    },
    correctiveApproveStatusFormatter(row) {
      return row.correctiveApproveStatus == 0
        ? "待整改"
        : row.correctiveApproveStatus == 1
        ? "待监理审批"
        : row.correctiveApproveStatus == 2
        ? "审批不通过"
        : row.correctiveApproveStatus == 3
        ? "完成"
        : "";
    },
  },
};
</script>
    
  <style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 700px;
  overflow: auto;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
::v-deep .el-table:before {
  height: 0px;
}
.stCard {
  margin: 0 20px 10px 0;
  padding: 20px 20px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.cenCard {
  padding: 20px 20px;
  width: 24%;
  height: 100px;
}
.cenNum {
  font-size: 28px;
  font-family: Arial;
  font-weight: bold;
  color: #081742;
}
</style>
    