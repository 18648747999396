<template>
  <div class="page-container bg-white p-4 h-full relative">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModel" class="flex justify-between">
        <el-row>
          <el-form-item label="工点">
            <el-cascader
              v-model="queryModel.lineSite"
              clearable
              :options="treeData"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="handleLineSiteChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="类型">
            <el-select
              v-model="queryModel.type"
              placeholder="请选择类型"
              clearable
            >
              <el-option
                v-for="item of dict.type"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select
              v-model="queryModel.status"
              placeholder="请选择状态"
              clearable
            >
              <el-option
                v-for="item of dict.status"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称">
            <el-input
              v-model="queryModel.name"
              placeholder="请输入名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="handleAdd">新增</el-button>
          <el-button type="primary" @click="handleExport">导出</el-button>
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="摄像头名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="所属工点"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="typeName"
          label="类型"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="statusName"
          label="状态"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleDetail(scope.row)" class="mr-4"
              >详情</el-button
            >
            <el-button type="text" @click="handleEdit(scope.row)" class="mr-4"
              >编辑</el-button
            >

            <el-button @click="handleDel(scope.row)" type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <MonitorForm
      ref="modalForm"
      @formSubmit="handleFormSubmit"
      :treeData="treeData"
    ></MonitorForm>
    <MonitorDetail ref="modalDetail"></MonitorDetail>
  </div>
</template>

<script>
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import MonitorForm from "./modules/monitorForm.vue";
import MonitorDetail from "./modules/monitorDetail.vue";
import { getVideoPage, deleteVideo } from "@/api/map";
export default {
  components: {
    MonitorForm,
    MonitorDetail,
  },
  data() {
    return {
      queryModel: {
        lineSite: [],
        siteId: "",
        status: "",
        name: "",
        type: "",
      },
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "id",
      },
      tableData: [],
      loading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      dict: {
        type: [],
        status: [],
      },
    };
  },
  mounted() {
    this.loadData();
    this.getLineSiteTreeData();
    this.getDictData("摄像头类型", this.dict.type);
    this.getDictData("摄像头状态", this.dict.status);
  },
  methods: {
    // 顶部线路工点树
    handleLineSiteChange(val) {
      this.queryModel.siteId = val[val.length - 1];
    },
    // 顶部查询
    handleSearch() {
      this.loadData();
    },
    // 导出
    handleExport() {},
    // 新增
    handleAdd() {
      this.$refs.modalForm.handleAdd();
    },
    // 查看详情
    handleDetail(record) {
      this.$refs.modalDetail.handleDetail(record);
    },
    // 修改
    handleEdit(record) {
      this.$refs.modalForm.handleAdd(record);
    },
    // 删除
    handleDel(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteVideo(record.id).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadData();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    // 表单提交
    handleFormSubmit() {
      this.loadData();
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    // 加载表格数据
    loadData() {
      this.loading = true;
      const params = {
        siteId: this.queryModel.siteId || null,
        status: this.queryModel.status || null,
        name: this.queryModel.name || null,
        type: this.queryModel.type || null,
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
      };
      getVideoPage(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.list;
          this.page.pageNo = res.data.pageNum;
          this.page.pageSize = res.data.pageSize;
          this.page.total = res.data.total;
          this.page.count = res.data.pages;
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
