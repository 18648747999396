<template>
  <div class="page-container bg-white p-4 h-full relative flex flex-col">
    <div class="search-wrapper" style="height: 50px">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="资源名称">
            <el-input
              v-model="queryModal.name"
              placeholder="输入资源名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="handleAdd">新增</el-button>
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper flex-1">
      <el-table
        :data="tableData"
        style="width: 100%"
        height="100%"
        v-loading="loading"
        row-key="id"
        :tree-props="{ children: 'sub', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column prop="resourceName" label="资源名称"></el-table-column>
        <el-table-column
          align="center"
          prop="type"
          label="类型"
          :formatter="typeLabel"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="sorted"
          label="排序"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="resourcePath"
          label="路径"
        ></el-table-column>
        <el-table-column align="center" label="操作" width="240">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)" class="mr-4"
              >编辑</el-button
            >

            <el-button type="text" @click="handleDel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="form-wrapper">
      <el-drawer
        :title="formTitle"
        :visible.sync="drawerVisible"
        direction="rtl"
        :before-close="handleClose"
        size="40%"
      >
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="120px"
          v-loading="formLoading"
        >
          <el-form-item label="资源类型" prop="type">
            <el-radio-group v-model="form.type">
              <el-radio :label="0">菜单</el-radio>
              <el-radio :label="1">按钮</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="主题样式" prop="category">
            <el-radio-group v-model="form.category">
              <el-radio :label="0">浅色</el-radio>
              <el-radio :label="1">深色</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="资源名称" prop="resourceName">
            <el-input v-model="form.resourceName"></el-input>
          </el-form-item>
          <!-- <el-form-item label="所属微服务" prop="microServer">
            <el-select
              v-model="form.microServer"
              placeholder="请选择所属微服务"
            >
              <el-option v-for="(item, index) of microServerList" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="上级菜单" prop="parentId">
            <!-- <el-select v-model="form.parentId" filterable placeholder="请选择上级菜单">
              <el-option
                v-for="item of resourceData"
                :key="item.id"
                :label="item.resourceName"
                :value="item.id"
              ></el-option>
            </el-select> -->
            <!-- <el-tree ref="tree" :data="resourceTree" :props="props" node-key="id" :highlight-current="true" :default-expanded-keys="expandedKeys"></el-tree> -->
            <el-select
              v-model="selectTree.label"
              placeholder="请选择"
              clearable
              @clear="handleClear"
              ref="selectUpResId"
            >
              <el-option
                hidden
                :key="selectTree.value"
                :value="selectTree.value"
                :label="selectTree.label"
              ></el-option>
              <el-tree
                :data="resourceTree"
                :props="props"
                :expand-on-click-node="false"
                :check-on-click-node="true"
                @node-click="handleResourceNodeClick"
              >
              </el-tree>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="form.type == 0 ? '资源路径' : '资源标识'"
            prop="resourcePath"
          >
            <el-input v-model="form.resourcePath"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sorted">
            <el-input-number
              v-model="form.sorted"
              controls-position="right"
              @change="handleChange"
              :min="0"
              :precision="0"
            ></el-input-number>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button @click="handleClose">取消</el-button>
          </el-form-item>
        </el-form>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {
  queryResourceList,
  addResource,
  editResource,
  delResource,
} from "@/api/resource";
export default {
  components: {},
  data() {
    return {
      queryModal: {
        name: "",
      },
      form: {
        microServer: "#",
        parentId: 0,
        resourceName: "",
        resourcePath: "",
        type: 0,
        sorted: 0,
        category: 0, // 0 -- 工作台 1 -- 大屏
      },
      rules: {
        microServer: [
          { required: true, message: "请输入资源名称", trigger: "change" },
        ],
        resourceName: [
          { required: true, message: "请输入资源名称", trigger: "blur" },
        ],
        resourcePath: [
          { required: true, message: "请输入资源路径", trigger: "blur" },
        ],
        // type: [
        //     { required: true, message: '请输入资源路径', trigger: 'blur' }
        // ],
      },
      loading: false,
      formLoading: false,
      tableData: [],
      resourceData: [],
      resourceTree: [],
      props: {
        children: "sub",
        label: "resourceName",
        value: "id",
      },
      selectTree: {
        label: "",
        value: 0,
      },
      expandedKeys: [],
      formTitle: "新增",
      drawerVisible: false,
      microServerList: [
        {
          label: "应急资源",
          value: "gzdt-eres",
        },
        {
          label: "账户",
          value: "gzdt-ucenter",
        },
        {
          label: "线路工点",
          value: "gzdt-linesite",
        },
        {
          label: "资源上传",
          value: "gzdt-upload",
        },
        {
          label: "组织机构",
          value: "gzdt-org",
        },
        {
          label: "关注重点",
          value: "gzdt-proof",
        },
        {
          label: "培训演练",
          value: "gzdt-stand",
        },
        {
          label: "资源管理",
          value: "gzdt-resource",
        },
        {
          label: "角色",
          value: "gzdt-role",
        },
        {
          label: "数据字典",
          value: "gzdt-dict",
        },
        {
          label: "应急响应",
          value: "gzdt-response",
        },
        {
          label: "应急预案",
          value: "预案管理",
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    // 加载表格数据
    async loadData() {
      this.loading = true;
      const params = {
        name: this.queryModal.name || "-",
        parentId: "-",
        resourcePath: "-",
        type: "-",
      };
      let res = await queryResourceList(params);
      if (res.code == 0) {
        this.resourceData = JSON.parse(JSON.stringify(res.data));
        let treeList = res.data;
        let handleResource = (resource) => {
          let parent = treeList.find((p) => p.id == resource.parentId);
          if (parent) {
            if (resource.parentId !== 0 && parent.sub) {
              parent.sub.push(resource);
            } else {
              parent.sub = [resource];
            }
          } else {
            console.log(parent);
          }
        };
        treeList.forEach((item) => handleResource(item));
        this.tableData = treeList.filter((item) => item.parentId === 0);
        this.loading = false;
      } else {
        this.loading = false;
        this.$message.error(res.message);
      }
    },
    typeLabel(row) {
      if (row.type == 0) {
        return "菜单";
      } else {
        return "按钮";
      }
    },
    // 查询栏搜索
    handleQuery() {
      this.loadData();
    },
    // 数据新增
    handleAdd() {
      this.formTitle = "新增";
      this.resourceTree = JSON.parse(JSON.stringify(this.tableData));
      this.drawerVisible = true;
    },
    // 数据删除
    async handleDel(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delResource(record.id).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadData();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    // 数据修改
    handleEdit(record) {
      this.expandedKeys = [];
      this.formTitle = "编辑";
      this.form = Object.assign({}, record);
      if (record.parentId != 0) {
        const parent = this.resourceData.find(
          (item) => item.id == record.parentId
        );
        this.selectTree.label = parent.resourceName;
        this.selectTree.value = record.id;
      }
      // 修改时，需要移除自身
      let treeList = JSON.parse(JSON.stringify(this.resourceData));
      let index = treeList.findIndex((item) => item.id == record.id);
      treeList.splice(index, 1);
      let handleResource = (resource) => {
        let parent = treeList.find((p) => p.id == resource.parentId);
        if (parent) {
          if (resource.parentId !== 0 && parent.sub) {
            parent.sub.push(resource);
          } else {
            parent.sub = [resource];
          }
        } else {
          console.log(parent);
        }
      };
      treeList.forEach((item) => handleResource(item));
      this.resourceTree = treeList.filter((item) => item.parentId === 0);
      this.drawerVisible = true;
    },
    recursionParent(parentId) {
      if (parentId != 0) {
        const tempObj = this.resourceData.find((item) => item.id == parentId);
        this.expandedKeys.unshift(tempObj.id);
      }
    },
    // 下拉列表树点击
    handleResourceNodeClick(val) {
      this.selectTree.label = val.resourceName;
      this.selectTree.value = val.id;
      this.form.parentId = val.id;
      this.form.category = val.category;
    },
    // 下拉列表树清空
    handleClear() {
      this.selectTree.label = "";
      this.selectTree.value = 0;
      this.form.parentId = 0;
      this.from.category = 0;
    },
    // 表单提交
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.formLoading = true;
          const params = JSON.parse(JSON.stringify(this.form));
          if (params.id) {
            editResource(params).then((res) => {
              if (res.code == 0) {
                this.$message.success("操作成功");
                this.drawerVisible = false;
                this.formLoading = false;
                this.resizeForm();
                this.loadData();
              } else {
                this.formLoading = false;
                this.$message.error(res.message);
              }
            });
          } else {
            addResource(params).then((res) => {
              if (res.code == 0) {
                this.$message.success("操作成功");
                this.drawerVisible = false;
                this.formLoading = false;
                this.resizeForm();
                this.loadData();
              } else {
                this.formLoading = false;
                this.$message.error(res.message);
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 抽屉关闭前回调
    handleClose() {
      this.drawerVisible = false;
      this.resizeForm();
    },
    // 表单重置
    resizeForm() {
      this.form = {
        microServer: "#",
        parentId: 0,
        resourceName: "",
        resourcePath: "",
        type: 0,
      };
      this.selectTree = {
        label: "",
        value: 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  .el-input {
    width: 80%;
  }

  .el-select {
    width: 80%;
  }

  .el-tree {
    width: 80%;
  }
}
</style>
