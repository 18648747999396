<template>
  <div class="content">
    <div class="info-wrapper">
      <el-table
        :data="tableHeaderData"
        v-loading="headerLoading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        height="105"
        border
        style="width:100%;margin-bottom:20px"
      >
        <!-- <el-table-column
          align="center"
          prop="superviseName"
          show-overflow-tooltip
          label="所属建管部"
        ></el-table-column> -->
        <el-table-column
          align="center"
          prop="lineName"
          show-overflow-tooltip
          label="线路名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="branchName"
          show-overflow-tooltip
          label="分部名称"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          show-overflow-tooltip
          label="工点名称"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="leaderName"
          show-overflow-tooltip
          label="负责人"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="mobile"
          show-overflow-tooltip
          label="联系方式"
        >
        </el-table-column>
      </el-table>
      <div class="choice">
        <span
          style="margin-right:10px"
          v-for="item in radioData"
          :key="item.value"
        >
          <el-radio v-model="radio" @change="radioChange" :label="item.value">{{
            item.label
          }}</el-radio>
        </span>
      </div>
      <div class="line"></div>
      <el-form :inline="true" :model="libraryForm" class="demo-form-inline">
        <el-form-item>
          <el-input
            v-model="libraryForm.label"
            :placeholder="
              libraryForm.type == 125
                ? '请输入物资名称搜索'
                : '请输入设备名称搜索'
            "
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="libraryQuery">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table
        :data="tableData"
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        height="380"
        border
        style="width: 100%"
        @cell-mouse-enter="handleCellMouseEnter"
        @cell-mouse-leave="handleCellMouseLeave"
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="60"
        ></el-table-column>

        <el-table-column
          align="center"
          prop="label"
          :label="libraryForm.type == 125 ? '物资名称' : '设备名称'"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="model"
          label="规格/型号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="unit"
          width="100"
          label="计量单位"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="count"
          width="100"
          label="库存数量"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="usages"
          :show-overflow-tooltip="true"
          label="用途"
        >
          <template slot-scope="scope">
            {{ JSON.parse(scope.row.usages).join(",") }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="remark" label="备注">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="libraryForm.count"
        :page-count="libraryForm.total"
        :page-size="libraryForm.pageSize"
        :current-page="libraryForm.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getMaterialsPage, getWareHouseManList } from "@/api/eres"
import { queryDictByLabel, queryDictNodesById } from "@/api/dict"

export default {
  components: {},
  props: {
    libraryData: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      libraryForm: {
        label: "",
        modelNo: "",
        state: "",
        siteIds: [],
        type: "125",
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      tableData: [
        {
          lineName: "姓名",
          siteName: "xxx职位",
          vownerName: "部门",
          tell: "12"
        }
      ],
      tableHeaderData: [],
      radio: 125,
      radioData: [
        {
          label: "应急物资",
          value: 125
        },
        {
          label: "应急设备",
          value: 126
        }
      ],
      total: 0,
      headerLoading: false,
      loading: false,
      usageCache: "",
      dict: {
        model: [],
        usages: [],
        state: [
          {
            label: "正常",
            value: 1
          },
          {
            label: "异常",
            value: 2
          }
        ]
      }
    }
  },
  watch: {
    libraryData: {
      handler (val) {
        if (val) {
          console.log(val)
          this.libraryForm.siteIds = []
          this.libraryForm.siteIds.push(val.id)
          this.libraryForm.label = val.lablename
          this.getHeadDatas(val.id)
          this.getDataList(val.id)
        }
      },
      immediate: true,
      deep: true,
      clickShow: false
    }
  },
  created () {
    this.libraryForm.type = 125
  },
  mounted () {
    this.getDictData("物资用途", this.dict.usages)
  },
  methods: {
    paertyu () {
      this.radio = 125
      console.log(this.radio)
    },
    getHeadDatas (val) {
      this.headerLoading = true
      const params = {
        siteIds: [val],
        name: "",
        page: 1,
        size: 10
      }
      getWareHouseManList(params).then(res => {
        if (res.code == 0) {
          this.headerLoading = false
          this.tableHeaderData = res.data.records ? res.data.records : []
        } else {
          this.headerLoading = false
        }
      })
    },
    getDataList () {
      this.loading = true
      const params = {
        materialType: 1,
        siteIds: this.libraryForm.siteIds,
        type: this.libraryForm.type,
        state: this.libraryForm.state,
        modelNo: this.libraryForm.modelNo,
        label: this.libraryForm.label,
        page: this.libraryForm.pageNo,
        size: this.libraryForm.pageSize
      }
      getMaterialsPage(params).then(res => {
        if (res.code == 0) {
          console.log(res.data)
          this.loading = false
          this.tableData = res.data.records ? res.data.records : []
          this.libraryForm.pageNo = res.data.current
          this.libraryForm.pageSize = res.data.size
          this.libraryForm.total = res.data.total
          this.libraryForm.count = res.data.count
        } else {
          this.loading = false
        }
      })
    },
    getDictData (label, target) {
      queryDictByLabel({ label }).then(res => {
        if (res.code == 0) {
          const dictId = res.data.id
          queryDictNodesById({ id: dictId }).then(res => {
            if (res.code == 0) {
              Object.assign(target, res.data)
              this.$forceUpdate()
            }
          })
        }
      })
    },
    // 鼠标滑入单元格
    handleCellMouseEnter (row, column, cell, event) {
      console.log(column.property)
      if (column.property == "usages") {
        cell.querySelector(".cell").style.cursor = "pointer"
        console.log(row, column, cell, event)
        const labelArr = []
        const usageStr = cell.querySelector(".cell").innerHTML
        this.usageCache = usageStr
        let usageArr = []
        if (usageStr.length) {
          usageArr = usageStr.split("")
        }
        for (let usage of usageArr) {
          const tempObj = this.dict.usages.find(item => item.label == usage)
          if (tempObj) {
            labelArr.push(tempObj)
          }
        }
        let str = ""
        for (let item of labelArr) {
          str += `<li>${item.label}.${item.intro}</li>`
        }
        cell.querySelector(".cell").innerHTML = `<ul>${str}</ul>`
      }
    },
    // 鼠标移出单元格
    handleCellMouseLeave (row, column, cell, event) {
      if (column.property == "usages") {
        console.log(row, column, cell, event)
        cell.querySelector(".cell").innerHTML = this.usageCache
      }
    },
    radioChange (vals) {
      this.radio = vals
      this.libraryForm.type = vals
      this.getDataList()
    },
    libraryCls () {
      this.$emit("libraryCls")
    },
    // 查询
    libraryQuery () {
      this.getDataList()
    },

    handleCurrentChange (val) {
      this.libraryForm.pageNo = val
      this.getDataList()
    },
    handleSizeChange (size) {
      this.libraryForm.pageSize = size
      this.getDataList()
    },
    yuyin (val) {
      console.log(val)
    },
    shipin (val) {
      console.log(val)
    },
    duanxian (val) {
      console.log(val)
    }
  }
};
</script>

<style lang="scss" scoped>
.info-wrapper {
  ::v-deep .el-table--border,
  .el-table::before {
    border-bottom: 0 !important;
    height: 0px;
  }
}

.peopleNum {
  color: #000;
  font-weight: bold;
}
::v-deep .el-radio-button__inner {
  width: 200px;
}
.choice {
  display: flex;
  flex-direction: row;
}
.line {
  height: 1px;
  width: 100%;
  background: #143363;
  margin-bottom: 20px;
}
::v-deep .el-input__inner {
  background: #081742;
  border-color: #25458f;
  color: #fff;
}
.el-table--border::after {
  width: 0;
}
::v-deep .el-input__inner:hover {
  border-color: #25458f;
}
::v-deep .el-table th.is-leaf,
::v-deep .el-table--border td {
  border: 1px solid #143363 !important;
}
</style>
