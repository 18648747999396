<template>
  <div>
    <div class="page-container" style="margin-top: 0px">
      <el-form
        :model="ruleForm"
        :rules=" title === '查看' ? {} : rules"
        ref="ruleForm"
        label-width="180px"
        class="demo-ruleForm"
        :disabled="title === '查看'"
        :validate-on-rule-change="false"
      >
        <!-- 项目信息 -->
        <el-row class="PostInfCs-input">
          <div class="name">
            <img src="../../../assets/system/xinxi.png" alt /> 项目信息
          </div>
          <el-divider></el-divider>
          <el-col :span="8">
            <el-form-item label="所属工点" prop="siteId">
              <el-cascader
                v-model="ruleForm.siteId"
                clearable
                :options="treeData"
                :props="propsAdd"
                style="width: 100%"
                :show-all-levels="false"
                collapse-tags
                @change="handleLineSiteChangeAdd"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="归属区域" prop="ownerUnitName">
              <el-input v-model="ruleForm.constructionManagementName" disabled></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <!--              <el-form-item label="项目名称" prop="projectRealName">
                            <el-input v-model="ruleForm.projectRealName" disabled></el-input>
            </el-form-item>-->
            <el-form-item label="建设阶段" prop="constructionUnitName">
              <el-input v-model="ruleForm.constructionUnitName" disabled></el-input>
            </el-form-item>
            <el-form-item label="建设单位" prop="constructionUnitName">
              <el-input v-model="ruleForm.constructionUnitName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="建管部" prop="supervisorUnitName">
              <el-input v-model="ruleForm.supervisorUnitName" disabled></el-input>
            </el-form-item>
            <el-form-item label="监理单位" prop="supervisorUnitName">
              <el-input v-model="ruleForm.supervisorUnitName" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 设备基本信息 -->
        <el-row class="PostInfCs-input">
          <div class="name">
            <img src="../../../assets/system/xinxi.png" alt /> 设备基本信息
          </div>
          <el-divider></el-divider>
          <el-col :span="5">
            <el-form-item label="设备用途" prop="equipmentType">
              <el-select v-model="ruleForm.deviceUse" placeholder="请选择">
                <el-option label="施工设备" :value="0"></el-option>
                <el-option label="应急设备" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备代码" prop="equipmentModel" v-if="ruleForm.deviceUse == 0">
              <el-input v-model="ruleForm.equipmentModel"></el-input>
            </el-form-item>

            <el-form-item label="规格型号" prop="productionDate" v-if="ruleForm.deviceUse == 0">
              <el-input v-model="ruleForm.equipmentModel"></el-input>
            </el-form-item>
            <el-form-item label="租赁单位" prop="enterTime" v-if="ruleForm.deviceUse == 0">
              <el-input v-model="ruleForm.equipmentModel"></el-input>
            </el-form-item>

            <el-form-item label="设备编号" prop="enterTime" v-if="ruleForm.deviceUse == 1">
              <el-input v-model="ruleForm.equipmentModel"></el-input>
            </el-form-item>

            <el-form-item label="车牌信息" prop="enterTime" v-if="ruleForm.deviceUse == 1">
              <el-input v-model="ruleForm.equipmentModel"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="设备分类" prop="propertyRightUnit">
              <el-select v-model="ruleForm.equipmentType" placeholder="请选择">
                <el-option
                  v-for="item in equType"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="产品编号" prop="manufacturer" v-if="ruleForm.deviceUse == 0">
              <el-input v-model="ruleForm.manufacturer"></el-input>
            </el-form-item>
            <el-form-item label="生产厂家" prop="recordTime" v-if="ruleForm.deviceUse == 0">
              <el-input v-model="ruleForm.manufacturer"></el-input>
            </el-form-item>
            <el-form-item
              label="铭牌号"
              prop="productionCertificationNumber"
              v-if="ruleForm.deviceUse == 0"
            >
              <el-input v-model="ruleForm.productionCertificationNumber"></el-input>
            </el-form-item>

            <el-form-item
              label="型号要求"
              prop="productionCertificationNumber"
              v-if="ruleForm.deviceUse == 1"
            >
              <el-input v-model="ruleForm.productionCertificationNumber"></el-input>
            </el-form-item>
            <el-form-item
              label="使用单位"
              prop="productionCertificationNumber"
              v-if="ruleForm.deviceUse == 1"
            >
              <el-input v-model="ruleForm.productionCertificationNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="设备性质" prop="deviceSerialNumber">
              <el-select v-model="ruleForm.equipmentType" placeholder="请选择">
                <el-option
                  v-for="item in equType"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="登记机构" prop="expiryPeriod" v-if="ruleForm.deviceUse == 0">
              <el-input v-model="ruleForm.expiryPeriod"></el-input>
            </el-form-item>
            <el-form-item
              label="出场时间"
              prop="equipmentRegistrationNumber"
              v-if="ruleForm.deviceUse == 0"
            >
              <el-date-picker
                v-model="ruleForm.productionDate"
                type="date"
                placeholder="出厂日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="实际型号" prop="expiryPeriod" v-if="ruleForm.deviceUse == 1">
              <el-input v-model="ruleForm.expiryPeriod"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="设备类型" prop="deviceSerialNumber">
              <el-select v-model="ruleForm.equipmentType" placeholder="请选择">
                <el-option
                  v-for="item in equType"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发证日期" prop="expiryPeriod" v-if="ruleForm.deviceUse == 0">
              <el-input v-model="ruleForm.expiryPeriod"></el-input>
            </el-form-item>
            <el-form-item
              label="进场时间"
              prop="equipmentRegistrationNumber"
              v-if="ruleForm.deviceUse == 0"
            >
              <el-date-picker
                v-model="ruleForm.productionDate"
                type="date"
                placeholder="出厂日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="数量要求" prop="expiryPeriod" v-if="ruleForm.deviceUse == 1">
              <el-input v-model="ruleForm.expiryPeriod"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="设备名称" prop="deviceSerialNumber">
              <el-input v-model="ruleForm.deviceSerialNumber"></el-input>
            </el-form-item>
            <el-form-item label="使用单位" prop="expiryPeriod" v-if="ruleForm.deviceUse == 0">
              <el-input v-model="ruleForm.expiryPeriod"></el-input>
            </el-form-item>
            <el-form-item
              label="产权单位"
              prop="equipmentRegistrationNumber"
              v-if="ruleForm.deviceUse == 0"
            >
              <el-input v-model="ruleForm.equipmentRegistrationNumber"></el-input>
            </el-form-item>
            <el-form-item
              label="实际数量"
              prop="equipmentRegistrationNumber"
              v-if="ruleForm.deviceUse == 1"
            >
              <el-input v-model="ruleForm.equipmentRegistrationNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div v-if="ruleForm.deviceUse == 0">
          <!-- 设备基本参数 -->
          <!--        v-if="title !== '新增' && typeChange === 'tower'"-->
          <el-row class="PostInfCs-input">
            <div class="name">
              <img src="../../../assets/system/xinxi.png" alt /> 设备基本参数
            </div>
            <el-divider></el-divider>
            <el-col :span="5">
              <el-form-item label="额定载重量">
                <el-input v-model="tdMsg.boomLength"></el-input>
              </el-form-item>
              <el-form-item label="起升高度">
                <el-input v-model="tdMsg.ratedHeight"></el-input>
              </el-form-item>
              <el-form-item label="试用状态">
                <el-input v-model="tdMsg.ratedWindSpeed"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="主梁跨度">
                <el-input v-model="tdMsg.balanceArmLength"></el-input>
              </el-form-item>
              <el-form-item label="有效悬臂长度">
                <el-input v-model="tdMsg.ratedAngle"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="工作级别">
                <el-input v-model="tdMsg.boomHeight"></el-input>
              </el-form-item>
              <el-form-item label="副钩起重量">
                <el-input v-model="tdMsg.ratedAmplitude"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="设备位置X">
                <el-input v-model="tdMsg.ratedLiftingWeight"></el-input>
              </el-form-item>
              <el-form-item label="设备位置Y">
                <el-input v-model="tdMsg.ratedDipAngle"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="主钩起重量">
                <el-input v-model="tdMsg.ratedLiftingWeight"></el-input>
              </el-form-item>
              <el-form-item label="额定风级">
                <el-input v-model="tdMsg.ratedDipAngle"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 设备基本参数 -->
          <el-row class="PostInfCs-input" v-if="title !== '新增' && typeChange === 'gantryCrane'">
            <div class="name">
              <img src="../../../assets/system/xinxi.png" alt /> 设备基本参数
            </div>
            <el-divider></el-divider>
            <el-col :span="6">
              <el-form-item label="额定载重">
                <el-input v-model="lmdMsg.ratedWeight"></el-input>
              </el-form-item>
              <el-form-item label="额定倾角">
                <el-input v-model="lmdMsg.ratedDip"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="额定高度">
                <el-input v-model="lmdMsg.ratedHeight"></el-input>
              </el-form-item>
              <el-form-item label="额定风速">
                <el-input v-model="lmdMsg.ratedWindSpeed"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="额定宽度">
                <el-input v-model="lmdMsg.ratedWidth"></el-input>
              </el-form-item>
              <el-form-item label="额定幅度">
                <el-input v-model="lmdMsg.ratedRange"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="额定角度">
                <el-input v-model="lmdMsg.ratedAngle"></el-input>
              </el-form-item>
              <el-form-item label="额定风级">
                <el-input v-model="lmdMsg.ratedWindScale"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="大车最大行程">
                <el-input v-model="lmdMsg.maxDistance"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 设备基本参数 -->
          <el-row class="PostInfCs-input" v-if="title !== '新增' && typeChange === 'crawlerCrane'">
            <div class="name">
              <img src="../../../assets/system/xinxi.png" alt /> 设备基本参数
            </div>
            <el-divider></el-divider>
            <el-col :span="6">
              <el-form-item label="额定载重">
                <el-input v-model="lddMsg.ratedWeight"></el-input>
              </el-form-item>
              <el-form-item label="净重">
                <el-input v-model="lddMsg.netWeight"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="悬臂长度">
                <el-input v-model="lddMsg.cantileverLength"></el-input>
              </el-form-item>
              <el-form-item label="大车运行速度">
                <el-input v-model="lddMsg.craneRunSpeed"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="起升高度">
                <el-input v-model="lddMsg.liftHeight"></el-input>
              </el-form-item>
              <el-form-item label="电动功率">
                <el-input v-model="lddMsg.electricPower"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="起升时间">
                <el-input v-model="lddMsg.liftSpeed"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!--        监测预警规则-->
          <el-row class="PostInfCs-input">
            <div class="name">
              <img src="../../../assets/system/xinxi.png" alt /> 设备基本参数
            </div>
            <el-divider></el-divider>
            <el-col :span="8">
              <el-form-item label="规则名称">
                <el-input v-model="tdMsg.boomLength"></el-input>
              </el-form-item>
              <el-form-item label="卷筒限位预警">
                <div class="input-radio">
                  <el-input v-model="tdMsg.ratedHeight"></el-input>
                  <el-radio-group v-model="tdMsg" class="radio-group">
                    <el-radio :label="3">上限</el-radio>
                    <el-radio :label="6">下限</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item label="偏斜预警">
                <div class="input-radio">
                  <el-input v-model="tdMsg.ratedHeight"></el-input>
                  <el-radio-group v-model="tdMsg" class="radio-group">
                    <el-radio :label="3">上限</el-radio>
                    <el-radio :label="6">下限</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item label="小车后行限位预警">
                <div class="input-radio">
                  <el-input v-model="tdMsg.ratedHeight"></el-input>
                  <el-radio-group v-model="tdMsg" class="radio-group">
                    <el-radio :label="3">上限</el-radio>
                    <el-radio :label="6">下限</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="主钩超载预警">
                <div class="input-radio">
                  <el-input v-model="tdMsg.ratedHeight"></el-input>
                  <el-radio-group v-model="tdMsg" class="radio-group">
                    <el-radio :label="3">上限</el-radio>
                    <el-radio :label="6">下限</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item label="大车左行限位预警">
                <div class="input-radio">
                  <el-input v-model="tdMsg.ratedHeight"></el-input>
                  <el-radio-group v-model="tdMsg" class="radio-group">
                    <el-radio :label="3">上限</el-radio>
                    <el-radio :label="6">下限</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item label="超风速预警">
                <div class="input-radio">
                  <el-input v-model="tdMsg.ratedHeight"></el-input>
                  <el-radio-group v-model="tdMsg" class="radio-group">
                    <el-radio :label="3">上限</el-radio>
                    <el-radio :label="6">下限</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item label="小车高度限位预警">
                <div class="input-radio">
                  <el-input v-model="tdMsg.ratedHeight"></el-input>
                  <el-radio-group v-model="tdMsg" class="radio-group">
                    <el-radio :label="3">上限</el-radio>
                    <el-radio :label="6">下限</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="副钩超载预警">
                <div class="input-radio">
                  <el-input v-model="tdMsg.ratedHeight"></el-input>
                  <el-radio-group v-model="tdMsg" class="radio-group">
                    <el-radio :label="3">上限</el-radio>
                    <el-radio :label="6">下限</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item label="大车右行限位预警">
                <div class="input-radio">
                  <el-input v-model="tdMsg.ratedHeight"></el-input>
                  <el-radio-group v-model="tdMsg" class="radio-group">
                    <el-radio :label="3">上限</el-radio>
                    <el-radio :label="6">下限</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item label="小车前行限位预警">
                <div class="input-radio">
                  <el-input v-model="tdMsg.ratedHeight"></el-input>
                  <el-radio-group v-model="tdMsg" class="radio-group">
                    <el-radio :label="3">上限</el-radio>
                    <el-radio :label="6">下限</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item label="门限位预警">
                <div class="input-radio">
                  <el-input v-model="tdMsg.ratedHeight"></el-input>
                  <el-radio-group v-model="tdMsg" class="radio-group">
                    <el-radio :label="3">上限</el-radio>
                    <el-radio :label="6">下限</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <!--        资质参数明细    -->
          <div class="out-box">
            <el-row>
              <div class="name btnTitle">
                <span class="sepc-span">
                  <img src="../../../assets/system/xinxi.png" alt /> 资质参数明细
                </span>
                <span class="buttomName">
                  <el-button
                    class="customButton"
                    type="primary"
                    @click.prevent="handleAddDialog('资质参数明细')"
                    size="mini"
                  >
                    <i class="fa fa-plus"></i>新增
                  </el-button>
                </span>
              </div>
              <el-divider></el-divider>
              <el-table :data="personList" id="tableList" border max-height="170">
                <el-table-column type="index" width="60" label="序号" align="center"></el-table-column>
                <el-table-column label="整数类型" align="center" prop="personName"></el-table-column>
                <el-table-column label="证书名称" align="center" prop="certificateName">
                  <template slot-scope="scope">
                    <div class="search-download">
                      <span>{{ scope.row.certificateName }}</span>
                      <i class="el-icon-search"></i>
                      <i class="el-icon-download"></i>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="上传时间" align="center" prop="mobile"></el-table-column>
                <el-table-column fixed="right" label="操作" width="120" align="center">
                  <template slot-scope="scope">
                    <!-- <el-button @click.native.prevent="updateRow5(scope.row)" type="text"> 编辑 </el-button> -->
                    <el-button @click.native.prevent="delRow5(scope.row)" type="text">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>

          <!-- 特种作业人员 -->
          <div class="out-box">
            <el-row>
              <div class="name btnTitle">
                <span class="sepc-span">
                  <img src="../../../assets/system/xinxi.png" alt /> 特种作业人员
                </span>
                <span class="buttomName">
                  <el-button
                    class="customButton"
                    type="primary"
                    @click.prevent="handleAddDialog('特种作业人员')"
                    size="mini"
                  >
                    <i class="fa fa-plus"></i>新增
                  </el-button>
                </span>
              </div>
              <el-divider></el-divider>
              <el-table :data="personList" id="tableList" border max-height="170">
                <el-table-column type="index" width="60" label="序号" align="center"></el-table-column>
                <el-table-column label="人员姓名" align="center" prop="personName"></el-table-column>
                <el-table-column label="证书名称" align="center" prop="certificateName"></el-table-column>
                <el-table-column label="联系方式" align="center" prop="mobile"></el-table-column>
                <el-table-column fixed="right" label="操作" width="120" align="center">
                  <template slot-scope="scope">
                    <!-- <el-button @click.native.prevent="updateRow5(scope.row)" type="text"> 编辑 </el-button> -->
                    <el-button @click.native.prevent="delRow5(scope.row)" type="text">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>

          <!-- 安装信息 -->
          <div class="out-box">
            <el-row>
              <div class="name btnTitle">
                <span class="sepc-span">
                  <img src="../../../assets/system/xinxi.png" alt /> 安装信息
                </span>
                <span class="buttomName">
                  <el-button
                    class="customButton"
                    type="primary"
                    @click.prevent="handleAddDialog('安装信息')"
                    size="mini"
                  >
                    <i class="fa fa-plus"></i>新增
                  </el-button>
                </span>
              </div>
              <el-divider></el-divider>
              <el-table :data="personList" id="tableList" border max-height="170">
                <el-table-column type="index" width="60" label="序号" align="center"></el-table-column>
                <el-table-column label="安装单位" align="center" prop="personName"></el-table-column>
                <el-table-column label="单位资质" align="center" prop="certificateName"></el-table-column>
                <el-table-column label="安装开始时间" align="center" prop="mobile"></el-table-column>
                <el-table-column label="安装完成时间" align="center" prop="mobile"></el-table-column>
                <el-table-column label="安装告知编号" align="center" prop="mobile"></el-table-column>
                <el-table-column label="设备验收报告" align="center" prop="mobile"></el-table-column>
                <el-table-column fixed="right" label="操作" width="120" align="center">
                  <template slot-scope="scope">
                    <!-- <el-button @click.native.prevent="updateRow5(scope.row)" type="text"> 编辑 </el-button> -->
                    <el-button @click.native.prevent="delRow5(scope.row)" type="text">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>

          <!--        备案信息-->
          <el-row class="PostInfCs-input out-box">
            <div class="name">
              <img src="../../../assets/system/xinxi.png" alt /> 备案信息
            </div>
            <el-divider></el-divider>
            <el-col :span="8">
              <el-form-item label="广州机械管理系统备案情况">
                <el-input v-model="tdMsg.boomLength"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="登记时间">
                <el-input v-model="tdMsg.boomLength"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="产权备案编号">
                <el-input v-model="tdMsg.boomLength"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!--        监测信息-->
          <el-row class="PostInfCs-input">
            <div class="name">
              <img src="../../../assets/system/xinxi.png" alt /> 监测信息
            </div>
            <el-divider></el-divider>
            <el-col :span="8">
              <el-form-item label="安全监测系统安装情况">
                <el-select class="mr-2" v-model="tdMsg.unitType" clearable placeholder="安全监测系统安装情况">
                  <el-option label="是" value="0"></el-option>
                  <el-option label="否" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="安全监测系统计划安装时间">
                <el-date-picker
                  v-model="ruleForm.productionDate"
                  type="date"
                  placeholder="出厂日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="监测内容对应国标规定">
                <el-select class="mr-2" v-model="tdMsg.unitType" clearable placeholder="监测内容对应国标规定">
                  <el-option label="是" value="0"></el-option>
                  <el-option label="否" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="接入监控平台计划时间">
                <el-date-picker
                  v-model="ruleForm.productionDate"
                  type="date"
                  placeholder="出厂日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="监测系统是否正常">
                <el-select class="mr-2" v-model="tdMsg.unitType" clearable placeholder="监测系统是否正常">
                  <el-option label="是" value="0"></el-option>
                  <el-option label="否" value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 核验信息 -->
          <div class="out-box">
            <el-row>
              <div class="name btnTitle">
                <span class="sepc-span">
                  <img src="../../../assets/system/xinxi.png" alt /> 核验信息
                </span>
                <span class="buttomName">
                  <el-button
                    class="customButton"
                    type="primary"
                    @click.prevent="handleAddDialog('核验信息')"
                    size="mini"
                  >
                    <i class="fa fa-plus"></i>新增
                  </el-button>
                </span>
              </div>
              <el-divider></el-divider>
              <el-table :data="personList" id="tableList" border max-height="170">
                <el-table-column type="index" width="60" label="序号" align="center"></el-table-column>
                <el-table-column label="核验机构" align="center" prop="personName"></el-table-column>
                <el-table-column label="核验日期" align="center" prop="certificateName"></el-table-column>
                <el-table-column label="核验报告" align="center" prop="mobile"></el-table-column>
                <el-table-column fixed="right" label="操作" width="120" align="center">
                  <template slot-scope="scope">
                    <!-- <el-button @click.native.prevent="updateRow5(scope.row)" type="text"> 编辑 </el-button> -->
                    <el-button @click.native.prevent="delRow5(scope.row)" type="text">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>

          <!--        设备使用须知-->
          <div class="PostInfCs-input out-box">
            <div class="name">
              <img src="../../../assets/system/xinxi.png" alt /> 设备使用须知
            </div>
            <el-divider></el-divider>

            <el-row>
              <el-col :span="24">
                <el-form-item label="操作规程" prop="productionDate">
                  <Uploader></Uploader>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="禁止使用情况" prop="productionDate">
                  <el-input v-model="ruleForm.ccDeviceId"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="使用登记证" prop="productionDate">
                  <Uploader></Uploader>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="维保周期" prop="productionDate">
                  <el-input v-model="ruleForm.ccDeviceId"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="检定周期" prop="productionDate">
                  <el-input v-model="ruleForm.ccDeviceId"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>

        <div v-else>
          <!--        证书信息-->
          <div class="PostInfCs-input out-box">
            <div class="name">
              <img src="../../../assets/system/xinxi.png" alt /> 证书信息
            </div>
            <el-divider></el-divider>

            <el-row>
              <el-col :span="8">
                <el-form-item label="生产厂家" prop="productionDate">
                  <el-input v-model="ruleForm.ccDeviceId"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="出场时间" prop="productionDate">
                  <el-input v-model="ruleForm.ccDeviceId"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="是否有合格证" prop="productionDate">
                  <el-input v-model="ruleForm.ccDeviceId"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="厂家生产许可证" prop="productionDate">
                  <Uploader></Uploader>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="合格证" prop="productionDate">
                  <Uploader></Uploader>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <!-- 核验信息 -->
          <div class="out-box">
            <el-row>
              <div class="name btnTitle">
                <span class="sepc-span">
                  <img src="../../../assets/system/xinxi.png" alt /> 核验信息
                </span>
                <!--                <span class="buttomName"
                ><el-button class="customButton" type="primary" @click.prevent="handleAddDialog('核验信息')" size="mini">
                <i class="fa fa-plus"></i>新增
              </el-button></span
                >-->
              </div>
              <el-divider></el-divider>
              <el-table :data="personList" id="tableList" border max-height="170">
                <el-table-column type="index" width="60" label="序号" align="center"></el-table-column>
                <el-table-column label="证书类型" align="center" prop="personName"></el-table-column>
                <el-table-column label="证书名称" align="center" prop="certificateName"></el-table-column>
                <el-table-column label="上传时间" align="center" prop="mobile"></el-table-column>
                <el-table-column fixed="right" label="操作" width="120" align="center">
                  <template slot-scope="scope">
                    <!-- <el-button @click.native.prevent="updateRow5(scope.row)" type="text"> 编辑 </el-button> -->
                    <el-button @click.native.prevent="delRow5(scope.row)" type="text">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>
        </div>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelAddPlan">取 消</el-button>
      <el-button type="primary" @click="submitForm" v-if="title !== '详情' ">确 定</el-button>
    </div>
    <!-- 二级弹窗 -->
    <el-dialog
      class="editDialog"
      :title="installTitle"
      width="50%"
      v-if="dialogFormVisible"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      @close="formClose"
    >
      <div class="page-container" style="margin-top: 0px">
        <!-- 资质参数明细 -->
        <el-form
          :model="install"
          label-width="180px"
          ref="form1"
          label-position="right"
          :validate-on-rule-change="false"
          v-if="this.tableName == '资质参数明细'"
          :rules="rules1"
        >
          <el-row class="PostInfCs-input">
            <el-col :span="24">
              <el-form-item label="证书类型" prop="installationUnit">
                <el-input v-model="install.installationUnit"></el-input>
              </el-form-item>
              <el-form-item
                label="证书名称"
                prop="qualificationCertificateNumber"
                style="text-align: left"
              >
                <Uploader></Uploader>
              </el-form-item>
              <el-form-item label="上传时间" prop="installationPrincipal" style="text-align: left">
                <el-date-picker
                  v-model="ruleForm.enterTime"
                  type="date"
                  placeholder="进场时间"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 特种作业人员 -->
        <el-form
          :model="person"
          label-width="180px"
          ref="form5"
          label-position="right"
          :validate-on-rule-change="false"
          v-if="this.tableName == '特种作业人员'"
          :rules="rules5"
        >
          <el-row class="PostInfCs-input">
            <el-col :span="24">
              <el-form-item label="人员姓名" prop="personName" style="text-align: left">
                <!-- <el-select v-model="person.personName" filterable placeholder="请选择"> -->
                <!-- <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> -->
                <!-- </el-option> -->
                <el-select
                  v-model="person.userId"
                  filterable
                  remote
                  placeholder="请输入关键词"
                  :remote-method="remoteMethod"
                  :loading="personLoading"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.realName"
                    :value="item.id"
                    @click.native="getPersonMsg(item)"
                  ></el-option>
                </el-select>
                <!-- </el-select> -->
              </el-form-item>
              <el-form-item label="联系方式" prop="mobile" style="text-align: left">
                <el-input v-model="person.mobile"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证书名称" prop="certificateName" style="text-align: left">
                <Uploader></Uploader>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 安装信息 -->
        <el-form
          :model="check"
          label-width="180px"
          ref="form2"
          label-position="right"
          :validate-on-rule-change="false"
          v-if="this.tableName ==  '安装信息' "
          :rules="rules2"
        >
          <el-row class="PostInfCs-input">
            <el-col :span="24">
              <el-form-item label="安装单位" prop="detectionPerson" style="text-align: left">
                <el-input v-model="check.detectionPerson"></el-input>
              </el-form-item>
              <el-form-item label="单位资质" prop="deviceName" style="text-align: left">
                <el-input v-model="check.deviceName"></el-input>
              </el-form-item>
              <el-form-item label="安装开始时间" prop="detectionTime" style="text-align: left">
                <el-date-picker
                  v-model="check.detectionTime"
                  type="datetime"
                  placeholder="开始时间"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="安装完成时间" prop="detectionResult" style="text-align: left">
                <el-date-picker
                  v-model="ruleForm.enterTime"
                  type="date"
                  placeholder="进场时间"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="安装告知编号" prop="fileList">
                <el-input v-model="check.equipmentType"></el-input>
              </el-form-item>
              <el-form-item label="设备验收报告" prop="fileList">
                <Uploader></Uploader>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 核验信息 -->
        <el-form
          :model="maintain"
          label-width="180px"
          ref="form3"
          label-position="right"
          :validate-on-rule-change="false"
          v-if="this.tableName ==  '核验信息' "
          :rules="rules3"
        >
          <el-row class="PostInfCs-input">
            <el-col :span="24">
              <el-form-item label="核验机构" prop="maintenanceUser" style="text-align: left">
                <el-input v-model="maintain.maintenanceUser"></el-input>
              </el-form-item>
              <el-form-item label="核验日期" prop="deviceName" style="text-align: left">
                <el-date-picker
                  v-model="ruleForm.enterTime"
                  type="date"
                  placeholder="进场时间"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                label="核验报告"
                prop="fileList"
                v-if="maintain.equipmentType != 'tower' && maintain.equipmentType != 'gantryCrane'"
              >
                <Uploader @uploader="expertUploader"></Uploader>
              </el-form-item>
              <el-form-item
                v-if="
              installFileList.length && maintain.equipmentType != 'tower' && maintain.equipmentType != 'gantryCrane'
              "
                label="文件列表"
                style="text-align: left"
              >
                <div class="fileList">
                  <p v-for="(item, index) in installFileList" :key="index">
                    {{ item.name || item.fileName }}
                    <span
                      @click="delFile(index)"
                      style="color: #7073FF;cursor: pointer;"
                    >删除</span>
                  </p>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-button @click.native="formClose">取消</el-button>
          <el-button type="primary" @click.native="sure">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import popupTreeInput from "@/views/DeviceManagent/components/popupTreeInput";
import Uploader from "@/views/DeviceManagent/components/Uploader";

export default {
  name: "registerDialog",
  components: {
    // popupTreeInput,
    Uploader
  },
  props: {
    title: "",
    ruleForm: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        deviceUse: 0
      }
    },
    treeData: {
      default: () => []
    },
    deviceType: {
      default: () => []
    },
    typeChange: ""
  },
  data() {
    return {
      deptTreeProps: {
        label: "label",
        children: "children"
      },
      propsAdd: {
        children: "children",
        label: "name",
        value: "uuid"
      },
      deviceNameAdd: "",
      tdMsg: {},
      lmdMsg: {},
      equType: [],
      personList: [
        {
          certificateName: "测试"
        }
      ],
      installTitle: "",
      install: {},
      rules1: {},
      rules: {},
      check: {},
      maintain: {},
      installFileList: [],
      tableName: "",
      dialogFormVisible: false,
      person: {},
      rules2: {},
      rules3: {},
      rules5: {}
    };
  },
  created() {},
  methods: {
    handleLineSiteChangeAdd() {
      // dd
    },
    cancelAddPlan() {
      // dd
    },
    submitForm() {
      // dd
    },
    handleAddDialog(name) {
      this.installTitle = "新增";
      this.tableName = name;
      this.dialogFormVisible = true;
    },
    formClose() {
      this.dialogFormVisible = false;
    },
    sure() {}
  }
};
</script>

<style lang="scss" scoped>
.PostInfCs-input {
  .el-divider--horizontal {
    margin: 10px 0;
  }
  .name {
    display: flex;
    img {
      margin-right: 10px;
    }
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
  .el-select {
    width: 100%;
  }
}
.input-radio {
  display: flex;
  align-items: center;
  .radio-group {
    margin-left: 10px;
    display: flex;
  }
}
.out-box {
  margin-top: 10px;
}
.btnTitle {
  display: flex;
  justify-content: space-between;
  .sepc-span {
    width: 120px;
    display: flex;
    img {
      width: 16px;
      height: 21px;
      margin-right: 10px;
    }
  }
}
.search-download {
  i {
    margin-left: 10px;
    color: #27a2fe;
    cursor: pointer;
  }
}
.dialog-footer {
  margin-top: 10px;
}
</style>
