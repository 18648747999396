<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-model"
    >
      <el-form-item label="工点名称" v-if="operation === 'add'">
        <el-cascader
          v-model="ruleForm.lineSite"
          style="width: 100%;"
          clearable
          :options="treeData"
          :props="props"
          ref="cascaderTree"
          :show-all-levels="false"
          collapse-tags
          @change="handleLineSiteChange"
        ></el-cascader>
      </el-form-item>

      <el-form-item label="培训名称" prop="label">
        <el-input v-model="ruleForm.label" placeholder="培训名称"></el-input>
      </el-form-item>
      <el-form-item label="主讲人" prop="teacher">
        <el-input v-model="ruleForm.teacher" placeholder="主讲人"></el-input>
      </el-form-item>
      <el-form-item label="培训地点" prop="trainAddress">
        <el-input
          v-model="ruleForm.trainAddress"
          placeholder="培训地点"
        ></el-input>
      </el-form-item>
      <el-form-item label="计划培训时间" prop="planDate">
        <el-date-picker
          style="width: 100%"
          v-model="ruleForm.planDate"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="课件" prop="">
        <Uploader @uploader="uploader" :accept="'.pdf,image/*'"></Uploader>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelAddPlan" v-if="operation !== 'detail'"
        >取 消</el-button
      >
      <el-button type="primary" @click="submitPlan" v-if="operation === 'add'"
        >确 定</el-button
      >
      <el-button type="primary" @click="submitPlan" v-if="operation === 'edit'"
        >重新提交</el-button
      >
    </div>

    <el-dialog
      width="30%"
      title="添加"
      :visible.sync="dialogVisible"
      class="editDialog"
      append-to-body
    >
      <addWork v-if="dialogVisible"></addWork>
    </el-dialog>
  </div>
</template>

<script>
import Uploader from "./Uploader";
import addWork from "@/views/System/TrainingDrill/components/addWork";
import { addTrain, getTrainDetail } from "@/api/stand";
export default {
  name: "addPlan",
  components: {
    Uploader,
    addWork,
  },
  props: {
    operation: {},
    detailId: null,
  },
  data() {
    return {
      ruleForm: {
        buildDeptName: "",
        lineName: "",
        branchName: "",
        label: "",
        teacher: "",
        trainAddress: "",
        planDate: "",
        siteIds: [],
        lineSiteDeptVo: {},
        coursewares: [],
      },
      rules: {
        label: [{ required: true, message: "请输入培训名称", trigger: "blur" }],
        teacher: [{ required: true, message: "请输入主讲人", trigger: "blur" }],
        trainAddress: [
          { required: true, message: "请输入培训地点", trigger: "blur" },
        ],
        planDate: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
        siteIds: [
          { required: true, message: "请选择参与工点", trigger: "change" },
        ],
      },
      tags: [],
      dialogVisible: false,
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "id",
        // multiple: true,
        // checkStrictly : true
      },
      flatTreeData: [],
    };
  },
  created() {
    this.getLineSiteTreeData();
  },
  mounted() {},
  methods: {
    uploader(event) {
      console.log(event);
      let params = {
        attrName: event.originalName,
        attrType: 0,
        link: event.uri,
        localPath: event.fullPath,
      };
      this.ruleForm.coursewares.push(params);
      console.log(this.ruleForm);
    },
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
      this.flatArr(lineSiteData);
    },
    getDetailData() {
      let params = {
        id: this.detailId,
      };
      getTrainDetail(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.ruleForm = res.data;
        }
      });
    },
    // 数组扁平化
    flatArr(data) {
      for (let item of data) {
        this.flatTreeData.push(item);
        if (item.children) {
          this.flatArr(item.children);
        }
      }
    },
    // 级联选择器事件
    handleLineSiteChange(record) {
      this.ruleForm.siteIds = [];
      for (let item of record) {
        this.ruleForm.siteIds.push(item[item.length - 1]);
      }
    },
    addWorker() {
      this.dialogVisible = true;
    },
    cancelAddPlan() {
      this.$emit("cancleAddPlan");
    },
    submitPlan() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          const siteNodes = this.$refs.cascaderTree.getCheckedNodes();
          this.ruleForm.lineSiteDeptVo = {
            branchId: siteNodes[0].path[1],
            branchName: siteNodes[0].pathLabels[1],
            lineId: siteNodes[0].path[0],
            lineName: siteNodes[0].pathLabels[0],
            siteId: siteNodes[0].value,
            siteName: siteNodes[0].label,
            // superviseId: node.data.constructionManagementUnitId,
            // superviseName: node.data.constructionManagementUnitName,
            buildDeptId: siteNodes[0].data.constructionManagementUnitId,
            buildDeptName: siteNodes[0].data.constructionUnitName,
          };
          console.log(this.ruleForm);
          if (
            this.ruleForm.planDate &&
            !this.ruleForm.planDate.includes(" 00:00:00")
          ) {
            this.ruleForm.planDate = this.ruleForm.planDate + " 00:00:00";
          }
          addTrain(this.ruleForm).then((res) => {
            console.log(res);
            if (res.code == 0) {
              this.$message.success("操作成功");
              this.$emit("successAdd");
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          this.$message.error("请正确填写");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-class {
  display: flex;
  flex-direction: column;
}
</style>
