<template>
  <div>
    <!-- 响应升级详情 -->
    <el-dialog
      title="详情"
      center
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
    <p style="margin-top:10px;margin-left:20px;font-size:16px;font-weight: bold">事件快报</p>
      <el-form ref="form" :model="form" label-width="100px" style="margin-top:20px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="事件类型" >
              <el-input
                v-model="form.typeName"
                readonly
                placeholder="事件类型"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发生时间">
              <el-input
                :disabled="true"
                v-model="form.created"
                readonly
                placeholder="发生时间"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="上报人" >
              <el-input
                :disabled="true"
                v-model="form.publisherName"
                readonly
                placeholder="上报人"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人">
              <el-input
                :disabled="true"
                v-model="form.chargeName"
                readonly
                placeholder="负责人"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="地理位置" >
              <el-input
                :disabled="true"
                v-model="form.xycoors"
                readonly
                placeholder="地理位置"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工程项目名称" >
              <el-input
                :disabled="true"
                v-model="form.siteName"
                readonly
                placeholder="工程项目名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="施工单位" >
              <el-input
                :disabled="true"
                v-model="form.constructionUnitName"
                readonly
                placeholder="施工单位"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="监理单位" >
              <el-input
                :disabled="true"
                v-model="form.supervisorUnitName"
                readonly
                placeholder="监理单位"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="事件经过" >
          <el-input
            readonly
            type="textarea"
            resize="none"
            placeholder="请输入作业内容、事发情形等"
            :autosize="{ minRows: 2, maxRows: 4 }"
            v-model="form.historyRecord"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="伤亡情况" >
          <el-input
            readonly
            type="textarea"
            resize="none"
            :disabled="true"
            placeholder="请输入人员伤亡、直接经济损失和影响范围"
            :autosize="{ minRows: 2, maxRows: 4 }"
            v-model="form.casualties"
          ></el-input>
        </el-form-item>
        <el-form-item label="处置情况" >
          <el-input
            readonly
            :disabled="true"
            type="textarea"
            resize="none"
            placeholder="请输入应急救援、已采取和拟采取的措施等"
            v-model="form.disposal"
          ></el-input>
        </el-form-item>
        <el-form-item label="照片或附件："   style="margin-left:30px">
          <el-link
        
          v-for="(item,index) in imgs"
          :key="index"
            type="primary"
            :href="item.uri"
            target="_blank"
            >{{ item.originalName }}</el-link
          >
         </el-form-item
        >
          <p style="margin-top:10px;margin-left:20px;font-size:16px;font-weight: bold">升级信息</p>
        <el-row  style="margin-top:20px">
          <el-col :span="12">
            <el-form-item label="升级级别">
              <el-input
                v-model="form.currentLevelName"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="升级原因">
              <el-input
                type="textarea"
                v-model="form.upgradeReason"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="升级时间">
              <el-input v-model="form.createdTime" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="操作人">
              <el-input v-model="form.createdName" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 事件续报和事件终报详情 -->
    <el-dialog
      :title="titles"
      center
      :visible.sync="dialVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="工点名称">
          <el-input v-model="form.siteName" :disabled="true"></el-input>
        </el-form-item>
         <el-form-item label="地理位置">
          <el-input v-model="form.position" v-if="fierts" :disabled="true"></el-input>
        </el-form-item>
         <el-form-item label="发生时间">
          <el-input v-model="form.happenTime" v-if="fierts" :disabled="true"></el-input>
        </el-form-item>
         <el-form-item label="上报时间">
          <el-input v-model="form.time"  v-if="fierts" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="事件名称" v-if="fiert">
          <el-input v-model="form.eventName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="事件等级" v-if="fiert">
          <el-input v-model="form.eventLevel" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="事件经过">
          <el-input v-model="form.historyRecord" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="伤亡情况">
          <el-input
            type="textarea"
            v-model="form.casualties"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="处置情况">
          <el-input v-model="form.disposal" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="上报人" :disabled="true">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="照片或附件">
           <el-link
          v-for="(item,index) in enclosure"
          :key="index"
            type="primary"
            :href="item.uri"
            target="_blank"
            >{{ item.originalName }}</el-link
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 启动预案 -->
    <el-dialog
      title="启动预案"
      center
      :visible.sync="planVisible"
      width="30%"
      :before-close="handleClose"
    >
      <p style="font-size:16px;font-weight: bold;margin-left:20px;margin-top:20px">事件快报</p>
      <el-form
          ref="form"
          :model="eventsForm"
          label-width="110px"
          size="mini"
          class="px-4"
          label-position="left"
          style="margin-top:10px"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="事件类型">
                <el-input
                  v-model="eventsForm.typeName"
                  readonly
                  placeholder="事件类型"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发生时间">
                <el-input
                  v-model="eventsForm.created"
                  readonly
                  placeholder="发生时间"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="上报人">
                <el-input
                  v-model="eventsForm.publisherName"
                  readonly
                  placeholder="上报人"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="负责人">
                <el-input
                  v-model="eventsForm.chargeName"
                  readonly
                  placeholder="负责人"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="地理位置">
                <el-input
                  v-model="eventsForm.xycoors"
                  readonly
                  placeholder="地理位置"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工程项目名称">
                <el-input
                  v-model="eventsForm.siteName"
                  readonly
                  placeholder="工程项目名称"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="施工单位">
                <el-input
                  v-model="eventsForm.constructionUnitName"
                  readonly
                  placeholder="施工单位"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="监理单位">
                <el-input
                  v-model="eventsForm.supervisorUnitName"
                  readonly
                  placeholder="监理单位"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="事件经过">
            <el-input
              readonly
              type="textarea"
              resize="none"
              placeholder="请输入作业内容、事发情形等"
              :autosize="{ minRows: 2, maxRows: 4 }"
              v-model="eventsForm.historyRecord"
            ></el-input>
          </el-form-item>
          <el-form-item label="伤亡情况">
            <el-input
              readonly
              type="textarea"
              resize="none"
              placeholder="请输入人员伤亡、直接经济损失和影响范围"
              :autosize="{ minRows: 2, maxRows: 4 }"
              v-model="eventsForm.casualties"
            ></el-input>
          </el-form-item>
          <el-form-item label="处置情况">
            <el-input
              readonly
              type="textarea"
              resize="none"
              placeholder="请输入应急救援、已采取和拟采取的措施等"
              v-model="eventsForm.disposal"
            ></el-input>
          </el-form-item>
          <el-form-item label="照片或附件">
            <a
              v-for="(item, index) in imgers"
              :key="index"
              :href="item.url"
              target="_blank"
              style="color:#409EFF;display:block;"
              >{{ item.originalName }}</a
            >
          </el-form-item>
        </el-form>
          <p style="font-size:16px;font-weight: bold;margin-left:20px;margin-top:20px">预案详情</p>
      <el-form ref="form" :model="form" label-width="100px" >
        <el-form-item label="工点名称" style="margin-top:20px">
          <el-input v-model="form.siteName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="启动人">
          <el-input v-model="form.userName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="预案名称">
          <el-link :href="form.planFile" target="_blank">{{
            form.planName
          }}</el-link>
        </el-form-item>
        <el-form-item label="启动时间">
          <el-input v-model="form.planName" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 应急处置卡 -->
    <el-dialog
      title="详情"
      center
      :visible.sync="disposalVisible"
      width="1200px"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="事件类型">
              <el-input v-model="form.typeName" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发生时间">
              <el-input v-model="form.created" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="事件等级">
              <el-input v-model="form.levelName" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人">
              <el-input v-model="form.chargeName" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="地理位置">
              <el-input v-model="form.xycoors" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工程项目名称">
              <el-input v-model="form.siteName" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="施工单位">
              <el-input
                v-model="form.constructionUnitName"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="监理单位">
              <el-input
                v-model="form.supervisorUnitName"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="事件经过">
          <el-input v-model="form.historyRecord" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="启动预案：" style="margin-left:12px">
          <el-link :href="form.planFile"  type="primary" target="_blank">{{
            form.planName
          }}</el-link>
        </el-form-item>
        <el-form-item label="启动人：">
          <el-link>{{ form.userName }}</el-link>
        </el-form-item>
      </el-form>

      <div class="toobte">
        <div class="title">你的职责</div>
        {{ form.duty }}
      </div>
    </el-dialog>
    <!-- 应急预警驳回详情 -->
    <el-dialog
      title="应急预警"
      center
      :visible.sync="warningVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="100px">
        <!-- <el-form-item label="工点名称">
          <el-input v-model="form.siteName" :disabled="true"></el-input>
        </el-form-item> -->
     <el-form-item label="预警级别">
          <el-input v-model="form.warnLevel" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="预警原因">
          <el-input
            type="textarea"
            v-model="form.warnReason"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="预警时间">
          <el-input v-model="form.startTime" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="驳回原因">
          <el-input
            type="textarea"
            v-model="form.rejectReason"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="驳回时间">
          <el-input v-model="form.startTime" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="操作人">
          <el-input v-model="form.startUserName" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 应急预警查看详情 -->
    <el-dialog
      title="应急预警"
      center
      :visible.sync="earlyVisible"
      width="40%"
      :before-close="handleClose"
    >
         <p style="font-size:16px;font-weight: bold;margin-left:20px;margin-top:20px">事件快报</p>
      <el-form
          ref="form"
          :model="eventForm"
          label-width="110px"
          size="mini"
          class="px-4"
          label-position="left"
          style="margin-top:10px"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="事件类型">
                <el-input
                  v-model="eventForm.typeName"
                  readonly
                  placeholder="事件类型"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发生时间">
                <el-input
                  v-model="eventForm.created"
                  readonly
                  placeholder="发生时间"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="上报人">
                <el-input
                  v-model="eventForm.publisherName"
                  readonly
                  placeholder="上报人"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="负责人">
                <el-input
                  v-model="eventForm.chargeName"
                  readonly
                  placeholder="负责人"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="地理位置">
                <el-input
                  v-model="eventForm.xycoors"
                  readonly
                  placeholder="地理位置"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工程项目名称">
                <el-input
                  v-model="eventForm.siteName"
                  readonly
                  placeholder="工程项目名称"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="施工单位">
                <el-input
                  v-model="eventForm.constructionUnitName"
                  readonly
                  placeholder="施工单位"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="监理单位">
                <el-input
                  v-model="eventForm.supervisorUnitName"
                  readonly
                  placeholder="监理单位"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="事件经过">
            <el-input
              readonly
              type="textarea"
              resize="none"
              placeholder="请输入作业内容、事发情形等"
              :autosize="{ minRows: 2, maxRows: 4 }"
              v-model="eventForm.historyRecord"
            ></el-input>
          </el-form-item>
          <el-form-item label="伤亡情况">
            <el-input
              readonly
              type="textarea"
              resize="none"
              placeholder="请输入人员伤亡、直接经济损失和影响范围"
              :autosize="{ minRows: 2, maxRows: 4 }"
              v-model="eventForm.casualties"
            ></el-input>
          </el-form-item>
          <el-form-item label="处置情况">
            <el-input
              readonly
              type="textarea"
              resize="none"
              placeholder="请输入应急救援、已采取和拟采取的措施等"
              v-model="eventForm.disposal"
            ></el-input>
          </el-form-item>
          <el-form-item label="照片或附件">
            <a
              v-for="(item, index) in imgers"
              :key="index"
              :href="item.url"
              target="_blank"
              style="color:#409EFF;display:block;"
              >{{ item.originalName }}</a
            >
          </el-form-item>
        </el-form>
        <p style="font-size:16px;font-weight: bold;margin-left:30px;margin-top:20px">预警详情</p>
      <el-form ref="form" :model="form" label-width="100px" style="margin-top:10px">
        <!-- <el-form-item label="工点名称">
          <el-input v-model="eventForm.siteName" :disabled="true"></el-input>
        </el-form-item> -->
        <el-form-item label="预警级别">
          <el-input v-model="form.warnLevel" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="预警原因">
          <el-input
            type="textarea"
            v-model="form.warnReason"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="预警时间">
          <el-input v-model="form.startTime" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="操作人">
          <el-input v-model="form.startUserName" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 资源调配通知详情 -->
    <el-dialog
      title="资源调配"
      center
      :visible.sync="ploymentVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="工点名称">
          <el-input v-model="form.siteName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="地理位置">
          <el-input v-model="form.position" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="调配时间">
          <el-input v-model="form.deployTime" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="发布人">
          <el-input v-model="form.releaseName" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 资源调配配送详情 -->
    <el-dialog
      title="资源调配"
      center
      :visible.sync="distributionVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="调配工点">
          <el-input v-model="form.siteName" :disabled="true"></el-input>
        </el-form-item>
         <el-form-item label="地理位置">
          <el-input v-model="form.position" :disabled="true"></el-input>
        </el-form-item>
         <el-form-item label="调配内容">
          <el-input v-model="form.deployContent" :disabled="true"></el-input>
        </el-form-item>
          <el-form-item label="被调配方">
          <el-input v-model="form.allocateName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="调配人">
          <el-input v-model="form.deployName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="调配时间">
          <el-input v-model="form.deployTime" :disabled="true"></el-input>
        </el-form-item>
         <el-form-item label="指派人">
          <el-input v-model="form.assignName" :disabled="true"></el-input>
        </el-form-item>
         
        <el-form-item label="指派时间">
          <el-input v-model="form.assignTime" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="配送人">
          <el-input v-model="form.deliveryName" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="联系方式">
          <el-input v-model="form.mobile" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
      <!-- <baidu-map
        :center="pageds.center"
        :scroll-wheel-zoom="true"
        :zoom="pageds.zoom"
        class="map"
        @ready="handler"
        :mapClick="false"
      >
        <bm-marker
          :position="{ lng: 113.2839488029, lat: 23.0907270045 }"
          :dragging="true"
          animation="BMAP_ANIMATION_BOUNCE"
        >
        </bm-marker>
      </baidu-map> -->
    </el-dialog>
    <!-- 资源配送人通知详情 -->
    <el-dialog
      title="详情"
      center
      :visible.sync="notssiceVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="noticeer" label-width="100px">
        <el-form-item label="调配工点">
          <el-input v-model="noticeer.siteName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="配送内容">
          <el-input
            v-model="diset"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="地理位置">
          <el-input
            v-model="noticeer.deliveryPosition"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="指派人">
          <el-input v-model="noticeer.assignName" :disabled="true"></el-input>
        </el-form-item>
         
        <el-form-item label="指派时间">
          <el-input v-model="noticeer.assignTime" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="调配人">
          <el-input v-model="noticeer.deployName" :disabled="true"></el-input>
        </el-form-item>
           <el-form-item label="被调配方" prop="allocateName">
        <el-input
          v-model="noticeer.allocateName"
         :disabled="true"
          
        ></el-input>
      </el-form-item>
       <el-form-item label="调配时间" prop="deployTime">
        <el-input
          v-model="noticeer.deployTime"
          :disabled="true"
        
        ></el-input>
      </el-form-item>
      
       <el-form-item label="事件联络人" prop="eventName">
        <el-input
          v-model="noticeer.eventName"
          :disabled="true"
        
        ></el-input>
      </el-form-item>
       <p style="color:red" v-if="awerts">“电脑端无法进行物资配送，请前往手机端进行处理”</p>
        <el-form-item label="配送人" prop="dispatchName" v-if="awert">
        <el-input
          v-model="noticeer.dispatchName"
          :disabled="true"
        
        ></el-input>
      </el-form-item>
        <el-form-item label="送达时间" prop="endTime" v-if="awert">
        <el-input
          v-model="noticeer.endTime"
          :disabled="true"
        
        ></el-input>
      </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 资源调配驳回详情 -->
    <el-dialog
      title="详情"
      center
      :visible.sync="dismissVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="调配工点">
          <el-input v-model="form.siteName" :disabled="true"></el-input>
        </el-form-item>
          <el-form-item label="地理位置">
          <el-input v-model="form.position" :disabled="true"></el-input>
        </el-form-item>
         <el-form-item label="调配内容">
          <el-input v-model="form.deployContent" :disabled="true"></el-input>
        </el-form-item>
          <el-form-item label="被调配方">
          <el-input v-model="form.allocateName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="调配人">
          <el-input v-model="form.deployName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="调配时间">
          <el-input v-model="form.deployTime" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="驳回原因">
          <el-input v-model="form.rejectReason" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="驳回时间">
          <el-input v-model="form.rejectTime" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="驳回人">
          <el-input v-model="form.optionName" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 响应结束详情 -->
    <el-dialog
      title="详情"
      center
      :visible.sync="responseVisible"
      width="80%"
     
      :before-close="handsClose"
    >
     <div class="ertt">
    <el-tabs v-model="activeName" type="card" @tab-click="handClick">
      <el-tab-pane label="事件快报" name="1">
        <el-form
          :model="formData"
          ref="ruleForm "
          label-width="130px"
          v-loading="fromLoading"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="事件名称"
                prop="startTime"
                style="margin-top: 10px"
              >
                <!-- readonly -->
                <el-input
                  v-model="formData.name"
                  readonly
                  placeholder="事件名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="上报人" prop="type">
                <el-input
                  v-model="formData.publisherName"
                  readonly
                  placeholder="上报人"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            
            <el-col :span="12">
              <el-form-item label="事件类型" prop="currentLevel">
                <el-input
                  v-model="formData.typeName"
                  readonly
                  placeholder="事件类型"
                ></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="12">
              <el-form-item label="发生时间" prop="currentLevel">
                <el-input
                  v-model="formData.created"
                  readonly
                  placeholder="发生时间"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
           

            <el-col :span="12">
              <el-form-item label="事件等级" prop="currentLevel">
                <el-input
                  v-model="formData.levelName"
                  readonly
                  placeholder="事件等级"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地理位置" prop="currentLevel">
                <el-input
                  v-model="formData.xycoors"
                  readonly
                  placeholder="地理位置"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            
            <el-col :span="12">
              <el-form-item label="工程项目名称" prop="currentLevel">
                <el-input
                  v-model="formData.siteName"
                  readonly
                  placeholder="工程项目名称"
                ></el-input>
              </el-form-item>
            </el-col>
              <el-col :span="12">
              <el-form-item label="施工单位" prop="currentLevel">
                <el-input
                  v-model="formData.constructionUnitName"
                  readonly
                  placeholder="施工单位"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
          
            <el-col :span="12">
              <el-form-item label="监理单位" prop="currentLevel">
                <el-input
                  v-model="formData.supervisorUnitName"
                  readonly
                  placeholder="监理单位"
                ></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="12">
              <el-form-item label="负责人" prop="currentLevel">
                <el-input
                  v-model="formData.chargeName"
                  readonly
                  placeholder="负责人"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        
          <el-row>
            <el-col :span="24">
              <el-form-item label="事件经过" prop="originalAlertMessage">
                <el-input
                  readonly
                  type="textarea"
                  resize="none"
                  placeholder="请输入作业内容、事发情形等"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  v-model="formData.historyRecord"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="伤亡情况" prop="originalAlertMessage">
                <el-input
                  readonly
                  type="textarea"
                  resize="none"
                  placeholder="请输入人员伤亡、直接经济损失和影响范围"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  v-model="formData.casualties"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="处置情况" prop="originalAlertMessage">
                <el-input
                  readonly
                  type="textarea"
                  resize="none"
                  placeholder="请输入应急救援、已采取和拟采取的措施等"
                  v-model="formData.disposal"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="附件" prop="currentLevel">
                <a
                  v-for="(item, index) in imgs"
                  :key="index"
                  :href="item.url"
                  target="_blank"
                  style="color: #409eff; display: block"
                  >{{ item.originalName }}</a
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="事件终报" name="2" >
        <el-form
          :model="formInline"
          class="demo-form-inline"
          label-width="100px"
          ref="ruleForm"
        >
          <el-form-item
            label="事件名称:"
            prop="responseRecordName"
            style="margin-top: 10px"
          >
            <el-input
              readonly
              v-model="formInline.responseRecordName"
            ></el-input>
          </el-form-item>
          <el-form-item label="事件等级:" prop="responseLevle">
            <el-input readonly v-model="formInline.levelName"></el-input>
          </el-form-item>
          <el-form-item label="事发经过:" prop="historyRecord">
            <el-input
              readonly
              type="textarea"
              v-model="formInline.historyRecord"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="伤亡情况:" prop="casualties">
            <el-input
              readonly
              type="textarea"
              v-model="formInline.casualties"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="处置情况:" prop="disposal">
            <el-input
              readonly
              type="textarea"
              v-model="formInline.disposal"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="照片或附件:">
            <el-link
              type="primary"
              v-for="(item, index) in enclosure"
              :key="index"
              :href="item.uri"
              target="_blank"
              >{{ item.originalName }}</el-link
            >
          </el-form-item>
        </el-form></el-tab-pane
      >
      <el-tab-pane label="事件进展" name="3"
        ><div>
          <div v-for="(activity, index) in formData.processList" :key="index">
          
                <div class="actitle">
                <span class="incid">{{ activity.responseProcessName }}</span>
                <span class="times" style="margin-left:20px">{{ activity.created }}</span>
              </div>
              <div
                class="content"
                v-show="
                  activity.responseProcessName == '事件发布' ||
                  activity.responseProcessName == '事件续报' ||
                  activity.responseProcessName == '事件终报'
                "
              >
                <span class="incid"> 上报人：</span>
                <span>{{ activity.responseUserName }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '应急预警'"
              >
                <span class="incid"> 启动人员：</span>
                <span>{{ activity.responseUserName }}</span>
              </div>
               
                <div
                class="content"
                v-show="activity.responseProcessName == '资源调配'"
              >
                <span class="incid"> 调配人：</span>
                <span></span>
              </div>
               <div
                class="content"
                v-show="activity.responseProcessName == '资源调配'"
              >
                <span class="incid"> 调配时间：</span>
                <span></span>
              </div>
               <div
                class="content"
                v-show="activity.responseProcessName == '资源调配'"
              >
                <span class="incid"> 调配内容：</span>
                <span></span>
              </div>
              <div
                class="content"
                v-show="
                 
                  activity.responseProcessName == '事件终报'
                "
              >
                <span class="incid">事件等级:</span
                ><span>{{
                  activity.levelName ? activity.levelName : "Ⅲ"
                }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '应急预警'"
              >
                <span class="incid">预警等级:</span
                ><span>{{
                  activity.levelName ? activity.levelName : "Ⅲ"
                }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '响应升级'"
              >
                <span class="incid">升级级别:</span
                ><span v-if="activity.levelName == 'Ⅱ'"
                  >Ⅲ->{{ activity.levelName }}</span
                >
                <span v-if="activity.levelName == 'Ⅰ'"
                  >Ⅱ->{{ activity.levelName }}</span
                >
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '事件终报'"
              >
                <span class="incid"> 事件经过:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '事件确认'"
              >
                <span class="incid"> 确认人:</span
                ><span>{{ activity.createName }}</span>
              </div>
              <div
                class="content"
                v-show="
                  activity.responseProcessName == '事件续报' ||
                  activity.responseProcessName == '事件终报'
                "
              >
                <span class="incid"> 伤亡情况:</span
                ><span>{{ activity.casualties }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '响应升级'"
              >
                <span class="incid">升级原因:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '应急预警'"
              >
                <span class="incid">预警原因:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
              <div
                class="content"
                v-show="
                 
                  activity.responseProcessName == '响应升级'
                "
              >
                <span class="incid">升级人员:</span
                ><span>{{ activity.createName }}</span>
              </div>
               <div
                class="content"
                v-show="
                  activity.responseProcessName == '启动预案' 
                "
              >
                <span class="incid">启动人员:</span
                ><span>{{ activity.createName }}</span>
              </div>
                <div
                class="content"
                 v-show="
                  activity.responseProcessName == '事件续报'
                "
              >
                <span class="incid">事件经过:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
                <div
                class="content"
                 v-show="
                  activity.responseProcessName == '响应结束'
                "
              >
                <span class="incid">结束人员:</span
                ><span></span>
              </div>
               <div
                class="content"
                v-show="
                  activity.responseProcessName == '事件续报'
                "
              >
                <span class="incid">附件:</span
                ><el-link v-for="(item,index) in enclosure" :key="index"  :href="item.uri"  style="margin-left: 4px; font-size: 12px;"  type="primary">{{item.originalName}}</el-link>
              </div>
              <div class="content">
                <span
                  class="incid"
                  v-if="activity.responseProcessName == '启动预案' ||  activity.responseProcessName == '事件续报' ||
                    activity.responseProcessName == '事件终报'"
                  >{{
                    activity.responseProcessName == "启动预案"
                      ? "启动预案:"
                      : "处置情况:"
                  }}</span
                ><span v-if="activity.responseProcessName == '启动预案'"
                  ><el-link
                    type="primary"
                    style="margin-left: 4px; font-size: 12px"
                    target="_blank"
                    :href="activity.enclosure"
                    >《{{ activity.disposal }}》</el-link
                  >预案</span
                ><span
                  v-if="
                    activity.responseProcessName == '事件续报' ||
                    activity.responseProcessName == '事件终报'
                  "
                  >{{ activity.disposal }}</span
                >
              </div>
            <el-divider></el-divider>
          </div></div
      ></el-tab-pane>
      <el-tab-pane label="舆情信息" name="4">舆情信息</el-tab-pane>
      <el-tab-pane label="物资调配" name="5">
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          element-loading-spinner="el-icon-loading"
          :header-cell-style="{color:'#000000'}"
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="sourceName"
            label="被调配方"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="materialsName"
            label="调配内容"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="num"
            label="调配内容数量"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="deployDate"
            label="调配时间"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="chargeName"
            label="被调配方负责人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="chargeMobile"
            label="负责人联系方式"
          ></el-table-column>
          <el-table-column align="center" prop="state" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.state == 'WAIT_ALLOCATION'"
                >待分配配送人员
              </span>
              <span v-if="scope.row.state == 'IN_PROGRESS'"
                >{{scope.row.tabModel == "物资" ? "配送中" : "进行中" }}
              </span>
              <span v-if="scope.row.state == 'FINISH'">已到位</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="deliveryName"
            label="配送人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="deliveryMobile"
            label="配送人联系方式"
          ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="人员调配" name="6"
        ><el-table
          :data="tableDatas"
          style="width: 100%"
          border
          element-loading-spinner="el-icon-loading"
          :header-cell-style="{color:'#000000'}"
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="rescueTeamName"
            label="调配队伍"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="rescueName"
            label="调配人员"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="rescueMobile"
            label="联系方式"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="deployDate"
            label="调配时间"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="chargeName"
            label="被调配方负责人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="chargeMobile"
            label="负责人联系方式"
          ></el-table-column>
          <el-table-column align="center" prop="state" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.state == 'WAIT_ALLOCATION'"
                >待分配配送人员
              </span>
              <span v-if="scope.row.state == 'IN_PROGRESS'"
                >{{ scope.row.tabModel == "物资" ? "配送中" : "进行中" }}
              </span>
              <span v-if="scope.row.state == 'FINISH'">已到位</span>
            </template></el-table-column
          >
        </el-table></el-tab-pane
      >
    </el-tabs>

    <div style="display: flex; justify-content: center">
      <!-- <el-button type="primary" @click="sureSub('ruleForm')">确认</el-button> -->
    </div>
  </div>
     
    </el-dialog>
    <el-dialog
      title="响应结束"
      center
      :visible.sync="resonseerVisible"
      width="80%"
      :before-close="handleClose"
    >
    <div class="ertt">
    <el-tabs v-model="activeName" type="card" @tab-click="handClick">
      <el-tab-pane label="事件快报" name="1">
        <el-form
          :model="formData"
          ref="ruleForm "
          label-width="130px"
          v-loading="fromLoading"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="事件名称"
                prop="startTime"
                style="margin-top: 10px"
              >
                <!-- readonly -->
                <el-input
                  v-model="formData.name"
                  readonly
                  placeholder="事件名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="上报人" prop="type">
                <el-input
                  v-model="formData.publisherName"
                  readonly
                  placeholder="上报人"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            
            <el-col :span="12">
              <el-form-item label="事件类型" prop="currentLevel">
                <el-input
                  v-model="formData.typeName"
                  readonly
                  placeholder="事件类型"
                ></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="12">
              <el-form-item label="发生时间" prop="currentLevel">
                <el-input
                  v-model="formData.created"
                  readonly
                  placeholder="发生时间"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
           

            <el-col :span="12">
              <el-form-item label="事件等级" prop="currentLevel">
                <el-input
                  v-model="formData.levelName"
                  readonly
                  placeholder="事件等级"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地理位置" prop="currentLevel">
                <el-input
                  v-model="formData.xycoors"
                  readonly
                  placeholder="地理位置"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            
            <el-col :span="12">
              <el-form-item label="工程项目名称" prop="currentLevel">
                <el-input
                  v-model="formData.siteName"
                  readonly
                  placeholder="工程项目名称"
                ></el-input>
              </el-form-item>
            </el-col>
              <el-col :span="12">
              <el-form-item label="施工单位" prop="currentLevel">
                <el-input
                  v-model="formData.constructionUnitName"
                  readonly
                  placeholder="施工单位"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
          
            <el-col :span="12">
              <el-form-item label="监理单位" prop="currentLevel">
                <el-input
                  v-model="formData.supervisorUnitName"
                  readonly
                  placeholder="监理单位"
                ></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="12">
              <el-form-item label="负责人" prop="currentLevel">
                <el-input
                  v-model="formData.chargeName"
                  readonly
                  placeholder="负责人"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        
          <el-row>
            <el-col :span="24">
              <el-form-item label="事件经过" prop="originalAlertMessage">
                <el-input
                  readonly
                  type="textarea"
                  resize="none"
                  placeholder="请输入作业内容、事发情形等"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  v-model="formData.historyRecord"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="伤亡情况" prop="originalAlertMessage">
                <el-input
                  readonly
                  type="textarea"
                  resize="none"
                  placeholder="请输入人员伤亡、直接经济损失和影响范围"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  v-model="formData.casualties"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="处置情况" prop="originalAlertMessage">
                <el-input
                  readonly
                  type="textarea"
                  resize="none"
                  placeholder="请输入应急救援、已采取和拟采取的措施等"
                  v-model="formData.disposal"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="附件" prop="currentLevel">
                <a
                  v-for="(item, index) in imgs"
                  :key="index"
                  :href="item.url"
                  target="_blank"
                  style="color: #409eff; display: block"
                  >{{ item.originalName }}</a
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="事件终报" name="2" >
        <el-form
          :model="formInline"
          class="demo-form-inline"
          label-width="100px"
          ref="ruleForm"
        >
          <el-form-item
            label="事件名称:"
            prop="responseRecordName"
            style="margin-top: 10px"
          >
            <el-input
              readonly
              v-model="formInline.responseRecordName"
            ></el-input>
          </el-form-item>
          <el-form-item label="事件等级:" prop="responseLevle">
            <el-input readonly v-model="formInline.levelName"></el-input>
          </el-form-item>
          <el-form-item label="事发经过:" prop="historyRecord">
            <el-input
              readonly
              type="textarea"
              v-model="formInline.historyRecord"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="伤亡情况:" prop="casualties">
            <el-input
              readonly
              type="textarea"
              v-model="formInline.casualties"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="处置情况:" prop="disposal">
            <el-input
              readonly
              type="textarea"
              v-model="formInline.disposal"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="照片或附件:">
            <el-link
              type="primary"
              v-for="(item, index) in enclosure"
              :key="index"
              :href="item.uri"
              target="_blank"
              >{{ item.originalName }}</el-link
            >
          </el-form-item>
        </el-form></el-tab-pane
      >
      <el-tab-pane label="事件进展" name="3"
        ><div>
          <div v-for="(activity, index) in formData.processList" :key="index">
          
                <div class="actitle">
                <span class="incid">{{ activity.responseProcessName }}</span>
                <span class="times" style="margin-left:20px">{{ activity.created }}</span>
              </div>
              <div
                class="content"
                v-show="
                  activity.responseProcessName == '事件发布' ||
                  activity.responseProcessName == '事件续报' ||
                  activity.responseProcessName == '事件终报'
                "
              >
                <span class="incid"> 上报人：</span>
                <span>{{ activity.responseUserName }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '应急预警'"
              >
                <span class="incid"> 启动人员：</span>
                <span>{{ activity.responseUserName }}</span>
              </div>
               
                <div
                class="content"
                v-show="activity.responseProcessName == '资源调配'"
              >
                <span class="incid"> 调配人：</span>
                <span></span>
              </div>
               <div
                class="content"
                v-show="activity.responseProcessName == '资源调配'"
              >
                <span class="incid"> 调配时间：</span>
                <span></span>
              </div>
               <div
                class="content"
                v-show="activity.responseProcessName == '资源调配'"
              >
                <span class="incid"> 调配内容：</span>
                <span></span>
              </div>
              <div
                class="content"
                v-show="
                 
                  activity.responseProcessName == '事件终报'
                "
              >
                <span class="incid">事件等级:</span
                ><span>{{
                  activity.levelName ? activity.levelName : "Ⅲ"
                }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '应急预警'"
              >
                <span class="incid">预警等级:</span
                ><span>{{
                  activity.levelName ? activity.levelName : "Ⅲ"
                }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '响应升级'"
              >
                <span class="incid">升级级别:</span
                ><span v-if="activity.levelName == 'Ⅱ'"
                  >Ⅲ->{{ activity.levelName }}</span
                >
                <span v-if="activity.levelName == 'Ⅰ'"
                  >Ⅱ->{{ activity.levelName }}</span
                >
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '事件终报'"
              >
                <span class="incid"> 事件经过:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '事件确认'"
              >
                <span class="incid"> 确认人:</span
                ><span>{{ activity.createName }}</span>
              </div>
              <div
                class="content"
                v-show="
                  activity.responseProcessName == '事件续报' ||
                  activity.responseProcessName == '事件终报'
                "
              >
                <span class="incid"> 伤亡情况:</span
                ><span>{{ activity.casualties }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '响应升级'"
              >
                <span class="incid">升级原因:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '应急预警'"
              >
                <span class="incid">预警原因:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
              <div
                class="content"
                v-show="
                 
                  activity.responseProcessName == '响应升级'
                "
              >
                <span class="incid">升级人员:</span
                ><span>{{ activity.createName }}</span>
              </div>
               <div
                class="content"
                v-show="
                  activity.responseProcessName == '启动预案' 
                "
              >
                <span class="incid">启动人员:</span
                ><span>{{ activity.createName }}</span>
              </div>
                <div
                class="content"
                 v-show="
                  activity.responseProcessName == '事件续报'
                "
              >
                <span class="incid">事件经过:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
                <div
                class="content"
                 v-show="
                  activity.responseProcessName == '响应结束'
                "
              >
                <span class="incid">结束人员:</span
                ><span></span>
              </div>
               <div
                class="content"
                v-show="
                  activity.responseProcessName == '事件续报'
                "
              >
                <span class="incid">附件:</span
                ><el-link v-for="(item,index) in enclosure" :key="index"  :href="item.uri"  style="margin-left: 4px; font-size: 12px;"  type="primary">{{item.originalName}}</el-link>
              </div>
              <div class="content">
                <span
                  class="incid"
                  v-if="activity.responseProcessName == '启动预案' ||  activity.responseProcessName == '事件续报' ||
                    activity.responseProcessName == '事件终报'"
                  >{{
                    activity.responseProcessName == "启动预案"
                      ? "启动预案:"
                      : "处置情况:"
                  }}</span
                ><span v-if="activity.responseProcessName == '启动预案'"
                  ><el-link
                    type="primary"
                    style="margin-left: 4px; font-size: 12px"
                    target="_blank"
                    :href="activity.enclosure"
                    >《{{ activity.disposal }}》</el-link
                  >预案</span
                ><span
                  v-if="
                    activity.responseProcessName == '事件续报' ||
                    activity.responseProcessName == '事件终报'
                  "
                  >{{ activity.disposal }}</span
                >
              </div>
            <el-divider></el-divider>
          </div></div
      ></el-tab-pane>
      <el-tab-pane label="舆情信息" name="4">舆情信息</el-tab-pane>
      <el-tab-pane label="物资调配" name="5">
         <el-table
          :data="tableData"
          style="width: 100%"
          border
          element-loading-spinner="el-icon-loading"
          :header-cell-style="{color:'#000000'}"
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
          ></el-table-column>
           <el-table-column
            align="center"
            prop="sourceName"
            label="被调配方"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="chargeName"
            label="被调配方负责人"
          ></el-table-column>
            <el-table-column
            align="center"
            prop="chargeMobile"
            label="被调配方联系方式"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="materialsNameList"
            label="调配内容"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="deployName"
            label="调配人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="deployDate"
            label="调配时间"
          ></el-table-column>
          
        
          <el-table-column align="center" prop="state" label="状态">
           
          </el-table-column>
          <el-table-column
            align="center"
            prop="deliveryName"
            label="配送人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="deliveryMobile"
            label="配送人联系方式"
          ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="人员调配" name="6"
        ><el-table
          :data="tableDatas"
          style="width: 100%"
          border
          element-loading-spinner="el-icon-loading"
          :header-cell-style="{color:'#000000'}"
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="rescueTeamName"
            label="调配队伍"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="rescueName"
            label="调配人员"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="rescueMobile"
            label="联系方式"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="deployDate"
            label="调配时间"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="chargeName"
            label="被调配方负责人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="chargeMobile"
            label="负责人联系方式"
          ></el-table-column>
          <el-table-column align="center" prop="state" label="状态">
            <!-- <template slot-scope="scope">
              <span v-if="scope.row.state == 'WAIT_ALLOCATION'"
                >待分配配送人员
              </span>
              <span v-if="scope.row.state == 'IN_PROGRESS'"
                >{{ scope.row.tabModel == "物资" ? "配送中" : "进行中" }}
              </span>
              <span v-if="scope.row.state == 'FINISH'">已到位</span>
            </template> -->
            </el-table-column
          >
        </el-table></el-tab-pane
      >
    </el-tabs>

    <div style="display: flex; justify-content: center">
      <!-- <el-button type="primary" @click="sureSub('ruleForm')">确认</el-button> -->
    </div>
  </div>
      
      <!-- <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="工点名称">
          <el-input v-model="form.siteName" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="结束时间">
          <el-input v-model="form.time" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="发布人">
          <el-input v-model="form.name" :disabled="true"></el-input>
        </el-form-item>
      </el-form> -->
    </el-dialog>
  </div>
</template>

<script>
import { notification, getPlan } from "@/api/emergency.js";
import {
  allocatesrt,
  distributorNotice,
  suppliesInform,
  rejectedGoods,
} from "@/api/eres.js";
import {
  getIncident,
  getReported,
  responseEnd,
  getWarningInfo,
  detailsList,
  getUpgrade,
  // getFillert,
} from "@/api/response.js";
import { updateReadNotificationState } from "@/api/notic.js";
import { materialsRecord, userRecord } from "@/api/eres";
export default {
  data() {
    return {
      titesle: "",
      form: {},
      noticeer: {},
      fiert: false,
      fierts:false,
      activeName: "first",
      matchinputs: "",
      pages: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      pageds: {
        start: "",
        end: "",
        center: { lng: 0, lat: 0 },
        zoom: 12,
      },
      eventsForm:{},
      dialogVisible: false,
      dialVisible: false,
      planVisible: false,
      disposalVisible: false,
      warningVisible: false,
      earlyVisible: false,
      ploymentVisible: false,
      distributionVisible: false,
      notssiceVisible: false,
      dismissVisible: false,
      responseVisible: false,
      resonseerVisible: false,
      gridlist: [],
      imgs: [],
      user: {},
      formInline: {},
      enclosure: [],
      diset:"",
  enclosures:[],
   eventForm: {},
   titles:"",
     fromLoading: false,
      activeName: "1",
      formData: {},
      imgs: [],
      formInline: {},
      tableData: [],
      tableDatas: [],
      page: 1,
      size: 1000000,
      total: 0,
      detailData:{},
     imgers:[],
      awert:false,
      awerts:false
   
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
  },
  methods: {
    updetr(ert, val) {
      updateReadNotificationState({ userId: ert, messageId: val }).then(
        (res) => {
          console.log(res);
        }
      );
    },

    //地图加载后事件
    handler({ map }) {
      map.setMapStyleV2({
        styleId: "565ab0773031556035c58e365ee8547a",
      });

      this.pageds = {
        start: "",
        end: "",
        center: { lng: 113.2839488029, lat: 23.0907270045 },
        zoom: 14,
      };
    },
    //响应升级查看详情
    async upgradets(record) {
      console.log(record);
      const data = await getUpgrade(record.eventId);
      this.dialogVisible = true;
      if (data.code == 0) {
        detailsList({ uuid: data.data.responseEventUuId }).then((res) => {
          console.log(res)
          this.form = res.data;
          this.form.currentLevelName = data.data.currentLevelName;
          this.form.upgradeReason = data.data.upgradeReason;
          this.form.createdTime = data.data.createdTime;
          this.form.createdName = data.data.createdName;

          this.imgs = [];

          if (res.data.processList && res.data.processList.length) {
            res.data.processList.forEach((element) => {
              console.log(element)
              if(element.enclosure!=null){
                this.imgs.push.apply(this.imgs,JSON.parse(element.enclosure))
                  console.log(this.imgs)
              }
              
            
              // JSON.parse(element.enclosure).forEach((list) => {
              //   this.imgs.push(list.url);
              // });
            });
          }
        });
      }
      this.updetr(this.user.uuid, record.id);
      console.log(data);
      

      //   this.titesle = "详情";
    },
    //事件续报查看详情
    async coinues(record) {
      const data = await getIncident({ eventId: record.eventId });
      console.log(data);
      this.updetr(this.user.uuid, record.id);
      if (data.code == 0) {
        this.form = data.data;
       
        this.enclosure = JSON.parse(data.data.enclosure);
        console.log(this.enclosure);
        this.dialVisible = true;
        this.titles="事件续报"
        this.fiert = false;
        this.fierts=true
      }
    },
    //事件终报查看详情
    async conterties(record) {
      const data = await getReported({ eventId: record.eventId });
      this.updetr(this.user.uuid, record.id);
      if (data.code == 0) {
        this.form = data.data;
        this.dialVisible = true;
        this.fiert = true;
         this.fierts=false
        this.titles="事件终报"
      }
    },
    //启动预案
    async plart(record) {
        
      const data = await notification({ id: record.eventId });
      console.log(data)
      this.updetr(this.user.uuid, record.id);
        detailsList({ uuid: data.data.responseEventUuId }).then((res) => {
            this.eventsForm = res.data;
            this.imgs = [];

            this.imgs = [];

          if (res.data.processList && res.data.processList.length) {
            res.data.processList.forEach((element) => {
    
              if(element.enclosure!=null){
                this.imgs.push.apply(this.imgs,JSON.parse(element.enclosure))
          
              }
              
            
            });
          }
           
          });
            
      this.form = data.data;
      this.planVisible = true;
    },

    //应急处置卡
    async cards(record) {
      console.log(record);
      const data = await getPlan({
        eventId: record.eventId,
        roleId: this.user.roleIdList[0],
      });
      this.updetr(this.user.uuid, record.id);
      console.log(data);
      this.form = data.data;
      this.disposalVisible = true;
    },
    //应急预警驳回详情
    warningts(record) {
      getWarningInfo(record.eventId).then((data) => {
        console.log(data);
        if (data.code == 0) {
          detailsList({ uuid: data.data.responseEventUuId }).then((res) => {
         
            this.form = data.data;
            this.form.siteName = res.data.siteName;
          });
          this.updetr(this.user.uuid, record.id);
          console.log(this.form);
          this.warningVisible = true;
        }
      });
    },
    //应急预警查看详情
    wartesrte(record) {
      console.log(record);
      getWarningInfo(record.eventId).then((data) => {
     
        if (data.code == 0) {
          detailsList({ uuid: data.data.responseEventUuId }).then((res) => {
            console.log(res.data)
            this.form = data.data;
            // this.form.siteName = res.data.siteName;
             this.eventForm =res.data
              if (res.data.processList[0].enclosure) {
          try {
            this.imgers = JSON.parse(res.data.processList[0].enclosure);
          } catch (error) {
            this.imgers = [];
          }
        }
          });
          this.updetr(this.user.uuid, record.id);
          console.log(this.form);
          this.earlyVisible = true;
        }
      });
    },

 
    //资源调配通知
    async ployment(record) {
      console.log(record, 1111111111111);
      const data = await distributorNotice({ eventId: record.eventId });
      this.updetr(this.user.uuid, record.id);
      console.log(data.data)
      if (data.code == 0) {
        console.log(data.data.endTime);
        if(data.data.endTime!==undefined){
          this.awert=true
           this.awerts=false
        }else{
           this.awert=false
           this.awerts=true
        }
        this.noticeer = data.data;
        this.diset=data.data.deliveryContent.join(",")
        this.notssiceVisible = true;
        console.log(this.notssiceVisible);
      }
    },
    //资源调配配送详情
    async distributions(record) {
      const data = await allocatesrt({ eventId: record.eventId });
      this.updetr(this.user.uuid, record.id);
      console.log(data);
      if (data.code == 0) {
        this.form = data.data;
        this.distributionVisible = true;
      }
    },
    //资源配送人通知详情
    async notice(record) {
      const data = await suppliesInform({ eventId: record.eventId });
      this.updetr(this.user.uuid, record.id);
      console.log(data);
      if (data.code == 0) {
        this.form = data.data;
        this.ploymentVisible = true;
      }
    },
    //资源调配驳回
    async dismiss(record) {
      const data = await rejectedGoods({ eventId: record.eventId });
      this.updetr(this.user.uuid, record.id);
      if (data.code == 0) {
        this.form = data.data;
        this.dismissVisible = true;
      }
    },
    //响应结束
    async response(record) {
      const data = await responseEnd({ eventId: record.eventId });
      // const datas = await getFillert({ eventId: record.eventId });
        const datas = await detailsList({ id: record.eventId });
      console.log(data);
       this.detailData = datas.data;
      this.updetr(this.user.uuid, record.id);
      if (datas.code == 0) {
     
        this.activeName = "1";
        if (this.detailData.processList) {
          this.detailData.processList.forEach((e) => {
            if (e.enclosure != null && e.responseProcessName == "事件发布") {
              // this.imgs.push.apply(this.imgs, [JSON.parse(JSON.stringify(e.enclosure))]);
              this.imgs = JSON.parse(e.enclosure);
            }
          });
        }
        if (this.detailData.enclosure != null) {
          this.imgs.push(JSON.parse(this.detailData.enclosure));
        }
        console.log(this.detailData);
        this.formData = this.detailData;
        if (this.detailData.processList) {
          this.detailData.processList.forEach((el) => {
            if (
              el.responseProcessName == "事件终报" ||
              el.responseProcessName == "事件续报"
            ) {
              this.enclosures = JSON.parse(el.enclosure);
            }
          });
        }
        if (this.detailData.processList) {
          this.detailData.processList.forEach((el) => {
            if (el.responseProcessName == "事件终报") {
              this.formInline = el;
              this.enclosure = JSON.parse(this.formInline.enclosure);
             
            }
          });
        }
       this.responseVisible = true;
      }
      
    },
    async resonseer(record) {
      const data = await responseEnd({ eventId: record.eventId });
       const datas = await detailsList({ id: record.eventId });
         this.detailData = datas.data;
      this.updetr(this.user.uuid, record.id);
     console.log(data)
         if (datas.code == 0) {
     
        this.activeName = "1";
        if (this.detailData.processList) {
          this.detailData.processList.forEach((e) => {
            if (e.enclosure != null && e.responseProcessName == "事件发布") {
              // this.imgs.push.apply(this.imgs, [JSON.parse(JSON.stringify(e.enclosure))]);
              this.imgs = JSON.parse(e.enclosure);
            }
          });
        }
        if (this.detailData.enclosure != null) {
          this.imgs.push(JSON.parse(this.detailData.enclosure));
        }
        console.log(this.detailData);
        this.formData = this.detailData;
        if (this.detailData.processList) {
          this.detailData.processList.forEach((el) => {
            if (
              el.responseProcessName == "事件终报" ||
              el.responseProcessName == "事件续报"
            ) {
              this.enclosures = JSON.parse(el.enclosure);
            }
          });
        }
        if (this.detailData.processList) {
          this.detailData.processList.forEach((el) => {
            if (el.responseProcessName == "事件终报") {
              this.formInline = el;
              this.enclosure = JSON.parse(this.formInline.enclosure);
             
            }
          });
        }
       this.resonseerVisible = true;
      }
      
    },
    handleClose() {
      console.log(2222222222)
         this.$parent.teryt();
      this.dialogVisible = false;
      this.dialVisible = false;
      this.planVisible = false;
      this.disposalVisible = false;
      this.warningVisible = false;
      this.earlyVisible = false;
      this.ploymentVisible = false;
      this.distributionVisible = false;
     this.notssiceVisible =  false;
      this.dismissVisible = false;
   
   this.resonseerVisible=false

    },
    handsClose(){
         this.responseVisible = false;
       this.$parent.terty()
    },
     //物资表格数据
    getmaterialsListData() {
      let parms = {
        id: this.detailData.uuid,
        // id: 2,
        page: this.page,
        size: this.size,
      };

      materialsRecord(parms).then((res) => {
        console.log(res.data.records)
        this.tableData = res.data;
      });
    },
    getmaertye() {
      let parms = {
        id: this.detailData.uuid,
        // id: 2,
        page: this.page,
        size: this.size,
      };
      userRecord(parms).then((res) => {
        
        this.tableDatas = res.data;
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handClick(tab, event) {
      console.log(tab, event);
      if (tab.name == "5") {
        this.getmaterialsListData();
      } else if (tab.name == "6") {
        this.getmaertye();
      }
    },
    sureSub(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fromLoading = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #ffffff;
}
::v-deep.el-textarea.is-disabled .el-textarea__inner {
  background-color: #ffffff;
}
.partime {
  width: 35%;
}
::v-deep .partime .el-input__inner {
  width: 150px;
}
::v-deep .el-table td div {
  color: #000000;
}
::v-deep .modal-wrapper[data-v-1210670c] .el-table th > .cell,
.modal-wrapper[data-v-1210670c] .el-table th.el-table__cell {
  color: #000000;
}
.toobte {
  width: 90%;
  height: 200px;
  border: 1px solid #606266;
  margin: auto;
  margin-top: 20px;
  color: #606266;
  text-indent: 5px;

  .title {
    font-size: 15px;
    text-align: center;
  }
}
.map {
  width: 500px;
  height: 300px;
  margin-top: 2%;
  margin-left: 5%;
}
.el-link {
  margin-left:12px;
  
}
.ertt {
  height: 500px;
  overflow-y: scroll;
}
</style>