<template>
  <systemModal
    ref="LogForm"
    :width="width"
    :title="title"
    :showFooter="false"
  >
    <div>
      <div class="leading-6 mt-6 mb-6">
        <span>名称：{{ model.name }}</span
        ><br />
      </div>
      <div class="text-center">
        <span class="mr-8">
          <el-button type="danger" @click="deleteData">删除</el-button>
        </span>
      </div>
    </div>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "操作",
      width: "400px",
      model: [],
      remet: "",
      loading: false,
      stateList: ["更新", "上传", "审核通过", "审核不通过", "删除"],
    };
  },
  mounted() {},
  methods: {
    handleAdd(data) {
      this.model = data;
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.LogForm.handleOpen();
    },
    handleClose(){
      this.$refs.LogForm.handleClose();
    },
    deleteData() {
      this.$emit('modalConfirm')
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
