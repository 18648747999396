<template>
  <div class="trow">
    <el-table
      v-if="optionType == 2"
      :data="tableHeaderData"
      height="103"
      border
      style="width: 100%; margin-bottom: 20px"
    >
      <!-- <el-table-column
        align="center"
        prop="optionUnitName"
        show-overflow-tooltip
        label="所属总承包部"
      ></el-table-column> -->
      <el-table-column
        align="center"
        prop="teamStation"
        show-overflow-tooltip
        label="队伍驻地"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="rescueScope"
        show-overflow-tooltip
        label="救援区域"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="captainName"
        show-overflow-tooltip
        label="负责人"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="captainPhone"
        show-overflow-tooltip
        label="联系方式"
      >
      </el-table-column>
    </el-table>
    <el-table
      v-if="optionType == 1"
      :data="tableHerData"
      height="110"
      border
      style="width: 100%; margin-bottom: 20px"
    >
      <!-- <el-table-column
        align="center"
        prop="superviseName"
        show-overflow-tooltip
        label="所属建管部"
      ></el-table-column> -->
      <el-table-column
        align="center"
        prop="lineName"
        show-overflow-tooltip
        label="线路名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="branchName"
        show-overflow-tooltip
        label="分部名称"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="siteName"
        show-overflow-tooltip
        label="工点名称"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="leaderName"
        show-overflow-tooltip
        label="负责人"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="mobile"
        show-overflow-tooltip
        label="联系方式"
      >
      </el-table-column>
    </el-table>

    <div class="tag-group">
      <el-tag
        v-for="tag in tags"
        :key="tag.teamId"
        closable
        type="danger"
        style="margin-left: 5px"
        @close="handleClose(tag)"
      >
        {{ tag.workerName }}
      </el-tag>
      <el-tag
        v-for="tag in tages"
        :key="tag.id"
        closable
        type="danger"
        style="margin-left: 5px"
        @close="handloses(tag)"
      >
        {{ tag.label }}*{{ tag.num }}
      </el-tag>
      <el-tag
        v-for="tag in tarts"
        :key="tag.id"
        closable
        type="danger"
        style="margin-left: 5px"
        @close="hases(tag)"
      >
        {{ tag.label }}*{{ tag.num }}
      </el-tag>
    </div>
    <div class="taper">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-input
          v-model="libraryForm.modelNo"
          placeholder="输入关键字查询"
          style="width: 17%; border: 1px solid #143363"
          class="partime"
        ></el-input>
        <el-button
          type="primary"
          @click="onSubmit"
          style="margin-left: 10px; background: #43a5e9"
          >查询</el-button
        >
        <el-tab-pane
          label="人员名单"
          name="first"
          style="color: #ffffff"
          v-if="firster"
        >
          <el-table
            :row-key="rowKey"
            ref="userTable"
            :data="gridData"
            border
            height="300"
            :header-cell-style="{
              background: '#0E2553',
              color: '#ffffff',
              border: '1px solid #193F71',
            }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column
              property="workerName"
              label="姓名"
            ></el-table-column>
            <el-table-column
              property="workRoleName"
              label="职务"
            ></el-table-column>
            <el-table-column
              property="workerPhone"
              label="联系电话"
            ></el-table-column>
            <el-table-column
              property="workerEducation"
              label="学历"
            ></el-table-column>
            <el-table-column
              property="workerSpecialty"
              label="专业"
            ></el-table-column>
            <el-table-column label="照片">
              <template slot-scope="scope">
                <span>
                  <img
                    :src="scope.row.workerImg"
                    alt=""
                    v-if="scope.row.workerImg"
                    style="width: 50px; height: 50px"
                  />
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane
          label="物资清单"
          name="second"
          v-if="supplies"
          :row-key="rowKey"
        >
          <div class="table-box">
            <el-table
              v-if="gridDatas"
              ref="suppliesTable"
              :data="gridDatas"
              border
              height="300"
              :header-cell-style="{
                background: '#0E2553',
                color: '#ffffff',
                border: '1px solid #193F71',
              }"
              @selection-change="handlenChange"
              @cell-mouse-enter="handleCellMouseEnter"
              @cell-mouse-leave="handleCellMouseLeave"
            >
              <el-table-column
                type="selection"
                width="55"
                :reserve-selection="true"
              >
              </el-table-column>
              <el-table-column
                property="label"
                label="物资名称"
              ></el-table-column>
              <el-table-column
                property="model"
                label="规格/型号"
              ></el-table-column>
              <el-table-column
                property="unit"
                label="计量单位"
              ></el-table-column>
              <el-table-column
                property="count"
                label="库存数量"
              ></el-table-column>
              <el-table-column
                property="usages"
                label="用途"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  {{ JSON.parse(scope.row.usages).join(",") }}
                </template></el-table-column
              >
              <el-table-column property="remark" label="备注"></el-table-column>
              <el-table-column label="调配数量" property="num">
                <template slot-scope="scope">
                  <el-input-number
                    v-model="scope.row.num"
                    :min="0"
                    :max="scope.row.count"
                    label="描述文字"
                    size="mini"
                    style="height: 26px; background-color: #081742"
                    @change="handleChange(scope.row)"
                  ></el-input-number>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="设备清单" name="third" v-if="equipment">
          <el-table
            :row-key="rowKey"
            ref="equipmentTable"
            :data="equipmentList"
            border
            height="300"
            :header-cell-style="{
              background: '#0E2553',
              color: '#ffffff',
              border: '1px solid #193F71',
            }"
            @selection-change="handChange"
            @cell-mouse-enter="handleCellMouseEnter"
            @cell-mouse-leave="handleCellMouseLeave"
          >
            <el-table-column
              type="selection"
              width="55"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column
              property="label"
              label="物资名称"
            ></el-table-column>
            <el-table-column
              property="model"
              label="规格/型号"
            ></el-table-column>
            <el-table-column property="unit" label="计量单位"></el-table-column>
            <el-table-column
              property="count"
              label="库存数量"
            ></el-table-column>
            <el-table-column
              property="usages"
              label="用途"
              :show-overflow-tooltip="true"
              ><template slot-scope="scope">
                {{ JSON.parse(scope.row.usages).join(",") }}
              </template></el-table-column
            >
            <el-table-column property="remark" label="备注"></el-table-column>
            <el-table-column label="调配数量" property="num">
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.num"
                  :min="0"
                  :max="scope.row.count"
                  @change="handange(scope.row)"
                  label="描述文字"
                  size="mini"
                  style="height: 26px; background-color: #081742"
                ></el-input-number>
              </template>
            </el-table-column> </el-table
        ></el-tab-pane>
        <div class="pagination-wrapper flex justify-center mt-4">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="libraryForm.count"
            :page-count="libraryForm.total"
            :page-size="libraryForm.pageSize"
            :current-page="libraryForm.pageNo"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          >
          </el-pagination>
          <!-- <el-pagination layout="prev, pager, next" :total="libraryForm.total">
          </el-pagination> -->
        </div>
      </el-tabs>
      <el-row class="allocert">
        <div class="terit">
          <el-button type="primary" @click="allocate">一键调配</el-button>
          <el-button type="primary" @click="reset()">重置</el-button>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  getWorkers,
  getMaterialsPage,
  allocation,
  getWareHouseManList,
} from "@/api/eres";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
export default {
  props: {
    libraryData: {
      type: Object,
    },
    evebty: {
      type: Object,
    },
    lablename: {
      type: String,
    },
  },
  watch: {
    libraryData: {
      handler(val) {
        console.log(val, "8888888");
        this.tableHeaderData = [];
        this.tableHeaderData.push(val);
        this.libraryForm.siteIds.push(val.siteId);
        this.libraryForm.name = val.lablename;
        if (val.library == "物资库") {
          this.optionType = 1;
          this.firster = false;
          this.supplies = true;
          this.equipment = true;
          this.activeName = "second";
          this.getHeadDatas();
          this.getListData();
          // this.pmentList();
        } else if (val.library == "专职救援队" || val.library == "专职") {
          this.optionType = 2;
          this.activeName = "first";
          this.tabname = "人员名单";
          this.firster = true;
          this.supplies = true;
          this.equipment = true;
          this.getDatalist();
          this.getHeadDatas();
          // this.pmentList();
        } else if (val.library == "兼职") {
          this.optionType = 2;
          this.firster = true;
          this.supplies = false;
          this.equipment = false;
          this.getDatalist();
        }
      },
      deep: true,
      immediate: true,
    },
    evebty: {
      handler(val) {
        console.log(val, "888888888888888888888888888");
      },
      deep: true,
      immediate: true,
    },
    lablename: {
      handler(val) {
        console.log(val);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      gridData: [],
      tableHeaderData: [],
      tableHerData: [],
      gridDatas: [],
      equipmentList: [],
      dialogallocate: false,
      form: {},
      tags: [
        // { name: "标签一" },
        // { name: "标签二" },
        // { name: "标签三" },
        // { name: "标签四" },
        // { name: "标签五" },
      ],
      tages: [],
      tarts: [],
      activeName: "first",
      num: 1,
      tablekey: false,
      firster: true,
      supplies: true,
      equipment: true,
      libraryForm: {
        label: "",
        modelNo: "",
        state: "",
        siteIds: [],
        type: "126",
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
        name: "",
        postId: "-",
        teamId: "",
      },
      total: 0,
      optionType: 1,
      datalist: {},
      itery: [],
      tabname: "物资清单",
      dict: {
        model: [],
        usages: [],
        state: [
          {
            label: "正常",
            value: 1,
          },
          {
            label: "异常",
            value: 2,
          },
        ],
      },
    };
  },
  created() {
    this.tableHerData = [];
    this.gridData = [];
    this.gridDatas = [];
    this.equipmentList = [];
  },
  mounted() {
    // this.$EventBus.$on('uploading', val=>{
    //      this.firster=val
    //    })
    this.getDictData("物资用途", this.dict.usages);
  },
  methods: {
    getHeadDatas() {
      this.libraryForm.siteIds = [];
      this.libraryForm.siteIds.push(this.libraryData.siteId);
      const params = {
        siteIds: this.libraryForm.siteIds,
        name: "",
        page: this.libraryForm.pageNo,
        size: this.libraryForm.pageSize,
      };
      getWareHouseManList(params).then((res) => {
        if (res.code == 0) {
          this.tableHerData = res.data.records;
        }
      });
    },
    rowKey(row) {
      return row.id;
    },
    //人员
    getDatalist() {
      const params = {
        teamId: this.libraryData.id,
        postId: this.libraryForm.postId ? this.libraryForm.postId : "-",
        name: this.libraryForm.modelNo ? this.libraryForm.modelNo : "-",
        page: this.libraryForm.pageNo,
        size: this.libraryForm.pageSize,
      };
      getWorkers(params).then((res) => {
        if (res.code == 0) {
          this.gridData = res.data.records;
          this.libraryForm.pageNo = res.data.current;
          this.libraryForm.pageSize = res.data.size;
          this.libraryForm.total = res.data.total;
          this.libraryForm.count = res.data.count;
        }
      });
    },
    //物资
    getListData() {
      console.log(this.libraryForm.id, "999999");
      this.libraryForm.siteIds = [];

      this.libraryForm.name = this.libraryData.lablename;
      const params = {
        materialType: 2,
        siteIds: [],
        type: "125",
        state: this.libraryForm.state,

        label: this.libraryForm.modelNo,
        page: this.libraryForm.pageNo,
        size: this.libraryForm.pageSize,
        rescueTeamId: this.libraryData.id,
      };
      getMaterialsPage(params).then((res) => {
        if (res.code == 0) {
          res.data.records &&
            res.data.records.forEach((el) => {
              el.num = 0;
            });
          this.gridDatas =
            res.data.records && res.data.records.length > 0
              ? res.data.records
              : [];

          this.libraryForm.pageNo = res.data.current;
          this.libraryForm.pageSize = res.data.size;
          this.libraryForm.total = res.data.total;
          this.libraryForm.count = res.data.count;
          // console.log(res.data.total, 11111);
          // console.log(this.libraryForm, 22222);
        }
      });
    },
    //设备
    pmentList() {
      this.libraryForm.siteIds = [];
      this.libraryForm.siteIds.push(this.libraryData.siteId);
      this.libraryForm.name = this.libraryData.lablename;
      const params = {
        materialType: 2,
        siteIds: [],
        type: "126",
        state: this.libraryForm.state,
        label: this.libraryForm.modelNo,
        page: this.libraryForm.pageNo,
        size: this.libraryForm.pageSize,
        rescueTeamId: this.libraryData.id,
      };
      getMaterialsPage(params).then((res) => {
        if (res.code == 0) {
          res.data.records &&
            res.data.records.forEach((el) => {
              el.num = 0;
            });
          this.equipmentList =
            res.data.records && res.data.records.length > 0
              ? res.data.records
              : [];
          this.libraryForm.pageNo = res.data.current;
          this.libraryForm.pageSize = res.data.size;
          this.libraryForm.total = res.data.total;
          this.libraryForm.count = res.data.count;
        }
      });
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 鼠标滑入单元格
    handleCellMouseEnter(row, column, cell, event) {
      if (column.property == "usages") {
        cell.querySelector(".cell").style.cursor = "pointer";
        console.log(row, column, cell, event);
        const labelArr = [];
        const usageStr = cell.querySelector(".cell").innerHTML;
        this.usageCache = usageStr;
        let usageArr = [];
        if (usageStr.length) {
          usageArr = usageStr.split("");
        }
        for (let usage of usageArr) {
          const tempObj = this.dict.usages.find((item) => item.label == usage);
          if (tempObj) {
            labelArr.push(tempObj);
          }
        }
        let str = "";
        for (let item of labelArr) {
          str += `<li>${item.label}.${item.intro}</li>`;
        }
        cell.querySelector(".cell").innerHTML = `<ul>${str}</ul>`;
      }
    },
    // 鼠标移出单元格
    handleCellMouseLeave(row, column, cell, event) {
      if (column.property == "usages") {
        console.log(row, column, cell, event);
        cell.querySelector(".cell").innerHTML = this.usageCache;
      }
    },
    onSubmit() {
      console.log(this.tabname, "777777");
      if (this.tabname == "物资清单") {
        this.getListData();
      } else if (this.tabname == "设备清单") {
        this.pmentList();
      } else {
        this.getDatalist();
      }
    },
    handleClick(tab, event) {
      (this.libraryForm = {
        label: "",
        modelNo: "",
        state: "",
        siteIds: [],
        type: "126",
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
        name: "",
        postId: "-",
        teamId: "",
      }),
        console.log(this.libraryData);
      this.libraryForm.siteIds.push(this.libraryData.siteId);
      this.tabname = tab.label;
      console.log(tab, event);
      if (tab.label == "物资清单") {
        this.getListData();
      } else if (tab.label == "人员名单") {
        this.getDatalist();
      } else {
        this.pmentList();
      }
    },
    //人员勾选表格
    handleSelectionChange(val) {
      this.tags = val;
    },
    //物资勾选表格
    handlenChange(val) {
      console.log(this.tages);
      console.log(val[val.length - 1]);

      var arr = val.map((item) => {
        return item.id;
      });
      this.gridDatas.forEach((item) => {
        if (!arr.includes(item.id)) {
          item.num = 0;
        } else if (arr.includes(item.id) && item.num == 0) {
          console.log(item);
          item.num = 1;
        }
      });

      this.tages = val;
    },
    //设备勾选
    handChange(val) {
      var arr = val.map((item) => {
        return item.id;
      });
      this.equipmentList.forEach((item) => {
        if (!arr.includes(item.id)) {
          item.num = 0;
        } else if (arr.includes(item.id) && item.num == 0) {
          console.log(item);
          item.num = 1;
        }
      });

      this.tarts = val;
    },
    reset() {
      // this.gridData = [];
      // this.gridDatas = [];
      if (this.activeName == "first") {
        this.$refs.suppliesTable.clearSelection();
        this.$refs.equipmentTable.clearSelection();
        this.$refs.userTable.clearSelection();
      } else {
        this.$refs.suppliesTable.clearSelection();
        this.$refs.equipmentTable.clearSelection();
      }

      this.tags = [];
      this.tages = [];
      this.tarts = [];

      // this.$emit("closeter");
    },
    clonwer() {
      this.tags = [];
      this.tages = [];
      this.tarts = [];

      if (this.activeName == "first") {
        this.$refs.suppliesTable.clearSelection();
        this.$refs.equipmentTable.clearSelection();
        this.$refs.userTable.clearSelection();
      } else {
        this.$refs.suppliesTable.clearSelection();
        this.$refs.equipmentTable.clearSelection();
      }
    },
    handleChange(val) {
      if (val.num != 0) {
        this.gridDatas.forEach((item) => {
          if (item.num == val.num) {
            this.itery.push(item);
            this.$refs.suppliesTable.toggleRowSelection(item, true);
          }
        });
      } else {
        this.tages.splice(
          this.tages.findIndex((item) => item.id == val.id),
          1
        );

        this.gridDatas.forEach((item) => {
          if (item.id == val.id) {
            this.$refs.suppliesTable.toggleRowSelection(item, false);
          }
        });
      }
    },
    handange(val) {
      if (val.num != 0) {
        this.equipmentList.forEach((item) => {
          if (item.num == val.num) {
            this.$refs.equipmentTable.toggleRowSelection(item, true);
          }
        });
      } else {
        this.tarts.splice(
          this.tarts.findIndex((item) => item.id == val.id),
          1
        );
        this.equipmentList.forEach((item) => {
          if (item.id == val.id) {
            this.$refs.equipmentTable.toggleRowSelection(item, false);
          }
        });
      }
    },
    //一键调配

    allocate() {
      let materialsDeployDetailsDtoList = [];
      let materialsDeployStaffDtoList = [];
      let conwrt = {
        materialsId: "",
        materialsName: "",
        num: "",
      };
      let reponer = {
        mobile: "",
        userId: "",
        userName: "",
      };

      this.tags.forEach((el) => {
        (reponer.mobile = el.workerPhone),
          (reponer.userId = el.workRoleId),
          (reponer.userName = el.workerName);
        materialsDeployStaffDtoList.push(reponer);
      });
      this.tages.forEach((el) => {
        (conwrt.materialsId = el.id),
          (conwrt.materialsName = el.label),
          (conwrt.num = el.num);
        materialsDeployDetailsDtoList.push(conwrt);
      });
      this.tarts.forEach((el) => {
        (conwrt.materialsId = el.id),
          (conwrt.materialsName = el.label),
          (conwrt.num = el.num);
        materialsDeployDetailsDtoList.push(conwrt);
      });

      if (this.optionType == 1) {
        const obj = {
          chargeId: this.tableHerData[0].leaderId,
          chargeMobile: this.tableHerData[0].mobile,
          chargeName: this.tableHerData[0].leaderName,
          eventId: this.evebty.uuid,
          optionType: this.optionType,
          materialsDeployDetailsDtoList: materialsDeployDetailsDtoList
            ? materialsDeployDetailsDtoList
            : [],
          materialsDeployStaffDtoList: materialsDeployStaffDtoList
            ? materialsDeployStaffDtoList
            : [],
          siteName: this.tableHerData[0].siteName,
          siteId: this.tableHerData[0].siteId,
        };

        allocation(obj).then((res) => {
          if (res.code == 0) {
            this.$emit("starts", this.libraryData.coord);
            this.$emit("end", this.evebty.coord);
            this.$EventBus.$emit("starts", this.libraryData.coord);
            this.$EventBus.$emit("end", this.evebty.coord);
            this.$emit("closeter");
            this.gridData = [];
            this.gridDatas = [];

            this.tags = [];
            this.tages = [];
            this.tarts = [];
          }
        });
      }
      if (this.optionType == 2) {
        this.libraryData.coord = [this.libraryData.lng, this.libraryData.lat];
        this.datalist = {
          chargeId: this.libraryData.captainUuid,
          chargeMobile: this.libraryData.captainPhone,
          chargeName: this.libraryData.captainName,
          eventId: this.evebty.uuid,
          optionType: this.optionType,
          materialsDeployDetailsDtoList: materialsDeployDetailsDtoList
            ? materialsDeployDetailsDtoList
            : [],
          materialsDeployStaffDtoList: materialsDeployStaffDtoList
            ? materialsDeployStaffDtoList
            : [],
          rescueTeamName: this.libraryData.teamName,
          rescueTeamId: this.libraryData.id,
        };
        allocation(this.datalist).then((res) => {
          if (res.code == 0) {
            this.$emit("starts", this.libraryData.coord);
            this.$emit("end", this.evebty.coord);
            this.$EventBus.$emit("starts", this.libraryData.coord);
            this.$EventBus.$emit("end", this.evebty.coord);
            this.$emit("closeter");
            this.gridData = [];
            this.gridDatas = [];

            this.tags = [];
            this.tages = [];
            this.tarts = [];
          }
        });
      }
    },
    // getdatelsir(val){
    //        this.gridData.forEach((el) => {
    //     if (val.workerName == el.workerName) {
    //       this.gridData.$remove(el);
    //     }
    //   })
    // },
    //移除人员标签
    handleClose(val) {
      this.tags.splice(this.tags.indexOf(val), 1);
      this.$refs.userTable.toggleRowSelection(val, false);
    },
    //移除物资标签
    handloses(val) {
      this.tages.splice(this.tages.indexOf(val), 1);
      this.$refs.suppliesTable.toggleRowSelection(val, false);
    },
    //移除设备标签
    hases(val) {
      this.tarts.splice(this.tarts.indexOf(val), 1);
      this.$refs.equipmentTable.toggleRowSelection(val, false);
    },
    handleCloses(val) {
      console.log(val);
    },

    handleCurrentChange(val) {
      this.libraryForm.pageNo = val;
      if (this.tabname == "物资清单") {
        this.getListData();
      } else if (this.tabname == "设备清单") {
        this.pmentList();
      } else {
        this.getDatalist();
      }
    },
    handleSizeChange(val) {
      this.libraryForm.pageSize = val;
      if (this.tabname == "物资清单") {
        this.getListData();
      } else if (this.tabname == "设备清单") {
        this.pmentList();
      } else {
        this.getDatalist();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 36px !important;
  border: none;
  background: #18467f;
}
::v-deep .el-input-number .el-input__inner {
  color: #ffffff;
}
::v-deep .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  background: #081742;
  border: 1px solid #002e75;
  color: #ffffff;
}
::v-deep .el-tabs__item.is-active {
  color: #409eff;
}
::v-deep .el-tabs__item {
  color: #ffffff;
}
::v-deep .el-table__header-wrapper {
  background-color: #081742;
}

::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
  border: 1px solid #002e75;
  background-color: #081742;
  color: white;
  margin-top: 20px;
}
::v-deep .el-table tbody tr:hover > td {
  background-color: #081742 !important;
  border-color: #143363;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: #409eff;

  background-color: #409eff !important;
}
::v-deep .el-checkbox__inner {
  background-color: #081742 !important;
  border: 1px solid #143363;
}
::v-deep .el-input-number--mini .el-input-number__decrease {
  background: #409eff;
}
::v-deep .el-input-number--mini .el-input-number__increase {
  background: #409eff;
}
::v-deep .el-input-number__increase {
  border: none;
}
::v-deep .el-input-number__decrease {
  border: none;
}
::v-deep .el-tag {
  background: none;
  border: none;
  color: #ffffff;
}
.top {
  width: 100%;
  height: 300px;
}

::v-deep .el-dialog .el-dialog__body {
  max-height: 900px !important;
  overflow: auto;
  padding-top: 15px;
  padding-bottom: 0;
}
.tag-group {
  margin-top: 20px;
  min-height: 40px;
  max-height: 70px;
  width: 85%;
  overflow: auto;
  position: relative;
}
.tag-group::-webkit-scrollbar {
  width: 0;
}
.el-table::before {
  height: 0;
}
.el-table--border::after,
.el-table--group::after {
  width: 0;
}
.dialog-footer {
  width: 30%;
  margin: 2% auto;
  display: flex;
  justify-content: space-between;
}
::v-deep .el-table__header {
  background: #081742 !important;
}
::v-deep .el-table {
  width: 100%;

  background: transparent;

  .el-table__body-wrapper {
    height: 300px;
  }

  &::before {
    background-color: #143363;
  }

  thead {
    color: #000;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: rgba(20, 51, 99, 0.5);
  }

  tr,
  th {
    background-color: transparent;
  }

  td {
    border-bottom: 1px solid #143363;
  }

  .cell {
    color: #fff;
  }

  th.is-leaf {
    border: 1px solid #143363;
  }
}

.el-table {
  height: 330px;
  overflow-y: auto;
}
.table-box {
  ::v-deep .is-scrolling-none {
    overflow: hidden;
    overflow-y: auto;
    overflow-x: scroll;
  }
  ::v-deep .is-scrolling-none::-webkit-scrollbar {
    display: none;
  }
  ::v-deep.el-table--scrollable-y ::-webkit-scrollbar {
    display: none;
  }
  ::v-deep.el-table--scrollable-x ::-webkit-scrollbar {
    display: none;
  }
}

.allocert {
  position: absolute;
  top: 26%;
  left: 84%;
}
.terit {
  width: 180px;
  height: 40px;
  display: flex;
}
</style>
