<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-model"
    >
      <el-form-item
        label="参与工点"
        prop="siteIds"
        v-if="companyType === 'construct'"
      >
        <el-cascader
          v-model="ruleForm.lineSite"
          style="width: 100%;"
          clearable
          :options="treeData"
          :props="props"
          ref="cascaderTree"
          :show-all-levels="false"
          collapse-tags
          @change="handleLineSiteChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item
        label="主办单位"
        prop="organizer"
        v-if="companyType === 'construct'"
      >
        <el-input
          v-model="ruleForm.organizer"
          placeholder="请输入主办单位"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="参与队伍"
        prop="rescueTeamId"
        v-if="companyType === 'fullEmergencyRescue'"
      >
        <el-select
          v-model="ruleForm.rescueTeamId"
          placeholder="参与队伍"
          style="width: 100%"
        >
          <el-option
            v-for="item in rescueTeamList"
            :key="item.id"
            :label="item.teamName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="参与单位" prop="participants">
        <el-input
          v-model="ruleForm.participants"
          placeholder="请输入参与单位"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练内容" prop="planContent">
        <el-input
          v-model="ruleForm.planContent"
          placeholder="请输入演练内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练类型" prop="drillTypeCode">
        <!--        <el-input v-model="ruleForm.category" placeholder="请输入演练地点"></el-input>-->
        <el-select
          v-model="ruleForm.drillTypeCode"
          placeholder="演练类型"
          @change="drillChange"
          style="width: 100%"
        >
          <el-option
            v-for="item in categoryList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="演练科目" prop="planSubjectCode">
        <!--        <el-input v-model="ruleForm.planSubjectCode" placeholder="请输入演练地点"></el-input>-->
        <el-select
          v-model="ruleForm.planSubjectCode"
          placeholder="演练科目"
          style="width: 100%"
        >
          <el-option
            v-for="item in subjectList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="演练级别" prop="drillLevelCode">
        <!--        <el-input v-model="ruleForm.drillLevelCode" placeholder="请输入演练地点"></el-input>-->
        <el-select
          v-model="ruleForm.drillLevelCode"
          placeholder="演练级别"
          style="width: 100%"
        >
          <el-option
            v-for="item in levelList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="演练地点" prop="address">
        <el-input
          v-model="ruleForm.address"
          placeholder="请输入演练地点"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练人数" prop="planJoinCount">
        <el-input
          v-model="ruleForm.planJoinCount"
          placeholder="演练人数"
        ></el-input>
      </el-form-item>
      <!--      <el-form-item label="演练类型" prop="label">
        <el-select v-model="model.label" placeholder="请选择演练类型">
            <el-option label="1" value="1"></el-option>
            <el-option label="2" value="2"></el-option>
        </el-select>
      </el-form-item>-->
      <el-form-item label="计划演练时间" prop="planDate">
        <el-date-picker
          v-model="ruleForm.planDate"
          type="date"
          style="width: 100%"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="计划演练时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="附件" prop="label">
        <Uploader @uploader="uploader" :accept="'.pdf,image/*'"></Uploader>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submitPlan">确 定</el-button>
    </div>
  </div>
</template>

<script>
import Uploader from "@/views/Contingency/System/TrainingDrill/components/Uploader";
import { getDictData } from "@/api/dict";
import { addRehearsalBuild, addRehearsalTeam } from "@/api/stand";
import { getQxlist } from "@/api/eres";
export default {
  name: "addPlan",
  props: {
    companyType: {
      default: "construct",
    },
  },
  components: {
    Uploader,
  },
  data() {
    return {
      ruleForm: {
        organizer: "",
        rescueTeamId: "",
        participants: "",
        planContent: "",
        drillTypeCode: "",
        planSubjectCode: "",
        drillLevelCode: "",
        address: "",
        planJoinCount: "",
        planDate: "",
        siteUuid: "",
        siteId: "",
        siteIds: [],
        lineSiteDeptVoList: [],
        fileUrl: "",
      },
      rules: {
        siteIds: [
          { required: true, message: "参与工点不能为空", trigger: "blur" },
        ],
        rescueTeamId: [
          { required: true, message: "参与队伍不能为空", trigger: "blur" },
        ],
        participants: [
          { required: true, message: "参与单位不能为空", trigger: "blur" },
        ],
        planContent: [
          { required: true, message: "演练内容不能为空", trigger: "blur" },
        ],
        drillTypeCode: [
          { required: true, message: "演练类型不能为空", trigger: "blur" },
        ],
        planSubjectCode: [
          { required: true, message: "演练科目不能为空", trigger: "blur" },
        ],
        drillLevelCode: [
          { required: true, message: "演练级别不能为空", trigger: "blur" },
        ],
        address: [
          { required: true, message: "演练地点不能为空", trigger: "blur" },
        ],
        planJoinCount: [
          { required: true, message: "演练人数不能为空", trigger: "blur" },
        ],
        planDate: [
          { required: true, message: "计划演练时间不能为空", trigger: "blur" },
        ],
      },
      tags: [],
      dialogVisible: false,
      treeData: [],
      props: {
        children: "children",
        label: "name",
        // value: "id",
        value: "uuid",
        // multiple: true,
        // checkStrictly : true
      },
      categoryList: [],
      subjectList: [],
      levelList: [],
      flatTreeData: [],
      rescueTeamList: [],
    };
  },
  created() {
    this.getLineSiteTreeData();
    this.getSelectList();
    this.getRescueData();
  },
  methods: {
    close() {
      this.$emit("successAdd");
    },
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      console.log(lineSiteData);
      if (lineSiteData) {
        this.treeData = lineSiteData;
        console.log(this.treeData);
      }
      this.flatArr(lineSiteData);
    },
    uploader(event) {
      console.log(event);
      // this.ruleForm.courseware.file_name = event.originalName;
      this.ruleForm.fileUrl = event.uri;
      this.ruleForm.fileName = event.originalName;
    },
    // 数组扁平化
    flatArr(data) {
      for (let item of data) {
        this.flatTreeData.push(item);
        if (item.children) {
          this.flatArr(item.children);
        }
      }
    },
    getRescueData() {
      getQxlist().then((res) => {
        if (res.code == 0) {
          this.rescueTeamList = res.data;
        }
      });
    },
    // 级联选择器事件
    handleLineSiteChange(record) {
      console.log(record);
      this.ruleForm.siteIds = [];
      for (let item of record) {
        this.ruleForm.siteIds.push(item[item.length - 1]);
      }
      const siteNodes = this.$refs.cascaderTree.getCheckedNodes();
      console.log(siteNodes);
      this.ruleForm.organizer = siteNodes[0].data.constructionUnitName;
    },
    getSelectList() {
      let stringList = ["eventType", "drill_type", "drill_level"];
      stringList.forEach((item) => {
        this.getDictList(item);
      });
    },
    getDictList(label) {
      getDictData(label).then((res) => {
        switch (label) {
          case "eventType":
            this.categoryList = res.data.map((item) => {
              item.value = item.label + "," + item.id;
              return item;
            });
            break;
          case "drill_type":
            this.subjectList = res.data.map((item) => {
              item.value = item.label + "," + item.id;
              return item;
            });
            break;
          case "drill_level":
            this.levelList = res.data.map((item) => {
              item.value = item.label + "," + item.id;
              return item;
            });
        }
      });
    },
    drillChange(event) {
      console.log(event);
    },
    submitPlan() {
      let that = this;
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          var params = {};
          if (this.companyType === "construct") {
            const siteNodes = this.$refs.cascaderTree.getCheckedNodes();
            console.log(siteNodes);
            for (let node of siteNodes) {
              this.ruleForm.lineSiteDeptVoList.push({
                branchId: node.path[1],
                branchName: node.pathLabels[1],
                lineId: node.path[0],
                lineName: node.pathLabels[0],
                areaUuid: node.path[2],
                areaName: node.pathLabels[2],
                siteId: node.value,
                siteName: node.label,
                superviseId: node.data.constructionManagementUnitId,
                superviseName: node.data.constructionManagementName,
                constructionManagementUnitId:
                  node.data.constructionManagementUnitId,
                constructionUnitName: node.data.constructionUnitName,
                constructionUnitId: node.data.constructionUnitId,
              });
            }
            console.log(this.ruleForm.lineSiteDeptVoList);
            params = {
              address: this.ruleForm.address,
              lineName: this.ruleForm.lineSiteDeptVoList[0].lineName,
              lineUuid: this.ruleForm.lineSiteDeptVoList[0].lineId,
              branchUuid: this.ruleForm.lineSiteDeptVoList[0].branchId,
              branchName: this.ruleForm.lineSiteDeptVoList[0].branchName,
              areaUuid: this.ruleForm.lineSiteDeptVoList[0].areaUuid,
              areaName: this.ruleForm.lineSiteDeptVoList[0].areaName,
              siteName: this.ruleForm.lineSiteDeptVoList[0].siteName,
              siteUuid: this.ruleForm.lineSiteDeptVoList[0].siteId,
              organizer: this.ruleForm.organizer,
              organizerUuid: this.ruleForm.lineSiteDeptVoList[0]
                .constructionUnitId,
              superviseUuid: this.ruleForm.lineSiteDeptVoList[0].superviseId,
              superviseName: this.ruleForm.lineSiteDeptVoList[0].superviseName,
              drillLevel: this.ruleForm.drillLevelCode.split(",")[0],
              drillLevelCode: this.ruleForm.drillLevelCode.split(",")[1],
              drillType: this.ruleForm.drillTypeCode.split(",")[0],
              drillTypeCode: this.ruleForm.drillTypeCode.split(",")[1],
              planSubject: this.ruleForm.planSubjectCode.split(",")[0],
              planSubjectCode: this.ruleForm.planSubjectCode.split(",")[1],
              planDate: this.ruleForm.planDate,
              fileUrl: this.ruleForm.fileUrl,
              fileName: this.ruleForm.fileName,
              planJoinCount: this.ruleForm.planJoinCount,
              planContent: this.ruleForm.planContent,
              participants: this.ruleForm.participants,
            };
            addRehearsalBuild(params).then((res) => {
              console.log(res);
              if (res.code == 0) {
                this.$message.success("添加成功");
                this.$emit("successAdd");
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            params = {
              address: this.ruleForm.address,
              drillLevel: this.ruleForm.drillLevelCode.split(",")[0],
              drillLevelCode: this.ruleForm.drillLevelCode.split(",")[1],
              drillType: this.ruleForm.drillTypeCode.split(",")[0],
              drillTypeCode: this.ruleForm.drillTypeCode.split(",")[1],
              planSubject: this.ruleForm.planSubjectCode.split(",")[0],
              planSubjectCode: this.ruleForm.planSubjectCode.split(",")[1],
              planDate: this.ruleForm.planDate,
              fileUrl: this.ruleForm.fileUrl,
              fileName: this.ruleForm.fileName,
              planJoinCount: this.ruleForm.planJoinCount,
              planContent: this.ruleForm.planContent,
              participants: this.ruleForm.participants,
              rescueTeamId: this.ruleForm.rescueTeamId,
            };
            let rescueTeamName = that.rescueTeamList.filter((item) => {
              return params.rescueTeamId == item.id;
            });
            params.rescueTeamName = rescueTeamName[0].teamName;
            params.superviseUuid = rescueTeamName[0].superviseUuid; //建管部
            params.superviseName = rescueTeamName[0].superviseName; //建管部
            params.optionUnitName = rescueTeamName[0].optionUnitName; //总包单位
            params.optionUnitUuid = rescueTeamName[0].optionUnitUuid; //总包单位
            addRehearsalTeam(params).then((res) => {
              console.log(res);
              if (res.code == 0) {
                this.$message.success("添加成功");
                this.$emit("successAdd");
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-class {
  display: flex;
  flex-direction: column;
}
</style>
