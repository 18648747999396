<template>
  <div class="page-container bg-white p-4 h-full">
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      style="margin-bottom: 20px; font-size: 16px"
    >
      <el-breadcrumb-item
        v-if="type !== 'stance'"
        :to="type == 'SoleDuty' ? { path: 'SoleDuty' } : { path: 'Pluralism' }"
        >{{
          type == "SoleDuty" ? "专职救援队" : "兼职救援队"
        }}</el-breadcrumb-item
      >
      <el-breadcrumb-item
        v-if="type == 'stance'"
        :to="{ path: 'Assistance' }"
        >{{ "社会救援队" }}</el-breadcrumb-item
      >
      <el-breadcrumb-item>人员名单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item>
            <el-input
              v-model="queryModal.workerName"
              clearable
              placeholder="输入姓名查询"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="handleAdd" v-if="show"
            >新增</el-button
          >
          <el-button type="primary" @click="handleExport" v-if="show"
            >导出</el-button
          >
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="loading"
        border
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="60"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="workerName"
          label="姓名"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="workRoleName"
          label="职务"
         show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="workerPhone"
          show-overflow-tooltip
          label="联系电话"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="workerEducation"
          show-overflow-tooltip
          label="学历"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="workerSpecialty"
          show-overflow-tooltip
          label="专业"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="permit"
          width="150"
          label="特种作业证"
        >
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.permit"
              style="width: 100px; height: 100px"
              :src="scope.row.permit"
              :preview-src-list="[scope.row.permit]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="photo" width="150" label="照片">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.workerImg"
              style="width: 100px; height: 100px"
              :src="scope.row.workerImg"
              :preview-src-list="[scope.row.workerImg]"
            >
            </el-image> </template
        ></el-table-column>
        <el-table-column
          align="center"
          prop="threeLevelSafetyEduImg"
          show-overflow-tooltip
          label="三级安全教育"
        >
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.threeLevelSafetyEduImg"
              style="width: 100px; height: 100px"
              :src="scope.row.threeLevelSafetyEduImg"
              :preview-src-list="[scope.row.threeLevelSafetyEduImg]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="preJobTrainingImg"
          show-overflow-tooltip
          label="上岗前培训"
        >
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.preJobTrainingImg"
              style="width: 100px; height: 100px"
              :src="scope.row.preJobTrainingImg"
              :preview-src-list="[scope.row.preJobTrainingImg]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="skillsTrainingImg"
          show-overflow-tooltip
          label="技能培训"
        >
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.skillsTrainingImg"
              style="width: 100px; height: 100px"
              :src="scope.row.skillsTrainingImg"
              :preview-src-list="[scope.row.skillsTrainingImg]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="epidemicPreventionDataImg"
          show-overflow-tooltip
          label="疫情防控资料"
        >
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.epidemicPreventionDataImg"
              style="width: 100px; height: 100px"
              :src="scope.row.epidemicPreventionDataImg"
              :preview-src-list="[scope.row.epidemicPreventionDataImg]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="medicalExaminationReportImg"
          label="个人年度体检报告"
          show-overflow-tooltip
          width="160"
        >
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.medicalExaminationReportImg"
              style="width: 100px; height: 100px"
              :src="scope.row.medicalExaminationReportImg"
              :preview-src-list="[scope.row.medicalExaminationReportImg]"
            >
            </el-image>
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)" v-if="show"
              >编辑</el-button
            >

            <el-button
              class="ml-2"
              @click="handleDelete(scope.row)"
              v-if="show"
              type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <MemberForm
      ref="modelForm"
      :typesr="type"
      :orgTree="orgTree"
      @formSubmit="handleFormSubmit"
    ></MemberForm>
    <!-- 预览图片 -->
    <el-image-viewer
      v-if="fileimage"
      :on-close="
        () => {
          (fileimage = false), (filepaths = '');
        }
      "
      :url-list="filepaths"
    />
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import MemberForm from "./modules/MemberForm.vue";
import { queryRescueTeamMemberList, deleteRescueTeamMember } from "@/api/eres";
import { getLoginUserDeptList } from "@/api/organization";
export default {
  components: {
    MemberForm,
    ElImageViewer,
  },
  data() {
    return {
      teamId: "",
      teamName: "",
      type: "",
      show: false,
      queryModal: {
        workRoleName: "",
        workerName: "",
        workerSpecialty: "",
      },
      tableData: [],
      loading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      orgTree: [],
      fileimage: false, //组件的显示隐藏
      filepaths: [], //因为预览组件是集合
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.teamId = this.$route.query.id;
    this.teamName = this.$route.query.name;
    this.show = this.$route.query.isShow;
  },
  mounted() {
    this.loadData();
    this.getLoginUserDeptList();
  },
  methods: {
    // 根据当前登录人单位获取组织结构权限树
    async getLoginUserDeptList() {
      let res = await getLoginUserDeptList({});
      if (res.code == 0) {
        // this.orgTree = res.data;
        res.data.forEach((i) => {
          if (i.category == "fullEmergencyRescue") {
            this.orgTree = [i];
          }
        });
      }
    },
    handleSearch() {
      this.loadData();
    },
    handleExport() {
      console.log("导出");
    },
    handleAdd() {
      let teamInfo = {
        teamId: this.teamId,
        teamName: this.teamName,
      };
      this.$refs.modelForm.handleAdd(teamInfo);
    },
    handleEdit(record) {
      let teamInfo = {
        teamId: this.teamId,
        teamName: this.teamName,
      };
      this.$refs.modelForm.handleAdd(teamInfo, record);
    },
    handleDelete(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteRescueTeamMember(record.id).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadData();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    handleFormSubmit() {
      this.loadData();
    },
    loadData() {
      this.loading = true;
      const params = {
        page: this.page.pageNo,
        size: this.page.pageSize,
        teamId: this.teamId,
        postId: this.queryModal.workRoleName
          ? this.queryModal.workRoleName
          : "-",
        name: this.queryModal.workerName ? this.queryModal.workerName : "-",
      };
      queryRescueTeamMemberList(params).then((res) => {
        if (res.code == 0) {
          const { current, size, total, count } = res.data;
          this.tableData = res.data.records ? res.data.records : [];
          this.page.pageNo = current;
          this.page.pageSize = size;
          this.page.total = total;
          this.page.count = count;
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep ::-webkit-scrollbar {
  height: 10px !important; /*对水平流动条有效*/
  display: block !important;
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: #fff !important;
  border-radius: 7px;
}
/*定义滑块颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}

//去掉固定列下方的横线
::v-deep .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: transparent;
}
//去掉固定列阴影
::v-deep .el-table__fixed-right {
  // box-shadow: none;
  height: 100% !important;
}
.p-4 {
  overflow-y: scroll;
}
</style>
