<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>

<!--          <el-form-item label="">
            <el-select v-model="queryModal.screeningType" placeholder="线路" style="width: 150px">
              <el-option
                  v-for="item in lineSiteData"
                  :key="item.value"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="queryModal.categoryPid" @change="dangerChange" placeholder="分部/标段">
              <el-option
                  v-for="item in dangerType"
                  :key="item.value"
                  :label="item.categoryName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="queryModal.categoryId" placeholder="工点">
              <el-option
                  v-for="item in dangerSon"
                  :key="item.value"
                  :label="item.categoryName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>-->
          <el-form-item label="">
            <el-cascader v-model="lineSite"
                         clearable :options="treeData" :props="props"
                         :show-all-levels="false" collapse-tags @change="handleLineSiteChange"></el-cascader>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>

<!--    <div class="tabs">
      <div class="tabs-item" :class="active === '开始排查' ?  'active' : '' " @click="changeTab('开始排查')">开始排查</div>
      <div class="tabs-item" :class="active === '排查记录' ?  'active' : '' " @click="changeTab('排查记录')">排查记录</div>
    </div>-->
    <div style="margin-bottom:10px">
      <el-radio-group v-model="radio" @change="changeTab">
        <el-radio-button :label="1">开始排查</el-radio-button>
        <el-radio-button :label="2">排查记录</el-radio-button>
      </el-radio-group>
    </div>

    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading" height="600"
                >
<!--        @selection-change="handleSelectionChange"-->
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" type="index" label="序号" width="100"></el-table-column>
        <el-table-column align="center" v-for="(item,index) in column" :prop="item.prop" :label="item.label"
                         :key="index" :formatter="item.formatter" :width="item.width"
                         :show-overflow-tooltip="item.showOverflow" :min-width="item.minWidth">
           <template slot-scope="scope" >
            <span v-if="item.label === '标题'" class="file-item" @click="lookDetail(scope.row)"
            > {{ scope.row.taskTitle }} </span>
            <span v-if="item.prop === 'cycle'">
              {{ scope.row.cycle == 1? '日' : scope.row.cycle == 2 ? '周' :
                scope.row.cycle == 3 ? '月' : scope.row.cycle == 4 ? '季' : '' }} </span>
            <span v-else-if="item.prop === 'unitType'"> {{ judgeRole(scope.row[`${item.prop}`]) }} </span>
            <span v-else-if="item.prop === 'pcRole'"> {{ judgeRole(loginInfo.companyCategory) }} </span>
             <span v-else> {{ scope.row[`${item.prop}`] }} </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200" v-if="radio == '1' ">
          <template slot-scope="scope">
            <!-- 计划审核 -->
            <el-button type="text" @click="handleStart(scope.row)" v-if="scope.row.stateName === '未完成' ">开始排查</el-button>
            <el-button type="text" @click="handleStart(scope.row)" v-else>再次排查</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4" v-if="radio == 2">
      <el-pagination background layout="prev, pager, next"
                     :total="page.total"
                     :page-size="page.pageSize"
                     :current-page="page.pageNo"
                     @current-change="handleCurrentChange"
                     @size-change="handleSizeChange">
      </el-pagination>
    </div>


    <el-dialog
        width="70%"
        title="日常隐患排查"
        :visible.sync="dialogVisible"
        class="editDialog"
        append-to-body>
      <startTrouble v-if="dialogVisible" :type="'日常排查'"
                    :trouble-data="troubleData" @cancelModal="cancelModal" @noTrouble="cancelModal"></startTrouble>
    </el-dialog>

    <el-dialog
        width="70%"
        :title="title"
        :visible.sync="dayVisible"
        class="editDialog"
        append-to-body>
      <dayRecord v-if="dayVisible" :type="'everyday_check'" :title="title" :detail-data="detailData"></dayRecord>
    </el-dialog>


  </div>
</template>

<script>
import startTrouble from "@/views/HiddenDanger/System/DangerTrouble/DayTrouble/components/startTrouble";
import dayRecord from "@/views/HiddenDanger/System/DangerTrouble/DayTrouble/components/dayRecord";
import { dayTroubleList, recordList} from "@/api/hdanger";
import {queryDeptDetail} from "@/api/stand";
export default {
  name: "DayTrouble",
  components : {
    startTrouble,
    dayRecord
  },
  data(){
    return {
      queryModal : {
        workAreaUuid : []
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      tableData : [],
      lineSite : [],
      loading : false,
      dialogVisible : false,
      active : '开始排查',
      startList : [
        { prop : 'lineName' , label : '线路',showOverflow:true },
        { prop : 'branchName' , label : '分部/标段',showOverflow:true },
        { prop : 'workAreaName' , label : '工点'/*,formatter: (row) => {
            return row.branchName + '-' + row.areaName
          }*/,showOverflow:true},
        { prop : 'frequency' , label : '次数',showOverflow:true},
        { prop : 'checkCount' , label : '已排查次数',showOverflow:true},
        { prop : 'cycle' , label : '周期',showOverflow:true },
        { prop : 'personLiable' , label : '巡检人',showOverflow:true },
        { prop : 'timeStr' , label : '剩余时间',showOverflow:true },
        { prop : 'stateName' , label : '状态',showOverflow:true },
      ],
      historyList : [
        { prop : 'lineName' , label : '线路',showOverflow:true },
        { prop : 'branchName' , label : '分部/标段',showOverflow:true },
        { prop : 'workAreaName' , label : '工点',showOverflow:true},
        { prop : '' , label : '标题',showOverflow:true },
        { prop : 'created' , label : '排查时间',showOverflow:true},
        { prop : 'unitType' , label : '排查单位',showOverflow:true},
        { prop : 'pcRole' , label : '排查角色',showOverflow:true },
        { prop : 'personLiable' , label : '排查人',showOverflow:true},
      ],
      column : [],
      lineSiteData : [],
      troubleData : {},
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      loginInfo : JSON.parse(localStorage.getItem('user')),
      deptType : '',
      companyName : '',
      title : '',
      dayVisible : false,
      detailData : {},
      radio : 1
    }
  },
  created() {
    this.column = this.startList;
    // this.column = this.historyList;
    this.queryLineSiteTreeData();
    this.getTableData('startList');
    this.getDeptDetail();
    // this.getTableData('historyList');
  },
  methods : {
    getNowTime(type) {
      var date = new Date();
      //年 getFullYear()：四位数字返回年份
      var year = date.getFullYear(); //getFullYear()代替getYear()
      //月 getMonth()：0 ~ 11
      var month = date.getMonth() + 1;
      //日 getDate()：(1 ~ 31)
      var day = date.getDate();
      //时 getHours()：(0 ~ 23)
      var hour = date.getHours();
      //分 getMinutes()： (0 ~ 59)
      var minute = date.getMinutes();
      //秒 getSeconds()：(0 ~ 59)
      var second = date.getSeconds();
      var time = year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
      var today = year + '-' + this.addZero(month) + '-' + this.addZero(day);
      return type === 'time' ? time : today;
    },
    addZero(s) {
      return s < 10 ? ('0' + s) : s;
    },
    getDeptDetail() {
      var nowTime = this.getNowTime('today');
      var userInfo = JSON.parse(localStorage.getItem("user"));
      queryDeptDetail(userInfo.companyId).then((res) => {
        console.log(res);
        this.deptType = res.data ? res.data.category : "0";
        switch (this.deptType){
          case 'build' :
            this.companyName = '建设单位';
            break;
          case 'monitor' :
            this.companyName = '监理单位';
            break;
          case 'construct' :
            this.companyName = '施工单位';
            break;
          case 'design' :
            this.companyName = '设计单位';
            break;
          case 'third' :
            this.companyName = '第三方监测单位';
            break;
          case 'survey' :
            this.companyName = '勘察单位';
            break;
          case 'contracting' :
            this.companyName = '施工总承包单位';
            break;
          case 'fullEmergencyRescue' :
            this.companyName = '专职应急救援队';
            break;
        }
        this.title = this.companyName + nowTime + '日常排查';
      });
    },
    // 线路工点树数据查询
    queryLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    judgeRole(name){
      switch (name) {
        case 'build' :
          return '建设单位';
        case 'monitor' :
          return '监理单位';
        case 'construct' :
          return '施工单位';
        case 'design' :
          return '设计单位';
        case 'third' :
          return '第三方监测单位';
        case 'survey' :
          return '勘察单位';
        case 'contracting' :
          return '施工总承包单位';
        case 'fullEmergencyRescue' :
          return '专职应急救援队';
      }
    },
    lookDetail(row){
      this.detailData = {...row};
      // this.title = '日常排查';
      this.detailData.create = this.detailData.itemList ? this.detailData.itemList.created : '';
      this.detailData.pcCompany = this.judgeRole(this.detailData.unitType);
      this.dayVisible = true;
    },
    changeTab(){
      this.column =  this.radio == 1 ? this.startList : this.historyList;
      let tableName = this.radio == 1 ? 'startList' : 'historyList';
      this.getTableData(tableName)
    },
    handleSearch(){
      let tableName = this.radio == 1 ? 'startList' : 'historyList';
      this.getTableData(tableName);
    },
    getTableData(tableName){
      this.loading = true;
      let tableInterface = tableName === 'startList' ? dayTroubleList : recordList
   /*   let params = {
        pageNum : this.page.pageNo,
        pageSize : this.page.pageSize,
        workAreaUuid : this.queryModal.workAreaUuid
      }*/
      let params = {}
      if(this.radio == 1){
        params = {
          ...this.queryModal
        }
      }else{
        params = {
          pageNum : this.page.pageNo,
          pageSize : this.page.pageSize,
          ...this.queryModal
        }
      }
      tableInterface(params).then(res => {
        if(tableName === 'historyList'){
           this.tableData = res.data.records;
          this.page.total = res.data.count;
          this.loading = false;
        }else{
          this.tableData = res.data;
          this.tableData = this.tableData.map(item => {
            item.stateName = item.checkCount >= item.frequency ? '已完成' : '未完成'
            return item
          })
          this.loading = false;
        }

      })
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getTableData('historyList');
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getTableData('historyList');
    },
    handleStart(row){
      this.troubleData = {...row};
      this.dialogVisible = true;
    },
    // 级联选择器事件
    handleLineSiteChange(record) {
      console.log(record)
      this.queryModal.workAreaUuid = [];
      for (let item of record) {
        this.queryModal.workAreaUuid.push(item[item.length-1]);
      }
      console.log(this.queryModal.workAreaUuid)
    },
    cancelModal(){
      this.dialogVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs{
  display: flex;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #1684fc;
  width: 300px;
  padding: 0;
  .tabs-item{
    width: 150px;
    height: 30px;
    line-height: 30px;
    color: #1684fc;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }
  .tabs-item:first-child{
    border-right: none;
  }
  .active{
    background: #1684fc;
    color: #ffffff;
    width: 150px;
    height: 32px;
    line-height: 32px;
    border-radius: 5px;
    margin: -1px 0 0 -1px;
  }
}
::v-deep .el-table__fixed-right{
  height: 100% !important;
}
.file-item {
  color: #3090ff;
  cursor: pointer;
}
</style>
