<template>
  <!-- 整改  -->
  <div class="material-container h-full">
    <el-tabs v-model="activeName">
      <el-tab-pane label="隐患详情" name="隐患详情">
        <el-form
          :model="checkForm"
          v-loading="formLoading"
          :rules="rules"
          ref="checkForm"
          label-width="120px"
          class="demo-model"
        >
          <el-row>
            <el-col :span="16">
              <el-form-item label="标题" prop="taskTitle">
                <el-input
                  v-model="checkForm.taskTitle"
                  readonly
                  placeholder="标题"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="排查类型" prop="screeningTypeName">
                <el-input
                  v-model="checkForm.screeningTypeName"
                  readonly
                  placeholder="排查类型"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="排查单位" prop="screeningCompanyName">
                <el-input
                  v-model="checkForm.screeningCompanyName"
                  readonly
                  placeholder="排查单位"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="任务组长"
                prop="leaderName"
                v-show="pageData.screeningType == 'special_check'"
              >
                <el-input
                  v-model="checkForm.leaderName"
                  readonly
                  placeholder="任务组长"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="排查人员"
                prop="itemName"
                v-show="pageData.screeningType == 'everyday_check'"
              >
                <el-input
                  v-model="checkForm.personLiable"
                  readonly
                  placeholder="排查人员"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="排查时间" prop="itemName">
                <el-input
                  v-model="checkForm.screeningTime"
                  readonly
                  placeholder="排查时间"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="线路" prop="itemName">
                <el-input
                  v-model="checkForm.lineName"
                  readonly
                  placeholder="线路"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="分部/标段" prop="itemName">
                <el-input
                  v-model="checkForm.branchName"
                  readonly
                  placeholder="分部/标段"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="工点" prop="itemName">
                <el-input
                  v-model="checkForm.workAreaName"
                  readonly
                  placeholder="工点"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="任务人员"
                prop="checkName"
                v-show="pageData.screeningType == 'special_check'"
              >
                <el-input
                  v-model="checkForm.checkName"
                  readonly
                  placeholder="任务人员"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="隐患类别" prop="categoryPname">
                <el-input
                  v-model="checkForm.categoryPname"
                  readonly
                  placeholder="隐患类别"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="隐患子类/子项" prop="categoryName">
                <el-input
                  v-model="checkForm.categoryName"
                  readonly
                  placeholder="隐患子类/子项"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="隐患条目" prop="itemName">
                <el-input
                  v-model="checkForm.itemName"
                  readonly
                  placeholder="隐患条目"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="责任人"
                prop="personLiable"
                v-show="pageData.screeningType == 'special_check'"
              >
                <el-input
                  v-model="checkForm.personLiable"
                  readonly
                  placeholder="责任人"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="整改单位" prop="reformCompanyName">
                <el-input
                  v-model="checkForm.reformCompanyName"
                  readonly
                  placeholder="整改单位"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="整改期限" prop="deadline">
                <el-input
                  v-model="checkForm.deadline"
                  readonly
                  placeholder="整改期限"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="隐患描述" prop="hdInfo">
                <el-input
                  v-model="checkForm.hdInfo"
                  readonly
                  placeholder="隐患描述"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="整改要求" prop="reformRequire">
                <el-input
                  v-model="checkForm.reformRequire"
                  readonly
                  placeholder="整改要求"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="现场图片" prop="itemName">
                <!--              <el-input v-model="ruleForm.itemName" type="textarea" placeholder="隐患条目名称"></el-input>-->
                <div class="img-list" v-if="checkForm.fileList">
                  <el-image
                    v-for="(item, index) in checkForm.fileList"
                    :key="index"
                    style="width: 100px; height: 100px"
                    :src="item.fileUrl"
                    :preview-src-list="
                      checkForm.fileList.map((item) => item.fileUrl)
                    "
                  >
                  </el-image>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="审核发布" name="审核发布">
        <div v-if="submitType == 'abarbeit'">
          <el-form
            ref="ruleForm"
            :rules="rules"
            :model="ruleForm"
            label-width="130px"
            :disabled="submitType == 'view'"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="审核人:" prop="operator">
                  <el-input readonly v-model="ruleForm.operator"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="审核时间:" prop="operateTime">
                  <el-input
                    readonly
                    placeholder="审核时间"
                    v-model="ruleForm.operateTime"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="审核描述:" prop="operateDescribe">
                  <el-input
                    type="textarea"
                    resize="none"
                    placeholder="请输入整改描述"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    v-model="ruleForm.operateDescribe"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="图片:" prop="imageOne">
                  <image-uploader
                    v-model="ruleForm.imageOne"
                    @uploadChange="reform"
                  ></image-uploader>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="附件:" prop="file">
                  <FileUploader
                    @uploadChange="handleFileUpload"
                    @delChange="handleFileDel"
                    :value="fileList"
                  ></FileUploader>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="dialogFooter" v-show="submitType != 'view'">
            <el-button size="medium" type="primary" @click="submit(0)"
              >同意发布</el-button
            >
            <el-button size="medium" type="primary" @click="submit(3)"
              >驳回</el-button
            >
          </div>
        </div>
        <!-- <el-timeline v-if="submitType == 'view'" style="margin:10px 0 0 10px">
          <el-timeline-item
            v-for="(activity, index) in zgActivities"
            :key="index"
            type="primary"
            color="#3fa5e9"
            :size="'large'"
          >
            <div>
              <i style="font-style:normal;margin-right: 5px;">{{
                activity.operateTime
              }}</i
              ><span>{{
                activity.companyName +
                  "-" +
                  activity.operator +
                  "（" +
                  activity.roleName +
                  "）" +
                  "-整改 " +
                  activity.operateDescribe
              }}</span>
              <span @click="hanlderViewList(activity)" class="fileDialogBox"
                >附件列表
              </span>
            </div>
          </el-timeline-item>
        </el-timeline> -->
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// 图片
import imageUploader from "../../../../../RiskManagement/components/imageUploader.vue";
// 文件
import FileUploader from "@/components/Uploader/FileUploader.vue";
// 整改,隐患详情,整改查看
import {
  checkOrReformOrRemove,
  getDetails,
  getOperateRecords,
  // 响应时间线
  detailsList,
} from "@/api/hdanger";
export default {
  components: {
    FileUploader,
    imageUploader,
  },
  props: {
    pageData: {
      type: Object,
      default: () => {},
    },
    submitType: {
      type: String,
    },
    gettime: {
      type: String,
    },
  },
  data() {
    return {
      // 时间线
      xyActivities: [],
      zgActivities: [],
      activeName: "审核发布",
      // 图片
      fileList: [],
      ruleForm: {
        operator: "",
        operateTime: "",
        operateDescribe: "",
        imageOne: [],
        imageOne: [],
      },
      hdLevelData: [],
      rules: {},
      // 隐患详情
      checkForm: {
        taskTitle: "",
        screeningTypeName: "",
        screeningCompanyName: "",
        leaderName: "",
        personLiable: "",
        screeningTime: "",
        lineName: "",
        branchName: "",
        workAreaName: "",
        checkName: "",
        categoryPname: "",
        categoryName: "",
        itemName: "",
        personLiable: "",
        reformCompanyName: "",
        deadline: "",
        hdInfo: "",
        reformRequire: "",
        formLoading: true,
      },
    };
  },
  watch: {
    "pageData.id": {
      handler(val) {
        if (val) {
          this.ruleForm.taskItemId = val;
          this.getRoles();
          // 隐患详情
          this.getDeatil(this.pageData);
          // 隐患响应查看
          this.getxyActivities(val);
          // 整改查看详情
          this.getzgDeatil(this.pageData);
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
    gettime: {
      handler(val) {
        if (val) {
          this.ruleForm.operateTime = val;
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 隐患响应查看
    getxyActivities(val) {
      detailsList(val).then((res) => {
        this.xyActivities = res.data;
      });
    },
    // 隐患详情
    getDeatil(val) {
      this.formLoading = true;
      let params = {
        id: val.id,
        screeningType: val.screeningType,
      };
      getDetails(params).then((res) => {
        if (res.code == 0) {
          this.formLoading = false;
          this.checkForm = res.data;
        }
        this.formLoading = false;
      });
    },
    getzgDeatil(val) {
      let params = {
        operateTypes: [1],
        taskItemId: val.id,
      };
      getOperateRecords(params).then((res) => {
        if (res.code == 0) {
          this.zgActivities = res.data;
        }
      });
    },
    // 审核发布-查看
    // 获取单位角色
    getRoles() {
      if (localStorage.getItem("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        this.ruleForm.operator = user.realName;
        this.ruleForm.roleName = user.roles[0].roleName;
        this.ruleForm.companyName = user.departmentName;
      }
    },
    reform(urlList) {
      this.photoList = [];
      for (let item of urlList) {
        this.photoList.push({
          name: item.name,
          url: item.url,
        });
      }
    },
    // 附件上传
    handleFileUpload(e) {
      this.fileList.push({
        originalName: e.name,
        url: e.url,
      });
    },
    handleFileDel(e) {
      let index = this.fileList.findIndex((v) => v.url === e.url);
      this.fileList.splice(index, 1);
    },
    cancel() {
      this.reset();
      this.$emit("CloseData");
    },
    submit(val) {
      const copyForm = Object.assign({}, this.ruleForm);
      copyForm.operateType = val;
      copyForm.operateRecordFiles = [];
      if (this.photoList) {
        this.photoList.forEach((item) => {
          copyForm.operateRecordFiles.push({
            fileType: 1,
            fileUrl: item.url,
            fileName: item.name,
          });
        });
      }
      if (this.fileList) {
        this.fileList.forEach((item) => {
          copyForm.operateRecordFiles.push({
            fileType: 2,
            fileUrl: item.url,
            fileName: item.originalName,
          });
        });
      }
      delete copyForm.imageOne;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          checkOrReformOrRemove(copyForm)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("操作成功");
                this.cancel();
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((res) => {
              this.$message.error(res.message);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    reset() {
      (this.activeName = "审核发布"), (this.fileList = []);
      // this.$refs['ruleForm'].resetFields()
      // 比上方获取demo的清理方式更彻底
      const th = this;
      Object.keys(this.ruleForm).forEach(function(key) {
        if (key == "imageOne" || key == "imageTwo") {
          th.ruleForm[key] = [];
        } else {
          th.ruleForm[key] = "";
        }
      });
      // this.$refs.ruleForm.resetFields();
    },
    hanlderViewList(obj) {
      this.$emit("showFileList", obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
// ::v-deep .el-dialog__body {
//   height: 460px;
// }
::v-deep .el-image {
  height: 150px;
}
.i {
  position: absolute;
  top: -5px;
  right: -5px;
  color: red;
  font-weight: 600;
}
.url-list {
  position: relative;
  display: inline-block;
}
.fileDialogBox {
  color: #3fa5e9;
  margin-left: 10px;
  cursor: pointer;
}
</style>
