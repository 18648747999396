<template>
  <systemModal
    ref="formModal"
    @modalConfirm="handleSubmit"
    :width="width"
    :title="title"
    @modalClose="modalClose"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="100px"
      class="demo-model"
    >
      <el-form-item label="事件名称" prop="caseName">
        <el-input
          v-model="model.caseName"
          placeholder="请输入事件名称"
          style="width: 100%"
        ></el-input>
      </el-form-item>
      <el-form-item label="地理位置" prop="caseLocation">
        <el-input
          v-model="model.caseLocation"
          style="width: 100%"
          placeholder="请输入地理位置"
        ></el-input>
      </el-form-item>
      <el-form-item label="事件类型" prop="eventTypeId">
        <el-select
          v-model="model.eventTypeId"
          placeholder="选择事件类型"
          clearable
          style="width: 100%"
        >
          <el-option
            :label="data.label"
            :value="data.id"
            v-for="(data, index) in typeList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="事故/件类别" prop="caseTypeId">
        <el-select
          v-model="model.caseTypeId"
          placeholder="选择事故/件类别"
          clearable
          style="width: 100%"
        >
          <el-option
            v-for="(data, index) in caseList"
            :label="data.label"
            :value="data.id"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="事件级别" prop="eventLevelId">
        <el-select
          v-model="model.eventLevelId"
          placeholder="选择事件级别"
          clearable
          style="width: 100%"
        >
          <el-option
            :label="data.label"
            :value="data.id"
            v-for="(data, index) in levelList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发生时间" prop="occurrenceTime">
        <el-date-picker
          v-model="model.occurrenceTime"
          type="datetime"
          placeholder="选择时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          style="width: 100%"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="附件" prop="enclosure">
        <FileUploader
          @uploadChange="handleFileUpload"
          @delChange="handleFileDel"
          :value="fileList"
        ></FileUploader>
        <!-- <Uploader
          v-model="model.enclosure"
          :fileLists="[model.enclosure]"
          @uploader="urlchange"
        ></Uploader> -->
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import FileUploader from "@/components/Uploader/FileUploader.vue";
// import Uploader from "@/components/Uploader/Uploader.vue";
import { caseLibraryAdd, caseLibraryUpdate } from "@/api/response";
export default {
  components: {
    systemModal,
    // Uploader,
    FileUploader,
  },
  props: { typeList: {}, levelList: {}, caseList: {} },
  data() {
    return {
      title: "新增",
      width: "50%",
      fileList: [],
      model: {
        caseName: "",
        caseLocation: "",
        eventTypeId: "",
        caseTypeId: "",
        eventLevelId: "",
        occurrenceTime: "",
        enclosure: "",
      },
      loading: false,
      rules: {
        caseName: [
          { required: true, message: "请输入事件名称", trigger: "blur" },
        ],
        caseLocation: [
          { required: true, message: "请输入地理位置", trigger: "blur" },
        ],

        eventTypeId: [
          { required: true, message: "请选择事件类型", trigger: "change" },
        ],
        caseTypeId: [
          { required: true, message: "请选择事故/件类别", trigger: "change" },
        ],
        eventLevelId: [
          { required: true, message: "选择事件级别", trigger: "change" },
        ],
        occurrenceTime: [
          { required: true, message: "选择时间", trigger: "change" },
        ],
        enclosure: [
          { required: true, message: "请上传附件", trigger: "change" },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    handleAdd(record) {
      if (record) {
        this.title = "编辑";
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
        this.fileList = [
          {
            name: "附件",
            url: this.model.enclosure,
          },
        ];
      } else {
        this.title = "新增";
        this.fileList = [];
        this.model = {
          caseName: "",
          caseLocation: "",
          eventTypeId: "",
          caseTypeId: "",
          eventLevelId: "",
          occurrenceTime: "",
          enclosure: "",
        };
      }
      this.handleEdit();
    },
    handleName(e) {
      console.log(e);
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    getlabel() {
      if (this.model.lineSite.length != 0) {
        this.lineName = this.$refs["linecas"].getCheckedNodes()[0].pathLabels;
      }
      console.log(this.lineName);
    },
    urlchange(url) {
      this.model.enclosure = url;
    },
    handleFileUpload({ name, url }) {
      console.log(name);
      this.fileList.push({ name, url });
      if (this.fileList.length > 1) {
        this.fileList = [this.fileList[1]];
      }
      this.model.realName = "附件";
      this.model.enclosure = url;
    },
    handleFileDel({ name, url }) {
      this.model.enclosure = "";
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;

          if (this.title == "新增") {
            let params = {
              caseName: this.model.caseName,
              caseLocation: this.model.caseLocation,
              eventTypeId: this.model.eventTypeId,
              caseTypeId: this.model.caseTypeId,
              eventLevelId: this.model.eventLevelId,
              occurrenceTime: this.model.occurrenceTime,
              enclosure: this.model.enclosure,
            };
            caseLibraryAdd(params).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "新增成功！",
                  type: "success",
                });
                this.$emit("getdata");
                this.$refs.formModal.handleClose();
              } else {
                this.$message.error("新增失败！");
              }
              this.loading = false;
            });
          } else {
            let params = {
              caseId: this.model.caseId,
              caseName: this.model.caseName,
              caseLocation: this.model.caseLocation,
              eventTypeId: this.model.eventTypeId,
              caseTypeId: this.model.caseTypeId,
              eventLevelId: this.model.eventLevelId,
              occurrenceTime: this.model.occurrenceTime,
              enclosure: this.model.enclosure,
            };
            caseLibraryUpdate(params).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "修改成功！",
                  type: "success",
                });
                this.$emit("getdata");
                this.$refs.formModal.handleClose();
              } else {
                this.$message.error("修改失败！");
              }
              this.loading = false;
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
    modalClose() {
      this.$refs.modelForm.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped></style>
