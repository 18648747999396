<template>
  <systemModal ref="formModal" :title="title" :IsAudit="true" @modalAgree="handleExamint(1)"
    @modalDisagree="handleExamint(2)">
    <el-form v-loading="loading" :model="model" ref="modelForm" label-width="120px" class="demo-model">
      <el-form-item label="线路-工点" prop="lineSite">
        {{ model.lineName }}-{{ model.siteName }}
      </el-form-item>
      <el-form-item label="检查单位" prop="unitId">
        {{ model.unitName }}
      </el-form-item>
      <el-form-item label="检查人员" prop="video">
        <ul>
          <li v-for="item of model.safeCheckStaff" :key="item.staffId">{{ item.staffName }}</li>
        </ul>
      </el-form-item>
      <el-form-item label="检查日期" prop="video">
        {{ model.checkDate }}
      </el-form-item>
      <el-form-item label="附件" prop="video">
        <el-button type="text" @click="handleFilePreview">{{ model.imageName }}</el-button>
      </el-form-item>
      <el-form-item label="驳回原因">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          placeholder="请输入驳回原因"
          v-model="note"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <file-preview ref="filePreview" :appendToBody="true" :src="model.imageUrl"></file-preview>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { queryOrg } from "@/api/org";
import { editSafeCheck } from "@/api/map";
import filePreview from "@/components/Uploader/filePreview.vue";
export default {
  components: {
    systemModal,
    filePreview
  },
  data() {
    return {
      title: "审核",
      model: {},
      loading: false,
      unitOptions: [],
      note: ""
    };
  },
  computed: {},
  mounted() {
    this.queryOrg();
  },
  methods: {
    handleOpen(record) {
      this.note = "";
      this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
      if (this.unitOptions.length > 0) {
        const tempObj = this.unitOptions.find(item => item.id == this.model.unitId);
        this.$set(this.model, "unitName", tempObj.companyName)
      }
      this.$refs.formModal.handleOpen();
    },
    queryOrg() {
      queryOrg().then(res => {
        if (res.code == 0) {
          this.unitOptions = res.data;
        }
      });
    },
    handleExamint(type) {
      const params = JSON.parse(JSON.stringify(this.model));
      if (type == 1) {
        // 通过
        params.state = 3;
        this.loading = true;
      } else {
        // 驳回
        if (!this.note) {
          this.$message.error("请输入驳回原因");
          return false;
        }
        this.loading = true;
        params.state = 2;
        params.note = this.note;
      }
      editSafeCheck(params).then(res => {
        if (res.code == 0) {
          this.loading = false;
          this.$message.success("操作成功！");
          this.$emit("examineOver");
          this.$refs.formModal.handleClose();
        }
      })
    },
    handleFilePreview() {
      this.$refs.filePreview.handleOpen();
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
