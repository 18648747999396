<template>
  <li
    class="submenu mr-4"
    :class="[{ 'is-opened': opened }]"
    @mouseenter="mouseenterHandle"
    @mouseleave="mouseleaveHandle"
  >
    <a href="javascript:;" class="menu-link">
      <div class="first-leave__title first-leave__hover" :class="[{ 'is-active': active }]" v-if="firstLevel">
        {{ item.resourceName }}
      </div>
      <div class="menu__title menu-item__hover" :class="[{ 'is-active': active }]" v-else>
        {{ item.resourceName }}
      </div>
    </a>
    <!--popover-->
    <transition :name="menuTransitionName">
      <div
        @mouseenter="
          ($event) => {
            mouseenterHandle($event, 50);
          }
        "
        @mouseleave="mouseleaveHandle"
        class="popover-menu"
        v-show="opened"
        :class="[popperClass]"
        ref="popper"
      >
        <div class="poppver-wrapper">
          <ul class="zz-menu zz-menu--popup">
            <slot></slot>
          </ul>
        </div>
      </div>
    </transition>
  </li>
</template>
<script>
import { createPopper } from "@popperjs/core";

export default {
  name: "zz-submenu",
  inject: ["rootMenu"],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    },
    firstLevel: {
      type: Boolean,
    },
    showTimeout: {
      type: Number,
      default: 300,
    },
    hideTimeout: {
      type: Number,
      default: 300,
    },
    transformOrigin: {
      type: [Boolean, String],
      default: true,
    },
    popperOptions: {
      type: Object,
      default() {
        return {
          modifiers: { computeStyle: { gpuAcceleration: false } },
        };
      },
    },
    popperClass: String,
  },
  data() {
    return {
      timeout: null,
      mouseInChild: false,
      menuTransitionName: "el-zoom-in-top",
      popperJS: null,
      submenus: {},
      items: {},
      popperElm: null,
      referenceElm: null
    };
  },
  computed: {
    currentPlacement() {
      return this.firstLevel ? "bottom" : "right-start";
    },
    opened() {
      return this.rootMenu.openedMenus.indexOf(this.item.id) > -1;
    },
    active() {
      return this.rootMenu.activeIds.includes(this.item.id);
    }
  },
  watch: {
    opened(val) {
      if (val) {
        this.updatePopper(this.referenceElm, this.popperElm);
      }
    }
  },
  methods: {
    initPopper() {
      this.referenceElm = this.$el;
      this.popperElm = this.$refs.popper;
    },
    createPopper(reference, popper) {
      let options = this.popperOptions;
      options.placement = this.currentPlacement;
      if (this.firstLevel) {
        document.body.appendChild(popper);
      }
      if (this.popperJS && this.popperJS.destroy) {
        this.popperJS.destroy();
      }
      this.popperJS = createPopper(reference, popper, options);
    },
    updatePopper(reference, popper) {
      console.log(reference, popper)
      const popperJS = this.popperJS;
      if (popperJS) {
        popperJS.update();
      } else {
        this.createPopper(reference, popper);
      }
    },
    mouseenterHandle(event, showTimeout = this.showTimeout) {
      clearTimeout(this.timeout, this.item);
      this.timeout = setTimeout(() => {
        this.rootMenu.openMenu(this.item.id);
      }, showTimeout);
    },
    mouseleaveHandle() {
      console.log("mouseleave")
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.rootMenu.closeMenu(this.item.id);
      }, this.hideTimeout);
    }
  },
  mounted() {
    this.initPopper();
  },
  beforeDestroy() {
    if (this.popperElm && this.popperElm.parentNode === document.body) {
      document.body.removeChild(this.popperElm);
    }
  }
};
</script>

<style lang="scss" scoped>
.submenu {
  width: 160px;
}
.first-leave__title {
  margin-top: 10px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 40px;
  background: #0b1b4f;
  border: 1px solid #173f58;
}
.menu__title {
  padding: 10px 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 46, 117, 1);
  border-bottom: 1px solid rgba(23, 63, 88, 1);
}
.is-active {
  background: rgba(0, 46, 117, 1);
  border: 1px solid #5AE5F3;
}
.first-leave__hover {
  &:hover {
    background: rgba(0, 46, 117, 1);
    border: 1px solid #5AE5F3;
  }
}
.menu-item__hover {
  &:hover {
    background: rgba(22, 70, 130, 1);
    border: 1px solid #5AE5F3;
  }
}
.popover-menu {
  min-width: 180px;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  .popover-menu { 
    min-width: 210px;
  }
  .poppver-wrapper {
    margin-top: 4px;
    width: 160px;
    background: rgba(0, 46, 117, 1);
    text-align: center;
  }
}
</style>
