<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper flex justify-between">
      <div class="flex justify-start">
        <el-cascader
          ref="linecas"
          placeholder="选择线路工点"
          v-model="lineSite"
          :options="options"
          :props="props"
          filterable
          clearable
          @change="getImage"
        ></el-cascader>
        <div class="cardws text-center ml-2 p-2">
          <div class="mb-5">航拍图</div>
          <div class="flex justify-center">
            <el-upload
              action="upload"
              :http-request="uploadImg"
              :show-file-list="false"
              :multiple="false"
              :accept="accept"
            >
              <img src="../../../assets/map/sc.png" alt="" />
            </el-upload>
          </div>
        </div>
        <div class="cardws text-center ml-2 p-2">
          <div class="mb-5">选择摄像头</div>
          <div class="flex justify-center cursor-pointer">
            <img
              class="mr-2"
              style="width: 25px; height: 33px"
              src="../../../assets/map/qiuji.png"
              alt=""
              @click="openCamerForm"
            />
            <img
              style="width: 25px; height: 33px"
              src="../../../assets/map/qiangji.png"
              alt=""
              @click="openCamerForm"
            />
          </div>
        </div>
        <div class="cardws text-center ml-2 p-2">
          <div class="mb-5">选择防汛关注重点</div>
          <div
            class="flex justify-center cursor-pointer"
            @click="openFloodForm"
          >
            <img class="mr-2" src="../../../assets/map/bianpo.png" alt="" />
            <img class="mr-2" src="../../../assets/map/diwa.png" alt="" />
            <img class="mr-2" src="../../../assets/map/hupo.png" alt="" />
            <img src="../../../assets/map/jiyouxian.png" alt="" />
          </div>
        </div>
        <div class="cardws text-center ml-2 p-2">
          <div class="mb-5">物资库</div>
          <div class="flex justify-center cursor-pointer" @click="openMaterial">
            <img src="../../../assets/map/wuziku.png" alt="" />
          </div>
        </div>
      </div>
      <div>
        <el-button
          type="primary"
          @click="handleEditTeamInfo"
          v-has="'aerialView:save'"
          >保存修改</el-button
        >
        <el-button type="primary" @click="handleLog">历史调整</el-button>
        <!-- <el-button type="primary" @click="handleDelete">删除</el-button> -->
      </div>
    </div>
    <div class="table-wrapper mt-2" v-loading="loading">
      <div>
        <div ref="main" class="draw-main" id="draw"></div>
      </div>
    </div>
    <div
      class="layer"
      :style="{
        top: layers.top + 'px',
        left: layers.left + 'px',
        display: layers.display,
      }"
    >
      <span>{{ layers.name }}</span>
    </div>
    <DeleteFrom ref="DeleteFrom" @modalConfirm="delClick"></DeleteFrom>
    <CameraForm ref="CameraForm" @getCamera="getCamera($event)"></CameraForm>
    <FloodForm ref="FloodForm" @getFlood="getFlood($event)"></FloodForm>
  </div>
</template>

<script>
import Konva from "konva";
import { upload, uploadThumb } from "@/api/upload";
import CameraForm from "./modules/cameraForm.vue";
import FloodForm from "./modules/floodForm.vue";
import DeleteFrom from "./modules/deleteFrom.vue";
import {
  airphotoModify,
  airphotoInfo,
  siteVideosModify,
  siteMaterialsModify,
  airphotoHistory,
} from "@/api/map";
// eslint-disable-next-line no-unused-vars
import { proolInfoPut, proolDelete, ProofCoreList } from "@/api/proof";
export default {
  components: {
    CameraForm,
    FloodForm,
    DeleteFrom,
  },
  props: {
    accept: {
      default: "image/*",
    },
  },
  data() {
    return {
      lineSite: [],
      options: [],
      Image: "",
      props: {
        value: "id",
        label: "name",
        children: "children",
      },
      queryModal: {},
      imageObj: null,
      layer: null,
      stage: null,
      containers: null,
      r: 0, // 底图缩放比例
      targetLayer: null,
      rect: null,
      circle: null,
      phiont: {
        x: 0,
        y: 0,
        minColumn: 50,
        maxColumn: 50,
      },
      loading: false,
      cameraList: [],
      layers: {
        top: 0,
        left: 0,
        display: "none",
        name: "",
      },
      floodList: [
        {
          id: 9,
          name: "地势低洼工点",
          url: require("../../../assets/map/diwa.png"),
          coordX: 0,
          coordY: 0,
        },
        {
          id: 10,
          name: "高边坡",
          url: require("../../../assets/map/bianpo.png"),
          coordX: 0,
          coordY: 0,
        },
        {
          id: 11,
          name: "河涌",
          url: require("../../../assets/map/hupo.png"),
          coordX: 0,
          coordY: 0,
        },
        {
          id: 8,
          name: "临近既有线路",
          url: require("../../../assets/map/jiyouxian.png"),
          coordX: 0,
          coordY: 0,
        },
      ],
      materials: [],
      floodData: [],
      iscode: true,
      isErrMessage: [],
      temList: null,
      temType: "",
    };
  },
  mounted() {
    this.getLineTree();
  },
  methods: {
    async getLineTree() {
      console.log(JSON.parse(localStorage.lineSiteTree));
      if (localStorage.lineSiteTree) {
        this.options = JSON.parse(localStorage.lineSiteTree);
        console.log(this.options);
      } else {
        const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
        if (lineSiteData) {
          this.options = lineSiteData;
          this.options.forEach((e) => {
            if (e.children.length != 0) {
              e.children.forEach((el) => {
                delete el.children;
              });
            }
          });
          localStorage.lineSiteTree = JSON.stringify(this.options);
        }
      }
    },
    openCamerForm() {
      if (this.lineSite[1]) {
        let camerasList = [];
        this.cameraList.forEach((e) => {
          if (e.coordX > 0 && e.coordY > 0) {
            console.log("已有坐标");
          } else {
            camerasList.push(e);
          }
        });

        this.$refs.CameraForm.handleAdd(camerasList);
      } else {
        this.$message("请先选择工点！");
      }
    },
    async openFloodForm() {
      if (this.lineSite[1]) {
        let FloodList = [];
        // 查询全部关注重点
        let i = this.lineSite.length - 1;
        let res = await ProofCoreList(this.lineSite[i]);
        if (res.code == 0) {
          res.data.forEach((item) => {
            this.floodList.forEach((e) => {
              if (item.type.id == e.id) {
                if (e.coordX > 0 && e.coordY > 0) {
                  console.log("已有坐标");
                } else {
                  FloodList.push(e);
                }
              }
            });
          });
        }
        this.$refs.FloodForm.handleAdd(FloodList);
      } else {
        this.$message("请先选择工点！");
      }
    },
    openMaterial() {
      if (this.lineSite[1]) {
        this.$confirm("是否增加物资库?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let data = {
            id: 10086,
            name: "物资库",
            coordX: 0,
            coordY: 0,
            url: require("../../../assets/map/wuziku.png"),
          };
          this.getMaterial(data);
        });
      } else {
        this.$message("请先选择工点！");
      }
    },
    //获取底图
    async getImage() {
      this.materials = [];
      this.cameraList = [];
      this.floodData = [];
      if (this.lineSite[1]) {
        let i = this.lineSite.length - 1;
        let res = await airphotoInfo(this.lineSite[i]);
        if (res.code == 0) {
          if (res.data) {
            this.image = res.data.photoPath;
            if (
              res.data.materialCoordinates &&
              res.data.materialCoordinates.length > 0
            ) {
              this.materials = res.data.materialCoordinates;
            }
            if (res.data.monitors && res.data.monitors.length > 0) {
              this.cameraList = res.data.monitors;
            }
            if (res.data.proofs && res.data.proofs.length > 0) {
              this.floodData = res.data.proofs;
            }
            this.init(this.image);
          } else {
            this.$message("当前工点没有航拍图，请上传");
          }
        }
      }
    },
    //获取初始化摄像头和位置
    camerasCoords() {
      this.cameraList.forEach((e) => {
        if (e.coordX > 0 && e.coordY > 0) {
          this.getCamera(e.id);
        }
      });
    },
    //获取初始化三防关注和位置
    floodCoords() {
      if (this.floodData && this.floodData.length > 0) {
        this.floodData.forEach((e) => {
          if (
            e.coordinate2D &&
            e.coordinate2D.lat > 0 &&
            e.coordinate2D.lng > 0
          ) {
            let com = this.floodList.find((el) => el.id === e.type.id);
            com.coordX = e.coordinate2D.lat;
            com.coordY = e.coordinate2D.lng;
            this.getFlood(e.type.id);
          }
        });
      } else {
        // this.$message("暂无防汛关注重点!")
      }
    },
    //获取初始化物资库
    materialsCoords() {
      if (this.materials && this.materials.length > 0) {
        let c = this.materials;
        c.forEach((e) => {
          if (e.lat > 0 && e.lng > 0) {
            let data = {
              id: 10086,
              name: "物资库",
              coordX: e.lat,
              coordY: e.lng,
              url: require("../../../assets/map/wuziku.png"),
            };
            this.getMaterial(data);
          }
        });
      }
    },
    //初始化底图
    init(data) {
      this.loading = true;
      let imageObj = new Image();
      imageObj.src = data;
      imageObj.onload = () => {
        this.imageObj = imageObj;
        this.drawBg(imageObj);
        this.loading = false;
      };
    },
    //初始化绘图
    drawBg(imageObj) {
      let container;
      document.getElementById("draw").innerHTML = "";
      container = this.$refs.main;
      this.containers = container;
      let width = container.clientWidth;
      let r = imageObj.width / width;
      this.r = r;
      this.stage = new Konva.Stage({
        container,
        height: imageObj.height / r,
        width: width,
      });
      let bg = new Konva.Image({
        x: 0,
        y: 0,
        image: imageObj,
        width: width,
        height: imageObj.height / r,
      });

      // bg layer
      let layer = new Konva.Layer();
      this.layer = layer;
      this.stage.add(layer);
      this.layer.add(bg);
      this.layer.batchDraw();
      //加载配置了的摄像头
      this.camerasCoords();
      //加载配置了的三防关注；
      this.floodCoords();
      //加载配置了的物资库；
      this.materialsCoords();
    },
    //获取监控摄像头
    getCamera(id) {
      let com = this.cameraList.find((e) => e.id === id);
      if (com.coordX > 0 && com.coordY > 0) {
        console.log("有坐标");
      } else {
        com.coordX = 1;
        com.coordY = 1;
      }
      let imageObj = new Image();
      imageObj.id = com.id;
      imageObj.name = com.name;
      imageObj.coordX = com.coordX;
      imageObj.coordY = com.coordY;
      imageObj.src = require("../../../assets/map/qiangji.png");
      imageObj.onload = () => {
        this.addCamera(imageObj, "camera");
      };
    },
    //获取防汛关注重点
    getFlood(id) {
      let com = this.floodList.find((e) => e.id === id);
      if (com.coordX > 0 && com.coordY > 0) {
        console.log("正常获取");
      } else {
        com.coordX = 1;
        com.coordY = 1;
      }
      let imageObj = new Image();
      imageObj.id = com.id;
      imageObj.name = com.name;
      imageObj.coordX = com.coordX;
      imageObj.coordY = com.coordY;
      imageObj.src = com.url;
      imageObj.ctype = com.id;
      imageObj.onload = () => {
        this.addCamera(imageObj, "flood");
      };
    },
    //获取物资库
    getMaterial(data) {
      let imageObj = new Image();
      imageObj.id = data.id;
      imageObj.name = data.name;
      imageObj.coordX = data.coordX;
      imageObj.coordY = data.coordY;
      imageObj.src = data.url;
      imageObj.ctype = data.id;
      imageObj.onload = () => {
        this.addCamera(imageObj, "materia");
      };
    },
    //将图片添加到绘图画布
    addCamera(imageObj, tname) {
      // darth vader
      var darthVaderImg = new Konva.Image({
        id: imageObj.id,
        name: tname,
        ctype: imageObj.ctype ? imageObj.ctype : 0,
        image: imageObj,
        x: imageObj.coordX,
        y: imageObj.coordY,
        width: 25,
        height: 33,
        draggable: true,
      });
      let _that = this;
      // add cursor styling
      darthVaderImg.on("mouseover", function (evt) {
        document.body.style.cursor = "pointer";
        _that.layers = {
          top: evt.evt.clientY - 50,
          left: evt.evt.clientX + 10,
          index: imageObj.id,
          display: "block",
          name: imageObj.name,
        };
      });
      darthVaderImg.on("mouseout", function (evt) {
        document.body.style.cursor = "default";
        _that.layers = {
          top: evt.evt.clientY - 50,
          left: evt.evt.clientX + 10,
          index: imageObj.id,
          display: "none",
          name: imageObj.name,
        };
      });
      darthVaderImg.on("click", function (evt) {
        console.log(evt);
        console.log(imageObj);
        _that.temList = evt;
        _that.temType = tname;
        _that.$refs.DeleteFrom.handleAdd(imageObj);
        // if (tname == "camera") {
        //   console.log("视频");
        // } else if (tname == "flood") {
        //   console.log("防汛");
        // } else if (tname == "materia") {
        //   console.log("物资库");
        // }
      });
      this.layer.add(darthVaderImg);
      this.stage.add(this.layer);
    },
    //上传底图
    async uploadImg(args) {
      if (this.lineSite[1]) {
        // this.loading = true;
        const loading = this.$loading({
          lock: true,
          text: "上传中...",
          // spinner: 'el-icon-loading',
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.percent = 0;
        const { file } = args;
        this.fileData = file;
        let res = {};
        let fn = this.isThumb ? uploadThumb : upload;
        res = await fn(file, (e) => {
          this.percent = ((e.loaded / e.total) * 100) | 0;
          return args.onProgress({ percent: this.percent });
        }).catch((err) => {
          res.message = err;
        });
        // this.loading = false;
        if (res.code === 0) {
          loading.spinner = "el-icon-success";
          loading.text = "上传成功！";
          setTimeout(() => {
            loading.close();
          }, 800);
          let url = res.data.uri;
          this.upimg(url);
        } else {
          this.message.error("上传失败");
          this.$emit("error", res.message);
        }
      } else {
        this.$message.error("请先选择工点！");
      }
    },
    async upimg(data) {
      let i = this.lineSite.length - 1;
      let params = {
        photoPath: data,
        siteId: this.lineSite[i],
      };
      let res = await airphotoModify(params);
      if (res.code == 0) {
        this.Image = data;
        this.init();
        this.$message({
          message: "新增成功！",
          type: "success",
        });
      } else {
        this.$message.error("提交失败！");
      }
    },
    handleLog() {
      if (this.lineSite[1]) {
        let i = this.lineSite.length - 1;
        let params = {
          siteId: this.lineSite[i],
        };
        airphotoHistory(params).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
          } else {
            this.$message.error("操作失败！");
          }
        });
      } else {
        this.$message.error("请先选择工点！");
      }
    },
    handleDelete(record) {
      console.log("record", record);
      let params = {
        id: 103,
      };
      proolDelete(params).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "操作成功！",
            type: "success",
          });
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    //删除弹窗回调
    delClick() {
      let tname = this.temType;
      let list = this.temList;
      console.log(list.target.attrs.id);
      let rk = this.stage.find("#" + list.target.attrs.id);
      console.log(rk);
      if (tname == "camera") {
        // this.cameraList.forEach((el) => {
        //   if (el.id == list.id) {
        //     el.coordX = 0;
        //     el.coordY = 0;
        //   }
        // });
        rk.forEach((el) => {
          el.attrs.x = 0;
          el.attrs.y = 0;
        });
        console.log("视频");
      } else if (tname == "flood") {
        rk.forEach((el) => {
          el.attrs.x = 0;
          el.attrs.y = 0;
        });
        console.log("防汛");
      } else if (tname == "materia") {
        console.log(list);
        rk.forEach((el) => {
          console.log("物资库");
          console.log(el.attrs.x);
          console.log(list.target.attrs.x);
          console.log(el.attrs.y);
          console.log(list.target.attrs.y);
          if (
            el.attrs.x == list.target.attrs.x &&
            el.attrs.y == list.target.attrs.y
          ) {
            el.attrs.x = 0;
            el.attrs.y = 0;
          }
        });
      }
      this.handleEditTeamInfo(true);

      this.$refs.DeleteFrom.handleClose();
    },
    handleEditTeamInfo(flag) {
      this.iscode = true;
      this.loading = true;
      this.isErrMessage = [];
      // let list = this.stage.children[0].Layer.children;
      let rk = this.stage.find(".camera");
      let flood = this.stage.find(".flood");
      let materia = this.stage.find(".materia");

      rk.forEach((el) => {
        let params = {
          Id: el.attrs.id,
          lat: el.attrs.x,
          lng: el.attrs.y,
        };
        this.isErrMessage.push(siteVideosModify(params));
      });
      flood.forEach((el) => {
        let i = this.lineSite.length - 1;
        let params = {
          siteId: this.lineSite[i],
          coordinate2D: {
            lat: el.attrs.x,
            lng: el.attrs.y,
          },
          type: el.attrs.ctype,
        };
        this.isErrMessage.push(proolInfoPut(el._id, params));
      });
      let i = this.lineSite.length - 1;
      let materList = {
        siteId: this.lineSite[i],
        list: [],
      };
      materia.forEach((el) => {
        if (el.attrs.x > 0 && el.attrs.y > 0) {
          let params = {
            lat: el.attrs.x,
            lng: el.attrs.y,
          };
          materList.list.push(params);
        }
      });
      this.isErrMessage.push(siteMaterialsModify(materList));
      console.log(this.isErrMessage, "this.isErrMessage");
      Promise.all(this.isErrMessage)
        .then((res) => {
          console.log(res, "res");
          let code = res.findIndex((i) => i.code != 0);
          console.log(code);
          if (code == -1) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            if (flag) {
              this.getImage();
            }
          } else {
            this.$message.error("操作失败！" + res[code].message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$message.error("操作失败！" + err.message);
          this.$message.error("操作失败！" /* + err.message */);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.draw-main {
  height: 801px;
  width: 1068px;
}
.cardws {
  height: 100px;
  min-width: 80px;
  border: 1px solid rgba(8, 23, 66, 0.1);
  img {
    width: 32px;
    height: 32px;
  }
}
.layer {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 5px;
  text-align: left;
  font-size: 14px;
  color: #fff;
  z-index: 100000000;
}
</style>
