<template>
  <div
    style="wieth: 100%; height: 100%; border-radius: 10px;"
    class="flex flex-col conLeft"
  >
    <Task></Task>
    <ReportInfo></ReportInfo>
    <RankedBranch></RankedBranch>
  </div>
</template>

<script>
import Task from "./task";
import ReportInfo from "./reportInfo";
import RankedBranch from "./rankedBranch";

export default {
  name: "routeList",
  components: { Task, RankedBranch, ReportInfo },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.conLeft {
  height: 100%;
  display: grid;
  grid-template-rows: 26% 39% calc(35% - 20px);
  grid-row-gap: 10px;
}
::v-deep .el-input__inner {
  background-color: #0b1b4f !important;
  border: 1px solid #173f58 !important;
}
</style>
