<template>
  <div class="content">
    <div class="info-wrapper">
      <el-table
        :data="tableHeaderData"
        height="115"
        border
        style="width:100%;margin-bottom:20px"
      >
        <el-table-column
          align="center"
          prop="captainName"
          show-overflow-tooltip
          label="所属建管部"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          show-overflow-tooltip
          label="线路名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="branchName"
          show-overflow-tooltip
          label="分部名称"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          show-overflow-tooltip
          label="工点名称"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="teamName"
          show-overflow-tooltip
          label="负责人"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="captainPhone"
          show-overflow-tooltip
          label="联系方式"
        >
        </el-table-column>
      </el-table>
      <el-form :inline="true" :model="libraryForm" class="demo-form-inline">
        <el-form-item>
          <el-input
            v-model="libraryForm.name"
            placeholder="请输入姓名搜索"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="libraryQuery">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :data="tableData" height="400" border style="width: 100%">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="60"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="workerName"
          show-overflow-tooltip
          width="160"
          label="姓名"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="workRoleName"
          show-overflow-tooltip
          width="160"
          label="职务"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="workerPhone"
          show-overflow-tooltip
          width="180"
          label="联系电话"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="workerEducation"
          show-overflow-tooltip
          width="160"
          label="学历"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="workerSpecialty"
          show-overflow-tooltip
          width="160"
          label="专业"
        >
        </el-table-column>
        <el-table-column align="center" label="照片">
          <template slot-scope="scope">
            <span v-if="scope.row.workerImg">
              <img
                :src="scope.row.workerImg"
                style="width:  50px; height: 50px; margin: auto"
              />
            </span>
            <span v-else style="width:  50px; height: 50px; margin: auto">
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="照片">
          <template slot-scope="scope">
            <span>
              <img
                :src="scope.row.workerImg"
                alt=""
                style="width: 50px; height: 50px"
              />
            </span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="yuyin(scope.row)" type="text" size="small"
              >语音</el-button
            >
            <el-button @click="yuyin(scope.row)" type="text" size="small"
              >视频</el-button
            ><el-button @click="yuyin(scope.row)" type="text" size="small"
              >查看详情</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
    </div>

    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="libraryForm.page"
        :page-size="libraryForm.size"
        layout="prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getWorkers } from "@/api/eres";

export default {
  components: {},
  props: {
    partRescue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      libraryForm: {
        name: "",
        teamId: "",
        postId: "-",
        page: 1,
        size: 10,
      },
      tableData: [],
      tableHeaderData: [],
      total: 0,
    };
  },
  watch: {
    "partRescue.id": {
      handler(newVal) {
        console.log(newVal);
        this.getDatalist();
        this.getTableData();
      },
    },
    deep: true,
    immediate: false,
  },
  mounted() {
    this.getDatalist();
    this.getTableData();
    // this.tableHeaderData.push({
    //   captainName: this.partRescue.captainName,
    //   lineName: this.partRescue.lineName,
    //   branchName: this.partRescue.branchName,
    //   siteName: this.partRescue.siteName,
    //   teamName: this.partRescue.teamName,
    //   captainPhone: this.partRescue.captainPhone,
    // });
  },
  methods: {
    getTableData() {
      this.tableHeaderData = [];
      this.tableHeaderData.push({
        captainName: this.partRescue.captainName,
        lineName: this.partRescue.lineName,
        branchName: this.partRescue.branchName,
        siteName: this.partRescue.siteName,
        teamName: this.partRescue.teamName,
        captainPhone: this.partRescue.captainPhone,
      });
    },
    getDatalist() {
      this.loading = true;
      const params = {
        teamId: this.partRescue.id,
        postId: this.libraryForm.postId ? this.libraryForm.postId : "-",
        name: this.libraryForm.name ? this.libraryForm.name : "-",
        page: this.libraryForm.page,
        size: this.libraryForm.size,
      };
      getWorkers(params).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.records ? res.data.records : [];
          this.loading = false;
          this.total = res.data.count;
        }
      });
    },
    clearCloseData() {
      this.libraryForm.page = 1;
      this.libraryForm.size = 10;
    },
    libraryCls() {
      this.$emit("libraryCls");
    },
    // 查询
    libraryQuery() {
      this.getDatalist();
    },

    handleCurrentChange(val) {
      this.page = val;
      this.getDatalist();
    },
    handleSizeChange(size) {
      this.size = size;
      this.getDatalist();
    },
    yuyin(val) {
      console.log(val);
    },
    shipin(val) {
      console.log(val);
    },
    duanxian(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss" scoped>
// .content {
//   height: 500px;
//   overflow-y: auto;
// }
.info-wrapper {
  ::v-deep .el-table--border,
  .el-table::before {
    border: 0 !important;
    height: 0px;
  }
}
.peopleNum {
  color: #000;
  font-weight: bold;
}
::v-deep .el-radio-button__inner {
  width: 200px;
}
::v-deep .el-input__inner {
  background: #081742;
  border-color: #25458f;
  color: #fff;
}
.el-table--border::after {
  width: 0;
}
::v-deep .el-input__inner:hover {
  border-color: #25458f;
}
::v-deep .el-table th.is-leaf,
::v-deep .el-table--border td {
  border: 1px solid #143363 !important;
}
// ::v-deep .el-table {
//   border: 0 !important;
//   border-bottom: none;
// }
</style>
