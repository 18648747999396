<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" :title="title">
    <el-row>
      <el-col :span="4">应急处置卡</el-col>
      <el-col :span="14">
        <el-upload
          action="action"
          :http-request="uploadFile"
          :file-list="fileList"
          :before-upload="beforeUpload"
          :limit="1"
          accept=".xls,.xlsx"
        >
          <el-button size="small" type="primary">点击上传应急处置卡</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传xls/xlsx文件
          </div>
        </el-upload>
      </el-col>
    </el-row>
    <!--   <el-dialog
      title="正在进行上传"
      :visible.sync="isFinally"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <el-progress
        :text-inside="true"
        :stroke-width="20"
        :percentage="percent"
      ></el-progress>
      <p>正在上传...</p>
    </el-dialog> -->
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { Emergencyimport } from "@/api/emergency";
// eslint-disable-next-line no-unused-vars
import { upload, uploadThumb } from "@/api/upload";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "新增",
      fileList: [],
      rowdata: {},
      // isFinally: false,
      percent: 0,
    };
  },
  methods: {
    handleCardImport(data) {
      console.log(data);
      this.rowdata = data;
      this.title = "应急处置卡导入";
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    // 自定义上传方法
    async uploadFile(args) {
      this.importFile = args.file;
      // this.isFinally = true;
      this.percent = 0;
      const { file } = args;
      let res = {};
      let fn = upload;
      res = await fn(file, (e) => {
        console.log(e);
        this.percent = ((e.loaded / e.total) * 100) | 0;
        return args.onProgress({ percent: this.percent });
        // return this.percent;
      }).catch((err) => {
        res.message = err;
        console.error(err);
      });
      if (res.code === 0) {
        // this.isFinally = false;
        this.$message.success("上传成功");
      } else {
        this.message.error("上传失败");
        this.$emit("error", res.message);
        // this.isFinally = false;
      }
    },
    // 上传前调用
    beforeUpload(file) {
      console.log(file);
      const tempArr = file.name.split(".");
      const isValid = tempArr.includes("xls") || tempArr.includes("xlsx");
      if (!isValid) {
        this.$message.error("只支持xls、xlsx格式文件！");
        return false;
      }
    },
    handleSubmit() {
      console.log(this.importFile);
      if (this.importFile !== null && this.importFile !== undefined) {
        this.loading = true;
        let parms = {
          importFile: this.importFile,
          planName: this.rowdata.planName,
          planId: this.rowdata.planId,
        };
        const data = new FormData();
        Object.keys(parms).forEach((key) => {
          if (parms[key]) {
            data.append(key, parms[key]);
          }
        });
        Emergencyimport(data)
          .then((res) => {
            if (res.code == 0) {
              this.$message.success("应急处置卡导入成功");
              this.loading = false;
              this.importFile = null;
              this.$refs.formModal.handleClose();
            } else {
              this.$message.error(res.messsage);
              this.loading = false;
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$message.error(err);
          });
      } else {
        this.$message.error("请先上传应急处置卡");
      }
    },
  },
};
</script>

<style></style>
