<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :width="width"
    :before-close="handleClose"
    :fullscreen="fullscreen"
    :modal="modal"
    :close-on-click-modal="false"
    :append-to-body="true"
    :show-close="false"
    center
  >
    <div class="modal-container">
      <div class="corner-wrap">
        <div class="corner corner-left-top"></div>
        <div class="corner corner-right-top"></div>
        <div class="corner corner-right-bottom"></div>
        <div class="corner corner-left-bottom"></div>
      </div>
      <div class="modal-wrapper p-4">
        <div class="header flex items-center justify-center mb-4">
          <div class="title text-center">{{ title }}</div>
          <div class="close-btn absolute right-4" @click="handleClose"></div>
        </div>
        <slot></slot>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      dialogVisible: false,
      fullscreen: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "标题",
    },
    width: {
      type: String,
      default: "30%",
    },
    modal: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
  methods: {
    handleOpen() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("closeDialog");
    },
    // 全屏按钮切换
    handleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 0;

  // margin-top: 26vh !important;
  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
    position: relative;
    background: #081742;
    border: 1px solid #002e75;
    box-shadow: 0px 0px 23px 6px #002e75 inset;
  }
}

.corner-wrap {
  width: 100%;
  height: 100%;
  position: absolute;

  .corner {
    width: 16px;
    height: 16px;
  }

  .corner-left-top {
    border-left: 2px solid #43a5e9;
    border-top: 2px solid #43a5e9;
    position: absolute;
    left: 0;
    top: 0;
  }

  .corner-right-top {
    border-right: 2px solid #43a5e9;
    border-top: 2px solid #43a5e9;
    position: absolute;
    right: 0;
    top: 0;
  }

  .corner-right-bottom {
    border-right: 2px solid #43a5e9;
    border-bottom: 2px solid #43a5e9;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .corner-left-bottom {
    border-left: 2px solid #43a5e9;
    border-bottom: 2px solid #43a5e9;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.modal-wrapper {
  .header {
    .title {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }

    .close-btn {
      width: 20px;
      height: 20px;
      background-image: url(../../assets/closeBtn.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  ::v-deep .el-table th > .cell,
  ::v-deep .el-table th.el-table__cell {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
  ::v-deep .el-table td div {
    color: #fff;
  }
  ::v-deep .el-table td,
  ::v-deep .el-table th.is-leaf {
    border: none;
  }
  ::v-deep .el-table td.el-table__cell,
  ::v-deep .el-table th.el-table__cell.is-leaf {
    border: none;
  }
}

::v-deep .el-pagination {
  position: relative;
  z-index: 999;
  .btn-prev,
  .btn-next {
    background: #18467f;

    .el-icon {
      color: #ffffff;
    }
  }

  .el-pager li {
    background: #18467f;
    color: #fff;
  }
}
</style>
