<template>
  <div class="login">
    <div class="login-img">
      <!-- <img src="../assets/login/logo_title.png" /> -->
    </div>
    <div class="login-p">
      <p class="font-bold">轨道交通工程建设安全风险管控平台</p>
      <span class="login-span">欢迎您！</span>
    </div>
    <div class="login-form">
      <div class="login-center text-center">
        <el-form @keyup.enter.native="handleClickLogin">
          <el-input class="login-name" v-model="params.username" ref="username"
            ><i slot="prefix" class="el-input__icon el-icon-user text-lg"></i
          ></el-input>
          <el-input
            class="login-password"
            type="password"
            v-model="params.password"
            ><i slot="prefix" class="el-input__icon el-icon-lock text-lg"></i
          ></el-input>
          <el-button
            class="mt-2 login-stmbtn"
            type="primary"
            @click="handleClickLogin()"
            :loading="loading"
            >{{ loading ? "正在登录" : "立即登录" }}</el-button
          >
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/user"
import qs from "qs"
import { tripleTree, worksiteTree } from "@/api/map"
import { queryResourceByRole, queryResourceList, apermission } from "@/api/resource"
import { emergencyExpireSendMessage } from "@/api/emergency"
export default {
  data () {
    return {
      params: {
        username: "",
        password: "",
      },
      activeName: "first",
      loading: false,
      jumpMenu: null
    }
  },
  mounted () {
    this.$refs.username.focus()
    // if (!localStorage.yjxTken) {
    //   let url = api.loginURL + "/v1/connect/authorize";
    //   let options = {
    //     response_type: "code",
    //     client_id: api.clientId,
    //     state: 1234,
    //     scope: "api openid offline_access",
    //     redirect_uri: api.callbackURL,
    //   };
    //   this.login(url, options);
    // }
  },
  methods: {
    // 查询线路工点树
    async queryLineSite () {
      let result = await tripleTree({})
      if (result.code == 0) {
        localStorage.setItem("lineSiteTree", JSON.stringify(result.data))
        return result
      }
      worksiteTree({}).then((res) => {
        if (res.code == 0) {
          localStorage.setItem("DetailsTree", JSON.stringify(res.data))
        }
      })
    },
    // 查询全部资源列表
    async queryResourceAll () {
      const params = {
        name: "-",
        parentId: "-",
        resourcePath: "-",
        type: "-",
      }
      let res = await queryResourceList(params)
      if (res.code == 0) {
        return res.data
      }
    },
    /**
     * 查询角色的权限列表
     * @param {String} roleIds 角色集合字符串，逗号分隔
     */
    async queryRoleResource (roleIds) {
      let res = await queryResourceByRole(roleIds)
      if (res.code == 0) {
        return res.data
      }
    },
    /**
     * 查询用户的权限列表
     * @param {String} userId 账户id
     */
    async queryAccountResource (userId) {
      let res = await apermission(userId)
      if (res.code == 0) {
        return res.data
      }
    },
    /**
     * 资源权限组合查询
     * @param {Object} userInfo 用户信息
     */
    async combinedQuery (userInfo) {
      // 菜单权限列表
      const systemPermission = [] // 工作台菜单
      const screenPermission = [] // 大屏菜单
      // 按钮权限列表
      const btnPermission = []
      // 具有的权限列表
      let hasPermission = []
      const roleIds = userInfo.roleIdList.join(",")
      const [resource, rolePermission] = await Promise.all([
        this.queryResourceAll(),
        this.queryRoleResource({ roleIds }),
      ])
      // 超级管理员
      if (userInfo.roleIdList.includes(1)) {
        for (let r of resource) {
          if (r.type == 0) {
            if (r.category == 0) {
              systemPermission.push(r)
            } else {
              screenPermission.push(r)
            }
          } else {
            btnPermission.push(r)
          }
        }
      } else {
        // 权限列去重
        const tempArr = [...rolePermission]
        let tempMap = new Map()
        for (let item of tempArr) {
          if (!tempMap.has(item.resourceId)) {
            tempMap.set(item.resourceId, item)
          }
        }
        hasPermission = [...tempMap.values()]
        // 过滤菜单权限和按钮权限
        for (let r of resource) {
          for (let p of hasPermission) {
            if (p.resourceId == r.id) {
              if (r.type == 0) {
                if (r.category == 0) {
                  systemPermission.push(r)
                } else {
                  screenPermission.push(r)
                }
              } else {
                btnPermission.push(r)
              }
            }
          }
        }
      }
      localStorage.setItem("resource", JSON.stringify(resource))
      localStorage.setItem("USER_AUTH", JSON.stringify(systemPermission))
      localStorage.setItem("SCREEN_AUTH", JSON.stringify(screenPermission))
      localStorage.setItem("USER_BTN_AUTH", JSON.stringify(btnPermission))
      const ScreenMenu = this.initMenuTree(screenPermission)
      if (ScreenMenu.length) {
        this.jumpMenu = this.findValidMenu(ScreenMenu[0])
      } else {
        this.jumpMenu = {
          resourcePath: "/"
        }
      }
      localStorage.setItem("FIRST_MEUN", JSON.stringify(this.jumpMenu))
      return Promise.resolve("success")
    },
    // 菜单树初始化
    initMenuTree (resourceData) {
      let handleResource = (resource) => {
        let parent = resourceData.find((p) => p.id == resource.parentId)
        if (parent) {
          if (resource.parentId !== 0 && parent.children) {
            parent.children.push(resource)
          } else {
            parent.children = [resource]
          }
        } else {
          console.log(parent)
        }
      }
      resourceData.forEach((item) => handleResource(item))
      const result = resourceData.filter((item) => item.parentId === 0)
      return result
    },
    // 找到第一个有效的菜单
    findValidMenu (resource) {
      if (resource.children) {
        return this.findValidMenu(resource.children[0])
      } else {
        return resource
      }
    },
    //测试登录
    async login (url, options) {
      let _win
      let _winTime
      return new Promise((resolve, reject) => {
        _win = window.location.href = `${url}?${qs.stringify(options)}`
        _winTime = setInterval(() => {
          if (_win && _win.closed) {
            clearInterval(_winTime)
            const data = JSON.parse(localStorage.getItem("socialData"))
            localStorage.removeItem("socialData")
            // 触发变更通知
            if (data) {
              resolve(data)
            } else {
              reject(data)
            }
          }
        }, 200)
      })
    },
    async handleClickLogin () {
      let res = {}
      const { password, username } = this.params
      if (!password || !username) {
        this.$message.error("请正确输入用户名及密码")
        return
      }
      this.loading = true
      try {
        res = await login(this.params)
      } catch (error) {
        res.code = 9
      }
      // res.code = 0
      if (res.code === 0) {
        let now = new Date()
        // save user info & jwtToken
        localStorage.setItem("user", JSON.stringify(res.data))
        localStorage.setItem("jwtToken", res.data.jwtToken)
        localStorage.setItem(
          "jwtTokenExpire",
          now.getTime() + 3 * 60 * 60 * 1000
        )
        // localStorage.removeItem("lineSiteTree");//登录时清理线路local
        // fetch permission
        // let permission = new Permission();
        // await permission.init(true);
        // attach Vue prototype
        // Vue.prototype.$permission = permission;
        // let operation = new Operation();
        // await operation.init();
        // Vue.prototype.$operation = operation;
        // navigate
        Promise.all([this.combinedQuery(res.data), this.queryLineSite()]).then(
          (res) => {
            if (res) {
              this.$router.push(this.jumpMenu.resourcePath)
              this.loading = false
            }
          }
        )
      } else {
        this.$message.error("登录失败")
        this.loading = false
      }
      emergencyExpireSendMessage().then(res => {
        console.log(res)
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
  width: 100vw;
  background: url("../assets/login/login_bg.png") repeat 100%;
  background-size: 100% 100%;
  padding-top: 80px;
  &-img {
    width: 460px;
    margin: 0 20% 120px auto;
  }
  &-p {
    color: #344955;
    font-size: 28px;
    width: 460px;
    margin: 0 7% 60px auto;
  }
  &-span {
    font-size: 20px;
  }
  &-form {
    width: 400px;
    margin: 0 10% 0 auto;
  }
  &-center {
    margin: 5px 0;
  }
  &-name,
  &-password {
    margin-bottom: 40px;
    ::v-deep .el-input__inner {
      height: 50px;
      background: #ffffff;
      border: 2px solid #e6e6e6;
      border-radius: 10px;
    }
  }
  &-stmbtn {
    height: 50px;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    background: #f9aa33;
    border: none;
    border-radius: 10px;
    &:hover {
      background: #f59003;
    }
  }
}
</style>
