<template>
  <div style="font-size:16px">
    <el-form ref="checkForm" :model="temList" label-width="130px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="事件类型:" prop="typeLabel">
            <el-input
              readonly
              placeholder="事件类型"
              v-model="temList.type.label"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="事件级别:" prop="currentLevelLabel">
            <el-input
              placeholder="事件级别"
              v-model="temList.currentLevel.label"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="启动时间:" prop="startTime">
            <el-input
              placeholder="启动时间"
              v-model="temList.startTime"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发布人:" prop="publisherName">
            <el-input
              placeholder="发布人"
              v-model="temList.publisherName"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="应急响应要求:" prop="respondImage">
            <el-input
              type="textarea"
              resize="none"
              placeholder="应急响应要求"
              :autosize="{ minRows: 2, maxRows: 6 }"
              v-model="temList.respondImage"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="margin-bottom:10px">
      <el-radio-group v-model="radio" @change="radioChange">
        <el-radio-button :label="1">三防检查情况</el-radio-button>
        <el-radio-button :label="2">市交通统计信息填报情况</el-radio-button>
        <el-radio-button :label="3">工作简报情况</el-radio-button>
        <el-radio-button :label="4">响应事件进展</el-radio-button>
      </el-radio-group>
    </div>
    <div v-show="radio == '2' || radio == '3'">
      <el-radio-group
        size="mini"
        v-model="degree"
        v-for="item in times"
        :key="item.time"
        @change="degreeChange"
      >
        <el-radio-button
          style="margin:0 10px 5px 0"
          :label="item.label"
        ></el-radio-button>
      </el-radio-group>
    </div>
    <div class="details" v-show="radio != '4'">
      {{ description }}
    </div>
    <div class="table-wrapper detailHeight mt-4" v-show="radio != '4'">
      <el-table
        :data="tableData"
        v-loading="loading"
        style="width: 100%;"
        row-key="code"
        border
        default-expand-all
        height="400"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          type="index"
          label="序号"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="lineName"
          label="线路/工点"
          header-align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.children == null"
              style="color: #7073ff; cursor: pointer"
              @click="lookLineName(scope.row)"
            >
              {{ scope.row.lineName }}
            </span>
            <span v-if="scope.row.children">
              {{ scope.row.lineName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="constructionManagementName"
          label="所属建管部"
          header-align="center"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          prop="performance"
          label="完成情况"
          align="center"
          show-overflow-tooltip
          :formatter="manceFormatter"
        >
        </el-table-column>
        <el-table-column
          prop="issueCount"
          label="问题数量"
          align="center"
          show-overflow-tooltip
          v-if="radio == '1'"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.issueList"
              style="color: #7073ff; cursor: pointer"
              @click="lookNamePop(scope.row)"
            >
              {{ scope.row.issueCount }}
            </span>
            <span v-if="scope.row.issueList == null">
              {{ scope.row.issueCount }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 响应事件进展 -->
    <el-timeline :reverse="false" style="margin-top:20px" v-show="radio == '4'">
      <el-timeline-item
        v-for="(activity, index) in historyData"
        :key="index"
        type="primary"
        color="#3fa5e9"
        :size="'large'"
      >
        <i> </i>
        <i style="font-style:normal;margin-right: 5px;">
          {{ activity.operatorTime }}
        </i>
        <span> {{ activity.actionType }}{{ activity.operationValue }} </span>
        <span>
          {{
            activity.actionType == "发布"
              ? activity.record.originalAlertMessage
              : activity.actionType == "结束"
              ? activity.record.endRemark
              : activity.record.relegationRemark
          }}
        </span>
      </el-timeline-item>
    </el-timeline>
    <!-- <div
      class="mb-4"
      style="display: flex;
  justify-content: center;
  margin-top: 20px"
    >
      <el-button type="primary" @click="sureSub">关闭</el-button>
    </div> -->
    <!-- 问题清单 -->
    <el-dialog
      title="问题清单"
      :visible.sync="dialogVisible"
      append-to-body
      width="70%"
      center
    >
      <CheckQuestion
        v-if="dialogVisible"
        :parmsData="parmsData"
      ></CheckQuestion>
    </el-dialog>
    <!-- 三防问题检查表 -->
    <el-dialog
      title="三防应急检查表"
      :visible.sync="dialogVisibleOne"
      append-to-body
      width="50%"
    >
      <inspectApproval
        v-if="dialogVisibleOne"
        :operation="title"
        :editData="editData"
        @cancelApproval="cancelApproval"
        @successApproval="successApproval"
      ></inspectApproval>
    </el-dialog>
    <!-- 统计表 -->
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="dialogVisibleTwo"
      width="80%"
      :before-close="buildWriteClose"
      center
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <buildWrite
        ref="child"
        @buildWriteClose="buildWriteClose"
        :buildWriteId="buildWriteId"
        :buildWriteResponseId="buildWriteResponseId"
        :threeTitle="threeTitle"
      ></buildWrite>
    </el-dialog>
    <!-- 工作简报 -->
    <Briefing
      ref="fillingModal1"
      @fillingSubmit="handleFillingSubmit"
    ></Briefing>
  </div>
</template>

<script>
import { getCurrentResponseTreeById } from "@/api/response";
import CheckQuestion from "./checkQuestion.vue";
import HistoryPage from "./historyPage.vue";
import inspectApproval from "../components/inspectApproval.vue";
import buildWrite from "../workStatistics/buildWrite.vue";
import Briefing from "../components/briefing.vue";
export default {
  props: {
    temList: {},
    historyData: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    HistoryPage,
    CheckQuestion,
    inspectApproval,
    Briefing,
    buildWrite,
  },
  data() {
    return {
      radio: "1",
      degree: "第1次",
      times: [],
      description: "",
      tableData: [],
      //问题清单
      dialogVisible: false,
      // 三防检查表
      dialogVisibleOne: false,
      // 交通统计表
      dialogVisibleTwo: false,
      buildWriteId: "",
      buildWriteResponseId: "",
      checkId: "", //当前行的id
      responseId: "", //事件id
      parmsData: [],
      loading: false,
      threeTitle: "",
    };
  },
  created() {
    let val = 139,
      num = 0;
    this.getTable(val, num);
  },

  // mounted() {},
  // watch: {
  //   historyData: {
  //     handler(val) {
  //       if (val) {
  //         console.log("laiba", val);
  //       }
  //     },
  //     deep: true,
  //     clickShow: false,
  //   },
  // },
  methods: {
    getTable(val, num) {
      this.loading = true;
      this.description = "";
      this.times = [];
      let paramsData = {
        responseRecordId: this.temList.id,
        type: val,
        number: num,
      };
      getCurrentResponseTreeById(paramsData)
        .then((res) => {
          if (res.code == 0) {
            this.description = res.data.description;
            this.tableData = res.data.treeList ? res.data.treeList : [];
            if (res.data.maxNumber) {
              for (let i = 1; i <= res.data.maxNumber; i++) {
                this.times.push({
                  label: `第${i}次`,
                  value: i,
                });
              }
            }
            this.loading = false;
          }
        })
        .catch((err) => {
          this.tableData = [];
          console.log(err);
          this.loading = false;
        });
    },
    // 0-待填写、1-待监理审核、2-待建管审核、3-待质安部审核、4-已完成
    manceFormatter(row) {
      const condition = ["0", "1", "2", "3", "4"];
      if (condition.includes(row.performance)) {
        return row.performance == 0
          ? "待填写"
          : row.performance == 1
          ? "待监理审核"
          : row.performance == 2
          ? "待建管审核"
          : row.performance == 3
          ? "待质安部审核"
          : row.performance == 4
          ? "已完成"
          : "";
      } else {
        return row.performance;
      }
    },
    // change切换
    radioChange(val) {
      if (val == 1) {
        let ar = 139,
          num = 0;
        this.getTable(ar, num);
      } else if (val == 2) {
        let ar = 141,
          num = 1;
        this.getTable(ar, num);
      } else if (val == 3) {
        let ar = 140,
          num = 1;
        this.getTable(ar, num);
      }
      this.degree = "第1次";
    },
    // 次数
    degreeChange(val) {
      if (this.radio == 2) {
        let ar = 141,
          num = val.replace(/[^\d]/g, " ");
        this.getTable(ar, num);
      } else if (this.radio == 3) {
        let ar = 140,
          num = val.replace(/[^\d]/g, " ");
        this.getTable(ar, num);
      }
    },
    // 线路/工点
    lookLineName(val) {
      if (this.radio == 1) {
        this.dialogVisibleOne = true;
        this.title = "查看";
        this.editData = { ...val };
      } else if (this.radio == 2) {
        // 施工
        this.dialogVisibleTwo = true;
        this.threeTitle = "check";
        this.buildWriteId = val.id;
        this.buildWriteResponseId = this.temList.id;
      } else if (this.radio == 3) {
        if (val.performance == 0) {
          this.$message.warning("待施工单位填写后才可查看");
        } else {
          this.$refs.fillingModal1.handleFilling(val, "read");
        }
      }
    },
    // 检查表
    successApproval() {
      this.dialogVisibleOne = false;
    },
    cancelApproval() {
      this.dialogVisibleOne = false;
    },
    // 统计表
    buildWriteClose() {
      this.dialogVisibleTwo = false;
    },
    // 工作简报
    handleFillingSubmit() {
      let val = 139,
        num = 0;
      this.getTable(val, num);
    },
    // 问题数量
    lookNamePop(val) {
      this.dialogVisible = true;
      this.parmsData = val.issueList;
    },
    // sureSub() {
    //   this.$emit("detailClose");
    // },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 700px;
  overflow: auto;
}
::v-deep .el-dialog--center .el-dialog__body {
  padding: 0 25px !important;
}
::v-deep.el-table::before {
  display: none;
}
::v-deep .el-table__header-wrapper {
  position: sticky;
  top: 0;
  z-index: 1;
}
.mb-4 {
  position: absolute;
  bottom: 1rem;
  left: 49%;
}

// /*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}
// /*定义滚动条的轨道颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-track {
  display: none;
}
/*定义滑块颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar {
  display: block;
  width: 16px;
  height: 7px;
}
// 滑块设置
::v-deep ::-webkit-scrollbar-thumb {
  background-color: rgb(205, 199, 199);
  border-radius: 0;
}
.detailHeight ::v-deep .el-table__body-wrapper {
  height: 348px !important;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
</style>
