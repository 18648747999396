<template>
  <div class="material-container h-full">
    <el-form
      ref="formData"
      :rules="rules"
      :model="formData"
      :loading="loading"
      label-width="110px"
      :disabled="submitType == 'view'"
    >
      <el-form-item label="模块名称:" prop="modularCode">
        <el-select
          v-model="formData.modularCode"
          style="width: 100%"
          placeholder="请选择单位类型"
          @change="getNodeList"
        >
          <el-option
            v-for="item of modularCodeList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="流程节点:" prop="processNodeCode">
        <el-select
          v-model="formData.processNodeCode"
          clearable
          style="width: 100%"
          placeholder="请选择流程节点"
        >
          <el-option
            v-for="item of nodeList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="流转节点" prop="circulationNodeCode">
        <el-select
          v-model="formData.circulationNodeCode"
          clearable
          placeholder="请选择流转节点"
          style="width: 100%"
        >
          <el-option
            v-for="item in nodeList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="驳回节点" prop="rejectNodeCode">
        <el-select
          v-model="formData.rejectNodeCode"
          clearable
          placeholder="请选择驳回节点"
          style="width: 100%"
        >
          <el-option
            v-for="item in nodeList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时限" prop="processDeadline">
        <el-input
          v-model="formData.processDeadline"
          clearable
          placeholder="请输入时限"
          style="width: 100%"
        >
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="创建时间" prop="created">
        <el-date-picker
          v-model="formData.created"
          type="date"
          placeholder="请选择日期"
          style="width: 100%"
        >
        </el-date-picker>
      </el-form-item> -->
      <el-form-item label="权限设置:">
        <el-button size="medium" type="primary" @click="allocate"
          >分配</el-button
        >
      </el-form-item>
    </el-form>
    <div class="dialogFooter">
      <el-button size="medium" @click="cancel" v-show="submitType !== 'view'"
        >取 消</el-button
      >
      <el-button
        size="medium"
        type="primary"
        @click="submit"
        v-show="submitType !== 'view'"
        >确 定</el-button
      >
    </div>
    <!-- <div class="form-wrapper">
      <el-dialog
        v-if="dialogVisible"
        width="30%"
        title="分配权限"
        :visible.sync="dialogVisible"
        append-to-body
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div class="tree-wrapper h-full overflow-y-scroll mr-4">
          <el-tree
            v-loading="treeLoading"
            :data="treeData"
            show-checkbox
            node-key="id"
            :props="defaultProps"
            ref="treeRef"
            accordion
            :default-checked-keys="defaultCheckedKeys"
            element-loading-text="拼命加载中"
          >
          </el-tree>
        </div>
        <span slot="footer" class="dialogFooter">
          <el-button @click="chooseCancel">上一步</el-button>
          <el-button type="primary" @click="allotSubmit">提交</el-button>
        </span>
      </el-dialog>
    </div> -->
    <!-- <div class="form-wrapper">
      <el-dialog
        v-if="dialogVisibleMenu"
        width="30%"
        title="分配权限"
        :visible.sync="dialogVisibleMenu"
        append-to-body
        :before-close="handleCloseMenu"
        :close-on-click-modal="false"
      >
        <div class="tree-wrapper h-full overflow-y-scroll mr-4">
          <el-form>
            <el-form-item label="选择菜单:">
              <el-cascader
                :options="treeDataMenu"
                :props="defaultPropsMenu"
                :show-all-levels="false"
                style="width: calc(100% - 100px)"
                v-model="menuId"
              ></el-cascader>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialogFooter">
          <el-button @click="chooseCancelMenu">取 消</el-button>
          <el-button type="primary" @click="menuNext">下一步</el-button>
        </span>
      </el-dialog>
    </div> -->

    <div class="form-wrapper">
      <el-dialog
        v-if="dialogVisible"
        width="30%"
        title="分配权限"
        :visible.sync="dialogVisible"
        append-to-body
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div class="tree-wrapper h-full overflow-y-scroll mr-4">
          <el-tree
            v-loading="treeLoading"
            :data="treeData"
            show-checkbox
            node-key="id"
            :props="defaultProps"
            ref="treeRef"
            accordion
            :default-checked-keys="defaultCheckedKeys"
            element-loading-text="拼命加载中"
          >
          </el-tree>
        </div>
        <span slot="footer" class="dialogFooter">
          <el-button @click="chooseCancel">取 消</el-button>
          <el-button type="primary" @click="allotSubmit">提交</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// 字典
import { getDictData } from "@/api/dict";
// 查询角色列表
import { getRoleApi } from "@/api/role";
// 获取权限单位
import { getUnitRoles } from "@/api/hdanger";
// 新增,编辑
import { processAdd, processEdit, getProcessById } from "@/api/risk";
import {
  queryResourceList,
  getRoleIds,
  // addMore,
  // delMore,
} from "@/api/resource";

export default {
  props: {
    pageData: {
      type: Object,
      default: () => {},
    },
    submitType: {
      type: String,
    },
  },
  data() {
    return {
      formData: {
        modularCode: "",
        processNodeCode: "",
        circulationNodeCode: "",
        rejectNodeCode: "",
        processDeadline: "",
        created: "",
      },
      // 模块
      modularCodeList: [],
      // 角色
      rolesData: [],
      loading: false,
      rules: {
        modularCode: [
          { required: true, message: "请选择模块名称", trigger: "change" },
        ],
        processNodeCode: [
          { required: true, message: "请选择流程节点", trigger: "change" },
        ],
        circulationNodeCode: [
          { required: true, message: "请选择流转节点", trigger: "change" },
        ],
      },
      nodeList: [],
      //分配权限
      treeData: [],
      treeDataMenu: [],
      dialogVisible: false,
      dialogVisibleMenu: false,
      treeLoading: false,
      menuLoading: false,
      defaultProps: {
        label: "roleName",
        children: "children",
      },
      defaultPropsMenu: {
        value: "id",
        label: "resourceName",
        children: "children",
      },
      defaultCheckedKeys: [],
      menuId: "",
      permissionLevel: "",
      oldRoleId: [], // 旧的角色id集合
      onOff: false,
    };
  },
  watch: {
    "pageData.id": {
      handler(val) {
        if (val) {
          this.getData();
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
  },
  created() {
    this.getUnitData();
  },
  mounted() {
    this.getDict();
  },
  methods: {
    getData() {
      // this.formData.roleId = this.pageData.roleName;
      this.formData = { ...this.pageData };
      this.formData.roleId = Number(this.pageData.roleId);
      this.rolesData = [];
      if (this.formData.modularCode) {
        this.getNodeList(this.formData.modularCode);
      }
      this.menuId = this.formData.menuId ? [this.formData.menuId] : [];

      getRoleApi({ unitType: this.pageData.responseUnitType })
        .then((res) => {
          if (res.code == 0) {
            this.rolesData = res.data;
          }
        })
        .catch((res) => {
          console.log(res.messafe);
        });
      getProcessById(this.formData.id).then((res) => {
        if (res.data.processManagementRoleList.length) {
          res.data.processManagementRoleList.forEach((element) => {
            this.defaultCheckedKeys.push(element.roleId);
          });
        }
      });
    },
    // 模板更改，节点更改
    getNodeList(val) {
      this.nodeList = [];
      getDictData(val).then((res) => {
        this.nodeList = res.data;
      });
    },
    getDict() {
      //发布单位/响应单位,整改单位,等级
      const demo = ["riskProcessModule", "riskControlLevel"];
      demo.forEach((item) => {
        this.getDictList(item);
      });
      // getRoleApi({ unitType: this.pageData.responseUnitType })
      //   .then((res) => {
      //     if (res.code == 0) {
      //       console.log(res);
      //       this.rolesData = res.data;
      //     }
      //   })
      //   .catch((res) => {
      //     console.log(res.messafe);
      //   });
    },
    // 字典  unitType 单位类型
    getDictList(val) {
      getDictData(val).then((res) => {
        if (res.code == 0) {
          switch (val) {
            case "riskProcessModule":
              this.modularCodeList = res.data;
              break;
            case "riskControlLevel":
              this.gradeList = res.data;
              break;
          }
        }
      });
    },
    submit() {
      this.$refs.formData.validate((valid) => {
        console.log(this.submitType);
        if (valid) {
          this.loading = true;
          let copyForm = Object.assign({}, this.formData);
          copyForm.processManagementRoleList = this.rolesData;
          copyForm.modularName = this.modularCodeList.find((i) => {
            return i.code == copyForm.modularCode;
          }).label;
          copyForm.processNodeName = this.nodeList.find((i) => {
            return i.code == copyForm.processNodeCode;
          }).label;
          copyForm.circulationNodeName = this.nodeList.find((i) => {
            return i.code == copyForm.circulationNodeCode;
          }).label;
          if (copyForm.rejectNodeCode) {
            copyForm.rejectNodeName = this.nodeList.find((i) => {
              return i.code == copyForm.rejectNodeCode;
            }).label;
          }
          copyForm.menuId = this.menuId[this.menuId.length - 1];
          if (this.submitType == "add") {
            console.log(1);
            if (copyForm.id) {
              copyForm.id = "";
            }

            console.log(copyForm);
            processAdd(copyForm)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.loading = true;
                  this.cancel();
                  this.$emit("CloseData");
                } else {
                  this.$message.error(res.message);
                }
              })
              .catch((res) => {
                this.$message.error(res.message);
              });
          } else if (this.submitType == "edit") {
            console.log(2);
            processEdit(copyForm)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.loading = true;
                  this.cancel();
                  this.$emit("CloseData");
                } else {
                  this.$message.error(res.message);
                }
              })
              .catch((res) => {
                // this.cancel();
                this.$message.error(res.message);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancel() {
      this.reset();
      this.defaultCheckedKeys = [];
      this.$emit("CloseData");
    },
    // 分配
    allocate() {
      if (this.onOff) {
        this.dialogVisible = true;
      } else {
        this.$message.error("正在查询，请稍后再点击");
      }
      // console.log(this.menuId);
      // this.dialogVisibleMenu = true;
      // if (this.menuId.length) {
      //   let res = this.getParentId(
      //     this.treeDataMenu,
      //     this.menuId[this.menuId.length - 1]
      //   );
      //   console.log(res);
      //   this.menuId = [];
      //   res.forEach((i) => {
      //     this.menuId.unshift(i.id);
      //   });
      // }
    },
    allotSubmit() {
      this.rolesData = [];
      const selectNodeData = this.$refs.treeRef.getCheckedNodes(true);
      console.log("数据", selectNodeData);
      if (selectNodeData.length) {
        for (let item of selectNodeData) {
          if (item.unitType) {
            this.rolesData.push({
              roleId: item.id,
              unitType: item.unitType,
            });
          }
        }
      }
      this.dialogVisible = false;
      // const selectNodeData = this.$refs.treeRef.getCheckedNodes(true);
      // if (selectNodeData.length) {
      //   let obj = {};
      //   let arr = [];
      //   for (let item of selectNodeData) {
      //     arr.push(item.id);
      //   }
      //   obj.resourceId = this.menuId[this.menuId.length - 1];
      //   obj.roleIds = arr;
      //   obj.permissionLevel = this.permissionLevel;
      //   addMore(obj);

      //   let delRoleIds = [];
      //   this.oldRoleId.forEach((i) => {
      //     if (!arr.includes(i)) {
      //       delRoleIds.push(i);
      //     }
      //   });
      //   if (delRoleIds.length > 0) {
      //     let delObj = JSON.parse(JSON.stringify(obj));
      //     delObj.roleIds = delRoleIds;
      //     delMore(delObj);
      //   }
      // }
      // this.dialogVisible = false;
      // this.dialogVisibleMenu = false;
    },
    menuNext() {
      if (this.menuId) {
        this.dialogVisible = true;
        getRoleIds({
          roleId: "-",
          resourceId: this.menuId[this.menuId.length - 1],
        }).then((res) => {
          console.log(res);
          if (res.data.length) {
            this.permissionLevel = res.data[0].permissionLevel;
            let arr = [];
            res.data.forEach((element) => {
              arr.push(element.roleId);
            });
            this.defaultCheckedKeys = arr;
            this.oldRoleId = arr;
          }
        });
      } else {
        this.$message.error("请选择菜单");
      }
    },
    getUnitData() {
      this.treeLoading = true;
      getUnitRoles().then((res) => {
        this.onOff = true;
        if (res.code == 0) {
          let unitRoles = [];
          unitRoles = res.data;
          unitRoles.forEach((element) => {
            element.dict.roleName = element.dict.intro;
            element.dict.children = element.roles;
            delete element.roles;
            this.treeData.push(element.dict);
          });
          this.treeLoading = false;
        }
      });
      const params = {
        name: "-",
        parentId: "-",
        resourcePath: "-",
        type: "-",
      };
      this.menuLoading = true;
      queryResourceList(params).then((res) => {
        if (res.code == 0) {
          this.resourceData = JSON.parse(JSON.stringify(res.data));
          let treeList = res.data;
          let handleResource = (resource) => {
            let parent = treeList.find((p) => p.id == resource.parentId);
            if (parent) {
              if (resource.parentId !== 0 && parent.children) {
                parent.children.push(resource);
              } else {
                parent.children = [resource];
              }
            }
          };
          treeList.forEach((item) => handleResource(item));
          this.treeDataMenu = treeList.filter((item) => item.parentId === 0);
          this.menuLoading = false;
        } else {
          this.menuLoading = false;
          this.$message.error(res.message);
        }
      });
    },
    // 分配权限关闭
    handleClose() {
      this.dialogVisible = false;
      this.rulesList = [];
    },
    // 分配权限关闭
    handleCloseMenu() {
      this.dialogVisibleMenu = false;
    },
    chooseCancel() {
      this.handleClose();
    },
    chooseCancelMenu() {
      this.dialogVisibleMenu = false;
    },
    reset() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = "";
      });
    },
    getParentId(list, id) {
      for (let i in list) {
        if (list[i].id == id) {
          return [list[i]];
        }
        if (list[i].children) {
          let node = this.getParentId(list[i].children, id);
          if (node !== undefined) {
            return node.concat(list[i]);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
::v-deep .el-dialog__body {
  max-height: 500px;
  overflow: auto;
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #e6e7ec;
  text-align: center;
}

::v-deep .el-dialog__title {
  font-weight: bold;
}
</style>
