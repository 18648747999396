<template>
  <div class="material-container h-full">
    <div class="mt-8">
      <el-steps :active="stepSx" finish-status="success" align-center>
        <el-step :status="stepSx == 1 ?  rowData.processStatus == 0 ? 'await' : rowData.processStatus == 1 ? 'await' : 'success' : 'success'" title="施工单位申请"></el-step>
        <el-step :status="stepSx == 2 ? rowData.processStatus == 3 ? 'success' : 'error' : stepSx > 2 ? 'success' : 'wait'" title="监理单位审核"></el-step>
        <el-step
          :status="stepSx == 3 ? rowData.processStatus == 5 ? 'success' : 'error' : stepSx > 3 ? 'success' : 'wait'"
          v-if="
            rowData.riskLevelCode == 'const_manage_dept_level' ||
              rowData.riskLevelCode == 'headquarters_level' ||
              rowData.riskLevelCode == 'group_level'
          "
          title="建管部审核"
        ></el-step>
        <el-step
          :status="stepSx == 4 ? rowData.processStatus == 9 ? 'success' : 'error' : stepSx > 3 ? 'success' : 'wait'"
          title="建设公司审核"
          v-if="
            rowData.riskLevelCode == 'headquarters_level' ||
              rowData.riskLevelCode == 'group_level'
          "
        ></el-step>
      </el-steps>
    </div>
    <div class="mt-8" v-if="active == 1">
      <h3 style="margin-bottom: 20px;font-size:16px">
        风险名称：{{ riskName }}
      </h3>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="100px"
        class="demo-model"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="审核人" prop="examinePerson">
              <el-input
                readonly
                v-model="ruleForm.examinePerson"
                placeholder="审核人"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="审核描述" prop="examineDescribe">
              <el-input
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 4 }"
                resize="none"
                v-model="ruleForm.examineDescribe"
                placeholder="审核描述"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="补充附件" prop="fileList">
              <FileUploader
                v-if="type != 'view'"
                @uploadChange="handleFileUpload"
                @delChange="handleFileDel"
                :value="fileList"
              ></FileUploader>
              <ul v-if="type == 'view'" class="fileGroupBox">
                <li v-for="(item, i) in ruleForm.fileList" :key="i">
                  {{ item.fileName }}
                  <span @click="handleFileClick(item)">预览</span>
                  <span @click="handleExport(item.fileUrl, item.fileName)"
                    >导出</span
                  >
                </li>
              </ul>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="isShowPerson">
            <el-form-item
              label="包保责任人"
              prop="dutyPerson"
              :rules="{
                required: isShowdutyPerson,
                message: '请选择包保责任人',
                trigger: 'blur'
              }"
            >
              <label slot="label" class="form-item-class">
                <el-button type="primary" size="small" @click="selectRole"
                  >选择</el-button
                >
                <span>包保责任人</span>
              </label>
              <el-input
                v-model="ruleForm.dutyPerson1"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                resize="none"
                readonly
                placeholder="包保责任人"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialogFooter">
        <el-button size="medium" type="danger" @click="submit('0')"
          >驳回</el-button
        >
        <el-button size="medium" type="primary" @click="submit('1')"
          >通过</el-button
        >
      </div>
    </div>
    <div class="mt-8" v-if="active == 2" v-loading="loading">
      <h3 style="margin-bottom: 20px;font-size:16px">
        风险名称：{{ riskName }}
      </h3>
      <el-timeline style="margin: 10px 0 0 10px" v-if="recordList.length > 0">
        <!-- :class="item.examineStatus == 0 ? 'danger' : 'primary'" -->
        <!-- :type="item.examineStatus == 0 ? 'danger' : 'primary'" -->
        <el-timeline-item
          type="primary"
          :size="'large'"
          v-for="(item, i) in recordList"
          :key="i"
        >
          <!-- :icon="
            item.examineStatus == 0
              ? 'el-icon-circle-close'
              : 'el-icon-circle-check'
          " -->
          <div>
            <i style="font-style: normal; margin-right: 5px"></i
            ><span
              >{{ item.created }}
              {{ item.processStatus == 2 ? "施工单位" : item.companyTypeName }}
              {{ "-" + item.deptName + "-" }}
              {{
                item.processStatus == 2 ? item.submitPerson : item.examinePerson
              }}
              {{ "（" + item.realName + "）" }}-
              {{
                item.examineStatus == 0
                  ? "已驳回"
                  : item.examineStatus == 1
                  ? "已通过"
                  : ""
              }}{{ item.processStatus == 2 ? "已提交申请" : "（" + item.examineDescribe + "）" }}{{ item.dutyPerson ? '选定' + item.dutyPerson + '为风险包保责任人' : '' }}
              </span
            >
            <span
              v-if="item.fileList.length > 0"
              @click="hanlderViewList(item)"
              class="fileDialogBox"
              >附件列表
            </span>
          </div>
        </el-timeline-item>
      </el-timeline>
      <el-empty
        v-if="recordList.length == 0"
        description="暂无审核数据"
      ></el-empty>
    </div>
    <el-drawer
      :visible.sync="drawerVisible"
      direction="rtl"
      append-to-body
      size="30%"
      v-if="drawerVisible"
      class="task-drawer"
    >
      <div class="tree-wrapper overflow-y-scroll h-full mr-4">
        <el-tree
          v-loading="treeLoading"
          show-checkbox
          :data="treeData"
          node-key="id"
          :props="defaultProps"
          ref="treeForm"
          accordion
          highlight-current
          @check="handleChange"
          :default-checked-keys="defaultCheckedKeys"
          element-loading-text="拼命加载中"
        >
          <span class="custom-tree-node" slot-scope="{ node }">
            <span>{{ node.label }}</span>
            <span style="margin-left: 30px"></span>
          </span>
        </el-tree>
        <div class="submit-button">
          <el-button type="primary" @click="allotSubmit">确定</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  riskstartAduit,
  riskstartAduitList /*, confirmOperation*/
} from "@/api/riskManage";
import FileUploader from "@/components/Uploader/FileUploader.vue";
// 获取权限单位
import { getUnitRoles } from "@/api/hdanger";
export default {
  components: {
    FileUploader
  },
  props: {
    active: {
      type: Number
    },
    stepSx: {
      type: Number,
      default: 1
    },
    // 当前弹窗类型。用来区分编辑和查看
    type: {
      type: String
    },
    // 当前弹窗类型。用来区分编辑和查看
    auditVisible: {
      type: Boolean,
      default: false
    },
    // // 风险控制等级code
    // controlLevel: {
    //   type: String
    // },
    // // 风险名称
    // riskName: {
    //   type: String
    // },
    // 当前行数据
    rowData: {
      type: Object
    }
  },
  data() {
    return {
      fileDialog: false,
      ruleForm: {
        companyTypeName: "",
        companyTypeCode: "",
        companyUuid: "",
        departmentUuid: "",
        examinePerson: "",
        examinePersonUuid: "",
        riskDeafUuid: "",
        riskMasterUuid: "",
        riskName: "",
        examineDescribe: "",
        dutyPerson: "",
        dutyPerson1: "",
        fileList: [],
        recordType: "",
        examineStatus: ""
      },
      labelPosition: "left",
      filter: {
        pageNum: 1,
        pageSize: 10,
        keyNodesType: 0,
        nodeUuid: ""
      },
      recordList: [],
      fileList: [],
      companyCopy: [],
      rules: {
        examineDescribe: [
          { required: true, message: "请输入描述", trigger: "blur" }
        ]
        // examineDescribe: [
        //   { required: true, message: "请输入描述", trigger: "blur" }
        // ]
      },
      riskName: "",
      departmentName: "",
      drawerVisible: false,
      treeData: [],
      defaultProps: {
        label: "roleName",
        children: "children"
      },
      defaultCheckedKeys: [],
      currentInfo: {
        id: "",
        name: "",
        pNam: ""
      },
      isShowdutyPerson: false,
      isShowPerson: false,
      loading: false
    };
  },
  mounted() {
    this.riskName = this.rowData.riskName;
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      this.ruleForm.companyTypeName = user.companyCategoryName;
      this.ruleForm.companyTypeCode = user.companyCategory;
      this.ruleForm.companyUuid = user.companyId;
      this.ruleForm.departmentUuid = user.departmentId;
      this.ruleForm.examinePerson = user.realName;
      this.ruleForm.examinePersonUuid = user.uuid;
      // this.ruleForm.companyName = user.companyName;
      this.departmentName = user.departmentName;
    }
    // if (
    //   this.rowData.riskLevelCode == "headquarters_level" ||
    //   this.rowData.riskLevelCode == "group_level" ||
    //   this.rowData.riskLevelCode == "const_manage_dept_level"
    // ) {
    //   // ddd
    // }
    if (
      this.rowData.riskLevelCode == "project_depart_level" &&
      this.rowData.processStatus == 2
    ) {
      this.isShowPerson = true;
    }
    if (
      this.rowData.riskLevelCode == "const_manage_dept_level" &&
      this.rowData.processStatus == 3
    ) {
      this.isShowPerson = true;
    }
    if (
      this.rowData.riskLevelCode == "group_level" &&
      this.rowData.processStatus == 5
    ) {
      this.isShowPerson = true;
    }
    if (
      this.rowData.riskLevelCode == "headquarters_level" &&
      this.rowData.processStatus == 5
    ) {
      this.isShowPerson = true;
    }
    if (this.active == 2) {
      // console.log(this.nodeUuid);
      this.getRecord();
    }
  },
  methods: {
    // 审核状态详情
    getRecord() {
      this.loading = true;
      const that = this;
      riskstartAduitList({
        riskDeafUuid: this.rowData.riskDeafUuid,
        riskMasterUuid: this.rowData.riskMasterUuid
      }).then(res => {
        console.log(res, "res");
        if (res.code == 0) {
          let list = [];
          res.data.forEach(el => {
            if (el.processStatus == 2) {
              this.recordList.push({
                processStatus: el.processStatus,
                ...el.riskStartSubmit,
                created: el.riskStartSubmit.fileList[0].created
              });
            } else {
              list = el.examineList;
              // this.recordList.push({
              //   processStatus: el.processStatus,
              //   ...el.examineList
              // })
            }
          });
          console.log(list);
          this.recordList.push(...list);
          this.recordList.sort(function(a, b) {
            return b.created < a.created ? -1 : 1;
          });
          this.loading = false;
        } else {
          // that.getRecord();
          that.$message.error("数据查询失败,请稍后重试");
          that.loading = false;
        }
      });
    },
    handleFileUpload({ name, url, realUrl }) {
      this.fileList.push({ name: name, url: url });
      this.ruleForm.fileList.push({
        fileName: name,
        fileUrl: url + "," + realUrl
      });
    },
    handleFileDel({ name, url }) {
      console.log(name);
      let index = this.fileList.findIndex(v => v.url === url);
      this.fileList.splice(index, 1);
      this.ruleForm.fileList.splice(index, 1);
    },
    handleClick(val) {
      console.log(val);
    },
    CloseData() {
      this.$emit("CloseData");
    },
    hanlderViewList(obj) {
      console.log(obj, "www");
      this.$emit("showFileList", obj);
      // this.fileDialog = true;
    },
    handleExport(url, name) {
      // 文件下载
      var x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function() {
        var url = window.URL.createObjectURL(x.response);
        var a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      };
      x.send();
    },
    handlefileClose() {
      this.fileDialog = false;
    },
    selectRole() {
      this.drawerVisible = true;
      this.treeLoading = true;
      this.treeData = [];
      getUnitRoles().then(res => {
        if (res.code == 0) {
          let unitRoles = [];
          unitRoles = res.data;
          unitRoles.forEach(element => {
            console.log(element);
            element.dict.roleName = element.dict.intro;
            element.dict.children = element.roles;
            if (element.dict.children != null) {
              element.dict.children.forEach(v => {
                v["pId"] = element.dict.id,
                v["PName"] = element.dict.roleName;
              });
            }
            delete element.roles;
            this.treeData.push(element.dict);
          });
          this.treeLoading = false;
          this.lock = true;
        }
      });
    },
    handleClose() {
      this.drawerVisible = false;
    },
    handleChange(checkedNodes, checkedKeys) {
      if (checkedKeys.checkedKeys.length > 1) {
        this.$refs.treeForm.setCheckedKeys([checkedNodes.id]);
      }
      console.log(checkedNodes);
      this.currentInfo.id = checkedNodes.id;
      this.currentInfo.name = checkedNodes.PName + "-" + checkedNodes.roleName;
    },
    allotSubmit() {
      // ss
      this.ruleForm.dutyPerson = this.currentInfo.name;
      this.ruleForm.dutyPerson1 = this.currentInfo.name;
      this.ruleForm.dutyPersonUuid = this.currentInfo.id;
      this.drawerVisible = false;
    },
    reset() {
      const th = this;
      Object.keys(this.ruleForm).forEach(function(key) {
        th.ruleForm[key] = "";
      });
      this.$refs.ruleForm.resetFields();
    },
    submit(type) {
      console.log(this.rowData, "rowData");
      // return
      const _this = this;
      this.ruleForm.examineStatus = type;
      this.ruleForm.recordType = type;
      this.ruleForm.riskDeafUuid = _this.rowData.riskDeafUuid;
      this.ruleForm.riskMasterUuid = _this.rowData.riskMasterUuid;
      this.ruleForm.riskName = _this.rowData.riskName;
      var status = null;
      if (type == 0) {
        //驳回
        if (_this.ruleForm.companyTypeName == "监理单位") {
          status = 4;
        }
        if (_this.departmentName.includes("建设管理部")) {
          status = 6;
        }
        if (_this.departmentName.includes("建设公司")) {
          status = 8;
        }
      } else if (type == 1) {
        //通过
        if (_this.ruleForm.companyTypeName == "监理单位") {
          if (_this.rowData.riskLevelCode == "project_depart_level") {
            status = 9;
            _this.isShowdutyPerson = true;
          } else {
            status = 3;
          }
        }
        if (_this.departmentName.includes("建设管理部")) {
          if (_this.rowData.riskLevelCode == "const_manage_dept_level") {
            status = 9;
            _this.isShowdutyPerson = true;
          } else {
            status = 5;
          }
        }
        if (_this.departmentName.includes("建设公司")) {
          if (
            _this.rowData.riskLevelCode == "headquarters_level" ||
            _this.rowData.riskLevelCode == "group_level"
          ) {
            status = 9;
            _this.isShowdutyPerson = true;
          } else {
            status = 7;
          }
        }
      }
      setTimeout(() => {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            let data = {
              riskStartExamine: _this.ruleForm,
              status: {
                processStatus: status,
                riskDeafUuid: _this.rowData.riskDeafUuid,
                riskMasterUuid: _this.rowData.riskMasterUuid,
                riskName: _this.rowData.riskName
              }
            };
            riskstartAduit(data).then(res => {
              if (res.code == 0) {
                _this.$message.success("操作成功");
                _this.reset();
                _this.$emit("submitClose");
              } else {
                _this.$message.error(res.message);
              }
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }, 50);
    }
  }
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .el-button {
    margin-right: 20px;
  }
}
// ::v-deep .el-dialog__body {
//   height: 460px;
// }
::v-deep .el-image {
  height: 150px;
}
.i {
  position: absolute;
  top: -5px;
  right: -5px;
  color: red;
  font-weight: 600;
}
.url-list {
  position: relative;
  display: inline-block;
}
// .el-dialog__header {
//   padding: 0 !important;
// }
.fileDialogBox {
  color: #3fa5e9;
  margin-left: 10px;
  cursor: pointer;
}
.material-container {
  min-height: 400px;
}
.form-item-class {
  display: flex;
  flex-direction: column;
  height: 50px;
}
::v-deep .el-tree {
  padding-top: 15px;
  padding-left: 10px;
  // 不可全选样式
  .el-tree-node {
    .is-leaf + .el-checkbox .el-checkbox__inner {
      display: inline-block;
    }
    .el-checkbox .el-checkbox__inner {
      display: none;
    }
  }
}
.submit-button {
  text-align: center;
  margin-top: 20px;
}
::v-deep .el-form-item .el-form-item__label {
  display: flex;
  justify-content: flex-end;
}
::v-deep .el-step__title.is-success {
  color: #409eff;
}
::v-deep .el-step__head.is-success {
  color: #409eff;
  border-color: #409eff;
}
// ::v-deep .el-timeline-item__node--primary {
//   background: #FFF !important;
//   .el-timeline-item__icon {
//     color: #409EFF;
//     font-size: 22px;
//     z-index: 2;
//   }
// }
// ::v-deep .el-timeline-item__node--danger {
//   background: #FFF !important;
//   .el-timeline-item__icon {
//     color: #F56C6C;
//     font-size: 22px;
//     z-index: 2;
//   }
// }
// ::v-deep .primary{
//   .el-timeline-item__tail {
//     border-left: 2px solid #409EFF;
//     height: 96%;
//   }
// }
// ::v-deep .danger {
//   .el-timeline-item__tail {
//     border-left: 2px solid #F56C6C;
//     height: 96%;
//   }
// }
</style>
