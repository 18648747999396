<template>
  <div class="dataRecord">
    <el-row class="search-time">
      <el-date-picker v-model="dataTime" type="datetime" size="small" placeholder="选择日期时间"></el-date-picker>
    </el-row>
    <!-- ss -->
    <el-table
      size="mini"
      :data="list"
      element-loading-text="拼命加载中"
      element-loading-background="rgba(255, 255, 255, 0.6)"
      height="250px"
      border
      class="helpTable"
    >
      <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
      <el-table-column
        v-for="item in headerList"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        :min-width="item.minWidth"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">{{ scope.row[item.prop] }}</template>
      </el-table-column>
    </el-table>
    <div class="flex justify-center mt-4">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryParams.pageNum"
        :page-size="queryParams.pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <div class="tj-box">
      <div class="tj-title">
        <span
          :class="typeIndex == i ? 'selectType' : ''"
          v-for="(v, i) in typeList"
          :key="i"
          @click="getTypeMenu(i)"
          >{{ v }}</span
        >
      </div>
    </div>
    <div class="tj-line">
      <chatLine :options="options"></chatLine>
    </div>
    <div class="fixed-header">
      <li
        :class="i == warnMenuIndex ? 'selectWarnMenu' : ''"
        v-for="(item, i) in warnMenuList"
        :key="i"
        @click="getWarnMenu(i)"
      >
        {{ item }}
      </li>
    </div>
  </div>
</template>

<script>
import chatLine from "./chatLine";
export default {
  components: {
    chatLine
  },
  data() {
    return {
      queryParams: {
        pageSize: 5,
        pageNum: 1
      },
      total: 20,
      dataTime: "",
      // dd
      headerList: [
        { prop: "deviceId", label: "起吊风速", minWidth: 80 },
        { prop: "userName", label: "起吊载重", minWidth: 80 },
        { prop: "mobile", label: "起吊高度", minWidth: 80 },
        { prop: "1", label: "落吊风速", minWidth: 80 },
        { prop: "2", label: "落吊载重", minWidth: 80 },
        { prop: "tim3e", label: "落吊高度", minWidth: 80 },
        { prop: "ti3me", label: "主钩实重", minWidth: 80 },
        { prop: "tim4e", label: "副钩实重", minWidth: 80 },
        { prop: "ti4me", label: "主钩高度", minWidth: 80 },
        { prop: "ti5me", label: "起吊时间", minWidth: 130 },
        { prop: "ti54me", label: "落吊时间", minWidth: 130 },
        { prop: "t5ime", label: "预警内容", minWidth: 90 },
        { prop: "ttime", label: "预警值", minWidth: 80 }
      ],
      list: [],
      typeList: ["近12小时预警趋势", "近一周预警趋势"],
      typeIndex: 0,
      options: {
        yAxisData: [
          31.5,
          31.9,
          32.6,
          32.9,
          32.9,
          32.9,
          33.2,
          33.1,
          33,
          32.8,
          32.5,
          32.4,
          32.9,
          34,
          34,
          34.2,
          34.8,
          35.1,
          35.4,
          35.4,
          35.7,
          36.5,
          37,
          36.8,
          36.8,
          36.2,
          35.7,
          35.8,
          36.2,
          36.6,
          37.5,
          38.3,
          38.1,
          37.6,
          36.4
        ],
        name: "气温",
        xAxisData: [
          "09:40",
          "09:50",
          "10:00",
          "10:10",
          "10:20",
          "10:30",
          "10:40",
          "10:50",
          "11:00",
          "11:10",
          "11:20",
          "11:30",
          "11:40",
          "11:50",
          "12:00",
          "12:10",
          "12:20",
          "12:30",
          "12:40",
          "12:50",
          "13:00",
          "13:10",
          "13:20",
          "13:30",
          "13:40",
          "13:50",
          "14:00",
          "14:10",
          "14:20",
          "14:30",
          "14:40",
          "14:50",
          "15:00",
          "15:10",
          "15:20"
        ]
      },
      warnMenuList: [
        "主钩超载预警",
        "副钩超载预警",
        "大车左限位预警",
        "大车右限位预警",
        "大车高度预警",
        "小车高度预警"
      ],
      warnMenuIndex: 0
    };
  },
  methods: {
    getTypeMenu(i) {
      this.typeIndex = i;
    },
    getWarnMenu(i) {
      this.warnMenuIndex = i;
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
    },
    handleCurrentChange(val) {
      this.queryParams.pageNum = val;
    }
  }
};
</script>

<style lang="scss" scoped>
// a
.search-time {
  text-align: right;
  margin: 0 0 20px;
}
.tj-box {
  .tj-title {
    span {
      cursor: pointer;
    }
    span:last-child {
      margin-left: 30px;
    }
  }
  .selectType {
    color: #66b1ff;
  }
}
.tj-line {
  height: 220px;
  width: 100%;
}
.fixed-header {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  li {
    list-style: none;
    padding: 5px 8px;
    margin: 10px;
    color: #fff;
    background: #a7afb2;
    border-radius: 2px;
    cursor: pointer;
  }
  .selectWarnMenu {
    background: #66b1ff;
  }
}
</style>
