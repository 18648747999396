<template>
  <el-dialog
    width="35%"
    title="详情"
    :visible.sync="pushModal"
    class="editDialog"
    append-to-body
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-model"
    >
      <el-form-item label="所属建管部" prop="supervisionName">
        <el-input
          v-model="ruleForm.supervisionName"
          readonly
          placeholder="所属建管部"
        ></el-input>
      </el-form-item>
      <el-form-item label="线路名称" prop="lineName">
        <el-input
          v-model="ruleForm.lineName"
          readonly
          placeholder="线路名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="分部名称" prop="branchName">
        <el-input
          v-model="ruleForm.branchName"
          readonly
          placeholder="分部名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="工点名称" prop="siteName">
        <el-input
          v-model="ruleForm.siteName"
          readonly
          placeholder="工点名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="预案名称" prop="planName">
        <el-input
          v-model="ruleForm.planName"
          readonly
          placeholder="预案名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="预案附件" prop="label">
        <el-button
          type="text"
          class="file-item"
          @click="openFile(ruleForm.planFile)"
        >
          {{ ruleForm.fileName ? ruleForm.fileName : "" }}
        </el-button>
      </el-form-item>
      <el-form-item label="预案类型" prop="planType">
        <el-input
          v-model="ruleForm.planType"
          readonly
          placeholder="预案类型"
        ></el-input>
      </el-form-item>
      <el-form-item label="上传人" prop="uploaderName">
        <el-input
          v-model="ruleForm.uploaderName"
          readonly
          placeholder="上传人"
        ></el-input>
      </el-form-item>
      <el-form-item label="上传时间" prop="uploadTime">
        <el-input
          v-model="ruleForm.uploadTime"
          readonly
          placeholder="上传时间"
        ></el-input>
      </el-form-item>
      <el-form-item label="到期时间" prop="expireTime">
        <el-input
          v-model="ruleForm.expireTime"
          readonly
          placeholder="到期时间"
        ></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="pushModal = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { expirePage } from "@/api/emergency";

export default {
  data() {
    return {
      pushModal: false,
      ruleForm: {},
      rules: {},
    };
  },
  methods: {
    openFile(url) {
      window.open(url);
    },
    handleDetail(data) {
      console.log(data);
      let str = data.noticeDescription;
      let planName = str.slice(1, str.lastIndexOf("】"));
      console.log(str, planName, str.lastIndexOf("】"));
      this.pushModal = true;
      const params = {
        planName: planName ? planName : null,
        pageNum: 1,
        pageSize: 10000,
      };
      expirePage(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.ruleForm = res.data.records[0];
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style></style>
