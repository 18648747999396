<template>
  <div class="home">
    <div class="content pl-2 pr-2">
      <div class="content-wrapper">
        <LeftIndex
          class="left-panel"
          ref="LeftIndex"
          :siteInfo="siteInfo"
        ></LeftIndex>
        <MapIndex
          v-if="reproduction"
          class="center-panel"
          ref="MapIndex"
        ></MapIndex>
        <BIM v-else ref="BIM"></BIM>
        <RightIndex
          class="right-panel"
          ref="RightIndex"
          :siteInfo="siteInfo"
        ></RightIndex>
        <div class="back-btn" @click="handleBackBtnClick()">
          <span>返回GIS地图</span>
        </div>
        <div class="ump_button">
          <div :class="reproduction?'teo':'teos'" @click="baiduClick()">
            <img src="@/assets/zhanshi.png" alt="" />
          </div>
          <!-- <div class="teos">
            <img src="@/assets/erwei.png" alt="" />
          </div> -->
          <div :class="reproduction?'teos':'teo'" @click="reproductionClick()">
            <img src="@/assets/bm.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MapIndex from "@/views/ThreeProofings/WorkPointPage/Map/index";
import LeftIndex from "@/views/ThreeProofings/WorkPointPage/leftPage/index";
import RightIndex from "@/views/ThreeProofings/WorkPointPage/rightPage/index";
import BIM from "./BIM/index.vue";
export default {
  name: "WorkPoint",
  components: { MapIndex, LeftIndex, RightIndex, BIM },
  data() {
    return {
      path: "",
      socket: "",
      siteInfo: {},
      reproduction: true,
    };
  },
  async created() {},
  mounted() {},
  computed: {},
  methods: {
    init() {
      let that = this;
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket

        that.socket = new WebSocket(that.path);
        that.$global.setWs(that.socket);
        // 监听socket连接
        that.socket.onopen = that.open;
        // 监听socket错误信息
        that.socket.onerror = that.error;
        // 监听socket消息
        that.socket.onmessage = that.getMessage;
        // 关闭连接
        that.socket.onclose = that.close;
      }
      that.getMessage();
    },
    open() {
      console.log("socket连接成功");
    },
    error() {
      console.log("连接错误");
    },

    close() {
      console.log("socket已经关闭");
    },
    getMessage() {
      let that = this;
      that.$global.ws.onmessage = function (res) {
        that.$refs.MapIndex.getwsData(res);
        that.$refs.LeftIndex.fluData(res);
        that.$refs.RightIndex.fluData(res);
      };
    },
    handleBackBtnClick() {
      this.$router.back();
    },
    baiduClick() {
      // this.$refs.BIM.removeModel();
      // var num1 = setInterval(() => {
      //   this.reproduction = true;
      //   clearInterval(num1);
      // }, 1000);
      this.reproduction = true;
    },
    reproductionClick() {
      this.reproduction = false;
    },
  },
  destroyed() {
    // 销毁ws
    // this.socket.close();
  },
};
</script>
<style lang="scss" scoped>
.home {
  ::v-deep {
    // 首页布局
    .content {
      width: 100%;
      height: calc(100vh - 88px - 0.25rem);
      .el-dialog {
        background: url("../../../assets/card-bg1.png") repeat 99%;
        background-size: 100% 100%;
        color: #fff;
      }
      .el-dialog,
      .el-pager li {
        border-radius: 10px;
        color: #fff;
      }
      .el-dialog__header {
        text-align: center;
      }
      .el-dialog__wrapper {
        background: rgba(0, 0, 0, 0.8);
      }
      .el-dialog__title {
        color: #fff;
      }
      .el-form-item__label {
        color: #fff;
      }
      .el-input__inner {
        background-color: rgba(2, 2, 6, 1);
        color: #fff;
        border-radius: 4px;
        border: 1px solid #4d4d4d;
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #f9aa33;
      }
      .content-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
      }
    }
  }
  .left-panel {
    width: 20.8%;
    height: 100%;
    margin-right: 0.5rem;
  }
  .right-panel {
    width: 20.8%;
    height: 100%;
    margin-left: 0.5rem;
  }
  .center-panel {
    width: calc(58.4% - 1rem);
    height: 100%;
  }
  .back-btn {
    width: 100px;
    height: 32px;
    background: #5aa3e3;
    border-radius: 4px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    position: absolute;
    left: 22%;
    top: 1%;
    span {
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.ump_button {
  width: 32px;
  height: 67px;
  background: #3a6892;
  position: absolute;
  right: 24%;
  top: 12%;
  z-index: 9;
  border-radius: 5px;
  .teo {
    width: 32px;
    height: 32px;
    background: #5aa3e3;
    border-radius: 5px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
      margin: auto;
    }
  }
  .teos {
    width: 32px;
    height: 32px;
    background: #3a6892;
    border-radius: 5px;
    position: relative;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 6px;
      left: 6px;
    }
  }
}
</style>
