<template>
  <div class="page-container bg-white p-4 h-full relative">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="状态">
            <el-select
              v-model="queryModal.onlineState"
              placeholder="请选择状态"
            >
              <el-option
                v-for="item of dict.onlineState"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input
              v-model="queryModal.realName"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="handleAdd" v-has="'soldier:newly'">新增单兵</el-button>
          <el-button type="primary" @click="handleExport" v-has="'soldier:export'">导出</el-button>
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="realName"
          label="姓名"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="companyName"
          label="单位"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="departmentName"
          label="部门"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="roleName"
          label="岗位"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column align="center" prop="onlineState" label="状态">
          <template slot-scope="scope">{{
            scope.row.onlineState == 0 ? "离线" : "在线"
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="contactPhone"
          label="联系方式"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.enableFlag == 1"
              @click="handleIsDisabled(scope.row, 0)"
              type="text"
              v-has="'soldier:disable'"
              >禁用</el-button
            >

            <el-button
              v-if="scope.row.enableFlag == 0"
              @click="handleIsDisabled(scope.row, 1)"
              v-has="'soldier:enable'"
              type="text"
              >启用</el-button
            >

            <el-button @click="handleDel(scope.row)" type="text"
            v-has="'soldier:delete'"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <SoldierForm
      ref="modalForm"
      :orgTree="orgTree"
      @formSubmit="handleFormSubmit"
    ></SoldierForm>
  </div>
</template>

<script>
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import SoldierForm from "./modules/soldierForm.vue";
import { getIndividualPage, delIndividual, updateIndividual } from "@/api/eres";
import { getLoginUserDeptList } from "@/api/organization";
export default {
  components: {
    SoldierForm,
  },
  data() {
    return {
      queryModal: {
        realName: "",
        enableFlag: "",
      },
      orgTree: [],
      tableData: [],
      loading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      dict: {
        state: [
          {
            label: "禁用",
            value: 0,
          },
          {
            label: "启用",
            value: 1,
          },
        ],
        onlineState: [
          {
            label: "离线",
            value: 0,
          },
          {
            label: "在线",
            value: 1,
          },
        ],
      },
    };
  },
  mounted() {
    this.loadData();
    this.getLoginUserDeptList();
  },
  methods: {
    // 根据当前登录人单位获取组织结构权限树
    async getLoginUserDeptList() {
      let res = await getLoginUserDeptList({});
      if (res.code == 0) {
        this.orgTree = res.data;
      }
    },
    // 顶部查询
    handleSearch() {
      this.loadData();
    },
    // 导入模板下载
    handleTemplateDownLoad() {},
    // 导入
    handleImport() {},
    // 导出
    handleExport() {},
    // 新增
    handleAdd() {
      this.$refs.modalForm.handleAdd();
    },
    // 修改
    handleEdit(record) {
      this.$refs.modalForm.handleAdd(record);
    },
    // 禁用or启用
    handleIsDisabled(record, type) {
      let text = type == 1 ? "启用" : "禁用";
      this.$confirm("确定+" + text + "该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 修改单兵
        const params = JSON.parse(JSON.stringify(record));
        params.enableFlag = type;
        updateIndividual(params).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "成功！",
              type: "success",
            });
            this.loadData();
          } else {
            this.$message.error("失败！");
          }
        });
      });
    },
    // 删除
    handleDel(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delIndividual(record.id).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadData();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    // 表单提交
    handleFormSubmit() {
      this.loadData();
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 加载表格数据
    loadData() {
      this.loading = true;
      const params = {
        onlineState: this.queryModal.onlineState,
        realName: this.queryModal.realName,
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
      };
      getIndividualPage(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.page.pageNo = res.data.pageNum;
          this.page.pageSize = res.data.pageSize;
          this.tableData = res.data.list;
          this.page.total = res.data.total;
          this.page.count = res.data.pages;
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
