<template>
  <div class="digi_uploader">
    <el-upload
      ref="upload"
      :http-request="uploads"
      class="upload-demo"
      action=""
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-change="handleChange"
      :before-upload="beforeAvatarUpload"
      :file-list="fileLists"
      :limit="1"
      :disabled="publicdisabled"
      multiple
      :accept="accept"
    >
      <!--
      :show-file-list="false"    -->
      <el-button size="small" type="primary" icon="el-icon-plus"
        >点击上传</el-button
      >
      <div slot="tip">
        <span v-if="accept == '.pdf,image/*'"
          >只能上传图片/pdf类型的附件，且不超过10Mb
        </span>
        <span v-else>
          只能上传图片类型的附件，且不超过10Mb
        </span>

        <span v-if="fileLists.length > 0" style="color: red"
          >当前存在文件，请删除后在上传</span
        >
      </div>
    </el-upload>
  </div>
</template>

<script>
import { upload } from "@/api/upload"
export default {
  name: "Uploader",
  props: {
    fileArray: {
      type: Array,
      default: () => [],
    },
    accept: {
      type: String,
      default: "image/*",
    },
  },
  data () {
    return {
      fileList: [],
      fileLists: [],
      // fileListsCopy: [],
      publicdisabled: false,
      emitList: [],
    }
  },
  watch: {
    fileArray: function (newValue) {
      this.fileLists = newValue.map(item => {
        return {
          name: item.attrName,
          url: item.link
        }
      })
    }
  },
  created () {
    console.log(this.fileArray)
    this.fileLists = this.fileArray
  },
  methods: {
    uploads (val) {
      console.log(val)
      /*Promise.all([ upload(val.file).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.emitList.push(res.data)
          // this.$emit("uploader", res.data);
        }
      })]).then(data => {
        console.log(this.emitList)
      })*/
      upload(val.file).then((res) => {
        console.log(res)
        if (res.code == 0) {
          this.emitList.push(res.data)
          this.$emit("uploader", res.data)
        }
      })
    },
    handleRemove (file, fileList) {
      this.fileLists = fileList
      this.$emit("uploader", fileList)
    },
    beforeAvatarUpload (file) {
      console.log(file)
      // 截取上传文件的后缀名
      let fileType = file.name.substring(file.name.lastIndexOf(".") + 1)
      // 判断文件名的类型
      // if (fileType == "pdf" || file.type == "image/*" || fileType == "mp4") {
      if (fileType == "pdf" || fileType == "mp4" || fileType == 'jpg' || fileType == 'jpeg' || fileType == 'png' || fileType == 'gif' || fileType == 'webp' || fileType == 'bmp' || fileType == 'tif') {
        console.log("通过")
      } else {
        if (this.accept == ".pdf,image/*") {
          this.$message.error("上传文件仅支持图片/pdf格式")
        } else {
          this.$message.error("上传文件仅支持pdf格式")
        }
        return false
      }
      let isLt10M = file.size / 1024 / 1024 < 10
      if (isLt10M) {
        console.log("大小")
      } else {
        this.$message.error("上传大小不能超过 10MB!")
        return false
      }
    },
    handlePreview (file) {
      console.log(file)
      window.open(file.url)
      /* upload(file.raw).then(res => {
        console.log(res);
        if (res.code == 0) {
          window.open(
            "https://view.xdocin.com/view?src=" +
              encodeURIComponent(res.data.uri)
          );
        }
      });*/
      console.log(1112323)
    },
    handleChange (file, fileList) {
      // console.log(file, fileList);
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1)
      let extension1 = testmsg === "jpg"
      let extension2 = testmsg === "jpeg"
      let extension3 = testmsg === "png"
      let extension4 = testmsg === "doc"
      let extension5 = testmsg === "docx"
      let extension6 = testmsg === "xls"
      let extension7 = testmsg === "xlsx"
      let extension8 = testmsg === "pdf"
      let extension9 = testmsg === "mp4"
      let extension10 = testmsg === "webp"
      let extension11 = testmsg === "gif"
      let extension12 = testmsg === "bmp"
      let extension13 = testmsg === "tif"
      if (
        !extension1 &&
        !extension2 &&
        !extension3 &&
        !extension4 &&
        !extension5 &&
        !extension6 &&
        !extension7 &&
        !extension8 &&
        !extension9 &&
        !extension10
        && !extension11
        && !extension12
        && !extension13
      ) {
        this.$message.error(
          "上传附件只能是doc/docx/xls/xlsx/pdf/jpg/png/jpeg/webp/gif/bmp/tif 格式!"
        )
        let filePash

        if (file.response && file.response.attachId) {
          filePash = file.response.attachId
        } else {
          filePash = file.attachmentId
        }
        let y = this.fileList.findIndx((x) => x.attachmentId === filePash)
        if (y === -1) {
          return
        }
        this.fileList.splice(y, 1)
        this.fileLists = fileList
        return false
      }
      let isLt10M = file.size / 1024 / 1024 < 10
      if (!isLt10M) {
        this.$message.error("上传大小不能超过 10MB!")
        let filePash
        if (file.response && file.response.attachId) {
          filePash = file.response.attachId
        } else {
          filePash = file.attachmentId
        }
        let y = this.fileList.findIndx((x) => x.attachmentId === filePash)
        if (y === -1) {
          return
        }
        this.fileList.splice(y, 1)
        this.fileLists = fileList
        return false
      }
      this.fileLists = fileList
    },
  },
};
</script>

<style></style>
