<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
             <el-cascader
               filterable
              v-model="queryModal.lineSite"
              clearable
              placeholder="选择线路工点"
              :options="options"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="handleLineSiteChange"
            ></el-cascader>
           
          </el-form-item>

          <el-form-item label="">
            <el-select
            
              v-model="queryModal.type"
              placeholder="选择事件类型"
              clearable
              
            >
              <el-option
                :label="data.label"
                :value="data.code"
                v-for="(data, index) in typeList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select
          
              v-model="queryModal.responseLevel"
              placeholder="选择事件级别"
              clearable
              
            >
              <el-option
                :label="data.label"
                :value="data.code"
                v-for="(data, index) in levelList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <!-- 时间框注销之后再改 -->
             <el-date-picker
      v-model="queryModal.times"
      type="datetimerange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd HH:mm:ss">
    </el-date-picker>
              <!-- <el-date-picker
          v-model="queryModal.startTime"
          type="datetime"
          placeholder="选择时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker> -->
            <!-- <el-date-picker
              v-model="queryModal.times"
              type="date"
              placeholder="选择时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker> -->
          </el-form-item>
          <el-form-item label="">
            <el-input
              v-model="queryModal.name"
              placeholder="输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="setsmit">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button
          disabled
            type="primary"
            @click="historical"
           v-has="'history:export'"
            >导出</el-button
          >
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" v-loading="tableLoading" style="width: 100%">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="80"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="事件名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="工点"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="typeName"
          label="事件类型"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="responseLevel"
          label="事件级别"
        ></el-table-column>

        <el-table-column
          align="center"
          prop="created"
          label="发生时间"
        ></el-table-column>
        <!-- <el-table-column align="center" prop="categoryName" label="事故/件类别">
          <template slot-scope="scope">
            <span v-if="scope.row.categoryName">
              {{ scope.row.endTag }}</span
            >
            <span class="text-red-500" v-else>待填写</span>
          </template>
        </el-table-column> -->

        <!-- <el-table-column
          align="center"
          prop="enclosure"
          label="事件附件"
          width="150"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.enclosure"
              @click="handleFileClick(scope.row)"
              >事件报告</el-button
            >
            <span class="text-red-500" v-else>待上传</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          align="center"
          prop="relegationTime"
          label="填报时间"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="publisherName"
          label="填报人"
        ></el-table-column> -->

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              @click="incident(scope.row)"
              v-show="scope.row.endTag == 0"
              v-has="'history:report'"
              >事件终报</el-button
            >
            <el-button type="text" @click="handleEdit(scope.row)" size="mini" v-has="'history:edit'"
              >编辑</el-button
            >
            <el-button type="text" @click="historical(scope.row)" size="mini" v-has="'history:details'"
              >详情</el-button
            >

            <el-button
              @click="handleDelete(scope.row)"
              size="mini"
              type="text"
              v-has="'history:del'"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-count="page.count"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="事件详情"
      :visible.sync="hisDialog"
      width="80%"
      
      :before-close="historicalClose"
      center
      :close-on-click-modal="false"
    >
      <HistoricalPage
      :endtag="endtag"
        @historicalClose="historicalClose($event)"
        :caseList="caseList"
        :detailData="detailData"
        :namert="namert"
      ></HistoricalPage>
    </el-dialog>
    <HistoricalForm
      ref="modelForm"
      @getdata="setsmit"
      :caseList="caseList"
    ></HistoricalForm>
    <el-dialog
      title="事件终报"
      :visible.sync="hisserDialog"
      width="40%"
      :before-close="histlClose"
      center
      :close-on-click-modal="false"
    >
      <Quoted
        @closeter="closeter"
        :statre="statre"
        :uuid="uuid"
        ref="quett"
      ></Quoted>
    </el-dialog>
  </div>
</template>

<script>
import HistoricalPage from "./modules/historicalPage.vue";
import HistoricalForm from "./modules/historicalForm.vue";
import Quoted from "./modules/quotedst.vue";
import { recordList, deleteResponseRecord, detailsList } from "@/api/response";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
export default {
  components: {
    HistoricalPage,
    HistoricalForm,
    Quoted,
  },
  data() {
    return {
      queryModal: {
        lineSite:"",
       times: [], siteIds: [],
        category: "",
        type: "",
        responseLevel: "",
        name: "",
        page: 1,
        size: 10,
        total: 0,
      },
      tableData: [],
      options: [],
      lineSiteData: [],
      page:{
pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      leveloptions: this.levelList,
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      tableLoading: false,
      hisDialog: false,
      hisserDialog: false,
      typeList: [],
      levelList: [],
      caseList: [],
      detailData: {},
      statre: 0,
      uuid: "",
      endtag:0,
      namert:{}
    };
  },
  mounted() {
    queryDictByLabel({ label: "事件类型" }).then((res) => {
      queryDictNodesById({ id: res.data.id }).then((el) => {
        this.typeList = el.data;
      });
    });
    queryDictByLabel({ label: "事故类别" }).then((res) => {
      queryDictNodesById({ id: res.data.id }).then((el) => {
        this.caseList = el.data;
        this.getdata();
      });
    });
    queryDictByLabel({ label: "当前等级" }).then((res) => {
      queryDictNodesById({ id: res.data.id }).then((el) => {
        this.levelList = el.data;
        this.levelList.pop();
      });
    });
    this.getLineTree();
  },
  methods: {
    handleLineSiteChange(val) {
        this.queryModal.siteIds = [];
      for (let item of val) {
        this.queryModal.siteIds.push(item[item.length - 1]);
      }
      console.log(this.queryModal.siteIds)
    },
    async historical(data) {
       this.detailData ={}
      console.log(data)
      this.endtag=data.endTag
      this.tableLoading = true;
      let params = {
        uuid: data.uuid,
      };
      let res = await detailsList(params);
      if (res.code == 0) {
        this.detailData =Object.assign({}, res.data);
          console.log(this.detailData)
        if (this.detailData.category) {
          this.detailData.categoryName = this.caseList.find(
            (el) => el.id == this.detailData.category
          ).label;
        }
          
        this.tableLoading = false;
        this.hisDialog = true;
      }
    },
    historicalClose(msg) {
      this.hisDialog = false;

      if (msg == true) {
        this.handleSearch();
      }
    },
    histlClose() {
      this.$refs.quett.finalterwr();
      this.hisserDialog = false;
    },
    async getLineTree() {
        const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.options = lineSiteData;
      }
      // const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      // if (lineSiteData) {
      //   this.lineSiteData = JSON.parse(JSON.stringify(lineSiteData));
      //   this.options = lineSiteData;
      //   console.log(this.options)
      //   this.options.forEach((e) => {
      //     if (e.children.length != 0) {
      //       e.children.forEach((el) => {
      //         delete el.children;
      //       });
      //     }
      //   });
      // }
    },
    setsmit() {
      this.queryModal.page = 1;
      this.getdata();
    },
    //条数改变
    handleSizeChange(val) {
      this.queryModal.size = val;
      this.queryModal.page = 1;
      this.getdata();
    },
    // 翻页
    handleCurrentChange(val) {
      this.queryModal.page = val;
      this.getdata();
    },
    async getdata() {
     

      // this.tableLoading = true;
      let params = {
        siteId: this.queryModal.siteIds[0]?Number(this.queryModal.siteIds[0].toString()) :"",
        startTime: this.queryModal.times[0] ? this.queryModal.times[0] : "",
        endTime: this.queryModal.times[1] ? this.queryModal.times[1] : "",
        category: this.queryModal.category,
        name: this.queryModal.name,
        type: this.queryModal.type,
        responseLevel: this.queryModal.responseLevel,
        page: this.queryModal.page,
        size: this.queryModal.size,
        endFile: 1,
      };
      // for (const key in params) {
      //   if (params[key] == "") {
      //     delete params[key];
      //   }
      // }
      let res = await recordList(params);
      if (res.code == 0) {
        console.log(res);
        this.tableData = res.data.list;
          this.page.pageNo = res.data.pageNum;
        this.page.pageSize = res.data.pagesize;
        this.page.count = res.data.pages;
        this.page.total = res.data.total;
        this.tableData.forEach((e) => {
          if (e.category) {
            console.log(this.caseList.find((el) => el.id == e.category));
            e.categoryName = this.caseList.find(
              (el) => el.id == e.category
            ).label;
          }
        });
        this.tableLoading = false;
      }
    },
    handleAdd() {
      this.$refs.modelForm.handleAdd();
    },
    //事件终报填写
    incident(val){
      console.log(val)
        detailsList({
        uuid: val.uuid,
        
      }).then((res) => {
        console.log(res)
        if(res.data.responseEndTime==null){
              this.$message({
            message: "响应结束才能,填写终报",
            type: "warning",
          });
        }else{
    this.statre = 1;
        this.uuid=val.uuid
         this.hisserDialog=true
        }
        // console.log(res);
        // let sbarr = [];
        // res.data.processList.forEach((el) => {
        //   sbarr.push(el.responseProcessName);
        // });

        // if (!sbarr.includes("响应结束")) {
        //    this.$message({
        //     message: "响应结束才能,填写终报",
        //     type: "warning",
        //   });
        
        // } else {
       
        // }
      });
        
    },
    closeter() {
       this.getdata();
      this.hisserDialog = false;
     
    },
    handleEdit(record) {
      this.$refs.modelForm.handleAdd(record);
    },
    handleAudit(record) {
      this.$refs.modelFormpage.handleAdd(record);
    },
    handleDelete(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteResponseRecord(record).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.getdata();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    handleLog() {
      this.tableLoading = true;
    },
    handleFileClick(record) {
      const link = document.createElement("a");
      link.href = record.enclosure;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
  
  },
};
</script>

<style lang="scss" scoped>
</style>
