<template>
  <div class="resource">
    <IndexBox
      :title="rescueTitle"
      :titleRight="titleRight"
      @expand-more="expandMore"
    >
      
      <div class="library" v-for="(item, index) in withins" :key="index">
        <div class="library_left">
          <P
            style="
              width: 155px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
            :title="item.teamName"
            >{{ item.teamName }}</P
          >
          <P style=" display: flex;"
            ><span>{{ item.captainName }}</span
            ><span style="margin-left: 10px;width:100px; overflow: hidden;text-overflow:ellipsis;white-space: nowrap;display: block;"  :title="item.captainPhone">{{ item.captainPhone }}</span></P
          >
        </div>
        <div class="library_right" >
          <span    :title="item.distance">{{ item.distance }}km</span
          ><span>
            <el-button
            v-if="item.library=='物资库'||item.library=='专职救援队'"
        
              size="mini"
              @click="deploy(item)"
              type="warning"
              v-has="'Outburst:allocate'"
              >调配</el-button
            ></span
          >
        </div>
      </div>
    </IndexBox>

    <Modal
      class="modalDetail"
      ref="rescueDetail"
      :modal="false"
      :width="'1200px'"
      :title="rescueTitle"
      @closeDialog="closeDialog"
    >
      <Resor
        ref="resores"
        @closeter="closeter"
        :evebty="detsiel"
        :libraryData="libraryData"
      ></Resor>
    </Modal>
    <Modal
      class="modalDetail"
      ref="deployment"
      :modal="false"
      :width="'1200px'"
      :title="deploymentTitle"
    >
      <deploymentOfRecord
        ref="deploymentR"
        :detsiel="detsiel"
        :tabradio="'物资'"
      ></deploymentOfRecord>
    </Modal>
  </div>
</template>

<script>
//高德坐标转百度（传入经度、纬度）
function bd_encrypt(gg_lng, gg_lat) {
  var X_PI = (Math.PI * 3000.0) / 180.0
  var x = gg_lng,
    y = gg_lat;
  var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
  var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
  var bd_lng = z * Math.cos(theta) + 0.0065;
  var bd_lat = z * Math.sin(theta) + 0.006;
  return {
    lat: bd_lng,
    lng: bd_lat,
  };
}

import IndexBox from "@/components/IndexBox/indexBox";

import Resor from "./incident/resor.vue";
import deploymentOfRecord from "./incident/deploymentOfRecord.vue";
import Modal from "@/components/Modal/modal.vue";
import { publicresources, rescueteam, equipmentlist } from "@/api/eres.js";
import { queryDictByCode } from "@/api/dict.js";
export default {
  components: { IndexBox, Resor, deploymentOfRecord, Modal },
  props: {
    detsiel: {
      type: Object,
    },
    within:{
      type:Array
    }
  },
  watch: {
    detsiel: {
      handler(val) {
        console.log(val);
        if (val.coord) {
          this.lat = val.coord[0];
          this.lng = val.coord[1];
         
        } else {
          let p = bd_encrypt(val.xcoor, val.ycoor);
          val.coord = [p.lat, p.lng];
          this.lat = p.lng;
          this.lng = p.lat;
           
        }
      },
      immediate: true,
    },
    within:{
       handler(val) {
        console.log(val);
      if(val){
        this.withins=val
      }
      },
      immediate: true,
    }
  },
  data() {
    return {
      rescueTitle: "资源调配",
      deploymentTitle: "调配记录",
      titleRight: "调配记录",
      input: 0,
      lat: 0,
      lng: 0,
      libraryData: {},
      // library: [
      //   {
      //     rescue: "XXX救援队",
      //     staff: "张三 15428542658",
      //     distance: "1.6km"
      //   },
      //   {
      //     rescue: "XXX救援队",
      //     staff: "张三 15428542658",
      //     distance: "1.6km"
      //   },
      //   {
      //     rescue: "XXX救援队",
      //     staff: "张三 15428542658",
      //     distance: "1.6km"
      //   },
      //   {
      //     rescue: "XXX救援队",
      //     staff: "张三 15428542658",
      //     distance: "1.6km"
      //   }
      // ],
      dictList: [],
      withins: [],
      hospital: [], //医院
      unit: [], //单位
      supplies: [], //物资
      fulltime: [], //专职救援队
      parttime: [], //兼职救援队
      fuiset: [],
      fullist: {
        teamName: "",
        captainName: "",
        captainPhone: "",
        distance: "",
      },
    };
  },
  created() {
    queryDictByCode({
      code: "commonResourceYype",
    }).then((res) => {
      console.log(res);
      this.dictList = res.data;
      this.according()
    });
   
    // this.$EventBus.$on('withinList',val=>{
    //   console.log(val)
    //   this.withinList=val
    // })
  },
  mounted(){
// this.$nextTick(()=>{

//    })
  },
  methods: {
    // 调配记录
    expandMore() {
      console.log("调配记录");
      this.$refs.deployment.handleOpen();
      this.$nextTick(() => {
        this.$refs.deploymentR.getmaterialsListData();
      });
    },
    deploy(data) {
      console.log(data);
      this.$refs.rescueDetail.handleOpen();
      this.libraryData = Object.assign({}, data);
    
    },
    closeter() {
      this.$refs.rescueDetail.handleClose();
    },
    closeDialog() {
      this.$refs.resores.reset();
      this.$refs.rescueDetail.handleClose();
    },
    //显示范围
    according() {
      console.log(this.dictList);

      this.withinList = [];
      this.$EventBus.$emit("ruides", this.input);
      //医院
      publicresources({
        km: "",
        lat: this.lng,
        lon: this.lat,
        resourceType: this.dictList[1].label,
      }).then((res) => {
        console.log(res);
        res.data.forEach((item) => {
                item.teamName = item.resourceName;

                item.captainPhone = item.telephone;
                item.library = "医院";
                this.withins.push(item);
              });

      });
      //单位
      publicresources({
        km: "",
        lat: this.lng,
        lon: this.lat,
        resourceType: this.dictList[0].label,
      }).then((res) => {
        console.log(res);
        if (res.data && res.data.length != 0) {
          res.data.forEach((item) => {
          item.teamName = item.resourceName;

                item.captainPhone = item.telephone;
                item.library = "应急联络";
                this.withins.push(item);
         });
        }
      });
      //专职救援队
      rescueteam({
        km: "",
        lat: this.lng,
        lon: this.lat,
        teamType: 1,
      }).then((res) => {
        console.log(res);
        if (res.data && res.data.length != 0) {
         
          res.data.forEach((el) => {
            el.library = "专职救援队";
          this.withins.push(el);
          });
          // this.withinList.push(res.data)
        }
      });
      //社会救援队
       rescueteam({
        km: "",
        lat: this.lng,
        lon: this.lat,
        teamType: 3,
      }).then((res) => {
        console.log(res);
        if (res.data && res.data.length != 0) {
         
          res.data.forEach((item) => {
             item.teamName = item.title;
                item.captainName = item.leaderName;
                item.captainPhone = item.mobile;
            item.library = "社会救援队";
          this.withins.push(item);
          });
          // this.withinList.push(res.data)
        }
      });
      //兼职救援队
      // rescueteam({
      //   km: this.input,
      //   lat: this.lng,
      //   lon: this.lat,
      //   teamType: 2,
      // }).then((res) => {
      //   console.log(res);
      //   if (res.data && res.data.length != 0) {
      //     this.parttime = res.data;
      //     this.$EventBus.$emit("parttime", this.parttime);
      //     res.data.forEach((el) => {
      //       el.library = "兼职";
      //       this.withinList.push(el);
      //     });
      //   }else{
      //       this.$EventBus.$emit("parttime", res.data);
      //   }
      // });
      //物资库
      equipmentlist({
        km:"",
        lat: this.lng,
        lon: this.lat,
      }).then((res) => {
        console.log(res);
        if (res.data && res.data.length != 0) {
        
          res.data.forEach((item) => {
            item.teamName = item.title;
            item.captainName = item.leaderName;
            item.captainPhone = item.mobile;
            item.library = "物资库";
           this.withins.push(item);
          });

          this.withins = this.withins.sort(this.sortId);
         
        }
      });

      // this.$EventBus.$emit('wirhin',this.withinList)
    },
    sortId(a, b) {
      return a.distance - b.distance;
    },

    //关闭范围
    shutdowns() {
      this.input = 0;
      // 医院
      this.hospital = [];
      this.unit = [];
      this.fulltime = [];
      this.parttime = [];
      // 物资库
      this.supplies = [];
      this.withinList = [];

      
    },
  },
};
</script>

<style lang="scss" scoped>
.deploy {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;

  p {
    font-size: 12px;
    color: #ffffff;
  }
}
.library {
  width: 98%;
  height: 80px;
  background: #0f2752;
  // opacity: 0.5;
  margin: auto;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  .library_left {
    font-size: 13px;
    color: #ffffff;
    p {
      margin-top: 10px;
      margin-left: 10px;
    }
  }
  .library_right {
    width: 150px;
    font-size: 13px;
    color: #ffffff;
      display: flex;
  justify-content: space-between;
    span {
      line-height: 80px;
      margin-left: 5px;
     
    }
  }
}
::v-deep .zydpInput.el-input {
  width: 50%;
}
::v-deep .zydpInput .el-input__inner {
  width: 100%;
  height: 21px;
  font-size: 12px;
  background-color: rgba(8, 23, 66, 0.9);
}
.partime .zydpInput .el-input__inner {
  width: 100%;
  height: 21px;
  font-size: 12px;
  background-color: #ffffff;
}
::v-deep .el-input__inner {
  color: #ffffff !important;
}
</style>
