<template>
  <div>
    <div class="info">
      <div class="info-item">
        {{ detailInfo.lineSiteDTO ? detailInfo.lineSiteDTO.name : "" }}
      </div>
      <div class="info-item">检查人员：{{ detailInfo.checkPeople }}</div>
      <div class="info-item">检查时间：{{ detailInfo.checkTime }}</div>
    </div>

    <div class="table-wrapper">
      <el-table
        :data="detailInfo.threeCheckListItemList"
        style="width: 100%; margin-bottom: 20px"
        border
        v-loading="loading"
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="checkItemContent"
          label="检查项"
          width="500"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="hiddenDangerCount"
          label="存在隐患数量"
        >
          <!--          <template slot-scope="scope">
            {{ scope.row.questionList.length }}
          </template>-->
        </el-table-column>
        <el-table-column align="center" prop="planCount" label="检查详情">
          <template slot-scope="scope">
            <div
              class="content"
              v-for="(item, index) in scope.row.questionList"
              :key="index"
            >
              {{ item.hiddenQuestion }}
              <el-image
                v-if="item.uri"
                style="width: 100px; height: 100px"
                :src="item.uri"
                fit="cover"
              ></el-image>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!--            查看显示-->
      <div class="title" v-if="operation === '查看'">
        审批状态:
        {{
          detailInfo.currentResponseReport.approvalState == 0
            ? "待审批"
            : detailInfo.currentResponseReport.approvalState == 1
            ? "审批通过"
            : detailInfo.currentResponseReport.approvalState == 2
            ? "审批驳回"
            : detailInfo.currentResponseReport.approvalState == -2
            ? "超时"
            : detailInfo.currentResponseReport.approvalState == -1
            ? "待填报"
            : ""
        }}
      </div>

      <div class="title" v-if="operation === '查看'">
        审批原因:
        {{ detailInfo.currentResponseReport.rejectReason }}
      </div>

      <!--      审批显示-->
      <div class="title" v-if="operation === '审批'">审批意见</div>
      <el-input
        v-if="operation === '审批'"
        type="textarea"
        v-model="rejectReason"
      ></el-input>

      <!--      查看显示-->
      <div slot="footer" class="dialog-footer" v-if="operation === '查看'">
        <el-button type="primary" @click="cancelApproval">关闭</el-button>
      </div>

      <!--      审批显示-->
      <div slot="footer" class="dialog-footer" v-else>
        <el-button type="primary" @click="approvalAdopt(1)">通过</el-button>
        <el-button type="danger" @click="approvalAdopt(2)">驳回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetail, threeCheckListApprove } from "@/api/response";

export default {
  name: "inspectApproval",
  props: {
    operation: null,
    editData: {},
  },
  data() {
    return {
      detailInfo: {
        currentResponseReport: {
          siteId: "",
        },
        lineSiteDTO: {
          name: "",
        },
      },
      rejectReason: "",
      loading: false,
      form: {},
    };
  },
  created() {
    this.getDetailData();
  },
  methods: {
    getDetailData() {
      this.loading = true;
      getDetail(this.editData.id).then((res) => {
        this.detailInfo = res.data;
        this.loading = false;
      });
    },
    approvalAdopt(num) {
      // if (num == 2) {
      if (this.rejectReason.length > 0) {
        console.log(this.rejectReason);
      } else {
        this.$message.error("审批意见不能为空");
        return;
      }
      // }
      let params = {
        approvalState: num,
        currentId: this.editData.id,
        rejectReason: this.rejectReason,
      };
      threeCheckListApprove(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message.success("操作成功");
          this.$emit("successApproval");
        }
      });
    },
    cancelApproval() {
      this.$emit("cancelApproval");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.info {
  display: flex;
  margin-bottom: 20px;
  .info-item {
    font-weight: bold;
    margin-right: 60px;
  }
}
.title {
  margin-bottom: 20px;
}
</style>
