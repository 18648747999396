<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
            <el-select
              v-model="queryModal.siteId"
              filterable
              placeholder="请选择线路"
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select
              v-model="queryModal.state"
              placeholder="请选择状态"
              clearable
            >
              <el-option
                :label="data.label"
                :value="data.id"
                v-for="(data, index) in stateList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-date-picker
              v-model="queryModal.date"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="">
            <el-input
              v-model="queryModal.user"
              placeholder="输入关键字搜索"
            ></el-input>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="setsmit">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="handleAdd">上传</el-button>
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" v-loading="tableLoading" style="width: 100%">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="80"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="工点名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="imageName"
          label="内容"
          width="150"
        >
          <template slot-scope="scope">
            <!-- <span class="cursor-pointer" style="color: #43a5e9">{{
              scope.row.imageName
            }}</span> -->
            <el-image
              style="width: 150px; height: 80px"
              :src="scope.row.imageUrl"
              :preview-src-list="[scope.row.imageUrl]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="state" label="状态">
          <template slot-scope="scope">
            <span>{{
              scope.row.state == "1"
                ? "已更新"
                : scope.row.state == "2"
                ? "审核中"
                : "审核未通过"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="userName"
          label="提交人员"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="created"
          label="提交时间"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="note"
          label="备注"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.state == '2'"
              type="text"
              @click="handleAudit(scope.row)"
              >审核</el-button
            >
            <el-button type="text" @click="handleEdit(scope.row)">{{
              scope.row.state == 1 ? "更新" : "重新提交"
            }}</el-button>
            <el-button type="text" @click="handleLog(scope.row)"
              >历史操作</el-button
            >

            <el-button @click="handleDelete(scope.row)" type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="queryModal.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <ChartForm
      ref="modelForm"
      :linetree="lineSiteData"
      :type="queryModal.type"
      @getdata="setsmit"
    ></ChartForm>
    <AuditFrom ref="AuditForm" @getdata="setsmit"></AuditFrom>
    <LogForm ref="LogForm"></LogForm>
  </div>
</template>
<script>
import ChartForm from "./modules/chartForm1.vue";
import AuditFrom from "./modules/auditForm.vue";
import LogForm from "./modules/logForm.vue";
import {
  drainagerList,
  drainagerDelete,
  drainagerLog,
  tripleTree,
} from "@/api/map";
export default {
  components: {
    ChartForm,
    AuditFrom,
    LogForm,
  },
  data() {
    return {
      queryModal: {
        siteId: "",
        state: "",
        date: "",
        page: 1,
        size: 10,
        total: 0,
        type: 1,
      },
      tableData: [],
      options: [],
      lineSiteData: [],
      stateList: [
        {
          id: 1,
          label: "已更新",
        },
        {
          id: 2,
          label: "审核中",
        },
        {
          id: 3,
          label: "审核未通过",
        },
      ],
      props: {
        value: "id",
        label: "name",
        children: "nodes",
      },
      tableLoading: true,
    };
  },
  mounted() {
    this.queryLineSiteTree();
    this.getdata();
  },
  created() {
    let f = this.$route.fullPath;
    if (f == "/System/Chart/FloodControl") {
      this.queryModal.type = "2";
    } else if (f == "/System/Chart/DrainPage") {
      this.queryModal.type = "1";
    } else {
      this.queryModal.type = "4";
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.fullPath === "/System/Chart/FloodControl") {
      this.queryModal.type = "2";
    } else if (to.fullPath === "/System/Chart/DrainPage") {
      this.queryModal.type = "1";
    } else {
      this.queryModal.type = "4";
    }
    this.getdata();
    next();
  },
  methods: {
    queryLineSiteTree() {
      tripleTree({}).then((res) => {
        if (res.code == 0) {
          this.lineSiteData = JSON.parse(JSON.stringify(res.data));
          this.options = this.lineSiteData;
          this.options.forEach((e) => {
            if (e.children.length != 0) {
              e.children.forEach((el) => {
                delete el.children;
              });
            }
          });
          console.log(this.options, "==========", this.lineSiteData);
        }
      });
    },
    setsmit() {
      this.queryModal.page = 1;
      this.getdata();
    },
    //条数改变
    handleSizeChange(val) {
      this.queryModal.size = val;
      this.queryModal.page = 1;
      this.getdata();
    },
    // 翻页
    handleCurrentChange(val) {
      this.queryModal.page = val;
      this.getdata();
    },
    async getdata() {
      this.tableLoading = true;
      let params = {
        siteId: this.queryModal.siteId ? this.queryModal.siteId : "-",
        state: this.queryModal.state ? this.queryModal.state : "-",
        date: this.queryModal.date ? this.queryModal.date : "-",
        keyword: "-",
        page: this.queryModal.page,
        size: this.queryModal.size,
        type: this.queryModal.type,
      };
      let res = await drainagerList(params);
      if (res.code == 0) {
        console.log(res);
        this.tableData = res.data.records;
        this.queryModal.total = res.data.count;
        this.tableLoading = false;
      }
    },
    handleAdd() {
      this.$refs.modelForm.handleAdd();
    },
    handleEdit(record) {
      this.$refs.modelForm.handleAdd(record);
    },
    handleAudit(record) {
      this.$refs.AuditForm.handleAdd(record);
    },
    handleDelete(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        drainagerDelete(record).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.getdata();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    handleLog(record) {
      this.tableLoading = true;
      drainagerLog(record).then((res) => {
        if (res.code == 0) {
          let datas = res.data.reverse();
          this.$refs.LogForm.handleAdd(datas);
          this.tableLoading = false;
        } else {
          this.$message.error("查询失败！");
          this.tableLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
