<template>
  <div class="material-container h-full">
    <el-form
      ref="formData"
      :rules="rules"
      :model="formData"
      :loading="loading"
      label-width="120px"
      :disabled="submitType == 'view'"
    >
      <el-row class="PostInfCs-input">
        <el-col :span="12">
          <el-form-item label="危大工程名称" prop="projectName">
            <el-input
              v-model="formData.projectName"
              clearable
              placeholder="请输入危大工程名称"
              :disabled="submitType === 'detail' "
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="类别" prop="typeCode">
            <el-select
              v-model="formData.typeCode"
              clearable
              :disabled="submitType === 'detail' "
              placeholder="类别"
              style="width: 100%"
            >
              <el-option
                v-for="item of bigProjectList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预计开工时间" prop="estimateStartDay">
            <el-date-picker
              v-model="formData.estimateStartDay"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :disabled="submitType === 'detail' "
              type="date"
              placeholder="请选择时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="实际开工时间" prop="realStartDay">
            <el-date-picker
              v-model="formData.realStartDay"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :disabled="submitType === 'detail' "
              type="date"
              placeholder="请选择时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="线路-分部-工点" prop="siteUuid">
            <el-cascader
                v-model="formData.linsiteIds"
                clearable
                :options="treeData"
                placeholder="线路工点"
                :disabled="submitType === 'detail' "
                :props="props"
                :show-all-levels="false"
                style="width: 100%"
                collapse-tags
                @change="handleLineSiteChange"
            ></el-cascader>


<!--            v-if="type != 'view'"-->
<!--            style="width:100%"-->
<!--            clearable-->
<!--            v-model="siteList"-->
<!--            :options="lineSiteData"-->
<!--            :props="props"-->
<!--            :show-all-levels="false"-->
<!--            @change="handleLineSiteChange"-->
<!--            <el-cascader v-model="lineSite" style="width: 100%"-->
<!--                         clearable :options="treeData" :props="props"-->
<!--                         :show-all-levels="false" collapse-tags @change="handleLineSiteChange"></el-cascader>-->
          </el-form-item>
          <el-form-item label="是否超大规模" prop="isLargeScale">
            <el-select
              v-model="formData.isLargeScale"
              clearable
              placeholder="请选择是否超大规模"
              :disabled="submitType === 'detail' "
              style="width: 100%"
            >
              <el-option
                v-for="item of isBigList"
                :key="item.code"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预计结束时间" prop="estimateEndDay">
            <el-date-picker
              v-model="formData.estimateEndDay"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :disabled="submitType === 'detail' "
              type="date"
              placeholder="请选择时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="实际结束时间" prop="realEndDay">
            <el-date-picker
              v-model="formData.realEndDay"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :disabled="submitType === 'detail' "
              type="date"
              placeholder="请选择时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="PostInfCs-input">
        <el-col :span="8">
          <el-form-item label="位置" prop="riskLevelCode">
            <el-button size="medium" type="primary" :disabled="submitType === 'detail' " @click="choseMap"
              >选择坐标</el-button
            >
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="经度" prop="lon">
            <el-input
              v-model="formData.lon"
              clearable
              disabled
              style="width: 100%"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="纬度" prop="lat">
            <el-input
              v-model="formData.lat"
              clearable
              disabled
              style="width: 100%"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="PostInfCs-input">
        <el-col>
          <el-form-item label="危大工程描述" prop="describe">
            <el-input
              type="textarea"
              v-model="formData.describe"
              :disabled="submitType === 'detail' "
              placeholder="请输入危大工程描述"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="PostInfCs-input">
        <el-col>
          <el-form-item label="专项方案" prop="riskLevelCode">
            <div class="file-item" v-for="(item,index) in specList"
                 :key="index" v-show="submitType !== 'add' ">
              <span class="file-name" @click="lookFile(item)"> {{ item.fileName }}</span>
              <span @click="deleteFile(item)">X</span>
            </div>
            <Uploader @uploader="specUploader"></Uploader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="PostInfCs-input">
        <el-col>
          <el-form-item label="专家论证" prop="riskLevelCode">
            <div class="file-item" v-for="(item,index) in expertList"
                 :key="index" v-show="submitType !== 'add' ">
              <span class="file-name" @click="lookFile(item)"> {{ item.fileName }}</span>
              <span @click="deleteFile(item)">X</span>
            </div>
            <Uploader @uploader="expertUploader"></Uploader>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="dialogFooter">
      <el-button size="medium" @click="cancel" v-show="submitType !== 'view'"
        >取 消</el-button
      >
      <el-button
        size="medium"
        type="primary"
        @click="submit"
        v-if="submitType === 'add' || submitType === 'edit' "
        >确 定</el-button
      >
    </div>

    <MapForm
        ref="mapForm"
        :getCoo="getCoo"
    ></MapForm>

  </div>
</template>

<script>
// 字典
import { getDictData } from "@/api/dict";
// 查询角色列表
import { getRoleApi } from "@/api/role";
// 新增,编辑
import { warnAdd, warnEdit } from "@/api/risk";
import MapForm from "@/views/Contingency/System/Resource/modules/map";
import Uploader from "@/components/Uploader/Uploader";
import {riskDangerBigProjectAdd, riskDangerBigProjectDetail, riskDangerBigProjectEdit} from "@/api/riskManage";
import {getConstructionUnitlnfo} from "@/api/map";

export default {
  props: {
    pageData: {
      type: Object,
      default: () => {},
    },
    submitType: {
      type: String,
    },
  },
  components : {
    MapForm,
    Uploader
  },
  data() {
    return {
      formData: {
        projectName : '',
        typeCode : '',
        estimateStartDay : '',
        realStartDay : '',
        isLargeScale : '',
        estimateEndDay : '',
        realEndDay : '',
        describe : '',
        lon : '',
        lat : '',
        lineUuid : '',
        branchUuid : '',
        siteUuid : '',
        dangerProjectFileList : [],
        linsiteIds : [],
      },
      isBigList: [
        {
          label : '是',
          value : 1
        },
        {
          label : '否',
          value : 0
        },
      ],
      // 单位类型
      unitTypeData: [],
      // 风险控制等级
      gradeList: [],
      cycleList: [],
      // 角色
      rolesData: [],
      loading: false,
      rules: {
        projectName : [
          { required: true, message: '危大工程名称必填', trigger: 'blur' }
        ],
        typeCode : [
          { required: true, message: '类别必填', trigger: 'change' }
        ],
        estimateStartDay : [
          { required: true, message: '预计开工时间必填', trigger: 'change' }
        ],
        isLargeScale : [
          { required: true, message: '是否超大规模必填', trigger: 'change' }
        ],
        estimateEndDay : [
          { required: true, message: '预计结束时间必填', trigger: 'change' }
        ],
        describe : [
          { required: true, message: '危大工程描述必填', trigger: 'blur' }
        ],
        siteUuid: [
          {
            required: true,
            message: "请选择工点",
            trigger: "change",
          },
        ],
      },
      lineSite : [],
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        // multiple: true
      },
      bigProjectList : [],
      faltArr: [], //站点数平行数据
      specList : [],
      expertList : [],
    };
  },

  created() {
    this.queryLineSiteTreeData();
    /*if(this.submitType === 'edit' || this.submitType === 'detail'){

    }*/
  },
  mounted() {
    this.getDictList();
    this.getDetailData();

  },
  methods: {
    queryLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
        this.treeData.forEach(item => {
          this.flatArray(item);
        });
      }
    },
    getDetailData(){
      riskDangerBigProjectDetail(this.pageData.id).then(res => {
        if(res.code == 0){
          this.formData = res.data;
          let arr = [];
          arr.unshift(this.formData.siteUuid)
          arr.unshift(this.formData.branchUuid)
          arr.unshift(this.formData.lineUuid)
          this.formData.linsiteIds = arr;
          if (this.submitType == "detail" || this.submitType == "edit") {
            this.formData = Object.assign({}, this.pageData);
            let site = this.faltArr.find(i => i.uuid == this.formData.siteUuid);
            console.log(site)
            console.log(this.formData)
            if (site.puuid == this.formData.branchUuid) {
              this.formData.linsiteIds = [
                this.formData.lineUuid,
                this.formData.branchUuid,
                this.formData.siteUuid
              ];
            } else {
              console.log(123)
              this.formData.linsiteIds = [
                this.formData.lineUuid,
                this.formData.branchUuid,
                site.puuid,
                this.formData.siteUuid
              ];
            }
          } else {
            this.getCoo();
          }
          this.formData.dangerProjectFileList = res.data.dangerProjectFileList;
          if(res.data.dangerProjectFileList && res.data.dangerProjectFileList.length > 0){
            this.specList = res.data.dangerProjectFileList.filter(item => item.fileType === 0);
            this.expertList = res.data.dangerProjectFileList.filter(item => item.fileType === 1);
          }
        }
      })
    },
    flatArray(data) {
      this.faltArr.push(data);
      if (data.children && data.children.length > 0) {
        data.children.forEach(item => {
          this.flatArray(item);
        });
      }
    },
    lookFile(item){
      // window.open(item.fileUrl)
      let fileUrl = item.fileUrl.split(',')[1];
      console.log(fileUrl)
      this.downLoadFile(fileUrl,item.fileName);
    },
    deleteFile(fileItem){
      let i = this.formData.dangerProjectFileList.findIndex(item => item.id === fileItem.id )
      console.log(i)
      this.formData.dangerProjectFileList.splice(i,1);
      console.log(this.formData.dangerProjectFileList)
      this.specList = this.formData.dangerProjectFileList.filter(item => item.fileType === 0);
      this.expertList = this.formData.dangerProjectFileList.filter(item => item.fileType === 1);
    },
    handleLineSiteChange(val) {
      if (val && val.length > 0) {
        this.formData.lineUuid = val[0];
        this.formData.branchUuid = val[1];
        this.formData.siteUuid = val[val.length - 1];
        let site = this.faltArr.find(
            item => item.uuid == this.formData.siteUuid
        );
        let line = this.faltArr.find(
            item => item.uuid == this.formData.lineUuid
        );
        let branch = this.faltArr.find(
            item => item.uuid == this.formData.branchUuid
        );
        this.formData.siteName = site.name;
        this.formData.lineName = line.name;
        this.formData.branchName = branch.name;
      }
    },
    choseMap() {
      this.$refs.mapForm.handleAdd(
          this.formData.lon,
          this.formData.lat,
          this.formData.teamStation
      )
    },
    specUploader(event){
      console.log(event)
      if(event.length !== 0){
        let params = {
          fileName : event.originalName,
          fileUrl : event.uri + ',' + event.realUrl ,
          fileType : 0,
        }
        this.formData.dangerProjectFileList.push(params)
      }
    },
    expertUploader(event){
      if(event.length !== 0){
        let params = {
          fileName : event.originalName,
          fileUrl : event.uri + ',' + event.realUrl ,
          fileType : 1,
        }
        this.formData.dangerProjectFileList.push(params)
      }
    },
    getDictList() {
      this.bigProjectList = [];
      getDictData("dangerBigProjectType").then((res) => {
        if (res.code == 0) {
          this.bigProjectList = res.data;
        }
      });
    },
    unitTypeChange(val) {
      this.formData.responseRoleId = "";
      this.rolesData = [];
      getRoleApi({ unitType: val })
        .then((res) => {
          if (res.code == 0) {
            this.rolesData = res.data;
          }
        })
        .catch((res) => {
          console.log(res.messafe);
        });
    },
    submit() {
      this.$refs.formData.validate((valid) => {
        console.log(this.submitType);
        this.formData.typeName = this.bigProjectList.filter(item => item.code === this.formData.typeCode)[0].label;
        if (valid) {
          if (this.submitType == "add") {
            riskDangerBigProjectAdd(this.formData).then(res => {
              if(res.code == 0){
                this.$message.success('操作成功');
                this.$emit('CloseData')
              }else{
                this.$message.error(res.message)
              }
            })
          } else if (this.submitType == "edit") {
            riskDangerBigProjectEdit(this.formData).then(res => {
              if(res.code == 0){
                this.$message.success('操作成功');
                this.$emit('CloseData')
              }else{
                this.$message.error(res.message)
              }
            })
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancel() {
      this.$emit('cancelModal')
    },
    reset() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = "";
      });
    },
    getCoo(lon, lat) {
      console.log(lon,lat)
      this.formData.lon = lon
      this.formData.lat = lat
      this.$forceUpdate()
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
.PostInfCs-input {
  .el-input--suffix .el-input__inner {
    width: 100%;
  }
  .el-col {
    padding: 0 20px;
  }
  .el-date-editor.el-input {
    width: 100%;
  }
}
.file-item{
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  .file-name{
    color: #1aa3ff;
  }
}
</style>
