<template>
  <systemModal
    ref="formModal"
    :title="title"
    :showFooter="false"
    width="40%"
    @modalClose="handleClose"
  >
    <el-form
      v-loading="loading"
      :model="model"
      ref="modelForm"
      label-width="200px"
      class="demo-model"
      style="width: 80%"
    >
      <el-form-item label="调配工点" prop="name">
        <el-input
          v-model="model.name"
          readonly
          placeholder="调配工点"
        ></el-input>
      </el-form-item>
      <el-form-item label="地理位置" prop="position ">
        <el-input
          v-model="model.position "
          readonly
     
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="调配内容" prop="deployContent">
        <el-input
          v-model="model.deployContent"
          readonly
          placeholder="调配内容"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="调配人" prop="optionName">
        <el-input
          v-model="model.optionName"
          readonly
          placeholder="调配人"
        ></el-input>
      </el-form-item>
          <el-form-item label="调配时间" prop="deployTime">
        <el-input
          v-model="model.deployTime"
        readonly
        
        ></el-input>
      </el-form-item>
      
       <el-form-item label="事件联络人" prop="eventName">
        <el-input
          v-model="model.eventName"
          readonly
        
        ></el-input>
      </el-form-item>
    
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { queryMaterialHandleInfo, queryMaterialStaffPerson } from "@/api/eres";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "资源调配",
      model: {},
      eventId: "",
      id: "",
      personList: [],
      loading: false,
      rejectModalShow: false,
      form: {
        reason: "",
      },
      rules: {
        reason: [{ required: true, message: "请输入驳回原因", trigger: "blur" }]
      }
    };
  },
  filters: {},
  mounted() {},
  methods: {
    handleDetail(record) {
      this.eventId = record.eventId;
      this.id = record.id;
      this.getInfo(record.eventId);
      this.$refs.formModal.handleOpen();
      // 处理人员请求接口参数
      let queryPersonParams = null;
      if (record.teamId) {
        queryPersonParams = {
          type: 1,
          rescueTeamId: record.teamId,
          eventId:record.eventId
        };
      } else {
        queryPersonParams = {
          type: 0,
          siteId: record.siteUuid,
           eventId:record.eventId
        };
      }
      this.queryPersonList(queryPersonParams);
    },
    // 查询详情
    async getInfo(id) {
      let res = await queryMaterialHandleInfo({ id });
      if (res.code == 0) {
        this.model = res.data;
      }
    },
    // 查询调配人员列表
    async queryPersonList(record) {
      let res = await queryMaterialStaffPerson(record);
      if (res.code == 0) {
        this.personList = res.data;
      }
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 30px !important;
}

::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}

::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
</style>
