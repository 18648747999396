<template>
  <!-- 灾害损失的列表 -->
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-select
        v-model="status"
        class="mr-2"
        clearable
        placeholder="请选择状态"
      >
        <el-option
          v-for="item of options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button type="primary" @click="handleSearch">查询</el-button>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="loading"
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="60"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="constructionName"
          label="线路-工点"
          :formatter="unitNameFormat"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="deadline"
          label="截止时间"
          width="200"
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row.fillTime"
              :style="{
                color: scope.row.deadline > scope.row.fillTime ? '' : 'red',
              }"
            >
              {{ scope.row.deadline }}
            </div>
            <div
              v-else
              :style="{ color: scope.row.deadline > gettime ? '' : 'red' }"
            >
              {{ scope.row.deadline }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="fillTime"
          show-overflow-tooltip
          label="填报时间"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="fillUserName"
          show-overflow-tooltip
          label="填报人员"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="status"
          :formatter="formatState"
          show-overflow-tooltip
          label="状态"
        >
        </el-table-column>
        <!-- <el-table-column
          align="center"
          prop="approvalNames"
          show-overflow-tooltip
          label="审批人员"
        >
        </el-table-column> -->
        <el-table-column align="center" label="操作" width="220px">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleDetail(scope.row)"
              v-show="
                scope.row.approvalState == 2 || scope.row.approvalState == 3
              "
            >
              查看
            </el-button>
            <el-button
              type="text"
              @click="handelAccraditation(scope.row)"
              v-show="scope.row.approvalState == 2"
              >审批
            </el-button>
            <el-button
              type="text"
              @click="handeFill(scope.row)"
              v-show="scope.row.approvalState == 1"
              >填报
            </el-button>
            <el-button
              type="text"
              @click="handelResubmit(scope.row)"
              v-show="scope.row.approvalState == 4"
            >
              重新填报
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout=" prev, pager, next,total, sizes"
        :total="page.count"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <!-- 填报 -->
    <el-dialog
      title="灾害损失及恢复情况上报"
      :visible.sync="reportDialog"
      width="80%"
      :before-close="reportClose"
      center
      :append-to-body="true"
      :modal-append-to-body="false"
    >
      <reportery
        v-if="reportDialog"
        @downResume="reportClose"
        :currentId="currentId"
        :resubmit="resubmit"
        :supervisionName="supervisionName"
      ></reportery>
    </el-dialog>
    <!-- 审批查看 -->
    <el-dialog
      title="灾害损失及恢复情况上报详情"
      :visible.sync="examineDialog"
      width="80%"
      :before-close="examieClose"
      center
      :append-to-body="true"
      :modal-append-to-body="false"
    >
      <examine
        v-if="examineDialog"
        :reportCurrentId="reportCurrentId"
        @examieClose="examieClose"
        :terwe="terwe"
      ></examine>
    </el-dialog>
  </div>
</template>

<script>
import examine from "./examine.vue";
import reportery from "./reportery.vue";
// 接口
import {
  queryCurrentResponseListNew,
  statusListByDictIdAndType,
} from "@/api/response";
export default {
  components: {
    examine,
    reportery,
  },
  props: {
    casualtyLossData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      page: {
        pageNum: 1,
        pageSize: 10,
        count: 0,
      },
      loading: false,
      status: "",
      stateList: [],
      tableData: [],

      examineDialog: false,
      terwe: "",
      reportCurrentId: null,
      id: 1,
      reportDialog: false,
      resubmit: "",
      currentId: null,
      supervisionName: "",
      options: [],
      gettime: null,
    };
  },
  mounted() {
    this.loadData();
    this.getStatue();
  },
  methods: {
    getStatue() {
      statusListByDictIdAndType({ dictId: 347, type: 0 })
        .then((res) => {
          this.options = [];
          res.data.forEach((item) => {
            this.options.push({
              value: item.code,
              label: item.text,
            });
          });
          console.log("状态", this.options);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    loadData() {
      this.loading = true;
      const params = {
        responseRecordId: this.casualtyLossData.id,
        // isApproval: 0,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        reportType: 347,
        // siteUuidList: [this.casualtyLossData.uuid],
        status: this.status,
      };
      queryCurrentResponseListNew(params)
        .then((res) => {
          this.loading = false;
          if (res.code == 0) {
            this.tableData = res.data.records;
            this.page.count = res.data.count;
            this.gettime = formatDate("YY-MM-DD hh:mm:ss");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSearch() {
      this.loadData();
    },
    // 查看
    handleDetail(row) {
      this.terwe = "查看";
      this.examineDialog = true;
      this.reportCurrentId = row.id;
    },
    // 审批
    handelAccraditation(row) {
      this.terwe = "审批";
      this.examineDialog = true;
      this.reportCurrentId = row.id;
    },
    // 填报
    handeFill(row) {
      this.reportDialog = true;
      this.resubmit = "未填报";
      this.currentId = row.id;
    },
    // 重新填报
    handelResubmit(row) {
      this.resubmit = "重新填报";
      this.reportDialog = true;
      this.currentId = row.id;
    },

    examieClose() {
      this.examineDialog = false;
      this.loadData();
    },
    reportClose() {
      this.reportDialog = false;
      this.loadData();
    },
    // 分页
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    // 角色类型type为4时单位名称转换
    unitNameFormat(row) {
      if (row.siteUuId) {
        return `${row.lineName}-${row.siteName}`;
      } else {
        return row.supervisionName;
      }
    },
    formatState: function(row, column, cellValue) {
      switch (cellValue) {
        case 0:
          return "待填报";
        case 1:
          return "总包已驳回";
        // case 2:
        // return "建管部已驳回";
        case 3:
          return "待总包审批";
        // case 4:
        // return "待建管部确认";
        case 5:
          return "已完成";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 1rem;
}
</style>
