import {
  httpInit
} from "./http"
const http = httpInit("/gzdt-org")

// 分页查询部门列表
export const queryDeepPageList = params => http.get(`/dept/page/${params.name}/${params.companyId}/${params.parentId}/${params.pageNo}/${params.pageSize}`)

// 分页查询机构列表
export const queryOrgPageList = params => http.get(`/org/page/${params.name}/${params.category}/${params.pageNo}/${params.pageSize}`)

// 查询全部单位及下辖的组织机构树
export const queryOrg = params => http.get(`/api/coms`, params)

// 查询指定单位下所有部门
export const queryDeepDataByOrgId = params => http.get(`/api/depts/${params.companyId}`)

//根据ID查询单位详情
export const getDeptList = params => http.get(`/api/com/${params.companyId}`)
// 查询所属承包部
export const dept = params => http.post(`/api/dept/list`, params)

// 根据ID查询单位详情
export const queryDeptDetail = (id) =>
  http.get(`/api/com/${id}`);

// 展示不同的单位类型下面对应的单位，单位下面的部门
export const queryDeepByType = () =>
  http.get(`/api/getAllVendorAndDepartmentFromCategory`);

// 组织机构---新增保存
export const saveVendor = (data) => http.post(`/org/vendor_dept`, data);
//查询三级树-部门单位
export const deleteUnit = (id) => http.delete(`/org/info/${id}`);
//查询三级树-部门权限
export const deleteSection = (id) => http.delete(`/dept/info/uuid/${id}`);
// 组织机构---修改保存
export const editVendor = (data) => http.post(`/org/update/vendor_dept`, data);
// 组织机构获取上级单位
export const getUserDeptList = (data) => http.post(`/org/getLoginUserDeptList`, data);

//根据登录人获取单位类型 3是建管部 4是质安部
export const getDepartmentTypeByUser = () =>http.get(`/dept/getDepartmentTypeByUser`);
// 三防合并

// 根据不同单位类型查询所有单位
export const queryUnitByType = () => http.get(`/api/getAllVendorFromCategory`);
// 组织机构---获取新增上级单位
export const getUnit = () => http.get(`/api/coms`);

// 根据部门id查询部门明细
export const getDeptInfoById = id => http.get(`/dept/info/${id}`);

// 查询所属单位
export const unit = params => http.post(`/api/company/list`, params)
