<template>
  <div class="riskDialog">
    <div class="riskDialogTitle">
      风险启动文件
      <el-button
        type="primary"
        v-if="type == 'view'"
        size="mini"
        @click="showView"
        >审核记录</el-button
      >
    </div>
    <div class="startContent">
      <div
        class="start-title"
        v-if="
          riskControlLevel == 'headquarters_level' ||
            riskControlLevel == 'group_level'
        "
      >
        风险实施申请表
      </div>
      <el-form
        ref="dataForm"
        :rules="rules"
        :disabled="type == 'view' ? true : false"
        :model="dataForm"
        label-position="left"
      >
        <div
          class="implement-table"
          v-if="
            (isShowTable == true && riskControlLevel == 'headquarters_level') ||
              (isShowTable == true && riskControlLevel == 'group_level')
          "
        >
          <div class="row" style="font-weight: bold;">
            {{
              riskControlLevel == "headquarters_level"
                ? "总部级风险实施表"
                : riskControlLevel == "group_level"
                ? "集团级风险实施表"
                : ""
            }}
          </div>
          <div class="row">
            <div class="row-label">风险名称</div>
            <el-form-item prop="riskName" style="width:50%">
              <el-input
                v-model="dataForm.riskName"
                :disabled="true"
                auto-complete="off"
              ></el-input>
            </el-form-item>
            <div class="row-label row-towLabel">预计开始时间</div>
            <el-form-item prop="estimateStartDay">
              <el-input
                v-model="dataForm.estimateStartDay"
                disabled
                auto-complete="off"
              ></el-input>
            </el-form-item>
          </div>
          <div class="row" v-for="(item, i) in list" :key="i">
            <!-- <div class="row" v-for="(item, i) in dataForm.list" :key="i"> -->
            <div class="row-label">
              <i class="rquireClass">*</i>{{ item.label }}
            </div>
            <el-form-item :prop="item.value" class="textareaItem">
              <el-input
                :readonly="type == 'view' ? true : false"
                v-model="item[item.value]"
                type="textarea"
                resize="none"
                :disabled="item.disabled"
                auto-complete="off"
                @change="handelChange($event, item)"
                :autosize="{ minRows: 1, maxRows: 3 }"
              ></el-input>
            </el-form-item>
          </div>
          <div class="row" v-if="riskControlLevel == 'headquarters_level'">
            <div class="row-label">
              <i class="rquireClass">*</i>建设公司分管领导意见
            </div>
            <el-form-item prop="deputyChiefEngineer" class="textareaItem">
              <el-input
                :readonly="type == 'view' ? true : false"
                v-model="dataForm.deputyChiefEngineer"
                type="textarea"
                resize="none"
                :disabled="isJgFgDisabled"
                auto-complete="off"
                :autosize="{ minRows: 1, maxRows: 3 }"
              ></el-input>
            </el-form-item>
          </div>
          <div class="row" v-else-if="riskControlLevel == 'group_level'">
            <div class="row-label">
              <i class="rquireClass">*</i>建设公司分管副总工意见
            </div>
            <el-form-item prop="deputyChiefEngineer" class="textareaItem">
              <el-input
                :readonly="type == 'view' ? true : false"
                v-model="dataForm.deputyChiefEngineer"
                type="textarea"
                :disabled="IsFzgDisabled"
                resize="none"
                auto-complete="off"
                :autosize="{ minRows: 1, maxRows: 3 }"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row-form" v-if="isShowTable == true">
          <el-form-item
            prop="dialogFileList"
            class="textareaItem"
            label="集团签字文件 "
            label-width="210px"
            v-if="riskControlLevel == 'group_level'"
          >
            <FileUpload
              v-if="type != 'view'"
              @uploadChange="handleFileUpload"
              @delChange="handleFileDel"
              :value="dialogFileList"
              type="btn"
              :disabled="jtFileDisabled"
              :showFileList="!jtFileDisabled"
            ></FileUpload>
            <ul
              v-if="type == 'view' || jtFileDisabled == true"
              class="fileGroupBox"
            >
              <li v-for="(item, i) in dialogFileList" :key="i">
                {{ item.fileName }}
                <span @click="handleFileClick(item)">预览</span>
                <span @click="handleExport(item.fileUrl, item.fileName)"
                  >导出</span
                >
              </li>
            </ul>
          </el-form-item>
        </div>
        <!-- 项目部级/建管部级 -->
        <div
          class="row-form"
          v-if="
            riskControlLevel == 'project_depart_level' ||
              riskControlLevel == 'const_manage_dept_level' ||
              isShowTable == false
          "
        >
          <el-form-item
            prop="YjFileList"
            label="应急演练证明材料"
            label-width="210px"
            class="textareaItem"
          >
            <FileUpload
              v-if="type != 'view'"
              @uploadChange="YjFileUpload"
              @delChange="YjFileDel"
              :value="YjFileList"
              type="btn"
            ></FileUpload>
            <ul v-if="type == 'view'" class="fileGroupBox">
              <li v-for="(item, i) in YjFileList" :key="i">
                {{ item.fileName }}
                <span @click="handleFileClick(item)">预览</span>
                <span @click="handleExport(item.fileUrl, item.fileName)"
                  >导出</span
                >
              </li>
            </ul>
          </el-form-item>
        </div>
        <div
          class="row-form"
          v-if="
            riskControlLevel == 'project_depart_level' ||
              riskControlLevel == 'const_manage_dept_level' ||
              isShowTable == false
          "
        >
          <el-form-item
            prop="AllFileList"
            label="各方签字完成申请表"
            label-width="210px"
            class="textareaItem"
          >
            <FileUpload
              v-if="type != 'view'"
              @uploadChange="AllFileUpload"
              @delChange="AllFileDel"
              :value="AllFileList"
              type="btn"
            ></FileUpload>
            <ul v-if="type == 'view'" class="fileGroupBox">
              <li v-for="(item, i) in AllFileList" :key="i">
                {{ item.fileName }}
                <span @click="handleFileClick(item)">预览</span>
                <span @click="handleExport(item.fileUrl, item.fileName)"
                  >导出</span
                >
              </li>
            </ul>
          </el-form-item>
        </div>
        <!-- 集团级/总部级 -->
        <div
          class="row-form"
          v-if="
            (isShowTable == true && riskControlLevel == 'headquarters_level') ||
              (isShowTable == true && riskControlLevel == 'group_level')
          "
        >
          <el-form-item
            prop="YjFileList"
            label="应急演练证明材料"
            label-width="210px"
            class="textareaItem"
          >
            <FileUpload
              v-if="type != 'view'"
              @uploadChange="YjFileUpload"
              @delChange="YjFileDel"
              :disabled="constFileISDisabled"
              :value="YjFileList"
              :showFileList="!constFileISDisabled"
              type="btn"
            ></FileUpload>
            <ul
              v-if="type == 'view' || constFileISDisabled == true"
              class="fileGroupBox"
            >
              <li v-for="(item, i) in YjFileList" :key="i">
                {{ item.fileName }}
                <span @click="handleFileClick(item)">预览</span>
                <span @click="handleExport(item.fileUrl, item.fileName)"
                  >导出</span
                >
              </li>
            </ul>
          </el-form-item>
        </div>
        <div
          class="row-form"
          v-if="
            (isShowTable == true && riskControlLevel == 'headquarters_level') ||
              (isShowTable == true && riskControlLevel == 'group_level')
          "
        >
          <el-form-item
            prop="AllFileList"
            label="各方签字完成申请表"
            label-width="210px"
            class="textareaItem"
          >
            <FileUpload
              v-if="type != 'view'"
              @uploadChange="AllFileUpload"
              @delChange="AllFileDel"
              :disabled="constAffFileISDisabled"
              :value="AllFileList"
              :showFileList="!constAffFileISDisabled"
              type="btn"
            ></FileUpload>
            <ul
              v-if="type == 'view' || constAffFileISDisabled == true"
              class="fileGroupBox"
            >
              <li v-for="(item, i) in AllFileList" :key="i">
                {{ item.fileName }}
                <span @click="handleFileClick(item)">预览</span>
                <span @click="handleExport(item.fileUrl, item.fileName)"
                  >导出</span
                >
              </li>
            </ul>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="dialogFooter" v-if="type != 'view'">
      <span
        v-if="
          riskControlLevel == 'headquarters_level' ||
            riskControlLevel == 'group_level'
        "
      >
        <el-button
          size="medium"
          v-if="rowData.processStatus == 1 || isHiddenBtn == true"
          @click="cancel"
          >保 存</el-button
        >
      </span>
      <el-button
        size="medium"
        type="primary"
        @click="submit"
        v-if="
          riskControlLevel == 'headquarters_level' ||
            riskControlLevel == 'group_level'
        "
        >提交申请</el-button
      >
      <el-button
        size="medium"
        type="primary"
        @click="DeptSubmit"
        v-if="
          riskControlLevel == 'project_depart_level' ||
            riskControlLevel == 'const_manage_dept_level'
        "
        >提交申请</el-button
      >
    </div>
  </div>
</template>

<script>
import FileUpload from "./FileUpload";
import {
  riskStartApply,
  riskStartFileApply,
  riskstartfileList,
  riskStartEditStatus,
  // riskstartupstatusEdit,
  riskStartApplyEdit,
  riskStartapplyDetail
} from "@/api/riskManage";
import dayjs from "dayjs";
export default {
  components: {
    FileUpload
  },
  props: {
    type: {
      type: String,
      default: ""
    },
    riskControlLevel: {
      type: String,
      default: ""
    },
    rowData: {
      type: Object,
      default: () => {}
    },
    isShowTable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dataForm: {
        riskMasterUuid: "", //风险主清单uuid
        riskDeafUuid: "", //当前记录uuid
        estimateStartDay: "",
        riskName: "",
        adjustment: "",
        geologicalChange: "",
        implementChanges: "",
        designComments: "",
        constructionComments: "",
        generalContractor: "",
        supervisorContracto: "",
        supervisorLeaderContracto: "",
        buildProjectEngineer: "",
        chiefEngineer: "",
        deputyChiefEngineer: "",
        dialogFileList: [],
        YjFileList: [],
        AllFileList: []
      },
      list: [
        {
          label: "设计方案审核变更、调整情况",
          adjustment: "",
          disabled: true,
          roleName: "项目经理",
          roleId: "277",
          unitType: "construct",
          value: "adjustment"
        },
        {
          label: "周边环境、地质变化等情况",
          geologicalChange: "",
          disabled: true,
          roleName: "项目经理",
          roleId: "277",
          unitType: "construct",
          value: "geologicalChange"
        },
        {
          label: "实施方案变更情况",
          implementChanges: "",
          disabled: true,
          roleName: "项目经理",
          roleId: "277",
          unitType: "construct",
          value: "implementChanges"
        },
        {
          label: "设计单位项目负责人意见",
          designComments: "",
          disabled: true,
          roleName: "项目负责人",
          roleId: "351",
          unitType: "design",
          value: "designComments"
        },
        {
          label: "施工单位项目经理意见",
          constructionComments: "",
          disabled: true,
          roleName: "项目经理",
          roleId: "277",
          unitType: "construct",
          value: "constructionComments"
        },
        {
          label: "总承包部负责人意见",
          generalContractor: "",
          disabled: true,
          roleName: "项目负责人",
          roleId: "343",
          unitType: "contracting",
          value: "generalContractor"
        },
        {
          label: "监理单位项目总监意见",
          supervisorContracto: "",
          disabled: true,
          roleName: "项目总监",
          roleId: "366",
          unitType: "monitor",
          value: "supervisorContracto"
        },
        {
          label: "监理单位分管领导意见",
          supervisorLeaderContracto: "",
          disabled: true,
          roleName: "分管领导",
          roleId: "367",
          unitType: "monitor",
          value: "supervisorLeaderContracto"
        },
        {
          label: "建设公司项目工程师意见",
          buildProjectEngineer: "",
          disabled: true,
          roleName: "项目工程师",
          roleId: "364",
          unitType: "build",
          value: "buildProjectEngineer"
        },
        {
          label: "建设公司建管部总工意见",
          chiefEngineer: "",
          disabled: true,
          deptName: "建管部",
          roleName: "总工（建管部）",
          roleId: "328",
          unitType: "build",
          value: "chiefEngineer"
        }
      ],
      rules: {
        adjustment: [
          {
            required: true,
            message: "请输入设计方案审核变更、调整情况",
            trigger: "blur"
          }
        ],
        geologicalChange: [
          {
            required: true,
            message: "请输入周边环境、地质变化等情况",
            trigger: "blur"
          }
        ],
        implementChanges: [
          { required: true, message: "请输入实施方案变更情况", trigger: "blur" }
        ],
        designComments: [
          {
            required: true,
            message: "请输入设计单位项目负责人意见",
            trigger: "blur"
          }
        ],
        constructionComments: [
          {
            required: true,
            message: "请输入施工单位项目经理意见",
            trigger: "blur"
          }
        ],
        generalContractor: [
          {
            required: true,
            message: "请输入总承包部负责人意见",
            trigger: "blur"
          }
        ],
        supervisorContracto: [
          {
            required: true,
            message: "请输入监理单位项目总监意见",
            trigger: "blur"
          }
        ],
        supervisorLeaderContracto: [
          {
            required: true,
            message: "请输入监理单位分管领导意见",
            trigger: "blur"
          }
        ],
        buildProjectEngineer: [
          {
            required: true,
            message: "请输入建设公司项目工程师意见",
            trigger: "blur"
          }
        ],
        chiefEngineer: [
          {
            required: true,
            message: "请输入建设公司建管部总工意见",
            trigger: "blur"
          }
        ],
        deputyChiefEngineer: [
          {
            required: true,
            message: "请输入建设公司分管副总工意见",
            trigger: "blur"
          }
        ],
        dialogFileList: [
          { required: true, message: "请上传集团签字文件", trigger: "change" }
        ],
        YjFileList: [
          {
            required: true,
            message: "请上传应急演练证明材料",
            trigger: "change"
          }
        ],
        AllFileList: [
          {
            required: true,
            message: "请上传各方签字完成申请表",
            trigger: "change"
          }
        ]
      },
      unitType: "", //当前用户的单位类型
      dialogFileList: [],
      YjFileList: [], //应急演练证明材料
      AllFileList: [], //各方签字完成申请表
      firstSave: false,
      constFileISDisabled: true,
      constAffFileISDisabled: true,
      applyInfo: {},
      isEdit: false,
      roleList: [],
      roleIdList: [],
      deptName: "",
      isJgFgDisabled: true,
      IsFzgDisabled: true,
      jtFileDisabled: true,
      changeFile: [],
      user: {},
      isHiddenBtn: true
    };
  },
  async mounted() {
    const that = this;
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      this.unitType = user.companyCategory;
      this.user = user;
      this.roleIdList = user.roleIdList;
      this.roleList = user.roles.map(v => v.roleName);
      // console.log(this.roleList, "roleList");
      this.deptName = user.departmentName;
    }
    console.log(this.rowData, "风险启动页面当前行数据打印");
    // 判断当前数据是否提交过申请，下面字段有值说明提交过申请，需要回显
    if (
      this.rowData.riskStartImplementationApplication != null &&
      this.type != "view"
    ) {
      console.log("Gggggggggggg");
      this.isEdit = true;
      this.dataForm = this.rowData.riskStartImplementationApplication;
      this.applyInfo = this.rowData.riskStartImplementationApplication;
      this.list.forEach(el => {
        el[el.value] = this.applyInfo[el.value];
      });
      if (this.type == "edit") {
        // debugger;
        if (
          this.rowData.processStatus == 4 ||
          this.rowData.processStatus == 6 ||
          this.rowData.processStatus == 8
        ) {
          return;
        } else {
          this.getFileList();
        }
      } else {
        this.getFileList();
      }
      that.getFun();
    } else {
      console.log("不满足条件");
      that.getDetails();
    }
    // if (this.type == "view") {
    //   // dd
    //   this.getFileList();
    // }
  },
  methods: {
    getDetails() {
      const that = this;
      riskStartapplyDetail({ riskDeafUuid: this.rowData.riskDeafUuid }).then(
        res => {
          if (res.code == 0 && res.data) {
            that.isEdit = true;
            that.dataForm = res.data;
            that.applyInfo = res.data;
            that.list.forEach(el => {
              el[el.value] = that.applyInfo[el.value];
            });
            if (that.type == "edit") {
              if (
                that.rowData.processStatus == 4 ||
                that.rowData.processStatus == 6 ||
                that.rowData.processStatus == 8
              ) {
                return;
              } else {
                that.getFileList();
              }
            } else {
              that.getFileList();
            }
          } else {
            if (that.type == "edit") {
              if (
                that.rowData.riskLevelCode == "const_manage_dept_level" ||
                that.rowData.riskLevelCode == "project_depart_level"
              )
                return;
              if (
                that.rowData.processStatus == 4 ||
                that.rowData.processStatus == 6 ||
                that.rowData.processStatus == 8
              ) {
                return;
              } else {
                // if(taht.rowData.)
                that.getFileList();
              }
            } else {
              that.getFileList();
            }
          }
          that.getFun();
        }
      );
    },
    getFun() {
      const that = this;
      this.list.forEach((el, i) => {
        console.log("ffffff");
        // 判断当前用户所属单位类型与表格对应行一致，且值为0，考虑是否可填写
        if (
          el.unitType == that.unitType &&
          that.dataForm[el.value].length == 0
        ) {
          if ("deptName" in el) {
            if (that.deptName.includes("建设管理部")) {
              that.getIsDiabled(el, i);
            }
            // ddd
          } else {
            that.getIsDiabled(el, i);
          }
        } else {
          el.disabled = true;
        }
      });
      // console.log(that.roleList,"roleList");
      // console.log(that.dataForm.deputyChiefEngineer,"dataForm.deputyChiefEngineer");
      // console.log(that.rowData.riskLevelCode,"rowData.riskLevelCode");
      // console.log(that.unitType,"unitType");
      if (
        that.unitType == "build" &&
        (that.dataForm.deputyChiefEngineer == "" ||
          that.dataForm.deputyChiefEngineer == null) &&
        that.rowData.riskLevelCode == "headquarters_level"
      ) {
        // let st = that.roleList.some(v => v == "分管领导");
        let st = that.roleIdList.some(v => v == "365");
        if (st == true) {
          // console.log(that.dataForm.chiefEngineer, "chiefEngineer");
          // 判断当前行的上一行数据是否被填写，如果没有，则禁用当前行
          if (that.dataForm.chiefEngineer.length == 0) {
            that.isJgFgDisabled = true;
          } else {
            that.isJgFgDisabled = false;
          }
        } else {
          that.isJgFgDisabled = true;
        }
      } else {
        that.isJgFgDisabled = true;
      }
      if (
        that.unitType == "build" &&
        (that.dataForm.deputyChiefEngineer == "" ||
          that.dataForm.deputyChiefEngineer == null) &&
        that.rowData.riskLevelCode == "group_level"
      ) {
        // that.dataForm.deputyChiefEngineer.length == 0
        // let st = that.roleList.some(v => v == "分管副总工程师");
        let st = that.roleIdList.some(v => v == "331");
        if (st == true) {
          // 判断当前行的上一行数据是否被填写，如果没有，则禁用当前行
          if (that.dataForm.chiefEngineer.length == 0) {
            that.IsFzgDisabled = true;
          } else {
            that.IsFzgDisabled = false;
          }
        } else {
          that.IsFzgDisabled = true;
        }
      } else {
        that.IsFzgDisabled = true;
      }
      that.dataForm.estimateStartDay = dayjs().format("YYYY-MM-DD HH:mm:ss");
      that.dataForm.riskName = that.rowData.riskName;
    },
    getFileList() {
      const that = this;
      riskstartfileList({
        riskDeafUuid: this.rowData.riskDeafUuid,
        riskMasterUuid: this.rowData.riskMasterUuid,
        isDelete: 0
      }).then(res => {
        if (res.code == 0) {
          res.data.forEach(el => {
            el["name"] = el.fileName;
            el["url"] = el.fileUrl;
            if (el.fileType == 0) {
              that.dialogFileList.push(el);
              that.dataForm["dialogFileList"] = [];
              that.dataForm.dialogFileList.push(el);
            } else if (el.fileType == 1) {
              that.YjFileList.push(el);
              that.dataForm["YjFileList"] = [];
              that.dataForm.YjFileList.push(el);
            } else if (el.fileType == 2) {
              that.AllFileList.push(el);
              that.dataForm["AllFileList"] = [];
              that.dataForm.AllFileList.push(el);
            }
          });
          if (that.AllFileList.length == 0) {
            that.isHiddenBtn = false;
          }
          if (that.unitType == "build" && that.dialogFileList.length == 0) {
            // let st = that.roleList.some(v => v == "业主项目工程师");
            let st = that.roleIdList.some(v => v == "327");
            if (st == true) {
              // 判断当前行的上一行数据是否被填写，如果没有，则禁用当前行
              if (that.dataForm.deputyChiefEngineer.length == 0) {
                that.jtFileDisabled = true;
              } else {
                that.jtFileDisabled = false;
              }
            } else {
              that.jtFileDisabled = true;
            }
          } else {
            that.jtFileDisabled = true;
          }
          if (that.unitType == "construct" && that.YjFileList.length == 0) {
            let st = that.roleIdList.some(v => v == "277");
            if (st == true) {
              if (that.rowData.riskLevelCode == "headquarters_level") {
                // 判断当前行的上一行数据是否被填写，如果没有，则禁用当前行
                if (that.dataForm.deputyChiefEngineer.length == 0) {
                  that.constFileISDisabled = true;
                } else {
                  that.constFileISDisabled = false;
                }
              } else if (that.rowData.riskLevelCode == "group_level") {
                // 判断当前行的上一行数据是否被填写，如果没有，则禁用当前行
                if (that.dialogFileList.length == 0) {
                  that.constFileISDisabled = true;
                } else {
                  that.constFileISDisabled = false;
                }
              }
            } else {
              that.constFileISDisabled = true;
            }
          } else {
            that.constFileISDisabled = true;
          }
          if (that.unitType == "construct" && that.AllFileList.length == 0) {
            let st = that.roleIdList.some(v => v == "277");
            if (st == true) {
              if (that.rowData.riskLevelCode == "headquarters_level") {
                if (that.dataForm.deputyChiefEngineer.length == 0) {
                  that.constAffFileISDisabled = true;
                } else {
                  that.constAffFileISDisabled = false;
                }
              } else if (that.rowData.riskLevelCode == "group_level") {
                if (that.dialogFileList.length == 0) {
                  that.constAffFileISDisabled = true;
                } else {
                  that.constAffFileISDisabled = false;
                }
              }
              // 判断当前行的上一行数据是否被填写，如果没有，则禁用当前行
            } else {
              that.constAffFileISDisabled = true;
            }
          } else {
            that.constAffFileISDisabled = true;
          }
        }
      });
    },
    getIsDiabled(el, i) {
      // console.log(i, "ddd");
      // ss
      const that = this;
      // console.log(this.roleIdList, "roleIdlist");
      // console.log(el.roleId, "roleIdlist");
      let st = that.roleIdList.some(v => v == el.roleId);
      // let st = that.roleList.some(v => v == el.roleName);
      // console.log(st, "st");
      if (st == true) {
        el.disabled = false;
        // 前三行数据是首次进来施工单位进行填写，次数不适用以上判断上一行数据是否有值的逻辑，所以单独处理
        if (i == 0 || i == 1 || i == 2) {
          el.disabled = false;
        } else {
          // 判断当前行的上一行数据是否被填写，如果没有，则禁用当前行
          if (that.dataForm[that.list[i - 1].value].length == 0) {
            el.disabled = true;
          } else {
            el.disabled = false;
          }
        }
        if (that.dialogFileList.length == 0) {
          that.constFileISDisabled = true;
        }
      } else {
        el.disabled = true;
      }
      // console.log(el.disabled, "是否禁用");
    },
    showView() {
      // dd
      this.$emit("showStartRecord", this.rowData);
    },
    handelChange(event, obj) {
      this.dataForm[obj.value] = obj[obj.value];
    },
    // 集团签字文件上传
    handleFileUpload(e) {
      let params = {
        name: e.name,
        fileName: e.name,
        url: e.url + "," + e.realUrl,
        fileUrl: e.url + "," + e.realUrl,
        fileType: 0, //文件类型（0集团签字文件 1应急演练证明材料 2各方签字完成）
        riskDeafUuid: this.rowData.riskDeafUuid, // 当前记录uuid
        riskMasterUuid: this.rowData.riskMasterUuid, //风险主清单uuid
        riskName: this.rowData.riskName, //风险名称
        submitPerson: this.user.realName, //提交人
        submitPersonUuid: this.user.uuid, //提交人uuid
        companyId: this.user.companyId,
        departmentUuid: this.user.departmentId
        // isDelete: 0
      };
      this.dialogFileList.push(params);
      this.dataForm.dialogFileList = this.dialogFileList;
      this.changeFile.push(params);
    },
    // 应急演练证明材料上传
    YjFileUpload(e) {
      let params = {
        name: e.name,
        fileName: e.name,
        url: e.url + "," + e.realUrl,
        fileUrl: e.url + "," + e.realUrl,
        fileType: 1, //文件类型（0集团签字文件 1应急演练证明材料 2各方签字完成）
        riskDeafUuid: this.rowData.riskDeafUuid, // 当前记录uuid
        riskMasterUuid: this.rowData.riskMasterUuid, //风险主清单uuid
        riskName: this.rowData.riskName, //风险名称
        submitPerson: this.user.realName, //提交人
        submitPersonUuid: this.user.uuid, //提交人uuid
        companyId: this.user.companyId,
        departmentUuid: this.user.departmentId
      };
      this.YjFileList.push(params);
      this.dataForm.YjFileList = this.YjFileList;
      this.changeFile.push(params);
    },
    // 各方签字完成申请表上传
    AllFileUpload(e) {
      let params = {
        name: e.name,
        fileName: e.name,
        url: e.url + "," + e.realUrl,
        fileUrl: e.url + "," + e.realUrl,
        fileType: 2, //文件类型（0集团签字文件 1应急演练证明材料 2各方签字完成）
        riskDeafUuid: this.rowData.riskDeafUuid, // 当前记录uuid
        riskMasterUuid: this.rowData.riskMasterUuid, //风险主清单uuid
        riskName: this.rowData.riskName, //风险名称
        submitPerson: this.user.realName, //提交人
        submitPersonUuid: this.user.uuid, //提交人uuid
        companyId: this.user.companyId,
        departmentUuid: this.user.departmentId
      };
      this.AllFileList.push(params);
      this.dataForm.AllFileList = this.AllFileList;
      this.changeFile.push(params);
    },
    handleFileDel(e) {
      let index = this.dialogFileList.findIndex(v => v.url === e.url);
      this.dialogFileList.splice(index, 1);
      let index1 = this.changeFile.findIndex(v => v.url === e.url);
      this.changeFile.splice(index1, 1);
    },
    YjFileDel(e) {
      let index = this.YjFileList.findIndex(v => v.url === e.url);
      this.YjFileList.splice(index, 1);
      let index1 = this.changeFile.findIndex(v => v.url === e.url);
      this.changeFile.splice(index1, 1);
    },
    AllFileDel(e) {
      let index = this.AllFileList.findIndex(v => v.url === e.url);
      this.AllFileList.splice(index, 1);
      let index1 = this.changeFile.findIndex(v => v.url === e.url);
      this.changeFile.splice(index1, 1);
    },
    cancel() {
      const that = this;
      if (this.isEdit == false) {
        this.dataForm.riskMasterUuid = this.rowData.riskMasterUuid;
        this.dataForm.riskDeafUuid = this.rowData.riskDeafUuid;
        riskStartApply(this.dataForm)
          .then(res => {
            if (res.code == 0) {
              this.$emit("closeDialog");
            } else {
              this.$message.error("操作失败！");
            }
          })
          .catch(err => {
            console.log(err);
            this.$message.error("操作失败！");
          });
      } else {
        // let fileList = [];
        // fileList.push(...this.dialogFileList);
        // fileList.push(...this.YjFileList);
        // fileList.push(...this.AllFileList);
        // console.log(fileList, "fff");
        if (that.changeFile.length > 0) {
          riskStartFileApply(that.changeFile).then(res => {
            if (res.code == 0) {
              this.$emit("closeDialog");
            } else {
              this.$message.error("操作失败！");
            }
          });
        } else {
          riskStartApplyEdit(this.dataForm).then(res => {
            // console.log(res, "Sss");
            if (res.code == 0) {
              this.$message.success("保存成功");
              this.$emit("closeDialog");
            } else {
              this.$message.error("操作失败！");
            }
          });
        }
      }
    },
    submit() {
      // console.log("总部级，集团级");
      const that = this;
      // console.log(that, "dddddd");
      this.$refs.dataForm.validate(valid => {
        if (valid) {
          that.applyFun();
          // riskStartFileApply(that.changeFile).then(res => {
          //   if (res.cpde == 0) {
          //     console.log("dddddddd");
          //   }
          //   riskstartupstatusEdit({ processStatus: 2 }).then(res => {
          //     console.log(res, "sss");
          //   });
          // });
        }
      });
    },
    DeptSubmit() {
      // console.log("项目级，建管部级");
      const that = this;
      this.$refs.dataForm.validate(valid => {
        if (valid) {
          let statusInfo = {
            riskDeafUuid: this.rowData.riskDeafUuid, // 当前记录uuid
            riskMasterUuid: this.rowData.riskMasterUuid, //风险主清单uuid
            riskName: this.rowData.riskName, //风险名称
            processStatus: 2
          };
          let fileList = [];
          fileList.push(...this.YjFileList);
          fileList.push(...this.AllFileList);
          riskStartEditStatus({
            fileList: fileList,
            status: statusInfo
          }).then(res => {
            if (res.code == 0) {
              that.$emit("closeDialog");
              that.$message.success("操作成功");
            } else {
              this.$message.error("操作失败");
            }
          });
        }
      });
    },
    applyFun() {
      const that = this;
      let statusInfo = {
        riskDeafUuid: this.rowData.riskDeafUuid, // 当前记录uuid
        riskMasterUuid: this.rowData.riskMasterUuid, //风险主清单uuid
        riskName: this.rowData.riskName, //风险名称
        processStatus: 2
      };
      riskStartEditStatus({
        fileList: that.changeFile,
        status: statusInfo
      }).then(res => {
        if (res.code == 0) {
          that.$emit("closeDialog");
          that.$message.success("操作成功");
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    handleFileClick(record) {
      let obj = Object.assign({}, record);
      let url = obj.fileUrl.split(",")[0];
      const link = document.createElement("a");
      link.href = url;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
    handleExport(url, name) {
      let fileUrl = url.split(",")[1];
      this.downLoadFile(fileUrl, name);
    }
  }
};
</script>

<style lang="scss" scoped>
.riskDialogTitle {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.startContent {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  .start-title {
    font-weight: bold;
  }
  .implement-table {
    width: 100%;
    border: 1px solid #eeeeee;
    margin-top: 15px;
  }
  .row {
    min-height: 40px;
    display: flex;
    border-top: 1px solid #eeeeee;
    ::v-deep .el-input__inner {
      outline: none;
      border: none;
    }
    .row-label {
      width: 210px;
      padding: 0 5px;
      box-sizing: border-box;
      border-right: 1px solid #eeeeee;
      display: flex;
      align-items: center;
      cursor: default;
    }
    .row-towLabel {
      border-left: 1px solid #eeeeee;
    }
    .row-first {
      width: 80%;
    }
    .textareaItem {
      width: calc(100% - 210px);
      ::v-deep .el-textarea__inner {
        outline: none;
        // border: none;
        min-height: 41px !important;
        border: 1px solid #eeeeee;
        padding: 10px 15px;
      }
    }
  }
  ::v-deep .row .el-form-item.is-error .el-textarea__inner {
    border-color: #f56b6b;
    border-radius: 0;
  }
  ::v-deep .el-form-item__error {
    display: none;
  }
  .row:first-child {
    border-top: none;
    justify-content: center;
    align-items: center;
  }
  .row-file {
    border-top: none;
    margin-top: 15px;
    .row-label {
      border-right: none;
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 0;
  border: none;
}
.dialogFooter {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .el-button {
    margin: 0 30px;
  }
}
.startContent {
  min-height: 208px;
}
.row-form {
  margin-top: 20px;
}
.rquireClass {
  font-style: normal;
  color: rgb(245, 108, 108);
  margin-right: 4px;
  user-select: none;
}
.el-form--label-left .el-form-item__label {
  padding-left: 5px;
}
::v-deep .row-form .el-form-item__error {
  display: block !important;
}
.fileGroupBox {
  span {
    color: #3fa5e9;
    cursor: pointer;
    margin-left: 10px;
  }
}
</style>
