<template>
  <div class="container">
    <index-box :title="title">
      <div
        class="point-container scroll-wrapper overflow-y-scroll flex flex-col justify-around"
      >
        <el-progress
          :text-inside="true"
          :stroke-width="20"
          :percentage="70"
        ></el-progress>
        <div
          class="point-center scroll-wrapper overflow-y-scroll mt-2 mb-2 2xl:text-base xl:text-sm md:text-xs"
        >
          <p>工点名称：{{ pointName }}</p>
          <p>工点概况：{{ pointCenter }}</p>
        </div>
        <div class="point-release">
          <div class="release-content">气象信息</div>
        </div>
        <div class="weather-info flex justify-between pl-2 pr-2">
          <span class="flex flex-col flex-1 items-center">
            <span class="info-text"
              >{{ weatherInfo.temperature
              }}<span class="info-des">℃</span></span
            >

            <span class="info-tit">温度</span>
          </span>
          <el-divider direction="vertical"></el-divider>
          <span class="flex flex-col  flex-1 items-center">
            <span class="info-text"
              >{{ weatherInfo.windPower
              }}<span class="info-des">m/s</span></span
            >

            <span class="info-tit">风力</span>
          </span>
          <el-divider direction="vertical"></el-divider>
          <span class="flex flex-col  flex-1 items-center">
            <span class="info-text"
              >{{ weatherInfo.rain }}<span class="info-des">mm</span></span
            >

            <span class="info-tit">降雨</span>
          </span>
        </div>
      </div>
    </index-box>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import { getLineSiteWeather, getPointInfo } from "@/api/map";
export default {
  name: "weather",
  components: {
    IndexBox
  },
  data() {
    return {
      title: "工点基础信息",
      weatherInfo: {
        rain: "",
        temperature: "",
        windPower: ""
      },
      pointName: "",
      pointCenter: "暂无该工点的概况信息。"
    };
  },
  async created() {
    await this.queryLineSiteDetail(this.$route.query.id);
  },
  methods: {
    // 根据工点id查询详情
    queryLineSiteDetail(id) {
      getPointInfo(id).then(res => {
        if (res.code == 0 && res.data) {
          this.pointName = res.data.name;
          this.pointCenter = res.data.intro
            ? res.data.intro
            : "暂无该工点的概况信息。";
          this.getLineSiteWeatherData(res.data.uuid);
        }
      });
    },
    // 获取实时雨量数据
    getLineSiteWeatherData(uid) {
      const params = {
        lineId: "-",
        siteId: uid
      };
      getLineSiteWeather(params).then(res => {
        if (res.code == 0) {
          this.weatherInfo.rain = res.data.rain ? res.data.rain : "0.0";
          this.weatherInfo.temperature = res.data.temperature
            ? res.data.temperature
            : "0.0";
          this.weatherInfo.windPower = res.data.windPower
            ? res.data.windPower
            : "0.0";
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.point-container {
  height: 100%;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  .point-center {
    height: 155px;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 28px;
  }
  .point-release {
    padding: 10px 0;
    .release-content {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #5aa3e3;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &::before {
        display: block;
        content: "";
        width: 35%;
        height: 1px;
        background: #212f55;
      }
      &::after {
        display: block;
        content: "";
        width: 35%;
        height: 1px;
        background: #212f55;
      }
    }
  }
  .info-text {
    font-size: 24px;
    font-family: Arial;
    font-weight: bold;
    color: #72c040;
  }
  .info-des {
    font-size: 14px;
    color: #72c040;
  }
  .info-tit {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.6;
  }
  ::v-deep .el-divider--vertical {
    width: 1px;
    height: 50px;
    background: #ffffff;
    opacity: 0.1;
  }
  ::v-deep .el-progress-bar__inner {
    background-color: unset;
    background-image: linear-gradient(to right, #0a214e, #71be40);
  }
  ::v-deep .el-progress-bar__outer {
    background: #0e2553;
  }
}
</style>
