<template>
  <div>
    <div class="content mb-4" v-for="item in logList" :key="item.title">
      <div style="font-weight: bold; margin-bottom: 10px">
        <span>
          {{ item.operatorTime }}
        </span>
      </div>
      <div>
        <span>【{{ item.operatorPost }}-{{ item.operatorName }}】 </span
        ><span>{{ item.actionType }}{{ item.operationValue }}</span>
      </div>
    </div>
    <div style="display: flex; justify-content: center">
      <el-button type="primary" @click="sureSub">关闭</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    sureSub() {
      this.$emit("historyClose");
    },
  },
};
</script>

<style lang="scss" scoped></style>
