<template>
  <systemModal
    ref="formModal"
    :title="title"
    :showFooter="false"
    width="60%"
    @modalClose="handleClose"
  >
    <el-form
      v-loading="loading"
      :model="model"
      ref="modelForm"
      label-width="200px"
      class="demo-model"
      style="width: 80%"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="事件类型" prop="typeName">
            <el-input
              v-model="model.typeName"
              readonly
              placeholder="事件类型"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发生时间" prop="created">
            <el-input
              v-model="model.created"
              readonly
              placeholder="发生时间"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="上报人" prop="publisherName">
            <el-input
              v-model="model.publisherName"
              readonly
              placeholder="上报人"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="负责人" prop="chargeName">
            <el-input
              v-model="model.chargeName"
              readonly
              placeholder="负责人"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="地理位置" prop="xycoors">
            <el-input
              v-model="model.xycoors"
              readonly
              placeholder="地理位置"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工程项目名称" prop="siteName">
            <el-input
              v-model="model.siteName"
              readonly
              placeholder="工程项目名称"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="施工单位" prop="constructionUnitName">
            <el-input
              v-model="model.constructionUnitName"
              readonly
              placeholder="施工单位"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="监理单位" prop="supervisorUnitName">
            <el-input
              v-model="model.supervisorUnitName"
              readonly
              placeholder="监理单位"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="事件经过" prop="lineSite">
        <el-input
          readonly
          type="textarea"
          resize="none"
          placeholder="请输入作业内容、事发情形等"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="model.historyRecord"
        ></el-input>
      </el-form-item>
      <el-form-item label="伤亡情况" prop="modelNo">
        <el-input
          readonly
          type="textarea"
          resize="none"
          placeholder="请输入人员伤亡、直接经济损失和影响范围"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="model.casualties"
        ></el-input>
      </el-form-item>
      <el-form-item label="处置情况" prop="factory">
        <el-input
          readonly
          type="textarea"
          resize="none"
          placeholder="请输入应急救援、已采取和拟采取的措施等"
          v-model="model.disposal"
        ></el-input>
      </el-form-item>
      <el-form-item label="照片或附件：">
        <div class="files" style="display: flex;flex-direction: column;">
          <a
            v-for="(item, index) in imgs"
            :key="index"
            :href="item.url"
            target="_blank"
            style="color:#409EFF;display:block;"
          >{{ item.originalName }}</a
          >
        </div>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { detailsList } from "@/api/response";

export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "详情",
      disabled: false,
      model: {},
      imgs: [],
      dict: {},
      loading: false,
    };
  },
  filters: {},
  mounted() {},
  methods: {
    handleDetail(record) {
      this.queryDetailInfo(record.eventId);
    },
    // 详情数据请求
    async queryDetailInfo(id) {
      let params = {
        id: id,
      };
      detailsList(params).then((res) => {
        if (res.code == 0) {
          this.model = res.data;
          this.imgs = [];
          this.model.chargeName = res.data.chargeName
            ? res.data.chargeName
            : "";
          if (res.data.processList && res.data.processList.length) {
            res.data.processList.forEach((element) => {
              if (element.enclosure) {
                JSON.parse(element.enclosure).forEach((list) => {
                  this.imgs.push(list);
                });
              }
            });
          }
        }
      });
      this.$refs.formModal.handleOpen();
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}
::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
</style>
