<template>
  <div class="briefing">
    <el-dialog
      type="text"
      :title="title"
      :visible.sync="show"
      width="60%"
      :before-close="handleClose"
      center
      class="abow_dialog"
    >
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        v-loading="loading"
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路-工点"
          show-overflow-tooltip
        >
          <template slot-scope="scope"
            >{{ scope.row.lineName }}{{ scope.row.siteName }}</template
          >
        </el-table-column>
        <el-table-column align="center" prop="deadline" label="截止时间">
        </el-table-column>
        <el-table-column align="center" prop="fillTime" label="填报时间">
        </el-table-column>
        <el-table-column align="center" prop="fillUser" label="填报人员">
          <template slot-scope="scope">{{
            scope.row.fillUser ? scope.row.fillUser.name : ""
          }}</template>
        </el-table-column>
        <el-table-column align="center" prop="approvalState" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.approvalState == -2" style="color: #ccc"
              >超时</span
            >
            <span v-else-if="scope.row.approvalState == -1" style="color: red"
              >未填报</span
            >
            <span v-else-if="scope.row.approvalState == 2" style="color: red"
              >驳回</span
            >
            <span v-else-if="scope.row.approvalState == 0" style="color: green"
              >待审批</span
            >
            <span v-else style="color: #ccc">已完成</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleCheck(scope.row)"
              v-if="scope.row.approvalState > -1"
              >查看</el-button
            >
            <el-button type="text" @click="handleHistory(scope.row)"
              >历史操作记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <DetailModal ref="detailHistoryModal"></DetailModal>
    <Briefing
      ref="fillingModal1"
      @fillingSubmit="handleFillingSubmit"
    ></Briefing>
    <BriefingJG
      ref="fillingModal2"
      @fillingSubmit="handleFillingSubmit"
      @fingrest="handleFillingSubmit"
    ></BriefingJG>
  </div>
</template>

<script>
import { queryCurrentResponseList } from "@/api/response";
import DetailModal from "../resources/detailModal.vue";
import Briefing from "./briefing.vue";
import BriefingJG from "./briefingJG.vue";
export default {
  components: { DetailModal, Briefing, BriefingJG },
  data() {
    return {
      show: false,
      type: "",
      title: "三防工作简报",
      loading: false,
      tableData: [],
      rowData: null, // 选中行的数据
    };
  },
  props: ["responseRecordId"],
  methods: {
    // 填报列表弹窗打开入口
    handleOpen(record) {
      this.show = true;
      this.type = record.reportNameId;
      if (record.reportNameId == 140) {
        this.title = "三防工作简报";
      }
      this.loadData();
    },
    // 加载表格数据
    async loadData() {
      this.loading = true;
      const params = {
        reportType: "140",
        status: "",
        responseRecordId: this.responseRecordId,
        isApproval: 1,
      };
      let res = await queryCurrentResponseList(params);
      if (res.code == 0) {
        this.tableData = res.data;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    // 查看详情按钮
    handleCheck(record) {
      console.log(record.siteUuId);
      if (record.siteUuId) {
        this.$refs.fillingModal1.handleFilling(record, "read");
      } else {
        this.$refs.fillingModal2.handleFilling(record, "read");
      }
    },
    // 历史记录按钮
    handleHistory(record) {
      console.log(record);
      this.$refs.detailHistoryModal.historyHandle(record);
    },
    handleFillingSubmit() {
      this.loadData();
    },
    handleClose() {
      this.show = false;
      this.$emit("FillingmodalClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.briefong_auto {
  width: 90%;
  margin: auto;

  .briefong_one {
    .typhoon {
      font-size: 14px;
      color: #000000;
      font-weight: bold;
    }

    .defense {
      font-size: 14px;
      color: #000000;
      font-weight: bold;
    }
  }
}

.el-form-item {
  margin-bottom: 8px !important;
}

.content {
  width: 100%;
  height: 300px;
  border: 2px solid #e8e8e8;
  margin-top: 8px;
  position: relative;

  .water {
    color: #62b9e5;
    position: absolute;
    top: 37%;
    left: 104%;
  }

  .influen {
    color: #62b9e5;
    position: absolute;
    top: 37%;
    left: 104%;
  }

  .reovem {
    color: #62b9e5;
    position: absolute;
    top: 37%;
    left: 104%;
  }
}

.el-table {
  font-size: 12px !important;
}

.toimg {
  display: flex;
  margin-top: 8px;
  margin-left: 4px;

  .imglist {
    width: 60px;
    height: 60px;
    margin-left: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.poert {
  position: relative;

  .el-icon-circle-plus-outline {
    color: #62b9e5;
    position: absolute;
    top: 33%;
    left: 60%;
  }

  .el-icon-delete {
    color: #62b9e5;
    position: absolute;
    top: 33%;
    left: 65%;
  }

  .supplie {
    top: 33%;
    left: 85%;
  }

  .suppiet {
    top: 33%;
    left: 90%;
  }
}

.water {
  position: relative;

  .waeeter {
    color: #62b9e5;
    position: absolute;
    top: 29%;
    left: 45%;
  }

  .suddend {
    color: #62b9e5;
    position: absolute;
    top: 29%;
    left: 50%;
  }
}

.el-radio {
  margin-right: 150px !important;
}
</style>
