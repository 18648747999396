<template>
  <div class="duty-container flex flex-col">
    <div class="header">
      <div class="tabs flex flex-wrap bg-white p-2 mb-1">
        <el-button
          size="small"
          class="mb-2"
          v-for="(item, index) of responseData"
          :key="index"
          :type="responseEventActive == index ? 'primary' : ''"
          @click="handleResponseEventClick(index)"
          >{{ item.title }}</el-button
        >
      </div>
      <div class="proofinge_top p-4">
        <div class="proofi_left overflow-y-scroll h-full p-4">
          <el-timeline :reverse="false">
            <el-timeline-item
              v-for="(activity, index) in tabsData"
              :key="index"
              :timestamp="activity.operatorTime"
              placement="top"
            >
              <div>{{ activity.actionType }}{{ activity.operationValue }}</div>
              {{
                activity.actionType == "发布"
                  ? activity.record.originalAlertMessage
                  : activity.actionType == "结束"
                  ? activity.record.endRemark
                  : activity.record.relegationRemark
              }}
            </el-timeline-item>
          </el-timeline>
        </div>
        <div class="proofi_right flex items-center justify-center">
          <el-button
            type="primary"
            @click="handleResponseConfirm"
            :disabled="isConfirm"
            >{{ !isConfirm ? "确认" : "已确认" }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="bottom flex-1 p-4 bg-white mt-2">
      <div class="table" style="height: 90%">
        <div class="serch_proofinge flex justify-between">
          <el-form :inline="true" :model="queryModel" class="demo-form-inline">
            <el-form-item label="状态" clearable>
              <el-select
                v-model="queryModel.status"
                clearable
                placeholder="请选择状态"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="报表名称" clearable>
              <el-select
                v-model="queryModel.reportType"
                placeholder="请选择报表名称"
                clearable
              >
                <el-option
                  v-for="item in dict.reportForm"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearch">查询</el-button>
            </el-form-item>
          </el-form>
          <div v-if="situationType">
            <el-button type="primary" @click="disastersClick()"
              >灾害及恢复情况</el-button
            >
          </div>
        </div>
        <div class="table-wrapper" style="height: 90%">
          <el-table
            :data="tableData"
            style="width: 100%"
            height="100%"
            v-loading="loading"
          >
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="100"
            ></el-table-column>
            <el-table-column
              v-if="pageType == 1"
              align="center"
              prop="constructionName"
              label="单位"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              v-if="pageType == 3"
              align="center"
              prop="supervisionName"
              label="填报单位"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              v-if="pageType == 4"
              align="center"
              prop="constructionName"
              label="线路-工点（建管部）"
              :formatter="unitNameFormat"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              align="center"
              prop="reportNameId"
              label="报表名称"
              show-overflow-tooltip
              :width="300"
              :formatter="reportName"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="deadline"
              label="截至时间"
              show-overflow-tooltip
            ></el-table-column>
            <!-- <el-table-column
              align="center"
              prop="fillTime"
              label="填报时间"
              show-overflow-tooltip
            ></el-table-column> -->

            <el-table-column align="center" prop="fillUser" label="发布人员">
              <template slot-scope="scope">{{
                scope.row.fillUser ? scope.row.fillUser.name : ""
              }}</template>
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="approvalState"
              label="响应人"
              :formatter="formatState"
            ></el-table-column> -->
            <el-table-column
              align="center"
              prop="approvalState"
              label="状态"
              :formatter="formatState"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="explain"
              label="推送说明"
              show-overflow-tooltip
            ></el-table-column>
            <!-- <el-table-column
              align="center"
              prop="suggest"
              label="审核意见"
              show-overflow-tooltip
            ></el-table-column> -->
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="onReport(scope.row)"
                  type="text"
                  size="small"
                  v-if="
                    scope.row.approvalState == -1 ||
                      scope.row.approvalState == 2
                  "
                  v-has="'atPresent:fillIn'"
                  >填报</el-button
                >

                <!-- <el-button
                  type="text"
                  size="small"
                  @click="getHistoryData(scope.row)"
                  >历史操作</el-button
                > -->
                <el-button
                  type="text"
                  size="small"
                  v-if="scope.row.approvalState > -1"
                  @click="openView(scope.row)"
                  >查看
                </el-button>
                <!-- v-if="scope.row.approvalState == 0" -->
                <el-button
                  type="text"
                  size="small"
                  @click="approvalClick(scope.row)"
                  v-show="scope.row.approvalState == 0"
                  v-has="'atpresent:approval'"
                  >审批</el-button
                >
              </template>
            </el-table-column>
            <!-- <el-table-column align="center" label="操作">
              <template>
                <el-button type="text">历史操作</el-button>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
        <div class="pagination-wrapper flex justify-center mt-4" v-if="false">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="page.count"
            :page-count="page.total"
            :page-size="page.pageSize"
            :current-page="page.pageNo"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Statistical
      res="turet"
      :dialogVisible="dialogVisible"
      @occlude="occlude"
      :cancel="cancel"
    ></Statistical>
    <BriefingModal
      ref="fillingModal"
      @modalSubmit="handleModalSubmit"
    ></BriefingModal>
    <Briefing ref="fillingModal1" @fingrest="handleModalSubmit"></Briefing>
    <ApprovalModal
      :rows="rows"
      :approval="approval"
      :responseRecordId="responseRecordId"
      @modalClose="handleApprovalModalClose"
    ></ApprovalModal>
    <el-dialog
      type="text"
      :title="StatisticsTitle"
      :visible.sync="StatisticsDialog"
      width="50%"
      :before-close="handleClose"
      center
    >
      <Statistics :rows="rows" @closeDialog="closeDialog"></Statistics>
    </el-dialog>
    <!-- <el-dialog
      :title="WorkTitle"
      :visible.sync="WorkReportsDialog"
      width="60%"
      :before-close="handleClose"
      center
    >
      <WorkReports></WorkReports>
    </el-dialog> -->

    <CheckTable
      :checkis="checkis"
      :staData="staData"
      @tabelsret="tabelsret"
    ></CheckTable>
    <!-- 灾害及恢复情况弹框 -->
    <div class="disasters">
      <el-dialog
        title="灾害情况及恢复上报"
        v-if="disastersType"
        :visible.sync="disastersType"
        width="60%"
        :before-close="disastersClose"
      >
        <resumeReporting
          @downResume="downResume"
          :responseId="responseId"
          :resumeReportingType="resumeReportingType"
          :reportId="reportId"
          :headerTile="headerTile"
        ></resumeReporting>
      </el-dialog>
    </div>

    <!--    施工填报应急检查弹窗-->
    <el-dialog
      title="三防应急检查表"
      v-if="emerDialog"
      :visible.sync="emerDialog"
      width="60%"
    >
      <!--      <emergencyInspection
        v-if="emerDialog"
        :detail-data="detailInfo"
        @cancelInspection="cancelInspection"
      >
      </emergencyInspection>-->
      <fillingModal v-if="emerDialog" :detail-info="editData"></fillingModal>
    </el-dialog>

    <!--    施工审批应急检查弹窗-->
    <el-dialog
      title="三防应急检查表"
      v-if="approvalDialog"
      :visible.sync="approvalDialog"
      width="60%"
      @close="cancelInspect"
    >
      <emergencyInspect
        v-if="approvalDialog"
        :editData="editData"
        :dep-type="pageType"
        :ctitle="title"
        @cancelInspect="cancelInspect"
      ></emergencyInspect>
    </el-dialog>

    <!--    <el-dialog title="三防应急检查表" :visible.sync="approvalDialog" append-to-body width="50%">
      <inspectApproval v-if="approvalDialog" :operation="title"
      :editData="editData" @cancelApproval="cancelApproval"
        @successApproval="successApproval"></inspectApproval>
    </el-dialog>-->

    <el-dialog
      title="历史操作记录"
      :visible.sync="historyDialog"
      append-to-body
      width="30%"
    >
      <historyPage
        v-if="historyDialog"
        :log-list="historyData"
        @historyClose="historyClose"
      ></historyPage>
    </el-dialog>
    <!-- 施工单位 -->
    <el-dialog
      v-if="buildWriteDialog"
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="buildWriteDialog"
      width="80%"
      :before-close="buildWriteClose"
      center
      :close-on-click-modal="false"
    >
      <buildWrite
        ref="child"
        @buildWriteClose="buildWriteClose"
        :buildWriteId="buildWriteId"
        :buildWriteResponseId="buildWriteResponseId"
        :threeTitle="threeTitle"
      ></buildWrite>
    </el-dialog>
    <!--  监理审-->
    <el-dialog
      v-if="supervisoDialog"
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="supervisoDialog"
      width="80%"
      :before-close="supervisoClose"
      center
      :close-on-click-modal="false"
    >
      <superviso
        ref="supervisomodel"
        @supervisoClose="supervisoClose"
        :linetes="linetes"
        :pageType="pageType"
        :supervisoId="supervisoId"
        :supervisoResponseId="supervisoResponseId"
        :supervisoTitle="supervisoTitle"
      >
      </superviso>
    </el-dialog>
    <!--  建管部汇总-->
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="buildDepartmentDialog"
      width="80%"
      :before-close="buildDepartmentClose"
      center
      :close-on-click-modal="false"
    >
      <buildDepartment
        @buildDepartmentClose="buildDepartmentClose"
        :linetes="linetes"
        :pageType="pageType"
        :supervisionUuid="supervisionUuid"
        :buildDepartmentId="buildDepartmentId"
        :buildDepartmentResponseId="buildDepartmentResponseId"
        :buildmentTitle="buildmentTitle"
      ></buildDepartment>
    </el-dialog>
    <!-- 质安部 -->
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="qualityDialog"
      width="80%"
      :before-close="qualityClose"
      center
      :close-on-click-modal="false"
    >
      <quality
        @qualityClose="qualityClose"
        :qualityResponseId="qualityResponseId"
        :linetes="linetes"
      ></quality>
    </el-dialog>
    <DetailModal
      ref="detailModal"
      :linetes="linetes"
      :pageType="pageType"
    ></DetailModal>
    <!-- 工作简报填报列表 -->
    <FillingListModal
      ref="fillingListModal"
      :responseRecordId="responseRecordId"
      @FillingmodalClose="handleFillingmodalClose"
    ></FillingListModal>
    <!-- 三防工作简报监理查看弹窗 -->
    <CheckListModal
      ref="checkListModal"
      :responseRecordId="responseRecordId"
    ></CheckListModal>
  </div>
</template>

<script>
import {
  getCurrentResponseMsg,
  queryCurrentResponseList,
  currentResponseConfirm,
  getDetail,
  operatinghistoryList,
  queryCurrentResponseLog,
  queryUserConfirmResponse,
} from "@/api/response";

import Statistical from "./components/statistical.vue";
import Statistics from "./components/statis.vue";
import BriefingModal from "./components/briefingModal.vue";
import Briefing from "./components/briefingJG.vue";
import resumeReporting from "./components/resumeReporting.vue";
// import WorkReports from "./components/workReports.vue";
import ApprovalModal from "./components/approvalModal";
import CheckTable from "./components/checkTable.vue";
import emergencyInspect from "./components/emergencyInspect";
// import inspectApproval from "./components/inspectApproval";
import historyPage from "./resources/historyPage";
import buildWrite from "./workStatistics/buildWrite.vue";
import buildDepartment from "./workStatistics/buildDepartment.vue";
import superviso from "./workStatistics/superviso.vue";
import quality from "./workStatistics/quality.vue";
import fillingModal from "./components/fillingModal";
import {
  queryDictByLabel,
  queryDictNodesById,
  queryCurrentResponse,
} from "@/api/dict";
import { getDeptInfoById } from "@/api/org";
import DetailModal from "./resources/detailModal.vue";
import { ResLog, getResList } from "@/api/responseTem";
import FillingListModal from "./components/FillingListModal.vue";
import CheckListModal from "./components/checkListModal.vue";

export default {
  components: {
    Statistical,
    Statistics,
    BriefingModal,
    CheckTable,
    resumeReporting,
    ApprovalModal,
    emergencyInspect,
    // inspectApproval,
    historyPage,
    buildWrite,
    buildDepartment,
    superviso,
    quality,
    DetailModal,
    FillingListModal,
    CheckListModal,
    fillingModal,
    Briefing,
  },
  data() {
    return {
      responseRecordId: null,
      isConfirm: false,
      queryModel: {
        reportType: "",
        status: "",
      },
      responseData: [],
      tabsData: [],
      proofinge: {
        status: "",
        reportName: "",
        keyword: "",
        lineId: "",
        siteId: "",
        size: 10,
        page: 1,
      },
      linetes: [],
      worite: [],
      tableData: [],
      loading: false,
      options: [
        {
          value: -2,
          label: "超时",
        },
        {
          value: -1,
          label: "未填报",
        },
        {
          value: 0,
          label: "待审批",
        },
        {
          value: 1,
          label: "通过",
        },
        {
          value: 2,
          label: "驳回",
        },
      ],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      dict: {
        reportForm: [],
      },
      line: "",
      worksite: "",
      dialogVisible: false,
      StatisticsTitle: "广州市交通运输系统三防工作情况统计",
      StatisticsDialog: false,
      WorkTitle: "",
      WorkReportsDialog: false,
      bruefing: false,
      approval: false,
      checkis: false,
      rows: {},
      cancel: {},
      briefi: {},
      staData: {},
      role: "2",
      /* 灾害弹框 */
      disastersType: false,
      resumeReportingType: "add",
      reportId: "",
      responseId: 0,
      emerDialog: false,
      approvalDialog: false,
      detailInfo: {},
      title: "",
      editData: {},
      historyDialog: false,
      historyData: [],
      //  三防工作统计表施工填报
      buildWriteDialog: false,
      buildWriteId: "",
      // buildWriteResponseId: "",
      // buildWriteId: "",
      buildWriteResponseId: "",
      //  三防工作统计表监理审核
      supervisoDialog: false,
      supervisoId: "",
      supervisoResponseId: "",
      //  三防工作统计表建管部审批
      buildDepartmentDialog: false,
      buildmentTitle: "",
      buildDepartmentId: "",
      buildDepartmentResponseId: "",
      //  三防工作统计表质安部
      qualityDialog: false,
      qualityResponseId: "",
      // 是否显示灾害及恢复情况
      situationType: false,
      headerTile: "《建设工地抢险和灾害损失情况》",
      listData: [],
      responseEventActive: 0,
      pageType: 0, // 限制当前页面所属类型（1：施工、2：监理、3：建管、4：质安部、5：其他）
      supervisionUuid: "", //监管部的UUID
      threeTitle: "",
      supervisoTitle: "",
    };
  },
  created() {
    this.history();
    this.queryLineSiteTreeData();
    this.getDictData("三防响应工作报表", this.dict.reportForm);
    this.getResponseList();
    this.getButtoncl();
    this.queryDeptInfo();
  },
  methods: {
    // 查询当前登陆人的部门详情（可以获取部门类型）
    queryDeptInfo() {
      const user = JSON.parse(localStorage.getItem("user"));
      this.supervisionUuid = user.departmentId; //监管部的UUID
      getDeptInfoById(user.departmentId).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            this.pageType = res.data.roleType;
          }
        }
      });
    },
    async history() {
      const params = {
        page: 1,
        size: 1,
      };
      let res = await getResList(params);
      if (res.code == 0) {
        if (res.data.records.length > 0) {
          const record = res.data.records[0];
          let tempObj = {
            incidenceId: record.id,
            operationType: 384,
          };
          ResLog(tempObj).then((re) => {
            if (re.code == 0) {
              this.listData = re.data;
              this.listData.forEach((e) => {
                e.record = JSON.parse(e.record);
                console.log(e.record);
              });
              console.log(this.listData);
            } else {
              this.$message.error(res.message);
            }
          });
        }
      }
    },
    // 按钮状态切换
    handleResponseEventClick(active) {
      if (this.responseEventActive == active) {
        return false;
      }
      this.tabsData = JSON.parse(
        JSON.stringify(this.responseData[active].operatorRecord)
      );
      this.responseRecordId = this.responseData[active].responseRecordId;
      this.loadData();
      this.isConfirm = false;
      this.queryResponseConfirm();
      this.responseEventActive = active;
    },
    getButtoncl() {
      queryCurrentResponse().then((res) => {
        if (res.code == 0) {
          if (res.data.length != 0) {
            this.situationType = false;
          } else {
            this.situationType = true;
          }
        }
      });
    },
    cancelInspect() {
      this.approvalDialog = false;
      this.loadData();
    },
    getDetailData(id) {
      getDetail(id).then((res) => {
        this.detailInfo = res.data;
        this.emerDialog = true;
      });
    },
    cancelInspection() {
      this.emerDialog = false;
      this.loadData();
    },

    // 审批
    approvalClick(record) {
      this.rows = JSON.parse(JSON.stringify(record));
      if (record.reportNameId == 140) {
        this.approval = true;
      } else if (record.reportNameId == 348 || record.reportNameId == 347) {
        if (record.reportNameId == 348) {
          this.headerTile = "《建设工地抢险和灾害损失情况》";
        } else if (record.reportNameId == 347) {
          this.headerTile = "《建设工地灾害损失及恢复情况表》";
        }

        // 以审批的方式展示弹框 'modify'
        this.resumeReportingType = "modify";
        this.reportId = String(record.id);
        this.disastersType = true;
      } else if (record.reportNameId == 139) {
        this.title = "审批";
        this.editData = { ...record };
        this.approvalDialog = true;
      } else if (record.reportNameId == 141) {
        // 监理-审核
        // pageType: 0,限制当前页面所属类型（1：施工、2：监理、3：建管、4：质安部、5：其他）
        if (this.pageType == 2) {
          this.supervisoTitle = "audit";
          this.supervisoDialog = true;
          this.supervisoId = record.id;
          this.supervisoResponseId = record.responseId;
          this.$nextTick(() => {
            this.$refs.supervisomodel.getTableList();
          });
        } else if (this.pageType == 3) {
          // 建管
          this.buildmentTitle = "audit";
          this.buildDepartmentDialog = true;
          this.buildDepartmentId = record.id;
          this.buildDepartmentResponseId = record.responseId;
        }
        // this.supervisionUuid = record.supervisionUuid;
      }
    },
    // 查看详情
    openView(row) {
      console.log("查看", this.pageType);
      if (row.reportNameId == 139) {
        // 三防应急检查表
        this.title = "查看";
        this.editData = { ...row };
        this.approvalDialog = true;
      } else if (row.reportNameId == 140) {
        this.$refs.checkListModal.handleOpen(row);
      } else if (row.reportNameId == 141) {
        // pageType: 0,限制当前页面所属类型（1：施工、2：监理、3：建管、4：质安部、5：其他）
        if (this.pageType == 1) {
          // 施工查看
          this.threeTitle = "check";
          this.buildWriteDialog = true;
          this.buildWriteId = row.id;
          this.buildWriteResponseId = row.responseId;
          this.statisticsVal = row;
        } else if (this.pageType == 2) {
          // 监理查看
          this.supervisoTitle = "check";
          this.supervisoDialog = true;
          this.supervisoId = row.id;
          this.supervisoResponseId = row.responseId;
          this.$nextTick(() => {
            this.$refs.supervisomodel.getTableList();
          });
        } else if (this.pageType == 3) {
          // 监管查看
          this.buildmentTitle = "check";
          this.buildDepartmentDialog = true;
          this.buildDepartmentId = row.id;
          this.buildDepartmentResponseId = row.responseId;
        }
      }
    },
    getHistoryData(row) {
      if (row.reportNameId == "10000") {
        queryCurrentResponseLog(row.id).then((res) => {
          if (res.code == 0) {
            this.historyData = res.data;
            this.historyDialog = true;
          }
        });
      } else {
        let params = {
          incidenceId: row.id,
          operationType: row.reportNameId,
        };
        operatinghistoryList(params).then((res) => {
          if (res.code == 0) {
            this.historyData = res.data;
            this.historyDialog = true;
          }
        });
      }
    },
    historyClose() {
      this.historyDialog = false;
    },
    cancelApproval() {
      this.approvalDialog = false;
    },
    successApproval() {
      this.approvalDialog = false;
      this.loadData();
    },
    // 灾害及恢复情况填报 完成回调
    downResume() {
      this.getResponseList();
      this.loadData();
      this.disastersType = false;
    },
    // 灾害及恢复情况
    disastersClick() {
      this.resumeReportingType = "add";
      this.disastersType = true;
    },
    disastersClose() {
      this.disastersType = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    // 根据父字典label查询子级
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 查询应急响应列表
    async getResponseList() {
      getCurrentResponseMsg().then((res) => {
        if (res.code == 0) {
          // this.responseData
          if (res.data.length) {
            const tempData = res.data;
            for (let active of tempData) {
              for (let item of active.operatorRecord) {
                item.record = JSON.parse(item.record);
              }
            }
            this.responseRecordId = tempData[0].responseRecordId;
            this.queryResponseConfirm();
            this.loadData();
            this.responseData = tempData;
            this.tabsData = this.responseData[0].operatorRecord;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 状态回显
    formatState: function(row, column, cellValue) {
      switch (cellValue) {
        case -2:
          return "超时";
        case -1:
          return "未填报";
        case 0:
          return "待审批";
        case 1:
          return "通过";
        case 2:
          return "驳回";
      }
    },
    // 报表名称回显
    reportName(row) {
      const tempObj = this.dict.reportForm.find(
        (item) => item.id == row.reportNameId
      );
      if (tempObj) {
        return tempObj.label;
      } else {
        return "";
      }
    },
    //工点线路
    queryLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.linetes = lineSiteData;
      }
    },
    //选择线路
    linerte(val) {
      this.worksite = "";
      console.log(val);
      this.proofinge.lineId = val;
      this.linetes.forEach((item) => {
        if (item.id == val) {
          this.worite = item.nodes;
          console.log(item.nodes);
        }
      });
    },
    // 加载表格数据
    async loadData() {
      this.loading = true;
      const params = {
        reportType: this.queryModel.reportType,
        status: this.queryModel.status,
        responseRecordId: this.responseRecordId,
        isApproval: 0,
      };
      let res = await queryCurrentResponseList(params);
      if (res.code == 0) {
        this.tableData = res.data;
        // this.page.count = res.data.count;
        // this.page.pageSize = res.data.size;
        // this.page.total = res.data.total;
        if (this.tableData.length) {
          this.responseId = this.tableData[0].responseId;
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    present() {
      this.loadData();
    },
    handleResponseConfirm() {
      currentResponseConfirm({ responseId: this.responseRecordId }).then(
        (res) => {
          if (res.code == 0) {
            this.isConfirm = true;
            this.$message.success("操作成功");
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    queryResponseConfirm() {
      queryUserConfirmResponse({ responseId: this.responseRecordId }).then(
        (res) => {
          if (res.code == 0) {
            if (res.data > 0) {
              this.isConfirm = true;
            }
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    //工作报表填报点击
    onReport(val) {
      console.log(val)
      //三防工作统计表
      if (val.reportNameId == 141) {
        if (this.pageType == 1) {
          // 施工
          this.threeTitle = "write";
          this.buildWriteDialog = true;
          this.buildWriteId = val.id;
          this.buildWriteResponseId = val.responseId;
          // this.$nextTick(()=>{
          //   this.$refs.child.checkTimes()
          // })
        }
        // 工作简报相关
      } else if (val.reportNameId == 140) {
        if (this.pageType == 3) {
          // 建管
          // this.$refs.fillingModal.handleFilling(val);
          this.$refs.fillingModal1.handleFilling(val);
        } else if (this.pageType == 1) {
          // 施工
          this.$refs.fillingListModal.handleOpen(val);
        }
      } else if (val.reportNameId == 140 && val.status == 0) {
        this.checkis = true;
      } else if (val.reportNameId == 139 && this.pageType == 1) {
        // this.getDetailData(val.id)
        this.editData = val;
        this.emerDialog = true;
      }
    },
    // 审核弹窗关闭
    handleApprovalModalClose() {
      this.loadData();
      this.approval = false;
    },
    closeDialog() {
      this.StatisticsDialog = false;
    },
    handleClose() {
      this.StatisticsDialog = false;
    },
    occlude() {
      this.dialogVisible = false;
    },
    fingrest() {
      this.bruefing = false;
    },
    tabelsret() {
      this.checkis = false;
    },
    //查询
    onSearch() {
      this.loadData();
    },
    //页数
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    // 三防工作简报提交完成回调
    handleModalSubmit() {
      this.loadData();
    },
    qualityClose() {
      this.qualityDialog = false;
      this.loadData();
    },
    //  三防工作统计表监理审核
    supervisoClose() {
      this.supervisoDialog = false;
      this.loadData();
    },
    buildDepartmentClose() {
      this.buildDepartmentDialog = false;
      this.loadData();
    },
    buildWriteClose() {
      this.buildWriteDialog = false;
      this.loadData();
      this.$refs.child.clearData();
    },
    handleFillingmodalClose() {
      this.loadData();
    },
    // 角色类型type为4时单位名称转换
    unitNameFormat(row) {
      console.log(row);
      if (row.siteUuId) {
        return `${row.lineName}-${row.siteName}`;
      } else {
        return row.supervisionName;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  ::v-deep .el-button {
    margin: 10px 5px;
  }
}
.duty-container {
  height: 100%;

  .proofinge_top {
    background: rgba(255, 255, 255, 1);
    height: 300px;
    display: flex;
    justify-content: space-around;

    .proofi_left {
      width: 78%;
    }

    .proofi_right {
      width: 350px;
      height: 260px;
      background: rgba(67, 165, 233, 0.1);
      margin: auto;
      line-height: 300px;
    }
  }

  .crear {
    position: absolute;
    top: 17%;
    right: 9%;
  }

  .bottom {
    .table {
      width: 100%;
      .serch_proofinge {
        display: flex;
      }
    }
  }
}

.disasters ::v-deep.el-dialog__title {
  display: inline-block;
  width: 100%;
  text-align: center !important;
}
.table {
  display: block;
}
</style>
