<template>
  <div style="wieth: 100%; height: 100%" class="flex flex-col conLeft">
    <Weather></Weather>
    <WorkResponse></WorkResponse>
    <LargeEquipment ref="LargeEquipment"></LargeEquipment>
  </div>
</template>

<script>
import Weather from "@/views/ThreeProofings/WorkPointPage/leftPage/weather";
import WorkResponse from "@/views/ThreeProofings/WorkPointPage/leftPage/workResponse";
import LargeEquipment from "@/views/ThreeProofings/WorkPointPage/leftPage/largeEquipment";
export default {
  name: "routeList",
  components: { Weather, WorkResponse, LargeEquipment },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    fluData(res) {
      let that = this;
      that.$refs.RealTime.getwsData(res);
      that.$refs.Cumulative.getwsData(res);
    },
  },
};
</script>

<style lang="scss" scoped>
.conLeft {
  height: 100%;
  display: grid;
  grid-template-rows: 43% calc(25% - 20px) 32%;
  grid-row-gap: 10px;
}
</style>
