<template>
  <div class="min-h-full bg-white">
    <div class="flex justify-between p-4">
      <el-row class="flex">
        <el-cascader
          v-model="queryParams.lineSite"
          clearable
          :options="treeData"
          :props="props"
          :show-all-levels="false"
          placeholder="线路/标段/工点"
          collapse-tags
          style="margin-right: 10px"
          @change="handleLineSiteChange"
        ></el-cascader>

        <popup-tree-input
          class="mr-2"
          v-if="deviceType[0].children"
          :data="deviceType"
          :props="deptTreeProps"
          placeholder="设备类型"
          :prop="deviceName"
          :nodeKey="queryParams.equipmentType"
          :currentChangeHandle="lineTreeCurrentChangeHandle"
        ></popup-tree-input>

        <el-input
          class="mr-2"
          v-model="queryParams.equipmentName"
          clearablecesdsdsd
          placeholder="设备名称"
          style="width: 150px"
        ></el-input>
        <el-input
          class="mr-2"
          v-model="queryParams.deviceSerialNumber"
          clearablecesdsdsd
          placeholder="设备编号"
          style="width: 150px"
        ></el-input>
        <el-button type="primary" @click="getTableData">搜索</el-button>
      </el-row>
      <el-row>
        <!-- <el-button type="primary" @click="handleAdd">新增</el-button> -->
        <el-button type="primary">导出</el-button>
      </el-row>
    </div>
    <div class="px-4">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column type="index" width="55" header-align="center" align="center" label="序号"></el-table-column>
        <el-table-column prop="siteName" align="center" label="线路标段工点"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="设备名称">
          <!-- <template slot-scope="scope">
            <span
              style="color: #7073ff; cursor: pointer"
              @click="lookDetail(scope.row)"
              >{{ scope.row.equipmentName }}</span
            >
          </template>-->
        </el-table-column>
        <el-table-column prop="equipmentTypeName" align="center" label="设备类型"></el-table-column>
        <el-table-column prop="manufacturer" align="center" label="生产厂家"></el-table-column>
        <el-table-column prop="deviceSerialNumber" align="center" label="设备编号"></el-table-column>
        <el-table-column prop align="center" label="车牌信息"></el-table-column>
        <el-table-column prop align="center" label="是否合格证"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-button
                type="text"
                size="small"
                @click="handleView(scope.row)"
                v-has="'role:assign'"
                class="mr-4"
              >查看</el-button>
              <el-button
                type="text"
                size="small"
                @click="handleEdit(scope.row)"
                v-has="'role:assign'"
                class="mr-4"
              >编辑</el-button>
              <el-button
                type="text"
                size="small"
                @click="handleDelete(scope.row)"
                v-has="'role:del'"
              >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex justify-center mt-4">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryParams.pageNum"
          :page-size="queryParams.pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <EmergencyDetails
      :title="title"
      :type="dialogType"
      :ruleForm="ruleForm"
      :dialogVisible="handleVisible"
      @closeMainDialog="closeMainDialog"
    ></EmergencyDetails>
  </div>
</template>

<script>
import EmergencyDetails from "./components/EmergencyDetails";
import popupTreeInput from "@/views/DeviceManagent/components/popupTreeInput";
import { getDictData } from "@/api/dict";
import { baseURL } from "../../../src/api/http";
import {
  getEquInfoList,
  aiEquipmentBaseInfoDelete
} from "@/api/deviceManagement";
export default {
  components: {
    popupTreeInput,
    EmergencyDetails
  },
  data() {
    return {
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      deviceName: "",
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        equipmentType: "",
        equipmentName: "",
        equipmentStatus: "",
        deviceSerialNumber: "",
        enterTime: "",
        siteIdList: []
      },
      tableData: [],
      loading: false,
      total: 0,
      deviceType: [
        {
          children: []
        }
      ],
      flatTreeData: [],
      deptTreeProps: {
        label: "label",
        children: "children"
      },
      handleVisible: false,
      title: "",
      dialogType: "",
      ruleForm: {
        deviceUse: 1
      }
    };
  },
  created() {
    this.actionUrl = baseURL + "/deptQualifications/uploadZizhiFile";
    this.getLineSiteTreeData();
    // this.dictLabel();
    this.getEquSelect();
    this.getTableData();
  },
  methods: {
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
      this.flatArr(lineSiteData);
    },
    flatArr(data) {
      for (let item of data) {
        this.flatTreeData.push(item);
        if (item.children) {
          this.flatArr(item.children);
        }
      }
    },
    getEquSelect() {
      getDictData("equipment_type").then(res => {
        if (res.code == 0) {
          this.deviceType = res.data.map(item => {
            item.id = item.code;
            item.children = [];
            return item;
          });
          let largeEquipment = [];
          let mechanicalEquipment = [];
          getDictData("mechanicalEquipment").then(res => {
            mechanicalEquipment = res.data;
            this.deviceType.forEach(item => {
              if (item.intro === "mechanicalEquipment") {
                item.children = mechanicalEquipment.map(item => {
                  item.id = item.code;
                  return item;
                });
              }
            });
          });
          getDictData("largeEquipment").then(res => {
            largeEquipment = res.data;
            this.deviceType.forEach(item => {
              if (item.intro === "largeEquipment") {
                item.children = largeEquipment.map(item => {
                  item.id = item.code;
                  return item;
                });
              }
            });
          });
        }
      });
    },
    getTableData() {
      this.loading = true;
      getEquInfoList(this.queryParams).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    lineTreeCurrentChangeHandle(data) {
      if (!data.children) {
        this.deviceName = data.intro;
        this.queryParams.equipmentType = data.id;
        this.getTableData();
      }
    },
    handleLineSiteChange(record) {
      console.log(record);
      this.queryParams.siteIdList = [];
      for (let item of record) {
        this.queryParams.siteIdList.push(item[item.length - 1]);
      }
    },
    handleCurrentChange(val) {
      this.queryParams.pageNum = val;
      this.getTableList();
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getTableList();
    },
    handleView(data) {
      // SS
      this.handleVisible = true;
      this.dialogType = "查看";
      this.title = data.equipmentName;
    },
    handleEdit(data) {
      this.dialogType = "编辑";
      this.title = data.equipmentName;
      this.handleVisible = true;
    },
    closeMainDialog() {
      this.handleVisible = false;
    },
    handleDelete(data) {
      this.$confirm("确认删除选中记录吗？", "提示", {}).then(() => {
        aiEquipmentBaseInfoDelete({ id: data.id }).then(res => {
          if (res.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success"
            });
            this.getTableData();
          } else {
            this.$message({
              message: "操作失败, " + res.msg,
              type: "error"
            });
          }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
// ss
</style>
