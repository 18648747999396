<template>
  <div class="material-container h-full">
    <el-form
      ref="formData"
      size="medium"
      :rules="rules"
      :model="formData"
      :loading="loading"
      label-width="90px"
    >
      <el-form-item label="隐患类别:" prop="parentCategoryName">
        <el-input
          v-model="formData.parentCategoryName"
          placeholder="请输入隐患类别"
          style="width:100%"
          :disabled="
            submitType == 'addSon' ||
              submitType == 'view' ||
              (submitType == 'edit' && pageData.categoryType == '1')
          "
        ></el-input>
      </el-form-item>
      <el-form-item label="排查类型:" prop="screeningType">
        <el-select
          v-model="formData.screeningType"
          clearable
          style="width:100%"
          placeholder="请选择排查类型"
          :disabled="
            submitType == 'addSon' ||
              submitType == 'view' ||
              (submitType == 'edit' && pageData.categoryType == '1')
          "
        >
          <el-option
            v-for="item of checkType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="隐患子类:"
        prop="categoryName"
        v-if="
          (submitType == 'addSon' && pageData.categoryType == '0') ||
            (submitType == 'view' && pageData.categoryType == '1') ||
            (submitType == 'edit' && pageData.categoryType == '1')
        "
      >
        <el-input
          v-model="formData.categoryName"
          placeholder="请输入隐患子类"
          style="width:100%"
          :disabled="submitType == 'view'"
        ></el-input>
      </el-form-item>
      <el-form-item label="关联工法:">
        <el-select
          v-model="formData.region"
          style="width:100%"
          placeholder="请选择关联工法"
          :disabled="submitType == 'view'"
        >
        </el-select>
      </el-form-item>
      <el-form-item label="分部工程:">
        <el-select
          v-model="formData.region"
          style="width:100%"
          placeholder="请选择分部工程"
          :disabled="submitType == 'view'"
        >
        </el-select>
      </el-form-item>
      <el-form-item label="排序:">
        <el-input
          v-model.number="formData.sort"
          placeholder="请输入"
          style="width:100%"
          :disabled="submitType == 'view'"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialogFooter">
      <el-button size="medium" @click="cancel" v-show="submitType !== 'view'"
        >取 消</el-button
      >
      <el-button
        size="medium"
        type="primary"
        @click="submit"
        v-show="submitType !== 'view'"
        >确 定</el-button
      >
    </div>
  </div>
</template>

<script>
// 字典
import { getDictData } from "@/api/dict";
// 新增,编辑
import { categoryAdd, categoryEdit } from "@/api/hdanger";
export default {
  props: {
    pageData: {
      type: Object,
      default: () => {},
    },
    classes: {
      type: Number,
    },
    submitType: {
      type: String,
    },
  },
  data() {
    return {
      formData: {
        categoryName: "",
        parentCategoryName: "",
        screeningType: "",
        categoryType: "",
        region: "",
        sort: "",
      },
      checkType: [],
      loading: false,
      rules: {
        categoryName: [
          { required: true, message: "请输入隐患子类", trigger: "blur" },
        ],
        parentCategoryName: [
          { required: true, message: "请选择隐患类型", trigger: "change" },
        ],
        screeningType: [
          { required: true, message: "请选择排查类型", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    "pageData.id": {
      handler(val) {
        if (val) {
          this.getData();
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
  },
  created() {},
  mounted() {
    this.getDictList();
    // this.getData();
  },
  methods: {
    // 查看/编辑数据回显
    getData() {
      // 先判断是父类还是子类
      if (this.pageData.categoryType == "0") {
        // 父类
        if (this.submitType == "edit" || this.submitType == "view") {
          this.formData = { ...this.pageData };
          this.formData.parentCategoryName = this.formData.categoryName;
        } else if (this.submitType == "addSon") {
          const formList = { ...this.pageData };
          this.formData.parentCategoryName = formList.categoryName;
          this.formData.screeningType = formList.screeningType;
          this.formData.categoryName = "";
          this.formData.sort = "";
        }
      } else if (this.pageData.categoryType == "1") {
        // 子类
        if (this.submitType == "edit" || this.submitType == "view") {
          this.formData = { ...this.pageData };
        }
      } else {
        const th = this;
        Object.keys(this.formData).forEach(function(key) {
          th.formData[key] = "";
        });
        this.$refs.formData.resetFields();
      }
    },
    // 字典  screeningType 隐患排查类型
    getDictList() {
      let label = "screeningType";
      this.checkType = [];
      getDictData(label).then((res) => {
        if (res.code == 0) {
          res.data.forEach((element) => {
            this.checkType.push({
              label: element.intro,
              value: element.code,
            });
          });
        }
      });
    },

    submit() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.loading = true;
          var copyForm = Object.assign({}, this.formData);
          if (this.pageData.categoryType == "0") {
            // 父
            if (this.submitType == "edit") {
              copyForm.categoryName = this.formData.parentCategoryName;
              delete copyForm.pid;
            } else if (this.submitType == "add") {
              copyForm.categoryName = this.formData.parentCategoryName;
              copyForm.categoryType = "0";
              delete copyForm.pid;
            } else if (this.submitType == "addSon") {
              copyForm.categoryName = this.formData.categoryName;
              copyForm.categoryType = "1";
              copyForm.pid = this.pageData.id;
            }
          } else if (this.pageData.categoryType == "1") {
            // 子
            if (this.submitType == "edit") {
              copyForm.categoryName = this.formData.categoryName;
              copyForm.pid = this.pageData.pid;
            }
          } else {
            copyForm.categoryName = this.formData.parentCategoryName;
            copyForm.categoryType = "0";
          }

          if (this.submitType == "add" || this.submitType == "addSon") {
            copyForm.id = "";
            categoryAdd(copyForm)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.cancel();
                } else {
                  this.$message.error(res.message);
                }
              })
              .catch((res) => {
                this.$message.error(res.message);
              });
          } else if (this.submitType == "edit") {
            delete copyForm.parentCategoryName;

            categoryEdit(copyForm)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.cancel();
                } else {
                  this.$message.error(res.message);
                }
              })
              .catch((res) => {
                // this.cancel();
                this.$message.error(res.message);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancel() {
      this.reset();
      this.$emit("CloseData");
    },
    reset() {
      // this.$refs['formData'].resetFields()
      // 比上方获取demo的清理方式更彻底
      const th = this;
      Object.keys(this.formData).forEach(function(key) {
        th.formData[key] = "";
      });
      this.$refs.formData.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
</style>
