/**
 * axios的封装
 */
import axios from "axios"
// import { MessageBox } from "element-ui";
import { Message } from "element-ui"
import router from "@/router"

let baseURL = "http://47.95.238.93:9901"
if (process.env.NODE_ENV === "development") {
  baseURL = "/wsapi"
} else {
  baseURL = "/wsapi"
}

// defaults config

axios.defaults.baseURL = baseURL
axios.defaults.timeout = 60 * 1000 * 60
axios.defaults.headers.common["Authorization"] = ""
axios.defaults.headers.common["Content-Type"] = "application/json;charset=utf8"

const httpInit = (pre) => {
  let http = axios.create({
    baseURL: baseURL + pre,
  })
  //  TODO 优化白名单和请求头设置
  const whiteList = ["gzdt-ucenter/wsapi/login"]
  http.interceptors.request.use(
    async (config) => {
      let token = ""
      if (whiteList.find((item) => item === config.url)) {
        token = ""
      } else {
        token = localStorage.getItem("jwtToken")
      }
      Object.assign(config.headers, {
        gzMetro_uc_online: token,
      })
      if (config.method === "delete" || config.method === "get") {
        config.data = config.data || true
      }

      if (
        config.data &&
        typeof config.data === "object" &&
        !(config.data instanceof Array) &&
        !(config.data instanceof FormData)
      ) {
        //TODO 优化拦截器对参数过滤
        let temp = {}
        let key
        for (key in config.data) {
          if (
            config.data[key] ||
            config.data[key] === 0 ||
            config.data[key] === ""
          ) {
            temp[key] = config.data[key]
          }
        }
        config.data = temp
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  http.interceptors.response.use(
    (res) => {
      let json = res && res.data
      return Promise.resolve(json)
    },
    (error) => {
      console.log(error.response.data.message)
      if (error.response) {
        // 请求已发出，但服务器响应的状态码不在 2xx 范围内
        if (error.response.code === 9999) {
          // TODO 权限不足的统一处理
          console.log(error)
        } else if (
          error.response.status == 401 &&
          error.response.data.code == 9302
        ) {
          // MessageBox.confirm(error.response.data.message, "提示", {
          //   confirmButtonText: "重新登录",
          //   showCancelButton: false,
          //   type: "warning",
          // }).then(() => {
          //   localStorage.removeItem("jwtToken");
          //   localStorage.removeItem("jwtTokenExpire");
          //   localStorage.removeItem("lineSiteTree");
          //   localStorage.removeItem("USER_AUTH");
          //   localStorage.removeItem("USER_BTN_AUTH");
          //   localStorage.removeItem("user");
          //   localStorage.removeItem("DetailsTree");
          //   router.push("/Login");
          // });
          Message({
            message: error.response.data.message,
            type: "warning",
            duration: 5 * 1000
          })
          localStorage.removeItem("jwtToken")
          localStorage.removeItem("jwtTokenExpire")
          localStorage.removeItem("lineSiteTree")
          localStorage.removeItem("USER_AUTH")
          localStorage.removeItem("USER_BTN_AUTH")
          localStorage.removeItem("user")
          localStorage.removeItem("DetailsTree")
          router.push("/Login")
        }
        return Promise.reject(error.response.data)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", error.message)
      }

      return Promise.reject(error)
    }
  )

  return http
}

/**
 * 处理多条件搜索的请求地址
 *
 * @param {String} prePath 固定url
 * @param {[String]} argNames 参数名称数组
 * @param {*} params 传入的参数
 */
const getFormat = (prePath, argNames, params) => {
  let url = prePath
  if (argNames.length) {
    argNames.forEach((argName) => {
      let arg = params[argName]
      if (arg || arg === 0) {
        arg
      } else {
        arg = "-"
      }
      url += "/" + arg
    })
  }
  return url
}
export { httpInit, getFormat, baseURL }