<template>
  <div class="info-container">
    <index-box :title="title">
      <div class="scroll-wrapper overflow-y-scroll">
        <ul class="list">
          <li class="item" v-for="(data, index) in warnData" :key="index">
            <p class="item-content">
              <span class="title" v-if="data.title && data.title !=''">【{{ data.title }}】</span>
              {{ data.content }}
            </p>
            <p class="footer" v-if="data.publishdate && data.publishdate !=''">
              {{ data.sender_CNNAME }}{{ data.publishdate }}发布
            </p>
            <el-divider></el-divider>
          </li>
        </ul>
      </div>
    </index-box>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import { getActiveWarn } from "@/api/proof";
export default {
  name: "info-container",
  components: {
    IndexBox,
  },
  data() {
    return {
      title: "政府应急发布",
      warnData: [],
    };
  },
  mounted() {
    this.getData();
    const timer = setInterval(() => {
      this.getData();
    }, 300000);
    this.$once("hook:beforeDestroy", () => {
      clearInterval(timer);
    });
  },
  methods: {
    async getData() {
      let res = await getActiveWarn();
      if (res.code == 0) {
        this.warnData = res.data.data;
        // res.data.data.forEach((e) => {
        //   if (
        //     e.sender_CNNAME.indexOf("广州") != -1 &&
        //     e.title.indexOf("森林") == -1
        //   ) {
        //     this.warnData.push(e);
        //   }
        // });
        if (this.warnData.length == 0) {
          this.warnData.push({
            id: "500204591",
            indentifier: "",
            publishdate: "",
            title: "",
            unit_CODE: "",
            sender_CNNAME: "",
            warning_LEVEL_NAME: "",
            category: "",
            icon: "",
            area_CODE: "",
            districts_NAME: "",
            publish_OBJECT: "",
            category_CNNAME: "",
            alert_TYPE: "2",
            alert_TYPE_NAME: "",
            content:
              "近三日未发布台风、暴雨、高温等气象预警信息",
            unit_ID: "",
            is_FQYJ: "",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.info-container {
  .scroll-wrapper {
    height: 100%;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    ul,
    ol,
    li {
      list-style: none;
    }
    .list {
      .item {
        .item-content {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #fff;
          line-height: 28px;
          .title {
            color: #43a5e9;
          }
        }
        .footer {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
          opacity: 0.4;
        }
      }
      ::v-deep .el-divider {
        background: #43a5e9;
        opacity: 0.2;
      }
    }
  }
}
</style>