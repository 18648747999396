<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
             label-width="120px" class="demo-model">
      <el-row>
        <el-col :span="16">
          <el-form-item label="标题" prop="itemName">
            <el-input v-model="ruleForm.taskTitle" disabled placeholder="标题"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查类型" prop="itemName">
            <el-input v-model="ruleForm.screeningTypeName" disabled placeholder="排查类型"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查单位" prop="itemName">
            <el-input v-model="ruleForm.screeningCompanyName" disabled placeholder="排查单位"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查人员" prop="itemName">
            <el-input v-model="ruleForm.personLiable" disabled placeholder="排查人员"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查时间" prop="itemName">
            <el-input v-model="ruleForm.screeningTime" disabled placeholder="排查时间"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="线路" prop="itemName">
            <el-input v-model="ruleForm.lineName" disabled placeholder="线路"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="分部/标段" prop="itemName">
            <el-input v-model="ruleForm.branchName" disabled placeholder="分部/标段"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工点" prop="itemName">
            <el-input v-model="ruleForm.workAreaName" disabled placeholder="工点"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="隐患类别" prop="itemName">
            <el-input v-model="ruleForm.categoryPname" disabled placeholder="隐患类别"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="隐患子类/子项" prop="itemName">
            <el-input v-model="ruleForm.categoryName" disabled placeholder="隐患子类/子项"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="隐患条目" prop="itemName">
            <el-input v-model="ruleForm.itemName" disabled placeholder="隐患条目"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="整改单位" prop="itemName">
            <el-input v-model="ruleForm.reformCompanyName" disabled placeholder="整改单位"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="整改期限" prop="itemName">
            <el-input v-model="ruleForm.deadline" disabled placeholder="整改期限"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="隐患描述" prop="itemName">
            <el-input v-model="ruleForm.hdInfo" disabled placeholder="隐患描述"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="整改要求" prop="itemName">
            <el-input v-model="ruleForm.reformRequire" disabled placeholder="整改要求"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="现场图片" prop="itemName">
            <!--              <el-input v-model="ruleForm.itemName" type="textarea" placeholder="隐患条目名称"></el-input>-->
            <div class="img-list" v-if="ruleForm.fileList">
              <el-image
                  v-for="(item,index) in ruleForm.fileList"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item.fileUrl"
                  :preview-src-list="ruleForm.fileList.map(item => item.fileUrl)">
              </el-image>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "troubleDetailDay",
  props : {
    ruleForm : {
      default : () => {}
    }
  },
  data(){
    return {
      rules : {}
    }
  }
}
</script>

<style scoped>

</style>
