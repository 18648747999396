<template>
  <div class="min-h-full bg-white">
    <div class="flex justify-between p-4">
      <el-row class="flex">
        <el-cascader
            v-model="queryParams.lineSite"
            clearable
            :options="treeData"
            :props="props"
            :show-all-levels="false"
            collapse-tags
            style="margin-right: 10px"
            @change="handleLineSiteChange"
        ></el-cascader>


        <el-select v-model="ruleForm.deviceUse" class="mr-2" placeholder="请选择">
          <el-option label="施工设备" :value="0"></el-option>
          <el-option label="应急设备" :value="1"></el-option>
        </el-select>

        <popup-tree-input
            v-if="deviceType[0].children"
            :data="deviceType"
            :props="deptTreeProps"
            placeholder="设备类型"
            class="mr-2"
            :prop="deviceName"
            :nodeKey="queryParams.equipmentType"
            :currentChangeHandle="lineTreeCurrentChangeHandle"
        ></popup-tree-input>

        <el-input
            class="mr-2"
            v-model="queryParams.equipmentName"
            clearablecesdsdsd
            placeholder="设备名称"
            style="width: 120px"
        ></el-input>

        <el-input
            class="mr-2"
            v-model="queryParams.equipmentName"
            clearablecesdsdsd
            placeholder="设备编号"
            style="width: 120px"
        ></el-input>

        <el-date-picker
            v-model="queryParams.date"
            class="mr-2"
            type="date"
            placeholder="进场时间">
        </el-date-picker>

        <el-select
            class="mr-2"
            v-model="queryParams.craneAlarmReason"
            clearable
            placeholder="请选择审核状态"
        >
          <el-option
              v-for="item in examineState"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>

        <el-button type="primary" @click="findData">搜索</el-button>

      </el-row>

      <el-row>
        <el-button type="primary" @click="handleAdd" v-if="deptType === 'construct' ">注册</el-button>
        <el-button type="primary" @click="handleImport" v-if="deptType === 'construct' || deptType == 0 ">批量导入</el-button>
        <el-button type="primary" @click="handleExport">导出</el-button>
        <el-button type="primary" @click="handleAgree"
                   v-if="deptType === 'monitor' && multipleSelection.length > 0 ">批量同意</el-button>
        <el-button type="primary" @click="handleReject"
                   v-if="deptType === 'monitor' && multipleSelection.length > 0 ">批量驳回</el-button>
      </el-row>
    </div>

    <div class="px-4">
      <el-table :data="tableData" style="width: 100%" v-loading="loading"  @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column type="index" width="55" header-align="center" align="center" label="序号"> </el-table-column>
        <el-table-column prop="equipmentTypeName" align="center" label="线路标段工点">
        </el-table-column>
        <el-table-column prop="equipmentName" align="center" label="设备名称"></el-table-column>
        <el-table-column prop="propertyRightUnit" align="center" label="设备用途">
        </el-table-column>
        <el-table-column prop="siteName" align="center" label="设备类型"></el-table-column>
        <el-table-column prop="enterTime" align="center" label="生产商家"></el-table-column>
        <el-table-column prop="codeImage" align="center" label="进场时间"></el-table-column>
        <el-table-column prop="codeImage" align="center" label="特种作业人员"></el-table-column>
        <el-table-column prop="codeImage" align="center" label="联系方式"></el-table-column>
        <el-table-column prop="codeImage" align="center" label="注册时间"></el-table-column>
        <el-table-column prop="codeImage" align="center" label="审核状态">
          <template slot-scope="scope">
            {{ scope.row.state }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-button
                  type="text"
                  size="small"
                  @click="handleDetail(scope.row)"
                  v-has="'role:assign'"
                  class="mr-4"
              >查看
              </el-button>
              <el-button
                  type="text"
                  size="small"
                  v-if="deptType === 'construct'"
                  @click="handleEdit(scope.row)"
                  v-has="'role:assign'"
                  class="mr-4"
              >编辑
              </el-button>
              <el-button
                  type="text"
                  size="small"
                  v-if="deptType === 'construct'"
                  @click="handleDelete(scope.row)"
                  v-has="'role:del'"
              >删除
              </el-button>
              <el-button
                  type="text"
                  size="small"
                  v-if="deptType === 'monitor'"
                  @click="handleAgree(scope.row)"
                  v-has="'role:del'"
              >同意
              </el-button>
              <el-button
                  type="text"
                  size="small"
                  v-if="deptType === 'monitor'"
                  @click="handleReject(scope.row)"
                  v-has="'role:del'"
              >驳回
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex justify-center mt-4">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
        width="90%"
        :title="title"
        :visible.sync="handleVisible"
        class="editDialog"
        append-to-body
    >
      <registerDialog v-if="handleVisible" :title="title"></registerDialog>
    </el-dialog>

    <el-dialog
        width="50%"
        :title="stateTitle"
        :visible.sync="stateVisible"
        class="editDialog"
        append-to-body
    >
      <el-steps  :active="1" finish-status="success">
        <el-step title="已完成"></el-step>
        <el-step title="进行中"></el-step>
        <el-step title="步骤 3"></el-step>
      </el-steps>

      <div class="device-info">
        <div class="site">线路工点：2号线1工区纪念堂站</div>
        <div class="device-type">设备类型：龙门吊</div>
        <div class="site">设备名称：纪念堂1号龙门吊</div>
      </div>


      <div class="block">
        <el-timeline>
          <el-timeline-item timestamp="2018/4/12" placement="top">
            <el-card>
              <div class="timeline-content">
                XX单位-单位名称-用户姓名（用户角色）-已驳回申请
              </div>
              <div class="timeline-desc">
                <span>描述:------------------</span> <span class="preview">附件预览</span>
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>

    </el-dialog>


    <el-dialog
        width="50%"
        title="驳回意见"
        :visible.sync="rejectVisible"
        class="editDialog"
        append-to-body
    >
      <el-form ref="form" :model="rejectForm" label-width="80px">
        <el-form-item label="驳回意见">
          <el-input v-model="rejectForm.name"></el-input>
        </el-form-item>
        <el-form-item label="附件说明">
          <Uploader></Uploader>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click.native="rejectVisible = false ">取消</el-button>
        <el-button type="primary" @click.native="submitReject">提交</el-button>
      </div>

    </el-dialog>

    <el-dialog
        width="40%"
        :visible.sync="downVisible"
        class="editDialog"
        append-to-body>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-model">
        <el-row>
          <el-col span="12">
            <el-form-item label="所属工点" prop="siteId">
              <el-cascader
                  v-model="ruleForm.siteId"
                  clearable
                  :options="treeData"
                  :props="props"
                  :show-all-levels="false"
                  collapse-tags
                  @change="handleLineSiteChangeAdd"
              ></el-cascader>
            </el-form-item>
          </el-col>

          <el-col span="12">
            <el-form-item label="设备用途" prop="equipmentType">
              <el-select v-model="ruleForm.deviceUse" placeholder="请选择">
                <el-option label="施工设备" :value="0"></el-option>
                <el-option label="应急设备" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col span="12">
            <el-form-item label="设备类型" prop="equipmentType">
              <el-select v-model="ruleForm.deviceUse" placeholder="请选择">
                <el-option label="施工设备" :value="0"></el-option>
                <el-option label="应急设备" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col span="12">
            <el-form-item label="设备型号" prop="equipmentType">
              <el-select v-model="ruleForm.deviceUse" placeholder="请选择">
                <el-option label="施工设备" :value="0"></el-option>
                <el-option label="应急设备" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col span="12">
            <el-form-item style="text-align: center">
              <el-button type="primary" v-has="'drill:add'" @click="downTemplate">下载Excel导入模板</el-button>
            </el-form-item>
          </el-col>
          <el-col span="12">
            <el-form-item style="text-align: center">
              <el-upload
                  action="action"
                  :http-request="uploadFile"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  :limit="1"
              >
                <el-button type="primary">上传Excel导入文件</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import popupTreeInput from "@/views/DeviceManagent/components/popupTreeInput";
import registerDialog from "@/views/DeviceManagent/components/registerDialog";
import Uploader from "@/views/DeviceManagent/components/Uploader";
import {getDictData} from "@/api/dict";
import {queryDeptDetail} from "@/api/stand";
export default {
  name: "DeviceRegister",
  components : {
    popupTreeInput,
    registerDialog,
    Uploader
  },
  data(){
    return{
      queryParams : {},
      treeData : [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true,
      },
      deviceType : [
        {
          children: [],
        }
      ],
      deptTreeProps: {
        label: "label",
        children: "children"
      },
      deviceName : "",
      examineState : [
        {
          label : "审核中",
          value : 0
        },  {
          label : "未通过",
          value : 1
        },  {
          label : "已通过",
          value : 2
        },
      ],
      tableData : [],
      loading : false,
      total : 0,
      title : "",
      stateTitle : "",
      handleVisible : false,
      stateVisible : false,
      rejectVisible : false,
      downVisible : false,
      rejectForm : {},
      deptType : null,
      multipleSelection : [],
      ruleForm : {},
      rules : {},
      fileList : []
    }
  },
  created() {
    this.getLineSiteTreeData();
    this.getEquSelect();
    this.getDeptDetail();
  },
  methods : {
    getDeptDetail() {
      console.log(JSON.parse(localStorage.getItem("user")));
      var userInfo = JSON.parse(localStorage.getItem("user"));
      queryDeptDetail(userInfo.companyId).then((res) => {
        console.log(res);
        this.deptType = res.data ? res.data.category : "0";
      });
    },
    getLineSiteTreeData () {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"))
      if (lineSiteData) {
        this.treeData = lineSiteData
      }
    },
    getEquSelect() {
      getDictData("equipment_type").then((res) => {
        if (res.code == 0) {
          this.deviceType = res.data.map(item => {
            item.id = item.code;
            item.children = [];
            return item
          })
          let largeEquipment = []
          let mechanicalEquipment = []
          getDictData("mechanicalEquipment").then((res) => {
            mechanicalEquipment = res.data
            this.deviceType.forEach((item) => {
              if (item.intro === "mechanicalEquipment") {
                item.children = mechanicalEquipment.map(item => {
                  item.id = item.code;
                  return item
                })
              }
            })
          })
          getDictData("largeEquipment").then((res) => {
            largeEquipment = res.data
            this.deviceType.forEach((item) => {
              if (item.intro === "largeEquipment") {
                item.children = largeEquipment.map(item => {
                  item.id = item.code;
                  return item
                })
              }
            })
          })
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    findData(){

    },
    handleAdd(){
      this.title = "新增";
      this.handleVisible = true;
    },
    handleAgree(){
      this.$confirm('是否确定同意选中的设备进场申请吗','提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

      })
    },
    handleReject(){
      this.rejectVisible = true;
    },
    submitReject(){

    },
    handleImport(){
      this.downVisible = true;
    },
    handleExport(){

    },
    handleEdit(){

    },
    handleDetail(){

    },
    handleDelete(){

    },
    handleCurrentChange(val){
      this.queryParams.pageNum = val;
      this.getTableList();
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val
      this.getTableList()
    },
  }
}
</script>

<style lang="scss" scoped>
.device-info{
  display: flex;
  margin-top: 20px;
  .device-type{
    margin: 0 30px;
  }
}
.block{
  margin-top: 30px;
  .timeline-desc{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .preview{
      color: #2999f3;
      cursor: pointer;
    }
  }
}
</style>
