<template>
  <div class="min-h-full bg-white">
    <div class="flex justify-between p-4">
      <el-row class="flex">
        <el-cascader
          v-model="queryParams.lineSite"
          clearable
          :options="treeData"
          :props="props"
          :show-all-levels="false"
          collapse-tags
          style="margin-right: 10px;"
          @change="handleLineSiteChange"
        ></el-cascader>

        <el-date-picker
          v-model="timeRange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          type="daterange"
          style="margin-right: 10px"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>

        <el-select
          v-model="queryParams.warnStatus"
          style="margin-right: 10px"
          clearable
          placeholder="请选择报警类型"
        >
          <el-option
            v-for="(item, index) in warnTypeList"
            :key="index"
            :label="item.label"
            :value="item.code"
          ></el-option>
        </el-select>

        <el-button type="primary" @click="findPage">搜索</el-button>
      </el-row>

      <el-row>
        <el-button type="primary" @click="handleAdd">新增</el-button>
        <el-button type="primary" @click="handleExport">导出</el-button>
      </el-row>
    </div>

    <div class="px-4">
      <el-table
        size="mini"
        height="630"
        :data="warnList"
        border
        v-loading="loading"
        :row-style="{ height: 59 + 'px' }"
        style="overflow-x: auto; overflow-y: auto"
        id="table"
      >
        <el-table-column
          width="60"
          type="selection"
          align="center"
        ></el-table-column>
        <el-table-column
          label="序号"
          type="index"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="site"
          header-align="center"
          align="center"
          label="工点"
        ></el-table-column>
        <el-table-column
          prop="deviceName"
          header-align="center"
          align="center"
          label="设备名称"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="clickable" @click="lookNamePop(scope.row, 'Leditor')">
              {{ scope.row.deviceName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="equipmentName" align="center" label="起吊载重"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="起吊幅度"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="起吊风速"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="起吊力矩百分比"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="起吊水平度"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="起吊回转角度"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="落吊载重"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="落吊幅度"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="起吊时间"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="落吊时间"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="预警内容"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="实际值"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-button
                type="text"
                size="small"
                @click="handleEdit(scope.row)"
                v-has="'role:assign'"
                class="mr-4"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="handleDelete(scope.row)"
                v-has="'role:del'"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="toolbar" style="padding: 10px">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="refreshPageRequest"
          :current-page.sync="queryParams.pageNum"
          :page-size="queryParams.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="totalSize"
          style="text-align: right"
        ></el-pagination>
      </div>
    </div>

    <!-- Dialog -->
    <el-dialog
      :title="title"
      width="60%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :before-close="handleDialogClose"
    >
      <el-form
        :rules="title == '详情' ? {} : rules"
        :model="dataForm"
        ref="dataForm"
        @keyup.enter.native="submitForm()"
        label-width="100px"
        :size="size"
        style="text-align: left; margin-right: 5px; overflow-y: auto"
        :disabled="title == '详情'"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="所属工点" prop="siteId">
              <el-cascader
                v-model="dataForm.siteId"
                clearable
                :options="treeData"
                :props="propsAdd"
                style="width: 100%"
                :show-all-levels="false"
                collapse-tags
                @change="handleLineSiteChangeAdd"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备名称" prop="deviceId">
              <el-select
                v-model="dataForm.deviceName"
                clearable
                style="width: 100%;"
                placeholder="设备名称"
                :disabled="title == '编辑'"
              >
                <el-option
                  v-for="item in equipmentList"
                  :key="item.equipmentName"
                  :label="item.equipmentName"
                  :value="item.equipmentName"
                  @click.native="geteqMsg(item)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="起吊时间" prop="deviceId">
              <el-input v-model="dataForm.deviceId" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="落吊时间" prop="workType">
              <el-input
                  v-model="dataForm.capacity"
                  placeholder="落吊时间"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="起吊载重" prop="capacity">
              <el-input
                v-model="dataForm.capacity"
                placeholder="起吊载重"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="落吊载重" prop="loadMoment">
              <el-input
                v-model="dataForm.loadMoment"
                placeholder="落吊载重"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item prop="amplitude" label="起吊力矩百分比">
              <el-input
                v-model="dataForm.amplitude"
                placeholder="起吊力矩百分比"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="落吊力矩百分比" prop="levelness">
              <el-input
                v-model="dataForm.levelness"
                placeholder="落吊力矩百分比"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="windSpeed" label="起吊幅度">
              <el-input
                v-model="dataForm.windSpeed"
                placeholder="起吊幅度"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="落吊幅度" prop="rotationAngle">
              <el-input
                v-model="dataForm.rotationAngle"
                placeholder="落吊幅度"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="起吊水平度" prop="warnStatus">
              <el-input
                  v-model="dataForm.deviceLon"
                  placeholder="起吊水平度"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="落吊水平度" prop="sensorStatus">
              <el-input
                  v-model="dataForm.deviceLon"
                  placeholder="落吊水平度"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="起吊风速" prop="createTime">
              <el-input
                  v-model="dataForm.deviceLon"
                  placeholder="起吊风速"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="落吊风速" prop="deviceLon">
              <el-input
                v-model="dataForm.deviceLon"
                placeholder="落吊风速"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="起吊回转角度" prop="deviceLat">
              <el-input
                v-model="dataForm.deviceLat"
                placeholder="起吊回转角度"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="落吊回转角度" prop="deviceLat">
              <el-input
                v-model="dataForm.deviceLat"
                placeholder="落吊回转角度"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="落吊臂架仰角(动臂塔机)" prop="deviceLat">
              <el-input
                v-model="dataForm.deviceLat"
                placeholder="落吊臂架仰角(动臂塔机)"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备位置X" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="设备位置X"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备位置Y" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="设备位置Y"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预报警状态" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="预报警状态"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="工作时间" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="工作时间"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="传感器状态" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="传感器状态"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="累计工作时间" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="累计工作时间"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="本次工作时长" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="本次工作时长"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="累计循环次数" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="累计循环次数"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="title != '详情'">
        <el-button :size="size" @click="handleDialogClose">取消</el-button>
        <el-button :size="size" type="primary" @click="submitForm()"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  crawlerDataSaveOrUpdate,
  crawlerDataDelete,
  getEquInfoList,
  crawlerDataPage,
  crawlerDataExport
} from "@/api/deviceManagement";
import dayjs from "dayjs";
import { getDictData } from "@/api/dict";

export default {
  name: "EquipmentManagement",
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        siteIdList: [],
        deviceName: ""
      },
      treeData: [],
      timeRange: [],
      selection: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      warnList: [],
      loading: false,
      totalSize: 0,
      envid: "",
      title: "",
      lonlat: [],
      dialogVisible: false,
      lookNamePoptPPE: true,
      editLoading: true,
      flatTreeData: [],
      dataForm: {
        capacity: "",
        loadMoment: "",
        amplitude: "",
        levelness: "",
        windSpeed: "",
        rotationAngle: "",
        site: "",
        siteId: "",
        deviceId: "",
        equipmentBaseId: "",
        deviceName: "",
        workType: "",
        workTypeName: "",
        warnStatus: "",
        warnStatusName: "",
        sensorStatus: "",
        sensorStatusName: ""
      },
      propsAdd: {
        children: "children",
        label: "name",
        value: "uuid"
      },
      filters: {
        pageNum: 1,
        pageSize: 10000,
        siteIdList: [],
        equipmentType: "crawlerCrane"
      },
      baseList: [],
      equipmentList: [],
      rules: {
        siteId: [
          { required: true, message: "所属工点不能为空", trigger: "blur" }
        ],
        deviceId: [
          { required: true, message: "设备名称不能为空", trigger: "blur" }
        ]
      },
      size: "small",
      warnTypeList: [],
      sensorStatusList: [],
      operateList: []
    };
  },
  created() {
    this.getLineSiteTreeData();
    this.getList();
    this.dictLabel();
  },
  methods: {
    geteqMsg(data) {
      console.log(data, "da");
      this.dataForm.deviceId = data.deviceSerialNumber;
      this.dataForm.equipmentBaseId = data.id;
      this.dataForm.deviceName = data.equipmentName;
    },
    getOperateMsg(data) {
      this.dataForm.workType = data.code;
      this.dataForm.workTypeName = data.label;
    },
    getWarnMsg(data) {
      this.dataForm.warnStatus = data.code;
      this.dataForm.warnStatusName = data.label;
    },
    getsensorMsg(data) {
      this.dataForm.sensorStatus = data.code;
      this.dataForm.sensorStatusName = data.label;
    },
    getEquipment() {
      let params = {
        pageSize: 10000000,
        pageNum: 1,
        siteIdList: [this.dataForm.siteId],
        equipmentType: "crawlerCrane"
      };
      getEquInfoList(params).then(res => {
        this.equipmentList = res.data.list;
      });
    },
    getList() {
      if (this.timeRange && this.timeRange.length != 0) {
        this.queryParams.startTime = this.timeRange[0] + " 00:00:00";
        this.queryParams.endTime = this.timeRange[1] + " 23:59:59";
      } else {
        this.queryParams.startTime = null;
        this.queryParams.endTime = null;
      }
      this.loading = true;
      crawlerDataPage(this.queryParams).then(res => {
        if (res.code == 200) {
          this.warnList = res.data.list;
          this.totalSize = res.data.total;
          this.loading = false;
        }
      });
    },

    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
      this.flatArr(lineSiteData);
    },
    handleLineSiteChange(record) {
      console.log(record);
      this.queryParams.siteIdList = [];
      for (let item of record) {
        this.queryParams.siteIdList.push(item[item.length - 1]);
      }
    },
    // 显示编辑界面
    handleEdit(row) {
      this.title = "编辑";
      this.dialogVisible = true;
      this.dataForm = { ...row };
      this.getEquipment();
    },
    // 删除
    handleDelete(row) {
      let id = (row && row.id) || this.multipleSelection;
      this.$confirm("确认删除选中记录吗？", "提示", {
        type: "warning"
      }).then(() => {
        crawlerDataDelete(id).then(res => {
          // this.findTreeData()
          if (res.code == "200") {
            this.getList();
            this.$message({ message: "删除成功", type: "success" });
          }
        });
      });
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    //翻页
    refreshPageRequest(pageNum) {
      this.queryParams.pageNum = pageNum;
      this.getList();
    },
    findPage() {
      this.getList();
    },
    handleExport() {
      this.$confirm("确定导出履带吊监测数据吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        crawlerDataExport(this.queryParams).then(res => {
          const aLink = document.createElement("a");
          let blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8"
          });
          aLink.href = URL.createObjectURL(blob);
          aLink.download = "履带吊监测数据.xlsx";
          aLink.click();
          document.body.appendChild(aLink);
        });
      });
    },
    handleAdd() {
      this.title = "新增";
      this.lonlat = [];
      this.equipmentList = [];
      // this.dataForm = {}
      this.resetForm();
      const date = new Date();
      this.dataForm.createTime = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
      this.dialogVisible = true;
      this.lookNamePoptPPE = true;
      if (this.$refs["dataForm"]) {
        this.$refs["dataForm"].clearValidate();
      }
    },
    dictLabel() {
      let dictArray = [
        "crawler_crane_warn_type",
        "crawler_crane_sensor_status",
        "crawler_operate_status"
      ];
      dictArray.forEach(item => {
        this.getDictData(item);
      });
    },
    getDictData(label) {
      getDictData(label).then(res => {
        if (res.code == 0) {
          switch (label) {
            case "crawler_crane_warn_type":
              this.warnTypeList = res.data;
              break;
            case "crawler_crane_sensor_status":
              this.sensorStatusList = res.data;
              break;
            case "crawler_operate_status":
              this.operateList = res.data;
              break;
          }
        }
      });
    },
    handleLineSiteChangeAdd(record) {
      let siteUuId = record[record.length - 1];
      this.dataForm.siteId = siteUuId;
      this.dataForm.site = this.flatTreeData.filter(
        item => item.uuid === siteUuId
      )[0].name;
      this.getEquipment();
    },
    flatArr(data) {
      for (let item of data) {
        this.flatTreeData.push(item);
        if (item.children) {
          this.flatArr(item.children);
        }
      }
    },
    handleDialogClose() {
      this.dialogVisible = false;
      this.resetForm();
    },
    resetForm() {
      this.dataForm = {
        deviceName: null,
        craneHeight: null,
        craneRange: null,
        craneTiltAngle: null,
        craneWindSpeed: null,
        craneTorquePercentage: null,
        craneElevatingCapacity: null,
        craneRotation: null,
        craneTime: null,
        alarmReason: null,
        remark: null,
        winddirection: null,
        windspeed: null,
        label: null,
        site: null,
        siteId: null,
        deviceId: null,
        createTime: "",
        deviceLon: null,
        deviceLat: null
      };
    },
    // 表单提交
    submitForm() {
      // console.log(this.dataForm.id)
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            const that = this;
            let params = Object.assign({}, that.dataForm);
            crawlerDataSaveOrUpdate(params).then(res => {
              this.editLoading = false;
              if (res.code == 200) {
                this.$message({ message: "操作成功", type: "success" });
                this.dialogVisible = false;
                this.$refs["dataForm"].resetFields();
              } else {
                this.$message({
                  message: "操作失败, " + res.msg,
                  type: "error"
                });
              }
              this.getList();
            });
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-cascader .el-input {
  width: 202px;
}
</style>
