<template>
  <div class="page-container bg-white p-4 h-full relative">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="线路工点" prop="lineSite">
            <el-cascader
              v-model="queryModal.lineSite"
              clearable
              :options="treeData"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="handleLineSiteChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="负责人">
            <el-input
              v-model="queryModal.name"
              clearable
              placeholder="输入负责人"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button
            type="primary"
            @click="handleExport"
            v-has="'Warehouseman:export'"
            >导出</el-button
          >
          <el-button
            type="primary"
            @click="handleAdd"
            v-has="'Warehouseman:add'"
            >新增</el-button
          >
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <!-- <el-table-column
          align="center"
          prop="superviseName"
          label="所属建管部"
          :show-overflow-tooltip="true"
        ></el-table-column> -->
        <el-table-column
          align="center"
          prop="lineName"
          label="线路名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="branchName"
          label="分部名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="工点名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="leaderName"
          label="负责人"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="mobile"
          label="联系电话"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="remark"
          label="备注"
        ></el-table-column>
        <el-table-column align="center" label="操作" width="300px">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              class="mr-4"
              v-has="'Warehouseman:edit'"
              >编辑</el-button
            >

            <el-button
              @click="handleDel(scope.row)"
              type="text"
              v-has="'Warehouseman:del'"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <WarehousemanForm
      ref="modalForm"
      :lineSiteData="treeData"
      @formSubmit="handleFormSubmit"
    ></WarehousemanForm>
  </div>
</template>

<script>
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { getWareHouseManList, delWareHouseMan } from "@/api/eres";
import WarehousemanForm from "./modules/warehousemanForm.vue";
export default {
  components: { WarehousemanForm },
  data() {
    return {
      queryModal: {
        lineSite: [],
        name: "",
        siteIds: [],
      },
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "id",
        multiple: true,
      },
      tableData: [],
      loading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      dict: {},
    };
  },
  mounted() {
    this.getLineSiteTreeData();
    this.loadData();
  },
  methods: {
    handleLineSiteChange(val) {
      this.queryModal.siteIds = [];
      for (let item of val) {
        this.queryModal.siteIds.push(item[item.length - 1]);
      }
    },
    // 顶部查询
    handleSearch() {
      this.page.pageNo = 1;
      this.loadData();
    },
    // 导出
    handleExport() {},
    // 新增
    handleAdd() {
      this.$refs.modalForm.handleAdd();
    },
    // 修改
    handleEdit(record) {
      this.$refs.modalForm.handleAdd(record);
    },
    // 删除
    handleDel(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delWareHouseMan(record.id).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadData();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    // 表单提交
    handleFormSubmit() {
      this.loadData();
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    // 加载表格数据
    loadData() {
      this.loading = true;
      const params = {
        name: this.queryModal.name,
        siteIds: this.queryModal.siteIds,
        page: this.page.pageNo,
        size: this.page.pageSize,
      };
      getWareHouseManList(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          this.page.pageNo = res.data.current;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.total;
          this.page.count = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 11;
}

.page-container {
  .handle-wrapper {
    min-width: 240px;
    max-width: 30vw;
    height: 60vh;
    position: absolute;
    top: 10%;
    left: 20px;
    background: rgba(255, 255, 255, 1);
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
    border-radius: 0px 20px 20px 0px;
    padding: 20px;
    z-index: 6;

    .tree-wrapper {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }

    .toggle-btn {
      width: 28px;
      height: 240px;
      position: absolute;
      right: 0px;
      top: 50%;
      margin-top: -120px;
      cursor: pointer;
      background-image: url();
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }

    .toggle-btn-close {
      transform: rotate(180deg);
    }

    .toggle-btn-open {
      transform: rotate(0deg);
    }
  }

  .handle-wrapper-hide {
    left: -212px;
    background: transparent;
    box-shadow: none;
  }

  .handle-wrapper-show {
    left: 0;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #43a5e9;
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
  }
}
</style>
