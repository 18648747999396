<template>
  <div class="min-h-full bg-white">
    <div class="flex justify-between p-4">
      <el-row class="flex ">
            <el-select
              v-model="queryParams.unitType"
              @keyup.native.enter="getList"
              clearable
              placeholder="请选择单位类型"
            >
              <el-option
                v-for="(item, index) in unitTypeSelect"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>


        <el-input
            class="mr-2"
            v-model="queryParams.postName"
            clearable
            placeholder="岗位名称"
            style="margin-left: 10px;width: 120px"
        ></el-input>

        <el-button type="primary" @click="getList">搜索</el-button>

      </el-row>

      <el-row>

        <el-button
            type="primary"
            @click="handleAdd()"
        ><i class="fa fa-plus"></i>新增
        </el-button
        >

      </el-row>
     </div>

    <div class="mainPart">
      <div class="mainPartCon">
        <el-table
          :data="postList"
          :row-style="{ height: '61px' }"
          border
          size="mini"
          @selection-change="selectionChange"
          v-loading="loading"
          style="overflow: auto"
        >
          <!-- :element-loading-text="$t('action.loading')" -->
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="postCode"
            show-overflow-tooltip
            label="岗位编码"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="postName"
            show-overflow-tooltip
            label="岗位名称"
            align="center"
          >
            <template slot-scope="scope">
              <div class="clickable" @click="handleDeleteModify(scope.row)">
                {{ scope.row.postName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="unitTypeName"
            show-overflow-tooltip
            label="单位类型"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="postSort"
            show-overflow-tooltip
            label="显示顺序"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="isKey"
            show-overflow-tooltip
            label="是否关键岗位"
            align="center"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isKey"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                @change="handlekeyChange(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            show-overflow-tooltip
            label="状态"
            align="center"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="0"
                :inactive-value="1"
                @change="handleChange(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="handleUpdate(scope.row)"
                v-has="'postManage:edit'"
                class="mr-4"
                >编辑
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="handleDelete(scope.row)"
                v-has="'postManage:delete'"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryParams.pageNum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          style="text-align: right; padding: 10px"
        >
        </el-pagination>
      </div>
    </div>
    <div class="editDialog">
      <el-dialog
        :title="title"
        v-if="dialogFormVisible"
        :visible.sync="dialogFormVisible"
        width="60%"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        :before-close="handleDialogClose"
      >
        <el-form
          ref="dataForm"
          :rules="title == '详情' ? {} : rules"
          :disabled="title == '详情' ? true : false"
          :model="form"
          size="small"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="岗位编码"
                prop="postCode"
                :label-width="formLabelWidth"
              >
                <el-input v-model="form.postCode" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="岗位名称"
                prop="postName"
                :label-width="formLabelWidth"
              >
                <el-input v-model="form.postName" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="单位类型"
                prop="unitType"
                :label-width="formLabelWidth"
              >
                <!-- <el-input v-model="form.unitType" autocomplete="off"></el-input> -->
                <el-select
                  v-model="form.unitType"
                  clearable
                  placeholder="请选择单位类型"
                >
                  <el-option
                    v-for="item in unitTypeSelect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="显示顺序"
                prop="postSort"
                :label-width="formLabelWidth"
              >
                <!-- <el-input v-model="form.postSort" autocomplete="off"></el-input> -->
                <el-input-number
                  v-model="form.postSort"
                  :min="1"
                  label="描述文字"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="是否关键岗位"
                prop="isKey"
                :label-width="formLabelWidth"
              >
                <!-- <el-input v-model="form.remark" type="textarea" auto-complete="off" rows="2"></el-input> -->
                <el-switch
                  v-model="form.isKey"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                ></el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="是否接受通知"
                prop="ifInform"
                :label-width="formLabelWidth"
              >
                <!-- <el-input v-model="form.remark" type="textarea" auto-complete="off" rows="2"></el-input> -->
                <el-switch
                  v-model="form.ifInform"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                  @change="resetMsgType"
                ></el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="选择通知类型"
                :label-width="formLabelWidth"
                v-show="form.ifInform"
              >
                <el-select v-model="msgTypes" multiple collapse-tags>
                  <el-option
                    v-for="(item, index) in msgTypeList"
                    :key="index"
                    :label="item.messageTypeLabel"
                    :value="item.categoryValue + item.messageTypeValue"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="状态"
                prop="status"
                :label-width="formLabelWidth"
              >
                <!-- <el-input v-model="form.remark" type="textarea" auto-complete="off" rows="2"></el-input> -->
                <el-switch
                  v-model="form.status"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="0"
                  :inactive-value="1"
                ></el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="备注"
                prop="remark"
                :label-width="formLabelWidth"
              >
                <el-input
                  v-model="form.remark"
                  type="textarea"
                  auto-complete="off"
                  rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <department :options="form.firstDeptId" @setdepartment="setdepartment"></department> -->
          <!-- <el-form-item label="创建者" prop="createBy" :label-width="formLabelWidth">
              <el-input v-model="form.createBy" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="创建时间" prop="createTime" :label-width="formLabelWidth">
              <el-date-picker
                value-format="yyyy-MM-dd hh:mm:ss"
                v-model="form.createTime"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="更新者" prop="updateBy" :label-width="formLabelWidth">
              <el-input v-model="form.updateBy" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="更新时间" prop="updateTime" :label-width="formLabelWidth">
              <el-date-picker
                value-format="yyyy-MM-dd hh:mm:ss"
                v-model="form.updateTime"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogFormVisible = false"
            >取 消</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click="submit"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import PopupTreeInput from "@/components/PopupTreeInput";
import { getDictData } from "@/api/dict";
import {deleteId, postAdd, postEdit, postMangementPage} from "@/api/user";
export default {
  components: {
    PopupTreeInput,
  },
  data() {
    return {
      multipleSelection: [],
      size: "small",
      loading: false,
      queryParams: {
        postName: '',
        pageNum: 1,
        pageSize: 10,
        unitType : ''
      },
      total: 0,
      lineTree: [],
      lineTree1: [],
      lineProps: {
        value: "id",
        label: "name",
        children: "children",
        expandTrigger: "hover",
      },
      rules: {
        postCode: [
          { required: true, message: "岗位编码不能忘为空", trigger: "blur" },
        ],
        postName: [
          { required: true, message: "岗位名称不能忘为空", trigger: "blur" },
        ],
        unitType: [
          { required: true, message: "单位类型不能忘为空", trigger: "change" },
        ],
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      postList: [
        {
          postCode: 111,
          postName: "测试",
          unitTypeName: "1",
        },
      ],
      formLabelWidth: "100px",
      dialogFormVisible: false,
      form: {
        postCode: "",
        postName: "",
        unitType: "",
        postSort: "",
        status: "",
      },
      unitTypeSelect: [],
      msgTypes: [],
      msgTypeList: [],
      oldMsgType: [], //这个是用来判断选择的通知类型是否有变化，来进行判断是否调用保存接口
      isChongqing: null,
      title : ""
    };
  },
  created() {
    this.getList();
    this.getType();
  },
  methods: {
    getList() {
      this.loading = true
      postMangementPage(this.queryParams).then((res) => {
        if (res.code == "200") {
          this.postList = res.data.list
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    getType() {
      let label = "unitType";
      this.unitTypeSelect = [];
      getDictData(label).then((res) => {
        if (res.code == 0) {
          res.data.forEach((element) => {
            this.unitTypeSelect.push({
              label: element.label,
              value: element.code,
            });
          });
        }
      });
      // this.$api.user.getMsgType().then((res) => {
      //   this.msgTypeList = res.data
      // })
    },
    // 弹框 x 关闭事件 弹框取消按钮(清空表单)
    handleDialogClose() {
      this.msgTypes = [];
      this.dialogFormVisible = false;
      this.resetForm();
    },
    resetForm() {
      this.form = {
        masterName: null,
        masterIp: null,
        masterPort: null,
        site: null,
        siteId: null,
        remake: null,
      };
    },
    // 如果关闭接受通知，通知类型清空
    resetMsgType(val) {
      if (!val) {
        this.msgTypes = [];
      }
    },
    handleChange(row) {
      let str = null;
      console.log(row, "row");
      if (row.isKey == 0) {
        str = "启用";
      } else if (row.isKey == 1) {
        str = "停用";
      }
      this.$confirm("确认是否要" + str + "岗位状态？", "提示", {
        type: "warning",
      })
        .then(() => {
          postEdit({ id: row.id, status: row.status })
            .then((res) => {
              if (res.code == "200") {
                this.$message({
                  type: "success",
                  message: "更改成功",
                });
                this.getList();
              }
            });
        })
        .catch(() => {
          this.$message({ type: "info", message: "取消更改" });
          this.getList();
        });
    },
    handlekeyChange(row) {
      let str = null;
      if (row.isKey == 0) {
        str = "关闭";
      } else if (row.isKey == 1) {
        str = "打开";
      }
      this.$confirm("确认是否要" + str + "关键岗位？", "提示", {
        type: "warning",
      })
        .then(() => {
          postEdit({ id: row.id, isKey: row.isKey })
            .then((res) => {
              if (res.code == "200") {
                this.$message({
                  type: "success",
                  message: "更改成功",
                });
                this.getList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消更改",
          });
          this.getList();
        });
    },
    // 翻页
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.queryParams.pageNum = val;
      this.getList();
    },
    // 菜单树选中
    deptTreeCurrentChangeHandle(data, node) {
      if (data.children == null) {
        this.form.siteId = data.id;
        this.form.site = data.name;
      }
    },
    // 新增
    handleAdd() {
      (this.title = "新增"), (this.dialogFormVisible = true);
      this.xiangqing = true;
    },
    // 修改
    handleUpdate(row) {
      this.title = "修改";
      this.form = { ...row };
      this.dialogFormVisible = true;
      //   this.$api.user.getMsgTypeByPostId(row.id).then((res) => {
      //     let arr = [];
      //     res.data.forEach((i) => {
      //       arr.push(i.category + i.messageType);
      //     });
      //     this.msgTypes = arr;
      //     this.oldMsgType = arr;
      //   });

      this.xiangqing = true;
    },
    handleDeleteModify(row) {
      this.dialogFormVisible = true;
      this.title = "详情";
      Object.assign(this.form, row);
      this.xiangqing = false;
    },
    // 提交
    submit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            var addoredit = null;
            this.unitTypeSelect.forEach((el) => {
              if (this.form.unitType == el.value) {
                this.form.unitTypeName = el.label;
              }
            });
            let params = Object.assign({}, this.form);
            if (this.title == "新增") {
              addoredit = postAdd(params);
            } else if (this.title == "修改") {
              addoredit = postEdit(params);
            }
            addoredit.then((res) => {
              if (res.code == "200") {
                let that = this;
                this.$message({ message: "操作成功", type: "success" });
                let checkArray = this.checkArray(
                  this.oldMsgType,
                  this.msgTypes
                );
                if (!checkArray) {
                  let arr = [];
                  let postId = "";
                  if (this.title == "新增") {
                    changeType(arr, res.data.id);
                    postId = res.data.id;
                  } else {
                    changeType(arr, params.id);
                    postId = params.id;
                  }

                  //   this.$api.user.saveMsgType(arr, postId).then((res) => {
                  //     console.log(res);
                  //     if (res.code != 200) {
                  //       this.$message.error("操作失败" + res.msg);
                  //     }
                  //   });
                }
                // changeType是用来把保存的type进行分离，变为后端要的格式
                function changeType(arr, id) {
                  that.msgTypes.forEach((i) => {
                    let obj = {
                      postId: id,
                      category: i.slice(0, i.length - 1),
                      messageType: i[i.length - 1],
                    };
                    arr.push(obj);
                  });
                }
                this.getList();
                this.resetForm();
                this.dialogFormVisible = false;
              } else {
                this.$message({
                  message: "操作失败, " + res.msg,
                  type: "error",
                });
              }
            });
          });
        }
      });
    },
    // 判断两个数组里面的字符串是否完全相等，来确定要不要调用接口
    checkArray(oldArr, newArr) {
      if (oldArr.length != newArr.length) return false;
      let index = newArr.length;
      oldArr.forEach((i) => {
        if (!newArr.includes(i)) {
          return false;
        }
        index--;
      });
      return index > 0 ? false : true;
    },
    // 删除车辆道闸设备信息
    handleDelete(row) {
        const id = (row && row.id) || this.ids;
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        }).then(() => {
          deleteId(id).then((res) => {
            if (res.code == "200") {
              this.$message({ message: "删除成功", type: "success" });
              this.getList();
            }
          });
        });
    },
    // 选择切换
    selectionChange(selections) {
      this.multipleSelection = selections;
      this.ids = selections.map((item) => item.id);
      if (this.multipleSelection.length !== 0) {
        document.getElementById("btn").style.display = "block";
      } else {
        document.getElementById("btn").style.display = "none";
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
