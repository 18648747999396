<template>
  <div class="left">
    <IndexBox :title="title">
      <!-- 事件切换 -->
      <div class="incident">
        <el-dropdown
          @command="
            (command) => {
              switchses(command, row);
            }
          "
        >
          <span class="el-dropdown-link" style="color: #3d98d9; margin-top: 5px"
            ><i class="el-icon-sort" style="transform: rotate(90deg);"></i>
            切换事件
          </span>
          <el-dropdown-menu slot="dropdown" class="dropdownPop">
            <el-dropdown-item
              v-for="(item, index) in switchs"
              :key="index"
              :command="item"
              :title="item.siteName + item.typeName"
              class="region-item-style"
            >
              {{ item.siteName }}{{ item.typeName }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- 事件内容  -->
      <div :class="showTotal ? ' tntiret' : ' incident_top'">
        <div class="titlte">
          <span
            >{{ details.levelName ? details.levelName : "III" }} |
            {{ details.xycoors }}</span
          >
        </div>
        <div class="conent">
          <div :class="showTotal ? 'total-introduce' : 'detailed-introduce'">
            <p>
              <span class="time">发生时间：</span>
              <span>{{
                details.happenTime ? details.happenTime : details.happenTime
              }}</span>
            </p>
            <p>
              <span class="time">事发经过：</span
              ><span>{{ details.historyRecord }}</span>
            </p>
            <p>
              <span class="time">伤亡情况：</span
              ><span>{{ details.casualties }}</span>
            </p>
            <p>
              <span class="time">处置情况：</span
              ><span>{{ details.disposal }}</span>
            </p>
            <p>
              <span class="time">上报人：</span
              ><span>{{ details.publisherName }}</span>
            </p>
            <p>
              <span class="time">负责人：</span
              ><span>{{ details.chargeName }}</span>
            </p>
            <p>
              <span class="time"> 施工单位：</span
              ><span>{{ details.constructionUnitName }}</span>
            </p>
            <p>
              <span class="time"> 监理单位：</span
              ><span>{{ details.supervisorUnitName }}</span>
            </p>
            <p>
              <span class="time">事件类型：</span
              ><span>{{ details.typeName }}</span>
            </p>
          </div>
          <div class="toner">
            <span class="more-txt" @click="more" v-if="anert"
              >展开全部 <img src="@/assets/zhankai.png" alt=""
            /></span>
            <span class="close-more" @click="close" v-if="pack"
              >收起 <img src="@/assets/shouq.png" alt=""
            /></span>
          </div>
        </div>
      </div>
      <!-- 事件进展、舆情信息 -->
      <div class="incident_bottom">
        <div class="tabes">
          <div
            :class="num == index ? 'panes ' : 'panese'"
            v-for="(item, index) in contn"
            :key="index"
            @click="changes(index)"
          >
            {{ item }}
          </div>
        </div>
        <div v-show="num == 0" class="rever">
          <el-timeline style="margin-top: 10px">
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
            >
              <div class="actitle">
                <span class="incid">{{ activity.responseProcessName }}</span>
                <span class="times">{{ activity.created }}</span>
              </div>
              <div
                class="reported"
                v-show="
                  activity.responseProcessName == '事件发布' ||
                    activity.responseProcessName == '事件续报' ||
                    activity.responseProcessName == '事件终报'
                "
              >
                <span class="incid"> 上报人：</span>
                <span>{{ activity.responseUserName }}</span>
              </div>
              <div
                class="reported"
                v-show="activity.responseProcessName == '应急预警'"
              >
                <span class="incid"> 启动人员：</span>
                <span>{{ activity.responseUserName }}</span>
              </div>
              <!--  <div
                class="content"
                v-show="activity.responseProcessName == '事件确认'"
              >
                <span class="incid"> 确认人：</span>
                <span>{{ activity.responseUserName }}</span>
              </div> -->
              <div
                class="content"
                v-show="activity.responseProcessName == '资源调配'"
              >
                <span class="incid"> 调配人：</span>
                <span></span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '资源调配'"
              >
                <span class="incid"> 调配时间：</span>
                <span></span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '资源调配'"
              >
                <span class="incid"> 调配内容：</span>
                <span></span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '事件终报'"
              >
                <span class="incid">事件等级:</span
                ><span>{{
                  activity.levelName ? activity.levelName : "Ⅲ"
                }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '应急预警'"
              >
                <span class="incid">预警等级:</span
                ><span>{{
                  activity.levelName ? activity.levelName : "Ⅲ"
                }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '响应升级'"
              >
                <span class="incid">升级级别:</span
                ><span v-if="activity.levelName == 'Ⅱ'"
                  >Ⅲ->{{ activity.levelName }}</span
                >
                <span v-if="activity.levelName == 'Ⅰ'"
                  >Ⅱ->{{ activity.levelName }}</span
                >
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '事件终报'"
              >
                <span class="incid"> 事件经过:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '事件确认'"
              >
                <span class="incid"> 确认人:</span
                ><span>{{ activity.createName }}</span>
              </div>
              <div
                class="content"
                v-show="
                  activity.responseProcessName == '事件续报' ||
                    activity.responseProcessName == '事件终报'
                "
              >
                <span class="incid"> 伤亡情况:</span
                ><span>{{ activity.casualties }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '响应升级'"
              >
                <span class="incid">升级原因:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '应急预警'"
              >
                <span class="incid">预警原因:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '响应升级'"
              >
                <span class="incid">升级人员:</span
                ><span>{{ activity.createName }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '启动预案'"
              >
                <span class="incid">启动人员:</span
                ><span>{{ activity.createName }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '事件续报'"
              >
                <span class="incid">事件经过:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
              <div
                class="content"
                v-show="activity.responseProcessName == '事件续报'"
              >
                <span class="incid">附件:</span
                ><el-link
                  v-for="(item, index) in enclosure"
                  :key="index"
                  style="margin-left: 62px; font-size: 12px;"
                  type="primary"
                  @click="linker(item)"
                  >{{ item.originalName }}</el-link
                >
              </div>
              <div class="content">
                <span
                  class="incid"
                  v-if="
                    activity.responseProcessName == '启动预案' ||
                      activity.responseProcessName == '事件续报' ||
                      activity.responseProcessName == '事件终报'
                  "
                  >{{
                    activity.responseProcessName == "启动预案"
                      ? "启动预案:"
                      : "处置情况:"
                  }}</span
                ><span v-if="activity.responseProcessName == '启动预案'"
                  ><el-link
                    type="primary"
                    style="margin-left: 64px; font-size: 12px"
                    target="_blank"
                    :href="activity.enclosure"
                    >《{{ activity.disposal }}》</el-link
                  >预案</span
                ><span
                  v-if="
                    activity.responseProcessName == '事件续报' ||
                      activity.responseProcessName == '事件终报'
                  "
                  >{{ activity.disposal }}</span
                >
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
        <div v-show="num == 1" class="rever">
          <el-timeline style="margin-top: 10px">
            <el-timeline-item
              v-for="(activity, index) in puberlic"
              :key="index"
            >
              <div class="actitle">
                <span class="incid">{{ activity.incident }}</span>
                <span class="times">{{ activity.time }}</span>
              </div>
              <div class="connt">
                <span>{{ activity.details }}</span>
                <el-button type="text" v-show="activity.incident !== '事件确认'"
                  >详情</el-button
                >
              </div>
              <div class="imged">
                <img :src="activity.imgs" alt="" style="" />
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
        <!-- <div v-show="num == 2">流程图</div> -->
      </div>
      <div class="ter" style="display: flex; justify-content: space-around">
        <el-button
          type="primary"
          @click="give"
          v-has="'Outburs:continue'"
          size="small"
          >事件续报</el-button
        >
        <!-- <el-button
          type="primary"
          @click="handling"
          v-has="'Outburst:quote'"
          size="small"
          >事件终报</el-button
        > -->
        <el-button type="primary" @click="casese" size="small"
          >类似案例</el-button
        >
      </div>
    </IndexBox>

    <Modal
      class="modalDetail"
      ref="qutypes"
      :modal="false"
      :width="'800px'"
      :title="titesle"
      ><Quoted @closeter="closeter" :statre="statre" :uuid="uuid"></Quoted>
    </Modal>
    <Modal
      class="modalDetail"
      ref="sesd"
      :modal="false"
      :width="'1200px'"
      :title="titesle"
    >
      <Cased ref="cserd" :typesr="typer"></Cased
    ></Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal/modal.vue";
import IndexBox from "@/components/IndexBox/indexBox";
import { getprogress, getHomeDatas } from "@/api/response.js";
import Quoted from "./incident/quoted.vue";
import Cased from "./incident/cased.vue";

// import { unzipSync } from "zlib";
// import bus from "@/utils/bus";
export default {
  components: { IndexBox, Quoted, Cased, Modal },
  data() {
    return {
      title: "地面巡查发现中国石油地面巡查发现中国石油",
      contn: ["事件进展", "舆情信息"],
      num: 0,
      activities: [],
      puberlic: [
        {
          incident: "广州快报",
          time: "2020-12-12 08:28:25",
          details:
            "地面巡查发现中国石油地面巡查发现中国石油，地面巡查发现中国石油，地面巡查发现中国石油地面巡查发现中国石油",
          imgs: require("@/assets/equipment-03.png"),
        },
        {
          incident: "新浪微博",
          time: "2020-12-12 08:28:25",

          details:
            "地面巡查发现中国石油地面巡查发现中国石油，地面巡查发现中国石油，地面巡查发现中国石油地面巡查发现中国石油",
          imgs: require("@/assets/equipment-03.png"),
        },
        {
          incident: "央视快报",
          time: "2020-12-12 08:28:25",

          details:
            "地面巡查发现中国石油地面巡查发现中国石油，地面巡查发现中国石油，地面巡查发现中国石油地面巡查发现中国石油",
          imgs: require("@/assets/equipment-03.png"),
        },
      ],
      titesle: "",
      rescueTitle: "",
      anert: false,
      pack: false,
      showTotal: true,
      statre: 0,
      uuid: "",
      details: {},
      switchs: [],
      path: "", //socket连接地址
      socket: null, //建立的连接
      lockReconnect: false, //是否真正建立连接
      timeout: 2 * 1000, //30秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      siteId: null,
      uuids: "",
      typer: "",
      enclosure: [],
    };
  },
  created() {
    this.$EventBus.$on("rseet", (val) => {
      console.log(val);
      if (val == 1) {
        console.log(this.switchs);
        let params = {
          status: "execute",
        };
        getHomeDatas(params).then((res) => {
          this.switchs = res.data.list;
          console.log(this.switchs);
          if (this.switchs.length != 0) {
            this.details = this.switchs[0];
            console.log(this.details, "1111111111");
            var userId = this.switchs[0].uuid;
            this.timer();
            var uuids = this.getUUID();
            this.uuids = uuids;
            console.log(userId, uuids);
            this.path =
              "ws://47.95.238.93:9310/responseMessage?userId=" +
              userId +
              "-" +
              uuids;
            this.init();
            this.$emit("detaliel", this.details);

            this.reconnect();
          } else {
            this.$router.push("/Screen/Contingency/Index");
          }
        });
      }
    });
    this.record();
    this.terwr();
    this.progress(this.details);

    var userId = this.details.uuid;
    var uuids = this.getUUID();
    this.uuids = uuids;
    this.path =
      "ws://47.95.238.93:9310/responseMessage?userId=" + userId + "-" + uuids;
  },
  mounted() {
    // 初始化
    this.init();
    this.$nextTick(() => {
      let contentDom = document.querySelectorAll(".conent");
      contentDom = [...contentDom];

      contentDom.forEach((item) => {
        let height = item.offsetHeight;

        if (height > 100) {
          // 超过高度，给该文本的父元素添加class
          this.anert = true;
          this.pack = false;
          this.showTotal = true;
        }
      });
    });
  },
  methods: {
    /* 随机获取uuid */
    getUUID() {
      var d = new Date().getTime();
      if (window.performance && typeof window.performance.now === "function") {
        d += performance.now(); //use high-precision timer if available
      }
      var uuids = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
      return uuids;
    },

    init() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        this.socket = new WebSocket(this.path);
        // 监听socket连接
        this.socket.onopen = this.open;
        // 监听socket错误信息
        this.socket.onerror = this.error;
        // 关闭socket
        this.socket.onclose = this.close;
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
      }
    },
    reconnect() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function() {
        that.init(); //新连接
        that.lockReconnect = false;
        console.log("重新连接中……");
        // that.path = "ws://219.153.100.85:8001/webSocket/" + that.siteId + "-" + that.uuid //模拟测试webscoket断线重连
      }, 1000 * 2);
    },
    reset() {
      //重置心跳
      var that = this;
      clearTimeout(that.timeoutObj); //清除时间
      clearTimeout(that.serverTimeoutObj); //清除时间
      // that.start(); //重启心跳
    },
    open() {
      console.log("socket连接成功");
      // this.start(); //开启心跳
    },
    error() {
      console.log("连接错误");
      //重连
      this.reconnect();
    },
    getMessage: function(msg) {
      console.log(JSON.parse(msg.data), "服务端发来消息");
      var message = JSON.parse(msg.data);
      this.activities = message;
      message.forEach((item) => {
        if (item.responseProcessName == "事件续报") {
          this.enclosure = JSON.parse(item.enclosure);
        }
      });
      // this.enclosure=JSON.parse(message.enclosure)
    },
    timer() {
      this.progress(this.details);
    },
    terwr() {
      this.details = JSON.parse(this.$route.query.data);
      console.log(this.details, "222222222222");
      this.title = this.details.typeName;
      this.typer = this.details.type;
      this.$emit("detaliel", this.details);

      this.progress(this.details);
    },
    //事件切换
    switchses(item, row) {
      this.details = item;
      console.log(this.details, "333333333333");
      this.$emit("detaliel", this.details);
      this.progress(this.details);
    },
    destroyed() {
      // 销毁监听
      this.socket.onclose = this.close;
    },

    //应急响应记录列表
    record() {
      let params = {
        status: "execute",
      };
      getHomeDatas(params).then((res) => {
        this.switchs = res.data.list;
        console.log(this.switchs);
      });
      // let arrts = JSON.parse(localStorage.getItem("Response_Information"));

      // if (arrts) {
      //   this.switchs = arrts;

      //   // this.detailse(arrts[0].responseRecordUuid);
      // } else {
      //   let params = {
      //     alarmStatus: "alarming",
      //     // uploadUnitType: this.deptType
      //     uploadUnitType: "monitor",
      //     // monitor 监理单位
      //     // construct 施工单位
      //   };
      //   getsiteAlarm(params).then((res) => {
      //     this.switchs = res.data.list;
      //   });
      // }
    },

    //事件进展
    progress(val) {
      getprogress({
        responseRecordUUid: val.uuid,
        responseType: "all",
        size: "0",
      }).then((res) => {
        console.log(res.data);
        this.activities = res.data;
        res.data.forEach((item) => {
          if (item.responseProcessName == "事件续报") {
            this.enclosure = JSON.parse(item.enclosure);
          }
        });
      });
    },
    changes(val) {
      this.num = val;
    },

    give() {
      getprogress({
        responseRecordUUid: this.details.uuid,
        responseType: "deadline",
        size: "0",
      }).then((res) => {
        console.log(res);
        let sbarr = [];
        res.data.forEach((el) => {
          sbarr.push(el.responseProcessName);
        });

        if (!sbarr.includes("事件终报")) {
          this.$refs.qutypes.handleOpen();

          this.uuid = this.details.uuid;
          this.statre = 1;
          this.titesle = "事件续报";
        } else {
          this.$message({
            message: "已填写事件终报，不可填写事件续报",
            type: "warning",
          });
        }
      });
    },
    handling() {
      getprogress({
        responseRecordUUid: this.details.uuid,
        responseType: "deadline",
        size: "0",
      }).then((res) => {
        console.log(res);
        let sbarr = [];
        res.data.forEach((el) => {
          sbarr.push(el.responseProcessName);
        });

        if (!sbarr.includes("事件终报")) {
          this.$refs.qutypes.handleOpen();
          this.uuid = this.details.uuid;
          this.statre = 2;
          this.titesle = "事件终报";
        } else {
          this.$message({
            message: "已填写事件终报，不可重复填写",
            type: "warning",
          });
        }
      });
    },
    closeter() {
      this.$refs.qutypes.handleClose();
    },
    casese() {
      this.titesle = "类似案例";
      this.$refs.sesd.handleOpen();
      this.$refs.cserd.putted();
    },
    opinion() {},
    tecer() {},
    //展开
    more() {
      this.showTotal = false;
      this.anert = false;
      this.pack = true;
    },
    //收起
    close() {
      this.anert = true;
      this.pack = false;
      this.showTotal = true;
    },
    linker(val) {
      window.open(val.uri);
    },
  },
  beforeDestroy() {
    // debugger;
    this.timer();
  },
};
</script>

<style lang="scss" scoped>
.left {
  .incident {
    position: absolute;
    left: 67%;
    top: 1.5%;
  }
  .tntiret {
    // height: 41%;
    max-height: 50%;
    padding-bottom: 20px;
    border-bottom: 1px solid #002e75;
    .titlte {
      display: flex;
      justify-content: space-between;
      span {
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .total-introduce {
      height: 200px;
      overflow: hidden;
    }
    .detailed-introduce {
      height: 100%;
    }
    p {
      margin-top: 3%;
      font-size: 13px;
      line-height: 22px;
      padding-left: 59px;
      text-indent: -59px;
      .time {
        width: 50px;
        color: #ffffff;
        opacity: 0.6;
      }
      span {
        color: #ffffff;
      }
    }

    .toner {
      .more-txt {
        font-size: 12px;
        color: #1791ff;
        float: right;
        position: relative;
        right: 45%;
        margin-top: -1%;

        cursor: pointer;
        display: flex;
        img {
          width: 10px;
          height: 10px;
          margin-top: 4px;
          margin-left: 7px;
        }
      }
      .close-more {
        font-size: 12px;
        color: #1791ff;
        float: right;
        position: relative;
        right: 45%;
        margin-top: 4px;

        margin-bottom: 4px;

        cursor: pointer;
        display: flex;
        img {
          width: 10px;
          height: 10px;
          margin-top: 4px;
          margin-left: 7px;
        }
      }
    }
  }
  .incident_top {
    height: 45%;
    overflow: auto;
    border-bottom: 1px solid #002e75;
    .titlte {
      display: flex;
      justify-content: space-between;
      span {
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .total-introduce {
      height: 200px;
      overflow: hidden;
    }
    .detailed-introduce {
      height: 100%;
    }
    p {
      margin-top: 3%;
      font-size: 13px;
      line-height: 22px;
      padding-left: 59px;
      text-indent: -59px;
      .time {
        width: 50px;
        color: #ffffff;
        opacity: 0.6;
      }
      span {
        color: #ffffff;
      }
    }

    .toner {
      .more-txt {
        font-size: 12px;
        color: #1791ff;
        float: right;
        position: absolute;
        right: 45%;
        margin-top: -2px;

        cursor: pointer;

        display: flex;
        img {
          width: 10px;
          height: 10px;
          margin-top: 4px;
          margin-left: 7px;
        }
      }
      .close-more {
        font-size: 12px;
        color: #1791ff;
        float: right;
        position: relative;
        right: 45%;
        margin-top: 4px;
        cursor: pointer;
        display: flex;

        padding-bottom: 4px;
        img {
          width: 10px;
          height: 10px;
          margin-top: 4px;
          margin-left: 7px;
        }
      }
    }
  }
  .incident_top::-webkit-scrollbar {
    display: none;
  }
  .incident_bottom {
    margin-top: 10px;
    margin-left: 30px;
    height: 54%;
    .tabes {
      width: 53%;
      height: 35px;
      margin: auto;
      border: 1px solid #43a5e9;
      display: flex;
      border-radius: 5px;
      justify-content: space-around;
      align-items: center;
      .panes {
        width: 52%;
        height: 35px;
        color: #ffffff;
        font-size: 12px;
        text-align: center;
        line-height: 35px;
      }
      .panese {
        width: 52%;
        height: 35px;
        background: #002e75;
        opacity: 0.6;
        color: #ffffff;
        font-size: 12px;
        text-align: center;
        line-height: 35px;
        border-left: 1px solid #43a5e9;
      }
    }
    .rever {
      height: 100%;

      overflow-y: auto;
      .actitle {
        display: flex;
        justify-content: space-between;
        .times {
          color: #ffffff;
          opacity: 0.6;
          font-size: 12px;
        }
        .incid {
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
      }
      .reported {
        margin-top: 10px;
        .incid {
          color: #ffffff;
          opacity: 0.6;
          font-size: 12px;
        }
        span {
          color: #ffffff;
          font-size: 12px;
        }
      }
      .content {
        margin-top: 10px;
        line-height: 22px;
        padding-left: 58px;
        text-indent: -58px;
        .incid {
          color: #ffffff;
          opacity: 0.6;
          font-size: 12px;
        }
        span {
          color: #ffffff;
          font-size: 12px;
        }
      }
      .connt {
        margin-top: 10px;
        color: #ffffff;
      }
    }
    .rever::-webkit-scrollbar {
      display: none;
    }
  }
}
::v-deep .el-timeline-item__node {
  background-color: rgba(59, 130, 246);
}
::v-deep .el-timeline-item__tail {
  border-left: 2px solid #28567f;
}

.top_rte:hover {
  width: 100%;
  background-color: #43a5e9;
}
.ter {
  width: 18%;
  height: 42px;
  position: fixed;
  top: 94%;
  background: #071743;
  // margin-top: 25%;
  display: flex;
  justify-content: space-around;
}
.dropdownPop {
  width: 200px;
  background-color: #143e73;
  border: 1px solid #071743;

  .el-dropdown-menu__item {
    color: #47c4ff;
  }
  .el-dropdown-menu__item:hover {
    background-color: #4472cb;
  }
  .popper__arrow {
    border: 1px solid #4472cb !important;
  }
  .popper__arrow::after {
    border-top-color: #46bcff !important;
    border-bottom-color: #46bcff !important;
  }
}
</style>
<style lang="scss">
.warp {
  background: #071743 !important;
  color: #ffffff;
  border-color: #071743;
  .top p:hover {
    background: #28567f;
  }
}
.warp .el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #071743 !important;
}
.el-dropdown-menu {
  overflow-y: auto;
  // overflow: scroll; // 添加滚动条
  max-height: 200px; // 最大高度
  overflow-x: hidden; // 隐藏x轴上的滚动条
}
.el-dropdown-menu ::-webkit-scrollbar {
  display: none;
}
.el-dropdown {
  margin-top: 10px;
}
.region-item-style {
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
