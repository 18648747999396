<template>
  <systemModal
    ref="formModal"
    :title="title"
    :showFooter="false"
    width="50%"
    @modalClose="handleClose"
  >
    <section>
      <h3>事件快报</h3>
      <el-form
        ref="form"
        :model="form"
        label-width="110px"
        size="mini"
        class="px-4"
        label-position="left"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="事件类型">
              <el-input
                v-model="form.typeName"
                readonly
                placeholder="事件类型"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发生时间">
              <el-input
                v-model="form.created"
                readonly
                placeholder="发生时间"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="上报人">
              <el-input
                v-model="form.publisherName"
                readonly
                placeholder="上报人"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人">
              <el-input
                v-model="form.chargeName"
                readonly
                placeholder="负责人"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="地理位置">
              <el-input
                v-model="form.xycoors"
                readonly
                placeholder="地理位置"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工程项目名称">
              <el-input
                v-model="form.siteName"
                readonly
                placeholder="工程项目名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="施工单位">
              <el-input
                v-model="form.constructionUnitName"
                readonly
                placeholder="施工单位"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="监理单位">
              <el-input
                v-model="form.supervisorUnitName"
                readonly
                placeholder="监理单位"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="事件经过">
          <el-input
            readonly
            type="textarea"
            resize="none"
            placeholder="请输入作业内容、事发情形等"
            :autosize="{ minRows: 2, maxRows: 4 }"
            v-model="form.historyRecord"
          ></el-input>
        </el-form-item>
        <el-form-item label="伤亡情况">
          <el-input
            readonly
            type="textarea"
            resize="none"
            placeholder="请输入人员伤亡、直接经济损失和影响范围"
            :autosize="{ minRows: 2, maxRows: 4 }"
            v-model="form.casualties"
          ></el-input>
        </el-form-item>
        <el-form-item label="处置情况">
          <el-input
            readonly
            type="textarea"
            resize="none"
            placeholder="请输入应急救援、已采取和拟采取的措施等"
            v-model="form.disposal"
          ></el-input>
        </el-form-item>
        <el-form-item label="照片或附件">
          <a
            v-for="(item, index) in imgs"
            :key="index"
            :href="item.url"
            target="_blank"
            style="color:#409EFF;display:block;"
            >{{ item.originalName }}</a
          >
        </el-form-item>
      </el-form>
    </section>
    <section>
      <h3>升级信息</h3>
      <el-form
        :model="model"
        ref="modelForm"
        label-width="100px"
        class="px-4"
        label-position="left"
        size="mini"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="升级类别" prop="currentLevelName">
              <el-input
                v-model="UpgradeInfo.currentLevelName"
                readonly
                placeholder="升级类别"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="升级原因" prop="upgradeReason">
              <el-input
                v-model="UpgradeInfo.upgradeReason"
                readonly
                placeholder="升级原因"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="升级时间" prop="updatedTime">
              <el-input
                v-model="UpgradeInfo.updatedTime"
                readonly
                placeholder="升级时间"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="操作人" prop="createdName">
              <el-input
                v-model="UpgradeInfo.createdName"
                readonly
                placeholder="操作人"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </section>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import {
  detailsList,
  getUpgrade,
} from "@/api/response";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "响应升级",
      model: {},
      loading: false,
      form: {},
      imgs: [],
      eventData: {},
      UpgradeInfo: {},
      id: "",
    };
  },
  filters: {},
  mounted() {},
  methods: {
    handleDetail(record) {
      this.id = record.id;
      this.type = record.noticeCode;
      this.$refs.formModal.handleOpen();
      this.getUpgradeInfo(record.eventId);
    },
    getInfo(eventId) {
      let params = {
        id: eventId,
      };
      detailsList(params).then((res) => {
        if (res.code == 0) {
          this.form = res.data;
          if (res.data.processList[0].enclosure) {
            try {
              this.imgs = JSON.parse(res.data.processList[0].enclosure);
            } catch (error) {
              this.imgs = [];
            }
          }
        }
      });
    },
    async getUpgradeInfo(id) {
      const res = await getUpgrade(id);
      if (res.code == 0) {
        this.UpgradeInfo = res.data;
        this.getInfo(res.data.responseEventId);
      }
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 30px !important;
}
::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}
::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
section {
  h3 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
    color: rgb(16, 16, 16);
  }
  &:nth-child(2) {
    margin-top: 20px;
  }
}
</style>
