import Layout from "@/components/Layout/SystemLayout.vue";
// import Focus from "@/views/ThreeProofings/ThreeProofings/Focus/index.vue";
import AtPresentPage from "@/views/ThreeProofings/System/Proofings/AtPresent.vue";
// 三防响应
import CurrentResponse from "@/views/ThreeProofings/System/ThreeResponse/index.vue";
// 历史响应记录
import HistoryRecords from "@/views/ThreeProofings/System/ThreeResponse/historyRecords.vue";
import ResponseIssuePage from "@/views/ThreeProofings/System/Proofings/ResponseIssue.vue";
import RectifyProblem from "@/views/ThreeProofings/System/RectifyProblem/index";
import Proof from "@/views/ThreeProofings/System/Proof/index";
import Preventionstatistics from "@/views/ThreeProofings/System/ThreeResponse/preventionstatistics/index";
import PreventionstatisticsTables from "@/views/ThreeProofings/System/ThreeResponse/preventionstatistics/tables.vue";

const Routes = [
  {
    path: "ThreeProofings",
    component: Layout,
    name: "ThreeProofings",
    children: [
      {
        path: "Response",
        component: Layout,
        name: "Response",
        meta: {
          title: "三防响应"
        },
        children: [
          {
            path: "AtPresent",
            name: "AtPresent",
            component: AtPresentPage,
            meta: {
              title: "当前响应"
            },
          },
          {
            path: "HistoricalResponseRecord",
            name: "HistoricalResponseRecord",
            component: ResponseIssuePage,
            meta: {
              title: "历史响应记录"
            },
          },
        ]
      },
      {
        path: "Response",
        component: Layout,
        name: "Response",
        meta: {
          title: "三防响应(new)"
        },
        children: [
          {
            path: "CurrentResponse",
            name: "CurrentResponse",
            component: CurrentResponse,
            meta: {
              title: "当前响应"
            },
          },
          {
            path: "HistoryRecords",
            name: "HistoryRecords",
            component: HistoryRecords,
            meta: {
              title: "历史响应记录"
            },
          },
          {
            path: "Preventionstatistics",
            name: "Preventionstatistics",
            component: Preventionstatistics,
            meta: {
              title: "三防统计"
            },
          },
          {
            path: "PreventionstatisticsTables",
            name: "PreventionstatisticsTables",
            component: PreventionstatisticsTables,
            meta: {
              title: "三防统计表格"
            },
          },
        ]
      },
      {
        path: "RectifyProblem",
        name: "RectifyProblem",
        component: RectifyProblem,
        meta: {
          title: "三防问题整改",
        }
      },
      {
        path: "Proof",
        name: "Proof",
        component: Proof,
        meta: {
          title: "防汛关注重点",
        }
      },
      // {
      //   path: "Focus",
      //   component: Focus,
      //   name: "Focus",
      //   meta: { title: "三防关注重点" }
      // },
    ]
  }
];

export default Routes;
