<template>
  <!-- 填报 -->
  <div v-loading="tabloading">
    <el-form :inline="true" :model="tableForm" class="demo-form-inline">
      <el-form-item label="填报工点" v-if="tableForm.tableData[0]">
        <el-input v-model="tableForm.tableData[0].lineSite" readonly></el-input>
      </el-form-item>

      <el-form-item label="三防应急响应类型" v-if="tableForm.tableData[0]">
        <el-input
          v-model="tableForm.tableData[0].responseInfo"
          readonly
        ></el-input> </el-form-item
      ><el-form-item label="三防应急响应级别" v-if="tableForm.tableData[0]">
        <el-input
          v-model="tableForm.tableData[0].responseLevel"
          readonly
        ></el-input>
      </el-form-item>
    </el-form>
    <el-form :model="tableForm" ref="tableForm" size="small">
      <el-table
        ref="tableData"
        :data="tableForm.tableData"
        id="tableData"
        border
        :row-style="{ height: '30px' }"
        style="width: 100%"
      >
        <el-table-column
          label="派出工作组(个)从应急响应启动到本次报送时间节点,派出工作组的累计数量"
          align="center"
          prop="workGroup"
        >
        </el-table-column>
        <el-table-column
          prop="position"
          label="检查场所部位(处)从应急响应启动到本次报送时间节点,检查场所部位的累计数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="hiddenDanger"
          label="新排查出三防风险隐患(处)上—次报送时间节点到本次报送时间节点，新排查出的三防风险隐患数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="info"
          label="发送预警或防御信息(条)地铁集团不需要填该项"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.info ? row.info : "/" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="worker"
          label="预置(出动)抢险人员(人)在本次报送时间节点，能立即调动的抢险人员"
          align="center"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.worker'"
              :rules="[
                {
                  required: true,
                  message: '必填且为数字',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input v-model="scope.row.worker" type="number"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="team"
          label="预置(出动)抢险队伍(支)在本次报送时间节点，能立即调动的抢险队伍数量"
          align="center"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.team'"
              :rules="[{ required: true, message: '必填且为数字', trigger: 'blur' }]"
            >
              <el-input
                v-model.number="scope.row.team"
                type="number"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="deviceTotal" label="合计" align="center">
          <template slot-scope="scope">
            {{
              Number(scope.row.vehicle) +
                Number(scope.row.boat) +
                Number(scope.row.waterPump) +
                Number(scope.row.other)
            }}
          </template>
        </el-table-column>
        <el-table-column
          label="预置(出动)抢险设备(台)指在本次报送时间节点，能立即调动设备数量"
          align="center"
        >
          <el-table-column
            prop="vehicle"
            label="车辆能立即调动的抢险汽车、吊车、铲车、履带车等"
            align="center"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.vehicle'"
                :rules="[
                  {
                    required: true,
                    message: '必填且为数字',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model.number="scope.row.vehicle"
                  type="number"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="boat"
            label="舟艇能立即调动的舟船、划艇等水上运输工具数量"
            align="center"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.boat'"
                :rules="[
                  {
                    required: true,
                    message: '必填且为数字',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model.number="scope.row.boat"
                  type="number"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="waterPump"
            label="抽水机能立即调动的龙吸水、自吸泵、排污泵、潜水泵、动力站水泵等抽排水设备数量"
            align="center"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.waterPump'"
                :rules="[
                  {
                    required: true,
                    message: '必填且为数字',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model.number="scope.row.waterPump"
                  type="number"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="other"
            label="其他能立即调动的、未统计在车辆、舟艇、抽水机范围内的三防抢险设备数量"
            align="center"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.other'"
                :rules="[
                  {
                    required: true,
                    message: '必填且为数字',
                    trigger: 'blur',
                  },
                ]"
                v-if="scope.row.siteName != '汇总数据'"
              >
                <el-input
                  v-model.number="scope.row.other"
                  type="number"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="其他应急措施落实及受灾害影响情况指从应急响应启动到本次报送时间节点，集团公司各单位应急措施落实及受灾受影响累计情况"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.remark'"
              :rules="[
                {
                  required: true,
                  message: '必填',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input v-model="scope.row.remark"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div style="display: flex; justify-content: center; margin-top: 20px">
      <el-button
        v-for="(item, index) in operationData"
        :key="index"
        @click="sureSave('tableForm', item.operation)"
        v-show="item.enabled"
        type="primary"
        >{{ item.operationText }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { statistics, statement } from "@/api/response.js";
export default {
  props: {
    statid: {},
  },
  data() {
    return {
      tableForm: {
        tableData: [],
      },
      operationData: [],
      tabloading: true,
    };
  },
  created() {},
  watch: {
    statid: {
      handler(val) {
        if (val) {
          this.statisticslist(val.id);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {},
  mounted() {},
  methods: {
    //填报查看详情
    statisticslist(val) {
      this.tableForm = {
        tableData: [],
      };
      this.tabloading = true;
      statistics({ id: val })
        .then((res) => {
          if (res.code == 0) {
            this.tableForm.tableData.push(res.data.item);
            this.tabloading = false;
            this.operationData = res.data.ops;
          }
        })
        .catch((err) => {
          this.tabloading = false;
        });
    },

    //保存
    sureSave(formName, operation) {
      this.tableForm.tableData[0].deviceTotal =
        Number(this.tableForm.tableData[0].vehicle) +
        Number(this.tableForm.tableData[0].boat) +
        Number(this.tableForm.tableData[0].waterPump) +
        Number(this.tableForm.tableData[0].other);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.tabloading = true;
          statement({
            data: this.tableForm.tableData[0],
            id: this.tableForm.tableData[0].id,
            operation: operation,
          })
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                this.tabloading = false;
                this.$emit("buildWriteClose");
                this.tableForm = {
                  tableData: [],
                };
              } else {
                this.$message.warning(res.message);
                this.tabloading = false;
                this.$emit("buildWriteClose");
                this.tableForm = {
                  tableData: [],
                };
              }
            })
            .catch((err) => {
              this.tabloading = false;
            });
        } else {
          return false;
        }
      });
    },
    //提交
    sureSave(formName, operation) {
      this.tableForm.tableData[0].deviceTotal =
        Number(this.tableForm.tableData[0].vehicle) +
        Number(this.tableForm.tableData[0].boat) +
        Number(this.tableForm.tableData[0].waterPump) +
        Number(this.tableForm.tableData[0].other);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.tabloading = true;
          statement({
            data: this.tableForm.tableData[0],
            id: this.tableForm.tableData[0].id,
            operation: operation,
          })
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "提交成功",
                  type: "success",
                });
                this.tabloading = false;
                this.$emit("buildWriteClose");
                this.tableForm = {
                  tableData: [],
                };
              } else {
                this.tabloading = false;
                this.$emit("buildWriteClose");
                this.tableForm = {
                  tableData: [],
                };
                this.$message.warning(res.message);
              }
            })
            .catch((err) => {
              this.tabloading = false;
            });
        } else {
          return false;
        }
      });
    },
    build() {
      this.tableForm = {
        tableData: [],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table::before {
  height: 0;
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: #fff !important;
  border-radius: 7px;
}
// /*定义滑块颜色、内阴影及圆角*/
// ::v-deep ::-webkit-scrollbar-thumb {
//   border-radius: 7px;
//   -webkit-box-shadow: none !important;
//   background-color: rgba(0, 0, 0, 0.1) !important;
// }
/*定义滑块颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 7px;
  border-radius: 0;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}

//去掉固定列下方的横线
::v-deep .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: transparent;
}
//去掉固定列阴影
::v-deep .el-table__fixed-right {
  // box-shadow: none;
  height: 100% !important;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}
::v-deep .el-table th > .cell {
  font-size: 12px !important;
}
::v-deep .el-table--border,
.el-table--group {
  border: none;
}
::v-deep .el-table--border::after,
.el-table--group::after {
  width: 0 !important;
}
::v-deep .el-table--border th.el-table__cell.gutter:last-of-type {
  background-color: #fff !important;
  border-bottom: none;
}
::v-deep .el-radio-button {
  width: 75px;
}
::v-deep .el-radio-button__inner {
  width: 65px;
}
::v-deep .el-form-item--small.el-form-item {
  margin-top: 16px;
}
</style>
