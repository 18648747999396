<template>
  <div>
    <div class="table-wrapper">
      <el-table v-loading="tableLoading" :data="tableData" style="width: 100%">
        <el-table-column type="index" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column
            prop="measureSmg"
            align="center"
            label="问题描述"
            min-width="300"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="happenTime"
            align="center"
            label="问题发生时间"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="created"
            align="center"
            label="上报时间"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="inspectUnitName"
            align="center"
            label="检查单位"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="inspectPersonName"
            align="center"
            label="监察人"
            show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column label="操作" align="center" width="160" fixed="right">
          <template slot-scope="scope">
            <el-button
                type="text"
                @click="view(scope.row)"
            >查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>


    <el-dialog
        width="50%"
        title="风险巡查历史记录"
        class="editDialog details-dialog"
        top="10vh"
        :visible.sync="dialogProblem"
        v-if="dialogProblem"
        center
        append-to-body
        :close-on-click-modal="false"
        :before-close="handleProblemClose"
    >
      <ProblemFillForm v-if="dialogProblem" type="prode" :row-fx-info="rowFxInfo"></ProblemFillForm>
    </el-dialog>

  </div>
</template>

<script>
import ProblemFillForm from "@/views/RiskManagement/System/RiskProcess/components/ProblemFillForm";
export default {
  name: "historyMeasures",
  components : {
    ProblemFillForm
  },
  props : {
    measuresInfo : {
      default : () => {}
    }
  },
  data(){
    return {
      tableData : [],
      tableLoading : false,
      dialogProblem : false,
      rowFxInfo : {}
    }
  },
  mounted() {
    this.tableData = this.measuresInfo.data;
  },
  methods : {
    view(row){
      this.rowFxInfo = {...row};
      this.dialogProblem = true;
    },
    handleProblemClose() {
      this.dialogProblem = false;
    },
  }
}
</script>

<style scoped>

</style>
