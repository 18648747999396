<template>
  <!-- 监控摄像头 小屏 -->
  <div class="block" style="height: 100%;">
    <el-carousel
      :autoplay="false"
      trigger="click"
      style="height: 100%;"
      indicator-position="click"
    >
      <el-carousel-item v-for="(i, index) in videoList" :key="index">
        <div
          v-for="(item, ind) in i"
          :key="item.id"
          class="float-left work-canten"
          :class="ind % 2 == 0 ? 'mr-1' : ''"
        >
          <video
            :id="`min-my-video${item.id}`"
            class="video-js vjs-default-skin"
            controls
            preload="auto"
            width="100%"
            height="100%"
            muted
            v-if="item.sourceId == 0"
          >
            <source :src="item.hls" type="application/x-mpegURL" />
          </video>
          <video
            v-else-if="item.sourceId == 2"
            class="video-js vjs-default-skin"
            :id="`test${item.id}`"
          ></video>
          <iframe
            v-else
            class="h-full w-full"
            :src="item.hls"
            frameborder="0"
          ></iframe>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
import { siteVideos } from "@/api/map";
import { lfsoftPlayerWS } from "@/utils/video";

export default {
  props: {
    eventsSwitching: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      videoList: [],
      playVideo: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      // let site = JSON.parse(this.$route.query.data);
      // this.mounteVideo(site.siteId);
    });
  },
  watch: {
    eventsSwitching: {
      handler(val) {
        console.log(val, this.eventsSwitching, val.siteId, "dddddddddd");
        this.mounteVideo(val.siteId);
      },
    },
  },
  methods: {
    mounteVideo(siteId) {
      //事件切换时 原来有播放的流就清除掉
      if (this.videoList.length > 0) {
        this.videoList.forEach((item) => {
          if (item && item.length > 0) {
            item.forEach((i) => {
              if (i.sourceId == 0) {
                videojs("min-my-video" + i.id).dispose();
              }
            });
          }
        });
        this.playVideo.forEach((item) => {
          item.disconnect();
        });
        this.videoList = [];
        this.playVideo = [];
      }
      const params = {
        siteId: siteId,
        type: "-",
      };
      siteVideos(params).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            res.data.forEach((element) => {
              if (this.videoList.length == 0) {
                this.videoList.push([element]);
              } else {
                if (this.videoList[this.videoList.length - 1].length < 4) {
                  this.videoList[this.videoList.length - 1].push(element);
                } else {
                  this.videoList.push([element]);
                }
              }
              if (element.sourceId == 0) {
                this.$nextTick(() => {
                  this.getVideo(element.id);
                });
              }
              //九碧木的视频
              if (element.sourceId == 2) {
                this.$nextTick(() => {
                  let IPhost = "zhjk.geobim.cn:3226";
                  if (element.ipAddress.slice(0, 3) == 172) {
                    IPhost = "zhjk.geobim.cn:3226";
                  } else {
                    IPhost = "spjk.geobim.cn:3234";
                  }
                  var conf1 = {
                    videoid: "test" + element.id,
                    protocol: "https:",
                    host: IPhost,
                    rootpath: "/",
                    token: element.ipAddress,
                    hlsver: "v1",
                    session: "c1782caf-b670-42d8-ba90-2244d0b0ee83", //session got from login
                  };
                  var v1 = new lfsoftPlayerWS(conf1);
                  this.playVideo.push(v1);
                  v1.connect();
                });
              }
            });
          }
        }
      });
    },
    //现场监控
    getVideo(id) {
      videojs(
        "min-my-video" + id,
        {
          bigPlayButton: false,
          textTrackDisplay: false,
          posterImage: true,
          errorDisplay: false,
          controlBar: true,
        },
        function() {
          this.play();
        }
      );
    },
  },
  // 页面关闭销毁视频播放
  beforeDestroy() {
    this.videoList.forEach((item) => {
      if (item && item.length > 0) {
        item.forEach((i) => {
          if (i.sourceId == 0) {
            videojs("min-my-video" + i.id).dispose();
          }
        });
      }
    });
    this.playVideo.forEach((item) => {
      item.disconnect();
    });
    this.videoList = [];
    this.playVideo = [];
  },
};
</script>

<style lang="scss" scoped>
.work-canten {
  // width: 45%;
  width: 49%;
  // height: 120px;
  height: 110px;
  background: #081742;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0px 1px 2px #000000;
  margin-bottom: 5px;
  overflow: hidden;
}

::v-deep .video-js {
  width: 100%;
  height: 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

::v-deep .el-carousel__container {
  // height: 100% !important;
  height: 92% !important;
}
::v-deep .el-carousel__arrow {
  display: none !important;
}
</style>
