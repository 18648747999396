<template>
  <div class="min-h-full bg-white">
    <div class="flex justify-between p-4">
      <el-row class="flex ">
        <el-cascader
            v-model="queryParams.lineSite"
            clearable
            :options="treeData"
            :props="props"
            :show-all-levels="false"
            collapse-tags
            style="margin-right: 10px"
            @change="handleLineSiteChange"
        ></el-cascader>

        <popup-tree-input
            :data="deviceType"
            :props="deptTreeProps"
            placeholder="设备类型"
            v-if="deviceType[0].children"
            :prop="deviceName"
            :nodeKey="queryParams.equipmentType"
            :currentChangeHandle="lineTreeCurrentChangeHandle"
        ></popup-tree-input>

        <el-select
            class="mr-2"
            v-model="queryParams.equipmentStatus"
            clearable
            style="margin-left: 10px"
            placeholder="设备状态"
        >
          <el-option
              v-for="item in deviceState"
              :key="item.value"
              :label="item.label"
              :value="item.code"
          >
          </el-option>
        </el-select>
        <el-input
            class="mr-2"
            v-model="queryParams.name"
            clearablecesdsdsd
            placeholder="设备名称"
            style="width: 120px"
        ></el-input>
        <el-button type="primary" @click="findData">搜索</el-button>
      </el-row>
    </div>

    <div class="px-4">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column type="index" width="55" header-align="center" align="center" label="序号"> </el-table-column>
        <el-table-column prop="siteName" align="center" label="线路标段工点"></el-table-column>
        <el-table-column prop="equipmentName" align="center" label="设备名称">
        </el-table-column>
        <el-table-column prop="equipmentTypeName" align="center" label="设备类型">
        </el-table-column>
        <el-table-column prop="equipmentStatusName" align="center" label="设备状态">
        </el-table-column>
        <el-table-column prop="personName" align="center" label="特种作业人员"></el-table-column>
        <el-table-column prop="mobile" align="center" label="联系方式"></el-table-column>
        <!-- <el-table-column prop="enterTime" align="center" label="进场时间"></el-table-column> -->
        <el-table-column prop="todayWarnTimes" align="center" label="今日报警次数">
          <template slot-scope="scope">
            <span class="times" @click="lookTodayWarn(scope.row)"> {{ scope.row.todayWarnTimes || 0 }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="allWarnTimes" align="center" label="累计报警次数">
          <template slot-scope="scope">
            <span class="times" @click="lookAllWarn(scope.row)"> {{ scope.row.allWarnTimes || 0 }} </span>
          </template>
        </el-table-column>
       <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-button type="primary" size="small" class="mr-4">
                监测页面
              </el-button>
              <!-- @click="handleEdit(scope.row)" -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex justify-center mt-4">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            layout="prev, pager, next, jumper,total"
            :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!--今日报警次数弹窗-->
    <el-dialog :title="toDayTitle" width="50" :append-to-body="true" :visible.sync="toDayDialog">
      <el-table v-if="equipmentTypeName == '履带吊'" height="480" ref="multipleTable" :data="todayData" tooltip-effect="dark" v-loading="warnLoading">
        <el-table-column type="index" label="序号" width="80"> </el-table-column>
        <el-table-column prop="site" label="工点" align="center"> </el-table-column>
        <el-table-column prop="deviceName" label="设备名称" align="center"> </el-table-column>
        <el-table-column prop="createTime" label="报警时间" width="160"> </el-table-column>
        <el-table-column prop="warnStatusName" label="报警原因" width="160" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="capacity" header-align="center" align="center" label="起重量(t)" show-overflow-tooltip ></el-table-column>
        <el-table-column prop="loadMoment" header-align="center" align="center" label="起重力矩(%)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="amplitude" header-align="center" align="center" label="幅度(m)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="levelness" header-align="center" align="center" label="水平度(°)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="windSpeed" header-align="center" align="center" label="风速(m/s)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="rotationAngle" header-align="center" align="center" label="回转角度(°)" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-table v-else ref="multipleTable" :data="todayData" height="480" tooltip-effect="dark" v-loading="warnLoading">
        <el-table-column type="index" label="序号" width="80"> </el-table-column>
        <el-table-column prop="site" label="工点" align="center"> </el-table-column>
        <el-table-column prop="deviceName" label="设备名称" align="center"> </el-table-column>
        <el-table-column prop="craneTime" label="报警时间" width="160"> </el-table-column>
        <el-table-column prop="alarmReason" label="报警原因" width="160" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="craneHeight" label="高度(m)"> </el-table-column>
        <el-table-column prop="craneRange" label="幅度(m)"> </el-table-column>
        <el-table-column prop="craneTiltAngle" label="倾角(°)"> </el-table-column>
        <el-table-column prop="craneWindSpeed" label="风速(m/s)" width="150"> </el-table-column>
        <el-table-column prop="craneTorquePercentage" label="力矩(%)"> </el-table-column>
        <el-table-column prop="craneElevatingCapacity" label="载重(t)"> </el-table-column>
      </el-table>
      <div class="flex justify-center mt-4">
        <el-pagination
          background
          @size-change="warnSizeChange"
          @current-change="warnCurrentChange"
          :current-page.sync="WarnParams.pageNum"
          :page-size="WarnParams.pageSize"
          layout="prev, pager, next, jumper,total"
          :total="warnTotal"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <!--累计报警次数弹窗-->
    <el-dialog :title="cumulativeTitle" :width="'80%'" :append-to-body="true" :visible.sync="cumulativeDialog">
      <el-form :inline="true" :model="filters" :size="size">
        <el-form-item label="日期">
          <el-date-picker
              v-model="warnTime"
              type="date"
              @change="dataChange"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-table v-if="equipmentTypeName == '履带吊'" height="480" ref="multipleTable" :data="todayData" tooltip-effect="dark" v-loading="warnLoading">
        <el-table-column type="index" label="序号" width="80"> </el-table-column>
        <el-table-column prop="site" label="工点" align="center"> </el-table-column>
        <el-table-column prop="deviceName" label="设备名称" align="center"> </el-table-column>
        <el-table-column prop="createTime" label="报警时间" width="160"> </el-table-column>
        <el-table-column prop="warnStatusName" label="报警原因" width="160" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="capacity" header-align="center" align="center" label="起重量(t)" show-overflow-tooltip ></el-table-column>
        <el-table-column prop="loadMoment" header-align="center" align="center" label="起重力矩(%)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="amplitude" header-align="center" align="center" label="幅度(m)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="levelness" header-align="center" align="center" label="水平度(°)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="windSpeed" header-align="center" align="center" label="风速(m/s)" show-overflow-tooltip width="100"></el-table-column>
        <el-table-column prop="rotationAngle" header-align="center" align="center" label="回转角度(°)" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-table v-else ref="multipleTable" :data="todayData" height="480" tooltip-effect="dark" v-loading="warnLoading">
        <el-table-column type="index" label="序号" width="80"> </el-table-column>
        <el-table-column prop="site" label="工点" align="center"> </el-table-column>
        <el-table-column prop="deviceName" label="设备名称" align="center"> </el-table-column>
        <el-table-column prop="craneTime" label="报警时间" width="160"> </el-table-column>
        <el-table-column prop="alarmReason" label="报警原因" width="160" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="craneHeight" label="高度(m)"> </el-table-column>
        <el-table-column prop="craneRange" label="幅度(m)"> </el-table-column>
        <el-table-column prop="craneTiltAngle" label="倾角(°)"> </el-table-column>
        <el-table-column prop="craneWindSpeed" label="风速(m/s)"> </el-table-column>
        <el-table-column prop="windLevel" label="风级(0~12级)"> </el-table-column>
        <el-table-column prop="craneElevatingCapacity" label="载重(t)"> </el-table-column>
        <el-table-column prop="angle" label="角度(0.0~359.9°)"> </el-table-column>
      </el-table>
      <div class="flex justify-center mt-4">
        <el-pagination
          background
          @size-change="warnSizeChange"
          @current-change="warnCurrentChange"
          :current-page.sync="WarnParams.pageNum"
          :page-size="WarnParams.pageSize"
          layout="prev, pager, next, jumper,total"
          :total="warnTotal"
        >
        </el-pagination>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {getDictData} from "@/api/dict";
import popupTreeInput from "@/views/DeviceManagent/components/popupTreeInput";
import {getGantryTrueTime, realTimeList, tdError, crawlerDataPage} from "@/api/deviceManagement";
import dayjs from "dayjs";

export default {
  name: "EquRealtime",
  components : {
    popupTreeInput
  },
  data(){
    return {
      queryParams : {
        pageNum : 1,
        pageSize : 10,
        equipmentStatus : ""
      },
      deviceType : [
        {
          children : []
        }
      ],
      deviceState : [],
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "id",
        multiple: true,
      },
      total : 0,
      tableData : [],
      loading : false,
      deptTreeProps: {
        label: "label",
        children: "children"
      },
      deviceName : "",
      toDayDialog : false,
      cumulativeDialog : false,
      todayData : [],
      toDayTitle : "",
      cumulativeTitle : "",
      filters: {
        pageNum: 1,
        pageSize: 10,
        equipmentName: "",
        siteIdList: [],
        equipmentType: "",
        equipmentStatus: "",
      },
      warnTime: "",
      size : "small",
      equipmentTypeName: "",
      equipmentBaseId: "",
      siteId: "",
      WarnParams: {
        pageSize: 10,
        pageNum: 1,
        isWarn: 0,
        equipmentBaseId: "",
        siteIdList: [],
        startTime: "",
        endTime: ""
      },
      warnTotal: 0,
      warnLoading: false
    }
  },
  created() {
    this.getLineSiteTreeData();
    this.dictLabel();
    this.getEquSelect();
    this.getTableData();
  },
  methods : {
    dictLabel(){
      let dictArray = ['equipment_status','equipment_type'];
      dictArray.forEach(item => {
        this.getDictData(item)
      })
    },
    getDictData(label){
      getDictData(label).then(res => {
        if(res.code == 0){
          switch (label){
            case 'equipment_status' :
              this.deviceState = res.data;
              break;
          }
        }
      })
    },
    getEquSelect() {
      getDictData("equipment_type").then((res) => {
        if (res.code == 0) {
          this.deviceType = res.data.map(item => {
            item.id = item.code;
            item.children = [];
            return item
          })
          let largeEquipment = []
          let mechanicalEquipment = []
          getDictData("mechanicalEquipment").then((res) => {
            mechanicalEquipment = res.data
            this.deviceType.forEach((item) => {
              if (item.intro === "mechanicalEquipment") {
                item.children = mechanicalEquipment.map(item => {
                  item.id = item.code;
                  return item
                })
              }
            })
          })
          getDictData("largeEquipment").then((res) => {
            largeEquipment = res.data
            this.deviceType.forEach((item) => {
              if (item.intro === "largeEquipment") {
                item.children = largeEquipment.map(item => {
                  item.id = item.code;
                  return item
                })
              }
            })
          })
        }
      })
    },
    getTableData() {
      this.loading = true
      realTimeList(this.queryParams).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    lookAllWarn(row) {
      this.equipmentBaseId = row.id;
      this.equipmentTypeName = row.equipmentTypeName;
      this.siteId = row.siteId;
      this.cumulativeTitle = "累计报警次数";
      this.cumulativeDialog = true;
      this.warnTime = "";
      this.WarnParams.startTime = "";
      this.WarnParams.endTime = "";
      this.WarnParams.time = "";
      this.WarnParams.equipmentId = row.id;
      this.WarnParams.equipmentBaseId = row.id;
      this.WarnParams.siteIdList = [row.siteId];
      this.WarnParams.siteIds = [row.siteId];
      if (row.equipmentTypeName === "塔吊") {
        delete this.WarnParams.startTime;
        delete this.WarnParams.endTime;
        delete this.WarnParams.equipmentId;
        this.getTodayData();
      } else if (row.equipmentTypeName === "龙门吊") {
        delete this.WarnParams.siteIdList;
        delete this.WarnParams.time;
        this.getTodayDataLmd();
      } else if (row.equipmentTypeName == "履带吊") {
        delete this.WarnParams.equipmentId;
        delete this.WarnParams.time;
        this.getTodayDataLdd();
      }
    },
    lookTodayWarn(row) {
      this.toDayTitle = "今日报警次数";
      this.equipmentTypeName = row.equipmentTypeName;
      this.toDayDialog = true;
      this.WarnParams.startTime = dayjs().format("YYYY-MM-DD") + " 00:00:00";
      this.WarnParams.endTime = dayjs().format("YYYY-MM-DD") + " 23:59:59";
      this.WarnParams.time = dayjs().format("YYYY-MM-DD");
      this.WarnParams.equipmentBaseId = row.id;
      this.WarnParams.equipmentId = row.id;
      this.WarnParams.siteIdList = [row.siteId];
      this.WarnParams.siteIds = [row.siteId];
      if (row.equipmentTypeName === "塔吊") {
        delete this.WarnParams.startTime;
        delete this.WarnParams.endTime;
        delete this.WarnParams.equipmentId;
        this.getTodayData();
      } else if (row.equipmentTypeName === "龙门吊") {
        delete this.WarnParams.siteIdList;
        delete this.WarnParams.time;
        this.getTodayDataLmd();
      } else if (row.equipmentTypeName == "履带吊") {
        delete this.WarnParams.equipmentId;
        delete this.WarnParams.time;
        this.getTodayDataLdd();
      }
    },
    dataChange() {
      console.log(this.warnTime)
      this.WarnParams.startTime = this.warnTime ? this.warnTime + " 00:00:00" : "";
      this.WarnParams.endTime = this.warnTime ? this.warnTime + " 23:59:59" : "";
      this.WarnParams.time = this.warnTime;
      this.WarnParams.equipmentId = this.equipmentBaseId;
      this.WarnParams.equipmentBaseId = this.equipmentBaseId;
      this.WarnParams.siteIdList = [this.siteId];
      this.WarnParams.siteIds = [this.siteId];
      if (this.equipmentTypeName === "塔吊") {
        delete this.WarnParams.startTime;
        delete this.WarnParams.endTime;
        delete this.WarnParams.equipmentId;
        this.getTodayData();
      } else if (this.equipmentTypeName === "龙门吊") {
        delete this.WarnParams.siteIdList;
        delete this.WarnParams.time;
        this.getTodayDataLmd()
      } else if (this.equipmentTypeName == "履带吊") {
        delete this.WarnParams.equipmentId;
        delete this.WarnParams.time;
        this.getTodayDataLdd();
      }
    },
    getTodayDataLdd() {
      this.warnLoading = true;
      this.todayData = [];
      crawlerDataPage(this.WarnParams).then(res => {
        this.todayData = res.data.list;
        this.warnTotal = res.data.total;
        this.warnLoading = false;
      });
    },
    getTodayDataLmd() {
      this.warnLoading = true;
      this.todayData = [];
      getGantryTrueTime(this.WarnParams).then((res) => {
        this.warnLoading = false;
        this.warnTotal = res.data.total;
        this.todayData = res.data.list.map((item) => {
          return {
            site: item.site,
            deviceName: item.deviceName,
            craneTime: item.datetime,
            alarmReason: item.alarmType,
            craneHeight: item.height,
            craneRange: item.rrange,
            craneTiltAngle: item.obliguity,
            craneWindSpeed: item.windSpeed,
            craneTorquePercentage: item.momentPercent,
            craneElevatingCapacity: item.weight
          }
        })
      })
    },
    getTodayData() {
      this.warnLoading = true;
      this.todayData = [];
      tdError(this.WarnParams).then((res) => {
        this.todayData = res.data.list
        this.warnTotal = res.data.total;
        this.warnLoading = false;
      })
    },
    // 线路工点树数据查询
    getLineSiteTreeData () {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"))
      if (lineSiteData) {
        this.treeData = lineSiteData
      }
    },
    lineTreeCurrentChangeHandle(data, node) {
      console.log(data)
      if (!data.children) {
        this.deviceName = data.intro
        this.queryParams.equipmentType = data.id
        console.log(this.ruleForm)
        this.getTableData()
      }
    },
    handleLineSiteChange(record){
      console.log(record);
      this.queryParams.siteIdList = []
      for (let item of record) {
        this.queryParams.siteIdList.push(item[item.length - 1])
      }
    },
    findData(){
      this.getTableData();
    },
    handleCurrentChange(val){
      this.queryParams.pageNum = val;
      this.getTableList();
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val
      this.getTableList()
    },
    warnCurrentChange(val) {
      this.WarnParams.pageNum = val;
      if (this.equipmentTypeName === "塔吊") {
        this.getTodayData();
      } else if (this.equipmentTypeName === "龙门吊") {
        this.getTodayDataLmd();
      } else if (this.equipmentTypeName == "履带吊") {
        this.getTodayDataLdd();
      }
    },
    warnSizeChange(val) {
      this.WarnParams.pageSize = val;
      if (this.equipmentTypeName === "塔吊") {
        this.getTodayData();
      } else if (this.equipmentTypeName === "龙门吊") {
        this.getTodayDataLmd();
      } else if (this.equipmentTypeName == "履带吊") {
        this.getTodayDataLdd();
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.times {
  color: #3291f8;
  cursor: pointer;
}
</style>
