<template>
  <div class="page-container bg-white p-4 min-h-full">
    <div class="flex justify-between mb-6">
      <div class="card-wrapper w-10/12">
        <el-row :gutter="20">
          <el-col :span="4" v-for="(item, key) of teamList" :key="item.id">
            <div class="item relative">
              <p
                :class="active == key ? 'active' : ''"
                @click="handleCardToggle(key, item)"
              >
                {{ item.teamName }}
              </p>
              <div
                class="edit-btn absolute top-2 right-2"
                @click="handleEditTeamInfo(item)"
              >
                <i
                  class="el-icon-edit-outline"
                  :style="{ color: active == key ? '#fff' : '#000' }"
                ></i>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="职位">
            <el-select
              v-model="queryModal.workRoleName"
              placeholder="请选择职位"
            >
              <el-option
                v-for="item of dict.workRole"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input
              v-model="queryModal.workerName"
              placeholder="输入姓名查询"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="handleAdd">新增</el-button>
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="workerName"
          label="姓名"
          width="180"
        >
        </el-table-column>
        <!-- <el-table-column align="center" prop="teamId" label="所属救援队"></el-table-column> -->
        <el-table-column
          align="center"
          prop="workRoleName"
          label="职务"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="workerPhone"
          label="联系电话"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="workerEducation"
          label="学历"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="workerSpecialty"
          label="专业"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="permit"
          label="特种作业证"
        ></el-table-column>
        <el-table-column align="center" prop="workerImg" label="照片">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 155px"
              :src="scope.row.workerImg"
              :preview-src-list="[scope.row.workerImg]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-divider direction="vertical"></el-divider>

            <el-button type="text" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        @size-change="handlePageSizeChange"
        @current-change="handlePageNoChange"
        @prev-click="handlePageNoChange"
        @next-click="handlePageNoChange"
      >
      </el-pagination>
    </div>
    <RescueTeamForm
      ref="modelForm"
      @formSubmit="handleFormSubmit"
    ></RescueTeamForm>
    <TeamInfoEditForm
      ref="infoEditModalForm"
      @teamFormSubmit="handleTeamFormSubmit"
    ></TeamInfoEditForm>
  </div>
</template>

<script>
import RescueTeamForm from "./modules/rescueTeamForm.vue";
import TeamInfoEditForm from "./modules/teamInfoEditForm.vue";
import {
  queryRescueTeamMemberList,
  deleteRescueTeamMember,
  queryTeamLists,
} from "@/api/eres";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
export default {
  components: {
    RescueTeamForm,
    TeamInfoEditForm,
  },
  data() {
    return {
      queryModal: {
        workerName: "",
        workRoleName: "",
      },
      tableData: [],
      loading: false,
      teamList: [],
      teamId: "",
      teamName: "",
      active: 0,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      dict: {
        workRole: [],
      },
    };
  },
  created() {
    this.getTeamList();
    this.getDictData("救援队职务", this.dict.workRole);
  },
  methods: {
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    handleCardToggle(key, record) {
      this.active = key;
      this.teamId = record.id;
      this.tableData = [];
      this.loadData();
    },
    handleSearch() {
      this.loadData();
    },
    handleAdd() {
      this.$refs.modelForm.handleAdd();
    },
    handleEdit(record) {
      this.$refs.modelForm.handleAdd(record);
    },
    handleDelete(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteRescueTeamMember(record).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadData();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    // 救援队编辑
    handleEditTeamInfo(record) {
      this.$refs.infoEditModalForm.handleAdd(record);
    },
    loadData() {
      this.loading = true;
      const params = {
        page: this.page.pageNo,
        size: this.page.pageSize,
        teamId: this.teamId,
        postId: this.queryModal.workRoleName
          ? this.queryModal.workRoleName
          : "-",
        name: this.queryModal.workerName ? this.queryModal.workerName : "-",
      };
      queryRescueTeamMemberList(params).then((res) => {
        if (res.code == 0) {
          const { current, size, total, count } = res.data;
          this.tableData = res.data.records ? res.data.records : [];
          this.page.pageNo = current;
          this.page.pageSize = size;
          this.page.total = total;
          this.page.count = count;
          this.loading = false;
        }
      });
    },
    handlePageNoChange(val) {
      this.page.pageSize = val;
    },
    handlePageSizeChange(val) {
      this.page.pageNo = val;
    },
    // 查询救援队列表
    getTeamList() {
      this.loading = true;
      queryTeamLists({ teamName: "-" }).then((res) => {
        if (res.code == 0) {
          if (res.data.length) {
            this.teamList = res.data;
            this.teamId = res.data[0].id;
            this.loadData();
          }
        }
      });
    },
    // 弹窗关闭回调
    handleFormSubmit() {
      this.loadData();
    },
    handleTeamFormSubmit() {
      this.getTeamList();
    },
  },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  p {
    height: 130px;
    padding: 20px 25px;
    border-radius: 10px;
    border: 1px solid #43a5e9;
    cursor: pointer;
  }
  .active {
    background: #43a5e9;
    color: #fff;
  }
  .edit-btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
</style>
