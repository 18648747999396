<template>
  <div class="material-container h-full">
    <el-form
      ref="formData"
      :rules="rules"
      :model="formData"
      :loading="loading"
      label-width="140px"
      :disabled="submitType == 'view' || onlyInput"
    >
      <el-row class="PostInfCs-input">
        <el-col :span="12">
          <el-form-item label="线路分部工点" prop="siteUuid">
            <el-cascader
              style="width: 100%"
              v-model="lineSite"
              clearable
              :options="treeData"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="handleLineSiteChange"
              placeholder="请选择工点"
            ></el-cascader>
          </el-form-item>

          <el-form-item label="风险类别" prop="riskTypeCode">
            <el-select
              v-model="formData.riskTypeCode"
              clearable
              placeholder="请选择类别"
              style="width: 100%"
              @change="getRiskSubTypeList"
            >
              <el-option
                v-for="item of riskTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预计开始时间" prop="estimateStartDay">
            <el-date-picker
              v-model="formData.estimateStartDay"
              type="date"
              placeholder="请选择时间"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="实际开始时间" prop="realStartDay">
            <el-date-picker
              v-model="formData.realStartDay"
              type="date"
              placeholder="请选择时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="原始风险等级" prop="originalRiskLevelCode">
            <el-select
              v-model="formData.originalRiskLevelCode"
              clearable
              placeholder="请选择原始风险等级"
              style="width: 100%"
            >
              <el-option
                v-for="item of riskLevelList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="经度" prop="lat">
            <el-input
              v-model="formData.lat"
              clearable
              placeholder="请输入经度"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="风险控制等级" prop="riskLevelCode">
            <el-select
              v-model="formData.riskLevelCode"
              clearable
              placeholder="请选择风险控制等级"
              style="width: 100%"
              @change="riskLevelCodeChange"
            >
              <el-option
                v-for="item of riskControlLevelList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="风险名称" prop="riskName">
            <el-input
              v-model="formData.riskName"
              clearable
              placeholder="请输入风险名称"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="安全情况" prop="safeStatus">
            <el-select
              v-model="formData.safeStatus"
              clearable
              placeholder="请选择安全情况"
              style="width: 100%"
            >
              <el-option
                v-for="item of safeStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item label="风险子类" prop="riskSubTypeCode">
            <el-select
              v-model="formData.riskSubTypeCode"
              clearable
              placeholder="请选择风险子类"
              style="width: 100%"
            >
              <el-option
                v-for="item of riskSubTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预计结束时间" prop="estimateEndDay">
            <el-date-picker
              v-model="formData.estimateEndDay"
              type="date"
              placeholder="请选择时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="实际结束时间" prop="realEndDay">
            <el-date-picker
              v-model="formData.realEndDay"
              type="date"
              placeholder="请选择时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="剩余风险等级" prop="restRiskLevelCode">
            <el-select
              v-model="formData.restRiskLevelCode"
              clearable
              placeholder="请选择剩余风险等级"
              style="width: 100%"
            >
              <el-option
                v-for="item of riskLevelList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="纬度" prop="lon">
            <el-input
              v-model="formData.lon"
              clearable
              placeholder="请输入纬度"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="PostInfCs-input">
        <el-col>
          <el-form-item label="里程范围" prop="mileageRange">
            <el-input
              type="input"
              v-model="formData.mileageRange"
              placeholder="请输入里程范围"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="PostInfCs-input">
        <el-col :span="12">
          <el-form-item label="关联工法" prop="relationConstructMethodName">
            <!-- <el-input
              v-model="formData.relationConstructMethodName"
              clearable
              placeholder="请输入关联工法"
              style="width: 100%"
            >
            constructionMethodList
            </el-input> -->
            <el-select
              v-model="formData.relationConstructMethodCode"
              clearable
              placeholder="请选择关联工法"
              style="width: 100%"
            >
              <el-option
                v-for="item of constructionMethodList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="施工单位联系人" prop="constructPerson">
            <el-input
              v-model="formData.constructPerson"
              clearable
              placeholder="请输入施工单位联系人"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="设计单位联系人" prop="designPerson">
            <el-input
              v-model="formData.designPerson"
              clearable
              placeholder="请输入设计单位联系人"
              style="width: 100%"
            >
            </el-input> </el-form-item
          ><el-form-item label="监理单位联系人" prop="monitorPerson">
            <el-input
              v-model="formData.monitorPerson"
              clearable
              placeholder="请输入监理单位联系人"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="建设单位联系人" prop="buildPerson">
            <el-input
              v-model="formData.buildPerson"
              clearable
              placeholder="请输入建设单位联系人"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="勘察单位联系人" prop="surveyPerson">
            <el-input
              v-model="formData.surveyPerson"
              clearable
              placeholder="请输入勘察单位联系人"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="第三方监测单位联系人" prop="thirdPerson">
            <el-input
              v-model="formData.thirdPerson"
              clearable
              placeholder="请输入第三方监测单位联系人"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="分部工程" prop="subprojectName">
            <el-input
              v-model="formData.subprojectName"
              clearable
              placeholder="请输入分部工程"
              style="width: 100%"
            >
            </el-input>
            <!-- <el-select
              v-model="formData.subprojectCode"
              clearable
              placeholder="请选择分部工程"
              style="width: 100%"
            >
              <el-option
                v-for="item of subprojectList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="联系电话" prop="constructPhone">
            <el-input
              v-model="formData.constructPhone"
              clearable
              placeholder="请输入联系电话"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="designPhone">
            <el-input
              v-model="formData.designPhone"
              clearable
              placeholder="请输入联系电话"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="monitorPhone">
            <el-input
              v-model="formData.monitorPhone"
              clearable
              placeholder="请输入联系电话"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="buildPhone">
            <el-input
              v-model="formData.buildPhone"
              clearable
              placeholder="请输入联系电话"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="surveyPhone">
            <el-input
              v-model="formData.surveyPhone"
              clearable
              placeholder="请输入联系电话"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="thirdPhone">
            <el-input
              v-model="formData.thirdPhone"
              clearable
              placeholder="请输入联系电话"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="PostInfCs-input">
        <el-col>
          <el-form-item label="具体风险描述" prop="riskDescribe">
            <el-input
              type="textarea"
              v-model="formData.riskDescribe"
              placeholder="请输入具体风险描述"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        class="PostInfCs-input"
        v-for="i in measuresForList"
        :key="i.value"
      >
        <el-col>
          <el-form-item :label="i.label">
            <el-form-item
              label="技术措施"
              :prop="i.value + 'first'"
              label-width="80px"
              v-for="(item, index) in measuresList[i.value].first"
              :key="item.uuid || item.id"
              style="margin: 0 0 20px 0"
            >
              <span class="sort">{{ index + 1 + "：" }}</span>
              <el-input
                v-model="item.measureContent"
                :style="`width: calc(100% - ${onlyInput ? '40px' : '80px'});`"
                @input="setRequire(item.measureContent, i.value, 'first')"
              ></el-input>
              <i
                v-if="!index && !onlyInput"
                class="el-icon-circle-plus iconBtn"
                @click="addOne(i.value, 'first')"
              ></i>
              <i
                v-if="index && !onlyInput"
                class="el-icon-error iconBtn redIconBtn"
                @click="delOne(i.value, 'first', index)"
              ></i>
            </el-form-item>
            <el-form-item
              label="管理措施"
              :prop="i.value + 'second'"
              label-width="80px"
              v-for="(item, index) in measuresList[i.value].second"
              :key="item.uuid || item.id"
              style="margin: 0 0 20px 0"
            >
              <span class="sort">{{ index + 1 + "：" }}</span>
              <el-input
                v-model="item.measureContent"
                :style="`width: calc(100% - ${onlyInput ? '40px' : '80px'});`"
                @input="setRequire(item.measureContent, i.value, 'second')"
              ></el-input>
              <i
                v-if="!index && !onlyInput"
                class="el-icon-circle-plus iconBtn"
                @click="addOne(i.value, 'second')"
              ></i>
              <i
                v-if="index && !onlyInput"
                class="el-icon-error iconBtn redIconBtn"
                @click="delOne(i.value, 'second', index)"
              ></i>
            </el-form-item>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="PostInfCs-input" v-show="isShowFile">
        <el-col>
          <el-form-item label="风险等级变化申请表">
            <!-- <span class="downText" @click="downModel">下载模板</span> -->
            <FileUploader
              @uploadChange="handleFileUpload"
              @delChange="handleFileDel"
              :value="fileList"
            ></FileUploader>
            <p v-show="pageData.fileUrl">
              {{ pageData.fileName
              }}<span @click="downFile(pageData.fileUrl)" class="down"
                >下载</span
              >
            </p>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="dialogFooter" v-if="!onlyInput">
      <el-button size="medium" @click="cancel" v-show="submitType !== 'view'"
        >取 消</el-button
      >
      <el-button
        size="medium"
        type="primary"
        @click="submit"
        v-show="submitType !== 'view'"
        >确 定</el-button
      >
    </div>
  </div>
</template>

<script>
// 字典
import { getDictData } from "@/api/dict";
// 查询角色列表
import { getRoleApi } from "@/api/role";
// 新增,编辑
import {
  riskLibraryAdd,
  riskLibraryEdit,
  riskLibraryDetail,
  riskDetail,
} from "@/api/risk";

import FileUploader from "@/components/Uploader/FileUploader.vue";

export default {
  components: {
    FileUploader,
  },
  props: {
    pageData: {
      type: Object,
      default: () => {},
    },
    submitType: {
      type: String,
    },
    // 为true时，表单禁用，没有底部确定按钮
    onlyInput: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "待确定",
    },
  },
  data() {
    return {
      safeStatusList: [
        { value: "0", label: "未启动" },
        { value: "1", label: "启动异常" },
        { value: "2", label: "管控异常" },
        { value: "3", label: "安全可控" },
        { value: "4", label: "以消除" },
      ],
      stateList: [
        { value: 0, label: "待申请" },
        { value: 1, label: "施工单位申请" },
        { value: 2, label: "待总承包单位审核" },
        { value: 3, label: "待监理审核" },
        { value: 4, label: "待建管部审核" },
        { value: 5, label: "待建设公司审核" },
        { value: 6, label: "待集团审批" },
        { value: 7, label: "已通过" },
      ],
      formData: {
        // safeStatus: "3",
        riskSubTypeCode: "",
      },
      lineSite: [],
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
      },
      loading: false,
      rules: {
        siteUuid: [
          {
            required: true,
            message: "请选择工点",
            trigger: "change",
          },
        ],
        riskLevelCode: [
          {
            required: true,
            message: "请选择风险控制等级",
            trigger: "change",
          },
        ],
        riskTypeCode: [
          {
            required: true,
            message: "请选择风险类别",
            trigger: "change",
          },
        ],
        estimateStartDay: [
          {
            required: true,
            message: "请选择预计开始时间",
            trigger: "change",
          },
        ],
        originalRiskLevelCode: [
          {
            required: true,
            message: "请选择原始风险等级",
            trigger: "change",
          },
        ],
        riskName: [
          {
            required: true,
            message: "请输入风险名称",
            trigger: "blur",
          },
        ],
        riskSubTypeCode: [
          {
            required: true,
            message: "请选择风险子类",
            trigger: "change",
          },
        ],
        // safeStatus: [
        //   {
        //     required: true,
        //     message: "请选择安全情况",
        //     trigger: "change",
        //   },
        // ],
        estimateEndDay: [
          {
            required: true,
            message: "请选择预计结束时间",
            trigger: "change",
          },
        ],
        restRiskLevelCode: [
          {
            required: true,
            message: "请选择原始风险等级",
            trigger: "change",
          },
        ],
        mileageRange: [
          {
            required: true,
            message: "请输入里程范围",
            trigger: "change",
          },
        ],
        constructPerson: [
          {
            required: true,
            message: "请输入施工单位联系人",
            trigger: "blur",
          },
        ],
        designPerson: [
          {
            required: true,
            message: "请输入设计单位联系人",
            trigger: "blur",
          },
        ],
        monitorPerson: [
          {
            required: true,
            message: "请输入监理单位联系人",
            trigger: "blur",
          },
        ],
        buildPerson: [
          {
            required: true,
            message: "请输入建设单位联系人",
            trigger: "blur",
          },
        ],
        surveyPerson: [
          {
            required: true,
            message: "请输入勘察单位联系人",
            trigger: "blur",
          },
        ],
        thirdPerson: [
          {
            required: true,
            message: "请输入第三方监测单位联系人",
            trigger: "blur",
          },
        ],
        constructPhone: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur",
          },
        ],
        designPhone: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur",
          },
        ],
        monitorPhone: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur",
          },
        ],
        buildPhone: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur",
          },
        ],
        surveyPhone: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur",
          },
        ],
        thirdPhone: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur",
          },
        ],
        riskDescribe: [
          {
            required: true,
            message: "请输入具体风险描述",
            trigger: "blur",
          },
        ],
        designfirst: [
          {
            required: true,
            message: "请输入措施",
            trigger: "blur",
          },
        ],
        designsecond: [
          {
            required: true,
            message: "请输入措施",
            trigger: "blur",
          },
        ],
        supervisorfirst: [
          {
            required: true,
            message: "请输入措施",
            trigger: "blur",
          },
        ],
        supervisorsecond: [
          {
            required: true,
            message: "请输入措施",
            trigger: "blur",
          },
        ],
        constructionfirst: [
          {
            required: true,
            message: "请输入措施",
            trigger: "blur",
          },
        ],
        constructionsecond: [
          {
            required: true,
            message: "请输入措施",
            trigger: "blur",
          },
        ],
        surveyfirst: [
          {
            required: true,
            message: "请输入措施",
            trigger: "blur",
          },
        ],
        surveysecond: [
          {
            required: true,
            message: "请输入措施",
            trigger: "blur",
          },
        ],
        thirdPartyfirst: [
          {
            required: true,
            message: "请输入措施",
            trigger: "blur",
          },
        ],
        thirdPartysecond: [
          {
            required: true,
            message: "请输入措施",
            trigger: "blur",
          },
        ],
      },
      measuresList: {
        design: {
          first: [{ uuid: 1, measureContent: "" }],
          second: [{ uuid: 1, measureContent: "" }],
        },
        supervisor: {
          first: [{ uuid: 1, measureContent: "" }],
          second: [{ uuid: 1, measureContent: "" }],
        },
        construction: {
          first: [{ uuid: 1, measureContent: "" }],
          second: [{ uuid: 1, measureContent: "" }],
        },
        survey: {
          first: [{ uuid: 1, measureContent: "" }],
          second: [{ uuid: 1, measureContent: "" }],
        },
        thirdParty: {
          first: [{ uuid: 1, measureContent: "" }],
          second: [{ uuid: 1, measureContent: "" }],
        },
      },
      measuresForList: [
        { label: "设计措施", value: "design" },
        { label: "监理措施", value: "supervisor" },
        { label: "施工措施", value: "construction" },
        { label: "勘察措施", value: "survey" },
        { label: "第三方措施", value: "thirdParty" },
      ],
      riskControlLevelList: [],
      riskTypeList: [],
      riskLevelList: [],
      riskSubTypeList: [],
      isShowFile: false,
      fileList: [],
      constructionMethodList: [],
      subprojectList: [],
    };
  },
  watch: {
    "pageData.id": {
      handler(val) {
        if (val) {
          this.getData();
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
  },
  created() {
    for (let key in this.measuresList) {
      this.measuresList[key].first[0].uuid = this.uuid();
      this.measuresList[key].second[0].uuid = this.uuid();
    }
    this.queryLineSiteTreeData();
  },
  mounted() {
    this.getDict();
  },
  methods: {
    riskLevelCodeChange(val) {
      console.log(val);
      if (val == "headquarters_level" || val == "group_level") {
        this.isShowFile = true;
      } else {
        this.isShowFile = false;
      }
    },
    // 下载模板
    downModel() {},
    // 线路工点树数据查询
    queryLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    // 级联选择器事件
    handleLineSiteChange(record) {
      this.formData.siteUuid = "";
      this.formData.siteUuid = record[record.length - 1];
      this.formData.branchUuid = record[1];
      this.formData.lineUuid = record[0];
      // 这时是反转的
      let siteArr = this.getParentId(this.treeData, record[record.length - 1]);
      siteArr.reverse();
      this.formData.siteName = siteArr[siteArr.length - 1].name;
      this.formData.branchName = siteArr[1].name;
      this.formData.lineName = siteArr[0].name;
      console.log(this.formData);
    },
    // 文件相关
    handleFileUpload(e) {
      if (this.fileList.length) {
        this.fileList.shift();
      }
      if (this.pageData.fileUrl) {
        this.pageData.fileUrl = "";
      }
      this.fileList.push({
        name: e.name,
        fileUrl: e.url + "," + e.realUrl,
        url: e.url,
      });
      console.log(e);
    },
    handleFileDel(e) {
      let index = this.fileList.findIndex((v) => v.url === e.url);
      this.fileList.splice(index, 1);
    },
    downFile(url) {
      let fileUrl = url.split(",")[1];
      this.downLoadFile(fileUrl, this.pageData.fileName || "附件");
    },
    setRequire(val, i, type) {
      this.formData[i + type] = val;
    },
    addOne(type, number) {
      let obj = {};
      obj.uuid = this.uuid();
      obj.measureContent = "";
      this.measuresList[type][number].push(obj);
      console.log(this.measuresList[type][number]);
    },
    delOne(type, number, index) {
      this.measuresList[type][number].splice(index, 1);
    },
    uuid() {
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";

      var uuid = s.join("");
      return uuid;
    },
    getData() {
      // this.formData.responseRoleId = this.pageData.roleName;
      this.formData = { ...this.pageData };
      this.rolesData = [];
      if (this.formData.riskSubTypeCode) {
        getDictData(this.formData.riskTypeCode).then((res) => {
          this.riskSubTypeList = res.data;
        });
      }
      if (this.formData.id) {
        if (
          this.pageData.riskLevelCode == "headquarters_level" ||
          this.pageData.riskLevelCode == "group_level"
        ) {
          this.isShowFile = true;
        } else {
          this.isShowFile = false;
        }
        const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
        if (lineSiteData) {
          let siteArr = this.getParentId(lineSiteData, this.formData.siteUuid);
          siteArr.reverse();
          this.formData.siteUuid = siteArr[siteArr.length - 1].uuid;
          this.formData.branchUuid = siteArr[1].uuid;
          this.formData.lineUuid = siteArr[0].uuid;
          this.lineSite = [];
          this.lineSite.push(this.formData.lineUuid);
          this.lineSite.push(this.formData.branchUuid);
          if (siteArr.length == 4) {
            this.lineSite.push(siteArr[2].uuid);
          }
          this.lineSite.push(this.formData.siteUuid);
        }

        let req = null;
        if (this.dialogType == "待确定") {
          req = riskLibraryDetail(this.formData.id);
        } else {
          req = riskDetail(this.formData.id);
        }
        req.then((res) => {
          console.log(res);
          this.formData.measuresList = res.data.measuresList;
          // 将获取的措施塞入本地循环
          let arr = [
            this.measuresList.design.first,
            this.measuresList.design.second,
            this.measuresList.supervisor.first,
            this.measuresList.supervisor.second,
            this.measuresList.survey.first,
            this.measuresList.survey.second,
            this.measuresList.thirdParty.first,
            this.measuresList.thirdParty.second,
            this.measuresList.construction.first,
            this.measuresList.construction.second,
          ];
          let k = 0;
          for (let i = 1; i < 6; i++) {
            for (let j = 1; j < 3; j++) {
              this.filterMeasuresList(arr[k++], i, j);
            }
          }
          // 回显的措施放入
          for (let k in this.measuresList) {
            this.formData[k + "first"] = this.measuresList[
              k
            ].first[0].measureContent;
            this.formData[k + "second"] = this.measuresList[
              k
            ].second[0].measureContent;
          }
          console.log(this.measuresList);
        });
      }
      // 将获取的措施塞入本地循环结束
      getRoleApi({ unitType: this.pageData.responseUnitType })
        .then((res) => {
          if (res.code == 0) {
            this.rolesData = res.data;
          }
        })
        .catch((res) => {
          console.log(res.messafe);
        });
    },
    filterMeasuresList(array, measureType, subType) {
      let filterArray = this.formData.measuresList.filter((i) => {
        return i.measureType == measureType && i.subType == subType;
      });
      if (filterArray.length) {
        if (array[0].uuid) {
          // 如果第一个是本地空的措施，则删掉
          array.shift();
        }
        filterArray.forEach((i) => {
          array.push(i);
        });
      }
    },
    getDict() {
      const demo = [
        "riskType",
        "riskControlLevel",
        "riskLevel",
        "constructionMethod",
      ];
      demo.forEach((item) => {
        this.getDictList(item);
      });
    },
    getParentId(list, uuid) {
      for (let i in list) {
        if (list[i].uuid == uuid) {
          return [list[i]];
        }
        if (list[i].children) {
          let node = this.getParentId(list[i].children, uuid);
          if (node !== undefined) {
            return node.concat(list[i]);
          }
        }
      }
    },
    // 字典  unitType 单位类型
    getDictList(val) {
      getDictData(val).then((res) => {
        if (res.code == 0) {
          switch (val) {
            case "riskType":
              this.riskTypeList = res.data;
              break;
            case "riskControlLevel":
              this.riskControlLevelList = res.data;
              break;
            case "riskLevel":
              this.riskLevelList = res.data;
              break;
            case "constructionMethod":
              this.constructionMethodList = res.data;
              break;
            // case "":
            //   this.subprojectList = res.data;
            //   break;
          }
        }
      });
    },
    getRiskSubTypeList(val) {
      this.formData.riskSubTypeCode = "";
      getDictData(val).then((res) => {
        this.riskSubTypeList = res.data;
      });
    },
    submit() {
      this.$refs.formData.validate((valid) => {
        // 删除必填加的内容
        for (let k in this.measuresList) {
          delete this.formData[k + "first"];
          delete this.formData[k + "second"];
        }
        console.log(this.submitType);
        if (valid) {
          this.loading = true;
          let copyForm = Object.assign({}, this.formData);
          copyForm.measuresList = [];
          // 把措施转换一下
          for (let key in this.measuresList) {
            this.measuresList[key].first.forEach((i, index) => {
              if (i.measureContent) {
                let obj = {};
                obj.measureContent = i.measureContent;
                obj.subType = 1;
                obj.sort = index + 1;
                if (key == "design") {
                  obj.measureType = 1;
                } else if (key == "supervisor") {
                  obj.measureType = 2;
                } else if (key == "construction") {
                  obj.measureType = 3;
                } else if (key == "survey") {
                  obj.measureType = 4;
                } else if (key == "thirdParty") {
                  obj.measureType = 5;
                }
                copyForm.measuresList.push(obj);
              }
            });
            this.measuresList[key].second.forEach((i, index) => {
              if (i.measureContent) {
                let obj = {};
                obj.measureContent = i.measureContent;
                obj.subType = 2;
                obj.sort = index + 1;
                if (key == "design") {
                  obj.measureType = 1;
                } else if (key == "supervisor") {
                  obj.measureType = 2;
                } else if (key == "construction") {
                  obj.measureType = 3;
                } else if (key == "survey") {
                  obj.measureType = 4;
                } else if (key == "thirdParty") {
                  obj.measureType = 5;
                }
                copyForm.measuresList.push(obj);
              }
            });
          }
          copyForm.originalRiskLevelName = copyForm.originalRiskLevelCode;
          copyForm.restRiskLevelName = copyForm.restRiskLevelCode;
          if (copyForm.riskLevelCode) {
            copyForm.riskLevelName = this.riskControlLevelList.find((i) => {
              return i.code == copyForm.riskLevelCode;
            }).label;
          }
          if (copyForm.riskTypeCode) {
            copyForm.riskTypeName = this.riskTypeList.find((i) => {
              return i.code == copyForm.riskTypeCode;
            }).label;
          }
          if (copyForm.riskSubTypeCode) {
            copyForm.riskSubTypeName = this.riskSubTypeList.find((i) => {
              return i.code == copyForm.riskSubTypeCode;
            }).label;
          }
          if (copyForm.relationConstructMethodCode) {
            copyForm.relationConstructMethodName = this.constructionMethodList.find(
              (i) => {
                return i.code == copyForm.relationConstructMethodCode;
              }
            ).label;
          }
          // if (copyForm.subprojectCode) {
          //   copyForm.subprojectName = this.subprojectList.find((i) => {
          //     return i.code == copyForm.subprojectCode;
          //   }).label;
          // }
          if (this.isShowFile && this.fileList.length) {
            copyForm.fileName = this.fileList[0].name;
            copyForm.fileUrl = this.fileList[0].fileUrl;
          }
          if (this.submitType == "add") {
            copyForm.createdUserUuid = JSON.parse(
              localStorage.getItem("user")
            ).uuid;
            console.log(1);
            copyForm.id = "";
            riskLibraryAdd(copyForm)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.loading = true;
                  this.cancel();
                  this.$emit("CloseData");
                } else {
                  this.$message.error(res.message);
                }
              })
              .catch((res) => {
                this.$message.error(res.message);
              });
          } else if (this.submitType == "edit") {
            console.log(2);
            if (copyForm.measuresList.length) {
              copyForm.measuresList.forEach((i) => {
                i.riskDeafUuid = copyForm.riskDeafUuid;
              });
            }

            riskLibraryEdit(copyForm)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.loading = true;
                  this.cancel();
                  this.$emit("CloseData");
                } else {
                  this.$message.error(res.message);
                }
              })
              .catch((res) => {
                // this.cancel();
                this.$message.error(res.message);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancel() {
      this.reset();
      this.$emit("CloseData");
    },
    reset() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = "";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
.PostInfCs-input {
  .el-input--suffix .el-input__inner {
    width: 100%;
  }
  .el-col {
    padding: 0 20px;
  }
  .el-date-editor.el-input {
    width: 100%;
  }
  .sort {
    display: inline-block;
    width: 36px;
  }
}
.iconBtn {
  font-size: 28px;
  position: relative;
  top: 6px;
  color: #409eff;
  margin-left: 10px;
}
.redIconBtn {
  color: #ff0000;
}
.downText {
  cursor: pointer;
  color: #409eff;
}

.down {
  color: #439eff;
  cursor: pointer;
  margin-left: 10px;
}
</style>
