<template>
  <div class="video-container">
    <index-box :title="title">
      <div class="scroll-wrapper overflow-y-scroll">
        <div
          class="flex justify-between mb-4 video-list"
          v-for="(i, index) in userList"
          :key="index"
        >
          <div class="video-center">
            <span> 【空闲】 </span>
            <span> {{ i.realName }} </span>
            <span> {{ i.roles[0].roleName }} </span>
          </div>
          <div>
            <el-button type="primary" size="small" @click="call(i)"
              >视频</el-button
            >
          </div>
        </div>
      </div>
    </index-box>
    <RtcDia
      ref="rtcdia"
      v-show="rtcShow"
      :dataList="dataList"
      @endVoip="endVoip"
      @openVoip="openVoip"
    ></RtcDia>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import RtcDia from "@/components/rtc/index.vue";
import { getSiteUserInformation } from "@/api/eres";
export default {
  name: "video",
  components: {
    IndexBox,
    RtcDia,
  },
  data() {
    return {
      title: "工点人员信息",
      rtcShow: false,
      dataList: {
        userId: JSON.parse(localStorage.user).id,
        toId: "",
        toName: "",
      },
      userList: [],
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      console.log(this.$route.query.uuid, "陆游的参数uuid");
      getSiteUserInformation(this.$route.query.id).then((res) => {
        console.log(res);
        this.userList = res.data;
      });
      // let id = JSON.parse(localStorage.user).id;
      // this.userList = this.userList.filter((e) => {
      //   return e.id != id;
      // });
    },
    call(data) {
      this.dataList.toId = data.id;
      this.dataList.toName = data.realName;
      this.rtcShow = true;
      this.$refs.rtcdia.callVideo();
    },
    openVoip() {
      this.rtcShow = true;
    },
    endVoip() {
      this.rtcShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.video-container {
  .scroll-wrapper {
    color: #000;
    height: 42vh;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    .video-list {
      height: 28px;
      line-height: 28px;
    }
    .video-center {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
