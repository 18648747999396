<template>
  <div class="briefing">
    <el-dialog
      type="text"
      :title="title"
      :visible.sync="breVisible"
      width="50%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      center
      class="abow_dialog"
      v-if="breVisible"
    >
      <div class="briefong_auto" v-loading="loading">
        <!-- 二、防御情况 -->
        <div class="briefong_one">
          <div class="defense">一、防御情况</div>
          <el-form
            :model="briefing.defense.defense"
            ref="ValidateForm"
            class="demo-ruleForm viewForm"
            :disabled="readState"
          >
            <el-row>
              <el-col :span="10">
                <el-form-item label="应急响应启动时间">
                  <el-date-picker
                    size="mini"
                    style="width: 180px"
                    v-model="briefing.defense.startTime"
                    type="datetime"
                    placeholder="选择时间"
                    value-format="yyyy-MM-DD HH:mm:ss"
                    :disabled="true"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="类型">
                  <el-select
                    v-model="briefing.defense.responseType.id"
                    size="mini"
                    :disabled="true"
                  >
                    <el-option
                      v-for="item in typeList"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="级别">
                  <el-select
                    v-model="briefing.defense.responseLevel.id"
                    size="mini"
                    style="width: 100px"
                    :disabled="true"
                  >
                    <el-option
                      v-for="item in leveloptions"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="是否升级或降级">
                  <el-select
                    v-model="briefing.defense.relegationFlag"
                    size="mini"
                    style="width: 100px"
                    :disabled="true"
                  >
                    <el-option label="是" :value="true" :key="true"></el-option>
                    <el-option
                      label="否"
                      :value="false"
                      :key="false"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="mt-8">
              <el-col :span="24">
                <el-form-item label="1、地铁集团、建设公司领导检查">
                  <div
                    class="titcens"
                    @click="
                      jumpDeail(
                        briefing.defense.leaderCheckInfo
                          .threeCheckItemQuestionList
                      )
                    "
                  >
                    查看详情 ＞
                  </div>
                  <div class="content">
                    <el-table
                      :data="[briefing.defense.leaderCheckInfo]"
                      border
                      style="width: 99%; margin-left: 4px; margin-top: 8px"
                    >
                      <el-table-column
                        prop="checkSiteCount"
                        label="检查工点总数"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="hiddenDangerCount"
                        label="排查出问题数量"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="rectificationCount"
                        label="已整改问题数量"
                      >
                      </el-table-column>
                    </el-table>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="mt-8">
              <el-col :span="24">
                <el-form-item label="2、是否与外单位(街道、运营单位等)联合检查">
                  <div
                    class="titcens"
                    @click="
                      jumpDeail(
                        briefing.defense.externalUnitCheckInfo
                          .threeCheckItemQuestionList
                      )
                    "
                  >
                    查看详情 ＞
                  </div>
                  <div class="content">
                    <el-table
                      :data="briefing.defense.externalUnitCheckInfo.listData"
                      border
                      style="width: 99%; margin-left: 4px; margin-top: 8px"
                    >
                      <el-table-column
                        prop="checkSiteCount"
                        label="检查工点总数"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="hiddenDangerCount"
                        label="排查出问题数量"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="rectificationCount"
                        label="已整改问题数量"
                      >
                      </el-table-column>
                    </el-table>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="mt-8">
              <el-col :span="24">
                <el-form-item label="3、建管部领导检查">
                  <div
                    class="titcens"
                    @click="
                      jumpDeail(
                        briefing.defense.constManageLeaderCheckInfo
                          .threeCheckItemQuestionList
                      )
                    "
                  >
                    查看详情 ＞
                  </div>

                  <div class="content">
                    <el-table
                      :data="[briefing.defense.constManageLeaderCheckInfo]"
                      border
                      style="width: 99%; margin-left: 4px; margin-top: 8px"
                    >
                      <el-table-column
                        prop="checkSiteCount"
                        label="检查工点总数"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="hiddenDangerCount"
                        label="检查出问题数量"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="rectificationCount"
                        label="已整改问题数量"
                      >
                      </el-table-column>
                    </el-table>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="mt-8">
              <el-col :span="24">
                <el-form-item label="4、各施工、监理单位检查情况">
                  <div class="titcens" @click="jumpDeail(otherList)">
                    查看详情 ＞
                  </div>
                  <div class="content">
                    <el-table
                      :data="[briefing.defense.reportCheckInfo]"
                      border
                      style="width: 99%; margin-left: 4px; margin-top: 8px"
                    >
                      <el-table-column
                        prop="checkSiteCount"
                        label="检查工点总数"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="hiddenDangerCount"
                        label="排查出问题数量"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="rectificationCount"
                        label="已整改问题数量"
                      >
                      </el-table-column>
                    </el-table>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <el-divider class="mt-16"></el-divider>
        <!-- 三、受影响情况 -->
        <div class="briefong_one">
          <div class="defense">二、受影响情况</div>
          <el-radio-group
            v-model="briefing.affected.affectedFlag"
            :disabled="readState"
          >
            <el-radio :label="false" style="margin-top: 12px"
              >本次{{ briefing.defense.responseType.label
              }}{{
                briefing.defense.responseLevel.label
              }}级应急响应期间，本单位所辖项目未受到影响，无突发事件发生，总体安全可控。</el-radio
            ><br />
            <el-radio :label="true" style="margin-top: 12px"
              >填写受影响情况</el-radio
            >
          </el-radio-group>
          <div class="defenserty" v-if="briefing.affected.affectedFlag">
            <div class="water">
              施工项目是否积水
              <el-select
                v-model="briefing.affected.sitePondingFlag"
                size="mini"
                style="width: 100px"
                @change="watererd"
                :disabled="readState"
              >
                <el-option label="有" :value="true" :key="true"></el-option>
                <el-option label="无" :value="false" :key="false"></el-option>
              </el-select>
              <i
                v-if="briefing.affected.sitePondingFlag && !readState"
                class="el-icon-circle-plus-outline waeeter cursor-pointer"
                @click="wateradd"
              ></i>
            </div>
            <div v-if="briefing.affected.sitePondingFlag">
              <div
                class="conten_wer"
                v-for="(item, index) in briefing.affected.sitePondingList"
                :key="index + 2"
              >
                <div class="content">
                  <el-form
                    :model="item"
                    class="demo-form-inline viewForm"
                    :disabled="false"
                  >
                    <el-row>
                      <el-col :span="8">
                        <el-form-item
                          label="积水具体地点"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            v-model="item.address"
                            size="mini"
                            style="width: 100px"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="最大积水深度"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            v-model="item.value"
                            size="mini"
                            style="width: 100px"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item
                          label=" 积水情况及影响"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder="请输入内容"
                            v-model="item.description"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item
                          label=" 采取的应急措施及处理结果"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder="请输入内容"
                            v-model="item.result"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>

                  <i
                    class="el-icon-delete water cursor-pointer"
                    v-if="briefing.affected.sitePondingFlag && !readState"
                    @click="waterremove(item)"
                  ></i>
                </div>
              </div>
            </div>
            <div class="water">
              施工项目是否受台风影响
              <el-select
                v-model="briefing.affected.typhoonFlag"
                size="mini"
                style="width: 100px"
                @change="influenceed"
                :disabled="readState"
              >
                <el-option label="有" :value="true" :key="true"></el-option>
                <el-option label="无" :value="false" :key="false"></el-option>
              </el-select>
              <i
                v-if="briefing.affected.typhoonFlag && !readState"
                class="el-icon-circle-plus-outline waeeter cursor-pointer"
                @click="influenceadd"
              ></i>
            </div>
            <div>
              <div
                class="conten_wer"
                v-for="(item, index) in briefing.affected.typhoonList"
                :key="index + 3"
              >
                <div class="content" v-if="briefing.affected.typhoonFlag">
                  <el-form
                    :model="briefing.affected.typhoonList"
                    class="demo-form-inline viewForm"
                  >
                    <el-row>
                      <el-col :span="24">
                        <el-form-item
                          label=" 台风情况及影响"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder="请输入内容"
                            v-model="item.description"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item
                          label=" 采取的应急措施及处理结果"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder="请输入内容"
                            v-model="item.result"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                  <i
                    class="el-icon-delete influen cursor-pointer"
                    v-if="briefing.affected.typhoonFlag && !readState"
                    @click="influenceremove(item)"
                  ></i>
                </div>
              </div>
            </div>
            <div class="water">
              是否有其他原因引起的突发事件
              <el-select
                v-model="briefing.affected.otherEffectFlag"
                size="mini"
                style="width: 100px"
                @change="sudeed"
              >
                <el-option label="有" :value="true" :key="true"></el-option>
                <el-option label="无" :value="false" :key="false"></el-option>
              </el-select>
              <i
                v-if="briefing.affected.otherEffectFlag && !readState"
                class="el-icon-circle-plus-outline suddend cursor-pointer"
                @click="suddenadd"
              ></i>
            </div>
            <div
              class="conten_wer"
              v-for="(item, index) in briefing.affected.otherEffectList"
              :key="index + 4"
            >
              <div class="content" v-if="briefing.affected.otherEffectFlag">
                <el-form
                  :model="briefing.affected.otherEffectList"
                  class="demo-form-inline viewForm"
                  :disabled="readState"
                >
                  <el-row>
                    <el-col :span="24">
                      <el-form-item
                        label=" 事件情况及影响"
                        style="margin-top: 8px; margin-left: 4px"
                      >
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4 }"
                          placeholder="请输入内容"
                          v-model="item.description"
                        >
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item
                        label=" 采取的应急措施及处理结果"
                        style="margin-top: 8px; margin-left: 4px"
                      >
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4 }"
                          placeholder="请输入内容"
                          v-model="item.result"
                        >
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
                <i
                  class="el-icon-delete reovem"
                  v-if="briefing.affected.otherEffectFlag"
                  @click="suddenremove(item)"
                ></i>
              </div>
            </div>
          </div>
          <div class="water">
            <span class="defense">是否有当前未能处理完毕事项的工作计划:</span>
            <el-select
              v-model="briefing.affected.workPlanInFlag"
              size="mini"
              style="width: 100px"
              :disabled="readState"
            >
              <el-option label="有" :value="true" :key="true"></el-option>
              <el-option label="无" :value="false" :key="false"></el-option>
            </el-select>
          </div>
          <div
            class="content conten_wer"
            v-if="briefing.affected.workPlanInFlag"
          >
            <el-form
              :model="briefing.affected.workPlanInfo"
              class="demo-form-inline viewForm"
              :disabled="readState"
            >
              <el-row>
                <el-col :span="10">
                  <el-form-item
                    label="建管部责任人"
                    style="margin-top: 8px; margin-left: 4px"
                  >
                    <el-select
                      v-model="
                        briefing.affected.workPlanInfo
                          .constManagDeptPersonInChargeUuid
                      "
                      size="mini"
                      style="width: 150px"
                      clearable
                      filterable
                      @change="getusers($event, '建管部责任人')"
                    >
                      <el-option
                        v-for="item in jgList"
                        :key="item.uuid"
                        :label="item.realName"
                        :value="item.uuid"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="10">
                  <el-form-item label="联系电话" style="margin-top: 8px">
                    <el-input
                      size="mini"
                      style="width: 150px"
                      v-model="
                        briefing.affected.workPlanInfo
                          .constManagDeptPersonInChargePhone
                      "
                      oninput="if(value.length>5)value=value.slice(0,11)"
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      maxlength="11"
                      placeholder="请输入联系电话"
                      @change="phoneVerifys"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item
                    label=" 工作计划"
                    style="margin-top: 8px; margin-left: 4px"
                  >
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder="请输入内容"
                      v-model="briefing.affected.workPlanInfo.workPlan"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <el-divider></el-divider>
        <!-- 三、撤离人数 -->
        <div class="briefong_one">
          <div class="defense">三、撤离人数</div>
          <el-table
            :data="
              briefing.briefSummary && briefing.briefSummary.evacueesInfoList
                ? briefing.briefSummary.evacueesInfoList
                : []
            "
            border
            style="width: 100%; margin-top: 10px"
          >
            <el-table-column prop="siteName" label="工点名称">
            </el-table-column>
            <el-table-column prop="evacueesCount" label="撤离人数">
            </el-table-column>
          </el-table>
        </div>
        <el-divider></el-divider>
        <!-- 四、小结 -->
        <div class="briefong_one">
          <div class="defense">四、小结</div>
          <el-table
            :data="briefing.briefSummaryList"
            border
            style="width: 100%; margin-top: 10px"
          >
            <el-table-column prop="workGroupCount" label="派出工作组数">
            </el-table-column>
            <el-table-column prop="checkerCount" label="出动检查人数">
            </el-table-column>
            <el-table-column prop="siteCount" label="检查在建工点数">
            </el-table-column>
            <el-table-column prop="hiddenDangerCount" label="发现隐患数量">
            </el-table-column>
            <el-table-column prop="rectificationCount" label="已整改数量">
            </el-table-column>
            <!-- <el-table-column prop="evacueesCount" label="撤离人员">
            </el-table-column> -->
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submit(0)" :disabled="loading">保存</el-button>
        <el-button type="primary" @click="submit(1)" :disabled="loading"
          >提交</el-button
        >
      </span>
      <Detarl ref="DetarlViewModal"> </Detarl>
    </el-dialog>
  </div>
</template>

<script>
import {
  getInfo,
  SavedInfo,
  listSiteInfo,
  listCheckInfo,
} from "@/api/responseTem";
import { orgTypeList } from "@/api/ucenter";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { getDeptInfoById } from "@/api/org";
import Detarl from "./detail.vue";

export default {
  components: { Detarl },
  props: {
    bruefing: {
      type: Boolean,
      default: () => false,
    },
    rows: {
      type: Object,
      default: () => {},
    },
    // briefi: {
    //   type: Object,
    //   default: () => {}
    // }
  },
  watch: {
    bruefing: {
      handler() {
        this.breVisible = this.bruefing;
      },
      deep: true,
    },
    briefi: {
      handler() {
        this.briefing = this.briefi;
      },
      deep: true,
    },
  },
  data() {
    return {
      title: "三防工作简报",
      breVisible: false,
      url: "",
      briefing: {
        weatherOrOnDuty: {}, //水雨晴的台风情况
        defense: {
          practicable: [
            {
              date: "0",
              name: "0",
              address: "",
            },
          ],
          threeCheckItemQuestionList: {
            checkPeopleList: "",
            checkTime: "",
          },
          abarbeitung: "",
          leaderCheckInfo: {
            checkPeopleList: "",
            checkTime: "",
          },
          externalUnitCheckInfo: {
            checkPeopleList: "",
            checkTime: "",
          },
          emergency: {
            list: [{ numbers: "", trew: "" }],
          },
          supplies: {
            list: [{ numbers: "", trew: "", tefcr: "" }],
          },
        }, //防御情况
        affected: {
          affectedFlag: false,
          sitePondingFlag: false,
          typhoonFlag: false,
          otherEffectFlag: false,
          workPlanInFlag: false,
          sitePondingList: [],
          typhoonList: [],
          otherEffectList: [],
          workPlanInfo: {},
        }, //受影响情况
      },

      options: [],

      equipment: [],

      connecting: [],

      against: "",
      typeList: [],
      leveloptions: [],
      jgList: [],
      jlList: [],
      sgList: [],
      readState: false,
      model: {
        supervisionUuid: "",
        supervisionName: "",
      },
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true,
      },
      faltArr: [],
      listId: "",
      types: ["待整改", "待监理审批", "审批不通过", "完成"],
      loading: false,
      otherList: [],
    };
  },
  methods: {
    haserb() {
      this.breVisible = true;
    },
    handleFilling(record, state) {
      console.log(state);
      if (state == "read") this.readState = true;
      console.log(record);

      const userInfo = JSON.parse(localStorage.getItem("user"));
      if (userInfo) {
        this.model.user = {
          id: userInfo.id,
          name: userInfo.realName,
        };
      }
      queryDictByLabel({ label: "应急类别" }).then((res) => {
        queryDictNodesById({ id: res.data.id }).then((el) => {
          this.typeList = el.data;
        });
      });
      queryDictByLabel({ label: "当前等级" }).then((res) => {
        queryDictNodesById({ id: res.data.id }).then((el) => {
          this.leveloptions = el.data;
        });
      });
      orgTypeList({ orgType: 3 }).then((el) => {
        this.jgList = el.data;
      });
      orgTypeList({ orgType: 2 }).then((el) => {
        this.jlList = el.data;
      });
      orgTypeList({ orgType: 1 }).then((el) => {
        this.sgList = el.data;
      });
      this.queryDeptInfo();
      this.gitusData(record);
    },
    queryDeptInfo() {
      const user = JSON.parse(localStorage.getItem("user"));
      this.model.supervisionUuid = user.departmentId;
      getDeptInfoById(user.departmentId).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            this.model.supervisionName = res.data.departmentName;
          }
        }
      });
    },

    //查询已完成填报的工点
    getSite(id) {
      this.listId = id;
      let params = {
        id: id,
      };
      listSiteInfo(params).then((res) => {
        console.log(res);
        this.treeData = res.data;
      });
    },
    //选择工点事件
    handleLineSiteChange(val) {
      console.log(val);
      this.briefing.defense.threeCheckItemQuestionList = [];

      if (val.length > 0) {
        this.getSiteCheck(val);
      }
      this.$forceUpdate();
    },
    //根据选择的工点查询填报信息
    getSiteCheck(data) {
      let params = {
        id: this.listId,
        reportCheckLineSiteList: data,
      };

      listCheckInfo(params).then((res) => {
        console.log(res);
        this.briefing.defense.threeCheckItemQuestionList =
          res.data.threeCheckItemQuestionList;
        this.briefing.defense.threeCheckItemQuestionList.forEach((e) => {
          e.checkPeoples = e.checkPeopleList.join();
        });
        console.log(this.briefing.defense.threeCheckItemQuestionList);
        this.$forceUpdate();
      });
    },
    // 回显信息接口
    gitusData(record) {
      let params = {
        id: record.id,
      };
      getInfo(params).then((res) => {
        // this.briefing = Object.assign(this.briefing, res.data);
        if (res.code == 0) {
          this.briefing = res.data;
          this.$emit("btnClose");
          this.breVisible = true;
          if (!this.briefing.affected) {
            this.briefing.affected = {
              affectedFlag: false,
              sitePondingFlag: false,
              typhoonFlag: false,
              otherEffectFlag: false,
              workPlanInFlag: false,
              sitePondingList: [],
              typhoonList: [],
              otherEffectList: [],
              workPlanInfo: {},
            };
          }
          if (!this.briefing.defense.leaderCheckInfo) {
            this.briefing.defense.leaderCheckInfo = {
              checkPeopleList: [],
              checkPeoples: "",
              checkTime: "",
              hiddenDangerCount: 0,
              checkSiteCount: 0,
              rectificationCount: 0,
              listData: [
                {
                  hiddenDangerCount: 0,
                  rectificationCount: 0,
                },
              ],
              threeCheckItemQuestionList: [],
            };
          } else {
            this.briefing.defense.leaderCheckInfo.checkPeoples = this.briefing.defense.leaderCheckInfo.checkPeopleList.join();
            this.briefing.defense.leaderCheckInfo.listData = [
              {
                hiddenDangerCount: this.briefing.defense.leaderCheckInfo
                  .hiddenDangerCount,
                rectificationCount: this.briefing.defense.leaderCheckInfo
                  .rectificationCount,
              },
            ];
          }
          if (!this.briefing.defense.threeCheckItemQuestionList) {
            this.briefing.defense.threeCheckItemQuestionList = [
              {
                checkPeopleList: [],
                checkPeoplescheckPeoples: "",
                checkTime: "",
                positionCount: 0,
                hiddenDangerCount: 0,
                rectificationCount: 0,
                threeCheckItemQuestionList: [],
              },
            ];
          } else {
            this.otherList = [];
            if (this.briefing.defense.threeCheckItemQuestionList.length > 0) {
              this.briefing.defense.threeCheckItemQuestionList.forEach((el) => {
                this.otherList = this.otherList.concat(
                  el.threeCheckItemQuestionList
                );
              });
            }
            // this.briefing.defense.threeCheckItemQuestionList.forEach((e) => {
            //   e.checkPeoples = e.checkPeopleList.join();
            // });
          }
          if (!this.briefing.defense.externalUnitCheckInfo) {
            this.briefing.defense.externalUnitCheckInfo = {
              checkPeopleList: [],
              checkPeoples: "",
              checkTime: "",
              hiddenDangerCount: 0,
              rectificationCount: 0,
              checkSiteCount: 0,
              listData: [
                {
                  hiddenDangerCount: 0,
                  rectificationCount: 0,
                  checkSiteCount: 0,
                },
              ],
              threeCheckItemQuestionList: [],
            };
          } else {
            this.briefing.defense.externalUnitCheckInfo.checkPeoples = this.briefing.defense.externalUnitCheckInfo.checkPeopleList.join();
            this.briefing.defense.externalUnitCheckInfo.listData = [
              {
                hiddenDangerCount: this.briefing.defense.externalUnitCheckInfo
                  .hiddenDangerCount,
                rectificationCount: this.briefing.defense.externalUnitCheckInfo
                  .rectificationCount,
                checkSiteCount: this.briefing.defense.externalUnitCheckInfo
                  .checkSiteCount,
              },
            ];
          }
          if (!this.briefing.defense.reportCheckLineSiteList) {
            this.briefing.defense.reportCheckLineSiteList = [];
            this.briefing.defense.reportCheckLineSiteLists = [];
          } else {
            this.briefing.defense.reportCheckLineSiteLists = [];
            this.briefing.defense.reportCheckLineSiteList.forEach((e) => {
              if (e && e.uuid && e.uuid.length > 0) {
                this.briefing.defense.reportCheckLineSiteLists.push(e.uuid);
              }
              // e.checkPeoples = e.checkPeopleList.join();
            });
          }
          //2、建管部领导检查
          if (!this.briefing.defense.constManageLeaderCheckInfo) {
            this.briefing.defense.constManageLeaderCheckInfo = {
              checkPeopleList: [],
              checkPeoples: "",
              checkTime: "",
              hiddenDangerCount: 0,
              rectificationCount: 0,
              checkSiteCount: 0,
              positionCount: 0,
              listData: [
                {
                  hiddenDangerCount: 0,
                  rectificationCount: 0,
                },
              ],
              threeCheckItemQuestionList: [],
            };
          }

          console.log("===========================");
          console.log(this.briefing.defense.reportCheckLineSiteLists);
          if (!this.briefing.defense.constManageLeaderCheckFlag) {
            this.briefing.defense.constManageLeaderCheckFlag = false;
          }

          this.briefing.briefSummaryList = [this.briefing.briefSummary];
          this.briefing.weatherOrOnDutyList = [this.briefing.weatherOrOnDuty];

          console.log(this.briefing);
          this.getSite(record.id);
          this.$forceUpdate();
        } else {
          this.$message.error("数据请求错误，请联系管理员！");
          this.$emit("btnClose");
        }
      });
    },
    uploader(val) {
      console.log(val);
      this.url = val;
    },
    mergencyadd() {
      this.briefing.defense.reportOnDutyInfoList.push({ count: "", name: "" });
    },
    mergencyremov(val) {
      if (this.briefing.defense.emergency.list.length < 2) {
        return;
      } else {
        this.briefing.defense.emergency.list.splice(
          this.briefing.defense.emergency.list.indexOf(val),
          1
        );
      }
    },
    suppliesadd() {
      this.briefing.defense.reportMaterialInfoList.push({
        materialName: "",
        count: "",
        address: "",
      });
    },
    suppliesremove(val) {
      if (this.briefing.defense.supplies.list.length < 2) {
        return;
      } else {
        this.briefing.defense.supplies.list.splice(
          this.briefing.defense.supplies.list.indexOf(val),
          1
        );
      }
    },
    // radiowe() {
    //   if (this.briefing.affected.affectedFlag == 1) {
    //     this.briefing.affected.defenseew = false;
    //   } else {
    //     this.briefing.affected.defenseew = true;
    //   }
    // },
    // 施工项目是否受积水影响
    watererd() {
      if (this.briefing.affected.sitePondingFlag) {
        this.briefing.affected.sitePondingList.push({
          address: "",
          value: "",
          description: "",
          result: "",
        });
      } else {
        this.briefing.affected.sitePondingList = [];
      }
    },
    wateradd() {
      this.briefing.affected.sitePondingList.push({
        address: "",
        value: "",
        description: "",
        result: "",
      });
    },
    waterremove(val) {
      if (this.briefing.affected.sitePondingList.length < 2) {
        return;
      } else {
        this.briefing.affected.sitePondingList.splice(
          this.briefing.affected.sitePondingList.indexOf(val),
          1
        );
      }
    },
    // 施工项目是否受台风影响
    influenceed() {
      if (this.briefing.affected.typhoonFlag) {
        this.briefing.affected.typhoonList.push({
          address: "",
          value: "",
          description: "",
          result: "",
        });
      } else {
        this.briefing.affected.typhoonList = [];
      }
    },
    influenceadd() {
      this.briefing.affected.typhoonList.push({
        address: "",
        value: "",
        description: "",
        result: "",
      });
    },
    influenceremove(val) {
      if (this.briefing.affected.typhoonList.length < 2) {
        return;
      } else {
        this.briefing.affected.typhoonList.splice(
          this.briefing.affected.typhoonList.indexOf(val),
          1
        );
      }
    },
    // 其他原因突发事件
    sudeed() {
      if (this.briefing.affected.otherEffectFlag) {
        this.briefing.affected.otherEffectList.push({
          address: "",
          value: "",
          description: "",
          result: "",
        });
      } else {
        this.briefing.affected.otherEffectList = [];
      }
    },
    suddenadd() {
      this.briefing.affected.otherEffectList.push({
        address: "",
        value: "",
        description: "",
        result: "",
      });
    },
    suddenremove(val) {
      if (this.briefing.affected.otherEffectList.length < 2) {
        return;
      } else {
        this.briefing.affected.otherEffectList.splice(
          this.briefing.affected.otherEffectList.indexOf(val),
          1
        );
      }
    },
    // 未处理完毕的工作计划
    workerd() {
      if (this.briefing.affected.workPlanInFlag == "true") {
        this.briefing.affected.workPlanInFlag = true;
      } else {
        this.briefing.affected.workPlanInFlag = false;
      }
    },
    abarbeitungChange(row) {
      console.log(row);
      this.briefing.defense.practicable[0].address = row.address;
    },

    // 小结
    onInputChange(row) {
      this.briefing.summary.tableData[0].onInput = row.onInput;
    },
    checkChange(row) {
      this.briefing.summary.tableData[0].check = row.check;
    },
    troubleChange(row) {
      this.briefing.summary.tableData[0].trouble = row.trouble;
    },
    rectificationChange(row) {
      this.briefing.summary.tableData[0].rectification = row.rectification;
    },
    evacuationChange(row) {
      this.briefing.summary.tableData[0].evacuation = row.evacuation;
    },
    //提交
    submit(data) {
      console.log(data, this.briefing, "this.briefing");
      this.loading = true;
      this.briefing.saveState = data;
      this.briefing.defense.leaderCheckInfo.hiddenDangerCount = this.briefing.defense.leaderCheckInfo.listData[0].hiddenDangerCount;
      this.briefing.defense.leaderCheckInfo.rectificationCount = this.briefing.defense.leaderCheckInfo.listData[0].rectificationCount;

      this.briefing.defense.externalUnitCheckInfo.hiddenDangerCount = this.briefing.defense.externalUnitCheckInfo.listData[0].hiddenDangerCount;
      this.briefing.defense.externalUnitCheckInfo.rectificationCount = this.briefing.defense.externalUnitCheckInfo.listData[0].rectificationCount;

      //人员转换
      this.briefing.defense.leaderCheckInfo.checkPeopleList = this.briefing.defense.leaderCheckInfo.checkPeoples.split();
      this.briefing.defense.externalUnitCheckInfo.checkPeopleList = this.briefing.defense.externalUnitCheckInfo.checkPeoples.split();

      this.briefing.briefSummary =
        this.briefing.briefSummaryList &&
        this.briefing.briefSummaryList.length > 0
          ? this.briefing.briefSummaryList[0]
          : [];
      this.briefing.weatherOrOnDuty =
        this.briefing.weatherOrOnDutyList &&
        this.briefing.weatherOrOnDutyList.length > 0
          ? this.briefing.weatherOrOnDutyList[0]
          : null;

      this.briefing.defense.reportCheckLineSiteList = this.briefing.defense.reportCheckLineSiteLists;

      delete this.briefing.defense.reportCheckLineSiteLists;
      delete this.briefing.briefSummaryList;
      delete this.briefing.weatherOrOnDutyList;

      let params = { ...this.briefing, ...this.model };
      console.log(params);
      // console.log(this.row);

      SavedInfo(params)
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.code == 0) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.handleClose();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //保存
    saves() {
      SavedInfo(this.briefing).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.$emit("fingrest");
          this.briefing = {};
        }
      });
    },
    handleClose() {
      this.breVisible = false;
      this.$emit("fingrest");
    },
    theOneChange() {
      this.$forceUpdate();
    },
    //监管部手机
    phoneVerifys(val) {
      let flag = this.phoneVerify(val);
      if (flag) {
        this.briefing.affected.workPlanInfo.constManagDeptPersonInChargePhone =
          "";
      }
    },
    //手机号码验证
    phoneVerify(val) {
      if (
        !/^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/.test(
          val
        )
      ) {
        this.$message({
          message: "手机号码有误，请重新填写",
          type: "warning",
        });
        return true;
      }
    },
    //查看问题详情
    jumpDeail(val) {
      if (val && val.length > 0) {
        this.$refs.DetarlViewModal.handleFilling(val);
      } else {
        this.$message("此项无问题");
      }
    },
    //选择负责人
    getusers(val, name) {
      console.log(val);
      console.log(name);

      switch (name) {
        case "建管部责任人":
          this.jgList.forEach((el) => {
            if (el.uuid == val) {
              this.briefing.affected.workPlanInfo.constManagDeptPersonInChargePhone =
                el.mobile;
            }
          });

          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.briefong_auto {
  width: 90%;
  margin: auto;
  .briefong_one {
    .typhoon {
      font-size: 14px;
      color: #000000;
      font-weight: bold;
    }
    .defense {
      font-size: 14px;
      color: #000000;
      font-weight: bold;
    }
  }
}
.el-form-item {
  margin-bottom: 8px !important;
}
.content {
  width: 100%;
  // height: 300px;
  margin-top: 8px;
  position: relative;

  .water {
    color: #62b9e5;
    position: absolute;
    top: 37%;
    left: 104%;
  }
  .influen {
    color: #62b9e5;
    position: absolute;
    top: 37%;
    left: 104%;
  }
  .reovem {
    color: #62b9e5;
    position: absolute;
    top: 37%;
    left: 104%;
  }
}
.el-table {
  font-size: 12px !important;
}
.toimg {
  display: flex;
  margin-top: 8px;
  margin-left: 4px;
  .imglist {
    width: 60px;
    height: 60px;
    margin-left: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.poert {
  position: relative;
  .el-icon-circle-plus-outline {
    color: #62b9e5;
    position: absolute;
    top: 33%;
    left: 60%;
  }
  .el-icon-delete {
    color: #62b9e5;
    position: absolute;
    top: 33%;
    left: 65%;
  }
  .supplie {
    top: 33%;
    left: 85%;
  }
  .suppiet {
    top: 33%;
    left: 90%;
  }
}

.water {
  margin-top: 40px;
  position: relative;
  font-weight: bold;
  color: #000;
  .waeeter {
    color: #62b9e5;
    position: absolute;
    top: 29%;
    left: 45%;
  }
  .suddend {
    color: #62b9e5;
    position: absolute;
    top: 29%;
    left: 50%;
  }
}
.el-radio {
  margin-right: 150px !important;
}
.titcens {
  color: #62b9e5;
  text-align: right;
  cursor: pointer;
}
.conten_wer {
  border: 1px solid rgb(189, 186, 186);
  padding: 10px;
  margin-top: 20px;
}
</style>
