<template>
  <div
    style="wieth: 100%; height: 100%; border-radius: 10px; position: relative"
  >
    <baidu-map
      :mapStyle="mapStyle"
      :center="page.center"
      :scroll-wheel-zoom="true"
      :zoom="page.zoom"
      class="map"
      @ready="handler"
      :mapClick="false"
    >
      <bm-boundary
        name="广州市越秀区"
        :strokeWeight="2"
        fillColor="rgba(0,0,0)"
        :fillOpacity="0.5"
        strokeColor="rgb(69, 133, 159)"
      >
      </bm-boundary>
      <bm-boundary
        name="广州市天河区"
        :strokeWeight="2"
        fillColor="rgba(0,0,0)"
        :fillOpacity="0.5"
        strokeColor="rgb(69, 133, 159)"
      >
      </bm-boundary>
      <bm-boundary
        name="广州市荔湾区"
        :strokeWeight="2"
        fillColor="rgba(0,0,0)"
        :fillOpacity="0.5"
        strokeColor="rgb(69, 133, 159)"
      >
      </bm-boundary>
      <bm-boundary
        name="广州市海珠区"
        :strokeWeight="2"
        fillColor="rgba(0,0,0)"
        :fillOpacity="0.5"
        strokeColor="rgb(69, 133, 159)"
      >
      </bm-boundary>
      <bm-boundary
        name="广州市白云区"
        :strokeWeight="2"
        fillColor="rgba(0,0,0)"
        :fillOpacity="0.5"
        strokeColor="rgb(69, 133, 159)"
      >
      </bm-boundary>
      <bm-boundary
        name="广州市黄埔区"
        :strokeWeight="2"
        fillColor="rgba(0,0,0)"
        :fillOpacity="0.5"
        strokeColor="rgb(69, 133, 159)"
      >
      </bm-boundary>
      <bm-boundary
        name="广州市番禺区"
        :strokeWeight="2"
        fillColor="rgba(0,0,0)"
        :fillOpacity="0.5"
        strokeColor="rgb(69, 133, 159)"
      >
      </bm-boundary>
      <bm-boundary
        name="广州市花都区"
        :strokeWeight="2"
        fillColor="rgba(0,0,0)"
        :fillOpacity="0.5"
        strokeColor="rgb(69, 133, 159)"
      >
      </bm-boundary>
      <bm-boundary
        name="广州市南沙区"
        :strokeWeight="2"
        fillColor="rgba(0,0,0)"
        :fillOpacity="0.5"
        strokeColor="rgb(69, 133, 159)"
      >
      </bm-boundary>
      <bm-boundary
        name="广州市从化区"
        :strokeWeight="2"
        fillColor="rgba(0,0,0)"
        :fillOpacity="0.5"
        strokeColor="rgb(69, 133, 159)"
      >
      </bm-boundary>
      <bm-boundary
        name="广州市增城区"
        :strokeWeight="2"
        fillColor="rgba(0,0,0)"
        :fillOpacity="0.5"
        strokeColor="rgb(69, 133, 159)"
      >
      </bm-boundary>
      <!-- 文本标注 -->
      <bm-label
        content="越秀区"
        :position="{ lng: 113.268123, lat: 23.146236 }"
        :labelStyle="mapLableStyle"
        title="越秀区"
      />
      <bm-label
        content="天河区"
        :position="{ lng: 113.370637, lat: 23.167025 }"
        :labelStyle="mapLableStyle"
        title="天河区"
      />
      <bm-label
        content="荔湾区"
        :position="{ lng: 113.227214, lat: 23.087383 }"
        :labelStyle="mapLableStyle"
        title="荔湾区"
      />
      <bm-label
        content="海珠区"
        :position="{ lng: 113.323253, lat: 23.089779 }"
        :labelStyle="mapLableStyle"
        title="海珠区"
      />
      <bm-label
        content="白云区"
        :position="{ lng: 113.318132, lat: 23.297296 }"
        :labelStyle="mapLableStyle"
        title="白云区"
      />
      <bm-label
        content="黄埔区"
        :position="{ lng: 113.489116, lat: 23.186817 }"
        :labelStyle="mapLableStyle"
        title="黄埔区"
      />
      <bm-label
        content="番禺区"
        :position="{ lng: 113.360864, lat: 22.991747 }"
        :labelStyle="mapLableStyle"
        title="番禺区"
      />
      <bm-label
        content="花都区"
        :position="{ lng: 113.215079, lat: 23.454135 }"
        :labelStyle="mapLableStyle"
        title="花都区"
      />
      <bm-label
        content="南沙区"
        :position="{ lng: 113.52971, lat: 22.808729 }"
        :labelStyle="mapLableStyle"
        title="南沙区"
      />
      <bm-label
        content="从化区"
        :position="{ lng: 113.557368, lat: 23.61466 }"
        :labelStyle="mapLableStyle"
        title="从化区"
      />
      <bm-label
        content="增城区"
        :position="{ lng: 113.7448, lat: 23.36165 }"
        :labelStyle="mapLableStyle"
        title="增城区"
      />
      <div v-if="isSHowlinesite">
        <!--工点图标-->
        <bm-marker
          v-for="data in workPoints"
          :key="data.uuid"
          :position="{ lng: data.coord[0], lat: data.coord[1] }"
          :dragging="false"
          :title="data.name"
          :icon="{
            url: data.url,
            size: { width: 15, height: 15 },
          }"
        >
        </bm-marker>
        <!-- 线路 -->
        <bm-polyline
          v-for="(data, index) of polylineList"
          :key="index"
          :path="data.polylinePath"
          :stroke-color="data.lineColors"
          :stroke-opacity="1"
          :stroke-weight="5"
          :editing="false"
        >
        </bm-polyline>
      </div>
      <!-- 气象标点 -->
      <div v-for="item of weatherData" :key="item.cityName">
        <div v-for="(point, key, index) in item.weatherData" :key="index">
          <!-- 风力图标 -->
          <bm-marker
            v-if="key == 'wind' && isShowWind"
            :rotation="setWindDirection(point.direction)"
            :position="point.position"
            :icon="{
              url: returnWeatherIcon(key),
              size: { width: 88, height: 60 },
            }"
            :title="item.cityName"
          >
            <bm-label
              :content="point.windP + 'm/s'"
              :labelStyle="mapstylewhite"
              :offset="{ width: 27, height: 80 }"
            />
          </bm-marker>
          <!-- 温度图标 -->
          <bm-marker
            v-else-if="key == 'temperature' && isSHowTemp"
            :position="point.position"
            :icon="{
              url: returnWeatherIcon(key),
              size: { width: 26, height: 52 },
            }"
            :title="item.cityName"
          >
            <bm-label
              :content="point.count + '℃'"
              :labelStyle="mapstylewhite"
              :offset="{ width: 0, height: 65 }"
            />
          </bm-marker>
          <!-- <bm-marker v-else  :position="point.position"></bm-marker> -->
        </div>
      </div>
    </baidu-map>
    <div class="fixed inset-x-0 bottom-3">
      <div class="flex justify-center">
        <ul class="flex">
          <li
            class="flex justify-center items-center btn-item"
            :class="isShowRain ? 'btn-item-active' : ''"
            @click="onTypeChange(0)"
          >
            <img src="@/assets/yuliang.png" alt="" /><span
              style="margin-right:10px;margin-left:10px"
              >雨量</span
            >
          </li>
          <li
            class="flex justify-center items-center btn-item"
            :class="isShowWind ? 'btn-item-active' : ''"
            @click="onTypeChange(1)"
          >
            <img src="@/assets/fengli.png" alt="" /><span
              style="margin-right:10px;margin-left:10px"
              >风力</span
            >
          </li>
          <li
            class="flex justify-center items-center btn-item"
            :class="isSHowTemp ? 'btn-item-active' : ''"
            @click="onTypeChange(2)"
          >
            <img src="@/assets/wendu.png" alt="" /><span
              style="margin-right:10px;margin-left:10px"
              >温度</span
            >
          </li>
          <li
            class="flex justify-center items-center btn-item"
            :class="isSHowlinesite ? 'btn-item-active' : ''"
            @click="onTypeChange(3)"
          >
            <img src="@/assets/linesite.png" alt="" /><span
              style="margin-right:10px;margin-left:10px"
              >线路</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="panel fixed bottom-3 right-3">
      <div class="flex flex-col h-full">
        <p class="panel-title flex justify-center items-center text-white">
          气象预警信号
        </p>
        <div class="panel-content flex-1 p-4 flex flex-col">
          <div class="img-wrapper flex" v-if="warnIconList.length > 0">
            <img
              v-for="(item, index) of warnIconList"
              :key="index"
              :src="item.icon"
              alt=""
              :class="index != 0 ? 'ml-2' : ''"
            />
          </div>
          <ul class="city-list mt-2 flex flex-col flex-auto">
            <li
              class="flex flex-1"
              v-for="item of cityWarnList"
              :key="item.cityName"
            >
              <div class="label flex text-center">{{ item.cityName }}</div>
              <div class="flex-1 leavel-wrapper flex items-center">
                <ul class="w-full flex justify-between">
                  <li
                    v-for="(i, k) of 4"
                    :key="k"
                    :class="returnWarnColor(item.warnList, k)"
                  ></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//高德坐标转百度（传入经度、纬度）
function bd_encrypt(gg_lng, gg_lat) {
  var X_PI = (Math.PI * 3000.0) / 180.0;
  var x = gg_lng,
    y = gg_lat;
  var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
  var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
  var bd_lng = z * Math.cos(theta) + 0.0065;
  var bd_lat = z * Math.sin(theta) + 0.006;
  return {
    lat: bd_lng,
    lng: bd_lat,
  };
}

import { mapStyle } from "../../mapStyle";
import { getCityLive, getActiveWarn } from "@/api/proof";
import { tripleTree } from "@/api/map";

import GHCJ from "@/assets/lineData/GHCJ.json";
import threeLineDYD from "@/assets/lineData/3lineDYD.json";
import fiveLineDYD from "@/assets/lineData/5lineDataDYD.json";
import sevensLineRQ from "@/assets/lineData/7lineEQ.json";
import tenLine from "@/assets/lineData/10line.json";
import elevenLine from "@/assets/lineData/11line.json";
import twelveLine from "@/assets/lineData/12line.json";
import thirteenLine from "@/assets/lineData/13lineER.json";
import twentytwoAndeighteenLine from "@/assets/lineData/18and22line.json";
import FBCJ from "@/assets/lineData/FBCJ.json";

export default {
  name: "WeatherPage",
  data() {
    return {
      mapStyle,
      mapLableStyle: {
        color: "rgb(60, 106, 155)",
        fontSize: "20px",
        border: "none",
        background: "none",
      },
      mapstylewhite: {
        color: "white",
        fontSize: "14px",
        border: "none",
        background: "none",
      },
      page: {
        center: { lng: 0, lat: 0 },
        zoom: 8,
      },
      weatherData: [],
      warnIconList: [], // 预警图片集合
      isShowWind: true,
      isSHowTemp: true,
      isShowRain: true,
      isSHowlinesite: false,
      cityWarnList: [
        {
          cityName: "越秀",
          warnList: [],
          level: 0,
        },
        {
          cityName: "天河",
          warnList: [],
          level: 0,
        },
        {
          cityName: "荔湾",
          warnList: [],
          level: 0,
        },
        {
          cityName: "海珠",
          warnList: [],
          level: 0,
        },
        {
          cityName: "白云",
          warnList: [],
          level: 0,
        },
        {
          cityName: "黄埔",
          warnList: [],
          level: 0,
        },
        {
          cityName: "番禺",
          warnList: [],
          level: 0,
        },
        {
          cityName: "花都",
          warnList: [],
          level: 0,
        },
        {
          cityName: "南沙",
          warnList: [],
          level: 0,
        },
        {
          cityName: "从化",
          warnList: [],
          level: 0,
        },
        {
          cityName: "增城",
          warnList: [],
          level: 0,
        },
      ],
      // 工点坐标集合
      workPoints: [],
      // 线路坐标集合
      polylineList: [],
    };
  },
  mounted() {
    this.getWeatherInfo();
    this.queryWeatherWarn();
    this.queryLineSiteTree(); //查询线路工点
  },
  methods: {
    // 查询地铁线路工点树
    queryLineSiteTree() {
      const lineSiteData = JSON.parse(localStorage.getItem("DetailsTree"));
      console.log("=====", lineSiteData);
      let list = [];
      if (lineSiteData) {
        list = lineSiteData;
        this.mapTree(list);
      } else {
        tripleTree({}).then((res) => {
          if (res.code == 0) {
            localStorage.setItem("DetailsTree", JSON.stringify(res.data));
            list = res.data;
            this.mapTree(list);
          }
        });
      }
    },
    treeToList(list) {
      let res = [];
      for (const item of list) {
        const { children, ...i } = item;
        if (children && children.length) {
          res = res.concat(this.treeToList(children));
        }
        res.push(i);
      }
      return res;
    },
    //地图绘点
    mapTree(data) {
      // 线路
      let lineNameList = [
        "芳白城际",
        "5号线东延段",
        "14号线二期",
        "18和22号线",
        "广花城际",
        "13号线二期",
        "3号线东延段",
        "10号线",
        "11号线",
        "12号线",
        "7号线二期",
      ];
      lineNameList.forEach((item) => {
        if (item == "芳白城际") {
          this.polylineList.push(
            {
              polylinePath: FBCJ.芳白城际右线,
              lineColors: "#00a5ff",
            },
            {
              polylinePath: FBCJ.芳白城际左线,
              lineColors: "#00a5ff",
            }
          );
        } else if (item == "5号线东延段") {
          this.polylineList.push(
            {
              polylinePath: fiveLineDYD.five号线东延1,
              lineColors: "#45d6b7",
            },
            {
              polylinePath: fiveLineDYD.five号线东延2,
              lineColors: "#45d6b7",
            },
            {
              polylinePath: fiveLineDYD.five号线东延3,
              lineColors: "#45d6b7",
            },
            {
              polylinePath: fiveLineDYD.five号线东延4,
              lineColors: "#45d6b7",
            },
            {
              polylinePath: fiveLineDYD.five号线东延5,
              lineColors: "#45d6b7",
            }
          );
        } else if (item == "14号线二期") {
          console.log("14号线只有工点没有线路#ff6600");
        } else if (item == "18和22号线") {
          this.polylineList.push(
            {
              polylinePath: twentytwoAndeighteenLine.twentytwo号线右线,
              lineColors: "#6054ff",
            },
            {
              polylinePath: twentytwoAndeighteenLine.twentytwo号线左线,
              lineColors: "#6054ff",
            },
            {
              polylinePath: twentytwoAndeighteenLine.eighteen号线右线,
              lineColors: "#6054ff",
            },
            {
              polylinePath: twentytwoAndeighteenLine.eighteen号线左线,
              lineColors: "#6054ff",
            }
          );
        } else if (item == "广花城际") {
          this.polylineList.push(
            {
              polylinePath: GHCJ.广花城际右线,
              lineColors: "#006aff",
            },
            {
              polylinePath: GHCJ.广花城际左线,
              lineColors: "#006aff",
            },
            {
              polylinePath: GHCJ.广花城际出入段线1,
              lineColors: "#006aff",
            },
            {
              polylinePath: GHCJ.广花城际出入段线2,
              lineColors: "#006aff",
            },
            {
              polylinePath: GHCJ.广花城际出入段线3,
              lineColors: "#006aff",
            },
            {
              polylinePath: GHCJ.广花城际出入段线4,
              lineColors: "#006aff",
            }
          );
        } else if (item == "13号线二期") {
          this.polylineList.push(
            {
              polylinePath: thirteenLine.thirteen号线二期8,
              lineColors: "#8700e0",
            },
            {
              polylinePath: thirteenLine.thirteen号线二期3,
              lineColors: "#8700e0",
            },
            {
              polylinePath: thirteenLine.thirteen号线二期6,
              lineColors: "#8700e0",
            },
            {
              polylinePath: thirteenLine.thirteen号线二期1,
              lineColors: "#8700e0",
            },
            {
              polylinePath: thirteenLine.thirteen号线二期4,
              lineColors: "#8700e0",
            },
            {
              polylinePath: thirteenLine.thirteen号线二期7,
              lineColors: "#8700e0",
            },
            {
              polylinePath: thirteenLine.thirteen号线二期2,
              lineColors: "#8700e0",
            },
            {
              polylinePath: thirteenLine.thirteen号线二期5,
              lineColors: "#8700e0",
            }
          );
        } else if (item == "3号线东延段") {
          this.polylineList.push(
            {
              polylinePath: threeLineDYD.三号线东延段左线1,
              lineColors: "#00d400",
            },
            {
              polylinePath: threeLineDYD.三号线东延段右线,
              lineColors: "#00d400",
            },
            {
              polylinePath: threeLineDYD.三号线东延段停车场支线1,
              lineColors: "#00d400",
            },
            {
              polylinePath: threeLineDYD.三号线东延段停车场支线2,
              lineColors: "#00d400",
            }
          );
        } else if (item == "10号线") {
          this.polylineList.push(
            {
              polylinePath: tenLine.ten号线左线,
              lineColors: "#f7e51b",
            },
            {
              polylinePath: tenLine.ten号线右线,
              lineColors: "#f7e51b",
            }
          );
        } else if (item == "11号线") {
          this.polylineList.push(
            {
              polylinePath: elevenLine.eleven号线1,
              lineColors: "#fc8421",
            },
            {
              polylinePath: elevenLine.eleven号线2,
              lineColors: "#fc8421",
            },
            {
              polylinePath: elevenLine.eleven号线3,
              lineColors: "#fc8421",
            },
            {
              polylinePath: elevenLine.eleven号线4,
              lineColors: "#fc8421",
            },
            {
              polylinePath: elevenLine.eleven号线5,
              lineColors: "#fc8421",
            },
            {
              polylinePath: elevenLine.eleven号线6,
              lineColors: "#fc8421",
            },
            {
              polylinePath: elevenLine.eleven号线7,
              lineColors: "#fc8421",
            }
          );
        } else if (item == "12号线") {
          this.polylineList.push(
            {
              polylinePath: twelveLine.twelve号线1,
              lineColors: "#f5b400",
            },
            {
              polylinePath: twelveLine.twelve号线2,
              lineColors: "#f5b400",
            },
            {
              polylinePath: twelveLine.twelve号线3,
              lineColors: "#f5b400",
            },
            {
              polylinePath: twelveLine.twelve号线4,
              lineColors: "#f5b400",
            },
            {
              polylinePath: twelveLine.twelve号线5,
              lineColors: "#f5b400",
            },
            {
              polylinePath: twelveLine.twelve号线6,
              lineColors: "#f5b400",
            },
            {
              polylinePath: twelveLine.twelve号线7,
              lineColors: "#f5b400",
            },
            {
              polylinePath: twelveLine.twelve号线8,
              lineColors: "#f5b400",
            }
          );
        } else if (item == "7号线二期") {
          this.polylineList.push(
            {
              polylinePath: sevensLineRQ.seven号线二期1,
              lineColors: "#e06060",
            },
            {
              polylinePath: sevensLineRQ.seven号线二期2,
              lineColors: "#e06060",
            },
            {
              polylinePath: sevensLineRQ.seven号线二期3,
              lineColors: "#e06060",
            },
            {
              polylinePath: sevensLineRQ.seven号线二期4,
              lineColors: "#e06060",
            },
            {
              polylinePath: sevensLineRQ.seven号线二期5,
              lineColors: "#e06060",
            }
          );
        }
      });

      //工点
      // let con = list.filter((e) => e.name == "广州市轨道交通十号线");
      let colors = "#01963F";
      let url = require("../../../assets/map/subwayIcon.png");
      data.forEach((e) => {
        switch (e.name) {
          case "广州市轨道交通十八号线":
            colors = "#01963F";
            url = require("../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通13号线二期线路":
            colors = "#026CB6";
            url = require("../../../assets/map/subwayIcon.png");
            break;
          case "广州市城市轨道交通十四号线二期工程":
            colors = "#956339";
            url = require("../../../assets/map/subwayIcon.png");
            break;
          case "广州东至花都天贵城际项目":
            colors = "#901D42";
            url = require("../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通二十二号线":
            colors = "#E6037D";
            url = require("../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通十二号线及同步实施工程总承包项目":
            colors = "#950885";
            url = require("../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通五号线东延段及同步实施工程":
            colors = "#F977A3";
            url = require("../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通十号线":
            colors = "#99D317";
            url = require("../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通7号线二期线路":
            colors = "#B159D4";
            url = require("../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通11号线路":
            colors = "#55CAE2";
            url = require("../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通三号线东延段":
            colors = "#5E5EF7";
            url = require("../../../assets/map/subwayIcon.png");
            break;
          case "芳白城际":
            colors = "#95D30C";
            url = require("../../../assets/map/subwayIcon.png");
            break;
          default:
            break;
        }
        const nodes = this.treeToList(e.children);
        for (let item of nodes) {
          // 将返回的围栏坐标集合并到
          if (item.lat != 0) {
            let p = bd_encrypt(item.lng, item.lat);

            // 添加工点
            item.coord = [p.lat, p.lng];
            // item.coord = [p.lng, p.lat];

            item.url = url;
            item.infoWindow = {
              show: false,
            };
            this.workPoints.push(item);
          }
          /* if (item.fance != null) {
            let line = {
              id: e.id,
              name: e.name,
              polylinePath: [],
              color: colors,
            };
            item.fance.forEach((el) => {
              // let q = bd_encrypt(el.lat, el.lng);
              let q = bd_encrypt(el.lng, el.lat);
              let d = { lng: q.lat, lat: q.lng };
              line.polylinePath.push(d);
            });
            this.polylineList.push(line);
          } */
        }
      });
    },
    //获取父组件传递的ws数据
    getwsData(res) {
      let that = this;
      if (
        res.data != "PING" &&
        res.data != "suc|欢迎登录广州地铁安全风险监管工具消息中心"
      ) {
        let usData = JSON.parse(res.data);
        let datas = usData.body;
        if (datas && datas.coord) {
          let p = bd_encrypt(datas.coord[1], datas.coord[0]);
          console.log(p);
          datas.coord = [p.lat, p.lng];
        }
        if (usData.msgType && usData.msgType === "TRUCK_COORD") {
          //实时位置推送
          if (that.markerList.length > 0) {
            let u = that.markerList.find((e) => e.truckNum === datas.truckNum);
            if (u) {
              u.msgType = usData.msgType;
              u.url = require("../../../assets/map/dingw-blue.png");
              u.coord = datas.coord;
            } else {
              datas.msgType = usData.msgType;
              datas.url = require("../../../assets/map/dingw-blue.png");
              that.markerList.push(datas);
            }
          } else {
            datas.msgType = usData.msgType;
            datas.url = require("../../../assets/map/dingw-blue.png");
            that.markerList.push(datas);
          }
        } else if (
          //超时报警推送和 位置偏移推送
          usData.msgType === "TIMEOUT_ALERT" ||
          usData.msgType === "MOVE_ALERT" ||
          usData.msgType === "ADV_FINISH_TASK"
        ) {
          let u = that.markerList.find((e) => e.truckNum === datas.truckNum);
          if (u) {
            u.msgType = usData.msgType;
            u.url = require("../../../assets/map/dingw-red.png");
          } else {
            if (
              usData.msgType === "MOVE_ALERT" ||
              usData.msgType === "ADV_FINISH_TASK"
            ) {
              datas.msgType = usData.msgType;
              datas.url = require("../../../assets/map/dingw-red.png");
              that.markerList.push(datas);
            }
          }
        } else if (usData.msgType === "FINISHED_TASK") {
          //任务完成推送
          let u = that.markerList.find((e) => e.truckNum === datas.truckNum);
          console.log(u);
          if (u) {
            that.markerList = that.markerList.filter(
              (item) => item.truckNum != u.truckNum
            );
          }
        }
      }
    },
    //地图加载后事件
    handler({ BMap, map }) {
      console.log(BMap);
      map.setMapStyleV2({
        styleId: "565ab0773031556035c58e365ee8547a",
      });

      this.page = {
        start: "",
        end: "",
        center: { lng: 113.3539488029, lat: 23.0807270045 },
        zoom: 12,
      };
    },
    async getWeatherInfo() {
      const cityList = [
        {
          cityName: "越秀",
          isShow: false,
          weatherData: {
            wind: {
              direction: "", // 方向
              windP: 0, //风速
              level: "", // 等级
              position: {
                lng: 113.292295,
                lat: 23.133286,
              }, // 固定的
            },
            temperature: {
              count: "",
              position: {
                lng: 113.282425,
                lat: 23.15201,
              },
            },
            rain: {
              count: "",
              position: {
                lng: 113.292295,
                lat: 23.133286,
              },
            },
          },
        },
        {
          cityName: "天河",
          isShow: false,
          weatherData: {
            wind: {
              direction: "", // 方向
              windP: 0, //风速
              level: "", // 等级
              position: {
                lng: 113.354307,
                lat: 23.155931,
              }, // 固定的
            },
            temperature: {
              count: "",
              position: {
                lng: 113.405309,
                lat: 23.157611,
              },
            },
            rain: {
              count: "",
              position: {
                lng: 113.354307,
                lat: 23.155931,
              },
            },
          },
        },
        {
          cityName: "荔湾",
          isShow: false,
          weatherData: {
            wind: {
              direction: "", // 方向
              windP: 0, //风速
              level: "", // 等级
              position: {
                lng: 113.242301,
                lat: 23.082101,
              }, // 固定的
            },
            temperature: {
              count: "",
              position: {
                lng: 113.23785,
                lat: 23.12055,
              },
            },
            rain: {
              count: "",
              position: {
                lng: 113.242301,
                lat: 23.082101,
              },
            },
          },
        },
        {
          cityName: "海珠",
          isShow: false,
          weatherData: {
            wind: {
              direction: "", // 方向
              windP: 0, //风速
              level: "", // 等级
              position: {
                lng: 113.347796,
                lat: 23.086696,
              }, // 固定的
            },
            temperature: {
              count: "",
              position: {
                lng: 113.295463,
                lat: 23.086042,
              },
            },
            rain: {
              count: "",
              position: {
                lng: 113.347796,
                lat: 23.086696,
              },
            },
          },
        },
        {
          cityName: "白云",
          isShow: false,
          weatherData: {
            wind: {
              direction: "", // 方向
              windP: 0, //风速
              level: "", // 等级
              position: { lng: 113.37221, lat: 23.324305 }, // 固定的
            },
            temperature: {
              count: "",
              position: { lng: 113.292802, lat: 23.254803 }, // 固定的
            },
            rain: {
              count: "",
              position: { lng: 113.37221, lat: 23.324305 }, // 固定的
            },
          },
        },
        {
          cityName: "黄埔",
          isShow: false,
          weatherData: {
            wind: {
              direction: "", // 方向
              windP: 0, //风速
              level: "", // 等级
              position: {
                lng: 113.526004,
                lat: 23.223811,
              }, // 固定的
            },
            temperature: {
              count: "",
              position: {
                lng: 113.497863,
                lat: 23.154235,
              },
            },
            rain: {
              count: "",
              position: {
                lng: 113.526004,
                lat: 23.223811,
              },
            },
          },
        },
        {
          cityName: "番禺",
          isShow: false,
          weatherData: {
            wind: {
              direction: "", // 方向
              windP: 0, //风速
              level: "", // 等级
              position: {
                lng: 113.404076,
                lat: 22.994533,
              }, // 固定的
            },
            temperature: {
              count: "",
              position: {
                lng: 113.344845,
                lat: 23.017752,
              },
            },
            rain: {
              count: "",
              position: {
                lng: 113.404076,
                lat: 22.994533,
              },
            },
          },
        },
        {
          cityName: "花都",
          isShow: false,
          weatherData: {
            wind: {
              direction: "", // 方向
              windP: 0, //风速
              level: "", // 等级
              position: {
                lng: 113.303031,
                lat: 23.461246,
              }, // 固定的
            },
            temperature: {
              count: "",
              position: {
                lng: 113.179042,
                lat: 23.440751,
              },
            },
            rain: {
              count: "",
              position: {
                lng: 113.303031,
                lat: 23.461246,
              },
            },
          },
        },
        {
          cityName: "南沙",
          isShow: false,
          weatherData: {
            wind: {
              direction: "", // 方向
              windP: 0, //风速
              level: "", // 等级
              position: {
                lng: 113.585477,
                lat: 22.738084,
              }, // 固定的
            },
            temperature: {
              count: "",
              position: {
                lng: 113.459156,
                lat: 22.843331,
              },
            },
            rain: {
              count: "",
              position: {
                lng: 113.585477,
                lat: 22.738084,
              },
            },
          },
        },
        {
          cityName: "从化",
          isShow: false,
          weatherData: {
            wind: {
              direction: "", // 方向
              windP: 0, //风速
              level: "", // 等级
              position: {
                lng: 113.685752,
                lat: 23.638368,
              }, // 固定的
            },
            temperature: {
              count: "",
              position: {
                lng: 113.52277,
                lat: 23.584003,
              },
            },
            rain: {
              count: "",
              position: {
                lng: 113.685752,
                lat: 23.638368,
              },
            },
          },
        },
        {
          cityName: "增城",
          isShow: false,
          weatherData: {
            wind: {
              direction: "", // 方向
              windP: 0, //风速
              level: "", // 等级
              position: {
                lng: 113.742103,
                lat: 23.260715,
              }, // 固定的
            },
            temperature: {
              count: "",
              position: {
                lng: 113.828982,
                lat: 23.414144,
              },
            },
            rain: {
              count: "",
              position: {
                lng: 113.742103,
                lat: 23.260715,
              },
            },
          },
        },
      ];
      getCityLive().then((res) => {
        if (res.code == 0) {
          for (let data of res.data) {
            const cityName = JSON.parse(data.cityName);
            if (cityName == "广州") {
              // 数据初始化
              for (let item of cityList) {
                item.weatherData.wind.direction = data.windDes.split("风")[0];
                item.weatherData.wind.level = data.windDes.split("风")[1];
                item.weatherData.wind.windP = data.windP;
                item.weatherData.temperature.count = data.temperater;
              }
            }
            for (let item of cityList) {
              if (cityName == item.cityName) {
                item.weatherData.wind.direction = data.windDes.split("风")[0];
                item.weatherData.wind.level = data.windDes.split("风")[1];
                item.weatherData.wind.windP = data.windP;
                item.weatherData.temperature.count = data.temperater;
                this.weatherData = cityList;
              }
            }
          }
        }
      });
    },
    returnWeatherIcon(type) {
      switch (type) {
        case "wind":
          return require("../../../assets/weather/windDir.png");
        case "temperature":
          return require("../../../assets/weather/temperature.png");
        case "rain":
          return {};
      }
    },
    setWindDirection(type) {
      switch (type) {
        case "西":
          return 0;
        case "西北":
          return 45;
        case "北":
          return 90;
        case "东北":
          return 135;
        case "东":
          return 180;
        case "东南":
          return 225;
        case "南":
          return 270;
        case "西南":
          return 315;
      }
    },
    // 查询广州天气预警
    queryWeatherWarn() {
      const that = this;
      const areaList = [
        "广州市",
        "越秀区",
        "天河区",
        "荔湾区",
        "海珠区",
        "白云区",
        "黄浦区",
        "番禺区",
        "花都区",
        "南沙区",
        "从化区",
        "增城区",
      ];
      let gzWarning = []; // 广州市区预警
      this.warnIconList = [];
      getActiveWarn().then((res) => {
        if (res.code == 0) {
          for (let data of res.data.data) {
            // 过滤出广州市内预警信息并且找出最高级别的相同类型预警
            if (areaList.includes(data.districts_NAME)) {
              const level = this.returnWarnLevel(data);
              if (level != 6) {
                if (data.districts_NAME == "广州市") {
                  gzWarning.push({
                    type: data.category_CNNAME,
                    level,
                  });
                }
                const index = this.warnIconList.findIndex(
                  (item) => item.type == data.category_CNNAME
                );
                if (index != -1) {
                  if (this.warnIconList[index].level < level) {
                    this.warnIconList[index] = {
                      type: data.category_CNNAME,
                      level,
                      icon: data.icon,
                    };
                  }
                } else {
                  const warn = {
                    type: data.category_CNNAME,
                    level: level,
                    icon: data.icon,
                  };
                  this.warnIconList.push(warn);
                }
              }
            }
            // 处理广州市内区县的预警信息
            that.cityWarnList.forEach((item) => {
              if (data.districts_NAME.indexOf(item.cityName) != -1) {
                const level = this.returnWarnLevel(data);
                if (level != 6) {
                  item.warnList.push({
                    level,
                    type: data.category_CNNAME,
                  });
                }
              }
            });
          }
          // 单独处理越秀区和天河区
          that.cityWarnList.forEach((item) => {
            if (item.cityName == "越秀" || item.cityName == "天河") {
              gzWarning.forEach((i) => {
                item.warnList.push({
                  level: i.level,
                  type: i.type,
                });
              });
            }
          });
        }
      });
    },
    // 返回预警级别
    returnWarnLevel(record) {
      const temp = record.icon.split("_")[1][0];
      if (temp != 6) {
        if (temp == 1) {
          return 4;
        } else if (temp == 2) {
          return 3;
        } else if (temp == 3) {
          return 2;
        } else if (temp == 4) {
          return 1;
        }
      } else {
        return 6;
      }
    },
    // 返回右下角颜色
    returnWarnColor(record, k) {
      if (record.length > 0) {
        const level = record[k] ? record[k].level : 0;
        switch (level) {
          case 0:
            return "";
          case 1:
            return "blue";
          case 2:
            return "yellow";
          case 3:
            return "orange";
          case 4:
            return "red";
          default:
            return "";
        }
      }
    },
    // 页面底部操作栏事件
    onTypeChange(type) {
      switch (type) {
        case 0:
          this.isShowRain = !this.isShowRain;
          break;
        case 1:
          this.isShowWind = !this.isShowWind;
          break;
        case 2:
          this.isSHowTemp = !this.isSHowTemp;
          break;
        case 3:
          this.isSHowlinesite = !this.isSHowlinesite;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
}

.btn-item {
  width: 200px;
  height: 60px;
  cursor: pointer;
  opacity: 0.4;
  background: rgba(8, 23, 66, 0.9);
  border: 1px solid #002e75;
  box-shadow: 0px 0px 23px 6px #002e75 inset;

  span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
  }
}
.btn-item-active {
  opacity: 1;
}

.panel {
  width: 350px;
  height: 440px;
  background: rgba(67, 165, 233, 0.1);
  border: 1px solid #43a5e9;

  .panel-title {
    height: 44px;
    background: #43a5e9;
  }

  .panel-content {
    .img-wrapper {
      margin-left: 40px;

      img {
        width: 60px;
        height: 56px;
      }
    }

    .city-list {
      .label {
        width: 40px;
        color: #fff;
      }

      .leavel-wrapper {
        ul {
        }

        li {
          width: 60px;
          height: 14px;
          background: #ffffff;
          opacity: 0.2;
        }

        .orange {
          background: #f0af41;
          opacity: 1;
        }

        .yellow {
          background: #f0ee41;
          opacity: 1;
        }
        .red {
          background: rgb(196, 12, 30);
          opacity: 1;
        }
        .blue {
          background: rgb(64, 86, 118);
          opacity: 1;
        }
      }
    }
  }
}
</style>
