<template>
  <!-- 被呼叫的页面 -->
  <div class="called-box">
    <div class="top">
      <div class="text-3xl">{{ name }}</div>
      <div class="call-th">邀请你进行视频通话</div>
    </div>
    <div class="bottom">
      <img
        class="cursor-pointer"
        src="@assets/im/jt.png"
        @click="voipAcceptCall"
      />
      <img
        class="cursor-pointer"
        src="@assets/im/gd.png"
        @click="exitVoipFunc"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", 'callData'],
  data () {
    return {
      // name: JSON.parse(localStorage.user).realName,
    }
  },
  created () {
    console.log(JSON.parse(localStorage.user).realName)
  },
  methods: {
    exitVoipFunc () {
      this.$emit("callClose")
    },
    voipAcceptCall () {
      this.$emit("voipAcceptCall", this.callData)
    },
  },
};
</script>

<style lang="scss" scoped>
// div {
//   width: 300px;
//   height: 450px;
//   background-color: #8df;
//   position: fixed;
//   top: calc(100% - 225px);
//   left: calc(100% - 150px);
// }
.called-box {
  width: 400px;
  height: 600px;
  position: fixed;
  left: 500px;
  top: 100px;
  background: rgba(0, 0, 0, 1);
  z-index: 100;
  text-align: center;
  .top {
    margin-top: 100px;
    color: #fff;
    .call-th {
      margin-top: 20px;
    }
  }
  .bottom {
    // text-align: center;
    display: flex;
    justify-content: space-around;
    margin-top: 280px;
  }
}
</style>
