import { httpInit } from "./http"
const http = httpInit("/gzdt-response")
// const http = httpInit("/gzdt-response-zjh")
// 根据ID查询应急响应记录详情
export const getRes = id => http.get(`/api/response/info/${id}`)
//分页查询响应记录列表，根据筛选条件精确匹配
export const getResponseList = params =>
  http.get(
    `/api/response/list/${params.uuid}/${params.type}/${params.currentLevel}/${params.publisherId}/${params.page}/${params.size}`
  )

//分页查询响应变更日志，根据筛选条件精确匹配
export const getLogList = params =>
  http.get(`/api/response/log/list/${params.id}/${params.page}/${params.size}`)
//分页查询响应变更日志，根据筛选条件精确匹配
export const getList = params =>
  http.get(`/api/response/log/list/${params.id}`)
//应急响应记录详情
export const getrecord = params => http.get(`/api/response/info/${params.id}`)

//施工单位 查询广州市交通运输系统三防工作情况统计表 历史填报数据
export const getfill = params =>
  http.get(
    `/report_form/statistic/getStatisticHistory/${params.responseRecordId}/${params.departmentId}`
  )

//施工单位 新增广州市交通运输系统三防工作情况统计表
export const getfilladd = data =>
  http.post(`/report_form/statistic/info`, data)

//分页查询工作报表
export const getreport = params =>
  http.get(
    `/response/current/page/${params.lineId}/${params.siteId}/${params.status}/${params.keyword}/${params.page}/${params.size}`
  )
//获取三防检查表检查项
export const getData = params =>
  http.get(`/report_form/item/${params.type}/${params.level}`)

//施工单位 新增广州市交通运输系统三防工作情况统计表
export const checkInfo = data => http.post(`/report_form/check/info`, data)

//施工单位 查询广州市交通运输系统三防工作情况统计表
export const getcomite = params =>
  http.get(`/report_form/statistic/info/${params.fillRecordId}/${params.type}`)
//施工单位 查询三防工作简报
export const getaering = params =>
  http.get(`/report_form/brief/info/${params.fillRecordId}/${params.type}`)

//新增施工单位三防简报
export const getbriefing = data => http.post(`/report_form/brief/info`, data)

//查询广州市交通运输系统三防工作情况统计列表
export const getWorkData = params =>
  http.get(
    `/report_form/list/statistic/info/${params.responseRecordId}/${params.siteId}/${params.departmentId}`
  )

//查询三防应急检查表详情
export const checkDetails = params =>
  http.get(`/report_form/check/info/${params.fillRecordId}/${params.type}`)

//新增案例库记录
export const caseLibraryAdd = params =>
  http.post(`/response/caseLibrary/save`, params)

//查询案例库列表
export const caseLibraryList = params =>
  http.post(`/response/caseLibrary/page`, params)

//更新指定案例库记录
export const caseLibraryUpdate = params =>
  http.put(`/response/caseLibrary/update`, params)

//删除案例库记录
export const caseLibraryDelete = params =>
  http.delete(`/response/caseLibrary/delete/${params.caseId}`, params)

// 新增规章制度记录
export const ruleSystemAdd = params =>
  http.post(`/response/ruleSystem/save`, params)

// 查询规章制度列表
export const ruleSystemList = params =>
  http.post(`/response/ruleSystem/page`, params)

// 更新指定规章制度记录
export const ruleSystemUpdate = params =>
  http.put(`/response/ruleSystem/update`, params)

// 删除规章制度记录
export const ruleSystemDelete = params =>
  http.delete(`/response/ruleSystem/delete/${params.uuid}`, params)

//发布应急响应记录
export const emergencyResponse = params =>
  http.post(`/response_yj/saveOrUpdate`, params)
//战时页面事件续报终报保存接口
export const finalQuote = params =>
  http.post(`/response_yj/continueResponse`, params)

//查询案例库列表
export const puttedForward = params =>
  http.post(`/response/caseLibrary/page`, params)
//获取事件响应续报、终报列表
export const getprogress = params =>
  http.get(
    `/response_yj/getResponseProcess/${params.responseRecordUUid}/${params.size}/${params.responseType}`
  )

//分页查询应急响应记录列表
export const recordList = params => http.post(`/response_yj/list`, params)

//应急响应详情
export const detailsList = params =>
  http.post(`/response_yj/responseDetail`, params)
//确认响应事件
export const detailsConfig = params =>
  http.get(`/response_yj/confirmResponse/${params.uuid}`)

// 分页查询应急响应记录列表
export const getHomeDatas = params => http.post(`/response_yj/list`, params)

//应急预警保存

export const warningete = data => http.post(`/emergencyWarning/save`, data)
// 查询事件报警点列表
export const getsiteAlarm = data => http.post(`/site_alarm/list`, data)

// 根据id查询报警点信息
export const getSiteAlarmInfoById = params => http.get(`/site_alarm/queryId`, params)

//事件报警结束
export const EndEvent = data => http.post(`/site_alarm/finishAlarm`, data)

//删除应急响应
export const deleteResponseRecord = params =>
  http.get(`/response_yj/deleteResponseRecord/${params.uuid}`)

//响应升级
export const Upgrade = data => http.post(`/responseUpgrade/save`, data)

//响应结束
export const responseend = params =>
  http.get(`/response_yj/finishResponse/${params.uuid}`)


//分页查询响应记录
export const getonseList = params =>
  http.get(
    `/api/response/list/map/${params.uuid}/${params.type}/${params.currentLevel}/${params.publisherId}/${params.startDate}/${params.endDate}/${params.page}/${params.size}`
  )


// 填报三防工作简报
export const fillInBriefing = data => http.post(`/response/save`, data)

// 查询三防简报详情
export const getBriefingInfo = params => http.get(`/response/getInfo/${params.id}`)

// 三防简报审批
export const approvalBriefing = data => http.post(`/response/editInfo`, data)



export const getDetail = id => http.get(`/threeCheckList/getDetail?currentId=${id}`)

//修改填报
//施工单位 新增广州市交通运输系统三防工作情况统计表
export const threeCheckListEdit = data =>
  http.post(`/threeCheckList/update`, data)

//应急检查表  填报-提交/保存接口
export const saveOrUpdate = data =>
  http.post(`/threeCheckList/saveOrUpdate`, data)

//应急检查表  获取检查人员列表接口
export const getUserBySiteUuid = params => http.get(`/threeCheckList/getUserBySiteUuid?siteUuid=${params.siteUuid}`)
//审批接口
export const threeCheckListApprove = data =>
  http.post(`/threeCheckList/approve`, data)

export const threeCheckListApproveNew = data =>
  http.post(`/threeCheckList/approve/new`, data)

// 当前响应确认按钮
export const getCurrentResponseMsg = () => http.get(`/response/current/getCurrentChangeLog`)

//获取响应状态列表
export const getResponseStatusList = params => http.get(`/response/current/getResponseStatusList/${params.dictId}`)

//历史操作记录列表查询
export const operatinghistoryList = data =>
  http.post(`/operatinghistory/list`, data)
//历史操作记录列表查询
export const operatinghistoryListNew = data =>
  http.post(`/threeCheckItemQuestion/operatingHistory/list`, data)

// 当前响应列表查询
export const queryCurrentResponseList = data => http.post(`/response/current/getCurrentResponseList`, data)
// 新当前响应列表查询
export const queryCurrentResponseListNew = data => http.post(`/response/current/getCurrentResponseListNew`, data)
// 专职救援队统计表列表接口
export const getCurrentResponseTeamList = data => http.post(`/response/current/getCurrentResponseTeamList`, data)
// 获取响应状态列表(救援队)
export const statusListByDictIdAndType = params => http.get(`/response/current/statusListByDictIdAndType/${params.dictId}/${params.type}`)

// 当前响应确认按钮
export const currentResponseConfirm = params => http.get(`/api/response/alReadyReceive${params.responseId}`)

// 当前用户是否已经对当前响应的消息进行了确认
export const queryUserConfirmResponse = params => http.get(`/alreadyReceive/currentUserIsReceived/${params.responseId}`)

// 应急响应详情
export const getResponseRecordById = params => http.get(`/responseNew/getResponseRecordById/${params.id}`)

// 代办升降级别 
export const getResponseRecordByHistoryId = params => http.get(`/responseNew/getResponseRecordByHistoryId/${params.id}`)
// 当前响应历史操作
export const queryCurrentResponseLog = id => http.get(`/response/current/log/list/${id}`)
//施工单位 新增广州市交通运输系统三防工作情况统计表
export const getStatisticList = (data) =>
  http.post(`/response/statisticFormNew/list`, data)
//施工单位 新增广州市交通运输系统三防工作情况统计表 提交
export const saveStatisticList = (data) =>
  http.post(`/response/statisticFormNew/insert`, data)
//施工单位 新增广州市交通运输系统三防工作情况统计表 保存 
export const saveStatisticListSave = (data) =>
  http.post(`/response/statisticFormNew/save`, data)
// //施工单位 新增广州市交通运输系统三防工作情况统计表 修改
// export const updateStatisticList = (data) =>
//   http.post(`/response/statisticFormNew/update`, data)
// 新增广州市交通运输系统三防工作情况统计表-监理审核接口
export const approve = (data) => http.post(`/statistic_form/approve`, data)
//施工单位 新增广州市交通运输系统三防工作情况统计表 提交
export const getStatisticData = (data) =>
  http.post(`/statistic_form/list/supervisor/link`, data)
//广州市交通运输系统三防工作情况统计表 质安部 汇总单位
export const getSelection = (data) =>
  http.post(`/statistic_form/list/selection`, data)
//广州市交通运输系统三防工作情况统计表 质安部 列表
export const getQualityData = (data) =>
  http.post(`/statistic_form/list/quality_safety/link`, data)
//广州市交通运输系统三防工作情况统计表 建管、监理 查看详情
export const getCheckDetail = (data) =>
  http.post(`/statistic_form/check/detail`, data)

//广州市交通运输系统三防工作情况统计表 建管部提交
export const saveCommit = (data) =>
  http.post(`/statistic_form/manage/commit`, data)

// 获取首页左下角数据，已接收，已推送，未结束
export const getResponseTotal = () => http.get(`/api/response/getResponseTotal`)
//受损或恢复详情查询

export const getlrosses = reportId => http.get(`/response/query/${reportId}`)
// 获取首页右下角响应数据
export const responseLogNew = () => http.get(`/api/response/log/newList`)

// 查询当前响应时间记录
export const queryCurrentResponse = () => http.get(`/response/queryHomeResponse`)

//建管审批列表接口
export const getApproveList = (data) =>
  http.post(`/response/current/getApproveList`, data)

// 查询当前响应时间记录
export const getCheckList = (data) => http.post(`/threeCheckItemQuestion/page`, data)
// 三防整改问题详情
export const getDetails = questionId => http.get(`/threeCheckItemQuestion/getDetail/${questionId}`)

// 三防整改填报
export const correctiveFill = (data) => http.post(`/threeCheckItemQuestion/correctiveFill`, data)
// 审批接口
export const checkApprove = (data) => http.post(`/threeCheckItemQuestion/approve`, data)

//批量审批接口
export const batchApprove = (data) => http.post(`/threeCheckList/batchApprove`, data)

//响应升级详情
export const getUpgrade = id => http.get(`/responseUpgrade/getDetail/${id}`)

//二次升级总值班室确认
export const groupConfirmResponse = uuid => http.get(`/response_yj/groupConfirmResponse/${uuid}`)

// 应急预警详情
export const getWarningInfo = id => http.get(`/emergencyWarning/getDetail/${id}`)

// 预警审批
export const warningApprove = data => http.post(`/emergencyWarning/approve`, data)
//应急预警审批
export const emergencyEarly = (data) => http.post(`/emergencyWarning/approve`, data)

//事件续报通知消息详情弹窗
export const getIncident = params => http.get(`/response_yj/alter/continued?eventId=${params.eventId}`)

//事件终报通知消息详情弹窗
export const getReported = params => http.get(`/response_yj/alter/end?eventId=${params.eventId}`)

//响应结束通知消息详情弹窗
export const responseEnd = params => http.get(`/response_yj/alter/response/end?eventId=${params.eventId}`)

// 事件确认
export const confirmResponseUpgrade = params => http.get(`/responseUpgrade/confirmResponse/${params.noticeEventId}`)


// 根据工点统计三防整改问题   
export const countQuestion = params => http.get(`/threeCheckItemQuestion/count_question/${params.siteUuid}`)

// 三防整改填报审批页面分页
export const threeCheckItemQuestion = (data) => http.post(`/threeCheckItemQuestion/page`, data)

// 三防整改问题详情
export const threeCheckItemQuestionDetail = params => http.get(`/threeCheckItemQuestion/getDetail/${params.questionId}`)

//终报填写完成查看详情弹窗
export const getFillert = params => http.get(`/response_yj/alter/response/death?eventId=${params.eventId}`)

//新增保存三防问题整改
export const saveQuestion = (data) => http.post(`/threeCheckItemQuestion/save`, data)

//三防响应-历史响应记录-查看获取列表
export const getCurrentResponseTreeById = (data) => http.post(`/response/current/getCurrentResponseTreeById`, data)

//三防响应-历史响应记录-查看获取列表-救援队
export const getCurrentResponseTeamTreeById = (data) => http.post(`/response/current/getCurrentResponseTeamTreeById`, data)

//三防响应-历史响应记录-查看获取列表-总包
export const getCurrentResponseZongBaoTreeById = (data) => http.post(`/response/current/getCurrentResponseZongBaoTreeById`, data)

//查询三防统计表详情
export const statistics = (data) => http.post(`/response/statics-v2/detail`, data)

//查询三防工作统计表详情(专职救援队)
export const teamDetails = (data) => http.post(`/response/statics-v2/detail/team`, data)

//填报三防工作统计表
export const statement = (data) => http.post(`/response/statics-v2/report-submit`, data)

//审批三防工作统计表
export const approveent = (data) => http.post(`/response/statics-v2/report-approve`, data)


//查看汇总数据

export const summarydata = (data) => http.post(`/response/statics-v2/report-summary
`, data)

//提交汇总数据
export const submitdata = (data) => http.post(`/response/statics-v2/report-summary-submit
`, data)

//质安部查看汇总详情
export const qualitydata = (data) => http.post(`/response/statics-v2/report-summary-detail
`, data)

//权限按钮
export const buttondata = (data) => http.post(`/response/statics-v2/get-ops
`, data)

//汇总数据导出

export const derivedata = (data) => http({ url: `/response/statics-v2/report-summary-export`, data, method: "post", responseType: "blob", headers: { 'Content-Type': 'application/json;charset=utf8' } })
//质安部查询损失恢复列表
export const recoverylist = (data) => http.post(`/response/situation/listLossAndRecovery
`, data)

//建管部查询受损或恢复详情查询
export const Department = params => http.get(`/response/superviion/query/${params.responseId}`)

//灾害损失驳回

export const situationlist = (data) => http.post(`/response/situation/reject
`, data)
//灾害损失同意
export const agreelist = (data) => http.post(`/response/situation/adopt
`, data)

//告知查看汇总提交
export const informlist = (data) => http.post(`/response/statics-v2/report-summary-detail
`, data)

//汇总数据导出

// export const deredata = (data) => http({ url: `/response/export/summaryInfo`, data, method: "post", responseType: "blob", headers: { 'Content-Type': 'application/json;charset=utf8' } })


//得当到前人员的权限按钮  三防当前响应的三防工作简报
export const getOps = (data) => http.post(`/response/get-ops`, data, {
  headers: {
    'Content-Type': 'application/json'
  }
})
// 当前响应建管部查询总包汇总信息
export const listSZongBaoDetailSummaryInfo = (data) => http.post(`/response/listSZongBaoDetailSummaryInfo`, data)

// 获取当前工作简报最大次数
export const getMaxTimes = params => http.get(`/response/getMaxTimes?responseId=${params}`)


// 代办建管部查询总包汇总信息
export const listSZongBaoDetailSummaryInfoDB = (data) => http.post(`/response/listSZongBaoDetailSummaryInfoDB`, data)

// 灾害头部数据
export const queryStaticByCurrent = params => http.get(`/response/situation/queryStaticByCurrent/${params.currentId}`);

// 查看详情-得当到前人员的权限按钮
export const currentGetOps = params => http.get(`/response/current/get-ops/${params.type}`);