<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
             label-width="100px" class="demo-model">

      <el-row>
        <el-col :span="16">
          <el-form-item label="标题" prop="itemName">
            <el-input v-model="title" disabled placeholder="隐患条目名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查类型" prop="itemName">
            <el-input v-model="troubleData.taskType" disabled placeholder="排查类型"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查单位" prop="itemName">
            <el-input v-model="companyName" disabled placeholder="排查单位"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查人员" prop="itemName">
            <el-input v-model="troubleData.personLiable" disabled placeholder="排查人员"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排查时间" prop="itemName">
<!--            <el-input v-model="troubleData.pcTime" placeholder="排查时间"></el-input>-->
            <el-date-picker
                v-model="escalation.screeningTime"
                style="width: 100%"
                disabled
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="线路" prop="itemName">
            <el-input v-model="troubleData.lineName" disabled placeholder="线路"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="分部/标段" prop="itemName">
            <el-input v-model="troubleData.branchName" disabled placeholder="分部/标段"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工点" prop="itemName">
            <el-input v-model="troubleData.workAreaName" disabled placeholder="工点"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">

          <el-form-item label="任务人员" prop="itemName" v-if="type === '专项排查' ">
            <el-input type="textarea" v-model="troubleData.checkName" :rows="4"
                      disabled placeholder="工点"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>


    <el-row style="margin-top: 20px">
      <el-col :span="8">
        <el-aside>
          <h2 class="title">隐患条目</h2>
          <el-input
              placeholder="输入关键字进行搜索"
              v-model="filterText"
              style="width: 250px; margin-left: 20px"
          >
          </el-input>
          <el-tree
              style="margin-top: 10px"
              v-loading="loading"
              :data="dataList"
              :props="defaultProps"
              @node-click="handleNodeClick"
              :filter-node-method="filterNode"
              ref="tree"
          ></el-tree>
        </el-aside>
      </el-col>
      <el-col :span="16">
        <div class="table-wrapper">
          <el-table :data="tableData" style="width: 100%" v-loading="loading" height="400">
            <el-table-column align="center" prop="itemName" show-overflow-tooltip label="隐患条目名称" width="200"></el-table-column>
            <el-table-column align="center" prop="itemState" label="状态" width="">
              <template slot-scope="scope">
                {{ scope.row.itemState == 0 ? '有效' : scope.row.itemState == 1 ? '无效' : '' }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="itemCode" label="条目编码" width="120"></el-table-column>
            <el-table-column align="center" prop="reformUnitName" label="整改单位" width="120"></el-table-column>
            <el-table-column align="center" prop="hiddenLevelName" label="隐患等级" width="120"></el-table-column>
            <el-table-column align="center" prop="reformDay" label="整改天数" width="120"></el-table-column>
            <el-table-column align="center" label="操作" width="200" fixed="right">
              <template slot-scope="scope">
                <el-radio-group v-model="scope.row.isTrouble">
                  <el-radio :label="false">无隐患</el-radio>
                  <el-radio :label="true">上报隐患</el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>


    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelModal">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>


    <el-dialog
        width="70%"
        title="日常隐患排查"
        :visible.sync="dialogVisible"
        class="editDialog"
        append-to-body>
      <ecalation v-if="dialogVisible" :escalation="escalation" :title="title" :task-id="taskId" :type="type"
                 @cancelModal="cancelModalSon" @successRelease="successRelease"></ecalation>
    </el-dialog>

  </div>
</template>

<script>
import {queryDeptDetail} from "@/api/stand";
import {categoryPage, dayTroubleSaveRecord, libraryList, taskRecordAdd} from "@/api/hdanger";
import ecalation from "@/views/HiddenDanger/System/DangerTrouble/DayTrouble/components/ecalation";

export default {
  name: "startTrouble",
  components : {
    ecalation
  },
  props : {
    troubleData : {
      default : () => {}
    },
    type : {
      default : ''
    }
  },
  data(){
    return{
      ruleForm : {},
      rules : {},
      tableData : [
        /* {
           itemName : '111',
           isTrouble : null
         }*/
      ],
      loading : false,
      dialogVisible : false,
      title : '',
      deptType : '',
      companyName : '',
      pcTime : '',
      dataList : [],
      defaultProps : {
        children: 'children',
        label: 'categoryName'
      },
      filterText : '',
      categoryId : '',
      escalation : {},
      userInfo : JSON.parse(localStorage.getItem('user')),
      taskId : ''
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    this.getDeptDetail();
    this.troubleData.pcTime = this.getNowTime('time');
    this.escalation.screeningTime = this.getNowTime('time');
  },
  methods : {
    getNowTime(type) {
      var date = new Date();
      //年 getFullYear()：四位数字返回年份
      var year = date.getFullYear(); //getFullYear()代替getYear()
      //月 getMonth()：0 ~ 11
      var month = date.getMonth() + 1;
      //日 getDate()：(1 ~ 31)
      var day = date.getDate();
      //时 getHours()：(0 ~ 23)
      var hour = date.getHours();
      //分 getMinutes()： (0 ~ 59)
      var minute = date.getMinutes();
      //秒 getSeconds()：(0 ~ 59)
      var second = date.getSeconds();
      var time = year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
      var today = year + '-' + this.addZero(month) + '-' + this.addZero(day);
      return type === 'time' ? time : today;
    },
    addZero(s) {
      return s < 10 ? ('0' + s) : s;
    },
    getDeptDetail() {
      var nowTime = this.getNowTime('today');
      var userInfo = JSON.parse(localStorage.getItem("user"));
      queryDeptDetail(userInfo.companyId).then((res) => {
        console.log(res);
        this.deptType = res.data ? res.data.category : "0";
        switch (this.deptType){
          case 'build' :
            this.companyName = '建设单位';
            break;
          case 'monitor' :
            this.companyName = '监理单位';
            break;
          case 'construct' :
            this.companyName = '施工单位';
            break;
          case 'design' :
            this.companyName = '设计单位';
            break;
          case 'third' :
            this.companyName = '第三方监测单位';
            break;
          case 'survey' :
            this.companyName = '勘察单位';
            break;
          case 'contracting' :
            this.companyName = '施工总承包单位';
            break;
          case 'fullEmergencyRescue' :
            this.companyName = '专职应急救援队';
            break;
        }
        this.title = this.companyName + nowTime + this.type;
      });
      this.tableLoading = true;
      let params = {
        pageNum: 1,
        pageSize: 999,
        screeningType : this.type === '日常排查' ? 'everyday_check' : 'special_check'
      };
      categoryPage(params).then((res) => {
        this.dataList = res.data.records;
      });
    },
    handleNodeClick(data){
      console.log(data)
      this.categoryId = data.id;
      this.getTableData();
    },
    filterNode(value, data, node) {
      console.log(value, data, node);
      if (!value) return true;
      // 如果传入的value和data中的label相同说明是匹配到了
      if (data.categoryName.indexOf(value) !== -1) {
        return true;
      }
      // 否则要去判断它是不是选中节点的子节点
      return this.checkBelongToChooseNode(value, data, node);
    },
    // 判断传入的节点是不是选中节点的子节点
    checkBelongToChooseNode(value, data, node) {
      console.log(data);
      const level = node.level;
      // 如果传入的节点本身就是一级节点就不用校验了if(level ===1) {returnfalse; }
      // 先取当前节点的父节点
      let parentData = node.parent;
      // 遍历当前节点的父节点
      let index = 0;
      while (index < level - 1) {
        // 如果匹配到直接返回
        if (parentData.data.categoryName.indexOf(value) !== -1) {
          return true;
        }
        // 否则的话再往上一层做匹配
        parentData = parentData.parent;
        index++;
      }
      // 没匹配到返回false
      return false;
    },
    getTableData(){
      let params = {
        pageNum : 1,
        pageSize : 9999,
        categoryId : this.categoryId
      }
      libraryList(params).then(res => {
        this.tableData = res.data.records;
        this.tableData.forEach(item => {
          this.$set(item,'isTrouble',false)
        })
      })
    },
    cancelModal(){
      this.$emit('cancelModal')
    },
    successRelease(){
      this.dialogVisible = false;
      this.getTableData();
    },
    cancelModalSon(){
      this.dialogVisible = false;
    },
    submit(){
      console.log(this.tableData)
      if(this.type === '日常排查'){
        // created updated  is_delete  version  id
        let params = {
          isDelete : this.troubleData.isDelete,
          personLiable : this.userInfo.realName,
          personId : this.userInfo.id,
          screeningId : this.troubleData.screeningId,
          taskTitle : this.title,
          taskType : '日常排查',
          unitType : this.companyName,
          lineUuid : this.troubleData.lineUuid,
          branchUuid : this.troubleData.branchUuid,
          workAreaUuid : this.troubleData.workAreaUuid,
          lineName : this.troubleData.lineName,
          branchName : this.troubleData.branchName,
          workAreaName : this.troubleData.workAreaName,
        }
        dayTroubleSaveRecord(params).then(res => {
          this.taskId = res.data;
        })
      }else if(this.type === '专项排查'){
        let params = {
          screeningType : "special_check",
          taskId : this.troubleData.id
        }
        taskRecordAdd(params).then(res => {
          console.log(res);
          this.taskId = res.data;
        })
      }

      let existenceList = this.tableData.filter(item => item.isTrouble === true);
      console.log(existenceList)
      if(existenceList && existenceList.length > 0){
        if(existenceList.length > 1){
          this.$message.error('请选择一条数据');
          return
        }
        if(existenceList.length === 0){
          this.$message.error('请至少选择一条数据');
          return
        }
        this.escalation = {
          ...this.troubleData,
          ...existenceList[0]
        }
        this.dialogVisible = true;
      }else{
        this.$emit('noTrouble')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-aside {
  //width: 20%;
  color: #333;
  background-color: #ffffff !important;
  height: 98%;
  border: 1px solid #eee;
  padding-top: 20px;
  box-sizing: border-box;
  max-height: 300px;
}

.title{
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  font-weight: bold;
}

.el-aside::-webkit-scrollbar {
  display: none;
}

::v-deep.el-table--scrollable-y ::-webkit-scrollbar {
  display: none;
}
::v-deep.el-table--scrollable-x ::-webkit-scrollbar {
  display: none;
}
::v-deep .el-table__fixed-right{
  height: 100% !important;
  right: 0 !important;
}
</style>
