<template>
  <systemModal
    ref="formModal"
    @modalConfirm="handleSubmit"
    :title="title"
    width="40%"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="120px"
      class="demo-model"
      style="width: 80%"
    >
      <el-form-item label="队伍名称" prop="teamName">
        <el-input
          v-model="model.teamName"
          placeholder="请输入队伍名称"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="所属承包部" prop="optionUnitName">
        <el-select
          v-model="model.optionUnitName"
          placeholder="请选择所属承包部"
          @change="handleDepartChange"
        >
          <el-option
            v-for="item of dictOption.unitName"
            :key="item.id"
            :label="item.companyName"
            :value="item.companyName"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="队伍驻地" prop="teamStation">
        <el-input
          v-model="model.teamStation"
          placeholder="请输入队伍驻地"
        ></el-input>
      </el-form-item>
      <el-form-item label="救援区域" prop="rescueScope">
        <el-input
          v-model="model.rescueScope"
          placeholder="请输入救援区域"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="人数" prop="numOfPeople" v-if="title == '编辑'">
        <el-input
          v-model="model.numOfPeople"
          placeholder="请输入人数"
          readonly
        ></el-input>
      </el-form-item> -->
      <el-form-item label="负责人" prop="captainName">
        <el-select
          v-model="model.captainName"
          placeholder="请选择负责人"
          @change="handlePeopleChange"
        >
          <el-option
            v-for="item of principalData"
            :key="item.id"
            :label="item.realName"
            :value="item.realName"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话" prop="captainPhone">
        <el-input
          readonly
          v-model="model.captainPhone"
          placeholder="请输入联系电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="坐标">
        <el-button type="primary" @click="choseMap">点击选择</el-button>
      </el-form-item>
      <el-form-item label="经度" prop="lng">
        <!-- <el-button disabled>{{ model.lng }}</el-button> -->
        <el-input readonly v-model="model.lng" placeholder="经度"></el-input>
      </el-form-item>
      <el-form-item label="纬度" prop="lat">
        <el-input readonly v-model="model.lat" placeholder="纬度"></el-input>

        <!-- <el-button disabled>{{ model.lat }}</el-button> -->
      </el-form-item>
    </el-form>
    <MapForm
      ref="mapForm"
      @formSubmit="handleSubmit"
      :getCoo="getCoo"
    ></MapForm>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import MapForm from "./map.vue";
import { addTeamInfo, updateTeamInfo } from "@/api/eres";
import { getUserList } from "@/api/eres";
import { unit } from "@/api/org";
export default {
  components: {
    systemModal,
    MapForm,
  },
  data() {
    return {
      title: "新增",
      model: {
        teamName: "",
        optionUnitName: "",
        optionUnitUuid: "",
        teamStation: "",
        rescueScope: "",
        captainName: "",
        captainPhone: "",
        captainUuid: "",
        lng: "",
        lat: "",
      },
      principalData: [],
      dictOption: {
        unitName: [], // 单位
      },
      loading: false,
      rules: {
        teamName: [
          { required: true, message: "请输入队伍名称", trigger: "blur" },
        ],
        optionUnitName: [
          { required: true, message: "请选择所属承包部", trigger: "blur" },
        ],
        teamStation: [
          { required: true, message: "请输入队伍驻地", trigger: "blur" },
        ],
        rescueScope: [
          { required: true, message: "请输入救援区域", trigger: "blur" },
        ],
        captainName: [
          { required: true, message: "请选择负责人", trigger: "blur" },
        ],
        captainPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          {
            pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: "请输入合法手机号",
            trigger: "blur",
          },
        ],
        lng: [{ required: true, message: "请选择坐标", trigger: "blur" }],
        lat: [{ required: true, message: "请选择坐标", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getCoo();
    this.getDept();
    this.getPeople();
  },
  methods: {
    getCoo(lng, lat) {
      this.model.lng = lng;
      this.model.lat = lat;
      this.$forceUpdate();
    },
    getDept() {
      let params = {
        category: "contracting",
      };
      unit(params).then((res) => {
        this.dictOption.unitName = res.data;
      });
    },
    getPeople() {
      getUserList({}).then((res) => {
        if (res.code == 0) {
          this.principalData = res.data;
        }
      });
    },
    choseMap() {
      this.$refs.mapForm.handleAdd(
        this.model.lng,
        this.model.lat,
        this.model.teamStation
      );
    },
    handleDepartChange(val) {
      console.log(this.dictOption.unitName);
      this.model.optionUnitUuid = this.dictOption.unitName.filter((item) => {
        return item.companyName == val;
      })[0].uuid;
      // let companyId = this.dictOption.unitName.filter((item) => {
      //   return item.companyName == val;
      // })[0].uuid;
      // let params = {
      //   companyId: companyId,
      // };
    },
    handlePeopleChange(val) {
      let params = {};
      params = this.principalData.filter((item) => {
        return item.realName == val;
      });

      this.model.captainUuid = params[0].uuid;
      this.model.captainPhone = params[0].mobile;
      console.log("uuid", this.model);
    },
    handleAdd(type, record) {
      if (record) {
        this.title = "编辑";
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
      } else {
        this.title = "新增";
        this.model = {};
      }
      this.model.type = type;
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },

    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.model.id) {
            const params = {
              id: this.model.id,
              data: this.model,
            };
            updateTeamInfo(params)
              .then((res) => {
                if (res.code == 0) {
                  this.loading = false;
                  this.$message.success("操作成功");
                  this.$refs.formModal.handleClose();
                  this.$emit("formSubmit");
                } else {
                  this.loading = false;
                  this.$message.warning(res.message);
                  this.$refs.formModal.handleClose();
                  this.$emit("formSubmit");
                }
              })
              .catch((err) => {
                this.loading = false;
                this.$refs.formModal.handleClose();
                this.$message.warning(err.message);
                this.$emit("formSubmit");
              });
          } else {
            addTeamInfo(this.model)
              .then((res) => {
                if (res.code == 0) {
                  this.loading = false;
                  this.$message.success("操作成功");
                  this.$refs.formModal.handleClose();
                  this.$emit("formSubmit");
                } else {
                  this.loading = false;
                  this.$message.warning(res.message);
                  this.$refs.formModal.handleClose();
                  this.$emit("formSubmit");
                }
              })
              .catch((err) => {
                this.loading = false;
                this.$refs.formModal.handleClose();
                this.$message.warning(err.message);
                this.$emit("formSubmit");
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
