<template>
  <!-- 隐患等级分布 -->
  <div class="person-statistics-container">
    <index-box :title="title">
      <div class="person-statistics-wrapper">
        <el-radio-group
          v-for="(item, index) in radioData"
          :key="index"
          v-model="radio"
          @change="radioChange"
          style="margin-right:10px"
        >
          <el-radio style="margin-right:10px" :label="item.value">{{
            item.label
          }}</el-radio>
        </el-radio-group>

        <el-date-picker
          size="mini"
          v-show="radio == 1"
          v-model="weekDate"
          type="week"
          format="yyyy 第 WW 周"
          placeholder="选择周"
          @change="timeDateChangeWeek"
        >
        </el-date-picker>
        <el-date-picker
          size="mini"
          v-show="radio == 2"
          v-model="monthDate"
          type="month"
          placeholder="选择月"
          value-format="yyyy-MM"
          @change="timeDateChangeMonth"
        >
        </el-date-picker>
      </div>
      <div id="branchEchart" class="branchEchart"></div>
    </index-box>
    <HomePageDialog
      ref="modalTable"
      :titleTable="titleTable"
      :columns="columns"
      :tableList="tableList"
      :totalData="totalData"
      :params="params"
    ></HomePageDialog>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import HomePageDialog from "@/views/HiddenDanger/components/HomePageDialog.vue";
// 隐患等级分布,liebiao
import { getLevelDist, getDistGeneralList } from "@/api/hdanger";
export default {
  components: {
    IndexBox,
    HomePageDialog,
  },
  data() {
    return {
      title: "隐患等级分布",
      radio: "1",
      weekDate: "",
      monthDate: "",
      radioData: [
        {
          value: "1",
          label: "周",
        },
        {
          value: "2",
          label: "月",
        },
      ],
      weekTime: "",
      titleTable: "",
      columns: [
        { prop: "lineName", label: "线路工点", minWidth: 80 },
        { prop: "category", label: "隐患类别", minWidth: 80 },
        { prop: "subClass", label: "隐患子类", minWidth: 80 },
        { prop: "hiddenLevel", label: "隐患等级", minWidth: 80 },
        { prop: "itemName", label: "隐患条目", minWidth: 180 },
      ],
      echartsData: [
        { value: 0, name: "I级" },
        { value: 4, name: "Ⅱ级" },
        { value: 496, name: "Ⅲ级" },
      ],
      tableList: [],
      totalData: 0,
      params: {
        weekStar: "",
        weekEnd: "",
        month: "",
      },
    };
  },
  mounted() {
    this.RefererShow();
    let time = new Date();
    this.weekDate = time;
    this.monthDate = time;

    this.timeDateChangeWeek(time);
  },
  methods: {
    radioChange(val) {
      this.weekDate = "";
      this.monthDate = "";
      console.log("数据", val);
    },

    RefererShow() {
      var myChartFour = this.$echarts.init(
        document.getElementById("branchEchart")
      );

      var option = {
        tooltip: {},
        legend: {
          orient: "vertical",
          right: "5%",
          top: "25%",
          textStyle: {
            color: "#ffffff",
          },
        },
        series: [
          {
            type: "pie",
            radius: "70%",
            // center: ["35%", "40%"],
            center: ["30%", "55%"],
            data: this.echartsData,
            label: {
              show: false,
            },
          },
        ],
      };
      myChartFour.clear();
      option && myChartFour.setOption(option);
      myChartFour.off("click");
      myChartFour.hideLoading();
      myChartFour.on("click", (param) => {
        if (this.radio == 1) {
          this.titleTable = param.name + "隐患列表 (" + this.weekTime + ")";
        } else {
          this.titleTable = param.name + "隐患列表 (" + this.monthDate + ")";
        }
        this.$refs.modalTable.handleOpen("right", "隐患等级分布");
        let paramData = {
          categoryName: "",
          categoryPName: "",
          hiddenLevel: param.name,
          workAreaNameList: [],
          pageNum: 1,
          pageSize: 5,
        };
        paramData.weekStar = this.weekTime.split("-")[0]
          ? this.weekTime.split("-")[0]
          : "";
        paramData.weekEnd = this.weekTime.split("-")[1]
          ? this.weekTime.split("-")[1]
          : "";
        paramData.month = this.monthDate.split("-")[1]
          ? this.monthDate.split("-")[1]
          : "";
        this.params = {
          weekStar: paramData.weekStar,
          weekEnd: paramData.weekEnd,
          month: paramData.month,
        };
        getDistGeneralList(paramData).then((res) => {
          if (res.code == 0) {
            this.tableList = res.data.records;
            this.totalData = res.data.count;
          }
        });
      });
    },

    getWeek(val) {
      let firstDay = new Date(val.getFullYear(), 0, 1);
      let dayOfWeek = firstDay.getDay(); // 2020-01-01是周三  dayOfWeek==3
      let spendDay = 1;
      if (dayOfWeek != 0) {
        spendDay = 7 - dayOfWeek + 1; // 5 离下周一还有5天
      }
      firstDay = new Date(val.getFullYear(), 0, 1 + spendDay); // 2020-01-06是2020年第一个周一，是2020年第二个周
      let d = Math.ceil((val.valueOf() - firstDay.valueOf()) / 86400000); // 当前时间距离2020-01-06有几天
      let result = Math.ceil(d / 7); // 换算成周为单位 2020-01-06result是0,但其实是第二个周，所以默认加2
      let week = result + 2; //如果使用的是默认为加2（如果将自然周设置为周一到周日则是加1）
      console.log(
        this.dataFomat(val.valueOf() - 86400000) +
          "-" +
          this.dataFomat(val.valueOf() + 5 * 86400000) +
          " 第" +
          week +
          "周 "
      );
      return (
        this.dataFomat(val.valueOf() - 86400000) +
        "-" +
        this.dataFomat(val.valueOf() + 5 * 86400000)
        /* +" 第" +week +  "周 " */
      );
    },
    dataFomat(time) {
      const date = new Date(time);

      const H = date.getFullYear();

      const M = (date.getMonth() + 1).toString().padStart(2, 0);

      const D = date
        .getDate()
        .toString()
        .padStart(2, 0);
      return `${H}.${M}.${D}`;
    },
    // 周
    timeDateChangeWeek(val) {
      this.monthDate = "";
      this.weekTime = this.getWeek(val);
      val.getTime();
      let param = {
        categoryName: "",
        categoryPName: "",
        hiddenLevel: "",
        workAreaNameList: [],
      };
      param.weekStar = this.weekTime.split("-")[0].replaceAll(".", "-");
      param.weekEnd = this.weekTime.split("-")[1].replaceAll(".", "-");
      param.month = "";

      getLevelDist(param).then((res) => {
        if (res.code == 0) {
          this.echartsData = [];
          if (res.data) {
            res.data.forEach((item) => {
              this.echartsData.push({
                value: item.num,
                name: item.groupName,
              });
            });
          }
          this.RefererShow();
        }
      });
    },
    // 月
    timeDateChangeMonth(val) {
      this.weekDate = "";
      let param = {
        categoryName: "",
        categoryPName: "",
        hiddenLevel: "",
        workAreaNameList: [],
      };
      param.weekStar = "";
      param.weekEnd = "";
      param.month = val.split("-")[1];
      getLevelDist(param).then((res) => {
        if (res.code == 0) {
          this.echartsData = [];
          if (res.data) {
            res.data.forEach((item) => {
              this.echartsData.push({
                value: item.num,
                name: item.groupName,
              });
            });
          }
          this.RefererShow();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../style/elTable.scss";
.person-statistics-container {
  width: 100%;
  height: 100%;
  ::v-deep .el-date-editor {
    width: 165px;
  }
  .person-statistics-wrapper {
    width: 100%;
    height: 10%;
  }
  .branchEchart {
    width: 100%;
    height: 85%;
    overflow: hidden;
  }
}
</style>
