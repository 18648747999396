<template>
  <!-- 隐患类别管理 -->
  <div class="page-container bg-white p-4 h-full relative">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item prop="screeningType">
            <el-select
              v-model="queryModal.screeningType"
              clearable
              placeholder="请选择排查类型"
            >
              <el-option
                v-for="item of checkType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="queryModal.categoryName"
              clearable
              placeholder="请输入类别名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryModal.gongfaId"
              clearable
              placeholder="请选择关联工法"
            >
              <el-option
                v-for="item of states"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryModal.fbgcId"
              clearable
              placeholder="请选择分部工程"
            >
              <el-option
                v-for="item of states"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="add">新增</el-button>
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        style="width: 100%;"
        row-key="id"
        :tree-props="{ children: 'children' }"
      >
        <el-table-column type="index" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column
          prop="categoryName"
          header-align="center"
          label="名称"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="categoryType"
          align="center"
          label="类型/子类"
          show-overflow-tooltip
          :formatter="categoryTypeData"
        >
        </el-table-column>
        <el-table-column
          prop="screeningTypeName"
          align="center"
          label="排查类型"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="gongfaId"
          align="center"
          label="关联工法"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="fbgcId"
          align="center"
          label="分部工程"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          prop="created"
          align="center"
          label="创建时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="220">
          <template slot-scope="scope">
            <el-button
              v-show="scope.row.categoryType == '0'"
              type="text"
              @click="addSon(scope.row)"
              >新增子类</el-button
            >
            <el-button type="text" @click="view(scope.row)">查看</el-button>
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" @click="del(scope.row)" style="color:red"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <!-- <el-pagination
        :total="page.total"
        :page-size="page.pageSize"
        :page-sizes="[10, 20, 30, 40]"
        :current-page="page.pageNo"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination> -->
      <el-pagination
        background
        :total="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        layout=" prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      width="30%"
      :title="title"
      :visible.sync="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <AddPage
        ref="addPageData"
        @CloseData="CloseData"
        :pageData="pageData"
        :submitType="submitType"
        :classes="classes"
      ></AddPage>
    </el-dialog>
  </div>
</template>

<script>
import AddPage from "./modules/addPage";
// 字典
import { getDictData } from "@/api/dict";
// 分页,删除,查看详情
import { categoryPage, categoryDel, categoryView } from "@/api/hdanger";

export default {
  components: { AddPage },
  data() {
    return {
      dialogVisible: false,
      queryModal: {
        categoryName: "",
        fbgcId: "",
        gongfaId: "",
        screeningType: "",
      },
      // 排查类型
      checkType: [],
      states: [],
      tableData: [{}],
      tableLoading: false,
      pageLoading: false,
      title: "",
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      //新增
      classes: 0,
      submitType: "",
      pageData: {},
    };
  },
  mounted() {
    this.getData();
    this.getDictList();
  },
  methods: {
    // 字典  screeningType 隐患排查类型
    getDictList() {
      let label = "screeningType";
      this.checkType = [];
      getDictData(label).then((res) => {
        if (res.code == 0) {
          res.data.forEach((element) => {
            this.checkType.push({
              label: element.intro,
              value: element.code,
            });
          });
        }
      });
    },
    // 查询
    search() {
      this.page.pageNo = 1;
      this.getData();
    },
    // 获取列表数据
    getData() {
      this.tableLoading = true;
      let params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.queryModal,
      };
      categoryPage(params).then((res) => {
        this.tableData = res.data.records;
        this.page.total = res.data.count;
        this.tableLoading = false;
      });
    },
    // formatter
    categoryTypeData(row) {
      return row.categoryType == "0"
        ? "父类"
        : row.categoryType == "1"
        ? "子类"
        : "";
    },
    // 新增
    add() {
      this.title = "新增隐患类别";
      this.classes = 0;
      this.dialogVisible = true;
      this.submitType = "add";
    },
    // 新增子类
    addSon(row) {
      this.title = "新增隐患子类";
      this.classes = 1;
      this.loadform("addSon", row);
    },
    // 查看
    view(row) {
      this.title = "查看";
      this.loadform("view", row);
    },
    // 编辑
    edit(row) {
      this.title = "编辑隐患";
      this.loadform("edit", row);
    },
    loadform(type, row) {
      this.dialogVisible = true;
      this.submitType = type;
      categoryView(row).then((res) => {
        this.pageData = { ...res.data };
      });
    },
    // 删除
    del(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        categoryDel(row.id)
          .then((res) => {
            if (res.code == 0) {
              this.$message.success("操作成功");
              this.getData();
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((res) => {
            this.$message.error(res.message);
          });
      });
    },
    // 关闭回调
    CloseData() {
      this.dialogVisible = false;
      this.getData();
    },
    handleClose() {
      this.$refs.addPageData.reset();
      this.CloseData();
    },
    // 分页
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 11;
}

.page-container {
  .handle-wrapper {
    min-width: 240px;
    max-width: 30vw;
    height: 60vh;
    position: absolute;
    top: 10%;
    left: 20px;
    background: rgba(255, 255, 255, 1);
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
    border-radius: 0px 20px 20px 0px;
    padding: 20px;
    z-index: 6;

    .tree-wrapper {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }

    .toggle-btn {
      width: 28px;
      height: 240px;
      position: absolute;
      right: 0px;
      top: 50%;
      margin-top: -120px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }

    .toggle-btn-close {
      transform: rotate(180deg);
    }

    .toggle-btn-open {
      transform: rotate(0deg);
    }
  }

  .handle-wrapper-hide {
    left: -212px;
    background: transparent;
    box-shadow: none;
  }

  .handle-wrapper-show {
    left: 0;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #43a5e9;
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
  }
}
</style>
