<template>
  <systemModal
    ref="formModal"
    :title="title"
    :showFooter="false"
    :width="'40%'"
  >
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="date" label="日期" width="180"> </el-table-column>
      <el-table-column prop="name" label="姓名" width="180"> </el-table-column>
      <el-table-column prop="address" label="地址"> </el-table-column>
    </el-table>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue"
import { EmergencyPageCardUserList } from '@/api/emergency'
export default {
  components: {
    systemModal,
  },
  data () {
    return {
      title: "对应人员",
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      loading: false,
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
    }
  },

  mounted () { },
  methods: {
    handleUser (data) {
      this.resetModel()
      this.title = "对应人员"
      this.handleEdit()
      let params = {
        planId: data.planId ? data.planId : null,
        roleId: data.roleId ? data.roleId : null,
        companyId: data.companyId ? data.companyId : null,
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
      }
      EmergencyPageCardUserList(params).then(res => {
        console.log(res)
        this.tableData = res.data.list
      })
    },
    handleEdit () {
      this.$refs.formModal.handleOpen()
    },
    // 分页器事件
    handleCurrentChange (val) {
      this.page.pageNo = val
      this.loadData()
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.loadData()
    },
    resetModel () {
      this.model = {
        lineId: "",
        siteId: "",
        sourceFileUrl: "",
        vendorType: "",
        evidenceFileUrl: "",
        evidenceFileName: "",
        sourceFileName: "",
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
