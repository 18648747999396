<template>
  <systemModal
    class="content"
    ref="formModal"
    @modalConfirm="handleSubmit"
    :title="title"
    width="50"
  >
    <baidu-map scroll-wheel-zoom @ready="mapReady">
      <!--地图视图-->
      <bm-view class="map"></bm-view>
      <bm-control>
        <el-form :inline="true" class="flex justify-between">
          <el-form-item>
            <el-input
              v-model="inputValue"
              placeholder="请输入地名关键字"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="confirmAddress(inputValue)"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </bm-control>
      <!--搜索-->
      <bm-local-search
        :keyword="keyword"
        :panel="false"
        :auto-viewport="true"
        :zoom="10000"
        :location="location"
        @markersset="setAddressList"
      ></bm-local-search>

      <!--点标注-->
      <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" />
      <bm-marker
        :position="location"
        :dragging="true"
        :disableMassClear="true"
        :top="true"
        :massClear="false"
        animation="BMAP_ANIMATION_BOUNCE"
      />
    </baidu-map>
    <div style="width:60%">
      <el-form
        label-width="100px"
        class="demo-model"
        :rules="rules"
        ref="modelForm"
        :model="model"
      >
        <el-form-item label="选择位置" prop="address">
          <el-input v-model="model.address"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </systemModal>
</template>
<script>
import systemModal from "@/components/Modal/systemModal.vue";
let geocoder;
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "坐标选择",
      model: {
        address: "",
      },
      keyword: "",
      location: {
        lng: 113.2839488029,
        lat: 23.0907270045,
      },
      isShowPanel: false,
      BMap: {},
      map: {},
      inputValue: "",
      rules: {
        address: [{ required: true, message: "请选择位置", trigger: "change" }],
      },
      search: [],
    };
  },
  watch: {
    keyword: {
      handler(val) {
        if (val) {
          console.log(this.keyword, "有值 搜索");
        } else {
          this.$nextTick(() => {
            console.log(this.search, "没值 搜索清除检索点");
            // if (this.search && this.search.point && this.search.point[0]) {
            this.map.clearOverlays();
            // }
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  // props: ["getCoo"],
  methods: {
    mapReady({ BMap, map }) {
      this.BMap = BMap;
      this.map = map;
      geocoder = new BMap.Geocoder();
      if (this.model.address) {
        this.keyword = this.model.address;
        this.map.centerAndZoom(
          new BMap.Point(this.location.lng, this.location.lat),
          10
        );
      } else {
        this.map.centerAndZoom(
          new BMap.Point(113.2839488029, 23.0907270045),
          10
        );
      }
      map.addEventListener("click", ({ point }) => {
        this.location.lng = point.lng;
        this.location.lat = point.lat;
        geocoder.getLocation(point, (res) => {
          this.model.address = res.address;
          this.model.storeLongitude = point.lng;
          this.model.storeLatitude = point.lat;
          this.$forceUpdate();
        });
      });
    },
    setAddressList(e) {
      console.log("搜索相关数据", e);
      this.search = e;
      // this.$emit("handelSearch", this.search);
    },
    confirmAddress(e) {
      console.log(e, "查询的关键字");
      if (e == undefined) {
        // this.keyword == "";
      } else {
        this.keyword = e;
      }
      setTimeout(() => {
        var local = new this.BMap.LocalSearch(this.map, {
          renderOptions: { map: this.map },
        });
        local.search(this.keyword);
      }, 0);
    },

    handleAdd(lng, lat, address) {
      this.$refs.formModal.handleOpen();
      this.$nextTick(() => {
        console.log(this.search, "展示地图的弹窗查看原来检索的数据");
        // if (this.search && this.search.point && this.search.point[0]) {
        this.map.clearOverlays();
        // }
      });
      this.inputValue = "";
      if (!address) {
        this.model.address = "";
      } else {
        this.model.address = address;
        this.location.lng = lng;
        this.location.lat = lat;
      }
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.$emit(
            "getCoo",
            this.model.storeLongitude,
            this.model.storeLatitude,
            this.model.address
          );
          this.$refs.formModal.handleClose();
        } else {
          this.$message({
            message: "请选择位置",
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 500px;
}

.more_panel {
  text-align: center;
  font-size: 12px;
  color: #2878ff;
  padding-top: 10px;

  span {
    cursor: pointer;
    i {
      transform: rotate(90deg);
    }
  }

  span.down {
    i {
      transform: rotate(-90deg);
    }
  }
}
::v-deep .el-dialog__body {
  padding: 30px 0 !important;
}
.demo-model {
  margin: 20px 0 0;
}
.el-form-item {
  margin: 20px 0 20px 20px;
  // width: 80%;
}
.content {
  ::v-deep .el-input__inner,
  ::v-deep .el-textarea__inner {
    background: #fff;
    border-color: #dcdfe6;
    color: #747985;
    font-size: 16px;
  }
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #dcdfe6;
  }
  ::v-deep .el-input__inner:hover,
  ::v-deep .el-textarea__inner:hover {
    border-color: #dcdfe6;
  }
  ::v-deep .el-form-item__label {
    font-size: 16px;
    color: #000;
  }
}
</style>
