<template>
  <!-- 隐患响应管理 -->
  <div class="page-container bg-white p-4 h-full relative">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item prop="releaseUnitType">
            <el-select
              v-model="queryModal.releaseUnitType"
              clearable
              placeholder="请选择响应单位"
              @change="unitTypeChange"
            >
              <el-option
                v-for="item of unitTypeData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryModal.roleId"
              clearable
              placeholder="请选择用户角色"
            >
              <el-option
                v-for="item of rolesData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="add">新增</el-button>
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table v-loading="tableLoading" :data="tableData" style="width: 100%;">
        <el-table-column type="index" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column
          prop="releaseUnitName"
          align="center"
          label="隐患发布单位"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="reformUnitName"
          align="center"
          label="隐患整改单位"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="hiddenLevelName"
          align="center"
          label="隐患等级"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="responseUnitName"
          align="center"
          label="隐患响应单位"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="roleName"
          align="center"
          label="隐患响应角色"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="responseDeadline"
          align="center"
          label="响应时限(小时)"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="220" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="view(scope.row)">查看</el-button>
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" @click="del(scope.row)" style="color:red"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        :total="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        layout=" prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      width="30%"
      :title="title"
      :visible.sync="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <AddPage
        ref="addPageData"
        @CloseData="CloseData"
        :pageData="pageData"
        :submitType="submitType"
      ></AddPage>
    </el-dialog>
  </div>
</template>

<script>
import AddPage from "./modules/addPage";
// 字典
import { getDictData } from "@/api/dict";
// 查询角色列表
import { getRoleApi } from "@/api/role";
// 列表,删除
import { responsePage, responseDel } from "@/api/hdanger";

export default {
  components: {
    AddPage,
  },
  data() {
    return {
      dialogVisible: false,
      queryModal: {
        releaseUnitType: "",
        roleId: "",
      },
      unitTypeData: [],
      rolesData: [],
      tableData: [],
      tableLoading: false,
      pageLoading: false,
      title: "",
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      //新增
      submitType: "",
      pageData: {},
    };
  },
  mounted() {
    this.getData();
    this.getDictList();
  },
  methods: {
    // 字典  unitType 单位类型
    getDictList() {
      let label = "unitType";
      this.unitTypeData = [];
      getDictData(label).then((res) => {
        if (res.code == 0) {
          res.data.forEach((element) => {
            this.unitTypeData.push({
              label: element.intro,
              value: element.code,
            });
          });
        }
      });
    },
    unitTypeChange(val) {
      this.queryModal.roleId = "";
      this.rolesData = [];
      getRoleApi({ unitType: val })
        .then((res) => {
          if (res.code == 0) {
            res.data.forEach((element) => {
              this.rolesData.push({
                label: element.roleName,
                value: element.id,
              });
            });
          }
        })
        .catch((res) => {
          console.log(res.messafe);
        });
    },
    // 查询
    search() {
      this.page.pageNo = 1;
      this.getData();
    },
    // 获取列表数据
    getData() {
      this.tableLoading = true;
      let params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.queryModal,
      };
      responsePage(params).then((res) => {
        this.tableData = res.data.records;
        this.page.total = res.data.count;
        this.tableLoading = false;
      });
    },
    // 新增
    add() {
      this.title = "新增";
      this.submitType = "add";
      this.dialogVisible = true;
      this.$refs.addPageData.reset();
    },
    // 编辑
    edit(row) {
      this.title = "编辑";
      this.loadform("edit", row);
    },
    // 查看
    view(row) {
      this.title = "查看";
      this.loadform("view", row);
    },
    loadform(type, row) {
      this.dialogVisible = true;
      this.submitType = type;
      const rowinfo = { ...row };
      this.pageData = rowinfo;
    },
    // 删除
    del(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        responseDel(row.id)
          .then((res) => {
            if (res.code == 0) {
              this.$message.success("操作成功");
              this.getData();
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((res) => {
            this.$message.error(res.message);
          });
      });
    },

    // 关闭回调
    CloseData() {
      this.dialogVisible = false;
      this.getData();
    },
    handleClose() {
      this.$refs.addPageData.reset();
      this.dialogVisible = false;
    },
    // 分页
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 11;
}

.page-container {
  .handle-wrapper {
    min-width: 240px;
    max-width: 30vw;
    height: 60vh;
    position: absolute;
    top: 10%;
    left: 20px;
    background: rgba(255, 255, 255, 1);
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
    border-radius: 0px 20px 20px 0px;
    padding: 20px;
    z-index: 6;

    .tree-wrapper {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }

    .toggle-btn {
      width: 28px;
      height: 240px;
      position: absolute;
      right: 0px;
      top: 50%;
      margin-top: -120px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }

    .toggle-btn-close {
      transform: rotate(180deg);
    }

    .toggle-btn-open {
      transform: rotate(0deg);
    }
  }

  .handle-wrapper-hide {
    left: -212px;
    background: transparent;
    box-shadow: none;
  }

  .handle-wrapper-show {
    left: 0;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #43a5e9;
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
  }
}
</style>
