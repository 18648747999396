<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="table-wrapper">
      <el-table :data="tableData" v-loading="tableLoading" style="width: 100%">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="80"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="事件名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="工点"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="typeName"
          label="事件类型"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="responseLevel"
          label="事件级别"
        ></el-table-column>

        <el-table-column
          align="center"
          prop="created"
          label="发生时间"
        ></el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-count="page.count"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { recordList } from "@/api/response";
export default {
  data() {
    return {
      props: {
        paramsData: {
          type: Object,
          default: () => {},
        },
      },
      queryModal: {
        lineSite: "",
        times: [],
        siteIds: [],
        category: "",
        type: "",
        responseLevel: "",
        name: "",
      },
      tableData: [],
      page: {
        page: 1,
        size: 10,
        total: 0,
        count: 0,
      },
      tableLoading: false,
    };
  },
  mounted() {},
  methods: {
    //条数改变
    handleSizeChange(val) {
      this.page.size = val;
      this.page.page = 1;
      this.getdata();
    },
    // 翻页
    handleCurrentChange(val) {
      this.page.page = val;
      this.getdata();
    },
    async getdata() {
      // siteId 线路工点
      //    name 事件名称
      //    type 事件类型
      //    responseLevel 事件级别
      let params = {
        siteId: this.paramsData.siteIds,
        startTime: "",
        endTime: "",
        category: this.queryModal.category,
        name: this.paramsData.name,
        type: this.paramsData.type,
        responseLevel: this.paramsData.responseLevel,
        page: this.page.page,
        size: this.page.size,
        endFile: 1,
      };
      let res = await recordList(params);
      if (res.code == 0) {
        this.tableData = res.data.list;
        this.page.page = res.data.pageNum;
        this.page.size = res.data.pagesize;
        this.page.total = res.data.total;
        this.tableData.forEach((e) => {
          if (e.category) {
            console.log(this.caseList.find((el) => el.id == e.category));
            e.categoryName = this.caseList.find(
              (el) => el.id == e.category
            ).label;
          }
        });
        this.tableLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
