<template>
    <el-select multiple collapse-tags :value="nameArr" :clearable="true" placeholder="单位" @clear="clearHandle">
      <el-option :value="valueTitle" :label="valueTitle">
        <el-tree
          id="tree-option"
          ref="selectTree"
          show-checkbox
          default-expand-all
          :data="deptData"
          :props="props"
          :node-key="props.value"
          @check-change="handleCheckChange"
        >
        </el-tree>
      </el-option>
    </el-select>
  </template>

  <script>
  export default {
    name: "selectTree",
    props: {
      /* 配置项 */
      props: {
        type: Object,
        default: () => {
          return {
            value: "id", // ID字段名
            label: "name", // 显示名称
            children: "children", // 子级字段名
            expandTrigger: "hover"
          }
        }
      },
      /* 初始值 */
      value: {
        type: Number,
        default: () => {
          return null
        }
      }
    },
    data() {
      return {
        valueTitle: "",
        idArr: [],
        nameArr: [],
        deptData: []
      }
    },
    mounted() {
      this.initHandle()
      this.$api.dept.findDeptTree().then((res) => {
        if (res.code == 200) {
          this.deptData = res.data[0].children
        }
      })
    },
    methods: {
      // 初始化值
      initHandle() {
        this.$nextTick(() => {
          let scrollWrap = document.querySelectorAll(".el-scrollbar .el-select-dropdown__wrap")[0]
          let scrollBar = document.querySelectorAll(".el-scrollbar .el-scrollbar__bar")
          scrollWrap.style.cssText = "margin: 0px; max-height: none; overflow: hidden;"
          scrollBar.forEach((ele) => (ele.style.width = 0))
        })
      }, // 切换选项
      handleCheckChange() {
        this.nameArr = []
        this.idArr = []
        this.$refs.selectTree.getCheckedNodes().forEach((item) => {
          this.nameArr.push(item.name)
          this.idArr.push(item.id)
        })
        // console.log(this.idArr)
        this.$emit("getValue", this.idArr)
      },
      // 清除选中
      clearHandle() {
        this.$refs.selectTree.setCheckedKeys([])
        this.nameArr = []
        this.idArr = []
        this.clearSelected()
        this.$emit("getValue", null)
      } /* 清空选中样式 */,
      clearSelected() {
        let allNode = document.querySelectorAll("#tree-option .el-tree-node")
        allNode.forEach((element) => element.classList.remove("is-current"))
      }
    },
    watch: {
      value() {
        this.initHandle()
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
    height: auto;
    max-height: 274px;
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
  }
  .el-select-dropdown__item.selected {
    font-weight: normal;
  }
  .el-tree {
    color: #606266;
  }
  .el-select__tags {
    flex-wrap: nowrap !important;
    overflow: hidden;
  }
  .el-tag.el-tag--info .el-tag__close {
    display: none;
  }

  .el-tree .el-tree-node__content {
    height: auto;
    padding: 0 20px;
  }
  .el-tree .is-current .el-tree-node__label {
    color: #409eff;
    font-weight: 700;
  }
  .el-tree .is-current .el-tree-node__children .el-tree-node__label {
    color: #606266;
    font-weight: normal;
  }
  </style>
