import Layout from "@/components/Layout/SystemLayout.vue";
import Users from "@/views/SystemManagement/Users/Users";
import Roles from "@/views/SystemManagement/Roles/Roles";
import orgMange from "@/views/SystemManagement/Organization/index.vue";
// import unitManage from "@/views/SystemManagement/unitManage/index.vue";
import Myset from "@/views/SystemManagement/Myset/Myset";
import DataInfo from "@/views/SystemManagement/Users/dataInfo";
import Menu from "@/views/SystemManagement/ResourcesMenu/ResourcesMenu";
import PersonPermission from "@/views/SystemManagement/PersonPermission/PersonPermission";
import Department from "@/views/SystemManagement/Users/department";
import ProjectManagement from "@/views/SystemManagement/Management/index.vue";
import PostMangement from "@/views/SystemManagement/PostMangement/index.vue";
import PersonInfo from "@/views/SystemManagement/personInfo/index.vue";
import Record from "@/views/SystemManagement/Record/index.vue";
import Criminalrecords from "@/views/SystemManagement/Criminalrecords/index.vue";

import loginManage from "@/views/SystemManagement/loginManage/index.vue";
import operateManage from "@/views/SystemManagement/operateManage/index.vue";
import systemManage from "@/views/SystemManagement/systemManage/index.vue";

const Routes = [
  {
    path: "SystemManagement",
    component: Layout,
    name: "SystemManagement",
    meta: {
      title: "系统管理",
    },
    children: [
      {
        path: "PersonManage",
        name: "PersonManage",
        component: Layout,
        meta: {
          title: "人员管理",
        },
        children: [
          {
            path: "PersonInfo",
            name: "PersonInfo",
            component: PersonInfo,
            meta: {
              title: "人员信息管理",
            },
          },
          {
            path: "Record",
            name: "Record",
            component: Record,
            meta: {
              title: "人员统计",
            },
          },
          {
            path: "Criminalrecords",
            name: "Criminalrecords",
            component: Criminalrecords,
            meta: {
              title: "黑白名单管理",
            },
          },
        ],
      },

      {
        path: "limits",
        name: "limits",
        component: Layout,
        meta: {
          title: "权限管理",
        },
        children: [
          {
            path: "roleManage",
            name: "roleManage",
            // component: Roles,
            component: Layout,
            meta: {
              title: "角色管理",
            },
            children: [
              {
                path: "Roles",
                name: "Roles",
                component: Roles,
                meta: {
                  title: "角色信息",
                },
              },
              // {
              //   path: "Menu",
              //   name: "Menu",
              //   component: Menu,
              //   meta: {
              //     title: "菜单管理",
              //   },
              // },
            ],
          },
        ],
      },
      {
        path: "configInfo",
        name: "configInfo",
        component: Layout,
        meta: {
          title: "配置信息",
        },
        children: [
          {
            path: "Menu",
            name: "Menu",
            component: Menu,
            meta: {
              title: "菜单管理",
            },
          },
          {
            path: "DataInfo",
            name: "DataInfo",
            component: DataInfo,
            meta: {
              title: "字典表管理",
            },
          },
          {
            path: "Project",
            name: "Project",
            component: ProjectManagement,
            meta: {
              title: "项目管理",
            },
          },
        ],
      },

      {
        path: "Users",
        name: "Users",
        component: Users,
        meta: {
          title: "用户管理",
        },
      },

      {
        path: "recordManage",
        name: "recordManage",
        component: Layout,
        meta: {
          title: "日志管理",
        },
        children: [
          {
            path: "loginManage",
            name: "loginManage",
            component: loginManage,
            meta: {
              title: "登录日志",
            },
          },
          {
            path: "operateManage",
            name: "operateManage",
            component: operateManage,
            meta: {
              title: "操作日志",
            },
          },
          {
            path: "systemManage",
            name: "systemManage",
            component: systemManage,
            meta: {
              title: "系统日志",
            },
          },
        ],
      },

      {
        path: "Organization",
        name: "Organization",
        // component: Organization,
        component: Layout,
        meta: {
          title: "组织机构",
        },
        children: [
          // {
          //   path: "unitManage",
          //   name: "unitManage",
          //   component: unitManage,
          //   meta: {
          //     title: "单位管理",
          //   },
          // },
          {
            path: "orgMange",
            name: "orgMange",
            component: orgMange,
            meta: {
              title: "组织管理",
            },
          },
          {
            path: "PostMangement",
            name: "PostMangement",
            component: PostMangement,
            meta: {
              title: "岗位管理",
            },
          },
        ],
      },
      {
        path: "PersonPermission",
        name: "PersonPermission",
        component: PersonPermission,
        meta: {
          title: "人员权限",
        },
      },
      {
        path: "Myset",
        name: "Myset",
        component: Myset,
        meta: {
          title: "密码修改",
        },
      },

      {
        path: "Department",
        name: "Department",
        component: Department,
        meta: {
          title: "部门权限",
        },
      },
    ],
  },
];

export default Routes;
