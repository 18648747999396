<template>
  <div class="emergency-container">
    <index-box :title="title">
      <div class="emergency-wrapper">
        <div
          class="list"
          v-for="(item, index) in groupData"
          :key="index"
          @click="manageClick(item)"
        >
          <p class="count">{{ item.dueCount }}</p>
          <p class="contentName">{{ item.name }}</p>
        </div>
      </div>
    </index-box>
    <HomePageDialog
      ref="modalTable"
      :titleTable="titleTable"
      :columns="columns"
    ></HomePageDialog>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import HomePageDialog from "@/views/HiddenDanger/components/HomePageDialog.vue";
// 数据
import { getRiskManageCount } from "@/api/hdanger";
export default {
  name: "emergency-container",
  components: {
    IndexBox,
    HomePageDialog,
  },
  data() {
    return {
      title: "风险管理统计",
      groupData: [
        {
          name: "应交底数量",
          dueCount: 578,
          color: "#FFFFFF",
        },
        {
          name: "已交底数量",
          dueCount: 577,
          color: "#72C040",
        },
        {
          name: "未交底数量",
          dueCount: 1,
          color: "#FAAD13",
        },
        {
          name: "应落实数量",
          dueCount: 578,
          color: "#1791FF",
        },
        {
          name: "已落实数量",
          dueCount: 578,
          color: "#1791FF",
        },
        {
          name: "未落实数量",
          dueCount: 0,
          color: "#1791FF",
        },
      ],
      titleTable: "",
      columns: [
        { prop: "lineSite", label: "线路工点", minWidth: 80 },
        { prop: "rotaName", label: "风险名称", minWidth: 80 },
        { prop: "mobile", label: "季度", minWidth: 80 },
        { prop: "mobile1", label: "交底次数", minWidth: 80 },
      ],
    };
  },

  mounted() {
    this.getData();
    // this.getResponseLog();
  },
  methods: {
    manageClick(data) {
      console.log(data);
      // this.titleTable = data.name + "列表"
      // this.$refs.modalTable.handleOpen("left");
    },
    getData() {
      getRiskManageCount().then((res) => {
        if (res.code == 0) {
          this.arrData = [];
          if (res.data) {
            this.groupData.forEach((item) => {
              switch (item.name) {
                case "应交底数量":
                  item.dueCount = res.data.discloseCount
                    ? res.data.discloseCount
                    : 0;
                  break;
                case "已交底数量":
                  item.dueCount = res.data.finishDiscloseCount
                    ? res.data.finishDiscloseCount
                    : 0;
                  break;
                case "未交底数量":
                  item.dueCount = res.data.noDiscloseCount
                    ? res.data.noDiscloseCount
                    : 0;
                  break;
                case "应落实数量":
                  item.dueCount = res.data.implementCount
                    ? res.data.implementCount
                    : 0;
                  break;
                case "已落实数量":
                  item.dueCount = res.data.finishImplementCount
                    ? res.data.finishImplementCount
                    : 0;
                  break;
                case "未落实数量":
                  item.dueCount = res.data.noImplementCount
                    ? res.data.noImplementCount
                    : 0;
                  break;
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.emergency-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;

  .list {
    width: 33.3%;
    border-right: 1px solid rgba(#ffffff, 0.1);
    border-top: 1px solid rgba(#ffffff, 0.1);
  }
  .list:nth-child(1),
  .list:nth-child(2),
  .list:nth-child(3) {
    border-top: none;
  }
  .list:nth-child(3),
  .list:nth-child(6),
  .list:nth-child(9) {
    border-right: none;
  }
  .count {
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
  }
  .contentName {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.6;
  }
}
</style>
