<template>
  <div class="monitor-windpower-container p-4 h-full">
    <div
      class="windpower-main-wrapper h-full flex flex-col"
      v-loading="loading"
      element-loading-text="数据加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
    >
      <div class="search-wrapper">
        <el-form :inline="true" :model="queryData">
          <!-- <el-form-item>
            <el-select v-model="queryData.specifications" placeholder="请选择风速">
              <el-option v-for="item in windLevelOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-input
              clearable
              v-model="queryData.name"
              placeholder="输入工点名称搜索"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-radio-group v-model="radio" @change="radioChange">
              <el-radio-button :label="1">雨量</el-radio-button>
              <el-radio-button :label="2">风力</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div class="item-wrapper flex-1 overflow-y-scroll">
        <ul
          v-if="radio == '1'"
          class="item-list h-full flex flex-wrap items-start"
        >
          <li
            class="flex flex-col"
            v-for="(data, key) in RainListData"
            :key="key"
            :class="
              data.num < 30
                ? 'box-border'
                : data.num < 70
                ? 'box-border-orange'
                : 'box-border-red'
            "
          >
            <div class="item-header text-center flex justify-center">
              <!-- <p>{{ data.lineSiteName }}</p> -->
              <p :title="data.lineName + '-' + data.siteName">
                <marquee
                  align="left"
                  behavior="scroll"
                  onMouseOut="this.start()"
                  onMouseOver="this.stop()"
                  >{{ data.lineName }}-{{ data.siteName }}</marquee
                >
              </p>
            </div>
            <div class="item-content flex-1 relative">
              <div class="absolute z-7 w-full h-full">
                <div class="text-center flex flex-col justify-around">
                  <p class="num">{{ data.num }}mm</p>
                  <p class="text flex justify-center">
                    <!-- <span>2小时内降雨变化</span> -->
                  </p>
                </div>
              </div>
              <div class="absolute w-full h-full z-0">
                <div
                  class="absolute z-0 w-full h-full liquidfill-box"
                  :ref="`liquidfill${key}`"
                ></div>
              </div>
            </div>
          </li>
        </ul>
        <ul
          v-if="radio == '2'"
          class="item-list h-full flex flex-wrap items-start"
        >
          <li
            class="flex flex-col"
            v-for="(data, key) in WindListData"
            :key="key"
            :class="
              data.level < 4
                ? 'box-border'
                : data.level < 7
                ? 'box-border-orange'
                : 'box-border-red'
            "
          >
            <div class="item-header text-center flex justify-center">
              <p :title="data.lineName + '-' + data.siteName">
                <marquee
                  align="left"
                  behavior="scroll"
                  onMouseOut="this.start()"
                  onMouseOver="this.stop()"
                  >{{ data.lineName }}-{{ data.siteName }}</marquee
                >
              </p>
              <!-- <p>{{ data.lineName }}-{{ data.siteName }}</p> -->
            </div>
            <div
              class="
                item-content
                flex-1
                text-center
                flex flex-col
                justify-around
                p-4
              "
            >
              <p class="num">{{ data.windSpeed }}m/s</p>
              <p class="text flex justify-center">
                <span class="text-opacity-60">{{ data.windDirect }}</span>
                <span class="text-opacity-60 ml-4">{{ data.windLevel }}</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="pagination-wrapper flex justify-center">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="queryData.total"
          :current-page.sync="queryData.page"
          :page-size="9"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-liquidfill";
import { getRain, getWind } from "@/api/map";
export default {
  data() {
    return {
      radio: "1",
      queryData: {
        name: "",
        // pointRangeId: "",
        page: 1,
        size: 9,
        total: 1,
      },
      windLevelOptions: [],
      RainListData: [],
      WindListData: [],

      loading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      let container = document.getElementsByClassName("liquidfill-box")[0];
      let container1 = document.getElementsByClassName("liquidfill-box")[1];
      let container2 = document.getElementsByClassName("liquidfill-box")[2];
      this.initLiquidfill(container, { num: 18 });
      this.initLiquidfill(container1, { num: 14 });
      this.initLiquidfill(container2, { num: 7 });
    });
    this.getDataRain();
    this.getDataWind();
  },
  methods: {
    // change切换
    radioChange(val) {
      // 雨量为1,风量为2
      if (val == "1") {
        this.getDataRain();
      } else {
        this.getDataWind();
      }
      this.queryData.page = 1;
    },
    getDataRain() {
      this.loading = true;
      let data = {
        name: this.queryData.name == "" ? "-" : this.queryData.name,
        pointRangeId:
          this.queryData.pointRangeId == "" ? "-" : this.queryData.pointRangeId,
        page: this.queryData.page,
        size: this.queryData.size,
      };
      getRain(data).then((res) => {
        if (res.code == 0) {
          this.RainListData = res.data.records;
          if (this.RainListData) {
            this.RainListData.forEach((e) => {
              e.num = Number(e.nowPoint.toString().match(/^\d+(?:\.\d{0,2})?/));
            });
          }
          this.queryData.total = res.data.count;
        }
        this.loading = false;
      });
    },
    getDataWind() {
      this.loading = true;
      let data = {
        name: this.queryData.name == "" ? "-" : this.queryData.name,
        pointRangeId:
          this.queryData.pointRangeId == "" ? "-" : this.queryData.pointRangeId,
        page: this.queryData.page,
        size: this.queryData.size,
      };
      getWind(data).then((res) => {
        if (res.code == 0) {
          this.WindListData = res.data.records;
          if (this.WindListData) {
            this.WindListData.forEach((e) => {
              e.level = e.windLevel.substring(0, e.windLevel.indexOf("级"));
              e.windSpeed = Number(
                e.windSpeed.toString().match(/^\d+(?:\.\d{0,2})?/)
              );
              console.log(e.level);
            });
          }
          this.queryData.total = res.data.count;
        }
        this.loading = false;
      });
    },
    //搜索
    onSearch() {
      this.queryData.page = 1;
      if (this.radio == "1") {
        this.getDataRain();
      } else {
        this.getDataWind();
      }
    },
    //条数改变
    handleSizeChange(val) {
      this.queryData.size = val;
      this.queryData.page = 1;
      if (this.radio == "1") {
        this.getDataRain();
      } else {
        this.getDataWind();
      }
    },
    // 翻页
    handleCurrentChange(val) {
      this.queryData.page = val;
      if (this.radio == "1") {
        this.getDataRain();
      } else {
        this.getDataWind();
      }
    },
    initLiquidfill(el, data) {
      console.log(data);
      const itemColorList = {
        red: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgba(226, 60, 57, 0.3)",
          },
          {
            offset: 1,
            color: "rgba(226, 60, 57, 0.1)",
          },
        ]),
        orange: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgba(250, 173, 19, 0.3)",
          },
          {
            offset: 1,
            color: "rgba(250, 173, 19, 0.1)",
          },
        ]),
        green: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgba(114, 192, 64, 0.3)",
          },
          {
            offset: 1,
            color: "rgba(114, 192, 64, 0.1)",
          },
        ]),
      };
      let currentColorUse = itemColorList.green;
      if (data.num > 10 && data.num <= 15) {
        currentColorUse = itemColorList.orange;
      } else if (data.num > 15) {
        currentColorUse = itemColorList.red;
      }
      const charts = echarts.init(el);
      // const option = {
      //   series: [
      //     {
      //       type: "liquidFill",
      //       data: [0.5],
      //       shape: "container",
      //       outline: {
      //         show: false
      //       },
      //       itemStyle: {
      //         color: currentColorUse
      //       },
      //       backgroundStyle: {
      //         color: "rgba(255, 255, 255, 0)"
      //       },
      //       label: {
      //         show: false
      //       },
      //       amplitude: "10%"
      //     }
      //   ]
      // };
      const option = {
        xAxis: {
          show: false,
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          show: false,
        },
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            areaStyle: {
              color: currentColorUse,
            },
            lineStyle: {
              width: 0,
            },
            smooth: true,
            showSymbol: false,
          },
        ],
      };
      charts.setOption(option);
      window.addEventListener("resize", () => {
        charts.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.monitor-windpower-container {
  background: rgba(8, 23, 66, 0.9);
}
.box-border {
  border: 1px solid #0e2553;

  .num {
    color: #72c040;
  }
}

.box-border-red {
  border: 1px solid rgba(226, 60, 57, 0.8);

  .num {
    color: #e23c39;
  }
}

.box-border-orange {
  border: 1px solid rgba(240, 175, 65, 0.8);

  .num {
    color: #faad13;
  }
}

::v-deep .el-input__inner {
  background: #18467f;
  color: #fff;
  border: none;
}

.item-wrapper {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  li {
    // width: 16.43vw;
    width: 32%;
    height: 20.81vh;
    margin-right: 1vw;
    margin-top: 1vw;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .item-header {
    p {
      line-height: 36px;
      clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
      background: linear-gradient(
        0deg,
        rgba(67, 165, 233, 0.7) 0%,
        rgba(67, 165, 233, 0.1) 100%
      );
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      width: 72.62%;
    }
  }

  .item-content {
    .num {
      font-size: 36px;
      font-family: Arial;
      font-weight: 400;
    }

    .text {
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .cordon-wrapper {
      top: 25%;

      .cordon {
        span {
          color: rgba(255, 255, 255, 0.1);
        }

        &::after {
          content: "";
          display: block;
          flex: 1;
          height: 1px;
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}
</style>
