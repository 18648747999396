<template>
  <div>
    <nodeAccForm
      :rowData="applyInfo"
      v-if="isApply == true && isAgent == false"
      jlView="jl"
      type="view"
    ></nodeAccForm>
    <!-- <nodeAccForm v-if="isShow == true" :rowData="AuditInfo" type="view"></nodeAccForm> -->
    <div v-if="isAudit == true && isAgent == false">
      <el-form
        v-if="isShow == true"
        :model="AuditInfo"
        label-width="100px"
        class="demo-model"
      >
        <el-row>
          <el-col :span="24">
            <h3 class="font-black mb-4" style="font-size:14px">
              施工单位提交整改
            </h3>
          </el-col>
          <el-col :span="12">
            <el-form-item label="整改人">
              <el-input readonly v-model="AuditInfo.userName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="整改时间">
              <el-input v-model="AuditInfo.operationTime" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="整改描述">
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6 }"
                v-model="AuditInfo.remark"
                resize="none"
                readonly
                :placeholder="AuditInfo.state == 6 ? '整改描述' : '申请描述'"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="附件" prop="fileList">
              <ul class="fileGroupBox">
                <li v-for="(item, i) in AuditInfo.fileList" :key="i">
                  <span v-if="rowData.state == 6"
                    >《风险管控关键节点实施前条件验收整改情况确认表》</span
                  >
                  <span v-else>《风险管控关键节点实施前条件验收申请表》</span>
                  <span @click="handleFileClick(item)">预览</span>
                  <span @click="handleExport(item.fileRealUrl, item.fileName)"
                    >导出</span
                  >
                </li>
              </ul>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- :rules="type == 'view' ? viewRule : rules" -->
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-model"
    >
      <el-row>
        <el-col :span="24">
          <h3 class="font-black mb-4">监理单位审核</h3>
        </el-col>
        <el-col :span="12">
          <el-form-item label="审核人" prop="userName">
            <el-input
              v-if="type != 'view'"
              v-model="ruleForm.userName"
              placeholder="审核人"
            ></el-input>
            <el-input
              v-if="type == 'view'"
              readonly
              v-model="ruleForm.userName"
              placeholder="审核人"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="审核时间" prop="operationTime">
            <el-input v-model="ruleForm.operationTime" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="审核描述" prop="remark">
            <el-input
              type="textarea"
              v-if="type != 'view'"
              :autosize="{ minRows: 4, maxRows: 6 }"
              v-model="ruleForm.remark"
              resize="none"
              placeholder="审核描述"
            ></el-input>
            <el-input
              type="textarea"
              v-if="type == 'view'"
              readonly
              :autosize="{ minRows: 4, maxRows: 6 }"
              v-model="ruleForm.remark"
              resize="none"
              placeholder="审核描述"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="附件" prop="fileOneList" v-if="type != 'view'">
            <FileUploader
              type="1"
              @uploadChange="handleFileUpload"
              @delChange="handleFileDel"
              :value="fileOneList"
            ></FileUploader>
          </el-form-item>
          <el-form-item label="" prop="fileTowList" v-if="type != 'view'">
            <FileUploader
              type="2"
              @uploadChange="handleFileTwoUpload"
              @delChange="handleFileDel"
              :value="fileTowList"
            ></FileUploader>
          </el-form-item>
          <el-form-item label="附件" v-if="type == 'view'">
            <ul class="fileGroupBox">
              <li v-for="(item, i) in ruleForm.fileList" :key="i">
                <span>{{ item.fileName }}</span>
                <span @click="handleFileClick(item)">预览</span>
                <span @click="handleExport(item.fileRealUrl, item.fileName)"
                  >导出</span
                >
              </li>
            </ul>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="dialogFooter mt-4" v-if="type != 'view'">
      <el-button size="medium" type="primary" @click="submit('5')"
        >通过</el-button
      >
      <!-- v-if="type != 'event'" -->
      <el-button size="medium" type="warning" @click="submit('3')"
        >整改后通过</el-button
      >
      <el-button size="medium" type="danger" @click="submit('2')"
        >驳回</el-button
      >
    </div>
  </div>
</template>

<script>
import {
  confirmRecord,
  nodeStateRecordDetail,
  nodeCheckAdd
} from "@/api/riskManage";
import dayjs from "dayjs";
import FileUploader from "./FileUploader";
import nodeAccForm from "./nodeAccForm";
export default {
  components: {
    FileUploader,
    nodeAccForm
  },
  props: {
    rowData: {
      type: Object,
      default: () => {}
    },
    nodeId: {
      type: Number
    },
    type: {
      type: String,
      default: ""
    },
    isAgent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ruleForm: {
        companyName: "",
        companyUuid: "",
        userName: "",
        userUuid: "",
        companyTypeName: "",
        companyTypeCode: "",
        departmentName: "",
        departmentUuid: "",
        fileList: [],
        operationTime: "",
        remark: "",
        nodeUuid: "",
        nodeName: "",
        id: "",
        recordType: null,
        fileOneList: [],
        fileTowList: []
      },
      rules: {
        remark: [{ required: true, message: "请输入描述", trigger: "blur" }],
        fileOneList: [
          {
            required: true,
            message: "请上传风险管控关键节点施工前条件核查表",
            trigger: "change"
          }
        ],
        fileTowList: [
          {
            required: true,
            message: "请上传风险管控关键节点实施前条件验收记录表",
            trigger: "change"
          }
        ]
      },
      viewRule: {},
      isRequire: false,
      lineSiteData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid"
      },
      selloading: false,
      options: [],
      fileList: [], // 附件列表
      siteList: [], // 层级站点树
      faltArr: [], //站点数平行数据
      photoList: [], //数组图片列表
      filter: {
        pageSize: 10,
        pageNum: 1,
        nodeUuid: null,
        keyNodesType: 1,
        eventId: null
      },
      fileOneList: [],
      fileTowList: [],
      applyInfo: {},
      AuditInfo: {},
      isShow: false,
      isApply: true,
      isAudit: false
    };
  },
  mounted() {
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      this.ruleForm.companyName = user.companyName;
      this.ruleForm.companyUuid = user.companyId;
      this.ruleForm.userName = user.realName;
      this.ruleForm.userUuid = user.uuid;
      this.ruleForm.companyTypeName = user.companyCategoryName;
      this.ruleForm.companyTypeCode = user.companyCategory;
      this.ruleForm.departmentName = user.departmentName;
      this.ruleForm.departmentUuid = user.departmentId;
      this.ruleForm.operationTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
    }
    if (this.type != "view") {
      this.ruleForm.operationTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.ruleForm.id = this.rowData.id;
      this.ruleForm.nodeUuid = this.rowData.nodeUuid;
      this.ruleForm.nodeName = this.rowData.nodeName;
      this.filter.eventId = this.rowData.eventId;
      this.ruleForm.eventId = this.rowData.eventId;
    } else {
      console.log("fffffff");
      // if (this.type == "view") {
      this.getEvnetDeta();
      // }
    }
    console.log(this.rowData, "Ddddddd");
    this.getDetails();
    this.getLineTree();
  },
  watch: {
    detailsForm: {
      deep: true,
      handler() {
        if (this.type == "view") {
          this.ruleForm = this.detailsForm;
        }
      }
    },
    "ruleForm.warnTypeName": {
      deep: true,
      handler() {
        if (this.ruleForm.warnTypeName == "巡视预警") {
          this.isRequire = true;
        } else {
          this.isRequire = false;
        }
      }
    }
  },
  methods: {
    getEvnetDeta() {
      console.log("ppppppppppppp");
      let data = { ...this.filter };
      const _this = this;
      data.keyNodesType = 0;
      nodeStateRecordDetail({ id: this.rowData.eventId }).then(res => {
        if (res.code == 0) {
          // console.log(res.data, "fata");
          if (res.data.length > 0) {
            res.data.forEach(el => {
              if (el.recordType == 1) {
                _this.isApply = true;
                el.state = 4;
                _this.applyInfo = el;
              }
              if (el.recordType == 4) {
                _this.isAudit = true;
                _this.isShow = true;
                el.state = 6;
                _this.AuditInfo = el;
              }
              if (
                el.recordType == 2 ||
                el.recordType == 3 ||
                el.recordType == 5
              ) {
                _this.ruleForm = el;
              }
            });
          }
          // _this.ruleForm = res.data;
        }
      });
    },
    handleFileUpload({ name, url, realUrl }) {
      console.log(name);
      this.ruleForm.fileOneList = [
        {
          fileName: "《风险管控关键节点施工前条件核查表》",
          fileUrl: url,
          fileRealUrl: realUrl
        }
      ];
      this.fileOneList = [
        {
          name: "《风险管控关键节点施工前条件核查表》",
          url: url,
          fileRealUrl: realUrl
        }
      ];
    },
    handleFileTwoUpload({ name, url, realUrl }) {
      console.log(name);
      this.ruleForm.fileTowList = [
        {
          fileName: "《风险管控关键节点实施前条件验收记录表》",
          fileUrl: url,
          fileRealUrl: realUrl
        }
      ];
      this.fileTowList = [
        {
          name: "《风险管控关键节点实施前条件验收记录表》",
          url: url,
          fileRealUrl: realUrl
        }
      ];
    },
    handleFileDel({ name, url }) {
      console.log(name);
      let index = this.fileList.findIndex(v => v.url === url);
      this.fileList.splice(index, 1);
      this.ruleForm.fileList.splice(index, 1);
    },
    async getLineTree() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.lineSiteData = JSON.parse(JSON.stringify(lineSiteData));
        this.lineSiteData.forEach(item => {
          this.flatArray(item);
        });
      }
    },
    flatArray(data) {
      this.faltArr.push(data);
      if (data.children && data.children.length > 0) {
        data.children.forEach(item => {
          this.flatArray(item);
        });
      }
    },
    handleLineSiteChange(val) {
      // console.log(val, "val");
      if (val && val.length > 0) {
        this.ruleForm.lineUuid = val[0];
        this.ruleForm.branchUuid = val[1];
        this.ruleForm.siteUuid = val[val.length - 1];
        this.getSiteConsUnit(this.ruleForm.siteUuid);
        let site = this.faltArr.find(
          item => item.uuid == this.ruleForm.siteUuid
        );
        let line = this.faltArr.find(
          item => item.uuid == this.ruleForm.lineUuid
        );
        let branch = this.faltArr.find(
          item => item.uuid == this.ruleForm.branchUuid
        );
        this.ruleForm.siteName = site.name;
        this.ruleForm.lineName = line.name;
        this.ruleForm.branchName = branch.name;
      }
    },
    getDetails() {
      const _this = this;
      this.filter.nodeUuid = this.rowData.nodeUuid;
      this.filter.eventId = this.rowData.eventId;
      console.log(this.filter, "filter");
      confirmRecord(this.filter).then(res => {
        console.log(res, "dddddddddddd");
        if (res.code == 0) {
          res.data.forEach(el => {
            if (el.recordType == 1) {
              el.state = 4;
              _this.applyInfo = el;
            }
            if (el.recordType == 4) {
              _this.isAudit = true;
              _this.isShow = true;
              el.state = 6;
              _this.AuditInfo = el;
            }
            // if (el.recordType == 5) {
            //   console.log(el.recordType, "recordType");
            //   _this.ruleForm = el;
            //   console.log(_this.ruleForm, "this.ruleForm");
            // }
          });
          console.log(_this.AuditInfo, "audit");
        }
      });
    },
    handleChangeWarnType(val) {
      console.log(val, "val");
      const _this = this;
      this.warnType.forEach(el => {
        if (el.code == val) {
          _this.ruleForm.warnTypeName = el.label;
        }
      });
    },
    handleChangeWarnLevel(val) {
      console.log(val, "val");
      const _this = this;
      this.warnLevel.forEach(el => {
        if (el.code == val) {
          _this.ruleForm.warnGradeName = el.label;
        }
      });
    },
    handleFileClick(record) {
      const link = document.createElement("a");
      link.href = record.fileUrl;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },

    cancel() {
      this.rolesData = [];
      this.defaultCheckedKeys = [];
      this.reset();
      this.$emit("CloseData");
    },
    reset() {
      const th = this;
      Object.keys(this.ruleForm).forEach(function(key) {
        th.ruleForm[key] = "";
      });
      // this.$refs.ruleForm.resetFields();
    },
    submit(type) {
      this.ruleForm.recordType = type;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.ruleForm.fileList.push(...this.ruleForm.fileOneList);
          this.ruleForm.fileList.push(...this.ruleForm.fileTowList);
          nodeCheckAdd(this.ruleForm).then(res => {
            if (res.code == 0) {
              this.$message.success("操作成功");
              this.$emit("submitClose");
              this.reset();
            } else {
              this.$message.error("操作失败");
              this.$emit("submitClose");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background-color: none !important;
}
.imgGroupBox {
  display: flex;
  flex-wrap: wrap;
}
.huixianImg {
  width: 148px;
  height: 148px;
  margin-left: 15px;
}
.huixianImg:first-child {
  margin-left: 0;
}
.fileGroupBox {
  span {
    color: #3fa5e9;
    cursor: pointer;
    margin-left: 10px;
  }
  span:first-child {
    color: #606266;
  }
}
</style>
