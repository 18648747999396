<template>
  <div class="statistical">
    <el-dialog
      type="text"
      :title="title"
      :visible.sync="checkisible"
      width="50%"
      :before-close="handleClose"
      center
    >
      <Uploader @uploader="uploader"></Uploader>
      <!-- <el-form :model="statistics" ref="statistics" class="demo-form-inline">
        <el-row>
          <el-col :span="8">
            <el-form-item label="应急响应类型:">
              <el-input
                v-model="statistics.regiontype"
                :disabled="true"
                style="width:180px"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="应急级别:">
              <el-input
                v-model="statistics.level"
                :disabled="true"
                style="width:180px"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检查时间:">
              <el-input
                v-model="statistics.checkTime"
                :disabled="true"
                style="width:180px"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="检查人员:">
              <el-select
                v-model="statistics.checkPerson"
                multiple
                placeholder="请选择"
                style="width:90%"
              >
                <el-option
                  v-for="item in checkPersonData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option> </el-select></el-form-item
          ></el-col>
        </el-row> -->
      <!-- </el-form>
      <el-form :model="formTable"> -->
      <!-- <el-table
          v-loading="loading"
          :data="statistics.statisData"
          border
          style="width: 100%"
          :header-cell-style="{ background: '#E8E8E8' }"
        >
          <el-table-column type="index" label="序号" width="80" align="center">
          </el-table-column>
          <el-table-column
            prop="checkEntry"
            show-overflow-tooltip
            label="检查项"
            align="center"
            width="300"
          >
          </el-table-column>
          <el-table-column
            prop="hiddenTroubleNum"
            label="存在隐患数量"
            align="center"
          >
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  v-model="scope.row.hiddenTroubleNum"
                  :autofocus="true"
                  placeholder="存在隐患数量"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="details" label="详情" align="center">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  v-model="scope.row.details"
                  :autofocus="true"
                  placeholder="详情"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="checkImg" label="图片" align="center">
            <template slot-scope="scope">
              <el-form-item>
                <cover-uploader
                  v-model="scope.row.checkImg"
                  @uploadChange="urlChange"
                ></cover-uploader>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div class="texter" style="margin-top: 40px">
        发起隐患上报
        <span>
          <el-button type="primary">填报</el-button>
        </span>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="save">保存</el-button> -->
        <el-button type="primary" @click="submit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { getrecord, getData, checkInfo } from "@/api/response";
import { checkInfo } from "@/api/response";
// import CoverUploader from "@/components/Uploader/CoverUploader.vue";
// import { getUser } from "@/api/ucenter";
export default {
  components: {
    Uploader: () => import("@/components/Uploader/Uploader.vue")
  },
  props: {
    checkis: {
      type: Boolean,
      default: () => false
    }
    // staData: {
    //   type: Object,
    //   default: () => {}
    // }
  },
  watch: {
    checkis: {
      handler() {
        this.checkisible = this.checkis;
      },
      deep: true
    }
  },
  data() {
    return {
      title: "三防应急检查表",
      loading: true,
      checkisible: false,
      // statistics: {
      //   regiontype: "",
      //   level: "",
      //   checkTime: "",
      //   checkPerson: [],
      //   statisData: []
      // },
      // options: [],
      // checkPersonData: [],
      // type: null,
      // levels: null,
      url: ""
    };
  },
  // components: {
  //   CoverUploader
  // },
  created() {
    // this.record();
  },
  mounted() {
    this.statistics = this.staData;
    // this.statistics.statisData = JSON.parse(this.staData.info);
    console.log(11111, this.statistics);
  },
  methods: {
    uploader(val) {
      console.log(val);
      this.url = val;
    },
    //应急响应记录详情和历史填报
    // record() {
    //   getrecord({ id: 35 }).then(res => {
    //     if (res.code == 0) {
    //       this.statistics.regiontype = res.data.type.label;
    //       this.statistics.level = res.data.currentLevel.label;
    //       if (this.statistics.regiontype == "防台风") {
    //         this.type = 1;
    //       } else if (this.statistics.regiontype == "防暴雨") {
    //         this.type = 2;
    //       } else if (this.statistics.regiontype == "防汛") {
    //         this.type = 3;
    //       }
    //       if (this.statistics.level == "Ⅰ") {
    //         this.levels = 1;
    //       } else if (this.statistics.level == "Ⅱ") {
    //         this.levels = 2;
    //       } else if (this.statistics.level == "Ⅲ") {
    //         this.levels = 3;
    //       } else if (this.statistics.level == "Ⅳ") {
    //         this.levels = 4;
    //       }
    //       getData({
    //         level: this.levels,
    //         type: this.type
    //       }).then(res => {
    //         if (res.code == 0) {
    //           this.loading = false;
    //           res.data.forEach(item => {
    //             this.statistics.statisData.push({
    //               checkEntry: item
    //             });
    //           });
    //         }
    //       });
    //     }
    //   });
    //   getUser({
    //     deptId: "5b1f393a8470a716b812e2cf",
    //     pageNo: 1,
    //     pageSize: 10
    //   }).then(res => {
    //     if (res.code == 0) {
    //       this.checkPersonData = [];
    //       res.data.records.forEach(item => {
    //         this.checkPersonData.push({
    //           value: item.id,
    //           label: item.realName
    //         });
    //       });
    //     } else {
    //       this.$message.warning(res.message);
    //     }
    //   });
    // },
    // urlChange(urlList) {
    //   console.log(urlList);
    // },

    //提交
    submit() {
      console.log(this.url);
      if (this.url.length == 0) {
        this.$message.error("请上传文件");
      } else {
        checkInfo({
          departmentId: "1",
          fillRecordId: 1,
          info: this.url,
          responseRecordId: 35,
          type: 1
        }).then(res => {
          if (res.code == 0) {
            this.$message({
              message: "提交成功",
              type: "success"
            });
            this.$emit("tabelsret");
            // this.$refs.statistics.resetFields();
            // this.statisData = [];
            // this.remark = "";
          } else {
            this.$message.error("提交失败");
          }
        });
      }
      // let statistics = JSON.stringify(this.statistics);
    },
    //保存
    // save() {
    //   let statistics = JSON.stringify(this.statistics);

    //   checkInfo({
    //     departmentId: "1",
    //     fillRecordId: 1,
    //     info: statistics,
    //     responseRecordId: 35,
    //     type: 0
    //   }).then(res => {
    //     if (res.code == 0) {
    //       this.$message({
    //         message: "保存成功",
    //         type: "success"
    //       });
    //       this.$emit("tabelsret");
    //       this.$refs.statistics.resetFields();
    //     } else {
    //       this.$message.error("保存失败");
    //     }
    //   });
    // },
    //关闭
    handleClose() {
      this.$emit("tabelsret");
      this.$refs.statistics.resetFields();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-upload {
    width: 50px;
    height: 50px;
  }
  .el-upload--picture-card {
    line-height: 50px;
  }
  .el-icon-plus {
    font-size: 14px;
  }
}
</style>
