import {
  httpInit
} from "./http"

const http = httpInit("/gzdt-resource")

//查询全部资源列表
export const resourceAll = params => http.get(`/api/src/all`, params)

//批量更新角色授权
export const updateBatch = params =>
  http.put(`/rpermission/update_batch`, params.data)

//查询角色授权
export const rpermissionList = params =>
  http.get(`/rpermission/list/${params}`)
//查询菜单授权
export const getRoleIds = params =>
  http.get(`/rpermission/list/${params.roleId}/${params.resourceId}`)

//批量更新用户授权
export const updateBatchUser = params =>
  http.put(`/apermission/update_batch/${params.accountId}`, params.data)

//查询用户授权
export const apermission = params =>
  http.get(`/apermission/info/acc/${params}`)

//查询用户是否有cms审批权限
export const apiInfo = params =>
  http.get(`/api/info/${params.userId}/${params.categoryId}/${params.server}`)

//查询用户权限{categoryId}/{userId}/{serverName}/{code}
export const infoSellist = params =>
  http.get(
    `/manager/info/sellist/${params.categoryId}/${params.userId}/${params.serverName}/${params.code}`
  )

//添加管理员 	权限code,1=增加,2=修改,3=删除,4=查询,5=部门领导审批，6=申请置顶,7=取消置顶,8=终审人员审批，9=审核置顶,10=申请推送热点，11=审核热点
export const addManager = params => http.post(`/manager/info/list`, params)
//新增批量角色授权
export const addMore = data => http.post(`/rpermission/info/batchAddByMenuId`, data)
//新增批量角色授权
export const delMore = data => http.post(`/rpermission/info/batchRemoveByMenuId`, data)

//修改管理员/manager/info/list
export const updateManager = params => http.put(`/manager/info/list`, params)

//查询用户的所有权限/api/allinfo/
export const allinfo = params =>
  http.get(`/api/allinfo/${params.userId}/${params.server}`)

/**
 * 权限类API
 * 无 controll 验证
 */
// 查询指定账号的授权列表
export const getResourceByAccountId = accountId =>
  http.get(`/api/aper/${accountId}`)
// 获取全部资源列表
export const getAllResource = () => http.get(`/api/src/all`)

// 人员或部门回显-根据用户uuid、部门uuid查询所有工点id数据
export const getSiteDataByUuId = data => http.post(`/data_list/list`, data)

// 站点uuid权限过滤-根据用户id、部门id查询所有工点id数据
export const getDatalist = params => http.get(`/api/siteIdDatalist`, params)

// 查询多个指定角色的授权
export const queryResourceByRole = params =>
  http.get(`/api/rper/roles/${params.roleIds}`)


// 分页查询资源菜单列表
export const getResourceByPage = params =>
  http.get(
    `/resource/page/${params.name}/${params.resourcePath}/${params.parentId}/${params.type}/${params.pageNo}/${params.pageSize}`
  )
// 查询菜单列表
export const queryResourceList = params => http.get(`/resource/list/${params.name}/${params.resourcePath}/${params.parentId}/${params.type}`, { category: params.category })
// 新增资源菜单
export const addResource = data => http.post(`/resource/info`, data)

// 编辑资源菜单
export const editResource = data => http.put(`/resource/info/${data.id}`, data)

// 删除资源菜单
export const delResource = id => http.delete(`/resource/info/${id}`)

// 批量保存数据权限
export const batchSaveOrUpdate = data =>
  http.post(`/data_list/saveOrUpdate_batch`, data)

// 人员或部门回显-根据用户uuid、部门uuid查询所有工点id数据
export const getDataByUuId = data => http.post(`/data_list/list`, data)
// 部门权限----批量保存数据权限
export const saveTree = params =>
  http.post(`/data_list/saveOrUpdate_batch`, params)

export const getTreeList = params => http.post(`/data_list/list`, params)




// 合并----------------------------

//  查询资源列表,带查询条件
export const resourceAllByCondition = params =>
  http.get(
    `/resource/list/${params.name}/${params.resourcePath}/${params.parentId}/${params.type}`
  )

// 批量新增授权
export const batchAddResourceToRole = data =>
  http.post(`/rpermission/info/batch_add`, data)


// 通过工点uuid和当前操作人单位查询所有的有权限的人员uuid 
export const listAccInfoBySiteUuidAndUnit = params =>
  http.get(
    `/data_list/listAccInfoBySiteUuidAndUnit?siteUuid=${params.siteUuid}`
  )
