<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" :title="title" width="40%">
    <el-form v-loading="loading" :model="model" :rules="rules" ref="modelForm" label-width="120px" class="demo-model"
      style="width: 80%">
      <!-- <el-input placeholder="输入关键字进行过滤" v-model="filterText">
      </el-input> -->

      <el-tree ref="tree" :props="props" :data="tree" node-key="uuid" :load="loadNode" lazy show-checkbox>
      </el-tree>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { addIndividual, updateIndividual } from "@/api/eres";
// import { getUser } from "@/api/ucenter";
import { getDeptAccountListByDeptId } from "@/api/eres";
export default {
  components: {
    systemModal
  },
  data() {
    return {
      title: "新增单兵",
      model: {},
      tree: [],
      filterText: "",
      defaultProps: {},
      dict: {},
      loading: false,
      rules: {},
      props: {
        label: "name",
        children: "children",
        isLeaf: "leaf"
      },
      tempTreeCheck: {},
      accountUuidList: []
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  props: {
    orgTree: {
      type: Array,
      default: () => []
    }
  },
  mounted() { },
  methods: {
    // 给tree增加disabled树形
    setDisabeld(record) {
      for (let item of record) {
        item.disabled = true;
        if (item.children) {
          this.setDisabeld(item.children);
        }
      }
    },
    async handleAdd(record) {
      if (record) {
        this.title = "修改单兵";
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
      }
      this.tree = JSON.parse(JSON.stringify(this.orgTree));
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    async handleSubmit() {
      this.addIndividual();
    },
    // 修改单兵
    async updateIndividual() {
      this.loading = true;
      const params = {
        accountUuidList: this.$refs.tree.getCheckedKeys(true)
      };
      if (params.accountUuidList.length > 0) {
        let res = await updateIndividual(params);
        if (res.code == 0) {
          this.$message.success("操作成功");
          this.$refs.formModal.handleClose();
          this.$emit("formSubmit");
          this.loading = false;
        } else {
          this.$message.error(res.message);
        }
      } else {
        this.$message.error("请选择人员");
        this.loading = false;
      }
    },
    // 新增单兵
    async addIndividual() {
      this.loading = true;
      const params = {
        accountUuidList: this.$refs.tree.getCheckedKeys(true)
      };
      console.log(params);
      if (params.accountUuidList.length > 0) {
        let res = await addIndividual(params);
        if (res.code == 0) {
          this.$message.success("操作成功");
          this.$refs.formModal.handleClose();
          this.$emit("formSubmit");
          this.loading = false;
        } else {
          this.$message.error(res.message);
        }
      } else {
        this.$message.error("请选择人员");
        this.loading = false;
      }
    },
    // 加载叶子节点
    async loadNode(node, resolve) {
      console.log(node);
      if (node.level === 0) {
        return resolve(this.orgTree);
      }
      if (node.level != 0 && node.data.vendorOrDept == 0) {
        resolve(node.data.children);
      } else if (node.data.vendorOrDept == 1) {
        // 查询部门下的人员
        const params = {
          deptUuId: node.data.uuid,
          data: {}
        };
        let res = await getDeptAccountListByDeptId(params);
        if (res) {
          const data = [];
          for (let item of res) {
            data.push({
              name: item.realName,
              id: item.id,
              uuid: item.accountUuid,
              contactPhone: item.contactPhone,
              leaf: true,
            });
          }
          resolve(data);
        } else {
          resolve([]);
        }
      } else {
        return false;
      }
    },
    // 树节点过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then(res => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then(res => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          })
        }
      });
    },
    // 清空el表单
    handleModalClose() {
      this.$refs.formModal.resetFields();
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tree-node {
  .is-leaf+.el-checkbox .el-checkbox__inner {
    display: inline-block;
  }

  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}
</style>
