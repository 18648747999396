<template>
  <!-- 三防工作简报的  建管部 汇总数据提交 代办  -->
  <div>
    <!-- <div style="margin-bottom: 20px">
      <el-radio-group
        size="mini"
        v-model="degree"
        v-for="(item, index) in manyTimes"
        :key="index"
        @change="degreeChange"
      >
        <el-radio-button :label="`第` + item + '次'"></el-radio-button>
      </el-radio-group>
    </div> -->
    <sapn style="color:red;margin-bottom: 10px;display: inline-block;"
      >请尽快前往电脑端事件列表进行汇总提交操作</sapn
    >
    <el-descriptions title="" style="width: 70%">
      <el-descriptions-item label="汇总单位">{{
        user.departmentName
      }}</el-descriptions-item>

      <el-descriptions-item label="汇总次数"
        >第{{ cuns }}次</el-descriptions-item
      >
    </el-descriptions>

    <el-table
      ref="tableData"
      :data="tableData"
      id="tableData"
      border
      v-loading="tableLoading"
      :row-style="{ height: '30px' }"
      style="width: 100%"
      height="430"
    >
      <el-table-column
        align="center"
        type="index"
        label="序号"
        width="100"
      ></el-table-column>
      <el-table-column label="总包部" prop="name" align="center">
      </el-table-column>
      <el-table-column label="填报次数" align="center" prop="times">
      </el-table-column>
      <el-table-column prop="performance" label="工点完成情况" align="center">
      </el-table-column>
      <el-table-column prop="deadlineDate" label="截止时间" align="center">
      </el-table-column>
      <el-table-column prop="fillDate" label="填报时间" align="center">
      </el-table-column>
      <el-table-column prop="fillUserName" label="填报人员" align="center">
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        :formatter="formatState"
      >
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <!-- <span v-if="scope.row.status == 4"> -->
          <el-button @click="handelDtile(scope.row)" type="text" size="small">
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 查看详情 -->
    <briefingJGView ref="briefingJGView"></briefingJGView>
  </div>
</template>

<script>
import {
  // getSelection,
  getRes,
  listSZongBaoDetailSummaryInfoDB,
  getMaxTimes,
  approvalBriefing,
} from "@/api/response";
import checkPage from "./checkPage.vue";
import briefingJGView from "./briefingJGView.vue";

export default {
  props: {
    supervisionUuid: {
      type: String,
    },
    activees: {
      type: Object,
      default: {},
    },
    briData: {},
  },
  components: {
    checkPage,
    briefingJGView,
  },
  data() {
    return {
      times: 1,
      manyTimes: 1,
      degree: "第1次",
      tableData: [],
      tableTotalData: [],
      tableLoading: false,
      checkDialog: false,
      formData: {
        emergency: "",
      },
      formInline: {
        responseType: "",
        responseLevel: "",
      },
      // leveloptions: [],
      checkId: "", //当前行的id
      checkName: "", //事件id
      // buildDepartmentResponseId: "",
      supervisionName: "",
      cuns: 1,
      // exportDialog: false,
      checkboxTimes: [],
      exloading: false,
      tableDatas: [],
      departmentName: {},
      rejectVisibe: false,
      rejectText: "",
      rowReject: {},
      buttonState: false,
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {
    // this.getData();
    // this.getTimes();
    this.getTableData();
  },
  methods: {
    // 获取表格数据
    getTableData() {
      this.tableLoading = true;
      let params = {
        currentId: this.briData.id,
      };
      listSZongBaoDetailSummaryInfoDB(params)
        .then((res) => {
          this.tableLoading = false;
          if (res.code == 0) {
            this.cuns = res.data.times;
            if (
              res.data.summaryInfoList &&
              res.data.summaryInfoList.length > 0
            ) {
              this.tableData = res.data.summaryInfoList;
            }
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    formatState: function(row, column, cellValue) {
      console.log(row, column, cellValue);
      switch (cellValue) {
        case 0:
          return "待填报";
        case 1:
          return "总包已驳回";
        case 2:
          return "建管部已驳回";
        case 3:
          return "待总包审批";
        case 4:
          return "待建管部确认";
        case 5:
          return "已完成";
      }
    },
    // 查看
    handelDtile(val) {
      this.$refs.briefingJGView.handleFilling(val);
    },
    checkClose() {
      this.rejectVisibe = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-table::before {
  height: 0;
}
::v-deep .el-table {
  height: 400px;
  // overflow-y: auto;
}
.content {
  display: flex;
  justify-content: space-between;
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: #fff !important;
  border-radius: 7px;
}
/*定义滑块颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}

//去掉固定列下方的横线
::v-deep .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: transparent;
}
//去掉固定列阴影
::v-deep .el-table__fixed-right {
  // box-shadow: none;
  height: 100% !important;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}
</style>
