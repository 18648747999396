<template>
  <div class="losses">
    <div class="table-wrapper">
      <div class="table-wrapper">
        <el-table
          :data="situationInspectDto"
          style="width: 100%; margin-bottom: 20px"
          border
        >
          <el-table-column
            align="center"
            prop="inspectNum"
            label="出动检查人员（人次）"
          >
          
          </el-table-column>
          <el-table-column
            align="center"
            prop="inspectPlaceNum"
            label="检查场所（处）"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="hiddenNum"
            label="发现三防问题隐患（处）"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="hiddenSuccessNum"
            label="已整改三防问题隐患（处）"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="ranks"
            label="出动(集结)抢险队伍（支）"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="ranksNum"
            label="出动(集结)抢险队伍人员（人次）"
          ></el-table-column>
        </el-table>
      </div>
      <el-table
        :data="actualTableData"
        style="width: 100%; margin-bottom: 20px"
        border
      >
        <el-table-column
          align="center"
          prop="lossSituation"
          label="损失情况"
        ></el-table-column>
        <el-table-column align="center" prop="num" label="数量">
        </el-table-column>
        <el-table-column
          align="center"
          prop="rescueSituation"
          label="抢险应对措施"
        >
        </el-table-column>
        <el-table-column align="center" prop="personCharge" label="结果">
        </el-table-column>
      </el-table>
      <div class="tab" style="margin-top: 20px; display: flex">
        <span style="width: 100px; line-height: 50px"> 审批意见 :</span
        ><el-input
          :disabled="true"
          type="textarea"
          v-model="actualTableForm.suggest"
        ></el-input>
      </div>
      <div class="teruq" style="margin-top: 20px; display: flex">
        <span style="width: 100px; line-height: 50px">驳回原因:</span>

        <el-input
          :disabled="true"
          type="textarea"
          v-model="actualTableForm.rejectReason"
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listdate: {},
  },
  watch: {
    listdate: {
      handler(val) {
        console.log(val);
        this.actualTableData = val.lossList;
        this.actualTableForm.suggest = val.suggest;
        this.actualTableForm.rejectReason = val.rejectReason;
        this.situationInspectDto.push(val.situationInspectDto)
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      actualTableData: [],
      actualTableForm: {},
      situationInspectDto:[]
    };
  },
  created() {},
  methods: {},
};
</script>

<style>
</style>