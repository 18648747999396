<template>
  <div class="page-container bg-white p-4 h-full">
    <el-aside style="background-color: rgb(238, 241, 246)">
      <el-input
        placeholder="输入关键字进行搜索"
        v-model="filterText"
        style="width: 250px; margin-left: 20px"
      >
      </el-input>
      <el-tree
        style="margin-top: 10px"
        v-loading="loading"
        :data="dataList"
        :props="defaultProps"
        @node-click="handleNodeClick"
        :filter-node-method="filterNode"
        ref="tree"
      ></el-tree>
    </el-aside>

    <div class="disple">
      <div class="flex justify-between headerContent">
        <div class="search-wrapper">
          <span style="line-height: 42px"> 名称:</span>
          <el-input v-model="input" placeholder="线路/标段/工点"></el-input>
          <span>
            <el-button type="primary" @click="querySite">查询</el-button>
          </span>
          <span>
            <el-button type="primary" @click="lead" v-has="'project:load'"
              >导入</el-button
            >
          </span>
          <span>
            <el-button
              type="primary"
              @click="leaddownload"
              v-has="'project:downloadLoad'"
              >下载导入模板</el-button
            >
          </span>
        </div>
        <div class="addert">
          <el-button type="primary" @click="addment" v-has="'project:add'"
            >新增</el-button
          >
        </div>
      </div>
      <!-- 右侧表格数据 -->
      <el-container>
        <el-main>
          <el-table :data="tableData" v-loading="loading" style="width: 100%">
            <el-table-column
              align="center"
              label="序号"
              type="index"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="名称"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="parentName"
              label="上级"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column label="类型" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                {{ companyStatus(scope.row) }}
              </template>
            </el-table-column>

            <!-- <el-table-column prop="fance" label="fance" align="center">
              <template slot-scope="scope">
                <div v-for="(item, index) in scope.row.fance" :key="index">
                  {{ item.lat }}
                </div>
              </template>
            </el-table-column> -->

            <el-table-column
              prop="created"
              label="创建时间"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column align="center" label="操作" width="200">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="editor(scope.row)"
                  v-has="'project:editor'"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="deletes(scope.row)"
                  v-has="'project:delete'"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="flex justify-center pt-4">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="page.count"
              :page-count="page.total"
              :page-size="page.pageSize"
              :current-page="page.pageNo"
              @size-change="handlerescueChange"
              @current-change="hanrescueChange"
            >
            </el-pagination>
          </div>
        </el-main>
      </el-container>
    </div>
    <!-- 新增修改弹框 -->
    <el-dialog
      type="text"
      :title="title"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      center
    >
      <el-form
        :rules="rules"
        :model="statistics"
        ref="statistics"
        class="demo-form-inline"
        :label-position="labelPosition"
        label-width="128px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="名称:" prop="name">
              <el-input v-model="statistics.name" style="width: 200px">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="上级:" prop="puuid">
              <propver
                :data="dataList"
                :defaultProps="props"
                :chooseName="chooses"
                :pid="statistics.puuid"
                :disabled="linetype == true"
                @deptTreeCurr="deptTreeCurrentChangeHandle"
              ></propver>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="类型:" prop="lineSiteType">
              <el-select
                v-model="statistics.lineSiteType"
                placeholder="请选择"
                @change="typets"
                :disabled="linetype == true"
              >
                <el-option
                  v-for="item in types"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="项目负责人:" prop="projectCharge">
              <el-input v-model="statistics.projectCharge" style="width: 200px">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话:" prop="projectChargeMobile">
              <el-input
                v-model="statistics.projectChargeMobile"
                style="width: 200px"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="工程类型:"
              prop="projectType"
              :rules="
                statistics.lineSiteType == 319 ||
                statistics.lineSiteType == 320 ||
                statistics.lineSiteType == 346
                  ? rules.projectType
                  : [{ required: false }]
              "
            >
              <el-select v-model="statistics.projectType" placeholder="请选择">
                <el-option
                  v-for="(item, index) in engineering"
                  :key="item.id + String(index)"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="项目状态:"
              prop="projectStatue"
              :rules="
                statistics.lineSiteType == 319 ||
                statistics.lineSiteType == 320 ||
                statistics.lineSiteType == 346
                  ? rules.projectStatue
                  : [{ required: false }]
              "
            >
              <el-select
                v-model="statistics.projectStatue"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in project"
                  :key="item.id + String(index)"
                  :label="item.label"
                  :value="item.id + ''"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="工法:"
              prop="constructionMethod"
              :rules="
                statistics.lineSiteType == 319 ||
                statistics.lineSiteType == 320 ||
                statistics.lineSiteType == 346
                  ? rules.constructionMethod
                  : [{ required: false }]
              "
            >
              <el-select
                v-model="statistics.constructionMethod"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in method"
                  :key="item.id + String(index)"
                  :label="item.label"
                  :value="item.id + ''"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="施工类型:"
              prop="workLevel"
              :rules="
                statistics.lineSiteType == 319 ||
                statistics.lineSiteType == 320 ||
                statistics.lineSiteType == 346
                  ? rules.workLevel
                  : [{ required: false }]
              "
            >
              <el-select v-model="statistics.workLevel" placeholder="请选择">
                <el-option
                  v-for="(item, index) in construction"
                  :key="item.id + String(index)"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item
              label="施工单位:"
              prop="constructionUnitId"
              :rules="
                statistics.lineSiteType == 319 ||
                statistics.lineSiteType == 320 ||
                statistics.lineSiteType == 346
                  ? rules.state
                  : [{ required: false }]
              "
              class="drop"
            >
              <!-- <propver
                :data="buesdList"
                :defaultProps="props"
                :chooseName="constructName"
                :pid="statistics.constructionUnitId"
                :disabled="title == '详情'"
                @deptTreeCurr="deptTreeCurry"
              ></propver> -->
              <el-select
                ref="treeSelect"
                v-model="statistics.constructionUnitId"
                placeholder="请选择"
                clearable
                :disabled="title == '详情'"
                @change="deptTreeCurry"
              >
                <el-option
                  v-for="item in buesdList"
                  :key="item.uuid"
                  :value="item.uuid"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="施工状态:"
              prop="state"
              :rules="
                statistics.lineSiteType == 319 ||
                statistics.lineSiteType == 320 ||
                statistics.lineSiteType == 346
                  ? rules.state
                  : [{ required: false }]
              "
            >
              <el-select v-model="statistics.state" placeholder="请选择">
                <el-option
                  v-for="(item, index) in constructio"
                  :key="item.id + String(index)"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="计划开工时间:" prop="planStartTime">
              <el-date-picker
                style="width: 200px"
                type="datetime"
                v-model="statistics.planStartTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="计划竣工时间:" prop="planEndTime">
              <el-date-picker
                style="width: 200px"
                type="datetime"
                v-model="statistics.planEndTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="建设单位:"
              prop="constructionManagementUnitId"
              :rules="
                statistics.lineSiteType == 319 ||
                statistics.lineSiteType == 320 ||
                statistics.lineSiteType == 346
                  ? rules.buildName
                  : [{ required: false }]
              "
            >
              <propver
                :data="buildList"
                :defaultProps="props"
                :chooseName="buildName"
                :pid="statistics.constructionManagementUnitId"
                @deptTreeCurr="deptTreeCurrmang"
              ></propver>
              <!-- <el-select
                ref="treeSelect"
                v-model="statistics.buildName"
                placeholder="请选择"
                clearable
                :disabled="title == '详情'"
              >
                <el-option value="" v-show="false"></el-option>
                <el-tree
                  :data="buildList"
                  node-key="uuid"
                  :props="props"
                  highlight-current
                  ref="tree"
                  @node-click="deptTreeCurrmang"
                >
                </el-tree>
              </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="总承包单位:"
              prop="optionUnitId"
              :rules="
                statistics.lineSiteType == 319 ||
                statistics.lineSiteType == 320 ||
                statistics.lineSiteType == 346
                  ? rules.deptName
                  : [{ required: false }]
              "
            >
              <el-select
                ref="treeSelect"
                v-model="statistics.optionUnitId"
                placeholder="请选择"
                clearable
                :disabled="title == '详情'"
                @change="deptTreeCurrcontr"
              >
                <el-option
                  v-for="item in contractingList"
                  :key="item.uuid"
                  :value="item.uuid"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="勘察单位:" prop="monitoringUnitId">
              <el-select
                ref="treeSelect"
                v-model="toringName"
                placeholder="请选择"
                clearable
                :disabled="title == '详情'"
                @change="deptTreeCurrsurvey"
              >
                <el-option
                  v-for="item in surveyList"
                  :key="item.uuid"
                  :value="item.uuid"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设计单位:" prop="designUnitId">
              <el-select
                ref="treeSelect"
                v-model="designName"
                placeholder="请选择"
                clearable
                :disabled="title == '详情'"
                @change="deptTreeCurrdesign"
              >
                <el-option
                  v-for="item in designList"
                  :key="item.uuid"
                  :value="item.uuid"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="监理单位:"
              prop="supervisorUnitId"
              :rules="
                statistics.lineSiteType == 319 ||
                statistics.lineSiteType == 320 ||
                statistics.lineSiteType == 346
                  ? rules.supervName
                  : [{ required: false }]
              "
            >
              <el-select
                ref="treeSelect"
                v-model="statistics.supervisorUnitId"
                placeholder="请选择"
                clearable
                :disabled="title == '详情'"
                @change="deptTreeCurrmonitor"
              >
                <el-option
                  v-for="item in monitorList"
                  :key="item.uuid"
                  :value="item.uuid"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="第三方咨询单位:" prop="thirdPartUnitId">
              <el-select
                ref="treeSelect"
                v-model="thirdName"
                placeholder="请选择"
                clearable
                :disabled="title == '详情'"
                @change="deptTreeCurrthird"
              >
                <el-option
                  v-for="item in thirdList"
                  :key="item.uuid"
                  :value="item.uuid"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="排序:" prop="sort">
              <el-input v-model="statistics.sort" style="width: 200px">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col
            :span="8"
            v-if="
              statistics.lineSiteType == 319 ||
                statistics.lineSiteType == 320 ||
                statistics.lineSiteType == 346
            "
          >
            <el-form-item label="颜色:" prop="color">
              <el-input v-model="statistics.color" style="width: 200px">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="画线"
              style="width: 100%"
              class="ascend"
              v-show="deaws"
            >
              <el-button @click="drawLine()" type="primary">点击画线</el-button>
            </el-form-item>

            <el-form-item
              label="画点"
              style="width: 100%"
              class="ascend"
              v-show="setting"
            >
              <el-button @click="settingMapPointer()" type="primary"
                >点击画点</el-button
              >
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="deaws">
            <el-form-item label="坐标" style="width: 100%">
              <el-input
                style="width: 200px"
                v-model="statistics.fance"
                auto-complete="off"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="经度" style="width: 100%" v-if="setting">
              <el-input
                style="width: 200px"
                v-model="statistics.lat"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="纬度" style="width: 100%" v-if="setting">
              <el-input
                style="width: 200px"
                v-model="statistics.lng"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="打卡点" style="width: 100%" class="ascend">
              <el-button @click="settingMapPointerClock()" type="primary"
                >选择打卡点
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="经度" style="width: 100%">
              <el-input
                v-model="statistics.attendanceCoordinateLat"
                :disabled="true"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="纬度" style="width: 100%">
              <el-input
                v-model="statistics.attendanceCoordinateLng"
                :disabled="true"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="工程概况:">
              <el-input
                type="textarea"
                :rows="6"
                placeholder="请输入内容"
                v-model="statistics.intro"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer" v-show="addter">
        <el-button @click="addcancel('statistics')">取 消</el-button>
        <el-button type="primary" @click="determine('statistics')"
          >确 定</el-button
        >
      </span>
      <span slot="footer" class="dialog-footer" v-show="prrert">
        <el-button @click="difycancel('statistics')">取 消</el-button>
        <el-button type="primary" @click="deter('statistics')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 画线弹框 -->
    <div class="mapPosition">
      <el-dialog
        title=""
        fullscreen
        v-if="dialogdrawLineVisible"
        :visible.sync="dialogdrawLineVisible"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
      >
        <el-button style="right: 145px" @click="saveMapEmpty">清空</el-button>
        <el-button @click="saveMapLine">保存</el-button>
        <baidu-map
          :center="pageeds.center"
          :scroll-wheel-zoom="true"
          :zoom="pageeds.zoom"
          class="map"
          @ready="handler"
          :mapClick="false"
        >
          <bm-marker
            v-for="(data, index) in workPoints"
            :key="data.uuid + String(index)"
            :position="{ lng: data.coord[0], lat: data.coord[1] }"
            :dragging="false"
            :icon="{
              url: data.url,
              size: { width: 15, height: 15 },
            }"
          >
          </bm-marker>
          <bm-polyline
            v-for="(data, index) of polylineList"
            :key="data.id + String(index)"
            :path="data.polylinePath"
            :stroke-color="data.color"
            :stroke-opacity="1"
            :stroke-weight="5"
            :editing="false"
          >
          </bm-polyline>
        </baidu-map>
      </el-dialog>
    </div>
    <!-- 画点弹框 -->
    <div class="mapPosition">
      <el-dialog
        title=""
        fullscreen
        v-if="dialogMapVisible"
        :visible.sync="dialogMapVisible"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
      >
        <el-button @click="saveMapPointer">保存</el-button>
        <baidu-map
          :center="pageds.center"
          :scroll-wheel-zoom="true"
          :zoom="pageds.zoom"
          class="map"
          @ready="handlers"
          :mapClick="false"
        >
          <bm-marker
            v-for="(data, index) in workPoints"
            :key="data.uuid + String(index)"
            :position="{ lng: data.coord[0], lat: data.coord[1] }"
            :dragging="false"
            :icon="{
              url: data.url,
              size: { width: 15, height: 15 },
            }"
          >
          </bm-marker>
          <bm-polyline
            v-for="(data, index) of polylineList"
            :key="data.id + String(index)"
            :path="data.polylinePath"
            :stroke-color="data.color"
            :stroke-opacity="1"
            :stroke-weight="5"
            :editing="false"
          >
          </bm-polyline>
        </baidu-map>
      </el-dialog>
    </div>

    <importForm ref="importForms"></importForm>
  </div>
</template>

<script>
//高德坐标转百度（传入经度、纬度）
function bd_encrypt(gg_lng, gg_lat) {
  var X_PI = (Math.PI * 3000.0) / 180.0;
  var x = gg_lng,
    y = gg_lat;
  var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
  var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
  var bd_lng = z * Math.cos(theta) + 0.0065;
  var bd_lat = z * Math.sin(theta) + 0.006;
  return {
    lat: bd_lat,
    lng: bd_lng,
  };
}
import propver from "@/components/propver/index.vue";
import {
  tripleTree,
  worksiteTree,
  addProje,
  triplePage,
  aertProje,
  deleProje,
} from "@/api/map.js";
import { queryDictByLabel, queryDictNodesById, getDictData } from "@/api/dict";
import { getUserDeptList } from "@/api/org";
import { lineDownload, importLineSite } from "@/api/map";
import importForm from "./importForm.vue";

export default {
  components: { propver, importForm },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  data() {
    return {
      dataList: [],
      input: "",
      defaultProps: {
        children: "children",
        label: "name",
      },
      eserops: {
        children: "children",
        label: "name",
      },
      childArr: [],
      categoryData: [],
      tableData: [],
      dialogVisible: false,
      title: "",
      statistics: {
        constructName: "",
        buildName: "",
        deptName: "",
        supervName: "",
        planStartTime: null,
        planEndTime: null,
        puuid: "",
        constructionUnitId: "",
        color: "",
        attendanceCoordinate: {
          lat: "",
          lng: "",
        },
      },
      checkPersonData: [],
      labelPosition: "right",
      pages: {
        pageNum: 1,
        pageSize: 10,
        puuid: "",
        name: "",
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        total: 0,
      },
      pageeds: {
        start: "",
        end: "",
        center: { lng: 0, lat: 0 },
        zoom: 12,
      },
      pageds: {
        start: "",
        end: "",
        center: { lng: 0, lat: 0 },
        zoom: 12,
      },
      tabedata: {},
      types: [], //类型
      lineSiteTypes: [], //类型
      engineering: [], //工程类型
      construction: [], //施工階段
      project: [], //项目状态
      method: [], //工法，
      options: [], //上级
      constructio: [], //施工状态
      addter: false,
      prrert: false,
      loading: false,
      rules: {
        puuid: [{ required: true, message: "请选择上级", trigger: "change" }],
        lineSiteType: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
        constructName: [
          { required: true, message: "请选择施工单位", trigger: "change" },
        ],
        supervName: [
          { required: true, message: "请选择监理单位", trigger: "change" },
        ],
        deptName: [
          { required: true, message: "请选择总承包单位", trigger: "change" },
        ],
        buildName: [
          { required: true, message: "请选择建设单位", trigger: "change" },
        ],
        state: [
          { required: true, message: "请选择施工状态", trigger: "change" },
        ],
        projectType: [
          { required: true, message: "请选择工程类型", trigger: "change" },
        ],
        workLevel: [
          { required: true, message: "请选择施工类型", trigger: "change" },
        ],
        projectStatue: [
          { required: true, message: "请选择项目状态", trigger: "change" },
        ],
        constructionMethod: [
          { required: true, message: "请选择工法", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入名称", trigger: "change" }],
      },
      filterText: "",
      buildList: [],
      buesdList: [],
      contractingList: [],
      surveyList: [],
      designList: [],
      monitorList: [],
      thirdList: [],
      dialogdrawLineVisible: false,
      isRmoveLne: false,
      bbbb: {},
      // 工点坐标集合
      workPoints: [],
      dialogMapVisible: false,
      marker: [],
      polylineList: [], //线路集合
      deaws: false,
      setting: false,
      props: {
        value: "uuid",
        label: "name",
        children: "children",
      },
      constructName: "",
      chooses: "",
      buildName: "",
      deptName: "",
      toringName: "",
      designName: "",
      supervName: "",
      thirdName: "",
      distList: [],
      treeLiseer: {},
      linetype: false,
      clockIn: false,
    };
  },
  created() {
    this.trees();
    this.worksiteDetails();
    this.querySite();
    let companyList = [
      "build",
      "construct",
      "survey",
      "design",
      "monitor",
      "third",
      "contracting",
    ];
    companyList.forEach((item) => {
      this.getList(item);
    });
    queryDictByLabel({ label: "工点类型" }).then((res) => {
      queryDictNodesById({ id: res.data.id }).then((el) => {
        this.types = el.data.filter((i) => i.id == 316);
        this.lineSiteTypes = el.data;
      });
    });

    queryDictByLabel({ label: "工点类别" }).then((res) => {
      queryDictNodesById({ id: res.data.id }).then((el) => {
        this.distList = el.data;
      });
    });
  },
  methods: {
    worksiteDetails() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      let list = [];
      if (lineSiteData) {
        list = lineSiteData;
        this.mapTree(list);
      } else {
        tripleTree({}).then((res) => {
          if (res.code == 0) {
            localStorage.setItem("lineSiteTree", JSON.stringify(res.data));
            list = res.data;
            this.mapTree(list);
          }
        });
      }
    },
    // 单位类型字典
    getList(category) {
      let params = {
        category: category,
      };
      getUserDeptList(params)
        .then((res) => {
          if (res.code === 0) {
            res.data &&
              res.data.forEach((i) => {
                if (i.category == "build" && i.category == category) {
                  this.buildList = i.children.length > 0 ? i.children : [];
                } else if (
                  i.category == "contracting" &&
                  i.category == category
                ) {
                  this.contractingList =
                    i.children.length > 0 ? i.children : [];
                } else if (i.category == "survey" && i.category == category) {
                  this.surveyList = i.children.length > 0 ? i.children : [];
                } else if (i.category == "design" && i.category == category) {
                  this.designList = i.children.length > 0 ? i.children : [];
                } else if (i.category == "monitor" && i.category == category) {
                  this.monitorList = i.children.length > 0 ? i.children : [];
                } else if (i.category == "third" && i.category == category) {
                  this.thirdList = i.children.length > 0 ? i.children : [];
                } else if (
                  i.category == "construct" &&
                  i.category == category
                ) {
                  this.buesdList = i.children.length > 0 ? i.children : [];
                }
              });
          }
        })
        .catch((res) => {
          this.$message({
            message: res.message,
            type: "error",
          });
        });
    },
    filterNode(value, data, node) {
      if (!value) return true;
      // 如果传入的value和data中的label相同说明是匹配到了
      if (data.name.indexOf(value) !== -1) {
        return true;
      }
      // 否则要去判断它是不是选中节点的子节点
      return this.checkBelongToChooseNode(value, data, node);
    },
    // 判断传入的节点是不是选中节点的子节点
    checkBelongToChooseNode(value, data, node) {
      const level = node.level;
      // 如果传入的节点本身就是一级节点就不用校验了if(level ===1) {returnfalse; }
      // 先取当前节点的父节点
      let parentData = node.parent;
      // 遍历当前节点的父节点
      let index = 0;
      while (index < level - 1) {
        // 如果匹配到直接返回
        if (parentData.data.name.indexOf(value) !== -1) {
          return true;
        }
        // 否则的话再往上一层做匹配
        parentData = parentData.parent;
        index++;
      }
      // 没匹配到返回false
      return false;
    },
    companyStatus(row) {
      var jjj = "";

      if (row.lineSiteType == 0) {
        this.distList.forEach((elts) => {
          if (row.category == elts.id) {
            jjj = elts.label;
          }
        });
        return jjj;
      } else {
        this.types.forEach((item) => {
          if (row.lineSiteType == item.id) {
            jjj = item.label;
          }
        });
        return jjj;
      }
    },
    //左侧树形数据
    trees() {
      let params = {};
      tripleTree(params).then((res) => {
        if (res.code == 0) {
          this.dataList = res.data;
          this.tableData = res.data;
          this.options = res.data;
          this.loading = false;
          localStorage.setItem("lineSiteTree", JSON.stringify(res.data));
        }
      });
    },

    //左侧树点击
    handleNodeClick(data) {
      this.loading = true;

      this.tabedata = data;
      this.tableData = this.getTreeName(this.dataList, data.id);
      this.pages.puuid = data.uuid;
      this.pages.pageNum = 1;
      triplePage(this.pages).then((res) => {
        this.tableData = res.data.list;
        this.statistics.puuid = data.puuid;
        // this.options = res.data.list;
        this.page.count = res.data.total;
        this.page.total = res.data.pages;
        this.loading = false;
      });
    },
    //上级树选择
    deptTreeCurrentChangeHandle(data) {
      this.treeLiseer = data;
      this.chooses = data.name;
      this.statistics.puuid = data.uuid;
      if (data.lineSiteType == 316) {
        this.types = this.lineSiteTypes.filter((i) => i.id == 317);
      } else if (data.lineSiteType == 317) {
        this.types = this.lineSiteTypes.filter(
          (i) => i.id == 318 || i.id == 319 || i.id == 320 || i.id == 346
        );
      } else if (data.lineSiteType == 318) {
        this.types = this.lineSiteTypes.filter(
          (i) => i.id == 319 || i.id == 320 || i.id == 346
        );
      } else if (
        data.lineSiteType == 319 ||
        data.lineSiteType == 320 ||
        data.lineSiteType == 346
      ) {
        this.type = [];
      }
    },
    deptTreeCurry(val) {
      this.$forceUpdate();
      let threeObject = this.buesdList.find((item) => item.uuid == val);
      this.statistics.constructionUnitId = val;
      this.statistics.constructionUnitName = threeObject.name;
    },
    deptTreeCurrmang(data) {
      this.$forceUpdate();
      this.buildName = data.name;
      this.statistics.constructionManagementUnitId = data.uuid;
      this.statistics.constructionManagementName = data.name;
    },
    deptTreeCurrcontr(data) {
      this.$forceUpdate();
      let threeObject = this.contractingList.find((item) => item.uuid == data);
      this.statistics.optionUnitId = data;
      this.statistics.optionUnitName = threeObject.name;
    },
    deptTreeCurrsurvey(data) {
      let threeObject = this.surveyList.find((item) => item.uuid == data);
      this.statistics.monitoringUnitId = data;
      this.statistics.monitoringUnitName = threeObject.name;
    },
    deptTreeCurrdesign(data) {
      let threeObject = this.designList.find((item) => item.uuid == data);
      this.statistics.designUnitId = data;
      this.statistics.designUnitName = threeObject.name;
    },
    deptTreeCurrmonitor(data) {
      this.$forceUpdate();
      let threeObject = this.monitorList.find((item) => item.uuid == data);
      this.statistics.supervisorUnitId = data;
      this.statistics.supervisorUnitName = threeObject.name;
    },
    deptTreeCurrthird(data) {
      let threeObject = this.thirdList.find((item) => item.uuid == data);
      this.statistics.thirdPartUnitId = data;
      this.statistics.thirdPartName = threeObject.name;
    },
    //递归查找子级
    getTreeName(list, id) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === id) {
          return list[i].children;
        } else if (list[i].children && list[i].children.length > 0) {
          let res = this.getTreeName(list[i].children, id);
          if (res) {
            return res;
          }
        }
      }
    },
    //递归查找自己
    gettrName(list, id) {
      if (id && id != null && id != "" && id != undefined) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].uuid == id) {
            return list[i].name;
          } else if (list[i].children && list[i].children.length > 0) {
            let res = this.gettrName(list[i].children, id);
            if (res) {
              return res;
            }
          }
        }
      }
    },
    // 递归查找
    getsteName(list, id) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].uuid == id) {
          return list[i].puuid;
        } else if (list[i].children && list[i].children.length > 0) {
          let res = this.getsteName(list[i].children, id);
          if (res) {
            return res;
          }
        }
      }
    },

    //新增
    addment() {
      this.deaws = false;
      this.setting = false;
      this.linetype = false;
      this.dialogVisible = true;
      this.addter = true;
      this.prrert = false;
      // this.image.png
      this.treuty();
      this.statistics = {
        planStartTime: null,
        planEndTime: null,
        puuid: "",
        constructionUnitId: "",
        attendanceCoordinate: {
          lat: "",
          lng: "",
        },
      };
      this.chooses = "";
      (this.constructName = ""),
        (this.chooses = ""),
        (this.buildName = ""),
        (this.deptName = ""),
        (this.toringName = ""),
        (this.designName = ""),
        (this.supervName = ""),
        (this.thirdName = "");
    },
    //新增确定
    determine(statistics) {
      this.tableData.forEach((item) => {
        if (!item.puuid) {
          this.statistics.puuid = "0";
        }
      });
      if (
        this.statistics.lineSiteType == 319 ||
        this.statistics.lineSiteType == 320 ||
        this.statistics.lineSiteType == 346
      ) {
        this.statistics.lineSiteType = 0;
      }
      this.$refs[statistics].validate((valid) => {
        if (valid) {
          addProje(this.statistics).then((res) => {
            if (res.code == 0) {
              worksiteTree({}).then((res) => {
                if (res.code == 0) {
                  localStorage.setItem("DetailsTree", JSON.stringify(res.data));
                }
              });
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.dialogVisible = false;
              this.trees();
              this.statistics = {
                planStartTime: null,
                planEndTime: null,
                puuid: "",
                attendanceCoordinate: {
                  lat: "",
                  lng: "",
                },
              };
              this.handleNodeClick(this.tabedata);
              this.$refs[statistics].resetFields();
            }
          });
        } else {
          this.$message({
            message: "请按照规则填写",
            type: "warning",
          });
          return false;
        }
      });
    },
    treuty(val) {
      getDictData("constructionStage").then((res) => {
        this.construction = res.data;
      });
      queryDictByLabel({ label: "工点类型" }).then((res) => {
        queryDictNodesById({ id: res.data.id }).then((el) => {
          if (val == "edit") {
            this.types = el.data;
          } else {
            this.types = el.data.filter((i) => i.id == 316);
          }
          this.lineSiteTypes = el.data;
        });
      });
      queryDictByLabel({ label: "工程类型" }).then((res) => {
        queryDictNodesById({ id: res.data.id }).then((el) => {
          this.engineering = el.data;
        });
      });
      queryDictByLabel({ label: "项目状态" }).then((res) => {
        queryDictNodesById({ id: res.data.id }).then((el) => {
          this.project = el.data;
        });
      });
      queryDictByLabel({ label: "工法" }).then((res) => {
        queryDictNodesById({ id: res.data.id }).then((el) => {
          this.method = el.data;
        });
      });
      queryDictByLabel({ label: "施工状态" }).then((res) => {
        queryDictNodesById({ id: res.data.id }).then((el) => {
          this.constructio = el.data;
        });
      });
    },
    //编辑判断类型校验
    editor(val) {
      console.log(val);
      this.linetype = true;
      this.treuty("edit");
      this.statistics = { ...val };
      this.statistics.supervName = val.supervisorUnitId;
      this.statistics.buildName = val.constructionManagementName;
      this.statistics.deptName = val.optionUnitId;
      this.statistics.constructName = val.constructionUnitId;
      try {
        if (val.attendanceCoordinate && val.attendanceCoordinate.lat !== null) {
          this.statistics.attendanceCoordinateLat =
            val.attendanceCoordinate.lat;
          this.statistics.attendanceCoordinateLng =
            val.attendanceCoordinate.lng;
        }
      } catch (error) {
        console.error(error);
      }
      if (this.statistics.lineSiteType == 0 && this.statistics.category == 45) {
        this.deaws = true;
        this.setting = false;
      } else if (
        this.statistics.category == 47 ||
        this.statistics.category == 46
      ) {
        this.deaws = false;
        this.setting = true;
      } else {
        this.deaws = false;
        this.setting = false;
      }
      this.chooses = this.gettrName(this.dataList, val.puuid);

      this.constructName = this.gettrName(
        this.buesdList,
        val.constructionUnitId
      );

      this.buildName = this.gettrName(
        this.buildList,
        val.constructionManagementUnitId
      );

      this.deptName = this.gettrName(this.contractingList, val.optionUnitId);
      this.toringName = this.gettrName(this.surveyList, val.monitoringUnitId);
      this.designName = this.gettrName(this.designList, val.designUnitId);
      this.supervName = this.gettrName(this.monitorList, val.supervisorUnitId);
      this.thirdName = this.gettrName(this.thirdList, val.thirdPartUnitId);
      if (this.statistics.lineSiteType == 0) {
        queryDictByLabel({ label: "工点类别" }).then((res) => {
          queryDictNodesById({ id: res.data.id }).then((el) => {
            let i = el.data.findIndex(
              (item) => this.statistics.category === item.id
            );
            var name = el.data[i].label;
            let j = this.lineSiteTypes.findIndex(
              (findItem) => name === findItem.label
            );
            this.statistics.lineSiteType = this.lineSiteTypes[j].id;
          });
        });
      }
      this.dialogVisible = true;
      this.addter = false;
      this.prrert = true;
      if (val.lineSiteType == 319 || val.lineSiteType == 320) {
        this.rules = {
          puuid: [{ required: true, message: "请选择上级", trigger: "change" }],
          lineSiteType: [
            { required: true, message: "请选择类型", trigger: "change" },
          ],

          projectCharge: [
            { required: true, message: "请输入项目负责人", trigger: "blur" },
          ],
          projectChargeMobile: [
            { required: true, message: "请输入手机号", trigger: "blur" },
            {
              pattern: /^1[3456789]\d{9}$/,
              message: "手机号码格式不正确",
              trigger: "blur",
            },
          ],
          projectType: [
            { required: true, message: "请选择工程类型", trigger: "change" },
          ],
          workLevel: [
            { required: true, message: "请选择施工类型", trigger: "change" },
          ],

          projectStatue: [
            { required: true, message: "请选择项目状态", trigger: "change" },
          ],
          constructionMethod: [
            { required: true, message: "请选择工法", trigger: "change" },
          ],
          constructionUnitId: [
            { required: true, message: "请输入施工单位", trigger: "blur" },
          ],
          state: [
            { required: true, message: "请选择施工状态", trigger: "change" },
          ],
          planStartTime: [
            {
              required: true,
              message: "请选择日期时间",
              trigger: "change",
            },
          ],
          planEndTime: [
            {
              required: true,
              message: "请选择日期时间",
              trigger: "change",
            },
          ],
          constructionManagementId: [
            { required: true, message: "请输入建设单位", trigger: "change" },
          ],
          optionUnitId: [
            { required: true, message: "请输入总承包单位", trigger: "change" },
          ],
          monitoringUnitId: [
            { required: true, message: "请输入勘察单位", trigger: "change" },
          ],
          designUnitId: [
            { required: true, message: "请输入设计单位", trigger: "change" },
          ],
          supervisorUnitId: [
            { required: true, message: "请输入监理单位", trigger: "change" },
          ],
          thirdPartUnitId: [
            {
              required: true,
              message: "请输入第三方咨询单位",
              trigger: "change",
            },
          ],
          name: [{ required: true, message: "请输入名称", trigger: "change" }],
        };
      } else {
        if (this.rules.projectCharge) {
          this.rules = {
            puuid: [
              { required: true, message: "请选择上级", trigger: "change" },
            ],
            lineSiteType: [
              { required: true, message: "请选择类型", trigger: "change" },
            ],
            state: [
              { required: true, message: "请选择施工状态", trigger: "change" },
            ],
            projectType: [
              { required: true, message: "请选择工程类型", trigger: "change" },
            ],
            workLevel: [
              { required: true, message: "请选择施工类型", trigger: "change" },
            ],
            projectStatue: [
              { required: true, message: "请选择项目状态", trigger: "change" },
            ],
            constructionMethod: [
              { required: true, message: "请选择工法", trigger: "change" },
            ],
            name: [
              { required: true, message: "请输入名称", trigger: "change" },
            ],
          };
          this.$refs.statistics.clearValidate();
        }
      }
    },
    //编辑确定
    deter(statistics) {
      this.$refs[statistics].validate((valid) => {
        if (valid) {
          let nameList = this.lineSiteTypes.filter(
            (item) => this.statistics.lineSiteType === item.id
          );
          if (nameList) {
            var name = nameList[0].label;
          }
          queryDictByLabel({ label: "工点类别" }).then((res) => {
            queryDictNodesById({ id: res.data.id }).then((el) => {
              var i = el.data.findIndex((item) => item.label === name);
              this.statistics.category = el.data[i].id;
            });
          });

          aertProje(this.statistics).then((res) => {
            if (res.code == 0) {
              worksiteTree({}).then((res) => {
                if (res.code == 0) {
                  localStorage.setItem("DetailsTree", JSON.stringify(res.data));
                }
              });
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.dialogVisible = false;
              this.trees();
              this.handleNodeClick(this.tabedata);
              this.$refs[statistics].resetFields();
            }
          });
        } else {
          return false;
        }
      });
    },
    //删除
    deletes(val) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleProje({ uuids: val.uuid }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.trees();
              this.handleNodeClick(this.tabedata);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //类型
    typets(val) {
      var names = "";
      //  319 车站 , 区间 320 , 工点 346
      this.types.forEach((el) => {
        if (el.id == val) {
          names = el.label;
        }
      });
      if (val == 319 || val == 320 || val == 346) {
        this.statistics.ancestor = 0;
        //上级选中的分布
        if (this.treeLiseer.lineSiteType == 317) {
          this.statistics.branchUuid = this.treeLiseer.uuid;
          this.statistics.lineUuid = this.treeLiseer.lineUuid;
        } else if (this.treeLiseer.lineSiteType == 318) {
          //上级选中的工区
          this.statistics.workAreaUuid = this.treeLiseer.uuid;
          this.statistics.branchUuid = this.treeLiseer.branchUuid;
          this.statistics.lineUuid = this.treeLiseer.lineUuid;
        }
      }
      queryDictByLabel({ label: "工点类别" }).then((res) => {
        queryDictNodesById({ id: res.data.id }).then((el) => {
          el.data.forEach((item) => {
            if (item.label == names) {
              this.statistics.category = item.id;
              if (this.statistics.category == 45) {
                //45是区间(目前看到是这样2023/4/11)
                this.deaws = true;
                this.setting = false;
              } else if (
                this.statistics.category == 47 ||
                this.statistics.category == 46
              ) {
                //47是工点 只有选择的是工点才显示打卡点和画点
                this.deaws = false;
                this.setting = true;
              } else {
                this.deaws = false;
                this.setting = false;
              }
            }
          });
        });
      });
    },
    //新增取消
    addcancel(statistics) {
      this.statistics = {
        planStartTime: null,
        planEndTime: null,
        puuid: "",
        constructionUnitId: "",
        attendanceCoordinate: {
          lat: "",
          lng: "",
        },
      };
      this.dialogVisible = false;
      this.rules = {};
      this.$refs[statistics].resetFields();
      // this.chooses = "";
      (this.constructName = ""),
        (this.chooses = ""),
        (this.buildName = ""),
        (this.deptName = ""),
        (this.toringName = ""),
        (this.designName = ""),
        (this.supervName = ""),
        (this.thirdName = "");
    },
    //修改取消
    difycancel(statistics) {
      this.statistics = {
        planStartTime: null,
        planEndTime: null,
        puuid: "",
        constructionUnitId: "",
        attendanceCoordinate: {
          lat: "",
          lng: "",
        },
      };
      this.dialogVisible = false;
      this.$refs[statistics].resetFields();
      this.chooses = "";
      (this.constructName = ""),
        (this.chooses = ""),
        (this.buildName = ""),
        (this.deptName = ""),
        (this.toringName = ""),
        (this.designName = ""),
        (this.supervName = ""),
        (this.thirdName = "");
    },
    handlerescueChange(val) {
      this.page.pageSize = val;
      this.pages.pageNum = this.page.pageNo;
      this.pages.pageSize = this.page.pageSize;
      this.handleNodeClick(this.tabedata);
    },
    hanrescueChange(val) {
      this.page.pageNo = val;
      this.pages.pageNum = this.page.pageNo;
      this.pages.pageSize = this.page.pageSize;
      this.handleClick(this.tabedata, this.pages.pageNum);
    },

    //分页点击
    handleClick(data, num) {
      this.loading = true;

      this.tabedata = data;
      this.tableData = this.getTreeName(this.dataList, data.id);
      this.pages.puuid = data.uuid;
      if (num) {
        this.pages.pageNum = num;
      } else {
        this.pages.pageNum = 1;
      }
      triplePage(this.pages).then((res) => {
        this.tableData = res.data.list;
        this.statistics.puuid = data.puuid;
        // this.options = res.data.list;
        this.page.count = res.data.total;
        this.page.total = res.data.pages;
        this.loading = false;
      });
    },
    //弹框关闭
    handleClose() {
      this.statistics = {
        planStartTime: null,
        planEndTime: null,
        puuid: "",
        constructionUnitId: "",
        attendanceCoordinate: {
          lat: "",
          lng: "",
        },
      };
      this.dialogVisible = false;

      this.$refs.statistics.resetFields();
      this.chooses = "";
      (this.constructName = ""),
        (this.chooses = ""),
        (this.buildName = ""),
        (this.deptName = ""),
        (this.toringName = ""),
        (this.designName = ""),
        (this.supervName = ""),
        (this.thirdName = "");
    },
    //右侧表格数据查询
    querySite() {
      this.pages.puuid = "0";
      this.pages.name = this.input;
      this.pages.pageNum = 1;
      triplePage(this.pages).then((res) => {
        this.tableData = res.data.list;
        this.page.count = res.data.total;
        this.page.total = res.data.pages;
        this.loading = false;
      });
    },
    //地图加载后事件画线
    handler({ BMap, map }) {
      this.bbbb = map;
      map.setMapStyleV2({
        styleId: "565ab0773031556035c58e365ee8547a",
      });
      var x = null;
      var y = null;
      //画线
      if (
        this.statistics.fance == "" ||
        this.statistics.fance == null ||
        this.statistics.fance == undefined
      ) {
        x = 113.2839488029;
        y = 23.0907270045;
        window.echo = [];
        window.polyline = [];
      } else {
        x = this.statistics.fance[0].lat;
        y = this.statistics.fance[0].lng;
        var echo = [];
        this.statistics.fance.map((el) => {
          echo.push(new BMap.Point(el.lat, el.lng));
        });
        window.echo = echo;
        var polyline = new BMap.Polyline(echo, {
          strokeColor: "green",
          strokeWeight: 8,
        });

        map.addOverlay(polyline);
        window.polyline = polyline;
      }
      this.pageeds = {
        start: "",
        end: "",
        center: { lng: x, lat: y },
        zoom: 14,
      };
      var area = [];
      var morePointer = [];
      morePointer = window.echo;
      let that = this;
      map.addEventListener("click", function(e) {
        that.worksiteDetails();
        if (window.morePointer && window.morePointer.length > 0) {
          map.clearOverlays(window.polyline);
        }
        if (that.isRmoveLne == true) {
          morePointer = [];
          that.isRmoveLne = false;
        }
        var arr = { lat: e.pointN.lng, lng: e.pointN.lat };
        area.push(arr); //需要往后端传的数组
        window.area = area;
        var createPointer = new BMap.Point(e.pointN.lng, e.pointN.lat); //多个点连线的格式
        morePointer.push(createPointer); //push到一个数组中然后渲染
        window.morePointer = morePointer;
        window.polyline = new BMap.Polyline(morePointer, {
          strokeColor: "green",
          strokeWeight: 8,
        });
        map.addOverlay(window.polyline);
      });
    },
    handlers({ BMap, map }) {
      this.bbbb = map;
      map.setMapStyleV2({
        styleId: "565ab0773031556035c58e365ee8547a",
      });
      var x = null;
      var y = null;
      if (this.clockIn == false) {
        if (
          (this.statistics.lat == null && this.statistics.lng == null) ||
          (this.statistics.lat == 0 && this.statistics.lng == 0)
        ) {
          x = 23.0907270045;
          y = 113.2839488029;
        } else {
          x = this.statistics.lat;
          y = this.statistics.lng;
          this.marker = new BMap.Marker(
            new BMap.Point(this.statistics.lng, this.statistics.lat)
            // new BMap.Point(this.statistics.lat, this.statistics.lng)
          );
        }
      } else {
        if (
          (this.statistics.attendanceCoordinateLat == null &&
            this.statistics.attendanceCoordinateLng == null) ||
          (this.statistics.attendanceCoordinateLat == 0 &&
            this.statistics.attendanceCoordinateLng == 0)
        ) {
          x = 23.0907270045;
          y = 113.2839488029;
        } else {
          x = this.statistics.attendanceCoordinateLat;
          y = this.statistics.attendanceCoordinateLng;
          this.marker = new BMap.Marker(
            new BMap.Point(
              this.statistics.attendanceCoordinateLng,
              this.statistics.attendanceCoordinateLat
            )
            // new BMap.Point(this.statistics.lat, this.statistics.lng)
          );
        }
      }
      map.addOverlay(this.marker);
      window.firstcreateMarker = this.marker;
      this.pageds = {
        start: "",
        end: "",
        center: { lng: y, lat: x },
        zoom: 14,
      };
      let that = this;
      map.addEventListener("click", function(e) {
        that.worksiteDetails();
        if (window.firstcreateMarker) {
          map.clearOverlays(window.firstcreateMarker);
        }
        if (this.marker) {
          map.clearOverlays(this.marker);
        }
        this.marker = new BMap.Marker(
          new BMap.Point(e.pointN.lng, e.pointN.lat)
        );
        map.addOverlay(this.marker);
        this.gotPointer = [e.pointN.lng, e.pointN.lat];
        window.gotPointer = this.gotPointer;
      });
    },
    treeToList(list) {
      let res = [];
      for (const item of list) {
        const { children, ...i } = item;
        if (children && children.length) {
          res = res.concat(this.treeToList(children));
        }
        res.push(i);
      }
      return res;
    },
    //地图绘点
    mapTree(data) {
      // let con = list.filter((e) => e.name == "广州市轨道交通十号线");
      let colors = "#01963F";
      let url = require("../../../assets/map/gongdian.png");
      data.forEach((e) => {
        switch (e.name) {
          case "广州市轨道交通十八号线":
            colors = "#01963F";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通13号线二期线路":
            colors = "#026CB6";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市城市轨道交通十四号线二期工程":
            colors = "#956339";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州东至花都天贵城际项目":
            colors = "#901D42";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通二十二号线":
            colors = "#E6037D";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通十二号线及同步实施工程总承包项目":
            colors = "#950885";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通五号线东延段及同步实施工程":
            colors = "#F977A3";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通十号线":
            colors = "#99D317";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通7号线二期线路":
            colors = "#B159D4";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通11号线路":
            colors = "#55CAE2";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通三号线东延段":
            colors = "#5E5EF7";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "芳白城际":
            colors = "#95D30C";
            url = require("../../../assets/map/gongdian.png");
            break;
          default:
            break;
        }
        const nodes = e.children ? this.treeToList(e.children) : "";

        for (let item of nodes) {
          // 将返回的围栏坐标集合并到
          if (item.lat != 0) {
            let p = bd_encrypt(item.lng, item.lat);

            // 添加工点
            item.coord = [p.lat, p.lng];

            item.url = url;
            item.infoWindow = {
              show: false,
            };
            item.markerShow = "";
            this.workPoints.push(item);
          }
          if (item.fance !== null) {
            let line = {
              id: e.id,
              name: e.name,
              polylinePath: [],
              color: colors,
            };
            item.fance.forEach((el) => {
              let q = bd_encrypt(el.lng, el.lat);
              let d = { lng: q.lat, lat: q.lng };
              line.polylinePath.push(d);
            });
            this.polylineList.push(line);
          } else {
          }
        }
      });
    },
    //点击画线按钮
    drawLine() {
      this.dialogdrawLineVisible = true;
    },
    //点击画点按钮
    settingMapPointer() {
      this.clockIn = false;
      this.dialogMapVisible = true;
    },
    //点击打卡按钮
    settingMapPointerClock() {
      this.clockIn = true;
      this.dialogMapVisible = true;
    },
    //清空
    saveMapEmpty() {
      this.isRmoveLne = true;
      this.bbbb.clearOverlays(window.polyline);
      this.worksiteDetails();
    },
    //保存画线
    saveMapLine() {
      if (this.isRmoveLne == true) {
        this.statistics.fance = null;
      } else {
        this.statistics.fance = window.area;
      }

      this.dialogdrawLineVisible = false;
    },
    //保存画点
    saveMapPointer() {
      this.dialogMapVisible = false;
      if (this.clockIn == false) {
        this.statistics.lat = window.gotPointer[1];
        this.statistics.lng = window.gotPointer[0];
      } else {
        this.statistics.attendanceCoordinateLat = window.gotPointer[1];
        this.statistics.attendanceCoordinateLng = window.gotPointer[0];
        this.statistics.attendanceCoordinate = {
          lat: window.gotPointer[1],
          lng: window.gotPointer[0],
        };
      }
    },
    // 导入
    lead() {
      this.$nextTick(() => {
        this.$refs.importForms.handleImport();
      });
    },
    importSub() {
      this.querySite();
    },
    // 下载
    leaddownload() {
      let parms = {};
      lineDownload(parms).then((res) => {
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel",
        });
        // 3.创建一个临时的url指向blob对象
        let url = window.URL.createObjectURL(blob);
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "导入模板下载.xlsx"; //自定义导出文件名
        a.click();
        this.checkList = [];
        this.timesList = [];
        this.dialogVisible = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-select-dropdown__item {
  height: 0 !important;
}
.page-container {
  display: flex;

  .disple {
    width: 80%;
    margin-left: 20px;
  }
}

.headerContent {
  padding-top: 1rem;
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  width: 20%;
  color: #333;
  background-color: #ffffff !important;
  height: 98%;
  border: 1px solid #eee;
  //   position: absolute;
  //   top: 1%;
}

.search-wrapper {
  // position: absolute;
  // right: 13%;

  // top: 6%;

  display: flex;

  span {
    width: 77px;

    margin-left: 5px;
  }
}

.addert {
  // position: absolute;
  // right: 6%;
  // top: 6%;
}

.el-main {
  width: 100%;
  height: 88%;
  //   position: absolute;
  //   left: 20%;
  //   margin-left: 10px;
}

// ::v-deep .el-input-group__append {
//     background: #43a5e9;
//     border: 1px solid #43a5e9;
//     border-left: none;
//     color: #fff;
//     margin-left: 5px;
//   }
::v-deep .mapPosition {
  .el-dialog__body {
    width: 97%;
    height: 90%;
    padding: 0;
    margin: auto;
    // position: relative;
  }

  .el-button {
    position: absolute;
    top: 10px;
    right: 60px;
    background: #ffffff;
    border: 1px solid #dcdfe6;
    color: #000;
  }

  .el-button:hover {
    color: #409eff;
    border-color: #c6e2ff;
    background-color: #ecf5ff;
  }
}

.map {
  width: 100%;
  height: 100%;
  margin-top: 2%;
}
</style>
<style>
.el-tree-node:focus > .el-tree-node__content {
  color: #43a5e9;
}

.el-tree-node__content:hover {
  color: #43a5e9;
}

.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  color: #43a5e9;
}
</style>
