import { httpInit } from "./http";
const http = httpInit("/gzdt-response");

//监理或建管部工作汇报统计列表查询
export const SupervisionRegulationList = params => http.post(`/response/statisticFormNew/managementList`, params);

//监理审批接口
export const supervisorApprover = params => http.post(`/response/statisticFormNew/approval`, params);

//建管部提交汇总数据信息.
export const proposalSummary = params => http.post(`/response/statisticFormNew/summaryDataSubmit`, params);

//质安部查询监管部数据详情
export const QualityToProposalData = params => http.post(`/response/statisticFormNew/getDataListBySupervisionUuid`, params);

//质安部查询事件全部汇总数据
export const QualityAllData = params => http.post(`/response/statisticFormNew/getSummaryDataList`, params);

// 导出质安部查询事件全部汇总数据
export const summaryDataList = params => http.post(`/response/statisticFormNew/export/summaryDataList`, params, {
  //重要  没有她导出的文件会打不开 
  responseType: 'blob'
});

// 导出监理或建管部工作汇报统计列表查询
export const managementList = params => http.post(`/response/statisticFormNew/export/managementList`, params, {
  //重要  没有她导出的文件会打不开 
  responseType: 'blob'
});