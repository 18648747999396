<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="160px"
      class="demo-model"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="专项任务类别" prop="categoryId">
            <!--            <el-input v-model="ruleForm.itemName" placeholder="隐患条目名称"></el-input>-->
            <el-select
              v-model="ruleForm.categoryId"
              clearable
              @change="dangerChange"
              style="width: 100%"
              placeholder="专项任务类别"
            >
              <el-option
                v-for="item in dangerType"
                :key="item.value"
                :label="item.categoryName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="专项任务子类别" prop="categorySubclassId">
            <!--            <el-input v-model="ruleForm.itemName" placeholder="隐患条目名称"></el-input>-->
            <el-select
              v-model="ruleForm.categorySubclassId"
              style="width: 100%"
              clearable
              placeholder="专项任务子类别"
            >
              <el-option
                v-for="item in dangerSon"
                :key="item.value"
                :label="item.categoryName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开始时间" prop="startTime">
            <!--            <el-input v-model="ruleForm.itemName" placeholder="隐患条目名称"></el-input>-->
            <el-date-picker
              v-model="ruleForm.startTime"
              format="yyyy-MM-dd"
              style="width: 100%"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="date"
              placeholder="开始时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结束时间" prop="endTime">
            <!--            <el-input v-model="ruleForm.itemName" placeholder="隐患条目名称"></el-input>-->
            <el-date-picker
              v-model="ruleForm.endTime"
              format="yyyy-MM-dd"
              style="width: 100%"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="date"
              placeholder="结束时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="排查线路/标段/工点" prop="itemName">
            <label slot="label" class="form-item-class">
              <el-button type="primary" size="small" @click="selectLine"
                >选择</el-button
              >
              <div>
                <span style="color: #f67979">*</span>排查线路/标段/工点：
              </div>
              &nbsp;</label
            >
            <el-input
              v-model="lineList"
              type="textarea"
              disabled
              placeholder="排查线路/标段/工点"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="单位" prop="itemName">
            <label slot="label" class="form-item-class">
              <el-button type="primary" size="small" @click="selectCompany"
                >选择</el-button
              >
              <div>
                <span style="color: #f67979">*</span>单位
              </div></label
            >
            <el-input
              v-model="companyString"
              disabled
              type="textarea"
              placeholder="单位"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="角色/人员" prop="itemName">
            <label slot="label" class="form-item-class">
              <el-button type="primary" size="small" @click="selectInfo"
                >选择</el-button
              >

              <div>
                <span style="color: #f67979">*</span>角色/人员
              </div></label
            >
            <el-input
              v-model="roleString"
              disabled
              type="textarea"
              placeholder="角色/人员"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="组长" prop="itemName">
            <label slot="label" class="form-item-class"> &nbsp; <div>
              <span style="color: #f67979">*</span>组长
            </div></label
            >
            <el-input
              v-model="groupLeader"
              disabled
              type="textarea"
              placeholder="组长"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-drawer
      :visible.sync="drawerVisible"
      direction="rtl"
      append-to-body
      size="30%"
      v-if="drawerVisible"
      class="task-drawer"
    >
      <el-tree
        :data="treeData"
        show-checkbox
        :props="props"
        node-key="id"
        v-if="drawerType === 'line'"
        :default-checked-keys="checkLineKey"
        ref="cascaderTree"
        @check-change="handleCheckChange"
        :expand-on-click-node="false"
      >
<!--         default-expand-all-->
        <span class="custom-tree-node" slot-scope="{ node }">
          <span>{{ node.label }}</span>
          <span style="margin-left: 30px">
            <!--          <el-button size="mini" type="primary" @click="() => append(data)">
            指定组长
          </el-button>-->
            <!--            <el-checkbox @change="checkChange(data,node)"></el-checkbox>-->
          </span>
        </span>
      </el-tree>

      <el-tree
        :data="companyData"
        show-checkbox
        :props="companyProps"
        node-key="unitTypeCode"
        v-if="drawerType === 'company'"
        :default-checked-keys="checkCompanyKey"
        @check-change="handleCompanyChange"
        default-expand-all
        :expand-on-click-node="false"
      >
      </el-tree>

      <div class="role-select" v-if="drawerType === 'rule'">
        <div
          class="company-item"
          v-for="(item, index) in companyCopy"
          :key="index"
        >
          <div class="company-top">
            <!--              <div :class=" item.isActive ? 'active' : 'company-name' "-->
            <div class="company-name" @click="getRoleList(item)">
              {{ item.unitTypeName }}
            </div>
            <div class="company-check">
              <el-checkbox
                v-model="item.isAll"
                @change="allChange($event, item)"
              ></el-checkbox>
            </div>
            <div class="company-open">
              <i class="el-icon-arrow-down" v-if="!item.isOpen"></i>
              <i class="el-icon-arrow-up" v-else></i>
            </div>
          </div>

          <el-collapse-transition>
            <div class="son-list" v-show="item.isOpen">
              <div
                class="empty"
                v-if="!item.roleList || item.roleList.length === 0"
              >
                暂无数据
              </div>

              <div
                class="son-item"
                v-else
                v-for="(sonItem, sonIndex) in item.roleList"
                :key="sonIndex"
              >
                <div class="son-name">
                  {{ sonItem.roleName }}
                </div>
                <div class="son-check">
                  <el-checkbox
                    v-model="sonItem.isCheck"
                    @change="sonCheck($event, sonItem, item)"
                  ></el-checkbox>
                </div>
                <el-button
                  type="primary"
                  size="small"
                  @click="appointLeader(sonItem)"
                  >指定组长</el-button
                >
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>

      <div class="submit-button">
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-drawer>

    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelModal">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </div>
</template>

<script>
import {
  getUnitsBySiteIds,
  specTroubleAdd,
  subCategoryList,
} from "@/api/hdanger";
import { getRoleApi } from "@/api/role";

export default {
  name: "IssueTask",
  data() {
    return {
      ruleForm: {
        categoryId: "",
        categorySubclassId: "",
        startTime: "",
        endTime: "",
      },
      rules: {
        categoryId: [
          { required: true, message: '专项任务类别必填', trigger: 'change' }
        ],
        categorySubclassId: [
          { required: true, message: '专项任务子类别必填', trigger: 'change' }
        ],
        startTime: [
          { required: true, message: '开始时间必填', trigger: 'change' }
        ],
        endTime: [
          { required: true, message: '开始时间必填', trigger: 'change' }
        ],
      },
      drawerVisible: false,
      treeData: [],
      companyData: [],
      dangerType: [],
      dangerSon: [],
      props: {
        label: "name",
        children: "children",
      },
      companyProps: {
        label: "unitTypeName",
        children: "companyDtoSet",
      },
      checkList: [],
      companyList: [],
      flarArr: [],
      lineList: "",
      drawerType: "",
      companyString: "",
      companyCopy: [],
      checkRoleList: [],
      companyArray: [],
      checkLineKey: [],
      checkCompanyKey: [],
      roleString: "",
      groupLeader: "",
      lastLineList: [],
      previousRole: {},
    };
  },
  created() {
    this.getHiddenSelect(0);
    this.queryLineSiteTreeData();
  },
  methods: {
    cancelModal() {
      this.$emit("cancelModal");
    },
    handleCheckChange(data, checked) {
      const siteNodes = this.$refs.cascaderTree.getCheckedNodes(true)
      console.log(siteNodes)
      console.log(data,checked)
      if(siteNodes.length > 0) {
        if (checked === true) {
          siteNodes.forEach(item => {
            this.checkList.push(item);
          })
        }else {
          let index = this.checkList.findIndex((item) => data.id === item.id);
          this.checkList.splice(index, 1);
        }
      }else{
        this.checkList = [];
      }
      var arr = new Set(this.checkList)
      this.checkList = [...arr];
      console.log(this.checkList)
      // var arr = new Set(this.checkList);
      // this.checkList = arr;
    /*  if (!data.children) {
        if (checked === true) {
          this.checkList.push(data);
        } else {
          let index = this.checkList.findIndex((item) => data.id === item.id);
          this.checkList.splice(index, 1);
        }
      }*/
    },
    handleCompanyChange(data, checked) {
      if (!data.companyDtoSet) {
        if (checked === true) {
          this.companyList.push(data);
        } else {
          let index = this.companyList.findIndex(
            (item) => data.unitTypeCode === item.unitTypeCode
          );
          this.companyList.splice(index, 1);
        }
      }
    },
    getName(arr, obj, result = []) {
      result.push(obj.name);
      obj = arr.find((item) => item.uuid == obj.puuid);
      if (obj && obj.puuid) {
        // result.push(obj.name);
        this.getName(arr, obj, result);
      }
      return result.reverse();
      // return result.reverse().join(':');
    },
    // 平铺树状数组
    trans(data) {
      let transData = [];
      function go(data) {
        for (let item of data) {
          let { children } = item;
          transData.push(item);
          if (children) {
            go(children);
          }
        }
      }
      go(data);
      return transData;
    },
    handleSubmit() {
      if (this.drawerType === "line") {
        this.flarArr = this.trans(this.treeData);
        var lineArray = [];
        this.checkList.forEach((item) => {
          let arr = this.getName(this.flarArr, item);
          // let obj = `${arr[0]}：${arr[2]}`
          let obj = {
            lineName: arr[0],
            siteName: arr[arr.length - 1],
          };
          lineArray.unshift(obj);
          // this.lineList += `${obj};`;
        });

        console.log(lineArray, "lineArray");
        this.lastLineList = this.abilitySort(lineArray, "lineName");
        this.lineList = "";
        this.lastLineList.forEach((item) => {
          this.lineList +=
            "；" +
            item.lineName +
            "：" +
            item.data.map((mapItem) => mapItem.siteName).toString();
        });
        this.lineList = this.lineList.slice(1);
        /*  this.checkList.forEach(item => {
           // arr.unshift(this.getName(this.flarArr, item))
           this.lineList += `${this.getName(this.flarArr,item)} \n`;
           // this.lineList += `${this.getName(this.flarArr, item)};`;
         })*/
      } else if (this.drawerType === "company") {
        // var companyFlar = this.trans(this.companyData);
        var fatherName = "";
        this.companyList.forEach((item) => {
          this.companyData.forEach((findItem) => {
            if (findItem.companyDtoSet && findItem.companyDtoSet.length > 0) {
              findItem.companyDtoSet.forEach((lastItem) => {
                if (lastItem.unitTypeCode === item.unitTypeCode) {
                  fatherName +=
                    "；" + findItem.unitTypeName + "：" + item.unitTypeName;
                  item.companyChina = findItem.unitTypeName;
                  item.companyEnglish = findItem.unitTypeCode;
                }
              });
            }
          });
        });
        var copyCompany = JSON.parse(JSON.stringify(this.companyList));
        this.companyArray = this.abilitySort(copyCompany, "companyChina");
        this.companyString = fatherName.slice(1);
        // var fatherList = fatherName.split(';').slice(1)
      } else if (this.drawerType === "rule") {
        var copyList = JSON.parse(JSON.stringify(this.checkRoleList));
        var array = this.abilitySort(copyList, "unitTypeName");
        array.forEach((item) => {
          item.string =
            item.unitTypeName +
            "：" +
            item.data.map((dataItem) => dataItem.roleName).toString();
        });
        this.roleString = "";
        array.forEach((item) => {
          this.roleString += "；" + item.string;
        });
        this.roleString = this.roleString.slice(1);
      }
      this.drawerVisible = false;
      // this.lineList = arr.toString()
    },
    abilitySort(arr, property) {
      let map = {};
      for (let i = 0; i < arr.length; i++) {
        const ai = arr[i];
        if (!map[ai[property]]) map[ai[property]] = [ai];
        else map[ai[property]].push(ai);
      }
      let res = [];
      Object.keys(map).forEach((key) => {
        res.push({ [property]: key, data: map[key] });
      });
      return res;
    },
    selectLine() {
      this.drawerType = "line";
      if (this.checkList.length > 0) {
        this.checkLineKey = this.checkList.map((item) => item.id);
      }
      this.drawerVisible = true;
    },
    selectCompany() {
      this.drawerType = "company";
      getUnitsBySiteIds(this.checkList.map((item) => item.uuid)).then((res) => {
        this.companyData = res.data;
      });
      if (this.companyList.length > 0) {
        this.checkCompanyKey = this.companyList.map(
          (item) => item.unitTypeCode
        );
      }
      this.drawerVisible = true;
    },
    selectInfo() {
      this.drawerType = "rule";
      this.companyCopy = JSON.parse(JSON.stringify(this.companyData));
      this.getAllRoleList();
      this.companyCopy = this.companyCopy.map((item) => {
        item.isOpen = true;
        item.isAll = false;
        item.isActive = false;
        return item;
      });
      this.drawerVisible = true;
      this.$forceUpdate();
    },
    getHiddenSelect(id) {
      subCategoryList(id).then((res) => {
        if (res.code == 0) {
          if (id == 0) {
            this.dangerType = res.data;
          } else {
            this.dangerSon = res.data;
          }
        }
      });
    },
    dangerChange() {
      this.ruleForm.categorySubclassId = "";
      this.getHiddenSelect(this.ruleForm.categoryId);
    },

    queryLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    getAllRoleList() {
      this.companyCopy.forEach((item) => {
        this.getRoleList(item);
      });
    },
    getRoleList(item) {
      item.isOpen = !item.isOpen;
      item.isActive = !item.isActive;
      getRoleApi({ unitType: item.unitTypeCode }).then((res) => {
        if (res.code == 0) {
          this.$set(
            item,
            "roleList",
            res.data.map((item) => {
              item.isCheck = false;
              return item;
            })
          );
        }
        if (this.checkRoleList.length > 0) {
          this.checkRoleList.forEach((roleItem) => {
            console.log(roleItem);
            this.companyCopy.forEach((item) => {
              if (item.roleList && item.roleList.length > 0) {
                console.log(roleItem);
                let index = item.roleList.findIndex(
                  (findItem) => roleItem.id === findItem.id
                );
                if (index !== -1) {
                  item.roleList[index].isCheck = true;
                }
              }
            });
          });
        }
        this.$forceUpdate();
      });
    },
    allChange(event, item) {
      item.isAll = event;
      if (event === true) {
        item.roleList.forEach((roleItem) => {
          roleItem.unitTypeName = item.unitTypeName;
          roleItem.unitTypeCode = item.unitTypeCode;
          this.checkRoleList.push(roleItem);
        });
        /* this.checkRoleList = this.checkRoleList.map(checkItem => {
           checkItem.unitTypeName = item.unitTypeName;
           checkItem.unitTypeCode = item.unitTypeCode;
           return item;
         })*/
      } else {
        item.roleList.forEach((roleItem) => {
          let index = this.checkRoleList.findIndex(
            (findItem) => findItem.id === roleItem.id
          );
          this.checkRoleList.splice(index, 1);
        });
      }
      item.roleList.forEach((roleItem) => {
        roleItem.isCheck = event;
      });
      this.$forceUpdate();
    },
    sonCheck(event, sonItem, item) {
      sonItem.isCheck = event;
      if (event === true) {
        sonItem.unitTypeName = item.unitTypeName;
        sonItem.unitTypeCode = item.unitTypeCode;
        this.checkRoleList.push(sonItem);
        /*   this.checkRoleList = this.checkRoleList.map(checkItem => {
             checkItem.unitTypeName = item.unitTypeName;
             checkItem.unitTypeCode = item.unitTypeCode;
             return checkItem;
           })*/
      } else {
        let index = this.checkRoleList.findIndex(
          (findItem) => findItem.id === sonItem.id
        );
        this.checkRoleList.splice(index, 1);
      }
      if (item.roleList && item.roleList.length > 0) {
        let index = item.roleList.findIndex(
          (findItem) => findItem.isCheck === false
        );
        if (index === -1) {
          item.isAll = true;
        } else {
          item.isAll = false;
        }
      }
      this.$forceUpdate();
    },
    appointLeader(info) {
      if (this.previousRole.roleName) {
        this.$delete(this.previousRole, "leader");
      }
      this.previousRole = info;
      this.$set(info, "leader", true);
      this.groupLeader = info.roleName;
      this.$message.success("成功");
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if(this.checkList.length === 0){
            this.$message.error('排查线路/标段/工点必填');
            return;
          }
          if(this.checkRoleList.length === 0){
            this.$message.error('角色/人员必填');
            return;
          }
          if(this.companyArray.length === 0){
            this.$message.error('单位u必填');
            return;
          }
          let params = {
            taskLines: this.checkList.map((item) => {
              return {
                workAreaUuid: item.uuid,
                workAreaName: item.name,
              };
            }),
            taskRoles: this.checkRoleList.map((item) => {
              return {
                roleId: item.id,
                unitType: item.unitTypeCode,
                leader: item.leader,
              };
            }),
            unitDtos: this.companyArray.map((item) => {
              return {
                companyDtoSet: item.data.map((dataItem) => {
                  return {
                    unitTypeCode: dataItem.unitTypeCode,
                    unitTypeName: dataItem.unitTypeName,
                  };
                }),
                unitTypeCode: item.data[0].companyEnglish,
                unitTypeName: item.companyChina,
              };
            }),
            screeningType: "special_check",
            ...this.ruleForm,
          };
          specTroubleAdd(params).then((res) => {
            if (res.code == 0) {
              this.$message.success("操作成功");
              this.$emit("successModal");
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          this.$message.error("请正确填写");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-item-class {
  display: flex;
  flex-direction: column;
}
.task-drawer {
  ::v-deep .el-tree-node__content {
    margin-bottom: 20px;
  }
}
::v-deep .el-tree-node__content > label.el-checkbox {
  position: absolute;
  left: 400px;
}
.submit-button {
  text-align: center;
  margin-top: 20px;
}
.role-select {
  .company-item {
    .company-top {
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
    }
    .active {
      color: #0092f3;
      cursor: pointer;
      width: 200px;
    }
    margin-bottom: 20px;
    width: 100%;
    .son-list {
      .son-item {
        padding: 0 20px;
        display: flex;
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 14px;
        .son-name {
          width: 200px;
        }
        .son-check {
          margin: 0 20px 0 100px;
        }
        ::v-deep .el-button--small {
          height: 20px;
          display: flex;
          align-items: center;
        }
      }
      .empty {
        margin: 10px 0;
        width: 400px;
        text-align: center;
      }
    }
  }
  .company-name {
    width: 200px;
    cursor: pointer;
  }
  .company-check {
    margin: 0 20px 0 100px;
  }
}
</style>
