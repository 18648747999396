<template>
  <div class="callback-container" />
</template>

<script>
import { api } from "@/services/config";
import { setToken } from "@/utils/token";
import { setUserInfo } from "@/utils/user";
import * as jsonwebtoken from "jsonwebtoken";
import { ssoLogin } from "@/api/user";
import axios from "axios";
import qs from "qs";
import dayjs from "dayjs";
export default {
  name: "callback",
  data() {
    return {
      loading: false,
    };
  },
  async created() {
    console.log("callback---------");
    this.callback();
    this.loading = true;
  },
  methods: {
    async callback() {
      let code = location.search.split("=")[1].split("&")[0];

      console.log(code);

      const getTokenReq = {
        grant_type: "authorization_code",
        code: code,
        redirect_uri: api.callbackURL,
        client_id: api.clientId,
        client_secret: api.clientSecret,
      };

      const data = await axios.post(
        api.loginURL + "/v1/connect/token",
        qs.stringify(getTokenReq)
      );
      console.log("result", data);
      const token = data.data.access_token;
      const idToken = data.data.id_token;
      const userInfo = jsonwebtoken.decode(idToken);

      await setUserInfo(JSON.stringify(userInfo));
      await setToken(token);
      userInfo.LoginTime = dayjs(userInfo.LoginTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      let params = {
        token: idToken,
        userInfo: userInfo,
      };
      let res = await ssoLogin(params);
      if (res.code === 0) {
        console.log(res.data);
        localStorage.setItem("jwtToken", res.data.jwtToken);
        localStorage.userData = JSON.stringify(res.data);
        this.$router.push("/index");
      }
    },
  },
  beforeDestroy() {
    this.loading = false;
  },
};
</script>
<style scoped lang="less"></style>
