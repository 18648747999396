<template>
  <systemModal
    ref="formModal"
    :title="title"
    :showFooter="false"
    width="40%"
    @modalClose="handleClose"
  >
    <el-form
      v-loading="loading"
      :model="model"
      ref="modelForm"
      label-width="200px"
      class="demo-model"
      style="width: 80%"
    >
      <el-form-item label="调配工点" prop="name">
        <el-input
          v-model="model.name"
          readonly
          placeholder="调配工点"
        ></el-input>
      </el-form-item>
       <el-form-item label="地理位置" prop="position ">
        <el-input
          v-model="model.position "
          readonly
          placeholder="地理位置"
        ></el-input>
      </el-form-item>
      <el-form-item label="调配内容" prop="deployContent">
        <el-input
          v-model="model.deployContent"
          readonly
          placeholder="调配内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="调配人" prop="optionName">
        <el-input
          v-model="model.optionName"
          readonly
          placeholder="调配人"
        ></el-input>
      </el-form-item>
       <el-form-item label="调配时间" prop="deployTime">
        <el-input
          v-model="model.deployTime"
          readonly
          placeholder="调配时间"
        ></el-input>
       </el-form-item>
      <el-form-item label="选择配送人" prop="shipperId">
        <el-select v-model="model.shipperId" placeholder="选择配送人" @change="handleShipperChange">
          <el-option
            v-for="item in personList"
            :key="item.uuid"
            :label="item.realName"
            :value="item.uuid"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="footer w-full flex justify-center">
      <el-button type="primary" @click="onSubmit">确认调配</el-button>
      <el-button type="primary" @click="onReject">驳回</el-button>
    </div>
    <el-dialog
      title="驳回"
      append-to-body
      :visible.sync="rejectModalShow"
      width="30%"
    >
      <el-form :model="form" :rules="rules" ref="form" label-width="120px">
        <el-form-item label="驳回原因" prop="reason">
          <el-input type="textarea" v-model="form.reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onRejectSubmit">确 定</el-button>
        <el-button @click="onRejectClose">取 消</el-button>
      </span>
    </el-dialog>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { materialHandle, queryMaterialHandleInfo, rejectMaterialHandle, queryMaterialStaffPerson } from "@/api/eres";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "资源调配",
      model: {},
      eventId: "",
      id: "",
      personList: [],
      loading: false,
      rejectModalShow: false,
      form: {
        reason: "",
      },
      rules: {
        reason: [{ required: true, message: "请输入驳回原因", trigger: "blur" }]
      }
    };
  },
  filters: {},
  mounted() {},
  methods: {
    handleOpen(record) {
      this.eventId = record.eventId;
      this.id = record.id;
      this.getInfo(record.eventId);
      this.$refs.formModal.handleOpen();
      // 处理人员请求接口参数
      let queryPersonParams = null;
      if (record.teamId) {
        queryPersonParams = {
          type: 1,
          rescueTeamId: record.teamId,
           eventId:record.eventId
        };
      } else {
        queryPersonParams = {
          type: 0,
          siteId: record.siteUuid,
           eventId:record.eventId
        };
      }
      this.queryPersonList(queryPersonParams);
    },
    // 查询详情
    async getInfo(id) {
      let res = await queryMaterialHandleInfo({ id });
      if (res.code == 0) {
        this.model = res.data;
      }
    },
    // 查询调配人员列表
    async queryPersonList(record) {
      let res = await queryMaterialStaffPerson(record);
      if (res.code == 0) {
        this.personList = res.data;
      }
    },
    // 配送人选中
    handleShipperChange(val) {
      const person = this.personList.find(item => item.uuid == val);
      if (person) {
        this.model.shipper = person.realName;
      }
    },
    // 通过
    async onSubmit() {
      const params = JSON.parse(JSON.stringify(this.model));
      params.id = this.id;
      params.eventId = this.eventId;
      let res = await materialHandle(params);
      if (res.code == 0) {
        this.$message.success("操作成功!");
        this.$emit("close", "2");
        this.$refs.formModal.handleClose();
      }
    },
    // 驳回
    onReject() {
      this.rejectModalShow = true;
    },
    onRejectSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 驳回成功
          const params = JSON.parse(JSON.stringify(this.model));
          params.id = this.id;
          params.eventId = this.eventId;
          params.reason = this.form.reason;
          rejectMaterialHandle(params).then(res => {
            if (res.code == 0) {
              this.$message.success("操作成功!");
              this.rejectModalShow = false;
              this.$refs.formModal.handleClose();
              this.$emit("close", "2");
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onRejectClose() {
      this.reason = "";
      this.rejectModalShow = false;
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 30px !important;
}

::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}

::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
</style>
