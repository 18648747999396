<template>
  <!-- 整改/审批 -->
  <div>
    <el-form
      ref="ruleForm"
      :rules="rules"
      :model="ruleForm"
      label-width="90px"
      class="demo-ruleForm"
      v-loading="fromLoading"
    >
      <ul>
        <p class="details">
          线路-工点:{{ lineName ? lineName + "-" : "" }}{{ siteName }}
        </p>
        <p class="details">问题上报时间:{{ checkTime }}</p>
        <p class="details">检查人员:{{ checkPeople }}</p>
        <p class="details">问题详情:{{ checkItemContent }}</p>
        <div class="demo-image__preview" v-if="uri" style="display:flex">
          <span>
            问题照片:
          </span>
          <el-image
            style="width: 100px; height: 100px; margin:0 0 10px 5px"
            :src="uri"
            :preview-src-list="uriList"
          >
          </el-image>
        </div>
        <p v-show="correctiveApproveStatus != 0" class="details">
          整改人员:{{ correctiveUser ? correctiveUser.name : "" }}
        </p>
        <p v-show="correctiveApproveStatus != 0" class="details">
          整改时间:{{ correctiveTime }}
        </p>
        <p v-show="correctiveApproveStatus != 0" class="details">
          整改描述:{{ correctiveDescription }}
        </p>
        <div
          v-show="correctiveApproveStatus != 0"
          class="demo-image__preview"
          v-if="correctivePhotosUri"
          style="display:flex"
        >
          <span>
            整改后照片:
          </span>
          <el-image
            style="width: 100px; height: 100px; margin:0 0 10px 5px"
            :src="correctivePhotosUri"
            :preview-src-list="correctivePhotosUriList"
          >
          </el-image>
        </div>
      </ul>

      <div class="line"></div>
      <el-form-item
        v-if="emeTitle == '整改'"
        label="整改描述:"
        prop="correctiveDescription"
      >
        <!-- v-show="correctiveApproveStatus == 1" -->
        <el-input
          type="textarea"
          resize="none"
          :autosize="{ minRows: 3, maxRows: 5 }"
          placeholder="请输入内容"
          v-model="ruleForm.correctiveDescription"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        v-if="emeTitle == '审批'"
        label="审批意见:"
        prop="rejectReason"
      >
        <!-- v-show="correctiveApproveStatus == 1" -->
        <el-input
          type="textarea"
          resize="none"
          :autosize="{ minRows: 3, maxRows: 5 }"
          placeholder="多行输入"
          v-model="ruleForm.rejectReason"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        v-if="emeTitle == '整改'"
        label="上传图片:"
        prop="correctivePhotosUri"
      >
        <image-uploader
          v-model="ruleForm.correctivePhotosUri"
          :modelSrc="ruleForm.correctivePhotosUri"
          @change="urlchange($event)"
        ></image-uploader>
      </el-form-item>
      <el-form-item class="dialog-footer">
        <el-button v-show="emeTitle == '详情'" @click="cancel">取 消</el-button>
        <el-button v-show="emeTitle == '整改'" type="primary" @click="submit"
          >提交</el-button
        >
        <el-button v-show="emeTitle == '审批'" type="primary" @click="transit"
          >通 过</el-button
        >
        <el-button v-show="emeTitle == '审批'" type="primary" @click="turnDown"
          >驳 回</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDetails, correctiveFill, checkApprove } from "@/api/response";
import ImageUploader from "@/components/Uploader/ImageUploader.vue";

export default {
  components: {
    ImageUploader,
  },
  props: {
    rectId: {
      type: Number,
    },
    emeTitle: {
      type: String,
    },
  },
  data() {
    return {
      lineName: "",
      siteName: "",
      checkTime: "",
      checkPeople: "",
      checkItemContent: "",
      uri: "",
      correctiveUser: {
        name: "",
      },
      correctiveTime: "",
      correctiveDescription: "",
      correctivePhotosUri: "",
      correctivePhotosUriList: [],
      correctiveApproveStatus: "",
      rejectReason: "",
      uriList: [],
      ruleForm: {
        parentId: "",
        intro: "",
        label: "",
        code: "",
        sort: "",
        parentLabel: "",
      },

      fromLoading: false,
      rules: {
        correctiveDescription: [
          { required: true, message: "请输入整改描述", trigger: "blur" },
        ],
        rejectReason: [
          { required: true, message: "请输入审批意见", trigger: "blur" },
        ],
        correctivePhotosUri: [
          { required: true, message: "请上传图片", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    rectId: {
      handler(val) {
        if (val) {
          if (this.emeTitle == "整改") {
            getDetails(val).then((res) => {
              this.uriList = [];
              (this.correctivePhotosUriList = []),
                (this.lineName = res.data.lineName),
                (this.siteName = res.data.siteName),
                (this.checkTime = res.data.checkTime),
                (this.checkPeople = res.data.checkPeople);
              this.checkItemContent = res.data.hiddenQuestion;
              this.uri = res.data.uri;
              this.uriList.push(this.uri);
              this.correctiveUser.name = res.data.correctiveUser
                ? res.data.correctiveUser.name
                : "";
              this.correctiveTime = res.data.correctiveTime;
              this.correctiveDescription = res.data.correctiveDescription;
              this.correctivePhotosUri = res.data.correctivePhotosUri;
              this.correctivePhotosUriList.push(this.correctivePhotosUri);
              this.correctiveApproveStatus = res.data.correctiveApproveStatus;
              this.rejectReason = res.data.rejectReason;
            });
          } else {
            //
            getDetails(val).then((res) => {
              (this.lineName = res.data.lineName),
                (this.siteName = res.data.siteName),
                (this.checkTime = res.data.checkTime),
                (this.checkPeople = res.data.checkPeople);
              this.checkItemContent = res.data.hiddenQuestion;
              this.uri = res.data.uri;
              this.correctiveUser.name = res.data.correctiveUser
                ? res.data.correctiveUser.name
                : "";
              this.correctiveTime = res.data.correctiveTime;
              this.correctiveDescription = res.data.correctiveDescription;
              this.correctivePhotosUri = res.data.correctivePhotosUri;
              this.correctiveApproveStatus = res.data.correctiveApproveStatus;
              this.rejectReason = res.data.rejectReason;
            });
          }
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
  },
  methods: {
    //提交
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.fromLoading = true;
          let params = {
            correctiveDescription: this.ruleForm.correctiveDescription,
            correctivePhotosUri: this.ruleForm.correctivePhotosUri,
            id: this.rectId,
            hiddenQuestion: this.checkItemContent,
          };

          if (this.emeTitle == "整改") {
            correctiveFill(params)
              .then((res) => {
                if (res.code == 0) {
                  this.fromLoading = false;
                  this.$emit("cancelInspection");
                  this.resetForm();
                  this.$message.success("操作成功");
                } else {
                  this.resetForm();
                  this.fromLoading = false;
                  this.$message.warning(res.message);
                }
              })
              .catch((error) => {
                this.$message.warning(error.message);
                this.fromLoading = false;
                this.resetForm();
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    urlchange(url) {
      this.ruleForm.correctivePhotosUri = url;
    },
    // 关闭
    cancel() {
      this.resetForm();
    },
    // 通过
    transit() {
      this.fromLoading = true;
      let param = {
        correctiveApproveStatus: 3,
        questionId: this.rectId,
        rejectReason: this.ruleForm.rejectReason,
      };
      checkApprove(param).then((res) => {
        if (res.code == 0) {
          this.resetForm();
          this.$emit("cancelInspection");
          this.$message.success("审批完成");
        }
      });
    },
    // 驳回
    turnDown() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.fromLoading = true;
          let param = {
            correctiveApproveStatus: 2,
            questionId: this.rectId,
            rejectReason: this.ruleForm.rejectReason,
          };
          checkApprove(param).then((res) => {
            if (res.code == 0) {
              this.resetForm();
              this.$emit("cancelInspection");
              this.$message.success("审批完成");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.fromLoading = false;
      const th = this;
      Object.keys(this.ruleForm).forEach(function(key) {
        th.ruleForm[key] = "";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dataInfo {
  width: 100%;
  padding: 0px 15px;
  box-sizing: border-box;
  .searchbar {
    display: flex;
    justify-content: space-between;
  }
  .search {
    padding: 1rem 0;
  }
}
// ::v-deep .el-table {
//   overflow: auto;
// }

.contentLeft {
  width: 200px;
  height: 700px;
  overflow-y: auto;
  margin-right: 20px;
  border: 1px solid rgb(207, 203, 203);
  margin-left: 1rem;
  float: left;
}
.px-4 {
  width: calc(100% - 220px - 1rem);
  float: right;
  height: 100%;
}
.el-pagination__jump {
  margin-top: -6px;
}
// ::v-deep .el-table {
//   height: 620px;
// }
.details {
  margin-bottom: 10px;
}

.q_class {
  background-color: #edf5fb;
  color: #85bee3;
  margin-top: 10px;
  font-size: 18px;
  cursor: pointer;
}
.d_class {
  color: #d7cbcb;
  background-color: #f86e72;
}
::v-deep .el-table--mini .el-table__cell {
  padding: 12px 0 !important;
}
.historyDialog ::v-deep .el-dialog__body {
  padding: 10px 20px;
}
.checkDialog ::v-deep .el-dialog__body {
  padding: 10px 20px 0;
}
.checkDialog ::v-deep .el-dialog__footer {
  margin: -12px auto 0;
  padding: 0 20px 10px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.dialog-footer ::v-deep .el-form-item__content {
  margin-left: 0 !important;
}
</style>
