<template>
  <Modal ref="modalUser" :modal="false" :width="'1200px'" :title="modelTitle">
    <div class="material-container h-full">
      <el-form
        ref="libraryForm"
        :inline="true"
        :model="libraryForm"
        class="demo-form-inline"
      >
        <el-form-item prop="startDate">
          <el-date-picker
            clearable
            v-model="libraryForm.startDate"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            :append-to-body="false"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item prop="state">
          <el-select
            clearable
            v-model="libraryForm.state"
            placeholder="选择状态"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in stateData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="companyUnit">
          <el-select
            clearable
            v-model="libraryForm.companyUnit"
            placeholder="选择单位类型"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in companyTypeData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="libraryQuery">查询</el-button>
        </el-form-item>
      </el-form>

      <div class="tablese">
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          v-loading="loading"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :min-width="item.minWidth"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>

          <el-table-column prop="state" align="center" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.state == false">未到岗</span>
              <span v-if="scope.row.state == true">到岗</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-center mt-4">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="libraryForm.page"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </Modal>
</template>

<script>
import { getEresList } from "@/api/eres";
import dayjs from "dayjs";
import Modal from "@/components/Modal/modal.vue";
export default {
  components: {
    Modal,
  },
  props: {
    userSiteData: {
      type: Object,
      defaule: () => {},
    },
    modelTitle: {
      type: String,
      defaule: "值班人员",
    },
  },
  data() {
    return {
      libraryForm: {
        page: 1,
        size: 5,
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: "",
        state: "",
        companyUnit: "",
        siteId: null,
        companyName: "",
      },
      loading: false,
      stateData: [
        {
          label: "到岗",
          value: 1,
        },
        {
          label: "未到岗",
          value: 0,
        },
      ],
      companyTypeData: [
        {
          label: "建设单位",
          value: "build",
        },
        {
          label: "监理单位",
          value: "monitor",
        },
        {
          label: "施工单位",
          value: "construct",
        },
        {
          label: "一般单位",
          value: "current",
        },
        {
          label: "设计单位",
          value: "design",
        },
        {
          label: "第三方监测单位",
          value: "third",
        },
        {
          label: "勘察单位",
          value: "survey",
        },
        {
          label: "施工总承包单位",
          value: "contracting",
        },
      ],
      columns: [
        { prop: "siteName", label: "工点", minWidth: 80 },
        { prop: "companyUnit", label: "单位类型", minWidth: 80 },
        { prop: "companyName", label: "单位名称", minWidth: 80 },
        { prop: "rotaName", label: "值班人员", minWidth: 80 },
        { prop: "mobile", label: "联系电话", minWidth: 80 },
      ],
      total: 0,
      tableData: [],
    };
  },
  created() {},
  mounted() {
    // this.getListDatas();
  },
  methods: {
    handelOpen() {
      this.$nextTick(() => {
        this.$refs.modalUser.handleOpen();
      });
      this.getListDatas();
    },
    //获取表格数据
    getListDatas() {
      this.loading = true;
      let params = {
        startDate: this.libraryForm.startDate + " 00:00:00",
        endDate: this.libraryForm.startDate + " 23:59:59",
        state: this.libraryForm.state,
        companyUnit: this.libraryForm.companyUnit,
        siteId: this.userSiteData.id,
        rotaType: 4,
        companyName: this.libraryForm.companyName,
        page: this.libraryForm.page,
        size: this.libraryForm.size,
      };
      getEresList(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          this.total = res.data.size;
        } else {
          this.loading = false;
        }
      });
    },
    // 查询
    libraryQuery() {
      this.getListDatas(4);
    },
    // 分页
    handleCurrentChange(val) {
      this.libraryForm.page = val;
      this.getListDatas(4);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
  color: #fff !important;
}
::v-deep .el-tabs__item.is-active {
  color: #409eff !important;
}
::v-deep .el-input--prefix .el-input__inner {
  color: #fff;
  background-color: #18467f;
  border: 0px solid #dcdfe6;
}
::v-deep .el-input__inner {
  color: #fff;
  background-color: #18467f;
  border: 0px solid #dcdfe6;
}
</style>
