<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
             label-width="120px" class="demo-model">
      <el-form-item label="实际培训时间" prop="trainDate">
        <el-date-picker
            style="width: 100%"
            v-model="ruleForm.trainDate"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="参与人数" prop="joinCount">
        <el-input v-model="ruleForm.joinCount" placeholder="参与人数"></el-input>
      </el-form-item>
      <el-form-item label="附件" prop="accessory">
        <Uploader @uploader="uploader"></Uploader>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="finishModalClose">取 消</el-button>
      <el-button type="primary" @click="submitFinish">确 定</el-button>
    </div>

  </div>
</template>

<script>
import Uploader from "./Uploader";
import {trainInfoUpdate} from "@/api/stand";
export default {
  name: "finishTrain",
  props : {
    finishObject : {}
  },
  components : {
    Uploader
  },
  data(){
    return{
      ruleForm : {
        joinCount : '',
        trainDate : '',
        accessory : {
          file_name : '',
          link : '',
        }
      },
      rules : {
        joinCount: [
          { required: true, message: '请输入参与人数', trigger: 'blur' },
        ],
        trainDate: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
        accessory: [
          { required: true, message: '请上传附件', trigger: 'change' }
        ],
      },
    }
  },
  created() {

  },
  methods : {
    finishModalClose(){
      this.$emit('finishModalClose')
    },
    uploader(event){
      this.ruleForm.accessory.file_name = event.originalName;
      this.ruleForm.accessory.link = event.uri;
    },
    submitFinish(){
      this.$refs['ruleForm'].validate((valid) => {
        if(valid){
          if(this.ruleForm.trainDate && !this.ruleForm.trainDate.includes(' 00:00:00')){
            this.ruleForm.trainDate = this.ruleForm.trainDate + ' 00:00:00';
          }
          // this.ruleForm.trainDate = this.ruleForm.trainDate + ' 00:00:00';
          this.ruleForm.id = this.finishObject.id;
          trainInfoUpdate(this.ruleForm).then(res => {
            if(res.code == 0){
              this.$message.success('操作成功');
              this.$emit('successFinish');
            }else{
              this.$message.error(res.msg);
            }
          })
        }else{
          this.$message.error('请正确填写')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
