<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" :title="title">
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="120px"
      class="demo-model"
      style="width: 80%"
    >
      <el-form-item label="工点名称" prop="siteName">
        <el-input v-if="model.siteName" v-model="model.siteName"></el-input>
        <el-cascader
          v-else
          v-model="model.siteName"
          ref="cascaderTree"
          :options="lineSiteData"
          :props="props"
          :show-all-levels="false"
          @change="handleLineSiteChange"
          :disabled="disabled"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="所属建管部" prop="superviseName">
        <el-input v-model="model.superviseName" disabled></el-input>
      </el-form-item>
      <el-form-item label="线路名称" prop="lineName">
        <el-input v-model="model.lineName" disabled></el-input>
      </el-form-item>

      <el-form-item label="分部名称" prop="branchName">
        <el-input v-model="model.branchName" disabled></el-input>
      </el-form-item>

      <el-form-item label="名称" prop="teamName">
        <el-input v-model="model.teamName" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="人数" prop="numOfPeople" v-if="title == '编辑'">
        <el-input
          v-model="model.numOfPeople"
          placeholder="请输入人数"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="负责人" prop="captainName">
        <el-popover
          ref="popover"
          placement="bottom"
          :disabled="disabled"
          trigger="click"
        >
          <el-tree
            ref="tree"
            :props="props"
            :data="tree"
            node-key="uuid"
            :load="loadNode"
            lazy
            accordion
            @current-change="deptTreeCurrentChangeHandle"
            @node-click="handleTreeCheck"
          >
          </el-tree>
        </el-popover>
        <el-input
          v-model="model.captainName"
          v-popover:popover
          :readonly="true"
          placeholder="请选择负责人"
          style="cursor: pointer"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="captainPhone">
        <el-input
          v-model="model.captainPhone"
          readonly
          placeholder="请输入联系电话"
        ></el-input>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue'
import {
  addPartTeamInfo,
  updatePatrTeamInfo,
  getDeptAccountList,
} from '@/api/eres'
import { queryDictByLabel, queryDictNodesById } from '@/api/dict'
import { queryOrgPageList, queryDeepDataByOrgId } from '@/api/org'
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: '新增',
      disabled: false,
      tree: [],

      model: {
        siteName: '',
        superviseName: '',
        lineName: '',
        branchName: '',
        teamName: '',
        captainName: '',
        captainPhone: '',
      },
      props: {
        children: 'children',
        label: 'name',
        value: 'uuid',
        multiple: false,
        isLeaf: 'leaf',
      },
      dictOption: {
        grouping: [],
        department: [], // 职位
        vendor: [], // 单位
        district: [], // 所在区
      },
      loading: false,
      rules: {
        siteName: [
          { required: true, message: '请输入工点名称', trigger: 'blur' },
        ],
        teamName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        captainName: [
          { required: true, message: '请选择负责人', trigger: 'blur' },
        ],
        // captainPhone: [
        //   {
        //     pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
        //     message: '请输入合法手机号',
        //     trigger: 'blur',
        //   },
        // ],
      },
    }
  },
  props: {
    lineSiteData: {
      type: Array,
      default: function() {
        return []
      },
    },
    orgTree: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {},
  methods: {
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data)
              this.$forceUpdate()
            }
          })
        }
      })
    },
    // 线路工点树选择
    handleLineSiteChange() {
      let nodesInfo = this.$refs['cascaderTree'].getCheckedNodes()[0]
      this.model.siteName = nodesInfo.pathLabels[3]
      this.model.siteUuid = nodesInfo.path[3]
      this.model.lineName = nodesInfo.pathLabels[0]
      this.model.lineUuid = nodesInfo.path[0]
      this.model.branchName = nodesInfo.pathLabels[1]
      this.model.branchUuid = nodesInfo.path[1]
      this.model.superviseName = nodesInfo.pathLabels[2]
      this.model.superviseUuid = nodesInfo.path[2]
    },
    deptTreeCurrentChangeHandle(data, node) {
      console.log(node)
      this.model.captainName = data.name
      this.model.captainUuid = data.uuid
      this.model.captainPhone = data.contactPhone
      this.$set(this.model, this.model.captainName, data.name)
    },
    handleTreeCheck(node) {
      if (node.children == undefined) {
        // 判断当前选中的数据是站点层的话 关闭当前选择框
        this.$refs.popover.showPopper = false
      }
    },
    // 加载叶子节点
    async loadNode(node, resolve) {
      console.log(node)
      if (node.level === 0) {
        return resolve(this.orgTree)
      }
      if (node.level != 0 && node.data.vendorOrDept == 0) {
        resolve(node.data.children)
      } else if (node.data.vendorOrDept == 1) {
        // 查询部门下的人员
        const params = {
          deptUuId: node.data.uuid,
        }
        let res = await getDeptAccountList(params)
        if (res) {
          const data = []
          for (let item of res) {
            data.push({
              name: item.realName,
              uuid: item.accountUuid,
              contactPhone: item.contactPhone,
              leaf: true,
            })
          }
          resolve(data)
        } else {
          resolve([])
        }
      } else {
        return false
      }
    },
    // 查询单位列表
    queryOrgData() {
      const params = {
        category: '-',
        name: '-',
        pageNo: 1,
        pageSize: 1000,
      }
      queryOrgPageList(params).then((res) => {
        if (res.code == 0) {
          this.dictOption.vendor = res.data.records
        }
      })
    },
    // 根据指定id查询部门
    handleVendorChange(val) {
      // 清空数据
      this.dictOption.department = []
      this.model.departmentId = ''
      const tempObj = this.dictOption.vendor.find((item) => item.id == val)
      queryDeepDataByOrgId({ companyId: tempObj.uuid }).then((res) => {
        if (res.code == 0) {
          this.dictOption.department = res.data
        }
      })
    },
    handleAdd(record) {
      if (record) {
        this.title = '编辑'
        this.disabled = true
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)))
      } else {
        this.title = '新增'
        this.disabled = false
        this.model = {}
      }
      this.tree = JSON.parse(JSON.stringify(this.orgTree))
      this.handleEdit()
    },
    handleEdit() {
      this.$refs.formModal.handleOpen()
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true
          console.log(this.model)
          if (this.model.id) {
            const params = {
              id: this.model.id,
              data: this.model,
            }
            updatePatrTeamInfo(params).then((res) => {
              if (res.code == 0) {
                this.loading = false
                this.$message.success('操作成功')
                this.$refs.formModal.handleClose()
                this.$emit('formSubmit')
              }
            })
          } else {
            addPartTeamInfo(this.model).then((res) => {
              if (res.code == 0) {
                this.loading = false
                this.$message.success('操作成功')
                this.$refs.formModal.handleClose()
                this.$emit('formSubmit')
              }
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
      // this.$refs.formModal.handleClose();
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.el-cascader {
  width: 100%;
}
</style>
