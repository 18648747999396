<template>
  <div class="statistics-page">
    <IndexBox :hideTitle="true">
      <div class="page-container w-full h-full pt-4 flex flex-col">
        <div class="top flex justify-between">
          <div class="left box">
            <div class="box-header">
              <p class="title">在建工地基本数据</p>
            </div>
            <ul class="box-wrapper flex justify-between mt-4">
              <li class="flex flex-col text-center" @click="textlinList">
                <p class="num">
                  {{
                    Object.keys(dataList).length == 0
                      ? "0"
                      : dataList.lineNumber
                  }}
                </p>
                <p class="title">线路总数</p>
              </li>
              <el-divider direction="vertical"></el-divider>
              <li class="flex flex-col text-center" @click="kilometre">
                <p class="num">0</p>
                <p class="title">公里数</p>
              </li>
              <el-divider direction="vertical"></el-divider>
              <li class="flex flex-col text-center" @click="site">
                <p class="num">
                  {{
                    Object.keys(dataList).length == 0
                      ? "0"
                      : dataList.siteNumber
                  }}
                </p>
                <p class="title">工点数</p>
              </li>
              <el-divider direction="vertical"></el-divider>
              <li class="flex flex-col text-center" @click="sitebuild">
                <p class="num">
                  {{
                    Object.keys(dataList).length == 0
                      ? "0"
                      : dataList.siteBuildingNumber
                  }}
                </p>
                <p class="title">在建工点</p>
              </li>
              <el-divider direction="vertical"></el-divider>
              <li class="flex flex-col text-center" @click="urban">
                <p class="num">0</p>
                <p class="title">广州市内工点</p>
              </li>
              <el-divider direction="vertical"></el-divider>
              <li class="flex flex-col text-center" @click="outside">
                <p class="num">0</p>
                <p class="title">广州市外工点</p>
              </li>
            </ul>
          </div>
          <div class="right box">
            <div class="box-header">
              <p class="title">其他数据</p>
            </div>
            <ul class="box-wrapper flex justify-between mt-4">
              <li class="flex flex-col text-center" @click="duty">
                <p class="num">
                  {{ Object.keys(dataList).length == 0 ? "0" : dataList.role }}
                </p>
                <p class="title">今日值班人数</p>
              </li>
              <el-divider direction="vertical"></el-divider>
              <li class="flex flex-col text-center" @click="parameter">
                <p class="num">
                  {{
                    Object.keys(dataList).length == 0 ? "0" : dataList.threeTail
                  }}
                </p>
                <p class="title">三本台账</p>
              </li>
              <el-divider direction="vertical"></el-divider>
              <li class="flex flex-col text-center" @click="control">
                <p class="num">
                  {{
                    Object.keys(dataList).length == 0
                      ? "0"
                      : dataList.drainager2
                  }}
                </p>
                <p class="title">防汛作战图</p>
              </li>
              <el-divider direction="vertical"></el-divider>
              <li class="flex flex-col text-center" @click="chectable">
                <p class="num">0</p>
                <p class="title">三防安全检查表</p>
              </li>
              <el-divider direction="vertical"></el-divider>
              <li class="flex flex-col text-center" @click="report">
                <p class="num">0</p>
                <p class="title">三防工作报告</p>
              </li>
              <el-divider direction="vertical"></el-divider>
              <li class="flex flex-col text-center" @click="emergenc">
                <p class="num">
                  {{ Object.keys(dataList).length == 0 ? "0" : dataList.plan }}
                </p>
                <p class="title">应急预案</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="middle flex flex-1 mt-4">
          <div class="middle-wrapper w-full h-full flex justify-between">
            <div class="box middle-item-1 flex flex-col">
              <div class="box-header">
                <p class="title">统计信息</p>
              </div>
              <div class="charts-wrapper flex-1 flex">
                <div class="charts-01 flex-1" ref="charts01"></div>
                <el-divider direction="vertical"></el-divider>
                <div class="charts-02 flex-1" ref="charts02"></div>
              </div>
            </div>
            <div class="box middle-item-2 flex flex-col">
              <div class="box-header">
                <p class="title">重要设备</p>
              </div>
              <div class="charts-wrapper flex-1" ref="charts03"></div>
            </div>
            <div class="box middle-item-3 flex flex-col">
              <div class="box-header">
                <p class="title">应急队伍情况</p>
              </div>
              <div
                class="flex flex-col flex-1"
                v-for="(item, index) in dataList.teamWorkerMapDtos"
                :key="index"
                @click="team"
              >
                <div
                  class="
                    item
                    flex-1
                    mt-4
                    flex
                    justify-between
                    items-center
                    px-6
                  "
                >
                  <span class="lable">{{ item.type }}</span>
                  <span class="num">{{ item.teamNumber }}</span>
                  <span>
                    共
                    <i>{{ item.peopleNumber }}</i>
                    人
                  </span>
                </div>
                <!-- <div
                  class="item flex-1 mt-4 flex justify-between items-center px-6"
                >
                  <span class="lable">兼职救援队</span>
                  <span class="num">0</span>
                  <span>
                    共
                    <i>0</i>
                    人
                  </span>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="bottom flex flex-1 justify-between mt-4">
          <div class="box bottom-item-1 mr-2 flex flex-col">
            <div class="box-header flex items-center">
              <p class="title">应急响应统计</p>
              <div class="date-picker ml-4">
                <el-date-picker
                  v-model="responseDate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  @change="daterang"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="charts-wrapper flex-1">
              <div class="charts-04 w-full h-full" ref="charts04"></div>
            </div>
          </div>
          <div class="box bottom-item-2 ml-2 flex flex-col">
            <div class="box-header">
              <p class="title">重要抢险设备</p>
            </div>
            <div class="charts-wrapper flex-1">
              <div class="charts-05 w-full h-full" ref="charts05"></div>
            </div>
          </div>
        </div>
      </div>
    </IndexBox>
    <Modal
      ref="modalDetail"
      :modal="false"
      width="800"
      :title="modelTitle"
      @closeDialog="closeDialog"
    >
      <div
        class="trrwr"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="gotrow" v-show="card">
          <el-table
            border
            :data="cardList"
            style="width: 100%"
            :header-cell-style="getRowClass"
          >
            <el-table-column type="index" label="序号" width="150">
            </el-table-column>
            <el-table-column prop="name" label="线路名称"> </el-table-column>
          </el-table>
          <div class="pagination-wrapper flex justify-center mt-4">
            <el-pagination
              style="z-index:999"
              background
              layout="prev, pager, next"
              :total="page.count"
              :page-count="page.total"
              :page-size="page.pageSize"
              :current-page="page.pageNo"
              @size-change="haneChange"
              @current-change="handleChange"
            >
            </el-pagination>
          </div>
        </div>
        <div class="gotrow" v-show="caressd">
          <el-table
            border
            :data="caressdList"
            style="width: 100%"
            :header-cell-style="getRowClass"
          >
            <el-table-column type="index" label="序号" width="150">
            </el-table-column>
            <el-table-column prop="name" label="工点名称"> </el-table-column>
          </el-table>
          <div class="pagination-wrapper flex justify-center mt-4">
            <el-pagination
              style="z-index:999"
              background
              layout="prev, pager, next"
              :total="page.count"
              :page-count="page.total"
              :page-size="page.pageSize"
              :current-page="page.pageNo"
              @size-change="hanecaressdChange"
              @current-change="handlecaressdChange"
            >
            </el-pagination>
          </div>
        </div>
        <div class="Station" v-show="cares">
          <el-table
            border
            :data="caresList"
            style="width: 100%"
            :header-cell-style="getRowClass"
          >
            <el-table-column type="index" label="序号" width="150">
            </el-table-column>
            <el-table-column prop="name" label="车站名称"> </el-table-column>
          </el-table>
          <div class="pagination-wrapper flex justify-center mt-4">
            <el-pagination
              style="z-index:999"
              background
              layout="prev, pager, next"
              :total="page.count"
              :page-count="page.total"
              :page-size="page.pageSize"
              :current-page="page.pageNo"
              @size-change="haneStationChange"
              @current-change="handlegetChange"
            >
            </el-pagination>
          </div>
        </div>
        <div class="duty" v-show="text">
          <el-table
            border
            :data="textList"
            style="width: 100%"
            :header-cell-style="getRowClass"
          >
            <el-table-column type="index" label="序号" width="150">
            </el-table-column>
            <el-table-column prop="dutyWorkerName" label="值班人员名称">
            </el-table-column>
          </el-table>
          <div class="pagination-wrapper flex justify-center mt-4">
            <el-pagination
              style="z-index:999"
              background
              layout="prev, pager, next"
              :total="page.count"
              :page-count="page.total"
              :page-size="page.pageSize"
              :current-page="page.pageNo"
              @size-change="hanedutyChange"
              @current-change="handleonChange"
            >
            </el-pagination>
          </div>
        </div>
        <div class="plan" v-show="emer">
          <el-table
            border
            :data="emerList"
            style="width: 100%"
            :header-cell-style="getRowClass"
          >
            <el-table-column type="index" label="序号" width="150">
            </el-table-column>
            <el-table-column prop="planName" label="应急预案名称">
            </el-table-column>
          </el-table>
          <div class="pagination-wrapper flex justify-center mt-4">
            <el-pagination
              style="z-index:999"
              background
              layout="prev, pager, next"
              :total="page.count"
              :page-count="page.total"
              :page-size="page.pageSize"
              :current-page="page.pageNo"
              @size-change="haneplanChange"
              @current-change="handleonplanChange"
            >
            </el-pagination>
          </div>
        </div>

        <div class="tod" v-show="table">
          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="getRowClass"
          >
            <el-table-column prop="line" label="线路" width="180">
            </el-table-column>
            <el-table-column prop="site" label="站点" width="180">
            </el-table-column>
            <el-table-column prop="vendor" label="公司"> </el-table-column>
            <el-table-column prop="realName" label="姓名"> </el-table-column>
            <el-table-column prop="phone" label="电话"> </el-table-column>
          </el-table>
          <div class="pagination-wrapper flex justify-center mt-4">
            <el-pagination
              style="z-index:999"
              background
              layout="prev, pager, next"
              :total="page.count"
              :page-count="page.total"
              :page-size="page.pageSize"
              :current-page="page.pageNo"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
        <div class="todss" v-show="imgeur">
          <el-table
            :data="tableList"
            style="width: 100%"
            :header-cell-style="getRowClass"
          >
            <el-table-column prop="lineName" label="线路" width="180">
            </el-table-column>
            <el-table-column prop="siteName" label="站点" width="180">
            </el-table-column>
            <el-table-column prop="" label="防汛作战图">
              <template slot-scope="scope">
                <img
                  :src="scope.row.imageUrl"
                  style="width: 75px; height: 75px; margin-right: 10px"
                />
              </template>
            </el-table-column>
            <el-table-column prop="note" label="名称"> </el-table-column>
          </el-table>
          <div class="pagination-wrapper flex justify-center mt-4">
            <el-pagination
              style="z-index:999"
              background
              layout="prev, pager, next"
              :total="page.count"
              :page-count="page.total"
              :page-size="page.pageSize"
              :current-page="page.pageNo"
              @size-change="handlecontrolChange"
              @current-change="handcontrolChange"
            >
            </el-pagination>
          </div>
        </div>
        <div class="rescue" v-show="rescue">
          <el-table
            :data="rescueList"
            style="width: 100%"
            :header-cell-style="getRowClass"
          >
            <el-table-column prop="workerName" label="姓名" width="180">
            </el-table-column>
            <el-table-column prop="workerEducation" label="学历" width="180">
            </el-table-column>
            <el-table-column prop="" label="照片">
              <template slot-scope="scope">
                <img
                  :src="scope.row.workerImg"
                  style="width: 75px; height: 75px; margin-right: 10px"
                />
              </template>
            </el-table-column>
            <el-table-column prop="workRoleName" label="职位">
            </el-table-column>
            <el-table-column prop="workerPhone" label="电话"> </el-table-column>
          </el-table>
          <div class="pagination-wrapper flex justify-center mt-4">
            <el-pagination
              style="z-index:999"
              background
              layout="prev, pager, next"
              :total="page.count"
              :page-count="page.total"
              :page-size="page.pageSize"
              :current-page="page.pageNo"
              @size-change="handleresceChange"
              @current-change="handresceChange"
            >
            </el-pagination>
          </div>
        </div>
        <div class="adret" v-show="adret">
          <el-table
            :data="answer"
            style="width: 100%"
            :header-cell-style="getRowClass"
          >
            <el-table-column prop="" label="类型" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.type.label }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="originalAlertMessage"
              label="响应"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="" label="等级" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.currentLevel.intro }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-wrapper flex justify-center mt-4">
            <el-pagination
              style="z-index:999"
              background
              layout="prev, pager, next"
              :total="page.count"
              :page-count="page.total"
              :page-size="page.pageSize"
              :current-page="page.pageNo"
              @size-change="handleanswerChange"
              @current-change="handanswerChange"
            >
            </el-pagination>
          </div>
        </div>
        <div class="butting" v-show="butting">
          <p>数据对接中</p>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox.vue";
import {
  dataCenter,
  linesite,
  drainager,
  buildingSite,
  infoLine,
  infoSite,
} from "@/api/map.js";
import {
  getonduty,
  queryWorkerListByPage,
  queryRescueTeamMemberList,
  curdayWorker,
} from "@/api/eres.js";
import { getryById } from "@/api/stand.js";
import { getonseList } from "@/api/response.js";
import { EmergencyPageList } from "@/api/emergency.js";
import Modal from "@/components/Modal/modal.vue";
import * as echarts from "echarts";

export default {
  components: {
    IndexBox,
    Modal,
  },
  data() {
    return {
      responseDate: [],
      dataList: {},
      data2: {
        total: 70,
        type: 2,
        text: 0,
        data: [
          { value: 0, name: "已完成" },
          { value: 0, name: "未完成" },
        ],
      },
      paredr: {
        startDate: "-",
        endDate: "-",
      },
      rester: {
        datarest: [],
        oerted: [],
        werder: [],
        terwer: [],
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      params: {
        category: "-",
        name: "-",
        page: 1,
        size: 10,
        parent: "-",
        state: "-",
        uuid: "-",
        workLevel: "-",
      },
      modelTitle: "",
      cardList: [],
      textList: [],
      caressdList: [],
      card: true,
      caressd: false,
      text: false,
      boxcard: false,
      emer: false,
      tableData: [],
      emerList: [],
      table: false,
      rescue: false,
      rescueList: [],
      loading: false,
      imgeur: false,
      tableList: [],
      caresList: [],
      cares: false,
      adret: false,
      answer: [],
      titlesr: "",
      param: {},
      butting: false,
    };
  },
  created() {
    this.centerers(this.paredr);
  },
  mounted() {
    // if (this.responseDate == []) {
    //   this.paredr.startDate = "-";
    //   this.paredr.endDate = "-";
    // } else {
    //   console.log(this.responseDate);
    // }
    dataCenter(this.paredr).then((res) => {
      console.log(res);
      this.data2.data[0].value = res.data.planInfoMapDto.old;
      this.data2.data[1].value = res.data.planInfoMapDto.news;
      this.data2.text = res.data.planInfoMapDto.all;
      this.initInfoCharts(this.$refs.charts02, this.data2, "应急演练总数");
      console.log(this.data2.data[0].value);
      res.data.responseStatisticMapDtos.forEach((item) => {
        this.rester.terwer.push(item.jurisdiction);
        this.rester.datarest.push(item.responseStatisticDtos[0].count);
        this.rester.oerted.push(item.responseStatisticDtos[1].count);
        this.rester.werder.push(item.responseStatisticDtos[2].count);
      });
      console.log(this.rester);
      this.initResponseCharts(this.rester);
    });
    const data1 = {
      total: 60,
      type: 1,
      data: [
        { value: 0, name: "已整改" },
        { value: 0, name: "整改中" },
        { value: 0, name: "整改超时" },
      ],
    };

    this.initInfoCharts(this.$refs.charts01, data1, "排查问题总数");

    console.log(this.data2);

    this.initEquipmentCharts();

    this.initRescueEquipmentCharts();
  },
  methods: {
    /**
     * 渲染统计信息饼图方法
     * @param {Dom} el echarts渲染的dom容器
     * @param {Object} data 图表数据
     * @param {String} title 副标题
     */
    initInfoCharts(el, data, title) {
      let color = [];
      if (data.type == 1) {
        color = ["#72C040", "#1791FF", "#E23C39"];
      } else {
        color = ["#72C040", "#E23C39"];
      }
      const charts = echarts.init(el);
      const option = {
        color: color,
        title: {
          show: true,
          textAlign: "center",
          left: "48.5%",
          top: "25%",
          text: data.text,
          textStyle: {
            color: "#fff",
            fontSize: 24,
          },
          subtext: title,
          subtextStyle: {
            color: "rgba(255,255,255,0.6)",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "70%",
          left: "center",
          orient: "vertical",
          textStyle: {
            color: "#fff",
          },
          icon: "circle",
        },
        series: [
          {
            name: "信息统计",
            type: "pie",
            center: ["50%", "35%"],
            radius: ["40%", "60%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: data.data,
          },
        ],
      };
      charts.setOption(option);

      charts.on("click", (param) => {
        console.log(param);
        if (param.name == "已完成") {
          console.log(22222);
          getryById({
            date: "-",
            label: "-",
            lineId: "-",
            page: this.page.pageNo,
            size: this.page.pageSize,
            state: 1,
          }).then((res) => {
            console.log(res);
          });
        } else if (param.name == "未完成") {
          getryById({
            date: "-",
            label: "-",
            lineId: "-",
            page: this.page.pageNo,
            size: this.page.pageSize,
            state: 0,
          }).then((res) => {
            console.log(res);
          });
        }
      });
    },
    /**
     * 渲染重要设备图表方法
     * @param {*} data 图表数据
     */
    initEquipmentCharts(data) {
      console.log(data);
      const charts = echarts.init(this.$refs.charts03);
      const option = {
        color: ["#1791FF"],
        grid: {
          left: 0,
          right: 0,
          top: "10%",
          bottom: 0,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: ["桥式起重机", "架桥机", "汽车吊", "塔吊", "龙门吊"],
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#43A5E9",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#43A5E9",
            },
          },
        },
        series: [
          {
            data: [0, 0, 0, 0, 0],
            type: "bar",
            barWidth: "30%",
            label: {
              show: true,
              textBorderWidth: 0,
              position: "top",
              color: "#fff",
            },
          },
        ],
      };
      charts.setOption(option);
      charts.on("click", (param) => {
        console.log(param);
        this.modelTitle = param.name;
        this.kimoe();
      });
    },
    initResponseCharts(data) {
      const charts = echarts.init(this.$refs.charts04);
      const option = {
        color: ["#1791FF", "#FAAD13", "#72C040"],
        legend: {
          show: true,
          right: 0,
          data: ["防暴雨内涝应急响应", "防汛应急响应", "防台风应急响应"],
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          left: 0,
          right: 0,
          top: "10%",
          bottom: 0,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data.terwer,
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#43A5E9",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#43A5E9",
            },
          },
        },
        series: [
          {
            data: data.werder,
            type: "bar",
            barWidth: "15%",
            emphasis: {
              focus: "series",
            },
            name: "防暴雨内涝应急响应",
          },
          {
            data: data.oerted,
            type: "bar",
            barWidth: "15%",
            emphasis: {
              focus: "series",
            },
            name: "防汛应急响应",
          },
          {
            data: data.datarest,
            type: "bar",
            barWidth: "15%",
            emphasis: {
              focus: "series",
            },
            name: "防台风应急响应",
          },
        ],
      };
      charts.setOption(option);
      charts.on("click", (param) => {
        console.log(param);
        this.charterw(param);
        this.param = param;
        this.loading = true;
        this.$refs.modalDetail.handleOpen();
      });
    },
    initRescueEquipmentCharts() {
      const charts = echarts.init(this.$refs.charts05);
      const option = {
        color: [
          "#72C040",
          "#1791FF",
          "#4058C0",
          "#3BDDC4",
          "#9051F0",
          "#FAAD13",
          "#43A5E9",
        ],
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "10%",
          left: "60%",
          orient: "vertical",
          textStyle: {
            color: "#fff",
          },
          itemGap: 20,
        },
        series: [
          {
            // name: "Access From",
            type: "pie",
            center: ["30%", "50%"],
            radius: ["40%", "75%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 0, name: "龙吸水(500m2/H)" },
              { value: 0, name: "柴油泵自吸(330m2/H)" },
              { value: 0, name: "应急抢险水泵" },
              { value: 0, name: "液压动力站" },
              { value: 0, name: "橡皮艇" },
              { value: 0, name: "抢险车辆" },
              { value: 0, name: "发电机" },
            ],
          },
        ],
      };
      charts.setOption(option);
      charts.on("click", (param) => {
        console.log(param, param.data.name);
        this.modelTitle = param.data.name;
        this.kimoe();
      });
    },
    centerers(paredr) {
      dataCenter(paredr).then((res) => {
        console.log(res);
        this.dataList = res.data;
        this.data2.data[0].value = res.data.planInfoMapDto.old;
        this.data2.data[1].value = res.data.planInfoMapDto.news;
        console.log(this.data2.data[0].value);
      });
    },
    daterang() {
      console.log(this.responseDate);
      this.paredr.startDate = this.responseDate[0];
      this.paredr.endDate = this.responseDate[1];
      this.centerers(this.paredr);
    },
    //点击线路
    textlinList() {
      this.loading = true;
      this.$refs.modalDetail.handleOpen();
      this.modelTitle = "线路总数";
      this.params.category = 44;
      infoLine().then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.card = true;
          this.text = false;
          this.caressd = false;
          this.cares = false;
          this.emer = false;
          this.table = false;
          this.imgeur = false;
          this.rescue = false;
          this.adret = false;
          this.butting = false;
          // this.page.count = res.data.count;
          // this.page.pageSize = res.data.size;
          // this.page.total = res.data.total;
          // this.cardList = res.data.records;

          this.page.count = res.data.length;
          let newTable = [];
          let newTableTwo = [];
          res.data.forEach((element, index) => {
            if (newTable.length == 10) {
              newTableTwo.push(newTable);
              newTable = [];
            }
            newTable.push(element);
            if (index == res.data.length - 1) {
              newTableTwo.push(newTable);
            }
          });
          console.log(
            newTable[this.page.pageNo - 1],
            newTable,
            newTableTwo,
            "newTable"
          );
          this.cardList = newTableTwo[this.page.pageNo - 1];

          this.loading = false;
        }
      });
    },
    //车站数
    site() {
      this.loading = true;
      this.modelTitle = "工点总数";
      this.$refs.modalDetail.handleOpen();
      this.params.category = 46;
      infoSite().then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.card = false;
          this.text = false;
          this.caressd = false;
          this.cares = true;
          this.emer = false;
          this.table = false;
          this.imgeur = false;
          this.rescue = false;
          this.adret = false;
          this.butting = false;
          // this.page.count = res.data.count;
          // this.page.pageSize = res.data.size;
          // this.page.total = res.data.total;
          // this.caresList = res.data.records;

          this.page.count = res.data.length;
          let newTable = [];
          let newTableTwo = [];
          res.data.forEach((element, index) => {
            if (newTable.length == 10) {
              newTableTwo.push(newTable);
              newTable = [];
            }
            newTable.push(element);
            if (index == res.data.length - 1) {
              newTableTwo.push(newTable);
            }
          });
          console.log(
            newTable[this.page.pageNo - 1],
            newTable,
            newTableTwo,
            "newTable"
          );
          this.caresList = newTableTwo[this.page.pageNo - 1];

          this.loading = false;
        }
      });
    },
    //在建工点数
    sitebuild() {
      this.loading = true;
      this.modelTitle = "在建工点总数";
      this.$refs.modalDetail.handleOpen();
      // this.params.category = 47;
      // this.params.state = 51;
      buildingSite(this.params).then((res) => {
        console.log(res, this.page.pageNo - 1, this.page.pageNo);
        if (res.code == 0) {
          this.card = false;
          this.text = false;
          this.caressd = true;
          this.cares = false;
          this.emer = false;
          this.table = false;
          this.imgeur = false;
          this.rescue = false;
          this.adret = false;
          this.butting = false;

          this.page.count = res.data.length;
          let newTable = [];
          let newTableTwo = [];
          res.data.forEach((element, index) => {
            if (newTable.length == 10) {
              newTableTwo.push(newTable);
              newTable = [];
            }
            newTable.push(element);
            if (index == res.data.length - 1) {
              newTableTwo.push(newTable);
            }
          });
          console.log(
            newTable[this.page.pageNo - 1],
            newTable,
            newTableTwo,
            "newTable"
          );
          this.caressdList = newTableTwo[this.page.pageNo - 1];
          // this.page.pageSize = res.data.size;
          // this.page.total = res.data.total;
          this.loading = false;
        }
      });
    },
    //值班人员
    duty() {
      this.loading = true;
      this.modelTitle = "值班人员";
      this.$refs.modalDetail.handleOpen();
      curdayWorker({ page: this.page.pageNo, size: this.page.pageSize }).then(
        (res) => {
          console.log(res);
          if (res.code == 0) {
            this.card = false;
            this.text = true;
            this.caressd = false;
            this.cares = false;
            this.emer = false;
            this.table = false;
            this.imgeur = false;
            this.rescue = false;
            this.adret = false;
            this.butting = false;
            this.textList = res.data.records;
            this.page.count = res.data.count;
            this.page.pageSize = res.data.size;
            this.page.total = res.data.total;
            this.loading = false;
          }
        }
      );
    },
    //三本台账
    parameter() {
      this.loading = true;
      this.modelTitle = "三本台账";
      this.$refs.modalDetail.handleOpen();
      queryWorkerListByPage({
        lineId: "-",
        siteId: "-",
        vendorId: "-",
        departmentId: "-",
        grouping: "-",
        type: 98,
        realName: "-",
        page: this.page.pageNo,
        size: this.page.pageSize,
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.card = false;
          this.text = false;
          this.caressd = false;
          this.cares = false;
          this.emer = false;
          this.table = true;
          this.imgeur = false;
          this.rescue = false;
          this.adret = false;
          this.butting = false;
          this.tableData = res.data.records;
          this.page.count = res.data.count;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.total;
          // this.caresList = res.data.records;

          // this.page.count = res.data.count;
          // this.page.pageSize = res.data.size;
          // this.page.total = res.data.total;
          this.loading = false;
        }
      });
    },
    getRowClass() {
      return "background:#081742;color:#ffffff;";
    },

    handleSizeChange(val) {
      this.page.pageSize = val;
      this.parameter();
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.parameter();
    },
    haneChange(val) {
      this.page.pageSize = val;
      this.params.page = this.page.pageNo;
      this.params.size = this.page.pageSize;
      this.textlinList();
    },
    handleChange(val) {
      this.page.pageNo = val;
      this.params.page = this.page.pageNo;
      this.params.size = this.page.pageSize;
      this.textlinList();
    },
    haneStationChange(val) {
      this.page.pageSize = val;
      this.params.page = this.page.pageNo;
      this.params.size = this.page.pageSize;
      this.site();
    },
    handlegetChange(val) {
      this.page.pageNo = val;
      this.params.page = this.page.pageNo;
      this.params.size = this.page.pageSize;
      this.site();
    },
    hanedutyChange(val) {
      this.page.pageSize = val;

      this.duty();
    },
    handleonChange(val) {
      this.page.pageNo = val;

      this.duty();
    },
    haneplanChange(val) {
      this.page.pageSize = val;

      this.emergenc();
    },
    handleonplanChange(val) {
      this.page.pageNo = val;

      this.emergenc();
    },
    hanecaressdChange(val) {
      this.page.pageSize = val;
      this.params.page = this.page.pageNo;
      this.params.size = this.page.pageSize;
      this.sitebuild();
    },
    handlecaressdChange(val) {
      this.page.pageNo = val;
      this.params.page = this.page.pageNo;
      this.params.size = this.page.pageSize;
      this.sitebuild();
    },
    handlecontrolChange(val) {
      this.page.pageSize = val;
      this.control();
    },
    handcontrolChange(val) {
      this.page.pageNo = val;
      this.control();
    },
    handleresceChange(val) {
      this.page.pageSize = val;
      this.team();
    },
    handresceChange(val) {
      this.page.pageNo = val;
      this.team();
    },
    handleanswerChange(val) {
      this.page.pageSize = val;
      this.charterw(this.param);
    },
    handanswerChange(val) {
      this.page.pageNo = val;
      this.charterw(this.param);
    },
    closeDialog() {
      this.page = {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      };
    },
    //防汛
    control() {
      this.loading = true;
      this.modelTitle = "防汛作战图";
      this.$refs.modalDetail.handleOpen();
      drainager({
        lineId: "-",
        state: "-",
        date: "-",
        keyword: "-",
        page: this.page.pageNo,
        size: this.page.pageSize,
        type: 2,
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.card = false;
          this.text = false;
          this.caressd = false;
          this.cares = false;
          this.emer = false;
          this.table = false;
          this.imgeur = true;
          this.rescue = false;
          this.adret = false;
          this.butting = false;
          this.tableList = res.data.records;
          this.page.count = res.data.count;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.total;
          this.loading = false;
        }
      });
    },
    //应急
    emergenc() {
      this.loading = true;
      this.modelTitle = "应急预案";
      this.$refs.modalDetail.handleOpen();
      EmergencyPageList({
        // vendorType: "-",
        // planName: "-",
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.emerList = res.data.records;
          this.card = false;
          this.text = false;
          this.caressd = false;
          this.cares = false;
          this.emer = true;
          this.table = false;
          this.imgeur = false;
          this.adret = false;
          this.rescue = false;
          this.butting = false;
          this.page.count = res.data.count;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.total;
          this.loading = false;
        }
      });
    },
    //应急救援队
    team() {
      this.loading = true;
      this.modelTitle = "队伍人员详情";
      this.$refs.modalDetail.handleOpen();
      queryRescueTeamMemberList({
        teamId: "-",
        postId: "-",
        name: "-",
        page: this.page.pageNo,
        size: this.page.pageSize,
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.card = false;
          this.text = false;
          this.caressd = false;
          this.cares = false;
          this.emer = false;
          this.table = false;
          this.imgeur = false;
          this.rescue = true;
          this.adret = false;
          this.butting = false;
          this.rescueList = res.data.records;
          this.page.count = res.data.count;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.total;
          this.loading = false;
        }
      });
    },
    //应急统计
    charterw(param) {
      if (param.seriesName == "防暴雨内涝应急响应") {
        this.modelTitle = "防暴雨内涝应急响应";
        getonseList({
          uuid: "-",
          type: 189,
          currentLevel: "-",
          publisherId: "-",
          startDate: "-",
          endDate: "-",
          page: this.page.pageNo,
          size: this.page.pageSize,
        }).then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.card = false;
            this.text = false;
            this.caressd = false;
            this.cares = false;
            this.emer = false;
            this.table = false;
            this.imgeur = false;
            this.rescue = false;
            this.adret = true;
            this.answer = res.data.records;
            this.page.count = res.data.count;
            this.loading = false;
          }
        });
      } else if (param.seriesName == "防台风应急响应") {
        this.modelTitle = "防台风应急响应";
        getonseList({
          uuid: "-",
          type: 27,
          currentLevel: "-",
          publisherId: "-",
          startDate: "-",
          endDate: "-",
          page: this.page.pageNo,
          size: this.page.pageSize,
        }).then((res) => {
          if (res.code == 0) {
            this.answer = res.data.records;
            this.page.count = res.data.count;
            this.card = false;
            this.text = false;
            this.caressd = false;
            this.cares = false;
            this.emer = false;
            this.table = false;
            this.imgeur = false;
            this.rescue = false;
            this.adret = true;
            this.loading = false;
          }
        });
      } else if (param.seriesName == "防汛应急响应") {
        this.modelTitle = "防汛应急响应";
        getonseList({
          uuid: "-",
          type: 28,
          currentLevel: "-",
          publisherId: "-",
          startDate: "-",
          endDate: "-",
          page: this.page.pageNo,
          size: this.page.pageSize,
        }).then((res) => {
          if (res.code == 0) {
            this.answer = res.data.records;
            this.page.count = res.data.count;
            this.card = false;
            this.text = false;
            this.caressd = false;
            this.cares = false;
            this.emer = false;
            this.table = false;
            this.imgeur = false;
            this.rescue = false;
            this.adret = true;
            this.loading = false;
          }
        });
      }
    },
    kimoe() {
      this.card = false;
      this.text = false;
      this.caressd = false;
      this.cares = false;
      this.emer = false;
      this.table = false;
      this.imgeur = false;
      this.rescue = false;
      this.adret = false;
      this.butting = true;
      this.$refs.modalDetail.handleOpen();
    },
    kilometre() {
      this.kimoe();
      this.modelTitle = "公里数";
    },
    urban() {
      this.kimoe();
      this.modelTitle = "广州市内工点";
    },
    outside() {
      this.kimoe();
      this.modelTitle = "广州市外工点";
    },
    chectable() {
      this.kimoe();
      this.modelTitle = "三防安全检查表";
    },
    report() {
      this.kimoe();
      this.modelTitle = "三防工作报告";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-divider--vertical {
  height: auto;
  opacity: 0.1;
}
::v-deep .el-input__inner {
  background: #18467f;
  border: 1px solid #18467f;
  .el-range-input {
    background: transparent;
    color: #fff;
  }
}

.statistics-page {
  height: 100%;
  width: 100%;
  padding: 10px;

  .page-container {
    .top {
      .left {
        width: calc(50% + 50px);
        height: 140px;
        margin-right: 10px;
      }

      .right {
        width: calc(50% - 50px);
        height: 140px;
        margin-left: 10px;
      }
    }

    .middle {
      .middle-wrapper {
        .middle-item-1 {
          width: 635px;
        }

        .middle-item-2 {
          width: 700px;
        }

        .middle-item-3 {
          width: 485px;

          .item {
            background: rgba(67, 165, 233, 0.05);

            span {
              color: #fff;
              font-size: 14px;
            }

            .num {
              font-size: 24px;
              font-weight: 600;
            }

            i {
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
            }
          }
        }
      }
    }

    .bottom {
      .bottom-item-1 {
        width: 1300px;
      }

      .bottom-item-2 {
        width: 540px;
      }
    }

    .num {
      font-size: 24px;
      font-family: Arial;
      font-weight: bold;
      color: #ffffff;
    }

    .title {
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.6;
    }
  }
}

.box {
  background: #081742;
  border: 1px solid #0e2553;
  padding: 21px 20px;

  .box-header {
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #43a5e9;
    }
  }

  .box-container {
  }
}
.box-card {
  background: #081742;
  color: #ffffff;

  height: 500px;

  overflow-y: auto;
}
::v-deep .el-table__expanded-cell {
  background-color: #081742 !important;
  color: #ffffff;
}

::v-deep .el-table tr {
  background-color: #081742 !important;
  color: #ffffff;
}
::v-deep .el-table__body {
  background: #081742 !important;
}
::v-deep .el-table tbody tr:hover > td {
  background-color: rgba($color: #03487b, $alpha: 0.5) !important;
}
::v-deep .el-table–enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: transparent;
}
::v-deep .el-table {
  // 去掉table的border start----------------------------
  border: 0;
  th,
  tr,
  td {
    border: 0;
  }
  &::before {
    height: 0px;
  }
  &::after {
    width: 0;
  }
  .el-table__fixed:before {
    height: 0;
  }
  // 去掉table的border end----------------------------
}

.rescue {
  height: 500px;

  overflow-y: auto;
  .rescue_one {
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    margin-top: 33px;
    .rescleft {
      width: 75px;
      height: 75px;
      img {
        width: 80%;
        height: 100%;
      }
    }
    .rescright {
      width: 200px;
      .topes {
        display: flex;
        justify-content: space-between;
      }
      .bottom {
        margin-top: 28px;
      }
    }
  }
}
.butting {
  p {
    font-size: 14px;
    text-align: center;
    color: #ffffff;
  }
}
.text-center {
  cursor: pointer;
}
::v-deep .el-table__empty-block {
  background: #081742 !important;
}
::v-deep .el-table {
  height: 520px;
  overflow: auto;
  background: #081742 !important;
}
::v-deep .el-date-editor .el-range-separator {
  color: #fff;
}
</style>
