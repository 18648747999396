<template>
    <div style="height: 90%">
      <div class="config-head" style="margin-bottom: 10px;width: 30%;padding-left: 7%;box-sizing: border-box">
        <el-input v-model="batchName" size="small" disabled></el-input>
      </div>
      <div class="configPeopleMain">
        <div class="config-box">
          <p class="config-box-title">可同步设备</p>
          <div class="config-box-button">
            <!-- <el-button :class="{ customButton: true, 'DL-btn': !isChongqing }" v-if="showHideenDelete" size="mini" @click="deletePeople"><i class="el-icon-delete"></i> 批量删除</el-button> -->
            <el-button
              icon="el-icon-refresh"
              size="small"
              :disabled="SynDisabled"
              :class="{ customButton: true, 'DL-btn': !isChongqing }"
              @click="getsynchronization"
            >同步
            </el-button
            >
            <!-- <kt-button :icon="syncButton === scope.row.id ? 'el-icon-loading' : 'el-icon-refresh'" :label="syncButton === scope.row.id ? '正在同步中' : '同步'" :class="{ customButton: true, 'DL-btn': !isChongqing }"/> -->
          </div>
          <div class="mainPart">
            <div class="configPeopleCon ketbsb">
              <!--表格内容栏-->
              <el-table
                ref="dataTable"
                height="610"
                style="overflow: auto"
                :data="peopleList"
                row-key="id"
                v-loading="peopleLoading"
                @select="handleSelectionChange"
                @select-all="handleSelectionChange"
                :element-loading-text="$t('action.loading')"
              >
                <el-table-column width="55" align="center" :reserve-selection="true" type="selection"></el-table-column>
                <el-table-column label="序号" width="55" align="center" type="index"></el-table-column>
                <el-table-column
                  prop="gateName"
                  header-align="center"
                  min-width="60"
                  align="center"
                  show-overflow-tooltip
                  label="设备名称"
                ></el-table-column>
                <el-table-column
                  prop="gateCode"
                  show-overflow-tooltip
                  header-align="center"
                  min-width="80"
                  align="center"
                  label="设备编号"
                ></el-table-column>
              </el-table>
              <!-- </div> -->
            </div>
          </div>
        </div>
  
        <div class="config-result">
          <p class="config-box-title">同步结果</p>
          <el-progress :text-inside="true" :stroke-width="20" :percentage="nowNum"></el-progress>
          <p>{{ nowPerson }}</p>
          <ul v-show="isShowList" class="big_ul">
            <li v-for="(i, indexI) in allPeopleList" :key="indexI">
              <p class="listTitle">{{ i.personName }}</p>
              <ul class="small_ul" v-if="i.childrenStatus && i.childrenStatus.length">
                <li v-for="(item, index) in i.childrenStatus" :key="index" class="small_li">
                  <span class="device_name">{{ item.name }}</span>
                  <el-tooltip effect="dark" :content="item.msg" placement="top">
                    <i :class="getImg(item.status)"></i>
                  </el-tooltip>
                  <!-- <span>{{ item.msg }}</span> -->
                </li>
              </ul>
              <p class="listTitle" v-if="i.noDevice">{{ `${i.personName}当前没有可同步的设备了` }}</p>
            </li>
          </ul>
          <ul v-show="errorList.length">
            <li v-for="(item, index) in errorList" style="list-style : none"
                :key="index">{{ `${item.name} 通过失败，原因：${item.msg}` }}</li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
//   import {findGateDeviceByDeptId} from "../../../http/moudules/sys/user";
  
  export default {
    name: "batchSync",
    props : {
      batchName : {
        default : ""
      },
      selections : {
        default : () => []
      },
      deptId : {
        default : null
      }
    },
    data(){
      return {
        peopleList : [],
        peopleLoading : false,
        whetDisabled : false,
        synchronLoading : false,
        awaitLoading : false,
        synchronList : [],
        isChongqing: null,
        SynDisabled : true,
        selectionsList : [],
        synchronSelection : [],
        ids : [],
        multiple : {},
        nowNum : 0,
        nowPerson: "",
        isShowList: false,
        allPeopleList : [],
        errorList : []
      }
    },
    mounted() {
      this.isChongqing = sessionStorage.getItem("roleType") == "role_dalian" ? false : true;
      this.getLeftTableData();
    },
    methods:{
      getLeftTableData(){
        this.$api.user.findGateDeviceByDeptId({deptId : this.deptId}).then(res => {
          if (res.code == 200){
            this.peopleList = res.data;
          }
        })
      },
      //可同步设备选中
      handleSelectionChange(val) {
        this.selectionsList = val
        this.SynDisabled = this.selectionsList.length > 0 ? false : true;
      },
      //可同步设备选中
      addAwaitSyn(row) {
        this.$api.user
          .addUserGate(this.multiple)
          .then((res) => {
            if (res.code == 200) {
              this.$refs.dataTable.toggleRowSelection(row, false)
              this.getAwaitSynchroPerson(this.syncData)
              this.peopleDialogList(this.syncData)
              this.peopleList.forEach((el) => {
                if (el.id == this.syncData.id) {
                  el.isSelect = false
                }
              })
            }
          })
          .catch((error) => [
            this.multiple.forEach((item) => {
              this.peopleList.forEach((el) => {
                if (el.id == item.userId) {
                  this.$refs.dataTable.toggleRowSelection(el, false)
                }
              })
            })
          ])
      },
      // 同步按钮
      async getsynchronization() {
        for (let i = 0; i < this.selections.length; i++) {
          let obj = {
            personName : this.selections[i].personName,
            childrenStatus : [],
            noDevice : false,
          }
          for (let j = 0; j < this.selectionsList.length; j++) {
            await this.$api.user.addUserGate({
              userId: this.selections[i].id,
              gateCode: this.selectionsList[j].gateCode
            }).then((res) => {
              if (res.code == 200) {
                if (!res.data) {
                  this.$api.face.saveOrUpdateUser({
                    gateCode: this.selectionsList[j].gateCode,
                    userId: this.selections[i].id
                  }).then((result) => {
                    if(result.code == 200) {
                      let successObj = {
                        name: this.selections[i].personName,
                        msg: result.msg,
                        status: "resolved"
                      }
                      obj.childrenStatus.push(successObj)
                    }else{
                      let errorOjb = {
                        name: this.selections[i].personName,
                        msg: result.msg,
                        status: "rejected"
                      }
                      this.errorList.push(errorOjb);
                    }
                  })
                  if (j === this.selectionsList.length - 1) {
                    obj.noDevice = true;
                  }
                } else {
                  obj.noDevice = false;
                  let errorOjb = {
                    name: this.selections[i].personName,
                    msg: res.msg,
                    status: "rejected"
                  }
                  this.errorList.push(errorOjb);
                }
              } else {
                obj.noDevice = false;
                let errorOjb = {
                  name: this.selections[i].personName,
                  msg: res.msg,
                  status: "rejected"
                }
                this.errorList.push(errorOjb);
              }
            })
          }
          this.allPeopleList.push(obj);
        }
        this.nowNum = 100;
        console.log(this.allPeopleList)
        let index = this.allPeopleList.findIndex(item => item.childrenStatus.length > 0);
        console.log(index)
        this.isShowList = index !== -1 ? true : false;
      },
      handleSelectionSynchronized(selection) {
        console.log(selection, "selection")
        if (selection.length > 0) {
          this.whetDisabled = false
        } else {
          this.whetDisabled = true
        }
        this.ids = selection.map((item) => item.id)
        this.synchronSelection = selection
      },
      getImg(status) {
        if (status == "resolved") {
          return "el-icon-circle-check color_green"
        } else if (status == "pending") {
          return "el-icon-loading"
        } else if (status == "rejected") {
          return "el-icon-circle-close color_red"
        } else {
          return "el-icon-video-play"
        }
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  ::v-deep.configPeopleMain {
    display: flex;
    justify-content: space-around;
    height: 100%;
    .config-box {
      display: flex;
      flex-direction: column;
      padding: 15px;
      width: 28%;
      height: 100%;
      border: 1px solid #7073ff;
      border-radius: 20px;
      .config-box-title {
        font-size: 16px;
        font-weight: bolder;
        margin: 0 0 8px 0;
      }
      .config-box-button {
        text-align: right;
        padding-top: 10px;
        .el-button [class*="el-icon-"] + span {
          margin-left: 0;
        }
      }
      > .mainPart {
        height: calc(100% - 40px) !important;
        .el-tree {
          width: 200px;
          box-sizing: border-box;
          margin-right: 0 !important;
        }
      }
    }
    .configPeopleCon {
      margin-left: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      > .el-table {
        height: calc(100% - 60px) !important;
        border-radius: 20px;
        overflow: auto;
      }
    }
    .mainPart {
      .el-table {
        height: calc(100% - 10px) !important;
      }
    }
  }
  .config-result{
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 45%;
    height: 100%;
    border: 1px solid #7073ff;
    border-radius: 20px;
    .config-box-title {
      font-size: 16px;
      font-weight: bolder;
      margin: 0 0 8px 0;
    }
    .big_ul {
      list-style: none;
      .small_ul {
        display: flex;
        flex-wrap: wrap;
  
        .small_li {
          width: 30%;
          margin-left: 1%;
          text-align: left;
          display: flex;
          align-items: center;
          i {
            cursor: pointer;
            position: relative;
            margin-left: 6px;
            top: 1px;
          }
          > span {
            display: inline-block;
            max-width: calc(100% - 30px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  </style>
  