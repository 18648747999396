<template>
  <div>
    <el-tree
      class="lineTree"
      ref="tree"
      :data="lineTree"
      :props="lineProps"
      @check="handleCheckChange"
      :show-checkbox="showCheckBox"
      :default-expand-all="defaultExpandAll"
      :default-expanded-keys="expandKey"
      :default-checked-keys="checkKey"
      @node-click="nodeClick"
      node-key="id"
    >
      <!-- <span slot-scope="{ node, data }"> -->
      <span slot-scope="{ data }">
        <span :title="data.name">{{ data.name }} </span>
      </span>
    </el-tree>
  </div>
</template>

<script>
export default {
  props: {
    lineTree: {
      type: Array,
      default: [],
    },
    lineProps: {
      type: Object,
      default: {},
    },
    /* 节点是否可被选择，默认为不能选择 */
    showCheckBox: {
      type: Boolean,
      default: false,
    },
    /* 默认展开节点 */
    expandKey: {
      type: Array,
      default: [],
    },
    /* 是否默认展开所有节点 */
    defaultExpandAll: {
      type: Boolean,
      default: false,
    },
    /* 默认选中节点 */
    checkKey: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      siteIdList: [],
      siteId: "",
    };
  },
  methods: {
    handleCheckChange(data, checkedNodes) {
      this.siteIdList = checkedNodes.checkedKeys;
      this.$emit("handleCheckChange", this.siteIdList);
    },
    nodeClick(data) {
      this.$emit("nodeClick", data.id);
    },
  },
};
</script>

<style></style>
