<template>
  <!-- 措施 -->
  <div class="page-container bg-white p-4 h-full relative">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item prop="riskLevelCode">
            <el-cascader
              v-model="siteUuidList"
              clearable
              :options="treeData"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="handleLineSiteChange"
              placeholder="请选择工点"
            ></el-cascader>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryModal.riskTypeCode"
              clearable
              placeholder="风险类别"
              @change="getSonList"
            >
              <el-option
                v-for="item of riskTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryModal.riskSubTypeCode"
              clearable
              placeholder="风险子类"
            >
              <el-option
                v-for="item of riskSonList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="queryModal.riskName"
              clearable
              placeholder="风险名称"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryModal.riskLevelCode"
              clearable
              placeholder="风险控制等级"
            >
              <el-option
                v-for="item of riskLevelList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div style="margin-bottom: 10px">
      <el-radio-group v-model="radio" @change="radioChange">
        <el-radio-button :label="1">待处置</el-radio-button>
        <el-radio-button :label="2">已处置</el-radio-button>
      </el-radio-group>
    </div>
    <div class="table-wrapper">
      <el-table v-loading="tableLoading" :data="tableData" style="width: 100%">
        <el-table-column type="index" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column
          prop="lineName"
          align="center"
          label="线路"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="branchName"
          align="center"
          label="分部/标段"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="siteName"
          align="center"
          label="工点"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="riskTypeName"
          align="center"
          label="风险类别"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="riskSubTypeName"
          align="center"
          label="风险子类"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="riskName"
          align="center"
          label="风险名称"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="mileageRange"
          align="center"
          label="风险里程"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="riskDescribe"
          align="center"
          label="风险描述"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="riskLevelName"
          align="center"
          label="风险控制等级"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="yearQuarter"
          align="center"
          label="年度/季度"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="160" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" v-show="radio == 2" @click="edit(scope.row)"
              >查看</el-button
            >
            <el-button type="text" v-show="radio == 1" @click="edit(scope.row)"
              >风险处置</el-button
            >
            <!-- <el-button type="text" @click="del(scope.row)" style="color: red"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        :total="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        layout=" prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      width="880px"
      :title="title"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <AddPage
        ref="addPageData"
        @CloseData="CloseData"
        :pageData="pageData"
        :submitType="submitType"
      ></AddPage>
    </el-dialog>
  </div>
</template>

<script>
import AddPage from "./modules/addPage";
// 字典
import { getDictData } from "@/api/dict";
// 列表,删除
import { measureList, measureFinishList, getMsg } from "@/api/risk";

export default {
  components: {
    AddPage,
  },
  data() {
    return {
      dialogVisible: false,
      queryModal: {
        riskTypeCode: "",
        riskSubTypeCode: "",
        riskName: "",
        riskLevelCode: "",
        siteUuidList: [],
      },
      siteUuidList: "",
      riskTypeList: [],
      riskLevelList: [],
      riskSonList: [],
      tableData: [],
      tableLoading: false,
      pageLoading: false,
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true,
      },
      title: "",
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      radio: 1,
      //新增
      submitType: "",
      pageData: {},
      gradeList: [],
      // 详情弹窗
      detailPageDataVisible: false,
      detailPageData: {},
      examinePageDataVisible: false,
      examinePageData: {},
    };
  },
  mounted() {
    this.queryLineSiteTreeData();
    this.getData();
    this.getDictList();
  },
  methods: {
    // 字典  unitType 单位类型
    getDictList() {
      // 风险类别
      getDictData("riskType").then((res) => {
        if (res.code == 0) {
          this.riskTypeList = res.data;
        }
      });
      // 风险控制等级
      getDictData("riskControlLevel").then((res) => {
        if (res.code == 0) {
          this.riskLevelList = res.data;
        }
      });
    },
    getSonList(val) {
      // 风险子类
      this.queryModal.riskSubTypeCode = "";
      getDictData(val).then((res) => {
        if (res.code == 0) {
          this.riskSonList = res.data;
        }
      });
    },
    // 查询
    search() {
      this.getData();
    },
    riskDetail() {
      this.detailPageDataVisible = true;
    },
    examine() {
      this.examinePageDataVisible = true;
    },

    // change切换
    radioChange() {
      this.page.pageNo = 1;
      this.page.pageSize = 10;
      this.getData();
    },
    // 获取列表数据
    getData() {
      this.tableLoading = true;
      let params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.queryModal,
      };
      if (this.radio == 1) {
        measureList(params).then((res) => {
          console.log(res);
          if (res.data) {
            this.tableData = res.data.list;
            this.page.total = res.data.total;
          }
          this.tableLoading = false;
        });
      } else if (this.radio == 2) {
        measureFinishList(params).then((res) => {
          console.log(res);
          if (res.data) {
            this.tableData = res.data.list;
            this.page.total = res.data.total;
          }
          this.tableLoading = false;
        });
      }
    },
    // 编辑
    edit(row) {
      this.title = "措施处置";
      // this.loadform("edit", row);
      getMsg(row.riskDeafUuid).then((res) => {
        this.pageData = res.data;
        this.dialogVisible = true;
      });
    },
    // 线路工点树数据查询
    queryLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
      console.log(lineSiteData);
    },
    // 级联选择器事件
    handleLineSiteChange(record) {
      console.log(record);
      this.queryModal.siteUuidList = [];
      for (let item of record) {
        this.queryModal.siteUuidList.push(item[item.length - 1]);
      }
      console.log(this.queryModal.siteUuidList);
    },
    // loadform(type, row) {
    //   this.dialogVisible = true;
    //   this.submitType = type;
    //   const rowinfo = { ...row };
    //   this.pageData = rowinfo;
    // },

    // 关闭回调
    CloseData() {
      this.dialogVisible = false;
      this.getData();
    },
    handleClose() {
      this.$refs.addPageData.reset();
      this.dialogVisible = false;
    },
    // 关闭回调
    detailHandleClose() {
      this.detailPageDataVisible = false;
    },
    // 关闭回调
    examineHandleClose() {
      this.examinePageDataVisible = false;
    },
    // 分页
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 11;
}
::v-deep .el-table__body-wrapper {
  overflow: auto;
  max-height: 500px;
}

.page-container {
  .handle-wrapper {
    min-width: 240px;
    max-width: 30vw;
    height: 60vh;
    position: absolute;
    top: 10%;
    left: 20px;
    background: rgba(255, 255, 255, 1);
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
    border-radius: 0px 20px 20px 0px;
    padding: 20px;
    z-index: 6;

    .tree-wrapper {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }

    .toggle-btn {
      width: 28px;
      height: 240px;
      position: absolute;
      right: 0px;
      top: 50%;
      margin-top: -120px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }

    .toggle-btn-close {
      transform: rotate(180deg);
    }

    .toggle-btn-open {
      transform: rotate(0deg);
    }
  }

  .handle-wrapper-hide {
    left: -212px;
    background: transparent;
    box-shadow: none;
  }

  .handle-wrapper-show {
    left: 0;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #43a5e9;
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
  }
}
.btnStyle {
  margin-bottom: 22px;
}
.hasLeft {
  margin-left: 10px;
}
::v-deep .el-dialog__body {
  max-height: 700px;
  overflow: auto;
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #e6e7ec;
  text-align: center;
}

::v-deep .el-dialog__title {
  font-weight: bold;
}
</style>
