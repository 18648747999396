<template>
  <div>
    <el-table :data="tableData" style="width: 100%" class="tablescoll" border>
      <el-table-column prop="lineSite" label="线路-工点">
        <template slot-scope="scope">
          <!-- @change="handleLineSiteChange" -->
          <el-cascader
            ref="linecas"
            placeholder="选择线路工点"
            v-model="scope.row.lineSite"
            :options="options"
            :props="props"
            filterable
            clearable
            @change="handleLineSiteChange($event, scope.row, scope)"
          ></el-cascader>
        </template>
      </el-table-column>
      <el-table-column prop="constructUnit" align="center" label="施工单位">
        <template slot-scope="scope">
          <el-select v-model="scope.row.constructUnit" placeholder="施工单位">
            <el-option
              v-for="item in builderData"
              :key="item.id"
              :label="item.companyName"
              :value="item.companyName"
            >
            </el-option>
          </el-select>
          <!-- <el-input v-model="scope.row.constructUnit"></el-input> -->
        </template>
      </el-table-column>
      <el-table-column prop="supervisionUnit" align="center" label="监理单位">
        <template slot-scope="scope">
          <el-select v-model="scope.row.supervisionUnit" placeholder="监理单位">
            <el-option
              v-for="item in supervisingData"
              :key="item.id"
              :label="item.companyName"
              :value="item.companyName"
            >
            </el-option>
          </el-select>
          <!-- <el-input v-model="scope.row.supervisionUnit"></el-input> -->
        </template>
      </el-table-column>
      <el-table-column
        prop="checkTime"
        align="center"
        label="检查时间"
        min-width="91px"
      >
        <template slot-scope="scope">
          <el-date-picker
            v-model="scope.row.checkTime"
            type="date"
            placeholder="检查时间"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <!-- <el-input v-model="scope.row.checkTime"></el-input> -->
        </template>
      </el-table-column>
      <el-table-column prop="checkStaffName" align="center" label="检查人员">
        <template slot-scope="scope">
          <el-input v-model="scope.row.checkStaffName"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="hiddenDangerType" align="center" label="隐患分类">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.hiddenDangerType"
            placeholder="隐患分类"
          >
            <el-option
              v-for="item in RiskData"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
          <!-- <el-input v-model="scope.row.hiddenDangerType"></el-input> -->
        </template>
      </el-table-column>
      <el-table-column
        prop="hiddenDangerQuestion"
        align="center"
        label="存在问题"
      >
        <template slot-scope="scope">
          <el-input v-model="scope.row.hiddenDangerQuestion"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="hiddenDangerDo" align="center" label="整改措施">
        <template slot-scope="scope">
          <el-input v-model="scope.row.hiddenDangerDo"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="correctStaffName"
        align="center"
        label="整改负责人"
      >
        <template slot-scope="scope">
          <el-input v-model="scope.row.correctStaffName"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="correctImageBefore"
        align="center"
        label="整改前照片"
      >
        <template slot-scope="scope">
          <!-- {{ scope.row.correctImageBefore }} -->
          <el-upload
            v-if="!scope.row.correctImageBefore"
            class="upload-demo"
            action="upload"
            :http-request="uploadImg"
            :file-list="fileList"
            :show-file-list="false"
          >
            <el-button
              size="small"
              class="el-icon-plus"
              @click="kkkk(scope)"
            ></el-button>
          </el-upload>
          <img
            class="img-class"
            v-else
            :src="scope.row.correctImageBefore"
            :alt="scope.row.correctImageBefore"
          />
          <!-- <el-input v-model="scope.row.correctImageBefore"></el-input> -->
        </template>
      </el-table-column>
      <el-table-column prop="correctState" align="center" label="整改情况">
        <template slot-scope="scope">
          <el-input v-model="scope.row.correctState"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="correctImageAfter"
        align="center"
        label="整改后照片"
      >
        <template slot-scope="scope">
          <el-upload
            v-if="!scope.row.correctImageAfter"
            class="upload-demo"
            action="upload"
            :http-request="uploadImg"
            :file-list="fileList"
            :show-file-list="false"
          >
            <el-button
              size="small"
              class="el-icon-plus"
              @click="kkkk(scope)"
            ></el-button>
          </el-upload>
          <img
            class="img-class"
            v-else
            :src="scope.row.correctImageAfter"
            :alt="scope.row.correctImageAfter"
          />
          <!-- <el-input v-model="scope.row.correctImageAfter"></el-input> -->
        </template>
      </el-table-column>
      <el-table-column
        prop="correctOverTime"
        align="center"
        label="整改完成时间"
        min-width="91px"
      >
        <template slot-scope="scope">
          <el-date-picker
            v-model="scope.row.correctOverTime"
            type="date"
            placeholder="整改完成时间"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <!-- <el-input v-model="scope.row.correctOverTime"></el-input> -->
        </template>
      </el-table-column>
    </el-table>
    <el-button
      size="small"
      type="primary"
      class="el-icon-plus"
      @click="tableAdd"
    ></el-button>
  </div>
</template>

<script>
import { upload } from "@/api/upload";
export default {
  props: {
    tableData: {
      type: Array,
      default: function() {
        return [];
      },
    },
    RiskData: {
      type: Array,
      default: () => [],
    },
    supervisingData: {
      type: Array,
      default: () => [],
    },
    builderData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
      },
      percent: 0,
      fileList: [],
      row: {},
    };
  },
  mounted() {
    this.getLineTree();
  },
  methods: {
    kkkk(row) {
      this.row = row;
      console.log(row);
    },
    // 图片上传的loading
    async uploadImg(args) {
      let _that = this;
      const loading = this.$loading({
        lock: true,
        text: "上传中...",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.percent = 0;
      const { file } = args;
      let res = {};
      let fn = upload;
      res = await fn(file, (e) => {
        this.percent = ((e.loaded / e.total) * 100) | 0;
        return args.onProgress({ percent: this.percent });
      }).catch((err) => {
        res.message = err;
      });
      if (res.code === 0) {
        loading.spinner = "el-icon-success";
        loading.text = "上传成功！";
        setTimeout(() => {
          loading.close();
        }, 800);
        // this.fileList.push(res.data);
        console.log(111);
        debugger;
        console.log(
          _that.tableData[_that.row.$index][_that.row.column.property]
        );
        if (_that.row.column.property == "correctImageBefore") {
          _that.tableData[_that.row.$index].correctImageBefore = res.data.uri;
        } else if (_that.row.column.property == "correctImageAfter") {
          _that.tableData[_that.row.$index].correctImageAfter = res.data.uri;
        }
      } else {
        this.message.error("上传失败");
        this.$emit("error", res.message);
      }
    },
    async getLineTree() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.options = lineSiteData;
      }
    },
    // 三、表格添加一行
    tableAdd() {
      let obj = {
        lineSite: "",
        constructUnit: "",
        supervisionUnit: "",
        checkTime: "",
        checkStaffName: "",
        hiddenDangerType: "",
        hiddenDangerQuestion: "",
        hiddenDangerDo: "",
        correctStaffName: "",
        correctImageBefore: "",
        correctState: "",
        correctImageAfter: "",
        correctOverTime: "",
      };
      this.tableData.push(obj);
    },
    // 线路工点树选择
    handleLineSiteChange(val, row, scope) {
      console.log(val, row, scope);
      this.tableData.forEach((item, index) => {
        if (index == scope.$index) {
          item.lineId = val[0];
          item.siteId = val[val.length-1];
          // this.options.forEach((element) => {
          //   if (element.id == val[0]) {
          //     console.log("线路名字", element);
          //     item.lineName = element.name;
          //     if (element.nodes && element.nodes.length > 0) {
          //       element.nodes.forEach((i) => {
          //         if (i.id == val[1]) {
          //           console.log("站点名字");
          //           item.siteName = i.name;
          //         }
          //       });
          //       // if (element.nodes.indexOf(val[1]) !== -1) {
          //       //   item.siteName =
          //       //     element.nodes[element.nodes.indexOf(val[1])].name;
          //       // }
          //     }
          //   }
          // });
        }
      });
      console.log(this.tableData);
      // return (row.lineId = val[0])((row.siteId = val[1]));
      // console.log(this.tableData);
      // console.log(val);
      // this.tableData.lineId = val[0];
      // this.tableData.siteId = val[1];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  font-size: 14px !important;
}

.tablescoll {
  /*定义滚动条的轨道颜色、内阴影及圆角*/
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(52, 73, 85, 0.5);
    background-color: rgba(52, 73, 85, 0.5);
    border-radius: 0px;
  }
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    display: block;
  }
}
.img-class {
  max-height: 80px;
}
</style>
