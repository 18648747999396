<template>
  <!-- 三防响应-历史响应-三防问题详情 -->
  <div>
    <el-form
      :model="checkForm"
      v-loading="formLoading"
      ref="checkForm"
      label-width="120px"
      class="demo-model"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item label="问题描述:" prop="hiddenQuestion">
            <el-input
              v-model="checkForm.hiddenQuestion"
              readonly
              placeholder="问题描述"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="线路:" prop="lineName">
            <el-input
              v-model="checkForm.lineName"
              readonly
              placeholder="线路"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工点:" prop="siteName">
            <el-input
              v-model="checkForm.siteName"
              readonly
              placeholder="工点"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="发布人:" prop="checkPeople">
            <el-input
              v-model="checkForm.checkPeople"
              readonly
              placeholder="发布人"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="发布时间:" prop="checkTime">
            <el-input
              v-model="checkForm.checkTime"
              readonly
              placeholder="发布时间"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态:" prop="correctiveApproveStatus">
            <el-input
              v-model="checkForm.correctiveApproveStatus"
              readonly
              placeholder="状态"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="问题照片:" prop="fileList">
            <div class="demo-image__preview" v-if="checkForm.uri">
              <el-image
                style="width: 100px; height: 100px"
                :src="checkForm.uri"
                :preview-src-list="checkForm.uriList"
              >
              </el-image>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="整改人:" prop="correctiveUsers">
            <el-input
              v-model="correctiveUsers"
              readonlya
              placeholder="整改人"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="整改时间:" prop="correctiveTime">
            <el-input
              v-model="checkForm.correctiveTime"
              readonly
              placeholder="整改时间"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="整改描述:" prop="correctiveDescription">
            <el-input
              v-model="checkForm.correctiveDescription"
              readonly
              placeholder="整改描述"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="整改照片:" prop="correctivePhotosUri">
            <div
              class="demo-image__preview"
              v-if="checkForm.correctivePhotosUri"
            >
              <el-image
                style="width: 100px; height: 100px"
                :src="checkForm.correctivePhotosUri"
                :preview-src-list="checkForm.correctivePhotosUriList"
              >
              </el-image>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="消除人:" prop="approveUser">
            <el-input
              v-model="approveUsers"
              readonly
              placeholder="消除人"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="消除时间:" prop="approveTime">
            <el-input
              v-model="checkForm.approveTime"
              readonly
              placeholder="消除时间"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="消除意见:" prop="rejectReason">
            <el-input
              v-model="checkForm.rejectReason"
              readonly
              placeholder="消除意见"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    hiddenData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      checkForm: {
        taskTitle: "",
        screeningTypeName: "",
        screeningCompanyName: "",
        leaderName: "",
        personLiable: "",
        uriList: [],
        correctivePhotosUriList: [],
      },
      formLoading: false,
    };
  },
  computed: {
    correctiveUsers() {
      return this.hiddenData.correctiveUser ? this.hiddenData.correctiveUser.name : "";
    },
    approveUsers() {
      return this.hiddenData.approveUser ? this.hiddenData.approveUser.name : "";
    }
  },
  watch: {
    hiddenData: {
      handler(val) {
        if (val) {
          this.checkForm = this.hiddenData;
          this.checkForm.uriList = [];
          this.checkForm.correctivePhotosUriList = [];
          this.checkForm.uriList.push(this.checkForm.uri);
          this.checkForm.correctivePhotosUriList.push(
            this.checkForm.correctivePhotosUri
          );
          switch (this.checkForm.correctiveApproveStatus) {
            case 0:
              this.checkForm.correctiveApproveStatus = "待整改";
              break;
            case 1:
              this.checkForm.correctiveApproveStatus = "待监理审批";
              break;
            case 2:
              this.checkForm.correctiveApproveStatus = "审批不通过";
              break;
            case 3:
              this.checkForm.correctiveApproveStatus = "完成";
              break;
          }
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
  },
};
</script>

<style></style>
