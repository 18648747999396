<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
            <el-cascader
              ref="linecas"
              placeholder="选择线路工点"
              v-model="lineSite"
              :options="options"
              :props="props"
              filterable
              clearable
              @change="getImage"
            ></el-cascader>
            <!-- <el-input
              v-model="queryModal.siteId"
              placeholder="请选择线路工点"
            ></el-input> -->
          </el-form-item>

          <el-form-item label="">
            <el-select
              v-model="queryModal.type"
              placeholder="请选择防汛关注重点类型"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="handleAdd" v-has="'emergency:add'"
            >新增</el-button
          >
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column align="center" prop="lineName" label="线路-工点" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.line.name }}-{{ scope.row.site.name }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="name" label="名称">
        </el-table-column>
        <el-table-column align="center" prop="types" label="类型">
        </el-table-column>
        <el-table-column
          align="center"
          prop="videoCount"
          label="摄像头安装数量"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="reportPersonName"
          label="上报人员"
        >
        </el-table-column>
        <el-table-column align="center" prop="reportTime" label="上报时间">
        </el-table-column>
        <!--    <el-table-column
          align="center"
          prop="uploadTime"
          label="上传时间"
          :formatter="dateFormat"
        >
        </el-table-column> -->
        <el-table-column align="center" label="操作" width="230">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="handleLook(scope.row)" -->
            <el-button
              type="text"
              @click="handleUpate(scope.row)"
              v-has="'emergency:update'"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="handleDelete(scope.row)"
              v-has="'emergency:del'"
              >删除</el-button
            >
            <el-popover placement="left" width="50" trigger="click">
              <el-button
                type="text"
                @click="handleDetail(scope.row)"
                v-has="'emergency:import'"
                >查看</el-button
              >
              <el-button
                type="text"
                @click="handleHistory(scope.row)"
                v-has="'emergency:import'"
                >历史操作</el-button
              >
              <el-button slot="reference" type="text" style="margin-left:10px"
                >更多</el-button
              >
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :width="'40%'"
      :before-close="handleClose"
      :close-on-click-modal="false"
      center
    >
      <el-form
        ref="ruleForm"
        :rules="rule"
        :model="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        v-loading="fromLoading"
      >
        <el-form-item label="选择上报类型:" prop="correctiveDescription">
          <el-radio-group v-model="ruleForm.type">
            <el-radio
              v-for="i in typeList"
              :key="i.id"
              :label="i.id"
              :value="i.value"
              >{{ i.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="线路-工点:" prop="correctiveDescription">
          <el-cascader
            ref="linecas"
            v-model="ruleForm.lineSite"
            :options="options"
            :props="props"
            filterable
            clearable
            @change="lineSiteChange"
            style="width:100%"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="名称:" prop="name">
          <el-input type="input" v-model="ruleForm.name"> </el-input>
        </el-form-item>
        <el-form-item label="具体位置:" prop="address">
          <el-input type="input" v-model="ruleForm.address"> </el-input>
        </el-form-item>
        <el-form-item label="摄像头:" prop="videos">
          <el-select
            v-model="ruleForm.videos"
            placeholder=""
            style="width:100%"
            multiple
          >
            <el-option
              v-for="item in videoList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!-- <el-input type="input" v-model="ruleForm.videos"> </el-input> -->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </span>
    </el-dialog>

    <el-dialog
      v-if="detailDialogVisible"
      :title="title"
      :visible.sync="detailDialogVisible"
      :width="'450px'"
      :before-close="cancel"
      :close-on-click-modal="false"
      center
    >
      <el-form
        ref="ruleForm"
        :rules="rule"
        :model="detailruleForm"
        label-width="75px"
        class="demo-ruleForm"
        v-loading="fromLoading"
      >
        <ul>
          <p class="details">
            防汛关注重点类型:{{ detailruleForm.type.label }}
          </p>
          <p class="details">
            线路-工点:{{ detailruleForm.line.name }}-{{
              detailruleForm.site.name
            }}
          </p>
          <p class="details">防汛关注重点名称:{{ detailruleForm.name }}</p>
          <p class="details">具体位置:{{ detailruleForm.address }}</p>
          <p class="details">
            摄像头:
            <span v-for="(item, i) in detailruleForm.videoVos" :key="i">
              <el-button type="text" size="mini"> [{{ item.name }}]</el-button>
            </span>
          </p>
          <p class="details">上报时间:{{ detailruleForm.reportTime }}</p>
          <p class="details">上报人员:{{ detailruleForm.reportPersonName }}</p>
        </ul>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">关 闭</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="历史操作记录"
      :visible.sync="historyDialog"
      append-to-body
      width="30%"
      center
    >
      <div class="content mb-4" v-for="(item, i) in historyList" :key="i">
        <div style="font-weight: bold; margin-bottom: 10px">
          <span>
            {{ item.createTime }}
          </span>
        </div>
        <div>
          <span>【{{ item.operatorName }}】 </span
          ><span>{{ item.operationName }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="historyCancel">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { tripleTree, siteVideos } from "@/api/map";

import {
  ProofNewList,
  ProofNewAdd,
  ProofNewDetail,
  ProofNewUpdate,
  ProofNewDelete,
  ProofNewHistory,
} from "@/api/proof";
import { getDictData } from "@/api/dict";

export default {
  data() {
    return {
      loading: false,
      deptList: [],
      queryModal: {
        siteId: "",
        type: "",
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      typeList: [],
      lineSite: [],
      options: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
      },
      ruleForm: {
        lineId: "",
        defLineName: "",
        siteId: "",
        deSiteName: "",
        type: null,
        videos: [],
        name: "",
        address: "",
        lineSite: [],
      },
      rule: {},
      title: "",
      fromLoading: false,
      dialogVisible: false,
      videoList: [], //摄像头的下拉
      pingxingArrList: [],
      //详情
      detailDialogVisible: false,
      detailruleForm: {},

      historyDialog: false,
      historyList: [],
    };
  },
  created() {
    this.loadData();
    this.queryLineSiteTree();
    this.getdeptdata();
  },
  methods: {
    cancel() {
      this.detailDialogVisible = false;
      this.detailruleForm = {};
    },
    handleHistory(row) {
      let params = {
        linkId: row.id,
      };
      ProofNewHistory(params).then((res) => {
        console.log(res);
        this.historyList = res.data;
        this.historyDialog = true;
      });
    },
    historyCancel() {
      this.historyDialog = false;
    },
    handleDetail(row) {
      ProofNewDetail(row.id).then((res) => {
        console.log(res);
        this.detailruleForm = Object.assign({}, res.data);
        this.detailDialogVisible = true;
      });
    },
    getSiteVideos(id) {
      let params = {
        siteId: id ? id : "-",
        type: "-",
      };
      siteVideos(params).then((res) => {
        if (res.code == 0) {
          this.videoList = res.data;
        }
      });
    },
    lineSiteChange(val) {
      console.log(val);
      // this.ruleForm.videos = [];
      this.videoList = [];
      this.ruleForm.lineId = val[0];
      this.ruleForm.siteId = val[val.length - 1];
      let line = this.pingxingArrList.find((i) => i.id == this.ruleForm.lineId);
      let site = this.pingxingArrList.find((i) => i.id == this.ruleForm.siteId);
      this.ruleForm.defLineName = line.name;
      this.ruleForm.deSiteName = site.name;
      //查询工点下的摄像头
      this.getSiteVideos(this.ruleForm.siteId);
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.title == "新增") {
            ProofNewAdd(this.ruleForm).then((res) => {
              console.log(res);
              if (res.code == 0) {
                this.handleClose();
                this.loadData();
                this.$message.success("操作成功!");
              }
            });
          } else {
            ProofNewUpdate(this.ruleForm).then((res) => {
              console.log(res);
              if (res.code == 0) {
                this.handleClose();
                this.loadData();
                this.$message.success("操作成功!");
              }
            });
          }
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.ruleForm = {
        lineId: "",
        defLineName: "",
        siteId: "",
        deSiteName: "",
        type: null,
        videos: [],
        name: "",
        address: "",
        lineSite: [],
      };
    },
    getImage(val) {
      console.log(val);
      this.queryModal.siteId = val[val.length - 1];
    },
    getdeptdata() {
      let label = "focusType";
      this.typeList = [];
      getDictData(label).then((res) => {
        if (res.code == 0) {
          res.data.forEach((element) => {
            this.typeList.push({
              label: element.label,
              value: element.code,
              id: element.id,
            });
          });
        }
      });
    },
    //时间格式化
    dateFormat(row, column, cellValue, index) {
      console.log(row, column, cellValue, index);
      let data = row[column.property];
      let dtime = new Date(data);
      const year = dtime.getFullYear();
      let month = dtime.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let day = dtime.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let hour = dtime.getHours();
      if (hour < 10) {
        hour = "0" + hour;
      }
      let minute = dtime.getMinutes();
      if (minute < 10) {
        minute = "0" + minute;
      }
      let second = dtime.getSeconds();
      if (second < 10) {
        second = "0" + second;
      }
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    //获取线路树
    queryLineSiteTree() {
      tripleTree({}).then((res) => {
        if (res.code == 0) {
          let lineSiteData = JSON.parse(JSON.stringify(res.data));
          this.options = lineSiteData;
          this.options.forEach((item) => {
            this.pingxingArrList.push(item);
            if (item.children && item.children !== null) {
              this.pingxingArr(item);
            }
          });
        }
      });
    },
    pingxingArr(data) {
      if (data.children && data.children !== null) {
        data.children.forEach((item) => {
          this.pingxingArrList.push(item);
          this.pingxingArr(item);
        });
      }
    },
    // 表格数据
    loadData() {
      this.loading = true;
      const params = {
        // uuid: "-",
        type: this.queryModal.type ? this.queryModal.type : null,
        siteId: this.queryModal.siteId ? this.queryModal.siteId : "",
        // alterFlag: "-",
        page: this.page.pageNo,
        size: this.page.pageSize,
      };
      ProofNewList(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          if (res.data.records && res.data.records.length > 0) {
            this.tableData = res.data.records;
            this.tableData.forEach((i) => {
              i.types = i.type.label ? i.type.label : "";
            });
          } else {
            this.tableData = [];
          }
          this.page.pageNo = res.data.current;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.total;
          this.page.count = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },
    //新增
    handleAdd() {
      this.dialogVisible = true;
      this.title = "新增";
    },
    // 编辑
    handleUpate(data) {
      console.log(data);
      this.title = "编辑";
      this.dialogVisible = true;

      ProofNewDetail(data.id).then((res) => {
        if (res.code == 0) {
          if (res.data.site.puuid == res.data.site.workAreaUuid) {
            this.ruleForm.lineSite = [
              res.data.line.id,
              this.pingxingArrList.find(
                (i) => i.uuid == res.data.site.branchUuid
              ).id,
              this.pingxingArrList.find((i) => i.uuid == res.data.site.puuid)
                .id,
              res.data.site.id,
            ];
          } else {
            this.ruleForm.lineSite = [
              res.data.line.id,
              this.pingxingArrList.find(
                (i) => i.uuid == res.data.site.branchUuid
              ).id,
              res.data.site.id,
            ];
          }
          this.getSiteVideos(res.data.site.id);
          this.ruleForm.type = res.data.type.id;
          this.ruleForm.name = res.data.name;
          this.ruleForm.address = res.data.address;
          this.ruleForm.id = res.data.id;
          this.ruleForm.defLineName = this.pingxingArrList.find(
            (i) => i.id == res.data.line.id
          ).name;
          this.ruleForm.deSiteName = this.pingxingArrList.find(
            (i) => i.id == res.data.site.id
          ).name;
          this.ruleForm.lineId = res.data.line.id;
          this.ruleForm.siteId = res.data.site.id;
          res.data.videoVos.forEach((i) => {
            this.ruleForm.videos.push(i.id);
          });
          console.log(this.ruleForm.lineSite);
        }
      });
    },
    //查询
    handleSearch() {
      this.title = "详情";
      this.loadData();
    },
    //删除
    handleDelete(row) {
      this.$confirm("是否删除该数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          ProofNewDelete(row.id).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.loadData();
            } else {
              this.$message.error("删除失败！");
            }
          });
        })
        .catch(() => {});
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  .handle-wrapper {
    min-width: 240px;
    max-width: 30vw;
    height: 60vh;
    position: absolute;
    top: 10%;
    left: 20px;
    background: rgba(255, 255, 255, 1);
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
    border-radius: 0px 20px 20px 0px;
    padding: 20px;
    z-index: 6;

    .tree-wrapper {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }

    .toggle-btn {
      width: 28px;
      height: 240px;
      position: absolute;
      right: 0px;
      top: 50%;
      margin-top: -120px;
      cursor: pointer;

      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }

    .toggle-btn-close {
      transform: rotate(180deg);
    }

    .toggle-btn-open {
      transform: rotate(0deg);
    }
  }

  .handle-wrapper-hide {
    left: -212px;
    background: transparent;
    box-shadow: none;
  }

  .handle-wrapper-show {
    left: 0;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #43a5e9;
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
  }
}
.details {
  margin-bottom: 10px;
}
</style>
