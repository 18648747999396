<template>
  <div style="wieth: 100%; height: 100%" class="flex flex-col conLeft">
    <Weather></Weather>
    <Info ref="Info"></Info>
    <EmergencyResponse ref="EmergencyResponse"></EmergencyResponse>
  </div>
</template>

<script>
import Weather from "./weather";
import EmergencyResponse from "./emergencyResponse";
import Info from "./info";
export default {
  name: "routeList",
  components: { Weather, EmergencyResponse, Info },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    fluData(res) {
      let that = this;
      that.$refs.RealTime.getwsData(res);
      that.$refs.Cumulative.getwsData(res);
    },
  },
};
</script>

<style lang="scss" scoped>
.conLeft {
  height: 100%;
  display: grid;
  grid-template-rows: 28% calc(39% - 20px) 33% ;
  grid-row-gap: 10px;
}
</style>
