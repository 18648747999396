<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="left_tree">
      <div class="ppert">选择工点</div>
      <el-tree
        :data="siteList"
        :props="defaultProps"
        @node-click="handleNodeClick"
        :highlight-current="true"
      >
        <span class="custom-tree-node" slot-scope="{ node }">
          <span :title="node.label">
            {{ node.label }}
          </span>
        </span>
      </el-tree>
    </div>
    <div class="right_table ml-4 flex-1">
      <div class="top_table">
        <div class="ppert">
          未关联bim模型<span
            style="font-size: 12px; color: #b3b0b0; margin-left: 10px"
            >共{{ tableData.length }}个</span
          >
        </div>
        <div class="sertch">
          <el-input placeholder="请输入内容" v-model="siteName">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="bimQuery()"
            ></el-button>
          </el-input>
          <el-button type="primary" @click="updateBim">更新</el-button>
        </div>

        <el-table
          :data="tableData"
          stripe
          :border="false"
          style="width: 98%; margin: auto"
          v-loading="topLoding"
        >
          <el-table-column align="center" width="60">
            <template slot-scope="scope">
              <i
                class="el-icon-circle-plus-outline addbim"
                @click="addBimClick(scope.row)"
              ></i>
            </template>

            ></el-table-column
          >
          <el-table-column prop="modelName" label="bim名称"> </el-table-column>
          <el-table-column prop="modelId" label="bim编号"> </el-table-column>
          <el-table-column label="操作" align="center" width="180">
            <template slot-scope="scope">
              <el-button size="mini" @click="deleBim(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom_table">
        <div class="ppert">
          已选择bim模型<span
            style="font-size: 12px; color: #b3b0b0; margin-left: 10px"
            >共{{ tableDatas.length }}个</span
          >
        </div>

        <el-table
          :data="tableDatas"
          stripe
          :border="false"
          style="width: 98%; margin: auto"
          v-loading="bottomLoding"
        >
          <el-table-column align="center" width="60">
            <template slot-scope="scope">
              <i
                class="el-icon-remove-outline addbim"
                @click="removeBimClick(scope.row)"
              ></i>
            </template>
            ></el-table-column
          >
          <el-table-column prop="modelName" label="bim名称"> </el-table-column>
          <el-table-column prop="modelId" label="bim编号"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  updateBimList,
  unBindingList,
  getCodeDictDetails,
  bindingBySite,
  bimDelete,
  unBindingBySite,
} from "@/api/bimApi";
export default {
  data() {
    return {
      siteList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      siteName: "",
      tableData: [],
      tableDatas: [],
      siteData: {},
      topLoding: false,
      bottomLoding: false,
    };
  },
  created() {
    const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
    this.siteList = lineSiteData;
    this.unboundedBim("");
  },
  methods: {
    // 站点树的点击事件
    handleNodeClick(data, node) {
      if (node.childNodes.length == 0) {
        this.siteData = data;
        this.lsBinding(this.siteData.uuid);
      }
    },
    // 更新
    updateBim() {
      updateBimList().then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.lsBinding(this.siteData.uuid);
          this.unboundedBim(this.siteName);
        }
      });
    },
    // 获取未关联的bim模型
    unboundedBim(name) {
      this.topLoding = true;
      unBindingList(name).then((res) => {
        this.topLoding = false;
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },
    // 根据名称查询bim
    bimQuery() {
      this.unboundedBim(this.siteName);
    },
    // 获取当前站点已经绑定的bin
    lsBinding(id) {
      this.bottomLoding = true;
      getCodeDictDetails(id).then((res) => {
        this.bottomLoding = false;
        if (res.code == 0) {
          this.tableDatas = res.data;
        }
      });
    },
    // 绑定bim
    addBimClick(row) {
      const obj = {
        id: row.id,
        modelId: row.modelId,
        modelName: row.modelName,
        siteName: this.siteData.name,
        siteUuid: this.siteData.uuid,
        remark: "",
        state: "",
      };
      if (!this.siteData.uuid) {
        this.$message({
          message: "请选择左侧需要绑定的站点",
          type: "warning",
        });
      } else {
        bindingBySite(obj).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.lsBinding(this.siteData.uuid);
            this.unboundedBim(this.siteName);
          }
        });
      }
    },
    // 删除模型
    deleBim(row) {
      this.$confirm("确定删除模型吗", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          bimDelete(row.id).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.unboundedBim(this.siteName);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 解绑模型
    removeBimClick(row) {
      this.$confirm("确定解绑吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          unBindingBySite(row.id).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.lsBinding(this.siteData.uuid);
              this.unboundedBim(this.siteName);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消操作",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  justify-content: space-between;
  .left_tree {
    width: 300px;
    height: 100%;
    border: 1px solid #E6E7EC;
    .ppert {
      font-size: 14px;
      margin-top: 10px;
      margin-left: 10px;
    }
    .el-tree {
      // width: 80%;
      height: 90%;
      margin: 20px;
      // border: 1px solid #b3b0b0;
      // box-shadow: 0px 0px 10px #b3b0b0;
    }
  }
  .right_table {
    // width: 83%;
    height: 100%;

    // border: 1px solid #969494;
    .top_table {
      width: 100%;
      height: 60%;
      // border-bottom: 1px solid #b3b0b0;
      .ppert {
        font-size: 14px;
        margin-top: 10px;
        margin-left: 10px;
      }
      .sertch {
        width: 98%;
        margin-top: 20px;
        margin: 10px auto;
        display: flex;
        justify-content: space-between;
        .el-input-group {
          width: 30%;
        }
      }
      .el-table {
        margin-top: 20px !important;
        height: 350px;

        overflow: scroll;
      }
    }
    .bottom_table {
      width: 100%;
      height: 40%;

      .ppert {
        font-size: 14px;
        margin-top: 10px;
        margin-left: 10px;
      }
      .el-table {
        margin-top: 20px !important;
        height: 260px;
        overflow: scroll;
      }
    }
  }
}
.addbim {
  cursor: pointer;
}
.custom-tree-node {
  display: inline-block;
  width: 200px;
  overflow: hidden;
  /*文本不会换行*/
  white-space: nowrap;
  /*当文本溢出包含元素时，以省略号表示超出的文本*/
  text-overflow: ellipsis;
  font-size: 14px;
}
</style>