<template>
  <div>
    <!-- 待盘点详情 -->
    <el-dialog
      title="待盘点详情"
      center
      :visible.sync="countedVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="countedform" label-width="100px">
        <el-form-item label="物资库名称">
          <el-input
            v-model="countedform.materialWareHouse"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="负责人">
          <el-input
            v-model="countedform.chargeName"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="countedform.mobile" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="截至时间">
          <el-input v-model="countedform.endDate" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
      <div class="rever" style="margin-top: 10px; margin-left: 10px">
        <div class="radio">处理过程（待盘点）</div>
        <el-timeline :reverse="reverse" style="margin-top: 10px; margin: 2px">
          <el-timeline-item
            v-for="(activity, index) in acticonunt"
            :key="index"
          >
            <div class="actitle">
              <span class="incid">{{ activity.title }}</span>
              <span class="times">{{ activity.created }}</span>
            </div>
            <div
              class="reported"
              v-show="
                activity.title == '物资盘点' || activity.title == '重新提交'
              "
            >
              <span>提交人:</span>
              <span>{{ activity.optionName }}</span>
            </div>
            <div class="reported" v-show="activity.title == '审核'">
              <span>审核人:</span>
              <span>{{ activity.optionName }}</span>
            </div>
            <div class="reported" v-show="activity.title == '审核'">
              <span>审核结果:</span>
              <span>{{ activity.examineOpinion }}</span>
            </div>
            <div class="reported" v-show="activity.title == '审核'">
              <span>审核意见:</span>
              <span>{{ activity.remark }}</span>
            </div>
            <div class="reported" v-show="activity.title == '重新提交'">
              <span>备注:</span>
              <span>{{ activity.remark }}</span>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
    <!-- 审核中详情 -->
    <el-dialog
      title="审核中详情"
      center
      :visible.sync="auditVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="详情" name="first">
          <el-form ref="form" :model="auditform" label-width="100px">
            <el-form-item label="物资库名称">
              <el-input
                v-model="auditform.materialWareHouse"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="负责人">
              <el-input
                v-model="auditform.chargeName"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input v-model="auditform.mobile" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="盘点时间">
              <el-input
                v-model="auditform.inventoryDate"
                :disabled="true"
              ></el-input>
            </el-form-item>

            <el-form-item label="盘点人员">
              <el-input
                v-model="auditform.inventoryUserName"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="auditform.remark" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="照片">
              <el-image
                v-for="(item, index) in imgs"
                :key="index"
                style="width: 100px; height: 100px"
                :src="item"
              ></el-image>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="异常物资" name="second">
          <el-table :data="materialList" style="width: 100%">
            <el-table-column prop="materialsName" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="num" label="现存数量" width="180">
            </el-table-column>
            <el-table-column prop="inventoryNum" label="盘点数量">
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="正常物资" name="third">
          <el-table :data="suppliesList" style="width: 100%">
            <el-table-column prop="label" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="count" label="现存数量" width="180">
            </el-table-column>
            <el-table-column prop="address" label="盘点数量"> </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="处理过程" name="fourth" class="rever">
          <div class="block">
            <div class="radio">处理过程（审核中）</div>
            <el-timeline
              :reverse="reverse"
              style="margin-top: 20px; margin: 2px"
            >
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
              >
                <div class="actitle">
                  <span class="incid">{{ activity.title }}</span>
                  <span class="times">{{ activity.created }}</span>
                </div>
                <div
                  class="reported"
                  v-show="
                    activity.title == '物资盘点' || activity.title == '重新提交'
                  "
                >
                  <span>提交人:</span>
                  <span>{{ activity.optionName }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核人:</span>
                  <span>{{ activity.optionName }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核结果:</span>
                  <span>{{ activity.examineOpinion }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核意见:</span>
                  <span>{{ activity.remark }}</span>
                </div>
                <div class="reported" v-show="activity.title == '重新提交'">
                  <span>备注:</span>
                  <span>{{ activity.remark }}</span>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div></el-tab-pane
        >
      </el-tabs>
    </el-dialog>
    <!-- 审核 -->
    <el-dialog
      title="审核"
      center
      :visible.sync="auditsserVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="详情" name="first">
          <el-form ref="form" :model="auditform" label-width="100px">
            <el-form-item label="物资库名称">
              <el-input
                v-model="auditform.materialWareHouse"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="负责人">
              <el-input
                v-model="auditform.chargeName"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input v-model="auditform.mobile" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="盘点时间">
              <el-input
                v-model="auditform.inventoryDate"
                :disabled="true"
              ></el-input>
            </el-form-item>

            <el-form-item label="盘点人员">
              <el-input
                v-model="auditform.inventoryUserName"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="auditform.remark" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="照片">
              <el-image
                v-for="(item, index) in imgs"
                :key="index"
                style="width: 100px; height: 100px"
                :src="item"
              ></el-image>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="异常物资" name="second">
          <el-table :data="materialList" style="width: 100%">
            <el-table-column prop="materialsName" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="num" label="现存数量" width="180">
            </el-table-column>
            <el-table-column prop="inventoryNum" label="盘点数量">
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="正常物资" name="third">
          <el-table :data="suppliesList" style="width: 100%">
            <el-table-column prop="label" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="count" label="现存数量" width="180">
            </el-table-column>
            <el-table-column prop="address" label="盘点数量"> </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="处理过程" name="fourth" class="rever">
          <div class="block">
            <div class="radio">处理过程（审核中）</div>
            <el-timeline
              :reverse="reverse"
              style="margin-top: 20px; margin: 2px"
            >
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
              >
                <div class="actitle">
                  <span class="incid">{{ activity.title }}</span>
                  <span class="times">{{ activity.created }}</span>
                </div>
                <div
                  class="reported"
                  v-show="
                    activity.title == '物资盘点' || activity.title == '重新提交'
                  "
                >
                  <span>提交人:</span>
                  <span>{{ activity.optionName }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核人:</span>
                  <span>{{ activity.optionName }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核结果:</span>
                  <span>{{ activity.examineOpinion }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核意见:</span>
                  <span>{{ activity.remark }}</span>
                </div>
                <div class="reported" v-show="activity.title == '重新提交'">
                  <span>备注:</span>
                  <span>{{ activity.remark }}</span>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div></el-tab-pane
        >
      </el-tabs>
      <el-input
        v-if="opinion"
        type="textarea"
        :rows="2"
        placeholder="请填写审核意见"
        v-model="textarea"
      >
      </el-input>
      <el-row style="margin-top: 10px; text-align: center" v-if="opinion">
        <el-button @click="reject">驳回</el-button>
        <el-button type="primary" @click="onSubmit">通过</el-button>
      </el-row>
    </el-dialog>
    <!-- 已完成详情 -->
    <el-dialog
      title="已完成详情"
      center
      :visible.sync="completedVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="详情" name="first">
          <el-form ref="form" :model="completeform" label-width="100px">
            <el-form-item label="物资库名称">
              <el-input
                v-model="completeform.materialWareHouse"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="负责人">
              <el-input
                v-model="completeform.chargeName"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input
                v-model="completeform.mobile"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="盘点时间">
              <el-input
                v-model="completeform.inventoryDate"
                :disabled="true"
              ></el-input>
            </el-form-item>

            <el-form-item label="盘点人员">
              <el-input
                v-model="completeform.inventoryUserName"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model="completeform.remark"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="照片">
              <el-image
                v-for="(item, index) in imgs"
                :key="index"
                style="width: 100px; height: 100px"
                :src="item"
              ></el-image>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="异常物资" name="second">
          <el-table :data="materialList" style="width: 100%">
            <el-table-column prop="materialsName" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="num" label="现存数量" width="180">
            </el-table-column>
            <el-table-column prop="inventoryNum" label="盘点数量">
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="正常物资" name="third">
          <el-table :data="suppliesList" style="width: 100%">
            <el-table-column prop="label" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="count" label="现存数量" width="180">
            </el-table-column>
            <el-table-column prop="address" label="盘点数量"> </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="处理过程" name="fourth" class="rever">
          <div class="block">
            <div class="radio">处理过程（已完成）</div>
            <el-timeline
              :reverse="reverse"
              style="margin-top: 20px; margin: 2px"
            >
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
              >
                <div class="actitle">
                  <span class="incid">{{ activity.title }}</span>
                  <span class="times">{{ activity.created }}</span>
                </div>
                <div
                  class="reported"
                  v-show="
                    activity.title == '物资盘点' || activity.title == '重新提交'
                  "
                >
                  <span>提交人:</span>
                  <span>{{ activity.optionName }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核人:</span>
                  <span>{{ activity.optionName }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核结果:</span>
                  <span>{{ activity.examineOpinion }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核意见:</span>
                  <span>{{ activity.remark }}</span>
                </div>
                <div class="reported" v-show="activity.title == '重新提交'">
                  <span>备注:</span>
                  <span>{{ activity.remark }}</span>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div></el-tab-pane
        >
      </el-tabs>
    </el-dialog>
    <!-- 整改中详情 -->
    <el-dialog
      title="整改中详情"
      center
      :visible.sync="recticationdVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="详情" name="first">
          <el-form ref="form" :model="rectificaform" label-width="100px">
            <el-form-item label="物资库名称">
              <el-input
                v-model="rectificaform.materialWareHouse"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="负责人">
              <el-input
                v-model="rectificaform.chargeName"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input
                v-model="rectificaform.mobile"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="盘点时间">
              <el-input
                v-model="rectificaform.inventoryDate"
                :disabled="true"
              ></el-input>
            </el-form-item>

            <el-form-item label="盘点人员">
              <el-input
                v-model="rectificaform.inventoryUserName"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model="rectificaform.remark"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="照片">
              <el-image
                v-for="(item, index) in imgs"
                :key="index"
                style="width: 100px; height: 100px"
                :src="item"
              ></el-image>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="异常物资" name="second">
          <el-table :data="materialList" style="width: 100%">
            <el-table-column prop="materialsName" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="num" label="现存数量" width="180">
            </el-table-column>
            <el-table-column prop="inventoryNum" label="盘点数量">
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="正常物资" name="third">
          <el-table :data="suppliesList" style="width: 100%">
            <el-table-column prop="label" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="count" label="现存数量" width="180">
            </el-table-column>
            <el-table-column prop="address" label="盘点数量"> </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="处理过程" name="fourth" class="rever">
          <div class="block">
            <div class="radio">处理过程（整改中）</div>
            <el-timeline
              :reverse="reverse"
              style="margin-top: 20px; margin: 2px"
            >
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
              >
                <div class="actitle">
                  <span class="incid">{{ activity.title }}</span>
                  <span class="times">{{ activity.created }}</span>
                </div>
                <div
                  class="reported"
                  v-show="
                    activity.title == '物资盘点' || activity.title == '重新提交'
                  "
                >
                  <span>提交人:</span>
                  <span>{{ activity.optionName }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核人:</span>
                  <span>{{ activity.optionName }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核结果:</span>
                  <span>{{ activity.examineOpinion }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核意见:</span>
                  <span>{{ activity.remark }}</span>
                </div>
                <div class="reported" v-show="activity.title == '重新提交'">
                  <span>备注:</span>
                  <span>{{ activity.remark }}</span>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div></el-tab-pane
        >
      </el-tabs>
    </el-dialog>
    <!-- 未完成详情 -->
    <el-dialog
      title="未完成详情"
      center
      :visible.sync="incompleteVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="物资库名称">
          <el-input
            v-model="form.materialWareHouse"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="负责人">
          <el-input v-model="form.chargeName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="form.mobile" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="截至时间">
          <el-input v-model="form.endDate" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
      <div class="block">
        <div class="radio">处理过程（未完成）</div>
        <el-timeline :reverse="reverse" style="margin-top: 20px; margin: 2px">
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
          >
            <div class="actitle">
              <span class="incid">{{ activity.title }}</span>
              <span class="times">{{ activity.created }}</span>
            </div>
            <div
              class="reported"
              v-show="
                activity.title == '物资盘点' || activity.title == '重新提交'
              "
            >
              <span>提交人:</span>
              <span>{{ activity.optionName }}</span>
            </div>
            <div class="reported" v-show="activity.title == '审核'">
              <span>审核人:</span>
              <span>{{ activity.optionName }}</span>
            </div>
            <div class="reported" v-show="activity.title == '审核'">
              <span>审核结果:</span>
              <span>{{ activity.examineOpinion }}</span>
            </div>
            <div class="reported" v-show="activity.title == '审核'">
              <span>审核意见:</span>
              <span>{{ activity.remark }}</span>
            </div>
            <div class="reported" v-show="activity.title == '重新提交'">
              <span>备注:</span>
              <span>{{ activity.remark }}</span>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  inventoryList,
  process,
  material,
  supplies,
  inventory,
} from "@/api/eres.js";
export default {
  data() {
    return {
      countedVisible: false, //待盘点

      countedform: {}, //待盘点
      activeName: "first",
      incompleteVisible: false, //未完成
      completedVisible: false, //已完成
      auditVisible: false, //审核中
      recticationdVisible: false, //整改中
      auditsserVisible: false, //审核
      reverse: false,
      acticonunt: [],
      form: {},
      activities: [],
      tableData: [],
      materialList: [],
      suppliesList: [],
      auditform: {},
      rectificaform: {},
      completeform: {},
      textarea: "",
      opinion: true,
      imgs: [],
      inventoryId: "",
    };
  },
  created(){

  },
  methods: {
    //待盘点
    besconunt(val) {
      inventoryList({ id: val.id }).then((res) => {
        console.log(res);
        this.countedform = res.data;
      });
      process({ id: val.id }).then((data) => {
        console.log(data);
        this.acticonunt = data.data;
      });
      this.countedVisible = true;
    },
    //审核中
    review(val) {
      inventoryList({ id: val.id }).then((res) => {
        this.auditform = res.data;
        this.imgs = res.data.imgs;
          supplies({
        id: val.id,
        siteId: this.auditform.siteId,
      }).then((res) => {
        this.suppliesList = res.data;
      });
      });
      material({ id: val.id }).then((res) => {
        this.materialList = res.data;
      });
    
      process({ id: val.id }).then((data) => {
        this.activities = data.data;
      });

      this.auditVisible = true;
    },
    //整改中
    rectification(val) {
      inventoryList({ id: val.id }).then((res) => {
        this.rectificaform = res.data;
        this.imgs = res.data.imgs;
         supplies({
        id: val.id,
        siteId: this.rectificaform.siteId,
      }).then((res) => {
        this.suppliesList = res.data;
      });
      });
      material({ id: val.id }).then((res) => {
        this.materialList = res.data;
      });
     
      process({ id: val.id }).then((data) => {
        this.activities = data.data;
      });
      this.recticationdVisible = true;
    },
    //完成
    complete(val) {
      inventoryList({ id: val.id }).then((res) => {
        this.completeform = res.data;
        this.imgs = res.data.imgs;
          supplies({
        id: val.id,
        siteId: this.completeform.siteId,
      }).then((res) => {
        this.suppliesList = res.data;
      });
      });
      material({ id: val.id }).then((res) => {
        this.materialList = res.data;
      });
    
      process({ id: val.id }).then((data) => {
        this.activities = data.data;
      });
      this.completedVisible = true;
    },
    //未完成
    incomplete(val) {
      inventoryList({ id: val.id }).then((res) => {
        this.form = res.data;
      });
      process({ id: val.id }).then((data) => {
        this.activities = data.data;
      });
      this.incompleteVisible = true;
    },

    //审核
    auditerts(val) {
      this.inventoryId = val.id;
      inventoryList({ id: val.id }).then((res) => {
        this.auditform = res.data;
        this.imgs = res.data.imgs;
         supplies({
        id: val.id,
        siteId: this.auditform.siteId,
      }).then((res) => {
        this.suppliesList = res.data;
      });
      });
      material({ id: val.id }).then((res) => {
        this.materialList = res.data;
      });
     
      process({ id: val.id }).then((data) => {
        this.activities = data.data;
      });
      this.auditsserVisible = true;
    },
    handleClose() {
      this.countedVisible = false;
      this.auditVisible = false;
      this.recticationdVisible = false;
      this.completedVisible = false;
      this.incompleteVisible = false;
      this.auditsserVisible = false;
      this.textarea=""
    },
    //通过
    onSubmit() {
      inventory({
        examineOpinion: this.textarea,
        state: 2,
        inventoryId: this.inventoryId,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "已审核",
            type: "success",
          });
        }
      });
    },
    //驳回
    reject() {
      inventory({
        examineOpinion: this.textarea,
        state: 1,
        inventoryId: this.inventoryId,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "已审核",
            type: "success",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.rever {
  height: 100%;

  overflow-y: auto;

  .actitle {
    display: flex !important;
    justify-content: space-between !important;
    .times {
      font-size: 12px;
    }
    .incid {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .reported {
    margin-top: 10px;
    .incid {
      font-size: 12px;
    }
    span {
      font-size: 12px;
    }
  }
}
::v-deep .el-timeline-item__node {
  background-color: rgba(59, 130, 246);
}

::v-deep .el-timeline-item__node--normal {
  left: 0px;
}
</style>