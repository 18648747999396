<template>
  <div class="briefing">
    <el-dialog type="text" :title="title" :visible.sync="visible" width="50%" :before-close="handleClose" center
      class="abow_dialog" append-to-body
    >
      <el-form :model="model" ref="modelForm" label-width="120px" v-loading="loading">
        <el-form-item label="填报单位" prop="supervisionName">
          <el-select v-model="supervisionName" placeholder="请选择填报单位" disabled>
            <el-option v-for="(item, key) of unitOptions" :key="key" :label="item.label" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传附件" prop="name">
          <Uploader @uploader="uploader" v-if="visible"></Uploader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fillInBriefing } from "@/api/response";
import { getDeptInfoById } from "@/api/org";
import {} from "@/utils/common";
export default {
  components: {
    Uploader: () => import("@/components/Uploader/ImagePdfUploader.vue")
  },
  data() {
    return {
      title: "三防工作简报",
      visible: false,
      url: "",
      lineSiteData: [],
      unitOptions: [],
      model: {
        currentId: "",
        lineUuid: "",
        siteUuid: "",
        siteName: "",
        lineName: "",
        fileUrl: "",
      },
      user: {},
      supervisionName: "",
      supervisionUuid: "",
      props: {
        children: "children",
        label: "name",
        value: "uuid"
      },
      loading: false
    };
  },
  mounted() {
    this.getLineSiteData();
    this.queryDeptInfo();
  },
  methods: {
    handleFilling(record) {
      this.visible = true;
      this.model.currentId = record.id;
      this.model.lineUuid = record.lineId;
      this.model.lineName = record.lineName;
      this.model.siteUuid = record.siteId;
      this.model.siteName = record.siteName;
      const userInfo = JSON.parse(localStorage.getItem("user"));
      if (userInfo) {
        this.user = {
          id: userInfo.id,
          name: userInfo.userName
        };
      }
    },
    // 上传
    uploader(val) {
      this.model.fileUrl = val;
    },
    queryDeptInfo() {
      const user = JSON.parse(localStorage.getItem("user"));
      this.supervisionUuid = user.departmentId;
      getDeptInfoById(user.departmentId).then(res => {
        if (res.code == 0) {
          if (res.data) {
            this.supervisionName = res.data.departmentName;
          }
        }
      });
    },
    // 获取线路工点树
    getLineSiteData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.lineSiteData = lineSiteData;
      }
    },
    // 线路工点树选择
    handleLineSiteChange(val) {
      this.model.lineUuid = val[0];
      this.model.siteUuid = val[val.length-1];
      const result = [];
      this.flatArr(result, this.lineSiteData);
      const line = result.find(item => item.uuid == val[0]);
      const site = result.find(item => item.uuid == val[val.length-1]);
      this.model.lineName = line.name;
      this.model.siteName = site.name;
    },
    // 数组扁平化方法
    flatArr(result, data) {
      for (let item of data) {
        result.push({
          name: item.name,
          id: item.id,
          uuid: item.uuid,
          parentuuid: item.puuid
        })
        if (item.children) {
          this.flatArr(result, item.children);
        }
      }
    },
    //提交
    async submit() {
      if (!this.model.fileUrl) {
        this.$message.error("请上传三防工作简报！");
        return false;
      }
      this.loading = true;
      const params = JSON.parse(JSON.stringify(this.model));
      params.user = this.user;
      params.supervisionName = this.supervisionName;
      params.supervisionUuid = this.supervisionUuid;
      let res = await fillInBriefing(params);
      if (res.code == 0) {
        this.$message.success("填报成功");
        this.$emit("fillingSubmit");
        this.handleClose();
        this.loading = false;
      }
    },
    handleClose() {
      this.model = {
        currentId: "",
        lineUuid: "",
        siteUuid: "",
        siteName: "",
        lineName: "",
        fileUrl: "",
      };
      this.visible = false;
      this.$emit("modalSubmit");
    }
  }
};
</script>

<style lang="scss" scoped>
.briefong_auto {
  width: 90%;
  margin: auto;

  .briefong_one {
    .typhoon {
      font-size: 14px;
      color: #000000;
      font-weight: bold;
    }

    .defense {
      font-size: 14px;
      color: #000000;
      font-weight: bold;
    }
  }
}

.el-form-item {
  margin-bottom: 8px !important;
}

.content {
  width: 100%;
  height: 300px;
  border: 2px solid #e8e8e8;
  margin-top: 8px;
  position: relative;

  .water {
    color: #62b9e5;
    position: absolute;
    top: 37%;
    left: 104%;
  }

  .influen {
    color: #62b9e5;
    position: absolute;
    top: 37%;
    left: 104%;
  }

  .reovem {
    color: #62b9e5;
    position: absolute;
    top: 37%;
    left: 104%;
  }
}

.el-table {
  font-size: 12px !important;
}

.toimg {
  display: flex;
  margin-top: 8px;
  margin-left: 4px;

  .imglist {
    width: 60px;
    height: 60px;
    margin-left: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.poert {
  position: relative;

  .el-icon-circle-plus-outline {
    color: #62b9e5;
    position: absolute;
    top: 33%;
    left: 60%;
  }

  .el-icon-delete {
    color: #62b9e5;
    position: absolute;
    top: 33%;
    left: 65%;
  }

  .supplie {
    top: 33%;
    left: 85%;
  }

  .suppiet {
    top: 33%;
    left: 90%;
  }
}

.water {
  position: relative;

  .waeeter {
    color: #62b9e5;
    position: absolute;
    top: 29%;
    left: 45%;
  }

  .suddend {
    color: #62b9e5;
    position: absolute;
    top: 29%;
    left: 50%;
  }
}

.el-radio {
  margin-right: 150px !important;
}
</style>
