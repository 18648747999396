<template>
  <!-- 物资盘点 -->
  <div class="page-container bg-white p-4 h-full">
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column type="index" label="序号" width="80"> </el-table-column>
      <el-table-column prop="chargeName" label="姓名" width="180">
      </el-table-column>
      <el-table-column prop="materialWareHouse" label="工点名称">
      </el-table-column>
      <el-table-column prop="typers" label="类型"> </el-table-column>
      <el-table-column prop="state" label="状态" :formatter="apser">
      </el-table-column>
      <el-table-column prop="inventoryDate" label="盘点时间"> </el-table-column>
      <el-table-column prop="inventoryUserName" label="盘点人员">
      </el-table-column>
    </el-table>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-count="page.count"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { InventorysseList } from "@/api/eres.js";
export default {
  data() {
    return {
      formInline: {
        endDate: "",
        siteId: "",
        startDate: "",
        state: "",
        type: "",
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
    };
  },
  created() {
    this.incenter();
  },
  methods: {
    //列表
    incenter() {
      InventorysseList({
        page: this.page.pageNo,
        size: this.page.pageSize,
        endDate: this.formInline.endDate,
        siteId: this.formInline.siteId,
        startDate: this.formInline.startDate,
        state: this.formInline.state,
        type: this.formInline.type,
      }).then((res) => {
        console.log(res);
        res.data.records.forEach((el) => {
          el.materialWareHouse = el.materialWareHouse.substring(
            0,
            el.materialWareHouse.length - 3
          );
          if (el.endDate == null) {
            el.typers = "自主推送";
          } else {
            el.typers = "系统推送";
          }
          console.log(el);
        });
        this.tableData = res.data.records;

        this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.page.count = res.data.total;
        this.page.total = res.data.count;
      });
    },

    //状态
    apser(row, column, cellvalue) {
      if (cellvalue == "0") {
        return "待盘点";
      } else if (cellvalue == "2") {
        return "审核中";
      } else if (cellvalue == "1") {
        return "整改中";
      } else if (cellvalue == "3") {
        return "完成";
      } else if (cellvalue == "4") {
        return "未完成";
      }
    },
    handleSizeChange(val) {
      console.log(val);
      this.page.pageSize = val;
      this.incenter();
    },
    handleCurrentChange(val) {
      console.log(val);
      this.page.pageNo = val;
      this.incenter();
    },
  },
};
</script>

<style></style>
