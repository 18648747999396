<template>
  <div>
    <el-form
      :model="formData"
      class="demo-form-inline"
      :rules="rules"
      ref="ruleForm"
      v-loading="fromLoading"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="当前应急响应：">
            <span
              >{{ temList.type ? temList.type.label : "" }}
              <span v-if="temList.isRelegation == 1">{{
                temList.relegationLevelLabel ? temList.relegationLevelLabel : ""
              }}</span>
              <span v-else>{{
                temList.currentLevel ? temList.currentLevel.label : ""
              }}</span>
              级响应</span
            >
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-radio-group
              v-model="formData.isRelegation"
              @change="changeLevel"
            >
              <el-radio label="1">升级</el-radio>
              <el-radio label="2">降级</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            :label="formData.isRelegation == '1' ? '升级时间' : '降级时间'"
            prop="relegationTime"
          >
            <el-date-picker
              v-model="formData.relegationTime"
              type="datetime"
              placeholder="请选择"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="formData.isRelegation == '1' ? '升级为' : '降级为'"
            prop="relegationLevel"
          >
            <el-select
              v-model="formData.relegationLevel"
              placeholder="请选择"
              :key="dtime"
            >
              <el-option
                v-for="item of leveloptions"
                :key="item.id"
                :label="item.label"
                :value="item.id"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="应急响应升降级详细内容" prop="relegationRemark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 8, maxRows: 16 }"
              v-model="formData.relegationRemark"
              placeholder="多行输入"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="display: flex; justify-content: center">
      <el-button type="primary" @click="sureSub('ruleForm')">确认</el-button>
    </div>
  </div>
</template>

<script>
import { ResRelegation } from "@/api/responseTem";
import dayjs from "dayjs";
export default {
  props: {
    temList: {
      type: Object,
      default: () => {},
    },
    leveloptions: {},
  },
  data() {
    return {
      dialogVisible: true,
      fromLoading: false,
      formData: {
        id: "",
        relegationTime: "",
        relegationLevel: "",
        relegationRemark: "",
        isRelegation: "1",
      },
      rules: {
        relegationTime: [
          {
            type: "string",
            required: true,
            message: "请选择升级时间",
            trigger: "change",
          },
        ],
        relegationLevel: [
          {
            type: "number",
            required: true,
            message: "请选择应急等级",
            trigger: "change",
          },
        ],
        relegationRemark: [
          {
            type: "string",
            required: true,
            message: "请填写应急响应详细内容",
            trigger: "blur",
          },
        ],
      },
      dtime: dayjs().format("HHmmss"),
    };
  },
  created() {
    this.formData = {
      id: this.temList.id,
      relegationTime: "",
      relegationLevel: "",
      relegationRemark: "",
      isRelegation: "1",
    };
    this.leveloptions.forEach((e) => {
      e.disabled = false;
      e.text = this.levelpas(e.label);
    });
    this.changeLevel(1);
  },
  methods: {
    levelpas(data) {
      if (data == "Ⅰ") {
        return 1;
      } else if (data == "Ⅱ") {
        return 2;
      } else if (data == "Ⅲ") {
        return 3;
      } else if (data == "Ⅳ") {
        return 4;
      }
    },
    changeLevel(val) {
      this.formData.relegationLevel = "";
      let level;
      if (this.temList.isRelegation == 1) {
        level = this.levelpas(this.temList.relegationLevelLabel);
      } else {
        level = this.levelpas(this.temList.currentLevel.label);
      }
      this.leveloptions.forEach((e) => {
        e.disabled = false;
        if (val == 1) {
          if (e.text > level || e.text == level) {
            e.disabled = true;
          }
        } else {
          if (e.text < level || e.text == level) {
            e.disabled = true;
          }
        }
      });
      this.dtime = dayjs().format("HHmmss");
    },

    sureSub(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fromLoading = true;
          let params = this.formData;
          ResRelegation(params).then((res) => {
            if (res.code == 0) {
              this.fromLoading = false;
              this.$message.success("操作成功");
              this.$emit("upgradeClose", true);
            } else {
              this.fromLoading = false;
              this.$message.error(res.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
