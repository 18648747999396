<template>
  <div class="model">
    <outLeft class="left-panel" v-show="filew" @detaliel="detaliel"></outLeft>
    <sidebares
      :class="filew == false ? 'left_ster' : 'left-side'"
      @pack="pack"
      :detaltes="detalieltes"
    ></sidebares>
    <BIM></BIM>
  </div>
</template>

<script>
import outLeft from "../../Outburst/components/outburstLeft.vue";
import sidebares from "../../Outburst/components/sidebar.vue";
import BIM from "./BIM/index.vue";
export default {
  components: { outLeft, sidebares, BIM },
  data() {
    return {
      filew: true,
      detalieltes: {},
    };
  },
  methods: {
    detaliel(val) {
      console.log(val);

      this.detalieltes = val;
    },
    pack() {
      this.filew = !this.filew;
    },
  },
};
</script>

<style lang="scss" scoped>
.model {
  width: 100%;
  height: 100%;
  position: absolute;

  .left-panel {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    width: 20.8%;
    height: 100%;
  }
  .left-side {
    position: absolute;
    left: 22%;
    top: 0;
    z-index: 9;
  }
  .left_ster {
    position: absolute;
    left: 2%;
    top: 0;
    z-index: 9;
  }
}
</style>

