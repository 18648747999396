<template>
  <systemModal
    ref="AuditForm"
    :width="width"
    :IsAudit="true"
    @modalAgree="handleSubmit(true)"
    @modalDisagree="handleSubmit(false)"
    :title="title"
  >
    <div v-loading="loadings">
      <div class="flex justify-between" >
        <div class="leading-6">
          <span> 线路-工点：{{ model.lineName + model.siteName }} </span><br />
          <span> 上传人员：{{ model.userName }} </span><br />
          <span> 上传时间：{{ model.created }} </span><br />
          <span> 备注：{{ model.note }} </span><br />
        </div>
        <div>
          <el-image
            style="width: 200px; height: 100px"
            :src="model.imageUrl"
            :preview-src-list="[model.imageUrl]"
          >
          </el-image>
        </div>
      </div>
      <div class="mt-2">
        <span>审核意见：</span
        ><el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6 }"
          v-model="remet"
          placeholder="请输入审核意见"
        ></el-input>
      </div>
    </div>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { drainagerModify } from "@/api/map";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "审批",
      width: "600px",
      model: {
        lineSite: "",
        name: "",
        unit: "",
        position: "",
        mobile: "",
      },
      remet: "",
      loadings: false,
    };
  },
  mounted() {},
  methods: {
    handleAdd(record) {
      this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
      this.remet = "";
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.AuditForm.handleOpen();
    },
    handleSubmit(data) {
      this.loadings = true;
      let state = 1;
      if (data) {
        state = 1;
      } else {
        state = 3;
      }
      this.model.state = state;
      this.model.note = this.remet;
      drainagerModify(this.model).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "操作成功！",
            type: "success",
          });
          this.$emit("getdata");
          this.$refs.AuditForm.handleClose();
        } else {
          this.$message.error("操作失败！");
        }
        this.loadings = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
