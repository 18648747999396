<template>
  <div class="page-container bg-white p-4 h-full">
    <el-radio-group
      v-model="companyPlans"
      style="margin-bottom: 30px;"
      @change="radioChange"
    >
      <el-radio-button :label="0">施工单位预案</el-radio-button>
      <el-radio-button :label="1">建设公司预案</el-radio-button>
      <el-radio-button :label="3">集团预案</el-radio-button>
    </el-radio-group>
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
            <el-select
              v-model="queryModal.planType"
              placeholder="请选择预案类型"
            >
              <el-option
                v-for="item of options"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-input
              v-model="queryModal.planName"
              placeholder="输入预案名称"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" disabled @click="handleAdd"
            >预案导入模板</el-button
          >
          <el-button type="primary" disabled @click="handleImport"
            >导入</el-button
          >
          <el-button type="primary" disabled @click="handleExport"
            >导出</el-button
          >
          <el-button type="primary" @click="handleAdd" v-has="'emergency:add'"
            >新增</el-button
          >
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          v-if="companyPlans == 0"
          align="center"
          prop="supervisionName"
          label="所属建管部"
        ></el-table-column>
        <el-table-column
          v-if="companyPlans == 0"
          align="center"
          prop="lineName"
          label="线路名称"
        ></el-table-column>
        <el-table-column
          v-if="companyPlans == 0"
          align="center"
          prop="branchName"
          label="分部名称"
        >
        </el-table-column>
        <el-table-column
          v-if="companyPlans == 0"
          align="center"
          prop="siteName"
          label="工点名称"
        >
        </el-table-column>
        <el-table-column align="center" prop="planName" label="预案名称">
        </el-table-column>
        <el-table-column align="center" prop="fileName" label="预案附件">
          <template slot-scope="scope">
            <el-button type="text" @click="handleFile(scope.row)">{{
              scope.row.fileName
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="planType" label="预案类型">
        </el-table-column>
        <el-table-column align="center" prop="uploaderName" label="上传人">
        </el-table-column>
        <el-table-column
          align="center"
          prop="uploadTime"
          label="上传时间"
          :formatter="dateFormat"
        >
        </el-table-column>
        <el-table-column align="center" prop="expireTime" label="到期时间">
        </el-table-column>
        <el-table-column align="center" label="操作" width="230">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="handleLook(scope.row)" -->
            <el-button
              type="text"
              @click="handleUpate(scope.row)"
              v-has="'emergency:update'"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="handleDelete(scope.row)"
              v-has="'emergency:del'"
              >删除</el-button
            >
            <el-popover placement="left" width="50" trigger="click">
              <el-button
                type="text"
                @click="handleCardImport(scope.row)"
                v-has="'emergency:import'"
                >应急处置卡导入</el-button
              >
              <el-button slot="reference" type="text" style="margin-left:10px"
                >更多</el-button
              >
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>

    <EmergencyResponseCardForm
      ref="modalForm"
      :sitelist="linetree"
      :options="options"
      @formSubmit="formSubmit"
      :dept="companyPlans"
    ></EmergencyResponseCardForm>
    <EmergencyCardImport ref="cardForm"></EmergencyCardImport>
    <previvew ref="look" :src="src" @srcClose="srcClose"></previvew>
  </div>
</template>

<script>
import {
  EmergencyPageList,
  delEmergencyData,
  getEmergencyExport
} from "@/api/emergency"
import { tripleTree } from "@/api/map"
import { queryDictNodesById, queryDictByLabel } from "@/api/dict"
import EmergencyResponseCardForm from "./modules/EmergencyResponseCardForm.vue"
import EmergencyCardImport from "./modules/EmergencyCardImport.vue"
import previvew from "@/components/Uploader/filePreview.vue"

export default {
  components: { EmergencyResponseCardForm, EmergencyCardImport, previvew },
  data () {
    return {
      loading: false,
      deptList: [],
      queryModal: {
        planType: "",
        planName: ""
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      options: [],
      isShowPanl: false,
      linetree: [],
      user: "",
      companyPlans: 0,
      src: ""
    }
  },
  created () {
    console.log(JSON.parse(localStorage.getItem("user")), "user----------")
    this.user = JSON.parse(localStorage.getItem("user"))
    this.loadData()
    this.queryLineSiteTree()
    this.gettype()
  },
  methods: {
    //时间格式化
    dateFormat (row, column, cellValue, index) {
      console.log(row, column, cellValue, index)
      let data = row[column.property]
      let dtime = new Date(data)
      const year = dtime.getFullYear()
      let month = dtime.getMonth() + 1
      if (month < 10) {
        month = "0" + month
      }
      let day = dtime.getDate()
      if (day < 10) {
        day = "0" + day
      }
      let hour = dtime.getHours()
      if (hour < 10) {
        hour = "0" + hour
      }
      let minute = dtime.getMinutes()
      if (minute < 10) {
        minute = "0" + minute
      }
      let second = dtime.getSeconds()
      if (second < 10) {
        second = "0" + second
      }
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      )
    },

    radioChange (val) {
      console.log(val)
      this.companyPlans = val
      this.loadData()
    },
    // 预览图片地址清空
    srcClose () {
      this.src = ""
    },
    //预览预案的地址
    handleFile (row) {
      console.log(row)
      this.src = row.planFile
      this.$nextTick(() => {
        this.$refs.look.handleOpen()
      })
    },
    //预案类型
    gettype () {
      queryDictByLabel({ label: "事件类型" }).then(el => {
        queryDictNodesById({ id: el.data.id }).then(res => {
          this.options = res.data
        })
      })
    },
    //获取线路树
    queryLineSiteTree () {
      tripleTree({}).then(res => {
        if (res.code == 0) {
          let lineSiteData = JSON.parse(JSON.stringify(res.data))
          this.linetree = lineSiteData
        }
      })
    },
    formSubmit () {
      this.loadData()
    },
    // 表格数据
    loadData () {
      this.loading = true
      const params = {
        belonging: this.companyPlans,
        planName: this.queryModal.planName ? this.queryModal.planName : null,
        planType: this.queryModal.planType ? this.queryModal.planType : null,
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize
      }
      EmergencyPageList(params).then(res => {
        if (res.code == 0) {
          this.loading = false
          if (res.data.records && res.data.records.length > 0) {
            res.data.records.forEach(element => {
              if (element.vendorType == 1) {
                element.vendorType = "建设单位"
              } else if (element.vendorType == 2) {
                element.vendorType = "监理单位"
              } else if (element.vendorType == 3) {
                element.vendorType = "施工单位"
              }
            })
            this.tableData = res.data.records
          } else {
            this.tableData = []
          }
          this.page.pageNo = res.data.current
          this.page.pageSize = res.data.size
          this.page.total = res.data.total
          this.page.count = res.data.count
        } else {
          this.loading = false
        }
      })
    },
    //新增
    handleAdd () {
      this.$nextTick(() => {
        this.$refs.modalForm.handleAdd()
      })
    },
    // 编辑
    handleUpate (data) {
      this.$nextTick(() => {
        this.$refs.modalForm.handleUpate(data)
      })
    },
    // 导入
    handleImport () {
      this.$nextTick(() => {
        this.$refs.importForm.handleImport()
      })
    },
    //应急处置卡导入
    handleCardImport (row) {
      this.$nextTick(() => {
        this.$refs.cardForm.handleCardImport(row)
      })
    },
    handleFormSubmit () {
      this.loadData()
    },
    //查询
    handleSearch () {
      this.loadData()
    },
    //导出
    handleExport () {
      const params = {
        planName: this.queryModal.planName ? this.queryModal.planName : "-",
        vendorType: this.queryModal.vendorType
          ? this.queryModal.vendorType
          : "-"
      }
      getEmergencyExport(params).then(res => {
        const aLink = document.createElement("a")
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel;charset=utf-8"
        })
        aLink.href = URL.createObjectURL(blob)
        aLink.download = "预案管理.xlsx"
        aLink.click()
        document.body.appendChild(aLink)
      })
    },
    //删除
    handleDelete (row) {
      console.log(row)
      this.$confirm("是否删除该预案?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          delEmergencyData(row).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "删除成功！",
                type: "success"
              })
              this.loadData()
            } else {
              this.$message.error("删除失败！")
            }
          })
        })
        .catch(() => { })
    },
    // 分页器事件
    handleCurrentChange (val) {
      this.page.pageNo = val
      this.loadData()
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.loadData()
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  .handle-wrapper {
    min-width: 240px;
    max-width: 30vw;
    height: 60vh;
    position: absolute;
    top: 10%;
    left: 20px;
    background: rgba(255, 255, 255, 1);
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
    border-radius: 0px 20px 20px 0px;
    padding: 20px;
    z-index: 6;

    .tree-wrapper {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }

    .toggle-btn {
      width: 28px;
      height: 240px;
      position: absolute;
      right: 0px;
      top: 50%;
      margin-top: -120px;
      cursor: pointer;

      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }

    .toggle-btn-close {
      transform: rotate(180deg);
    }

    .toggle-btn-open {
      transform: rotate(0deg);
    }
  }

  .handle-wrapper-hide {
    left: -212px;
    background: transparent;
    box-shadow: none;
  }

  .handle-wrapper-show {
    left: 0;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #43a5e9;
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
  }
}
</style>
