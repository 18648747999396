<template>
  <div class="content">
    <div class="info-wrapper">
      <el-table
        :data="tableHeaderData"
        height="110"
        border
        style="width:100%;margin-bottom:20px"
      >
        <!-- <el-table-column
          align="center"
          prop="optionUnitName"
          show-overflow-tooltip
          label="所属总承包部"
        ></el-table-column> -->
        <el-table-column
          align="center"
          prop="teamStation"
          show-overflow-tooltip
          label="队伍驻地"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="rescueScope"
          show-overflow-tooltip
          label="救援区域"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="captainName"
          show-overflow-tooltip
          label="负责人"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="teamTel"
          show-overflow-tooltip
          label="联系方式"
        >
        </el-table-column>
      </el-table>
      <div class="choice">
        <span
          style="margin-right:10px"
          v-for="item in radioData"
          :key="item.value"
        >
          <el-radio v-model="radio" @change="radioChange" :label="item.value">{{
            item.label
          }}</el-radio>
        </span>
      </div>
      <div class="line"></div>
      <el-form :inline="true" :model="libraryForm" class="demo-form-inline">
        <el-form-item v-show="radio == 1">
          <el-input
            v-model="libraryForm.name"
            placeholder="请输入姓名搜索"
          ></el-input>
        </el-form-item>
        <el-form-item v-show="radio == 2 || radio == 3">
          <el-input
            v-model="libraryForm.label"
            placeholder="请输入名称搜索"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="libraryQuery">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table
        :data="tableData"
        height="420"
        border
        style="width: 100%"
        @cell-mouse-enter="handleCellMouseEnter"
        @cell-mouse-leave="handleCellMouseLeave"
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="60"
        ></el-table-column>
        <el-table-column
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.minWidth"
          align="center"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>
              <el-image
                style="width: 40px; height: 40px"
                v-if="item.prop == 'workerImg' && scope.row.workerImg"
                :src="scope.row.workerImg ? scope.row.workerImg : ''"
                :fit="fit"
              ></el-image>
              <!--  <img
                v-if="item.prop == 'workerImg'"
                :src="scope.row.workerImg"
                style="width:40px; height: 40px; margin:0 auto"
              /> -->
            </span>

            <span
              v-if="item.prop !== 'workerImg' && item.prop !== 'usages'"
              style="
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >{{ scope.row[item.prop] }}</span
            >
            <span
              v-if="item.prop == 'usages' && scope.row.usages !== undefined"
              >{{ JSON.parse(scope.row.usages).join(",") }}</span
            >
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" v-if="radio == 1">
          <template slot-scope="scope">
            <!--  <el-button @click="yuyin(scope.row)" type="text" size="small"
              >语音</el-button
            >
            <el-button @click="yuyin(scope.row)" type="text" size="small"
              >视频</el-button
            > -->
            <el-button
              @click="handelDetile(scope.row)"
              type="text"
              size="small"
              v-if="radio == 1"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="libraryForm.currentPage"
        :page-size="libraryForm.size"
        layout="prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>
    <MemberForm ref="modelForm"></MemberForm>
  </div>
</template>

<script>
import { getWorkers, getMaterialsPage } from "@/api/eres";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import MemberForm from "./MemberForm.vue";
export default {
  props: {
    fullTimeTeam: {
      type: Object,
      default: () => {},
    },
  },
  components: { MemberForm },
  data() {
    return {
      libraryForm: {
        label: "",
        modelNo: "",
        state: "",
        siteIds: [],
        type: "126",
        currentPage: 1,
        size: 10,
        name: "",
        postId: "-",
        teamId: "",
      },
      dict: {
        model: [],
        usages: [],
        state: [
          {
            label: "正常",
            value: 1,
          },
          {
            label: "异常",
            value: 2,
          },
        ],
      },
      tableData: [],
      tableHeaderData: [],
      radio: 1,
      radioData: [
        {
          label: "人员名单",
          value: 1,
        },
        {
          label: "物资清单",
          value: 2,
        },
        {
          label: "设备清单",
          value: 3,
        },
      ],
      total: 0,
      columns: [
        { prop: "workerName", label: "姓名", minWidth: 80 },
        { prop: "workRoleName", label: "职务", minWidth: 80 },
        { prop: "workerPhone", label: "联系电话", minWidth: 80 },
        { prop: "workerEducation", label: "学历", minWidth: 80 },
        { prop: "workerSpecialty", label: "专业", minWidth: 80 },
        { prop: "workerImg", label: "照片", minWidth: 80 },
      ],
    };
  },
  created() {
    this.getTable();
  },
  watch: {
    "fullTimeTeam.id": {
      handler(newVal) {
        console.log(newVal);
        this.getDatalist();
        this.getTableData();
        this.radio = 1;
        (this.columns = [
          { prop: "workerName", label: "姓名", minWidth: 80 },
          { prop: "workRoleName", label: "职务", minWidth: 80 },
          { prop: "workerPhone", label: "联系电话", minWidth: 80 },
          { prop: "workerEducation", label: "学历", minWidth: 80 },
          { prop: "workerSpecialty", label: "专业", minWidth: 80 },
          { prop: "workerImg", label: "照片", minWidth: 80 },
        ]),
          this.radioChange(this.radio);
      },
    },
    deep: true,
    immediate: false,
  },
  mounted() {
    this.getDatalist();
    this.getTableData();
    this.libraryForm.siteIds = [];
    console.log(this.fullTimeTeam, "8888");
    this.libraryForm.siteIds.push(this.fullTimeTeam.siteId);
    this.getDictData("物资用途", this.dict.usages);
  },
  methods: {
    handelDetile(row) {
      console.log(row);
      this.$refs.modelForm.handleAdd(row);
    },
    getTableData() {
      this.tableHeaderData = [];
      this.tableHeaderData.push({
        optionUnitName: this.fullTimeTeam.optionUnitName,
        teamStation: this.fullTimeTeam.teamStation,
        rescueScope: this.fullTimeTeam.rescueScope,
        captainName: this.fullTimeTeam.captainName,
        teamTel: this.fullTimeTeam.captainPhone,
      });
    },
    getTable() {
      this.columns = [
        { prop: "workerName", label: "姓名", minWidth: 80 },
        { prop: "workRoleName", label: "职务", minWidth: 80 },
        { prop: "workerPhone", label: "联系电话", minWidth: 80 },
        { prop: "workerEducation", label: "学历", minWidth: 80 },
        { prop: "workerSpecialty", label: "专业", minWidth: 80 },
        { prop: "workerImg", label: "照片", minWidth: 80 },
      ];
    },
    getDatalist() {
      this.loading = true;
      const params = {
        teamId: this.fullTimeTeam.id,
        postId: this.libraryForm.postId ? this.libraryForm.postId : "-",
        name: this.libraryForm.name ? this.libraryForm.name : "-",
        page: this.libraryForm.currentPage,
        size: this.libraryForm.size,
      };
      getWorkers(params).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.records;
          this.loading = false;
          this.total = res.data.count;
        }
      });
    },
    getListData(val) {
      this.loading = true;
      const params = {
        materialType: 2,
        siteIds: [],
        type: val,
        state: this.libraryForm.state,
        modelNo: this.libraryForm.modelNo,
        label: this.libraryForm.label,
        page: this.libraryForm.currentPage,
        size: this.libraryForm.size,
        rescueTeamId: this.fullTimeTeam.id,
      };
      getMaterialsPage(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          this.total = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },
    radioChange(val) {
      if (val == 1) {
        this.radio = 1;
        this.libraryForm.currentPage = 1;
        this.libraryForm.size = 10;
        this.columns = [
          { prop: "workerName", label: "姓名", minWidth: 80 },
          { prop: "workRoleName", label: "职务", minWidth: 80 },
          { prop: "workerPhone", label: "联系电话", minWidth: 80 },
          { prop: "workerEducation", label: "学历", minWidth: 80 },
          { prop: "workerSpecialty", label: "专业", minWidth: 80 },
          { prop: "workerImg", label: "照片", minWidth: 80 },
        ];
        this.getDatalist();
        // this.$refs.libraryForm.resetFields();
      } else if (val == 2) {
        this.radio = 2;
        this.libraryForm.currentPage = 1;
        this.libraryForm.size = 10;
        this.columns = [
          { prop: "label", label: "物资名称", minWidth: 80 },
          { prop: "model", label: "规格/型号", minWidth: 80 },
          { prop: "unit", label: "计量单位", minWidth: 80 },
          { prop: "count", label: "库存数量", minWidth: 80 },
          { prop: "usages", label: "用途", minWidth: 80 },
          { prop: "remark", label: "备注", minWidth: 80 },
        ];

        let params = "125";
        this.getListData(params);
        // this.$refs.libraryForm.resetFields();
      } else if (val == 3) {
        this.radio = 3;
        this.libraryForm.currentPage = 1;
        this.libraryForm.size = 10;
        this.columns = [
          { prop: "label", label: "设备名称", minWidth: 80 },
          { prop: "model", label: "规格/型号", minWidth: 80 },
          { prop: "unit", label: "计量单位", minWidth: 80 },
          { prop: "count", label: "库存数量", minWidth: 80 },
          { prop: "usages", label: "用途", minWidth: 80 },
          { prop: "remark", label: "备注", minWidth: 80 },
        ];
        let params = "126";
        this.getListData(params);
        // this.$refs.libraryForm.resetFields();
      }
    },
    clearCloseData() {
      console.log("关闭");
      this.radio = 1;
      this.libraryForm.currentPage = 1;
      this.libraryForm.size = 10;
    },
    // 查询
    libraryQuery() {
      if (this.radio == 1) {
        this.getDatalist();
      } else if (this.radio == 2) {
        let params = "125";
        this.getListData(params);
      } else if (this.radio == 3) {
        let params = "126";
        this.getListData(params);
      }
    },

    handleCurrentChange(val) {
      this.libraryForm.currentPage = val;
      console.log(val);
      console.log(this.radio);
      if (this.radio == 1) {
        this.getDatalist();
      } else if (this.radio == 2) {
        let params = "125";
        this.getListData(params);
      } else if (this.radio == 3) {
        let params = "126";
        this.getListData(params);
      }
    },
    handleSizeChange(size) {
      this.libraryForm.size = size;
      if (this.radio == 1) {
        this.getDatalist();
      } else if (this.radio == 2) {
        let params = "125";
        this.getListData(params);
      } else if (this.radio == 3) {
        let params = "126";
        this.getListData(params);
      }
    },
    yuyin(val) {
      console.log(val);
    },
    shipin(val) {
      console.log(val);
    },
    duanxian(val) {
      console.log(val);
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 鼠标滑入单元格
    handleCellMouseEnter(row, column, cell, event) {
      if (column.property == "usages") {
        cell.querySelector(".cell").style.cursor = "pointer";

        const labelArr = [];
        const usageStr = cell.querySelector(".cell").innerText;
        console.log(usageStr, "当前单元格的内容");
        this.usageCache = usageStr;
        let usageArr = [];
        if (usageStr.length) {
          usageArr = usageStr.split("");
        }
        console.log(usageArr, "split后的结果");
        for (let usage of usageArr) {
          const tempObj = this.dict.usages.find((item) => item.label == usage);
          if (tempObj) {
            labelArr.push(tempObj);
          }
        }
        let str = "";
        for (let item of labelArr) {
          str += `<li>${item.label}.${item.intro}</li>`;
        }

        cell.querySelector(".cell").innerHTML = `<ul>${str}</ul>`;
      }
    },
    // 鼠标移出单元格
    handleCellMouseLeave(row, column, cell, event) {
      if (column.property == "usages") {
        console.log(row, column, cell, event);
        cell.querySelector(".cell").innerHTML = this.usageCache;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .content {
//   height: 500px;
//   overflow-y: auto;
// }
.info-wrapper {
  ::v-deep .el-table--border,
  .el-table::before {
    border: 0 !important;
    height: 0px;
  }
}
.peopleNum {
  color: #000;
  font-weight: bold;
}
::v-deep .el-radio-button__inner {
  width: 200px;
}
.choice {
  display: flex;
  flex-direction: row;
}
.line {
  height: 1px;
  width: 100%;
  background: #143363;
  margin-bottom: 20px;
}
::v-deep .el-input__inner {
  background: #081742;
  border-color: #25458f;
  color: #fff;
}
.el-table--border::after {
  width: 0;
}
::v-deep .el-table th.is-leaf,
::v-deep .el-table--border td {
  border: 1px solid #143363 !important;
}
// ::v-deep .el-table {
//   border: 0 !important;
//   border-bottom: none;
// }
</style>
