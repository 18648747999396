<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" :title="title">
    <el-form v-loading="loading" :model="model" :rules="rules" ref="modelForm" label-width="120px" class="demo-model">
      <el-form-item label="线路工点" prop="lineSite">
        <el-cascader
          v-model="model.lineSite"
          :options="lineSiteData"
          @change="handleLineSiteChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="培训类型" prop="category">
        <el-select v-model="model.category" placeholder="请选择培训类型">
          <el-option v-for="item of dictOption.category" :key="item.id" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="培训主题" prop="label">
        <el-input v-model="model.label" placeholder="请输入培训主题"></el-input>
      </el-form-item>
      <el-form-item label="主讲人" prop="teacher">
        <el-input v-model="model.teacher" placeholder="请输入主讲人名"></el-input>
      </el-form-item>
      <el-form-item label="培训时间" prop="trainDate">
        <el-date-picker
          v-model="model.trainDate"
          type="datetime"
          placeholder="请选择培训时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="计划参与人数" prop="planCount">
        <el-input v-model="model.planCount" placeholder="请输入计划参与人数"></el-input>
      </el-form-item>
      <el-form-item label="实际参与人数" prop="joinCount">
        <el-input v-model="model.joinCount" placeholder="请输入实际参与人数"></el-input>
      </el-form-item>
      <el-form-item label="签到表" prop="checkinTable">
        <image-uploader
          v-model="model.checkinTable"
        ></image-uploader>
      </el-form-item>
      <el-form-item label="现场照片" prop="localePhoto">
        <cover-uploader
          v-model="model.localePhoto"
          @uploadChange="urlChange"
        ></cover-uploader>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { addTrain, editTrain } from "@/api/stand";
import { queryDictByLabel, queryDictNodesById, queryDictList } from "@/api/dict";
import CoverUploader from "@/components/Uploader/CoverUploader.vue";
import ImageUploader from "@/components/Uploader/ImageUploader.vue";
export default {
  components: {
    systemModal,
    CoverUploader,
    ImageUploader
  },
  data() {
    return {
      title: "新增",
      model: {
        lineSite: "",
        lineId: "",
        siteId: "",
        label: "",
        teacher: "",
        planCount: "",
        localePhoto: []
      },
      photoList: [],
      dictOption: {
        state: [],
        category: []
      },
      loading: false,
      rules: {
        // lineSite: [
        //   { required: true, message: '请选择线路工点', trigger: 'change' },
        // ],
        // realName: [
        //   { required: true, message: '请输入姓名', trigger: 'blur' },
        // ],
      }
    };
  },
  props: {
    lineSiteData: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  mounted() {
    // 应急培训类型
    const params = {
      label: "-",
      parent: "-",
      canModify: "-",
      page: 1,
      size: 1000,
    }
    queryDictList(params);
    this.getDictData("应急培训类型", this.dictOption.category);
  },
  methods: {
    getDictData(label, target) {
      queryDictByLabel({ label }).then(res => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then(res => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          })
        }
      });
    },
    // 线路工点树选择
    handleLineSiteChange(val) {
      this.model.lineId = val[0];
      this.model.siteId = val[1];
    },
    handleAdd(record) {
      if (record) {
        this.title = "编辑";
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
        this.$set(this.model, "lineSite", [record.lineId, record.siteId]);
      } else {
        this.title = "新增";
      }
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.model.id) {
            const params = {
              id: this.model.id,
              data: this.model
            };
            editTrain(params).then(res => {
              if (res.code == 0) {
                this.$message.success("操作成功");
                this.$emit("formSubmit");
                this.resetModel();
                this.$refs.formModal.handleClose();
              }
            });
          } else {
            const params = JSON.parse(JSON.stringify(this.model));
            params.localePhoto = this.photoList;
            addTrain(params).then(res => {
              if (res.code == 0) {
                this.$message.success("操作成功");
                this.$emit("formSubmit");
                this.$refs.formModal.handleClose();
                this.resetModel();
              }
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
    urlChange(urlList) {
      this.photoList = [];
      console.log(urlList)
      for (let item of urlList) {
        this.photoList.push({
          file_name: item.name,
          link: item.url
        });
      }
    },
    resetModel() {
      this.model = {
        lineSite: "",
        lineId: "",
        siteId: "",
        label: "",
        teacher: "",
        planCount: "",
        localePhoto: []
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
