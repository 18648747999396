<template>
  <!-- 风险巡查历史记录 -->
  <div>
    <el-dialog
        width="50%"
        title="风险巡查历史记录"
        class="editDialog details-dialog"
        top="10vh"
        :visible.sync="dialogHistory"
        v-if="dialogHistory"
        center
        append-to-body
        :before-close="handleHistoryClose"
    >
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="index" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column
            prop="progressDescribe"
            align="center"
            label="风险进展文字说明"
            width="180"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="companyTypeName"
            align="center"
            label="检查单位"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            prop="submitPersonName"
            align="center"
            label="检查人"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="view(scope.row)">查看</el-button> -->
            <el-button type="text" @click="handleView(scope.row, 'prode')">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>


    <el-dialog
        width="50%"
        title="风险进展历史记录"
        class="editDialog details-dialog"
        top="10vh"
        :visible.sync="dialogVisible"
        v-if="dialogVisible"
        center
        append-to-body
        :close-on-click-modal="false"
    >
      <historyDetail v-if="dialogVisible" :row-data="rowData"></historyDetail>
    </el-dialog>

  </div>


</template>

<script>
import {progressList} from "@/api/riskManage";
import {queryDeptDetail} from "@/api/stand";
import historyDetail from "@/views/RiskManagement/System/RiskProcess/components/historyDetail";

export default {
  props: {
    dialogHistory: {
      type: Boolean,
      default: false
    },
    riskDeafUuid : {
      default : ''
    },
  },
  components : {
    historyDetail
  },
  data() {
    return {
      // dialogHistory: false
      tableData: [
        { patrolName: "2022年11月19日共处0出问题" },
        { patrolName: "2022年11月19日共处0出问题" },
        { patrolName: "2022年11月19日共处0出问题" },
        { patrolName: "2022年11月19日共处0出问题" },
        { patrolName: "2022年11月19日共处0出问题" }
      ],
      deptType : '',
      dialogVisible : false,
      rowData : {}
    };
  },
  created() {
    this.getDeptDetail();
    this.getTableData();
  },
  methods: {
    handleHistoryClose() {
      this.$emit("closedialogHistory");
    },
    handleView(row) {
      this.rowData = {...row};
      this.dialogVisible = true;
    },
    getTableData(){
      progressList(this.riskDeafUuid).then(res => {
        this.tableData = res.data;
      })
    },
    //查询属于哪个角色
    getDeptDetail() {
      console.log(JSON.parse(localStorage.getItem("user")));
      var userInfo = JSON.parse(localStorage.getItem("user"));
      queryDeptDetail(userInfo.companyId).then((res) => {
        console.log(res);
        this.deptType = res.data ? res.data.category : "0";
      });
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .details-dialog {
  .el-dialog__header {
    .el-dialog__title {
      position: static;
    }
  }
  .el-dialog__body {
    height: 680px;
    // max-height: 680px;
    overflow: auto;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
/*::v-deep .el-table__fixed-right{
  height: 100% !important;
}*/
</style>
