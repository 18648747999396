<template>
  <div class="min-h-full bg-white">
    <div class="flex justify-between p-4">
      <span>
        <el-input
          class="mr-2"
          v-model="page.name"
          clearablecesdsdsd
          placeholder="角色名称"
          style="width: 120px"
        ></el-input>
        <el-select
          class="mr-2"
          v-model="page.unitType"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in unitTypeData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="findData">搜索</el-button>
      </span>
      <span>
        <el-button type="primary" @click="opAdd" v-has="'role:add'"
          >新增角色</el-button
        >
      </span>
    </div>
    <div class="px-4">
      <el-table :data="list" style="width: 100%" v-loading="loading">
        <el-table-column prop="roleName" align="center" label="角色名称">
        </el-table-column>
        <el-table-column prop="unitTypeName" align="center" label="单位类型">
        </el-table-column>
        <el-table-column prop="creatorName" align="center" label="创建人">
        </el-table-column>
        <el-table-column prop="created" align="center" label="创建时间">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <div>
              <el-button
                type="text"
                size="small"
                @click="opUpdata(scope.row)"
                v-has="'role:assign'"
                class="mr-4"
                >编辑
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="roleDelete(scope.row)"
                v-has="'role:del'"
                >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex justify-center mt-4">
        <el-pagination
          background
          @current-change="getTableList"
          :current-page.sync="page.pageNo"
          :page-size="page.pageSize"
          layout="prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </div>
    <AddRole ref="modalForm" @onSubmit="handleFormSubmit"></AddRole>
  </div>
</template>

<script>
import { getRole, deleteRole } from "@/api/role";
import { getDictData } from "@/api/dict";

// import { rpermissionList } from "@/api/resource";

import AddRole from "../Roles/roleForm";
export default {
  name: "roleList",
  components: { AddRole },
  data() {
    return {
      list: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 1,
        name: "",
      },
      loading: false,
      // 单位类型
      unitTypeData: [],
    };
  },
  mounted() {
    this.getTableList();
    this.getDictDatas();
  },
  methods: {
    // 获取单位类型
    getDictDatas() {
      let label = "unitType";
      this.unitTypeData = [];
      getDictData(label).then((res) => {
        if (res.code == 0) {
          res.data.forEach((element) => {
            this.unitTypeData.push({
              label: element.label,
              value: element.code,
            });
          });
        }
      });
    },

    //获取角色列表
    async getTableList() {
      this.loading = true;
      let params = {
        name: this.page.name == "" || !this.page.name ? "-" : this.page.name,
        companyId: "-",
        departmentId: "-",
        parentId: "-",
        unitType:
          this.page.unitType == "" || !this.page.unitType
            ? "-"
            : this.page.unitType,
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      };
      let res = await getRole(params);
      if (res.code === 0) {
        this.loading = false;
        this.list = res.data.records || [];
        this.page.total = res.data.count;
      }
    },
    //搜索
    findData() {
      this.page.pageNo = 1;
      this.getTableList();
    },
    //打开修改弹窗
    opUpdata(record) {
      this.$refs.modalForm.handleAdd(record);
      console.log(111, record);
    },
    //删除角色
    async deletefun(data) {
      let delres = await deleteRole(data.id);
      if (delres.code === 0) {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getTableList();
      } else {
        this.$message.error(delres.message);
      }
    },
    //删除角色确认
    async roleDelete(data) {
      this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.deletefun(data);
      });
    },
    //打开新增弹窗
    opAdd() {
      this.$refs.modalForm.handleAdd();
    },
    // form弹窗关闭
    handleFormSubmit() {
      this.getTableList();
    },
  },
};
</script>

<style scoped>
.el-pagination__jump {
  margin-top: -6px;
}
</style>
