<template>
  <div class="page-container bg-white p-4 h-full relative">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="线路">
            <el-select v-model="queryModal.lineId" placeholder="请选择线路">
              <el-option
                v-for="item of dict.lineSiteData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期">
            <el-date-picker
              v-model="queryModal.checkDate"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button
            type="primary"
            @click="handleAdd"
            v-has="'securityChecks:add'"
            >新增检查</el-button
          >
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路-工点"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope"
            >{{ scope.row.lineName }} - {{ scope.row.siteName }}</template
          >
        </el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="检查单位"
        ></el-table-column>
        <el-table-column align="center" prop="safeCheckStaff" label="检查人员">
          <template slot-scope="scope">
            <ul>
              <li v-for="item of scope.row.safeCheckStaff" :key="item.staffId">
                {{ item.staffName }}
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="checkDate"
          label="检查日期"
        ></el-table-column>
        <el-table-column align="center" prop="imageName" label="附件">
          <template slot-scope="scope">
            <el-button type="text" @click="handleFilePreview">{{
              scope.row.imageName
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="state"
          label="状态"
          :formatter="stateLabel"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="note"
          label="备注"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-has="'securityChecks:edit'"
              class="ml-4"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="handleExamine(scope.row)"
              v-show="scope.row.state == 1"
              v-has="'securityChecks:examine'"
              class="ml-4"
              >审核</el-button
            >
            <el-button type="text" @click="handleDetail(scope.row)" class="ml-4"
              >详情</el-button
            >

            <el-button
              type="text"
              @click="handleDelete(scope.row)"
              v-has="'securityChecks:del'"
              class="ml-4"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <security-checks-form
      ref="modalForm"
      @modelFormSubmit="handleModelFormSubmit"
    ></security-checks-form>
    <security-checks-detail ref="modalDetail"></security-checks-detail>
    <file-preview ref="filePreview" :src="fileUrl"></file-preview>
    <security-checks-examine
      ref="examineModal"
      @examineOver="handleExamineOver"
    ></security-checks-examine>
  </div>
</template>

<script>
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import SecurityChecksForm from "./modules/SecurityChecksForm.vue";
import SecurityChecksDetail from "./modules/SecurityChecksDetail.vue";
import { querySafeCheckPage, delSafeCheck } from "@/api/map";
import filePreview from "@/components/Uploader/filePreview.vue";
import SecurityChecksExamine from "./modules/SecurityChecksExamine.vue";
export default {
  components: {
    SecurityChecksForm,
    SecurityChecksDetail,
    filePreview,
    SecurityChecksExamine,
  },
  data() {
    return {
      queryModal: {
        checkDate: "",
        lineId: "",
        state: "",
        date: "",
      },
      tableData: [],
      loading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      dict: {
        lineSiteData: [],
        state: [],
      },
      fileUrl: "",
    };
  },
  mounted() {
    this.queryLineSiteTreeData();
    // this.getDictData("物资规格型号", this.dict.model);
  },
  methods: {
    handleSearch() {
      this.loadData();
    },
    handleAdd() {
      this.$refs.modalForm.handleAdd();
    },
    handleEdit(record) {
      this.$refs.modalForm.handleAdd(record);
    },
    handleExamine(record) {
      this.$refs.examineModal.handleOpen(record);
    },
    handleDetail(record) {
      this.$refs.modalDetail.handleDetail(record);
    },
    handleDelete(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delSafeCheck(record.id).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadData();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 线路工点树数据查询
    queryLineSiteTreeData() {
      this.loading = true;
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.dict.lineSiteData = lineSiteData;
        this.loadData();
      }
    },
    // 加载表格数据
    loadData() {
      this.loading = true;
      const params = {
        lineId: this.queryModal.lineId ? this.queryModal.lineId : "-",
        checkDate: this.queryModal.checkDate ? this.queryModal.checkDate : "-",
        page: this.page.pageNo,
        size: this.page.pageSize,
      };
      querySafeCheckPage(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          this.page.pageNo = res.data.current ? res.data.current : 1;
          this.page.pageSize = res.data.size ? res.data.size : 10;
          this.page.total = res.data.total ? res.data.total : 0;
          this.page.count = res.data.count ? res.data.count : 0;
        } else {
          this.loading = false;
        }
      });
    },
    stateLabel(row) {
      switch (row.state) {
        case 1:
          return "审核中";
        case 2:
          return "审核未通过";
        case 3:
          return "审核已通过";
      }
    },
    handleModelFormSubmit() {
      this.loadData();
    },
    handleExamineOver() {
      this.loadData();
    },
    handleFilePreview(record) {
      this.fileUrl = record.imageUrl;
      this.$refs.filePreview.handleOpen();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 11;
}
</style>
