<template>
  <systemModal
    ref="formModal"
    @modalConfirm="handleSubmit"
    :title="title"
    width="40%"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="120px"
      class="demo-model"
      style="width: 80%"
    >
      <el-form-item label="队伍名称" prop="teamName">
        <el-input
          v-model="model.teamName"
          placeholder="请输入队伍名称"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="所属承包部" prop="optionUnitName">
        <el-select
          v-model="model.optionUnitName"
          placeholder="请选择所属承包部"
          @change="handleDepartChange"
        >
          <el-option
            v-for="item of dictOption.unitName"
            :key="item.id"
            :label="item.departmentName"
            :value="item.departmentName"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="队伍驻地" prop="teamStation">
        <el-input
          v-model="model.teamStation"
          placeholder="请输入队伍驻地"
        ></el-input>
      </el-form-item>
      <el-form-item label="救援区域" prop="rescueScope">
        <el-input
          v-model="model.rescueScope"
          placeholder="请输入救援区域"
        ></el-input>
      </el-form-item>
      <el-form-item label="人数" prop="numOfPeople" v-if="title == '编辑'">
        <el-input
          v-model="model.numOfPeople"
          placeholder="请输入人数"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="负责人" prop="captainName">
        <el-input
          v-model="model.captainName"
          placeholder="请输入负责人"
          
        ></el-input>
        
      </el-form-item>
      <el-form-item label="联系电话" prop="captainPhone">
        <el-input
          v-model="model.captainPhone"
          placeholder="请输入联系电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="坐标">
        <el-button type="primary" @click="choseMap">点击选择</el-button>
      </el-form-item>
      <el-form-item label="经度" prop="lng">
        <el-button disabled>{{ model.lng }}</el-button>
      </el-form-item>
      <el-form-item label="纬度" prop="lat">
        <el-button disabled>{{ model.lat }}</el-button>
      </el-form-item>
    </el-form>
    <MapForm
      ref="mapForm"
      @formSubmit="handleSubmit"
      :getCoo="getCoo"
    ></MapForm>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue'
import MapForm from './map.vue'
import { addStance, updateStance } from '@/api/eres'
// import { allPeople } from '@/api/ucenter'
// import { dept } from '@/api/org'
export default {
  components: {
    systemModal,
    MapForm,
  },
  data() {
    return {
      title: '新增',
      model: {
        teamName: '',
      
        optionUnitUuid: '',
        teamStation: '',
        rescueScope: '',
        captainName: '',
        captainPhone: '',
        captainUuid: '',
        lng: '',
        lat: '',
      },
      dictOption: {
       
      
      },
      loading: false,
      rules: {
        teamName: [
          { required: true, message: '请输入队伍名称', trigger: 'blur' },
        ],
        teamStation: [
          { required: true, message: '请输入队伍驻地', trigger: 'blur' },
        ],
        rescueScope: [
          { required: true, message: '请输入救援区域', trigger: 'blur' },
        ],
        captainName: [
          { required: true, message: '请输入负责人', trigger: 'blur' },
        ],
        captainPhone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: '请输入合法手机号',
            trigger: 'blur',
          },
        ],
        lng: [{ required: true, message: '请选择坐标', trigger: 'blur' }],
        lat: [{ required: true, message: '请选择坐标', trigger: 'blur' }],
      },
    }
  },
  mounted() {
    this.getCoo()
    // this.getDept()
  },
  methods: {
    getCoo(lng, lat) {
      this.model.lng = lng
      this.model.lat = lat
      this.$forceUpdate()
    },
    // getDept() {
    //   let params = {
    //     category: 'build',
    //   }
    //   dept(params).then((res) => {
    //     this.dictOption.unitName = res.data
    //   })
    // },
    choseMap() {
      this.$refs.mapForm.handleAdd(
        this.model.lng,
        this.model.lat,
        this.model.teamStation
      )
    },
    // handleDepartChange(val) {
    //   console.log(this.dictOption.unitName)
    //   this.model.optionUnitUuid = this.dictOption.unitName.filter((item) => {
    //     return item.departmentName == val
    //   })[0].uuid
    //   let companyId = this.dictOption.unitName.filter((item) => {
    //     return item.departmentName == val
    //   })[0].companyId
    //   let params = {
    //     companyId: companyId,
    //   }
    //   allPeople(params).then((res) => {
    //     this.dictOption.people = res.data
    //   })
    // },
    // handlePeopleChange(val) {
    //   this.model.captainUuid = this.dictOption.people.filter((item) => {
    //     return item.realName == val
    //   })[0].uuid
    // },
    handleAdd(type, record) {
      if (record) {
        this.title = '编辑'
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)))
      } else {
        this.title = '新增'
        this.model = {}
      }
      this.model.type = type
      this.handleEdit()
    },
    handleEdit() {
      this.$refs.formModal.handleOpen()
    },

    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.model.id) {
            const params = {
              id: this.model.id,
              data: this.model,
            }
            updateStance(params).then((res) => {
              if (res.code == 0) {
                this.loading = false
                this.$message.success('操作成功')
                this.$refs.formModal.handleClose()
                this.$emit('formSubmit')
              }
            })
          } else {
            addStance(this.model).then((res) => {
              if (res.code == 0) {
                this.loading = false
                this.$message.success('操作成功')
                this.$refs.formModal.handleClose()
                this.$emit('formSubmit')
              }
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
      // this.$refs.formModal.handleClose();
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
