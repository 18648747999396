<template>
  <div class="dataInfo min-h-full bg-white">
    <div class="flex justify-between search">
      <span>
        <el-input
          class="mr-2"
          v-model="page.name"
          placeholder="输入名称查询"
          style="width: 180px"
          clearable
        ></el-input>
        <el-button type="primary" @click="findData">搜索</el-button>
      </span>
      <span>
        <el-button type="primary" @click="opAdd">新增字典项</el-button>
      </span>
    </div>
    <div class="content">
      <el-table
        v-loading="tableloading"
        :data="list"
        style="width: 100%"
        row-key="id"
        height="78vh"
        size="mini"
        :tree-props="{ children: 'nodes', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          prop="label"
          label="名称"
          show-overflow-tooltip
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          prop="code"
          label="值"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="intro"
          label="描述"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="200px">
          <template #default="scope">
            <div class="flex justify-around cursor-pointer">
              <el-button type="text" size="small" @click="userInfo(scope.row)"
                >详情
              </el-button>
              <el-button type="text" size="small" @click="opUpdata(scope.row)"
                >修改
              </el-button>
              <el-button type="text" size="small" @click="userDelete(scope.row)"
                >删除
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="userLock(scope.row)"
                v-if="scope.row.state == 0"
                >锁定
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :width="'500px'"
      :before-close="handleClose"
      :close-on-click-modal="false"
      center
    >
      <el-form
        ref="ruleForm"
        :rules="rule"
        :model="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        v-loading="fromLoading"
      >
        <el-form-item label="名称" prop="label">
          <el-input
            v-model="ruleForm.label"
            :readonly="title == '详情'"
          ></el-input>
        </el-form-item>
        <el-form-item label="上级字典" prop="parentId">
          <popup-tree-input
            :data="parentIdData"
            :props="parentIdProps"
            :nodeKey="ruleForm.parentId"
            :prop="ruleForm.parentId == '0' ? '顶级菜单' : ruleForm.parentLabel"
            :currentChangeHandle="handleCheckChange"
          >
          </popup-tree-input>
        </el-form-item>
        <el-form-item label="值" prop="code">
          <el-input
            v-model="ruleForm.code"
            :readonly="title == '详情'"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input
            v-model="ruleForm.sort"
            :readonly="title == '详情'"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="intro">
          <el-input
            v-model="ruleForm.intro"
            :readonly="title == '详情'"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="是否可编辑" prop="canModify">
          <el-switch
            v-model="ruleForm.canModify"
            :disabled="title == '详情'"
          ></el-switch>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button v-show="title != '详情'" type="primary" @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { lockUser, unlockUser } from "@/api/ucenter";

import {
  addDictInfo,
  deleteDict,
  // getDetails,
  editDictInfo,
  getDictList,
} from "@/api/dict";
import PopupTreeInput from "@/components/PopupTreeInput";

export default {
  components: {
    PopupTreeInput,
  },
  name: "userList",
  data() {
    return {
      dataInfo: [],
      id: "",
      defaultProps: {
        children: "children",
        label: "label",
      },
      list: [],
      page: {
        name: "",
      },
      loading: false,
      tableloading: false,
      fromLoading: false,
      title: "",
      dialogVisible: false,
      ruleForm: {
        parentId: "",
        intro: "",
        label: "",
        // canModify: false,
        code: "",
        sort: "",
        parentLabel: "",
      },
      parentIdData: [],
      parentIdProps: {
        children: "nodes",
        label: "label",
      },
      rule: {
        label: [{ required: true, message: "请输入名称", trigger: "blur" }],
        code: [{ required: true, message: "请输入值", trigger: "blur" }],
        parentLabel: [
          { required: true, message: "请选择上级字典", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.tableloading = true;
      let params = {
        label: "",
      };
      getDictList(params).then((res) => {
        if (res.code == 0) {
          this.tableloading = false;
          this.list = res.data;
          this.parentIdData = this.getParentMenuTree(res.data);
        } else {
          this.parentIdData = [];
        }
      });
    },
    // 获取上级机构树
    getParentMenuTree(tableTreeData) {
      let parent = {
        id: "0",
        label: "顶级菜单",
        nodes: tableTreeData,
      };
      return [parent];
    },
    // 上级单位触发方法
    handleCheckChange(data) {
      if (this.title != "详情") {
        this.ruleForm.parentId = String(data.id);
        this.ruleForm.parentLabel = data.label;
      }
    },
    //搜索
    findData() {
      this.tableloading = true;
      let params = {
        label: this.page.name,
      };
      getDictList(params).then((res) => {
        if (res.code == 0) {
          this.tableloading = false;
          this.list = res.data;
          this.parentIdData = this.getParentMenuTree(res.data);
        } else {
          this.parentIdData = [];
        }
      });
    },
    // 新增
    opAdd() {
      this.title = "新增字典项";
      this.dialogVisible = true;
      this.$refs.ruleForm.clearValidate();
      const th = this;
      Object.keys(this.ruleForm).forEach(function(key) {
        th.ruleForm[key] = "";
        // th.ruleForm.canModify = false;
      });
    },
    // 修改
    opUpdata(row) {
      row.parentId = String(row.parentId);
      this.ruleForm = Object.assign({}, row);
      // this.ruleForm.canModify = true;
      this.title = "修改";
      this.dialogVisible = true;
    },
    //提交
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.fromLoading = true;

          const form = {
            label: this.ruleForm.label,
            parentId: this.ruleForm.parentId,
            // canModify: this.ruleForm.canModify ? 1 : 0,
            intro: this.ruleForm.intro,
            introType: 0,
            code: this.ruleForm.code,
            sort: this.ruleForm.sort,
          };
          let params = {
            id: this.ruleForm.id,
            form: form,
          };
          if (this.title == "修改") {
            editDictInfo(params)
              .then((res) => {
                if (res.code == 0) {
                  this.findData();
                  this.resetForm();
                } else {
                  this.resetForm();
                  this.$message.warning(res.message);
                }
              })
              .catch((error) => {
                this.$message.warning(error.message);
                this.resetForm();
              });
          } else {
            addDictInfo(form)
              .then((res) => {
                if (res.code == 0) {
                  this.findData();
                  this.resetForm();
                } else {
                  this.resetForm();
                  this.$message.warning(res.message);
                }
              })
              .catch((error) => {
                this.$message.warning(error.message);
                this.resetForm();
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 关闭
    cancel() {
      this.resetForm();
    },
    // 关闭
    handleClose() {
      this.resetForm();
    },
    resetForm() {
      this.fromLoading = false;
      this.dialogVisible = false;
      const th = this;
      Object.keys(this.ruleForm).forEach(function(key) {
        th.ruleForm[key] = "";
      });
    },
    //根据用户id获取详情
    userInfo(data) {
      this.dialogVisible = true;
      this.title = "详情";
      data.parentId = String(data.parentId);
      this.ruleForm = Object.assign({}, data);
      // if (data.canModify == 1) {
      //   this.ruleForm.canModify = true;
      // } else {
      //   this.ruleForm.canModify = false;
      // }
    },
    //删除用户确认
    userDelete(data) {
      const id = data && data.id;
      this.$confirm("确认删除选中记录吗？", "提示", {
        type: "warning",
      }).then(() => {
        deleteDict(id).then((res) => {
          if (res.code == 0) {
            this.$message({ message: "删除成功", type: "success" });
            this.getData();
          }
        });
      });
    },

    //根据单位查询部门
    // async comChange(val) {
    //   if (val != "") {
    //     let res = await getDepts(val);
    //     if (res.code === 0) {
    //       this.deptList = res.data || [];
    //     }
    //   } else {
    //     this.page.deptId = "";
    //     this.deptList = [];
    //   }
    // },

    //解锁
    async unlockfun(data) {
      let delres = await unlockUser(data);
      if (delres.code === 0) {
        this.$message({
          type: "success",
          message: "解锁成功!",
        });
        this.getTableList();
      } else {
        this.$message.error(delres.message);
      }
    },
    //解锁确认
    async userUnlock(data) {
      this.$confirm("确认解锁该用户？解锁后用户可正常登录", "解锁", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.unlockfun(data);
      });
    },
    //锁定用户
    async lockfun(data) {
      let delres = await lockUser(data);
      if (delres.code === 0) {
        this.$message({
          type: "success",
          message: "锁定成功!",
        });
        this.getTableList();
      } else {
        this.$message.error(delres.message);
      }
    },
    //锁定用户确认
    async userLock(data) {
      this.$confirm("确认锁定该用户？锁定后用户不能登录", "锁定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.lockfun(data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dataInfo {
  width: 100%;
  padding: 0px 15px;
  box-sizing: border-box;
  .searchbar {
    display: flex;
    justify-content: space-between;
  }
  .search {
    padding: 1rem 0;
  }
}
::v-deep .el-table {
  overflow: auto;
}

.contentLeft {
  width: 200px;
  height: 700px;
  overflow-y: auto;
  margin-right: 20px;
  border: 1px solid rgb(207, 203, 203);
  margin-left: 1rem;
  float: left;
}
.px-4 {
  width: calc(100% - 220px - 1rem);
  float: right;
  height: 100%;
}
.el-pagination__jump {
  margin-top: -6px;
}
::v-deep .el-table {
  height: 620px;
}
</style>
