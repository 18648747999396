<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>

          <el-form-item label="">
            <el-cascader v-model="lineSite"
                         clearable :options="treeData" :props="props"
                         :show-all-levels="false" collapse-tags @change="handleLineSiteChange"></el-cascader>
          </el-form-item>

          <el-form-item label="">
            <el-date-picker
                v-model="queryModal.startTime"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="date"
                placeholder="开始时间">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="">
            <el-date-picker
                v-model="queryModal.endTime"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="date"
                placeholder="结束时间">
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item>
            <el-button type="primary" @click="issuedTask">下发任务</el-button>
          </el-form-item>
        </el-row>

      </el-form>
    </div>

<!--    <div class="tabs">
      <div class="tabs-item" :class="active === '开始排查' ?  'active' : '' " @click="changeTab('开始排查')">开始排查</div>
      <div class="tabs-item" :class="active === '排查记录' ?  'active' : '' " @click="changeTab('排查记录')">排查记录</div>
    </div>-->

    <div style="margin-bottom:10px">
      <el-radio-group v-model="radio" @change="changeTab">
        <el-radio-button :label="1">开始排查</el-radio-button>
        <el-radio-button :label="2">排查记录</el-radio-button>
      </el-radio-group>
    </div>

    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" type="index" label="序号" width="100"></el-table-column>
        <el-table-column align="center" v-for="(item,index) in column" :prop="item.prop" :label="item.label"
                         :key="index" :formatter="item.formatter" :width="item.width"
                         :show-overflow-tooltip="item.showOverflow" :min-width="item.minWidth">
          <template slot-scope="scope" >
            <span v-if="item.label === '标题'" class="file-item" @click="handleDetail(scope.row)"
            > {{ scope.row.taskTitle }}(共 {{ scope.row.num }} 处 ) </span>
            <span v-if="item.prop === 'cycle'">
              {{ scope.row.cycle == 1? '日' : scope.row.cycle == 2 ? '周' :
                scope.row.cycle == 3 ? '月' : scope.row.cycle == 4 ? '季' : '' }} </span>
            <span v-else> {{ scope.row[`${item.prop}`] }} </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <!-- 计划审核 -->
            <el-button type="text" v-if="radio == '1' " @click="handleStart(scope.row)">开始排查</el-button>
            <el-button type="text" v-if="radio == '2' " @click="handleDetail(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4" v-if="radio == 2">
      <el-pagination background layout="prev, pager, next"
                     :total="page.count" :page-count="page.total"
                     :page-size="page.pageSize"
                     :current-page="page.pageNo"
                     @current-change="handleCurrentChange"
                     @size-change="handleSizeChange">
      </el-pagination>
    </div>


    <el-dialog
        width="70%"
        title="专项任务下发"
        :visible.sync="dialogVisible"
        class="editDialog"
        append-to-body>
      <IssueTask v-if="dialogVisible" @cancelModal="cancelModal" @successModal="successModal"></IssueTask>
    </el-dialog>


    <el-dialog
        width="70%"
        title="专项隐患排查"
        :visible.sync="startVisible"
        class="editDialog"
        append-to-body>
      <startTrouble v-if="startVisible" :type="'专项排查'" :trouble-data="troubleData"
                    @cancelModal="cancelTrouble" @noTrouble="cancelTrouble"></startTrouble>
    </el-dialog>

    <el-dialog
        width="70%"
        :title="title"
        :visible.sync="dayVisible"
        class="editDialog"
        append-to-body>
      <dayRecord v-if="dayVisible" :type="'special_check'" :title="title" :detail-data="detailData"></dayRecord>
    </el-dialog>

  </div>
</template>

<script>
import IssueTask from "@/views/HiddenDanger/System/DangerTrouble/SpecialTrouble/components/IssueTask";
import startTrouble from "@/views/HiddenDanger/System/DangerTrouble/DayTrouble/components/startTrouble";
import dayRecord from "@/views/HiddenDanger/System/DangerTrouble/DayTrouble/components/dayRecord";
import {checkUnitType, specTroubleList, taskRecordList} from "@/api/hdanger";
import {queryDeptDetail} from "@/api/stand";
export default {
  name: "SpecialTrouble",
  components : {
    IssueTask,
    startTrouble,
    dayRecord
  },
  data(){
    return {
      queryModal : {
        workAreaUuidList : []
      },
      active : '开始排查',
      dialogVisible : false,
      startVisible : false,
      dayVisible : false,
      loading : false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      radio : 1,
      tableData : [],
      startList : [
        { prop : 'lineName' , label : '线路',showOverflow:true},
        { prop : 'branchName' , label : '分部/标段',showOverflow:true},
        { prop : 'workAreaName' , label : '工点',showOverflow:true },
        { prop : 'categoryName' , label : '专项任务类别',showOverflow:true},
        { prop : 'checkName' , label : '巡检人',showOverflow:true},
        { prop : 'leaderName' , label : '组长',showOverflow:true },
        { prop : 'num' , label : '已排查次数',showOverflow:true},
        { prop : 'startTime' , label : '开始时间' ,showOverflow:true},
        { prop : 'endTime' , label : '截止时间',showOverflow:true},
      ],
      historyList : [
        { prop : 'lineName' , label : '线路',showOverflow:true},
        { prop : 'branchName' , label : '分部/标段',showOverflow:true },
        { prop : 'workAreaName' , label : '工点',showOverflow:true },
        { prop : '' , label : '标题',showOverflow:true },
        { prop : 'categoryName' , label : '专项任务类别',showOverflow:true },
        { prop : 'checkName' , label : '巡检人',showOverflow:true},
        { prop : 'leaderName' , label : '组长',showOverflow:true },
        { prop : 'startTime' , label : '开始时间',showOverflow:true },
        { prop : 'endTime' , label : '结束时间',showOverflow:true },
      ],
      column : [],
      lineSite : [],
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      troubleData : {},
      detailData : {},
      deptType : '',
      companyName : '',
      title : '',
    }
  },
  created() {
    this.column = this.startList;
    this.getTableData();
    this.queryLineSiteTreeData();
    this.getDeptDetail();
  },
  methods : {
    changeTab(){
      this.column =  this.radio === 1 ? this.startList : this.historyList;
      this.getTableData();
    },
    getNowTime(type) {
      var date = new Date();
      //年 getFullYear()：四位数字返回年份
      var year = date.getFullYear(); //getFullYear()代替getYear()
      //月 getMonth()：0 ~ 11
      var month = date.getMonth() + 1;
      //日 getDate()：(1 ~ 31)
      var day = date.getDate();
      //时 getHours()：(0 ~ 23)
      var hour = date.getHours();
      //分 getMinutes()： (0 ~ 59)
      var minute = date.getMinutes();
      //秒 getSeconds()：(0 ~ 59)
      var second = date.getSeconds();
      var time = year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
      var today = year + '-' + this.addZero(month) + '-' + this.addZero(day);
      return type === 'time' ? time : today;
    },
    addZero(s) {
      return s < 10 ? ('0' + s) : s;
    },
    handleDetail(row){
      this.detailData = {...row};
      // this.title = '日常排查';
      this.dayVisible = true;
    },
    getDeptDetail() {
      var nowTime = this.getNowTime('today');
      var userInfo = JSON.parse(localStorage.getItem("user"));
      queryDeptDetail(userInfo.companyId).then((res) => {
        console.log(res);
        this.deptType = res.data ? res.data.category : "0";
        switch (this.deptType){
          case 'build' :
            this.companyName = '建设单位';
            break;
          case 'monitor' :
            this.companyName = '监理单位';
            break;
          case 'construct' :
            this.companyName = '施工单位';
            break;
          case 'design' :
            this.companyName = '设计单位';
            break;
          case 'third' :
            this.companyName = '第三方监测单位';
            break;
          case 'survey' :
            this.companyName = '勘察单位';
            break;
          case 'contracting' :
            this.companyName = '施工总承包单位';
            break;
          case 'fullEmergencyRescue' :
            this.companyName = '专职应急救援队';
            break;
        }
        this.title = this.companyName + nowTime + '专项排查';
      });
    },
    cancelModal(){
      this.dialogVisible = false;
    },
    successModal(){
      this.dialogVisible = false;
      this.getTableData();
    },
    queryLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    getTableData(){
      this.tableData = [];
      let params = {}
      if(this.radio == 1){
        params = {
          ...this.queryModal
        }
      }else{
        params = {
          pageNum : this.page.pageNo,
          pageSize : this.page.pageSize,
          ...this.queryModal
        }
      }
      let tableInterface = this.radio == 1 ? specTroubleList : taskRecordList
      tableInterface(params).then(res => {
        if(res.code ==0){
          if(this.radio == 2){
            this.tableData = res.data.records;
            this.page.total = res.data.count;
            this.loading = false;
          }else{
            this.tableData = res.data;
            this.loading = false;
          }
        }else{
          this.tableData = [];
          this.loading = false;
        }

      })
    },
    issuedTask(){
      checkUnitType({}).then(res => {
        console.log(res);
        if(res.code == 0){
          this.dialogVisible = true;
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // 级联选择器事件
    handleLineSiteChange(record) {
      console.log(record)
      this.queryModal.workAreaUuidList = [];
      for (let item of record) {
        this.queryModal.workAreaUuidList.push(item[item.length-1]);
      }
    },
    handleStart(row){
      this.troubleData = {...row};
      this.startVisible = true;
    },
    cancelTrouble(){
      this.startVisible = false;
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getTableData();
    },
    handleSearch(){
      this.getTableData();
    },
  }
};
</script>

<style lang="scss" scoped>
.tabs{
  display: flex;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #1684fc;
  width: 300px;
  padding: 0;
  .tabs-item{
    width: 150px;
    height: 30px;
    line-height: 30px;
    color: #1684fc;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }
  .tabs-item:first-child{
    border-right: none;
  }
  .active{
    background: #1684fc;
    color: #ffffff;
    width: 150px;
    height: 32px;
    line-height: 32px;
    border-radius: 5px;
    margin: -1px 0 0 -1px;
  }
}
::v-deep .el-table__fixed-right{
  height: 100% !important;
}
.file-item {
  color: #3090ff;
  cursor: pointer;
}
</style>
