import Layout from "@/components/Layout/SystemLayout.vue";
import IssueEarlyWarn from "@/views/RiskManagement/System/WarnManagement/IssueEarlyWarn.vue";
import WarnResponse from "@/views/RiskManagement/System/WarnManagement/WarnResponse.vue";
import WarnCorrection from "@/views/RiskManagement/System/WarnManagement/WarnCorrection.vue";
import WarnAudit from "@/views/RiskManagement/System/WarnManagement/WarnAudit.vue";
import EliminationWarn from "@/views/RiskManagement/System/WarnManagement/EliminationWarn.vue";
import WarnRecord from "@/views/RiskManagement/System/WarnManagement/WarnRecord.vue";
import EarlyWarningResponse from "@/views/RiskManagement/Risk/EarlyWarningResponse";
import RiskAssessment from "@/views/RiskManagement/Risk/RiskAssessment";
import RiskProcess from "@/views/RiskManagement/Risk/RiskProcess";
import RiskToDo from "@/views/RiskManagement/Risk/RiskToDo";
import RiskTroubleshooting from "@/views/RiskManagement/Risk/RiskTroubleshooting";
import DangerousWorksList from "@/views/RiskManagement/RiskList/DangerousWorksList";
import RiskListLibrary from "@/views/RiskManagement/RiskList/RiskListLibrary";
import RiskMeasures from "@/views/RiskManagement/RiskList/RiskMeasures";
import KeyNode from "@/views/RiskManagement/System/KeyNode/KeyNode";
import NodeConfirmation from "@/views/RiskManagement/System/KeyNode/nodeConfirmation";
import NodeAcceptance from "@/views/RiskManagement/System/KeyNode/nodeAcceptance";
import SynthesizeMange from "@/views/RiskManagement/System/RiskProcess/SynthesizeMange";
import RiskDisclosure from "@/views/RiskManagement/System/RiskProcess/RiskDisclosure";
import RiskStart from "@/views/RiskManagement/System/RiskProcess/RiskStart";
import RiskPatrol from "@/views/RiskManagement/System/RiskProcess/RiskPatrol";
import RiskRemove from "@/views/RiskManagement/System/RiskProcess/RiskRemove";

const Routes = [
  {
    path: "RiskManagement",
    component: Layout,
    name: "RiskManagement",
    children: [
      {
        path: "WarnManagement",
        name: "WarnManagement",
        component: Layout,
        meta: {
          title: "预警管理"
        },
        children: [
          {
            path: "IssueEarlyWarn",
            name: "IssueEarlyWarn",
            component: IssueEarlyWarn
          },
          {
            path: "WarnResponse",
            name: "WarnResponse",
            component: WarnResponse
          },
          {
            path: "WarnCorrection",
            name: "WarnCorrection",
            component: WarnCorrection
          },
          {
            path: "WarnAudit",
            name: "WarnAudit",
            component: WarnAudit
          },
          {
            path: "EliminationWarn",
            name: "EliminationWarn",
            component: EliminationWarn
          },
          {
            path: "WarnRecord",
            name: "WarnRecord",
            component: WarnRecord
          },
          {
            path: "EarlyWarningResponse",
            name: "EarlyWarningResponse",
            component: EarlyWarningResponse
          },
          {
            path: "RiskAssessment",
            name: "RiskAssessment",
            component: RiskAssessment
          },
          {
            path: "RiskProcess",
            name: "RiskProcess",
            component: RiskProcess
          },
          {
            path: "RiskToDo",
            name: "RiskToDo",
            component: RiskToDo
          },
          {
            path: "RiskTroubleshooting",
            name: "RiskTroubleshooting",
            component: RiskTroubleshooting
          },
          {
            path: "DangerousWorksList",
            name: "DangerousWorksList",
            component: DangerousWorksList
          },
          {
            path: "RiskListLibrary",
            name: "RiskListLibrary",
            component: RiskListLibrary
          },
          {
            path: "RiskMeasures",
            name: "RiskMeasures",
            component: RiskMeasures
          }
        ]
      },
      {
        path: "KeyNode",
        name: "KeyNode",
        component: Layout,
        meta: {
          title: "关键节点"
        },
        children: [
          {
            path: "KeyNode",
            name: "KeyNode",
            component: KeyNode
          },
          {
            path: "NodeConfirmation",
            name: "NodeConfirmation",
            component: NodeConfirmation
          },
          {
            path: "NodeAcceptance",
            name: "NodeAcceptance",
            component: NodeAcceptance
          }
        ]
      },
      {
        path: "RiskProcess",
        name: "RiskProcess",
        component: Layout,
        meta: {
          title: "风险过程管理"
        },
        children: [
          {
            path: "SynthesizeMange",
            name: "SynthesizeMange",
            component: SynthesizeMange
          },
          {
            path: "RiskDisclosure",
            name: "RiskDisclosure",
            component: RiskDisclosure
          },
          {
            path: "RiskStart",
            name: "RiskStart",
            component: RiskStart
          },
          {
            path: "RiskPatrol",
            name: "RiskPatrol",
            component: RiskPatrol
          },
          {
            path: "RiskRemove",
            name: "RiskRemove",
            component: RiskRemove
          }
        ]
      }
    ]
  }
];

export default Routes;
