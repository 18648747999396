import { render, staticRenderFns } from "./deploymentOfRecord.vue?vue&type=template&id=563ba24f&scoped=true&"
import script from "./deploymentOfRecord.vue?vue&type=script&lang=js&"
export * from "./deploymentOfRecord.vue?vue&type=script&lang=js&"
import style0 from "./deploymentOfRecord.vue?vue&type=style&index=0&id=563ba24f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563ba24f",
  null
  
)

export default component.exports