<template>
  <!--多人会议  单兵通信 邀请的人员列表弹窗 -->
  <div>
    <Modal ref="modalDetail" :modal="false" width="800" :title="modelTitle">
      <!-- <el-row class="mb-2">
        <el-tag
          v-for="tag in tagsUsers"
          :key="tag.name"
          closable
          @close="handleClose(tag)"
          class="mr-1"
        >
          {{ tag.name }}
        </el-tag>
      </el-row> -->
      <el-row class="mb-2">
        <el-col :span="6" class="mr-1.5">
          <el-input v-model="username" placeholder="输入部门查询"></el-input>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-col>
      </el-row>

      <el-table
        ref="tableList"
        :data="tableData"
        style="width: 100%"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="100"> </el-table-column>
        <el-table-column prop="showName" label="姓名"> </el-table-column>
        <!-- <el-table-column prop="date" label="单位" width="180">
        </el-table-column>
        <el-table-column prop="address" label="部门"> </el-table-column>
        <el-table-column prop="address" label="岗位"> </el-table-column>
        <el-table-column prop="address" label="联系方式"> </el-table-column> -->
      </el-table>
      <el-row class="text-center mt-2">
        <el-button type="primary" @click="handleInvite">一键邀请</el-button>
        <el-button type="primary" plain @click="handleRemake">重制</el-button>
      </el-row>
    </Modal>
    <ManyRtcDia
      ref="manyRtcdia"
      v-show="manyRtcShow"
      :dataList="tagsUsers"
      :owner="owner"
      @endVoip="endVoip"
      @openVoip="openVoip"
    ></ManyRtcDia>
  </div>
</template>

<script>
import Modal from "@/components/Modal/modal.vue";
import ManyRtcDia from "@/components/rtc/manyIndex.vue";

export default {
  components: {
    Modal,
    ManyRtcDia,
  },
  data() {
    return {
      modelTitle: "邀请",
      manyRtcShow: false,
      // 发起人
      owner: {
        id: JSON.parse(localStorage.user).id,
        showName: JSON.parse(localStorage.user).realName,
      },
      // 表格数据
      tableData: [
        {
          id: 1784,
          showName: "谢飞",
        },
        {
          id: 1785,
          showName: "李唐",
        },
        {
          id: 1837,
          showName: "帅小兵",
        },
      ],
      username: "",
      //选择的人员数据
      tagsUsers: [],
      tabs: 0,
    };
  },
  mounted() {},
  methods: {
    // 视频监控0  单兵通信1   多人会议2
    handelOpen(val) {
      this.tabs = val;
      this.$refs.modalDetail.handleOpen();
    },
    //搜索
    handleSearch() {},
    //表格的选择框
    handleSelectionChange(selection) {
      this.tagsUsers = selection;
    },
    // 人员的删除
    // handleClose(tag) {
    //   this.tagsUsers.splice(this.tagsUsers.indexOf(tag), 1);
    //   this.$nextTick(() => {
    //     this.$refs.tableList.toggleRowSelection(tag, false);
    //   });
    // },
    //一键邀请
    handleInvite() {
      this.manyRtcShow = true;
      this.$refs.manyRtcdia.call();
      this.$refs.modalDetail.handleClose();
      this.$refs.tableList.clearSelection();
    },
    openVoip() {
      this.manyRtcShow = true;
    },
    endVoip() {
      this.manyRtcShow = false;
    },
    //重制
    handleRemake() {
      this.tagsUsers = [];
      this.$nextTick(() => {
        this.$refs.tableList.clearSelection();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  color: #fff;
  background-color: #18467f;
  border: 0px solid #dcdfe6;
}
//table
::v-deep .el-table {
  width: 100%;

  background: transparent;

  .el-table__body-wrapper {
    height: 300px;
  }

  &::before {
    background-color: #143363;
  }

  thead {
    color: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: rgba(20, 51, 99, 0.5);
  }

  tr,
  th {
    background-color: transparent;
  }

  td {
    border-bottom: 1px solid #143363;
  }

  .cell {
    color: #fff;
  }

  th.is-leaf {
    border: 1px solid #143363;
  }
}

::v-deep .el-table--border {
  border: 1px solid #143363;

  .el-table--enable-row-hover {
    background-color: #143363;
  }

  td {
    border-right: 1px solid #143363;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: rgba(0, 46, 117, 0.4);
}
::v-deep .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  width: 0;
}
</style>
