<template>
  <!--  -->
  <el-dialog
    v-if="detileVisible"
    width="35%"
    title="详情"
    :visible.sync="detileVisible"
    class="editDialog"
    append-to-body
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-model"
    >
      <el-form-item label="所属建管部" prop="superviseName">
        <el-input
          readonly
          v-model="ruleForm.superviseName"
          placeholder="所属建管部"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="所属总承包部"
        v-if="companyType === 'fullEmergencyRescue'"
        prop="optionUnitName"
      >
        <el-input
          readonly
          v-model="ruleForm.optionUnitName"
          placeholder="所属总承包部"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="专职救援队名称"
        v-if="companyType === 'fullEmergencyRescue'"
        prop="rescueTeamName"
      >
        <el-input
          readonly
          v-model="ruleForm.rescueTeamName"
          placeholder="专职救援队名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="线路名称"
        prop="lineName"
        v-if="companyType === 'construct'"
      >
        <el-input
          readonly
          v-model="ruleForm.lineName"
          placeholder="线路名称"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="分部-工区名称"
        prop="branchName"
        v-if="companyType === 'construct'"
      >
        <el-input
          readonly
          v-model="ruleForm.branchName"
          placeholder="分部-工区名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="主办单位"
        prop="organizer"
        v-if="companyType === 'construct'"
      >
        <el-input
          readonly
          v-model="ruleForm.organizer"
          placeholder="请输入主办单位"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="参与单位" prop="participants">
        <el-input
          readonly
          v-model="ruleForm.participants"
          placeholder="请输入参与单位"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="工点名称"
        prop="siteName"
        v-if="companyType === 'construct'"
      >
        <el-input
          readonly
          v-model="ruleForm.siteName"
          placeholder="工点名称"
        ></el-input>
      </el-form-item>

      <!-- <el-form-item
        label="参与队伍"
        prop="ranksUuids"
        v-if="companyType === 'fullEmergencyRescue'"
      >
        <el-input
          readonly
          v-model="ruleForm.rescueTeamIdList"
          placeholder="参与队伍"
        ></el-input>
      </el-form-item> -->

      <el-form-item label="演练内容" prop="planContent">
        <el-input
          readonly
          v-model="ruleForm.planContent"
          placeholder="请输入演练内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练类型" prop="drillType">
        <el-input
          readonly
          v-model="ruleForm.drillType"
          placeholder="演练类型"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练科目" prop="planSubject">
        <el-input
          readonly
          v-model="ruleForm.planSubject"
          placeholder="演练科目"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练级别" prop="drillLevel">
        <el-input
          readonly
          v-model="ruleForm.drillLevel"
          placeholder="演练级别"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练地点" prop="address">
        <el-input
          readonly
          v-model="ruleForm.address"
          placeholder="请输入演练地点"
        ></el-input>
      </el-form-item>
      <el-form-item label="拟参与人数" prop="planJoinCount">
        <el-input
          readonly
          v-model="ruleForm.planJoinCount"
          placeholder="拟参与人数"
        ></el-input>
      </el-form-item>
      <el-form-item label="计划演练时间" prop="planDate">
        <el-date-picker
          readonly
          v-model="ruleForm.planDate"
          type="date"
          style="width: 100%"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="计划演练时间"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="状态" prop="stateName">
        <el-input
          readonly
          v-model="ruleForm.stateName"
          placeholder="状态"
        ></el-input>
      </el-form-item>
      <el-form-item label="发起人" prop="planJoinCount">
        <el-input
          readonly
          v-model="ruleForm.owner.name"
          placeholder="发起人"
        ></el-input>
      </el-form-item>
      <el-form-item label="发起时间" prop="created">
        <el-date-picker
          readonly
          v-model="ruleForm.created"
          type="date"
          style="width: 100%"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="发起时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="附件" prop="label">
        <div class="file-item" @click="downFile(ruleForm.fileUrl)">
          {{ ruleForm.fileName }}
        </div>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="detileVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    companyType: {
      default: "construct",
    },
  },
  data() {
    return {
      ruleForm: {
        organizer: "",
        ranksUuids: "",
        participants: "",
        planContent: "",
        drillTypeCode: "",
        planSubjectCode: "",
        drillLevelCode: "",
        address: "",
        planJoinCount: "",
        planDate: "",
        siteUuid: "",
        siteId: "",
        siteIds: [],
        lineSiteDeptVoList: [],
        fileUrl: "",
      },
      rules: {},
      detileVisible: false,
    };
  },
  methods: {
    handleDetail(row) {
      this.empty();
      console.log(row);
      this.ruleForm = Object.assign({}, row);
      this.ruleForm.branchName =
        this.ruleForm.branchName + "-" + this.ruleForm.areaName;
      this.detileVisible = true;
    },
    // 置空
    empty() {
      this.ruleForm = {
        organizer: "",
        ranksUuids: "",
        participants: "",
        planContent: "",
        drillTypeCode: "",
        planSubjectCode: "",
        drillLevelCode: "",
        address: "",
        planJoinCount: "",
        planDate: "",
        siteUuid: "",
        siteId: "",
        siteIds: [],
        lineSiteDeptVoList: [],
        fileUrl: "",
      };
    },
    downFile(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-item {
  color: #1aa3ff;
  cursor: pointer;
}
</style>
