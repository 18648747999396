<template>
  <el-container class="el-content screen" ref="scaleBox">
    <el-container>
      <el-header
        class="flex items-center justify-between home-header"
        height="88px"
        v-if="ferty"
      >
        <div class="text-logo top-tit">
          <div style="font-size: 14px; font-weight: 400">
            {{ dataTime }}
          </div>
          <ScreenMenu :menu="leftMenu"></ScreenMenu>
        </div>
        <div class="flex items-center justify-center header-center">
          <div
            class="justify-center text-xl font-bold logtext"
            style="
              font-size: 34px;
              display: flex;
              flex-direction: row;
              align-items: center;
            "
          >
            <span>轨道交通工程建设安全风险管控平台</span>
          </div>
        </div>
        <div class="top-tit">
          <div
            class="mr-2"
            style="font-size: 14px; font-weight: 400; text-align: right"
          >
            您好！{{ username }}
            <span
              @click="useHandBook"
              class="cursor-pointer"
              style="color: #43a5e9"
              >【 使用手册 】
            </span>
            <span @click="logouts" class="cursor-pointer" style="color: #43a5e9"
              >【 退出登录 】
            </span>
          </div>
          <div class="flex items-center">
            <ScreenMenu
              :menu="rightMenu"
              rightMenu
              :systemPath="systemMenu.resourcePath"
            ></ScreenMenu>
          </div>
        </div>
      </el-header>
      <el-main
        id="layoutContainer"
        class="el-content-main blackStyle"
        style="padding: 0"
      >
        <router-view />
      </el-main>
    </el-container>
    <el-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="40%"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :before-close="handleDialogClose"
    >
      <div
        style="width: 100%;height:40px;background: #c2d8e9;font-weight: bold;
    font-size: 18px;
    color: #000;
    text-align: center;
    line-height: 40px;"
      >
        用户使用手册
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" width="80" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="name" label="名称" width="450" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="downloadFile(scope.row)"
              >下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import dayjs from "dayjs";
import qs from "qs";
import { logout } from "@/api/user";
import { api } from "@/services/config";
import { removeToken } from "@/utils/token";
import { removeUserInfo } from "@/utils/user";
import ScreenMenu from "@/components/ScreenMenu/Index.vue";

export default {
  name: "ScreenHome",
  components: {
    ScreenMenu,
  },
  data() {
    return {
      dialogVisible: false,
      resourceAll: [],
      resourceTree: [],
      menu: [],
      ferty: true,
      user: {},
      dataTime:
        dayjs().format("YYYY-MM-DD  ") +
        " 星期" +
        "日一二三四五六".charAt(dayjs().day()) +
        dayjs().format("  HH:mm:ss"),
      signInTime: dayjs().format("YYYY-MM-DD  "),
      native: true,
      jumpUrl: "",
      currentKey: "",
      username: JSON.parse(localStorage.user).realName,
      Styles: true,
      userData: {},
      leftMenu: [], // 一级菜单左侧
      rightMenu: [], // 一级菜单右侧
      defaultActive: "",
      systemMenu: null,
      tableData: [
        {
          name: "系统用户用户手册",
          url: "城市轨道交通工程建设安全风险管控平台用户手册.pdf",
        },
        {
          name: "系统管理员用户手册",
          url:
            "城市轨道交通工程建设安全风险管控平台（数字三防）用户管理员pdf.pdf",
        },
        {
          name: "系统用户使用视频教程",
          url: "系统用户使用视频教程.zip",
        },
      ],
    };
  },
  async created() {
    this.$EventBus.$on("secrty", (val) => {
      console.log(val);
      if (val == 1) {
        this.ferty = false;
      } else {
        this.ferty = true;
      }
    });
    setInterval(() => {
      this.dataTime =
        dayjs().format("YYYY-MM-DD  ") +
        " 星期" +
        "日一二三四五六".charAt(dayjs().day()) +
        dayjs().format("  HH:mm:ss");
    }, 1000);
  },
  beforeDestroy() {
    this.$EventBus.$off("secrty");
  },
  mounted() {
    // this.setScale()
    this.userData = JSON.parse(localStorage.getItem("user"));
    this.resourceAll = JSON.parse(localStorage.getItem("SCREEN_AUTH") || '""');
    console.log("用户数据", this.userData);
    this.initResourceTree();
    this.initNavMenu();
    // 获取工作台第一个有效菜单
    const menuData = JSON.parse(localStorage.getItem("USER_AUTH") || '""');
    const menuTree = this.initTree(menuData);
    const menu = this.findValidMenu(menuTree[0]);
    if (menu) this.systemMenu = menu;
  },
  // 组件内路由守卫
  beforeRouteEnter(to, from, next) {
    console.log(to, from);
    next((vm) => {
      console.log(vm);
      if (from.path == "/Login") {
        vm.currentKey = vm.leftMenu[0].id;
        vm.handleMenuJump(vm.leftMenu[0]);
      }
    });
  },
  methods: {
    // 初始化一级菜单
    initNavMenu() {
      const Menu = this.resourceTree;
      // const middleIndex = Math.ceil(Menu.length / 2) - 1;
      for (let i = 0; i < Menu.length; i++) {
        if (i <= 3) {
          this.leftMenu.push(Menu[i]);
        } else {
          this.rightMenu.push(Menu[i]);
        }
      }
    },
    // 找到第一级菜单
    findForebears(resource, pId) {
      const tempObj = resource.find((item) => item.id == pId);
      if (tempObj) {
        if (tempObj.parentId == 0) {
          return tempObj;
        } else {
          return this.findForebears(resource, tempObj.parentId);
        }
      }
    },
    // 找到最后一级菜单
    findValidMenu(resource) {
      if (resource.children) {
        return this.findValidMenu(resource.children[0]);
      } else {
        return resource;
      }
    },
    // 初始化菜单树
    initResourceTree() {
      let handleResource = (resource) => {
        let parent = this.resourceAll.find((p) => p.id == resource.parentId);
        if (parent) {
          if (resource.parentId !== 0 && parent.children) {
            parent.children.push(resource);
          } else {
            parent.children = [resource];
          }
        } else {
          console.log(parent);
        }
      };
      this.resourceAll.forEach((item) => handleResource(item));
      this.resourceTree = [];
      this.resourceTree = this.resourceAll.filter(
        (item) => item.parentId === 0
      );
    },
    // 初始化菜单树2
    initTree(resourceData) {
      let handleResource = (resource) => {
        let parent = resourceData.find((p) => p.id == resource.parentId);
        if (parent) {
          if (resource.parentId !== 0 && parent.children) {
            parent.children.push(resource);
          } else {
            parent.children = [resource];
          }
        } else {
          console.log(parent);
        }
      };
      resourceData.forEach((item) => handleResource(item));
      const result = resourceData.filter((item) => item.parentId === 0);
      return result;
    },
    // 工作台跳转
    handleJumpWorkbench() {
      const path = this.systemMenu.resourcePath;
      window.open(path, "_blank");
    },
    /**
     * 一级菜单跳转，加载二级菜单
     * @param {Object} record
     * @param {String} type
     * @param {Number} index
     */
    handleMenuJump(record) {
      this.currentKey = record.id;
      this.menu = record.children;
      const validMenu = this.findValidMenu(record);
      this.$router.push({
        path: validMenu.resourcePath,
      });
      this.defaultActive = validMenu.resourcePath;
    },
    async logout() {
      const options = {
        response_type: "code",
        scope: "api openid offline_access",
        client_id: api.clientId,
        state: 1234,
        redirect_uri: api.callbackURL,
      };
      await removeToken();
      await removeUserInfo();
      window.location.href = `${api.loginURL}/v1/connect/logout?${qs.stringify(
        options
      )}`;

      logout().then((res) => {
        if (res.code == 0) {
          this.$message.info("操作成功");
          // 移除localStorage中的token和token过期时间
          localStorage.removeItem("jwtToken");
          localStorage.removeItem("jwtTokenExpire");
          this.$router.push("/Login");
        }
      });
    },
    // 使用手册
    useHandBook() {
      this.dialogVisible = true;
    },
    handleDialogClose() {
      this.dialogVisible = false;
    },
    downloadFile(row) {
      const link = document.createElement("a");
      link.href = process.env.BASE_URL + row.url;
      link.download = row.url.split(".")[0];
      link.target = "_blank";
      link.click();
    },
    logouts() {
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("jwtTokenExpire");
      localStorage.removeItem("lineSiteTree");
      localStorage.removeItem("USER_AUTH");
      localStorage.removeItem("USER_BTN_AUTH");
      localStorage.removeItem("user");
      localStorage.removeItem("DetailsTree");
      this.$router.push("/Login");
    },
    setScale() {
      const scale = this.getScale();
      this.$refs.scaleBox.$el.style.transform = `scale(${scale})`;
      this.$refs.scaleBox.$el.style.transformOrigin = `0 0`;
      this.$refs.scaleBox.$el.style.width = `1920px`;
      this.$refs.scaleBox.$el.style.height = `1080px`;
    },
    getScale() {
      const width = 1920;
      const height = 1080;
      let ww = window.innerWidth / width;
      let wh = window.innerHeight / height;
      return ww < wh ? ww : wh;
    },
  },
};
</script>
<style lang="scss">
.screen {
  .el-header {
    background: #081742;
    color: #ffffff;

    .header-center {
      width: 40%;
      height: 70px;
      background: url("../assets/bt-bg.png") repeat 100%;
      background-size: 100% 100%;

      .logtext {
        color: #ffffff;
        opacity: 0.6;
      }
    }
  }
  .el-content-main {
    background: #081742;
  }
}

.el-header {
  text-align: left;
  font-size: 18px;
  background-size: 100% 100%;
  box-shadow: 0 2px 0px #f0f1f2;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  padding: 0 10px;

  .home-header {
    padding-bottom: 10px;
  }

  .top-tit {
    width: 29%;
  }
}

.el-content {
  height: 100vh;
  // background: #081742;
  // background: url("../assets/center-bg.png") repeat 100%;
  background-size: 100% 100%;

  .el-main {
    padding: 10px 0;
  }

  .el-menu {
    background: #041527;
    border-right: none !important;
  }

  .asider-logo {
    background-color: #0094bf;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-img {
      display: inline-block;
      width: 100px;
    }
  }

  .el-menu-item,
  .el-submenu__title {
    // background:#041527 ;
    color: #a7adb4;

    &:hover {
      color: #fff;
      background: none;
    }
  }

  .el-menu-item.is-active {
    background-color: #1890ff;
    color: #fff;
  }

  &-main {
    padding: 10px;
    position: relative;
  }

  .el-aside {
    height: 100vh;
    background: #041527;
  }

  .el-card__body {
    padding: 15px;
  }
}
.sys-content {
  height: calc(100vh - 100px);
  .system-modal {
    .el-input {
      width: 80%;
    }
    .el-select {
      width: 100%;
    }
    .el-cascader {
      width: 100%;
    }
    .el-textarea {
      width: 80%;
    }
  }
  .el-menu {
    background: #ffffff !important;
    font-weight: 400;
    color: #081742;
  }
  .asider-logo {
    background-color: #0094bf;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-img {
      display: inline-block;
      width: 100px;
    }
  }
  .el-submenu .el-menu-item {
    min-width: 100px;
  }
  .el-menu-item,
  .el-submenu__title {
    color: #081742 !important;
    background: #ffffff !important;
    &:hover {
      color: #43a5e9 !important;
      background: none !important;
    }
  }
  .el-menu-item.is-active {
    color: #43a5e9 !important;
    background: #ffffff;
  }
  &-main {
    padding: 10px;
    position: relative;
  }
  .el-aside {
    height: calc(100vh - 100px);
    background: #ffffff;
    margin-top: 10px;
    padding-top: 10px;
  }
  //card标签
  .el-card {
    background-size: 100% 100%;
    border-radius: 10px;
    border: none;
  }
  ::-webkit-scrollbar {
    width: 5px; /*对垂直流动条有效*/
    height: 5px; /*对水平流动条有效*/
    display: none;
  }

  /*定义滚动条的轨道颜色、内阴影及圆角*/
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(52, 73, 85, 0.5);
    background-color: rgba(52, 73, 85, 0.5);
    border-radius: 3px;
  }

  /*定义滑块颜色、内阴影及圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
    -webkit-box-shadow: inset 0 0 6px rgba(52, 73, 85, 0.5);
    background-color: rgba(52, 73, 85, 0.5);
  }

  /*定义两端按钮的样式*/
  ::-webkit-scrollbar-button {
    background-color: rgba(52, 73, 85, 0.5);
  }

  /*定义右下角汇合处的样式*/
  ::-webkit-scrollbar-corner {
    background: rgba(52, 73, 85, 0.5);
  }
  .el-menu-item.is-active {
    background: #e6f7ff !important;
  }
  .text-center {
    cursor: pointer;
  }
}
</style>
