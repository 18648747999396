<template>
  <div id="chart" ref="chart"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      autoElecChartline: null
    };
  },
  watch: {
    options: {
      deep: true,
      handler() {
        console.log(
          this.options,
          "optionsoptionsoptionsoptionsoptionsoptionsoptionsoptions"
        );
        this.init();
      }
    }
  },
  methods: {
    init() {
      if (this.autoElecChartline) {
        this.autoElecChartline.dispose();
      }
      this.autoElecChartline = echarts.init(this.$refs.chart);
      // this.autoElecChartline.setOption(
      var option = {
        grid: {
          top: "9%",
          left: "4%",
          right: "4%",
          bottom: "10%"
        },
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          boundaryGap: false,
          type: "category",
          data: this.options.xAxisData,
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            // interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
            //	margin:15,
            textStyle: {
              color: "#606266",
              fontStyle: "normal",
              fontSize: 12
            }
            // margin: 15, //刻度标签与轴线之间的距离。
          },
          axisLine: {
            lineStyle: {
              color: "rgba(137, 157, 191, .38)"
            }
          }
        },
        yAxis: {
          // type: "value",
          axisLabel: {
            textStyle: {
              color: "#606266", //设置y轴字体颜色
              fontStyle: "normal",
              fontSize: 12
            }
          },
          axisLine: {
            show: false //不展示y轴线
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(137, 157, 191, .38)" //设置y轴线的颜色
            }
          }
        },

        series: [
          {
            name: this.name,
            type: "line",
            smooth: true,
            lineStyle: {
              width: 2,
              color: "#66b1ff"
              // color: this.changeColor,
            },
            markPoint: {
              symbol: "circle",
              itemStyle: {
                normal: {
                  color: "#fff",
                  borderColor: "#66b1ff",
                  // borderColor: this.changeColor,
                  borderWidth: 2
                }
              },
              symbolSize: "5",
              label: {
                show: false
              },
              data: [{ type: "max", name: "Max" }]
            },
            symbol: "none",
            itemStyle: {
              normal: {
                color: "#66b1ff ", // 会设置点和线的颜色，所以需要下面定制 line
                borderColor: "#66b1ff" // 点边线的颜色
              }
            },
            areaStyle: {
              // 颜色自上而下渐变
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  // 1代表上面
                  offset: 0,
                  color: "rgba(102, 177, 255, 1)"
                  // color: "rgba(219, 124, 40, .5)",
                },
                {
                  offset: 0.5,
                  color: "rgba(102, 177, 255, .5)"
                  // color: "rgba(219, 124, 40, .3)",
                },
                {
                  offset: 1,
                  color: "rgba(102, 177, 255, .1)"
                  // color: "rgba(219, 124, 40, 0.1)",
                }
              ])
              // opacity: 0.8    // 填充区域透明度
            },
            data: this.options.yAxisData
          }
        ]
      };
      //   true
      // );
      option && this.autoElecChartline.setOption(option);
      window.addEventListener("resize", this.rest);
    },
    rest() {
      this.autoElecChartline.resize();
    }
  },
  mounted() {
    this.init();
    // this.chart.showLoading()
  },
  beforeDestroy() {
    if (this.autoElecChartline) {
      this.autoElecChartline.dispose();
    }
    this.autoElecChartline = null;
    window.removeEventListener("resize", this.rest);
  }
};
</script>

<style lang="scss" scoped>
#chart {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
</style>
