<template>
  <systemModal
    ref="formModal"
    @modalConfirm="handleSubmit"
    :title="title"
    width="40%"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="120px"
      class="demo-model"
      style="width: 80%"
    >
      <el-form-item label="名称" prop="resourceName">
        <el-input
          v-model="model.resourceName"
          placeholder="请输入名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="resourceAddress">
        <el-input
          v-model="model.resourceAddress"
          placeholder="请输入地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="telephone">
        <el-input v-model="model.telephone" placeholder="请输入电话"></el-input>
      </el-form-item>
      <el-form-item label="简介" prop="synopsis">
        <el-input v-model="model.synopsis" placeholder="请输入简介"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="resourceType">
        <el-select
          v-model="model.resourceType"
          placeholder="请选择(医院、应急联络)"
        >
          <el-option
            v-for="item of dictOption.resourcOption"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="坐标" prop="coordinate">
        <el-button type="primary" @click="choseMap">点击选择</el-button>
      </el-form-item>
      <el-form-item label="经度">
        <el-button readonly>{{ model.longitude }}</el-button>
      </el-form-item>
      <el-form-item label="纬度">
        <el-button readonly>{{ model.latitude }}</el-button>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input
          v-model="model.remarks"
          placeholder="请输入备注"
          type="textarea"
        ></el-input>
      </el-form-item>
    </el-form>
    <MapForm
      ref="mapForm"
      @formSubmit="handleSubmit"
      :getCoo="getCoo"
    ></MapForm>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue'
import MapForm from './map.vue'
import { AddPublic, EditPublic } from '@/api/eres'
import { queryDictByLabel, queryDictNodesById } from '@/api/dict'
// import { queryOrgPageList, queryDeepDataByOrgId } from '@/api/org'
export default {
  components: {
    systemModal,
    MapForm,
  },
  data() {
    return {
      title: '新增',
      model: {
        remarks: '', //remarks
        resourceAddress: '', //地址
        resourceId: '', //资源id
        resourceName: '', //名称
        resourceType: '', //类型
        synopsis: '', //简介
        telephone: '', //电话
        latitude: '',
        longitude: '',
        address: '',
      },
      lineSiteData: [],
      dictOption: {
        resourcOption: [],
      },
      loading: false,
      rules: {
        resourceName: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        telephone: [{ required: true, message: '请输入电话', trigger: 'blur' }],
        resourceType: [
          { required: true, message: '请选择类型', trigger: 'blur' },
        ],
      },
    }
  },
  mounted() {
    this.getCoo()
    this.getDictData('公共资源类型', this.dictOption.resourcOption)
  },
  methods: {
    getCoo(lng, lat, address) {
      this.model.longitude = lng
      this.model.latitude = lat
      this.model.address = address
      this.$forceUpdate()
    },
    choseMap() {
      this.$refs.mapForm.handleAdd(
        this.model.longitude,
        this.model.latitude,
        this.model.address
      )
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data)
              this.$forceUpdate()
            }
          })
        }
      })
    },
    handleAdd(type, record) {
      if (record) {
        this.title = '编辑'
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)))
        console.log(this.model)
        // this.$set(this.model, 'lineSite', [record.lineId, record.siteId])
      } else {
        this.title = '新增'
        this.model = {}
      }
      this.handleEdit()
    },
    handleEdit() {
      this.$refs.formModal.handleOpen()
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.model.resourceId) {
            const params = this.model
            EditPublic(params)
              .then((res) => {
                if (res.code == 0) {
                  this.loading = false
                  this.$message.success('操作成功')
                  this.$refs.formModal.handleClose()
                  this.$emit('formSubmit')
                }
              })
              .catch((err) => {
                this.$message.error(err.message)
                this.loading = false
              })
          } else {
            AddPublic(this.model)
              .then((res) => {
                if (res.code == 0) {
                  this.loading = false
                  this.$message.success('操作成功')
                  this.$refs.formModal.handleClose()
                  this.$emit('formSubmit')
                }
              })
              .catch((err) => {
                this.$message.error(err.message)
                this.loading = false
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
      // this.$refs.formModal.handleClose();
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
