<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-model"
    >
      <el-form-item
        label="所属建管部"
        prop="buildDeptName"
        v-if="editStatus == 2 || editStatus == 3 || operation === 'detail'"
      >
        <el-input
          v-model="ruleForm.buildDeptName"
          disabled
          placeholder="所属建管部"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="线路名称"
        prop="lineName"
        v-if="editStatus == 2 || editStatus == 3 || operation === 'detail'"
      >
        <el-input
          v-model="ruleForm.lineName"
          disabled
          placeholder="线路名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="分部名称"
        prop="branchName"
        v-if="editStatus == 2 || editStatus == 3 || operation === 'detail'"
      >
        <el-input
          v-model="ruleForm.branchName"
          disabled
          placeholder="分部名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="工点名称"
        prop="siteName"
        v-if="editStatus == 2 || editStatus == 3 || operation === 'detail'"
      >
        <el-input
          v-model="ruleForm.siteName"
          disabled
          placeholder="工点名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="培训名称"
        prop="label"
        v-if="editStatus == 2 || editStatus == 3 || operation === 'detail'"
      >
        <el-input
          v-model="ruleForm.label"
          :disabled="operation === 'detail'"
          placeholder="培训名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="主讲人"
        prop="teacher"
        v-if="editStatus == 2 || editStatus == 3 || operation === 'detail'"
      >
        <el-input
          v-model="ruleForm.teacher"
          :disabled="operation === 'detail'"
          placeholder="主讲人"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="培训地点"
        prop="trainAddress"
        v-if="editStatus == 2 || editStatus == 3 || operation === 'detail'"
      >
        <el-input
          v-model="ruleForm.trainAddress"
          :disabled="operation === 'detail'"
          placeholder="培训地点"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="计划培训时间"
        prop="planDate"
        v-if="editStatus == 2 || editStatus == 3 || operation === 'detail'"
      >
        <el-input
          v-model="ruleForm.planDate"
          :disabled="operation === 'detail'"
          placeholder="计划培训时间"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="planDate" v-if="operation === 'detail'">
        <el-input
          v-model="ruleForm.stateName"
          :disabled="operation === 'detail'"
          placeholder="状态"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="发起人"
        prop="planDate"
        v-if="operation === 'detail'"
      >
        <el-input
          v-model="ruleForm.operatorName"
          :disabled="operation === 'detail'"
          placeholder="发起人"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="发起时间"
        prop="planDate"
        v-if="operation === 'detail'"
      >
        <el-input
          v-model="ruleForm.created"
          :disabled="operation === 'detail'"
          placeholder="发起时间"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="实际培训时间"
        prop="trainDate"
        v-if="editStatus == 6 || editStatus == 7"
      >
        <el-date-picker
          style="width: 100%"
          v-model="ruleForm.trainDate"
          :disabled="operation === 'detail'"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="实际培训时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="参与人数"
        prop="joinCount"
        v-if="editStatus == 6 || editStatus == 7"
      >
        <el-input
          v-model="ruleForm.joinCount"
          :disabled="operation === 'detail'"
          placeholder="参与人数"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="课件"
        prop="courseware"
        v-if="
          editStatus == 2 ||
            editStatus == 3 ||
            editStatus == 1 ||
            editStatus == 4
        "
      >
        <!--        <el-input v-model="ruleForm.courseware" placeholder="请输入演练主题"></el-input>-->
        <div
          class="file-item"
          v-if="operation === 'detail'"
          @click="openFile(ruleForm.coursewares[0].link)"
        >
          {{ ruleForm.coursewares ? ruleForm.coursewares[0].attrName : "" }}
        </div>
        <Uploader
          v-if="
            editStatus == 2 ||
              (editStatus == 3 &&
                fileCoursewareList.length > 0 &&
                operation === 'edit')
          "
          @uploader="courseware"
          :file-array="fileCoursewareList"
          :accept="'.pdf,image/*'"
        ></Uploader>
      </el-form-item>

      <el-form-item label="总结" prop="accessory" v-if="ruleForm.state >= 5">
        <!--        <el-input v-model="ruleForm.accessory" placeholder="请输入演练主题"></el-input>-->
        <div
          class="file-item"
          v-show="operation === 'detail'"
          v-for="(item, index) in fileAccessoryList"
          :key="index"
          @click="openFile(item.url)"
        >
          {{ item.name }}
        </div>
        <Uploader
          v-if="
            editStatus == 6 ||
              (editStatus == 7 &&
                fileAccessoryList.length > 0 &&
                operation === 'edit')
          "
          @uploader="uploadSummart"
          :file-array="fileAccessoryList"
          :accept="'.pdf,image/*'"
        ></Uploader>
      </el-form-item>

      <el-form-item label="照片" prop="accessory" v-if="ruleForm.state >= 5">
        <!--        <el-input v-model="ruleForm.accessory" placeholder="请输入演练主题"></el-input>-->
        <div
          class="file-item"
          v-show="operation === 'detail'"
          v-for="(item, index) in this.ruleForm.pictures"
          :key="index"
        >
          <el-image
            style="width: 100px; height: 100px"
            :src="item.link"
            @click="openFile(item.link)"
            fit="cover"
          ></el-image>
        </div>

        <Uploader
          v-if="
            editStatus == 6 ||
              (editStatus == 7 &&
                pictureList.length > 0 &&
                operation === 'edit')
          "
          @uploader="pictureSummart"
          :file-array="pictureList"
        ></Uploader>
      </el-form-item>

      <el-form-item label="签到表" prop="accessory" v-if="ruleForm.state >= 5">
        <!--        <el-input v-model="ruleForm.accessory" placeholder="请输入演练主题"></el-input>-->

        <div
          class="file-item"
          v-show="operation === 'detail'"
          v-for="(item, index) in signInFormsList"
          :key="index"
          @click="openFile(item.url)"
        >
          {{ item.name }}
        </div>
        <Uploader
          v-if="
            editStatus == 6 ||
              (editStatus == 7 &&
                signInFormsList.length > 0 &&
                operation === 'edit')
          "
          @uploader="uploadSign"
          :file-array="signInFormsList"
          :accept="'.pdf,image/*'"
        ></Uploader>
      </el-form-item>

      <el-form-item
        label="其他附件"
        prop="accessory"
        v-if="ruleForm.state >= 5"
      >
        <!--        <el-input v-model="ruleForm.accessory" placeholder="请输入演练主题"></el-input>-->
        <!--        <div class="file-item" v-if="operation ===  'detail' " @click="openFile(otherList[0].url)">
          {{ otherList ? otherList[0].name : '' }}
        </div>-->

        <div
          class="file-item"
          v-show="operation === 'detail'"
          v-for="(item, index) in otherList"
          :key="index"
          @click="openFile(item.url)"
        >
          {{ item.name }}
        </div>

        <Uploader
          v-if="
            editStatus == 6 ||
              (editStatus == 7 && otherList.length > 0 && operation === 'edit')
          "
          @uploader="otherSummart"
          :file-array="otherList"
          :accept="'.pdf,image/*'"
        ></Uploader>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer" v-if="operation == 'edit'">
      <el-button @click="cancelEditPlan">取 消</el-button>
      <!--      <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>-->
      <el-button type="primary" @click="refreshSubmit">重新提交</el-button>
    </div>
  </div>
</template>

<script>
import Uploader from "./Uploader"
import { getTrainDetail, trainInfoUpdate } from "@/api/stand"
export default {
  name: "trainModal",
  props: {
    detailId: {
      default: "",
    },
    operation: {
      default: "",
    },
    editStatus: {
      default: null,
    },
  },
  components: {
    Uploader,
  },
  data () {
    return {
      ruleForm: {
        ruleForm: {},
        coursewares: [],
        accessories: [],
        pictures: [],
        signInForms: [],
        summarisingDocuments: [],
      },
      rules: {},
      fileCoursewareList: [],
      fileAccessoryList: [],
      signInFormsList: [],
      pictureList: [],
      otherList: [],
    }
  },
  created () {
    this.getDetailData()
  },
  methods: {
    getDetailData () {
      let params = {
        id: this.detailId,
      }
      getTrainDetail(params).then((res) => {
        console.log(res)
        if (res.code == 0) {
          this.ruleForm = res.data
          // this.editStatus = res.data.state;

          if (res.data.coursewares && res.data.coursewares.length > 0) {
            this.fileCoursewareList = res.data.coursewares.map((item) => {
              return {
                name: item.attrName,
                url: item.link,
              }
            })
          }
          if (
            res.data.summarisingDocuments &&
            res.data.summarisingDocuments.length > 0
          ) {
            this.fileAccessoryList = res.data.summarisingDocuments.map(
              (item) => {
                return {
                  name: item.attrName,
                  url: item.link,
                }
              }
            )
          }

          if (res.data.signInForms && res.data.signInForms.length > 0) {
            this.signInFormsList = res.data.signInForms.map((item) => {
              return {
                name: item.attrName,
                url: item.link,
              }
            })
          }

          if (res.data.pictures && res.data.pictures.length > 0) {
            this.pictureList = res.data.pictures.map((item) => {
              return {
                name: item.attrName,
                url: item.link,
              }
            })
          }
          if (res.data.accessories && res.data.accessories.length > 0) {
            this.otherList = res.data.accessories.map((item) => {
              return {
                name: item.attrName,
                url: item.link,
              }
            })
          }
        }
      })
    },
    uploadSummart (event) {
      let params = {
        planId: this.planId,
        attrName: event.originalName,
        attrType: 1,
        link: event.uri,
        localPath: event.fullPath,
      }
      this.ruleForm.summarisingDocuments.push(params)
      console.log(this.ruleForm.summarisingDocuments)
    },
    pictureSummart (event) {
      let params = {
        planId: this.planId,
        attrName: event.originalName,
        attrType: 2,
        link: event.uri,
        localPath: event.fullPath,
      }
      this.ruleForm.pictures.push(params)
    },
    uploadSign (event) {
      let params = {
        planId: this.planId,
        attrName: event.originalName,
        attrType: 3,
        link: event.uri,
        localPath: event.fullPath,
      }
      this.ruleForm.signInForms.push(params)
    },
    otherSummart (event) {
      let params = {
        planId: this.planId,
        attrName: event.originalName,
        attrType: 4,
        link: event.uri,
        localPath: event.fullPath,
      }
      this.ruleForm.accessories.push(params)
    },
    openFile (url) {
      window.open(url)
    },
    courseware (event) {
      console.log(event)
      /* this.ruleForm.courseware.file_name = event.originalName;
       this.ruleForm.courseware.link = event.uri;*/
      let params = {
        planId: this.planId,
        attrName: event.originalName,
        attrType: 0,
        link: event.uri,
        localPath: event.fullPath,
      }
      this.ruleForm.coursewares.push(params)
    },
    accessory (event) {
      console.log(event)
      this.ruleForm.accessory.file_name = event.originalName
      this.ruleForm.accessory.link = event.uri
    },
    cancelEditPlan () {
      this.$emit("cancelEditPlan")
    },
    refreshSubmit () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.ruleForm.state == 2 || this.ruleForm.state == 3) {
            this.ruleForm.state = 1
          }
          if (this.ruleForm.state == 6 || this.ruleForm.state == 7) {
            this.ruleForm.state = 5
          }
          trainInfoUpdate(this.ruleForm).then((res) => {
            if (res.code == 0) {
              this.$message.success("操作成功")
              this.$emit("successRefresh")
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          this.$message.error("请正确填写")
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.file-item {
  color: #3090ff;
  cursor: pointer;
}
</style>
