<template>
  <div class="content">
    <div class="info-wrapper">
      <div class="choice">
        <el-radio-group
          style="margin-right:10px"
          v-for="item in radioData"
          :key="item.value"
          @change="radioChange"
          v-model="radio"
        >
          <el-radio :label="item.value">{{ item.label }}</el-radio>
        </el-radio-group>
      </div>
      <div class="line"></div>
      <el-form
        ref="libraryForm"
        :inline="true"
        :model="libraryForm"
        class="demo-form-inline"
      >
        <el-form-item prop="startDate">
          <el-date-picker
            clearable
            v-model="libraryForm.startDate"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item prop="state">
          <el-select
            clearable
            v-model="libraryForm.state"
            placeholder="选择状态"
          >
            <el-option
              v-for="item in stateData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="radio == 1 || radio == 2" prop="companyName">
          <el-input
            v-model="libraryForm.companyName"
            clearable
            placeholder="输入单位名称/部门"
          ></el-input>
        </el-form-item>
        <el-form-item v-show="radio == 4" prop="companyUnit">
          <el-select
            clearable
            v-model="libraryForm.companyUnit"
            placeholder="选择单位类型"
          >
            <el-option
              v-for="item in companyTypeData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="siteIds" v-show="radio == 4">
          <el-cascader
            ref="treeSree"
            filterable
            clearable
            v-model="libraryForm.siteIds"
            placeholder="选择工点"
            :props="props"
            :options="treeData"
            :show-all-levels="false"
            @change="handleLineSiteChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="libraryQuery">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
          type="index"
          label="序号"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.minWidth"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column prop="rotaShift" align="center" label="班次">
          <template slot-scope="scope">
            <span v-if="scope.row.state == false">白班</span>
            <span v-if="scope.row.state == true">夜班</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" align="center" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.state == false">未到岗</span>
            <span v-if="scope.row.state == true">到岗</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex justify-center mt-4">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="libraryForm.page"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
      <!-- <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="libraryForm.page"
        :page-size="libraryForm.size"
        layout=" prev, pager, next"
        :total="total"
      >
      </el-pagination> -->
    </div>
  </div>
</template>

<script>
import { getEresList } from "@/api/eres";
export default {
  components: {},
  data() {
    return {
      libraryForm: {
        page: 1,
        size: 5,
        startDate: "",
        endDate: "",
        state: null,
        companyUnit: "",
        siteId: null,
        companyName: "",
      },
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "id",
      },
      loading: false,
      stateData: [
        {
          label: "到岗",
          value: 1,
        },
        {
          label: "未到岗",
          value: 0,
        },
      ],
      companyTypeData: [
        {
          label: "建设单位",
          value: "build",
        },
        {
          label: "监理单位",
          value: "monitor",
        },
        {
          label: "施工单位",
          value: "construct",
        },
        {
          label: "一般单位",
          value: "current",
        },
        {
          label: "设计单位",
          value: "design",
        },
        {
          label: "第三方监测单位",
          value: "third",
        },
        {
          label: "勘察单位",
          value: "survey",
        },
        {
          label: "施工总承包单位",
          value: "contracting",
        },
      ],

      tableData: [
        {
          lineName: "姓名",
          siteName: "xxx职位",
          siteNames: "xxx职位",
          vownerName: "部门",
          tell: "13834566543",
        },
      ],
      radio: 1,
      radioData: [
        {
          label: "总承包单位",
          value: 1,
        },
        {
          label: "建设管理公司",
          value: 2,
        },
        {
          label: "专职应急救援队",
          value: 3,
        },
        {
          label: "工点值班人员",
          value: 4,
        },
      ],
      columns: [],
      total: 80,
      // currentPage: 1,
      // size: 10,
    };
  },
  created() {
    this.getTable();
  },
  mounted() {
    this.getListDatas();
    this.getLineSiteTreeData();
  },
  methods: {
    getListDatas() {
      this.loading = true;
      let params = {
        startDate: this.libraryForm.startDate,
        endDate: this.libraryForm.startDate,
        state: this.libraryForm.state,
        companyUnit: this.libraryForm.companyUnit,
        siteId: this.libraryForm.siteId,
        rotaType: this.radio,
        companyName: this.libraryForm.companyName,
        page: this.libraryForm.page,
        size: this.libraryForm.size,
      };
      getEresList(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          this.total = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },

    getListData(rotaType) {
      this.loading = true;
      let params = {
        startDate: this.libraryForm.startDate,
        endDate: this.libraryForm.startDate,
        state: this.libraryForm.state,
        companyUnit: this.libraryForm.companyUnit,
        siteId: this.libraryForm.siteId,
        rotaType: rotaType,
        companyName: this.libraryForm.companyName,
        page: this.libraryForm.page,
        size: this.libraryForm.size,
      };
      getEresList(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          this.total = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    handleLineSiteChange(val) {
      if (val.length) {
        this.libraryForm.siteId = val.slice(-1)[0];
        this.libraryForm.siteIds = val;
      } else {
        this.libraryForm.siteIds = [];
        this.libraryForm.siteId = null;
      }
    },
    getTable() {
      this.columns = [
        { prop: "companyName", label: "单位名称", minWidth: 80 },
        { prop: "rotaName", label: "值班人员", minWidth: 80 },
        { prop: "mobile", label: "联系电话", minWidth: 80 },
      ];
    },
    libraryCls() {
      this.$emit("libraryCls");
    },
    // 查询
    libraryQuery() {
      this.getListData(this.radio);
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.libraryForm.page = val;
      this.getListData(this.radio);
    },
    yuyin(val) {
      console.log(val);
    },
    shipin(val) {
      console.log(val);
    },
    duanxian(val) {
      console.log(val);
    },
    // 切换
    radioChange(val) {
      if (val == 1) {
        this.radio = 1;
        this.columns = [
          { prop: "companyName", label: "单位名称", minWidth: 80 },
          { prop: "rotaName", label: "值班人员", minWidth: 80 },
          { prop: "mobile", label: "联系电话", minWidth: 80 },
        ];
        this.getListData(val);
        this.$refs.libraryForm.resetFields();
      } else if (val == 2) {
        this.radio = 2;
        this.columns = [
          { prop: "companyName", label: "部门", minWidth: 80 },
          { prop: "rotaName", label: "值班人员", minWidth: 80 },
          { prop: "mobile", label: "联系电话", minWidth: 80 },
        ];
        this.getListData(val);
        this.$refs.libraryForm.resetFields();
      } else if (val == 3) {
        this.radio = 3;
        this.columns = [
          { prop: "companyName", label: "应急救援队", minWidth: 80 },
          { prop: "rotaName", label: "值班人员", minWidth: 80 },
          { prop: "mobile", label: "联系电话", minWidth: 80 },
        ];
        this.getListData(val);
        this.$refs.libraryForm.resetFields();
      } else if (val == 4) {
        this.radio = 4;
        this.columns = [
          { prop: "siteName", label: "工点", minWidth: 80 },
          { prop: "companyUnit", label: "单位类型", minWidth: 80 },
          { prop: "companyName", label: "单位名称", minWidth: 80 },
          { prop: "rotaName", label: "值班人员", minWidth: 80 },
          { prop: "mobile", label: "联系电话", minWidth: 80 },
        ];
        this.getListData(val);
        this.$refs.libraryForm.resetFields();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 分页样式
::v-deep .btn-prev,
::v-deep .btn-next {
  position: relative;
}
// .content {
//   height: 500px;
//   overflow-y: auto;
// }
// ::v-deep .el-select-dropdown__item {
//   background: #333 !important;
// }
// .peopleNum {
//   color: #000;
//   font-weight: bold;
// }
// ::v-deep .el-radio-button__inner {
//   width: 200px;
// }
// .el-select-dropdown__item {
//   color: #fff;
// }
// .el-select-dropdown__item.selected,
// .el-select-dropdown__item:hover {
//   background: #081742;
//   color: #409eff !important;
// }
// .choice {
//   display: flex;
//   flex-direction: row;
// }
.line {
  height: 1px;
  width: 100%;
  // background: #143363;
  margin-bottom: 20px;
}
.el-table--border::after {
  width: 0;
}
// ::v-deep .el-input__inner {
//   background: #081742;
//   border-color: #25458f;
//   color: #fff;
// }
// .el-select .el-input.is-focus .el-input__inner {
//   border-color: #173f58;
// }

// ::v-deep .el-input__inner:hover {
//   border-color: #25458f;
// }
// ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
//   background-color: #0f2a47;
// }
// .el-select-dropdown__list {
//   background: #081742 !important;
// }
</style>
