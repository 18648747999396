<template>
  <div class="page-container bg-white p-4 h-full" v-loading="loading">
    <!-- 头部 -->
    <div class="header">
      <div class="tabs flex flex-wrap bg-white" style="padding: 5px 13px 0 0">
        <el-button
          style="margin: 0 10px 2px 0"
          size="small"
          v-for="(item, index) of responseData"
          :key="index"
          :type="responseEventActive == index ? 'primary' : ''"
          @click="handleResponseEventClick(index, item)"
          >{{ item.title }}</el-button
        >
      </div>
      <div
        class="bg-white flex justify-between"
        style="padding: 5px 13px 10px 0"
      >
        <span>
          <el-radio-group v-model="tabRadioOne" @change="tabRadioOneChange">
            <el-radio-button label="139">三防应急检查表</el-radio-button>
            <el-radio-button label="141"
              >广州市交通运输系统三防工作统计表</el-radio-button
            >
            <el-radio-button label="140">三防工作简报</el-radio-button>
          </el-radio-group>
        </span>
        <span class="checkDetail" @click="checkDetails">查看详情>></span>
      </div>
    </div>
    <div class="bottom flex-1 bg-white" style="padding: 0 0 1rem 0">
      <!-- 搜索 -->
      <div class="search-wrapper">
        <el-form
          :inline="true"
          size="small"
          :model="queryModel"
          class="demo-form-inline"
        >
          <el-radio-group
            v-show="tabRadioOne == '141'"
            v-model="tabRadioThree"
            size="small"
            style="margin-right: 20px"
            @change="getChangeThree"
          >
            <el-radio-button label="0" v-show="lineSiteBtn"
              >工点</el-radio-button
            >
            <el-radio-button label="1" v-show="rescueBtn"
              >专职救援队</el-radio-button
            >
          </el-radio-group>
          <el-radio-group
            v-show="tabRadioOne == '140'"
            v-model="tabRadioTwo"
            size="small"
            style="margin-right: 20px"
            @change="getChange"
          >
            <el-radio-button
              label="工点"
              v-if="BriefLineSiteBtn"
            ></el-radio-button>
            <el-radio-button
              label="总包部"
              v-if="BriefRescueBtn"
            ></el-radio-button>
          </el-radio-group>

          <el-form-item
            label="线路工点"
            prop="lineSite"
            v-show="
              (tabRadioThree == '0' && tabRadioOne != '140') ||
                (tabRadioOne == '140' && tabRadioTwo == '工点') ||
                tabRadioOne == '139'
            "
          >
            <el-cascader
              v-model="queryModel.lineSite"
              clearable
              :options="treeData"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="handleLineSiteChange"
            ></el-cascader>
          </el-form-item>
          <!-- 专职救援队 -->
          <el-form-item
            label="专职救援队"
            prop="teamId"
            v-show="tabRadioThree == '1' && tabRadioOne == '141'"
            class="tags-select-input"
          >
            <el-select
              v-model="queryModel.teamIds"
              clearable
              multiple
              collapse-tags
              placeholder="请选择专职救援队"
            >
              <el-option
                v-for="item in teamData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 总包部 -->
          <el-form-item
            label="总包部"
            prop="teamId"
            v-show="tabRadioTwo == '总包部' && tabRadioOne == '140'"
          >
            <el-select
              v-model="queryModel.optionUnitUuid"
              placeholder="请选择所属承包部"
              multiple
              collapse-tags
              clearable
              @change="handleDepartChange"
            >
              <el-option
                v-for="item of unitName"
                :key="item.id"
                :label="item.companyName"
                :value="item.uuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态" clearable>
            <el-select
              v-model="queryModel.status"
              clearable
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
          </el-form-item>

          <!-- 广州交通运输表 -->
          <el-form-item style="float: right" v-if="tabRadioOne == '141'">
            <el-button type="primary" @click="submission" v-if="operat"
              >汇总提交</el-button
            >
            <el-button type="primary" @click="Summary" v-if="operated"
              >汇总数据</el-button
            >
          </el-form-item>
          <!-- 三防工作简报 -->
          <el-form-item style="float: right" v-if="tabRadioOne == '140'">
            <el-button type="primary" @click="briSubmission" v-if="BriefOperat"
              >汇总提交</el-button
            >
            <el-button type="primary" @click="briSummary" v-if="BriefOperated"
              >汇总数据</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="table-wrapper">
        <el-table :data="tableData" ref="myTable" style="width: 100%">
          <el-table-column
            align="center"
            type="index"
            label="序号"
            width="100"
            :key="Math.random()"
          ></el-table-column>
          <el-table-column
            v-if="tabRadioThree != '1' || tabRadioTwo == '总包部'"
            align="center"
            prop="constructionName"
            label="线路-工点"
            :formatter="unitNameFormat"
            show-overflow-tooltip
            :key="Math.random()"
          ></el-table-column>
          <el-table-column
            v-if="tabRadioTwo == '总包部'"
            align="center"
            prop="optionUnitName"
            label="总包部"
            show-overflow-tooltip
            :key="Math.random()"
          ></el-table-column>
          <el-table-column
            v-if="tabRadioThree == '1' && tabRadioOne == '141'"
            align="center"
            prop="teamName"
            label="专职救援队"
            show-overflow-tooltip
            :key="Math.random()"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="times"
            label="填报次数"
            width="160"
            show-overflow-tooltip
            :key="Math.random()"
            ><template slot-scope="scope" v-if="scope.row.times">
              第{{ scope.row.times }}次
            </template></el-table-column
          >
          <el-table-column
            v-if="tabRadioOne != '139' && tabRadioTwo == '总包部'"
            align="center"
            prop="completion"
            label="工点完成情况"
            show-overflow-tooltip
            :key="Math.random()"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="deadline"
            label="截止时间"
            width="200"
            :key="Math.random()"
          >
            <template slot-scope="scope">
              <div
                v-if="scope.row.fillTime"
                :style="{
                  color: scope.row.deadline > scope.row.fillTime ? '' : 'red',
                }"
              >
                {{ scope.row.deadline }}
              </div>
              <div
                v-else
                :style="{ color: scope.row.deadline > gettime ? '' : 'red' }"
              >
                {{ scope.row.deadline }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="fillTime"
            label="填报时间"
            width="200"
            :key="Math.random()"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="fillUserName"
            label="填报人员"
            show-overflow-tooltip
            :key="Math.random()"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="status"
            label="状态"
            :formatter="formatState"
            show-overflow-tooltip
            :key="Math.random()"
          ></el-table-column>
          <!-- <el-table-column
            v-if="tabRadioOne != '139'"
            align="center"
            prop="approvalNames"
            label="审批人员"
            show-overflow-tooltip
          >
          </el-table-column> -->
          <el-table-column align="center" label="操作" width="150">
            <template slot-scope="scope">
              <!-- performanceState  2工点未完成填报能填报 重新填报 1是工点都填完了只能审批和查看 -->
              <el-button
                @click="onReport(scope.row)"
                type="text"
                size="small"
                :disabled="
                  (Number(scope.row.incompleteCount) > 0 &&
                    tabRadioOne == '140' &&
                    tabRadioTwo == '总包部') ||
                    btnLoading
                "
                v-show="
                  (tabRadioOne == '140' &&
                    tabRadioTwo == '总包部' &&
                    scope.row.performanceState == 2 &&
                    scope.row.approvalState == 1) ||
                    (tabRadioOne == '140' &&
                      tabRadioTwo == '工点' &&
                      scope.row.approvalState == 1) ||
                    (tabRadioOne != '140' && scope.row.approvalState == 1)
                "
                >填报</el-button
              >
              <el-button
                type="text"
                size="small"
                v-show="
                  scope.row.approvalState == 2 ||
                    scope.row.approvalState == 3 ||
                    (tabRadioOne == '140' &&
                      tabRadioTwo == '总包部' &&
                      scope.row.performanceState == 1 &&
                      scope.row.approvalState == 1)
                "
                @click="openView(scope.row)"
                :disabled="btnLoading"
                >查看
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="approvalClick(scope.row)"
                v-show="
                  (tabRadioOne == '140' &&
                    tabRadioTwo == '总包部' &&
                    scope.row.performanceState == 1 &&
                    scope.row.approvalState == 2) ||
                    (tabRadioOne == '140' &&
                      tabRadioTwo == '工点' &&
                      scope.row.approvalState == 2) ||
                    (tabRadioOne != '140' && scope.row.approvalState == 2)
                "
                :disabled="btnLoading"
                >审批</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="onReport(scope.row)"
                v-show="
                  (tabRadioOne == '140' &&
                    tabRadioTwo == '总包部' &&
                    scope.row.performanceState == 2 &&
                    scope.row.approvalState == 4) ||
                    (tabRadioOne == '140' &&
                      tabRadioTwo == '工点' &&
                      scope.row.approvalState == 4) ||
                    (tabRadioOne != '140' && scope.row.approvalState == 4)
                "
                :disabled="btnLoading"
                >重新填报</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-wrapper flex justify-center mt-4">
          <el-pagination
            background
            :total="page.count"
            :page-size="page.pageSize"
            :current-page="page.pageNum"
            layout=" prev, pager, next"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 右上角-查看详情 -->
    <el-dialog
      :title="detailTitle"
      :visible.sync="detailDialog"
      width="80%"
      :before-close="detailClose"
      center
      :close-on-click-modal="false"
      class="checkDialog"
      append-to-body
    >
      <DetailPage
        @detailClose="detailClose"
        v-if="detailDialog"
        :temList="temList"
        :historyData="historyData"
        :disasters="disaster"
        :statisticses="statisticses"
      ></DetailPage>
    </el-dialog>
    <!-- 三防应急检查表-填报 -->
    <el-dialog
      title="三防应急检查表"
      :visible.sync="emerDialog"
      :close-on-click-modal="false"
      append-to-body
      class="checkDialog"
      width="60%"
    >
      <emergencyWrite
        v-if="emerDialog"
        :titleOne="titleOne"
        :emerId="emerId"
        :siteUuId="siteUuId"
        @cancelInspection="cancelInspection"
      >
      </emergencyWrite>
    </el-dialog>
    <!-- 查看 -->
    <el-dialog
      title="三防应急检查表"
      :visible.sync="oneCheckDialog"
      :close-on-click-modal="false"
      append-to-body
      width="50%"
      class="checkDialog emergencyCheck"
    >
      <emergencyCheck
        v-if="oneCheckDialog"
        :operation="oneCheckTitle"
        :oneCheckData="oneCheckData"
        @cancelApproval="cancelApproval"
        @successApproval="successApproval"
      ></emergencyCheck>
    </el-dialog>
    <!-- 三防工作简报 -->
    <briefings
      ref="fillingListModal"
      @fingrest="onSearch"
      @btnClose="btnClose"
    ></briefings>
    <briefingsView
      ref="briefingsViewModal"
      @fingrest="onSearch"
      @btnClose="btnClose"
    ></briefingsView>

    <briefingsJG
      ref="fillingModal1"
      @fingrest="onSearch"
      @btnClose="btnClose"
    ></briefingsJG>
    <briefingsJGView
      ref="briefingsJGViewModal"
      @fingrest="onSearch"
      @btnClose="btnClose"
    ></briefingsJGView>
    <!-- 施工单位填报 -->
    <div v-if="buildDialog">
      <el-dialog
        title="广州市交通运输系统三防工作统计表"
        :visible.sync="buildDialog"
        :before-close="handleClose"
        append-to-body
        width="80%"
        center
      >
        <buildwrite
          ref="child"
          :statid="statid"
          @buildWriteClose="buildWriteClose"
        ></buildwrite>
      </el-dialog>
    </div>
    <!-- 救援队填报 -->
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="resuceWirte"
      :before-close="resuceClose"
      append-to-body
      width="80%"
      center
    >
      <rescuewrite
        ref="child"
        :statid="statid"
        @resuceClose="resuceClose"
      ></rescuewrite>
    </el-dialog>
    <!--救援队查看-->
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="rescueCheckDialog"
      :before-close="rescueCheckClose"
      append-to-body
      width="80%"
      center
    >
      <rescueCheck
        :approveid="approveid"
        :options="reseuceOptions"
        @rescueCheckClose="rescueCheckClose"
      ></rescueCheck>
    </el-dialog>
    <!-- 建管部值班领导汇总 -->
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="superyDialog"
      :before-close="handleCloses"
      append-to-body
      width="80%"
      center
      class="checkDialog"
    >
      <superv
        ref="supervisomodel"
        :ids="aponerid"
        :swert="swert"
        @handleCloses="handleCloses"
      ></superv>
    </el-dialog>
    <!-- 审批 查看-->
    <div v-if="checkerDialog">
      <el-dialog
        title="广州市交通运输系统三防工作统计表"
        :visible.sync="checkerDialog"
        append-to-body
        width="80%"
        center
      >
        <checker
          v-if="checkerDialog"
          :strery="strery"
          :approveid="approveid"
          @chexkpageClose="chexkpageClose"
        ></checker>
      </el-dialog>
    </div>

    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="qualitysDialog"
      append-to-body
      width="80%"
      center
      class="qualitys checkDialog"
    >
      <qualitys :ids="aponerids"></qualitys>
    </el-dialog>
    <!-- 三防工作简报 安质部长 汇总弹窗 -->
    <el-dialog
      title="汇总数据"
      :visible.sync="briQualitysDialogs"
      append-to-body
      width="1350px"
      center
    >
      <briQualitys
        ref="briQualitysMell"
        :activees="activees"
        :supervisionUuid="supervisionUuid"
        :userDept="userDept"
        :qualityResponseId="responseRecordId"
      ></briQualitys>
    </el-dialog>

    <!-- 工作简报 建管 汇总提交 -->
    <el-dialog
      title="三防工作简报"
      :visible.sync="regulatorySummary"
      append-to-body
      width="1350px"
      center
      v-if="regulatorySummary"
    >
      <regulatoryConstruction
        :activees="activees"
        :supervisionUuid="supervisionUuid"
        :userDept="userDept"
        @submit="regulatorySummarySubmit"
      ></regulatoryConstruction>
    </el-dialog>
    <!-- 应急检查表的 建管 汇总提交 -->
    <el-dialog
      title="广州市交通运输系统三防工作"
      :visible.sync="briQualitysDialog"
      append-to-body
      width="1350px"
      center
    >
      <regulatorySummary
        ref="regulatorySummary"
        :userDept="userDept"
        :qualityResponseId="responseRecordId"
      ></regulatorySummary>
    </el-dialog>
  </div>
</template>

<script>
// 弹框
// 计算当前时间
import { formatDate } from "@/utils/comfun.js";
// 查看详情-跳转
import DetailPage from "./history/checkDetails.vue";
// 检查表填报
import emergencyWrite from "./emergency/emergencyWrite.vue";
// 检查表查看/审批
import emergencyCheck from "./emergency/emergencyCheck.vue";

// 接口
import {
  // 检查表填报-获取检查项
  getCurrentResponseMsg,
  queryCurrentResponseListNew,
  // 专职救援队
  getCurrentResponseTeamList,
  // 获取救援队状态
  statusListByDictIdAndType,
  // queryUserConfirmResponse,
  getResponseStatusList,
  buttondata,
  getOps,
} from "@/api/response";
import { getTeamList } from "@/api/eres";
import { getDeptInfoById, getDepartmentTypeByUser, unit } from "@/api/org";
import {
  ResLog,
  getResList,
  // isRotaLeaderOrSafeDepartment,
  getSupervisionCurrentResponseListNew,
} from "@/api/responseTem";
import briefings from "./prevention/briefing.vue";
import briefingsView from "./prevention/briefingView.vue";
import briefingsJGView from "./prevention/briefingJGView.vue";

import briefingsJG from "./prevention/briefingJG.vue";
import superv from "./statistical/superviso.vue";
import briQualitys from "./prevention/statistical.vue";
import qualitys from "./statistical/quality.vue";
import checker from "./statistical/checkPage.vue";
import buildwrite from "./statistical/buildWrite.vue";
// 救援队
import rescuewrite from "./rescueTeam/write.vue";
import rescueCheck from "./rescueTeam/rescueCheck.vue";
import regulatorySummary from "./prevention/regulatorySummary.vue";
import regulatoryConstruction from "./prevention/regulatoryConstruction.vue";

export default {
  components: {
    emergencyWrite,
    briefings,
    briefingsJG,
    emergencyCheck,
    DetailPage,
    superv,
    qualitys,
    checker,
    buildwrite,
    briefingsView,
    briefingsJGView,
    briQualitys,
    rescuewrite,
    rescueCheck,
    regulatorySummary,
    regulatoryConstruction,
  },
  data() {
    return {
      tabRadioOne: "139",
      tabRadioTwo: "工点",
      tabRadioThree: "0",
      responseRecordId: null,
      // isConfirm: false,
      queryModel: {
        lineSite: [],
        status: "",
        teamIds: [],
        siteIds: [],
        optionUnitUuid: [],
      },
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true,
      },
      responseData: [],
      tabsData: [],
      tableData: [],
      loading: false,
      options: [],
      teamData: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        count: 0,
      },
      responseId: 0,
      historyData: [],
      listData: [],
      responseEventActive: 0,
      pageType: 0, // 限制当前页面所属类型（1：施工、2：监理、3：建管、4：质安部、5：其他）
      supervisionUuid: "", //监管部的UUID
      //查看详情-跳转
      detailDialog: false,
      disaster: 2,
      statisticses: 1,
      temList: {
        id: null,
        type: {
          label: "",
        },
        currentLevel: {
          label: "",
        },
        startTime: "",
        publisherName: "",
      },
      detailTitle: "",
      //应急检查表
      //填报
      titleOne: "",
      emerDialog: false,
      emerId: "",
      siteUuId: "",
      //查看
      oneCheckTitle: "",
      oneCheckData: {},
      oneCheckDialog: false,
      detailInfos: {},
      // 救援队
      resuceWirte: false,
      rescueCheckDialog: false,
      lineSiteBtn: false,
      rescueBtn: false,
      buildDialog: false,
      superyDialog: false,
      buildWriteResponseId: "",
      threeTitle: "",
      checkerDialog: false,
      strery: {},
      reseuceOptions: "",
      qualitysDialog: false,
      briQualitysDialog: false,
      briQualitysDialogs: false,
      regulatorySummary: false,
      // //交通运输表
      // deptFlag: false,
      userDept: "3",
      statid: {},
      approveid: {},
      aponerid: {},
      aponerids: {},
      rose: "",
      activees: {},
      operat: false,
      operated: false,
      swert: {},
      btnLoading: false,
      // 总包部
      unitName: [],
      BriefOperat: false,
      BriefOperated: false,
      BriefLineSiteBtn: false,
      BriefRescueBtn: false,
    };
  },
  created() {
    // (this.operat = false), (this.operated = false);
    this.history();
    // this.queryLineSiteTreeData();
    this.getResponseList();
    // 获取救援队数据
    this.getTeamData();
    this.queryDeptInfo();
    // this.getrot();
    this.getChangeValue();
    this.getUserDept();
    this.getDept();
    this.getBriefOps(); //三防工作简报列表的按钮权限
  },
  mounted() {
    this.getLineSiteTreeData();
  },

  beforeUpdate() {
    this.$nextTick(() => {
      this.$refs.myTable.doLayout(); // 解决表格错位
    });
  },
  methods: {
    getBriefOps() {
      getOps({}).then((res) => {
        if (res.code == 0) {
          res.data.forEach((item) => {
            if (item.operationText == "汇总提交") {
              this.BriefOperat = item.enabled;
            } else if (item.operationText == "汇总数据") {
              this.BriefOperated = item.enabled;
            } else if (item.operationText == "工点") {
              this.BriefLineSiteBtn = item.enabled;
            } else if (item.operationText == "总包部") {
              this.BriefRescueBtn = item.enabled;
            }
          });
        }
      });
    },
    // 总包下拉框的change
    handleDepartChange(val) {
      if (val.length > 1) {
        // .el-select .el-tag{
        //   max-width:70%
        // }
      }
    },
    // 总包部的查询下拉数据
    getDept() {
      let params = {
        category: "contracting",
      };
      unit(params).then((res) => {
        this.unitName = res.data;
      });
    },
    getChangeValue() {
      getResponseStatusList({ dictId: this.tabRadioOne })
        .then((res) => {
          this.options = [];
          res.data.forEach((item) => {
            this.options.push({
              value: item.code,
              label: item.text,
            });
          });
        })
        .catch((res) => {
          console.log(res);
        });
    },
    // 获取救援队数据
    getTeamData() {
      getTeamList({ teamType: 1 })
        .then((res) => {
          this.teamData = [];
          res.data.forEach((item) => {
            this.teamData.push({
              value: item.id,
              label: item.teamName,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取当前用户是什么部门
    getUserDept() {
      getDepartmentTypeByUser()
        .then((res) => {
          this.userDept = res.data;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    //权限按钮
    buttoner() {
      buttondata({ id: this.activees.responseRecordId }).then((res) => {
        if (res.code == 0) {
          res.data.forEach((item) => {
            if (item.operationText == "汇总提交") {
              this.operat = item.enabled;
            } else if (item.operationText == "汇总数据") {
              this.operated = item.enabled;
            } else if (item.operationText == "工点") {
              this.lineSiteBtn = item.enabled;
            } else if (item.operationText == "专职救援队") {
              this.rescueBtn = item.enabled;
            }
          });
        }
      });
    },
    // 判断 按钮权限
    rescueLineShow() {
      if (this.tabRadioOne == "141") {
        if (this.lineSiteBtn && !this.rescueBtn) {
          this.tabRadioThree = "0";
          this.loadData();
        } else if (this.rescueBtn && !this.lineSiteBtn) {
          this.tabRadioThree = "1";
          this.getTeamList();
        } else if (this.rescueBtn && this.lineSiteBtn) {
          this.tabRadioThree = "0";
          this.loadData();
        }
      } else if (this.tabRadioOne == "140") {
        if (this.BriefLineSiteBtn && !this.BriefRescueBtn) {
          this.tabRadioTwo = "工点";
          this.loadData();
        } else if (this.BriefRescueBtn && !this.BriefLineSiteBtn) {
          this.tabRadioTwo = "总包部";
          this.loadDataJG();
        } else if (this.BriefRescueBtn && this.BriefLineSiteBtn) {
          this.tabRadioTwo = "工点";
          this.loadData();
        }
      } else {
        this.loadData();
      }
    },
    // 表切换
    tabRadioOneChange(val) {
      this.tabRadioTwo = "工点";
      this.page.pageNum = 1;
      this.queryModel.lineSite = [];
      this.queryModel.siteIds = [];
      this.queryModel.status = "";
      this.queryModel.teamIds = [];
      this.page.pageSize = 10;
      this.rescueLineShow();
      statusListByDictIdAndType({ dictId: val, type: "0" })
        .then((res) => {
          this.options = [];
          res.data.forEach((item) => {
            this.options.push({
              value: item.code,
              label: item.text,
            });
          });
        })
        .catch((res) => {
          console.log(res);
        });
    },
    // 查看详情跳转
    checkDetails() {
      this.detailDialog = true;
      this.disaster = 2;
      if (this.tabRadioOne == "141" && this.tabRadioThree == "1") {
        this.statisticses = 2;
      } else {
        this.statisticses = 1;
      }
    },
    // 查看详情跳转-关闭
    detailClose() {
      this.detailDialog = false;
    },
    handleLineSiteChange(val) {
      this.queryModel.siteIds = [];
      for (let item of val) {
        this.queryModel.siteIds.push(item[item.length - 1]);
      }
    },
    // 查询当前登陆人的部门详情（可以获取部门类型）
    queryDeptInfo() {
      const user = JSON.parse(localStorage.getItem("user"));
      this.supervisionUuid = user.departmentId; //监管部的UUID
      getDeptInfoById(user.departmentId).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            this.pageType = res.data.roleType;
          }
        }
      });
    },
    async history() {
      const params = {
        page: 1,
        size: 1,
      };
      let res = await getResList(params);
      if (res.code == 0) {
        if (res.data.records.length > 0) {
          const record = res.data.records[0];
          let tempObj = {
            incidenceId: record.id,
            operationType: 384,
          };
          ResLog(tempObj).then((re) => {
            if (re.code == 0) {
              this.listData = re.data;
              this.listData.forEach((e) => {
                e.record = JSON.parse(e.record);
              });
            } else {
              this.$message.error(res.message);
            }
          });
        }
      }
    },
    // 按钮状态切换
    handleResponseEventClick(active, item) {
      console.log(item);
      this.activees = item;
      // this.ids = item.responseRecordId;

      if (this.responseEventActive == active) {
        return false;
      }
      this.tabsData = JSON.parse(
        JSON.stringify(this.responseData[active].operatorRecord)
      );
      this.responseRecordId = this.responseData[active].responseRecordId;
      this.tabRadioOne = "139";
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      this.queryModel.lineSite = [];
      this.queryModel.siteIds = [];
      this.queryModel.status = "";
      this.queryModel.teamIds = [];
      this.rescueLineShow();
      // this.isConfirm = false;
      // this.queryResponseConfirm();
      this.responseEventActive = active;
      this.temList.id = this.responseData[active].responseRecordId;
      this.temList.type.label = this.responseData[active].typeStr;
      this.temList.currentLevel.label = this.responseData[active].levelStr;
      this.historyData = this.tabsData;
      this.detailTitle = this.responseData[active].title + "详情";
      this.tabsData.forEach((element) => {
        if (element.actionType == "发布") {
          this.temList.respondImage = element.record.originalAlertMessage;
          this.temList.startTime = element.record.startTime;
          this.temList.publisherName = element.record.publisherName;
        }
      });
    },
    // 查询应急响应列表
    async getResponseList() {
      getCurrentResponseMsg().then((res) => {
        if (res.code == 0) {
          // this.responseData
          if (res.data.length) {
            const tempData = res.data;
            this.activees = tempData[0];
            this.buttoner();
            for (let active of tempData) {
              if (active && active !== undefined && active.operatorRecord) {
                for (let item of active.operatorRecord) {
                  if (item && item !== undefined && item.record) {
                    item.record = JSON.parse(item.record);
                  }
                }
              }
            }
            this.responseRecordId = tempData[0].responseRecordId;
            // this.queryResponseConfirm();
            this.loadData();
            this.responseData = tempData;
            this.tabsData = this.responseData[0].operatorRecord;
            this.temList.id = this.tabsData[0].incidenceId;
            this.temList.type.label = this.responseData[0].typeStr;
            this.temList.currentLevel.label = this.responseData[0].levelStr;
            this.historyData = this.tabsData;
            this.detailTitle = this.responseData[0].title + "详情";
            this.tabsData.forEach((element) => {
              if (element.actionType == "发布") {
                this.temList.respondImage = element.record.originalAlertMessage;
                this.temList.startTime = element.record.startTime;
                this.temList.publisherName = element.record.publisherName;
              }
            });
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 加载表格数据
    async loadData() {
      this.loading = true;
      const params = {
        responseRecordId: this.responseRecordId,
        isApproval: 0,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        reportType: this.tabRadioOne,
        siteUuidList: this.queryModel.siteIds,
        status: this.queryModel.status,
      };
      let res = await queryCurrentResponseListNew(params).catch((res) => {
        console.log(res);
        this.loading = false;
      });
      if (res.code == 0) {
        this.tableData = res.data.records;
        this.gettime = formatDate("YY-MM-DD hh:mm:ss");
        this.page.count = res.data.count;
        if (this.tableData) {
          this.responseId = this.tableData[0].responseId;
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    // 专职救援队
    async getTeamList() {
      console.log("分页按钮1", this.page.pageNum);
      this.loading = true;
      const params = {
        isApproval: 0,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        reportType: this.tabRadioOne,
        responseRecordId: this.responseRecordId,
        teamIds: this.queryModel.teamIds,
        status: this.queryModel.status,
      };
      let res = await getCurrentResponseTeamList(params).catch((res) => {
        console.log(res);
        this.loading = false;
      });
      if (res.code == 0) {
        this.tableData = res.data.records;
        this.page.count = res.data.count;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    // 加载总包表格数据
    async loadDataJG() {
      this.loading = true;
      const params = {
        responseRecordId: this.responseRecordId,
        isApproval: 0,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        reportType: this.tabRadioOne,
        siteUuidList: this.queryModel.siteIds,
        status: this.queryModel.status,
        optionUnitUuid: this.queryModel.optionUnitUuid,
      };
      let res = await getSupervisionCurrentResponseListNew(params).catch(
        (res) => {
          console.log(res);
          this.loading = false;
        }
      );
      if (res.code == 0) {
        this.tableData = res.data.records;
        this.gettime = formatDate("YY-MM-DD hh:mm:ss");
        this.page.count = res.data.count;
        if (this.tableData) {
          this.responseId = this.tableData[0].responseId;
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    //工作报表填报点击
    onReport(val) {
      this.btnLoading = true;
      //三防应急检查表
      if (this.tabRadioOne == "139") {
        this.emerId = val.id;
        this.siteUuId = val.siteUuId;
        this.emerDialog = true;
        this.titleOne = "填报";
        this.btnLoading = false;
        // 工作简报相关
      } else if (this.tabRadioOne == "140") {
        console.log(this.tabRadioOne, "工作简报相关");
        console.log(this.tabRadioTwo);
        if (this.tabRadioTwo == "工点") {
          this.$refs.fillingListModal.handleFilling(val);
        } else if (this.tabRadioTwo == "总包部") {
          this.$refs.fillingModal1.handleFilling(val);
        }
        // 交通统计表
      } else if (this.tabRadioOne == "141") {
        if (this.tabRadioThree == "0") {
          this.statid = Object.assign({}, val);
          this.buildDialog = true;
          this.btnLoading = false;
        } else {
          this.statid = Object.assign({}, val);
          this.resuceWirte = true;
          this.btnLoading = false;
        }
      }
    },
    // 查看详情
    openView(val) {
      this.btnLoading = true;
      //三防应急检查表
      if (this.tabRadioOne == "139") {
        this.oneCheckTitle = "查看";
        this.oneCheckData = { ...val };
        this.oneCheckDialog = true;
        this.btnLoading = false;
        console.log(this.oneCheckDialog, "三防应急检查表");
        // 工作简报相关
      } else if (this.tabRadioOne == "140") {
        if (val.status == 0) {
          this.$message("此表暂未填报");
          this.btnLoading = false;
          return;
        }
        if (this.tabRadioTwo == "工点") {
          this.$refs.briefingsViewModal.handleFilling(val, "read");
        } else if (this.tabRadioTwo == "总包部") {
          this.$refs.briefingsJGViewModal.handleFilling(val, "read");
        }

        // 交通统计表
      } else if (this.tabRadioOne == "141") {
        var ser = "1";
        this.approveid = Object.assign({}, val);
        this.btnLoading = false;
        if (this.tabRadioThree == "0") {
          this.strery = Object.assign({}, ser);
          this.checkerDialog = true;
        } else {
          this.reseuceOptions = "查看";
          this.rescueCheckDialog = true;
        }
      }
    },
    // 审批
    approvalClick(val) {
      this.btnLoading = true;
      //三防应急检查表
      if (this.tabRadioOne == "139") {
        this.oneCheckTitle = "审批";
        this.oneCheckData = { ...val };
        this.oneCheckDialog = true;
        this.btnLoading = false;
        // 工作简报相关
      } else if (this.tabRadioOne == "140") {
        // if (this.tabRadioTwo == "总包部") {
        // this.$refs.briefingsJGViewModal.handleFilling(val);
        this.$refs.briefingsViewModal.handleFilling(val, this.tabRadioTwo); //审批和详情页公用的一个页面
        // }
        // 交通统计表
      } else if (this.tabRadioOne == "141") {
        var ser = "approve";
        this.approveid = Object.assign({}, val);
        this.btnLoading = false;
        if (this.tabRadioThree == "0") {
          this.strery = Object.assign({}, ser);
          this.checkerDialog = true;
        } else {
          this.reseuceOptions = "审批";
          this.rescueCheckDialog = true;
        }
      }
    },
    btnClose() {
      this.btnLoading = false;
    },
    //建管数据汇总审批通过 关闭弹窗
    regulatorySummarySubmit() {
      this.regulatorySummary = false;
    },
    approvalClicks() {},
    //交通检查填报关闭
    buildWriteClose() {
      this.loadData();
      this.buildDialog = false;
    },
    //审批交通统计表关闭
    chexkpageClose() {
      this.checkerDialog = false;
      this.loadData();
    },
    // 检查表填报-关闭
    cancelInspection() {
      this.emerDialog = false;
      this.loadData();
    },
    // 检查表查看/审核-关闭
    successApproval() {
      this.oneCheckDialog = false;
      this.loadData();
    },
    cancelApproval() {
      this.oneCheckDialog = false;
    },
    // 状态回显
    // status：0-待填报 1-已驳回 2-待监理值班人员审批 3-待建管值班人员审批 4-待建管值班领导审批  5 已完成
    // status：0-待填报 1-总包已驳回 2-建管部已驳回 3-待总包审批 4-待建管部确认 5-已完成
    formatState: function(row, column, cellValue) {
      switch (cellValue) {
        case 0:
          return "待填报";
        case 1:
          return "总包已驳回";
        case 2:
          return "建管部已驳回";
        case 3:
          return "待总包审批";
        case 4:
          return "待建管部确认";
        case 5:
          return "已完成";
      }
    },
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    // queryResponseConfirm() {
    //   queryUserConfirmResponse({ responseId: this.responseRecordId }).then(
    //     (res) => {
    //       if (res.code == 0) {
    //         if (res.data > 0) {
    //           this.isConfirm = true;
    //         }
    //       } else {
    //         this.$message.error(res.message);
    //       }
    //     }
    //   );
    // },
    //查询
    onSearch() {
      if (this.tabRadioOne == "140" && this.tabRadioTwo == "总包部") {
        this.loadDataJG();
      } else if (this.tabRadioOne == "141" && this.tabRadioThree == "1") {
        this.getTeamList();
      } else {
        this.loadData();
      }
    },
    //页数
    handleSizeChange(val) {
      console.log("页数");
      this.page.pageSize = val;
      this.onSearch();
    },
    handleCurrentChange(val) {
      console.log("分页按钮", val);
      this.page.pageNum = val;
      // if (this.tabRadioThree == "1") {
      //   this.getTeamList();
      // } else {
      //   this.onSearch();
      // }
      if (this.tabRadioOne == "140" && this.tabRadioTwo == "总包部") {
        this.loadDataJG();
      } else if (this.tabRadioOne == "141" && this.tabRadioThree == "1") {
        this.getTeamList();
      } else {
        this.loadData();
      }
    },

    // 角色类型type为4时单位名称转换
    unitNameFormat(row) {
      if (row.siteUuId) {
        return `${row.lineName}-${row.siteName}`;
      } else {
        return row.supervisionName;
      }
    },
    //汇总提交
    submission() {
      if (this.tabRadioOne == "141") {
        // 广州轨道交通统计表
        this.aponerid = Object.assign({}, this.activees);
        var sert = [];
        sert.push("汇总提交");
        this.swert = Object.assign({}, sert);
        this.superyDialog = true;
      } else if (this.tabRadioOne == "140") {
        //这里是三防工作简报的汇总提交
      }
    },

    Summary() {
      this.aponerids = Object.assign({}, this.activees);
      this.qualitysDialog = true;
    },
    //简报 质安部 汇总数据
    briSummary() {
      this.briQualitysDialogs = true;
      this.$refs.briQualitysMell.getTableData();
    },
    //简报 监管部 汇总提交
    briSubmission() {
      console.log(this.activees, "activees");
      this.regulatorySummary = true;
    },
    handleClose() {
      this.$refs.child.build();
      this.buildDialog = false;
      this.loadData();
    },
    resuceClose() {
      this.resuceWirte = false;
      this.getTeamList();
    },
    rescueCheckClose() {
      this.rescueCheckDialog = false;
      this.getTeamList();
    },
    handleCloses() {
      this.superyDialog = false;
      this.$refs.supervisomodel.serder();
      this.loadData();
    },
    //判断当前响应页面列表是否有建管部按钮权限
    // getrot() {
    //   isRotaLeaderOrSafeDepartment({}).then((res) => {
    //     if (res.code == 0) {
    //       if (res.data) {
    //         this.deptFlag = res.data;
    //       }
    //     }
    //   });
    // },
    //施工 总包列表切换
    getChange(val) {
      // 三防工作简报切换后强制刷新一下 不然总包部的字段不显示
      // try {
      //   this.$refs.tableData.doLayout();
      // } catch (error) {
      //   console.log(error);
      // }

      this.page.pageNum = 1;
      if (val == "工点") {
        statusListByDictIdAndType({ dictId: this.tabRadioOne, type: "0" })
          .then((res) => {
            this.options = [];
            res.data.forEach((item) => {
              this.options.push({
                value: item.code,
                label: item.text,
              });
            });
          })
          .catch((res) => {
            console.log(res);
          });
        this.onSearch();
      } else {
        statusListByDictIdAndType({ dictId: this.tabRadioOne, type: "2" })
          .then((res) => {
            this.options = [];
            res.data.forEach((item) => {
              this.options.push({
                value: item.code,
                label: item.text,
              });
            });
          })
          .catch((res) => {
            console.log(res);
          });
        this.onSearch();
      }
    },
    getChangeThree(val) {
      this.page.pageNum = 1;
      if (val == "0") {
        statusListByDictIdAndType({ dictId: this.tabRadioOne, type: "0" })
          .then((res) => {
            this.options = [];
            res.data.forEach((item) => {
              this.options.push({
                value: item.code,
                label: item.text,
              });
            });
          })
          .catch((res) => {
            console.log(res);
          });
        this.onSearch();
      } else {
        statusListByDictIdAndType({ dictId: this.tabRadioOne, type: "1" })
          .then((res) => {
            this.options = [];
            res.data.forEach((item) => {
              this.options.push({
                value: item.code,
                label: item.text,
              });
            });
          })
          .catch((res) => {
            console.log(res);
          });
        this.getTeamList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// @import "../../../../style/scroll.scss";
.duty-container {
  height: 100%;

  .proofinge_top {
    background: rgba(255, 255, 255, 1);
    height: 300px;
    display: flex;
    justify-content: space-around;

    .proofi_left {
      width: 78%;
    }

    .proofi_right {
      width: 350px;
      height: 260px;
      background: rgba(67, 165, 233, 0.1);
      margin: auto;
      line-height: 300px;
    }
  }

  .crear {
    position: absolute;
    top: 17%;
    right: 9%;
  }

  .bottom {
    .table {
      width: 100%;
    }
  }
}
::v-deep.el-dialog__title {
  display: inline-block;
  width: 100%;
  text-align: center !important;
}
// .disasters ::v-deep.el-dialog__title {
//   display: inline-block;
//   width: 100%;
//   text-align: center !important;
// }
.table {
  display: block;
}
.p-4 {
  padding: 0 1rem 1rem;
}
.checkDetail {
  color: rgb(49, 134, 214);
  font-size: 14px;
  cursor: pointer;
}
.checkDialog ::v-deep .el-dialog__body {
  height: 680px;
  overflow: auto;
}
.emergencyCheck,
.qualitys ::v-deep .el-dialog__body {
  padding: 5px 20px !important;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
.tags-select-input {
  height: 30px;
  ::v-deep .el-select__tags {
    height: 30px;
    white-space: nowrap;
    overflow: hidden;
    flex-wrap: nowrap;
  }
  ::v-deep .el-select__tags-text {
    display: inline-block;
    max-width: 85px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
