<template>
  <div class="monitor-video-container p-4 relative h-full overflow-hidden">
    <div class="video-main-wrapper flex flex-col h-full">
      <div class="search-wrapper">
        <el-form :inline="true" :model="queryData" class="flex justify-between">
          <el-row>
            <el-form-item>
              <el-select
                v-model="queryData.type"
                placeholder="请选择风险点类型"
                clearable
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item>
              <el-input
                v-model="queryData.keyword"
                placeholder="输入关键字搜索"
              ></el-input>
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" @click="onSearch">查询</el-button>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item>
              <el-button type="primary" @click="handleSplitScreen(4)"
                >四分屏</el-button
              >
              <el-button type="primary" @click="handleSplitScreen(9)"
                >九分屏</el-button
              >
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <div
        class="video-wrapper flex-1 box-video"
        v-loading="loading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        <ul class="video-group overflow-y-scroll h-full flex flex-wrap">
          <li
            class="video-item"
            :class="splitScreenType == 4 ? 'division4' : 'division9'"
            v-for="item of videoData"
            :key="item.id"
          >
            <video
              v-if="item.sourceId == 0"
              :id="`my-video${item.id}`"
              class="video-js vjs-default-skin"
              controls
              preload="auto"
              width="100%"
              height="100%"
              muted
            >
              <source :src="item.url" type="application/x-mpegURL" />
            </video>
            <video
              v-else-if="item.sourceId == 2"
              class="video-js vjs-default-skin"
              :id="`test${item.id}`"
            ></video>
            <iframe
              v-else
              class="h-full w-full"
              :src="item.url"
              frameborder="0"
            ></iframe>
          </li>
        </ul>
      </div>
    </div>

    <!-- 线路工点树选择面板 -->
    <div
      class="handle-wrapper"
      :class="isShowPanl ? 'handle-wrapper-show' : 'handle-wrapper-hide'"
    >
      <div
        class="toggle-btn"
        :class="isShowPanl ? 'toggle-btn-close' : 'toggle-btn-open'"
        @click="handleTogglePanl"
      ></div>
      <div class="tree-wrapper h-full overflow-y-scroll">
        <el-input
          placeholder="输入关键字进行过滤"
          v-model="filterText"
          clearable
          style="margin-bottom: 10px; width: 95%"
        >
        </el-input>
        <el-tree
          ref="tree"
          class="filter-tree"
          v-show="isShowPanl"
          :data="lineSiteOptions"
          show-checkbox
          expand-on-click-node
          node-key="id"
          @check="handleTreeCheck"
          @node-click="handleTreeCheckChange"
          :default-expanded-keys="defaultExpandedKeys"
          :default-checked-keys="defaultCheckedKeys"
          :filter-node-method="filterNode"
          :props="props"
          :accordion="true"
        ></el-tree>
      </div>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
// import 'video.js/dist/video-js.css'//在main里引入了
import { tripleTree } from "@/api/map";
import { siteVideos, getfangxun } from "@/api/map";
import { lfsoftPlayerWS } from "@/utils/video";
import { getDictData } from "@/api/dict";

export default {
  data() {
    return {
      loading: false,
      filterText: "",
      queryData: {
        type: "",
        keyword: "",
      },
      isShowPanl: true,
      lineSiteData: [],
      defaultExpandedKeys: [],
      defaultCheckedKeys: [],
      typeList: [],
      videoData: [
        /* {
          id: 123,
          uuid: 111111111111,
          name: '九碧木',
          url: '192.222.225.74', //播放的地址
          videoOrNot: 1, //工点还是摄像头
          sourceId: 2,//区分中铁0 广联达1 九碧木2的视频
        } */
      ],
      playVideo: [], //九碧木的视频new出来的对象的集合 （播放视频）
      splitScreenType: 4,
      videoList: [],
      //中铁
      ztvideoList: [],
      //4=》9时不在重新选择了 直接显示地址
      hisvideoData: [],
      props: {
        disabled: "disabled",
        children: "children",
        label: "name",
      },
      lineSiteOptions: [],
    };
  },
  created() {
    this.tripleTree();
    this.getdeptdata();
  },
  mounted() {},
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },

  methods: {
    getdeptdata() {
      let label = "focusType";
      this.typeList = [];
      getDictData(label).then((res) => {
        if (res.code == 0) {
          res.data.forEach((element) => {
            this.typeList.push({
              label: element.label,
              value: element.code,
            });
          });
        }
      });
    },
    // 查询线路工点三级树
    tripleTree() {
      let params = {};
      this.defaultExpandedKeys = [];
      tripleTree(params).then((res) => {
        if (res.code == 0) {
          res.data.forEach((item) => {
            recursionfun(item);
          });
          // eslint-disable-next-line no-inner-declarations
          function recursionfun(data) {
            data.disabled = true;
            if (data.children && data.children.length > 0) {
              data.children.forEach((item) => {
                recursionfun(item);
              });
            }
          }
          this.lineSiteOptions = res.data;
          if (this.lineSiteOptions.length > 0) {
            this.defaultExpandedKeys.push(this.lineSiteOptions[0].id);
            // if (this.lineSiteOptions[0].children.length) {
            //   this.querySiteVideos(this.lineSiteOptions[0].children[0].id);
            // }
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 站点树的查找工点
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    //搜索
    onSearch() {
      this.loading = true;
      this.defaultExpandedKeys = [];
      this.filterText = "";
      // let params = { type: this.queryData.type };
      getfangxun(this.queryData.type ? this.queryData.type : "-").then(
        (res) => {
          this.loading = false;
          if (res.code == 0) {
            const lineSiteData = res.data;
            lineSiteData.forEach((item) => {
              recursionfun(item);
            });
            // eslint-disable-next-line no-inner-declarations
            function recursionfun(data) {
              if (data.children && data.children.length > 0) {
                data.disabled = true;
                data.children.forEach((item) => {
                  recursionfun(item);
                });
              }
            }
            this.lineSiteOptions = res.data;
            if (this.lineSiteOptions.length > 0) {
              this.defaultExpandedKeys.push(this.lineSiteOptions[0].id);
              // if (this.lineSiteOptions[0].children.length) {
              //   this.querySiteVideos(this.lineSiteOptions[0].children[0].id);
              // }
            }
          }
        }
      );
    },
    // 是否显示站点树
    handleTogglePanl() {
      this.isShowPanl = !this.isShowPanl;
    },
    // 四九分屏
    handleSplitScreen(type) {
      console.log(this.hisvideoData, "历史的9分屏的播放数组");
      this.splitScreenType = type;
      // this.getVideo()
      // 9分屏切换4分屏的时候把hls的地址清除请求
      if (type == 4) {
        this.$nextTick(() => {
          for (let i = 0; i < this.videoData.length; i++) {
            if (this.videoData[i].sourceId == 0 && i >= 4) {
              videojs("my-video" + this.videoData[i].id).dispose();
            }
            if (this.videoData[i].sourceId == 2 && i >= 4) {
              this.playVideo.forEach((item) => {
                if (item.W == this.videoData[i].ipAddress) {
                  item.disconnect();
                }
              });
            }
            if (i >= 4) {
              // 9分屏切换4分屏最后5个摄像头自动取消选中
              this.$refs.tree.setChecked(this.videoData[i], false, false);
            }
          }
          this.videoData.splice(4, 5);
        });
      }
      if (type == 9 && this.hisvideoData.length > 0) {
        this.videoData = [...this.hisvideoData];
        for (let i = 0; i < this.videoData.length; i++) {
          this.$refs.tree.setChecked(this.videoData[i], true, false);
          if (this.videoData[i].sourceId == 0 && i >= 4) {
            this.$nextTick(() => {
              videojs("my-video" + this.videoData[i].id).play();
            });
          }
        }
      }
      // })
    },
    // 中铁视频节点的创建及播放
    getVideo(i) {
      console.log(i, "当前要创建的节点video");
      videojs(
        "my-video" + i,
        {
          bigPlayButton: false,
          textTrackDisplay: false,
          posterImage: true,
          errorDisplay: false,
          controlBar: true,
        },
        function() {
          this.play();
        }
      );
    },
    // 复选框被选中时的状态
    handleTreeCheck(record, checkInfo) {
      console.log(record, checkInfo, "复选框选中");
      /**
       * 复选框选中后判断是不是摄像头  是就push到videoData里
       * 选中的是工点就 调videos接口 查询摄像头
       */
      // 工点  就查询他下边的摄像头
      // if (record.videoOrNot == 0) {
      if (record.lineSiteType == 0) {
        if (record.children && record.children.length > 0) {
          console.log(record.children);
        } else {
          if (checkInfo.checkedNodes.includes(record)) {
            this.querySiteVideos(record.id);
          }
        }

        //摄像头
      } else if (record.videoOrNot == 1) {
        // } else if (record.$treeNodeId == 5) {
        console.log(
          !this.videoData.includes(record),
          "当前选中的是否在videoData数组里"
        );
        // 判断当前选中的是否在数组里  不在数组里就是选中
        if (!this.videoData.includes(record)) {
          // 当前播放的视频是否超过49分屏
          if (this.videoData.length < this.splitScreenType) {
            this.videoData.push(record);

            this.hisvideoData = [...this.videoData];
            //中铁的视频
            if (record.sourceId == 0) {
              if (!this.ztvideoList.includes(record)) {
                this.ztvideoList.push(record);
                this.$nextTick(() => {
                  this.getVideo(record.id);
                });
              }
            }
            //广联达的视频
            if (record.sourceId == 2) {
              this.$nextTick(() => {
                let IPhost = "zhjk.geobim.cn:3226";
                if (record.ipAddress.slice(0, 3) == 172) {
                  IPhost = "zhjk.geobim.cn:3226";
                } else {
                  IPhost = "spjk.geobim.cn:3234";
                }
                var conf1 = {
                  videoid: "test" + record.id,
                  protocol: "https:",
                  host: IPhost,
                  rootpath: "/",
                  token: record.ipAddress,
                  hlsver: "v1",
                  session: "c1782caf-b670-42d8-ba90-2244d0b0ee83", //session got from login
                };
                var v1 = new lfsoftPlayerWS(conf1);
                console.log(conf1, v1);
                this.playVideo.push(v1);
                v1.connect();
              });
            }
          } else {
            this.$refs.tree.setChecked(record, false, false);
            this.$message.warning("当前播放已达到最大");
          }
        } else {
          /**
           * 要是超出几分屏的最大限制 就取消当前的选中
           */
          // 在数组里就是取消选中   去做取消按钮的销毁video节点
          this.ztvideoList.forEach((item, index) => {
            if (item.id == record.id) {
              let video = videojs("my-video" + item.id);
              video.dispose();
              this.ztvideoList.splice(index, 1);
            }
          });
          this.playVideo.forEach((item) => {
            if (item.W == record.ipAddress) {
              item.disconnect();
            }
          });
          // v-for的数组里删除带当前选中的视频
          this.videoData.forEach((item, index) => {
            if (item.id == record.id) {
              this.videoData.splice(index, 1);
            }
          });
        }
      }
    },
    //点击工点获取他下边的摄像头列表
    handleTreeCheckChange(data, node) {
      console.log(data, node);
      if (!data.children && !data.videoOrNot) {
        // if (node.level == 4) {
        this.querySiteVideos(data.id);
        node.expanded = !node.expanded; //在组件点击事件 中使用
        // }
      }
    },
    // 查询工点下的视频  //点击工点获取他下边的摄像头列表
    querySiteVideos(siteId) {
      this.loading = true;
      const params = {
        siteId,
        type: this.queryData.type ? this.queryData.type : "-",
      };
      siteVideos(params)
        .then((res) => {
          this.loading = false;
          if (res.code == 0) {
            let treeVideo = [];
            if (res.data.length > 0) {
              res.data.forEach((element) => {
                treeVideo.push({
                  id: element.id,
                  uuid: element.uuid,
                  name: element.name,
                  url: element.hls,
                  videoOrNot: element.videoOrNot,
                  sourceId: element.sourceId,
                  ipAddress: element.ipAddress,
                });
              });
              this.$nextTick(() => {
                this.$refs["tree"].updateKeyChildren(siteId, treeVideo);
              });
            } else {
              this.$message.warning("该工点摄像头未接入");
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  //页面销毁
  beforeDestroy() {
    console.log("页面销毁 video销毁");
    for (let i = 0; i < this.ztvideoList.length; i++) {
      videojs("my-video" + this.ztvideoList[i].id).dispose();
    }
    this.playVideo.forEach((item) => {
      item.disconnect();
    });
  },
};
</script>

<style lang="scss" scoped>
.monitor-video-container {
  background: rgba(8, 23, 66, 0.9);
}
::v-deep .el-input__inner {
  background: #18467f;
  border: none;
  color: #fff;
}

.handle-wrapper {
  min-width: 240px;
  max-width: 30vw;
  height: 60vh;
  position: absolute;
  top: 20%;
  background: rgba(11, 20, 53, 0.9);
  box-shadow: 10px 0px 25px 0px #002e75 inset;
  border-radius: 0px 20px 20px 0px;
  padding: 20px;
  z-index: 99;

  .tree-wrapper {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  ::v-deep .el-tree {
    background: transparent;
    color: #fff;
  }

  ::v-deep .el-tree-node__content:hover {
    background: transparent;
  }

  ::v-deep .el-tree-node:focus > .el-tree-node__content {
    background: transparent;
  }

  .toggle-btn {
    width: 28px;
    height: 240px;
    position: absolute;
    right: 0px;
    top: 50%;
    margin-top: -120px;
    cursor: pointer;
    background-image: url(../../../assets/sq.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }

  .toggle-btn-close {
    transform: rotate(180deg);
  }

  .toggle-btn-open {
    transform: rotate(0deg);
  }
}

.handle-wrapper-hide {
  left: -212px;
  background: transparent;
  box-shadow: none;
}

.handle-wrapper-show {
  left: 0px;
  background: rgba(11, 20, 53, 0.9);
  box-shadow: 10px 0px 25px 0px #002e75 inset;
}

.video-wrapper {
  .video-group {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .video-item {
      background: #000;
    }

    .division9 {
      width: 32.66%;
      height: 32%;
      margin-top: 1%;
      margin-right: 1%;

      &:nth-child(1) {
        margin-top: 0;
      }

      &:nth-child(2) {
        margin-top: 0;
      }

      &:nth-child(3) {
        margin-top: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    .division4 {
      width: 49.5%;
      height: 49.5%;
      margin-top: 1%;
      margin-right: 1%;

      &:nth-child(1) {
        margin-top: 0;
      }

      &:nth-child(2) {
        margin-top: 0;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
::v-deep .video-js {
  width: 100%;
  height: 100%;
}

.box-video {
  height: calc(100% - 62px);
}

::v-deep .el-popper[x-placement^="bottom"] {
  margin-top: 12px;
  overflow-y: auto;
  height: 100px;
}
</style>
