<template>
  <!-- 汇总数据-查看详情 -->
  <div
    v-loading="tableLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div style="color:#ffffff">
      <span style="margin-right:100px">汇总单位：{{ summaryDepartment }}</span
      ><span>汇总次数:{{ times }}</span>
    </div>
    <el-table
      ref="tableData"
      :data="tableData"
      id="tableData"
      border
      :row-style="{ height: '30px' }"
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column
        label="线路工点"
        prop="lineSite"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        label="派出工作组(个)从应急响应启动到本次报送时间节点,派出工作组的累计数量"
        align="center"
        prop="workGroup"
      >
      </el-table-column>
      <el-table-column
        prop="position"
        label="检查场所部位(处)从应急响应启动到本次报送时间节点,检查场所部位的累计数量"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="hiddenDanger"
        label="新排查出三防风险隐患(处)上—次报送时间节点到本次报送时间节点，新排查出的三防风险隐患数量"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="info"
        label="发送预警或防御信息(条)地铁集团不需要填该项"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.info ? scope.row.info : "/" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="worker"
        label="预制(出动)抢险人员(人)在本次报送时间节点，能立即调动的抢险人员"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="team"
        label="预制(出动)抢险队伍(支)在本次报送时间节点，能立即调动的抢险队伍数量"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="预置(出动)抢险设备(台)指在本次报送时间节点，能立即调动设备数量"
        align="center"
      >
        <el-table-column prop="deviceTotal" label="合计" align="center">
        </el-table-column>
        <el-table-column
          prop="vehicle"
          label="车辆能立即调动的抢险汽车、吊车、铲车、履带车等"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="boat"
          label="舟艇能立即调动的舟船、划艇等水上运输工具数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="waterPump"
          label="抽水机能立即调动的龙吸水、自吸泵、排污泵、潜水泵、动力站水泵等抽排水设备数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="other"
          label="其他能立即调动的、未统计在车辆、舟艇、抽水机范围内的三防抢险设备数量"
          align="center"
        >
        </el-table-column>
      </el-table-column>

      <el-table-column
        prop="remark"
        label="其他应急措施落实及受灾害影响情况指从应急响应启动到本次报送时间节点，集团公司各单位应急措施落实及受灾受影响累计情况"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.remark ? scope.row.remark : "/" }}
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: center; margin-top: 20px">
      <el-button @click="sureSub" type="primary" :disabled="derty"
        >导出</el-button
      >
    </div>
  </div>
</template>

<script>
// 接口
import { qualitydata, derivedata } from "@/api/response";

export default {
  props: {
    stasher: {
      type: String,
    },
    resetid: {
      type: Number,
    },
    responseId: {
      type: Number,
    },
    supervisionUuid: {
      type: String,
    },
  },
  components: {
    // checkPage,
  },
  data() {
    return {
      tableData: [],
      summaryDepartment: "",
      times: "",
      derty: false,
      timeList: [],
      tableLoading: false,
    };
  },
  created() {},
  mounted() {},
  watch: {
    resetid: {
      handler(val) {
        if (val) {
          this.tableData = [];
          this.serte(val);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    serte(val) {
      this.tableLoading = true;
      qualitydata({ id: val }).then((res) => {
        this.tableLoading = false;
        this.tableData = res.data.list;
        this.summaryDepartment = res.data.summaryDepartment;
        this.times = res.data.times;
        this.timeList = res.data.timeList;
      });
    },

    // 表格的汇总
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "汇总";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "/";
        }
      });

      return sums;
    },
    //导出弹框
    sureSub() {
      this.$confirm("确定导出, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableLoading = true;
          derivedata({
            times: this.timeList,
            responseId: this.responseId,
            supervisionUuid: this.supervisionUuid,
          }).then((res) => {
            let blob = new Blob([res], {
              type: "application/vnd.ms-excel",
            });
            // 3.创建一个临时的url指向blob对象
            let url = window.URL.createObjectURL(blob);
            // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
            let a = document.createElement("a");
            a.href = url;
            a.download = "导出数据_" + new Date().getTime() + ".xlsx"; //自定义导出文件名
            a.click();
            this.tableLoading = false;
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //导出文件
    derive() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__cell {
  border: 1px solid #143363 !important;
}
::v-deep .el-radio-button {
  width: 75px;
}
::v-deep .el-radio-button__inner {
  width: 65px;
}
::v-deep .el-descriptions-item__cell {
  background: #081742;
}
::v-deep .el-table--border::after {
  width: 0;
}
</style>
