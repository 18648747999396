<template>
  <!-- 关键节点统计 -->
  <div class="emergency-container">
    <index-box :title="title">
      <div class="emergency-wrapper">
        <div
          class="list"
          v-for="(item, index) in groupData"
          :key="index"
          @click="manageClick(item)"
        >
          <div class="listLeft"></div>
          <div class="listRight">
            <div class="text">
              <p class="textOne">{{ item.rank }}</p>
              <p class="textTwo">{{ item.name }}</p>
            </div>
            <p
              class="count"
              :style="{
                color: item.name == '已验收' ? '#1791ff' : '#f0af41',
              }"
            >
              {{ item.dueCount }}
            </p>
          </div>
        </div>
      </div>
    </index-box>
    <HomePageDialog
      ref="modalTable"
      :titleTable="titleTable"
      :columns="columns"
      :tableList="tableList"
      :totalData="totalData"
      :params="params"
    ></HomePageDialog>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import HomePageDialog from "@/views/HiddenDanger/components/HomePageDialog.vue";

// 数据,弹框
import { getSubWayNodeCount, getSubWayNodePage } from "@/api/hdanger";
export default {
  name: "emergency-container",
  components: {
    IndexBox,
    HomePageDialog,
  },
  data() {
    return {
      title: "关键节点统计",
      groupData: [
        {
          rank: "Ⅰ类",
          dueCount: 190,
          name: "已验收",
        },
        {
          rank: "Ⅰ类",
          dueCount: 719,
          name: "未验收",
        },
        {
          rank: "Ⅱ类",
          dueCount: 74,
          name: "已验收",
        },
        {
          rank: "Ⅱ类",
          dueCount: 605,
          name: "未验收",
        },
      ],
      titleTable: "",
      columns: [
        { prop: "lineName", label: "线路", minWidth: 80 },
        { prop: "siteName", label: "工点", minWidth: 80 },
        { prop: "nodeType", label: "类别", minWidth: 80 },
        { prop: "nodeName", label: "关键节点名称", minWidth: 80 },
        { prop: "status", label: "状态", minWidth: 80 },
      ],
      tableList: [],
      totalData: 0,
      params: {
        status: "",
        nodeLevel: "",
      },
    };
  },

  mounted() {
    this.getData();
  },
  methods: {
    manageClick(data) {
      this.titleTable = data.rank + data.name + "节点";
      this.$refs.modalTable.handleOpen("center", "关键节点统计");
      let paramData = {
        status: data.name == "未验收" ? "2" : "1",
        nodeLevel: data.rank == "Ⅰ类" ? "一类关键节点" : "二类关键节点",
        siteIdList: [],
        nodeType: "",
        pageNum: 1,
        pageSize: 5,
      };
      this.params = {
        status: paramData.status,
        nodeLevel: paramData.nodeLevel,
      };
      getSubWayNodePage(paramData).then((res) => {
        if (res.code == 0) {
          this.tableList = res.data.records;
          this.totalData = res.data.count;
        }
      });
    },
    getData() {
      getSubWayNodeCount().then((res) => {
        if (res.code == 0) {
          if (res.data) {
            this.groupData.forEach((item) => {
              if (item.rank == "Ⅰ类" && item.name == "已验收") {
                item.dueCount = res.data.checkCountOne;
              } else if (item.rank == "Ⅰ类" && item.name == "未验收") {
                item.dueCount = res.data.notCheckCountOne;
              } else if (item.rank == "Ⅱ类" && item.name == "已验收") {
                item.dueCount = res.data.checkCountTwo;
              } else if (item.rank == "Ⅱ类" && item.name == "未验收") {
                item.dueCount = res.data.notCheckCountTwo;
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.emergency-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  .list {
    width: 50%;
    height: calc(50% - 5px);
    display: flex;
    justify-content: flex-start;
    .listLeft {
      width: 4px;
      height: 100%;
      background: #1791ff;
    }
    .listRight {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .text {
        color: #ffffff;
        margin-right: 10px;
        font-size: 14px;
        .textOne {
          display: flex;
          justify-content: center;
        }
        .textTwo {
          display: flex;
          justify-content: center;
        }
      }
      .count {
        font-size: 30px;
        font-weight: bold;
      }
    }
  }
  .list:nth-child(1),
  .list:nth-child(2) {
    margin-bottom: 10px;
  }
  .count {
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>
