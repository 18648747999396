<template>
  <div class="material-container h-full">
    <el-steps
      :active="active"
      finish-status="success"
      process-status="wait"
      align-center
    >
      <el-step
        title=""
        v-for="item in years"
        :key="item.id"
        :status="
          item.status == 1 ? 'success' : item.status == 0 ? 'error' : 'wait'
        "
      ></el-step>
    </el-steps>
    <div class="yearName">
      <div
        :class="
          active > item.id
            ? 'prevYear'
            : active < item.id
            ? 'nextYear'
            : 'activeYear'
        "
        v-for="item in years"
        :key="item.id"
      >
        <span :class="item.status == 0 ? 'rejectStyle' : ''">{{
          item.name
        }}</span>
      </div>
    </div>
    <div class="dialogWrap">
      <p class="riskName">
        风险名称<span>{{ riskName }}</span>
      </p>
      <div class="process">
        <div class="process_left">
          <el-steps
            :active="colActive"
            finish-status="success"
            direction="vertical"
            align-center
            space="60px"
            process-status="error"
          >
            <el-step
              title=""
              :status="item.status ? 'success' : 'error'"
              v-for="item in colYears"
              :key="item.id"
            ></el-step>
          </el-steps>
        </div>
        <div class="process_content">
          <p v-for="item in colYears" :key="item.id">
            <span class="process_content_time">{{ item.time }}</span
            ><span>{{ item.content }}</span>
          </p>
        </div>
        <div class="process_right">
          <span v-for="item in colYears" :key="item.id"
            ><span v-if="item.approverFile" @click="viewFile(item.approverFile)"
              >附件下载</span
            ></span
          >
        </div>
      </div>
    </div>
    <div class="dialogFooter">
      <el-button size="medium" @click="cancel">取 消</el-button>
      <el-button size="medium" type="primary" @click="submit">确 定</el-button>
    </div>
    <filePreview ref="fileView" :src="fileUrl" :model="false"></filePreview>
  </div>
</template>

<script>
import filePreview from "@/components/Uploader/filePreview.vue";
import { riskApprovalRecord } from "@/api/risk";
// 字典
export default {
  components: {
    filePreview,
  },
  props: {
    pageData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fileUrl: "",
      active: 1,
      years: [],
      colActive: 5,
      colYears: [],
      riskName: "风险名称",
      stateList: [
        "施工单位申请",
        "监理单位审核",
        "总承包单位审核",
        "建管部审核",
        "建设公司审核",
        "集团审批",
      ],
      rejectStateList: [
        { value: 1, label: "正常" },
        { value: 2, label: "监理驳回" },
        { value: 3, label: "总包驳回" },
        { value: 4, label: "建管部驳回" },
        { value: 5, label: "建设公司驳回" },
        { value: 6, label: "集团驳回" },
      ],
    };
  },
  created() {
    riskApprovalRecord(this.pageData.riskDeafUuid).then((res) => {
      console.log(res);
      if (res.code == 0) {
        if (res.data.length) {
          this.colYears = [];
          res.data.forEach((i) => {
            let obj = {};
            obj.time = i.created;
            obj.content = i.approverRecord;
            obj.status = i.approverStatus;
            obj.approverFile = i.approverFile;
            this.colYears.push(obj);
          });
        }
      }
    });
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      console.log(this.pageData);
      for (let i = 0; i < 6; i++) {
        let obj = {};
        obj.id = i;
        obj.name = this.stateList[i];
        obj.status = 2;
        this.years.push(obj);
      }
      this.active =
        this.pageData.rejectState == 1
          ? this.pageData.state - 1
          : this.pageData.rejectState;
      this.riskName = this.pageData.riskName;
      // 判断当前是否是驳回状态
      if (this.pageData.rejectState != 1) {
        this.years[this.pageData.rejectState - 1].status = 0;
        for (let i = 0; i < this.pageData.rejectState - 1; i++) {
          this.years[i].status = 1;
        }
      } else {
        for (let i = 0; i < this.pageData.state - 1; i++) {
          this.years[i].status = 1;
        }
      }
      console.log(this.years);
    },
    viewFile(url, name = "附件") {
      let fileUrl = url.split(',')[1]
      this.downLoadFile(fileUrl, name);
      // console.log(url);
      // this.fileUrl = url;
      // this.$refs.fileView.handleOpen();
    },
    // downloadFile(url, name = "附件") {
    //   console.log(url);
    //   var x = new XMLHttpRequest();
    //   x.open("GET", url, true);
    //   x.responseType = "blob";
    //   x.onload = function (e) {
    //     var url = window.URL.createObjectURL(x.response);
    //     var a = document.createElement("a");
    //     a.href = url;
    //     a.download = name;
    //     a.click();
    //   };
    //   x.send();
    // },
    // 文件下载
    // downloadFile(url, name = "附件") {
    //   console.log(url);
    //   var x = new XMLHttpRequest();
    //   x.open("GET", url, true);
    //   x.responseType = "blob";
    //   x.onload = function (e) {
    //     var url = window.URL.createObjectURL(x.response);
    //     var a = document.createElement("a");
    //     a.href = url;
    //     a.download = name;
    //     a.click();
    //   };
    //   x.send();
    // },
    submit() {},
    cancel() {
      this.$emit("CloseData");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
::v-deep .el-step__title.is-success {
  color: #409eff;
}
::v-deep .el-step__head.is-success {
  color: #409eff;
  border-color: #409eff;
}
.yearName {
  margin-top: 14px;
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  span {
    cursor: pointer;
    color: #999;
    display: inline-block;
    width: 130px;
    text-align: center;
  }
  .activeYear {
    text-align: center;
    span {
      color: #999;
    }
  }
  .nextYear {
    text-align: center;
    span {
      color: #999;
    }
  }
  .prevYear {
    text-align: center;
    span {
      color: #409eff;
    }
    .rejectStyle {
      color: #ff0000;
    }
  }
}
.dialogWrap {
  padding: 0 46px;
  .riskName {
    font-size: 16px;
    margin: 30px 0 20px;
    span {
      margin-left: 20px;
    }
  }
  .process {
    display: flex;
    .process_left {
      width: 50px;
    }
    .process_content {
      flex: 1;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      position: relative;
      top: -18px;
      span {
        color: #333;
      }
      .process_content_time {
        margin-right: 20px;
      }
    }
    .process_right {
      width: 100px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      position: relative;
      top: -18px;
      span {
        cursor: pointer;
        color: #409eef;
      }
    }
  }
}
</style>
