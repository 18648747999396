<template>
  <el-dialog
    class="myDialog"
    :title="title"
    :visible.sync="dialogVisible"
    :width="width"
    :before-close="handleClose"
    append-to-body
    :modal="modal"
  >
    <iframe
      :src="src"
      frameborder="0"
      scrolling="auto"
      width="100%"
      height="450px"
    ></iframe>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">关 闭 </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "预览",
    },
    width: {
      type: [String, Number],
      default: "50%",
    },
    modal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    handleOpen() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.$emit("srcClose");
      // this.src = ''
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss">
.myDialog {
  height: 90%;
}
</style>