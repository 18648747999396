<template>
  <router-view />
</template>

<script>
export default {
  name: "screen-layout",
};
</script>

<style lang="scss" scoped>
::v-deep .pagination-wrapper {
  .el-pagination {
    .btn-prev,
    .btn-next {
      background: #18467f;

      .el-icon {
        color: #606266;
      }
    }
  }

  .el-pager li {
    background: #18467f;
    color: #fff;
  }
}
</style>
