<template>
  <el-upload
    action="action"
    :http-request="handleUpload"
    list-type="text"
    :disabled="disabled"
    :on-remove="handleRemoveCover"
    :file-list="list"
    :show-file-list="showFileList"
  >
    <i v-if="type != 'btn'" class="el-icon-upload"></i>
    <el-button
      type="primary"
      v-if="type == 'btn'"
      size="mini"
      :disabled="disabled"
      icon="el-icon-plus"
      >点击上传</el-button
    >
  </el-upload>
</template>

<script>
import { uploadThumb } from "@/api/upload";
export default {
  name: "FileUploader",
  props: {
    value: {
      type: Array
    },
    type: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showFileList: {
      type: Boolean,
      default: true
    }
  },
  model: {
    prop: "value",
    event: "change"
  },
  watch: {
    value: {
      handler(e) {
        let temp = [];
        if (e && e.length) {
          e.forEach(file => {
            let res = this.list.find(item => item.url === file.url);
            if (res) {
              temp.push(res);
            } else {
              temp.push({
                url: file.url,
                name: file.name
              });
            }
          });
        }
        this.list = [...temp];
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      list: []
    };
  },
  methods: {
    handleUploadImg() {},
    async handleUpload(args) {
      let res = await uploadThumb(args.file, e => {
        this.percent = ((e.loaded / e.total) * 100) | 0;
        return args.onProgress({ percent: this.percent });
      });
      if (res.code === 0) {
        this.list.push({
          uid: args.file.uid,
          url: res.data.uri,
          name: res.data.originalName
        });
        args.onSuccess(res, args.file, this.list);
        this.emitEvent();
        this.emitEvent2({
          url: res.data.uri,
          name: res.data.originalName,
          realUrl: res.data.fullPath
        });
      }
    },
    handleRemoveCover(file) {
      let index = this.list.findIndex(v => v.url === file.url);
      this.list.splice(index, 1);
      this.emitEvent();
      this.emitDelEvent(file);
    },
    emitEvent() {
      this.$emit(
        "change",
        this.list.map(v => v.url)
      );
    },
    emitEvent2(record) {
      this.$emit("uploadChange", record);
    },
    emitDelEvent(record) {
      this.$emit("delChange", record);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-icon-upload {
  font-size: 30px;
  line-height: 40px;
}
</style>
