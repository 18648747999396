<template>
  <div class="info-container">
    <index-box :title="title">
      <div id="dangerousBig" class="dangerousBig"></div>
    </index-box>
    <HomePageDialog
      ref="modalTable"
      :titleTable="titleTable"
      :columns="columns"
      :tableList="tableList"
      :totalData="totalData"
      :params="params"
    ></HomePageDialog>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import HomePageDialog from "@/views/HiddenDanger/components/HomePageDialog.vue";

// 数据,弹框
import { getDangerous, getDangerousProjectPage } from "@/api/hdanger";
export default {
  name: "info-container",
  components: {
    IndexBox,
    HomePageDialog,
  },
  data() {
    return {
      title: "危大工程统计",
      titleTable: "",
      columns: [
        { prop: "lineName", label: "线路", minWidth: 80 },
        { prop: "siteName", label: "工点", minWidth: 80 },
        { prop: "projectType", label: "类别", minWidth: 80 },
        { prop: "projectName", label: "危大工程名称", minWidth: 80 },
        { prop: "status", label: "开工状态", minWidth: 80 },
      ],
      tableList: [],
      totalData: 0,
      params: {
        status: "",
        bigger: "",
      },
      seriesData: [
        {
          name: "未开工",
          type: "bar",
          stack: "Ad",
          barWidth: "35%",
          emphasis: {
            focus: "series",
          },
          label: {
            show: true,
          },
          color: "#72C040",
          data: [],
        },
        {
          name: "施工中",
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          label: {
            show: true,
          },
          color: "#F0AF41",
          data: [],
        },
        {
          name: "已完工",
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          label: {
            show: true,
          },
          color: "#E23C39",
          data: [],
        },
      ],
    };
  },
  mounted() {
    this.getData();
    this.RefererShow();
  },
  methods: {
    RefererShow() {
      var myChartTwo = this.$echarts.init(
        document.getElementById("dangerousBig")
      );

      var option = {
        tooltip: {},
        legend: {
          textStyle: {
            color: "#ffffff",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "0%",
          top: "20%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["一般规模", "超过一定规模"],
            axisLine: {
              lineStyle: {
                color: "#ffffff",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#ffffff",
              },
            },
          },
        ],
        series: this.seriesData,
      };
      myChartTwo.clear();
      option && myChartTwo.setOption(option);
      myChartTwo.off("click");
      myChartTwo.hideLoading();
      myChartTwo.on("click", (param) => {
        this.titleTable = param.name + "危大工程" + param.seriesName + "列表";
        this.$refs.modalTable.handleOpen("center", "危大工程统计");
        let paramData = {
          status: param.seriesName,
          bigger: param.name == "超过一定规模" ? "1" : "0",
          siteIdList: [],
          projectTypeId: "",
          pageNum: 1,
          pageSize: 5,
        };
        this.params = {
          status: paramData.status,
          bigger: paramData.bigger,
        };
        getDangerousProjectPage(paramData).then((res) => {
          if (res.code == 0) {
            this.tableList = res.data.records;
            this.totalData = res.data.count;
          }
        });
      });
    },
    getData() {
      getDangerous().then((res) => {
        if (res.code == 0) {
          if (res.data) {
            let arrOne = [];
            let arrTwo = [];
            let arrThree = [];
            this.seriesData.forEach((item) => {
              switch (item.name) {
                case "未开工":
                  arrOne.push(
                    res.data.notStartCommonCount,
                    res.data.notStartBiggerCount
                  );
                  item.data = arrOne;
                  break;
                case "施工中":
                  arrTwo.push(res.data.runCommonCount, res.data.runBiggerCount);
                  item.data = arrTwo;
                  break;
                case "已完工":
                  arrThree.push(
                    res.data.finishCommonCount,
                    res.data.finishBiggerCount
                  );
                  item.data = arrThree;

                  break;
              }
            });
            this.RefererShow();
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-container {
  .dangerousBig {
    width: 100%;
    height: 100%;
  }
  .scroll-wrapper {
    height: 100%;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}
</style>
