import {
  httpInit
} from "./http"

const http = httpInit("/gzdt-ucenter")
const http1 = httpInit("/gzdt-org")

export const login = (params) => http.post(`/api/login`, params)

//分页查询账户列表
export const getAccounts = (params) =>
  http.get(
    `/account/list/${params.companyId}/${params.deptId}/${params.roleId}/${params.username}/${params.state}/${params.pageNo}/${params.pageSize}`
  )
//分页查询账户列表, 限制部门
export const getUser = (params) =>
  http.get(
    `/account/list/deptId/${params.deptId}/${params.pageNo}/${params.pageSize}`
  )
//后台注册新账户接口，由各级管理员注册新管理账户
export const addAccounts = (params) => http.post(`/account/info`, params)

export const createAccountByUser = (params) => http.post(`/account/createAccountByUser`, params)

//查询账户详情，包括角色、所属公司和部门
export const getAccountInfo = (id) => http.get(`/account/info/${id}`)

//删除账号
export const deleteAccount = (id) => http.delete(`/account/info/${id}`)

//修改账户信息，这里仅能变更角色、所属公司、所属部门
export const modifyAccount = (params) =>
  http.put(`/account/info/${params.id}`, params)

//重置密码
export const resetPwd = (params) =>
  http.put(`/account/reset_pwd/${params.id}`, params)

//锁定账号
export const lockUser = (params) =>
  http.put(`/account/lock/${params.id}`, params)

//解锁账号
export const unlockUser = (params) =>
  http.put(`/account/unlock/${params.id}`, params)
// 获取所有系统用户
export const systemuser = (params) => http.get(`/api/systemuser`, params)

//查询全部单位
export const getComs = (params) => http1.get(`/api/coms`, params)

//查询当前用户权限下的单位
export const getQxComs = (params) => http1.get(`/api/my_qx_coms`, params)

//查询指定单位下所有部门
export const getDepts = (id) => http1.get(`/api/depts/${id}`)

export const pageOrganization = (params) =>
  http1.get(
    `/org/page/${params.name}/${params.category}/${params.pageNo}/${params.pageSize}`
  )

// 用户修改密码
export const modifyPwd = (params) => http.put(`/api/modify_pwd`, params)

// jwttoken 登录
export const jwtTokenLogin = () => http.get("/api/jwt_login", {})
// 根据单位id，查询单位下所有人
export const allPeople = params => http.get(`/api/getCompanyUser/${params.companyId}`)



//根据condition条件查询账户列表
export const queryAccountList = data => http.post(`/api/accountList`, data)

// 根据uuid列表查询账户详情列表
export const accountList = data => http.post(`/account/list/info/uuid`, data)


// 根据单位id，查询单位下所有人
export const account = params => http.get(`/account/info/uuid/${params.uuid}`)

//根据uuid列表查询账户详情列表
// orgType	单位部门类型：1为施工、2为监理、3为建管、4为质安部、5为其他
export const orgTypeList = data => http.post(`/account/list/info/site/org_type`, data)

// 下载
export const userDownload = (data) => http({ url: `/account/download`, data, method: "post", responseType: "blob", headers: { 'Content-Type': 'application/json;charset=utf8' } })

// 用户导入
// export const importData = (data) => http({ url: `/account/importData`, data, method: "post", responseType: "blob", headers: { 'Content-Type': 'application/json;charset=utf8' } })

// 导入
export const importData = data => http.post(`/account/importData`, data);
