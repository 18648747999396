<template>
  <div class="Application">
    <el-dialog
      title="调配申请"
      center
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>
        <el-form ref="form" :model="form" label-width="100px">
           <el-form-item label="调配工点">
            <el-input v-model="form.siteName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="所需物资">
            <el-input v-model="maerty" :disabled="true" type="textarea"></el-input>
          </el-form-item>

          <el-form-item label="申请原因">
            <el-input v-model="form.applyReason" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="申请人">
            <el-input v-model="form.applyName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="申请时间">
            <el-input v-model="form.applyDate" :disabled="true"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button style="text-align: center" type="primary" @click="fillert"
          >调配物资</el-button
        ><el-button style="text-align: center" type="primary" @click="rejected"
          >驳回</el-button
        ></span
      >
    </el-dialog>
    <el-dialog
      title="物资调配"
      center
      :visible.sync="cationVisible"
      width="50%"
      :before-close="catuionClonse"
    >
     资源范围:
      <el-input
        v-model="libraryForm.modelNo"
        placeholder="输入关键字查询"
        style="width: 17%; border: 1px solid #143363"
        class="partime"
      ></el-input>
      <el-button
        type="primary"
        @click="onSubmit"
        style="margin-left: 10px; background: #43a5e9"
        >查询</el-button
      >
       <el-form ref="form"  style="margin-top:20px">
        <el-form-item label="所需物资:" class="srtt">
          
         <el-input v-model="maerty" :disabled="true"  style="width: 30%; border: 1px solid #143363;" type="textarea"></el-input>
        </el-form-item>
       </el-form>
      <div class="tag-group">
        <el-tag
          v-for="tag in tarts"
          :key="tag.id"
          closable
          style="margin-left: 5px"
          @close="hases(tag)"
        >
          {{ tag.libName }}-{{ tag.label }}*{{ tag.num }}
        </el-tag>
      </div>
      <el-table
        style="margin-top: 20px"
        ref="suppliesTable"
        :data="gridDatas"
        border
        height="300"
        @selection-change="handlenChange"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true">
        </el-table-column>
        <el-table-column
          property="libName"
          label="物资库/救援队名称"
        ></el-table-column>
        <el-table-column
          property="label"
          label="物资设备名称"
        ></el-table-column>
        <el-table-column property="model" label="规格/型号"></el-table-column>
        <el-table-column property="unit" label="计量单位"></el-table-column>
        <el-table-column property="count" label="库存数量"></el-table-column>

        <el-table-column property="chargeName" label="负责人"></el-table-column>
        <el-table-column property="mobile" label="联系方式"></el-table-column>
        <el-table-column label="调配数量" property="num" width="180">
          <template slot-scope="scope">
            <el-input-number
            type="number"
            :precision="0"
              v-model="scope.row.num"
              :min="0"
              :max="scope.row.count"
              label="描述文字"
              size="mini"
              @change="handleChange(scope.row)"
            ></el-input-number>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="allocate">确认调配</el-button>
        <el-button type="primary" @click="reset">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      center
      :visible.sync="rejectVisible"
      width="30%"
      :before-close="rejectClonse"
    >
      <el-form ref="ruleForm" label-width="100px" :rules="rules" :model="ruleForm">
        <el-form-item label="驳回原因" prop="reason">
          <el-input v-model="ruleForm.reason" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button style="text-align: center" type="primary" @click="confirm('ruleForm')"
          >确认</el-button
        ><el-button style="text-align: center" type="primary" @click="cancel"
          >取消</el-button
        ></span
      >
    </el-dialog>
  </div>
</template>

<script>
import {
  getimmediate,
  getployialsNew,
  geteployment,
  getapplication,
} from "@/api/eres.js";
export default {
  data() {
    return {
      dialogVisible: false,
      form: {},
      maerty: "",
      cationVisible: false, //调配物资弹框
      libraryForm: {
        modelNo: "50",
      },
      tarts: [],
      gridDatas: [], //物资设备列表
      resuetid: 0,
      ids: 0,
      num: 1,
      itery: [],
      reason: "",
      rejectVisible: false,
      ruleForm:{},
      rules:{
        reason:[
          { required: true, message: '请输入驳回原因', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    async report(record) {
      console.log(record.eventId);
      this.resuetid = record.eventId;
      this.ids = record.id;
      const data = await getimmediate({ eventId: record.eventId });
      if (data.code == 0) {
        this.form = data.data;
        let maeft=[]
        data.data.materialListDtoList.forEach((item) => {
            maeft.push(item.materialName + "*" + item.num);
          
          this.maerty=maeft.toString()
            console.log(this.maerty)
        });

        this.dialogVisible = true;
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.form = {};
      this.maerty = [];
    },
    //调配物资
    async fillert() {
      const data = await getployialsNew({
        eventId: this.resuetid,
        scope: this.libraryForm.modelNo,
      });
      if (data.code == 0) {
        data.data.forEach((el) => {
          el.num = 0;
        });
        this.gridDatas = data.data;
        console.log(data);
        this.cationVisible = true;
      }
    },
    //驳回
    rejected() {
        this.reason=""
      this.rejectVisible = true;
    },
    //驳回确认
    confirm(formName) {
       this.$refs[formName].validate((valid) => { 

        if(valid){
                getapplication({
        eventId: this.resuetid,
        id: this.ids,
        reason: this.ruleForm.reason,
      }).then((res) => {
        console.log(res);
        if(res.code==0){
             this.$message({
            message: "驳回成功",
            type: "success",
          });
            this.rejectVisible = false;
             this.dialogVisible = false
              this.$emit('close')
        }
      });
        }else{
           return false;
        }
       })

    },
    //驳回取消
    cancel() {
      this.rejectVisible = false;
      this.reason = "";
    },
    //驳回关闭
    rejectClonse() {
      this.rejectVisible = false;
      this.reason = "";
    },
    //关闭调配物资
    catuionClonse(val) {
      this.libraryForm.modelNo="50"
      this.cationVisible = false;
      this.tarts = [];
      this.$refs.suppliesTable.toggleRowSelection(val, false);
    },

    //查询
   async onSubmit() {
       const data = await getployialsNew({
        eventId: this.resuetid,
        scope: this.libraryForm.modelNo,
      });
      if (data.code == 0) {
        data.data.forEach((el) => {
          el.num = 0;
        });
        this.gridDatas = data.data;
        console.log(data);
        
      }
    },
    hases(val) {
      this.tarts.splice(this.tarts.indexOf(val), 1);
      this.$refs.suppliesTable.toggleRowSelection(val, false);
    },
    handleChange(val) {
    
      if (val.num != 0) {
        this.gridDatas.forEach((item) => {
          if (item.num == val.num) {
            this.itery.push(item);
            this.$refs.suppliesTable.toggleRowSelection(item, true);
          }
        });
      } else {
        this.tarts.splice(
          this.tarts.findIndex((item) => item.labelId == val.labelId),
          1
        );

        this.gridDatas.forEach((item) => {
          if (item.labelId == val.labelId) {
            this.$refs.suppliesTable.toggleRowSelection(item, false);
          }
        });
      }
      console.log( this.tarts)
    },
    //勾选物资设备
    handlenChange(val) {
      console.log(val);
      var arr = val.map((item) => {
        return item.id;
      });
      this.gridDatas.forEach((item) => {
        if (!arr.includes(item.id)) {
          item.num = 0;
        }
      });

      this.tarts = val;
      console.log(val);
    },
    //确认调配
    allocate() {
        if(this.$refs.suppliesTable.selection.length==0){
               this.$message({
          message: '请勾选物资',
          type: 'warning'
        });
        }else{
             geteployment({
        eventId: this.resuetid,
        id: this.ids,
        materialApplyListDtoList: this.$refs.suppliesTable.selection,
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "调配成功",
            type: "success",
          });
          this.cationVisible = false;
          this.dialogVisible = false;
           this.$emit('close')
        }
      });
        }
     
    },
    //取消
    reset(val) {
      this.cationVisible = false;
      this.tarts = [];
      this.$refs.suppliesTable.toggleRowSelection(val, false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input {
  border: none !important;

}
::v-deep .el-table th > .cell {
  color: #313030 !important;
}

::v-deep .el-input-number--mini .el-input-number__decrease {
  background: green;
  color: #ffffff;
}
::v-deep .el-input-number--mini .el-input-number__increase {
  background: green;
  color: #ffffff;
}
.tag-group {
  margin-top: 20px;
  height: 10%;
}
::v-deep .el-textarea{
border: none !important;
}
.srtt {
    .el-form-item__label{
    margin-top: 18px;
}
}
</style>