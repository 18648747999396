<template>
  <div class="digi_uploader">
    <!-- <el-upload
      ref="upload"
      :http-request="uploads"
      class="upload-demo"
      action=""
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-change="handleChange"
      :file-list="fileLists"
    >
      <i class="el-icon-plus"></i>
    </el-upload> -->
    <!-- <el-dialog :visible.sync="dialogVble">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog> -->
    <el-upload
      ref="upload"
      :http-request="uploads"
      class="upload-demo"
      action=""
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-change="handleChange"
      :before-upload="beforeAvatarUpload"
      :limit="10"
      :disabled="publicdisabled"
      :accept="accept"
      :file-list="fileLists"
      multiple
    >
<!--      -->
      <div class="tape">
        <i class="el-icon-plus"></i>
      </div>
      <!-- <el-button size="small" type="primary" icon="el-icon-plus"
        >点击上传</el-button
      > -->
    </el-upload>
    <!-- <el-dialog
      title="正在进行上传"
      :visible.sync="isFinally"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <el-progress
        :text-inside="true"
        :stroke-width="20"
        :percentage="percent"
      ></el-progress>
      <p>正在上传...</p>
    </el-dialog> -->
  </div>
</template>

<script>
import {uploadBook} from "@/api/user";
export default {
  name: "DigiUploader",
  props : {
    accept : ""
  },
  data() {
    return {
      fileList: [],
      fileLists: [],
      dialogVble: false,
      dialogImageUrl: "",
      publicdisabled: false,
      isFinally: false,
      percent: 0,
    };
  },
  methods: {
    async uploads(val) {
       let arer={}
        arer.originalName=val.file.name
      console.log(val);
      // this.isFinally = true;
      this.percent = 0;
      let formData = new FormData();
      formData.append("folder", '/certificate/');
      formData.append("upfile", val.file);
      let res = await uploadBook(formData, (e) => {
        console.log(e);
        this.percent = ((e.loaded / e.total) * 100) | 0;
        return val.onProgress({ percent: this.percent });
        // return this.percent;
      }).catch((err) => {
        res.message = err;
        console.error(err);
      });
      // await upload(val.file).then((res) => {
      // console.log(res);
      // this.percent = ((res.loaded / res.total) * 100) | 0;
      // return this.percent;
      if (res.code == 200) {

        arer.uri=res.data.uri
        arer.fileRealUrl = res.data.fileRealUrl;
        arer.fileUrl = res.data.fileUrl;
        // this.isFinally = false;
        console.log(arer)
        this.$emit("uploader", arer);
      }
      // });
    },
    handleRemove(file, fileList) {
      console.log(file,fileList)
      this.fileLists = fileList;
      this.$emit("uploader", this.fileLists);
      // this.$emit("deleteFile", file,fileList);
    },
    beforeAvatarUpload(file) {
      console.log(file);
    },
    handlePreview(file) {
      console.log(file);
      upload(file.raw).then((res) => {
        console.log(res);
        if (res.code == 0) {
          window.open(res.data.uri);
        }
      });
      console.log(1112323);
    },
    handleChange(file, fileList) {
      console.log(file, fileList);
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      let extension1 = testmsg === "jpg";
      let extension2 = testmsg === "jpeg";
      let extension3 = testmsg === "png";
      let extension4 = testmsg === "doc";
      let extension5 = testmsg === "docx";
      let extension6 = testmsg === "xls";
      let extension7 = testmsg === "xlsx";
      let extension8 = testmsg === "pdf";
      if (
        !extension1 &&
        !extension2 &&
        !extension3 &&
        !extension4 &&
        !extension5 &&
        !extension6 &&
        !extension7 &&
        !extension8
      ) {
        this.$message.error(
          "上传附件只能是doc/docx/xls/xlsx/pdf/jpg/png/jpeg 格式!"
        );
        let filePash;

        if (file.response && file.response.attachId) {
          filePash = file.response.attachId;
        } else {
          filePash = file.attachmentId;
        }
        let y = this.fileList.findIndx((x) => x.attachmentId === filePash);
        if (y === -1) {
          return;
        }
        this.fileList.splice(y, 1);
        this.fileLists = fileList;
        return false;
      }
      let isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("上传大小不能超过 10MB!");
        let filePash;
        if (file.response && file.response.attachId) {
          filePash = file.response.attachId;
        } else {
          filePash = file.attachmentId;
        }
        let y = this.fileList.findIndx((x) => x.attachmentId === filePash);
        if (y === -1) {
          return;
        }
        this.fileList.splice(y, 1);
        this.fileLists = fileList;
        return false;
      }
      this.fileLists = fileList;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload-dragger {
  width: 182px;
  height: 159px;
  margin-left: 40px;
}

::v-deep .el-upload--picture-card {
  background: #081742;
  border-color: #143363;
}
.tape {
  width: 100px;
  height: 100px;
  border: 1px dashed #143363;
  .el-icon-plus {
    width: 15px;
    height: 15px;
    line-height: 100px;
  }
}
</style>
