<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
             label-width="120px" class="demo-model">

      <el-form-item label="考核单位" prop="unitType">
        <el-select v-model="ruleForm.unitType" placeholder="考核单位"
                   style="width: 100%">
          <el-option
              v-for="item in examCompany"
              :key="item.value"
              :label="item.label"
              :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="隐患考核项目" prop="checkItem">
        <el-select v-model="ruleForm.checkItem" placeholder="隐患考核项目"
                   style="width: 100%">
          <el-option
              v-for="item in examProject"
              :key="item.value"
              :label="item.label"
              :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="隐患等级" prop="screeningType">
        <el-select v-model="ruleForm.hiddenLevel" placeholder="隐患等级"
                   style="width: 100%">
          <el-option
              v-for="item in dangerLevel"
              :key="item.value"
              :label="item.label"
              :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="扣分标准" prop="deductMarks">
        <el-input v-model="ruleForm.deductMarks" placeholder="扣分标准"></el-input>
      </el-form-item>

    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelModal">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </div>
</template>

<script>
import {getDictData} from "@/api/dict";
import {criteriaAdd, criteriaDetail, criteriaEdit} from "@/api/hdanger";

export default {
  name: "criteriaModal",
  props : {
    operation : {
      default : 'add'
    },
    editData : {
      default : () => {}
    }
  },
  data(){
    return {
      ruleForm : {
        checkItem : '',
        unitType : '',
        hiddenLevel : '',
        deductMarks : '',
        createBy : '',
      },
      rules : {
        unitType: [
          { required: true, message: '考核单位不能为空', trigger: 'change' }
        ],
        checkItem: [
          { required: true, message: '考核项目不能为空', trigger: 'change' }
        ],
        deductMarks: [
          { required: true, message: '扣分标准不能为空', trigger: 'change' }
        ],
      },
      examCompany : [],
      examProject : [],
      dangerLevel : [],
    }
  },
  created() {
    this.getSelectList();
    if(this.operation === 'edit'){
      this.getDetailData();
    }
  },
  methods : {
    reset(){
      this.ruleForm = {
        checkItem : '',
        unitType : '',
        hiddenLevel : '',
        deductMarks : '',
        createBy : '',
      }
    },
    getDetailData(){
      criteriaDetail(this.editData.id).then(res => {
        if(res.code == 0){
          this.ruleForm = res.data;
        }
      })
    },
    getSelectList(){
      let dictArray = ['unitType','check_item','hdLevel'];
      dictArray.forEach(item => {
        this.getDictList(item);
      })
    },
    getDictList(label){
      getDictData(label).then(res => {
        switch (label){
          case 'unitType' :
            this.examCompany = res.data;
            break;
          case 'check_item' :
            this.examProject = res.data;
            break;
          case 'hdLevel' :
            this.dangerLevel = res.data;
            break;
        }
      })
    },
    cancelModal(){
      this.$emit('cancelModal')
    },
    submit(){
      this.$refs['ruleForm'].validate((valid) => {
        if(valid){
          this.ruleForm.createBy = JSON.parse(localStorage.getItem('user')).realName;
          if(this.operation === 'add'){
            criteriaAdd(this.ruleForm).then(res => {
              if(res.code == 0){
                this.$message.success('操作成功');
                this.$emit('successModal');
              }else{
                this.$message.error(res.msg);
              }
            })
          }else{
            criteriaEdit(this.ruleForm).then(res => {
              if(res.code == 0){
                this.$message.success('操作成功');
                this.$emit('successModal');
              }else{
                this.$message.error(res.msg);
              }
            })
          }
        }else{
          this.$message.error('请正确填写');
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
