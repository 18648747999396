<template>
  <div class="emergency-container">
    <index-box :title="title">
      <div
        class="emergency-wrapper h-full flex flex-col justify-between"
        v-if="resData.currentLevelName != ''"
      >
        <div class="flex justify-between items-center">
          <div class="title-date">
            <div class="title flex items-center">
              <img src="../../../../assets/map/hupo.png" alt="" />
              <h3 class="2xl:text-xl xl:text-sm md:text-xs">
                {{ resData.responseType
                }}{{ resData.currentLevelName }}级应急响应启动
              </h3>
            </div>
            <p class="date text-center">时间： {{ resData.frontTime }}</p>
          </div>
          <div class="detail-btn">
            <el-button type="primary" @click="goData">详情</el-button>
          </div>
        </div>
        <div class="middle p-2 flex-auto">
          <p class="title">应急响应最新变动</p>
          <p>变动时间：{{ resData.afterTime ? resData.afterTime : "无" }}</p>
          <p v-if="resData.responseLevelFront != ''">
            变动情况：{{ resData.responseLevelFront }} →
            {{ resData.responseLevelAfter }}
          </p>
          <p v-else>
            变动情况：无
          </p>
        </div>
        <!-- <div class="footer flex-auto flex justify-between text-center mt-3">
          <div class="footer-item flex-1 flex flex-col justify-around">
            <p class="footer-num font-bold">{{ responseTotal.alReadyPublish }}</p>
            <p
              class="
                footer-title
                text-opacity-40 text-white
                2xl:text-base
                xl:text-sm
                md:text-xs
              "
            >
              已推送
            </p>
          </div>
          <div class="footer-item flex-1 flex flex-col justify-around">
            <p class="footer-num font-bold">{{ responseTotal.alReadyReceive }}</p>
            <p
              class="
                footer-title
                text-opacity-40 text-white
                2xl:text-base
                xl:text-sm
                md:text-xs
              "
            >
              已接收
            </p>
          </div>
          <div class="footer-item flex-1 flex flex-col justify-around">
            <p class="footer-num font-bold" style="color: #e23c39">{{ responseTotal.unReceive }}</p>
            <p
              class="
                footer-title
                text-opacity-40 text-white
                2xl:text-base
                xl:text-sm
                md:text-xs
              "
            >
              未接收
            </p>
          </div>
        </div> -->
      </div>
      <div v-else class="text_current">当前暂无响应数据</div>
    </index-box>
    <Modal ref="modalDetail" :modal="false" width="800" :title="modelTitle">
      <ul class="response-list overflow-y-scroll">
        <li v-for="item of detailData" :key="item.id">
          <p class="response-date">{{ item.time }}</p>
          <article class="response-content" v-html="item.message"></article>
        </li>
      </ul>
    </Modal>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import {
  responseLogNew,
  getResponseTotal,
  getList,
  queryCurrentResponse,
} from "@/api/response";
import Modal from "@/components/Modal/modal.vue";
import { EventBus } from "@/utils/bus.js";

export default {
  name: "emergency-container",
  components: {
    IndexBox,
    Modal,
  },
  data() {
    return {
      title: "当前应急响应",
      responseId: "",
      resData: {
        responseType: "",
        responseLevelFront: "",
        responseLevelAfter: "",
        frontTime: "",
        afterTime: "",
      },
      detailData: [],
      responseTotal: {
        alReadyPublish: 0,
        alReadyReceive: 0,
        unReceive: 0,
      },
    };
  },
  computed: {
    modelTitle() {
      return (
        this.resData.responseType +
        this.resData.responseLevelFront +
        "级应急响应启动"
      );
    },
  },
  mounted() {
    // this.getResponseLog();
    this.getResponseTotal();
    this.queryCurrentResponse();
  },
  methods: {
    // 获取响应日志
    async getResponseLog() {
      let res = await responseLogNew();
      if (res.code == 0) {
        const tempObj = {
          responseType: "",
          responseLevelFront: "",
          responseLevelAfter: "",
          frontTime: "",
          afterTime: "",
        };
        const data = res.data.length > 0 ? res.data[0] : tempObj;
        this.resData = data;
        this.responseId = res.data[0].id;
        if (res.data.length) {
          this.getResponseList(this.responseId);
        }
      } else {
        this.$message.error(res.message);
      }
    },
    async queryCurrentResponse() {
      let res = await queryCurrentResponse();
      if (res.code == 0) {
        const tempObj = {
          responseType: "",
          currentLevelName: "",
          responseLevelFront: "",
          responseLevelAfter: "",
          frontTime: "",
          afterTime: "",
        };
        if (res.data.length) {
          const data = res.data[0];
          tempObj.responseType = data.typeName;
          tempObj.currentLevelName = data.currentLevelName
            ? data.currentLevelName
            : "";
          tempObj.frontTime = data.startTime;
          if (data.operatingHistorieList.length) {
            tempObj.responseLevelAfter =
              data.operatingHistorieList[0].relegationLevelName + "级";
            tempObj.afterTime = data.operatingHistorieList[0].relegationTime;
            if (data.operatingHistorieList.length == 1) {
              tempObj.responseLevelFront = "无";
            } else {
              tempObj.responseLevelFront =
                data.operatingHistorieList[1].relegationLevelName + "级";
            }
          }
          this.resData = tempObj;
          console.log(this.resData, tempObj);
        }
      }
    },
    // 查询响应列表
    getResponseList(data) {
      const params = {
        id: data,
      };
      getList(params).then((res) => {
        if (res.code == 0) {
          this.detailData = res.data;
        }
      });
    },
    //详情
    goData() {
      // console.log(record);
      // // this.$message("数据对接中，敬请期待。");
      // this.$refs.modalDetail.handleOpen();
      this.$router.push({ path: "/ThreeProofings/Response/CurrentResponse" });
      EventBus.$emit("changeTheme");
    },
    // 获取响应接收统计
    async getResponseTotal() {
      let res = await getResponseTotal();
      if (res.code == 0) {
        this.responseTotal = {
          alReadyPublish: res.data.alReadyPublish ? res.data.alReadyPublish : 0,
          alReadyReceive: res.data.alReadyReceive ? res.data.alReadyReceive : 0,
          unReceive: res.data.unReceive ? res.data.unReceive : 0,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.emergency-container {
  .title-date {
    .title {
      img {
        width: 18px;
        height: 16px;
        margin-right: 10px;
      }
      h3 {
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #f0af41;
      }
    }
    .date {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
    }
  }
  .middle {
    background: rgba(67, 165, 233, 0.1);
    .title {
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #43a5e9;
    }
    p {
      font-size: 14px;
      color: #fff;
      line-height: 24px;
    }
  }
  .footer {
    .footer-item {
      position: relative;
      &::after {
        display: block;
        content: "";
        width: 1px;
        height: 100%;
        background: rgba(255, 255, 255, 0.1);
        position: absolute;
        right: 0;
        top: 0;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    .footer-num {
      color: #fff;
    }
  }
}
.response-list {
  max-height: 480px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  li {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .response-date {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 28px;
  }
  .response-content {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.6;
  }
}
.text_current {
  color: #fff;
}
</style>
