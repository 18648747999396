<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="left_tree">
      <div class="ppert">选择工点</div>
      <el-tree
        :data="siteList"
        :props="defaultProps"
        @node-click="handleNodeClick"
        :highlight-current="true"
      >
        <span class="custom-tree-node" slot-scope="{ node }">
          <span :title="node.label">
            {{ node.label }}
          </span>
        </span>
      </el-tree>
    </div>
    <div class="right_table ml-4 flex-1">
      <div style="display: flex; justify-content: space-around">
        <el-tabs
          v-model="activeName"
          type="card"
          @tab-click="handleClick"
          style="
            margin-top: 20px;
            margin-left: 20px;
            border-bottom: none;
            width: 85%;
          "
        >
          <el-tab-pane
            :label="item.label"
            :name="item.code"
            v-for="(item, index) in dierty"
            :key="index"
          >
            <el-table
              ref="multiple"
              :data="tableList"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column label="序号" type="index" width="80">
              </el-table-column>
              <el-table-column label="摄像头名称" prop="videoName">
              </el-table-column>
              <el-table-column label="类型" prop="type"> </el-table-column>
              <el-table-column label="状态" prop="status"> </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.row)"
                    >解绑</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
         <div class="pagination-wrapper flex justify-center mt-4">
  
    </div>
        <div style="margin-top: 10px">
          <el-button type="primary" @click="editor">编辑</el-button>
        </div>
      </div>
          <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
      <el-dialog
        title="编辑"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose"
        center
      >
        <el-table
          :show-header="false"
          height="500"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="videoName" show-overflow-tooltip>
          </el-table-column>
          <el-table-column type="selection" width="55"> </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="determine">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getDictData } from "@/api/dict.js";
import {
  cameraList,
  bindiedtrList,
  bindingList,
  delebinding,
} from "@/api/map.js";
export default {
  data() {
    return {
      siteList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      activeName: "",
      dierty: [],
     
      usdeconde: "",
      siteData: [],
      dialogVisible: false,
      tableData: [],
      videoBindingDtos: [],
      pwert: [],
      tableList: [],
      arrts: [],
       page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
    };
  },
  created() {
    const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
    this.siteList = lineSiteData;
    getDictData("videoUseType").then((res) => {
      console.log(res);
      this.dierty = res.data;
      this.activeName = res.data[0].code;
    });
  },
  methods: {
    // 站点树的点击事件
    handleNodeClick(data, node) {
      console.log(data, node);
      this.activeName = this.dierty[0].code;
      if (node.childNodes.length == 0) {
        this.cameetr(data.uuid, this.dierty[0].code);

        this.siteData = data;
      }
    },
    cameetr(val, data) {
      cameraList({
        page: this.page.pageNo,
        size: this.page.pageSize,
        siteId: val,
        useCode: data,
      }).then((res) => {
        console.log(res);
        this.tableList = res.data.records;
         this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.page.total = res.data.total;
        this.page.count = res.data.count;
      });
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
       if (this.activeName == this.dierty[0].code) {
            this.cameetr(this.siteData.uuid, this.dierty[0].code);
            this.activeName = this.dierty[0].code;
          } else {
            this.cameetr(this.siteData.uuid, this.usdeconde);
            this.activeName = this.dierty[0].code;
          }
     
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
     if (this.activeName == this.dierty[0].code) {
            this.cameetr(this.siteData.uuid, this.dierty[0].code);
            this.activeName = this.dierty[0].code;
          } else {
            this.cameetr(this.siteData.uuid, this.usdeconde);
            this.activeName = this.dierty[0].code;
          }
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.usdeconde = tab.name;
      this.cameetr(this.siteData.uuid, tab.name);
    },
    //编辑
    editor() {
      if (this.activeName == this.dierty[0].code) {
        bindiedtrList({
          siteId: this.siteData.uuid,
          useCode: this.dierty[0].code,
        }).then((res) => {
          this.dialogVisible = true;
          console.log(res);
          res.data.forEach((item) => {
            if (item.optionStatue == 1) {
              this.$nextTick(() => {
                this.$refs.multipleTable.toggleRowSelection(item, true);
              });
            }
          });
          this.tableData = res.data;
          this.arrts = res.data;
        });
      } else {
        bindiedtrList({
          siteId: this.siteData.uuid,
          useCode: this.usdeconde,
        }).then((res) => {
          console.log(res);
          this.dialogVisible = true;
          this.tableData = res.data;
          this.tableData.forEach((item) => {
            if (item.optionStatue == 1) {
              this.$nextTick(() => {
                this.$refs.multipleTable.toggleRowSelection(item, true);
              });
            }
          });
        });
      }
    },
    determine() {
      let pwet = [];
      this.videoBindingDtos = [];
      this.arrts.forEach((it) => {
        if (it.optionStatue == 1) {
          pwet.push(it);
        }
      });
      this.pwert.forEach((el) => {
        if (this.activeName == this.dierty[0].code) {
          let psety = {
            optionStatue: 1,
            siteId: this.siteData.uuid,
            useCode: this.dierty[0].code,
            videoId: el.videoId,
            useId: el.useId,
           
          };
          this.videoBindingDtos.push(psety);
         
        } else {
          let psety = {
            optionStatue: 1,
            siteId: this.siteData.uuid,
            useCode: this.usdeconde,
            videoId: el.videoId,
            useId: el.useId,
          };
          this.videoBindingDtos.push(psety);
        
        }
      });
    
       const arr2 = [];
      this.videoBindingDtos.forEach((item) => {
        arr2.push(item.videoId);
      });
    

      pwet.forEach((item) => {
        if (!arr2.includes(item.videoId)) {
          if (this.activeName == this.dierty[0].code) {
             this.videoBindingDtos.push({
                  optionStatue: 0,
                  siteId: this.siteData.uuid,
                  useCode: this.dierty[0].code,
                  videoId: item.videoId,
                  useId: item.useId,
                });
          }else{
             this.videoBindingDtos.push({
                  optionStatue: 0,
                  siteId: this.siteData.uuid,
                    useCode: this.usdeconde,
                  videoId: item.videoId,
                  useId: item.useId,
                });
          }
        
        }
      });
      bindingList(this.videoBindingDtos).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "编辑成功",
            type: "success",
          });
          if (this.activeName == this.dierty[0].code) {
            this.cameetr(this.siteData.uuid, this.dierty[0].code);
            this.activeName = this.dierty[0].code;
          } else {
            this.cameetr(this.siteData.uuid, this.usdeconde);
            this.activeName = this.dierty[0].code;
          }
          this.dialogVisible = false;
        }
      });
    },
    cancel() {},
    handleClose() {
      this.dialogVisible = false;
    },
    handleSelectionChange(val) {
      console.log(val);
      this.pwert = val;
    },
    //解绑
    handleDelete(val) {
      this.$confirm("此操作将永久解绑该摄像头, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delebinding({
            useId: val.useId,
          }).then((res) => {
            console.log(res);
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "解绑成功!",
              });
              if (this.activeName == this.dierty[0].code) {
                this.cameetr(this.siteData.uuid, this.dierty[0].code);
                this.activeName = this.dierty[0].code;
              } else {
                this.cameetr(this.siteData.uuid, this.usdeconde);
                this.activeName = this.dierty[0].code;
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      console.log(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  justify-content: space-between;
  .left_tree {
    width: 20%;
    height: 100%;
    overflow-x: hidden;

    overflow-y: auto;
    border: 1px solid #e6e7ec;
    .ppert {
      font-size: 14px;
      margin-top: 10px;
      margin-left: 10px;
    }
    .el-tree {
      // width: 80%;
      height: 90%;
      margin: 20px;
      // border: 1px solid #b3b0b0;
      // box-shadow: 0px 0px 10px #b3b0b0;
    }
  }
  .left_tree ::-webkit-scrollbar {
    display: none;
  }
  .right_table {
    width: 83%;
    height: 100%;
    border: 1px solid #e6e7ec;
  }
}
.el-pagination{
  text-align: center;
}
</style>