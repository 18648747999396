<template>
  <systemModal
    ref="formModal"
    :title="title"
    :showFooter="false"
    width="40%"
    @modalClose="handleClose"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-model"
    >
      <el-form-item label="所属建管部" prop="superviseName" v-if="type == 1025">
        <el-input
          v-model="ruleForm.superviseName"
          placeholder="请输入所属建管部"
          readonly
          v-if="type == 1025"
        ></el-input>
      </el-form-item>
      <el-form-item label="线路名称" prop="lineName" v-if="type == 1025">
        <el-input
          v-model="ruleForm.lineName"
          placeholder="请输入线路名称"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="分部名称" prop="branchName" v-if="type == 1025">
        <el-input
          v-model="ruleForm.branchName"
          placeholder="请输入分部名称"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="工点名称" prop="siteName" v-if="type == 1025">
        <el-input
          v-model="ruleForm.siteName"
          placeholder="请输入工点名称"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="演练内容" prop="planContent">
        <el-input
          v-model="ruleForm.planContent"
          placeholder="请输入演练内容"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="演练类型" prop="planContent" v-if="type == 1025">
        <el-input
          v-model="ruleForm.planContent"
          placeholder="请输入演练类型"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="演练科目" prop="label" v-if="type == 1025">
        <el-input
          v-model="ruleForm.planSubject"
          placeholder="请输入演练科目"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="演练级别" prop="label" v-if="type == 1025">
        <el-input
          v-model="ruleForm.drillLevel"
          placeholder="请输入演练级别"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="演练地点" prop="label" v-if="type == 1025">
        <el-input
          v-model="ruleForm.address"
          placeholder="请输入演练地点"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="拟参与人数" prop="planJoinCount" v-if="type == 1025">
        <el-input
          v-model="ruleForm.planJoinCount"
          placeholder="请输入拟参与人数"
          readonly
        ></el-input>
      </el-form-item>

      <el-form-item label="计划演练时间" prop="planDate" v-if="type == 1025">
        <el-input
          v-model="ruleForm.planDate"
          placeholder="请输入计划演练时间"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="发起人" prop="owner" v-if="type == 1025">
        <el-input
          v-model="ruleForm.owner.name"
          placeholder="请输入发起人"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="发起时间" prop="created" v-if="type == 1025">
        <el-input
          v-model="ruleForm.created"
          placeholder="请输入发起时间"
          readonly
        ></el-input>
      </el-form-item>

      <el-form-item label="附件" prop="label" v-if="type == 1025">
        <p></p>
      </el-form-item>
      <el-form-item label="实际演练时间" prop="executeTime" v-if="type == 1026">
        <el-input
          v-model="ruleForm.executeTime"
          placeholder="请输入实际演练时间"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="实际参与人数" prop="joinCount" v-if="type == 1026">
        <el-input
          v-model="ruleForm.joinCount"
          placeholder="请输入实际参与人数"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="演练方案" prop="label" v-if="type == 1026">
        <div
          class="file-item"
          v-for="(item, index) in programmeList"
          :key="index"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="演练脚本" prop="label" v-if="type == 1026">
        <div class="file-item" v-for="(item, index) in scriptList" :key="index">
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="影像资料" prop="label" v-if="type == 1026">
        <div class="file-item" v-for="(item, index) in videoList" :key="index">
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="签到表" prop="label" v-if="type == 1026">
        <div class="file-item" v-for="(item, index) in signList" :key="index">
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="总结" prop="label" v-if="type == 1026">
        <div
          class="file-item"
          v-for="(item, index) in summaryList"
          :key="index"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="评估报告" prop="label" v-if="type == 1026">
        <div
          class="file-item"
          v-for="(item, index) in assessmentList"
          :key="index"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="其他附件" prop="label" v-if="type == 1026">
        <div class="file-item" v-for="(item, index) in otherList" :key="index">
          {{ item.attrName }}
        </div>
      </el-form-item>

      <el-form-item label="审核意见" prop="label">
        <el-radio-group v-model="radio">
          <el-radio :label="3">同意</el-radio>
          <el-radio :label="6">驳回</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="原因">
        <el-input
          type="textarea"
          v-model="opinion"
          placeholder="请输入原因"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="footer w-full flex justify-center">
      <el-button type="primary" @click="onSubmit">确认</el-button>
      <el-button @click="onClose">取消</el-button>
    </div>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import {
  approvePlan,
  approveReport,
  getByLogId,
  noApprovePlan,
  noApproveReport,
} from "@/api/stand";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "",
      type: "",
      examineData: {},
      ruleForm: {},
      rules: {
        option: [{ required: true, message: "请输入原因", trigger: "blur" }],
      },
      radio: 3,
      opinion: "",
      enclosureList: [],
      programmeList: [],
      scriptList: [],
      videoList: [],
      signList: [],
      summaryList: [],
      assessmentList: [],
      otherList: [],
      loading: false,
    };
  },
  filters: {},
  mounted() {},
  methods: {
    handleOpen(record) {
      this.type = record.noticeCode;
      this.title = record.noticeCode == 1025 ? "计划审核" : "报告审核";
      this.opinion ="";
      this.getDetailData(record.eventId);
    },
    onSubmit() {
      this.submitExamine();
    },
    onClose() {
      this.ruleForm = {};
      this.$refs.formModal.handleClose();
    },
    getDetailData(id) {
      getByLogId(id).then((res) => {
        if (res.code == 0) {
          if (res.code == 0) {
            this.ruleForm = res.data;
            this.examineData = res.data;
            var arr = this.ruleForm.fileUrl.split("/");
            this.ruleForm.fileName = arr[arr.length - 1];
            this.ruleForm.approvalResult =
              this.ruleForm.approvalResult === true
                ? "通过"
                : this.ruleForm.approvalResult === false
                ? "驳回"
                : "";
            this.$refs.formModal.handleOpen();
          }
        }
      });
    },
    submitExamine() {
      if (this.radio == 6 && !this.opinion) {
        this.$message.error("驳回原因必填");
        return;
      }
      if (this.type == 1025) {
        // 计划审批
        let examineInterface = this.radio == 3 ? approvePlan : noApprovePlan;
        let paramsA = {};
        if (this.radio == 3) {
          paramsA = {
            id: this.examineData.id,
          };
        } else {
          paramsA = {
            id: this.examineData.id,
            opinion: this.opinion,
          };
        }
        examineInterface(paramsA).then((res) => {
          if (res.code == 0) {
            this.$message.success("操作成功");
            this.$emit("close");
            this.$refs.formModal.handleClose();
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        let examineInterface =
          this.radio == 3 ? approveReport : noApproveReport;
        let params = {};
        if (this.radio == 3) {
          params = {
            id: this.examineData.id,
          };
        } else {
          params = {
            id: this.examineData.id,
            opinion: this.opinion,
          };
        }
        examineInterface(params).then((res) => {
          if (res.code == 0) {
            this.$message.success("操作成功");
            this.$emit("close");
            this.$refs.formModal.handleClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 30px !important;
}

::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}

::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
</style>
