<template>
  <div class="page-container bg-white p-4 h-full">

    <div class="tabs" v-if="deptType === 'contracting' || deptType === 'build' ">
      <div class="tabs-item" :class="active === '施工单位' ?  'active' : '' " @click="changeTab('施工单位')">施工单位</div>
      <div class="tabs-item" :class="active === '专职救援队' ?  'active' : '' " @click="changeTab('专职救援队')">专职救援队</div>
    </div>

    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
<!--          <el-form-item label="">
            <el-cascader
              v-model="queryModal.lineSite"
              :options="dict.lineSiteData"
              @change="handleLineSiteChange"
            ></el-cascader>
          </el-form-item> -->
          <el-form-item label="">
            <el-input v-model="queryModal.superviseName" placeholder="建管部"></el-input>
          </el-form-item>
          <el-form-item label="" style="width: 250px"  v-if="deptType === 'construct'
            || ( (this.deptType === 'contracting' || deptType === 'build') && this.active === '施工单位' ) ">
            <el-cascader v-model="queryModal.lineSite" style="width: 250px"
                         clearable :options="treeData" :props="props" ref="cascaderTree"
                         :show-all-levels="false" collapse-tags @change="handleLineSiteChange"></el-cascader>
          </el-form-item>

          <el-form-item label="" v-if="deptType === 'fullEmergencyRescue'
          || ( (this.deptType === 'contracting' || deptType === 'build') && this.active === '专职救援队' ) ">
            <el-input v-model="queryModal.optionUnitName" placeholder="所属总承包部"></el-input>
          </el-form-item>
          <el-form-item label="" style="width: 350px" v-if="deptType === 'fullEmergencyRescue'
            || ( (this.deptType === 'contracting' || deptType === 'build') && this.active === '专职救援队' )">
            <el-select v-model="queryModal.rescueTeamIds" multiple clearable placeholder="救援队选择"
                       style="width: 350px" collapse-tags>
              <el-option
                  v-for="item in rescueTeamList"
                  :key="item.id"
                  :label="item.teamName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="queryModal.organizer" placeholder="主办单位"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="queryModal.drillTypeCode" placeholder="演练类型选择">
              <el-option
                  v-for="item in categoryList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="queryModal.planSubjectCode" placeholder="演练科目选择">
              <el-option
                  v-for="item in subjectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="queryModal.drillLevelCode" placeholder="演练级别选择">
              <el-option
                  v-for="item in levelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="">
            <el-date-picker
              v-model="planDate"
              type="daterange"
              value-format="yyyy-MM-dd"
              start-placeholder="计划培训开始日期" end-placeholder="结束日期"
              @change="planChange"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="">
            <el-date-picker
              v-model="executeTime"
              type="daterange"
              @change="exeuteChange"
              start-placeholder="实际演练开始日期" end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="queryModal.state" placeholder="请选择状态">
              <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
<!--          <el-form-item label="">
            <el-input v-model="queryModal.label" placeholder="输入需要查询的内容"></el-input>
          </el-form-item>-->
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-has="'drill:add'" @click="handleAdd">新建计划</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-has="'drill:push'" @click="planPush">推送计划</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column align="center" type="index" label="序号" width="100"  ></el-table-column>
        <el-table-column align="center" v-for="(item,index) in column" :prop="item.prop" :label="item.label"
        :key="index" :formatter="item.formatter" :width="item.width">
        </el-table-column>

        <el-table-column align="center" label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <!-- 计划审核 -->
            <el-button type="text" @click="withdraw(scope.row)" v-show="scope.row.revoke">撤回</el-button>
            <el-button type="text" @click="handleUpload(scope.row)" v-show="scope.row.submitReport">上传报告</el-button>
            <el-button type="text" @click="handleDetail(scope.row)" v-show="scope.row.view">详情</el-button>
            <el-button type="text" @click="handleEdit(scope.row)" v-show="scope.row.edit">编辑</el-button>
            <el-button type="text" @click="handleReportExamine(scope.row)" v-has="'drill:examineReport'" v-show="scope.row.examine">审核</el-button>
            <el-button type="text" @click="handleReportExamine(scope.row)" v-has="'drill:examinePlan'" v-show="scope.row.approve">审核</el-button>
            <el-button type="text" @click="handleHistory(scope.row)" v-has="'drill:history'" v-show="scope.row.history">历史操作</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination background layout="prev, pager, next"
                     :total="page.count" :page-count="page.total"
                     :page-size="page.pageSize"
                     :current-page="page.pageNo"
                     @current-change="handleCurrentChange"
                     @size-change="handleSizeChange">
      </el-pagination>
    </div>
    <drill-form ref="modalForm" :lineSiteData="dict.lineSiteData" @formSubmit="handleFormSubmit"></drill-form>
    <drill-plan-examine ref="modalExaminePlan" @examineSubmit="handleFormSubmit"></drill-plan-examine>
    <drill-report-examine ref="modalExamineReport" @examineOver="handleFormSubmit"></drill-report-examine>

    <el-dialog
        width="35%"
        :title="titleAdd"
        :visible.sync="dialogVisible"
        class="editDialog"
        append-to-body>
      <YlAddPlan v-if="dialogVisible" @successAdd="successAdd"></YlAddPlan>
    </el-dialog>

    <el-dialog
        width="35%"
        title="推送计划"
        :visible.sync="pushVisible"
        class="editDialog"
        append-to-body>
      <pushPlan v-if="pushVisible" :add-type="addType" @successPlan="successPlan"></pushPlan>
    </el-dialog>


    <el-dialog
        :width=" operation === 'edit' ? '35%' : '60%' "
        :title=" operation === 'edit' ? '编辑' : '详情' "
        :visible.sync="editVisible"
        class="editDialog"
        append-to-body>
      <YlDetail v-if="editVisible" :operation="operation" @cancelEdit="cancelEdit"
                :edit-data="editData" @successEdit="successEdit"></YlDetail>
    </el-dialog>

    <el-dialog
        width="35%"
        :title="examineTitle"
        :visible.sync="examineVisible"
        class="editDialog"
        append-to-body>
      <plan-examine v-if="examineVisible" :examine-data="examineData" @successExamine="successExamine"></plan-examine>
    </el-dialog>

    <el-dialog
        width="35%"
        title="上传报告"
        :visible.sync="uploadVisible"
        class="editDialog"
        append-to-body>
      <YlFinish v-if="uploadVisible" :operation="operation"
                :upload-data="uploadData" @successUpload="successUpload"></YlFinish>
    </el-dialog>

    <el-dialog
        width="35%"
        title="上传报告"
        :visible.sync="historyVisible"
        class="editDialog"
        append-to-body>
      <historyDrill v-if="historyVisible" :history-object="historyData"></historyDrill>
    </el-dialog>


  </div>
</template>

<script>
import {getRehearsalPageList, queryDeptDetail, withdrawalPlan, withdrawalReport} from "@/api/stand";
import {getDictData} from "@/api/dict";
import YlAddPlan from "@/views/Contingency/System/TrainingDrill/components/YlAddPlan";
import pushPlan from "@/views/Contingency/System/TrainingDrill/components/pushPlan";
import YlDetail from "@/views/Contingency/System/TrainingDrill/components/YlDetail";
import planExamine from "@/views/Contingency/System/TrainingDrill/components/planExamine";
import YlFinish from "@/views/Contingency/System/TrainingDrill/components/YlFinish";
import historyDrill from "@/views/Contingency/System/TrainingDrill/components/historyDrill";
import {getQxlist} from "@/api/eres";
export default {
  components: {
    YlAddPlan,
    pushPlan,
    YlDetail,
    planExamine,
    YlFinish,
    historyDrill
},
  data() {
    return {
      queryModal: {
        lineSite: [],
        lineId: "",
        siteId: "",
        state: "",
        drillLevelCode: "",
        drillTypeCode: "",
        planSubjectCode: "",
        type: "site",
        ePlanDate: "",
        sPlanDate: "",
        superviseName: "",
        organizer: "",
        sExecuteTime: "",
        eExecuteTime: "",
        rescueTeamIds : [],
        siteUuids : [],
        page : 1,
        size : 10,
      },
      tableData: [],
      rescueTeamList: [],
      constructionList: [],
      active : '施工单位',
      loading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      imgPreviewList: [],
      planDate : [],
      executeTime : [],
      treeData: [],
      props: {
        children: "children",
        label: "name",
        // value: "id",
        value: "uuid",
        multiple: true
      },
      dict: {
        lineSiteData: [],
        state: [
          {
            value: "0",
            label: "待审核"
          },
          {
            value: "1",
            label: "待执行/待演练"
          },
          {
            value: "2",
            label: "审批未通过"
          },
          {
            value: "3",
            label: "执行中"
          },
          {
            value: "4",
            label: "待提交报告"
          },
          {
            value: "5",
            label: "待审核报告"
          },
          {
            value: "6",
            label: "已完成"
          },
          {
            value: "7",
            label: "报告审核未通过"
          },
        ]
      },
      dialogVisible : false,
      historyVisible : false,
      pushVisible : false,
      editVisible : false,
      examineVisible : false,
      uploadVisible : false,
      titleAdd : '新增',
      examineTitle : '新增',
      column : [],
      buildList : [
        { prop : 'superviseName' , label : '所属建管部', width : '200' },
        { prop : 'lineName' , label : '线路名称',width : '200' },
        { prop : '' , label : '分部-工区名称',formatter: (row) => {
          return row.branchName + '-' + row.areaName
          },width : '200'},
        { prop : 'siteName' , label : '工点名称',width : '100' },
        { prop : 'organizer' , label : '主办单位',width : '200' },
        { prop : 'participants' , label : '参与单位',width : '100' },
        { prop : 'planContent' , label : '演练内容',width : '200' },
        { prop : 'drillType' , label : '演练类型',width : '120' },
        { prop : 'planSubject' , label : '演练科目',width : '120' },
        { prop : 'drillLevel' , label : '演练级别',width : '100' },
        { prop : 'address' , label : '演练地点',width : '150' },
        { prop : 'planJoinCount' , label : '拟参与人数',width : '120' },
        { prop : 'planDate' , label : '计划演练时间',width : '160' },
        { prop : 'state' , label : '状态',formatter : (row) => {
          return row.state == 1 ? '计划待审批' : row.state == 2 ? '计划编辑中' : row.state == 3 ? '计划未过审' :
              row.state == 4 ? '待执行演练' : row.state == 5 ? '待审核报告' : row.state == 6 ? '报告编辑中' :
                  row.state == 7 ? '报告未过审' : row.state == 8 ? '已完成' : ''
          },width : '150' },
        { prop : 'executeTime' , label : '实际演练时间',width : '160' },
      ], // 施工单位  监理权限
      rescueList : [
        { prop : 'superviseName' , label : '所属建管部' ,width : '200'},
        { prop : 'optionUnitName' , label : '所属总承包部' ,width : '200'},
        { prop : 'rescueTeamName' , label : '专职救援队名称' ,width : '200'},
        { prop : 'planContent' , label : '演练内容' ,width : '200'},
        { prop : 'drillType' , label : '演练类型' ,width : '150'},
        { prop : 'planSubject' , label : '演练科目',width : '120' },
        { prop : 'drillLevel' , label : '演练级别',width : '120' },
        { prop : 'address' , label : '演练地点' ,width : '150'},
        { prop : 'planJoinCount' , label : '拟参与人数',width : '120' },
        { prop : 'planDate' , label : '计划演练时间',width : '160' },
        { prop : 'state' , label : '状态',formatter : (row) => {
            return row.state == 1 ? '计划待审批' : row.state == 2 ? '计划编辑中' : row.state == 3 ? '计划未过审' :
                row.state == 4 ? '待执行演练' : row.state == 5 ? '待审核报告' : row.state == 6 ? '报告编辑中' :
                    row.state == 7 ? '报告未过审' : row.state == 8 ? '已完成' : ''
          },width : '150'},
        { prop : 'executeTime' , label : '实际演练时间',width : '160' },
      ],  //专职救援队

      editData : {},
      examineData : {},
      operation : 'edit',
      categoryList : [],
      subjectList : [],
      levelList : [],
      deptType : null,
      statusList : [
        {
          label : '计划待审批',
          value : 1
        },
        {
          label : '计划编辑中',
          value : 2
        },
        {
          label : '计划未过审',
          value : 3
        },
        {
          label : '待执行演练',
          value : 4
        },
        {
          label : '待审核报告',
          value : 5
        },
        {
          label : '报告编辑中',
          value : 6
        },
        {
          label : '报告未过审',
          value : 7
        },
        {
          label : '已完成',
          value : 8
        },
      ],
      addType : '',
      flatTreeData : [],
      historyData : {},
      uploadData : {}
    };
  },
  mounted() {
    this.getSelectList();
    this.getDeptDetail();
    //contracting  build
    this.getLineSiteTreeData();
  },
  methods: {
    changeTab(name){
      this.active = name;
      this.addType = name === '施工单位' ? 'construction' : 'Rescue';
      this.column =  name === '施工单位' ? this.buildList : this.rescueList;
      this.getDeptDetail();
    },
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
        this.recursionData(this.treeData[0])
        this.getTableData();
        // this.flatArr(this.treeData);
      }
    },
    recursionData(object,result=[]){
      if(object.uuid){
        result.push(object.uuid)
      }
      // this.queryModal.lineSite.push(arr);
      this.queryModal.siteUuids.push(object.uuid);
      if(object.children){
        this.recursionData(object.children[0],result)
      }else{
        this.queryModal.lineSite.push(result)
      }
    },
    //查询属于哪个角色
    getDeptDetail() {
      console.log(JSON.parse(localStorage.getItem("user")));
      var userInfo = JSON.parse(localStorage.getItem("user"));
      queryDeptDetail(userInfo.companyId).then((res) => {
        this.deptType = res.data ? res.data.category : "0";
        if(this.deptType === 'construct' || this.deptType === 'monitor'
            || (this.deptType === 'contracting' && this.active === '施工单位' ) ||
            (this.deptType === 'build' && this.active === '施工单位' )){
          this.column = this.buildList;
          this.queryModal.type = 'site';
          this.getLineSiteTreeData();
        }else{
          this.column = this.rescueList;
          this.queryModal.type = 'rescueTeam';
          this.getRescueData();
        }

      });
    },
    getRescueData(){
      getQxlist().then(res => {
        if(res.code == 0){
          this.rescueTeamList = res.data;
          if(this.rescueTeamList.length > 0){
            this.queryModal.rescueTeamIds = this.rescueTeamList.map(item => item.id)
          }else{
            this.queryModal.rescueTeamIds = [];
          }
        }
      })
    },
    getTableData(){
      this.loading = true;
      // if(params.ePlanDate)
      if(this.queryModal.ePlanDate && !this.queryModal.planDate.ePlanDate(' 00:00:00')){
        this.queryModal.ePlanDate = this.queryModal.ePlanDate + ' 00:00:00';
      }
      if(this.queryModal.sPlanDate && !this.queryModal.planDate.sPlanDate(' 00:00:00')){
        this.queryModal.sPlanDate = this.queryModal.sPlanDate + ' 00:00:00';
      }
      if(this.queryModal.eExecuteTime && !this.queryModal.planDate.eExecuteTime(' 00:00:00')){
        this.queryModal.eExecuteTime = this.queryModal.eExecuteTime + ' 00:00:00';
      }
      if(this.queryModal.sExecuteTime && !this.queryModal.planDate.sExecuteTime(' 00:00:00')){
        this.queryModal.sExecuteTime = this.queryModal.sExecuteTime + ' 00:00:00';
      }
      var params = {}
      this.queryModal.page=this.page.pageNo
      this.queryModal.size= this.page.pageSize
      if(this.active === '施工单位'){
        // this.queryModal.rescueTeamIds = null;
        params = {
          ...this.queryModal
        }
        params.rescueTeamIds = null;
      }else{
        // this.queryModal.siteUuids = null;
        params = {
          ...this.queryModal
        }
        params.siteUuids = null;
      }
      getRehearsalPageList(params).then(res => {
        console.log(res);
        this.tableData = res.data.records;
      this.page.pageNo = res.data.current;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.total;
          this.page.count = res.data.count;
        this.loading = false;
      })
    },
    planChange(time){
      if(time){
        this.queryModal.sPlanDate = time[0];
        this.queryModal.ePlanDate = time[1];
      }else{
        this.queryModal.sPlanDate = '';
        this.queryModal.ePlanDate = '';
      }
    },
    exeuteChange(time){
      if(time){
        this.queryModal.sExecuteTime = time[0];
        this.queryModal.eExecuteTime = time[1];
      }else{
        this.queryModal.sExecuteTime = '';
        this.queryModal.eExecuteTime = '';
      }
    },
    getSelectList(){
      let stringList = ['eventType','drill_type','drill_level'];
      stringList.forEach(item => {
        this.getDictList(item);
      })
    },
    handleUpload(row){
      this.uploadData = {...row}
      this.uploadVisible = true;
    },
    successUpload(){
      this.uploadVisible = false;
      this.getTableData();
    },
    getDictList(label){
      getDictData(label).then(res => {
        switch (label){
          case 'eventType' :
            this.categoryList = res.data;
            // this.queryModal.drillTypeCode = this.categoryList[0].id;
            break;
          case 'drill_type' :
            this.subjectList = res.data;
            break;
          case 'drill_level' :
            this.levelList = res.data;
            break
        }
      })
    },
    withdraw(row){
      this.$confirm('是否确定撤回？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
      }).then( () => {
        if(row.state == 1){
          withdrawalPlan(row.id).then(res => {
            if(res.code == 0){
              this.$message.success('撤回成功');
              this.getTableData();
            }else{
              this.$message.error(res.msg);
            }
          })
        }else{
          withdrawalReport(row.id).then(res => {
            if(res.code == 0){
              this.$message.success('撤回成功');
              this.getTableData();
            }else{
              this.$message.error(res.msg);
            }
          })
        }
      })
    },
    // 级联选择器事件
    handleLineSiteChange(record) {
      console.log(record)
      const siteNodes = this.$refs.cascaderTree.getCheckedNodes();
      console.log(siteNodes)
      this.queryModal.siteUuids = [];
      for (let item of record) {
        this.queryModal.siteUuids.push(item[item.length-1]);
      }
    },
    // 数组扁平化
    flatArr(data) {
      for (let item of data) {
        this.flatTreeData.push(item);
        if (item.children) {
          this.flatArr(item.children);
        }
      }
      // this.queryModal.siteId
      this.queryModal.siteIds = this.flatTreeData.map(item => item.id);
      console.log(this.flatTreeData.map(item => item.id))
    },
    handleSearch() {
      this.getTableData();
    },
    handleAdd() {
      // this.$refs.modalForm.handleAdd();
      this.dialogVisible = true;
    },
    planPush(){
      this.pushVisible = true;
    },
    successPlan(){
      this.pushVisible = false;
      this.getTableData();
    },
    successAdd(){
      this.dialogVisible = false;
      this.getTableData();
    },
    handleEdit(record) {
      // this.$refs.modalForm.handleAdd(record);
      this.editData = {...record};
      this.operation = 'edit';
      if(record.state == 2 || record.state == 3){
        this.editVisible = true;
      }else{
        this.uploadData = {...record}
        this.uploadVisible = true;
      }
    },
    successEdit(){
      console.log(111)
      this.editVisible = false;
      this.getTableData();
    },
    cancelEdit(){
      this.editVisible = false;
    },
    handleDetail(record){
      this.editData = {...record};
      this.operation = 'detail'
      this.editVisible = true;
    },
    handlePlanExamine(record) {
      this.$refs.modalExaminePlan.handlePlanExamine(record);
    },
    handleReportExamine(record) {
      this.examineTitle = record.state == 1 ? '计划审核' : '报告审核';
      this.examineData = {...record};
      this.examineVisible = true;
    },
    successExamine(){
      this.examineVisible = false;
      this.getTableData();
    },
    handleReport(record) {
      this.$refs.modalReport.handleReport(record);
    },
    handleHistory(record) {
      // this.$refs.modalHistory.handleHistory(record);.
      this.historyData = {...record};
      this.historyVisible = true;
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getTableData();
    },
    handleFormSubmit() {
      this.loadData();
    },
    handleExamint(record) {
      this.$refs.modalExamine.handleDetail(record);
    },
    handleExamintSubmit() {
      this.loadData();
    },
    // 点击报告附件
    handleFileClick(record) {
      if (record.attrType == 2) {
        this.imgPreviewList = [];
        this.imgPreviewList.push(record.link);
        setTimeout(() => {
          this.$refs.imgPreview.clickHandler();
        }, 10);
      } else if (record.attrType == 3) {
        const reg = /.(avi|wmv|mpg|mpeg|mov|rm|swf|mp4)$/i;
        // 判断是否为视频
        if (reg.test(record.link)) {
          this.$refs.modelVideoPlayer.handleVideoPlay(record);
        } else {
          this.imgPreviewList = [];
          this.imgPreviewList.push(record.link);
          setTimeout(() => {
            this.$refs.imgPreview.clickHandler();
          }, 10);
        }
      } else {
        const link = document.createElement("a");
        link.href = record.link;
        link.download = record.attrName;
        link.click();
      }
    },
    loadData() {
      this.loading = true;
      const params = {
        lineId: this.queryModal.lineId ? this.queryModal.lineId : "",
        siteId: this.queryModal.siteId ? this.queryModal.siteId : "",
        label: this.queryModal.label ? this.queryModal.label : "",
        date: this.queryModal.date ? this.queryModal.date : "",
        state: this.queryModal.state ? this.queryModal.state : "",
        page: this.page.pageNo,
        size: this.page.pageSize
      };
      getRehearsalPageList(params).then(res => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          // this.page.pageNo = res.data.current;
          // this.page.pageSize = res.data.size;
          // this.page.total = res.data.total;
          // this.page.count = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },
    // 线路工点树数据查询
    queryLineSiteTreeData() {
      this.loading = true;
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        let tempArr = [];
        for (let item of lineSiteData) {
          const obj = {
            value: item.id,
            label: item.name,
            children: []
          };
          for (let site of item.nodes) {
            obj.children.push({
              value: site.id,
              label: site.name
            });
          }
          tempArr.push(obj);
        }
        this.dict.lineSiteData = tempArr;
        this.loadData();
      }
    },
    // 列表中状态初始化
    formatterState(row) {
      return this.dict.state.find(item => item.value == row.state).label;
    },
    formatterLineSite(row) {
      return `${row.lineName}-${row.siteName}`
    },
  }
}
</script>

<style lang="scss" scoped>
.tabs{
  display: flex;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #1684fc;
  width: 300px;
  padding: 0;
  .tabs-item{
    width: 150px;
    height: 30px;
    line-height: 30px;
    color: #1684fc;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }
  .tabs-item:first-child{
    border-right: none;
  }
  .active{
    background: #1684fc;
    color: #ffffff;
    width: 150px;
    height: 32px;
    line-height: 32px;
    border-radius: 5px;
    margin: -1px 0 0 -1px;
  }
}
::v-deep .el-table__fixed-right{
  height: 100% !important;
}
::v-deep .el-table__fixed {
    height:auto !important; // 让固定列的高自适应，且设置!important覆盖ele-ui的默认样式
    bottom:17px; // 固定列默认设置了定位，    position: absolute;top: 0;left: 0;只需要再设置一下bottom的值，让固定列和父元素的底部出现距离即可
}


::v-deep ::-webkit-scrollbar {
  height: 10px !important; /*对水平流动条有效*/
  display: block !important;
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: #fff !important;
  border-radius: 7px;
}
/*定义滑块颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}

//去掉固定列下方的横线
::v-deep .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: transparent;
}
//去掉固定列阴影
::v-deep .el-table__fixed-right {
  // box-shadow: none;
  height: 100% !important;
}
.p-4 {
  overflow-y: scroll;
}
</style>
