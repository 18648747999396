<template>
  <div v-loading="loading">
    <div class="info">
      <span class="info-item">
        {{
          detailInfo.currentResponseReport
            ? detailInfo.currentResponseReport.lineName +
              "-" +
              detailInfo.currentResponseReport.siteName
            : ""
        }}
      </span>
      <span class="info-item">检查时间：{{ detailInfo.checkTime }}</span>
    </div>
    <div class="info-item">检查人员：{{ detailInfo.checkPeople }}</div>

    <div class="table-wrapper">
      <el-table
        :data="detailInfo.threeCheckListItemList"
        style="width: 100%; margin-bottom: 20px"
        border
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="checkItemContent"
          label="检查项"
          width="500"
        >
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
              <div
                style="max-width:480px"
                v-html="scope.row.checkItemContent"
                slot="content"
              ></div>
              <div class="oneLine">
                {{ scope.row.checkItemContent }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="hiddenDangerCount"
          label="存在问题数量"
        >
          <!--          <template slot-scope="scope">
            {{ scope.row.questionList.length }}
          </template>-->
        </el-table-column>
        <el-table-column align="center" prop="planCount" label="检查详情">
          <template slot-scope="scope">
            <div
              class="content"
              v-for="(item, index) in scope.row.questionList"
              :key="index"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.hiddenQuestion"
                placement="top-start"
              >
                <div
                  style="overflow: hidden;
                            text-overflow: ellipsis; 
                            white-space: nowrap; "
                >
                  {{ item.hiddenQuestion }}
                </div>
              </el-tooltip>
              <el-image
                v-if="item.uri"
                style="width: 100px; height: 100px"
                :src="item.uri"
                fit="contain"
                :preview-src-list="[item.uri]"
              ></el-image>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-timeline v-show="operation == '查看'">
        <el-timeline-item
          v-for="(activity, index) in xyActivities"
          :key="index"
          type="primary"
          color="#3fa5e9"
          :size="'large'"
        >
          <div>
            <i style="font-style:normal;margin-right: 5px;">{{
              activity.operatorTime
            }}</i
            ><span>{{ activity.actionType }}</span>
            <span style="margin-left:10px"
              >操作人: {{ activity.operatorPost }}-{{ activity.operatorName }}
            </span>
          </div>
        </el-timeline-item>
      </el-timeline>
      <!--            查看显示-->
      <!-- <div class="title" v-if="operation === '查看'">
        审批状态:
        {{
          detailInfo.currentResponseReport.approvalState == 0
            ? "待审批"
            : detailInfo.currentResponseReport.approvalState == 1
            ? "审批通过"
            : detailInfo.currentResponseReport.approvalState == 2
            ? "审批驳回"
            : detailInfo.currentResponseReport.approvalState == -2
            ? "超时"
            : detailInfo.currentResponseReport.approvalState == -1
            ? "待填报"
            : ""
        }}
      </div> -->

      <!-- <div class="title" v-if="detailInfo.currentResponseReport.rejectReason">
        审批意见:
        {{ detailInfo.currentResponseReport.rejectReason }}
      </div> -->

      <!--      审批显示-->
      <!-- <div class="title" v-if="operation === '审批'">审批意见:</div>
      <el-input
        v-if="operation === '审批'"
        type="textarea"
        v-model="rejectReason"
      ></el-input> -->

      <!--      查看显示-->
      <!-- <el-button type="primary" @click="cancelApproval">关闭</el-button> -->

      <!--      审批显示-->
      <!-- <div
        slot="footer"
        class="dialog-footer"
        v-if="operation === '审批'"
        style="margin-top:10px"
      >
        <el-button type="primary" @click="approvalAdopt(1)">通过</el-button>
        <el-button type="danger" @click="approvalAdopt(2)">驳回</el-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getDetail, threeCheckListApproveNew } from "@/api/response";

export default {
  name: "inspectApproval",
  props: {
    operation: null,
    oneCheckData: {},
  },
  data() {
    return {
      detailInfo: {
        currentResponseReport: {
          siteId: "",
        },
        lineSiteDTO: {
          name: "",
        },
      },
      rejectReason: "",
      loading: false,
      form: {},
      xyActivities: [],
    };
  },
  created() {
    this.getDetailData();
  },
  methods: {
    getDetailData() {
      this.loading = true;
      getDetail(this.oneCheckData.id).then((res) => {
        this.xyActivities = res.data.operatingHistoryList;
        this.detailInfo = res.data;
        this.loading = false;
      });
    },
    // approvalAdopt(num) {
    //   if (num == 2) {
    //     if (this.rejectReason.length > 0) {
    //       this.loading = true;
    //     } else {
    //       this.$message.warning("审批意见不能为空");
    //       return;
    //     }
    //   } else {
    //     this.loading = true;
    //   }
    //   let params = {
    //     approvalState: num,
    //     currentId: this.oneCheckData.id,
    //     rejectReason: this.rejectReason,
    //   };
    //   threeCheckListApproveNew(params).then((res) => {
    //     if (res.code == 0) {
    //       this.$message.success("操作成功");
    //       this.loading = false;
    //       this.$emit("successApproval");
    //     }
    //   });
    // },
    // cancelApproval() {
    //   this.$emit("cancelApproval");
    // },
    // resubmit() {
    //   this.$emit("cancelApproval");
    // },
  },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: center;
}
.info {
  display: flex;
}
.info-item {
  font-weight: bold;
  margin-bottom: 10px;
  margin-right: 20px;
}
.title {
  margin-bottom: 10px;
}
.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
