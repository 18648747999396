<template>
  <div class="statistics-container p-4 w-full h-full">
    <div class="main-wrapper flex flex-col h-full">
      <div class="header-wrapper mb-4 flex">
        <div class="left box-border flex flex-col">
          <p>实时监控设备接入统计</p>
          <ul class="flex flex-1 pt-2">
            <li class="h-auto flex flex-1 justify-center">
              <div
                class="item-content flex flex-col items-center justify-around"
              >
                <span class="num cursor-pointer" @click="dateils(10086)">{{
                  equipmentData.total
                }}</span>
                <span class="title">设备总数</span>
              </div>
            </li>
            <el-divider direction="vertical" class="h-full"></el-divider>
            <li class="h-auto flex flex-1 justify-center">
              <div
                class="item-content flex flex-col items-center justify-around"
              >
                <span class="num cursor-pointer" @click="dateils(0)">{{
                  equipmentData.onLine
                }}</span>
                <span class="title">在线设备</span>
              </div>
            </li>
            <el-divider direction="vertical" class="h-full"></el-divider>
            <li class="h-auto flex flex-1 justify-center">
              <div
                class="item-content flex flex-col items-center justify-around"
              >
                <span class="num cursor-pointer" @click="dateils(1)">{{
                  equipmentData.offLine
                }}</span>
                <span class="title">离线设备</span>
              </div>
            </li>
            <el-divider direction="vertical" class="h-full"></el-divider>
            <li class="h-auto flex flex-1 justify-center">
              <div
                class="item-content flex flex-col items-center justify-around"
              >
                <span class="num cursor-pointer" @click="dateils(2)">{{
                  equipmentData.fault
                }}</span>
                <span class="title">故障设备</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="right box-border flex-auto ml-4 h-full">
          <ul class="flex h-full">
            <li class="flex-1 flex flex-col">
              <p class="text-center">
                风力监测仪：{{ equipmentData.wind.total }}
              </p>
              <div
                class="item-charts-wrapper flex-1 h-full"
                ref="chartsWrapper01"
              ></div>
            </li>
            <el-divider direction="vertical" class="h-full"></el-divider>
            <li class="flex-1 flex flex-col">
              <p class="text-center">
                雨量监测仪：{{ equipmentData.rain.total }}
              </p>
              <div
                class="item-charts-wrapper flex-1 h-full"
                ref="chartsWrapper02"
              ></div>
            </li>
            <el-divider direction="vertical" class="h-full"></el-divider>
            <li class="flex-1 flex flex-col">
              <p class="text-center">温度计：{{ equipmentData.temp.total }}</p>
              <div
                class="item-charts-wrapper flex-1 h-full"
                ref="chartsWrapper03"
              ></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="charts-wrapper flex-1 flex flex-col">
        <ul class="flex-1 flex w-full">
          <li class="flex-1 flex flex-col box-border">
            <p>24小时内雨量监测统计</p>
            <div class="flex-1" ref="lineCharts01">1</div>
          </li>
          <li class="flex-1 flex flex-col box-border ml-4">
            <p>24小时内风力监测统计</p>
            <div class="flex-1" ref="lineCharts02">2</div>
          </li>
          <li class="flex-1 flex flex-col box-border ml-4">
            <p>24小时内温度监测统计</p>
            <div class="flex-1" ref="lineCharts03">3</div>
          </li>
        </ul>
        <div class="flex-1 flex flex-col mt-4">
          <div class="flex items-center">
            <p>各线路设备预警统计</p>
            <div class="date-picker ml-4 dicom">
              <el-date-picker
                v-model="yearMonth"
                type="month"
                placeholder="选择年月"
                @change="onDateChange"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="flex-1 mt-4" ref="barChartsWrapper"></div>
        </div>
      </div>
    </div>
    <Modal
      ref="modalDetail"
      :modal="false"
      :width="'1200px'"
      :title="modelTitle"
      @closeDialog="closeDialog"
    >
      <statisticsTable
        v-if="staShow"
        ref="staDetail"
        :type="type"
      ></statisticsTable>
    </Modal>
  </div>
</template>

<script>
import * as echarts from "echarts";
import dayjs from "dayjs";
import Modal from "@/components/Modal/modal.vue";
import statisticsTable from "./monitorDialog/statisticsTable.vue";
import { getMonitorLog, getEquipmentStatis } from "@/api/map";
export default {
  components: {
    Modal,
    statisticsTable,
  },
  data() {
    return {
      yearMonth: "",
      equipmentData: {
        total: 0,
        onLine: 0,
        offLine: 0,
        fault: 0,
        rain: {
          total: 0,
          onLine: 0,
          offLine: 0,
          fault: 0,
        },
        wind: {
          total: 0,
          onLine: 0,
          offLine: 0,
          fault: 0,
        },
        temp: {
          total: 0,
          onLine: 0,
          offLine: 0,
          fault: 0,
        },
      },
      staShow: false,
      modelTitle: "",
      type: 10086,
    };
  },
  mounted() {
    // 接口查询
    this.queryMonitorLogData("0");
    this.queryMonitorLogData("1");
    this.queryMonitorLogData("2");
    this.queryEquipmentStatisData("-");
    this.queryEquipmentStatisData("0");
    this.queryEquipmentStatisData("1");
    this.queryEquipmentStatisData("2");

    this.yearMonth = dayjs().format("YYYY-MM");
    this.initBarCharts();
  },
  // data = {
  //   legendData: [],
  //   xData: [],
  //   yData: []
  // }
  methods: {
    // 加载仪表盘图表
    initDashboardCharts(el, data) {
      const charts = echarts.init(el);
      const option = {
        color: ["#72C040", "#999999", "#E23C39"],
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            center: ["25%", "40%"],
            radius: "70%",
            title: {
              show: true,
              fontSize: 14,
              color: "#72C040",
              offsetCenter: [0, "150%"],
            },
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              width: 6,
              roundCap: true,
            },
            axisLine: {
              lineStyle: {
                width: 6,
                opacity: 0.1,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              fontSize: 14,
              color: "#fff",
              offsetCenter: [0, "0%"],
            },
            max: data.total,
            data: [
              {
                value: data.onLine,
                name: "在线",
              },
            ],
          },
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            center: ["50%", "40%"],
            radius: "70%",
            title: {
              show: true,
              fontSize: 14,
              color: "#999999",
              offsetCenter: [0, "150%"],
            },
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              width: 6,
              roundCap: true,
            },
            axisLine: {
              lineStyle: {
                width: 6,
                opacity: 0.1,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              fontSize: 14,
              color: "#fff",
              offsetCenter: [0, "0%"],
            },
            max: data.total,
            data: [
              {
                value: data.offLine,
                name: "离线",
              },
            ],
          },
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            center: ["75%", "40%"],
            radius: "70%",
            title: {
              show: true,
              fontSize: 14,
              color: "#E23C39",
              offsetCenter: [0, "150%"],
            },
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              width: 6,
              roundCap: true,
            },
            axisLine: {
              lineStyle: {
                width: 6,
                opacity: 0.1,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              fontSize: 14,
              color: "#fff",
              offsetCenter: [0, "0%"],
            },
            max: data.total,
            data: [
              {
                value: data.fault,
                name: "故障",
              },
            ],
          },
        ],
      };
      charts.setOption(option);
      window.addEventListener("resize", () => {
        charts.resize();
      });
    },
    // 加载折线图表
    initLineChartsByCategory(el, data) {
      const charts = echarts.init(el);
      const option = {
        color: ["#F0EE41", "#FAAD13", "#E23C39"],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: data.legendData,
          right: 0,
          itemWidth: 20,
          itemHeight: 6,
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          top: "15%",
          left: "5%",
          right: "8%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,

          data: data.xData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#fff",
          },
          axisLine: {
            lineStyle: {
              color: "#43A5E9",
              opacity: 0.5,
            },
          },
          name: "时间",
          nameTextStyle: {
            color: "#fff",
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#43A5E9",
              opacity: 0.5,
            },
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#fff",
          },
          name: "工点数量",
          nameTextStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            name: data.legendData[0],
            type: "line",
           
            smooth: true,
            symbol: "none",
            data: data.yData1
              ? data.yData1
              : ["0", "1", "2", "3", "4", "5", "6", "7", "8"],
          },
          {
            name: data.legendData[1],
            type: "line",
        
            smooth: true,
            symbol: "none",
            data: data.yData2
              ? data.yData2
              : ["0", "1", "2", "3", "4", "5", "6", "7", "8"] ,
          },
          {
            name: data.legendData[2],
            type: "line",
           
            smooth: true,
            symbol: "none",
            data: data.yData3
              ? data.yData3
              : ["0", "1", "2", "3", "4", "5", "6", "7", "8"] ,
          },
        ],
      };
      charts.setOption(option);
      window.addEventListener("resize", () => {
        charts.resize();
      });
    },
    onDateChange() {},
    // 加载柱状图表
    initBarCharts() {
      const charts = echarts.init(this.$refs.barChartsWrapper);
      const option = {
        color: ["#1791FF", "#72C040", "#FAAD13"],
        legend: {
          show: true,
          data: ["雨量监测预警", "风力监测预警", "高温预警"],
          right: 0,
          textStyle: {
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "14%",
          left: "3%",
          right: "3%",
          bottom: "15%",
        },
        xAxis: {
          type: "category",
          data: [
            "广州市轨道交通二十二号线",
            "广州市轨道交通十八号线",
            "广州市轨道交通13号线二期线路",
            "广州市城市轨道交通十四号线二期工程",
            "广州东至花都天贵城际项目",
            "广州市轨道交通十二号线及同步实施工程总承包项目",
            "广州市轨道交通五号线东延段及同步实施工程",
          ],
          axisLine: {
            lineStyle: {
              color: "#43A5E9",
              opacity: 0.5,
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#43A5E9",
              opacity: 0.5,
            },
          },
        },
        series: [
          {
            name: "雨量监测预警",
            data: [1, 0, 0, 0, 0, 0, 0],
            type: "bar",
          },
          {
            name: "风力监测预警",
            data: [1, 0, 0, 0, 0, 0, 0],
            type: "bar",
          },
          {
            name: "高温预警",
            data: [2, 2, 2, 2, 2, 2, 2],
            type: "bar",
          },
        ],
      };
      charts.setOption(option);
      window.addEventListener("resize", () => {
        charts.resize();
      });
    },
    /***
     * 24小时天气监测
     * @param { * } type 0-凤 1-温度 2-雨
     */
    queryMonitorLogData(type) {
      getMonitorLog({ type }).then((res) => {
        if (res.code == 0) {
          // 数据初始化
          const tempYArr = [];
          let tempXArr = [];
          if (res.data.length > 0) {
            for (let item of res.data[0].timeList) {
              console.log(item)
              tempYArr.push(0);
              
            }
            tempXArr = res.data[0].timeList;
          }
          const data = {
            legendData: [],
            xData: tempXArr,
            yData1: tempYArr,
            yData2: tempYArr,
            yData3: tempYArr,
          };
          // 赋值
          for (let item of res.data) {
            if (item.scope == 0) {
              data.yData1 = item.countList;
            } else if (item.scope == 1) {
              data.yData2 = item.countList;
            } else {
              data.yData3 = item.countList;
            }
          }
          if (type == 0) {
            console.log(data)
            data.legendData = ["<5m/s", "5-10m/s", ">10m/s"];
            this.initLineChartsByCategory(this.$refs.lineCharts02, data);
          } else if (type == 1) {
             console.log(data)
            data.legendData = ["<25℃", "25-35℃", ">35℃"];
            this.initLineChartsByCategory(this.$refs.lineCharts03, data);
          } else {
             console.log(data)
            data.legendData = ["<5mm", "5-10mm", ">10mm"];
            this.initLineChartsByCategory(this.$refs.lineCharts01, data);
          }
        }
      });
    },
    /**
     * 监控设备统计
     * @param {*} type "-": 全部，"0": 风，"1": 温度，"2"：雨
     */
    queryEquipmentStatisData(type) {
      getEquipmentStatis({ type }).then((res) => {
        if (res.code == 0) {
          if (type == "-") {
            for (let item of res.data) {
              switch (item.label) {
                case "设备总数":
                  this.equipmentData.total = item.count;
                  break;
                case "在线设备":
                  this.equipmentData.onLine = item.count;
                  break;
                case "离线设备":
                  this.equipmentData.offLine = item.count;
                  break;
                case "故障设备":
                  this.equipmentData.fault = item.count;
                  break;
              }
            }
          } else if (type == "0") {
            for (let item of res.data) {
              switch (item.label) {
                case "设备总数":
                  this.equipmentData.wind.total = item.count;
                  break;
                case "在线设备":
                  this.equipmentData.wind.onLine = item.count;
                  break;
                case "离线设备":
                  this.equipmentData.wind.offLine = item.count;
                  break;
                case "故障设备":
                  this.equipmentData.wind.fault = item.count;
                  break;
              }
            }
            this.initDashboardCharts(
              this.$refs.chartsWrapper01,
              this.equipmentData.wind
            );
          } else if (type == "1") {
            for (let item of res.data) {
              switch (item.label) {
                case "设备总数":
                  this.equipmentData.temp.total = item.count;
                  break;
                case "在线设备":
                  this.equipmentData.temp.onLine = item.count;
                  break;
                case "离线设备":
                  this.equipmentData.temp.offLine = item.count;
                  break;
                case "故障设备":
                  this.equipmentData.temp.fault = item.count;
                  break;
              }
            }
            this.initDashboardCharts(
              this.$refs.chartsWrapper03,
              this.equipmentData.temp
            );
          } else {
            for (let item of res.data) {
              switch (item.label) {
                case "设备总数":
                  this.equipmentData.rain.total = item.count;
                  break;
                case "在线设备":
                  this.equipmentData.rain.onLine = item.count;
                  break;
                case "离线设备":
                  this.equipmentData.rain.offLine = item.count;
                  break;
                case "故障设备":
                  this.equipmentData.rain.fault = item.count;
                  break;
              }
            }
            this.initDashboardCharts(
              this.$refs.chartsWrapper02,
              this.equipmentData.rain
            );
          }
        }
      });
    },
    dateils(type) {
   
      this.type = type;
      this.modelTitle = "实时监控设备接入";
      this.staShow = true;
      this.$refs.modalDetail.handleOpen();
      setTimeout(() => {
        this.$refs.staDetail.initdata();
      }, 1000);
    },
    closeDialog() {
      this.staShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-container {
  background: rgba(8, 23, 66, 0.9);
}
::v-deep .el-divider {
  width: 1px;
  background: #fff;
  opacity: 0.1;
}

::v-deep .dicom .el-input__inner {
  background: #18467f;
  border: none;
  height: 30px;
  line-height: 30px;
  color: #fff;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 180px;
}

::v-deep .dicom .el-input__icon {
  line-height: 30px;
}

.box-border {
  border: 1px solid #0e2553;
  padding: 1.6vh;
}

.main-wrapper {
  .header-wrapper {
    height: 14.96vh;

    .left {
      width: 26vw;

      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #43a5e9;
      }

      span {
        color: #fff;
        line-height: normal;
      }

      .num {
        font-size: 24px;
        font-family: Arial;
        font-weight: bold;
      }

      .title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        opacity: 0.6;
      }
    }

    .right {
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #43a5e9;
      }
    }
  }

  .charts-wrapper {
    p {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #43a5e9;
    }
  }
}
</style>
