<template>
  <div class="cuoshiWrap">
    <div class="cuoshiTitle">
      <div class="cuoshiTitle_left">措施问题处置</div>
      <div class="cuoshiTitle_right">状态</div>
      <div class="cuoshiTitle_right">操作</div>
    </div>
    <div
      class="cuoshi"
      v-for="item in measuresList"
      :key="item.measureTypeName"
    >
      <h3>{{ item.measureTypeName }}</h3>
      <div v-for="j in item.subDataList" :key="j.subTypeName">
        <p>{{ j.subTypeName }}</p>
        <div class="cuoshi_top">
          <div class="cuoshi_top_left">
            <p v-for="(i, index) in j.riskMeasureList" :key="index">
              {{ `（${index + 1}）` + i.measureContent }}
            </p>
          </div>
          <div class="cuoshi_top_right">
            <p v-for="i in j.riskMeasureList" :key="i.id">
              {{ i.state ? "已落实" : "未落实" }}
            </p>
          </div>
          <div class="cuoshi_top_right cuoshi_btn">
            <p
              v-for="i in j.riskMeasureList"
              :key="i.id"
              @click="setCuoshi(i)"
              :class="{ setCuoshi: i.viewButton ? false : true }"
            >
              {{ i.viewButton ? "查看" : i.dealButton ? "处置" : "" }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      width="1200px"
      :title="title"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <SetCuoshi
        ref="SetCuoshi"
        @CloseData="handleClose"
        :pageData="pageData"
      ></SetCuoshi>
    </el-dialog>
  </div>
</template>

<script>
import SetCuoshi from "./setCuoshi";
import { getMeasuresList } from "@/api/risk";
export default {
  components: {
    SetCuoshi,
  },
  props: {
    measuresList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      title: "",
      dialogVisible: false,
      pageData: {},
      submitType: false,
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.$emit('getData')
    },
    setCuoshi(data) {
      console.log(data);
      if (data.viewButton) {
        this.title = "查看措施";
        this.submitType = false;
      } else if (data.dealButton) {
        this.title = "处置措施";
        this.submitType = true;
      }
      getMeasuresList(data.id).then((res) => {
        this.pageData = res.data;
        this.dialogVisible = true;
      });
    },
  },
};
</script>

<style lang="scss" scope>
.cuoshiWrap {
  padding: 0 20px;
  .cuoshiTitle {
    display: flex;
    font-weight: bold;
    font-size: 16px;
    margin: 14px 0;
    .cuoshiTitle_left {
      flex: 1;
    }
    .cuoshiTitle_right {
      width: 70px;
    }
  }
  .cuoshi {
    margin-bottom: 20px;
    > h3 {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 10px;
    }
    > p {
      font-size: 14px;
      margin: 10px 0;
    }
    .cuoshi_top {
      display: flex;
      > div {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
      }
      .cuoshi_top_left {
        flex: 1;
        p {
          margin: 4px 0;
        }
      }
      .cuoshi_top_right {
        width: 70px;
      }
      .cuoshi_btn {
        cursor: pointer;
        color: #409eff;
      }
      .setCuoshi {
        color: #ff0000;
      }
    }
  }
}
</style>
