let result = [];
const ids = [];
/**
 * 根据工点id查询父级id
 * @param {*} siteId 工点id
 * @returns
 */
export function lineSiteTreeCascadeEcho(siteId) {
  ids.push(siteId)
  const tree = JSON.parse(localStorage.getItem("lineSiteTree"));
  const flatLineSiteData = JSON.parse(localStorage.getItem("flatLineSiteData"))
  if (flatLineSiteData) {
    result = flatLineSiteData;
  } else {
    flatArr(tree);
    localStorage.setItem("flatLineSiteData", JSON.stringify(result));
  }
  const tempObj = result.find(item => item.id == siteId);
  if (tempObj) {
    findParent(tempObj.parentuuid);
  }
  return ids;
}
/**
 * 根据工点uuid查询父级id
 * @param {*} siteUuId 工点uuid
 * @returns 包含父级的id集合
 */
export function lineSiteTreeCascadeEchoByUuid(siteUuId) {
  ids.push(siteUuId)
  const tree = JSON.parse(localStorage.getItem("lineSiteTree"));
  const flatLineSiteData = JSON.parse(localStorage.getItem("flatLineSiteData"))
  if (flatLineSiteData) {
    result = flatLineSiteData;
  } else {
    flatArr(tree);
    localStorage.setItem("flatLineSiteData", JSON.stringify(result));
  }
  const tempObj = result.find(item => item.uuid == siteUuId);
  if (tempObj) {
    findParentByUuId(tempObj.parentuuid);
  }
  return ids;
}
// 数组扁平化
function flatArr(data) {
  for (let item of data) {
    result.push({
      name: item.name,
      id: item.id,
      uuid: item.uuid,
      parentuuid: item.puuid
    })
    if (item.children) {
      flatArr(item.children)
    }
  }
}
// 查找父级
function findParent(puuid) {
  const tempObj = result.find(item => item.uuid == puuid);
  if (tempObj) {
    ids.unshift(tempObj.id);
    if (tempObj.parentuuid) {
      findParent(tempObj.parentuuid)
    }
  }
}
// 根据uuid查找父级
function findParentByUuId(puuid) {
  const tempObj = result.find(item => item.uuid == puuid);
  if (tempObj) {
    ids.unshift(tempObj.uuid);
    if (tempObj.parentuuid) {
      findParentByUuId(tempObj.parentuuid);
    }
  }
}