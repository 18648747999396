<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
             label-width="120px" class="demo-model">
      <el-form-item label="推送单位" prop="siteIds">
        <el-cascader v-model="ruleForm.lineSite" style="width: 100%;"
                     clearable :options="treeData" :props="props" ref="cascaderTree"
                     :show-all-levels="false" collapse-tags @change="handleLineSiteChange"></el-cascader>

<!--        <el-select v-model="ruleForm.rescueTeamIdList" multiple placeholder="推送单位" v-else
                   style="width: 100%">
          <el-option
              v-for="item in rescueTeamList"
              :key="item.value"
              :label="item.teamName"
              :value="item.id">
          </el-option>
        </el-select>-->

      </el-form-item>
      <el-form-item label="参与单位" prop="participants">
        <el-input v-model="ruleForm.participants" placeholder="请输入演练主题"></el-input>
      </el-form-item>
      <el-form-item label="演练内容" prop="planContent">
        <el-input v-model="ruleForm.planContent" placeholder="请输入演练主题"></el-input>
      </el-form-item>

      <el-form-item label="演练类型" prop="drillTypeCode">
        <!--        <el-input v-model="ruleForm.category" placeholder="请输入演练地点"></el-input>-->
        <el-select v-model="ruleForm.drillTypeCode" placeholder="演练类型"
                   style="width: 100%">
          <el-option
              v-for="item in categoryList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="演练科目" prop="planSubjectCode">
        <!--        <el-input v-model="ruleForm.planSubjectCode" placeholder="请输入演练地点"></el-input>-->
        <el-select v-model="ruleForm.planSubjectCode" placeholder="演练科目" style="width: 100%">
          <el-option
              v-for="item in subjectList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="演练级别" prop="drillLevelCode">
        <!--        <el-input v-model="ruleForm.drillLevelCode" placeholder="请输入演练地点"></el-input>-->
        <el-select v-model="ruleForm.drillLevelCode" placeholder="演练科目" style="width: 100%">
          <el-option
              v-for="item in levelList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="演练地点" prop="label">
        <el-input v-model="ruleForm.address" placeholder="请输入演练主题"></el-input>
      </el-form-item>
      <el-form-item label="演练人数" prop="planJoinCount">
        <el-input v-model="ruleForm.planJoinCount" placeholder="请输入演练主题"></el-input>
      </el-form-item>

      <el-form-item label="计划演练时间" prop="planDate">
        <el-input v-model="ruleForm.planDate" placeholder="请输入演练主题"></el-input>
      </el-form-item>
      <el-form-item label="附件" prop="label">
        <Uploader @uploader="uploader"></Uploader>
      </el-form-item>

    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitPush">确 定</el-button
      >
    </div>

  </div>
</template>

<script>
import Uploader from "@/views/System/TrainingDrill/components/Uploader";
import {getDictData} from "@/api/dict";
import {getQxlist} from "@/api/eres";
import {pushRescueTeamPlan, pushSitePlan} from "@/api/stand";
export default {
  name: "pushPlan",
  components:{
    Uploader,
  },
  props : {
    addType : {
      //Rescue
      default : 'construction'
      // default : 'Rescue'
    }
  },
  data(){
    return{
      ruleForm : {
        organizer : '',
        ranksUuids : '',
        participants : '',
        planContent : '',
        drillTypeCode : '',
        planSubjectCode : '',
        drillLevelCode : '',
        address : '',
        planJoinCount : '',
        planDate : '',
        siteUuid : '',
        siteId : '',
        siteIds : [],
        lineSiteDeptVoList : [],
        fileUrl : '',
        rescueTeamIdList : []
      },
      rules : {
         siteIds: [
           { required: true, message: '参与工点不能为空', trigger: 'blur' },
         ],
         ranksUuids: [
           { required: true, message: '参与队伍不能为空', trigger: 'blur' },
         ],
         participants: [
           { required: true, message: '参与单位不能为空', trigger: 'blur' },
         ],
         planContent: [
           { required: true, message: '演练内容不能为空', trigger: 'blur' },
         ],
         drillTypeCode: [
           { required: true, message: '演练类型不能为空', trigger: 'blur' },
         ],
         planSubjectCode: [
           { required: true, message: '演练科目不能为空', trigger: 'blur' },
         ],
         drillLevelCode: [
           { required: true, message: '演练级别不能为空', trigger: 'blur' },
         ],
         address: [
           { required: true, message: '演练地点不能为空', trigger: 'blur' },
         ],
         planJoinCount: [
           { required: true, message: '演练人数不能为空', trigger: 'blur' },
         ],
         planDate: [
           { required: true, message: '计划演练时间不能为空', trigger: 'blur' },
         ],
      },
      tags : [],
      dialogVisible : false,
      treeData: [],
      props: {
        children: "children",
        label: "name",
        // value: "id",
        value: "uuid",
        multiple: true
      },
      categoryList : [],
      subjectList : [],
      levelList : [],
      rescueTeamList : [],
      flatTreeData : [],
    }
  },
  created() {
    this.getLineSiteTreeData();
    this.getSelectList();
    if(this.addType === 'Rescue'){
      this.getRescueData();
    }
  },
  methods : {
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
      this.flatArr(lineSiteData)
    },
    // 数组扁平化
    flatArr(data) {
      for (let item of data) {
        this.flatTreeData.push(item);
        if (item.children) {
          this.flatArr(item.children);
        }
      }
    },
    uploader(event){
      this.ruleForm.fileUrl = event.uri;
    },
    getRescueData(){
      getQxlist().then(res => {
        if(res.code == 0){
          this.rescueTeamList = res.data;
        }
      })
    },
    getSelectList(){
      let stringList = ['eventType','drill_type','drill_level'];
      stringList.forEach(item => {
        this.getDictList(item);
      })
    },
    getDictList(label){
      getDictData(label).then(res => {
        switch (label){
          case 'eventType' :
            this.categoryList = res.data.map(item => {
              item.value = item.label + ',' + item.id
              return item
            })
            break;
          case 'drill_type' :
            this.subjectList = res.data.map(item => {
              item.value = item.label + ',' + item.id
              return item
            })
            break;
          case 'drill_level' :
            this.levelList = res.data.map(item => {
              item.value = item.label + ',' + item.id
              return item
            })
        }
      })
    },
    handleLineSiteChange(record) {
      console.log(record)
      this.ruleForm.siteIds = [];
      for (let item of record) {
        this.ruleForm.siteIds.push(item[item.length-1]);
      }
    },
    submitPush(){
      this.$refs['ruleForm'].validate((valid) => {
        if(valid){
          const siteNodes = this.$refs.cascaderTree.getCheckedNodes();
          for (let node of siteNodes) {
            this.ruleForm.lineSiteDeptVoList.push({
              branchId: node.path[1],
              branchName: node.pathLabels[1],
              lineId: node.path[0],
              lineName: node.pathLabels[0],
              areaUuid: node.path[2],
              areaName: node.pathLabels[2],
              siteId: node.value,
              siteName: node.label,
              superviseId: node.data.constructionManagementUnitId,
              superviseName: node.data.constructionManagementName,
              constructionManagementUnitId: node.data.constructionManagementUnitId,
              constructionUnitName: node.data.constructionUnitName,
              constructionUnitId: node.data.constructionUnitId,
            });
          }
          var params = {}

          if(this.addType === 'construction'){
            params = {
              address : this.ruleForm.address,
              drillLevel : this.ruleForm.drillLevelCode.split(',')[0],
              drillLevelCode : this.ruleForm.drillLevelCode.split(',')[1],
              drillType : this.ruleForm.drillTypeCode.split(',')[0],
              drillTypeCode : this.ruleForm.drillTypeCode.split(',')[1],
              planSubject : this.ruleForm.planSubjectCode.split(',')[0],
              planSubjectCode : this.ruleForm.planSubjectCode.split(',')[1],
              planDate : this.ruleForm.planDate,
              fileUrl : this.ruleForm.fileUrl,
              planJoinCount : this.ruleForm.planJoinCount,
              planContent : this.ruleForm.planContent,
              participants : this.ruleForm.participants,
              siteUuidList : siteNodes.map(item => item.siteId),
            }
            pushSitePlan(params).then(res => {
              if(res.code == 0){
                this.$message.success('操作成功');
                this.$emit('successPlan')
              }else{
                this.$message.error(res.msg)
              }
            })
          }else{
            params = {
              rescueTeamIdList : siteNodes.map(item => item.siteId),
              drillLevel : this.ruleForm.drillLevelCode.split(',')[0],
              drillLevelCode : this.ruleForm.drillLevelCode.split(',')[1],
              drillType : this.ruleForm.drillTypeCode.split(',')[0],
              drillTypeCode : this.ruleForm.drillTypeCode.split(',')[1],
              planSubject : this.ruleForm.planSubjectCode.split(',')[0],
              planSubjectCode : this.ruleForm.planSubjectCode.split(',')[1],
              planJoinCount : this.ruleForm.planJoinCount,
              planDate : this.ruleForm.planDate,
              participants : this.ruleForm.participants,
              planContent : this.ruleForm.planContent,
              address : this.ruleForm.address,
              fileUrl : this.ruleForm.fileUrl,
            }
            pushRescueTeamPlan(params).then(res => {
              if(res.code == 0){
                this.$message.success('操作成功');
                this.$emit('successPlan')
              }else{
                this.$message.error(res.msg)
              }
            })
          }

        }else{
          this.$message.error('请正确填写')
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
