<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
            <el-cascader
              v-model="queryModal.linsiteIds"
              clearable
              :options="lineSiteData"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="handleLineSiteChange"
              placeholder="选择线路工点"
            ></el-cascader>
          </el-form-item>

          <el-form-item label="">
            <el-select
              clearable
              v-model="queryModal.nodeTypeCode"
              placeholder="选择类别"
            >
              <el-option
                v-for="item in keyNodeTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="queryModal.nodeLevelCode"
              placeholder="选择级别"
            >
              <el-option
                v-for="item in keyNodesLevelList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              clearable
              v-model="queryModal.nodeName"
              placeholder="节点名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="queryModal.state"
              placeholder="选择验收状态"
            >
              <el-option
                v-for="item in stateList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData">查询</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div style="margin-bottom: 10px">
      <el-radio-group v-model="active" @change="changeTab">
        <el-radio-button :label="1">待验收</el-radio-button>
        <el-radio-button :label="2">已验收</el-radio-button>
      </el-radio-group>
    </div>

    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="分区"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="工点"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="nodeTypeName"
          label="类别"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="nodeName"
          label="节点名称"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="nodeLevelName"
          label="级别"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="stateName"
          label="验收状态"
          show-overflow-tooltip
          width=""
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.state != 3"
              :class="
                scope.row.state == 5
                  ? 'redClass'
                  : scope.row.state == 6
                  ? 'orangeClass'
                  : ''
              "
              type="text"
              @click="auditclick(scope.row)"
              >{{ scope.row.stateName }}</el-button
            >
            <span v-else>{{ scope.row.stateName }}</span>
          </template>
          <!-- <span v-if="scope.row.types == 0">
            <el-button type="text" @click="auditclick(scope.row)"
              >待申请</el-button
            >
          </span>
          <span v-if="scope.row.types == 1">
            <el-button type="text" @click="auditclick(scope.row)"
              >待监理验收</el-button
            >
          </span>
          <span v-if="scope.row.types == 2">
            <el-button type="text" @click="auditclick(scope.row)"
              >整改后通过</el-button
            >
          </span>
          <span v-if="scope.row.types == 3">
            <el-button type="text" @click="auditclick(scope.row)"
              >监理驳回</el-button
            >
          </span> -->
        </el-table-column>
        <el-table-column align="center" label="操作" width="250" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="handleView(scope.row)"
              >查看</el-button
            >
            <span
              v-if="
                scope.row.state == 3 ||
                  scope.row.state == 4 ||
                  scope.row.state == 5 ||
                  scope.row.state == 7
              "
              class="ml-2"
            >
              <!-- <el-button
                v-if="userUnitType == 'construct' || userName == 'admin'"
                type="text"
                :disabled="
                  scope.row.state == 4
                    ? true
                    : scope.row.state == 7
                    ? true
                    : false
                "
                @click="handleApp(scope.row, 'apply')"
                >{{ scope.row.state == 3 ? "申请" : "整改" }}</el-button
              > -->
              <el-button
                v-if="
                  scope.row.state != 7 &&
                    (userUnitType == 'construct' || userName == 'admin')
                "
                type="text"
                :disabled="scope.row.state == 4 ? true : false"
                @click="handleApp(scope.row, 'apply')"
                >申请</el-button
              >
              <!-- <el-button
                
                type="text"
                :disabled="true"
                @click="handleApp(scope.row, 'apply')"
                >整改</el-button
              > -->
              <span
                v-if="
                  scope.row.state == 7 &&
                    (userUnitType == 'construct' || userName == 'admin')
                "
                style="color:#c0c4cc;cursor:no-drop"
              >
                整改
              </span>
            </span>
            <span v-if="scope.row.state == 6" class="ml-2">
              <el-button
                v-if="userUnitType == 'construct' || userName == 'admin'"
                type="text"
                @click="handleApp(scope.row, 'edit')"
                >整改</el-button
              >
            </span>
            <span
              v-if="
                scope.row.state == 4 ||
                  scope.row.state == 5 ||
                  scope.row.state == 6 ||
                  scope.row.state == 7
              "
              class="ml-2"
            >
              <el-button
                :disabled="
                  scope.row.state == 5
                    ? true
                    : scope.row.state == 6
                    ? true
                    : false
                "
                v-if="userUnitType == 'monitor' || userName == 'admin'"
                type="text"
                @click="handleAudit(scope.row)"
                >审核</el-button
              >
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      :close-on-click-modal="false"
      class="editDialog details-dialog"
      :before-close="CloseData"
    >
      <nodeAccForm :rowData="rowData" @submitClose="applyClose"></nodeAccForm>
    </el-dialog>
    <el-dialog
      :title="title"
      :visible.sync="accJLVisible"
      v-if="accJLVisible"
      class="editDialog"
      append-to-body
      :before-close="CloseData"
    >
      <nodeAccFormJL
        :rowData="rowData"
        @submitClose="closeFormJL"
      ></nodeAccFormJL>
    </el-dialog>
    <!-- <el-dialog
      width="50%"
      class="editDialog details-dialog"
      :visible.sync="nodeDetailsDia"
      v-if="nodeDetailsDia"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <NodeDetails :rowData="rowData"></NodeDetails>
    </el-dialog> -->
    <el-dialog
      title="关键节点详情"
      :visible.sync="nodeDetailsDia"
      v-if="nodeDetailsDia"
      :close-on-click-modal="false"
      class="editDialog details-dialog"
      :before-close="handleClose"
      append-to-body
    >
      <nodeConForm
        type="view"
        :keyNodeTypeList="keyNodeTypeList"
        :keyNodesLevelList="keyNodesLevelList"
        @CloseData="CloseData"
        @submitClose="submitClose"
        :nodeId="nodeId"
        :nodeForm="nodeForm"
      ></nodeConForm>
    </el-dialog>
    <el-dialog
      width="50%"
      class="editDialog details-dialog"
      :visible.sync="auditVisible"
      v-if="auditVisible"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <NodeAudit
        :active="AuditActive"
        :rowData="rowData"
        :currentState="currentState"
        pageType="验收"
        :nodeUuid="nodeUuid"
        @showFileList="showFileList"
      ></NodeAudit>
    </el-dialog>
    <el-dialog
      width="40%"
      title="附件列表"
      class="fileDialogCs"
      :visible.sync="fileDialog"
      v-if="fileDialog"
      style="z-index:99999"
      :close-on-click-modal="false"
      :before-close="handlefileClose"
    >
      <el-table :data="fileData" style="width: 100%">
        <el-table-column
          align="center"
          prop="fileName"
          show-overflow-tooltip
          label="附件名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          show-overflow-tooltip
          label="操作"
          width="90"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="handleFileView(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import nodeConForm from "./KeyNodeDialog/nodeConForm.vue";
import nodeAccForm from "./KeyNodeDialog/nodeAccForm.vue";
import nodeAccFormJL from "./KeyNodeDialog/nodeAccFormJL.vue";
// import NodeDetails from "./KeyNodeDialog/nodeDetails.vue";
import NodeAudit from "./KeyNodeDialog/nodeAuditLog.vue";
import { getDictData } from "@/api/dict";
import { nodeCheckPage } from "@/api/riskManage";
export default {
  components: {
    nodeConForm,
    // NodeDetails,
    nodeAccForm,
    NodeAudit,
    nodeAccFormJL
  },
  data() {
    return {
      queryModal: {
        siteUuidList: [],
        nodeTypeCode: "",
        nodeLevelCode: "",
        nodeName: "",
        linsiteIds: [],
        isApply: false,
        keyNodesType: 1,
        state: null
      },
      loading: false,
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      tableData: [],
      warnTypeList: [],
      nodeDetailsDia: false,
      active: 1,
      dialogVisible: false,
      auditVisible: false,
      AuditActive: 2,
      rowData: {},
      accJLVisible: false,
      keyNodeTypeList: [],
      keyNodesLevelList: [],
      stateList: [
        // 0 待确认 1待确认审核 2确认驳回 3.待申请验收 4待验收审核 5驳回待申请 6验收待整改 7整改待验收 8验收通过
        // { name: "待申请",value:0 },
        // { name: "待监理审核",value:1 },
        // { name: "监理驳回",value:2 },
        { name: "待申请", value: 3 },
        { name: "待监理验收", value: 4 },
        { name: "监理驳回", value: 5 },
        { name: "整改后通过", value: 6 },
        // { name: "整改待验收 ", value: 7 },
        { name: "验收通过 ", value: 8 }
      ],
      nodeId: null,
      nodeUuid: "",
      nodeForm: {},
      userUnitType: "",
      userName: "",
      title: "",
      fileDialog: false,
      fileData: [],
      currentState: null
    };
  },
  created() {
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      this.userUnitType = user.companyCategory;
      console.log(this.userUnitType, "userUnitType");
      this.userName = user.userName;
    }
    this.getLineTree();
    this.getDict();
    this.getData();
  },
  methods: {
    async getLineTree() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.lineSiteData = JSON.parse(JSON.stringify(lineSiteData));
      }
    },
    // 级联选择器事件
    handleLineSiteChange(val) {
      console.log(val, "val");
      this.queryModal.siteUuidList = [];
      if (val.length > 0) {
        for (let item of val) {
          this.queryModal.siteUuidList.push(item[item.length - 1]);
        }
      }
    },
    getDict() {
      getDictData("keyNodesType").then(res => {
        if (res.code == 0) {
          this.keyNodeTypeList = res.data;
        }
      });
      getDictData("keyNodesLevel").then(res => {
        if (res.code == 0) {
          this.keyNodesLevelList = res.data;
        }
      });
    },
    getData() {
      this.loading = true;
      let params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.queryModal
      };
      nodeCheckPage(params).then(res => {
        this.stateList.forEach(el => {
          res.data.records.forEach(v => {
            // debugger
            if (el.value == v.state) {
              v.stateName = el.name;
            }
            if (v.state == 7) {
              v.stateName = "待监理验收";
            }
          });
        });
        this.tableData = res.data.records;
        this.page.total = res.data.total;
        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getData();
    },
    handleView(row) {
      console.log(row, "row");
      this.nodeId = row.id;
      this.nodeUuid = row.nodeUuid;
      this.nodeForm = row;
      this.nodeDetailsDia = true;
    },
    auditclick(row) {
      console.log(row, "row");
      this.AuditActive = 2;
      if (row.state == 4 || row.state == 7) {
        this.currentState = 1;
      } else if (row.state == 6 || row.state == 5 || row.state == 8) {
        this.currentState = 2;
      }
      this.rowData = { ...row };
      // this
      this.nodeId = row.id;
      this.nodeUuid = row.nodeUuid;
      this.auditVisible = true;
    },
    applyClose() {
      this.loading = true;
      this.dialogVisible = false;
      setTimeout(() => {
        this.getData();
      }, 1000);
    },
    closeFormJL() {
      this.loading = true;
      this.accJLVisible = false;
      setTimeout(() => {
        this.getData();
      }, 1000);
    },
    submitClose() {
      this.nodeDetailsDia = false;
      this.getData();
    },
    handleAudit(row) {
      this.rowData = row;
      this.accJLVisible = true;
    },
    CloseData() {
      this.dialogVisible = false;
      this.accJLVisible = false;
      this.nodeDetailsDia = false;
      this.nodeForm = {};
    },
    handleClose() {
      this.nodeDetailsDia = false;
      this.auditVisible = false;
    },
    showFileList(obj) {
      this.fileDialog = true;
      this.fileData = obj.fileList;
      console.log(this.fileData);
    },
    handleFileView(row) {
      const link = document.createElement("a");
      link.href = row.fileUrl;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
    handlefileClose() {
      this.fileDialog = false;
    },
    changeTab(name) {
      if (name == 1) {
        this.queryModal.isApply = false;
      }
      if (name == 2) {
        this.queryModal.isApply = true;
      }
      this.getData();
      // this.active = name;
    },
    handleApp(row, type) {
      console.log(row);
      if (type == "apply") {
        this.title = "节点验收申请";
      } else if (type == "edit") {
        this.title = "节点整改";
      }
      this.rowData = { ...row };
      this.dialogVisible = true;
    },
    handleDelete(row) {
      console.log(row);
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #1684fc;
  width: 300px;
  padding: 0;
  .tabs-item {
    width: 150px;
    height: 30px;
    line-height: 30px;
    color: #1684fc;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }
  .tabs-item:first-child {
    border-right: none;
  }
  .active {
    background: #1684fc;
    color: #ffffff;
    width: 150px;
    height: 32px;
    line-height: 32px;
    border-radius: 5px;
    margin: -1px 0 0 -1px;
  }
}
::v-deep .editDialog .el-dialog .el-dialog__body {
  padding-left: 20px;
}
.redClass {
  color: rgb(245, 108, 108);
}
.orangeClass {
  color: rgb(230, 162, 60);
}
</style>
