<template>
  <div class="digi_uploader">
    <el-upload
      ref="upload"
      :http-request="uploads"
      class="upload-demo"
      action=""
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-change="handleChange"
      :before-upload="beforeAvatarUpload"
      :file-list="fileLists"
      :disabled="publicdisabled"
      multiple
    >
      <!--      :limit="1"-->
      <el-button size="small" type="primary" icon="el-icon-plus"
        >点击上传</el-button
      >
      <div slot="tip">
        只能上传doc/docx/xls/xlsx/pdf类型的附件，且不超过10Mb
      </div>
    </el-upload>
  </div>
</template>

<script>
import { upload } from "@/api/upload";
export default {
  name: "DigiUploader",
  props: {
    fileArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fileList: [],
      fileLists: [],
      // fileListsCopy: [],
      publicdisabled: false,
      emitList: [],
    };
  },
  /*  watch:{
    fileArray : function (newValue) {
      this.fileLists = newValue.map(item => {
        return {
          name : item.file_name,
          url : item.link
        }
      })
    }
  },*/
  created() {
    console.log(this.fileArray);
    this.fileLists = this.fileArray;
  },
  methods: {
    uploads(val) {
      console.log(val);
      /*Promise.all([ upload(val.file).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.emitList.push(res.data)
          // this.$emit("uploader", res.data);
        }
      })]).then(data => {
        console.log(this.emitList)
      })*/
      upload(val.file).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.emitList.push(res.data);
          this.$emit("uploader", res.data);
        }
      });
    },
    handleRemove(file, fileList) {
      this.fileLists = fileList;
      this.$emit("uploader", fileList);
    },
    beforeAvatarUpload(file) {
      console.log(file);
    },
    handlePreview(file) {
      console.log(file);
      window.open(file.url);
      /* upload(file.raw).then(res => {
        console.log(res);
        if (res.code == 0) {
          window.open(
            "https://view.xdocin.com/view?src=" +
              encodeURIComponent(res.data.uri)
          );
        }
      });*/
      console.log(1112323);
    },
    handleChange(file, fileList) {
      // console.log(file, fileList);
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      let extension1 = testmsg === "jpg";
      let extension2 = testmsg === "jpeg";
      let extension3 = testmsg === "png";
      let extension4 = testmsg === "doc";
      let extension5 = testmsg === "docx";
      let extension6 = testmsg === "xls";
      let extension7 = testmsg === "xlsx";
      let extension8 = testmsg === "pdf";
      if (
        !extension1 &&
        !extension2 &&
        !extension3 &&
        !extension4 &&
        !extension5 &&
        !extension6 &&
        !extension7 &&
        !extension8
      ) {
        this.$message.error(
          "上传附件只能是doc/docx/xls/xlsx/pdf/jpg/png/jpeg 格式!"
        );
        let filePash;

        if (file.response && file.response.attachId) {
          filePash = file.response.attachId;
        } else {
          filePash = file.attachmentId;
        }
        let y = this.fileList.findIndx((x) => x.attachmentId === filePash);
        if (y === -1) {
          return;
        }
        this.fileList.splice(y, 1);
        this.fileLists = fileList;
        return false;
      }
      let isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("上传大小不能超过 10MB!");
        let filePash;
        if (file.response && file.response.attachId) {
          filePash = file.response.attachId;
        } else {
          filePash = file.attachmentId;
        }
        let y = this.fileList.findIndx((x) => x.attachmentId === filePash);
        if (y === -1) {
          return;
        }
        this.fileList.splice(y, 1);
        this.fileLists = fileList;
        return false;
      }
      this.fileLists = fileList;
    },
  },
};
</script>

<style></style>
