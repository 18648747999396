<template>
  <div class="stCard" style="height: 99%">
    <div class="mt-2 mb-8">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item
          :to="{ path: '/ThreeProofings/Response/Preventionstatistics' }"
          >数据统计</el-breadcrumb-item
        >
        <el-breadcrumb-item
          >{{ paramsData.name }}-{{ paramsData.tabName }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div>
      <SFDB
        v-if="paramsData.name == '三防待办统计'"
        :paramsData="paramsData"
      ></SFDB>
      <SFYH
        v-if="paramsData.name == '三防隐患问题排查统计'"
        :paramsData="paramsData"
      ></SFYH>
      <SFTB
        v-if="paramsData.name == '三防图表录入统计'"
        :paramsData="paramsData"
      ></SFTB>
      <SFXY
        v-if="paramsData.name == '三防应急发布统计'"
        :paramsData="paramsData"
      ></SFXY>
      <SFGZ
        v-if="paramsData.name == '防汛关注重点统计'"
        :paramsData="paramsData"
      ></SFGZ>
      <SFYJ
        v-if="paramsData.name == '三防应急响应工作情况统计'"
        :paramsData="paramsData"
      ></SFYJ>
    </div>
  </div>
</template>
  
  <script>
import SFDB from "./tables/SFDB.vue";
import SFYH from "./tables/SFYH.vue";
import SFTB from "./tables/SFTB.vue";
import SFXY from "./tables/SFXY.vue";
import SFGZ from "./tables/SFGZ.vue";
import SFYJ from "./tables/SFYJ.vue";

export default {
  components: { SFDB, SFYH, SFTB, SFXY, SFGZ, SFYJ },
  props: {},
  data() {
    return {
      tabledata: [],
      paramsData: {},
    };
  },
  watch: {},
  methods: {},

  mounted() {
    this.paramsData = this.$route.params;
  },
};
</script>
  
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 700px;
  overflow: auto;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
::v-deep .el-table:before {
  height: 0px;
}
.stCard {
  margin: 0 20px 10px 0;
  padding: 20px 20px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.cenCard {
  padding: 20px 20px;
  width: 24%;
  height: 100px;
}
.cenNum {
  font-size: 28px;
  font-family: Arial;
  font-weight: bold;
  color: #081742;
}
</style>
  