<template>
  <!-- 危大工程清单库 -->
  <div class="page-container bg-white p-4 h-full relative">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row class="leftSearch">
          <el-form-item prop="siteUuidList">
            <el-cascader
              v-model="lineSite"
              clearable
              :options="treeData"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="handleLineSiteChange"
              placeholder="请选择工点"
            ></el-cascader>
          </el-form-item>
          <el-form-item prop="riskTypeCode">
            <el-select
              v-model="queryModal.riskTypeCode"
              clearable
              placeholder="风险类别"
              @change="getRiskTypeList"
            >
              <el-option
                v-for="item of riskTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="riskSubTypeCode">
            <el-select
              v-model="queryModal.riskSubTypeCode"
              clearable
              placeholder="风险子类"
            >
              <el-option
                v-for="item of riskSonTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="riskName">
            <el-input
              v-model="queryModal.riskName"
              clearable
              placeholder="风险名称"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="riskLevelCode">
            <el-select
              v-model="queryModal.riskLevelCode"
              clearable
              placeholder="风险控制等级"
            >
              <el-option
                v-for="item of riskLevelList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="state">
            <el-select
              v-model="queryModal.queryState"
              clearable
              placeholder="审核状态"
            >
              <el-option
                v-for="item of stateList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="radio == 2">
            <el-select
              v-model="queryModal.yearQuarter"
              clearable
              placeholder="年度/季度"
            >
              <el-option
                v-for="item of yearQuarters"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button
            class="btnStyle hasLeft"
            v-if="nowUserType == '施工单位'"
            type="primary"
            @click="add"
            >新增</el-button
          >
          <el-button
            class="btnStyle"
            v-if="nowUserType == '施工单位' && radio == 1"
            type="primary"
            @click="importFn()"
            >导入</el-button
          >
          <el-button
            class="btnStyle"
            v-show="radio == 2"
            type="primary"
            @click="exportFn()"
            >导出</el-button
          >
          <el-button
            class="btnStyle"
            v-if="nowUserType == '施工单位'"
            type="primary"
            @click="apply()"
            >申请</el-button
          >
          <el-button class="btnStyle" v-else type="primary" @click="check()"
            >审批</el-button
          >
        </el-row>
      </el-form>
    </div>
    <div style="margin-bottom: 10px">
      <el-radio-group v-model="radio" @change="radioChange">
        <el-radio-button :label="1">待确定</el-radio-button>
        <el-radio-button :label="2">已确定</el-radio-button>
      </el-radio-group>
    </div>
    <div class="table-wrapper">
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          type="selection"
          width="50"
        ></el-table-column>
        <el-table-column type="index" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column
          prop="lineName"
          align="center"
          label="线路"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="branchName"
          align="center"
          label="分部"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="siteName"
          align="center"
          label="工点"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="riskTypeName"
          align="center"
          label="风险类别"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="riskSubTypeName"
          align="center"
          label="风险子类"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="riskName"
          align="center"
          label="风险名称"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span class="titleStyle" @click="riskDetail(scope.row)">{{
              scope.row.riskName
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="mileageRange"
          align="center"
          label="风险里程"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="riskDescribe"
          align="center"
          label="风险描述"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="riskLevelName"
          align="center"
          label="风险控制等级"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          v-if="radio == 2"
          prop="yearQuarter"
          align="center"
          label="年度/季度"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.yearQuarter.slice(0, 4) +
              "年度第" +
              scope.row.yearQuarter.charAt(4) +
              "季度"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="stateName"
          align="center"
          label="审核状态"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              :class="{
                titleStyle:
                  scope.row.state == 0 || scope.row.state == 7 ? false : true,
                isReject: scope.row.isRejectState,
              }"
              @click="examine(scope.row)"
              >{{ scope.row.stateName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="160">
          <template slot-scope="scope">
            <el-button
              type="text"
              :disabled="getBtnState(scope.row)"
              @click="apply(scope.row)"
              v-if="
                (nowUserType == '施工总承包单位' && radio == 1) ||
                (nowUserType == '施工单位' && radio == 1)
              "
              >申请</el-button
            >
            <el-button
              type="text"
              v-if="
                nowUserType != '施工单位' && nowUserType != '施工总承包单位'
              "
              @click="view(scope.row)"
              >查看</el-button
            >
            <el-button
              type="text"
              v-if="nowUserType != '施工单位'"
              :disabled="getBtnCheck(scope.row)"
              @click="check(scope.row)"
              >审核</el-button
            >
            <el-button
              type="text"
              :disabled="getBtnEdit(scope.row)"
              @click="edit(scope.row)"
              v-if="
                nowUserType == '施工总承包单位' || nowUserType == '施工单位'
              "
              >编辑</el-button
            >
            <el-button
              type="text"
              :disabled="scope.row.state != 0"
              @click="del(scope.row)"
              v-if="nowUserType == '施工单位' && radio == 1"
              :style="scope.row.state != 0 ? '' : 'color: red'"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        :total="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        layout=" prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      width="880px"
      :title="title"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <AddPage
        ref="addPageData"
        @CloseData="CloseData"
        :dialogType="dialogType"
        :pageData="pageData"
        :submitType="submitType"
      ></AddPage>
    </el-dialog>
    <el-dialog
      width="1200px"
      title="风险详情"
      :visible.sync="detailPageDataVisible"
      v-if="detailPageDataVisible"
      append-to-body
      :close-on-click-modal="false"
      :before-close="detailHandleClose"
    >
      <DetailPage
        ref="detailPage"
        @CloseData="detailHandleClose"
        @changeData="changeData"
        :dialogType="dialogType"
        :addPageData="pageData"
        :isShowTop="isShowTop"
      ></DetailPage>
    </el-dialog>
    <el-dialog
      width="1200px"
      title="风险清单确认过程"
      :visible.sync="examinePageDataVisible"
      v-if="examinePageDataVisible"
      append-to-body
      :close-on-click-modal="false"
      :before-close="examineHandleClose"
    >
      <ExaminePage
        ref="examinePage"
        @CloseData="examineHandleClose"
        :pageData="examinePageData"
      ></ExaminePage>
    </el-dialog>
    <el-dialog
      width="880px"
      title="审核"
      :visible.sync="checkDialogView"
      v-if="checkDialogView"
      append-to-body
      :close-on-click-modal="false"
      :before-close="checkHandleClose"
    >
      <CheckPage
        ref="checkPage"
        @CloseData="checkHandleClose"
        :pageData="checkPageData"
      ></CheckPage>
    </el-dialog>
    <el-dialog
      width="420px"
      title="导入"
      :visible.sync="importDialog"
      v-if="importDialog"
      append-to-body
      :close-on-click-modal="false"
      :before-close="importHandleClose"
    >
      <ImportPage ref="importPage" @CloseData="importHandleClose"></ImportPage>
    </el-dialog>
  </div>
</template>

<script>
import AddPage from "./modules/addPage";
import DetailPage from "./modules/detailPage";
import ExaminePage from "./modules/examinePage";
import CheckPage from "./modules/checkPage";
import ImportPage from "./modules/importPage";
// 字典
import { getDictData } from "@/api/dict";
// 查询角色列表
// import { getRoleApi } from "@/api/role";
// 列表,删除
import {
  riskLibraryList,
  riskLibraryDel,
  riskLibraryRealList,
  riskLibraryRealDel,
  riskLibraryapply,
  riskLibraryYear,
  exportList,
} from "@/api/risk";

export default {
  components: {
    AddPage,
    DetailPage,
    ExaminePage,
    CheckPage,
    ImportPage,
  },
  data() {
    return {
      stateList: [
        { value: 0, label: "待申请" },
        { value: 1, label: "施工单位申请" },
        { value: 2, label: "待监理审核" },
        { value: 3, label: "待总承包单位审核" },
        { value: 4, label: "待建管部审核" },
        { value: 5, label: "待建设公司审核" },
        { value: 6, label: "待集团审批" },
        { value: 7, label: "已通过" },
      ],
      rejectStateList: [
        { value: 1, label: "正常" },
        { value: 2, label: "监理驳回" },
        { value: 3, label: "总包驳回" },
        { value: 4, label: "建管部驳回" },
        { value: 5, label: "建设公司驳回" },
        { value: 6, label: "集团驳回" },
      ],
      yearQuarters: [],
      dialogVisible: false,
      queryModal: {
        riskTypeCode: "",
        riskSubTypeCode: "",
        riskName: "",
        riskLevelCode: "",
        state: "",
        yearQuarter: "",
        queryState: "",
      },
      tableData: [],
      tableLoading: false,
      pageLoading: false,
      title: "",
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      radio: 1,
      lineSite: [],
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true,
      },
      //新增
      submitType: "",
      pageData: {},
      gradeList: [],
      // 详情弹窗
      detailPageDataVisible: false,
      detailPageData: {},
      examinePageDataVisible: false,
      examinePageData: {},
      riskTypeList: [],
      riskSonTypeList: [],
      riskLevelList: [],
      // 表格多选
      tableSelect: [],
      tableCheck: false,

      nowUserType: "", //单位类型
      createdUserUuid: "", //登陆人uuid
      nowUserName: "", //登陆人姓名
      departmentName: "", //登陆人部门

      // 审核弹窗
      checkDialogView: false,
      checkPageData: {},

      isShowTop: false,
      dialogType: "",

      importDialog: false,
    };
  },
  created() {
    // 获取登陆人用户uuid和单位类型
    let getUserMsg = JSON.parse(localStorage.getItem("user"));
    this.nowUserType = getUserMsg.companyCategoryName;
    this.createdUserUuid = getUserMsg.uuid;
    this.nowUserName = getUserMsg.realName;
    this.departmentName = getUserMsg.departmentName;
  },
  mounted() {
    if (this.nowUserType == "施工单位") {
      this.queryModal.createdUserUuid = this.createdUserUuid;
    } else if (this.nowUserType == "施工总承包单位") {
      this.setCheck(3);
    } else if (this.nowUserType == "监理单位") {
      this.setCheck(2);
    } else if (this.nowUserType == "建设单位") {
      if (this.departmentName.includes("建设管理部")) {
        this.setCheck(4);
      } else if (this.departmentName == "建设公司") {
        this.setCheck(5);
      } else if (this.departmentName == "集团领导") {
        this.setCheck(6);
      }
    } else {
      this.queryModal.state = "";
    }
    this.queryLineSiteTreeData();
    this.getData();
    this.getDictList();
  },
  methods: {
    // 设置状态筛选
    setCheck(val) {
      this.queryModal.state = val;
      for (let i = 0; i < val; i++) {
        this.stateList.shift();
      }
    },
    changeData(data) {
      this.pageData = data;
    },
    // 字典
    getDictList() {
      // 风险类别
      getDictData("riskType").then((res) => {
        if (res.code == 0) {
          this.riskTypeList = res.data;
        }
      });
      // 风险控制等级
      getDictData("riskControlLevel").then((res) => {
        if (res.code == 0) {
          this.riskLevelList = res.data;
        }
      });
    },
    getRiskTypeList(val) {
      this.queryModal.riskSubTypeCode = "";
      // 风险子类
      getDictData(val).then((res) => {
        if (res.code == 0) {
          this.riskSonTypeList = res.data;
        }
      });
    },
    // 表格多选
    handleSelectionChange(val) {
      this.tableSelect = val.map((v) => v.riskDeafUuid);
      this.tableCheck = val.length ? true : false;
    },
    // 查询
    search() {
      this.getData();
    },
    riskDetail(row) {
      this.dialogType = this.radio == 1 ? "待确定" : "已确定";
      this.isShowTop = this.radio == 2 ? true : false;
      this.detailPageDataVisible = true;
      const rowinfo = { ...row };
      this.pageData = rowinfo;
    },
    examine(row) {
      if (row.state == 0 || row.state == 7) return;
      this.examinePageDataVisible = true;
      this.examinePageData = row;
    },
    // 级联选择器事件
    handleLineSiteChange(record) {
      console.log(record);
      this.queryModal.siteUuidList = [];
      for (let item of record) {
        this.queryModal.siteUuidList.push(item[item.length - 1]);
      }
      console.log(this.queryModal.siteUuidList);
    },
    // 线路工点树数据查询
    queryLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    // 获取列表数据
    getData() {
      this.tableLoading = true;
      let params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.queryModal,
      };

      if (this.radio == 1) {
        riskLibraryList(params).then((res) => {
          this.tableData = res.data.records;
          // 手动添加状态中文
          this.tableData.forEach((i) => {
            if (i.rejectState == 1) {
              // 代表是正常状态，返回待审核状态
              i.stateName = this.stateList.filter((j) => {
                return j.value == i.state;
              })[0].label;
              i.isRejectState = false;
            } else {
              // 代表被驳回状态，返回驳回的状态
              i.stateName = this.rejectStateList.filter((j) => {
                return j.value == i.rejectState;
              })[0].label;
              i.isRejectState = true;
            }
          });
          this.page.total = res.data.total;
          this.tableLoading = false;
        });
      } else if (this.radio == 2) {
        // 获取年度季度
        this.yearQuarters = [];
        riskLibraryYear().then((res) => {
          console.log(res);
          // this.yearQuarters
          if (res.data) {
            // 获取最早季度
            let year = res.data.slice(0, 4);
            let quarter = res.data.charAt(4);
            console.log(year, quarter);
            // 获取当年季度
            let nowYear = new Date().getFullYear();
            let nowMonth = new Date().getMonth() + 1;
            if (nowMonth > 9) {
              nowMonth = 4;
            } else if (nowMonth > 6) {
              nowMonth = 3;
            } else if (nowMonth > 3) {
              nowMonth = 2;
            } else {
              nowMonth = 1;
            }
            year = Number(year);
            quarter = Number(quarter);
            nowYear = Number(nowYear);
            nowMonth = Number(nowMonth);
            console.log(year, quarter, nowYear, nowMonth);
            while (year != nowYear || quarter <= nowMonth) {
              let obj = {};
              if (year < nowYear) {
                obj.code = year + "" + quarter;
                obj.label = `${year}年第${quarter}季度`;
                this.yearQuarters.push(obj);
                quarter++;
                if (quarter > 4) {
                  quarter = 1;
                  year++;
                }
              } else {
                obj.code = year + "" + quarter;
                obj.label = `${year}年第${quarter}季度`;
                this.yearQuarters.push(obj);
                quarter++;
              }
            }
          }
        });
        riskLibraryRealList(params).then((res) => {
          this.tableData = res.data.records;
          // 手动添加状态中文
          this.tableData.forEach((i) => {
            // 代表是正常状态，返回待审核状态
            i.stateName = "已通过";
          });
          this.page.total = res.data.total;
          this.tableLoading = false;
        });
      }
    },
    radioChange(val) {
      console.log(val);
      if (val == 2) {
        this.queryModal.state = 7;
      } else {
        if (this.nowUserType == "施工单位") {
          this.queryModal.state = "";
        } else if (this.nowUserType == "施工总承包单位") {
          this.queryModal.state = 3;
        } else if (this.nowUserType == "监理单位") {
          this.queryModal.state = 2;
        } else if (this.nowUserType == "建设单位") {
          if (this.departmentName.includes("建设管理部")) {
            this.queryModal.state = 4;
          } else if (this.departmentName == "建设公司") {
            this.queryModal.state = 5;
          } else if (this.departmentName == "集团领导") {
            this.queryModal.state = 6;
          }
        } else {
          this.queryModal.state = "";
        }
      }
      this.page.pageNo = 1;
      this.page.pageSize = 10;
      this.getData();
    },
    // 审核
    check(row) {
      let obj = {...row};
      obj.approvalName = this.nowUserName;
      obj.approvalUuid = this.createdUserUuid;
      obj.nowUserType = this.nowUserType;
      if (row) {
        obj.state = row.state;
        obj.riskDeafUuid = row.riskDeafUuid;
        obj.isAll = false;
        this.checkPageData = obj;
        this.checkDialogView = true;
      } else {
        if (this.tableSelect.length) {
          obj.isAll = true;
          if (this.nowUserType == "施工单位") {
            obj.state = 1;
          } else if (this.nowUserType == "施工总承包单位") {
            obj.state = 3;
          } else if (this.nowUserType == "监理单位") {
            obj.state = 2;
          } else if (this.nowUserType == "建设单位") {
            if (this.departmentName.includes("建设管理部")) {
              obj.state = 4;
            } else if (this.departmentName == "建设公司") {
              obj.state = 5;
            } else if (this.departmentName == "集团领导") {
              obj.state = 6;
            }
          } else {
            this.queryModal.state = 1;
          }
          this.checkDialogView = true;
          obj.riskDeafUuid = [];
          this.tableSelect.forEach((i) => {
            obj.riskDeafUuid.push(i);
          });
          this.checkPageData = obj;
        } else {
          this.$message.error("请先进行勾选");
        }
      }
    },
    // 导入
    importFn() {
      this.importDialog = true;
    },
    // 导出
    exportFn() {
      this.$confirm("确定导出风险清单库吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let obj = {
          pageNum: this.page.pageNo,
          pageSize: this.page.pageSize,
          ...this.queryModal,
        };
        exportList(obj).then((res) => {
          const aLink = document.createElement("a");
          let blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          aLink.href = URL.createObjectURL(blob);
          aLink.download = "风险清单库.xlsx";
          aLink.click();
          document.body.appendChild(aLink);
        });
      });
    },
    // 新增
    add() {
      this.title = "新增";
      this.submitType = "add";
      this.dialogVisible = true;
      this.pageData = {};
    },
    // 编辑
    edit(row) {
      this.title = "编辑";
      this.loadform("edit", row);
    },
    // 查看
    view(row) {
      this.title = "查看";
      this.loadform("view", row);
    },
    // 申请
    apply(row) {
      if (this.radio == 2) return;
      if (row) {
        // 这里是单选
        this.$confirm("确认提交该条清单确认申请吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let obj = {
            approvalUuid: row.createdUserUuid,
            riskDeafUuid: row.riskDeafUuid,
          };
          if (row.rejectState != 1) {
            obj.approverStatus = 1;
          }
          riskLibraryapply(obj).then((res) => {
            if (res.code == 0) {
              this.$message.success("申请成功");
              this.getData();
            } else {
              this.$message.error("申请失败:" + res.message);
            }
          });
        });
      } else {
        // 这里是多选
        if (this.tableSelect.length) {
          console.log("多选", this.tableSelect);
          this.$confirm(
            `共选择了${this.tableSelect.length}个风险点，确认提交清单确认申请吗？`,
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          ).then(() => {
            let reqArr = [];
            this.tableSelect.forEach((i) => {
              let obj = {
                approvalUuid: this.createdUserUuid,
                riskDeafUuid: i,
              };
              if (i.rejectState != 1) {
                obj.approverStatus = 1;
              }
              let req = riskLibraryapply(obj);

              reqArr.push(req);
              // .then((res) => {
              //   if (res.code == 0) {
              //     this.$message.success("申请成功");
              //     this.getData();
              //   } else {
              //     this.$message.success("申请失败:" + res.message);
              //   }
              // });
            });
            Promise.all(reqArr).then((res) => {
              let isTrue = res.every((i) => {
                return i.code == 0;
              });
              console.log(isTrue);
              if (isTrue) {
                this.$message.success("申请成功");
              } else {
                this.$message.error("申请失败");
              }
              this.getData();
            });
          });
        } else {
          this.$message.error("请先进行勾选");
        }
      }
    },
    // 获取申请按钮状态
    getBtnState(row) {
      if (this.nowUserType == "施工单位") {
        return (row.rejectState < 4 && row.rejectState != 1) || row.state == 0
          ? false
          : true;
      } else if (this.nowUserType == "施工总承包单位") {
        return row.rejectState > 3 && row.rejectState != 1 ? false : true;
      }
    },
    // 获取编辑按钮状态
    getBtnEdit(row) {
      if (this.nowUserType == "施工单位") {
        return (row.rejectState < 4 && row.rejectState != 1) || row.state == 0
          ? false
          : true;
      } else if (this.nowUserType == "施工总承包单位") {
        return row.rejectState > 3 && row.rejectState != 1 ? false : true;
      }
    },
    getBtnCheck(row) {
      if (this.nowUserType == "施工总承包单位") {
        return row.state == 3 && row.rejectState == 1 ? false : true;
      } else if (this.nowUserType == "监理单位") {
        return row.state == 2 ? false : true;
      } else if (
        this.nowUserType == "建设单位" &&
        this.departmentName.includes("建设管理部")
      ) {
        return row.state == 4 ? false : true;
      } else if (
        this.nowUserType == "建设单位" &&
        this.departmentName == "建设公司"
      ) {
        return row.state == 5 ? false : true;
      } else if (
        this.nowUserType == "建设单位" &&
        this.departmentName == "集团领导"
      ) {
        return row.state == 6 ? false : true;
      } else {
        return true;
      }
    },
    loadform(type, row) {
      this.dialogType = this.radio == 1 ? "待确定" : "已确定";
      this.dialogVisible = true;
      this.submitType = type;
      const rowinfo = { ...row };
      this.pageData = rowinfo;
    },
    // 删除
    del(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (this.radio == 1) {
          riskLibraryDel(row.id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("操作成功");
                this.getData();
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((res) => {
              this.$message.error(res.message);
            });
        } else if (this.radio == 2) {
          riskLibraryRealDel(row.id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("操作成功");
                this.getData();
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((res) => {
              this.$message.error(res.message);
            });
        }
      });
    },

    // 关闭回调
    CloseData() {
      this.dialogVisible = false;
      this.getData();
    },
    handleClose() {
      this.$refs.addPageData.reset();
      this.dialogVisible = false;
    },
    // 关闭回调
    detailHandleClose() {
      this.detailPageDataVisible = false;
    },
    importHandleClose() {
      this.importDialog = false;
      this.getData();
    },
    // 关闭回调
    examineHandleClose() {
      this.examinePageDataVisible = false;
    },
    checkHandleClose() {
      this.checkDialogView = false;
      this.getData();
    },
    // 分页
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 11;
}
::v-deep .el-table__body-wrapper {
  overflow: auto;
  max-height: 600px;
}

.page-container {
  .handle-wrapper {
    min-width: 240px;
    max-width: 30vw;
    height: 60vh;
    position: absolute;
    top: 10%;
    left: 20px;
    background: rgba(255, 255, 255, 1);
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
    border-radius: 0px 20px 20px 0px;
    padding: 20px;
    z-index: 6;

    .tree-wrapper {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }

    .toggle-btn {
      width: 28px;
      height: 240px;
      position: absolute;
      right: 0px;
      top: 50%;
      margin-top: -120px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }

    .toggle-btn-close {
      transform: rotate(180deg);
    }

    .toggle-btn-open {
      transform: rotate(0deg);
    }
  }

  .handle-wrapper-hide {
    left: -212px;
    background: transparent;
    box-shadow: none;
  }

  .handle-wrapper-show {
    left: 0;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #43a5e9;
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
  }
}
.btnStyle {
  margin-bottom: 22px;
}
.hasLeft {
  margin-left: 10px;
}
::v-deep .el-dialog__body {
  max-height: 700px;
  overflow: auto;
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #e6e7ec;
  text-align: center;
}

::v-deep .el-dialog__title {
  font-weight: bold;
}
.leftSearch {
  flex: 1;
}
.titleStyle {
  color: #409eff;
  cursor: pointer;
}
.isReject {
  color: #ff0000;
}
</style>
