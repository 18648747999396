<template>
  <!-- 菜单渲染 -->
  <el-container class="el-content system" ref="scaleBox">
    <el-container>
      <el-header
        class="flex items-center justify-between home-header"
        height="78px"
      >
        <div class="text-logo top-tit">
          <div style="font-size: 14px; font-weight: 400">
            {{ dataTime }}
          </div>
          <div class="flex justify-start">
            <div
              v-for="(item, index) of leftMenu"
              :key="index"
              class="mr-4 text-sm cursor-pointer top-bot"
              :class="currentKey == item.id ? 'native' : 'unnative'"
              style="font-size: 16px; font-weight: 600;"
              @click="handleMenuJump(item)"
            >
              {{ item.resourceName }}
            </div>
          </div>
          <!-- <SystemMenu :menu="leftMenu" leftMenu></SystemMenu> -->
        </div>
        <div class="flex items-center justify-center header-center">
          <div
            class="justify-center text-xl font-bold logtext"
            style="
              font-size: 34px;
              display: flex;
              flex-direction: row;
              align-items: center;
            "
          >
            <span>轨道交通工程建设安全风险管控平台</span>
          </div>
        </div>
        <div class="top-tit">
          <div
            class="mr-2"
            style="font-size: 14px; font-weight: 400; text-align: right"
          >
            您好！{{ username }}
            <span
              @click="changePassword"
              class="cursor-pointer"
              style="color: #43a5e9"
              >【 修改密码 】
            </span>
            <span
              @click="useHandBook"
              class="cursor-pointer"
              style="color: #43a5e9"
              >【 使用手册 】
            </span>
            <span @click="logouts" class="cursor-pointer" style="color: #43a5e9"
              >【 退出登录 】
            </span>
          </div>
          <div class="flex">
            <div
              v-for="(item, index) of rightMenu"
              :key="index"
              class="mr-4 text-sm cursor-pointer top-bot"
              :class="currentKey == item.id ? 'native' : 'unnative'"
              style="font-size: 16px; font-weight: 600;"
              @click="handleMenuJump(item)"
            >
              {{ item.resourceName }}
            </div>
            <div v-show="moreMenu.children.length > 0" class="cursor-pointer top-bot" @click="onShowDrop">
              <div class="relative w-full text-sm">
                <div
                  class="more-btn"
                  :class="currentKey == moreMenu.id ? 'native' : 'unnative'"
                >
                  更多
                </div>
                <ul class="more-drop" v-show="isDrop">
                  <li
                    v-for="item of moreMenu.children"
                    :key="item.id"
                    @click="handleMenuJump(item)"
                  >
                    {{ item.resourceName }}
                  </li>
                </ul>
              </div>
            </div>
            <!-- <SystemMenu :menu="rightMenu" rightMenu></SystemMenu> -->
          </div>
        </div>
      </el-header>
      <el-dialog
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="40%"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        :before-close="handleDialogCloseFile"
      >
        <div
          style="width: 100%;height:40px;background: #c2d8e9;font-weight: bold;
    font-size: 18px;
    color: #000;
    text-align: center;
    line-height: 40px;"
        >
          用户使用手册
        </div>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column type="index" width="80" label="序号" align="center">
          </el-table-column>
          <el-table-column prop="name" label="名称" width="450" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="downloadFile(scope.row)"
                >下载</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
        </div>
      </el-dialog>
      <el-container class="sys-content">
        <el-aside
          class="mr-2"
          width="180px"
          v-if="menu.length > 1 || (menu[0] && menu[0].children)"
        >
          <div class="ml-10 text-center">
            <!-- <img src="../assets/system/shouye.png" alt="" /> -->
          </div>
          <el-menu
            :router="true"
            :default-active="defaultActive"
            active-text-color="#43A5E9"
            text-color="#fff"
            background-color="transparent"
          >
            <menu-item :menuList="menu"></menu-item>
          </el-menu>
        </el-aside>
        <el-container>
          <el-main>
            <router-view />
          </el-main>
        </el-container>
      </el-container>
    </el-container>
    <el-dialog
      title="修改密码"
      :visible.sync="updateDialogPassword"
      width="30%"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :before-close="handleDialogClose"
      center
    >
      <el-form
        :rules="passwardFormRules"
        size="small"
        ref="passwardForm"
        :model="passwardForm"
      >
        <el-form-item
          label="原密码"
          prop="oldPassword"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="passwardForm.oldPassword"
            type="password"
            placeholder="请输入原密码"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="新密码"
          prop="newPassword"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="passwardForm.newPassword"
            type="password"
            placeholder="请输入新密码"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          prop="confirmPassword"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="passwardForm.confirmPassword"
            type="password"
            placeholder="请确认密码"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
    <div
      @click="onShowDrop"
      v-show="isDrop"
      class="absolute z-50 w-screen h-screen"
    ></div>
  </el-container>
</template>

<script>
import dayjs from "dayjs";
import qs from "qs";
import { logout } from "@/api/user";
import { api } from "@/services/config";
import { removeToken } from "@/utils/token";
import { removeUserInfo } from "@/utils/user";
import MenuItem from "@/components/Layout/SystemMenu.vue";
import { emergencyExpireSendMessage } from "@/api/emergency";
import { modifyPwd } from "@/api/ucenter";
// import SystemMenu from "@/components/SystemMenu/Index.vue";

export default {
  name: "Home",
  provide() {
    return {
      parentPage: this,
    };
  },
  components: { MenuItem },
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.passwardForm.newPassword !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      tableData: [
        {
          name: "系统用户用户手册",
          url: "城市轨道交通工程建设安全风险管控平台用户手册.pdf",
        },
        {
          name: "系统管理员用户手册",
          url:
            "城市轨道交通工程建设安全风险管控平台（数字三防）用户管理员pdf.pdf",
        },
        {
          name: "系统用户使用视频教程",
          url: "系统用户使用视频教程.zip",
        },
      ],
      resourceAll: [],
      resourceTree: [],
      menu: [],
      user: {},
      dataTime:
        dayjs().format("YYYY-MM-DD  ") +
        " 星期" +
        "日一二三四五六".charAt(dayjs().day()) +
        dayjs().format("  HH:mm:ss"),
      signInTime: dayjs().format("YYYY-MM-DD  "),
      native: true,
      jumpUrl: "",
      currentKey: "",
      username: JSON.parse(localStorage.user).realName,
      Styles: false,
      userData: {},
      leftMenu: [], // 一级菜单左侧
      rightMenu: [], // 一级菜单右侧
      moreMenu: {
        id: 99999,
        resourceName: '更多',
        isDrop: true,
        children: []
      },
      defaultActive: "",
      updateDialogPassword: false,
      passwardForm: {
        oldPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined,
      },
      passwardFormRules: {
        oldPassword: [
          { required: true, message: "旧密码不能为空", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { required: true, validator: equalToPassword, trigger: "blur" },
        ],
      },
      formLabelWidth: "100px",
      activeIds: [],
      isDrop: false,
    };
  },
  async created() {
    setInterval(() => {
      this.dataTime =
        dayjs().format("YYYY-MM-DD  ") +
        " 星期" +
        "日一二三四五六".charAt(dayjs().day()) +
        dayjs().format("  HH:mm:ss");
    }, 1000);
  },
  mounted() {
    // this.setScale()
    this.userData = JSON.parse(localStorage.getItem("user"));
    this.resourceAll = JSON.parse(localStorage.getItem("USER_AUTH") || '""');
    console.log("用户数据", this.userData);
    this.initResourceTree();
    this.initNavMenu();
    this.initSecMenu();
  },
  // 组件内路由守卫
  beforeRouteEnter(to, from, next) {
    console.log(to, from);
    next((vm) => {
      console.log(vm);
      if (from.path == "/Login") {
        vm.currentKey = vm.leftMenu[0].id;
        vm.handleMenuJump(vm.leftMenu[0]);
      }
    });
  },
  methods: {
    // 使用手册
    useHandBook() {
      this.dialogVisible = true;
    },
    handleDialogCloseFile() {
      this.dialogVisible = false;
    },
    downloadFile(row) {
      const link = document.createElement("a");
      link.href = process.env.BASE_URL + row.url;
      link.download = row.url.split(".")[0];
      link.target = "_blank";
      link.click();
    },
    onShowDrop() {
      this.isDrop = !this.isDrop;
    },
    //修改密码
    changePassword() {
      console.log("修改密码");
      this.updateDialogPassword = true;
    },
    handleDialogClose() {
      this.updateDialogPassword = false;
      this.resetPASSForm();
    },
    resetPASSForm() {
      this.passwardForm = {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      };
    },
    cancel() {
      this.updateDialogPassword = false;
    },
    submit() {
      this.$refs["passwardForm"].validate((valid) => {
        if (valid) {
          let params = {
            oldPwd: this.passwardForm.oldPassword,
            newPwd: this.passwardForm.newPassword,
          };
          modifyPwd(params)
            .then((res) => {
              if (res.code == 0) {
                this.$message({ message: "密码修改成功", type: "success" });
                this.updateDialogPassword = false;
                this.resetPASSForm();
                sessionStorage.clear();
                localStorage.clear();
                location.reload(); //用来刷新当前页面
              } else {
                this.$message({
                  message: "操作失败, " + res.message,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              console.log(err);
              this.$message({
                message: "操作失败, " + err.message,
                type: "error",
              });
            });
        }
      });
    },

    // 初始化一级菜单
    initNavMenu() {
      let url = JSON.parse(localStorage.getItem("FIRST_MEUN"));
      const Menu = this.resourceAll.filter((item) => item.parentId === 0);
      Menu.forEach((e) => {
        if (e.resourceName == "数据中心") {
          e.resourcePath = url.resourcePath;
        }
      });
      // const middleIndex = Math.ceil(Menu.length / 2) - 1;
      // for (let i = 0; i < Menu.length; i++) {
      //   if (i <= 4) {
      //     this.leftMenu.push(Menu[i]);
      //   } else {
      //     this.rightMenu.push(Menu[i]);
      //   }
      // }
      // console.log(this.rightMenu);
      this.leftMenu = Menu.splice(0, 5);
      const ResidueMenu = Menu;
      const MoreMenu = {
        id: 99999,
        resourceName: "更多",
        isDrop: true,
        children: [],
      };
      // 判断剩余一级菜单是否超出指定长度，是的话截取4个，超出部分放到更多里
      if (ResidueMenu.length > 5) {
        this.rightMenu = ResidueMenu.splice(0, 4);
        MoreMenu.children = ResidueMenu || [];
        // this.rightMenu.push(MoreMenu);
        this.moreMenu.children = ResidueMenu;
      } else {
        this.rightMenu = ResidueMenu;
      }
    },
    // 初始化二级菜单
    initSecMenu() {
      const path = this.$route.path;
      this.defaultActive = path;
      const tempObj = this.resourceAll.find(
        (item) => item.resourcePath == path
      );
      if (tempObj) {
        const forebears = this.findForebears(
          this.resourceAll,
          tempObj.parentId
        );
        this.currentKey = forebears.id;
        const menuData = this.resourceTree.find(
          (item) => item.id == forebears.id
        );
        if (menuData) {
          this.menu = menuData.children;
        }
      }
    },
    // 找到第一级菜单
    findForebears(resource, pId) {
      const tempObj = resource.find((item) => item.id == pId);
      if (tempObj) {
        if (tempObj.parentId == 0) {
          return tempObj;
        } else {
          return this.findForebears(resource, tempObj.parentId);
        }
      }
    },
    // 找到最后一级菜单
    findValidMenu(resource) {
      if (resource.children) {
        return this.findValidMenu(resource.children[0]);
      } else {
        return resource;
      }
    },
    // 初始化菜单树
    initResourceTree() {
      let handleResource = (resource) => {
        let parent = this.resourceAll.find((p) => p.id == resource.parentId);
        if (parent) {
          if (resource.parentId !== 0 && parent.children) {
            parent.children.push(resource);
          } else {
            parent.children = [resource];
          }
        } else {
          console.log(parent);
        }
      };
      this.resourceAll.forEach((item) => handleResource(item));
      this.resourceTree = [];
      this.resourceTree = this.resourceAll.filter(
        (item) => item.parentId === 0
      );
    },
    /**
     * 一级菜单跳转，加载二级菜单
     * @param {Object} record
     * @param {String} type
     * @param {Number} index
     */
    handleMenuJump(record) {
      this.currentKey = record.id;
      this.menu = record.children || [];
      const validMenu = this.findValidMenu(record);
      this.$router.push({
        path: validMenu.resourcePath,
      });
      this.defaultActive = validMenu.resourcePath;
      emergencyExpireSendMessage().then((res) => {
        console.log(res);
      });
    },
    changeStyle(data, url) {
      if (data == "5") {
        this.Styles = false;
      } else {
        this.Styles = true;
      }
      this.$router.push(url);
    },
    goUrl() {
      if (process.env.NODE_ENV === "development") {
        this.$router.push("/Login");
      } else {
        window.location.href = this.jumpUrl;
      }
    },
    async logout() {
      const options = {
        response_type: "code",
        scope: "api openid offline_access",
        client_id: api.clientId,
        state: 1234,
        redirect_uri: api.callbackURL,
      };
      await removeToken();
      await removeUserInfo();
      window.location.href = `${api.loginURL}/v1/connect/logout?${qs.stringify(
        options
      )}`;

      logout().then((res) => {
        if (res.code == 0) {
          this.$message.info("操作成功");
          // 移除localStorage中的token和token过期时间
          localStorage.removeItem("jwtToken");
          localStorage.removeItem("jwtTokenExpire");
          this.$router.push("/Login");
        }
      });
    },
    logouts() {
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("jwtTokenExpire");
      localStorage.removeItem("lineSiteTree");
      localStorage.removeItem("USER_AUTH");
      localStorage.removeItem("USER_BTN_AUTH");
      localStorage.removeItem("user");
      localStorage.removeItem("DetailsTree");
      this.$router.push("/Login");
    },
    setScale() {
      const scale = this.getScale();
      this.$refs.scaleBox.$el.style.transform = `scale(${scale})`;
      this.$refs.scaleBox.$el.style.transformOrigin = `0 0`;
      this.$refs.scaleBox.$el.style.width = `1920px`;
      this.$refs.scaleBox.$el.style.height = `1080px`;
    },
    getScale() {
      const width = 1920;
      const height = 1080;
      let ww = window.innerWidth / width;
      let wh = window.innerHeight / height;
      return ww < wh ? ww : wh;
    },
  },
};
</script>
<style lang="scss">
.system {
  .el-header {
    background: rgba(67, 165, 233, 0.3);
    color: #081742;

    .header-center {
      width: 40%;
      height: 70px;
      background: url("../assets/wt-bg.png") repeat 100%;
      background-size: 100% 100%;

      .logtext {
        color: #43a5e9;
      }
    }

    .more-btn {
      height: 40px;
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 600;
      line-height: 40px;
    }

    .more-drop {
      position: absolute;
      top: 40px;
      width: 100%;
      z-index: 99;
      padding-bottom: 10px;
      background-color: rgba(185, 222, 247, 1);
      li {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid #e5e5e5;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        font-family: PingFang SC;
        line-height: 40px;
      }
    }

    .native {
      color: #fff;
      background: #43a5e9;
      border: 1px solid #43a5e9;
    }

    .unnative {
      color: #081742;
      background: rgba(67, 165, 233, 0.1);
      border: 1px solid #43a5e9;
    }
  }

  .el-content-main {
    background: linear-gradient(
      180deg,
      rgba(67, 165, 233, 0.3) 0%,
      rgba(250, 250, 250, 0) 100%
    );
  }
}

.el-header {
  text-align: left;
  font-size: 18px;
  background-size: 100% 100%;
  box-shadow: 0 2px 0px #f0f1f2;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  padding: 0 10px;

  .text-logo {
    .signIn {
      width: 350px;
      height: 30px;
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      padding: 0 10px;
      font-size: 14px;

      margin-top: 10px;

      .signIn-img {
        width: 20px;
        height: 20px;
        margin: 5px 5px 5px 0;
        float: left;
      }

      .sign {
        float: right;
      }

      .signIn-right {
        color: rgb(176, 171, 171);
      }
    }

    .blacksignIn {
      background: #002e75;
    }

    .whitesignIn {
      background: none;
    }
  }

  .home-header {
    padding-bottom: 10px;
  }

  .heat-logo {
    width: 28px;
    height: 32px;
    vertical-align: middle;
    margin-right: 5px;
    margin-left: -20px;
  }

  .top-bot {
    width: 160px;
    margin-top: 10px;
    height: 40px;
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 40px;
  }

  .top-tit {
    width: 29%;
  }
}

.el-content {
  height: 100vh;
  // background: #081742;
  // background: url("../assets/center-bg.png") repeat 100%;
  background-size: 100% 100%;

  .el-main {
    padding: 10px 0;
  }

  .el-menu {
    background: #041527;
    border-right: none !important;
  }

  .asider-logo {
    background-color: #0094bf;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-img {
      display: inline-block;
      width: 100px;
    }
  }

  .el-menu-item,
  .el-submenu__title {
    // background:#041527 ;
    color: #a7adb4;

    &:hover {
      color: #fff;
      background: none;
    }
  }

  .el-menu-item.is-active {
    background-color: #1890ff;
    color: #fff;
  }

  &-main {
    padding: 10px;
    position: relative;
  }

  .el-aside {
    height: 100vh;
    background: #041527;
  }

  .el-card__body {
    padding: 15px;
  }
}
.sys-content {
  height: calc(100vh - 100px);
  background: linear-gradient(
    180deg,
    rgba(67, 165, 233, 0.3) 0%,
    rgba(250, 250, 250, 0) 100%
  );
  padding: 0 10px;
  .system-modal {
    .el-input {
      width: 80%;
    }
    .el-select {
      width: 100%;
    }
    .el-cascader {
      width: 100%;
    }
    .el-textarea {
      width: 80%;
    }
  }
  .el-menu {
    background: #ffffff !important;
    font-weight: 400;
    color: #081742;
  }
  .asider-logo {
    background-color: #0094bf;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-img {
      display: inline-block;
      width: 100px;
    }
  }
  .el-submenu .el-menu-item {
    min-width: 100px;
  }
  .el-menu-item,
  .el-submenu__title {
    color: #081742 !important;
    background: #ffffff !important;
    &:hover {
      color: #43a5e9 !important;
      background: none !important;
    }
  }
  .el-menu-item.is-active {
    color: #43a5e9 !important;
    background: #ffffff;
  }
  &-main {
    padding: 10px;
    position: relative;
  }
  .el-aside {
    height: calc(100vh - 100px);
    background: #ffffff;
    margin-top: 10px;
    padding-top: 10px;
  }
  //card标签
  .el-card {
    background-size: 100% 100%;
    border-radius: 10px;
    border: none;
  }
  ::-webkit-scrollbar {
    width: 5px; /*对垂直流动条有效*/
    height: 5px; /*对水平流动条有效*/
    display: none;
  }

  /*定义滚动条的轨道颜色、内阴影及圆角*/
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(52, 73, 85, 0.5);
    background-color: rgba(52, 73, 85, 0.5);
    border-radius: 3px;
  }

  /*定义滑块颜色、内阴影及圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
    -webkit-box-shadow: inset 0 0 6px rgba(52, 73, 85, 0.5);
    background-color: rgba(52, 73, 85, 0.5);
  }

  /*定义两端按钮的样式*/
  ::-webkit-scrollbar-button {
    background-color: rgba(52, 73, 85, 0.5);
  }

  /*定义右下角汇合处的样式*/
  ::-webkit-scrollbar-corner {
    background: rgba(52, 73, 85, 0.5);
  }
  .el-menu-item.is-active {
    background: #e6f7ff !important;
  }
  .text-center {
    cursor: pointer;
  }
}
</style>
