<template>
  <div class="response-container">
    <index-box :title="title">
      <div class="scroll-wrapper overflow-y-scroll flex flex-col justify-around">
        <div class="wring-tit">
          <span class="wring-all 2xl:text-base xl:text-sm md:text-xs">累计响应次数：{{ responseInfo.total }}</span>
          <el-divider class="2xl:ml-4 2xl:mr-4 xl:ml-2 xl:mr-2" direction="vertical"></el-divider>
          <span class="wring-new 2xl:text-base xl:text-sm md:text-xs">今日响应次数：{{ responseInfo.today }}</span>
        </div>
        <div class="weather-info flex justify-between mt-2 pl-2 pr-2">
          <span class="flex flex-col">
            <span class="info-text text-center 2xl:text-xl xl:text-base md:text-base">{{ responseInfo.floodSeason }}</span>

            <span class="info-tit 2xl:text-base xl:text-sm md:text-xs">防汛</span>
          </span>
          <el-divider direction="vertical"></el-divider>
          <span class="flex flex-col">
            <span class="info-text text-center 2xl:text-xl xl:text-base md:text-base">{{ responseInfo.typhoon }}</span>

            <span class="info-tit 2xl:text-base xl:text-sm md:text-xs">防台风</span>
          </span>
          <el-divider direction="vertical"></el-divider>
          <span class="flex flex-col">
            <span class="info-text text-center 2xl:text-xl xl:text-base md:text-base">{{ responseInfo.rain }}</span>

            <span class="info-tit 2xl:text-base xl:text-sm md:text-xs">防暴雨内涝</span>
          </span>
        </div>
      </div>
    </index-box>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import { getResDetailBySiteId } from "@/api/proof";
export default {
  name: "workResponse",
  components: {
    IndexBox,
  },
  data() {
    return {
      title: "三防应急响应统计",
      responseInfo: {
        total: 0,
        today: 0,
        typhoon: 0,
        rain: 0,
        floodSeason: 0
      }
    };
  },
  mounted() {
    this.queryDataBySiteId(this.$route.query.id);
  },
  methods: {
    queryDataBySiteId(siteId) {
      getResDetailBySiteId(siteId).then(res => {
        if (res.code == 0) {
          for (let item of res.data) {
            switch (item.type) {
              case "累计响应次数":
                this.responseInfo.total = item.count;
                break;
              case "今日响应次数":
                this.responseInfo.today = item.count;
                break;
              case "防汛":
                this.responseInfo.floodSeason = item.count;
                break;
              case "防台风":
                this.responseInfo.typhoon = item.count;
                break;
              case "防暴雨内涝":
                this.responseInfo.rain = item.count;
                break;
            }
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.response-container {
  .scroll-wrapper {
    height: 100%;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    .wring-tit {
      height: 44px;
      border-radius: 22px;
      background: #0e2553;
      text-align: center;
      line-height: 44px;
      .wring-all {
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #43a5e9;
      }
      .wring-new {
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #e23c39;
      }
      ::v-deep .el-divider--vertical {
        background: #273b65;
      }
    }
    .weather-info {
      .info-text {
        font-family: Arial;
        font-weight: bold;
        color: #ffffff;
      }
      .info-des {
        font-size: 14px;
        color: #ffffff;
      }
      .info-tit {
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.6;
      }
      ::v-deep .el-divider--vertical {
        width: 1px;
        height: 50px;
        background: #ffffff;
        opacity: 0.1;
      }
    }
  }
}
</style>