<template>
  <div>
    <div class="table-wrapper">
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        height="590"
        style="width: 100%;"
      >
        <el-table-column type="index" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column
          prop="lineName"
          align="center"
          label="线路"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="siteName"
          align="center"
          label="工点"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="hiddenQuestion"
          align="center"
          label="问题描述"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              style="color: #7073ff; cursor: pointer"
              @click="lookNamePop(scope.row)"
            >
              {{ scope.row.hiddenQuestion }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="correctiveApproveStatus"
          align="center"
          label="当前状态"
          show-overflow-tooltip
          :formatter="correctiveApproveStatusFormatter"
        >
        </el-table-column>
        <el-table-column
          prop="checkTime"
          align="center"
          label="发布时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="correctiveTime"
          align="center"
          label="问题整改时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="categoryName"
          align="center"
          label="整改单位"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
    </div>

    <!-- 问题清单 -->
    <el-dialog
      title="三防问题详情"
      :visible.sync="dialogVisible"
      append-to-body
      width="70%"
      center
    >
      <ProblemDetails
        v-if="dialogVisible"
        :hiddenData="hiddenData"
      ></ProblemDetails>
    </el-dialog>
  </div>
</template>

<script>
import { getDetails } from "@/api/response";
import ProblemDetails from "./problemDetails.vue";
export default {
  components: {
    ProblemDetails,
  },
  props: {
    parmsData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      hiddenData: {},
      tableLoading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  watch: {
    parmsData: {
      handler(val) {
        if (val) {
          this.tableData = this.parmsData;
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
  },
  methods: {
    // 当前状态
    lookNamePop(val) {
      getDetails(val.id).then((res) => {
        this.hiddenData = res.data;
      });
      this.dialogVisible = true;
    },
    // 分页
    handleCurrentChange(val) {
      this.page.pageNo = val;
    },
    correctiveApproveStatusFormatter(row) {
      return row.correctiveApproveStatus == 0
        ? "待整改"
        : row.correctiveApproveStatus == 1
        ? "待监理审批"
        : row.correctiveApproveStatus == 2
        ? "审批不通过"
        : row.correctiveApproveStatus == 3
        ? "完成"
        : "";
    },
  },
  mounted() {
    // this.tableData = this.parmsData;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 700px;
  overflow: auto;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
::v-deep .el-table:before {
  height: 0px;
}
</style>
