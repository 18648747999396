import Layout from "@/components/Layout/ScreenLayout";
import Index from "@/views/ThreeProofings/HomePage/Index.vue";
import WorkPoint from "@/views/ThreeProofings/WorkPointPage/Index.vue";
import GzWeather from "@/views/ThreeProofings/WeatherPage/index.vue";
import MonitorStatistics from "@/views/ThreeProofings/MonitorStatisticsPage/index.vue";
import MonitorVideo from "@/views/ThreeProofings/MonitorVideoPage/index.vue";
import MonitorRainfall from "@/views/ThreeProofings/MonitorRainfallPage/index.vue";
// import MonitorSWindPower from "@/views/ThreeProofings/MonitorWindPowerPage/index.vue";
import Statistics from "@/views/ThreeProofings/StatisticsPage/index.vue";
import ContingencyIndex from "@/views/Contingency/HomePage/Index.vue";
import ContingencyOutburst from "@/views/Contingency/Outburst/index.vue";
import ContingencyModelres from "@/views/Contingency/Modelres/index.vue";
import MaterialPage from "@/views/ThreeProofings/MaterialPage/index.vue";
import RescueTeam from "@/views/ThreeProofings/RescueTeam/index.vue";
import HiddenDangerIndex from "@/views/HiddenDanger/HomePage/Index.vue";
import montoring from "@/views/monitoring/index.vue"
import digging from "@/views/digging/index.vue"
import technique from "@/views/technique/index.vue"

const Routes = [
  {
    path: "ThreeProofings",
    component: Layout,
    name: "ThreeProofings",
    children: [
      {
        path: "Index",
        component: Index,
        name: "Index",
        meta: {
          title: "三防首页",
        },
      },
      {
        path: "WorkPoint",
        component: WorkPoint,
        name: "WorkPoint",
        meta: {
          title: "工点详情页",
        },
      },
      {
        path: "GzWeather",
        component: GzWeather,
        name: "GzWeather",
        meta: {
          title: "广州天气",
        },
      },
      {
        path: "Monitor",
        component: Layout,
        name: "Monitor",
        meta: {
          title: "实时监控",
        },
        children: [
          {
            path: "Statistics",
            component: MonitorStatistics,
            name: "Statistics",
            meta: {
              title: "监控统计",
            },
          },
          {
            path: "Video",
            component: MonitorVideo,
            name: "Video",
            meta: {
              title: "视频监控",
            },
          },
          {
            path: "Rainfall",
            component: MonitorRainfall,
            name: "Rainfall",
            meta: {
              title: "雨量/风力监控",
            },
          },
          // {
          //   path: "WindPower",
          //   component: MonitorSWindPower,
          //   name: "WindPower",
          //   meta: {
          //     title: "风力监控",
          //   },
          // },
        ],
      },
      {
        path: "Resource",
        component: Layout,
        name: "Resource",
        meta: {
          title: "应急资源",
        },
        children: [
          {
            path: "Material",
            component: MaterialPage,
            name: "Material",
            meta: {
              title: "物资库",
            },
          },
          {
            path: "RescueTeam",
            component: RescueTeam,
            name: "RescueTeam",
            meta: {
              title: "专职救援队",
            },
          },
        ],
      },
      {
        path: "Statistics",
        component: Statistics,
        name: "Statistics",
        meta: {
          title: "数据中心",
        },
      },
    ],
  },
  {
    path: "Contingency",
    component: Layout,
    name: "Contingency",
    children: [
      {
        path: "Index",
        component: ContingencyIndex,
        name: "Index",
        meta: {
          title: "应急首页",
        },
      },
      {
        path: "Outburst",
        component: ContingencyOutburst,
        name: "Outburst",
        meta: {
          title: "应急战时状态",
        },
      },
      {
        path: "Modelres",
        component: ContingencyModelres,
        name: "Modelres",
        meta: {
          title: "应急bm页面",
        },
      },
    ],
  },
  {
    path: "HiddenDanger",
    component: Layout,
    name: "HiddenDanger",
    children: [
      {
        path: "Index",
        component: HiddenDangerIndex,
        name: "Index",
        meta: {
          title: "风险隐患首页",
        },
      },
    ],
  },
  {
    path: "Montoring",
    component: montoring,
    name: "Montoring",
    meta: {
      title: "盾构监控首页",
    },
  },
  {
    path: "digging",
    component: digging,
    name: "digging",
    meta: {
      title: "暗挖工法",
    },
  },
  {
    path: "technique",
    component: technique,
    name: "technique",
    meta: {
      title: "明挖工法",
    },
  }
];

export default Routes;
