<template>
  <div class="material-container h-full">
    <el-form ref="formData" :model="formData" label-width="140px">
      <el-form-item label="线路分部工点" prop="siteUuid">
        <el-cascader
          style="width: 100%"
          v-model="lineSite"
          clearable
          :options="treeData"
          :props="props"
          :show-all-levels="false"
          collapse-tags
          @change="handleLineSiteChange"
          placeholder="请选择工点"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="下载模板">
        <el-button size="small" type="primary" @click="downLoad"
          >下载模板</el-button
        >
      </el-form-item>
      <el-form-item label="导入模板">
        <el-upload
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-change="changeFile"
          :on-remove="delFile"
          show-file-list
          :file-list="fileList"
          :auto-upload="false"
        >
          <el-button size="small" type="primary">导入</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <div class="dialogFooter">
      <el-button size="medium" @click="cancel">取 消</el-button>
      <el-button size="medium" type="primary" @click="submit">确 定</el-button>
    </div>
  </div>
</template>

<script>
import { riskDangerBigProjectImport } from "@/api/riskManage";
export default {
  data() {
    return {
      formData: {},
      fileList: [],

      lineSite: [],
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
      },
    };
  },
  mounted() {
    this.queryLineSiteTreeData();
  },
  methods: {
    // 线路工点树数据查询
    queryLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    // 级联选择器事件
    handleLineSiteChange(record) {
      this.formData.siteUuid = "";
      this.formData.siteUuid = record[record.length - 1];
      this.formData.branchUuid = record[1];
      this.formData.lineUuid = record[0];
      // 这时是反转的
      let siteArr = this.getParentId(this.treeData, record[record.length - 1]);
      this.formData.siteName = siteArr[0].name;
      this.formData.branchName = siteArr[1].name;
      this.formData.lineName = siteArr[2].name;
      console.log(this.formData);
    },
    downLoad() {
      const link = document.createElement("a");
      link.href = process.env.BASE_URL + "危大工程导入模板.xlsx";
      link.download = "危大工程清单库模板";
      link.target = "_blank";
      link.click();
    },
    cancel() {
      this.$emit("CloseData");
    },
    changeFile(file) {
      console.log(file);
      if (this.fileList.length) {
        this.fileList = [];
      }
      this.fileList.push(file);
    },
    delFile() {
      this.fileList = [];
    },
    submit() {
      if (!this.formData.siteName) {
        this.$message.error("请选择站点");
        return;
      }
      if (!this.fileList.length) {
        this.$message.error("请导入文件");
        return;
      }
      let obj = { ...this.formData };
      obj.importFile = this.fileList[0].raw;
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      riskDangerBigProjectImport(formData).then((res) => {
        if (res.code == 0) {
          console.log(res);
          this.cancel();
        } else {
          this.$message.error("导入失败" + res.message);
        }
      });
    },
    getParentId(list, uuid) {
      for (let i in list) {
        if (list[i].uuid == uuid) {
          return [list[i]];
        }
        if (list[i].children) {
          let node = this.getParentId(list[i].children, uuid);
          if (node !== undefined) {
            return node.concat(list[i]);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
</style>
