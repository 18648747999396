<template>
  <div class="calendar-wrapper">
    <el-calendar v-model="calendarDate">
      <template slot="dateCell" slot-scope="{ data }">
        <div
          class="w-full h-full relative p-2 calendar-item overflow-y-scroll"
          @click="handleCalendarClick($event, data)"
        >
          <div v-for="item in calendarData" :key="item.id">
            <div v-if="data.day == item.dutyTime">
              <ul>
                <li v-for="i of item.dayShiftList" :key="i.id">
                  <span class="label">白班：</span>
                  <span>{{ i.dutyWorkerName }}</span>
                </li>
                <li v-for="i of item.nightShiftList" :key="i.id">
                  <span class="label">夜班：</span>
                  <span>{{ i.dutyWorkerName }}</span>
                </li>
              </ul>
            </div>
          </div>
          <p class="date absolute right-0 top-0">
            {{ data.day.slice(-2) }}
          </p>
        </div>
      </template>
    </el-calendar>
  </div>
</template>

<script>
import dayjs from "dayjs"

export default {
  props: {
    calendarData: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      calendarDate: dayjs().format("YYYY-MM"),
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-calendar {
  .el-calendar__header {
    display: none;
  }
}
.calendar-wrapper {
  span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }

  .calendar-item {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}
</style>