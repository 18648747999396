<template>
  <div class="sys-main-wrapper flex flex-col">
    <div class="header flex">
      <ul class="info flex">
        <li class="flex flex-1 items-center justify-between p-4">
          <div class="text-center flex-1 flex flex-col justify-center">
            <h5>全部任务</h5>
            <p>{{ noticCount.taskCount }}</p>
          </div>
          <div class="icon icon1 ml-4 flex justify-center items-center">
            <img src="../../../assets/agency_4.png" alt="" />
          </div>
        </li>
        <li class="flex flex-1 items-center ml-4 justify-between p-4">
          <div class="text-center flex-1 flex flex-col justify-center">
            <h5>待处理</h5>
            <p>{{ noticCount.dealtNum }}</p>
          </div>
          <div class="icon icon2 ml-4 flex justify-center items-center">
            <img src="../../../assets/agency_2.png" alt="" />
          </div>
        </li>
        <li class="flex flex-1 items-center ml-4 justify-between p-4">
          <div class="text-center flex-1 flex flex-col justify-center">
            <h5>已完成</h5>
            <p>{{ noticCount.finishNum }}</p>
          </div>
          <div class="icon icon3 ml-4 flex justify-center items-center">
            <img src="../../../assets/agency_3.png" alt="" />
          </div>
        </li>
        <li class="flex flex-1 items-center ml-4 justify-between p-4">
          <div class="text-center flex-1 flex flex-col justify-center">
            <h5>完成率</h5>
            <p>{{ noticCount.finishRatio }}</p>
          </div>
          <div class="icon icon4 ml-4 flex justify-center items-center">
            <img src="../../../assets/agency_1.png" alt="" />
          </div>
        </li>
      </ul>
      <div class="tabs flex-1 h-full ml-20">
        <ul class="w-full h-full flex text-center">
          <li
            class="flex-1 flex flex-col justify-center items-center"
            :class="{ active: activeIndex == null }"
            @click="handleSourceChange(null)"
          >
            <div class="icon-wrapper flex justify-center items-center">
              <el-badge
                :hidden="unReadCount.total == 0"
                type="danger"
                :value="unReadCount.total"
                class="item"
              >
                <div class="icon icon1 flex justify-center items-center">
                  <img src="../../../assets/agency_8.png" alt="" />
                </div>
              </el-badge>
            </div>
            <p class="title mt-2">全部</p>
          </li>
          <li
            class="flex-1 flex flex-col justify-center items-center"
            :class="{ active: activeIndex == 0 }"
            @click="handleSourceChange(0)"
          >
            <div class="icon-wrapper flex justify-center items-center">
              <el-badge
                :hidden="unReadCount.hiddenDanger == 0"
                type="danger"
                :value="unReadCount.hiddenDanger"
                class="item"
              >
                <div class="icon icon2 flex justify-center items-center">
                  <img src="../../../assets/agency_6.png" alt="" />
                </div>
              </el-badge>
            </div>
            <p class="title mt-2">风险隐患双预控</p>
          </li>
          <li
            class="flex-1 flex flex-col justify-center items-center"
            :class="{ active: activeIndex == 1 }"
            @click="handleSourceChange(1)"
          >
            <div class="icon-wrapper flex justify-center items-center">
              <el-badge
                :hidden="unReadCount.emergency == 0"
                type="danger"
                :value="unReadCount.emergency"
                class="item"
              >
                <div class="icon icon1 flex justify-center items-center">
                  <img src="../../../assets/agency_5.png" alt="" />
                </div>
              </el-badge>
            </div>
            <p class="title mt-2">应急管理</p>
          </li>
          <li
            class="flex-1 flex flex-col justify-center items-center"
            :class="{ active: activeIndex == 2 }"
            @click="handleSourceChange(2)"
          >
            <div class="icon-wrapper flex justify-center items-center">
              <el-badge
                :hidden="unReadCount.threePrevention == 0"
                type="danger"
                :value="unReadCount.threePrevention"
                class="item"
              >
                <div class="icon icon3 flex justify-center items-center">
                  <img src="../../../assets/agency_7.png" alt="" />
                </div>
              </el-badge>
            </div>
            <p class="title mt-2">三防管理</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="search-table flex-1 mt-4 p-4 flex flex-col">
      <div class="search">
        <el-form
          :inline="true"
          :model="queryModel"
          class="flex justify-between"
        >
          <el-row>
            <el-form-item label="">
              <el-button
                :type="queryModel.noticeCategory == 0 ? 'primary' : ''"
                @click="handleTypeChange(0)"
                >告知提示
              </el-button>
              <el-button
                :type="queryModel.noticeCategory == 1 ? 'primary' : ''"
                @click="handleTypeChange(1)"
                >我的待办
              </el-button>
            </el-form-item>
            <el-form-item label="">
              <el-date-picker
                v-model="queryModel.date"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleDateRangeChange"
                value-format="yyyy-MM-dd HH:mm:ss"
                clearable
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="">
              <el-input
                v-model="queryModel.matterName"
                placeholder="输入事项关键词进行搜索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="" v-if="queryModel.noticeCategory == 1">
              <el-select
                v-model="queryModel.state"
                clearable
                placeholder="选择状态"
              >
                <el-option
                  v-for="item in stateData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">查询</el-button>
            </el-form-item>
          </el-row>
          <el-row>
            <el-button
              type="primary"
              @click="handleReadAll"
              v-if="queryModel.noticeCategory == 0"
              >一键已读</el-button
            >

            <!-- <el-form-item label="">
            <el-input
              v-model="queryModel.matterName"
              placeholder="输入事项关键词进行搜索"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
             <el-button type="primary" @click="upgrade">响应升级</el-button>
               <el-button type="primary" @click="continues">事件续报</el-button>
               <el-button type="primary" @click="conties">事件快报</el-button>
               <el-button type="primary" @click="planert">启动预案</el-button>
               <el-button type="primary" @click="disposalcard">应急处置卡</el-button>
               <el-button type="primary" @click="dismiss">预警驳回</el-button>
                <el-button type="primary" @click="early">预警查看</el-button>
                    <el-button type="primary" @click="notification">调配通知</el-button>
                    <el-button type="primary" @click="notiation">调配配送</el-button>
                    <el-button type="primary" @click="distributor">配送人通知</el-button>
                      <el-button type="primary" @click="allocate">调配驳回</el-button>
                      <el-button type="primary" @click="responsend">响应结束</el-button>
          </el-form-item> -->
          </el-row>
        </el-form>
      </div>
      <div class="table w-full flex flex-col flex-1">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          v-loading="loading"
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="date"
            label="内容"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="flex items-center">
                <i
                  v-if="queryModel.noticeCategory == 0"
                  class="inline-block state-icon"
                  :class="{ unread: scope.row.state == 0 }"
                ></i>
                <i
                  v-if="queryModel.noticeCategory == 1"
                  class="inline-block state-icon"
                  :class="{ unread: scope.row.executorStatus == 0 }"
                ></i>
                <p class="flex-1 text-left truncate">
                  {{ scope.row.noticeDescription }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="name"
            label="线路-工点/队伍名称"
            :formatter="lineSiteTeamName"
            width="500"
          >
          </el-table-column>

          <el-table-column
            align="center"
            prop="createTime"
            width="240"
            label="发起时间"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="executorStatus"
            label="状态"
            width="240"
            v-if="queryModel.noticeCategory == 1"
          >
            <template slot-scope="scope">
              {{ scope.row.executorStatus == 0 ? "待处理" : "已处理" }}
            </template>
          </el-table-column>
          <el-table-column align="center" width="240" label="操作">
            <template slot-scope="scope">
              <div class="flex justify-around">
                <el-button
                  type="primary"
                  @click="handleLaunchPlan(scope.row)"
                  v-if="scope.row.noticeCode == 1001"
                  >启动预案</el-button
                >
                <el-button
                  type="primary"
                  @click="handleDealWith(scope.row)"
                  v-if="
                    scope.row.executorStatus == 0 &&
                      queryModel.noticeCategory == 1
                  "
                  :disabled="btnLoading"
                  >立即处理</el-button
                >
                <el-button
                  @click="handleDetail(scope.row)"
                  v-if="
                    queryModel.noticeCategory == 0 ||
                      scope.row.executorStatus == 1
                  "
                  :disabled="btnLoading"
                  >查看详情</el-button
                >
              </div>
            </template>
          </el-table-column>
          <template slot="empty">
            <el-empty :image-size="100"></el-empty>
          </template>
        </el-table>

        <complet ref="completiner" @close="handlodalClose"></complet>
        <Applicat ref="application" @close="handlodalClose"></Applicat>
        <viewDils ref="viewtails" @close="handlodalClose"></viewDils>
        <Meterials ref="meteialse"></Meterials>
        <Toerdone ref="incentory"></Toerdone>
        <div class="pagination w-full flex justify-center mt-5">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="page.count"
            :page-count="page.total"
            :page-size="page.pageSize"
            :current-page="page.pageNo"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <upgrade ref="rtyuer"></upgrade>
    <component
      :is="detailComponentName"
      ref="detailComponent"
      @close="handleAsyncModalClose"
    ></component>
    <component
      :is="operationComponentName"
      ref="operationComponent"
      @close="handleAsyncModalClose"
    ></component>

    <el-dialog
      width="35%"
      title="详情"
      :visible.sync="adoptModal"
      class="editDialog"
      append-to-body
    >
      <trainAdopt v-if="adoptModal" :row-data="rowData"></trainAdopt>
    </el-dialog>

    <el-dialog
      width="35%"
      title="详情"
      :visible.sync="withdrawModal"
      class="editDialog"
      append-to-body
    >
      <planWithdraw v-if="withdrawModal" :row-data="rowData"></planWithdraw>
    </el-dialog>

    <el-dialog
      width="35%"
      title="详情"
      :visible.sync="rejectModal"
      class="editDialog"
      append-to-body
    >
      <reportReject v-if="rejectModal" :row-data="rowData"></reportReject>
    </el-dialog>

    <el-dialog
      width="35%"
      title="详情"
      :visible.sync="drillAdoptModal"
      class="editDialog"
      append-to-body
    >
      <drillAdopt v-if="drillAdoptModal" :row-data="rowData"></drillAdopt>
    </el-dialog>

    <el-dialog
      width="35%"
      title="详情"
      :visible.sync="planWithdrawModal"
      class="editDialog"
      append-to-body
    >
      <drillPlanWithdraw
        v-if="planWithdrawModal"
        :row-data="rowData"
      ></drillPlanWithdraw>
    </el-dialog>

    <el-dialog
      width="35%"
      title="详情"
      :visible.sync="reportModal"
      class="editDialog"
      append-to-body
    >
      <drillReportWithdraw
        v-if="reportModal"
        :row-data="rowData"
      ></drillReportWithdraw>
    </el-dialog>

    <el-dialog
      width="35%"
      title="详情"
      :visible.sync="reportRejectModal"
      class="editDialog"
      append-to-body
    >
      <drillReportReject
        v-if="reportRejectModal"
        :row-data="rowData"
      ></drillReportReject>
    </el-dialog>

    <el-dialog
      width="35%"
      title="详情"
      :visible.sync="pushModal"
      class="editDialog"
      append-to-body
    >
      <drillPush v-if="pushModal" :row-data="rowData"></drillPush>
    </el-dialog>

    <el-dialog
      width="1200px"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div slot="title">
        <el-tabs v-model="activeName">
          <el-tab-pane label="问题描述" name="one"></el-tab-pane>
          <el-tab-pane label="问题处置" name="two"></el-tab-pane>
          <el-tab-pane
            v-if="proState"
            label="处置验收"
            name="three"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <SetCuoshiDialog
        ref="setCuoshiDialog"
        @CloseData="handleClose"
        :titleName="activeName"
        :rowFxInfo="rowFxInfo"
        :setData="setData"
        :checkData="checkData"
        :isEdit="isEdit"
      ></SetCuoshiDialog>
    </el-dialog>
    <el-dialog
      width="880px"
      title="审核"
      :visible.sync="checkDialogView"
      v-if="checkDialogView"
      append-to-body
      :close-on-click-modal="false"
      :before-close="checkHandleClose"
    >
      <CheckPage
        ref="checkPage"
        @CloseData="checkHandleClose"
        :pageData="checkPageData"
      ></CheckPage>
    </el-dialog>

    <el-dialog
      width="70%"
      :visible.sync="releaseModal"
      class="editDialog"
      append-to-body
    >
      <releasePage
        ref="addPageData"
        v-if="releaseModal"
        :pageData="pageData"
        :submitType="submitType"
        @CloseData="CloseData"
        :gettime="gettime"
      ></releasePage>
    </el-dialog>

    <el-dialog
      width="70%"
      title="专项隐患排查"
      :visible.sync="startVisible"
      class="editDialog"
      append-to-body
    >
      <startTrouble
        v-if="startVisible"
        :type="troubleType"
        :trouble-data="troubleData"
        @cancelModal="cancelTrouble"
        @noTrouble="cancelTrouble"
      ></startTrouble>
    </el-dialog>

    <el-dialog
      width="70%"
      :visible.sync="responModal"
      class="editDialog"
      append-to-body
    >
      <respondPage
        ref="addPageData"
        v-if="responModal"
        :pageData="pageData"
        :submitType="submitType"
        @CloseData="CloseDataResponse"
        :gettime="gettime"
      ></respondPage>
    </el-dialog>

    <el-dialog
      class="editDialog"
      width="70%"
      :visible.sync="rectifyModal"
      append-to-body
      :close-on-click-modal="false"
    >
      <rectifyPage
        ref="addPageData"
        @CloseData="CloseDataRectify"
        @showFileList="rectifyModal"
        :pageData="pageData"
        :submitType="submitType"
        :gettime="gettime"
      ></rectifyPage>
    </el-dialog>

    <el-dialog
      width="70%"
      class="editDialog details-dialog"
      :visible.sync="eliminateDialog"
      v-if="eliminateDialog"
      append-to-body
    >
      <eliminatePage
        ref="addPageData"
        @CloseData="CloseDataElimina"
        :pageData="pageData"
        :submitType="submitType"
        :classes="0"
        :gettime="gettime"
      ></eliminatePage>
    </el-dialog>

    <el-dialog
      width="70%"
      :title="title"
      :visible.sync="dayVisible"
      class="editDialog"
      append-to-body
    >
      <dayRecord
        v-if="dayVisible"
        :type="'special_check'"
        where="todo"
        :title="title"
        :detail-data="detailData"
      ></dayRecord>
    </el-dialog>

    <!-- 预案的查看详情 -->
    <planDetail ref="plan"></planDetail>
    <!-- 关键节点审核 -->
    <el-dialog
      width="50%"
      class="editDialog details-dialog"
      :visible.sync="nodeAuditDialog"
      v-if="nodeAuditDialog"
      :close-on-click-modal="false"
      :before-close="CloseKeyNode"
    >
      <NodeAudit
        :active="1"
        :type="nodeCorfDetailsType"
        :rowData="nodeCurrentInfo"
        :currentState="isFinish"
        :eventId="nodeCurrentInfo.eventId"
        @submitClose="AuditClose"
      ></NodeAudit>
    </el-dialog>
    <!-- 关键节点验收 -->
    <el-dialog
      :title="keyNodeTitle"
      :visible.sync="keyNodeDialog"
      v-if="keyNodeDialog"
      :close-on-click-modal="false"
      class="editDialog details-dialog"
      :before-close="CloseKeyNode"
    >
      <nodeAccForm
        :type="nodeCorfDetailsType"
        :rowData="nodeCurrentInfo"
        :dbType="nodedbType"
        @submitClose="applyClose"
        @aaaa="loadData"
      ></nodeAccForm>
    </el-dialog>
    <!-- 关键节点验收审核(监理) -->
    <el-dialog
      :title="title"
      :visible.sync="accJLVisible"
      v-if="accJLVisible"
      class="editDialog"
      append-to-body
      :before-close="CloseKeyNode"
    >
      <!-- :isAgent="isAgent" -->
      <nodeAccFormJL
        :type="nodeCorfDetailsType"
        :rowData="nodeCurrentInfo"
        @submitClose="closeFormJL"
        @aaaa="loadData"
      ></nodeAccFormJL>
    </el-dialog>
    <!-- 预警模块相关处理及详情 -->
    <el-dialog
      width="50%"
      class="editDialog details-dialog"
      :visible.sync="warnDialogVisible"
      v-if="warnDialogVisible"
      :close-on-click-modal="false"
      :before-close="CloseKeyNode"
    >
      <warn-details
        @CloseData="CloseWarnDialog"
        :eventId="nodeCurrentInfo.eventId"
        :response="responseType"
        :audit="audit"
        :correction="correction"
        :remove="remove"
        :selected="selectedType"
        @showFileList="warnCtorlRecord"
      ></warn-details>
    </el-dialog>
    <el-dialog
      width="40%"
      title="附件列表"
      class="fileDialogCs"
      :visible.sync="warnfileDialog"
      v-if="warnfileDialog"
      style="z-index: 99999"
      :close-on-click-modal="false"
      :before-close="handlefileClose"
    >
      <el-table :data="fileData" style="width: 100%">
        <el-table-column
          align="center"
          prop="fileName"
          show-overflow-tooltip
          label="附件名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          show-overflow-tooltip
          label="操作"
          width="90"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="handleFileView(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      width="70%"
      title="风险交底"
      class="editDialog details-dialog1"
      :visible.sync="riskDisclosureDialog"
      v-if="riskDisclosureDialog"
      center
      top="10vh"
      :close-on-click-modal="false"
      :before-close="CloseRiskDia"
    >
      <BaseInfo :risk-deaf-uuid="riskCurrentInfo.riskDeafUuid"></BaseInfo>
      <div class="riskDialogTitle">风险交底</div>
      <DisclosureRisk
        :rowData="riskCurrentInfo"
        :disclosureType="disclosureType"
        @handlerCloseDia="CloseRiskDia"
      ></DisclosureRisk>
    </el-dialog>
    <el-dialog
      width="70%"
      title="风险启动"
      class="editDialog details-dialog1"
      :visible.sync="riskStartDialog"
      v-if="riskStartDialog"
      center
      top="10vh"
      :close-on-click-modal="false"
      :before-close="CloseRiskDia"
    >
      <BaseInfo :risk-deaf-uuid="riskCurrentInfo.riskDeafUuid"></BaseInfo>
      <StartRiskForm
        @closeDialog="CloseRiskDia"
        :type="disclosureType"
        :rowData="riskCurrentInfo"
        :isShowTable="isShowTable"
        :riskControlLevel="riskCurrentInfo.riskLevelCode"
      ></StartRiskForm>
    </el-dialog>
    <el-dialog
      width="50%"
      class="editDialog details-dialog1"
      :visible.sync="auditVisible"
      v-if="auditVisible"
      :close-on-click-modal="false"
      :before-close="CloseRiskDia"
    >
      <AuditDialog
        :active="AuditActive"
        :stepSx="stepSx"
        :controlLevel="riskCurrentInfo.riskLevelCode"
        :rowData="riskCurrentInfo"
        @submitClose="CloseRiskDia"
      ></AuditDialog>
      <!-- @showFileList="ShowFileDialog" -->
    </el-dialog>
    <el-dialog
      width="50%"
      class="editDialog details-dialog1"
      :visible.sync="cancelVisible"
      v-if="cancelVisible"
      :close-on-click-modal="false"
      :before-close="CloseCancel"
    >
      <RiskMitigationForm
        :row-info="rowInfo"
        :type="removeType"
        @CloseDialog="CloseCancel"
      ></RiskMitigationForm>
      <!-- @showFileList="ShowFileDialog" -->
    </el-dialog>
    <!-- 应急响应查看详情 -->
    <el-dialog
      :title="emeTitle"
      :visible.sync="emeCheckDialog"
      :before-close="emeCheckDialogClose"
      append-to-body
      width="60%"
    >
      <emeResponse
        v-if="emeCheckDialog"
        :emeTitle="emeTitle"
        :emeParams="emeParams"
        @cancelInspection="emeCheckDialogEnd"
      >
      </emeResponse>
    </el-dialog>
    <!-- 三防应急检查表-查看 -->
    <el-dialog
      title="三防应急检查表"
      :visible.sync="oneCheckDialog"
      :before-close="oneCheckDialogClose"
      append-to-body
      width="50%"
      class="checkDialog emergencyCheck"
    >
      <emergencyCheck
        v-if="oneCheckDialog"
        :operation="oneCheckTitle"
        :oneCheckData="oneCheckData"
        @cancelApproval="cancelApproval"
        @successApproval="successApproval"
      ></emergencyCheck>
    </el-dialog>
    <!-- 专职救援队查看 -->
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="rescueCheckDialog"
      :before-close="rescueCheckClose"
      append-to-body
      width="80%"
      center
    >
      <rescueCheck
        :approveid="approveid"
        :options="reseuceOptions"
        @rescueCheckClose="rescueCheckClose"
      ></rescueCheck>
    </el-dialog>
    <!-- 整改驳回 -->
    <el-dialog
      title="三防问题详情"
      :visible.sync="historyDialog"
      :before-close="historyDialogClose"
      append-to-body
      width="30%"
      class="historyDialog"
    >
      <historyPage
        v-if="historyDialog"
        :log-list="historyData"
        @historyClose="historyClose"
      ></historyPage>
    </el-dialog>
    <!-- 整改完成查看 -->
    <el-dialog
      title="三防问题详情"
      :visible.sync="checkDialogVisible"
      :close-on-click-modal="false"
      append-to-body
      width="70%"
      center
    >
      <ProblemDetails
        v-if="checkDialogVisible"
        :hiddenData="hiddenData"
      ></ProblemDetails>
    </el-dialog>
    <!-- 三防应急检查表-填报 -->
    <el-dialog
      title="三防应急检查表"
      :visible.sync="emerDialog"
      :before-close="emerDialogClose"
      append-to-body
      class="checkDialog"
      width="60%"
    >
      <emergencyWrite
        :titleOne="titleOne"
        :emerId="emerId"
        :siteUuId="siteUuId"
        @cancelInspection="cancelInspection"
      >
      </emergencyWrite>
    </el-dialog>
    <!-- 整改操作 -->
    <el-dialog
      :title="rectTitle"
      :visible.sync="emedialogVisible"
      :width="'600px'"
      :before-close="rectCloseData"
      :close-on-click-modal="false"
      append-to-bodyF
      center
    >
      <rectProblem
        v-if="emedialogVisible"
        :rectId="rectId"
        :emeTitle="rectTitle"
        @cancelInspection="rectClose"
      >
      </rectProblem>
    </el-dialog>
    <!-- 三防工作简报相关 -->
    <briefings
      ref="fillingListModal"
      @fingrest="onSearch"
      @btnClose="btnClose"
    ></briefings>
    <briefingsView
      ref="briefingsViewModal"
      @fingrest="onSearch"
      @btnClose="btnClose"
    ></briefingsView>

    <briefingsJG
      ref="fillingModal1"
      @fingrest="onSearch"
      @btnClose="btnClose"
    ></briefingsJG>
    <briefingsJGView
      ref="briefingsJGViewModal"
      @fingrest="onSearch"
      @btnClose="btnClose"
    ></briefingsJGView>
    <el-dialog
      :title="examinetitle"
      :visible.sync="examineDialog"
      width="80%"
      center
      append-to-body
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <examiners
        :reportCurrentId="reportCurrentId"
        @examieClose="examieClose"
        :terwe="terwe"
      ></examiners>
    </el-dialog>
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="checkerDialog"
      append-to-body
      width="80%"
      center
      :before-close="chexkpageClose"
    >
      <checker
        v-if="checkerDialog"
        :strery="strery"
        :approveid="approveid"
        @chexkpageClose="chexkpageClose"
      ></checker>
    </el-dialog>
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="buildDialog"
      append-to-body
      width="80%"
      center
      :before-close="buildWriteClose"
    >
      <buildwrite
        ref="child"
        :statid="statid"
        @buildWriteClose="buildWriteClose"
      ></buildwrite>
    </el-dialog>
    <!-- 专职救援队填报 -->
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="resuceWirte"
      :before-close="resuceClose"
      append-to-body
      width="80%"
      center
    >
      <rescuewrite
        ref="child"
        :statid="statid"
        @resuceClose="resuceClose"
      ></rescuewrite>
    </el-dialog>
    <el-dialog
      title="灾害损失及恢复情况上报"
      :visible.sync="reportDialog"
      width="80%"
      center
      :before-close="downResume"
    >
      <report
        @downResume="downResume"
        :currentId="currentId"
        :resubmit="resubmit"
      ></report>
    </el-dialog>
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="superyDialog"
      append-to-body
      width="80%"
      center
      :before-close="supervis"
      class="checkDialog"
    >
      <superv
        ref="supervisomodel"
        :ids="aponerid"
        :swert="swert"
        :gather="gather"
        :gatherStr="gatherStr"
        @supervis="supervis"
      ></superv>
    </el-dialog>
    <!-- 工作简报 建管 汇总提交 -->
    <el-dialog
      title="三防工作简报"
      :visible.sync="regulatorySummary"
      append-to-body
      width="1350px"
      center
      :before-close="regulatorySummaryVisible"
      v-if="regulatorySummary"
    >
      <regulatoryConstructionTZDB
        :briData="briData"
      ></regulatoryConstructionTZDB>
    </el-dialog>
    <!-- 三防工作简报 安质部长 汇总弹窗 -->
    <el-dialog
      title="汇总数据"
      :visible.sync="briQualitysDialogs"
      append-to-body
      width="1350px"
      :before-close="briQualitysDialogsVisible"
      center
      v-if="briQualitysDialogs"
    >
      <statistical
        ref="briQualitysMell"
        :qualityResponseId="briData.id"
        :TZDB="'TZDB'"
      ></statistical>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryAgencyNoticListByPage,
  queryInformNoticListByPage,
  queryNoticCount,
  queryNoticSourceCount,
  updateReadNotificationState,
  readAll,
} from "@/api/notic";

import { getProblem, getCheck } from "@/api/risk";

import { getRiskDeafUuid, getRiskDeafUuidTwo, riskDetail } from "@/api/risk";
import { riskStartpage } from "@/api/riskManage";
//交通统计表汇总查看
import superv from "@/views/ThreeProofings/System/ThreeResponse/statistical/superviso.vue";
import checker from "@/views/ThreeProofings/System/ThreeResponse/statistical/checkPage.vue";
// 统计表专职救援队
import rescueCheck from "@/views/ThreeProofings/System/ThreeResponse/rescueTeam/rescueCheck.vue";

//灾害损失填报
import report from "@/views/ThreeProofings/System/ThreeResponse/lossste/reportery.vue";
//交通统计表填报
import buildwrite from "@/views/ThreeProofings/System/ThreeResponse/statistical/buildWrite.vue";
// 专职救援队填报
import rescuewrite from "@/views/ThreeProofings/System/ThreeResponse/rescueTeam/write.vue";
//灾害损失恢复表查看
import examiners from "@/views/ThreeProofings/System/ThreeResponse/lossste/examine.vue";
// 应急响应详情
import emeResponse from "./modules/emeResponse.vue";
// 整改审批-查看
import historyPage from "@/views/ThreeProofings/System/ThreeResponse/history/historyPage.vue";
import ProblemDetails from "@/views/ThreeProofings/System/ThreeResponse/history/problemDetails.vue";

// 整改操作
import rectProblem from "@/views/ThreeProofings/System/RectifyProblem/rectProblem.vue";
// 三防-检查表-查看
import emergencyCheck from "@/views/ThreeProofings/System/ThreeResponse/emergency/emergencyCheck.vue";
// 三防-检查表
import emergencyWrite from "@/views/ThreeProofings/System/ThreeResponse/emergency/emergencyWrite.vue";
import Meterials from "./modules/materials.vue";
import Toerdone from "./modules/Inventorydone.vue";
import SetCuoshiDialog from "@/views/RiskManagement/RiskList/RiskMeasures/modules/setCuoshiDialog";
import CheckPage from "@/views/RiskManagement/RiskList/RiskListLibrary/modules/checkPage";
import nodeAccForm from "@/views/RiskManagement/System/KeyNode/KeyNodeDialog/nodeAccForm";
import nodeAccFormJL from "@/views/RiskManagement/System/KeyNode/KeyNodeDialog/nodeAccFormJL";
import NodeAudit from "@/views/RiskManagement/System/KeyNode/KeyNodeDialog/nodeAuditLog";
import keyDetails from "@/views/RiskManagement/System/KeyNode/KeyNodeDialog/keyDetails";
import warnDetails from "@/views/RiskManagement/System/WarnManagement/IssueComponent/warnDetails";
import BaseInfo from "@/views/RiskManagement/System/RiskProcess/components/BaseInfo";
import DisclosureRisk from "@/views/RiskManagement/System/RiskProcess/components/DisclosureRisk";
import StartRiskForm from "@/views/RiskManagement/System/RiskProcess/components/StartRiskForm";
import AuditDialog from "@/views/RiskManagement/System/RiskProcess/components/AuditDialog";
import BulletinDetail from "./modules/bulletinDetail.vue";
import ResponseUpgradeDetail from "./modules/responseUpgradeDetail.vue";
import DefaultDetail from "./modules/defaultDetail.vue";
import StartPlan from "./modules/startPlan.vue";
import ReportWarning from "./modules/reportWarning.vue";
import BulletinDeal from "./modules/bulletinDeal.vue";
import ResponseUpgradeDeal from "./modules/responseUpgradeDeal.vue";
import EarlyWarningDeal from "./modules/earlyWarningDeal.vue";
import ResourceAllocationDeal from "./modules/resourceAllocationDeal.vue";
import ResourceAllocationDetail from "./modules/resourceAllocationDetail.vue";
import TrainDeal from "./modules/trainDeal.vue";
import DrillDeal from "./modules/drillDeal.vue";
import upgrade from "./modules/upgrade.vue";
import complet from "./modules/completion.vue";
import Applicat from "./modules/Application.vue";
import viewDils from "./modules/viewDetails.vue";
import ReportWarningDeatils from "./modules/reportWarningDeatils.vue";
import trainAdopt from "@/views/SystemHome/Agency/modules/trainAdopt";
import planWithdraw from "@/views/SystemHome/Agency/modules/planWithdraw";
import reportReject from "@/views/SystemHome/Agency/modules/reportReject";
import drillAdopt from "@/views/SystemHome/Agency/modules/drillAdopt";
import drillPlanWithdraw from "@/views/SystemHome/Agency/modules/drillPlanWithdraw";
import drillReportWithdraw from "@/views/SystemHome/Agency/modules/drillReportWithdraw";
import drillReportReject from "@/views/SystemHome/Agency/modules/drillReportReject";
import drillPush from "@/views/SystemHome/Agency/modules/drillPush";
import trainDetail from "@/views/SystemHome/Agency/modules/trainDetail";
import drillDetail from "@/views/SystemHome/Agency/modules/drillDetail";
import EarlyWarningDetail from "./modules/earlyWarningDetail.vue";
import planDetail from "./modules/planDetail.vue";
import releasePage from "@/views/HiddenDanger/System/WoodenGovern/Settle/modules/releasePage";
import startTrouble from "@/views/HiddenDanger/System/DangerTrouble/DayTrouble/components/startTrouble";
import respondPage from "@/views/HiddenDanger/System/WoodenGovern/Settle/modules/respondPage";
import rectifyPage from "@/views/HiddenDanger/System/WoodenGovern/Settle/modules/rectifyPage";
import eliminatePage from "@/views/HiddenDanger/System/WoodenGovern/Settle/modules/eliminatePage";
import dayRecord from "@/views/HiddenDanger/System/DangerTrouble/DayTrouble/components/dayRecord";
import RiskMitigationForm from "@/views/RiskManagement/System/RiskProcess/components/RiskMitigationForm";
import { formatDate } from "@/utils/comfun";
import { dayTroubleList, libraryPass, libraryReject } from "@/api/hdanger";
import { queryDeptDetail } from "@/api/stand";

import {
  getResponseRecordById,
  getResponseRecordByHistoryId,
  operatinghistoryListNew,
  getDetails,
} from "@/api/response";

import briefings from "@/views/ThreeProofings/System/ThreeResponse/prevention/briefing.vue";
import briefingsView from "@/views/ThreeProofings/System/ThreeResponse/prevention/briefingView.vue";
import briefingsJGView from "@/views/ThreeProofings/System/ThreeResponse/prevention/briefingJGView.vue";
import briefingsJG from "@/views/ThreeProofings/System/ThreeResponse/prevention/briefingJG.vue";
import regulatorySummary from "@/views/ThreeProofings/System/ThreeResponse/prevention/regulatorySummary.vue";
import regulatoryConstructionTZDB from "@/views/ThreeProofings/System/ThreeResponse/prevention/regulatoryConstructionTZDB.vue";
import statistical from "@/views/ThreeProofings/System/ThreeResponse/prevention/statistical.vue";

export default {
  components: {
    BulletinDetail,
    ResponseUpgradeDetail,
    DefaultDetail,
    StartPlan,
    ReportWarning,
    BulletinDeal,
    ResponseUpgradeDeal,
    EarlyWarningDeal,
    ResourceAllocationDeal,
    ResourceAllocationDetail,
    TrainDeal,
    DrillDeal,
    upgrade,
    complet,
    Applicat,
    viewDils,
    ReportWarningDeatils,
    Toerdone,
    trainAdopt,
    planWithdraw,
    reportReject,
    drillAdopt,
    drillPlanWithdraw,
    drillReportWithdraw,
    drillReportReject,
    drillPush,
    trainDetail,
    drillDetail,
    EarlyWarningDetail,
    planDetail,
    SetCuoshiDialog,
    releasePage,
    startTrouble,
    respondPage,
    rectifyPage,
    eliminatePage,
    CheckPage,
    dayRecord,
    Meterials,
    nodeAccForm,
    NodeAudit,
    nodeAccFormJL,
    warnDetails,
    keyDetails,
    BaseInfo,
    DisclosureRisk,
    StartRiskForm,
    AuditDialog,
    RiskMitigationForm,
    emergencyWrite,
    emergencyCheck,
    historyPage,
    ProblemDetails,
    emeResponse,
    rectProblem,
    briefings,
    briefingsJG,
    briefingsView,
    briefingsJGView,
    examiners,
    checker,
    buildwrite,
    report,
    superv,
    rescueCheck,
    rescuewrite,
    regulatorySummary,
    regulatoryConstructionTZDB,
    statistical,
  },
  data() {
    return {
      noticCount: {
        dealtNum: 0, // 代办
        finishNum: 0, // 已完成
        finishRatio: "0%", // 完成率
        taskCount: 0, // 总任务
      },
      activeIndex: "",
      queryModel: {
        noticeCategory: 0,
        matterName: "",
        state: "",
        date: [],
        startDate: "",
        endDate: "",
      },
      stateData: [
        {
          value: 0,
          label: "待处理",
        },
        {
          value: 1,
          label: "已处理",
        },
      ],
      loading: false,
      adoptModal: false,
      withdrawModal: false,
      rejectModal: false,
      drillAdoptModal: false,
      planWithdrawModal: false,
      reportModal: false,
      reportRejectModal: false,
      pushModal: false,
      releaseModal: false,
      startVisible: false,
      responModal: false,
      rectifyModal: false,
      eliminateDialog: false,
      dayVisible: false,
      cancelVisible: false,
      // 应急响应
      emeCheckDialog: false,
      emeParams: {},
      emeTitle: "",
      // 检查表-查看
      oneCheckTitle: "",
      oneCheckData: {},
      oneCheckDialog: false,
      historyDialog: false,
      checkDialogVisible: false,
      // 整改-查看
      historyData: {},
      hiddenData: {},
      // 整改-操作
      emedialogVisible: false,
      rectTitle: "",
      rectId: "",
      // 检查表-操作
      titleOne: "",
      emerDialog: false,
      emerId: "",
      siteUuId: "",
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      tableData: [],
      unReadCount: {
        total: 0,
        emergency: 0,
        hiddenDanger: 0,
        threePrevention: 0,
      },
      detailComponentName: "",
      operationComponentName: "",
      userId: "",
      rowData: {},
      regulatorySummary: false,
      // 措施
      dialogVisible: false,
      activeName: "one",
      proState: true,
      rowFxInfo: {},
      setData: {},
      checkData: {},
      isEdit: "",
      // 审核
      checkDialogView: false,
      checkPageData: {},
      briQualitysDialogs: false,
      pageData: {},
      submitType: "",
      gettime: "",
      troubleType: "",
      title: "",
      troubleData: {},
      dayTroubleData: {},
      detailData: {},
      keyNodeDialog: false, //关键节点弹窗
      keyNodeTitle: "", //关键节点弹窗标题
      nodedbType: "", //关键节点代办类型
      nodeCurrentInfo: {},
      nodeAuditDialog: false,
      accJLVisible: false,
      warnDialogVisible: false,
      responseType: null,
      correction: null,
      audit: null,
      remove: null,
      selectedType: "",
      warnfileDialog: false,
      fileData: [],
      keyNodeData: {},
      nodeCorfDetailsType: "",
      isAgent: false,
      isFinish: 1,
      riskCurrentInfo: {},
      riskDisclosureDialog: false,
      riskStartDialog: false,
      disclosureType: "",
      removeType: "",
      isShowTable: false,
      auditVisible: false,
      AuditActive: 0, //步骤条进度
      stepSx: 1,
      rowInfo: {},
      briData: { id: 1 },
      examineDialog: false, //灾害损失通过查看详情
      reportCurrentId: "",
      terwe: "",
      examinetitle: "",
      checkerDialog: false, //广州交通运输统计表查看
      strery: {},
      approveid: {},
      reseuceOptions: "",
      buildDialog: false, //广州交通运输统计表重新提交
      rescueCheckDialog: false, //救援队查看
      resuceWirte: false, //救援队填报
      statid: {},
      reportDialog: false, //灾害损失表上报
      resubmit: "",
      currentId: "",
      superyDialog: false, //广州交通运输统计表汇总提交
      gather: false,
      gatherStr: "",
      aponerid: {},
      swert: {},
      btnLoading: false,
    };
  },
  mounted() {
    this.loadData();
    this.queryNoticCount();
    this.queryNoticSourceCount();
    const userInfo = JSON.parse(localStorage.getItem("user"));
    this.userId = userInfo.uuid;
  },
  methods: {
    regulatorySummaryVisible() {
      this.regulatorySummary = false;
      this.onSearch();
    },
    briQualitysDialogsVisible() {
      this.briQualitysDialogs = false;
      this.onSearch();
    },
    // 数据来源切换
    handleSourceChange(index) {
      this.activeIndex = index;
      this.queryModel.noticeCategory = 0;
      this.page = {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      };
      this.resetQueryForm();
      this.loadData();
    },
    // 告知/代办数据切换
    handleTypeChange(type) {
      this.queryModel.noticeCategory = type;
      this.page = {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      };
      this.resetQueryForm();
      this.loadData();
    },
    handleDateRangeChange(date) {
      if (date) {
        [this.queryModel.startDate, this.queryModel.endDate] = date;
      } else {
        [this.queryModel.startDate, this.queryModel.endDate] = ["", ""];
      }
    },
    handleSearch() {
      this.loadData();
    },
    // 一键已读
    async handleReadAll() {
      const params = {
        messageSource: this.activeIndex ? this.activeIndex : "-",
        noticeCategory: this.queryModel.noticeCategory,
      };
      const res = await readAll(params);
      if (res.code == 0) {
        this.loadData();
        this.queryNoticSourceCount();
      }
    },
    // 重置查询表单
    resetQueryForm() {
      this.queryModel.date = [];
      this.queryModel.startDate = "";
      this.queryModel.endDate = "";
      this.queryModel.matterName = "";
      this.queryModel.state = "";
    },
    teryt() {
      const params = {
        page: this.page.pageNo,
        size: this.page.pageSize,
        messageSource: this.activeIndex,
        matterName: this.queryModel.matterName,
        startDate: this.queryModel.startDate,
        endDate: this.queryModel.endDate,
      };
      this.queryInformNoticListByPage(params);
    },
    terty() {
      const params = {
        page: this.page.pageNo,
        size: this.page.pageSize,
        messageSource: this.activeIndex,
        matterName: this.queryModel.matterName,
        state: this.queryModel.state,
        startDate: this.queryModel.startDate,
        endDate: this.queryModel.endDate,
      };
      this.queryAgencyNoticListByPage(params);
    },
    async loadData() {
      this.loading = true;
      const params = {
        page: this.page.pageNo,
        size: this.page.pageSize,
        messageSource: this.activeIndex,
        matterName: this.queryModel.matterName,
        startDate: this.queryModel.startDate,
        endDate: this.queryModel.endDate,
      };
      if (this.queryModel.noticeCategory == 0) {
        // 告知
        this.queryInformNoticListByPage(params);
      } else {
        params.state = this.queryModel.state;
        this.queryAgencyNoticListByPage(params);
      }
      this.queryNoticCount();
      this.queryNoticSourceCount();
    },
    // 查询告知数据
    async queryInformNoticListByPage(params) {
      const res = await queryInformNoticListByPage(params);
      if (res.code == 0) {
        this.tableData = res.data.records;
        this.page.count = res.data.count;
        this.page.total = res.data.total;
        this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.loading = false;
      }
    },
    // 查询代办数据
    async queryAgencyNoticListByPage(params) {
      const res = await queryAgencyNoticListByPage(params);
      if (res.code == 0) {
        this.tableData = res.data.records;
        this.page.count = res.data.count;
        this.page.total = res.data.total;
        this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.loading = false;
      }
    },
    // 查询消息代办统计
    async queryNoticCount() {
      const res = await queryNoticCount();
      if (res.code == 0) {
        const { dealtNum, finishNum, finishRatio, taskCount } = res.data;
        this.noticCount.dealtNum = dealtNum || 0;
        this.noticCount.finishNum = finishNum || 0;
        this.noticCount.finishRatio = finishRatio || "0%";
        this.noticCount.taskCount = taskCount || 0;
      }
    },
    async queryNoticSourceCount() {
      const res = await queryNoticSourceCount();
      if (res.code == 0) {
        const { emergencyNum, preventionNum, riskNum } = res.data;
        this.unReadCount.emergency = emergencyNum || 0;
        this.unReadCount.threePrevention = preventionNum || 0;
        this.unReadCount.hiddenDanger = riskNum || 0;
        this.unReadCount.total = emergencyNum + preventionNum + riskNum;
      }
    },
    // 查看详情
    async handleDetail(record) {
      console.log(record);
      switch (record.noticeCode) {
        case 1001:
          this.detailComponentName = "bulletin-detail";
          this.$nextTick(() => this.$refs.detailComponent.handleDetail(record));
          break;
        case 1019:
          this.detailComponentName = "bulletin-detail";
          this.$nextTick(() => this.$refs.detailComponent.handleDetail(record));
          break;
        case 1017:
          this.detailComponentName = "report-warning-deatils";
          this.$nextTick(() => this.$refs.detailComponent.handleDetail(record));
          break;
        case 1018:
          this.detailComponentName = "ReportWarningDeatils";
          this.$nextTick(() => this.$refs.detailComponent.handleDetail(record));
          break;
        case 1021:
          // 应急预警
          this.detailComponentName = "EarlyWarningDetail";
          this.$nextTick(() => this.$refs.detailComponent.handleDetail(record));
          break;
        case 1036:
          // 响应升级
          this.detailComponentName = "ResponseUpgradeDetail";
          this.$nextTick(() => this.$refs.detailComponent.handleDetail(record));
          break;
        case 1020:
          // 响应升级
          this.detailComponentName = "ResponseUpgradeDetail";
          this.$nextTick(() => this.$refs.detailComponent.handleDetail(record));
          break;
        case 1022:
          this.detailComponentName = "ResourceAllocationDetail";
          this.$nextTick(() => this.$refs.detailComponent.handleDetail(record));
          break;
        case 1023:
        case 1024:
          this.detailComponentName = "trainDetail";
          this.$nextTick(() =>
            this.$refs.detailComponent.getDetailData(record)
          );
          break;
        case 1025:
        case 1026:
          this.detailComponentName = "drillDetail";
          this.$nextTick(() =>
            this.$refs.detailComponent.getDetailData(record)
          );
          break;
        case 1028:
          this.$nextTick(() => this.$refs.rtyuer.distributions(record));
          break;
        case 1034:
          this.$nextTick(() => this.$refs.rtyuer.notice(record));
          break;

        case 1008:
          this.$nextTick(() => this.$refs.rtyuer.dismiss(record));
          break;
        case 1007:
          this.$nextTick(() => this.$refs.rtyuer.resonseer(record));
          break;
        case 1037:
          this.$nextTick(() => this.$refs.rtyuer.response(record));
          break;
        case 1047:
          this.$nextTick(() => this.$refs.viewtails.diserts(record));
          break;
        case 1048:
          this.$nextTick(() => this.$refs.viewtails.tertst(record));
          break;
        case 1049:
          this.$nextTick(() => this.$refs.viewtails.disert(record));
          break;
        case 1052:
          this.$nextTick(() => this.$refs.incentory.rectification(record));
          break;
        case 1053:
          this.$nextTick(() => this.$refs.meteialse.complete(record));
          break;
        case 1054:
          this.$nextTick(() => this.$refs.incentory.complerete());
          break;
        case 1009:
          this.rowData = { ...record };
          this.adoptModal = true;
          break;
        case 1010:
          this.rowData = { ...record };
          this.adoptModal = true;
          break;
        case 1011:
          this.rowData = { ...record };
          this.rejectModal = true;
          break;
        case 1012:
          this.rowData = { ...record };
          this.rejectModal = true;
          break;
        case 1029:
          this.rowData = { ...record };
          this.withdrawModal = true;
          break;
        case 1030:
          this.rowData = { ...record };
          this.withdrawModal = true;
          break;
        case 1013:
        case 1014:
          this.rowData = { ...record };
          this.drillAdoptModal = true;
          break;
        case 1032:
          this.rowData = { ...record };
          this.planWithdrawModal = true;
          break;

        case 1033:
          this.rowData = { ...record };
          this.reportModal = true;
          break;

        case 1015:
        case 1016:
          this.rowData = { ...record };
          this.reportRejectModal = true;
          break;

        case 1031:
          this.rowData = { ...record };
          this.pushModal = true;
          break;

        case 1002:
          this.$nextTick(() => this.$refs.rtyuer.coinues(record));
          break;
        case 1003:
          this.$nextTick(() => this.$refs.rtyuer.conterties(record));
          break;

        case 1005:
          this.$nextTick(() => this.$refs.rtyuer.wartesrte(record));
          break;
        case 1006:
          this.$nextTick(() => this.$refs.rtyuer.warningts(record));
          break;
        case 1004:
          this.$nextTick(() => this.$refs.rtyuer.upgradets(record));
          break;
        case 1027:
          this.$nextTick(() => this.$refs.rtyuer.plart(record));
          break;
        case 1046:
          this.$nextTick(() => this.$refs.plan.handleDetail(record));
          break;
        case 1000:
          this.$nextTick(() => this.$refs.rtyuer.cards(record));
          break;
        // 发布响应
        case 2001:
          this.emeTitle = "应急响应发布详情";
          getResponseRecordById({ id: record.eventId })
            .then((res) => {
              this.emeParams = res.data;
              this.emeCheckDialog = true;
            })
            .catch((res) => {
              console.log(res);
            });
          break;
        // 响应升级
        case 2002:
          this.emeTitle = "应急响应升级详情";
          getResponseRecordByHistoryId({ id: record.eventId })
            .then((res) => {
              this.emeParams = res.data;
              this.emeCheckDialog = true;
            })
            .catch((res) => {
              console.log(res);
            });
          break;
        // 响应降级
        case 2003:
          this.emeTitle = "应急响应降级详情";
          getResponseRecordByHistoryId({ id: record.eventId })
            .then((res) => {
              this.emeParams = res.data;
              this.emeCheckDialog = true;
            })
            .catch((res) => {
              console.log(res);
            });
          break;
        // 响应结束
        case 2004:
          this.emeTitle = "应急响应结束详情";
          getResponseRecordById({ id: record.eventId })
            .then((res) => {
              this.emeParams = res.data;
              this.emeCheckDialog = true;
            })
            .catch((res) => {
              console.log(res);
            });
          break;
        // 检查表-查看
        case 2005:
          this.oneCheckTitle = "查看";
          this.oneCheckData = { ...record };
          this.oneCheckData.id = record.eventId;
          this.oneCheckDialog = true;
          break;
        // 三防工作简报-查看
        case 2007:
          this.btnLoading = true;
          this.briData.id = record.eventId;

          if (record.siteUuid) {
            // 施工填报的
            this.$refs.briefingsViewModal.handleFilling(this.briData, "read");
          } else {
            // （建管部填报的）
            this.$refs.briefingsJGViewModal.handleFilling(this.briData, "read");
          }
          break;
        //交通统计表查看
        case 2006:
          this.checkerDialog = true;
          var ser = "1";
          var val = {};
          val.id = record.eventId;
          this.strery = Object.assign({}, ser);
          this.approveid = Object.assign({}, val);
          break;
        // 检查表-审批通过
        case 2010:
          this.oneCheckTitle = "查看";
          this.oneCheckData = { ...record };
          this.oneCheckData.id = record.eventId;
          this.oneCheckDialog = true;
          break;
        //交通统计表通过
        case 2011:
          this.checkerDialog = true;
          var ser = "1";
          var val = {};
          val.id = record.eventId;
          this.strery = Object.assign({}, ser);
          this.approveid = Object.assign({}, val);
          break;
        // 三防工作简报-审批通过消息查看
        case 2012:
        case 2043:
          this.btnLoading = true;
          this.briData.id = record.eventId;

          // 施工填报的
          this.$refs.briefingsViewModal.handleFilling(this.briData, "read");
          break;
        // 交通统计表查看
        case 2016:
          this.checkerDialog = true;
          var ser = "1";
          var val = {};
          val.id = record.eventId;
          this.strery = Object.assign({}, ser);
          this.approveid = Object.assign({}, val);
          break;
        //灾害损失通过查看
        case 2013:
          this.examinetitle = "灾害损失及恢复情况详情";
          this.terwe = "查看";
          this.examineDialog = true;
          this.reportCurrentId = record.eventId;

          break;
        case 2018:
          this.examinetitle = "灾害损失及恢复情况详情";
          this.terwe = "查看";
          this.examineDialog = true;
          this.reportCurrentId = record.eventId;
          break;
        // 交通统计表查看
        case 2025:
          this.checkerDialog = true;
          var ser = "1";
          var val = {};
          val.id = record.eventId;
          this.strery = Object.assign({}, ser);
          this.approveid = Object.assign({}, val);
          break;
        // 三防工作简报查看
        case 2026:
          this.btnLoading = true;
          this.briData.id = record.eventId;
          // 施工填报的
          this.$refs.briefingsViewModal.handleFilling(this.briData, "read");
          break;
        //交通统计表审核后查看
        case 2028:
          this.checkerDialog = true;
          var ser = "1";
          var val = {};
          val.id = record.eventId;
          this.strery = Object.assign({}, ser);
          this.approveid = Object.assign({}, val);
          break;
        // 三防工作简报查看
        case 2029:
          this.btnLoading = true;
          this.briData.id = record.eventId;
          // 施工填报的
          this.$refs.briefingsViewModal.handleFilling(this.briData, "read");
          break;
        //灾害损失通过查看
        case 2030:
        case 2047:
        case 2055:
          this.reportCurrentId = record.eventId;
          this.examineDialog = true;
          this.terwe = "查看";
          this.examinetitle = "灾害损失及恢复情况详情";

          break;
        //汇总提交查看
        case 2035:
          var vals = {};
          vals.responseRecordId = record.eventId;
          this.aponerid = Object.assign({}, vals);
          var sert = [];
          sert.push("查看汇总提交");
          this.swert = Object.assign({}, sert);
          this.superyDialog = true;
          this.gatherStr = record.noticeDescription.substring(
            record.noticeDescription.lastIndexOf("第") + 1,
            record.noticeDescription.lastIndexOf("第") + 2
          );
          break;
        // case 2036:
        //   var val = {};
        //   val.id = record.eventId;
        //   this.statid = Object.assign({}, val);
        //   this.resuceWirte = true;
        //   break;
        // case 2036:
        //   var ser = "1";
        //   var val = {};
        //   val.id = record.eventId;
        //   this.approveid = Object.assign({}, val);
        //   this.btnLoading = false;
        //   this.rescueCheckDialog = true;
        //   break;
        case 2036:
        case 2040:
        case 2049:
        case 2050:
          this.rescueCheckDialog = true;
          this.reseuceOptions = "查看";
          var val = {};
          val.id = record.eventId;
          this.approveid = Object.assign({}, val);
          break;
        // 三防工作简报-审批驳回消息查看
        case 2017:
          this.btnLoading = true;
          this.briData.id = record.eventId;
          // 施工填报的
          this.$refs.briefingsViewModal.handleFilling(this.briData, "read");
          break;
        // 整改-审批通过
        case 2022:
          operatinghistoryListNew({
            incidenceId: record.eventId,
            operationType: 921,
          }).then((res) => {
            if (res.code == 0) {
              this.historyData = res.data;
              this.historyDialog = true;
            }
          });
          break;
        case 2045:
          //三防工作简报 监管去汇总总包的填报通知值班人员（安质部以及值班人员）去查看
          this.briData.id = record.eventId;
          // this.regulatorySummary = true;
          this.briQualitysDialogs = true;
          this.$refs.briQualitysMell.getTableData();
          break;
        case 2044:
        case 2052:
        case 2054:
          //三防工作简报 总包安质部长的填报通过啦
          this.briData.id = record.eventId;
          this.$refs.briefingsJGViewModal.handleFilling(this.briData, "read");
          // this.regulatorySummary = true;
          break;
        case 2053:
          //三防工作简报 其下所有总包填报完通知建管部应急模块人员去汇总
          this.briData.id = record.eventId;
          this.regulatorySummary = true;
          break;
        //整改完成,请查看
        case 2038:
        case 2023:
          getDetails(record.eventId).then((res) => {
            this.hiddenData = res.data;
          });
          this.checkDialogVisible = true;
          break;
        // 检查表
        case 2024:
          this.oneCheckTitle = "查看";
          this.oneCheckData = { ...record };
          this.oneCheckData.id = record.eventId;
          this.oneCheckDialog = true;
          break;
        // 检查表
        case 2027:
          this.oneCheckTitle = "查看";
          this.oneCheckData = { ...record };
          this.oneCheckData.id = record.eventId;
          this.oneCheckDialog = true;
          break;
        case 2032:
        case 2033:
        case 2034:
          operatinghistoryListNew({
            incidenceId: record.eventId,
            operationType: 921,
          }).then((res) => {
            if (res.code == 0) {
              this.historyData = res.data;
              this.historyDialog = true;
            }
          });
          break;
        case 2051:
          this.gatherStr = record.noticeDescription.substring(
            record.noticeDescription.lastIndexOf("第") + 1,
            record.noticeDescription.lastIndexOf("第") + 2
          );
          var vals = {};
          vals.responseRecordId = record.eventId;
          this.aponerid = Object.assign({}, vals);
          var sert = [];
          sert.push("汇总提交");
          this.swert = Object.assign({}, sert);
          this.superyDialog = true;
          this.gather = true;
          break;
        case 4021:
          getRiskDeafUuid(record.eventId).then((res) => {
            console.log(res.data);
            if (res.data.problemStatus == 0) {
              this.proState = false;
            }
            this.isEdit = "view";
            this.activeName = "one";
            this.rowFxInfo = res.data;
            this.getDetailMsg(res.data.id);
          });
          break;
        case 4022:
          getRiskDeafUuid(record.eventId).then((res) => {
            console.log(res.data);
            if (res.data.problemStatus == 0) {
              this.proState = false;
            }
            this.isEdit = "view";
            this.activeName = "one";
            this.rowFxInfo = res.data;
            this.getDetailMsg(res.data.id);
          });
          break;
        case 4023:
          getRiskDeafUuid(record.eventId).then((res) => {
            console.log(res.data);
            if (res.data.problemStatus == 0) {
              this.proState = false;
            }
            this.isEdit = "view";
            this.activeName = "one";
            this.rowFxInfo = res.data;
            this.getDetailMsg(res.data.id);
          });
          break;
        case 4024:
          this.nodeCurrentInfo = { ...record };
          this.nodeAuditDialog = true;
          this.isFinish = 2;
          this.nodeCorfDetailsType = "view";
          break;
        case 4026:
          this.nodeCurrentInfo = { ...record };
          this.accJLVisible = true;
          this.isAgent = true;
          this.nodeCorfDetailsType = "view";
          break;
        case 4027:
          this.nodeCurrentInfo = { ...record };
          this.keyNodeDialog = true;
          this.nodedbType = "申请";
          this.nodeCorfDetailsType = "view";
          break;
        case 4039:
          riskDetail(record.eventId).then((res) => {
            console.log(res.data);
            this.rowInfo = res.data;
            this.removeType = "detail";
            this.rowInfo.closeState = 1;
          });
          this.cancelVisible = true;
          break;
        case 4040:
          // 节点整改审核
          riskDetail(record.eventId).then((res) => {
            console.log(res.data);
            this.removeType = "detail";
            this.rowInfo = res.data;
          });
          this.cancelVisible = true;
          break;
        case 4041:
          // 节点整改审核
          riskDetail(record.eventId).then((res) => {
            console.log(res.data);
            this.removeType = "detail";
            this.rowInfo = res.data;
            this.rowInfo.closeState = 3;
          });
          this.cancelVisible = true;
          break;
        case 4037:
          // 节点确认整改
          this.nodeCurrentInfo = { ...record };
          this.keyNodeDialog = true;
          this.nodedbType = "整改";
          this.nodeCorfDetailsType = "view";
          break;
        case 4038:
          // 节点整改审核
          this.nodeCurrentInfo = { ...record };
          this.accJLVisible = true;
          this.isAgent = true;
          this.nodeCorfDetailsType = "view";
          break;
        case 4032:
          // 预警响应
          this.warnDialogVisible = true;
          this.nodeCurrentInfo = { ...record };
          this.responseType = 2;
          this.selectedType = "预警响应";
          break;
        case 4033:
          // 预警整改
          this.warnDialogVisible = true;
          this.nodeCurrentInfo = { ...record };
          this.responseType = 2;
          this.correction = 2;
          this.selectedType = "预警整改";
          break;
        case 4034:
          // 预警审核
          this.warnDialogVisible = true;
          this.nodeCurrentInfo = { ...record };
          this.responseType = 2;
          this.correction = 2;
          this.audit = 2;
          this.selectedType = "预警审核";
          break;
        case 4035:
          // 预警驳回整改
          this.warnDialogVisible = true;
          this.nodeCurrentInfo = { ...record };
          this.correction = 2;
          this.responseType = 2;
          this.selectedType = "预警整改";
          break;
        case 4036:
          // 预警消除
          this.warnDialogVisible = true;
          this.nodeCurrentInfo = { ...record };
          this.correction = 2;
          this.responseType = 2;
          this.audit = 2;
          this.remove = 2;
          this.selectedType = "预警消除";
          break;
        case 4016:
          // 风险交底
          riskDetail(record.eventId).then((res) => {
            if (res.code == 0) {
              this.riskDisclosureDialog = true;
              this.disclosureType = "view";
              this.riskCurrentInfo = { ...res.data };
            }
          });
          break;
        case 4017:
          // 风险启动
          riskDetail(record.eventId).then((res) => {
            if (res.code == 0) {
              this.riskStartDialog = true;
              this.disclosureType = "view";
              this.riskCurrentInfo = { ...res.data };
              this.isShowTable = true;
            }
          });
          break;
        case 4018:
          // 风险审核
          riskDetail(record.eventId).then((res) => {
            const that = this;
            if (res.code == 0) {
              riskStartpage({
                riskName: res.data.riskName,
                pageSize: 100,
                pageNum: 1,
              }).then((vm) => {
                if (vm.code == 0) {
                  vm.data.list.forEach((el) => {
                    if (el.riskDeafUuid == res.data.riskDeafUuid) {
                      that.auditVisible = true;
                      that.getActive(el.processStatus);
                      that.AuditActive = 2;
                      that.riskCurrentInfo = { ...el };
                    }
                  });
                }
              });
            }
          });
          break;
        case 4019:
          // 风险启动驳回
          riskDetail(record.eventId).then((res) => {
            if (res.code == 0) {
              this.riskStartDialog = true;
              this.disclosureType = "view";
              this.riskCurrentInfo = { ...res.data };
              this.isShowTable = false;
            }
          });
          break;
        case 3008:
          var userInfo = JSON.parse(localStorage.getItem("user"));
          this.title =
            userInfo.companyCategoryName + formatDate("YY-MM-DD") + "专项排查";
          this.detailData = { ...record };
          this.detailData.id = this.detailData.eventId;
          this.dayVisible = true;
          break;
        case 3004:
          this.submitType = "detail";
          this.gettime = formatDate("YY-MM-DD hh:mm:ss");
          this.pageData = { ...record };
          this.pageData.id = this.pageData.eventId;
          this.responModal = true;
          break;
        case 3010:
          this.submitType = "view";
          this.gettime = formatDate("YY-MM-DD hh:mm:ss");
          this.pageData = { ...record };
          this.pageData.id = this.pageData.eventId;
          this.pageData.screeningType = "special_check";
          this.releaseModal = true;
          break;
        case 3005:
          this.submitType = "view";
          this.gettime = formatDate("YY-MM-DD hh:mm:ss");
          this.pageData = { ...record };
          this.pageData.id = this.pageData.eventId;
          this.rectifyModal = true;
          break;
        case 3006:
          this.submitType = "view";
          this.gettime = formatDate("YY-MM-DD hh:mm:ss");
          this.pageData = { ...record };
          this.pageData.id = this.pageData.eventId;
          this.eliminateDialog = true;
          break;
        default:
          this.detailComponentName = "default-detail";
          this.$nextTick(() => this.$refs.detailComponent.handleDetail(record));
          break;
      }
      const params = {
        messageId: record.id,
        userId: this.userId,
      };
      if (record.state != 1) {
        let res = await updateReadNotificationState(params);
        if (res.code == 0) {
          this.$nextTick(() => this.$refs.detailComponent.handleDetail(record));
        }
      }
    },
    emeCheckDialogEnd() {
      this.emeCheckDialog = false;
      this.loadData();
    },
    // 检查表填报-关闭
    cancelInspection() {
      this.emerDialog = false;
      this.loadData();
    },
    // 检查表查看/审核-关闭
    successApproval() {
      this.oneCheckDialog = false;
      this.loadData();
    },
    cancelApproval() {
      this.oneCheckDialog = false;
      this.loadData();
    },
    historyClose() {
      this.historyDialog = false;
      this.loadData();
    },
    // 整改操作-关闭
    rectClose() {
      this.emedialogVisible = false;
      this.loadData();
    },
    warnCtorlRecord(obj) {
      this.warnfileDialog = true;
      this.fileData = obj.riskWarnFileList;
    },
    handlefileClose() {
      this.warnfileDialog = false;
    },
    handleAsyncModalClose() {
      this.loadData();
      this.queryNoticSourceCount();
      this.detailComponentName = "";
      this.operationComponentName = "";
    },
    handlodalClose() {
      this.loadData();
    },
    // 启动预案
    handleLaunchPlan(record) {
      this.operationComponentName = "StartPlan";
      this.$nextTick(() => this.$refs.operationComponent.handleOpen(record));
    },
    // 立即处理
    async handleDealWith(record) {
      console.log(record);
      switch (record.noticeCode) {
        case 1017:
          // 一键报警（App）
          this.operationComponentName = "ReportWarning";
          break;
        case 1018:
          // 一键报警（物理报警）
          this.operationComponentName = "ReportWarning";
          break;
        case 1019:
          // 快报处理
          this.operationComponentName = "BulletinDeal";
          break;
        case 1020:
          // 响应升级
          this.operationComponentName = "ResponseUpgradeDeal";
          break;
        case 1036:
          // 响应升级
          this.operationComponentName = "ResponseUpgradeDeal";
          break;
        case 1021:
          // 应急预警
          this.operationComponentName = "EarlyWarningDeal";
          break;
        case 1022:
          // 资源调配
          this.operationComponentName = "ResourceAllocationDeal";
          break;
        case 1023:
          // 应急培训审核计划
          this.operationComponentName = "TrainDeal";
          break;
        case 1024:
          // 应急培训审核报告
          this.operationComponentName = "TrainDeal";
          break;
        case 1025:
          // 应急演练审核报告
          this.operationComponentName = "DrillDeal";
          break;
        case 1026:
          // 应急演练审核报告
          this.operationComponentName = "DrillDeal";
          break;
        case 1037:
          this.$nextTick(() => this.$refs.completiner.report(record));
          break;
        case 1047:
          this.$nextTick(() => this.$refs.application.report(record));
          break;
        case 1051:
          this.$nextTick(() => this.$refs.incentory.auditerts(record));
          break;
        case 1050:
          this.$nextTick(() => this.$refs.incentory.besconunt(record));
          break;
        case 1055:
          this.$nextTick(() => this.$refs.incentory.complerete());
          break;
        case 1035:
          this.$nextTick(() => this.$refs.rtyuer.ployment(record));
          break;
        // 检查表-审批驳回
        case 2015:
          this.emerId = record.eventId;
          this.siteUuId = record.siteUuid;
          this.emerDialog = true;
          this.titleOne = "填报";
          break;
        // 三防工作简报-填报
        case 2017:
          this.btnLoading = true;
          this.briData.id = record.eventId;
          if (record.siteUuid) {
            // 施工填报
            this.$refs.fillingListModal.handleFilling(this.briData);
          } else {
            // （建管部填报）
            this.$refs.fillingModal1.handleFilling(this.briData);
          }
          break;
        // 整改-审批驳回
        case 2023:
          this.rectTitle = "整改";
          this.rectId = record.eventId;
          this.emedialogVisible = true;
          break;
        // 灾害损失表填报提交
        case 2025:
          var val = {};
          val.id = record.eventId;
          this.statid = Object.assign({}, val);
          this.buildDialog = true;
          break;
        case 2036:
        case 2050:
          var val = {};
          val.id = record.eventId;
          this.statid = Object.assign({}, val);
          this.resuceWirte = true;
          break;
        // 交通统计表重新提交
        case 2016:
          var val = {};
          val.id = record.eventId;
          this.statid = Object.assign({}, val);
          this.buildDialog = true;
          break;
        // 灾害损失表重新提交
        case 2018:
          this.$nextTick(() => {
            this.reportDialog = true;
            this.resubmit = "重新填报";
            this.currentId = record.eventId;
          });
          break;
        case 2055:
          this.reportDialog = true;
          this.resubmit = "未填报";
          this.currentId = record.eventId;
          break;
        // 检查表
        case 2024:
          this.$nextTick(() => {
            this.emerId = record.eventId;
            this.siteUuId = record.siteUuid;
            this.emerDialog = true;
            this.titleOne = "填报";
          });
          break;
        // 三防工作简报-填报
        case 2026:
          this.btnLoading = true;
          this.briData.id = record.eventId;
          if (record.siteUuid) {
            // 施工填报
            this.$refs.fillingListModal.handleFilling(this.briData);
          } else {
            // （建管部填报）
            this.$refs.fillingModal1.handleFilling(this.briData);
          }
          break;
        case 2052:
        case 2054:
          this.btnLoading = true;
          this.briData.id = record.eventId;
          this.$refs.fillingModal1.handleFilling(this.briData);
          break;
        // case 2054:
        //   this.btnLoading = true;
        //   this.briData.id = record.eventId;
        //   this.$refs.fillingModal1.handleFilling(this.briData);
        //   break;
        //交通统计表审核
        case 2028:
          this.checkerDialog = true;
          var ser = "approve";
          var val = {};
          val.id = record.eventId;
          this.strery = Object.assign({}, ser);
          this.approveid = Object.assign({}, val);
          break;
        //交通统计表-救援队-审核
        case 2049:
          this.rescueCheckDialog = true;
          var val = {};
          val.id = record.eventId;
          this.reseuceOptions = "审批";
          this.approveid = Object.assign({}, val);
          break;
        // 审核
        case 2027:
          this.oneCheckTitle = "审批";
          this.oneCheckData = { ...record };
          this.oneCheckData.id = record.eventId;
          this.oneCheckDialog = true;
          break;
        // 三防工作简报-审批
        case 2029:
          this.btnLoading = true;
          this.briData.id = record.eventId;
          // 施工填报
          this.$refs.briefingsViewModal.handleFilling(this.briData, "2029");
          break;
        // 整改
        case 2032:
          this.rectTitle = "整改";
          this.rectId = record.eventId;
          this.emedialogVisible = true;
          break;
        // 整改
        case 2034:
          this.rectTitle = "整改";
          this.rectId = record.eventId;
          this.emedialogVisible = true;
          break;
        // 审批
        case 2033:
          this.rectTitle = "审批";
          this.rectId = record.eventId;
          this.emedialogVisible = true;
          break;
        //灾害损失恢复表审核
        case 2030:
          this.reportCurrentId = record.eventId;
          this.examineDialog = true;
          this.terwe = "审批";
          this.examinetitle = "灾害损失及恢复情况审批";

          break;
        case 4021:
          getRiskDeafUuid(record.eventId).then((res) => {
            console.log(res.data);
            if (res.data.problemStatus == 0) {
              this.proState = false;
            }
            this.isEdit = "edit";
            this.activeName = "two";
            this.rowFxInfo = res.data;
            this.getDetailMsg(res.data.id);
          });
          break;
        case 4022:
          getRiskDeafUuid(record.eventId).then((res) => {
            console.log(res.data);
            if (res.data.problemStatus == 0) {
              this.proState = false;
            }
            this.isEdit = "check";
            this.activeName = "three";
            this.rowFxInfo = res.data;
            this.getDetailMsg(res.data.id);
          });
          break;
        case 4023:
          getRiskDeafUuid(record.eventId).then((res) => {
            console.log(res.data);
            if (res.data.problemStatus == 0) {
              this.proState = false;
            }
            this.isEdit = "edit";
            this.activeName = "two";
            this.rowFxInfo = res.data;
            this.getDetailMsg(res.data.id);
          });
          break;
        case 4024:
          this.nodeCurrentInfo = { ...record };
          this.nodeAuditDialog = true;
          this.isFinish = 1;
          this.nodeCorfDetailsType = "";
          // ss
          break;
        case 4026:
          this.nodeCurrentInfo = { ...record };
          this.accJLVisible = true;
          this.isAgent = false;
          this.nodeCorfDetailsType = "event";
          // ss
          break;
        case 4027:
          // ss
          this.nodeCurrentInfo = { ...record };
          this.keyNodeDialog = true;
          this.nodedbType = "申请";
          break;
        case 4037:
          // 节点确认整改
          this.nodeCurrentInfo = { ...record };
          this.keyNodeDialog = true;
          this.nodedbType = "整改";
          break;
        case 4038:
          // 节点整改审核
          this.nodeCurrentInfo = { ...record };
          this.accJLVisible = true;
          this.isAgent = false;
          this.nodeCorfDetailsType = "event";
          // this.nodeCurrentInfo = { ...record };
          // this.nodeAuditDialog = true;
          // this.isFinish = 1;
          // this.nodeCorfDetailsType = "";
          break;
        case 4039:
          riskDetail(record.eventId).then((res) => {
            console.log(res.data);
            this.rowInfo = res.data;
            this.removeType = "add";
            this.rowInfo.closeState = 1;
          });
          this.cancelVisible = true;
          break;
        case 4040:
          // 节点整改审核
          riskDetail(record.eventId).then((res) => {
            console.log(res.data);
            this.removeType = "add";
            this.rowInfo = res.data;
          });
          this.cancelVisible = true;
          break;
        case 4041:
          // 节点整改审核
          riskDetail(record.eventId).then((res) => {
            console.log(res.data);
            this.removeType = "add";
            this.rowInfo = res.data;
            this.rowInfo.closeState = 3;
          });
          this.cancelVisible = true;
          break;
        case 4032:
          // 预警响应
          this.warnDialogVisible = true;
          this.nodeCurrentInfo = { ...record };
          this.responseType = 1;
          this.selectedType = "预警响应";
          break;
        case 4033:
          // 预警整改
          this.warnDialogVisible = true;
          this.nodeCurrentInfo = { ...record };
          this.responseType = 2;
          this.correction = 1;
          this.selectedType = "预警整改";
          break;
        case 4034:
          // 预警审核
          this.warnDialogVisible = true;
          this.nodeCurrentInfo = { ...record };
          this.responseType = 2;
          this.correction = 2;
          this.audit = 1;
          this.selectedType = "预警审核";
          break;
        case 4035:
          // 预警驳回整改
          this.warnDialogVisible = true;
          this.nodeCurrentInfo = { ...record };
          this.responseType = 2;
          this.correction = 1;
          this.selectedType = "预警整改";
          break;
        case 4036:
          // 预警消除
          this.warnDialogVisible = true;
          this.nodeCurrentInfo = { ...record };
          this.responseType = 2;
          this.correction = 2;
          this.audit = 2;
          this.remove = 1;
          this.selectedType = "预警消除";
          break;
        case 4016:
          // 风险交底
          riskDetail(record.eventId).then((res) => {
            if (res.code == 0) {
              this.riskDisclosureDialog = true;
              this.disclosureType = "edit";
              this.riskCurrentInfo = { ...res.data };
            }
          });
          break;
        case 4017:
          // 风险启动
          riskDetail(record.eventId).then((res) => {
            if (res.code == 0) {
              res.data["processStatus"] = 1;
              this.riskStartDialog = true;
              this.disclosureType = "edit";
              this.riskCurrentInfo = { ...res.data };
              this.isShowTable = true;
            }
          });
          break;
        case 4018:
          // 风险审核
          riskDetail(record.eventId).then((res) => {
            if (res.code == 0) {
              riskStartpage({
                riskName: res.data.riskName,
                pageSize: 100,
                pageNum: 1,
              }).then((vm) => {
                const that = this;
                if (vm.code == 0) {
                  vm.data.list.forEach((el) => {
                    if (el.riskDeafUuid == res.data.riskDeafUuid) {
                      that.auditVisible = true;
                      that.getActive(el.processStatus);
                      that.AuditActive = 1;
                      that.riskCurrentInfo = { ...el };
                    }
                  });
                }
              });
            }
          });
          break;
        case 4019:
          // 风险启动驳回
          riskDetail(record.eventId).then((res) => {
            if (res.code == 0) {
              res.data["processStatus"] = 4; //不管是哪家单位驳回，施工单位重新提交申请填写的内容一样；这里默认取一个驳回状态
              // riskStartpage
              this.riskStartDialog = true;
              this.disclosureType = "edit";
              this.riskCurrentInfo = { ...res.data };
              this.isShowTable = false;
            }
          });
          break;
        case 4014:
          getRiskDeafUuidTwo(record.eventId).then((res) => {
            console.log(res.data);
            let obj = {};
            let getUserMsg = JSON.parse(localStorage.getItem("user"));
            let nowUserType = getUserMsg.companyCategoryName;
            let createdUserUuid = getUserMsg.uuid;
            let nowUserName = getUserMsg.realName;
            obj.approvalName = nowUserName;
            obj.approvalUuid = createdUserUuid;
            obj.nowUserType = nowUserType;
            obj.state = res.data.state;
            obj.riskDeafUuid = res.data.riskDeafUuid;
            obj.isAll = false;
            this.checkPageData = obj;
            console.log(obj);
            this.checkDialogView = true;
          });
          break;
        case 3010:
          this.submitType = "abarbeit";
          this.gettime = formatDate("YY-MM-DD hh:mm:ss");
          this.pageData = { ...record };
          this.pageData.id = this.pageData.eventId;
          this.releaseModal = true;
          break;
        case 3004:
          this.submitType = "respond";
          this.gettime = formatDate("YY-MM-DD hh:mm:ss");
          this.pageData = { ...record };
          this.pageData.id = this.pageData.eventId;
          this.responModal = true;
          break;
        case 3005:
          this.submitType = "abarbeit";
          this.gettime = formatDate("YY-MM-DD hh:mm:ss");
          this.pageData = { ...record };
          this.pageData.id = this.pageData.eventId;
          this.rectifyModal = true;
          break;
        case 3006:
          this.submitType = "abarbeit";
          this.gettime = formatDate("YY-MM-DD hh:mm:ss");
          this.pageData = { ...record };
          this.pageData.id = this.pageData.eventId;
          this.eliminateDialog = true;
          break;
        /*  case 3002 :
          let params = {
            workAreaUuid : []
          }
          let dayTroubleData = [];
          dayTroubleList(params).then(res => {
            dayTroubleData = res.data;
            this.dayTroubleData = dayTroubleData.filter(item => {
              return record.eventId == item.id && record.siteUuid == item.workAreaUuid;
            })[0]
          })
          this.troubleType = '日常排查';
          this.troubleData = this.dayTroubleData;
          this.startVisible = true;
          console.log(this.dayTroubleData)
          break;*/
        case 3008:
          this.troubleData = { ...record };
          this.troubleType = "专项排查";
          this.troubleData.id = this.troubleData.eventId;
          this.startVisible = true;
          break;
        case 3012:
          this.$confirm("此操作将审批条目建议, 是否继续?", "提示", {
            confirmButtonText: "通过",
            cancelButtonText: "驳回",
            type: "warning",
            // showClose: false, //是否显示右上角的x
            // closeOnClickModal: false, //是否可以点击空白处关闭弹窗
          })
            .then(() => {
              libraryPass(record.eventId).then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.loadData();
                } else {
                  this.$message.error(res.msg);
                }
              });
            })
            .catch(() => {
              libraryReject(record.eventId).then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.loadData();
                } else {
                  this.$message.error(res.msg);
                }
              });
            });
          break;
        default:
          this.operationComponentName = "";
          break;
      }
      const params = {
        messageId: record.id,
        userId: this.userId,
      };
      if (record.state != 1) {
        let res = await updateReadNotificationState(params);
        if (res.code == 0) {
          this.$nextTick(() =>
            this.$refs.operationComponent.handleOpen(record)
          );
        }
      } else {
        this.$nextTick(() => this.$refs.operationComponent.handleOpen(record));
      }
    },
    getActive(status) {
      switch (status) {
        case 2:
          this.stepSx = 1;
          break;
        case 3:
          this.stepSx = 2;
          break;
        case 4:
          this.stepSx = 2;
          break;
        case 5:
          this.stepSx = 3;
          break;
        case 6:
          this.stepSx = 3;
          break;
        case 7:
          this.stepSx = 4;
          break;
        case 8:
          this.stepSx = 4;
          break;
        case 9:
          this.stepSx = 4;
          break;
        default:
          break;
      }
    },
    CloseCancel() {
      this.cancelVisible = false;
      this.loadData();
    },
    CloseRiskDia() {
      this.riskDisclosureDialog = false;
      this.riskStartDialog = false;
      this.auditVisible = false;
      this.loadData();
    },
    applyClose() {
      this.keyNodeDialog = false;
      this.loadData();
    },
    emeCheckDialogClose() {
      this.emeCheckDialog = false;
      this.loadData();
    },
    oneCheckDialogClose() {
      this.oneCheckDialog = false;
      this.loadData();
    },
    rescueCheckClose() {
      this.rescueCheckDialog = false;
      this.loadData();
    },
    historyDialogClose() {
      this.historyDialog = false;
      this.loadData();
    },
    emerDialogClose() {
      this.emerDialog = false;
      this.loadData();
    },
    examieClose() {
      this.examineDialog = false;
      this.loadData();
    },
    chexkpageClose() {
      this.checkerDialog = false;
      this.loadData();
    },
    buildWriteClose() {
      this.buildDialog = false;
      this.loadData();
    },
    resuceClose() {
      this.resuceWirte = false;
      this.loadData();
    },
    downResume() {
      this.reportDialog = false;
      this.loadData();
    },
    supervis() {
      this.superyDialog = false;
      this.loadData();
    },
    CloseKeyNode() {
      this.keyNodeDialog = false;
      this.nodeAuditDialog = false;
      this.accJLVisible = false;
      this.warnDialogVisible = false;
    },
    AuditClose() {
      this.nodeAuditDialog = false;
      this.loadData();
    },
    closeFormJL() {
      this.accJLVisible = false;
      this.loadData();
    },
    CloseWarnDialog() {
      this.warnDialogVisible = false;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    CloseData() {
      this.releaseModal = false;
      this.loadData();
    },
    CloseDataResponse() {
      this.responModal = false;
      this.loadData();
    },
    CloseDataRectify() {
      this.rectifyModal = false;
      this.loadData();
    },
    CloseDataElimina() {
      this.eliminateDialog = false;
      this.loadData();
    },
    cancelTrouble() {
      this.startVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
      this.handleSearch();
    },
    // 整改操作-关闭
    rectCloseData() {
      this.emedialogVisible = false;
      this.loadData();
    },
    checkHandleClose() {
      this.checkDialogView = false;
      this.handleSearch();
    },
    getDetailMsg(id) {
      let promiseOne = getProblem(id);
      let promiseTwo = getCheck(id);
      Promise.all([promiseOne, promiseTwo]).then((res) => {
        console.log(res);
        this.setData = res[0].data ? res[0].data : {};
        this.checkData = res[1].data ? res[1].data : {};
        this.dialogVisible = true;
      });
    },
    lineSiteTeamName(row) {
      const lineName = row.lineName || "";
      const siteName = row.siteName || "";
      const teamName = row.teamName || "";
      if (teamName) {
        return teamName;
      } else {
        return lineName + siteName;
      }
    },
    onSearch() {
      this.loadData();
    },
    btnClose() {
      this.btnLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sys-main-wrapper {
  width: 100%;
  height: 100%;
  // background: #fff;
}

.header {
  height: 120px;

  .info {
    li {
      width: 240px;
      background: #fff;
    }

    h5 {
      font-size: 14px;
      color: #081742;
      font-family: Arial;
    }

    p {
      font-weight: bold;
      font-size: 28px;
      color: #081742;
      font-family: Arial;
    }

    .icon {
      width: 74px;
      height: 74px;
      border-radius: 37px;
    }

    .icon1 {
      background: rgba(23, 145, 255, 1);
    }

    .icon2 {
      background: rgba(114, 192, 64, 1);
    }

    .icon3 {
      background: rgba(122, 99, 255, 1);
    }

    .icon4 {
      background: rgba(250, 173, 19, 1);
    }
  }

  .tabs {
    li {
      background: #fff;
      border-right: 1px solid rgb(187, 187, 187);

      &:last-child {
        border-right: none;
      }
    }

    .active {
      background: #00b0ff;

      .title {
        color: #fff;
      }
    }

    .icon-wrapper {
      width: 55px;
      height: 55px;
      // background: #fff;

      .icon {
        width: 48px;
        height: 48px;
        border-radius: 12px;
      }

      .icon1 {
        background: #fef7e7;
      }

      .icon2 {
        background: #e7f4ff;
      }

      .icon3 {
        background: #f1f9ec;
      }
    }
  }
}

.search-table {
  background: #fff;
  .state-icon {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background: #dbdbdb;
    margin-right: 20px;
  }
  .unread {
    background: #18b0ff;
  }
}
::v-deep .details-dialog > .el-dialog {
  .el-dialog__body {
    padding-left: 20px;
  }
  > .el-dialog__header {
    padding: 0 !important;
  }
}
::v-deep .details-dialog1 {
  .el-dialog__header {
    .el-dialog__title {
      position: static;
    }
  }
  .el-dialog__body {
    max-height: 680px;
    overflow: auto;
  }
}
.checkDialog ::v-deep .el-dialog__body {
  height: 680px;
  overflow: auto;
}
.emergencyCheck ::v-deep .el-dialog__body {
  padding: 5px 20px !important;
}
</style>
