<template>
  <div class="info-container">
    <index-box :title="title">
      <div id="early" class="early"></div>
    </index-box>
    <HomePageDialog
      ref="modalTable"
      :titleTable="titleTable"
      :columns="columns"
    ></HomePageDialog>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox"
import HomePageDialog from "@/views/HiddenDanger/components/HomePageDialog.vue"

export default {
  name: "info-container",
  components: {
    IndexBox,
    HomePageDialog,
  },
  data () {
    return {
      title: "预警统计",
      titleTable: "",
      columns: [
        { prop: "lineSite", label: "线路工点", minWidth: 80 },
        { prop: "mobile", label: "预警等级", minWidth: 80 },
        { prop: "rotaName", label: "消除状态", minWidth: 80 },
        { prop: "mobile1", label: "预警名称", minWidth: 80 },
      ],
    }
  },
  mounted () {
    this.RefererShow()
  },
  methods: {
    RefererShow () {
      var myChartThree = this.$echarts.init(document.getElementById("early"))
      myChartThree.clear()

      var option = {
        tooltip: {},
        legend: {
          textStyle: {
            color: "#ffffff",
          },
        },
        grid: {
          left: "0%",
          right: "4%",
          bottom: "0%",
          top: "18%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "value",
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#ffffff",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            data: ["监测", "巡查", "综合"],
            axisLine: {
              lineStyle: {
                color: "#ffffff",
              },
            },
          },
        ],
        series: [
          {
            name: "红色预警",
            type: "bar",
            stack: "total",
            color: "red",
            label: {
              show: true,
              normal: {
                show: true,
                position: "insideRight",
                formatter: this.zero_format(),
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [0, 13, 0],
          },
          {
            name: "橙色预警",
            type: "bar",
            stack: "total",
            color: "orange",
            label: {
              show: true,
              normal: {
                show: true,
                position: "insideRight",
                formatter: this.zero_format(),
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [0, 0, 0],
          },
          {
            name: "黄色预警",
            type: "bar",
            stack: "total",
            radius: "80%",
            color: "yellow",
            label: {
              show: true,
              normal: {
                show: true,
                position: "insideRight",
                formatter: this.zero_format(),
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [0, 5, 0],
          },
        ],
      }
      option && myChartThree.setOption(option)
      myChartThree.on("click", (param) => {
        this.titleTable = param.name + param.seriesName + "列表"
        this.$refs.modalTable.handleOpen("center", "预警统计")
      })
    },
    zero_format () {
      return function (params) {
        if (params.value > 0) {
          return params.value
        } else {
          return ""
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.info-container {
  .early {
    width: 100%;
    height: 100%;
  }
  .scroll-wrapper {
    height: 100%;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}
</style>
