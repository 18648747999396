export const api = {
    baseURL: "https://open-metro.4dbim.vip:4214/api",

    loginURL: "https://dev-metro.4dbim.vip/sso/api",

    callbackURL: "http://localhost:3000/callback",

    clientId: "gis_bim-localhost",

    clientSecret: "gis_bim-localhost-secret"
}