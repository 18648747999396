<template>
  <div class="page-container bg-white p-4 h-full">
    <!-- 应急培训 -->
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="buildDeptName"
          label="所属建管部"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路名称"
        ></el-table-column>
        <el-table-column align="center" prop="branchName" label="分部名称">
        </el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="工点名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="label"
          label="培训名称"
        ></el-table-column>
        <el-table-column align="center" prop="teacher" label="主讲人">
        </el-table-column>
        <el-table-column align="center" prop="trainAddress" label="培训地点">
        </el-table-column>
        <el-table-column align="center" prop="planDate" label="计划培训时间">
        </el-table-column>
        <!--        <el-table-column align="center" prop="localePhoto" label="课件">
        </el-table-column>-->
        <el-table-column
          align="center"
          prop="stateName"
          label="状态"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="trainDate"
          label="实际培训时间"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="joinCount"
          label="参与人数 "
        ></el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="
                (scope.row.state == 1 || scope.row.state == 5) &&
                  (deptType == 'monitor' || deptType == '0')
              "
              @click="handleExamint(scope.row)"
              v-has="'train:examine'"
              >审核</el-button
            >
            <el-button
              type="text"
              v-if="
                (scope.row.state == 1 || scope.row.state == 5) &&
                  (deptType == 'construct' || deptType == '0') &&
                  loginUuid === scope.row.operatorUuid
              "
              @click="handleCancel(scope.row)"
              >撤回</el-button
            >
            <!--            <el-button type="text" v-if="scope.row.state == 3 &&
            (deptType == 'construct' || deptType == '0' ) "
                       @click="handleFinish(scope.row)">完成培训</el-button>-->
            <el-button
              type="text"
              v-if="
                (scope.row.state == 2 ||
                  scope.row.state == 6 ||
                  scope.row.state == 3 ||
                  scope.row.state == 7) &&
                  (deptType == 'construct' || deptType == '0') &&
                  loginUuid === scope.row.operatorUuid
              "
              @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              v-if="
                (deptType == 'construct' || deptType == '0') &&
                  scope.row.state == 4 &&
                  loginUuid === scope.row.operatorUuid
              "
              @click="handleUpload(scope.row)"
              >上传报告</el-button
            >
            <el-button type="text" @click="handleDetail(scope.row)"
              >详情</el-button
            >
            <el-button type="text" @click="handleHistory(scope.row)"
              >历史操作</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="total,prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getTrainPageList,
  queryDeptDetail,
  trainInfoUpdate,
} from "@/api/stand";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import addPlan from "@/views/Contingency/System/TrainingDrill/components/addPlan";
import history from "@/views/Contingency/System/TrainingDrill/components/history";
import finishTrain from "@/views/Contingency/System/TrainingDrill/components/finishTrain";
import trainExamine from "@/views/Contingency/System/TrainingDrill/components/trainExamine";
import trainModal from "@/views/Contingency/System/TrainingDrill/components/trainModal";
import trainUpload from "@/views/Contingency/System/TrainingDrill/components/trainUpload";
export default {
  components: {
    addPlan,
    history,
    finishTrain,
    trainExamine,
    trainModal,
    trainUpload,
  },
  data() {
    return {
      queryModal: {
        label: "",
        planEndDate: "",
        planStartDate: "",
        siteId: "",
        siteIds: [],
        lineSite: [],
        trainEndDate: "",
        trainStartDate: "",
      },
      planTime: [],
      trainTime: [],
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "id",
        multiple: true,
      },
      tableData: [],
      loading: false,
      dialogVisible: false,
      historyVisible: false,
      finishVisible: false,
      editVisible: false,
      trainVisible: false,
      uploadVisible: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      dict: {
        lineSiteData: [],
        state: [
          {
            value: "0",
            label: "待审核",
          },
          {
            value: "1",
            label: "已完成",
          },
          {
            value: "2",
            label: "审核驳回",
          },
        ],
      },
      historyData: {},
      finishObject: {},
      operation: "",
      titleAdd: "",
      detailId: null,
      trainId: null,
      editStatus: null,
      examintStatus: null,
      deptType: null,
      loginUuid: JSON.parse(localStorage.getItem("user")).uuid,
    };
  },
  mounted() {
    this.getLineSiteTreeData();
    this.getTableData();
    this.getDeptDetail();
  },
  methods: {
    //查询属于哪个角色
    getDeptDetail() {
      console.log(JSON.parse(localStorage.getItem("user")));
      var userInfo = JSON.parse(localStorage.getItem("user"));
      queryDeptDetail(userInfo.companyId).then((res) => {
        console.log(res);
        this.deptType = res.data ? res.data.category : "0";
      });
    },

    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },

    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    planChange(time) {
      if (time) {
        this.queryModal.planStartDate = time[0];
        this.queryModal.planEndDate = time[1];
      } else {
        this.queryModal.planStartDate = "";
        this.queryModal.planEndDate = "";
      }
    },
    trainChange(time) {
      if (time) {
        this.queryModal.trainStartDate = time[0];
        this.queryModal.trainEndDate = time[1];
      } else {
        this.queryModal.trainStartDate = "";
        this.queryModal.trainEndDate = "";
      }
    },
    getTableData() {
      let params = {
        ...this.queryModal,
        page: this.page.pageNo,
        size: this.page.pageSize,
      };
      if (params.planStartDate && !params.planStartDate.includes(" 00:00:00")) {
        params.planStartDate = params.planStartDate + " 00:00:00";
      }

      if (params.planEndDate && !params.planEndDate.includes(" 00:00:00")) {
        params.planEndDate = params.planEndDate + " 00:00:00";
      }

      if (
        params.trainStartDate &&
        !params.trainStartDate.includes(" 00:00:00")
      ) {
        params.trainStartDate = params.trainStartDate + " 00:00:00";
      }

      if (params.trainEndDate && !params.trainEndDate.includes(" 00:00:00")) {
        params.trainEndDate = params.trainEndDate + " 00:00:00";
      }
      getTrainPageList(params).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.records;
          this.page.count = res.data.count;
        }
      });
    },
    cancleAddPlan() {
      this.dialogVisible = false;
    },
    successAdd() {
      this.dialogVisible = false;
      this.getTableData();
    },
    cancelTrainClose() {
      this.trainVisible = false;
    },
    successTrain() {
      this.trainVisible = false;
      this.getTableData();
    },
    handleHistory(row) {
      this.historyData = { ...row };
      this.historyVisible = true;
    },
    handleFinish(row) {
      this.finishObject = { ...row };
      this.finishVisible = true;
    },
    handleEdit(row) {
      console.log(row);
      this.operation = "edit";
      this.titleAdd = "编辑";
      this.detailId = row.id;
      this.editStatus = row.state;
      this.editVisible = true;
    },
    cancelEditPlan() {
      this.editVisible = false;
    },
    successRefresh() {
      this.editVisible = false;
      this.getTableData();
    },
    finishModalClose() {
      this.finishVisible = false;
    },
    successFinish() {
      this.finishVisible = false;
      this.getTableData();
    },
    handleCancel(row) {
      this.$confirm("是否确定取消计划？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      }).then(() => {
        let params = {
          id: row.id,
          state: row.state == 1 ? 2 : 6,
        };
        trainInfoUpdate(params).then((res) => {
          if (res.code == 0) {
            this.$message.success("操作成功");
            this.getTableData();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 级联选择器事件
    handleLineSiteChange(record) {
      /*   this.queryModal.lineId = record[0];
      this.queryModal.siteId = record[1];*/
      this.queryModal.siteIds = [];
      for (let item of record) {
        this.queryModal.siteIds.push(item[item.length - 1]);
      }
    },
    handleSearch() {
      this.getTableData();
    },
    handleAdd() {
      // this.$refs.modalForm.handleAdd();
      this.operation = "add";
      this.titleAdd = "新增计划";
      this.dialogVisible = true;
    },
    handleDetail(record) {
      console.log(record, "详情");
      this.operation = "detail";
      this.titleAdd = "详情";
      this.detailId = record.id;
      this.editVisible = true;
      this.editStatus = record.state;
      // this.$refs.modalDetail.handleDetail(record);
    },
    handleUpload(row) {
      this.historyData = { ...row };
      this.uploadVisible = true;
    },
    cancelUpload() {
      this.uploadVisible = false;
    },
    successUpload() {
      this.uploadVisible = false;
      this.getTableData();
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getTableData();
    },
    handleFormSubmit() {
      this.loadData();
    },
    handleExamint(record) {
      // this.$refs.modalExamine.handleDetail(record);
      this.trainId = record.id;
      this.examintStatus = record.state;
      this.trainVisible = true;
    },
    handleExamintSubmit() {
      this.loadData();
    },
    // 点击图片名称，预览图片
    handleImgClick(record) {
      this.$refs.imgPreview.previewSrcList = [];
      this.$refs.imgPreview.previewSrcList.push(record.link);
      this.$refs.imgPreview.clickHandler();
      console.log();
    },
    loadData() {
      this.loading = true;
      const params = {
        lineId: this.queryModal.lineId ? this.queryModal.lineId : "-",
        siteId: this.queryModal.siteId ? this.queryModal.siteId : "-",
        label: this.queryModal.label ? this.queryModal.label : "-",
        startTime:
          this.queryModal.updateDate.length > 0
            ? this.queryModal.updateDate[0]
            : "-",
        endTime:
          this.queryModal.updateDate.length > 0
            ? this.queryModal.updateDate[1]
            : "-",
        state: this.queryModal.state ? this.queryModal.state : "-",
        page: this.page.pageNo,
        size: this.page.pageSize,
      };
      getTrainPageList(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          this.page.pageNo = res.data.current;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.total;
          this.page.count = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },
    // 线路工点树数据查询
    queryLineSiteTreeData() {
      this.loading = true;
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        let tempArr = [];
        for (let item of lineSiteData) {
          const obj = {
            value: item.id,
            label: item.name,
            children: [],
          };
          for (let site of item.nodes) {
            obj.children.push({
              value: site.id,
              label: site.name,
            });
          }
          tempArr.push(obj);
        }
        this.dict.lineSiteData = tempArr;
        this.loadData();
      }
    },
    // 列表中状态初始化
    formatterState(row) {
      return this.dict.state.find((item) => item.value == row.state).label;
    },
    // formatterLineSite (row) {
    //   let lineId = ""
    //   let siteId = ""
    //   const tempObj = this.dict.lineSiteData.find(
    //     (item) => item.value == row.lineId
    //   )
    //   if (tempObj) {
    //     lineId = tempObj.label
    //     if (tempObj.children) {
    //       siteId = tempObj.children.find((item) => item.value == row.siteId)
    //         .label
    //     }
    //     return `${lineId}-${siteId}`
    //   } else {
    //     return ""
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped></style>
