<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" :title="title">
    <el-form v-loading="loading" :model="model" :rules="rules" ref="modelForm" label-width="100px" class="demo-model">
      <el-form-item label="姓名" prop="workerName">
        <el-input v-model="model.workerName" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="所属救援队" prop="teamId">
        <!-- <el-input v-model="model.teamId" placeholder="请输入所属救援队"></el-input> -->
        <el-select v-model="model.teamId" placeholder="请选择">
          <el-option v-for="item in teamList" :key="item.id" :label="item.teamName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职位">
        <el-select v-model="model.workRoleName" placeholder="请选择职位">
          <el-option v-for="item of dict.workRole" :key="item.id" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话" prop="workerPhone">
        <el-input v-model="model.workerPhone" placeholder="请输入联系电话"></el-input>
      </el-form-item>
      <el-form-item label="学历" prop="workerEducation">
        <!-- <el-input v-model="model.workerEducation" placeholder="请输入学历"></el-input> -->
        <el-select v-model="model.workerEducation" placeholder="请选择职位">
          <el-option v-for="item of dict.education" :key="item.value" :label="item.label" :value="item.label">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="专业" prop="workerSpecialty">
        <el-input v-model="model.workerSpecialty" placeholder="请输入专业"></el-input>
      </el-form-item>
      <el-form-item label="特种作业证" prop="permit">
        <el-input v-model="model.permit" placeholder="请输入联系电话"></el-input>
      </el-form-item>
      <el-form-item label="照片" prop="workerImg">
        <image-uploader v-model="model.workerImg"></image-uploader>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { queryTeamLists, addRescueTeamMember } from "@/api/eres";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import ImageUploader from "@/components/Uploader/ImageUploader.vue";
export default {
  components: {
    systemModal,
    ImageUploader
  },
  data() {
    return {
      title: "新增",
      model: {
        workerName: "",
        teamId: "",
        workRoleName: "",
        workerPhone: "",
        workerEducation: "",
        workerSpecialty: "",
        permit: "",
        workerImg: ""
      },
      loading: false,
      teamList: [],
      rules: {
        workerName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        teamId: [
          { required: true, message: '请选择救援队', trigger: 'change' }
        ],
        workRoleName: [
          { required: true, message: '请选择职位', trigger: 'change' }
        ],
        workerPhone: [
          { required: true, message: '请输入电话', trigger: 'blur' }
        ],
        workerImg: [
          { required: true, message: "请上传图片", trigger: "change" },
        ],
      },
      dict: {
        workRole: [],
        education: [
          {
            label: "小学",
            value: 0
          }, {
            label: "初中",
            value: 1
          }, {
            label: "高中",
            value: 2
          }, {
            label: "专科",
            value: 3
          }, {
            label: "本科",
            value: 4
          }, {
            label: "硕士",
            value: 5
          }, {
            label: "博士",
            value: 6
          }
        ]
      }
    };
  },
  mounted() {
    this.getTeamList();
    this.getDictData("救援队职务", this.dict.workRole);
  },
  methods: {
    getDictData(label, target) {
      queryDictByLabel({ label }).then(res => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then(res => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          })
        }
      });
    },
    handleAdd(record) {
      if (record) {
        this.title = "编辑";
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
      } else {
        this.title = "新增";
      }
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          addRescueTeamMember(this.model).then(res => {
            if (res.code == 0) {
              this.loading = false;
              this.$message("操作成功！");
              this.$refs.formModal.handleClose();
              this.$emit("formSubmit");
            } else {
              this.$message.error("操作失败！");
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
    getTeamList() {
      queryTeamLists({ teamName: "-" }).then(res => {
        if (res.code == 0) {
          this.teamList = res.data;
        }
      });
    },
    // 图片上传成功
    handleAvatarSuccess() { },
    beforeAvatarUpload() { }
  }
}
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px solid #ccc;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
