
import Layout from "@/components/Layout/SystemLayout.vue";
import command from "@/views/Emergency/command/index.vue"
import materials from "@/views/Emergency/resources/materials.vue"

const Routes = [
    {
        path: "/Emergency",
        name: "/Emergency",
        component: Layout,
        meta: {
          title: "应急指挥",
        },
        children: [
            {
                path: "Personnel",
                name: "Personnel",
                component: Layout,
                meta: {
                  title: "人员管理",
                  path: [
                    {
                      name: "人员管理",
                    },
                    {
                      name: "首页",
                    },
                  ],
                },
                children: [
                    {
                      path: "command",
                      name: "command",
                      component: command,
                    },
                    
                  ],
              },
              {
                path: "Resource",
                name: "Resource",
                component: Layout,
                meta: {
                  title: "应急资源"
                },
                children: [
                    {
                      path: "materials",
                      name: "materials",
                      component: materials,
                    },
                    
                  ],
              }
        ]
    }
]
export default Routes;