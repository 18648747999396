<template>
  <systemModal
    ref="formModal"
    :title="title"
    :showFooter="false"
    width="40%"
    @modalClose="handleClose"
  >
    <el-form
      v-loading="loading"
      :model="model"
      ref="modelForm"
      label-width="200px"
      class="demo-model"
      style="width: 80%"
    >
      <el-form-item label="报警来源" prop="lineSite">
        <el-input
          v-model="model.resource"
          readonly
          placeholder="报警来源"
        ></el-input>
      </el-form-item>
      <el-form-item label="地理位置" prop="modelNo">
        <el-input
          v-model="model.resource"
          readonly
          placeholder="地理位置"
        ></el-input>
      </el-form-item>
      <el-form-item label="报警时间" prop="initiatorName">
        <el-input
          v-model="model.resource"
          readonly
          placeholder="报警时间"
        ></el-input>
      </el-form-item>
      <el-form-item label="上报人" prop="standardCount" v-if="type == 1017">
        <el-input
          v-model="model.resource"
          readonly
          placeholder="上报人"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="standardCount" v-if="type == 1017">
        <el-input
          v-model="model.resource"
          readonly
          placeholder="联系方式"
        ></el-input>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "一键报警",
      model: {
        siteName: "",
        level: "",
        reason: "",
        date: "",
        handlar: "",
      },
      type: "",
      dict: {},
      loading: false,
    };
  },
  filters: {},
  mounted() {},
  methods: {
    handleDetail(record) {
      this.type = record.noticeCode;
      this.$refs.formModal.handleOpen();
    },
    handlePublishMsgModalClose() {},
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 30px !important;
}
::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}
::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
</style>
