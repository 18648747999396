<template>
  <!--质安部汇总数据-->
  <div>
    <div style="margin-bottom: 20px">
      <el-radio-group
        size="mini"
        v-model="degree"
        v-for="(item, index) in times"
        :key="index"
        @change="degreeChange(item)"
      >
        <el-radio-button
          :label="item[0]"
          style="margin: 0 10px 5px 0"
        ></el-radio-button>
      </el-radio-group>
    </div>

    <el-table
      ref="tableData"
      :data="tableData"
      id="tableData"
      border
      :row-style="{ height: '30px' }"
      style="width: 100%; margin-top: 20px"
      v-loading="tableLoading"
    >
      <el-table-column
        :label="ertw"
        prop="lineSite"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        label="派出工作组(个)从应急响应启动到本次报送时间节点,派出工作组的累计数量"
        align="center"
        prop="workGroup"
      >
      </el-table-column>
      <el-table-column
        prop="position"
        label="检查场所部位(处)从应急响应启动到本次报送时间节点,检查场所部位的累计数量"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="hiddenDanger"
        label="新排查出三防风险隐患(处)上—次报送时间节点到本次报送时间节点，新排查出的三防风险隐患数量"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="info"
        label="发送预警或防御信息(条)地铁集团不需要填该项"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.info ? scope.row.info : "/" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="worker"
        label="预置(出动)抢险人员(人)在本次报送时间节点，能立即调动的抢险人员"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="team"
        label="预置(出动)抢险队伍(支)在本次报送时间节点，能立即调动的抢险队伍数量"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="预置(出动)抢险设备(台)指在本次报送时间节点，能立即调动设备数量"
        align="center"
      >
        <el-table-column prop="deviceTotal" label="合计" align="center">
        </el-table-column>
        <el-table-column
          prop="vehicle"
          label="车辆能立即调动的抢险汽车、吊车、铲车、履带车等"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="boat"
          label="舟艇能立即调动的舟船、划艇等水上运输工具数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="waterPump"
          label="抽水机能立即调动的龙吸水、自吸泵、排污泵、潜水泵、动力站水泵等抽排水设备数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="other"
          label="其他能立即调动的、未统计在车辆、舟艇、抽水机范围内的三防抢险设备数量"
          align="center"
        >
        </el-table-column>
      </el-table-column>

      <el-table-column
        prop="remark"
        label="其他应急措施落实及受灾害影响情况指从应急响应启动到本次报送时间节点，集团公司各单位应急措施落实及受灾受影响累计情况"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.remark ? scope.row.remark : "/" }}
        </template>
      </el-table-column>
      <el-table-column align="center" v-if="operation" label="操作" width="100">
        <template slot-scope="scope" v-if="scope.row.lineSite != '合计'">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-empty description="当前暂无建管部提交汇总数据" v-if="derty"></el-empty>
    <div
      class="btn"
      style="display: flex; justify-content: center; margin-top: 20px"
    >
      <el-button @click="sureSub" type="primary" :disabled="derty"
        >导出</el-button
      >

      <!-- :disabled="demo ? 1 : 0" -->
    </div>
    <el-dialog
      width="80%"
      title="查看详情"
      center
      :visible.sync="innerVisible"
      append-to-body
      class="checkDialog"
    >
      <summaryse
        :resetid="resetid"
        :responseId="responseId"
        :supervisionUuid="supervisionUuid"
        @summeryClose="summeryClose"
      ></summaryse>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :modal-append-to-body="false"
      :append-to-body="true"
      center
    >
      <el-checkbox-group v-model="checkList">
        <el-checkbox
          :label="item[0]"
          v-for="(item, index) in times"
          :key="index"
        ></el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="derive">导出</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  // 检查表填报-获取检查项
  getCurrentResponseMsg,
  summarydata,
  derivedata,
} from "@/api/response";
import summaryse from "./summary.vue";

export default {
  props: {
    ids: {
      type: Object,
      default() {
        return {};
      },
    },
    stasher: {
      type: String,
    },
  },
  components: {
    summaryse,
    // checkPage,
  },
  data() {
    return {
      tableData: [],
      times: [],
      timwe: [],
      degree: "",
      id: "",

      operation: true,
      tableLoading: false,
      summaryInfo: "",
      submited: false,
      innerVisible: false,
      resetid: "",
      responseId: "",
      supervisionUuid: "",
      dialogVisible: false,
      checkList: [],
      timesList: [],
      derty: false,
      gederty: false,
      liderty: false,
      ertw: "",
    };
  },
  created() {},
  mounted() {
    // this.getTableList();
    //线路
    // this.linetes.forEach((element) => {
    //   this.lineList.push(element);
    // });
  },
  watch: {
    ids: {
      handler(val) {
        if (val.responseRecordId) {
          this.tableLoading = true;
          this.times = [];
          this.tableData = [];
          this.timwe = [];
          this.id = val.responseRecordId;
          this.serte(val.responseRecordId);
        } else {
          this.tableLoading = true;
          this.times = [];
          this.tableData = [];
          this.timwe = [];

          this.id = val.id;
          this.serte(val.id);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    serte(val) {
      var newser = [];
      summarydata({ id: val }).then((res) => {
        if (res.data.role == "qualitySafetyDep") {
          this.operation = true;

          this.ertw = "建管部";
        } else if (res.data.role == "constManagDept") {
          this.operation = false;
          this.ertw = "线路工点/专职救援队";
        }
        if (JSON.stringify(res.data.reportData) != "{}") {
          for (let j in res.data.reportData) {
            newser.push({ [j]: res.data.reportData[j] });
          }
          this.timwe = JSON.parse(JSON.stringify(newser));

          for (let value in this.timwe) {
            this.times.push(Object.keys(this.timwe[value]));
          }
          this.degree = this.times[0][this.times[0].length - 1];
          this.tableData =
            newser[0][this.times[0][this.times[0].length - 1]].list;
          this.summaryInfo =
            newser[0][this.times[0][this.times[0].length - 1]].summaryInfo;
          var ert = [];
          this.tableData.forEach((it) => {
            ert.push(it.status);
          });
          const erwe = ert.every((item) => item == "已完成");
          if (erwe == true) {
            this.submited = true;
          } else {
            this.submited = false;
          }
          this.tableLoading = false;
          this.derty = false;
        } else {
          this.tableLoading = false;
          this.derty = true;
        }
      });
    },
    //汇总数据
    async getResponseList() {
      getCurrentResponseMsg().then((res) => {
        if (res.code == 0) {
          // this.responseData
          if (res.data.length) {
            const tempData = res.data;
            for (let active of tempData) {
              for (let item of active.operatorRecord) {
                item.record = JSON.parse(item.record);
              }
            }
            this.id = tempData[0].responseRecordId;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //点击第几次
    degreeChange(val) {
      for (let value in this.timwe) {
        var newser = [];
        if (Object.keys(this.timwe[value])[0] == val[0]) {
          for (let j in this.timwe[value]) {
            newser.push({ [j]: this.timwe[value][j] });
          }
          this.tableData = newser[0][val[0]].list;
        }
      }
    },

    // 表格的汇总
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "汇总";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          // sums[index] += " 元";
        } else {
          sums[index] = "/";
        }
      });

      return sums;
    },

    //查看详情
    handleClick(val) {
      this.resetid = val.id;
      this.innerVisible = true;
      this.responseId = this.id;
      this.supervisionUuid = val.supervisionUuid;
    },
    summeryClose() {
      this.innerVisible = false;
    },
    //导出弹框
    sureSub() {
      this.dialogVisible = true;
    },
    //导出文件
    derive() {
      var ser = [];

      for (let value in this.timwe) {
        this.checkList.forEach((item) => {
          if (Object.keys(this.timwe[value])[0] == item) {
            ser.push(this.timwe[value]);
          }
        });
      }
      this.checkList.forEach((item, index) => {
        ser.forEach((item2, index2) => {
          if (item == Object.keys(item2)) {
            this.timesList.push(item2[item].times);
          }
        });
      });
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      derivedata({
        times: this.timesList,
        responseId: this.id,
      }).then((res) => {
        loading.close();
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel",
        });
        // 3.创建一个临时的url指向blob对象
        let url = window.URL.createObjectURL(blob);
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "导出数据_" + new Date().getTime() + ".xlsx"; //自定义导出文件名
        a.click();
        this.checkList = [];
        this.timesList = [];
        this.dialogVisible = false;
      });
    },
    cancel() {
      this.checkList = [];
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-table::before {
  height: 0;
}
// /*定义滚动条的轨道颜色、内阴影及圆角*/
// ::v-deep ::-webkit-scrollbar-track {
//   -webkit-box-shadow: none !important;
//   background-color: #fff !important;
//   border-radius: 7px;
// }
// /*定义滑块颜色、内阴影及圆角*/
// ::v-deep ::-webkit-scrollbar-thumb {
//   background-color: rgba(0, 0, 0, 0.1) !important;
// }
// ::v-deep ::-webkit-scrollbar {
//   display: block;
//   width: 10px;
//   height: 7px;
//   border-radius: 0;
// }
// /*定义两端按钮的样式*/
// ::v-deep ::-webkit-scrollbar-button {
//   display: none;
// }

// //去掉固定列下方的横线
// ::v-deep .el-table__fixed-right::before,
// .el-table__fixed::before {
//   background-color: transparent;
// }
// //去掉固定列阴影
// ::v-deep .el-table__fixed-right {
//   // box-shadow: none;
//   height: 100% !important;
// }

// 表格多选框是小计的隐藏掉
::v-deep .myCell .el-checkbox__input {
  display: none !important;
}
::v-deep .el-table th > .cell {
  font-size: 12px !important;
}
::v-deep .el-table--border,
.el-table--group {
  border: none;
}
::v-deep .el-table--border::after,
.el-table--group::after {
  width: 0 !important;
}
::v-deep .el-table--border th.el-table__cell.gutter:last-of-type {
  background-color: #fff !important;
  border-bottom: none;
}
::v-deep .el-radio-button {
  width: 75px;
}
::v-deep .el-radio-button__inner {
  width: 65px;
}
::v-deep .el-table {
  display: flex;
  flex-direction: column;
}
::v-deep .el-table__body-wrapper {
  order: 1;
}

::v-deep .el-table__footer-wrapper tbody td {
  cursor: pointer;
}
::v-deep .el-radio-button:first-child:last-child .el-radio-button__inner {
  width: 88px;
}
::v-deep .el-radio-button {
  width: 95px;
}
::v-deep .el-table__empty-text {
  line-height: 60px;
  width: 50%;
  color: #ffffff;
}
.checkDialog ::v-deep .el-dialog__body {
  height: 680px;
  overflow: auto;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
</style>
