<template>
  <Modal
    ref="modalDetail"
    :modal="false"
    :width="'39%'"
    :title="modelTitle"
    @closeDialog="closeDialog"
  >
    <div
      class="monitor-video-container p-4 relative h-full overflow-hidden"
      :class="splitScreenType == 4 ? 'video-content4' : 'video-content9'"
      v-loading="loading"
      element-loading-text="数据加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
    >
      <el-tree
        ref="tree"
        :class="splitScreenType == 4 ? 'tree-css4' : 'tree-css9'"
        class="p-1"
        :data="videoData"
        :props="defaultProps"
        :show-checkbox="true"
        node-key="id"
        :default-checked-keys="defaultChecked"
        @check="handleNodeClick"
      >
        <span slot-scope="{ data }">
          <span :title="data.label">{{ data.label }} </span>
        </span>
      </el-tree>
      <div class="video-main-wrapper flex flex-col h-full">
        <div class="search-wrapper">
          <el-form
            :inline="true"
            :model="queryData"
            class="flex justify-between"
          >
            <el-row>
              <el-form-item>
                <el-button
                  type="primary"
                  size="small"
                  @click="handleSplitScreen(4)"
                  >四分屏</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click="handleSplitScreen(9)"
                  >九分屏</el-button
                >
              </el-form-item>
            </el-row>
          </el-form>
        </div>
        <div class="video-wrapper flex-1 box-video">
          <ul
            class="video-group overflow-y-scroll h-full flex flex-wrap"
            v-if="videoList.length > 0"
          >
            <li
              class="video-item"
              :class="splitScreenType == 4 ? 'division4' : 'division9'"
              v-for="item of videoList"
              :key="item.id"
            >
              <video
                v-if="item.sourceId == 0"
                :id="`my-video${item.id}`"
                class="video-js vjs-default-skin"
                controls
                preload="auto"
                style="width:100%;height:100%"
                muted
              >
                <!-- <source :src="item.hls" type="application/x-mpegURL" /> -->
                <source :src="item.url" type="application/x-mpegURL" />
              </video>
              <video
                v-else-if="item.sourceId == 2"
                class="video-js vjs-default-skin"
                :id="`test${item.id}`"
              ></video>
              <iframe
                v-else
                class="h-full w-full"
                :src="item.url"
                frameborder="0"
              ></iframe>
            </li>
          </ul>
          <div v-else class="zan-wu-sj">
            暂无数据
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { getTypeVideos } from "@/api/map";
import Modal from "@/components/Modal/modal.vue";
import videojs from "video.js";
import "videojs-contrib-hls";
import { lfsoftPlayerWS } from "@/utils/video";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      modelTitle: "视频监控",
      queryData: {},
      loading: false,
      splitScreenType: 4,
      // 左侧视频列表的展示数据
      videoData: [],
      //几分屏情况下展示的播放视频的数组
      videoList: [],
      defaultProps: {
        label: "label",
        id: "id",
        isLeaf: (data, node) => {
          if (node.level === 0) {
            return true;
          }
        },
      },
      defaultChecked: [],
      //中铁
      ztvideoList: [],
      hisvideoData: [],
      playVideo: [],
    };
  },
  mounted() {},
  methods: {
    handleNodeClick(record, checkInfo) {
      console.log(record, checkInfo);
      // 判断当前选中的是否在数组里  不在数组里就是选中
      if (!this.videoList.includes(record)) {
        // 当前播放的视频是否超过49分屏
        if (this.videoList.length < this.splitScreenType) {
          this.videoList.push(record);

          this.hisvideoData = [...this.videoList];
          //中铁的视频
          if (record.sourceId == 0) {
            if (!this.ztvideoList.includes(record)) {
              this.ztvideoList.push(record);
              this.$nextTick(() => {
                this.getVideo(record.id);
              });
            }
          }
          //九碧木的视频
          if (record.sourceId == 2) {
            this.$nextTick(() => {
              let IPhost = "zhjk.geobim.cn:3226";
              if (record.ipAddress.slice(0, 3) == 172) {
                IPhost = "zhjk.geobim.cn:3226";
              } else {
                IPhost = "spjk.geobim.cn:3234";
              }
              var conf1 = {
                videoid: "test" + record.id,
                protocol: "https:",
                host: IPhost,
                rootpath: "/",
                token: record.ipAddress,
                hlsver: "v1",
                session: "c1782caf-b670-42d8-ba90-2244d0b0ee83", //session got from login
              };
              console.log(conf1);
              var v1 = new lfsoftPlayerWS(conf1);
              this.playVideo.push(v1);
              v1.connect();
            });
          }
        } else {
          this.$refs.tree.setChecked(record, false, false);
          this.$message.warning("当前播放已达到最大");
        }
      } else {
        /**
         * 要是超出几分屏的最大限制 就取消当前的选中
         */
        // 在数组里就是取消选中   去做取消按钮的销毁video节点
        this.ztvideoList.forEach((item, index) => {
          if (item.id == record.id) {
            console.log(
              index,
              "中铁视频的取消选中的索引",
              "my-video" + item.id,
              "video销毁的ID"
            );
            let video = videojs("my-video" + item.id);
            video.dispose();
            this.ztvideoList.splice(index, 1);
          }
        });
        this.playVideo.forEach((item) => {
          if (item.W == record.ipAddress) {
            item.disconnect();
          }
        });
        // v-for的数组里删除带当前选中的视频
        this.videoList.forEach((item, index) => {
          if (item.id == record.id) {
            this.videoList.splice(index, 1);
          }
        });
        console.log(
          this.videoList,
          "视频播放列表",
          this.ztvideoList,
          "中铁的视频"
        );
      }
    },
    handleSplitScreen(type) {
      this.splitScreenType = type;
      // 9分屏切换4分屏的时候把hls的地址清除请求
      if (type == 4) {
        this.$nextTick(() => {
          for (let i = 0; i < this.videoList.length; i++) {
            if (this.videoList[i].sourceId == 0 && i >= 4) {
              console.log(
                "my-video" + this.videoList[i].id,
                "9=》4分屏时把后边5个中铁视频video请求删除掉"
              );
              videojs("my-video" + this.videoList[i].id).dispose();
            }
            if (this.videoList[i].sourceId == 2 && i >= 4) {
              this.playVideo.forEach((item) => {
                if (item.W == this.videoList[i].ipAddress) {
                  item.disconnect();
                }
              });
            }
            if (i >= 4) {
              // 9分屏切换4分屏最后5个摄像头自动取消选中
              this.$refs.tree.setChecked(this.videoList[i], false, false);
            }
          }
          this.videoList.splice(4, 5);
          console.log(this.$refs.tree, "做树的取消选中");
          console.log(this.videoList, "9切4删掉最后5个视频");
        });
      }
      if (type == 9 && this.hisvideoData.length > 0) {
        this.videoList = [...this.hisvideoData];
        for (let i = 0; i < this.videoList.length; i++) {
          this.$refs.tree.setChecked(this.videoList[i], true, false);
          if (this.videoList[i].sourceId == 0 && i >= 4) {
            this.$nextTick(() => {
              console.log(
                "my-video" + this.videoList[i].id,
                "9=》4分屏时把后边5个中铁视频video再次播放" + i
              );
              videojs("my-video" + this.videoList[i].id).play();
            });
          }
        }
      }
    },
    getVideo(i) {
      console.log(i, "当前要创建的节点video");
      videojs(
        "my-video" + i,
        {
          bigPlayButton: false,
          textTrackDisplay: false,
          posterImage: true,
          errorDisplay: false,
          controlBar: true,
        },
        function() {
          this.play();
        }
      );
    },
    handelOpen(data) {
      let that = this;
      this.$refs.modalDetail.handleOpen();
      this.loading = true;
      if (data.site && data.site.uuid) {
        let params = {
          siteUUID: data.site.uuid,
          type: data.type.code,
        };
        this.videoList = [];
        this.defaultChecked = [];
        this.videoData = [];
        getTypeVideos(params)
          .then((res) => {
            this.loading = false;

            res.data.forEach((item) => {
              let params = {
                label: item.name,
                sourceId: item.sourceId,
                url: item.hls,
                id: item.id,
                ipAddress: item.ipAddress,
              };
              that.videoData.push(params);
            });

            // that.videoData.forEach((item, index) => {
            //   if (index < that.splitScreenType) {

            //     that.defaultChecked.push(item.id)
            //     that.videoList.push(item)
            //   }
            // })
            // debugger
            // that.videoList.forEach(item => {

            //   if (item.sourceId == 0) {
            //     // that.getVideo(item.id)
            //   }
            // })
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        setInterval(() => {
          this.loading = false;
        }, 200);
      }
    },
    closeDialog() {
      for (let i = 0; i < this.ztvideoList.length; i++) {
        videojs("my-video" + this.ztvideoList[i].id).dispose();
      }
      this.playVideo.forEach((item) => {
        item.disconnect();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.zan-wu-sj {
  height: 100%;
  text-align: center;
  line-height: 300px;
  /* transform: translate(10px, 10px); */
  color: #909399;
}
.video-content9 {
  height: 540px;
}
.video-content4 {
  height: 400px;
}
.video-wrapper {
  height: 84%;
  .video-group {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .video-item {
      background: #000;
    }

    .division9 {
      width: 32.66%;
      height: 32%;
      margin-top: 1%;
      margin-right: 1%;

      &:nth-child(1) {
        margin-top: 0;
      }

      &:nth-child(2) {
        margin-top: 0;
      }

      &:nth-child(3) {
        margin-top: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    .division4 {
      width: 49.5%;
      height: 49.5%;
      margin-top: 1%;
      margin-right: 1%;

      &:nth-child(1) {
        margin-top: 0;
      }

      &:nth-child(2) {
        margin-top: 0;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

.tree-css4 {
  display: inline-block;
  width: 20%;
  border: 1px solid #ccc;
  height: 385px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  overflow: auto;
}
.tree-css9 {
  display: inline-block;
  width: 20%;
  border: 1px solid #ccc;
  height: 540px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 0px;
}
::v-deep .el-tree {
  background: #081742 !important;
  color: #fff !important;
}
.video-main-wrapper {
  display: inline-block;
  width: 80%;
  /* position: relative; */
  margin-left: 20%;
}

::v-deep .el-icon-caret-right:before {
  content: "\e791";
  color: #fff;
}
//隐藏那个树的前边的小箭头
::v-deep .el-tree-node__expand-icon.is-leaf {
  display: none;
}
// 树的悬停的颜色
::v-deep .el-tree-node__content {
  &:hover {
    background-color: #265f97;
  }
}
.box-video {
  height: calc(100% - 62px);
}
</style>
