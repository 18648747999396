<template>
  <div class="material-container h-full">
    <div class="mt-8">
      <!-- <el-steps :active="1" align-center> -->
      <el-steps :active="currentState" align-center>
        <el-step :status="currentState == 1 ? 'success' : currentState == 2 ? 'success' : 'wait'" title="施工单位申请"></el-step>
        <el-step v-if="pageType == '确认'" :status="currentState == 1 ? 'wait' : currentState == 2 ? rowData.state == 2 ? 'error' : 'success' : 'wait'" title="监理单位审核"></el-step>
        <el-step v-if="pageType == '验收'" :status="currentState == 1 ? 'wait' : currentState == 2 ? rowData.state == 5 ? 'error' : 'success' : 'wait'" title="监理单位审核"></el-step>
      </el-steps>
    </div>
 
    <div class="mt-8" v-if="active == 1">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="type == 'view' ? {} : rules"
        label-width="120px"
        class="demo-model"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="审核人" prop="createUser">
              <el-input
                readonly
                v-if="type != 'view'"
                v-model="ruleForm.createUser"
                placeholder="审核人"
              ></el-input>
              <el-input
                readonly
                v-if="type == 'view'"
                v-model="ruleForm.userName"
                placeholder="审核人"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="审核描述" prop="examineOpinion">
              <el-input
                v-if="type != 'view'"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6 }"
                resize="none"
                v-model="ruleForm.examineOpinion"
                placeholder="审核描述"
              ></el-input>
              <el-input
                type="textarea"
                v-if="type == 'view'"
                :autosize="{ minRows: 4, maxRows: 6 }"
                resize="none"
                :readonly="type == 'view' ? true : false"
                v-model="ruleForm.remark"
                placeholder="审核描述"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="补充附件" prop="fileList">
              <FileUploader
                v-if="type != 'view'"
                @uploadChange="handleFileUpload"
                @delChange="handleFileDel"
                :value="fileList"
              ></FileUploader>
              <ul v-if="type == 'view'" class="fileGroupBox">
                <li v-for="(item, i) in ruleForm.fileList" :key="i">
                  <span>{{ item.fileName }}</span>
                  <span @click="handleFileClick(item)">预览</span>
                  <span @click="handleExport(item.fileRealUrl, item.fileName)"
                    >导出</span
                  >
                </li>
              </ul>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialogFooter" v-if="type != 'view'">
        <el-button size="medium" type="danger" @click="submit('2')"
          >驳回</el-button
        >
        <el-button size="medium" type="primary" @click="submit('3')"
          >通过</el-button
        >
      </div>
    </div>
    <div class="mt-8" v-if="active == 2">
      <el-timeline style="margin: 10px 0 0 10px" v-if="recordList.length > 0">
        <el-timeline-item
          type="primary"
          color="#3fa5e9"
          :size="'large'"
          v-for="(item, i) in recordList"
          :key="i"
        >
          <div>
            <i style="font-style: normal; margin-right: 5px"></i
            ><span
              >{{ item.operationTime }}
              {{
                item.companyTypeName +
                  "-" +
                  item.companyName +
                  "-" +
                  item.userName +
                  "（" +
                  item.roleName +
                  "）"
              }}</span
            >
            <span v-if="pageType == '确认'">
              <span v-if="item.recordType == 2 || item.recordType == 3">
                -
                {{
                  item.recordType == 2
                    ? "已驳回"
                    : item.recordType == 3
                    ? "已通过"
                    : ""
                }} {{ "（" + item.remark + "）" }}
              </span>
            </span>
            <span v-if="pageType == '验收'">
              <span
                v-if="
                  item.recordType == 2 ||
                    item.recordType == 3 ||
                    item.recordType == 5
                "
              >
                -
                {{
                  item.recordType == 2
                    ? "已驳回"
                    : item.recordType == 3
                    ? "整改后通过"
                    : "已通过"
                }}
                {{ "（" + item.remark + "）" }}
              </span>
            </span>
            <span v-if="item.recordType == 1">
              -已提交申请
            </span>
            <span v-if="item.recordType == 4">-已整改</span>
            <span
              v-if="item.fileList.length > 0"
              @click="hanlderViewList(item)"
              class="fileDialogBox"
              >附件列表
            </span>
          </div>
        </el-timeline-item>
      </el-timeline>
      <el-empty
        v-if="recordList.length == 0"
        description="暂无审核数据"
      ></el-empty>
    </div>
  </div>
</template>

<script>
import { nodeStateRecord, confirmOperation } from "@/api/riskManage";
import { nodeStateRecordDetail } from "@/api/riskManage";
import FileUploader from "@/components/Uploader/FileUploader.vue";
export default {
  components: {
    FileUploader
  },
  props: {
    active: {
      type: Number
    },
    currentState: {
      type: Number
    },
    nodeId: {
      type: Number
    },
    nodeUuid: {
      type: String
    },
    eventId: {
      type: Number
    },
    pageType: {
      type: String,
      default: "确认"
    },
    type: {
      type: String,
      default: ""
    },
    rowData: {
      type: Object,
      default: () => {},
      require: false
    }
  },
  data() {
    return {
      fileDialog: false,
      ruleForm: {
        companyName: "",
        companyUuid: "",
        createUserUuid: "",
        companyTypeName: "",
        companyTypeCode: "",
        departmentName: "",
        departmentUuid: "",
        createUser: "",
        examineOpinion: "",
        fileList: [],
        recordType: ""
      },
      labelPosition: "left",
      filter: {
        pageNum: 1,
        pageSize: 10,
        keyNodesType: 0,
        nodeUuid: ""
      },
      rules: {
        examineOpinion: [
          { required: true, message: "请输入描述", trigger: "blur" }
        ],
        fileList: [{ required: true, message: "请上传附件", trigger: "change" }]
      },
      recordList: [],
      fileList: []
      // type: ""
    };
  },
  mounted() {
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      this.ruleForm.companyName = user.companyName;
      this.ruleForm.companyUuid = user.companyId;
      this.ruleForm.createUser = user.realName;
      this.ruleForm.createUserUuid = user.uuid;
      this.ruleForm.companyTypeName = user.companyCategoryName;
      this.ruleForm.companyTypeCode = user.companyCategory;
      this.ruleForm.departmentName = user.departmentName;
      this.ruleForm.departmentUuid = user.departmentId;
    }
    if (this.pageType == "验收") {
      this.filter.keyNodesType = 1;
    }
    if (this.active == 2) {
      this.getRecord();
    }
    if (this.type == "view") {
      // dd
      this.getDetails();
    }
  },
  methods: {
    getDetails() {
      let data = { ...this.filter };
      const _this = this;
      data.keyNodesType = 0;
      nodeStateRecordDetail({ id: this.eventId }).then(res => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            _this.ruleForm = res.data[res.data.length - 1];
          }
        }
      });
    },
    handleFileUpload({ name, url, realUrl }) {
      this.fileList.push({ name: name, url: url, fileRealUrl: realUrl });
      this.ruleForm.fileList.push({
        fileName: name,
        fileUrl: url,
        fileRealUrl: realUrl
      });
    },
    handleFileDel({ name, url }) {
      console.log(name);
      let index = this.fileList.findIndex(v => v.url === url);
      this.fileList.splice(index, 1);
      this.ruleForm.fileList.splice(index, 1);
    },
    handleClick(val) {
      console.log(val);
    },
    CloseData() {
      this.$emit("CloseData");
    },
    hanlderViewList(obj) {
      this.$emit("showFileList", obj);
      console.log(obj, "Dddddddddddddddd");
      // this.fileDialog = true;
    },
    handleFileClick(record) {
      const link = document.createElement("a");
      link.href = record.fileUrl;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
    handleExport(url, name) {
      this.downLoadFile(url, name);
    },
    handlefileClose() {
      this.fileDialog = false;
    },
    getRecord() {
      this.filter.nodeUuid = this.nodeUuid;
      console.log(this.filter);
      nodeStateRecord(this.filter).then(res => {
        this.recordList = res.data.records;
      });
    },
    reset() {
      const th = this;
      Object.keys(this.ruleForm).forEach(function(key) {
        th.ruleForm[key] = "";
      });
      this.$refs.ruleForm.resetFields();
    },
    submit(type) {
      this.ruleForm.id = this.nodeId;
      this.ruleForm.eventId = this.eventId;
      this.ruleForm.recordType = type;
      const _this = this;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          confirmOperation(this.ruleForm).then(res => {
            if (res.code == 0) {
              _this.$message.success("操作成功");
              _this.reset();
              _this.$emit("submitClose");
            } else {
              _this.$message.error(res.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
// ::v-deep .el-dialog__body {
//   height: 460px;
// }
::v-deep .el-image {
  height: 150px;
}
.i {
  position: absolute;
  top: -5px;
  right: -5px;
  color: red;
  font-weight: 600;
}
.url-list {
  position: relative;
  display: inline-block;
}
// .el-dialog__header {
//   padding: 0 !important;
// }
.fileDialogBox {
  color: #3fa5e9;
  margin-left: 10px;
  cursor: pointer;
}
.material-container {
  min-height: 400px;
}
.fileGroupBox {
  span {
    color: #3fa5e9;
    cursor: pointer;
    margin-left: 10px;
  }
  span:first-child {
    color: #606266;
  }
}
::v-deep .el-step__title.is-success {
  color: #409eff;
}
::v-deep .el-step__head.is-success {
  color: #409eff;
  border-color: #409eff;
}
</style>
