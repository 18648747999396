<template>
  <div
    class="cesiumContainer"
    @click="oldclick()"
    id="cesiumMap"
    ref="cesiumMap"
    v-loading="loading"
    element-loading-text="模型加载中"
  >
    <!-- 删除模型 -->
    <!-- <div class="moelClass">
      <input type="button" value="删除模型" @click="removeModel()" />
      <span class="spanClass"></span>
      <input type="button" value="添加模型" @click="init()" />
    </div> -->
    <!-- 弹框 -->
    <template v-for="(item, index) in bouncdeList">
      <div
        class="bounced"
        :key="index"
        :style="getStyle(item)"
        v-if="item.bool"
      >
        <div>编号：YB5</div>
        <div>地下室层数：九</div>
        <div>基础类型：桩基础</div>
        <div>建（构）建筑名称：积德花苑1</div>
        <div>建筑年代：1993</div>
        <div>结构类型：框架</div>
        <div>位置：白云区西嵯路518号</div>
        <div>与隧道关系：与隧道最小距离15.5米，左线19.7米</div>
      </div>
    </template>
  </div>
</template>
  
  <script>
import CryptoJS from "crypto-js";
import axios from "axios";
export default {
  data() {
    return {
      filew: true,
      detalieltes: {},
      msg: "Template",
      api: "https://bim.gzdtjl.com:4201",
      geoCesium1: null,
      bouncdeList: [{ left: 200, top: 400, bool: false }],
      loading: false,
    };
  },
  mounted() {
    this.init();
    // this.getApi();
    // this.detailsModel();
  },
  methods: {
    detaliel(val) {
      this.detalieltes = val;
    },
    pack() {
      this.filew = !this.filew;
    },
    init() {
      this.loading = true;
      var mapSetting = {
        //元素单击事件
        comClick: (modelId, compId) => {
          //获取点击对应的模型编号和构件编号
          console.log(modelId + " : " + compId);
          this.specifyDetails(modelId, compId);
        },
        //元素双击事件
        comDoubleClick: (modelId, compId) => {
          //获取点击对应的模型编号和构件编号
          this.getMousePos();
          console.log(modelId + " : " + compId);
        },
      };
      /* eslint-disable */
      this.geoCesium1 = $("#cesiumMap").geoCesium(mapSetting);
      this.geoCesium1.addModel(
        {
          id: "27cc4a78-a1e0-43a1-a9c3-95096c4bfb60",
          //   id: "27cc4a78-a1e0-43a1-a9c3-9445677",
          name: "测试模型",
          config: {
            // url: "https://bim.gzdtjl.com:4201/BIM3d/3edb86b1ff8354ca78407736863bf056/3edb86b1ff8354ca78407736863bf056.glb",
            url: "https://bim.gzdtjl.com:4201/BIM3d/e9ecd01588a7f408c76a964d6f5dc14e/e9ecd01588a7f408c76a964d6f5dc14e.glb",
            // url: 'http://localhost:4201/BIM3d/f7a374268cd89d64eb264fd9427d3900/f7a374268cd89d64eb264fd9427d3900.glb'
            // url: 'http://localhost:4201/BIM3d/3edb86b1ff8354ca78407736863bf056.glb'
            //url: 'http://58.248.231.164:5003/bim3d/162/36/50e1b2a3-3cd2-4a3c-b06c-372c75bdc6dc.glb'
          },
        },
        () => {
          this.loading = false;
          console.log("模型加载完成！");
        }
      );
    },
    // 设置特殊头部
    setHeader() {
      let time = Date.now();
      const key = "5ca428ad-8f5a-4bff-87b5-f0169add31e5";
      let sign = CryptoJS.SHA256(key + time);
      axios.defaults.headers.common["__app_key"] = key;
      axios.defaults.headers.common["__timestamp"] = time;
      axios.defaults.headers.common["__sign"] =
        CryptoJS.enc.Base64.stringify(sign);
    },
    // 获取模型列表
    getApi() {
      this.setHeader();
      // 获取 获取模型列表
      axios({
        method: "get",
        url:
          this.api +
          "/api/ModelInfo/GetModelList?pageSize=" +
          1 +
          "&pageIndex=" +
          10 +
          "&state=" +
          3,
      }).then((res) => {
        console.log(res);
      });
      // var formData = {};
      // axios({
      //   method: "post",
      //   url: this.urlP + "/aiVisitor/update",
      //   data: formData,
      // }).then((res) => {
      //   if (res.data.code == 200) {
      //     this.$toast.success(res.data.msg);
      //     this.tiole = "0";
      //     this.number = "2";
      //     this.updtaIsAdd = false;
      //     this.uploader = [];
      //   }
      // });
    },
    // 获取模型详情
    detailsModel() {
      this.setHeader();
      axios({
        method: "get",
        url:
          this.api +
          "/api/ModelInfo/GetModelDetail?modelId=" +
          "27cc4a78-a1e0-43a1-a9c3-95096c4bfb60",
      }).then((res) => {
        console.log(res);
      });
    },
    // 获取指定构件的详情信息
    specifyDetails(modelId, compId) {
      this.setHeader();
      axios({
        method: "get",
        url:
          this.api +
          "/api/ModelInfo/GetCompPropertyByCompId?modelId=" +
          modelId +
          "&compId=" +
          compId,
      }).then((res) => {
        console.log(res);
      });
    },
    getMousePos() {
      var e = window.event;
      this.bouncdeList[0].left = e.screenX ;
      this.bouncdeList[0].top = e.screenY - 350;
      this.bouncdeList[0].bool = true;
      // return { x: e.screenX, y: screenY };
    },
    getStyle(item) {
      const left = item.left;
      const top = item.top;
      return "left:" + left + "px" + ";top:" + top + "px";
    },
    oldclick() {
      this.bouncdeList[0].bool = false;
    },
    removeModel() {
      console.log(this.geoCesium1.removeEntity);
      console.log("执行删除模型removeEntity方法");
      this.geoCesium1.removeEntity(["27cc4a78-a1e0-43a1-a9c3-95096c4bfb60"]);
    },
  },
  beforeDestroy() {
    // this.removeModel();
  },
  destroyed() {
    // this.removeModel();
  },
};
</script>
  
  <style lang="scss" scoped>
.model {
  // width: 1000px;
  height: 800px;
  position: absolute;

  .left-panel {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    width: 20.8%;
    height: 100%;
  }
  .left-side {
    position: absolute;
    left: 22%;
    top: 0;
    z-index: 9;
  }
  .left_ster {
    position: absolute;
    left: 2%;
    top: 0;
    z-index: 9;
  }
}
.cesiumContainer {
  // max-width: 1070px;
  // width: calc(58.4% - 1rem);
  width: calc(60% - 10px);
  // float: right;
  height: 100%;
  margin: auto;
   // float: right;
}
.bounced {
  width: 200px;
  height: 280px;
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: 99;
  padding: 10px;
  div {
    font-size: 16px;
    color: #000;
    line-height: 24px;
  }
}
.moelClass {
  position: absolute;
  left: 200px;
  top: 200px;
  z-index: 99;
}
.spanClass {
  display: inline-block;
  width: 20px;
  height: 20px;
}
</style>
  
  