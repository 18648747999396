import { httpInit } from "./http";
const http = httpInit("/gzdt-notic");

// 消息待办统计
export const queryNoticCount = () => http.get(`/message/count`);

// 分页查询代办列表
export const queryAgencyNoticListByPage = data => http.post(`/message/dealt/page`, data);

// 分页查询告知信息
export const queryInformNoticListByPage = data => http.post(`/message/message/page`, data);

// 数据来源统计
export const queryNoticSourceCount = () => http.get(`/message/source/count`);

// 消息已读状态修改
export const updateReadNotificationState = params => http.get(`/message/read_notification/${params.userId}/${params.messageId}`);

// 待处理状态修改
export const updateHandleState = params => http.put(`/api/message/detailMessage/${params.id}`, params);

// 一键已读
export const readAll = params => http.put(`/message/read_notification/all/${params.messageSource}/${params.noticeCategory}`, { headers: { 'Content-Type': 'application/json;charset=utf8' } });


// 待办列表
export const todoList = data => http.post(`/notice_type/page/notice_type`, data);
// 待办删除
export const todoDel = ids => http.delete(`/notice_type/delete/notice_type/${ids}`);
// 新增待办
export const todoAdd = data => http.post(`/notice_type/add/notice_type`, data);
// 新增待办
export const todoEdit = params => http.put(`/notice_type/modify/notice_type/${params.id}`, params);
// 根据code查询角色
export const getRoles = data => http.get(`/notice_type/list/role/${data}`);
// 绑定角色
export const setRoles = data => http.put(`/notice_type/bind/role`, data);