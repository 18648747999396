<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <div>
          <el-form-item label="">
            <el-select
              v-model="queryModal.type"
              clearable
              placeholder="请选择类型"
            >
              <el-option
                v-for="item of typeList"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select
              v-model="queryModal.currentLevel"
              clearable
              placeholder="请选择级别"
            >
              <el-option
                v-for="item of leveloptions"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-date-picker
              v-model="queryModal.times"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </div>
        <div class="issue">
          <el-button type="primary" @click="issue" v-has="'defone:response'"
            >发布三防响应</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="loading"
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="60"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="id"
          show-overflow-tooltip
          label="三防应急响应"
        >
          <template slot-scope="scope">
            {{ scope.row.type ? scope.row.type.label : ""
            }}{{
              scope.row.currentLevel ? scope.row.currentLevel.label : ""
            }}级响应
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="id"
          show-overflow-tooltip
          label="响应类型"
        >
          <template slot-scope="scope">
            {{ scope.row.type ? scope.row.type.label : "" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="responseName"
          show-overflow-tooltip
          label="响应级别"
        >
          <template slot-scope="scope">
            {{ scope.row.currentLevel ? scope.row.currentLevel.label : "" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="startTime"
          show-overflow-tooltip
          label="开始时间"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="isRelegation"
          show-overflow-tooltip
          label="是否升/降级"
        >
          <template slot-scope="scope">
            {{
              scope.row.isRelegation
                ? scope.row.isRelegation == 0
                  ? "/"
                  : scope.row.isRelegation == 1
                  ? "升级"
                  : "降级"
                : "/"
            }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="relegationTime"
          show-overflow-tooltip
          label="升/降级时间"
        >
          <template slot-scope="scope">
            {{ scope.row.relegationTime ? scope.row.relegationTime : "/" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="relegationLevel"
          show-overflow-tooltip
          label="升/降级等级"
        >
          <template slot-scope="scope">
            {{
              scope.row.relegationLevelDto
                ? scope.row.relegationLevelDto.label
                : "/"
            }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="endTime"
          show-overflow-tooltip
          label="结束时间"
        >
          <template slot-scope="scope">
            {{ scope.row.endTime ? scope.row.endTime : "/" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="status"
          show-overflow-tooltip
          label="状态"
          :formatter="statusFormatter"
        >
        </el-table-column>
        <!-- <template slot-scope="scope">
            {{ scope.row.status == 0 ? "进行中" : "已结束" }}
          </template> -->
        <el-table-column align="center" label="操作" width="220px">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.status == 0"
              v-has="'defone:upgrade'"
              @click="upgrade(scope.row)"
              >升/降级</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status == 0"
              v-has="'defone:end'"
              @click="end(scope.row)"
              >结束</el-button
            >
            <el-button type="text" @click="detailHandle(scope.row)"
              >查看</el-button
            >
            <!-- <el-button type="text" @click="historyHandle(scope.row)"
              >历史操作</el-button
            > -->
            <el-button
              type="text"
              v-if="scope.row.disasterLossButton"
              @click="disasteloss(scope.row)"
              >灾害损失及恢复情况</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout=" prev, pager, next,total, sizes"
        :total="page.count"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="三防应急响应发布"
      :visible.sync="issueDialog"
      width="60%"
      :before-close="issueClose"
      center
      :close-on-click-modal="false"
    >
      <IssuePage
        @issueClose="issueClose($event)"
        :typeList="typeList"
        :leveloptions="leveloptions"
      ></IssuePage>
    </el-dialog>
    <el-dialog
      :title="upgradeTitle"
      :visible.sync="upgradeDialog"
      width="50%"
      :before-close="upgradeClose"
      center
      :close-on-click-modal="false"
    >
      <upgradePage
        v-if="upgradeDialog"
        @upgradeClose="upgradeClose($event)"
        :temList="temList"
        :leveloptions="leveloptions"
      ></upgradePage>
    </el-dialog>
    <el-dialog
      title="应急响应结束"
      :visible.sync="endDialog"
      width="50%"
      :before-close="endClose"
      center
      :close-on-click-modal="false"
    >
      <EndgradePage
        @endClose="endClose($event)"
        v-if="endDialog"
        :temList="temList"
        :leveloptions="leveloptions"
      ></EndgradePage>
    </el-dialog>
    <el-dialog
      :title="title"
      :visible.sync="detailDialog"
      width="80%"
      :before-close="detailClose"
      center
      :close-on-click-modal="false"
      class="checkDialog"
      append-to-body
    >
      <DetailPage
        @detailClose="detailClose"
        v-if="detailDialog"
        :temList="temList"
        :historyData="historyData"
      ></DetailPage>
    </el-dialog>
    <!-- <el-dialog
      title="历史操作记录"
      :visible.sync="historyDialog"
      width="30%"
      :before-close="historyClose"
      center
      :close-on-click-modal="false"
    >
      <HistoryPage
        @historyClose="historyClose"
        :logList="logList"
      ></HistoryPage>
    </el-dialog> -->

    <!-- 原有的灾害损失的弹窗 -->
    <!-- <el-dialog
      title="灾害损失及恢复情况"
      :visible.sync="disarerDialog"
      width="50%"
      :before-close="disarerClose"
      center
      :close-on-click-modal="false"
      :modal-append-to-body="false"
    >
      <disarer :responseIds="responseIds"></disarer>
    </el-dialog>
    <el-dialog
      title="灾害损失及恢复情况上报"
      :visible.sync="reportDialog"
      width="80%"
      :before-close="reportClose"
      center
      :close-on-click-modal="false"
      :modal-append-to-body="false"
    >
      <report
        @downResume="downResume"
        :responseIds="responseIds"
        :resubmit="resubmit"
        :supervisionName="supervisionName"
      ></report>
    </el-dialog>
    <el-dialog
      title="灾害损失及恢复情况上报详情"
      :visible.sync="examineDialog"
      width="80%"
      center
      append-to-body
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <examiners
        :reportId="id"
        @examieClose="examieClose"
        :terwe="terwe"
      ></examiners>
    </el-dialog> -->
    <el-dialog
      title="灾害损失情况"
      :visible.sync="casualtyLossDialog"
      width="1200px"
      :before-close="checkClose"
      center
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
      v-if="casualtyLossDialog"
    >
      <casualtyLoss
        ref="casualtyLoss"
        :casualtyLossData="casualtyLossData"
      ></casualtyLoss>
    </el-dialog>
  </div>
</template>

<script>
import IssuePage from "./history/issuePage.vue";
import upgradePage from "./history/upgradePage.vue";
import EndgradePage from "./history/endgradePage.vue";
import DetailPage from "./history/checkDetails.vue";
// import HistoryPage from "./history/historyPage.vue";
import { getResList, ResLog } from "@/api/responseTem";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { operatinghistoryList, Department } from "@/api/response";
import disarer from "./lossste/disaster.vue";
import report from "./lossste/reportery.vue";
import examiners from "./lossste/examine.vue";
import casualtyLoss from "./lossste/casualtyLoss.vue";

export default {
  components: {
    IssuePage,
    upgradePage,
    DetailPage,
    EndgradePage,
    // HistoryPage,
    disarer,
    report,
    examiners,
    casualtyLoss,
  },
  data() {
    return {
      title: "",
      loading: false,
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      queryModal: {
        type: "",
        currentLevel: "",
        times: [],
      },
      //发布三防响应
      issueDialog: false,
      // 应急响应升/降级
      upgradeTitle: "升/降级",
      upgradeDialog: false,
      // 应急响应结束
      endDialog: false,
      // 历史操作记录
      // historyDialog: false,
      detailDialog: false,
      //灾害损失
      disarerDialog: false,

      typeList: [],
      leveloptions: [],
      temList: {},
      historyData: [],
      logList: [],
      reportDialog: false,
      resubmit: "",
      responseIds: "",
      supervisionName: "",
      examineDialog: false,
      terwe: "",
      id: "",
      ids: "",
      // 灾害损失
      casualtyLossDialog: false,
      casualtyLossData: {},
    };
  },
  created() {
    queryDictByLabel({ label: "应急类别" }).then((res) => {
      queryDictNodesById({ id: res.data.id }).then((el) => {
        this.typeList = el.data;
      });
    });
    queryDictByLabel({ label: "当前等级" }).then((res) => {
      queryDictNodesById({ id: res.data.id }).then((el) => {
        this.leveloptions = el.data;
      });
    });
    this.loadData();
  },
  methods: {
    //参数筛选
    removeProperty(obj) {
      Object.keys(obj).forEach((e) => {
        if (
          obj[e] === "" ||
          obj[e] === undefined ||
          obj[e] === null ||
          obj[e] === "null"
        ) {
          delete obj[e];
        }
      });
      return obj;
    },
    //查询
    handleSearch() {
      this.page.pageNo = 1;
      this.loadData();
    },
    //分页查询列表
    async loadData() {
      this.loading = true;
      let params = {
        page: this.page.pageNo,
        size: this.page.pageSize,
        startDate: this.queryModal.times ? this.queryModal.times[0] : "",
        endDate: this.queryModal.times ? this.queryModal.times[1] : "",
        currentLevel: this.queryModal.currentLevel,
        type: this.queryModal.type,
      };
      params = this.removeProperty(params);
      let res = await getResList(params);
      if (res.code == 0) {
        this.loading = false;
        this.tableData = res.data.records;
        this.tableData.forEach((e) => {
          if (e.relegationLevel) {
            this.leveloptions.forEach((el) => {
              let dd = parseInt(el.id);
              let nu = parseInt(e.relegationLevel);
              if (dd == nu) {
                e.relegationLevelLabel = el.label;
              }
            });
          }
        });
        this.page.count = res.data.count;
      } else {
        this.loading = false;
      }
    },
    statusFormatter(val) {
      switch (val.status) {
        case 0:
          return "进行中";
        case 1:
          return "响应结束,事件闭合中";
        case 2:
          return "已结束";
      }
    },

    //发布三防响应
    issue() {
      this.issueDialog = true;
    },
    issueClose(msg) {
      this.issueDialog = false;
      if (msg == true) {
        this.handleSearch();
      }
    },
    // 升级/降级
    upgrade(data) {
      this.temList = data;
      this.upgradeDialog = true;
    },
    upgradeClose(msg) {
      this.upgradeDialog = false;
      if (msg == true) {
        this.handleSearch();
      }
    },
    // 结束
    end(data) {
      this.temList = data;
      this.endDialog = true;
    },
    endClose(msg) {
      this.endDialog = false;
      if (msg == true) {
        this.handleSearch();
      }
    },
    // 查看详情
    detailHandle(data) {
      this.title = data.type.label + data.currentLevel.label + "级响应详情";
      this.temList = data;
      this.detailDialog = true;
      let params = {
        incidenceId: data.id,
        operationType: 384,
      };
      operatinghistoryList(params)
        .then((res) => {
          if (res.code == 0) {
            this.historyData = res.data;
            res.data.forEach((element) => {
              if (element.actionType == "发布") {
                element.record = JSON.parse(element.record);
                this.temList.respondImage = element.record.originalAlertMessage;
              }
            });
          }
        })
        .catch((err) => {
          this.historyData = [];
          console.log(err);
        });
    },
    detailClose(msg) {
      this.detailDialog = false;
      if (msg == true) {
        this.handleSearch();
      }
    },
    // 历史操作
    // async historyHandle(data) {
    //   let params = {
    //     incidenceId: data.id,
    //     operationType: 384,
    //   };
    //   let res = await ResLog(params);
    //   if (res.code == 0) {
    //     console.log(res);
    //     this.logList = res.data;
    //     this.historyDialog = true;
    //   } else {
    //     this.$message.error("数据出错");
    //   }
    // },
    //灾害损失及恢复情况
    disasteloss(val) {
      this.casualtyLossDialog = true;
      this.casualtyLossData = val;
    },
    checkClose() {
      this.casualtyLossDialog = false;
    },
    disarerClose() {
      this.disarerDialog = false;
    },
    reportClose() {
      this.reportDialog = false;
    },
    downResume() {
      this.reportDialog = false;
      this.loadData();
    },
    historyClose() {
      this.historyDialog = false;
    },
    examieClose() {},
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
.p-4 {
  padding: 1rem 1rem 0 1rem;
}
.checkDialog ::v-deep .el-dialog__body {
  height: 680px;
  overflow: auto;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
</style>
