<template>
  <!-- 隐患治理-隐患整改 -->
  <div class="page-container bg-white p-4 h-full relative">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item>
            <el-cascader
              v-model="siteUuidList"
              clearable
              :options="lineSiteData"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              placeholder="请选择线路工点"
              @change="lineChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item prop="screeningType">
            <el-select
              v-model="queryModal.screeningType"
              clearable
              placeholder="请选择排查类型"
            >
              <el-option
                v-for="item of checkType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryModal.categoryPid"
              clearable
              @change="dangerChange"
              placeholder="请选择隐患类别"
            >
              <el-option
                v-for="item of dangerType"
                :key="item.value"
                :label="item.categoryName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryModal.categoryId"
              clearable
              placeholder="隐患子类"
            >
              <el-option
                v-for="item in dangerSon"
                :key="item.value"
                :label="item.categoryName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryModal.hiddenLevel"
              clearable
              placeholder="请选择隐患等级"
            >
              <el-option
                v-for="item of hdLevelData"
                :key="item.code"
                :label="item.intro"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="queryModal.itemName"
              clearable
              placeholder="请输入隐患条目"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>

    <div class="table-wrapper">
      <el-table v-loading="tableLoading" :data="tableData" style="width: 100%;">
        <el-table-column type="index" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column
          prop="lineName"
          align="center"
          label="线路"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="branchName"
          align="center"
          label="标段"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="workAreaName"
          align="center"
          label="工点"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          prop="screeningTypeName"
          align="center"
          label="排查类型"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="categoryPName"
          align="center"
          label="隐患类别"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="categoryName"
          align="center"
          label="隐患子类"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="itemName"
          align="center"
          label="隐患条目"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="hdInfo"
          align="center"
          label="隐患描述"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          prop="reformUnitTypeLabel"
          align="center"
          label="整改单位"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="hiddenLevelLabel"
          align="center"
          label="隐患等级"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="status"
          align="center"
          label="状态"
          show-overflow-tooltip
          :formatter="statusMatter"
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="70">
          <template slot-scope="scope">
            <el-button type="text" @click="view(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        :total="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        layout=" prev, pager, next"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      class="editDialog"
      width="70%"
      :visible.sync="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <HistoryPage
        ref="addPageData"
        @CloseData="CloseData"
        @showFileList="showFileList"
        :pageData="pageData"
        :submitType="submitType"
      ></HistoryPage>
    </el-dialog>
    <el-dialog
      width="40%"
      title="附件列表"
      class="fileDialogCs"
      :visible.sync="fileDialog"
      v-if="fileDialog"
      style="z-index:99999"
      :close-on-click-modal="false"
      :before-close="handlefileClose"
    >
      <el-table :data="fileData" style="width: 100%">
        <el-table-column
          align="center"
          prop="fileName"
          show-overflow-tooltip
          label="附件名称"
        ></el-table-column>
        <el-table-column
          align="center"
          show-overflow-tooltip
          label="操作"
          width="90"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="handleFileView(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
// 响应
import HistoryPage from "./modules/historyPage.vue";

// 字典
import { getDictData } from "@/api/dict";
// 分页,
import { subCategoryList, taskItemListPage } from "@/api/hdanger";

export default {
  components: {
    HistoryPage,
  },
  data() {
    return {
      // 线路查询
      siteUuidList: [],
      lineSiteData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true,
      },
      dialogVisible: false,
      queryModal: {
        workAreaUuidList: [],
        itemName: "",
        hiddenLevel: "",
        categoryPid: "",
        categoryId: "",
        screeningType: "",
      },
      // 排查类型
      checkType: [],
      // 隐患等级
      hdLevelData: [],
      // 排查类型
      dangerType: [],
      dangerSon: [],
      tableData: [{}],
      tableLoading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      submitType: "",
      pageData: {},
      // 图片文件弹框
      fileDialog: false,
    };
  },
  mounted() {
    this.getData();
    this.getDictList();
    this.queryLineSiteTreeData();
    this.getHiddenSelect(0);
  },
  methods: {
    // 线路工点树数据查询
    queryLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.lineSiteData = lineSiteData;
      }
    },
    lineChange(record) {
      this.queryModal.workAreaUuidList = [];
      for (let item of record) {
        this.queryModal.workAreaUuidList.push(item[item.length - 1]);
      }
    },
    // 获取隐患子类
    dangerChange() {
      this.queryModal.categoryId = "";
      this.getHiddenSelect(this.queryModal.categoryPid);
    },
    getHiddenSelect(id) {
      subCategoryList(id).then((res) => {
        if (res.code == 0) {
          if (id == 0) {
            this.dangerType = res.data;
          } else {
            this.dangerSon = res.data;
          }
        }
      });
    },
    // 字典  screeningType 隐患排查类型
    getDictList() {
      let label = "screeningType";
      this.checkType = [];
      getDictData(label).then((res) => {
        if (res.code == 0) {
          res.data.forEach((element) => {
            this.checkType.push({
              label: element.intro,
              value: element.code,
            });
          });
        }
      });
      getDictData("hdLevel").then((res) => {
        if (res.code == 0) {
          this.hdLevelData = res.data;
        }
      });
    },
    statusMatter(row) {
      switch (row.status) {
        case 0:
          return "待审核";
        case 1:
          return "未整改";
        case 2:
          return "未消除";
        case 3:
          return "已消除";
        default:
          return "";
      }
    },
    // 查询
    search() {
      this.getData();
    },
    // 获取列表数据
    getData() {
      this.tableLoading = true;
      let params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        status: 2,
        ...this.queryModal,
      };
      taskItemListPage(params)
        .then((res) => {
          this.tableData = res.data.records;
          this.page.total = res.data.count;
          this.tableLoading = false;
        })
        .catch((res) => {
          this.tableLoading = false;
          console.log(res.message);
        });
    },
    // 查看
    view(row) {
      this.loadform("view", row);
    },
    loadform(type, row) {
      this.dialogVisible = true;
      this.submitType = type;
      const rowinfo = { ...row };
      this.pageData = rowinfo;
    },
    // 关闭回调
    CloseData() {
      this.dialogVisible = false;
      this.getData();
    },
    handleClose() {
      this.CloseData();
    },
    // 分页
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getData();
    },
    // 图片文件回显
    showFileList(obj) {
      this.fileDialog = true;
      if (obj.operateRecordFiles) {
        this.fileData = obj.operateRecordFiles;
      } else if (obj.fileList) {
        this.fileData = obj.fileList;
      }
    },
    handleFileView(row) {
      const link = document.createElement("a");
      link.href = row.fileUrl;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
    handlefileClose() {
      this.fileDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 11;
}
::v-deep .el-dialog__header {
  padding: 0 !important;
}
::v-deep .el-dialog__body {
  padding-left: 20px !important;
  box-sizing: border-box;
}
.fileDialogCs ::v-deep .el-dialog__header {
  padding: 20px 20px 10px !important;
}

.page-container {
  .handle-wrapper {
    min-width: 240px;
    max-width: 30vw;
    height: 60vh;
    position: absolute;
    top: 10%;
    left: 20px;
    background: rgba(255, 255, 255, 1);
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
    border-radius: 0px 20px 20px 0px;
    padding: 20px;
    z-index: 6;

    .tree-wrapper {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }

    .toggle-btn {
      width: 28px;
      height: 240px;
      position: absolute;
      right: 0px;
      top: 50%;
      margin-top: -120px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }

    .toggle-btn-close {
      transform: rotate(180deg);
    }

    .toggle-btn-open {
      transform: rotate(0deg);
    }
  }

  .handle-wrapper-hide {
    left: -212px;
    background: transparent;
    box-shadow: none;
  }

  .handle-wrapper-show {
    left: 0;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #43a5e9;
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
  }
}
</style>
