<template>
  <sub-menu
    :firstLevel="firstLevel"
    v-if="item.children && item.children.length"
    :item="item"
  >
    <!--递归-->
    <zz-menu-item
      v-for="cItem in item.children"
      :key="cItem.id"
      :firstLevel="false"
      :item="cItem"
    >
    </zz-menu-item>
  </sub-menu>
  <menu-item :item="item" :id="item.id" v-else></menu-item>
</template>

<script>
import MenuItem from "./MenuItem.vue";
import SubMenu from "./SubMenu.vue";

export default {
  name: "zz-menu-item",
  inject: ["rootMenu"],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    firstLevel: {
      type: Boolean,
    },
  },
  components: {
    MenuItem,
    SubMenu,
  },
  mounted() {
  },
  methods: {},
};
</script>
