<template>
  <div
    style="wieth: 100%; height: 100%; border-radius: 10px; position: relative"
  >
    <div class="search-wrapper">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <el-select
            class="resources"
            v-model="formInline.resource"
            placeholder="请选择"
            clearable
            @change="resourceChange"
          >
            <el-option
              v-for="item in optionsData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            :placeholder="searchNamePlace"
            v-model="formInline.searchName"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="querySite">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="information" @click="infoIssue">
        <div class="supervise">
          <div class="issue"></div>
          <p>信息发布</p>
        </div>
      </div>
      <div class="keeper" @click="duty">
        <div class="supervise">
          <div class="keepers"></div>
          <p>值班人员</p>
        </div>
      </div>
    </div>
    <baidu-map
      :center="page.center"
      :scroll-wheel-zoom="true"
      :zoom="page.zoom"
      class="map"
      @ready="handler"
      :mapClick="false"
    >
      <!-- 预警 -->
      <bm-marker
        v-for="(data, index) in earlyWarnList"
        :key="'--' + index"
        :position="{ lng: data.coord[0], lat: data.coord[1] }"
        :dragging="false"
        :icon="{
          url: data.url,
          size: { width: 120, height: 120 },
        }"
        :offset="{ width: 0, height: 0 }"
        :title="data.siteName"
        @click="earlyWarnOpen(data)"
      >
        <!-- 响应事件信息窗口 -->
        <bm-info-window
          :position="{ lng: data.coord[0], lat: data.coord[1] }"
          :show="data.infoWindow.show"
          :closeOnClick="false"
          :width="340"
          :height="data.alarmTypeName == '手机报警' ? 160 : 110"
          :offset="{ width: -40, height: -25 }"
        >
          <div id="bigdiv" style="z-index: 999">
            <div class="info-header flex justify-between items-center">
              <p class="title flex items-center">
                {{ data.alarmTypeName }}|{{ data.lineName }}{{ data.siteName }}
              </p>
              <span
                class="close-btn"
                style="margin-left: 10px; margin-top: -16px"
                @click="earlyWarnClose(data)"
                >X</span
              >
            </div>
            <div
              v-if="data.alarmTypeName == '手机报警'"
              style="color: #fff; margin-top: -10px"
            >
              <div>上报人:{{ data.uploadName }}</div>
              <div>联系方式:{{ data.phoneNumber }}</div>
            </div>
            <div class="btn-wrapper flex justify-between mt-2">
              <el-button
                type="primary"
                @click="newsIssue(data)"
                v-has="'index:infoRelease'"
                >信息发布</el-button
              >
              <el-button
                type="primary"
                @click="eventEnd(data)"
                v-has="'index:eventEnd'"
                >事件结束</el-button
              >
              <el-button type="primary" @click="monitor(data)"
                >视频监控</el-button
              >
            </div>
          </div>
        </bm-info-window>
      </bm-marker>
      <!-- 响应 -->
      <bm-marker
        v-for="(data, index) in markerList"
        :key="data.coord[1] + index"
        :position="{ lng: data.coord[0], lat: data.coord[1] }"
        :dragging="false"
        :icon="{
          url: data.url,
          size: data.size,
        }"
        :title="data.categoryName"
        :offset="{ width: 0, height: -10 }"
        @mouseout="infoWindowCls(data)"
        @mouseover="infoWindowHover(data)"
        @click="infoWindowOpen(data)"
      >
        <!-- -->

        <!-- 信息知会页 -->
        <bm-info-window
          :position="{ lng: data.coord[0], lat: data.coord[1] }"
          :key="data.id"
          :show="data.infoWindow.show"
          :closeOnClick="false"
          :width="340"
          :height="145"
        >
          <div id="bigdiv" style="z-index: 999">
            <div style="color: #fff">工点名称:{{ data.siteName }}</div>
            <div style="color: #fff">发生时间:{{ data.created }}</div>
            <div style="color: #fff">事件类型:{{ data.typeName }}</div>
            <div style="color: #fff">上报人:{{ data.publisherName }}</div>
          </div>
        </bm-info-window>
      </bm-marker>
      <!--工点图标-->
      <bm-marker
        v-for="data in workPoints"
        :key="data.uuid"
        :position="{ lng: data.coord[0], lat: data.coord[1] }"
        :dragging="false"
        :title="data.name"
        :animation="data.markerShow"
        :icon="{
          url: data.url,
          size: { width: 15, height: 15 },
        }"
        @click="jumpWorkPoint(data)"
      >
      </bm-marker>
      <!--物资库-->
      <bm-marker
        v-for="(data, index) in materLibrary"
        :key="'-' + index"
        :position="{ lng: data.coord[0], lat: data.coord[1] }"
        :dragging="false"
        :title="data.resettlementSite"
        :icon="{
          url: data.url,
          size: { width: 20, height: 20 },
        }"
        :offset="{ width: 5, height: 15 }"
        @click="handleLibrary(data)"
      >
      </bm-marker>
      <!-- 专职救援队 -->
      <bm-marker
        v-for="data in rescueTeamMarkerList"
        :key="data.id"
        :position="{ lng: data.coord[0], lat: data.coord[1] }"
        :dragging="false"
        :title="data.teamName"
        :icon="{
          url: data.url,
          size: { width: 20, height: 20 },
        }"
        @click="rescue(data)"
      >
      </bm-marker>
      <!-- 兼职救援队 -->
      <bm-marker
        v-for="(data, index) in partTimeTeam"
        :key="'-' + index"
        :position="{ lng: data.coord[0], lat: data.coord[1] }"
        :dragging="false"
        :title="data.resettlementSite"
        :icon="{
          url: data.url,
          size: { width: 20, height: 20 },
        }"
        :offset="{ width: 5, height: 15 }"
        @click="openPartTeam(data)"
      >
      </bm-marker>

      <!--单兵-->
      <bm-marker
        v-show="isTaDiao"
        v-for="data in taDiaoMarkerList"
        :key="data.id"
        :position="{ lng: data.coord[1], lat: data.coord[0] }"
        :dragging="false"
        :icon="{
          url: data.url,
          size: { width: 32, height: 42 },
        }"
        @click="pawn(data)"
      >
      </bm-marker>
      <!-- 医院 -->
      <bm-marker
        v-for="(data, index) in hospitalList"
        :key="'-' + index"
        :position="{ lng: data.longitude, lat: data.latitude }"
        :dragging="false"
        :title="data.resettlementSite"
        :icon="{
          url: data.url,
          size: { width: 20, height: 20 },
        }"
        :offset="{ width: 0, height: 0 }"
        @click="openHospital(data)"
      >
        <!-- 医院 -->
        <bm-info-window
          :position="{ lng: data.longitude, lat: data.latitude }"
          :show="data.infoWindow.show"
          :closeOnClick="true"
          :width="320"
          :height="280"
          :offset="{ width: -20, height: 125 }"
        >
          <div id="bigdiv" style="z-index: 999">
            <div class="info-header flex justify-between items-center">
              <p class="text-white flex items-center">
                {{ data.resourceName }}
              </p>
              <span class="close-btn" @click="hospitalCls(data)">X</span>
            </div>
            <div class="info-wrapper">
              <p class="address" style="margin-bottom: 10px">
                医疗特色:{{ data.synopsis }}
              </p>
              <p class="department" style="margin-bottom: 10px">
                医院地址:{{ data.address }}{{ data.resourceAddress }}
              </p>
              <p class="tell">联系电话:{{ data.telephone }}</p>
            </div>
          </div>
        </bm-info-window>
      </bm-marker>
      <!-- 应急联络 -->
      <bm-marker
        v-for="(data, index) in unitsList"
        :key="'-' + index"
        :position="{ lng: data.longitude, lat: data.latitude }"
        :dragging="false"
        :title="data.resettlementSite"
        :icon="{
          url: data.url,
          size: { width: 20, height: 20 },
        }"
        :offset="{ width: 0, height: 0 }"
        @click="openUnits(data)"
      >
        <bm-info-window
          :position="{ lng: data.longitude, lat: data.latitude }"
          :show="data.infoWindow.show"
          :closeOnClick="true"
          :width="320"
          :height="140"
          :offset="{ width: -20, height: 30 }"
        >
          <div id="bigdiv" style="z-index: 999">
            <div class="info-header flex justify-between items-center">
              <p class="text-white flex items-center">
                {{ data.resourceName }}
              </p>
              <span class="close-btn" @click="unitsCls(data)">X</span>
            </div>
            <div class="info-wrapper">
              <p class="address" style="margin-bottom: 10px">
                地址:{{ data.address }}
              </p>
              <p class="state">联系电话:{{ data.telephone }}</p>
            </div>
          </div>
        </bm-info-window>
      </bm-marker>
      <!-- 线路 -->
      <bm-polyline
        v-for="(data, index) of polylineList"
        :key="data.id + index"
        :path="data.polylinePath"
        :stroke-color="data.color"
        :stroke-opacity="1"
        :stroke-weight="5"
        :editing="false"
      >
      </bm-polyline>
    </baidu-map>
    <!-- 底部操作栏 -->
    <div class="handle-wrapper">
      <div class="handle-content flex justify-center">
        <div class="handle-item" style="width: 9.37vw">
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div
            class="
              item-content
              2xl:text-base
              xl:text-sm
              md:text-xs
              cursor-pointer
            "
            :class="isAnZhi ? '' : 'ops'"
            @click="selectAnZhi()"
          >
            <div class="item-content-anzhi">物资库</div>
          </div>
        </div>
        <div
          class="handle-item 2xl:text-base xl:text-sm md:text-xs"
          style="width: 12.5vw"
        >
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div
            class="
              item-content
              2xl:text-base
              xl:text-sm
              md:text-xs
              cursor-pointer
            "
            :class="isJiuYuan ? '' : 'ops'"
            @click="selectJiuYuan()"
          >
            <div class="item-content-wrapper">专职救援队</div>
          </div>
        </div>
        <div
          class="handle-item 2xl:text-base xl:text-sm md:text-xs"
          style="width: 12.5vw"
        >
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div
            class="
              item-content
              2xl:text-base
              xl:text-sm
              md:text-xs
              cursor-pointer
            "
            :class="isPartJiuYuan ? '' : 'ops'"
            @click="partJiuYuan()"
          >
            <div class="item-content-wrapper partJob">兼职救援队</div>
          </div>
        </div>
        <!-- <div
          class="handle-item 2xl:text-base xl:text-sm md:text-xs"
          style="width: 9.37vw"
        >
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div
            class="
              item-content
              2xl:text-base
              xl:text-sm
              md:text-xs
              cursor-pointer
            "
            :class="isSingle ? '' : 'ops'"
            @click="single()"
          >
            <div class="item-content-wrapper singlePawn">单兵</div>
          </div>
        </div> -->
        <div
          class="handle-item 2xl:text-base xl:text-sm md:text-xs"
          style="width: 9.37vw"
        >
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div
            class="
              item-content
              2xl:text-base
              xl:text-sm
              md:text-xs
              cursor-pointer
            "
            :class="isHospital ? '' : 'ops'"
            @click="selectHospital()"
          >
            <div class="item-content-wrapper hospital">医院</div>
          </div>
        </div>
        <div
          class="handle-item 2xl:text-base xl:text-sm md:text-xs"
          style="width: 11.5vw"
        >
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div
            class="
              item-content
              2xl:text-base
              xl:text-sm
              md:text-xs
              cursor-pointer
            "
            :class="isMunicipal ? '' : 'ops'"
            @click="selectMunicipal()"
          >
            <div class="item-content-wrapper municipal">应急联络</div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      class="modalDetail"
      ref="modalDetail"
      @closeDialog="closeDialog"
      :modal="false"
      :width="'1200px'"
      :title="modelTitle"
    >
      <div
        class="table-wrapper p-4 h-full"
        v-loading="pageLoading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column align="center" label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table>
        <div class="flex justify-center pt-4">
          <el-pagination
            @size-change="handleSizeChange"
            @prev-click="handleCurrentChange"
            @next-click="handleCurrentChange"
            @current-change="handleCurrentChange"
            :current-page="pages.pageNo"
            :page-size="pages.pageSize"
            layout="prev, pager, next, sizes,  total, jumper"
            :total="pages.total"
          >
          </el-pagination>
        </div>
      </div>
    </Modal>
    <!-- 信息快报 -->
    <Modal
      class="modalDetail"
      ref="newsLetters"
      :modal="false"
      :width="'800px'"
      title="事件快报"
    >
      <div
        class="table-wrapper p-4 h-full"
        v-loading="pageLoading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        style="height: 450px; overflow-y: auto"
      >
        <newsLetterPage
          @colsenewsLetters="colsenewsLetters"
          :newsData.sync="newsData"
          :unitType="unitType"
        >
        </newsLetterPage>
      </div>
    </Modal>
    <!-- 信息发布弹框 -->
    <Modal
      class="modalDetail"
      ref="infoDialog"
      :modal="false"
      :width="'1200px'"
      title="信息发布"
      @closeDialog="infoColseDialogs"
    >
      <div
        class="table-wrapper p-4 h-full"
        v-loading="pageLoading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        style="height: 550px; overflow-y: auto"
      >
        <infoPage
          @infoColseDialog="infoColseDialog"
          ref="infoDialogs"
          :infoNewsData="infoNewsData"
        >
        </infoPage>
      </div>
    </Modal>
    <!-- 事件结束 -->
    <Modal
      class="modalDetail"
      ref="eventEnd"
      :modal="false"
      :width="'500px'"
      title="事件结束"
    >
      <div
        class="table-wrapper p-4 h-full"
        v-loading="pageLoading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        <eventEndPage @eventEndClose="eventEndClose"></eventEndPage>
      </div>
    </Modal>

    <!-- 应急值班 -->
    <Modal
      class="modalDetail"
      ref="keeperDialog"
      :modal="false"
      :width="'1200px'"
      title="值班人员"
      @rescueClose="keeperClose"
    >
      <div
        class="table-wrapper p-4 h-full"
        v-loading="pageLoading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        <dutyPage></dutyPage>
      </div>
    </Modal>
    <Modal
      class="modalDetail"
      ref="library"
      :modal="false"
      :width="'1200px'"
      title="物资库"
      @libraryClose="libraryClose"
    >
      <div
        class="table-wrapper p-4 h-full"
        v-loading="pageLoading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        style="height: 450px; overflow-y: auto"
      >
        <libraryPage :libraryData="libraryData"></libraryPage>
      </div>
    </Modal>
    <!-- 专职救援队 -->
    <Modal
      class="modalDetail"
      ref="rescueDialog"
      :modal="false"
      :width="'1200px'"
      title="专职救援队"
      @rescueClose="rescueClose"
    >
      <div
        class="table-wrapper p-4 h-full"
        v-loading="pageLoading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        <rescuePage :fullTimeTeam="fullTimeTeam"></rescuePage>
      </div>
    </Modal>

    <Modal
      class="modalDetail"
      ref="partRescueDialog"
      :modal="false"
      :width="'1200px'"
      title="兼职救援队"
      @partRescueClose="partRescueClose"
    >
      <div
        class="table-wrapper p-4 h-full"
        v-loading="pageLoading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        <partRescuePage :partRescue="partRescue"></partRescuePage>
      </div>
    </Modal>
  </div>
</template>

<script>
//高德坐标转百度（传入经度、纬度）
function bd_encrypt(gg_lng, gg_lat) {
  var X_PI = (Math.PI * 3000.0) / 180.0;
  var x = gg_lng,
    y = gg_lat;
  var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
  var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
  var bd_lng = z * Math.cos(theta) + 0.0065;
  var bd_lat = z * Math.sin(theta) + 0.006;
  return {
    lat: bd_lng,
    lng: bd_lat,
  };
}
// import { getsetting } from "@/api/setting";
// import { getMap } from "@/api/coord";
import infoPage from "./modules/infoPage.vue";
// import alarmPage from "./modules/alarmPage.vue";
import newsLetterPage from "./modules/newsLettersPage.vue";
import dutyPage from "./modules/dutyPage.vue";
import libraryPage from "./modules/libraryPage.vue";
import rescuePage from "./modules/rescuePage.vue";
import partRescuePage from "./modules/partRescuePage.vue";
import eventEndPage from "./modules/eventEndPage.vue";

import dayjs from "dayjs";
import { mapStyle } from "../../mapStyle";
import { tripleTree } from "@/api/map";
// import { queryDictNodesById } from "@/api/dict";
import {
  queryTeamLists,
  // queryMentList,
  // queryDutyList,
  queryWorkerListByPage,
  getMaterials,
} from "@/api/eres";
// import { queryDeptDetail } from "@/api/stand";
import { queryRoleById } from "@/api/role";
import { queryMaterialsList, getResource } from "@/api/eres";
// 首页响应事件,报警事件
import { getHomeDatas, getsiteAlarm } from "@/api/response";

import Modal from "@/components/Modal/modal.vue";

export default {
  name: "routeList",
  components: {
    Modal,
    infoPage,
    dutyPage,
    libraryPage,
    rescuePage,
    partRescuePage,
    eventEndPage,
    newsLetterPage,
    // alarmPage,
  },
  data() {
    return {
      formInline: {
        resource: "",
        searchName: "",
      },
      searchNamePlace: "请输入物资或设备名称搜索",
      optionsData: [
        {
          value: "1",
          label: "物资",
        },
        {
          value: "2",
          label: "专职救援队",
        },
        {
          value: "3",
          label: "兼职救援队",
        },
        // {
        //   value: "4",
        //   label: "单兵",
        // },
        {
          value: "5",
          label: "医院",
        },
        {
          value: "6",
          label: "应急联络",
        },
      ],
      // lineSite: [],
      // options: [],
      // props: {
      //   value: "id",
      //   label: "name",
      //   children: "nodes",
      // },
      siteName: "", // 站点名称
      lineShow: false,
      pointShow: false,
      currentDate: dayjs(),
      mapStyle: mapStyle,
      tableData: [],
      columns: [],
      pages: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        total: 0,
      },
      pageLoading: false,
      lineId: "",
      siteId: "",
      typeData: null,
      addDialog: {
        show: false,
        data: {},
        tree: [],
        title: "",
      },
      page: {
        start: "",
        end: "",
        center: { lng: 0, lat: 0 },
        zoom: 12,
      },
      modelTitle: "",
      //安置点
      materLibrary: [],
      secZhiMarkerList: [],
      // 救援队
      rescueTeamMarkerList: [],
      sectaDiaoMarkerList: [],
      // 专职救援队
      rescueTeamList: [],
      // 兼职救援队
      partTimeTeamList: [],
      partTimeTeam: [],
      // 医院
      hospitals: [],
      hospitalList: [],
      // 应急联络
      units: [],
      unitsList: [],
      //单兵
      taDiaoMarkerList: [],
      // 线路坐标集合
      polylineList: [],
      // 工点坐标集合
      workPoints: [],
      // 电子围栏坐标点集合
      enclosureList: [],
      isJiuYuan: false,
      //兼职救援队
      isPartJiuYuan: false,
      // 单兵
      isSingle: false,
      // 医院shi
      isHospital: false,
      // 应急联络
      isMunicipal: false,
      isAnZhi: false,
      // 报警点图标点击
      alarmPageData: {},
      // 专职救援队
      fullTimeTeam: {},
      // 兼职救援队
      partRescue: {},
      // 物资库
      libraryData: {},
      // 信息发布
      infoNewsData: {},
      // 告警图标移入
      markerListOver: false,
      // 告警图标点击
      markerListClick: false,
      // 角色
      userData: {},
      roleName: "",
      // 单位类型
      unitType: "",
      // 单位角色
      unitRole: "",
      // 响应点
      markerList: [],
      // 预警点
      earlyWarnList: [],
      newsData: {},
      // 物资查询 使用
      jydList: "",
      wzkList: "",
    };
  },

  mounted() {
    // this.getTableList();
    this.getLineTree();
    // this.getSheBei(); //查询大型设备
    this.queryLineSiteTree(); //查询线路工点
    this.getHomeData(); //地图响应信息绘点
    // this.getAnZhi(); //查询物资库
    this.getJiuYuan(); //查询救援队
    this.getTeamList(); //查询兼职救援队
    this.getHospital(); //查询医院
    this.getNnits(); //查询应急联络
    // this.getGuanZhu(); //查询关注
    this.userData = JSON.parse(localStorage.getItem("user"));
    this.roleName = this.userData.roles[0].roleName;
    var roleId = this.userData.roles[0].id;
    // var userInfo = JSON.parse(localStorage.getItem("user"));
    queryRoleById({ id: roleId }).then((res) => {
      this.unitType = res.data ? res.data.unitType : "0";
      this.getsiteAlarms(); //地图预警信息加载
      this.unitRole = this.unitType + this.roleName;
      console.log("单位类型", this.unitType);
      console.log("单位角色类型", this.unitRole);
    });

    // 建管部,"constManagDept"
    // "建设单位", "build",
    // "监理单位","monitor",
    // "施工单位","construct",
    // "施工总承包单位","contracting",
    // 质安部,"qualitySafetyDep"
  },
  methods: {
    // 信息发布
    infoIssue() {
      this.$refs.infoDialog.handleOpen();
    },
    // handleClose() {
    //   this.$refs.infoDialog.handleClose();

    // },
    infoColseDialog() {
      // this.infoDialog = false;
      this.$refs.infoDialog.handleClose();
      this.$refs.infoDialogs.resetFieldClose();
    },
    infoColseDialogs() {
      this.$refs.infoDialogs.resetFieldClose();
    },
    // 值班人员
    duty() {
      // 值班人员
      this.$refs.keeperDialog.handleOpen();
    },
    // 信息快报关闭
    colsenewsLetters() {
      this.$refs.newsLetters.handleClose();
    },
    keeperClose() {
      this.$refs.keeperDialog.handleClose();
    },
    // keeperColseDialog() {
    //   this.$refs.keeperDialog.handleClose();
    // },
    async getLineTree() {
      if (localStorage.linetree) {
        this.options = JSON.parse(localStorage.linetree);
      } else {
        const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
        if (lineSiteData) {
          this.options = lineSiteData;
          this.options.forEach((e) => {
            if (e.nodes && e.nodes.length != 0) {
              e.nodes.forEach((el) => {
                delete el.nodes;
              });
            }
          });
          localStorage.linetree = JSON.stringify(this.options);
        }
      }
    },
    resourceChange(val) {
      if (val == 1) {
        this.searchNamePlace = "请输入物资或设备名称搜索";
      } else if (val == 2 || val == 3) {
        this.searchNamePlace = "请输入队伍名称或人员名称搜索";
      } else if (val == 5) {
        this.searchNamePlace = "请输入医院名称搜索";
      } else if (val == 6) {
        this.searchNamePlace = "请输入应急联络名称搜索";
      }
    },
    // 首页查询
    querySite() {
      if (this.formInline.resource == "1") {
        let params = {
          materialsName: this.formInline.searchName,
        };
        let demo = {
          teamType: 1,
          teamName: "-",
          personName: "-",
          materialsName: this.formInline.searchName
            ? this.formInline.searchName
            : "-",
        };
        getMaterials(params).then((res) => {
          if (res.code == 0) {
            this.wzkList = res.data;
            this.wzkList.forEach((e) => {
              e.url = require("../../../assets/map/material.png");
              let p = bd_encrypt(e.lng, e.lat);
              e.coord = [p.lat, p.lng];
              e.infoWindow = {
                show: false,
              };
            });
            // 物资库
            this.materLibrary = this.wzkList;
            this.isAnZhi = true;
          }
        });

        queryTeamLists(demo).then((res) => {
          if (res.code == 0) {
            this.jydList = res.data;
            this.jydList.forEach((e) => {
              e.url = require("../../../assets/map/rescueTeam.png");
              let p = bd_encrypt(e.lng, e.lat);
              e.coord = [p.lat, p.lng];
              e.infoWindow = {
                show: false,
              };
              // e.arrange = {
              //   show: false,
              // };
            });
          }
          // 专职救援队
          this.rescueTeamMarkerList = this.jydList;
          this.isJiuYuan = true;
        });
        // 兼职救援队
        this.partTimeTeam = [];
        this.isPartJiuYuan = false;
        // 医院
        this.hospitalList = [];
        this.isHospital = false;
        // 应急联络
        this.unitsList = [];
        this.isMunicipal = false;
      } else if (this.formInline.resource == "2") {
        let params = {
          teamType: 1,
          teamName: this.formInline.searchName
            ? this.formInline.searchName
            : "-",
          personName: this.formInline.searchName
            ? this.formInline.searchName
            : "-",
          materialsName: "-",
        };
        queryTeamLists(params).then((res) => {
          if (res.code == 0) {
            let list = res.data;
            list.forEach((e) => {
              e.url = require("../../../assets/map/rescueTeam.png");
              let p = bd_encrypt(e.lng, e.lat);
              e.coord = [p.lat, p.lng];
              e.infoWindow = {
                show: false,
              };
              // e.arrange = {
              //   show: false,
              // };
            });
            // 专职救援队
            this.rescueTeamMarkerList = list;
            this.isJiuYuan = true;
            // 兼职救援队
            this.partTimeTeam = [];
            this.isPartJiuYuan = false;
            // 物资库
            this.materLibrary = [];
            this.isAnZhi = false;
            // 医院
            this.hospitalList = [];
            this.isHospital = false;
            // 应急联络
            this.unitsList = [];
            this.isMunicipal = false;
          }
        });
      } else if (this.formInline.resource == "3") {
        let param = {
          teamType: 2,
          teamName: this.formInline.searchName
            ? this.formInline.searchName
            : "-",
          personName: this.formInline.searchName
            ? this.formInline.searchName
            : "-",
          materialsName: "-",
        };
        queryTeamLists(param).then((res) => {
          if (res.code == 0) {
            let list = res.data;
            list.forEach((e) => {
              e.url = require("../../../assets/map/jobTeam.png");
              let p = bd_encrypt(e.lng, e.lat);
              e.coord = [p.lat, p.lng];
              e.infoWindow = {
                show: false,
              };
            });
            // 兼职救援队
            this.partTimeTeam = list;
            this.isPartJiuYuan = true;
            // 物资库
            this.materLibrary = [];
            this.isAnZhi = false;
            // 专职救援队
            this.rescueTeamMarkerList = [];
            this.isJiuYuan = false;
            // 医院
            this.hospitalList = [];
            this.isHospital = false;
            // 应急联络
            this.unitsList = [];
            this.isMunicipal = false;
          }
        });
      } else if (this.formInline.resource == "5") {
        let param = {
          resourceType: "医院",
          resourceName: this.formInline.searchName,
        };

        getResource(param).then((res) => {
          if (res.code == 0) {
            let list = res.data;
            list.forEach((e) => {
              e.url = require("../../../assets/map/hospital.png");
              let p = bd_encrypt(e.lng, e.lat);
              e.coord = [p.lat, p.lng];
              e.infoWindow = {
                show: false,
              };
            });
            // 医院
            this.hospitalList = list;
            this.isHospital = true;
            // 物资库
            this.materLibrary = [];
            this.isAnZhi = false;
            // 专职救援队
            this.rescueTeamMarkerList = [];
            this.isJiuYuan = false;
            // 兼职救援队
            this.partTimeTeam = [];
            this.isPartJiuYuan = false;
            // 应急联络
            this.unitsList = [];
            this.isMunicipal = false;
          }
        });
      } else if (this.formInline.resource == "6") {
        let param = {
          resourceType: "应急联络",
          resourceName: this.formInline.searchName,
        };
        getResource(param).then((res) => {
          if (res.code == 0) {
            let list = res.data;
            list.forEach((e) => {
              e.url = require("../../../assets/map/municipal.png");
              e.coord = [e.lng, e.lat];
              e.infoWindow = {
                show: false,
              };
            });
            // 应急联络
            this.unitsList = list;
            this.isMunicipal = true;
            // 物资库
            this.materLibrary = [];
            this.isAnZhi = false;
            // 专职救援队
            this.rescueTeamMarkerList = [];
            this.isJiuYuan = false;
            // 兼职救援队
            this.partTimeTeam = [];
            this.isPartJiuYuan = false;
            // 医院
            this.hospitalList = [];
            this.isHospital = false;
          }
        });
      } else {
        // 应急联络
        this.unitsList = [];
        this.isMunicipal = false;
        // 物资库
        this.materLibrary = [];
        this.isAnZhi = false;

        // 专职救援队
        this.rescueTeamMarkerList = [];
        this.isJiuYuan = false;

        // 兼职救援队
        this.partTimeTeam = [];
        this.isPartJiuYuan = false;
        // 医院
        this.hospitalList = [];
        this.isHospital = false;
      }
      // if (this.lineSite.length != 0) {
      //   this.workPoints.forEach((e) => {
      //     if (this.lineSite[1] == e.id) {
      //       this.page = {
      //         start: "",
      //         end: "",
      //         center: { lng: e.coord[0], lat: e.coord[1] },
      //         zoom: 14,
      //       };
      //       e.markerShow = "BMAP_ANIMATION_BOUNCE";
      //       setTimeout(() => {
      //         e.markerShow = "";
      //       }, 3000);
      //     }
      //   });
      // } else {
      //   this.page = {
      //     start: "",
      //     end: "",
      //     center: { lng: 113.2839488029, lat: 23.0907270045 },
      //     zoom: 14,
      //   };
      // }
      // this.$router.push("/Outburst");
    },
    //地图加载后事件
    handler({ map }) {
      map.setMapStyleV2({
        styleId: "565ab0773031556035c58e365ee8547a",
      });

      this.page = {
        start: "",
        end: "",
        center: { lng: 113.2839488029, lat: 23.0907270045 },
        zoom: 14,
      };
    },
    //相互之间互相影响
    relieves() {
      this.markerList.forEach((element) => {
        element.infoWindow.show = false;
      });
      this.earlyWarnList.forEach((element) => {
        element.infoWindow.show = false;
      });
      this.unitsList.forEach((element) => {
        element.infoWindow.show = false;
      });
      this.hospitalList.forEach((element) => {
        element.infoWindow.show = false;
      });
    },
    // 预警点渲染
    earlyWarnOpen(data) {
      this.relieves();
      // construct 施工单位
      // monitor 监理单位
      if (this.unitType == "construct" || this.unitType == "monitor") {
        const index = this.earlyWarnList.findIndex(
          (item) => item.id == data.id
        );
        this.earlyWarnList[index].infoWindow.show = true;
      } else {
         localStorage.setItem("detirler", JSON.stringify(data));
        this.$router.push({
          path: "/Screen/Contingency/Outburst",
          query: {
            data: JSON.stringify(data),
          },
        });
        
      }
    },
    // 预警事件关闭
    earlyWarnClose(data) {
      const index = this.earlyWarnList.findIndex((item) => item.id == data.id);
      this.earlyWarnList[index].infoWindow.show = false;
    },
    // 响应事件打开
    infoWindowOpen(data) {
      // 建管部,"constManagDept"
      // "建设单位", "build",
      // "监理单位","monitor",
      // "施工单位","construct",
      // "施工总承包单位","contracting",
      // 质安部,"qualitySafetyDep"
      if (
        this.unitRole == "constManagDept总工程师室主任" ||
        this.unitRole == "constManagDept总监" ||
        this.unitRole == "constManagDept副总监"
      ) {
        this.newsData = data;
        this.unitType = this.unitRole;
        this.$refs.newsLetters.handleOpen();
      } else {
        localStorage.setItem("detirler", JSON.stringify(data));
        // 否则进入战时页面
        this.$router.push({
          path: "/Screen/Contingency/Outburst",
          query: {
            data: JSON.stringify(data),
          },
        });
        
      }
    },
    //告警图标关闭事件
    onInfoWindowClose(data) {
      const index = this.markerList.findIndex((item) => item.id == data.id);
      this.markerList[index].infoWindow.show = false;
    },
    // 告警信息鼠标移入
    infoWindowHover(data) {
      this.relieves();
      const index = this.markerList.findIndex((item) => item.id == data.id);
      this.markerList[index].infoWindow.show = true;
    },
    // 告警信息鼠标移出
    infoWindowCls(data) {
      //总承包部-项目经理、监理单位-总监理工程师、建设公司-值班领导
      const index = this.markerList.findIndex((item) => item.id == data.id);
      setTimeout(() => {
        this.markerList[index].infoWindow.show = false;
      }, 2000);
    },

    // 查询地铁线路工点树
    queryLineSiteTree() {
      const lineSiteData = JSON.parse(localStorage.getItem("DetailsTree"));
      let list = [];
      if (lineSiteData) {
        list = lineSiteData;
        this.mapTree(list);
      } else {
        tripleTree({}).then((res) => {
          if (res.code == 0) {
            localStorage.setItem("DetailsTree", JSON.stringify(res.data));
            list = res.data;
            this.mapTree(list);
          }
        });
      }
    },
    treeToList(list) {
      let res = [];
      for (const item of list) {
        const { children, ...i } = item;
        if (children && children.length) {
          res = res.concat(this.treeToList(children));
        }
        res.push(i);
      }
      return res;
    },
    //地图绘点
    mapTree(data) {
      let colors = "#01963F";
      let url = require("../../../assets/map/gongdian.png");
      data.forEach((e) => {
        switch (e.name) {
          case "广州市轨道交通十八号线":
            colors = "#01963F";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通13号线二期线路":
            colors = "#026CB6";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市城市轨道交通十四号线二期工程":
            colors = "#956339";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州东至花都天贵城际项目":
            colors = "#901D42";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通二十二号线":
            colors = "#E6037D";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通十二号线及同步实施工程总承包项目":
            colors = "#950885";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通五号线东延段及同步实施工程":
            colors = "#F977A3";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通十号线":
            colors = "#99D317";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通7号线二期线路":
            colors = "#B159D4";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通11号线路":
            colors = "#55CAE2";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "广州市轨道交通三号线东延段":
            colors = "#5E5EF7";
            url = require("../../../assets/map/gongdian.png");
            break;
          case "芳白城际":
            colors = "#95D30C";
            url = require("../../../assets/map/gongdian.png");
            break;
          default:
            break;
        }
        const nodes = this.treeToList(e.children);
        const nodeDatas = this.treeToList(e.children);

        for (let item of nodes) {
          // 将返回的围栏坐标集合并到
          if (item.lat != 0) {
            let p = bd_encrypt(item.lng, item.lat);
            // 添加工点
            item.coord = [p.lat, p.lng];
            item.url = url;
            item.infoWindow = {
              show: false,
            };
            item.markerShow = "";
            this.workPoints.push(item);
          }
          if (item.fance !== null) {
            let line = {
              id: e.uuid,
              name: e.name,
              polylinePath: [],
              color: colors,
            };
            item.fance.forEach((el) => {
              let q = bd_encrypt(el.lng, el.lat);
              let d = { lng: q.lat, lat: q.lng };
              line.polylinePath.push(d);
            });
            this.polylineList.push(line);
          }
        }
        // 物资库
        for (let e of nodeDatas) {
          // 将返回的围栏坐标集合并到
          if (e.lat != 0) {
            e.url = require("../../../assets/map/material.png");
            let p = bd_encrypt(e.lng, e.lat);
            e.coord = [p.lat, p.lng];
            e.infoWindow = {
              show: false,
            };
            // demo.markerShow = "";
            this.secZhiMarkerList.push(e);
          }
        }
      });
    },
    // 预警点渲染
    getsiteAlarms() {
      let params = {
        alarmStatus: "alarming",
        // uploadUnitType: this.unitType,
        uploadUnitType: this.unitType,
        // monitor 监理单位
        // construct 施工单位
      };
      getsiteAlarm(params).then((res) => {
        let list = res.data.list;
        localStorage.setItem("Event_Information", JSON.stringify(list));
        list.forEach((e) => {
          e.url = require("../../../assets/map/warning.png");
          let p = bd_encrypt(e.xcoor, e.ycoor);
          e.coord = [p.lat, p.lng];
          e.infoWindow = {
            show: false,
          };
        });
        this.earlyWarnList = list;
      });
      // });
    },
    // 地图响应事件渲染
    getHomeData() {
      let params = {
        status: "execute",
      };
      getHomeDatas(params).then((res) => {
        let list = res.data.list;
        console.log("事件类型", list);
        localStorage.setItem("Response_Information", JSON.stringify(list));
        list.forEach((e) => {
          e.url = require("../../../assets/map/warning.png");
          e.size = {
            width: 30,
            height: 56,
          };
          let p = bd_encrypt(e.xcoor, e.ycoor);
          e.coord = [p.lat, p.lng];
          // e.infoWindow = {
          //   show: false,
          // };
          e.infoWindow = {
            show: false,
          };
        });
        this.markerList = list;
      });
    },
    // 查询物资库
    // getAnZhi() {
    //   let params = {
    //     lineId: "-",
    //     siteId: "-",
    //     departmentId: "-",
    //     resettlementSite: "-",
    //   };
    //   queryMentList(params).then((res) => {
    //     if (res.code == 0) {
    //       let list = res.data;
    //       list.forEach((e) => {
    //         e.url = require("../../../assets/map/material.png");
    //         let p = bd_encrypt(e.lng, e.lat);
    //         e.coord = [p.lng, p.lat];
    //         e.infoWindow = {
    //           show: false,
    //         };
    //       });
    //       // this.secZhiMarkerList = list;
    //     }
    //   });
    // },
    // 查询专职救援队
    getJiuYuan() {
      let params = {
        teamType: 1,
        teamName: this.formInline.searchName ? this.formInline.searchName : "-",
        personName: this.formInline.searchName
          ? this.formInline.searchName
          : "-",
        materialsName: "-",
      };
      queryTeamLists(params).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          list.forEach((e) => {
            e.url = require("../../../assets/map/rescueTeam.png");
            let p = bd_encrypt(e.lng, e.lat);
            e.coord = [p.lat, p.lng];
            e.infoWindow = {
              show: false,
            };
            // e.arrange = {
            //   show: false,
            // };
          });
          this.rescueTeamList = list;
        }
      });
    },
    // 兼职救援队
    getTeamList() {
      let param = {
        teamType: 2,
        teamName: this.formInline.searchName ? this.formInline.searchName : "-",
        personName: this.formInline.searchName
          ? this.formInline.searchName
          : "-",
        materialsName: "-",
      };
      queryTeamLists(param).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          list.forEach((e) => {
            e.url = require("../../../assets/map/jobTeam.png");
            e.coord = [e.lng, e.lat];
            e.infoWindow = {
              show: false,
            };
          });
          this.partTimeTeamList = list;
        }
      });
    },
    //查询医院
    getHospital() {
      let param = {
        resourceType: "医院",
      };
      getResource(param).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          list.forEach((e) => {
            e.url = require("../../../assets/map/hospital.png");
            e.coord = [e.lng, e.lat];
            e.infoWindow = {
              show: false,
            };
          });
          this.hospitals = list;
        }
      });
    },
    // 获取应急联络
    getNnits() {
      let param = {
        resourceType: "应急联络",
      };
      getResource(param).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          list.forEach((e) => {
            e.url = require("../../../assets/map/municipal.png");
            e.coord = [e.lng, e.lat];
            e.infoWindow = {
              show: false,
            };
          });
          this.units = list;
        }
      });
    },

    // 单兵
    pawn(data) {
      console.log("单兵", data);
    },
    voice() {
      this.$message.warning("语音");
    },
    video() {
      this.$message.warning("视频");
    },
    // 物资库
    handleLibrary(data) {
      this.$refs.library.handleOpen();
      this.libraryData = data;
    },
    libraryClose() {
      this.$refs.library.handleClose();
    },
    // 专职救援队
    rescue(data) {
      this.$refs.rescueDialog.handleOpen();
      // this.$refs.partRescueDialog.handleOpen();
      this.fullTimeTeam = data;
    },
    // 专职救援队关闭
    rescueClose() {
      this.$refs.rescueDialog.handleClose();
    },
    // 兼职救援队
    openPartTeam(data) {
      this.$refs.partRescueDialog.handleOpen();
      // this.$refs.partRescueDialog.handleOpen();
      this.partRescue = data;
    },
    rescueCls(data) {
      // 单兵
      const index = this.rescueTeamMarkerList.findIndex(
        (item) => item.id == data.id
      );
      this.rescueTeamMarkerList[index].infoWindow.show = false;
    },
    // 兼职救援队关闭
    partRescueClose() {
      this.$refs.partRescueDialog.handleClose();
    },
    //医院点击事件
    openHospital(data) {
      this.relieves();
      const index = this.hospitalList.findIndex((item) => item.id == data.id);
      this.hospitalList[index].infoWindow.show = true;
    },
    hospitalCls(data) {
      const index = this.hospitalList.findIndex((item) => item.id == data.id);
      this.hospitalList[index].infoWindow.show = false;
    },
    //应急联络点击事件
    openUnits(data) {
      this.relieves();
      const index = this.unitsList.findIndex((item) => item.id == data.id);
      this.unitsList[index].infoWindow.show = true;
    },
    unitsCls(data) {
      const index = this.unitsList.findIndex((item) => item.id == data.id);
      this.unitsList[index].infoWindow.show = false;
    },

    //物资库
    selectAnZhi() {
      if (this.isAnZhi) {
        this.materLibrary = [];
      } else {
        this.materLibrary = this.secZhiMarkerList;
      }
      this.isAnZhi = !this.isAnZhi;
    },
    //专职救援队
    selectJiuYuan() {
      if (this.isJiuYuan) {
        this.rescueTeamMarkerList = [];
      } else {
        this.rescueTeamMarkerList = this.rescueTeamList;
        // this.rescueTeamMarkerList.forEach((item) => {
        //   item.arrange.show = false;
        // });
      }
      this.isJiuYuan = !this.isJiuYuan;
    },
    // 兼职救援队
    partJiuYuan() {
      if (this.isPartJiuYuan) {
        this.partTimeTeam = [];
      } else {
        this.partTimeTeam = this.partTimeTeamList;
      }
      this.isPartJiuYuan = !this.isPartJiuYuan;
    },
    // 单兵
    single() {
      if (this.isSingle) {
        this.rescueTeamMarkerList = [];
      } else {
        this.rescueTeamMarkerList = this.sectaDiaoMarkerList;
        this.rescueTeamMarkerList.forEach((item) => {
          item.arrange.show = false;
        });
      }
      this.isSingle = !this.isSingle;
    },
    // 医院
    selectHospital() {
      if (this.isHospital) {
        this.hospitalList = [];
      } else {
        this.hospitalList = this.hospitals;
        // this.hospitalList.forEach((item) => {
        //   item.arrange.show = false;
        // });
      }
      this.isHospital = !this.isHospital;
    },
    // 应急联络
    selectMunicipal() {
      if (this.isMunicipal) {
        this.unitsList = [];
      } else {
        this.unitsList = this.units;
        // this.rescueTeamMarkerList.forEach((item) => {
        //   item.arrange.show = false;
        // });
      }
      this.isMunicipal = !this.isMunicipal;
    },

    //关注弹窗内按钮
    goData() {
      this.$message("数据对接中，敬请期待。");
    },
    // 分页相关
    handleSizeChange(val) {
      this.pages.pageSize = val;
      if (this.modelTitle == "物资") {
        this.typeData = 125;
        this.getTableData();
      } else if (this.modelTitle == "设备") {
        this.typeData = 126;
        this.getTableData();
      } else if (this.modelTitle == "值班人员") {
        this.typeData = 96;
        this.getData();
      }
    },

    handleCurrentChange(val) {
      this.pages.pageNo = val;
      if (this.modelTitle == "物资") {
        this.typeData = 125;
        this.getTableData();
      } else if (this.modelTitle == "设备") {
        this.typeData = 126;
        this.getTableData();
      } else if (this.modelTitle == "值班人员") {
        this.typeData = 96;
        this.getData();
      }
    },
    // 获取列表
    getTableData() {
      queryMaterialsList({
        lineId: this.lineId,
        siteId: this.siteId,
        type: this.typeData,
        label: "-",
        modelNo: "-",
        pageNo: this.pages.pageNo,
        pageSize: this.pages.pageSize,
      }).then((res) => {
        if (res.code == 0) {
          this.pageLoading = false;
          this.tableData = res.data.records;
          this.tableData &&
            this.tableData.forEach((item) => {
              if (item.state == 0) {
                item.state = "待修";
              } else {
                item.state = "完好";
              }
            });
          this.pages.count = res.data.count;
          this.pages.total = res.data.total;
        }
      });
    },
    closeDialog() {
      this.pages.pageNo = 1;
      this.pages.pageSize = 10;
    },
    // 信息发布
    newsIssue(data) {
      this.infoNewsData = data;
      this.$refs.infoDialog.handleOpen();
    },
    // 事件结束
    eventEnd(data) {
      console.log("事件结束", data);
      this.$refs.eventEnd.handleOpen();
    },
    eventEndClose() {
      this.$refs.eventEnd.handleClose();
    },
    // 视频监控
    monitor(data) {
      console.log("视频监控", data);
    },
    // 物资
    materials(data) {
      this.tableData = [];
      this.pageLoading = true;
      this.modelTitle = "物资";
      this.columns = [
        { prop: "label", label: "名称" },
        { prop: "modelNo", label: "规格型号" },
        { prop: "siteName", label: "所属工点" },
        { prop: "address", label: "详细位置" },
        { prop: "responsiblePersonName", label: "负责人" },
        { prop: "factory", label: "生产厂家" },
        { prop: "unit", label: "单位" },
        { prop: "count", label: "数量" },
        { prop: "inTime", label: "进场时间" },
        { prop: "expire", label: "有效期" },
        { prop: "state", label: "性能状况" },
        { prop: "remark", label: "备注" },
      ];
      this.$refs.modalDetail.handleOpen();
      this.lineId = data.parent;
      this.siteId = data.id;
      this.typeData = 125;
      this.getTableData();
    },

    // 设备
    facility(data) {
      this.tableData = [];
      this.pageLoading = true;
      this.modelTitle = "设备";
      this.columns = [
        { prop: "label", label: "名称" },
        { prop: "modelNo", label: "规格型号" },
        { prop: "siteName", label: "所属工点" },
        { prop: "address", label: "详细位置" },
        { prop: "responsiblePersonName", label: "负责人" },
        { prop: "factory", label: "生产厂家" },
        { prop: "unit", label: "单位" },
        { prop: "count", label: "数量" },
        { prop: "inTime", label: "进场时间" },
        { prop: "expire", label: "有效期" },
        { prop: "state", label: "性能状况" },
        { prop: "remark", label: "备注" },
      ];
      this.$refs.modalDetail.handleOpen();
      this.lineId = data.parent;
      this.siteId = data.id;
      this.typeData = 126;
      this.getTableData();
    },
    getData() {
      this.pageLoading = true;
      const params = {
        departmentId: "-",
        grouping: "-",
        lineId: this.lineId,
        page: this.pages.pageNo,
        realName: "-",
        siteId: this.siteId,
        size: this.pages.pageSize,
        type: this.typeData,
        vendorId: "-",
      };
      queryWorkerListByPage(params).then((res) => {
        if (res.code == 0) {
          this.pageLoading = false;
          this.tableData = res.data.records;
          this.pages.total = res.data.total;
          this.pages.count = res.data.count;
        } else {
          this.pageLoading = false;
        }
      });
    },
    // 值班人员
    operatorDuty(data) {
      this.tableData = [];
      this.typeData = 96;
      this.lineId = data.parent;
      this.siteId = data.id;
      this.getData();
      this.modelTitle = "值班人员";
      this.columns = [
        { prop: "realName", label: "值班人员名称" },
        { prop: "phone", label: "	值班人员电话" },
      ];
      this.$refs.modalDetail.handleOpen();
    },
    // 工点信息窗口关闭事件
    InfoMouseLeave(data) {
      const index = this.workPoints.findIndex((item) => item.id == data.id);
      this.workPoints[index].infoWindow.show = false;
      let re = this.workPoints[index];
      this.workPoints.splice(index, 1, re);
      this.lineShow = false;
    },
    // 工点信息窗口打开事件
    InfoMouseEnter(data) {
      this.lineShow = true;
      this.workPoints.forEach((e) => {
        e.infoWindow.show = false;
      });
      const index = this.workPoints.findIndex((item) => item.id == data.id);
      this.workPoints[index].infoWindow.show = true;

      let re = this.workPoints[index];
      this.workPoints.splice(index, 1, re);
    },
    // 工点点击跳转事件
    jumpWorkPoint(data) {
      this.$router.push({ path: "/workPoint", query: { id: data.id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.modalDetail ::v-deep.el-pager {
  position: relative;
  li {
    border-radius: 0px !important;
  }
}

::v-deep .el-pagination {
  width: 350px;
  display: flex;
  justify-content: center;
}

::v-deep .el-table {
  width: 100%;

  background: transparent;

  .el-table__body-wrapper {
    height: 300px;
  }

  &::before {
    background-color: #143363;
  }

  thead {
    color: #000;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: rgba(20, 51, 99, 0.5);
  }

  tr,
  th {
    background-color: transparent;
  }

  td {
    border-bottom: 1px solid #143363;
  }

  .cell {
    color: #fff;
  }

  th.is-leaf {
    border: 1px solid #143363;
  }
}

::v-deep .el-table--border {
  border: 1px solid #143363;

  .el-table--enable-row-hover {
    background-color: #143363;
  }

  td {
    border-right: 1px solid #143363;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: rgba(0, 46, 117, 0.4);
}

.map {
  width: 100%;
  height: 100%;
}

//弹窗
.el-dialog,
.el-pager li {
  background: rgba(52, 73, 85, 0.3);
  border: 1px solid #47a2d3;
  border-radius: 10px;
  color: #fff;
}

.el-dialog__wrapper {
  background: rgba(0, 0, 0, 0.8);
}

.el-dialog__title {
  color: #fff;
}

.el-form-item__label {
  color: #fff;
}

// .el-input__inner {
//   background-color: rgba(2, 2, 6, 1);
//   color: #fff;
//   border-radius: 4px;
//   border: 1px solid #4d4d4d;
// }
.el-dialog__headerbtn .el-dialog__close {
  color: #f9aa33;
}

.search-wrapper {
  position: absolute;
  left: 2%;
  top: 2%;
  z-index: 99;
  display: flex;
  flex-direction: column;

  ::v-deep .el-input__inner {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background: rgba(24, 70, 127, 0.8) !important;
    border: 1px solid #173f58 !important;
    border-right: none !important;
    width: 240px;
  }

  ::v-deep .el-input-group__append {
    background: #43a5e9;
    border: 1px solid #43a5e9;
    border-left: none;
    color: #fff;
  }
}
.information,
.keeper {
  width: 70px;
  height: 70px;
  background: rgba(24, 70, 127, 0.8);

  color: #409eff;
  border: 1px solid #409eff;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  .supervise {
    .keepers {
      width: 27px;
      height: 27px;
      margin: 4px auto;

      background-image: url(../../../assets/map/keepers.png);
    }
    .issue {
      width: 22px;
      margin: 4px auto;
      height: 24px;
      background-image: url(../../../assets/map/issue.png);
    }
  }
}
// .keeper {
//   width: 70px;
//   height: 70px;
//   background: red;
//   margin-top: 10px;
// }

.handle-wrapper {
  position: fixed;
  bottom: 30px;
  left: 50%;
  margin-left: -400px;
  width: 800px;
  height: 5.56vh;

  .corner-wrap {
    width: 100%;
    height: 100%;
    position: absolute;

    .corner {
      width: 16px;
      height: 16px;
    }

    .corner-left-top {
      border-left: 2px solid #43a5e9;
      border-top: 2px solid #43a5e9;
      position: absolute;
      left: -1px;
      top: -1px;
    }

    .corner-right-top {
      border-right: 2px solid #43a5e9;
      border-top: 2px solid #43a5e9;
      position: absolute;
      right: -1px;
      top: -1px;
    }

    .corner-right-bottom {
      border-right: 2px solid #43a5e9;
      border-bottom: 2px solid #43a5e9;
      position: absolute;
      right: -1px;
      bottom: -1px;
    }

    .corner-left-bottom {
      border-left: 2px solid #43a5e9;
      border-bottom: 2px solid #43a5e9;
      position: absolute;
      left: -1px;
      bottom: -1px;
    }
  }

  .handle-content {
    width: 100%;
    height: 100%;

    .handle-item {
      margin-left: 10px;
      position: relative;
      background: rgba(8, 23, 66, 0.9);
      border: 1px solid #002e75;
      box-shadow: 0px 0px 23px 6px #002e75 inset;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        .item-content {
          .item-content-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;

            &::before {
              display: inline-block;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url(../../../assets/map/rescueTeam.png);
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center;
              margin-right: 10px;
            }
          }
          .partJob {
            &::before {
              background-image: url(../../../assets/map/jobTeam.png);
            }
          }
          .hospital {
            &::before {
              background-image: url(../../../assets/map/hospital.png);
            }
          }
          .singlePawn {
            &::before {
              background-image: url(../../../assets/map/singlePawn.png);
            }
          }
          .municipal {
            &::before {
              background-image: url(../../../assets/map/municipal.png);
            }
          }

          .item-content-anzhi {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;

            &::before {
              display: inline-block;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url(../../../assets/map/material.png);
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center;
              margin-right: 10px;
            }
          }
        }
      }

      &:first-child {
        margin-left: 0;

        .item-content {
          .item-content-wrapper {
            &::before {
              display: inline-block;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url(../../../assets/map/rescueTeam.png);
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center;
              margin-right: 10px;
            }
            .partJob {
              background-image: url(../../../assets/map/jobTeam.png);
            }
            .singlePawn {
              background-image: url(../../../assets/map/singlePawn.png);
            }
            .hospital {
              background-image: url(../../../assets/map/hospital.png);
            }
            .municipal {
              background-image: url(../../../assets/map/municipal.png);
            }
          }

          .item-content-anzhi {
            &::before {
              display: inline-block;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url(../../../assets/map/material.png);
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center;
              margin-right: 10px;
            }
          }
        }
      }

      .item-content {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 99;

        .item-content-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          color: #fff;
          padding: 0 10px;

          img {
            width: 1.98vw;
          }
        }

        .item-content-anzhi {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          color: #fff;
          padding: 0 10px;

          img {
            width: 20px;
          }
        }
      }
    }
  }
}

// 信息弹窗样式
::v-deep .BMap_pop {
  > div,
  > img:nth-child(10) {
    display: none;
    overflow: unset;
  }

  > div:nth-child(9) {
    display: block;
    overflow: unset;
    min-width: 320px !important;
    top: 20px !important;
  }

  // > div:nth-child(8) {
  //   /*display: block;*/
  // }
  .BMap_top {
    display: none;
  }

  .BMap_center {
    background: transparent;
    border: none;
    position: sticky !important;
    height: 100%;
  }
}
::v-deep .BMap_bubble_content {
  background: #081742;
  box-shadow: 0px 0px 8px 0px rgba(0, 14, 38, 0.2);
  border: 1px solid #173f58;
  padding: 20px;
  position: relative;
  // background: #fff;

  .info-wrapper {
    width: 100%;

    p {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
    }
  }

  .btn-wrapper {
    .el-button {
      padding: 7px 16px;
    }
  }

  .info-header {
    height: 18px;
    margin-bottom: 20px;

    .title {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      // &::before {
      //   display: inline-block;
      //   height: 18px;
      //   width: 18px;
      //   content: "";
      //   background: red;
      //   margin-right: 5px;
      // }
    }

    .close-btn {
      font-size: 12px;
      color: #fff;
      cursor: pointer;
    }
  }

  .triangle {
    width: 40px;
    height: 40px;
    background: #081742;
    border: 1px solid #173f58;
    transform: rotate(-45deg);
    position: absolute;
    z-index: -99;
    left: 41%;
  }
}
.info-rescue {
  width: 200px;
  height: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  background-color: #fff;
}
.close-btn {
  float: right;
  font-size: 12px;
  color: #000;
  cursor: pointer;
}
// 医院
.info-hospital {
  width: 200px;
  height: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  // background-color: #fff;
}
.close-hospital {
  position: absolute;
  top: 10px;
  left: 180px;
  font-size: 12px;
  color: #000;
  cursor: pointer;
}

::v-deep.el-table--border {
  &::after {
    width: 0px;
  }
}
.resources ::v-deep .el-input__inner {
  width: 120px !important;
  color: #c9cbd0;
}
::v-deep .el-table thead.is-group th {
  background: #f5f7fa;
  font-size: 14px;
  color: #101010;
}
//显示滚动条
::v-deep ::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  display: block !important;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}
</style>
<style>
.anchorBL {
  display: none !important;
}

.ops {
  opacity: 0.4;
}
</style>
