<template>
  <div class="statistical">
    <el-dialog
      type="text"
      :title="title"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      center
    >
      <el-form :inline="true" :model="statistics" class="demo-form-inline">
        <el-form-item label="应急响应类型:">
          <el-input v-model="statistics.regiontype" :disabled="true">
          </el-input>
        </el-form-item>
        <el-form-item label="应急级别:">
          <el-input v-model="statistics.level" :disabled="true"> </el-input>
        </el-form-item>
      </el-form>
      <el-table
        :data="statisData"
        border
        style="width: 100%"
        :header-cell-style="{ background: '#E8E8E8' }"
      >
        <el-table-column prop="info" label="统计项"> </el-table-column>
        <el-table-column prop="historyCount" label="历史上报数量">
        </el-table-column>
        <el-table-column prop="currentCount" label="新增数量">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.currentCount"
              @change="onInputChange(scope.row)"
            >
            </el-input>
          </template>
        </el-table-column>
      </el-table>
      <div class="texter" style="margin-top: 40px">
        其他应急措施落实及受灾害影响情况
      </div>
      <el-input
        style="margin-top: 20px"
        type="textarea"
        :autosize="{ minRows: 8, maxRows: 16 }"
        placeholder="请输入内容"
        v-model="remark"
      >
      </el-input>
      <span slot="footer" class="dialog-footer" v-if="isFooterShow">
        <el-button @click="save">保存</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getrecord, getfill, getfilladd } from "@/api/response"
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: () => false
    },
    cancel: {
      type: Object,
      default: () => { }
    },
    isFooterShow: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    innerVisible: {
      handler () {
        this.dialVisible = this.dialogVisible
      },
      deep: true
    },
    cancel: {
      handler () {
        this.statisData = this.cancel.statisticSummaryList
        this.remark = this.cancel.remark
      },
      deep: true
    }
  },
  data () {
    return {
      title: "广州市交通运输系统三防工作情况统计表",
      dialVisible: false,
      statistics: {
        regiontype: "",
        level: ""
      },
      options: [],
      statisData: [],
      remark: ""
    }
  },
  created () {
    this.record()
  },
  methods: {
    //应急响应记录详情和历史填报
    record () {
      getrecord({ id: 35 }).then(res => {
        if (res.code == 0) {
          this.statistics.regiontype = res.data.type.label
          this.statistics.level = res.data.currentLevel.label
        }
      })
      getfill({ responseRecordId: 35, departmentId: "1" }).then(res => {
        if (res.code == 0) {
          this.statisData = res.data
        }
      })
    },
    //提交
    submit () {
      getfilladd({
        departmentId: "1",
        fillRecordId: 1,
        info: this.statisData,
        responseRecordId: 35,
        remark: this.remark,
        type: 1
      }).then(res => {
        if (res.code == 0) {
          this.$message({
            message: "提交成功",
            type: "success"
          })
          this.statisData = []
          this.remark = ""
          this.$emit("occlude")
        } else {
          this.$message.error("提交失败")
        }
      })
    },
    //保存
    save () {
      getfilladd({
        departmentId: "1",
        fillRecordId: 1,
        info: this.statisData,
        responseRecordId: 35,
        remark: this.remark,
        type: 0
      }).then(res => {
        if (res.code == 0) {
          this.$message({
            message: "保存成功",
            type: "success"
          })
          this.statisData = []
          this.remark = ""
          this.$emit("occlude")
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    //关闭
    handleClose () {
      this.$emit("occlude")
      this.statisData = []
      this.remark = ""
    },
    onInputChange (row) {
      console.log(row)
    }
  }
};
</script>

<style lang="scss" scoped></style>
