<template>
  <!--三防工作简报数据汇总质安部  -->
  <div>
    <div style="margin-bottom: 20px">
      <el-radio-group
        size="mini"
        v-model="degree"
        v-for="item in times"
        :key="item.time"
        @change="degreeChange"
      >
        <el-radio-button
          style="margin:0 10px 5px 0"
          :label="item.label"
        ></el-radio-button>
      </el-radio-group>
    </div>
    <div class="huizong">
      <el-table
        ref="tableData"
        :data="tableData"
        id="tableData"
        border
        :row-style="{ height: '30px' }"
        style="width:100%"
      >
        <el-table-column
          v-if="userDept == '4'"
          label="建管部"
          prop="name"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          v-if="userDept == '3'"
          label="线路-工点"
          prop="name"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="派出工作组数"
          align="center"
          prop="workGroupCount"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="checkerCount"
          label="出动检查人数"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="siteCount"
          label="检查在建工点数"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="hiddenDangerCount"
          label="发现隐患数量"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="rectificationCount"
          label="整改隐患数量"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="evacueesCount"
          label="撤离人员"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column label="操作" align="center" v-if="userDept == '4'">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.name != '汇总'"
              @click="check(scope.row, tableData)"
              type="text"
              size="small"
            >
              查看详情
            </el-button>
            <span v-else>/</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="text-center mt-8" style="position:relative;z-index:10000000">
      <el-button type="primary" @click="exportsubmit">导出</el-button>
    </div>
    <!-- 查看详情 -->
    <Modal
      ref="checkDialog"
      :width="'1300px'"
      title="查看详情"
      @closeDialog="checkClose"
      class="checkDialog"
    >
      <checkPage
        v-if="checkDialog"
        ref="checkmell"
        @checkClose="checkClose"
        :checkId="checkId"
        :checkName="checkName"
        :cuns="cuns"
        :qualityResponseId="qualityResponseId"
        :tableDatas="tableDatas"
      ></checkPage>
    </Modal>
    <!-- 导出 -->
    <Modal
      ref="exportDialog"
      :width="'400px'"
      title="导出"
      @closeDialog="diaLogClose"
      class="exportDialog"
      v-loading="exloading"
    >
      <div>
        <el-checkbox-group v-model="checkboxTimes" size="small">
          <el-checkbox
            :label="item.label"
            v-for="item in times"
            :key="item.time"
            :disabled="exloading"
          ></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="text-center mt-8">
        <el-button type="primary" :disabled="exloading" @click="exportGo"
          >确认</el-button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import { getSelection, getRes } from "@/api/response";
import {
  listSupervisionSummaryInfo,
  listSiteSummaryInfo,
  exportSummaryInfo,
  listSiteDetailSummaryInfo,
} from "@/api/responseTem";
import checkPage from "./checkPages.vue";
import Modal from "@/components/Modal/modal.vue";

export default {
  props: {
    qualityResponseId: {
      type: Number,
      default: () => null,
    },
    userDept: {
      type: String,
      default: "1",
    },
  },
  components: {
    checkPage,
    Modal,
  },
  data() {
    return {
      times: [],
      degree: "第1次",
      tableData: [
        {
          reportcishu: "三号线",
        },
      ],
      tableTotalData: [],
      checkDialog: false,
      formData: {
        emergency: "",
      },
      formInline: {
        responseType: "",
        responseLevel: "",
      },
      leveloptions: [],
      checkId: "", //当前行的id
      checkName: "", //事件id
      // buildDepartmentResponseId: "",
      supervisionName: "",
      cuns: 1,
      exportDialog: false,
      checkboxTimes: [],
      exloading: false,
      tableDatas: [],
    };
  },
  created() {},
  watch: {
    qualityResponseId: {
      handler(val) {
        if (val) {
          this.getGenre(val);
          this.$nextTick(() => {
            this.$refs.tableData.doLayout(); // el-table添加ref="tableName"
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    console.log(this.linetes, "质安部查看的线路工点");
    this.getData();
    this.getTableData();
  },
  methods: {
    getTableData() {
      let params = {
        responseId: this.qualityResponseId,
      };
      if (this.userDept == "3") {
        listSiteSummaryInfo(params).then((res) => {
          this.times = [];
          if (res.code == 0) {
            // this.tableForm.tableData = res.data;
            res.data.forEach((element) => {
              this.times.push({
                time: element.times,
                label: "第" + element.times + "次",
              });
            });
            this.tableData = res.data[0].summaryInfoList;
            this.tableTotalData = res.data;
          }
        });
      } else {
        listSupervisionSummaryInfo(params).then((res) => {
          this.times = [];
          if (res.code == 0) {
            // this.tableForm.tableData = res.data;
            res.data.forEach((element) => {
              this.times.push({
                time: element.times,
                label: "第" + element.times + "次",
              });
            });
            this.tableData = res.data[0].summaryInfoList;
            this.tableTotalData = res.data;
          }
        });
      }
    },
    getData() {
      let params = {};
      getSelection(params).then((res) => {
        if (res.code == 0 && res.data && res.data.length != 0) {
          this.leveloptions = [];
          let id1 = res.data.findIndex((item) => {
            if (item.label == "汇总") {
              return true;
            }
          });
          res.data.splice(id1, 1);
          res.data.forEach((element) => {
            this.leveloptions.push({
              label: element.label,
              value: element.value,
            });
          });
        }
      });
    },
    degreeChange(val) {
      var arr = val.replace(/\D/g, "");
      this.cuns = arr;
      this.tableData = [];
      this.tableTotalData.forEach((item) => {
        if (arr == item.times) {
          this.tableData = item.summaryInfoList;
        }
      });
    },
    getGenre(val) {
      let id = val;
      getRes(id).then((res) => {
        if (res.code == 0) {
          this.formInline.responseLevel = res.data.currentLevel.label;
          this.formInline.responseType = res.data.type.label;
        }
      });
    },
    emergencyChange(val) {
      let params = {
        manageCurrentId: val,
      };
      listSupervisionSummaryInfo(params).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data[0].statisticFormDtoList;
        }
      });
    },
    exportGo() {
      if (this.checkboxTimes.length > 0) {
        this.exloading = true;
        this.checkboxTimes.forEach((el) => {
          this.derive(el);
        });
      } else {
        this.$message.warning("请至少选择一个提交次数");
      }
    },
    derive(data) {
      let parms = {
        times: data.replace(/\D/g, ""),
        responseId: this.qualityResponseId,
      };
      exportSummaryInfo(parms).then((res) => {
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel",
        });
        // 3.创建一个临时的url指向blob对象
        let url = window.URL.createObjectURL(blob);
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "三防工作简报" + data + ".xlsx"; //自定义导出文件名
        a.click();
        this.exloading = false;
      });
    },
    check(row, tabdata) {
      this.checkId = row.supervisionUuid;
      this.checkName = row.name;
      let params = {
        supervisionName: this.checkName,
        supervisionUuid: this.checkId,
        times: this.cuns,
        responseId: this.qualityResponseId,
      };
      listSiteDetailSummaryInfo(params).then((res) => {
        if (res.code == 0) {
          this.tableDatas = res.data.summaryInfoList;
          this.$refs.checkDialog.handleOpen();

          this.checkDialog = true;
        }
      });

      // this.$refs.checkmell.getdata();
    },
    checkClose() {
      this.checkDialog = false;
    },
    exportsubmit() {
      this.$refs.exportDialog.handleOpen();
    },
    diaLogClose() {
      this.exportDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table--border {
  border: none;
}
::v-deep .el-table tr {
  background: #081742 !important;
}
::v-deep .el-table__cell.is-leaf {
  background: #081742 !important;
}
::v-deep .el-table::before,
.el-table--border::after {
  background-color: #143363 !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: rgba(0, 46, 117, 0.4);
}
.huizong {
  ::v-deep .el-table {
    background-color: #081742 !important;
    height: 490px !important;
    overflow: auto;
  }
  ::v-deep .el-table--border,
  .el-table::before {
    border-bottom: 0 !important;
    height: 0px;
  }
  .el-table--border::after {
    width: 0;
  }
  ::v-deep .el-table th.is-leaf,
  ::v-deep .el-table--border td {
    border: 1px solid #143363 !important;
  }
}
::v-deep .corner-wrap {
  display: none !important;
}
::v-deep .el-table:before {
  height: 0px;
}
</style>
