<template>
  <div style="font-size:16px" class="checkDetails">
    <div class="info-wrapper mt-4">
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        border
        height="600"
      >
        <el-table-column type="index" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column
          prop="lineName"
          align="center"
          label="线路"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="siteName"
          align="center"
          label="工点"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="hiddenQuestion"
          align="center"
          label="问题描述"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              style="color: #7073ff; cursor: pointer"
              @click="lookNamePop(scope.row)"
            >
              {{ scope.row.hiddenQuestion }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="correctiveApproveStatus"
          align="center"
          label="当前状态"
          show-overflow-tooltip
          :formatter="correctiveApproveStatusFormatter"
        >
        </el-table-column>
        <el-table-column
          prop="checkTime"
          align="center"
          label="发布时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="correctiveTime"
          align="center"
          label="问题整改时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="categoryName"
          align="center"
          label="整改单位"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
    </div>

    <Modal
      ref="problemDetails"
      :width="'70%'"
      title="三防问题详情"
    >
      <ProblemDetails
        v-if="dialogVisible"
        :hiddenData="hiddenData"
      ></ProblemDetails>
    </Modal>
  </div>
</template>

<script>
import { getDetails } from "@/api/response";

import Modal from "@/components/Modal/modal.vue";
import ProblemDetails from "./problemDetails.vue";

export default {
  props: {
    parmsData: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ProblemDetails,
    Modal,
  },
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      hiddenData: {},
      tableLoading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  watch: {
    parmsData: {
      handler(val) {
        if (val) {
          this.tableData = this.parmsData;
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
  },
  methods: {
    // 当前状态
    lookNamePop(val) {
      getDetails(val.id).then((res) => {
        this.hiddenData = res.data;
      });
      this.dialogVisible = true;
      this.$refs.problemDetails.handleOpen();
    },
    // 分页
    handleCurrentChange(val) {
      this.page.pageNo = val;
    },
    correctiveApproveStatusFormatter(row) {
      return row.correctiveApproveStatus == 0
        ? "待整改"
        : row.correctiveApproveStatus == 1
        ? "待监理审批"
        : row.correctiveApproveStatus == 2
        ? "审批不通过"
        : row.correctiveApproveStatus == 3
        ? "完成"
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  background-color: #081742 !important;
}
::v-deep .el-table--border {
  border: none;
}
::v-deep .el-table tr {
  background: #081742 !important;
}
::v-deep .el-table__cell.is-leaf {
  background: #081742 !important;
}
::v-deep .el-table::before,
.el-table--border::after {
  background-color: #143363 !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: rgba(0, 46, 117, 0.4);
}
.info-wrapper {
  ::v-deep .el-table--border,
  .el-table::before {
    border-bottom: 0 !important;
    height: 0px;
  }
  .el-table--border::after {
    width: 0;
  }
  ::v-deep .el-table th.is-leaf,
  ::v-deep .el-table--border td {
    border: 1px solid #143363 !important;
  }
  .checkDetails .el-table__body-wrapper {
    height: 549px;
  }
}
::v-deep .el-dialog__body {
  height: 700px;
  overflow: auto;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
::v-deep .corner-wrap {
  display: none !important;
}
::v-deep .el-table:before {
  height: 0px;
}
</style>
