<template>
  <systemModal
    ref="formModal"
    @modalConfirm="handleSubmit"
    :title="title"
    width="40%"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="120px"
      class="demo-model"
      style="width: 80%"
    >
      <el-form-item
        :label="type == '125' ? '物资名称' : '设备名称'"
        prop="label"
      >
        <el-input
          v-model="model.label"
          :placeholder="type == '125' ? '请输入物资名称' : '请输入设备名称'"
        ></el-input>
      </el-form-item>
      <el-form-item label="规格型号" prop="model">
        <el-input v-model="model.model" placeholder="请输入规格型号"></el-input>
      </el-form-item>
      <el-form-item label="标准数量" prop="standardCount">
        <el-input-number
          v-model="model.standardCount"
          controls-position="right"
          :precision="0"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="现存数量" prop="count">
        <el-input-number
          v-model="model.count"
          controls-position="right"
          :precision="0"
          :min="0"
          :disabled="isEdit"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="计量单位" prop="unit">
        <el-input v-model="model.unit" placeholder="请输入单位"></el-input>
      </el-form-item>
      <el-form-item label="选择用途" prop="usages">
        <el-select v-model="model.usages" multiple placeholder="请选择用途">
          <el-option
            v-for="item in dict.usages"
            :key="item.label"
            :label="item.intro"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="model.remark"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入备注"
        >
        </el-input>
      </el-form-item>
    </el-form>
  </systemModal>
</template>
<script>
import systemModal from '@/components/Modal/systemModal.vue'
import { queryDictByLabel, queryDictNodesById } from '@/api/dict'
import { addMaterials, editMaterials } from '@/api/eres'
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: '新增',
      isEdit: false,
      model: {
        label: '',
        model: '',
        standardCount: '',
        count: '',
        unit: '',
        usages: [],
        remark: '',
        type: '',
        materialType: 2,
        // rescueTeamId: '',
      },
      dict: {
        usages: [],
      },
      loading: false,
      rules: {
        label: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        model: [{ required: true, message: '请输入规格型号', trigger: 'blur' }],
        unit: [{ required: true, message: '请输入计量单位', trigger: 'blur' }],
        usages: [{ required: true, message: '请选择用途', trigger: 'change' }],
        standardCount: [
          { required: true, message: '请输入标准数量', trigger: 'blur' },
          { pattern: /^[+]{0,1}(\d+)$/, message: '请输入合法数量' },
        ],
        count: [
          { required: true, message: '请输入标准数量', trigger: 'blur' },
          { pattern: /^[+]{0,1}(\d+)$/, message: '请输入合法数量' },
        ],
      },
    }
  },
  props: {
    lineSiteData: {
      type: Array,
      default: function() {
        return []
      },
    },
    type: {
      type: Number,
    },
  },
  watch: {
    type: {
      handler(newVal) {
        this.model.type = newVal
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getDictData('物资用途', this.dict.usages)
  },
  methods: {
    handleAdd(record) {
      if (typeof record != 'number') {
        this.title = '编辑'
        this.isEdit = true
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)))
        this.model.usages = []
        const tempArr = JSON.parse(record.usages)
        for (let item of tempArr) {
          this.model.usages.push(String(item))
        }
      } else {
        this.title = '新增'
        this.model.rescueTeamId = record
        this.isEdit = false
      }
      this.handleEdit()
    },
    handleEdit() {
      this.$refs.formModal.handleOpen()
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true
          const params = JSON.parse(JSON.stringify(this.model))
          params.usages = params.usages.join(',')
          if (this.isEdit) {
            editMaterials(params).then((res) => {
              if (res.code == 0) {
                this.$message.success('操作成功')
                this.formReset()
                this.$refs.formModal.handleClose()
                this.$emit('formSubmit')
                this.loading = false
              }
            })
          } else {
            addMaterials(params).then((res) => {
              if (res.code == 0) {
                this.$message.success('操作成功')
                this.formReset()
                this.$refs.formModal.handleClose()
                this.$emit('formSubmit')
                this.loading = false
              }
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
      // this.$refs.formModal.handleClose();
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data)
              this.$forceUpdate()
            }
          })
        }
      })
    },
    formReset() {
      this.$refs.modelForm.resetFields()
    },
  },
}
</script>
<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
