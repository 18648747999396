
const hasPermission = {
    install (Vue) {
          Vue.directive('has', {
            inserted: (el, binding, vnode)=>{
                // console.log("插入");
                filterPermission(el, binding, vnode);
            },
            update: (el, binding, vnode)=>{
                // console.log("更新");
                filterPermission(el, binding, vnode);
            },
          });
    }
};
function filterPermission(el, binding) {
    const btnPermission = JSON.parse(localStorage.getItem("USER_BTN_AUTH") || "[]");
    // // 权限过滤：过滤出按钮权限
    // for (let auth of btnPermission) {
    //     if (auth.type == 1) {
    //         permissionList.push(auth)
    //     }
    // }
    let permissions = [];
    // 权限处理：指令绑定的值是否再权限列表中
    for (let item of btnPermission) {
        permissions.push(item.resourcePath);
    }
    if (!permissions.includes(binding.value)) {
        el.parentNode && el.parentNode.removeChild(el);
    }
}
export default hasPermission;