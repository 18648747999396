<template>
  <div class="flex justify-start">
    <div class="flex flex-col" style="width: 50%">
      <div class="stCard" style="width: 100%; height: 176px">
        <div class="flex justify-between">
          <div style="font-size: 16px; font-weight: bold; color: #081742">
            待办统计
          </div>
          <div class="flex justify-start">
            <div class="mr-2">
              <el-cascader
                size="small"
                ref="linecas"
                placeholder="选择线路工点"
                v-model="model.gzSite"
                :options="treeData"
                :props="props"
                filterable
                clearable
                collapse-tags
                @change="handleLineSiteChangeFrom"
              ></el-cascader>
            </div>
            <div>
              <el-radio-group
                v-model="model.agencyTimes"
                size="small"
                @change="getDB"
              >
                <el-radio-button label="1">年</el-radio-button>
                <el-radio-button label="2">季</el-radio-button>
                <el-radio-button label="3">月</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="flex justify-between mt-2">
          <div
            class="cenCard flex justify-between cursor-pointer"
            style="background: rgba(23, 145, 255, 0.1)"
            @click="jumps('应急待办统计', 1, '已处理')"
          >
            <div class="flex flex-col">
              <div>已处理</div>
              <div class="cenNum">{{ selectMessageCountList.finishCount }}</div>
            </div>

            <div>
              <img src="@/assets/system/db-2.png" alt="" />
            </div>
          </div>
          <div
            class="cenCard flex justify-between cursor-pointer"
            style="background: rgba(250, 173, 19, 0.1)"
            @click="jumps('应急待办统计', 0, '待处理')"
          >
            <div class="flex flex-col">
              <div>待处理</div>
              <div class="cenNum">
                {{ selectMessageCountList.notFinishCount }}
              </div>
            </div>

            <div>
              <img src="@/assets/system/db-3.png" alt="" />
            </div>
          </div>
          <div
            class="cenCard flex justify-between cursor-pointer"
            style="background: rgba(114, 192, 64, 0.1)"
          >
            <div class="flex flex-col">
              <div>完成率</div>
              <div class="cenNum">{{ selectMessageCountList.finishRatio }}</div>
            </div>

            <div>
              <img src="@/assets/system/db-4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="stCard" style="width: 100%; height: 460px">
        <div class="flex justify-between">
          <div style="font-size: 16px; font-weight: bold; color: #081742">
            应急事件统计
          </div>
          <div class="flex justify-start">
            <div class="mr-2">
              <el-cascader
                size="small"
                ref="linecas"
                placeholder="选择线路工点"
                v-model="model.eventGzSite"
                :options="treeData"
                :props="props"
                filterable
                clearable
                collapse-tags
                @change="handleLineSiteChangeFrom"
              ></el-cascader>
            </div>
            <div>
              <el-radio-group
                v-model="model.eventTimes"
                size="small"
                @change="getYJSJ"
              >
                <el-radio-button label="1">年</el-radio-button>
                <el-radio-button label="2">季</el-radio-button>
                <el-radio-button label="3">月</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div
          class="flex justify-around mt-3 pt-2"
          style="background: rgba(67, 165, 233, 0.05); height: 100px"
        >
          <div
            class="flex flex-col text-center"
            v-for="(val, index) in responseCountDepartmentList.resultList"
            :key="index"
            @click="jumpSJ('应急事件统计', val.cateName, '', '', val.id)"
          >
            <div class="cenNum">{{ val.num }}</div>
            <div>{{ val.cateName }}</div>
          </div>
        </div>
        <div>
          <div class="flex justify-around">
            <div
              id="main2"
              class="mt-3"
              style="width: 40%; height: 300px"
            ></div>
            <div
              id="main4"
              class="mt-3"
              style="width: 40%; height: 300px"
            ></div>
          </div>
        </div>
      </div>
      <div class="stCard" style="width: 100%; height: 350px">
        <div class="flex justify-between">
          <div style="font-size: 16px; font-weight: bold; color: #081742">
            物资盘点统计
          </div>
          <div class="flex justify-start">
            <div class="mr-2">
              <el-cascader
                size="small"
                ref="linecas"
                placeholder="选择线路工点"
                v-model="model.materialsSite"
                :options="treeData"
                :props="props"
                filterable
                clearable
                collapse-tags
                @change="handleLineSiteChangeFrom"
              ></el-cascader>
            </div>
            <div>
              <el-radio-group
                v-model="model.materialsTimes"
                size="small"
                @change="getWZPD"
              >
                <el-radio-button label="1">年</el-radio-button>
                <el-radio-button label="2">季</el-radio-button>
                <el-radio-button label="3">月</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div>
          <div class="flex justify-between">
            <div
              id="main1"
              class="mt-3"
              style="width: 100%; height: 300px"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右边 -->
    <div class="flex flex-col ml-2" style="width: 50%">
      <div class="stCard" style="width: 100%; height: 176px">
        <div class="flex justify-between">
          <div style="font-size: 16px; font-weight: bold; color: #081742">
            应急培训统计
          </div>
          <div class="flex justify-start">
            <div class="mr-2">
              <el-cascader
                size="small"
                ref="linecas"
                placeholder="选择线路工点"
                v-model="model.trainSite"
                :options="treeData"
                :props="props"
                filterable
                clearable
                collapse-tags
                @change="handleLineSiteChangeFrom"
              ></el-cascader>
            </div>
            <div>
              <el-radio-group
                v-model="model.trainTimes"
                size="small"
                @change="getYJPX"
              >
                <el-radio-button label="1">年</el-radio-button>
                <el-radio-button label="2">季</el-radio-button>
                <el-radio-button label="3">月</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div
          class="flex justify-around mt-3 pt-2"
          style="background: rgba(67, 165, 233, 0.05); height: 100px"
        >
          <div
            class="flex flex-col text-center"
            @click="jumpPX('应急培训统计', YJresponseCountVo.total, '全部计划')"
          >
            <div class="cenNum">
              {{ YJresponseCountVo.total }}
            </div>
            <div>全部计划</div>
          </div>
          <div
            class="flex flex-col text-center"
            @click="
              jumpPX('应急培训统计', YJresponseCountVo.finishCount, '已完成')
            "
          >
            <div class="cenNum">
              {{ YJresponseCountVo.finishCount }}
            </div>
            <div>已完成</div>
          </div>
          <div
            class="flex flex-col text-center"
            @click="
              jumpPX('应急培训统计', YJresponseCountVo.notFinishCount, '未完成')
            "
          >
            <div class="cenNum">
              {{ YJresponseCountVo.notFinishCount }}
            </div>
            <div>未完成</div>
          </div>
          <div class="flex flex-col text-center">
            <div class="cenNum">{{ YJresponseCountVo.finishRatio }} %</div>
            <div>完成率</div>
          </div>
        </div>
      </div>
      <div class="stCard" style="width: 100%; height: 460px">
        <div class="flex justify-between">
          <div style="font-size: 16px; font-weight: bold; color: #081742">
            应急演练统计
          </div>
          <div class="flex justify-start">
            <div class="mr-2">
              <el-select
                v-model="model.drillUnit"
                size="small"
                clearable
                placeholder="请选择"
                style="margin-right:5px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-cascader
                size="small"
                ref="linecas"
                placeholder="选择线路工点"
                v-model="model.drillSite"
                :options="treeData"
                :props="props"
                filterable
                clearable
                collapse-tags
                @change="handleLineSiteChangeFrom"
              ></el-cascader>
            </div>
            <div>
              <el-radio-group
                v-model="model.drillTimes"
                size="small"
                @change="getYJPX"
              >
                <el-radio-button label="1">年</el-radio-button>
                <el-radio-button label="2">季</el-radio-button>
                <el-radio-button label="3">月</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div
          class="flex justify-around mt-3 pt-2"
          style="background: rgba(67, 165, 233, 0.05); height: 100px"
        >
          <div
            class="flex flex-col text-center"
            @click="jumpYL('应急演练统计', YJresponseCountVo.total, '全部计划')"
          >
            <div class="cenNum">
              {{ YJresponseCountVo.total }}
            </div>
            <div>全部计划</div>
          </div>
          <div
            class="flex flex-col text-center"
            @click="
              jumpYL('应急演练统计', YJresponseCountVo.finishCount, '已完成')
            "
          >
            <div class="cenNum">
              {{ YJresponseCountVo.finishCount }}
            </div>
            <div>已完成</div>
          </div>
          <div
            class="flex flex-col text-center"
            @click="
              jumpYL('应急演练统计', YJresponseCountVo.notFinishCount, '未完成')
            "
          >
            <div class="cenNum">
              {{ YJresponseCountVo.notFinishCount }}
            </div>
            <div>未完成</div>
          </div>
          <div class="flex flex-col text-center">
            <div class="cenNum">{{ YJresponseCountVo.finishRatio }}%</div>
            <div>完成率</div>
          </div>
        </div>

        <div>
          <div id="main5" class="mt-3" style="width: 100%; height: 260px"></div>
        </div>
      </div>
      <div class="stCard" style="width: 100%; height: 350px">
        <div class="flex justify-between">
          <div style="font-size: 16px; font-weight: bold; color: #081742">
            应急预案统计
          </div>
          <div class="flex justify-start">
            <div class="mr-2">
              <el-cascader
                size="small"
                ref="linecas"
                placeholder="选择线路工点"
                v-model="model.planningSite"
                :options="treeData"
                :props="props"
                filterable
                clearable
                collapse-tags
                @change="handleLineSiteChangeFrom"
              ></el-cascader>
            </div>
          </div>
        </div>
        <div>
          <div class="flex justify-between">
            <div
              id="main3"
              class="mt-3"
              style="width: 100%; height: 300px"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  selectMessageCount,
  responseCountDepartment,
  responseQuestionCount,
  responseChartCount,
  responseCurrentCount,
  proofCount,
} from "@/api/responseTem";
export default {
  components: {},
  props: {},
  data() {
    return {
      props: {
        multiple: true,
        children: "children",
        label: "name",
        value: "uuid",
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      value: "",
      model: {
        // 代办
        agencyTimes: "1",
        // 事件
        eventTimes: "1",
        eventGzSite: [],
        // 物资
        materialsTimes: "1",
        materialsSite: [],
        // 应急培训统计
        trainSite: [],
        trainTimes: "1",
        // 应急演练统计
        drillUnit: "",
        drillSite: [],
        drillTimes: "1",
        // 应急预案统计
        planningSite: [],
        dateType: "1",
        YHdateType: "1",
        gztimes: "1",
        gzSite: [],
      },
      treeData: [],
      lineId: "",
      siteId: "",
      selectMessageCountList: {},
      responseCountDepartmentList: {
        resultList: [],
      },
      responseQuestionCountList: {},
      thisResponseCountVo: {},
      responseCountVo: {},
      gzrResponseCountVo: {},
      totals: 0,
      responseChartCountList: {},
      HPchartCountVo: {},
      HPchartMonthCountVo: {},
      responseCurrentCountList: {},
      YJresponseCountVo: {
        total: 100,
        finishCount: 90,
        notFinishCount: 10,
        finishRatio: 90,
      },
    };
  },
  watch: {},
  methods: {
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    handleLineSiteChangeFrom(val) {
      console.log("工点", val);
      this.getYJPX();
    },
    //应急代办统计
    getDB() {
      let params = {
        dateType: this.model.agencyTimes,
        messageSource: 2,
        noticeCategory: 1,
        siteUuidList: [],
      };
      console.log("change", params);
      selectMessageCount(params).then((res) => {
        if (res.code == 0) {
          this.selectMessageCountList = res.data;
        }
      });
    },
    //应急事件统计
    getYJSJ() {
      let params = {
        dateType: this.model.eventTimes,
        lineUuid: this.model.eventGzSite,
      };
      console.log("应急事件", params);
      responseCountDepartment(params).then((res) => {
        if (res.code == 0) {
          this.responseCountDepartmentList = res.data;
          this.responseCountDepartmentList.resultList = [];
          this.responseCountDepartmentList.resultList.push(
            {
              cateName: "已闭合",
              num: 12,
              id: 1,
            },
            {
              cateName: "未闭合",
              num: 2,
              id: 2,
            }
          );
          let event = [];
          let unEvent = [];

          // 事件已闭合
          event = [
            { value: 10, name: "1级事件" },
            { value: 8, name: "2级事件" },
            { value: 7, name: "3级事件" },
          ];
          // 事件未闭合
          unEvent = [
            { value: 2, name: "事件1" },
            { value: 3, name: "事件2" },
            { value: 4, name: "事件3" },
          ];
          setTimeout(() => {
            this.getCharts2(document.getElementById("main2"), event, "已闭合");
            this.getCharts2(
              document.getElementById("main4"),
              unEvent,
              "已闭合"
            );
          }, 1000);
        }
      });
    },
    //三防隐患问题排查统计
    // getSFYHPC() {
    //   let params = {
    //     dateType: this.model.YHdateType,
    //   };
    //   responseQuestionCount(params).then((res) => {
    //     if (res.code == 0) {
    //       this.responseQuestionCountList = res.data;
    //       this.responseCountVo = this.responseQuestionCountList.responseCountVo;
    //       this.thisResponseCountVo = this.responseQuestionCountList.thisResponseCountVo;
    //       let xData = [];
    //       this.responseQuestionCountList.dataList.forEach((el) => {
    //         xData.push(el.lineName);
    //       });
    //       let series = [];
    //       this.responseQuestionCountList.responseRecordCountVoList.forEach(
    //         (el) => {
    //           series.push({
    //             name: el.label,
    //             type: "bar",
    //             stack: "Ad",
    //             emphasis: {
    //               focus: "series",
    //             },
    //             data: el.numList,
    //           });
    //         }
    //       );
    //       //   setTimeout(() => {
    //       //     this.getCharts(
    //       //       document.getElementById("main1"),
    //       //       xData,
    //       //       series,
    //       //       "隐患",
    //       //       this.responseQuestionCountList.dataList,
    //       //       this.responseQuestionCountList.responseRecordCountVoList
    //       //     );
    //       //   }, 1000);
    //     }
    //   });
    // },

    //应急演练统计
    getYJYL() {
      let params = {
        drillUnit: this.model.drillUnit,
        drillSite: this.model.drillSite,
        drillTimes: this.model.drillTimes,
      };
      console.log("应急演练加载", params);
      proofCount().then((res) => {
        if (res.code == 0) {
          this.proofCountList = res.data;
          let series = [];
          this.proofCountList.proofCountVos.map((item) => {
            if (item.label == "临近既有线路" || item.label == "地势低洼点") {
              item.label = "未完成";
            } else {
              item.label = "已完成";
            }
          });
          this.proofCountList.proofCountVos.forEach((el) => {
            series.push({
              name: el.label,
              type: "bar",
              stack: "Ad",
              emphasis: {
                focus: "series",
              },
              data: el.numList,
            });
          });
          let xdata = [];
          this.proofCountList.dataSet.forEach((el) => {
            xdata.push(el.lineName);
          });
          setTimeout(() => {
            this.getCharts(
              document.getElementById("main5"),
              xdata,
              series,
              "防汛",
              this.proofCountList.dataSet,
              this.proofCountList.proofCountVos
            );
          }, 1000);
        }
      });
      // responseChartCount().then((res) => {
      //   if (res.code == 0) {
      //     this.responseChartCountList = res.data;
      //     this.HPchartCountVo = this.responseChartCountList.chartCountVo;
      //     this.HPchartMonthCountVo = this.responseChartCountList.chartMonthCountVo;
      //   }
      // });
    },
    getWZPD() {
      let params = {
        dateType: this.model.materialsTimes,
        lineUuid: this.model.materialsSite,
      };
      // get(params)
      //   .then((res) => {
      //     if (res.code == 0) {
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      console.log("物资盘点事件", params);
      let materials = [];
      let planning = [];
      // 物资
      materials = [
        { value: 21, name: "已完成" },
        { value: 19, name: "未完成" },
      ];
      // 预案
      planning = [
        { value: 7, name: "正常" },
        { value: 2, name: "已到期" },
      ];
      setTimeout(() => {
        this.getCharts3(
          document.getElementById("main1"),
          materials,
          "物资盘点统计"
        );
        this.getCharts3(
          document.getElementById("main3"),
          planning,
          "应急预案统计"
        );
      }, 1000);
    },
    //应急培训统计
    getYJPX() {
      let arr = [];
      if (this.model.trainSite.length > 0) {
        this.model.trainSite.forEach((el) => {
          arr.push(el[el.length - 1]);
        });
      } else {
        arr = [];
      }
      console.log("应急培训统计工点", arr);
      let params = {
        dateType: this.model.trainTimes,
        siteUuidList: arr,
      };
      responseCurrentCount(params).then((res) => {
        if (res.code == 0) {
          this.responseCurrentCountList = res.data;
          this.YJresponseCountVo = this.responseCurrentCountList.responseCountVo;
        }
      });
    },
    //防汛关注重点统计
    // getFXGZ() {
    // },
    //获取统计数据
    getdata() {
      this.getDB();
      this.getYJSJ();
      // this.getSFYHPC();
      // 演练
      this.getYJYL();
      this.getYJPX();
      //物资盘点
      this.getWZPD();
    },
    getCharts(val, data, series, type, xlist, ylist) {
      var myChart = echarts.init(val);
      var option;

      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: data,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: series,
      };

      option && myChart.setOption(option);
      let that = this;
      myChart.on("click", function(params) {
        that.jumpYL("应急演练统计", params.value, params.seriesName);
      });
    },
    getCharts2(val, undata, label) {
      var myChart = echarts.init(val);
      var option;

      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            name: label,
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },

            labelLine: {
              show: false,
            },
            data: undata,
          },
        ],
      };

      option && myChart.setOption(option);

      let that = this;
      myChart.on("click", function(params) {
        console.log("查看", params);
        that.jumpSJ(
          "应急事件统计",
          params.seriesName,
          params.value,
          params.name,
          1
        );
      });
    },
    getCharts3(val, seriesData, name) {
      var myChart = echarts.init(val);
      var option;
      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          right: "20%",
          top: "50%",
          textStyle: {
            color: "grey",
          },
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            center: ["40%", "50%"],
            data: seriesData,
            label: {
              show: false,
            },
          },
        ],
      };

      option && myChart.setOption(option);
      let that = this;
      myChart.on("click", function(params) {
        console.log(name, params);
        if (name == "物资盘点统计") {
          that.jumpWZPD(name, params.name, params.value);
        } else if (name == "应急预案统计") {
          that.jumpWZPD(name, params.name, params.value);
        }
      });
    },
    // 代办
    jumps(name, executorStatus, tabName) {
      let params = {
        name: name,
        dateType: this.model.agencyTimes,
        executorStatus: executorStatus,
        tabName: tabName,
      };
      console.log("jump", params);
      this.$router.push({
        name: "EmeStaticsTable",
        params: params,
      });
    },
    // 物资盘点
    jumpWZPD(name, tabName, value) {
      let params = {};
      if (name == "物资盘点统计") {
        params = {
          name: name,
          dateType: this.model.materialsTimes,
          tabName: tabName,
          value: value,
          lineUuid: this.model.materialsSite,
        };
      } else if (name == "应急预案统计") {
        params = {
          name: name,
          tabName: tabName,
          value: value,
          lineUuid: this.model.planningSite,
        };
      }
      console.log("物资盘点/应急预案统计jump", params);
      this.$router.push({
        name: "EmeStaticsTable",
        params: params,
      });
    },
    // 应急事件
    jumpSJ(name, tabName, value, typeName, id) {
      let params = {
        name: name,
        dateType: this.model.eventTimes,
        lineUuid: this.model.eventGzSite,
        tabName: tabName,
        value: value,
        typeName: typeName,
      };
      console.log("应急事件jump", params);
      if (tabName == "已闭合") {
        this.$router.push({
          name: "EmeStaticsTable",
          params: params,
        });
      }
    },
    // 应急培训
    jumpPX(name, value, label) {
      let params = {
        name: name,
        value: value,
        tabName: label,
        name: name,
        trainSite: this.model.trainSite,
        trainTimes: this.model.trainTimes,
      };
      console.log("应急培训jump", params);
      this.$router.push({
        name: "EmeStaticsTable",
        params: params,
      });
    },
    // 应急演练
    jumpYL(name, value, label) {
      let params = {
        drillUnit: this.model.drillUnit,
        drillSite: this.model.drillSite,
        drillTimes: this.model.drillTimes,
        name: name,
        value: value,
        tabName: label,
      };
      console.log("应急演练jump", params);
      this.$router.push({
        name: "EmeStaticsTable",
        params: params,
      });
    },
  },
  mounted() {
    this.getdata();
    this.getLineSiteTreeData();

    // this.getCharts3(document.getElementById("main6"));
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 700px;
  overflow: auto;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
::v-deep .el-table:before {
  height: 0px;
}
.stCard {
  margin: 0 20px 10px 0;
  padding: 20px 20px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.cenCard {
  padding: 20px 20px;
  width: 33%;
  height: 100px;
}
.cenNum {
  font-size: 28px;
  font-family: Arial;
  font-weight: bold;
  color: #081742;
}
</style>
