<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
            <el-cascader
              v-model="queryModal.linsiteIds"
              clearable
              :options="lineSiteData"
              placeholder="线路工点"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="lineChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="">
            <el-select
              clearable
              v-model="queryModal.warnTypeCode"
              @change="dangerChange"
              placeholder="预警类型"
            >
              <el-option
                v-for="item in warnTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="queryModal.warnGradeCode"
              @change="dangerChange"
              placeholder="预警等级"
            >
              <el-option
                v-for="item in warnGradeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              clearable
              v-model="queryModal.warnName"
              placeholder="名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getList">查询</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          show-overflow-tooltip
          label="线路"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="branchName"
          show-overflow-tooltip
          label="分部/标段"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          show-overflow-tooltip
          label="工点"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="warnTypeName"
          show-overflow-tooltip
          label="预警类型"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="warnName"
          show-overflow-tooltip
          label="预警名称"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskLevelName"
          show-overflow-tooltip
          label="风险等级"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="warnGradeName"
          show-overflow-tooltip
          label="预警等级"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="responseDeadline"
          show-overflow-tooltip
          label="剩余时限"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="stateName"
          show-overflow-tooltip
          label="状态"
          width=""
        ></el-table-column>
        <el-table-column align="center" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="radio == 1"
              @click="handleView(scope.row)"
              >待消除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <!-- append-to-body -->
    <el-dialog
      width="50%"
      class="editDialog details-dialog"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <warn-details
        @CloseData="CloseData"
        :warnId="warnId"
        @showFileList="showFileList"
        :warnUuid="warnUuid"
        :remove="radio"
        selected="预警消除"
      ></warn-details>
    </el-dialog>
    <el-dialog
      width="40%"
      title="附件列表"
      class="fileDialogCs"
      :visible.sync="fileDialog"
      v-if="fileDialog"
      style="z-index:99999"
      :close-on-click-modal="false"
      :before-close="handlefileClose"
    >
      <el-table :data="fileData" style="width: 100%">
        <el-table-column
          align="center"
          prop="fileName"
          show-overflow-tooltip
          label="附件名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          show-overflow-tooltip
          label="操作"
          width="90"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="handleFileView(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { warnPage } from "@/api/riskManage";
import { getDictData } from "@/api/dict";
import warnDetails from "./IssueComponent/warnDetails.vue";
export default {
  components: {
    warnDetails
  },
  data() {
    return {
      queryModal: {
        siteUuidList: [],
        warnTypeCode: "",
        warnGradeCode: "",
        warnName: "",
        linsiteIds: [],
        state: 4,
        isState: 0
      },
      loading: false,
      options: [],
      siteList: [],
      troubleshooting: [],
      dangerType: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      tableData: [],
      warnTypeList: [],
      warnGradeList: [],
      title: "",
      dialogVisible: false,
      radio: 1,
      // 预警状态（0待响应 1待整改 2待审核 3待消除 4已消除）
      warnState: [
        { label: "待整改", value: 1 },
        { label: "待审核", value: 2 },
        { label: "驳回待整改", value: 3 },
        { label: "待消除", value: 4 },
        { label: "已消除", value: 5 }
      ],
      warnId: "",
      warnUuid: "",
      fileDialog: false,
      fileData: []
    };
  },
  created() {
    this.getLineTree();
    this.getData();
    this.getDict();
  },
  methods: {
    async getLineTree() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.lineSiteData = JSON.parse(JSON.stringify(lineSiteData));
      }
    },
    getDict() {
      getDictData("warnType").then(res => {
        if (res.code == 0) {
          this.warnTypeList = res.data;
        }
      });
      getDictData("warnLevel").then(res => {
        if (res.code == 0) {
          this.warnGradeList = res.data;
        }
      });
    },
    lineChange(val) {
      console.log(val, "val");
      this.queryModal.siteUuidList = [];
      console.log(this.queryModal.siteUuidList, "siteUuidList");
      if (val.length > 0) {
        for (let item of val) {
          this.queryModal.siteUuidList.push(item[item.length - 1]);
        }
      }
    },
    getList() {
      this.page.pageNo = 1;
      this.getData();
    },
    getData() {
      console.log(this.queryModal);
      const that = this;
      this.loading = true;
      let params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.queryModal
      };
      warnPage(params).then(res => {
        if (res.code == 0) {
          res.data.records.forEach(el => {
            that.warnState.forEach(v => {
              if (that.queryModal.isState == 1) {
                el.stateName = "已响应";
              } else {
                if (el.state == v.value) {
                  el.stateName = v.label;
                }
              }
            });
          });
          this.tableData = res.data.records;
          this.page.total = res.data.total;
          this.loading = false;
        }
      });
    },
    handleSearch() {},
    handleAdd() {
      this.title = "发布预警";
      this.dialogVisible = true;
    },
    dangerChange() {},
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getData();
    },
    handleSizeChange() {},
    handleView(row) {
      this.warnId = row.id;
      this.warnUuid = row.warnUuid;
      this.dialogVisible = true;
    },
    CloseData() {
      this.dialogVisible = false;
      this.getData();
    },
    handleClose() {
      this.dialogVisible = false;
    },
    showFileList(obj) {
      this.fileDialog = true;
      this.fileData = obj.riskWarnFileList;
    },
    handleFileView(row) {
      const link = document.createElement("a");
      link.href = row.fileUrl;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
    handlefileClose() {
      this.fileDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .details-dialog > .el-dialog {
  > .el-dialog__header {
    padding: 0 !important;
  }
}

::v-deep .editDialog .el-dialog .el-dialog__body {
  padding-left: 20px;
}
</style>
