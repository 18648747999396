<template>
  <router-view />
</template>

<script>
import "@/style/theme.css";
export default {
  name: "system-layout"
}
</script>

<style lang="less" scoped></style>
