<template>
  <div>
     <el-dialog
      title="物资盘点"
      center
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
        <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="详情" name="first">
          <el-form ref="form" :model="completeform" label-width="100px">
            <el-form-item label="物资库名称">
              <el-input
                v-model="completeform.materialWareHouse"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="负责人">
              <el-input
                v-model="completeform.chargeName"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input
                v-model="completeform.mobile"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="盘点时间">
              <el-input
                v-model="completeform.inventoryDate"
                :disabled="true"
              ></el-input>
            </el-form-item>

            <el-form-item label="盘点人员">
              <el-input
                v-model="completeform.inventoryUserName"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model="completeform.remark"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="照片">
              <el-image
                v-for="(item, index) in imgs"
                :key="index"
                style="width: 100px; height: 100px"
                :src="item"
              ></el-image>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="异常物资" name="second">
          <el-table :data="materialList" style="width: 100%">
            <el-table-column prop="materialsName" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="num" label="现存数量" width="180">
            </el-table-column>
            <el-table-column prop="inventoryNum" label="盘点数量">
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="正常物资" name="third">
          <el-table :data="suppliesList" style="width: 100%">
            <el-table-column prop="label" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="count" label="现存数量" width="180">
            </el-table-column>
            <el-table-column prop="address" label="盘点数量"> </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="处理过程" name="fourth" class="rever">
          <div class="block">
            <div class="radio">处理过程（已完成）</div>
            <el-timeline
           
              style="margin-top: 20px; margin: 2px"
            >
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
              >
                <div class="actitle">
                  <span class="incid">{{ activity.title }}</span>
                  <span class="times">{{ activity.created }}</span>
                </div>
                <div
                  class="reported"
                  v-show="
                    activity.title == '物资盘点' || activity.title == '重新提交'
                  "
                >
                  <span>提交人:</span>
                  <span>{{ activity.optionName }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核人:</span>
                  <span>{{ activity.optionName }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核结果:</span>
                  <span>{{ activity.examineOpinion }}</span>
                </div>
                <div class="reported" v-show="activity.title == '审核'">
                  <span>审核意见:</span>
                  <span>{{ activity.remark }}</span>
                </div>
                <div class="reported" v-show="activity.title == '重新提交'">
                  <span>备注:</span>
                  <span>{{ activity.remark }}</span>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div></el-tab-pane
        >
      </el-tabs>
     </el-dialog>
  </div>
</template>

<script>
import {
  inventoryList,
  process,
  material,
  supplies,
  
} from "@/api/eres.js";
export default {
data(){
    return{
        dialogVisible:false, 
        activeName: "first",
        completeform:{},
        imgs:[],
        materialList:[],
        suppliesList:[],
        activities:[]
    }
},
methods:{
     complete(val) {
      this.activeName="first"
        console.log(val)
      inventoryList({ id: val.eventId }).then((res) => {
        console.log(res.data)
        this.completeform = res.data;
        this.imgs = res.data.imgs;
         supplies({
        id: val.eventId,
        siteId: this.completeform.siteId,
      }).then((res) => {
        this.suppliesList = res.data;
      });
      });
      material({ id: val.eventId }).then((res) => {
        this.materialList = res.data;
      });
     
      process({ id: val.eventId }).then((data) => {
        this.activities = data.data;
      });
      this.dialogVisible = true;
    },
    handleClose(){
        this.dialogVisible = false
    }
}
}
</script>

<style>

</style>