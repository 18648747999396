<template>
  <div :class="errorKey == '404' ? 'develop-page' : 'error-page'">
    <el-card class="main">
      <div class="error" v-if="errorKey == '403'">
        <img :src="error[errorKey]['src']" alt="" />
        <p class="error-msg">{{ errorKey }}:{{ error[errorKey]["text"] }}</p>
      </div>
      <div class="develop" v-if="errorKey == '404'">
        <img :src="error[errorKey]['src']" alt="" />
        <p class="develop-msg">{{ error[errorKey]["text"] }}</p>
      </div>
    </el-card>
  </div>
</template>

<script>
// const img_404 = require("@/assets/404.png");
const img_404 = require("@/assets/develop.png");
const img_403 = require("@/assets/403.png");

export default {
  name: "error-page",
  data() {
    return {
      error: {
        404: {
          src: img_404,
          text: "该模块努力开发中."
        },
        403: {
          src: img_403,
          text: "权限不足"
        }
      }
    };
  },
  computed: {
    errorKey() {
      return this.$route.name || "404";
    }
  }
};
</script>

<style lang="scss" scoped>
.error-page .main {
  height: 80vh;
  position: relative;
  .error {
    text-align: center;
    &-msg {
      color: #999999;
      font-size: 24px;
      position: absolute;
      bottom: 25%;
      left: 20px;
      width: calc(100% - 40px);
      text-align: center;
    }
  }
}
.develop-page {
  height: 100%;
  position: relative;
  .main {
    box-shadow: none;
    height: 100%;
  }
  .develop {
    height: 78vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &-msg {
      margin-top: 34px;
      color: #909399;
      font-family: PingFang SC;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
    }
  }
}
</style>
