<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
            <el-select
              v-model="queryModal.planId"
              placeholder="请选择关联预案"
              clearable
            >
              <el-option
                v-for="item of EmergencyData"
                :key="item.id"
                :label="item.planName"
                :value="item.planId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select
              v-model="queryModal.companyId"
              placeholder="请选择单位/部门类型"
              clearable
              @change="selectChange"
            >
              <el-option
                v-for="item of dept"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!--  <el-form-item label="">
            <el-select v-model="queryModal.postId" placeholder="请选择岗位选择">
              <el-option
                v-for="item of options"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="">
            <el-select
              v-model="queryModal.roleId"
              placeholder="请选择角色选择"
              clearable
            >
              <el-option
                v-for="item of options"
                :key="item.id"
                :label="item.roleName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button
            type="primary"
            @click="handleImportCard"
            v-has="'card:download'"
            >应急处置卡导入模板下载</el-button
          >
          <el-button type="primary" @click="handleImport" v-has="'card:import'"
            >导入</el-button
          >
          <el-button type="primary" @click="handleAdd" v-has="'card:add'"
            >新增</el-button
          >
          <el-button type="primary" disabled @click="handleExport"
            >导出</el-button
          >
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="planName"
          label="关联预案"
          width="150"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="companyName"
          label="单位"
          width="150"
        ></el-table-column>
        <!--  <el-table-column align="center" prop="vownerName" label="岗位">
        </el-table-column> -->
        <el-table-column
          align="center"
          prop="roleName"
          label="角色"
          width="150"
        >
        </el-table-column>
        <el-table-column align="center" prop="duty" label="职责">
        </el-table-column>
        <el-table-column
          align="center"
          prop="uploaderName"
          label="上传人"
          width="150"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="uploadTime"
          label="上传时间"
          width="150"
        >
        </el-table-column>

        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="handleLook(scope.row)" -->
            <el-button
              type="text"
              @click="handleUser(scope.row)"
              v-has="'card:personnel'"
              >对应人员</el-button
            >
            <el-button
              type="text"
              @click="handleUpate(scope.row)"
              v-has="'card:update'"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="handleDelete(scope.row)"
              v-has="'card:del'"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <contingencyplanFrom
      ref="modalForm"
      :EmergencyData="EmergencyData"
      :dept="dept"
      @formSubmit="formSubmit"
    ></contingencyplanFrom>
    <!-- <previvew ref="look" :src="src" @srcClose="srcClose"></previvew> -->
    <importForm
      ref="importForm"
      @importSub="importSub"
      :EmergencyData="EmergencyData"
    ></importForm>
    <userForm ref="userForm"></userForm>
  </div>
</template>

<script>
import {
  EmergencyPageCardList,
  delEmergencyCardData,
  getEmergencyExport,
  EmergencyPage,
} from "@/api/emergency"
// import { getLoginUserDeptList } from "@/api/organization.js";
import { getRoles } from "@/api/role"
import contingencyplanFrom from "./modules/contingencyplanFrom.vue"
// import previvew from "@/components/Uploader/filePreview.vue";
import importForm from "./modules/importForm.vue"
import userForm from "./modules/userForm.vue"
import { getDictData } from "@/api/dict"
import { ImportCard } from "@/api/eres"

export default {
  components: {
    contingencyplanFrom,
    importForm,
    userForm,
  },
  data () {
    return {
      loading: false,
      deptList: [],
      queryModal: {
        planId: "",
        roleId: "",
        companyId: "",
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      options: [],
      dept: [],
      EmergencyData: [],
    }
  },
  created () {
    this.loadData()
    this.getEmergencyPage()
    this.getdeptdata()
    // this.getrolelist();
  },
  methods: {
    // 应急处置卡模板的导出
    handleImportCard () {
      ImportCard().then((res) => {
        const aLink = document.createElement("a")
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel",
        })
        aLink.href = URL.createObjectURL(blob)
        aLink.download = "应急处置卡"
        aLink.click()
        document.body.appendChild(aLink)
        URL.revokeObjectURL(aLink.href) //销毁下载地址
        document.body.removeChild(aLink) //将a标签从dom中移除
      })
    },
    //应急处置卡的导入
    importSub () {
      this.loadData()
    },
    // 单位选择完 掉角色
    selectChange (val) {
      this.queryModal.roleId = ""
      this.options = []
      let params = {
        name: "-",
        companyId: "-",
        departmentId: "-",
        parentId: "-",
        unitType: val,
        pageNo: 1,
        pageSize: 1000000,
      }
      getRoles(params).then((res) => {
        this.options = res.data.records
      })
    },
    // getrolelist() {
    //   let params = {
    //     pageNo: 1,
    //     pageSize: 10000,
    //     name: "-",
    //     companyId: "-",
    //     departmentId: "-",
    //     parentId: "-",
    //     unitType: "-",
    //   };
    //   getRoles(params).then((res) => {
    //     console.log(res);
    //     this.options = res.data.records;
    //   });
    // },
    formSubmit () {
      this.loadData()
    },
    getdeptdata () {
      console.log("unit")
      let label = "unitType"
      this.dept = []
      getDictData(label).then((res) => {
        if (res.code == 0) {
          res.data.forEach((element) => {
            this.dept.push({
              label: element.label,
              value: element.code,
            })
          })
        }
      })
      /*  let parms = {
        category: "",
        name: "",
      };
      let daptlist = [];
      getLoginUserDeptList(parms).then((res) => {
        res.data.forEach((item) => {
          if (item.children && item.children !== null) {
            item.children.forEach((i) => {
              daptlist.push(i);
            });
          }
        });
        this.dept = daptlist;
      }); */
    },
    // 查询关联预案
    getEmergencyPage () {
      let parms = {
        belonging: 1,
      }
      EmergencyPage(parms).then((res) => {
        this.EmergencyData = res.data
        console.log(res)
      })
    },
    // 表格数据
    loadData () {
      this.loading = true
      const params = {
        planId: this.queryModal.planId ? this.queryModal.planId : null,
        roleId: this.queryModal.roleId ? this.queryModal.roleId : null,
        companyId: this.queryModal.companyId ? this.queryModal.companyId : null,
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
      }
      EmergencyPageCardList(params).then((res) => {
        if (res.code == 0) {
          this.loading = false
          if (res.data.records && res.data.records.length > 0) {
            res.data.records.forEach((element) => {
              if (element.vendorType == 1) {
                element.vendorType = "建设单位"
              } else if (element.vendorType == 2) {
                element.vendorType = "监理单位"
              } else if (element.vendorType == 3) {
                element.vendorType = "施工单位"
              }
            })
            this.tableData = res.data.records
          } else {
            this.tableData = []
          }
          this.page.pageNo = res.data.current
          this.page.pageSize = res.data.size
          this.page.total = res.data.total
          this.page.count = res.data.count
        } else {
          this.loading = false
        }
      })
    },
    //新增
    handleAdd () {
      this.$nextTick(() => {
        this.$refs.modalForm.handleAdd()
      })
    },
    // 编辑
    handleUpate (row) {
      this.$nextTick(() => {
        this.$refs.modalForm.handleUpate(row)
      })
    },
    // 导入
    handleImport () {
      this.$nextTick(() => {
        this.$refs.importForm.handleImport()
      })
    },
    //对应人员
    handleUser (row) {
      this.$nextTick(() => {
        this.$refs.userForm.handleUser(row)
      })
    },
    handleFormSubmit () {
      this.loadData()
    },
    //查询
    handleSearch () {
      this.loadData()
    },
    //导出
    handleExport () {
      const params = {
        planName: this.queryModal.planName ? this.queryModal.planName : "-",
        vendorType: this.queryModal.vendorType
          ? this.queryModal.vendorType
          : "-",
      }
      getEmergencyExport(params).then((res) => {
        const aLink = document.createElement("a")
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel;charset=utf-8",
        })
        aLink.href = URL.createObjectURL(blob)
        aLink.download = "预案管理.xlsx"
        aLink.click()
        document.body.appendChild(aLink)
      })
    },
    //删除
    handleDelete (row) {
      console.log(row)
      this.$confirm("是否删除该预案?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delEmergencyCardData(row).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "删除成功！",
                type: "success",
              })
              this.loadData()
            } else {
              this.$message.error("删除失败！")
            }
          })
        })
        .catch(() => { })
    },
    // 分页器事件
    handleCurrentChange (val) {
      this.page.pageNo = val
      this.loadData()
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.loadData()
    },
  },
};
</script>

<style lang="scss" scoped></style>
