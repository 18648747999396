<template>
  <div class="page-container bg-white p-4 h-full relative">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="人员姓名">
            <el-input
              v-model="queryModal.username"
              placeholder="请输入人员姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="单位">
            <el-select
              v-model="selectTree.label"
              placeholder="请选择"
              clearable
              @clear="handleClear"
              ref="selectUpResId"
            >
              <el-option
                hidden
                :key="selectTree.value"
                :value="selectTree.value"
                :label="selectTree.label"
              ></el-option>
              <el-tree
                :data="unitOptions"
                :props="defaultProps"
                node-key="id"
                :highlight-current="true"
                :expand-on-click-node="false"
                :check-on-click-node="true"
                @node-click="handleNodeClick"
              >
              </el-tree>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <!-- <el-button type="primary">导出</el-button> -->
        </el-row>
      </el-form>
    </div>
    <div>
      <div class="table-wrapper">
        <el-table :data="tableData" v-loading="loading" height="74.5vh">
          <el-table-column
            prop="realName"
            align="center"
            label="姓名"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="departmentName"
            label="部门"
          ></el-table-column>
          <el-table-column align="center" prop="roles" label="角色">
            <template slot-scope="scope">
              <ul>
                <li v-for="item of scope.row.roles" :key="item.id">
                  <el-button type="text">{{ item.roleName }}</el-button>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="240">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="handleAssignPermissions(scope.row)"
                v-has="'personPermission:assign'"
                >分配权限</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-wrapper flex justify-center mt-4">
          <el-pagination
            background
            layout="prev, pager, next, jumper"
            :total="page.total"
            :page-count="page.count"
            :page-size="page.pageSize"
            :current-page="page.pageNo"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <SystemModal
      ref="permissionModal"
      title="权限分配"
      @modalConfirm="handleSubmit"
      @modalClose="handleClose"
    >
      <el-card class="box-card" v-loading="modalLoading">
        <div slot="header" class="clearfix">
          <span>人员授权</span>
          <!-- <el-button style="float: right; padding: 3px 0" type="text"
            >操作按钮</el-button
          > -->
        </div>
        <div style="max-height: 400px; overflow-y: scroll;">
          <el-tree
            ref="dataPermissionTree"
            :data="lineSiteOptions"
            :props="props"
            show-checkbox
            node-key="uuid"
            :default-expanded-keys="defaultExpandedKeys"
            :default-checked-keys="defaultCheckedKeys"
          >
          </el-tree>
        </div>
      </el-card>
    </SystemModal>
  </div>
</template>

<script>
import { queryDeepByType } from "@/api/org";
import { getAccounts } from "@/api/ucenter";
import { tripleTree } from "@/api/map";
import { batchSaveOrUpdate, getDataByUuId } from "@/api/resource";
import SystemModal from "@/components/Modal/systemModal.vue";
export default {
  components: { SystemModal },
  data() {
    return {
      queryModal: {
        username: "",
        companyId: "",
        deptId: "",
      },
      loading: false,
      tableData: [],
      filterText: "",
      unitOptions: [],
      defaultProps: {},
      typeOptions: [],
      lineSiteOptions: [],
      props: {
        children: "children",
        label: "name",
      },
      selectTree: {
        label: "",
        value: 0,
      },
      model: {
        userId: "",
        siteUuIdList: [],
        siteIdList: [],
      },
      defaultExpandedKeys: [],
      defaultCheckedKeys: [],
      modalLoading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        total: 0,
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.unitTree.filter(val);
    },
  },
  mounted() {
    this.loadData();
    this.loadOrgTree();
    this.tripleTree();
  },
  methods: {
    // 加载表格数据
    async loadData() {
      this.loading = true;
      const params = {
        username: this.queryModal.username || "-",
        companyId: this.queryModal.companyId || "-",
        deptId: this.queryModal.deptId || "-",
        roleId: "-",
        state: "-",
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      };
      let res = await getAccounts(params);
      if (res.code == 0) {
        this.tableData = res.data.records;
        this.loading = false;
        (this.page.count = res.data.total), (this.page.total = res.data.count);
      } else {
        this.loading = false;
        this.$message.error(res.message);
      }
    },
    // 加载单位部门树
    async loadOrgTree() {
      let res = await queryDeepByType();
      if (res.code == 0) {
        const tempArr = [];
        // 遍历第一层
        for (let first of res.data) {
          const obj1 = {
            label: first.companyType,
            children: [],
            level: 1,
          };
          // 遍历第二层
          for (let second of first.companyList) {
            const obj2 = {
              label: second.companyName,
              id: second.uuid,
              children: [],
              level: 2,
            };
            // 遍历第三层
            for (let third of second.departments) {
              const obj3 = {
                label: third.departmentName,
                id: third.uuid,
                level: 3,
                parentId: second.uuid,
              };
              obj2.children.push(obj3);
            }
            obj1.children.push(obj2);
          }
          tempArr.push(obj1);
        }
        this.unitOptions = tempArr;
        console.log(this.unitOptions);
      } else {
        this.$message.error(res.message);
      }
    },
    // 查询线路工点三级树
    async tripleTree() {
      let params = {};
      let res = await tripleTree(params);
      if (res.code == 0) {
        this.lineSiteOptions = res.data;
      } else {
        this.$message.error(res.message);
      }
    },
    // 查询栏搜索
    handleQuery() {
      this.loadData();
    },
    handleClear() {
      this.queryModal.companyId = "";
      this.queryModal.deptId = "";
    },
    // 单位信息树点击
    handleNodeClick(record) {
      this.selectTree.label = record.label;
      this.selectTree.value = record.level;
      this.queryModal.companyId = "";
      this.queryModal.deptId = "";
      if (record.level == 2) {
        this.queryModal.companyId = record.id;
      } else if (record.level == 3) {
        this.queryModal.companyId = record.parentId;
        this.queryModal.deptId = record.id;
      } else {
        return false;
      }
      this.loadData();
    },
    // 单位信息树过滤
    filterUnitNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    // 根据用户id查询已有权限
    async getDataByUuId(record) {
      this.defaultCheckedKeys = [];
      this.modalLoading = true;
      const params = {
        userId: record.uuid,
        companyId: record.companyId,
        roleIdList: record.roleIdList,
      };
      let res = await getDataByUuId(params);
      this.modalLoading = false;
      this.defaultCheckedKeys = [...res.data];
    },
    // 分配权限
    handleAssignPermissions(record) {
      this.model.siteUuIdList = [];
      this.model.siteIdList = [];
      this.model.userId = record.uuid;
      this.getDataByUuId(record);
      this.$refs.permissionModal.handleOpen();
    },
    // 递归增加工点树子项
    addTree(record) {
      this.model.siteUuIdList.push(record.uuid);
      if (record.childList) {
        for (let item of record.childList) {
          this.addTree(item);
        }
      }
    },
    // 递归删除工点树子项
    delTree(record) {
      const index = this.model.siteUuIdList.findIndex(
        (id) => id == record.uuid
      );
      if (index === 0 || index) {
        this.model.siteUuIdList.splice(index, 1);
      }
      if (record.childList) {
        for (let item of record.childList) {
          this.delTree(item);
        }
      }
    },
    // 分配权限后提交
    handleSubmit() {
      const selectNodeData = this.$refs.dataPermissionTree.getCheckedNodes(
        true
      );
      for (let item of selectNodeData) {
        this.model.siteUuIdList.push(item.uuid);
        this.model.siteIdList.push(item.id);
      }

      if (this.model.siteUuIdList.length) {
        this.modalLoading = true;
        batchSaveOrUpdate(this.model).then((res) => {
          if (res.code == 0) {
            this.$message.success("保存成功");
            this.modalLoading = false;
            this.$refs.dataPermissionTree.setCheckedKeys([]);
            this.$refs.permissionModal.handleClose();
            this.loadData();
          } else {
            this.$message.error(res.message);
            this.modalLoading = false;
          }
        });
      } else {
        this.$message.warning("请至少选择一个工点");
      }
    },
    handleClose() {
      this.$refs.dataPermissionTree.setCheckedKeys([]);
      this.defaultCheckedKeys = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  .el-input {
    width: 80%;
  }
  .el-select {
    width: 80%;
  }
}
.tree-wrapper {
  width: 350px;
  margin-right: 20px;
  .tree {
    max-height: 63vh;
    overflow-y: scroll;
    margin-top: 20px;
  }
}
</style>
