<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" :title="title">
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="110px"
      class="demo-model"
    >
      <el-form-item label="关联预案">
        <el-select
          v-model="model.planId"
          placeholder="选择现在已有的预案名称"
          :disabled="title == '编辑'"
        >
          <el-option
            v-for="item of EmergencyData"
            :key="item.id"
            :label="item.planName"
            :value="item.planId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单位/部门类型">
        <el-select
          v-model="model.companyId"
          placeholder="请选择单位/部门类型"
          :disabled="title == '编辑'"
          @change="selectChange"
        >
          <el-option
            v-for="item of dept"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色">
        <el-select
          v-model="model.roleId"
          placeholder="请选择角色"
          :disabled="title == '编辑'"
        >
          <el-option
            v-for="item of options"
            :key="item.id"
            :label="item.roleName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职责">
        <el-input
          v-model="model.duty"
          type="textarea"
          placeholder="请填写职责"
        ></el-input>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { addEmergencyCardData, UpdateEmergencyCardData } from "@/api/emergency";
import { getRoles } from "@/api/role";
// eslint-disable-next-line no-unused-vars
import { upload, uploadThumb } from "@/api/upload";
export default {
  components: {
    systemModal,
  },
  props: {
    EmergencyData: {
      type: Array,
      default: () => [],
    },
    dept: {
      type: Array,
      default: () => [],
    },
    /*  options: {
      type: Array,
      default: () => [],
    }, */
  },
  data() {
    return {
      title: "新增",
      model: {
        planId: "",
        companyId: "",
        roleId: "",
        duty: "",
      },
      loading: false,
      percent: 0,
      percentsp: 0,
      rules: {},
      lineList: [],
      siteIdList: [],
      fileListsp: [],
      fileList: [],
      options: [],
      row: {},
    };
  },
  mounted() {
    console.log(this.dept, "dept");
  },
  methods: {
    selectChange(val) {
      this.model.roleId = "";
      this.options = [];
      let params = {
        name: "-",
        companyId: "-",
        departmentId: "-",
        parentId: "-",
        unitType: val,
        pageNo: 1,
        pageSize: 1000000,
      };
      getRoles(params).then((res) => {
        this.options = res.data.records;
      });
    },
    handleUpate(data) {
      this.resetModel();
      this.title = "编辑";
      this.handleEdit();
      this.row = data;
      this.model = Object.assign({}, data);
      this.model.roleId = data.roleName;
      this.model.companyId = data.companyName;
      // this.selectChange(data.companyId);
    },
    /*  // 线路的change
     lineChange (val) {
       console.log(val, "val")
       this.lineList.forEach((item) => {
         if (item.id == val) {
           this.siteIdList = item.nodes
           this.model.siteId = ""
         }
       })
     }, */
    handleAdd() {
      this.resetModel();
      this.title = "新增";
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    handleSubmit() {
      console.log(this.model, this.dept, "传参");
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.title == "新增") {
            let planNames = this.EmergencyData.find(
              (item) => item.planId == this.model.planId
            );
            let companyId = this.dept.find(
              (item) => item.value == this.model.companyId
            );
            let roleNames = this.options.find(
              (item) => item.id == this.model.roleId
            );
            this.model.planName = planNames.planName;
            this.model.companyName = companyId.label;
            this.model.roleName = roleNames.roleName;
            const params = JSON.parse(JSON.stringify(this.model));
            console.log(params, "参数");
            addEmergencyCardData(params)
              .then((res) => {
                this.loading = false;
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.$emit("formSubmit");
                  this.$refs.formModal.handleClose();
                  this.resetModel();
                } else {
                  this.$message.error("操作失败" + res.message);
                }
              })
              .catch(() => {
                this.$message.error("操作失败");
              });
          } else {
            this.row.duty = this.model.duty;
            UpdateEmergencyCardData(this.row)
              .then((res) => {
                this.loading = false;
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.$emit("formSubmit");
                  this.$refs.formModal.handleClose();
                  this.resetModel();
                } else {
                  this.$message.error("操作失败" + res.message);
                }
              })
              .catch(() => {
                this.$message.error("操作失败");
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
    resetModel() {
      this.model = {
        planId: "",
        companyId: "",
        roleId: "",
        duty: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
