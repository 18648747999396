<template>
  <Modal
    class="modalDetail"
    ref="model"
    :modal="false"
    :width="'1200px'"
    :title="modelTitle"
    @libraryClose="libraryClose"
  >
    <div
      class="table-wrapper p-4 h-full"
      v-loading="pageLoading"
      element-loading-text="数据加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
      style="height: 450px; overflow-y: auto"
    >
      <div class="content">
        <div class="info-wrapper">
          <el-table
            :data="tableHeaderData"
            v-loading="headerLoading"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :height="tableHeaderData.length == 0 ? '120' : '125'"
            border
            style="width:100%;margin-bottom:20px"
          >
            <el-table-column
              align="center"
              prop="superviseName"
              show-overflow-tooltip
              label="所属建管部"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="lineName"
              show-overflow-tooltip
              label="线路名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="branchName"
              show-overflow-tooltip
              label="分部名称"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="siteName"
              show-overflow-tooltip
              label="工点名称"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="leaderName"
              show-overflow-tooltip
              label="负责人"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="mobile"
              show-overflow-tooltip
              label="联系方式"
            >
            </el-table-column>
          </el-table>
          <div class="line"></div>
          <el-form :inline="true" :model="libraryForm" class="demo-form-inline">
            <el-form-item>
              <el-input
                v-model="libraryForm.label"
                placeholder="请输入物资名称搜索"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="libraryQuery">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-table
            :data="tableData"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            height="380"
            border
            style="width: 100%"
             @cell-mouse-enter="handleCellMouseEnter"
        @cell-mouse-leave="handleCellMouseLeave"
          >
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="60"
            ></el-table-column>

            <el-table-column
              align="center"
              prop="label"
             
              :label="radio == 125 ? '物资名称' : '设备名称'"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="model"
              
              label="规格/型号"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="unit"
              
              width="100"
              label="计量单位"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="count"
              
              width="100"
              label="库存数量"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="usages"
              :show-overflow-tooltip="true"
              label="用途"
            >
            <template slot-scope="scope">
            {{ JSON.parse(scope.row.usages).join(",") }}
          </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="remark"
             
              label="备注"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-wrapper flex justify-center mt-4">
          <el-pagination
            background
            style="z-index:999"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="libraryForm.pageNo"
            :page-size="libraryForm.pageSize"
            layout="prev, pager, next"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { getMaterialsPage, getWareHouseManList } from "@/api/eres";
import Modal from "@/components/Modal/modal.vue";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";

export default {
  components: { Modal },
  props: {
    libraryData: {
      type: Object,
      default: () => {},
    },
    radio: {
      type: Number,
      default: 125,
    },
    modelTitle: {
      type: String,
      defaulf: "物资",
    },
  },
  data() {
    return {
      libraryForm: {
        label: "",
        modelNo: "",
        state: "",
        siteIds: [],
        type: "125",
        currentPage: 1,
        size: 5,
      },
      tableData: [
        {
          lineName: "姓名",
          siteName: "xxx职位",
          vownerName: "部门",
          tell: "12",
        },
      ],
      tableHeaderData: [],
          usageCache: "",
      total: 0,
      headerLoading: false,
      loading: false,
      pageLoading: false,
       dict: {
        model: [],
        usages: [],
        state: [
          {
            label: "正常",
            value: 1,
          },
          {
            label: "异常",
            value: 2,
          },
        ],
      },
    };
  },
  mounted() {
    this.getDictData("物资用途", this.dict.usages);
  },
  methods: {
    libraryClose() {
      this.$refs.library.handleClose();
    },
    handleOpen() {
      console.log(this.radio);
      this.$refs.model.handleOpen();
      this.libraryForm.label = "";
      this.libraryForm.siteIds = [];
      this.libraryForm.siteIds.push(this.libraryData.id);
      this.libraryForm.type = this.radio;
      this.pageLoading = true;
      this.getDataList(this.libraryData.id);
      this.getHeadDatas();
    },
    //  物资库负责人列表
    getHeadDatas() {
      this.pageLoading = false;
      this.headerLoading = true;
      const params = {
        siteIds: this.libraryForm.siteIds,
        name: "",
        page: 1,
        size: 10,
      };
      getWareHouseManList(params).then((res) => {
        if (res.code == 0) {
          this.headerLoading = false;
          this.tableHeaderData = res.data.records ? res.data.records : [];
        } else {
          this.headerLoading = false;
        }
      });
    },
       getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
        // 鼠标滑入单元格
    handleCellMouseEnter(row, column, cell, event) {
      console.log(column.property)
      if (column.property == "usages") {
        cell.querySelector(".cell").style.cursor = "pointer";
        console.log(row, column, cell, event);
        const labelArr = [];
        const usageStr = cell.querySelector(".cell").innerHTML;
        this.usageCache = usageStr;
        let usageArr = [];
        if (usageStr.length) {
          usageArr = usageStr.split("");
        }
        for (let usage of usageArr) {
          const tempObj = this.dict.usages.find((item) => item.label == usage);
          if (tempObj) {
            labelArr.push(tempObj);
          }
        }
        let str = "";
        for (let item of labelArr) {
          str += `<li>${item.label}.${item.intro}</li>`;
        }
        cell.querySelector(".cell").innerHTML = `<ul>${str}</ul>`;
      }
    },
    // 鼠标移出单元格
    handleCellMouseLeave(row, column, cell, event) {
      if (column.property == "usages") {
        console.log(row, column, cell, event);
        cell.querySelector(".cell").innerHTML = this.usageCache;
      }
    },
    getDataList() {
      this.pageLoading = false;
      this.loading = true;
      const params = {
        materialType: 1,
        siteIds: this.libraryForm.siteIds,
        type: this.libraryForm.type,
        state: this.libraryForm.state,
        modelNo: this.libraryForm.modelNo,
        label: this.libraryForm.label,
        page: this.libraryForm.currentPage,
        size: this.libraryForm.size,
      };
      getMaterialsPage(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records ? res.data.records : [];
          this.total = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },
    // 查询
    libraryQuery() {
      this.getDataList();
    },

    handleCurrentChange(val) {
      this.libraryForm.currentPage = val;
      this.getDataList();
    },
    handleSizeChange(size) {
      this.libraryForm.size = size;
      this.getDataList();
    },
  },
};
</script>

<style lang="scss" scoped>
.info-wrapper {
  ::v-deep .el-table--border,
  .el-table::before {
    // border-bottom: 0 !important;
    height: 0px;
  }
}

.peopleNum {
  color: #000;
  font-weight: bold;
}
::v-deep .el-radio-button__inner {
  width: 200px;
}
.choice {
  display: flex;
  flex-direction: row;
}
.line {
  height: 1px;
  width: 100%;
  background: #143363;
  margin-bottom: 20px;
}
::v-deep .el-input__inner {
  background: #081742;
  border-color: #25458f;
  color: #fff;
}
.el-table--border::after {
  width: 0;
}
::v-deep .el-input__inner:hover {
  border-color: #25458f;
}
::v-deep .el-table th.is-leaf,
::v-deep .el-table--border td {
  border: 1px solid #143363 !important;
}
</style>
