import { httpInit } from "./http";

const http = httpInit("/gzdt-org");

// 分页查询机构列表
export const pageOrganization = params =>
  http.get(
    `/org/page/${params.name}/${params.category}/${params.pageNo}/${params.pageSize}`
  );

// 新增机构
export const addOrganization = params => http.post(`/org/info`, params);

// 删除机构
export const delOrganization = id => http.delete(`/org/info/${id}`);

// 编辑机构
export const changeOrganization = params =>
  http.put(`/org/info/${params.uuid}`, params);

// 查询机构
export const queOrganization = id => http.get(`/org/info/${id}`);

// 根据类别查询机构
export const quecategory = params =>
  http.get(`/api/coms/${params.category}/${params.name}`);

// 分页查询部门列表
export const departmentList = params =>
  http.get(
    `/dept/page/${params.name}/${params.companyId}/${params.parentId}/${params.pageNo}/${params.pageSize}`
  );

// 新增部门
export const addDepartment = params => http.post(`/dept/info`, params);

// 删除部门
export const delDepartment = id => http.delete(`/dept/info/${id}`);

// 编辑部门
export const changeDepartment = params =>
  http.put(`/dept/info/${params.companyId}`, params);

// 查询部门
export const queDepartment = id => http.get(`/dept/info/${id}`);

// 根据当前登陆人单位获取组织机构权限树
export const getLoginUserDeptTree = data => http.post(`/org/getLoginUserDeptTree`, data);

// 根据当前登陆人单位获取组织机构权限树
export const getLoginUserDeptList = data => http.post(`/org/getLoginUserDeptList`, data);
