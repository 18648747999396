<template>
  <!-- 三防响应-历史响应-三防问题详情 -->
  <div>
    <el-form
      :model="checkForm"
      ref="checkForm"
      label-width="120px"
      class="demo-model"
    >
      <el-row>
        <!-- 发布 -->
        <el-col :span="12" v-if="emeTitle == '应急响应发布详情'">
          <el-form-item label="应急响应类型:" prop="typeName">
            <el-input
              v-model="checkForm.typeName"
              readonly
              placeholder="应急响应类型"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 其余三个 -->
        <el-col :span="12" v-if="emeTitle != '应急响应发布详情'">
          <el-form-item label="当前事件:" prop="typeName">
            <el-input
              v-model="checkForm.typeName"
              readonly
              placeholder="当前事件"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="emeTitle == '应急响应发布详情'">
          <el-form-item label="应急响应级别:" prop="currentLevelName">
            <el-input
              v-model="checkForm.currentLevelName"
              readonly
              placeholder="应急响应级别"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="emeTitle == '应急响应发布详情'">
          <el-form-item label="启动时间:" prop="startTime">
            <el-input
              v-model="checkForm.startTime"
              readonly
              placeholder="启动时间"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发布人:" prop="publisherName">
            <el-input
              v-model="checkForm.publisherName"
              readonly
              placeholder="发布人"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 升级/降级 -->
        <el-col
          :span="12"
          v-if="
            emeTitle == '应急响应升级详情' || emeTitle == '应急响应降级详情'
          "
        >
          <el-form-item
            :label="emeTitle == '应急响应升级详情' ? '升级时间:' : '降级时间:'"
            prop="relegationTime"
          >
            <el-input
              v-model="checkForm.relegationTime"
              readonly
              placeholder="升级时间"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 升降级 -->
        <el-col
          :span="12"
          v-if="
            emeTitle == '应急响应升级详情' || emeTitle == '应急响应降级详情'
          "
        >
          <el-form-item
            :label="
              emeTitle == '应急响应升级详情' ? '升级后级别:' : '降级后级别:'
            "
            prop="relegationLevelName"
          >
            <el-input
              v-model="checkForm.relegationLevelName"
              readonly
              :placeholder="
                emeTitle == '应急响应升级详情' ? '升级后级别' : '降级后级别'
              "
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 结束 -->
        <el-col :span="12" v-if="emeTitle == '应急响应结束详情'">
          <el-form-item label="结束时间:" prop="endTime">
            <el-input
              v-model="checkForm.endTime"
              readonly
              placeholder="结束时间"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 其余三个 -->
        <el-col :span="12" v-if="emeTitle != '应急响应发布详情'">
          <el-form-item label="操作人:" prop="operator">
            <el-input
              v-model="checkForm.operator"
              readonly
              placeholder="操作人"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 发布 -->
        <el-col :span="24" v-if="emeTitle == '应急响应发布详情'">
          <el-form-item label="应急响应要求:" prop="originalAlertMessage">
            <el-input
              type="textarea"
              resize="none"
              readonly
              placeholder="应急响应要求"
              :autosize="{ minRows: 2, maxRows: 6 }"
              v-model="checkForm.originalAlertMessage"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 其余三个 -->
        <el-col :span="24" v-if="emeTitle == '应急响应降级详情'||emeTitle == '应急响应升级详情'">
          <el-form-item label="详细内容:" prop="relegationRemark">
            <el-input
              type="textarea"
              resize="none"
              readonly
              placeholder="详细内容"
              :autosize="{ minRows: 2, maxRows: 6 }"
              v-model="checkForm.relegationRemark"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="emeTitle == '应急响应结束详情'">
          <el-form-item label="详细内容:" prop="relegationRemark">
            <el-input
              type="textarea"
              resize="none"
              readonly
              placeholder="详细内容"
              :autosize="{ minRows: 2, maxRows: 6 }"
              v-model="checkForm.endRemark"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    emeParams: {
      type: Object,
      default: () => {},
    },
    emeTitle: {
      type: String,
    },
  },
  data() {
    return {
      checkForm: {
        typeName: "",
        typeName: "",
        currentLevelName: "",
        startTime: "",
        publisherName: "",
        relegationTime: "",
        relegationLevelName: "",
        endTime: "",
        publisherName: "",
        originalAlertMessage: "",
        relegationRemark: "",
      },
    };
  },
  watch: {
    emeParams: {
      handler(val) {
        if (val) {
          this.checkForm = Object.assign({}, val);
          console.log("传过来的", this.checkForm);
          //   this.checkForm.uriList = [];
          //   this.checkForm.correctivePhotosUriList = [];
          //   this.checkForm.uriList.push(this.checkForm.uri);
          //   this.checkForm.correctivePhotosUriList.push(
          //     this.checkForm.correctivePhotosUri
          //   );
          //   switch (this.checkForm.correctiveApproveStatus) {
          //     case 0:
          //       this.checkForm.correctiveApproveStatus = "待整改";
          //       break;
          //     case 1:
          //       this.checkForm.correctiveApproveStatus = "待监理审批";
          //       break;
          //     case 2:
          //       this.checkForm.correctiveApproveStatus = "审批不通过";
          //       break;
          //     case 3:
          //       this.checkForm.correctiveApproveStatus = "完成";
          //       break;
          //   }
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
  },
};
</script>

<style></style>
