<template>
  <!-- 填报 -->
  <el-tabs v-model="activeName" type="card" v-loading="loading">
    <el-tab-pane label="建设工地抢险和灾害损失情况" name="first">
      <el-form :model="tableForm" ref="tableForm" size="small">
        <el-table
          :data="tableForm.rescueData"
          border
          style="width: 100%; margin-top: 20px"
        >
          <el-table-column
            align="center"
            prop="inspectNum"
            label="出动检查人员（人次）"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="'rescueData.' + scope.$index + '.inspectNum'"
              >
                <el-input
                  v-model.number="scope.row.inspectNum"
                  readonly
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="inspectPlaceNum"
            label="检查场所（处）"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="'rescueData.' + scope.$index + '.inspectPlaceNum'"
              >
                <el-input
                  readonly
                  v-model.number="scope.row.inspectPlaceNum"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="hiddenNum"
            label="发现三防问题隐患（处）"
          >
            <template slot-scope="scope">
              <el-form-item :prop="'rescueData.' + scope.$index + '.hiddenNum'">
                <el-input
                  v-model.number="scope.row.hiddenNum"
                  placeholder="请输入"
                  readonly
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="hiddenSuccessNum"
            label="已整改三防问题隐患（处）"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="'rescueData.' + scope.$index + '.hiddenSuccessNum'"
              >
                <el-input
                  readonly
                  v-model.number="scope.row.hiddenSuccessNum"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="ranks"
            label="出动(集结)抢险队伍（支）"
          >
            <template slot-scope="scope">
              <el-form-item :prop="'rescueData.' + scope.$index + '.ranks'">
                <el-input
                  readonly
                  v-model.number="scope.row.ranks"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="ranksNum"
            label="出动(集结)抢险队伍人员（人次）"
          >
            <template slot-scope="scope">
              <el-form-item :prop="'rescueData.' + scope.$index + '.ranksNum'">
                <el-input
                  readonly
                  v-model.number="scope.row.ranksNum"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <p style="margin-top: 20px">本单位实际受灾情况报告:</p>
      <el-form
        :model="restoreTableForm"
        ref="restoreTableForm"
        :rules="restoreRules"
        size="small"
      >
        <el-table
          :data="restoreTableForm.restoreTableData"
          style="width: 100%; margin-top: 20px"
          border
        >
          <el-table-column
            align="center"
            prop="lossSituation"
            label="损失情况"
          ></el-table-column>
          <el-table-column align="center" prop="num" label="数量">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  v-model="scope.row.num"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="rescueSituation"
            label="抢险应对措施"
          >
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  type="textarea"
                  resize="none"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  placeholder="请输入内容"
                  v-model="scope.row.rescueSituation"
                >
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="personCharge" label="结果">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  type="textarea"
                  resize="none"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  placeholder="请输入内容"
                  v-model="scope.row.personCharge"
                >
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="建设工地灾害损失及恢复情况表" name="second">
      <el-form
        :model="recoveryTableForm"
        ref="restoreTableForm"
        :rules="recoveryRules"
        size="small"
      >
        <el-table
          :data="recoveryTableForm.restoreTableData"
          style="width: 100%; margin-top: 20px"
          border
        >
          <el-table-column
            align="center"
            prop="lossSituation"
            label="损失情况"
          ></el-table-column>
          <el-table-column align="center" prop="num" label="数量">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  v-model="scope.row.num"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="rescueSituation"
            label="抢险及恢复情况(线路-工点+情况描述，多个工点用逗号隔开)"
          >
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  type="textarea"
                  resize="none"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  placeholder="请输入内容"
                  v-model="scope.row.rescueSituation"
                >
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="personCharge"
            label="负责单位联系人及电话"
          >
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  type="textarea"
                  resize="none"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  placeholder="请输入内容"
                  v-model="scope.row.personCharge"
                >
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-tab-pane>

    <span class="dialog-footer">
      <el-button type="primary" @click="nextstep()" v-if="activeName == 'first'"
        >下一步</el-button
      >
      <el-button type="primary" @click="submit()" v-if="activeName == 'second'"
        >提交</el-button
      >
    </span>
  </el-tabs>
</template>

<script>
import { queryDictList, situationReport } from "@/api/dict";
import {
  Department,
  getlrosses,
  queryStaticByCurrent,
} from "@/api/response.js";
export default {
  props: {
    currentId: {
      type: Number,
    },
    resubmit: {
      type: String,
    },
    supervisionName: {
      type: String,
    },
    responseId: {
      type: Number,
    },
  },
  data() {
    return {
      activeName: "first",
      tableForm: {
        rescueData: [
          {
            hiddenNum: "",
            hiddenSuccessNum: "",
            inspectNum: "",
            inspectPlaceNum: "",
            ranks: "",
            ranksNum: "",
          },
        ],
      },

      recoveryTableForm: {
        restoreTableData: [],
      },
      recoveryRules: {},
      restoreTableForm: {
        restoreTableData: [],
      },
      restoreRules: {},
      // responseId: "",
      erteru: {},
      loading: false,
    };
  },
  watch: {
    resubmit: {
      handler(val) {
        if (val) {
          if (val == "重新填报") {
            this.seweer(this.currentId);
          } else if (val == "未填报") {
            this.addCrea();
            this.loading = true;
            queryStaticByCurrent({ currentId: this.currentId }).then((res) => {
              if (res.code == 0) {
                this.tableForm.rescueData = [];
                this.tableForm.rescueData.push(res.data);
                this.loading = false;
              }
            });
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  methods: {
    seweer(val) {
      this.loading = true;
      getlrosses(val).then((res) => {
        this.tableForm.rescueData = [];
        this.tableForm.rescueData.push(res.data.situationInspectDto);
        this.restoreTableForm.restoreTableData = res.data.lossList;
        this.recoveryTableForm.restoreTableData = res.data.recoveryList;
        this.supervisionName = res.data.supervisionName;
        this.loading = false;
      });
    },
    nextstep() {
      this.activeName = "second";
    },
    addCrea() {
      const paramsLoss = {
        label: "-",
        parent: 344,
        canModify: "-",
        page: 1,
        size: 1000,
      };
      const paramsRestore = {
        label: "-",
        parent: 345,
        canModify: "-",
        page: 1,
        size: 1000,
      };
      // 损失
      queryDictList(paramsLoss).then((res) => {
        if (res.code == 0) {
          res.data.records.forEach((item) => {
            const obj = {
              lossSituation: item.label,
              num: "0",
              personCharge: "无",
              rescueSituation: "无",
              type: 1,
            };
            Object.assign(item, obj);
          });
          this.restoreTableForm.restoreTableData = res.data.records;
        }
      });
      queryDictList(paramsRestore).then((res) => {
        if (res.code == 0) {
          res.data.records.forEach((item) => {
            const obj = {
              lossSituation: item.label,
              num: "0",
              personCharge: "无",
              rescueSituation: "无",
              type: 2,
            };
            Object.assign(item, obj);
          });
          this.recoveryTableForm.restoreTableData = res.data.records;
        }
      });
    },
    submit() {
      this.loading = true;
      // 处理提交的数据
      const Nobj = {
        situationDetailedList: [],
        situationInspectDtoList: [],
        responseId: this.responseId,
        currentId: this.currentId,
      };

      this.restoreTableForm.restoreTableData.forEach((item) => {
        const obj = {
          lossSituation: item.id,
          num: item.num,
          personCharge: item.personCharge,
          rescueSituation: item.rescueSituation,
          type: item.type,
        };
        Nobj.situationDetailedList.push(obj);
      });
      this.recoveryTableForm.restoreTableData.forEach((item) => {
        const obj = {
          lossSituation: item.id,
          num: item.num,
          personCharge: item.personCharge,
          rescueSituation: item.rescueSituation,
          type: item.type,
        };
        Nobj.situationDetailedList.push(obj);
      });
      Nobj.situationInspectDtoList.push(this.tableForm.rescueData[0]);
      situationReport(Nobj)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "填报成功",
              type: "success",
            });
            this.$emit("downResume");
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    erty() {
      this.recoveryTableForm.restoreTableData = [];
      this.restoreTableForm.restoreTableData = [];
      this.tableForm.rescueData = [];
    },

    //重新填报
    resubmits() {
      this.submit();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.terw {
  display: flex;
  margin-top: 20px;
  span {
    margin-left: 20px;
  }
}
::v-deep .el-form-item--small.el-form-item {
  margin-top: 16px;
}
</style>
