var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"duty-container flex flex-col relative"},[_c('div',{staticClass:"bottom flex-1 p-4 bg-white mt-4"},[_c('el-tabs',{attrs:{"type":"card"},on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"日常值班","name":"1"}}),_c('el-tab-pane',{attrs:{"label":"应急值班","name":"2"}})],1),_c('div',{staticClass:"first-wrapper flex"},[_c('div',{staticClass:"right ml-4 flex-1"},[_c('div',{staticClass:"search-wrapper flex justify-between items-center"},[_c('el-form',{staticClass:"demo-form-inline",attrs:{"inline":true,"model":_vm.queryModel}},[_c('el-form-item',{attrs:{"label":""}},[_c('el-date-picker',{attrs:{"type":"month","placeholder":"选择月","value-format":"yyyy-MM"},model:{value:(_vm.queryModel.monthDate),callback:function ($$v) {_vm.$set(_vm.queryModel, "monthDate", $$v)},expression:"queryModel.monthDate"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("查询")])],1)],1),_c('el-row')],1),_c('div',{staticClass:"calendar-wrapper"},[_c('el-calendar',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],scopedSlots:_vm._u([{key:"dateCell",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"w-full h-full relative p-2 calendar-item overflow-y-scroll",on:{"click":function($event){return _vm.handleCalendarClick($event, data)}}},[_vm._l((_vm.calendarData),function(item){return _c('div',{key:item.id},[(data.day == item.dutyTime)?_c('div',[_c('ul',[_vm._l((item.dayShiftList),function(i){return _c('li',{key:i.id},[_c('span',{staticClass:"label"},[_vm._v("白班：")]),_c('span',[_vm._v(_vm._s(i.realName))])])}),_vm._l((item.nightShiftList),function(i){return _c('li',{key:i.id},[_c('span',{staticClass:"label"},[_vm._v("夜班：")]),_c('span',[_vm._v(_vm._s(i.realName))])])})],2)]):_vm._e()])}),_c('p',{staticClass:"date absolute right-0 top-0"},[_vm._v(" "+_vm._s(data.day.slice(-2))+" ")])],2)]}}]),model:{value:(_vm.calendarDate),callback:function ($$v) {_vm.calendarDate=$$v},expression:"calendarDate"}})],1)])])],1),_c('system-modal',{ref:"setDutyModal",attrs:{"title":_vm.title},on:{"modalConfirm":_vm.handleSubmit,"modalClose":_vm.handleClose}},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.formLoading),expression:"formLoading"}],ref:"dynamicValidateForm",staticClass:"demo-dynamic",attrs:{"model":_vm.dynamicValidateForm,"label-width":"100px"}},[_vm._l((_vm.dynamicValidateForm.dayShiftList),function(day,index){return _c('el-form-item',{key:index,attrs:{"label":index == 0 ? '白班人员' : '',"prop":'dayShiftList.' + index + '.id',"rules":{
          required: true,
          message: '人员不能为空',
          trigger: 'change',
        }}},[_c('el-select',{staticStyle:{"width":"50%"},attrs:{"placeholder":"请选择"},on:{"change":function($event){return _vm.personChange($event, index, 1)}},model:{value:(day.id),callback:function ($$v) {_vm.$set(day, "id", $$v)},expression:"day.id"}},_vm._l((_vm.personList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('el-button',{staticClass:"ml-4",attrs:{"type":"primary","icon":"el-icon-plus","size":"mini"},on:{"click":function($event){return _vm.addPersonnel(1)}}}),(_vm.dynamicValidateForm.dayShiftList.length > 1)?_c('el-button',{staticClass:"ml-4",attrs:{"type":"danger","size":"mini","icon":"el-icon-minus"},on:{"click":function($event){$event.preventDefault();return _vm.removePersonnel(1, index, day)}}}):_vm._e()],1)}),_vm._l((_vm.dynamicValidateForm.nightShiftList),function(night,index){return _c('el-form-item',{key:night.key,attrs:{"label":index == 0 ? '夜班人员' : '',"prop":'nightShiftList.' + index + '.id',"rules":{
          required: true,
          message: '人员不能为空',
          trigger: 'change',
        }}},[_c('el-select',{staticStyle:{"width":"50%"},attrs:{"placeholder":"请选择"},on:{"change":function($event){return _vm.personChange($event, index, 2)}},model:{value:(night.id),callback:function ($$v) {_vm.$set(night, "id", $$v)},expression:"night.id"}},_vm._l((_vm.personList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('el-button',{staticClass:"ml-4",attrs:{"type":"primary","icon":"el-icon-plus","size":"mini"},on:{"click":function($event){return _vm.addPersonnel(2)}}}),(_vm.dynamicValidateForm.nightShiftList.length > 1)?_c('el-button',{staticClass:"ml-4",attrs:{"type":"danger","size":"mini","icon":"el-icon-minus"},on:{"click":function($event){$event.preventDefault();return _vm.removePersonnel(2, index, night)}}}):_vm._e()],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }