<template>
  <Modal
    ref="model"
    :modal="false"
    :width="'55%'"
    :title="titleTable"
    @closeDialog="closeDialog"
  >
    <el-form
      ref="libraryForm"
      :inline="true"
      :model="libraryForm"
      class="demo-form-inline"
    >
      <el-form-item prop="companyName" v-if="name !== '隐患任务'">
        <el-cascader
          ref="treeSree"
          filterable
          clearable
          v-model="libraryForm.siteIds"
          placeholder="线路工点"
          :props="props"
          :options="treeData"
          :show-all-levels="false"
          @change="handleLineSiteChange"
          :popper-append-to-body="false"
        ></el-cascader>
      </el-form-item>
      <el-form-item prop="riskTypeId" v-if="type == 'left'">
        <el-select
          v-model="libraryForm.riskTypeId"
          placeholder="风险类别"
          :popper-append-to-body="false"
          clearable
        >
          <el-option
            v-for="item in stateData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="riskTypeChildId" v-if="type == 'left'">
        <el-input
          v-model="libraryForm.riskTypeChildId"
          clearable
          placeholder="风险子类"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="companyName"
        v-if="name == '关键节点统计' || name == '危大工程统计'"
      >
        <el-input
          v-model="libraryForm.companyName"
          clearable
          placeholder="类别"
        ></el-input>
      </el-form-item>

      <el-form-item prop="companyName" v-if="name == '危大工程统计'">
        <el-select
          clearable
          v-model="libraryForm.state"
          placeholder="开工状态"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in state"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="companyName" v-if="name == '预警统计'">
        <el-select
          clearable
          v-model="libraryForm.warning"
          placeholder="预警等级"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in warnState"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        prop="categoryPName"
        v-if="name == '隐患上报概况' || name == '隐患等级分布'"
      >
        <el-input
          v-model="libraryForm.categoryPName"
          clearable
          placeholder="隐患类别"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="categoryName"
        v-if="name == '隐患上报概况' || name == '隐患等级分布'"
      >
        <el-input
          v-model="libraryForm.categoryName"
          clearable
          placeholder="隐患子类"
        ></el-input>
      </el-form-item>
      <el-form-item prop="companyName" v-if="name == '隐患上报概况'">
        <el-select
          clearable
          v-model="libraryForm.hiddenLevel"
          placeholder="预警等级"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in rankLevel"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="name !== '隐患任务'">
        <el-button type="primary" @click="libraryQuery()">查询</el-button>
      </el-form-item>
    </el-form>
    <div class="tablese">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
          type="index"
          label="序号"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.minWidth"
          show-overflow-tooltip
          align="center"
        >
          <!-- <template slot-scope="scope">
            <div v-if="item.prop == 'lineName'">
              <span>{{ scope.row.lineName }}{{ scope.row.siteName }}</span>
            </div>
            <div v-else>
              <span>{{ scope.row[item.prop] }}</span>
            </div>
          </template> -->
        </el-table-column>
      </el-table>
    </div>
    <div
      class="pagination-wrapper flex justify-center mt-4"
      v-if="name !== '隐患任务'"
    >
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="libraryForm.page"
        :page-size="5"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal/modal.vue";

// 右侧-隐患中/中下,左侧-风险左上,左中-风险类别统计,下左-危大工程统计,下右-关键节点统计
import {
  getDistGeneralList,
  getRiskCountPage,
  getRiskTypePage,
  getDangerousProjectPage,
  getSubWayNodePage,
} from "@/api/hdanger";
export default {
  props: {
    titleTable: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: () => [],
    },
    tableList: {
      type: Array,
    },
    totalData: {
      type: Number,
    },
    // 右侧隐患上报/隐患等级分布
    params: {
      type: Object,
    },
    // 左侧风险等级
    leftOneParams: {
      type: Object,
    },
    // 左侧风险类别
    leftTwoParams: {
      type: Object,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      loading: false,
      tableData: [],
      libraryForm: {
        page: 1,
        size: 5,
        categoryName: "",
        categoryPName: "",
        siteId: "",
        siteIds: [],
        riskTypeId: "",
        riskTypeChildId: "",
        warning: "",
        hiddenLevel: "",
        workAreaNameList: [],
      },
      total: 0,
      stateData: [],
      type: "left",
      name: "关键节点统计",
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "id",
      },
      state: [
        {
          label: "施工中",
          value: 1,
        },
        {
          label: "已完工",
          value: 0,
        },
        {
          label: "未完工",
          value: 2,
        },
      ],
      warnState: [
        {
          label: "红色",
          value: 2,
        },
        {
          label: "黄色",
          value: 2,
        },
        {
          label: "绿色",
          value: 2,
        },
      ],
      rankLevel: [
        { value: "I级", label: "I级" },
        { value: "Ⅱ级", label: "Ⅱ级" },
        { value: "Ⅲ级", label: "Ⅲ级" },
        { value: "Ⅳ级", label: "Ⅳ级" },
      ],
    };
  },
  watch: {
    tableList: {
      handler(val) {
        if (val) {
          this.tableData = this.tableList;
          this.total = this.totalData;
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
      formLoading: true,
    },
  },
  mounted() {},
  methods: {
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    handleLineSiteChange(val) {
      if (val && val.length) {
        this.libraryForm.siteId = val.slice(-1)[0];
        this.libraryForm.siteIds = val;
      } else {
        this.libraryForm.siteIds = [];
        this.libraryForm.siteId = null;
      }
      this.libraryForm.workAreaNameList = [];
      let casename = this.$refs["treeSree"].getCheckedNodes()[0].label;
      this.libraryForm.workAreaNameList.push(casename);
    },
    //查询
    libraryQuery() {
      this.loading = true;
      switch (this.name) {
        case "隐患等级分布":
          this.rightQuery();
          break;
        case "隐患上报概况":
          this.rightQuery();
          break;
        case "风险等级":
          this.leftTopQuery();
          break;
        case "风险类别统计":
          this.leftCenterQuery();
          break;
        case "危大工程统计":
          this.bottomLeftQuery();
          break;
        case "关键节点统计":
          this.bottomRightQuery();
          break;
      }
    },
    // 右侧-隐患上报概况/隐患等级分布
    rightQuery() {
      let param = {
        categoryName: this.libraryForm.categoryName,
        categoryPName: this.libraryForm.categoryPName,
        hiddenLevel: this.libraryForm.hiddenLevel,
        workAreaNameList: this.libraryForm.workAreaNameList,
        pageNum: this.libraryForm.page,
        pageSize: 5,
      };
      param.weekStar = this.params.weekStar;
      param.weekEnd = this.params.weekEnd;
      param.month = this.params.month;
      getDistGeneralList(param)
        .then((res) => {
          if (res.code == 0) {
            this.loading = false;
            this.tableData = res.data.records;
            this.total = res.data.count;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 左上-风险等级
    leftTopQuery() {
      let param = {
        riskTypeChildId: this.libraryForm.riskTypeChildId,
        riskTypeId: this.libraryForm.riskTypeId,
        siteIdList: this.libraryForm.workAreaNameList,
        status: this.leftOneParams.status,
        riskLevel: this.leftOneParams.riskLevel,
        pageNum: this.libraryForm.page,
        pageSize: 5,
      };
      getRiskCountPage(param)
        .then((res) => {
          if (res.code == 0) {
            this.loading = false;
            this.tableData = res.data.records;
            this.total = res.data.count;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 左中-风险类别统计
    leftCenterQuery() {
      let param = {
        riskTypeChildId: this.libraryForm.riskTypeChildId,
        riskTypeId: this.leftTwoParams.riskTypeId,
        siteIdList: this.libraryForm.workAreaNameList,
        pageNum: this.libraryForm.page,
        pageSize: 5,
      };
      getRiskTypePage(param)
        .then((res) => {
          if (res.code == 0) {
            this.loading = false;
            this.tableData = res.data.records;
            this.total = res.data.count;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 下左-危大工程统计
    bottomLeftQuery() {
      let paramData = {
        status: this.params.status,
        bigger: this.params.bigger,
        siteIdList: [],
        projectTypeId: "",
        pageNum: this.libraryForm.page,
        pageSize: 5,
      };
      getDangerousProjectPage(paramData)
        .then((res) => {
          if (res.code == 0) {
            this.loading = false;
            this.tableData = res.data.records;
            this.total = res.data.count;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 下右-关键节点统计
    bottomRightQuery() {
      let paramData = {
        status: this.params.status,
        nodeLevel: this.params.nodeLevel,
        siteIdList: [],
        nodeType: "",
        pageNum: this.libraryForm.page,
        pageSize: 5,
      };
      getSubWayNodePage(paramData)
        .then((res) => {
          if (res.code == 0) {
            this.loading = false;
            this.tableData = res.data.records;
            this.total = res.data.count;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    handleOpen(type, name) {
      this.type = type;
      this.name = name;
      this.getLineSiteTreeData();
      this.$refs.model.handleOpen();
    },
    // 分页
    handleCurrentChange(val) {
      this.libraryForm.page = val;
      this.libraryQuery();
    },
    closeDialog() {
      this.libraryForm = {
        page: 1,
        pageSize: 5,
        categoryName: "",
        categoryPName: "",
        hiddenLevel: "",
        workAreaNameList: [],
        siteIds: [],
        riskTypeId: "",
        riskTypeChildId: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/elTable.scss";

//显示滚动条
::v-deep ::-webkit-scrollbar {
  width: 1px;
  height: 2px;
  display: block !important;
}
/*定义两端按钮的样式*/
::v-deep .el-table__body-wrapper {
  overflow-y: scroll !important;
}
.info-wrapper {
  ::v-deep .el-table--border,
  .el-table::before {
    border: 0 !important;
    height: 0px;
  }
}
::v-deep .el-radio-button__inner {
  width: 200px;
}
::v-deep .el-input__inner {
  background: #081742;
  border-color: #25458f;
  color: #fff;
}

::v-deep .el-table th.is-leaf,
::v-deep .el-table--border td {
  border: 1px solid #143363 !important;
}
</style>
