<template>
  <div class="organization min-h-full bg-white">
    <div class="searchbar">
      <div class="searchbar-left">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          :size="size"
        >
          <el-form-item label="单位/部门名称">
            <el-input
              v-model="formInline.name"
              placeholder="单位/部门名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="单位类型">
            <el-select
              v-model="formInline.category"
              clearable
              filterable
              placeholder="单位类型"
            >
              <el-option
                v-for="item in categoryData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="searchbar-right">
        <el-button
          :disabled="tableLoading"
          @click="add()"
          class="add_button"
          type="primary"
          :size="size"
          v-has="'organization:add'"
        >
          <span>新增</span>
        </el-button>
        <!-- <el-button
          @click="dialogVisible = true"
          class="add_button"
          type="primary"
          size="small"
        >
          <span>导出</span>
        </el-button> -->
      </div>
    </div>
    <div>
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        height="78vh"
        size="mini"
        row-key="uuid"
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column type="index" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column
          prop="name"
          header-align="center"
          label="单位名称"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          prop="vendorOrDeptName"
          align="center"
          label="单位/部门"
        >
        </el-table-column>
        <el-table-column prop="categoryName" align="center" label="单位类型">
        </el-table-column>
        <el-table-column
          prop="roleType"
          align="center"
          label="部门类型"
          :formatter="roleTypeFormatter"
        >
        </el-table-column>
        <el-table-column
          prop="roleUnitTypeName"
          align="center"
          label="关联类型"
        >
        </el-table-column>

        <el-table-column prop="sort" align="center" label="排序">
        </el-table-column>
        <el-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button
              :disabled="scope.row.typeFlag == '1' ? true : false"
              :size="size"
              type="text"
              @click="handleEdit(scope.row)"
              v-has="'organization:del'"
              >修改</el-button
            >
            <el-button
              :size="size"
              type="text"
              @click="deleteRow(scope.row)"
              v-has="'organization:del'"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-dialog
        class="system-modal"
        width="30%"
        :title="addTitle"
        center
        :visible.sync="dialogVisible"
        :before-close="handleClose"
      >
        <el-form
          ref="form"
          :rules="addRules"
          :model="form"
          label-width="80px"
          :size="size"
        >
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="上级单位" prop="unit">
            <popup-tree-input
              :data="unitsData"
              :props="unitsProps"
              :nodeKey="form.puuid"
              :prop="form.unit"
              :currentChangeHandle="handleCheckChange"
            >
            </popup-tree-input>
          </el-form-item>
          <el-form-item label="单位/部门" prop="vendorOrDeptName">
            <el-select
              v-model="form.vendorOrDeptName"
              placeholder="单位"
              @change="vendorChange"
            >
              <el-option
                v-for="item in sectionData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单位类型" prop="categoryName">
            <el-input
              v-model="form.categoryName"
              readonly
              placeholder="请输入单位类型"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="部门类型"
            prop="roleType"
            :rules="
              form.vendorOrDeptName == 1
                ? addRules.roleType
                : [{ required: false }]
            "
          >
            <el-select
              :disabled="roleTypeDisabled"
              v-model="form.roleType"
              placeholder="部门类型"
            >
              <el-option
                v-for="item in roleTypeData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="关联类型"
            prop="roleUnitType"
            :rules="
              form.vendorOrDeptName == 1
                ? addRules.roleUnitType
                : [{ required: false }]
            "
          >
            <el-select
              :disabled="roleTypeDisabled"
              v-model="form.roleUnitType"
              placeholder="关联类型"
            >
              <el-option
                v-for="item in unitTypeData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择工点">
            <el-button type="primary" @click="chooseWorksite(form)"
              >选择工点</el-button
            >
          </el-form-item>
          <el-form-item label="排序编号" prop="sort">
            <el-input-number
              v-model="form.sort"
              controls-position="right"
              :min="0"
              label="排序编号"
            ></el-input-number>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <span>
            <el-button @click="cancel()">取 消</el-button>
            <el-button type="primary" @click="handleSubmit">确 定</el-button>
          </span>
        </span>
      </el-dialog>
    </div>
    <div class="editDialog">
      <el-dialog
        v-if="dialogQxVisible"
        class="system-modal"
        :width="'450px'"
        title="分配权限"
        center
        :visible.sync="dialogQxVisible"
        :before-close="chooseCancel"
      >
        <div class="tree-wrapper h-full overflow-y-scroll mr-4">
          <el-tree
            v-loading="treeLoading"
            :data="treeData"
            show-checkbox
            node-key="uuid"
            :props="defaultProps"
            ref="treeRef"
            accordion
            :default-checked-keys="defaultCheckedKeys"
            element-loading-text="拼命加载中"
          >
          </el-tree>
        </div>
        <span slot="footer" class="dialog-footer">
          <span>
            <el-button @click.native="chooseCancel">取 消</el-button>
            <el-button type="primary" @click="allotSubmit">提交</el-button>
          </span>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  // getPageList,
  // getUnit,
  saveVendor,
  deleteUnit,
  deleteSection,
  editVendor,
  getUserDeptList,
} from "@/api/org";
import PopupTreeInput from "@/components/PopupTreeInput";
import { tripleTree } from "@/api/map";
// 回显和保存工点
import { saveTree, getTreeList } from "@/api/resource";

import { queryDictNodesById, getDictData } from "@/api/dict";
export default {
  name: "Organization",
  components: {
    PopupTreeInput,
  },
  data() {
    return {
      dialogVisible: false,
      tableLoading: false,
      addTitle: "",
      // 选择工点弹框
      dialogQxVisible: false,
      editLoading: false,
      treeLoading: false,
      treeData: [],
      siteUuIdList: [],
      siteIdList: [],
      vendorId: "",
      parentId: "",
      uuid: "",
      // 权限树
      defaultProps: {
        label: "name",
        children: "children",
      },
      demo: 0,
      // 分配权限参数
      // deptId: "",
      title: "",
      selectedValue: "",
      defaultCheckedKeys: [],
      unitsData: [],
      unitsProps: {
        children: "children",
        label: "name",
      },
      formInline: {
        name: "",
        category: "",
      },
      form: {
        name: "",
        unit: "",
        category: "",
        categoryName: "",
        vendorOrDeptName: "",
        sort: 0,
        roleType: "",
        roleUnitType: "",
      },
      size: "small",
      categoryData: [],
      // 新增上级单位
      unitData: [],
      tableData: [],
      sectionData: [
        {
          label: "单位",
          value: 0,
        },
        {
          label: "部门",
          value: 1,
        },
      ],
      // 部门类型
      roleTypeDisabled: false,
      roleTypeData: [
        {
          label: "施工",
          value: 1,
        },
        {
          label: "监理",
          value: 2,
        },
        {
          label: "建管",
          value: 3,
        },
        {
          label: "质安部",
          value: 4,
        },
        {
          label: "其他",
          value: 5,
        },
        {
          label: "总包单位",
          value: 6,
        },
      ],
      // 关联类型
      unitTypeData: [],
      addRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        unit: [{ required: true, message: "请选择单位", trigger: "change" }],
        categoryName: [
          {
            required: true,
            message: "请选择单位类型",
            trigger: "change",
          },
        ],
        roleType: [
          {
            required: true,
            message: "请选择部门类型",
            trigger: "change",
          },
        ],
        roleUnitType: [
          {
            required: true,
            message: "请选择关联类型",
            trigger: "change",
          },
        ],
      },
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  mounted() {
    this.getList();
    this.getData();
    this.getTripleTree();
    this.getDictDatas();
    console.log(this.user, "user");
  },
  methods: {
    // 获取单位类型
    getDictDatas() {
      let label = "unitType";
      this.unitTypeData = [];
      getDictData(label).then((res) => {
        if (res.code == 0) {
          res.data.forEach((element) => {
            this.unitTypeData.push({
              label: element.label,
              value: element.code,
            });
          });
        }
      });
    },
    //分配权限弹出框
    getTripleTree() {
      let params = {};
      tripleTree(params)
        .then((res) => {
          if (res.code === 0) {
            this.treeData = res.data;
          }
        })
        .catch((res) => {
          this.$message({
            message: res.message,
            type: "error",
          });
        });
    },
    roleTypeFormatter(row) {
      return row.roleType == "1"
        ? "施工"
        : row.roleType == "2"
        ? "监理"
        : row.roleType == "3"
        ? "建管"
        : row.roleType == "4"
        ? "质安部"
        : row.roleType == "5"
        ? "其他"
        : row.roleType == "6"
        ? "总包单位"
        : "";
    },
    vendorChange(val) {
      if (val == 0) {
        this.roleTypeDisabled = true;
        // this.form.roleType = "";
        // this.form.roleUnitType = "";
      } else if (val == 1) {
        this.roleTypeDisabled = false;
      }
    },
    getList() {
      this.tableLoading = true;
      let params = {
        name: this.formInline.name,
        category: this.formInline.category,
      };
      getUserDeptList(params)
        .then((res) => {
          if (res.code === 0) {
            // 获取列表
            this.tableData = res.data;
            // 获取上级单位
            // this.unitsData = res.data;
          }
          this.tableLoading = false;
        })
        .catch((res) => {
          this.tableLoading = false;
          this.$message({
            message: res.message,
            type: "error",
          });
        });
    },
    getData() {
      let params = {
        id: 306,
      };
      this.categoryData = [];
      queryDictNodesById(params)
        .then((res) => {
          if (res.code === 0) {
            res.data.forEach((item) => {
              this.categoryData.push({
                label: item.intro,
                value: item.code,
              });
            });
          }
        })
        .catch((res) => {
          this.$message({
            message: res.message,
            type: "error",
          });
        });
    },
    // 选择工点
    chooseWorksite(params) {
      this.dialogQxVisible = true;
      if (this.addTitle == "新增") {
        if (this.$refs.treeRef) {
          this.$refs.treeRef.setCheckedKeys([]);
        }
      } else {
        this.treeLoading = true;
        // 默认回显
        this.defaultCheckedKeys = [];
        let param = {
          deptId: params.uuid,
        };
        getTreeList(param)
          .then((res) => {
            this.treeLoading = false;
            this.defaultCheckedKeys = [...res.data];
          })
          .catch((res) => {
            this.$message({
              message: res.message,
              type: "error",
            });
          });
      }

      //this.operation == "新增"

      // this.deptId = params.uuid;

      this.demo = 1;
    },
    // 选增工点弹出框取消
    chooseCancel() {
      this.dialogQxVisible = false;
      this.defaultCheckedKeys = [];
    },

    //工点暂存
    allotSubmit() {
      // this.siteUuIdList = [...this.$refs.treeRef.getCheckedKeys(true)];
      const selectNodeData = this.$refs.treeRef.getCheckedNodes(true);
      for (let item of selectNodeData) {
        this.siteUuIdList.push(item.uuid);
        this.siteIdList.push(item.id);
      }
      this.dialogQxVisible = false;
    },
    // 显示编辑界面
    handleEdit(row) {
      this.addTitle = "修改";
      this.dialogVisible = true;
      this.title = "修改";
      this.dataAdmin();
      this.$nextTick(() => {
        this.form = { ...row };
        this.form.category = row.category;
        this.form.categoryName = row.categoryName;
        this.uuid = row.uuid;
        // 单位parentId为0,parentId为上级的uuid
        if (row.vendorOrDeptName == "单位") {
          this.parentId = "0";
          this.vendorId = row.vendorId;
          // row.parentName = "顶级菜单";
        } else if (row.vendorOrDeptName == "部门") {
          this.parentId = row.parentId;
          this.vendorId = row.vendorId;
        }
        this.form.unit = row.parentName;
        this.demo = 0;
        this.siteUuIdList = [];
        this.siteIdList = [];
      });
    },
    // 工点保存
    saveWorkSite(id) {
      if (this.demo == 1) {
        let params = {
          deptId: id,
          siteUuIdList: this.siteUuIdList,
          siteIdList: this.siteIdList,
        };
        if (params.siteUuIdList.length) {
          this.treeLoading = true;
          saveTree(params)
            .then((res) => {
              if (res.code === 0) {
                this.treeLoading = false;
                this.$message.success("保存成功");
                this.getList();
                this.chooseCancel();
              }
            })
            .catch((res) => {
              this.$message({
                message: res.message,
                type: "error",
              });
            });
        } else {
          this.$message.warning("请至少勾选一个工点");
        }
      } else {
        return;
      }
    },
    // 上级单位触发方法
    handleCheckChange(data) {
      this.selectedValue = data;
      this.form.unit = data.name;
      this.form.puuid = data.uuid;
      this.form.category = data.category;
      this.form.categoryName = data.categoryName;

      if (data.typeFlag == "1") {
        this.parentId = "0";
      } else {
        this.vendorId = data.vendorId;
        this.parentId = data.uuid;
      }
    },

    onSubmit() {
      this.getList();
    },
    deleteRow(rows) {
      if (rows.children.length) {
        this.$message.warning("此单位下有部门,删除部门后方可删除此项");
      } else {
        if (rows.vendorOrDeptName == "单位") {
          // 删除单位
          this.$confirm("是否确定删除?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              deleteUnit(rows.uuid).then((res) => {
                if (res.code == 0) {
                  this.$message({
                    message: "删除成功！",
                    type: "success",
                  });
                  this.getList();
                } else {
                  this.$message.error("删除失败！");
                }
              });
            })
            .catch(() => {});
        } else {
          // 删除部门
          this.$confirm("是否确定删除?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              deleteSection(rows.uuid).then((res) => {
                if (res.code == 0) {
                  this.$message({
                    message: "删除成功！",
                    type: "success",
                  });
                  this.getList();
                } else {
                  this.$message.error("删除失败！");
                }
              });
            })
            .catch(() => {});
        }
      }
    },
    add() {
      this.addTitle = "新增";
      this.dialogVisible = true;
      this.defaultCheckedKeys = [];
      this.dataAdmin();
    },
    // 上级单位对超管加一个参数
    dataAdmin() {
      let params = {};
      if (this.user.roleIdList && this.user.roleIdList[0] == 1) {
        params.typeFlag = true;
      }
      getUserDeptList(params)
        .then((res) => {
          if (res.code === 0) {
            // 获取上级单位
            this.unitsData = res.data;
          } else {
            // this.tableLoading = false;
          }
        })
        .catch((res) => {
          this.$message({
            message: res.message,
            type: "error",
          });
        });
    },

    // 添加取消
    cancel() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      this.form.roleUnitType = "";
    },
    handleClose() {
      this.cancel();
    },
    // 添加保存
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = {
            category: this.form.category,
            name: this.form.name,
            parentId: this.parentId,
            sort: this.form.sort,
            vendorId: this.vendorId,
            uuid: this.uuid,
            roleType: this.form.roleType,
            roleUnitType: this.form.roleUnitType,
          };
          if (this.addTitle == "新增") {
            saveVendor(params)
              .then((res) => {
                if (res.code === 0) {
                  this.$message.success("添加成功");
                  this.cancel();
                  this.getList();
                }
              })
              .catch((res) => {
                this.dialogVisible = false;
                this.$message({
                  message: res.message,
                  type: "error",
                });
                this.saveWorkSite(res.data.uuid);
              });
          } else {
            editVendor(params)
              .then((res) => {
                if (res.code === 0) {
                  this.$message.success("修改成功");
                  this.cancel();
                  this.getList();
                }
                this.saveWorkSite(this.uuid);
              })
              .catch((res) => {
                this.dialogVisible = false;
                this.$message({
                  message: res.message,
                  type: "error",
                });
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.organization {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  .searchbar {
    display: flex;
    justify-content: space-between;
  }
}
::v-deep .el-tree {
  color: #606266;
  overflow: auto;
  height: 200px !important;
}
.editDialog {
  ::v-deep .el-select-dropdown__item {
    height: 200px !important;
    padding: 0;
  }
}
</style>
