<template>
  <systemModal
    ref="formModal"
    @modalConfirm="handleSubmit"
    @modalClose="handleModalClose"
    :title="title"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="model"
      label-width="80px"
      class="demo-model"
    >
      <el-form-item label="角色名称" prop="roleName">
        <el-input
          v-model="model.roleName"
          placeholder="请输入角色名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="单位类型" prop="unitType">
        <el-select v-model="model.unitType" placeholder="请选择单位类型">
          <el-option
            v-for="item of categoryData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div>
        <el-card class="box-card">
          <div slot="header" class="clearfix flex justify-between items-center">
            <span>资源列表树</span>
            <el-dropdown @command="handleDropMenuCommand">
              <el-button type="primary">
                树操作<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="1">父子关联</el-dropdown-item>
                <el-dropdown-item :command="2">取消关联</el-dropdown-item>
                <!-- <el-dropdown-item>全部勾选</el-dropdown-item>
                <el-dropdown-item>取消全选</el-dropdown-item>
                <el-dropdown-item>展开</el-dropdown-item>
                <el-dropdown-item>合并所有</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="tree-wrapper overflow-y-scroll" style="height: 30vh">
            <el-tree
              :data="tree"
              show-checkbox
              node-key="id"
              ref="trees"
              highlight-current
              :indent="40"
              :default-checked-keys="targetRules"
              :check-strictly="treeProps.relation"
              default-expand-all
              :props="{
                children: 'sub',
                label: 'resourceName',
              }"
            ></el-tree>
          </div>
        </el-card>
      </div>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { getDictData } from "@/api/dict";
import { rpermissionList, resourceAll } from "@/api/resource";
import { addRole, updateRole } from "@/api/role";
import { updateBatch } from "@/api/resource";

export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "新增",
      model: {
        roleName: "",
        unitType: "",
      },
      categoryData: [], // 单位类型
      adminRules: [],
      tree: [],
      treeProps: {
        relation: true,
      },
      targetRules: [],
      loading: false,
      rules: {
        roleName: [
          { required: true, message: "请输入角色名", trigger: "blur" },
        ],
        unitType: [
          { required: true, message: "请选择单位类型", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.getDictDatas();
    this.getAllResource();
  },
  methods: {
    // 获取单位类型
    getDictDatas() {
      let label = "unitType";
      this.categoryData = [];
      getDictData(label).then((res) => {
        if (res.code == 0) {
          res.data.forEach((element) => {
            this.categoryData.push({
              label: element.label,
              value: element.code,
            });
          });
        }
      });
    },
    // 查询全部资源列表
    async getAllResource() {
      let res = await resourceAll();
      if (res.code == 0) {
        let treeList = res.data;
        let handleResource = (resource) => {
          let parent = treeList.find((p) => p.id == resource.parentId);
          if (parent) {
            if (resource.parentId !== 0 && parent.sub) {
              parent.sub.push(resource);
            } else {
              parent.sub = [resource];
            }
          } else {
            console.log(parent);
          }
        };
        treeList.forEach((item) => handleResource(item));
        this.tree = treeList.filter((item) => item.parentId === 0);
        this.loading = false;
        for (let item of res.data) {
          this.adminRules.push(item.id);
        }
      }
    },
    // 根据角色id查询权限，数据回显
    async getPermissionData(roleId) {
      if (roleId == 1) {
        this.targetRules = JSON.parse(JSON.stringify(this.adminRules));
        this.loading = false;
      } else {
        let res = await rpermissionList(roleId);
        if (res.code == 0) {
          const list = [];
          res.data.forEach((e) => {
            list.push(e.resourceId);
          });
          this.targetRules = list;
          if (this.tree.length) {
            this.loading = false;
          }
        }
      }
    },
    // 下拉菜单事件，处理树操作方式
    handleDropMenuCommand(val) {
      switch (val) {
        case 1:
          this.treeProps.relation = false;
          break;
        case 2:
          this.treeProps.relation = true;
          break;
      }
    },
    handleAdd(record) {
      this.loading = true;
      if (record) {
        this.title = "编辑";
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
        this.getPermissionData(record.id);
      } else {
        this.title = "新增";
        this.loading = false;
      }
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    handleSubmit() {
      this.$refs.model.validate((valid) => {
        if (valid) {
          this.loading = true;
          let label = {
            roleName: this.model.roleName,
            unitType: this.model.unitType,
          };
          let pardata = [];
          let ids = [...this.$refs.trees.getCheckedKeys()];
          ids.forEach((e) => {
            pardata.push({
              roleId: this.model.id,
              resourceId: e,
              permissionLevel: 0,
            });
          });
          if (this.title == "新增") {
            addRole(label).then((res) => {
              if (res.code == 0) {
                this.getId(res.data.id);
                this.loading = false;
              } else {
                this.$message.error(res.message);
                this.loading = false;
              }
            });
          } else {
            label = {
              roleName: this.model.roleName,
              unitType: this.model.unitType,
              id: this.model.id,
            };
            updateRole(label).then((res) => {
              if (res.code == 0) {
                updateBatch({ data: pardata }).then((res) => {
                  if (res.code == 0) {
                    this.loading = false;
                    this.$message.success("操作成功");
                    this.$emit("onSubmit");
                    this.$refs.formModal.handleClose();
                    this.$refs.model.resetFields();
                  }
                });
              } else {
                this.$message.error(res.message);
                this.loading = false;
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //新增 走分配权限接口
    getId(id) {
      let pardata = [];
      let ids = [...this.$refs.trees.getCheckedKeys()];
      ids.forEach((e) => {
        pardata.push({
          roleId: id,
          resourceId: e,
          permissionLevel: 0,
        });
      });
      updateBatch({ data: pardata }).then((res) => {
        if (res.code == 0) {
          this.loading = false;

          this.$message.success("操作成功");
          this.$emit("onSubmit");
          this.$refs.formModal.handleClose();
          this.$refs.model.resetFields();
        }
      });
    },
    handleModalClose() {
      this.resizeForm();
    },
    // 表单重置
    resizeForm() {
      this.$nextTick(() => {
        this.$refs.trees.setCheckedKeys([]);
      });
      this.$refs.model.resetFields();

      this.model = {
        roleName: "",
        unitType: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
