<template>
  <!-- 危大工程清单库 -->
  <div class="page-container bg-white p-4 h-full relative">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item prop="riskLevelCode">
            <el-cascader
                v-model="queryModal.linsiteIds"
                clearable
                :options="treeData"
                placeholder="线路工点"
                :props="props"
                :show-all-levels="false"
                collapse-tags
                @change="lineChange"
            ></el-cascader>
<!--            <el-cascader v-model="lineSite"
                         clearable :options="treeData" :props="props"
                         :show-all-levels="false" collapse-tags @change="handleLineSiteChange"></el-cascader>-->
          </el-form-item>
          <el-form-item prop="riskLevelCode">
            <el-select
              v-model="queryModal.typeCode"
              clearable
              placeholder="类别"
            >
              <el-option
                v-for="item of bigProjectList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="warnGradeCode">
            <el-select
              v-model="queryModal.isLargeScale"
              clearable
              placeholder="是否超大规模"
            >
              <el-option
                v-for="item of isBigList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="warnGradeCode">
            <el-input
              v-model="queryModal.projectName"
              clearable
              placeholder="名称"
            >
            </el-input>
          </el-form-item>
          <!-- <el-form-item>
            <el-select
              v-model="queryModal.responseRoleId"
              clearable
              placeholder="请选择用户角色"
            >
              <el-option
                v-for="item of rolesData"
                :key="item.id"
                :label="item.roleName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="add">新增</el-button>
          <el-button type="primary" @click="development">导入</el-button>
          <el-button type="primary" @click="handleExport">导出</el-button>
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table v-loading="tableLoading" :data="tableData" style="width: 100%">
        <el-table-column type="index" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column
          prop="lineName"
          align="center"
          label="线路"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="branchName"
          align="center"
          label="分部"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="siteName"
          align="center"
          label="工点"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="typeName"
          align="center"
          label="类别"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="projectName"
          align="center"
          label="危大工程名称"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="isLargeScale"
          align="center"
          label="是否超大规模"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.isLargeScale == 0 ? '否' : scope.row.isLargeScale == 1 ? '是' : '' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="materialsStatus"
          align="center"
          label="专家论证"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.materialsStatus == 0 ? '未完成' : scope.row.materialsStatus == 1 ? '已完成' : '' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="commencementStatus"
          align="center"
          label="开工状态"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.commencementStatus == 0 ? '未开工' : scope.row.commencementStatus == 1 ? '已开工' : '' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="realStartDay"
          align="center"
          label="实际开工时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="realEndDay"
          align="center"
          label="实际结束时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="160">
          <template slot-scope="scope">
            <el-button type="text" @click="view(scope.row)">查看</el-button>
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" @click="del(scope.row)" style="color: red"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        :total="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        layout=" prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      width="880px"
      :title="title"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <AddPage
        ref="addPageData"
        @CloseData="CloseData"
        :pageData="pageData"
        @cancelModal="cancelModal"
        :submitType="submitType"
      ></AddPage>
    </el-dialog>

    <el-dialog
        width="420px"
        title="导入"
        :visible.sync="importDialog"
        v-if="importDialog"
        append-to-body
        :close-on-click-modal="false"
        :before-close="importHandleClose"
    >
      <importPage @CloseData="importHandleClose"></importPage>
    </el-dialog>

  </div>
</template>

<script>
import AddPage from "./modules/addPage";
import importPage from './modules/importPage'
// 字典
import { getDictData } from "@/api/dict";

import {bigProjectExport, riskDangerBigProjectDelete, riskDangerBigProjectList} from "@/api/riskManage";

export default {
  components: {
    AddPage,
    importPage
  },
  data() {
    return {
      dialogVisible: false,
      importDialog: false,
      queryModal: {
        companyTypeCode: "",
        roleId: "",
        riskLevelCode: "",
        siteUuidList: [],
      },
      unitTypeData: [],
      isBigList: [
        {
          label : '是',
          value : 1
        },
        {
          label : '否',
          value : 0
        },
      ],
      rolesData: [],
      tableData: [],
      tableLoading: false,
      pageLoading: false,
      title: "",
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      //新增
      submitType: "",
      pageData: {},
      gradeList: [],
      lineSite : [],
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      bigProjectList : []
    };
  },
  mounted() {
    this.getData();
    this.getDictList();
    this.queryLineSiteTreeData();
  },
  methods: {
    queryLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    importHandleClose() {
      this.importDialog = false;
      this.getData()
    },
    handleExport(){
      this.$confirm('确定导出危大工程清单库吗', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          pageNum: this.page.pageNo,
          pageSize: this.page.pageSize,
          ...this.queryModal,
        }
        bigProjectExport(params).then((res) => {
          const aLink = document.createElement("a");
          let blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          aLink.href = URL.createObjectURL(blob);
          aLink.download = "危大工程清单库.xlsx";
          aLink.click();
          document.body.appendChild(aLink);
        });
      })
    },
    lineChange(val) {
      console.log(val, "val");
      this.queryModal.siteUuidList = [];
      console.log(this.queryModal.siteUuidList, "siteUuidList");
      if (val.length > 0) {
        for (let item of val) {
          this.queryModal.siteUuidList.push(item[item.length - 1]);
        }
      }
    },
    //dangerBigProjectType 伟大工程类别
    // 字典  unitType 单位类型
    getDictList() {
      this.bigProjectList = [];
      getDictData("dangerBigProjectType").then((res) => {
        if (res.code == 0) {
          this.bigProjectList = res.data;
        }
      });
    },
    // 查询
    search() {
      this.getData();
    },
    // 获取列表数据
    getData() {
      this.tableLoading = true;
      let params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.queryModal,
      };
      riskDangerBigProjectList(params).then((res) => {
        console.log(res);
        this.tableData = res.data.records;
        this.page.total = res.data.total;
        this.tableLoading = false;
      });
    },
    // 新增
    add() {
      this.title = "新增";
      this.submitType = "add";
      this.dialogVisible = true;
      this.pageData = {};
    },
    development(){
      // this.$message.info('正在开发中。。。')
      this.importDialog = true;
    },
    // 编辑
    edit(row) {
      this.title = "编辑";
      this.loadform("edit", row);
    },
    // 查看
    view(row) {
      this.title = "查看";
      this.loadform("detail", row);
    },
    loadform(type, row) {
      this.dialogVisible = true;
      this.submitType = type;
      const rowinfo = { ...row };
      this.pageData = rowinfo;
    },
    // 删除
    del(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        riskDangerBigProjectDelete(row.id)
          .then((res) => {
            if (res.code == 0) {
              this.$message.success("操作成功");
              this.getData();
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((res) => {
            this.$message.error(res.message);
          });
      });
    },

    // 关闭回调
    CloseData() {
      this.dialogVisible = false;
      this.getData();
    },
    cancelModal(){
      this.dialogVisible = false;
    },
    handleClose() {
      this.$refs.addPageData.reset();
      this.dialogVisible = false;
    },
    // 分页
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getData();
    },
    // 级联选择器事件
    handleLineSiteChange(record) {
      console.log(record)
      this.queryModal.workAreaUuidList = [];
      for (let item of record) {
        this.queryModal.workAreaUuidList.push(item[item.length-1]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 11;
}
::v-deep .el-table__body-wrapper {
  overflow: auto;
  max-height: 500px;
}

.page-container {
  .handle-wrapper {
    min-width: 240px;
    max-width: 30vw;
    height: 60vh;
    position: absolute;
    top: 10%;
    left: 20px;
    background: rgba(255, 255, 255, 1);
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
    border-radius: 0px 20px 20px 0px;
    padding: 20px;
    z-index: 6;

    .tree-wrapper {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }

    .toggle-btn {
      width: 28px;
      height: 240px;
      position: absolute;
      right: 0px;
      top: 50%;
      margin-top: -120px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }

    .toggle-btn-close {
      transform: rotate(180deg);
    }

    .toggle-btn-open {
      transform: rotate(0deg);
    }
  }

  .handle-wrapper-hide {
    left: -212px;
    background: transparent;
    box-shadow: none;
  }

  .handle-wrapper-show {
    left: 0;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #43a5e9;
    // box-shadow: 10px 0px 25px 0px #002E75 inset;
  }
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #e6e7ec;
  text-align: center;
}

::v-deep .el-dialog__title {
  font-weight: bold;
}
::v-deep .el-table__fixed-right{
  height: 100% !important;
}
</style>
