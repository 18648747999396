<template>
  <div>
    <!-- 历史操作记录弹窗 -->
    <el-dialog
      title="历史操作记录"
      :visible.sync="historyDialog"
      width="30%"
      :before-close="historyClose"
      center
      append-to-body
      :close-on-click-modal="false"
    >
      <HistoryPage
        @historyClose="historyClose"
        :logList="logList"
      ></HistoryPage>
    </el-dialog>
    <!-- 三防应急检查详情 -->
    <el-dialog
      title="三防应急检查表"
      :visible.sync="approvalDialog"
      append-to-body
      width="50%"
    >
      <inspectApproval
        v-if="approvalDialog"
        :operation="title"
        :editData="editData"
        @cancelApproval="cancelApproval"
        @successApproval="successApproval"
      ></inspectApproval>
    </el-dialog>
    <!-- 三防工作简报详情 -->
    <el-dialog
      width="30%"
      title="详情"
      :visible.sync="detailVisible"
      append-to-body
    >
      <el-form :model="model" label-width="100px">
        <el-form-item label="填报单位：">
          <span>{{ model.supervisionName }}</span>
        </el-form-item>
        <el-form-item label="填报内容：">
          <el-button type="text" @click="handleFilePreview"
            >【三防工作简报】</el-button
          >
        </el-form-item>
        <el-form-item label="审核状态：">
          <span>{{
            model.status == 1 ? "通过" : model.status == 2 ? "驳回" : "待审核"
          }}</span>
        </el-form-item>
        <el-form-item label="审核意见：">{{ model.opinion }}</el-form-item>
      </el-form>
      <FilePreview
        :src="model.fileUrl"
        appendToBody
        ref="filePreviewModal"
      ></FilePreview>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleDetailModalClose"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <!-- 广州市交通运输系统三防工作统计表 -->
    <!-- 施工单位 -->
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="buildWriteDialog"
      width="80%"
      :before-close="buildWriteClose"
      center
      :close-on-click-modal="false"
      append-to-body
    >
      <buildWrite
        @buildWriteClose="buildWriteClose"
        :buildWriteId="buildWriteId"
        :buildWriteResponseId="buildWriteResponseId"
        :demo="demo"
        :sureSubDate="2"
      ></buildWrite>
    </el-dialog>
    <!--  监理审-->
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="supervisoDialog"
      width="80%"
      :before-close="supervisoClose"
      center
      :close-on-click-modal="false"
      append-to-body
    >
      <superviso
        @supervisoClose="supervisoClose"
        :supervisoId="supervisoId"
        :supervisoResponseId="supervisoResponseId"
        :sureSubDate="2"
        :pageType="pageType"
        :supervisionUuid="supervisionUuid"
        :linetes="linetes"
      ></superviso>
    </el-dialog>
    <!--  建管部汇总-->
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="buildDepartmentDialog"
      width="80%"
      :before-close="buildDepartmentClose"
      center
      :close-on-click-modal="false"
      append-to-body
    >
      <buildDepartment
        @buildDepartmentClose="buildDepartmentClose"
        :buildDepartmentId="buildDepartmentId"
        :buildDepartmentResponseId="buildDepartmentResponseId"
        :sureSubDate="2"
      ></buildDepartment>
    </el-dialog>
    <!-- 质安部 -->
    <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="qualityDialog"
      width="80%"
      :before-close="qualityClose"
      center
      :close-on-click-modal="false"
      append-to-body
    >
      <quality
        @qualityClose="qualityClose"
        :qualityResponseId="qualityResponseId"
        :sureSubDate="2"
        :linetes="linetes"
      ></quality>
    </el-dialog>
    <!-- 灾害及恢复情况弹框 -->

    <el-dialog
      :title="titoles"
      v-if="disastersType"
      :visible.sync="disastersType"
      append-to-body
      center
      width="60%"
      :before-close="disastersClose"
    >
      <detailp :listdate="listdate" :stosses="stosses" :listoos="listoos">
      </detailp>
      <!-- <resumeReporting
        @downResume="downResume"
        :responseId="responseId"
        :resumeReportingType="resumeReportingType"
        :reportId="reportId"
      ></resumeReporting> -->
    </el-dialog>
  </div>
</template>

<script>
import { ResLog } from "@/api/responseTem";
import { getBriefingInfo, getlrosses } from "@/api/response";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import HistoryPage from "./historyPage.vue";
import inspectApproval from "../components/inspectApproval";
import FilePreview from "@/components/Uploader/filePreview.vue";
import buildDepartment from "../workStatistics/buildDepartment.vue";
import buildWrite from "../workStatistics/buildWrite.vue";
import superviso from "../workStatistics/superviso.vue";
import quality from "../workStatistics/quality.vue";
import detailp from "../components/disasterosses.vue";
export default {
  props: {
    temList: {},
    linetes: Array,
    pageType: Number,
  },
  components: {
    HistoryPage,
    inspectApproval,
    FilePreview,
    buildWrite,
    buildDepartment,
    superviso,
    quality,
    detailp,
  },
  data() {
    return {
      loading: false,
      historyDialog: false,
      approvalDialog: false,
      detailVisible: false,
      checkDialog: false,
      checkPageLoading: false,
      //  三防工作统计表施工填报
      buildWriteDialog: false,
      buildWriteId: "",
      buildWriteResponseId: "",
      demo: 0,
      //  三防工作统计表监理审核
      supervisoDialog: false,
      supervisoId: "",
      supervisoResponseId: "",
      //  三防工作统计表建管部汇总
      buildDepartmentDialog: false,
      buildDepartmentId: "",
      buildDepartmentResponseId: "",
      //  三防工作统计表质安部
      qualityDialog: false,
      qualityResponseId: "",
      /* 灾害弹框 */
      disastersType: false,
      title: "查看",
      typeoptions: [],
      logList: [],
      editData: [],
      model: {
        opinion: "",
      },
      responseId: "",
      reportId: "",
      stosses: false,
      listoos: false,
      titoles: "",
      statistics: 3,
      supervisionUuid: "",
    };
  },
  created() {
    queryDictByLabel({ label: "三防响应工作报表" }).then((res) => {
      queryDictNodesById({ id: res.data.id }).then((el) => {
        this.typeoptions = el.data;
      });
    });
    const deptIds = [
      "0f262186361639aeb2c65fc704a5c18a",
      "f55322405b8739e0a4f958857063505b",
      "b2b5421f23443f4b9a2857033db35134",
      "c7b4e5841a60384d9f160ba2312b725e",
      "f4d96a013949360ca8c2fcf92975345a",
      "dc0728d7d7ad39eab31a7d53362be1f7",
      "87992ff66f4f398b8a74520337b02946",
    ];
    const userInfo = JSON.parse(localStorage.getItem("user"));
    this.supervisionUuid = userInfo.departmentId;
    if (deptIds.includes(userInfo.departmentId)) {
      // 建管
      this.userType = 0;
      this.statistics = 2;
    } else if (userInfo.departmentId == "e6fd43bcadb33e27aa93d28c2cd4fdf7") {
      // 质安部
      this.userType = 1;
      this.statistics = 3;
    } else if (userInfo.departmentId == "a6af5900b7cf3fbeb47b532063817d33") {
      // 监理
      this.statistics = 1;
    } else if (userInfo.departmentId == "cc2da0417c17325ea8d7d11206a551b4") {
      // 施工
      this.statistics = 0;
    } else {
      this.userType = 2;
    }
  },
  methods: {
    // 历史操作
    async historyHandle(data) {
      let params = {
        incidenceId: data.id,
        operationType: data.reportNameId,
      };
      let res = await ResLog(params);
      if (res.code == 0) {
        console.log(res);
        this.logList = res.data;
        this.historyDialog = true;
      } else {
        this.$message.error("数据出错");
      }
    },
    historyClose() {
      this.historyDialog = false;
    },
    // 查看
    detailHandle(data) {
      if (data.reportNameId == "139") {
        this.title = "查看";
        this.editData = { ...data };
        this.approvalDialog = true;
      } else if (data.reportNameId == "140") {
        this.model = {}
        this.queryBriefingInfo(data);
        this.detailVisible = true;
      } else if (data.reportNameId == "141") {
        if (this.statistics == 0) {
          // 施工
          this.buildWriteDialog = true;
          this.buildWriteId = data.id;
          this.buildWriteResponseId = data.responseId;
          this.demo = 1;
        } else if (this.statistics == 1) {
          // 监理
          this.supervisoDialog = true;
          this.supervisoId = data.id;
          this.supervisoResponseId = data.responseId;
        } else if (this.statistics == 2) {
          // 建管
          this.buildDepartmentDialog = true;
          this.buildDepartmentId = data.id;
          this.buildDepartmentResponseId = data.responseId;
        } else if (this.statistics == 3) {
          // 质安部
          this.qualityDialog = true;
          this.qualityResponseId = data.responseId;
        }
      } else if (data.reportNameId == "347") {
        console.log(data);
        this.titoles = "建设工地灾害损失及恢复情况";
        getlrosses(data.id).then((res) => {
          console.log(res);
          this.listdate = res.data;
          this.disastersType = true;
        });
      } else if (data.reportNameId == "348") {
        this.titoles = "建设工地抢险和灾害损失情况";
        getlrosses(data.id).then((res) => {
          console.log(res);
          this.listdate = res.data;
          this.disastersType = true;
        });
      }
    },
    // 调用详情接口方法
    queryBriefingInfo(record) {
      getBriefingInfo({ id: record.id }).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            const tempArr = res.data.fileUrl.split("/");
            const fileName = tempArr[tempArr.length - 1];
            this.model = Object.assign({}, res.data);
            this.$set(this.model, "fileName", fileName);
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 三防应急检查表详情弹窗关闭
    cancelApproval() {
      this.approvalDialog = false;
    },
    // 三防工作简报详情弹窗关闭
    handleDetailModalClose() {
      this.detailVisible = false;
    },
    // 广州市交通运输系统三防工作统计表详情弹窗关闭
    checkClose() {
      this.checkDialog = false;
    },
    successApproval() {
      this.approvalDialog = false;
      this.loadData();
    },
    qualityClose() {
      this.qualityDialog = false;
    },
    //  三防工作统计表监理审核
    supervisoClose() {
      this.supervisoDialog = false;
    },
    buildDepartmentClose() {
      this.buildDepartmentDialog = false;
    },
    buildWriteClose() {
      this.buildWriteDialog = false;
    },
    // 灾害及恢复情况填报 完成回调
    downResume() {
      this.disastersType = false;
    },
    // 灾害及恢复情况
    disastersClick() {
      this.resumeReportingType = "add";
      this.disastersType = true;
    },
    disastersClose() {
      this.disastersType = false;
    },
    // 文件预览
    handleFilePreview() {
      this.$refs.filePreviewModal.handleOpen();
    },
    sureSub() {
      this.$emit("detailClose");
    },
  },
};
</script>

<style lang="scss" scoped></style>
