<template>
  <div>
    <el-dialog
      width="50%"
      title="风险启动过程"
      class="editDialog details-dialog"
      :visible.sync="dialogRecord"
      v-if="dialogRecord"
      center
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <!-- s -->
      <el-steps :active="2" align-center>
        <el-step title="施工单位申请"></el-step>
        <el-step title="监理单位审核"></el-step>
        <el-step title="建管部审核"></el-step>
        <el-step title="建设公司审核"></el-step>
      </el-steps>
      <div class="step-content">
        <div class="title">
          风险名称：<span>明挖法工程维护结构施工风险</span>
        </div>
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            :type="activity.state == 2 ? 'danger ' : 'primary'"
            color="#3fa5e9"
            :size="'large'"
          >
            <div>
              <i style="font-style:normal;margin-right: 5px;">{{
                activity.created
              }}</i
              ><span
                >{{
                  activity.beforUnitType +
                    "-" +
                    activity.beforeUnitName +
                    "-" +
                    activity.beforeName +
                    "（" +
                    activity.beforeRole +
                    "）-"
                }}{{
                  activity.state == "0"
                    ? "已通过审核"
                    : activity.state == "1"
                    ? "已提交申请"
                    : "已驳回审核"
                }}</span
              >
              <span v-if="activity.beforeRemark">{{
                "（" + activity.beforeRemark + "）"
              }}</span>
              <span v-if="activity.state == 0">{{
                "选定 " +
                  activity.changeUnit +
                  "-" +
                  activity.changeUUnitName +
                  "-" +
                  activity.changeUName +
                  "（" +
                  activity.changeURole +
                  "）为风险包保责任人"
              }}</span>
              <span @click="hanlderViewList(activity)" class="fileDialogBox"
                >附件列表
              </span>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogRecord: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activities: [
        {
          created: "2022-12-08 14:23:00",
          beforUnitType: "建设公司",
          beforeUnitName: "质安部",
          beforeName: "张三",
          beforeRole: "质安负责人",
          state: "0",
          beforeRemark: "测试测试测试测试测试测试测试测试",
          changeUnit: "监理单位",
          changeUnitType: "施工单位",
          changeUUnitName: "质安部",
          changeUName: "李四",
          changeURole: "质安负责人"
        },
        {
          created: "2022-12-08 14:23:00",
          beforUnitType: "建设公司",
          beforeUnitName: "质安部",
          beforeName: "张三",
          beforeRole: "质安负责人",
          state: "1",
          beforeRemark: "测试测试测试测试测试测试测试测试",
          changeUnit: "监理单位",
          changeUnitType: "施工单位",
          changeUUnitName: "质安部",
          changeUName: "李四",
          changeURole: "质安负责人"
        },
        {
          created: "2022-12-08 14:23:00",
          beforUnitType: "建设公司",
          beforeUnitName: "质安部",
          beforeName: "张三",
          beforeRole: "质安负责人",
          state: "0",
          beforeRemark: "测试测试测试测试测试测试测试测试",
          changeUnit: "监理单位",
          changeUnitType: "施工单位",
          changeUUnitName: "质安部",
          changeUName: "李四",
          changeURole: "质安负责人"
        },
        {
          created: "2022-12-08 14:23:00",
          beforUnitType: "建设公司",
          beforeUnitName: "质安部",
          beforeName: "张三",
          beforeRole: "质安负责人",
          state: "2",
          beforeRemark: "测试测试测试测试测试测试测试测试",
          changeUnit: "监理单位",
          changeUnitType: "施工单位",
          changeUUnitName: "质安部",
          changeUName: "李四",
          changeURole: "质安负责人"
        }
      ]
    };
  },
  mounted() {
    console.log(this.dialogVisible, "ddddddddd");
  },
  methods: {
    handleClose() {
      // cc
      this.$emit("closeStartRecord");
    },
    hanlderViewList(obj) {
      this.$emit("showFileList", obj);
      // console.log(obj);
      // this.fileDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.step-content {
  margin-top: 20px;
  padding: 0 20px;
  span {
    margin-left: 10px;
  }
  .title {
    margin-bottom: 20px;
  }
}
.fileDialogBox {
  color: #3fa5e9;
  margin-left: 10px;
  cursor: pointer;
}
</style>
