<template>
  <div class="material-container h-full">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="基本信息">
        <nodeConForm jlView="jl" type="view" :nodeForm="rowData"></nodeConForm>
      </el-tab-pane>

      <el-tab-pane label="节点清单确认" name="节点清单确认">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="120px"
          class="demo-model"
        >
          <el-row>
            <el-col :span="24">
              <h3 class="font-black mb-4">施工单位</h3>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申请人" prop="userName">
                <el-input v-model="confrimApply.userName" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申请时间" prop="operationTime">
                <el-input
                  v-model="confrimApply.operationTime"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <h3 class="font-black mb-4">监理单位</h3>
            </el-col>
            <el-col :span="12">
              <el-form-item label="审核人" prop="userName">
                <el-input
                  v-model="confrimEnd.userName"
                  readonly
                  placeholder="审核人"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="审核时间" prop="operationTime">
                <el-input
                  v-model="confrimEnd.operationTime"
                  readonly
                  placeholder="审核人"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="审核描述" prop="remark">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 6 }"
                  v-model="confrimEnd.remark"
                  resize="none"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="节点验收" name="节点验收">
        <nodeAccForm jlView="jl" :rowData="applyInfo" type="view"></nodeAccForm>
        <nodeAccForm
          v-show="isShow"
          jlView="jl"
          :rowData="AuditInfo"
          type="view"
        ></nodeAccForm>
        <el-form
          :model="AuditForm"
          ref="AuditForm"
          label-width="120px"
          class="demo-model"
        >
          <el-row>
            <el-col :span="24">
              <h3 class="font-black mb-4">监理单位审核</h3>
            </el-col>
            <el-col :span="12">
              <el-form-item label="审核人" prop="userName">
                <el-input
                  v-model="AuditForm.userName"
                  readonly
                  placeholder="审核人"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="审核时间" prop="operationTime">
                <el-input v-model="AuditForm.operationTime" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="审核描述" prop="remark">
                <el-input
                  type="textarea"
                  readonly
                  :autosize="{ minRows: 4, maxRows: 6 }"
                  v-model="AuditForm.remark"
                  resize="none"
                  placeholder="审核描述"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="附件" prop="itemName">
                <ul class="fileGroupBox">
                  <li v-for="(item, i) in AuditForm.fileList" :key="i">
                    <span>{{ item.fileName }}</span>
                    <span @click="handleFileClick(item)">预览</span>
                    <span @click="handleExport(item.fileRealUrl, item.fileName)"
                      >导出</span
                    >
                  </li>
                </ul>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import nodeAccForm from "./nodeAccForm";
import nodeConForm from "./nodeConForm.vue";
import { confirmRecord, download } from "@/api/riskManage";
export default {
  components: {
    nodeAccForm,
    nodeConForm
  },
  props: {
    rowData: {
      type: Object
    }
  },
  data() {
    return {
      activeName: "基本信息",
      // 时间线
      activities: [],
      detailsForm: {},
      params: {
        pageNum: 1,
        pageSize: 10,
        warnUuid: "",
        type: null
      },
      filter: {
        pageSize: 10,
        pageNum: 1,
        nodeUuid: null,
        keyNodesType: 1
      },
      fileDialog: false,
      ruleForm: {},
      labelPosition: "right",
      applyInfo: {},
      AuditInfo: {},
      confrimApply: {},
      confrimEnd: {},
      AuditForm: {},
      isShow: false
    };
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    handleClick(val) {
      console.log(val);
    },
    getDetails() {
      this.filter.nodeUuid = this.rowData.nodeUuid;
      let data = { ...this.filter };
      const _this = this;
      data.keyNodesType = 0;
      confirmRecord(data).then(res => {
        // confrimApply
        // confrimEnd
        var confrimAudit = [];
        if (res.code == 0) {
          res.data.forEach(el => {
            if (el.recordType == 1) {
              _this.confrimApply = el;
            } else {
              if (el.recordType == 3) {
                _this.confrimEnd = el;
                confrimAudit.push(el);
              } else if (el.recordType == 2) {
                _this.confrimEnd = el;
                confrimAudit.push(el);
              }
              confrimAudit.sort(function(a, b) {
                return b.created < a.created ? -1 : 1;
              });
              if (confrimAudit.length > 0) {
                this.confrimEnd = confrimAudit[0];
              }
            }
          });
        }
      });
      confirmRecord(_this.filter).then(res => {
        console.log(res);
        if (res.code == 0) {
          let auditList = [];
          res.data.forEach(el => {
            if (el.recordType == 1) {
              el.state = 4;
              _this.applyInfo = el;
            }
            if (el.recordType == 4) {
              _this.isShow = true;
              el.state = 6;
              _this.AuditInfo = el;
              console.log(_this.AuditInfo, "AuditInfo");
            }
            if (el.recordType == 5) {
              _this.AuditForm = el;
              auditList.push(el);
            } else if (el.recordType == 2) {
              auditList.push(el);
            } else if (el.recordType == 3) {
              auditList.push(el);
            }
            console.log(auditList, "auditList");
            if (auditList.length > 0) {
              auditList.sort(function(a, b) {
                return b.created < a.created ? -1 : 1;
              });
              _this.AuditForm = auditList[0];
              console.log(_this.AuditForm, "AuditForm");
            }
          });
        }
      });
    },
    CloseData() {
      this.$emit("CloseData");
    },
    hanlderViewList(obj) {
      this.$emit("showFileList", obj);
      // console.log(obj);
      // this.fileDialog = true;
    },
    handlefileClose() {
      this.fileDialog = false;
    },
    handleFileClick(record) {
      const link = document.createElement("a");
      link.href = record.fileUrl;
      link.download = "附件";
      link.target = "_blank";
      link.click();
    },
    handleExport(url, name) {
      console.log(url, name, "fffffffffffff");
      let list = [
        { name: "doc", value: "application/msword" },
        {
          name: "docx",
          value:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        },
        { name: "xls", value: "application/vnd.ms-excel" },
        {
          name: "xlsx",
          value:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        { name: "ppt", value: "application/vnd.ms-powerpoint" },
        {
          name: "pptx",
          value:
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        },
        { name: "pdf", value: "application/pdf;chartset=UTF-8" }
      ];
      let fileType = url.split(".")[1];
      let type = null;
      list.forEach(el => {
        if (el.name == fileType) {
          type = el.value;
        }
      });
      download(url).then(res => {
        const aLink = document.createElement("a");
        let blob = null;
        if (fileType == "txt") {
          blob = new Blob([res]);
        } else if (
          fileType == "png" ||
          fileType == "jpg" ||
          fileType == "jpeg"
        ) {
          blob = new Blob([res]);
        } else {
          blob = new Blob([res], { type: type });
        }
        aLink.href = URL.createObjectURL(blob);
        aLink.download = name + "." + fileType;
        aLink.click();
        document.body.appendChild(aLink);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
// ::v-deep .el-dialog__body {
//   height: 460px;
// }
::v-deep .el-image {
  height: 150px;
}
.i {
  position: absolute;
  top: -5px;
  right: -5px;
  color: red;
  font-weight: 600;
}
.url-list {
  position: relative;
  display: inline-block;
}
// .el-dialog__header {
//   padding: 0 !important;
// }
.fileDialogBox {
  color: #3fa5e9;
  margin-left: 10px;
  cursor: pointer;
}
.material-container {
  min-height: 400px;
}
.fileGroupBox {
  span {
    color: #3fa5e9;
    cursor: pointer;
    margin-left: 10px;
  }
  span:first-child {
    color: #606266;
  }
}
</style>
