<template>
  <!--三防工作统计表监理审  -->
  <div
    v-loading="tableLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-descriptions title="" style="width: 100%">
      <el-descriptions-item label="填报单位">{{
        tableForm.tableData[0] ? tableForm.tableData[0].lineSite : ""
      }}</el-descriptions-item>
      <el-descriptions-item label="三防应急响应类型">{{
        tableForm.tableData[0] ? tableForm.tableData[0].responseInfo : ""
      }}</el-descriptions-item>
      <el-descriptions-item label="三防应急响应级别">{{
        tableForm.tableData[0] ? tableForm.tableData[0].responseLevel : ""
      }}</el-descriptions-item>
    </el-descriptions>
    <div class="huizong">
      <el-table
        ref="tableData"
        :data="tableForm.tableData"
        id="tableData"
        border
        style="width: 100%; margin-top: 20px"
        :row-style="{ height: '50px' }"
      >
        <el-table-column
          label="派出工作组(个)从应急响应启动到本次报送时间节点,派出工作组的累计数量"
          align="center"
          prop="workGroup"
        >
        </el-table-column>
        <el-table-column
          prop="position"
          label="检查场所部位(处)从应急响应启动到本次报送时间节点,检查场所部位的累计数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="hiddenDanger"
          label="新排查出三防风险隐患(处)上—次报送时间节点到本次报送时间节点，新排查出的三防风险隐患数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="info"
          label="发送预警或防御信息(条)地铁集团不需要填该项"
          align="center"
        >
          <template>
            {{ "/" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="worker"
          label="预制(出动)抢险人员(人)在本次报送时间节点，能立即调动的抢险人员"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="team"
          label="预制(出动)抢险队伍(支)在本次报送时间节点，能立即调动的抢险队伍数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="预置(出动)抢险设备(台)指在本次报送时间节点，能立即调动设备数量"
          align="center"
        >
          <el-table-column prop="deviceTotal" label="合计" align="center">
          </el-table-column>
          <el-table-column
            prop="vehicle"
            label="车辆能立即调动的抢险汽车、吊车、铲车、履带车等"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="boat"
            label="舟艇能立即调动的舟船、划艇等水上运输工具数量"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="waterPump"
            label="抽水机能立即调动的龙吸水、自吸泵、排污泵、潜水泵、动力站水泵等抽排水设备数量"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="other"
            label="其他能立即调动的、未统计在车辆、舟艇、抽水机范围内的三防抢险设备数量"
            align="center"
          >
          </el-table-column>
        </el-table-column>

        <el-table-column
          prop="remark"
          label="其他应急措施落实及受灾害影响情况指从应急响应启动到本次报送时间节点，集团公司各单位应急措施落实及受灾受影响累计情况"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
    <el-form
      ref="checkForm"
      :rules="rules"
      :model="checkForm"
      v-show="options == '审批'"
    >
      <el-form-item label="审批意见:" prop="rejectReason">
        <el-input type="textarea" v-model="checkForm.rejectReason"></el-input>
      </el-form-item>
    </el-form>
    <div v-show="options == '查看'">
      <el-timeline>
        <el-timeline-item
          v-for="(activity, index) in xyActivities"
          :key="index"
          type="primary"
          color="#3fa5e9"
          :size="'large'"
        >
          <div>
            <i style="font-style:normal;margin-right: 5px;">{{
              activity.operatorTime
            }}</i
            ><span>{{ activity.actionType }}</span>
            <span style="margin-left:10px"
              >操作人: {{ activity.operatorName }}
            </span>
            <span style="margin-left:10px" v-show="activity.operatorInfo"
              >操作意见: {{ activity.operatorInfo }}
            </span>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>

    <div
      style="text-align: center; margin-top: 10px;position: relative; z-index: 111;"
      v-show="options == '查看'"

    >
      <el-button @click="Shutdown">关闭</el-button>
    </div>
    <div
      style="text-align: center; margin-top: 10px"
      v-show="options == '审批'"
    >
      <el-button type="danger" @click="turnDown">驳回</el-button>
      <el-button type="primary" @click="approve">通过</el-button>
    </div>
  </div>
</template>

<script>
import { teamDetails, approveent } from "@/api/response.js";
export default {
  props: {
    options: {},
    approveid: {},
  },
  watch: {
    approveid: {
      handler(val) {
        if (val) {
          this.tableLoading = true;
          this.statisticslist(val.id);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      ster: "",
      tableForm: {
        tableData: [],
      },
      tableLoading: false,
      opera: "",
      operaesr: "",
      checkForm: {
        rejectReason: "",
      },
      xyActivities: [],

      rules: {
        rejectReason: [
          {
            required: true,
            message: "驳回意见必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    //填报查看详情
    statisticslist(val) {
      this.tableForm.tableData = [];
      teamDetails({ id: val }).then((res) => {
        this.tableForm.tableData.push(res.data.item);
        this.xyActivities = res.data.operatingHistoryList;
        this.tableLoading = false;
        if (this.options == "审批") {
          res.data.ops.forEach((list) => {
            if (list.operationText == "通过") {
              this.opera = list.operation;
            } else if (list.operationText == "驳回") {
              this.operaesr = list.operation;
            }
          });
        }
      });
    },
    //关闭
    Shutdown() {
      this.checkForm.rejectReason = "";
      this.$refs.checkForm.clearValidate();
      this.$emit("rescueCheckClose");
    },
    turnDown() {
      this.$refs.checkForm.validate((valid) => {
        if (valid) {
          this.tableLoading = true;
          approveent({
            data: this.checkForm.rejectReason,
            id: this.approveid.id,
            operation: this.operaesr,
          })
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                });
                this.tableLoading = false;
                this.rejectDialog = false;
                this.Shutdown();
              } else {
                this.tableLoading = false;
                this.$message.warning("操作失败");
              }
            })
            .catch((err) => {
              this.tableLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    approve() {
      this.tableLoading = true;
      approveent({
        data: this.checkForm.rejectReason,
        id: this.approveid.id,
        operation: this.opera,
      })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.tableLoading = false;
            this.Shutdown();
            // this.statisticslist(approveid.id);
          } else {
            this.$message.error("操作失败");
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table th > .cell {
  font-size: 12px !important;
}
::v-deep .el-table--border {
  border: none;
}
.huizong {
  ::v-deep .el-table {
    height: 320px !important;
    overflow: auto;
  }
  ::v-deep .el-table--border,
  .el-table::before {
    border-bottom: 0 !important;
    height: 0px;
  }
  .el-table--border::after {
    width: 0;
  }
}
::v-deep .corner-wrap {
  display: none !important;
}
::v-deep .el-table:before {
  height: 0px;
}

::v-deep .el-table {
  background-color: #081742 !important;
}
::v-deep .el-table--border {
  border: none;
}
::v-deep .el-table tr {
  background: #081742 !important;
}
::v-deep .el-table__cell.is-leaf {
  background: #081742 !important;
}
::v-deep .el-table thead.is-group th.el-table__cell {
  background: #081742 !important;
}
::v-deep .el-table--border th.el-table__cell {
  border: 1px solid #081742 !important;
}
::v-deep .el-table::before,
.el-table--border::after {
  background-color: #143363 !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: rgba(0, 46, 117, 0.4);
}
::v-deep .el-table th.is-leaf,
::v-deep .el-table--border td {
  border: 1px solid #143363 !important;
}
::v-deep.el-timeline {
  margin-left: 5px;
  margin-top: 20px;
  background: #081742 !important;
  .el-timeline-item__content {
    color: #ffffff;
  }
}
.info-item {
  color: #ffffff;
}
::v-deep .el-descriptions-row {
  background-color: #081742 !important;
  color: #fff;
}
</style>
