import { httpInit, baseURL } from "./http";
let http = httpInit("/gzdt-upload");

export const upload = (file, onUploadProgress) => {
  let form = new FormData();
  form.append("upfile", file);
  return http.post(`/api/upload/0`, form, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress
  });
};
// 上传封面等 会生成压缩图片
export const uploadThumb = (file, onUploadProgress) => {
  let form = new FormData();
  form.append("upfile", file);
  return http.post(`/api/upload/1`, form, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress
  });
};
export const uploadConfig = {
  action: baseURL + "/gzdt-upload" + "/api/upload/0",
  name: "upfile",
  headers: {
    "Content-Type": "multipart/form-data"
  }
};
// 上传封面等 会生成压缩图片
export const uploadThumbConfig = {
  action: baseURL + "/gzdt-upload" + "/api/upload/1",
  name: "upfile",
  headers: {
    "Content-Type": "multipart/form-data"
  }
};
