<template>
  <div class="material-container h-full">
    <el-tabs v-model="radio" @tab-click="radioChange">
      <el-tab-pane
        v-for="item in radioData"
        :key="item.value"
        :label="item.label"
        :name="item.value"
      >
        <el-form
          ref="libraryForm"
          :inline="true"
          :model="libraryForm"
          class="demo-form-inline"
        >
          <el-form-item prop="startDate">
            <el-date-picker
              clearable
              v-model="libraryForm.startDate"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              :append-to-body="false"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item prop="state">
            <el-select
              clearable
              v-model="libraryForm.state"
              placeholder="选择状态"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in stateData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="radio == 1 || radio == 2" prop="companyName">
            <el-input
              v-model="libraryForm.companyName"
              clearable
              placeholder="输入单位名称/部门"
            ></el-input>
          </el-form-item>
          <el-form-item v-show="radio == 4" prop="companyUnit">
            <el-select
              clearable
              v-model="libraryForm.companyUnit"
              placeholder="选择单位类型"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in companyTypeData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="siteIds" v-show="radio == 4">
            <el-cascader
              ref="treeSree"
              filterable
              clearable
              v-model="libraryForm.siteIds"
              placeholder="选择工点"
              :props="props"
              :options="treeData"
              :show-all-levels="false"
              @change="handleLineSiteChange"
              :popper-append-to-body="false"
            ></el-cascader>
          </el-form-item>
          <el-form-item prop="emergency">
            <el-select
              clearable
              v-model="libraryForm.emergency"
              placeholder="选择值班类型"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in emergencyData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="libraryQuery">查询</el-button>
          </el-form-item>
        </el-form>

        <div class="tablese">
          <el-table
            :data="tableData"
            style="width: 100%"
            border
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          >
            <el-table-column
              type="index"
              label="序号"
              width="60"
              align="center"
            ></el-table-column>
            <el-table-column
              v-for="item in columns"
              :key="item.prop"
              :prop="item.prop"
              :label="item.label"
              :min-width="item.minWidth"
              show-overflow-tooltip
              align="center"
            >
              <template slot-scope="scope">
                <div v-if="item.prop == 'companyType'">
                  <span v-if="scope.row.companyType == 'build'"> 建设单位</span>
                  <span v-if="scope.row.companyType == 'monitor'"
                    >监理单位</span
                  >
                  <span v-if="scope.row.companyType == 'construct'"
                    >施工单位</span
                  >
                  <span v-if="scope.row.companyType == 'current'"
                    >一般单位</span
                  >
                  <span v-if="scope.row.companyType == 'design'">设计单位</span>
                  <span v-if="scope.row.companyType == 'third'"
                    >第三方监测单位</span
                  >
                  <span v-if="scope.row.companyType == 'survey'">勘察单位</span>
                  <span v-if="scope.row.companyType == 'contracting'"
                    >施工总承包单位</span
                  >
                </div>
                <div v-else>
                  {{ scope.row[item.prop] }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="state" align="center" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.state == false">未到岗</span>
                <span v-if="scope.row.state == true">到岗</span>
              </template>
            </el-table-column>
            <el-table-column prop="emergency" align="center" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.emergency == 1">应急值班</span>
                <span v-if="scope.row.emergency == 0">日常值班</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="flex justify-center mt-4">
          <el-pagination
            background
            :page-size="libraryForm.size"
            @current-change="handleCurrentChange"
            :current-page.sync="libraryForm.page"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getEresList } from "@/api/eres";
import dayjs from "dayjs";
export default {
  data() {
    return {
      radio: "1",
      radioData: [
        {
          label: "总承包单位",
          value: "1",
        },
        {
          label: "建设单位",
          value: "2",
        },
        {
          label: "专职应急救援队",
          value: "3",
        },
        {
          label: "工点值班人员",
          value: "4",
        },
      ],
      libraryForm: {
        page: 1,
        size: 5,
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: "",
        state: null,
        companyUnit: "",
        siteId: null,
        companyName: "",
        emergency: 0,
      },
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "id",
      },
      loading: false,
      stateData: [
        {
          label: "到岗",
          value: 1,
        },
        {
          label: "未到岗",
          value: 0,
        },
      ],
      emergencyData: [
        {
          label: "应急值班",
          value: 1,
        },
        {
          label: "日常值班",
          value: 0,
        },
      ],
      companyTypeData: [
        {
          label: "建设单位",
          value: "build",
        },
        {
          label: "监理单位",
          value: "monitor",
        },
        {
          label: "施工单位",
          value: "construct",
        },
        {
          label: "一般单位",
          value: "current",
        },
        {
          label: "设计单位",
          value: "design",
        },
        {
          label: "第三方监测单位",
          value: "third",
        },
        {
          label: "勘察单位",
          value: "survey",
        },
        {
          label: "施工总承包单位",
          value: "contracting",
        },
      ],
      columns: [],
      total: 0,
      tableData: [
        {
          lineName: "姓名",
          siteName: "xxx职位",
          siteNames: "xxx职位",
          vownerName: "部门",
          tell: "13834566543",
        },
      ],
    };
  },
  created() {
    this.getTable();
  },
  mounted() {
    this.radio = "1";
    this.getListDatas();
    this.getLineSiteTreeData();
  },
  methods: {
    getListDatas() {
      this.loading = true;
      let params = {
        startDate: this.libraryForm.startDate,
        endDate: this.libraryForm.startDate,
        state: this.libraryForm.state,
        emergency: this.libraryForm.emergency,
        companyUnit: this.libraryForm.companyUnit,
        siteId: this.libraryForm.siteId,
        rotaType: this.radio,
        companyName: this.libraryForm.companyName,
        page: this.libraryForm.page,
        size: this.libraryForm.size,
      };
      getEresList(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          this.total = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },

    getListData(rotaType) {
      this.loading = true;
      let params = {
        startDate: this.libraryForm.startDate,
        endDate: this.libraryForm.startDate,
        state: this.libraryForm.state,
        emergency: this.libraryForm.emergency,
        companyUnit: this.libraryForm.companyUnit,
        siteId: this.libraryForm.siteId,
        rotaType: rotaType,
        companyName: this.libraryForm.companyName,
        page: this.libraryForm.page,
        size: this.libraryForm.size,
      };
      getEresList(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          this.total = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    handleLineSiteChange(val) {
      if (val && val.length) {
        this.libraryForm.siteId = val.slice(-1)[0];
        this.libraryForm.siteIds = val;
      } else {
        this.libraryForm.siteIds = [];
        this.libraryForm.siteId = null;
      }
    },
    getTable() {
      this.columns = [
        { prop: "companyName", label: "单位名称", minWidth: 80 },
        { prop: "rotaName", label: "值班人员", minWidth: 80 },
        { prop: "mobile", label: "联系电话", minWidth: 80 },
      ];
    },
    libraryCls() {
      this.$emit("libraryCls");
    },
    // 查询
    libraryQuery() {
      this.getListData(this.radio);
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.libraryForm.page = val;
      this.getListData(this.radio);
    },
    yuyin(val) {
      console.log(val);
    },
    shipin(val) {
      console.log(val);
    },
    duanxian(val) {
      console.log(val);
    },
    // 切换
    radioChange(tab, event) {
      console.log(tab, tab.index, event);
      if (tab.name == "1") {
        this.radio = "1";
        this.columns = [
          { prop: "companyName", label: "单位名称", minWidth: 80 },
          { prop: "rotaName", label: "值班人员", minWidth: 80 },
          { prop: "mobile", label: "联系电话", minWidth: 80 },
        ];
        this.getListData(tab.name);
        console.log(this.$refs.libraryForm);
        this.$refs.libraryForm[0].resetFields();
      } else if (tab.name == "2") {
        this.radio = "2";
        this.columns = [
          { prop: "companyName", label: "部门", minWidth: 80 },
          { prop: "rotaName", label: "值班人员", minWidth: 80 },
          { prop: "mobile", label: "联系电话", minWidth: 80 },
        ];
        this.getListData(tab.name);
        this.$refs.libraryForm[1].resetFields();
      } else if (tab.name == "3") {
        this.radio = "3";
        this.columns = [
          { prop: "companyName", label: "应急救援队", minWidth: 80 },
          { prop: "rotaName", label: "值班人员", minWidth: 80 },
          { prop: "mobile", label: "联系电话", minWidth: 80 },
        ];
        this.getListData(tab.name);
        this.$refs.libraryForm[2].resetFields();
      } else if (tab.name == "4") {
        this.radio = "4";
        this.columns = [
          { prop: "siteName", label: "工点", minWidth: 80 },
          { prop: "companyType", label: "单位类型", minWidth: 80 },
          { prop: "companyName", label: "单位名称", minWidth: 120 },
          { prop: "rotaName", label: "值班人员", minWidth: 80 },
          { prop: "mobile", label: "联系电话", minWidth: 80 },
        ];
        this.getListData(tab.name);
        this.$refs.libraryForm[3].resetFields();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
  color: #fff !important;
}
::v-deep .el-tabs__item.is-active {
  color: #409eff !important;
}
::v-deep .el-input--prefix .el-input__inner {
  color: #fff;
  background-color: #18467f;
  border: 0px solid #dcdfe6;
}
::v-deep .el-input__inner {
  color: #fff;
  background-color: #18467f;
  border: 0px solid #dcdfe6;
}
// ::v-deep .el-select-dropdown__wrap {
//   background-color: #18467f;
// }
// ::v-deep .el-select-dropdown {
//   border: 0px;
//   border-radius: 5px;
// }
// ::v-deep .hover {
//   background-color: #0b3c77;
// }
// ::v-deep .el-select-dropdown__item.selected {
//   color: #409eff !important;
// }
// ::v-deep .el-select-dropdown__item {
//   color: #fff;
// }
// ::v-deep .el-popper[x-placement^="bottom"] .popper__arrow {
//   border-bottom-color: #18467f;
// }
// ::v-deep .el-popper[x-placement^="bottom"] .popper__arrow::after {
//   border-bottom-color: #18467f;
// }
// ::v-deep .el-popper[x-placement^="bottom"] {
//   background-color: #18467f;
//   border: 0px;
//   color: #fff;
// }
// ::v-deep .el-date-table > tbody > tr > th {
//   color: #fff;
// }
// ::v-deep .el-date-picker__header-label {
//   color: #fff;
// }
// ::v-deep .el-picker-panel__icon-btn {
//   color: #fff;
// }
</style>
