<template>
  <div class="briefing">
    <el-dialog
      type="text"
      :title="title"
      :visible.sync="breVisible"
      width="60%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :append-to-body="true"
      center
      class="abow_dialog"
    >
      <div class="briefong_auto">
        <!-- 一、水雨晴的台风情况 -->
        <div class="briefong_one">
          <div class="typhoon">一、水雨情的台风情况</div>
          <el-form
            :model="briefing.weatherOrOnDuty"
            ref="numberValidateForm"
            class="demo-ruleForm"
            :disabled="readState"
          >
            <el-row>
              <el-col :span="24">
                <el-form-item label="广州市是否发布雨晴或台风预警信号">
                  <el-select
                    v-model="briefing.weatherOrOnDuty.warnFlag"
                    size="mini"
                    style="width: 100px"
                    :disabled="readState"
                  >
                    <el-option label="有" :value="true" :key="true"></el-option>
                    <el-option
                      label="无"
                      :value="false"
                      :key="false"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="项目所在行政区是否发布雨晴或台风预警信号">
                  <el-select
                    v-model="briefing.weatherOrOnDuty.regionWarnFlag"
                    size="mini"
                    style="width: 100px"
                    :disabled="readState"
                  >
                    <el-option label="有" :value="true" :key="true"></el-option>
                    <el-option
                      label="无"
                      :value="false"
                      :key="false"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item>
                  <el-input
                    v-if="briefing.weatherOrOnDuty.regionWarnFlag"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    placeholder="请输入内容"
                    v-model="briefing.weatherOrOnDuty.regionWarnDescription"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="是否雨晴期间">
                  <el-select
                    v-model="briefing.weatherOrOnDuty.rainFlag"
                    size="mini"
                    style="width: 100px"
                    :disabled="readState"
                  >
                    <el-option label="是" :value="true" :key="true"></el-option>
                    <el-option
                      label="否"
                      :value="false"
                      :key="false"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="平均降雨量">
                  <el-input
                    size="mini"
                    style="width: 100px"
                    v-model="briefing.weatherOrOnDuty.avgRainfall"
                    placeholder="请输入内容"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="最大降雨量">
                  <el-input
                    size="mini"
                    style="width: 100px"
                    v-model="briefing.weatherOrOnDuty.maxRainfall"
                    placeholder="请输入内容"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="是否台风期间">
                  <el-select
                    v-model="briefing.weatherOrOnDuty.windFlag"
                    size="mini"
                    style="width: 100px"
                    :disabled="readState"
                  >
                    <el-option label="是" :value="true" :key="true"></el-option>
                    <el-option
                      label="否"
                      :value="false"
                      :key="false"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="台风名">
                  <el-input
                    size="mini"
                    style="width: 100px"
                    v-model="briefing.weatherOrOnDuty.windName"
                    placeholder="请输入内容"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="台风等级">
                  <el-input
                    size="mini"
                    style="width: 80px"
                    v-model="briefing.weatherOrOnDuty.windLevel"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="最大风速">
                  <el-input
                    size="mini"
                    style="width: 80px"
                    v-model="briefing.weatherOrOnDuty.maxWindSpeed"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <el-divider></el-divider>
        <!-- 二、防御情况 -->
        <div class="briefong_one">
          <div class="defense">二、防御情况</div>
          <el-form
            :model="briefing.defense.defense"
            ref="ValidateForm"
            class="demo-ruleForm"
            :disabled="true"
          >
            <el-row>
              <el-col :span="10">
                <el-form-item label="应急响应启动时间">
                  <el-date-picker
                    size="mini"
                    style="width: 180px"
                    v-model="briefing.defense.startTime"
                    type="datetime"
                    placeholder="选择时间"
                    value-format="yyyy-MM-DD HH:mm:ss"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="类型">
                  <el-select
                    v-model="briefing.defense.responseType.id"
                    size="mini"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in typeList"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="级别">
                  <el-select
                    v-model="briefing.defense.responseLevel.id"
                    size="mini"
                    style="width: 100px"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in leveloptions"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="是否升级或降级">
                  <el-select
                    v-model="briefing.defense.relegationFlag"
                    size="mini"
                    style="width: 100px"
                    :disabled="readState"
                  >
                    <el-option label="是" :value="true" :key="true"></el-option>
                    <el-option
                      label="否"
                      :value="false"
                      :key="false"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="地铁集团、建设公司领导检查">
                  <el-select
                    v-model="briefing.defense.leaderCheckFlag"
                    size="mini"
                    style="width: 100px"
                    :disabled="readState"
                  >
                    <el-option label="有" :value="true" :key="true"></el-option>
                    <el-option
                      label="无"
                      :value="false"
                      :key="false"
                    ></el-option>
                  </el-select>
                  <div class="content" v-if="briefing.defense.leaderCheckFlag">
                    <!-- <el-form
                      :inline="true"
                      :model="briefing.defense.leaderCheckInfo"
                      class="demo-form-inline"
                    >
                      <el-form-item
                        label="检查人员"
                        style="margin-top: 8px; margin-left: 4px"
                      >
                        <el-input
                          v-model="
                            briefing.defense.leaderCheckInfo.checkPeoples
                          "
                          :disabled="true"
                          @input="theOneChange($event)"
                          size="mini"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="检查时间"
                        style="margin-top: 8px; margin-left: 4px"
                      >
                        <el-date-picker
                          size="mini"
                          style="width: 180px"
                          v-model="briefing.defense.leaderCheckInfo.checkTime"
                          @input="theOneChange($event)"
                          type="datetime"
                          placeholder="选择时间"
                          value-format="yyyy-MM-DD HH:mm:ss"
                          :disabled="true"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-form> -->
                    <div class="practicable" style="margin-left: 4px">
                      检查内容及整改落实情况
                    </div>

                    <el-table
                      :data="briefing.defense.leaderCheckInfo.listData"
                      border
                      style="width: 99%; margin-left: 4px; margin-top: 8px"
                    >
                      <el-table-column prop="name" label="排查出问题数量">
                        <template slot-scope="scope">
                          <el-input
                            v-model="scope.row.hiddenDangerCount"
                            :disabled="true"
                          >
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="address" label="已整改问题数量">
                        <template slot-scope="scope">
                          <el-input
                            v-model="scope.row.rectificationCount"
                            :disabled="true"
                          >
                          </el-input>
                        </template>
                      </el-table-column>
                    </el-table>

                    <div class="practicable" style="margin-left: 4px">
                      隐患问题及照片
                    </div>
                    <el-table
                      :data="
                        briefing.defense.leaderCheckInfo
                          .threeCheckItemQuestionList
                      "
                      border
                      canter
                      class="mb-2"
                      style="width: 99%; margin-left: 4px; margin-top: 8px"
                    >
                      <el-table-column prop="checkPeople" label="检查人员">
                        <template slot-scope="scope">
                          <el-input
                            v-model="scope.row.checkPeople"
                            :disabled="true"
                          >
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="checkTime" label="检查时间">
                        <template slot-scope="scope">
                          <el-input
                            v-model="scope.row.checkTime"
                            :disabled="true"
                          >
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="correctiveApproveStatus"
                        label="整改状态"
                      >
                        <template slot-scope="scope">
                          <el-input
                            v-model="types[scope.row.correctiveApproveStatus]"
                            :disabled="true"
                          >
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="hiddenQuestion" label="检查问题">
                        <template slot-scope="scope">
                          <el-input
                            v-model="scope.row.hiddenQuestion"
                            :disabled="true"
                          >
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="uri" label="检查照片">
                        <template slot-scope="scope">
                          <!-- <span>{{ scope.row.uri }}</span> -->
                          <el-image
                            style="width: 50px; height: 50px"
                            :src="scope.row.uri"
                            :preview-src-list="[scope.row.uri]"
                          >
                          </el-image>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="是否与外单位(街道、运营单位等)联合检查">
                  <el-select
                    v-model="briefing.defense.externalUnitCheckFlag"
                    size="mini"
                    style="width: 100px"
                    :disabled="readState"
                  >
                    <el-option label="有" :value="true" :key="true"></el-option>
                    <el-option
                      label="无"
                      :value="false"
                      :key="false"
                    ></el-option>
                  </el-select>
                  <div
                    class="content"
                    v-if="briefing.defense.externalUnitCheckFlag"
                  >
                    <!-- <el-form
                      :inline="true"
                      :model="briefing.defense.externalUnitCheckInfo"
                      class="demo-form-inline"
                      :disabled="readState"
                    >
                      <el-form-item
                        label="检查人员"
                        style="margin-top: 8px; margin-left: 4px"
                      >
                        <el-input
                          v-model="
                            briefing.defense.externalUnitCheckInfo.checkPeoples
                          "
                          :disabled="true"
                          @input="theOneChange($event)"
                          size="mini"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="检查时间"
                        style="margin-top: 8px; margin-left: 4px"
                      >
                        <el-date-picker
                          size="mini"
                          style="width: 180px"
                          v-model="
                            briefing.defense.externalUnitCheckInfo.checkTime
                          "
                          @input="theOneChange($event)"
                          type="datetime"
                          placeholder="选择时间"
                          value-format="yyyy-MM-DD HH:mm:ss"
                          :disabled="true"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-form> -->
                    <div class="practicable" style="margin-left: 4px">
                      检查内容及整改落实情况
                    </div>

                    <el-table
                      :data="briefing.defense.externalUnitCheckInfo.listData"
                      border
                      style="width: 99%; margin-left: 4px; margin-top: 8px"
                    >
                      <el-table-column prop="name" label="排查出问题数量">
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.hiddenDangerCount">
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="address" label="已整改问题数量">
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.rectificationCount">
                          </el-input>
                        </template>
                      </el-table-column>
                    </el-table>

                    <div class="practicable" style="margin-left: 4px">
                      隐患问题及照片
                    </div>
                    <el-table
                      :data="
                        briefing.defense.externalUnitCheckInfo
                          .threeCheckItemQuestionList
                      "
                      border
                      canter
                      class="mb-2"
                      style="width: 99%; margin-left: 4px; margin-top: 8px"
                    >
                      <el-table-column prop="checkPeople" label="检查人员">
                        <template slot-scope="scope">
                          <el-input
                            v-model="scope.row.checkPeople"
                            :disabled="true"
                          >
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="checkTime" label="检查时间">
                        <template slot-scope="scope">
                          <el-input
                            v-model="scope.row.checkTime"
                            :disabled="true"
                          >
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="correctiveApproveStatus"
                        label="整改状态"
                      >
                        <template slot-scope="scope">
                          <el-input
                            v-model="types[scope.row.correctiveApproveStatus]"
                            :disabled="true"
                          >
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="hiddenQuestion" label="检查问题">
                        <template slot-scope="scope">
                          <el-input
                            v-model="scope.row.hiddenQuestion"
                            :disabled="true"
                          >
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="uri" label="检查照片">
                        <template slot-scope="scope">
                          <!-- <span>{{ scope.row.uri }}</span> -->
                          <el-image
                            style="width: 50px; height: 50px"
                            :src="scope.row.uri"
                            :preview-src-list="[scope.row.uri]"
                          >
                          </el-image>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="check">本项目开展的检查情况</div>
          <div class="content">
            <div
              v-for="(rew, index) in briefing.defense
                .threeCheckItemQuestionList"
              :key="index"
            >
              <!-- <el-form
                :inline="true"
                :model="rew"
                class="demo-form-inline"
                :disabled="true"
              >
                <el-form-item
                  label="检查人员"
                  style="margin-top: 8px; margin-left: 4px"
                >
                  <el-input
                    v-model="rew.checkPeoples"
                    @input="theOneChange($event)"
                    size="mini"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="检查时间"
                  style="margin-top: 8px; margin-left: 4px"
                >
                  <el-date-picker
                    size="mini"
                    style="width: 180px"
                    v-model="rew.checkTime"
                    @input="theOneChange($event)"
                    type="datetime"
                    placeholder="选择时间"
                    value-format="yyyy-MM-DD HH:mm:ss"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-form> -->
              <div class="practicable" style="margin-left: 4px">
                检查内容及整改落实情况
              </div>

              <el-table
                :data="[rew]"
                border
                style="width: 99%; margin-left: 4px; margin-top: 8px"
              >
                <el-table-column prop="positionCount" label="检查场所部位">
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.positionCount"
                      :disabled="true"
                    >
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="hiddenDangerCount"
                  label="排查出问题数量"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.hiddenDangerCount"
                      :disabled="true"
                    >
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="rectificationCount"
                  label="已整改问题数量"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.rectificationCount"
                      :disabled="true"
                    >
                    </el-input>
                  </template>
                </el-table-column>
              </el-table>
              <div class="practicable" style="margin-left: 4px">
                隐患问题及照片
              </div>
              <el-table
                :data="rew.threeCheckItemQuestionList"
                border
                canter
                class="mb-2"
                style="width: 99%; margin-left: 4px; margin-top: 8px"
              >
                <el-table-column prop="checkPeople" label="检查人员">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.checkPeople" :disabled="true">
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="checkTime" label="检查时间">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.checkTime" :disabled="true">
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="correctiveApproveStatus"
                  label="整改状态"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="types[scope.row.correctiveApproveStatus]"
                      :disabled="true"
                    >
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="hiddenQuestion" label="检查问题">
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.hiddenQuestion"
                      :disabled="true"
                    >
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="uri" label="检查照片">
                  <template slot-scope="scope">
                    <!-- <span>{{ scope.row.uri }}</span> -->
                    <el-image
                      style="width: 50px; height: 50px"
                      :src="scope.row.uri"
                      :preview-src-list="[scope.row.uri]"
                    >
                    </el-image>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="emergency" style="margin-top: 12px">
            应急人员、设备物资准备情况
          </div>
          <div class="poert" style="margin-top: 12px">
            <div class="topwe">
              <el-form
                :model="briefing.defense"
                ref="dateForm"
                class="ruleForm"
                :disabled="readState"
              >
                <div
                  class="emer"
                  v-for="(item, index) in briefing.defense.reportOnDutyInfoList"
                  :key="index"
                >
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="应急人数">
                        <el-input
                          size="mini"
                          style="width: 100px"
                          v-model="item.count"
                          placeholder="请输入内容"
                          @input="theOneChange($event)"
                        ></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :span="6">
                      <el-form-item>
                        <el-input
                          size="mini"
                          style="width: 150px"
                          v-model="item.name"
                          placeholder="请输入值守地点"
                          @input="theOneChange($event)"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <i
                    v-if="!readState"
                    class="el-icon-circle-plus-outline cursor-pointer"
                    @click="mergencyadd"
                  ></i>
                  <i
                    class="el-icon-delete cursor-pointer cursor-pointer"
                    @click="mergencyremov(item)"
                    v-if="!readState"
                  ></i>
                </div>
              </el-form>
            </div>
          </div>
          <div class="poert" style="margin-top: 12px">
            <div class="topwe">
              <el-form
                :model="briefing.defense.reportMaterialInfoList"
                ref="dateForm"
                class="ruleForm"
                :disabled="readState"
              >
                <div
                  class="emer"
                  v-for="(item, index) in briefing.defense
                    .reportMaterialInfoList"
                  :key="index"
                >
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="物资设备">
                        <el-select
                          v-model="item.materialId"
                          size="mini"
                          style="width: 100px"
                          clearable
                          filterable
                          @input="theOneChange($event)"
                        >
                          <el-option
                            v-for="it in equipment"
                            :key="it.id"
                            :label="it.label + '(' + it.model + ')'"
                            :value="it.id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <el-col :span="6">
                      <el-form-item>
                        <el-input
                          size="mini"
                          style="width: 150px"
                          v-model="item.count"
                          placeholder="输入数量"
                          @input="theOneChange($event)"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item>
                        <el-input
                          size="mini"
                          style="width: 150px"
                          v-model="item.address"
                          placeholder="输入地址"
                          @input="theOneChange($event)"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <i
                    v-if="!readState"
                    class="el-icon-circle-plus-outline supplie cursor-pointer"
                    @click="suppliesadd"
                  ></i>
                  <i
                    v-if="!readState"
                    class="el-icon-delete suppiet cursor-pointer"
                    @click="suppliesremove(item)"
                  ></i>
                </div>
              </el-form>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <!-- 三、受影响情况 -->
        <div class="briefong_one">
          <div class="defense">三、受影响情况</div>
          <el-radio-group
            v-model="briefing.affected.affectedFlag"
            :disabled="readState"
          >
            <el-radio :label="false" style="margin-top: 12px"
              >本次{{ briefing.defense.responseType.label
              }}{{
                briefing.defense.responseLevel.label
              }}级应急响应期间，本单位所辖项目未受到影响，无突发事件发生，总体安全可控。</el-radio
            >
            <el-radio :label="true" style="margin-top: 12px"
              >填写受影响情况</el-radio
            >
          </el-radio-group>
          <div class="defenserty" v-if="briefing.affected.affectedFlag">
            <div class="water" style="margin-top: 12px">
              施工项目是否积水
              <el-select
                v-model="briefing.affected.sitePondingFlag"
                size="mini"
                style="width: 100px"
                @change="watererd"
                :disabled="readState"
              >
                <el-option label="有" :value="true" :key="true"></el-option>
                <el-option label="无" :value="false" :key="false"></el-option>
              </el-select>
              <i
                v-if="briefing.affected.sitePondingFlag && !readState"
                class="el-icon-circle-plus-outline waeeter cursor-pointer"
                @click="wateradd"
              ></i>
            </div>
            <div v-if="briefing.affected.sitePondingFlag">
              <div
                class="conten_wer"
                v-for="(item, index) in briefing.affected.sitePondingList"
                :key="index + 2"
              >
                <div class="content">
                  <el-form
                    :model="item"
                    class="demo-form-inline"
                    :disabled="readState"
                  >
                    <el-row>
                      <el-col :span="8">
                        <el-form-item
                          label="积水具体地点"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            v-model="item.address"
                            size="mini"
                            style="width: 100px"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="最大积水深度"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            v-model="item.value"
                            size="mini"
                            style="width: 100px"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item
                          label=" 积水情况及影响"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder="请输入内容"
                            v-model="item.description"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item
                          label=" 采取的应急措施及处理结果"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder="请输入内容"
                            v-model="item.result"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>

                  <i
                    class="el-icon-delete water cursor-pointer"
                    v-if="briefing.affected.sitePondingFlag && !readState"
                    @click="waterremove(item)"
                  ></i>
                </div>
              </div>
            </div>
            <div class="water" style="margin-top: 12px">
              施工项目是否受台风影响
              <el-select
                v-model="briefing.affected.typhoonFlag"
                size="mini"
                style="width: 100px"
                @change="influenceed"
                :disabled="readState"
              >
                <el-option label="有" :value="true" :key="true"></el-option>
                <el-option label="无" :value="false" :key="false"></el-option>
              </el-select>
              <i
                v-if="briefing.affected.typhoonFlag && !readState"
                class="el-icon-circle-plus-outline waeeter cursor-pointer"
                @click="influenceadd"
              ></i>
            </div>
            <div>
              <div
                class="conten_wer"
                v-for="(item, index) in briefing.affected.typhoonList"
                :key="index + 3"
              >
                <div class="content" v-if="briefing.affected.typhoonFlag">
                  <el-form
                    :model="briefing.affected.typhoonList"
                    class="demo-form-inline"
                    :disabled="readState"
                  >
                    <el-row>
                      <el-col :span="8">
                        <el-form-item
                          label="台风具体地点"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            v-model="item.address"
                            size="mini"
                            style="width: 100px"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="最大风速"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            v-model="item.value"
                            size="mini"
                            style="width: 100px"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item
                          label=" 台风情况及影响"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder="请输入内容"
                            v-model="item.description"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item
                          label=" 采取的应急措施及处理结果"
                          style="margin-top: 8px; margin-left: 4px"
                        >
                          <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder="请输入内容"
                            v-model="item.result"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                  <i
                    class="el-icon-delete influen cursor-pointer"
                    v-if="briefing.affected.typhoonFlag && !readState"
                    @click="influenceremove(item)"
                  ></i>
                </div>
              </div>
            </div>
            <div class="water" style="margin-top: 12px">
              是否有其他原因引起的突发事件
              <el-select
                v-model="briefing.affected.otherEffectFlag"
                size="mini"
                style="width: 100px"
                @change="sudeed"
                :disabled="readState"
              >
                <el-option label="有" :value="true" :key="true"></el-option>
                <el-option label="无" :value="false" :key="false"></el-option>
              </el-select>
              <i
                v-if="briefing.affected.otherEffectFlag && !readState"
                class="el-icon-circle-plus-outline suddend cursor-pointer"
                @click="suddenadd"
              ></i>
            </div>
            <div
              class="conten_wer"
              v-for="(item, index) in briefing.affected.otherEffectList"
              :key="index + 4"
            >
              <div class="content" v-if="briefing.affected.otherEffectFlag">
                <el-form
                  :model="briefing.affected.otherEffectList"
                  class="demo-form-inline"
                  :disabled="readState"
                >
                  <el-row>
                    <el-col :span="8">
                      <el-form-item
                        label="事件具体地点"
                        style="margin-top: 8px; margin-left: 4px"
                      >
                        <el-input
                          v-model="item.address"
                          size="mini"
                          style="width: 100px"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item
                        label="事件原因"
                        style="margin-top: 8px; margin-left: 4px"
                      >
                        <el-input
                          v-model="item.value"
                          size="mini"
                          style="width: 100px"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item
                        label=" 事件情况及影响"
                        style="margin-top: 8px; margin-left: 4px"
                      >
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4 }"
                          placeholder="请输入内容"
                          v-model="item.description"
                        >
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item
                        label=" 采取的应急措施及处理结果"
                        style="margin-top: 8px; margin-left: 4px"
                      >
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4 }"
                          placeholder="请输入内容"
                          v-model="item.result"
                        >
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
                <i
                  class="el-icon-delete reovem cursor-pointer"
                  v-if="briefing.affected.otherEffectFlag && !readState"
                  @click="suddenremove(item)"
                ></i>
              </div>
            </div>
          </div>
          <div class="water" style="margin-top: 12px">
            是否有当前未能处理完毕事项的工作计划
            <el-select
              v-model="briefing.affected.workPlanInFlag"
              size="mini"
              style="width: 100px"
              :disabled="readState"
            >
              <el-option label="有" :value="true" :key="true"></el-option>
              <el-option label="无" :value="false" :key="false"></el-option>
            </el-select>
          </div>
          <div class="content" v-if="briefing.affected.workPlanInFlag">
            <el-form
              :model="briefing.affected.workPlanInfo"
              class="demo-form-inline"
              :disabled="readState"
            >
              <el-row>
                <el-col :span="10">
                  <el-form-item
                    label="建管部责任人"
                    style="margin-top: 8px; margin-left: 4px"
                  >
                    <el-select
                      v-model="
                        briefing.affected.workPlanInfo
                          .constManagDeptPersonInChargeUuid
                      "
                      size="mini"
                      style="width: 150px"
                      clearable
                      filterable
                    >
                      <el-option
                        v-for="item in jgList"
                        :key="item.uuid"
                        :label="item.realName"
                        :value="item.uuid"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="10">
                  <el-form-item label="联系电话" style="margin-top: 8px">
                    <el-input
                      size="mini"
                      style="width: 150px"
                      v-model="
                        briefing.affected.workPlanInfo
                          .constManagDeptPersonInChargePhone
                      "
                      oninput="if(value.length>5)value=value.slice(0,11)"
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      maxlength="11"
                      placeholder="请输入联系电话"
                      @change="phoneVerifyJG"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10">
                  <el-form-item
                    label="监理单位责任人"
                    style="margin-top: 8px; margin-left: 4px"
                  >
                    <el-select
                      v-model="
                        briefing.affected.workPlanInfo.monitorPersonInChargeUuid
                      "
                      size="mini"
                      style="width: 150px"
                      clearable
                      filterable
                    >
                      <el-option
                        v-for="item in jlList"
                        :key="item.uuid"
                        :label="item.realName"
                        :value="item.uuid"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="10">
                  <el-form-item label="联系电话" style="margin-top: 8px">
                    <el-input
                      size="mini"
                      style="width: 150px"
                      v-model="
                        briefing.affected.workPlanInfo
                          .monitorPersonInChargePhone
                      "
                      oninput="if(value.length>5)value=value.slice(0,11)"
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      maxlength="11"
                      placeholder="请输入联系电话"
                      @change="phoneVerifyJL"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10">
                  <el-form-item
                    label="施工单位责任人"
                    style="margin-top: 8px; margin-left: 4px"
                  >
                    <el-select
                      v-model="
                        briefing.affected.workPlanInfo
                          .constructPersonInChargeUuid
                      "
                      size="mini"
                      style="width: 150px"
                      clearable
                      filterable
                    >
                      <el-option
                        v-for="item in sgList"
                        :key="item.uuid"
                        :label="item.realName"
                        :value="item.uuid"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="10">
                  <el-form-item label="联系电话" style="margin-top: 8px">
                    <el-input
                      size="mini"
                      style="width: 150px"
                      v-model="
                        briefing.affected.workPlanInfo
                          .constructPersonInChargePhone
                      "
                      oninput="if(value.length>5)value=value.slice(0,11)"
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      maxlength="11"
                      placeholder="请输入联系电话"
                      @change="phoneVerifySG"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item
                    label=" 工作计划"
                    style="margin-top: 8px; margin-left: 4px"
                  >
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder="请输入内容"
                      v-model="briefing.affected.workPlanInfo.workPlan"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <el-divider></el-divider>
        <!-- 四、小结 -->
        <div class="briefong_one">
          <div class="defense">四、小结</div>
          <el-table
            :data="briefing.briefSummaryList"
            border
            style="width: 100%; margin-top: 10px"
          >
            <el-table-column prop="workGroupCount" label="派出工作组数">
              <template slot-scope="scope">
                <el-input v-model="scope.row.workGroupCount" :disabled="true">
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="checkerCount" label="出动检查人数">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.checkerCount"
                  :disabled="readState"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="hiddenDangerCount" label="发现隐患数量">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.hiddenDangerCount"
                  :disabled="true"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="rectificationCount" label="已整改数量">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.rectificationCount"
                  :disabled="true"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="evacueesCount" label="撤离人员">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.evacueesCount"
                  :disabled="readState"
                >
                </el-input> </template
            ></el-table-column>
          </el-table>
          <div class="plan" style="margin-top: 10px">
            根据天气预报信息简述下一步防范工作
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入内容"
              v-model="briefing.briefSummary.nextWorkPlan"
              :disabled="readState"
            >
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submit(0)" v-if="!readState">保存</el-button>
        <el-button type="primary" @click="submit(1)" v-if="!readState"
          >提交</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getInfo, SavedInfo } from "@/api/responseTem";
import { orgTypeList } from "@/api/ucenter";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { getDeptInfoById } from "@/api/org";
import { getMaterialsPage } from "@/api/eres";
export default {
  components: {},
  props: {
    bruefing: {
      type: Boolean,
      default: () => false,
    },
    rows: {
      type: Object,
      default: () => {},
    },
    // briefi: {
    //   type: Object,
    //   default: () => {}
    // }
  },
  watch: {
    bruefing: {
      handler() {
        this.breVisible = this.bruefing;
      },
      deep: true,
    },
    briefi: {
      handler() {
        this.briefing = this.briefi;
      },
      deep: true,
    },
  },
  data() {
    return {
      title: "三防工作简报",
      breVisible: false,
      url: "",
      briefing: {
        weatherOrOnDuty: {}, //水雨晴的台风情况
        defense: {
          practicable: [
            {
              date: "0",
              name: "0",
              address: "",
            },
          ],
          threeCheckItemQuestionList: [
            {
              checkPeopleList: [],
              checkTime: "",
            },
          ],
          abarbeitung: "",
          leaderCheckInfo: {
            checkPeopleList: [],
            checkTime: "",
          },
          externalUnitCheckInfo: {
            checkPeopleList: [],
            checkTime: "",
          },
          emergency: {
            list: [{ numbers: "", trew: "" }],
          },
          supplies: {
            list: [{ numbers: "", trew: "", tefcr: "" }],
          },
        }, //防御情况
        affected: {
          affectedFlag: false,
          sitePondingFlag: false,
          typhoonFlag: false,
          otherEffectFlag: false,
          workPlanInFlag: false,
          sitePondingList: [],
          typhoonList: [],
          otherEffectList: [],
          workPlanInfo: {},
        }, //受影响情况
      },

      options: [],

      equipment: [],

      connecting: [],

      against: "",
      typeList: [],
      leveloptions: [],
      jgList: [],
      jlList: [],
      sgList: [],
      readState: false,
      model: {
        supervisionUuid: "",
        supervisionName: "",
      },
      types: ["待整改", "待监理审批", "审批不通过", "完成"],
    };
  },
  methods: {
    handleFilling(record, state) {
      console.log(state);
      if (state == "read") this.readState = true;
      console.log(record);

      const userInfo = JSON.parse(localStorage.getItem("user"));
      if (userInfo) {
        this.model.user = {
          id: userInfo.id,
          name: userInfo.realName,
        };
      }
      queryDictByLabel({ label: "应急类别" }).then((res) => {
        queryDictNodesById({ id: res.data.id }).then((el) => {
          this.typeList = el.data;
        });
      });
      queryDictByLabel({ label: "当前等级" }).then((res) => {
        queryDictNodesById({ id: res.data.id }).then((el) => {
          this.leveloptions = el.data;
        });
      });
      orgTypeList({ orgType: 3 }).then((el) => {
        this.jgList = el.data;
      });
      orgTypeList({ orgType: 2 }).then((el) => {
        this.jlList = el.data;
      });
      orgTypeList({ orgType: 1 }).then((el) => {
        this.sgList = el.data;
      });
      this.queryDeptInfo();
      this.getDataList();
      this.gitusData(record);
    },
    //查询物资
    getDataList() {
      this.loading = true;
      const params = {
        materialType: 1,
        page: 1,
        size: 1000000,
      };
      getMaterialsPage(params).then((res) => {
        if (res.code == 0) {
          console.log(res.data);
          this.loading = false;
          this.equipment = res.data.records ? res.data.records : [];
        }
      });
    },
    queryDeptInfo() {
      const user = JSON.parse(localStorage.getItem("user"));
      this.model.supervisionUuid = user.departmentId;
      getDeptInfoById(user.departmentId).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            this.model.supervisionName = res.data.departmentName;
          }
        }
      });
    },
    // 回显信息接口
    gitusData(record) {
      let params = {
        id: record.id,
      };
      getInfo(params).then((res) => {
        // this.briefing = Object.assign(this.briefing, res.data);
        this.briefing = res.data;
        this.breVisible = true;
        if (!this.briefing.affected) {
          this.briefing.affected = {
            affectedFlag: false,
            sitePondingFlag: false,
            typhoonFlag: false,
            otherEffectFlag: false,
            workPlanInFlag: false,
            sitePondingList: [],
            typhoonList: [],
            otherEffectList: [],
            workPlanInfo: {},
          };
        }
        if (!this.briefing.defense.leaderCheckInfo) {
          this.briefing.defense.leaderCheckInfo = {
            checkPeopleList: [],
            checkPeoples: "",
            checkTime: "",
            hiddenDangerCount: 0,
            rectificationCount: 0,
            listData: [
              {
                hiddenDangerCount: 0,
                rectificationCount: 0,
              },
            ],
            threeCheckItemQuestionList: [],
          };
        } else {
          this.briefing.defense.leaderCheckInfo.checkPeoples =
            this.briefing.defense.leaderCheckInfo.checkPeopleList.join();
          this.briefing.defense.leaderCheckInfo.listData = [
            {
              hiddenDangerCount:
                this.briefing.defense.leaderCheckInfo.hiddenDangerCount,
              rectificationCount:
                this.briefing.defense.leaderCheckInfo.rectificationCount,
            },
          ];
        }
        if (!this.briefing.defense.threeCheckItemQuestionList) {
          this.briefing.defense.threeCheckItemQuestionList = [
            {
              checkPeopleList: [],
              checkPeoples: "",
              checkTime: "",
              positionCount: 0,
              hiddenDangerCount: 0,
              rectificationCount: 0,
              threeCheckItemQuestionList: [],
            },
          ];
        } else {
          this.briefing.defense.threeCheckItemQuestionList.forEach((e) => {
            e.checkPeoples = e.checkPeopleList.join();
          });
        }
        if (!this.briefing.defense.externalUnitCheckInfo) {
          this.briefing.defense.externalUnitCheckInfo = {
            checkPeopleList: [],
            checkPeoples: "",
            checkTime: "",
            hiddenDangerCount: 0,
            rectificationCount: 0,
            listData: [
              {
                hiddenDangerCount: 0,
                rectificationCount: 0,
              },
            ],
            threeCheckItemQuestionList: [],
          };
        } else {
          this.briefing.defense.externalUnitCheckInfo.checkPeoples =
            this.briefing.defense.externalUnitCheckInfo.checkPeopleList.join();
          this.briefing.defense.externalUnitCheckInfo.listData = [
            {
              hiddenDangerCount:
                this.briefing.defense.externalUnitCheckInfo.hiddenDangerCount,
              rectificationCount:
                this.briefing.defense.externalUnitCheckInfo.rectificationCount,
            },
          ];
        }
        if (!this.briefing.defense.reportOnDutyInfoList) {
          this.briefing.defense.reportOnDutyInfoList = [
            {
              count: 0,
              name: "",
            },
          ];
        }
        if (!this.briefing.defense.reportMaterialInfoList) {
          this.briefing.defense.reportMaterialInfoList = [
            {
              address: "",
              count: "",
              materialId: "",
              materialName: "",
            },
          ];
        }
        this.briefing.briefSummaryList = [this.briefing.briefSummary];
        console.log(this.briefing);
        this.$forceUpdate();
      });
    },
    uploader(val) {
      console.log(val);
      this.url = val;
    },
    mergencyadd() {
      this.briefing.defense.reportOnDutyInfoList.push({ count: 0, name: "" });
      this.$forceUpdate();
    },
    mergencyremov(val) {
      if (this.briefing.defense.reportOnDutyInfoList.length < 2) {
        return;
      } else {
        this.briefing.defense.reportOnDutyInfoList.splice(
          this.briefing.defense.reportOnDutyInfoList.indexOf(val),
          1
        );
      }
      this.$forceUpdate();
    },
    suppliesadd() {
      this.briefing.defense.reportMaterialInfoList.push({
        materialName: "",
        count: "",
        address: "",
      });
      this.$forceUpdate();
    },
    suppliesremove(val) {
      if (this.briefing.defense.reportMaterialInfoList.length < 2) {
        return;
      } else {
        this.briefing.defense.reportMaterialInfoList.splice(
          this.briefing.defense.reportMaterialInfoList.indexOf(val),
          1
        );
      }
      this.$forceUpdate();
    },
    // radiowe() {
    //   if (this.briefing.affected.affectedFlag == 1) {
    //     this.briefing.affected.defenseew = false;
    //   } else {
    //     this.briefing.affected.defenseew = true;
    //   }
    // },
    // 施工项目是否受积水影响
    watererd() {
      if (this.briefing.affected.sitePondingFlag) {
        this.briefing.affected.sitePondingList.push({
          address: "",
          value: "",
          description: "",
          result: "",
        });
      } else {
        this.briefing.affected.sitePondingList = [];
      }
    },
    wateradd() {
      this.briefing.affected.sitePondingList.push({
        address: "",
        value: "",
        description: "",
        result: "",
      });
    },
    waterremove(val) {
      if (this.briefing.affected.sitePondingList.length < 2) {
        return;
      } else {
        this.briefing.affected.sitePondingList.splice(
          this.briefing.affected.sitePondingList.indexOf(val),
          1
        );
      }
    },
    // 施工项目是否受台风影响
    influenceed() {
      if (this.briefing.affected.typhoonFlag) {
        this.briefing.affected.typhoonList.push({
          address: "",
          value: "",
          description: "",
          result: "",
        });
      } else {
        this.briefing.affected.typhoonList = [];
      }
    },
    influenceadd() {
      this.briefing.affected.typhoonList.push({
        address: "",
        value: "",
        description: "",
        result: "",
      });
    },
    influenceremove(val) {
      if (this.briefing.affected.typhoonList.length < 2) {
        return;
      } else {
        this.briefing.affected.typhoonList.splice(
          this.briefing.affected.typhoonList.indexOf(val),
          1
        );
      }
    },
    // 其他原因突发事件
    sudeed() {
      if (this.briefing.affected.otherEffectFlag) {
        this.briefing.affected.otherEffectList.push({
          address: "",
          value: "",
          description: "",
          result: "",
        });
      } else {
        this.briefing.affected.otherEffectList = [];
      }
    },
    suddenadd() {
      this.briefing.affected.otherEffectList.push({
        address: "",
        value: "",
        description: "",
        result: "",
      });
    },
    suddenremove(val) {
      if (this.briefing.affected.otherEffectList.length < 2) {
        return;
      } else {
        this.briefing.affected.otherEffectList.splice(
          this.briefing.affected.otherEffectList.indexOf(val),
          1
        );
      }
    },
    // 未处理完毕的工作计划
    workerd() {
      if (this.briefing.affected.workPlanInFlag == "true") {
        this.briefing.affected.workPlanInFlag = true;
      } else {
        this.briefing.affected.workPlanInFlag = false;
      }
    },
    abarbeitungChange(row) {
      console.log(row);
      this.briefing.defense.practicable[0].address = row.address;
    },

    // 小结
    onInputChange(row) {
      this.briefing.summary.tableData[0].onInput = row.onInput;
    },
    checkChange(row) {
      this.briefing.summary.tableData[0].check = row.check;
    },
    troubleChange(row) {
      this.briefing.summary.tableData[0].trouble = row.trouble;
    },
    rectificationChange(row) {
      this.briefing.summary.tableData[0].rectification = row.rectification;
    },
    evacuationChange(row) {
      this.briefing.summary.tableData[0].evacuation = row.evacuation;
    },
    //提交
    submit(data) {
      this.briefing.saveState = data;
      this.briefing.defense.leaderCheckInfo.hiddenDangerCount =
        this.briefing.defense.leaderCheckInfo.listData[0].hiddenDangerCount;
      this.briefing.defense.leaderCheckInfo.rectificationCount =
        this.briefing.defense.leaderCheckInfo.listData[0].rectificationCount;

      this.briefing.defense.externalUnitCheckInfo.hiddenDangerCount =
        this.briefing.defense.externalUnitCheckInfo.listData[0].hiddenDangerCount;
      this.briefing.defense.externalUnitCheckInfo.rectificationCount =
        this.briefing.defense.externalUnitCheckInfo.listData[0].rectificationCount;

      //人员转换
      this.briefing.defense.leaderCheckInfo.checkPeopleList =
        this.briefing.defense.leaderCheckInfo.checkPeoples.split();
      this.briefing.defense.externalUnitCheckInfo.checkPeopleList =
        this.briefing.defense.externalUnitCheckInfo.checkPeoples.split();

      this.briefing.briefSummary = this.briefing.briefSummaryList[0];

      delete this.briefing.briefSummaryList;
      let params = { ...this.briefing, ...this.model };
      console.log(params);
      // console.log(this.row);
      SavedInfo(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.handleClose();
        }
      });
    },
    //保存
    saves() {
      SavedInfo(this.briefing).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.$emit("fingrest");
          this.briefing = {};
        }
      });
    },
    handleClose() {
      this.breVisible = false;
      this.$emit("fingrest");
    },
    theOneChange() {
      this.$forceUpdate();
    },
    //建管部手机
    phoneVerifyJG(val) {
      let flag = this.phoneVerify(val);
      if (flag) {
        this.briefing.affected.workPlanInfo.constManagDeptPersonInChargePhone =
          "";
      }
    },
    //监理部手机
    phoneVerifyJL(val) {
      let flag = this.phoneVerify(val);
      if (flag) {
        this.briefing.affected.workPlanInfo.monitorPersonInChargePhone = "";
      }
    },
    //施工部手机
    phoneVerifySG(val) {
      let flag = this.phoneVerify(val);
      if (flag) {
        this.briefing.affected.workPlanInfo.constructPersonInChargeUuid = "";
      }
    },
    //手机号码验证
    phoneVerify(val) {
      if (
        !/^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/.test(
          val
        )
      ) {
        this.$message({
          message: "手机号码有误，请重新填写",
          type: "warning",
        });
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.briefong_auto {
  width: 90%;
  margin: auto;
  .briefong_one {
    .typhoon {
      font-size: 14px;
      color: #000000;
      font-weight: bold;
    }
    .defense {
      font-size: 14px;
      color: #000000;
      font-weight: bold;
    }
  }
}
.el-form-item {
  margin-bottom: 8px !important;
}
.content {
  width: 100%;
  // height: 300px;
  border: 2px solid #e8e8e8;
  margin-top: 8px;
  position: relative;

  .water {
    color: #62b9e5;
    position: absolute;
    top: 37%;
    left: 104%;
  }
  .influen {
    color: #62b9e5;
    position: absolute;
    top: 37%;
    left: 104%;
  }
  .reovem {
    color: #62b9e5;
    position: absolute;
    top: 37%;
    left: 104%;
  }
}
.el-table {
  font-size: 12px !important;
}
.toimg {
  display: flex;
  margin-top: 8px;
  margin-left: 4px;
  .imglist {
    width: 60px;
    height: 60px;
    margin-left: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.poert {
  position: relative;
  .el-icon-circle-plus-outline {
    color: #62b9e5;
    position: absolute;
    top: 33%;
    left: 60%;
  }
  .el-icon-delete {
    color: #62b9e5;
    position: absolute;
    top: 33%;
    left: 65%;
  }
  .supplie {
    top: 33%;
    left: 85%;
  }
  .suppiet {
    top: 33%;
    left: 90%;
  }
}

.water {
  position: relative;
  .waeeter {
    color: #62b9e5;
    position: absolute;
    top: 29%;
    left: 45%;
  }
  .suddend {
    color: #62b9e5;
    position: absolute;
    top: 29%;
    left: 50%;
  }
}
.el-radio {
  margin-right: 150px !important;
}
</style>
