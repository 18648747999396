<template>
  <div>
    <el-select
      v-model="chooseName"
      :placeholder="placeholder"
      :disabled="disabled"
      clearable
      @clear="handleClear"
      ref="selectUpResId"
    >
      <el-option
        hidden
        :key="pid"
        value="一级菜单"
        :label="chooseName"
      ></el-option>
      <!-- data数据， props配置选项 expand-on-click-node是否在点击节点的时候展开或者收缩节点  check-on-click-node是否在点击节点的时候选中节点 node-click节点被点击时的回调-->
      <el-tree
        :data="data"
        :props="defaultProps"
        :expand-on-click-node="false"
        :check-on-click-node="true"
        @node-click="handleNodeClick"
      >
      </el-tree>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "PopupTreeInput",
  props: {
    data: {
      type: Array,
    },
    defaultProps: {
      type: Object,
    },
    chooseName: {
      type: String,
    },
    pid: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleNodeClick(data) {
      this.$emit("deptTreeCurr", data);
    },
    handleClear() {},
  },
};
</script>

<style lang="scss" scoped>
.el-tree {
  color: #606266;
  overflow: auto;
  max-height: 350px !important;
}
</style>
