<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      :class="operation === 'detail' ? 'demo-model' : ''"
    >
      <el-form-item label="所属建管部" prop="superviseName">
        <el-input
          :disabled="operation === 'detail'"
          v-model="ruleForm.superviseName"
          placeholder="所属建管部"
        ></el-input>
      </el-form-item>
      <el-form-item label="线路名称" prop="lineName">
        <el-input
          :disabled="operation === 'detail'"
          v-model="ruleForm.lineName"
          placeholder="线路名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="分部-工区名称" prop="branchName">
        <el-input
          :disabled="operation === 'detail'"
          v-model="ruleForm.branchName"
          placeholder="分部-工区名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="参与工点"
        prop="label"
        v-if="
          editData.state == 2 || editData.state == 3 || operation === 'detail'
        "
      >
        <el-input v-model="ruleForm.siteName" disabled></el-input>
      </el-form-item>
      <el-form-item
        label="主办单位"
        prop="label"
        v-if="
          editData.state == 2 || editData.state == 3 || operation === 'detail'
        "
      >
        <el-input v-model="ruleForm.organizer" disabled></el-input>
      </el-form-item>
      <el-form-item
        label="参与单位"
        prop="label"
        v-if="
          editData.state == 2 || editData.state == 3 || operation === 'detail'
        "
      >
        <el-input
          v-model="ruleForm.participants"
          :disabled="operation === 'detail'"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="演练内容"
        prop="label"
        v-if="
          editData.state == 2 || editData.state == 3 || operation === 'detail'
        "
      >
        <el-input
          v-model="ruleForm.planContent"
          :disabled="operation === 'detail'"
          placeholder="请输入演练内容"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="演练类型"
        prop="drillTypeCode"
        v-if="
          editData.state == 2 || editData.state == 3 || operation === 'detail'
        "
      >
        <!--        <el-input v-model="ruleForm.category" placeholder="请输入演练地点"></el-input>-->
        <el-select
          v-model="ruleForm.drillTypeCode"
          placeholder="演练类型"
          :disabled="operation === 'detail'"
          style="width: 100%"
        >
          <el-option
            v-for="item in categoryList"
            :key="item.value"
            :label="item.label"
            :value="item.id.toString()"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="演练科目"
        prop="planSubjectCode"
        v-if="
          editData.state == 2 || editData.state == 3 || operation === 'detail'
        "
      >
        <!--        <el-input v-model="ruleForm.planSubjectCode" placeholder="请输入演练地点"></el-input>-->
        <el-select
          v-model="ruleForm.planSubjectCode"
          placeholder="演练科目"
          :disabled="operation === 'detail'"
          style="width: 100%"
        >
          <el-option
            v-for="item in subjectList"
            :key="item.value"
            :label="item.label"
            :value="item.id.toString()"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="演练级别"
        prop="drillLevelCode"
        v-if="
          editData.state == 2 || editData.state == 3 || operation === 'detail'
        "
      >
        <!--        <el-input v-model="ruleForm.drillLevelCode" placeholder="请输入演练地点"></el-input>-->
        <el-select
          v-model="ruleForm.drillLevelCode"
          placeholder="演练科目"
          :disabled="operation === 'detail'"
          style="width: 100%"
        >
          <el-option
            v-for="item in levelList"
            :key="item.value"
            :label="item.label"
            :value="item.id.toString()"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="演练地点"
        prop="label"
        v-if="
          editData.state == 2 || editData.state == 3 || operation === 'detail'
        "
      >
        <el-input
          v-model="ruleForm.address"
          :disabled="operation === 'detail'"
          placeholder="请输入演练地点"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="演练人数"
        prop="label"
        v-if="
          editData.state == 2 || editData.state == 3 || operation === 'detail'
        "
      >
        <el-input
          v-model="ruleForm.planJoinCount"
          :disabled="operation === 'detail'"
          placeholder="请输入演练人数"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="计划演练时间"
        prop="planDate"
        v-if="
          editData.state == 2 || editData.state == 3 || operation === 'detail'
        "
      >
        <el-date-picker
          style="width: 100%;"
          v-model="ruleForm.planDate"
          type="date"
          :disabled="operation === 'detail'"
          placeholder="计划演练时间"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="状态" prop="label" v-if="operation === 'detail'">
        <el-input
          v-model="ruleForm.stateName"
          placeholder="状态"
          :disabled="operation === 'detail'"
        ></el-input>
      </el-form-item>

      <el-form-item label="发起人" prop="label" v-if="operation === 'detail'">
        <el-input
          v-model="ruleForm.owner.name"
          placeholder="发起人"
          :disabled="operation === 'detail'"
        ></el-input>
      </el-form-item>

      <el-form-item label="发起时间" prop="label" v-if="operation === 'detail'">
        <el-input
          v-model="ruleForm.created"
          placeholder="发起时间"
          :disabled="operation === 'detail'"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="实际演练时间"
        prop="label"
        v-if="
          editData.state == 6 || editData.state == 7 || operation === 'detail'
        "
      >
        <el-date-picker
          style="width: 100%;"
          v-model="ruleForm.executeTime"
          type="date"
          :disabled="operation === 'detail'"
          placeholder="实际演练时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="实际参与人数"
        prop="label"
        v-if="
          editData.state == 6 || editData.state == 7 || operation === 'detail'
        "
      >
        <el-input
          v-model="ruleForm.joinCount"
          :disabled="operation === 'detail'"
          placeholder="请输入参与人数"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="附件"
        prop="label"
        v-if="
          editData.state == 2 || editData.state == 3 || operation === 'detail'
        "
      >
        <Uploader
          v-if="operation === 'edit'"
          @uploader="uploader"
          :file-array="[ruleForm.fileUrl ? ruleForm.fileUrl : '']"
        ></Uploader>
        <div v-else class="file-item" @click="downFile(ruleForm.fileUrl)">
          {{ ruleForm.fileName }}
        </div>
      </el-form-item>
      <el-form-item label="演练方案" prop="label" v-if="ruleForm.state >= 5">
        <Uploader
          v-if="operation === 'edit'"
          :file-array="programmeList"
        ></Uploader>
        <div
          v-else
          class="file-item"
          v-for="(item, index) in programmeList"
          :key="index"
          @click="openFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="脚本" prop="label" v-if="ruleForm.state >= 5">
        <Uploader
          v-if="operation === 'edit'"
          :file-array="scriptList"
        ></Uploader>
        <div
          v-else
          class="file-item"
          v-for="(item, index) in scriptList"
          :key="index"
          @click="openFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="影像资料" prop="label" v-if="ruleForm.state >= 5">
        <Uploader
          v-if="operation === 'edit'"
          :file-array="videoList"
        ></Uploader>
        <div
          v-else
          class="file-item"
          v-for="(item, index) in videoList"
          :key="index"
          @click="openFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="签到表" prop="label" v-if="ruleForm.state >= 5">
        <Uploader v-if="operation === 'edit'" :file-array="signList"></Uploader>
        <div
          v-else
          class="file-item"
          v-for="(item, index) in signList"
          :key="index"
          @click="openFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="总结" prop="label" v-if="ruleForm.state >= 5">
        <Uploader
          v-if="operation === 'edit'"
          :file-array="summaryList"
        ></Uploader>
        <div
          v-else
          class="file-item"
          v-for="(item, index) in summaryList"
          :key="index"
          @click="openFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="评估报告" prop="label" v-if="ruleForm.state >= 5">
        <Uploader
          v-if="operation === 'edit'"
          :file-array="assessmentList"
        ></Uploader>
        <div
          v-else
          class="file-item"
          v-for="(item, index) in assessmentList"
          :key="index"
          @click="openFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="其他附件" prop="label" v-if="ruleForm.state >= 5">
        <Uploader
          v-if="operation === 'edit'"
          :file-array="otherList"
        ></Uploader>
        <div
          v-else
          class="file-item"
          v-for="(item, index) in otherList"
          :key="index"
          @click="openFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer" v-if="operation === 'edit'">
      <el-button @click="cancelEdit">取 消</el-button>
      <el-button type="primary" @click="submitEdit">重新提交</el-button>
    </div>
  </div>
</template>

<script>
import Uploader from "@/views/System/TrainingDrill/components/Uploader";
import { getDictData } from "@/api/dict";
import { getRehearsalDetail, updateRehearsalState } from "@/api/stand";
export default {
  name: "addPlan",
  components: {
    Uploader,
  },
  props: {
    editData: {},
    operation: {
      default: "",
    },
  },
  data() {
    return {
      ruleForm: {
        siteName: "",
        organizer: "",
        participants: "",
        planContent: "",
        drillTypeCode: "",
        planSubjectCode: "",
        drillLevelCode: "",
        address: "",
        planJoinCount: "",
        planDate: "",
        owner: { name: "" },
      },
      rules: {},
      tags: [],
      dialogVisible: false,
      categoryList: [],
      subjectList: [],
      levelList: [],
      programmeList: [],
      scriptList: [],
      videoList: [],
      signList: [],
      summaryList: [],
      assessmentList: [],
      otherList: [],
    };
  },
  created() {
    this.getSelectList();
    this.getDetailData();
  },
  methods: {
    uploader(file) {
      console.log(file, "kkkkkkkkkkkkkk  ");
      this.ruleForm.fileUrl = file.uri;
      this.ruleForm.fileName = file.originalName;
    },
    getSelectList() {
      let stringList = ["eventType", "drill_type", "drill_level"];
      stringList.forEach((item) => {
        this.getDictList(item);
      });
    },
    getDictList(label) {
      getDictData(label).then((res) => {
        switch (label) {
          case "eventType":
            this.categoryList = res.data;
            break;
          case "drill_type":
            this.subjectList = res.data;
            break;
          case "drill_level":
            this.levelList = res.data;
            break;
        }
      });
    },
    cancelEdit() {
      this.$emit("cancelEdit");
    },
    getDetailData() {
      let params = {
        id: this.editData.id,
      };
      getRehearsalDetail(params).then((res) => {
        if (res.code == 0) {
          this.ruleForm = res.data;
          if (this.ruleForm.attachs && this.ruleForm.attachs.length > 0) {
            this.programmeList = this.ruleForm.attachs.filter((item) => {
              return item.attrType == 1;
            });
            this.scriptList = this.ruleForm.attachs.filter((item) => {
              return item.attrType == 2;
            });
            this.videoList = this.ruleForm.attachs.filter((item) => {
              return item.attrType == 3;
            });
            this.signList = this.ruleForm.attachs.filter((item) => {
              return item.attrType == 4;
            });
            this.summaryList = this.ruleForm.attachs.filter((item) => {
              return item.attrType == 5;
            });
            this.assessmentList = this.ruleForm.attachs.filter((item) => {
              return item.attrType == 6;
            });
            this.otherList = this.ruleForm.attachs.filter((item) => {
              return item.attrType == 7;
            });
          }
          this.ruleForm.branchName =
            this.ruleForm.branchName + "-" + this.ruleForm.areaName;
        }
      });
    },
    openFile(url) {
      window.open(url);
    },
    submitEdit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.ruleForm.drillType = this.categoryList.filter((item) => {
            return this.ruleForm.drillTypeCode == item.id;
          })[0].label;
          this.ruleForm.drillLevel = this.levelList.filter((item) => {
            return this.ruleForm.drillLevelCode == item.id;
          })[0].label;
          this.ruleForm.planSubject = this.subjectList.filter((item) => {
            return this.ruleForm.planSubjectCode == item.id;
          })[0].label;
          console.log(this.ruleForm);
          updateRehearsalState(this.ruleForm).then((res) => {
            console.log(res);
            if (res.code == 0) {
              this.$message.success("操作成功");
              this.$emit("successEdit");
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          this.$message.error("请正确填写");
        }
      });
    },
    downFile(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-class {
  display: flex;
  flex-direction: column;
}
.demo-model {
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    width: 50%;
  }
}
.file-item {
  color: #1aa3ff;
  cursor: pointer;
}
</style>
