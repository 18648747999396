<template>
  <div class="page-container bg-white p-4 h-full">
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      style="margin-bottom: 20px; font-size: 16px"
    >
      <el-breadcrumb-item
        :to="type == 'SoleDuty' ? { path: 'SoleDuty' } : { path: 'Assistance' }"
        >{{
          type == "SoleDuty" ? "专职救援队" : "社会救援队"
        }}</el-breadcrumb-item
      >
      <el-breadcrumb-item>物资清单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="状态">
            <el-select
              v-model="queryModal.state"
              clearable
              placeholder="请选择状态"
            >
              <el-option
                v-for="item of dict.state"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="queryModal.label"
              clearable
              placeholder="输入物资名称查询"
            ></el-input>
          </el-form-item>
            <el-form-item label="规格型号">
            <el-input
              v-model="queryModal.modelName"
              clearable
              placeholder="输入规格型号"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-button type="primary" @click="handleDownload" v-if="show"
            >下载导入模板</el-button
          >
          <el-button type="primary" @click="handleImport" v-if="show"
            >导入</el-button
          >
          <!-- <el-button type="primary" disabled>盘点记录</el-button> -->
          <el-button type="primary" @click="handleExport">导出</el-button>
          <el-button type="primary" @click="handleAdd" v-if="show"
            >新增</el-button
          >
        </el-row>
      </el-form>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="loading"
        @cell-mouse-enter="handleCellMouseEnter"
        @cell-mouse-leave="handleCellMouseLeave"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="label"
          label="物资名称"
          width="180"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="model"
          label="规格/型号"
          width="180"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="unit"
          label="计量单位"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="count"
          label="现存数量"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="standardCount"
          label="标准数量"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="usages"
          label="用途"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ JSON.parse(scope.row.usages).join(",") }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="stateLabel"
          label="状态"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="remark"
          label="备注"
        ></el-table-column>
        <el-table-column align="center" label="操作" width="200px">
          <template slot-scope="scope">
            <el-button type="text" @click="handleOut(scope.row)" v-if="show"
              >出库</el-button
            >
            <el-button type="text" @click="handleIn(scope.row)" v-if="show"
              >入库</el-button
            >
            <el-button type="text" @click="handleEdit(scope.row)" v-if="show"
              >编辑</el-button
            >
            <el-button type="text" @click="handleHistory(scope.row)"
              >历史记录</el-button
            >

            <el-button @click="handleDelete(scope.row)" v-if="show" type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <MaterialForm
      ref="modelForm"
      @formSubmit="handleFormSubmit"
      :type="125"
    ></MaterialForm>
    <MaterialInOut
      ref="modalInOutForm"
      @inOutSubmit="handleInOutSubmit"
    ></MaterialInOut>
    <HistoryRecord ref="modalHistory" :type="125"></HistoryRecord>
    <importEquip
      ref="importForm"
      @importSub="handleImportSub"
      :type="125"
    ></importEquip>
  </div>
</template>

<script>
import MaterialForm from "./modules/EquipmentForm.vue";
import MaterialInOut from "./modules/materialInOut.vue";
import HistoryRecord from "./modules/historyRecord.vue";
import importEquip from "./modules/importEquip.vue";
import { getMaterialsPage, delMaterials, exportMaterialFile } from "@/api/eres";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
export default {
  components: {
    MaterialForm,
    MaterialInOut,
    HistoryRecord,
    importEquip,
  },
  data() {
    return {
      teamId: "",
      teamName: "",
      queryModal: {
        label: "",
        state: "",
        modelNo: "",
        type: "125",
      },
      show: false,
      type: "",
      tableData: [],
      loading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      dict: {
        model: [],
        usages: [],
        state: [
          {
            label: "正常",
            value: 1,
          },
          {
            label: "异常",
            value: 2,
          },
        ],
      },
    };
  },
  created() {
    this.teamId = this.$route.query.id;
    this.teamName = this.$route.query.name;
    this.type = this.$route.query.type;
    this.show = this.$route.query.isShow;
  },
  mounted() {
    this.loadData();
    this.getDictData("物资用途", this.dict.usages);
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      console.log(row, rowIndex, "rowIndex");
      if (row.stateLabel == "异常") {
        return "warning-row";
      }
      // return "";
    },
    handleSearch() {
      this.loadData();
    },
    async handleDownload() {
      let res = await exportMaterialFile();
      if (res) {
        let fileName = "物资导入模板"; //这个是需要导出的表格的名字
        let blob = new Blob([res], { type: "application/vnd.ms-excel" }); //实例一个blob对象,res.data是后台反馈的二进制数据,type是需要下载的文件的类型此处为表格
        let link = document.createElement("a"); //创建一个a标签dom节点
        link.download = fileName; //下载文件的名字
        link.href = URL.createObjectURL(blob); //下载文件的地址
        link.style.display = "none"; //a标签默默无闻
        document.body.appendChild(link); //将a标签加入到dom中
        link.click(); //添加点击事件进行文件下载
        // 下载完成可以退场
        URL.revokeObjectURL(link.href); //销毁下载地址
        document.body.removeChild(link); //将a标签从dom中移除
      }
    },
    // 导入成功回调
    handleImportSub() {
      this.loadData();
    },
    handleImport() {
      this.$refs.importForm.handleImport(this.teamId, this.teamName);
    },
    handleExport() {
      console.log("导出");
    },
    handleOut(record) {
      this.$refs.modalInOutForm.handleInOut(1, record);
    },
    handleIn(record) {
      this.$refs.modalInOutForm.handleInOut(2, record);
    },
    handleHistory(record) {
      this.$refs.modalHistory.handleHistory(record);
    },
    handleAdd() {
      this.$refs.modelForm.handleAdd(this.teamId);
    },
    handleEdit(record) {
      this.$refs.modelForm.handleAdd(record);
    },
    handleDelete(record) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delMaterials(record.id).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadData();
          } else {
            this.$message.error("删除失败！");
          }
        });
      });
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    handleFormSubmit() {
      this.loadData();
    },
    handleInOutSubmit() {
      this.loadData();
    },
    // 加载表格数据
    loadData() {
      this.loading = true;
      const params = {
        materialType: 2,
        modelNo: this.queryModal.modelNo,
        state: this.queryModal.state,
         modelName:this.queryModal.modelName,
        label: this.queryModal.label,
        type: this.queryModal.type,
        page: this.page.pageNo,
        size: this.page.pageSize,
        rescueTeamId: this.teamId,
      };
      getMaterialsPage(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records;
          this.page.pageNo = res.data.current;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.total;
          this.page.count = res.data.count;
        } else {
          this.loading = false;
        }
      });
    },
    // 鼠标滑入单元格
    handleCellMouseEnter(row, column, cell, event) {
      if (column.property == "usages") {
        cell.querySelector(".cell").style.cursor = "pointer";
        console.log(row, column, cell, event);
        const labelArr = [];
        const usageStr = cell.querySelector(".cell").innerHTML;
        this.usageCache = usageStr;
        let usageArr = [];
        if (usageStr.length) {
          usageArr = usageStr.split("");
        }
        for (let usage of usageArr) {
          const tempObj = this.dict.usages.find((item) => item.label == usage);
          if (tempObj) {
            labelArr.push(tempObj);
          }
        }
        let str = "";
        for (let item of labelArr) {
          str += `<li>${item.label}.${item.intro}</li>`;
        }
        cell.querySelector(".cell").innerHTML = `<ul>${str}</ul>`;
      }
    },
    // 鼠标移出单元格
    handleCellMouseLeave(row, column, cell, event) {
      if (column.property == "usages") {
        console.log(row, column, cell, event);
        cell.querySelector(".cell").innerHTML = this.usageCache;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.p-4 {
  overflow-y: scroll;
}
::v-deep .el-table .warning-row {
  background-color: oldlace !important;
  // background-color: red !important;
}
</style>
