<template>
  <!-- 多人会议的小屏 -->
  <div style="width:100%;height:100%" class=" text-center">
    <div class="w-full h-full p-4" v-if="meetList.length == 0">
      <img
        class="image_8 mb-2 mt-2"
        referrerpolicy="no-referrer"
        src="@/assets/dummy.png"
      />
      <span class="text_23 mb-2">暂时无人通话</span>
      <el-button type="text" @click="AKeyToInvite">一键邀请</el-button>
    </div>

    <div class="block h-full" v-else>
      <el-carousel
        :autoplay="false"
        trigger="click"
        style="height: 100%;"
        indicator-position="click"
      >
        <el-carousel-item v-for="(i, index) in meetList" :key="index">
          <ul>
            <li
              v-for="(item, inde) in i"
              :key="inde"
              class="float-left work-canten"
              :class="inde % 2 == 0 ? 'mr-1.5' : ''"
            >
              {{ item.lable }}
            </li>
          </ul>
          <!-- <RtcDia
              ref="rtcdia"
              v-show="rtcShow"
              :dataList="dataList"
              @endVoip="endVoip"
              @openVoip="openVoip"
            ></RtcDia> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <inviteDialog ref="invite" @tonghuaList="tonghuaList"></inviteDialog>
  </div>
</template>

<script>
import inviteDialog from "./inviteDialog.vue";
export default {
  components: {
    inviteDialog,
  },
  data() {
    return {
      meetList: [],
    };
  },
  methods: {
    AKeyToInvite() {
      this.$refs.invite.handelOpen();
    },
    tonghuaList(/* data */) {
      // this.meetList = data;
      this.meetList = [
        [
          {
            lable: 111,
            value: 1,
          },
          {
            lable: 222,
            value: 2,
          },
          {
            lable: 333,
            value: 3,
          },
          {
            lable: 444,
            value: 4,
          },
        ],
        [
          {
            lable: 555,
            value: 5,
          },
          {
            lable: 666,
            value: 6,
          },
          {
            lable: 7777,
            value: 7,
          },
          {
            lable: 888,
            value: 8,
          },
        ],
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.image_8 {
  display: inline-block;
}
.text_23 {
  display: block;
  color: #fff;
  font-size: 12px;
}
.work-canten {
  // width: 45%;
  width: 49%;
  // height: 120px;
  height: 110px;
  background: #081742;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0px 1px 2px #000000;
  margin-bottom: 10px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

::v-deep .el-carousel__container {
  // height: 100% !important;
  height: 92% !important;
}
::v-deep .el-carousel__arrow {
  display: none !important;
}
</style>
