<template>
  <div class="left">
    <IndexBox :title="title">
      <div class="incident">
        <el-popover
          popper-class="warp"
          :popper-append-to-body="false"
          :visible-arrow="false"
          placement="bottom"
          width="100"
          trigger="click"
        >
          <div class="tpo">
            <p
              v-for="(item, index) in switchs"
              :key="index"
              style="margin-top:10px;   cursor: pointer;"
              @click="switchses(item)"
              class="top_rte"
            >
              {{ item.siteName }}
            </p>
          </div>
          <el-button slot="reference" type="text" icon="el-icon-edit"
            >切换事件</el-button
          >
        </el-popover>
      </div>
      <div :class="showTotal ? ' tntiret' : ' incident_top'">
        <div class="titlte">
          <span>{{ details.levelName==""?details.levelName:'未知等级' }} | {{ details.xycoors }}</span>
        </div>
        <div class="conent">
          <div :class="showTotal ? 'total-introduce' : 'detailed-introduce'">
            <p>
              <span class="time">发生时间：</span>
              <span>{{ details.created }}</span>
            </p>
            <p>
              <span class="time">事发经过：</span
              ><span>{{ details.historyRecord }}</span>
            </p>
            <p>
              <span class="time">伤亡情况：</span
              ><span>{{ details.casualties }}</span>
            </p>
            <p>
              <span class="time">处置情况：</span
              ><span>{{ details.disposal }}</span>
            </p>
            <p>
              <span class="time">负责人：</span
              ><span>{{ details.chargeName }}：{{ details.chargePhone }}</span>
            </p>
            <p>
              <span class="time"> 施工单位：</span
              ><span>{{ details.constructionUnitName }}</span>
            </p>
            <p>
              <span class="time">事件类型：</span
              ><span>{{ details.typeName }}</span>
            </p>
          </div>
          <div class="toner">
            <span class="more-txt" @click="more" v-if="anert"
              >展开全部 <img src="../../../assets/zhankai.png" alt=""
            /></span>
            <span class="close-more" @click="close" v-if="pack"
              >收起 <img src="../../../assets/shouq.png" alt=""
            /></span>
          </div>
        </div>
      </div>
      <div class="incident_bottom">
        <div class="tabes">
          <div
            :class="num == index ? 'panes' : 'panese'"
            v-for="(item, index) in contn"
            :key="index"
            @click="changes(index)"
          >
            {{ item }}
          </div>
        </div>
        <div v-show="num == 0" class="rever">
          <el-timeline style="margin-top: 10px">
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
            >
              <div class="actitle">
                <span class="incid">{{ activity.responseProcessName }}</span>
                <span class="times">{{ activity.created }}</span>
              </div>
              <div class="reported">
                <span class="incid"> 上报人：</span>
                <span>{{ activity.responseUserName }}</span>
              </div>
              <div
                class="content"
                v-show="
                  activity.responseProcessName == '事件发布' ||
                    activity.responseProcessName == '事件终报' ||
                    activity.responseProcessName == '响应升级' ||
                    activity.responseProcessName == '应急预警'
                "
              >
                <span class="incid">事件等级:</span
                ><span>{{ activity.levelName==""?activity.levelName:'未知' }}</span>

                <!-- <el-button type="text" v-show="activity.incident !== '事件确认'"
                  >详情</el-button
                > -->
              </div>
              <div
                class="content"
                v-show="
                  activity.responseProcessName == '事件续报' ||
                    activity.responseProcessName == '事件终报'
                "
              >
                <span class="incid"> 事件经过:</span
                ><span>{{ activity.historyRecord }}</span>
              </div>
              <div
                class="content"
                v-show="
                  activity.responseProcessName == '事件续报' ||
                    activity.responseProcessName == '事件终报'
                "
              >
                <span class="incid"> 伤亡情况:</span
                ><span>{{ activity.casualties }}</span>
              </div>
              <div
                class="content"
                v-show="
                  activity.responseProcessName == '事件续报' ||
                    activity.responseProcessName == '事件终报' ||
                    activity.responseProcessName == '启动预案'
                "
              >
                <span class="incid">处置情况:</span
                ><span>{{ activity.disposal }}</span>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
        <div v-show="num == 1" class="rever">
          <el-timeline style="margin-top: 10px">
            <el-timeline-item
              v-for="(activity, index) in puberlic"
              :key="index"
            >
              <div class="actitle">
                <span class="incid">{{ activity.incident }}</span>
                <span class="times">{{ activity.time }}</span>
              </div>
              <div class="connt">
                <span>{{ activity.details }}</span>
                <el-button type="text" v-show="activity.incident !== '事件确认'"
                  >详情</el-button
                >
              </div>
              <div class="imged">
                <img :src="activity.imgs" alt="" style="" />
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
        <div v-show="num == 2">流程图</div>
      </div>
      <div class="ter" style="display: flex; justify-content: space-between">
        <el-button type="primary" @click="give" v-has="'Outburs:continue'"
          >事件续报</el-button
        >
        <el-button type="primary" @click="handling" v-has="'Outburst:quote'"
          >事件终报</el-button
        >
        <el-button type="primary" @click="casese">类似案例</el-button>
      </div>
    </IndexBox>

    <Modal
      class="modalDetail"
      ref="qutypes"
      :modal="false"
      :width="'800px'"
      :title="titesle"
      ><Quoted @closeter="closeter" :statre="statre" :uuid="uuid"></Quoted>
    </Modal>
    <Modal
      class="modalDetail"
      ref="sesd"
      :modal="false"
      :width="'1200px'"
      :title="titesle"
    >
      <Cased ref="cserd"></Cased
    ></Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal/modal.vue";
import IndexBox from "@/components/IndexBox/indexBox";
import { getprogress, getsiteAlarm } from "@/api/response.js";
import Quoted from "./incident/quoted.vue";
import Cased from "./incident/cased.vue";
// import { unzipSync } from "zlib";
// import bus from "@/utils/bus";
export default {
  components: { IndexBox, Quoted, Cased, Modal },
  data() {
    return {
      title: "地面沉降",
      contn: ["事件进展", "舆情信息", "流程图"],
      num: 0,
      activities: [],
      puberlic: [
        {
          incident: "广州快报",
          time: "2020-12-12 08:28:25",
          details:
            "地面巡查发现中国石油地面巡查发现中国石油，地面巡查发现中国石油，地面巡查发现中国石油地面巡查发现中国石油",
          imgs: require("../../../assets/equipment-03.png")
        },
        {
          incident: "新浪微博",
          time: "2020-12-12 08:28:25",

          details:
            "地面巡查发现中国石油地面巡查发现中国石油，地面巡查发现中国石油，地面巡查发现中国石油地面巡查发现中国石油",
          imgs: require("../../../assets/equipment-03.png")
        },
        {
          incident: "央视快报",
          time: "2020-12-12 08:28:25",

          details:
            "地面巡查发现中国石油地面巡查发现中国石油，地面巡查发现中国石油，地面巡查发现中国石油地面巡查发现中国石油",
          imgs: require("../../../assets/equipment-03.png")
        }
      ],
      titesle: "",
      rescueTitle: "",
      anert: false,
      pack: false,
      showTotal: true,
      statre: 0,
      uuid: "",
      details: [],
      switchs: []
    };
  },
  created() {
    setInterval(
      //需要定时执行的代码
      this.timer,
      30000
    );

    this.record();
    this.terwr();
    this.progress(this.details);
  },
  mounted() {
    this.$nextTick(() => {
      let contentDom = document.querySelectorAll(".conent");
      contentDom = [...contentDom];
      console.log(contentDom);
      contentDom.forEach(item => {
        let height = item.offsetHeight;
        console.log(height);
        if (height > 100) {
          // 超过高度，给该文本的父元素添加class
          this.anert = true;
          this.pack = false;
          this.showTotal = true;
        }
      });
    });
  },
  methods: {
    timer() {
      this.progress(this.details);
    },
    terwr() {
      // if (!JSON.parse(this.$route.query.data)) {
      //   bus.$on("formData", val => {
      //     console.log(val);
      //     this.details = val;
      //   });
      // } else {

      // }
      if(this.$route.query.data==undefined){
      this.details= JSON.parse(localStorage.getItem("detirler"));
      console.log( JSON.parse(localStorage.getItem("detirler")))
      }else{
         this.details = JSON.parse(this.$route.query.data);
      }
      
     
      this.$emit("detaliel", this.details);
      console.log(this.details);
      this.progress(this.details);
    },
    //事件切换
    switchses(item) {
      console.log(item);
      this.details = item;
      this.$emit("detaliel", this.details);
      this.progress(this.details);
    },
    //应急响应记录列表
    record() {
      let arrts = JSON.parse(localStorage.getItem("Response_Information"));

      if (arrts) {
        this.switchs = arrts;
        console.log(arrts);
        // this.detailse(arrts[0].responseRecordUuid);
      } else {
        let params = {
          alarmStatus: "alarming",
          // uploadUnitType: this.deptType
          uploadUnitType: "monitor"
          // monitor 监理单位
          // construct 施工单位
        };
        getsiteAlarm(params).then(res => {
          this.switchs = res.data.list;
          // console.log(res);
          // this.detailse(arrts[0].responseRecordUuid);
        });
      }
    },
    // detailse(val) {
    //   console.log(val);
    //   detailsList({ uuid: val }).then(res => {
    //     console.log(res);
    //     this.details = res.data;
    //     this.$emit("detaliel", this.details);
    //   });
    // },
    //事件进展
    progress(val) {
      getprogress({
        responseRecordUUid: val.uuid,
        responseType: "all",
        size: "0"
      }).then(res => {
        console.log(res);
        this.activities = res.data;
      });
    },
    changes(val) {
      console.log(val);
      this.num = val;
    },

    give() {
      this.$refs.qutypes.handleOpen();
      console.log(this.details);
      this.uuid = this.details.uuid;
      this.statre = 1;
      this.titesle = "事件续报";
    },
    handling() {
      this.$refs.qutypes.handleOpen();
      this.uuid = this.details.uuid;
      this.statre = 2;
      this.titesle = "事件终报";
    },
    closeter() {
      this.$refs.qutypes.handleClose();
    },
    casese() {
      this.$refs.sesd.handleOpen();
      this.$refs.cserd.putted();
      this.titesle = "类似案例";
    },
    opinion() {},
    tecer() {},
    //展开
    more() {
      this.showTotal = false;
      this.anert = false;
      this.pack = true;
    },
    //收起
    close() {
      this.anert = true;
      this.pack = false;
      this.showTotal = true;
    }
  },
  beforeDestroy() {
    // debugger;
    this.timer();
  }
};
</script>

<style lang="scss" scoped>
.left {
  .incident {
    position: absolute;
    left: 67%;
    top: 1.5%;
  }
  .tntiret {
    height: 35%;

    border-bottom: 1px solid #002e75;
    .titlte {
      display: flex;
      justify-content: space-between;
      span {
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .total-introduce {
      height: 200px;
      overflow: hidden;
    }
    .detailed-introduce {
      height: 100%;
    }
    p {
      margin-top: 3%;
      font-size: 13px;
      line-height: 22px;
      padding-left: 59px;
      text-indent: -59px;
      .time {
        width: 50px;
        color: #ffffff;
        opacity: 0.6;
      }
      span {
        color: #ffffff;
      }
    }

    .toner {
      .more-txt {
        font-size: 12px;
        color: #1791ff;
        float: right;
        position: relative;
        right: 45%;
        margin-top: -1%;

        cursor: pointer;
        display: flex;
        img {
          width: 10px;
          height: 10px;
          margin-top: 4px;
          margin-left: 7px;
        }
      }
      .close-more {
        font-size: 12px;
        color: #1791ff;
        float: right;
        position: relative;
        right: 45%;
        margin-top: 4px;

        margin-bottom: 4px;

        cursor: pointer;
        display: flex;
        img {
          width: 10px;
          height: 10px;
          margin-top: 4px;
          margin-left: 7px;
        }
      }
    }
  }
  .incident_top {
    height:37%;
    overflow: auto;
    border-bottom: 1px solid #002e75;
    .titlte {
      display: flex;
      justify-content: space-between;
      span {
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .total-introduce {
      height: 200px;
      overflow: hidden;
    }
    .detailed-introduce {
      height: 100%;
    }
    p {
      margin-top: 3%;
      font-size: 13px;
      line-height: 22px;
      padding-left: 59px;
      text-indent: -59px;
      .time {
        width: 50px;
        color: #ffffff;
        opacity: 0.6;
      }
      span {
        color: #ffffff;
      }
    }

    .toner {
      .more-txt {
        font-size: 12px;
        color: #1791ff;
        float: right;
        position: absolute;
        right: 45%;
        margin-top: -2px;

        cursor: pointer;

        display: flex;
        img {
          width: 10px;
          height: 10px;
          margin-top: 4px;
          margin-left: 7px;
        }
      }
      .close-more {
        font-size: 12px;
        color: #1791ff;
        float: right;
        position: relative;
        right: 45%;
        margin-top: 4px;
        cursor: pointer;
        display: flex;

        padding-bottom: 4px;
        img {
          width: 10px;
          height: 10px;
          margin-top: 4px;
          margin-left: 7px;
        }
      }
    }
  }
  .incident_top::-webkit-scrollbar {
    display: none;
  }
  .incident_bottom {
    margin-top: 10px;
    margin-left: 30px;
    height: 54%;
    .tabes {
      width: 70%;
      height: 35px;
      margin: auto;
      border: 1px solid #43a5e9;
      display: flex;
      border-radius: 5px;
      justify-content: space-around;
      align-items: center;
      .panes {
        width: 34%;
        height: 35px;
        color: #ffffff;
        font-size: 12px;
        text-align: center;
        line-height: 35px;
      }
      .panese {
        width: 34%;
        height: 35px;
        background: #002e75;
        opacity: 0.6;
        color: #ffffff;
        font-size: 12px;
        text-align: center;
        line-height: 35px;
        border-left: 1px solid #43a5e9;
      }
    }
    .rever {
      height: 100%;

      overflow-y: auto;
      .actitle {
        display: flex;
        justify-content: space-between;
        .times {
          color: #ffffff;
          opacity: 0.6;
          font-size: 12px;
        }
        .incid {
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
      }
      .reported {
        margin-top: 10px;
        .incid {
          color: #ffffff;
          opacity: 0.6;
          font-size: 12px;
        }
        span {
          color: #ffffff;
          font-size: 12px;
        }
      }
      .content {
        margin-top: 10px;
        line-height: 22px;
        padding-left: 58px;
        text-indent: -58px;
        .incid {
          color: #ffffff;
          opacity: 0.6;
          font-size: 12px;
        }
        span {
          color: #ffffff;
          font-size: 12px;
        }
      }
      .connt {
        margin-top: 10px;
        color: #ffffff;
      }
    }
    .rever::-webkit-scrollbar {
      display: none;
    }
  }
}
::v-deep .el-timeline-item__node {
  background-color: rgba(59, 130, 246);
}
::v-deep .el-timeline-item__tail {
  border-left: 2px solid #28567f;
}
.top_rte {
}
.top_rte:hover {
  width: 100%;
  background-color: #28567f;
}
.ter {
  width: 19%;
  height: 42px;
  position: fixed;
  top: 94%;
  background: #071743;
  // margin-top: 25%;
  display: flex;
  justify-content: space-around;
}
</style>
<style lang="scss">
.warp {
  background: #071743 !important;
  color: #ffffff;
  border-color: #071743;
  .top p:hover {
    background: #28567f;
  }
}
.warp .el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #071743 !important;
}
</style>
