<template>
  <div class="stCard" style="height: 99%">
    <div class="mt-2 mb-8">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/Contingency/EmeStatics' }"
          >数据统计</el-breadcrumb-item
        >
        <el-breadcrumb-item
          >{{ paramsData.name }}-{{ paramsData.tabName }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div>
      <YJDB
        v-if="paramsData.name == '应急待办统计'"
        :paramsData="paramsData"
      ></YJDB>
      <YJPD
        v-if="paramsData.name == '物资盘点统计'"
        :paramsData="paramsData"
      ></YJPD>
      <YJPX
        v-if="paramsData.name == '应急培训统计'"
        :paramsData="paramsData"
      ></YJPX>
      <YJSJ
        v-if="paramsData.name == '应急事件统计'"
        :paramsData="paramsData"
      ></YJSJ>
      <YJYA
        v-if="paramsData.name == '应急预案统计'"
        :paramsData="paramsData"
      ></YJYA>
      <YJYL
        v-if="paramsData.name == '应急演练统计'"
        :paramsData="paramsData"
      ></YJYL>
    </div>
  </div>
</template>

<script>
import YJDB from "./tables/YJDB.vue";
import YJPD from "./tables/YJPD.vue";
import YJPX from "./tables/YJPX.vue";
import YJSJ from "./tables/YJSJ.vue";
import YJYA from "./tables/YJYA.vue";
import YJYL from "./tables/YJYL.vue";

export default {
  components: { YJDB, YJPD, YJPX, YJSJ, YJYA, YJYL },
  props: {},
  data() {
    return {
      tabledata: [],
      paramsData: {},
    };
  },
  watch: {},
  methods: {},

  mounted() {
    this.paramsData = this.$route.params;
    console.log("route", this.$route);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 700px;
  overflow: auto;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
::v-deep .el-table:before {
  height: 0px;
}
.stCard {
  margin: 0 20px 10px 0;
  padding: 20px 20px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.cenCard {
  padding: 20px 20px;
  width: 24%;
  height: 100px;
}
.cenNum {
  font-size: 28px;
  font-family: Arial;
  font-weight: bold;
  color: #081742;
}
</style>
