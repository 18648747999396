<template>
  <div class="material-container h-full">
    <el-form
      ref="formData"
      :rules="rules"
      :model="formData"
      :loading="loading"
      label-width="110px"
      :disabled="submitType == 'view'"
    >
      <el-form-item label="隐患发布单位:" prop="releaseUnitType">
        <el-select
          v-model="formData.releaseUnitType"
          style="width:100%"
          placeholder="请选择隐患发布单位"
        >
          <el-option
            v-for="item of unitTypeData"
            :key="item.code"
            :label="item.intro"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="隐患整改单位:" prop="reformUnitType">
        <el-select
          v-model="formData.reformUnitType"
          clearable
          style="width:100%"
          placeholder="请选择整改单位"
        >
          <el-option
            v-for="item of reformUnitTypeData"
            :key="item.code"
            :label="item.intro"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排查等级:" prop="hiddenLevel">
        <el-select
          v-model="formData.hiddenLevel"
          clearable
          style="width:100%"
          placeholder="请选择排查等级"
        >
          <el-option
            v-for="item of hdLevelData"
            :key="item.code"
            :label="item.intro"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="隐患响应单位:" prop="responseUnitType">
        <el-select
          v-model="formData.responseUnitType"
          style="width:100%"
          placeholder="请选择隐患响应单位"
          @change="unitTypeChange"
        >
          <el-option
            v-for="item of unitTypeData"
            :key="item.code"
            :label="item.intro"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="隐患响应角色:" prop="roleId">
        <el-select
          v-model="formData.roleId"
          clearable
          style="width:100%"
          placeholder="请选择用户角色"
        >
          <el-option
            v-for="item of rolesData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="响应时限:" prop="responseDeadline">
        <el-input
          v-model.number="formData.responseDeadline"
          placeholder="请输入响应时限"
          style="width:100%"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialogFooter">
      <el-button size="medium" @click="cancel" v-show="submitType !== 'view'"
        >取 消</el-button
      >
      <el-button
        size="medium"
        type="primary"
        @click="submit"
        v-show="submitType !== 'view'"
        >确 定</el-button
      >
    </div>
  </div>
</template>

<script>
// 字典
import { getDictData } from "@/api/dict";
// 查询角色列表
import { getRoleApi } from "@/api/role";
// 新增,编辑
import { responseAdd, responseEdit } from "@/api/hdanger";

export default {
  props: {
    pageData: {
      type: Object,
      default: () => {},
    },
    submitType: {
      type: String,
    },
  },
  data() {
    return {
      formData: {
        releaseUnitType: "",
        reformUnitType: "",
        hiddenLevel: "",
        responseUnitType: "",
        region: "",
        responseDeadline: "",
        roleId: "",
      },
      // 单位类型
      unitTypeData: [],
      // 整改单位
      reformUnitTypeData: [],
      // 隐患等级
      hdLevelData: [],
      // 角色
      rolesData: [],
      loading: false,
      rules: {
        releaseUnitType: [
          { required: true, message: "请选择隐患发布单位", trigger: "change" },
        ],
        responseUnitType: [
          { required: true, message: "请选择隐患响应单位", trigger: "change" },
        ],
        reformUnitType: [
          { required: true, message: "请选择隐患整改单位", trigger: "change" },
        ],
        hiddenLevel: [
          { required: true, message: "请选择排查等级", trigger: "change" },
        ],
        roleId: [
          { required: true, message: "请选择隐患响应角色", trigger: "change" },
        ],
        responseDeadline: [
          { required: true, message: "请输入响应时限", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    "pageData.id": {
      handler(val) {
        if (val) {
          this.getData();
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
  },
  created() {},
  mounted() {
    // this.getData();
    this.getDict();
  },
  methods: {
    getData() {
      // this.formData.roleId = this.pageData.roleName;
      this.formData = { ...this.pageData };
      this.formData.roleId = Number(this.pageData.roleId);
      this.rolesData = [];
      getRoleApi({ unitType: this.pageData.responseUnitType })
        .then((res) => {
          if (res.code == 0) {
            res.data.forEach((element) => {
              this.rolesData.push({
                label: element.roleName,
                value: element.id,
              });
            });
          }
        })
        .catch((res) => {
          console.log(res.messafe);
        });
    },
    getDict() {
      //发布单位/响应单位,整改单位,等级
      const demo = ["unitType", "hdLevel", "reform_unit_type"];
      demo.forEach((item) => {
        this.getDictList(item);
      });
    },
    // 字典  unitType 单位类型
    getDictList(val) {
      getDictData(val).then((res) => {
        if (res.code == 0) {
          switch (val) {
            case "unitType":
              this.unitTypeData = res.data;
              break;
            case "hdLevel":
              this.hdLevelData = res.data;
              break;
            case "reform_unit_type":
              this.reformUnitTypeData = res.data;
              break;
          }
        }
      });
    },
    unitTypeChange(val) {
      this.formData.roleId = "";
      this.rolesData = [];
      getRoleApi({ unitType: val })
        .then((res) => {
          if (res.code == 0) {
            res.data.forEach((element) => {
              this.rolesData.push({
                label: element.roleName,
                value: element.id,
              });
            });
          }
        })
        .catch((res) => {
          console.log(res.messafe);
        });
    },
    submit() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, this.formData);
          if (this.submitType == "add") {
            copyForm.id = "";
            responseAdd(copyForm)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.loading = true;
                  this.cancel();
                } else {
                  this.$message.error(res.message);
                }
              })
              .catch((res) => {
                this.$message.error(res.message);
              });
          } else if (this.submitType == "edit") {
            responseEdit(copyForm)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.loading = true;
                  this.cancel();
                } else {
                  this.$message.error(res.message);
                }
              })
              .catch((res) => {
                // this.cancel();
                this.$message.error(res.message);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancel() {
      this.reset();
      this.$emit("CloseData");
    },
    reset() {
      this.$refs.formData.resetFields();

      // this.$refs['formData'].resetFields()
      // 比上方获取demo的清理方式更彻底
      const th = this;
      Object.keys(this.formData).forEach(function(key) {
        th.formData[key] = "";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
</style>
