<template>
<!--  <systemModal ref="formModal" :title="title" :showFooter="false" width="40%">-->
    <div class="timeline-wrapper" v-loading="loading">
      <el-timeline>
        <el-timeline-item v-for="item in historyData" :key="item.id"
                          :timestamp="item.createTime" placement="top">
          <el-card>
            <h4>{{ item.operationName }}</h4>
            <p> 操作人 : {{ item.operatorName }}</p>
            <p v-if="item.result"> 审核结果 :  {{ item.result }}</p>
            <p v-if="item.reason"> 审核原因 : {{ item.reason }}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
<!--  </systemModal>-->
</template>

<script>
import { operation_historyList} from "@/api/stand";
let handleType = [
  {label: "提交计划", value: 0},
  {label: "计划审批通过", value: 1},
  {label: "计划审批驳回", value: 2},
  {label: "开始前提醒", value: 3},
  {label: "开始演练", value: 4},
  {label: "提交报告", value: 5},
  {label: "报告审核通过，完成演练", value: 6},
  {label: "报告审核驳回", value: 7},
  {label: "上传附件", value: 8},
]
export default {
  props:{
    historyObject : {}
  },
  components: {
  },
  data() {
    return {
      title: "历史操作",
      historyData: [],
      loading: false,
      activities: [{
        content: '活动按期开始',
        timestamp: '2018-04-15'
      }, {
        content: '通过审核',
        timestamp: '2018-04-13'
      }, {
        content: '创建成功',
        timestamp: '2018-04-11'
      }]
    };
  },
  filters: {
    HanldeName(val) {
      const temp = handleType.find(item => item.value == val);
      if (temp) {
        return temp.label;
      }
    }
  },
  mounted() {
    this.getHistoryById();
  },
  methods: {
    handleHistory(record) {
      this.historyData = [];
      this.getHistoryById(record.id);
      this.$refs.formModal.handleOpen();
    },
    getHistoryById() {
      this.loading = true;
      let params = {
        linkId : this.historyObject.id
      }
      operation_historyList(params).then(res => {
        console.log(res);
        console.log(111)
        if (res.code == 0) {
          this.historyData = res.data;
          this.loading = false;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}
::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
.timeline-wrapper {
  min-height: 300px;
  padding: 20px;
}
</style>
