<template>
  <!-- 风险等级/状态统计 -->
  <div class="container">
    <index-box :title="title">
      <div class="weather-container flex flex-col justify-between">
        <div class="list">
          <p class="headline">
            集团级
          </p>
          <div class="list-item">
            <div
              class="item-group"
              v-for="(item, index) in groupData"
              :key="index"
              @click="leveClick('集团级', item)"
            >
              <p class="item-group-count" :style="{ color: item.color }">
                {{ item.dueCount }}
              </p>
              <p class="item-group-name">{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="list">
          <p class="headline">
            总部级
          </p>
          <div class="list-item">
            <div
              class="item-group"
              v-for="(item, index) in officeData"
              :key="index"
              @click="leveClick('总部级', item)"
            >
              <p class="item-group-count" :style="{ color: item.color }">
                {{ item.dueCount }}
              </p>
              <p class="item-group-name">{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="list">
          <p class="headline">
            部门级
          </p>
          <div class="list-item">
            <div
              class="item-group"
              v-for="(item, index) in sectionData"
              :key="index"
              @click="leveClick('部门级', item)"
            >
              <p class="item-group-count" :style="{ color: item.color }">
                {{ item.dueCount }}
              </p>
              <p class="item-group-name">{{ item.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </index-box>
    <HomePageDialog
      ref="modalTable"
      :titleTable="titleTable"
      :columns="columns"
      :tableList="tableList"
      :totalData="totalData"
      :leftOneParams="leftOneParams"
    ></HomePageDialog>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
// 数据,弹框
import { getRiskCount, getRiskCountPage } from "@/api/hdanger";
import HomePageDialog from "@/views/HiddenDanger/components/HomePageDialog.vue";
export default {
  name: "weather",
  components: {
    IndexBox,
    HomePageDialog,
  },
  data() {
    return {
      title: "风险等级/状态统计",
      groupData: [
        {
          name: "总数",
          dueCount: 0,
          color: "#FFFFFF",
        },
        {
          name: "已攻克",
          dueCount: 0,
          color: "#72C040",
        },
        {
          name: "未实施",
          dueCount: 0,
          color: "#FAAD13",
        },
        {
          name: "正实施",
          dueCount: 0,
          color: "#1791FF",
        },
      ],
      officeData: [
        {
          name: "总数",
          dueCount: 0,
          color: "#FFFFFF",
        },
        {
          name: "已攻克",
          dueCount: 0,
          color: "#72C040",
        },
        {
          name: "未实施",
          dueCount: 0,
          color: "#FAAD13",
        },
        {
          name: "正实施",
          dueCount: 0,
          color: "#1791FF",
        },
      ],
      sectionData: [
        {
          name: "总数",
          dueCount: 0,
          color: "#FFFFFF",
        },
        {
          name: "已攻克",
          dueCount: 0,
          color: "#72C040",
        },
        {
          name: "未实施",
          dueCount: 0,
          color: "#FAAD13",
        },
        {
          name: "正实施",
          dueCount: 0,
          color: "#1791FF",
        },
      ],
      titleTable: "",
      columns: [
        { prop: "lineName", label: "线路", minWidth: 80 },
        { prop: "siteName", label: "工点", minWidth: 80 },
        { prop: "riskName", label: "风险名称", minWidth: 80 },
        { prop: "riskQuarter", label: "季度", minWidth: 80 },
      ],
      tableList: [],
      totalData: 0,
      leftOneParams: {
        riskLevel: "",
        status: "",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      getRiskCount().then((res) => {
        if (res.code == 0) {
          if (res.data) {
            res.data.forEach((item) => {
              if (item.riskLevel == "总部级") {
                this.officeData.forEach((list) => {
                  switch (list.name) {
                    case "总数":
                      list.dueCount = item.num ? item.num : 0;
                      break;
                    case "已攻克":
                      list.dueCount = item.finishCount ? item.finishCount : 0;
                      break;
                    case "未实施":
                      list.dueCount = item.noImplementCount
                        ? item.noImplementCount
                        : 0;
                      break;
                    case "正实施":
                      list.dueCount = item.implementCount
                        ? item.implementCount
                        : 0;
                      break;
                  }
                });
              } else if (item.riskLevel == "部门级") {
                this.sectionData.forEach((list) => {
                  switch (list.name) {
                    case "总数":
                      list.dueCount = item.num ? item.num : 0;
                      break;
                    case "已攻克":
                      list.dueCount = item.finishCount ? item.finishCount : 0;
                      break;
                    case "未实施":
                      list.dueCount = item.noImplementCount
                        ? item.noImplementCount
                        : 0;
                      break;
                    case "正实施":
                      list.dueCount = item.implementCount
                        ? item.implementCount
                        : 0;
                      break;
                  }
                });
              } else if (item.riskLevel == "集团级") {
                this.groupData.forEach((list) => {
                  switch (list.name) {
                    case "总数":
                      list.dueCount = item.num ? item.num : 0;
                      break;
                    case "已攻克":
                      list.dueCount = item.finishCount ? item.finishCount : 0;
                      break;
                    case "未实施":
                      list.dueCount = item.noImplementCount
                        ? item.noImplementCount
                        : 0;
                      break;
                    case "正实施":
                      list.dueCount = item.implementCount
                        ? item.implementCount
                        : 0;
                      break;
                  }
                });
              }
            });
          }
        }
      });
    },
    // 二级弹窗
    leveClick(val, data) {
      let date = new Date();
      let year = date.getFullYear();
      if (data.name == "总数") {
        this.titleTable = val + "风险列表 " + "(" + year + "年总数" + ")";
      } else {
        this.titleTable = val + data.name + "风险列表";
      }
      this.$refs.modalTable.handleOpen("left", "风险等级");
      if (data.name == "正实施") {
        let name = "安全可控";
        let param = {
          riskTypeChildId: "",
          riskTypeId: "",
          siteIdList: [],
          status: name,
          riskLevel: val,
          pageNum: 1,
          pageSize: 5,
        };
        this.leftOneParams = {
          riskLevel: val,
          status: name,
        };
        getRiskCountPage(param)
          .then((res) => {
            if (res.code == 0) {
              this.tableList = res.data.records;
              this.totalData = res.data.count;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (data.name != "总数") {
        let param = {
          riskTypeChildId: "",
          riskTypeId: "",
          siteIdList: [],
          status: data.name,
          riskLevel: val,
          pageNum: 1,
          pageSize: 5,
        };
        this.leftOneParams = {
          riskLevel: val,
          status: data.name,
        };
        getRiskCountPage(param)
          .then((res) => {
            if (res.code == 0) {
              this.tableList = res.data.records;
              this.totalData = res.data.count;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (data.name == "总数") {
        let param = {
          riskTypeChildId: "",
          riskTypeId: "",
          siteIdList: [],
          status: "",
          riskLevel: val,
          pageNum: 1,
          pageSize: 5,
        };
        this.leftOneParams = {
          riskLevel: val,
          status: "",
        };
        getRiskCountPage(param)
          .then((res) => {
            if (res.code == 0) {
              this.tableList = res.data.records;
              this.totalData = res.data.count;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.tableList = [];
        this.totalData = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.weather-container {
  height: 100%;
  .list {
    padding: 5px 0px 0px 5px;
    box-sizing: border-box;
    height: 33%;
    background: #0e254f;
    margin-bottom: 2px;
    .headline {
      font-size: 14px;
      font-weight: bold;
      color: #43a5e9;
    }
    .list-item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .item-group {
      width: 25%;
      cursor: pointer;
      .item-group-count {
        display: flex;
        justify-content: center;
        // align-items: center;
        font-size: 14px;
        font-weight: bold;
      }
      .item-group-name {
        display: flex;
        justify-content: center;
        // align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.6;
      }
    }
  }
}
</style>
