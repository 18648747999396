<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
             label-width="120px" class="demo-model">

      <el-form-item label="单位类型" prop="unitType">
        <el-select v-model="ruleForm.unitType" placeholder="单位类型" @change="companyChange"
                   style="width: 100%">
          <el-option
              v-for="item in examCompany"
              :key="item.value"
              :label="item.label"
              :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="用户角色" prop="roleId">
        <el-select v-model="ruleForm.roleId" placeholder="用户角色"
                   style="width: 100%">
          <el-option
              v-for="item in roleList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排查周期" prop="cycle">
        <el-select v-model="ruleForm.cycle" placeholder="排查周期"
                   style="width: 100%">
          <el-option
              v-for="item in cycleList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="次数" prop="frequency">
        <el-input v-model="ruleForm.frequency" placeholder="次数"></el-input>
      </el-form-item>

      <el-form-item label="隐患等级" prop="hiddenLevel">
        <el-select v-model="hiddenLevelList" placeholder="隐患等级" multiple
                   style="width: 100%">
          <el-option
              v-for="item in dangerLevel"
              :key="item.value"
              :label="item.label"
              :value="item.label">
          </el-option>
        </el-select>
      </el-form-item>

    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelModal">取 消</el-button>
      <el-button type="primary" @click="submit('0')">确 定</el-button>
    </div>
  </div>
</template>

<script>
import {getDictData} from "@/api/dict";
import {screeningDetail, screeningAdd, screeningEdit} from "@/api/hdanger";
import {getRoleApi} from "@/api/role";

export default {
  name: "criteriaModal",
  props : {
    operation : {
      default : 'add'
    },
    editData : {
      default : () => {}
    }
  },
  data(){
    return {
      ruleForm : {
        unitType : '',
        roleId : '',
        cycle : '',
        frequency : '',
        hiddenLevel : '',
      },
      rules : {
        unitType: [
          { required: true, message: '单位类型不能为空', trigger: 'change' }
        ],
        roleId: [
          { required: true, message: '用户角色不能为空', trigger: 'change' }
        ],
        cycle: [
          { required: true, message: '排查周期不能为空', trigger: 'change' }
        ],
        frequency: [
          { required: true, message: '次数不能为空', trigger: 'blur' }
        ],
        /*hiddenLevel: [
          { required: true, message: '隐患等级不能为空', trigger: 'change' }
        ],*/
      },
      examCompany : [],
      roleList : [],
      cycleList : [
        {
          label : '日',
          value : 1
        },
        {
          label : '周',
          value : 2
        },
        {
          label : '月',
          value : 3
        },
        {
          label : '季',
          value : 4
        },
      ],
      dangerLevel : [],
      hiddenLevelList : [],
    }
  },
  created() {
    this.getSelectList();
    if(this.operation === 'edit'){
      this.getDetailData();
    }
  },
  methods : {
    getSelectList() {
      let dictArray = ["unitType", "cycle","hdLevel"];
      dictArray.forEach((item) => {
        this.getDictList(item);
      });
    },
    getDictList(label) {
      getDictData(label).then((res) => {
        switch (label) {
          case "unitType":
            this.examCompany = res.data;
            break;
          /*case "cycle":
            this.cycleList = res.data;
            break;*/
          case "hdLevel":
            this.dangerLevel = res.data;
            break;
        }
      });
    },
    companyChange(){
      this.ruleForm.roleId = null;
      this.getRoleList();
    },
    getRoleList(){
      getRoleApi({ unitType: this.ruleForm.unitType })
          .then((res) => {
            if (res.code == 0) {
              res.data.forEach((element) => {
                this.roleList.push({
                  label: element.roleName,
                  value: element.id,
                });
              });
            }
          })
          .catch((res) => {
            console.log(res.messafe);
          });
    },
    getDetailData(){
      screeningDetail(this.editData.id).then(res => {
        if(res.code == 0){
          this.ruleForm = res.data;
          this.hiddenLevelList = this.ruleForm.hiddenLevel.split(',')
          if(this.operation === 'edit'){
            this.getRoleList();
            this.ruleForm.roleId = Number(this.ruleForm.roleId)
          }
        }
      })
    },
    cancelModal(){
      this.$emit('cancelModal')
    },
    submit(){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.hiddenLevelList.length === 0){
            this.$message.error('隐患等级不能为空');
            return
          }
          this.ruleForm.createBy = JSON.parse(localStorage.getItem('user')).realName;
          this.ruleForm.hiddenLevel = this.hiddenLevelList.toString();
          if (this.operation === 'add') {
            screeningAdd(this.ruleForm).then(res => {
              if(res.code == 0){
                this.$message.success('操作成功');
                this.$emit('successModal');
              }else{
                this.$message.error(res.msg);
              }
            })
          }else{
            screeningEdit(this.ruleForm).then(res => {
              if(res.code == 0){
                this.$message.success('操作成功');
                this.$emit('successModal');
              }else{
                this.$message.error(res.msg);
              }
            })
          }
        } else {
          this.$message.error('请正确填写');
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
