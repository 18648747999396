<template>
  <div>
    <div v-show="titleName == 'one'">
      <ProblemFillForm
        type="prode"
        :row-fx-info="rowFxInfo"
        :hiddenBtn="true"
      ></ProblemFillForm>
    </div>
    <el-form
      :model="formData"
      :disabled="isEdit != 'edit'"
      v-show="titleName == 'two'"
      label-width="130px"
      :rules="formDataRules"
      ref="formData"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item label="整改单位">
            <el-input
              v-model="formData.rectificationUnitName"
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="整改人">
            <el-input
              v-model="formData.rectificationPerson"
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="整改时间" prop="rectificationTime">
            <el-date-picker
              style="width: 100%"
              clearable
              v-model="formData.rectificationTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="整改过程描述" prop="rectificationDescribe">
        <el-input
          v-model="formData.rectificationDescribe"
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item label="整改后图片">
        <ImageUploader
          v-if="isEdit == 'edit'"
          @changeAll="imgUploder"
        ></ImageUploader>
        <div v-else>
          <el-image
            v-for="(item, index) in getImgList"
            :key="index"
            style="width: 130px; height: 130px"
            :src="item"
            :preview-src-list="[item]"
          >
          </el-image>
        </div>
      </el-form-item>
      <el-form-item label="附件">
        <FileUploader
          v-if="isEdit == 'edit'"
          @uploadChange="handleFileUpload"
          @delChange="handleFileDel"
          :value="fileList"
        ></FileUploader>
        <div v-else class="process_right">
          <p v-for="(item, index) in getFileList" :key="index">
            {{ item.fileName
            }}<span class="view" @click="viewFile(item)">下载</span>
          </p>
        </div>
      </el-form-item>
    </el-form>
    <el-form
      :model="formDataThree"
      v-show="titleName == 'three'"
      :disabled="isEdit != 'check'"
      label-width="130px"
      :rules="formDataThreeRules"
      ref="formDataThree"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item label="验收单位">
            <el-input v-model="formDataThree.checkUnitName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="验收人">
            <el-input v-model="formDataThree.checkPerson" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="验收时间" prop="checkTime">
            <el-date-picker
              style="width: 100%"
              clearable
              v-model="formDataThree.checkTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="验收过程描述" prop="checkOpinion">
        <el-input
          v-model="formDataThree.checkOpinion"
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item label="附件">
        <FileUploader
          v-if="isEdit == 'check'"
          @uploadChange="handleFileUploadThree"
          @delChange="handleFileDelThree"
          :value="fileListThree"
        ></FileUploader>
        <div v-else class="process_right">
          <span v-for="(item, index) in getFileListThree" :key="index"
            >{{ item.fileName
            }}<span class="view" @click="viewFile(item)"
              >下载</span
            ></span
          >
        </div>
      </el-form-item>
    </el-form>
    <div class="dialogFooter" v-if="titleName != 'one'">
      <el-button
        size="medium"
        v-show="titleName == 'two' && isEdit == 'edit'"
        @click="cancel"
        >取 消</el-button
      >
      <el-button
        size="medium"
        v-show="titleName == 'two' && isEdit == 'edit'"
        type="primary"
        @click="submit"
        >确 定</el-button
      >
      <el-button
        size="medium"
        v-show="titleName == 'three' && isEdit == 'check'"
        type="danger"
        @click="check(0)"
        >驳 回</el-button
      >
      <el-button
        size="medium"
        v-show="titleName == 'three' && isEdit == 'check'"
        type="primary"
        @click="check(1)"
        >通 过</el-button
      >
    </div>
    <filePreview ref="fileView" :src="fileUrl" :model="false"></filePreview>
  </div>
</template>

<script>
import filePreview from "@/components/Uploader/filePreview.vue";
import ImageUploader from "../../../components/imageUploader";
import FileUploader from "@/components/Uploader/FileUploader.vue";
import ProblemFillForm from "@/views/RiskManagement/System/RiskProcess/components/ProblemFillForm";
import { setProblem, setCheck } from "@/api/risk";
export default {
  components: {
    ImageUploader,
    FileUploader,
    ProblemFillForm,
    filePreview,
  },
  props: {
    titleName: {
      type: String,
      default: "one",
    },
    rowFxInfo: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: String,
      default: "view",
    },
    // 问题处置的回显数据
    setData: {
      type: Object,
      default: () => {},
    },
    // 处置验收的回显数据
    checkData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        rectificationUnitName: "",
        rectificationUnitUuid: "",
        rectificationPerson: "",
        rectificationPersonUuid: "",
        rectificationDescribe: "",
        riskDeafUuid: "",
        rectificationTime: "",
      },
      formDataThree: {
        checkUnitName: "",
        checkUnitUuid: "",
        checkPerson: "",
        checkPersonUuid: "",
        checkOpinion: "",
        riskDeafUuid: "",
        checkTime: "",
      },
      formDataRules: {
        rectificationTime: [
          { required: true, message: "请选择整改时间", trigger: "change" },
        ],
        rectificationDescribe: [
          { required: true, message: "请输入整改过程描述", trigger: "change" },
        ],
      },
      formDataThreeRules: {
        checkTime: [
          { required: true, message: "请选择验收时间", trigger: "change" },
        ],
        checkOpinion: [
          { required: true, message: "请输入整改过程描述", trigger: "change" },
        ],
      },
      imgList: [],
      fileList: [],
      fileListThree: [],

      getImgList: [],
      getFileList: [],
      getFileListThree: [],
      fileUrl: "",
    };
  },
  mounted() {
    let person = JSON.parse(localStorage.getItem("user"));
    this.formData.rectificationUnitName = person.departmentName;
    this.formData.id = person.id;
    this.formData.rectificationUnitUuid = person.departmentId;
    this.formData.rectificationPerson = person.realName;
    this.formData.rectificationPersonUuid = person.uuid;
    this.formData.riskDeafUuid = this.rowFxInfo.riskDeafUuid;
    this.formDataThree.checkUnitName = person.departmentName;
    this.formDataThree.checkUnitUuid = person.departmentId;
    this.formDataThree.checkPerson = person.realName;
    this.formDataThree.checkPersonUuid = person.uuid;
    this.formDataThree.riskDeafUuid = this.rowFxInfo.riskDeafUuid;
    let time = new Date();
    let year = time.getFullYear();
    let month = time.getMonth() + 1;
    let day = time.getDate();
    this.formData.rectificationTime = `${year}-${month}-${day}`;
    this.formDataThree.checkTime = `${year}-${month}-${day}`;
    if (this.setData.rectificationPerson) {
      this.formData.rectificationUnitName = this.setData.rectificationUnitName;
      this.formData.rectificationUnitUuid = this.setData.rectificationUnitUuid;
      this.formData.rectificationPerson = this.setData.rectificationPerson;
      this.formData.rectificationPersonUuid = this.setData.rectificationPersonUuid;
      this.formData.rectificationTime = this.setData.rectificationTime;
      this.formData.rectificationDescribe = this.setData.rectificationDescribe;
    }
    if (this.checkData.checkUnitName) {
      this.formDataThree.checkUnitName = this.checkData.checkUnitName;
      this.formDataThree.checkUnitUuid = this.checkData.checkUnitUuid;
      this.formDataThree.checkPerson = this.checkData.checkPerson;
      this.formDataThree.checkPersonUuid = this.checkData.checkPersonUuid;
      this.formDataThree.checkTime = this.checkData.checkTime;
      this.formDataThree.checkOpinion = this.checkData.checkOpinion;
    }
    // 回显文件
    if (this.setData.fileList && this.setData.fileList.length) {
      let imgList = this.setData.fileList.filter((i) => {
        return i.fileType == 0;
      });
      imgList.length
        ? imgList.forEach((i) => {
            this.getImgList.push(i.fileUrl);
          })
        : "";

      let fileList = this.setData.fileList.filter((i) => {
        return i.fileType == 2;
      });
      fileList.length
        ? fileList.forEach((i) => {
            this.getFileList.push(i);
          })
        : "";
    }

    this.checkData.fileList && this.checkData.fileList.length
      ? this.checkData.fileList.forEach((i) => {
          this.getFileListThree.push(i);
        })
      : "";
  },
  methods: {
    // 下载
    viewFile(url) {
      let fileUrl = url.fileUrl.split(',')[1]
      this.downLoadFile(fileUrl, url.fileName);
      // console.log(url);
      // this.fileUrl = url;
      // this.$refs.fileView.handleOpen();
    },
    // 图片上传回调
    imgUploder(val) {
      console.log(val);
      this.imgList = val;
    },
    handleFileUpload(e) {
      console.log(e);
      this.fileList.push({
        fileName: e.name,
        name: e.name,
        fileUrl: e.url + "," + e.realUrl,
        url: e.url,
        fileType: 2,
      });
    },
    handleFileDel(e) {
      let index = this.fileList.findIndex((v) => v.fileUrl === e.url);
      this.fileList.splice(index, 1);
    },
    handleFileUploadThree(e) {
      this.fileListThree.push({
        fileName: e.name,
        name: e.name,
        fileUrl: e.url + "," + e.realUrl,
        url: e.url,
        fileType: 2,
      });
    },
    handleFileDelThree(e) {
      let index = this.fileListThree.findIndex((v) => v.fileUrl === e.url);
      this.fileListThree.splice(index, 1);
    },
    cancel() {
      this.$emit("CloseData");
    },
    submit() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          let fileList = [];
          if (this.imgList.length) {
            this.imgList.forEach((i) => {
              let obj = {};
              obj.fileUrl = i.url;
              obj.fileName = i.name;
              obj.fileType = 0;
              fileList.push(obj);
            });
          }
          let data = { ...this.formData };
          data.fileList = [...fileList, ...this.fileList];
          data.problemId = this.rowFxInfo.id;
          console.log(data);
          setProblem(data).then((res) => {
            if (res.code == 0) {
              this.$message.success("操作成功");
              this.$emit("CloseData", true);
            } else {
              this.$message.error("操作失败，" + res.message);
            }
          });
        }
      });
    },
    check(val) {
      this.$refs.formDataThree.validate((valid) => {
        if (valid) {
          let data = { ...this.formDataThree };
          data.fileList = this.fileListThree;
          data.problemId = this.rowFxInfo.id;
          data.checkPass = val ? true : false; //通过或者是驳回
          setCheck(data).then((res) => {
            if (res.code == 0) {
              this.$message.success("操作成功");
              this.$emit("CloseData", true);
            } else {
              this.$message.error("操作失败，" + res.message);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  text-align: center;
}
.el-image {
  margin-right: 10px;
}
.process_right {
  .view {
    color: #409eff;
    cursor: pointer;
    margin-left: 20px;
  }
}
</style>