<template>
    <div>
      <el-form ref="form" :model="form" label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="审批人">
              <el-input v-model="form.approvalPeople" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审批时间">
              <el-input v-model="form.approvalTime" disabled></el-input>
              <!--            <el-input v-model="form.name"></el-input>-->
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="审批意见">
              <el-input type="textarea" v-model="form.rejectReason"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="agree">同意</el-button>
        <el-button type="primary" @click="reject">驳回</el-button>
      </div>
    </div>
</template>

<script>
import {batchApprove} from "@/api/response";

export default {
  name: "batchModal",
  props : {
    responseList : {
      type : Array,
      default : () => []
    },
    responseRecordId : {
      default : ''
    }
  },
  data(){
    return {
      form : {
        approvalPeople : '',
        approvalTime : '',
        rejectReason : '',
        approvalState : '',
      }
    }
  },
  created() {
    this.form.approvalPeople = JSON.parse(localStorage.getItem('user')).realName;
    console.log(this.getNowTime())
    this.form.approvalTime = this.getNowTime();
  },
  methods : {
    agree(){
      let params = {
        approvalState : 1,
        responseRecordId : this.responseRecordId,
        rejectReason : this.form.rejectReason,
        currentIdList : this.responseList.map(item => item.id)
      }
      batchApprove(params).then(res => {
        if(res.code == 0){
          this.$message.success('操作成功');
          this.$emit('successAgree')
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    reject(){
      let params = {
        approvalState : 2,
        responseRecordId : this.responseRecordId,
        rejectReason : this.form.rejectReason,
        currentIdList : this.responseList.map(item => item.id)
      }
      batchApprove(params).then(res => {
        if(res.code == 0){
          this.$message.success('操作成功');
          this.$emit('successAgree')
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    getNowTime() {
      var date = new Date();
      //年 getFullYear()：四位数字返回年份
      var year = date.getFullYear(); //getFullYear()代替getYear()
      //月 getMonth()：0 ~ 11
      var month = date.getMonth() + 1;
      //日 getDate()：(1 ~ 31)
      var day = date.getDate();
      //时 getHours()：(0 ~ 23)
      var hour = date.getHours();
      //分 getMinutes()： (0 ~ 59)
      var minute = date.getMinutes();
      //秒 getSeconds()：(0 ~ 59)
      var second = date.getSeconds();
      var time = year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
      // var today = year + '-' + this.addZero(month) + '-' + this.addZero(day);
      return time;
    },
    addZero(s) {
      return s < 10 ? ('0' + s) : s;
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer{
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
</style>
