<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" :title="title">
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="100px"
      class="demo-model"
    >
      <el-form-item label="事故/件类别" prop="category">
        <el-select v-model="model.category" placeholder="选择类型" clearable>
          <el-option
            :label="data.label"
            :value="data.id"
            v-for="(data, index) in caseList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="附件">
        <FileUploader
          @uploadChange="handleFileUpload"
          @delChange="handleFileDel"
          :value="fileList"
        ></FileUploader>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import FileUploader from "@/components/Uploader/FileUploader.vue";
import { emergencyResponse } from "@/api/response";
export default {
  components: {
    systemModal,
    FileUploader,
  },
  props: { caseList: {} },
  data() {
    return {
      title: "新增",
      model: {
        name: "",
        address: "",
        type: "",
        level: "",
        time: "",
        imageUrl: "",
      },
      fileList: [],
      loading: false,
      rules: {
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        category: [{ required: true, message: "请选择", trigger: "change" }],
        summary: [{ required: true, message: "请输入", trigger: "blur" }],
        fullPath: [{ required: true, message: "请上传", trigger: "change" }],
      },
      lineName: [],
    };
  },
  mounted() {},
  methods: {
    handleAdd(record) {
      this.title = "编辑";
      this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
      if (this.model.category) {
        this.model.category = parseInt(this.model.category);
      }
      if (this.model.enclosure) {
        this.fileList = [
          {
            name: "事件报告",
            url: this.model.enclosure,
          },
        ];
      } else {
        this.fileList = [];
      }

      this.handleEdit();
    },
    handleName(e) {
      console.log(e);
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    getlabel() {
      if (this.model.lineSite.length != 0) {
        this.lineName = this.$refs["linecas"].getCheckedNodes()[0].pathLabels;
      }
      console.log(this.lineName);
    },
    urlchange(url) {
      this.model.imageUrl = url;
    },
    handleFileUpload({ name, url }) {
      console.log(name);
      this.fileList.push({ name, url });
      if (this.fileList.length > 1) {
        this.fileList = [this.fileList[1]];
      }
      this.model.realName = "事件报告";
      this.model.enclosure = url;
    },
    handleFileDel({ name, url }) {
      console.log(name);
      let index = this.fileList.findIndex((v) => v.url === url);
      this.fileList.splice(index, 1);
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true;

          let params = {
            id: this.model.id,
            uuid: this.model.uuid,
            enclosure: this.model.enclosure,
            category: this.model.category,
          };
          emergencyResponse(params).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "修改成功！",
                type: "success",
              });
              this.$emit("getdata");
              this.$refs.formModal.handleClose();
            } else {
              this.$message.error("修改失败！");
            }
            this.loading = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
