import { httpInit } from "./http";
const http = httpInit("/gzdt-proof");
const http2 = httpInit("/gzdt-response");

// 按关注重点类别分类统计关注重点数量
export const getStatistics = params => http.get("/proof/statistics", params);

// 查询全部关注重点
export const getProofCoreList = params =>
  http.get("/api/proofCore/list", params);

//查询最新的广东省气象预警信息
export const getActiveWarn = params =>
  http.get("/api/third/weather/active_warn", params);

//查询最新一小时的广东省气温信息
export const getHourTemp = params =>
  http.get("/api/third/weather/hour_temp", params);

//查询最新的广东省气象补充信息
export const getCityLive = params =>
  http.get("/api/third/weather/city_live", params);

//添加新的三方关注重点到系统，须验证权限：+proof+info+1
export const proolInfo = params => http.post("/proof/info", params);

//添加新的三方关注重点到系统，须验证权限: +proof+info+2
export const proolInfoPut = (id, params) =>
  http.put(`/proof/info/${id}`, params);

//逻辑删除指定的三防关注重点。
export const proolDelete = params =>
  http.delete(`/proof/info/${params.id}`, params);

//应急响应统计
export const getResStatistic = params =>
  http2.get("/api/response/statistic", params);

export const getResStatistics = params =>
  http2.post("/response/statistic", params);

//根据线路工点id查询应急响应
export const getResDetailBySiteId = params =>
  http2.get(`/api/response/statistic/${params}`);

//根据工点查询最新关注类型
export const getproofid = params => http.get(`/proof/getType/${params.siteId}`);
// 查询全部关注重点
export const ProofCoreList = params =>
  http.get(`/api/proofCore/list/${params}`);

// // 天气信息接口
export const getData = (params) => http.get(`/api/third/weather/opengd/city_live`, params);

export const getwarnData = (params) => http.get(`/api/third/weather/opengd/city_warn`, params);

//分页查询三防关注重点列表
export const PagingCoreList = params =>
  http.get(`/proof/list/${params.uuid}/${params.type}/${params.siteId}/${params.alterFlag}/${params.page}/${params.size}`);


//分页查询三防关注重点列表 工作台的增删改查页面 
export const ProofNewList = (params) => http.post(`/proof/new/list/info`, params);

//新增三防关注重点列表 工作台的增删改查页面 
export const ProofNewAdd = (params) => http.post(`/proof/new/info`, params);

//详情三防关注重点列表 工作台的增删改查页面 
export const ProofNewDetail = (id) => http.get(`/proof/new/info/${id}`);

export const ProofNewDelete = (id) => http.delete(`/proof/new/info/${id}`);
//修改三防关注重点列表 工作台的增删改查页面 
export const ProofNewUpdate = (data) => http.put
  (`/proof/new/info/${data.id}`, data);



//历史操作三防关注重点列表 工作台的增删改查页面 
export const ProofNewHistory = (params) => http.post(`/proof/log/list`, params)