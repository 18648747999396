<template>
  <div>
    <el-radio-group v-model="tabModel" @change="radioChange">
      <el-radio-button
        v-for="item in radioData"
        :key="item.value"
        :label="item.label"
      ></el-radio-button>
    </el-radio-group>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="{
        background: '#0E2553',
        color: '#ffffff',
        border: '1px solid #193F71',
      }"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-table-column
        type="index"
        label="序号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        v-for="item in columns"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        :min-width="item.minWidth"
        show-overflow-tooltip
        align="center"
      >
        <!-- <template slot-scope="scope">
          <div v-if="item.prop == 'state'">
            <span v-if="scope.row.state == 'WAIT_ALLOCATION'"
              >待分配配送人员
            </span>
            <span v-if="scope.row.state == 'IN_PROGRESS'"
              >{{ tabModel == "物资" ? "配送中" : "进行中" }}
            </span>
            <span v-if="scope.row.state == 'FINISH'">已到位</span>
          </div>
          <div v-else>
            {{ scope.row[item.prop] }}
          </div>
        </template> -->
      </el-table-column>
    </el-table>
    <!-- <div class="flex justify-center mt-4">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
import { materialsRecord, userRecord } from "@/api/eres";
export default {
  props: {
    detsiel: {
      type: Object,
    },
    tabradio: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      tableData: [],
      columns: [
        { prop: "sourceName", label: "被调配方", minWidth: 60 },
        { prop: "materialsName", label: "调配内容", minWidth: 60 },
        { prop: "num", label: "调配内容数量", minWidth: 90 },
        { prop: "deployDate", label: "调配时间", minWidth: 60 },
        { prop: "chargeName", label: "被调配方负责人", minWidth: 100 },
        { prop: "chargeMobile", label: "负责人联系方式", minWidth: 100 },
        { prop: "state", label: "状态", minWidth: 50 },
        { prop: "deliveryName", label: "配送人", minWidth: 50 },
        { prop: "deliveryMobile", label: "配送人联系方式", minWidth: 100 },
      ],
      tabModel: "物资",
      radioData: [
        {
          label: "物资",
          value: 1,
        },
        {
          label: "人员",
          value: 2,
        },
      ],
      page: 1,
      size: 1000000,
      total: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.tabradio, this.tabModel, "Contingency");
      this.tabModel = this.tabradio;
    });
  },
  methods: {
    //物资表格数据
    getmaterialsListData() {
      console.log(this.tabModel, this.detsiel, this.detsiel.uuid);
      let parms = {
        id: this.detsiel.uuid,
        // id: 2,
        page: this.page,
        size: this.size,
      };
      if (this.tabModel == "物资") {
        materialsRecord(parms).then((res) => {
          console.log(res);
          this.tableData = res.data;
        });
      } else {
        userRecord(parms).then((res) => {
          console.log(res);
          this.tableData = res.data;
        });
      }
    },
    /*     //物资表格数据
    getuserListData() {
      let parms = {
        // id: this.detsiel.id,
        id: 2,
        page: this.page,
        size: this.size,
      };
      userRecord(parms).then((res) => {
        console.log(res);
        this.tableData = res.data.records;
      });
    }, */
    // // 分页
    // handleCurrentChange(val) {
    //   this.page = val;
    //   if (this.tabModel == "物资") {
    //     this.getmaterialsListData();
    //   } else {
    //     this.getuserListData();
    //   }
    // },
    //物资 人员  切换
    radioChange(val) {
      console.log(val);
      if (val == "物资") {
        this.columns = [
          { prop: "sourceName", label: "被调配方", minWidth: 60 },
          { prop: "materialsNameList", label: "调配内容", minWidth: 60 },
          { prop: "deployDate", label: "调配时间", minWidth: 60 },
          { prop: "chargeName", label: "被调配方负责人", minWidth: 100 },
          { prop: "chargeMobile", label: "负责人联系方式", minWidth: 100 },
          { prop: "state", label: "状态", minWidth: 50 },
          { prop: "deliveryName", label: "配送人", minWidth: 50 },
          { prop: "deliveryMobile", label: "配送人联系方式", minWidth: 100 },
        ];
        this.getmaterialsListData();
      } else if (val == "人员") {
        this.columns = [
          { prop: "rescueTeamName", label: "调配队伍", minWidth: 60 },
          { prop: "rescueName", label: "调配人员", minWidth: 60 },
          { prop: "rescueMobile", label: "联系方式", minWidth: 90 },
          { prop: "deployDate", label: "调配时间", minWidth: 60 },
          { prop: "chargeName", label: "被调配方负责人", minWidth: 100 },
          { prop: "chargeMobile", label: "负责人联系方式", minWidth: 100 },
          { prop: "state", label: "状态", minWidth: 50 },
        ];
        this.getmaterialsListData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__header-wrapper {
  background-color: #081742;
}

::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
  border: 1px solid #002e75;
  background-color: #081742;
  color: white;
  margin-top: 20px;
}
::v-deep .el-table tbody tr:hover > td {
  background-color: #081742 !important;
  border-color: #143363;
}
// ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
//   background-color: #002e75;
// }
</style>
