<template>
  <div style="wieth: 100%; height: 100%" class="center-below">
    <DangerousBig class="dangerousBig"></DangerousBig>
    <EarlyWarning class="EarlyWarning" ref="EarlyWarning"></EarlyWarning>
    <RiskManage class="riskManage" ref="RiskManage"></RiskManage>
  </div>
</template>

<script>
import DangerousBig from "./dangerousBig";
import RiskManage from "./riskManage";
import EarlyWarning from "./earlyWarning";
export default {
  name: "routeList",
  components: { DangerousBig, RiskManage, EarlyWarning },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    fluData(res) {
      let that = this;
      that.$refs.RealTime.getwsData(res);
      that.$refs.Cumulative.getwsData(res);
    },
  },
};
</script>

<style lang="scss" scoped>
.center-below {
  height: 100%;
  display: grid;
  margin: 0 5px;
  grid-template-columns: 32% calc(37% - 20px) 31%;
  grid-gap: 5px;
  //   grid-row-gap: 10px;
}
</style>
