<template>
  <div class="home">
    <div :class="this.$route.query.rte==222?'contenert':'content' ">
      <div class="content-wrapper">
        <!-- <LeftIndex class="left-panel" ref="LeftIndex"></LeftIndex> -->
        <MapIndex class="center-panel" ref="MapIndex"></MapIndex>
        <!-- <RightIndex class="right-panel" ref="RightIndex"></RightIndex> -->
      </div>
    </div>
  </div>
</template>
<script>
import MapIndex from "@/views/Contingency/HomePage/Map/index";
// import LeftIndex from "@/views/HomePage/leftPage/index";
// import RightIndex from "@/views/HomePage/rightPage/index";

export default {
  name: "HomePage",
  components: { MapIndex },
  data() {
    return {
      path: "",
      socket: "",
    };
  },
   created() {
    console.log(this.$route.query.rte)
    if(this.$route.query.rte==222){
     this.$EventBus.$emit('secrty',1)
    }
   },
  computed: {},
  methods: {
    init() {
      let that = this;
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket

        that.socket = new WebSocket(that.path);
        that.$global.setWs(that.socket);
        // 监听socket连接
        that.socket.onopen = that.open;
        // 监听socket错误信息
        that.socket.onerror = that.error;
        // 监听socket消息
        that.socket.onmessage = that.getMessage;
        // 关闭连接
        that.socket.onclose = that.close;
      }
      that.getMessage();
    },
    open() {
      console.log("socket连接成功");
    },
    error() {
      console.log("连接错误");
    },

    close() {
      console.log("socket已经关闭");
    },
    getMessage() {
      let that = this;
      that.$global.ws.onmessage = function (res) {
        that.$refs.MapIndex.getwsData(res);
        that.$refs.LeftIndex.fluData(res);
        that.$refs.RightIndex.fluData(res);
      };
    },
  },
  destroyed() {
    // 销毁ws
    // this.socket.close();
  },
};
</script>
<style lang="scss" scoped>
.home {
  ::v-deep {
    // 首页布局
    .content {
      width: 100%;
      height: calc(100vh - 88px - 0.25rem);
      .el-dialog {
        background: url("../../../assets/card-bg1.png") repeat 99%;
        background-size: 100% 100%;
        color: #fff;
      }
      .el-dialog,
      .el-pager li {
        border-radius: 10px;
        color: #fff;
      }
      .el-dialog__header {
        text-align: center;
      }
      .el-dialog__wrapper {
        background: rgba(0, 0, 0, 0.8);
      }
      .el-dialog__title {
        color: #fff;
      }
      .el-form-item__label {
        color: #fff;
      }
      // .el-input__inner {
      //   background-color: rgba(2, 2, 6, 1);
      //   color: #fff;
      //   border-radius: 4px;
      //   border: 1px solid #4d4d4d;
      // }
      .el-dialog__headerbtn .el-dialog__close {
        color: #f9aa33;
      }
      .content-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
    .contenert{
       width: 100%;
      height: 100vh;
       .el-dialog {
        background: url("../../../assets/card-bg1.png") repeat 99%;
        background-size: 100% 100%;
        color: #fff;
      }
      .el-dialog,
      .el-pager li {
        border-radius: 10px;
        color: #fff;
      }
      .el-dialog__header {
        text-align: center;
      }
      .el-dialog__wrapper {
        background: rgba(0, 0, 0, 0.8);
      }
      .el-dialog__title {
        color: #fff;
      }
      .el-form-item__label {
        color: #fff;
      }
      // .el-input__inner {
      //   background-color: rgba(2, 2, 6, 1);
      //   color: #fff;
      //   border-radius: 4px;
      //   border: 1px solid #4d4d4d;
      // }
      .el-dialog__headerbtn .el-dialog__close {
        color: #f9aa33;
      }
      .content-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
  }
  .left-panel {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    width: 20.8%;
    height: 100%;
  }
  .right-panel {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    width: 20.8%;
    height: 100%;
  }
  .center-panel {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
