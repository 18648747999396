<template>
  <div>
    <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="120px"
    >
      <el-form-item label="工点名称" prop="label">
        <el-input v-model="ruleForm.siteName" disabled></el-input>
      </el-form-item>
      <el-form-item label="演练内容" prop="label">
        <el-input v-model="ruleForm.planContent" disabled></el-input>
      </el-form-item>
      <el-form-item label="演练类型" prop="label">
        <el-input v-model="ruleForm.drillType" disabled></el-input>
      </el-form-item>
      <el-form-item label="演练科目" prop="label">
        <el-input v-model="ruleForm.planSubject" disabled></el-input>
      </el-form-item>
      <el-form-item label="演练级别" prop="label">
        <el-input v-model="ruleForm.drillLevel" disabled></el-input>
      </el-form-item>
      <el-form-item label="演练地点" prop="label">
        <el-input v-model="ruleForm.address" disabled></el-input>
      </el-form-item>
      <el-form-item label="拟参与人数" prop="label">
        <el-input v-model="ruleForm.planJoinCount" disabled></el-input>
      </el-form-item>
      <el-form-item label="计划演练时间" prop="label">
        <el-input v-model="ruleForm.planDate" disabled></el-input>
      </el-form-item>
      <el-form-item label="发起人" prop="label">
        <el-input v-model="ruleForm.owner.name" disabled></el-input>
      </el-form-item>
      <el-form-item label="发起时间" prop="label">
        <el-input v-model="ruleForm.created" disabled></el-input>
      </el-form-item>
      <el-form-item label="附件" prop="label">
<!--        <el-input v-model="ruleForm.siteName" disabled></el-input>-->
        <div class="file-item" @click="downFile(ruleForm.fileUrl)">
          {{ ruleForm.fileName }}
        </div>
      </el-form-item>
      <el-form-item label="审核意见" prop="label" v-if="ruleForm.approvalResult">
        <el-input v-model="ruleForm.approvalResult" disabled></el-input>
      </el-form-item>
      <el-form-item label="原因" prop="label">
        <el-input v-model="ruleForm.opinion" disabled></el-input>
      </el-form-item>
      <el-form-item label="审核人" prop="label">
        <el-input v-model="ruleForm.relationPerson" disabled></el-input>
      </el-form-item>
      <el-form-item label="审核时间" prop="label">
        <el-input v-model="ruleForm.relationTime" disabled></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {getByLogId} from "@/api/stand";

export default {
  name: "planWithdraw",
  props : {
    rowData : {
      default : () => {}
    }
  },
  data(){
    return {
      ruleForm : {}
    }
  },
  created() {
    this.getDetailData();
  },
  methods : {
    getDetailData(){
      getByLogId(this.rowData.eventId).then(res => {
        if(res.code == 0){
          this.ruleForm = res.data;
          var arr = this.ruleForm.fileUrl.split('/')
          this.ruleForm.fileName = arr[arr.length - 1];
          this.ruleForm.approvalResult = this.ruleForm.approvalResult === true ? '通过'
              : this.ruleForm.approvalResult === false ? '驳回' : '';
        }
      })
    },
    downFile(url){
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.file-item {
  color: #3090ff;
  cursor: pointer;
}
</style>
