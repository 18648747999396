import Vue from "vue";
import ElementUI from "element-ui";
import * as echarts from "echarts";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import "./theme.scss";
import dayjs from "dayjs";
import global from './views/global'
import 'video.js/dist/video-js.css'
Vue.prototype.$global = global
import BaiduMap from 'vue-baidu-map'
import '@/assets/style/editDialog.css'
import BaiduMapComponent from "@/components/bMap/Map.vue"
import EventBus from '@/utils/bus.js'
import downLoadFile from '@/utils/downLoadFile.js'
Vue.prototype.downLoadFile = downLoadFile
import hasPermission from '@/utils/hasPermission'
Vue.use(EventBus)
Vue.prototype.$EventBus = EventBus
Vue.use(BaiduMap, {
  ak: "BALcSP5MqQAfrMk4ge66XFlwsZ1V0UrU"
})
Vue.component("baidu-map", BaiduMapComponent)
Vue.use(ElementUI, { zIndex: 10 });
Vue.use(hasPermission)
Vue.prototype.dayjs = dayjs;
// 编辑器
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
