<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-model"
    >
      <el-form-item
        label="所属建管部"
        prop="superviseName"
        v-if="examineData.state == 1"
      >
        <el-input
          v-model="ruleForm.superviseName"
          readonly
          placeholder="所属建管部"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="线路名称"
        prop="lineName"
        v-if="examineData.state == 1"
      >
        <el-input
          v-model="ruleForm.lineName"
          readonly
          placeholder="线路名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="分部名称"
        prop="branchName"
        v-if="examineData.state == 1"
      >
        <el-input
          v-model="ruleForm.branchName"
          readonly
          placeholder="分部名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="工点名称"
        prop="siteName"
        v-if="examineData.state == 1"
      >
        <el-input
          v-model="ruleForm.siteName"
          readonly
          placeholder="工点名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练内容" prop="planContent">
        <el-input
          v-model="ruleForm.planContent"
          readonly
          placeholder="演练内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练科目" prop="label" v-if="examineData.state == 1">
        <el-input
          v-model="ruleForm.planSubject"
          readonly
          placeholder="演练科目"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练级别" prop="label" v-if="examineData.state == 1">
        <el-input
          readonly
          v-model="ruleForm.drillLevel"
          placeholder="演练级别"
        ></el-input>
      </el-form-item>
      <el-form-item label="演练地点" prop="label" v-if="examineData.state == 1">
        <el-input
          readonly
          v-model="ruleForm.address"
          placeholder="演练地点"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="拟参与人数"
        prop="planJoinCount"
        v-if="examineData.state == 1"
      >
        <el-input
          readonly
          v-model="ruleForm.planJoinCount"
          placeholder="拟参与人数"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="计划演练时间"
        prop="planDate"
        v-if="examineData.state == 1"
      >
        <el-input
          readonly
          v-model="ruleForm.planDate"
          placeholder="计划演练时间"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="实际演练时间"
        prop="executeTime"
        v-if="examineData.state == 5"
      >
        <el-input
          readonly
          v-model="ruleForm.executeTime"
          placeholder="实际演练时间"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="实际参与人数"
        prop="joinCount"
        v-if="examineData.state == 5"
      >
        <el-input
          readonly
          v-model="ruleForm.joinCount"
          placeholder="实际参与人数"
        ></el-input>
      </el-form-item>
      <el-form-item label="发起人" prop="label" v-if="examineData.state == 1">
        <el-input
          readonly
          v-model="ruleForm.owner.name"
          placeholder="发起人"
        ></el-input>
      </el-form-item>
      <el-form-item label="发起时间" prop="label" v-if="examineData.state == 1">
        <!--        <el-input-->
        <!--          v-model="ruleForm.created"-->
        <!--          placeholder="发起时间"-->
        <!--        ></el-input>-->
        <el-date-picker
          v-model="ruleForm.created"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          readonly
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="附件" prop="label" v-if="examineData.state == 1">
        <div class="file-item" @click="downFile(ruleForm.fileUrl)">
          {{ ruleForm.fileName }}
        </div>
      </el-form-item>
      <el-form-item label="演练方案" prop="label" v-if="examineData.state == 5">
        <div
          class="file-item"
          v-for="(item, index) in programmeList"
          :key="index"
          @click="downFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="演练脚本" prop="label" v-if="examineData.state == 5">
        <div
          class="file-item"
          v-for="(item, index) in scriptList"
          :key="index"
          @click="downFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="影像资料" prop="label" v-if="examineData.state == 5">
        <div
          class="file-item"
          v-for="(item, index) in videoList"
          :key="index"
          @click="downFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="签到表" prop="label" v-if="examineData.state == 5">
        <div
          class="file-item"
          v-for="(item, index) in signList"
          :key="index"
          @click="downFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item
        label="总结"
        prop="label"
        v-if="examineData.state == 5"
        @click="downFile(item.link)"
      >
        <div
          class="file-item"
          v-for="(item, index) in summaryList"
          :key="index"
          @click="downFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="评估报告" prop="label" v-if="examineData.state == 5">
        <div
          class="file-item"
          v-for="(item, index) in assessmentList"
          :key="index"
          @click="downFile(item.link)"
        >
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item
        label="其他附件"
        prop="label"
        v-if="examineData.state == 5"
        @click="downFile(item.link)"
      >
        <div class="file-item" v-for="(item, index) in otherList" :key="index">
          {{ item.attrName }}
        </div>
      </el-form-item>

      <el-form-item label="审核意见" prop="label">
        <el-radio-group v-model="radio">
          <el-radio :label="3">同意</el-radio>
          <el-radio :label="6">驳回</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="原因" prop="label">
        <el-input
          type="textarea"
          v-model="option"
          placeholder="请输入原因"
        ></el-input>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitExamine">确 定</el-button>
    </div>
  </div>
</template>

<script>
import {
  approvePlan,
  approveReport,
  getRehearsalDetail,
  noApprovePlan,
  noApproveReport,
} from "@/api/stand";

export default {
  name: "planExamine",
  props: {
    examineData: {
      default: () => {},
    },
  },
  data() {
    return {
      ruleForm: {
        owner: {
          name: "",
        },
      },
      rules: {},
      radio: 3,
      option: "",
      enclosureList: [],
      programmeList: [],
      scriptList: [],
      videoList: [],
      signList: [],
      summaryList: [],
      assessmentList: [],
      otherList: [],
    };
  },
  created() {
    this.getDetailData();
  },
  methods: {
    getDetailData() {
      let params = {
        id: this.examineData.id,
      };
      getRehearsalDetail(params).then((res) => {
        if (res.code == 0) {
          this.ruleForm = res.data;
          if (res.data.attachs && res.data.attachs.length > 0) {
            this.programmeList = res.data.attachs.filter((item) => {
              return item.attrType == 1;
            });
            this.scriptList = res.data.attachs.filter((item) => {
              return item.attrType == 2;
            });
            this.videoList = res.data.attachs.filter((item) => {
              return item.attrType == 3;
            });
            this.signList = res.data.attachs.filter((item) => {
              return item.attrType == 4;
            });
            this.summaryList = res.data.attachs.filter((item) => {
              return item.attrType == 5;
            });
            this.assessmentList = res.data.attachs.filter((item) => {
              return item.attrType == 6;
            });
            this.otherList = res.data.attachs.filter((item) => {
              return item.attrType == 7;
            });
          }
          // var arr = this.ruleForm.fileUrl.split("/");
          // this.ruleForm.fileName = arr[arr.length - 1];
        }
      });
    },

    submitExamine() {
      if (this.radio == 6 && !this.option) {
        this.$message.error("驳回原因必填");
        return;
      }
      // 计划待审批
      if (this.examineData.state == 1) {
        let examineInterface = this.radio == 3 ? approvePlan : noApprovePlan;
        let paramsA = {};
        if (this.radio == 3) {
          paramsA = {
            id: this.examineData.id,
            opinion: { opinion: this.option },
          };
        } else {
          paramsA = {
            id: this.examineData.id,
            opinion: { opinion: this.option },
          };
        }
        examineInterface(paramsA).then((res) => {
          if (res.code == 0) {
            this.$message.success("操作成功");
            this.$emit("successExamine");
          } else {
            this.$message.error(res.msg);
          }
        });
        // 报告待审批
      } else {
        let examineInterface =
          this.radio == 3 ? approveReport : noApproveReport;
        let params = {};
        if (this.radio == 3) {
          params = {
            id: this.examineData.id,
            opinion: { opinion: this.option },
          };
        } else {
          params = {
            id: this.examineData.id,
            opinion: { opinion: this.option },
          };
        }
        examineInterface(params).then((res) => {
          if (res.code == 0) {
            this.$message.success("操作成功");
            this.$emit("successExamine");
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    downFile(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-item {
  color: #3090ff;
  cursor: pointer;
}
</style>
