<template>
  <div class="material-container h-full">
    <el-form
      ref="formData"
      :rules="rules"
      :model="formData"
      :loading="loading"
      label-width="130px"
      :disabled="true"
    >
      <el-row v-show="!isShow">
        <el-col :span="12">
          <el-form-item label="线路分部工点" prop="siteUuid">
            <el-input v-model="formData.siteName"></el-input>
          </el-form-item>
          <el-form-item label="风险控制等级" prop="riskLevelCode">
            <el-input v-model="formData.riskLevelName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="风险名称" prop="riskName">
            <el-input
              v-model="formData.riskName"
              clearable
              placeholder="请输入风险名称"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="安全情况" prop="safeStatus">
            <el-input
              v-model="formData.safeStatus"
              clearable
              placeholder="请输入安全情况"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-show="isShow" class="PostInfCs-input">
        <el-col :span="12">
          <el-form-item label="线路分部工点" prop="siteUuid">
            <!-- <el-cascader
              style="width: 100%"
              v-model="lineSite"
              clearable
              :options="treeData"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="handleLineSiteChange"
              placeholder="请选择工点"
            ></el-cascader> -->
            <el-input v-model="formData.siteName"></el-input>
          </el-form-item>
          <el-form-item label="风险控制等级" prop="riskLevelCode">
            <!-- <el-select
              v-model="formData.riskLevelCode"
              clearable
              placeholder="请选择风险控制等级"
              style="width: 100%"
            >
              <el-option
                v-for="item of riskControlLevelList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select> -->
            <el-input v-model="formData.riskLevelName"></el-input>
          </el-form-item>
          <el-form-item label="风险类别" prop="riskTypeCode">
            <!-- <el-select
              v-model="formData.riskTypeCode"
              clearable
              placeholder="请选择类别"
              style="width: 100%"
              @change="getRiskSubTypeList"
            >
              <el-option
                v-for="item of riskTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select> -->
            <el-input v-model="formData.riskTypeName"></el-input>
          </el-form-item>
          <el-form-item label="预计开始时间" prop="estimateStartDay">
            <el-date-picker
              v-model="formData.estimateStartDay"
              type="date"
              placeholder="请选择时间"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="实际开始时间" prop="realStartDay">
            <el-date-picker
              v-model="formData.realStartDay"
              type="date"
              placeholder="请选择时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="原始风险等级" prop="originalRiskLevelCode">
            <!-- <el-select
              v-model="formData.originalRiskLevelCode"
              clearable
              placeholder="请选择原始风险等级"
              style="width: 100%"
            >
              <el-option
                v-for="item of riskLevelList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select> -->
            <el-input v-model="formData.originalRiskLevelName"></el-input>
          </el-form-item>
          <el-form-item label="经度" prop="lat">
            <el-input
              v-model="formData.lat"
              clearable
              placeholder="请输入经度"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="风险名称" prop="riskName">
            <el-input
              v-model="formData.riskName"
              clearable
              placeholder="请输入风险名称"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="安全情况" prop="safeStatus">
            <el-input
              v-model="formData.safeStatus"
              clearable
              placeholder="请输入安全情况"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>

          <el-form-item label="风险子类" prop="riskSubTypeCode">
            <!-- <el-select
              v-model="formData.riskSubTypeCode"
              clearable
              placeholder="请选择风险子类"
              style="width: 100%"
            >
              <el-option
                v-for="item of riskSubTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select> -->
            <el-input v-model="formData.riskSubTypeName"></el-input>
          </el-form-item>
          <el-form-item label="预计结束时间" prop="estimateEndDay">
            <el-date-picker
              v-model="formData.estimateEndDay"
              type="date"
              placeholder="请选择时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="实际结束时间" prop="realEndDay">
            <el-date-picker
              v-model="formData.realEndDay"
              type="date"
              placeholder="请选择时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="剩余风险等级" prop="restRiskLevelCode">
            <!-- <el-select
              v-model="formData.restRiskLevelCode"
              clearable
              placeholder="请剩余原始风险等级"
              style="width: 100%"
            >
              <el-option
                v-for="item of riskLevelList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select> -->
            <el-input v-model="formData.restRiskLevelName"></el-input>
          </el-form-item>
          <el-form-item label="纬度" prop="lon">
            <el-input
              v-model="formData.lon"
              clearable
              placeholder="请输入纬度"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-show="isShow" class="PostInfCs-input">
        <el-col>
          <el-form-item label="里程范围" prop="mileageRange">
            <el-input
              type="input"
              v-model="formData.mileageRange"
              placeholder=""
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-show="isShow" class="PostInfCs-input">
        <el-col :span="12">
          <el-form-item label="关联工法" prop="relationConstructMethodName">
            <el-input
              v-model="formData.relationConstructMethodName"
              clearable
              placeholder=""
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="施工单位联系人" prop="constructPerson">
            <el-input
              v-model="formData.constructPerson"
              clearable
              placeholder=""
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="设计单位联系人" prop="designPerson">
            <el-input
              v-model="formData.designPerson"
              clearable
              placeholder=""
              style="width: 100%"
            >
            </el-input> </el-form-item
          ><el-form-item label="监理单位联系人" prop="monitorPerson">
            <el-input
              v-model="formData.monitorPerson"
              clearable
              placeholder=""
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="建设单位联系人" prop="buildPerson">
            <el-input
              v-model="formData.buildPerson"
              clearable
              placeholder=""
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="勘察单位联系人" prop="surveyPerson">
            <el-input
              v-model="formData.surveyPerson"
              clearable
              placeholder=""
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="第三方监测单位联系人" prop="thirdPerson">
            <el-input
              v-model="formData.thirdPerson"
              clearable
              placeholder=""
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="分部工程" prop="subprojectName">
            <el-input
              v-model="formData.subprojectName"
              clearable
              placeholder=""
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="constructPhone">
            <el-input
              v-model="formData.constructPhone"
              clearable
              placeholder=""
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="designPhone">
            <el-input
              v-model="formData.designPhone"
              clearable
              placeholder=""
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="monitorPhone">
            <el-input
              v-model="formData.monitorPhone"
              clearable
              placeholder=""
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="buildPhone">
            <el-input
              v-model="formData.buildPhone"
              clearable
              placeholder=""
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="surveyPhone">
            <el-input
              v-model="formData.surveyPhone"
              clearable
              placeholder=""
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="thirdPhone">
            <el-input
              v-model="formData.thirdPhone"
              clearable
              placeholder=""
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-show="isShow" class="PostInfCs-input">
        <el-col>
          <el-form-item label="具体风险描述" prop="riskDescribe">
            <el-input
              type="textarea"
              v-model="formData.riskDescribe"
              placeholder=""
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <p @click="handleEx" class="zhankaiBtn">
        {{ isShow == true ? "点击收起" : "点击展开"
        }}<i
          class="el-icon-d-arrow-left"
          :class="isShow == true ? 'upArrow' : ''"
        ></i>
      </p>
    </el-form>
    <Cuoshi :measuresList="measuresList" @getData="getDataFn()"></Cuoshi>
    <!-- <div class="dialogFooter">
      <el-button size="medium" @click="cancel" v-show="submitType !== 'view'"
        >取 消</el-button
      >
      <el-button
        size="medium"
        type="primary"
        @click="submit"
        v-show="submitType !== 'view'"
        >确 定</el-button
      >
    </div> -->
  </div>
</template>

<script>
import Cuoshi from "./cuoshi";
import { getDictData } from "@/api/dict";
import { getMeasures } from "@/api/risk";
export default {
  components: {
    Cuoshi,
  },
  props: {
    pageData: {
      type: Object,
      default: () => {},
    },
    submitType: {
      type: String,
    },
  },
  data() {
    return {
      formData: {
        companyTypeCode: "",
        responseRoleId: "",
        riskLevelCode: "",
        responseDeadline: "",
        warnGradeCode: "",
      },
      // 单位类型
      unitTypeData: [],
      // 风险控制等级
      gradeList: [],
      cycleList: [],
      // 角色
      rolesData: [],
      loading: false,
      rules: {},
      isShow: false,
      measuresList: [],
    };
  },
  watch: {
    "pageData.id": {
      handler(val) {
        if (val) {
          this.getData();
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
  },
  created() {},
  mounted() {
    getMeasures(this.pageData.riskDeafUuid).then((res) => {
      // getMeasures("c6a2ffb0-10bf-49ba-b1e5-86716635c4e1").then((res) => {
      console.log(res);
      if (res.data) {
        this.measuresList = res.data;
      }
      // this.measuresList = res.data
    });
  },
  methods: {
    getDataFn() {
      getMeasures(this.pageData.riskDeafUuid).then((res) => {
        // getMeasures("c6a2ffb0-10bf-49ba-b1e5-86716635c4e1").then((res) => {
        console.log(res);
        if (res.data) {
          this.measuresList = res.data;
        }
        // this.measuresList = res.data
      });
    },
    handleEx() {
      this.isShow = !this.isShow;
    },
    getData() {
      console.log(this.pageData);
      // this.formData.responseRoleId = this.pageData.roleName;
      this.formData = { ...this.pageData };
      getDictData("safeStatusType").then((res) => {
        this.formData.safeStatus = res.data.find(
          (i) => i.code == this.formData.safeStatus
        ).label;
        console.log(this.ruleForm.safeStatus);
      });
      this.formData.responseRoleId = Number(this.pageData.responseRoleId);
      this.formData.warnGradeCode = Number(this.pageData.warnGradeCode);
    },
    cancel() {
      this.reset();
    },
    reset() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = "";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
}
.PostInfCs-input {
  .el-input--suffix .el-input__inner {
    width: 100%;
  }
  .el-col {
    padding: 0 20px;
  }
  .el-date-editor.el-input {
    width: 100%;
  }
}
.iconBtn {
  font-size: 28px;
  position: relative;
  top: 6px;
  color: #409eff;
  margin-left: 10px;
}
.redIconBtn {
  color: #ff0000;
}
.zhankaiBtn {
  padding: 3px 0px;
  width: calc(100% - 40px);
  border: 1px solid #bdbdbd;
  margin: 8px 0 15px 20px;
  text-align: center;
  cursor: pointer;
  .el-icon-d-arrow-left {
    transform: rotate(-90deg);
    margin-left: 5px;
  }
  .upArrow {
    transform: rotate(90deg);
  }
}
</style>
