<template>
  <div class="material-container h-full">
    <div class="table-wrapper p-4 h-full" v-loading="pageLoading" element-loading-text="数据加载中"
      element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)">
      <div class="table-search">
        <el-form :inline="true" :model="queryData" class="flex justify-between">
          <el-row>
            <el-form-item>
              <el-select v-model="queryData.type" placeholder="请选择规格">
                <el-option v-for="item in dict.modelNo" :key="item.id" :label="item.label" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input v-model="queryData.label" placeholder="输入设备名称搜索"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearch">查询</el-button>
            </el-form-item>
          </el-row>
          <el-row>
            <!-- <el-form-item>
              <el-button type="primary" @click="onExport">导出</el-button>
            </el-form-item> -->
          </el-row>
        </el-form>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column align="center" label="序号" type="index" width="80">
        </el-table-column>
        <el-table-column align="center" prop="label" label="名称"></el-table-column>
        <el-table-column align="center" prop="model" label="规格型号"></el-table-column>
        <el-table-column align="center" prop="siteName" label="所属工点" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="address" label="详细位置"></el-table-column>
        <el-table-column align="center" prop="responsiblePersonName" label="负责人"></el-table-column>
        <el-table-column align="center" prop="factory" label="生产厂家" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="unit" label="单位"></el-table-column>
        <el-table-column align="center" prop="count" label="数量"></el-table-column>
        <el-table-column align="center" prop="inTime" label="进场时间"></el-table-column>
        <el-table-column align="center" prop="expire" label="有效期"></el-table-column>
        <el-table-column align="center" prop="usages" label="用途">
          <template slot-scope="scope">
            <div style="cursor: pointer;" @mouseenter="handleShowDetail($event, scope)"
              @mouseleave="handleHideDetail($event, scope)">
              <section v-if="scope.row.showDetail">
                <p v-for="(item, key) of textSwitch(scope.row.usages)" :key="key">{{ `${item.label}.${item.intro}` }}
                </p>
              </section>
              <section v-else>{{ scope.row.usages }}</section>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="state" label="性能状况">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 0" style="color: red">待修</span>
            <span v-else>完好</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="remark" label="备注"></el-table-column>
      </el-table>
      <div class="flex justify-center pt-4">
        <el-pagination @size-change="handleSizeChange" @prev-click="handleCurrentChange"
          @next-click="handleCurrentChange" @current-change="handleCurrentChange" :current-page="page.pageNo"
          :page-sizes="[10, 50, 100, 200]" :page-count="page.count" :page-size="page.pageSize" layout="prev, pager, next, sizes,  total, jumper"
          :total="page.total">
        </el-pagination>
      </div>
    </div>
    <div class="handle-wrapper" :class="isShowPanl ? 'handle-wrapper-show' : 'handle-wrapper-hide'">
      <div class="toggle-btn" :class="isShowPanl ? 'toggle-btn-close' : 'toggle-btn-open'" @click="handleTogglePanl">
      </div>
      <div class="tree-wrapper h-full overflow-y-scroll">
        <el-tree v-show="isShowPanl" :data="lineSiteData" ref="treePanl" node-key="id" show-checkbox @check="getCurrentTreeNode" :props="props" :highlight-current="true"></el-tree>
      </div>
    </div>
  </div>
</template>

<script>
import { getScreenMaterialsNew } from "@/api/eres";
// import { queryDictNodesDataById } from "@/utils/dict";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
export default {
  components: {},
  data() {
    return {
      pageLoading: false,
      type: "125",
      specificationsOptions: [],
      queryData: {
        modelNo: "",
        label: "",
        type: "",
        siteIds: []
      },
      props: {
        children: "children",
        label: "name",
        value: "id"
      },
      isShowPanl: false,
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        total: 0
      },
      lineSiteData: [],
      dict: {
        modelNo: [],
        usages: []
      }
    }
  },
  created() {
    this.queryLineSiteTreeData();
    this.queryMaterialsListData();
    // this.dict.modelNo = queryDictNodesDataById();
    this.getDictData("物资规格型号", this.dict.modelNo);
    this.getDictData("物资用途", this.dict.usages);
  },
  mounted() { },
  methods: {
    getDictData(label, target) {
      queryDictByLabel({ label }).then(res => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then(res => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          })
        }
      });
    },
    onSearch() {
      this.queryMaterialsListData();
    },
    onExport() { },
    handleTogglePanl() {
      this.isShowPanl = !this.isShowPanl;
    },
    handleShowDetail(event, record) {
      const tempIndex = this.tableData.findIndex(item => item.id == record.row.id);
      this.tableData[tempIndex].showDetail = true;
    },
    handleHideDetail(event, record) {
      const tempIndex = this.tableData.findIndex(item => item.id == record.row.id);
      this.tableData[tempIndex].showDetail = false;
    },
    textSwitch(val) {
      console.log(Array.isArray(val))
      // const valArr = val.split(",");
      const tempArr = [];
      console.log(val, this.dict.usages);
      for (let item of val) {
        for (let dictItem of this.dict.usages) {
          if (item == dictItem.label) {
            tempArr.push(dictItem);
          }
        }
      }
      return tempArr;
    },
    // 分页相关
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.queryMaterialsListData();
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.queryMaterialsListData();
    },
    // 线路工点树数据查询
    queryLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.lineSiteData = lineSiteData;
      }
    },
    // 线路工点树切换
    getCurrentTreeNode() {
      this.queryData.siteIds = this.$refs.treePanl.getCheckedKeys(true);
      this.queryMaterialsListData();
    },
    // 分页查询列表数据
    queryMaterialsListData() {
      this.pageLoading = true;
      const params = {
        siteIds: this.queryData.siteIds,
        type: this.type,
        modelNo: this.queryData.modelNo,
        label: this.queryData.label,
        page: this.page.pageNo,
        size: this.page.pageSize,
        materialType: 1
      };
      getScreenMaterialsNew(params).then(res => {
        if (res.code == 0) {
          this.pageLoading = false;
          this.tableData = res.data.records;
          this.page.pageNo = res.data.current;
          this.page.pageSize = res.data.size;
          this.page.total = res.data.count;
          this.page.count = res.data.total;
        } else {
          this.pageLoading = false;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-select-dropdown {
  z-index: 99 !important;
}

.material-container {
  position: relative;
  .table-wrapper {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      width: 0 !important
    }
  }

  .handle-wrapper {
    min-width: 240px;
    max-width: 30vw;
    height: 60vh;
    position: absolute;
    top: 10%;
    background: rgba(11, 20, 53, 0.9);
    box-shadow: 10px 0px 25px 0px #002E75 inset;
    border-radius: 0px 20px 20px 0px;
    padding: 20px;
    z-index: 99;

    .tree-wrapper {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important
      }
    }

    ::v-deep .el-tree {
      background: transparent;
      color: #fff;
    }

    ::v-deep .el-tree-node__content:hover {
      background: transparent;
    }

    ::v-deep .el-tree-node:focus>.el-tree-node__content {
      background: transparent;
    }

    .toggle-btn {
      width: 28px;
      height: 240px;
      position: absolute;
      right: 0px;
      top: 50%;
      margin-top: -120px;
      cursor: pointer;
      background-image: url(../../../assets/sq.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }

    .toggle-btn-close {
      transform: rotate(180deg);
    }

    .toggle-btn-open {
      transform: rotate(0deg);
    }
  }

  .handle-wrapper-hide {
    left: -212px;
    background: transparent;
    box-shadow: none;
  }

  .handle-wrapper-show {
    left: 0px;
    background: rgba(11, 20, 53, 0.9);
    box-shadow: 10px 0px 25px 0px #002E75 inset;
  }
}

.table-search {
  ::v-deep .el-input__inner {
    background: #18467F;
    border: none;
    color: #fff;
  }
}

::v-deep .el-table {
  background: transparent;

  &::before {
    background-color: #143363;
  }

  thead {
    color: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: rgba(20, 51, 99, 0.5);
  }

  tr,
  th {
    background-color: transparent;
  }

  td {
    border-bottom: 1px solid #143363;
  }

  .cell {
    color: #fff;
  }

  th.is-leaf {
    border: 1px solid #143363;
  }
}

::v-deep .el-table--border {
  border: 1px solid #143363;

  &::after {
    background-color: #143363;
  }

  td {
    border-right: 1px solid #143363;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
  background: rgba(0, 46, 117, 0.4);
}
</style>
