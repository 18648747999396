<template>
  <div class="page-container bg-white p-4 h-full">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item>
        <el-cascader
          placeholder="工点选择"
          v-model="lineSite"
          :show-all-levels="false"
          :options="treeData"
          :props="props"
          collapse-tags
          clearable
          @change="handleLineSiteChange"
        ></el-cascader>
      </el-form-item>

     
      <el-form-item>
        <el-date-picker
          v-model="value1"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="handleDateChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select v-model="formInline.region" placeholder="类型选择">
          <el-option label="系统推送" value="1"></el-option>
          <el-option label="自主发起" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="formInline.state" placeholder="状态选择">
          <el-option
            v-for="item in states"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            
          >
          </el-option>
        </el-select>
      </el-form-item>
  
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
      <el-form-item style="margin-right: 100px;float:right;">
        <el-button type="primary" @click="derive">导出</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" border style="width: 100%">
      <el-table-column type="index" label="序号" width="80"> </el-table-column>

      <el-table-column prop="chargeName" label="姓名" width="180">
      </el-table-column>
      <!-- <el-table-column prop="date" label="所属监管部" width="180">
      </el-table-column>
      <el-table-column prop="date" label="线路名称" width="180">
      </el-table-column>
      <el-table-column prop="address" label="分部-工区名称"> </el-table-column> -->
      <el-table-column prop="materialWareHouse" label="工点名称">
      </el-table-column>
      <el-table-column prop="typers" label="类型"> </el-table-column>

      <el-table-column prop="state" label="状态" :formatter="apser">
      </el-table-column>
      <el-table-column prop="inventoryDate" label="盘点时间"> </el-table-column>
      <el-table-column prop="inventoryUserName" label="盘点人员">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="audit(scope.row)"
            v-show="opinion == true && scope.row.state == 2"
            >审核</el-button
          >
          <el-button size="mini" type="text" @click="details(scope.row)"
            >详情</el-button
          >
          <el-button size="mini" type="text" @click="handleDelete()"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-count="page.count"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <Conunted ref="counteds"></Conunted>
  </div>
</template>

<script>
import { InventorysseList, Approval,exinventory } from "@/api/eres.js";
import Conunted from "../modules/counted.vue";
export default {
  components: { Conunted },
  data() {
    return {
       treeData: [],
          props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true,
      },
      states: [
        {
          value:0,
          label:'待盘点'
        },
         {
          value:1,
          label:'整改中'
        },
         {
          value:2,
          label:'审核中'
        },
         {
          value:3,
          label:'完成'
        },
         {
          value:4,
          label:'未完成'
        },
        
      ],
      formInline: {
        endDate:"",
        siteId:"",
        startDate:"",
        state:"",
        type:""
      },
         formInlines: {
        endDate:null,
        siteId:null,
        startDate:null,
        state:null,
        type:null
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      opinion: false,
       lineSite: [],
          value1: [],
    };
  },
  created() {
    this.incenter();
     this.getLineSiteTreeData();
    Approval().then((res) => {
      console.log(res.data)
      this.opinion = res.data;
    });
  },
  methods: {
     // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
        console.log(this.treeData);
      }
    },
    handleLineSiteChange(val) {
      console.log(val);
      this.lineSite = val;
      this.formInline.siteId = this.lineSite[0];
      this.formInlines.siteId=this.lineSite[0];
    },
    //列表
    incenter() {
      InventorysseList({
        page: this.page.pageNo,
        size: this.page.pageSize,
        endDate:this.formInline.endDate,
         siteId:this.formInline.siteId,
          startDate:this.formInline.startDate,
           state:this.formInline.state,
            type:this.formInline.type,
      }).then((res) => {
        console.log(res);
        res.data.records.forEach((el) => {
          el.materialWareHouse = el.materialWareHouse.substring(
            0,
            el.materialWareHouse.length - 3
          );
          if (el.endDate == null) {
            el.typers = "自主推送";
          } else {
            el.typers = "系统推送";
          }
          console.log(el);
        });
        this.tableData = res.data.records;

        this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.page.count = res.data.total;
        this.page.total = res.data.count;
      });
    },
     // 时间选择
    handleDateChange(val) {
      if (val) {
        this.formInline.startTime = val[0];
        this.formInline.endTime = val[1];
         this.formInlines.startTime = val[0];
        this.formInlines.endTime = val[1];
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
        this.formInlines.startTime = "";
        this.formInlines.endTime = "";
      }
    },
   
    //状态
    apser(row, column, cellvalue) {
      if (cellvalue == "0") {
        return "待盘点";
      } else if (cellvalue == "2") {
        return "审核中";
      } else if (cellvalue == "1") {
        return "整改中";
      } else if (cellvalue == "3") {
        return "完成";
      } else if (cellvalue == "4") {
        return "未完成";
      }
    },
    //详情
    details(val) {
      console.log(val);
      if (val.state == 0) {
        this.$nextTick(() => this.$refs.counteds.besconunt(val));
      } else if (val.state == 1) {
        this.$nextTick(() => this.$refs.counteds.review(val));
      } else if (val.state == 2) {
        this.$nextTick(() => this.$refs.counteds.rectification(val));
      } else if (val.state == 3) {
        this.$nextTick(() => this.$refs.counteds.complete(val));
      } else if (val.state == 4) {
        this.$nextTick(() => this.$refs.counteds.incomplete(val));
      }
    },
    //查询
    onSubmit() {
      this.formInline.page = this.page.pageNo;
      this.formInline.size = this.page.pageSize;
         InventorysseList(this.formInline).then((res) => {
        console.log(res);
        res.data.records.forEach((el) => {
          el.materialWareHouse = el.materialWareHouse.substring(
            0,
            el.materialWareHouse.length - 3
          );
          if (el.endDate == null) {
            el.typers = "自主推送";
          } else {
            el.typers = "系统推送";
          }
          console.log(el);
        });
        this.tableData = res.data.records;

        this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.page.count = res.data.total;
        this.page.total = res.data.count;
      });
    },
    //导出
    derive() {
      if(this.formInline.type!=null){
        this.formInlines.type=this.formInline.type
      }
        if(this.formInline.state!=null){
          console.log(this.formInline.state)
        this.formInlines.state=this.formInline.state
      }
       
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
          exinventory(this.formInlines).then((res)=>{
        loading.close();
        let blob = new Blob([res], {
				type: "application/vnd.ms-excel"
			});
			// 3.创建一个临时的url指向blob对象
			let url = window.URL.createObjectURL(blob);
			// 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
			let a = document.createElement("a");
			a.href = url;
			a.download = "导出数据_" + new Date().getTime() + ".xlsx"; //自定义导出文件名
			a.click();
      })
      
    
    },
    //删除
    handleDelete() {},

    handleSizeChange(val) {
      console.log(val);
      this.page.pageSize = val;
      this.incenter();
    },
    handleCurrentChange(val) {
      console.log(val);
      this.page.pageNo = val;
      this.incenter();
    },
    //审核
    audit(val) {
       this.$nextTick(() => this.$refs.counteds.auditerts(val));
    },
  },
};
</script>

<style>
</style>