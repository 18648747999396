import cookie from 'js-cookie'
// import * as jsonwebtoken from 'jsonwebtoken'
import dayjs from 'dayjs'
// import store from '@/store'~
var userInfoName = "userInfo"
var storage = 'localStorage'
/**
 * @description 获取userInfo
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getUserInfo() {
  let userInfo = ''
  if (storage) {
    if ('localStorage' === storage) {
      userInfo = localStorage.getItem(userInfoName)
    } else if ('sessionStorage' === storage) {
      userInfo = sessionStorage.getItem(userInfoName)
    } else if ('cookie' === storage) {
      userInfo = cookie.get(userInfoName)
    } else {
      userInfo = localStorage.getItem(userInfoName)
    }
  } else {
    userInfo = localStorage.getItem(userInfoName)
  }
  if (!userInfo) {
    return undefined
  }
  if (userInfo.exp - dayjs().unix() <= 0) {
    return undefined
  }
  return userInfo
}

/**
 * @description 存储userInfo
 * @param userInfo
 * @returns {void|*}
 */
export function setUserInfo(userInfo) {
  if (storage) {
    // store.dispatch('user/getUserInfo')
    if ('localStorage' === storage) {
      return localStorage.setItem(userInfoName, userInfo)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem(userInfoName, userInfo)
    } else if ('cookie' === storage) {
      return cookie.set(userInfoName, userInfo)
    } else {
      return localStorage.setItem(userInfoName, userInfo)
    }
  } else {
    return localStorage.setItem(userInfoName, userInfo)
  }
}

/**
 * @description 移除userInfo
 * @returns {void|Promise<void>}
 */
export function removeUserInfo() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.removeItem(userInfoName)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.clear()
    } else if ('cookie' === storage) {
      return cookie.remove(userInfoName)
    } else {
      return localStorage.removeItem(userInfoName)
    }
  } else {
    return localStorage.removeItem(userInfoName)
  }
}
