import { httpInit } from "./http";
const http = httpInit("/gzdt-risk");
/**
 * 风险模块相关接口配置
 */

// 预警管理列表
export const warnPage = (params) => http.post(`/risk_warn/page`, params);
// 预警响应,审核列表分页查询
export const responsePage = (params) => http.post(`/risk_warn/responsePage`, params);
// 发布预警接口
export const warnAdd = (params) => http.post(`/risk_warn/add`, params);
// 预警详情
export const warnDetails = (id) => http.get(`/risk_warn/${id}`);

//查询工点对应施工单位信息
export const getConstructionUnitlnfo = (siteUuid) => http.get(`/risk_warn/getRectifierInfo/${siteUuid}`);

// 预警处理信息记录分页接口
export const warnRecord = (p) => http.get(`/risk_warn_record/page?pageNum=${p.pageNum}&pageSize=${p.pageSize}&warnUuid=${p.warnUuid}&type=${p.type}`, p);
export const warnRecord1 = (p) => http.get(`/risk_warn_record/page?pageNum=${p.pageNum}&pageSize=${p.pageSize}&warnUuid=${p.warnUuid}&type=${p.type}&eventId=${p.eventId}`, p);

// 预警处理新增信息接口
export const warnRecordAdd = (p) => http.post(`/risk_warn_record/add`, p);
// 巡视预警-风险列表查询
export const riskItemMasterList = (p) => http.post(`/risk_warn/riskItemMasterList`, p);

// 危险大工程分页列表
export const riskDangerBigProjectList = (p) => http.post(`/riskDangerBigProject/page`, p);

//新增
export const riskDangerBigProjectAdd = (p) => http.post(`/riskDangerBigProject/add`, p);
//编辑
export const riskDangerBigProjectEdit = (p) => http.post(`/riskDangerBigProject/edit`, p);

//逻辑删除
export const riskDangerBigProjectDelete = (id) => http.delete(`/riskDangerBigProject/${id}`);

// 预警详情
export const riskDangerBigProjectDetail = (id) => http.get(`/riskDangerBigProject/${id}`);

// 关键节点分页查询
export const keyNodes = (params) => http.post(`/key_nodes/page`, params);
// 关键节点清单确认分页查询
export const confirmPage = (params) => http.post(`/key_nodes/confirmPage`, params);
// 关键节点清单确认详情
export const nodeDetails = (p) => http.post(`/key_nodes/${p.id}`, p);
// 新增确认节点
export const nodeAdd = (params) => http.post(`/key_nodes/add`, params);
// 编辑确认节点
export const nodeEdit = (params) => http.post(`/key_nodes/edit`, params);
// 删除确认节点
export const nodeDelete = (id) => http.delete(`/key_nodes/${id}`);
// 确认节点申请
export const nodeApply = (p) => http.get(`/key_nodes/apply/${p.ids}`, p);
// 确认节点确认操作
export const confirmOperation = (p) => http.post(`/key_nodes/confirmOperation`, p);
// 状态记录：(0节点确认 1节点验收)，分页查询
export const nodeStateRecord = (p) => http.get(`/key_nodes_record/page?pageNum=${p.pageNum}&pageSize=${p.pageSize}&keyNodesType=${p.keyNodesType}&nodeUuid=${p.nodeUuid}`, p);
// 关键节点确认-验收通知代办详情，通过id查询
export const nodeStateRecordDetail = (p) => http.get(`/key_nodes_record/${p.id}`, p);
// 状态记录：(0节点确认 1节点验收)，分页查询
// export const confirmRecord = (p) => http.get(`/key_nodes_record/confirmRecord?pageNum=${p.pageNum}&pageSize=${p.pageSize}&keyNodesType=${p.keyNodesType}&nodeUuid=${p.nodeUuid}&eventId=${p.eventId}`, p);
export const confirmRecord = params => {
  return http({
    method: "get",
    url: "/key_nodes_record/confirmRecord",
    headers: {
      "Content-Type": "application/json"
    },
    params
  });
};


// 节点验收
export const nodeCheckPage = (p) => http.post(`/key_nodes/checkPage`, p);
// 节点验收操作
export const nodeCheckAdd = (p) => http.post(`/key_nodes_check/add`, p);
export const nodeChecDetails = (id) => http.get(`/key_nodes_check/${id}`);
// export const download = (id) => https.get(`/download?url=${id}`);

// 物资模板导出
export const download = (url) => {
  return http({
    method: "get",
    url: "/file/download?url=" + url,
    headers: {
      "Content-Type": "application/json"
    },
    responseType: "blob"
  });
};

//开始巡查分页列表
export const toPatrolPage = (p) => http.post(`/riskPatrol/toPatrolPage`, p);

//巡查记录分页列表
export const historyPatrolPage = (p) => http.post(`/riskPatrol/historyPatrolPage`, p);

//新增风险巡查
export const historyPatrolAdd = (p) => http.post(`/riskPatrol/add`, p);

// 预警详情
export const findByUuid = (id) => http.get(`/riskItem/findByUuid?riskDeafUuid=` + id);

// 预警详情
export const findRiskMeasure = (id) => http.get(`/riskPatrol/findRiskMeasure?riskDeafUuid=` + id);

// 预警详情
export const historyList = (id) => http.get(`/riskPatrol/list?riskDeafUuid=` + id);

// 风险巡查问题填报前，加载显示风险信息
export const getRiskBaseMsg = (id) => http.get(`/riskPatrolProblem/getRiskBaseMsg?riskMeasureId=` + id);

// 通过巡查uuid查询问题列表
export const getMessageDetail = (id) => http.get(`/riskPatrolProblem/listByProgressUuid?progressUuid=` + id);


// 通过巡查uuid查询风险进展信息
export const findByProgressUuid = (id) => http.get(`/riskPatrol/findByProgressUuid?progressUuid=` + id);

//巡查记录分页列表
export const riskPatrolProblemAdd = (p) => http.post(`/riskPatrolProblem/add`, p);

// 过程综合管理列表接口
export const riskItemPage = (p) => http.post(`/riskItem/page`, p);
// 风险交底分页查询
export const riskBottompage = (p) => http.post(`/riskbottom/riskpage`, p);
// 风险交底操作新增接口
export const riskBottomAdd = (p) => http.post(`/riskbottom/add`, p);
// 风险交底操作编辑接口
export const riskBottomEdit = (p) => http.post(`/riskbottom/edit`, p);
// 风险交底操作 删除接口
export const riskBottomDelete = (p) => http.delete(`/riskbottom/${p.id}`, p);
// 风险交底详情
export const riskBottomList = (p) => http.get(`/riskbottom/list?riskMasterUuid=${p.riskMasterUuid}&riskDeafUuid=${p.riskDeafUuid}`, p);
// 风险启动分页查询
export const riskStartpage = (p) => http.post(`/riskbottom/riskpage`, p);
// export const riskStartRecord = (p) => http.post(`/risk_startup_status_record/page`, p);// 风险启动申请接口
// 风险申请保存数据接口
export const riskStartApply = (p) => http.post(`/riskstartimplementationapplication/add`, p);
// 风险申请修改数据接口
export const riskStartApplyEdit = (p) => http.post(`/riskstartimplementationapplication/edit`, p);
// 风险启动 申请文件接口：
export const riskStartFileApply = (p) => http.post(`/riskstartsubmitfile/addList`, p);
// 风险启动申请（提交申请按钮） 状态更新  前端传状态
export const riskStartEditStatus = (p) => http.post(`/riskstartsubmitfile/editStatus`, p);
// 风险启动申请详情接口
export const riskStartapplyDetail = (p) => http.post(`/riskstartimplementationapplication/getApplication`, p);
export const riskstartupstatus = (id) => http.post(`/riskstartupstatus/${id}`);
// 风险启动审核接口
export const riskstartAduit = (p) => http.post(`/riskstartexamine/add`,p);
// 修改状态接口
export const riskstartupstatusEdit = p =>
  http.post(`/riskstartupstatus/edit`, p);
// 风险启动文件列表接口
export const riskstartfileList = (p) => http.get(`/riskstartsubmitfile/list?riskDeafUuid=${p.riskDeafUuid}&riskMasterUuid=${p.riskMasterUuid}&isDelete=${p.isDelete}`);
// 风险启动审核状态详情接口
// export const riskstartAduitDetails = (p) => http.post(`/riskstartexamine/getRiskStartExamine`, p,);
export const riskstartAduitDetails = data =>
  http({
    url: `/riskstartexamine/getRiskStartExamine`,
    data,
    method: "post",
    headers: { "Content-Type": "application/json;charset=utf8" }
  });

// 风险启动审核状态列表接口
// export const riskstartAduitDetails = (p) => http.post(`/riskstartexamine/getRiskStartExamine`, p,);
export const riskstartAduitList = params =>
  http({
    url: `/risk_startup_status_record/list`,
    params,
    method: "get",
    headers: { "Content-Type": "application/json;charset=utf8" }
  });

//巡查记录分页列表
export const removeList = (p) => http.post(`/riskbottom/riskpage`, p);

//申请消除
export const applyClear = (p) => http.post(`/risk_close_submit/add`, p);

//申请消除
export const applyClearEdit = (p) => http.post(`/risk_close_submit/edit`, p);

//申请消除
export const applyClearFile = (p) => http.post(`/risk_close_submit_file/add`, p);
//申请消除
export const examineClear = (p) => http.post(`/risk_close_examine/add`, p);

//申请消除
export const examineClearFile = (p) => http.post(`/risk_close_examine_file/add`, p);

//通过id查询
export const applyDetails = (id) => http.get(`/risk_close_examine/${id}`);

//通过id查询
export const applyDetailsFile = (id) => http.get(`/risk_close_examine_file/${id}`);

// 查询风险进展信息
export const progressList = (id) => http.get(`/riskPatrol/listProgress?progressUuid=` + id);

// 导出预案管理
export const bigProjectExport = (params) =>
  http.post(`/riskDangerBigProject/export`, params, {
    //重要  没有她导出的文件会打不开
    responseType: "blob",
  });


//查看施工单位填报信息
export const getRiskCloseSubmit = (p) => http.post(`/risk_close_submit/getRiskCloseSubmit`, p);

//查看监理单位审核信息
export const getRiskCloseExamine = (p) => http.post(`/risk_close_examine/getRiskCloseExamine`, p);

//查看施工单位填报附件
export const applyClearFileList = (p) => http.post(`/risk_close_submit_file/list`, p);

//查看监理单位填报附件
export const examineClearFileList = (p) => http.post(`/risk_close_examine_file/list`, p);

export const riskDangerBigProjectImport = (file) => {
  return http.post(`/riskDangerBigProject/import`, file);
};


//删除
export const applyClearFileDelete = (id) => http.delete(`/risk_close_submit_file/${id}`);
