<template>
  <systemModal ref="formModal" :title="title" :IsAudit="true" width="40%" @modalAgree="handleExamint(1)" @modalDisagree="handleExamint(2)">
    <el-form v-loading="loading" :model="model" ref="modelForm" label-width="200px" class="demo-model" style="width: 80%">
      <el-form-item label="线路工点" prop="lineSite">
        {{ model.line }} - {{ model.site }}
      </el-form-item>
      <el-form-item label="培训类型" prop="category">
        {{ model.categoryName }}
      </el-form-item>
      <el-form-item label="培训主题" prop="label">
        {{ model.label }}
      </el-form-item>
      <el-form-item label="主讲人" prop="teacher">
        {{ model.teacher }}
      </el-form-item>
      <el-form-item label="培训时间" prop="trainDate">
        {{ model.trainDate }}
      </el-form-item>
      <el-form-item label="计划参与人数" prop="planCount">
        {{ model.planCount }}
      </el-form-item>
      <el-form-item label="实际参与人数" prop="joinCount">
        {{ model.joinCount }}
      </el-form-item>
      <el-form-item label="签到表" prop="checkinTable">
        <el-image
          style="width: 150px; height: 80px"
          :src="model.checkinTable"
          :preview-src-list="[model.checkinTable]"
        >
        </el-image>
      </el-form-item>
      <el-form-item label="现场照片" prop="localePhoto">
        <el-image
          v-for="(item, index) of model.localePhoto"
          :key="index"
          style="width: 150px; height: 80px"
          :src="item.link"
          :preview-src-list="[item.link]"
        >
        </el-image>
      </el-form-item>
      <el-form-item label="状态">
        {{ model.stateName }}
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          placeholder="请输入内容"
          v-model="remark"
        >
        </el-input>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { getTrainDetail, editTrain } from "@/api/stand";
export default {
  components: {
    systemModal
  },
  data() {
    return {
      title: "详情",
      model: {
        lineId: "",
        siteId: "",
        labelId: "",
        modelNo: "",
        factoryId: "",
        standardCount: "",
        unit: "",
        count: "",
        inTime: "",
        expire: "",
        usages: [],
        remark: "",
        responsiblePersonName: "",
        responsiblePersonPhone: "",
        type: ""
      },
      loading: false,
      remark: ""
    };
  },
  props: {
    lineSiteData: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  mounted() {},
  methods: {
    handleDetail(record) {
      this.$refs.formModal.handleOpen();
      this.loading = true;
      getTrainDetail({ id: record.id }).then(res => {
        if (res.code == 0) {
          this.model = Object.assign({}, res.data);
          this.loading = false;
        }
      });
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then(res => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then(res => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 审核
    handleExamint(type) {
      const data = JSON.parse(JSON.stringify(this.model));
      const params = {
        id: data.id,
        data
      };
      if (type == 1) {
        params.data.state = 1;
      } else {
        params.data.state = 2;
        if (!this.remark) {
          this.$message.error("请输入驳回原因");
          return false;
        } else {
          params.data.remark = this.remark;
        }
      }
      this.loading = true;
      editTrain(params).then(res => {
        if (res.code == 0) {
          this.loading = false;
          this.$message.success("操作成功");
          this.$refs.formModal.handleClose();
          this.$emit("examineSubmit");
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}
::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
</style>
