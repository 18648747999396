<template>
  <div>
    <el-table :data="tabledata" border style="width: 100%; margin-top: 10px">
      <el-table-column
        align="center"
        type="index"
        label="序号"
        width="100"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="constructionName"
        label="线路-工点"
        :formatter="unitNameFormat"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="times"
        label="填报次数"
        show-overflow-tooltip
        ><template slot-scope="scope" v-if="scope.row.times">
          第{{ scope.row.times }}次
        </template></el-table-column
      >
      <!-- <el-table-column
        v-if="tabRadioOne != '139' && tabRadioTwo == '建管部'"
        align="center"
        prop="completion"
        label="完成情况"
        show-overflow-tooltip
      ></el-table-column> -->
      <el-table-column
        align="center"
        prop="deadline"
        label="截止时间"
        width="200"
      >
        <template slot-scope="scope">
          <div
            v-if="scope.row.fillTime"
            :style="{
              color: scope.row.deadline > scope.row.fillTime ? '' : 'red',
            }"
          >
            {{ scope.row.deadline }}
          </div>
          <div
            v-else
            :style="{ color: scope.row.deadline > gettime ? '' : 'red' }"
          >
            {{ scope.row.deadline }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="fillTime"
        label="填报时间"
        width="200"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="fillUserName"
        label="填报人员"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="status"
        label="状态"
        :formatter="formatState"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="approvalNames"
        label="审批人员"
        show-overflow-tooltip
      >
        <!-- <template slot-scope="scope">
              {{ scope.row.approvalNames ? scope.row.approvalNames : "/" }}
            </template> -->
      </el-table-column>
    </el-table>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        :total="page.count"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        layout=" prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { queryCurrentResponseListNew } from "@/api/response";
export default {
  components: {},
  props: {
    paramsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      page: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
      },
      tabledata: [],
    };
  },
  watch: {},
  mounted() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      let params = {
        finishStatus: this.paramsData.finishStatus,
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteUuidList: this.paramsData.siteUuidList,
        responseRecordId: this.paramsData.responseRecordId,
        reportType: this.paramsData.reportType,
      };
      queryCurrentResponseListNew(params).then((res) => {
        if (res.code == 0) {
          this.tabledata = res.data.records;
          this.page.count = res.data.count;
        }
      });
    },
    //页数
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.onSearch();
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.onSearch();
    },
    // 角色类型type为4时单位名称转换
    unitNameFormat(row) {
      if (row.siteUuId) {
        return `${row.lineName}-${row.siteName}`;
      } else {
        return row.supervisionName;
      }
    },
    // status：0-待填报 1-已驳回 2-待监理值班人员审批 3-待建管值班人员审批 4-待建管值班领导审批  5 已完成
    formatState: function (row, column, cellValue) {
      switch (cellValue) {
        case 0:
          return "待填报";
        case 1:
          return "已驳回";
        case 2:
          return "待监理值班人员审批";
        case 3:
          return "待建管值班人员审批";
        case 4:
          return "待建管值班领导审批";
        case 5:
          return "已完成";
      }
    },
  },
};
</script>
    
  <style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 700px;
  overflow: auto;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
::v-deep .el-table:before {
  height: 0px;
}
.stCard {
  margin: 0 20px 10px 0;
  padding: 20px 20px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.cenCard {
  padding: 20px 20px;
  width: 24%;
  height: 100px;
}
.cenNum {
  font-size: 28px;
  font-family: Arial;
  font-weight: bold;
  color: #081742;
}
</style>
    