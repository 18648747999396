<template>
  <!-- <systemModal
    ref="formModal"
    @modalConfirm="handleSubmit"
    :title="title"
    width="70%"
  > -->
  <Modal
    class="modalDetail"
    ref="formModal"
    :modal="false"
    :width="'1200px'"
    :title="title"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="150px"
      class="demo-model"
      style="width: 90%"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item label="姓名" prop="workerName">
            <el-input
              v-model="model.workerName"
              readonly
              placeholder="请输入姓名"
            ></el-input
          ></el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="职务" prop="workRoleName">
            <el-input
              v-model="model.workRoleName"
              readonly
              placeholder="联系电话"
            ></el-input>
            <!--  <el-select
               v-model="model.workRoleName"
              placeholder="请选择职务"
              @change="changeRole"
            >
              <el-option
                v-for="item of dict.workRole"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              ></el-option> 
            </el-select>-->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系电话" prop="workerPhone">
            <el-input
              v-model="model.workerPhone"
              readonly
              placeholder="联系电话"
            ></el-input> </el-form-item
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="学历" prop="workerEducation">
            <el-input
              v-model="model.workerEducation"
              readonly
              placeholder="学历"
            ></el-input>
            <!--   <el-select v-model="model.workerEducation" placeholder="请选择学历">
              <el-option
                v-for="item of dict.education"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select> -->
          </el-form-item></el-col
        >
        <el-col :span="8">
          <el-form-item label="专业" prop="workerSpecialty">
            <el-input
              v-model="model.workerSpecialty"
              readonly
              placeholder="专业"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="8"></el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="特种作业证" prop="permit" label-width="150px">
            <div class="url-list" v-if="model.permit">
              <el-image :src="model.permit"> </el-image>
            </div>
            <!-- <image-uploader v-else v-model="model.permit"></image-uploader> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="照片" prop="workerImg" label-width="150px">
            <div class="url-list" v-if="model.workerImg">
              <el-image :src="model.workerImg"> </el-image>
            </div>
            <!-- <image-uploader
              v-else
              v-model="model.workerImg"
            ></image-uploader>  --></el-form-item
          ></el-col
        >
        <el-col :span="8">
          <el-form-item
            label="三级安全教育"
            prop="threeLevelSafetyEduImg"
            label-width="150px"
          >
            <div class="url-list" v-if="model.threeLevelSafetyEduImg">
              <el-image :src="model.threeLevelSafetyEduImg"> </el-image>
            </div>
            <!-- <image-uploader
              v-else
              v-model="model.threeLevelSafetyEduImg"
            ></image-uploader>  --></el-form-item
          ></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item
            label="上岗前培训"
            prop="preJobTrainingImg"
            label-width="150px"
          >
            <div class="url-list" v-if="model.preJobTrainingImg">
              <el-image :src="model.preJobTrainingImg"> </el-image>
            </div>
            <!--  <image-uploader
              v-else
              v-model="model.preJobTrainingImg"
            ></image-uploader> -->
          </el-form-item></el-col
        >
        <el-col :span="8">
          <el-form-item
            label="技能培训"
            prop="skillsTrainingImg"
            label-width="150px"
          >
            <div class="url-list" v-if="model.skillsTrainingImg">
              <el-image :src="model.skillsTrainingImg"> </el-image>
            </div>
            <!-- <image-uploader
              v-else
              v-model="model.skillsTrainingImg"
            ></image-uploader> -->
          </el-form-item></el-col
        >
        <el-col :span="8">
          <el-form-item
            label="疫情防控资料"
            prop="epidemicPreventionDataImg"
            label-width="150px"
          >
            <div class="url-list" v-if="model.epidemicPreventionDataImg">
              <el-image :src="model.epidemicPreventionDataImg"> </el-image>
            </div>
            <!--  <image-uploader
              v-else
              v-model="model.epidemicPreventionDataImg"
            ></image-uploader> -->
          </el-form-item></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item
            label="个人年度体检报告"
            prop="medicalExaminationReportImg"
            label-width="150px"
          >
            <div class="url-list" v-if="model.medicalExaminationReportImg">
              <el-image :src="model.medicalExaminationReportImg"> </el-image>
            </div>
            <!-- <image-uploader
              v-else
              v-model="model.medicalExaminationReportImg"
            ></image-uploader> -->
          </el-form-item></el-col
        >
        <el-col :span="16"></el-col>
      </el-row>
    </el-form>
  </Modal>
  <!-- </systemModal>  -->
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import {
  addRescueTeamMember,
  editRescueTeamMember,
  getDeptAccountList,
} from "@/api/eres";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import ImageUploader from "@/components/Uploader/imgUp_copy.vue";
import Modal from "@/components/Modal/modal.vue";
export default {
  components: {
    systemModal,
    ImageUploader,
    Modal,
  },

  data() {
    return {
      title: "详情",
      model: {
        workerName: "",
        workRoleName: "",
        workerPhone: "",
        workerEducation: "",
        workerSpecialty: "",
        workerImg: "",
        epidemicPreventionDataImg: "",
        medicalExaminationReportImg: "",
        permit: "",
        preJobTrainingImg: "",
        threeLevelSafetyEduImg: "",
        skillsTrainingImg: "",
      },
      tree: [],
      props: {
        children: "children",
        label: "name",
        isLeaf: "leaf",
      },
      disabled: false,
      loading: false,
      teamList: [],
      rules: {},
      dict: {
        workRole: [],
        education: [
          {
            label: "小学",
            value: 0,
          },
          {
            label: "初中",
            value: 1,
          },
          {
            label: "高中",
            value: 2,
          },
          {
            label: "专科",
            value: 3,
          },
          {
            label: "本科",
            value: 4,
          },
          {
            label: "硕士",
            value: 5,
          },
          {
            label: "博士",
            value: 6,
          },
        ],
      },
      uuid: "",
    };
  },
  props: {
    orgTree: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.getDictData("救援队职务", this.dict.workRole);
  },
  methods: {
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    handleAdd(record) {
      if (record) {
        this.title = "详情";
        this.disabled = true;
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
      }
      this.tree = JSON.parse(JSON.stringify(this.orgTree));
      this.$refs.formModal.handleOpen();
    },
    handleSubmit() {
      this.$refs.formModal.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-image {
  height: 150px;
}
.i {
  position: absolute;
  top: -5px;
  right: -5px;
  color: red;
  font-weight: 600;
}
.url-list {
  position: relative;
  display: inline-block;
}
::v-deep .el-input__inner {
  background: #081742;
  border-color: #25458f;
  color: #fff;
}
.el-table--border::after {
  width: 0;
}
::v-deep .el-table th.is-leaf,
::v-deep .el-table--border td {
  border: 1px solid #143363 !important;
}
</style>
