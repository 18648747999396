<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
            <el-select
              v-model="queryModal.unitType"
              placeholder="考核单位"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="item in examCompany"
                :key="item.value"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select
              v-model="queryModal.checkItem"
              placeholder="考核项目"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="item in examProject"
                :key="item.value"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="">
            <el-select
              v-model="queryModal.hiddenLevel"
              placeholder="隐患等级"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="item in dangerLevel"
                :key="item.value"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item>
            <el-button type="primary" v-has="'drill:add'" @click="handleAdd"
              >新建</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
    </div>

    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="unitName"
          label="考核单位"
          width="120"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="checkItemName"
          label="考核项目"
          width="160"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="hiddenLevel"
          label="隐患等级"
          width="120"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="deductMarks"
          label="扣分标准"
          width="120"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="createBy"
          label="创建人"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="created"
          label="创建时间"
          width=""
        ></el-table-column>
        <el-table-column align="center" label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <!-- 计划审核 -->
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="titleAdd"
      :visible.sync="dialogVisible"
      class="editDialog"
      append-to-body
    >
      <criteriaModal
        v-if="dialogVisible"
        :operation="operation"
        :edit-data="editData"
        @successModal="successModal"
        @cancelModal="cancelModal"
      ></criteriaModal>
    </el-dialog>
  </div>
</template>

<script>
import { getDictData } from "@/api/dict";
import { criteriaDelete, criteriaList } from "@/api/hdanger";
import criteriaModal from "@/views/HiddenDanger/System/Wooden/AssessCriteria/criteriaModal";

export default {
  name: "AssessCriteria",
  components: {
    criteriaModal,
  },
  data() {
    return {
      queryModal: {
        checkItem: "",
        unitType: "",
        hiddenLevel: "",
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      tableData: [],
      examCompany: [],
      examProject: [],
      dangerLevel: [],
      loading: false,
      titleAdd: "",
      operation: "",
      dialogVisible: false,
      editData: {},
    };
  },
  created() {
    this.getSelectList();
    this.getTableData();
  },
  methods: {
    getSelectList() {
      let dictArray = ["unitType", "check_item", "hdLevel"];
      dictArray.forEach((item) => {
        this.getDictList(item);
      });
    },
    getDictList(label) {
      getDictData(label).then((res) => {
        switch (label) {
          case "unitType":
            this.examCompany = res.data;
            break;
          case "check_item":
            this.examProject = res.data;
            break;
          case "hdLevel":
            this.dangerLevel = res.data;
            break;
        }
      });
    },
    getTableData() {
      this.loading = true;
      let params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.queryModal,
      };
      criteriaList(params).then((res) => {
        this.tableData = res.data.records;
        this.page.total = res.data.count;
        this.loading = false;
      });
    },
    handleAdd() {
      this.titleAdd = "新增";
      this.operation = "add";
      this.dialogVisible = true;
    },
    cancelModal() {
      this.dialogVisible = false;
    },
    successModal() {
      this.dialogVisible = false;
      this.getTableData();
    },
    handleEdit(row) {
      this.titleAdd = "编辑";
      this.operation = "edit";
      this.editData = { ...row };
      this.dialogVisible = true;
    },
    handleSearch() {
      this.page.pageNo = 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getTableData();
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
          criteriaDelete(row.id).then((res) => {
          if (res.code == 0) {
            this.$message.success("操作成功");
            this.getTableData();
          } else {
            this.$message.error(res.msg);
          }
        });
         });
    },
  },
};
</script>

<style scoped>
</style>
