<template>
  <div class="dataInfo min-h-full bg-white">
    <div class="flex justify-between search">
      <span>
        <el-cascader
          v-model="lineSite"
          :show-all-levels="false"
          :options="treeData"
          :props="props"
          collapse-tags
          clearable
          @change="handleLineSiteChange"
        ></el-cascader>
        <el-select
          v-model="state"
          clearable
          placeholder="选择状态"
          style="margin:0 20px"
        >
          <el-option
            v-for="item of stateData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-date-picker
          v-model="dateTime"
          type="datetimerange"
          clearable
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="handleDateChange"
          style="margin:0 20px"
        >
        </el-date-picker>
        <el-button type="primary" @click="findData">搜索</el-button>
      </span>
      <span>
        <el-button type="primary" @click="opAdd" v-has="'corrective:add'"
          >新增</el-button
        >
      </span>
    </div>
    <div class="content">
      <el-table
        v-loading="tableloading"
        border
        :data="list"
        style="width: 100%"
        row-key="id"
        size="mini"
        :tree-props="{ children: 'nodes', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          prop="lineName,siteName"
          label="线路-工点"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.lineName }}-{{ scope.row.siteName }}
          </template>
        </el-table-column>
        <!-- prop="checkItemContent" -->
        <el-table-column
          prop="hiddenQuestion"
          label="问题详情"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="typeName"
          label="检查类型"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="checkTime"
          label="问题上报时间"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="correctiveTime"
          label="问题整改时间"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="correctiveUser.name"
          label="整改人员"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="correctiveApproveStatus"
          label="状态"
          show-overflow-tooltip
          align="center"
          :formatter="correctiveApproveStatusFormatter"
        >
        </el-table-column>
        <!-- <el-table-column
          prop="correctiveApproveStatus"
          label="备注"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column> -->
        <el-table-column label="操作" align="center" width="200px">
          <template #default="scope">
            <div class="flex justify-around cursor-pointer">
              <el-button
                v-has="'corrective:add'"
                type="text"
                size="small"
                @click="opUpdata(scope.row)"
                v-if="scope.row.buttonStatus != 3"
                >整改
              </el-button>
              <el-button
                v-has="'corrective:approve'"
                type="text"
                size="small"
                @click="approve(scope.row)"
                v-if="scope.row.buttonStatus == 1"
                >审批
              </el-button>

              <el-button type="text" size="small" @click="userInfo(scope.row)"
                >查看
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="getHistoryData(scope.row)"
                >历史操作
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.count"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="emeTitle"
      :visible.sync="dialogVisible"
      :width="'600px'"
      :before-close="handleClose"
      :close-on-click-modal="false"
      append-to-bodyF
      center
      class="checkDialog"
    >
      <rectProblem
        v-if="dialogVisible"
        :rectId="rectId"
        :emeTitle="emeTitle"
        @cancelInspection="rectClose"
      >
      </rectProblem>
    </el-dialog>

    <el-dialog
      title="历史操作记录"
      :visible.sync="historyDialog"
      :close-on-click-modal="false"
      append-to-body
      width="30%"
      class="historyDialog"
    >
      <historyPage
        v-if="historyDialog"
        :log-list="historyData"
        @historyClose="historyClose"
      ></historyPage>
    </el-dialog>

    <!-- 新增 -->
    <el-dialog
      :title="title"
      :visible.sync="addAialogVisible"
      :width="'800px'"
      :before-close="handleAddClose"
      :close-on-click-modal="false"
      center
    >
      <el-form
        ref="model"
        :rules="rule"
        :model="model"
        label-width="110px"
        class="demo-ruleForm"
        v-loading="loadingAdd"
        :label-position="'left'"
      >
        <el-row>
          <el-col :span="12" class="m-1.5">
            <el-form-item label="存在隐患工点:" prop="lineSite">
              <el-cascader
                ref="linecas"
                placeholder="选择线路工点"
                v-model="model.lineSite"
                :options="treeData"
                :props="propsForm"
                filterable
                clearable
                @change="handleLineSiteChangeFrom"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="检查时间:" prop="checkTime" label-width="80px">
              <el-date-picker
                v-model="model.checkTime"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择检查时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="检查类型:" prop="type">
          <el-select
            v-model="model.type"
            placeholder="请选择"
            style="width:100%"
          >
            <el-option
              v-for="item of options"
              :key="item.value"
              :label="item.label"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="参与检查人员:"
          prop="checkPeople"
          :rules="{
            required: true,
            message: '请输入参与检查人员',
            trigger: 'bulr',
          }"
        >
          <el-input
            type="text"
            placeholder="请输入参与检查人员"
            v-model="model.checkPeople"
          >
          </el-input>
        </el-form-item>
        <div v-for="(item, index) in model.addCheckQuestionList" :key="index">
          <el-form-item label="" prop="correctivePhotosUri" label-width="0px">
            <span class="font-semibold">隐患问题{{ index + 1 }}</span
            ><br />
            <span class="font-medium">问题详情：</span>
            <el-input
              type="textarea"
              resize="none"
              :autosize="{ minRows: 3, maxRows: 5 }"
              placeholder="问题详情"
              v-model="item.hiddenQuestion"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="上传图片:" prop="uri" label-width="80px">
            <image-uploader
              v-model="item.uri"
              :modelSrc="item.uri"
              @change="urlchangeForm($event, index)"
            ></image-uploader>
          </el-form-item>
        </div>
      </el-form>
      <div class="flex justify-center q_class" @click="pulsClick">
        <i class="el-icon-plus"></i>
      </div>
      <div
        v-if="model.addCheckQuestionList.length > 1"
        class="flex justify-center q_class d_class"
        @click="mergencyremov"
      >
        <i class="el-icon-minus"></i>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="loadingAdd" type="primary" @click="addSubmit"
          >提交</el-button
        >
      </span>
    </el-dialog>
    <!-- 问题清单 -->
    <el-dialog
      title="三防问题详情"
      :visible.sync="checkDialogVisible"
      :close-on-click-modal="false"
      append-to-body
      width="70%"
      center
    >
      <ProblemDetails
        v-if="checkDialogVisible"
        :hiddenData="hiddenData"
      ></ProblemDetails>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCheckList,
  getDetails,
  correctiveFill,
  checkApprove,
  operatinghistoryListNew,
  saveQuestion,
} from "@/api/response";
import { getDictData } from "@/api/dict";
import ImageUploader from "@/components/Uploader/ImageUploader.vue";
import historyPage from "../ThreeResponse/history/historyPage.vue";
import ProblemDetails from "../ThreeResponse/history/problemDetails.vue";
// 整改/审批
import rectProblem from "./rectProblem.vue";
export default {
  components: {
    ImageUploader,
    historyPage,
    ProblemDetails,
    rectProblem,
  },
  name: "userList",
  data() {
    return {
      state: "",
      historyDialog: false,
      stateData: [
        {
          label: "待整改",
          value: 0,
        },
        {
          label: "待监理审批",
          value: 1,
        },
        {
          label: "审批不通过",
          value: 2,
        },
        {
          label: "完成",
          value: 3,
        },
      ],
      xianlu: "",
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },

      id: "",
      //详情
      // lineName: "",
      // siteName: "",
      // checkTime: "",
      // checkPeople: "",
      // checkItemContent: "",
      // uri: "",
      // uriList: [],
      // correctiveUser: {
      //   name: "",
      // },
      // correctiveTime: "",
      // correctiveDescription: "",
      // correctivePhotosUri: "",
      // correctivePhotosUriList: [],
      // correctiveApproveStatus: "",
      // rejectReason: "",
      dateTime: [],
      startTime: "",
      endTime: "",
      lineSite: [],
      lineSites: [],
      treeData: [],
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true,
      },
      defaultProps: {
        children: "children",
        label: "label",
      },
      list: [],

      // ruleForm: {
      //   parentId: "",
      //   intro: "",
      //   label: "",
      //   code: "",
      //   sort: "",
      //   parentLabel: "",
      // },
      loading: false,
      tableloading: false,
      // fromLoading: false,
      title: "",
      dialogVisible: false,
      emeTitle: "",
      rectId: "",
      // 查看
      hiddenData: {},
      checkDialogVisible: false,

      addAialogVisible: false,

      parentIdData: [],
      parentIdProps: {
        children: "nodes",
        label: "label",
      },
      rule: {
        label: [{ required: true, message: "请输入名称", trigger: "blur" }],
        code: [{ required: true, message: "请输入值", trigger: "blur" }],
        parentLabel: [
          { required: true, message: "请选择上级字典", trigger: "change" },
        ],
      },
      // rules: {
      //   correctiveDescription: [
      //     { required: true, message: "请输入整改描述", trigger: "blur" },
      //   ],
      //   rejectReason: [
      //     { required: true, message: "请输入审批意见", trigger: "blur" },
      //   ],
      //   correctivePhotosUri: [
      //     { required: true, message: "请上传图片", trigger: "change" },
      //   ],
      // },
      historyData: {},

      model: {
        addCheckQuestionList: [
          {
            id: "",
            uri: "",
            hiddenQuestion: "",
          },
        ],
        lineName: "",
        lineUuId: "",
        siteName: "",
        siteUuId: "",
      },
      pingxingArrList: [],
      lineId: "",
      siteId: "",
      propsForm: {
        children: "children",
        label: "name",
        value: "uuid",
      },
      loadingAdd: false,
      options: [],
      one: false,
      two: false,
      three: false,
    };
  },
  mounted() {
    this.getData();
    this.getLineSiteTreeData();
  },
  methods: {
    //新增
    opAdd() {
      this.title = "新增";
      this.addAialogVisible = true;
      this.gettype();
      this.restForm();
    },
    handleAddClose() {
      this.addAialogVisible = false;
    },
    pulsClick() {
      this.model.addCheckQuestionList.push({
        id: "",
        uri: "",
        hiddenQuestion: "",
      });
    },
    mergencyremov() {
      this.model.addCheckQuestionList.pop();
    },
    addSubmit() {
      this.model.addCheckQuestionList.forEach((item) => {
        if (item.hiddenQuestion == "" && item.uri == "") {
          this.one = false;
        } else if (item.hiddenQuestion == "" && item.uri != "") {
          this.two = false;
        } else if (item.hiddenQuestion != "" && item.uri == "") {
          this.three = false;
        } else {
          this.one = true;
          this.two = true;
          this.three = true;
        }
      });
      if (!this.one || !this.two || !this.three) {
        this.$message.warning("问题详情和图片必填");
      } else {
        this.$refs.model.validate((valid) => {
          if (valid) {
            this.loadingAdd = true;
            const params = JSON.parse(JSON.stringify(this.model));
            saveQuestion(params)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("操作成功");
                  this.getData();
                  this.addAialogVisible = false;
                  this.loadingAdd = false;
                  // this.$emit("formSubmit")
                  // this.$refs.formModal.handleClose()
                  this.restForm();
                } else {
                  this.loadingAdd = false;
                }
              })
              .catch((res) => {
                this.loadingAdd = false;
              });
          }
        });
      }
    },
    restForm() {
      this.model = {
        addCheckQuestionList: [
          {
            id: "",
            uri: "",
            hiddenQuestion: "",
          },
        ],
        lineName: "",
        lineUuId: "",
        siteName: "",
        siteUuId: "",
      };
    },
    handleLineSiteChangeFrom(val) {
      console.log(val);
      if (val.length > 0) {
        this.lineId = val[0];
        this.siteId = val[val.length - 1];
      }
      this.treeData.forEach((item) => {
        if (item.children && item.children !== null) {
          this.pingxingArr(item);
        }
        this.pingxingArrList.push(item);
        if (item.uuid == this.lineId) {
          this.model.lineName = item.name;
          this.model.lineUuId = item.uuid;
        }
      });
    },
    pingxingArr(data) {
      if (data.children && data.children !== null) {
        data.children.forEach((item) => {
          this.pingxingArrList.push(item);
          this.pingxingArr(item);
          if (item.uuid == this.siteId) {
            this.model.siteName = item.name;
            this.model.siteUuId = item.uuid;
          }
        });
      }
    },
    urlchangeForm(url, index) {
      this.addCheckQuestionList[index].uri = url;
    },
    gettype() {
      getDictData("question_type").then((res) => {
        this.options = res.data;
      });
    },

    getData() {
      this.tableloading = true;
      let params = {
        siteUuidList: this.lineSites,
        startTime: this.dateTime ? this.dateTime[0] : "",
        endTime: this.dateTime ? this.dateTime[1] : "",
        correctiveApproveStatus: this.state,
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
      };

      getCheckList(params).then((res) => {
        if (res.code == 0) {
          this.tableloading = false;
          this.list = res.data.list;
          this.page.count = res.data.total;
          //   this.parentIdData = this.getParentMenuTree(res.data);
        } else {
          this.parentIdData = [];
        }
      });
    },
    getHistoryData(row) {
      let params = {
        incidenceId: row.id,
        operationType: 921,
      };
      operatinghistoryListNew(params).then((res) => {
        if (res.code == 0) {
          this.historyData = res.data;
          this.historyDialog = true;
        }
      });
    },
    historyClose() {
      this.historyDialog = false;
    },
    correctiveApproveStatusFormatter(row) {
      return row.correctiveApproveStatus == 0
        ? "待整改"
        : row.correctiveApproveStatus == 1
        ? "待监理审批"
        : row.correctiveApproveStatus == 2
        ? "审批不通过"
        : row.correctiveApproveStatus == 3
        ? "完成"
        : "";
    },
    // 线路工点树数据查询
    getLineSiteTreeData() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.treeData = lineSiteData;
      }
    },
    handleLineSiteChange(val) {
      this.lineSite = val;
      this.lineSites = this.lineSite[0];
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getData();
    },
    // 选择时间
    handleDateChange(val) {
      this.dateTime = val;
    },
    // 获取上级机构树
    getParentMenuTree(tableTreeData) {
      let parent = {
        id: "0",
        label: "顶级菜单",
        nodes: tableTreeData,
      };
      return [parent];
    },
    // 上级单位触发方法
    handleCheckChange(data) {
      this.ruleForm.parentId = String(data.id);
      this.ruleForm.parentLabel = data.label;
    },
    //搜索
    findData() {
      this.getData();
    },

    // 修改
    opUpdata(data) {
      this.emeTitle = "整改";
      this.dialogVisible = true;
      this.rectId = data.id;
    },
    // 审批
    approve(data) {
      this.dialogVisible = true;
      this.emeTitle = "审批";
      this.rectId = data.id;
    },
    rectClose() {
      this.dialogVisible = false;
      this.getData();
    },
    // 关闭
    handleClose() {
      console.log("guanbi");
      this.dialogVisible = false;
      this.getData();
    },
    clearData() {
      (this.lineName = ""),
        (this.siteName = ""),
        (this.checkTime = ""),
        (this.checkPeople = "");
      this.checkItemContent = "";
      this.uri = "";
      this.correctiveUser.name = "";

      this.correctiveTime = "";
      this.correctiveDescription = "";
      this.correctivePhotosUri = "";
      this.correctiveApproveStatus = "";
      this.rejectReason = "";
    },
    // 历史操作
    OperHistory(row) {
      console.log(12312312, row);
    },
    //根据用户id获取详情
    userInfo(data) {
      getDetails(data.id).then((res) => {
        this.hiddenData = res.data;
      });
      this.checkDialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.dataInfo {
  width: 100%;
  padding: 0px 15px;
  box-sizing: border-box;
  .searchbar {
    display: flex;
    justify-content: space-between;
  }
  .search {
    padding: 1rem 0;
  }
}
// ::v-deep .el-table {
//   overflow: auto;
// }

.contentLeft {
  width: 200px;
  height: 700px;
  overflow-y: auto;
  margin-right: 20px;
  border: 1px solid rgb(207, 203, 203);
  margin-left: 1rem;
  float: left;
}
.px-4 {
  width: calc(100% - 220px - 1rem);
  float: right;
  height: 100%;
}
.el-pagination__jump {
  margin-top: -6px;
}
// ::v-deep .el-table {
//   height: 620px;
// }
.details {
  margin-bottom: 10px;
}

.q_class {
  background-color: #edf5fb;
  color: #85bee3;
  margin-top: 10px;
  font-size: 18px;
  cursor: pointer;
}
.d_class {
  color: #d7cbcb;
  background-color: #f86e72;
}
::v-deep .el-table--mini .el-table__cell {
  padding: 12px 0 !important;
}
.historyDialog ::v-deep .el-dialog__body {
  padding: 10px 20px;
}
.checkDialog ::v-deep .el-dialog__body {
  padding: 10px 20px 0;
}
.checkDialog ::v-deep .el-dialog__footer {
  margin: -12px auto 0;
  padding: 0 20px 10px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.dialog-footer ::v-deep .el-form-item__content {
  margin-left: 0 !important;
}
</style>
