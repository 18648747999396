import { httpInit } from "./http";

const http = httpInit("/gzdt-eqpt");

// const httpLine = httpInit("/gzdt-eqpt")

// 设备台账分页
export const getEquInfoList = params => http.post(`/aiEquipmentBaseInfo/page`, params)

export const getEquInfoAdd = params => http.post(`/aiEquipmentBaseInfo/save`, params)

export const getEquInfoEdit = params => http.post(`/aiEquipmentBaseInfo/updateById`, params)

export const tdSetMsg = params => http.post(`/towerDevice/saveOrUpdateTowerDeviceInfo`, params)

export const lmdSetMsg = params => http.post(`/gantryParameter/saveOrUpdateByEquipmentId`, params)

export const getInstall = params => http.post(`/cooper/aiEquipmentInstallationDismantlingRecord/page`, params)

export const getDeviceDetection = params => http.post(`/deviceDetection/page`, params)

export const getDeviceMaintenance = params => http.post(`/deviceMaintenance/page`, params)

export const getGantryDataList = params => http.post(`/gantryStatistics/getGantryDataList`, params)

export const getTowerRealTimeData = params => http.post(`/towerData/getTowerRealTimeData`, params)


export const tdError = params => http.post(`/towerData/getTowerDataWarnList`, params)

export const lmdError = params => http.post(`/gantryMonitor/page`, params)

export const filterPerson = params => http.post(`/cooper/aiEquipmentUser/getSpeWorkListByPersonName`, params)

export const setInstall = params => http.post(`/cooper/aiEquipmentInstallationDismantlingRecord/updateById`, params)

export const saveInstall = params => http.post(`cooper/aiEquipmentInstallationDismantlingRecord/save`, params)

export const setDeviceDetection = params => http.post(`/deviceDetection/updateById`, params)

export const saveDeviceDetection = params => http.post(`/deviceDetection/save`, params)

export const setDeviceMaintenance = params => http.post(`/deviceMaintenance/updateById`, params)

export const saveDeviceMaintenance = params => http.post(`/deviceMaintenance/save`, params)

export const setPerson = params => http.post(`/cooper/aiEquipmentUser/updateById`, params)

export const savePerson = params => http.post(`/cooper/aiEquipmentUser/save`, params)

export const getPerson = params => http.post(`/cooper/aiEquipmentUser/page`, params)


export const fileAdd = params => http.post(`/file/uploadFile`, params)

export const fileFind = params => http.post(`/aiEquiptmentFile/page`, params)

export const delDeviceDetection = params => http.post(`/deviceDetection/delete`, params)

export const delDeviceMaintenance = params => http.post(`/deviceMaintenance/delete`, params)

export const uploadFileNew = params => http.post(`/file/uploadFile_new`, params)

export const fileDel = params => http.post(`/aiEquiptmentFile/delete`, params)

export const aiEquipmentBaseInfoDelete = params => http.post(`/aiEquipmentBaseInfo/delete`, params)

export const saveAllCheck = params => http.post(`/deviceMaintenanceContent/saveOrUpdateBatch`, params)

export const saveDict = params => http.post(`/dict/save`, params)

export const batchDelete = params => http.post(`/dict/delete`, params)

export const realTimeList = params => http.post(`/aiEquipmentBaseInfo/realTimeDataPage`, params)


export const getGantryTrueTime = params => http.post(`/gantryMonitor/getGantryTrueTime`, params)

export const clearTowerWarn = params => http.post(`/towerData/dealAlarmById`, params)

export const gantryMonitorList = params => http.post(`/gantryMonitor/page`, params)

export const clearGantryWarn = params => http.post(`/gantryMonitor/dealAlarmById`, params)

export const updateGantry = params => http.post(`/gantryMonitor/updateById`, params)

export const uploadFile = params => http.post(`/aiEquiptmentFile/uploadFile`, params)

export const gethistoryTowerData = params => http.post(`/towerData/gethistoryTowerData`, params)

export const add = params => http.post(`/towerData/add`, params)

export const towerUpdate = params => http.post(`/towerDevice/updateById`, params)

export const getAlarmTypeList = params => http.post(`/towerData/getAlarmTypeList`, params)

export const gethistoryGantryCraneData = params => http.post(`/gantryMonitor/page`, params)

export const gantryCraneAdds = params => http.post(`/gantryMonitor/save`, params)

export const gantryCraneUpdateById = params => http.post(`/gantryMonitor/updateById`, params)

// 龙门吊数据导出
export const gantryCraneExport = params => http.post(`/gantryMonitor/export`, params, {
    //重要  没有她导出的文件会打不开
    responseType: 'blob'
});

// 导出质安部查询事件全部汇总数据
export const exportInfo = params => http.post(`/towerData/exportExcel`, params, {
    //重要  没有她导出的文件会打不开
    responseType: 'blob'
});


export const delInstall = id => http.delete(`/cooper/aiEquipmentInstallationDismantlingRecord/${id}`);

export const delPerson = id => http.delete(`/cooper/aiEquipmentUser/${id}`);

export const towerDel = id => http.delete(`/towerData/${id}`);

export const gantryDelete = id => http.delete(`/gantryMonitor/${id}`);

export const deleteById = ids => http.delete(`/towerData/${ids}`);

export const gantryCraneDeleteById = ids => http.delete(`/gantryMonitor/${ids}`);

export const updateById = params =>
    http.post(`/towerData/updateById`,params);

export const tdMsg = params =>
  http.get(`/towerDevice/getByEquipmentId/${params}`);

export const towerList = params =>
    http.get(`/towerSys/towerList?pageSize=${params.pageSize}&pageNum=${params.pageNum}
    &deviceName=${params.deviceName}&siteIdList=${params.siteIdList}`);

export const getListBySiteId = params =>
    http.get(`/cameraDev/getListBySiteId/${params}`);

export const getAllList = params =>
    http.get(`/deviceMaintenanceContent/list?equipmentType=${params.equipmentType}&maintenanceId=${params.maintenanceId}`);


export const towerWarnRulesList = params =>
    http.get(`/towerData/getTowerAlarmTypeList?id=${params.id}&name=${params.name}`);

export const gantryWarnRulesList = params =>
    http.get(`/gantryMonitor/getGantryAlarmTypeList?id=${params.id}&name=${params.name}`);

export const lmdMsg = params =>
    http.get(`/gantryParameter/getByEquipmentId/${params}`);

export const findFile = params =>
    http.get(`/aiEquiptmentFile/page?baseId=${params.baseId}&pageSize=${params.pageSize}&pageNum=${params.pageNum}`);


//根据id获取信息详情
export const getProDetail = params =>
    http.get(
        `/aiEquipmentBaseInfo/linesite/${params}`
    );

export const upload = (file, onUploadProgress) => {
    let form = new FormData();
    form.append("upfile", file);
    return http.post(`/file/upload`, form, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
        onUploadProgress
    });
};

// 导出预案管理
export const towerExport = (params) =>
    http.post(`/towerData/exportExcel`, params, {
        //重要  没有她导出的文件会打不开
        responseType: "blob",
    });


/**
 * 履带吊相关接口
 */

// 查询履带吊设备参数详情
export const crawlerMsg = params =>
  http.post(`/crawlerCraneParameter/getCrawlerCraneParameter`, params);
// 新增或修改设备参数接口
export const crawlerSaveOrUpdate = params =>
  http.post(`/crawlerCraneParameter/saveOrUpdateCrawlerCraneParameter`, params);
// 履带吊规则列表
export const getCrawlerAlarmTypeList = params =>
  http.get(`/crawlerCraneData/getAlarmTypeList?name=${params}`);

// 查询履数据列表接口
export const crawlerDataPage = params =>
  http.post(`/crawlerCraneData/page`, params);

// 履带吊数据新增-修改
export const crawlerDataSaveOrUpdate = params =>
  http.post(`/crawlerCraneData/saveOrUpdate`, params);
// 履带吊数据删除接口
export const crawlerDataDelete = params =>
  http.delete(`/crawlerCraneData/${params}`);

// 履带吊处理消警接口
export const crawlerDataDealAlarmById = params =>
  http.post(`/crawlerCraneData/dealAlarmById`, params);

// 履带吊数据导出接口
export const crawlerDataExport = params =>
  http.post(`/crawlerCraneData/export`, params, {
    //重要  没有她导出的文件会打不开
    responseType: "blob"
  });

/**
 * 设备安全表相关
 */

// 分页查询
export const findDevicePage = params =>
  http.post(`/deviceMaintenanceItem/page`, params);

// 删除
export const deleteDecive = params =>
  http.delete(`/deviceMaintenanceItem/${params}`);

// 新增修改
export const deviceMSaveOrUpdate = params =>
  http.post(`/deviceMaintenanceItem/saveOrUpdate`, params);
