<template>
  <systemModal
    ref="systemModal"
    @modalConfirm="handleSubmit"
    :title="title"
    width="50%"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="100px"
      class="demo-model"
    >
      <el-form-item label="线路工点" prop="lineSite">
        <el-cascader
          v-model="model.lineSite"
          :options="lineSiteData"
          @change="handleLineSiteChange"
          :props="props"
        ></el-cascader>
      </el-form-item>
      <div class="flex">
        <el-form-item style="width: 44.5%" label="检查单位" prop="unitId">
          <el-select
            v-model="model.unitId"
            placeholder="请选择"
            style="width: 100%"
            @change="handleUnitChange"
          >
            <el-option
              v-for="item in unitOptions"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 44.5%" label="检查日期" prop="checkDate">
          <el-date-picker
            v-model="model.checkDate"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      </div>
      <el-form-item label="检查人员" prop="safeCheckPerson">
        <el-select
          v-model="model.safeCheckPerson"
          multiple
          placeholder="请选择"
          @change="handlePersonChange"
        >
          <el-option
            v-for="item in person"
            :key="item.id"
            :label="item.realName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="附件" prop="file">
        <FileUploader
          @uploadChange="handleFileUpload"
          @delChange="handleFileDel"
          :value="fileList"
        ></FileUploader>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import FileUploader from "@/components/Uploader/FileUploader.vue";
import { queryOrg } from "@/api/org";
import { queryAccountList } from "@/api/ucenter";
import { addSafeCheck, editSafeCheck } from "@/api/map";
import { lineSiteTreeCascadeEcho } from "@/utils/common";
export default {
  components: {
    systemModal,
    FileUploader,
  },
  data() {
    return {
      title: "广州市轨道交通工程三防安全检查表",
      model: {
        lineSite: [],
        lineId: "",
        siteId: "",
        safeCheckPerson: [],
        checkDate: "",
        unitId: "",
        imageName: "",
        imageUrl: "",
      },
      fileList: [],
      lineSiteData: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
      },
      unitOptions: [],
      person: [],
      loading: false,
      rules: {
        lineSite: [
          { required: true, message: "请选择线路工点", trigger: "change" },
        ],
        unitId: [
          { required: true, message: "请选择检查单位", trigger: "change" },
        ],
        checkDate: [
          { required: true, message: "请选择检查日期", trigger: "change" },
        ],
        // safeCheckPerson: [
        //   { required: true, message: "请选择检查人员", trigger: "change" }
        // ]
      },
      //扁平的树
      faltArr: [],
    };
  },
  mounted() {
    this.queryOrg();
  },
  methods: {
    initLineSiteData() {
      const data = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (data) {
        this.lineSiteData = data;
        console.log(this.lineSiteData, "lineSiteData");
        if (this.lineSiteData) {
          this.lineSiteData.forEach((item) => {
            this.flatArray(item);
          });
        }
      }
    },
    flatArray(data) {
      this.faltArr.push(data);
      if (data.children && data.children.length > 0) {
        data.children.forEach((item) => {
          this.flatArray(item);
        });
      }
    },
    handleAdd(record) {
      this.model = Object.assign({}, record);
      this.initLineSiteData();
      this.handleEdit();
    },
    handleEdit() {
      if (this.model.id) {
        const treeEcho = lineSiteTreeCascadeEcho(this.model.siteId);
        console.log(treeEcho);
        this.$set(this.model, "lineSite", treeEcho);
        this.handleUnitChange(this.model.unitId);
        this.$set(this.model, "safeCheckPerson", []);
        for (let item of this.model.safeCheckStaff) {
          this.model.safeCheckPerson.push(item.staffId);
        }
        this.fileList = [
          {
            name: this.model.imageName,
            url: this.model.imageUrl,
          },
        ];
      }
      this.$refs.systemModal.handleOpen();
    },
    // 线路工点事件
    handleLineSiteChange(val) {
      if (val.length > 0) {
        this.model.lineId = val[0];
        this.model.siteId = val[val.length - 1];
      }
    },
    // 单位选择事件
    handleUnitChange(val) {
      console.log(val);
      let uuid = "";
      const tempObj = this.unitOptions.find((item) => item.id == val);
      if (tempObj) {
        uuid = tempObj.uuid;
      }
      queryAccountList({ companyId: uuid }).then((res) => {
        if (res.code == 0) {
          this.person = res.data;
        }
      });
    },
    // 人员选择事件
    handlePersonChange(record) {
      console.log(record);
    },
    queryOrg() {
      queryOrg().then((res) => {
        if (res.code == 0) {
          this.unitOptions = res.data;
        }
      });
    },
    handleFileUpload({ name, url }) {
      this.model.imageName = name;
      this.model.imageUrl = url;
    },
    handleFileDel({ name, url }) {
      this.model.imageName = name;
      this.model.imageUrl = url;
    },
    handleSubmit() {
      console.log(this.faltArr, "faltArr");
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          // 验证通过
          this.loading = true;
          const params = JSON.parse(JSON.stringify(this.model));
          params.safeCheckStaff = [];
          for (let item of this.model.safeCheckPerson) {
            const tempObj = this.person.find((p) => p.id == item);
            params.safeCheckStaff.push({
              staffId: item,
              staffName: tempObj.realName,
            });
          }
          let line = this.faltArr.find((item) => item.id == params.lineId);
          let site = this.faltArr.find((item) => item.id == params.siteId);
          params.lineName = line.name;
          params.siteName = site.name;
          if (params.id) {
            editSafeCheck(params).then((res) => {
              if (res.code == 0) {
                this.$emit("modelFormSubmit");
                this.$refs.systemModal.handleClose();
                this.$message.success("操作成功");
                this.loading = false;
              }
            });
          } else {
            addSafeCheck(params).then((res) => {
              if (res.code == 0) {
                this.$emit("modelFormSubmit");
                this.$refs.systemModal.handleClose();
                this.$message.success("操作成功");
                this.loading = false;
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-input {
//   width: 100% !important;
// }
</style>
