<template>
  <systemModal ref="floodModal" @modalConfirm="handleSubmit" :title="title">
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="100px"
      class="demo-model"
    >
      <el-form-item label="三防类型" prop="flood">
        <el-select
          v-model="model.flood"
          filterable
          placeholder="请选择三防类型"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
export default {
  components: {
    systemModal,
  },
  props: { linetree: {}, type: {} },
  data() {
    return {
      title: "选择三防类型",
      model: {
        video: "",
      },
      options: [],
      loading: false,
      rules: {
        flood: [
          { required: true, message: "请选择三防类型", trigger: "change" },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    handleAdd(record) {
      this.model = {
        video: "",
      };
      this.options = record;
      this.handleEdit();
    },
    handleName(e) {
      console.log(e);
    },
    handleEdit() {
      this.$refs.floodModal.handleOpen();
    },

    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.$emit("getFlood", this.model.flood);
          this.$refs.floodModal.handleClose();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.$refs.formModal.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
