<template>
  <div
    style="wieth: 100%; height: 100%; border-radius: 10px; position: relative"
  >
    <div class="search-wrapper">
      <el-cascader
        ref="linecas"
        placeholder="选择线路工点"
        v-model="lineSite"
        :options="options"
        :props="props"
        filterable
        clearable
      >
      </el-cascader>
      <span>
        <el-button type="primary" @click="querySite">查询</el-button>
      </span>
    </div>
    <baidu-map
      :center="page.center"
      :scroll-wheel-zoom="true"
      :zoom="page.zoom"
      class="map"
      @ready="handler"
      :mapClick="false"
    >
      <!-- 告警marker -->
      <bm-marker
        v-for="(data, index) in markerList"
        :key="data.coord[1] + index"
        :position="{ lng: data.coord[1], lat: data.coord[0] }"
        :dragging="false"
        :icon="{
          url: data.url,
          size: data.size,
        }"
        :title="data.truckNum"
        :offset="{ width: 0, height: -10 }"
        @click="infoWindowOpen(data)"
      >
        <!-- 告警信息窗口 -->
        <bm-info-window
          :position="{ lng: data.coord[1], lat: data.coord[0] }"
          :show="data.infoWindow.show"
          :closeOnClick="false"
          :width="340"
          :height="230"
        >
          <div class="info-header flex justify-between items-center">
            <p class="title flex items-center">{{ data.infoWindow.type }}</p>
            <span class="close-btn" @click="onInfoWindowClose(data)">X</span>
          </div>
          <div class="info-wrapper">
            <p class="address">发生地点：{{ data.infoWindow.address }}</p>
            <p class="state">事件状态：{{ data.infoWindow.state }}</p>
            <p class="date">发生时间：{{ data.infoWindow.date }}</p>
            <p class="leavel">事件等级：{{ data.infoWindow.leavel }}</p>
            <p class="reason">事件原因：{{ data.infoWindow.reason }}</p>
            <p>事件内容：{{ data.infoWindow.content }}</p>
          </div>
          <div
            class="triangle"
            style="width: 25px; height: 25px; bottom: -12px"
          ></div>
        </bm-info-window>
      </bm-marker>
      <!--工点图标-->
      <bm-marker
        v-for="data in workPoints"
        :key="data.uuid"
        :position="{ lng: data.coord[0], lat: data.coord[1] }"
        :dragging="false"
        :title="data.name"
        :animation="data.markerShow"
        :icon="{
          url: data.url,
          size: { width: 15, height: 15 },
        }"
        @mouseout="sitWindowCls(data)"
        @mouseover="handleMarkHover(data)"
        @click="jumpWorkPoint(data)"
      >
        <!-- 工点弹窗 -->
        <bm-info-window
          :position="{ lng: data.coord[1], lat: data.coord[0] }"
          :show="data.infoWindow.show"
          :closeOnClick="true"
          :width="320"
          :height="200"
          @close="siteWindowCls(data)"
          :offset="{ width: 5, height: 30 }"
        >
          <div
            id="bigdiv"
            style="z-index: 999"
            @mouseleave="InfoMouseLeave(data)"
            @mouseenter="InfoMouseEnter(data)"
          >
            <div class="info-header flex justify-between items-center">
              <p class="text-white flex items-center">{{ data.name }}</p>
              <span class="close-btn" @click="siteWindowCls(data)">X</span>
            </div>
            <div class="info-wrapper">
              <p class="address">
                工点类型：{{
                  gettypes == "" || gettypes == null ? "暂无" : gettypes
                }}
              </p>
              <p class="state">
                负责人员：{{ treu == "" || treu == null ? "数据更新中" : treu }}
              </p>
              <p class="date">
                包保人员：{{ ages == "" || ages == null ? "数据更新中" : ages }}
              </p>
              <p class="leavel">
                当日值班人员：<span></span>
                {{ dutyon == "" || dutyon == null ? "数据更新中" : dutyon }}
              </p>
            </div>
            <div class="btn-wrapper flex justify-between mt-2">
              <el-button type="primary" @click="materials(data)"
                >物资</el-button
              >
              <el-button type="primary" @click="facility(data)">设备</el-button>
              <el-button type="primary" @click="operatorDuty(data)"
                >值班人员</el-button
              >
            </div>
            <div
              class="triangle"
              style="width: 20px; height: 20px; bottom: -5px"
            ></div>
          </div>
        </bm-info-window>
      </bm-marker>
      <!-- 防汛关注重点图标 -->
      <bm-marker
        v-for="data in floodPreventionMarkerList"
        ref="marker"
        :key="data.id + 'te'"
        :position="{ lng: data.coord[1], lat: data.coord[0] }"
        :dragging="false"
        :icon="{
          url: data.url,
          size: { width: 32, height: 42 },
        }"
        @click="handleMarkClick(data)"
      >
        <!-- 防汛关注重点弹窗 -->
        <bm-info-window
          :position="{ lng: data.coord[1], lat: data.coord[0] }"
          :show="data.infoWindow.show"
          :closeOnClick="true"
          @close="onFloodPreventionWindowCls(data)"
          :width="365"
          :height="200"
        >
          <div class="info-header flex justify-between items-center">
            <p class="text-white flex items-center">{{ data.type.label }}</p>
            <span class="close-btn" @click="onFloodPreventionWindowCls(data)"
              >X</span
            >
          </div>
          <div class="info-wrapper">
            <p class="address">工点类型：{{ data.type.label }}</p>
            <p class="state">负责人员：数据对接中</p>
            <p class="date">包保人员：数据对接中</p>
            <p class="leavel">当日值班人员：数据对接中</p>
          </div>
          <div class="btn-wrapper flex justify-between mt-2">
            <el-button type="primary" @click="materials(data.site)"
              >物资</el-button
            >
            <el-button type="primary" @click="facility(data.site)"
              >设备</el-button
            >
            <el-button type="primary" @click="operatorDuty(data.site)"
              >值班人员</el-button
            >
            <el-button type="primary" @click="videoSurveillance(data)"
              >视频监控</el-button
            >
          </div>
          <div
            class="triangle"
            style="width: 20px; height: 20px; bottom: -5px"
          ></div>
        </bm-info-window>
      </bm-marker>
      <!--安置点-->
      <bm-marker
        v-for="data in anZhiMarkerList"
        :key="data.id"
        :position="{ lng: data.coord[1], lat: data.coord[0] }"
        :dragging="false"
        :title="data.resettlementSite"
        :icon="{
          url: data.url,
          size: { width: 20, height: 20 },
        }"
        :offset="{ width: 5, height: 15 }"
        @mouseout="pointout(data)"
        @mouseover="pointment(data)"
      >
        <bm-info-window
          :position="{ lng: data.coord[1], lat: data.coord[0] }"
          :show="data.marker.show"
          :closeOnClick="true"
          @close="onrescueteam(data)"
          :width="320"
          :height="200"
          :offset="{ width: 5, height: 140 }"
        >
          <div class="info-header flex justify-between items-center">
            <p
              class="text-white flex items-center"
              style="font-size: 14px; margin-top: 10px"
            >
              安置点名称:{{ data.resettlementSite }}
            </p>
          </div>

          <div
            class="triangle"
            style="width: 20px; height: 20px; bottom: -5px"
          ></div>
        </bm-info-window>
      </bm-marker>
      <!-- 救援队图标 -->
      <bm-marker
        v-for="data in rescueTeamMarkerList"
        :key="data.id + 400"
        :position="{ lng: data.coord[0], lat: data.coord[1] }"
        :dragging="false"
        :title="data.teamName"
        :icon="{
          url: data.url,
          size: { width: 20, height: 20 },
        }"
        @mouseout="placementOut(data)"
        @mouseover="placement(data)"
      >
        <!-- 救援队弹框 -->
        <bm-info-window
          :position="{ lng: data.coord[0], lat: data.coord[1] }"
          :show="data.arrange.show"
          :closeOnClick="true"
          @close="onrescueteam(data)"
          :width="320"
          :height="200"
          :offset="{ width: 5, height: 30 }"
        >
          <div class="info-header flex justify-between items-center">
            <p class="text-white flex items-center">{{ data.teamName }}</p>
          </div>
          <div class="info-wrapper">
            <p class="address">队长名称：{{ data.captainName }}</p>
            <p class="state">联系电话：{{ data.captainPhone }}</p>
            <p class="date">队伍驻地：{{ data.teamStation }}</p>
          </div>
          <div class="btn-wrapper flex justify-between mt-2">
            <el-button type="primary" @click="gorescue(data)"
              >物资信息</el-button
            >
            <el-button type="primary" @click="goteam(data)">人员信息</el-button>
          </div>
          <div
            class="triangle"
            style="width: 20px; height: 20px; bottom: -5px"
          ></div>
        </bm-info-window>
      </bm-marker>
      <!-- 救援队范围 -->
      <bm-circle
        v-for="data in rescueTeamMarkerList"
        :key="data.id"
        :center="{ lng: data.coord[0], lat: data.coord[1] }"
        :radius="10000"
        stroke-color="rgba(226, 60, 57, 1)"
        :fill-color="data.arrange.show ? 'rgba(226,60,57,0.3)' : '0'"
        :fillOpacity="data.arrange.show ? '0.5' : '0'"
        :strokeOpacity="data.arrange.show ? '0.5' : '0'"
        :stroke-weight="2"
        :editing="false"
      ></bm-circle>
      <!--普通塔式起重机-->
      <bm-marker
        v-show="isTaDiao"
        v-for="data in taDiaoMarkerList"
        :key="data.id + 1000000"
        :position="{ lng: data.coord[1], lat: data.coord[0] }"
        :dragging="false"
        :icon="{
          url: data.url,
          size: { width: 32, height: 42 },
        }"
        @click="handleRescueMarkClick(data)"
      ></bm-marker>
      <!--汽车吊/履带吊-->
      <bm-marker
        v-show="isQiChe"
        v-for="data in qiCheMarkerList"
        :key="data.id + 10000000"
        :position="{ lng: data.coord[1], lat: data.coord[0] }"
        :dragging="false"
        :icon="{
          url: data.url,
          size: { width: 32, height: 42 },
        }"
        @click="handleRescueMarkClick(data)"
      ></bm-marker>
      <!--龙门吊-->
      <bm-marker
        v-show="isLongMen"
        v-for="data in longMenMarkerList"
        :key="data.id + 100000000"
        :position="{ lng: data.coord[1], lat: data.coord[0] }"
        :dragging="false"
        :icon="{
          url: data.url,
          size: { width: 32, height: 42 },
        }"
        @click="handleRescueMarkClick(data)"
      ></bm-marker>
      <!--轮胎式起重机-->
      <bm-marker
        v-show="isQiZhong"
        v-for="data in qiZhongMarkerList"
        :key="data.id + 1000000000"
        :position="{ lng: data.coord[1], lat: data.coord[0] }"
        :dragging="false"
        :icon="{
          url: data.url,
          size: { width: 32, height: 42 },
        }"
        @click="handleRescueMarkClick(data)"
      ></bm-marker>
      <!-- 线路 -->
      <bm-polyline
        v-for="(data, index) of polylineList"
        :key="index"
        :path="data.polylinePath"
        :stroke-color="data.lineColors"
        :stroke-opacity="1"
        :stroke-weight="5"
        :editing="false"
      >
      </bm-polyline>
      <!-- <bm-polygon
        v-for="(data, index) of enclosureList"
        :key="index"
        :path="data.polygonPath"
        stroke-color="blue"
        :stroke-opacity="0.5"
        fillColor="blue"
        :fill-opacity="0.2"
        :stroke-weight="4"
      /> -->
    </baidu-map>
    <!-- 底部操作栏 -->
    <div class="handle-wrapper">
      <div class="handle-content flex justify-center">
        <div class="handle-item" style="width: 9.37vw">
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div
            class="
              item-content
              2xl:text-base
              xl:text-sm
              md:text-xs
              cursor-pointer
            "
            :class="isAnZhi ? '' : 'ops'"
            @click="selectAnZhi()"
          >
            <div class="item-content-anzhi">集中安置点</div>
          </div>
        </div>
        <div
          class="handle-item 2xl:text-base xl:text-sm md:text-xs"
          style="width: 9.37vw"
        >
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div
            class="
              item-content
              2xl:text-base
              xl:text-sm
              md:text-xs
              cursor-pointer
            "
            :class="isJiuYuan ? '' : 'ops'"
            @click="selectJiuYuan()"
          >
            <div class="item-content-wrapper">专职救援队</div>
          </div>
        </div>
        <div class="handle-item" style="width: 12.5vw">
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div class="item-content">
            <div class="item-content-wrapper">
              <img
                class="cursor-pointer"
                :class="isDiWa ? '' : 'ops'"
                title="地势低洼点"
                src="../../../../assets/map/diwa.png"
                alt=""
                @click="selectAttention('diwa')"
              />
              <img
                class="cursor-pointer"
                :class="isGaoPo ? '' : 'ops'"
                title="高边坡"
                src="../../../../assets/map/bianpo.png"
                alt=""
                @click="selectAttention('gaopo')"
              />
              <img
                class="cursor-pointer"
                :class="isHeYong ? '' : 'ops'"
                title="临近湖泊（河涌）"
                src="../../../../assets/map/hupo.png"
                alt=""
                @click="selectAttention('heyong')"
              />
              <img
                class="cursor-pointer"
                :class="isYouXian ? '' : 'ops'"
                title="临近既有线接口"
                src="../../../../assets/map/jiyouxian.png"
                alt=""
                @click="selectAttention('youxian')"
              />
            </div>
          </div>
        </div>
        <div class="handle-item" style="width: 12.5vw">
          <div class="corner-wrap">
            <div class="corner corner-left-top"></div>
            <div class="corner corner-right-top"></div>
            <div class="corner corner-right-bottom"></div>
            <div class="corner corner-left-bottom"></div>
          </div>
          <div class="item-content">
            <div class="item-content-wrapper">
              <img
                class="cursor-pointer"
                :class="isTaDiao ? '' : 'ops'"
                title="普通塔式起重机"
                src="../../../../assets/equipment-01.png"
                alt=""
                @click="selectEquipment('tadiao')"
              />
              <img
                class="cursor-pointer"
                :class="isQiChe ? '' : 'ops'"
                title="汽车吊/履带吊"
                src="../../../../assets/equipment-02.png"
                alt=""
                @click="selectEquipment('qiche')"
              />
              <img
                class="cursor-pointer"
                :class="isLongMen ? '' : 'ops'"
                title="龙门吊"
                src="../../../../assets/equipment-03.png"
                alt=""
                @click="selectEquipment('longmen')"
              />
              <img
                class="cursor-pointer"
                :class="isQiZhong ? '' : 'ops'"
                title="轮胎式起重机"
                src="../../../../assets/equipment-04.png"
                alt=""
                @click="selectEquipment('qizhong')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- // 救援队点击事件 -->
    <Modal
      class="modalDetail"
      ref="modalDetail"
      @closeDialog="closeDialog"
      :modal="false"
      :width="'1200px'"
      :title="modelTitle"
    >
      <div
        class="table-wrapper p-4 h-full"
        v-loading="pageLoading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        v-show="eltable"
      >
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          @cell-mouse-enter="handleCellMouseEnter"
          @cell-mouse-leave="handleCellMouseLeave"
        >
          <el-table-column align="center" label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            show-overflow-tooltip
            align="center"
          >
            <!-- <template slot-scope="scope">
              <span  v-if="item.prop == 'usages'">
  {{ JSON.parse(scope.row.usages).join(",") }}
              </span>
           </template> -->
          </el-table-column>
        </el-table>
        <div class="flex justify-center pt-4">
          <el-pagination
            style="z-index: 99"
            background
            layout="prev, pager, next"
              :total="pages.count"
            :page-count="pages.total"
            :page-size="pages.pageSize"
            :current-page="pages.pageNo"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
          <!-- <el-pagination
            @size-change="handleSizeChange"
            @prev-click="handleCurrentChange"
            @next-click="handleCurrentChange"
            @current-change="handleCurrentChange"
            :current-page="pages.pageNo"
            :page-size="pages.pageSize"
            layout="prev, pager, next, sizes,  total, jumper"
            :total="pages.total"
          >
          </el-pagination> -->
        </div>
        <!-- <div v-if="tableData.length == 0">暂无数据</div> -->
      </div>

      <div class="pyions" v-show="pyions">
        <!-- <el-descriptions> -->
        <el-card class="box-card">
          <div
            v-for="(item, index) in tlement"
            :key="index + 10086"
            class="text item"
          >
            {{ item.names }}： {{ item.propers }}
          </div>
        </el-card>
        <!-- </el-descriptions> -->
      </div>
    </Modal>
    <!-- 救援队弹框 -->
    <!-- @closeDialog="rescueDialog" -->
    <!-- 救援队弹框的人员信息 -->
    <Modal
      class="modalDetail"
      ref="rescueDetail"
      :modal="false"
      :width="'1200px'"
      :title="rescueTitle"
    >
      <div
        class="table-wrapper p-4 h-full"
        v-loading="pageLoading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        <el-table
          :data="rescueData"
          border
          style="width: 100%"
          max-height="500"
        >
          <el-table-column align="center" label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column
            v-for="item in rescueColumns"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            show-overflow-tooltip
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="item.prop == 'workerImg'">
                <img
                  v-if="scope.row.workerImg"
                  :src="scope.row.workerImg"
                  style="width: 75px; height: 75px; margin: auto;border:0"
                />
              </span>

              <span
                v-if="item.prop !== 'workerImg'"
                style="
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
                >{{ scope.row[item.prop] }}</span
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center pt-4">
          <el-pagination
            style="z-index: 99"
            background
            layout="prev, pager, next"
              :total="pages.count"
            :page-count="pages.total"
            :page-size="pages.pageSize"
            :current-page="pages.pageNo"
            @size-change="handlerescueChange"
            @current-change="hanrescueChange"
          >
          </el-pagination>
        </div>
      </div>
    </Modal>
    <!-- 物资设备的弹窗 -->
    <libraryPage
      ref="library"
      :libraryData="libraryData"
      :radio="typeData"
      :modelTitle="modelTitle"
    ></libraryPage>
    <!-- 值班人员的弹窗 -->
    <dutyTable
      ref="dutyTable"
      :modelTitle="modelTitle"
      :userSiteData="userSiteData"
    ></dutyTable>
    <!-- 防汛关注重点的视频 -->
    <videos49 ref="videosmodal"></videos49>
  </div>
</template>

<script>
//高德坐标转百度（传入经度、纬度）
function bd_encrypt(gg_lng, gg_lat) {
  var X_PI = (Math.PI * 3000.0) / 180.0;
  var x = gg_lng,
    y = gg_lat;
  var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
  var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
  var bd_lng = z * Math.cos(theta) + 0.0065;
  var bd_lat = z * Math.sin(theta) + 0.006;
  return {
    lat: bd_lng,
    lng: bd_lat,
  };
}
import dayjs from "dayjs";
import { mapStyle } from "../../../mapStyle";
import { getList, worksiteTree } from "@/api/map";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import {
  queryTeamLists,
  queryMentList,
  queryDutyList,
  queryWorkerListByPage,
  queryRescueTeamMemberList,
  getScreenMaterialsNew,
} from "@/api/eres";

import { getProofCoreList } from "@/api/proof";
import { queryMaterialsList } from "@/api/eres";

import Modal from "@/components/Modal/modal.vue";
import videos49 from "./videos49.vue";
import libraryPage from "@/views/ThreeProofings/HomePage/Map/libraryPage";
import dutyTable from "@/views/ThreeProofings/HomePage/Map/dutyTable.vue";

import GHCJ from "@/assets/lineData/GHCJ.json";
import threeLineDYD from "@/assets/lineData/3lineDYD.json";
import fiveLineDYD from "@/assets/lineData/5lineDataDYD.json";
import sevensLineRQ from "@/assets/lineData/7lineEQ.json";
import tenLine from "@/assets/lineData/10line.json";
import elevenLine from "@/assets/lineData/11line.json";
import twelveLine from "@/assets/lineData/12line.json";
import thirteenLine from "@/assets/lineData/13lineER.json";
import twentytwoAndeighteenLine from "@/assets/lineData/18and22line.json";
import FBCJ from "@/assets/lineData/FBCJ.json";

export default {
  name: "routeList",
  components: {
    Modal,
    videos49,
    libraryPage,
    dutyTable,
  },
  data() {
    return {
      lineSite: [],
      options: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
      },
      siteName: "", // 站点名称
      lineShow: false,
      pointShow: false,
      currentDate: dayjs(),
      mapStyle: mapStyle,
      tableData: [],
      columns: [],
      rescueTitle: "专职救援队",
      rescueData: [],
      usageCache: "",
      dict: {
        model: [],
        usages: [],
        state: [
          {
            label: "正常",
            value: 1,
          },
          {
            label: "异常",
            value: 2,
          },
        ],
      },
      rescueColumns: [
        {
          label: "人员名称",
          prop: "workerName",
        },
        {
          label: "队伍角色",
          prop: "workRoleName",
        },
        {
          label: "人员学历",
          prop: "workerEducation",
        },
        {
          label: "人员专业",
          prop: "workerSpecialty",
        },
        {
          label: "人员电话",
          prop: "workerPhone",
        },
        {
          label: "人员照片",
          prop: "workerImg",
        },
      ],
      pages: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        total: 0,
      },
      pageLoading: false,
      lineId: "",
      siteId: "",
      typeData: null,
      addDialog: {
        show: false,
        data: {},
        tree: [],
        title: "",
      },
      page: {
        start: "",
        end: "",
        center: { lng: 0, lat: 0 },
        zoom: 12,
      },
      modelTitle: "",
      // 告警点
      markerList: [],
      // 防汛关注重点
      floodPreventionMarkerList: [],
      //安置点
      anZhiMarkerList: [],
      secZhiMarkerList: [],
      // 救援队
      rescueTeamMarkerList: [],
      sectaDiaoMarkerList: [],
      //塔吊列表
      taDiaoMarkerList: [],
      //汽车吊/履带吊列表
      qiCheMarkerList: [],
      //龙门吊列表
      longMenMarkerList: [],
      //轮胎式起重机列表
      qiZhongMarkerList: [],
      // 线路坐标集合
      polylineList: [],
      // 工点坐标集合
      workPoints: [],
      // 电子围栏坐标点集合
      // enclosureList: [],
      zoomFlag: false,
      dictList: {},
      sheBeiList: [], //大型设备
      isTaDiao: false,
      isQiChe: false,
      isLongMen: false,
      isQiZhong: false,
      isDiWa: false,
      isGaoPo: false,
      isHeYong: false,
      isYouXian: false,
      isJiuYuan: false,
      isAnZhi: false,
      allAttention: [],
      fangxunData: {}, //地图防汛关注弹窗数据
      gettypes: "",
      getterlidt: [],
      treu: "",
      packages: [],
      ages: "",
      peron: [],
      dutyon: "",
      eltable: false,
      pyions: false,
      tlement: [], //安置点详情
      dataer: {},
      listTree: [], //工点信息
      libraryData: {}, //物资设备的工点data
      userSiteData: {}, //值班人员工点data
    };
  },
  mounted() {
    this.getDictData("物资用途", this.dict.usages);
    this.getLineTree();
    this.worksiteDetails(); //查询工点信息
    this.getDictList(); //查询字典表
    this.getSheBei(); //查询大型设备

    this.getAnZhi(); //查询安置点
    this.getJiuYuan(); //查询救援队
    this.getGuanZhu(); //查询关注
  },
  methods: {
    // 防汛关注中点的视频监控
    videoSurveillance(data) {
      this.$refs.videosmodal.handelOpen(data);
      console.log(data);
    },
    async getLineTree() {
      console.log(JSON.parse(localStorage.lineSiteTree));
      if (localStorage.lineSiteTree) {
        this.options = JSON.parse(localStorage.lineSiteTree);
        console.log(this.options);
      } else {
        const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
        if (lineSiteData) {
          this.options = lineSiteData;
          this.options.forEach((e) => {
            if (e.children.length != 0) {
              e.children.forEach((el) => {
                delete el.children;
              });
            }
          });
          localStorage.lineSiteTree = JSON.stringify(this.options);
        }
      }
    },
    querySite() {
      if (this.lineSite.length != 0) {
        this.workPoints.forEach((e) => {
          console.log(e);
          if (this.lineSite[this.lineSite.length - 1] == e.id) {
            this.page = {
              start: "",
              end: "",
              center: { lng: e.coord[0], lat: e.coord[1] },
              zoom: 14,
            };
            e.markerShow = "BMAP_ANIMATION_BOUNCE";
            setTimeout(() => {
              e.markerShow = "";
            }, 3000);
          }
        });
      } else {
        this.page = {
          start: "",
          end: "",
          center: { lng: 113.2839488029, lat: 23.0907270045 },
          zoom: 14,
        };
      }
    },
    placement(data) {
      console.log(data);
      console.log(this.rescueTeamMarkerList);
      const index = this.rescueTeamMarkerList.findIndex(
        (item) => item.id == data.id
      );
      this.rescueTeamMarkerList[index].arrange.show = true;
      console.log(this.rescueTeamMarkerList);
      // data.arrange.show = true;
    },
    placementOut(data) {
      console.log(data);
      // data.arrange.show = false;
      const index = this.rescueTeamMarkerList.findIndex(
        (item) => item.id == data.id
      );
      setTimeout(() => {
        if (!this.lineShow && !this.pointShow) {
          this.rescueTeamMarkerList[index].arrange.show = false;
        }
      }, 1000);
    },
    pointment(data) {
      const index = this.anZhiMarkerList.findIndex(
        (item) => item.id == data.id
      );
      this.anZhiMarkerList[index].marker.show = true;
    },
    pointout(data) {
      const index = this.anZhiMarkerList.findIndex(
        (item) => item.id == data.id
      );
      setTimeout(() => {
        if (!this.lineShow && !this.pointShow) {
          this.anZhiMarkerList[index].marker.show = false;
        }
      }, 1000);
    },

    //救援队物资
    gorescue(data) {
      this.pages.pageNo=1
      this.pages.pageSize=10
      console.log(data);
      this.dataer = data;
      this.tableData = [];
      this.pageLoading = true;
      this.modelTitle = "救援队物资信息";
      this.columns = [
        { prop: "label", label: "名称" },
        { prop: "model", label: "规格型号" },
        { prop: "standardCount", label: "数量" },
        { prop: "usages", label: "用途" },
        { prop: "stateLabel", label: "性能状况" },
        { prop: "created", label: "时间" },
        { prop: "remark", label: "备注" },
      ];
      this.eltable = true;
      this.pyions = false;
      getScreenMaterialsNew({
        materialType: 2,
        page: this.pages.pageNo,
        size: this.pages.pageSize,
        rescueTeamId: data.id,
      }).then((res) => {
        console.log(res);
        this.pageLoading = false;
        if (res.data.records && res.data.records.length != 0) {
          this.tableData = res.data.records;
          this.pages.count = res.data.count;
          this.pages.pageSize = res.data.size;
          this.pages.total = res.data.total;
          this.pages.pageNo = res.data.current;
          console.log(this.tableData);
          this.$refs.modalDetail.handleOpen();
          console.log(888888888888888);
        } else if (!res.data.records) {
          this.$refs.modalDetail.handleOpen();
          this.pageLoading = false;
        }
      });
    },
    //救援队人员
    goteam(data) {
      this.pages.pageNo=1
      this.pages.pageSize=10
      this.dataer = data;

      this.pageLoading = true;
      this.rescueTitle = "救援队人员信息";
      queryRescueTeamMemberList({
        name: "-",
        page: this.pages.pageNo,
        size: this.pages.pageSize,
        postId: "-",
        teamId: data.id,
      }).then((res) => {
        if (res.data.records && res.data.records.length != 0) {
          this.rescueData = res.data.records;
          this.pages.count = res.data.count;
          this.pages.pageSize = res.data.size;
          this.pages.total = res.data.total;
          this.pages.pageNo = res.data.current;
          this.$refs.rescueDetail.handleOpen();
          this.pageLoading = false;
        } else if (!res.data.records) {
          this.$refs.rescueDetail.handleOpen();
          this.pageLoading = false;
        }
      });
    },
    onrescueteam() {},
    //获取父组件传递的ws数据
    getwsData(res) {
      let that = this;
      if (
        res.data != "PING" &&
        res.data != "suc|欢迎登录广州地铁安全风险监管工具消息中心"
      ) {
        let usData = JSON.parse(res.data);
        let datas = usData.body;
        if (datas && datas.coord) {
          let p = bd_encrypt(datas.coord[1], datas.coord[0]);
          console.log(p);
          datas.coord = [p.lat, p.lng];
        }
        if (usData.msgType && usData.msgType === "TRUCK_COORD") {
          //实时位置推送
          if (that.markerList.length > 0) {
            let u = that.markerList.find((e) => e.truckNum === datas.truckNum);
            if (u) {
              u.msgType = usData.msgType;
              u.url = require("../../../../assets/map/dingw-blue.png");
              u.coord = datas.coord;
            } else {
              datas.msgType = usData.msgType;
              datas.url = require("../../../../assets/map/dingw-blue.png");
              that.markerList.push(datas);
            }
          } else {
            datas.msgType = usData.msgType;
            datas.url = require("../../../../assets/map/dingw-blue.png");
            that.markerList.push(datas);
          }
        } else if (
          //超时报警推送和 位置偏移推送
          usData.msgType === "TIMEOUT_ALERT" ||
          usData.msgType === "MOVE_ALERT" ||
          usData.msgType === "ADV_FINISH_TASK"
        ) {
          let u = that.markerList.find((e) => e.truckNum === datas.truckNum);
          if (u) {
            u.msgType = usData.msgType;
            u.url = require("../../../../assets/map/dingw-red.png");
          } else {
            if (
              usData.msgType === "MOVE_ALERT" ||
              usData.msgType === "ADV_FINISH_TASK"
            ) {
              datas.msgType = usData.msgType;
              datas.url = require("../../../../assets/map/dingw-red.png");
              that.markerList.push(datas);
            }
          }
        } else if (usData.msgType === "FINISHED_TASK") {
          //任务完成推送
          let u = that.markerList.find((e) => e.truckNum === datas.truckNum);
          console.log(u);
          if (u) {
            that.markerList = that.markerList.filter(
              (item) => item.truckNum != u.truckNum
            );
          }
        }
      }
    },
    //地图加载后事件
    handler({ BMap, map }) {
      console.log(BMap);
      map.setMapStyleV2({
        styleId: "565ab0773031556035c58e365ee8547a",
      });

      this.page = {
        start: "",
        end: "",
        center: { lng: 113.2839488029, lat: 23.0907270045 },
        zoom: 14,
      };
    },
    //告警图标点击事件
    infoWindowOpen(data) {
      const index = this.markerList.findIndex((item) => item.id == data.id);
      this.markerList[index].infoWindow.show = true;
    },
    // 告警信息窗口关闭
    onInfoWindowClose(data) {
      const index = this.markerList.findIndex((item) => item.id == data.id);
      this.markerList[index].infoWindow.show = false;
    },

    //查询地铁线路工点树
    worksiteDetails() {
      const lineDatails = JSON.parse(localStorage.getItem("DetailsTree"));
      let list = [];
      console.log("2222", lineDatails);
      if (lineDatails) {
        list = lineDatails;

        this.mapTree(list);
      } else {
        worksiteTree({}).then((res) => {
          if (res.code == 0) {
            localStorage.setItem("DetailsTree", JSON.stringify(res.data));
            list = res.data;
            this.mapTree(list);
          }
        });
      }
    },
    treeToList(list) {
      let res = [];
      for (const item of list) {
        const { children, ...i } = item;
        if (children && children.length) {
          res = res.concat(this.treeToList(children));
        }
        res.push(i);
      }
      return res;
    },

    //地图绘点
    mapTree(data) {
      // let con = list.filter((e) => e.name == "广州市轨道交通十号线");
      // 线路
      let lineNameList = [
        "芳白城际",
        "5号线东延段",
        "14号线二期",
        "18和22号线",
        "广花城际",
        "13号线二期",
        "3号线东延段",
        "10号线",
        "11号线",
        "12号线",
        "7号线二期",
      ];
      lineNameList.forEach((item) => {
        if (item == "芳白城际") {
          this.polylineList.push(
            {
              polylinePath: FBCJ.芳白城际右线,
              lineColors: "#00a5ff",
            },
            {
              polylinePath: FBCJ.芳白城际左线,
              lineColors: "#00a5ff",
            }
          );
        } else if (item == "5号线东延段") {
          this.polylineList.push(
            {
              polylinePath: fiveLineDYD.five号线东延1,
              lineColors: "#45d6b7",
            },
            {
              polylinePath: fiveLineDYD.five号线东延2,
              lineColors: "#45d6b7",
            },
            {
              polylinePath: fiveLineDYD.five号线东延3,
              lineColors: "#45d6b7",
            },
            {
              polylinePath: fiveLineDYD.five号线东延4,
              lineColors: "#45d6b7",
            },
            {
              polylinePath: fiveLineDYD.five号线东延5,
              lineColors: "#45d6b7",
            }
          );
        } else if (item == "14号线二期") {
          console.log("14号线只有工点没有线路#ff6600");
        } else if (item == "18和22号线") {
          this.polylineList.push(
            {
              polylinePath: twentytwoAndeighteenLine.twentytwo号线右线,
              lineColors: "#6054ff",
            },
            {
              polylinePath: twentytwoAndeighteenLine.twentytwo号线左线,
              lineColors: "#6054ff",
            },
            {
              polylinePath: twentytwoAndeighteenLine.eighteen号线右线,
              lineColors: "#6054ff",
            },
            {
              polylinePath: twentytwoAndeighteenLine.eighteen号线左线,
              lineColors: "#6054ff",
            }
          );
        } else if (item == "广花城际") {
          this.polylineList.push(
            {
              polylinePath: GHCJ.广花城际右线,
              lineColors: "#006aff",
            },
            {
              polylinePath: GHCJ.广花城际左线,
              lineColors: "#006aff",
            },
            {
              polylinePath: GHCJ.广花城际出入段线1,
              lineColors: "#006aff",
            },
            {
              polylinePath: GHCJ.广花城际出入段线2,
              lineColors: "#006aff",
            },
            {
              polylinePath: GHCJ.广花城际出入段线3,
              lineColors: "#006aff",
            },
            {
              polylinePath: GHCJ.广花城际出入段线4,
              lineColors: "#006aff",
            }
          );
        } else if (item == "13号线二期") {
          this.polylineList.push(
            {
              polylinePath: thirteenLine.thirteen号线二期8,
              lineColors: "#8700e0",
            },
            {
              polylinePath: thirteenLine.thirteen号线二期3,
              lineColors: "#8700e0",
            },
            {
              polylinePath: thirteenLine.thirteen号线二期6,
              lineColors: "#8700e0",
            },
            {
              polylinePath: thirteenLine.thirteen号线二期1,
              lineColors: "#8700e0",
            },
            {
              polylinePath: thirteenLine.thirteen号线二期4,
              lineColors: "#8700e0",
            },
            {
              polylinePath: thirteenLine.thirteen号线二期7,
              lineColors: "#8700e0",
            },
            {
              polylinePath: thirteenLine.thirteen号线二期2,
              lineColors: "#8700e0",
            },
            {
              polylinePath: thirteenLine.thirteen号线二期5,
              lineColors: "#8700e0",
            }
          );
        } else if (item == "3号线东延段") {
          this.polylineList.push(
            {
              polylinePath: threeLineDYD.三号线东延段左线1,
              lineColors: "#00d400",
            },
            {
              polylinePath: threeLineDYD.三号线东延段右线,
              lineColors: "#00d400",
            },
            {
              polylinePath: threeLineDYD.三号线东延段停车场支线1,
              lineColors: "#00d400",
            },
            {
              polylinePath: threeLineDYD.三号线东延段停车场支线2,
              lineColors: "#00d400",
            }
          );
        } else if (item == "10号线") {
          this.polylineList.push(
            {
              polylinePath: tenLine.ten号线左线,
              lineColors: "#f7e51b",
            },
            {
              polylinePath: tenLine.ten号线右线,
              lineColors: "#f7e51b",
            }
          );
        } else if (item == "11号线") {
          this.polylineList.push(
            {
              polylinePath: elevenLine.eleven号线1,
              lineColors: "#fc8421",
            },
            {
              polylinePath: elevenLine.eleven号线2,
              lineColors: "#fc8421",
            },
            {
              polylinePath: elevenLine.eleven号线3,
              lineColors: "#fc8421",
            },
            {
              polylinePath: elevenLine.eleven号线4,
              lineColors: "#fc8421",
            },
            {
              polylinePath: elevenLine.eleven号线5,
              lineColors: "#fc8421",
            },
            {
              polylinePath: elevenLine.eleven号线6,
              lineColors: "#fc8421",
            },
            {
              polylinePath: elevenLine.eleven号线7,
              lineColors: "#fc8421",
            }
          );
        } else if (item == "12号线") {
          this.polylineList.push(
            {
              polylinePath: twelveLine.twelve号线1,
              lineColors: "#f5b400",
            },
            {
              polylinePath: twelveLine.twelve号线2,
              lineColors: "#f5b400",
            },
            {
              polylinePath: twelveLine.twelve号线3,
              lineColors: "#f5b400",
            },
            {
              polylinePath: twelveLine.twelve号线4,
              lineColors: "#f5b400",
            },
            {
              polylinePath: twelveLine.twelve号线5,
              lineColors: "#f5b400",
            },
            {
              polylinePath: twelveLine.twelve号线6,
              lineColors: "#f5b400",
            },
            {
              polylinePath: twelveLine.twelve号线7,
              lineColors: "#f5b400",
            },
            {
              polylinePath: twelveLine.twelve号线8,
              lineColors: "#f5b400",
            }
          );
        } else if (item == "7号线二期") {
          this.polylineList.push(
            {
              polylinePath: sevensLineRQ.seven号线二期1,
              lineColors: "#e06060",
            },
            {
              polylinePath: sevensLineRQ.seven号线二期2,
              lineColors: "#e06060",
            },
            {
              polylinePath: sevensLineRQ.seven号线二期3,
              lineColors: "#e06060",
            },
            {
              polylinePath: sevensLineRQ.seven号线二期4,
              lineColors: "#e06060",
            },
            {
              polylinePath: sevensLineRQ.seven号线二期5,
              lineColors: "#e06060",
            }
          );
        }
      });

      //工点
      let colors = "#01963F";
      let url = require("../../../../assets/map/subwayIcon.png");
      data.forEach((e) => {
        switch (e.name) {
          case "广州市轨道交通十八号线":
            colors = "#01963F";
            url = require("../../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通13号线二期线路":
            colors = "#026CB6";
            url = require("../../../../assets/map/subwayIcon.png");
            break;
          case "广州市城市轨道交通十四号线二期工程":
            colors = "#956339";
            url = require("../../../../assets/map/subwayIcon.png");
            break;
          case "广州东至花都天贵城际项目":
            colors = "#901D42";
            url = require("../../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通二十二号线":
            colors = "#E6037D";
            url = require("../../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通十二号线及同步实施工程总承包项目":
            colors = "#950885";
            url = require("../../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通五号线东延段及同步实施工程":
            colors = "#F977A3";
            url = require("../../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通十号线":
            colors = "#99D317";
            url = require("../../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通7号线二期线路":
            colors = "#B159D4";
            url = require("../../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通11号线路":
            colors = "#55CAE2";
            url = require("../../../../assets/map/subwayIcon.png");
            break;
          case "广州市轨道交通三号线东延段":
            colors = "#5E5EF7";
            url = require("../../../../assets/map/subwayIcon.png");
            break;
          case "芳白城际":
            colors = "#95D30C";
            url = require("../../../../assets/map/subwayIcon.png");
            break;
          default:
            break;
        }
        const nodes = this.treeToList(e.children);

        for (let item of nodes) {
          // 将返回的围栏坐标集合并到
          if (item.lat != 0) {
            let p = bd_encrypt(item.lng, item.lat);

            // 添加工点
            item.coord = [p.lat, p.lng];
            // item.coord = [p.lng, p.lat];

            item.url = url;
            item.infoWindow = {
              show: false,
            };
            item.markerShow = "";
            this.workPoints.push(item);
          }

          // if (item.fance !== null) {
          //   let line = {
          //     id: e.id,
          //     name: e.name,
          //     polylinePath: [],
          //     color: colors,
          //   };
          //   item.fance.forEach((el) => {
          //     let q = bd_encrypt(el.lng, el.lat);
          //     let d = { lng: q.lat, lat: q.lng };
          //     line.polylinePath.push(d);
          //   });
          //   this.polylineList.push(line);
          // }
        }
      });
    },
    // 查询设备字典
    getDictList() {
      // let params = {
      //   id: "67",
      // };
      // queryDictNodesById(params).then((res) => {
      //   if (res.code == 0) {
      //     console.log(res);
      //   }
      // });
    },
    // 查询大型设备
    getSheBei() {
      let params = {
        type: "67",
        lineId: "-",
        siteId: "-",
        state: "-",
        is_special: "-",
        is_using: "-",
      };
      getList(params).then((res) => {
        if (res.code == 0) {
          console.log(res);
          this.sheBeiList = res.data;
          console.log(this.sheBeiList);
        }
      });
    },
    // 查询集中安置点
    getAnZhi() {
      let params = {
        lineId: "-",
        siteId: "-",
        departmentId: "-",
        resettlementSite: "-",
      };
      queryMentList(params).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          list.forEach((e) => {
            e.url = require("../../../../assets/map/anzhi.png");
            let p = bd_encrypt(e.lng, e.lat);
            e.coord = [p.lng, p.lat];
            e.infoWindow = {
              show: false,
            };
            e.marker = {
              show: false,
            };
          });
          this.secZhiMarkerList = list;
        }
      });
    },
    //选择安置点事件
    selectAnZhi() {
      if (this.isAnZhi) {
        this.anZhiMarkerList = [];
      } else {
        this.anZhiMarkerList = this.secZhiMarkerList;
        this.anZhiMarkerList.forEach((item) => {
          item.marker.show = false;
        });
        console.log(this.anZhiMarkerList);
      }
      this.isAnZhi = !this.isAnZhi;
    },
    // 关注重点
    getGuanZhu() {
      getProofCoreList().then((res) => {
        if (res.code == 0) {
          let list = res.data;
          list.forEach((e) => {
            if (e.coordinate) {
              this.allAttention.push(e);
            }
          });
        }
      });
    },
    // 查询专职救援队
    getJiuYuan() {
      let params = {
        teamType: 1,
        personName: "-",
        materialsName: "-",
        teamName: "-",
      };
      queryTeamLists(params).then((res) => {
        if (res.code == 0) {
          console.log(res);
          let list = res.data;
          list.forEach((e) => {
            e.url = require("../../../../assets/map/jiuyuan.png");
            // let p = bd_encrypt(e.lng, e.lat);
            e.coord = [e.lng, e.lat];
            e.infoWindow = {
              show: false,
            };
            e.arrange = {
              show: false,
            };
          });
          this.sectaDiaoMarkerList = list;
        }
      });
    },
    //选择救援队事件
    selectJiuYuan() {
      if (this.isJiuYuan) {
        this.rescueTeamMarkerList = [];
      } else {
        this.rescueTeamMarkerList = this.sectaDiaoMarkerList;
        this.rescueTeamMarkerList.forEach((item) => {
          item.arrange.show = false;
        });
      }
      this.isJiuYuan = !this.isJiuYuan;
      console.log(this.rescueTeamMarkerList);
    },
    //选择大型设备事件
    selectEquipment(data) {
      console.log(data);
      switch (data) {
        case "tadiao":
          if (this.isTaDiao) {
            this.taDiaoMarkerList = [];
          } else {
            this.sheBeiList.forEach((e) => {
              if (e.name == "普通塔式起重机") {
                e.url = require("../../../../assets/equipment-01.png");
                let p = bd_encrypt(e.lng, e.lat);
                e.coord = [p.lng, p.lat];
                this.taDiaoMarkerList.push(e);
                console.log(this.taDiaoMarkerList);
              }
            });
          }
          this.isTaDiao = !this.isTaDiao;

          break;
        case "qiche":
          if (this.isQiChe) {
            this.qiCheMarkerList = [];
          } else {
            this.sheBeiList.forEach((e) => {
              if (e.name == "汽车吊" || e.name == "履带吊") {
                e.url = require("../../../../assets/equipment-02.png");
                let p = bd_encrypt(e.lng, e.lat);
                e.coord = [p.lng, p.lat];
                this.qiCheMarkerList.push(e);
              }
            });
          }
          this.isQiChe = !this.isQiChe;
          break;
        case "longmen":
          if (this.isLongMen) {
            this.longMenMarkerList = [];
          } else {
            this.sheBeiList.forEach((e) => {
              if (e.name == "龙门吊") {
                e.url = require("../../../../assets/equipment-03.png");
                let p = bd_encrypt(e.lng, e.lat);
                e.coord = [p.lng, p.lat];
                this.longMenMarkerList.push(e);
              }
            });
          }
          this.isLongMen = !this.isLongMen;
          break;
        case "qizhong":
          if (this.isQiZhong) {
            this.qiZhongMarkerList = [];
          } else {
            this.sheBeiList.forEach((e) => {
              if (e.name == "轮胎起重机") {
                e.url = require("../../../../assets/equipment-04.png");
                let p = bd_encrypt(e.lng, e.lat);
                e.coord = [p.lng, p.lat];
                this.qiZhongMarkerList.push(e);
                console.log(this.qiZhongMarkerList);
              }
            });
          }
          this.isQiZhong = !this.isQiZhong;
          break;
      }
      console.log(this.qiZhongMarkerList);
    },
    //选择三防关注重点
    selectAttention(data) {
      console.log(data);
      switch (data) {
        case "diwa":
          if (this.isDiWa) {
            this.floodPreventionMarkerList = this.floodPreventionMarkerList.filter(
              (e) => e.type.label != "地势低洼工点"
            );
            this.floodPreventionMarkerList = this.floodPreventionMarkerList.filter(
              (e) => e.type.label != "地势低洼工点"
            );
          } else {
            this.allAttention.forEach((e) => {
              if (e.type.label == "地势低洼工点") {
                e.url = require("../../../../assets/map/diwa.png");
                let p = bd_encrypt(e.coordinate.lng, e.coordinate.lat);
                e.coord = [p.lng, p.lat];
                e.infoWindow = {
                  show: false,
                };
                this.floodPreventionMarkerList.push(e);
              }
            });
          }
          this.isDiWa = !this.isDiWa;

          break;
        case "gaopo":
          if (this.isGaoPo) {
            this.floodPreventionMarkerList = this.floodPreventionMarkerList.filter(
              (e) => e.type.label != "高边坡"
            );
            this.floodPreventionMarkerList = this.floodPreventionMarkerList.filter(
              (e) => e.type.label != "高边坡"
            );
          } else {
            this.allAttention.forEach((e) => {
              if (e.type.label == "高边坡") {
                e.url = require("../../../../assets/map/bianpo.png");
                let p = bd_encrypt(e.coordinate.lng, e.coordinate.lat);
                e.coord = [p.lng, p.lat];
                e.infoWindow = {
                  show: false,
                };
                this.floodPreventionMarkerList.push(e);
              }
            });
          }
          this.isGaoPo = !this.isGaoPo;
          break;
        case "heyong":
          if (this.isHeYong) {
            this.floodPreventionMarkerList = this.floodPreventionMarkerList.filter(
              (e) => e.type.label != "河涌"
            );
            this.floodPreventionMarkerList = this.floodPreventionMarkerList.filter(
              (e) => e.type.label != "河涌"
            );
          } else {
            this.allAttention.forEach((e) => {
              if (e.type.label == "河涌") {
                e.url = require("../../../../assets/map/hupo.png");
                let p = bd_encrypt(e.coordinate.lng, e.coordinate.lat);
                e.coord = [p.lng, p.lat];
                e.infoWindow = {
                  show: false,
                };
                this.floodPreventionMarkerList.push(e);
              }
            });
          }
          this.isHeYong = !this.isHeYong;
          break;
        case "youxian":
          if (this.isYouXian) {
            this.floodPreventionMarkerList = this.floodPreventionMarkerList.filter(
              (e) => e.type.label != "临近既有线路"
            );
            this.floodPreventionMarkerList = this.floodPreventionMarkerList.filter(
              (e) => e.type.label != "临近既有线路"
            );
          } else {
            this.allAttention.forEach((e) => {
              if (e.type.label == "临近既有线路") {
                e.url = require("../../../../assets/map/jiyouxian.png");
                let p = bd_encrypt(e.coordinate.lng, e.coordinate.lat);
                e.coord = [p.lng, p.lat];
                e.infoWindow = {
                  show: false,
                };
                this.floodPreventionMarkerList.push(e);
              }
            });
            console.log(this.floodPreventionMarkerList);
            this.floodPreventionMarkerList.forEach((item) => {
              console.log(item.site);
            });
          }
          this.isYouXian = !this.isYouXian;
          break;
      }
    },
    //获取关注弹窗详情
    getguanzhu(data) {
      let params = {
        lineId: "-",
        siteId: data,
        grouping: "-",
        realName: "-",
      };
      queryDutyList(params).then((res) => {
        if (res.code == 0) {
          console.log(res);
        }
      });
    },
    //关注弹窗内按钮
    goData() {
      this.$message("数据对接中，敬请期待。");
    },
    // 分页相关
    handleSizeChange(val) {
      this.pages.pageSize = val;
      if (this.modelTitle == "物资") {
        console.log("分页相关", this.pages.pageNo);
        console.log("分页相关", this.pages.pageSize);
        this.typeData = 125;
        this.getTableData();
      } else if (this.modelTitle == "设备") {
        this.typeData = 126;
        this.getTableData();
      } else if (this.modelTitle == "值班人员") {
        this.typeData = 96;
        this.getData();
      } else if (this.modelTitle == "救援队物资信息") {
        this.gorescue(this.dataer);
      }
      console.log(this.modelTitle);
    },
    handleCurrentChange(val) {
      this.pages.pageNo = val;
      if (this.modelTitle == "物资") {
        console.log("pageNo", this.pages.pageNo);
        console.log("pageSize", this.pages.pageSize);
        this.typeData = 125;
        this.getTableData();
      } else if (this.modelTitle == "设备") {
        this.typeData = 126;
        this.getTableData();
      } else if (this.modelTitle == "值班人员") {
        this.typeData = 96;
        this.getData();
      } else if (this.modelTitle == "救援队物资信息") {
        this.gorescue(this.dataer);
      }
    },
    handlerescueChange(val) {
      this.pages.pageSize = val;
      this.goteam(this.dataer);
    },
    hanrescueChange(val) {
      this.pages.pageNo = val;
      this.goteam(this.dataer);
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 鼠标滑入单元格
    handleCellMouseEnter(row, column, cell, event) {
      console.log(column.property);
      if (column.property == "usages") {
        cell.querySelector(".cell").style.cursor = "pointer";
        console.log(row, column, cell, event);
        const labelArr = [];
        const usageStr = cell.querySelector(".cell").innerHTML;
        this.usageCache = usageStr;
        let usageArr = [];
        if (usageStr.length) {
          usageArr = usageStr.split("");
        }
        for (let usage of usageArr) {
          const tempObj = this.dict.usages.find((item) => item.label == usage);
          if (tempObj) {
            labelArr.push(tempObj);
          }
        }
        let str = "";
        for (let item of labelArr) {
          str += `<li>${item.label}.${item.intro}</li>`;
        }
        cell.querySelector(".cell").innerHTML = `<ul>${str}</ul>`;
      }
    },
    // 鼠标移出单元格
    handleCellMouseLeave(row, column, cell, event) {
      if (column.property == "usages") {
        console.log(row, column, cell, event);
        cell.querySelector(".cell").innerHTML = this.usageCache;
      }
    },
    // 获取列表
    getTableData() {
      console.log(this.siteId);
      var sited = [];
      sited.push(this.siteId);
      queryMaterialsList({
        siteIds: sited,
        type: this.typeData,
        page: this.pages.pageNo,
        size: this.pages.pageSize,
      }).then((res) => {
        if (res.code == 0) {
          this.pageLoading = false;
          this.tableData = res.data.records;
          this.tableData &&
            this.tableData.forEach((item) => {
              if (item.state == 0) {
                item.state = "待修";
              } else {
                item.state = "完好";
              }
            });
          this.pages.count = res.data.count;
          this.pages.total = res.data.total;
        }
      });
    },
    closeDialog() {
      this.pages.pageNo = 1;
      this.pages.pageSize = 10;
      this.tlement = [];
    },
    // 物资
    materials(data) {
      this.libraryData = data;
      this.modelTitle = "物资";
      this.typeData = 125;
      this.$nextTick(() => {
        this.$refs.library.handleOpen();
      });
    },

    // 设备
    facility(data) {
      this.libraryData = data;
      this.modelTitle = "设备";
      this.typeData = 126;
      this.$nextTick(() => {
        this.$refs.library.handleOpen();
      });
    },
    getData() {
      this.pageLoading = true;
      const params = {
        departmentId: "-",
        grouping: "-",
        lineId: this.lineId,
        page: this.pages.pageNo,
        realName: "-",
        siteId: this.siteId,
        size: this.pages.pageSize,
        type: this.typeData,
        vendorId: "-",
      };
      queryWorkerListByPage(params).then((res) => {
        if (res.code == 0) {
          this.pageLoading = false;
          this.tableData = res.data.records;
          this.pages.total = res.data.total;
          this.pages.count = res.data.count;
        } else {
          this.pageLoading = false;
          this.tableData = [];
        }
      });
    },
    // 值班人员
    operatorDuty(data) {
      this.userSiteData = data;
      // this.$refs.modalUser.handleOpen();
      this.modelTitle = "值班人员";
      this.$refs.dutyTable.handelOpen();
    },
    // 点击关注点事件
    handleMarkClick(data) {
      this.floodPreventionMarkerList.forEach((e) => {
        e.infoWindow.show = false;
      });
      const index = this.floodPreventionMarkerList.findIndex(
        (item) => item.id == data.id
      );
      // this.getguanzhu(data.site.id); //查询弹窗详情
      this.floodPreventionMarkerList[index].infoWindow.show = true;
      let re = this.floodPreventionMarkerList[index];
      this.floodPreventionMarkerList.splice(index, 1, re);
      // this.$router.push({
      //   path: "WorkPoint",
      //   query: data,
      // });
    },
    // 鼠标移入工点事件
    handleMarkHover(data) {
      console.log(data);
      this.gettypes = "";
      this.treu = "";
      this.getterlidt = [];
      this.ages = "";
      this.packages = [];
      this.dutyon = "";
      this.peron = [];

      this.gettypes = data.homePageWindowDto.type;
      if (data.homePageWindowDto.personInChargeList) {
        data.homePageWindowDto.personInChargeList.forEach((item) => {
          this.getterlidt.push(item.realName);
        });
      }
      if (data.homePageWindowDto.underwriterList) {
        data.homePageWindowDto.underwriterList.forEach((item) => {
          this.packages.push(item.realName);
        });
      }
      if (data.homePageWindowDto.watchList) {
        data.homePageWindowDto.watchList.forEach((item) => {
          this.peron.push(item.realName);

          this.dutyon = this.peron.toString();
        });
      }

      this.treu = this.getterlidt.toString();
      this.ages = this.packages.toString();

      this.workPoints.forEach((e) => {
        e.infoWindow.show = false;
      });
      const index = this.workPoints.findIndex((item) => item.id == data.id);
      // this.getguanzhu(data.site.id); //查询弹窗详情
      this.workPoints[index].infoWindow.show = true;

      // let re = this.workPoints[index];
      // this.workPoints.splice(index, 1, re);

      // this.$router.push({
      //   path: "WorkPoint",
      //   query: data,
      // });
    },
    // 工点信息窗口关闭事件
    InfoMouseLeave(data) {
      const index = this.workPoints.findIndex((item) => item.id == data.id);
      this.workPoints[index].infoWindow.show = false;
      let re = this.workPoints[index];
      this.workPoints.splice(index, 1, re);
      this.lineShow = false;
    },
    // 工点信息窗口打开事件
    InfoMouseEnter(data) {
      this.lineShow = true;
      this.workPoints.forEach((e) => {
        e.infoWindow.show = false;
      });
      const index = this.workPoints.findIndex((item) => item.id == data.id);
      this.workPoints[index].infoWindow.show = true;

      let re = this.workPoints[index];
      this.workPoints.splice(index, 1, re);
    },
    //工点移出
    sitWindowCls(data) {
      const index = this.workPoints.findIndex((item) => item.id == data.id);
      setTimeout(() => {
        if (!this.lineShow && !this.pointShow) {
          this.workPoints[index].infoWindow.show = false;
          console.log(111111);
        }
      }, 1000);

      let re = this.workPoints[index];
      this.workPoints.splice(index, 1, re);
    },
    // 工点信息窗口关闭事件
    siteWindowCls(data) {
      const index = this.workPoints.findIndex((item) => item.id == data.id);
      this.workPoints[index].infoWindow.show = false;
      let re = this.workPoints[index];
      this.workPoints.splice(index, 1, re);
    },
    // 关注信息窗口关闭事件
    onFloodPreventionWindowCls(data) {
      const index = this.floodPreventionMarkerList.findIndex(
        (item) => item.id == data.id
      );
      this.floodPreventionMarkerList[index].infoWindow.show = false;
      let re = this.floodPreventionMarkerList[index];
      this.floodPreventionMarkerList.splice(index, 1, re);
    },
    // 工点点击跳转事件
    jumpWorkPoint(data) {
      console.log(data, "data");
      this.$router.push({
        path: "/Screen/ThreeProofings/workPoint",
        query: { id: data.id, uuid: data.uuid },
      });
    },
    //   大型设备 （塔吊龙门吊点击事件）
    handleRescueMarkClick(data) {
      console.log(data);
      this.tlement.push(
        {
          names: "设备名称",
          propers: data.name,
        },
        {
          names: "设备型号",
          propers: data.code,
        },
        {
          names: "设备来源",
          propers: data.source,
        },
        {
          names: "设备起重能力",
          propers: data.spec,
        },
        {
          names: "设备工作点状态",
          propers: data.state,
        }
      );
      this.$refs.modalDetail.handleOpen();
      this.modelTitle = "设备详情";
      this.eltable = false;
      this.pyions = true;
      console.log(this.tlement);
    },
  },
};
</script>

<style lang="scss" scoped>
.modalDetail ::v-deep.el-pager li {
  // color: #409eff !important;
  border-radius: 0px !important;
}

::v-deep .el-table {
  width: 100%;

  background: transparent;

  .el-table__body-wrapper {
    height: 300px;
  }

  &::before {
    background-color: #143363;
  }

  thead {
    color: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: rgba(20, 51, 99, 0.5);
  }

  tr,
  th {
    background-color: transparent;
  }

  td {
    border-bottom: 1px solid #143363;
  }

  .cell {
    color: #fff;
  }

  th.is-leaf {
    border: 1px solid #143363;
  }
}

::v-deep .el-table--border {
  border: 1px solid #143363;

  .el-table--enable-row-hover {
    background-color: #143363;
  }

  td {
    border-right: 1px solid #143363;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: rgba(0, 46, 117, 0.4);
}

.map {
  width: 100%;
  height: 100%;
}

//弹窗
.el-dialog,
.el-pager li {
  background: rgba(52, 73, 85, 0.3);
  border: 1px solid #47a2d3;
  border-radius: 10px;
  color: #fff;
}

.el-dialog__wrapper {
  background: rgba(0, 0, 0, 0.8);
}

.el-dialog__title {
  color: #fff;
}

.el-form-item__label {
  color: #fff;
}

// .el-input__inner {
//   background-color: rgba(2, 2, 6, 1);
//   color: #fff;
//   border-radius: 4px;
//   border: 1px solid #4d4d4d;
// }
.el-dialog__headerbtn .el-dialog__close {
  color: #f9aa33;
}

.search-wrapper {
  position: absolute;
  left: 22%;
  top: 2%;
  z-index: 99;

  ::v-deep .el-input__inner {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background: rgba(24, 70, 127, 0.8) !important;
    border: 1px solid #173f58 !important;
    border-right: none !important;
    width: 240px;
  }

  ::v-deep .el-input-group__append {
    background: #43a5e9;
    border: 1px solid #43a5e9;
    border-left: none;
    color: #fff;
  }
}

.handle-wrapper {
  position: fixed;
  bottom: 30px;
  left: 50%;
  margin-left: -400px;
  width: 800px;
  height: 5.56vh;

  .corner-wrap {
    width: 100%;
    height: 100%;
    position: absolute;

    .corner {
      width: 16px;
      height: 16px;
    }

    .corner-left-top {
      border-left: 2px solid #43a5e9;
      border-top: 2px solid #43a5e9;
      position: absolute;
      left: -1px;
      top: -1px;
    }

    .corner-right-top {
      border-right: 2px solid #43a5e9;
      border-top: 2px solid #43a5e9;
      position: absolute;
      right: -1px;
      top: -1px;
    }

    .corner-right-bottom {
      border-right: 2px solid #43a5e9;
      border-bottom: 2px solid #43a5e9;
      position: absolute;
      right: -1px;
      bottom: -1px;
    }

    .corner-left-bottom {
      border-left: 2px solid #43a5e9;
      border-bottom: 2px solid #43a5e9;
      position: absolute;
      left: -1px;
      bottom: -1px;
    }
  }

  .handle-content {
    width: 100%;
    height: 100%;

    .handle-item {
      margin-left: 10px;
      position: relative;
      background: rgba(8, 23, 66, 0.9);
      border: 1px solid #002e75;
      box-shadow: 0px 0px 23px 6px #002e75 inset;

      &:nth-child(1),
      &:nth-child(2) {
        .item-content {
          .item-content-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;

            &::before {
              display: inline-block;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url(../../../../assets/map/jiuyuan.png);
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center;
              margin-right: 10px;
            }
          }

          .item-content-anzhi {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;

            &::before {
              display: inline-block;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url(../../../../assets/map/jiuyuan.png);
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center;
              margin-right: 10px;
            }
          }
        }
      }

      &:first-child {
        margin-left: 0;

        .item-content {
          .item-content-wrapper {
            &::before {
              display: inline-block;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url(../../../../assets/map/jiuyuan.png);
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center;
              margin-right: 10px;
            }
          }

          .item-content-anzhi {
            &::before {
              display: inline-block;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url(../../../../assets/map/anzhi.png);
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center;
              margin-right: 10px;
            }
          }
        }
      }

      .item-content {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 99;

        .item-content-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          color: #fff;
          padding: 0 10px;

          img {
            width: 1.98vw;
          }
        }

        .item-content-anzhi {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          color: #fff;
          padding: 0 10px;

          img {
            width: 20px;
          }
        }
      }
    }
  }
}

// 信息弹窗样式
::v-deep .BMap_pop {
  > div,
  > img:nth-child(10) {
    display: none;
    overflow: unset;
  }

  > div:nth-child(9) {
    display: block;
    overflow: unset;
    min-width: 320px !important;
    top: 20px !important;
  }

  // > div:nth-child(8) {
  //   /*display: block;*/
  // }
  .BMap_top {
    display: none;
  }

  .BMap_center {
    background: transparent;
    border: none;
    position: sticky !important;
    height: 100%;
  }
}

::v-deep .BMap_bubble_content {
  background: #081742;
  box-shadow: 0px 0px 8px 0px rgba(0, 14, 38, 0.2);
  border: 1px solid #173f58;
  padding: 20px;
  position: relative;

  .info-wrapper {
    width: 100%;

    p {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
      margin-top: 2px;
    }
  }

  .btn-wrapper {
    .el-button {
      padding: 7px 16px;
    }
  }

  .info-header {
    height: 18px;
    margin-bottom: 20px;

    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;

      &::before {
        display: inline-block;
        height: 18px;
        width: 18px;
        content: "";
        background: red;
        margin-right: 5px;
      }
      .text-white {
        margin-top: 5px;
        font-size: 14px;
      }
    }

    .close-btn {
      font-size: 12px;
      color: #fff;
      cursor: pointer;
    }
  }

  .triangle {
    width: 40px;
    height: 40px;
    background: #081742;
    border: 1px solid #173f58;
    transform: rotate(-45deg);
    position: absolute;
    z-index: -99;
    left: 41%;
  }
}

::v-deep.el-table--border {
  &::after {
    width: 0px;
  }
}
::v-deep .el-card__body {
  background: #081742;
}
.text {
  font-size: 14px;
  color: #ffffff;
}

.item {
  margin-bottom: 18px;
}
.rescue {
  height: 500px;

  overflow-y: auto;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #409eff;
  color: #ffffff;
}

::v-deep .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  width: 0;
}
</style>
<style>
.anchorBL {
  display: none !important;
}

.ops {
  opacity: 0.4;
}
</style>
