<template>
  <systemModal ref="formModal" :title="title" :showFooter="false" width="40%">
    <el-form
      ref="form"
      :model="form"
      label-width="100px"
      size="small"
      :rules="rules"
    >
      <el-form-item label="结束原因:" prop="endReason">
        <el-input
          type="textarea"
          placeholder="请输入结束原因"
          :autosize="{ minRows: 2, maxRows: 4 }"
          resize="none"
          v-model="form.endReason"
        ></el-input>
      </el-form-item>

      <el-form-item label="上报人:">
        <el-input
          placeholder="请输入上报人"
          v-model="form.uploadName"
          :readonly="isreadonly"
        ></el-input>
      </el-form-item>
      <el-form-item label="操作人:">
        <el-input v-model="form.managerName" readonly></el-input>
      </el-form-item>
      <el-form-item style="margin-top:50px">
        <el-button type="primary" @click="onSubmit">确定</el-button>
        <el-button style="margin-left:50px" @click="handleClose">取消</el-button>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { EndEvent } from "@/api/response";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "事件结束",
      loading: false,
      form: {
        endReason: "",
        uploadName: "",
        managerName: JSON.parse(localStorage.user).realName,
        managerUuid: JSON.parse(localStorage.user).uuid,
      },
      rules: {
        endReason: [
          { required: true, message: "请输入结束原因", trigger: "blur" },
        ],
      },
      isreadonly: false, //当前上报人是否只读
      eventData: {}
    };
  },
  mounted() {
  },
  methods: {
    handleEventEnd(record) {
      this.eventData = JSON.parse(JSON.stringify(record));
      if (this.eventData.alarmTypeName == "手机报警") {
        // this.form.uploadName=app一键上报的账号登录人
        this.form.uploadName = this.eventData.uploadName;
        this.isreadonly = true;
      } else {
        this.isreadonly = false;
      }
      this.$refs.formModal.handleOpen();
    },
    handleClose() {
      this.$refs.formModal.handleClose();
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          EndEvent(this.form).then((res) => {
            if (res.code == 0) {
              this.$emit("eventEndClose");
              this.$refs.formModal.handleClose();
            }
          });
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 30px !important;
}
::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}
::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
</style>
