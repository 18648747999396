<template>
  <systemModal ref="formModal" :title="title" :showFooter="false" width="40%">
    <div class="video-wrapper">
      <video class="video-uploader-preview" :src="link" controls></video>
    </div>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
export default {
  components: {
    systemModal
  },
  data() {
    return {
      title: "视频播放",
      historyData: [],
      loading: false,
      link: ""
    };
  },
  mounted() {},
  methods: {
    handleVideoPlay(record) {
      this.link = record.link;
      this.$refs.formModal.handleOpen();
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  &::after {
    display: inline-block;
    content: ":";
    margin-left: 5px;
  }
}
::v-deep .el-form-item__content {
  color: rgb(128, 125, 125);
}
</style>
