<template>
  <div
    style="wieth: 100%; height: 100%; border-radius: 10px;"
    class="flex flex-col conLeft"
  >
    <ResponseStatistics></ResponseStatistics>
    <PersonStatistics></PersonStatistics>
    <FloodPrevention></FloodPrevention>
  </div>
</template>

<script>
import ResponseStatistics from "./responseStatistics";
import PersonStatistics from "./personStatistics";
import FloodPrevention from "./floodPrevention";

export default {
  name: "routeList",
  components: { ResponseStatistics, FloodPrevention, PersonStatistics },
  data() {
    return {};
  },
  mounted() {},
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.conLeft {
  height: 100%;
  display: grid;
  grid-template-rows: 36.5% 21% calc(42.5% - 20px);
  grid-row-gap: 10px;
}
</style>
