<template>
  <div class="page-container bg-white p-4 h-full">
    <div class="search-wrapper">
      <el-form :inline="true" :model="queryModal" class="flex justify-between">
        <el-row>
          <el-form-item label="">
            <el-cascader
              v-model="queryModal.linsiteIds"
              clearable
              :options="lineSiteData"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="lineChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="">
            <el-select
              clearable
              v-model="queryModal.riskTypeCode"
              @change="handleSonCate"
              placeholder="风险类别"
            >
              <el-option
                v-for="item in riskCategory"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="queryModal.riskSubTypeCode"
              placeholder="风险子类"
            >
              <el-option
                v-for="item in riskSubTypeList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              clearable
              v-model="queryModal.riskName"
              placeholder="风险名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="queryModal.riskLevelCode"
              placeholder="风险控制等级"
            >
              <el-option
                v-for="item in riskControl"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="queryModal.safeStatus"
              placeholder="安全状态"
            >
              <el-option
                v-for="item in safeStateList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="radio == 2">
            <el-select
              v-model="queryModal.yearQuarter"
              clearable
              placeholder="年度/季度"
            >
              <el-option
                v-for="item of yearQuarters"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData">查询</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div style="margin-bottom: 10px">
      <el-radio-group v-model="radio" @change="radioChange">
        <el-radio-button :label="1">当前风险</el-radio-button>
        <el-radio-button :label="2">历史风险</el-radio-button>
      </el-radio-group>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="branchName"
          label="分部/标段"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="工点"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskTypeName"
          label="风险类别"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskSubTypeName"
          label="风险子类"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskName"
          label="风险名称"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="mileageRange"
          label="风险里程"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskDescribe"
          show-overflow-tooltip
          label="风险描述"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="riskLevelName"
          show-overflow-tooltip
          label="风险控制等级"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="quarter"
          label="年度/季度"
          show-overflow-tooltip
          width=""
        >
          <template slot-scope="scope">
            {{ scope.row.riskYear + "年度第" + scope.row.riskQuarter + "季度" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="safeStatusName"
          label="安全状态"
          show-overflow-tooltip
          width=""
        ></el-table-column>
        <el-table-column align="center" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="radio == 1"
              @click="handleView(scope.row, 'edit')"
              >动态管理</el-button
            >
            <el-button
              type="text"
              v-if="radio == 2"
              @click="handleView(scope.row, 'view')"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-count="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      width="70%"
      title="过程综合管理"
      class="editDialog details-dialog zongheGuanli"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      center
      top="10vh"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <BaseInfo :risk-deaf-uuid="riskDeafUuid"></BaseInfo>
      <div class="riskDialogTitle">过程综合管理</div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="风险交底" name="风险交底">
          <DisclosureRisk
            :rowData="currentRowInfo"
            :disclosureType="disclosureType"
            @handlerCloseDia="handlerCloseDia"
          ></DisclosureRisk>
        </el-tab-pane>
        <el-tab-pane label="风险启动" name="风险启动">
          <!-- <StartRiskForm
            @closeDialog="handlerCloseDia"
            :type="disclosureType"
            @showStartRecord="showStartRecord"
            :rowData="currentRowInfo"
            :riskControlLevel="currentRowInfo.riskLevelCode"
          ></StartRiskForm> -->
          <AuditDialog
            v-if="activeName == '风险启动'"
            :active="AuditActive"
            :stepSx="stepSx"
            :controlLevel="currentCtolLevel"
            :rowData="currentBottomInfo"
            @showFileList="ShowFileDialog"
          ></AuditDialog>
          <!-- :isShowTable="isShowTable" -->
        </el-tab-pane>
        <el-tab-pane label="风险巡查" :disabled="isClickXc" name="风险巡查">
          <PatrolRiskForm
            @showProblemDialog="showProblemDialog"
            @closeProblemDialog="handleClose"
            v-if="activeName === '风险巡查'"
            :xcType="xcType"
            :rowInfo="problemRowInfo"
            :risk-deaf-uuid="riskDeafUuid"
          ></PatrolRiskForm>
        </el-tab-pane>
        <el-tab-pane label="措施处置" :disabled="isClickXc" name="措施处置">
          <Cuoshi :measuresList="measuresList" @getData="getDataFn()"></Cuoshi>
        </el-tab-pane>
        <el-tab-pane label="风险消除" :disabled="isElimina" name="风险消除">
          <RiskMitigationForm
            @CloseDialog="handleClose"
            v-if="activeName === '风险消除'"
            :row-info="currentRowInfo"
          ></RiskMitigationForm>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!-- 风险启动审核记录弹窗 -->
    <Audit
      :dialogRecord="dialogRecord"
      @closeStartRecord="closeStartRecord"
      @showFileList="showFileList"
    ></Audit>
    <!-- 附件列表弹窗 -->
    <Accessory
      :fileDialog="fileDialog"
      :fileData="fileData"
      @closeAccessory="closeAccessory"
    ></Accessory>
    <!-- 风险巡查问题填报 -->
    <el-dialog
      width="50%"
      title="风险巡查问题填报"
      class="editDialog details-dialog"
      top="10vh"
      :visible.sync="dialogProblem"
      v-if="dialogProblem"
      center
      :close-on-click-modal="false"
      :before-close="CloseProblem"
    >
      <ProblemFillForm @closeProblem="handleProblemClose"></ProblemFillForm>
    </el-dialog>
  </div>
</template>

<script>
import {
  removeList,
  riskItemPage,
  riskStartpage,
  // riskStartapplyDetail,
  // riskstartupstatus
} from "@/api/riskManage";
import { getDictData } from "@/api/dict";
import BaseInfo from "./components/BaseInfo";
import DisclosureRisk from "./components/DisclosureRisk";
// import StartRiskForm from "./components/StartRiskForm";
import Audit from "./components/AuditRecordDialog";
import Accessory from "./components/AccessoryDialog";
import PatrolRiskForm from "./components/PatrolRiskForm";
import RiskMitigationForm from "./components/RiskMitigationForm";
import ProblemFillForm from "./components/ProblemFillForm";
// 风险措施
import Cuoshi from "@/views/RiskManagement/RiskList/RiskMeasures/modules/cuoshi";
import { riskLibraryRealList, riskLibraryYear, getMeasures } from "@/api/risk";
import AuditDialog from "./components/AuditDialog";
export default {
  components: {
    BaseInfo,
    DisclosureRisk,
    // StartRiskForm,
    Audit,
    Accessory,
    PatrolRiskForm,
    RiskMitigationForm,
    ProblemFillForm,
    Cuoshi,
    AuditDialog
  },
  data() {
    return {
      queryModal: {
        linsiteIds: [],
        siteUuidList: [],
        riskTypeCode: "",
        riskSubTypeCode: "",
        riskName: "",
        riskLevelCode: "",
        safeStatus: "",
        yearQuarter: "",
        isHistoricalRisk: 0 //是否历史风险 0 否 1是
      },
      lineSiteData: [],
      loading: false,
      props: {
        children: "children",
        label: "name",
        value: "uuid",
        multiple: true
      },
      riskCategory: [], //风险类别
      riskSubTypeList: [], //风险子类
      riskControl: [], //风险控制等级
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0
      },
      radio: 1,
      tableData: [],
      dialogVisible: false,
      activeName: "风险交底",
      disclosureType: "", //风险交底类型
      startRiskType: "", //风险启动类型
      currentRowInfo: {}, //当前行信息
      dialogRecord: false, // 审核记录弹窗
      fileDialog: false, // 附件列表弹窗
      dialogProblem: false, // 风险巡查问题填报弹窗
      fileData: [],
      problemRowInfo: {}, //问题上报当前行数据
      xcType: Boolean,
      safeStateList: [
        { label: "未启动", code: 0 },
        { label: "启动异常", code: 1 },
        { label: "管控异常", code: 2 },
        { label: "安全可控", code: 3 },
        { label: "已消除", code: 4 }
      ],
      isShowTable: true,
      riskDeafUuid: "",
      yearQuarters: [],
      isClickXc: false,
      measuresList: [], //措施
      stepSx: 1,
      currentCtolLevel: "",
      currentInfo: {},
      AuditActive: 2, //步骤条进度
      currentBottomInfo: {},
      currentprocessStatus: "",
      eliminateList : [],
      isElimina : false
    };
  },
  created() {
    this.getLineTree();
    this.getDict();
    this.getData();
  },
  methods: {
    async getLineTree() {
      const lineSiteData = JSON.parse(localStorage.getItem("lineSiteTree"));
      if (lineSiteData) {
        this.lineSiteData = JSON.parse(JSON.stringify(lineSiteData));
      }
    },
    // 获取措施
    getDataFn() {
      getMeasures(this.riskDeafUuid).then(res => {
        // getMeasures("c6a2ffb0-10bf-49ba-b1e5-86716635c4e1").then((res) => {
        console.log(res);
        if (res.data) {
          this.measuresList = res.data;
        }
      });
    },
    getTableData(){
      let params = {
        pageNum : 1,
        pageSize : 10,
        riskDeafUuid : this.riskDeafUuid,
        estimateEndDay : '2023-10-30'
      }
      removeList(params).then(res => {
        this.eliminateList = res.data.list;
        if(this.eliminateList.length === 0 ||
            (this.eliminateList.length > 0 && this.eliminateList[0].problemCount > 0)){
          this.isElimina = true;
        }else{
          this.isElimina = false;
        }
      })
    },
    // riskType
    getDict() {
      getDictData("riskType").then(res => {
        if (res.code == 0) {
          this.riskCategory = res.data;
        }
      });
      getDictData("riskControlLevel").then(res => {
        if (res.code == 0) {
          this.riskControl = res.data;
        }
      });
    },
    handleSonCate(val) {
      this.queryModal.riskSubTypeCode = "";
      this.riskSubTypeList = [];
      if (val.length > 0) {
        getDictData(val).then(res => {
          if (res.code == 0) {
            this.riskSubTypeList = res.data;
          }
        });
      }
    },
    // change切换
    radioChange(val) {
      if (val == 1) {
        //未响应
        this.queryModal.isHistoricalRisk = 0;
        this.getData();
      } else if (val == 2) {
        //已响应
        this.queryModal.isHistoricalRisk = 1;
        this.loading = true;
        let params = {
          pageNum: this.page.pageNo,
          pageSize: this.page.pageSize,
          ...this.queryModal
        };
        // 获取年度季度
        this.yearQuarters = [];
        riskLibraryYear().then(res => {
          console.log(res);
          // this.yearQuarters
          if (res.data) {
            // 获取最早季度
            let year = res.data.slice(0, 4);
            let quarter = res.data.indexOf(4);
            console.log(year, quarter);
            // 获取当年季度
            let nowYear = new Date().getFullYear();
            let nowMonth = new Date().getMonth() + 1;
            if (nowMonth > 9) {
              nowMonth = 4;
            } else if (nowMonth > 6) {
              nowMonth = 3;
            } else if (nowMonth > 3) {
              nowMonth = 2;
            } else {
              nowMonth = 1;
            }
            year = Number(year);
            quarter = Number(quarter);
            nowYear = Number(nowYear);
            nowMonth = Number(nowMonth);
            console.log(year, quarter, nowYear, nowMonth);
            while (year != nowYear || quarter <= nowMonth) {
              let obj = {};
              if (year < nowYear) {
                obj.code = year + "" + quarter;
                obj.label = `${year}年第${quarter}季度`;
                this.yearQuarters.push(obj);
                quarter++;
                if (quarter > 4) {
                  quarter = 1;
                  year++;
                }
              } else {
                obj.code = year + "" + quarter;
                obj.label = `${year}年第${quarter}季度`;
                this.yearQuarters.push(obj);
                quarter++;
              }
            }
          }
        });
        riskLibraryRealList(params).then(res => {
          this.tableData = res.data.records;
          // 手动添加状态中文
          this.tableData.forEach(i => {
            // 代表是正常状态，返回待审核状态
            i.stateName = "已通过";
          });
          this.page.total = res.data.total;
          this.loading = false;
        });
      }
      // this.getData();
      console.log("val", val);
    },
    getData() {
      const that = this;
      this.loading = true;
      let params = {
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.queryModal
      };
      riskItemPage(params).then(res => {
        if (res.code == 0) {
          // dd
          res.data.records.forEach(el => {
            that.safeStateList.forEach(v => {
              if (el.safeStatus == v.code) {
                el.safeStatusName = v.label;
              }
            });
          });
          this.tableData = res.data.records;
          this.page.total = res.data.total;
          this.loading = false;
        }
      });
    },
    lineChange(record) {
      console.log(record);
      this.queryModal.siteUuidList = [];
      for (let item of record) {
        this.queryModal.siteUuidList.push(item[item.length - 1]);
      }
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getData();
    },
    handleView(row, type) {
      this.currentRowInfo = Object.assign({}, row);
      this.xcType = false;
      console.log(this.currentRowInfo, "currentRowInfo");
      this.riskDeafUuid = row.riskDeafUuid;
      this.disclosureType = type;
      this.getDataFn();
      this.getRiskStartDetail(row);
      this.getTableData();
      this.dialogVisible = true;
    },
    // 附件列表弹窗
    ShowFileDialog(obj) {
      this.fileDialog = true;
      this.fileData = obj;
    },
    getRiskStartDetail(row) {
      const that = this;
      let params = {
        pageSize: 100,
        pageNum: 1,
        // processStatus: -9,
        riskName: row.riskName
      };
      let list = [];
      riskStartpage(params).then(res => {
        if (res.code == 0) {
          list.push(...res.data.list);
          list.forEach(el => {
            if (el.riskDeafUuid == row.riskDeafUuid) {
              that.currentBottomInfo = el;
              that.currentprocessStatus = el.processStatus;
              console.log(row.processStatus, "processStatus");
              that.getActive(el.processStatus);
              if (el.processStatus == 9) {
                that.isClickXc = false;
              } else {
                that.isClickXc = true;
              }
            }
          });
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.activeName = "风险交底";
    },
    handleClick() {
      // d
    },
    handlerCloseDia() {
      this.dialogVisible = false;
      this.activeName = "风险交底";
    },
    showStartRecord(row) {
      this.getActive(row.processStatus);
      this.AuditActive = 2;
      this.currentInfo = { ...row };
      this.auditVisible = true;
      this.dialogRecord = true;
    },
    getActive(status) {
      console.log(status, "ddd");
      switch (status) {
        case 1:
          this.stepSx = 1;
          break;
        case 2:
          this.stepSx = 1;
          break;
        case 3:
          this.stepSx = 2;
          break;
        case 4:
          this.stepSx = 2;
          break;
        case 5:
          this.stepSx = 3;
          break;
        case 6:
          this.stepSx = 3;
          break;
        case 7:
          this.stepSx = 4;
          break;
        case 8:
          this.stepSx = 4;
          break;
        case 9:
          this.stepSx = 4;
          break;
        default:
          break;
      }
    },
    closeStartRecord() {
      this.dialogRecord = false;
    },
    // 显示附件列表弹窗
    showFileList(obj) {
      this.fileDialog = true;
      this.fileData = obj.riskWarnFileList;
    },
    // 关闭附件列表弹窗
    closeAccessory() {
      this.fileDialog = false;
    },
    // 显示风险巡查问题填报弹窗
    showProblemDialog(val) {
      this.problemRowInfo = val;
      this.dialogProblem = true;
    },
    // 显示风险巡查问题填报弹窗
    closeProblemDialog(val) {
      this.problemRowInfo = val;
      this.dialogVisible = false;
    },
    CloseProblem() {
      this.dialogProblem = false;
    },
    handleProblemClose(type) {
      console.log(type);
      this.xcType = type;
      this.dialogProblem = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.riskDialogTitle {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
}
::v-deep .details-dialog {
  .el-dialog__header {
    .el-dialog__title {
      position: static;
    }
  }
  .el-dialog__body {
    height: 680px;
    // max-height: 680px;
    overflow: auto;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
// ::v-deep .zongheGuanli {
//   .el-dialog__body {
//     height: 680px;
//   }
// }
::v-deep .el-tabs__item.is-disabled {
  cursor: no-drop;
}
</style>
