<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-model">
      <el-form-item>
        <el-button type="primary" v-has="'drill:add'" @click="downTemplate">下载模板</el-button>
      </el-form-item>
      <el-form-item>
        <el-upload
            action="action"
            :http-request="uploadFile"
            :file-list="fileList"
            :before-upload="beforeUpload"
            :limit="1"
        >
          <el-button type="primary">上传清单</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {libraryImport, libraryUpload} from "@/api/hdanger";

export default {
  name: "downUpload",
  data(){
    return {
      ruleForm : {},
      rules : {},
      fileList : []
    }
  },
  created() {

  },
  methods : {
    downTemplate() {
      libraryImport().then((res) => {
        const aLink = document.createElement("a");
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel",
        });
        aLink.href = URL.createObjectURL(blob);
        aLink.download = "隐患条目库";
        aLink.click();
        document.body.appendChild(aLink);
        URL.revokeObjectURL(aLink.href); //销毁下载地址
        document.body.removeChild(aLink); //将a标签从dom中移除
      });
    },
    // 自定义上传方法
    async uploadFile(params) {
      // this.model.importFile = params.file;
      // this.isFinally = true;
      this.percent = 0;
      const { file } = params;
      let res = await libraryUpload(file);
      console.log(res);
      if (res.code === 0) {
        // this.isFinally = false;
        this.$message.success("上传成功");
        this.$emit('successUpload')
      } else {
        this.message.error("上传失败");
        this.$emit("error", res.message);
        // this.isFinally = false;
      }
    },
    beforeUpload(file) {
      console.log(file);
      const tempArr = file.name.split(".");
      const isValid = tempArr.includes("xls") || tempArr.includes("xlsx");
      if (!isValid) {
        this.$message.error("只支持xls、xlsx格式文件！");
        return false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.demo-model{
  display: flex;
  justify-content: space-around;
}
</style>
