<template>
<!-- 施工 -->
  <div>
    <div style="margin-bottom:20px">
      <el-radio-group
        size="mini"
        v-model="degree"
        v-for="item in times"
        :key="item.time"
        @change="degreeChange"
      >
        <el-radio-button
          :label="item.label"
          style="margin:0 10px 5px 0"
        ></el-radio-button>
      </el-radio-group>
    </div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="汇总单位">
        <el-input v-model="formInline.circuit" readonly></el-input>
      </el-form-item>

      <el-form-item label="三防应急响应类型">
        <el-input
          v-model="formInline.responseType"
          readonly
        ></el-input> </el-form-item
      ><el-form-item label="三防应急响应级别">
        <el-input v-model="formInline.responseLevel" readonly></el-input>
      </el-form-item>
    </el-form>
    <el-form :model="tableForm" ref="tableForm" size="small">
      <el-table
        v-loading="tableLoading"
        ref="tableData"
        :data="tableForm.tableData"
        id="tableData"
        border
        :row-style="{ height: '30px' }"
        style="width:100%"
      >
        <el-table-column
          label="单位"
          prop="siteName"
          align="center"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="派出工作组(个)从应急响应启动到本次报送时间节点,派出工作组的累计数量"
          align="center"
          prop="workGroup"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.siteName == '汇总数据'">{{
              scope.row.workGroup
            }}</span>
            <el-form-item
              :prop="'tableData.' + scope.$index + '.workGroup'"
              :rules="[
                {
                  required: true,
                  message: '必填',
                  trigger: 'blur',
                },
                { type: 'number', message: '必须为数字值' },
              ]"
              v-if="scope.row.siteName != '汇总数据'"
            >
              <el-input
                :readonly="
                  scope.row.approvalState == 1 ||
                  scope.row.approvalState == 3 ||
                  threeTitle == 'check'
                    ? true
                    : false
                "
                v-model.number="scope.row.workGroup"
                @blur="workGroupOn($event.target.value)"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="position"
          label="检查场所部位(处)从应急响应启动到本次报送时间节点,检查场所部位的累计数量"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.siteName == '汇总数据'">{{
              scope.row.position
            }}</span>
            <el-form-item
              :prop="'tableData.' + scope.$index + '.position'"
              :rules="[
                {
                  required: true,
                  message: '必填',
                  trigger: 'blur',
                },
                { type: 'number', message: '必须为数字值' },
              ]"
              v-if="scope.row.siteName != '汇总数据'"
            >
              <el-input
                :readonly="
                  scope.row.approvalState == 1 ||
                  scope.row.approvalState == 3 ||
                  threeTitle == 'check'
                    ? true
                    : false
                "
                v-model.number="scope.row.position"
                @blur="positionOn($event.target.value)"
              ></el-input>
              <!-- v-on:input="positionOn" -->
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="hiddenDanger"
          label="新排查出三防风险隐患(处)上—次报送时间节点到本次报送时间节点，新排查出的三防风险隐患数量"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.siteName == '汇总数据'">{{
              scope.row.hiddenDanger
            }}</span>
            <el-form-item
              :prop="'tableData.' + scope.$index + '.hiddenDanger'"
              :rules="[
                {
                  required: true,
                  message: '必填',
                  trigger: 'blur',
                },
                { type: 'number', message: '必须为数字值' },
              ]"
              v-if="scope.row.siteName != '汇总数据'"
            >
              <el-input
                :readonly="
                  scope.row.approvalState == 1 ||
                  scope.row.approvalState == 3 ||
                  threeTitle == 'check'
                    ? true
                    : false
                "
                v-model.number="scope.row.hiddenDanger"
                @blur="hiddenDangerOn($event.target.value)"
              ></el-input>
              <!-- v-on:input="hiddenDangerOn" -->
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="info"
          label="发送预警或防御信息(条)地铁集团不需要填该项"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.siteName == '汇总数据'">{{
              scope.row.info
            }}</span>
            <el-form-item
              :prop="'tableData.' + scope.$index + '.info'"
              :rules="[
                {
                  required: true,
                  message: '必填',
                  trigger: 'blur',
                },
                { type: 'number', message: '必须为数字值' },
              ]"
              v-if="scope.row.siteName != '汇总数据'"
            >
              <!-- v-on:input="infoOn" -->
              <el-input
                :readonly="
                  scope.row.approvalState == 1 ||
                  scope.row.approvalState == 3 ||
                  threeTitle == 'check'
                    ? true
                    : false
                "
                v-model.number="scope.row.info"
                @blur="infoOn($event.target.value)"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="worker"
          label="预制(出动)抢险人员(人)在本次报送时间节点，能立即调动的抢险人员"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.siteName == '汇总数据'">{{
              scope.row.worker
            }}</span>
            <el-form-item
              :prop="'tableData.' + scope.$index + '.worker'"
              :rules="[
                {
                  required: true,
                  message: '必填',
                  trigger: 'blur',
                },
                { type: 'number', message: '必须为数字值' },
              ]"
              v-if="scope.row.siteName != '汇总数据'"
            >
              <el-input
                :readonly="
                  scope.row.approvalState == 1 ||
                  scope.row.approvalState == 3 ||
                  threeTitle == 'check'
                    ? true
                    : false
                "
                v-model.number="scope.row.worker"
                @blur="workerOn($event.target.value)"
              ></el-input>
              <!-- v-on:input="workerOn" -->
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="team"
          label="预制(出动)抢险队伍(支)在本次报送时间节点，能立即调动的抢险队伍数量"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.siteName == '汇总数据'">{{
              scope.row.team
            }}</span>
            <el-form-item
              :prop="'tableData.' + scope.$index + '.team'"
              :rules="[
                {
                  required: true,
                  message: '必填',
                  trigger: 'blur',
                },
                { type: 'number', message: '必须为数字值' },
              ]"
              v-if="scope.row.siteName != '汇总数据'"
            >
              <el-input
                :readonly="
                  scope.row.approvalState == 1 ||
                  scope.row.approvalState == 3 ||
                  threeTitle == 'check'
                    ? true
                    : false
                "
                v-model.number="scope.row.team"
                @blur="teamOn($event.target.value)"
              ></el-input>
              <!-- v-on:input="teamOn" -->
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          label="预置(出动)抢险设备(台)指在本次报送时间节点，能立即调动设备数量"
          align="center"
        >
          <el-table-column prop="deviceTotal" label="合计" align="center" width="80">
            <template slot-scope="scope">
              {{
                Number(scope.row.vehicle) +
                  Number(scope.row.boat) +
                  Number(scope.row.waterPump) +
                  Number(scope.row.other)
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="vehicle"
            label="车辆能立即调动的抢险汽车、吊车、铲车、履带车等"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.siteName == '汇总数据'">{{
                scope.row.vehicle
              }}</span>
              <el-form-item
                :prop="'tableData.' + scope.$index + '.vehicle'"
                :rules="[
                  {
                    required: true,
                    message: '必填',
                    trigger: 'blur',
                  },
                  { type: 'number', message: '必须为数字值' },
                ]"
                v-if="scope.row.siteName != '汇总数据'"
              >
                <el-input
                  :readonly="
                    scope.row.approvalState == 1 ||
                    scope.row.approvalState == 3 ||
                    threeTitle == 'check'
                      ? true
                      : false
                  "
                  v-model.number="scope.row.vehicle"
                  @blur="vehicleOn($event.target.value)"
                ></el-input>
                <!-- v-on:input="vehicleOn" -->
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="boat"
            label="舟艇能立即调动的舟船、划艇等水上运输工具数量"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.siteName == '汇总数据'">{{
                scope.row.boat
              }}</span>
              <el-form-item
                :prop="'tableData.' + scope.$index + '.boat'"
                :rules="[
                  {
                    required: true,
                    message: '必填',
                    trigger: 'blur',
                  },
                  { type: 'number', message: '必须为数字值' },
                ]"
                v-if="scope.row.siteName != '汇总数据'"
              >
                <el-input
                  :readonly="
                    scope.row.approvalState == 1 ||
                    scope.row.approvalState == 3 ||
                    threeTitle == 'check'
                      ? true
                      : false
                  "
                  v-model.number="scope.row.boat"
                  @blur="boatOn($event.target.value)"
                ></el-input>
                <!-- v-on:input="boatOn" -->
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="waterPump"
            label="抽水机能立即调动的龙吸水、自吸泵、排污泵、潜水泵、动力站水泵等抽排水设备数量"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.siteName == '汇总数据'">{{
                scope.row.waterPump
              }}</span>
              <el-form-item
                :prop="'tableData.' + scope.$index + '.waterPump'"
                :rules="[
                  {
                    required: true,
                    message: '必填',
                    trigger: 'blur',
                  },
                  { type: 'number', message: '必须为数字值' },
                ]"
                v-if="scope.row.siteName != '汇总数据'"
              >
                <el-input
                  :readonly="
                    scope.row.approvalState == 1 ||
                    scope.row.approvalState == 3 ||
                    threeTitle == 'check'
                      ? true
                      : false
                  "
                  v-model.number="scope.row.waterPump"
                  @blur="waterPumpOn($event.target.value)"
                ></el-input>
                <!-- v-on:input="waterPumpOn" -->
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="other"
            label="其他能立即调动的、未统计在车辆、舟艇、抽水机范围内的三防抢险设备数量"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.siteName == '汇总数据'">{{
                scope.row.other
              }}</span>
              <el-form-item
                :prop="'tableData.' + scope.$index + '.other'"
                :rules="[
                  {
                    required: true,
                    message: '必填',
                    trigger: 'blur',
                  },
                  { type: 'number', message: '必须为数字值' },
                ]"
                v-if="scope.row.siteName != '汇总数据'"
              >
                <el-input
                  :readonly="
                    scope.row.approvalState == 1 ||
                    scope.row.approvalState == 3 ||
                    threeTitle == 'check'
                      ? true
                      : false
                  "
                  v-model.number="scope.row.other"
                  @blur="otherOn($event.target.value)"
                ></el-input>
                <!-- v-on:input="otherOn" -->
              </el-form-item>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="其他应急措施落实及受灾害影响情况指从应急响应启动到本次报送时间节点，集团公司各单位应急措施落实及受灾受影响累计情况"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.siteName == '汇总数据'">{{
                scope.row.remark
              }}</span>
            <el-form-item
              :prop="'tableData.' + scope.$index + '.remark'"
              :rules="[
                {
                  required: true,
                  message: '必填',
                  trigger: 'blur',
                },
              ]"
              v-if="scope.row.siteName != '汇总数据'"
            >
              <el-input
                v-model="scope.row.remark"
                :readonly="threeTitle == 'check' ? true : false"
              ></el-input>
              <!-- v-on:input="remarkOn" -->
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div
      style="display:flex;justify-content: center;margin-top:20px"
      v-show="threeTitle == 'write'"
    >
      <el-button @click="sureSave">保存</el-button>
      <el-button @click="sureSub">提交</el-button>
      <!-- :disabled="demo ? 1 : 0" -->
    </div>
  </div>
</template>

<script>
import {
  getStatisticList,
  getRes,
  saveStatisticList,
  saveStatisticListSave,
} from "@/api/response";
export default {
  props: {
    buildWriteId: {
      type: Number,
      default: () => null,
    },
    threeTitle: {
      type: String,
    },
    buildWriteResponseId: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      times: [],
      tableLoading: false,
      degree: "第1次",
      tableForm: {
        tableData: [],
      },
      tableData: [],
      total: null,
      formInline: {
        circuit: "",
        responseType: "",
        responseLevel: "",
      },
      sureSubShow: false,
      gather: "1",
    };
  },
  created() {},
  watch: {
    buildWriteResponseId: {
      handler(val) {
        if (val) {
          this.getGenre(val);
          this.getList(val);
        }
      },
      immediate: true,
      deep: true,
      clickShow: false,
    },
  },
  computed: {
    workGroup: function() {
      var total = 0;
      this.tableForm.tableData.forEach((item) => {
        if (item.siteName != "汇总数据") {
          total = total + Number(item.workGroup);
        }
      });
      return total;
    },
  },
  mounted() {
    let arr = JSON.parse(localStorage.getItem("user"));
    if (arr.departmentId == "0") {
      this.formInline.circuit = "管理员";
    } else {
      this.formInline.circuit = arr.departmentName;
    }
  },
  methods: {
    getList(val) {
      this.tableLoading = true;
      let params = {
        responseId: val,
      };
      getStatisticList(params).then((res) => {
        this.times = [];
        if (res.code == 0) {
          this.tableLoading = false;
          res.data.forEach((element) => {
            this.times.push({
              time: element.times,
              label: "第" + element.times + "次",
            });
          });
          this.tableData = res.data;
          this.tableData.forEach((element) => {
            element.statisticFormDtoList = element.statisticFormDtoList.reverse();
          });
          this.tableForm.tableData = this.tableData[0].statisticFormDtoList;
          // if (this.tableData.length <= 1) {
          //   this.sureSubShow = true;
          // } else {
          //   this.sureSubShow = false;
          // }
        }
      });
    },
    checkTimes() {
      if (this.times.length != 0) {
        this.degree = "第1次";
        var arr = this.degree.replace(/\D/g, "");
        this.tableForm.tableData = [];
        this.tableData.forEach((item) => {
          if (arr == item.times) {
            this.tableForm.tableData = item.statisticFormDtoList;
            this.tableForm.tableData.forEach((element) => {
              if (element.boat != "" || element.boat != null) {
                this.clickShow = true;
              } else {
                this.clickShow = false;
              }
            });
          }
        });
      }
    },
    getGenre(val) {
      let id = val;
      getRes(id).then((res) => {
        if (res.code == 0) {
          this.formInline.responseLevel = res.data.currentLevel.label;
          this.formInline.responseType = res.data.type.label;
        }
      });
    },
    degreeChange(val) {
      this.gather = val;
      var arr = val.replace(/\D/g, "");
      this.tableForm.tableData = [];
      this.tableData.forEach((item) => {
        if (arr == item.times) {
          this.tableForm.tableData = item.statisticFormDtoList;
        }
      });
      // this.checkTimes()
    },
    // 派出工作组
    workGroupOn() {
      var a = 0;
      this.tableForm.tableData.forEach((item) => {
        if (item.siteName != "汇总数据") {
          a = a + Number(item.workGroup);
        }
      });
      this.tableForm.tableData[0].workGroup = a;
    },
    // 检查场所部位
    positionOn() {
      var a = 0;
      this.tableForm.tableData.forEach((item) => {
        if (item.siteName != "汇总数据") {
          a = a + Number(item.position);
        }
      });
      this.tableForm.tableData[0].position = a;
    },
    // 新排查出三防风险隐患
    hiddenDangerOn() {
      var a = 0;
      this.tableForm.tableData.forEach((item) => {
        if (item.siteName != "汇总数据") {
          a = a + Number(item.hiddenDanger);
        }
      });
      this.tableForm.tableData[0].hiddenDanger = a;
    },
    // 发送预警或防御信息
    infoOn() {
      var a = 0;
      this.tableForm.tableData.forEach((item) => {
        if (item.siteName != "汇总数据") {
          a = a + Number(item.info);
        }
      });
      this.tableForm.tableData[0].info = a;
    },
    // 预制(出动)抢险人员
    workerOn() {
      var a = 0;
      this.tableForm.tableData.forEach((item) => {
        if (item.siteName != "汇总数据") {
          a = a + Number(item.worker);
        }
      });
      this.tableForm.tableData[0].worker = a;
    },
    // 预制(出动)抢险队伍
    teamOn() {
      var a = 0;
      this.tableForm.tableData.forEach((item) => {
        if (item.siteName != "汇总数据") {
          a = a + Number(item.team);
        }
      });
      this.tableForm.tableData[0].team = a;
    },
    // 预制(出动)抢险设备
    deviceTotalOn() {
      var a = 0;
      this.tableForm.tableData.forEach((item) => {
        if (item.siteName != "汇总数据") {
          a = a + Number(item.deviceTotal);
        }
      });
      this.tableForm.tableData[0].deviceTotal = a;
    },
    // 车辆
    vehicleOn() {
      var a = 0;
      this.tableForm.tableData.forEach((item) => {
        if (item.siteName != "汇总数据") {
          a = a + Number(item.vehicle);
        }
      });
      this.tableForm.tableData[0].vehicle = a;
    },
    // 舟艇
    boatOn() {
      var a = 0;
      this.tableForm.tableData.forEach((item) => {
        if (item.siteName != "汇总数据") {
          a = a + Number(item.boat);
        }
      });
      this.tableForm.tableData[0].boat = a;
    },
    // 抽水机
    waterPumpOn() {
      // let arr = this.gather.replace(/\D/g, "");
      // if (arr == 1) {
      var a = 0;
      this.tableForm.tableData.forEach((item) => {
        if (item.siteName != "汇总数据") {
          a = a + Number(item.waterPump);
        }
      });
      this.tableForm.tableData[0].waterPump = a;
      // } else if (arr > 1) {
      //   var c = this.tableData[arr - 2].statisticFormDtoList[0].waterPump;
      //   var b = 0;
      //   this.tableForm.tableData.forEach((item) => {
      //     if (item.siteName != "汇总数据") {
      //       b = b + Number(item.waterPump);
      //     }
      //   });
      //   this.tableForm.tableData[0].waterPump = b + c;
      // }
    },
    // 其他
    otherOn() {
      var a = 0;
      this.tableForm.tableData.forEach((item) => {
        if (item.siteName != "汇总数据") {
          a = a + Number(item.other);
        }
      });
      this.tableForm.tableData[0].other = a;
    },
    clearData() {
      this.$refs.tableForm.resetFields();
    },
    // 保存
    sureSave() {
      this.$refs.tableForm.validate((valid) => {
        if (valid) {
          this.tableLoading = true;
          var params = [];
          this.tableForm.tableData.forEach((element) => {
            if (element.siteName != "汇总数据") {
              params.push({
                boat: element.boat,
                deviceTotal: element.deviceTotal,
                hiddenDanger: element.hiddenDanger,
                currentId: element.currentId,
                responseId: this.buildWriteResponseId,
                siteName: element.siteName,
                siteUuid: element.siteUuid,
                info: element.info,
                other: element.other,
                position: element.position,
                remark: element.remark,
                team: element.team,
                vehicle: element.vehicle,
                waterPump: element.waterPump,
                workGroup: element.workGroup,
                worker: element.worker,
                id: element.id,
              });
            }
          });
          saveStatisticListSave(params)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("提交成功");
                this.tableLoading = false;
                this.$emit("buildWriteClose");
              } else {
                this.tableLoading = false;
                this.$emit("buildWriteClose");
                this.$message.warning("提交失败");
              }
            })
            .catch((error) => {
              console.log(error);
              this.tableLoading = false;
            });
          // }
        } else {
          this.tableLoading = false;
          return false;
        }
      });
    },
    // 提交
    sureSub() {
      this.$refs.tableForm.validate((valid) => {
        if (valid) {
          this.tableLoading = true;
          var params = [];

          // if (this.tableForm.tableData[1].id != null) {
          //   // 修改
          //   this.tableForm.tableData.forEach((element) => {
          //     if (element.siteName != "汇总数据") {
          //       params.push({
          //         boat: element.boat,
          //         deviceTotal: element.deviceTotal,
          //         hiddenDanger: element.hiddenDanger,
          //         id: element.id,
          //         currentId: element.currentId,
          //         responseId: this.buildWriteResponseId,
          //         siteName: element.siteName,
          //         siteUuid: element.siteUuid,
          //         info: element.info,
          //         other: element.other,
          //         position: element.position,
          //         remark: element.remark,
          //         team: element.team,
          //         vehicle: element.vehicle,
          //         waterPump: element.waterPump,
          //         workGroup: element.workGroup,
          //         worker: element.worker,
          //       });
          //     }
          //   });
          //   updateStatisticList(params).then((res) => {
          //     if (res.code == 0) {
          //       this.$message.success("修改成功");
          //       this.tableLoading = false;
          //       this.$emit("buildWriteClose");
          //     } else {
          //       this.tableLoading = false;
          //       this.$emit("buildWriteClose");
          //       this.$message.warning("修改失败");
          //     }
          //   });
          // } else {
          //新增
          this.tableForm.tableData.forEach((element) => {
            if (element.siteName != "汇总数据") {
              params.push({
                boat: element.boat,
                deviceTotal: element.deviceTotal,
                hiddenDanger: element.hiddenDanger,
                currentId: element.currentId,
                responseId: this.buildWriteResponseId,
                siteName: element.siteName,
                siteUuid: element.siteUuid,
                info: element.info,
                other: element.other,
                position: element.position,
                remark: element.remark,
                team: element.team,
                vehicle: element.vehicle,
                waterPump: element.waterPump,
                workGroup: element.workGroup,
                worker: element.worker,
                id: element.id,
              });
            }
          });
          saveStatisticList(params)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("提交成功");
                this.tableLoading = false;
                this.$emit("buildWriteClose");
              } else {
                this.tableLoading = false;
                this.$emit("buildWriteClose");
                this.$message.warning("提交失败");
              }
            })
            .catch((error) => {
              console.log(error);
              this.tableLoading = false;
            });
          // }
        } else {
          this.tableLoading = false;
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table::before {
  height: 0;
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: #fff !important;
  border-radius: 7px;
}
// /*定义滑块颜色、内阴影及圆角*/
// ::v-deep ::-webkit-scrollbar-thumb {
//   border-radius: 7px;
//   -webkit-box-shadow: none !important;
//   background-color: rgba(0, 0, 0, 0.1) !important;
// }
/*定义滑块颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 7px;
  border-radius: 0;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}

//去掉固定列下方的横线
::v-deep .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: transparent;
}
//去掉固定列阴影
::v-deep .el-table__fixed-right {
  // box-shadow: none;
  height: 100% !important;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}
::v-deep .el-table th > .cell {
  font-size: 12px !important;
}
::v-deep .el-table--border,
.el-table--group {
  border: none;
}
::v-deep .el-table--border::after,
.el-table--group::after {
  width: 0 !important;
}
::v-deep .el-table--border th.el-table__cell.gutter:last-of-type {
  background-color: #fff !important;
  border-bottom: none;
}
::v-deep .el-radio-button {
  width: 75px;
}
::v-deep .el-radio-button__inner {
  width: 65px;
}
</style>
