<template>
  <div class="min-h-full bg-white">
    <div class="flex justify-between p-4">
      <el-row class="flex ">
<!--        <input-select-tree
            :nameArr="nameArr"
            :valueTitle="valueTitle"
            :lineTree="deptData1"
            :defaultId="defaultId"
            :placeholder="'请选择单位'"
            :defaultCheckedkeys="defaultCheckedkeys"
            @handleCheckChange="handleCheckChange"
            @clearHandle="clearHandle"
            @visibleChangeSelect="findPage"
        ></input-select-tree>-->
        <propver
            class="mr-2 spec-select"
            :data="comList"
            :defaultProps="props"
            :chooseName="chooses"
            :pid="queryParams.puuid"
            :placeholder="'请选择单位/部门'"
            @deptTreeCurr="deptTreeCurrentChangeHandle"
        ></propver>

        <el-input
            v-model="queryParams.personName"
            @keyup.native.enter="findPage"
            class="mr-2"
            clearable
            style="width: 120px;"
            placeholder="人员姓名"
        ></el-input>

        <el-select
            v-model="queryParams.violationType"
            placeholder="请选择"
            clearable
        >
          <el-option
              v-for="item in villationTypes"
              :key="item.id"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>

        <el-date-picker
            v-model="queryParams.violationTime"
            type="datetime"
            style="margin: 0 10px;"
            format="yyyy-MM-dd hh:mm"
            value-format="yyyy-MM-dd hh:mm"
            placeholder="起始时间"
            clearable
        >
        </el-date-picker>

        <el-button
            type="primary"
            @click="findPage()"
        >
          <i class="fa fa-search"></i> 查询
        </el-button>
      </el-row>
      <!-- 右边 -->
      <el-row>
        <el-button
            type="primary"
            @click.prevent="handleAdd()"
        >
          <i class="fa fa-plus"></i>新增
        </el-button>

        <el-button
            type="primary"
            @click.prevent="exportExcel()"
        >
          <i class="el-icon-download"></i>导出
        </el-button>
      </el-row>

    </div>

    <!-- main -->
    <div class="mainPart">
      <!-- 表格(右侧) -->
      <div style="flex: 1">
        <el-table
          :data="VisitorListData"
          @selection-change="selectionChange"
          border
          size="mini"
          :row-style="{ height: 61 + 'px' }"
          default-expand-all
          v-loading="loading"
          style="overflow: auto"
          max-height="650"
        >
          <!-- :element-loading-text="$t('action.loading')" -->
          <template slot="empty">{{ emptyText }}</template>
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="personName"
            show-overflow-tooltip
            label="人员姓名"
            align="center"
          >
            <template slot-scope="scope">
              <div class="clickable" @click="handleDeleteModify(scope.row)">
                {{ scope.row.personName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="unitName"
            show-overflow-tooltip
            label="单位"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="deptName"
            show-overflow-tooltip
            label="部门"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="violationTypeName"
            show-overflow-tooltip
            label="不良行为"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="ccIsSynchronize"
            show-overflow-tooltip
            label="上报建委"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.ccIsSynchronize == 0 ? "未同步" : "已同步"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="violationDescribe"
            show-overflow-tooltip
            label="处罚决定内容"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="violationTime"
            show-overflow-tooltip
            label="发生时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="blacklist"
            show-overflow-tooltip
            label="影响范围"
            align="center"
          >
            <template slot-scope="scope">
              <el-switch
                class="switch"
                :value="scope.row.blacklist"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="0"
                :inactive-value="1"
                active-text="白"
                inactive-text="黑"
                @change="switchChange($event, scope.row)"
              >
              </el-switch>
              <!-- {{ scope.row.blacklist }} -->
            </template>
          </el-table-column>
          <!-- 操作 -->
          <!-- v-if="hasPerms('sys:aiViolationRecord:delete') || hasPerms('sys:aiViolationRecord:edit')" -->
          <el-table-column label="操作" align="center" width="130">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="handleUpdate(scope.row)"
                class="mr-4"
                >修改
              </el-button>

              <el-button
                type="text"
                size="small"
                @click="handleDelete(scope.row)"
                >删除
              </el-button>

            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryParams.pageNum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          style="padding: 10px; text-align: right"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 新增编辑页面 -->
    <el-dialog
      :title="operation"
      width="60%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="colsediong"
    >
      <el-form
        :model="dataForm"
        label-width="140px"
        :rules="!xiangqing ? {} : dataFormRules"
        ref="dataForm"
        :size="size"
        label-position="right"
        id="formList"
        :disabled="!xiangqing"
        :validate-on-rule-change="false"
      >
        <el-row>
          <!-- <el-col :span="8">
              <el-form-item label="所属单位" prop="line">
                <treeselect
                  style="width: 100%"
                  v-model="dataForm.deptId"
                  :clearable="clearable"
                  :options="deptNameList"
                  :normalizer="normalizer"
                  :flat="true"
                  :disabled="!xiangqing"
                  placeholder="请选择"
                  @select="treeSel"
                ></treeselect>
              </el-form-item>
            </el-col> -->
          <el-col :span="8">
            <el-form-item label="所属单位" prop="unitId" class="">
<!--              <el-select
                :value="dataForm.unitName"
                placeholder="请选择所属单位"
                id="selectN"
              >
                <el-option :value="valueTitle" :label="valueTitle">
                  <el-tree
                    id="tree-option"
                    ref="selectTree"
                    :data="deptData"
                    :props="props"
                    node-key="id"
                    @node-click="inputTreeSelecet"
                  >
                  </el-tree>
                </el-option>
              </el-select>-->
              <el-select
                  v-model="dataForm.unitId"
                  placeholder="请选择"
                  @change="comChange"
                  style="width: 100%"
              >
                <el-option
                    v-for="item in unitList"
                    :key="item.uuid"
                    :label="item.companyName"
                    :value="item.uuid"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属部门" prop="deptId">
<!--              <el-select
                :value="dataForm.deptName"
                placeholder="请选择所属部门"
                id="selectN"
              >
                <el-option :value="valueTitle" :label="valueTitle">
                  <el-tree
                    id="tree-option"
                    ref="selectTree"
                    :data="deptDataDepartment"
                    :props="props"
                    node-key="id"
                    @node-click="inputTreeSelecet1"
                  >
                  </el-tree>
                </el-option>
              </el-select>-->
              <el-select
                  v-model="dataForm.deptId"
                  placeholder="请选择"
                  filterable
                  @change="deptChange"
                  style="width: 100%"
              >
                <el-option
                    v-for="item in deptList"
                    :key="item.id"
                    :label="item.departmentName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="人员" prop="personName">
              <el-select
                v-model="dataForm.personName"
                placeholder="请选择"
                class="form-item"
                style="width: 100%"
                @change="selctChange"
              >
                <el-option
                  v-for="item in userNameList"
                  :key="item.id"
                  :label="item.personName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="不良行为" prop="violationTypeName">
              <el-select
                v-model="dataForm.violationTypeName"
                placeholder="请选择"
                class="form-item"
                style="width: 100%"
                @change="selectChange"
              >
                <el-option
                  v-for="item in villationTypes"
                  :key="item.id"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="行为代码" prop="violationTypeName">
              <el-input
                type="input"
                v-model="villationTypesObj.code"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="行为类别" prop="violationTypeName">
              <el-input
                type="input"
                v-model="villationTypesObj.introType"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发生时间" prop="violationTime">
              <el-date-picker
                v-model="dataForm.violationTime"
                type="datetime"
                format="yyyy-MM-dd hh:mm"
                value-format="yyyy-MM-dd hh:mm"
                placeholder="起始时间"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="处罚日期" prop="punishDate">
              <el-date-picker
                v-model="dataForm.punishDate"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="处罚日期"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="处罚截止日期" prop="punishEndDate">
              <el-date-picker
                v-model="dataForm.punishEndDate"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="处罚截止日期"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="违规图片" prop="fileList" style="width: 100%">
<!--              <el-upload
                class="upload"
                :action="imgUrl"
                :file-list="fileList"
                accept=".png,.jpg"
                ref="upload"
                list-type="picture"
                :on-change="handleChangeImg"
                :on-remove="handleDelImg"
                :headers="imgHeaders"
                :auto-upload="false"
                :show-file-list="true"
              >
                <el-button size="small" type="primary">点击选择</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
              </el-upload>-->
              <Uploader @uploader="expertUploader" accept=".jpg,.png"></Uploader>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="处罚决定内容" prop="violationDescribe">
              <el-input
                type="textarea"
                v-model="dataForm.violationDescribe"
                auto-complete="off"
                show-word-limit
                maxlength="200"
              ></el-input>
            </el-form-item>
          </el-col>
<!--          <el-col :span="8">
            <el-form-item style="margin-left: -30px">
              <el-checkbox
                style="float: left; border-radius: 7px"
                v-model="dataForm.blacklist"
                :true-label="1"
                :false-label="0"
                @change="checboxChange"
                >加入黑名单并同步现场设备
              </el-checkbox>
              <el-button
                icon="el-icon-refresh"
                size="mini"
                :class="{ 'DL-btn': isDalian }"
                style="margin-left: 0"
                type="primary"
                @click="handleUpdate1()"
                >一键同步
              </el-button>
            </el-form-item>
          </el-col>-->
        </el-row>

        <!-- <el-form-item> -->
        <ul class="syncItme">
          <li
            v-for="(item, index) in syncList"
            :key="index"
            style="width: 100%; text-align: left"
            class="liclls"
          >
            <span class="gateCode">{{ item.gateCode }}</span>
            <span v-if="isShow">
              <span v-if="item.code">
                <i
                  class="el-icon-circle-check"
                  style="transform: scale(1.8); color: red"
                ></i>
              </span>
              <span v-else>
                <i
                  class="el-icon-circle-close"
                  style="transform: scale(1.8); color: red"
                ></i>
              </span>
              <span class="syncSmg">{{ item.synchroMsg }}</span>
            </span>
          </li>
        </ul>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="xiangqing">
        <el-button :size="size" @click.native="colsediong">
          <!-- {{
          $t("action.cancel")
        }} -->
          返回
        </el-button>
        <el-button
          :size="size"
          :class="{ 'DL-btn': isDalian }"
          type="primary"
          @click.native="submitForm"
        >
          提交
          <!-- {{ $t("action.submit") }} -->
        </el-button>
      </div>
    </el-dialog>

    <!-- 同步 -->
    <el-dialog
      title="同步"
      width="30%"
      :visible.sync="issync"
      :close-on-click-modal="false"
    >
      <el-form
        :model="syncForm"
        label-width="30px"
        :rules="syncFormRules"
        ref="syncForm"
        :size="size"
        label-position="right"
      >
        <div class="toolbarRight">
          <el-form :inline="true" :size="size">
            <el-form-item>
              <el-button
                icon="el-icon-refresh"
                size="mini"
                :class="{ 'DL-btn': isDalian }"
                type="primary"
                style="margin-left: 0"
                @click="handleUpdate1()"
                >同步</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="mainPart">
          <!--表格内容栏-->
          <el-table
            :data="syncList"
            style="overflow: auto"
            :close-on-click-modal="false"
          >
            <el-table-column
              prop="gateCode"
              header-align="center"
              min-width="70"
              align="center"
              label="设备名称"
            ></el-table-column>
            <el-table-column
              prop="isSynchro"
              show-overflow-tooltip
              header-align="center"
              min-width="80"
              align="center"
              label="是否同步"
            >
              <template slot-scope="scope">{{
                scope.row.isSynchro == "0"
                  ? "否"
                  : scope.row.isSynchro == "1"
                  ? "是"
                  : ""
              }}</template>
            </el-table-column>
            <el-table-column
              prop="synchroMsg"
              show-overflow-tooltip
              header-align="center"
              min-width="90"
              align="center"
              label="同步情况"
            ></el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="dialog-footer nameButton"
          style="text-align: center"
        >
          <el-button
            type="primary"
            :class="{ 'DL-btn': isDalian }"
            size="small"
            @click="submitSync()"
            >提交</el-button
          >
        </div>
        <!-- <el-form-item>
            <el-button icon="el-icon-refresh" size="mini" style="margin-left: 0" type="primary" @click="handleUpdate1()"
              >一键同步</el-button
            >
          </el-form-item>
          <el-form-item>
            <ul class="syncItme">
              <li v-for="(item, index) in syncList" :key="index" style="width: 100%; text-align: left">
                <span class="gateCode">{{ item.gateCode }}</span>
                <span v-if="isShow">
                  <span v-if="item.code">
                    <i class="el-icon-circle-check"></i>
                  </span>
                  <span v-else>
                    <i class="el-icon-close"></i>
                  </span>
                </span>
              </li>
            </ul>
          </el-form-item> -->
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import inputSelectTree from "@/components/inputSelectTree";
import propver from "@/components/propver/index.vue";
import { getDictData } from "@/api/dict";
import {
  criminalrecordsDelete,
  criminalrecordsList,
  criminalrecordsSave,
  criminalrecordsupdate,
  getUserName,
  userNameList
} from "@/api/user";
import {getUserDeptList, queryDeptDetail} from "@/api/org";
import {getComs, getDepts} from "@/api/ucenter";
import Uploader from "@/views/DeviceManagent/components/Uploader";

export default {
  components: {
    // Treeselect,
    inputSelectTree,
    propver,
    Uploader
  },
  data() {
    return {
      loading: false,
      //查询参数
      queryParams: {
        deptId: "",
        deptIdList: [],
        deptName: "",
        pageNum: 1,
        pageSize: 10,
        violationType: "",
      },
      // 查询按钮
      size: "small",
      VisitorListData: [],
      total: 0,
      operation: "新增", //弹窗titlte
      dialogVisible: false, //弹窗显示隐藏
      dataForm: {
        deptId: "",
        unitName: "",
        deptName: "",
        personName: "",
        violationTypeName: "",
        violationTime: "",
        punishDate: "",
        punishEndDate: "",
        violationDescribe: "",
        fileList: [],
      }, //弹窗数据
      dataFormRules: {
        personName: [
          {
            required: true,
            message: "请输入不良人员姓名",
            trigger: "change",
          },
        ],
        violationTypeName: [
          {
            required: true,
            message: "请输入不良类型",
            trigger: "change",
          },
        ],
        violationTime: [
          { required: true, message: "请输入发生时间", trigger: "blur" },
        ],
        // line: [{ required: true, message: "请选择单位", trigger: "change" }],
        punishDate: [
          { required: true, message: "请选择处罚日期", trigger: "blur" },
        ],
        punishEndDate: [
          { required: true, message: "请选择处罚截止日期", trigger: "blur" },
        ],
        violationDescribe: [
          { required: true, message: "请填写处罚决定内容", trigger: "blur" },
        ],
        deptId: [
          { required: true, message: "请选择部门", trigger: "change" },
        ],
        unitId: [
          { required: true, message: "请选择单位", trigger: "change" },
        ],
     /*   fileList: [
          { required: true, message: "请上传图片", trigger: "change" },
        ],*/
      }, //弹窗 校验
      checked: 0, //弹窗 加入黑名单并同步现场设备
      // 树data
      deptData1: [],
      // 树的默认展开值
      expandedArr: [],
      // 树的key的转换
      defaultProps: {
        children: "children",
        label: "name",
      },
      // 不良影响的开关
      blacklist: 0,
      // 不良类型的下拉
      villationTypes: [],
      // 弹窗的按钮
      xiangqing: true,
      // 所属单位
      deptNameList: [],
      clearable: false,
      normalizer(node) {
        //去掉children=[]的children属性
        if (node.children && !node.children.length) {
          delete node.children;
        }
        return {
          id: node.id,
          label: node.name,
          children: node.children,
        };
      },
      //人员的下拉
      userNameList: [],
      multipleSelection: [],
      // 同步的
      issync: false,
      syncForm: {},
      syncFormRules: {},
      // 同步的设备
      syncList: [],
      isShow: false,
      // 树
      nameArr: [],
      idArr: [],
      valueTitle: "",
      defaultId: "",
      //默认勾选的树
      defaultCheckedkeys: [],
      emptyText: "",
      villationTypesObj: {},
      isDalian: sessionStorage.getItem("roleType") == "role_dalian",

      //权限树的默认配置
      props: {
        value: "id",
        label: "name",
        children: "children",
        expandTrigger: "hover",
        disabled: "disabled",
      },
      deptData: [],
      deptDataDepartment: [],
      imgUrl: "",
      fileList: [],
      imgHeaders: {
        Authorization: sessionStorage.getItem("token"),
      },
      comList: [],
      propsDept: {
        value: "uuid",
        label: "name",
        // label: "companyName",
        children: "children",
        disabled: "disabledS",
      },
      chooses: "",
      deptList : [],
      unitList : [],
      roleList : [],
      isbuild: true, //单位选择的要是建设单位就把角色清空
    };
  },
  created() {
    //   this.imgUrl = this.global.baseUrl + "/cooper/aiViolationRecord/uploadFile"
    // this.findPage()
  },
  mounted() {
    this.findPage();
    this.getvillationTypes();
    this.getdeptlist();
    this.getAllComs();
    // this.getUserNameList()
  },
  methods: {
    //获取所有单位
    async getAllComs() {
      let res = await getComs();
      if (res.code === 0) {
        this.unitList = res.data || [];
      }
    },
    //根据单位查询部门 和角色
    async comChange(val, data) {
      // data==undefined就是新增
      if (data == undefined) {
        this.dataForm.deptId = "";
      }
      queryDeptDetail(val).then((res) => {
        getDepts(val).then((ress) => {
          if (ress.code === 0) {
            this.deptList = ress.data || [];
          }
        });
        if (res.data.category == "build") {
          this.isbuild = false;
          this.roleList = []; //要是选择的是建设单位就把角色清空 选择完部门再掉角色
        } else {
          this.isbuild = true;
          let params = {
            pageNo: 1,
            pageSize: 10000,
            name: "-",
            departmentId: "-",
            parentId: "-",
            unitType: res.data.category,
            companyId: "-",
          };
          this.queryAllRole(params);
        }
      });
    },
    handleDelImg() {
      this.fileList = [];
      this.dataForm.fileList = [];
    },
    expertUploader(event){
      console.log(event);
      if(event.length !== 0){
        let params = {
          fileName : event.originalName,
          fileRealUrl : event.fileRealUrl,
          fileUrl : event.fileUrl
        }
        this.dataForm.fileList.push(params)
      }
    },
    handleChangeImg(file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      this.fileList = fileList;
      this.dataForm.fileList = fileList;
    },
    inputTreeSelecet(val, node, nodes) {
      if (val.parentId == 0) {
        return;
      } else {
        this.dataForm.unitName = val.name;
        this.dataForm.unitId = val.id;
        this.dataForm.unitType = val.unitType;
        this.dataForm.deptName = "";
        this.deptDataDepartment = [];
        this.postList();
      }
    },
    inputTreeSelecet1(val, node, nodes) {
      this.dataForm.deptName = val.name;
      this.dataForm.deptId = val.id;
      this.getUserNameList();
    },
    deptChange(){
      this.getUserNameList();
    },
    postList() {
      this.$api.userList.findDeptTreeById(this.dataForm.unitId).then((res) => {
        if (res.code == 200) {
          this.deptDataDepartment = res.data;
        }
      });
    },
    // 弹窗关闭
    colsediong() {
      this.dialogVisible = false;
      // this.fileImg = ""
      this.fileList = [];
      this.dataForm = {
        deptId: "",
        unitName: "",
        deptName: "",
        personName: "",
        violationTypeName: "",
        violationTime: "",
        punishDate: "",
        punishEndDate: "",
        violationDescribe: "",
        fileList: [],
      };
    },
    // 获取分页数据
    findPage(data) {
        this.loading = true;
        criminalrecordsList(this.queryParams).then((res) => {
          if (res.code == 200) {
            this.loading = false;
            this.VisitorListData = res.data.list;
            this.total = res.data.total;
            if ("list" in res.data) {
              this.emptyText = "";
            } else {
              this.emptyText = "暂无数据";
            }
          } else {
            this.loading = false;
            this.emptyText = "暂无数据";
          }
        });
    },
    // 删除
    handleDelete(row) {
      const id = (row && row.id) || this.ids;
      this.$confirm("确认删除选中记录吗？", "提示", {
        type: "warning",
      }).then(() => {
        criminalrecordsDelete(id).then((res) => {
          if (res.code == 200) {
            this.findPage();
            this.$message({ message: "删除成功", type: "success" });
          } else {
            this.$message({ message: "删除失败", type: "error" });
          }
        });
      });
    },
    // 新增
    handleAdd() {
      this.xiangqing = true;
      this.operation = "新增";
      this.dialogVisible = true;
    },
    // 获取不良类型字典
    getvillationTypes() {
      let label = "violationType";
      getDictData(label).then((res) => {
        if (res.code == 0) {
          this.villationTypes = res.data;
        }
      });
      //   this.$api.user.findAll("violationType").then((res) => {
      //     console.log(res);
      //     this.villationTypes = res.data;
      //   });
    },
    // 不良人员弹窗
    getUserNameList() {
      userNameList(this.dataForm.deptId).then((res) => {
        this.userNameList = res.data;
      });
    },
    treeSel(node, instanceId) {
      console.log(node, instanceId);
      this.queryParams.deptId = node.id;
      this.getUserNameList();
    },
    // 同步
    handleSync(data) {
      this.issync = true;
      this.isShow = false;
      this.$api.user.syncAiGateUser({ userId: data.id }).then((res) => {
        this.syncList = res.data;
      });
    },
    // 一键同步
    handleUpdate1() {
      this.isShow = true;
      if (this.syncList.length > 0) {
        this.syncList.forEach((item, index) => {
          this.$api.user
            .saveOrUpdateUser({ userId: item.userId })
            .then((res) => {
              this.syncList.map((i) => {
                if (item.id == i.id) {
                  i.synchroMsg = res.msg;
                }
              });
            });
        });
      } else {
        this.$message.warning("当前没有设备可以同步");
      }

      // this.isShow = false
    },
    // 导出
    exportExcel() {
      console.log(this.queryParams);
      let obj = {
        personName: this.queryParams.personName,
        violationTime: this.queryParams.violationTime,
        deptId: this.queryParams.deptId,
        deptIdList: this.queryParams.deptIdList,
        deptName: this.queryParams.deptName,
        violationType: this.queryParams.violationType,
      };
      this.exportLoading = true;
      this.$api.user.criminalrecordsExport(obj).then((res) => {
        const aLink = document.createElement("a");
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        aLink.href = URL.createObjectURL(blob);
        aLink.download = "不良记录.xlsx";
        aLink.click();
        document.body.appendChild(aLink);
      });
    },
    //修改
    handleUpdate(row) {
      this.operation = "修改";

      this.xiangqing = true;
      this.dataForm = {};
      getUserName(row.id).then((res) => {
        this.dataForm = res.data;
        this.$set(this.dataForm, "fileList", []);
        if (this.dataForm.violationImgs) {
          this.fileList.push({
            name: "违规图片",
            url: this.dataForm.violationImgs,
          });
        }
        this.unitList.forEach(item => {
          if(this.dataForm.unitId === item.id){
            this.dataForm.unitId = item.uuid;
          }
        })
        this.dialogVisible = true;
        this.villationTypes.forEach(item => {
          if(this.dataForm.violationType === item.code){
            this.dataForm.violationTypeName = item.intro;
          }
        })
        this.villationTypesObj = this.villationTypes.filter(
          (i) => i.code == this.dataForm.violationType
        )[0];
      });
    },
    selectChange(value) {
      this.villationTypesObj = this.villationTypes.filter(
        (i) => i.label == value
      )[0];
    },
    // 人员选择
    selctChange(val) {
      console.log(val);
      // this.issync = true
      this.isShow = false;
      this.$api.user.syncAiGateUser({ userId: val }).then((res) => {
        this.syncList = res.data;
        this.syncList.forEach((item) => {
          item.code = false;
        });
      });
    },
    uploadFile(id) {
      console.log(this.fileList);
      if (this.fileList.length) {
        let form = new FormData();
        form.append("id", id);
        form.append("file", this.fileList[0].raw);
        this.$api.user.criminalImg(form).then((res) => {
          console.log(res);
        });
      }
    },
    // 确定
    submitForm() {
      if (this.dataForm.blacklist !== 1) {
        this.dataForm.blacklist = 0;
      }
      this.userNameList.forEach((item) => {
        if (this.dataForm.personName == item.id) {
          this.dataForm.userId = item.id;
          this.dataForm.userName = item.personName;
        }
      });
      this.villationTypes.forEach((i) => {
        if (this.dataForm.violationTypeName == i.label) {
          // console.log(this.dataForm.violationTypeName ,i.val)
          this.dataForm.violationType = i.code;
          this.dataForm.violationTypeName = i.label;
        }
      });
      this.unitList.forEach(item => {
        if(this.dataForm.unitId === item.uuid){
          this.dataForm.unitId = item.id;
        }
      })
      if (this.operation == "新增") {
        let that = this;
        this.$confirm("确认提交吗？", "提示", {}).then(() => {
          that.$refs.dataForm.validate((valid) => {
            if (valid) {
              criminalrecordsSave(that.dataForm).then((res) => {
                if (res.code == 200) {
                  that.uploadFile(res.data.id);
                  that.$message({ message: "操作成功", type: "success" });
                  that.colsediong();
                  that.findPage();
                  that.$refs["dataForm"].resetFields();
                } else {
                  that.$message({
                    message: "操作失败, " + res.msg,
                    type: "error",
                  });
                }
              });
            }
          });
        });
      } else {
        let that = this;
        this.$confirm("确认提交吗？", "提示", {}).then(() => {
          that.$refs.dataForm.validate((valid) => {
            if (valid) {
              criminalrecordsupdate(that.dataForm)
                .then((res) => {
                  if (res.code == 200) {
                    that.uploadFile(that.dataForm.id);
                    that.$message({ message: "操作成功", type: "success" });
                    that.colsediong();
                    that.findPage();
                    that.$refs["dataForm"].resetFields();
                  } else {
                    that.$message({
                      message: "操作失败, " + res.msg,
                      type: "error",
                    });
                  }
                });
            }
          });
        });
      }
    },
    // 影响范围 开关
    switchChange(val, row) {
      console.log(val);
      this.$confirm("确认更改吗？", "提示", {}).then(() => {
        let that = this;
        row.blacklist = val;
        criminalrecordsupdate(row).then((res) => {
          if (res.code == 200) {
            // this.dataForm.blacklist = val
            that.$message({ message: "操作成功", type: "success" });
            that.colsediong();
            that.findPage();
            /* that.$refs["dataForm"].resetFields() */
          } else {
            that.findPage();
            that.$message({
              message: "操作失败, " + res.msg,
              type: "error",
            });
          }
        });
      });
    },
    // 表格的复选框
    selectionChange(selections) {
      this.multipleSelection = selections;
      this.ids = selections.map((item) => item.id);
      if (this.multipleSelection.length !== 0) {
        document.getElementById("btn").style.display = "block";
      } else {
        document.getElementById("btn").style.display = "none";
      }
    },
    // 树的节点
    handleNodeClick(checked) {
      this.queryParams.deptId = checked.id;
      // this.queryParams.deptName = checked.name
      this.findPage();
      this.getUserNameList();
    },
    getdeptlist() {
      let params = {
        name: "",
        category: "",
      };
      getUserDeptList(params)
          .then((res) => {
            if (res.code === 0) {
              res.data.forEach((item) => {
                item.disabledS = true;
              });
              this.comList = res.data || [];
            }
          })
          .catch((res) => {
            this.$message({
              message: res.message,
              type: "error",
            });
          });
    },
    // 推送
    handlePush(row) {
      console.log(row);
      this.$api.user.pushBad({ id: row.id }).then((res) => {
        if (res.code == 200) {
          this.$message.success("推送成功");
          this.findPage();
        } else {
          this.$message.error("推送失败");
        }
      });
    },
    // 选中
    handleCheckChange(data, name) {
      this.queryParams.deptIdList = data;
      this.nameArr = name;
      // this.findPage()
    },
    deptTreeCurrentChangeHandle(data) {
      console.log(data);
      this.chooses = data.name;
      this.queryParams.deptIdList = [data.id]
    },
    // 清除选中
    clearHandle() {
      // this.$refs.selectTree.setCheckedKeys([])
      this.nameArr = [];
      this.idArr = [];
      this.queryParams.deptIdList = [];
    },
    checboxChange(val) {
      console.log(val);
      this.dataForm.blacklist = val;
    },
    //分页
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.findPage();
    },
    //分页
    handleCurrentChange(val) {
      this.queryParams.pageNum = val;
      this.findPage();
    },
    // 详情
    handleDeleteModify(row) {
      this.dialogVisible = true;
      this.operation = "详情";
      this.$api.user.getUserName(row.id).then((res) => {
        this.dataForm = res.data;
        if (this.dataForm.violationImgs) {
          this.fileList.push({
            name: "违规图片",
            url: this.dataForm.violationImgs,
          });
        }
        this.villationTypesObj = this.villationTypes.filter(
          (i) => i.label == this.dataForm.violationTypeName
        )[0];
      });
      this.xiangqing = false;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
