<template>
  <!--三防工作统计表监理审  -->
  <div>
    <div style="margin-bottom:20px">
      <el-radio-group
        size="mini"
        v-model="degree"
        v-for="item in times"
        :key="item.time"
        @change="degreeChange"
      >
        <el-radio-button
          :label="item.label"
          style="margin:0 10px 5px 0"
        ></el-radio-button>
      </el-radio-group>
    </div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="线路">
        <el-select
          v-model="formInline.lineId"
          placeholder="请选择"
          @change="lineChange"
        >
          <el-option
            v-for="item in lineList"
            :key="item.id"
            :label="item.name"
            :value="item.uuid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分部">
        <el-select
          v-model="formInline.branchUuid"
          placeholder="请选择"
          @change="branchChange"
        >
          <el-option
            v-for="item in branchList"
            :key="item.id"
            :label="item.name"
            :value="item.uuid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工点">
        <el-select v-model="formInline.siteUuid" placeholder="请选择">
          <el-option
            v-for="item in siteList"
            :key="item.id"
            :label="item.name"
            :value="item.uuid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table
      ref="tableData"
      :data="tableData"
      id="tableData"
      border
      :row-style="{ height: '30px' }"
      style="width: 100%"
      v-loading="tableLoading"
      @selection-change="handleSelectionChange"
      :summary-method="getSummaries"
      :span-method="objectSpanMethod"
      :cell-class-name="cellcb"
    >
      <el-table-column type="selection" width="55" align="center">
      </el-table-column>
      <el-table-column
        label="线路分部"
        prop="branch"
        align="center"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        label="工点"
        prop="siteName"
        align="center"
        width="120"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        label="派出工作组(个)从应急响应启动到本次报送时间节点,派出工作组的累计数量"
        align="center"
        prop="workGroup"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="position"
        label="检查场所部位(处)从应急响应启动到本次报送时间节点,检查场所部位的累计数量"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="hiddenDanger"
        label="新排查出三防风险隐患(处)上—次报送时间节点到本次报送时间节点，新排查出的三防风险隐患数量"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="info"
        label="发送预警或防御信息(条)地铁集团不需要填该项"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="worker"
        label="预制(出动)抢险人员(人)在本次报送时间节点，能立即调动的抢险人员"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="team"
        label="预制(出动)抢险队伍(支)在本次报送时间节点，能立即调动的抢险队伍数量"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        label="预置(出动)抢险设备(台)指在本次报送时间节点，能立即调动设备数量"
        align="center"
      >
        <el-table-column
          prop="deviceTotal"
          label="合计"
          align="center"
          width="50"
        >
        </el-table-column>
        <el-table-column
          prop="vehicle"
          label="车辆能立即调动的抢险汽车、吊车、铲车、履带车等"
          align="center"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="boat"
          label="舟艇能立即调动的舟船、划艇等水上运输工具数量"
          align="center"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="waterPump"
          label="抽水机能立即调动的龙吸水、自吸泵、排污泵、潜水泵、动力站水泵等抽排水设备数量"
          align="center"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="other"
          label="其他能立即调动的、未统计在车辆、舟艇、抽水机范围内的三防抢险设备数量"
          align="center"
          width="120"
        >
        </el-table-column>
      </el-table-column>

      <el-table-column
        prop="remark"
        label="其他应急措施落实及受灾害影响情况指从应急响应启动到本次报送时间节点，集团公司各单位应急措施落实及受灾受影响累计情况"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="deadline"
        label="截至时间"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="fillTime"
        label="填报时间"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="fillUser"
        label="填报人员"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column prop="status" label="状态" align="center" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalState == -2">超时</span>
          <span v-if="scope.row.approvalState == -1" style="color:#18c268"
            >待填报</span
          >
          <span v-if="scope.row.approvalState == 0">待审批</span>
          <span v-if="scope.row.approvalState == 1">审批通过</span>
          <span v-if="scope.row.approvalState == 2" style="color:red"
            >驳回</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="120"
        v-if="supervisoTitle == 'audit'"
      >
        <template slot-scope="scope">
          <el-button
            @click="transit(scope.row)"
            type="text"
            size="small"
            v-if="scope.row.siteName != '小计' && scope.row.approvalState == 0"
          >
            通过
          </el-button>
          <el-button
            @click="turnDown(scope.row)"
            type="text"
            size="small"
            v-if="scope.row.siteName != '小计' && scope.row.approvalState == 0"
          >
            驳回
          </el-button>
          <!--   <el-button @click="check(scope.row)" type="text" size="small">
            查看详情
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <div
      style="text-align: center;margin-top:10px"
      v-show="supervisoTitle == 'audit'"
    >
      <el-button type="primary" @click="BatchApproval('1')">批量审批</el-button>
      <el-button type="danger" @click="BatchApproval('2')">批量驳回</el-button>
      <el-button @click="closetable">关闭</el-button>
    </div>

    <!--审核驳回弹出框 -->
    <el-dialog
      :title="title"
      :visible.sync="turnDownDialog"
      width="40%"
      :before-close="turnDownClose"
      center
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <el-form ref="downForm" :model="downForm" label-width="80px">
        <!-- <span>请输入驳回原因:</span> -->
        <el-form-item label="审批人">
          <el-input type="input" v-model="user" readonly></el-input>
        </el-form-item>
        <el-form-item label="审批时间">
          <el-input type="input" v-model="value1" readonly></el-input>
        </el-form-item>
        <el-form-item
          label="审批意见"
          prop="approverOpinion"
          :rules="{
            required: true,
            message: '请填写审批意见',
            trigger: 'bulr',
          }"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 8, maxRows: 16 }"
            v-model="downForm.approverOpinion"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="display:flex;justify-content: center;">
        <el-button type="primary" @click="Batch">确定</el-button>
        <el-button @click="rejected">取消</el-button>
      </div>
    </el-dialog>

    <!-- 广州市交通运输系统三防工作统计表 详情页 -->
    <!-- <el-dialog
      title="广州市交通运输系统三防工作统计表"
      :visible.sync="checkDialog"
      width="80%"
      :before-close="checkClose"
      center
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <checkPage
        v-loading="checkPageLoading"
        @checkClose="checkClose"
        :checkData="checkData"
      ></checkPage>
    </el-dialog> -->
  </div>
</template>

<script>
// import {
//   getStatisticData,
//   getRes,
//   approve,
//   getCheckDetail,
// } from "@/api/response";
// import checkPage from "./checkPage.vue";
import {
  SupervisionRegulationList,
  supervisorApprover,
} from "@/api/currentResponse";
import dayjs from "dayjs";
export default {
  props: {
    supervisoId: {
      type: Number,
      default: () => null,
    },
    //事件id
    supervisoResponseId: {
      type: Number,
      default: () => null,
    },
    // 当前登录人是属于哪的（1：施工、2：监理、3：建管、4：质安部、5：其他）
    pageType: {
      type: Number,
      default: () => null,
    },
    //监管部的UUID
    supervisionUuid: {
      default: () => null,
    },
    //线路工点
    linetes: {
      type: Array,
      default: () => [],
    },
    supervisoTitle: {
      type: String,
    },
  },
  components: {
    // checkPage,
  },
  data() {
    return {
      value1: dayjs().format("YYYY年MM月DD日 HH时mm分"),
      user: JSON.parse(localStorage.getItem("user")).realName,
      times: [],
      degree: "第1次",
      tableData: [],
      tableTotalData: [],
      checkPageLoading: false,
      //审批驳回
      turnDownDialog: false,
      downForm: {
        approverOpinion: "",
      },
      checkDialog: false,
      tableLoading: false,
      formInline: {
        lineId: "",
        branchUuid: "",
        siteUuid: "",
      },
      // 驳回参数
      id: "",
      constructionCurrentId: "",
      // 查看返回的列表
      checkData: [],
      currentId: "",
      //复选框选中的行
      selectchange: [],
      //线路工点的下拉选
      lineList: [],
      branchList: [],
      siteList: [],
      approverStatus: 1, //点击的当前行的通过还是驳回
      // approverStatusp: null,
      ids: [], //通过驳回的id的数组
      title: "通过",
    };
  },
  created() {},
  mounted() {
    // this.getTableList();
    //线路
    this.linetes.forEach((element) => {
      this.lineList.push(element);
    });
  },
  watch: {
    supervisoId: {
      handler(val) {
        if (val) {
          // this.getList(val);
          // this.getTableList();
          this.currentId = val;
        }
      },
      immediate: true,
      deep: true,
    },
    /*  supervisoResponseId: {
      handler(val) {
        if (val) {
          this.getGenre(val);
        }
      },
      immediate: true,
      deep: true,
    }, */
  },
  methods: {
    // 表格的通过驳回
    // 通过
    transit(row) {
      this.turnDownDialog = true;
      this.approverStatus = 1;
      this.ids = [row.currentId];
      this.title = "通过";
    },
    //驳回
    turnDown(row) {
      this.turnDownDialog = true;
      this.approverStatus = 2;
      this.ids = [row.currentId];
      this.title = "驳回";
    },
    //  弹窗审批 确定
    Batch() {
      this.$refs["downForm"].validate((valid) => {
        if (valid) {
          // this.approverStatusp = 3;
          let params = {
            approverStatus: this.approverStatus,
            approverOpinion: this.downForm.approverOpinion,
            currentIds: this.ids,
            responseId: this.supervisoResponseId,
          };
          // if (this.approverStatus == 1) {
          supervisorApprover(params)
            .then((res) => {
              if (res.code == 0) {
                this.turnDownClose();
                this.gettable();
                this.$message.success("操作成功！");
              } else {
                this.$message.error("操作失败！");
              }
            })
            .catch((err) => {
              this.$message.error("操作失败！");
            });
          // }
        }
      });
    },
    // 弹窗审批 取消
    rejected() {
      // this.approverStatusp = 3;
      // this.$refs["downForm"].validate((valid) => {
      //   if (valid) {
      //     let params = {
      //       approverStatus: 2,
      //       approverOpinion: this.downForm.approverOpinion,
      //       currentIds: this.ids,
      //       responseId: this.supervisoResponseId,
      //     };
      //     supervisorApprover(params).then((res) => {
      //       if (res.code == 0) {
      this.turnDownClose();
      this.gettable();
      // }
      // });
      // }
      // });
    },
    //线路的选择
    lineChange(val) {
      this.formInline.branchUuid = "";
      this.formInline.siteUuid = "";
      this.branchList = [];
      this.siteList = [];
      let line = this.linetes.find((ite) => ite.uuid == val);
      if (line.children && line.children.length > 0) {
        this.branchList = line.children;
      }
    },
    //分布的选择
    branchChange(val) {
      let branch = this.branchList.find((ite) => ite.uuid == val);
      this.formInline.siteUuid = "";
      if (branch.children && branch.children.length > 0) {
        // this.siteList = branch.children;
        branch.children.forEach((item) => {
          this.siteList.push(item);
          if (item.children && item.children.length > 0) {
            item.children.forEach((i) => {
              this.siteList.push(i);
            });
          }
        });
      }
    },
    // 搜索
    handleSearch() {
      this.gettable();
    },

    //表格数据
    getTableList() {
      let params = {
        branchUuid: this.formInline.branchUuid
          ? this.formInline.branchUuid
          : null,
        lineId: this.formInline.lineId ? this.formInline.lineId : null,
        responseId: this.supervisoResponseId,
        siteUuid: this.formInline.siteUuid ? this.formInline.siteUuid : null,
        status: null,
        supervisionUuid: "",
      };
      //判断当前是监理还是监管
      // if (this.pageType == 2) {
      //   params.status = 1;
      //   params.supervisionUuid = null;
      // } else if (this.pageType == 3) {
      //   params.status = 2;
      //   params.supervisionUuid = this.supervisionUuid;
      // }

      SupervisionRegulationList(params).then((res) => {
        if (res.code == 0) {
          this.times = [];
          let data = [];
          if (res.code == 0) {
            this.tableLoading = false;
            res.data.forEach((element) => {
              this.times.push({
                time: element.times,
                label: "第" + element.times + "次",
              });
            });
            if (res.data && res.data.length > 0) {
              let nn = res.data[0].statisticFormDtoList;
              nn.forEach((item) => {
                for (let i in item) {
                  if (item[i] && item[i].length > 0) {
                    item[i].forEach((kk) => {
                      kk.branch = kk.lineName ? kk.lineName + "-" + i : "" + i;
                      if (kk.fillUser && kk.fillUser.name) {
                        kk.fillUser = kk.fillUser.name;
                      }
                      data.push(kk);
                    });
                  }
                  if (i == "汇总") {
                    item[i].branch = "汇总";
                    item[i].siteName = "/";

                    data.push(item[i]);
                  }
                }
              });
              console.log(data, "当前表格数据");

              this.tableData = data;
            }
            this.tableTotalData = res.data;
          }
        }
      });
    },
    // 页面刷新的数据
    gettable() {
      let params = {
        branchUuid: this.formInline.branchUuid
          ? this.formInline.branchUuid
          : null,
        lineId: this.formInline.lineId ? this.formInline.lineId : null,
        responseId: this.supervisoResponseId,
        siteUuid: this.formInline.siteUuid ? this.formInline.siteUuid : null,
        status: null,
        supervisionUuid: "",
      };

      SupervisionRegulationList(params).then((res) => {
        if (res.code == 0) {
          let data = [];
          let num = this.degree.slice(1, 2);
          console.log(
            num - 1,
            this.degree,
            "当前第几次",
            this.tableTotalData,
            res.data
          );
          let nn = res.data[num - 1].statisticFormDtoList;
          nn.forEach((item) => {
            for (let i in item) {
              if (item[i] && item[i].length > 0) {
                item[i].forEach((kk) => {
                  kk.branch = kk.lineName ? kk.lineName + "-" + i : "" + i;
                  if (kk.fillUser && kk.fillUser.name) {
                    kk.fillUser = kk.fillUser.name;
                  }
                  data.push(kk);
                });
              }
              if (i == "汇总") {
                item[i].branch = "汇总";
                item[i].siteName = "/";
                data.push(item[i]);
              }
            }
          });
          console.log(data, "审批过后的数据刷新 ");
          this.tableData = data;
        }
      });
    },
    //合并行合并列
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1) {
        const _row = this.filterData(this.tableData, columnIndex).one[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    filterData(arr, colIndex) {
      let spanOneArr = [];
      let concatOne = 0;
      arr.forEach((item, index) => {
        if (index == 0) {
          spanOneArr.push(1);
        } else {
          // name标识数据表格第一列的参数
          if (colIndex == 1) {
            if (item.branchName === arr[index - 1].branchName) {
              spanOneArr[concatOne] += 1;
              spanOneArr.push(0);
            } else {
              spanOneArr.push(1);
              concatOne = index;
            }
          }
        }
      });
      return {
        one: spanOneArr,
      };
    },
    cellcb(row) {
      if (row.row.siteName == "小计" || row.row.siteName == "汇总") {
        // return "myCell";
        // return "display: none";
        if (row.columnIndex == 0) {
          return "myCell";
          // return "opacity:0";
        }
      }
    },
    // 表格的汇总
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "汇总";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          // sums[index] += " 元";
        } else {
          sums[index] = "/";
        }
      });

      return sums;
    },
    // 表格的复选框
    handleSelectionChange(val) {
      this.selectchange = [];
      this.ids = [];
      this.selectchange = val;
      this.selectchange.forEach((item) => {
        if (
          item.currentId &&
          item.currentId !== "" &&
          item.currentId !== null
        ) {
          this.ids.push(item.currentId);
        }
      });
      console.log(this.ids, "ids");
    },
    //批量通过驳回的弹出层
    BatchApproval(type) {
      if (type == 1) {
        this.title = "通过";
        this.approverStatus = 1;
      } else {
        this.title = "驳回";
        this.approverStatus = 2;
      }
      if (this.selectchange.length > 0) {
        let selectcox = [];
        this.selectchange.forEach((item) => {
          if (item.siteName !== "小计" && item.branch !== "汇总") {
            selectcox.push(item);
          }
        });
        // 能BU审批的
        let nn = selectcox.findIndex((item) => item.approvalState != 0);
        // 找不到是-1
        if (nn == -1) {
          this.turnDownDialog = true;
        } else {
          this.$message.warning("当前选中的有已审批");
        }
      } else {
        this.$message.warning("请先勾选");
      }
    },
    //表格弹窗关闭
    closetable() {
      this.$emit("supervisoClose");
    },

    // 第一次第二次第三次
    degreeChange(val) {
      console.log(val, this.degree);
      // 把所有非数字的替换成空
      var arr = val.replace(/\D/g, "");
      this.tableData = [];
      let data = [];
      // this.gettable();
      this.tableTotalData.forEach((item) => {
        if (arr == item.times) {
          let nn = item.statisticFormDtoList;
          nn.forEach((item) => {
            for (let i in item) {
              if (item[i] && item[i].length > 0) {
                item[i].forEach((kk) => {
                  kk.branch = kk.lineName + i;
                  if (kk.fillUser && kk.fillUser.name) {
                    kk.fillUser = kk.fillUser.name;
                  }
                  data.push(kk);
                });
              }
              if (i == "汇总") {
                item[i].branch = "汇总";
                item[i].siteName = "/";

                data.push(item[i]);
              }
            }
          });
          console.log(data);

          this.tableData = data;
          // this.tableData = item.statisticFormDtoList;
        }
      });
    },
    //审批的弹窗关闭
    turnDownClose() {
      this.turnDownDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-table::before {
  height: 0;
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: #fff !important;
  border-radius: 7px;
}
/*定义滑块颜色、内阴影及圆角*/
::v-deep ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
::v-deep ::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 7px;
  border-radius: 0;
}
/*定义两端按钮的样式*/
::v-deep ::-webkit-scrollbar-button {
  display: none;
}

//去掉固定列下方的横线
::v-deep .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: transparent;
}
//去掉固定列阴影
::v-deep .el-table__fixed-right {
  // box-shadow: none;
  height: 100% !important;
}

// 表格多选框是小计的隐藏掉
::v-deep .myCell .el-checkbox__input {
  display: none !important;
}
::v-deep .el-table th > .cell {
  font-size: 12px !important;
}
::v-deep .el-table--border,
.el-table--group {
  border: none;
}
::v-deep .el-table--border::after,
.el-table--group::after {
  width: 0 !important;
}
::v-deep .el-table--border th.el-table__cell.gutter:last-of-type {
  background-color: #fff !important;
  border-bottom: none;
}
::v-deep .el-radio-button {
  width: 75px;
}
::v-deep .el-radio-button__inner {
  width: 65px;
}
</style>
