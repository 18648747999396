<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" @modalClose="handleModalClose" :title="title" width="40%">
    <el-form v-loading="loading" :model="model" :rules="rules" ref="modelForm" label-width="120px" class="demo-model"
      style="width: 80%">
      <el-form-item label="姓名" prop="specialistName">
        <el-input v-model="model.specialistName" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="单位/部门">
        <el-input v-model="model.deptName" placeholder="请输入单位/部门"></el-input>
      </el-form-item>
      <el-form-item label="职务">
        <el-input v-model="model.postName" placeholder="请输入职务"></el-input>
      </el-form-item>
      <el-form-item label="专业类型" prop="majorType">
        <el-select v-model="model.majorType" placeholder="请选择专业类型">
          <el-option v-for="item of dict.majorType" :key="item.id" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="擅长领域">
        <el-input v-model="model.expertiseAreas" placeholder="请输入擅长领域"></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="contactInfo">
        <el-input v-model="model.contactInfo" placeholder="请输入联系方式"></el-input>
      </el-form-item>
      <el-form-item label="简介" prop="synopsis">
        <el-input v-model="model.synopsis" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入简介">
        </el-input>
      </el-form-item>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from '@/components/Modal/systemModal.vue';
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { addExpert, updateExpert } from "@/api/eres";
export default {
  components: {
    systemModal
  },
  data() {
    return {
      title: "新增",
      model: {
        specialistName: "",
        deptName: "",
        postName: "",
        majorType: "",
        expertiseAreas: "",
        contactInfo: "",
        synopsis: ""
      },
      dict: {
        majorType: []
      },
      loading: false,
      rules: {
        specialistName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        deptName: [
          { required: true, message: '请输入单位/部门', trigger: 'blur' }
        ],
        postName: [
          { required: true, message: '请输入职务', trigger: 'blur' }
        ],
        majorType: [
          { required: true, message: '请输入专业类型', trigger: 'blur' }
        ],
        expertiseAreas: [
          { required: true, message: '请输入擅长领域', trigger: 'blur' }
        ],
        contactInfo: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/, message: "请输入合法手机号码" }
        ],
      }
    };
  },
  props: {},
  mounted() {
    this.getDictData("专业类型", this.dict.majorType);
    // this.getDictData("擅长领域", this.dict.expertiseAreas);
  },
  methods: {
    handleAdd(record) {
      if (record) {
        this.title = "编辑";
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
      } else {
        this.title = "新增";
      }
      this.handleEdit();
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          const params = JSON.parse(JSON.stringify(this.model));
          if (this.model.specialistId) {
            this.updateExpert(params);
          } else {
            this.addExpert(params);
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async addExpert(record) {
      this.loading = true;
      let res = await addExpert(record);
      if (res.code == 0) {
        this.$message.success("操作成功");
        this.loading = false;
        this.$emit("formSubmit");
        this.handleModalClose();
        this.$refs.formModal.handleClose();
      }
    },
    async updateExpert(record) {
      this.loading = true;
      let res = await updateExpert(record);
      if (res.code == 0) {
        this.$message.success("操作成功");
        this.loading = false;
        this.$emit("formSubmit");
        this.handleModalClose();
        this.$refs.formModal.handleClose();
      }
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then(res => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then(res => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          })
        }
      });
    },
    // 清空el表单
    handleModalClose() {
      this.model = {
        specialistName: "",
        deptName: "",
        postName: "",
        majorType: "",
        expertiseAreas: "",
        contactInfo: "",
        synopsis: ""
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
