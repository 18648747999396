<template>
  <div class="material-container h-full">
    <el-steps
      :active="active"
      finish-status="wait"
      v-show="isShowTop && isShowNowTop"
    >
      <el-step title="" v-for="item in years" :key="item.id"></el-step>
    </el-steps>
    <p class="yearName" v-show="isShowTop && isShowNowTop">
      <span
        :class="active == item.id ? 'activeYear' : ''"
        v-for="item in years"
        :key="item.id"
        @click="quarterChange(item)"
        >{{ item.name }}</span
      >
    </p>
    <p class="radioBox" v-show="isShowTop && isShowNowTop">
      <el-radio-group v-model="radioValue" @change="radioChange">
        <el-radio label="1">历史季度变更</el-radio>
        <el-radio label="2">当前季度变更</el-radio>
      </el-radio-group>
    </p>
    <el-form
      ref="formData"
      :model="formData"
      label-width="100px"
      v-show="isShowTop && isShowNowTop"
    >
      <el-form-item label="风险变更描述">
        <el-input v-model="formData.changeText" type="textarea" disabled></el-input>
      </el-form-item>
      <el-form-item label="风险过程总结">
        <el-input v-model="formData.summaryText" type="textarea" disabled></el-input>
      </el-form-item>
    </el-form>
    <el-tabs v-model="activeName" @change="tabChange">
      <el-tab-pane label="风险基本信息" name="first">
        <AddPage
          :onlyInput="true"
          :pageData="addPageData"
          :dialogType="dialogType"
        ></AddPage>
      </el-tab-pane>
      <el-tab-pane :disabled="!isShowTop" label="风险交底" name="second">
        <DisclosureRisk
          :rowData="addPageData"
          disclosureType="view"
        ></DisclosureRisk>
      </el-tab-pane>
      <el-tab-pane :disabled="!isShowTop" label="风险启动" name="third">
        <StartRiskForm
          type="view"
          :rowData="addPageData"
          @showStartRecord="showStartRecord"
          :riskControlLevel="addPageData.riskLevelCode"
        ></StartRiskForm>
        <!-- :isShowTable="isShowTable" -->
      </el-tab-pane>
      <el-tab-pane :disabled="!isShowTop" label="风险巡查" name="fourth">
        <PatrolRiskForm
          :is-button="false"
          v-if="activeName === 'fourth'"
          view-risk-type="riskLibrary"
          :risk-deaf-uuid="addPageData.riskDeafUuid"
        ></PatrolRiskForm>
      </el-tab-pane>
      <el-tab-pane :disabled="!isShowTop" label="措施处置" name="five">
        <Cuoshi :measuresList="measuresList" @getData="getDataFn()"></Cuoshi>
      </el-tab-pane>
      <el-tab-pane :disabled="!isShowTop" label="风险消除" name="six">
        <RiskMitigationForm
          :rowInfo="addPageData"
          v-if="activeName === 'six'"
        ></RiskMitigationForm>
      </el-tab-pane>
    </el-tabs>
    <div class="dialogFooter">
      <el-button size="medium" @click="cancel">取 消</el-button>
      <el-button size="medium" type="primary" @click="submit">确 定</el-button>
    </div>
    <el-dialog
      width="50%"
      class="editDialog details-dialog"
      :visible.sync="dialogRecord"
      :close-on-click-modal="false"
      :before-close="handleAuditClose"
      append-to-body
      v-if="dialogRecord"
    >
      <Audit
        v-if="dialogRecord"
        :active="AuditActive"
        :stepSx="stepSx"
        :dialogRecord="dialogRecord"
        :rowData="currentInfo"
        @closeStartRecord="closeStartRecord"
        @showFileList="showFileList"
      ></Audit>
    </el-dialog>
  </div>
</template>

<script>
// 基本信息
import AddPage from "./addPage";
// 风险交底
import DisclosureRisk from "@/views/RiskManagement/System/RiskProcess/components/DisclosureRisk";
// 风险启动
import StartRiskForm from "@/views/RiskManagement/System/RiskProcess/components/StartRiskForm";
import Audit from "@/views/RiskManagement/System/RiskProcess/components/AuditDialog";
// 风险消除
import RiskMitigationForm from "@/views/RiskManagement/System/RiskProcess/components/RiskMitigationForm";
// 风险巡查
import PatrolRiskForm from "@/views/RiskManagement/System/RiskProcess/components/PatrolRiskForm";
// 风险措施
import Cuoshi from "@/views/RiskManagement/RiskList/RiskMeasures/modules/cuoshi";
import { getMeasures, getLibraryDetail, getSummary } from "@/api/risk";
import { riskStartpage } from "@/api/riskManage";
// 字典
export default {
  components: {
    AddPage,
    DisclosureRisk,
    StartRiskForm,
    Cuoshi,
    RiskMitigationForm,
    PatrolRiskForm,
    Audit
  },
  props: {
    addPageData: {
      type: Object,
      default: () => {},
    },
    isShowTop: {
      type: Boolean,
      default: () => false,
    },
    dialogType: {
      type: String,
      default: "待确定",
    },
  },
  data() {
    return {
      formData: {
        changeText: "",
        summaryText: "",
      },
      active: 0,
      years: [],
      activeName: "first",
      radioValue: "1",
      measuresList: [],
      yearQuarter: "",

      dataList: [],
      isShowNowTop: false,
      objectList: {
        siteName: "工点名称",
        riskName: "风险名称",
        riskLevelName: "风险控制等级",
        safeStatus: "安全情况",
        riskTypeName: "风险类别",
        riskSubTypeName: "风险子类",
        estimateStartDay: "预计开始时间",
        estimateEndDay: "预计结束时间",
        realStartDay: "实际开始时间",
        realEndDay: "实际结束时间",
        originalRiskLevelName: "原始风险等级",
        restRiskLevelName: "剩余风险等级",
        lon: "经度",
        lat: "纬度",
        mileageRange: "里程范围",
        relationConstructMethodName: "关联工法",
        subprojectName: "分部工程",
        constructPerson: "施工单位联系人",
        constructPhone: "施工单位联系人电话",
        designPerson: "设计单位联系人",
        designPhone: "设计单位联系人电话",
        monitorPerson: "监理单位联系人",
        monitorPhone: "监理单位联系人电话",
        buildPerson: "建设单位联系人",
        buildPhone: "建设单位联系人电话",
        surveyPerson: "勘察单位联系人",
        surveyPhone: "勘察单位联系人电话",
        thirdPerson: "第三方单位联系人",
        thirdPhone: "第三方单位联系人电话",
        riskDescribe: "风险描述",
      },
      showFileList: [],
      AuditActive: 2,
      currentInfo: {},
      stepSx: 1,
      dialogRecord: false, // 审核记录弹窗
    };
  },

  created() {},
  mounted() {
    this.init();
    getSummary(this.addPageData.riskDeafUuid).then((res) => {
      this.formData.summaryText = res.data;
      console.log(res);
    });
  },
  methods: {
    radioChange(val) {
      this.years = [];
      this.active = 0;
      this.formData.changeText = "";
      console.log("aaa");
      if (val == 1) {
        this.getYearDetail();
      } else {
        this.setQuarter();
      }
    },
    // 季度切换
    quarterChange(item) {
      this.formData.changeText = "";
      this.active = item.id;
      let index = 0;
      let list = null;
      if (this.radioValue == 1) {
        list = this.dataList.filter(
          (i) => i.yearQuarter == item.yearQuarter
        )[0];
        console.log(list);
        this.$emit("changeData", list);
        index = this.dataList.findIndex((i) => {
          return i.yearQuarter == list.yearQuarter;
        });

        console.log(this.dataList[index + 1]);
      } else {
        console.log(item);
        list = this.dataList.filter((i) => i.created == item.time)[0];
        console.log(list);
        this.$emit("changeData", list);
        index = this.dataList.findIndex((i) => {
          console.log(i.created, list.created);
          return i.created == list.created;
        });
        console.log(index);
        console.log(this.dataList[index]);
        console.log(this.dataList[index + 1]);
      }
      // 以下是获取变更
      let arr = [];
      if (this.dataList[index + 1]) {
        for (let key in list) {
          if (this.findDifferent(key)) {
            if (list[key] != this.dataList[index + 1][key]) {
              let obj = {};
              obj.enName = key;
              // 第一个是当前的，第二个是改变的
              obj.change = [list[key], this.dataList[index + 1][key]];
              arr.push(obj);
            }
          }
        }
      } else {
        this.formData.changeText = "";
      }
      console.log(arr);
      if (arr.length) {
        let str = "与上次变化对比：";
        arr.forEach((i, index) => {
          if (i.change[0] == null) {
            i.change[0] = "空";
          }
          if (i.change[1] == null) {
            i.change[1] = "空";
          }
          str = str + (index + 1 + ":") + this.objectList[i.enName] + " ";
          str = str + i.change[1] + " " + "变为" + " " + i.change[0];
        });
        console.log(str);
        this.formData.changeText = str;
      }
    },
    init() {
      this.getDataFn();
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;
      let quarter = 1;
      if (month > 9) {
        quarter = 4;
      } else if (month > 6) {
        quarter = 3;
      } else if (month > 3) {
        quarter = 2;
      } else {
        quarter = 1;
      }
      this.yearQuarter = year + "" + quarter;
      if (this.isShowTop) {
        this.getYearDetail();
      }
    },
    // 找不同的项
    findDifferent(key) {
      let isTrue = false;
      for (let k in this.objectList) {
        if (key == k) {
          isTrue = true;
        }
      }
      return isTrue;
    },
    // 获取详情
    getYearDetail() {
      let obj = {
        riskMasterUuid: this.addPageData.riskMasterUuid,
      };
      getLibraryDetail(obj).then((res) => {
        console.log(res);
        if (res.data.length) {
          this.dataList = res.data;
          this.$emit("changeData", res.data[0]);
          this.isShowNowTop = res.data.length ? true : false;
          this.setYears();
        }
      });
    },
    getDataFn() {
      getMeasures(this.addPageData.riskDeafUuid).then((res) => {
        // getMeasures("c6a2ffb0-10bf-49ba-b1e5-86716635c4e1").then((res) => {
        if (res.data) {
          this.measuresList = res.data;
        }
      });
    },
    // 设置当前季度
    setQuarter() {
      let obj = {
        riskMasterUuid: this.addPageData.riskMasterUuid,
        yearQuarter: this.yearQuarter,
      };
      getLibraryDetail(obj).then((res) => {
        console.log(res);
        if (res.data.length) {
          this.dataList = res.data;
          res.data.forEach((i, index) => {
            let obj = {};
            obj.id = index;
            obj.name = i.created.slice(0, 10);
            obj.time = i.created;
            this.years.push(obj);
          });
          this.quarterChange(this.years[0]);
        }
      });
    },
    // 设置当年季度
    setYears() {
      let year = new Date().getFullYear();
      this.dataList.forEach((i, index) => {
        let obj = {};
        obj.id = index;
        obj.name = `${year}年第${i.yearQuarter.charAt(4)}季度`;
        obj.yearQuarter = i.yearQuarter;
        this.years.push(obj);
      });
      this.quarterChange(this.years[0]);
      // let year = new Date().getFullYear();
      // let month = new Date().getMonth() + 1;
      // let quarter = 1;
      // if (month > 9) {
      //   quarter = 4;
      // } else if (month > 6) {
      //   quarter = 3;
      // } else if (month > 3) {
      //   quarter = 2;
      // } else {
      //   quarter = 1;
      // }
      // this.active = quarter - 1;
      // this.yearQuarter = year + "" + quarter;
      // for (let i = 0; i < quarter; i++) {
      //   let obj = {};
      //   obj.id = i;
      //   obj.name = `${year}第${i + 1}季度`;
      //   this.years.push(obj);
      // }
    },
    tabChange(val) {
      console.log(val);
    },
    submit() {},
    cancel() {
      this.reset();
      this.$emit("CloseData");
    },
    reset() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = "";
      });
    },
    closeStartRecord() {
      this.dialogRecord = false;
    },
    showStartRecord(row) {
      const that = this;
      riskStartpage({
        pageNum: 1,
        pageSize: 1
      }).then(res => {
        if (res.code == 0) {
          that.currentInfo = res.data.list[0];
          console.log(that.currentInfo, "currentInfo");
          that.getActive(that.currentInfo.processStatus);
          that.AuditActive = 2;
          // that.currentInfo = { ...row };
          that.currentCtolLevel = row.riskLevelCode;
          that.currentRiskName = row.riskName;
          that.dialogRecord = true;
        }
      });
    },
    getActive(status) {
      switch (status) {
        case 2:
          this.stepSx = 1;
          break;
        case 3:
          this.stepSx = 2;
          break;
        case 4:
          this.stepSx = 2;
          break;
        case 5:
          this.stepSx = 3;
          break;
        case 6:
          this.stepSx = 3;
          break;
        case 7:
          this.stepSx = 4;
          break;
        case 8:
          this.stepSx = 4;
          break;
        case 9:
          this.stepSx = 4;
          break;
        default:
          break;
      }
    },
    handleAuditClose() {
      this.dialogRecord = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.dialogFooter {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.el-steps {
  padding: 0 40px;
}
.yearName {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  span {
    cursor: pointer;
    color: #999;
  }
  .activeYear {
    color: #333333;
    font-weight: bold;
  }
}
.radioBox {
  text-align: right;
  margin: 20px 0;
}
</style>
