<template>
  <div class="image-uploader">
    <el-upload
      action="upload"
      :http-request="uploadImg"
      :show-file-list="false"
      :multiple="false"
      :accept="accept"
    >
      <div class="text-left">
        <div v-if="showCover">
          <el-image style="width: 150px;min-height:100px" :src="modelValue">
            <template #error>
              <div class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </template>
          </el-image>
        </div>
        <el-button style="outline: none;" size="small" type="success">{{
          loading ? percent + "%" : "点击上传"
        }}</el-button>
        <div v-show="!showCover">{{ fileData.name }}</div>
      </div>
    </el-upload>
  </div>
</template>
<script>
import { upload, uploadThumb } from "@/api/upload"
export default {
  name: "imageUploader",
  model: {
    event: "change",
    prop: "modelValue"
  },
  props: {
    width: {
      type: String,
      default: "150px"
    },
    height: {
      type: String,
      default: "100px"
    },
    showCover: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: false
    },
    modelValue: {},
    accept: {
      default: "image/*"
    },
    // 是否是封面 定高压缩
    isThumb: {
      default: true
    }
  },
  data () {
    return {
      loading: false,
      fileData: {
        name: ""
      },
      percent: 0
      // isFinally: false,
    }
  },
  methods: {
    async uploadImg (args) {
      this.percent = 0
      const { file } = args
      this.fileData = file
      let res = {}
      let fn = this.isThumb ? uploadThumb : upload
      res = await fn(file, e => {
        this.percent = ((e.loaded / e.total) * 100) | 0
        return args.onProgress({ percent: this.percent })
        // return this.percent;
      }).catch(err => {
        res.message = err
        console.error(err)
      })
      if (res.code === 0) {
        let url = res.data.uri
        // this.isFinally = false;
        this.$emit("change", url)
        this.$emit("on-success", res)
        this.$emit("test", res)
      } else {
        this.message.error("上传失败")
        // this.isFinally = false;
        this.$emit("error", res.message)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.image-uploader {
  .image-slot {
    font-size: 30px;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #f5f7fa;
    color: #909399;
  }
}
</style>
