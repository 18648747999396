import { render, staticRenderFns } from "./checkQuestions.vue?vue&type=template&id=718dd055&scoped=true&"
import script from "./checkQuestions.vue?vue&type=script&lang=js&"
export * from "./checkQuestions.vue?vue&type=script&lang=js&"
import style0 from "./checkQuestions.vue?vue&type=style&index=0&id=718dd055&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718dd055",
  null
  
)

export default component.exports