<template>
  <systemModal ref="formModal" @modalConfirm="handleSubmit" :title="title">
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="110px"
      class="demo-model"
    >
      <el-form-item
        label="工点名称"
        v-if="title == '新增' && dept == 0"
        prop="lineSite"
      >
        <el-cascader
          ref="linecas"
          placeholder="选择线路工点"
          v-model="model.lineSite"
          :options="sitelist"
          :props="props"
          filterable
          clearable
          @change="handleLineSiteChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="所属建管部" v-if="title == '新增' && dept == 0">
        <el-input
          v-model="model.supervisionName"
          placeholder="请输入所属建管部称"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="线路名称" v-if="title == '新增' && dept == 0">
        <el-input
          v-model="model.lineName"
          placeholder="请输入线路名称"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="分部名称" v-if="title == '新增' && dept == 0">
        <el-input
          v-model="model.branchName"
          placeholder="请输入分部名称"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="预案名称" prop="planName">
        <el-input v-model="model.planName" placeholder="请填写"></el-input>
      </el-form-item>
      <el-form-item label="预案类型" prop="planTypeId">
        <el-select v-model="model.planTypeId" placeholder="请选择">
          <el-option
            v-for="item of options"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
        <el-form-item label="编制时间" prop="organizationTime">
        <el-date-picker
          v-model="model.organizationTime"
          type="datetime"
          placeholder="选择编制时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="预案到期时间" prop="expireTime">
        <el-date-picker
          v-model="model.expireTime"
          type="datetime"
          placeholder="选择到期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="预案附件" prop="fileName">
        <el-upload
          class="upload-demo"
          action="upload"
          :http-request="uploadImg"
          :file-list="fileList"
          :limit="1"
        >
          <el-button style="outline: none;" size="small" type="success"
            >点击上传预案附件</el-button
          >
        </el-upload>
      </el-form-item>
    </el-form>
    <!--   <el-dialog
      title="正在进行上传"
      :visible.sync="isFinally"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <el-progress
        :text-inside="true"
        :stroke-width="20"
        :percentage="percent"
      ></el-progress>
      <p>正在上传...</p>
    </el-dialog> -->
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue"
import {
  addEmergencyData,
  UpdateEmergencyData,
  // Emergencyimport,
} from "@/api/emergency"
import { upload } from "@/api/upload"
export default {
  components: {
    systemModal,
  },
  props: {
    sitelist: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    dept: {},
  },
  data () {
    return {
      title: "新增",
      model: {
        lineSite: [],
        supervisionName: "",
        lineName: "",
        branchName: "",
        planName: "",
        planTypeId: "",
        expireTime: '',
        organizationTime:'',
        planFile: '',
        fileName: ''
      },
      loading: false,
      rules: {
        lineSite: [
          { required: true, message: '请选择线路工点', trigger: 'change' },
        ],
        planName: [
          {
            required: true, message: '请选择预案名称', trigger: 'change'
          },
        ],
        planTypeId: [
          {
            required: true, message: '预案类型', trigger: 'change'
          },
        ],
        expireTime: [
          {
            required: true, message: '预案到期时间', trigger: 'change'
          },
        ],
         organizationTime: [
          {
            required: true, message: '编制时间', trigger: 'change'
          },
        ],
        fileName: [
          {
            required: true, message: '预案附件', trigger: 'change'
          },
        ],
      },
      lineList: [],
      siteIdList: [],
      percent: 0,
      fileList: [],
      fileListsp: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
      },
      pingxingArrList: [],
      lineId: "",
      branchId: "",
      siteId: "",
      importFile: null,
      progressNum: 0,
      // isFinally: false,
    }
  },

  mounted () {
    console.log(this.sitelist, "sitelist")
  },
  methods: {
    async uploadImg (args) {
      // this.isFinally = true;
      this.percent = 0
      const { file } = args
      let res = {}
      let fn = upload
      res = await fn(file, (e) => {
        console.log(e)
        this.percent = ((e.loaded / e.total) * 100) | 0
        return args.onProgress({ percent: this.percent })
        // return this.percent;
      }).catch((err) => {
        res.message = err
        console.error(err)
      })
      if (res.code === 0) {
        console.log(file, res, args, "args")
        this.model.fileName = res.data.originalName
        this.model.planFile = res.data.uri
        // this.isFinally = false;
        this.$message.success("上传成功")
      } else {
        // this.isFinally = false;
        this.message.error("上传失败")
        this.$emit("error", res.message)
      }
    },
        // 递归查找
    getsteName(list, id) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          return list[i].constructionManagementName;
        } else if (list[i].children && list[i].children.length > 0) {
          let res = this.getsteName(list[i].children, id);
          if (res) {
            return res;
          }
        }
      }
    },
    handleLineSiteChange (val) {
      console.log(val)
     
     this.model.supervisionName=this.getsteName(this.sitelist,val[val.length - 1])
      if (val.length > 0) {
        this.lineId = val[0]
        this.branchId = val[1]
        this.siteId = val[val.length - 1]
      }
      this.sitelist.forEach((item) => {
        if (item.children && item.children !== null) {
          this.pingxingArr(item)
        }
        this.pingxingArrList.push(item)
        if (item.id == this.lineId) {
          this.model.lineName = item.name
          this.model.lineId = item.uuid
        }
      })
    },
    pingxingArr (data) {
      if (data.children && data.children !== null) {
        data.children.forEach((item) => {
          this.pingxingArrList.push(item)
          this.pingxingArr(item)
          if (item.id == this.branchId) {
            this.model.branchName = item.name
            this.model.branchId = item.uuid
          }
          if (item.id == this.siteId) {
            this.model.siteName = item.name
            this.model.siteId = item.uuid
            this.model.constructionManagementName =
              item.constructionManagementName
            this.model.constructionManagementUnitId =
              item.constructionManagementUnitId
          }
        })
      }
    },
    handleAdd () {
      this.resetModel()
      this.title = "新增"
      this.handleEdit()
      this.progressNum = 0
    },
    handleUpate (data) {
      this.resetModel()
      this.title = "编辑"
      this.model = Object.assign({}, data)
      this.fileList.push({
        name: data.fileName,
        url: data.planFile,
      })
      this.handleEdit()
    },
    handleEdit () {
      this.$refs.formModal.handleOpen()
    },
    handleSubmit () {
      console.log(this.model, 'this.model ')
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.loading = true
          // this.model.belonging = 1;
          this.options.forEach((item) => {
            if (item.id == this.model.planTypeId) {
              this.model.planType = item.label
            }
          })
          const params = JSON.parse(JSON.stringify(this.model))

          params.belonging = this.dept
          if (this.title == "新增") {
            addEmergencyData(params)
              .then((res) => {
                this.loading = false
                if (res.code == 0) {
                  // this.importcard();
                  this.$message.success("操作成功")
                  this.$emit("formSubmit")
                  this.$refs.formModal.handleClose()
                  this.resetModel()
                } else {
                  this.$message.error("操作失败" + res.message)
                }
              })
              .catch((err) => {
                // this.$message.error("操作失败")
                this.loading = false
                this.$message.error("操作失败" + err.message)
              })
          } else {
            UpdateEmergencyData(params)
              .then((res) => {
                this.loading = false
                if (res.code == 0) {
                  // this.importcard();
                  this.$message.success("操作成功")
                  this.$emit("formSubmit")
                  this.$refs.formModal.handleClose()
                  this.resetModel()
                } else {
                  this.$message.error("操作失败" + res.message)
                }
              })
              .catch((err) => {
                // this.$message.error("操作失败")
                this.loading = false
                this.$message.error("操作失败" + err.message)
              })
          }
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },
    resetModel () {
      this.model = {
        lineSite: [],
        superviseName: "",
        lineName: "",
        branchName: "",
        planName: "",
        planTypeId: "",
        // user: '',
      }
      this.fileList = []
      this.fileListsp = []
    },
  },
};
</script>

<style lang="scss" scoped></style>
