<template>
  <div>
    <div class="content mb-4" v-for="item in logList" :key="item.title">
      <div style="font-weight: bold; margin-bottom: 10px">
        <span>
          {{ item.operatorTime }}
        </span>
      </div>
      <div>
        <span>【{{ item.operatorPost }}-{{ item.operatorName }}】 </span
        ><span>{{ item.actionType }}{{ item.operationValue }}</span>
      </div>
      <div v-if="item.questionLog.question">
        <div>问题:{{ item.questionLog.question }}</div>
        <div
          class="demo-image__preview"
          v-if="item.questionLog.uri"
          style="display:flex"
        >
          <el-image
            style="width: 100px; height: 100px; margin:0 0 10px 5px"
            :src="item.questionLog.uri"
          >
          </el-image>
        </div>
      </div>
      <div v-if="item.questionLog.describe">
        <div>整改描述:{{ item.questionLog.describe }}</div>
        <div
          class="demo-image__preview"
          v-if="item.questionLog.uri"
          style="display:flex"
        >
          <el-image
            style="width: 100px; height: 100px; margin:0 0 10px 5px"
            :src="item.questionLog.uri"
          >
          </el-image>
        </div>
      </div>
      <div v-if="item.questionLog.approval">
        <div>审批意见:{{ item.questionLog.approval }}</div>
      </div>
    </div>
    <div style="display: flex; justify-content: center">
      <el-button type="primary" @click="sureSub">关闭</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    sureSub() {
      this.$emit("historyClose");
    },
  },
};
</script>

<style lang="scss" scoped></style>
