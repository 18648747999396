import { download } from "@/api/riskManage";
export default function downLoadFile(url, name) {
    let list = [
        { name: "doc", value: "application/msword" },
        {
            name: "docx",
            value:
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        },
        { name: "xls", value: "application/vnd.ms-excel" },
        {
            name: "xlsx",
            value:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        { name: "ppt", value: "application/vnd.ms-powerpoint" },
        {
            name: "pptx",
            value:
                "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        },
        { name: "pdf", value: "application/pdf;chartset=UTF-8" }
    ];
    let fileType = url.split(".")[1];
    let type = null;
    list.forEach(el => {
        if (el.name == fileType) {
            type = el.value;
        }
    });
    download(url).then(res => {
        const aLink = document.createElement("a");
        let blob = null;
        if (fileType == "txt") {
            blob = new Blob([res]);
        } else if (
            fileType == "png" ||
            fileType == "jpg" ||
            fileType == "jpeg"
          ) {
            blob = new Blob([res]);
          }  else {
            blob = new Blob([res], { type: type });
        }
        aLink.href = URL.createObjectURL(blob);
        aLink.download = name + "." + fileType;
        aLink.click();
        document.body.appendChild(aLink);
    });
}