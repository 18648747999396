<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="60%"
    :before-close="handleClose"
    :modal="true"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      class="demo-model"
    >
      <el-form-item label="" prop="" class="first">
        按照建设公司统一部署要求，第二建管部组织积极开展三防工作，将现工作落实情况和下一步工作计划报告<br />如下：
      </el-form-item>
      <el-form-item label="" prop="">
        一、 工程概况
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input type="textarea" v-model="model.projectBaseFact"></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        二、 三防通知要求制度宣贯传达落实情况
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input
          type="textarea"
          v-model="model.defencePropagationImple"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <div v-for="(item, index) in fileList" :key="index" class="url-list">
          <el-image :src="item"> </el-image>
        </div>
      </el-form-item>
      <el-form-item label="" prop="">
        三、 三防风险隐患整改落实情况
      </el-form-item>
      <el-form-item
        label=""
        prop=""
        style="font-weight: 400; text-align: center;"
      >
        <el-table
          :data="model.defenceProblemRectifySituationDtos"
          style="width: 100%"
          class="tablescoll"
          border
        >
          <el-table-column prop="lineName" label="线路-工点">
            <template slot-scope="scope">
              {{ scope.row.lineName }}-{{ scope.row.siteName }}
            </template>
          </el-table-column>
          <el-table-column prop="constructUnit" align="center" label="施工单位">
          </el-table-column>
          <el-table-column
            prop="supervisionUnit"
            align="center"
            label="监理单位"
          >
          </el-table-column>
          <el-table-column prop="checkTime" align="center" label="检查时间">
          </el-table-column>
          <el-table-column
            prop="checkStaffName"
            align="center"
            label="检查人员"
          >
          </el-table-column>
          <el-table-column
            prop="hiddenDangerType"
            align="center"
            label="隐患分类"
          >
          </el-table-column>
          <el-table-column
            prop="hiddenDangerQuestion"
            align="center"
            label="存在问题"
          >
          </el-table-column>
          <el-table-column
            prop="hiddenDangerDo"
            align="center"
            label="整改措施"
          >
          </el-table-column>
          <el-table-column
            prop="correctStaffName"
            align="center"
            label="整改负责人"
          >
          </el-table-column>
          <el-table-column
            prop="correctImageBefore"
            align="center"
            label="整改前照片"
          >
            <template slot-scope="scope">
              <img
                class="img-class"
                :src="scope.row.correctImageBefore"
                :alt="scope.row.correctImageBefore"
              />
            </template>
          </el-table-column>
          <el-table-column prop="correctState" align="center" label="整改情况">
          </el-table-column>
          <el-table-column
            prop="correctImageAfter"
            align="center"
            label="整改后照片"
          >
            <template slot-scope="scope">
              <img
                class="img-class"
                :src="scope.row.correctImageAfter"
                :alt="scope.row.correctImageAfter"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="correctOverTime"
            align="center"
            label="整改完成时间"
          >
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="" prop="">
        四、 本月上级单位/领导检查情况
      </el-form-item>
      <el-form-item label="" prop="">
        <el-table :data="monthData" style="width: 100%" border>
          <el-table-column
            prop="leaderInspectTimes"
            label="检查次数"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="leaderInspectPeopleNum"
            label="检查人次"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="leaderExposedProblemNum"
            label="发现问题数量"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="leaderRectifyProblemNum"
            label="整改问题数量"
            align="center"
          >
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="" prop="">
        五、 本月值班情况
      </el-form-item>
      <el-form-item label="" prop="">
        <duty-status :calendarData="calendarData"></duty-status>
      </el-form-item>
      <el-form-item label="" prop="">
        六、 三防检查工作开展情况
      </el-form-item>
      <el-form-item label="" prop="">
        <el-table :data="workData" style="width: 100%" border>
          <el-table-column
            prop="defenceInspectTimes"
            label="三防检查次数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="defenceInspectPeopleNum"
            label="参与检查人数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="defenceExposedProblemNum"
            label="发现问题数量"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="defenceRectifyProblemNum"
            label="整改问题数量"
            align="center"
          >
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="" prop="">
        七、 三防演练开展情况
      </el-form-item>
      <el-form-item label="" prop="" style="text-align: center;">
        <el-table :data="model.defencePlanReports" style="width: 100%" border>
          <el-table-column prop="exerciseTime" label="演练时间" align="center">
          </el-table-column>
          <el-table-column prop="exerciseTheme" label="演练主题" align="center">
          </el-table-column>
          <el-table-column
            prop="exerciseLocation"
            label="演练地点"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="exercisePeopleNum"
            label="参与人数"
            align="center"
          >
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input
          type="textarea"
          v-model="model.exerciseDeri"
          placeholder="具体演练情况"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        八、 本月三防突发事件情况
      </el-form-item>
      <el-form-item label="" prop="">
        <el-radio-group v-model="model.emergencySymbol" disabled>
          <el-radio :label="0">本月未发生三防突发事件</el-radio>
          <el-radio :label="1">填写三防突发事件情况</el-radio>
        </el-radio-group>

        <el-input
          type="textarea"
          v-model="model.defenceEmergencySituation"
          v-if="model.emergencySymbol == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        九、 下一步工作计划
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input type="textarea" v-model="model.nextWorkPlan"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { queryDutyMonth } from "@/api/eres";
import dayjs from "dayjs";
// 本月值班情况
import DutyStatus from "./DutyStatus.vue";

export default {
  components: {
    DutyStatus,
  },
  data() {
    return {
      title: "详情",
      model: {
        projectBaseFact: "",
        defencePropagationImple: "",
        defenceProblemRectifySituationDtos: [],
        defencePlanReports: [],
        leaderExposedProblemNum: "",
        leaderRectifyProblemNum: "",
        defenceExposedProblemNum: "",
        defenceRectifyProblemNum: "",
        leaderInspectTimes: "",
        leaderInspectPeopleNum: "",
        defenceInspectTimes: "",
        defenceInspectPeopleNum: "",
        exerciseDeri: "",
        emergencySymbol: 0,
        defenceEmergencySituation: "",
        nextWorkPlan: "",
        pics: [],
        isSave: 0,
      },
      loading: false,
      rules: {},
      monthData: [
        {
          leaderInspectTimes: 0,
          leaderInspectPeopleNum: 0,
          leaderExposedProblemNum: 0,
          leaderRectifyProblemNum: 0,
        },
      ],
      workData: [
        {
          defenceInspectTimes: 0,
          defenceInspectPeopleNum: 0,
          defenceExposedProblemNum: 0,
          defenceRectifyProblemNum: 0,
        },
      ],
      calendarData: [],
      dialogVisible: false,
      fileList: [],
    };
  },
  mounted() {},
  methods: {
    // 查询月份值班表
    queryDutyDataByMonth() {
      this.loading = true;
      const params = {
        lineId: this.model.lineId,
        siteId: this.model.siteId,
        emergency: false,
        dutyMonth: dayjs().format("YYYY-MM"),
      };
      queryDutyMonth(params).then((res) => {
        if (res.code == 0) {
          res.data.map((item) => {
            item.dutyTime = item.dutyTime.split(" ")[0];
          });
          this.calendarData = res.data;
          this.loading = false;
        }
      });
    },

    //填写月报
    handleDetail(record) {
      this.title = "月报";
      this.handleEdit();
      this.model = Object.assign({}, record);
      console.log(this.model, " this.model this.model");
      this.queryDutyDataByMonth();
      this.fileList = this.model.pics;
      console.log(this.model, "model");
      this.monthData[0].leaderInspectTimes = this.model.leaderInspectTimes;
      this.monthData[0].leaderInspectPeopleNum = this.model.leaderInspectPeopleNum;
      this.monthData[0].leaderExposedProblemNum = this.model.leaderExposedProblemNum;
      this.monthData[0].leaderRectifyProblemNum = this.model.leaderRectifyProblemNum;
      this.workData[0].defenceInspectTimes = this.model.defenceInspectTimes;
      this.workData[0].defenceInspectPeopleNum = this.model.defenceInspectPeopleNum;
      this.workData[0].defenceExposedProblemNum = this.model.defenceExposedProblemNum;
      this.workData[0].defenceRectifyProblemNum = this.model.defenceRectifyProblemNum;
    },
    handleEdit() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.first {
  margin-bottom: 0 !important;
  ::v-deep .el-form-item__content {
    font-weight: 550;
  }
}
::v-deep .el-form-item__content {
  font-weight: 700;
}
::v-deep .el-image {
  height: 80px;
  margin-right: 10px;
}
.i {
  position: absolute;
  top: 1px;
  right: 10px;
  color: red;
  font-weight: 500;
}
.url-list {
  position: relative;
  display: inline-block;
}
::v-deep .el-table {
  font-size: 14px !important;
}

.img-class {
  max-height: 80px;
}
</style>
