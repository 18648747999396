<template>
  <div class="riskDialog">
    <div class="main">
      <div class="riskDialogTitle" v-if="ViewRiskType != 'process' && deptType === 'monitor' ">
        风险进展
      </div>
      <el-form v-if="deptType === 'monitor' || ViewRiskType === 'process' " :rules="rules" :model="dataForm">
        <!-- s -->
        <el-form-item
          :label-width="ViewRiskType == 'process' ? '90px' : '135px'"
          prop="progressDescribe"
          :label="ViewRiskType == 'process' ? '进展描述' : '风险进展文字说明'"
          class="textareaItem"
        >
          <el-input
            v-model="dataForm.progressDescribe"
            type="textarea"
            resize="none"
            placeholder="请输入"
            auto-complete="off"
            :autosize="{ minRows: 2, maxRows: 4 }"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label-width="ViewRiskType == 'process' ? '100px' : '130px'"
          prop="photoList"
          label="形象进展图"
          class="textareaItemSpec"
        >
          <image-uploader
            v-model="dataForm.riskWarnPicList"
            v-if="ViewRiskType != 'process' "
            @uploadChange="urlChange"
          ></image-uploader>
          <el-image
              style="width: 100px; height: 100px"
              v-else
              v-for="(item,index) in progressPicList"
              :key="index"
              :preview-src-list="progressPicList"
              :src="item"></el-image>
          <div class="spec-button" v-if="ViewRiskType === 'riskLibrary' ">
            <el-button type="primary" size="small" @click="progressHistory">历史记录</el-button>
          </div>

        </el-form-item>

      </el-form>
      <div v-if="ViewRiskType != 'process'">
        <div class="cuoshi" v-for="(item, index) in measuresList" :key="index">
          <div class="riskDialogTitle cuoshiTitle">
            <p class="cuoshiTitle_left">{{ item.measureTypeName }}</p>
            <div v-if="index == 0 && ViewRiskType !== 'riskLibrary'" class="cuoshiTitle_right">无问题</div>
            <div v-if="index == 0 &&ViewRiskType !== 'riskLibrary' " class="cuoshiTitle_right">问题上报</div>
          </div>
          <div v-for="(t, i) in item.subDataList" :key="t.id">
            <p> {{ t.subTypeName }} </p>
            <div class="cuoshi_top">
              <div class="coushi_box" v-for="(lastItem,lastIndex) in t.riskMeasureList" :key="lastIndex">
              <span class="cuoshi_top_left">{{ lastItem.measureContent }}</span>
                <el-radio-group
                    v-model="lastItem.isQuestion"
                    size="small"
                    @change="radioChange(lastItem, lastIndex, index, 'guanli' , item.measureTypeName)"
                    v-if="ViewRiskType !== 'riskLibrary' "
                >
                  <el-radio label="1"></el-radio>
                  <el-radio label="2"></el-radio>
                </el-radio-group>

                <el-button type="primary" v-else size="small" @click="measuresHistory(lastItem)">历史记录</el-button>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="dialogFooter" v-if="ViewRiskType != 'process' && isButton === true ">
      <el-button size="medium" @click="cancel">取消排查</el-button>
      <el-button size="medium" type="primary" @click="submit"
        >确定排查</el-button
      >
    </div>

    <historyRisk v-if="dialogHistory" :risk-deaf-uuid="riskDeafUuid" :dialog-history="dialogHistory"
    @closedialogHistory="closedialogHistory"></historyRisk>

    <el-dialog
        width="70%"
        title="风险巡查历史记录"
        class="editDialog details-dialog"
        :visible.sync="dialogVisible"
        v-if="dialogVisible"
        center
        top="10vh"
        :close-on-click-modal="false"
        append-to-body
    >
      <historyMeasures v-if="dialogVisible" :measures-info="measuresInfo"></historyMeasures>
    </el-dialog>

  </div>
</template>

<script>
import imageUploader from "../../../components/imageUploader.vue";
import historyRisk from "@/views/RiskManagement/System/RiskProcess/components/historyRisk";
import historyMeasures from "@/views/RiskManagement/System/RiskProcess/components/historyMeasures";
import {queryDeptDetail} from "@/api/stand";
import {findByProgressUuid, findByUuid, findRiskMeasure, historyPatrolAdd} from "@/api/riskManage";
import {getMeasuresList} from "@/api/risk";
export default {
  components: {
    imageUploader,
    historyRisk,
    historyMeasures
  },
  props: {
    xcType: {
      type: Boolean,
      default: false
    },
    frontUuid: {
      type: String,
      default: ""
    },
    isChange: {
      type: String,
      default: ""
    },
    rowInfo: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {}
    },
    ViewRiskType: {
      type: String,
      default: ""
    },
    riskDeafUuid : {
      default : ''
    },
    isButton : {
      default : true
    }
  },
  data() {
    return {
      radio3: "1",
      cuoshiList: [
        {
          id: 1,
          name: "设计措施",
          guanli: [
            {
              id: 1,
              content:
                "这里是措施内容，进行测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试进行测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试",
              state: "1"
            },
            { id: 2, content: "这里是措施内容，进行测试", state: "1" },
            { id: 3, content: "这里是措施内容，进行测试", state: "1" }
          ],
          jishu: [
            { id: 1, content: "这里是措施内容，进行测试", state: "1" },
            { id: 2, content: "这里是措施内容，进行测试", state: "1" },
            { id: 3, content: "这里是措施内容，进行测试", state: "1" }
          ]
        },
        {
          id: 2,
          name: "设计措施",
          guanli: [
            { id: 1, content: "这里是措施内容，进行测试", state: "1" },
            { id: 2, content: "这里是措施内容，进行测试", state: "1" },
            { id: 3, content: "这里是措施内容，进行测试", state: "1" }
          ],
          jishu: [
            { id: 1, content: "这里是措施内容，进行测试", state: "1" },
            { id: 2, content: "这里是措施内容，进行测试", state: "1" },
            { id: 3, content: "这里是措施内容，进行测试", state: "1" }
          ]
        },
        {
          id: 5,
          name: "勘察措施",
          guanli: [
            { id: 1, content: "这里是措施内容，进行测试", state: "1" },
            { id: 2, content: "这里是措施内容，进行测试", state: "1" },
            { id: 3, content: "这里是措施内容，进行测试", state: "1" }
          ],
          jishu: [
            { id: 1, content: "这里是措施内容，进行测试", state: "1" },
            { id: 2, content: "这里是措施内容，进行测试", state: "1" },
            { id: 3, content: "这里是措施内容，进行测试", state: "1" }
          ]
        }
      ],
      measuresList : [],
      dataForm: {
        progressDescribe: "",
        riskWarnPicList: []
      },
      photoList: [],
      deptType : '',
      ruleForm : {},
      userInfo : JSON.parse(localStorage.getItem('user')),
      progressPicList : [],
      rules : {
        progressDescribe : [
          { required: true, message: '风险进展文字说明必填', trigger: 'blur' }
        ],
        photoList : [
          { required: true, message: '形象进展图最少上传一个', trigger: 'change' }
        ]
      },
      dialogHistory : false,
      dialogVisible : false,
      measuresInfo : {}
    };
  },
  watch: {
    xcType() {
      console.log(this.xcType);
      if(this.xcType === false){
        this.measuresList.forEach(item => {
          item.subDataList.forEach(sonItem => {
            sonItem.riskMeasureList.forEach(lastItem => {
              this.$set(lastItem,'isQuestion','1');
            })
          })
        })
      }
    },
    isChange : {
      handler(oldValue,newValue){
        if(newValue !== oldValue){
          this.measuresList.forEach(item => {
            item.subDataList.forEach(sonItem => {
              sonItem.riskMeasureList.forEach(lastItem => {
                this.$set(lastItem,'isQuestion','1');
              })
            })
          })
        }
      }
    }
  },
  mounted() {
    if (this.rowInfo) {
      let obj = this.rowInfo;
      console.log(obj, "sdd");
      // console.log(this.xcType, "xcType");
      if (this.xcType == false) {
        if (obj.type == "guanli") {
          console.log(obj, "ddd");
          console.log(this.cuoshiList[obj.parentIndex]);
          this.cuoshiList[obj.parentIndex].guanli[obj.rowIndex].state = "1";
        }
      }
    }
    this.getDeptDetail();
    if(this.ViewRiskType === 'process'){
      this.getProgressData();
    }else{
      this.getDetailData();
      this.getFormData();
    }
  },
  methods: {
    progressHistory(){
      this.dialogHistory = true;
    },
    measuresHistory(item){
      console.log(item);
      getMeasuresList(item.id).then(res => {
        console.log(res);
        this.measuresInfo = res.data;
        this.dialogVisible = true;
      })
    },
    closedialogHistory(){
      this.dialogHistory = false;
    },
    cancel() {
      // ff
      console.log("ffffffffffffffffffffff");
      this.$emit("closeProblemDialog");
    },
    getProgressData(){
      findByProgressUuid(this.rowInfo.progressUuid).then(res => {
        this.dataForm = res.data;
        this.progressPicList = res.data.progressPicUrlList;
      })
    },
    getFormData(){
      findByUuid(this.riskDeafUuid).then(res => {
        if(res.code == 0){
          this.ruleForm = res.data;
        }
      })
    },
    //查询属于哪个角色
    getDeptDetail() {
      console.log(JSON.parse(localStorage.getItem("user")));
      var userInfo = JSON.parse(localStorage.getItem("user"));
      queryDeptDetail(userInfo.companyId).then((res) => {
        console.log(res);
        this.deptType = res.data ? res.data.category : "0";
      });
    },
    getDetailData(){
      findRiskMeasure(this.riskDeafUuid).then(res => {
        this.measuresList = res.data;
        console.log(this.measuresList);
        this.measuresList.forEach(item => {
          item.subDataList.forEach(sonItem => {
            sonItem.riskMeasureList.forEach(lastItem => {
              this.$set(lastItem,'isQuestion','1');
            })
          })
        })
        // this.$forceUpdate();
      })
    },
    submit() {
      // dd
      // this.$emit("closeProblemDialog");
      if(this.deptType === 'monitor'){
        if(!this.dataForm.progressDescribe){
          this.$message.error('风险进展描述不能为空');
          return
        }
        if(this.photoList.length === 0){
          this.$message.error('形象进展图最少上传一个');
          return
        }
      }
      let params = {
        companyName : this.userInfo.companyName,
        companyTypeCode : this.userInfo.companyCategory,
        companyTypeName : this.userInfo.companyCategoryName,
        companyUuid : this.userInfo.companyId,
        departmentUuid : this.userInfo.departmentId,
        progressDescribe : this.dataForm.progressDescribe,
        progressPicUrlList : this.photoList,
        riskDeafUuid : this.ruleForm.riskDeafUuid,
        riskName : this.ruleForm.riskName,
        submitPersonName : this.ruleForm.patrolsPerson,
        submitPersonUuid : this.userInfo.uuid,
        progressTitle : '',
        progressUuid : this.frontUuid,
        riskMasterUuid : this.rowInfo.riskMasterUuid
      }
      console.log(params)
      historyPatrolAdd(params).then(res => {
        if(res.code == 0){
          this.$message.success('操作成功');
          this.$emit("closeProblemDialog");
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    urlChange(urlList) {
      this.photoList = [];
      for (let item of urlList) {
        this.photoList.push(item.url);
      }
    },
    radioChange(val, i, index, type,measureTypeName) {
      console.log(val,type)
      this.ruleForm.progressPicUrlList = this.photoList;
      this.ruleForm.progressDescribe = this.dataForm.progressDescribe;
      this.ruleForm.measureTypeName = measureTypeName;
      if(val.isQuestion == 2){
        let params = {
          row: val,
          rowIndex: i,
          parentIndex: index,
          type: type,
          ruleFrom : this.ruleForm,
          rowMainInfo : this.rowInfo
        };
        // dd
        this.$emit("showProblemDialog", params);
        console.log(val.state, "radioBtn");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  min-height: 253px;
}
.dialogFooter {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .el-button {
    margin: 0 30px;
  }
}
.riskDialogTitle {
  margin-top: 20px;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
  display: flex;
  // justify-content: space-between;
}
.riskDialogTitle:first-child {
  margin-top: 0;
}
.cuoshiTitle {
  display: flex;
  padding-right: 12px;
  box-sizing: border-box;
  margin-top: 20px !important;
  .cuoshiTitle_left {
    flex: 1;
  }
  .cuoshiTitle_right {
    width: 70px;
    text-align: center;
  }
}
.cuoshi {
  .cuoshi_top {
    display: flex;
    padding: 3px 12px;
    box-sizing: border-box;
    flex-direction: column;
    > div {
      display: flex;
      justify-content: space-around;
      align-items: center;
      // flex-direction: column;
    }
    .coushi_box {
      // display: flex;
      width: 100%;
    }
    .cuoshi_top_left {
      flex: 1;
      margin: 3px 0;
    }
    .cuoshi_top_right {
      width: 70px;
    }
  }
}
::v-deep .el-radio__label {
  display: none;
}
::v-deep .el-radio {
  width: 70px;
  margin: 0;
  text-align: center;
}
.textareaItemSpec{
  ::v-deep .el-form-item__content{
    width: calc(100% - 130px);
    display: flex;
    align-items: center;
    //justify-content: space-between;
  }
  .spec-button{
    margin-left: 76%;
  }
}
</style>
