import { httpInit } from "./http";
const http = httpInit("/gzdt-hdanger");

//分页查询
export const libraryList = (params) => http.post(`/hd_item/page`, params);
//新增
export const libraryAdd = (params) => http.post(`/hd_item/add`, params);
//编辑
export const libraryEdit = (params) => http.post(`/hd_item/edit`, params);

//逻辑删除
export const libraryDelete = (id) => http.delete(`/hd_item/${id}`);

// 隐患类别分页查询
export const categoryPage = (params) => http.post(`/hd_category/page`, params);

// 隐患类别新增
export const categoryAdd = (params) => http.post(`/hd_category/add`, params);

// 隐患类别编辑
export const categoryEdit = (params) => http.post(`/hd_category/edit`, params);

// 隐患类别查看详情
export const categoryView = (params) =>
  http.get(`/hd_category/info/${params.id}`);

// 隐患类别删除
export const categoryDel = (id) => http.delete(`/hd_category/removeById/${id}`);

//隐患响应列表
export const responsePage = (params) => http.post(`/hd_response/page`, params);

// 隐患响应新增
export const responseAdd = (params) => http.post(`/hd_response/add`, params);

// 隐患响应编辑
export const responseEdit = (params) => http.post(`/hd_response/edit`, params);

// 隐患响应删除
export const responseDel = (id) => http.delete(`/hd_response/removeById/${id}`);

//隐患治理流程分页列表
export const managePage = (params) => http.post(`/hd_manage/page`, params);

// 隐患治理流程新增
export const manageAdd = (params) => http.post(`/hd_manage/add`, params);

//隐患治理流程编辑
export const manageEdit = (params) => http.post(`/hd_manage/edit`, params);

// 获取流程节点列表
export const getManageNode = (params) => http.get(`/hd_manage_node/list?hiddenLevel=${params.hiddenLevel}`);

// 通过id查询获取流转节点和驳回节点
export const getmanageNodeInfo = (id) => http.get(`/hd_manage_node/info/${id}`);

// 获取单位角色列表
export const getUnitRoles = () => http.get(`/hd_manage/getUnitRoles`);

// 获取详情
export const manageView = (params) => http.get(`/hd_manage/info/${params.id}`);

// 隐患治理流程删除
export const manageDel = (id) => http.delete(`/hd_manage/${id}`);

// 导出预案管理
export const libraryExport = (params) =>
  http.get(`/hd_item/export?ids=` + params, {
    //重要  没有她导出的文件会打不开
    responseType: "blob",
  });
// 导出预案管理
export const subCategoryList = (id) =>
  http.get(`hd_category/subCategoryList/${id}`);

// 详情
export const libraryDetail = (id) => http.get(`/hd_item/${id}`);

export const libraryUpload = (file) => {
  let form = new FormData();
  form.append("file", file);
  return http.post(`/hd_item/import`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

//应急处置卡的模板下载
export const libraryImport = () =>
  http.get(`/hd/excle/item/export`, {
    //重要  没有她导出的文件会打不开
    responseType: "blob",
  });

//考核标准
//分页查询
export const criteriaList = (params) => http.post(`/hd_assess/page`, params);

//新增
export const criteriaAdd = (params) => http.post(`/hd_assess/add`, params);
//编辑
export const criteriaEdit = (params) => http.post(`/hd_assess/edit`, params);

// 详情
export const criteriaDetail = (id) => http.get(`/hd_assess/${id}`);

// 隐患类别删除
export const criteriaDelete = (id) =>
  http.delete(`/hd_assess/${id}`);

// 排查频次
//分页查询
export const screeningList = (params) =>
  http.post(`/hd_screening/page`, params);

//新增
export const screeningAdd = (params) => http.post(`/hd_screening/add`, params);
//编辑
export const screeningEdit = (params) =>
  http.post(`/hd_screening/edit`, params);

// 详情
export const screeningDetail = (id) => http.get(`/hd_screening/${id}`);

// 隐患类别删除
export const screeningDelete = (id) => http.delete(`/hd_screening/${id}`);




//日常排查接口

//日常排查任务列表
export const dayTroubleList = params => http.post(`/hd_screening_task_daily/list`, params);

//开始排查添加记录接口
export const dayTroubleSaveRecord = params => http.post(`/hd_screening_task_daily/saveRecord`, params);

//日常排查任务记录列表
export const recordList = params => http.post(`/hd_screening_task_daily/recordList`, params);

//日常排查任务记录列表
export const dayTroubleReport = params => http.post(`/hd_screening_task_daily/reported`, params);

// 详情
export const recordDetails = (params) => http.get(`/hd_screening_task_daily/recordDetails?id=` + params.id +
    '&screeningType='+ params.screeningType );

// 详情
export const recordIteamDetails = (id) => http.get(`/hd_screening_task_daily/recordIteamDetails?id=` + id);



// 详情
export const getNodeRole = (params) => http.get(`/hd_manage/getNodeRole?hiddenLevel=` + params.hiddenLevel
 + '&thisnode=' + params.thisnode + '&unitType=' + params.unitType);

//专项排查

export const specTroubleList = params => http.post(`/hd_screening_task/list`, params);

// 下发任务单位类型验证
export const checkUnitType = params => http.post(`/hd_unit_type_config/checkUnitType`, params);

// 根据选择的工点信息获取授权单位
export const getUnitsBySiteIds = params => http.post(`/hd_unit_type_config/getUnitsBySiteIds`, params);

// 根据选择的工点信息获取授权单位
export const specTroubleAdd = params => http.post(`/hd_screening_task/add`, params);

// 根据选择的工点信息获取授权单位
export const taskRecordList = params => http.post(`/hd_screening_task_record/page`, params);

// 专项排查获取taskId
export const taskRecordAdd = params => http.post(`/hd_screening_task_record/add`, params);

// 响应详情列表
export const detailsList = (taskItemId) => http.get(`/hd_task_item_reponse/detailsList/${taskItemId}`);

//隐患治理-已响应-分页
export const hasResponseList = params => http.post(`/hd_task_item_reponse/hasResponseList`, params);

//隐患治理-未响应-不分页
export const reponseList = params => http.post(`/hd_task_item_reponse/list`, params);

//隐患治理-未响应-响应按钮保存
export const responseSave = params => http.post(`/hd_task_item_reponse/response`, params);

//隐患治理-未响应-查看
export const checkReponse = (id) => http.get(`/hd_task_item_reponse/details/${id}`)

//隐患治理-未整改-分页
export const taskItemList = params => http.post(`/hd_task_item/taskItemList`, params);

//隐患治理-已整改-分页
export const taskItemListPage = (params) => http.post(`/hd_task_item/taskItemListPage`,params);

//隐患治理-整改
export const checkOrReformOrRemove = (params) => http.post(`/hd_task_operate_record/checkOrReformOrRemove`,params);

//隐患整改/消除-查看
export const getOperateRecords = (params) => http.post(`/hd_task_operate_record/getOperateRecords`,params);

//隐患详情
export const getDetails = (params) =>
    http.get(`/hd_task_item/info?id=` + params.id + '&screeningType=' + params.screeningType);

//大屏隐患任务
export const getHiddenTask = (params) => http.post(`/hidden_task_standing/hiddenTask`,params);

//大屏隐患任务-完成任务
export const getHiddenTaskList = (params) => http.post(`/hidden_task_standing/hiddenTaskList`,params);

//大屏隐患任务-超时完成
export const getOverList = (params) => http.post(`/hidden_task_standing/overTimeHiddenTaskList`,params);

//大屏隐患任务-隐患上报概况
export const getReportedGeneral = (params) => http.post(`/hidden_standing/reportedGeneral`,params);

//大屏隐患任务-隐患上报概况列表
export const getDistGeneralList = (params) => http.post(`/hidden_standing/distGeneralList`,params);

//大屏隐患任务-隐患等级分布
export const getLevelDist = (params) => http.post(`/hidden_standing/levelDist`,params);

//大屏隐患任务-左侧-风险等级
export const getRiskCount = () => http.get(`/riskCount/getRiskLevelAndStatusCount`);

//大屏隐患任务-左侧-风险等级弹框
export const getRiskCountPage = (params) => http.post(`/riskCount/getRiskLevelAndStatusPage`,params);

//大屏隐患任务-左中-风险类别统计
export const getRiskTypeCount = () => http.get(`/riskCount/getRiskTypeCount`);

//大屏隐患任务-左中-风险类别统计弹框
export const getRiskTypePage = (params) => http.post(`/riskCount/getRiskTypePage`,params);

//大屏隐患任务-左下-风险管理统计
export const getRiskManageCount = () => http.get(`/riskCount/getRiskManageCount`);

//大屏隐患任务-下左-危大工程统计
export const getDangerous = () => http.get(`/riskCount/getDangerousProjectCount`);

//大屏隐患任务-下左-危大工程统计弹窗
export const getDangerousProjectPage = (params) => http.post(`/riskCount/getDangerousProjectPage`,params);

//大屏隐患任务-下右-危大工程统计
export const getSubWayNodeCount = () => http.get(`/riskCount/getSubWayNodeCount`);

//大屏隐患任务-下右-危大工程统计弹窗
export const getSubWayNodePage = (params) => http.post(`/riskCount/getSubWayNodePage`,params);

//建议通过-通知待办
export const libraryPass = (id) => http.get(`/hd_item/pass/${id}`);

//建议驳回-通知待办
export const libraryReject = (id) => http.get(`/hd_item/reject/${id}`);

//排查任务记录详情-通知待办
export const getRecordDetailsByTaskId = (id) =>
    http.get(`/hd_screening_task_daily/getRecordDetailsByTaskId?taskId=` + id);
