import { httpInit } from "./http";
const http = httpInit("/gzdt-response");
// const https = httpInit("/gzdt-response");
// 根据ID查询应急响应记录详情
export const getResList = params => http.post(`/response/list`, params);

//应急响应 新增-修改接口
export const ResSaveOrUpdate = params => http.post(`/response/saveOrUpdate`, params);

export const ResSaveOrUpdateNew = params => http.post(`/responseNew/saveOrUpdate`, params);

//应急响应 升降级接口
export const ResRelegation = params => http.post(`/response/relegation`, params);

export const ResRelegationNew = params => http.post(`/responseNew/relegation`, params);

//应急响应结束接口
export const ResFinish = params => http.post(`/response/finish`, params);

export const ResFinishNew = params => http.post(`/responseNew/finish`, params);

//应急响应历史操作查询
export const ResLog = params => http.post(`/operatinghistory/list`, params);

//通过历史响应记录页面通过历史响应记录Id查询所有的当前响应的记录
export const ResRecord = params => http.get(`/response/current/getCurrentResponseListByResponseRecordId/${params.responseRecordId}/${params.pageNum}/${params.pageSize}`, params);

//简报填报数据回显
export const getSavedInfo = params => http.post(`/response/getSavedInfo`, params);

//新增保存三防工作简报信息

export const SavedInfo = params => http.post(`/response/save`, params);

//三防工作简报详情 /response/getInfo/{id}
export const getInfo = params => http.get(`/response/getInfo/${params.id}`, params);

///建管部查询各监理、施工检查情况信息-工点列表 
export const listSiteInfo = params => http.post(`/response/listSiteInfo`, params);

//建管部查询各监理、施工检查情况信息
export const listCheckInfo = params => http.post(`/response/listCheckInfo`, params);

//判断当前响应页面列表是否有建管部按钮权限isRotaLeaderOrSafeDepartment
export const isRotaLeaderOrSafeDepartment = params => http.get(`/response/current/isRotaLeaderOrSafeDepartment`, params);

//建管部当前响应页面列表接口（新）
export const getSupervisionCurrentResponseListNew = params => http.post(`/response/current/getSupervisionCurrentResponseListNew`, params);


//质安部查询建管部汇总信息responseId
export const listSupervisionSummaryInfo = params => http.post(`/response/listSupervisionSummaryInfo`, params);

//质安部查询建管部汇总信息responseId
export const listSupervisionSummaryInfoDB = params => http.post(`/response/listSupervisionSummaryInfoDB`, params);
//建管部查询工点汇总信息responseId
export const listSiteSummaryInfo = params => http.post(`/response/listSiteSummaryInfo`, params);
//建管部详情查询工点汇总信息supervisionName  supervisionUuid  times responseId
export const listSiteDetailSummaryInfo = params => http.post(`/response/listSiteDetailSummaryInfo`, params);

// 三防简报汇总导出 /response/export/summaryInfo
export const exportSummaryInfo = (data) => http({ url: `/response/export/summaryInfo`, data, method: "post", responseType: "blob", headers: { 'Content-Type': 'application/json;charset=utf8' } })



//代办统计列表
export const messageCountPage = params => http.post(`/responseCount/messageCountPage`, params);


//三防应急响应发布统计
export const responseCountDepartment = params => http.get(`/responseCount/responseCount/${params.dateType}`, params);


//分页查询响应记录列表
export const responseList = params => http.post(`/responseCount/responseList`, params);


//三防隐患问题排查统计
export const responseQuestionCount = params => http.get(`/responseCount/responseQuestionCount/${params.dateType}`, params);

//三防隐患问题分页列表
export const questionCountPage = params => http.post(`/responseCount/questionCountPage`, params);

//代办统计
export const selectMessageCount = params => http.post(`/responseCount/selectMessageCount`, params);

// 防汛关注重点统计 
export const proofCount = () => http.get(`/responseCount/proofCount`);

// 三防图表录入统计
export const responseChartCount = () => http.get(`/responseCount/responseChartCount`);

// 三防图表录入列表
export const responseChartPage = params => http.post(`/responseCount/responseChartPage`, params);

// 三防应急响应工作情况统计 
export const responseCurrentCount = params => http.post(`/responseCount/responseCurrentCount`, params);
