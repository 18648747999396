<template>
  <div class="briefing">
    <el-dialog
      type="text"
      :title="title"
      :visible.sync="approvalVisible"
      width="60%"
      :before-close="handleClose"
      center
      class="abow_dialog"
    >
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        v-loading="loading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          :selectable="selected"
          type="selection"
          width="55"
        ></el-table-column>
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="lineName"
          label="线路-工点"
          show-overflow-tooltip
        >
          <template slot-scope="scope"
            >{{ scope.row.lineName }}{{ scope.row.siteName }}</template
          >
        </el-table-column>
        <el-table-column align="center" prop="deadline" label="截止时间">
        </el-table-column>
        <el-table-column align="center" prop="fillTime" label="填报时间">
        </el-table-column>
        <el-table-column align="center" prop="fillUser" label="填报人员">
          <template slot-scope="scope">{{
            scope.row.fillUser ? scope.row.fillUser.name : ""
          }}</template>
        </el-table-column>
        <el-table-column align="center" prop="approvalState" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.approvalState == -2" style="color: #ccc"
              >超时</span
            >
            <span
              v-else-if="
                scope.row.approvalState == -1 || scope.row.approvalState == 2
              "
              style="color: red"
              >未填报</span
            >
            <span v-else-if="scope.row.approvalState == 0" style="color: green"
              >待审批</span
            >
            <span v-else style="color: #ccc">已完成</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleCheck(scope.row)"
              v-if="scope.row.approvalState > -1"
              >查看</el-button
            >
            <el-button
              type="text"
              @click="handleApproval(scope.row)"
              v-if="scope.row.approvalState == 0"
              >审批</el-button
            >
            <el-button type="text" @click="handleHistory(scope.row)"
              >历史操作记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <footer
        class="mt-4 flex justify-center"
        v-show="multipleSelection.length > 0"
      >
        <div>
          <el-button @click="handleBatchApproval">批量审批</el-button>
          <el-button @click="handleClose">取消</el-button>
        </div>
      </footer>
      <!-- 审核二级弹窗 -->
      <el-dialog
        width="30%"
        title="审核"
        :visible.sync="approvalFormVisible"
        :before-close="handleApprovalModalClose"
        append-to-body
      >
        <el-form
          :model="model"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          v-loading="approvalLoading"
        >
          <el-form-item label="填报单位：" v-if="!batchApproval">
            <span>{{ model.supervisionName }}</span>
          </el-form-item>
          <el-form-item label="填报内容：" v-if="!batchApproval">
            <el-button type="text" @click="handleFilePreview"
              >【三防工作简报】</el-button
            >
          </el-form-item>
          <el-form-item label="审核意见：">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              placeholder="请输入审核意见"
              v-model="model.opinion"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onSubmit(1)">通过</el-button>
          <el-button type="danger" @click="onSubmit(2)">驳回</el-button>
        </span>
      </el-dialog>
    </el-dialog>
    <DetailModal ref="detailHistoryModal"></DetailModal>
    <Briefing
      ref="fillingModal1"
      @fillingSubmit="handleFillingSubmit"
    ></Briefing>
  </div>
</template>

<script>
import {
  approvalBriefing,
  queryCurrentResponseList,
  getBriefingInfo,
} from "@/api/response";
import DetailModal from "../resources/detailModal.vue";
import Briefing from "./briefing.vue";
export default {
  components: {
    DetailModal,
    Briefing,
  },
  props: {
    approval: {
      type: Boolean,
      default: () => false,
    },
    rows: {
      type: Object,
      default: () => {},
    },
    responseRecordId: {
      type: Number,
    },
  },
  watch: {
    approval: {
      handler(val) {
        this.approvalVisible = this.approval;
        if (val) {
          this.loadData();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      title: "三防工作简报",
      approvalVisible: false,
      approvalFormVisible: false,
      batchApproval: false,
      loading: false,
      tableData: [],
      lineName: "",
      siteName: "",
      model: {
        opinion: "",
      },
      rules: {},
      approvalLoading: false,
      multipleSelection: [],
      selectData: [],
    };
  },
  methods: {
    // 加载表格数据
    async loadData() {
      this.loading = true;
      const params = {
        reportType: "140",
        status: "",
        responseRecordId: this.responseRecordId,
        isApproval: 1,
      };
      let res = await queryCurrentResponseList(params);
      if (res.code == 0) {
        this.tableData = res.data;
        // this.page.count = res.data.count;
        // this.page.pageSize = res.data.size;
        // this.page.total = res.data.total;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    formatState: function (row, column, cellValue) {
      switch (cellValue) {
        case -2:
          return "超时";
        case -1:
          return "未填报";
        case 0:
          return "待审批";
        case 1:
          return "通过";
        case 2:
          return "驳回";
      }
    },
    // 表格选中事件
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    // 查看详情按钮
    handleCheck(record) {
      console.log(record);
      this.$refs.fillingModal1.handleFilling(record, "read");
      // this.$refs.detailHistoryModal.detailHandle(record);
    },
    handleFillingSubmit() {
      this.loadData();
    },
    // 历史记录按钮
    handleHistory(record) {
      console.log(record);
      this.$refs.detailHistoryModal.historyHandle(record);
    },
    // 审核操作按钮
    handleApproval(record) {
      // 单次审核前清空
      this.selectData = [];
      this.selectData.push(record);
      this.queryBriefingInfo(record);
      this.approvalFormVisible = true;
    },
    // 批量审批
    handleBatchApproval() {
      this.batchApproval = true;
      this.approvalFormVisible = true;
      this.selectData = this.multipleSelection;
    },
    // 查询详情
    async queryBriefingInfo(record) {
      getBriefingInfo({ id: record.id }).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            let tempArr;
            if (res.data.fileUrl) {
              tempArr = res.data.fileUrl.split("/");
              const fileName = tempArr[tempArr.length - 1];
              this.$set(this.model, "fileName", fileName);
            }

            this.model = Object.assign({}, res.data);
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    onSubmit(type) {
      if (type == 2 && !this.model.opinion) {
        this.$message.error("请输入审核意见");
        return false;
      }
      const params = {
        threePreventionWorks: [],
      };
      for (let item of this.selectData) {
        params.threePreventionWorks.push({
          status: type,
          opinion: this.model.opinion,
          currentId: item.id,
        });
      }
      this.approvalBriefing(params);
    },
    // 审核请求
    approvalBriefing(params) {
      this.approvalLoading = true;
      approvalBriefing(params).then((res) => {
        if (res.code == 0) {
          this.$message.success("操作成功！");
          this.approvalLoading = false;
          if (this.batchApproval) {
            this.batchApproval = false;
          }
          this.handleApprovalModalClose();
          this.loadData();
        } else {
          this.$message.error(res.message);
          this.approvalLoading = false;
        }
      });
    },
    // 详情弹窗关闭
    handleDetailModalClose() {
      this.detailVisible = false;
    },
    // 审核弹窗关闭
    handleApprovalModalClose() {
      this.approvalFormVisible = false;
      this.selectData = [];
      this.model.opinion = "";
    },
    // 文件预览
    handleFilePreview() {
      this.$refs.filePreviewModal.handleOpen();
    },
    handleClose() {
      this.$emit("modalClose");
    },
    selected(row) {
      if (row.approvalState != 0) {
        return false; //不可勾选
      } else {
        return true; //可勾选
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.briefong_auto {
  width: 90%;
  margin: auto;

  .briefong_one {
    .typhoon {
      font-size: 14px;
      color: #000000;
      font-weight: bold;
    }

    .defense {
      font-size: 14px;
      color: #000000;
      font-weight: bold;
    }
  }
}

.el-form-item {
  margin-bottom: 8px !important;
}

.content {
  width: 100%;
  height: 300px;
  border: 2px solid #e8e8e8;
  margin-top: 8px;
  position: relative;

  .water {
    color: #62b9e5;
    position: absolute;
    top: 37%;
    left: 104%;
  }

  .influen {
    color: #62b9e5;
    position: absolute;
    top: 37%;
    left: 104%;
  }

  .reovem {
    color: #62b9e5;
    position: absolute;
    top: 37%;
    left: 104%;
  }
}

.el-table {
  font-size: 12px !important;
}

.toimg {
  display: flex;
  margin-top: 8px;
  margin-left: 4px;

  .imglist {
    width: 60px;
    height: 60px;
    margin-left: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.poert {
  position: relative;

  .el-icon-circle-plus-outline {
    color: #62b9e5;
    position: absolute;
    top: 33%;
    left: 60%;
  }

  .el-icon-delete {
    color: #62b9e5;
    position: absolute;
    top: 33%;
    left: 65%;
  }

  .supplie {
    top: 33%;
    left: 85%;
  }

  .suppiet {
    top: 33%;
    left: 90%;
  }
}

.water {
  position: relative;

  .waeeter {
    color: #62b9e5;
    position: absolute;
    top: 29%;
    left: 45%;
  }

  .suddend {
    color: #62b9e5;
    position: absolute;
    top: 29%;
    left: 50%;
  }
}

.el-radio {
  margin-right: 150px !important;
}
</style>
