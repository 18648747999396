<template>
  <div v-loading="loadingbox">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="headerTile" name="first">
        <el-form :inline="true" style="marginbottom: 20px">
          <el-form-item label="填报单位">
            <el-select v-model="company" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <div class="table-wrapper">
          <!-- 损失头部表格 -->
          <el-table
            :data="unitTableData"
            style="width: 100%; margin-bottom: 20px"
            border
            v-loading="loading"
          >
            <el-table-column
              align="center"
              prop="inspectNum"
              label="出动检查人员（人次）"
            >
              <template slot-scope="scope">
                <!-- <el-form-item> -->
                <el-input
                  v-model="scope.row.inspectNum"
                  placeholder="请输入"
                  :disabled="resumeReportingType != 'add'"
                ></el-input>
                <!-- </el-form-item> -->
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="inspectPlaceNum"
              label="检查场所（处）"
            >
              <template slot-scope="scope">
                <!-- <el-form-item> -->
                <el-input
                  v-model="scope.row.inspectPlaceNum"
                  placeholder="请输入"
                  :disabled="resumeReportingType != 'add'"
                ></el-input>
                <!-- </el-form-item> -->
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="hiddenNum"
              label="发现三防问题隐患（处）"
            >
              <template slot-scope="scope">
                <!-- <el-form-item> -->
                <el-input
                  v-model="scope.row.hiddenNum"
                  placeholder="请输入"
                  :disabled="resumeReportingType != 'add'"
                ></el-input>
                <!-- </el-form-item> -->
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="hiddenSuccessNum"
              label="已整改三防问题隐患（处）"
            >
              <template slot-scope="scope">
                <!-- <el-form-item> -->
                <el-input
                  v-model="scope.row.hiddenSuccessNum"
                  placeholder="请输入"
                  :disabled="resumeReportingType != 'add'"
                ></el-input>
                <!-- </el-form-item> -->
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="ranks"
              label="出动(集结)抢险队伍（支）"
            >
              <template slot-scope="scope">
                <!-- <el-form-item> -->
                <el-input
                  v-model="scope.row.ranks"
                  placeholder="请输入"
                  :disabled="resumeReportingType != 'add'"
                ></el-input>
                <!-- </el-form-item> -->
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="ranksNum"
              label="出动(集结)抢险队伍人员（人次）"
            >
              <template slot-scope="scope">
                <!-- <el-form-item> -->
                <el-input
                  v-model="scope.row.ranksNum"
                  placeholder="请输入"
                  :disabled="resumeReportingType != 'add'"
                ></el-input>
                <!-- </el-form-item> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="title">本单位实际受灾情况报告</div>
        <div class="table-wrapper">
          <el-form
            :model="actualTableForm"
            ref="actualTableForm"
            :rules="actualRules"
            size="small"
          >
            <el-table
              :data="actualTableForm.actualTableData"
              style="width: 100%; margin-bottom: 20px"
              border
              v-loading="loading"
            >
              <el-table-column
                align="center"
                prop="label"
                label="损失情况"
                v-if="resumeReportingType == 'add'"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="lossSituation"
                label="损失情况"
                v-else
              ></el-table-column>
              <el-table-column align="center" prop="num" label="数量">
                <template slot-scope="scope">
                  <el-form-item>
                    <el-input
                      v-model="scope.row.num"
                      placeholder="请输入"
                      :disabled="resumeReportingType != 'add'"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="rescueSituation"
                label="抢险应对措施"
              >
                <template slot-scope="scope">
                  <el-form-item>
                    <el-input
                      type="textarea"
                      resize="none"
                      :autosize="{ minRows: 1, maxRows: 5 }"
                      placeholder="请输入内容"
                      v-model="scope.row.rescueSituation"
                      :disabled="resumeReportingType != 'add'"
                    >
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="personCharge" label="结果">
                <template slot-scope="scope">
                  <el-form-item>
                    <el-input
                      type="textarea"
                      resize="none"
                      :autosize="{ minRows: 1, maxRows: 5 }"
                      placeholder="请输入内容"
                      v-model="scope.row.personCharge"
                      :disabled="resumeReportingType != 'add'"
                    >
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
            <el-form-item label="审批意见" v-if="resumeReportingType != 'add'">
              <el-input
                type="textarea"
                v-model="actualTableForm.desc"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="《建设工地灾害损失及恢复情况表》"
        name="second"
        v-if="resumeReportingType == 'add'"
      >
        <el-form :inline="true" style="marginbottom: 20px">
          <el-form-item label="填报单位">
            <el-select v-model="company" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="table-wrapper">
          <!-- 恢复头部表格 -->
          <el-table
            :data="restoreTableData"
            style="width: 100%; margin-bottom: 20px"
            border
            v-loading="loading"
          >
            <el-table-column
              align="center"
              prop="inspectNum"
              label="出动检查人员（人次）"
            >
              <template slot-scope="scope">
                <!-- <el-form-item> -->
                <el-input
                  v-model="scope.row.inspectNum"
                  placeholder="请输入"
                ></el-input>
                <!-- </el-form-item> -->
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="inspectPlaceNum"
              label="检查场所（处）"
            >
              <template slot-scope="scope">
                <!-- <el-form-item> -->
                <el-input
                  v-model="scope.row.inspectPlaceNum"
                  placeholder="请输入"
                ></el-input>
                <!-- </el-form-item> -->
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="hiddenNum"
              label="发现三防问题隐患（处）"
            >
              <template slot-scope="scope">
                <!-- <el-form-item> -->
                <el-input
                  v-model="scope.row.hiddenNum"
                  placeholder="请输入"
                ></el-input>
                <!-- </el-form-item> -->
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="hiddenSuccessNum"
              label="已整改三防问题隐患（处）"
            >
              <template slot-scope="scope">
                <!-- <el-form-item> -->
                <el-input
                  v-model="scope.row.hiddenSuccessNum"
                  placeholder="请输入"
                ></el-input>
                <!-- </el-form-item> -->
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="ranks"
              label="出动(集结)抢险队伍（支）"
            >
              <template slot-scope="scope">
                <!-- <el-form-item> -->
                <el-input
                  v-model="scope.row.ranks"
                  placeholder="请输入"
                ></el-input>
                <!-- </el-form-item> -->
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="ranksNum"
              label="出动(集结)抢险队伍人员（人次）"
            >
              <template slot-scope="scope">
                <!-- <el-form-item> -->
                <el-input
                  v-model="scope.row.ranksNum"
                  placeholder="请输入"
                ></el-input>
                <!-- </el-form-item> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-wrapper">
          <el-form
            :model="restoreTableForm"
            ref="restoreTableForm"
            :rules="restoreRules"
            size="small"
          >
            <el-table
              :data="restoreTableForm.restoreTableData"
              style="width: 100%; margin-bottom: 20px"
              border
              v-loading="loading"
            >
              <el-table-column
                align="center"
                prop="label"
                label="损失情况"
              ></el-table-column>
              <el-table-column align="center" prop="num" label="数量">
                <template slot-scope="scope">
                  <el-form-item>
                    <el-input
                      v-model="scope.row.num"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="rescueSituation"
                label="抢险应对措施"
              >
                <template slot-scope="scope">
                  <el-form-item>
                    <el-input
                      type="textarea"
                      resize="none"
                      :autosize="{ minRows: 1, maxRows: 5 }"
                      placeholder="请输入内容"
                      v-model="scope.row.rescueSituation"
                    >
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="personCharge" label="结果">
                <template slot-scope="scope">
                  <el-form-item>
                    <el-input
                      type="textarea"
                      resize="none"
                      :autosize="{ minRows: 1, maxRows: 5 }"
                      placeholder="请输入内容"
                      v-model="scope.row.personCharge"
                    >
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div slot="footer" class="dialog-footer">
      <div v-if="resumeReportingType == 'add'">
        <el-button
          v-if="activeName == 'first'"
          type="primary"
          @click="nextClick()"
          >下一步</el-button
        >
        <el-button v-else type="primary" @click="submitClick()">提交</el-button>
      </div>
      <div v-else>
        <el-button type="primary" @click="throughClck()">通过</el-button>
        <el-button type="danger" @click="rejectedClick()">驳回</el-button>
      </div>
    </div>

    <el-dialog
      title="驳回"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      append-to-body
      v-if="dialogVisible"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="驳回原因" prop="rejectInfo">
          <el-input type="textarea" v-model="ruleForm.rejectInfo"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="nacoldf">
            <el-button type="primary" @click="submitForm('ruleForm')"
              >确定</el-button
            >
            <el-button @click="handleClose()">关闭</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryDictList,
  situationReport,
  theEcho,
  throughapi,
  rejectedhapi,
  queryCurrentResponseId,
  theEchoQueryStatic
} from "@/api/dict";
export default {
  name: "disaster",
  props: {
    resumeReportingType: {
      type: String,
      default: "add",
    },
    reportId: {
      type: String,
      default: "",
    },
    headerTile: {
      type: String,
      default: "《建设工地抢险和灾害损失情况》",
    },
    // responseId: {
    //   type: Number,
    //   default: 0,
    // },
  },
  watch: {
    reportId: {
      handler(val) {
        console.log(val);
        if (val && this.resumeReportingType != "add") {
          this.modifyThe(val);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      options: [
        {
          value: "1",
          label: "第一建管部",
        },
      ],
      unitTableData: [
        {
          hiddenNum: "",
          hiddenSuccessNum: "",
          inspectNum: "",
          inspectPlaceNum: "",
          ranks: "",
          ranksNum: "",
        },
      ],
      restoreTableData: [
        {
          hiddenNum: "",
          hiddenSuccessNum: "",
          inspectNum: "",
          inspectPlaceNum: "",
          ranks: "",
          ranksNum: "",
        },
      ],
      tableData: [],
      loading: false,
      company: "1",
      activeName: "first",
      lossList: [],
      restoreList: [],
      // 损失
      actualTableForm: {
        actualTableData: [],
        desc: "",
      },
      actualRules: {},
      // 恢复
      restoreTableForm: {
        restoreTableData: [],
      },
      restoreRules: {},
      dialogVisible: false,
      ruleForm: {
        rejectInfo: "",
      },
      rules: {
        rejectInfo: [
          { required: true, message: "请填写驳回原因", trigger: "blur" },
        ],
      },
      loadingbox: false,
      responseId:'',
    };
  },
  created() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user.departmentName) {
      this.options[0].label = user.departmentName;
    }
    this.loading = true;
    if (this.resumeReportingType == "add") {
      this.addCrea();
    }
    // 获取 responseId 
    queryCurrentResponseId().then((res)=>{
      if(res.code == 0 ){
        this.responseId = res.data
      }
    })
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const obj = {
            rejectInfo: this.ruleForm.rejectInfo,
            reportId: this.reportId,
          };
          rejectedhapi(obj).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.$emit("downResume");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.ruleForm.desc = "";
    },
    // 通过
    throughClck() {
      // this.loadingbox = true;
      if (this.actualTableForm.desc) {
        const obj = {
          approvalInfo: this.actualTableForm.desc,
          reportId: this.reportId,
        };
        throughapi(obj).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
          this.$emit("downResume");
          // this.loadingbox = false;
        });
      } else {
        this.$message({
          message: "请输入审批意见",
          type: "warning",
        });
      }
    },
    // 驳回
    rejectedClick() {
      this.dialogVisible = true;
    },
    // 审批
    modifyThe(id) {
      theEcho({ reportId: id }).then((res) => {
        if (res.code == 0) {
          this.actualTableForm.actualTableData = res.data;
          this.loading = false;
        }
      });
      theEchoQueryStatic({ reportId: id }).then((res) => {
        if (res.code == 0) {
          Object.assign(this.unitTableData[0],res.data)
          this.loading = false;
        }
      });
    },
    // 新增进来
    addCrea() {
      const paramsLoss = {
        label: "-",
        parent: 344,
        canModify: "-",
        page: 1,
        size: 1000,
      };
      const paramsRestore = {
        label: "-",
        parent: 345,
        canModify: "-",
        page: 1,
        size: 1000,
      };
      // 损失
      queryDictList(paramsLoss).then((res) => {
        if (res.code == 0) {
          res.data?.records.forEach((item) => {
            const obj = {
              lossSituation: "",
              num: "",
              personCharge: "",
              rescueSituation: "",
              type: 1,
            };
            Object.assign(item, obj);
          });
          this.actualTableForm.actualTableData = res.data?.records;
          this.loading = false;
        }
      });

      // 恢复
      queryDictList(paramsRestore).then((res) => {
        if (res.code == 0) {
          res.data?.records.forEach((item) => {
            const obj = {
              lossSituation: "",
              num: "",
              personCharge: "",
              rescueSituation: "",
              type: 2,
            };
            Object.assign(item, obj);
          });
          this.restoreTableForm.restoreTableData = res.data?.records;
        }
      });
    },
    nextClick() {
      this.activeName = "second";
    },
    // 提交
    submitClick() {
      // 处理提交的数据
      const Nobj = {
        situationDetailedList:[],
        situationInspectDtoList:[]
      }
      this.actualTableForm.actualTableData.forEach((item) => {
        const obj = {
          lossSituation: item.id,
          num: item.num,
          personCharge: item.personCharge,
          rescueSituation: item.rescueSituation,
          type: item.type,
          responseId: this.responseId,
        };
        Nobj.situationDetailedList.push(obj);
      });
      this.restoreTableForm.restoreTableData.forEach((item) => {
        const obj = {
          lossSituation: item.id,
          num: item.num,
          personCharge: item.personCharge,
          rescueSituation: item.rescueSituation,
          type: item.type,
          responseId: this.responseId,
        };
        Nobj.situationDetailedList.push(obj);
      });
      Nobj.situationInspectDtoList.push(this.unitTableData[0])
      Nobj.situationInspectDtoList.push(this.restoreTableData[0])
      situationReport(Nobj).then((res) => {
        if (res.code == 0) {
          this.$emit("downResume");
        }
      });
    },
    handleClick() {},
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 10px;
}
.dialog-footer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
::v-deep.el-form-item {
  margin-bottom: 0px !important;
}
.nacoldf {
  margin-top: 20px;
}
</style>
