<template>
  <div class="page-container" style="margin-top: 0px">
    <el-form
      :model="ruleForm"
      :rules="!isHaveCertificate ? {} : rules"
      ref="ruleForm"
      label-width="125px"
      class="demo-ruleForm"
      :disabled="!isHaveCertificate"
    >
      <el-row class="PostInfCs-input">
        <div class="name">
          <img src="../../../assets/userImg/xinxi.png" alt="" /> 基本信息
        </div>
        <el-divider></el-divider>
        <el-col :span="6">
          <el-form-item label="姓名" prop="personName">
            <el-input v-model="ruleForm.personName"></el-input>
          </el-form-item>
          <!--          <el-form-item label="账号" prop="name" v-if="isDalian">-->
          <!--            <el-input v-model="ruleForm.name"></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item label="紧急联系人">
            <el-input v-model="ruleForm.emergencyContact"></el-input>
          </el-form-item>

          <el-form-item label="身份证" prop="idCard">
            <el-input
              v-model="idCard"
              ref="idcardbf"
              @blur="idCardBlur"
              @focus="idCardFocus"
            >
              <i
                class="el-icon-view el-input__icon"
                slot="suffix"
                v-if="iseyes"
                @click="handleIconClick"
              >
              </i>
            </el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex" style="text-align: left">
            <el-radio-group v-model="ruleForm.sex" class="form-item">
              <el-radio label="0" style="color: #59baf2"> 男 </el-radio>
              <el-radio label="1" style="color: #59baf2"> 女 </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="年龄">
            <el-input v-model="ruleForm.age"></el-input>
          </el-form-item>
          <el-form-item label="政治面貌">
            <el-select
              v-model="ruleForm.politicalIdentity"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in identity"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="住址">
            <el-input v-model="ruleForm.address"></el-input>
          </el-form-item>
          <el-form-item label="进场时间">
            <el-date-picker
              v-model="ruleForm.entryTime"
              :disabled="arena"
              :picker-options="pickerOptions"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="联系电话" prop="mobile">
            <el-input v-model="ruleForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="紧急联系人电话">
            <el-input v-model="ruleForm.emergencyContactPhone"></el-input>
          </el-form-item>
          <el-form-item
            label="123"
            prop="aaaaa"
            style="position: absolute; z-index: -999"
          >
            <el-input v-model="aaaaa" autocomplete="new-password"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="ruleForm.password"
              ref="password"
              type="password"
              autocomplete="new-password"
            ></el-input>
          </el-form-item>

          <el-form-item label="婚姻状态">
            <el-select
              v-model="ruleForm.marriage"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in marriageList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="专业">
            <el-input v-model="ruleForm.major"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="ruleForm.email"></el-input>
          </el-form-item>
          <el-form-item label="社保号" prop="socialSecurityNumber">
            <el-input
              v-model="ruleForm.socialSecurityNumber"
              @input="sbinput"
            ></el-input>
          </el-form-item>
          <el-form-item label="出场时间" prop="email">
            <el-date-picker
              v-model="ruleForm.exitTime"
              :disabled="exarch"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              type="date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="照片" style="overflow: hidden">
<!--            <el-upload
              class="upload-demo idCardCss"
              :action="imgUrl"
              :data="imgData"
              accept=".jpg"
              :file-list="fileList"
              list-type="picture-card"
              :headers="imgHeaders"
              ref="userImg"
              :on-remove="userImgRemove"
              :on-change="setingGurl"
              :disabled="!isHaveCertificate"
              :auto-upload="false"
            >
              <el-tooltip
                class="item"
                effect="dark"
                content="只能上传jpg文件"
                placement="bottom-start"
              >
                <i class="el-icon-plus"></i>
              </el-tooltip>
            </el-upload>-->

            <el-upload
                style="float: left"
                class="upload-demo idCardCss"
                action=""
                accept=".png,.jpg"
                ref="upload"
                list-type="picture-card"
                :headers="imgHeaders"
                :file-list="fileList"
                :on-change="MainImgonChangePic"
                :show-file-list="true"
                :auto-upload="false"
                :on-remove="fileRemovePic"
            >
              <el-tooltip
                  class="item"
                  effect="dark"
                  content="只能上传jpg文件"
                  placement="bottom-start"
              >
                <i class="el-icon-plus"></i>
              </el-tooltip>
            </el-upload>
          </el-form-item>
          <el-form-item label="身份证照片" prop="idCardMainImage">
            <!-- <el-input v-model="dataForm.userPicUrl" auto-complete="off"></el-input> -->
            <div
              class="idCardCss"
              style="display: flex; flex-direction: column"
            >
              <el-upload
                style="float: left"
                class="upload-demo"
                action=""
                accept=".png,.jpg"
                ref="upload"
                list-type="picture-card"
                :headers="imgHeaders"
                :file-list="MainfileList"
                :on-change="MainImgonChange"
                :show-file-list="true"
                :auto-upload="false"
                :on-remove="fileRemove"
              >
                <i class="el-icon-plus"></i>
                <span class="idCardText">身份证人像面</span>
                <!-- <el-button size="small" type="primary">点击选择</el-button> -->
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item prop="idCardBackImage">
            <div
              class="idCardCss"
              style="display: flex; flex-direction: column"
            >
              <el-upload
                style="float: left"
                class="upload-demo"
                action=""
                accept=".png,.jpg"
                ref="upload"
                list-type="picture-card"
                :headers="imgHeaders"
                :file-list="BackfileList"
                :on-change="BackImgonChange"
                :show-file-list="true"
                :auto-upload="false"
                :on-remove="backFileRemove"
              >
                <i class="el-icon-plus"></i>
                <span class="idCardText">身份证国徽面</span>
                <!-- <el-button size="small" type="primary">点击选择</el-button> -->
              </el-upload>
            </div>
          </el-form-item>
          <!-- <el-form-item label="邮箱" prop="email">
              <el-input v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item label="住址">
              <el-input v-model="ruleForm.address"></el-input>
            </el-form-item> -->
        </el-col>
        <el-col :span="6">
          <!-- 二维码 -->
          <img class="codeImageClass" :src="ruleForm.codeImage" alt="" />
        </el-col>
      </el-row>

      <el-row class="PostInfCs">
        <div class="name">
          <img src="../../../assets/userImg/xinxi.png" alt="" /> 岗位信息
        </div>
        <el-divider></el-divider>
        <el-col v-if="isSubcontractUnit == false" :span="6">
<!--          <el-form-item
            label="所属单位"
            label-width="100px"
            prop="nameArr"
            class=""
          >
            <el-select
              :value="ruleForm.nameArr"
              placeholder="请选择所属单位"
              id="selectN"
            >
              <el-option :value="valueTitle" :label="valueTitle">
                <el-tree
                  id="tree-option"
                  ref="selectTree"
                  :data="deptData"
                  :props="props"
                  node-key="id"
                  @node-click="inputTreeSelecet"
                >
                </el-tree>
              </el-option>
            </el-select>
          </el-form-item>-->
          <el-form-item
              label="所属单位"
              label-width="100px"
              prop="unitId"
              class=""
          >
            <el-select
                v-model="ruleForm.unitId"
                placeholder="请选择"
                @change="comChange"
                style="width: 100%"
            >
              <el-option
                  v-for="item in unitList"
                  :key="item.uuid"
                  :label="item.companyName"
                  :value="item.uuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="isSubcontractUnit == true" :span="6">
          <el-form-item label="所属单位" label-width="100px" prop="unitName">
            <el-input v-model="ruleForm.unitName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单位类型" label-width="100px">
            <el-input
              v-model="ruleForm.unitTypeName"
              placeholder="请选择"
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="isSubcontractUnit == false" :span="6">
          <el-form-item label="所属部门" label-width="100px" prop="nameArr1">
            <el-select
                v-model="ruleForm.deptId"
                placeholder="请选择"
                filterable
                @change="deptChange"
                style="width: 100%"
            >
              <el-option
                  v-for="item in deptList"
                  :key="item.id"
                  :label="item.departmentName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="isSubcontractUnit == true" :span="6">
          <el-form-item label="所属单位" label-width="100px" prop="deptName">
            <el-input v-model="ruleForm.deptName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="岗位职称" label-width="100px" v-if="!isDalian">
            <el-select
              v-model="ruleForm.postId"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in postidlistcList"
                :key="item.id"
                :label="item.postName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="人员类别"
            label-width="100px"
            prop="personType"
            v-if="isDalian"
          >
            <el-select
              v-model="ruleForm.personType"
              placeholder="请选择"
              clearable
              class="form-item sel-width"
              :disabled="title != '添加'"
              @change="personTypeChange"
            >
              <el-option label="管理人员" value="manager"> </el-option>
              <el-option label="作业人员" value="operationPersonnel">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="人员细分"
            label-width="100px"
            prop="personDetailType"
            v-if="isDalian"
          >
            <el-select
              v-model="ruleForm.personDetailType"
              placeholder="请选择"
              clearable
              class="form-item sel-width"
              @change="getChangeJobTypes"
            >
              <el-option
                v-for="item in personTypeList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="人员工种"
            filterable
            label-width="100px"
            prop="jobTypeLabel"
          >
            <el-select
              v-model="ruleForm.jobTypeLabel"
              placeholder="请选择"
              filterable
              class="form-item sel-width"
            >
              <el-option
                v-for="item in jobTypes"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="角色" label-width="100px" class="roledfddd">
            <el-select
              v-model="ruleForm.userRoles"
              collapse-tags
              multiple
              placeholder="请选择"
              v-has="'sys:user:roleEdit'"
              @change="rolsechange"
            >
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

<!--        <el-col :span="6">
          <el-form-item
            label="LED班组"
            label-width="100px"
            class="roledfddd"
            v-if="isJM"
          >
            <el-select
              v-model="ruleForm.ledTeamType"
              collapse-tags
              placeholder="请选择"
            >
              <el-option
                v-for="item in LedteamAll"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>-->
        <!-- <el-col :span="6">
            <el-form-item label="班组" label-width="100px" class="roledfddd" v-if="!isDalian">
              <el-select v-model="ruleForm.teamType" collapse-tags placeholder="请选择">
                <el-option v-for="item in teamAll" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="签名" label-width="100px" class="roledfddd" v-if="!isDalian">
              <div class="signatureClass" @click="signatureClick()">
                <img v-if="signatureImgUrl" :src="signatureImgUrl" alt="" />
              </div>
            </el-form-item>
          </el-col> -->
      </el-row>

      <!-- 手签名内层弹框 -->
      <el-dialog
        width="700px"
        title="手签名"
        :visible.sync="signatureVisible"
        v-if="signatureVisible"
        :close-on-click-modal="false"
        append-to-body
        :before-close="handleSignature"
      >
        <signatureBox
          :signatureImgUrl="signatureImgUrl"
          @setImgUrl="setImgUrl"
        ></signatureBox>
      </el-dialog>

      <el-row v-if="editorType == 'Leditor'">
        <div class="name">
          <span
            ><img src="../../../assets/userImg/xinxi.png" alt="" />
            合同信息</span
          >
          <span v-if="!deptForm.length" class="buttomName"
            ><el-button
              @click.prevent="contractAdd()"
              size="mini"
            >
              <i class="fa fa-plus"></i>新增
            </el-button></span
          >
        </div>
        <el-divider></el-divider>
        <el-table :data="deptForm" id="tableList" border max-height="250">
          <el-table-column type="index" width="60" label="序号" align="center">
          </el-table-column>
          <el-table-column label="合同编号" align="center" prop="contractCode">
          </el-table-column>
          <el-table-column label="签订时间" align="center" prop="startTime">
          </el-table-column>
          <el-table-column label="到期时间" align="center" prop="endTime">
          </el-table-column>
          <el-table-column label="合同附件" align="center">
            <template slot-scope="scope">
              <div class="clickable" @click="getfile(scope.row)">点击查看</div>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                @click.native.prevent="deptEdit(scope.$index, scope.row)"
                type="text"
              >
                修改
              </el-button>
              <el-button
                @click.native.prevent="deptDel(scope.$index, scope.row)"
                type="text"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <div class="name">
          <img src="../../../assets/userImg/xinxi.png" alt="" /> 补充信息
        </div>
        <el-divider></el-divider>
        <div class="information">
          <span
            >特种作业人员
            <el-switch
              v-model="ruleForm.specialEquipmentOperators"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="changeSwith"
            >
            </el-switch>
          </span>
          <span
            >安全培训
            <el-switch
              v-model="ruleForm.safeTrain"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </span>
          <!--    <span
                >劳务合同
                <el-switch
                  v-model="ruleForm.signLaborContract"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </span> -->
          <span
            >进场手续
            <el-switch
              v-model="ruleForm.signEntryProcedure"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </span>
          <span
            >是否社保<el-switch
              v-model="ruleForm.socialInsurance"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch
          ></span>
          <span
            >身体隐疾<el-switch
              v-model="ruleForm.disease"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch
          ></span>
          <span
            >用户状态<el-switch
              v-model="ruleForm.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch
          ></span>
          <!--  <span
              >黑名单<el-switch
                v-model="ruleForm.isBlacklist"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch
            ></span> -->
          <!-- <span class="dansup">疫苗接种 -->
          <!-- <el-form-item label="疫苗接种" label-width="80px" class="dansup">
              <el-select v-model="ruleForm.vaccination" placeholder="请选择">
                <el-option v-for="item in vaccinationList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item> -->
          <!-- </span> -->
        </div>
      </el-row>
      <!-- 证书  违规记录  v-if="isHaveCertificate"-->
      <div v-if="editorType == 'Leditor'">
        <el-row>
          <div class="name">
            <span
              ><img src="../../../assets/userImg/xinxi.png" alt="" />
              技能证书</span
            >
            <span class="buttomName"
              ><el-button
                :class="{ customButton: true, 'DL-btn': isDalian }"
                @click.prevent="handleAdd()"
                size="mini"
              >
                <i class="fa fa-plus"></i>新增
              </el-button></span
            >
          </div>
          <el-divider></el-divider>
          <el-table
            :data="ruleForm.aiUserCertificateList"
            id="tableList"
            border
            max-height="250"
          >
            <el-table-column
              type="index"
              width="50"
              label="序号"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="证书名称"
              align="center"
              prop="certificateName"
            >
            </el-table-column>
            <el-table-column
              label="证书编号"
              align="center"
              prop="certificateCode"
            >
            </el-table-column>
            <el-table-column
              label="证书类型"
              align="center"
              prop="certificateTypeName"
            >
            </el-table-column>
            <el-table-column
              label="证书等级"
              align="center"
              prop="certificateLevel"
            >
            </el-table-column>
            <el-table-column
              label="开始有效期"
              align="center"
              prop="expirationStart"
            >
            </el-table-column>
            <el-table-column
              label="结束有效期"
              align="center"
              prop="expirationEnd"
            >
            </el-table-column>
            <el-table-column label="证书照片" align="center">
              <template slot-scope="scope">
                <ImgUpload
                  class="erweima"
                  :src="scope.row.imgUrlpic || scope.row.file"
                  :preview-src-list="[scope.row.imgUrlpic || scope.row.file]"
                  :zIndex="2100"
                ></ImgUpload>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="updateRow(scope.$index, scope.row)"
                  type="text"
                >
                  修改
                </el-button>
                <el-button
                  @click.native.prevent="deleteRow(scope.$index, scope.row)"
                  type="text"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <div class="name">
            <span
              ><img src="../../../assets/userImg/xinxi.png" alt="" />
              不良行为</span
            >
          </div>
          <el-divider></el-divider>
          <el-table
            :data="ruleForm.aiViolationRecordList"
            id="tableList"
            border
            max-height="250"
          >
            <el-table-column
              label="序号"
              width="180"
              type="index"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="不良行为"
              align="center"
              prop="violationTypeName"
            >
            </el-table-column>
            <el-table-column label="处罚日期" align="center" prop="punishDate">
            </el-table-column>
            <el-table-column
              label="发生时间"
              align="center"
              prop="violationTime"
            >
            </el-table-column>
            <el-table-column label="违规图片" align="center">
              <template slot-scope="scope">
                <ImgUpload
                  class="erweima"
                  :src="scope.row.violationImgs"
                  :preview-src-list="[scope.row.violationImgs]"
                  :zIndex="2100"
                ></ImgUpload>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <!-- </div>
        <div v-if="editorType == 'Leditor'"> -->

        <el-row class="daka">
          <div class="name">
            <img src="../../../assets/userImg/xinxi.png" alt="" /> 打卡记录
          </div>
          <el-divider></el-divider>
          <!--工具栏-->
          <div
            style="height: 45px; overflow: hidden; padding: 5px 0 0 0"
            v-if="isParmsShow"
          >
            <div class="" style="float: left">
              <el-form :inline="true" :model="filters" :size="size">
                <el-form-item
                  class="filter-item"
                  style="width: 50%; float: left"
                >
                  <el-date-picker
                    v-model="timeList"
                    type="daterange"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    clearable
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  class="filter-item"
                  style="width: 30%; float: left"
                >
                  <el-select
                    v-model="siteIdd"
                    placeholder="请选择进出地点"
                    class="form-item"
                    clearable
                  >
                    <el-option
                      v-for="item in userSiteIdList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button
                    :size="size"
                    @click="findPage()"
                  >
                    <i class="fa fa-search"></i> 查询
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="" style="float: right">
              <el-form :inline="true" :model="filters" :size="size">
                <el-form-item>
                  <el-button
                    :size="size"
                    @click.prevent="exportExcel()"
                  >
                    <i class="el-icon-download"></i>导出
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div v-else class="" style="float: right;margin: 10px 0">
            <el-button
              :size="size"
              @click.prevent="allButton()"
              >更多
            </el-button>
          </div>
          <el-table
            max-height="400"
            :data="BrakeList.list"
            border
            style="width: 100%"
          >
            <el-table-column
              label="序号"
              width="80"
              type="index"
              align="center"
            >
            </el-table-column>
            <el-table-column align="center" prop="userName" label="姓名">
            </el-table-column>
            <el-table-column align="center" prop="deptName" label="单位">
            </el-table-column>
            <el-table-column align="center" prop="ioTime" label="时间">
            </el-table-column>
            <el-table-column align="center" prop="siteName" label="进出位置">
            </el-table-column>
            <el-table-column align="center" prop="ioStatusStr" label="进出类型">
            </el-table-column>
            <el-table-column align="center" prop="temperature" label="体温">
            </el-table-column>
            <el-table-column align="center" prop="snapImage" label="现场图片">
              <template slot-scope="scope">
                <ImgUpload
                  class="erweima"
                  :src="scope.row.snapImage"
                  :preview-src-list="[scope.row.snapImage]"
                  :zIndex="2100"
                ></ImgUpload>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="isParmsShow"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="filters.pageNum"
            :page-sizes="[2, 3, 5, 10]"
            :page-size="filters.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="BrakeList.total"
            style="padding: 10px; text-align: right"
          >
          </el-pagination>
        </el-row>
      </div>

      <div class="nameButton" v-if="isHaveCertificate">
        <el-form-item>
          <el-button
            type="primary"
            :class="{ 'DL-btn': isDalian }"
            @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <!-- 证书的新增修改 -->
    <el-dialog
      :title="aiUsertitle"
      width="50%"
      :visible.sync="aiUserVisible"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
      :before-close="aiUsercolse"
    >
      <el-form
        :model="aiUserForm"
        label-width="200px"
        ref="aiUserForm"
        :size="size"
        label-position="right"
        :validate-on-rule-change="false"
        :disabled="disabled"
      >
        <el-form-item
          label="证书名称"
          prop="certificateName"
          :rules="{
            required: true,
            message: '请输入证书名称',
            trigger: 'blur',
          }"
        >
          <el-input v-model="aiUserForm.certificateName"></el-input>
        </el-form-item>
        <el-form-item
          label="证书编号"
          prop="certificateCode"
          :rules="{
            required: true,
            message: '请输入证书编号',
            trigger: 'blur',
          }"
        >
          <el-input v-model="aiUserForm.certificateCode"></el-input>
        </el-form-item>
        <el-form-item
          label="证书类型"
          prop="certificateType"
          :rules="{
            required: true,
            message: '请输入证书类型',
            trigger: 'change',
          }"
        >
          <el-select
            v-model="aiUserForm.certificateType"
            placeholder="请选择证书类型"
            class="form-item"
            style="width: 100%"
          >
            <el-option
              v-for="item in certificateTypeList"
              :key="item.id"
              :label="item.label"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="证书等级"
          prop="certificateLevel"
          :rules="{
            required: true,
            message: '请输入证书等级',
            trigger: 'blur',
          }"
        >
          <el-input v-model="aiUserForm.certificateLevel"></el-input>
        </el-form-item>
        <el-form-item
          label="开始结束有效期"
          prop="valuedate"
          :rules="{
            required: true,
            message: '请输入开始结束有效期',
            trigger: 'blur',
          }"
        >
          <el-date-picker
            v-model="aiUserForm.valuedate"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始有效期"
            end-placeholder="结束有效期"
          >
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item
            label="开始有效期"
            prop="expirationStart"
            :rules="{ required: true, message: '请输入开始有效期', trigger: 'blur' }"
          >
            <el-date-picker
              v-model="aiUserForm.expirationStart"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="起始时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="结束有效期"
            prop="expirationEnd"
            :rules="{ required: true, message: '请输入结束有效期', trigger: 'blur' }"
          >
            <el-date-picker
              v-model="aiUserForm.expirationEnd"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="起始时间"
            >
            </el-date-picker>
          </el-form-item> -->
        <el-form-item
          prop="file"
          label="证书照片"
          :rules="{
            required: istrue,
            message: '请输入证书照片',
            trigger: 'blur',
          }"
        >
<!--          <el-upload
            class="upload-demo"
            :action="certificateTypePic"
            accept=".jpg"
            :data="CertificateData"
            list-type="picture-card"
            :headers="imgHeaders"
            ref="CertificatePic"
            :limit="1"
            :file-list="aifileList"
            :before-upload="aiUserCertificateListBefore"
            :on-remove="aiUserCertificateListRemove"
            :on-success="aiUserCertificateListSuccess"
            :on-change="aiUserCertificateListchange"
            :disabled="!isHaveCertificate"
            :auto-upload="false"
          >
            <el-button size="mini" type="primary"> 上传证书图片</el-button>
          </el-upload>-->
          <Uploader @uploader="bookUploader" accept=".jpg,.png"></Uploader>
          <div class="file-list" v-if="aiUsertitle === '修改' ">
            <div class="file-item" v-for="(item,index) in aiUserFile" :key="index">
              {{ item.fileName }}
            </div>
          </div>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer" v-if="!disabled">
        <el-button
          :size="size"
          @click.native="
            () => {
              (aiUserVisible = false), (aiUserForm = {});
            }
          "
          >取消</el-button
        >
        <el-button
          :size="size"
          type="primary"
          @click.native="aiUsersubmit"
        >
          确定
        </el-button>
      </div>
    </el-dialog>

    <!-- 查看附件 -->
    <el-dialog
      title="查看附件"
      width="60%"
      v-if="dialogFileVisible"
      :visible.sync="dialogFileVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      @close="fileClose"
    >
      <div v-if="hasFile" class="fileContent">
        <h4 v-if="imgList.length > 0">图片附件</h4>
        <div v-for="item in imgList" :key="item.id">
          <el-image
            style="width: 100px; height: 100px"
            :src="item.filePath"
            :preview-src-list="[item.filePath]"
          >
          </el-image>
          <p @click="downloadImg(item.filePath, item.fileName)">下载</p>
        </div>

        <h4 v-if="pdfList.length > 0">PDF附件</h4>
        <ul v-if="pdfList.length > 0">
          <li v-for="item in pdfList" :key="item.id">
            {{ item.fileName
            }}<span @click="downloadImg(item.filePath, item.fileName)"
              >下载</span
            ><a @click="view(item.filePath)">在线预览</a>
          </li>
        </ul>
      </div>
      <div v-else class="noFile">暂无附件</div>
    </el-dialog>

    <el-dialog
      title="预览"
      :visible.sync="viewVisible"
      center
      width="60%"
      @close="closePreview"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <iframe
        class="iframe"
        :src="src"
        frameborder="0"
        width="100%"
        height="700px"
      >
      </iframe>
    </el-dialog>

    <!-- 合同信息修改,新增 -->
    <el-dialog
      class="editDialog"
      :title="contractTitle"
      width="70%"
      v-if="dialogFormVisible"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      @close="formClose"
    >
      <div class="page-container" style="margin-top: 0px">
        <!-- :rules="disabled ? {} : dataFormRules" -->
        <el-form
          :model="nowMsg"
          label-width="180px"
          ref="form"
          :size="size"
          label-position="right"
          :validate-on-rule-change="false"
        >
          <el-row class="PostInfCs-input">
            <el-col :span="12">
              <el-form-item
                label="合同编号"
                prop="contractCode"
                style="text-align: left"
              >
                <el-input v-model="nowMsg.contractCode"></el-input>
              </el-form-item>
              <el-form-item
                label="合同签订时间"
                prop="datevaert"
                style="text-align: left"
              >
                <el-date-picker
                  v-model="nowMsg.datevaert"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="签订时间"
                  end-placeholder="终止时间"
                >
                </el-date-picker>
              </el-form-item>
              <!-- <el-form-item label="终止时间" prop="endTime">
                  <el-date-picker style="width: 100%" v-model="nowMsg.endTime" :clearable="false" type="date"
                    format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="终止时间">
                  </el-date-picker>
                </el-form-item> -->
            </el-col>
            <!-- <el-col :span="12">
                <el-form-item label="签订时间" prop="startTime">
                  <el-date-picker style="width: 100%" v-model="nowMsg.startTime" type="date" format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd" placeholder="签订时间" :clearable="false">
                  </el-date-picker>
                </el-form-item>
              </el-col> -->
          </el-row>
          <el-row v-if="contractTitle == '修改合同信息'">
            <el-form-item label="附件上传">
              <el-upload
                ref="upload"
                :action="iUserContractpicUrl"
                :on-change="handleChange"
                :auto-upload="true"
                :file-list="fileList"
                :headers="imgHeaders"
                :show-file-list="false"
                :data="fileform"
                :before-upload="handleBefore"
                :on-success="handleSuccess"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-row>
          <el-row v-else>
            <el-form-item label="附件上传">
              <el-upload
                :file-list="files"
                :auto-upload="false"
                :action="iUserContractpicUrl"
                :show-file-list="false"
                :headers="imgHeaders"
                :before-upload="handleBefore"
                :on-change="handleChangeAdd"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
              <!-- <input type="file" @change="fn"> -->
            </el-form-item>
          </el-row>
          <el-row class="fileList">
            <el-form-item label="合同附件">
              <ul v-if="contractTitle == '修改合同信息'">
                <li v-for="item in fileLists" :key="item.id">
                  {{ item.fileName
                  }}<span class="del" @click="handleRemove(item.id)">删除</span>
                </li>
              </ul>
              <ul v-else>
                <li v-for="item in files" :key="item.uid">
                  {{ item.name
                  }}<span class="del" @click="handleRemoveAdd(item.uid)"
                    >删除</span
                  >
                </li>
              </ul>
              <span
                v-if="
                  (fileLists.length == 0 && contractTitle == '修改合同信息') ||
                    (files.length == 0 && contractTitle == '新增合同信息')
                "
                >暂无附件</span
              >
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-button :size="size" @click.native="formClose">取消</el-button>
          <el-button
            :size="size"
            type="primary"
            style="height: 32px"
            @click.native="sure"
            >确认</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ImgUpload from "@/components/ImgUpload";
import Uploader from "@/views/SystemManagement/personInfo/components/Uploader";
import signatureBox from "@/components/signature";
import { getDictData } from "@/api/dict";
import {
  aiEduExamRecordmyPage,
  changeContract,
  contractPage,
  delContract, getUserGateBrakePage, iUserContractPage,
  postidlist,
  saveContract,
  uploadIdCardImg, uploadPicImg,
  userSave
} from "@/api/user";
import {getComs, getDepts} from "@/api/ucenter";
import {queryDeptDetail} from "@/api/org";
import dayjs from "dayjs";
import {baseURL} from "@/api/http";

export default {
  components: {
    ImgUpload,
    signatureBox,
    Uploader
  },
  props: {
    idUsetList: {
      type: Object,
      default: {},
    },
    BrakeList: {
      type: Object,
      default: {},
    },
    roleList: {
      type: Array,
      default: [],
    },
    userSiteIdList: {
      type: Array,
      default: [],
    },
    editorType: {
      type: String,
      default: "Beditor",
    },
    isHaveCertificate: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
    isSubcontractUnit: {
      type: Boolean,
      default: false,
    },
    userDeptUnitType: {
      type: String,
      default: "",
    },
    subUnitType: {
      type: Array,
      default: [],
    },
  },
  data() {
    let issMobile = /^1[3456789]\d{9}$/;
    let ismobile = (rule, value, callback) => {
      if (!issMobile.test(value)) {
        return callback(new Error("手机号码格式错误！"));
      } else {
        callback();
      }
    };
    return {
      isDalian: false,
      aaaaa: "",
      size: "small",
      // 证书弹出框
      aiUsertitle: "",
      aiUserVisible: false,
      aiUserFile : [],
      aifileList: [],
      aiUserForm: {
        iid: null,
        file: "",
        imgUrlpic: "",
        certificateName: "",
        certificateCode: "",
        certificateTypeName: "",
        certificateType: "",
        certificateLevel: "",
        expirationStart: "",
        expirationEnd: "",
        valuedate: [],
      },
      unitList : [],
      deptList : [],
      ruleForm: {
        personName: "",
        emergencyContact: "",
        emergencyContactPhone: "",
        name: "",
        sex: "",
        age: "",
        politicalIdentity: "",
        idCard: "",
        password: "",
        mobile: null,
        major: "",
        marriage: "",
        email: "",
        socialSecurityNumber: "",
        address: "",
        codeImage: "",
        unitId: null,
        deptId: null,
        postId: "",
        jobTypeLabel: "",
        jobTypeValue: "",
        safeTrain: "",
        specialEquipmentOperators: 0,
        signLaborContract: "",
        signEntryProcedure: "",
        socialInsurance: "",
        disease: "",
        vaccination: "",
        userPicUrl: "",
        userRoles: [],
        status: "",
        isBlacklist: "",
        unitTypeName: "",
        nameArr: "",
        nameArr1: "",
        aiUserCertificateList: [],
        aiViolationRecordList: [],
        contractId: "",
        deptList: [],
        teamTypeName: "",
        teamType: "",
        ledTeamTypeName: "", //LED班组名称
        ledTeamType: "", //LED班组

        personType: "",
        personDetailType: "",
        entryTime: "",
        exitTime: "",
      },
      MainfileList: [],
      BackfileList: [],
      exarch: true,
      arena: false,
      filters: {
        pageNum: 1,
        pageSize: 5,
        siteIdList: [],
        userId: null,
        endtime: "",
        starttime: "",
      },
      timeList: [],
      total: 0,
      isParmsShow: false,
      siteIdd: [],
      pickerOptions: {
        disabledDate(time) {
          const date = new Date();
          return time.getTime() > date.getTime();
        },
      },
      istrue: false,
      swithNum: "",
      // 证书类型
      certificateTypeList: [],
      rules: {
        personName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        // name: [{ required: true, message: "请输入账号", trigger: "blur" }],
        idCard: [
          { required: true, message: "请输入身份证", trigger: "blur" },
          {
            pattern: /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/,
            message: "身份证号格式错误",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        deptId: [
          { required: true, message: "请选择部门", trigger: "change" },
        ],
        unitId: [{ required: true, message: "请选择单位", trigger: "change" }],
        jobTypeLabel: [
          { required: true, message: "请选择人员工种", trigger: "change" },
        ],
        personType: [
          { required: true, message: "请选择人员类别", trigger: "change" },
        ],
        personDetailType: [
          { required: true, message: "请选择人员细分", trigger: "change" },
        ],

        certificateName: [
          { required: true, message: "请输入证书名称", trigger: "blur" },
        ],
        certificateType: [
          { required: true, message: "请输入证书类型", trigger: "blur" },
        ],
        certificateLevel: [
          { required: true, message: "请输入证书等级", trigger: "blur" },
        ],
        expirationStart: [
          { required: true, message: "请输入有效期", trigger: "blur" },
        ],
        file: [{ required: true, message: "请输入证书照片", trigger: "blur" }],
        // idCardMainImage: [{ required: true, message: "请上传身份证人像面照片", trigger: "change" }],
        // idCardBackImage: [{ required: true, message: "请上传身份证国徽面照片", trigger: "change" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { required: true, validator: ismobile, trigger: "blur" },
          // { pattern: /^1[3456789]\d{9}$/, message: "手机号码格式错误", trigger: "blur" }
        ],
        email: [
          { required: false, trigger: "blur" },
          {
            pattern: /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
            message: "邮箱格式错误",
            trigger: "blur",
          },
        ],
      },
      imageUrl: "",
      identity: [],
      marriageList: [
        {
          value: 1,
          label: "未婚",
        },
        {
          value: 2,
          label: "已婚",
        },
        {
          value: 3,
          label: "离异",
        },
        {
          value: 4,
          label: "丧偶",
        },
      ],
      deptData: [],
      clearable: false,
      normalizer(node) {
        //去掉children=[]的children属性
        if (node.children && !node.children.length) {
          delete node.children;
        }
        return {
          id: node.id,
          label: node.name,
          children: node.children,
          isDisabled: node.parentId == 0,
        };
      },
      deptDataDepartment: [],
      postidlistcList: [],
      jobTypes: [], // 工种类型字典
      vaccinationList: [
        { name: "未接种", id: 0 },
        { name: "第一针", id: 1 },
        { name: "第二针", id: 2 },
        { name: "第三针", id: 3 },
      ],
      tableDataK: [],
      tableDataP: [],
      imgUrl: "",
      MainImgUrl: "",
      fileList: [],
      imgHeaders: {
        Authorization: localStorage.getItem("jwtToken"),
      },
      imgData: { userId: "" },
      xIds: "",
      CertificateData: { userId: "", xId: ""},
      fileListPic: [],
      disabled: false,
      unitTypeList: [],
      unitTypeName: "",
      certificateTypePic: "",
      imgParams: {
        file: null,
        backFile: null,
        userId: "",
      },

      // 以下是合同信息
      src: "",
      viewVisible: false,
      contractTitle: "",

      imgList: [],
      pdfList: [],
      dialogFileVisible: false,
      hasFile: false,
      dialogFormVisible: false,
      iUserContractpicUrl: "",
      fileform: {},
      fileLists: [],
      deptForm: [],
      deptNum: "",
      nowMsg: {
        contractId: "",
        contractCode: "",
        startTime: "",
        endTime: "",
        datevaert: [],
      },
      oldMsg: null,
      contractId: "",
      parms: {
        pageNum: 1,
        pageSize: 100,
        id: null,
      },
      files: [],

      newData: null,
      /*********************end合同**** */
      iseyes: true,
      idCard: "",
      valueTitle: "",
      nameArr: "",
      nameArr1: "",
      //权限树的默认配置
      props: {
        value: "id",
        label: "name",
        children: "children",
        expandTrigger: "hover",
        disabled: "disabled",
      },
      // 证书是添加还是修改 是否走图片的success事件
      aiUserCertificate: [],
      // 班组集合
      teamAll: [],
      // LED班组
      LedteamAll: [],
      // 手签名组件显示
      signatureVisible: false,
      // Base64||img路径
      signatureImgUrl: null,
      personTypeList: [],
      isJM: false,
      hasPic : false,
    };
  },
  watch: {
   /* "ruleForm.unitId": {
      deep: true,
      handler(v) {
        if (this.ruleForm.unitId) {
          this.unitTypeList.forEach((i) => {
            if (i.value == this.ruleForm.unitType) {
              this.unitType = i.label;
            }
          });
          this.postList();
        }
      },
    },*/
   /* "ruleForm.deptId": {
      deep: true,
      handler(v) {
        if (this.ruleForm.deptId) {
          // this.$api.userList.findDeptTreeById(this.ruleForm.deptId).then((res) => {
          //   if (res.code == 200) {
          //     this.deptDataDepartment = res.data
          //   }
          // })
          this.postidlistc(this.ruleForm.deptId);
        }
      },
    },*/
    fileList: {
      deep: true,
      handler(v) {
        if (this.fileList.length > 0) {
          this.$nextTick(() => {
            let ele = document.getElementsByClassName(
              "el-upload--picture-card"
            )[0];
            ele.style.display = "none";
          });
        }
      },
    },
    MainfileList: {
      deep: true,
      handler(v) {
        if (this.MainfileList.length > 0) {
          this.$nextTick(() => {
            let ele = document.getElementsByClassName(
              "el-upload--picture-card"
            )[1];
            ele.style.display = "none";
          });
        }
      },
    },
    BackfileList: {
      deep: true,
      handler(v) {
        if (this.BackfileList.length > 0) {
          this.$nextTick(() => {
            let ele = document.getElementsByClassName(
              "el-upload--picture-card"
            )[2];
            ele.style.display = "none";
          });
        }
      },
    },
    "ruleForm.idCard": {
      deep: true,
      handler(v) {
        if (this.ruleForm.idCard == "") {
          this.iseyes = false;
        }
      },
    },
  },
  created() {
    let nTime = new Date();
    let nYear = nTime.getFullYear();
    let nMonth = nTime.getMonth() + 1;
    let nDay = nTime.getDate();

    this.isJM =
      sessionStorage.getItem("roleType") == "role_tiejian" ? true : false;
    this.getCertificateTypeList();
    if (this.title == "添加") {
      this.exarch = true;
      this.arena = true;
      this.ruleForm.entryTime = nYear + "-" + nMonth + "-" + nDay;
    }
    this.getAllComs();
  },
  mounted() {
    this.getJobTypes();
    // console.log(this.title, "this.title")
    this.initHandle();
    //上传图片附件接口
    this.ruleForm = Object.assign(this.ruleForm, this.idUsetList);
    this.certificateTypePic = baseURL + "/cooper/aiUserCertificate/uploadPic"
    this.imgUrl = baseURL + "/gzdt-ucenter/user/uploadPic"
    this.signatureImgUrl = this.idUsetList.signImage;
    // 判断当前用户在编辑的情况下 并且不属于分包单位的话 执行以下逻辑，否则在获取单位类型后执行其他逻辑
    if (
      this.title == "用户信息" &&
      !this.subUnitType.includes(this.userDeptUnitType)
    ) {
      this.idUsetList.aiUserCertificateList.forEach((item, index) => {
        item.xId = index;
      });
      this.ruleForm.unitName = this.ruleForm.unitName;
      this.ruleForm.nameArr = this.ruleForm.unitName;
      this.ruleForm.unitId = this.ruleForm.unitId;
      this.ruleForm.nameArr1 = this.ruleForm.deptName;
      this.ruleForm.deptId = this.ruleForm.deptId;
      this.ruleForm.entryTime = this.ruleForm.entryTime;
      this.ruleForm.exitTime = this.ruleForm.exitTime;
      if (this.ruleForm.status == 1) {
        this.exarch = true;
        this.arena = false;
      } else {
        this.exarch = false;
        this.arena = true;
      }
    }
    // this.findUnitTreeLit();
    // 获取班组字典
    // this.getTeamTypes();
    // 获取政治面貌
    this.getpoliticalIdentity();
    // 单位类型
    this.getType();
  /*  // 考试培训的表格数据
    this.aiEduExamRecordmyPagecl(1, this.ruleForm.id);
    this.aiEduExamRecordmyPagecl(2, this.ruleForm.id);*/
    this.imgData.id = this.ruleForm.id;
    //  回显头像
    if (this.ruleForm.userPicUrl) {
      this.fileList.push({
        name: "头像",
        url: this.ruleForm.userPicUrl,
      });
    }
    if (this.ruleForm.idCardMainImage) {
      console.log(222)
      this.MainfileList.push({
        url: this.ruleForm.idCardMainImage,
      });
    }
    if (this.ruleForm.idCardBackImage) {
      this.BackfileList.push({
        url: this.ruleForm.idCardBackImage,
      });
    }
    if (this.ruleForm.idCard) {
      this.idCard = "******************";
      this.$refs.idcardbf.value = this.ruleForm.idCard;
    }
    this.CertificateData.userId = this.ruleForm.id;
    // 获取合同
    this.getDept();
    // this.idUsetList.userRoles = ["35", "36"]
    // console.log(this.idUsetList, this.idUsetList.userRoles)
  },
  methods: {
    bookUploader(event){
      console.log(event)
      if(event.length !== 0){
        this.aiUserForm.file = event.fileUrl;
        this.hasPic = true;
      }else{
        this.hasPic = false;
      }
    },
    async getAllComs() {
      let res = await getComs();
      if (res.code === 0) {
        this.unitList = res.data || [];
      }
    },
    //根据单位查询部门 和角色
    async comChange(val, data) {
      // data==undefined就是新增
      if (data == undefined) {
        this.ruleForm.deptId = "";
      }
      let category = "";
      this.unitList.forEach(item => {
        if(item.uuid === this.ruleForm.unitId){
          category = item.category;
        }
      })
      console.log(category)
      getDictData('typeUnit').then(res => {
        if(res.code == 0){
          res.data.forEach(item => {
            if(item.code === category){
              this.ruleForm.unitTypeName = item.intro;
            }
          })
        }
      })
      queryDeptDetail(val).then((res) => {
        getDepts(val).then((ress) => {
          if (ress.code === 0) {
            this.deptList = ress.data || [];
          }
        });
        if (res.data.category == "build") {
          this.isbuild = false;
          this.roleList = []; //要是选择的是建设单位就把角色清空 选择完部门再掉角色
        } else {
          this.isbuild = true;
          let params = {
            pageNo: 1,
            pageSize: 10000,
            name: "-",
            departmentId: "-",
            parentId: "-",
            unitType: res.data.category,
            companyId: "-",
          };
          this.queryAllRole(params);
        }
      });
    },
    deptChange(){
      this.unitList.forEach(item => {
        if(item.uuid === this.ruleForm.unitId){
          this.postidlistc(item.id);
        }
      })

    },
    // 人员细分改变，获取工种列表
    getChangeJobTypes(val) {
      if (val == "manager") {
        this.jobTypes = this.managerList;
      } else if (val == "specialOperationPersonnel") {
        this.jobTypes = this.specialOperationPersonnelList;
      } else if (val == "generalOperationPersonnel") {
        this.jobTypes = this.generalOperationPersonnelList;
      }
    },
    // 人员类别改变
    personTypeChange(val) {
      this.ruleForm.personDetailType = "";
      this.jobTypes = [];
      if (val == "operationPersonnel") {
        this.personTypeList = [
          { id: 1, label: "特种作业人员", value: "specialOperationPersonnel" },
          { id: 2, label: "一般作业人员", value: "generalOperationPersonnel" },
        ];
      } else if (val == "manager") {
        this.personTypeList = [{ id: 3, label: "管理人员", value: "manager" }];
      } else {
        this.personTypeList = [];
      }
    },
    // 社保号input
    sbinput(val) {
      console.log(val);
      if (val !== "") {
        this.ruleForm.socialInsurance = 1;
      } else {
        this.ruleForm.socialInsurance = 0;
      }
    },
    postList() {
      this.$api.userList.findDeptTreeById(this.ruleForm.unitId).then((res) => {
        if (res.code == 200) {
          // if (sessionStorage.getItem("roleType") != "role_dalian") {
          this.deptDataDepartment = res.data;
          // }
        }
      });
    },
    inputTreeSelecet(val, node, nodes) {
      if (val.parentId == 0) {
        return;
      } else {
        console.log("所属单位的点击", val);
        this.ruleForm.nameArr = val.name;
        this.ruleForm.unitId = val.id;
        this.ruleForm.unitType = val.unitType;
        // if (sessionStorage.getItem("roleType") != "role_dalian") {
        this.ruleForm.nameArr1 = "";
        this.deptDataDepartment = [];
        this.postList();
        // }
        this.unitTypeList.forEach((i) => {
          if (i.value == val.unitType) {
            this.ruleForm.unitTypeName = i.label;
          }
        });
      }
    },
    inputTreeSelecet1(val, node, nodes) {
      this.ruleForm.nameArr1 = val.name;
      this.ruleForm.deptId = val.id;
    },
    // 初始化值
    initHandle() {
      this.$nextTick(() => {
        let scrollWrap = document.querySelectorAll(
          ".el-scrollbar .el-select-dropdown__wrap"
        )[0];
        let scrollBar = document.querySelectorAll(
          ".el-scrollbar .el-scrollbar__bar"
        );
        scrollWrap.style.cssText =
          "margin: 0px; max-height: none; overflow: hidden;";
        scrollBar.forEach((ele) => (ele.style.width = 0));
      });
    },
    handleIconClick() {
      if (this.$refs.idcardbf.value == "******************") {
        this.$refs.idcardbf.value = this.ruleForm.idCard;
      } else if (this.$refs.idcardbf.value !== "") {
        this.$refs.idcardbf.value = "******************";
      }
    },
    // 预览
    view(url) {
      this.viewVisible = true;
      this.src = url;
    },
    closePreview() {
      this.viewVisible = false;
    },

    // 获取合同
    getDept() {
      contractPage({ userId: this.idUsetList.id })
        .then((res) => {
          this.deptForm = res.data;
          if (res.data.length > 0) {
            this.contractId = res.data[0].id;
          }
        });
    },
    // 查看文件
    getfile(row) {
      this.dialogFileVisible = true;
      iUserContractPage({ contractId: row.id, pageNum: 1, pageSize: 100 })
        .then((res) => {
          this.hasFile = res.data.list.length > 0 ? true : false;
          this.imgList = res.data.list.filter((i) => i.fileType !== "pdf");
          this.pdfList = res.data.list.filter((i) => i.fileType == "pdf");
        });
    },
    // 弹窗关闭
    fileClose() {
      this.dialogFileVisible = false;
    },
    formClose() {
      this.dialogFormVisible = false;
      if (this.deptForm.length > 0) {
        this.deptForm.splice(0, 1, this.oldMsg);
      }
      this.nowMsg = {
        contractId: "",
        contractCode: "",
        startTime: "",
        endTime: "",
      };
    },

    // 合同信息确认提交
    sure() {
      this.dialogFormVisible = false;
      this.nowMsg.userId = this.idUsetList.id;
      this.nowMsg.startTime = this.nowMsg.datevaert[0];
      this.nowMsg.endTime = this.nowMsg.datevaert[1];

      if (this.contractTitle == "新增合同信息") {
        this.nowMsg.file = this.files;
        let formData = new FormData();
        this.nowMsg.file = formData;
        for (let key in this.nowMsg) {
          if (key !== "file") {
            formData.append(key, this.nowMsg[key]);
          } else {
            this.files.forEach((i) => {
              formData.append("file", i);
            });
          }
        }
        saveContract(formData).then((res) => {
          if (res.code == 200) {
            this.getDept();
            this.$message({
              message: "上传成功",
              type: "success",
            });
          } else {
            this.$message({ message: "操作失败, " + res.msg, type: "error" });
          }
        });
      } else {
        changeContract(this.nowMsg).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
          } else {
            this.$message({ message: "操作失败, " + res.msg, type: "error" });
          }
        });
      }
    },
    contractAdd() {
      this.contractTitle = "新增合同信息";
      this.dialogFormVisible = true;
      this.nowMsg.contractId = "";
      this.fileLists = [];
    },
    // 修改合同
    deptEdit(index, row) {
      this.contractTitle = "修改合同信息";
      this.dialogFormVisible = true;
      this.nowMsg = row;
      this.nowMsg.datevaert = [row.startTime,row.endTime];
      this.oldMsg = JSON.parse(JSON.stringify(row));
      this.getFile();
    },
    // 删除合同
    deptDel(index, row) {
      this.$confirm("确认删除吗？", "提示", {}).then(() => {
        delContract(this.contractId).then((res) => {
          if (res.code == 200) {
            this.getDept();
            this.$message({
              message: "删除成功",
              type: "success",
            });
          } else {
            this.$message({ message: "操作失败, " + res.msg, type: "error" });
          }
        });
      });
    },

    // 文件提交前格式确认
    handleBefore(file) {
      // 截取上传文件的后缀名
      let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      // 判断文件名的类型
      if (fileType === "pdf" || fileType === "jpg" || fileType === "png") {
      } else {
        this.$message.error("上传文件仅支持pdf,jpg,png格式");
      }
    },

    // 获取文件
    getFile() {
      this.parms.contractId = this.contractId;
      iUserContractPage(this.parms).then((res) => {
        this.fileLists = res.data ? res.data.list : [];
      });
    },

    // 文件上传的change
    handleChange(file, fileList) {
      this.fileform.file = file.raw;
      this.fileform.contractId = this.contractId;
      this.fileform.fileSourceName = file.name;
      this.getFile();
    },
    handleChangeAdd(file, files) {
      this.files.push(file.raw);
    },
    // 上传成功
    handleSuccess(file, fileList) {
      if (file.code == "200") {
        this.getFile();
        this.$message({
          message: "上传成功",
          type: "success",
        });
      }
    },
    // 文件删除 remove
    handleRemove(id) {
      this.$confirm("确认删除选中记录吗？", "提示", {}).then(() => {
        this.$api.contract.aiUserContractDelete(id).then((res) => {
          if (res.code == 200) {
            this.getFile();
            this.$message({ message: "操作成功", type: "success" });
          } else {
            this.$message({ message: "操作失败, " + res.msg, type: "error" });
          }
        });
      });
    },
    handleRemoveAdd(id) {
      this.$confirm("确认删除选中记录吗？", "提示", {}).then(() => {
        this.files.splice(
          this.files.findIndex((i) => i.uid == id),
          1
        );
      });
    },
    // 图片下载
    downloadImg(url, name) {
      var x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function(e) {
        var url = window.URL.createObjectURL(x.response);
        var a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      };
      x.send();
    },
    // 点击更多展示查询导出等功能
    allButton() {
      this.isParmsShow = true;
      let parms = {
        pageNum: 1,
        pageSize: 5,
        siteIdList: [],
        userId: this.ruleForm.id,
      };
      getUserGateBrakePage(parms).then((udata) => {
        if (udata.code == 200) {
          this.BrakeList = udata.data;
        }
      });
    },
    // 打卡记录查询
    findPage() {
      if (this.timeList && this.timeList.length > 0) {
        this.filters.starttime = this.timeList[0];
        this.filters.endtime = this.timeList[1];
      } else {
        this.filters.starttime = "";
        this.filters.endtime = "";
      }
      this.filters.userId = this.ruleForm.id;
      if (this.siteIdd instanceof Array) {
        this.filters.siteIdList = this.siteIdd;
      } else if (this.siteIdd !== "") {
        this.filters.siteIdList[0] = this.siteIdd;
      } else {
        this.filters.siteIdList = [];
      }
      getUserGateBrakePage(this.filters).then((udata) => {
        if (udata.code == 200) {
          this.BrakeList = udata.data;
        }
      });
    },
    // 打卡记录导出
    exportExcel() {
      if (this.timeList && this.timeList.length > 0) {
        this.filters.starttime = this.timeList[0];
        this.filters.endtime = this.timeList[1];
      } else {
        this.filters.starttime = "";
        this.filters.endtime = "";
      }
      if (this.siteIdd instanceof Array) {
        this.filters.siteIdList = this.siteIdd;
      } else if (this.siteIdd !== "") {
        this.filters.siteIdList[0] = this.siteIdd;
      } else {
        this.filters.siteIdList = [];
      }
      let obj = {
        siteIdList: this.filters.siteIdList,
        userId: this.ruleForm.id,
        endtime: this.filters.endtime,
        starttime: this.filters.starttime,
      };
      this.exportLoading = true;
      this.$api.userList.exportUserGateBrake(obj).then((res) => {
        const aLink = document.createElement("a");
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        aLink.href = URL.createObjectURL(blob);
        aLink.download = "打卡记录列表.xlsx";
        aLink.click();
        document.body.appendChild(aLink);
      });
    },
    //分页
    handleSizeChange(val) {
      this.filters.pageSize = val;
      this.findPage();
    },
    //分页,
    handleCurrentChange(val) {
      this.filters.pageNum = val;
      this.findPage();
    },
    idCardFocus(e) {
      const idcard = e.target.value;
      if (idcard == "" || idcard == null) {
        this.iseyes = false;
      } else {
        this.iseyes = true;
      }
    },
    // 身份证填完失焦后获取到年龄性别
    idCardBlur(e) {
      const idcard = e.target.value;
      if (idcard !== "******************") {
        this.ruleForm.idCard = idcard;
      }
      if (idcard == "" || idcard == null) {
        this.iseyes = false;
      } else {
        this.iseyes = true;
        if (this.$refs.idcardbf.value == "******************") {
        } else {
          this.idCard = "******************";
        }
      }
      let age = this.ruleForm.idCard.substr(6, 4);
      const dateYear = new Date();
      let todayYear = dayjs(dateYear).format("YYYY");
      this.ruleForm.age = Number(todayYear) - Number(age);
      let sex = this.ruleForm.idCard.substr(16, 1);
      if (sex % 2 == 0) {
        this.ruleForm.sex = "1";
      } else if (sex % 2 == 1) {
        this.ruleForm.sex = "0";
      }
    },
    // 证书的提交
    aiUsersubmit() {
      let that = this;
      console.log(that.aiUserForm.valuedate);
      that.aiUserForm.expirationStart = that.aiUserForm.valuedate[0];
      that.aiUserForm.expirationEnd = that.aiUserForm.valuedate[1];

      console.log(that.$refs.CertificatePic, "that.$refs.CertificatePic");
      that.$refs["aiUserForm"].validate((valid) => {
        if (valid) {
          // 证书类型
          that.certificateTypeList.forEach((item) => {
            if (item.code == that.aiUserForm.certificateType) {
              that.aiUserForm.certificateTypeName = item.label;
            }
          });
          console.log(that.hasPic)
          if (that.hasPic) {
            if (that.aiUserCertificate.length > 0) {
              that.$refs.CertificatePic.submit();
            } else {
              console.log(that.aiUserForm, "this.aiUserForm");
              if(that.aiUsertitle === '修改'){
                let index = that.ruleForm.aiUserCertificateList.findIndex(item => item.id === that.aiUserForm.id);
                that.ruleForm.aiUserCertificateList[index] = that.aiUserForm;
              }
              that.ruleForm.aiUserCertificateList.push(that.aiUserForm)
            /*  that.ruleForm.aiUserCertificateList.forEach((item, index) => {
                if (item.xId == that.aiUserForm.xId) {
                  that.ruleForm.aiUserCertificateList.splice(
                    index,
                    1,
                    that.aiUserForm
                  );
                  that.aiUserVisible = false;
                }
              });*/
              that.aiUserVisible = false;
            }
          } else {
            that.$message({
              message: "请上传一张证书图片",
              type: "warning",
            });
          }
        }
      });
    },
    // 证书类型
    getCertificateTypeList() {
      getDictData("certificateType").then((res) => {
        if (res.code == 0) {
          this.certificateTypeList = res.data;
        }
      });
    },
    // 证书的新增一行
    handleAdd() {
      this.aiUsertitle = "新增";
      this.aiUserVisible = true;
      this.aiUserForm = {};
      // this.$refs.CertificatePic.clearFiles()
      this.aifileList = [];
    },
    // 删除
    deleteRow(index, row) {
      this.ruleForm.aiUserCertificateList.splice(index, 1);
    },
    //修改
    updateRow(index, row) {
      console.log(index, row);
      this.aiUsertitle = "修改";
      this.aiUserVisible = true;
      this.aiUserForm = { ...row };
      this.aiUserForm.valuedate = [row.expirationStart,row.expirationEnd];

      this.aiUserCertificate = [];
      if (this.aifileList.length == 0) {
        if (row.file !== "") {
          this.aifileList.push({
            // name: res.data.fileSourceName,
            url: row.file,
          });
        }
      } else {
        this.aifileList = [];
        if (row.file !== "") {
          this.aifileList.push({
            // name: res.data.fileSourceName,
            url: row.file,
          });
        }
      }
    },
    //照片的change事件
    setingGurl(file) {
      if (this.fileList.length >= 1) {
        this.fileList = [];
      }
      this.fileList.push(file);
    },
    // idCardMainImage , idCardBackImage
    MainImgonChange(file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
        this.imgParams.file = file.row;
      }
      this.imgParams.file = file.raw;
      // this.ruleForm.idCardMainImage = file.url
      if (this.MainfileList.length > 0 || fileList.length > 0) {
        this.$nextTick(() => {
          let ele = document.getElementsByClassName(
            "el-upload--picture-card"
          )[1];
          ele.style.display = "none";
        });
      } else {
        this.$nextTick(() => {
          let ele = document.getElementsByClassName(
            "el-upload--picture-card"
          )[1];
          ele.style.display = "block";
        });
      }
    },
    MainImgonChangePic(file, fileList){
      if (fileList.length > 1) {
        fileList.splice(0, 1);
        this.imgData.file = file.row;
      }
      this.imgData.file = file.raw;
      if (this.fileList.length > 0 || fileList.length > 0) {
        this.$nextTick(() => {
          let ele = document.getElementsByClassName(
              "el-upload--picture-card"
          )[0];
          ele.style.display = "none";
        });
      } else {
        this.$nextTick(() => {
          let ele = document.getElementsByClassName(
              "el-upload--picture-card"
          )[0];
          ele.style.display = "block";
        });
      }
    },
    BackImgonChange(file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
        this.imgParams.backFile = file.row;
      }
      this.imgParams.backFile = file.raw;
      // this.ruleForm.idCardBackImage = file.url
      if (this.BackfileList.length > 0 || fileList.length > 0) {
        this.$nextTick(() => {
          let ele = document.getElementsByClassName(
            "el-upload--picture-card"
          )[2];
          ele.style.display = "none";
        });
      } else {
        this.$nextTick(() => {
          let ele = document.getElementsByClassName(
            "el-upload--picture-card"
          )[2];
          ele.style.display = "block";
        });
      }
    },
    backFileRemove(file, fileList) {
      this.$nextTick(() => {
        let ele = document.getElementsByClassName("el-upload--picture-card")[2];
        ele.style.display = "block";
      });
      this.imgParams.backFile = null;
    },
    fileRemove(file, fileList) {
      this.$nextTick(() => {
        let ele = document.getElementsByClassName("el-upload--picture-card")[1];
        ele.style.display = "block";
      });
      this.imgParams.file = null;
    },
    fileRemovePic(file, fileList) {
      this.$nextTick(() => {
        let ele = document.getElementsByClassName("el-upload--picture-card")[0];
        ele.style.display = "block";
      });
      this.imgData.file = null;
    },
    // 证书添加弹窗关闭
    aiUsercolse() {
      this.aiUserVisible = false;
      this.aiUserForm = {};
      this.aifileList = [];
    },

    //删除用户头像
    userImgRemove(file, fileList) {
      this.$confirm("确认删除吗？", "提示", {}).then(() => {
        this.$nextTick(() => {
          let ele = document.getElementsByClassName(
            "el-upload--picture-card"
          )[0];
          ele.style.display = "block";
        });
        this.$api.user.deletePic({ id: this.dataForm.id }).then((res) => {
          this.editLoading = false;
          if (res.code == 200) {
            this.$message({ message: "操作成功", type: "success" });
          } else {
            this.$message({ message: "操作失败, " + res.msg, type: "error" });
          }
        });
      });
    },
    //考试培训的表格数据
    aiEduExamRecordmyPagecl(type, id) {
      const obj = {
        pageNum: 1,
        pageSize: 99999,
        examType: type,
        userId: id,
      };
      aiEduExamRecordmyPage(obj).then((res) => {
        if (res.code == 200) {
          if (type == 1) {
            this.tableDataK = res.data.content;
          } else if (type == 2) {
            this.tableDataP = res.data.content;
          }
        }
      });
    },
    // 证书的删除图片
    aiUserCertificateListRemove(file, fileList) {},
    aiUserCertificateListBefore(file, fileList) {
      // console.log(file, fileList, "befor")
    },
    aiUserCertificateListchange(file, fileList) {
      console.log(file, fileList, "change");
      this.aiUserCertificate.push(file.raw);
    },
    // 上传成功
    aiUserCertificateListSuccess(response, file, fileList) {
      console.log(response, file, fileList, "success");

      if (response.code == 200) {
        this.aiUserForm.file = response.data.path;
        this.aiUserForm.imgUrlpic = file.url;
        if (this.aiUsertitle == "新增") {
          this.aiUserForm.xId = this.ruleForm.aiUserCertificateList.length + 1;
          // this.$refs.CertificatePic.submit()
          this.ruleForm.aiUserCertificateList.push(this.aiUserForm);
          this.aiUserVisible = false;
        } else if (this.aiUsertitle == "修改") {
          this.ruleForm.aiUserCertificateList.forEach((item, index) => {
            if (item.xId == this.aiUserForm.xId) {
              this.ruleForm.aiUserCertificateList.splice(
                index,
                1,
                this.aiUserForm
              );
              this.aiUserVisible = false;
              // this.$refs.CertificatePic.submit()
            }
          });
        }
      }
    },
    // 获取班组字典
    getTeamTypes() {
      this.$api.user.findAll("led_group").then((res) => {
        this.LedteamAll = res.data;
      });
    },
    getDictDatas() {
      let label = "unitType";
      this.unitTypeData = [];
      getDictData(label).then((res) => {
        if (res.code == 0) {
          res.data.forEach((element) => {
            this.unitTypeData.push({
              label: element.label,
              value: element.code,
            });
          });
        }
      });
    },
    // 获取工种字典
    getJobTypes() {
      let label = "jobType";
      this.jobTypes = [];
      getDictData(label).then((res) => {
        if (res.code == 0) {
          res.data.forEach((element) => {
            this.jobTypes.push({
              label: element.label,
              value: element.label,
            });
          });
        }
      });
      // this.$api.user.findAll("jobType").then((res) => {
      //   console.log(res);
      //   if (!this.isDalian) {
      //     // 如果是重庆，工种是没有联动的
      //     this.jobTypes = res.data;
      //   }

      //   let managerList = res.data;
      //   let specialOperationPersonnelList = []; //特种
      //   let generalOperationPersonnelList = []; //一般
      //   res.data.forEach((i) => {
      //     if (i.correlation == "specialOperationPersonnel") {
      //       specialOperationPersonnelList.push(i);
      //     } else if (i.correlation == "generalOperationPersonnel") {
      //       generalOperationPersonnelList.push(i);
      //     }
      //   });
      //   this.managerList = managerList;
      //   this.specialOperationPersonnelList = specialOperationPersonnelList;
      //   this.generalOperationPersonnelList = generalOperationPersonnelList;

      //   if (this.isDalian) {
      //     // 这里是一些mounted的逻辑，但是必须在工种后调用
      //     if (this.ruleForm.jobTypeValue) {
      //       if (this.ruleForm.personType == "manager") {
      //         this.ruleForm.personDetailType = "manager";
      //       } else {
      //         let obj = this.managerList.find(
      //           (i) => this.ruleForm.jobTypeValue == i.value
      //         );
      //         if (obj.correlation == "specialOperationPersonnel") {
      //           this.ruleForm.personDetailType = "specialOperationPersonnel";
      //         } else if (obj.correlation == "generalOperationPersonnel") {
      //           this.ruleForm.personDetailType = "generalOperationPersonnel";
      //         } else {
      //           this.ruleForm.personDetailType = "manager";
      //         }
      //       }
      //     }
      //     if (this.ruleForm.personDetailType == "manager") {
      //       this.jobTypes = this.managerList;
      //     } else if (
      //       this.ruleForm.personDetailType == "specialOperationPersonnel"
      //     ) {
      //       this.jobTypes = this.specialOperationPersonnelList;
      //     } else if (
      //       this.ruleForm.personDetailType == "generalOperationPersonnel"
      //     ) {
      //       this.jobTypes = this.generalOperationPersonnelList;
      //     }
      //   }
      // });
    },
    // 单位类型
    getType() {
      const that = this;
      getDictData("unit_type").then((res) => {
        if (res.code == 200) {
          this.unitTypeList = res.data;
          // 判断当前用户属于分包单位，通过单位类型数组查找当前单位的单位类型
          if (that.subUnitType.includes(that.userDeptUnitType)) {
            that.unitTypeList.forEach((i) => {
              if (i.value == that.idUsetList.unitType) {
                that.ruleForm.unitTypeName = i.label;
              }
            });
          }
        }
        // this.findPage()
      });
    },
    // 获取政治面貌
    getpoliticalIdentity() {
      getDictData("political_identity").then((res) => {
        this.identity = res.data;
      });
    },
    // 获取岗位职称下拉列表
    postidlistc(id) {
      postidlist(id).then((res) => {
        if (res.code == 200) {
          this.postidlistcList = res.data;
        }
      });
    },
    // 获取所属单位信息
    findUnitTreeLit() {
      this.$api.dept.findUserDeptTree().then((res) => {
        if (res.code == 200) {
          console.log(res);
          // if (sessionStorage.getItem("roleType") != "role_dalian") {
          // this.deptData = res.data
          let nn = [];
          res.data.forEach((item) => {
            getdata(item);
          });
          //先找出是单位的数据  此时出来的是一个二维数组
          function getdata(item) {
            if (item.unitDept == "unit") {
              nn.push(item);
            }
            if (item.children !== null) {
              item.children.forEach((data) => {
                getdata(data);
              });
            }
          }
          let mm = []; //1
          let ll = []; //下级
          nn.map((item) => {
            item.children = [];
            if (item.parentId == 0) {
              item.disabled = true;
              mm.push(item);
            } else {
              ll.push(item);
            }
          });
          // 判断最顶级没有数据的时候直接渲染下级数据，否则循环遍历将下级元素匹配到对应的上级children里面
          if (mm.length > 0) {
            mm.map((item) => {
              ll.forEach((i) => {
                if (item.id == i.parentId) {
                  item.children.push(i);
                }
                if (i.children !== null) {
                  pp(i, i.children);
                }
              });
            });
          } else {
            mm = ll;
          }
          function pp(i, item) {
            item.forEach((index) => {
              if (i.id == index.parentId) {
                item.children.push(index);
              }
            });
          }
          this.deptData = mm;
          // } else {
          //   this.deptDataDepartment = res.data
          //   this.deptData = res.data
          // }
        }
      });
    },
    // 特种作业人员change
    changeSwith(val) {
      this.swithNum = val;
    },
    rolsechange(val) {
      console.log(val, this.roleList);
    },
    // 弹窗提交
    submitForm(formName) {
      console.log(this.ruleForm);
      this.$confirm("确认提交吗？", "提示", {}).then(() => {
        this.editLoading = true;
        if (this.swithNum == "1") {
          this.istrue = true;
        } else {
          this.istrue = false;
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.ruleForm.jobTypeLabel) {
              this.jobTypes.forEach((item, index) => {
                if (this.ruleForm.jobTypeLabel == item.label) {
                  this.ruleForm.jobTypeValue = item.value;
                }
              });
            } else {
              this.ruleForm.jobTypeValue = "";
            }

            if (this.ruleForm.ledTeamType) {
              this.LedteamAll.forEach((item, index) => {
                if (this.ruleForm.ledTeamType == item.value) {
                  this.ruleForm.ledTeamTypeName = item.label;
                }
              });
            } else {
              this.ruleForm.teamTypeName = "";
            }

            this.ruleForm.personTypeName =
              this.ruleForm.personType == "manager"
                ? "管理人员"
                : this.ruleForm.personType == "operationPersonnel"
                ? "作业人员"
                : "";
            this.ruleForm.personDetailTypeName =
              this.ruleForm.personDetailType == "specialOperationPersonnel"
                ? "特种作业人员"
                : this.ruleForm.personType == "generalOperationPersonnel"
                ? "一般作业人员"
                : "";

            console.log(this.ruleForm.userRoles, "this.ruleForm.userRoles");
            let roles = [];
            this.ruleForm.userRoles.forEach((item) => {
              if (this.editorType == "Leditor") {
                roles.push({
                  userId: this.ruleForm.id,
                  roleId: item,
                });
              } else {
                roles.push({
                  roleId: item,
                });
              }
            });
            this.ruleForm.userRoles = roles;
            console.log(this.ruleForm.userRoles);
            // let len = this.ruleForm.userRoles
            // let that = this
            // let userRolesL = []
            // for (let i = 0; i < len.length; i++) {
            //   nn(len[i])
            //   function nn(item) {
            //     if (item instanceof Object) {
            //       nn(item.roleId)
            //     } else {
            //       let userRoleO = {
            //         userId: that.ruleForm.id,
            //         roleId: item
            //       }
            //       userRolesL.push(userRoleO)
            //     }
            //   }
            // }
            // this.ruleForm.userRoles = userRolesL

            let params = Object.assign({}, this.ruleForm);
            this.unitList.forEach(item => {
              if(item.uuid === this.ruleForm.unitId){
                params.unitId = item.id;
              }
            })
            userSave(params).then((res) => {
              if (res.code == 200) {
                this.imgData.id = res.data.id;
                if (
                  this.imgParams.file == null &&
                  this.imgParams.backFile == null
                ) {
                } else {
                  this.getImg(res.data.id);
                }
                if(this.imgData.file == null){

                }else{
                  this.uploadPicImg(res.data.id);
                }
                // 手签名保存
                if (this.signatureImgUrl) {
                  this.setSignatureImgObj(res.data.id);
                }
                // this.$refs.userImg.submit();
                this.$message({ message: "操作成功", type: "success" });
                this.$emit("downLog", "down");
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            });
          } else {
            return false;
          }
        });
      });
    },
    // 手签名文件保存
    setSignatureImgObj(id) {
      const obj = {
        imgBase64: this.signatureImgUrl,
        userId: id,
      };
      let fromData = new FormData();

      for (let key in obj) {
        fromData.append(key, obj[key]);
      }
      console.log(fromData, "签名");
      this.$api.user.uploadSignImg(fromData).then((res) => {
        if (res.code == 200) {
          console.log(7677);
        }
      });
    },
    getImg(id) {
      this.imgParams.userId = id;
      let fromData = new FormData();
      for (let key in this.imgParams) {
        fromData.append(key, this.imgParams[key]);
      }
      console.log(fromData, "身份证");
      uploadIdCardImg(fromData).then((res) => {
        if (res.code == 200) {
        }
      });
    },
    uploadPicImg(id) {
      this.imgData.userId = id;
      let fromData = new FormData();
      for (let key in this.imgData) {
        fromData.append(key, this.imgData[key]);
      }
      console.log(fromData, "照片");
      uploadPicImg(fromData).then((res) => {
        if (res.code == 200) {
        }
      });
    },
    // 签名的点击事件
    signatureClick() {
      this.signatureVisible = true;
    },
    handleSignature() {
      this.signatureVisible = false;
    },
    // 接受手签名流文件
    setImgUrl(Base64) {
      if (Base64) {
        // 用于给后端储存
        this.signatureImgUrl = Base64;
      }
      // 关闭弹框
      this.signatureVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//   @import "../../assets/style/userList.scss";
@import "../../../assets/style/userList.scss";

// @import url("../../assets/style/userList.scss");
// ~assets/style/userList.scss
.signatureClass {
  width: 200px;
  height: 40px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}
img {
  display: inline-block;
}
.page-container {
  /* flex: 1; */
  height: calc(100% - 46px);
  margin: 10px 20px 0;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.nameButton {
  display: flex;
  justify-content: center;
}
.clickable {
  color: #7073FF;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* font-weight: bold; */
}
</style>
