<template>
  <li
    class="zz-menu-item"
    :class="{ 'is-active': active, 'mr-4': item.parentId == 0 }"
    @click="handleClick"
  >
    <a href="javascript:;" class="menu-link">
      <div class="first-leave__title is-hover" v-if="item.parentId == 0">
        {{ item.resourceName }}
      </div>
      <div class="menu__title is-hover" v-else>
        {{ item.resourceName }}
      </div>
    </a>
  </li>
</template>
<script>
export default {
  name: "menu-item",
  inject: ["rootMenu"],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    level: {
      type: Number,
      default: 0,
    },
    paddingLeft: {
      type: Number,
      default: 20,
    },
  },
  computed: {
    active() {
      return this.rootMenu.activeIds.includes(this.item.id);
    }
  },
  methods: {
    handleClick() {
      this.rootMenu.handleItemClick(this.item);
      this.$router.push({ path: this.item.resourcePath });
    },
  },
};
</script>
<style lang="scss" scoped>
.zz-menu-item {
  width: 160px;
}
.first-leave__title {
  margin-top: 10px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 40px;
  background: #0b1b4f;
  border: 1px solid #173f58;
}
.menu__title {
  text-align: center;
  padding: 10px 0;
  color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 46, 117, 1);
  border-bottom: 1px solid rgba(23, 63, 88, 1);
}
.is-active {
  border: none;
  // background-image: url(../../assets/screen-active.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  .menu__title {
    border: none !important;
  }
}
.is-hover {
  &:hover {
    background: rgba(22, 70, 130, 1);
    border: 1px solid #5AE5F3;
  }
}
</style>
