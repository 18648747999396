<template>
  <systemModal
    ref="formModal"
    @modalConfirm="handleSubmit"
    @modalClose="handleModalClose"
    :title="title"
    :width="screenWidth"
  >
    <el-form
      v-loading="loading"
      :model="model"
      :rules="rules"
      ref="modelForm"
      label-width="120px"
      class="demo-model"
      style="width: 90%"
    >
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="摄像头名称" prop="name">
            <el-input
              v-model="model.name"
              placeholder="请输入摄像头名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="所属工点" prop="lineSite">
            <el-cascader
              v-model="model.lineSite"
              clearable
              :options="treeData"
              :props="props"
              :show-all-levels="false"
              collapse-tags
              @change="handleLineSiteChange"
            ></el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="类型" prop="type">
            <el-select v-model="model.type" placeholder="请选择类型">
              <el-option
                v-for="item of dict.type"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="状态" prop="status">
            <el-select v-model="model.status" placeholder="请选择状态">
              <el-option
                v-for="item of dict.status"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="IP">
            <el-input
              v-model="model.ipAddress"
              placeholder="请输入IP"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="通道">
            <el-input
              v-model="model.channel"
              placeholder="请输入通道"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="端口">
            <el-input v-model="model.port" placeholder="请输入端口"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="用户名">
            <el-input
              v-model="model.userName"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="密码">
            <el-input
              v-model="model.userPwd"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="公网流地址">
            <el-input
              v-model="model.flowLink"
              placeholder="请输入可公网访问的流地址"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan">
          <el-form-item label="flv流地址">
            <el-input
              v-model="model.flv"
              placeholder="请输入flv流地址"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="hls流地址">
            <el-input
              v-model="model.hls"
              placeholder="请输入hls流地址"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan" v-if="isEdit">
          <el-form-item label="中铁获取直播请求地址">
            <el-input
              v-model="model.videoAddress"
              placeholder="请输入中铁获取直播请求地址"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="colSpan" v-if="isEdit">
          <el-form-item label="中铁登录请求地址">
            <el-input
              v-model="model.loginAddress"
              placeholder="请输入中铁登录请求地址"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan" v-if="isEdit">
          <el-form-item label="数据来源">
            <el-select v-model="model.sourceId" placeholder="请选择数据来源">
              <el-option
                v-for="item of dict.source"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="colSpan" v-if="isEdit">
          <el-form-item label="是否可控制">
            <el-radio-group v-model="model.canContr">
              <el-radio :label="0">不可控制</el-radio>
              <el-radio :label="1">可控制</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan" v-if="isEdit">
          <el-form-item label="X坐标">
            <el-input
              v-model="model.coordX"
              placeholder="请输入X坐标"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="colSpan" v-if="isEdit">
          <el-form-item label="Y坐标">
            <el-input
              v-model="model.coordY"
              placeholder="请输入Y坐标"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="colSpan" v-if="isEdit">
          <el-form-item label="记录时间">
            <el-input
              v-model="model.created"
              placeholder="请输入记录时间"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="colSpan">
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="model.remark"
              type="textarea"
              :autosize="{ minRows: 2 }"
              placeholder="请输入备注"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </systemModal>
</template>

<script>
import systemModal from "@/components/Modal/systemModal.vue";
import { queryDictByLabel, queryDictNodesById } from "@/api/dict";
import { lineSiteTreeCascadeEchoByUuid } from "@/utils/common";
import { addVideo, updateVideo } from "@/api/map";
export default {
  components: {
    systemModal,
  },
  data() {
    return {
      title: "新增",
      screenWidth: "30%",
      colSpan: 24,
      isEdit: false,
      model: {
        lineSite: [],
        siteId: "",
        name: "",
        type: "",
        status: "",
        ipAddress: "",
        channel: "",
        port: "",
        userName: "",
        userPwd: "",
        flowLink: "",
        flv: "",
        hls: "",
        videoAddress: "",
        loginAddress: "",
        sourceId: "",
        canContr: "",
        coordX: "",
        coordY: "",
        created: "",
        remark: "",
      },
      props: {
        children: "children",
        label: "name",
        value: "uuid",
      },
      dict: {
        type: [],
        status: [],
        source: [
          {
            label: "中铁",
            value: 0,
          },
          {
            label: "广联达",
            value: 1,
          },
        ],
      },
      loading: false,
      rules: {
        lineSite: [
          { required: true, message: "请选择线路工点", trigger: "change" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
        name: [
          { required: true, message: "请输入摄像头名称", trigger: "blur" },
        ],
      },
      flatData: [],
    };
  },
  props: {
    treeData: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.getDictData("摄像头类型", this.dict.type);
    this.getDictData("摄像头状态", this.dict.status);
  },
  methods: {
    handleAdd(record) {
      this.$nextTick(() => {
        this.$refs["modelForm"].resetFields();
      });
      // model初始化
      this.model = {
        lineSite: [],
        siteId: "",
        name: "",
        type: "",
        status: "",
        ipAddress: "",
        channel: "",
        port: "",
        userName: "",
        userPwd: "",
        flowLink: "",
        flv: "",
        hls: "",
        videoAddress: "",
        loginAddress: "",
        sourceId: "",
        canContr: "",
        coordX: "",
        coordY: "",
        created: "",
        remark: "",
      };
      if (record) {
        this.title = "编辑";
        this.screenWidth = "60%";
        this.colSpan = 12;
        this.isEdit = true;
        this.model = Object.assign({}, JSON.parse(JSON.stringify(record)));
        if (this.model.type) {
          this.model.type = Number(this.model.type);
        }
        if (this.model.status) {
          this.model.status = Number(this.model.status);
        }
        // this.model.status = Number(this.model.status);
        const lineSite = lineSiteTreeCascadeEchoByUuid(record.siteId);
        this.$set(this.model, "lineSite", lineSite);
      } else {
        this.title = "新增";
        this.screenWidth = "30%";
        this.colSpan = 24;
      }
      this.flatArr(this.treeData);
      this.handleEdit();
    },
    flatArr(record) {
      for (let item of record) {
        this.flatData.push(item);
        if (item.children) {
          this.flatArr(item.children);
        }
      }
    },
    handleEdit() {
      this.$refs.formModal.handleOpen();
    },
    handleLineSiteChange(val) {
      this.model.siteId = val[val.length - 1];
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          // 验证通过
          const params = JSON.parse(JSON.stringify(this.model));
          const site = this.flatData.find((item) => item.uuid == params.siteId);
          params.siteName = site.name;
          if (this.model.id) {
            this.update(params);
          } else {
            this.add(params);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async add(record) {
      console.log(record);
      this.loading = true;
      let res = await addVideo(record);
      if (res.code == 0) {
        this.$message.success("操作成功");
        this.loading = false;
        this.$emit("formSubmit");
        this.handleModalClose();
        this.$refs.formModal.handleClose();
      } else {
        this.loading = false;
      }
    },
    async update(record) {
      console.log(record);
      this.loading = true;
      let res = await updateVideo(record);
      if (res.code == 0) {
        this.$message.success("操作成功");
        this.loading = false;
        this.$emit("formSubmit");
        this.handleModalClose();
        this.$refs.formModal.handleClose();
      } else {
        this.loading = false;
      }
    },
    getDictData(label, target) {
      queryDictByLabel({ label }).then((res) => {
        if (res.code == 0) {
          const dictId = res.data.id;
          queryDictNodesById({ id: dictId }).then((res) => {
            if (res.code == 0) {
              Object.assign(target, res.data);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 清空el表单
    handleModalClose() {
      this.model = {
        lineSite: [],
        label: "",
        monitorType: "",
        state: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
