<template>
  <div class="page-container p-4 h-full">
    <div
      class="search-wrapper"
      v-loading="loading"
      element-loading-text="数据加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
    >
      <div class="table-search">
        <el-form
          :inline="true"
          :model="queryModal"
          class="flex justify-between"
        >
          <el-row>
            <el-form-item>
              <el-input
                v-model="queryModal.teamName"
                clearable
                placeholder="输入队伍名称查询"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">查询</el-button>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="teamName"
          label="名称"
          width="250"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="optionUnitName"
          label="所属承包部"
          width="180"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="teamStation"
          label="队伍驻地"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="rescueScope"
          label="救援区域"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="numOfPeople"
          label="人数"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="captainName"
          label="负责人"
          width="100"
        ></el-table-column>
        <el-table-column align="center" label="坐标" width="250">
          <template slot-scope="scope" v-if="scope.row.lng && scope.row.lat">
            {{ scope.row.lng + '，' + scope.row.lat }}
          </template>
          <template slot-scope="scope" v-else>
            {{ scope.row.lng + '，' + scope.row.lat }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="captainPhone"
          label="联系方式"
          width="150"
        ></el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.count"
        :page-count="page.total"
        :page-size="page.pageSize"
        :page-sizes="[10, 50, 100, 200]"
        :current-page="page.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        @prev-click="handleCurrentChange"
        @next-click="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getSoleDuty, delSoleDuty } from '@/api/eres'

export default {
  data() {
    return {
      type: '',
      queryModal: {
        teamName: '',
      },
      tableData: [],
      loading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    handleSearch() {
      this.loadData()
    },
    handleDownload() {
      console.log('下载导入模板')
    },
    handleImport() {
      console.log('导入')
    },
    handleExport() {
      console.log('导出')
    },
    MemberList(row) {
      this.$router.push({
        path: '/System/Personnel/MemberList',
        query: {
          id: row.id,
          name: row.teamName,
          type: 'SoleDuty',
        },
      })
    },
    MaterialList() {
      this.$router.push('/System/Personnel/MaterialList')
    },
    EquipmentList() {
      this.$router.push('/System/Personnel/EquipmentList')
    },
    handleAdd() {
      this.$refs.modelForm.handleAdd(this.type)
    },
    handleEdit(record) {
      this.$refs.modelForm.handleAdd(this.type, record)
    },
    handleDelete(record) {
      delSoleDuty(record.id).then((res) => {
        if (res.code == 0) {
          this.$message.success('操作成功！')
          this.loadData()
        }
      })
    },
    // 分页器事件
    handleCurrentChange(val) {
      this.page.pageNo = val
      this.loadData()
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.loadData()
    },
    handleFormSubmit() {
      this.loadData()
    },
    loadData() {
      this.loading = true
      const params = {
        page: this.page.pageNo,
        size: this.page.pageSize,
        teamName: this.queryModal.teamName ? this.queryModal.teamName : '-',
        teamType: 1,
      }
      getSoleDuty(params).then((res) => {
        if (res.code == 0) {
          const { current, size, total, count } = res.data
          this.tableData = res.data.records ? res.data.records : []
          this.page.pageNo = current
          this.page.pageSize = size
          this.page.total = total
          this.page.count = count
          this.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 1;
}
.table-wrapper {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}
.table-search {
  ::v-deep .el-input__inner {
    background: #18467f;
    border: none;
    color: #fff;
  }
}
::v-deep .el-table {
  background: transparent;

  &::before {
    background-color: #143363;
  }

  thead {
    color: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: rgba(20, 51, 99, 0.5);
  }

  tr,
  th {
    background-color: transparent;
  }

  td {
    border-bottom: 1px solid #143363;
  }

  .cell {
    color: #fff;
  }

  th.is-leaf {
    border: 1px solid #143363;
  }
}

::v-deep .el-table--border {
  border: 1px solid #143363;

  &::after {
    background-color: #143363;
  }

  td {
    border-right: 1px solid #143363;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: rgba(0, 46, 117, 0.4);
}
::v-deep .el-input__inner {
  background: #18467f;
  color: #fff;
  border: none;
}
</style>
