<template>
  <!-- 隐患任务 -->
  <div class="person-statistics-container">
    <index-box :title="title">
      <div class="person-statistics-wrapper">
        <el-radio-group
          v-for="(item, index) in radioData"
          :key="index"
          v-model="radio"
          @change="radioChange"
          style="margin-right:10px"
        >
          <el-radio style="margin-right:10px" :label="item.value">{{
            item.label
          }}</el-radio>
        </el-radio-group>

        <el-date-picker
          size="mini"
          v-show="radio == 1"
          v-model="weekDate"
          type="week"
          format="yyyy 第 WW 周"
          placeholder="选择周"
          @change="timeDateChangeWeek"
        >
        </el-date-picker>
        <el-date-picker
          size="mini"
          v-show="radio == 2"
          v-model="monthDate"
          type="month"
          placeholder="选择月"
          value-format="yyyy-MM"
          @change="timeDateChangeMonth"
        >
        </el-date-picker>
      </div>
      <div class="text">
        <div
          class="text-content"
          v-for="(item, index) in textData"
          :key="index"
          @click="manageClick(item)"
        >
          <p
            class="contentList"
            :style="{
              color:
                item.name == '完成任务' || item.name == '超时完成'
                  ? '#1791FF'
                  : '#f0af41',
            }"
          >
            {{ item.name }}:{{ item.count }}
          </p>
        </div>
      </div>
    </index-box>
    <HomePageDialog
      ref="modalTable"
      :titleTable="titleTable"
      :columns="columns"
      :tableList="tableList"
    ></HomePageDialog>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import HomePageDialog from "@/views/HiddenDanger/components/HomePageDialog.vue";
// 完成任务
import { getHiddenTask, getHiddenTaskList, getOverList } from "@/api/hdanger";
export default {
  components: {
    IndexBox,
    HomePageDialog,
  },
  data() {
    return {
      title: "隐患任务",
      radio: "1",
      weekDate: "",
      monthDate: "",
      radioData: [
        {
          value: "1",
          label: "周",
        },
        {
          value: "2",
          label: "月",
        },
      ],
      textData: [
        {
          name: "完成任务",
          count: 1668,
        },
        {
          name: "完成率",
          count: "100%",
        },
        {
          name: "超时完成",
          count: 3,
        },
        {
          name: "超时率",
          count: "0.18%",
        },
      ],
      titleTable: "",
      columns: [
        { prop: "lineName", label: "线路", minWidth: 80 },
        { prop: "fulfillCount", label: "完成任务次数", minWidth: 80 },
        { prop: "haveHiddenCount", label: "上报隐患任务次数", minWidth: 80 },
        { prop: "cashCount", label: "任务兑现率", minWidth: 80 },
        { prop: "hiddenCount", label: "上报隐患", minWidth: 80 },
      ],
      tableList: [],
      weekTime: "",
    };
  },
  mounted() {
    let time = new Date();
    this.weekDate = time;
    this.timeDateChangeWeek(time);
  },
  methods: {
    manageClick(data) {
      if (this.radio == 1) {
        this.titleTable = data.name + "完成情况" + "(" + this.weekTime + ")";
      } else {
        this.titleTable = data.name + "完成情况" + "(" + this.monthDate + ")";
      }
      this.$refs.modalTable.handleOpen("right", "隐患任务");
      let param = {};
      if (this.radio == 1) {
        param.weekStar = this.weekTime.split("-")[0];
        param.weekEnd = this.weekTime.split("-")[1];
        param.month = this.monthDate.split("-")[1];
      } else if (this.radio == 2) {
        param.month = this.monthDate.split("-")[1];
      }

      if (data.name == "完成任务") {
        getHiddenTaskList(param).then((res) => {
          if (res.code == 0) {
            this.tableList = res.data;
          }
        });
      } else if (data.name == "超时完成") {
        getOverList(param).then((res) => {
          if (res.code == 0) {
            this.tableList = res.data;
          }
        });
      }
    },
    radioChange(val) {
      this.weekDate = "";
      this.monthDate = "";
      this.textData.forEach((item) => {
        item.count = 0;
      });
      console.log("数据", val);
    },
    getWeek(val) {
      let firstDay = new Date(val.getFullYear(), 0, 1);
      let dayOfWeek = firstDay.getDay(); // 2020-01-01是周三  dayOfWeek==3
      let spendDay = 1;
      if (dayOfWeek != 0) {
        spendDay = 7 - dayOfWeek + 1; // 5 离下周一还有5天
      }
      firstDay = new Date(val.getFullYear(), 0, 1 + spendDay); // 2020-01-06是2020年第一个周一，是2020年第二个周
      let d = Math.ceil((val.valueOf() - firstDay.valueOf()) / 86400000); // 当前时间距离2020-01-06有几天
      let result = Math.ceil(d / 7); // 换算成周为单位 2020-01-06result是0,但其实是第二个周，所以默认加2
      let week = result + 2; //如果使用的是默认为加2（如果将自然周设置为周一到周日则是加1）
      console.log(
        this.dataFomat(val.valueOf() - 86400000) +
          "-" +
          this.dataFomat(val.valueOf() + 5 * 86400000) +
          " 第" +
          week +
          "周 "
      );
      return (
        this.dataFomat(val.valueOf() - 86400000) +
        "-" +
        this.dataFomat(val.valueOf() + 5 * 86400000)
        /* +" 第" +week +  "周 " */
      );
    },
    dataFomat(time) {
      const date = new Date(time);

      const H = date.getFullYear();

      const M = (date.getMonth() + 1).toString().padStart(2, 0);

      const D = date
        .getDate()
        .toString()
        .padStart(2, 0);
      return `${H}.${M}.${D}`;
    },
    // 周
    timeDateChangeWeek(val) {
      this.monthDate = "";
      val.getTime();
      this.weekTime = this.getWeek(val);
      let param = {
        categoryName: "",
        categoryPName: "",
        hiddenLevel: "",
        workAreaNameList: [],
      };
      param.weekStar = this.weekTime.split("-")[0].replaceAll(".", "-");
      param.weekEnd = this.weekTime.split("-")[1].replaceAll(".", "-");
      param.month = "";

      getHiddenTask(param).then((res) => {
        if (res.code == 0) {
          this.textData.forEach((item) => {
            switch (item.name) {
              case "完成任务":
                item.count = res.data.fulfillCount ? res.data.fulfillCount : 0;
                break;
              case "完成率":
                item.count =
                  res.data.fulfillRate == null ? 0 : res.data.fulfillRate + "%";
                break;
              case "超时完成":
                item.count = res.data.overTimeCount
                  ? res.data.overTimeCount
                  : 0;
                break;
              case "超时率":
                item.count =
                  res.data.overTimeRate == null
                    ? 0
                    : res.data.overTimeRate + "%";
                break;
            }
          });
        }
      });
    },
    // 月
    timeDateChangeMonth(val) {
      this.weekDate = "";
      let param = {
        categoryName: "",
        categoryPName: "",
        hiddenLevel: "",
        workAreaNameList: [],
      };
      param.weekStar = "";
      param.weekEnd = "";
      param.month = val.split("-")[1];
      getHiddenTask(param).then((res) => {
        if (res.code == 0) {
          this.textData.forEach((item) => {
            switch (item.name) {
              case "完成任务":
                item.count = res.data.fulfillCount ? res.data.fulfillCount : 0;
                break;
              case "完成率":
                item.count =
                  res.data.fulfillRate == null ? 0 : res.data.fulfillRate + "%";
                break;
              case "超时完成":
                item.count = res.data.overTimeCount
                  ? res.data.overTimeCount
                  : 0;
                break;
              case "超时率":
                item.count =
                  res.data.overTimeRate == null
                    ? 0
                    : res.data.overTimeRate + "%";
                break;
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../style/elTable.scss";
.person-statistics-container {
  height: 100%;
  ::v-deep .el-date-editor {
    width: 165px;
  }
  .text {
    width: 100%;
    height: calc(100% - 38px);
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    .text-content {
      width: 50%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .contentList {
      font-size: 14px;
      font-weight: bold;
    }
    .text-content:nth-child(1),
    .text-content:nth-child(2) {
      border-bottom: 1px solid rgba(#ffffff, 0.1);
    }
    .text-content:nth-child(1),
    .text-content:nth-child(3) {
      border-right: 1px solid rgba(#ffffff, 0.1);
      cursor: pointer;
    }
  }
}
</style>
