<template>
  <div class="quoted">
    <el-form
      :model="formInline"
      class="demo-form-inline"
      :label-position="labelPosition"
      label-width="100px"
      :rules="rules"
       ref="ruleForm"
    >
      <el-form-item label="事件名称:"  prop="responseRecordName">
        <el-input
          type="textarea"
          v-model="formInline.responseRecordName"
        ></el-input>
      </el-form-item>
      <el-form-item label="事件等级:"  prop="responseLevle">
        <el-select
          v-model="formInline.responseLevle"
          placeholder="请选择"
          :popper-append-to-body="false"
          class="respon"
          @change="reserns"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.label"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="事发经过:" prop="historyRecord">
        <el-input
          type="textarea"
          v-model="formInline.historyRecord"
          :autosize="{ minRows: 4, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="伤亡情况:" prop="casualties">
        <el-input
          type="textarea"
          v-model="formInline.casualties"
          :autosize="{ minRows: 4, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="处置情况:" prop="disposal">
        <el-input
          type="textarea"
          v-model="formInline.disposal"
          :autosize="{ minRows: 4, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="照片或附件:">
        <Uploders @uploader="uploader"></Uploders>
      
      </el-form-item>

      <el-form-item >
        <el-button
          type="primary"
          @click="finalreminkss('ruleForm')"
          style="margin-left: 35%; margin-top: 20px"
          >确 定</el-button
        >
        <el-button
          @click="finalterwr"
          style="
            background: none;
            color: #43a5e9;
            border-color: #43a5e9;
            margin-left: 5%;
          "
          >取 消</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { finalQuote } from "@/api/response.js";
import { queryDictByCode } from "@/api/dict.js";
import Uploders from "@/components/Uploader/Uploader.vue";
export default {
  components: { Uploders },
  props: {
    statre: {
      type: Number,
      default: 0
    },
    uuid: {
      type: String
    }
  },
  watch: {
    statre: {
      immediate: true,
      handler(val) {
        console.log(val);
        if (val == 1) {
          this.continues = true;
          this.finalquote = false;
        } else if (val == 2) {
          this.continues = false;
          this.finalquote = true;
        }
      },
      deep: true
    }
  },
  data() {
    return {
      formInline: {},
      labelPosition: "right",
      dialogImageUrl: "",
      dialogVble: false,
      continues: false,
      finalquote: false,
      options: [],
      value: "",
      arr: [],
      user:{},
      rules:{
       responseRecordName:[
          { required: true, message: '请输入', trigger: 'blur' },
          
       ] ,

       responseLevle:[
          { required: true, message: '请选择等级', trigger: 'change' }
       ],
       historyRecord:[
         { required: true, message: '请输入事发经过', trigger: 'blur' },
       ],
       casualties:[
         { required: true, message: '请输入伤亡情况', trigger: 'blur' },
       ],
       disposal:[
         { required: true, message: '请输入处置情况', trigger: 'blur' },
       ]
      },
      arres:{}
    };
  },
  created() {
    queryDictByCode({ code: "currentLevel" }).then(res => {
      console.log(res);
      this.options = res.data;
    });
        this.user = JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
  },
  methods: {
 
    //点击事件等级
    reserns(val) {
      console.log(val);
      this.value = val.label;
      this.formInline.levelName = val.label;
      this.formInline.responseLevle = val.code;
    },
    //事件终报确定和取消
    finalreminkss(formName) {
       this.$refs[formName].validate((valid) =>{
        if(valid){
           this.formInline.responseType = "deadline";
      this.formInline.responseRecordUuid = this.uuid;
      this.formInline.responseProcessName = "事件终报";
        this.formInline.responseUserName=this.user.realName
      this.formInline.responseUserUuid=this.user.uuid
      finalQuote(this.formInline).then(res => {
        if (res.code == 0) {
          this.$message({
            message: "事件终报成功",
            type: "success"
          });
          this.formInline = {};
          this.arr = [];
          this.value = "";
          this.$emit("closeter");
            this.$refs[formName].resetFields();
        }else{
          console.log(11111)
        }
      });
        }
        })
     
    },
    finalterwr() {
      this.formInline = {};
      this.arr = [];
      this.value = "";
      this.$emit("closeter");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVble = true;
    },
    uploader(val) {
      console.log(val)
       this.arres.originalName=val.originalName
      this.arres.uri=val.uri
    
     let arr=[]
     arr.push(this.arres)
      this.formInline.enclosure=JSON.stringify(arr)
      
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-form--inline .el-form-item {
  width: 400px;
}
::v-deep .el-form--inline .el-form-item__content {
  width: 320px;
}
::v-deep .el-upload-dragger {
  width: 182px;
  height: 159px;
  margin-left: 40px;
}
.dialog-footer {
  .el-button {
    z-index: 99;
  }
}

</style>
