<template>
  <div>
    <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="120px"
    >
      <el-form-item label="工点名称" prop="label">
        <el-input v-model="ruleForm.siteName" disabled></el-input>
      </el-form-item>
      <el-form-item label="培训名称" prop="label">
        <el-input v-model="ruleForm.label" disabled></el-input>
      </el-form-item>
      <el-form-item label="主讲人" prop="label">
        <el-input v-model="ruleForm.teacher" disabled></el-input>
      </el-form-item>
      <el-form-item label="培训地点" prop="label">
        <el-input v-model="ruleForm.trainAddress" disabled></el-input>
      </el-form-item>
      <el-form-item label="计划培训时间" prop="label">
        <el-input v-model="ruleForm.planDate" disabled></el-input>
      </el-form-item>
      <el-form-item label="实际培训时间" prop="label" v-if="ruleForm.state == 6">
        <el-input v-model="ruleForm.trainDate" disabled></el-input>
      </el-form-item>
      <el-form-item label="参与人数" prop="label" v-if="ruleForm.state == 6">
        <el-input v-model="ruleForm.joinCount" disabled></el-input>
      </el-form-item>
      <el-form-item label="上传人" prop="label" v-if="ruleForm.state == 6">
        <el-input v-model="ruleForm.operatorName" disabled></el-input>
      </el-form-item>
      <el-form-item label="上传时间" prop="label" v-if="ruleForm.state == 6">
        <el-input v-model="ruleForm.created" disabled></el-input>
      </el-form-item>
      <el-form-item label="课件" prop="label">
        <div class="file-item" v-for="(item,index) in ruleForm.coursewares" :key="index" @click="downFile(item.link)">
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="附件" prop="label" v-if="ruleForm.state == 6">
        <div class="file-item" v-for="(item,index) in ruleForm.accessories" :key="index" @click="downFile(item.link)">
          {{ item.attrName }}
        </div>
      </el-form-item>
      <el-form-item label="撤回人" prop="label">
        <el-input v-model="ruleForm.currentOperatorName" disabled></el-input>
      </el-form-item>
      <el-form-item label="撤回时间" prop="label">
        <el-input v-model="ruleForm.currentOperationTime" disabled></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {backlogInfo} from "@/api/stand";
import {updateReadNotificationState} from "@/api/notic";

export default {
  name: "planWithdraw",
  props : {
    rowData : {
      default : () => {}
    }
  },
  data(){
    return {
      ruleForm : {}
    }
  },
  created() {
    this.readMessage();
    this.getDetailData();
  },
  methods : {
    readMessage(){
      let params = {
        userId : JSON.parse(localStorage.getItem('user')).uuid,
        messageId : this.rowData.id
      }
      updateReadNotificationState(params).then(res => {
        console.log(res);
      })
    },
    getDetailData(){
      backlogInfo(this.rowData.eventId).then(res => {
        if(res.code == 0){
          this.ruleForm = res.data;
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    downFile(url){
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.file-item{
  color: #1aa3ff;
  cursor: pointer;
}
</style>
