<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="60%"
    top="10vh"
    center
    :before-close="cancle"
  >
    <div class="rule-page">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备型号" prop="name">
              <el-input
                v-model="ruleForm.unitType"
                placeholder="请填写"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规则名称" prop="name">
              <el-input
                v-model="ruleForm.ruleName"
                placeholder="请填写"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" v-for="(item, i) in tableData" :key="i">
            <el-form-item :label="item.label" prop="name">
              <el-input
                class="mr-2"
                style="width:48%"
                v-model="ruleForm.name"
                placeholder="上限预警"
              ></el-input>
              <el-input
                style="width:48%"
                v-model="ruleForm.name"
                placeholder="下限预警"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancle">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
    <!-- s -->
  </el-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    },
    ruleForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      rule: {}
    };
  },
  mounted() {
    // ss
  },
  methods: {
    getTableData() {
      // ss
    },
    cancle() {
      // ss
      this.$emit("closeMainDialog");
    },
    submit() {
      this.$emit("closeMainDialog");
    }
  }
};
</script>

<style lang="scss" scoped>
// s
::v-deep .el-dialog {
  height: 85%;
  .el-dialog__body {
    height: calc(100% - 124px);
    box-sizing: border-box;
  }
  .rule-page {
    height: 100%;
    overflow: auto;
  }
  .dialog-footer {
    text-align: center;
    width: 100%;
  }
}
</style>
