<template>
  <div class="content">
    <div class="info-wrapper">
      <el-table
        :data="tableHeaderData"
        height="110"
        border
        style="width:100%;margin-bottom:20px"
      >
        <el-table-column
          align="center"
          prop="optionUnitName"
          show-overflow-tooltip
          label="所属总承包部"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="teamStation"
          show-overflow-tooltip
          label="队伍驻地"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="rescueScope"
          show-overflow-tooltip
          label="救援区域"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="captainName"
          show-overflow-tooltip
          label="负责人"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="teamTel"
          show-overflow-tooltip
          label="联系方式"
        >
        </el-table-column>
      </el-table>
      <div class="choice">
        <span
          style="margin-right:10px"
          v-for="item in radioData"
          :key="item.value"
        >
          <el-radio v-model="radio" @change="radioChange" :label="item.value">{{
            item.label
          }}</el-radio>
        </span>
      </div>
      <div class="line"></div>
      <el-form :inline="true" :model="libraryForm" class="demo-form-inline">
        <el-form-item v-show="radio == 1">
          <el-input
            v-model="libraryForm.name"
            placeholder="请输入姓名搜索"
          ></el-input>
        </el-form-item>
        <el-form-item v-show="radio == 2 || radio == 3">
          <el-input
            v-model="libraryForm.label"
            placeholder="请输入名称搜索"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="libraryQuery">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :data="tableData" height="380" border style="width: 100%">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="60"
        ></el-table-column>
        <el-table-column
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.minWidth"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="yuyin(scope.row)" type="text" size="small"
              >语音</el-button
            >
            <el-button @click="yuyin(scope.row)" type="text" size="small"
              >视频</el-button
            ><el-button @click="yuyin(scope.row)" type="text" size="small"
              >查看详情</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
    </div>

    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="libraryForm.currentPage"
        :page-size="libraryForm.size"
        layout="prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getWorkers, getMaterialsPage } from "@/api/eres";

export default {
  props: {
    fullTimeTeam: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {
      libraryForm: {
        label: "",
        modelNo: "",
        state: "",
        siteIds: [],
        type: "126",
        currentPage: 1,
        size: 5,
        name: "",
        postId: "-",
        teamId: "",
      },
      tableData: [],
      tableHeaderData: [],
      radio: 1,
      radioData: [
        {
          label: "人员名单",
          value: 1,
        },
        {
          label: "物资清单",
          value: 2,
        },
        {
          label: "设备清单",
          value: 3,
        },
      ],
      total: 0,
      columns: [],
    };
  },
  created() {
    this.getTable();
  },
  mounted() {
    this.tableHeaderData = [];
    this.tableHeaderData.push({
      optionUnitName: this.fullTimeTeam.optionUnitName,
      teamStation: this.fullTimeTeam.teamStation,
      rescueScope: this.fullTimeTeam.rescueScope,
      captainName: this.fullTimeTeam.captainName,
      teamTel: this.fullTimeTeam.teamTel,
    });
    this.getDatalist();
    this.libraryForm.siteIds = [];
    this.libraryForm.siteIds.push(this.fullTimeTeam.siteId);
  },
  methods: {
    getTable() {
      this.columns = [
        { prop: "workerName", label: "姓名", minWidth: 80 },
        { prop: "workRoleName", label: "职务", minWidth: 80 },
        { prop: "workerPhone", label: "联系电话", minWidth: 80 },
        { prop: "workerEducation", label: "学历", minWidth: 80 },
        { prop: "workerSpecialty", label: "专业", minWidth: 80 },
        { prop: "workerImg", label: "照片", minWidth: 80 },
      ];
    },
    getDatalist() {
      this.loading = true;
      const params = {
        teamId: this.fullTimeTeam.id,
        postId: this.libraryForm.postId ? this.libraryForm.postId : "-",
        name: this.libraryForm.name ? this.libraryForm.name : "-",
        page: this.libraryForm.currentPage,
        size: this.libraryForm.size,
      };
      getWorkers(params).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.records ? res.data.records : [];
          this.loading = false;
          this.total = res.data.total;
        }
      });
    },
    getListData(val) {
      this.loading = true;
      const params = {
        materialType: 2,
        siteIds: [],
        type: val,
        state: this.libraryForm.state,
        modelNo: this.libraryForm.modelNo,
        label: this.libraryForm.label,
        page: this.libraryForm.currentPage,
        size: this.libraryForm.size,
        rescueTeamId: this.fullTimeTeam.id,
      };
      getMaterialsPage(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.records ? res.data.records : [];
          this.total = res.data.total;
        } else {
          this.loading = false;
        }
      });
    },
    radioChange(val) {
      if (val == 1) {
        this.radio = 1;
        this.columns = [
          { prop: "workerName", label: "姓名", minWidth: 80 },
          { prop: "workRoleName", label: "职务", minWidth: 80 },
          { prop: "workerPhone", label: "联系电话", minWidth: 80 },
          { prop: "workerEducation", label: "学历", minWidth: 80 },
          { prop: "workerSpecialty", label: "专业", minWidth: 80 },
          { prop: "workerImg", label: "照片", minWidth: 80 },
        ];
        this.getDatalist();
        // this.$refs.libraryForm.resetFields();
      } else if (val == 2) {
        this.radio = 2;
        this.columns = [
          { prop: "label", label: "物资名称", minWidth: 80 },
          { prop: "model", label: "规格/型号", minWidth: 80 },
          { prop: "unit", label: "计量单位", minWidth: 80 },
          { prop: "count", label: "库存数量", minWidth: 80 },
          { prop: "usages", label: "用途", minWidth: 80 },
          { prop: "remark", label: "备注", minWidth: 80 },
        ];

        let params = "125";
        this.getListData(params);
        // this.$refs.libraryForm.resetFields();
      } else if (val == 3) {
        this.radio = 3;
        this.columns = [
          { prop: "label", label: "设备名称", minWidth: 80 },
          { prop: "model", label: "规格/型号", minWidth: 80 },
          { prop: "unit", label: "计量单位", minWidth: 80 },
          { prop: "count", label: "库存数量", minWidth: 80 },
          { prop: "usages", label: "用途", minWidth: 80 },
          { prop: "remark", label: "备注", minWidth: 80 },
        ];
        let params = "126";
        this.getListData(params);
        // this.$refs.libraryForm.resetFields();
      }
    },
    libraryCls() {
      this.$emit("libraryCls");
    },
    // 查询
    libraryQuery() {
      console.log(1);
      if (this.radio == 1) {
        this.getDatalist();
      } else if (this.radio == 2) {
        let params = "125";
        this.getListData(params);
      } else if (this.radio == 3) {
        let params = "126";
        this.getListData(params);
      }
    },

    handleCurrentChange(val) {
      this.currentPage = val;
    },
    handleSizeChange(size) {
      this.size = size;
    },
    yuyin(val) {
      console.log(val);
    },
    shipin(val) {
      console.log(val);
    },
    duanxian(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss" scoped>
// .content {
//   height: 500px;
//   overflow-y: auto;
// }
.info-wrapper {
  ::v-deep .el-table--border,
  .el-table::before {
    border-bottom: 0 !important;
    height: 0px;
  }
}
.peopleNum {
  color: #000;
  font-weight: bold;
}
::v-deep .el-radio-button__inner {
  width: 200px;
}
.choice {
  display: flex;
  flex-direction: row;
}
.line {
  height: 1px;
  width: 100%;
  background: #143363;
  margin-bottom: 20px;
}
::v-deep .el-input__inner {
  background: #081742;
  border-color: #25458f;
  color: #fff;
}
.el-table--border::after {
  width: 0;
}
</style>
