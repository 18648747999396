<template>
  <div>
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px"
        border
        v-loading="loading"
        :span-method="objectSpanMethod"
        @selection-change="selectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column align="center" prop="branchName" label="线路-分布">
          <template slot-scope="scope">
            {{ scope.row.branchName }} - {{ scope.row.lineName }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="siteName"
          label="线路-工点"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="deadline"
          label="截止时间"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="fillTime"
          label="填报时间"
        ></el-table-column>
        <el-table-column align="center" prop="rejectReason" label="审批意见">
          <!--          <template slot-scope="scope">{{
            scope.row.fillUser ? scope.row.fillUser.name : ''
          }}</template>-->
        </el-table-column>
        <el-table-column
          align="center"
          prop="approvalState"
          label="状态"
          :formatter="formatState"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="openView(scope.row)" type="text" size="small"
              >查看</el-button
            >
            <el-button
              @click="onApproval(scope.row)"
              type="text"
              size="small"
              v-if="scope.row.status == 2 && depType != 1 && ctitle === '审批'"
              >审批</el-button
            >
            <el-button
              @click="getHistoryData(scope.row)"
              type="text"
              v-if="depType != 1 && ctitle === '审批'"
              size="small"
              >历史操作记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div slot="footer" class="dialog-footer" v-if="ctitle === '审批'">
      <el-button type="primary" @click="batchApproval" v-if="depType != 1"
        >批量操作</el-button
      >
      <!--      <el-button type="primary" @click="batchReject">批量审批</el-button>-->
      <el-button type="primary" @click="cancelInspect">取消</el-button>
    </div>

    <el-dialog
      title="三防应急检查表"
      :visible.sync="dialogVisible"
      append-to-body
      width="50%"
    >
      <inspectApproval
        v-if="dialogVisible"
        :operation="title"
        :editData="editData"
        @cancelApproval="cancelApproval"
        @successApproval="successApproval"
      ></inspectApproval>
    </el-dialog>

    <el-dialog
      title="历史操作记录"
      :visible.sync="historyDialog"
      append-to-body
      width="30%"
    >
      <historyPage
        v-if="historyDialog"
        :log-list="historyData"
        @historyClose="historyClose"
      ></historyPage>
    </el-dialog>

    <el-dialog
      title="三防应急检查表"
      :visible.sync="batchVisible"
      append-to-body
      width="50%"
    >
      <batchModal
        v-if="batchVisible"
        :responseList="selectionList"
        :response-record-id="editData.id"
        @successAgree="successAgree"
      ></batchModal>
    </el-dialog>
  </div>
</template>

<script>
import inspectApproval from "./inspectApproval";
import historyPage from "../resources/historyPage";
import { getApproveList, operatinghistoryList } from "@/api/response";
import batchModal from "./batchModal";
// ,queryCurrentResponseList
export default {
  name: "emergencyInspect",
  components: {
    inspectApproval,
    historyPage,
    batchModal,
  },
  props: {
    operation: null,
    editData: {},
    depType: {
      default: 0,
    },
    ctitle: {
      default: "",
    },
  },
  data() {
    return {
      tableData: [],
      loading: false,
      dialogVisible: false,
      historyDialog: false,
      batchVisible: false,
      // editData: {},
      title: "",
      historyData: [],
      selectionList: [],
      needMergeArr: ["branchName"],
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      let params = {
        responseRecordId: this.editData.responseId,
      };
      getApproveList(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    selectionChange(selection) {
      this.selectionList = selection;
    },
    successAgree() {
      this.batchVisible = false;
      this.getTableData();
    },
    /* async getTableData(){
      this.loading = true;
      let params = {
        reportType: "",
        status: ""
      }
      let res = await queryCurrentResponseList(params);
      if (res.code == 0) {
        this.tableData = res.data;
        // this.page.count = res.data.count;
        // this.page.pageSize = res.data.size;
        // this.page.total = res.data.total;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },*/
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(row, column, rowIndex, columnIndex);
      if (columnIndex === 2) {
        const _row = this.filterData(this.tableData, columnIndex).one[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    filterData(arr, colIndex) {
      let spanOneArr = [];
      let concatOne = 0;
      arr.forEach((item, index) => {
        if (index == 0) {
          spanOneArr.push(1);
        } else {
          // name标识数据表格第一列的参数
          if (colIndex == 2) {
            if (item.branchName === arr[index - 1].branchName) {
              spanOneArr[concatOne] += 1;
              spanOneArr.push(0);
            } else {
              spanOneArr.push(1);
              concatOne = index;
            }
          }
        }
      });
      console.log(spanOneArr);
      return {
        one: spanOneArr,
      };
    },
    batchApproval() {
      if (this.selectionList.length === 0) {
        this.$message.error("请至少选择一项");
        return;
      }
      let i = this.selectionList.findIndex((item) => item.status != 2);
      if (i !== -1) {
        this.$message.error("请选择都为待审批状态的数据");
        return;
      }
      this.batchVisible = true;
    },
    batchReject() {},
    cancelInspect() {
      this.$emit("cancelInspect");
    },
    successApproval() {
      this.dialogVisible = false;
      this.getTableData();
    },
    cancelApproval() {
      this.dialogVisible = false;
    },
    openView(row) {
      this.title = "查看";
      this.editData = { ...row };
      this.dialogVisible = true;
    },
    onApproval(row) {
      this.title = "审批";
      this.editData = { ...row };
      this.dialogVisible = true;
    },
    getHistoryData(row) {
      let params = {
        incidenceId: row.id,
        operationType: row.reportNameId,
      };
      operatinghistoryList(params).then((res) => {
        if (res.code == 0) {
          this.historyData = res.data;
          this.historyDialog = true;
        }
      });
    },
    historyClose() {
      this.historyDialog = false;
    },
    // '0-待填写、1-待监理审核、2-待建管审核、3-待质安部审核、4-已完成',
    formatState: function(row, column, cellValue) {
      switch (cellValue) {
        case -2:
          return "超时";
        case -1:
          return "未填报";
        case 0:
          return "待审批";
        case 1:
          return "通过";
        case 2:
          return "驳回";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
</style>
