<template>
  <div>
    <el-table :data="tabledata" border style="width: 100%; margin-top: 10px">
      <el-table-column prop="realName" label="人员" align="center">
      </el-table-column>
      <el-table-column prop="total" label="数量" align="center">
      </el-table-column>
    </el-table>
    <div class="pagination-wrapper flex justify-center mt-4">
      <el-pagination
        background
        :total="page.count"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        layout=" prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { messageCountPage } from "@/api/responseTem";
export default {
  components: {},
  props: {
    paramsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      page: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
      },
      tabledata: [],
    };
  },
  watch: {},
  mounted() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      //         dateType	时间类型 1年 2季 3 月		true
      // integer
      // endDate	结束时间		false
      // string
      // executorStatus	处理状态 0待处理 1已完成		false
      // integer
      // messageSource	消息来源 0：风险隐患1：应急 2：三防		true
      // integer
      // noticeCategory	通知类别: 0告知 1待办		true
      // integer
      // pageNum	页码,默认1 ,示例值(1)		false
      // integer
      // pageSize	分页长度，默认10 ,示例值(10)		false
      // integer
      // siteUuidList	工点uuid集合		false
      // array
      // string
      // startDate	开始时间
      let params = {
        dateType: Number(this.paramsData.dateType),
        endDate: "",
        executorStatus: this.paramsData.executorStatus,
        messageSource: 2,
        noticeCategory: 1,
        pageNum: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteUuidList: [],
        startDate: "",
      };
      messageCountPage(params).then((res) => {
        if (res.code == 0) {
          this.tabledata = res.data.records;
          this.page.count = res.data.count;
        }
      });
    },
    //页数
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.onSearch();
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.onSearch();
    },
  },
};
</script>
    
  <style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 700px;
  overflow: auto;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
::v-deep .el-table:before {
  height: 0px;
}
.stCard {
  margin: 0 20px 10px 0;
  padding: 20px 20px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.cenCard {
  padding: 20px 20px;
  width: 24%;
  height: 100px;
}
.cenNum {
  font-size: 28px;
  font-family: Arial;
  font-weight: bold;
  color: #081742;
}
</style>
    