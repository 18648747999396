<template>
  <div class="large-equipment-container">
    <index-box :title="title">
      <div class="wring-tit mb-2">
        <span class="wring-all 2xl:text-base xl:text-sm md:text-xs"
          >负责人员：张林坤 18908765245</span
        >
      </div>
      <div
        class="table-wrapper flex flex-col 2xl:text-base xl:text-sm md:text-xs"
      >
        <div class="flex items-center item" @click="facilityDetails('塔吊')">
          <div class="flex-1 flex items-center justify-center">
            <span class="icon-01 icon"></span>
          </div>
          <div class="flex-1 flex items-center justify-center">塔吊</div>
          <div class="flex-1 flex items-center justify-center">
            {{ machineData.towerCrane }}台
          </div>
        </div>
        <div
          class="flex items-center item"
          @click="facilityDetails('汽车吊/履带吊')"
        >
          <div class="flex-1 flex items-center justify-center">
            <span class="icon-02 icon"></span>
          </div>
          <div class="flex-1 flex items-center justify-center">
            汽车吊/履带吊
          </div>
          <div class="flex-1 flex items-center justify-center">
            {{ machineData.carCrane }}台
          </div>
        </div>
        <div class="flex items-center item" @click="facilityDetails('龙门吊')">
          <div class="flex-1 flex items-center justify-center">
            <span class="icon-03 icon"></span>
          </div>
          <div class="flex-1 flex items-center justify-center">龙门吊</div>
          <div class="flex-1 flex items-center justify-center">
            {{ machineData.gantryCrane }}台
          </div>
        </div>
        <div
          class="flex items-center item"
          @click="facilityDetails('轮胎式起重机')"
        >
          <div class="flex-1 flex items-center justify-center">
            <span class="icon-04 icon"></span>
          </div>
          <div class="flex-1 flex items-center justify-center">
            轮胎式起重机
          </div>
          <div class="flex-1 flex items-center justify-center">
            {{ machineData.crane }}台
          </div>
        </div>
      </div>
    </index-box>

    <Modal
      class="modalDetail"
      ref="rescueDetail"
      :modal="false"
      @closeDialog="closeDialog"
      :width="'1200px'"
      title="大型起重设备"
    >
      <div
        class="table-wrapper p-4 h-full"
        v-loading="pageLoading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        <el-table
          :data="rescueData"
          border
          style="width: 100%"
          max-height="500"
        >
          <el-table-column align="center" label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column prop="label" label="关注点类型"> </el-table-column>
          <el-table-column prop="site" label="归属工点名称"> </el-table-column>
          <el-table-column prop="alterFlag" label="当前有无报警">
          </el-table-column>
          <el-table-column prop="created" label="记录时间"> </el-table-column>
        </el-table>
        <div class="flex justify-center pt-4">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="page.count"
            :page-count="page.total"
            :page-size="page.pageSize"
            :current-page="page.pageNo"
            @size-change="handlerescueChange"
            @current-change="hanrescueChange"
          >
          </el-pagination>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import { getMachineById, getPointInfo } from "@/api/map";
import Modal from "@/components/Modal/modal.vue";
export default {
  name: "largeEquipment",
  components: {
    IndexBox,
    Modal,
  },
  data() {
    return {
      title: "大型起重设备",
      machineData: {
        towerCrane: 0,
        carCrane: 0,
        gantryCrane: 0,
        crane: 0,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        count: 0,
      },
      rescueData: [],
      pageLoading: false,
    };
  },
  async created() {
    await this.queryLineSiteDetail(this.$route.query.id);
  },
  mounted() {},
  methods: {
    facilityDetails(name) {
      console.log(name);
      // 大型设备的详情弹窗 暂时不显示
      // this.$refs.rescueDetail.handleOpen();
    },
    // 根据工点id查询详情
    queryLineSiteDetail(id) {
      getPointInfo(id).then((res) => {
        if (res.code == 0) {
          const params = {
            lineId: res.data.parentUuid,
            siteId: res.data.uuid,
          };
          if (res.data.parentUuid == undefined) {
            params.lineId = "-";
          }
          this.queryMachineData(params);
        }
      });
    },
    // 根据线路的uuid和工点的uuid查询大型设备统计
    queryMachineData(record) {
      getMachineById(record).then((res) => {
        if (res.code == 0) {
          console.log(res, "普通塔式起重机");
          for (let item of res.data) {
            switch (item.label) {
              case "普通塔式起重机":
                this.machineData.towerCrane = item.count;
                break;
              case "汽车吊":
                this.machineData.carCrane += item.count;
                break;
              case "履带吊":
                this.machineData.carCrane += item.count;
                break;
              case "龙门吊":
                this.machineData.gantryCrane = item.count;
                break;
              case "轮胎式起重机":
                this.machineData.crane = item.count;
                break;
            }
          }
        }
      });
    },
    closeDialog() {
      (this.rescueData = []),
        (this.page = {
          pageNo: 1,
          pageSize: 10,
          total: 0,
          count: 0,
        });
    },
    handlerescueChange(val) {
      this.page.pageSize = val;
      this.rescueData = [];
      this.totalnum(this.datarry);
    },
    hanrescueChange(val) {
      this.rescueData = [];
      this.page.pageNo = val;
      this.totalnum(this.datarry);
    },
  },
};
</script>

<style lang="scss" scoped>
.large-equipment-container {
  height: 100%;
  .wring-tit {
    height: 44px;
    border-radius: 22px;
    background: #0e2553;
    text-align: center;
    line-height: 44px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  .table-wrapper {
    margin-bottom: 20px;
    border-top: 1px solid #173f58;
    border-left: 1px solid #173f58;
    .item {
      height: 58px;
      div {
        height: 100%;
        border-right: 1px solid #173f58;
        border-bottom: 1px solid #173f58;
        color: #fff;
      }
      .icon {
        display: block;
        width: 44px;
        height: 44px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
      }
      .icon-01 {
        background-image: url("../../../../assets/equipment-01.png");
      }
      .icon-02 {
        background-image: url("../../../../assets/equipment-02.png");
      }
      .icon-03 {
        background-image: url("../../../../assets/equipment-03.png");
      }
      .icon-04 {
        background-image: url("../../../../assets/equipment-04.png");
      }
      .header-title {
        color: #43a5e9;
      }
    }
  }
}

::v-deep .el-table {
  width: 100%;

  background: transparent;

  .el-table__body-wrapper {
    height: 300px;
  }

  &::before {
    background-color: #143363;
  }

  thead {
    color: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: rgba(20, 51, 99, 0.5);
  }

  tr,
  th {
    background-color: transparent;
  }

  td {
    border-bottom: 1px solid #143363;
  }

  .cell {
    color: #fff;
  }

  th.is-leaf {
    border: 1px solid #143363;
  }
}

::v-deep .el-table--border {
  border: 1px solid #143363;

  .el-table--enable-row-hover {
    background-color: #143363;
  }

  td {
    border-right: 1px solid #143363;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: rgba(0, 46, 117, 0.4);
}
::v-deep .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  width: 0;
}
</style>
