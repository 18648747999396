<template>
  <div class="rectification-container">
    <index-box :title="title">
      <div
        class="scroll-wrapper overflow-y-scroll flex flex-col justify-around"
      >
        <div class="rec-tit flex justify-between pl-5 pr-5">
          <span class="rec-all">今日新整改问题数：</span>
          <span class="rec-green">{{ countData.todayRectify }}</span>
        </div>
        <div class="rec-info flex justify-between mt-2 pl-2 pr-2">
          <span class="flex flex-col" @click="siteIssueDetail">
            <span class="info-text text-center">{{
              countData.questionCount
            }}</span>

            <span class="info-tit 2xl:text-base xl:text-sm md:text-xs"
              >累计问题</span
            >
          </span>
          <el-divider
            direction="vertical"
            class="2xl:mx-2 xl:mx-1.5 md:mx-1"
          ></el-divider>
          <span class="flex flex-col" @click="siteIssueDetail">
            <span class="info-text text-center" style="color: #72c040">{{
              countData.rectified
            }}</span>

            <span class="info-tit 2xl:text-base xl:text-sm md:text-xs"
              >已整改</span
            >
          </span>
          <el-divider direction="vertical"></el-divider>
          <span class="flex flex-col" @click="siteIssueDetail">
            <span class="info-text text-center" style="color: #1791ff">{{
              countData.rectifying
            }}</span>

            <span class="info-tit 2xl:text-base xl:text-sm md:text-xs"
              >整改中</span
            >
          </span>
          <el-divider direction="vertical"></el-divider>
          <span class="flex flex-col" @click="siteIssueDetail">
            <span class="info-text text-center" style="color: #e23c39">{{
              countData.toRectify
            }}</span>

            <span class="info-tit 2xl:text-base xl:text-sm md:text-xs"
              >待整改</span
            >
          </span>
        </div>
      </div>
    </index-box>
    <!-- 点击查看详情 -->
    <Modal
      ref="modalSite"
      :modal="false"
      :width="'1200px'"
      :title="'工点问题排查及整改情况'"
    >
      <div class="material-container h-full">
        <el-form
          ref="libraryForm"
          :inline="true"
          :model="libraryForm"
          class="demo-form-inline"
        >
          <el-form-item prop="startDate">
            <el-date-picker
              v-model="dateTime"
              type="datetimerange"
              clearable
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="margin:0 20px"
              :append-to-body="false"
            >
            </el-date-picker>
            <!-- <el-date-picker
              clearable
              v-model="libraryForm.startDate"
              type="date"
              placeholder="选择整改起始时间"
              value-format="yyyy-MM-dd"
              :append-to-body="false"
            >
            </el-date-picker> -->
          </el-form-item>

          <el-form-item prop="state">
            <el-select
              clearable
              v-model="libraryForm.state"
              placeholder="选择状态"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in stateData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="libraryQuery">查询</el-button>
          </el-form-item>
        </el-form>

        <div class="tablese">
          <el-table
            :data="tableData"
            style="width: 100%"
            border
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            max-height="500"
          >
            <el-table-column
              type="index"
              label="序号"
              width="60"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="checkItemContent"
              align="center"
              label="问题详情"
              width="320"
            >
            </el-table-column>
            <el-table-column
              prop="checkTime"
              align="center"
              label="问题上报时间"
            >
            </el-table-column>
            <el-table-column
              prop="correctiveTime"
              align="center"
              label="问题整改时间"
            >
            </el-table-column>
            <el-table-column
              prop="correctiveUser.name"
              align="center"
              label="整改人员"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="correctiveApproveStatus"
              align="center"
              label="状态"
              width="80"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.correctiveApproveStatus == 1"
                  >待审批</span
                >
                <span v-if="scope.row.correctiveApproveStatus == 0"
                  >待整改</span
                >
                <span v-if="scope.row.correctiveApproveStatus == 3"
                  >已完成</span
                >
                <span v-if="scope.row.correctiveApproveStatus == 2"
                  >未通过</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="" align="center" label="状态" width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="detailClick(scope.row)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="flex justify-center mt-4">
          <el-pagination
            background
            style="z-index:9"
            @current-change="handleCurrentChange"
            :current-page.sync="libraryForm.page"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </Modal>
    <Modal
      ref="modalSiteDetail"
      :modal="false"
      :width="'800px'"
      :title="'详情'"
    >
      <div class="material-container h-full">
        <div
          class="table-wrapper table-auto flex flex-col 2xl:text-base xl:text-sm md:text-xs"
          style="height: 100%; overflow-y: auto"
        >
          <div class="flex items-center item">
            <div class=" flex items-center justify-center" style="width:30%">
              线路-工点
            </div>
            <div class="flex-1 flex items-center justify-center">
              {{ QuestionDetail.lineName }}- {{ QuestionDetail.siteName }}
            </div>
          </div>
          <div class="flex items-center item">
            <div class=" flex items-center justify-center" style="width:30%">
              发布人
            </div>
            <div class="flex-1 flex items-center justify-center">
              {{ QuestionDetail.checkPeople }}
            </div>
          </div>
          <div class="flex items-center item">
            <div class=" flex items-center justify-center" style="width:30%">
              发布时间
            </div>
            <div class="flex-1 flex items-center justify-center">
              {{ QuestionDetail.checkTime }}
            </div>
          </div>
          <div class="flex items-center item">
            <div class=" flex items-center justify-center" style="width:30%">
              状态
            </div>
            <div class="flex-1 flex items-center justify-center">
              {{ QuestionDetail.correctiveApproveStatus }}
            </div>
          </div>
          <div class="flex items-center item">
            <div class=" flex items-center justify-center" style="width:30%">
              问题描述
            </div>
            <div class="flex-1 flex items-center justify-center">
              {{ QuestionDetail.checkItemContent }}
            </div>
          </div>
          <div class="flex items-center item">
            <div class=" flex items-center justify-center" style="width:30%">
              问题照片
            </div>
            <div class="flex-1 flex items-center  justify-center">
              <div class="demo-image__preview">
                <el-image
                  v-for="(i, index) in questionUrl"
                  :key="index"
                  class="ml-1.5"
                  style="width: 100px; height: 100px"
                  :src="i"
                  :preview-src-list="questionUrl"
                >
                </el-image>
              </div>
            </div>
          </div>
          <div
            class="flex items-center item"
            v-if="QuestionDetail.correctiveApproveStatus == 3"
          >
            <div class=" flex items-center justify-center" style="width:30%">
              整改情况
            </div>
            <div class="flex-1 flex items-center  justify-center flex-col">
              <div>
                <span class="mr-6"
                  >整改人：{{
                    QuestionDetail.correctiveUser &&
                      QuestionDetail.correctiveUser.name
                  }}</span
                >
                <span>整改时间：{{ QuestionDetail.correctiveTime }}</span>
              </div>

              <!-- <div>整改描述： {{ QuestionDetail.correctiveDescription }}</div> -->
              <div>整改描述： {{ QuestionDetail.correctiveDescription }}</div>

              <div class="demo-image__preview">
                <el-image
                  v-for="(i, index) in PhotosUrl"
                  :key="index"
                  class="ml-1.5"
                  style="width: 100px; height: 100px"
                  :src="i"
                  :preview-src-list="PhotosUrl"
                >
                </el-image>
              </div>
            </div>
          </div>
          <div
            class="flex items-center item"
            v-if="QuestionDetail.correctiveApproveStatus == 3"
          >
            <div class=" flex items-center justify-center" style="width:30%">
              消除情况
            </div>
            <div class="flex-1 flex items-center  justify-center  flex-col">
              <div>
                <span class="mr-6"
                  >消除人：{{
                    QuestionDetail.approveUser &&
                      QuestionDetail.approveUser.name
                  }}</span
                >
                <span>消除时间：{{ QuestionDetail.approveTime }}</span>
              </div>
              <div>消除描述： {{ QuestionDetail.rejectReason }}</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import IndexBox from "@/components/IndexBox/indexBox";
import Modal from "@/components/Modal/modal.vue";
import dayjs from "dayjs";
import {
  countQuestion,
  threeCheckItemQuestion,
  threeCheckItemQuestionDetail,
} from "@/api/response";

export default {
  name: "workResponse",
  components: {
    IndexBox,
    Modal,
  },
  data() {
    return {
      title: "工点问题排查及整改情况",
      countData: {},
      libraryForm: {
        page: 1,
        size: 5,
        startDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        state: "",
      },
      dateTime: [],
      QuestionDetail: {},
      loading: false,
      stateData: [
        {
          label: "已完成",
          value: 3,
        },
        {
          label: "待整改",
          value: 0,
        },
        {
          label: "待审批",
          value: 1,
        },
        {
          label: "未通过",
          value: 2,
        },
      ],
      total: 0,
      tableData: [
        {
          siteName: "wqewqe",
          companyUnit: "zxcxzc",
        },
      ],
      questionUrl: [], //问题照片
      PhotosUrl: [], //整改照片
    };
  },
  created() {
    console.log(this.$route.query.uuid);
    this.getSiteCount();
  },
  methods: {
    getSiteCount() {
      let params = {
        siteUuid: this.$route.query.uuid,
      };
      countQuestion(params).then((res) => {
        if (res.code == 0) {
          this.countData = res.data;
        }
      });
    },
    detailClick(row) {
      this.$nextTick(() => {
        this.$refs.modalSiteDetail.handleOpen();
      });
      this.getDetail(row);
    },
    getDetail(row) {
      // this.loading = true;
      let params = {
        questionId: row.id,
      };
      threeCheckItemQuestionDetail(params).then((res) => {
        if (res.code == 0) {
          this.QuestionDetail = res.data;
          this.questionUrl =
            this.QuestionDetail.uri && this.QuestionDetail.uri.split(",");
          console.log(this.questionUrl, "questionUrl");
          this.PhotosUrl =
            this.QuestionDetail.correctivePhotosUri &&
            this.QuestionDetail.correctivePhotosUri.split(",");
          console.log(this.PhotosUrl, "PhotosUrl");
          /* for (let key in this.QuestionDetail) {
            // pass
            // this.QuestionDetail[key]
            if (key == "uri") {
              this.QuestionDetail[key].split(",");
            }
          } */
        }
      });
    },

    siteIssueDetail() {
      this.$nextTick(() => {
        this.$refs.modalSite.handleOpen();
      });
      this.getListDatas();
    },
    //获取表格数据
    getListDatas() {
      this.loading = true;
      let params = {
        correctiveApproveStatus: this.libraryForm.state,
        startTime: this.dateTime ? this.dateTime[0] : "",
        endTime: this.dateTime ? this.dateTime[1] : "",
        siteUuId: this.$route.query.uuid,
        pageNum: this.libraryForm.page,
        pageSize: 10,
      };
      threeCheckItemQuestion(params).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.tableData = res.data.list;
          console.log(this.tableData);
          this.total = res.data.total;
          this.pageNum = res.data.pageNum;
          this.pageSize = res.data.pageSize;
        } else {
          this.loading = false;
        }
      });
    },
    // 查询
    libraryQuery() {
      this.getListDatas(4);
    },
    // 分页
    handleCurrentChange(val) {
      this.libraryForm.page = val;
      this.getListDatas(4);
    },
  },
};
</script>

<style lang="scss" scoped>
.rectification-container {
  .scroll-wrapper {
    height: 100%;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    .rec-tit {
      height: 44px;
      border-radius: 22px;
      background: #0e2553;
      text-align: center;
      line-height: 44px;
      .rec-all {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;

        color: #ffffff;
      }
      .rec-ten {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #e23c39;
      }
      .rec-green {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #72c040;
      }
      .rec-new {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #e23c39;
      }
      ::v-deep .el-divider--vertical {
        background: #273b65;
      }
    }
    .rec-info {
      .info-text {
        font-size: 24px;
        font-family: Arial;
        font-weight: bold;
        color: #ffffff;
      }
      .info-des {
        font-size: 14px;
        color: #ffffff;
      }
      .info-tit {
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.6;
      }
      ::v-deep .el-divider--vertical {
        width: 1px;
        height: 50px;
        background: #ffffff;
        opacity: 0.1;
      }
    }
  }
}
:v-deep .el-input--prefix .el-input__inner {
  color: #fff;
  background-color: #18467f;
  border: 0px solid #dcdfe6;
}
// ::v-deep .el-input__inner {
//   color: #fff;
//   background-color: #18467f;
//   border: 0px solid #dcdfe6;
// }
::v-deep .el-range-editor .el-range-input {
  background-color: #18467f;
  border: 0px solid #dcdfe6;
  color: #fff !important;
}
::v-deep .el-date-editor .el-range-separator {
  color: #fff !important;
}
::v-deep .el-date-editor {
  background-color: #18467f;
  border: 0px solid #dcdfe6 !important;
}
::v-deep .el-input--suffix .el-input__inner {
  background-color: #18467f;
  border: 0px solid #dcdfe6 !important;
  color: #fff !important;
}

::v-deep .el-table {
  width: 100%;

  background: transparent;

  .el-table__body-wrapper {
    height: 300px;
  }

  &::before {
    background-color: #143363;
  }

  thead {
    color: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: rgba(20, 51, 99, 0.5);
  }

  tr,
  th {
    background-color: transparent;
  }

  td {
    border-bottom: 1px solid #143363;
  }

  .cell {
    color: #fff;
  }

  th.is-leaf {
    border: 1px solid #143363;
  }
}

::v-deep .el-table--border {
  border: 1px solid #143363;

  .el-table--enable-row-hover {
    background-color: #143363;
  }

  td {
    border-right: 1px solid #143363;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: rgba(0, 46, 117, 0.4);
}

.table-wrapper {
  margin-bottom: 20px;
  border-top: 1px solid #173f58;
  border-left: 1px solid #173f58;
  .item {
    height: 60px;
    &:nth-child(5) {
      min-height: 130px;
    }
    &:nth-child(6) {
      height: 130px;
    }
    &:nth-child(7) {
      height: 180px;
    }
    &:nth-child(8) {
      height: 80px;
    }
    > div {
      // min-height: 60px;
      height: 100%;
      border-right: 1px solid #173f58;
      border-bottom: 1px solid #173f58;
      color: #fff;
    }

    .header-title {
      color: #43a5e9;
    }
  }
}
</style>
